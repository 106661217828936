import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color } from 'highcharts';

@Component({
	selector: 'rb-waiting-indicator',
	templateUrl: './waiting-indicator.component.html',
	styleUrls: ['./waiting-indicator.component.scss']
})
export class WaitingIndicatorComponent implements OnInit {

	@Input() backgroundColor =	'transparent';	 // 'rgb(128, 128, 128, 0.6)';
	@Input() color: Color;
	@Input() fontSize = '16px';
	@Input() iconSize = '40px';
	@Input() isVisible = true;
	@Input() text: string;
	@Input() percentComplete = -1;

	private _error: string = null;
	@Input() set error(value: string) {
		this._error = value;
		this.showError = (this._error && this._error.length > 0);
	}
	get error(): string { return this._error; }

	@Output() okClick = new EventEmitter();

	showError = false;

	constructor() { }

	ngOnInit() {
	}

	onOkClick() {
		this.okClick.emit();
	}
}

export class GlobalWeatherLocation {

	areaName: string;
	country: string;
	latitude: number;
	longitude: number;
	region: string;
	weatherUrl: string;

	constructor(json: any = null) {

		if (json) {
			if (json.areaName != null && json.areaName.length > 0) this.areaName = json.areaName[0].value;
			if (json.country != null && json.country.length > 0) this.country = json.country[0].value;
			if (json.region != null && json.region.length > 0) this.region = json.region[0].value;
			if (json.weatherUrl != null && json.weatherUrl.length > 0) this.weatherUrl = json.weatherUrl[0].value;
			if (json.latitude != null) this.latitude = +json.latitude;
			if (json.longitude != null) this.longitude = +json.longitude;
		}
	}
}

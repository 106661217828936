/* eslint-disable @angular-eslint/directive-class-suffix */

import { ConnectDataPacksDict } from '../../connect-data-pack/models/connect-data-packs-dict.model';
import { Directive } from '@angular/core';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationStatus } from '../../stations/models/station-status.model';
import { StationStatusListItem } from '../../stations/models/_station-status-list-item.model';

import Priority = RbEnums.Common.Priority;

@Directive()
export class ProgramStepListItem extends StationStatusListItem {

	constructor(json: any = null) {
		super(!json ? 0 : json.stationId);

		if (json) {
			Object.assign(this, json);
		}
	}

	adjustedRunTime: string;
	areaLevel2Name: string = null;
	areaLevel3Name: string = null;
	baseRunTime: string;
	courseName: string;
	programStepId: number;
	sequenceNumber: number;
	stationName: string;
	stationTerminal: number;
	stationAddressValid = false;
	sprinklerCategory: number;
	subAreaName: string;
	runtimeETLong: string;
	tempAdjust: number;
	tempAdjustDays: number;
	yearlyAdjFactor: number;
	precRateFinal: number;
	arc: number;
	rotation: number;
	priority: Priority;
	etAdjustFactor: number;
	volume: number;
	passes: number;
	isEtBasedProgram: boolean;
	programETAdjustType: RbEnums.Common.EtAdjustType;
	master = false;
	overrideStationDefaultRunTime = RbEnums.Common.StepRunTime.Default;

	get landscapeTypeGridColumn(): string {
		return this.areaLevel2Name || this.INDETERMINATE_STATE;
	}

	get sprinklerTypeGridColumn(): string {
		return this.areaLevel3Name || this.INDETERMINATE_STATE;
	}

	static createDefault(): ProgramStepListItem {
		const item = new ProgramStepListItem();
		item.yearlyAdjFactor = 100;
		return item;
	}

	setStationStatus(status: StationStatus) {
		this.status = status.status !== '' ? status.status : this.INDETERMINATE_STATE;
		this.courseViewStatus = status.courseViewStatus;
		this.irrigationStatus = status.irrigationStatus;
		this.irrigationEngineStationStatusItem = status.irrigationEngineStationStatusItem;

		// RB-9052: Copy the status reason. If you don't do this, the reason for Waiting won't
		// be available for display to the user.
		this.statusReason = status.statusReason;
	}

	get isSprinklerDetailSetUp(): boolean {
		return this.sprinklerCategory === 0 || !!this.sprinklerCategory;
	}

	updateData(programStepListItem: ProgramStepListItem, controllerId: number, connectDataPacks: ConnectDataPacksDict) {
		// Some places need to keep this object REFERENCE to prevent angular from making reload.
		const preventedUpdatedKeys = ['secondsRemainingChange', 'stationStatusChangeSubscription', 'stoppingIrrigationSubscription'];
		Object.keys(programStepListItem).filter(x => !preventedUpdatedKeys.includes(x)).forEach(item => {
			this[item] = programStepListItem[item];
			this.setStationStatus(RbUtils.Stations.getStationStatus(this.stationTerminal, controllerId, connectDataPacks))
		});
	}
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { CompanyManagerService } from '../../../api/companies/company-manager.service';
import { CompanyPreferences } from '../../../api/companies/models/company-preferences.model';
import { MapService } from '../../../common/services/map.service';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Site } from '../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { WeatherManagerService } from '../../../api/weather/weather-manager.service';
import { WeatherSource } from '../../../api/weather-sources/models/weather-source.model';
import { WeatherSourceManagerService } from '../../../api/weather-sources/weather-source-manager.service';
import { WorldWeatherCurrentCondition } from '../../../api/weather/models/world-weather-current-condition.model';
import { WorldWeatherData } from '../../../api/weather/models/world-weather-data.model';
import { WorldWeatherWeather } from '../../../api/weather/models/world-weather-weather.model';

@Component({
	selector: 'rb-weather-widget',
	templateUrl: './weather-widget.component.html',
	styleUrls: ['./weather-widget.component.scss']
})
export class WeatherWidgetComponent implements OnInit, OnDestroy {
	private readonly cssTransitionSpeedInMs = 500;

	private _siteId: number;
	@Input() set siteId(value: number) {
		if (!value || value === this._siteId) { return; }

		this._siteId = value;
		this.fetchWeather();
	}

	private _companyId: number;
	@Input() set companyId(value: number) {
		if (!value || value === this._companyId) { return; }

		this._companyId = value;
	}

	private _weatherForecastQueryParam: string;
	@Input() set weatherForecastQueryParam(value: string) {
		if (!value || value === this._weatherForecastQueryParam) { return; }

		this._weatherForecastQueryParam = value;
		this.fetchWeather();
	}

	get weatherForecastQueryParam(): string {
		return this._weatherForecastQueryParam;
	}

	@Input() isViewWeatherShown = false;
	@Output() viewWeatherEvent = new EventEmitter();

	private site: Site;
	private companyPrefs: CompanyPreferences;

	tileData: WorldWeatherWeather[] = [];
	location: string;
	currentConditions: WorldWeatherCurrentCondition;
	activeTile = 0;
	isBusy = false;
	loadError: string = null;
	isTileAnimating = false;
	fetchWeatherTimerRef;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(
		private companyManager: CompanyManagerService,
		private siteManager: SiteManagerService,
		private weatherManager: WeatherManagerService,
		private weatherSourceManager: WeatherSourceManagerService,
		// @ts-ignore
		// We need to create the map service, but don't need to call any instance methods
		private mapService: MapService,
	) {
	}

	ngOnInit() {
		// this.fetchWeather();
	}

	ngOnDestroy() {
		// Just in case.
		clearTimeout(this.fetchWeatherTimerRef);
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	// Method to mitigate unnecessary and redundant calls to 3rd party api by allowing for a settling time for setting the site or company.
	// Our preferences is to use the Site location if we have it.
	private fetchWeather() {
		clearTimeout(this.fetchWeatherTimerRef);

		// Place in timeout to allow both Site and Company parameters (if appropriate) to be set.
		this.fetchWeatherTimerRef = setTimeout(() => this.getWeatherForecast(), 100);
	}

	onTileClick(tileIndex: number) {
		if (this.isTileAnimating) return;

		this.isTileAnimating = true;
		this.activeTile = tileIndex;

		setTimeout(() => this.isTileAnimating = false, this.cssTransitionSpeedInMs + 50);
	}

	onShowETCalendar() {
		this.viewWeatherEvent.emit();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getWeatherForecast() {
		this.isBusy = true;
		if (this.weatherForecastQueryParam) {
			this.callWeatherService(this.weatherForecastQueryParam).subscribe();
			return;
		}

		const sources: Observable<any>[] = [ this.companyManager.getCompanyPreferences().pipe(take(1)) ];
		if (this._siteId) { sources.push(this.siteManager.getSite(this._siteId).pipe(take(1))); }

		forkJoin(sources)
			.subscribe(([companyPrefs, site]) => {
				this.companyPrefs = <CompanyPreferences>companyPrefs;
				if (site) { this.site = <Site>site; }

				if (this.siteHasWeatherLocation) {
					this.callWeatherService(this.site.weatherForecastQueryParam)
						.subscribe((isSuccess: boolean) => {
							if (!isSuccess) { this.callWeatherService(this.companyPrefs.weatherForecastQueryParam).subscribe(); }
						});
					return;
				}
				// RB-12788: IQ4: Display the weather widget data based on the weather source address
				if (!this.site.isGolfSite && this.site.defaultWeatherSourceId) {
					this.weatherSourceManager.getWeatherSource(this.site.defaultWeatherSourceId)
						.subscribe((ws: WeatherSource) => {
							this.callWeatherServiceByWeatherSource(ws);
						});
					return;
				}
				this.callWeatherService(this.companyPrefs.weatherForecastQueryParam).subscribe();

			}, (error: any) => {
				this.isBusy = false;
			});
	}

	/**
	 * Call the weather service to get the local weather for the queryParam string passed here. We typically
	 * choose a different queryParam value for more-precise location.
	 * @param queryParam - string describing the location (could be from the site or the company)
	 * @returns Observable<boolean> - true if successful, false otherwise
	 */
	private callWeatherService(queryParam: string): Observable<boolean> {
		this.isBusy = true;
		const self = this;	// Capture 'this' for use in Google callback.

		return Observable.create(observer => {
			// RB-9518: Pass the query parameters to a utility method for geocoding into lat/long. We'll use that
			// data for the actual query as we get MUCH better results than passing a city, state, country when the
			// city is small and there is no weather report for that location. See St Thomas Golf and Country Club,
			// 42325 Sparta Line, Union, Ontario, Canada. The returned location when you pass this address is
			// 500 miles away!
			RbUtils.Weather.GetWWOQueryParamsPrecise(queryParam).pipe(take(1)).subscribe(queryParamPrecise => {
				self.weatherManager.getSevenDayForecast(queryParamPrecise, 'en')
					.pipe(
						take(1),
						finalize(() => self.isBusy = false)
					)
					.subscribe((data: WorldWeatherData) => {
						if (data.weather == null || data.weather.length < 7) {
							self.clearWeatherData();
							observer.next(false);
							observer.complete();
							return;
						}

						self.location = data.nearest_area[0].location;
						self.tileData = data.weather;
						self.currentConditions = data.current_condition[0];
						self.currentConditions.timeZone = self.site ? self.site.timeZoneAbbreviation : '';

						observer.next(true);
						observer.complete();

					}, () => {
						self.clearWeatherData();
						observer.next(false);
						observer.complete();
					});
			});
		});
	}

	/**
	 * Call the weather service to get the local weather for the weather source object passed here.
	 * For now we will use latitude and longitude to get the data
	 * @param ws - Weather Source
	 */
	private callWeatherServiceByWeatherSource(ws: WeatherSource): void {
		if (ws.latitude && ws.longitude) {
			this.isBusy = true;
			const queryParamPrecise = `${ws.latitude},${ws.longitude}`;

			this.weatherManager.getSevenDayForecast(queryParamPrecise, /* lang */ 'en')
				.pipe(
					take(1),
					finalize(() => this.isBusy = false)
				)
				.subscribe((data: WorldWeatherData) => {
					if (data.weather == null || data.weather.length < 7) {
						this.clearWeatherData();
						return;
					}

					this.location = data.nearest_area[0].location;
					this.tileData = data.weather;
					this.currentConditions = data.current_condition[0];
					this.currentConditions.timeZone = this.site ? this.site.timeZoneAbbreviation : '';
				});
		} else if (ws.programName) {
			this.callWeatherService(ws.programName).subscribe();
		} else {
			this.callWeatherService(this.companyPrefs.weatherForecastQueryParam).subscribe();
		}
	}

	private clearWeatherData() {
		this.location = null;
		this.tileData = null;
		this.currentConditions = null;
	}

	private get siteHasWeatherLocation(): boolean {
		return this.site != null && this.site.weatherForecastQueryParam.length > 0;
	}

}

/*
 * NOTE: DO NOT USE THESE CONSTANTS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbConstants Namespace.
 * 		 E.g., RbConstants.Form.DEBOUNCE
 * 		 See: _rb.constants.ts
 */

import { RbConstants } from './_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';

export namespace XXUseRbConstantsNamespace {

	export abstract class Form {
		
		static readonly DEBOUNCE = 500;
		static readonly THROTTLE = 1000;
		static readonly IP_CONNECTION = 'Ethernet';
		static readonly IP_CONNECTION_TYPE = 2;
		static readonly NOTIFICATION_DELAY = 10000;
		static readonly PROGRAM_TYPE = { custom: 0, odd: 2, odd31: 3, even: 4, cyclical: 5 };
		static readonly SITE_TYPE_GOLF = 'Golf';
		static readonly FLOW_SENSOR = 'Flow';
		static readonly MERIDIEM_OPTIONS = ['AM', 'PM'];
		static readonly WSProLTWeatherSource = { name: 'WS-PRO LT', id: 11 };
		static readonly WSPro2WeatherSource = { name: 'WS-PRO2', id: 12 };
		static readonly CimisWeatherSource = { name: 'CIMIS', id: 13 };
		static readonly WwoWeatherSource = { name: 'IQ Global Weather', id: 26 };
		static readonly WwoProWeatherSource = { name: 'IQ Global Weather Pro', id: 61 };
		static readonly WatchDogWeatherSource = { name: "WatchDog", id: 73 };
		static readonly FloZoneFlowElementType = { name: 'FloZone', id: 0 };
		static readonly BranchFlowElementType = { name: 'Branch', id: 3 };
		static readonly BoosterPumpElementType = { name: 'Booster Pump', id: 2 };
		static readonly PumpStationFloElementType = { name: 'Pump Station', id: 1 };
		static readonly MIMControllerType = { name: 'MIM', id: 44 };
		static readonly MIMLinkControllerType = { name: 'MIM-LINK', id: 45 };
		static readonly PORT_NUMBER_RANGE = { min: 1, max: 65535 };
		static readonly RADIO_ADDRESS_RANGE = { min: 0, max: 65000 };
		static readonly RAIN_DELAY_RANGE = { min: 0, max: 14 };
		static readonly RAIN_DELAY_RANGE_LXIVM = { min: 0, max: 30 };
		static readonly UNIVERSAL_RAIN_DELAY_RANGE = { min: 0, max: 30 };
		static readonly CHARACTER_LENGTH_RANGE = { min: 1, max: 50 };
		static readonly MAX_CHARACTER_LENGTH = { max: 100 };
		static readonly DESCRIPTION_LENGTH_RANGE = { min: 0, max: 255 };
		static readonly SHORTNAME_CHARACTER_LENGTH_RANGE = { min: 1, max: 5 };
		static readonly POSTAL_CODE_LENGTH_RANGE = { min: 0, max: 20 };
		static readonly ELEVATION_RANGE = { min: -30000, max: 30000 };
		static readonly PAKBUS_ADDRESS_RANGE = { min: 0, max: 65535 };
		static readonly LATITUDE_RANGE = { min: -90, max: 90 };
		static readonly LONGITUDE_RANGE = { min: -180, max: 180 };
		static readonly PASSWORD_LENGTH_RANGE = { max: 4 };
		static readonly USER_PASSWORD_LENGTH_RANGE = { min: 8 };
		static readonly USER_PASSWORD_VALIDATION_REGEX = {
			// The pattern constant is used to validate password 
			// The password should contain at least one lowercase, one uppercase, one number, and one special character with min length is 8.
			pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\W_]).{8,}$',
			// The regex constants are used to check separately each case to show the validations list
			containsNumber: /\d/, 
			containsUpperCase: /[A-Z]/, 
			containsLowerCase: /[a-z]/, 
			containsSpecialCharacter: /[\W_]/ 
		};
		static readonly ADDRESS_RANGE_NONE = [{ min: 1, max: 0 }];
		static readonly ADDRESS_RANGE_DEFAULT = [{ min: 0, max: 65535 }];
		static readonly CROP_COEFFICIENT_RANGE = { min: 0, max: 2 };
		static readonly STATE_LENGTH_RANGE = { min: 2, max: 50 };
		static readonly ZIP_LENGTH_RANGE = { min: 1 , max: 50 };
		static readonly WATCHDOG_WEATHER_SOURCE_SERIAL_NUMBER_MAX_LENGTH = 50;

		// "ICM" ranges must include those for sensors only and stations which can include IFX/Satellite stations connected
		// to ICI+.
		static readonly ADDRESS_RANGE_ICM_STATION = [{ min: 0, max: 0x3FFFFF }];
		static readonly ADDRESS_RANGE_ICM_STATION_WITH_IFX = [{ min: 0, max: 0x3FFFFF }, {min: 0xC30000, max: 0xC3FFFF}];
		// RB-10607: Decoder stations address range
		static readonly ADDRESS_RANGE_LDISDI_STATION = [{ min: 0, max: 65534 }];
		static readonly ALERT_DATE_COLUMN_WIDTH = { min: 150, max: 175 };
		static readonly ALERT_DESCRIPTION_COLUMN_WIDTH = { min: 155, max: 155 };
		static readonly ALERT_SITE_COLUMN_WIDTH = { min: 200, max: 200 };
		static readonly ALERT_TYPE_COLUMN_WIDTH = { min: 135, max: 135 };
		static readonly AREA_DEFAULT_UI_SETTINGS = { fillColor: '#588f27', fillOpacity: 70, lineColor: '#000', lineOpacity: 100, lineWidth: 3 };
		static readonly AREA_NUMBER_RANGE = { min: 1 };
		static readonly SUBAREA_ADJUST = { min: 0, max: 300, default: 100 };
		static readonly ASCII_NUMBER_RANGE = { zero: 48, nine: 57 };
		static readonly CAPACITY_RANGE = { min: 0, max: 9999 };
		static readonly COM_PORT = 'COM';
		static readonly COVER_PAGE = { left: 20, height: 600, width: 435, top: 20 };
		static readonly DATE_RANGE = {
			none: 0,
			monthToDate: 1, oneDay: 2, threeDays: 3, oneWeek: 4, thirtyDays: 5, threeMonths: 6,
			sixMonths: 7, yearToDate: 8, oneYear: 9, custom: 10, alarm: 11, nintyDays: 12, oneEightyDays: 13, threesixtyFiveDays: 14,
			twelveHours: 15, twelveHours_wm: 16, oneDay_wm: 17, oneWeek_wm: 18, thirtyDays_wm: 19, nintyDays_wm: 20, oneEightyDays_wm: 21,
			threesixtyFiveDays_wm: 22, monthToDate_wm: 23, yearToDate_wm: 24, sixtyDays: 25, allAlarms: 26
		};
		static readonly DATE_RANGE_HOURS = {
			twelveHours: 12, oneDay: 24, threeDays: 72
		};
		static readonly DEBOUNCE_TIME_MS = 500;
		static readonly DEFAULT_CONNECTION_TYPE_ID = 4;
		static readonly DEFAULT_ROW_HEIGHT = 48;
		static readonly DEFAULT_SIMULTANEOUS_STATIONS = 10;
		static readonly DOUBLE_ROW_HEIGHT = 70;
		static readonly DRY_RUN_WARNING_MAX_FLOW_RANGE = { min: 0, max: 999 };
		static readonly DURATION_RANGE = { min: 0, max: 255 };
		static readonly DURATION_DAYS = { min: 0, max: 4 };
		static readonly DURATION_HOURS = { min: 0, elevenHrs: 11, twelveHrs: 12, max: 24, ninetySixHrs: 96, };
		static readonly DURATION_MINUTES = { min: 0, max: 59, maxSensorSettlingTime: 10 };
		static readonly DURATION_SECONDS = { min: 0, max: 59 };
		static readonly EMAIL =  {
			pattern: '^[^\\s@]{1,64}@[^\\s@.]+\\.[^\\s@]+([\\.][^\\s@]+)?$',
			minLength: 1,
			maxLength: 254
		};
		static readonly EMAIL_OPTIONAL =  {
			pattern: '^$|^[^\\s@]{1,64}@[^\\s@.]+\\.[^\\s@]+([\\.][^\\s@]+)?$',
			minLength: 0,
			maxLength: 254
		};
		static readonly ICI_MAX_STATIONS_PER_WIREPATH = 750;
		static readonly STEP = 1;
		static readonly PADDING_ZERO = 2;
		static readonly ASCII_SPECIAL_CHAR = { zero: 48, nine: 57 };
		static readonly REPORT_PAGE = { top: 20, left: 20, header: 200, width: 400, height: 295,
			headerTextLineHeightAdjustment: 8,
			chartColors: [ '#80c3ac', '#008658', '#f8d69d' ],
			chartAlternateColors: [ '#c4c4c4', '#7a7a7a', '#e8e8e8'],
			primary: '#008658',
			color: '#008658',
			textColor: '#000000',
			overrunColor: '#fbaea5',
			lightGreenColor: '#80c3ac',
			lineLighterGray: '#777777',
			tableSuperHeaderColorHex: '#efa535',
			tableSuperHeaderColorRGB: [239, 165, 53],
			tableHeaderColorHex: '#f5c576',
			alternatingTableHeaderColorHex: '#e8e8e8',
			tableHeaderColorRGB: [245, 197, 118],
			alternatingRowColorHex: '#f8d69d',
			alternatingRowColorRGB: [248, 214, 157],
			grayText: '#888888',
			grayBackground: [196, 196, 196],
			primaryBackground: [0, 134, 88],
			whiteColorHex: '#FFF',
			lineColor: [160, 160, 160],
			level1ColorHex: '#008658',
			level2ColorHex: '#c4c4c4',
			level3ColorHex: '#e8e8e8',
			level1ColorRGB: [0, 134, 88],
			level2ColorRGB: [196, 196, 196],
			level3ColorRGB: [232, 232, 232],
		};

		static readonly WATER_SUMMARY_DASHBOARD_WIDGET = {
			barColor: '#1976d2',
			textColor: '#FFF',
			gridLineColor: '#5a5b5c',
			axisFontSize: '9px',
			tooltipFontSize: '18px',
			fontFamily: 'Verdana, sans-serif',
		};

		static readonly FLO_GRAPH_DASHBOARD_WIDGET = {
			lineColor: '#1976d2',
			textColor: '#FFF',
			gridLineColor: '#5a5b5c',
			markerFillColor: '#eea148',
			markerStrokeColor: 'white',
			axisFontSize: '9px',
			axisTitleFontSize: '12px',
			tooltipFontSize: '10px',
			fontFamily: 'Verdana, sans-serif',
		};

		static readonly FLO_GRAPH_ACTIVE_CHART_GOLF = {
			textColor: '#000000',
			tooltipFontSize: '10px',
			fontFamily: 'Verdana, sans-serif',
		};

		static readonly FLO_GRAPH_TRACE_COLORS = [
			'#E6194B',
			'#000000',
			'#3CB44B',
			'#000075',
			'#FFE119',
			'#911EB4',
			'#A9A9A9',
			'#F58231',
			'#469990',
			'#9A6324',
			'#808000',
			'#42D4F4',
			'#F032E6',
		];

		static readonly RAINWATCH_DASHBOARD_WIDGET = {
			barColor: '#1976d2',
			textColor: '#FFF',
			gridLineColor: '#5a5b5c',
			axisFontSize: '9px',
			tooltipFontSize: '18px',
			fontFamily: 'Verdana, sans-serif',
			pauseLineColor: '#f0b648',
			shutdownLineColor: '#de3f51',
			pauseRainfallColor: '#f0b648',
			shutdownRainfallColor: '#de3f51',
			shutdownRainfallBarColor: '#d57e7e',
			rainfallColor: '#2c91e3',
			rainbarContainerColor: '#3e3e3e',
			dataLabelTextColor: '#303030',
			dataLabelTextShutdownColor: '#d57e7e',
			borderColor: '#303030',
		};

		static readonly FLOW_SENSOR_MODEL = { custom: 8, FS350B: 6, FS350SS: 7 };
		static readonly FLOW_SETTING_TIME = { min: 1, max: 10 };
		static readonly FLOWRATE_PRECISION = { golf: 3, commercial: 2, master_valve: 1 };
		static readonly FLOWRATE_RANGE_GOLF = { min: 0.0, max: 999.999, step: 0.001, default: 10.0};
		static readonly FLOWRATE_RANGE_COMMERCIAL = { min: 0.0, max: 999.99, universalMax: 9999.99, master_valve_max: 999.9,
			master_valve_universalMax: 9999.9, step: 0.01, default: 10.0,  };
		static readonly GROUP_LEVEL = { RootAdmin: 35, Admin: 30, PowerUser: 20, ReadOnlyUser: 2 };
		static readonly HERTZ = [{ key: 60, value: '60Hz' }, { key: 50, value: '50Hz' }];
		static readonly HIGH_THRESHOLD = { min: 105, max: 200 };
		static readonly IP_NAME = '0.0.0.0';
		static readonly IP_PORT = 50005;
		static readonly KFACTOR_RANGE = { min: 0.001, max: 999.999, default: 1.000, lxd_default: 0.001 };
		static readonly LXME_FLOZONE_NAME = 'FloZone™ 1';
		static readonly MAX_START_TIMES_DEFAULT = { max: 8 };
		static readonly MAX_START_TIMES_LXME2 = { max: 10 };
		static readonly MAX_START_TIMES_ESPISK = { max: 4 };
		static readonly MAX_START_TIMES_TM2 = { max: 4 };
		static readonly MAX_START_TIMES_ESPME = { max: 6 };
		static readonly MAX_START_TIMES_FIRMWARE_SCHEDULED = { max: 6 };
		static readonly MOBILE_NAVIGATION_NAME = { controller: 'Controllers', station: 'Stations', programs: 'Programs', schedules: 'Schedules',
			subBranches: 'SubBranches' };
		static readonly MONTH_NUMBER = { jan: 1, feb: 2, mar: 3, apr: 4, may: 5, jun: 6, jul: 7, aug: 8, sep: 9, oct: 10, nov: 11, dec: 12 };
		static readonly MONTH_FORM_CONTROL_LABEL = { jan: 'jan', feb: 'feb', mar: 'mar', apr: 'apr', may: 'may',
			jun: 'jun', jul: 'jul', aug: 'aug', sep: 'sep', oct: 'oct', nov: 'nov', dec: 'dec' };
		static readonly MONTHLY_FLOW_REPORT_PAGE = {
			header: 300, width: 250, currentColor: '#008658', previousColor: '#80b579',
			last30DayColor: '#c49753', prior30DayColor: '#e1c672'
		};
		static readonly ARC_VALIDATION = { minAdjustable: 30, maxIntermediate: 345, maxAdjustable: 360, midAdjustable: 180 };
		static readonly NETWORK_ADDRESS_RANGE = { min: 2, max: 256 };
		static readonly OFFSET_INPUT_RANGE = { min: -99.999, max: 99.999, default: 0 };
		static readonly QUICK_CHECK_DURATION_RANGE = { min: 1, max: 99 };
		static readonly PIN_CODE_PADDING_ZERO = 4;
		static readonly PIN_CODE_RANGE = { min: 1, max: 9999 };
		static readonly PIN_LOCK_OUT_TYPE = { none: 0, partial: 1, fullAccess: 2 };
		static readonly PIPE_DIAMETER_RANGE = { min: 2.9, max: 12, default: 2.9 };
		static readonly PROGRAM_ADJUST = 100;
		static readonly PROGRAM_GROUP_ADJUST = 100;
		static readonly PROGRAM_SHORTNAME_COLUMN_WIDTH = 120;
		static readonly PROGRAM_TYPES_TO_CONSIDER = [0, 2, 3, 4, 5];
		static readonly ROOT_ADMIN_ID = 35;
		static readonly REFERENCES_ET_INCH = { min: 0.01, max: 0.99 };
		static readonly REFERENCES_ET_MM = { min: 0.25, max: 25.15 };
		static readonly SELF_THRESHOLD = { min: 1, max: 95 };
		static readonly SIMULSTATIONS_RANGE = { min: 1, max: 8 };
		static readonly SIMULSTATIONS_RANGE_LXME = { min: 1, max: 5 };
		static readonly SIMULSTATIONS_RANGE_LXIVMPLUS = { min: 1, max: 16 };
		static readonly NON_SIMULSTATIONS_RANGE_LXME2 = { min: 1, max: 5 };
		static readonly SIMULSTATIONS_RANGE_FOR_GOLF = { min: 1, max: 40 };
		static readonly SOLENOID_TYPES = [
			{ name: 'SOLENOID_TYPES.PGA_PEB_PESB_GB_EFB_CP', id: 'PGA_PEB_PESB_GB_EFB_CP', value: 0, defaultSwitchCode: '59F370' },
			{ name: 'SOLENOID_TYPES.BPEB_BPES_GOLF_VIH_ROTORS', id: 'BPEB_BPES_GolfVIHRotors', value: 1, defaultSwitchCode: '59FA50' },
			{ name: 'SOLENOID_TYPES.PUMP_START_RELAY', id: 'PumpStartRelay', value: 2, defaultSwitchCode: '59F390' },
			{ name: 'SOLENOID_TYPES.CUSTOM', id: 'Custom', value: 3, defaultSwitchCodeCommercial: '59F370', defaultSwitchCodeGolf: '59F370' },
		];
		static readonly GOLF_DEFAULT_SWITCH_CODE = "59FA50";
		static readonly GOLF_DEFAULT_SWITCH_CODE_VALVE_TYPE = 0;
		static readonly HEXADECIMAL_PATTERN = "^[a-fA-F0-9]+$";
		static readonly SOLENOIDS_QUANTITY = [{ key: 0, value: 1 }, { key: 1, value: 2 }];
		static readonly STATION_MAX_RUN_TIME_HOURS = { golf: 12, commercial: 12 };
		static readonly STATION_MAX_CYCLE_TIME_HOURS = { golf: 12, commercial: 1 };
		static readonly STATION_MAX_SOAK_TIME_HOURS = { golf: 12, commercial: 1 };
		static readonly STATION_MIN_SOAK_TIME_SECONDS = { golf: 60, commercial: 0 };
		static readonly STATION_NUMBERS = [16, 24, 32, 40, 48, 56, 64, 72];
		static readonly STEPS_CAPACITY_RANGE = { min: 1, max: 9999 };
		static readonly SWITCH_CODE_RANGE = { min: 0x300000, max: 0x5FFFFF };
		static readonly THRESHOLD = { min: 0, max: 95 };
		static readonly TERMINAL_COLUMN_WIDTH = 120;
		static readonly TIME_PERIOD = {
			currentStart: 'currentStart', currentEnd: 'currentEnd',
			previousStart: 'previousStart', previousEnd: 'previousEnd'
		};
		static readonly VOLTAGE_HIGH_RANGE = { min: 40, max: 100 };
		static readonly VOLTAGE_LOW_RANGE = { min: 0, max: 39 };
		static readonly ROUTE_TYPE = { hole: 'hole', siteconfig: 'siteconfig', area: 'area' };
		static readonly CONTROLLER_TYPE_FREQUENCY = 60;
		static readonly CHANNEL_LIMIT = { channelA: 0, channelB: 24, channelC: 48, channelCount: 28 }; // ? used in satellite configuration
		static readonly IQ_NET_CLIENT = 'IQNet™ Client';
		static readonly IQ_NET_SERVER = 'IQNet™ Server';
		static readonly STATIONS_PRIORITY = { nonIrrigation: 0 };
		static readonly ALERT_FIXED_COLUMN_WIDTH = { min: 100, max: 100 };
		static readonly RainFallSource = [{ name: 'Use Weather Source', id: 1 }, { name: 'Rain Gauge', id: 2 }];
		static readonly DECIMAL_SEPARATOR = '.';
		static readonly COMMA_SEPARATOR = ',';
		static readonly DECIMAL_ASCII_VALUE = '46';
		static readonly VIEW_ET_CALENDAR = 'viewETCalendar';
		static readonly VIEW_PDF_ET_CALENDAR_MARGIN = {horizontal: 30, vertical: 10};

		static readonly UNITS_OF_MEASURE_FLOW = {
			litersPerSecondToCubicMetersPerHour: 3.60,
			litersPerSecondToLitersPerMin: 60,
			litersPerSecondToGallonsPerMin: 15.8503222,
			cubicMetersPerHourToLitersPerSecond: 0.277778,
			cubicMetersPerHourToLitersPerMin: 16.67,
			cubicMetersPerHourToGallonsPerMin: 4.40286186,
			litersPerMinToLitersPerSecond: 0.0166667,
			litersPerMinToCubicMetersPerHour: 0.060,
			litersPerMinToGallonsPerMin: 0.264172176857989,
			gallonsPerMinToLitersPerSecond: 0.0630902,
			gallonsPerMinToCubicMetersPerHour: 0.227125,
			gallonsPerMinToLitersPerMin: 3.78541,
		};

		static readonly UNITS_OF_MEASURE_VOLUME = {
			litersToCubicMeters: 0.001,
			litersToGallons: 0.264172,
			cubicMetersToLiters: 1000,
			cubicMetersToGallons: 264.172037,
			gallonsToLiters: 3.785412,
			gallonsToCubicMeters: 0.003785412,
		};

		static readonly UNITS_OF_MEASURE_LENGTH = {
			footToMeter:  0.3048,
			inchToMillimeter: 25.4,
			mileToKilometer: 1.60934,
			inchToCentimeter: 2.54,
			meterToFoot:  3.28084,
			millimeterToInch: 0.0393701,
			kilometerToMile: 1.60934,
			centimeterToInch: 0.393701,
		};

		static readonly UNITS_OF_MEASURE_PRESSURE = {
			psiToBar:  0.0689476,
			barToPsi:  14.5038,
		};

		static readonly UNITS_OF_MEASURE_AREA = {
			squareMetersToSquareFeet: 10.7639104,
			squareMetersToSquareYards: 1.19599005,
			squareYardsToSquareMeters: 0.83612736,
			squareFeetToSquareMeters: 0.09290304,
		};

		static readonly PHONE_NUMBER = {
			minLength: 10,
			maxLength: 15,
			maxTotalLength: 25,
		};

		static readonly EMAIL_SETTINGS = {
			defaultSmtpPortNumber: 25,
		};
		static readonly TIME_FORMAT = { hours: 24, amPm: 12 };
		static readonly TIME_FORMAT_OPTIONS = { hours: '12:00', amPm: '12:00 a'};
		static readonly TIME_FORMAT_DISPLAY_OPTIONS = { hours: 'HH:mm', amPm: 'h:mm a'};
		static readonly TIME_FORMAT_WITH_SECONDS_DISPLAY_OPTIONS = { hours: 'H:mm:ss', amPm: 'h:mm:ss a'};
		static readonly DATE_FORMAT = {
			MMDDYYYY_Dash: 'MM-dd-yyyy',
			MMDDYYYY_Slash: 'MM/dd/yyyy',
			MMDDYYYY_Dot: 'MM.dd.yyyy',
			DDMMYYYY_Dash: 'dd-MM-yyyy',
			DDMMYYYY_Slash: 'dd/MM/yyyy',
			DDMMYYYY_Dot: 'dd.MM.yyyy',
			YYYYMMDD_Dot: 'yyyy/MM/dd',
		};
		static readonly TOAST_TIME = 5000;
		static readonly UNIQUE_NAMES = {
			weatherSource: 'weatherSource', site: 'site', controller: 'controller',
			user: 'user', email: 'email', stationAddress: 'stationAddress',
			area: 'area', areaNumber: 'areaNumber', areaShortName: 'areaShortName',
			subarea: 'subarea',
			stationName: 'stationName', selectedSensor: 'selectedSensor',
			tbos: 'tbos',
			program: 'program', programGroup: 'programGroup', flowElement: 'flowElement',
			sensorAddress: 'sensorAddress', sensorName: 'sensorName', clientAddress: 'clientAddress', pinNumber: 'pinNumber',
			programNumber: 'programNumber', programGroupNumber: 'programGroupNumber',
		};
		static readonly USER_LENGTH_RANGE = { min: 0, max: 255 };
		static readonly VALVE_TYPE_OPTIONS = ['V1', 'V2', 'V3', 'V4', 'V5'];
		static readonly  VERSION_CHECK_FREQUENCY = 1000 * 60 * 5; // in milliseconds, defaults to 5 minutes
		static readonly WATER_ADJUST_RANGE = { min: 0, max: 300 };
		static readonly GPRS_LICENSE = {info: 90, expiring: 10, expired: 0, not_expired: -1 };
		static readonly SYSTEM_STATUS_COLOR = {
			orange: '#f57336', green: '#588f27', yellow: '#f3b543', red: '#b9121b', white: '#ffffff', blue: '#365FB7'
		};
		static readonly SYSTEM_STATUS_DASH_BOARD_COLOR = {
			orange: '#f57336', green: '#8bc34a', yellow: '#f3b543', red: '#b9121b', white: '#e5e5e5', blue: '#00C1FF',
			darkGreen: '#008757', lightBlue: '#C9F7FF'
		};
		static readonly SYSTEM_STATUS_BACKGROUND = {
			orange: 'linear-gradient(to bottom, #f77a52, #f57336)', green: 'linear-gradient(to bottom, #8bc34a, #588f27)',
			yellow: 'linear-gradient(to bottom, #ffd34e, #f3b543)', red: 'linear-gradient(to bottom, #de4051, #b9121b)',
			white: 'linear-gradient(to bottom, #ffffff, #e5e5e5)', blue: 'linear-gradient(to bottom, #1374CE, #365FB7)',
		};
		static readonly SYSTEM_STATUS = {
			pause: 'pause', resume: 'resume', off: 'off', on: 'on', cancel: 'cancel', shutdownMins: 'shutdownMins',
			overrideFZ: 'overrideFZ'
		};
		static readonly ManualWeatherSource = { name: 'Manual Entry', id: 53 };
		static readonly CURRENT_ET = { min: 0.00, max: 99.00 };
		static readonly SYSTEM_FLO_GUARD_RANGE = { min: 100, max: 5000 };
		static readonly DEFAULT_INDICATOR_MAX_RANGE = 10;
		static readonly FLOW_LOG_GRAPH_TIME_PERIOD_GOLF = 31;
		static readonly VOLUME = { min: 0.01, max: 10.0 };
		static readonly PASSES = { min: 0.25, max: 999.00 };
		static readonly DEFAULT_TEMPORARY_ADJUST = 100;
		static readonly ET_CHECKBOOK_AMOUNT_RANGE = { min: 0.00, max: 99.999 };
	// default values for golf sprinkler
		static readonly DEFAULT_SPRINKLER_CATEGORY = RbEnums.Common.SprinklerCategoryType.Rotors;
		static readonly DEFAULT_SPRINKLER_SORT_ORDER = ['Rain Bird', 'EAGLE'];
		static readonly DEFAULT_PRESSURE = 70;
		static readonly CUSTOM_ICON_NUMBER = {	standard: 0, resume: 1, simulate: 2, pending_actions: 3, manual_sensor_read: 4, mdi_icons: 5, rb_icons: 6 };
		static readonly DEFAULT_TEMPORARY_ADJUST_DAY = 0;
		static readonly TRIGGER_SYMBOL_COLORS = [
			'#2a2b2c', '#5a5b5c', '#8a8b8c', '#babbbc', '#008658', '#79bd8f',
			'#d9cb9e', '#b9121b', '#f57336', '#f3b543', '#588f27', '#1976d2'
		];
		static readonly DEFAULT_RETRIEVAL_TIME = { time: '5:00pm', format: 'h:mma' };
		static readonly ESP_MC_SUBCHANNELS = [{value: 'A', name: 'A'}, {value: 'B', name: 'B'}];
		static readonly ADD_STATION_COUNT = { min: 1, max: 200 };
		static readonly MASTER_VALVE_DELAY_TIME_HOURS = 1;
		static readonly HEADS_PER_STATION = { min: 1, max: 100000, default: 1 };
		static readonly SENSOR_SECONDSPERUPDATE_RANGE = { min: 5, max: 300, default: null };
		static readonly WEATHER_DATA_RAIN_TOTAL_RANGE = { min: 0, max: 10.99 };
		static readonly WIREPATHS = [
			{ value: 1, name: '1' },
			{ value: 2, name: '2' },
			{ value: 3, name: '3' },
			{ value: 4, name: '4' }
		];
		static readonly CALENDAR_YEAR_RANGE = 2;

		static readonly POWER_LINE_FREQUENCY_HERTZ = { sixtyHz: 60 };

		static readonly DECODER_INTERFACE_DEFAULT_MAX_SOLENOIDS = 20;
		static readonly DECODER_INTERFACE_MAX_SOLENOIDS = 200;
		static readonly DECODER_INTERFACE_MIN_SOLENOIDS = 1;
		static readonly DECODER_INTERFACE_SDI_MAX_SOLENOIDS = 15;
		static readonly DECODER_INTERFACE_MDI_MAX_SOLENOIDS = 40;
		static readonly DECODER_INTERFACE_LDI_MAX_SOLENOIDS = 40;
		static readonly DECODER_INTERFACE_NAME_SDI = "SDI";
		static readonly DECODER_INTERFACE_NAME_MDI = "MDI";
		static readonly DECODER_INTERFACE_NAME_LDI = "LDI";
		static readonly DECODER_INTERFACE_MIN_CURRENT_SAMPLES = 4;
		static readonly DECODER_INTERFACE_MAX_CURRENT_SAMPLES = 15;	// Only one hex digit is used so F is the maximum.
		static readonly DECODER_INTERFACE_MIN_CURRENT_INTERVAL_MS = 20;
		static readonly DECODER_INTERFACE_MAX_CURRENT_INTERVAL_MS = 80;

		static readonly SATELLITE_CAPABILITIES = {
			[RbEnums.Common.DeviceType.ESPSAT]: {
				// IQI no need to separate powerLineFrequency, use _60Hz as the default setting
				MULTI_STATION_MAX_60Hz: 8,
				SOLENOIDS_MAX_60Hz: 9,

				MULTI_STATION_MAX_50Hz: 0,
				SOLENOIDS_MAX_50Hz: 0,

				MAX_STATION_COUNT: 40
			},
			[RbEnums.Common.DeviceType.ESP_MC]: {
				LINK: {
					MULTI_STATION_MAX_60Hz: 8,
					SOLENOIDS_MAX_60Hz: 9,

					MULTI_STATION_MAX_50Hz: 6,
					SOLENOIDS_MAX_50Hz: 6,

					MAX_STATION_COUNT: 40
				},
				MULTI_STATION_MAX_60Hz: 8,
				SOLENOIDS_MAX_60Hz: 9,

				MULTI_STATION_MAX_50Hz: 6,
				SOLENOIDS_MAX_50Hz: 6,

				MAX_STATION_COUNT: 40
			},
			[RbEnums.Common.DeviceType.PAR_ES]: {
				LINK: {
					MULTI_STATION_MAX_60Hz: 12,
					SOLENOIDS_MAX_60Hz: 16,

					MULTI_STATION_MAX_50Hz: 12,
					SOLENOIDS_MAX_50Hz: 12,

					MAX_STATION_COUNT: 72
				},
				MULTI_STATION_MAX_60Hz: 16,
				SOLENOIDS_MAX_60Hz: 16,

				MULTI_STATION_MAX_50Hz: 14,
				SOLENOIDS_MAX_50Hz: 14,

				MAX_STATION_COUNT: 72
			},
			[RbEnums.Common.DeviceType.PARplus]: {
				LINK: {
					MULTI_STATION_MAX_60Hz: 8,
					SOLENOIDS_MAX_60Hz: 12,

					MULTI_STATION_MAX_50Hz: 8,
					SOLENOIDS_MAX_50Hz: 10,

					MAX_STATION_COUNT: 48
				},
				MULTI_STATION_MAX_60Hz: 8,
				SOLENOIDS_MAX_60Hz: 12,

				MULTI_STATION_MAX_50Hz: 8,
				SOLENOIDS_MAX_50Hz: 10,

				MAX_STATION_COUNT: 48
			},
			[RbEnums.Common.DeviceType.MSCplus]: {
				LINK: {
					MULTI_STATION_MAX_60Hz: 8,
					SOLENOIDS_MAX_60Hz: 16,

					MULTI_STATION_MAX_50Hz: 8,
					SOLENOIDS_MAX_50Hz: 12,

					MAX_STATION_COUNT: 48	
				},
				MULTI_STATION_MAX_60Hz: 16,
				SOLENOIDS_MAX_60Hz: 16,

				MULTI_STATION_MAX_50Hz: 12,
				SOLENOIDS_MAX_50Hz: 12,

				MAX_STATION_COUNT: 48
			},
			DEFAULT: {
				MULTI_STATION_MAX_60Hz: 8,
				SOLENOIDS_MAX_60Hz: 8,

				MULTI_STATION_MAX_50Hz: 6,
				SOLENOIDS_MAX_50Hz: 6,

				MAX_STATION_COUNT: 24
			}
		};

		static getSatelliteCapabilities(satType: RbEnums.Common.DeviceType, linkInterface: boolean = false): {
				SOLENOIDS_MAX_60Hz: number, 
				MULTI_STATION_MAX_60Hz: number, 
				SOLENOIDS_MAX_50Hz: number, 
				MULTI_STATION_MAX_50Hz: number,
				MAX_STATION_COUNT: number
			}
		{
			const capabilities = linkInterface ? RbConstants.Form.SATELLITE_CAPABILITIES[satType].LINK : RbConstants.Form.SATELLITE_CAPABILITIES[satType];
			return capabilities != null ? capabilities : RbConstants.Form.SATELLITE_CAPABILITIES.DEFAULT;
		}


		static readonly LANDSCAPE_INFORMATION_ROOT_DEPTH_RANGE = {
			min: 0.5,
			max: 120
		};
		static readonly LANDSCAPE_INFORMATION_REFILL_PERCENTAGE_RANGE = {
			min: 0,
			max: 100
		};
		static readonly LANDSCAPE_INFORMATION_MAD_RANGE = {
			min: 0,
			max: 100
		};
		static readonly LANDSCAPE_INFORMATION_MAX_WATERING_ET_RANGE = {
			min: 0.01,
			max: 10
		};
		static readonly LANDSCAPE_INFORMATION_COEFFICIENT_RANGE = {
			min: 0.2,
			max: 2
		};
		
		static readonly LANDSCAPE_INFORMATION_PLANT_MATERIALS = [
			{ id: RbEnums.Common.PlantMaterial.Trees, name: 'LANDSCAPE_INFORMATION.TREES'},
			{ id: RbEnums.Common.PlantMaterial.Shrubs, name: 'LANDSCAPE_INFORMATION.SHRUBS'},
			{ id: RbEnums.Common.PlantMaterial.GroundCover, name: 'LANDSCAPE_INFORMATION.GROUND_COVER'},
			{ id: RbEnums.Common.PlantMaterial.Mixed, name: 'LANDSCAPE_INFORMATION.MIXED'},
			{ id: RbEnums.Common.PlantMaterial.CoolSeasonTurfgrass, name: 'LANDSCAPE_INFORMATION.COOL_SEASON_TURFGRASS'},
			{ id: RbEnums.Common.PlantMaterial.WarmSeasonTurfgrass, name: 'LANDSCAPE_INFORMATION.WARM_SEASON_TURFGRASS'}
		];
		static readonly LANDSCAPE_INFORMATION_SOIL_TYPES = [
			{ id: RbEnums.Common.SoilType.Clay, name: 'LANDSCAPE_INFORMATION.CLAY' },
			{ id: RbEnums.Common.SoilType.SiltyClay, name: 'LANDSCAPE_INFORMATION.SILTY_CLAY' },
			{ id: RbEnums.Common.SoilType.ClayLoam, name: 'LANDSCAPE_INFORMATION.CLAY_LOAM' },
			{ id: RbEnums.Common.SoilType.Loam, name: 'LANDSCAPE_INFORMATION.LOAM' },
			{ id: RbEnums.Common.SoilType.SandyLoam, name: 'LANDSCAPE_INFORMATION.SANDY_LOAM' },
			{ id: RbEnums.Common.SoilType.LoamySand, name: 'LANDSCAPE_INFORMATION.LOAMY_SAND' },
			{ id: RbEnums.Common.SoilType.Sand, name: 'LANDSCAPE_INFORMATION.SAND' }
		];
		static readonly LANDSCAPE_INFORMATION_FORECASTING_METHODS = [
			{ id: RbEnums.Common.ForecastingMethod.MAD, name: 'LANDSCAPE_INFORMATION.MAD' },
			{ id: RbEnums.Common.ForecastingMethod.DailyET, name: 'STRINGS.DAILY_ET' }
		];
		static readonly LANDSCAPE_INFORMATION_OPTIONS_BY_SOIL_TYPE = {
			[`${RbEnums.Common.SoilType.Clay}`]: {
				defaultValues: {
					mad: 30,
					aw: 0.17
				}
			},
			[`${RbEnums.Common.SoilType.ClayLoam}`]: {
				defaultValues: {
					mad: 40,
					aw: 0.18
				}
			},
			[`${RbEnums.Common.SoilType.SiltyClay}`]: {
				defaultValues: {
					mad: 40,
					aw: 0.17
				}
			},
			[`${RbEnums.Common.SoilType.Loam}`]: {
				defaultValues: {
					mad: 50,
					aw: 0.17
				}
			},
			[`${RbEnums.Common.SoilType.SandyLoam}`]: {
				defaultValues: {
					mad: 50,
					aw: 0.12
				}
			},
			[`${RbEnums.Common.SoilType.LoamySand}`]: {
				defaultValues: {
					mad: 50,
					aw: 0.08
				}
			},
			[`${RbEnums.Common.SoilType.Sand}`]: {
				defaultValues: {
					mad: 60,
					aw: 0.06
				}
			}
		};
		static readonly LANDSCAPE_INFORMATION_OPTIONS_BY_PLANT_MATERIAL = {
			[`${RbEnums.Common.PlantMaterial.Trees}`]: {
				defaultValues: {
					rootDepth: 12,
					landscapeCoefficient: 0.5
				},
				landscapeCoefficientOptions: {
					species: {
						high: 0.9,
						medium: 0.5,
						low: 0.2
					},
					density: {
						high: 1.3,
						medium: 1.0,
						low: 0.5
					},
					microClimate: {
						high: 1.4,
						medium: 1.0,
						low: 0.5
					}
				}
			},
			[`${RbEnums.Common.PlantMaterial.Shrubs}`]: {
				defaultValues: {
					rootDepth: 8,
					landscapeCoefficient: 0.5
				},
				landscapeCoefficientOptions: {
					species: {
						high: 0.7,
						medium: 0.5,
						low: 0.2
					},
					density: {
						high: 1.1,
						medium: 1.0,
						low: 0.5
					},
					microClimate: {
						high: 1.3,
						medium: 1.0,
						low: 0.5
					}
				}
			},
			[`${RbEnums.Common.PlantMaterial.GroundCover}`]: {
				defaultValues: {
					rootDepth: 6,
					landscapeCoefficient: 0.5
				},
				landscapeCoefficientOptions: {
					species: {
						high: 0.9,
						medium: 0.5,
						low: 0.2
					},
					density: {
						high: 1.1,
						medium: 1.0,
						low: 0.5
					},
					microClimate: {
						high: 1.2,
						medium: 1.0,
						low: 0.5
					}
				}
			},
			[`${RbEnums.Common.PlantMaterial.Mixed}`]: {
				defaultValues: {
					rootDepth: 6,
					landscapeCoefficient: 0.55
				},
				landscapeCoefficientOptions: {
					species: {
						high: 0.9,
						medium: 0.5,
						low: 0.2
					},
					density: {
						high: 1.3,
						medium: 1.1,
						low: 0.6
					},
					microClimate: {
						high: 1.4,
						medium: 1.0,
						low: 0.5
					}
				}
			},
			[`${RbEnums.Common.PlantMaterial.CoolSeasonTurfgrass}`]: {
				defaultValues: {
					rootDepth: 3,
					landscapeCoefficient: 0.7
				},
				landscapeCoefficientOptions: {
					species: {
						high: 0.8,
						medium: 0.7,
						low: 0.6
					},
					density: {
						high: 1.0,
						medium: 1.0,
						low: 0.6
					},
					microClimate: {
						high: 1.2,
						medium: 1.0,
						low: 0.8
					}
				}
			},
			[`${RbEnums.Common.PlantMaterial.WarmSeasonTurfgrass}`]: {
				defaultValues: {
					rootDepth: 3,
					landscapeCoefficient: 0.7
				},
				landscapeCoefficientOptions: {
					species: {
						high: 0.8,
						medium: 0.7,
						low: 0.6
					},
					density: {
						high: 1.0,
						medium: 1.0,
						low: 0.6
					},
					microClimate: {
						high: 1.2,
						medium: 1.0,
						low: 0.8
					}
				}
			}
		};
		static readonly LANDSCAPE_INFORMATION_COEFFICIENT_OPTIONS = {
			species: {
				high: 0,
				medium: 0.7,
				low: 0
			},
			density: {
				high: 0,
				medium: 1,
				low: 0
			},
			microClimate: {
				high: 0,
				medium: 1,
				low: 0
			}
		};
		static readonly LANDSCAPE_INFORMATION_GRAPHIC_DEFAULT_VALUES = {
			refillPercentage: 20,
			mad: 50,
			maxWateringET: 1,
			paw: 0.5,
			species: 0.7,
			density: 1,
			microClimate: 1,
			balance: 0.35
		};
		static readonly DECIMAL_NUMBER_PATTERN_WITH_DOT = /^(-[0-9]|[0-9]){0,}(\.[0-9]*){0,1}$/;
		static readonly DECIMAL_NUMBER_PATTERN_WITH_COMMA = /^(-[0-9]|[0-9]){0,}(\,[0-9]*){0,1}$/;
		static readonly STATION_DIST_UNIFORMITY_OPTIONS  = {
			excellent: 80,
			good: 70,
			poor: 50
		};
		static readonly STATION_DIST_UNIFORMITY_RANGE = {
			min: 0,
			max: 100
		};
		static readonly STATION_ADJUST_RANGE = {
			min: 0,
			max: 300
		};
		static readonly STATION_SLOPE_OPTIONS = [
			{ value: RbEnums.Common.SlopeType.Slope_0_3, name: 'STRINGS.ZEROTOTHREE' },
			{ value: RbEnums.Common.SlopeType.Slope_4_6, name: 'STRINGS.FOURTOSIX' },
			{ value: RbEnums.Common.SlopeType.Slope_7_12, name: 'STRINGS.SEVENTOTWELVE' },
			{ value: RbEnums.Common.SlopeType.Slope_13, name: 'STRINGS.THIRTEENPLUSS' }
		];
		static readonly STATION_REQUIRES_MASTER_VALVE_YES = 'Yes';
		// time in minutes
		static readonly STATION_SCHEDULE_CHECK_MINUTES = 15;
		static readonly ADJUSTMENT_TYPE_LIST = [
			{
				id: RbEnums.Common.AdjustmentType.Deposit,
				name: 'Deposit',
				shownName: 'LANDSCAPE_INFORMATION.DEPOSIT',
				amount: null,
				percentage: null
			},
			{
				id: RbEnums.Common.AdjustmentType.Withdrawal,
				name: 'Withdrawal',
				shownName: 'LANDSCAPE_INFORMATION.WITHDRAWAL',
				amount: null,
				percentage: null
			},
			{
				id: RbEnums.Common.AdjustmentType.SetToFC,
				name: 'Set to Field Capacity',
				shownName: 'LANDSCAPE_INFORMATION.ADJUSTMENT_TYPE_SET_TO_FIELD_CAPACITY',
				amount: 0,
				percentage: 0
			},
			{
				id: RbEnums.Common.AdjustmentType.SetToRefill,
				name: 'Set to Refill',
				shownName: 'LANDSCAPE_INFORMATION.ADJUSTMENT_TYPE_SET_TO_REFILL',
				amount: 0,
				percentage: 0
			},
			{
				id: RbEnums.Common.AdjustmentType.SetToMAD,
				name: 'Set to MAD',
				shownName: 'LANDSCAPE_INFORMATION.ADJUSTMENT_TYPE_SET_TO_MAD',
				amount: 0,
				percentage: 0
			}
		];
		static readonly ADJUSTMENT_TYPE_LIST_FOR_UPDATE_BATCH = [
			{
				id: RbEnums.Common.ETCheckbookWaterLevel.Deposit,
				name: 'Deposit',
				shownName: 'LANDSCAPE_INFORMATION.DEPOSIT',
			},
			{
				id: RbEnums.Common.ETCheckbookWaterLevel.Withdrawal,
				name: 'Withdrawal',
				shownName: 'LANDSCAPE_INFORMATION.WITHDRAWAL',
			},
			{
				id: RbEnums.Common.ETCheckbookWaterLevel.SetToFC,
				name: 'Set to Field Capacity',
				shownName: 'LANDSCAPE_INFORMATION.ADJUSTMENT_TYPE_SET_TO_FIELD_CAPACITY_UPDATE_BATCH',
			},
			{
				id: RbEnums.Common.ETCheckbookWaterLevel.SetToRefill,
				name: 'Set to Refill',
				shownName: 'LANDSCAPE_INFORMATION.ADJUSTMENT_TYPE_SET_TO_REFILL_UPDATE_BATCH',
			},
			{
				id: RbEnums.Common.ETCheckbookWaterLevel.SetToMAD,
				name: 'Set to MAD',
				shownName: 'LANDSCAPE_INFORMATION.ADJUSTMENT_TYPE_SET_TO_MAD_UPDATE_BATCH',
			}		
			
		];
		static readonly ADVANCED_ET_PRECISION = {
			balance: 3,
			paw: 2,
			mad: 2,
			percentage: 2,
			rootDepth: 2,
			rootDepthMm: 1,
			maxWateringEt: 2,
			maxWateringEtMm: 1,
			coefficient: 2
		}

		static readonly SITE_PRIMARY_TAB = [
			{name: 'STRINGS.CONTROLLERS', id: RbEnums.Common.SitePrimaryTab.Controller},
			{name: 'STRINGS.LANDSCAPE_TYPES', id: RbEnums.Common.SitePrimaryTab.LandscapeTypes},
			{name: 'STRINGS.SPRINKLER_TYPES', id: RbEnums.Common.SitePrimaryTab.SprinklerTypes},
			{name: 'STRINGS.MAP', id: RbEnums.Common.SitePrimaryTab.Map}
		]

		static readonly CONTROLLER_PRIMARY_TAB = [
			{name: 'STRINGS.MANUAL_OPS_IRRIGATION_QUEUE', id: RbEnums.Common.ControllerPrimaryTab.ManualOpsIrrgationQueue},
			{name: 'STRINGS.MANUAL_OPS_EVENTS', id: RbEnums.Common.ControllerPrimaryTab.ManualOpsEvents},
			{name: 'STRINGS.MANUAL_OPS_DIAGNOSTICS', id: RbEnums.Common.ControllerPrimaryTab.ManualOpsDiagnostics},
			{name: 'STRINGS.PROGRAMS', id: RbEnums.Common.ControllerPrimaryTab.Programs},
			{name: 'STRINGS.DRY_RUN', id: RbEnums.Common.ControllerPrimaryTab.Dryrun},
			{name: 'STRINGS.STATIONS', id: RbEnums.Common.ControllerPrimaryTab.Stations},
			{name: 'STRINGS.MASTER_VALVES', id: RbEnums.Common.ControllerPrimaryTab.MasterValves},
			{name: 'STRINGS.SENSORS', id: RbEnums.Common.ControllerPrimaryTab.Sensors},
			{name: 'STRINGS.MAP', id: RbEnums.Common.ControllerPrimaryTab.Map},
		]

		// RB-10607: The table on the epic has these specifications.
		static readonly DECODER_MODEL_TYPES = [
			{ value: RbEnums.Common.DecoderModelType.FD101, name: 'FD101', maxAddressesPerDecoder: 1, currentDrawAtRest: 0.5 },
			{ value: RbEnums.Common.DecoderModelType.FD102, name: 'FD102', maxAddressesPerDecoder: 1, currentDrawAtRest: 0.5 },
			{ value: RbEnums.Common.DecoderModelType.FD202, name: 'FD202', maxAddressesPerDecoder: 2, currentDrawAtRest: 1 },
			{ value: RbEnums.Common.DecoderModelType.FD401, name: 'FD401', maxAddressesPerDecoder: 4, currentDrawAtRest: 1 },
			{ value: RbEnums.Common.DecoderModelType.FD601, name: 'FD601', maxAddressesPerDecoder: 6, currentDrawAtRest: 1 },
		];

		static readonly DECODER_SOLENOIDS = [
			{ value: 1, name: '1' },
			{ value: 2, name: '2' }
		];

		static readonly SYSTEMS = [
			{ value: 0, name: 'STRINGS.ALL' },
			{ value: 1, name: 'STRINGS.LDISDI' },
			{ value: 2, name: 'STRINGS.ICI' },
			{ value: 3, name: 'STRINGS.MIM-LINK' },
			{ value: 4, name: 'STRINGS.MIM' },
		];

		static readonly DEFAULT_SEASONAL_ADJUST_DATA = { 
			periodicReferenceInchValueInitialized: false, 
			referenceInchValueInitialized: false, 
			adjustTypeId: 3, 
			aprAdjust: 100, 
			aprInchValue: 0.01, 
			augAdjust: 100, 
			augInchValue: 0.01, 
			currentAdjustPct: 100, 
			dailySaValue: 100, 
			decAdjust: 100, 
			decInchValue: 0.01, 
			febAdjust: 100, 
			febInchValue: 0.01, 
			janAdjust: 100, 
			janInchValue: 0.01, 
			julAdjust: 100, 
			julInchValue: 0.01, 
			junAdjust: 100, 
			junInchValue: 0.01, 
			marAdjust: 100, 
			marInchValue: 0.01, 
			mayAdjust: 100, 
			mayInchValue: 0.01, 
			novAdjust: 100, 
			novInchValue: 0.01, 
			octAdjust: 100, 
			octInchValue: 0.01, 
			periodicReferenceInchValue: 0.01, 
			periodicValue: 0.01, 
			referenceInchValue: 0.01, 
			sepAdjust: 100, 
			sepInchValue: 0.01 }

		static readonly MASTER_VALVE_MAX_SHARING_IVM_PRO = 5;
		static readonly WEATHER_SENSOR_MAX_SHARING_IVM_PRO = 3;
	}
}

import { Component, Input } from '@angular/core';

import { CellClassParams } from 'ag-grid-community';

export class ColumnFilterDefaultValue {
	constructor(public type: string, public filter: any, public filterTo: any = null) {
	}
}
@Component({
	selector: 'rb-column',
	templateUrl: './column.component.html',
	styleUrls: ['./column.component.scss'],
})
export class ColumnComponent {
	@Input() cellClass: string | string[] | ((cellClassParams: CellClassParams) => (string | string[]));
	@Input() field: string;

	/**
	 * @summary The text value to be displayed in the column header. This is a simple string value, not HTML. Should
	 * be mutually exclusive with headerComponentParams (which is used to pass a complete HTML template for the header
	 * cell to the rendering component).
	 */
	@Input() headerName: string;

	@Input() headerClass: string;

	/**
	 * @summary String value to be used for column header layout. See Simple Header Templates in the ag-grid documentation.
	 * This should return an object containing a "template" property. That property should be a string containing the HTML
	 * for the header, generally following the pattern shown below which is the default. ref="eText" is the normal label
	 * text rendered by the header.
	 * {
	 *		'template':
	 *		'<div class="ag-cell-label-container" role="presentation">' +
	 *		'  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
	 *		'  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
	 *		'    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
	 *		'    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
	 *		'    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
	 *		'    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
	 *		'    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
	 *		'    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
	 *		'  </div>' +
	 *		'</div>'
	 * }
	 */
	@Input() headerComponentParams: string;

	@Input() maxWidth: number;
	@Input() minWidth: number;
	@Input() suppressSizeToFit: boolean;
	@Input() suppressMenu: boolean;
	@Input() sortable: boolean;
	@Input() filter: string;
	@Input() filterParams: any;
	@Input() filterDefaultValue: ColumnFilterDefaultValue;
	@Input() comparator: any;
	@Input() unSortIcon: boolean;
	@Input() hide: boolean;

	/** A string representing the HTML contents of a cell. */
	@Input() cellRenderer: string;

	/** An ICellRendererAngularComp Component for rendering cell contents. */
	@Input() cellRendererFramework: any;
	@Input() cellRendererParams: any;

	/**
	 * tooltipField is used to get the tooltip data. NOTE: THE UNDERLYING FIELD IN THE ROW MUST EXIST OR AGGRID WILL
	 * NOT SHOW THE TOOLTIP. IF YOU HAVE CHANGED/CREATED A NEW tooltipField ON A NEW COLUMN AND YOUR TOOLTIP ISN'T
	 * POPPING UP, THE MOST-LIKELY CAUSE IS A MISSING FIELD. FOR EXAMPLE, IN ProgramStepTabComponent, IF WE WANT TO
	 * SHOW A TOOLTIP FOR THE passes/rotations COLUMN, WE CANNOT SET tooltipField TO 'passes' OR 'rotations' BECAUSE
	 * THE ProgramStepListItem DOES NOT CONTAIN THOSE FIELDS; WE'RE RENDERING THE VALUES IN THOSE COLUMNS USING A
	 * CUSTOM RENDERER. THE RIGHT FIELD REFERENCE FOR THAT CASE IS THE ONE ON WHICH THE RENDERED VALUE IS BASED,
	 * 'adjustedRunTime'.
	 */
	@Input() tooltipField: string;

	/**
	 * Class instance name representing the tooltip component class. In this case 'baseCellTooltip' points to an
	 * instance of BaseCellTooltipComponent providing the initialization and rendering interface expected by agGrid.
	 * The instance has to be defined in the frameworkComponents data passed during agGrid initialization. See
	 * TableWrapperComponent for the declaration of baseCellTooltip.
	 */
	@Input() tooltipComponent = 'baseCellTooltip';

	/**
	 * Parameters passed to the tooltip component class, in addition to those found in ITooltipParams, for initialization
	 * (see agInit method of the tooltip component class). If you are using baseCellTooltip, you should be certain there is
	 * a 'renderCallback' property in these parameters points to a function/method taking the row data item and returning
	 * the HTML string for the tooltip.
	 */
	@Input() tooltipComponentParams: any;

	/**
	 * @summary autoHeight may be set to true if the row height of the table should be adjusted so that all data in the
	 * column will fit after wrapping. The cellClass should be set to 'wrap-content' also, so the correct other styles
	 * are set.
	 */
	@Input() autoHeight = false;

	/**
	 * @summary editable should be set to true if this column is editable, otherwise false. Can also be a function to have
	 * different rows editable.
	 */
	@Input() editable: any = false;

	/**
	 * @summary singleClickEdit should be set to true to have cells under this column enter edit mode after single click.
	 */
	@Input() singleClickEdit = false;

	/**
	 * @summary cellEditorFramework takes the name of a known component class. This class will be used to edit values in the
	 * associated column. The class must be added to the list AgGridModule.withComponents([className1, className2]). Note
	 * that this is not a function call or variable reference, just a class name string.
	 */
	@Input() cellEditorFramework: string;

	/**
	 * @summary cellEditorParams is a reference to a function in the using component which will return a parameter object
	 * for the cellEditorFramework class specified. The format of the string for this property is [cellEditorParams]="functionName".
	 * Note that no () is at the end of the function name. If you pass functionName(), agGrid will interpret that as function
	 * returning a function returning the parameters!
	 */
	@Input() cellEditorParams: string;

	/**
	 * @summary cellEditorSelector is a reference to a function in the using component which will return the type of editor
	 * to use for the cell and the parameters which that editor will need.
	 */
	@Input() cellEditorSelector: { component: string, params?: any };
	@Input() type: Array<string>;
	@Input() valueGetter: (params: any) => string = null;
	@Input() width: number;

	/**
	 * @summary Mobile view is only have a column, set its default filter
	 */
	@Input() isMobileFilter: boolean = false;

	@Input() showRowGroup: string = null;
}

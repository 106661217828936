<div class="ccweather-widget-section ccweather-widget-current-section" [ngClass]="{'is-sidebar-widget': isIq4SidebarWidget, 'light-theme': theme === 'light'}">
	<div class="ccweather-widget-current-header-row">
		<span class="ccweather-current-section-header">{{'STRINGS.CURRENT_CONDITIONS' | translate}}</span>
		<button mat-icon-button (click)="onShowFullDataClick()"><mat-icon [svgIcon]="showFullData ? 'eye_closed' : 'eye_open'"></mat-icon></button>
	</div>
	<div class="ccweather-widget-current-row">
		<div class="ccweather-widget-current-image">
			<img *ngIf="currentData.weatherCode !== null"
				 id="ccweather-widget-image"
				 [src]="getIcon(currentData.weatherCode)"
				 (contextmenu)="onWeatherIconClick($event)"
			>
			<span *ngIf="showFullData" class="ccweather-widget-weather-code">{{getWeatherName(currentData.weatherCode)}}</span>
		</div>
		<div class="ccweather-widget-current-temperature" *ngIf="!showFullData">
			<span>{{getFieldValue(conditions[0])}}</span>
			<span class="ccweather-widget-current-temperature-min-max">{{getFieldValue(conditions[2])}}</span>
		</div>
		<div class="ccweather-widget-current-wind-block" *ngIf="!showFullData">
			<div class="ccweather-widget-current-wind">
			<div class="ccweather-widget-current-wind-north">
					<span>{{'STRINGS.NORTH_SHORT' | translate}}</span>
			</div>
			<div class="ccweather-widget-current-wind-east">
					<span>{{'STRINGS.WEST_SHORT' | translate}}</span>
			</div>
			<div class="ccweather-widget-current-wind-west">
					<span>{{'STRINGS.EAST_SHORT' | translate}}</span>
			</div>
			<div class="ccweather-widget-current-wind-south">
					<span>{{'STRINGS.SOUTH_SHORT' | translate}}</span>
			</div>
			<div class="ccweather-widget-compass">
				<div class="ccweather-widget-compass-circle"></div>
					<div class="ccweather-widget-compass-direction"
						 *ngIf="currentData.windDirectionDegrees"
						 [ngStyle]="{'transform': 'rotate(' + currentData.windDirectionDegrees + 'deg)'}"
					>
					</div>
					<span class="ccweather-widget-compass-text">{{getWindSpeed()}}</span>
				</div>
			</div>
		</div>
		<div class="ccweather-widget-current-full" *ngIf="showFullData">
			<ng-container *ngFor="let item of conditions">
				<div class="ccweather-widget-current-full-icon">
					<img [src]="'../../../../../../assets/images/ccweather_icons/hourly/' + item.iconName + '.svg'"
						 [matTooltip]="getTooltip(item.hint)" matTooltipPosition="right" (contextmenu)="onWeatherIconClick($event)">
				</div>
				<div class="ccweather-widget-current-full-data">{{getFieldValue(item)}}</div>
			</ng-container>
		</div>
	</div>
</div>

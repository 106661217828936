import { RbUtils } from '../../common/utils/_rb.utils';
import { RealTimeWeatherData } from '../weather-data/models/real-time-weather-data.model';

export class WeatherSourceStatusChange {

	constructor(json: any = null) {

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here
			this.changeDateTime = (json.changeDateTime instanceof String) ? RbUtils.Conversion.convertStringToDate(json.changeDateTime) : json.changeDateTime;
			if (json.weatherData) {	this.weatherData = new RealTimeWeatherData(json.weatherData); }
		}
	}

	changeDateTime: Date;
	weatherSourceId: number;
	companyId: number;
	errorMessage: string;
	jobPhase: string;
	changeType: string;

	/**
	 * weatherData contains a representation of the weather source data. It is normally null but will be set when
	 * WeatherSourceValue is the changeType (if null in that case, check reasonCode; if NoError, read latest data
	 * from the database). This gives a backdoor way to notify that data was received but couldn't be sent via
	 * SignalR for some reason.
	 */
	weatherData: RealTimeWeatherData = null;
}

import { RbUtils } from '../../../common/utils/_rb.utils';

export class ScheduledReportTime {

	id: number;
	companyId: number;
	companyIdRef: number;
	scheduledTimeOffset: Date;
	scheduledReportId: number;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.scheduledTimeOffset) {
				this.scheduledTimeOffset = RbUtils.Conversion.convertStringToDate(json.scheduledTimeOffset);
				this.scheduledTime = RbUtils.Common.timeOffsetToDisplayTime(json.scheduledTimeOffset)
			}
		}
	}

	scheduledTime: Date;
}

import { AppInjector } from "../../../core/core.module";
import { GroupLevel } from "./group-level.model";
import { RbEnums } from "../../../common/enumerations/_rb.enums";
import { RbUtils } from "../../../common/utils/_rb.utils";
import { Site } from "../../sites/models/site.model";
import { UserManagerService } from "../user-manager.service";

export class UserSummary {
	id: number;
	email: string;
	groupLevel: RbEnums.Common.GroupLevel;
	lastLogin: Date;
	localTimeLastLogin: Date;
	name: string;
	sites: Site[];

	private userManager: UserManagerService;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			// Dates:
			if (json.lastLogin) {
				this.lastLogin = RbUtils.Conversion.convertStringToDate(json.lastLogin);
				this.localTimeLastLogin = RbUtils.Conversion.convertStringUTCToLocalDate(json.lastLogin);
			}

			if (json.sites?.length) {
				this.sites = json.sites.map(site => new Site(site));
			}
		}

		this.userManager = AppInjector.get(UserManagerService);
	}

	get groupLevelItem(): GroupLevel {
		const groupLevel = this.userManager.getGroupLevel(this.groupLevel);
		if (!groupLevel) return;

		return groupLevel;
	}
}
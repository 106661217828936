import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

@Component({
  selector: 'rb-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss'],
})
export class NotificationSnackbarComponent implements OnInit {
  @Input() header: string = '';
  @Input() message: string = '';
  @Input() buttonLabel: string = '';
  @Input() disabledCloseFromOutside = false;
  @Input() type: RbEnums.Common.NotificationSnackbarType;
  @Output() onActionButtonClick = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @ViewChild('container') container: ElementRef;
  constructor(
    public snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {}

  dismiss() {
    this.snackBar.dismiss();
  }
  
  actionClick() {
    this.onActionButtonClick.emit();
    this.dismiss();
  }

  onFocusout() {
    if(this.disabledCloseFromOutside) {
      return;
    }
    this.dismiss();
  }

  get notificationIcon() {
    switch (this.type) {
      case RbEnums.Common.NotificationSnackbarType.Success:
        return 'success-style icon-confirmation';
      case RbEnums.Common.NotificationSnackbarType.Warning:
        return 'warning-style icon-warning';
      case RbEnums.Common.NotificationSnackbarType.Error:
        return 'alert-style icon-warning';
      case RbEnums.Common.NotificationSnackbarType.Action:
        return 'selection-style icon-info';
      default:
        return '';
    }
	}

  get styleClass() {
    switch (this.type) {
      case RbEnums.Common.NotificationSnackbarType.Success:
        return 'success';
      case RbEnums.Common.NotificationSnackbarType.Warning:
        return 'warning';
      case RbEnums.Common.NotificationSnackbarType.Error:
        return 'alert';
      case RbEnums.Common.NotificationSnackbarType.Action:
        return 'action';
      default:
        return '';
    }
	}

  @HostListener('document:mousedown', ['$event'])
  onOutsideClick(event): void {
    if (!this.container.nativeElement.contains(event.target) && !this.disabledCloseFromOutside) {
      this.dismiss();
    }
  }
}

import { UserAccessRequestInfo, UserAccessRequestInfoUI } from './models/access-request.model';

import { AccessRequestApiService } from './access-request-api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AccessRequestManagerService {


	constructor(private accessRequestApiService: AccessRequestApiService) {
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getCurrentAccessRequestInfoForUser(): Observable<UserAccessRequestInfoUI> {
		return this.accessRequestApiService.getCurrentAccessRequestInfoForUser();
	}

	dismissRequest(): Observable<UserAccessRequestInfo> {
		return this.accessRequestApiService.dismissRequest();
	}
}

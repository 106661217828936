import { Component, Input, OnInit } from '@angular/core';
import { LicenseManagerService } from '../../../api/license/license-manager.service';
import { take } from 'rxjs/operators';

@Component({
	selector: 'rb-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
	@Input() isGolfSite = false;
	showCirrusProLogo = false;

	constructor(private licenseManager: LicenseManagerService) { }

	ngOnInit() {
		this.licenseManager.allowGolfUpgraded()
		.pipe(take(1))
		.subscribe(allowGolfUpgraded => this.showCirrusProLogo = allowGolfUpgraded ? true : false);
	}
}

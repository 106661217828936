import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { CimisStation } from '../stations/models/cimis-station.model';
import { GetWeatherSourceQueryParams } from '../comm-interfaces/models/get-weather-source-params.model';
import { GlobalWeatherLocation } from './models/global-weather-location.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';
import { WeatherSource } from './models/weather-source.model';
import { WeatherSourceType } from './models/weather-source-type.model';

@Injectable({
	providedIn: 'root'
})
export class WeatherSourceApiService extends ApiService {
	clearCache() {
		super.clearCache(this.getWeatherSourcesUrl);
		super.clearCache(this.getCimisStationsUrl);
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	createWeatherSource(weatherSourceData: any): Observable<WeatherSource> {
		return this.apiRequest<any>(this.createWeatherSourceUrl, HttpMethod.Post, weatherSourceData);
	}

	deleteWeatherSources(weatherSourceIds: number[]): Observable<null> {
		return this.apiRequest(this.deleteWeatherSourcesUrl(weatherSourceIds), HttpMethod.Delete);
	}

	getCimisStations(): Observable<ApiCachedRequestResponse<CimisStation[]>> {
		return this.apiRequestWithCache<any>(this.getCimisStationsUrl, false,
			result => result.map(c => new CimisStation(c)), Number.MAX_SAFE_INTEGER);
	}

	getNameUniqueness(name: string, id: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNameUniquenessUrl(name, id), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getWeatherSource(weatherSourceId: number, queryParams: GetWeatherSourceQueryParams, bypassCache = false): Observable<WeatherSource> {
		return this.apiRequestWithCache<any>(this.getWeatherSourceUrl(weatherSourceId, queryParams), bypassCache, null, 15 * 60)
			.pipe(map(response => new WeatherSource(response.value)));
	}

	getWeatherSources(bypassCache = false): Observable<ApiCachedRequestResponse<WeatherSource[]>> {
		return this.apiRequestWithCache<any>(this.getWeatherSourcesUrl, bypassCache,
				result => result.map(c => new WeatherSource(c)).sort((a, b) => a.name.localeCompare(b.name)));
	}

	getWeatherSourceTypes(): Observable<ApiCachedRequestResponse<WeatherSourceType[]>> {
		return this.apiRequestWithCache<any>(this.getWeatherSourceTypesUrl, false,
			result => result.map(c => new WeatherSourceType(c)).sort((a, b) => a.name.localeCompare(b.name)), Number.MAX_SAFE_INTEGER);
	}

	searchGlobalWeatherLocations(search: string): Observable<GlobalWeatherLocation[]> {
		// Bypass OPTIONS call since www doesn't respond properly to it. This is done with Content-Type = text/plain
		return this.http.request<any>('GET',
			`${this.baseWwoUrl}search.ashx?key=${this.wwoKey}&format=json&num_of_results=150&query=${search}`,
			{ headers: { 'Content-Type': 'text/plain' } })
			.pipe(map(results => {
				if (results == null || results.search_api == null || results.search_api.result == null) return [];

				return results.search_api.result.map(json => new GlobalWeatherLocation(json));
			}));
	}

	getSevenDayForecast() {
		// // Bypass OPTIONS call since www doesn't respond properly to it. This is done with Content-Type = text/plain
		// return this.http.request<any>('GET',
		// 	`http://http://api.worldweatheronline.com/premium/v1/weather.ashx?key=rbzzxtsyydjedw88uvfvsqjd&q=48.85,2.35&num_of_days=2&tp=3&format=json`,
		// 	{ headers: { 'Content-Type': 'text/plain' } });

		const url = `${this.baseWwoUrl}weather.ashx?key=${this.wwoKey}&num_of_days=5&q=92121&mca=no&tp=24&format=json`;
		return this.apiRequest<any>(url, HttpMethod.Get);
	}

	updateWeatherSource(id: number, updateData: any) {
		return this.apiRequest<any>(this.updateWeatherSourceUrl(), HttpMethod.Patch,
			{ ids: [id], patch: this.patchTransform(updateData) });
	}

	updateWeatherDataMonthlyAverages(weatherSrcId: number, wsUpdate: object): Observable<null> {
		return this.apiRequest<any>(this.updateWeatherDataMonthlyAveragesUrl(), HttpMethod.Patch,
			{ ids: [weatherSrcId], patch: this.patchTransform(wsUpdate) });
	}

	collectWeatherData(id: number) {
		return this.apiRequest<any>(this.collectWeatherDataUrl(id), HttpMethod.Post);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseWwoUrl(): string { return `${this.env.wwoUrl}/`; }
	private get wwoKey(): string { return `${this.env.wwoKey}`; }
	private get baseUrl(): string { return `${this.baseApiUrl}WeatherSource`; }

	private get createWeatherSourceUrl(): string { return `${this.baseUrl}/CreateWeatherSource`; }

	private deleteWeatherSourcesUrl(weatherSourceIds: number[]): string {
		const queryParam = weatherSourceIds.reduce((list, weatherSourceId) => `${list ? list + '&' : ''}weatherSourceIds=${weatherSourceId}`, '');
		return `${this.baseUrl}/DeleteWeatherSource?${queryParam}`;
	}

	private get getCimisStationsUrl(): string { return `${this.baseUrl}/GetCimisStations`; }

	private getNameUniquenessUrl(name: string, id: number): string {
		return `${this.baseUrl}/IsNameUnique?weatherSourceName=${encodeURIComponent(name)}&weatherSourceId=${id}`;
	}

	private getWeatherSourceUrl(id: number, queryParams: GetWeatherSourceQueryParams): string {
		let queryString = queryParams ? queryParams.queryString : '';
		queryString += (queryString.length > 0) ? `&weatherSourceId=${id}` : `?weatherSourceId=${id}`;
		return `${this.baseUrl}/GetWeatherSource${queryString}`;
	}

	private get getWeatherSourcesUrl(): string { return `${this.baseUrl}/GetWeatherSources?IncludeCommInterfaces=true`; }

	private get getWeatherSourceTypesUrl(): string { return `${this.baseUrl}/GetWeatherSourceTypes`; }

	private updateWeatherSourceUrl(): string { return `${this.baseUrl}/v2/Update`; }

	private collectWeatherDataUrl(id: number): string { return `${this.baseUrl}/CollectWeatherData?weatherSourceId=${id}`; }

	private updateWeatherDataMonthlyAveragesUrl(): string {
		return `${this.baseUrl}/v2/UpdateWeatherDataMonthlyAverages`;
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-dialog
	[title]="'STRINGS.SELECT_RECIPIENTS' | translate"
	[closeButtonLabel]="'STRINGS.CANCEL' | translate"
	[submitButtonLabel]="'STRINGS.SUBMIT' | translate"
	[showSubmitButton]="true"
	[closeOnSubmit]="true"
	[saveDisabled]="selectedContacts.length === 0"
	[isSelfHandleDialogClose]="isSelfHandleDialogClose"
	(onSubmit)="onSave()"
	(onCancel)="onCancel()"
	class="select-recipients-dialog"
	data-qaid="select-recipients-dialog">

	<div class="select-recipients-dialog">
		<div *ngIf="showFormatSelection">
			<div class="description p">{{'STRINGS.SELECT_REPORT_FORMAT' | translate}}</div>
			<div class="pb4 pt2">
				<mat-radio-group (change)="onReportFormatChange($event)" [ngModel]="selectedReportFormat">
					<mat-radio-button class="pr3" color="primary" [value]="0">{{ 'STRINGS.PDF' | translate }}</mat-radio-button>
					<mat-radio-button color="primary" [value]="1" [checked]="false">{{ 'STRINGS.CSV' | translate }}</mat-radio-button>
    			</mat-radio-group>
			</div>
			<div class="description">{{'STRINGS.SELECT_RECIPIENTS_DESCRIPTION' | translate}}</div>
		</div>
		<div class="select-all">
			<mat-checkbox [checked]="allContactsSelected" (change)="onAllContactSelectionChange($event.checked)" color="primary">
				{{'STRINGS.SELECT_ALL_UPPERCASE' | translate}}
			</mat-checkbox>
		</div>

		<div class="contacts-list">
			<ng-container *ngFor="let contact of contactsList">
				<div class="contact">
					<mat-checkbox [checked]="isContactSelected(contact)" (change)="onContactSelectionChange(contact, $event.checked)" color="primary">
						{{contact.firstName}} {{contact.lastName}}
					</mat-checkbox>
				</div>
			</ng-container>
		</div>
	</div>
</rb-dialog>

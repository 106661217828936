import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { StationAssignedColumnKey } from '../../../../../api/stations/models/station-assigned-program.model';


@Component({
	selector: 'rb-station-programs-assigned',
	templateUrl: './station-programs-assigned.component.html',
	styleUrls: ['./station-programs-assigned.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StationProgramsAssignedComponent implements ICellRendererAngularComp {
	programsDisplay = "-";
	params: any = null;
	isLongList = false;

	private _displayLimit = 3;
	
	constructor() { }
	refresh(params: ICellRendererParams): boolean {
		this.params = params;
		this.updateCell(this.params.dataDisplayType);
		return true;
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		this.updateCell(this.params.dataDisplayType);
	}

	triggerAction(event) {
		event.stopPropagation();
		if (this.isLongList) {
			this.params?.showPrograms(this.params.data);
		}
	}


	// Helpers functions
	private updateCell(dataDisplayType = StationAssignedColumnKey.AssignedPrograms) {
		const { data = {}, isUniversalController = false } = this.params;
		if (isUniversalController) {
			this._displayLimit = 5;
		}
		if (dataDisplayType === StationAssignedColumnKey.AssignedPrograms) {
			this.showAssignedProgram(data);
			return;
		}

		this.showRuntime(data);
		
	}

	private showAssignedProgram(data: any) {
		const { assignedPrograms = [] } = data;
		
		if (assignedPrograms?.length) {
			const programNames = assignedPrograms.map(p => p.programShortName);
			if (programNames.length <= this._displayLimit) {
				this.programsDisplay = programNames.join(', ')
				return;
			}

			this.programsDisplay = `${programNames.slice(0, this._displayLimit).join(', ')}...`;
			this.isLongList = true;
		}
	}

	private showRuntime(data: any) {
		const { assignedPrograms = [] } = data;
		if (!assignedPrograms?.length) {
			return;
		}

		const [first] = assignedPrograms;
		for (const program of assignedPrograms) {
			if (!!first?.adjustedRunTime && program?.adjustedRunTime !== first.adjustedRunTime) {
				this.programsDisplay = RbUtils.Translate.instant('STRINGS.MULTIPLE');
				this.isLongList = true;
				return;
			}
		}

		[this.programsDisplay] = assignedPrograms.map(p => p.adjustedRunTime);
	}

}

import { Duration } from 'moment';
import { RbUtils } from '../../common/utils/_rb.utils';

/**
 * RainWatchData is sent by irrigation engine with a SensorChange notification for a Rain Watch sensor.
 * It contains information about the current state of a Rain Watch sensor including whether it's pausing
 * operations, has shut down operations, has seen rain during the Rain Watch Window, etc.
 */
export class RainWatchData {
	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.rainWatchTimeSincePause) {
				this.rainWatchTimeSincePause = RbUtils.Conversion.convertTimeSpanStringToDuration(json.rainWatchTimeSincePause);
			}
			if (json.rainWatchTimeSinceShutDown) {
				this.rainWatchTimeSinceShutDown = RbUtils.Conversion.convertTimeSpanStringToDuration(json.rainWatchTimeSinceShutDown);
			}
			if (json.rainWatchTimeSinceLastRain) {
				this.rainWatchTimeSinceLastRain = RbUtils.Conversion.convertTimeSpanStringToDuration(json.rainWatchTimeSinceLastRain);
			}

		}
	}

	/**
	 * Amount of rain accumulated toward the Pause threshold in Rain Watch by a rain can sensor (inches).
	 */
	rainWatchPauseRainAccumulated?: number = null;

	/**
	 * Amount of rain accumulated toward the Rain Shut Down threshold in Rain Watch by a rain can sensor
	 * (inches).
	 */
	rainWatchShutDownRainAccumulated?: number = null;

	/**
	 * Incremental rain accumulated in Rain Watch (inches).
	 */
	rainWatchIncrementalRainAccumulated?: number = null;

	/**
	 * If non-null, the amount of time remaining before the pause status of the Rain Watch sensor is cleared
	 * and normal operation is resumed.
	 */
	rainWatchTimeSincePause: Duration = null;

	/**
	 * If non-null, the amount of time remaining before the shut down status of the Rain Watch sensor is cleared
	 * and normal operation is resumed.
	 */
	rainWatchTimeSinceShutDown: Duration = null;

	/**
	 * If non-null, the amount of time since the last Rain Watch "window" event, the last time rain was recorded.
	 * When this time matches the Rain Watch window value, all counters are cleared.
	 */
	rainWatchTimeSinceLastRain: Duration = null;
}

export class FormattedControllerDifferenceItem {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	parentId: number;
	parentKey: string;
	key: string;
	name: string;
	database: string;
	physicalData: string;

}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="showDurationDialog">
	<!-- Field Tech User -->
	<mat-form-field *ngIf="isReadOnly" [class.narrow]="narrow">
		<input matInput type="text" [disabled]="true" readonly [ngModel]="durationString" (click)="durationTimeClicked()" [placeholder]="placeholder" [disabled]="isDisabled"/>

		<mat-hint align="left" [hidden]="hideDays">{{'STRINGS.DAY_LOWERCASE' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideHours">{{'STRINGS.HR' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideMinutes">{{'STRINGS.MIN' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideSeconds">{{'STRINGS.SEC' | translate}}</mat-hint>
	</mat-form-field>
	<!-- Normal User -->
	<mat-form-field *ngIf="!isReadOnly" [class.narrow]="narrow" class="duration-timer">
		<input matInput type="text" readonly [ngModel]="durationString" (click)="durationTimeClicked()" [placeholder]="placeholder" [disabled]="isDisabled"/>

		<mat-hint align="left" [hidden]="hideDays">{{'STRINGS.DAY_LOWERCASE' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideHours">{{'STRINGS.HR' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideMinutes">{{'STRINGS.MIN' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideSeconds">{{'STRINGS.SEC' | translate}}</mat-hint>
	</mat-form-field>

	<ng-template #durationDialog>
		<rb-sensor-duration-selector-dialog
			[duration]="value"
			(durationChange)="onDurationChanged($event)"
			[hideDays]="hideDays"
			[hideHours]="hideHours"
			[hideMinutes]="hideMinutes"
			[hideSeconds]="hideSeconds"
			[maxDuration]="maxDuration"
			[title]="dialogTitle"
			[dialogRef]="durationDialogRef"
		>
		</rb-sensor-duration-selector-dialog>
	</ng-template>
</div>


export class UserSnapshotListItem {

	id: number;
	name: string;
	isDeleted = false;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

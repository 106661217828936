import * as L from 'leaflet';
import * as moment from 'moment';

import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { Circle, Map, MapOptions } from 'leaflet';
import { filter, take, takeUntil, timeout } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ActivatedRoute } from '@angular/router';
import { Area } from '../../../api/areas/models/area.model';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CompanyPreferences } from '../../../api/companies/models/company-preferences.model';
import { ContextMenuInfoTypes } from '../../../common/models/context-menu-info-types';
import { ControllerListItem } from '../../../api/controllers/models/controller-list-item.model';
import { ControllerManagerService } from '../../../api/controllers/controller-manager.service';
import { ControllerOptions } from '../../../common/models/controller-options';
import { ControllerWithMapInfoLeaflet } from '../../../common/models/controller-with-map-info-leaflet.model';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { ExportService } from '../../../common/services/export.service';
import { GeoGroup } from '../../../api/regions/models/geo-group.model';
import { IStation } from '../../../common/models/station.interface';
import { ITriPaneComponent } from '../../../shared-ui/components/tri-pane/tri-pane-component.interface';
import { LeftPanelComponent } from '../left-panel/left-panel.component';
import { MapInfoLeaflet } from '../../../common/models/map-info-leaflet.model';
import { MapLeafletService } from '../../../common/services/map-leaflet.service';
import { MapToolbarComponent } from '../map-toolbar/map-toolbar.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { MultiSelectService } from '../../../common/services/multi-select.service';
import { NearbyStationSelectComponent } from '../nearby-station-select/nearby-station-select.component';
import { Note } from '../../../api/sticky-notes/models/sticky-note.model';
import { NotePopupComponent } from '../note-popup/note-popup.component';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { ReportPdfService } from '../../../sections/reports/common/pdf/report-pdf.service';
import { Sensor } from '../../../api/sensors/models/sensor.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { Station } from '../../../api/stations/models/station.model';
import { StationWithMapInfoLeaflet } from '../../../common/models/station-with-map-info-leaflet.model';
import { StickyNoteManagerService } from '../../../api/sticky-notes/sticky-note-manager.service';
import { ToasterService } from '../../../common/services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { TriPaneComponent } from '../../../shared-ui/components/tri-pane/tri-pane.component';
import MapLayer = RbEnums.Map.MapLayer;

@UntilDestroy()
@Component({
	selector: 'rb-map-leaflet',
	templateUrl: './map-leaflet.component.html',
	styleUrls: ['./map-leaflet.component.scss']
})
export class MapLeafletComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@ViewChild('triPane', { static: true }) triPane: ITriPaneComponent;
	@ViewChild('mapContainer', { read: ViewContainerRef }) mapContainerView: ViewContainerRef;
	@ViewChild('mapContainer') mapContainer: ElementRef;
	@ViewChild('map') map: ElementRef;
	@ViewChild('mapToolbarComponent') mapToolbarComponent: MapToolbarComponent;
	@ViewChild('contextMenuTrigger') contextMenuTrigger: MatMenuTrigger;
	@ViewChild('rightClickContextMenuTrigger') rightClickContextMenuTrigger: MatMenuTrigger;
	// @ViewChild('leftPane') leftPane: MatDrawer;
	@ViewChild('leftSidebar') leftSidebar: LeftPanelComponent;
	@ViewChild('durationDialog', { static: true }) durationDialog;
	@ViewChild('startControllerDialog', { static: true }) startControllerDialog;
	@ViewChild('startStationModal', { static: true }) startStationModal;
	@ViewChild('siteAddressModal', { static: true }) siteAddressModal;
	@ViewChild('controllerAutoConnectModal', { static: true }) controllerAutoConnectModal;

	@Input() isWidget: boolean;
	@Input() siteId: number;
	@Input() uniqueId: number;
	@Input() showContextMenu = false;
	@Input() showVisibleSelector = false;
	@Input() showCustomLayersMenu = false;
	@Input() specialZoom = true;
	@Input() canEditCustomLayers = false;
	@Input() noInternet = false;
	@Input() isAllowBatchEditRotationTime = true;

	@Output() siteAddressLookupFailed = new EventEmitter();
	@Output() drop: EventEmitter<CdkDragDrop<any, any>> = new EventEmitter();
	@Output() mapClicked = new EventEmitter();
	@Output() editController = new EventEmitter<number>(); // Hole Id
	@Output() editHole = new EventEmitter<number>(); // Hole Id
	@Output() editArea = new EventEmitter<number>(); // Area Id
	@Output() editSensor = new EventEmitter<{ id: number, controllerId: number }>(); // Sensor Id
	@Output() editStation = new EventEmitter<number>(); // Station Id
	@Output() editStationBatch = new EventEmitter<IStation[]>(); // Station Id
	@Output() siteDataLoaded = new EventEmitter<any>(); // See MapService.siteDataLoaded
	@Output() invokeCreateUser = new EventEmitter<any>(); // Invoke the Create User sidebar 
	
	nearbyStationSelectRef: ComponentRef<NearbyStationSelectComponent>

	busy = false;
	companyPreferences: CompanyPreferences;
	contextMenuInfo: ContextMenuInfoTypes;
	rightClickContextMenuInfo: any;
	controllers: ControllerListItem[] = [];
	defaultRunStationDuration = moment.duration({ minutes: 1 });
	durationDialogTitle = 'Set Duration';
	dragging = false;
	public expandedControllerId = -1;
	holes: Area[] = [];
	isGolfSite: boolean;
	isSensorClicked = false;
	isShowStationAdjustments = false;
	geoGroups: GeoGroup[] = [];
	isMobile = false;
	leftPanelVisible = false;
	loadError: string;
	maxHours = 0;
	selectedStationIds: number[] = [];
	showingDetailMarkers = true;
	mapSubscriptions: Subscription = new Subscription();
	batchMode = false;
	lastIrrigatedDateTimeFormat: string;
	cultureSettings: CultureSettings;
	treeStructure = [];
	public numberStations = 0;
	private subscriptionNotifier$ = new Subject<void>();
	textComments = '';
	textDescriptions = '';
	isTextCommentsChange = false;
	isTextDescriptionsChange = false;
	isEditingAreaColor = false;
	isEditingFromStationAreaContextMenu = false;
	styles: any;
	isConnectingController = false;
	parentController: ControllerListItem;
	/** Stations selected with multi select tool to start running */
	selectedStationsToRun: StationWithMapInfoLeaflet[];

	// canSidePanelComponentBeShown = false;
	isSidePanelComponentInitialized = false;
	sidePanelComponentInitError = null;
	isRightMatDrawerOpen = false;

	/**
	 * The MapInfo model object
	 */
	mapInfo: MapInfoLeaflet;

	selectedControllerWithMapInfoLeaflet: ControllerWithMapInfoLeaflet[] = [];

	itemsMovable: boolean;

	isLocalStationEdit = false;

	get mapSubs() {
		return this.mapSubscriptions;
	}

	set mapSubs(suscription: Subscription) {
		if (suscription === null)
			this.mapSubscriptions = new Subscription();
		else {
			this.mapSubscriptions.add(suscription);
		}
	}

	get contextMenuStation() {
		return this.contextMenuInfo ? this.contextMenuInfo['station'] : null;
	}

	get isSatelliteTbos(): boolean {
		if (!this.parentController) return false;

		return this.controllerManager.isSatelliteTbos(this.parentController.type);
	}

	private areaMarkerInfo = { width: 80, height: 50, mapIconHotspotX: 40, mapIconHotspotY: 25, dragIconHotspotX: 15, dragIconHotspotY: 15 };
	private controllerMarkerInfo = { width: 20, height: 15, mapIconHotspotX: 10, mapIconHotspotY: 7, dragIconHotspotX: 10, dragIconHotspotY: 7 };
	private fullScreenElement: any;
	private holeMarkerInfo = { width: 20, height: 30, mapIconHotspotX: 5, mapIconHotspotY: 30, dragIconHotspotX: 5, dragIconHotspotY: 30 };
	private stationMarkerInfo = { width: 33, height: 33, mapIconHotspotX: 16, mapIconHotspotY: 16, dragIconHotspotX: 15, dragIconHotspotY: 15 };
	private selectedContextMenuItem: number;
	private MIN_ZOOM_FOR_DETAIL_MARKERS = 18;
	private _leafletMap: Map;
	private _leafletMapOptions: MapOptions;

	get leafletMapOptions() {
		if (!this._leafletMapOptions) {
			this._leafletMapOptions = this.mapService.getDefaultLeafletMapOptions();
		}
		return this._leafletMapOptions;
	}

	get leafletLayers() {
		return this.mapInfo ?
			[...this._leafletMapOptions.layers, ...this.mapInfo.leafletLayers] : [...this._leafletMapOptions.layers];
	}

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(
		private authManager: AuthManagerService,
		private controllerManager: ControllerManagerService,
		protected deviceManager: DeviceManagerService,
		private dialog: MatDialog,
		public mapService: MapLeafletService,
		public multiSelectService: MultiSelectService,
		private exportService: ExportService,
		private reportPdfService: ReportPdfService,
		private siteManager: SiteManagerService,
		public toastService: ToasterService,
		public translate: TranslateService,
		protected route: ActivatedRoute,
		private hostElement: ElementRef,
		private cdr: ChangeDetectorRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private notesManager: StickyNoteManagerService
	) {
		this.busy = true;
		this.multiSelectService.getNumberStations()
			.asObservable().pipe(takeUntil(this.subscriptionNotifier$))
			.subscribe(value => this.numberStations = value);
	}

	ngOnInit() {
		this.isGolfSite = this.siteManager.isGolfSite;

		if (this.isWidget) {
			this.itemsMovable = false;
		} else {
			this.itemsMovable = true;
		}

		this.deviceManager.windowResize
			.pipe(untilDestroyed(this))
			.subscribe(() => (this.isMobile = this.deviceManager.isMobile));
		this.isMobile = this.deviceManager.isMobile;

		if (!this.isGolfSite) {
			this.exportService.onExportPdfEvent$.subscribe((res: any) => {
				this.busy = res && res === true ? false : true;
			});
		}

		this.lastIrrigatedDateTimeFormat = RbUtils.Common.getDateTimeString(this.authManager.userCulture, false);
	}

	ngAfterViewInit() {
		setTimeout(() => this.loadData());
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['siteId']) {
			this.reloadData();
		}
	}

	get leafletMap(): Map { return this.mapInfo == null ? null : this.mapInfo.map; }

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
		this.mapService.mapRemoved(this.siteId, this.uniqueId);
		this.mapInfo.rasterItemsChanged = undefined;
		this.mapInfo.vectorItemsChanged = undefined;
		this.mapInfo.cleanup();

		this.subscriptionNotifier$.next();
		this.subscriptionNotifier$.complete();
	}

	toggleSidePanel() {
		if (this.leftPanelVisible) {
			this.onShowHideLeftPane({ shown: false })
		} else {
			this.onShowHideLeftPane({ shown: true })
		}
	}

	onShowHideLeftPane(event: { shown: boolean }) {
		if (this.leftSidebar) {
			if (event.shown) {
				this.leftPanelVisible = true;
				setTimeout(() => {
					this.leftSidebar.open();
					if (this.isGolfSite) {
						this.mapInfo.toggleMultiSelectMode(true, true);
					}
				});
			} else {
				this.closeSidebar();
			}
		}
	}

	closeSidebar() {
		this.leftPanelVisible = false;
		this.leftSidebar.close();
		if (this.isGolfSite) {
			if (this.numberStations === 0) {
				this.mapInfo.toggleMultiSelectMode(true, false);
			}
		}
	}

	onExportPdf() {
		if (!this.isGolfSite) {
			const pdfInfo = this.reportPdfService.createPdfDocument('l');
			this.exportService.mapInfo = this.mapInfo;

			this.busy = true;

			this.exportService.printMaps(pdfInfo, this.mapService.env);
		}
	}

	onGoHome() {
		this.mapInfo.goToCourseLocation(/* fitMapBounds */ true);
	}

	onLeafletMapReady(map: Map) {
		map.doubleClickZoom.disable();
		this._leafletMap = map;
	}

	// =========================================================================================================================================================
	// Map Service, data handling, and context menu handling
	// =========================================================================================================================================================

	private async loadData() {
		if (this.siteId == null || this.uniqueId == null || isNaN(this.siteId)) return;
		// Set the map Site ID then wait for data to load from the siteDataLoaded event
		if (this.mapInfo) {
			this.mapInfo.cleanup();
		}

		// Ensure we have culture settings before continuing. This will be necessary on a page refresh;
		this.cultureSettings = RbUtils.User.cultureSettings;
		if (!this.cultureSettings) {
			setTimeout(() => this.loadData(), 100);
			return;
		}

		// Show multiple select for both Golf & IQ4
		const showMultipleSelect = !this.isWidget;
		this.mapInfo = this.mapService.getMap(this.siteId, this.uniqueId, this._leafletMap, this.itemsMovable, this.specialZoom, showMultipleSelect);
		this.mapInfo.setMinZoomForDetailMarkers(this.MIN_ZOOM_FOR_DETAIL_MARKERS);

		await this.mapInfo.loadPrefs();

		this.busy = false;
		
		this.setMapsCenter();

		this.mapInfo.changeBaseLayer({ baseLayerId: this.mapInfo.layerVisibility.selectedBaseLayer, saveLayerConfig: false });

		// Just to set the current "showingCustomLayers" state
		this.mapInfo.checkCustomLayers();

		if (this.isGolfSite) {
			this.leftSidebar.updateLayerVisibility(this.mapInfo.layerVisibility, this.itemsMovable);
		} else {
			this.mapToolbarComponent.updateLayerVisibility(this.mapInfo.layerVisibility, this.itemsMovable);
		}

		if (this.mapInfo.prefs.sitePreferences.visibility.showingStickyNotes && !this.isGolfSite) {
			this.mapInfo.showStickyNotes(this.mapInfo.prefs.sitePreferences.visibility.showingStickyNotes);
		}

		this.updateShowingDetailMarkers(this.mapInfo.prefs.sitePreferences.zoom)
		this.mapSubs = this.mapInfo.mapZoomChanged.pipe(untilDestroyed(this), filter(mi =>
			mi.siteId === this.mapInfo.siteId && mi.siteId === this.mapInfo.siteId))
			.subscribe((mi: MapInfoLeaflet) => {
				this.updateShowingDetailMarkers(mi.map.getZoom())
				if (this.isGolfSite) {
					this.showVisibleMarkers();
				} else {
					this.showVisibleMarkersCommercial();
				}
			});
		this.mapSubs = this.mapInfo.busy.pipe(untilDestroyed(this)).subscribe(busy => this.busy = busy);
		this.mapSubs = this.mapInfo.mapClicked.pipe(untilDestroyed(this)).subscribe(() => this.handleMapClicked());
		this.mapSubs = this.mapInfo.contextMenuInvoked.pipe(untilDestroyed(this)).subscribe(menuInfo => {
			this.invokeContextMenu(menuInfo);
		});
		this.mapSubs = this.mapInfo.rightClickContextMenuInvoked.pipe(untilDestroyed(this)).subscribe(menuInfo => {
			this.invokeRightClickContextMenu(menuInfo)
		});
		this.mapSubs = this.mapInfo.openNoteDialogInvoked.pipe(untilDestroyed(this)).subscribe((note: Note) => this.openNoteDialog(note));
		this.mapSubs = this.mapInfo.editController.pipe(untilDestroyed(this)).subscribe(id => this.editController.emit(id));
		this.mapSubs = this.mapInfo.editHole.pipe(untilDestroyed(this)).subscribe(id => this.editHole.emit(id));
		this.mapSubs = this.mapInfo.editArea.pipe(untilDestroyed(this)).subscribe(id => this.editArea.emit(id));
		this.mapSubs = this.mapInfo.editSensor.pipe(untilDestroyed(this)).subscribe(sensorInfo => this.editSensor.emit(sensorInfo));
		this.mapSubs = this.mapInfo.editStation.pipe(untilDestroyed(this)).subscribe(id => this.editStation.emit(id));
		this.mapSubs = this.mapInfo.loadError.pipe(untilDestroyed(this)).subscribe(error => this.loadError = error);
		this.mapSubs = this.mapInfo.multiSelectActionInvoked.pipe(untilDestroyed(this)).subscribe(action => this.onContextMenuItemSelected(action));
		this.mapSubs = this.mapInfo.batchEditStations.pipe(untilDestroyed(this)).subscribe((stationIds) => this.editStationBatch.emit(stationIds));
		this.mapSubs = this.mapInfo.siteDataLoaded.pipe(untilDestroyed(this)).subscribe(data => this.handleDataLoaded(data));
		this.mapSubs = this.mapInfo.openControllerAutoConnectModal.pipe(untilDestroyed(this)).subscribe((data) => this.openControllerAutoConnectModal(data));
		this.mapSubs = this.mapInfo.siteAddressLookupFailed.pipe(untilDestroyed(this)).subscribe(data => {
			this.siteAddressLookupFailed.emit();
			this.mapInfo.goToCurrentLocation();
		});

		if (this.isGolfSite) {
			this.notesManager.setSiteId(this.siteId);
		}
	}

	private reloadData() {
		if (this.map != null && this.siteId != null) {

			// RB-12599: Change back to the continents view before attempting to center the map
			// based on the new site
			this._leafletMap.setView([0, 0], 3);
			this.cleanMapSubscriptions();
			this.loadData(); // SiteId changed after we are loaded - let's reset everything
		}
	}

	get isFullScreen(): boolean {
		return TriPaneComponent.isFullScreen;
	}

	private updateShowingDetailMarkers(zoom: number) {
		this.showingDetailMarkers = !this.isGolfSite || zoom >= this.MIN_ZOOM_FOR_DETAIL_MARKERS;
	}

	private handleMapClicked() {
		this.mapClicked.emit();
	}

	private invokeContextMenu(menuInfo: ContextMenuInfoTypes) {
		if (!this.showContextMenu) return;
		this.isConnectingController = false;
		this.contextMenuInfo = menuInfo;
		this.contextMenuTrigger.openMenu();
		this.contextMenuTrigger.menuClosed.subscribe(() => {
			this.contextMenuInfo = null;
		});
		this.cdr.detectChanges();
	}

	private invokeRightClickContextMenu(menuInfo: any) {
		this.rightClickContextMenuInfo = menuInfo;
		this.rightClickContextMenuTrigger.openMenu();
		this.rightClickContextMenuTrigger.menuClosed.subscribe(() => {
			this.rightClickContextMenuInfo = null;
		});
		this.cdr.detectChanges();
	}

	onContextMenuItemSelected(selectedItem: number) {

		let defaultDuration: moment.Duration;
		if (selectedItem !== RbEnums.Map.StationContextMenu.Start && selectedItem !== RbEnums.Map.AreaContextMenu.Start &&
			selectedItem !== RbEnums.Map.HoleContextMenu.Start) {
			defaultDuration = null;
		} else {
			if (this.mapInfo.isMultiSelectModeEnabled) {
				defaultDuration = RbUtils.Conversion.convertTicksToDuration(0);
			} else {
				defaultDuration = this.mapInfo.getDefaultDuration(selectedItem, this.contextMenuInfo);
			}
		};

		if (selectedItem === RbEnums.Map.ControllerContextMenu.Start) {
			this.dialog.open(this.startControllerDialog);
			return;
		}

		// COMMERCIAL ONLY
		if (!this.isGolfSite && selectedItem === RbEnums.Map.SiteContextMenu.Edit) {
			this.contextMenuTrigger.closeMenu();
			// open site address dialog
			this.dialog.open(this.siteAddressModal);
			return;
		}

		// GOLF ONLY
		if (this.isGolfSite && selectedItem === RbEnums.Map.StationContextMenu.Edit) {
			const batchMode = this.multiSelectService.getSelectedStations().length > 1 ? true : false;
			if (batchMode) {
				this.editStationBatch.emit(this.multiSelectService.getSelectedStations())
				return;
			}
		}

		if (defaultDuration == null) {
			// No duration necessary - call the service to handle the item selection
			if (!this.isGolfSite) {
				this.isEditingAreaColor = selectedItem === RbEnums.Map.StationContextMenu.EditAreaColor;
				this.isEditingFromStationAreaContextMenu = selectedItem === RbEnums.Map.StationAreaContextMenu.EditAreaColor;
			}
			const menuInfo = this.contextMenuInfo ? this.contextMenuInfo : this.rightClickContextMenuInfo;
			this.mapInfo.contextMenuItemSelected(selectedItem, menuInfo);
			if (!(this.isEditingAreaColor || this.isEditingFromStationAreaContextMenu)) {
				this.contextMenuTrigger.closeMenu();
			}
			return;
		}

		// Check for Demo Mode Controller (commercial only)
		if (!this.isGolfSite && this.contextMenuInfo && this.contextMenuInfo['station'] != null) {
			this.controllerManager.doIfNotDemoModeController(
				this.contextMenuInfo['station'].satelliteId,
				this.openDurationDialog.bind(this),
				{ selectedItem: selectedItem, defaultDuration: defaultDuration });
			return;
		}

		this.openDurationDialog({ selectedItem: selectedItem, defaultDuration: defaultDuration });
	}

	private openDurationDialog(params: { selectedItem: number, defaultDuration: moment.Duration }) {
		this.selectedContextMenuItem = params.selectedItem;
		this.defaultRunStationDuration = params.defaultDuration;
		this.batchMode = this.multiSelectService.getSelectedStations().length ? true : false;
		if (this.batchMode) {
			this.durationDialogTitle =
				`Set Duration for ${
					this.multiSelectService.getSelectedStations().length
				} Station${ this.multiSelectService.getSelectedStations().length > 1 ? 's' : '' }`;
				this.selectedStationsToRun = this.multiSelectService.getSelectedStations().map(s => s.station);
			
		} else {
			this.durationDialogTitle = `Set Duration`;
		}

		// @ts-ignore
		this.fullScreenElement = document.webkitFullscreenElement;
		this.dialog.open(this.durationDialog, { panelClass: 'duration-dialog' });
	}

	onDurationSet(duration: moment.Duration | { [stationId: string]: moment.Duration }) {
		if (duration != null) {
			this.mapInfo.contextMenuItemSelected(
				this.selectedContextMenuItem,
				this.contextMenuInfo,
				duration
			);

			this.leftPanelVisible = false;
			this.leftSidebar.close();
		}
		this.contextMenuInfo = null;
		this.contextMenuTrigger.closeMenu();
		this.batchMode = false;
		this.selectedStationsToRun = undefined;
		if (this.fullScreenElement != null) this.fullScreenElement.webkitRequestFullscreen();
	}

	onStartController(event: { programIds: number[]; stationIds: number[] }) {

		const controllerContextMenu = this.contextMenuInfo as ControllerOptions

		if (event.programIds != null) {
			this.mapInfo.startPrograms(event.programIds);
			return;
		}

		if (event.stationIds != null && "controller" in controllerContextMenu) {
			this.selectedStationIds = event.stationIds;
			this.maxHours = (RbUtils.Common.isUniversalController(controllerContextMenu.controller.type)) ?
				RbConstants.Form.DURATION_HOURS.ninetySixHrs : RbConstants.Form.DURATION_HOURS.twelveHrs;
			this.dialog.open(this.startStationModal);
		}
	}

	onStationStartRequested() {
		this.dialog.closeAll();
	}

	private handleDataLoaded(data: any) {

		this.companyPreferences = data.company;

		if (this.isGolfSite) {
			this.holes = data.holes;
			this.geoGroups = data.regions;
		} else {
			this.controllers = data.controllers;
		};

		setTimeout(() => {
			if (this._leafletMap) {
				this._leafletMap.invalidateSize();
			}
		}, 100);

		setTimeout(() => {
			if (!this.hostElement.nativeElement.requestFullscreen) {
				if (this.isGolfSite) {
					const fullScreenButton = document.querySelector('.leaflet-fullscreen-control');
					fullScreenButton.classList.add('d-none');
				} else {
					/**
					 * Fullscreen is partial support on iOS. Only available on iPad, not on iPhone.
					 * iPad will not be seen the Fullscreen icon in the Map after lock screen.
					 */
					const isUnsupportedFullscreen = !this.hostElement.nativeElement.webkitRequestFullscreen &&
						!this.hostElement.nativeElement.msRequestFullscreen;
					const fullScreenButton = document.querySelector('.leaflet-fullscreen-control');
					if (isUnsupportedFullscreen && fullScreenButton) {
						fullScreenButton.classList.add('d-none');
						return;
					}
					if (!isUnsupportedFullscreen && !fullScreenButton) {
						// Re-add the Fullscreen icon in the Map after lock screen of the iPad device.
						this.mapInfo.addFullScreenControl(true);
					}
				}
			}
		}, 500);
		this.siteDataLoaded.emit(data);
	}

	// =========================================================================================================================================================
	// Drag and drop support
	// =========================================================================================================================================================

	onDrop(event: CdkDragDrop<any, any>) {
		const isController = event.item.data.controller != null;
		const isHole = this.holes.some(h => h.id === event.item.data.id);
		const isArea = event.item.data.area != null;

		const markerInfo = isController ? this.controllerMarkerInfo :
			(isHole ? this.holeMarkerInfo : (isArea ? this.areaMarkerInfo : this.stationMarkerInfo));
		this.mapInfo.drop(event, { offsetX: markerInfo.dragIconHotspotX, offsetY: markerInfo.dragIconHotspotY });
	}

	// =========================================================================================================================================================
	// Holes, Areas, and Stations utility functions
	// =========================================================================================================================================================

	controllerClicked(evt) {
		this.onControllerClicked(evt.event, evt.controller);
	}

	onControllerClicked(event: any, controller: ControllerListItem) {
		const x = event.clientX - this.mapContainer.nativeElement.getBoundingClientRect().left;
		const y = event.clientY - this.mapContainer.nativeElement.getBoundingClientRect().top + 10;
		this.contextMenuInfo = this.mapInfo.contextMenu.controllerOptions(controller, x, y, this.itemsMovable, true);
		if (this.contextMenuInfo.menuOptions.length > 0) {
			this.contextMenuTrigger.openMenu();
		}
	}

	sensorClicked(evt) {
		this.onSensorClicked(evt.event, evt.sensor);
	}

	onSensorClicked(event: any, sensor: Sensor) {
		const x = event.clientX - this.mapContainer.nativeElement.getBoundingClientRect().left;
		const y = event.clientY - this.mapContainer.nativeElement.getBoundingClientRect().top + 10;
		this.isSensorClicked = true;
		this.contextMenuInfo = this.mapInfo.contextMenu.sensorOptions(sensor, x, y, this.itemsMovable, true);
		if (this.contextMenuInfo.menuOptions.length > 0) this.contextMenuTrigger.openMenu();
	}

	stationClicked(evt) {
		this.onStationClicked(evt.event, evt.station);
	}

	onStationClicked(event: any, station: StationWithMapInfoLeaflet) {
		const x = event.clientX - this.mapContainer.nativeElement.getBoundingClientRect().left;
		const y = event.clientY - this.mapContainer.nativeElement.getBoundingClientRect().top + 10;
		this.isSensorClicked = false;

		this.mapService.stationManager.getStation(station.id).pipe(take(1)).subscribe((stationResponse: Station) => {
			station.lastManualRunStartTime = stationResponse.lastManualRunStartTime;
			station.lastRunTimeSeconds = stationResponse.lastRunTimeSeconds;
		}), error => { throw error }

		this.contextMenuInfo = this.mapInfo.contextMenu.stationOptions(station, x, y, this.itemsMovable, true);
		if (this.contextMenuInfo.menuOptions.length > 0) {
			this.contextMenuTrigger.openMenu();
			this.isConnectingController = false;
			this.contextMenuTrigger.menuClosed.subscribe(() => {
				this.contextMenuInfo = null;
				this.isEditingAreaColor = false;
			});
		}
		this.cdr.detectChanges();
	}

	holeClicked(evt) {
		this.onHoleClicked(evt.event, evt.hole);
	}

	onHoleClicked(event: any, hole: Area) {
		const x = event.clientX - this.mapContainer.nativeElement.getBoundingClientRect().left;
		const y = event.clientY - this.mapContainer.nativeElement.getBoundingClientRect().top + 10;
		this.contextMenuInfo = this.mapInfo.contextMenu.holeOptions(hole, x, y, this.itemsMovable, true);
		if (this.contextMenuInfo.menuOptions.length > 0) this.contextMenuTrigger.openMenu();
	}

	areaClicked(evt) {
		this.onAreaClicked(evt.event, evt.holeId, evt.area);
	}

	onAreaClicked(event: any, holeId: number, area: Area) {
		const x = event.clientX - this.mapContainer.nativeElement.getBoundingClientRect().left;
		const y = event.clientY - this.mapContainer.nativeElement.getBoundingClientRect().top + 10;
		const geoItemsForArea = this.mapInfo.getGeoItemsForArea(holeId, area.id);
		this.contextMenuInfo = this.mapInfo.contextMenu.areaOptions(holeId, area, geoItemsForArea[0], x, y, this.itemsMovable, true, null);
		if (this.contextMenuInfo.menuOptions.length > 0) this.contextMenuTrigger.openMenu();
	}

	onLayerSelectedChanged(event: { layer: MapLayer, selected: boolean }) {
		type fn = () => void;
		const commandList: { [key: string]: fn } = {
			[MapLayer.Holes]: () => this.mapInfo.showHoles(event.selected),
			[MapLayer.Areas]: () => this.mapInfo.showAreas(event.selected),
			[MapLayer.Controllers]: () => this.mapInfo.showControllers(event.selected),
			[MapLayer.ControllerName]: () => this.mapInfo.showControllersName(event.selected),
			[MapLayer.ControllerStatus]: () => this.mapInfo.showServerClientStatus(event.selected),
		  [MapLayer.ControllerRelationships]: () => this.mapInfo.showServerClientRelationships(event.selected),
		  [MapLayer.Sensors]: () => this.mapInfo.showSensors(event.selected),
		  [MapLayer.SensorNames]: () => this.mapInfo.showSensorNames(event.selected),
		  [MapLayer.StickyNotes]: () => this.mapInfo.showStickyNotes(event.selected),
		  [MapLayer.AddStickyNote]: () => this.mapInfo.addStickyNote(),
		  [MapLayer.Stations]: () => this.mapInfo.showStations(event.selected, true, MapLayer.Stations),
		  [MapLayer.StationNames]: () => this.mapInfo.showStationNames(event.selected, MapLayer.Stations),
		  [MapLayer.StationRuntimes]: () => this.mapInfo.showStationRuntimes(event.selected),
		  [MapLayer.NozzleColor]: () => this.mapInfo.showStationNozzleColors(event.selected),
		  [MapLayer.StationAdjustments]: () => this.mapInfo.showAdjustments(event.selected),
		  [MapLayer.StationCycleSoak]: () => this.mapInfo.showCycleSoak(event.selected),
		  [MapLayer.StationAreas]: () => this.mapInfo.showStationGeoAreas(event.selected, MapLayer.StationAreas),
		  [MapLayer.Irrigation]: () => this.mapInfo.showIrrigation(event.selected),
		  [MapLayer.Alerts]: () => this.mapInfo.showAlerts(event.selected),
		  [MapLayer.Moveable]: () => this.mapInfo.changeMoveAbility(event.selected),
		  [MapLayer.MasterValves]: () => this.mapInfo.showStations(event.selected, true, MapLayer.MasterValves),
		  [MapLayer.MasterValvesName]: () => this.mapInfo.showStationNames(event.selected, MapLayer.MasterValves),
		  [MapLayer.MasterValvesAreas]: () => this.mapInfo.showStationGeoAreas(event.selected, MapLayer.MasterValvesAreas),
			[MapLayer.Notes]: () => this.mapInfo.showNotes(event.selected, true),
			[MapLayer.NoteAnimation]: () => this.mapInfo.showNoteAnimation(event.selected)
		};
		commandList[event.layer] && commandList[event.layer]();
	}

	onBaseLayerSelectedChanged(event) {
		if (this.mapInfo) {
			this.mapInfo.changeBaseLayer(event);
		} else {
			setTimeout(() => {
				this.mapInfo.changeBaseLayer(event);
			}, 3000);
		}
	}

	onDownloadTiles() {
		this.mapInfo.downloadTiles();
	}

	onRemoveTiles() {
		this.mapInfo.removeDownloadedTiles();
	}

	onTextColorChanged(textColor: string) {
		this.mapInfo.textColorChanged(textColor);
	}

	onAddStickyNote() {
		const latlng = this.rightClickContextMenuInfo?.latlng;
		this.mapInfo.addStickyNote(latlng);
	}

	onAddNote() {
		this.openNoteDialog();
	}

	onNoteLocationClicked(e) {

		if (e && e.note) {
			if (e.note.status !== 1) {
				let bg = '';
				switch (e.note.notePriority) {
					case RbEnums.Note.NotePriority.Urgent:
						bg = ' bg-red';
						break;
					case RbEnums.Note.NotePriority.High:
						bg = ' bg-orange';
						break;
					case RbEnums.Note.NotePriority.Moderate:
						bg = ' bg-yellow text-carbon';
						break;
					case RbEnums.Note.NotePriority.Low:
						bg = ' bg-light-green text-carbon';
						break;
				
					default:
						break;
				}
				const icon = L.divIcon({
					className: `note-marker blinking`,
					html: '<div class="note-container look-at-me' + bg + '"><i class="mdi mdi-message-processing"></i></div>',
					iconSize: [30, 30],
					iconAnchor: [15, 15]
				});

				const noteMarker =  L.marker(
					[e.note.latitude, e.note.longitude],
					{ icon: icon }
				).addTo(this.leafletMap);

				setTimeout(() => {
					this.leafletMap.removeLayer(noteMarker);
				}, 5000);
			}

			const latlng = { lat: e.note.latitude, lng: e.note.longitude };
			if (!this.isMobile) {
				this.mapInfo.centerMap(latlng, 21, [380, 0]);
			} else {
				this.mapInfo.centerMap(latlng, 21);	
				this.closeSidebar();
			}
		}
	}

	openNoteDialog(note?: Note) {
		const user = this.authManager.getUserProfile();
		if (!note ||
			(note.attachedToId && note.attachedToType !== RbEnums.Note.NoteAnchor.Note)) {
			// New note invoked
			note = {
				id: 0,
				companyId: user.companyId,
				siteId: this.siteId,
				authorId: user.userId,
				latitude: 0,
				longitude: 0,
				content: '',
				isMinimized: true,
				notePriority: 1,
				noteType: 1,
				status: RbEnums.Note.NoteStatus.Active,
				attachedToType: RbEnums.Note.NoteAnchor.Note, // Default to Single Note
				isEditing: false,
				replies: []
			}
			const menuInfo = this.rightClickContextMenuInfo ?
				this.rightClickContextMenuInfo : this.contextMenuInfo;
			const position = menuInfo.latlng;
			if (position) {
				note.latitude = position.lat
				note.longitude = position.lng;
			}
			if (menuInfo?.station) {
				note.attachedToName = menuInfo.station.name;
				note.id = menuInfo.station.id
				note.attachedToType = RbEnums.Note.NoteAnchor.Station;
				note.latitude = menuInfo.station.latitude,
				note.longitude = menuInfo.station.longitude
			}
		}

		let dialogRef: MatDialogRef<NotePopupComponent> = this.dialog.open(NotePopupComponent, {
			width: '280px',
			maxWidth: '90%',
			data: {
				mapInfo: this.mapInfo,
				note,
				user,
				isWidget: this.isWidget,
				isGolfSite: this.isGolfSite,
				siteId: this.siteId,
			},
			closeOnNavigation: true,
			hasBackdrop: true,
			panelClass: 'rb-note-panel'
		});
	
		dialogRef.afterClosed().subscribe(() => dialogRef = null);
		dialogRef.componentInstance.invokeCreateUser.subscribe((event) => this.invokeCreateUser.emit(event));
	}

	onCenterMap() {
		const latlng = this.rightClickContextMenuInfo?.latlng;
		this.mapInfo.centerMap(latlng, this.mapService.ZOOM_LEVEL_STREET);
	}

	onSelectNearbyStations() {
		const latlng = this.rightClickContextMenuInfo?.latlng;
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NearbyStationSelectComponent);
    this.nearbyStationSelectRef = componentFactory.create(this.mapContainerView.injector);
		this.nearbyStationSelectRef.instance.latLng = latlng;

		// Get the pixel coordinates of the click on the map
		const point = this.leafletMap.latLngToContainerPoint(latlng);

		// Position the overlay at the click coordinates, adjusted for the width of the overlay
		this.nearbyStationSelectRef.location.nativeElement.style.position = 'absolute';
		this.nearbyStationSelectRef.location.nativeElement.style.left = `${point.x}px`;
		this.nearbyStationSelectRef.location.nativeElement.style.top = `${point.y + 50}px`;


		const circleLayer = new Circle(latlng, {
			radius: this.nearbyStationSelectRef.instance.radius,
			weight: 1,
			color: '#fff'
		});
		this.mapInfo.leafletLayers.push(circleLayer);
		this.nearbyStationSelectRef.instance.radiusChange.subscribe(radius => {
			if (circleLayer) {
        circleLayer.setRadius(radius);
      }
		});
		this.nearbyStationSelectRef.instance.close.subscribe((radius) => {
			if (radius) {
				this.mapInfo.selectNearbyStations(latlng, radius);
			}
			if (circleLayer) {
				this.leafletMap.removeLayer(circleLayer);
			}
			this.nearbyStationSelectRef.destroy();
			this.nearbyStationSelectRef = null;
		});

		// attach the component to the view
    this.mapContainerView.insert(this.nearbyStationSelectRef.hostView);

    // detect changes to make sure the component is rendered
    this.nearbyStationSelectRef.changeDetectorRef.detectChanges();
	}

	private showVisibleMarkers() {
		this.mapInfo.showHoles(this.leftSidebar.isLayerVisible(MapLayer.Holes), false);
		if (!this.isGolfSite && this.leftSidebar.isLayerVisible(MapLayer.MasterValves)) {
			this.mapInfo.showStations(this.leftSidebar.isLayerVisible(MapLayer.MasterValves) && this.showingDetailMarkers, 
				false, MapLayer.MasterValves);
		} else {
			this.mapInfo.showStations(this.leftSidebar.isLayerVisible(MapLayer.Stations) && this.showingDetailMarkers, 
				false, MapLayer.Stations);
		}

		setTimeout(() => {
			this.mapInfo.showAreas(this.leftSidebar.isLayerVisible(MapLayer.Areas) && this.showingDetailMarkers, false);
		}, 100);
	}

	private showVisibleMarkersCommercial() {
		this.mapInfo.showHoles(this.mapToolbarComponent.isLayerVisible(MapLayer.Holes), false);
		if (!this.isGolfSite && this.mapToolbarComponent.isLayerVisible(MapLayer.MasterValves)) {
			this.mapInfo.showStations(this.mapToolbarComponent.isLayerVisible(MapLayer.MasterValves) && this.showingDetailMarkers,
				false, MapLayer.MasterValves);
		} else {
			this.mapInfo.showStations(this.mapToolbarComponent.isLayerVisible(MapLayer.Stations) && this.showingDetailMarkers,
				false, MapLayer.Stations);
		}

		setTimeout(() => {
			this.mapInfo.showAreas(this.mapInfo.layerVisibility.showingAreas && this.showingDetailMarkers, false);
		}, 100);
	}

	private cleanMapSubscriptions() {
		if (this.mapSubs != null) {
			this.mapSubs.unsubscribe();
			this.mapSubs = null;
		}
	}

	handleCloseStationAreaContextMenu() {
		this.contextMenuTrigger.menuClosed.subscribe(() => {
			this.isEditingFromStationAreaContextMenu = false;
			this.contextMenuInfo = null;
		});
		this.isEditingFromStationAreaContextMenu = false;
		this.contextMenuInfo = null;
		this.contextMenuTrigger.closeMenu();
	}

	handleCloseStationContextMenu() {
		this.isEditingAreaColor = false;
	}

	getStationIDForContextMenu() {
		if (this.contextMenuInfo && "station" in this.contextMenuInfo) {
			return this.contextMenuInfo.station.id;
		} else {
			return "na";
		}
	}

	openControllerAutoConnectModal(needConnectControllers: ControllerWithMapInfoLeaflet[]) {
		this.selectedControllerWithMapInfoLeaflet = needConnectControllers;
		this.dialog.open(this.controllerAutoConnectModal);
	}

	onStartMultiStations() {
		this.dialog.closeAll();
		this.mapInfo.multiSelectActionInvoked.next(RbEnums.Map.StationContextMenu.Start);
	}

	/**
	 * Sets the map's center based on available info in the following order of preference
	 * 1 - If we find valid location information in the mapInfo.prefs.sitePreferences, set the view to the 
	 * sitePreferences.center lat and long and then zoom to the site preferences' zoom.
	 * 2 - If we do not have valid info in mapInfo.prefs.sitePreferences, we check to see if the user is allowing
	 * us to use their location, and if so:
	 *  · if the user location marker has a value, that value will be used to set the map's center and the zoom will be set to street level. 
	 *  · Otherwise, the map service will try to retrieve the user's location and if it can  find it, then the map will be centered to that 
	 *    location at zoom 15. 
	 *  · Failing both of those - the user location marker has no value and we cannot retrieve the user's location successfully - call ourselves.
	 * 3 - Set up a subscription to watch for siteData to load, and then
	 *  · If we get a location from siteAddressLookupCallbackFactory, then center that at street level
	 *  · If we didn't get a location from siteAddressLookupCallbackFactory but we do have an address, then maybe our internet connection is down,
	 *    or we couldn't reach google maps, or google maps wasn't initialized - call the onGoHome method that will try to address those issues.
	 *  · Failing both of those, if we have successfully looked up company address and it has values for lat & long then set _leafletMap's view
	 *    to those coordinates at street level zoom.
	 */
	private setMapsCenter() {

		if (this.mapInfo.prefs.sitePreferences.center &&
			this.mapInfo.prefs.sitePreferences.center.latitude &&
				this.mapInfo.prefs.sitePreferences.center.longitude &&
				(this.mapInfo.prefs.sitePreferences.center.latitude > 1 ||
				this.mapInfo.prefs.sitePreferences.center.latitude < -1) &&
				(this.mapInfo.prefs.sitePreferences.center.longitude > 1 ||
					this.mapInfo.prefs.sitePreferences.center.longitude < -1)) {
			this._leafletMap.setView(
				[
					this.mapInfo.prefs.sitePreferences.center.latitude,
					this.mapInfo.prefs.sitePreferences.center.longitude,
				],
				this.mapInfo.prefs.sitePreferences.zoom
			);
		} else if (this.mapService.userLocationAvailabilityResponse.value != false) {
			// Try to center the map on user's location under the following conditions:
			// - We don't know yet whether the user allowed us to use their location
			// - We know that the user DID allow us to use their location

			// Check if userLocationMarker already exists
			if (this.mapInfo.userLocationMarker) {
				this._leafletMap.setView(this.mapInfo.userLocationMarker.getLatLng(), this.mapService.ZOOM_LEVEL_STREET);
			} else {

				// userLocationMarker doesn't exist yet, so subscribe to the response of userLocationAvailabilityResponse
				this.mapService.userLocationAvailabilityResponse.pipe(
					filter(value => value != null), // filter out the default 'null' value
					timeout(3000), // if first user location takes too long resort to other means of centering the map
					take(1),
					untilDestroyed(this))
					.subscribe({
						next: (userLocationAvailable) => {

							if (userLocationAvailable) {
								// If allowed, use the user's location
								this._leafletMap.setView(this.mapInfo.userLocationMarker.getLatLng(), 15);
							} else {
								// If not allowed, use the regular means of setting the maps center
								this.setMapsCenter();
							}
						},
						error: () => { // Timeout error
							this.setMapsCenter();
						}
					});
			}

		} else {

			this.mapSubs = this.mapInfo.siteDataLoaded.pipe(untilDestroyed(this)).subscribe(data => {
				if (this.mapInfo.site.latitude && this.mapInfo.site.longitude) {
					this._leafletMap.setView([this.mapInfo.site.latitude, this.mapInfo.site.longitude], this.mapService.ZOOM_LEVEL_STREET);
				} else if (this.mapInfo.site.address) { // Check against empty or null address
					this.onGoHome();
				} else if (this.mapInfo.companyAddressLookupComplete && (this.mapInfo.companyLatitude && this.mapInfo.companyLongitude)) {
					this._leafletMap.setView([this.mapInfo.companyLatitude, this.mapInfo.companyLongitude], this.mapService.ZOOM_LEVEL_STREET);
				}
			});

		}
	}

}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="controller-mobile-cell">
	<div class="controller-name"><span class="ellipsis-text">{{controller?.name}}</span></div>

	<div class="status-buttons">
		<div class="controller-grid-status-container" *ngIf="firmwareUpdateProgress">
			<span class="fa-stack">
				<mat-icon class="fw-progress-icons fw-progress-icon-center">adjust</mat-icon>
				<mat-icon class="fw-progress-icons fw-progress-icon-track">panorama_fish_eye</mat-icon>
			</span>

			<mat-progress-spinner
				mode="determinate"
				[value]="firmwareUpdateProgress.progress"
			>
			</mat-progress-spinner>
		</div>

		<div *ngIf="!firmwareUpdateProgress">
			<div *ngIf="!isGolfSite" class="b tc w-40-m mobile-sync-icon-container mr-1" [innerHTML]="getControllerStatusCellRenderer()"></div>
			<div *ngIf="isGolfSite" class="b tc w-40-m mobile-sync-icon-container mr-1"> {{ getInterfaceStatusCellRenderer() }} </div>
		</div>
	</div>

	<div *ngIf="!isGolfSite" class="controller-iq-net">
		<span class="controller-label">{{'STRINGS.IQ_NET' | translate}}:</span>
		<span class="controller-value">{{controller?.iqNetTypeShortDescription}}</span>
	</div>

	<div class="controller-type">
		<span class="controller-label">{{'STRINGS.TYPE' | translate}}:</span>
		<span class="controller-value">{{controller?.typeDescription}}</span>
	</div>

	<div *ngIf="!isGolfSite" class="stations-programmed">
		<span class="controller-label">{{'STRINGS.STATIONS_PROGRAMMED' | translate}}:</span>
		<span class="controller-value">{{controller?.stationsProgrammedCount}}</span>
	</div>

	<div *ngIf="!isGolfSite" class="programs-used">
		<span class="controller-label">{{'STRINGS.PROGRAMS_USED' | translate}}:</span>
		<span class="controller-value">{{controller?.programsUsedCount}}</span>
	</div>

	<div *ngIf="isGolfSite && controller?.parentId" class="station-configuration">
		<span class="controller-label">{{'STRINGS.STATION_CONFIGURATION' | translate}}:</span>
		<span class="controller-value">{{controller?.stationCount}}</span>
	</div>

	<div *ngIf="isGolfSite && controller?.parentId" class="channel-a">
		<span class="controller-label">{{'STRINGS.CHANNEL_A' | translate}}:</span>
		<span class="controller-value">{{controller?.channelA}}</span>
	</div>

	<div *ngIf="isGolfSite && controller?.parentId" class="channel-b">
		<span class="controller-label">{{'STRINGS.CHANNEL_B' | translate}}:</span>
		<span class="controller-value">{{controller?.channelB}}</span>
	</div>

	<div *ngIf="isGolfSite && controller?.parentId" class="channel-c">
		<span class="controller-label">{{'STRINGS.CHANNEL_C' | translate}}:</span>
		<span class="controller-value">{{controller?.channelC}}</span>
	</div>

<!--	<div class="flex justify-between">-->
<!--		<div class="ellipsis-text f7-m w-50-m">{{controller?.siteName}}</div>-->
<!--		<div class="f7-m tr w-50-m mr-1"> {{controller?.iqNetTypeString}}</div>-->
<!--	</div>-->
<!--	<div class="flex justify-between">-->
<!--		<div class="ellipsis-text b w-60-m"> {{controller?.name}} </div>-->

<!--		<div class="controller-grid-status-container" *ngIf="firmwareUpdateProgress">-->
<!--			<span class="fa-stack">-->
<!--				<mat-icon class="fw-progress-icons fw-progress-icon-center">adjust</mat-icon>-->
<!--				<mat-icon class="fw-progress-icons fw-progress-icon-track">panorama_fish_eye</mat-icon>-->
<!--			</span>-->

<!--			<mat-progress-spinner-->
<!--				mode="determinate"-->
<!--				[value]="firmwareUpdateProgress.progress"-->
<!--			>-->
<!--			</mat-progress-spinner>-->
<!--		</div>-->

<!--		<div *ngIf="!firmwareUpdateProgress">-->
<!--			<div *ngIf="!isGolfSite" class="b tc w-40-m mobile-sync-icon-container mr-1" [innerHTML]="getControllerStatusCellRenderer()"></div>-->
<!--			<div *ngIf="isGolfSite" class="b tc w-40-m mobile-sync-icon-container mr-1"> {{ getInterfaceStatusCellRenderer() }} </div>-->
<!--		</div>-->
<!--	</div>-->
</div>

import { EventEmitter } from '@angular/core';
import { RainWatchData } from './rain-watch-data.model';
import { RbUtils } from '../../common/utils/_rb.utils';

export class SensorStatusChange {

	// Events, private data
	timeRemainingChange = new EventEmitter<number>();

	// private _timerRef;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.changeDateTime && json.changeDateTime instanceof String) {
				this.changeDateTime = RbUtils.Conversion.convertStringToDate(json.changeDateTime);
			}
			if (json.rainWatchData) {
				this.rainWatchData = new RainWatchData(json.rainWatchData);
			}
		}
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================

	companyId?: number;
	satelliteId?: number;
	changeDateTime: Date;
	errorMessage?: string = null;

	/**
	 * sensorId indicates the Id value of the Sensor whose status is to be updated.
	 */
	sensorId: number;

	/**
	 * sensorResponseId indicates the Id value of the SensorResponse whose status is to be updated. Note
	 * that the response Id may be null, if the change is to the Sensor object itself.
	 */
	sensorResponseId: number;

	/**
	 * sensorTriggerId indicates the Id value of the SensorTrigger whose status is to be updated. Note
	 * that the Trigger Id may be null, if the change is to the Sensor object itself.
	 */
	sensorTriggerId: number;

	/**
	 * CurrentValue represents the newest sensor value and is sent with the SensorValue change type.
	 * Note the declaration is an 'object' and the value could be a uint, a decimal, etc., depending
	 * on the configuration. The value is returned in the highest-level units known to the system.
	 * That is, if the value is from an A/D and, as far as the sensor knows, it's a straight voltage,
	 * it will be a decimal and its units will be "V". If there is some additional information known,
	 * such as that the voltage represents temperature from a temperature sensor, the value will be
	 * converted into those higher-level units and returned as a decimal with units of "°F" or "°C",
	 * perhaps.
	 */
	currentValue: any;

	/**
	 * CurrentValueUnits represents the units associated with the CurrentValue above. The value could
	 * be "counts" or "pulses" for a pulse counter with no K value, etc. specified, or it could be
	 * "gpm", "volts", "mA", etc.
	 */
	currentValueUnits: string;

	/**
	 * reasonCode may be set to null or NoError to indicate a successful status change. Otherwise
	 * the changeType indicated failed and the reason code indicates how/why. See
	 * SensorFailureReasonCode for values.
	 */
	reasonCode?: string = null;

	/**
	 * changeType defines the type of change being reported by the sensor, trigger, or response.
	 */
	changeType: string;

	/**
	 * Rain Watch detail data (rain accumulated toward pause threshold, shutdown threshold, etc.) This property
	 * should only be set when changeType == SensorValue. It doesn't make sense in the other cases.
	 */
	rainWatchData: RainWatchData;

	sensorName?: string;

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	// Method to start runTimeRemaining countdown timer. This timer will tick down the remaining seconds in between receiving new StationStatusChange
	// via SignalR. The event fired within the timer will be caught by StationListItems and ProgramStepListItems to update the time remaining in the UI.
	// private startCountdownTimer() {
	// 	if (this._timerRef) clearInterval(this._timerRef);

	// 	this._timerRef = setInterval(() => {
	// 		if (this.cycleTimeRemaining - 1 >= 0) {
	// 			--this.cycleTimeRemaining;
	// 			this.timeRemainingChange.emit(this.cycleTimeRemaining);
	// 		} else if (this.soakTimeRemaining - 1 >= 0) {
	// 			--this.soakTimeRemaining;
	// 			this.timeRemainingChange.emit(this.soakTimeRemaining);
	// 		} else {
	// 			clearInterval(this._timerRef);
	// 		}
	// 	}, 1000);
	// }

	// private get showTimeRemaining(): boolean {
	// 	switch (this.changeType.toLowerCase()) {
	// 		case 'runningupdate':
	// 		case 'started':
	// 		case 'soakstart':
	// 			return true;

	// 		default:
	// 			return false;
	// 	}
	// }

	// private hasRuntime(): boolean {
	// 	return (this.rainWatchData != null
	// 		&& (this.rainWatchData.rainWatchTimeSinceLastRain && this.rainWatchData.rainWatchTimeSinceLastRain > 0) ||
	// 		(this.soakTimeRemaining && this.soakTimeRemaining > 0);
	// }

}

export class CatalogFlowRate {

	id: number;
	nozzleId: number;
	pressure: number;
	radiusHead: number;
	radiusRow: number;
	flowRate: number;
	units: number;
	showedValue?: string;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

<rb-dialog
        [title]="dialogTitle | translate"
        [headerIcon]="'search'"
        [hideCloseButton]="true"
        [submitButtonLabel]="'STRINGS.CLOSE' | translate"
        [showSubmitButton]="true"
        (onSubmit)="onClose()"
>
    <div class="station-search-container">

        <div class="station-search-filter-container">
            <mat-form-field class="station-select" [ngClass]="{'isMobile': isMobile}">
                <mat-label>{{'STRINGS.STATION_NAME' | translate | titlecase}}</mat-label>
                <input #autoCompleteInput type="text" matInput [formControl]="stationFilter" [(ngModel)]="station" [placeholder]="'STRINGS.SELECT_A_STATION' | translate" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption="true" disableRipple="true" (opened)="onPanelOpened()" (optionSelected)="onOptionSelected($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button mat-raised-button color="primary" type="button" data-qaid="station-search-button" (click)="onFind()">
                {{ 'STRINGS.FIND' | translate }}
            </button>
        </div>

        <div *ngIf="true" class="station-search-grid-label">
            <span>{{'STRINGS.SHOWING_RESULTS_FOR' | translate}}: {{resultsStationName}}</span>
        </div>

        <div class="station-search-grid-container">
            <rb-grid-container
                #gridContainer
                *ngIf="isGridDataLoaded"
                [isFadableGrid]="true"
                [isGridDisplayable]="isGridDisplayable"
                [hideRowSelection]="true"
                [rowData]="rowData"
                [mobileNavigation]="false"
                [mobileRightArrow]="false"
                [mobileCellRendererComponent]="StationSearchMobileCellRendererComponent"
            >
                <rb-column
                        [headerName]="'STRINGS.PROGRAM' | translate"
                        [field]="isGolfSite ? 'programGroupName' : 'programName'"
                        [minWidth]="150"
                        [cellRendererFramework]="HyperlinkCellRenderer"
                        [cellRendererParams]="{linkClick: onProgramLinkClick.bind(this)}"
                        [isMobileFilter]="true"
                ></rb-column>
                <rb-column
                        *ngIf="isGolfSite"
                        [headerName]="'STRINGS.SCHEDULE_VERB' | translate"
                        field="programName"
                        [minWidth]="150"
                        [cellClass]="'wrap-content cellStyle'"
                        [autoHeight]="true"
                        [cellRendererFramework]="HyperlinkCellRenderer"
                        [cellRendererParams]="{linkClick: onScheduleLinkClick.bind(this)}"
                ></rb-column>
                <rb-column
                        *ngIf="isGolfSite"
                        [headerName]="'STRINGS.STATUS' | translate"
                        field="status"
                        [minWidth]="150"
                        [autoHeight]="true"
                ></rb-column>
            </rb-grid-container>

            <rb-waiting-indicator
                    *ngIf="showWaitingIndicator"
                    [isVisible]="showWaitingIndicator"
                    [error]="loadError"
                    [text]="waitIndicatorText"
            ></rb-waiting-indicator>

            <rb-no-data-overlay
                    *ngIf="rowData == null || rowData.length < 1"
                    [displayText]="'STRINGS.NO_DATA_TO_DISPLAY' | translate"
                    [instructionsText]="'SPECIAL_MSG.STATION_SEARCH_INSTRUCTIONS' | translate"
            ></rb-no-data-overlay>
        </div>
    </div>

</rb-dialog>

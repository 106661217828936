<!--suppress TypeScriptUnresolvedVariable -->

<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="fl">
 	<div *ngIf="!isChangingNonCurrentUserPinCode" class="fl w-50">
		<mat-form-field>
			<input
				matInput
				type="password"
				[placeholder]="'STRINGS.PIN_CODE' | translate"
				name="Password"
				value="******"
				(focus)="$any($event.target).select()"
			/>
		</mat-form-field>
	</div>
	<div [ngClass]="{'fl w-50 pa2': !isChangingNonCurrentUserPinCode}">
 		<button class="outlined-button" (click)="handleChangePinCode()">{{changeButtonText | translate }}</button>
	</div>
</div>
<ng-template #updatePin>
	<rb-dialog
		[title]="'STRINGS.CHANGE_PIN_CODE' | translate"
		[closeButtonLabel]="'STRINGS.CANCEL' | translate "
		[submitButtonLabel]="'STRINGS.OK' | translate"
		[showSubmitButton]="true"
		[closeOnSubmit]="false"
		(onSubmit) = "onSubmit()"
		(onCancel)="onCancel()"
		[saveDisabled]="!editForm?.valid || !editForm?.dirty"
	>
		<form [formGroup]="editForm" class="side-panel-body flex">
			<fieldset>
				<mat-form-field class="pt4">
					<input formControlName="pin" matInput type="number" [placeholder]="'STRINGS.PIN_CODE' | translate"
						   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						   (focus)="$any($event.target).select()" [max]="RbConstants.Form.PIN_CODE_RANGE.max" [min]="RbConstants.Form.PIN_CODE_RANGE.min"/>
				</mat-form-field>
				<div *ngIf="pinErrorMessage" class="warnColorText">{{pinErrorMessage | translate}}</div>

 			</fieldset>
		</form>
	</rb-dialog>
</ng-template>

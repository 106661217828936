/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Array.xxx
 * 		 See: _rb.utils.ts
 */

export namespace XXUseRbUtilsNamespace {

	/**
	 * SelectorCallback provides a selection function for choosing the property or properties which are used
	 * to compare array elements for difference, etc. below. The result of the selector just has to be
	 * comparable for equality.
	 */
	type SelectorCallback<T> = (t: T) => any;

	/**
	 * ChooserCallback provides a function to choose one of two objects to be returned during intersect operations
	 * when the same object is found in both arrays. a and b correspond to the a and b inputs to Array.intersect().
	 */
	type ChooserCallback<T> = (a: T, b: T) => T;

	export abstract class Array {

		/**
		 * Subtract 'subtract' from 'original' yielding array of items in 'original' but not 'subtract'
		 * @param original - The base array.
		 * @param subtract - Items to subtract from the array.
		 * @param selector - SelectorCallback picking the comparison values from each array element for
		 * differencing.
		 * @returns T[] containing the items in 'original' but not in 'subtract'.
		 */
		static difference<T>(original: T[], subtract: T[], selector: SelectorCallback<T>): T[] {
			// Map the subtract array to the selected values from the selector.
			const subtractSelected = subtract.map(selector);
			return original.filter(o => !subtractSelected.includes(selector(o)));
		}

		/**
		 * Find items common to both input arrays, yielding array of items found in both arrays.
		 * @param a
		 * @param b
		 * @param selector
		 */
		static intersect<T>(a: T[], b: T[], selector: SelectorCallback<T>, chooser: ChooserCallback<T>): T[] {
			const aSelected = a.map(selector);
			const bSelected = b.map(selector);
			const common = aSelected.filter(aitem => bSelected.includes(aitem));

			// Now use the chooser to return the corresponding item from either a or b for each common item.
			return common.map(c => {
				const acandidate = a.find(item => selector(item) === c);
				const bcandidate = b.find(item => selector(item) === c);

				// Have the chooser choose.
				return chooser(acandidate, bcandidate);
			});
		}

	}

}

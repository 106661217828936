import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { DomSanitizer } from '@angular/platform-browser';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { ScheduledReport } from '../../../../../api/scheduled-reports/models/scheduled-report.model';

@Component({
	selector: 'rb-scheduled-report-mobile-cell-renderer',
	templateUrl: './scheduled-report-mobile-cell-renderer.component.html',
	styleUrls: ['./scheduled-report-mobile-cell-renderer.component.scss']
})
export class ScheduledReportMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	scheduledReport: ScheduledReport;

	constructor(
		private sanitizer: DomSanitizer,
		injector: Injector,
	) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.scheduledReport = params.data;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getReportCellRenderer() {
		const cellHtml = RbUtils.GridCellStyles.getReportTypeCell(RbUtils.Reports.scheduledTypes[this.scheduledReport.typeId], this.scheduledReport.subject);
		// cellHtml includes styling attribute, sanitizes untrusted values for HTML, turn it into a value that's safe to insert into the DOM
		return this.sanitizer.bypassSecurityTrustHtml(cellHtml);
	}
}

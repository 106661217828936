/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.User.currentUserId
 * 		 See: _rb.utils.ts
 */

import { AppInjector } from '../../core/core.module';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { CultureSettings } from '../../api/culture-settings/models/culture-settings.model';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from './_rb.utils';

export namespace XXUseRbUtilsNamespace {

	export abstract class User {

		private static _authManager: AuthManagerService;

		private static get authManager() {
			if (!User._authManager) {
				User._authManager = AppInjector.get(AuthManagerService);
			}
			return User._authManager;
		}

		static get currentUserId(): number {
			return User.authManager.getUserProfile().userId;
		}

		static get unitsType(): RbEnums.Common.UnitsType {
			return User.authManager.userCulture.unitType;
		}

		static get cultureSettings(): CultureSettings {
			return User.authManager.userCulture;
		}

		static get timeFormat(): string {
			return RbUtils.Common.getTimePickerDisplayValue(User.cultureSettings);
		}

		static get dateFormat(): string {
			return RbUtils.Common.getDateFormat(User.cultureSettings);
		}

	}
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';

@Component({
	selector: 'rb-runtime-mobile-cell-renderer',
	templateUrl: './runtime-mobile-cell-renderer.component.html',
	styleUrls: [ './runtime-mobile-cell-renderer.component.scss' ]
})
export class RuntimeMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	runtime: any;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.runtime = params.data;
	}
}

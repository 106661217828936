<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="waiting-indicator-container w-100 h-100" [ngClass]="{'waiting-indicator-fade-out': !isVisible}" [style.background]="backgroundColor">
	<div *ngIf="!showError" class="waiting-indicator-content" [ngStyle]="{'color': color}">
		<div id="progress-spinner-wrapper">
			<img class="progress-spinner--large" src="../../../../assets/loader.svg"/>
			<div id="waiting-indicator-progress" *ngIf="percentComplete >= 0">{{percentComplete}}%</div>
		</div>
		<div *ngIf="text" class="waiting-text status-text">{{ text }}</div>

	</div>

	<div *ngIf="showError" class="waiting-error-content" [ngStyle]="{'color': color, 'max-width': '400px'}">
		<div class="waiting-text" [ngStyle]="{'font-size': fontSize}" [innerHTML] ="error | translate"></div>
		<div id="waiting-error-btn-container">
			<rb-primary-button label="{{ 'STRINGS.OK' | translate }}" (click)="onOkClick()"></rb-primary-button>
		</div>
	</div>
</div>

<div *ngIf="!hideBackArrow">
	<rb-icon-button
		*ngIf="!(isMobile && isGolf)"
		icon="arrow_back"
		(iconButtonClicked)="onButtonClick()"
		class="breadcrumb-arrow-back"
		attr.data-qaid="breadcrumb-back-{{header | replaceWhitespace: '_' }}">
	</rb-icon-button>

	<rb-back-button *ngIf="isMobile && isGolf" class="breadcrumb-arrow-back"></rb-back-button>
</div>

<h1 class="header1 breadcrumb--h1 ma0 fw5" [ngClass]="{'no-arrow': hideBackArrow}">
	<span class="header-txt" [innerHtml]="header | translate"></span>
	<mat-icon class="rb-icon-font icon-question" [matMenuTriggerFor]="helpContent" *ngIf="titleTooltip"></mat-icon>
</h1>

<div id="bc-controller-connection-status-container" *ngIf="isConnected">
	<mat-icon [matTooltip]="'STRINGS.CONNECTED_TO_CONTROLLER' | translate" matTooltipPosition="above">lens</mat-icon>
</div>

<mat-menu #helpContent="matMenu"  yPosition="above" class="mat-menu-et-info">
	<div style="padding: 10px;">
		- {{titleTooltip}}
	</div>
</mat-menu>

import * as moment from 'moment';
import { CCWeatherDataService, WeatherTimeStep } from '../../../../../api/ccweather/ccweather-data.service';
import { Component, Input, OnInit } from '@angular/core';
import { CCWeatherCondition } from '../../../../../api/ccweather/models/ccweather-condition.model';
import { CCWeatherValuesData } from '../../../../../api/ccweather/models/ccweather-values-data.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-ccweather-widget-current',
	templateUrl: './ccweather-widget-current.component.html',
	styleUrls: ['./ccweather-widget-current.component.scss']
})
export class CCWeatherWidgetCurrentComponent implements OnInit {
	@Input() widgetId: number;
	@Input() isIq4SidebarWidget = false;
	@Input() theme = '';

	currentData: CCWeatherValuesData = new CCWeatherValuesData();
	showFullData = false;
	requiredConditions: string[] = [];
	conditions: CCWeatherCondition[] = [];

	constructor(private ccWeatherDataService: CCWeatherDataService,
				private matIconRegistry: MatIconRegistry,
				private translate: TranslateService,
				private domSanitizer: DomSanitizer) {
		// Create the mat-icon values used in our HTML. Since the URL reference has to be a SafeResourceUrl, we must pass the SVG
		// file location through the DomSanitizer to reference it. Note that we should NOT pass user-entered data here but we are
		// fine directly referencing the URL path since it's part of the app. If you don't agree that the path is safe, pass the
		// URL to .sanitize() instead to clean it of any embedded JavaScript, etc.
		this.matIconRegistry.addSvgIcon(
			'eye_open',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/ccweather_icons/eye_open_small.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'eye_closed',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/ccweather_icons/eye_closed_small.svg')
		);
	}

	ngOnInit() {
		// NOTE: We do not have an et0 value for current conditions.
		this.requiredConditions = ['temperature', 'pressureSurfaceLevel', 'temperatureMinMax', 'humidity', 'wind', 'precipitationIntensity', 'dewPoint'];
		this.conditions = this.ccWeatherDataService.getSettings(this.widgetId, this.requiredConditions).conditions;
		this.currentData = this.ccWeatherDataService.getWeatherForecast(this.widgetId, WeatherTimeStep.CURRENT).intervals[0].values;
		this.ccWeatherDataService.weatherForecastLoaded.subscribe(() => {
			this.currentData = this.ccWeatherDataService.getWeatherForecast(this.widgetId, WeatherTimeStep.CURRENT).intervals[0].values;
		});
	}

	onShowFullDataClick() {
		this.showFullData = !this.showFullData;
	}

	public getIcon(weatherCode: number): string {
		return '../../../../../assets/images/ccweather_icons/' + this.modifyWeatherCode(weatherCode) + '.png';
	}

	public getWeatherName(weatherCode): string {
		return this.ccWeatherDataService.getWeatherName(this.modifyWeatherCode(weatherCode));
	}

	private modifyWeatherCode(weatherCode: number): number | string {
		if (this.ccWeatherDataService.DAY_NIGHT_DOUBLE_CODES.includes(weatherCode) && this.isNight()) {
			return weatherCode + this.ccWeatherDataService.NIGHT_CODE_PART;
		}
		return weatherCode;
		}

	private isNight(): boolean {
		return moment().isBefore(this.currentData.sunriseTime) || moment().isAfter(this.currentData.sunsetTime);
	}

	getFieldValue(field) {
		let value;
		switch (field.fieldName) {
			case 'temperatureMinMax':
				value = this.currentData.temperatureMax === null ? '-' :
					this.currentData.temperatureMax + field.units + ' / ' + this.currentData.temperatureMin + field.units;
				break;
			case 'wind':
				value = this.currentData.windSpeed === null ? '-' :
					this.currentData.windDirection + ' / ' + this.currentData.windSpeed + ' ' + field.units;
				break;
			case 'temperature':
			case 'dewPoint':
				value = this.currentData[field.fieldName] === null ? '-' :
					this.currentData[field.fieldName] + field.units;
				break;
			default:
				value = this.currentData[field.fieldName] === null ? '-' :
					this.currentData[field.fieldName] + ' ' + field.units;
				break;
		}
		return value;
	}

	getWindSpeed() {
		return this.currentData.windSpeed === null ?
			'-' : (this.currentData.windSpeed + '\n' + this.conditions.find(condition => condition.fieldName === 'wind').units);
	}

	getTooltip(hint) {
		return this.translate.instant('STRINGS.' + hint);
	}

	onWeatherIconClick(event) {
		event.preventDefault();
		event.stopPropagation();
		return false;
	}
}

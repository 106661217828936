import { ApiService, HttpMethod } from '../_common/api.service';
import { AddressBookEntry } from './models/address-book-entry.model';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AddressBookApiService extends ApiService {

	clearCache() {
		super.clearCache(this.baseGetContactsUrl);
	}

	createNewContact(contact: AddressBookEntry) {
		return this.apiRequest<any>(this.createContactUrl, HttpMethod.Post, contact);
	}

	deleteContacts(contactIds: number[]): Observable<void> {
		return this.apiRequest(this.deleteContactsUrl(contactIds), HttpMethod.Delete, contactIds);
	}

	getContacts(bypassCache: boolean, includeCultureSettings: boolean): Observable<ApiCachedRequestResponse<AddressBookEntry[]>> {
		return this.apiRequestWithCache<any>(this.getContactsUrl(includeCultureSettings), bypassCache, result => result.map(c => new AddressBookEntry(c)));
	}

	updateContact(contactId: number, updateValues: any): Observable<null> {
		return this.apiRequest<any>(this.updateContactUrl(contactId), HttpMethod.Patch,
			this.patchTransform(updateValues));
	}

	/**
	 * @description UpdateContacts performs an API update of the indicated list of AddressBook items specified by Id.
	 * @param contactIds The list of items to be changed, by ID.
	 * @param updateValues Object containing the properties to be changed.
	*/
	updateContacts(contactIds: number[], updateValues: object) {
		return this.apiRequest<any>(this.updateBatchesUrl(contactIds, updateValues), HttpMethod.Patch,
			{ ids: contactIds, patch: updateValues });
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}AddressBook`; }
	private get baseGetContactsUrl(): string { return `${this.baseUrl}/GetAddressBooks`; }

	private getContactsUrl(includeCultureSettings: boolean = false): string {
		return `${this.baseGetContactsUrl}?includeCultureSettings=${includeCultureSettings}`;
	}
	private get createContactUrl(): string { return `${this.baseUrl}/CreateAddressBook`; }

	private deleteContactsUrl(contactIds: number[]): string {
		return `${this.baseUrl}/DeleteAddressBooks`;
	}

	private updateBatchesUrl(addressBookIds: number[], addressBookChanges: object) {
		return `${this.baseUrl}/UpdateBatches`;
	}

	private updateContactUrl(id: number): string { return `${this.baseUrl}/Update?addressBookId=${id}`; }
}

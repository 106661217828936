import * as moment from 'moment';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class Snapshot {

	name: string;
	companyId: number;
	companyUUID: string;
	siteId: number; // Not used for user list
	date: Date;
	satelliteId: number; // Not used for site or user lists
	userId: number; // Not used for site or satellite list
	reason: RbEnums.Common.SnapshotReasonType;
	isSuccess: boolean; // Only used in UI

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.date) { this.date = moment.utc(json.date).toDate(); } // Data is UTC
		}
	}

	get reasonString(): string {
		if (this.reason === RbEnums.Common.SnapshotReasonType.Manual) return RbUtils.Translate.instant('STRINGS.MANUAL');
		if (this.reason === RbEnums.Common.SnapshotReasonType.ReverseSync) return RbUtils.Translate.instant('STRINGS.REVERSE_SYNC');
		if (this.reason === RbEnums.Common.SnapshotReasonType.Deleted) return RbUtils.Translate.instant('STRINGS.DELETED');
		return RbUtils.Translate.instant('STRINGS.UNKNOWN');
	}
}

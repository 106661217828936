import { ApiService, HttpMethod } from '../_common/api.service';

import { Controller } from '../controllers/models/controller.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RadioRelay } from './models/radio-relay.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { Tbos } from './models/tbos.model';
import { TbosAutomaticAction } from './models/tbos-automatic.actions.mode';
import { TbosNetwork } from './models/tbos-network.model';

@Injectable({
	providedIn: 'root'
})
export class TbosService extends ApiService {

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	getTbosNetwork(companyId: number, controllerId: number): Observable<TbosNetwork[]> {
		return this.apiRequest(this.getTbosNetworkUrl(companyId, controllerId), HttpMethod.Get);
	}

	updateRadioRelays(radioRelayIds: number[], radioRelayUpdate: object): Observable<null> {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch, { ids: radioRelayIds, patch: this.patchTransform(radioRelayUpdate) });
	}

	learnTbosNetwork(satelliteId: number, learningType: RbEnums.Common.TbosNetworkLearningType, connectionLifetime?: number): Observable<null> {
		return this.apiRequest<any>(this.learnTbosNetworkUrl(satelliteId, learningType, connectionLifetime), HttpMethod.Post);
	}

	getRadioRelay(radioRelayId: number): Observable<RadioRelay> {
		return this.apiRequest(this.getRadioRelayUrl(radioRelayId), HttpMethod.Get);
	}

	getServerSatellite(controllerId: number): Observable<Controller> {
		return this.apiRequest(this.getServerSatelliteUrl(controllerId), HttpMethod.Get);
	}

	getRadioRelays(): Observable<RadioRelay[]> {
		return this.apiRequest<any>(this.getRadioRelaysUrl, HttpMethod.Get)
	}

	getTbosSatellites(): Observable<Tbos[]> {
		return this.apiRequest<any>(this.getTbosSatellite, HttpMethod.Get)
	}

	getTbosProgramsStations (tbosId:number): Observable<Tbos> {
		return this.apiRequest<any>(this.getTbosProgramsStationsUrl(tbosId), HttpMethod.Get)
	}

	getAutomaticAction(companyId: number, tbosId: number, isBypass = false): Observable<TbosAutomaticAction[]> {
		return this.apiRequest<any>(this.getAutomaticActionUrl(companyId, tbosId), HttpMethod.Get, null, null, isBypass)
	}

	createAutomaticAction(tbosAutomaticAction: TbosAutomaticAction) {
		return this.apiRequest<any>(this.createAutomaticActionUrl, HttpMethod.Post, tbosAutomaticAction)
	}

	updateAutomaticAction(tbosAutomaticAction: TbosAutomaticAction) {
		return this.apiRequest<any>(this.updateAutomaticActionUrl, HttpMethod.Put, tbosAutomaticAction)
	}

	deleteAutomaticAction(id: number) {
		return this.apiRequest<any>(this.deleteAutomaticActionUrl(id), HttpMethod.Delete);
	}

	clearAlarms(satelliteId: number): Observable<null> {
		return this.apiRequest<any>(this.clearAlarmsUrl(satelliteId), HttpMethod.Post);
	}

	clearGlobalFlowCount(tbosId: number): Observable<any> {
		return this.apiRequest<any>(this.clearGlobalFlowCountUrl(tbosId), HttpMethod.Post);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================

	private get baseUrl(): string { return `${this.baseApiUrl}Tbos`; }

	private get updateBatchesUrl(): string { return `${this.baseUrl}/UpdateBatches`; }

	private getRadioRelayUrl(radioRelayId: number): string { return `${this.baseUrl}/getRadioRelay?radioRelayId=${radioRelayId}`; }

	private getServerSatelliteUrl(controllerId: number): string {
		return `${this.baseUrl}/getTbosServerSatellite?deviceId=${controllerId}`; 
	}
	
	private getTbosNetworkUrl(companyId: number, controllerId: number): string {
		return `${this.baseUrl}/GetEntireNetwork?companyId=${companyId}&satelliteId=${controllerId}`;
	}

	private get getRadioRelaysUrl(): string { return `${this.baseUrl}/getRadioRelays`; }

	private get getTbosSatellite(): string { return `${this.baseUrl}/getTbosSatellites`; }

	private getTbosProgramsStationsUrl(tbosId: number): string { return `${this.baseUrl}/getTbosProgramsStations?tbosId=${tbosId}`; }

	private getAutomaticActionUrl(companyId: number, tbosId: number): string {
		return `${this.baseUrl}/getAutomaticActions?companyId=${companyId}&tbosId=${tbosId}`;
	}

	private get createAutomaticActionUrl(): string { return `${this.baseUrl}/createAutomaticAction`; }

	private deleteAutomaticActionUrl(id: number): string { return `${this.baseUrl}/deleteAutomaticAction?id=${id}`; }

	private get updateAutomaticActionUrl(): string { return `${this.baseUrl}/updateAutomaticAction?`; }

	private learnTbosNetworkUrl(satelliteId: number, learningType: RbEnums.Common.TbosNetworkLearningType, connectionLifetime?: number): string {
		return `${this.baseUrl}/LearnNetwork?satelliteId=${satelliteId}&learningType=${learningType}&connectionLifetime=${connectionLifetime}`;
	}

	private clearAlarmsUrl(satelliteId: number): string { return `${this.baseUrl}/clearAlarms?tbosId=${satelliteId}`;}

	private clearGlobalFlowCountUrl(tbosId: number): string { return `${this.baseUrl}/ClearGlobalFlowCount?tbosId=${tbosId}`;}
}

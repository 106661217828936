import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { FloManagerTabUtils } from '../../../../../sections/system-setup/flo-manager-tab/flo-manager-tab.utils';
import { FlowElement } from '../../../../../api/flow-elements/models/flow-element.model';
import { FlowElementManagerService } from '../../../../../api/flow-elements/flow-element-manager.service';

@Component({
	selector: 'rb-flo-manager-mobile-cell-renderer',
	templateUrl: './flo-manager-mobile-cell-renderer.component.html',
	styleUrls: ['./flo-manager-mobile-cell-renderer.component.scss']
})
export class FloManagerMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	params;
	flowElement: FlowElement;
	flowManagerTabUtils: FloManagerTabUtils;
	constructor(injector: Injector, protected authManager: AuthManagerService,
		private flowElementManager: FlowElementManagerService) {
			super(injector);
			this.flowManagerTabUtils = new FloManagerTabUtils(this.authManager,
				this.flowElementManager);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.params = params;
		this.flowElement = params.data;
	}
	convertCapacity(flowElement) {
		let ret = '';
		if (this.params) {
			ret = this.flowManagerTabUtils.convertCapacity(this.params);
		}
		return ret;
	}
	convertCurrentFlow() {
		let ret = '';
		if (this.params) {
			ret = this.flowManagerTabUtils.convertCurrentFlow(this.params);
		}
		return ret;
	}
	convertAvailableFlow() {
		let ret = '';
		if (this.params) {
			ret = this.flowManagerTabUtils.convertAvailableFlow(this.params);
		}
		return ret;
	}
}

/* eslint-disable @angular-eslint/directive-class-suffix */
import * as moment from 'moment';
import { Directive } from '@angular/core';
import { IIdentityObject } from '../../_common/models/IIdentityObject.model';
import { IrrigationQueueItem } from '../../connect-data-pack/models/irrigation-queue-item.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationStatus } from './station-status.model';
import { StationStatusListItem } from './_station-status-list-item.model';
import IrrigationStatus = RbEnums.Common.IrrigationStatus;
import SprinklerCategoryType = RbEnums.Common.SprinklerCategoryType;
import { IProgramRuntimeList } from './station-assigned-program.model';

@Directive()
export class StationListItem extends StationStatusListItem implements IIdentityObject {

	constructor(json: any = null) {
		super(json.id);

		if (!json) {
			return;
		}
		
		Object.assign(this, json);

		if (json.tempAdjustStart && json.tempAdjustStart instanceof String) {
			this.tempAdjustStart = RbUtils.Conversion.convertStringToDate(json.tempAdjustStart);
		}
		if (json.tempAdjustStop && json.tempAdjustStop instanceof String) {
			this.tempAdjustStop = RbUtils.Conversion.convertStringToDate(json.tempAdjustStop);
		}
	}

	address: number;
	// Arc calculated based on rotor type, user-entered arc, if any, or 360.
	arc: number;
	areaLevel2Id: number;
	areaLevel2Name: string;
	areaLevel2Number: number;
	areaLevel2ShortName: string;
	areaLevel3Id: number;
	areaLevel3Name: string;
	areaLevel3Number: number;
	areaLevel3AreaNumber: number;
	areaLevel3ShortName: string;
	canAdvance = false;
	channel: number;
	cycleTimeLong?: number;
	defaultRunTimeLong: number = null;			// Ticks
	fastConnectStationNumber: number;
	flowRate: number;
	flowRateLearned: boolean;
	flowZoneId: number;
	flowZoneName: string;
	groupNumber: number;
	id: number;
	irrigationSourceDescription: string;
	isInterfaceConnected = false;
	isLocked: boolean;
	lastManualRunStartTime?: Date;
	lastRunTimeSeconds?: number;
	latitude: number;
	longitude: number;
	master = false;
	name: string;
	notes: string;
	nozzleColor?: string;
	parentSatelliteType: RbEnums.Common.DeviceType;
	// Precip rate in inches. Used for both custom-entered and catalog-based.
	precRateFinal: number;
	priority: number;
	programId = 0;
	pumpId: number;
	pumpName: string;
	// Rotation time in seconds/rotation. Used for both custom-entered and catalog-based.
	rotationTime: number;
	runTimeRemaining: number;
	runTimeSoFar: number;
	satelliteId: number;
	siteId: number;
	siteNumber: number;
	soakTimeLong?: number;
	sprinklerCategory: SprinklerCategoryType;
	subAreaId: number;
	suspended: boolean;
	tempAdjustStart: Date;
	tempAdjustStop: Date;
	terminal: number;
	// Flag indicating whether the user custom-set precip rate or is using the catalog values.
	usePrecRateCalc: boolean;

	valveType: number;
	// these 3 are used on area-view-tab-component in the html
	tempStationAdjust: number;
	yearlyAdjFactor: number;
	etAdjustFactor: number;
	tempAdjustDays: number;

	// This field is for indicate this station is being updated from APi request.
	isBeingUpdated: boolean;

	// NOTE: This value is currently set external to this class. The api does not populate this field.
	addressString = '';

	// This field is for placeholding for assigned programs
	isAssignedToAdvancedEtProgram: boolean = null;
	_assignedPrograms: IProgramRuntimeList[] = null;
	set assignedPrograms (value) {
		this._assignedPrograms = value;
		this.assignedProgramsDict = {};
		this.assignedPrograms.forEach(assignedProgram => {
			this.assignedProgramsDict[assignedProgram.programId] = assignedProgram;
		})
		
		this.isAssignedToAdvancedEtProgram  = !!this.assignedPrograms.find((x) => {
			return x.etAdjustType === RbEnums.Common.EtAdjustType.WeatherSource;
		})

	}
	get assignedPrograms(): IProgramRuntimeList[] {
		return this._assignedPrograms;
	}

	assignedProgramsDict: { [programId: number]: IProgramRuntimeList } = {};
	extensionData: StationExtensionData = null;

	signalREventType?: RbEnums.SignalR.StationStatusChangeType;
	
	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get isConnected(): boolean {
		return RbUtils.Stations.isStationConnected(this);
	}

	get isIrrigating(): boolean {
		return this.irrigationStatus === RbEnums.Common.IrrigationStatus.Pending ||
			this.irrigationStatus === RbEnums.Common.IrrigationStatus.Running ||
			this.irrigationStatus === RbEnums.Common.IrrigationStatus.Soaking ||
			this.irrigationStatus === RbEnums.Common.IrrigationStatus.Delaying;
	}

	get isIrrigating_Golf(): boolean {
		return this.status != null && this.status !== this.INDETERMINATE_STATE;
	}

	// NOTE: The fields below ARE BEING USED by StationsTab Grid.

	get hasLocation(): boolean { return this.latitude != null && this.longitude != null; }

	get locationString(): string {
		if (this.areaLevel3ShortName === '') return '';

		return `${this.areaLevel2Number}${this.areaLevel3ShortName}${this.areaLevel3Number}`;
	}
	get locationWithSite(): string {
		if (this.areaLevel3ShortName === '') return '';

		return `${this.siteNumber}-${this.locationString}`;
	}

	// Called Hole for Golf
	get landscape(): string {
		return this.areaLevel2Name || this.INDETERMINATE_STATE;
	}

	// Called Area for Golf
	get sprinkler(): string {
		return this.areaLevel3Name || this.INDETERMINATE_STATE;
	}

	// NOTE: DO NOT PERFORM ANY TRANSLATION HERE! We rely on the english value of the status string in the StationStatusCellRenderer.
	get statusGridDisplay(): string {
		return this.status || this.INDETERMINATE_STATE;
	}

	get notesGridDisplay(): string {
		return this.notes || this.INDETERMINATE_STATE;
	}

	// Used by grid on AreViewTab Component.
	get adjustSortableGridValue(): number {
		if (this.tempAdjustDays > 0)
			return this.tempStationAdjust + this.tempAdjustDays / 1000;
		else
			return this.yearlyAdjFactor;
	}

	get defaultRuntime(): string {
		return moment.utc(RbUtils.Conversion.convertTicksToMSRound(this.defaultRunTimeLong)).format('HH:mm:ss');
	}

	get priorityName(): string {
		switch (this.priority) {
			case RbEnums.Common.Priority.NonIrrigation:
				return RbUtils.Translate.instant('STRINGS.NON_IRRIGATION');
			case RbEnums.Common.Priority.High:
				return RbUtils.Translate.instant('STRINGS.HIGH');
			case RbEnums.Common.Priority.Medium:
				return RbUtils.Translate.instant('STRINGS.MEDIUM');
			case RbEnums.Common.Priority.Low:
				return RbUtils.Translate.instant('STRINGS.LOW');
			case RbEnums.Common.Priority.Lower:
				return RbUtils.Translate.instant('STRINGS.LOWER');
			case RbEnums.Common.Priority.Lowest:
				return RbUtils.Translate.instant('STRINGS.LOWEST');
		}
	}

	setStationStatus(status: StationStatus): boolean {
		const oldStatus = this.status;
		const oldCourseViewStatus = this.courseViewStatus;
		const oldIrrigationStatus = this.irrigationStatus;
		const oldIrrigationEngineStationStatusItem = this.irrigationEngineStationStatusItem;
		this.irrigationEngineStationStatusItem = status.irrigationEngineStationStatusItem;
		this.status = status.status !== '' ? status.status : this.INDETERMINATE_STATE;
		this.mapStatus = status.mapStatus !== ''? status.mapStatus : '';
		let statusChanged = this.canAdvance !== status.canAdvance ||
			this.programId !== status.programId ||
			this.statusReason !== status.statusReason;
		this.courseViewStatus = status.courseViewStatus;
		this.irrigationStatus = status.irrigationStatus;
		this.canAdvance = status.canAdvance;
		this.programId = status.programId;
		this.secondsRemaining = status.secondsRemaining;

		// RB-9052: Copy the status reason. If you don't do this, the reason for Waiting won't
		// be available for display to the user.
		this.statusReason = status.statusReason;

		// Handle Cycle/Soak w/pending cycle. Initially implemented for Manual Ops Irrigation Queue.
		if (this.irrigationStatus === IrrigationStatus.Dash
			&& status.irrigationEngineStationStatusItem
			&& (<IrrigationQueueItem>status.irrigationEngineStationStatusItem).additionalTime > 0) {
			this.status = RbUtils.Translate.instant('STRINGS.PENDING');
			this.courseViewStatus = RbUtils.Translate.instant('STRINGS.PENDING');
			this.irrigationStatus = IrrigationStatus.Pending;
		}

		statusChanged = statusChanged || this.status !== oldStatus || this.courseViewStatus !== oldCourseViewStatus ||
			this.irrigationStatus !== oldIrrigationStatus || this.irrigationEngineStationStatusItem !== oldIrrigationEngineStationStatusItem;
		return statusChanged;
	}

	get nextStartTime() {
		return this.extensionData?.nextStartTime;
	}

	get assignedSensors() {
		return this.extensionData?.sensors;
	}

	get nextProgramStart() {
		const next = this.assignedPrograms?.reduce((selectedStart, current) => {
			if (!selectedStart?.nextStart) {
				return current;
			}

			const currentMoment = moment(current.nextStart);
			const selectedMoment = moment(selectedStart.nextStart);
			if (currentMoment.isBefore(selectedMoment)) {
				return current;
			}

			return selectedStart;

		}, null);
		return next?.nextStart;
	}
}


export class StationExtensionData {
	stationId: number;
	nextStartTime: string;
	sensors: { id: number, name: string }[];

	constructor(json: any = null) {
		if (!json) {
			return;
		}

		Object.assign(this, json)

	}
}
/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Stations.sortStationsList
 * 		 See: _rb.utils.ts
 */

import * as L from 'leaflet';
import * as moment from 'moment';

import { Area } from '../../api/areas/models/area.model';
import { ConnectDataPacksDict } from '../../api/connect-data-pack/models/connect-data-packs-dict.model';
import { Controller } from '../../api/controllers/models/controller.model';
import { IcShortAddressPollData } from '../../api/manual-ops/models/ic-short-address-poll-data.model';
import { IcVoltagePollData } from '../../api/manual-ops/models/ic-voltage-poll-data.model';
import { RbConstants } from '../constants/_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from './_rb.utils';
import { SiteManagerService } from '../../api/sites/site-manager.service';
import { Station } from '../../api/stations/models/station.model';
import { StationListItem } from '../../api/stations/models/station-list-item.model';
import { StationStatus } from '../../api/stations/models/station-status.model';
import { StationStatusChange } from '../../api/signalR/station-status-change.model';
import { StationValveType } from '../../api/station-valve-types/station-valve-type.model';
import { StationWithMapInfoLeaflet } from '../models/station-with-map-info-leaflet.model';
import { VoltageDiagnostic } from '../../api/voltage-diagnostic/models/voltage-diagnostic.model';

export namespace XXUseRbUtilsNamespace {

	export abstract class Stations {

		private static readonly INDETERMINATE_STATE = '-';
		private static readonly COURSE_VIEW_INDETERMINATE_STATE = '-';

		static addressFromString(value: string, controllerType: RbEnums.Common.DeviceType): number {
			if (this.isStationAddressHex(controllerType)) return parseInt(value, 16);
			return +value;
		}

		/**
		 * Return an RegEx matching string which specifies the basic format of the address value for a station attached to
		 * a satellite of the given type.
		 * @param controllerType - RbEnums.Common.DeviceType specifying the type of the connected satellite (ICI, for example).
		 * @returns "^[0-9]+$" or "^[a-fA-F0-9]+$" depending on the satellite type. Note that we're matching the beginning
		 * and end of the string, so no funny business with non-matching characters in there.
		 */
		static addressPattern(controllerType: RbEnums.Common.DeviceType): string {
			if (RbUtils.Controllers.hideStationAddress(controllerType)) return '.*';
			if (this.isStationAddressHex(controllerType)) return '^[a-fA-F0-9]+$';
			return '^[0-9]+$';
		}

		static addressString(stationAddress: number, controllerType: RbEnums.Common.DeviceType): string {
			if (stationAddress == null) return '';
			if (this.isStationAddressHex(controllerType)) return ('00000000' + stationAddress.toString(16)).slice(-6).toUpperCase();
			if (this.stationAddressRange(controllerType) === RbConstants.Form.ADDRESS_RANGE_NONE) return '-';
			return stationAddress.toString();
		}

		static getStationStatus(stationNumber: number, controllerId: number, connectDataPacks: ConnectDataPacksDict): StationStatus {
			const stationStatus = new StationStatus();
			stationStatus.programId = -1; // "No program"
			stationStatus.secondsRemaining = 0;
			const connectDataPack = connectDataPacks ? connectDataPacks[controllerId] : null;

			if (connectDataPack == null || connectDataPack.isStale) {
				stationStatus.status = Stations.INDETERMINATE_STATE;
				stationStatus.courseViewStatus = Stations.COURSE_VIEW_INDETERMINATE_STATE;
				stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Dash;
				return stationStatus;
			}

			stationStatus.status = RbUtils.Translate.instant('STRINGS.IDLE');
			stationStatus.courseViewStatus = RbUtils.Translate.instant('STRINGS.IDLE');

			if (connectDataPack.error != null || connectDataPack.isRetrieving) {
				stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Dash;
				stationStatus.status = Stations.INDETERMINATE_STATE;
				stationStatus.courseViewStatus = Stations.COURSE_VIEW_INDETERMINATE_STATE;
			}

			if ((connectDataPack.irrigationStatus != null && connectDataPack.irrigationStatus.stationsIrrigating === 0
				&& connectDataPack.irrigationStatus.stationsPending === 0)
				|| (connectDataPack.irrigationQueue != null && connectDataPack.irrigationQueue.length > 0)) {
				stationStatus.status = RbUtils.Translate.instant('STRINGS.IDLE');
				stationStatus.courseViewStatus = RbUtils.Translate.instant('STRINGS.IDLE');
				stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Idle;
			}

			if (connectDataPack.irrigationQueue != null && connectDataPack.irrigationQueue.length > 0) {
				const irrigationForStation = connectDataPack.irrigationQueue.find(i => i.stationNumber === stationNumber);
				if (irrigationForStation != null) {
					// Store the IrrigationQueueItem in the 'Station' List Item. This will allow us to keep the countdown of time remaining in sync.
					stationStatus.irrigationEngineStationStatusItem = irrigationForStation;

					switch (irrigationForStation.stationState) {
						case RbEnums.Common.StationStatus.ManuallyStarted:
							stationStatus.status = RbUtils.Translate.instant('STRINGS.POSTED');
							stationStatus.courseViewStatus = RbUtils.Translate.instant('STRINGS.POSTED');
							stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.ManuallyStarted;
							break;

						case RbEnums.Common.StationStatus.ManuallyAdvanced:
							stationStatus.status = RbUtils.Translate.instant('STRINGS.ADVANCING');
							stationStatus.courseViewStatus = RbUtils.Translate.instant('STRINGS.ADVANCING');
							stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.ManuallyAdvanced;
							irrigationForStation.secondsRemaining = 0;
							break;

						case RbEnums.Common.StationStatus.On:
							if (connectDataPack.irrigationStatus && connectDataPack.irrigationStatus.irrigationState === 'LearnedFlow') {
								stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.LearnedFlow;
								stationStatus.status = RbUtils.Translate.instant('STRINGS.LEARNING_FLOW');
								stationStatus.courseViewStatus = RbUtils.Translate.instant('STRINGS.LEARNING_FLOW');
							} else if (irrigationForStation.secondsRemaining > 0) {
								// If secondsRemaining === 0, it is assumed that the previous Cycle Op had just ended when the SignalR Event was received.
								stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Running;
								stationStatus.secondsRemaining = irrigationForStation.secondsRemaining;
								stationStatus.status = this.secondsToFriendlyString(irrigationForStation.secondsRemaining);
								stationStatus.courseViewStatus = this.secondsToCourseViewFriendlyString(irrigationForStation.secondsRemaining);
							} else {
								stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Dash;
								stationStatus.status = Stations.INDETERMINATE_STATE;
								stationStatus.courseViewStatus = Stations.COURSE_VIEW_INDETERMINATE_STATE;
							}
							break;

						case RbEnums.Common.StationStatus.Pending:
							stationStatus.status = RbUtils.Translate.instant('STRINGS.PENDING');
							stationStatus.courseViewStatus = RbUtils.Translate.instant('STRINGS.PENDING');
							stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Pending;
							break;

						case RbEnums.Common.StationStatus.Delaying:
							stationStatus.status = RbUtils.Translate.instant('STRINGS.DELAYING');
							stationStatus.courseViewStatus = RbUtils.Translate.instant('STRINGS.DELAYING');
							stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Delaying;
							break;

						case RbEnums.Common.StationStatus.Soaking:
							// If secondsRemaining === 0, it is assumed that the previous Soak Op had just ended when the SignalR Event was received.
							if (irrigationForStation.secondsRemaining > 0) {
								stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Soaking;
								stationStatus.secondsRemaining = irrigationForStation.secondsRemaining;
								stationStatus.status = this.secondsToFriendlyString(irrigationForStation.secondsRemaining);
								stationStatus.courseViewStatus = this.secondsToCourseViewFriendlyString(irrigationForStation.secondsRemaining);
							} else {
								stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Dash;
								stationStatus.status = Stations.INDETERMINATE_STATE;
								stationStatus.courseViewStatus = Stations.COURSE_VIEW_INDETERMINATE_STATE;
							}
							break;

						case RbEnums.Common.StationStatus.Suspended:
							stationStatus.status = RbUtils.Translate.instant('STRINGS.SUSPENDED');
							stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Suspended;
							break;

						case RbEnums.Common.StationStatus.Prevented:
							stationStatus.status = RbUtils.Translate.instant('STRINGS.PREVENTED');
							stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Prevented;
							break;
					}

					stationStatus.programId = irrigationForStation.program;
					stationStatus.canAdvance = irrigationForStation.canAdvance;
				}
			}
			return stationStatus;
		}

		static getCommercialIqNetSharingLabel(borrowedMvid: number): string {
			if (borrowedMvid === 0) {
				return RbUtils.Translate.instant('STRINGS.IQ_NET_SHARING_NO_BORROW_STATE');
			} else {
				return RbUtils.Translate.instant('STRINGS.IQ_NET_SHARING_BORROWED');
			}
		}

		static getCommercialIqNetSharingValue(borrowState: RbEnums.Common.BorrowState): string {
			if (borrowState === RbEnums.Common.BorrowState.None)
				return RbUtils.Translate.instant('STRINGS.NONE');
			else if (borrowState === RbEnums.Common.BorrowState.Shared)
				return RbUtils.Translate.instant('STRINGS.SHARE');
			else if (borrowState === RbEnums.Common.BorrowState.Borrow)
				return RbUtils.Translate.instant('STRINGS.BORROW');
		}

		static getStationStatusFromStatusChange(golfStationStatus: StationStatusChange, isMaster: boolean, isNonIrrigation: boolean): StationStatus {
			const stationStatus = new StationStatus();

			// Store the StationStatusChange in the 'Station' List Item. This will allow us to keep the countdown of time remaining in sync.
			stationStatus.irrigationEngineStationStatusItem = golfStationStatus;

			// RB-9052: Copy the status reason code, in case we want to display to the user. Note that this is an enum value, not a string.
			stationStatus.statusReason = golfStationStatus.reasonCode;

			switch (golfStationStatus.changeType) {
				case RbEnums.SignalR.StationStatusChangeType.Started:
				case RbEnums.SignalR.StationStatusChangeType.RunningUpdate:
				case RbEnums.SignalR.StationStatusChangeType.Resumed:
					// RB-8549: Station status should be simply "Running" if the station is a master valve/booster pump or other
					// non-irrigation station and if there is zero cycleTimeRemaining indicated.
					stationStatus.status = ((isMaster || isNonIrrigation) && golfStationStatus.cycleTimeRemaining === 0)
						? RbUtils.Translate.instant('STRINGS.RUNNING')
						: this.secondsToFriendlyString(golfStationStatus.cycleTimeRemaining);
					stationStatus.mapStatus = ((isMaster || isNonIrrigation) && golfStationStatus.cycleTimeRemaining === 0)
						? ''
						: this.secondsToHhMmSs(golfStationStatus.cycleTimeRemaining);
					stationStatus.courseViewStatus = ((isMaster || isNonIrrigation) && golfStationStatus.cycleTimeRemaining === 0)
						? RbUtils.Translate.instant('STRINGS.RUNNING')
						: this.secondsToCourseViewFriendlyString(golfStationStatus.cycleTimeRemaining);
					stationStatus.secondsRemaining = golfStationStatus.cycleTimeRemaining;
					stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Running;
					break;
				case RbEnums.SignalR.StationStatusChangeType.SoakStart:
				case RbEnums.SignalR.StationStatusChangeType.SoakUpdate:
					stationStatus.status = this.secondsToFriendlyString(golfStationStatus.soakTimeRemaining);
					stationStatus.mapStatus = this.secondsToHhMmSs(golfStationStatus.soakTimeRemaining);
					stationStatus.courseViewStatus = this.secondsToCourseViewFriendlyString(golfStationStatus.soakTimeRemaining);
					stationStatus.secondsRemaining = golfStationStatus.soakTimeRemaining;
					stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Soaking;
					break;
				case RbEnums.SignalR.StationStatusChangeType.Stopped:
					stationStatus.status = '-';
					stationStatus.courseViewStatus = '';
					stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Idle;
					break;
				case RbEnums.SignalR.StationStatusChangeType.Paused:
				case RbEnums.SignalR.StationStatusChangeType.PausedUpdate:
					stationStatus.status = RbUtils.Translate.instant('STRINGS.PAUSED');
					stationStatus.courseViewStatus = RbUtils.Translate.instant('STRINGS.PAUSED');
					stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Paused;
					break;
				case RbEnums.SignalR.StationStatusChangeType.Waiting:
					stationStatus.status = RbUtils.Translate.instant('STRINGS.WAITING');
					stationStatus.courseViewStatus = RbUtils.Translate.instant('STRINGS.WAITING');
					stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Pending;
					break;
			}
			return stationStatus;
		}

		/**
		 * When display a reason code around a waiting station, this method determines the string representation of "why" we're
		 * waiting to run that station based on the reason code.
		 * @param reasonCode - RbEnums.Common.StationFailureReasonCode to be converted into translated string describing the
		 * state
		 * @returns string - Describing the why? of the failure code reason from the
		 */
		static getStationFailureReasonFromEnum(reasonCode: RbEnums.Common.StationFailureReasonCode): string {
			// If the code is null, of course, the reason is empty. Same with NoError.
			if (reasonCode == null) {
				return '';
			}
			switch (reasonCode) {
				case RbEnums.Common.StationFailureReasonCode.NoError:
					return '';
				case RbEnums.Common.StationFailureReasonCode.NoFieldConnection:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.NO_FIELD_CONNECTION');
				case RbEnums.Common.StationFailureReasonCode.NoFlowCapacity:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.NO_FLOW_CAPACITY');
				case RbEnums.Common.StationFailureReasonCode.NoElectricCapacity:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.NO_ELECTRIC_CAPACITY');
				case RbEnums.Common.StationFailureReasonCode.NoFeedback:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.NO_FEEDBACK');
				case RbEnums.Common.StationFailureReasonCode.BadStationData:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.BAD_STATION_DATA');
				case RbEnums.Common.StationFailureReasonCode.BadFlowNode:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.BAD_FLOW_NODE');
				case RbEnums.Common.StationFailureReasonCode.Locked:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.LOCKED');
				case RbEnums.Common.StationFailureReasonCode.CommandTimeOut:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.COMMAND_TIMEOUT');
				case RbEnums.Common.StationFailureReasonCode.NoGroup:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.NO_GROUP');
				case RbEnums.Common.StationFailureReasonCode.NoSimulStations:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.NO_SIMULSTATIONS');
				case RbEnums.Common.StationFailureReasonCode.FlowNetworkWait:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.FLOW_NETWORK_WAIT');
				case RbEnums.Common.StationFailureReasonCode.InterfaceWait:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.INTERFACE_WAIT');
				default:
				// Already running.
				// Already soaking.
				// Not running.
				// Not paused.
				case RbEnums.Common.StationFailureReasonCode.Error:
					return RbUtils.Translate.instant('STATION_FAILURE_REASON_CODES.ERROR');
			}
		}

		/**
		 * Return a plain text tooltip format for the indicated station status and corresponding failure reason code. This would be
		 * used in, for example, the stations tab where we want to provide a tooltip for the indicated station status when it's
		 * something like Waiting, describing why?
		 * @param stationName string name of the station for which we are displaying the tooltip
		 * @param stationStatus string status value for the station. This is normally Waiting as that's what we're trying to
		 * detail for the user
		 * @param reasonCode RbEnums.Common.StationFailureReasonCode describing the failure/reason
		 * @returns string plain text tooltip
		 */
		static getStationStatusReasonCodeTooltipPlainText(stationName: string, stationStatus: string,
			reasonCode: RbEnums.Common.StationFailureReasonCode): string {
			// Get the description. If it's empty, we return empty.
			const description = RbUtils.Stations.getStationFailureReasonFromEnum(reasonCode);
			if (description.length === 0) return '';

			const tooltipTitleFormatParams = { stationName, stationStatus, description };
			return RbUtils.Translate.instant('STRINGS.WAITING_STATUS_TOOLTIP_WITH_DESCRIPTION_FORMAT', tooltipTitleFormatParams);
		}

		/**
		 * Return an HTML tooltip format for the indicated station status and corresponding failure reason code. This would be
		 * used in, for example, the stations tab where we want to provide a tooltip for the indicated station status when it's
		 * something like Waiting, describing why?
		 * @param stationName string name of the station for which we are displaying the tooltip
		 * @param stationStatus string status value for the station. This is normally Waiting as that's what we're trying to
		 * detail for the user
		 * @param reasonCode RbEnums.Common.StationFailureReasonCode describing the failure/reason
		 * @returns string HTML tooltip text suitable for display by the standard RBCC tooltip setup
		 */
		static getStationStatusReasonCodeTooltip(stationName: string, stationStatus: string,
			reasonCode: RbEnums.Common.StationFailureReasonCode): string {
			// Get the description. If it's empty, we return empty.
			const description = RbUtils.Stations.getStationFailureReasonFromEnum(reasonCode);
			if (description.length === 0) return '';

			// The title is going to be the station name + status ('Waiting' here). We send those values as properties of an object so
			// the translation can format them however is necessary by national language.
			const tooltipTitleFormatParams = { stationName, stationStatus };
			let html = `<div class='title'>${RbUtils.Translate.instant('STRINGS.WAITING_STATUS_TOOLTIP_FORMAT', tooltipTitleFormatParams)}</div>`;

			// Draw line
			html += `<div class='line'></div>`;

			// Next we show the why information.
			html +=	`<div class='info-line'>` +
						`<div class='label'>${description}</div>` +
					`</div>`;

			return html;
		}

		static isStationRunningStatus(changeType: string): boolean {
			switch (changeType) {
				case RbEnums.SignalR.StationStatusChangeType.Stopped:
				case RbEnums.SignalR.StationStatusChangeType.Paused:
				case RbEnums.SignalR.StationStatusChangeType.PausedUpdate:
				case RbEnums.SignalR.StationStatusChangeType.SoakStart:
				case RbEnums.SignalR.StationStatusChangeType.SoakUpdate:
				case RbEnums.SignalR.StationStatusChangeType.Waiting:
					return false;

				default:
					// Anything else returns true, indicating the station is active (or potentially active for changes like Updated, etc.)
					return true;
			}
		}

		static isStationInactiveStatus(changeType: string): boolean {
			switch (changeType) {
				case RbEnums.SignalR.StationStatusChangeType.Paused:
					return true;

				default:
					return false;
			}
		}

		/**
		 * Return an indicator of whether the specified StationChange.ChangeType value indicates a running/not-running
		 * state. This centralizes separating things like Added, Deleted, Updated from things like Running, Paused, Waiting,
		 * Posted, etc.
		 * @param changeType - string change type from a StationChange.
		 * @returns true if the station state (running, paused, stopped, waiting, etc.) is defined by the message; false if
		 * the message is not status-related.
		 */
		static isStationRealTimeStatusMessage(changeType: string): boolean {
			switch (changeType) {
				case RbEnums.SignalR.StationStatusChangeType.Added:
				case RbEnums.SignalR.StationStatusChangeType.Deleted:
				case RbEnums.SignalR.StationStatusChangeType.Updated:
				case RbEnums.SignalR.StationStatusChangeType.BatchUpdated:
					return false;

				case RbEnums.SignalR.StationStatusChangeType.Paused:
				case RbEnums.SignalR.StationStatusChangeType.PausedUpdate:
				case RbEnums.SignalR.StationStatusChangeType.Resumed:
				case RbEnums.SignalR.StationStatusChangeType.RunningUpdate:
				case RbEnums.SignalR.StationStatusChangeType.SoakStart:
				case RbEnums.SignalR.StationStatusChangeType.SoakUpdate:
				case RbEnums.SignalR.StationStatusChangeType.Started:
				case RbEnums.SignalR.StationStatusChangeType.Stopped:
				case RbEnums.SignalR.StationStatusChangeType.Waiting:
				case RbEnums.SignalR.StationStatusChangeType.Unlocked:
					return true;

				default:
					// Anything else returns false, for now. It's probably an error, however, so drop a messsage in the
					// console to indicate something is amiss.
					console.log('UNEXPECTED STATION STATUS MESSAGE: %o. See isStationRealTimeStatus', changeType);
					return false;
			}
		}

		static isStationAddressHex(controllerType: RbEnums.Common.DeviceType): boolean {
			return controllerType === RbEnums.Common.DeviceType.ICI;
		}

		static isStationRunning(status: string): boolean {
			// Compare to getStationStatus result above
			return status !== RbUtils.Translate.instant('STRINGS.IDLE') && status !== '-';
		}

		static stationAddressRange(controllerType: RbEnums.Common.DeviceType, isSensor = false): { min: number, max: number }[] {
			if (RbUtils.Common.isLxmeTypeController(controllerType)) {
				return RbConstants.Form.ADDRESS_RANGE_NONE;
			}

			// RB-8013: There's one extra-special case for ICI: stations (not sensors), can have a special
			// address in the range 0xC3xxyy to specify that the ICI is an ICI+ and there is a PAR+ES-style
			// satellite connected with Id = xx and a Station = yy. This allows that satellite to work as
			// though it's a collection of ICMs. However, this is a disjoint range with the normal ICM addresses,
			// so we need a fancier validation specifier containing two valid ranges.
			if (controllerType === RbEnums.Common.DeviceType.ICI) {
				if (isSensor) {
					return RbConstants.Form.ADDRESS_RANGE_ICM_STATION;
				} else {
					return RbConstants.Form.ADDRESS_RANGE_ICM_STATION_WITH_IFX;
				}
			}

			// RB-10607: Decoder stations address range
			if (controllerType === RbEnums.Common.DeviceType.LDISDI){
				return RbConstants.Form.ADDRESS_RANGE_LDISDI_STATION;
			}

			return RbConstants.Form.ADDRESS_RANGE_DEFAULT;
		}

		static stationHasWirePaths(controllerType: RbEnums.Common.DeviceType): boolean {
			return controllerType === RbEnums.Common.DeviceType.ICI || controllerType === RbEnums.Common.DeviceType.PAR_ES
			|| controllerType === RbEnums.Common.DeviceType.PARplus || controllerType === RbEnums.Common.DeviceType.IQI
			|| controllerType === RbEnums.Common.DeviceType.MIM || controllerType === RbEnums.Common.DeviceType.MIM_LINK
			|| controllerType === RbEnums.Common.DeviceType.ESP_MC;
		}

		static sortStationsList(s1: StationListItem, s2: StationListItem): number {
			// Sort by controller Id...
			if (s1.satelliteId > s2.satelliteId) { return 1; }
			if (s1.satelliteId < s2.satelliteId) { return -1; }

			// ...then by terminal
			if (s1.terminal > s2.terminal) { return 1; }
			if (s1.terminal < s2.terminal) { return -1; }

			// or perhaps they are the same
			return 0;
		}

		/**
		 * sortStation sorts an array of Station items based on their Golf area information. The order is:
		 * 1. Hole number
		 * 2. Area number
		 * 3. Station number in area
		 * The station list items must already have their stationArea entries set and each stationArea entry
		 * must already have its area entry set. NOTE: We do not take Course number into account here.
		 * @param stationList - Station list to be sorted against.
		 * @returns sorted Station[]
		 */
		static sortStations_Golf(stationList: Array<Station>): Array<Station> {
			// There are two ways to go, doing the lookups to find the hole and area at each step for each item,
			// or trying to cache those. Since we have to compare to each other element in the list at least
			// n*log(n) times, I'm going with caching. To do that, we preprocess the list, create a new object
			// for each Station, then remap the result list after sorting. If you want to go without all these
			// object builds, the code for lookups at each step is below:
			// let resultList = stationList
			// 	.sort((s1, s2) =>
			// 		s1.stationArea.find(sa => sa.area.level === 2).area.number - s2.stationArea.find(sa => sa.area.level === 2).area.number)
			// 	.sort((s1, s2) =>
			// 		s1.stationArea.find(sa => sa.area.level === 3).area.number - s2.stationArea.find(sa => sa.area.level === 3).area.number)
			// 	.sort((s1, s2) =>
			// 		s1.stationArea.find(sa => sa.area.level === 3).number - s2.stationArea.find(sa => sa.area.level === 3).number)
			// 	;

			// Load info for quicker access to the station's 'hole' number and 'area' stationArea (which we use to get both
			// the area.number value and the station number in that area).
			const courseHoleAreaStationList = stationList.map((s, index, list) => {
				return {
					station: s,
					holeNumber: s.stationArea.find(sa => sa.area.level === 2 && sa.area.isExclusive).area.number,
					areaStationArea: s.stationArea.find(sa => sa.area.level === 3 && sa.area.isExclusive)
				};
			});

			courseHoleAreaStationList.sort((a, b) => {
				const holediff = a.holeNumber - b.holeNumber;
				if (holediff !== 0) {
					return holediff;
				}
				const areadiff = a.areaStationArea.area.number - b.areaStationArea.area.number;
				if (areadiff !== 0) {
					return areadiff;
				}
				const stationdiff = a.areaStationArea.number - b.areaStationArea.number;
				return stationdiff;
			});

			return courseHoleAreaStationList.map((value, index, list) => value.station);
		}

		static secondsToFriendlyString(time: number) {
			let minutes = Math.floor(time / 60);
			const hours = Math.floor(minutes / 60);
			minutes = minutes % 60;
			const seconds = time % 60;
			return (hours > 0 ? `${hours} ${RbUtils.Translate.instant('STRINGS.HR')} ` : '') +
				(minutes > 0 ? `${minutes} ${RbUtils.Translate.instant('STRINGS.MIN')} ` : '') +
				(seconds > 0 ? `${seconds} ${RbUtils.Translate.instant('STRINGS.SEC')} ` : '');
		}

		static secondsToCourseViewFriendlyString(seconds: number) {
			if (seconds <= 0) { return ''; }

			if (seconds < 60) {
				return `${seconds} ${RbUtils.Translate.instant('STRINGS.COURSE_VIEWER_SECONDS_ABBR')} `;
			}

			return `${Math.floor(seconds / 60)}`;
		}

		static secondsToHhMmSs(durationInSeconds: number) {
			let minutes: any = Math.floor(durationInSeconds / 60);
			let hours: any = Math.floor(minutes / 60);
			minutes %= 60;
			let seconds: any = durationInSeconds % 60;

			// RB-12280: Use translate service to give format to display time instead of assembling strings using "+" operators, so they can
			// be localizables.
			hours = hours.toString().padStart(RbConstants.Form.PADDING_ZERO, '0');
			minutes = minutes.toString().padStart(RbConstants.Form.PADDING_ZERO, '0');
			seconds = seconds.toString().padStart(RbConstants.Form.PADDING_ZERO, '0');

			return RbUtils.Translate.instant('STRINGS.DISPLAY_TIME_HHMMSS', { hours, minutes, seconds });
		}

		static durationString(duration: moment.Duration, hideHours: boolean = false, hideMinutes: boolean = false, hideSeconds: boolean = false): string {
			if (!duration) return '';

			let formatString = 'H:mm:ss';
			if (hideSeconds) formatString = formatString.substring(0, formatString.length - 3);
			if (hideMinutes) formatString = formatString.substring(0, formatString.length - 3); // Assumes also hiding seconds
			if (hideHours || duration.days() > 0) formatString = formatString.substring(2, formatString.length);
			let durationString = moment.utc(duration.asMilliseconds()).format(formatString);
			if (!hideHours && duration.days() > 0) {
				durationString = (duration.days() * 24).toString() + ':' + this.durationString;
			}

			return durationString;
		}
		static sortStations(stations: StationListItem[]): StationListItem[] {
			stations = stations.sort((a, b) => a.areaLevel3Number - b.areaLevel3Number)
			.sort((a, b) => a.areaLevel3AreaNumber - b.areaLevel3AreaNumber)
			.sort((a, b) => a.areaLevel2Number - b.areaLevel2Number)
			.sort((a, b) => a.siteNumber - b.siteNumber);
			return stations;
		}
		static getStationsForReorder(stations: StationListItem[], parentController: Controller): StationListItem[] {
			let index = 1;
			stations = stations.sort((a, b) => (a.areaLevel3Number - b.areaLevel3Number));
			const newStations: StationListItem [] = [];
			for ( let i = 1; i < stations.length + 1; i++) {
				if (stations[i - 1].areaLevel3Number > index) {
					for ( let j = index; j < stations[i - 1].areaLevel3Number; j++) {
						const station = new StationListItem(stations[i - 1]);
						station.areaLevel3Number = j;
						station.id = -1;
						station.name = '<empty>';
						newStations.push(station);
					}
				}
				stations[i - 1].addressString = this.convertAddress(stations[i - 1].address, parentController);
				index  = stations[i - 1].areaLevel3Number + 1;
				newStations.push(new StationListItem(stations[i - 1]));
			}
			return newStations;
		}

		/**
		 * Find the indicated station(s) in a list and update its voltage(s) and other status items based on the specified IcVoltagePollData
		 * diagnostic result list. That is, you can pass a list of stations and a list of diagnostic results updating all stations covered
		 * by the result list.
		 * @param stations - Station[] containing stations to be checked for a match by station Id and updated
		 * @param diagnosticResult - IcVoltagePollData[] containing the diagnostic result(s)
		 * @returns Station[] containing the modified station(s)
		 */
		static updateStationDiagnosticResult_Voltage(stations: Station[], diagnosticResult: IcVoltagePollData[]): Station[] {
			const stationsUpdated: Station[] = [];

			// Since the station list is usually longer than any update we'd normally get with voltage results, iterate on the stations
			// at the outer level.
			stations.forEach(station => {
				// Find corresponding voltage diagnostic result in the list.
				const dr = diagnosticResult.find(v => v.stationId === station.id);
				if (dr != null) {
					station.voltage = dr.result;
					stationsUpdated.push(station);
				}
			});

			return stationsUpdated;
		}

		/**
		 * Find the indicated station(s) in a list and update its voltage(s) and other status items based on the specified VoltageDiagnosticLogData
		 * diagnostic result list. That is, you can pass a list of stations and a list of diagnostic results updating all stations covered
		 * by the result list.
		 * @param stations - Station[] containing stations to be checked for a match by station Id and updated
		 * @param diagnosticResult - VoltageDiagnostic[] containing the diagnostic result(s)
		 * @returns Station[] containing the modified station(s)
		 */
		static updateStationDiagnosticResult_VoltageDiagnostic(stations: Station[], diagnosticResult: VoltageDiagnostic[]): Station[] {
			const stationsUpdated: Station[] = [];

			// Since the station list is usually longer than any update we'd normally get with voltage results, iterate on the stations
			// at the outer level.
			stations.forEach(station => {
				// Find corresponding voltage diagnostic result in the list.
				const dr = diagnosticResult.find(v => v.stationId === station.id);
				if (dr != null) {
					station.voltage = dr.voltage;
					stationsUpdated.push(station);
				}
			});

			return stationsUpdated;
		}

		/**
		 * Find the indicated station(s) in a list and update its feedback state and other status items based on the specified IcShortAddressPollData
		 * diagnostic result list. That is, you can pass a list of stations and a list of diagnostic results updating all stations covered
		 * by the result list. Note also that each of the IcShortAddressPollData items includes multiple stations. Note further that the station.
		 * feedback property is set to 1 for good feedback and set to 0 otherwise, whether due to ERROR or NO_FB result.
		 * @param stations - Station[] containing stations to be checked for a match by station Id and updated
		 * @param diagnosticResult - IcShortAddressPollData[] containing the diagnostic result(s)
		 * @returns Station[] containing list of stations which were updated during the operation
		 */
		static updateStationDiagnosticResult_ShortAddress(stations: Station[], diagnosticResult: IcShortAddressPollData[]): Station[] {
			const stationsUpdated: Station[] = [];

			// Since the diagnostic result lists are harder to search, we iterate those at the outer level, rather than stations.
			if (diagnosticResult && diagnosticResult.length) {
				diagnosticResult.forEach(dr => {
					// Iterate each of the resultByStationId items.
					if (dr.resultByStationId && dr.resultByStationId.length) {
						dr.resultByStationId.forEach((result, stationId) => {
							// Using the station id of this item, find the corresponding station and set the feedback flag.
							const station = stations.find(s => s.id === stationId);
							if (station != null) {
								station.feedback = (result === RbEnums.Common.DiagnosticFeedbackResult.OK || result === 'OK' ? 1 : 0);
								stationsUpdated.push(station);
							}
						});
					}
				});

			}

			return stationsUpdated;
		}

		/**
		 * Utility converting from run time of a station to number of rotations of the station's sprinkler
		 * @param arc - Arc for the station or 360 if unknown, unspecified, or full-circle expressed in degrees
		 * @param rotationTime - Rotation time for the station expressed in seconds/rotation
		 * @param seconds - Amount of run time for which the number of rotations is calculated expressed in seconds
		 * @returns number containing the number of rotations
		 */
		static stationRotations(arc: number, rotationTime: number, seconds: number): number {
			// Handle rotation times set custom by the user, not just catalog times. NOTE: This requires that the incoming station data
			// copy the custom rotation time into the nozzle data, set the arc correctly, etc.
			if (arc !== null && rotationTime !== null && rotationTime !== 0) {
				const onePass = (arc / 360) * rotationTime;
				return (seconds / onePass);
			} else return null;
		}

		/**
		 * Utility converting from run time of a station to number of rotations of the station's sprinkler, expressing the
		 * result as a floating point string (with corrected decimal point character).
		 * @param arc - Arc for the station or 360 if unknown, unspecified, or full-circle expressed in degrees
		 * @param rotationTime - Rotation time for the station expressed in seconds/rotation
		 * @param seconds - Amount of run time for which the number of rotations is calculated expressed in seconds
		 * @returns string containing the number of rotations
		 */
		static stationRotationsDisplay(arc: number, rotationTime: number, seconds: number): string {
			// Get the rotations value. If null, set '-'; otherwise format correctly as string and return.
			const stationRotations = this.stationRotations(arc, rotationTime, seconds);
			if (stationRotations != null) {
				return stationRotations.toFixed(2).replace('.', RbUtils.User.cultureSettings.decimalSeparator);
			} else return '-';
		}

		/**
		 * Utility converting from run time of a station to amount of water applied by the station's sprinkler.
		 * @param precRateFinal - Precipitation Rate expressed in inches/hour
		 * @param seconds - Amount of run time for which the application amount is calculated expressed in seconds
		 * @returns number value of application amount in inches or null, if we don't have precip rate.
		 */
		static applicationAmount(precRateFinal: number, seconds: number): number {
			// Calculation applies whether we are using custom user-entered precip rate or catalog rate.
			if (precRateFinal != null && precRateFinal > 0) {
				return (seconds / 60 / 60 * precRateFinal);
			} else return null;
		}

		/**
		 * Utility converting from run time of a station to amount of water applied by the station's sprinkler, expressing the
		 * result as a floating point string in user-selected units.
		 * @param precRateFinal - Precipitation Rate expressed in inches/hour
		 * @param seconds - Amount of run time for which the application amount is calculated expressed in seconds
		 * @returns string containing amount of application (note that the units are not included) expressed in user-selected
		 * units system
		 */
		static applicationAmountDisplay(precRateFinal: number, seconds: number): string {
			const applicationAmount = this.applicationAmount(precRateFinal, seconds);
			if (applicationAmount != null && applicationAmount > 0) {
				return RbUtils.Common.convertLengthToUserCulture(RbUtils.User.cultureSettings, Number(applicationAmount),
					RbEnums.Common.LengthUnit.Inch, 2);
			} else return '-';
		}

		private  static convertAddress( value:  number, parentController: Controller ) {
			if ( value < 1) return '-';
			return parentController == null || !value ? '-' : RbUtils.Stations.addressString(value, parentController.type);
		}

		/**
		 * Get station count for child satellite (ESP-SAT, PAR+ES, etc..) For example, we default to 72 stations for
		 * PAR+ES, which is the maximum number a PAR+ES of some model can have. The user will have to adjust the max
		 * station count setting for the satellite if it's a 48-station, etc.
		 * @param controllerType - controller type
		 * @returns Number of stations that can be added to the satellite
		 */
		static getSatelliteDefaultStationCount(controllerType: RbEnums.Common.DeviceType): number {
			return RbConstants.Form.getSatelliteCapabilities(controllerType).MAX_STATION_COUNT;
		}

		/**
		 * Return the station isConnected status based on the station type, properties, etc. The most-common items to check
		 * are 'suspended', 'channel', 'terminal', and 'address' but only some of these matter depending on the station's
		 * parent satellite type. We do this in a central location so we don't have to manage multiple places where "connected"
		 * is checked and each one is different.
		 * @param station - Station | StationListItem
		 * @returns boolean true if the station should be displayed as "connected", false otherwise.
		 */
		static isStationConnected(station: Station | StationListItem): boolean {
			const notSuspended = (station.suspended == null || !station.suspended);
			const hasGroup = station.groupNumber != null && station.groupNumber >= 1 && station.groupNumber <= 8;
			const hasChannel = station.channel != null && station.channel > 0;
			const hasTerminal = station.terminal > 0;
			const hasFastConnectStationNumber = station.fastConnectStationNumber > 0;

			// Address is in StationListItem while AddressInt is in Station. Choose the right one. The main problem here
			// is that Station *has* an 'address' value and it's not the one we want here.
			let address = 0;
			if (station['addressInt'] != null) {
				// We must have a Station.
				address = station['addressInt'];
			} else {
				address = station['address'];
			}
			const hasAddress = address > 0;

			// We set false for other cases, like a station currently assigned to a MIM (in the "bullpen"), which should not
			// indicated connected because it doesn't represent an addressible location.
			let isConnected = false;
			switch (station.parentSatelliteType) {
				case RbEnums.Common.DeviceType.None:
					// This is a problem. We shouldn't be getting this unless the API forgot to Include(s => s.Satellite) before
					// sending us the Station or StationListItem.
					console.error(`Unable to correctly generate isStationConnected for ID=${station.id}. parentSatelliteType not received.`);
					break;
				case RbEnums.Common.DeviceType.ICI:
				case RbEnums.Common.DeviceType.IQI:
					// Include channel, terminal, and address in check.
					isConnected = notSuspended && hasGroup && hasChannel && hasFastConnectStationNumber && hasAddress;
					break;
				case RbEnums.Common.DeviceType.PAR_ES:
				case RbEnums.Common.DeviceType.PARplus:
				case RbEnums.Common.DeviceType.ESP_MC:
				case RbEnums.Common.DeviceType.MSCplus:
					// For satellite types, the address and channel are not required, only terminal (and suspended).
					isConnected = notSuspended && hasTerminal;
					break;
				case RbEnums.Common.DeviceType.LDISDI:
					// For decoder types, only the address (and suspended) matters.
					isConnected = notSuspended && hasAddress;
					break;
			}

			return isConnected;
		}

		/**
		 * Return indication of station address validity. For ICM, 0 is not valid. The same is true for decoders. For
		 * satellite-based stations, however, address = 0 is expected. For ICM, we also check the groupNumber, as
		 * a valid address value isn't really valid if group is null or zero.
		 * @param station - Station | StationListItem
		 * @returns boolean true if the station's address should be treated as valid; false if invalid.
		 */
		static isStationAddressValid(station: Station | StationListItem): boolean {
			// Address is in StationListItem while AddressInt is in Station. Choose the right one. The main problem here
			// is that Station *has* an 'address' value and it's not the one we want here.
			let hasGroup = station.groupNumber != null && station.groupNumber >= 1 && station.groupNumber <= 8;
			let address = 0;
			if (station['addressInt'] != null) {
				// We must have a Station.
				address = station['addressInt'];
			} else {
				address = station['address'];
			}
			const hasAddress = address !== 0;

			// Address is considered valid unless we find that its parent satellite is a type indicating that address != 0
			// is required, in which case we check that.
			let isValid = true;
			switch (station.parentSatelliteType) {
				case RbEnums.Common.DeviceType.None:
					// This is a problem. We shouldn't be getting this unless the API forgot to Include(s => s.Satellite) before
					// sending us the Station or StationListItem.
					console.error(`Unable to correctly generate isStationAddressValid for ID=${station.id}. parentSatelliteType not received.`);
					break;
				case RbEnums.Common.DeviceType.ICI:		// ICM
					// Address is valid for ICM if <> 0, but we also need a group number.
					isValid = hasAddress && hasGroup;
					break;
				case RbEnums.Common.DeviceType.LDISDI:	// Golf decoder
				case RbEnums.Common.DeviceType.LXD:		// Decoder
				case RbEnums.Common.DeviceType.LXIVM:	// IVM
				case RbEnums.Common.DeviceType.LXIVMPlus:	// IVM
					// Address is valid for decoder if <> 0.
					isValid = hasAddress;
					break;
			}

			return isValid;
		}

		/**
		 * Return the Site where the station is "located". This is used for golf where the station's location is
		 * NOT RELATED TO THE SITE WHERE ITS SATELLITE IS LOCATED. We actually use the hole and/or area of the station to
		 * get the siteId and look up the site from there.
		 * @param siteManager - SiteManagerService used for site lookup. Must be non-null and sites must have previously
		 * been loaded.
		 * @param hole - Area describing the station's hole (level === 2). One of hole and area must be non-null
		 * @param area - Area describing the station's area (level === 3). One of hole and area must be non-null
		 * @returns string name of site where station lives or some variant of (empty) if the parameters are not correct
		 * or the list of sites is not yet loaded by SiteManagerService
		 */
		static getGolfStationSiteName(siteManager: SiteManagerService, hole?: Area, area?: Area): string {
			if (siteManager == null) {
				return "";
			}

			// The easiest way to the site's Id is through the hole or area parameter.
			let siteId = 0;
			if (hole != null) {
				siteId = hole.siteId;
			} else if (area != null) {
				siteId = area.siteId;
			}

			// If siteId is zero, it won't be found by the siteManager, returning a "suitable" result for no-site. This
			// should not happen here as we have the site list loaded but it will be an indicator of the problem source if
			// it does appear.
			return siteManager.getSiteName(siteId);
		}

		static checkIfHasAdjustment(station: StationWithMapInfoLeaflet) {
			station.isTempAdjustActive = station.tempAdjustDays > 0;
			station.resultingAdjustment = station.isTempAdjustActive ? station.tempStationAdjust : station.yearlyAdjFactor;
			station.hasAdjustments = station.resultingAdjustment !== 100;
		}

		static getAdjustmentIconClass(station: StationWithMapInfoLeaflet) {
			let cssClass = '';
			if (station.resultingAdjustment === 0) {
				cssClass = 'zero-adjustment';
			} else if (station.resultingAdjustment >= 1 && station.resultingAdjustment <= 33) {
				cssClass = 'lower-3';
			} else if (station.resultingAdjustment >= 34 && station.resultingAdjustment <= 66) {
				cssClass = 'lower-2';
			} else if (station.resultingAdjustment >= 67 && station.resultingAdjustment <= 99) {
				cssClass = 'lower';
			} else if (station.resultingAdjustment >= 101 && station.resultingAdjustment <= 125) {
				cssClass = 'higher';
			} else if (station.resultingAdjustment >= 126 && station.resultingAdjustment <= 150) {
				cssClass = 'higher-2';
			} else if (station.resultingAdjustment >= 151 && station.resultingAdjustment <= 300) {
				cssClass = 'higher-3';
			}
			return cssClass;
		}

		static getBadges(station: StationWithMapInfoLeaflet) {
			const visibility = station.mapInfo.layerVisibility;
			return  `
			<span class="badge"></span>
			<span class="badge badge-note">
				<i class="mdi mdi-message note ${ visibility.showingNotesAnimation ? "animate" : '' }"></i>
			</span>
			<span class="badge badge-soaking">` +
				((station.cycleTimeLong || station.soakTimeLong) ? `<i class="mdi cycle-soak
					${ visibility.showingStationCycleSoak ? '' : 'dn' }" ></i>` : '') +
			`</span>
			<span class="badge badge-adjustment">` +
				(station.hasAdjustments ? `<i class="mdi adjustment ${ RbUtils.Stations.getAdjustmentIconClass(station) }
				${ visibility.showingStationAdjustments ? '' : 'dn' }" ></i>` : '') +
			`</span>`
		}

		static convertRuntimeToDuration(params: number): string {
			const duration = RbUtils.Conversion.convertTicksToDuration(params);
			return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
		}

		private static getStationMarkerTemplate(station: StationWithMapInfoLeaflet, options?: {
			innerCircleHTML?: string,
			pieHTML?: string,
			nozzleColor?: string,
			haloHTML?: string,
		}) {
			const visibility = station.mapInfo.layerVisibility;

			let html = `<div>
					${options?.haloHTML}
					<div id="st-${station.id}" class="outer-circle">
						<div class="inner-circle ${!visibility.showingNozzleColors
							&& options?.nozzleColor !== null&& !options?.innerCircleHTML ? 'd-none' : ''}"
							style="background:${options?.nozzleColor ?? ''}">
								${options?.innerCircleHTML ?? ''}
						</div>
						${options?.pieHTML ?? ''}
						<div class="station-info" style="color:${visibility.textColor};">
							
						${station.master === false ? 
							`<span class="station-name ${visibility.showingStationNames ? 'db' : 'dn'}">${station.name}</span>` : 
							`<span class="station-name ${visibility.showingMasterValvesName ? 'db' : 'dn'}">${station.name}</span>`}
							<span class="remaining-time
								${visibility.showingStationRuntimes && visibility.showingIrrigation
									&& (station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running ||
										station.irrigationStatus === RbEnums.Common.IrrigationStatus.Soaking)? 'db' : 'dn'}">
								${station.runTimeRemaining && station.irrigationStatus !== RbEnums.Common.IrrigationStatus.Soaking?
									RbUtils.Stations.secondsToHhMmSs(station.runTimeRemaining) : station.mapStatus}
							</span>
						</div>
						<div class="badges">${this.getBadges(station)}</div>
						${station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running
							&& visibility.showingIrrigation? `<div class="${options?.haloHTML ? 'pulse-with-halo ' : ''}pulse">` : ''}
					</div>
					${options?.haloHTML ? '</div>' : ''}
				</div>`;

			return html;
		}

		/**
		 * Creates a leaflet icon for a station based on its properties
		 * 
		 * Station properties used
		 * 
		 *  - `irrigationStatus`:  directly determines the main icon (blue = irrigating, orange = paused, gray with white = idle, etc)
		 *  - `suspended`:  makes the icon display a suspended state
		 *  - `feedback`:  if value is "no feedback" makes the icond isplay a no-feedback state
		 *  - `nozzleColor`:  Makes the icon reflect the color of the nozzle of the station
		 *  - `runrTimeRemaining` & `runTimeSoFar`:  needed to represent remaining runtime with the icon's piechart when the station is irrigating
		 *  - `visible`:  whether or not to add a class to keep the icon hidden, based on user preference
		 *  - `isSelected`:  determines if the selected state (white outline) should be displayed
		 * 
		 * @param station The station that we are generating an icon for
		 * @param hasError Whether or not the station has an error. If it does and the user allows it, the icon will reflect the error
		 * @param showingIrrigation Whether or not we are allowing irrigation status to show on the map
		 * @param haloHTML HTML elements of the halo to be used on the generated icon (IQ4)
		 * @returns A leaflet icon object
		 */
		static iconForStation(
			station: StationWithMapInfoLeaflet,
			hasError: boolean,
			hasNotes: boolean,
			showingIrrigation: boolean,
			haloHTML?: string
		): L.DivIcon {
			let icon: L.DivIcon;
			let className = 'station station-';
			let html = '';

			const classStatus = RbEnums.Common.EClassStatus;
			const iconAnchor: L.PointExpression = [10, 10];
			const iconSize: L.PointExpression = [20, 20];

			station.classStatus = classStatus.Default;

			switch (station.irrigationStatus) {
				default: // If we don't know the status, choose Idle
				case RbEnums.Common.IrrigationStatus.Idle:
					if (hasError) {
						if (station.suspended) {
							station.classStatus = classStatus.Suspended;
							html = this.getStationMarkerTemplate(station, {innerCircleHTML: "<span>|</span>", haloHTML});
						} else if (
							station.feedback ===
								RbEnums.Common.DiagnosticFeedbackResult.NO_FB &&
							!station.suspended
						) {
							station.classStatus = classStatus.NoFeedback;
							html = this.getStationMarkerTemplate(station, {innerCircleHTML: "<span>?</span>", haloHTML});
						} else {
							station.classStatus = classStatus.Error;
							html = this.getStationMarkerTemplate(station, {
								innerCircleHTML: "<i class='material-icons icon-station_disconnected icon-station-disconnected-style'></i>",
								haloHTML
							});
						}
					} else {
						station.classStatus = classStatus.Idle;
						html = this.getStationMarkerTemplate(station, {
							nozzleColor: station.nozzleColor ? station.nozzleColor.toLowerCase() : '#ddd',
							haloHTML
						});
					}
					break;

				case RbEnums.Common.IrrigationStatus.Pending:
					station.classStatus = classStatus.Pending;
					html = this.getStationMarkerTemplate(station, {haloHTML});
					break;

				case RbEnums.Common.IrrigationStatus.Running:
					let pieHTML: string;
					let nozzleColor: string;

					if (showingIrrigation) {
						station.classStatus = classStatus.Running;
						const progress = 100 - ((station.runTimeRemaining / (station.runTimeRemaining + (station.runTimeSoFar || 0))) * 100);
						pieHTML = `<div class="pie" style="--p: ${ progress }"></div>`;
					} else {
						station.classStatus = classStatus.Idle;
						nozzleColor = station.nozzleColor ? station.nozzleColor.toLowerCase() : '#ddd';
					}

					html = this.getStationMarkerTemplate(station, {pieHTML, nozzleColor, haloHTML});
					break;

				case RbEnums.Common.IrrigationStatus.Paused:
					station.classStatus = classStatus.Paused;
					html = this.getStationMarkerTemplate(station, {innerCircleHTML: "<span class='material-icons'>pause</span>", haloHTML});
					break;

				case RbEnums.Common.IrrigationStatus.Soaking:
					station.classStatus = classStatus.Soaking;
					html = this.getStationMarkerTemplate(station, {haloHTML});
					break;
			}

			icon = L.divIcon({
				className: `${className}${station.classStatus}
					${station.visible ? '' : 'd-none'} 
					${station.isSelected ? 'station-selected' : ''}
					${ hasNotes ? "has-note" : '' }`,
				iconAnchor, iconSize, html
			});
	
			return icon;
		}

		/**
		 * Used to set property stationValveType.selectItemDisplayString using translations, then we can
		 * use it to display a dropdown text.
		 * @param valveTypes The valveTypes we want to show in the select list.
		 * @returns String with a format like this: "V1: 59FA50 valveTypeDescription".
		 */
		static getSwitchCodeSelectDisplayFormat(valveTypes: StationValveType): string {
			// we do this so it doesn't show as NULL in text.
			const code: string | number = valveTypes.switchCode ? valveTypes.switchCode : '';
			const description: string = valveTypes.valveTypeDescription ? valveTypes.valveTypeDescription : '';

			const params = { valveNumber: valveTypes.valveNumber, code, description };
			const format = RbUtils.Translate.instant("STRINGS.SWITCH_CODE_SELECT_ITEM_FORMAT", params);
			return format;
		}

		/**
		 * Calculates the runtime needed by a station to irrigate the specified amount of application using
		 * the station's prec rate
		 * 
		 * @param station The station for which we are calculating runtime
		 * @param volumeValue The application value we are using to calculate runtime
		 * @param volumeLengthType The application value's measure unit
		 * @returns An object that represents the time the station will take to 
		 * irrigate the specified amount of application
		 */
		static getRuntimeFromApplication(station: Station, volumeValue: number, volumeLengthType: RbEnums.Common.LengthUnit) {
			const length = RbUtils.Common.ToLength(volumeValue, volumeLengthType, RbEnums.Common.LengthUnit.Inch);
			const runtimeTicks = RbUtils.Conversion.convertMinToTicks((length / station.precRateFinal) * 60);
			return RbUtils.Conversion.convertTicksToHMSObject(runtimeTicks);
		}
	
		/**
		 * Calculates the runtime needed by a station to irrigate for the specified number of passes
		 * using the station's rotation rate
		 * 
		 * @param station The station for which we are calculating runtime
		 * @param passes Number of passes the station should run for
		 * @returns An object that represents the time the station will take to run for the 
		 * specified number of passes
		 */
		static getRuntimeFromRotation(station: Station, passes: number) {
			const onePass = (station.arc / 360) * station.rotationTime;
			const runtimeTicks = RbUtils.Conversion.convertMinToTicks((onePass * passes) / 60);
			return RbUtils.Conversion.convertTicksToHMSObject(runtimeTicks);
		}

		static getStationNumberInArea(station: Station): number {
			if (station.stationArea && station.stationArea.length) {
				return station.stationArea.find(a => a.area.level === RbEnums.Common.AreaLevel.GolfArea).number;
			}
		}

		static getAreaNumber(station: Station): number {
			if (station.stationArea && station.stationArea.length) {
				return station.stationArea.find(a => a.area.level === RbEnums.Common.AreaLevel.GolfArea).area.number;
			}
		}

		static getHoleNumber(station: Station): number {
			if (station.stationArea && station.stationArea.length) {
				return station.stationArea.find(a => a.area.level === RbEnums.Common.AreaLevel.Hole).number
			}
		}
	}
}

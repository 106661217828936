import * as moment from 'moment';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { CultureSettingsManagerService } from '../../../../../api/culture-settings/culture-settings-manager.service';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { DryRunPeriod } from '../models/dry-run-period.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RbConstants } from '../../../../../common/constants/_rb.constants';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { SelectListItem } from '../../../../../api/_common/models/select-list-item.model';

import RangeDirection = RbEnums.Common.RangeDirection;

@UntilDestroy()
@Component({
	selector: 'rb-dry-run-date-time-selector',
	templateUrl: './dry-run-date-time-selector.component.html',
	styleUrls: ['./dry-run-date-time-selector.component.scss'],
	providers: [
		{
			provide: DateAdapter, useClass: AppDateAdapter
		},
	]
})
export class DryRunDateTimeSelectorComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'rb-dry-run-date-time-selector';

	@Output() dateRangeChange = new EventEmitter<DryRunPeriod>();
	@Output() isMobileFilterDisplayedChange = new EventEmitter<boolean>();

	@Input() isMobileFilterDisplayed = false;
	@Input() dateRanges: SelectListItem[] = [];

	private _forceMobileFilter = false;
	@Input() set forceMobileFilter(value: boolean) {
		this._forceMobileFilter = value;
		this.isMobile = value;
		this.setMobileHeaderText();
	}

	get forceMobileFilter(): boolean {
		return this._forceMobileFilter;
	}

	// @ts-ignore
	private _selectedDateRange: RbConstants.Form.DATE_RANGE;
	// @ts-ignore
	@Input() set selectedDateRange(value: RbConstants.Form.DATE_RANGE) {
		this._selectedDateRange = value;

		if (this._selectedDateRange !== RbConstants.Form.DATE_RANGE.custom) {
			const dateRange = RbUtils.DateRange.getDateRange(value, null, RangeDirection.FromDate);
			this.startDate = dateRange.startDate;
			this.endDate = moment(dateRange.endDate).subtract(1, 'minutes').toDate();
		}

		this.setMobileHeaderText();

		this.minEndDate = this.startDate;
		this.startTime = this.startDate;
		this.endTime = this.endDate;

		// RB-9918: Custom Dry Run needs to be limited to time span of 7 days in the future for CirrusIC
		const isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		if (isGolfSite) {
			this.maxEndDate = moment(this.startDate).add(7, 'days').toDate();
			// don't limit the start date selection
			this.maxStartDate = null;
		} else {
			this.maxEndDate = moment().add(1, 'months').toDate();
			this.maxStartDate = this.maxEndDate;
		}

		if (this.endDate > this.maxEndDate) {
			this.endDate = this.maxEndDate;
			this.endTime = this.endDate;
		}

		this.dateRangeChange.emit(new DryRunPeriod(this.startDate, this.endDate));
	}

	// @ts-ignore
	get selectedDateRange(): RbConstants.Form.DATE_RANGE {
		return this._selectedDateRange;
	}

	dateRangeValues;
	startDate: Date;
	startTime: Date;
	minStartDate: Date;
	endDate: Date;
	endTime: Date;
	minEndDate: Date;
	maxEndDate: Date;
	maxStartDate: Date;
	isMobile = false;

	headerStartDate: string;
	headerStartTime: string;
	headerEndDate: string;
	headerEndTime: string;
	usersDateFormat: string;
	showFilterPanel = false;
	animateFilterPanel = false;

	private readonly oneDayDateRange: DryRunPeriod;
	private readonly threeDayDateRange: DryRunPeriod;
	private readonly oneWeekDayDateRange: DryRunPeriod;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private cultureSettingsManager: CultureSettingsManagerService,
				private dateAdapter: DateAdapter<Date>,
				private datePipe: DatePipe,
				private deviceManager: DeviceManagerService,
	) {
		this.oneDayDateRange = RbUtils.DateRange.getDateRange(RbConstants.Form.DATE_RANGE.oneDay, null, RangeDirection.FromDate);
		this.threeDayDateRange = RbUtils.DateRange.getDateRange(RbConstants.Form.DATE_RANGE.threeDays, null, RangeDirection.FromDate);
		this.oneWeekDayDateRange = RbUtils.DateRange.getDateRange(RbConstants.Form.DATE_RANGE.oneWeek, null, RangeDirection.FromDate);
	}

	ngOnInit() {
		this.dateRangeValues = this.dateRanges;
		this.minStartDate = RbUtils.DateRange.setToNoonHour(moment()).toDate();

		// According to the Culture setting, set Calender start Day
		this.dateAdapter.getFirstDayOfWeek = () => this.authManager.userCulture.calendarFormatId;

		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => {
				this.isMobile = isMobile || this.forceMobileFilter;
			});

		// Monitor culture settings change to properly update date format on filter header.
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.usersDateFormat = RbUtils.Common.getDateFormat(this.authManager.userCulture);
				this.setMobileHeaderText();
			});

		this.isMobile = this.deviceManager.isMobile || this.forceMobileFilter;
		this.initHeader();
	}

	ngOnDestroy() {
		/** Required by untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onUserChangedStartDate(startDateEvent: MatDatepickerInputEvent<any>) {
		const previousStartDate = this.startDate;

		if (startDateEvent.value === null) {
			startDateEvent.target.value = previousStartDate;
			return;
		}

		const m = moment(this.startDate);
		this.startDate = moment(startDateEvent.value).startOf('day').add(m.hours(), 'hour').add(m.minutes(), 'minutes').toDate();
		this.validateAndEmitStartDate();
	}

	onUserChangedEndDate(endDateEvent: MatDatepickerInputEvent<any>) {
		const previousEndDate = this.endDate;

		if (endDateEvent.value === null) {
			endDateEvent.target.value = previousEndDate;
			return;
		}

		const m = moment(this.endDate);
		this.endDate = moment(endDateEvent.value).startOf('day').add(m.hours(), 'hour').add(m.minutes(), 'minutes').toDate();
		this.validateAndEmitEndDate();
	}

	onStartTimeChanged(date: Date) {
		// Only the time portion of "date" is valid, so use that
		const m = moment(date);
		this.startDate = moment(this.startDate).startOf('day').add(m.hours(), 'hour').add(m.minutes(), 'minutes').toDate();
		this.validateAndEmitStartDate();
	}

	onEndTimeChanged(date: Date) {
		// Only the time portion of "date" is valid, so use that
		const m = moment(date);
		this.endDate = moment(this.endDate).startOf('day').add(m.hours(), 'hour').add(m.minutes(), 'minutes').toDate();
		this.validateAndEmitEndDate();
	}

	onDateRangeChange(rangeValue: number) {
		this.selectedDateRange = rangeValue;
	}

	onFilterClick() {
		this.isMobileFilterDisplayedChange.emit(!this.showFilterPanel);

		if (this.showFilterPanel) {
			this.animateFilterPanel = false;
			setTimeout(() => this.showFilterPanel = false, 500);
			return;
		}

		this.showFilterPanel = true;
		setTimeout(() => this.animateFilterPanel = true, 100);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private validateAndEmitStartDate() {
		if (this.maxStartDate && (this.startDate > this.maxStartDate)) { this.startDate = this.maxStartDate; }

		if (this.endDate < this.startDate) {
			this.endDate = moment(this.startDate).add(1, 'minutes').toDate();
			this.endTime = this.endDate;
		}

		this.selectedDateRange = RbConstants.Form.DATE_RANGE.custom;
	}

	private validateAndEmitEndDate() {
		if (this.endDate > this.maxEndDate) { this.endDate = this.maxEndDate; }
		if (this.endDate < this.startDate) { this.endDate = moment(this.startDate).add(1, 'minutes').toDate(); }

		this.selectedDateRange = this.getCurrentDateRangeType();
	}

	// @ts-ignore
	private getCurrentDateRangeType(): RbConstants.Form.DATE_RANGE {
		if (this.isCurrentRangeKnownRange(this.oneDayDateRange)) {
			return RbConstants.Form.DATE_RANGE.oneDay;
		} else if (this.isCurrentRangeKnownRange(this.threeDayDateRange)) {
			return RbConstants.Form.DATE_RANGE.threeDays;
		} else if (this.isCurrentRangeKnownRange(this.oneWeekDayDateRange)) {
			return RbConstants.Form.DATE_RANGE.oneWeek;
		}

		return RbConstants.Form.DATE_RANGE.custom;
	}

	private isCurrentRangeKnownRange(dateRange: {startDate: Date, endDate: Date}) {
		return moment(dateRange.startDate).diff(moment(this.startDate)) === 0
			&& moment(dateRange.endDate).subtract(1, 'minute').diff(moment(this.endDate)) === 0;
	}

	private initHeader() {
		if (!this.authManager.userCulture) {
			setTimeout(() => this.initHeader(), 10);
			return;
		}

		this.usersDateFormat = RbUtils.Common.getDateFormat(this.authManager.userCulture);
		if (this.startDate && this.endDate) { this.setMobileHeaderText(); }
	}

	private setMobileHeaderText() {
		if (!this.isMobile || !this.usersDateFormat || !this.startDate || !this.endDate) { return; }

		this.headerStartDate = this.datePipe.transform(this.startDate, this.usersDateFormat);
		this.headerStartTime = this.authManager.userCulture.toUserTimeString(this.startDate);
		this.headerEndDate = this.datePipe.transform(this.endDate, this.usersDateFormat);
		this.headerEndTime = this.authManager.userCulture.toUserTimeString(this.endDate);
	}
}

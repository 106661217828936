import { CourseViewAreaOrdinal } from './course-view-area-ordinal.model';

export class CourseViewArea {

	constructor(json: any = null, nonGolfHoleIndexesArray: number[]) {
		if (json) {
			if (json.id) { this.id = json.id; }
			if (json.name) { this.name = json.name; }
			if (json.isGolfArea != null) { this.isGolfArea = json.isGolfArea; }
			if (json.labels) { this.ordinals = json.labels.map(item => new CourseViewAreaOrdinal(item, nonGolfHoleIndexesArray)); }
		}
	}

	id: number;
	name: string;
	isGolfArea: boolean;
	ordinals: CourseViewAreaOrdinal[] = [];
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { LxIvmPingTestItem } from '../../../../../sections/controllers/manual-ops/manual-ops-diagnostics-tab/models/lx-ivm-ping-test-item.model';

@Component({
	selector: 'rb-ivm-ping-test-mobile-cell-renderer',
	templateUrl: './ivm-ping-test-mobile-cell-renderer.component.html',
	styleUrls: ['./ivm-ping-test-mobile-cell-renderer.component.scss']
})
export class IvmPingTestMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	pingTestItem: LxIvmPingTestItem;

	constructor(public appDateAdapter: AppDateAdapter,
				injector: Injector
	) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.pingTestItem = params.data;
	}

}

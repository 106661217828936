import { EventEmitter } from '@angular/core';
import { IIrrigationEngineStationStatusItem } from './_irrigation-engine-station-status-item.interface';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';

export class StationStatusChange implements IIrrigationEngineStationStatusItem {

	// Events
	stoppingIrrigation = new EventEmitter();

	private static timers: { [stationId: string]: number } = {};
	private static secondsRemainingEvents = {};

	get secondsRemainingChange() {
		return StationStatusChange.secondsRemainingEvents[this.stationId];
	}	

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.changeDateTime && json.changeDateTime instanceof String) {
				this.changeDateTime = RbUtils.Conversion.convertStringToDate(json.changeDateTime);
			}

			if (this.showTimeRemaining && this.hasRuntime()) {
				this.startCountdownTimer();
			}

			// RB-9052: We want the enumerated type of the reasonCode, so we have to translate from string, if that format was used
			// during serialization.
			if (this.reasonCode != null && ( typeof this.reasonCode === 'string' || this.reasonCode instanceof String)) {
				// Look up the enumerated type value based on the string and change it to the enumerated type value instead
				// of the string. The Object.entries() for the enumerated type look something like this:
				// 0: (2) ['0', 'NoError']
				// 1: (2) ['1', 'NoFieldConnection']
				// ....
				// 13: (2) ['13', 'NoGroup']
				// 14: (2) ['1000', 'Error']
				//   We use this portion of the entries array comparing the incoming string to the 'key' element and casting the
				//   numeric value to the right enumerated type.
				// 15: (2) ['NoError', 0]
				// 16: (2) ['NoFieldConnection', 1]
				// ...
				// 28: (2) ['NoGroup', 13]
				// 29: (2) ['Error', 1000]
				this.reasonCode = <RbEnums.Common.StationFailureReasonCode>Object.entries(RbEnums.Common.StationFailureReasonCode)
					.find(([key, value]) => key === this.reasonCode)[1];
			}

			if (!StationStatusChange.secondsRemainingEvents[this.stationId]) {
				StationStatusChange.secondsRemainingEvents[this.stationId] = new EventEmitter<number>();
			}
		}
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================

	stationId: number;
	stationName?: string;

	/**
	 * RB-11625: ID of station's pump. NOTE: THIS IS NOT SENT VIA SIGNALR, ONLY WHEN RETRIEVING FLOW CHART DATA FROM THE API.
	 */
	pumpId?: number;

	runTimeSoFar?: number;
	runTimeSoFarIrrigationDay?: number;
	runTimeRemaining?: number;
	cycleTimeLong?: number;
	cycleTimeRemaining?: number;
	soakTimeLong?: number;
	soakTimeRemaining?: number;
	lastManualRunStartTime?: Date;
	lastRunTimeSeconds?: number;
	reasonCode?: any = null;		// The reasonCode arrives as a string, but we update it to a StationFailureReasonCode enum value.
	changeType: string;
	companyId?: number;
	changeDateTime: Date;
	currentFlowRateGPM?: number;
	errorMessage?: string = null;

	// Used IrrigationActivityChartService
	programGroupId: number;
	programId: number;

	/**
	 * itemsChanged will contain the patch changes made to the object, if discernable by the API. For example,
	 * this might contain new values for terminal and channel if the engine reprograms the station's fast
	 * connect address. Here's the portion of the object associated with itemsChanged when the engine
	 * successfully updates a station's fast connect address:
	 * "itemsChanged": {
     *   "ids": [
     *       14414
     *   ],
     *   "patch": [
     *       {
     *           "value": 5,
     *           "path": "/Channel",
     *           "op": "replace"
     *       },
     *       {
     *           "value": 1,
     *           "path": "/Terminal",
     *           "op": "replace"
     *       }
     *   ]
     * },
	 */
	itemsChanged: any;

	clearCountdownTimer() {
		if (StationStatusChange.timers[this.stationId]) { 
			clearInterval(StationStatusChange.timers[this.stationId]); 
			StationStatusChange.timers[this.stationId] = null;
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	// Method to start runTimeRemaining countdown timer. This timer will tick down the remaining seconds in between receiving new StationStatusChange
	// via SignalR. The event fired within the timer will be caught by StationListItems and ProgramStepListItems to update the time remaining in the UI.
	private startCountdownTimer() {
		this.clearCountdownTimer();

		StationStatusChange.timers[this.stationId] = window.setInterval(() => {
			if (this.cycleTimeRemaining - 1 >= 0) {
				--this.cycleTimeRemaining;
				this.secondsRemainingChange.emit(this.cycleTimeRemaining);
			} else if (this.soakTimeRemaining - 1 >= 0) {
				--this.soakTimeRemaining;
				this.secondsRemainingChange.emit(this.soakTimeRemaining);
			} else {
				this.clearCountdownTimer();				
			}
		}, 1000);
	}

	private get showTimeRemaining(): boolean {
		switch (this.changeType) {
			case RbEnums.SignalR.StationStatusChangeType.RunningUpdate:
			case RbEnums.SignalR.StationStatusChangeType.Started:
			case RbEnums.SignalR.StationStatusChangeType.SoakStart:
			case RbEnums.SignalR.StationStatusChangeType.Resumed:
				return true;

			default:
				return false;
		}
	}

	private hasRuntime(): boolean {
		return (this.cycleTimeRemaining && this.cycleTimeRemaining > 0) || (this.soakTimeRemaining && this.soakTimeRemaining > 0);
	}

}

import { Component, HostBinding, Input } from '@angular/core';

import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { DigitalManagerService } from '../../../api/digital/digital-manager.service';
import { GprsLicenseListItem } from '../../../api/license-api-cloud/models/gprs-license-list-item.model';
import { MessageBoxService } from '../../../common/services/message-box.service';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-gprs-renew-warning',
	templateUrl: './gprs-renew-warning.component.html',
	styleUrls: ['./gprs-renew-warning.component.scss'],
})
export class GprsRenewWarningComponent {
	@HostBinding('class') class = 'rb-gprs-renew-warning';
	ExpiringConstants = RbConstants.Form.GPRS_LICENSE;

	@Input() warningText: string;

	private _expiryState = RbConstants.Form.GPRS_LICENSE.expired;
	// @ts-ignore
	@Input() set expiryState(value: RbConstants.Form.GPRS_LICENSE) {
		this._expiryState = value;
		this.checkIfShouldShowExpiryDate();
	}

	// @ts-ignore
	get expiryState(): RbConstants.Form.GPRS_LICENSE {
		return this._expiryState;
	}

	private _expiryDate: string;
	@Input() set expiryDate(value: string) {
		this._expiryDate = value;
		this.checkIfShouldShowExpiryDate();
	}

	get expiryDate(): string {
		return this._expiryDate;
	}

	list: GprsLicenseListItem [];
	hasExpirationDate = false;

	// =========================================================================================================================================================
	// C'tor and Init
	// =========================================================================================================================================================

	constructor(
		private authManager: AuthManagerService,
		private siteManager: SiteManagerService,
		private translateService: TranslateService,
		private messageBoxService: MessageBoxService,
		private digitalManagerService: DigitalManagerService
	) { }

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onNavigate() {
		this.getLicenseData();	
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getLicenseData() {
		// User is not owner, IQ4 needs to not allow this access to online store
		if (!this.siteManager.isGolfSite && !this.authManager.isOwner) {
			return;
		}

		// Only allow the Company Owner access to the subscription store
		this.digitalManagerService.openDigitalUrl().subscribe({
			next: () => {},
			error: (error) => {
				this.messageBoxService.showMessageBox(`${this.translateService.instant('SPECIAL_MSG.REQUESTED_OPERATION_FAILED')} ${error.error}`);
			}
		});
	}

	private checkIfShouldShowExpiryDate() {
		this.hasExpirationDate = RbUtils.Common.isGprsExpried(this.expiryState);
	}
}

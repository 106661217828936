<div class="ici-status-cell"
	[class.ok]="data.status === 'OK'"
	[class.error]="data.status === 'WARNING'">
	<ng-container *ngIf="data.isWaiting">
		{{'STRINGS.WAITING' | translate}}
	</ng-container>
	<ng-container *ngIf="!data.isWaiting">
		<ng-container *ngIf="data.faultFindingON">
			<span class="status-running">{{convertToSecondToFriendlyString(data.timeRemainingForStatusChange)}}</span>
		</ng-container>
		<ng-container *ngIf="!data.faultFindingON">
			<span *ngIf="data.status === 'OK'" class="rb-status-icon icon-confirmation"></span>
			<span *ngIf="data.status === 'WARNING'" class="rb-status-icon icon-warning"></span>
		</ng-container>
	</ng-container>
	
</div>

/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Forms.getPatchValuesForFormGroup
 * 		 See: _rb.utils.ts
 */

import { FormGroup } from '@angular/forms';

export namespace XXUseRbUtilsNamespace {

	export abstract class Forms {

		/**
		 * @summary Get a suitable JsonPatch object from the indicated FormGroup. This will be usable for most Update or UpdateBatches
		 * API calls and will include entries for each of the active form group editors when batch editing. It will *optionally*
		 * remove any form values that are 'undefined'. This allows you to batch edit some properties but, if the user doesn't change
		 * them, to ignore them in the patch document generated.
		 * @param fg - FormGroup containing the updated data to be sent as a patch.
		 * @param removeUndefined - boolean value defaulting to 'false'. If true, will ignore any patch values that would otherwise
		 * be generated as 'undefined'. This allows you to remove things that were not changed during a batch edit.
		 */
		static getPatchValuesForFormGroup(fg: FormGroup, removeUndefined: boolean = false) {
			const changedProperties = [];
			Object.keys(fg.controls).forEach(name => {
				const currentControl = fg.controls[name];
				if (currentControl.dirty) {
					changedProperties.push( {
						name: name,
						value: currentControl instanceof FormGroup ? this.getPatchValuesForFormGroup(currentControl) : currentControl.value,
					});
				}
			});
			return changedProperties.length === 0 ? null : changedProperties.reduce((map, obj) => {
				if (!removeUndefined || obj.value !== undefined) {
					map[obj.name] = obj.value;
				}
				return map;
			}, {});
		}

		static getKeysAndValuesForEnum(enumObject): any[] {
			const keys = [];
			for (const enumMember in enumObject) {
				if (!isNaN(parseInt(enumMember, 10))) {
					keys.push({ key: parseInt(enumMember, 10), value: enumObject[enumMember] });
				}
			}
			return keys;
		}
	}
}

import { BroadcastService } from '../../common/services/broadcast.service';
import { Injectable } from '@angular/core';
import { KMZItem } from './models/kmz-Item.model';
import { LeafletApiService } from './leaflet-api.service';
import { Observable } from 'rxjs';
import { RasterItem } from './models/raster-file.model';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class LeafletManagerService extends ServiceManagerBase {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private leafletApiService: LeafletApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
	}

	// =========================================================================================================================================================
	// Raster Properties and Methods
	// =========================================================================================================================================================

	getRasterItemsForSite(siteId: number, bypassCache: boolean): Observable<RasterItem[]> {
		return this.leafletApiService.getRasterItemsForSite(siteId, bypassCache);
	}

	addRasterItem(siteId: number, data: any): Observable<RasterItem> {
		return this.leafletApiService.addRasterItem(siteId, data);
	}

	updateRasterItem(rasterItemId: number, data: any): Observable<RasterItem> {
		return this.leafletApiService.updateRasterItem(rasterItemId, data);
	}

	updateRasterItemList(data: {id: number, item: RasterItem}[]): Observable<void> {
		return this.leafletApiService.updateRasterItemList(data);
	}

	deleteRasterItem(siteId: number, id: number): Observable<any> {
		return this.leafletApiService.deleteRasterItem(siteId, id);
	}

	// =========================================================================================================================================================
	// KMZ Properties and Methods
	// =========================================================================================================================================================

	getKmzItemById(kmzItemId: number): Observable<KMZItem> {
		return this.leafletApiService.getKmzItemById(kmzItemId);
	}

	getActiveKmzItems(siteId: number, mapPref: string): Observable<KMZItem[]> {
		return this.leafletApiService.getActiveKmzItems(siteId, mapPref);
	}

	getKmzItemsForSite(siteId: number, bypassCache: boolean): Observable<KMZItem[]> {
		return this.leafletApiService.getKmzItemsForSite(siteId, bypassCache);
	}

	addKmzItem(data: any): Observable<KMZItem> {
		return this.leafletApiService.addKmzItem(data);
	}

	updateKmzItem(kmzItemId: number, data: any): Observable<KMZItem> {
		return this.leafletApiService.updateKmzItem(kmzItemId, data);
	}

	updateKmzItemList(data: {id: number, item: KMZItem}[]): Observable<KMZItem> {
		return this.leafletApiService.updateKmzItemList(data);
	}

	deleteKmzItem(siteId: number, id: number): Observable<any> {
		return this.leafletApiService.deleteKmzItem(siteId, id);
	}

	// =========================================================================================================================================================
	// Shape File Properties and Methods
	// =========================================================================================================================================================

	getCoordinateSystemsList() {
		return this.leafletApiService.getCoordinateSystemsList();
	}

	getGeojsonFromShapeFiles(epsg: string, shpFile: File, dbfFile: File, shxFile: File) {
		return this.leafletApiService.getGeojsonFromShapeFiles(epsg, shpFile, dbfFile, shxFile);
	}

}

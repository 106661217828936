<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<!--[ngClass]="{'fadable-grid': isFadableGrid, 'fade-in': isGridDisplayable}"-->

<div class="ag-grid-container" [ngClass]="{'fadable-grid': isFadableGrid, 'fade-in': isGridDisplayable}">
	<div class="flex w-100 center" [class.rb-mw10]="!noGridMargin">

		<!-- Grid Container -->
		<div class="side-section-right rb-flex-grow-2 ph0-ns w-100 relative" [class.rb-mw10]="!noGridMargin" [ngClass]="{'is-tablet': isTablet}">

			<!-- Grid Fab Button -->
			<rb-fab-button *ngIf="includeFabButton && !includeFabGroupButton"
				data-qaid="create-button"
				class="rb-fab-button z-1"
				(click)="handleFabClick()"
			>
			</rb-fab-button>
			<div class="mr4-l w-100-m bg-white" *ngIf="showGrid">
				<ng-content select="[additional-filters]"></ng-content>
			</div>

			<rb-table-wrapper
				[showGrid]="showGrid"
				#tableWrapper
				class="rb-table-wrapper"
				[groupDefaultExpanded]="groupDefaultExpanded"
				[ngClass]="{'agGridMobile': isMobile}"
				[isMobile]="isMobile"
				[rowSelection]="rowSelection"
				[rowData]="rowData"
				[rowClassCallback]="rowClassCallback"
				[rowHeight]="rowHeight"
				[enableSorting]="enableSorting"
				[domLayout]="domLayout"
				[hideRowSelection]="hideRowSelection"
				[hideRowSelectionButLeaveSpace]="hideRowSelectionButLeaveSpace"
				[isClickable]="isClickable"
				[includeCheckbox]="includeCheckbox"
				[checkboxClass]="checkboxClass"
				[checkboxClassCallback]="checkboxClassCallback"
				[headerCheckboxSelection]="headerCheckboxSelection"
				[suppressScrollOnNewData]="suppressScrollOnNewData"
				[columns]="columns"
				[instructionsText]="instructionsText"
				(onMobileCommandRequested)="onMobileCommandRequested($event)"
				[rowDrag]="rowDrag"
				[rowDragFieldName]="dragFieldName"
				[useManagedRowDragging]="useManagedRowDragging"
				[hideMainTreeNodeColumn]="hideMainTreeNodeColumn"
				(onRowSelect)="onSelectionChanged($event)"
				(onRowClick)="onRowClicked($event)"
				(onCellClick)="onCellClicked($event)"
				(onRowDataChanged)="handleRowChanged($event)"
				(dragCompleted)="onDragCompleted($event)"

				[treeNodeOrder]="treeNodeOrder"
				[treeNodeField]="treeNodeField"
				[treeNodeHeaderName]="treeNodeHeaderName"
				[treeNodeDesiredWidth]="treeNodeDesiredWidth"
				[treeNodeCallback]="treeNodeCallback"
				[treeNodeUseMobileRendererWithHeader]="treeNodeUseMobileRendererWithHeader"
				[treeNodeUseMobileRendererWithNonHeader]="treeNodeUseMobileRendererWithNonHeader"
				[treeNodeIsHeaderCallback]="treeNodeIsHeaderCallback"
				[treeInnerRendererCallback]="treeInnerRendererCallback"
				[treeNodeMobileExpanderColWidth]="treeNodeMobileExpanderColWidth"
				[includeTreeInnerRendererCallback]="includeTreeInnerRendererCallback"
				[treeSuppressMenu]="treeSuppressMenu"
				[treeSortable]="treeSortable"
				(treeNodeExpanded)="onTreeNodeExpanded($event)"

				[treeInnerRendererComponent]="treeInnerRendererComponent"
				[treeInnerRendererComponentParams]="treeInnerRendererComponentParams"

				[mobileCellRendererComponent]="mobileCellRendererComponent"
				[mobileCellRendererParams]="mobileCellRendererParams"
				[mobileRendererDesiredWidth]="mobileRendererDesiredWidth"
				[mobileRightArrow]="mobileRightArrow"
				[mobileNavigation]="mobileNavigation"
				[mobileExtraColumns]="mobileExtraColumns"

				[masterDetail]="masterDetail"
				[isRowMaster]="isRowMaster"
				[detailCellRenderer]="detailCellRenderer"
				[detailCellRendererParams]="detailCellRendererParams"
				[detailRowHeight]="detailRowHeight"
				[detailRowAutoHeight]="detailRowAutoHeight"
				[getRowHeight]="getRowHeight"
				[totalRecords]="totalRecords"
				[selectedData]="selectedData"

				[isServerSideGroupOpenByDefault]="isServerSideGroupOpenByDefault"
				[isServerSideGroup]="isServerSideGroup"
				[getServerSideGroupKey]="getServerSideGroupKey"
				[rowModelType]="rowModelType"
				[isRowSelectable]="isRowSelectable"
				[ignoreMobileView]="ignoreMobileView"
				(onGridReady)="onGridReady($event)"
			>
			</rb-table-wrapper>

			<rb-fab-group-button
				*ngIf="!includeFabButton && includeFabGroupButton"
				[fabButtons]="addFabOptions"
				(optionClick)="onFabOptionSelected($event)"
				class="rb-fab-group-button flex flex-column items-center">
			</rb-fab-group-button>
		</div>
	</div>
</div>

import { KMZItem } from '../../api/leaflet/models/kmz-Item.model';
import { MapInfoLeaflet } from './map-info-leaflet.model';

/**
 * Data to pass in to the Imported Layers Editor
 */
export class ImportedLayersEditorData {
	canEdit: boolean;
	layer?: KMZItem;
	compact?: boolean;
	mapInfo: MapInfoLeaflet;
}

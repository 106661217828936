import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class ControlRequestState {

	constructor(public controllerId: number,
				public property: RbEnums.SignalR.ManualControlProperty,
				public isUpdating: boolean,
				public value?: any) {}

}

import { Component, EventEmitter, Input, Output, } from '@angular/core';

@Component({
	selector: 'rb-primary-button',
	templateUrl: './primary-button.component.html',
	styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent {

	@Input() disabled = false;
	@Input() isLoading: boolean;
	@Input() label: string;
	@Input() modalSubmitting: boolean;

	@Output() primaryButtonClicked = new EventEmitter();
}

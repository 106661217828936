<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="full-screen-dialog">
	<div class="header" >
		<div>{{ 'STRINGS.CONTROLLERS' | translate }}</div>
	</div>

	<div class="main-body">
		<mat-radio-group [ngModel]="currentlySelectedControllerId">
		<div *ngFor="let site of treeItems" class="site">
			<label color="accent">{{site.item}}</label>
			<div *ngFor="let controller of site.children" class="controller">
				<mat-radio-button 
					color="accent"
					[disabled]="controller.isDisabled"
					[value]="controller.id" 
					(change)="onSelectController($event);">{{controller.item}}</mat-radio-button>
			</div>
		</div>
		</mat-radio-group>
	</div>

	<div class="button-area justify-end">
		<button mat-button (click)="onCancel()" data-qaid="dialog-cancel-button">{{'STRINGS.CANCEL' | translate }}</button>
		<button mat-button (click)="onSubmit()" color="primary" data-qaid="dialog-submit-button" [disabled]="!currentlySelectedControllerId">{{'STRINGS.OK' | translate }}</button>
	</div>
</div>>

import { Component, HostBinding, Injector, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AcCurrentTestOptions } from '../../../../../api/manual-ops/models/ac-current-test-options.model';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { MessageBoxInfo } from '../../../../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from '../../../../../common/services/message-box.service';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { SegmentInfoItem } from '../../../../../sections/controllers/manual-ops/manual-ops-diagnostics-tab/models/segment-info-item.model';
import { TranslateService } from '@ngx-translate/core';

import SourceState = RbEnums.Common.SourceState;
import DiagnosticsMode = RbEnums.Common.DiagnosticsMode;
import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;
import MessageBoxButtons = RbEnums.Common.MessageBoxButtons;

@Component({
	selector: 'rb-ivm-short-report-mobile-cell-renderer',
	templateUrl: './ivm-short-report-mobile-cell-renderer.component.html',
	styleUrls: ['./ivm-short-report-mobile-cell-renderer.component.scss']
})
export class IvmShortReportMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';
	@ViewChild('energizeToggle') energizeToggle: MatSlideToggle;

	segmentInfo: SegmentInfoItem;
	isDisabled = false;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(public appDateAdapter: AppDateAdapter,
				private broadcastService: BroadcastService,
				private messageBoxService: MessageBoxService,
				private translateService: TranslateService,
				injector: Injector
	) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.segmentInfo = params.data;
		this.setDisabled();
	}

	refresh(params: any): boolean {
		this.segmentInfo = params.data;
		this.setDisabled();
		return true;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onToggleEnergizePath(event: MatSlideToggleChange) {
		const newState = this.segmentInfo.isEnergized ? SourceState.Off : SourceState.On;

		// If we are de-energizing the path, just do it!
		if (newState === SourceState.Off) {
			this.segmentInfo.mode = DiagnosticsMode.Off;
			this.broadcastToggleEvent(newState);
			return;
		}

		// If we're energizing the path, make sure the user realizes all irrigation will be disabled.
		const mbi = new MessageBoxInfo(this.translateService.instant('SPECIAL_MSG.ENERGIZE_PATH_PROMPT', { path: this.segmentInfo.name }),
			MessageBoxIcon.Question, 'STRINGS.TEST_PATH',
			this.broadcastToggleEvent.bind(this, newState),
			this.cancelToggleEvent.bind(this),
			MessageBoxButtons.YesNo);
		this.messageBoxService.showMessageBox(mbi);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setDisabled() {
		this.isDisabled = this.segmentInfo.isAnyPathEnergized && this.segmentInfo.index !== this.segmentInfo.testPath;
	}

	private broadcastToggleEvent(newState: SourceState) {
		this.segmentInfo.mode = newState === SourceState.On ? DiagnosticsMode.Energized : DiagnosticsMode.Off;
		this.broadcastService.toggleEnergizePath.next(
			{controllerId: this.segmentInfo.controllerId, options: new AcCurrentTestOptions(this.segmentInfo.index, newState)});
	}

	private cancelToggleEvent() {
		this.energizeToggle.toggle();
	}
}

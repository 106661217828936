/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.DateRange.dateRange
 * 		 See: _rb.utils.ts
 */

import * as moment from 'moment';
import { RbConstants } from '../constants/_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';

export namespace XXUseRbUtilsNamespace {

	export abstract class DateRange {

		static RangeDirection = RbEnums.Common.RangeDirection;

		static get dateRange(): any {
			const DateRangeConstants = RbConstants.Form.DATE_RANGE
			const dateRangeValue = [
				{
					id: DateRangeConstants.twelveHours,
					name: 'DATE_RANGES.TWELVE_HOURS'
				},
				{
					id: DateRangeConstants.monthToDate,
					name: 'DATE_RANGES.MONTH_TO_DATE',
				},
				{
					id: DateRangeConstants.oneDay,
					name: 'DATE_RANGES.ONE_DAY',
				},
				{
					id: DateRangeConstants.threeDays,
					name: 'DATE_RANGES.THREE_DAYS',
				},
				{
					id: DateRangeConstants.oneWeek,
					name: 'DATE_RANGES.ONE_WEEK',
				},
				{
					id: DateRangeConstants.thirtyDays,
					name: 'DATE_RANGES.THIRTY_DAYS',
				},
				{
					id: DateRangeConstants.threeMonths,
					name: 'DATE_RANGES.THREE_MONTHS',
				},
				{
					id: DateRangeConstants.sixMonths,
					name: 'DATE_RANGES.SIX_MONTHS',
				},
				{
					id: DateRangeConstants.nintyDays,
					name: 'DATE_RANGES.NINETY_DAYS',
				},
				{
					id: DateRangeConstants.oneEightyDays,
					name: 'DATE_RANGES.ONE_EIGHTY_DAYS',
				},
				{
					id: DateRangeConstants.yearToDate,
					name: 'DATE_RANGES.YTD',
				},
				{
					id: DateRangeConstants.oneYear,
					name: 'DATE_RANGES.ONE_YEAR',
				},
				{
					id: DateRangeConstants.threesixtyFiveDays,
					name: 'DATE_RANGES.THREE_SIXTY_FIVE_DAYS',
				},
				{
					id: DateRangeConstants.custom,
					name: 'STRINGS.CUSTOM',
				},
				{
					id: DateRangeConstants.alarm,
					name: 'STRINGS.ALARMS',
				},
				// water management
				{
					id: DateRangeConstants.twelveHours_wm,
					name: 'DATE_RANGES.TWELVE_HOURS'
				},
				{
					id: DateRangeConstants.oneDay_wm,
					name: 'DATE_RANGES.ONE_DAY',
				},
				{
					id: DateRangeConstants.oneWeek_wm,
					name: 'DATE_RANGES.ONE_WEEK',
				},
				{
					id: DateRangeConstants.thirtyDays_wm,
					name: 'DATE_RANGES.THIRTY_DAYS',
				},
				{
					id: DateRangeConstants.nintyDays_wm,
					name: 'DATE_RANGES.NINETY_DAYS',
				},
				{
					id: DateRangeConstants.oneEightyDays_wm,
					name: 'DATE_RANGES.ONE_EIGHTY_DAYS',
				},
				{
					id: DateRangeConstants.threesixtyFiveDays_wm,
					name: 'DATE_RANGES.THREE_SIXTY_FIVE_DAYS',
				},
				{
					id: DateRangeConstants.monthToDate_wm,
					name: 'DATE_RANGES.MONTH_TO_DATE',
				},
				{
					id: DateRangeConstants.yearToDate_wm,
					name: 'DATE_RANGES.YTD',
				},
				{
					id: DateRangeConstants.sixtyDays,
					name: 'DATE_RANGES.SIXTY_DAYS',
				},
				{
					id: DateRangeConstants.allAlarms,
					name: 'STRINGS.ALL',
				},
			];

			const dr = dateRangeValue.filter(dateRangeVal => (dateRangeVal.id >= DateRangeConstants.oneDay && dateRangeVal.id <= DateRangeConstants.oneWeek));
			const dr1 = dateRangeValue.filter(dateRangeVal => dateRangeVal.id > DateRangeConstants.oneYear && dateRangeVal.id < DateRangeConstants.nintyDays);
			const dr2 = dateRangeValue.filter(dateRangeVal => dateRangeVal.id > DateRangeConstants.oneYear && dateRangeVal.id !== DateRangeConstants.alarm
				&& dateRangeVal.id < DateRangeConstants.nintyDays);

			return {
				reportsDateRange: dateRangeValue.filter(dateRangeVal => dateRangeVal.id !== DateRangeConstants.threeDays
					&& dateRangeVal.id !== DateRangeConstants.alarm && dateRangeVal.id < DateRangeConstants.nintyDays),
				alertsDateRange: [...dr, ...dr1],
				waterUseDataRange: dateRangeValue.filter(dateRangeVal => dateRangeVal.id !== DateRangeConstants.threeDays
					&& dateRangeVal.id !== DateRangeConstants.yearToDate
					&& dateRangeVal.id !== DateRangeConstants.oneYear && dateRangeVal.id !== DateRangeConstants.custom
					&& dateRangeVal.id !== DateRangeConstants.alarm && dateRangeVal.id < DateRangeConstants.nintyDays),
				alertsDateRangeReport: [...dr, ...dr2],
				alarmDateRange: dateRangeValue.filter(dateRangeVal => dateRangeVal.id === DateRangeConstants.thirtyDays
					|| dateRangeVal.id === DateRangeConstants.sixtyDays
					|| dateRangeVal.id === DateRangeConstants.allAlarms),
				waterReportsDateRange: dateRangeValue.filter(dateRangeVal => dateRangeVal.id >= DateRangeConstants.twelveHours_wm
					&& dateRangeVal.id <= DateRangeConstants.yearToDate_wm),
				rainfallReportsDateRange: dateRangeValue.filter(dateRangeVal => dateRangeVal.id >= DateRangeConstants.oneDay
					&& dateRangeVal.id <= DateRangeConstants.thirtyDays
					|| dateRangeVal.id === DateRangeConstants.oneYear || dateRangeVal.id === DateRangeConstants.custom),
				completedDateRange: dateRangeValue.filter(dateRangeVal =>
					dateRangeVal.id === DateRangeConstants.twelveHours			// 12 hours
					|| (dateRangeVal.id >= DateRangeConstants.oneDay && dateRangeVal.id <= DateRangeConstants.oneWeek)
					|| dateRangeVal.id === DateRangeConstants.custom),	// same as alerts, but only with Custom, not Alerts
				dryRunDateRange: [...dr, ...dateRangeValue.filter(drv => drv.id === DateRangeConstants.custom)],
				systemChangeLog: dateRangeValue.filter(dateRangeVal => dateRangeVal.id <= DateRangeConstants.thirtyDays),
				scheduledDateRange: dateRangeValue.filter(dateRangeVal => dateRangeVal.id === DateRangeConstants.oneDay
					|| dateRangeVal.id === DateRangeConstants.threeDays
					|| dateRangeVal.id === DateRangeConstants.twelveHours)
			};

		}

		// NOTE: This is a horrible way to determine which yearlyConsumptionData we have.
		// TODO: MW - Implement a robust check.
		static isWaterReportsDateRange(dateRangeList: { id: number, name: string }[]): boolean {
			const waterReportsDateRange = DateRange.dateRange.waterReportsDateRange;

			// RB-6291: Don't test if(array); you must check for null and undefined. We're using a type-casting-allowed
			// != operator here, rather than two !== tests.
			if (dateRangeList == null || dateRangeList.length !== waterReportsDateRange.length) return false;

			for (let i = 0; i < waterReportsDateRange.length; i++) {
				if (waterReportsDateRange[i].id !== dateRangeList[i].id) return false;
			}

			return true;
		}

		static getDateRangeChangeStartDate(rangeValue: number, startDate?: any) {
			startDate = startDate ? moment(startDate) : moment();

			switch (rangeValue) {
				case RbConstants.Form.DATE_RANGE.monthToDate:
					return this.setToNoonHour(startDate.startOf('month')).toDate();
				case RbConstants.Form.DATE_RANGE.oneDay:
					return this.setToNoonHour(startDate.subtract(1, 'days')).toDate();
				case RbConstants.Form.DATE_RANGE.threeDays:
					return this.setToNoonHour(startDate.subtract(3, 'days')).toDate();
				case RbConstants.Form.DATE_RANGE.oneWeek:
					return this.setToNoonHour(startDate.subtract(1, 'weeks')).toDate();
				case RbConstants.Form.DATE_RANGE.thirtyDays:
					return this.setToNoonHour(startDate.subtract(30, 'days')).toDate();
				case RbConstants.Form.DATE_RANGE.threeMonths:
					return this.setToNoonHour(startDate.subtract(3, 'months')).toDate();
				case RbConstants.Form.DATE_RANGE.sixMonths:
					return this.setToNoonHour(startDate.subtract(6, 'months')).toDate();
				case RbConstants.Form.DATE_RANGE.yearToDate:
					return this.setToNoonHour(startDate.startOf('year')).toDate();
				case RbConstants.Form.DATE_RANGE.oneYear:
					return this.setToNoonHour(startDate.subtract(1, 'years')).toDate();
				case RbConstants.Form.DATE_RANGE.nintyDays:
					return this.setToNoonHour(startDate.subtract(90, 'd')).toDate();
				case RbConstants.Form.DATE_RANGE.oneEightyDays:
					return this.setToNoonHour(startDate.subtract(180, 'd')).toDate();
				case RbConstants.Form.DATE_RANGE.threesixtyFiveDays:
					return this.setToNoonHour(startDate.subtract(365, 'd')).toDate();
				case RbConstants.Form.DATE_RANGE.twelveHours:
					return startDate.subtract(12, 'h').toDate();	// startDate = now. Return now - 12h.
				// default:
				// 	if (this.startDateValue) {
				// 		return this.startDateValue;
				// 	} else {
				// 		this.formatMomentDate(this.setToZeroHour(moment()));
				// 	}
			}
		}

		static getDateRange(rangeValue: number, baseDate?: Date, rangeDirection = DateRange.RangeDirection.FromDate): { startDate: Date, endDate: Date } {
			const baseMoment = baseDate ? moment(baseDate) : moment();
			let params: any[] = [];

			const dateRange = { startDate: new Date(), endDate: new Date() };

			if (rangeDirection === DateRange.RangeDirection.PriorToDate) {
				dateRange.endDate = rangeValue !== RbConstants.Form.DATE_RANGE.twelveHours ? this.setToNoonHour(baseMoment).toDate() : baseMoment.toDate();
			} else {
				dateRange.startDate = rangeValue !== RbConstants.Form.DATE_RANGE.twelveHours ? this.setToNoonHour(baseMoment).toDate() : baseMoment.toDate();
			}

			switch (rangeValue) {
				case RbConstants.Form.DATE_RANGE.monthToDate:
					dateRange.startDate = this.setToNoonHour(baseMoment.startOf('month')).toDate();
					break;
				case RbConstants.Form.DATE_RANGE.yearToDate:
					dateRange.startDate = this.setToNoonHour(baseMoment.startOf('year')).toDate();
					break;

				case RbConstants.Form.DATE_RANGE.twelveHours:
					if (rangeDirection === DateRange.RangeDirection.PriorToDate) {
						dateRange.startDate = baseMoment.subtract(12, 'h').toDate();	// startDate = now. Return now - 12h.
					} else {
						dateRange.endDate = baseMoment.add(12, 'h').toDate();			// startDate = now. Return now - 12h.
					}
					return dateRange;

				case RbConstants.Form.DATE_RANGE.oneDay:
					params = [1, 'days'];
					break;
				case RbConstants.Form.DATE_RANGE.threeDays:
					params = [3, 'days'];
					break;
				case RbConstants.Form.DATE_RANGE.oneWeek:
					params = [1, 'weeks'];
					break;
				case RbConstants.Form.DATE_RANGE.thirtyDays:
					params = [30, 'days'];
					break;
				case RbConstants.Form.DATE_RANGE.sixtyDays:
					params = [60, 'days'];
					break;
				case RbConstants.Form.DATE_RANGE.threeMonths:
					params = [3, 'months'];
					break;
				case RbConstants.Form.DATE_RANGE.sixMonths:
					params = [6, 'months'];
					break;
				case RbConstants.Form.DATE_RANGE.oneYear:
					params = [1, 'years'];
					break;
				case RbConstants.Form.DATE_RANGE.nintyDays:
					params = [90, 'd'];
					break;
				case RbConstants.Form.DATE_RANGE.oneEightyDays:
					params = [180, 'd'];
					break;
				case RbConstants.Form.DATE_RANGE.threesixtyFiveDays:
					params = [365, 'd'];
					break;
			}

			if (rangeDirection === DateRange.RangeDirection.PriorToDate) {
				dateRange.startDate = this.setToNoonHour(baseMoment.subtract(params[0], params[1])).toDate();
			} else {
				dateRange.endDate = this.setToNoonHour(baseMoment.add(params[0], params[1])).toDate();
			}

			return dateRange;
		}

		static formatMomentDate(value) {
			return value.toDate();
		}

		static setToZeroHour(value: moment.Moment) {
			return value.startOf('day');
		}

		static setToNoonHour(value: moment.Moment) {
			return this.setToZeroHour(value).add(12, 'hours');
		}

		static setTo24Hour(value: moment.Moment) {
			return value.endOf('day');
		}
	}
}

import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { AlertModalOptions } from '../../models/alert-modal-options.model';

@Component({
	selector: 'rb-alert-modal',
	templateUrl: './alert-modal.component.html',
	styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {
	@HostBinding('class') class = 'rb-alert-modal';

	@Input() options: AlertModalOptions;
	constructor() {}

	ngOnInit() {}

	onPrimaryClick() {
		this.options.primaryBtnEvent();
	}

	onSecondaryClick() {
		this.options.secondaryBtnEvent();
	}

	onCrossBtnClick() {
		this.options.crossBtnEvent();
	}
}

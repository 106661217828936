<div class="checkbox-table-filter">
	<div *ngFor="let choice of choices; let i = index;" class="choice">
		<mat-checkbox [(ngModel)]="selections[i]" (change)="choiceSelectChanged(i, $event.checked)" color="primary">
			{{choice.text}}
		</mat-checkbox>
	</div>

	<div class="none" *ngIf="choices && choices.length > 0">
		<button mat-button color="primary" type="button" (click)="clearSelections()">{{'STRINGS.CLEAR' | translate}}</button>
	</div>
</div>

import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { AppDateAdapter } from '../../../../shared-ui/custom/date-adapter';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { Observable } from 'rxjs';
import { RbConstants } from '../../../../common/constants/_rb.constants';
import { ReportPdfInfo } from '../pdf/report-pdf-info';

@Injectable()
export class ReportCoverPageService {

	constructor(private appDateAdapter: AppDateAdapter,
				private authManager: AuthManagerService,
				private componentFactoryResolver: ComponentFactoryResolver,
				private appRef: ApplicationRef,
				private injector: Injector) { }

	sleep = m => new Promise(r => setTimeout(r, m));

	async appendComponentToBody(component: any, model: any) {
		// 1. Create a component reference from the component
		const componentRef = this.componentFactoryResolver
			.resolveComponentFactory(component)
			.create(this.injector);

		Object.assign(componentRef.instance, model || {});

		// 2. Attach component to the appRef so that it's inside the ng component tree
		this.appRef.attachView(componentRef.hostView);

		// 3. Get DOM element from component
		const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
			.rootNodes[0] as HTMLElement;

		// 4. Append DOM element to the body
		document.body.appendChild(domElem);

		// 5. Wait some time and remove it from the component tree and from the DOM
		await this.sleep(100);
		this.appRef.detachView(componentRef.hostView);
		componentRef.destroy();
		return domElem;
	}

	createCoverPage(
		pdfInfo: ReportPdfInfo,
		reportTitle: string,
		date: string,
		companyName: string,
		contentSections: string[]
	): Observable<ReportPdfInfo> {
		return new Observable(observer => {
			// Image loading with Promise
			this.loadImage('assets/rblogo_color.png')
				.then(img => {
					// Once the image is loaded, continue with the rest of the PDF creation
					this.addHeaderToPdf(pdfInfo, img);
					this.addTextToPdf(pdfInfo, companyName, reportTitle, contentSections, date);

					observer.next(pdfInfo);
					observer.complete();
				})
				.catch(error => {
					console.error('Error loading image', error);
					observer.error(error);
				});
		});
	}

	private loadImage(src: string): Promise<HTMLImageElement> {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onerror = () => reject(new Error(`Failed to load image at ${src}`));
			img.src = src;
			resolve(img)
		});
	}

	private addHeaderToPdf(pdfInfo: ReportPdfInfo, img: HTMLImageElement) {
		// User name and generated date header
		pdfInfo.pdf.setFont(pdfInfo.getFont, 'normal');
		// pdfInfo.pdf.setFontStyle('normal');
		pdfInfo.pdf.setFontSize(pdfInfo.dim.smallFontSize);
		pdfInfo.pdf.text(this.authManager.getUserProfile().name, pdfInfo.dim.horizontalMargin, pdfInfo.dim.verticalMargin);
		pdfInfo.outputRightAlignedText(
			pdfInfo.pageWidth - pdfInfo.dim.horizontalMargin,
			pdfInfo.outputBottom,
			this.appDateAdapter.format(new Date()));
		pdfInfo.outputBottom += pdfInfo.lineHeight;
		pdfInfo.outputRightAlignedText(
			pdfInfo.pageWidth - pdfInfo.dim.horizontalMargin,
			pdfInfo.outputBottom,
			this.appDateAdapter.formatTime(new Date()));
		pdfInfo.outputBottom += pdfInfo.lineHeight * 3;
		const logoWidth = 280;
		const logoHeight = 53;
		const sectionBounds = { left: pdfInfo.center - logoWidth / 2, top: pdfInfo.outputBottom, right: 0, bottom: 0 };
		pdfInfo.pdf.addImage(img, 'PNG', sectionBounds.left, sectionBounds.top, logoWidth, logoHeight);
		pdfInfo.outputBottom += logoHeight + 40;  // Update outputBottom for subsequent content
	}

	private addTextToPdf(pdfInfo: ReportPdfInfo, companyName: string, reportTitle: string, contentSections: string[], date: string) {
		// Company name
		pdfInfo.pdf.setFont(pdfInfo.getFont, 'bold');
		pdfInfo.pdf.setFontSize(pdfInfo.dim.largeFontSize);
		pdfInfo.outputBottom += 40;
		pdfInfo.pdf.text(companyName, pdfInfo.center, pdfInfo.outputBottom, { align: 'center', baseline: 'top' });

		// Title
		pdfInfo.pdf.setFontSize(pdfInfo.dim.veryLargeFontSize);
		let blockHeight = pdfInfo.outputTextBlock(pdfInfo.dim.horizontalMargin, pdfInfo.pageHeight / 2, pdfInfo.bodyWidth, reportTitle,
			RbConstants.Form.REPORT_PAGE.primary, '#FFFFFF');

		// List of sections
		pdfInfo.outputBottom = pdfInfo.pageHeight / 2 + blockHeight + pdfInfo.lineHeight;
		pdfInfo.pdf.setFont(pdfInfo.getFont, 'normal');
		pdfInfo.pdf.setFontSize(pdfInfo.dim.regularFontSize);
		blockHeight = pdfInfo.outputTextBlock(pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom, pdfInfo.bodyWidth, contentSections.join(', '),
			'#93c08d', '#000000');
		pdfInfo.outputBottom += blockHeight + pdfInfo.lineHeight * 3;

		// Date
		if (date != null) {
			pdfInfo.pdf.setFontSize(pdfInfo.dim.largeFontSize);
			pdfInfo.pdf.text(date, pdfInfo.center, pdfInfo.outputBottom, { align: 'center', baseline: 'top' });
		}
	}

}

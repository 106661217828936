<div class="common-layers-container" [class.is-busy]="busy">
	<div class="header">
		<div class="title-search">
			<h3 class="title">{{ 'STRINGS.COMMON_LAYERS' | translate }}</h3>
		</div>
	</div>
	<div class="body">
		<div class="layer-group common-layers-list">
			<ng-container *ngFor="let option of layerOptions">
				<div *ngIf="option.visible" class="common-layer-option"
					 (keydown.tab)="$event.stopPropagation()">

					<div class="expand-button" *ngIf="option.submenu && option.selected"
						(click)="option.submenuOpen = !option.submenuOpen"
						[class.open]="option.submenuOpen">
						<i class="mdi mdi-chevron-right"></i>
					</div>

					<span class="mr-auto">{{option.text}}</span>

					<mat-slide-toggle *ngIf="!option.isColorOption" [(ngModel)]="option.selected" [ngClass]="'map-option-slider-' + option.value"
						[disabled]="option.isDetailOption && detailOptionsDisabled"
						(ngModelChange)="onOptionChanged(option)"></mat-slide-toggle>

					<rb-color-picker *ngIf="option.isColorOption" class="fill-color" [presetColors]="presetTextColors"
						[position]="'top'" [positionOffset]="'-200px'"
						[(ngModel)]="textColor" (ngModelChange)="onTextColorChanged()"></rb-color-picker>
				</div>
				<ng-container *ngIf="option.submenu && option.selected">
					<ng-container *ngFor="let subOption of option.submenu">
						<div *ngIf="subOption.visible" class="common-layer-option sub-option"
							[class.d-none]="!option.submenuOpen" (keydown.tab)="$event.stopPropagation()">
							<span class="mr-auto">{{subOption.text}}</span>
							<mat-icon *ngIf="isAddLayer(subOption.value)" class="add-sticky-note-icon" (click)="onAddStickyNote(subOption)">add_circle_outline</mat-icon>
							<mat-slide-toggle *ngIf="!subOption.isColorOption && !isAddLayer(subOption.value)" [(ngModel)]="subOption.selected" [ngClass]="'map-option-slider-' + subOption.value"
								[disabled]="(subOption.isDetailOption && detailOptionsDisabled) || subOption.disabled"
								(ngModelChange)="onOptionChanged(subOption)"></mat-slide-toggle>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>

		<!-- Download Tiles -->
		<div class="layer-group background-layers-list">
			<div class="menu-divider">Background layers</div>
			<div class="common-layers-list">
				<mat-radio-group class="rb-radio-group" [(ngModel)]="selectedBackground"
					(ngModelChange)="changeBackground($event)">
					<ng-container *ngFor="let option of backgroundOptions">
						<mat-radio-button *ngIf="option.visible" class="common-layer-option" [class.sub-option]="option.suboption"
							(keydown.tab)="$event.stopPropagation()" [value]="option.value" [disabled]="option.disabled">
							<span class="mdi mdi-clock-fast mr1" *ngIf="option.value == 'offline'"></span>
							{{option.text}}
						</mat-radio-button>
					</ng-container>
				</mat-radio-group>
			</div>
		</div>
		<!-- Download Tiles -->
		<!-- RB-13612: IQ4 - Hide the Download Tiles from the Maps feature for IQ4 Cloud users (isCloud) only -->
		<div class="layer-group download-tiles" *ngIf="selectedBackground === 'esri' && isDownloadTilesShown">
			<div class="menu-divider">Offline tiles</div>
			<div class="offline-tiles-menu common-layers-list">
				<button class="download-tiles-button common-layer-option" (click)="onDownloadTilesClick()" [disabled]="hasDownloadedTiles">
					<span>
						<span class="i">{{ 'STRINGS.DOWNLOAD_TILES' | translate }}</span>
					</span>
					<i class="material-icons">cloud_download</i>
				</button>
				<div class="button-container downloading-tiles" *ngIf="mapService.downloadingTiles">
					<div class="bg"></div>
					<small>{{ 'STRINGS.DOWNLOADING_TILES' | translate }}...</small>
					<span>0%</span>
				</div>
				<button class="download-tiles-button common-layer-option" [disabled]="!hasDownloadedTiles" (click)="onRemoveTiles()">
					<span>{{ 'STRINGS.REMOVE_DOWNLOADED_TILES' | translate }}</span>
					<i class="material-icons">delete_forever</i>
				</button>
			</div>
		</div>

		<!-- Export -->
		<div *ngIf="!isGolfSite" class="layer-group export">
			<div class="menu-divider">{{'STRINGS.EXPORT' | translate}}</div>
			<div class="common-layers-list">
				<div class="common-layer-option">
					<span class="mr-auto">{{'STRINGS.VIEW_PDF' | translate}}</span>
					<mat-icon class="fill-color" (click)="onExportPDFClick()">launch</mat-icon>
				</div>
			</div>
		</div>

		<!-- UI Settings -->
		<div class="layer-group ui-settings">
			<div class="menu-divider">UI</div>
			<div class="common-layers-list">
				<div class="common-layer-option">
					<span class="mr-auto">Label color</span>
					<rb-color-picker class="fill-color" [presetColors]="presetTextColors" [position]="'top'"
						[positionOffset]="'-200px'" [(ngModel)]="textColor" (ngModelChange)="onTextColorChanged()"></rb-color-picker>
				</div>
			</div>
		</div>
	</div>
</div>
import { ControllerConnectionStatus, ControllerStatusChange } from "../models/controller-changes.model";
import { filter, Observable, Subject } from "rxjs";

import { BroadcastService } from "../../../common/services/broadcast.service";
import { Injectable } from "@angular/core";
import { ManualControlManagerService } from "../../manual-control/manual-control-manager.service";
import { ManualControlState } from "../../manual-control/models/manual-control-state.model";

@Injectable({
	providedIn: 'root'
})
export class ControllerStatusChangesManager {
	// Controller Status Manager
	private _controllerStatuses: {[controllerId: number]: ControllerStatusChange} = {}
	private controllerStatusChannge$: Subject<ControllerStatusChange> = new Subject<ControllerStatusChange>();
	get controllerStatuses() {
		return this._controllerStatuses;
	}

	constructor(
		private manualControlManager: ManualControlManagerService,
		private broadcastService: BroadcastService
		) {
		this.listenControllerStatusChange();
	}

	listenControllerStatusChangeById$(controllerId: number): Observable<ControllerStatusChange> {
		return this.controllerStatusChannge$.pipe(
			filter(controllerStatusChange => controllerStatusChange.controllerId === controllerId)
		);
	}

	getControllerStatusById(controllerId: number): ControllerStatusChange {
		let status = this.controllerStatuses[controllerId];
		if (!status) {
			const manualState = this.manualControlManager.getManualControlState(controllerId);
			if (manualState) {
				status = {
					controllerId: controllerId,
					status: this.getControllerStatusBasedOnManualControlState(manualState),
				}
			} else {
				status = {
					controllerId: controllerId,
					status: ControllerConnectionStatus.DISCONNECT
				}
			}
		}
		return status;
	}

	private listenControllerStatusChange() {
		this.manualControlManager.manualControlStateChange
			.subscribe((manualControlState: ManualControlState) => {
				this.updateControllerStatus({
					controllerId: manualControlState.controllerId,
					manualControlState: manualControlState
				})
			})
		this.broadcastService.connectionStopped
		.subscribe((controllerId: number) => {
			this.updateControllerStatus({
				controllerId: controllerId,
				isForceStopping: true
			})
		});
	}

	private updateControllerStatus(params: {
			controllerId: number,
			manualControlState?: ManualControlState,
			isForceStopping?: boolean,
			message?: string,
			preventEmiter?: boolean
		}) {
		const {controllerId, manualControlState, isForceStopping, message, preventEmiter} = params;
		let status = ControllerConnectionStatus.DISCONNECT;

		this.listenControllerStatusChangeById$(controllerId); // Init subject for this controller id

		// Handle status logic
		if (isForceStopping) {
			status = ControllerConnectionStatus.DISCONNECT;
		} else if (manualControlState) {
			status = this.getControllerStatusBasedOnManualControlState(manualControlState);
		}

		if (status === this.controllerStatuses[controllerId]?.status) {
			// If the current is not different from new status, do nothing
			return;
		}

		this.controllerStatuses[controllerId] = {
			controllerId: controllerId,
			status: status,
			message: message
		}

		if (!preventEmiter) {
			this.controllerStatusChannge$.next(this.controllerStatuses[controllerId])
		}
	}

	private getControllerStatusBasedOnManualControlState(manualControlState: ManualControlState): ControllerConnectionStatus {
		if (manualControlState.isConnecting.error) {
			return ControllerConnectionStatus.ERROR;
		} else if (manualControlState.isConnecting.isUpdating) {
			return ControllerConnectionStatus.CONNECTING;
		} else {
			return ControllerConnectionStatus.CONNECTED;
		}
	}
	
}

import { Duration } from 'moment';
import { GolfSensorListItem } from '../../sensors/models/golf-sensor-list-item.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SensorResponse } from '../../sensors/models/sensor-response.model';
import { SensorStatusChange } from '../../../api/signalR/sensor-status-change.model';

import ComparisonType = RbEnums.Common.ComparisonType;
import SensorTriggerSymbol = RbEnums.Common.SensorTriggerSymbol;

export class SensorTrigger {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	// =========================================================================================================================================================
	// Public Members
	// =========================================================================================================================================================

	/**
	 * number represents an ordering value for the triggers. For Programmable Sensors, it allows us to present
	 * each sensor trigger in the same order every time. For Rain Watch Sensors, the Pause trigger MUST have
	 * number = 1, while the ShutDown trigger must have number = 2.
	 */
	number: number;

	id: number;
	companyId: number;
	activeHoursMask: string;
	name: string;
	sensorId: number;
	enabled: boolean;
	compareOperator: ComparisonType;
	threshold1: number;
	threshold2: number;
	duration: Duration;
	durationLong: number;
	symbolId: SensorTriggerSymbol;
	sensorResponse: SensorResponse[] = [];

	// =========================================================================================================================================================
	// Static Methods
	// =========================================================================================================================================================

	static createDefault(threshold1: number, threshold2: number, compareOperator: ComparisonType, durationLong: number): SensorTrigger {
		const trigger: SensorTrigger = new SensorTrigger();
		trigger.name = RbUtils.Translate.instant('STRINGS.ACTION1');
		trigger.threshold1 = threshold1;
		trigger.threshold2 = threshold2;
		trigger.durationLong = durationLong;		// The duration is in Ticks, not minutes!
		trigger.enabled = true;
		trigger.symbolId = SensorTriggerSymbol.One;
		trigger.compareOperator = compareOperator;
		return trigger;
	}

	/**
	 * @summary Determine if the trigger condition is active based on the sensor configuration and its last sensor
	 * data value received via SignalR
	 * @param sensor - Sensor | GolfSensorListItem associated with the trigger
	 * @param lastSensorStatus - SensorStatusChange describing the last sensor value received
	 * @returns boolean true if the value and the trigger configuration result in a 'hot' state for the condition;
	 * false otherwise.
	 */
	public isActive(sensor: GolfSensorListItem, lastSensorStatus: SensorStatusChange): boolean {
		return RbUtils.Sensor.comparisonResult(lastSensorStatus.currentValue, lastSensorStatus.currentValueUnits,
			this.compareOperator,
			this.threshold1, this.threshold2);
	}
}

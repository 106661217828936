import { Component, ViewChild } from '@angular/core';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AcCurrentTestOptions } from '../../../../../api/manual-ops/models/ac-current-test-options.model';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MessageBoxInfo } from '../../../../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from '../../../../../common/services/message-box.service';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { SegmentInfoItem } from '../../../../../sections/controllers/manual-ops/manual-ops-diagnostics-tab/models/segment-info-item.model';
import { TranslateService } from '@ngx-translate/core';

import SourceState = RbEnums.Common.SourceState;
import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;
import MessageBoxButtons = RbEnums.Common.MessageBoxButtons;
import DiagnosticsMode = RbEnums.Common.DiagnosticsMode;

@Component({
	selector: 'rb-ivm-test-path-cell-renderer',
	templateUrl: './ivm-test-path-cell-renderer.component.html',
	styleUrls: ['./ivm-test-path-cell-renderer.component.scss']
})
export class IvmTestPathCellRendererComponent implements ICellRendererAngularComp {
	@ViewChild('energizeToggle') energizeToggle: MatSlideToggle;

	segmentInfoItem: SegmentInfoItem;
	isDisabled = false;

	constructor(private broadcastService: BroadcastService,
				private messageBoxService: MessageBoxService,
				private translateService: TranslateService
	) { }

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.segmentInfoItem = params.data;
		this.setDisabled();
	}

	refresh(params: any): boolean {
		this.segmentInfoItem = params.data;
		this.setDisabled();
		return true;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onToggleEnergizePath(event: MatSlideToggleChange) {
		const newState = this.segmentInfoItem.isEnergized ? SourceState.Off : SourceState.On;

		// If we are de-energizing the path, just do it!
		if (newState === SourceState.Off) {
			this.segmentInfoItem.mode = DiagnosticsMode.Off;
			this.broadcastToggleEvent(newState);
			return;
		}

		// If we're energizing the path, make sure the user realizes all irrigation will be disabled.
		const mbi = new MessageBoxInfo(this.translateService.instant('SPECIAL_MSG.ENERGIZE_PATH_PROMPT', { path: this.segmentInfoItem.name }),
			MessageBoxIcon.Question, 'STRINGS.TEST_PATH',
			this.broadcastToggleEvent.bind(this, newState),
			this.cancelToggleEvent.bind(this),
			MessageBoxButtons.YesNo);
		this.messageBoxService.showMessageBox(mbi);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setDisabled() {
		this.isDisabled = this.segmentInfoItem.isAnyPathEnergized && this.segmentInfoItem.index !== this.segmentInfoItem.testPath;
	}

	private broadcastToggleEvent(newState: SourceState) {
		this.segmentInfoItem.mode = newState === SourceState.On ? DiagnosticsMode.Energized : DiagnosticsMode.Off;
		this.broadcastService.toggleEnergizePath.next(
			{controllerId: this.segmentInfoItem.controllerId, options: new AcCurrentTestOptions(this.segmentInfoItem.index, newState)});
	}

	private cancelToggleEvent() {
		this.energizeToggle.toggle();
	}

}

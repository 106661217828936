<mat-form-field>
	<mat-select #matSelect
		[placeholder]="label"
		multiple="true"
		#dataSelect="ngModel"
		[ngModel]="selectedSites"
		[compareWith]="equals"
		[panelClass]="!isMobile ? 'multiselect-dropdown' : 'multiselect-dropdown is-mobile'"
		(selectionChange)="onSelectionChange($event.value)"
		(closed)="onSelectClosed($event)"
		(opened)="onSelectOpened($event)"
		data-qaid="msd-sites-selector"
	>
		<div
			disabled="disabled"
			class="mat-option db mat-select-all-options">
			<ng-container *ngIf="isShowSelectAllAsCheckbox; else actionToolBar">
				<mat-checkbox
				color="primary"
				[checked]="isAllSitesSelected"
				(change)="onSelectAllChange($event)"
				class="select-all checklist-leaf-node"
			>
				{{ 'STRINGS.SELECT_ALL_UPPERCASE' | translate }}
			</mat-checkbox>
			</ng-container>

			<ng-template #actionToolBar>
				<button
					class="fl"
					mat-button
					color="primary"
					type="button"
					(click)="selectAll(sites)"
					data-qaid="msd-select-all-button">
					{{'STRINGS.SELECT_ALL_UPPERCASE' | translate}}
				</button>
				<button
					class="fr"
					mat-button
					color="primary"
					type="button"
					(click)="deselectAll()"
					data-qaid="msd-deselect-all-button">
					{{'STRINGS.CLEAR_UPPERCASE' | translate}}
				</button>
			</ng-template>
		</div>
		<mat-select-trigger class="mat-select-trigger">
			{{selectedSites && selectedSites.length > 0 ? selectedSites[0].name : ''}}
			<span *ngIf="selectedSites && selectedSites.length > 1">
			(+{{selectedSites.length - 1}} more)
		</span>
		</mat-select-trigger>
		<div class="options-list">
			<mat-option
				*ngFor="let item of sites"
				[value]="item"
				attr.data-qaid="msd-site-option-{{item.name | replaceWhitespace: '_' }}"
			>
				{{ item.name }}
			</mat-option>
		</div>
		<div class="mat-option flex">
			<div *ngIf="showSelectionCount" class="pa3 mat-form-field-label fl">{{selectedSites ? selectedSites.length : 0 }} / {{sites?.length}}</div>
			<button
				mat-raised-button
				class="flex-shrink-0 flex-grow-1"
				color="primary"
				type="button"
				(click)="onDoneClick()"
				data-qaid="msd-done-button">
				{{'STRINGS.DONE' | translate}}
			</button>
		</div>
	</mat-select>
</mat-form-field>

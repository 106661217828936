/*
 * NOTE: DO NOT USE THESE CONSTANTS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbConstants Namespace.
 * 		 E.g., RbConstants.AppState.ALERT_LOG_TYPE
 * 		 See: _rb.constants.ts
 */

export namespace XXUseRbConstantsNamespace {

	export abstract class AppState {

		static readonly MIN_WIDTH_FOR_SUB_NAV = 768;

	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div id="map-toolbar-container">
	<div class="map-toolbar-left" [class.is-busy]="busy">
		<div
			class="button-container"
			matTooltip="{{(isGolfSite? 'STRINGS.HOLE_LIST' : 'STRINGS.CONTROLLER_LIST') | translate}}"
			[class.is-busy]="busy"
			(click)="onToggleLeftSide()">
			<i class="mdi mdi-menu" *ngIf="!leftSideVisible"></i>
			<i class="mdi mdi-close" *ngIf="leftSideVisible"></i>
		</div>

		<div *ngIf="showVisibleSelector"
			class="button-container"
			matTooltip="Common Layers"
			[class.is-busy]="busy"
			[matMenuTriggerFor]="layersMenu">
			<i class="mdi mdi-layers-triple-outline" mat-button></i>
		</div>

		<mat-menu #layersMenu="matMenu"
			yPosition="below"
			backdropClass="map-layers-menu-container"
			class="map-layers-menu-max-height"
			(closed)="onMenuClosed()">
			<div class="map-layers-menu">
				<ng-container *ngFor="let option of layerOptions">
					<div *ngIf="option.visible" class="map-layer-option"
						(keydown.tab)="$event.stopPropagation()"
						(click)="$event.stopPropagation()">

						<div class="expand-button" *ngIf="option.submenu && option.selected"
							(click)="option.submenuOpen = !option.submenuOpen"
							[class.open]="option.submenuOpen">
							<i class="mdi mdi-chevron-right"></i>
						</div>

						<span class="mr-auto" [class.has-expand]="option.submenu && option.selected">{{option.text}}</span>

						<mat-slide-toggle *ngIf="!option.isColorOption" [(ngModel)]="option.selected" [ngClass]="'map-option-slider-' + option.value"
							[disabled]="option.isDetailOption && detailOptionsDisabled"
							(ngModelChange)="onOptionChanged(option)"></mat-slide-toggle>

						<rb-color-picker *ngIf="option.isColorOption" class="fill-color" [presetColors]="presetTextColors"
							[position]="'top'" [positionOffset]="'-200px'"
							[(ngModel)]="textColor" (ngModelChange)="onTextColorChanged()"></rb-color-picker>
					</div>
					<ng-container *ngIf="option.submenu && option.selected">
						<ng-container *ngFor="let subOption of option.submenu">
							<div *ngIf="subOption.visible" class="map-layer-option sub-option" (click)="$event.stopPropagation()"
								[class.d-none]="!option.submenuOpen" (keydown.tab)="$event.stopPropagation()">
								<span class="mr-auto">{{subOption.text}}</span>
								<mat-icon *ngIf="isAddLayer(subOption.value)" class="add-sticky-note-icon" (click)="onAddStickyNote(subOption)">add_circle_outline</mat-icon>
								<mat-slide-toggle *ngIf="!subOption.isColorOption && !isAddLayer(subOption.value)" [(ngModel)]="subOption.selected" [ngClass]="'map-option-slider-' + subOption.value"
									[disabled]="(subOption.isDetailOption && detailOptionsDisabled) || subOption.disabled"
									(ngModelChange)="onOptionChanged(subOption)"></mat-slide-toggle>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</mat-menu>
	</div>

	<div class="map-toolbar-right">

		<div class="button-container home-button" (click)="onGoHome()"  [ngClass]="{'disabled-button':!showGoHome,'':showGoHome}" [matTooltip]="'STRINGS.HOME' | translate">
			<i class="home-map-pin"></i>
		</div>

		<!-------------------------------->
		<!-- Map Settings Menu Selector -->
		<!-------------------------------->
		<div *ngIf="showVisibleSelector" class="button-container" [matMenuTriggerFor]="mapSettingsMenu" matTooltip="Map Settings">
			<i class="mdi mdi-dots-vertical" mat-button></i>
		</div>

		<mat-menu #mapSettingsMenu="matMenu" yPosition="below" backdropClass="map-layers-menu-container" (closed)="onMenuClosed()">

			<!-- Background Selector -->
			<div class="menu-divider" (click)="$event.stopPropagation();">Background layers</div>
			<div class="map-layers-menu" (click)="$event.stopPropagation();">
				<mat-radio-group
					class="rb-radio-group"
					[(ngModel)]="selectedBackground"
					(ngModelChange)="changeBackground($event)">
					<ng-container *ngFor="let option of backgroundOptions">
						<mat-radio-button *ngIf="option.visible" class="map-layer-option" [class.sub-option]="option.suboption" (keydown.tab)="$event.stopPropagation()"
							[value]="option.value" [disabled]="option.disabled">
							<span class="mdi mdi-clock-fast mr1" *ngIf="option.value == 'offline'"></span>
							{{option.text}}
						</mat-radio-button>
					</ng-container>
				</mat-radio-group>
			</div>

			<!-- Download Tiles -->
			<!-- RB-13612: IQ4 - Hide the Download Tiles from the Maps feature for IQ4 Cloud users (isCloud) only -->
			<ng-container *ngIf="selectedBackground === 'esri' && isDownloadTilesShown">
				<div class="menu-divider" (click)="$event.stopPropagation();">Offline tiles</div>
				<div class="offline-tiles-menu map-layers-menu" (click)="$event.stopPropagation();">
					<button (click)="onDownloadTilesClick()" [disabled]="hasDownloadedTiles" *ngIf="!mapService.downloadingTiles">
						<span>
							<span class="i">{{ 'STRINGS.DOWNLOAD_TILES' | translate }}</span>
						</span>
						<i class="material-icons">cloud_download</i>
					</button>
					<div class="button-container downloading-tiles" *ngIf="mapService.downloadingTiles">
						<div class="bg"></div>
						<small>{{ 'STRINGS.DOWNLOADING_TILES' | translate }}...</small>
						<span>0%</span>
					</div>
					<button class="downloadTilesButton" [disabled]="!hasDownloadedTiles" (click)="onRemoveTiles()">
						<span>{{ 'STRINGS.REMOVE_DOWNLOADED_TILES' | translate }}</span>
						<i class="material-icons">delete_forever</i>
					</button>
				</div>
			</ng-container>

			<!-- Export -->
			<div *ngIf="!isGolfSite">
				<div class="menu-divider" (click)="$event.stopPropagation();">{{'STRINGS.EXPORT' | translate}}</div>
				<div class="map-layers-menu" (click)="$event.stopPropagation();">
					<div class="map-layer-option" (keydown.tab)="$event.stopPropagation()">
						<span>{{'STRINGS.VIEW_PDF' | translate}}</span>
						<mat-icon class="fill-color" (click)="$event.stopPropagation(); onExportPDFClick()">launch</mat-icon>
					</div>
				</div>
			</div>

			<!-- UI Settings -->
			<div class="menu-divider" (click)="$event.stopPropagation();">UI</div>
			<div class="map-layers-menu" (click)="$event.stopPropagation();">
				<div class="map-layer-option" (keydown.tab)="$event.stopPropagation()">
					<span>Label color</span>
					<rb-color-picker class="fill-color" [presetColors]="presetTextColors"
						[position]="'top'" [positionOffset]="'-200px'"
						[(ngModel)]="textColor" (ngModelChange)="onTextColorChanged()"></rb-color-picker>
				</div>
			</div>
		</mat-menu>
	</div>
</div>


import { BroadcastService } from '../../common/services/broadcast.service';
import { GeoGroup } from './models/geo-group.model';
import { GeoGroupApiService } from './geo-group-api.service';
import { GeoItem } from './models/geo-item.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class GeoGroupManagerService extends ServiceManagerBase {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private geoGroupApiService: GeoGroupApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getGeoGroup(id: number): Observable<GeoGroup> {
		return this.geoGroupApiService.getGeoGroup(id);
	}

	getGeoGroupForStation(siteId: number, stationId: number, bypassCache = false): Observable<GeoGroup> {
		return this.geoGroupApiService.getGeoGroupForStation(siteId, stationId, bypassCache);
	}

	getGeoGroupsForSite(siteId: number): Observable<GeoGroup[]> {
		return this.geoGroupApiService.getGeoGroupsForSite(siteId);
	}

	createGeoItem(siteId: number, areaLevel2Id: number, areaLevel3Id: number, data: any): Observable<GeoItem> {
		return this.geoGroupApiService.createGeoItem(siteId, areaLevel2Id, areaLevel3Id, data);
	}

	createGeoItemForStation(siteId: number, stationId: number, data: any) {
		return this.geoGroupApiService.createGeoItemForStation(siteId, stationId, data);
	}

	updateGeoItem(data: any): Observable<GeoItem> {
		return this.geoGroupApiService.updateGeoItem(data);
	}

	updateStationGeoGroupSettings(siteId: number, stationId: number, settings: string): Observable<any> {
		return this.geoGroupApiService.updateStationGeoGroupSettings(siteId, stationId, settings);
	}

	deleteGeoItem(id: number): Observable<null> {
		return this.geoGroupApiService.deleteGeoItem(id);
	}
}

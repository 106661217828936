import { RbUtils } from '../../../../../common/utils/_rb.utils';

export class ToolbarItemInfo {

	constructor(qaIdString: string,
				label: string,
				icon: string,
				buttonClickFunc: Function,
				isDisabledFunc: Function = null,
				customIconNumber: number  = 0,
				animationType: any = null,
				isAnimatingFunc: Function = null,
				colorFunc: Function = null,
				itemClass: string = null,
				dynamicIconFunc: Function = null,
				dynamicLabelFunc: Function = null,
				ngClassFunc: Function = null,
				badgeCountFunc: Function = null,
				iconContainerClassFunc: Function = null,
				customIconNumberFunc: Function = null
	) {

		this.label = label;
		this.icon = icon;
		this.customIconNumber = customIconNumber;
		this.onButtonClick = buttonClickFunc;
		this.animationType = animationType;
		this.isAnimatingFunc = isAnimatingFunc;
		this.colorFunc = colorFunc;
		this.isDisabledFunc = isDisabledFunc;
		this.itemClass = itemClass;
		this.dynamicIconFunc = dynamicIconFunc;
		this.dynamicLabelFunc = dynamicLabelFunc;
		this.ngClassFunc = ngClassFunc;
		this.badgeCountFunc = badgeCountFunc;
		this.iconContainerClassFunc = iconContainerClassFunc;
		this.customIconNumberFunc = customIconNumberFunc;

		this.qaId = qaIdString;
	}

	private readonly isAnimatingFunc: Function;
	private readonly colorFunc: Function;
	private readonly isDisabledFunc: Function;
	private readonly dynamicIconFunc: Function;
	private readonly dynamicLabelFunc: Function;
	private readonly ngClassFunc: Function;
	private readonly badgeCountFunc: Function;
	private readonly iconContainerClassFunc: Function;
	private readonly customIconNumberFunc: Function;

	readonly label: string;
	readonly icon: string;
	readonly onButtonClick: Function;
	readonly animationType: any;
	readonly itemClass: string;
	readonly customIconNumber: number;

	readonly qaId: string;

	get dynamicCustomIconNumber(): number {
		return this.customIconNumberFunc && this.customIconNumberFunc();
	}

	get isAnimating(): boolean {
		return this.isAnimatingFunc && this.isAnimatingFunc();
	}

	get color(): string {
		return this.colorFunc && this.colorFunc();
	}

	get isDisabled(): boolean {
		return this.isDisabledFunc && this.isDisabledFunc();
	}

	get labelLength(): number {
		return this.label ? RbUtils.Translate.instant(this.label).length : 0;
	}

	get translatedLabel(): string {
		return this.label
			? RbUtils.Translate.instant(this.label)
			: this.dynamicLabel ? RbUtils.Translate.instant(this.dynamicLabel) : '';
	}

	get dynamicIcon(): string {
		return this.dynamicIconFunc && this.dynamicIconFunc();
	}

	get dynamicLabel(): string {
		return this.dynamicLabelFunc && this.dynamicLabelFunc();
	}

	get ngClass(): {} {
		return this.ngClassFunc && this.ngClassFunc();
	}

	get badgeCount(): number {
		return this.badgeCountFunc && this.badgeCountFunc();
	}

	get iconContainerClass(): string {
		return this.iconContainerClassFunc && this.iconContainerClassFunc();
	}
}

import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Area } from '../../../../api/areas/models/area.model';
import { AreaManagerService } from '../../../../api/areas/area-manager.service';
import { FrontBackDropdownComponent } from '../../../../core/components/front-back-dropdown/front-back-dropdown.component';
import { HoleAreaPair } from '../../../../api/sites/models/hole-area-pair.model';
import { SelectHolesDropdownComponent } from '../../../../core/components/select-holes-dropdown/select-holes-dropdown.component';
import { Site } from '../../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../../api/sites/site-manager.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-hole-area-filter',
	templateUrl: './hole-area-filter.component.html',
	styleUrls: ['./hole-area-filter.component.scss'],
})
export class HoleAreaFilterComponent implements OnInit, OnDestroy {

	@HostBinding('class') class = 'rb-main-panel-toolbar hole-area-filter';
	@ViewChild('holesDropdown') holesDropdownComponent: SelectHolesDropdownComponent;
	@ViewChild('areasDropdown') areasDropdownComponent: FrontBackDropdownComponent;
	@Input() isMobile: boolean;
	@Input() selectedCourseId: number;
	@Input() selectedHoles:  Area[] = [];
	@Input() selectedAreas:  Area[] = [];
	@Output() valueChange = new EventEmitter();
	@Output() complete = new EventEmitter();
	areas: Area[] = [];
	busy = false;
	course: Site;
	courses: Site[] = [];
	courseAreas: Area[] = [];
	holes: Area[] = [];
	places: Area[] = [];
	holesAndPlaces: Area[] = [];
	loadError: string;
	currentlySelectedHoleAreaPairs = Array<HoleAreaPair>();
	assignSelected = false;
	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(private areaManager: AreaManagerService,
				private siteManager: SiteManagerService,
				private translate: TranslateService) { }

	ngOnInit(): void {
		this.loadCourses();
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	get selectedHolesAndAreasIds(): number[] {
		if (this.holesDropdownComponent == null || this.areasDropdownComponent == null) return [];
		// return this.holesDropdownComponent.selectedItems.map(h => h.id).concat(this.areasDropdown.selectedItems.map(a => a.id));
		return this.holesDropdownComponent.selectedHoles.map(h => h.id).concat(this.areasDropdownComponent.selectedItems.map(a => a.id));
	}
	// =========================================================================================================================================================
	// Data Loading
	// =========================================================================================================================================================

	private loadCourses() {
		this.busy = true;
		this.siteManager.getSites(true).pipe(take(1)).subscribe(courses => {
			this.courses = courses || [];
			if (this.ShouldSelectFirstCourse()) {
				this.SelectFirstCourse();
			}
			this.busy = false;
		}, error => {
			this.loadError = error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
		});
	}

	private ShouldSelectFirstCourse(): boolean {
		return this.courses.length > 0;
	}

	private SelectFirstCourse(): void {
		if (!this.selectedCourseId)
			this.selectedCourseId = this.courses[0].id;
		else
			this.assignSelected = true;
		this.onCourseSelected();
	}

	private loadAreas(): Observable<Area[]> {
		return (this.areas.length > 0) ? of(this.areas) : this.areaManager.getAllAreas().pipe(take(1));
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================
	onActionErrorAcknowledged() {
		this.loadError = null;
		this.busy = false;
	}

	onCourseSelected() {
		this.busy = true;
		this.course = this.courses.find(c => c.id === this.selectedCourseId);
		if (this.holesDropdownComponent != null) this.holesDropdownComponent.selectedHoles = [];
		if (this.areasDropdownComponent != null) this.areasDropdownComponent.selectedItems = [];

		forkJoin([
			this.loadAreas(),
			this.areaManager.getHoles(this.selectedCourseId).pipe(take(1)),
		]).subscribe(([areas,  holes]) => {
			this.areas = areas;
			this.courseAreas = areas.filter(area => area.siteId === this.selectedCourseId && area.level === 3).sort((a, b) => a.number - b.number);
			this.holes = holes.filter(h => h.isGolfArea && h.level === 2).sort((a, b) => a.number - b.number);
			this.places = holes.filter(h => !h.isGolfArea).sort((a, b) => a.number - b.number);
			this.holesAndPlaces = this.holes.concat(this.places);
			this.busy = false;
			if (this.assignSelected) {
				this.holesDropdownComponent.selectedHoles = this.selectedHoles;
				this.areasDropdownComponent.selectedItems = this.selectedAreas;
			}
			this.valueChange.emit( {selectedCourseId: this.selectedCourseId});
			this.updateSelectedHoleAreaPairs();
		}, error => {
			this.loadError = error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
		});
	}
	onSelectedHolesChange(event) {
		this.selectedHoles =  event;
		this.valueChange.emit( {selectedHoles: this.selectedHoles});
		this.updateSelectedHoleAreaPairs();
	}
	onSelectedAreasChange(event) {
		this.selectedAreas =  event;
		this.valueChange.emit( {selectedAreas: this.selectedAreas});
		this.updateSelectedHoleAreaPairs();
	}
	updateSelectedHoleAreaPairs() {
		this.currentlySelectedHoleAreaPairs = [];
		if (this.selectedCourseId) {
			let holes =  Array<Area> ();
			let areas =  Array<Area> ();
			if (this.selectedHoles && this.selectedHoles.length > 0 && this.selectedAreas && this.selectedAreas.length > 0) {
				holes = this.selectedHoles;
				areas = this.selectedAreas;
			} else if (this.selectedHoles && this.selectedHoles.length > 0 && (!this.selectedAreas || this.selectedAreas.length < 1)) {
				holes = this.selectedHoles;
				areas = this.courseAreas;
			} else if ( (!this.selectedHoles || this.selectedHoles.length < 1) && (this.selectedAreas && this.selectedAreas.length > 0)) {
				holes = this.holes;
				areas = this.selectedAreas;
			} else if ( (!this.selectedHoles || this.selectedHoles.length < 1) && (!this.selectedAreas || this.selectedAreas.length < 1)) {
				holes = this.holes;
				areas = this.courseAreas;
			}
			holes.forEach( hole => {
				areas.forEach( area => {
					this.currentlySelectedHoleAreaPairs.push(new HoleAreaPair(hole.id, area.id));
				});
			});
		}
		this.valueChange.emit( {selectedHoleAreaPairs: this.currentlySelectedHoleAreaPairs});
	}
	onCancel() {
		this.complete.emit(null);
	}

	onSubmit() {
		this.complete.emit(this.currentlySelectedHoleAreaPairs);
	}

}

import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectListItem } from '../_common/models/select-list-item.model';
import { SensorTrigger } from './models/sensor-trigger.model';

@Injectable({
	providedIn: 'root'
})
export class SensorTriggerApiService extends ApiService {
	clearCache() {
		super.clearCache(this.getSensorTriggersUrl);
		super.clearCache(this.getTriggerComparisonTypesUrl);
	}

	// =========================================================================================================================================================
	// API Calls
	// =========================================================================================================================================================

	createSensorTrigger(sensorTrigger: any): Observable<SensorTrigger> {
		return this.apiRequest<any>(this.createSensorTriggerUrl, HttpMethod.Post, sensorTrigger);
	}

	deleteSensorTriggers(SensorTriggerIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.deleteSensorTriggersUrl, HttpMethod.Delete, SensorTriggerIds);
	}

	getSensorTrigger(id: number): Observable<SensorTrigger> {
		return this.apiRequest<any>(this.getSensorTriggerUrl(id), HttpMethod.Get)
			.pipe(map(trigger => new SensorTrigger(trigger)));
	}

	getSensorTriggers(bypassCache = false): Observable<ApiCachedRequestResponse<SensorTrigger[]>> {
		return this.apiRequestWithCache<any>(this.getSensorTriggersUrl, bypassCache, result => result.map(c => new SensorTrigger(c)));
	}

	getTriggerComparisonTypes(bypassCache = false): Observable<ApiCachedRequestResponse<SelectListItem[]>> {
		return this.apiRequestWithCache<any>(this.getTriggerComparisonTypesUrl, bypassCache, result => result.map(c => new SelectListItem(c)));
	}

	update(SensorTriggerId: number, updateValues: any): Observable<null> {
		return this.apiRequest<any>(this.updateUrl(SensorTriggerId), HttpMethod.Patch, this.patchTransform(updateValues));
	}

	updateSensorTrigger(sensorTrigger: SensorTrigger): Observable<void> {
		return this.apiRequest<any>(this.updateSensorTriggerUrl, HttpMethod.Put, sensorTrigger);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}SensorTrigger`; }

	private get createSensorTriggerUrl(): string { return `${this.baseUrl}/CreateSensorTrigger`; }

	private get deleteSensorTriggersUrl(): string { return `${this.baseUrl}/DeleteSensorTriggers`; }

	private getSensorTriggerUrl(id: number): string { return `${this.baseUrl}/GetSensorTrigger?sensorTriggerId=${id}`; }

	private get getSensorTriggersUrl(): string { return `${this.baseUrl}/GetSensorTriggers`; }

	private get getTriggerComparisonTypesUrl(): string { return `${this.baseUrl}/GetTriggerComparisonTypes`; }

	private updateUrl(id: number): string { return `${this.baseUrl}/Update?sensorTriggerId=${id}`; }

	private get updateSensorTriggerUrl(): string { return `${this.baseUrl}/UpdateSensorTrigger`; }

}

import { ChangeBase } from './change-base.model';

export class ScheduledReportChange extends ChangeBase {

	constructor(json: any = null) {

		super(json);

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here
		}
	}

	scheduledReportId : number;
	reasonCode: number;
	changeType: string;
	itemsChanged: any

}
<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>
<div class="renewal-background" [class.expired]="renewalState === EntitlementsRenewalState.Expired" [class.expiring]="renewalState === EntitlementsRenewalState.Expiring">

	<div class="icon-space">
		<mat-icon *ngIf="renewalState === EntitlementsRenewalState.Expired" [inline]="true">error_outline</mat-icon>
		<mat-icon *ngIf="renewalState === EntitlementsRenewalState.Expiring" [inline]="true"><i class="icon-warning"></i></mat-icon>
	</div>

	<div class="text-space">
		<div>{{message}}</div>
		<mat-label class="hyperlink">
			<span *ngIf="renewalState === EntitlementsRenewalState.Expired" (click)="onManageSubscription()">{{'SUBSCRIPTIONS.PROFEATURES.RENEW_NOW' | translate}}</span>
			<span *ngIf="renewalState === EntitlementsRenewalState.Expiring" (click)="onManageSubscription()">{{'SUBSCRIPTIONS.PROFEATURES.MANAGE_SUBSCRIPTION' | translate}}</span>
			<span *ngIf="renewalState === EntitlementsRenewalState.Expiring && !deviceManager.isMobile" (click)="onDoNotShowAgain()">{{'SUBSCRIPTIONS.PROFEATURES.DO_NOT_SHOW_AGAIN' | translate}}</span>
		</mat-label>
	</div>
</div>

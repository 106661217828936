import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { Site } from '../../../api/sites/models/site.model';

@UntilDestroy()
@Component({
	selector: 'rb-multi-select-dropdown',
	templateUrl: './multi-select-dropdown.component.html',
	styleUrls: ['./multi-select-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class MultiSelectDropdownComponent implements OnDestroy {
	@ViewChild('matSelect', { static: true }) matSelect: MatSelect;

	@Input() label: string;
	@Input() selectedSites: Array<Site>;
	@Input() showSelectionCount = false;
	@Input() sites: Array<Site>;
	@Input() isShowSelectAllAsCheckbox = false;

	@Output() siteSelectionComplete = new EventEmitter<number[]>();

	doneFired = false;
	isMobile = false;

	constructor (private deviceManager: DeviceManagerService
	) {
		// Get current mobile state and then subscribe to changes. Needed to hide/show navigation elements.
		this.isMobile = this.deviceManager.isMobile;
		this.deviceManager.isMobileChange.pipe(untilDestroyed(this)).subscribe((isMobile: boolean) => this.isMobile = isMobile);
	}

	ngOnDestroy() {
		/** Required by untilDestroyed */
	}

	equals(objOne, objTwo) {
		if (objOne && objTwo) {
			return objOne.id === objTwo.id;
		}
	}

	selectAll(sites: Site[]) {
		this.selectedSites = sites;
	}

	deselectAll() {
		this.selectedSites = [];
	}

	onSelectionChange(selectedSites: Site[]) {
		this.selectedSites = selectedSites;
	}

	onSelectOpened(event) {
		// Just clear the user-pressed-Done flag.
		this.doneFired = false;
	}

	onSelectAllChange($event: MatCheckboxChange) {
		if ($event.checked) {
			this.selectAll(this.sites);
			return;
		}

		this.deselectAll();
	}

	/**
	 * Check if all sites have been select yet
	 * @return {Boolean}
	 */
	get isAllSitesSelected() {
		return this.sites
			&& this.sites.length > 0
			&& this.selectedSites
			&& this.sites.length === this.selectedSites.length;
	}

	/**
	 * @summary Fired when the user closes the dropdown. Note that this will be fired whether she clicks Done
	 * or just clicks outside the dropdown. Because the selection is updated whether the Done button is clicked
	 * or not, we should emit on clicked-outside, too.
	 * @param event closed event.
	 */
	onSelectClosed(event) {
		if (!this.doneFired) {
			this.siteSelectionComplete.emit((<Array<any>>this.matSelect.selected).map(option => option.value.id));
		}
	}

	onDoneClick() {
		this.doneFired = true;
		this.siteSelectionComplete.emit((<Array<any>>this.matSelect.selected).map(option => option.value.id));
		this.matSelect.close();
	}

}

import { ApiService, HttpMethod } from '../_common/api.service';
import { Observable, of } from 'rxjs';
import { AcCurrentTestOptions } from './models/ac-current-test-options.model';
import { AdvanceStation } from '../stations/models/advance-station.model';
import { AppInjector } from '../../core/core.module';
import { ControllerFirmwareVersions } from './models/controller-firmware-versions.model';
import { Injectable } from '@angular/core';
import { IStationDiagnosticHistory } from '../diagnostic-log/models/station-diagnostic';
import { LxdPingDecoderItem } from './models/lxd-ping-decoder-item.model';
import { LxIvmPingRequestItem } from './models/lx-ivm-ping-request-item.model';
import { map } from 'rxjs/operators';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ScheduledDiagnostic } from './models/scheduled-diagnostic.model';
import { ScheduledDiagnostics } from './models/scheduled-diagnostics.model';
import { SignalRService } from '../signalR/signalr.service';
import { StartStationModel } from './models/start-station.model';
import { Station } from '../stations/models/station.model';

@Injectable({
	providedIn: 'root'
})
export class ManualOpsApiService extends ApiService {

	diagnosticsCancelled = true;

	// =========================================================================================================================================================
	// API Calls - Public
	// =========================================================================================================================================================

	advanceAreas(areaIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.advanceAreasUrl, HttpMethod.Post, areaIds);
	}

	advanceProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.advanceProgramGroupsUrl, HttpMethod.Post, programGroupIds);
	}

	advancePrograms(programIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.advanceProgramsUrl, HttpMethod.Post, programIds);
	}

	advanceStations(advanceStations: AdvanceStation[], isProgramIndex: boolean): Observable<void> {
		return this.apiRequest<any>(this.advanceStationsUrl(isProgramIndex), HttpMethod.Post, advanceStations);
	}

	clearFlowLogs(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.clearFlowLogsUrl(controllerId), HttpMethod.Post);
	}

	closeMasterValves(controllerId: number, closeValves: boolean): Observable<void> {
		return this.apiRequest<any>(this.closeMasterValvesUrl(controllerId, closeValves), HttpMethod.Post);
	}

	connect(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.connectUrl(controllerId), HttpMethod.Post);
	}

	disconnect(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.disconnectUrl(controllerId), HttpMethod.Post);
	}

	enableFloManager(controllerId: number, isFloManagerEnabled: boolean, isFloWatchEnabled: boolean): Observable<void> {
		return this.apiRequest<any>(this.enableFlowManagerUrl(controllerId, isFloManagerEnabled, isFloWatchEnabled), HttpMethod.Post);
	}

	getRasterTest(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.rasterTestUrl(controllerId), HttpMethod.Post);
	}

	getSatelliteFirmwareVersions(controllerId: number): Observable<ControllerFirmwareVersions> {
		return this.apiRequest<any>(this.getSatelliteFirmwareVersionsUrl(controllerId), HttpMethod.Get)
			.pipe(map(response => new ControllerFirmwareVersions(response)));
	}

	initiateLearnedFlow(controllerId: number, stationIds: number[], startTime?: string): Observable<void> {
		return this.apiRequest<any>(this.getInitiateLearnedFlowUrl(controllerId, startTime), HttpMethod.Post, stationIds);
	}

	/** Initiates a service request to get Diagnostics Segement Info for LXIVM series controllers. The result will be returned via SignalR
	 * 'DiagnosticsSegmentInfoChanged' */
	requestDiagnosticsSegmentInfo(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getDiagnosticsSegmentInfoUrl(controllerId), HttpMethod.Post);
	}

	/** Initiates a service request to get Weather Sensor Status. The result will be returned via SignalR 'WeatherSensorStatusChanged' */
	request2WireData(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.get2WireDataUrl(controllerId), HttpMethod.Post);
	}

	requestMasterValveStatus(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getMasterValveStatusUrl(controllerId), HttpMethod.Post);
	}

	requestShortReport(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getShortReportUrl(controllerId), HttpMethod.Post);
	}
	cancelFirmwareUpdate(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getCancelFirmwareUpdateUrl(controllerId), HttpMethod.Post);
	}
	/** Initiates a service request to get 2 Wire Device Paths for LXIVM series controllers. The result will be returned via SignalR
	 * 'Universal2WireDevicePathsChanged' */
	requestUniversal2WireDevicePaths(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getUniversal2WireDevicePathsUrl(controllerId), HttpMethod.Post);
	}

	/** Initiates a service request to get 2 Wire data for LXIVM series controllers. The result will be returned via SignalR 'UniversalTwoWireDataChanged' */
	requestUniversal2WireLineSurvey(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getUniversal2WireLineSurveyUrl(controllerId), HttpMethod.Post);
	}

	/** Initiates a service request to get Device Response Statuses for LXIVM series controllers. The result will be returned via SignalR
	 * 'UniversalDeviceResponseStatusesChanged' */
	requestUniversalDeviceResponseStatuses(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getUniversalDeviceResponseStatusesUrl(controllerId), HttpMethod.Post);
	}

	/** Initiates a service request to get Weather Sensor Status. The result will be returned via SignalR 'WeatherSensorStatusChanged' */
	requestWeatherSensorStatus(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getWeatherSensorStatusUrl(controllerId), HttpMethod.Post);
	}

	overrideDial(controllerId: number, dialPosition: RbEnums.Common.LogicalDialPos): Observable<void> {
		return this.apiRequest<any>(this.overrideDialUrl(controllerId, dialPosition), HttpMethod.Post);
	}

	overrideSensor(controllerId: number, switchPosition: RbEnums.Common.SensorSwitchPosition): Observable<void> {
		return this.apiRequest<any>(this.overrideSensorUrl(controllerId, switchPosition), HttpMethod.Post);
	}

	overrideTwoWirePath(controllerId: number, twoWirePathPosition: RbEnums.Common.TwoWirePath): Observable<void> {
		return this.apiRequest<any>(this.overrideTwoWirePathUrl(controllerId, twoWirePathPosition), HttpMethod.Post);
	}

	pauseProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.pauseProgramGroupsUrl, HttpMethod.Post, programGroupIds);
	}

	pausePrograms(programIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.pauseProgramsUrl, HttpMethod.Post, programIds);
	}

	pauseStations(stationIds: number[]) {
		return this.apiRequest<any>(this.pauseStationsUrl, HttpMethod.Post, stationIds);
	}

	pingDecoder(controllerId: number, items: LxdPingDecoderItem[]): Observable<void> {
		return this.apiRequest<any>(this.getPingDecodersUrl(controllerId), HttpMethod.Post, items);
	}

	pingUniversal(controllerId: number, items: LxIvmPingRequestItem[]): Observable<void> {
		return this.apiRequest<any>(this.getPingUniversalUrl(controllerId), HttpMethod.Post, items);
	}

	pollSensors(sensorIds: Array<number>) {
		// No direct result. When the call completes, the poll request has been sent to the engine.
		return this.apiRequest<any>(this.pollSensorsUrl, HttpMethod.Post, sensorIds);
	}

	renumberStationAddresses(controllerId: number, groupNumber: number): Observable<void> {
		return this.apiRequest<any>(this.renumberStationAddressesUrl(controllerId, groupNumber), HttpMethod.Post);
	}

	resetSensors(sensors: Array<number>) {
		return this.apiRequest<any>(this.resetSensorsUrl, HttpMethod.Post, sensors);
	}

	resumeProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.resumeProgramGroupsUrl, HttpMethod.Post, programGroupIds);
	}

	resumePrograms(programIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.resumeProgramsUrl, HttpMethod.Post, programIds);
	}

	resumeStations(stationIds: number[]) {
		return this.apiRequest<any>(this.resumeStationsUrl, HttpMethod.Post, stationIds);
	}

	retrieveEventLogs(controllerIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.retrieveEventLogsUrl, HttpMethod.Post, controllerIds);
	}

	runTestProgram(controllerId: number, duration: number): Observable<void> {
		return this.apiRequest<any>(this.getRunTestUrl(controllerId, duration), HttpMethod.Post);
	}

	SendIrrigationCommand(commandType: RbEnums.Common.IrrigationEngineCommandType): Observable<void> {
		return this.apiRequest<any>(this.sendIrrigationCommandUrl(commandType), HttpMethod.Post);
	}

	sendRainDelay(controllerId: number, days: number): Observable<void> {
		return this.apiRequest<any>(this.sendRainDelayUrl(controllerId, days), HttpMethod.Post);
	}

	setDiagnosticsTwoWireAcCurrentTest(controllerId: number, options: AcCurrentTestOptions): Observable<void> {
		return this.apiRequest<any>(this.setDiagnosticsTwoWireAcCurrentTestUrl(controllerId), HttpMethod.Post, options);
	}

	setWaterWindow(controllerId: number, seconds: number): Observable<void> {
		return this.apiRequest<any>(this.setWaterWindowUrl(controllerId, seconds), HttpMethod.Post);
	}

	simulateRainCanPulse(sensors: Array<number>, pulseValue?: number) {
		return this.apiRequest<any>(this.getSimulateRainCanPulseUrl(pulseValue), HttpMethod.Post, sensors);
	}

	simulateFREEDOMCommand(freedomCommand: string): Observable<any> {
		// We have to pass the string in the body as a quoted string, not just the string itsef. See CoreApi for details.
		// This allows the string to be parsed by the JSON parser.
		return this.apiRequest<any>(this.getSimulateFREEDOMCommandUrl(), HttpMethod.Post, 
			`"${freedomCommand}"`); 
	}

	enableShortFindMode(controllerId: number, enableShortFindMode: boolean): Observable<void> {
		return this.apiRequest<any>(this.enableShortFindModeUrl(controllerId, enableShortFindMode), HttpMethod.Post);
	}

	startLxdDecoderTest(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getStartLxdDecoderTestUrl(controllerId), HttpMethod.Post);
	}

	startProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.startProgramGroupsUrl, HttpMethod.Post, programGroupIds);
	}

	startPrograms(programIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.startProgramsUrl, HttpMethod.Post, programIds);
	}

	startStations(startStationModel: StartStationModel) {
		return this.apiRequest<any>(this.startStationsUrl, HttpMethod.Post, startStationModel);
	}

	stopProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.stopProgramGroupsUrl, HttpMethod.Post, programGroupIds);
	}

	stopPrograms(programIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.stopProgramsUrl, HttpMethod.Post, programIds);
	}

	stopStations(stationIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.stopStationsUrl, HttpMethod.Post, stationIds);
	}

	updateFirmware(controllerId: number, filePath: string, verifySatellite = true): Observable<void> {
		return this.apiRequest<any>(this.updateFirmwareUrl(controllerId, filePath, verifySatellite), HttpMethod.Post);
	}

	resetNccModem(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.resetNccModemUrl(controllerId), HttpMethod.Post);
	}

	unlockSystem(): Observable<void> {
		return this.apiRequest<any>(this.unlockAllUrl, HttpMethod.Post);
	}

	// =========================================================================================================================================================
	// Mock running
	// =========================================================================================================================================================

	startGolfStationsMock(startStationModel: StartStationModel) {
		const signalRService = AppInjector.get(SignalRService);
		signalRService.startGolfStationsMock(startStationModel);
	}

	pauseGolfStationsMock(stationIds: number[]) {
		const signalRService = AppInjector.get(SignalRService);
		signalRService.pauseGolfStationsMock(stationIds);
	}

	resumeGolfStationsMock(stationIds: number[]) {
		const signalRService = AppInjector.get(SignalRService);
		signalRService.resumeGolfStationsMock(stationIds);
	}

	stopGolfStationsMock(stationIds: number[]) {
		const signalRService = AppInjector.get(SignalRService);
		signalRService.stopGolfStationsMock(stationIds);
	}

	advanceGolfStationsMock(stationids: number[]) {
		const signalRService = AppInjector.get(SignalRService);
		signalRService.advanceGolfStationsMock(stationids);
	}

	// =========================================================================================================================================================
	// Diagnostic tests
	// =========================================================================================================================================================

	cancelDiagnostics(): Observable<void> {
		this.diagnosticsCancelled = true;
		return this.apiRequest<any>(this.cancelDiagnosticsUrl, HttpMethod.Post);
	}

	checkInterfaceConnection(): Observable<void> {
		return this.apiRequest<any>(this.checkInterfaceConnectionUrl, HttpMethod.Post);
	}

	runDiagnosticsStatusCheckByStation(stationId: number): Observable<IStationDiagnosticHistory[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsStatusCheckByStationUrl(stationId), HttpMethod.Post);
	}

	runDiagnosticsVoltageCheckByStation(stationId: number): Observable<IStationDiagnosticHistory[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsVoltageCheckByStationUrl(stationId), HttpMethod.Post);
	}

	runDiagnosticsStatusCheckByStationList(stationIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsStatusCheckByStationListUrl(), HttpMethod.Post, stationIds)
				.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsVoltageCheckByStationList(stationIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsVoltageCheckByStationListUrl(), HttpMethod.Post, stationIds)
				.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsStatusPollByAddress(courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsSystemPollBySatelliteWirePathsUrl(interfaceId, wirePathIds), HttpMethod.Post)
				.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsStatusPollByArea(courseId: number, holeAndAreaIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsSystemPollByAreaUrl(holeAndAreaIds), HttpMethod.Post)
				.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsStatusPollBySite(courseId: number): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsSystemPollBySiteUrl(courseId), HttpMethod.Post)
				.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsVoltageByAddress(courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsVoltagesBySatelliteWirePathsUrl(interfaceId, wirePathIds), HttpMethod.Post)
				.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsVoltageByArea(courseId: number, holeAndAreaIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsVoltagesByAreaUrl(holeAndAreaIds), HttpMethod.Post)
				.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsVoltageBySite(courseId: number): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsVoltagesBySiteUrl(courseId), HttpMethod.Post)
				.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsQuickCheckByStation(stationId: number, runTimeSeconds: number, onBeforeOff: boolean): Observable<IStationDiagnosticHistory[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsQuickCheckByStationUrl(runTimeSeconds, onBeforeOff), HttpMethod.Post, [stationId]);
	}

	runDiagnosticsQuickCheckByAddress(courseId: number, interfaceId: number, wirePathIds: number[], runTimeSeconds: number): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsQuickCheckBySatelliteWirePathsUrl(
					interfaceId,
					wirePathIds,
					runTimeSeconds),
					HttpMethod.Post)
					.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsQuickCheckByArea(courseId: number, holeAndAreaIds: number[], runTimeSeconds: number): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsQuickCheckByAreaUrl(holeAndAreaIds, runTimeSeconds), HttpMethod.Post)
				.pipe(map(list => list.map(r => Number(r))));
	}

	runDiagnosticsQuickCheckBySite(courseId: number, runTimeSeconds: number): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
				.apiRequest<any>(this.runDiagnosticsQuickCheckBySiteUrl(courseId, runTimeSeconds), HttpMethod.Post)
				.pipe(map(list => list.map(r => Number(r))));
	}
	
	/**
	 * Run a decoder On/Off diagnostic test, limiting the stations selected for the diagnostic to those
	 * specified. NOTE: onBeforeOff flag expected by the ManualOpsController will always be defaulted;
	 * we do not pass a value for it.
	 * @param stationIds - list of station Id values to be tested.
	 * @returns Observable(number[]) returning the list of station IDs selected for the diagnostic. Note that
	 * if some of the passed stationIds are *not* decoder stations, are suspended, etc., the result may not 
	 * equal the input parameter.
	 */
	 runDecoderOnOff(stationIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
			.apiRequest<any>(this.runDecoderOnOffUrl, HttpMethod.Post, stationIds)
				.pipe(map(list => list.map(r => Number(r))));
	}
	
	/**
	 * Run a decoder On/Off diagnostic test, limiting the stations selected for the diagnostic to those
	 * in *all* of the indicated area Id values. NOTE: onBeforeOff flag expected by the ManualOpsController 
	 * will always be defaulted; we do not pass a value for it.
	 * @param holeAndAreaIds - list of hole/area Id values limiting the selected stations. Only stations
	 * in *all* of the indicated areas will be chosen.
	 * @returns Observable(number[]) returning the list of station IDs selected for the diagnostic.
	 */
	 runDecoderOnOffByArea(holeAndAreaIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		return this
			.apiRequest<any>(this.runDecoderOnOffByAreaUrl(holeAndAreaIds), HttpMethod.Post)
			.pipe(map(list => list.map(r => Number(r))));
	}

	runFaultFindingModeOnOff(satelliteId: number, wirePath: number,
		faultFindingOn: boolean, durationSeconds: number): Observable<void> {
			return this
					.apiRequest<any>(this.runFaultFindingModeOnOffUrl(satelliteId, wirePath, faultFindingOn, durationSeconds), HttpMethod.Post);
	}

	// =========================================================================================================================================================
	// ScheduledDiagnostic tests
	// =========================================================================================================================================================

	getScheduledDiagnostics(): Observable<ScheduledDiagnostics> {
		return this.apiRequest<any>(this.getScheduledDiagnosticsUrl(), HttpMethod.Get)
			.pipe(map(json => new ScheduledDiagnostics(json)));
	}

	scheduleDiagnostic(test: ScheduledDiagnostic): Observable<void> {
		return this.apiRequest<any>(this.scheduleDiagnosticUrl(), HttpMethod.Post, test);
	}

	deleteScheduledDiagnostics(testType: RbEnums.Common.ScheduledDiagnosticTestType): Observable<void> {
		return this.apiRequest<any>(this.deleteScheduledDiagnosticsUrl(), HttpMethod.Post, testType);
	}

	// =========================================================================================================================================================
	// Diagnostic Mock tests
	// =========================================================================================================================================================

	runMockDiagnosticsStatusPollByAddress(stations: Station[], courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		const signalRService = AppInjector.get(SignalRService);
		return of(signalRService
					.createMockAddressDiagnosticsData(this,
													RbEnums.Common.DiagnosticDataType.ICShortAddress,
													courseId,
													stations,
													interfaceId,
													wirePathIds));
	}

	runMockDiagnosticsStatusPollByCourse(stations: Station[], courseId: number, holeIds: number[], areaIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		const signalRService = AppInjector.get(SignalRService);
		return of(signalRService
					.createMockCourseDiagnosticsData(this,
													RbEnums.Common.DiagnosticDataType.ICShortAddress,
													courseId,
													stations,
													holeIds,
													areaIds));
	}

	runMockDiagnosticsStatusPollBySystemPoll(stations: Station[], courseId: number): Observable<number[]> {
		this.diagnosticsCancelled = false;
		const signalRService = AppInjector.get(SignalRService);
		return of(signalRService
					.createMockSystemPollDiagnosticsData(this,
														RbEnums.Common.DiagnosticDataType.ICShortAddress,
														courseId,
														stations));
	}

	runMockDiagnosticsVoltageByAddress(stations: Station[], courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		const signalRService = AppInjector.get(SignalRService);
		return of(signalRService
					.createMockAddressDiagnosticsData(this,
													RbEnums.Common.DiagnosticDataType.ICVoltage,
													courseId,
													stations,
													interfaceId,
													wirePathIds));
	}

	runMockDiagnosticsVoltageByCourse(stations: Station[], courseId: number, holeIds: number[], areaIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		const signalRService = AppInjector.get(SignalRService);
		return of(signalRService
					.createMockCourseDiagnosticsData(this,
													RbEnums.Common.DiagnosticDataType.ICVoltage,
													courseId,
													stations,
													holeIds,
													areaIds));
	}

	runMockDiagnosticsVoltageBySystemPoll(courseId: number, stations: Station[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		const signalRService = AppInjector.get(SignalRService);
		return of(signalRService
					.createMockSystemPollDiagnosticsData(this,
													RbEnums.Common.DiagnosticDataType.ICVoltage,
													courseId,
													stations));
	}

	runMockDiagnosticsQuickCheckByAddress(stations: Station[], courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		const signalRService = AppInjector.get(SignalRService);
		return of(signalRService
					.createMockAddressDiagnosticsData(this,
													RbEnums.Common.DiagnosticDataType.QuickCheckDiagnostics,
													courseId,
													stations,
													interfaceId,
													wirePathIds));
	}

	runMockDiagnosticsQuickCheckByCourse(stations: Station[], courseId: number, holeIds: number[], areaIds: number[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		const signalRService = AppInjector.get(SignalRService);
		return of(signalRService
					.createMockCourseDiagnosticsData(this,
													RbEnums.Common.DiagnosticDataType.QuickCheckDiagnostics,
													courseId,
													stations,
													holeIds,
													areaIds));
	}

	runMockDiagnosticsQuickCheckBySystemPoll(courseId: number, stations: Station[]): Observable<number[]> {
		this.diagnosticsCancelled = false;
		const signalRService = AppInjector.get(SignalRService);
		return of(signalRService
					.createMockSystemPollDiagnosticsData(this,
														RbEnums.Common.DiagnosticDataType.QuickCheckDiagnostics,
														courseId,
														stations));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private runDiagnosticsStatusCheckByStationUrl(stationId: number): string { return `${this.baseUrl}/PollStationPresent?stationId=${stationId}`; }

	private runDiagnosticsVoltageCheckByStationUrl(stationId: number): string { return `${this.baseUrl}/PollStationVoltage?stationId=${stationId}`; }

	private runDiagnosticsStatusCheckByStationListUrl(): string { return `${this.baseUrl}/PollStationStatusByIds`; }

	private runDiagnosticsVoltageCheckByStationListUrl(): string { return `${this.baseUrl}/PollStationVoltageByIds`; }

	private get baseUrl(): string { return `${this.baseApiUrl}ManualOps`; }

	private get advanceProgramGroupsUrl(): string { return `${this.baseUrl}/AdvanceProgramGroups`; }

	private get advanceProgramsUrl(): string { return `${this.baseUrl}/AdvancePrograms`; }

	private advanceStationsUrl(isProgramIndex: boolean): string {return `${this.baseUrl}/AdvanceStations?isProgramIndex=${isProgramIndex}`; }

	private get advanceAreasUrl(): string { return `${this.baseUrl}/AdvanceAreas`; }

	private get resetSensorsUrl(): string { return `${this.baseUrl}/ResetSensors`; }

	private getSimulateRainCanPulseUrl(pulseValue?: number): string {
		return `${this.baseUrl}/SimulateRainCanPulse${pulseValue != null ? `?pulseValue=${pulseValue}` : ''}`;
	}

	private getSimulateFREEDOMCommandUrl(): string {
		return `${this.baseUrl}/SimulateFREEDOMCommand`;
	}

	private get pollSensorsUrl(): string { return `${this.baseUrl}/PollSensors`; }

	private connectUrl(controllerId: number): string { return `${this.baseUrl}/Connect?satelliteId=${controllerId}`; }

	private clearFlowLogsUrl(controllerId: number): string { return `${this.baseUrl}/ClearFlowLogs?satelliteId=${controllerId}`; }

	private closeMasterValvesUrl(controllerId: number, closeValves: boolean): string {
		return `${this.baseUrl}/CloseMasterValves?satelliteId=${controllerId}&closeMasterValves=${closeValves}`;
	}

	private disconnectUrl(controllerId: number): string { return `${this.baseUrl}/Disconnect?satelliteId=${controllerId}`; }

	private enableFlowManagerUrl(controllerId: number, isFloManagerEnabled: boolean, isFloWatchEnabled: boolean): string {
		return `${this.baseUrl}/EnableFloManager?satelliteId=${controllerId}&enableFloManager=${isFloManagerEnabled}&enableFloWatch=${isFloWatchEnabled}`;
	}

	private overrideTwoWirePathUrl(controllerId: number, twoWirePathPosition: RbEnums.Common.TwoWirePath): string {
		return `${this.baseUrl}/OverrideTwoWirePath?satelliteId=${controllerId}&twoWirePathState=${RbEnums.Common.TwoWirePath[twoWirePathPosition]}`;
	}

	private getSatelliteFirmwareVersionsUrl(controllerId: number) { return `${this.baseUrl}/GetSatelliteFirmwareVersions?satelliteId=${controllerId}`; }

	private get2WireDataUrl(controllerId: number) { return `${this.baseUrl}/Get2WireData?satelliteId=${controllerId}`; }

	private getDiagnosticsSegmentInfoUrl(controllerId: number) { return `${this.baseUrl}/GetDiagnosticsSegmentInfo?satelliteId=${controllerId}`; }

	private getWeatherSensorStatusUrl(controllerId: number) { return `${this.baseUrl}/GetWeatherSensorStatus?satelliteId=${controllerId}`; }

	private getMasterValveStatusUrl(controllerId: number) { return `${this.baseUrl}/GetMasterValveStatus?satelliteId=${controllerId}`; }

	private getShortReportUrl(controllerId: number) { return `${this.baseUrl}/GetShortReport?satelliteId=${controllerId}`; }

	private getCancelFirmwareUpdateUrl(controllerId: number) { return `${this.baseUrl}/CancelFirmwareUpdate?satelliteId=${controllerId}`; }

	private getUniversal2WireDevicePathsUrl(controllerId: number) { return `${this.baseUrl}/GetUniversal2WireDevicePaths?satelliteId=${controllerId}`; }

	private getUniversal2WireLineSurveyUrl(controllerId: number) { return `${this.baseUrl}/GetUniversal2WireLineSurvey?satelliteId=${controllerId}`; }

	private getUniversalDeviceResponseStatusesUrl(controllerId: number) {
		return `${this.baseUrl}/GetUniversalDeviceResponseStatuses?satelliteId=${controllerId}`;
	}

	private getInitiateLearnedFlowUrl(controllerId: number, startTime?: string) {
		const initiateLearnedFlowUrl = !!startTime
			? `${this.baseUrl}/InitiateLearnedFlow?satelliteId=${controllerId}&startTime=${startTime}`
			: `${this.baseUrl}/InitiateLearnedFlow?satelliteId=${controllerId}`;
		return initiateLearnedFlowUrl;
	}

	private overrideDialUrl(controllerId: number, dialPosition: RbEnums.Common.LogicalDialPos): string {
		return `${this.baseUrl}/OverrideDial?satelliteId=${controllerId}&dialPos=${RbEnums.Common.LogicalDialPos[dialPosition]}`;
	}

	private overrideSensorUrl(controllerId: number, switchPosition: RbEnums.Common.SensorSwitchPosition): string {
		return `${this.baseUrl}/OverrideSensor?satelliteId=${controllerId}&sensorPos=${RbEnums.Common.SensorSwitchPosition[switchPosition]}`;
	}

	private get retrieveEventLogsUrl(): string { return `${this.baseUrl}/RetrieveEventLogs`; }

	private getRunTestUrl(controllerId: number, duration: number): string {
		return `${this.baseUrl}/RunTestProgram?satelliteId=${controllerId}&seconds=${duration}`;
	}

	private get cancelDiagnosticsUrl(): string { return `${this.baseUrl}/CancelActiveDiagnostics`; }

	private get checkInterfaceConnectionUrl(): string { return `${this.baseUrl}/CheckInterfaceConnection`; }

	private runDiagnosticsSystemPollBySatelliteWirePathsUrl(satelliteId: number, wirePathIds: number[]): string {
		const wirePathsParam = (wirePathIds || []).reduce((list, id) => `${list ? list + '&' : ''}wirepaths=${id}`, '');
		return `${this.baseUrl}/PollStationStatusBySatelliteWirepaths?satelliteId=${satelliteId}&${wirePathsParam}`;
	}
	private runDiagnosticsSystemPollByAreaUrl(holeAndAreaIds: number[]): string {
		const idsParam = (holeAndAreaIds || []).reduce((list, id) => `${list ? list + '&' : ''}holesAndAreasList=${id}`, '');
		return `${this.baseUrl}/PollStationStatusByArea?${idsParam}`;
	}
	private runDiagnosticsSystemPollBySiteUrl(siteId: number): string { return `${this.baseUrl}/PollStationStatusBySite?siteId=${siteId}`; }

	private runDiagnosticsVoltagesBySatelliteWirePathsUrl(satelliteId: number, wirePathIds: number[]): string {
		const wirePathsParam = (wirePathIds || []).reduce((list, id) => `${list ? list + '&' : ''}wirepaths=${id}`, '');
		return `${this.baseUrl}/PollStationVoltagesBySatelliteWirepaths?satelliteId=${satelliteId}&${wirePathsParam}`;
	}

	private runDiagnosticsVoltagesByAreaUrl(holeAndAreaIds: number[]): string {
		const idsParam = (holeAndAreaIds || []).reduce((list, id) => `${list ? list + '&' : ''}holesAndAreasList=${id}`, '');
		return `${this.baseUrl}/PollStationVoltagesByArea?${idsParam}`;
	}

	private runDiagnosticsQuickCheckByStationUrl(runTimeSeconds: number, onBeforeOff: boolean): string {
		return `${this.baseUrl}/QuickCheck?runTimeSeconds=${runTimeSeconds}&onBeforeOff=${onBeforeOff}`;
	}

	private runDiagnosticsVoltagesBySiteUrl(siteId: number): string { return `${this.baseUrl}/PollStationVoltagesBySite?siteId=${siteId}`; }

	private runDiagnosticsQuickCheckBySatelliteWirePathsUrl(satelliteId: number, wirePathIds: number[], runTimeSeconds: number): string {
		const wirePathsParam = (wirePathIds || []).reduce((list, id) => `${list ? list + '&' : ''}wirepaths=${id}`, '');
		return `${this.baseUrl}/QuickCheckBySatelliteWirepaths?satelliteId=${satelliteId}&${wirePathsParam}&runTimeSeconds=${runTimeSeconds}`;
	}

	private runDiagnosticsQuickCheckByAreaUrl(holeAndAreaIds: number[], runTimeSeconds: number): string {
		const idsParam = (holeAndAreaIds || []).reduce((list, id) => `${list ? list + '&' : ''}holesAndAreasList=${id}`, '');
		return `${this.baseUrl}/QuickCheckByArea?${idsParam}&runTimeSeconds=${runTimeSeconds}`;
	}

	private runDiagnosticsQuickCheckBySiteUrl(siteId: number, runTimeSeconds: number): string {
		return `${this.baseUrl}/QuickCheckBySite?siteId=${siteId}&runTimeSeconds=${runTimeSeconds}`;
	}

	private get runDecoderOnOffUrl(): string { return `${this.baseUrl}/DecoderOnOff`; }

	private runDecoderOnOffByAreaUrl(holeAndAreaIds: number[]): string {
		const idsParam = (holeAndAreaIds || []).reduce((list, id) => `${list ? list + '&' : ''}holesAndAreasList=${id}`, '');
		return `${this.baseUrl}/DecoderOnOffByArea?${idsParam}`;
	}

	private sendRainDelayUrl(controllerId: number, days: number): string {
		return `${this.baseUrl}/SendRainDelay?satelliteId=${controllerId}&days=${days}`;
	}

	private setDiagnosticsTwoWireAcCurrentTestUrl(controllerId: number): string {
		return `${this.baseUrl}/SetDiagnosticsTwoWireAcCurrentTest?satelliteId=${controllerId}`;
	}

	private setWaterWindowUrl(controllerId: number, seconds: number): string {
		return `${this.baseUrl}/OpenManualMVWaterWindow?satelliteId=${controllerId}&seconds=${seconds}`;
	}

	private enableShortFindModeUrl(controllerId: number, enableShortFindMode: boolean): string {
		return `${this.baseUrl}/EnableShortFindMode?satelliteId=${controllerId}&enableShortFindMode=${enableShortFindMode}`;
	}

	private getStartLxdDecoderTestUrl(controllerId: number): string { return `${this.baseUrl}/StartLxdDecoderTest?satelliteId=${controllerId}`; }

	private runFaultFindingModeOnOffUrl(
		satelliteId: number, wirePath: number,
		faultFindingOn: boolean, durationSeconds: number): string {
		return `${this.baseUrl}/FaultFindingModeOnOff?satelliteId=${satelliteId}&groupNumber=${wirePath}
			&faultFindingOn=${faultFindingOn}&durationSeconds=${durationSeconds}`;
	}

	private get startProgramGroupsUrl(): string { return `${this.baseUrl}/StartProgramGroups`; }

	private get stopProgramGroupsUrl(): string { return `${this.baseUrl}/StopProgramGroups`; }

	private get pauseProgramGroupsUrl(): string { return `${this.baseUrl}/PauseProgramGroups`; }

	private get resumeProgramGroupsUrl(): string { return `${this.baseUrl}/ResumeProgramGroups`; }

	private get startProgramsUrl(): string { return `${this.baseUrl}/StartPrograms`; }

	private get stopProgramsUrl(): string { return `${this.baseUrl}/StopPrograms`; }

	private get pauseProgramsUrl(): string { return `${this.baseUrl}/PausePrograms`; }

	private get resumeProgramsUrl(): string { return `${this.baseUrl}/ResumePrograms`; }

	private get startStationsUrl(): string { return `${this.baseUrl}/StartStations`; }

	private get stopStationsUrl(): string { return `${this.baseUrl}/StopStations`; }

	private get pauseStationsUrl(): string { return `${this.baseUrl}/PauseStations`; }

	private getPingDecodersUrl(controllerId: number): string { return `${this.baseUrl}/PingDecoders?satelliteId=${controllerId}`; }

	private getPingUniversalUrl(controllerId: number): string { return `${this.baseUrl}/PingUniversal?satelliteId=${controllerId}`; }

	private get resumeStationsUrl(): string { return `${this.baseUrl}/ResumeStations`; }

	private updateFirmwareUrl(controllerId: number, filePath: string, verifySatellite = true): string {
		return `${this.baseUrl}/UpdateFirmware?satelliteId=${controllerId}&path=${filePath}&verifySatellite=${verifySatellite}`;
	}

	private sendIrrigationCommandUrl(commandType: RbEnums.Common.IrrigationEngineCommandType): string {
		return `${this.baseUrl}/SendIrrigationCommand?commandType=${commandType}`;
	}

	private getScheduledDiagnosticsUrl(): string {
		return `${this.baseUrl}/GetScheduledDiagnostics`;
	}

	private scheduleDiagnosticUrl(): string {
		return `${this.baseUrl}/ScheduleDiagnostic`;
	}

	private deleteScheduledDiagnosticsUrl(): string {
		return `${this.baseUrl}/DeleteScheduledDiagnostics`;
	}

	private renumberStationAddressesUrl(controllerId: number, groupNumber: number): string {
		return `${this.baseUrl}/RenumberStationAddresses?satelliteId=${controllerId}&groupNumber=${groupNumber}`;
	}

	private resetNccModemUrl(controllerId): string { return `${this.baseUrl}/resetNccModem?satelliteId=${controllerId}`; }

	private rasterTestUrl(controllerId: number): string { return `${this.baseUrl}/RasterTest?satelliteId=${controllerId}`; }

	private get unlockAllUrl(): string { return `${this.baseUrl}/UnlockAll`; }
}

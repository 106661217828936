import { Component, HostBinding, Injector, OnDestroy } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ConversionService } from '../../../../../common/services/conversion.service';
import { EditableGridUtil } from '../../../editable-grid/cell-editors/_utils/editable-grid.util';
import {
	IrrigationQueueCommandsComponent
} from '../../../../../sections/controllers/manual-ops/manual-ops-irrigation-queue-tab/irrigation-queue-commands/irrigation-queue-commands.component';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { Station } from '../../../../../api/stations/models/station.model';
import { StationListItem } from '../../../../../api/stations/models/station-list-item.model';
import { StationStatusListItem } from '../../../../../api/stations/models/_station-status-list-item.model';

@Component({
	selector: 'rb-station-mobile-cell-renderer',
	templateUrl: './station-mobile-cell-renderer.component.html',
	styleUrls: ['./station-mobile-cell-renderer.component.scss']
})
export class StationMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnDestroy {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	programsDisplay = '-';
	runtimeDisplay = '-';
	isRunning = false;
	station: StationListItem | StationStatusListItem | Station;
	satelliteName = '';
	isQueueCommandsGrid = false;
	isAssignedProgramsLongList = false;
	isRuntimeProgramsLongList = false;

	private _statusString: string;
	private _displayLimit = 3;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private conversionService: ConversionService,
				injector: Injector
	) {
		super(injector);
	}

	ngOnDestroy(): void {}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		super.agInit(params);

		this.isQueueCommandsGrid = params.parent != null && params.parent instanceof IrrigationQueueCommandsComponent;
		this.station = params.data;
		this.satelliteName = params.data?.satelliteName;

		if (this.isStationListItem) {
			this.updateAssignedProgramsData()
		}

		if (!this.isQueueCommandsGrid) {
			this.updateStationStatus(params);
		}
	}

	refresh(params: any): boolean {
		this.station = params.data;
		this.satelliteName = params.data?.satelliteName;
		if (this.isStationListItem) {
			this.updateAssignedProgramsData()
		}

		if (!this.isQueueCommandsGrid) {
			this.updateStationStatus(params);
		}
		return true;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get isActivelyCyclingOrSoaking(): boolean {
		if (!(this.station instanceof StationStatusListItem)) return false;
		return this.station.isActivelyCyclingOrSoaking;
	}

	get isCycling(): boolean {
		if (!(this.station instanceof StationStatusListItem)) return false;
		return this.station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running;
	}

	get isSoaking(): boolean {
		if (!(this.station instanceof StationStatusListItem)) return false;
		return this.station.irrigationStatus === RbEnums.Common.IrrigationStatus.Soaking;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	get statusString(): string {
		return (this.station instanceof StationStatusListItem) ? this.station.status : this._statusString;
	}

	get isStationListItem() { return this.station instanceof StationListItem; }

	get landscape(): string {
		return this.station instanceof StationListItem ? this.station.landscape : null;
	}

	get sprinkler(): string {
		return this.station instanceof StationListItem ? this.station.sprinkler : null;
	}

	get flowRateString(): string {
		if (!(this.station instanceof StationListItem)) return '';
		return this.conversionService.getUserFlowRateString(this.station.flowRate);
	}

	get flowRateIcon(): string {
		if (!(this.station instanceof StationListItem)) return '';
		return this.station.flowRateLearned ? 'mdi mdi-lightbulb-on learned' : 'mdi mdi-pencil manual';
	}

	get flowRateUnits(): string {
		if (!(this.station instanceof StationListItem)) return '';
		return EditableGridUtil.getFlowRateUnits(this.authManager.userCulture.unitType, this.station.sprinklerCategory);
	}

	// Method to flash cell when soaking/running status changes occur.
	private updateStationStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		this.isRunning = false;

		if (!(this.station instanceof StationStatusListItem)) {
			this._statusString = '';
			return;
		}

		switch (this.station.irrigationStatus) {
			case RbEnums.Common.IrrigationStatus.Dash:
			case RbEnums.Common.IrrigationStatus.Idle:
			case RbEnums.Common.IrrigationStatus.Pending:
			case RbEnums.Common.IrrigationStatus.Delaying:
				break;

			default:
				this.isRunning = true;
				params.api.flashCells({
					rowNodes: [rowNode]
				});
				break;
		}
	}

	triggerAction(event) {
		event.stopPropagation();
		if (this.isAssignedProgramsLongList || this.isRuntimeProgramsLongList) {
			this.params?.showPrograms(this.params.data);
		}
	}


	// Helpers functions
	private updateAssignedProgramsData() {
		const { isUniversalController = false } = this.params;
		if (isUniversalController) {
			this._displayLimit = 5;
		}

		this.showAssignedProgram();
		this.showRuntime();
	}

	private showAssignedProgram() {
		const { assignedPrograms = [] } = <StationListItem>this.station;
		if (assignedPrograms?.length) {
			const programNames = assignedPrograms.map(p => p.programShortName);
			if (programNames.length <= this._displayLimit) {
				this.programsDisplay = programNames.join(', ')
				return;
			}

			this.programsDisplay = `${programNames.slice(0, this._displayLimit).join(', ')}...`;
			this.isAssignedProgramsLongList = true;
		}
	}

	private showRuntime() {
		const { assignedPrograms = [] } = <StationListItem>this.station;
		if (!assignedPrograms?.length) {
			return;
		}

		const [first] = assignedPrograms;
		for (const program of assignedPrograms) {
			if (!!first?.adjustedRunTime && program?.adjustedRunTime !== first.adjustedRunTime) {
				this.runtimeDisplay = RbUtils.Translate.instant('STRINGS.MULTIPLE');
				this.isRuntimeProgramsLongList = true;
				return;
			}
		}

		[this.runtimeDisplay] = assignedPrograms.map(p => p.adjustedRunTime);
	}
}

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../../api/culture-settings/models/culture-settings.model';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../common/utils/_rb.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-units-of-measure-length',
	templateUrl: './units-of-measure-length.component.html',
	styleUrls: ['./units-of-measure-length.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UnitsOfMeasureLengthComponent),
			multi: true
		}
	]
})
export class UnitsOfMeasureLengthComponent implements OnInit, ControlValueAccessor {

	constructor(
		private translateService: TranslateService,
		private authManager: AuthManagerService,
	) { }

	@Input() name = '';
	@Input() isRequired = false;
	@Input() isDisabled = false;
	@Input() lengthFromUnit: RbEnums.Common.LengthUnit;
	@Input() lengthToUnit: RbEnums.Common.LengthUnit;
	@Input() precision = 2;
	@Input() allowNegativeValue = false;

	minValue = 0;
	valueString: string;
	cultureSettings: CultureSettings;
	loadError: string;
	hintLabel: string;
	pattern = /\D/g;

	private _value: number;

	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => { };
	private onTouched = () => { };

	private loadData() {
		switch (this.lengthToUnit) {
			case RbEnums.Common.LengthUnit.Millimeter:
				return this.hintLabel = this.translateService.instant('UNIT_TYPE.MILLIMETER');
			case RbEnums.Common.LengthUnit.Inch:
				return this.hintLabel = this.translateService.instant('UNIT_TYPE.INCH');
			case RbEnums.Common.LengthUnit.Centimeter:
				return this.hintLabel = this.translateService.instant('UNIT_TYPE.CENTIMETER');
			case RbEnums.Common.LengthUnit.Foot:
				return this.hintLabel = this.translateService.instant('UNIT_TYPE.FOOT');
			case RbEnums.Common.LengthUnit.Kilometer:
				return this.hintLabel = this.translateService.instant('UNIT_TYPE.KILOMETER');
			case RbEnums.Common.LengthUnit.Mile:
				return this.hintLabel = this.translateService.instant('UNIT_TYPE.MILE');
			case RbEnums.Common.LengthUnit.Meter:
			default:
				return this.hintLabel = this.translateService.instant('UNIT_TYPE.METER');
		}
	}

	ngOnInit(): void {
		this.cultureSettings = this.authManager.userCulture;
		// We will not replace the "-" for the negative value
		if (this.allowNegativeValue) {
			this.pattern = /[^\d-]/g;
		}
		this.loadData();
	}

	get value(): number { return this._value; }

	writeValue(value: any): void {
		this._value = value || this.minValue;
		this.updateValueString();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onValueChanged() {
		this.onTouched();
		this._value = this.valueString.length === 0 ? null : +this.valueString.toString().replace(this.pattern, '.');
		this.onChange(this._value);
	}

	private updateValueString() {
		this.valueString = this._value == null ? null :
			RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator, this._value.toString(), this.precision);
	}

}

import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationWithMapInfoLeaflet } from '../../../common/models/station-with-map-info-leaflet.model';

export class Subarea {

	adjustment = 100;
	arc: number;
	areaId: number;
	companyId: number;
	defaultCycleTime: string;
	defaultCycleTimeLong = 0;
	defaultRunTime: string;
	defaultRunTimeLong = 6000000000;
	defaultSoakTime: string;
	defaultSoakTimeLong = 0;
	defaultWeatherSourceId: number;
	distUniformityFinal: number;
	flowRate: number;
	headFlowRate: number;
	headRadius: number;
	headSpacing: RbEnums.Common.HeadSpacingType = RbEnums.Common.HeadSpacingType.Triangular;
	holeId: number;
	id: number;
	minETInchPerHr: number;
	maxETInchPerHr: number;
	name: string;
	nozzleId: number;
	precipRateInchPerHr: number;
	precRateFinal: number;
	pressure = 70;
	rowRadius: number;
	slope: number;
	sprinklerCategory = -1;
	sprinklerTypeId: number;
	sprinklersPerValve: number;
	stations?: StationWithMapInfoLeaflet[];
	usePrecRateCalc: boolean;
	numberOfStations: number;
	valveType: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}

	get stationsCountForHyperlink(): string {
		return this.numberOfStations === 0
			? RbUtils.Translate.instant('STRINGS.ADD_STATION')
			: `${this.numberOfStations} ${RbUtils.Translate.instant(this.numberOfStations === 1 ? 'STRINGS.STATION_LOWERCASE' : 'STRINGS.STATIONS_LOWERCASE')}`;
	}

	static createDefault(): Subarea {
		const area = new Subarea();
		return area;
	}
}

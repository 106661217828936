import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { GlobalWeatherLocation } from '../../../../../api/weather-sources/models/global-weather-location.model';

@Component({
	selector: 'rb-global-weather-mobile-cell-renderer',
	templateUrl: './global-weather-mobile-cell-renderer.component.html',
	styleUrls: ['./global-weather-mobile-cell-renderer.component.scss']
})
export class GlobalWeatherMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	location: GlobalWeatherLocation;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.location = params.data;
	}
}

import { Component, ViewChild } from '@angular/core';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { TextInputCellEditorBase } from '../_text-input-cell-editor-base';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-unique-name-cell-editor',
	templateUrl: './unique-name-cell-editor.component.html',
	styleUrls: ['./unique-name-cell-editor.component.scss']
})
export class UniqueNameCellEditorComponent extends TextInputCellEditorBase {
	@ViewChild('i') textInput;

	value: string;
	minLength = 0;
	maxLength = 255;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected broadcastService: BroadcastService,
				protected translateService: TranslateService,
	) {
		super(broadcastService, translateService);
	}

	// =========================================================================================================================================================
	// agGrid Methods
	// =========================================================================================================================================================

	// Get the value from the grid.
	agInit(params: any): void {
		super.agInit(params);

		this.value = this.params.charPress ? this.params.charPress : this.params.value;

		if (this.colDef.cellEditorParams) {
			if (this.colDef.cellEditorParams.minLength) { this.minLength = this.colDef.cellEditorParams.minLength; }
			if (this.colDef.cellEditorParams.maxLength) { this.maxLength = this.colDef.cellEditorParams.maxLength; }
		}
	}

	afterGuiAttached(params?: IAfterGuiAttachedParams) {
		this.textInput.nativeElement.focus();
		if (!this.params.charPress) { setTimeout(() => this.textInput.nativeElement.select()); }
	}

}


<button 
	[matMenuTriggerFor]="exportToMenu"
	class="toolbar-button">
	<i class="mdi mdi-file-export"></i>
	<span>{{ 'STRINGS.EXPORT_TO' | translate }}</span>
</button>

<mat-menu #exportToMenu="matMenu" class="export-to-menu rb-menu">

	<button mat-menu-item (click)="exportToCSV()" *ngIf="showCSV">
		<i class="mdi mdi-file-delimited text-blue"></i>
		<span>{{ 'STRINGS.CSV' | translate }}</span>
	</button>
	<button mat-menu-item (click)="exportToPDF()" *ngIf="showPDF">
		<i class="mdi mdi-file-document text-red"></i>
		<span>{{ 'STRINGS.PDF' | translate }}</span>
	</button>
	<button mat-menu-item (click)="sendToEmail()" *ngIf="showEmail">
		<i class="mdi mdi-email-fast-outline text-green"></i>
		<span>{{ 'STRINGS.EMAIL' | translate }}</span>
	</button>

</mat-menu>
<mat-form-field *ngIf="!resetting" class="input-with-select">
	<input matInput
		   type="text"
		   rbGroupDecimalSeparator
		   [placeholder]="placeholder"
		   [required]="isRequired"
		   [readonly]="readonly"
		   [min]="minVal"
		   [max]="maxVal"
		   [(ngModel)]="valueString"
		   (ngModelChange)="onValueChanged()"/>
	<mat-select [(ngModel)]="valueString" (selectionChange)="onValueChanged()" [required]="isRequired" [disabled]="readonly">
		<mat-option *ngFor="let item of list" [value]="item.value">{{item.display}}</mat-option>
	</mat-select>
</mat-form-field>
<div class="tr black warnColorText">{{hintLabel}}</div>

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-dialog-embedded" [ngClass]="{'global-weather': globalWeather}">
	<div class="rb-dialog-content">
		<ng-content></ng-content>
	</div>

	<div *ngIf="!showSubPanelSaveButton" class="rb-dialog-footer" [class.hidden]="hideCancelAndSave">
		<!--<div class="side-panel-footer-bg rb-dialog&#45;&#45;footer-bgX bg-white absolute w-100 left-0"></div>-->
		<button mat-button type="button" class="rb-secondary-button" color="primary" [disabled]="cancelDisabled" (click)="cancel.emit()" data-qaid="detail-panel-button-cancel">
			{{cancelString | translate}}
		</button>
		<button *ngIf="!isReadOnly" mat-raised-button type="button" class="rb-primary-button" color="primary" [disabled]="saveDisabled" (click)="saveClick()" data-qaid="detail-panel-button-save">
			{{saveString | translate}}
		</button>
	</div>
	<div *ngIf="showSubPanelSaveButton && isEditingController" class="rb-dialog-footer">
		<button [matTooltip]="'TOOLTIP.BACK_BUTTON_EDITING_IN_CONTROLLER' | translate" mat-raised-button type="button" class="rb-primary-button edit-panel-back-btn" color="primary" (click)="done.emit()" data-qaid="detail-panel-button-back">
			<span *ngIf="!showSubPanelBackArrow">{{ 'STRINGS.SAVE' | translate }}</span>
			<span *ngIf="showSubPanelBackArrow"><mat-icon>navigate_before</mat-icon>{{'STRINGS.BACK' | translate}}</span>
		</button>
	</div>
	<div *ngIf="showSubPanelSaveButton && !isEditingController" class="rb-dialog-footer">
		<button mat-raised-button type="button" class="rb-primary-button edit-panel-back-btn" color="primary" (click)="done.emit()" data-qaid="detail-panel-button-back">
			<span *ngIf="!showSubPanelBackArrow">{{ 'STRINGS.SAVE' | translate }}</span>
			<span *ngIf="showSubPanelBackArrow"><mat-icon>navigate_before</mat-icon>{{'STRINGS.BACK' | translate}}</span>
		</button>
	</div>
</div>

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-flow-alarm-mobile-cell-container">

    <div style="display: flex;">
        <div class="famc-col famc-labels-col">
            <span class="famc-label">#</span>
            <span class="famc-label">{{'STRINGS.NAME' | translate}}</span>
            <span class="famc-label">{{'STRINGS.TYPE' | translate}}</span>
            <span *ngIf="showAddress" class="famc-label">{{'STRINGS.ADDRESS' | translate}}</span>
        </div>
        <div class="famc-col famc-vals-col">
            <span>{{alarm.sequence}}</span>
            <span>{{alarm.name}}</span>
            <span>{{alarm.type}}</span>
            <span *ngIf="showAddress">{{alarm.address}}</span>
        </div>
    </div>

</div>

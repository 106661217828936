<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="showDurationDialog">
	<!-- Field Tech User -->
	<mat-form-field *ngIf="isReadOnly" [class.narrow]="narrow">
		<input data-qaid="input-set-duration-trigger" matInput type="text" [disabled]="true" readonly [ngModel]="durationString" (click)="durationTimeClicked()" [placeholder]="placeholder" [disabled]="isDisabled"/>

		<mat-hint align="left" [hidden]="hideHours">{{'STRINGS.HR' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideMinutes">{{'STRINGS.MIN' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideSeconds">{{'STRINGS.SEC' | translate}}</mat-hint>
	</mat-form-field>

	<!-- Normal User -->
	<mat-form-field *ngIf="!isReadOnly" [class.narrow]="narrow" class="duration-timer">
		<input data-qaid="input-set-duration-trigger" matInput type="text" readonly [ngModel]="durationString" (click)="durationTimeClicked()" [placeholder]="placeholder" [disabled]="isDisabled"/>

		<mat-hint align="left" [hidden]="hideHours">{{'STRINGS.HR' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideMinutes">{{'STRINGS.MIN' | translate}}</mat-hint>
		<mat-hint align="right" class="min-suffix" [hidden]="hideSeconds">{{'STRINGS.SEC' | translate}}</mat-hint>
	</mat-form-field>

	<ng-template #durationDialog>
		<rb-duration-dialog
			[duration]="value"
			(durationChange)="onDurationChanged($event)"
			[hideHours]="hideHours"
			[hideMinutes]="hideMinutes"
			[hideSeconds]="hideSeconds"
			[maxHours]="maxHours"
			[maxMinutes]="maxMinutes"
			[dialogRef]="durationDialogRef"
			[showTabs]="showTabs"
			[station]="station"
		>
		</rb-duration-dialog>
	</ng-template>
</div>

<div *ngIf="!showDurationDialog">
	<div class="pt3">
		<rb-duration-dialog
			[duration]="value"
			(durationChange)="onDurationChanged($event)"
			[hideHours]="hideHours"
			[hideMinutes]="hideMinutes"
			[hideSeconds]="hideSeconds"
			[showButtons]="false"
			[showHeader]="false"
			[maxHours]="maxHours"
			[showSlider]="showSlider"
			[isReadOnly]="isReadOnly"
		>
		</rb-duration-dialog>

	</div>
</div>

import { IActiveFlowListItem } from './active-flow-list-item.interface';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

export abstract class ActiveFlowListItem implements IActiveFlowListItem {

	protected constructor(changDateTime: Date) {
		this.changeDateTime = this.changeDateTime;
	}

	isStatusChange = false;

	readonly changeDateTime: Date;

	abstract get id(): number;

	abstract get name(): string;

	abstract get typeId(): RbEnums.Common.ActivityType;

	abstract get type(): string;

	abstract get typeSortValue(): number;

	abstract get changeType(): string;

	get status(): string {
		return '-';
	}

	get startTime(): Date {
		return null;
	}

	get runtimeRemaining(): string {
		return '-';
	}

}

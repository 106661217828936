import { BehaviorSubject, Observable, of} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventManager } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { WeatherSourceManagerService } from '../weather-sources/weather-source-manager.service';

@Injectable({
	providedIn: 'root'
})
export class InternetService {
	public connectionChanged = new BehaviorSubject<boolean>(true);
	public connState = true;
	private searchItem = 'Tucson';

	constructor(private eventManager: EventManager, private weatherManager: WeatherSourceManagerService) {
		this.eventManager.addGlobalEventListener('window', 'online', e => this.statusChanged(true));
		this.eventManager.addGlobalEventListener('window', 'offline', e => this.statusChanged(false));
	}

	public testConnection() {
		this.testConnectionFunction().subscribe();
	}

	public testConnectionFunction(): Observable<boolean> {
		return this.weatherManager.searchGlobalWeatherLocations(this.searchItem)
			.pipe(map(() => {
				this.connected(true);
				return true;
				}),
			catchError(() => {
				this.connected(false);
				return of(false);
			}));
	}

	private statusChanged(connected: boolean) {
		if (connected) {
			this.testConnection();
		} else {
			this.connected(false);
		}
	}

	private connected(data: boolean) {
		this.connState = data;
		this.connectionChanged.next(this.connState);
	}
}

import { Component, EventEmitter, Input, Output, } from '@angular/core';

import { ControllerListItem } from '../../../api/controllers/models/controller-list-item.model';
import { MessageBoxInfo } from '../global-message-box/message-box-info.model';
import { MessageBoxService } from '../../../common/services/message-box.service';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;

@Component({
	selector: 'rb-icon-button',
	templateUrl: './icon-button.component.html',
	styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
	@Input() animationType: RbEnums.Common.IconAnimationType = RbEnums.Common.IconAnimationType.None;
	@Input() color: string;
	@Input() disabled = false;
	@Input() icon = 'add_circle';
	@Input() label: string;
	@Input() hideLabel = false;
	@Input() hideBorder = false;
	@Input() customIconNumber = RbConstants.Form.CUSTOM_ICON_NUMBER.standard;
	@Input() badgeCount = 0;
	@Input() iconContainerClass = '';

	@Input() set isAnimating(value: boolean) {
		this._isAnimating = value;
		this.animationClass = this.getAnimationClass(value);
	}
	get isAnimating(): boolean { return this._isAnimating; }

	@Input() controllerListItem: ControllerListItem = null;
	@Input() isControllerListItemEventDisabled: boolean = false;

	@Output() iconButtonClicked = new EventEmitter();
	Constants =  RbConstants.Form;
	_isAnimating = false;
	animationClass: {} = null;

	constructor(private messageBoxService: MessageBoxService) { }

	getAnimationClass(isAnimating: boolean) {
		switch (this.animationType) {
			case RbEnums.Common.IconAnimationType.ControllerConnecting:
				return { 'connect-button-icon-blinking': isAnimating };
			case RbEnums.Common.IconAnimationType.ControllerDisconnecting:
				return { 'connect-button-icon-blinking-disconnecting': isAnimating };
			case RbEnums.Common.IconAnimationType.Spin:
				return { 'rb-spin': isAnimating };
			case RbEnums.Common.IconAnimationType.SpinCounterclockwise:
				return { 'rb-spin-counterclockwise': isAnimating };
			case RbEnums.Common.IconAnimationType.RotateX:
				return { 'rb-rotate-x': isAnimating };
			case RbEnums.Common.IconAnimationType.RotateY:
				return { 'rb-rotate-y': isAnimating };
			default:
				return null;
		}
	}

	onButtonClicked() {
		if (!this.isControllerListItemEventDisabled && this.controllerListItem?.iqNetType === RbEnums.Common.IqNetType.IQNetServer && 
			(this.controllerListItem.commInterfaceType === RbEnums.Common.CommInterfaceType.None ||
			this.controllerListItem.commInterfaceType === RbEnums.Common.CommInterfaceType.Demo)) {
			const mbi = new MessageBoxInfo('SPECIAL_MSG.DEMO_MODE_CONNECT_UNAVAILABLE', MessageBoxIcon.Information);
			this.messageBoxService.showMessageBox(mbi);
			return;
		}
		this.iconButtonClicked.emit();

		// this.animationClass = this.getAnimationClass(true);
		// if (this.animationClass !== null) {
		// 	this._isAnimating = true;
		// }
	}

}

import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { ChangeRBCC, GetRBCCMessages, onPublishRBCCResult } from './change-rbcc.model';
import { Observable, Subscription } from 'rxjs';
import { AppInjector } from '../../core/core.module';
import { BackendNotificationService } from './backend-notification.service';
import { CompanyStatusChange } from './company-status-change.model';
import { ControllerChange } from './controller-change.model';
import { DryrunChange } from './dryrun-change.model';
import { environment } from '../../../environments/environment';
import { FlowElementChange } from './flow-element-change.model';
import gql from 'graphql-tag';
import { JobChange } from './job-change.model';
import { ManualOpsManagerService } from '../../api/manual-ops/manual-ops-manager.service';
import { ProgramChange } from './program-change.model';
import { RadioRelayChange } from './radio-relay-change.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ScheduledReportChange } from './scheduled-report-change.model';
import { SensorStatusChange } from './sensor-status-change.model';
import { SiteStatusChange } from './site-status-change.model';
import { StationStatusChange } from './station-status-change.model';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WeatherSourceStatusChange } from './weather-source-status-change.model';


@UntilDestroy()
export class AppSyncService extends BackendNotificationService {
	private readonly ReconnectDelayMS = 15000;

	private subscribeDoc = `
	subscription RBCCSubscription {
		onPublishRBCC(name: "{{name}}") {
			data
			messageName
			name
		}
	}`;
	private queryDoc = `query GetRBCCMessages($name: String!) {
		getRBCCMessages(name: $name) {
		  data
		  messageName
		  name
		  __typename
		}
	  }`;
	private _appSyncObservable: Observable<ChangeRBCC>;
	private _appSyncObserverCount = 0;
	private _awsAppSyncClient: AWSAppSyncClient<any>;
	private _token: string;
	private _hydratedClientObservable: Subscription;

	protected override get notificationServiceName(): string {
		return typeof AppSyncService;
	}

	/**
	 * We use string substitution (replacing {{name}} with value of parameters.name) in several instances
	 * here. I've set this up so we could cache an instance of TranslateDefaultParser, if desirable, but
	 * simple object creation is probably just as fast as null checking.
	 */
	protected get stringFormatter(): TranslateDefaultParser {
		return new TranslateDefaultParser();
	}

	/**
	 * awsAppSyncClient returns the active AWSAppSyncClient used for notifications. It will be created on first
	 * access.
	 */
	private get awsAppSyncClient(): AWSAppSyncClient<any> {
		if (this._awsAppSyncClient != null) return this._awsAppSyncClient;

		// Build the auth element of the client based on environment settings. If API key is non-null, use that
		// (debugging). If null, use the OPENID_CONNECT authentication and pass the token we received when
		// logging-in.
		let auth: any;
		if (this.env.awsRBCCAppSyncApiKey != null)
		{
			auth = {
				type: AUTH_TYPE.API_KEY,
				apiKey: this.env.awsRBCCAppSyncApiKey,
			};
		} else {
			auth = {
				type: AUTH_TYPE.OPENID_CONNECT,
				jwtToken: this._token,
			};
		};

		this._awsAppSyncClient = new AWSAppSyncClient({
			url: this.env.awsRBCCAppSyncUrl,
			region: this.env.awsRBCCAppSyncRegion,
			auth: auth,
		});
		return this._awsAppSyncClient;
	}

	constructor() { 
		super();
		
		this.retry = 0;

		// Injected here so we receive any WeatherSensorStatusChanged events at startup.
		AppInjector.get(ManualOpsManagerService);
	}

	public override startConnection(): void {
		this._appSyncObserverCount++;
		if (this._appSyncObservable != null) return;

		// Retrieve the token from auth manager service. The token should not change for the duration of
		// the AppSync connection (???? confirm!).
		this._token = this.authManager.accessToken;

		// Create an observable that will pass-thru the data we receive from AppSync.
		// That way we can control the life-cycle of our observables more carefully.
		this._appSyncObservable = new Observable<ChangeRBCC>(observer => {
			this.awsAppSyncClient.hydrated().then((hydratedClient: any) => {
				// Don't subscribe to the hydrated client more than once
				if (this._hydratedClientObservable !== undefined) return;

				// Use an environment string as the format for the subscription channel 'name'. After this segment of 
				// code, name is the name, which could be company ID, company UUID or some combination of both as 
				// specified in the environment string.
				const name = this.getCompanyName();

				// Now insert the channel name into the subscription GraphQL text. We'd like to use the normal GraphQL
				// variable substitution but it doesn't seem to work in JavaScript with these libraries. After this
				// code section, subscribeDoc should be the correct query with channel 'name' filled-in.
				const parser = this.stringFormatter;
				const subscribeDoc = parser.interpolate(this.subscribeDoc, { name });

				const query = gql(subscribeDoc);

				this._hydratedClientObservable = hydratedClient
					.subscribe({ query: query, variables: {} })
						.subscribe((result: onPublishRBCCResult) => {
							observer.next(result.data.onPublishRBCC);
						},
						error => {
							console.error('Error in hydratedClient AppSync subscription %o', error);

							// Set the count to 1 so stopConnection() will shut down everything (it decrements the value and checks
							// for zero).
							this._appSyncObserverCount = 1;
							this.stopConnection();

							// Restart the connection in a few seconds.
							setTimeout(() => this.startConnection(), this.ReconnectDelayMS);
						});
				});
		});

		this._appSyncObservable.subscribe((result: ChangeRBCC) => {
			// Ignore any message not corresponding with our company "name". This should never occur, but it doesn't
			// hurt to do a quick check.
			const name = this.getCompanyName();
			if (result.name != name) {
				return;
			}
			this.processResult(result, name);
		},
		error => {
			// Error in reception.
			console.error('Error in appSyncObservable subscription = %o', error);

			// Set ref count to 1 so stopConnection() will complete the disconnect process.
			this._appSyncObserverCount = 1;
			this.stopConnection();

			// Restart the connection after a short pause.
			setTimeout(() => this.startConnection(), this.ReconnectDelayMS);
		});
		const name = this.getCompanyName();
		const query = gql(this.queryDoc);
		const input = {name: name};
		this.awsAppSyncClient.query<GetRBCCMessages>({
			query: query,
			variables: input
		}).then( result => {
			if (result.data && result.data.getRBCCMessages) {
				const changes = result.data.getRBCCMessages;
				if (changes.length > 0) {
					this.processRBCCMessage(changes);	
				}
			}			
		});
	}

	private processResult(change: ChangeRBCC, name: string) {
			// The 'data' property is stringified Json. Parse it.
			const json = JSON.parse(change.data);

			// Switch based on the message type, extract the data based on that type, calling the BackendNotificationService
			// method to match.
			switch(change.messageName) {
				case RbEnums.SignalR.StatusMethod.DryRunStatusChange:
					{
						const changes: DryrunChange[] = json.map(c => new DryrunChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.DryRunStatusChange, changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.FlowElementStatusChange:
					{
						const changes: FlowElementChange[] = json.map(c => new FlowElementChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.FlowElementStatusChange, changes);
						this.onFlowElementStatusChange(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.IrrigationEngineStatusChange:
					{
						const changes: CompanyStatusChange[] = json.map(c => new CompanyStatusChange(c))
							.filter(c => c.companyId === this.authManager.getUserProfile().companyId);
						if (changes.length > 0) {
							this.logCallback(RbEnums.SignalR.StatusMethod.IrrigationEngineStatusChange, changes);
							this.onIrrigationEngineStatusChange(changes);
						}
					}
					break;

				case RbEnums.SignalR.StatusMethod.JobQueued:
					{
						const changes: JobChange[] = json.map(c => new JobChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.JobQueued, changes);
						this.onJobQueued(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.JobFinish:
					{
						const changes: JobChange[] = json.map(c => new JobChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.JobFinish, changes);
						this.onJobFinish(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.JobProgressStatus:
					{
						const changes: JobChange[] = json.map(c => new JobChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.JobProgressStatus, changes);
						this.onJobProgressStatus(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.JobRequestCompleted:
					{
						const changes: JobChange[] = json.map(c => new JobChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.JobRequestCompleted, changes);
						this.onJobRequestCompleted(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.JobRequested:
					{
						const changes: JobChange[] = json.map(c => new JobChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.JobRequested, changes);
						this.onJobRequested(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.JobStart:
					{
						const changes: JobChange[] = json.map(c => new JobChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.JobStart, changes);
						this.onJobStart(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.ProgramStatusChange:
					{
						const changes: ProgramChange[] = json.map(c => new ProgramChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.ProgramStatusChange, changes);
						this.onProgramStatusChange(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.SatelliteStatusChange:
					{
						const changes: ControllerChange[] = json.map(c => new ControllerChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.SatelliteStatusChange, changes);
						this.onSatelliteStatusChange(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.RadioRelayStatusChange:
					{
						const changes: RadioRelayChange[] = json.map(c => new RadioRelayChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.RadioRelayStatusChange, changes);
						this.onRadioRelayStatusChange(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.SensorStatusChange:
					{
						const changes: SensorStatusChange[] = json.map(c => new SensorStatusChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.SensorStatusChange, changes);
						this.onSensorStatusChange(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.StationStatusChange:
					{
						const changes: StationStatusChange[] = json.map(c => new StationStatusChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.StationStatusChange, changes);
						this.onStationStatusChange(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.StationValveTypeStatusChange:
					{
						this.logCallback(RbEnums.SignalR.StatusMethod.StationValveTypeStatusChange, change.data);
					}
					break;

				case RbEnums.SignalR.StatusMethod.SiteStatusChange:
					{
						const changes: SiteStatusChange[] = json.map(c => new SiteStatusChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.SiteStatusChange, changes);
						this.onSiteStatusChange(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.WeatherSourceStatusChange:
					{
						const changes: WeatherSourceStatusChange[] = json.map(c => new WeatherSourceStatusChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.WeatherSourceStatusChange, changes);
						this.onWeatherSourceStatusChange(changes);
					}
					break;

				case RbEnums.SignalR.StatusMethod.ScheduledReportStatusChange:
					{
						const changes: ScheduledReportChange[] = json.map(c => new ScheduledReportChange(c));
						this.logCallback(RbEnums.SignalR.StatusMethod.ScheduledReportStatusChange, changes);
						this.onScheduledReportChangeType(changes);
					}
					break;

				default:
					// Bad message. We should look into this!
					console.error('Invalid AppSync message received %o, message = %o', change.messageName, change);
					break;
			}
	}
	private processJobChangeStatus(change: ChangeRBCC, name: string) {
		// The 'data' property is stringified Json. Parse it.
		const json = JSON.parse(change.data);
		const changes: JobChange[] = json.map(c => new JobChange(c))
		// Switch based on the message type, extract the data based on that type, calling the BackendNotificationService
		// method to match.
		if (changes.length < 1) return;

		switch(RbEnums.SignalR.JobPhase[changes[0].jobPhase]) {
			case RbEnums.SignalR.JobPhase.Queued:
				{
					this.logCallback(RbEnums.SignalR.StatusMethod.JobQueued, changes);
					this.onJobQueued(changes);
				}
				break;

			case RbEnums.SignalR.JobPhase.Finish:
				{
					this.logCallback(RbEnums.SignalR.StatusMethod.JobFinish, changes);
					this.onJobFinish(changes);
				}
				break;

			case RbEnums.SignalR.JobPhase.InProcess:
				{
					this.logCallback(RbEnums.SignalR.StatusMethod.JobProgressStatus, changes);
					this.onJobProgressStatus(changes);
				}
				break;

			case RbEnums.SignalR.JobPhase.RequestComplete:
				{
					this.logCallback(RbEnums.SignalR.StatusMethod.JobRequestCompleted, changes);
					this.onJobRequestCompleted(changes);
				}
				break;

			case RbEnums.SignalR.JobPhase.Request:
				{
					this.logCallback(RbEnums.SignalR.StatusMethod.JobRequested, changes);
					this.onJobRequested(changes);
				}
				break;

			case RbEnums.SignalR.JobPhase.Start:
				{
					this.logCallback(RbEnums.SignalR.StatusMethod.JobStart, changes);
					this.onJobStart(changes);
				}
				break;
			default:
				// Bad message. We should look into this!
				console.error('Invalid AppSync message received %o, message = %o', change.messageName, change);
				break;
		}
	}

	private processRBCCMessage(changes: ChangeRBCC[]) {
		const name = this.getCompanyName();

		// data should contains more than one actual changes, so we make a new list of ChangeRBCC that contains only 1 change in data
		const results: ChangeRBCC[] = [];
		changes.forEach(change => {
			if (change.data) {
				const actualChanges = JSON.parse(change.data);
				if (actualChanges && actualChanges.length > 0)
				{
					actualChanges.forEach(actualChange => results.push({
						messageName: change.messageName,
						data: JSON.stringify([actualChange]),
						name: change.name
					}));
				}
			}
		});

		// sort the notifications by their changeDateTime to make sure they come in the order we expected when sending from service
		results.sort((c1, c2) => {
			const d1 = new Date(JSON.parse(c1.data)[0].changeDateTime);
			const d2 = new Date(JSON.parse(c2.data)[0].changeDateTime);
			
			return d1 > d2 ? 1 : -1;
		});
		
		results.forEach( change => {
			change.messageName = change.messageName.split('#')[0];
			if (change.messageName === 'JobChangeStatus') {
				this.processJobChangeStatus(change, name);
			} else {
				this.processResult(change, name);
			}
		});
	}

	public override stopConnection() {
		if (--this._appSyncObserverCount > 0) return;
		if (this._hydratedClientObservable == null) return;
		this._hydratedClientObservable.unsubscribe();
		this._hydratedClientObservable = null;
		this._appSyncObservable = null;
	}

	/**
	 * This method is used to convert the current user's company into a channel name for AppSync. There
	 * are two real possiblities for the name, companyId and companyUUID. the choice is controlled by
	 * a format string (not translatable, of course), in the environment.
	 */
	protected getCompanyName(): string {
		const userProfile = this.authManager.getUserProfile();
		const id = userProfile.companyId;
		const uuid = userProfile.companyUUId;
		const name = this.stringFormatter.interpolate(environment.awsRBCCAppSyncChannelFormat, { id, uuid });
		return name;
	}

}

import { ApiService, HttpMethod } from '../_common/api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PinCode } from './models/pin-code.model';
import { PinCodeListItem } from './models/pin-code-list-item.model';
import { PinCodeUpdate } from './models/pin-code-update.model';
import { SatellitePinCode } from './models/satellite-pin-code.model';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@Injectable({
	providedIn: 'root'
})
export class PinCodeApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getMyPinCode(): Observable<PinCode> {
		return this.apiRequest<any>(this.getMyPinCodeUrl(), HttpMethod.Get)
			.pipe(map(pinCode => new PinCode(pinCode)));
	}

	getPinCodes(): Observable<PinCodeListItem[]> {
		return this.apiRequest<any>(this.getPinCodesUrl, HttpMethod.Get)
			.pipe(map((pinCodes: PinCodeListItem[]) => pinCodes.map(pc => new PinCodeListItem(pc))));
	}

	getSatellitePinCodes(controllerId: number): Observable<SatellitePinCode[]> {
		return this.apiRequest<any>(this.getSatellitePinCodesUrl(controllerId), HttpMethod.Get)
			.pipe(map(list => list.map(item => new SatellitePinCode(item))));
	}

	getPinCodeByUserId(userId: number): Observable<PinCode> {
		return this.apiRequest<any>(this.getPinCodeByUserIdUrl(userId), HttpMethod.Get)
			.pipe(map(pinCode => new PinCode(pinCode)));
	}

	getPinUniqueness(pin: number, id: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getPinUniquenessUrl(pin, id), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	saveSatellitePinCodes(controllerId: number, pinCodeIds: number[]): Observable<null> {
		return this.apiRequest<any>(this.getSaveSatellitePinCodesUrl(controllerId, pinCodeIds), HttpMethod.Get);
	}

	updatePinCode(pinCodeUpdate: PinCodeUpdate): Observable<void> {
		const pinCode = {pin: pinCodeUpdate.pin};
		return this.apiRequest<any>(this.updatePinCodeUrl(pinCodeUpdate), HttpMethod.Patch, this.patchTransform(pinCode));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}PinCode`; }

	private get getPinCodesUrl(): string { return `${this.baseUrl}/GetPinCodeList`; }

	private getSatellitePinCodesUrl(controllerId: number): string {
		return `${this.baseUrl}/GetSatellitePinCodes?satelliteId=${controllerId}`;
	}

	private getPinUniquenessUrl(pin: number, id: number): string {
		return `${this.baseUrl}/IsPinUnique?pin=${pin}&pinId=${id}`;
	}

	private getSaveSatellitePinCodesUrl(controllerId: number, pinCodeIds: number[]): string {
		const idsParam = pinCodeIds.reduce((list, id) => `${list ? list + '&' : ''}pinCodeIds=${id}`, '');
		return `${this.baseUrl}/SaveSatellitePinCodes?satelliteId=${controllerId}&${idsParam}`;
	}

	private getMyPinCodeUrl(): string { return `${this.baseUrl}/GetMyPinCode`; }

	private getPinCodeByUserIdUrl(userId: number): string { return `${this.baseUrl}/GetPinCodeByUserId?userId=${userId}`; }

	private updatePinCodeUrl(pinCodeUpdate: PinCodeUpdate): string { return `${this.baseUrl}/Update?pinCodeId=${pinCodeUpdate.id}`; }
}

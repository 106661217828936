import { Component, Input, OnInit } from '@angular/core';
import { CourseViewActiveStation } from '../../../../api/activity/models/course-view-active-station.model';
import { CourseViewerStationBase } from '../course-viewer-station-base';
import { CourseViewStationData } from '../../../../api/activity/models/course-view-station-data.model';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { IcmDiagnosticResult } from '../../../../sections/diagnostics/diagnostics-container/icm-diagnostics/models/icm-diagnostic-result.model';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { TranslateService } from '@ngx-translate/core';

import DiagnosticFeedbackResult = RbEnums.Common.DiagnosticFeedbackResult;
import DiagnosticDataType = RbEnums.Common.DiagnosticDataType;

@Component({
	selector: 'rb-course-viewer-diagnostics-station',
	templateUrl: './course-viewer-diagnostics-station.component.html',
	styleUrls: ['./course-viewer-diagnostics-station.component.scss']
})
export class CourseViewerDiagnosticsStationComponent extends CourseViewerStationBase implements OnInit {

	DiagnosticsDataType = DiagnosticDataType;
	DiagnosticsFeedbackResult = DiagnosticFeedbackResult;

	@Input() set stationData(stationData: CourseViewActiveStation | CourseViewStationData) {
		if (stationData == null || stationData instanceof CourseViewStationData) { return; }

		this.station = stationData.station;
		this.setRunnableState();
	}

	@Input() set diagnosticsData(stationData: IcmDiagnosticResult[]) {
		if (!stationData || stationData.length < 1) { return; }

		const sd = stationData.find(s => s.station.id === this.stationId);
		if (!sd) { return; }

		this.diagnosticsResult = sd;
		this.setDisplayValues();
	}

	diagnosticsResult: IcmDiagnosticResult;

	// ========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// ========================================================================================================================================================

	constructor(protected deviceManager: DeviceManagerService,
				protected translateService: TranslateService
	) {
		super(deviceManager, translateService);
	}

	// ========================================================================================================================================================
	// Helper Methods
	// ========================================================================================================================================================

	private setDisplayValues() {
		if (this.diagnosticsResult == null) { return; }

		this.tooltip = `${this.translateService.instant('STRINGS.INTERFACE')}: ${this.diagnosticsResult.interface}`;

		if (this.diagnosticsResult.cdType === DiagnosticDataType.ICShortAddress || this.diagnosticsResult.cdType === DiagnosticDataType.ICVoltage) {
			this.tooltip += `\n${this.translateService.instant('STRINGS.WIREPATH')}: ${this.diagnosticsResult.wirePath}`;
		}
	}

}

import * as moment from 'moment';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RbConstants } from '../../../common/constants/_rb.constants';

@Component({
	selector: 'rb-time-input',
	templateUrl: './time-input.component.html',
	styleUrls: ['./time-input.component.scss'],
})
export class TimeInputComponent {

	@Input() durationHours = 0;
	@Input() durationMinutes = 0;
	@Input() durationSeconds = 0;
	@Input() hideHours = false;
	@Input() hideMinutes = false;
	@Input() hideSeconds = false;
	@Input() maxHours: number;
	@Input() title;

	@Output() onInputChange = new EventEmitter();

	RbFormConstants = RbConstants.Form;

	formatLabel(value: number | null) {
		if (!value) {
			return 0;
		}
		return value.toString().padStart(RbConstants.Form.PADDING_ZERO, '0');
	}

	getDurationMinutes = (value) => {
		if (!value) {
			value = 0;
		}
		return value.toString().padStart(RbConstants.Form.PADDING_ZERO, '0');
	}

	getDurationSeconds = (value) => {
		if (!value) {
			value = 0;
		}
		return value.toString().padStart(RbConstants.Form.PADDING_ZERO, '0');
	}

	handleInputChanged(value, type: string) {
		switch (type) {
			case 'hours':
			if (value >= this.maxHours) {
				this.durationHours = value;
				this.durationMinutes = 0 ;
				this.durationSeconds = 0 ;
				} else {
				this.durationHours = value;
				}
				break;
			case 'minutes':
				this.durationMinutes = value;
				break;
			case 'seconds':
				this.durationSeconds = value;
				break;
			default:
			// handled elsewhere
		}
		const duration = moment.duration({ hours: Number(this.durationHours),
									minutes: Number(this.durationMinutes), seconds: Number(this.durationSeconds) });
		this.onInputChange.emit(duration);
	}
}

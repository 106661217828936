import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

import { AppDateAdapter } from '../custom/date-adapter';

@Pipe({
  name: 'formattedTime'
})
export class FormattedTimePipe implements PipeTransform {
  constructor(private appDateAdapter: AppDateAdapter) {}

  transform(datetimeString: string, emptyDateTimeFormat = '-'): string {
    return datetimeString ? this.appDateAdapter.formatTime(moment(datetimeString).toDate()) : emptyDateTimeFormat;
  }

}

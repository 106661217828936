import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { StationListItem } from '../../../../../api/stations/models/station-list-item.model';

import DiagnosticDataType = RbEnums.Common.DiagnosticDataType;
import { Station } from '../../../../../api/stations/models/station.model';

export class DecoderStationDiagnosticResult<T = StationListItem | Station> {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	station: T;
	cdType: DiagnosticDataType;
	interface: string;
	location: string;
	hole: number;
	area: string;
	subArea: string;
	course: string;
	result: number;
	isSuccess: boolean;
	// Current received when doing Quick Check from API, 
	// we get value1, value2 and value3 which we set respectively on these fields.
	atRest: number;
	inRush: number;
	hold: number;
	
	feedback?: number;
	updateTime?: string;
}
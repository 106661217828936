/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Common.isGolfSite
 * 		 See: _rb.utils.ts
 */

import * as moment from 'moment';
import { CultureSettings } from '../../api/culture-settings/models/culture-settings.model';
import { EventLogEntry } from '../../api/event-logs/models/event-log-entry.model';
import { ExpiryState } from '../../api/license-api-cloud/models/expiry-state.model';
import { format } from 'date-fns';
import { MasterValveListItem } from '../../api/stations/models/master-valve-list-item.model';
import { RbConstants } from '../constants/_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from './_rb.utils';
import { Sensor } from '../../api/sensors/models/sensor.model';
import { SensorListItem } from '../../api/sensors/models/sensor-list-item.model';

export namespace XXUseRbUtilsNamespace {

	export abstract class Common {

		static isAdmin(groupLevel: number) {
			return (groupLevel >= RbEnums.Common.GroupLevel.PowerUser);
		}

		static isGolfSite(siteType: string) {
			return (siteType === RbConstants.Form.SITE_TYPE_GOLF);
		}

		/**
		 * Indicator for IQ4 standalone (IQ4 with hybrid capabilities) or cloud IQ4 (IQ4 without hybrid capabilities).
		 */
		static isCommercialHybridSite(siteType: string, isCloud: boolean) {
			return (siteType !== RbConstants.Form.SITE_TYPE_GOLF && !isCloud);
		}

		static isRBDev(groupLevel: number) {
			return (groupLevel >= 50);
		}

		static isUniversalController(type: number) {
			return (type === RbEnums.Common.DeviceType.LXIVM ||
					type === RbEnums.Common.DeviceType.LXIVMPlus ||
					type === RbEnums.Common.DeviceType.LXME2);
		}

		static getValidIQNetMasterValveSharing(currentMasterValve: MasterValveListItem, 
			masterValvesList: MasterValveListItem[],
			controllerType: RbEnums.Common.DeviceType): boolean {
				if (currentMasterValve && masterValvesList?.length > 0) {
					const ids = masterValvesList.map(({ id }) => id);
					const minId = Math.min(...ids);
					// For IVM-Pro, only Master Valves 1-5 can be shared IQNet 
					if (controllerType === RbEnums.Common.DeviceType.LXIVMPlus &&
						 currentMasterValve.id < minId + RbConstants.Form.MASTER_VALVE_MAX_SHARING_IVM_PRO) {
						return true;
					}
					// For LXME2, only Master Valves 1 can be shared IQNet
					if (controllerType === RbEnums.Common.DeviceType.LXME2 && currentMasterValve.id === minId) {
						return true;
					}
				}
				return false;
		}

		static getValidIQNetWeatherSensorSharing(currentSensor: Sensor, 
			sensorsList: SensorListItem[],
			controllerType: RbEnums.Common.DeviceType): boolean {
				if (currentSensor && sensorsList?.length > 0) {					
					const ids = sensorsList.map(({ id }) => id);
					const minId = Math.min(...ids);
					// For IVM-Pro, weather sensors Local, 1, 2, and 3 can be shared IQNet
					if (controllerType === RbEnums.Common.DeviceType.LXIVMPlus) {
						if (currentSensor.isLocal) return true;
						if (currentSensor.id < minId + RbConstants.Form.WEATHER_SENSOR_MAX_SHARING_IVM_PRO) return true;
					}
				}
				return false;
		}

		static isLxmeTypeController(type: number) {
			return (type === RbEnums.Common.DeviceType.LXME ||
					type === RbEnums.Common.DeviceType.LXME2);
		}

		static isESPMEController(type: number) {
			return (type === RbEnums.Common.DeviceType.ESPME3 ||
					type === RbEnums.Common.DeviceType.ESP2Wire);
		}

		static isTM2Controller(type: number) {
			return (type === RbEnums.Common.DeviceType.TM2_12Station ||
					type === RbEnums.Common.DeviceType.TM2_4Station ||
					type === RbEnums.Common.DeviceType.TM2_6Station ||
					type === RbEnums.Common.DeviceType.TM2_8Station);
		}

		static isLxivmPlusTypeController(type: number) {
			return (type === RbEnums.Common.DeviceType.LXIVMPlus);
		}

		static isNonUniversalController (type: number) {
			return (type === RbEnums.Common.DeviceType.LXME || 
				type === RbEnums.Common.DeviceType.LXD);
		}

		static isShowIQNetSharing(type: number, isAcceptBetaFeatures: boolean) {
			// RB-13624: IQ4 - Re-Enable MV/Sensor sharing on universal controllers
			return this.isNonUniversalController(type) || (this.isUniversalController(type) && isAcceptBetaFeatures);
		}

		static isHiddenEventLog(entry: EventLogEntry): boolean {
			switch (entry.eventNumber) {
				case RbEnums.Common.IQEvent.ControllerDataChangeMadeAtController1:
					return (entry.eventParameter1 === RbEnums.Common.Id.IrrigationStationSequence ||
						entry.eventParameter1 === RbEnums.Common.Id.UiLogicalDialRequested)
				default:
					return false;
			}
		}

		static pluckByKeyValue(key: string, val?: any) {
			return (arr: any[] = []) => {
				if (!arr) { return undefined; }
				return arr.find((item) => (item[key] === val));
			};
		}

		static currentDateWithMeridiemTime(duration: moment.Duration, meridiem: string): Date {
			const date = new Date();
			const twentyFourHour = duration.hours() === 12 ?
				(meridiem === 'PM' ? 12 : 0) :
				(meridiem === 'PM' ? duration.hours() + 12 : duration.hours());
			return new Date(date.getFullYear(), date.getMonth(), date.getDate(), twentyFourHour, duration.minutes(), duration.seconds());
		}

		static formatDurationForApi(duration: moment.Duration, meridiem: string): string {
			return format(Common.currentDateWithMeridiemTime(duration, meridiem), 'YYYY-MM-DDTHH:mm:ss');
		}

		static formatDateForApi(date: Date): string {
			return format(date, 'YYYY-MM-DDTHH:mm:ss');
		}

		/**
		 * This function is used to get a date object to show in the UI from an datetimeoffset string.
		 * The return date have exact the same date, time with the datetimeoffset. This won't do any conversion.
		 * @param dateWithOffsetStr datetimeoffset in ISO 8601 format, ex: 2023-04-06T11:54:03.0000000+02:00
		 * @returns date object with the same date time of, ex: dateWithOffsetStr = 2023-04-06T11:54:03.0000000
		 */
		static timeOffsetToDisplayTime(dateWithOffsetStr: string) : Date {
			const dateWithoutOffsetStr = dateWithOffsetStr.slice(0, -6);
			return moment(dateWithoutOffsetStr).toDate();
		}

		static timeOffsetToDisplayTimeDryRun(dateWithOffsetStr: string | Date) : Date {
			if (typeof(dateWithOffsetStr) === 'object') {
				return dateWithOffsetStr;
			}
			return this.timeOffsetToDisplayTime(dateWithOffsetStr);
		}

		/**
		 * Utility for rounding a duration (a run time, typically) to the nearest unit of time (seconds, or minutes).
		 * This avoids issues where the engine runs a station for an actual 4:59.940 but that time is displayed as
		 * 4:59 in the activity screen. Better to round to the nearest second and display 5:00.
		 * @param dur - moment.Duration to be rounded
		 * @param roundTo - moment.unitOfTime.DurationAs supporting only 's', 'second', 'seconds' and 'm', 'minute',
		 * 'minutes' in this implementation.
		 * @returns new moment.Duration value rounded as directed
		 */
		static roundDurationTo(dur: moment.Duration, roundTo: moment.unitOfTime.DurationAs): moment.Duration {
			switch (roundTo) {
				case 's':
				case 'second':
				case 'seconds':
					dur = moment.duration(dur.asMilliseconds() + 500);
					break;
				case 'm':
				case 'minute':
				case 'minutes':
					dur = moment.duration(dur.asSeconds() + 30);
					break;
				default:
					throw new Error(`Attempted to use roundDurationTo with invalid roundTo type "${roundTo}"`);
			}

			return dur;
		}

		static replaceCharInStringAtIndex(string, index, replace) {
			return string.substring(0, index) + replace + string.substring(index + 1);
		}

		/**
		 * Simulate roughly what String.IsNullOrWhiteSpace() does in .NET. If the string is null or contains only whitespace
		 * characters (or is empty), return true; otherwise false.
		 * @param string - string which will be checked for null, whitespace.
		 * @returns boolean - true if null, empty or whitespace; false otherwise.
		 */
		static isNullOrWhiteSpace(str: string) {
			return (str == null || str.match(/^\s*$/) !== null);
		}

		static formatGroupDecimalSeparator(value: string, separator: string, precision = 2) {
			if (!precision) return value;
			return value.substring(0, value.length - precision) + separator +
				value.substring(value.length - precision);
		}

		/**
		 * Return the precision (number of decimal places) for flow/rate values, based on the user's selected culture settings/
		 * units system. NOTE: THIS IS REALLY INTENDED FOR FLOW MANAGEMENT ENTRIES, NOT FOR STATIONS. IT IS USED FOR MAX DRYRUN
		 * FLOWS FOR CONTROLLERS, PUMP AND GOLF FlowElement CAPACITY VALUES, ETC. DON'T USE IT FOR STATIONS AS IT DOESN'T HAVE
		 * ENOUGH RESOLUTION FOR STANDARD CATALOG FLOW RATES THERE.
		 * @param cultureSettings - CultureSettings specifying user-selected units system
		 * @returns number of decimal places expected for display of these flow values
		 */
		static getPrecisionForFlow(cultureSettings: CultureSettings) {
			if (cultureSettings.unitType === RbEnums.Common.UnitsType.English ||
				cultureSettings.unitType === RbEnums.Common.UnitsType.Metric3)
				return 0;
			else if (cultureSettings.unitType === RbEnums.Common.UnitsType.Metric1)
				return 2;
			else if (cultureSettings.unitType === RbEnums.Common.UnitsType.Metric2)
				return 1;
		}

		static convertMinFlowToUserCulture(cultureSettings: CultureSettings, value: number, precision = 2) {
			const flow = this.ToFlow(value, RbEnums.Common.UnitsType.English, cultureSettings.unitType);
			return Math.floor(flow + 0.9999).toString();
		}

		static convertMaxFlowToUserCulture(cultureSettings: CultureSettings, value: number, precision = 2) {
			const flow = this.ToFlow(value, RbEnums.Common.UnitsType.English, cultureSettings.unitType);
			return Math.floor(flow).toString();
		}

		static convertFlowToUserCulture(cultureSettings: CultureSettings, value: number, precision = 2) {
			const flow = this.ToFlow(value, RbEnums.Common.UnitsType.English, cultureSettings.unitType);
			return this.convertValueToPrecision(cultureSettings.decimalSeparator, flow.toString(), precision);
		}

		static convertVolumeToUserCulture(cultureSettings: CultureSettings, value: number, precision = 2) {
			const flow = this.ToVolume(value, RbEnums.Common.UnitsType.English, cultureSettings.unitType);
			return this.convertValueToPrecision(cultureSettings.decimalSeparator, flow.toString(), precision);
		}

		static convertFlowToUnitType(cultureSettings: CultureSettings, unitType: RbEnums.Common.UnitsType, value: number, multipler: number, precision = 2) {
			const flow = this.ToFlow(value, RbEnums.Common.UnitsType.English, unitType) * multipler;
			return this.convertValueToPrecision(cultureSettings.decimalSeparator, flow.toString(), precision);
		}

		/**
		 * Convert the indicated flow value into the standard API units (gpm). Optionally use a given number of significant
		 * digits.
		 * @param value - number specifying the flow rate in the user's units system
		 * @param unitFrom - RbEnums.Common.UnitsType which the user is employing to get the value above
		 * @param precision - (nullable) number specifying how many decimal places should be retaining in the incoming value
		 * BEFORE conversion to API units. If null, no trimming is done. (default = null)
		 * @returns number containing the flow value in gpm units, optionally with the indicated number of significant taken
		 * from the original value before conversion
		 */
		static convertFlowToSaveData(value: number, unitFrom: RbEnums.Common.UnitsType, precision: number = null) {
			let digits = value.toString().replace(/\D/g, '.');
			if (precision != null) {
				// RB-9410: Adjust the number of significant digits based on the parameter. This is done in the user's units
				// system for best results. This uses rounding to the nearest value.
				digits = Number(digits).toFixed(precision);
			}
			const flow = this.ToFlow(Number(digits), unitFrom, RbEnums.Common.UnitsType.English);
			return flow;
		}

		static convertVolumeToSaveData(value: number, unitFrom: RbEnums.Common.UnitsType, precision: number = null) {
			let digits = value.toString().replace(/\D/g, '.');
			if (precision != null) {
				digits = Number(digits).toFixed(precision);
			}
			const flow = this.ToVolume(Number(digits), unitFrom, RbEnums.Common.UnitsType.English);
			return flow;
		}

		static roundToPrecision(value, precision, roundUp: boolean): number {
			const multiplier = Math.pow(10, precision || 0);
			const increment = roundUp ? 0.99999999 : 0;
			return Math.floor(value * multiplier + increment) / multiplier;
		}

		static ToFlow(value: number, unitFrom: RbEnums.Common.UnitsType, unitTo: RbEnums.Common.UnitsType) {
			if (unitFrom === unitTo) {
				return value;
			}
			switch (unitFrom) {
				case RbEnums.Common.UnitsType.Metric1:
					switch (unitTo) {
						case RbEnums.Common.UnitsType.Metric2:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.litersPerSecondToCubicMetersPerHour;
						case RbEnums.Common.UnitsType.Metric3:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.litersPerSecondToLitersPerMin;
						case RbEnums.Common.UnitsType.English:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.litersPerSecondToGallonsPerMin;
					}

				case RbEnums.Common.UnitsType.Metric2:
					switch (unitTo) {
						case RbEnums.Common.UnitsType.Metric1:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.cubicMetersPerHourToLitersPerSecond;
						case RbEnums.Common.UnitsType.Metric3:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.cubicMetersPerHourToLitersPerMin;
						case RbEnums.Common.UnitsType.English:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.cubicMetersPerHourToGallonsPerMin;
					}

				case RbEnums.Common.UnitsType.Metric3:
					switch (unitTo) {
						case RbEnums.Common.UnitsType.Metric1:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.litersPerMinToLitersPerSecond;
						case RbEnums.Common.UnitsType.Metric2:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.litersPerMinToCubicMetersPerHour;
						case RbEnums.Common.UnitsType.English:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.litersPerMinToGallonsPerMin;
					}

				case RbEnums.Common.UnitsType.English:
				default:
					switch (unitTo) {
						case RbEnums.Common.UnitsType.Metric1:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.gallonsPerMinToLitersPerSecond;
						case RbEnums.Common.UnitsType.Metric2:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.gallonsPerMinToCubicMetersPerHour;
						case RbEnums.Common.UnitsType.Metric3:
							return value * RbConstants.Form.UNITS_OF_MEASURE_FLOW.gallonsPerMinToLitersPerMin;
						default:
							return value;
					}
			}
		}

		static ToVolume(value: number, unitFrom: RbEnums.Common.UnitsType, unitTo: RbEnums.Common.UnitsType) {
			if (unitFrom === unitTo) return value;

			if (unitFrom === RbEnums.Common.UnitsType.Metric1 || unitFrom === RbEnums.Common.UnitsType.Metric3) {
				switch (unitTo) {
					case RbEnums.Common.UnitsType.Metric1:
					case RbEnums.Common.UnitsType.Metric3:
						return value;
					case RbEnums.Common.UnitsType.Metric2:
						return value * RbConstants.Form.UNITS_OF_MEASURE_VOLUME.litersToCubicMeters;
					case RbEnums.Common.UnitsType.English:
						return value * RbConstants.Form.UNITS_OF_MEASURE_VOLUME.litersToGallons;
				}
			}

			if (unitFrom === RbEnums.Common.UnitsType.Metric2) {
				switch (unitTo) {
					case RbEnums.Common.UnitsType.Metric1:
					case RbEnums.Common.UnitsType.Metric3:
						return value * RbConstants.Form.UNITS_OF_MEASURE_VOLUME.cubicMetersToLiters;
					case RbEnums.Common.UnitsType.English:
						return value * RbConstants.Form.UNITS_OF_MEASURE_VOLUME.cubicMetersToGallons;
				}
			}

			if (unitFrom === RbEnums.Common.UnitsType.English) {
				switch (unitTo) {
					case RbEnums.Common.UnitsType.Metric1:
					case RbEnums.Common.UnitsType.Metric3:
						return value * RbConstants.Form.UNITS_OF_MEASURE_VOLUME.gallonsToLiters;
					case RbEnums.Common.UnitsType.Metric2:
						return value * RbConstants.Form.UNITS_OF_MEASURE_VOLUME.gallonsToCubicMeters;
				}
			}

			throw new Error('Invalid Conversion');
		}

		static convertValueToPrecision(decimalSeparator: string, value: string, precision = 2): string {
			if (value === decimalSeparator) return '0';
			const digits = (decimalSeparator === RbConstants.Form.COMMA_SEPARATOR) ?
				value.toString().replace(/[^0-9\-]/g, RbConstants.Form.DECIMAL_SEPARATOR) : value;
			const currentPrecision = digits.indexOf(RbConstants.Form.DECIMAL_SEPARATOR) === -1 ? 0 :
				digits.length - digits.indexOf(RbConstants.Form.DECIMAL_SEPARATOR) - 1;
			value = currentPrecision === precision ? digits : Number(digits).toFixed(precision).toString();

			return this.convertDecimalSeparator(value, decimalSeparator);
		}

		static convertDecimalSeparator(value: any, decimalSeparator: string = null): string {
			if (decimalSeparator == null) 
				decimalSeparator = RbUtils.User.cultureSettings.decimalSeparator;
			
			return (decimalSeparator === RbConstants.Form.DECIMAL_SEPARATOR) 
			? value 
			: value.toString().replace(/[^0-9\-]/g, decimalSeparator);
		}

		static convertMaxLengthToUserCulture(cultureSettings: CultureSettings, value: number, unitFrom: RbEnums.Common.LengthUnit,
											unitTo: RbEnums.Common.LengthUnit, precision = 2) {
			const length = this.ToLength(value, unitFrom, unitTo);
			return Math.floor(length).toString();
		}

		static convertLengthToUserCulture(cultureSettings: CultureSettings, value: number, unitFrom: RbEnums.Common.LengthUnit,
			precision = 1) {
				let unitTo = RbEnums.Common.LengthUnit.Millimeter;
			if (cultureSettings.unitType === RbEnums.Common.UnitsType.English)
				unitTo = RbEnums.Common.LengthUnit.Inch;
			const length = this.ToLength(value, unitFrom, unitTo);
			return this.convertValueToPrecision(cultureSettings.decimalSeparator, length.toString(), precision);
		}

		static convertHeightToUserCulture(cultureSettings: CultureSettings, value: number, unitFrom: RbEnums.Common.LengthUnit,
			precision = 1) {
			let unitTo = RbEnums.Common.LengthUnit.Meter;
			if (cultureSettings.unitType === RbEnums.Common.UnitsType.English)
				unitTo = RbEnums.Common.LengthUnit.Foot;
			const length = this.ToLength(value, unitFrom, unitTo);
			return this.convertValueToPrecision(cultureSettings.decimalSeparator, length.toString(), precision);
		}

		// Save in Linear measure (English) in Database. For ex: kilometer -> mile, meter -> foot, millimeter -> inch, centimeter -> inch
		static convertLengthToSaveData(value: number, unitFrom: RbEnums.Common.LengthUnit, unitTo: RbEnums.Common.LengthUnit) {
			const digits = value && value.toString().replace(/\D!-/g, '.');
			const flow = this.ToLength(Number(digits), unitFrom, unitTo);
			return flow;
		}

		static convertTemperature(value: number, cultureSettings: CultureSettings) {
			if (cultureSettings.unitType !== RbEnums.Common.UnitsType.English) {
				return (value - 32) * 5/9;
			}

			return value;
		}

		static ToLength(value: number, unitFrom: RbEnums.Common.LengthUnit, unitTo: RbEnums.Common.LengthUnit) {
			if (unitFrom === unitTo) {
				return value;
			}
			switch (unitFrom) {
				case RbEnums.Common.LengthUnit.Foot:
					switch (unitTo) {
						case RbEnums.Common.LengthUnit.Meter:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_LENGTH.footToMeter;
					}

				case RbEnums.Common.LengthUnit.Mile:
					switch (unitTo) {
						case RbEnums.Common.LengthUnit.Kilometer:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_LENGTH.mileToKilometer;
					}

				case RbEnums.Common.LengthUnit.Meter:
					switch (unitTo) {
						case RbEnums.Common.LengthUnit.Foot:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_LENGTH.meterToFoot;
					}
				case RbEnums.Common.LengthUnit.Millimeter:
					switch (unitTo) {
						case RbEnums.Common.LengthUnit.Inch:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_LENGTH.millimeterToInch;
					}
				case RbEnums.Common.LengthUnit.Kilometer:
					switch (unitTo) {
						case RbEnums.Common.LengthUnit.Mile:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_LENGTH.kilometerToMile;
					}
				case RbEnums.Common.LengthUnit.Centimeter:
					switch (unitTo) {
						case RbEnums.Common.LengthUnit.Inch:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_LENGTH.centimeterToInch;
					}
				case RbEnums.Common.LengthUnit.Inch:
				default:
					switch (unitTo) {
						case RbEnums.Common.LengthUnit.Centimeter:
							return value * RbConstants.Form.UNITS_OF_MEASURE_LENGTH.inchToCentimeter;
						case RbEnums.Common.LengthUnit.Millimeter:
							return value * RbConstants.Form.UNITS_OF_MEASURE_LENGTH.inchToMillimeter;
						default:
							return value;
					}
			}
		}

		/**
		 * Simple utility for getting the string representation of the indicated length units
		 * @param units - RbEnums.Common.LengthUnit specifying the units system for length
		 * @returns string containing the units string
		 */
		static getLengthUnitsString(units: RbEnums.Common.LengthUnit): string {
			switch (units) {
				case RbEnums.Common.LengthUnit.Millimeter:
					return RbUtils.Translate.instant('UNIT_TYPE.MILLIMETER');
				case RbEnums.Common.LengthUnit.Inch:
					return RbUtils.Translate.instant('UNIT_TYPE.INCH');
				case RbEnums.Common.LengthUnit.Centimeter:
					return RbUtils.Translate.instant('UNIT_TYPE.CENTIMETER');
				case RbEnums.Common.LengthUnit.Foot:
					return RbUtils.Translate.instant('UNIT_TYPE.FOOT');
				case RbEnums.Common.LengthUnit.Kilometer:
					return RbUtils.Translate.instant('UNIT_TYPE.KILOMETER');
				case RbEnums.Common.LengthUnit.Mile:
					return RbUtils.Translate.instant('UNIT_TYPE.MILE');
				case RbEnums.Common.LengthUnit.Meter:
				default:
					return RbUtils.Translate.instant('UNIT_TYPE.METER');
			}
		}

		static convertMaxPressureToUserCulture(cultureSettings: CultureSettings, value: number, precision = 2) {
			const pressure = this.ToPressure(value, RbEnums.Common.UnitsType.English, cultureSettings.unitType);
			return Math.floor(pressure).toString();
		}

		static convertPressureToUserCulture(cultureSettings: CultureSettings, value: number, precision = 2) {
			const flow = this.ToPressure(value, RbEnums.Common.UnitsType.English, cultureSettings.unitType);
			return this.convertValueToPrecision(cultureSettings.decimalSeparator, flow.toString(), precision);
		}

		static convertPressureToSaveData(value: any, unitFrom: RbEnums.Common.UnitsType) {
			const digits = value.toString().replace(/\D/g, '.');
			const flow = this.ToPressure(Number(digits), unitFrom, RbEnums.Common.UnitsType.English);
			return flow;
		}

		static ToPressure(value: number, unitFrom: RbEnums.Common.UnitsType, unitTo: RbEnums.Common.UnitsType) {
			if (unitFrom === unitTo) {
				return value;
			}
			switch (unitFrom) {
				case RbEnums.Common.UnitsType.Metric1:
				case RbEnums.Common.UnitsType.Metric2:
				case RbEnums.Common.UnitsType.Metric3:
					switch (unitTo) {
						case RbEnums.Common.UnitsType.English:
						default:
							return value * RbConstants.Form.UNITS_OF_MEASURE_PRESSURE.barToPsi;
					}

				case RbEnums.Common.UnitsType.English:
				default:
					switch (unitTo) {
						case RbEnums.Common.UnitsType.Metric1:
						case RbEnums.Common.UnitsType.Metric2:
						case RbEnums.Common.UnitsType.Metric3:
							return value * RbConstants.Form.UNITS_OF_MEASURE_PRESSURE.psiToBar;
						default:
							return value;
					}
			}
		}

		/**
		 * Calculate the precipitation rate from the flow rate and area, performing some units conversion in the process.
		 * @param flowRate - number expressed in GPM, gallons per minute
		 * @param area - number expressed in square feet, ft^2
		 * @returns precipitation rate expressed in inches per hour, in/h
		 */
		static calculatePrecipitationRateFromArea(flowRate: number, area: number): number {
			// Formula comes from CLIA manual, Appendix F: Formulas and Tables, p153 (Sept 2004 edition)
			// PR = 96.3 * fpm / A

			// The 96.3 constant is just the unit conversion from gal/(min * sq ft) into in/h
			// gal/(min*ft^2) * 60m/h * 1ft^3/ 7.48051948g * 12in/ft = 96.25 in/h
			// 96.250000006684027778241946373489 in/h
			const k = 60.0 * 12.0 / 7.48051948;

			const pr = k * flowRate / area;
			return pr;
		}

		/**
		 * Calculate the precip rate for a head based on a variety of parameters.
		 * @param flowRate - flow rate of the head in GPM
		 * @param headRadius - radius of the head throw in ft
		 * @param rowRadius - spacing from emitter to emitter along the row
		 * @param arc - arc across which the emitter throws
		 * @param isSquareNozzlePattern - true if the emitter has a square pattern, requiring a different area calculation;
		 * false for most scenarios.
		 * @param isSingleRowPattern - true if single-row calculations should be applied, ignoring the row radius and using
		 * the head radius in a slightly different calculation than square or triangular.
		 * @returns precip rate in in/hr
		 */
		static calculatePrecipitationRateFromRadius(flowRate: number, headRadius: number, rowRadius: number, 
			arc: number, isSquareNozzlePattern: boolean, isSingleRowPattern: boolean): number {

			const area = isSquareNozzlePattern ? 
				2 * headRadius * 2 * rowRadius * arc / 360.0 : 
				isSingleRowPattern ? 
					headRadius /* S */ * (headRadius * 2) /* Dia */ * 0.8 * arc / 360.0 :
					headRadius * rowRadius * arc / 360.0;

			return this.calculatePrecipitationRateFromArea(flowRate, area);
		}

		static pad(num: number, digits: number = 2) {
			const padding = '0'.repeat(digits);

			const paddedNum = padding + num;
			return paddedNum.substr(paddedNum.length - digits);
		}

		static getTimePickerFormat(cultureSettings: CultureSettings) {
			return (cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm) ?
				RbConstants.Form.TIME_FORMAT.amPm : RbConstants.Form.TIME_FORMAT.hours;
		}

		static getTimePickerInitialFormat(cultureSettings: CultureSettings) {
			return (cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm) ?
				RbConstants.Form.TIME_FORMAT_OPTIONS.amPm : RbConstants.Form.TIME_FORMAT_OPTIONS.hours;
		}

		static getTimePickerDisplayValue(cultureSettings: CultureSettings, includeSeconds?: boolean) {
			if (includeSeconds) {
				return (cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm) ?
					RbConstants.Form.TIME_FORMAT_WITH_SECONDS_DISPLAY_OPTIONS.amPm : RbConstants.Form.TIME_FORMAT_WITH_SECONDS_DISPLAY_OPTIONS.hours;
			} else {
				return (cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm) ?
					RbConstants.Form.TIME_FORMAT_DISPLAY_OPTIONS.amPm : RbConstants.Form.TIME_FORMAT_DISPLAY_OPTIONS.hours;
			}
		}

		static getCultureFormat(timeString: any, cultureSettings: CultureSettings) {
			return (cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm) ?
				this.convertTimeStringToDate(timeString) : moment(timeString, 'HH:mm:ss');
		}

		static convertTimeStringToDate(timeString: string): Date {
			const m = moment(timeString, 'HH:mm:ss');
			const duration = moment.duration({ hours: m.hours(), minutes: m.minutes() });
			const timeStringLower = timeString.toLowerCase();
			if (timeStringLower.includes('am') || timeStringLower.includes('pm'))
				return Common.currentDateWithMeridiemTime(duration, RbConstants.Form.MERIDIEM_OPTIONS[timeStringLower.includes('am') ? 0 : 1]);
			else {
				const date = new Date();
				return new Date(date.getFullYear(), date.getMonth(), date.getDate(), duration.hours(), duration.minutes(), duration.seconds());
			}
		}

		static getDateFormat(cultureSettings: CultureSettings) {
			switch (cultureSettings.dateFormatId) {
				case RbEnums.Common.DateFormat.MMDDYYYY_Dash:
					return RbConstants.Form.DATE_FORMAT.MMDDYYYY_Dash;
				case RbEnums.Common.DateFormat.MMDDYYYY_Slash:
					return RbConstants.Form.DATE_FORMAT.MMDDYYYY_Slash;
				case RbEnums.Common.DateFormat.MMDDYYYY_Dot:
					return RbConstants.Form.DATE_FORMAT.MMDDYYYY_Dot;
				case RbEnums.Common.DateFormat.DDMMYYYY_Dash:
					return RbConstants.Form.DATE_FORMAT.DDMMYYYY_Dash;
				case RbEnums.Common.DateFormat.DDMMYYYY_Slash:
					return RbConstants.Form.DATE_FORMAT.DDMMYYYY_Slash;
				case RbEnums.Common.DateFormat.DDMMYYYY_Dot:
					return RbConstants.Form.DATE_FORMAT.DDMMYYYY_Dot;
				default:
					return RbConstants.Form.DATE_FORMAT.YYYYMMDD_Dot;
			}
		}

		static getTimeFormat(cultureSettings: CultureSettings) {
			switch (cultureSettings.timeFormatId) {
				case RbEnums.Common.TimeFormat.AmPm:
					return RbConstants.Form.TIME_FORMAT_DISPLAY_OPTIONS.amPm;
				case RbEnums.Common.TimeFormat.Hours:
					return RbConstants.Form.TIME_FORMAT_DISPLAY_OPTIONS.hours;
				default:
					return RbConstants.Form.TIME_FORMAT_DISPLAY_OPTIONS.amPm;
			}
		}

		static getTimeWithSecondsFormat(cultureSettings: CultureSettings) {
			switch (cultureSettings.timeFormatId) {
				case RbEnums.Common.TimeFormat.AmPm:
					return RbConstants.Form.TIME_FORMAT_WITH_SECONDS_DISPLAY_OPTIONS.amPm;
				case RbEnums.Common.TimeFormat.Hours:
					return RbConstants.Form.TIME_FORMAT_WITH_SECONDS_DISPLAY_OPTIONS.hours;
				default:
					return RbConstants.Form.TIME_FORMAT_WITH_SECONDS_DISPLAY_OPTIONS.amPm;
			}
		}

		static scrollElementIntoView(elementId: string, scrollSmoothly = false) {
			const el = document.getElementById(elementId);
			if (el != null) el.scrollIntoView(!scrollSmoothly ? {} : { behavior: 'smooth' });
		}

		static compareBoolean(a: boolean, b: boolean) {
			return (a === b) ? 0 : (a ? -1 : 1);
		}

		static randomNumber(min: number, max: number) {
			return Math.floor((Math.random() * (max - min + 1)) + min);
		}
		static convertDateToTimeString(cultureSettings: CultureSettings, date:  Date) {
			return moment(date).format(Common.getTimeFormat(cultureSettings));
		}

		static convertDateTimeToString(cultureSettings: CultureSettings, dateTime: Date, includeSeconds?: boolean): string {
			return moment(dateTime).format(this.getDateTimeString(cultureSettings, includeSeconds));
		}

		static getDateTimeString(cultureSettings: CultureSettings, includeSeconds?: boolean): string {
			const timeDisplayFormat = RbUtils.Common.getTimePickerDisplayValue(cultureSettings, includeSeconds);
			const dateFormat = RbUtils.Common.getDateFormat(cultureSettings);
			
			return dateFormat + ' ' + timeDisplayFormat;
		}

		static getTextWidth(text: string, font: string): number {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			ctx.font = font;
			return ctx.measureText(text).width;
		}
		static setCharAt(str, index, chr) {
			if ( index > str.length - 1) return str;
			return str.substr(0, index) + chr + str.substr(index + 1);
		}

		static getUpdateObjectFromItemsChanged(itemsChanged: any): any {
			if (itemsChanged == null) return {};

			const obj = {};
			if (itemsChanged.patch == null) {
				// Just a list of property/value pairs int he itemsChanged object
				Object.assign(obj, itemsChanged);
				return obj;
			}

			if (Array.isArray(itemsChanged.patch)) {
				// Formal JSON patch structure needs to be parsed
				itemsChanged.patch.forEach(patch => {
					// Build up the property.subProperty1.subProperty2 structure based on the "path" in the JSON patch object
					const pathItems = patch.path.split('/').filter(item => item.length > 0);
					let elem = obj;
					for (let iItem = 0; iItem < pathItems.length - 1; iItem++) {
						elem[pathItems[iItem]] = {};
						elem = elem[pathItems[iItem]];
					}
					elem[pathItems[pathItems.length - 1]] = patch.value;
				});
			} else {
				// Just a list of property/value pairs int he itemsChanged.patch object
				Object.assign(obj, itemsChanged.patch);
			}
			return obj;
		}

		static isRunningLocally() {
			const localDomains = ['localhost', '127.0.0.1'];
			if (localDomains.includes(window.location.hostname)) {
				return true;
			}
			return false;
		}

		static replaceWhitespace(targetString, whitespaceReplacement) {
			const replaceWithString = whitespaceReplacement || '';
			return targetString ? targetString.toString().replace(/\s/g, replaceWithString) : '';
		}

		static isGprsExpried(expiryState: ExpiryState) {
			return expiryState.expiryState !== RbConstants.Form.GPRS_LICENSE.not_expired;
		}

		static isSupportedWeatherSourceForCalendar(type: any) {
			return (type === RbEnums.Common.WeatherSourceType.GlobalWeather
				|| type === RbEnums.Common.WeatherSourceType.GlobalWeatherPro
				|| type === RbEnums.Common.WeatherSourceType.Cimis
				|| type === RbEnums.Common.WeatherSourceType.WsProLT
				|| type === RbEnums.Common.WeatherSourceType.WsPro2);
		}

		static isSupportedWeatherSourceForEditHighestAvgET(type: any) {
			return (type === RbEnums.Common.WeatherSourceType.Cimis
				|| type === RbEnums.Common.WeatherSourceType.WsProLT
				|| type === RbEnums.Common.WeatherSourceType.WsPro2);
		}

		static isGlobalWeatherSourceType(type: number) {
			return (type === RbEnums.Common.WeatherSourceType.GlobalWeather
				|| type === RbEnums.Common.WeatherSourceType.GlobalWeatherPro);
		}

		static isSameTime(date1: Date, date2: Date): boolean {
			return (date1.getHours() === date2.getHours() &&
				date1.getMinutes() === date2.getMinutes() &&
				date1.getSeconds() === date2.getSeconds());
		}

		static convertToCommaNumber(cultureSettings: CultureSettings, value: number) {
			if (cultureSettings.decimalSeparator === RbConstants.Form.COMMA_SEPARATOR) {
				return `${value}`.replace(/\./, ',');
			}
			return `${value}`;
		}

		static convertToNumber(cultureSettings: CultureSettings, value: string) {
			if (cultureSettings.decimalSeparator === RbConstants.Form.COMMA_SEPARATOR) {
				return +`${value}`.replace(/\,/, '.');
			}
			return +`${value}`;
		}

		static userCultureLengthType(cultureSettings: CultureSettings) {
			return cultureSettings.unitType === RbEnums.Common.UnitsType.English
			? RbEnums.Common.LengthUnit.Inch
			: RbEnums.Common.LengthUnit.Millimeter;
		}

		static convertAdvancedETValueToUserCulture(cultureSettings: CultureSettings, value: number, precision = 3) {
			return +RbUtils.Common.convertLengthToSaveData(value, RbEnums.Common.LengthUnit.Inch,
				this.userCultureLengthType(cultureSettings)).toFixed(precision);
		}

		static convertAdvancedETDataToDisplay(cultureSettings: CultureSettings, value: any) {
			return RbUtils.Common.convertToCommaNumber(cultureSettings, value);
		}

		static convertAdvancedETValueToSaveData(cultureSettings: CultureSettings, value: string) {
			return RbUtils.Common.convertLengthToSaveData(
				RbUtils.Common.convertToNumber(cultureSettings, value), this.userCultureLengthType(cultureSettings), RbEnums.Common.LengthUnit.Inch);
		}

		static convertAdvancedETDateTime(cultureSettings: CultureSettings, value: Date) {
			const timeFormat = RbUtils.Common.getTimePickerDisplayValue(cultureSettings);
			const dateFormat = RbUtils.Common.getDateFormat(cultureSettings);
			return RbUtils.Date.transform(value, `${dateFormat} ${timeFormat}`);
		}

		// this will create a new UTC+0 Date from selected local date, 
		// the returned UTC date will have the same date, month, year with the local date
		static createDateAsAConstantToSave(date: Date) {
			const newDate = moment.utc();
			newDate.set('year', date.getFullYear());
			newDate.set('month', date.getMonth());
			newDate.set('date', date.getDate());
			newDate.startOf('day');
			return newDate.toDate();
		}

		// this will create a new local Date from UTC+0 date, 
		// the returned local date will have the same date, month, year with the utc+0 date
		static createDateAsAConstantToShow(utcDate: moment.Moment) {
			return new Date (utcDate.year(), utcDate.month() , utcDate.date());
		}
		// Accepts the array and key
		static groupBy = (array, key) => {
			// Return the end result
			return array.reduce((result, currentValue) => {
				// If an array already present for key, push it to the array. Else create an array and push the object
				(result[currentValue[key]] = result[currentValue[key]] || []).push(
					currentValue
				);
				// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
				return result;
			}, {}); // empty object is the initial value for result object
		};

		
		/**
		 * Return true if the country code is in the list of European Union
		 */
		static isEuropeanUnionCountry(countryCode: string): boolean {
			if (!countryCode) {
				return false;
			}
			return RbConstants.Common.EUROPEAN_UNION_CODES.includes(countryCode);
		}
	}
}

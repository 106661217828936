export class StoreTransactionToken
{
	token: string;

	constructor(json?: any) {
		if (json) {
			Object.assign(this, json);
		}
	}
}

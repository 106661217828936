import { RbEnums } from '../../../common/enumerations/_rb.enums';

import SourceState = RbEnums.Common.SourceState;

export class AcCurrentTestOptions {

	constructor(pathToTest: number, sourceState: SourceState) {
		this.pathToTest = pathToTest;
		this.setSourceState = sourceState;
	}

	setSourceState: SourceState;
	pathToTest: number;

}

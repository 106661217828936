import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';

@Component({
	selector: 'rb-sprinkler-type-mobile-cell-renderer',
	templateUrl: './sprinkler-type-mobile-cell-renderer.component.html',
	styleUrls: ['./sprinkler-type-mobile-cell-renderer.component.scss']
})
export class SprinklerTypeMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	sprinklerType: { name: string };			// TODO: MW - Use real data type

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.sprinklerType = params.data;
	}
}

import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { startWith, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { FormControl } from '@angular/forms';
import { HyperlinkCellRendererComponent } from '../tables/cell-renderers/hyperlink-cell-renderer/hyperlink-cell-renderer.component';
import { map } from 'rxjs/internal/operators/map';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ProgramIdentifier } from '../../../api/stations/models/program-identifier.model';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Router } from '@angular/router';
import { StationListItem } from '../../../api/stations/models/station-list-item.model';
import { StationManagerService } from '../../../api/stations/station-manager.service';
import { StationPrograms } from '../../../api/stations/models/station-programs.model';
import {
	StationSearchMobileCellRendererComponent
} from '../tables/mobile-cell-renderers/station-search-mobile-cell-renderer/station-search-mobile-cell-renderer.component';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'rb-station-search',
	templateUrl: './station-search.component.html',
	styleUrls: ['./station-search.component.scss']
})
export class StationSearchComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'rb-station-search';
	@ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoCompleteInput: MatAutocompleteTrigger;

	// Cell Renderers
	HyperlinkCellRenderer = HyperlinkCellRendererComponent;
	StationSearchMobileCellRendererComponent = StationSearchMobileCellRendererComponent;

	@Input() parentControllerId: number;
	@Input() stationId: number;
	@Input() station: any;

	stationFilter = new FormControl();
	filteredOptions: Observable<any[]>;
	initPanel = true;
	isGolfSite = false;
	isGridDataLoaded = false;
	isGridDisplayable = false;
	showWaitingIndicator = true;
	waitIndicatorText = '';
	loadError = '';
	resultsStationName = '--';
	stations: StationListItem[] = [];
	rowData: ProgramIdentifier[];
	dialogTitle: string;
	currentStationId: number;

	private _isMobile = false;
	
	set isMobile(value: boolean) {
		this._isMobile = value;
		this.class = value ? 'rb-station-search isMobile' : 'rb-station-search';
	}

	get isMobile(): boolean {
		return this._isMobile;
	}

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private deviceManager: DeviceManagerService,
				private dialog: MatDialog,
				private router: Router,
				private stationManager: StationManagerService,
				private translateService: TranslateService
	) { }

	ngOnInit() {
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		this.dialogTitle = this.isGolfSite ? 'STRINGS.PROGRAMS_AND_SCHEDULES' : 'STRINGS.PROGRAMS';
		this.isMobile = this.deviceManager.isMobile;

		// Monitor mobile resizing
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => this.isMobile = isMobile);

		this.filteredOptions = this.stationFilter.valueChanges
			.pipe(
				startWith(''),
				map(value => (typeof value === 'string' ? value : value.name)),
				map(name => (name ? this._filter(name) : this.stations.slice()))
			);

		this.getComponentData();
	}

	ngOnDestroy() {
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onClose() {
		this.dialog.closeAll();
	}

	// Find station name which belongs to program or schedule
	// Depend on the input value to find so it could be used for string or StationListItem object.
	onFind() {
		const noStations: number[] = [];
		if (this.station != null && this.station.name === undefined) {
			const stationsList = this._filter(this.station);
			this.resultsStationName = this.station;
			if (stationsList.length === 0) {
				this.getStationPrograms(noStations);
			} else {
				let currentStation: any;
				[currentStation] = stationsList;
				this.currentStationId = currentStation.id;
				this.getStationPrograms([this.currentStationId]);
			}
		} else {
			this.resultsStationName = this.station.name !== undefined ? this.station.name : '';
			this.getStationPrograms([this.stationId]);
		}
	}

	displayFn(option: any): string {
		return option && option.name ? option.name : '';
	}

	onPanelOpened() {
		if (this.initPanel) {
			this.autoCompleteInput.closePanel();
			this.initPanel = false;
		}
	}

	onOptionSelected(event: MatAutocompleteSelectedEvent) {
		this.stationId = event.option.value.id;
	}

	onProgramLinkClick(data: ProgramIdentifier) {
		// RB-12829: We MUST close this dialog before rerouting to the program/schedule page or the routing will not work throwing
		// an exception.
		this.onClose();

		const route = this.isGolfSite ? '/programs/programgroups/edit' : '/programs/programsteps';
		const params = this.isGolfSite ? { programGroupId: data.programGroupId } : { programId: data.programId };
		this.router.navigate([route], { queryParams: params });
	}

	onScheduleLinkClick(data: ProgramIdentifier) {
		// RB-12829: We MUST close this dialog before rerouting to the program/schedule page or the routing will not work throwing
		// an exception.
		this.onClose();

		this.router.navigate(['/programs/programgroups/editprogram'], {
			queryParams: {
				programGroupId: data.programGroupId,
				programId: data.programId
			}
		});
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getComponentData() {
		this.stationManager.getStationsList(this.parentControllerId)
			.pipe(take(1))
			.subscribe({
				next: (stations: StationListItem[]) => {
					this.stations = stations;
					this.validateAndSetSearchValue(true);
					this.showWaitingIndicator = false;
				},
				error: (err) => {
					this.loadError = err.error || err.message || this.translateService.instant('STRINGS.NETWORK_ERROR_RETRY');
				}
			});
	}

	private getStationPrograms(stationIds: number[]) {
		this.showWaitingIndicator = true;

		this.stationManager.getStationPrograms(stationIds)
			.pipe(take(1))
			.subscribe({
				next: (stationPrograms: StationPrograms[]) => {
					this.showWaitingIndicator = false;
					this.rowData = [];
					this.hideGrid();

				if (stationPrograms == null || stationPrograms.length < 1
					|| stationPrograms[0].programReferences == null || stationPrograms[0].programReferences.length < 1) {
					return;
				}

				const resultsStation = this.stations.find(s => s.id === stationPrograms[0].stationId);
				this.resultsStationName = this.resultsStationName !== resultsStation.name
					? this.resultsStationName : (resultsStation !== null) ? resultsStation.name : '';

				// RB-14262: For each station program, we need to add the string program grup status, Enabled/Disabled.
				// We could, alternatively, do this with a cell renderer, saving us from defining a UI-only field
				// in the row data, etc., but this is OK for now.
				stationPrograms[0].programReferences.forEach((ref: ProgramIdentifier) => {
					this.rowData.push({
						...ref,
						status: ref.programGroupIsEnabled ? 
							RbUtils.Translate.instant('STRINGS.ENABLED') : 
							RbUtils.Translate.instant('STRINGS.DISABLED')
					});
				});

				this.displayGrid();
				this.showWaitingIndicator = false;
			}, 
			error: (e) => {
				this.loadError = e.error || e.message || this.translateService.instant('STRINGS.NETWORK_ERROR_RETRY');
			}
			});
	}

	private _filter(name: string): any[] {
		const filterValue = name.toLowerCase();
		return this.stations.filter(option => option.name.toLowerCase().includes(filterValue));
	}

	private validateAndSetSearchValue(isInitialLoad = false) {
		if (this.initPanel) {
			// For cases where we are not setting the initial station search filter on load.
			setTimeout(() => this.initPanel = false, 1000);
		}

		if (this.stationId == null || this.stations == null || this.stations.length < 1) { return; }

		const currentStation = this.stations.find(s => s.id === this.stationId);
		if (currentStation != null) {
			// RB-10986: Filter out stations not belong to the selected controller
			if (!this.isGolfSite) {
				this.stations = this.stations.filter(x => x.satelliteId === currentStation.satelliteId);
			}

			this.stationFilter.setValue({ name: currentStation.name }, { emitEvent: true });
			if (isInitialLoad) { this.getStationPrograms([currentStation.id]); }
			this.resultsStationName = currentStation.name;
		}

		this.station = currentStation;
	}

	private displayGrid() {
		setTimeout(() => {
			this.isGridDataLoaded = true;
			this.isGridDisplayable = true;
		}, 50);
	}

	private hideGrid() {
		this.isGridDataLoaded = false;
		this.isGridDisplayable = false;
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-edit-panel
	class="rb-edit-panel"
	[saveDisabled]="!editForm?.valid || !editForm?.dirty"
	(cancel)="onCancel()"
	(save)="onSave()">
	<ng-container *ngTemplateOutlet="editUserForm"></ng-container>
</rb-edit-panel>

<ng-template #editUserForm>
	<div class="edit-user-container gray" [ngClass]="{'edit-panel-fade-in': !busy}">
		<form [formGroup]="editForm" class="side-panel-body flex">
			<fieldset>
				<div>
					<mat-form-field>
						<input formControlName="name" matInput [placeholder]="'STRINGS.USERNAME' | translate" required
							   [minLength]="RbConstants.Form.USER_LENGTH_RANGE.min" [maxLength]="RbConstants.Form.USER_LENGTH_RANGE.max"
							   (focus)="$event.target.select()"/>
					</mat-form-field>
					<div *ngIf="nameErrorMessage" class="warnColorText">{{nameErrorMessage | translate}}</div>

					<mat-form-field>
						<input formControlName="email" matInput [placeholder]="'STRINGS.EMAIL' | translate"
						(focus)="$event.target.select()"/>
					</mat-form-field>
					<div *ngIf="!isGolfSite" class="warnColorText gray">{{'STRINGS.USER_EMAIL_NOTE' | translate}}</div>
					<div *ngIf="emailErrorMessage" class="warnColorText">{{emailErrorMessage | translate}}</div>

					<div *ngIf="creatingNew">
						<mat-form-field>
							<input formControlName="Password" matInput type="password" [placeholder]="'STRINGS.PASSWORD' | translate" required
								   [minLength]="RbConstants.Form.USER_LENGTH_RANGE.min" [maxLength]="RbConstants.Form.USER_LENGTH_RANGE.max"
								   (focus)="$event.target.select()"/>
						</mat-form-field>
						<div *ngIf="PasswordErrorMessage && (isCloud ? (getActivedFormControlName() !== 'Password') : true)" class="warnColorText">{{PasswordErrorMessage | translate}}</div>
						<div *ngIf="isCloud && getActivedFormControlName() === 'Password'">
							<div *ngFor="let passwordValidationMessage of PasswordValidationMessages" class="rb-dialog-password-validation">
								<div class="warnColorText helperErrorColorText"
									[ngClass]="{'helperSuccessColorText': passwordValidationMessage.valid}">
									<mat-icon class="validation-icon">{{passwordValidationMessage.valid?'check_circle':'error'}}</mat-icon>
									{{passwordValidationMessage.message}}
								</div>
							</div>
						</div>
						<mat-form-field>
							<input formControlName="confirmPassword" matInput type="password" [placeholder]="'STRINGS.CONFIRM_PASSWORD' | translate" required
								(focus)="$event.target.select()"/>
						</mat-form-field>
						<div *ngIf="confirmPasswordErrorMessage" class="warnColorText">{{confirmPasswordErrorMessage | translate}}</div>
					</div>

					<mat-form-field>
						<mat-select formControlName="groupLevel" [placeholder]="'STRINGS.ACCESS_LEVEL' | translate" required>
							<mat-option *ngFor="let groupLevel of groupLevels" [value]="groupLevel.value" class="pv1" color="primary" >{{groupLevel.name}}</mat-option>
						</mat-select>
					</mat-form-field>

					<div *ngIf="!creatingNew && isCurrentUserOwner && !isReadOnly" id="eu-change-password-container">
						<rb-change-password
							[isChangingNonCurrentUserPassword]="true"
							[changeButtonText]="'STRINGS.CHANGE_PASSWORD'"
							(passwordChange)="onPasswordChange($event)"
						></rb-change-password>
					</div>

					<div *ngIf="!creatingNew && isCurrentUserOwner && !isGolfSite && !isReadOnly" id="eu-change-pin-code-container">
						<rb-change-pin-code
							[isChangingNonCurrentUserPinCode]="true"
							[changeButtonText]="'STRINGS.CHANGE_PIN_CODE'"
							[selectedUserId]="selectedUserId"
							(pinCodeChange)="onPinCodeChange($event)"
						></rb-change-pin-code>
					</div>

					<div *ngIf="editForm.get('groupLevel').value != null && editForm.get('groupLevel').value < RbEnums.Common.GroupLevel.Admin">
						<div>
							<label class="rb-h4-green">{{'STRINGS.SITES' | translate}}</label>
						</div>
						<div>
							<mat-checkbox 
								[checked]="isAllUserSitesSelected"
								(change)="selectAllUserSites($event.checked)" 
								class="pa2" 
								color="primary"
							>
								{{ 'STRINGS.SELECT_ALL' | translate }}
							</mat-checkbox>
						</div>
						<ng-container *ngFor="let site of sites" class="checkBox">
							<mat-checkbox [checked]="isUserSite(site.id)" [disabled]="isUserSiteDisabled || isReadOnly" (change)="onUserSiteChanged(site.id, $event.checked)" class="fl pa2 cb" color="primary">
								{{site.name}}
							</mat-checkbox>
						</ng-container>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</ng-template>

<rb-waiting-indicator
	*ngIf="busy"
	[isVisible]="busy"
	[error]="loadError"
	(okClick)="onActionErrorAcknowledged()">
</rb-waiting-indicator>

<div class="mobile-diagnostics-filter-components">

	<div class="selection-container">
		<mat-select [placeholder]="'STRINGS.TEST_TYPE' | translate"
					[(ngModel)]="parentDiagnosticsFilter.selectedTestTypeId"
					[disabled]="parentDiagnosticsFilter.selectedCourseId == null">
			<mat-option *ngFor="let testType of parentDiagnosticsFilter.testTypes" [value]="testType.id">{{testType.name}}</mat-option>
		</mat-select>
	</div>

	<div class="selection-container">
		<mat-select [placeholder]="'STRINGS.TYPE' | translate" [disabled]="parentDiagnosticsFilter.selectedCourseId == null"
					[(ngModel)]="parentDiagnosticsFilter.selectedDiagnosticTypeId" (ngModelChange)="parentDiagnosticsFilter.onDiagnosticTypeSelected()">
			<mat-option *ngFor="let diagnosticsType of parentDiagnosticsFilter.availableDiagnosticTypes" [value]="diagnosticsType.id">{{diagnosticsType.name}}</mat-option>
		</mat-select>
	</div>

	<div *ngIf="parentDiagnosticsFilter.selectedDiagnosticTypeId !== 1" class="selection-container">
		<mat-select [placeholder]="'STRINGS.COURSE' | translate"
					[(ngModel)]="parentDiagnosticsFilter.selectedCourseId" (ngModelChange)="parentDiagnosticsFilter.onCourseSelected()">
			<mat-option *ngFor="let course of parentDiagnosticsFilter.courses" [value]="course.id">{{course.name}}</mat-option>
		</mat-select>
	</div>

	<!-- Address options -->
	<div *ngIf="parentDiagnosticsFilter.selectedDiagnosticTypeId === 1" class="selection-container">
		<mat-select [placeholder]="'STRINGS.INTERFACE' | translate" [(ngModel)]="parentDiagnosticsFilter.selectedInterfaceId">
			<mat-option *ngFor="let i of parentDiagnosticsFilter.interfaces" [class.all-item]="i.id === -1" [value]="i.id">
				{{i.name}}
			</mat-option>
		</mat-select>
	</div>

	<div *ngIf="parentDiagnosticsFilter.selectedDiagnosticTypeId === 1" class="selection-container">
		<mat-select [placeholder]="'STRINGS.WIREPATH_NUMBER' | translate" [disabled]="parentDiagnosticsFilter.selectedInterfaceId == null"
					[(ngModel)]="parentDiagnosticsFilter.selectedWirePathIds" multiple (selectionChange)="parentDiagnosticsFilter.onWirePathSelected()">
			<mat-option *ngFor="let wirePath of parentDiagnosticsFilter.wirePaths" [class.all-item]="wirePath.id === -1" [value]="wirePath.id">
				{{wirePath.name}}
			</mat-option>
		</mat-select>
	</div>

	<!-- Course options -->
	<div *ngIf="parentDiagnosticsFilter.selectedDiagnosticTypeId === 2" class="front-back-dropdown-selection-container">
		<rb-select-holes-dropdown #holesDropdown 
			[holesAndPlaces]="parentDiagnosticsFilter.holesAndPlaces"
			[isLeftJustified]="true"
			[isWhiteBackground]="true"
			[isMobile]="true"
			>
		</rb-select-holes-dropdown>
	</div>

	<div *ngIf="parentDiagnosticsFilter.selectedDiagnosticTypeId === 2" class="front-back-dropdown-selection-container">
		<rb-front-back-dropdown #areasDropdown 
			[label]="'STRINGS.AREAS' | translate" 
			[dataArray]="parentDiagnosticsFilter.courseAreas" 
			[isAreas]="true" 
			[showSelectionCount]="true"
			[isWhiteBackground]="true"
			[isLeftJustified]="true"
			[isCompressed]="true"
			>
		</rb-front-back-dropdown>
	</div>
</div>

<div class="mobile-diagnostics-filter-buttons">
	<button class="run-test" (click)="parentDiagnosticsFilter.onRunButtonClicked()" [disabled]="!runButtonEnabled">{{'STRINGS.RUN_TEST_UPPERCASE' | translate}}</button>
<!--	<button class="run-test cancel" (click)="parentDiagnosticsFilter.onCancelButtonClicked()">{{'STRINGS.CANCEL_ALL' | translate | uppercase}}</button>-->
</div>

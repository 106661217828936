<mat-accordion class="tree-list" *ngIf="controllers && controllers.length else emptyListMessage">
	<!-- Controllers -->
	<mat-expansion-panel *ngFor="let controller of controllers" [expanded]="expandedControllerId === controller.id"
		[disabled]="true">
		<mat-expansion-panel-header class="item-list">
			<div class="panel-header-wrapper" cdkDropList cdkDropListConnectedTo="map">
				<div class="title-content" cdkDrag
					[cdkDragDisabled]="mapInfo.controllerHasLocation(controller) || dragDisabled"
					[cdkDragData]="{ controller: controller }" (cdkDragMoved)="onDragMoved.emit($event)"
					(cdkDragReleased)="onDragEnded.emit()">

					<div class="div-expansion-icon">
						<mat-icon class="expansion-icon" (click)="expandController(controller)">
							{{expandedControllerId === controller.id ? 'expand_more' : 'chevron_right'}}
						</mat-icon>
					</div>

					<div class="div-icon">
						<i class="title-icon" cdkDragHandle>
						</i>
						<img src="../../../../assets/images/controller_icon.jpg" *cdkDragPreview />
					</div>

					<div class="div-name">
						<span class="title-text ellipsis-text no-select"
							[class.has-location]="mapInfo.controllerHasLocation(controller)"
							(click)="onControllerClicked($event, controller)" cdkDragHandle>
							{{controller.name}}
						</span>
					</div>

					<span *ngIf="showSpinner && expandedControllerId === controller.id" class="flex items-center pl2">
						<img class="progress-spinner--button" src="../../../assets/loader.svg" />
					</span>
				</div>
			</div>
		</mat-expansion-panel-header>

		<!-- Stations -->
		<div class="station-accordion" *ngIf="expandedControllerId === controller.id">
			<div *ngFor="let sensor of mapInfo.sensorsForController(controller.id)" class="mat-expansion-panel">
				<div class="mat-expansion-panel-header item-list">
					<span class="mat-content">
						<div class="panel-header-wrapper" cdkDropList cdkDropListConnectedTo="map">
							<div class="title-content" cdkDrag
								[cdkDragDisabled]="mapInfo.sensorHasLocation(sensor) || dragDisabled" [cdkDragData]="sensor"
								(cdkDragMoved)="onDragMoved.emit($event)" (cdkDragReleased)="onDragEnded.emit()">
								<div class="div-icon without-expansion-controller">
									<i class="icon-stations title-icon" (click)="onSensorClicked($event, sensor)" cdkDragHandle>
									</i>
									<img src="../../../../assets/images/location.png" *cdkDragPreview />
								</div>

								<div class="div-name">
									<span class="ellipsis-text no-select" (click)="onSensorClicked($event, sensor)"
										[class.has-location]="mapInfo.sensorHasLocation(sensor)" cdkDragHandle
										[cdkDragHandleDisabled]="isMobile">
										{{sensor.name}}
									</span>
								</div>

							</div>
						</div>
					</span>
				</div>


			</div>

			<div *ngFor="let station of mapInfo.stationsForController(controller.id)" class="mat-expansion-panel">
				<div class="mat-expansion-panel-header item-list">
					<span class="mat-content">
						<div class="panel-header-wrapper" cdkDropList cdkDropListConnectedTo="map">
							<div class="title-content" cdkDrag
								[cdkDragDisabled]="mapInfo.stationHasLocation(station) || dragDisabled" [cdkDragData]="station"
								(cdkDragMoved)="onDragMoved.emit($event)" (cdkDragReleased)="onDragEnded.emit()">

								<div class="div-icon without-expansion-controller">
									<i class="icon-stations title-icon" (click)="onStationClicked($event, station)"
										cdkDragHandle>
									</i>
									<img src="../../../../assets/images/location.png" *cdkDragPreview />
								</div>

								<div class="div-name">
									<span class="ellipsis-text no-select" (click)="onStationClicked($event, station)"
										[class.has-location]="mapInfo.stationHasLocation(station)" cdkDragHandle
										[cdkDragHandleDisabled]="isMobile">
										{{station.name}}
									</span>
								</div>

							</div>
						</div>
					</span>
				</div>

			</div>

		</div>

	</mat-expansion-panel>
</mat-accordion>

<!-- Empty message -->

<ng-template #emptyListMessage>
	<p class="message">{{'STRINGS.NO_ITEMS_IN_LIST' | translate}}</p>
</ng-template>
export class AdvanceStation {

	constructor(programId: number, stationId: number) {
		this.programId = programId;
		this.stationId = stationId;
	}

	programId: number;
	stationId: number;
}

import { Component, EventEmitter, Input, OnChanges, Output, } from '@angular/core';

@Component({
	selector: 'rb-dropdown',
	templateUrl: './dropdown.component.html',
})

// DropdownComponent is a ComboBox-style of component with a list of values and a single- or
// 	multiple-selection (controlled via input option 'allowMultiple').
// * The list of values comes from the 'dataArray' input which is an Array<any>.
// * The selected value of the list can be set or retrieved via the dropdownValue input. The
// 	code is written so that the dropdownValue is always the 'id' of the item in the dataArray.
export class DropdownComponent implements OnChanges {

	@Input() allowMultiple = false;
	@Input() dataArray: Array<any>;
	@Input() dropdownValue = null;
	@Input() placeholder: string;
	@Input() shouldReset: boolean;
	@Input() showAllOption = true;
	@Input() showNoneOption = false;
	@Input() value: string;

	@Output() valueChange: EventEmitter<string> = new EventEmitter();

	ngOnChanges() {
		if (this.shouldReset) {
			this.dropdownValue = null;
		}
	}

	handleChange(event) {
		this.value = event.value;
		this.valueChange.emit(this.value);
	}
}

<div>
	<button
		mat-mini-fab
		class="system-status-button"
		[disabled]="disabled"
		(click)="buttonClicked.emit($event)"
		[ngStyle]="buttonStyle()"
	>
		<mat-icon 
			[ngStyle]="iconStyle()"
			>
			{{ matIconName }}
		</mat-icon>
	</button>
</div>

<div class="map-component no-select" [class.modern]="isGolfSite">
	<rb-map-toolbar #mapToolbarComponent
		[style.display]="isFullScreen? 'none': 'flex'"
		[detailOptionsDisabled]="!showingDetailMarkers"
		[busy]="busy"
		[showVisibleSelector]="showVisibleSelector"
		[showGoHome]="mapInfo?.site != null && mapInfo?.site.address != null && mapInfo?.site.address.length > 0"
		[showCustomLayersMenu]="true"
		[noInternet]="noInternet"
		[leftSideVisible]="leftPanelVisible"

		(showHideLeft)="onShowHideLeftPane($event)"
		(goHome)='onGoHome()'
		(layerSelectionChange)="onLayerSelectedChanged($event)"
		(baseLayerSelectionChange)="onBaseLayerSelectedChanged($event)"
		(textColorChange)="onTextColorChanged($event)"
		(exportPdf)="onExportPdf()"
		(downloadTiles)="onDownloadTiles()"
		(removeTiles)="onRemoveTiles()"
		*ngIf="!isGolfSite">
	</rb-map-toolbar>

	<div class="toggle-button-container"
		[class.left-panel-open]="leftPanelVisible"
		*ngIf="isGolfSite">
		<button class="rb-leaflet-control" (click)="toggleSidePanel()"
			[disabled]="busy" [title]="'STRINGS.OPEN_MAP_SIDEBAR' | translate">
			<i class="mdi" [class.mdi-menu]="!leftPanelVisible" [class.mdi-close]="leftPanelVisible"></i>
		</button>
	</div>

	<div #mapContainer class="map-container" id="listPanel">
		
		<rb-left-panel #leftSidebar
			class="left-panel"
			[class.d-none]="busy"
			[class.open]="leftSidebar.isOpen"

			[mapInfo]="mapInfo"
			[map]="map"
			[dragDisabled]="!itemsMovable || !showingDetailMarkers"
			[isWidget]="isWidget"

			[detailOptionsDisabled]="!showingDetailMarkers"
			[busy]="busy"
			[showVisibleSelector]="showVisibleSelector"
			[noInternet]="noInternet"

			(layerSelectionChange)="onLayerSelectedChanged($event)"
			(baseLayerSelectionChange)="onBaseLayerSelectedChanged($event)"
			(textColorChange)="onTextColorChanged($event)"
			(exportPdf)="onExportPdf()"
			(downloadTiles)="onDownloadTiles()"
			(removeTiles)="onRemoveTiles()"

			(holeClicked)="holeClicked($event)"
			(areaClicked)="areaClicked($event)"
			(stationClicked)="stationClicked($event)"
			(noteLocationClicked)="onNoteLocationClicked($event)"

			(controllerClicked)="controllerClicked($event)"
			(sensorClicked)="sensorClicked($event)"

			(closeLeftPanel)="onShowHideLeftPane($event)">
		</rb-left-panel>

		<div class="leaflet-map" #map>
			<div style="width: 100%; height: 100%"
				leaflet
				[leafletOptions]="leafletMapOptions"
				[leafletLayers]="leafletLayers"
				(leafletMapReady)="onLeafletMapReady($event)"
				cdkDropList
				id="map"
				[class.dragging]="dragging"
				(cdkDropListDropped)="onDrop($event)">
			</div>
		</div>

		<div #contextMenuTrigger="matMenuTrigger" class="context-menu-trigger" [matMenuTriggerFor]="mapContextMenu"
			[style.left.px]="contextMenuInfo?.menuPosition.x" [style.top.px]="contextMenuInfo?.menuPosition.y"></div>

		<mat-menu #mapContextMenu="matMenu" yPosition="below" 
			class="map-context-menu-container popup-{{getStationIDForContextMenu()}}">
			<ng-template matMenuContent>
				<rb-map-context-menu 
					[contextMenuInfo]="contextMenuInfo"
					[contextMenuTrigger]="contextMenuTrigger"
					[isEditingAreaColor]="isEditingAreaColor"
					[isEditingFromStationAreaContextMenu]="isEditingFromStationAreaContextMenu"
					(closeStationContextMenu)="handleCloseStationContextMenu()"
					(closeStationAreaContextMenu)="handleCloseStationAreaContextMenu()"
					(contextMenuItemSelected)="onContextMenuItemSelected($event)"
					[lastIrrigatedDateTimeFormat]="lastIrrigatedDateTimeFormat">
				</rb-map-context-menu>
			</ng-template>
		</mat-menu>

		<div #rightClickContextMenuTrigger="matMenuTrigger" class="context-menu-trigger" [matMenuTriggerFor]="mapRightClickMenu"
			[style.left.px]="rightClickContextMenuInfo?.menuPosition.x" [style.top.px]="rightClickContextMenuInfo?.menuPosition.y"></div>

			<mat-menu #mapRightClickMenu="matMenu" class="right-click-context-menu contextmenu-toolbarmenu">
				<!-- COMMERCIAL ONLY -->
				<button mat-menu-item (click)="onAddStickyNote()" *ngIf="!isGolfSite">
					<mat-icon>add_circle_outline</mat-icon>
					<span>{{ 'STRINGS.ADD_STICKY_NOTE' | translate }}</span>
				</button>
	
				<!-- GOLF ONLY -->
				<button mat-menu-item (click)="onAddNote()" *ngIf="isGolfSite">
					<i class="mdi mdi-message-plus text-blue"></i>
					<span>{{ 'STRINGS.ADD_NOTE' | translate }}</span>
				</button>
				<!-- <button mat-menu-item (click)="onSelectNearbyStations()" *ngIf="isGolfSite">
					<i class="mdi mdi-map-marker-circle text-orange"></i>
					<span>{{ 'STRINGS.SELECT_NEARBY_STATIONS' | translate }}</span>
				</button> -->
				<button mat-menu-item (click)="onCenterMap()" *ngIf="isGolfSite">
					<mat-icon class="text-green">location_searching</mat-icon>
					<span>{{ 'STRINGS.CENTER_MAP_HERE' | translate }}</span>
				</button>
				<button mat-menu-item *ngIf="isGolfSite && rightClickContextMenuInfo?.stationId" (click)="onContextMenuItemSelected(rightClickContextMenuInfo?.editOption)">
					<mat-icon class="text-yellow">edit</mat-icon>
					<span>{{ 'STRINGS.EDIT_STATION' | translate }}</span>
				</button>
				<button mat-menu-item *ngIf="isGolfSite && rightClickContextMenuInfo?.stationId" (click)="onContextMenuItemSelected(rightClickContextMenuInfo?.removeOption)">
						<mat-icon class="text-red">delete_forever</mat-icon>
						<span>{{ (contextMenuInfo?.fromStationArea ? 'STRINGS.DELETE_SHAPE'  : 'STRINGS.REMOVE_FROM_MAP')  | translate }}</span>
				</button>
			</mat-menu>

		<rb-waiting-indicator
			*ngIf="busy"
			[isVisible]="busy"
			[error]="loadError">
		</rb-waiting-indicator>
		<rb-waiting-indicator
			*ngIf="!isGolfSite && isConnectingController"
			[text]="('STRINGS.CONNECTING_TO_CONTROLLER' | translate)">
		</rb-waiting-indicator>
	</div>
</div>

<ng-template #durationDialog>
	<rb-duration-dialog
		[duration]="defaultRunStationDuration"
		[title]="durationDialogTitle"
		[showTabs]="true"
		[station]="contextMenuStation"
		[stations]="selectedStationsToRun"
		[isMultiSelectFromMap]="true"
		(durationChange)="onDurationSet($event)"
		(multipleDurationChange)="onDurationSet($event)">
	</rb-duration-dialog>
</ng-template>

<ng-template #startControllerDialog>
	<rb-start-controller-modal [controllerId]="contextMenuInfo.controller.id"
		(complete)="onStartController($event)"></rb-start-controller-modal>
</ng-template>

<ng-template #startStationModal>
	<rb-start-station-modal
		[selectedItemIds]="selectedStationIds"
		[selectedStationIds]="selectedStationIds"
		[isGolfController]="false"
		[isProgramStepStation]="false"
		[controllerId]="contextMenuInfo.controller.id"
		[maxHours]="maxHours"
		(stationStartRequested)="onStationStartRequested()"
	>
	</rb-start-station-modal>
</ng-template>

<ng-template #siteAddressModal>
	<rb-site-address-modal
		[site]="site"
		[mapComponent]="this"
	>
	</rb-site-address-modal>
</ng-template>

<ng-template #controllerAutoConnectModal>
	<rb-start-controller-auto-connect-modal
		[selectedControllerWithMapInfoLeaflet] = "selectedControllerWithMapInfoLeaflet"
		(startClick) = "onStartMultiStations()"
	>
	</rb-start-controller-auto-connect-modal>
</ng-template>

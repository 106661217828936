import { WeatherSensor } from './weather-sensor.model';

export class DecoderWeatherSensor extends WeatherSensor {

	constructor(json: any = null) {

		super(json);
		if (json) {
		}
	}
}

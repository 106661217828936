<div #stationContainer class='cv-station-container rb-input-shield-host'
	 [ngClass]="{'is-narrow-col' : isNarrowColumn, 'disable-gradient': !showGradient}"
	 [ngStyle]="{'background-color': backgroundColor}"
>
	<span *ngIf='activityType !== CourseViewActivityType.Active'>{{displayValue}}</span>
	<span *ngIf="isOverWater" class="cv-over-under cv-over-water"><i class="mdi mdi-arrow-up-bold"></i></span>
	<span *ngIf="isUnderWater" class="cv-over-under cv-under-water"><i class="mdi mdi-arrow-down-bold"></i></span>

	<div *ngIf='activityType === CourseViewActivityType.Active' class='station-status-running'>
		<span *ngIf='station?.isActivelyCyclingOrSoaking && isCycling' class='station-status-cycling'>
			<i class='icon-water'></i>
		</span>
			<span *ngIf='station?.isActivelyCyclingOrSoaking && isSoaking' class='station-status-soaking'>
			<i class='icon-timer'></i>
		</span>
		<span class="is-runnable" *ngIf="isStationRunnable" [ngClass]="{'not-actively-cycling-or-soaking' : !isSoaking && ! isCycling}">
			{{dataView === CourseViewData.Time ? 
				stationIsPaused ? ('STRINGS.PAUSED' | translate) : station?.courseViewStatus
				: this.station.isActivelyCyclingOrSoaking ? applicationOrRotation : station?.courseViewStatus}}
		</span>
		<div class="is-not-runnable" *ngIf="!isStationRunnable" [innerHTML]="nonRunnableInnerHtml"></div>
	</div>

	<div *ngIf="activityType === CourseViewActivityType.Active && isStationRunnable; then activeTypeAndRunningShield else otherShield"></div>
</div>

<ng-template #activeTypeAndRunningShield>
	<div class="rb-input-shield--w-pointer" *ngIf="activityType === CourseViewActivityType.Active && isStationRunnable"
		 [ngClass]="{'is-selected': isSelected}"
		 [matTooltip]="dynamicTooltip"
		 matTooltipPosition="above"
		 (click)='onToggleSelection()'
	></div>
</ng-template>

<ng-template #otherShield>
	<div class="rb-input-shield" [matTooltip]="tooltip" matTooltipPosition="above" [matMenuTriggerFor]="menu"></div>
</ng-template>

<mat-menu #menu="matMenu" class="course-view-context-menu">
	<ng-container *ngIf="!isBusy; else loader">
		<button mat-menu-item (click)="onStationSearch()">
			<mat-icon>search</mat-icon>
			<span>{{'STRINGS.PROGRAMS_AND_SCHEDULES' | translate}}</span>
		</button>
		<button mat-menu-item (click)="onRunDiagnostic()">
			<i class="mdi mdi-help-network-outline"></i>
			<span>{{'STRINGS.SHOW_STATION_DIAGNOSTIC' | translate}}</span>
		</button>
		<button mat-menu-item (click)="onShowNotes()">
			<i class="mdi mdi-message-processing"></i>
			<span>{{'STRINGS.SHOW_NOTES' | translate}}</span>
		</button>
	</ng-container>

	<ng-template #loader>
		<div class="progress-spinner--button">
			<mat-spinner class="rb-inline-spinner"></mat-spinner>
		</div>
	</ng-template>
</mat-menu>

export class ControllerGetLogsState {

	constructor(
		public controllerId: number,
		public gettingLogs: boolean,
		public queued: boolean = false,
		public changeDateTime: Date = null,
	) {}

}

import { RbConstants } from '../../../../common/constants/_rb.constants';

export class AlertFilter {

	constructor(rangeValue: any, startDate?: Date, endDate?: Date) {
		this.endDate = endDate;
		this.rangeValue = rangeValue;
		this.startDate = startDate;
	}

	endDate: Date;
	rangeValue = RbConstants.Form.DATE_RANGE.none;
	startDate: Date;
}

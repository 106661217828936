/* eslint-disable @typescript-eslint/member-ordering, @angular-eslint/directive-class-suffix */
import * as L from 'leaflet';
import * as LO from 'leaflet.offline';
import * as lodash from 'lodash';

import 'leaflet-toolbar';

import 'leaflet-distortableimage';
import 'leaflet-kmz';

import { AlertPopupLeaflet, StationWithMapInfoLeaflet } from './station-with-map-info-leaflet.model';
import { catchError, finalize, map, share, take } from 'rxjs/operators';
import { CdkDragDrop, CdkDragMove } from '@angular/cdk/drag-drop';
import {
	Circle, circle, Control, DomUtil, GeometryUtil, LatLng, latLng, LatLngBounds, latLngBounds,
	LatLngExpression, Layer, LeafletMouseEvent, polygon as Lpolygon,
	Map, Marker, marker, icon as newIcon, point as newPoint, Point, Polygon
} from 'leaflet';
import { Directive, EventEmitter, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { Note, StickyNote } from '../../api/sticky-notes/models/sticky-note.model';
import { AdvanceStation } from '../../api/stations/models/advance-station.model';
import { Area } from '../../api/areas/models/area.model';
import { AreaManagerService } from '../../api/areas/area-manager.service';
import { AreaUiSettings } from '../../api/areas/models/area-ui-settings.model';
import { AreaWithMapInfo } from './area-with-map-info.model';
import { AreaWithMapInfoLeaflet } from './area-with-map-info-leaflet.model';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { BroadcastService } from '../services/broadcast.service';
import { CenterOnLocationControlLeaflet } from './center-on-location-control-leaflet';
import { CenterOnSiteControlLeaflet } from './center-on-site-control-leaflet';
import { CollectionChange } from './collection-change.model';
import { CompanyManagerService } from '../../api/companies/company-manager.service';
import { CompanyPreferences } from '../../api/companies/models/company-preferences.model';
import { ContextMenuInfoTypes } from './context-menu-info-types';
import { ControllerChange } from '../../api/signalR/controller-change.model';
import { ControllerGetLogsState } from '../../api/signalR/controller-get-logs-state.model';
import { ControllerListItem } from '../../api/controllers/models/controller-list-item.model';
import { ControllerManagerService } from '../../api/controllers/controller-manager.service';
import { ControllerWithMapInfoLeaflet } from './controller-with-map-info-leaflet.model';
import { DeviceManagerService } from '../services/device-manager.service';
import { DownloadTilesControl } from './download-tiles-control';
import { Duration } from 'moment';
import { EnvironmentService } from '../services/environment.service';
import { FullScreenControl } from './full-screen-control';
import { FullScreenControlLeaflet } from './full-screen-control-leaflet';
import { GeoGroup } from '../../api/regions/models/geo-group.model';
import { GeoGroupManagerService } from '../../api/regions/geo-group-manager.service';
import { GeoItem } from '../../api/regions/models/geo-item.model';
import { GeoJSONProperties } from '../../api/leaflet/models/geojson-properties.model';
import { HoleWithMapInfo } from './hole-with-map-info.model';
import { HoleWithMapInfoLeaflet } from './hole-with-map-info-leaflet.model';
import { IcShortAddressPollData } from '../../api/manual-ops/models/ic-short-address-poll-data.model';
import { IcVoltagePollData } from '../../api/manual-ops/models/ic-voltage-poll-data.model';
import { IStation } from './station.interface';
import { KMZGroup } from '../../api/leaflet/models/kmz-group.model';
import { KMZItem } from '../../api/leaflet/models/kmz-Item.model';
import { LeafletManagerService } from '../../api/leaflet/leaflet-manager.service';
import { ManualControlState } from '../../api/manual-control/models/manual-control-state.model';
import { ManualOpsManagerService } from '../../api/manual-ops/manual-ops-manager.service';
import { MapInfoContextMenuLeaflet } from './map-info-context-menu-leaflet';
import { MapInfoPreferencesLeaflet } from './map-info-preferences-leaflet';
import { MapLeafletService } from '../services/map-leaflet.service';
import { MasterValveListItem } from '../../api/stations/models/master-valve-list-item.model';
import { MessageBoxInfo } from '../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from '../services/message-box.service';
import { ModuleApiService } from '../../api/modules/module-api.service';
import { MultiSelectModeControl } from './multi-select-mode-control';
import { RasterItem } from '../../api/leaflet/models/raster-file.model';
import { RbConstants } from '../constants/_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';
import { Sensor } from '../../api/sensors/models/sensor.model';
import { SensorListChange } from '../../api/sensors/models/sensor-list-change.model';
import { SensorListItem } from '../../api/sensors/models/sensor-list-item.model';
import { SensorManagerService } from '../../api/sensors/sensor-manager.service';
import { SensorWithMapInfoLeaflet } from './sensor-with-map-info-leaflet.model';
import { ServerClientRelationship } from './server-client-relationship.model';
import { Site } from '../../api/sites/models/site.model';
import { SiteManagerService } from '../../api/sites/site-manager.service';
import { StartStationModel } from '../../api/manual-ops/models/start-station.model';
import { Station } from '../../api/stations/models/station.model';
import { StationArea } from '../../api/station-areas/station-area.model';
import { StationAreaWithMapInfoLeaflet } from './station-area-with-map-info-leaflet.model';
import { StationListItem } from '../../api/stations/models/station-list-item.model';
import { StationManagerService } from '../../api/stations/station-manager.service';
import { StationOptions } from './station-options';
import { StationsListChange } from '../../api/stations/models/stations-list-change.model';
import { StationStatusChange } from '../../api/signalR/station-status-change.model';
import { StickyNoteManagerService } from '../../api/sticky-notes/sticky-note-manager.service';
import { Subarea } from '../../api/areas/models/subarea.model';
import { SystemStatusService } from '../services/system-status.service';
import { tap } from 'rxjs/internal/operators/tap';
import { TrackingDataControlLeaflet } from './tracking-data-control-leaflet';
import { TranslateService } from '@ngx-translate/core';
import { TriPaneComponent } from '../../shared-ui/components/tri-pane/tri-pane.component';
import { UiSettingsService } from '../../api/ui-settings/ui-settings.service'
import { VoltageDiagnosticManagerService } from '../../api/voltage-diagnostic/voltage-diagnostic-manager.service';
import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;


require('leaflet-path-drag');

/**
 * Usage notes:
 * Stations:
 * 	- Station markers (station.marker) must be created for any station within the bounds of the map, allowing
 * 		marker visibility changes with a simple setMap/setLabel call set.
 * 	- A station's marker can have several appearances: suspended, running, soaking, idle, low-voltage, high-voltage,
 * 		no-feedback, not-connected. There are various visibility scenarios for these states so we have to carefully
 * 		follow the rules for marker creation and destruction.
 *  - Station voltages can be null (no diagnostic result yet received), or zero. Don't treat the two as identical! RB-9921
 */

@Directive()
export class MapInfoLeaflet implements OnDestroy {

	private static loadSensorObservables = {}; // dictionary of observables by controller ID
	private static loadStationObservables = {}; // dictionary of observables by site ID
	private static getAreasObservables = {}; // dictionary of observables by site ID
	private static getStationAreasObservables = {};
	private static getGeoGroupsObservables = {}; // dictionary of observables by site ID
	private static getSiteObservables = {}; // dictionary of observables by site ID
	private static loadStationRunningStatusObservables = {}; // dictionary of observables by site ID

	// Location accuracy circle.
	// private static locationAccuracyCircleStrokeColor = '#008751';
	private static locationAccuracyCircleStrokeColor = '#1976d2';
	private static locationAccuracyCircleStrokeOpacity = 0.6;
	private static locationAccuracyCircleStrokeWeight = 3;
	// private static locationAccuracyCircleFillColor = '#008751';
	private static locationAccuracyCircleFillColor = '#1976d2';
	private static locationAccuracyCircleFillOpacity = 0.3;

	// Location accuracy circle size below which we hide the circle and say "we have a fix".
	private static locationAccuracyToHideCircle = 5.0;

	private static skipEvents = {};

	// Subjects
	busy = new Subject<boolean>();
	contextMenu: MapInfoContextMenuLeaflet;
	contextMenuInvoked = new Subject<any>(); // Object { menuOptions: any[], menuPosition: google.maps.Point, image: string, title: string }
	batchEditStations = new Subject<IStation[]>()
	rightClickContextMenuInvoked = new Subject<any>();
	editArea = new Subject<number>(); // Area Id
	editController = new Subject<number>(); // Controller Id
	editHole = new Subject<number>(); // Hole Id
	editingStationAreaGeoItemId: number;
	editSensor = new Subject<any>(); // Sensor Id
	editStation = new Subject<number>(); // Station Id
	holes: HoleWithMapInfoLeaflet[] = [];
	stationAreas: StationAreaWithMapInfoLeaflet[] = [];
	loadError = new Subject<string>();
	mapClicked = new Subject();
	mapZoomChanged = new Subject<MapInfoLeaflet>();
	multiSelectActionInvoked = new Subject<any>();
	openNoteDialogInvoked = new Subject<StickyNote>();
	onInvokeRightPane = new Subject<Boolean>();
	siteAddressLookupFailed = new Subject();
	siteDataLoaded = new Subject<any>(); // Object { site: Site, stations: Station[], holes: Area[], areas: Area[], geoGroups: GeoGroup[] }
	sensorRemoved = new Subject<number>();
	stationRemoved = new Subject();
	stationAreaRemoved = new Subject<number>();
	loadingVectorLayers = new ReplaySubject<boolean>(1);
	showStationAdjustments = new Subject<boolean>();
	openControllerAutoConnectModal = new Subject<ControllerWithMapInfoLeaflet[]>();
	private stationSelectionChange = new Subject<IStation[]>();

	areItemsMovable = false;

	isMultiSelectModeEnabled = false;

	/** Notifies the CLE (if active) that the KMZ items changed. Used after finished loading KMZ from the backend */
	vectorItemsChanged = new EventEmitter<void>();
	/** Notifies the CLE (if active) that the raster items changed. Used after finished loading rasters from the backend */
	rasterItemsChanged = new EventEmitter<void>();

	readonly leafletLayers: Layer[] = [];

	/** Minimum zoom level for raster images to show */
	readonly minZoomForRasterImages = 14;

	/**
	 * Last zoom-based custom layer visibility
	 */
	private showingCustomLayers: boolean;

	private selectedBaseLayer;

	private locationControl: Control;
	private goHomeControl: Control;
	private fullscreenControl: Control;
	private multiSelectControl: Control;
	private downloadTilesControl: Control;
	private leafletDownloadTilesControl: Control;
	// private leafletImportKmlControl: any;
	private trackingDataControl: Control;
	private zoomControl: Control;

	private addedCenterOnLocation = false;
	areas: AreaWithMapInfoLeaflet[] = [];
	private companyPreferences: CompanyPreferences;
	controllers: ControllerWithMapInfoLeaflet[] = [];
	private courseMarker: Marker;
	private currentStationForPopup: StationWithMapInfoLeaflet;
	selectedStations: any[] = [];
	prevSelectedStations: any[] = [];
	controllersHaveStationRunning: number[] = [];
	isJustStartedStations = false;
	private draggedArea: AreaWithMapInfoLeaflet;
	private draggedController: ControllerWithMapInfoLeaflet;
	private draggedHole: HoleWithMapInfoLeaflet;
	private draggedSensor: SensorWithMapInfoLeaflet;
	private draggedStation: StationWithMapInfoLeaflet;
	private draggedAreaStation: StationAreaWithMapInfoLeaflet;
	public dragging = false;
	private dragPosition: { x: number; y: number };
	private draggableStickyNotesList: L.Draggable[] = [];
	private geoGroups: GeoGroup[] = [];
	// private googleMap: google.maps.Map; %% Commented for replacement
	private leafletMap: Map;
	private pendingStationsStatusList: StationListItem[];
	private handleCompanyStatusChangeTimer: number;
	private isGolfSite: boolean;
	private mapBounds: LatLngBounds;
	private receivedMapMoved = false;
	private pref: MapInfoPreferencesLeaflet;
	private minZoomForMarkers = 0;
	/**
	 * JS interval to check if the leaflet overlay canvas exists.
	 * Should be cleared when the canvas is detected or when this mapInfo is not longer needed
	 */
	private canvasInterval: number;
	private internetCheckInterval: any;
	private stationsChangeSubscription: Subscription;

	site: Site;

	/**
	 * stations contains an array of StationWithMapInfoLeaflet which is a subclass of Station (not StationListItem, please note) containing
	 * other information needed to render a station on the map. Some of the items we have to populate are the last voltage value
	 * diagnosed for the station, its last feedback result from a fast-connect poll, etc.
	 */
	stations: StationWithMapInfoLeaflet[] = [];
	private sensors: SensorWithMapInfoLeaflet[] = [];
	private stickyNotes: StickyNote[] = [];
	private popups: Array<{ id: number, item: L.Popup | L.Marker }> = [];

	private updateMarkersTimer: number;
	private useMockData = false;

	userLocationMarker: Marker;
	private userLocationAccuracyCircle: Circle;
	private zoomRadiusInMeters = 0;
	private prepared = false;
	private _kmzItems: KMZItem[] = [];
	private _rasterItems: RasterItem[] = [];

	private counterInterval;
	private isShowAllCalculateAreas = false;

	stationsCompleteList: StationListItem[] = [];

	// Marker data

	private controllerMarkerInfo = {
		width: 20,
		height: 15,
		mapIconHotspotX: 10,
		mapIconHotspotY: 7,
		dragIconHotspotX: 10,
		dragIconHotspotY: 7,
	};
	private holeMarkerInfo = {
		width: 20,
		height: 30,
		mapIconHotspotX: 5,
		mapIconHotspotY: 30,
		dragIconHotspotX: 5,
		dragIconHotspotY: 30,
	};
	private _controllerImage: any;

	private isLayerLoading = false;
	private changeBaseLayerTimer = null;

	private subscriberNumberStation: Subscription;
	private selectedStationsCount: number;

	serverClientRelationshipLines: ServerClientRelationship[] = []

	/**
	 * Flag indicating true once the address has been run through a geolocation search in this browser session. 
	 * Until connected to the Internet and the geolocation search is completed, false.
	 */
	public companyAddressLookupComplete: boolean = false;

	/**
	 * Company address lookup-based latitude value. This may be used for various geolocation defaults. null unless
	 * companyAddressLookupComplete is true, or unless address is not successfully looked.
	 */
	public companyLatitude: number = null;

	/**
	 * Company address lookup-based longitude value. This may be used for various geolocation defaults. null unless
	 * companyAddressLookupComplete is true, or unless address is not successfully looked.
	 */
	public companyLongitude: number = null;

	constructor(
		leafletMap: Map,
		public siteId: number,
		public uniqueId: number,
		public mapService: MapLeafletService,
		specialZoom: boolean,
		private canMultiselect?: boolean,
		private lastPosition?: GeolocationPosition
	) {

		this.subscriberNumberStation = this.mapService.multiSelectService.getNumberStations().asObservable().subscribe(value => {
			this.selectedStationsCount = value;
			MultiSelectModeControl.updateCounter(value);
		});

		if (!L.DomEvent['fakeStop']) {
			L.DomEvent['fakeStop'] = MapInfoLeaflet.fakeStop;
		}

		if (!L.DomEvent['skipped']) {
			L.DomEvent['skipped'] = MapInfoLeaflet.skipped;
		}

		this.leafletMap = leafletMap;
		this.isGolfSite = this.siteManager.isGolfSite;
		this.useMockData = this.env.useMockData && this.isGolfSite;
		this.pref = new MapInfoPreferencesLeaflet(this, this.uiSettingsService);
		this.contextMenu = new MapInfoContextMenuLeaflet(this, this.isGolfSite, this.translate);
		this.selectedBaseLayer = null;

		this.map.on('zoomend', (e: any) => {
			if (!this.isCurrent) return;

			this.pref.save();
			this.receivedMapMoved = true;
			this.updateMapBounds();
			this.updateAllMarkers(true);
			this.checkCustomLayers();
			this.mapZoomChanged.next(this);

			// Add the zoom level as a class to the map
			this.addZoomLevelClass();
		});

		this.map.on('moveend', () => {
			if (!this.isCurrent) return;

			const center = this.leafletMap.getCenter();
			if (center.lat > 1 || center.lat < -1 || center.lng > 1 || center.lng < -1) {
				this.pref.save();
			}

			this.receivedMapMoved = true;
			this.updateMapBounds();
			this.updateAllMarkers(false);
		});

		this.map.on('click', () => {
			this.stationGeoAreaItemsEditModeRelease(null);
			if (!this.isCurrent) return;

			this.mapClicked.next(null);
		});

		this.map.on('contextmenu', (event) => {
			if (!this.canAddNewStickyNote && !this.isGolfSite) {
				return;
			}
			const latlng = event.latlng;
			const point = this.latLngToPixel(event.latlng);
			this.rightClickContextMenuInvoked.next({
				latlng,
				menuPosition: {
					x: point.x,
					y: point.y
				}
			});
		})

		// Load data only if the siteId has changed. Otherwise, trigger siteDataLoaded with existing data.
		// Slightly delayed so map component can setup data handlers.
		setTimeout(() => this.loadData());

		this.updateMapBounds();

		this.addMultiSelectControl();
		this.addFullScreenControl();
		this.addZoomControl(mapService);
		this.addGoHomeControl();
		this.addDownloadTilesControl();

		this.startCountdownTimer();

		this.stationsChangeSubscription = this.stationManager.stationsChange
			.subscribe((stations: StationListItem[]) => {
				for (let i = 0; i < stations.length; i++) {
					const stationIndex = this.stations.findIndex(s => s.id === stations[i].id);
					if (stationIndex > -1 && stationIndex < this.stations.length) {
						if (stations[i].name !== null) this.stations[stationIndex].name = stations[i].name;
						if (stations[i].irrigationStatus !== null) this.stations[stationIndex].irrigationStatus = stations[i].irrigationStatus;
						// if (stations[i].areaLevel3Number !== null) this.stations[stationIndex].areaLevel3Number = stations[i].areaLevel3Number;
						if (stations[i].tempAdjustDays !== null) this.stations[stationIndex].tempAdjustDays = stations[i].tempAdjustDays;

						if (stations[i].tempStationAdjust !== null) this.stations[stationIndex].tempStationAdjust = stations[i].tempStationAdjust;
						if (stations[i].yearlyAdjFactor !== null) this.stations[stationIndex].yearlyAdjFactor = stations[i].yearlyAdjFactor;
						if (stations[i].etAdjustFactor !== null) this.stations[stationIndex].etAdjustFactor = stations[i].etAdjustFactor;
						if (!this.isGolfSite && stations[i].notes !== null) this.stations[stationIndex].description = stations[i].notes;
						if (stations[i].defaultRunTimeLong !== null) this.stations[stationIndex].defaultRunTimeLong = stations[i].defaultRunTimeLong;
						if (stations[i].terminal !== null) this.stations[stationIndex].terminal = stations[i].terminal;
						if (stations[i].channel !== null) this.stations[stationIndex].channel = stations[i].channel;
						if (stations[i].suspended !== null) this.stations[stationIndex].suspended = stations[i].suspended;
						// if (stations[i].isLocked !== null) this.stations[stationIndex].isLocked = stations[i].isLocked;
						if (stations[i].sprinklerCategory !== null) this.stations[stationIndex].sprinklerCategory = stations[i].sprinklerCategory;
						if (stations[i].secondsRemaining !== null) this.stations[stationIndex].secondsRemaining = stations[i].secondsRemaining;
						if (stations[i].runTimeRemaining !== null) this.stations[stationIndex].runTimeRemaining = stations[i].runTimeRemaining;
						if (stations[i].runTimeSoFar !== null) this.stations[stationIndex].runTimeSoFar = stations[i].runTimeSoFar;
						if (stations[i].lastManualRunStartTime != null
							&& (stations[i].lastManualRunStartTime as unknown as string) !== "0001-01-01T00:00:00+00:00") {
							this.stations[stationIndex].lastManualRunStartTime = (stations[i].lastManualRunStartTime as Date);
						}
						if (stations[i].lastRunTimeSeconds != null) this.stations[stationIndex].lastRunTimeSeconds = stations[i].lastRunTimeSeconds;
						if (stations[i].cycleTimeLong != null) this.stations[stationIndex].cycleTimeLong = stations[i].cycleTimeLong;
						if (stations[i].soakTimeLong != null) this.stations[stationIndex].soakTimeLong = stations[i].soakTimeLong;

						RbUtils.Stations.checkIfHasAdjustment(this.stations[stationIndex]);

						this.createMarkerForStation(this.stations[stationIndex]);

					}
				}
			});

		this.sensorManager.sensorsListChange
			.subscribe((sensorListChange: SensorListChange) => {
				const sensors = sensorListChange?.sensors;
				for (let i = 0; i < sensors?.length; i++) {
					const sensorIndex = this.sensors.findIndex(s => s.id === sensors[i].id);
					if (sensorIndex > -1 && sensorIndex < this.sensors.length) {
						if (!!sensors[i].name && (sensors[i].name != this.sensors[sensorIndex].name)) {
							this.sensors[sensorIndex].name = sensors[i].name;
							this.createMarkerForSensor(this.sensors[sensorIndex]);
						}
					}
				}
			});

		if (!this.isGolfSite) {
			this.updateMasterValve();
		}

		if (this.lastPosition && this.mapService.userLocationAvailabilityResponse.value) {
			this.userLocationUpdated(this.lastPosition);
		}
	}

	ngOnDestroy(): void {
		this.subscriberNumberStation.unsubscribe();
	}

	private updateMasterValve() {
		this.systemStatusService.stationStatusChange.subscribe((stationStatusChange: StationStatusChange) => {
			if (stationStatusChange.itemsChanged && stationStatusChange.itemsChanged?.ids?.length === 1) {
				const masterValveId = stationStatusChange.itemsChanged?.ids[0];
				this.stations.filter(s => s.id === masterValveId).map((station: StationWithMapInfoLeaflet) => {
					const updatedObj = RbUtils.Common.getUpdateObjectFromItemsChanged(stationStatusChange.itemsChanged);
					if (updatedObj) {
						if (updatedObj.flowZone != null) {
							if (station.flowZone) {
								if (!updatedObj.flowZone.name) {
									this.stationManager.getMasterValveListItem(masterValveId).subscribe((mv: MasterValveListItem) => {
										station.flowZone.name = mv.flowZone;
										station.flowZone.flowRate = mv.flowRate;
										if (mv.flowRate !== station.flowRate) {
											station.flowRate = mv.flowRate;
											delete updatedObj['flowRate'];
										}
										Object.assign(station, updatedObj);
									});
								} else {
									station.flowZone.name = updatedObj.flowZone.name;
									station.flowZone.flowRate = updatedObj.flowZone.flowRate;
								}
							}
							delete updatedObj['flowZone'];
						}
						Object.assign(station, updatedObj);
					}
					return station;
				});
			}
		});

		this.broadcastService.controllerUpdated
			.subscribe((change: ControllerChange) => {
				if (change.itemsChanged && change.itemsChanged?.ids?.length === 1) {
					const satelliteId = change.itemsChanged?.ids[0];
					this.stations.filter(s => s.satelliteId === satelliteId).map((station: StationWithMapInfoLeaflet) => {
						const updatedObj = RbUtils.Common.getUpdateObjectFromItemsChanged(change.itemsChanged);
						if (updatedObj && updatedObj.monthlyCyclingTime != null) {
							station.monthlyCyclingTime = updatedObj.monthlyCyclingTime;
						}
						return station;
					});
				}
			});
	}

	private startCountdownTimer() {
		if (this.counterInterval) clearInterval(this.counterInterval);

		this.counterInterval = setInterval(() => {
			const runningStations = this.stations.filter(s => s.irrigationStatus === RbEnums.Common.IrrigationStatus.Running ||
				s.irrigationStatus === RbEnums.Common.IrrigationStatus.Soaking);

			for (const station of runningStations) {
				station.updateRuntimeStatus();
			}
		}, 1000);
	}

	timeStringToSeconds(timeString: string) {
		const [hrs, mins, secs] = timeString.split(':');
		function convertToSeconds(hours: string, minutes: string, seconds: string) {
			return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
		} return convertToSeconds(hrs, mins, secs);
	}

	/**
	 * Whether the zoom a this exact moment allows for the custom layers to be shown
	 */
	get canShowCustomLayers() {
		return this.map.getZoom() >= this.minZoomForRasterImages;
	}

	get map(): Map {
		if (this.leafletMap) return this.leafletMap;
		return null;
	}

	set map(newMap: Map) {
		this.leafletMap = newMap;
	}

	get isPrepared() {
		return this.prepared;
	}

	set isPrepared(prepared: boolean) {
		this.prepared = prepared;
	}

	/** Emulating Leaflet's L.DomEvent.fakeStop function */
	static fakeStop(e) {
		MapInfoLeaflet.skipEvents[e.type] = true;
	}

	/** Emulating Leaflet's L.DomEvent.skipped function */
	static skipped(e) {
		const events = MapInfoLeaflet.skipEvents[e.type];
		// reset when checking, as it's only used in map container and propagates outside of the map
		MapInfoLeaflet.skipEvents[e.type] = false;
		return events;
	}

	addZoomLevelClass() {
		const leafletMap = this.leafletMapContainerNodeElement.querySelector('.leaflet-pane');

		if (leafletMap) {
			const prefix = 'zoom-';
			const classes = leafletMap.className.split(' ').filter(c => !c.startsWith(prefix));
			leafletMap.className = classes.join(' ').trim();
			leafletMap.classList.add('zoom-' + this.map.getZoom());
		}
	}

	tooltipThreshold = this.mapService.ZOOM_LEVEL_STREET;
	lastZoom = 0;
	toggleTooltipsByZoom() {
		const zoom = this.map.getZoom();
		if (zoom < this.tooltipThreshold && (!this.lastZoom || this.lastZoom >= this.tooltipThreshold)) {
			this.map.eachLayer(function (l) {
				if (l.getTooltip()) {
					const tooltip = l.getTooltip();
					l.unbindTooltip().bindTooltip(tooltip, {
						permanent: false
					});
				}
			});
		} else if (zoom >= this.tooltipThreshold && (!this.lastZoom || this.lastZoom < this.tooltipThreshold)) {
			this.map.eachLayer(function (l) {
				if (l.getTooltip()) {
					const tooltip = l.getTooltip();
					l.unbindTooltip().bindTooltip(tooltip, {
						permanent: true
					});
				}
			});
		}
		this.lastZoom = zoom;
	}

	/**
	 * Array of custom layers loaded for this site
	 */
	public get kmzItems() {
		return this._kmzItems;
	}

	public get rasterItems() {
		return this._rasterItems;
	}

	/**
	 * Function to cleanup everything that needs cleaning when we are changing from one MapInfo to another
	 */
	cleanup() {
		if (this.fullscreenControl) {
			this.fullscreenControl.remove();
		}
		if (this.locationControl) {
			this.locationControl.remove();
		}
		if (this.goHomeControl) {
			this.goHomeControl.remove();
		}
		if (this.trackingDataControl) {
			this.trackingDataControl.remove();
		}
		if (this.zoomControl) {
			this.zoomControl.remove();
		}
		if (this.multiSelectControl) {
			this.multiSelectControl.remove();
		}
		this.downloadTilesControlCleanup();

		this.mapService.downloadingTiles = false;
		if (this.canvasInterval !== undefined) {
			clearInterval(this.canvasInterval);
			this.canvasInterval = undefined;

		}

		this.stationsChangeSubscription.unsubscribe();
	}

	downloadTilesControlCleanup() {
		if (this.downloadTilesControl) {
			this.downloadTilesControl.remove();
		}
		if (this.leafletDownloadTilesControl) {
			this.leafletDownloadTilesControl.remove();

			if (this.offLineToggleNodeElement) {
				if (this.offLineToggleNodeElement.checked === true)
					this.offLineToggleNodeElement.click();
			}

			if (this.offLineToggleContainerNodeElement)
				this.offLineToggleContainerNodeElement.parentElement.removeChild(this.offLineToggleContainerNodeElement);
		}

		return this;
	}

	get layerVisibility() {
		return this.pref.sitePreferences.visibility;
	}

	/**
	 * Public reference to the map preferences
	 */
	get prefs() {
		return this.pref;
	}

	// =========================================================================================================================================================
	// Public methods to get updated from MapService events
	// =========================================================================================================================================================
	instanceReused(): void {
		// All the clients of this instance to get event handlers set up before we start triggering them
		setTimeout(() => {
			if (this.isGolfSite) {
				this.siteDataLoaded.next({
					site: this.site,
					stations: this.stations,
					holes: this.holes,
					areas: this.areas,
					geoGroups: this.geoGroups,
				});
			} else {
				this.siteDataLoaded.next({
					site: this.site,
					stations: this.stations,
					controllers: this.controllers,
					geoGroups: this.geoGroups
				});
			}
			this.addDownloadTilesControl();
			// this.addImportKmlControl();
			this.addMultiSelectControl();
		});
	}

	areaChanged(updatedArea: Area) {
		let area: any = this.areas.find((a) => a.id === updatedArea.id);
		const isArea = area != null;
		if (area == null)
			area = this.holes.find((a) => a.id === updatedArea.id);
		if (area == null) return;
		area.name = updatedArea.name;
		if (area.number !== updatedArea.number) {
			area.number = updatedArea.number;
			if (isArea) this.areas.sort((a, b) => a.number - b.number);
			else this.holes.sort((a, b) => a.number - b.number);
		}
		if (isArea) {
			area.uiSettings = updatedArea.uiSettings;
			area.polygons.forEach((polygon, index) => {
				polygon.setStyle({
					fillColor: area.uiSettings.fillColor,
					fillOpacity: area.uiSettings.fillOpacity / 100,
					color: area.uiSettings.lineColor,
					opacity: area.uiSettings.lineOpacity / 100,
					weight: area.uiSettings.lineWidth,
				});
			});
		}
		if (!isArea && area.marker != null) this.createMarkerForHole(area);
	}

	/**
	 * Makes the map center at the specified location. This was made a function to get
	 * around the zoom level setting
	 * @param latlng The location to center the map at
	 */
	centerMapOn(latlng: LatLngExpression) {
		this.map.panTo(
			latlng,
		);
	}

	companyStatusChanged() {
		if (this.handleCompanyStatusChangeTimer != null)
			clearTimeout(this.handleCompanyStatusChangeTimer);

		// Prevent calling getEventLogs too many times in response to rapid company status changes
		this.handleCompanyStatusChangeTimer = window.setTimeout(() => {
			this.handleCompanyStatusChangeTimer = null;
			this.stations.forEach((station) => {
				// RB-9921: This is a not-quite-right scenario. We really want to check whether the alert has
				// appeared/disappeared *OR* changed, but we can't do that. Unless we didn't have one and
				// still don't need one, we call createMarkerForStation to update it.
				const hasExistingAlert = station.alertPopup != null;
				const needsAlert =
					this.alertPopupHTMLForStation(station).length > 0;
				if (hasExistingAlert || needsAlert) {
					// Reprocess the station, updating the alert, or removing it if no longer needed.
					this.createMarkerForStation(station);
				}
			});
		}, 1000);
	}

	controllerCollectionChanged(change: CollectionChange) {
		if (
			change.collection == null ||
			change.collection.length === 0 ||
			!(change.collection[0] instanceof ControllerListItem)
		)
			return;
		change.collection.forEach((controller) => {
			const existingController = this.controllers.find(
				(c) => c.id === controller.id
			);
			if (existingController == null) return;
			existingController.name = controller.name;
			existingController.syncState = controller.syncState;
			existingController.gettingLogs = controller.gettingLogs;
			existingController.queued = controller.queued;
			existingController.description = controller.description;

			if (existingController.marker != null)
				this.createMarkerForController(existingController);
		});
	}

	cultureSettingsChanged() {
		this.areas.forEach((a) =>
			a.geoItemIds.forEach((id) =>
				this.updateAreaLabelMarker(a, id, false, false)
			)
		);
	}

	diagnosticDataReceived(data: any) {
		// Update local StationWithMapData copies including voltage or feedback data of active diagnostics. This should
		// match the values we would normally get if we reloaded the station list from the API.
		if (data instanceof IcVoltagePollData) {
			const stationsUpdated =
				RbUtils.Stations.updateStationDiagnosticResult_Voltage(
					this.stations,
					[data as IcVoltagePollData]
				);
			stationsUpdated.forEach((s) =>
				this.createMarkerForStation(s as StationWithMapInfoLeaflet)
			);
		} else if (data instanceof IcShortAddressPollData) {
			const stationsUpdated =
				RbUtils.Stations.updateStationDiagnosticResult_ShortAddress(
					this.stations,
					[data as IcShortAddressPollData]
				);
			stationsUpdated.forEach((s) =>
				this.createMarkerForStation(s as StationWithMapInfoLeaflet)
			);
		}
	}

	eventLogsStateChanged(state: ControllerGetLogsState) {
		const controller = this.controllers.find(
			(c) => c.id === state.controllerId
		);
		if (controller == null || controller.gettingLogs === state.gettingLogs)
			return;
		controller.gettingLogs = state.gettingLogs;
		if (controller.marker != null)
			this.createMarkerForController(controller);
	}

	manualControlStateChanged(
		controllerId: number,
		state?: ManualControlState
	) {
		const controller = this.controllers.find((c) => c.id === controllerId);
		if (controller == null) return;
		controller.isConnecting =
			state != null &&
			!state.isConnecting.error &&
			!state.isConnecting.isUpdating &&
			!state.hasReceivedConnectDataPack;
		controller.isConnected =
			state != null &&
			!state.isConnecting.error &&
			!state.isConnecting.isUpdating &&
			state.hasReceivedConnectDataPack;
		if (controller.marker != null)
			this.createMarkerForController(controller);

		if (!this.isGolfSite) {
			// RB-12560: Reload latest station status on Map
			this.stationManager.getStationsList(controllerId, true).pipe(take(1))
				.subscribe((stationListItem) => {
					this.stationsListChanged(new StationsListChange(controllerId, stationListItem, true));
				});
		}
	}

	sitesUpdated(data: any) {
		if (data != null && data.address != null && this.pref != null)
			this.pref.sitePreferences.center = null;
		this.siteManager
			.getSite(this.siteId)
			.pipe(take(1))
			.subscribe((site: Site) => {
				this.site = site;
				this.lookupSiteAddress({ centerOnAddress: true });
			});
	}

	stationsListChanged(change: StationsListChange) {
		if (!change.isStatusUpdateOnly) return;

		if (!this.isGolfSite && this.controllersHaveStationRunning.length) {
			// Remove this controller before recheck it has station running
			this.controllersHaveStationRunning = this.controllersHaveStationRunning.filter(
				item => item !== change.controllerId
			);
		}

		this.updateRunningStatusForStations(
			change.stations,
			change.updatedStationId
		);
	}

	holeAdded(holeId: number, updatedGeoGroups: GeoGroup[]) {
		const hole = this.holes.find((h) => h.id === holeId);
		if (hole == null) return;

		this.geoGroups = lodash.cloneDeep(updatedGeoGroups);
		this.createMarkerForHole(hole);
		this.mapService.multiSelectService.setIcon({ holeId, areaId: 0, show: true });
	}

	holeRemoved(holeId: number): void {
		const hole = this.holes.find((h) => h.id === holeId);
		if (hole == null) return;

		const geoGroup = this.geoGroups.find(
			(r) => r.areaLevel2Id === holeId && r.areaLevel3Id == null
		);
		if (geoGroup == null) return;

		geoGroup.geoItem = [];

		// Clear Marker
		this.removeLayerFromMap(hole.marker);
		hole.marker = null;
		this.mapService.multiSelectService.setIcon({ holeId, areaId: 0, show: false });
	}

	stationGeoAreaAdded(
		stationId: number,
		geoItem: GeoItem,
		areasStyleSetting: AreaUiSettings,
		polygon: { latitude: number, longitude: number }[],
		isNewGeoGroup: boolean
	) {
		this.geoGroupManager.getGeoGroupForStation(this.siteId, stationId, true).subscribe((geoGroup) => {
			const geoGroupIndex = this.stationAreas?.findIndex((gg) =>
				gg.stationId === stationId
			);
			if (isNewGeoGroup) {
				const updateGeoGroup = <StationAreaWithMapInfoLeaflet>lodash.cloneDeep(geoGroup);
				updateGeoGroup.polygons = [];
				updateGeoGroup.labels = [];
				updateGeoGroup.geoItemIds = [];
				updateGeoGroup.editModes = [];
				updateGeoGroup.shapeEditingFunctions = [];
				updateGeoGroup.geoItem = geoGroup.geoItem;
				updateGeoGroup.squareAreas = [];
				this.stationAreas.push(updateGeoGroup);
			} else {
				this.stationAreas[geoGroupIndex].geoItem.push(geoItem);
			}

			this.reloadStationHalo(stationId);

			const currentGeoGroup = this.stationAreas.find((gg) =>
				gg.id === geoItem.geoGroupId
			);

			if (currentGeoGroup) {
				const labelAndPolygon = this.createMarkerForStationArea(
					stationId,
					geoItem,
					currentGeoGroup,
					areasStyleSetting,
					polygon
				);

				currentGeoGroup.geoItemIds.push(geoItem.id);
				currentGeoGroup.polygons.push(labelAndPolygon.polygon);
				currentGeoGroup.labels.push(labelAndPolygon.label);
				currentGeoGroup.squareAreas.push(0);
				currentGeoGroup.editModes.push(false);

				this.stationAreas = [...this.stationAreas.filter(s => s.stationId !== stationId), currentGeoGroup];
				if (this.pref.sitePreferences.visibility.showingStationGeoAreas) {
					this.addLayerToMap(labelAndPolygon.polygon);
				}
			}
		});
	}

	areaAdded(
		holeId: number,
		areaId: number,
		geoItem: GeoItem,
		updatedGeoGroups: GeoGroup[],
		polygon: { latitude: number; longitude: number }[]
	) {
		const area = this.areas.find((a) => a.id === areaId);
		if (area == null) return;

		area.geoItemIds.push(geoItem.id);
		area.squareAreas.push(0);
		area.editModes.push(false);

		this.geoGroups = lodash.cloneDeep(updatedGeoGroups);
		const geoGroup = updatedGeoGroups.find(x => x.id === geoItem.geoGroupId);
		const labelAndPolygon = this.createMarkerForArea(
			area,
			geoGroup,
			geoItem,
			polygon
		);
		area.polygons.push(labelAndPolygon.polygon);
		area.labels.push(labelAndPolygon.label);

		this.addLayerToMap(labelAndPolygon.polygon);
		this.mapService.multiSelectService.setIcon({ holeId, areaId, show: true });
	}

	addLayerToMap(layer: Layer, properties?: any, force: boolean = false) {
		if (force) {
			this.map.addLayer(layer);
		}

		if (!this.leafletLayers.includes(layer)) {
			this.leafletLayers.push(layer);

			// if (properties && properties.renderer && properties.renderer._container) {
			// 	L.DomUtil.addClass(properties.renderer._container, `kmz-${properties.fileName} no-pointer-events`);
			// }
		}
	}

	areaGeoItemRemoved(
		holeId: number,
		areaId: number,
		geoItemId: number
	): void {
		const geoGroup = this.geoGroups.find((gg) =>
			gg.geoItem.some((gi) => gi.id === geoItemId)
		);
		if (geoGroup != null) {
			geoGroup.geoItem = geoGroup.geoItem.filter(
				(rl) => rl.id !== geoItemId
			);
		}

		const area = this.areas.find((a) => a.id === areaId);
		if (area == null) return;

		// Clear Markers
		const itemIndex = area.geoItemIds.findIndex((id) => id === geoItemId);
		this.removeLayerFromMap(area.polygons[itemIndex]);
		this.removeLayerFromMap(area.labels[itemIndex]);
		area.polygons.splice(itemIndex, 1);
		area.labels.splice(itemIndex, 1);
		area.geoItemIds.splice(itemIndex, 1);
		area.squareAreas.splice(itemIndex, 1);
		this.mapService.multiSelectService.setIcon({ holeId, areaId, show: false });
	}

	stationGeoAreaItemRemoved(
		geoItemId: number
	): void {
		const geoGroup = this.stationAreas.find((gg) =>
			gg.geoItem.some((gi) => gi.id === geoItemId)
		);

		// Clear Markers
		const itemIndex = geoGroup?.geoItemIds.findIndex((id) => id === geoItemId);
		this.removeLayerFromMap(geoGroup?.polygons[itemIndex]);
		this.removeLayerFromMap(geoGroup?.labels[itemIndex]);
		geoGroup?.polygons.splice(itemIndex, 1);
		geoGroup?.geoItemIds.splice(itemIndex, 1);
		geoGroup?.labels.splice(itemIndex, 1);
		geoGroup?.geoItem.splice(itemIndex, 1);
		this.reloadStationHalo(geoGroup?.stationId);
	}

	geoItemUpdated(geoItem: GeoItem): void {
		// Search all geo groups for matching GeoItems (there should only be one)
		this.geoGroups.forEach((gg) =>
			gg.geoItem
				.filter((gi) => gi.id === geoItem.id)
				.forEach((gi) => Object.assign(gi, geoItem))
		);
	}

	stationAreaGeoItemUpdated(geoItemId: number) {
		if (this.stationAreas?.length) {
			const geoGroup = this.stationAreas?.find((gg) =>
				gg.geoItem?.some((gi) => gi.id === geoItemId)
			);
			if (geoGroup) {
				const geoItemIndex = geoGroup.geoItemIds?.findIndex((id) => id === geoItemId);
				if (geoItemIndex === -1) return;
				const station = this.stations?.find((s) => s.id === geoGroup.stationId);
				this.updateStationAreaLabelMarker(
					station?.id,
					station?.name,
					geoGroup,
					geoItemId,
					false,
					false
				);
			}
		}
	}

	userLocationUpdated(position: any) {
		console.log("User location updated...")
		const pos = L.latLng(position.coords.latitude, position.coords.longitude);
		if (this.userLocationMarker == null) {
			this.userLocationMarker = L.marker(pos, {
				icon: new L.DivIcon({
					className: 'me-marker',
					html: '<div class="outer-circle"><div class="inner-circle"><span class="material-icons">face</span></div></div>',
					iconSize: [30, 30],
					iconAnchor: [15, 15],
				}),
			});

			// Create the standard location accuracy circle.
			this.userLocationAccuracyCircle = circle(pos, {
				radius: position.coords.accuracy,

				// (R, G, B) = (0, 135, 81) Standard Rain Bird green. Stroke is the outline. Fill is the body of the
				// circle.
				color: MapInfoLeaflet.locationAccuracyCircleStrokeColor,
				weight: MapInfoLeaflet.locationAccuracyCircleStrokeWeight,
				opacity: MapInfoLeaflet.locationAccuracyCircleStrokeOpacity,

				fillColor: MapInfoLeaflet.locationAccuracyCircleFillColor,
				fillOpacity: MapInfoLeaflet.locationAccuracyCircleFillOpacity,
			});

			this.addLayerToMap(this.userLocationMarker);
			this.addLayerToMap(this.userLocationAccuracyCircle);
		} else {
			this.userLocationMarker.setLatLng(pos);
			this.userLocationAccuracyCircle.setLatLng(pos);
			this.userLocationAccuracyCircle.setRadius(position.coords.accuracy);
		}

		// If the accuracy circle is really small, hide it so it's not creating a halo around our current location
		// marker; otherwise show it.
		const showCircle =
			position.coords.accuracy == null ||
			position.coords.accuracy >
			MapInfoLeaflet.locationAccuracyToHideCircle;
		this.userLocationAccuracyCircle.options.opacity = showCircle
			? MapInfoLeaflet.locationAccuracyCircleStrokeOpacity
			: 0;
		this.userLocationAccuracyCircle.options.fillOpacity = showCircle
			? MapInfoLeaflet.locationAccuracyCircleFillOpacity
			: 0;

		// Add the tool usable for centering the map on the user's current location.
		if (!this.addedCenterOnLocation) {
			this.addedCenterOnLocation = true;
			const centerOnLocationDiv = document.createElement('div');
			this.locationControl = CenterOnLocationControlLeaflet.create(
				this,
				this.translate,
				centerOnLocationDiv
			);
			this.map.addControl(this.locationControl);
		}

		// If we need to show the tracking data for debugging, add that control to the map.
		this.checkTrackingDataDisplay();

		// See if we have a pending zoom operation
		if (this.zoomRadiusInMeters > 0) {
			const mapNode = this.map.getContainer();
			const numPixels = Math.min(
				mapNode.getBoundingClientRect().width,
				mapNode.getBoundingClientRect().height
			);
			if (numPixels === 0) return; // Try again later after the map has some size

			// Formula based on this discussion thread: https://groups.google.com/forum/#!msg/google-maps-js-api-v3/hDRO4oHVSeM/osOYQYXg2oUJ
			// meters_per_pixel = 156543.03392 * Math.cos(position.coords.latitude * Math.PI / 180) / Math.pow(2, zoom)
			const zoom = Math.log2(
				(156543.03392 *
					Math.cos((position.coords.latitude * Math.PI) / 180) *
					numPixels) /
				this.zoomRadiusInMeters
			);
			this.map.setZoom(zoom);
			this.zoomRadiusInMeters = 0;
		}
	}

	areaGeoItemUpdated(areaId: number, geoItemId: number, newPoints: LatLng[]) {
		const area = this.areas.find((a) => a.id === areaId);
		if (area == null) return;
		const geoItemIndex = area.geoItemIds.findIndex(
			(id) => id === geoItemId
		);
		if (geoItemIndex === -1) return;

		//  %%
		// area.polygons[geoItemIndex].setLatLngs(newPoints);

		this.updateAreaLabelMarker(area, geoItemId, false, false);
	}

	controllerLocationUpdated(
		id: number,
		loc: { latitude: any; longitude: any }
	) {
		const controller = this.controllers.find((c) => c.id === id);
		if (controller == null) return;

		controller.latitude = loc.latitude;
		controller.longitude = loc.longitude;
	}

	// =========================================================================================================================================================
	// Public methods to get/update properties (generally used by MapComponent)
	// =========================================================================================================================================================
	changeMoveAbility(moveable: boolean) {
		this.pref.sitePreferences.visibility.moveable = moveable;
		this.setItemMoveAbility();
		this.pref.save();
	}

	getDefaultDuration(selectedItem: number, contextMenuInfo?: any): Duration {
		if (selectedItem !== RbEnums.Map.StationContextMenu.Start && selectedItem !== RbEnums.Map.AreaContextMenu.Start &&
			selectedItem !== RbEnums.Map.HoleContextMenu.Start) return null;

		let stationIds = [];
		if (!this.selectedStationsCount) {
			stationIds = this.stationIdsForMenuSelection(contextMenuInfo);
		} else {
			stationIds = this.mapService.multiSelectService.getStationId();
		}
		if (stationIds.length === 0) return null;
		const station = this.stations.find((s) => s.id === stationIds[0]);
		return RbUtils.Conversion.convertTicksToDuration(
			station.defaultRunTimeLong
		);
	}

	startPrograms(programIds: number[]) {
		this.manualOpsManager.startPrograms(programIds).subscribe();
	}

	textColorChanged(color: string) {
		this.pref.sitePreferences.visibility.textColor = color;
		this.pref.save();
		this.updateAllMarkers(true);
	}

	holeHasLocation(hole): boolean {
		const geoGroup = this.geoGroups.find(
			(r) =>
				r.siteId === hole.siteId &&
				r.areaLevel2Id === hole.id &&
				r.areaLevel3Id == null
		);
		if (geoGroup == null) return false;
		return geoGroup.geoItem.length > 0;
	}

	/**
	 * Show or hide hole markers on the map
	 *
	 * @param visible Whether to show or hide hole markers
	 * @param savePreference Whether the new visible value should change the visibility setting and be saved to the database or not
	 */
	showHoles(visible: boolean, savePreference = true) {
		this.holes
			.filter((s) => s.marker != null)
			.forEach((s) => {
				if (visible) {
					this.addLayerToMap(s.marker);
				} else {
					this.removeLayerFromMap(s.marker);
				}
			});

		if (savePreference) {
			this.pref.sitePreferences.visibility.showingHoles = visible;
			this.pref.save();
		}
	}

	/**
	 * Show or hide area polygons on the map
	 *
	 * @param visible Whether to show or hide area polygons
	 * @param savePreference Whether the new visible value should change the visibility setting and be saved to the database or not
	 */
	showAreas(visible: boolean, savePreference = true) {
		this.areas.forEach((a) => {
			a.polygons.forEach((p) => {
				if (visible) {
					this.addLayerToMap(p);
				} else {
					this.removeLayerFromMap(p);
				}
			});
			a.labels.forEach((l) => {
				if (visible) {
					l.openTooltip();
				} else {
					l.closeTooltip();
				}
			});
		});

		if (savePreference) {
			this.pref.sitePreferences.visibility.showingAreas = visible;
			this.pref.save();
		}
	}

	showControllers(visible: boolean) {
		this.pref.sitePreferences.visibility.showingControllers = visible;
		this.controllers
			.filter((c) => c.marker != null)
			.forEach((s) => {
				if (visible) {
					this.addLayerToMap(s.marker);
					this.addAllServerClientRelationship();
				} else {
					this.removeLayerFromMap(s.marker);
					this.removeAllServerClientRelationship();
				}
			});
		this.pref.save();
	}

	showControllersName(visible: boolean) {
		this.pref.sitePreferences.visibility.showingControllersName = visible;
		this.controllers
			.filter((c) => !!c.marker)
			.forEach((s) => {
				this.createControllerName(s);
			});
		this.pref.save();
	}

	showServerClientStatus(visible: boolean) {
		this.pref.sitePreferences.visibility.showingServerClientStatus = visible;
		this.controllers
			.filter((c) => c.marker != null
				&& (c.iqNetType === RbEnums.Common.IqNetType.IQNetServer || c.iqNetType === RbEnums.Common.IqNetType.IQNetClient))
			.forEach((s) => {
				this.createMarkerForController(s);
			});
		this.pref.save();
	}

	showServerClientRelationships(visible: boolean) {
		this.pref.sitePreferences.visibility.showingServerClientRelationships = visible;
		this.controllers
			.filter((c) => c.marker != null)
			.forEach((s) => {
				if (visible) {
					this.addAllServerClientRelationship();
				} else {
					this.removeAllServerClientRelationship();
				}
			});
		this.pref.save();
	}

	showSensors(visible: boolean, savePreference = true) {
		if (savePreference) {
			this.pref.sitePreferences.visibility.showingSensors = visible;
			this.pref.save();
		}
		this.sensors
			.filter((c) => c.marker != null)
			.forEach((s) => {
				if (visible) {
					this.addLayerToMap(s.marker);
				} else {
					this.removeLayerFromMap(s.marker);
				}
			});
	}

	showStickyNotes(visible: boolean, savePreference = true) {
		if (savePreference) {
			this.pref.sitePreferences.visibility.showingStickyNotes = visible;
			this.pref.save();
		}
		if (visible) {
			this.stickyNoteManager.getStickyNotes(this.siteId).subscribe(stickyNotes => {
				// RB-14217: IQ4 - Retain the last position before generate StickyNotes
				const mapCenter = this.pref.sitePreferences.center;
				this.stickyNotes = stickyNotes;
				this.displayAllStickyNotes();
				if (this.stickyNotes?.length) {
					// RB-14217: IQ4 - Revisiting the Map is properly retaining last position
					this.map.setView(
						[
							mapCenter.latitude,
							mapCenter.longitude,
						]
					);
				}
			});
		} else {
			if (this.popups) {
				this.removeAllStickyNotes();
			}
		}
	}

	displayAllStickyNotes() {
		this.stickyNotes.forEach((stickyNote) => {
			const popup = this.createPopupForStickyNote(stickyNote);
			this.popups.push({ id: stickyNote.id, item: popup });
		});
	}

	removeAllStickyNotes() {
		this.popups.forEach(popup => popup.item?.removeFrom(this.map));
		this.popups = [];
	}

	minimizeAllStickyNotes() {
		this.stickyNotes.forEach(stickyNote => {
			this.updateStickyNoteStatus({ ...stickyNote, isMinimized: true });
		});
	}

	addStickyNote(latlng?: L.LatLng) {
		if (!this.canAddNewStickyNote) {
			return;
		}
		const position = latlng ? latlng : this.map.getCenter();
		const userProfile = this.authManager.getUserProfile();
		const id = 0;

		const stickyNote: StickyNote = {
			id,
			companyId: userProfile.companyId,
			siteId: this.siteId,
			latitude: position.lat,
			longitude: position.lng,
			content: '',
			isMinimized: false
		}

		const popup = this.createPopupForStickyNote(stickyNote);
		this.popups.push({ id, item: popup });
		this.stickyNotes = [...this.stickyNotes, stickyNote];
	}

	createPopupForStickyNote(stickyNote: StickyNote): L.Popup {
		const id = stickyNote.id;
		if ((!id && id !== 0) || stickyNote.latitude == null || stickyNote.longitude == null) {
			return null;
		}
		const buttonGroups = id === 0
			? `<button id="sticky-note-cancel-button-${id}">Cancel</button>
			   <button id="sticky-note-save-button-${id}">Save</button>`
			: `<button id="sticky-note-delete-button-${id}">Delete</button>
			   <button id="sticky-note-cancel-button-${id}">Cancel</button>
			   <button id="sticky-note-save-button-${id}">Save</button>`

		const htmlContent = stickyNote?.isMinimized
			? `<i class="material-icons minimized-sticky-note" id="minimized-sticky-note-${id}">textsms</i>`
			: `<div class="popup-container" id="sticky-note-popup-${id}">
					<div class="popup-content" id="popup-content-${id}">
						<span class="minimize-icon" id="sticky-note-minimize-icon-${id}">${id ? '_' : ''}</span>
						<textarea id="textarea-${id}">${stickyNote.content}</textarea>
					</div>
					<div class="button-group-container" id="button-group-${id}">
						${buttonGroups}
					</div>
				</div>`;

		const options = {
			closeButton: false,
			closeOnClick: false,
			closeOnEscapeKey: false,
			className: `${stickyNote?.isMinimized ? 'minimized-sticky-note-popup' : 'sticky-note-popup'} sticky-note-popup-${id}`,
			draggable: true
		};

		const popup = L.popup(options)
			.setLatLng([stickyNote.latitude, stickyNote.longitude])
			.setContent(htmlContent)
			.addTo(this.map);

		const popupElement = this.leafletMapContainerNodeElement.querySelector(`.leaflet-popup.sticky-note-popup.sticky-note-popup-${id}`) as HTMLElement;

		if (popupElement) {
			const draggable = new L.Draggable(popupElement);
			const moveable = this.areItemsMovable && this.pref.sitePreferences.visibility.moveable;
			if (moveable) {
				draggable.enable();
			} else {
				draggable.disable();
			}
			draggable.on('dragend', (event) => {
				const target = event.target?._newPos;
				const newPosition = this.map.layerPointToLatLng(target);
				popup.setLatLng(newPosition);
				stickyNote.latitude = newPosition.lat;
				stickyNote.longitude = newPosition.lng;
				if (stickyNote.id !== 0) {
					this.stickyNoteManager.updateStickyNote(
						stickyNote.id, { latitude: newPosition.lat, longitude: newPosition.lng }
					).subscribe();
				}
			});
			this.draggableStickyNotesList.push(draggable);
		}

		this.addStickyNoteButtonsClickHandlers(stickyNote);
		return popup;
	}

	deleteStickyNote(id: number): void {
		const mbi = MessageBoxInfo.create(
			this.translate.instant('STRINGS.DELETE'),
			this.translate.instant('STRINGS.DELETE_STICKY_NOTE'),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				this.stickyNoteManager.deleteStickyNote(id).subscribe(() => {
					this.removePopup(id);
					this.removeStickyNoteData(id);
				});
			},
			null,
			RbEnums.Common.MessageBoxButtons.YesNo
		);
		this.broadcastService.showMessageBox.next(mbi);
	}

	cancelPopup(id: number): void {
		if (id === 0) {
			this.removePopup(id);
			this.removeStickyNoteData(id);
		} else {
			const original = this.stickyNotes.find(s => s.id === id);
			const content = this.leafletMapContainerNodeElement.querySelector(`#textarea-${id}`) as HTMLInputElement;
			if (content) {
				content.value = original.content;
			}
			this.hideButtonGroup(id);
		}
	}

	saveStickyNote(stickyNote: StickyNote): void {
		if (stickyNote.id === 0) {
			const { id, ...body } = stickyNote;
			this.stickyNoteManager.createStickyNote(body).subscribe(data => {
				this.removePopup(id);
				this.removeStickyNoteData(id);
				const updated = { ...data };
				const newPopup = this.createPopupForStickyNote(updated);
				this.popups = [...this.popups, { id: updated.id, item: newPopup }];
				this.stickyNotes.push(updated);
			});
		} else {
			this.stickyNoteManager.updateStickyNote(stickyNote.id, { content: stickyNote.content })
				.subscribe(() => {
					const updated = { ...stickyNote, content: stickyNote.content };
					this.stickyNotes = [...this.stickyNotes.filter(s => s.id !== stickyNote.id), updated];
					this.hideButtonGroup(stickyNote.id);
				});
		}
	}

	hideButtonGroup(id: number) {
		const buttons = document.querySelector(`#button-group-${id}`) as HTMLButtonElement;
		if (buttons) {
			buttons.style.display = 'none';
		}
	}

	addStickyNoteButtonsClickHandlers(note: StickyNote) {
		const stickyNote = { ...note };
		const id = stickyNote.id;

		if (stickyNote?.isMinimized) {
			const minimizedStickyNote = document.querySelector(`#minimized-sticky-note-${id}`) as HTMLElement;
			minimizedStickyNote?.addEventListener('click', (event) => {
				event.preventDefault();
				this.updateStickyNoteStatus({ ...stickyNote, isMinimized: false });
			});
		} else {
			const content = document.querySelector(`#popup-content-${id}`) as HTMLElement;
			const textarea = document.querySelector(`#textarea-${id}`) as HTMLTextAreaElement;
			const deleteButton = document.querySelector(`#sticky-note-delete-button-${id}`) as HTMLButtonElement;
			const cancelButton = document.querySelector(`#sticky-note-cancel-button-${id}`) as HTMLButtonElement;
			const saveButton = document.querySelector(`#sticky-note-save-button-${id}`) as HTMLButtonElement;
			const minimizeIcon = document.querySelector(`#sticky-note-minimize-icon-${id}`);
			const buttonGroup = document.querySelector(`#button-group-${id}`) as HTMLElement;

			buttonGroup.style.display = 'none';
			saveButton.disabled = true;

			content?.addEventListener('click', (event) => {
				event.preventDefault();
				buttonGroup.style.display = 'block';
			});

			deleteButton?.addEventListener('click', (event) => {
				event.preventDefault();
				this.deleteStickyNote(stickyNote.id);
			});

			cancelButton?.addEventListener('click', (event) => {
				event.preventDefault();
				this.cancelPopup(stickyNote.id);
			});

			saveButton?.addEventListener('click', (event) => {
				event.preventDefault();
				this.saveStickyNote(stickyNote);
			});

			minimizeIcon?.addEventListener('click', (event) => {
				event.preventDefault();
				if (stickyNote.id) {
					this.updateStickyNoteStatus({ ...stickyNote, isMinimized: true });
				}
			});

			textarea?.addEventListener('input', (event: any) => {
				const content = event.target.value?.trim();
				saveButton.disabled = !content;
				stickyNote.content = content;
			});
		}
	}

	updateStickyNoteStatus(stickyNote: StickyNote) {
		this.stickyNoteManager.updateStickyNote(stickyNote.id, { isMinimized: stickyNote?.isMinimized })
			.subscribe(() => {
				const id = stickyNote.id;
				this.removePopup(id);
				const newPopup = this.createPopupForStickyNote(stickyNote);
				this.popups = [...this.popups, { id, item: newPopup }];
			});
	}

	removePopup(id: number) {
		const popup = this.popups.find(popup => popup.id === id);
		if (popup && popup.item) {
			popup.item.removeFrom(this.map);
		}
		this.popups = this.popups.filter(popup => popup.id !== id);
	}

	removeStickyNoteData(id: number) {
		this.stickyNotes = this.stickyNotes.filter(s => s.id !== id);
	}

	// Golf Notes

	showNotes(visible: boolean, savePreference = true) {
		if (savePreference) {
			this.pref.sitePreferences.visibility.showingNotes = visible;
			this.pref.save();
		}
		const badges = this.leafletMapContainerNodeElement.querySelectorAll('.station.has-note');
		if (visible) {
			const notesLength = this.stickyNoteManager.getNotesLength();
			if (!notesLength) {
				this.stickyNoteManager.setSiteId(this.siteId);
				this.stickyNoteManager.setStationAnchorNames(this.stations);
				this.displayAllNotes();
			} else {
				this.displayAllNotes();
			}
		} else {
			if (this.popups) {
				this.removeAllStickyNotes();
			}
			badges.forEach(badge => {
				badge.classList.remove('has-note');
			});
		}
	}

	/**
	 * Displays all active notes, processing each note based on its type and properties.
	 * This function subscribes to the notes observable and filters out active notes.
	 * It then processes each active note, adding markers or badges accordingly.
	 */
	displayAllNotes() {
		this.stickyNoteManager.notes$.pipe(take(1)).subscribe(notes => {
			const activeNotes = this.filterActiveNotes(notes);
			activeNotes.forEach(note => {
				this.processNote(note);
			});
		});
	}

	/**
	 * Filters out active notes from the provided array of notes.
	 * @param notes Array of notes to filter.
	 * @returns An array containing only active notes.
	 */
	filterActiveNotes(notes: Note[]): Note[] {
		return notes.filter(note => note.status === RbEnums.Note.NoteStatus.Active);
	}

	/**
	 * Processes a single note based on its type and properties.
	 * @param note The note to be processed.
	 */
	processNote(note: Note) {
		if (note.attachedToType === RbEnums.Note.NoteAnchor.Station) {
			this.processStationNote(note);
		} else if (note.attachedToType === RbEnums.Note.NoteAnchor.Note && !note.attachedToId) {
			this.processMapNote(note);
		} else if (!note.attachedToId) {
			// Handle other cases if necessary
		}
	}

	/**
	 * Processes a note attached to a station, adding a note badge to the corresponding station marker.
	 * @param note The note attached to the station.
	 */
	processStationNote(note: Note) {
		const foundStation = this.stations.find(s => s.id === note.attachedToId);
		if (foundStation) {
			this.addNoteBadgeToStation(foundStation);
		}
	}

	/**
	 * Processes a note attached to the map, creating a marker and adding a popup for the note.
	 * @param note The note attached to the map.
	 */
	processMapNote(note: Note) {
		const layer = this.createMarkerForNote(note);
		this.popups = [...this.popups, { id: note.id, item: layer }];
	}

	/**
	 * Creates a marker for the given note with the appropriate background color based on its priority.
	 * @param note The note for which to create the marker.
	 * @returns The created marker object.
	 */
	createMarkerForNote(note: Note): L.Marker {
		const bgClass = this.getBackgroundClassForPriority(note.notePriority);
		const animated = this.pref.sitePreferences.visibility.showingNotesAnimation;
		const icon = L.divIcon({
			className: `note-marker ${animated ? 'animate' : ''}`,
			html: `<div id="${note.attachedToType}-${note.id}" class="note-container ${bgClass}"><i class="mdi mdi-message-processing"></i></div>`,
			iconSize: [30, 30],
			iconAnchor: [15, 15]
		});

		const noteMarker = L.marker([note.latitude, note.longitude], { icon: icon })
			.addTo(this.map)
			.on('click', event => this.handleMarkerClicked(event))
			.on('contextmenu', event => this.handleMarkerClicked(event, true));

		return noteMarker;
	}

	getBackgroundClassForPriority(priority: RbEnums.Note.NotePriority): string {
		switch (priority) {
			case RbEnums.Note.NotePriority.Urgent: return 'bg-red';
			case RbEnums.Note.NotePriority.High: return 'bg-orange';
			case RbEnums.Note.NotePriority.Moderate: return 'bg-yellow text-carbon';
			case RbEnums.Note.NotePriority.Low: return 'bg-light-green text-carbon';
			default: return '';
		}
	}

	updateMarkerForNote(note: Note) {
		const noteMarker = document.getElementById(note.attachedToType + '-' + note.id);

		const removeClassesStartingWith = (prefix: string) => {
			const classesToRemove = [];
			for (let i = 0; i < noteMarker.classList.length; i++) {
				const className = noteMarker.classList[i];
				if (className.startsWith(prefix)) {
					classesToRemove.push(className);
				}
			}
			classesToRemove.forEach(className => noteMarker.classList.remove(className));
		};

		removeClassesStartingWith('bg-');
		removeClassesStartingWith('text-');

		const bgClass = this.getBackgroundClassForPriority(note.notePriority);
		noteMarker.classList.add(bgClass);
	}

	addNoteBadgeToStation(station: StationWithMapInfoLeaflet) {
		if (station.marker && station.marker['_icon']) {
			DomUtil.addClass(station.marker['_icon'], 'has-note');
		}
	}

	removeNoteBadgeToStation(station: StationWithMapInfoLeaflet) {
		if (station.marker && station.marker['_icon']) {
			DomUtil.removeClass(station.marker['_icon'], 'has-note');
		}
	}
	onNoteCreated(note: Note) {
		if (note.attachedToType === RbEnums.Note.NoteAnchor.Station) {
			const foundStation = this.stations.find(s => s.id === note.attachedToId);
			this.addNoteBadgeToStation(foundStation);
		} else if (note.attachedToType === RbEnums.Note.NoteAnchor.Note && !note.attachedToId) {
			const layer = this.createMarkerForNote(note);
			this.popups = [...this.popups, { id: note.id, item: layer }];
		}
	}

	onNoteDeleted(id: number) {
		this.removePopup(id);
		this.removeNoteData(id);
	}

	removeNoteData(id: number) {
		this.stickyNoteManager.removeNoteById(id)
	}

	showSensorNames(visible: boolean) {
		this.pref.sitePreferences.visibility.showingSensorNames = visible;
		if (visible === true) {
			document.querySelectorAll('.station .sensor-name').forEach(function (el) {
				el['style']['display'] = 'block';
			});
		} else {
			document.querySelectorAll('.station .sensor-name').forEach(function (el) {
				el['style']['display'] = 'none';
			});
		}
		this.pref.save();
	}

	/**
	 * Show or hide station markers on the map
	 *
	 * @param visible Whether to show or hide station markers
	 * @param savePreference Whether the new visible value should change the visibility setting and be saved to the database or not
	 * @param mapLayer The map layer in Map
	 */
	showStations(visible: boolean, savePreference = true, mapLayer: RbEnums.Map.MapLayer) {

		// This one goes before because it is needed in createMarkerForStation
		if (savePreference) {
			if (!this.isGolfSite && mapLayer === RbEnums.Map.MapLayer.MasterValves) {
				this.pref.sitePreferences.visibility.showingMasterValves = visible;
			} else {
				this.pref.sitePreferences.visibility.showingStations = visible;
			}
			this.pref.save();
		}

		if (!this.isGolfSite && mapLayer === RbEnums.Map.MapLayer.MasterValves) {
			// Only for Master Valves in IQ4
			this.stations
				.filter((s) => s.marker != null && s.master === true)
				.forEach((station) => {
					this.createMarkerForStation(station, visible);
				});
		} else {
			// RB-9921: Take care with assuming all stations disappear when visible is false! Not so if the have alerts.
			this.stations
				// We don't want to show master valves if station slider is on the we need to filter again.
				// IQ4: The master valve need to be filtering
				.filter((s) => mapLayer === RbEnums.Map.MapLayer.Stations && this.isGolfSite ? s.marker != null : s.marker != null && !s.master)
				.forEach((station) => {
					// visible variable is equal false when we have toggle off stations in common layer.
					// Otherwise visible variable is equal true
					this.createMarkerForStation(station, visible);
				});
		}
	}

	/**
	 * Show or hide station (master valve) name on the map
	 *
	 * @param visible Whether to show or hide station (master valve) name
	 * @param mapLayer The map layer in Map
	 */
	showStationNames(visible: boolean, mapLayer: RbEnums.Map.MapLayer) {
		if (!this.isGolfSite && mapLayer === RbEnums.Map.MapLayer.MasterValves) {
			this.pref.sitePreferences.visibility.showingMasterValvesName = visible;
			this.stations
				.filter((s) => !!s.marker && s.master)
				.forEach(s => s.showName(visible));
		} else {
			this.pref.sitePreferences.visibility.showingStationNames = visible;
			this.stations
				.filter((s) => !!s.marker && !s.master)
				.forEach(s => s.showName(visible));
		}
		this.pref.save();
	}

	showStationRuntimes(visible: boolean) {
		this.pref.sitePreferences.visibility.showingStationRuntimes = visible;
		this.stations.forEach(s => s.showRuntime(visible));
		this.pref.save();
	}

	showAdjustments(visible: boolean) {
		this.pref.sitePreferences.visibility.showingStationAdjustments = visible;
		this.stations.forEach(s => s.showAdjustment(visible));
		this.pref.save();
		this.showStationAdjustments.next(visible);
	}

	showStationNozzleColors(visible: boolean) {
		this.pref.sitePreferences.visibility.showingNozzleColors = visible;
		this.stations.forEach(s => s.showNozzleColor(visible));
		this.pref.save();
	}

	showNoteAnimation(visible: boolean) {
		this.pref.sitePreferences.visibility.showingNotesAnimation = visible;
		this.toggleNoteAnimation(visible);
		this.pref.save();
	}

	showStationGeoAreas(visible: boolean, mapLayer: RbEnums.Map.MapLayer, savePreference = true) {
		if (savePreference) {
			if (!this.isGolfSite && mapLayer === RbEnums.Map.MapLayer.MasterValvesAreas) {
				this.pref.sitePreferences.visibility.showingMasterValvesGeoAreas = visible;
			} else {
				this.pref.sitePreferences.visibility.showingStationGeoAreas = visible;
			}

			this.pref.save();
		}
		if (visible) {
			this.stationAreas.forEach((gg) => {
				const station = this.stations.filter(s => s.id === gg.stationId);
				if (station != null && station.length === 1) {
					if (station[0].master && mapLayer === RbEnums.Map.MapLayer.MasterValvesAreas) {
						gg.polygons.forEach((p, index) => {
							this.addLayerToMap(p);
						});
					} else if (!station[0].master && mapLayer === RbEnums.Map.MapLayer.StationAreas) {
						gg.polygons.forEach((p, index) => {
							this.addLayerToMap(p);
						});
					}
				}
			});
		} else {
			this.isShowAllCalculateAreas = false;
			this.stationAreas.forEach((gg) => {
				const station = this.stations.filter(s => s.id === gg.stationId);
				if (station != null && station.length === 1) {
					if (station[0].master && mapLayer === RbEnums.Map.MapLayer.MasterValvesAreas) {
						gg.polygons.forEach((p, index) => {
							this.removeLayerFromMap(p);
							this.removeLayerFromMap(gg.labels[index]);
						});
					} else if (!station[0].master && mapLayer === RbEnums.Map.MapLayer.StationAreas) {
						gg.polygons.forEach((p, index) => {
							this.removeLayerFromMap(p);
							this.removeLayerFromMap(gg.labels[index]);
						});
					}
				}
			});
		}
		this.showStationAreaHalos(visible, mapLayer === RbEnums.Map.MapLayer.MasterValvesAreas);
	}

	showStationAreaHalos(visible: boolean, isMasterValve: boolean) {
		// This is the root
		const queryClassName = isMasterValve ? '.station-area-circle.is-master-valve' : '.station-area-circle:not(.is-master-valve)';
		document.querySelectorAll(queryClassName)?.forEach(el => {
			el['style']['borderWidth'] = visible ? '5px' : '0px';
			el['style']['marginTop'] = visible ? '-10px' : '-5px';
			el['style']['marginLeft'] = visible ? '-10px' : '-5px';
		});
	}

	showCycleSoak(visible: boolean) {
		this.pref.sitePreferences.visibility.showingStationCycleSoak = visible;
		this.stations.forEach(s => s.showCycleSoak(visible));
		this.pref.save();
	}

	showIrrigation(visible: boolean) {
		this.pref.sitePreferences.visibility.showingIrrigation = visible;
		this.stations
			.filter((s) => s.marker != null)
			.forEach((s) => this.createMarkerForStation(s));
		this.pref.save();
	}

	showAlerts(visible: boolean) {
		this.pref.sitePreferences.visibility.showingAlerts = visible;
		this.stations
			.filter((s) => s.marker != null)
			.forEach((s) => this.createMarkerForStation(s));
		this.pref.save();
	}

	addZoomControl(mapService) {
		if (!mapService.deviceManager.isMobile) {
			const mapAny = this.map as any;
			if (!mapAny.zoomControl) {
				const zoomControl = new L.Control.Zoom({ position: 'bottomright' });
				this.map.addControl(zoomControl);
				mapAny.zoomControl = zoomControl;
			}
		}
	}

	setMinZoomForDetailMarkers(value: number) {
		this.minZoomForMarkers = value;
	}

	// =========================================================================================================================================================
	// Data loading and updating
	// =========================================================================================================================================================
	private loadData() {
		this.clearMarkers();
		if (this.siteId == null) return;
		this.busy.next(true);

		if (this.isGolfSite) {
			const golfSources: Observable<any>[] = [
				this.getSite(),
				this.getAreasForSite(),
				this.getGeoGroupsForSite(),
				this.companyManager.getCompanyPreferences().pipe(take(1)),
				this.controllerManager.getSiteControllersList(this.siteId)
				// this.getVoltageDiagnosticLogs(),
			];

			forkJoin(golfSources).subscribe(
				([site, areas, geoGroups, companyPreferences, controllers]) => {
					this.leafletMap.invalidateSize();
					this.site = site;
					// this.courseAreas = areas;
					this.areas = <any>(
						lodash.cloneDeep(
							areas
								.filter((h) => h.level === 3)
								.sort((a, b) => a.number - b.number)
						)
					);
					this.areas.forEach((a) => {
						a.polygons = [];
						a.labels = [];
						a.geoItemIds = [];
						a.squareAreas = [];
						a.editModes = [];
						a.shapeEditingFunctions = [];
					});
					this.holes = <any>(
						lodash.cloneDeep(
							areas
								.filter((h) => h.level === 2)
								.sort((a, b) => a.number - b.number)
						)
					);
					this.geoGroups = lodash.cloneDeep(geoGroups);
					// this.voltageLogs = voltageLogs;
					this.companyPreferences = companyPreferences;
					this.loadStations().subscribe((stations) => {
						this.stations = <any>lodash.cloneDeep(stations).map((s: any) =>
							new StationWithMapInfoLeaflet(s, this)
						);

						this.initializeStationVoltageDiagnostic(
							/* forceUpdate */ true
						);
						this.initializeStationStatus(true);
						this.initializeHoleMarkers();
						this.initializeAreaMarkers();
						this.loadLatestStationStatus(true);

						this.mapService.runAfterGoogleMapsInitialized(() => {
							this.lookupSiteAddress({ centerOnAddress: false });
							this.lookupCompanyAddress();
						}, () => {
							this.busy.next(false);
						})

						this.siteDataLoaded.next({
							site: this.site,
							company: this.companyPreferences,
							stations: this.stations,
							holes: this.holes,
							areas: this.areas,
							geoGroups: this.geoGroups,
						});

						this.stickyNoteManager.setStationAnchorNames(this.stations);

						if (this.prefs.sitePreferences.visibility.showingNotes) {
							this.showNotes(this.prefs.sitePreferences.visibility.showingNotes);
						}

					});
					this.controllers = controllers;
					if (this.siteId && this.uniqueId !== null) {
						this.loadVectorAndRasterLayers();
					}
				},
				(error) => {
					this.busy.next(false);
					this.loadError.next(
						error.error ||
						error.message ||
						this.translate.instant(
							'STRINGS.NETWORK_ERROR_RETRY'
						)
					);
				}
			);

			return;
		}

		// Commercial
		this.busy.next(true);
		const sources: Observable<any>[] = [
			this.getSite(),
			this.loadSensors(),
			this.loadStations(),
			this.controllerManager
				.getSiteControllersList(this.siteId)
				.pipe(take(1),
					map(controllers => controllers.map(controller => {
						//get module with LXME2 device
						if (controller.type === RbEnums.Common.DeviceType.LXME2)
							this.moduleApiService.getControllerModules(controller.id)
								.pipe(take(1)).subscribe(module => controller["module"] = module)
						return controller
					})
					)),
			this.companyManager.getCompanyPreferences().pipe(take(1)),
			this.stationManager.getStationsListBySiteId(this.siteId),
			this.getStationAreasForSite(),
		];

		if (this.pref.sitePreferences.visibility.showingStickyNotes) {
			sources.push(this.stickyNoteManager.getStickyNotes(this.siteId))
		}

		if (this.pref.sitePreferences.visibility.showingNotes) {
			this.stickyNoteManager.setSiteId(this.siteId);
			// sources.push(this.stickyNoteManager.getNotes())
		}

		forkJoin(sources).subscribe(
			([site, sensors, stations, controllers, companyPreferences, displayedStations, stationArea, stickyNotes]) => {
				this.leafletMap.invalidateSize();
				this.site = site;
				this.stationAreas = lodash.cloneDeep(stationArea);
				this.stationAreas?.forEach((s) => {
					s.polygons = [];
					s.labels = [];
					s.geoItemIds = [];
					s.shapeEditingFunctions = [];
					s.editModes = [];
					s.squareAreas = [];
				});
				this.sensors = lodash.cloneDeep(sensors.sort((a, b) => a.name.localeCompare(b.name)));
				const baseModuleLXME2devices = controllers.filter(c =>
					c["module"] && c["module"].find(m => m.type === RbEnums.Common.DeviceType.BaseModule))
				this.stations = (
					lodash
						.cloneDeep(
							this.isGolfSite ? stations.sort((a, b) => a.name.localeCompare(b.name))
								: stations.filter(s =>
								//hide the pumps on LXME2 device when Base Module is selected
								((s.master && !baseModuleLXME2devices.some(c => c.id === s.satelliteId && s.pump)) ||
									displayedStations.some(ds => ds.id === s.id)))
									.sort((a, b) => a.name.localeCompare(b.name))
						)
						.map((s: any) =>
							new StationWithMapInfoLeaflet(s, this)
						)
				);
				this.controllers = controllers;
				this.companyPreferences = companyPreferences;

				if (stickyNotes) {
					this.stickyNotes = stickyNotes;
				}

				this.initializeControllerMarkers();
				this.initializeStationAreaMarker();
				this.initializeSensorMarkers(true);
				this.initializeStationStatus(true);

				/**
				 * NOT bypass the cache to show the current station status running after load/reload Map.
				 * Will be no delay by waiting for the SignalR to show the station running status.
				 */
				this.loadLatestStationStatus(false);
				this.lookupSiteAddress({ centerOnAddress: false });

				this.siteDataLoaded.next({
					site: this.site,
					stations: stations,
					controllers: this.controllers,
				});
			},
			(error) => {
				this.busy.next(false);
				console.log('Busy - sources', false);
				this.loadError.next(
					error.error ||
					error.message ||
					this.translate.instant('STRINGS.NETWORK_ERROR_RETRY')
				);
			}
		);
	}

	/**
	 * 	Updates the stations status
	 * 
	 * @param bypassCache Whether the cache should be bypassed
	 * @returns an observable of a list of stations with updated properties
	 */
	public loadLatestStationRunningStatus(bypassCache: boolean): Observable<StationListItem[]> {
		if (this.siteId == null) return;

		const observable =
			MapInfoLeaflet.loadStationRunningStatusObservables[this.siteId];
		if (observable != null) return observable;

		MapInfoLeaflet.loadStationRunningStatusObservables[this.siteId] =
			this.stationManager
				.getStationsList(null, bypassCache)
				.pipe(take(1), share(),
					map((stations) => {
						this.stationsCompleteList = stations;
						if (stations != null && stations.length > 0) {
							stations.forEach(station => {
								if (station.isLocked) {
									station.status = RbUtils.Translate.instant('STRINGS.LOCKED');
									station.courseViewStatus = RbUtils.Translate.instant('STRINGS.LOCKED');
								} else {
									const golfStationStatus = this.systemStatusService.getGolfStationStatus(station.id);
									if (golfStationStatus) {
										station.setStationStatus(
											RbUtils.Stations.getStationStatusFromStatusChange(golfStationStatus,
												station.master, station.priority === RbEnums.Common.Priority.NonIrrigation));
									} else if (!station.status) {
										station.status = '-';
										station.courseViewStatus = '';
									}
								}
							});
						}
						return stations;
					}),
					tap(
						() =>
							delete MapInfoLeaflet.loadStationRunningStatusObservables[
							this.siteId
							]
					));
		return MapInfoLeaflet.loadStationRunningStatusObservables[this.siteId];
	}

	public loadLatestStationStatus(bypassCache: boolean = false) {
		this.busy.next(true);
		this.loadLatestStationRunningStatus(bypassCache).subscribe({
			next: (list) => {
				if (this.mapBounds == null) {
					this.pendingStationsStatusList = list;
				} else {
					if (!this.isGolfSite) {
						this.controllersHaveStationRunning = [];
					}
					this.updateRunningStatusForStations(list);
				}
				this.busy.next(false);
			}, error: () => this.busy.next(false)
		});
	}

	private loadSensors(): Observable<SensorListItem[]> {
		const observable = MapInfoLeaflet.loadSensorObservables[this.siteId];
		if (observable != null) return observable;

		MapInfoLeaflet.loadSensorObservables[this.siteId] = this.sensorManager
			.getSensorsListBySiteId(this.siteId)
			.pipe(
				share(),
				tap(
					() =>
						delete MapInfoLeaflet.loadSensorObservables[
						this.siteId
						]
				)
			);
		return MapInfoLeaflet.loadSensorObservables[this.siteId];
	}

	private loadStations(): Observable<Station[]> {
		if (this.holes == null) return of([]);

		const observable = MapInfoLeaflet.loadStationObservables[this.siteId];
		if (observable != null) return observable;

		MapInfoLeaflet.loadStationObservables[this.siteId] = this.stationManager
			.getStationsByAreasAndSites(
				this.isGolfSite ? this.holes.map((h) => h.id) : null,
				[this.siteId]
			)
			.pipe(
				share(),
				tap(
					() =>
						delete MapInfoLeaflet.loadStationObservables[
						this.siteId
						]
				)
			);

		return MapInfoLeaflet.loadStationObservables[this.siteId];
	}

	private getAreasForSite(): Observable<Area[]> {
		const observable = MapInfoLeaflet.getAreasObservables[this.siteId];
		if (observable != null) return observable;

		MapInfoLeaflet.getAreasObservables[this.siteId] = this.areaManager
			.getAreas(this.siteId, true)
			.pipe(
				share(),
				tap(
					() => delete MapInfoLeaflet.getAreasObservables[this.siteId]
				)
			);

		return MapInfoLeaflet.getAreasObservables[this.siteId];
	}

	private getStationAreasForSite(): Observable<GeoGroup[]> {
		const observable = MapInfoLeaflet.getStationAreasObservables[this.siteId];
		if (observable != null) return observable;

		MapInfoLeaflet.getStationAreasObservables[this.siteId] = this.geoGroupManager
			.getGeoGroupsForSite(this.siteId)
			.pipe(
				share(),
				tap(
					() => delete MapInfoLeaflet.getStationAreasObservables[this.siteId]
				)
			);

		return MapInfoLeaflet.getStationAreasObservables[this.siteId];
	}

	private getGeoGroupsForSite(): Observable<GeoGroup[]> {
		const observable = MapInfoLeaflet.getGeoGroupsObservables[this.siteId];
		if (observable != null) return observable;

		MapInfoLeaflet.getGeoGroupsObservables[this.siteId] =
			this.geoGroupManager.getGeoGroupsForSite(this.siteId).pipe(
				share(),
				tap(
					() =>
						delete MapInfoLeaflet.getGeoGroupsObservables[
						this.siteId
						]
				)
			);

		return MapInfoLeaflet.getGeoGroupsObservables[this.siteId];
	}

	private getSite(): Observable<Site> {
		const observable = MapInfoLeaflet.getSiteObservables[this.siteId];
		if (observable != null) return observable;

		MapInfoLeaflet.getSiteObservables[this.siteId] = this.siteManager
			.getSite(this.siteId)
			.pipe(
				share(),
				tap(() => delete MapInfoLeaflet.getSiteObservables[this.siteId])
			);

		return MapInfoLeaflet.getSiteObservables[this.siteId];
	}

	getCommercialControllerById(id: number): ControllerWithMapInfoLeaflet {
		return this.controllers.find(c => c.id == id);
	}

	getHaloHTML(stationId: number, isMasterValve: boolean) {
		const geoGroup = this.stationAreas.find(stationArea => stationArea.stationId === stationId);
		if (!this.pref.sitePreferences.visibility.showingStationGeoAreas || !this.stationAreaHasLocation(stationId) || !geoGroup) return '';

		return `<div class="station-area-circle ${isMasterValve ? 'is-master-valve' : ''}" id="station-area-halo-${stationId}"
			style="border-color: ${this.getStationAreaSettings(stationId)?.fillColor ?? 'transparent'};
			border-width: ${this.prefs.sitePreferences.visibility.showingStationGeoAreas ? '5px' : '0px'}">`
	}

	private getStationAreaSettings(stationId: number) {
		const geoGroup = this.stationAreas?.find(s => s.stationId === stationId);
		if (!geoGroup) {
			return null;
		}
		const settings = geoGroup?.uiSettingsJson ? JSON.parse(geoGroup?.uiSettingsJson) : new AreaUiSettings();
		return settings;
	}

	private setItemMoveAbility() {
		const moveable =
			this.areItemsMovable &&
			this.pref.sitePreferences.visibility.moveable;
		this.sensors.forEach((sensor) => {
			if (sensor.marker != null && sensor.marker.dragging) {
				if (moveable) {
					sensor.marker.dragging.enable();
				} else {
					sensor.marker.dragging.disable();
				}
			}
		});
		this.stations.forEach((station) => {
			if (station.marker != null && station.marker.dragging) {
				if (moveable) {
					station.marker.dragging.enable();
				} else {
					station.marker.dragging.disable();
				}
			}
		});
		this.sensors.forEach((sensor) => {
			if (sensor.marker != null && sensor.marker.dragging) {
				if (moveable) {
					sensor.marker.dragging.enable();
				} else {
					sensor.marker.dragging.disable();
				}
			}
		});
		this.holes.forEach((hole) => {
			if (hole.marker != null && hole.marker.dragging) {
				if (moveable) {
					hole.marker.dragging.enable();
				} else {
					hole.marker.dragging.disable();
				}
			}
		});
		this.controllers.forEach((controller) => {
			if (controller.marker != null && controller.marker.dragging) {
				if (moveable) {
					controller.marker.dragging.enable();
				} else {
					controller.marker.dragging.disable();
				}
			}
		});
		this.areas.forEach((area) => {
			area.polygons.forEach((p) => {
				if ((<any>p).dragging) {
					if (moveable) {
						(<any>p).dragging.enable();
					} else {
						(<any>p).dragging.disable();
					}
				}
				(<any>p).editing.disable();
			});
			for (let i = 0; i < area.editModes.length; i++)
				area.editModes[i] = false;
		});

		this.stationAreas.forEach(stationArea => {
			stationArea.polygons.forEach((p) => {
				if ((<any>p).dragging) {
					if (moveable) {
						(<any>p).dragging.enable();
					} else {
						(<any>p).dragging.disable();
					}
				}
				(<any>p).editing.disable();
			});
			for (let i = 0; i < stationArea.editModes.length; i++)
				stationArea.editModes[i] = false;
		});

		this.draggableStickyNotesList.forEach(item => {
			if (moveable) {
				item.enable();
			} else {
				item.disable();
			}
		});

		this.map.off('draw:editvertex');
	}

	private areasForStation(stations: Station[]): Area[] {
		const areasForStation: Area[] = [];
		for (const station of stations) {
			for (const stationArea of station.stationArea) {
				const nonHoleArea = this.areas.find(
					(ca) => ca.id === stationArea.areaId
				);
				if (
					nonHoleArea != null &&
					areasForStation.every((afs) => afs.id !== nonHoleArea.id)
				) {
					areasForStation.push(nonHoleArea);
				}
			}
		}
		return areasForStation.sort((a, b) => a.number - b.number);
	}

	private updateMapBounds(): void {
		this.mapBounds = this.map.getBounds();
		if (this.mapBounds != null && this.pendingStationsStatusList != null) {
			this.updateRunningStatusForStations(this.pendingStationsStatusList);
			this.pendingStationsStatusList = null;
		}
	}

	/**
	 * If necessary, create a new TrackingDataControl on top of the map showing the current mapService.trackingData string.
	 * If the control has already been created and should be removed, remove it. If the control exists, update it.
	 */
	private checkTrackingDataDisplay(): void {
		if (
			this.trackingDataControl == null &&
			this.mapService.showTrackingData
		) {
			const trackingDataDiv = document.createElement('div');
			this.trackingDataControl = TrackingDataControlLeaflet.create(
				null,
				this.mapService.trackingData,
				trackingDataDiv
			);
			// Push returns the new length. The last index is len - 1. Save it for removal operation.
			this.trackingDataControl.addTo(this.map);
		} else if (
			this.trackingDataControl != null &&
			!this.mapService.showTrackingData
		) {
			// Remove the control if present.
			this.trackingDataControl.remove();
			this.trackingDataControl = null;
		} else if (
			this.trackingDataControl != null &&
			this.mapService.showTrackingData
		) {
			// Update the tracking data.
			TrackingDataControlLeaflet.setText(this.mapService.trackingData);
		}
	}

	// =========================================================================================================================================================
	// Context-menu handling
	// =========================================================================================================================================================

	private handleMarkerClicked(event: LeafletMouseEvent, isContextualClick = false) {

		this.mapClicked.next(null);

		const { lat, lng } = event.latlng;

		const stationIndex = this.stations.findIndex(
			(station) =>
				station.latitude === lat &&
				station.longitude === lng
		);

		if (stationIndex !== -1) {
			this.currentStationForPopup = this.stations[stationIndex];

			if (!isContextualClick) {
				// Handles left click
				if (!this.isMultiSelectModeEnabled) {
					this.stationManager.getStation(this.currentStationForPopup.id).pipe(
						take(1),
						tap((station: StationWithMapInfoLeaflet) => {
							this.currentStationForPopup.lastManualRunStartTime = station.lastManualRunStartTime;
							this.currentStationForPopup.lastRunTimeSeconds = station.lastRunTimeSeconds;
						}),
						catchError((error) => {
							throw error;
						})
					).subscribe();

					const latLngPoint = this.latLngToPixel(event.latlng);
					const contextInfo = this.contextMenu.stationOptions(
						this.currentStationForPopup, latLngPoint.x, latLngPoint.y, this.areItemsMovable, false);
					contextInfo["latlng"] = event.latlng;
					this.contextMenuInvoked.next(contextInfo);
				} else {
					if (!this.isGolfSite && this.currentStationForPopup.master) {
						return;
					}
					this.selectStation(this.currentStationForPopup);
				}
			} else {
				// Handles right click
				this.stationManager.getStation(this.currentStationForPopup.id).pipe(
					take(1),
					tap((station: StationWithMapInfoLeaflet) => {
						this.currentStationForPopup.lastManualRunStartTime = station.lastManualRunStartTime;
						this.currentStationForPopup.lastRunTimeSeconds = station.lastRunTimeSeconds;
					}),
					catchError((error) => {
						throw error;
					})
				).subscribe();

				const latLngPoint = this.latLngToPixel(event.latlng);
				const contextInfo = this.contextMenu.stationOptions(
					this.currentStationForPopup, latLngPoint.x, latLngPoint.y, this.areItemsMovable, false);
				contextInfo["latlng"] = event.latlng;
				this.rightClickContextMenuInvoked.next(contextInfo);
			}
			return;
		}

		this.stickyNoteManager.notes$.subscribe((notes: Note[]) => {
			const foundNoteIndex = notes.findIndex((note) => note.latitude === lat && note.longitude === lng);
			if (foundNoteIndex !== -1) {
				const foundNote = notes[foundNoteIndex];
				this.openNoteDialogInvoked.next(foundNote);
				return;
			}
		})

	}

	itemPause(stationIds) {
		if (stationIds.length > 0) {
			if (this.useMockData) {
				this.manualOpsManager.pauseGolfStationsMock(stationIds);
			} else {
				this.manualOpsManager.pauseStations(stationIds).subscribe();
			}
		}
	}

	itemResume(stationIds) {
		if (stationIds.length > 0) {
			if (this.useMockData) {
				this.manualOpsManager.resumeGolfStationsMock(stationIds);
			} else {
				this.manualOpsManager
					.resumeStations(stationIds)
					.subscribe();
			}
		}
	}

	itemStart(stationIds: number[], duration: Duration | { [stationId: string]: Duration }) {
		if (stationIds.length > 0) {
			const seconds: number[] = [];

			// Determine if the `duration` variable is of type Duration using the "in" operator
			// with any property or function name that ONLY exists in the Duration type
			// (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/in)
			if ("asSeconds" in duration) {
				seconds.length = stationIds.length;
				seconds.fill((duration as Duration).asSeconds());
			} else {
				stationIds.forEach((id) => {
					seconds.push(duration[id].asSeconds());
				});
			}

			if (!this.isGolfSite) {
				this.isJustStartedStations = true;
			}

			if (this.useMockData) {
				this.manualOpsManager.startGolfStationsMock(
					new StartStationModel(stationIds, seconds)
				);
			} else {
				this.manualOpsManager
					.startStations(
						new StartStationModel(
							stationIds,
							seconds
						)
					)
					.subscribe();
			}
		}
	}

	itemStop(stationIds, controllerId: number) {
		if (stationIds.length > 0) {
			if (this.useMockData) {
				this.manualOpsManager.stopGolfStationsMock(stationIds);
			} else {
				if (this.isGolfSite) {
					this.manualOpsManager
						.stopStations(stationIds)
						.subscribe();
				} else {
					if (!this.isGolfSite && controllerId != null) {
						this.controllerManager.doIfNotDemoModeController(controllerId, this.stopAllIrrigation.bind(this), controllerId);
					} else {
						this.stopAllIrrigation(controllerId);
					}
				}
			}
		}
	}

	areaAdvance(stationIds, contextMenuInfo) {
		if (stationIds.length > 0) {
			if (this.useMockData) {
				this.manualOpsManager.advanceGolfStationsMock(stationIds);
			} else {
				// RB-9541: You have to pass both the hole and area ids. Otherwise you'll be advancing all 18 greens
				// when you click Advance on the 9th green. Not at all what you want.
				const holeAndAreaIds = [
					contextMenuInfo.holeId,
					contextMenuInfo.area.id,
				];
				this.manualOpsManager
					.advanceAreas(holeAndAreaIds)
					.subscribe();
			}
		}
	}

	stationAdvance(stationIds, controllerId: number, contextMenuInfo) {
		if (stationIds.length > 0) {
			const args = { stationIds: stationIds, contextMenuInfo: contextMenuInfo };
			const selectedStations = this.mapService.multiSelectService.getSelectedStations();

			if (!this.isGolfSite) {
				// advance multiple stations IQ4
				if (this.selectedStationsCount) {
					this.controllerManager.doIfNotContainsDemoModeController(
						this.mapService.multiSelectService.getSatelliteId(),
						this.advanceStations.bind(this), { selectedStations }
					);
				}
				// advance single station IQ4
				else if (controllerId != null) {
					this.controllerManager.doIfNotDemoModeController(controllerId, this.advanceStation.bind(this), args);
				}
			}
			else {
				if (this.selectedStationsCount) {
					this.advanceStations({ selectedStations });
				} else {
					this.advanceStation(args);
				}
			}
		}
	}

	itemEdit(stationIds: number[]) {
		if (stationIds.length > 0){
			if (stationIds.length === 1) {
				this.editStation.next(stationIds[0])
			} else {
				// this.batchEditStations.next(this.mapService.multiSelectService.getSelectedStations())
			}
		}
	}

	stationCalculateAreas(stationIds, contextMenuInfo) {
		const geoGroup = this.stationAreas.find(
			(r) =>
				r.stationId === stationIds[0]
		);
		const hasCalculate = this.stationAreaHasCalculate(geoGroup);
		this.isShowAllCalculateAreas = (!this.isShowAllCalculateAreas || (!this.isShowAllCalculateAreas && hasCalculate) || !hasCalculate);
		geoGroup?.geoItem?.forEach((geoItem) => {
			this.updateStationAreaLabelMarker(
				contextMenuInfo.stationId,
				contextMenuInfo.title,
				geoGroup,
				geoItem.id,
				true,
				true
			);
		});
	}

	areaCalculateArea(contextMenuInfo) {
		if (contextMenuInfo.geoItem != null) {
			this.updateAreaLabelMarker(
				contextMenuInfo.area,
				contextMenuInfo.geoItem.id,
				true,
				true
			);
		} else {
			const geoItems = this.getGeoItemsForArea(
				contextMenuInfo.holeId,
				contextMenuInfo.area.id
			);
			geoItems.forEach((geoItem) =>
				this.updateAreaLabelMarker(
					contextMenuInfo.area,
					geoItem.id,
					true,
					true
				)
			);
		}
	}

	holeFindOnMap(contextMenuInfo) {
		this.map.flyTo(contextMenuInfo.hole.marker.getLatLng());
		this.fitMapBoundsByMarker(contextMenuInfo.hole.marker);
	}

	areaAddToMap(contextMenuInfo) {
		contextMenuInfo.area.holeId = contextMenuInfo.holeId;
		this.addArea(
			contextMenuInfo.area,
			this.map.getCenter().lat,
			this.map.getCenter().lng
		);
	}

	areaFindOnMap(contextMenuInfo) {
		const geoItemsForHoleAndArea = this.getGeoItemsForArea(
			contextMenuInfo.holeId,
			contextMenuInfo.area.id
		);
		const polygons = contextMenuInfo.area.polygons.filter(
			(polygon, index) => {
				return geoItemsForHoleAndArea.some(
					(gi) =>
						gi.id === contextMenuInfo.area.geoItemIds[index]
				);
			}
		);
		this.map.flyTo(
			this.getCenterOfPolygons(
				polygons.map((p) => p.getLatLngs())
			)
		);
		this.fitMapBoundsByLatLngs(polygons.map((p) => p.getLatLngs()));
	}

	sensorFindOnMap(contextMenuInfo) {
		this.map.flyTo(
			latLng(
				contextMenuInfo.sensor.latitude,
				contextMenuInfo.sensor.longitude
			)
		);
		this.fitMapBoundsByMarker(contextMenuInfo.sensor.marker);
	}

	stationAddToMap(contextMenuInfo) {
		contextMenuInfo.station.latitude = this.map.getCenter().lat;
		contextMenuInfo.station.longitude = this.map.getCenter().lng;
		this.createMarkerForStation(contextMenuInfo.station);
		this.addStation(contextMenuInfo.station);
	}

	stationFindOnMap(contextMenuInfo) {
		this.moveToMarker(contextMenuInfo.station.marker)
	}

	moveToMarker(marker) {
		const latlng: L.LatLngLiteral = marker.getLatLng();
		this.centerMap(latlng, 20, [380, 0]);
		setTimeout(() => {
			L.DomUtil.addClass(marker._icon, 'look-at-me');
			marker._icon.onmouseover = () => {
				L.DomUtil.removeClass(marker._icon, 'look-at-me');
			}
		}, 1000);
	}

	stationOpenNotes(contextMenuInfo) {
		const foundNote = this.stickyNoteManager.getStationAnchorNote(contextMenuInfo.station.id)
		this.openNoteDialogInvoked.next(foundNote);
	}

	invokeRightPane(event) {
		this.onInvokeRightPane.next(event)
	}

	controllerFindOnMap(contextMenuInfo) {
		this.map.flyTo(
			latLng(
				contextMenuInfo.controller.latitude,
				contextMenuInfo.controller.longitude
			)
		);
		this.fitMapBoundsByMarker(contextMenuInfo.controller.marker);
	}

	controllerSync(controllerId: number, contextMenuInfo) {
		const mbiSync = MessageBoxInfo.create(
			RbUtils.Translate.instant('STRINGS.SYNCHRONIZATION'),
			RbUtils.Translate.instant('STRINGS.SYNCHRONIZATION_MESSAGE', { name: contextMenuInfo?.controller?.name }),
			null,
			() => {
				if (!this.isGolfSite && controllerId != null) {
					this.controllerManager.doIfNotDemoModeController(controllerId, this.syncControllerSelected.bind(this), contextMenuInfo);
				} else {
					this.syncControllerSelected(contextMenuInfo);
				}
			},
			null,
			RbEnums.Common.MessageBoxButtons.YesNo
		);
		this.messageBoxService.showMessageBox(mbiSync);
	}

	controllerReverseSync(controllerId: number, contextMenuInfo) {
		const mbiReverseSync = MessageBoxInfo.create(
			RbUtils.Translate.instant('STRINGS.REVERSE_SYNCHRONIZING'),
			RbUtils.Translate.instant('STRINGS.REVERSE_SYNCHRONIZING_MESSAGE', { name: contextMenuInfo?.controller?.name }),
			null,
			() => {
				if (!this.isGolfSite && controllerId != null) {
					this.controllerManager.doIfNotDemoModeController(controllerId, this.reverseSyncControllerSelected.bind(this), contextMenuInfo);
				} else {
					this.reverseSyncControllerSelected(contextMenuInfo);
				}
			},
			null,
			RbEnums.Common.MessageBoxButtons.YesNo
		);
		this.messageBoxService.showMessageBox(mbiReverseSync);
	}

	controllerLogs(controllerId: number, contextMenuInfo) {
		if (!this.isGolfSite && controllerId != null) {
			this.controllerManager.doIfNotDemoModeController(controllerId, this.getControllerLogs.bind(this), contextMenuInfo);
		} else {
			this.getControllerLogs(contextMenuInfo);
		}
	}

	controllerConnect(controllerId: number, contextMenuInfo) {
		if (!this.isGolfSite && controllerId != null) {
			this.controllerManager.doIfNotDemoModeController(controllerId, this.connectControllerSelected.bind(this), contextMenuInfo);
		} else {
			this.connectControllerSelected(contextMenuInfo);
		}
	}

	controllerDisconnect(controllerId: number, contextMenuInfo) {
		if (!this.isGolfSite && controllerId != null) {
			this.controllerManager.doIfNotDemoModeController(controllerId, this.disconnectControllerSelected.bind(this), contextMenuInfo);
		} else {
			this.disconnectControllerSelected(contextMenuInfo);
		}
	}

	stationPrograms(stationIds) {
		if (stationIds == null || stationIds.length < 1) {
			return;
		}
		this.broadcastService.showStationSearch.next(stationIds[0]);
	}

	stationDiagnostics(stationIds) {
		if (stationIds == null || stationIds.length < 1) { return; }
		this.broadcastService.showStationDiagnostics.next(stationIds);
	}

	stationCalculateArea(contextMenuInfo) {
		if (contextMenuInfo.geoItem != null) {
			this.updateStationAreaLabelMarker(
				contextMenuInfo.stationId,
				contextMenuInfo.stationName,
				contextMenuInfo.geoGroup,
				contextMenuInfo.geoItem.id,
				true,
				true,
				true
			);
		}
	}

	contextMenuItemSelected(
		selectedMenuItem: number,
		contextMenuInfo: ContextMenuInfoTypes,
		duration: (Duration | { [stationId: string]: moment.Duration }) = null
	) {
		let stationIds: number[] = [];
		if (!contextMenuInfo?.hasOwnProperty('sensor')) {
			if (!this.selectedStationsCount) {
				stationIds = this.stationIdsForMenuSelection(contextMenuInfo);
			} else {
				stationIds = this.mapService.multiSelectService.getStationId();
			}
		}

		// For Demo Controller Check (Commercial Only)
		let controllerId: number;
		if (!this.isGolfSite && contextMenuInfo) {
			if ("station" in contextMenuInfo) {
				controllerId = contextMenuInfo.station.satelliteId;
			} else if ("controller" in contextMenuInfo) {
				controllerId = contextMenuInfo.controller.id;
			}
		}

		type fn = () => void;
		const commandList: { [key: number]: fn } = {
			[RbEnums.Map.AreaContextMenu.AddToMap]: () => this.areaAddToMap(contextMenuInfo),
			[RbEnums.Map.AreaContextMenu.Advance]: () => this.areaAdvance(stationIds, contextMenuInfo),
			[RbEnums.Map.AreaContextMenu.CalculateArea]: () => this.areaCalculateArea(contextMenuInfo),
			[RbEnums.Map.AreaContextMenu.EditShape]: () => this.toggleAreaGeoItemEditMode(contextMenuInfo),
			[RbEnums.Map.AreaContextMenu.Edit]: () => this.editArea.next(contextMenuInfo["area"].id),
			[RbEnums.Map.AreaContextMenu.FindOnMap]: () => this.areaFindOnMap(contextMenuInfo),
			[RbEnums.Map.AreaContextMenu.Pause]: () => this.itemPause(stationIds),
			[RbEnums.Map.AreaContextMenu.Remove]: () => this.removeAreaGeoItem(contextMenuInfo),
			[RbEnums.Map.AreaContextMenu.Resume]: () => this.itemResume(stationIds),
			[RbEnums.Map.AreaContextMenu.Start]: () => this.itemStart(stationIds, duration),
			[RbEnums.Map.AreaContextMenu.Stop]: () => this.itemStop(stationIds, controllerId),
			[RbEnums.Map.AreaContextMenu.ViewShape]: () => this.toggleAreaGeoItemEditMode(contextMenuInfo),
			[RbEnums.Map.ControllerContextMenu.AddToMap]: () => 
				this.addController(contextMenuInfo['controller'], this.map.getCenter().lat, this.map.getCenter().lng),
			[RbEnums.Map.ControllerContextMenu.Connect]: () => this.controllerConnect(controllerId, contextMenuInfo),
			[RbEnums.Map.ControllerContextMenu.Disconnect]: () => this.controllerDisconnect(controllerId, contextMenuInfo),
			[RbEnums.Map.ControllerContextMenu.Edit]: () => this.editController.next(contextMenuInfo['controller'].id),
			[RbEnums.Map.ControllerContextMenu.FindOnMap]: () => this.controllerFindOnMap(contextMenuInfo),
			[RbEnums.Map.ControllerContextMenu.Logs]: () => this.controllerLogs(controllerId, contextMenuInfo),
			[RbEnums.Map.ControllerContextMenu.Remove]: () => this.removeController(contextMenuInfo['controller'].id),
			[RbEnums.Map.ControllerContextMenu.ReverseSync]: () => this.controllerReverseSync(controllerId, contextMenuInfo),
			[RbEnums.Map.ControllerContextMenu.StopAll]: () => this.itemStop(stationIds, controllerId),
			[RbEnums.Map.ControllerContextMenu.Sync]: () => this.controllerSync(controllerId, contextMenuInfo),
			[RbEnums.Map.HoleContextMenu.AddToMap]: () => this.addHole(contextMenuInfo['hole'], this.map.getCenter().lat, this.map.getCenter().lng),
			[RbEnums.Map.HoleContextMenu.Edit]: () => this.editHole.next(contextMenuInfo['hole'].id),
			[RbEnums.Map.HoleContextMenu.FindOnMap]: () => this.holeFindOnMap(contextMenuInfo),
			[RbEnums.Map.HoleContextMenu.Pause]: () => this.itemPause(stationIds),
			[RbEnums.Map.HoleContextMenu.Remove]: () => this.removeHole(contextMenuInfo['hole'].id),
			[RbEnums.Map.HoleContextMenu.Resume]: () => this.itemResume(stationIds),
			[RbEnums.Map.HoleContextMenu.Start]: () => this.itemStart(stationIds, duration),
			[RbEnums.Map.HoleContextMenu.Stop]: () => this.itemStop(stationIds, controllerId),
			[RbEnums.Map.SensorContextMenu.AddToMap]: () =>
				this.addSensor(contextMenuInfo['sensor'], this.map.getCenter().lat, this.map.getCenter().lng),
			[RbEnums.Map.SensorContextMenu.Edit]: () =>
				this.editSensor.next({ id: contextMenuInfo['sensor'].id, controllerId: contextMenuInfo['sensor'].satelliteId }),
			[RbEnums.Map.SensorContextMenu.FindOnMap]: () => this.sensorFindOnMap(contextMenuInfo),
			[RbEnums.Map.SensorContextMenu.Remove]: () => this.removeSensor(contextMenuInfo['sensor'].id),
			[RbEnums.Map.StationAreaContextMenu.CalculateArea]: () => this.stationCalculateArea(contextMenuInfo),
			[RbEnums.Map.StationAreaContextMenu.EditAreaColor]: () => this.onEditStationAreaColorMenu(contextMenuInfo),
			[RbEnums.Map.StationAreaContextMenu.EditShape]: () => this.toggleStationAreaGeoItemEditMode(contextMenuInfo),
			[RbEnums.Map.StationAreaContextMenu.Remove]: () => this.removeStationAreaGeoItem(contextMenuInfo),
			[RbEnums.Map.StationAreaContextMenu.ViewShape]: () => this.toggleStationAreaGeoItemEditMode(contextMenuInfo),
			[RbEnums.Map.StationContextMenu.AddShape]: () => this.toggleStationGeoItemAddMode(stationIds[0], contextMenuInfo),
			[RbEnums.Map.StationContextMenu.AddToMap]: () => this.stationAddToMap(contextMenuInfo),
			[RbEnums.Map.StationContextMenu.Advance]: () => this.stationAdvance(stationIds, controllerId, contextMenuInfo),
			[RbEnums.Map.StationContextMenu.CalculateAreas]: () => this.stationCalculateAreas( stationIds, contextMenuInfo ),
			[RbEnums.Map.StationContextMenu.Diagnostics]: () => this.stationDiagnostics(stationIds),
			[RbEnums.Map.StationContextMenu.Edit]: () => this.itemEdit(stationIds),
			[RbEnums.Map.StationContextMenu.FindOnMap]: () => this.stationFindOnMap(contextMenuInfo),
			[RbEnums.Map.StationContextMenu.Notes]: () => this.stationOpenNotes(contextMenuInfo),
			[RbEnums.Map.StationContextMenu.Pause]: () => this.itemPause(stationIds),
			[RbEnums.Map.StationContextMenu.ProgramsAndSchedules]: () => this.stationPrograms(stationIds),
			[RbEnums.Map.StationContextMenu.Remove]: () => this.removeStation(stationIds[0]),
			[RbEnums.Map.StationContextMenu.Resume]: () => this.itemResume(stationIds),
			[RbEnums.Map.StationContextMenu.Start]: () => this.itemStart(stationIds, duration),
			[RbEnums.Map.StationContextMenu.Stop]: () => this.itemStop(stationIds, controllerId)
		};

		commandList[selectedMenuItem] && commandList[selectedMenuItem]();

		if (this.isMultiSelectModeEnabled) {
			this.toggleMultiSelectMode();
		}
	}

	stationIdsForMenuSelection(contextMenuInfo: ContextMenuInfoTypes) {
		let stationIds: number[];
		if ("hole" in contextMenuInfo) {
			stationIds = this.stationsForHole(contextMenuInfo.hole.id).map((s) => s.id);
		} else if ("area" in contextMenuInfo) {
			stationIds = this.stationsForHoleAndArea(contextMenuInfo.holeId,contextMenuInfo.area.id).map((s) => s.id);
		} else if ("controller" in contextMenuInfo && !this.isGolfSite) {
			stationIds = this.stationsForController(contextMenuInfo.controller.id).map((s) => s.id);
		} else if ("fromStationArea" in contextMenuInfo) {
			stationIds = [contextMenuInfo.stationId];
		} else {
			const stationContextMenu = contextMenuInfo as StationOptions
			stationIds = [stationContextMenu.station?.id];
		}
		return stationIds;
	}

	private advanceStation(params: { stationIds: number[], contextMenuInfo: any }) {
		if (this.useMockData) {
			this.manualOpsManager.advanceGolfStationsMock(params.stationIds);
		} else {
			if (params.contextMenuInfo.station.canAdvance) {
				const advanceStations = [new AdvanceStation(params.contextMenuInfo.station.programId, params.contextMenuInfo.station.id)];
				this.manualOpsManager.advanceStations(advanceStations, true).subscribe();
			}
		}
	}

	private advanceStations(params: { selectedStations: any }) {
		if (this.useMockData) {
			this.manualOpsManager.advanceGolfStationsMock(params.selectedStations.map(x => x.station.id));
		} else {
			const advanceStations = params.selectedStations.filter(x => x.station.canAdvance).map(x => new AdvanceStation(x.station.programId, x.station.id));
			if (advanceStations.length) {
				this.manualOpsManager.advanceStations(advanceStations, true).subscribe();
			}
		}
	}

	private stopAllIrrigation(controllerId: number) {
		this.controllerManager.stopAllIrrigation([controllerId]).subscribe();
	}

	private stopAllIrrigationByMultiController(controllerIds: number[]) {
		this.controllerManager.stopAllIrrigation(controllerIds).subscribe();
	}

	// =========================================================================================================================================================
	// Drag-and-drop support
	// =========================================================================================================================================================
	updateDragPosition(event: CdkDragMove) {
		this.dragPosition = event.pointerPosition;
	}

	dragEnded() {
		// this.googleMap.setOptions({draggableCursor: ''});
	}

	drop(
		event: CdkDragDrop<any, any>,
		hotspot: { offsetX: number; offsetY: number }
	) {
		const mapBounds = this.map.getContainer().getBoundingClientRect();

		// Adjust for image size so the bottom center is used as the drop point

		const dropPointOnMap = {
			x:
				this.dragPosition.x -
				mapBounds.left +
				(this.deviceManager.isTouchDevice ? 0 : hotspot.offsetX),
			y:
				this.dragPosition.y -
				mapBounds.top +
				(this.deviceManager.isTouchDevice ? 0 : hotspot.offsetY),
		};
		const pointLatLng = this.pixelToLatlng(
			dropPointOnMap.x,
			dropPointOnMap.y
		);

		const addShapeToStationId = event.item.data.addShapeToStationId;
		if (addShapeToStationId) {
			this.addStationArea(addShapeToStationId, pointLatLng.lat, pointLatLng.lng);
			return;
		}

		if ((event.item.data instanceof Area || event.item.data.areas) &&
			this.holes.some((h) => h.id === event.item.data.id)
		) {
			// Hole
			const hole: HoleWithMapInfo = <HoleWithMapInfo>event.item.data;
			this.addHole(hole, pointLatLng.lat, pointLatLng.lng);
			return;
		}

		const area = event.item.data.area;
		if (area != null) {
			area.holeId = event.item.data.holeId;
			this.addArea(area, pointLatLng.lat, pointLatLng.lng);
			return;
		}

		const controller = event.item.data.controller;
		if (controller != null) {
			this.addController(controller, pointLatLng.lat, pointLatLng.lng);
			return;
		}

		// Station
		if (event.item.data instanceof StationWithMapInfoLeaflet) {
			const station: StationWithMapInfoLeaflet = event.item.data;
			station.latitude = pointLatLng.lat;
			station.longitude = pointLatLng.lng;

			// Save the latitude and longitude, updating the map
			this.addStation(station);

			this.createMarkerForStation(station);
		}

		// Sensor List Item
		if (event.item.data instanceof SensorListItem) {
			const sensorListItem: SensorListItem = <SensorListItem>event.item.data;
			this.addSensor(sensorListItem, pointLatLng.lat, pointLatLng.lng);
		}
	}

	/**
	 * Loads the user preferences.
	 *
	 * Made async to be used syncrhonously. This was refactored to be called by
	 * the parent map component directly and do other work right after this completes
	 */
	async loadPrefs() {
		await this.pref.load();
	}

	// =========================================================================================================================================================
	// Geo-location handling
	// =========================================================================================================================================================
	private updateRunningStatusForStations(
		updatedListItems: StationListItem[],
		updateStationId: number = null
	) {

		this.stations.forEach((station) => {
			if (updateStationId != null && station.id !== updateStationId)
				return;

			const updatedItem = updatedListItems.find(
				(s) => s.id === station.id
			);

			if (updatedItem == null) return;

			station.name = updatedItem.name; // Should this be a full Object.assign?
			station.arc = updatedItem.arc;
			station.rotationTime = updatedItem.rotationTime;
			station.programId = updatedItem.programId;
			station.canAdvance = updatedItem.canAdvance;
			station.tempAdjustDays = updatedItem.tempAdjustDays;
			station.tempStationAdjust = updatedItem.tempStationAdjust;
			station.yearlyAdjFactor = updatedItem.yearlyAdjFactor;
			station.stationListItem = updatedItem;

			RbUtils.Stations.checkIfHasAdjustment(station);

			// Update the marker only if the running status has changed
			if (this.hasStationMapStatusChanged(station, updatedItem, !this.isGolfSite ? true : false)) {
				this.updateStationStatus(station, updatedItem);
			}

			this.createMarkerForStation(station);
		});
	}

	private async lookupSiteAddress(options: lookupSiteAddressOptions) {
		if (!this.mapService.internetService.connState) {
			this.mapService.toastService.showToaster(this.translate.instant('STRINGS.NO_INTERNET'), 5000);
			this.busy.next(false);
			return;
		}

		if (this.site.latitude && this.site.longitude && options.centerOnAddress) {
			this.map.setView([this.site.latitude, this.site.longitude], this.mapService.ZOOM_LEVEL_STREET);
			return;
		}

		this.busy.next(true);
		if (this.site) {
			if (this.site.address == null || this.site.address.length === 0) {
				this.busy.next(false);
				console.log('Busy - lookupSiteAddress', false);
				return;
			}

			this.mapService.runAfterGoogleMapsInitialized(() => {
				this.mapService.LookupAddressLatLng(
					this.site.address,
					this.site.city,
					this.site.state,
					this.site.zip,
					this.site.countryCode,
					this.siteAddressLookupCallbackFactory(options)
				);
			}, () => this.busy.next(false));

		} else {
			this.goToCurrentLocation();
			this.busy.next(false);
		}
	}

	private async lookupCompanyAddress() {
		if (!this.mapService.internetService.connState) {
			this.mapService.toastService.showToaster(this.translate.instant('STRINGS.NO_INTERNET'), 5000);
			this.busy.next(false);
			return;
		}

		this.busy.next(true);
		if (this.companyPreferences) {
			if (this.companyPreferences.address == null || this.companyPreferences.address.length === 0) {
				this.busy.next(false);
				return;
			}

			if (MapLeafletService.googleScriptInitialized) {
				this.mapService.LookupAddressLatLng(
					this.companyPreferences.address,
					this.companyPreferences.city,
					this.companyPreferences.state,
					this.companyPreferences.zip,
					this.companyPreferences.countryCode,
					this.companyAddressLookupCallbackFactory()
				);
			} else {
				MapLeafletService.initializingGoogleMapsSubject.subscribe(() => {
					this.mapService.LookupAddressLatLng(
						this.companyPreferences.address,
						this.companyPreferences.city,
						this.companyPreferences.state,
						this.companyPreferences.zip,
						this.companyPreferences.countryCode,
						this.companyAddressLookupCallbackFactory()
					);
				});
			}

		} else {
			this.busy.next(false);
		}
	}

	getGeoItemForHole(holeId: number): GeoItem {
		const geoGroup = this.geoGroups.find(
			(r) => r.areaLevel2Id === holeId && r.areaLevel3Id == null
		);
		if (geoGroup == null || geoGroup.geoItem.length === 0) return null;

		return geoGroup.geoItem[0];
	}

	private getAreaGeoGroupsForHole(hole: Area): GeoGroup[] {
		const geoGroups = this.geoGroups.filter(
			(r) => r.areaLevel2Id === hole.id && r.areaLevel3Id != null
		);
		return geoGroups;
	}

	toggleFullScreen(): void {
		// @ts-ignore
		this.broadcastService.fullScreenToggleRequested.next(null);
	}

	changeBaseLayer(event: { baseLayerId: RbEnums.Map.BackgroundLayer, saveLayerConfig: boolean }) {

		if (event.baseLayerId == null) {
			return;
		}

		if (this.layerVisibility.selectedBaseLayer === event.baseLayerId && this.selectedBaseLayer !== null) {
			return;
		}

		// clear current timer
		if (this.changeBaseLayerTimer) {
			clearTimeout(this.changeBaseLayerTimer);
		}

		// if the layer is loading, we will set a timer for switching layout after the previous layer is loaded
		// normally, it will take about 1-2 seconds to load a layer
		if (this.isLayerLoading) {
			this.changeBaseLayerTimer = setTimeout(() => {
				clearTimeout(this.changeBaseLayerTimer);
				this.changeBaseLayer(event);
			}, 1000);
			return;
		}

		if (this.mapService.internetService.connState) {
			if (!MapLeafletService.googleScriptInitialized) {
				const s = this.mapService.initializeMaps().subscribe(() => { s.unsubscribe(); });
			}
		} else if (event.baseLayerId !== RbEnums.Map.BackgroundLayer.Offline) {
			return;
		}

		if (this.selectedBaseLayer) {
			this.map.removeLayer(this.selectedBaseLayer);
		}

		switch (event.baseLayerId) {
			case RbEnums.Map.BackgroundLayer.Esri:
				this.selectedBaseLayer = this.mapService.getEsriTileLayer();
				break;
			case RbEnums.Map.BackgroundLayer.OSM:
				this.selectedBaseLayer = this.mapService.getOpenStreetMapTileLayer();
				break;
			case RbEnums.Map.BackgroundLayer.GoogleRoadMap:
				this.selectedBaseLayer = this.mapService.getGoogleLayerRoadmap();
				break;
			case RbEnums.Map.BackgroundLayer.GoogleStreetMap:
				this.selectedBaseLayer = this.mapService.getGoogleLayerSatellite();
				break;
			case RbEnums.Map.BackgroundLayer.Offline:
				this.selectedBaseLayer = this.mapService.getOfflineLayer();
				break;
			case RbEnums.Map.BackgroundLayer.None:
				this.selectedBaseLayer = null;
				break;
			default:
				break;
		}

		if (this.selectedBaseLayer) {
			this.selectedBaseLayer.on("load", () => {
				// the layer is loaded, but wait a bit more to prevent any error.
				setTimeout(() => {
					this.isLayerLoading = false;
				}, 500);
			});
			this.isLayerLoading = true;
			this.map.addLayer(this.selectedBaseLayer);
		}

		if (event.saveLayerConfig) {
			this.pref.sitePreferences.visibility.selectedBaseLayer = event.baseLayerId;
			this.pref.save();
		}

		this.mapService.internetService.testConnection();

		setTimeout(() => {
			this.fixMultiSelectPosition();
		}, 50);
	}

	goToCurrentLocation() {
		if (this.userLocationMarker == null) return;
		this.map.panTo(this.userLocationMarker.getLatLng());
		this.fitMapBoundsByMarker(this.userLocationMarker);
	}

	// =========================================================================================================================================================
	// Go Home Control
	// =========================================================================================================================================================

	addGoHomeControl() {
		if (!this.goHomeControl && this.isGolfSite) {
			const goHomeDiv = document.createElement('div');
			this.goHomeControl = CenterOnSiteControlLeaflet.create(
				this,
				this.translate,
				goHomeDiv
			);
			this.goHomeControl.addTo(this.map);
		}
	}

	async goToCourseLocation(fitMapBounds = false) {
		this.validateInternetConnection(() => {
			this.mapService.runAfterGoogleMapsInitialized(() => {
				this.lookupSiteAddress({ centerOnAddress: true, savePreferences: true, fitMapBounds });
			})
		});
	}

	async addDownloadTilesControl(): Promise<void> {
		// @ts-ignore
		this.downloadTilesControlCleanup();
		const offlineLayer: any = this.mapService.getEsriTileLayer();
		this.leafletDownloadTilesControl = L.control.savetiles(offlineLayer, {
			zoomlevels: [16, 17, 18, 19, 20, 21],
			confirm: (layer: any, successCallback: any) => {
				const mbi = MessageBoxInfo.create(
					RbUtils.Translate.instant('STRINGS.OFFLINE_TILES'),
					RbUtils.Translate.instant('STRINGS.DOWNLOAD_TILES_INFO', { tilesLength: layer._tilesforSave.length }),
					null,
					successCallback,
					null,
					RbEnums.Common.MessageBoxButtons.YesNo
				);
				this.messageBoxService.showMessageBox(mbi);
			},
			confirmRemoval: (layer: any, successCallback: any) => {
				const mbi = MessageBoxInfo.create(
					RbUtils.Translate.instant('STRINGS.OFFLINE_TILES'),
					RbUtils.Translate.instant('STRINGS.REMOVE_DOWNLOADED_TILES_INFO'),
					null,
					() => {
						this.removingTiles = true;
						successCallback();
					},
					null,
					RbEnums.Common.MessageBoxButtons.YesNo
				);
				this.messageBoxService.showMessageBox(mbi);
			},
			saveText:
				'<i class="material-icons" aria-hidden="true" title="Save tiles">cloud_download</i>',
			rmText: '<i class="material-icons" aria-hidden="true"  title="Remove tiles">delete_forever</i>',
		});

		this.leafletDownloadTilesControl.addTo(this.map);

		let progress: number, total: number;
		offlineLayer.on('savestart', (e: any) => {
			progress = 0;
			total = e._tilesforSave.length;
			DownloadTilesControl.addProgressBar(this).then(() => {
				this.mapService.downloadingTiles = true;
				this.mapService.offLineTilesSaveStart.next(this);
			});
		});
		offlineLayer.on('savetileend', () => {
			progress += 1;
			DownloadTilesControl.updateProgressBar(this, progress, total).then(() => {
				if (progress === total) {
					this.mapService.offLineTilesSaved.next(this);
					DownloadTilesControl.progressBarCompleted(this).then(async () => {
						this.mapService.downloadingTiles = false;
					});
					DownloadTilesControl.downloadedTilesChange.next(true);
				}
			});
		});
		offlineLayer.on('tilesremoved', async () => {
			this.mapService.removingTiles = false;
			this.mapService.offLineTilesRemoved.next(this);
			DownloadTilesControl.downloadedTilesChange.next(false);
		});

	}

	downloadTiles() {
		if (this.downloadingTiles) {
			DownloadTilesControl.downloadInProgressAlert(this);
			return;
		}

		if (this.removingTiles) {
			DownloadTilesControl.removeInProgressAlert(this);
			return;
		}

		this.validateInternetConnection(() => {
			const saveOffline: any = this.leafletMapContainerNodeElement.querySelector('.savetiles .savetiles');
			saveOffline.click();
		});

	}

	removeDownloadedTiles() {
		if (this.downloadingTiles) {
			DownloadTilesControl.downloadInProgressAlert(this);
			return;
		}

		if (this.removingTiles) {
			DownloadTilesControl.removeInProgressAlert(this);
			return;
		}
		const removeOffline: any = this.leafletMapContainerNodeElement.querySelector('.savetiles .rmtiles');
		removeOffline.click();
	}

	// =========================================================================================================================================================
	// FullScreen Control
	//  * isReenabled = true: Re-enable the Fullscreen icon in the Map
	// =========================================================================================================================================================

	addFullScreenControl(isReenabled = false) {
		if (!this.fullscreenControl) {
			const fullScreenDiv = document.createElement('div');
			this.fullscreenControl = FullScreenControlLeaflet.create(
				this,
				this.translate,
				fullScreenDiv
			);
			this.fullscreenControl.addTo(this.map);
		} else if (isReenabled) {
			this.fullscreenControl.addTo(this.map);
		}

		document.onfullscreenchange = function (event) {
			FullScreenControl.setIcon(TriPaneComponent.isFullScreen);
		};
		// @ts-ignore
		document.onwebkitfullscreenchange = function (event) {
			FullScreenControl.setIcon(TriPaneComponent.isFullScreen);
		};
	}

	// =========================================================================================================================================================
	// Station multi-selection mode
	// =========================================================================================================================================================

	addMultiSelectControl() {
		// @ts-ignore
		if (this.canMultiselect && !this.multiSelectControl) {
			const multiSelectDiv = document.createElement('div');
			this.multiSelectControl = MultiSelectModeControl
				.create(
					this,
					this.translate,
					multiSelectDiv,
					this.isGolfSite,
					this.stationSelectionChange.asObservable(),
					this.mapService.multiSelectService
				);
			this.multiSelectControl.addTo(this.map);
		}
	}

	fixMultiSelectPosition() {
		const multiSelectContainer: any = this.leafletMapContainerNodeElement.querySelector('.rb-multiselect-container');
		const attribution: any = this.leafletMapContainerNodeElement.querySelector('.leaflet-control-attribution');

		if (multiSelectContainer) {
			if (attribution && attribution.offsetWidth >= (screen.width - 60)) {
				multiSelectContainer.style.transform = `translateY(-${attribution.offsetHeight}px)`;
			} else {
				multiSelectContainer.style.transform = 'translateY(0)';
			}
		} else {
			setTimeout(() => {
				this.fixMultiSelectPosition();
			}, 100);
		}
	}

	toggleMultiSelectMode(fromMapLeaflet = false, value = true): void {
		// @ts-ignore
		if (fromMapLeaflet) {
			this.isMultiSelectModeEnabled = value;
		} else {
			this.isMultiSelectModeEnabled = !this.isMultiSelectModeEnabled;
		}
		MultiSelectModeControl.toggle(this.translate, this.isMultiSelectModeEnabled, this.mapService.multiSelectService);
		if (!this.isMultiSelectModeEnabled) {
			this.deselectAllStations();
		}
	}

	private selectStation(station: StationWithMapInfoLeaflet) {
		if (station.marker) {
			const isSelected = L.DomUtil.hasClass(station.marker['_icon'], 'station-selected');
			if (isSelected) {
				this.mapService.multiSelectService.deselectStationMap(station);
			} else {
				this.mapService.multiSelectService.selectStationMap(station);
			}
			this.stationSelectionChange.next(this.mapService.multiSelectService.getSelectedStations());
		}
	}

	private deselectAllStations() {
		this.mapService.multiSelectService.deselectAllStations();
		this.stationSelectionChange.next([]);
	}

	startSelectedStations() {
		const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		if (selectedStations.length) {
			this.prevSelectedStations = selectedStations.map(e => e);
			MultiSelectModeControl.disableReselectButton(false);
			this.mapService.multiSelectService.setDisableReselect(false);
			if (!this.isGolfSite) {
				const selectedControllerIds = [... new Set(selectedStations.map(x => x.station?.satelliteId))];
				const necessaryConnectedControllers = this.controllers.filter((c) => selectedControllerIds.includes(c.id) && !c.isConnected);
				if (necessaryConnectedControllers.length) {
					this.openControllerAutoConnectModal.next(necessaryConnectedControllers);
				} else {
					this.multiSelectActionInvoked.next(RbEnums.Map.StationContextMenu.Start);
				}
			} else {
				this.multiSelectActionInvoked.next(RbEnums.Map.StationContextMenu.Start);
			}
		} else {
			console.warn('There are no stations selected for this action');
			this.toggleMultiSelectMode();
		}
	}

	advanceSelectedStations() {
		const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		if (selectedStations.length) {
			this.prevSelectedStations = selectedStations.map(e => e);
			MultiSelectModeControl.disableReselectButton(false);
			this.mapService.multiSelectService.setDisableReselect(false);
			this.multiSelectActionInvoked.next(RbEnums.Map.StationContextMenu.Advance);
		} else {
			this.toggleMultiSelectMode();
		}
	}

	stopSelectedStations() {
		const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		if (selectedStations.length) {
			this.prevSelectedStations = selectedStations.map(e => e);
			MultiSelectModeControl.disableReselectButton(false);
			this.mapService.multiSelectService.setDisableReselect(false);
			this.multiSelectActionInvoked.next(RbEnums.Map.StationContextMenu.Stop);
		} else {
			console.warn('There are no stations selected for this action');
			this.toggleMultiSelectMode();
		}
	}

	stopAllSelectedStations() {
		const letsProceed = !this.isGolfSite && ((this.prevSelectedStations.length && this.isJustStartedStations) ||
			this.controllersHaveStationRunning.length);
		if (letsProceed) {
			const mbi = MessageBoxInfo.create(
				this.translate.instant('STRINGS.STOP'),
				this.translate.instant('STRINGS.STOP_SELECTED_STATIONS'),
				RbEnums.Common.MessageBoxIcon.None,
				() => {
					MultiSelectModeControl.disableReselectButton(false);
					this.mapService.multiSelectService.setDisableReselect(false);
					if (this.controllersHaveStationRunning.length) {
						this.stopAllIrrigationByMultiController(this.controllersHaveStationRunning);
					} else if (this.prevSelectedStations.length) {
						const stopControllerIds = [... new Set(this.prevSelectedStations.map(x => x.station?.satelliteId))];
						this.stopAllIrrigationByMultiController(stopControllerIds);
					}
					this.controllersHaveStationRunning = [];
					this.isJustStartedStations = false;
					this.toggleMultiSelectMode();
				},
				null,
				RbEnums.Common.MessageBoxButtons.YesNo
			);
			this.broadcastService.showMessageBox.next(mbi);
		} else {
			console.warn('There are no stations selected for this action');
			this.toggleMultiSelectMode();
		}
	}

	pauseSelectedStations() {
		const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		if (selectedStations.length) {
			this.prevSelectedStations = selectedStations.map(e => e);
			MultiSelectModeControl.disableReselectButton(false);
			this.mapService.multiSelectService.setDisableReselect(false);
			this.multiSelectActionInvoked.next(RbEnums.Map.StationContextMenu.Pause);
		} else {
			console.warn('There are no stations selected for this action');
			this.toggleMultiSelectMode();
		}
	}

	resumeSelectedStations() {
		const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		if (selectedStations.length) {
			this.prevSelectedStations = selectedStations.map(e => e);
			MultiSelectModeControl.disableReselectButton(false);
			this.mapService.multiSelectService.setDisableReselect(false);
			this.multiSelectActionInvoked.next(RbEnums.Map.StationContextMenu.Resume);
		} else {
			console.warn('There are no stations selected for this action');
			this.toggleMultiSelectMode();
		}
	}

	batchEditSelectedStations() {
		const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		if (selectedStations.length) {
			this.prevSelectedStations = selectedStations.map(e => e);
			MultiSelectModeControl.disableReselectButton(false);
			this.mapService.multiSelectService.setDisableReselect(false);
			this.multiSelectActionInvoked.next(RbEnums.Map.StationContextMenu.Edit);
		} else {
			console.warn('There are not enough stations selected for this action');
		}
	}

	diagnosticsSelectedStations() {
		const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		if (selectedStations.length) {
			this.prevSelectedStations = selectedStations.map(e => e);
			MultiSelectModeControl.disableReselectButton(false);
			this.mapService.multiSelectService.setDisableReselect(false);
			this.multiSelectActionInvoked.next(RbEnums.Map.StationContextMenu.Diagnostics);
		} else {
			console.warn('There are no stations selected for this action');
			this.toggleMultiSelectMode();
		}
	}

	repeatLastSelection() {
		this.deselectAllStations();
		for (let item of this.prevSelectedStations) {
			this.selectStation(item.station)
		}
	}

	fitSelectedStationsToView() {
		const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		if (selectedStations.length) {
			const layerArray = selectedStations.map(item => item.station.marker);
			const group = L.featureGroup(layerArray);
			this.map.fitBounds(group.getBounds());
		} else {
			console.warn('There are no stations selected for this action');
		}
	}

	/**
	 * goToCurrentLocationSpecialActivated is called by the center-on-location-control when the user double-taps/clicks on the
	 * "find me" icon. We use this to set special tracking display to on/off.
	 */
	goToCurrentLocationSpecialActivated() {
		this.mapService.monitorUserLocationSpecialActivated();
		this.checkTrackingDataDisplay();
	}

	// =========================================================================================================================================================
	// Methods to deal with markers
	// =========================================================================================================================================================
	private updateAreaLabelMarker(
		area: AreaWithMapInfoLeaflet,
		geoItemId: number,
		showIfNotVisible: boolean,
		toggleVisibility: boolean
	) {
		const index = area.geoItemIds.findIndex((g) => g === geoItemId);
		if (index === -1) return;

		const polygon = area.polygons[index];
		const labelMarker = area.labels[index];
		if (!this.map.hasLayer(labelMarker) && !showIfNotVisible) return;
		if (toggleVisibility && this.map.hasLayer(labelMarker)) {
			labelMarker.closeTooltip();
			this.removeLayerFromMap(labelMarker);
			return;
		}
		// const path = polygon.getPath();
		// const areaInSquareMeters = google.maps.geometry.spherical.computeArea(path);
		const areaInSquareMeters = GeometryUtil.geodesicArea(
			<any>polygon.getLatLngs()[0]
		);
		if (areaInSquareMeters === 0) {
			this.removeLayerFromMap(labelMarker);
			labelMarker.closeTooltip();
		} else {
			this.addLayerToMap(labelMarker);
			labelMarker.openTooltip();
		}
		const polygonCenter = this.getCenterOfPolygons(
			<any>polygon.getLatLngs()[0]
		);
		labelMarker.setLatLng(polygonCenter);
		area.squareAreas[index] = areaInSquareMeters;
		const useDecimal =
			this.authManager.userCulture.decimalSeparator ===
			RbConstants.Form.DECIMAL_SEPARATOR;
		let text = areaInSquareMeters.toLocaleString(
			useDecimal ? 'en-us' : 'de-de',
			{ maximumFractionDigits: 0 }
		);
		let unitLabel = this.translate.instant('UNIT_TYPE.SQUARE_METERS');
		if (
			this.authManager.userCulture.areaFormat ===
			RbEnums.Common.AreaFormat.SQ_FT
		) {
			const areaInSquareFeet =
				areaInSquareMeters *
				RbConstants.Form.UNITS_OF_MEASURE_AREA.squareMetersToSquareFeet;
			text = areaInSquareFeet.toLocaleString(
				useDecimal ? 'en-us' : 'de-de',
				{ maximumFractionDigits: 0 }
			);
			unitLabel = this.translate.instant('UNIT_TYPE.SQUARE_FEET');
		} else if (
			this.authManager.userCulture.areaFormat ===
			RbEnums.Common.AreaFormat.SQ_YD
		) {
			const areaInSquareYards =
				areaInSquareMeters *
				RbConstants.Form.UNITS_OF_MEASURE_AREA
					.squareMetersToSquareYards;
			text = areaInSquareYards.toLocaleString(
				useDecimal ? 'en-us' : 'de-de',
				{ maximumFractionDigits: 0 }
			);
			unitLabel = this.translate.instant('UNIT_TYPE.SQUARE_YARDS');
		}

		labelMarker.setTooltipContent(`${text} ${unitLabel}`);
	}

	private updateStationAreaLabelMarker(
		stationId: number,
		stationName: string,
		geoGroup: StationAreaWithMapInfoLeaflet,
		geoItemId: number,
		showIfNotVisible: boolean,
		toggleVisibility: boolean,
		isIndividual: boolean = false
	) {
		const index = geoGroup?.geoItemIds?.findIndex((g) => g === geoItemId);
		if (index === -1) return;
		const polygon = geoGroup?.polygons?.[index];
		const labelMarker = geoGroup?.labels?.[index];
		if (!this.map.hasLayer(labelMarker) && !showIfNotVisible) return;
		if (toggleVisibility && this.map.hasLayer(labelMarker) && (!this.isShowAllCalculateAreas || isIndividual)) {
			labelMarker.closeTooltip();
			this.removeLayerFromMap(labelMarker);
			return;
		}
		if (polygon) {
			const areaInSquareMeters = GeometryUtil.geodesicArea(<any>polygon.getLatLngs()?.[0]);
			if (areaInSquareMeters === 0) {
				this.removeLayerFromMap(labelMarker);
				labelMarker.closeTooltip();
			} else if (this.isShowAllCalculateAreas || isIndividual) {
				this.addLayerToMap(labelMarker);
				labelMarker.openTooltip();
			}
			const polygonCenter = this.getCenterOfPolygons(<any>polygon.getLatLngs()?.[0]);
			labelMarker?.setLatLng(polygonCenter);
			if (geoGroup) {
				geoGroup.squareAreas[index] = areaInSquareMeters;
			}
			const useDecimal =
				this.authManager.userCulture.decimalSeparator ===
				RbConstants.Form.DECIMAL_SEPARATOR;
			let text = areaInSquareMeters.toLocaleString(
				useDecimal ? 'en-us' : 'de-de',
				{ maximumFractionDigits: 0 }
			);
			let unitLabel = this.translate.instant('UNIT_TYPE.SQUARE_METERS');
			if (this.authManager.userCulture.areaFormat === RbEnums.Common.AreaFormat.SQ_FT) {
				const areaInSquareFeet =
					areaInSquareMeters *
					RbConstants.Form.UNITS_OF_MEASURE_AREA.squareMetersToSquareFeet;
				text = areaInSquareFeet.toLocaleString(
					useDecimal ? 'en-us' : 'de-de',
					{ maximumFractionDigits: 0 }
				);
				unitLabel = this.translate.instant('UNIT_TYPE.SQUARE_FEET');
			} else if (this.authManager.userCulture.areaFormat === RbEnums.Common.AreaFormat.SQ_YD) {
				const areaInSquareYards =
					areaInSquareMeters *
					RbConstants.Form.UNITS_OF_MEASURE_AREA
						.squareMetersToSquareYards;
				text = areaInSquareYards.toLocaleString(
					useDecimal ? 'en-us' : 'de-de',
					{ maximumFractionDigits: 0 }
				);
				unitLabel = this.translate.instant('UNIT_TYPE.SQUARE_YARDS');
			}

			labelMarker.setTooltipContent(`${stationName} ${text} ${unitLabel}`);
			geoGroup.labels[index] = labelMarker;
		}
	}

	private createMarkerForStationArea(
		stationId: number,
		geoItem: GeoItem,
		stationAreaInfo: StationAreaWithMapInfoLeaflet,
		areasStyleSetting: AreaUiSettings,
		polygon: { latitude: number; longitude: number }[]
	) {
		const latLngPolygon = polygon.map((ll) =>
			latLng(ll.latitude, ll.longitude)
		);
		if (!areasStyleSetting) {
			areasStyleSetting = new AreaUiSettings();
		}

		const polygonMarker = Lpolygon(latLngPolygon, {
			fillColor: areasStyleSetting.fillColor,
			fillOpacity: areasStyleSetting.fillOpacity / 100,
			color: areasStyleSetting.lineColor,
			opacity: areasStyleSetting.lineOpacity / 100,
			weight: areasStyleSetting.lineWidth
		});
		(<any>polygonMarker).makeDraggable();

		const labelMarker = marker(this.getCenterOfPolygons(latLngPolygon), {
			// visible: this.pref.sitePreferences.visibility.showingAreas,
			icon: newIcon({
				iconUrl: this.env.rbcc_ui + '/assets/images/flag.png',
				iconAnchor: [0, 0],
				iconSize: [0, 0],
			}),
		});

		labelMarker.bindTooltip('', {
			permanent: true,
			direction: 'center',
			className: 'leafletMarkerTooltip areaLabel',
		});

		const stationAreaMarkerDraggable =
			this.areItemsMovable &&
			this.pref.sitePreferences.visibility.moveable;
		if (stationAreaMarkerDraggable) {
			(<any>polygonMarker).dragging.enable();
		}

		const self = this;
		polygonMarker.on('click', function (event: LeafletMouseEvent) {
			if (!self.isMultiSelectModeEnabled) {
				self.stationGeoAreaItemsEditModeRelease(geoItem);
				const latLngPoint = self.latLngToPixel(event.latlng);
				self.contextMenuInvoked.next(self.contextMenu.stationAreaOptions(
					stationId,
					stationAreaInfo,
					geoItem,
					latLngPoint.x,
					latLngPoint.y,
					self.areItemsMovable,
					false,
					polygonMarker
				));
			}
		});

		polygonMarker.on('dragstart', function (event) {
			(<any>polygonMarker).editing.disable();
			self.dragging = true;
			self.draggedAreaStation = stationAreaInfo;
			if (self.draggedAreaStation == null) return;
		});

		polygonMarker.on('dragend', () => {
			this.dragging = false;
			if (this.draggedAreaStation == null) return;
			this.mapService.updateStationAreaGeoItem(
				geoItem.id,
				<any>polygonMarker?.getLatLngs()[0]
			);
			this.draggedAreaStation = null;

			this.stationAreas.forEach((gg) => {
				gg.polygons.forEach((polygon) => polygon.redraw());
			});
		});

		return { label: labelMarker, polygon: polygonMarker };
	}

	private createMarkerForArea(
		area: AreaWithMapInfoLeaflet,
		geoGroup: GeoGroup,
		geoItem: GeoItem,
		polygon: { latitude: number; longitude: number }[]
	): { label: Marker; polygon: Polygon } {
		const latLngPolygon = polygon.map((ll) =>
			latLng(ll.latitude, ll.longitude)
		);
		const polygonMarker = Lpolygon(latLngPolygon, {
			// draggable: this.areItemsMovable && this.pref.sitePreferences.visibility.moveable,
			// editable: false,
			// map: this.googleMap,
			// visibility: this.pref.sitePreferences.visibility.showingAreas? 1 : 0,
			fillColor: area.uiSettings.fillColor,
			fillOpacity: area.uiSettings.fillOpacity / 100,
			color: area.uiSettings.lineColor,
			opacity: area.uiSettings.lineOpacity / 100,
			weight: area.uiSettings.lineWidth,
		});

		(<any>polygonMarker).makeDraggable();

		const hole = this.holes.find((h) => h.id === geoGroup.areaLevel2Id);
		const labelMarker = marker(this.getCenterOfPolygons(latLngPolygon), {
			// visible: this.pref.sitePreferences.visibility.showingAreas,
			icon: newIcon({
				iconUrl: this.env.rbcc_ui + '/assets/images/flag.png',
				iconAnchor: [0, 0],
				iconSize: [0, 0],
			}),
		});

		labelMarker.bindTooltip('', {
			permanent: true,
			direction: 'center',
			className: 'leafletMarkerTooltip areaLabel',
		});

		const self = this;
		polygonMarker.on('click', function (event: LeafletMouseEvent) {
			if (!self.isMultiSelectModeEnabled) {
				const latLngPoint = self.latLngToPixel(event.latlng);
				self.contextMenuInvoked.next(self.contextMenu.areaOptions(
					hole.id,
					area,
					geoItem,
					latLngPoint.x,
					latLngPoint.y,
					self.areItemsMovable,
					false,
					polygonMarker));
			}
		});

		polygonMarker.on('dragstart', function (event) {
			(<any>polygonMarker).editing.disable();
			self.dragging = true;
			self.draggedArea = area;
			if (self.draggedArea == null) return;
			self.draggedArea.holeId = hole.id;
		});

		polygonMarker.on('dragend', () => {
			this.dragging = false;
			if (this.draggedArea == null) return;
			this.mapService.updateAreaGeoItem(
				this.draggedArea.id,
				geoItem.id,
				<any>polygonMarker.getLatLngs()[0]
			);
			const polygonIndex = this.draggedArea.polygons.findIndex(
				(p) => p === polygonMarker
			);
			if (
				this.map.listens('draw:editvertex') &&
				(<any>this.map)._events['draw:editvertex'].findIndex(
					(e) =>
						e.fn ===
						this.draggedArea.shapeEditingFunctions[polygonIndex]
				) > -1
			) {
				(<any>polygonMarker).editing.enable();
			}
			this.draggedArea = null;
		});

		return { label: labelMarker, polygon: polygonMarker };
	}

	private createControllerName(controller: ControllerWithMapInfoLeaflet) {
		if (this.pref.sitePreferences.visibility.showingControllersName) {
			controller.marker.setTooltipContent(this.toolTipWrapper(controller.name));
		} else {
			controller.marker.setTooltipContent(this.toolTipWrapper(''));
		}
	}

	private createMarkerForController(
		controller: ControllerWithMapInfoLeaflet
	) {
		if (
			controller.marker != null &&
			(controller.latitude == null || controller.longitude == null)
		) {
			this.removeLayerFromMap(controller.marker);
			controller.marker = null;
		}

		if (controller.latitude == null || controller.longitude == null) return;

		const icon = this.iconForController(controller);

		if (controller.marker != null) {

			// Remove polyline
			this.removeServerClientRelationship(controller);

			controller.marker.setLatLng([
				controller.latitude,
				controller.longitude,
			]);

			controller.marker.setIcon(icon);

			if (this.pref.sitePreferences.visibility.showingControllers) {
				this.addLayerToMap(controller.marker);
				this.addAllServerClientRelationship()
			} else {
				this.removeLayerFromMap(controller.marker);
				this.removeAllServerClientRelationship();
			}

			return;
		}

		controller.marker = marker(
			[controller.latitude, controller.longitude],
			{
				icon: icon,
			}
		);



		controller.marker.bindTooltip(this.toolTipWrapper(controller.name), {
			permanent: true,
			direction: 'right',
			className: 'leafletMarkerTooltip',
		});

		const self = this;
		controller.marker.on('click', function (event: LeafletMouseEvent) {
			if (!self.isMultiSelectModeEnabled) {
				const latLngPoint = self.latLngToPixel(event.latlng);
				self.contextMenuInvoked.next(
					self.contextMenu.controllerOptions(
						controller,
						latLngPoint.x,
						latLngPoint.y,
						self.areItemsMovable
					)
				);
			}
		});

		controller.marker.on('dragstart', function (event) {
			self.draggedController = controller;
		});

		controller.marker.on('dragend', function (event) {
			self.dragging = false;
			if (self.draggedController == null) return;
			self.mapService.updateController(self.draggedController.id, {
				latitude: (<Marker>event.target).getLatLng().lat,
				longitude: (<Marker>event.target).getLatLng().lng,
			});
			self.draggedController = null;
		});

		if (this.pref.sitePreferences.visibility.showingControllers) {
			this.addLayerToMap(controller.marker);
			this.addAllServerClientRelationship();
		} else {
			this.removeLayerFromMap(controller.marker);
			this.removeAllServerClientRelationship();
		}

		return controller.marker;
	}

	updateAllMarkers(forceUpdate: boolean) {
		if (this.updateMarkersTimer != null)
			clearTimeout(this.updateMarkersTimer);

		// Prevent updates many times in response to user panning
		this.updateMarkersTimer = window.setTimeout(() => {
			this.receivedMapMoved = false;
			this.updateMarkersTimer = null;
			this.initializeControllerMarkers();
			this.initializeSensorMarkers(forceUpdate);
			this.initializeStationStatus(forceUpdate);
			this.initializeAreaMarkers();
			this.initializeHoleMarkers();
			if (this.courseMarker != null) {
				this.courseMarker.setTooltipContent(this.toolTipWrapper(this.site.name));
				// %% Previous way of setting label
				// setLabel({ color: this.pref.sitePreferences.visibility.textColor, fontSize: '15px', fontFamily: 'Lato', text: `${this.site.name}` });
			}
		}, 500); // To prevent very fast "duplicate" calls like when zooming the map
	}

	private initializeHoleMarkers() {
		this.holes.forEach((hole) => {
			const geoItem = this.getGeoItemForHole(hole.id);
			if (geoItem == null) return;
			this.createMarkerForHole(hole);
		});
	}

	private initializeStationGeoAreas(geoItems: GeoItem[], geoGroup: StationAreaWithMapInfoLeaflet, showGeoAreas: boolean) {
		geoItems?.forEach(geoItem => {
			geoGroup.geoItemIds.push(geoItem.id);
			const labelAndPolygon = this.createMarkerForStationArea(
				geoGroup.stationId,
				geoItem,
				geoGroup,
				geoGroup.uiSettingsJson ? JSON.parse(geoGroup.uiSettingsJson) : null,
				geoItem.multiPoint.map((rp) => ({
					latitude: rp.latitude,
					longitude: rp.longitude,
				}))
			);
			geoGroup?.polygons.push(labelAndPolygon.polygon);
			geoGroup?.labels.push(labelAndPolygon.label);
			geoGroup?.squareAreas.push(0);
			geoGroup?.editModes.push(false);
			if (showGeoAreas) {
				this.addLayerToMap(labelAndPolygon.polygon);
			}
		})
	}

	private initializeStationAreaMarker() {
		this.stationAreas?.forEach((geoGroup: StationAreaWithMapInfoLeaflet) => {
			const geoItems = geoGroup?.geoItem;
			const stations = this.stations.filter(s => s.id === geoGroup.stationId);
			if (stations != null && stations.length === 1) {
				if (stations[0].master) {
					const showingMasterValvesGeoAreas = this.pref.sitePreferences.visibility.showingMasterValvesGeoAreas;
					this.initializeStationGeoAreas(geoItems, geoGroup, showingMasterValvesGeoAreas);
				} else {
					const showStationGeoAreas = this.pref.sitePreferences.visibility.showingStationGeoAreas;
					this.initializeStationGeoAreas(geoItems, geoGroup, showStationGeoAreas);
				}
			}
		});
	}

	updateStationAreaStyle(stationId: number, settings: string) {
		this.geoGroupManager.getGeoGroupForStation(this.siteId, stationId, true).subscribe((geoGroupUpdated: StationAreaWithMapInfoLeaflet) => {
			const geoGroup = this.stationAreas?.find(s => s.stationId === stationId);
			geoGroup.polygons.forEach((p) => this.removeLayerFromMap(p));
			geoGroup.geoItemIds = [];
			geoGroup.polygons = [];
			geoGroup.squareAreas = [];
			geoGroup.editModes = [];
			geoGroupUpdated.geoItem.forEach(geoItem => {
				geoGroup.geoItemIds.push(geoItem.id);
				const labelAndPolygon = this.createMarkerForStationArea(
					geoGroup.stationId,
					geoItem,
					geoGroup,
					settings ? JSON.parse(settings) : null,
					geoItem.multiPoint.map((rp) => ({
						latitude: rp.latitude,
						longitude: rp.longitude,
					}))
				);

				geoGroup.polygons.push(labelAndPolygon.polygon);
				geoGroup.squareAreas.push(0);
				geoGroup.editModes.push(false);
				geoGroup.uiSettingsJson = settings;
				this.addLayerToMap(labelAndPolygon.polygon);
			});

			const stationAreaHalo = document.querySelector(`.station-area-circle#station-area-halo-${stationId}`) as HTMLElement;
			if (stationAreaHalo) {
				stationAreaHalo['style']['borderColor'] = JSON.parse(settings)?.fillColor ?? 'transparent';
			}
		});
	}

	private initializeAreaMarkers() {
		this.holes.forEach((hole) => {
			const areaGeoGroups = this.getAreaGeoGroupsForHole(hole);
			const areasForHole = this.areasForHole(hole.id);

			areasForHole.forEach((area: AreaWithMapInfoLeaflet) => {
				const areaGeoGroup = areaGeoGroups.find(
					(r) =>
						r.areaLevel2Id === hole.id && r.areaLevel3Id === area.id
				);
				if (areaGeoGroup == null) return;

				areaGeoGroup.geoItem.forEach((rl) => {
					if (
						rl.multiPoint == null ||
						rl.multiPoint.map == null ||
						area.geoItemIds.some((id) => id === rl.id)
					)
						return;
					area.geoItemIds.push(rl.id);
					const labelAndPolygon = this.createMarkerForArea(
						area,
						areaGeoGroup,
						rl,
						rl.multiPoint.map((rp) => ({
							latitude: rp.latitude,
							longitude: rp.longitude,
						}))
					);
					area.polygons.push(labelAndPolygon.polygon);
					area.labels.push(labelAndPolygon.label);
					area.squareAreas.push(0);
					area.editModes.push(false);
					this.addLayerToMap(labelAndPolygon.polygon);
				});
			});
		});
	}

	private initializeControllerMarkers() {
		this.controllers.forEach((controller) => {
			this.createMarkerForController(controller);
		});
		this.controllers.forEach((controller) => {
			this.addServerClientRelationship(controller);
		});
	}

	private showingServerClientRelationships() {
		return this.pref.sitePreferences.visibility.showingControllers && this.pref.sitePreferences.visibility.showingServerClientRelationships;
	}

	private showingServerClientStatus() {
		return this.pref.sitePreferences.visibility.showingControllers && this.pref.sitePreferences.visibility.showingServerClientStatus;
	}

	private addAllServerClientRelationship() {
		if (!this.showingServerClientRelationships()) return;
		this.controllers.forEach((controller) => {
			this.addServerClientRelationship(controller);
		});
	}
	private removeAllServerClientRelationship() {
		this.serverClientRelationshipLines.map((line) => line.scLine.remove(this.map));
		this.serverClientRelationshipLines.length = 0;
	}
	private removeServerClientRelationship(controller: ControllerWithMapInfoLeaflet) {
		if (controller.latitude == null || controller.longitude == null)
			return;
		if (controller.iqNetType === RbEnums.Common.IqNetType.IQNetServer) {
			// remove all relationship from Server Controller to Client Controllers
			const scRelationshipLines = this.serverClientRelationshipLines.filter(line => line.serverPoint === controller.id);
			if (scRelationshipLines?.length > 0) {
				scRelationshipLines.map((line) => line.scLine.remove(this.map));
				this.serverClientRelationshipLines = this.serverClientRelationshipLines.filter(line => !scRelationshipLines.includes(line));
			}
		}
		else if (controller.iqNetType === RbEnums.Common.IqNetType.IQNetClient) {
			// remove relationship from Client Controller to Server Controller
			const scRelationshipLines = this.serverClientRelationshipLines.filter(line => line.clientPoint === controller.id);
			if (scRelationshipLines?.length > 0) {
				scRelationshipLines.map((line) => line.scLine.remove(this.map));
				this.serverClientRelationshipLines = this.serverClientRelationshipLines.filter(line => !scRelationshipLines.includes(line));
			}
		}
	}

	private addServerClientRelationship(controller: ControllerWithMapInfoLeaflet) {
		if (!this.showingServerClientRelationships())
			return;
		if (controller.latitude == null || controller.longitude == null)
			return;
		if (controller.iqNetType === RbEnums.Common.IqNetType.IQNetServer) {
			const clientControllersBelongingServer = this.controllers
				.filter((ctrl) => ctrl.iqNetType === RbEnums.Common.IqNetType.IQNetClient && ctrl.parentId === controller.id
					&& (ctrl.latitude != null || ctrl.longitude != null));

			if (!!clientControllersBelongingServer) {
				let points = [];
				points.push([controller.latitude,
				controller.longitude]);
				clientControllersBelongingServer.forEach((clientCtrl: ControllerWithMapInfoLeaflet) => {
					const serverClientRelationshipLines = this.serverClientRelationshipLines
						.filter(line => line.clientPoint === clientCtrl.id && line.serverPoint == controller.id);
					if (serverClientRelationshipLines?.length == 0) {
						points.push([clientCtrl.latitude,
						clientCtrl.longitude]);
						const scLine = L.polyline(points).addTo(this.map);
						const scRelationshipLine: ServerClientRelationship = {
							serverPoint: controller.id,
							clientPoint: clientCtrl.id,
							scLine: scLine,
						};
						this.serverClientRelationshipLines.push(scRelationshipLine);
						points = points.slice(0, -1);
					}
				});
			}
		}
		else if (controller.iqNetType === RbEnums.Common.IqNetType.IQNetClient) {
			const parentServerController = this.controllers
				.find((ctrl) => ctrl.iqNetType === RbEnums.Common.IqNetType.IQNetServer && ctrl.id === controller.parentId
					&& (ctrl.latitude != null || ctrl.longitude != null));
			if (!!parentServerController) {
				let points = [];
				const serverClientRelationshipLines = this.serverClientRelationshipLines
					.filter(line => line.clientPoint === controller.id && line.serverPoint == parentServerController.id);
				if (serverClientRelationshipLines?.length == 0) {
					points.push([parentServerController.latitude,
					parentServerController.longitude]);
					points.push([controller.latitude,
					controller.longitude]);

					const scLine = L.polyline(points).addTo(this.map);
					const scRelationshipLine: ServerClientRelationship = {
						serverPoint: parentServerController.id,
						clientPoint: controller.id,
						scLine: scLine,
					};
					this.serverClientRelationshipLines.push(scRelationshipLine);
				}
			}
		}
		return;
	}

	private initializeSensorMarkers(forceUpdate: boolean) {
		this.sensors.filter(s => !!s.longitude).forEach((sensor) => {
			if (this.receivedMapMoved) return false; // stop if we were interrupted by something that will call this function again.

			if (sensor.latitude == null || sensor.longitude == null)
				return true;
			const wasOnMap = sensor.isInMapView;
			const isOnMap =
				this.mapBounds != null &&
				sensor.latitude != null &&
				sensor.longitude != null &&
				this.mapBounds.contains({
					lat: sensor.latitude,
					lng: sensor.longitude,
				});

			if (forceUpdate || wasOnMap !== isOnMap) {
				this.createMarkerForSensor(sensor);
			}
		});
	}

	private initializeStationStatus(forceUpdate: boolean) {
		this.stations.every((station) => {
			if (this.receivedMapMoved) return false; // stop if we were interrupted by something that will call this function again.

			if (station.latitude == null || station.longitude == null)
				return true;
			const wasOnMap = station.isInMapView;
			const isOnMap =
				this.mapBounds != null &&
				station.latitude != null &&
				station.longitude != null &&
				this.mapBounds.contains({
					lat: station.latitude,
					lng: station.longitude,
				});

			if (forceUpdate || wasOnMap !== isOnMap) {
				station.isStationRunning = false;
				this.createMarkerForStation(station);
			}
			return true;
		});
	}

	private initializeStationVoltageDiagnostic(forceUpdate: boolean) {
		// Retrieve the voltage polling results initializing the diagnostic states of the stations. For now, we do this only for golf.
		if (this.isGolfSite) {
			// All satellites all stations, just like the stations list we have. Get most-recent voltage results.
			this.voltageDiagnosticManager
				.getLastVoltageDiagnosticLogs()
				.subscribe((voltageDiagnostics) => {
					// Update the station values with voltage results. Update markers.
					const stationsUpdated =
						RbUtils.Stations.updateStationDiagnosticResult_VoltageDiagnostic(
							this.stations,
							voltageDiagnostics
						);
					stationsUpdated.forEach((s) => {
						this.createMarkerForStation(
							s as StationWithMapInfoLeaflet
						);
					});
				});
		}
	}

	private createMarkerForHole(hole: HoleWithMapInfoLeaflet): Marker {
		// Remove the previous marker from the map
		const geoItem = this.getGeoItemForHole(hole.id);
		if (hole.marker != null && geoItem == null) {
			this.removeLayerFromMap(hole.marker);
			hole.marker = null;
		}

		if (geoItem == null) return;

		const icon = L.divIcon({
			className: 'hole-marker',
			html: '<div class="flag-container"><span class="material-icons">golf_course</span></div>',
			iconSize: [this.holeMarkerInfo.width, this.holeMarkerInfo.height],
			iconAnchor: [
				this.holeMarkerInfo.mapIconHotspotX,
				this.holeMarkerInfo.mapIconHotspotY,
			],
		});

		if (hole.marker != null) {
			hole.marker.setLatLng([
				geoItem.point.latitude,
				geoItem.point.longitude,
			]);
			hole.marker.setIcon(icon);
			hole.marker.setTooltipContent(this.toolTipWrapper(hole.name));
			return;
		}

		hole.marker = marker(
			[geoItem.point.latitude, geoItem.point.longitude],
			{ icon: icon }
		).bindTooltip(this.toolTipWrapper(hole.name), {
			permanent: true,
			direction: 'right',
			offset: [10, -15],
			className: 'leafletMarkerTooltip',
		});

		this.addLayerToMap(hole.marker);

		const self = this;
		hole.marker.on('click', function (event: LeafletMouseEvent) {
			if (!self.isMultiSelectModeEnabled) {
				const latLngPoint = self.latLngToPixel(event.latlng);
				self.contextMenuInvoked.next(
					self.contextMenu.holeOptions(
						hole,
						latLngPoint.x,
						latLngPoint.y,
						self.areItemsMovable
					)
				);
			}
		});

		hole.marker.on('dragstart', function (event) {
			self.draggedHole = hole;
		});

		hole.marker.on('dragend', function (event) {
			if (self.draggedHole == null) return;
			self.mapService.holeDragged(geoItem, {
				longitude: (<Marker>event.target).getLatLng().lng,
				latitude: (<Marker>event.target).getLatLng().lat,
			});
			self.draggedHole = null;
		});

		return hole.marker;
	}

	private detachSensorMarkerFromMap(
		sensor: SensorWithMapInfoLeaflet
	): void {
		if (sensor.marker != null) {
			// This will hide the marker.
			sensor.visible = false;
		}
	}

	/**
	 * Utility to handle everything needed when the station should not be marked. Check whether marker exists
	 * and remove it, if so. Check whether alertPopup exists and detach it, if so.
	 */
	private detachStationMarkerFromMap(
		station: StationWithMapInfoLeaflet
	): void {
		if (station.marker != null) {
			// This will hide the marker.
			station.visible = false;
			station.marker.remove();
		}
		if (station.alertPopup != null) {
			// We can safely remove this if we're removing the marker. No marker = no where to show the popup.
			if (station.marker) station.marker.unbindPopup();
			station.alertPopup = null;
		}

	}

	removeLayerFromMap(layer: Layer) {
		if (!layer) return;
		layer.remove();
		const index = this.leafletLayers.findIndex((l) => l === layer);
		if (index !== -1) {
			this.leafletLayers.splice(index, 1);
		}
	}

	createMarkerForSensor(sensor: SensorWithMapInfoLeaflet): Marker {
		// See if the marker is going to be removed
		if (sensor.latitude == null || sensor.longitude == null) {
			this.detachSensorMarkerFromMap(sensor);
			return;
		}

		// See if the sensor is outside the bounds of the map
		if (
			this.mapBounds == null ||
			!this.mapBounds.contains({
				lat: sensor.latitude,
				lng: sensor.longitude,
			})
		) {
			sensor.visible = false;
			sensor.isInMapView = false;
			if (sensor.marker) {
				return;
			}
		} else {
			sensor.isInMapView = true;
		}

		const sensorIcon = this.iconForSensor(sensor);
		const sensorMarkerVisible = (this.pref.sitePreferences.visibility.showingSensors &&
			this.map.getZoom() >= this.minZoomForMarkers);
		const sensorMarkerDraggable =
			this.areItemsMovable &&
			this.pref.sitePreferences.visibility.moveable;

		// The sensor already has a marker, update all of its attributes using the setters.
		const itemIdx = this.mapService.multiSelectService.findIndexOfStation(sensor.id);
		if (sensor.marker != null) {
			sensor.marker.setLatLng([sensor.latitude, sensor.longitude]);
			sensor.marker.setIcon(sensorIcon);
			if (sensorMarkerVisible) {

				if (itemIdx > -1) {
					const interval = () => {
						if (sensor.marker['_icon']) {
							sensor.marker['_icon'].classList.add('station-selected');
						} else {
							setTimeout(interval, 200);
						}
					};
					interval();
				}
				sensor.visible = true;
			} else {
				sensor.visible = false;
			}

			if ((<any>sensor.marker).dragging != null) {
				if (sensorMarkerDraggable) {
					(<any>sensor.marker).dragging.enable();
				} else {
					(<any>sensor.marker).dragging.disable();
				}
			} else {
				(<any>sensor.marker).options.draggable =
					sensorMarkerDraggable;
			}

			sensor.marker.setTooltipContent(this.toolTipWrapper(sensor.name));
			this.addLayerToMap(sensor.marker);
			// Ready to return. The sensor.marker is non-null. The marker may or may not be visible on the map, depending
			// on the various settings and the sensor state.
			return;
		}

		// No sensor marker for this sensor yet. We must create one. If the marker should be visible based on sensor
		// state and map settings, show it by setting the map reference to our map; if not visible, set map to null.
		sensor.marker = marker([sensor.latitude, sensor.longitude], {
			draggable: sensorMarkerDraggable,
			icon: this.iconForSensor(sensor),
		});

		this.addLayerToMap(sensor.marker);

		if (sensorMarkerVisible) {
			if (itemIdx > -1) {
				const interval = () => {
					if (sensor.marker['_icon']) {
						sensor.marker['_icon'].classList.add('station-selected');
					} else {
						setTimeout(interval, 200);
					}
				};
				interval();
			}
			sensor.visible = true;
		} else {
			sensor.visible = false;
		}

		const self = this;

		sensor.marker.on('click', function (event: LeafletMouseEvent) {
			if (!self.isMultiSelectModeEnabled) {
				const latLngPoint = self.latLngToPixel(event.latlng);
				self.contextMenuInvoked.next(
					self.contextMenu.sensorOptions(
						sensor,
						latLngPoint.x,
						latLngPoint.y,
						self.areItemsMovable
					)
				);
			}
		});

		sensor.marker.on('dragstart', function (event) {
			self.draggedSensor = self.sensors.find(
				(s) =>
					s.latitude === (<Marker>event.target).getLatLng().lat &&
					s.longitude === (<Marker>event.target).getLatLng().lng
			);
		});

		sensor.marker.on('dragend', function (event) {
			if (self.draggedSensor == null) return;
			self.draggedSensor.latitude = event.target.getLatLng().lat;
			self.draggedSensor.longitude = event.target.getLatLng().lng;
			self.mapService.sensorManager
				.updateSensors([self.draggedSensor.id], {
					latitude: event.target.getLatLng().lat,
					longitude: event.target.getLatLng().lng,
				})
				.pipe(finalize(() => (self.draggedStation = null)))
				.subscribe(() => {
					self.mapService.broadcastService.sensorLocationChanged.next(
						self.draggedSensor
					);
				});
		});
		if (this.pref.sitePreferences.visibility.showingSensors) {
			this.addLayerToMap(sensor.marker);
		} else {
			this.removeLayerFromMap(sensor.marker);
		}
	}

	/**
	 * Create/update/remove marker for station. This method might be called when the map location or zoom moves,
	 * when the system status changes, when diagnostic results are received, when the station's individual status
	 * changes, etc. One critical post-condition is that if the station is in the map bounds, it will have a
	 * marker (station.marker != null) on exit. The same states apply to the station.alertPopup. If station is
	 * on the map, the popup is present; if not, it's removed.
	 * @param station - StationWithMapInfoLeaflet describing the station.
	 * @param visible - False value for Stations is invisible in Map (default value is always true)
	 * @returns void
	 */

	createMarkerForStation(station: StationWithMapInfoLeaflet, visible: boolean = true): Marker {
		station.hasLocation = station.latitude !== null && station.longitude !== null;

		// See if the marker is going to be removed
		if (station.latitude == null || station.longitude == null) {
			this.detachStationMarkerFromMap(station);
			return;
		}

		// See if the station is outside the bounds of the map
		if (
			this.mapBounds == null ||
			!this.mapBounds.contains({
				lat: station.latitude,
				lng: station.longitude,
			})
		) {
			station.visible = false;
			station.isInMapView = false;
			if (station.marker) {
				return;
			}
		} else {
			station.isInMapView = true;
		}

		// if the site Preferences has invisible for master valves or stations
		if (!this.isGolfSite) {
			if (station.master) {
				if (visible && this.pref.sitePreferences.visibility.showingMasterValves === false) {
					visible = false;
				}
			} else {
				if (visible && this.pref.sitePreferences.visibility.showingStations === false) {
					visible = false;
				}
			}
		}

		// IQ4 only - Get controller has station.irrigationStatus is running for Multi Select
		if (!this.isGolfSite && station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running) {
			if (!this.controllersHaveStationRunning.includes(station.satelliteId)) {
				this.controllersHaveStationRunning.push(station.satelliteId);
			}
		}

		// -----
		// From here, the post-condition station.marker != null must be maintained. So no matter what the visibility
		// status of the marker is, the station.marker should always be set when returning from any point below.
		// -----

		const isRunning = station.status != null && station.status !== '-';
		station.isStationRunning = isRunning;

		// Update the station alert based on the error status of the station. From here down, if station.alertPopup
		// is non-null, the station is in alert state; if null, the station is in a normal state.
		this.replaceAlertPopupForStation(station);

		// RB-9921: Decide if the station marker should be visible. This is a complex calculation now that we can show the station
		// when it's in alert status, irrigating, idle, etc. all at various zooms and with various icons. Visibility
		// is controlled by station.marker.map being null (invisible) or the map reference (visible).
		const stationHasAlertPopup = station.alertPopup != null;
		const showStationAllLevels = this.showStationAtAllZoomLevels(station);
		const stationMarkerVisible =
			(stationHasAlertPopup &&
				this.pref.sitePreferences.visibility.showingAlerts) ||
			(this.isGolfSite ? this.pref.sitePreferences.visibility.showingStations :
				// IQ4 need to check site Preferences for master valves or stations
				station.master ? this.pref.sitePreferences.visibility.showingMasterValves : this.pref.sitePreferences.visibility.showingStations &&
					this.map.getZoom() >= this.minZoomForMarkers) ||
			showStationAllLevels;
		// IQ4: stations are displayed in map based on station Marker Visible and stations toggle on/off in common layer
		station.visible = this.isGolfSite ? stationMarkerVisible : stationMarkerVisible && visible;

		const stationMarkerDraggable =
			this.areItemsMovable &&
			this.pref.sitePreferences.visibility.moveable;

		// The station already has a marker, update all of its attributes using the setters.
		// const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		// const itemIdx = selectedStations.findIndex(item => item.station.id === station.id);
		const itemIdx = this.mapService.multiSelectService.findIndexOfStation(station.id);
		const foundNote = this.stickyNoteManager.getStationAnchorNote(station.id)
		// const foundNotes = this.stickyNoteManager.notes?.find(note => note.attachedToType === RbEnums.Note.NoteAnchor.Station
		// 	&& note.attachedToId === station.id);
		if (station.marker != null) {
			station.marker.setLatLng([station.latitude, station.longitude]);
			if (station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running
				&& this.layerVisibility.showingIrrigation
				&& station.statusText == 'running') {
				station.updateMarkerInfo();
			} else {
				const haloHTML = this.getHaloHTML(station?.id, station?.master);
				const stationIcon = RbUtils.Stations.iconForStation(
					station,
					station.alertPopup != null &&
					this.layerVisibility.showingAlerts,
					!!foundNote,
					this.layerVisibility.showingIrrigation,
					haloHTML
				);
				station.marker.setIcon(stationIcon);
				station.statusText = stationIcon.options.className.replace('station-', '').replace('station', '').trim();
			}

			if (stationMarkerVisible) {
				if (station.alertPopup) {
					if (station.marker.getPopup()) {
						station.marker.setPopupContent(
							station.alertPopup.popup
						);
					} else {
						station.marker.bindPopup(station.alertPopup.popup);
					}
				}
				if (itemIdx > -1) {
					const interval = () => {
						if (station.marker['_icon']) {
							station.marker['_icon'].classList.add('station-selected');
							station.isSelected = true;
						} else {
							setTimeout(interval, 200);
						}
					};
					interval();
				}
			}

			if ((<any>station.marker).dragging != null) {
				if (stationMarkerDraggable) {
					(<any>station.marker).dragging.enable();
				} else {
					(<any>station.marker).dragging.disable();
				}
			} else {
				(<any>station.marker).options.draggable =
					stationMarkerDraggable;
			}

			// Ready to return. The station.marker is non-null. The marker may or may not be visible on the map, depending
			// on the various settings and the station state.
			return;
		}

		// No station marker for this station yet. We must create one. If the marker should be visible based on station
		// state and map settings, show it by setting the map reference to our map; if not visible, set map to null.
		const stationIcon = RbUtils.Stations.iconForStation(
			station,
			station.alertPopup != null &&
			this.layerVisibility.showingAlerts,
			!!foundNote,
			this.layerVisibility.showingIrrigation,
			this.getHaloHTML(station?.id, station?.master)
		);

		station.marker = marker([station.latitude, station.longitude], {
			draggable: stationMarkerDraggable,
			icon: stationIcon,
		});

		station.statusText = stationIcon.options.className.replace('station-', '').replace('station', '').trim();

		if (stationMarkerVisible) {
			if (itemIdx > -1) {
				const interval = () => {
					if (station.marker['_icon']) {
						station.marker['_icon'].classList.add('station-selected');
						station.isSelected = true;
					} else {
						setTimeout(interval, 200);
					}
				};
				interval();
			}
		}

		if (station.alertPopup) {
			station.marker.bindPopup(station.alertPopup.popup);
		}

		const self = this;

		station.marker.on('click', function (event: LeafletMouseEvent) {
			self.handleMarkerClicked(event);
		});

		station.marker.on('contextmenu', function (event: LeafletMouseEvent) {
			self.handleMarkerClicked(event, true);
		});

		station.marker.on('mouseover', function (event) {
			if (station.alertPopup == null) return;
			station.hoverTimer = window.setTimeout(() => {
				if (
					station == null ||
					station.marker == null ||
					station.marker.openPopup == null
				)
					return;

				station.marker.openPopup();
				station.hoverTimer = null;
			}, 500);
		});

		station.marker.on('mouseout', function (event) {
			if (
				station.alertPopup != null &&
				station.marker != null &&
				station.marker.closePopup != null
			)
				station.marker.closePopup();

			if (station.hoverTimer == null) return;
			clearTimeout(station.hoverTimer);
			station.hoverTimer = null;
		});

		station.marker.on('dragstart', function (event) {
			self.draggedStation = self.stations.find(
				(s) =>
					s.latitude === (<Marker>event.target).getLatLng().lat &&
					s.longitude === (<Marker>event.target).getLatLng().lng
			);
		});

		station.marker.on('dragend', function (event) {
			if (self.draggedStation == null) return;
			self.draggedStation.latitude = event.target.getLatLng().lat;
			self.draggedStation.longitude = event.target.getLatLng().lng;
			self.mapService.stationManager
				.updateStations([self.draggedStation.id], {
					latitude: event.target.getLatLng().lat,
					longitude: event.target.getLatLng().lng,
				})
				.pipe(finalize(() => (self.draggedStation = null)))
				.subscribe(() => {
					self.mapService.broadcastService.stationLocationChanged.next(
						self.draggedStation
					);
				});
		});
	}

	private toggleNoteAnimation(visible: boolean) {
		const badges = this.leafletMapContainerNodeElement.querySelectorAll('.badge i.note');
		const markers = this.leafletMapContainerNodeElement.querySelectorAll('.note-marker');
		if (visible) {
			badges.forEach(badge => {
				badge.classList.add('animate');
			});
			markers.forEach(marker => {
				marker.classList.add('animate');
			});
		} else {
			badges.forEach(badge => {
				badge.classList.remove('animate');
			});
			markers.forEach(marker => {
				marker.classList.remove('animate');
			});
		}
	}

	private reloadStationHalo(stationId: number) {
		const station = this.stations.find(s => s.id === stationId);

		// const foundNotes = this.stickyNoteManager.notes.find(note => note.attachedToType === RbEnums.Note.NoteAnchor.Station
		// 	&& note.attachedToId === station.id);
		const foundNote = this.stickyNoteManager.getStationAnchorNote(station.id)
		if (!!station?.marker) {
			// No station marker for this station yet. We must create one. If the marker should be visible based on station
			// state and map settings, show it by setting the map reference to our map; if not visible, set map to null.
			const stationIcon = RbUtils.Stations.iconForStation(
				station,
				station.alertPopup != null &&
				this.layerVisibility.showingAlerts,
				!!foundNote,
				this.layerVisibility.showingIrrigation,
				this.getHaloHTML(station.id, station.master)
			);
			station.marker.setIcon(stationIcon);
		}
	}

	private clearMarkers(): void {
		this.clearControllerMarkers();
		this.clearHoleMarkers();
		this.clearAreaMarkers();
		this.clearSensorMarkers();
		this.clearStationMarkers();
	}

	private clearHoleMarkers() {
		this.holes.forEach((hole) => {
			if (hole.marker != null) {
				this.removeLayerFromMap(hole.marker);
				hole.marker = null;
			}
		});
	}

	private clearControllerMarkers() {
		this.controllers.forEach((controller) => {
			if (controller.marker != null) {
				this.removeLayerFromMap(controller.marker);
				controller.marker = null;
			}
		});
	}

	private clearAreaMarkers() {
		this.areas.forEach((area) => {
			area.polygons.forEach((p) => this.removeLayerFromMap(p));
			area.labels.forEach((p) => this.removeLayerFromMap(p));
			area.polygons = [];
			area.labels = [];
			area.editModes = [];
			area.geoItemIds = [];
		});
	}

	private clearSensorMarkers() {
		this.sensors.forEach((sensor) => {
			if (sensor.marker != null) {
				this.removeLayerFromMap(sensor.marker);
				sensor.marker = null;
			}
		});
	}

	private clearStationMarkers() {
		this.stations.forEach((station) =>
			this.detachStationMarkerFromMap(station)
		);
	}

	// =========================================================================================================================================================
	// Drawing support
	// =========================================================================================================================================================
	private getCenterOfPolygons(polygons: LatLngExpression[]) {
		const bounds = latLngBounds(polygons);
		return bounds.getCenter();
	}

	iconForSensor(
		sensor: SensorWithMapInfoLeaflet
	): any {
		let icon = L.divIcon({
			className: 'station station-idle',
			iconAnchor: [10, 10],
			iconSize: [20, 20],
			html: `<div class="outer-circle">
					<div class="sensor-circle" style="background: '#ddd', display: 'block'"></div>
					<div class="station-info" style="color:${this.pref.sitePreferences.visibility.textColor};">
						<span class="sensor-name" style="display:
							${this.pref.sitePreferences.visibility.showingSensorNames ? 'block' : 'none'}">${sensor.name}</span>
					</div>
				</div>`
		});

		return icon;
	}

	// private get holeImage(): any {
	// 	if (this._holeImage != null) return this._holeImage;

	// 	this._holeImage = {
	// 		iconUrl: this.env.rbcc_ui + '/assets/images/flag_yellow.png',
	// 		iconSize: [this.holeMarkerInfo.width, this.holeMarkerInfo.height],
	// 		// origin: new google.maps.Point(0, 0),
	// 		iconAnchor: [this.holeMarkerInfo.mapIconHotspotX, this.holeMarkerInfo.mapIconHotspotY],
	// 	};
	// 	return this._holeImage;
	// }

	private get controllerImage(): any {
		if (this._controllerImage != null) return this._controllerImage;

		this._controllerImage = {
			iconUrl: this.env.rbcc_ui + '/assets/images/controller_icon.jpg',
			iconSize: [
				this.controllerMarkerInfo.width,
				this.controllerMarkerInfo.height,
			],
			// origin: new google.maps.Point(0, 0),
			iconAnchor: [
				this.controllerMarkerInfo.mapIconHotspotX,
				this.controllerMarkerInfo.mapIconHotspotY,
			],
		};
		return this._controllerImage;
	}

	private iconForIQNetType(controller: ControllerListItem): L.DivIcon {
		let icon: L.DivIcon;
		// noinspection FallThroughInSwitchStatementJS
		switch (controller?.iqNetType) {
			case RbEnums.Common.IqNetType.IQNetServer:
				icon = L.divIcon({
					className: 'controller',
					iconSize: [30, 30],
					iconAnchor: [15, 15],
					html: `<div class="outer server"><img class='ctlIcon' src="../../../assets/images/controller_icon.jpg" alt='rb-controller'/></div>`
				});
				break;
			case RbEnums.Common.IqNetType.IQNetClient:
				icon = L.divIcon({
					className: 'controller',
					iconSize: [30, 30],
					iconAnchor: [15, 15],
					html: `<div class="outer client"><img class='ctlIcon' src="../../../assets/images/controller_icon.jpg" alt='rb-controller'/></div>`
				});
				break;
		}
		return icon;
	}

	private iconForController(controller: ControllerListItem): any {
		// const textWidth = RbUtils.Common.getTextWidth(controller.name, '15px Lato'); %%
		let icon = this.showingServerClientStatus() && this.iconForIQNetType(controller);
		if (!!icon) {
			return icon;
		}
		switch (controller.syncState) {
			case RbEnums.Common.ControllerSyncState.Syncing:
				return newIcon({
					iconAnchor: [10, 10],
					// labelOrigin: new google.maps.Point(12 + RbConstants.MapIcons.controllerSyncingMarkerInfo.width + textWidth / 2, 8),
					iconSize: [20, 20], // scaledSize
					iconUrl:
						'data:image/svg+xml;charset=UTF-8,' +
						encodeURIComponent(
							RbConstants.MapIcons.CONTROLLER_SYNCING_SVG
						),
				});
			case RbEnums.Common.ControllerSyncState.ReverseSyncing:
				return newIcon({
					// iconAnchor: new google.maps.Point(10, 10),
					iconAnchor: [10, 10],
					// labelOrigin: new google.maps.Point(12 + RbConstants.MapIcons.controllerReverseSyncingMarkerInfo.width + textWidth / 2, 8),
					iconSize: [20, 20],
					iconUrl:
						'data:image/svg+xml;charset=UTF-8,' +
						encodeURIComponent(
							RbConstants.MapIcons.CONTROLLER_REVERSE_SYNCING_SVG
						),
				});
		}
		if (controller.gettingLogs) {
			return newIcon({
				iconAnchor: [10, 10],
				// labelOrigin: new google.maps.Point(12 + RbConstants.MapIcons.controllerGettingLogsMarkerInfo.width + textWidth / 2, 8),
				iconSize: [20, 20],
				iconUrl:
					'data:image/svg+xml;charset=UTF-8,' +
					encodeURIComponent(
						RbConstants.MapIcons.CONTROLLER_LOGS_SVG
					),
			});
		}

		return newIcon({
			...this.controllerImage,
			// labelOrigin: new google.maps.Point(this.controllerMarkerInfo.width + 6 + textWidth / 2, this.controllerMarkerInfo.height / 2)
		});
	}

	/**
	 * Create the HTML describing the alert popup needed for the indicated station (with map info).
	 * @param station - StationWithMapInfoLeaflet containing the station data used to calculate the HTML needed for the popup.
	 * @returns string containing the basic error HTML describing things, or null, if no error to describe.
	 */
	private alertPopupHTMLForStation(
		station: StationWithMapInfoLeaflet
	): string {
		let errorHtml = '';
		if (!RbUtils.Stations.isStationAddressValid(station)) {
			errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant(
				'SPECIAL_MSG.STATION_ADDRESS_INVALID'
			)}</div>`;
		}
		if (this.isGolfSite) {
			// No other alerts for commercial
			if (station.suspended) {
				errorHtml = `<div class="map-alert-popup-content-info">${this.translate.instant(
					'STRINGS.SUSPENDED'
				)}</div>`;
			} else if (!station.isConnected) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant(
					'SPECIAL_MSG.NOT_CONNECTED'
				)}</div>`;
			} else if (station.fastConnectStationNumber < 0 || station.channel < 0) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant(
					'SPECIAL_MSG.STATION_NOT_PROGRAMMED'
				)}</div>`;
			} else if (
				station.voltage != null && // RB-9921: Treat zero as a reasonable value!
				this.companyPreferences.diagnosticVoltageLowRange != null &&
				station.voltage <
				this.companyPreferences.diagnosticVoltageLowRange
			) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant(
					'SPECIAL_MSG.STATION_LOW_VOLTAGE'
				)}</div>`;
			} else if (
				station.voltage != null &&
				this.companyPreferences.diagnosticVoltageHighRange != null &&
				station.voltage >
				this.companyPreferences.diagnosticVoltageHighRange
			) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant(
					'SPECIAL_MSG.STATION_HIGH_VOLTAGE'
				)}</div>`;
			} else if (
				station.feedback ===
				RbEnums.Common.DiagnosticFeedbackResult.NO_FB
			) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant(
					'SPECIAL_MSG.STATION_NO_FEEDBACK'
				)}</div>`;
			}
		}

		return errorHtml;
	}

	/**
	 * Remove any existing alertPopup for the indicated station, check whether the station should have an error popup
	 * and, if so, create a new alertPopup, attach it to the map and save it in the station.
	 * @param station - StationWithMapInfoLeaflet to have its alertPopup reworked
	 * @returns - void
	 */
	private replaceAlertPopupForStation(
		station: StationWithMapInfoLeaflet
	): void {
		if (station.alertPopup != null) {
			if (station.marker) station.marker.unbindPopup();
			station.alertPopup = null;
		}
		if (station.latitude == null || station.longitude == null) return null;

		// Get HMTL describing error, or null if no error or no way to describe it.
		const errorHtml = this.alertPopupHTMLForStation(station);

		// If station has no error, we've already removed the old alertPopup from the station and map; just return. Otherwise
		// build a new AlertPopup and save that to the map and station.
		if (errorHtml.length > 0) {
			const content = document.createElement('div');
			content.classList.add('map-alert-popup-content');
			content.innerHTML = errorHtml;

			// const AlertPopupClass = createAlertPopupClass();
			const alertPopup = new AlertPopupLeaflet(
				latLng(station.latitude, station.longitude),
				content
			);
			station.alertPopup = alertPopup;
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================
	private addHole(
		hole: HoleWithMapInfo,
		latitude: number,
		longitude: number
	) {
		this.mapService.addHole(hole, {
			longitude: longitude,
			latitude: latitude,
		});
	}

	private addStationArea(
		stationId: number,
		latitude: number,
		longitude: number
	) {
		const geoGroup = this.stationAreas.find(
			(r) =>
				r.stationId === stationId
		);
		this.mapService.addStationArea(stationId, {
			latitude,
			longitude,
		}, !geoGroup);
	}

	private addArea(
		area: AreaWithMapInfo,
		latitude: number,
		longitude: number
	) {
		this.mapService.addArea(this.siteId, area.id, area.holeId, {
			latitude,
			longitude,
		});
	}

	private addController(
		controller: ControllerListItem,
		latitude: number,
		longitude: number
	) {
		this.controllerManager
			.updateControllers([controller.id], {
				latitude: latitude,
				longitude: longitude,
			})
			.subscribe(() => {
				const mapController = this.controllers.find(
					(c) => c.id === controller.id
				);
				mapController.latitude = latitude;
				mapController.longitude = longitude;
				this.createMarkerForController(mapController);
				this.addServerClientRelationship(mapController);
			});
	}

	private addSensor(
		sensor: SensorListItem,
		latitude: number,
		longitude: number
	) {
		this.sensorManager
			.updateSensors([sensor.id], {
				latitude: latitude,
				longitude: longitude,
			})
			.subscribe(() => {
				const mapSensor = this.sensors.find(
					(c) => c.id === sensor.id
				);
				mapSensor.latitude = latitude;
				mapSensor.longitude = longitude;
				this.createMarkerForSensor(mapSensor);
			});
	}

	/**
	 * addStation should be called when adding the indicated Station or StationListItem *to the map*.
	 * That is, setting its latitude and longitude. It should not be called for other purposes. That
	 * allows us to minimize the performance impact of adding the station, as we don't have to worry
	 * about station status, name, run time, etc.
	 */
	private addStation(station: Station | StationListItem) {
		this.mapService.updateStationLatLong(station);
	}

	private syncControllerSelected(contextMenuInfo: any) {
		const controllerId = contextMenuInfo.controller.id;
		this.controllerManager
			.haveControllersBeenSynced([controllerId])
			.subscribe((hasBeenSynced) => {
				if (hasBeenSynced) {
					this.syncController(controllerId);
					return;
				}

				const mbi = MessageBoxInfo.create(
					this.translate.instant('STRINGS.SYNCHRONIZE'),
					this.translate.instant(
						'SPECIAL_MSG.SYNC_WILL_ERASE_SETTINGS'
					),
					RbEnums.Common.MessageBoxIcon.Question,
					this.syncController.bind(this, controllerId),
					null,
					RbEnums.Common.MessageBoxButtons.YesNo
				);
				this.broadcastService.showMessageBox.next(mbi);
			});
	}

	private syncController(controllerId: number) {
		this.controllerManager.synchronize([controllerId]).subscribe(
			() => { },
			() => {
				this.messageBoxService.showMessageBox(
					'SPECIAL_MSG.REQUESTED_OPERATION_FAILED'
				);
			}
		);
	}

	private reverseSyncControllerSelected(contextMenuInfo: any) {
		const controllerId = contextMenuInfo.controller.id;
		this.controllerManager.reverseSynchronize([controllerId]).subscribe(
			() => { },
			(error) => {
				this.messageBoxService.showMessageBox(
					`${this.translate.instant(
						'SPECIAL_MSG.REQUESTED_OPERATION_FAILED'
					)} ${error.error}`
				);
			}
		);
	}

	private getControllerLogs(contextMenuInfo: any) {
		const controllerId = contextMenuInfo.controller.id;
		this.manualOpsManager.retrieveEventLogs([controllerId]).subscribe(
			() => { },
			() => {
				this.messageBoxService.showMessageBox(
					'SPECIAL_MSG.REQUESTED_OPERATION_FAILED'
				);
			}
		);
	}

	private connectControllerSelected(contextMenuInfo: any) {
		const controllerId = contextMenuInfo.controller.id;
		this.manualOpsManager.connect(controllerId).subscribe(
			() => { },
			(error) => {
				this.messageBoxService.showMessageBox(
					new MessageBoxInfo(
						'SPECIAL_MSG.UNABLE_TO_CONNECT',
						MessageBoxIcon.Error,
						'SPECIAL_MSG.CONTROLLER_CONNECTION_FAILED'
					)
				);
			}
		);
	}

	private disconnectControllerSelected(contextMenuInfo: any) {
		this.manualOpsManager.disconnect(contextMenuInfo.controller.id).subscribe(() => {
		}, error => {
			this.messageBoxService.showMessageBox(new MessageBoxInfo('SPECIAL_MSG.UNABLE_TO_DISCONNECT', MessageBoxIcon.Error,
				'SPECIAL_MSG.CONTROLLER_DISCONNECT_FAILED'));
		});
	}

	private removeHole(id: number) {
		const hole = this.holes.find((s) => s.id === id);
		if (hole == null || hole.marker == null) return;

		const mbi = MessageBoxInfo.create(
			this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_STATION_FROM_MAP', {
				name: hole.name,
			}),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				const geoItem = this.getGeoItemForHole(hole.id);
				this.mapService.removeHole(hole.id, geoItem.id);
			},
			null,
			RbEnums.Common.MessageBoxButtons.YesNo
		);
		this.broadcastService.showMessageBox.next(mbi);
	}

	private removeAreaGeoItem(contextMenuInfo: any) {
		if (contextMenuInfo.geoItem == null) return;

		const mbi = MessageBoxInfo.create(
			this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_STATION_FROM_MAP', {
				name: contextMenuInfo.area.name,
			}),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				this.mapService.removeAreaGeoItem(
					contextMenuInfo.holeId,
					contextMenuInfo.area.id,
					contextMenuInfo.geoItem.id
				);
			},
			null,
			RbEnums.Common.MessageBoxButtons.YesNo
		);
		this.broadcastService.showMessageBox.next(mbi);
	}

	private removeStationAreaGeoItem(contextMenuInfo: any) {
		if (!contextMenuInfo.geoItem) return;
		const mbi = MessageBoxInfo.create(
			this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_STATION_AREA_FROM_MAP', {
				name: contextMenuInfo.stationName,
			}),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				this.mapService.removeStationAreaGeoItem(contextMenuInfo.geoItem.id);
			},
			null,
			RbEnums.Common.MessageBoxButtons.YesNo
		);
		this.broadcastService.showMessageBox.next(mbi);
	}

	private toggleStationGeoItemAddMode(stationId: number, contextMenuInfo: any) {
		if (contextMenuInfo.station?.latitude) {
			this.addStationArea(
				stationId,
				contextMenuInfo.station?.latitude,
				contextMenuInfo.station?.longitude
			);
			return;
		}
		this.addStationArea(
			stationId,
			this.map.getCenter().lat,
			this.map.getCenter().lng
		);
	}

	private onEditStationAreaColorMenu(contextMenuInfo: any) {
	}

	private stationGeoAreaItemsEditModeRelease(geoItem?: GeoItem) {
		if (!this.isGolfSite) {
			if (!!geoItem) {
				if (this.editingStationAreaGeoItemId) return;
				const updatedStationArea = this.stationAreas.find(x => x.id === geoItem.geoGroupId);
				const updatedGeoItemIndex = updatedStationArea.geoItemIds.findIndex(x => x === geoItem.id);
				if (updatedStationArea.editModes[updatedGeoItemIndex]) {
					this.editingStationAreaGeoItemId = geoItem.id;
				}
				return;
			}
			if (this.editingStationAreaGeoItemId) {
				this.editingStationAreaGeoItemId = null;
				return;
			}
			if (!this.editingStationAreaGeoItemId) {
				this.stationAreas.forEach(stationArea => {
					stationArea.polygons.forEach((p) => {
						(<any>p).editing.disable();
					});
					for (let i = 0; i < stationArea.editModes.length; i++)
						stationArea.editModes[i] = false;
				});
				this.map.off('draw:editvertex');
			}
		}
	}

	private toggleStationAreaGeoItemEditMode(contextMenuInfo: any) {
		if (contextMenuInfo.geoItem == null) return;

		const geoGroupId = contextMenuInfo.geoItem.geoGroupId;
		const updatedStationArea = this.stationAreas.find(x => x.id === geoGroupId);
		const updatedGeoItemIndex = updatedStationArea.geoItemIds.findIndex(x => x === contextMenuInfo.geoItem.id);

		updatedStationArea.editModes[updatedGeoItemIndex] = !updatedStationArea.editModes[updatedGeoItemIndex];
		const polygon: Polygon = updatedStationArea.polygons[updatedGeoItemIndex];

		const self = this;

		if ((<any>polygon).editing) {
			if (updatedStationArea.editModes[updatedGeoItemIndex]) {
				updatedStationArea.shapeEditingFunctions[updatedGeoItemIndex] = () => {
					self.mapService.updateStationAreaGeoItem(
						contextMenuInfo.geoItem.id,
						<LatLng[]>(<unknown>polygon.getLatLngs()[0])
					);
				};
				(<any>polygon).editing.enable();
				this.map.on(
					'draw:editvertex',
					updatedStationArea.shapeEditingFunctions[updatedGeoItemIndex]
				);
			} else {
				(<any>polygon).editing.disable();
				this.map.off(
					'draw:editvertex',
					updatedStationArea.shapeEditingFunctions[updatedGeoItemIndex]
				);
			}
		}
	}

	private toggleAreaGeoItemEditMode(contextMenuInfo: any) {
		if (contextMenuInfo.geoItem == null) return;

		const index = contextMenuInfo.area.geoItemIds.findIndex(
			(g) => g === contextMenuInfo.geoItem.id
		);
		if (index === -1) return;

		contextMenuInfo.area.editModes[index] =
			!contextMenuInfo.area.editModes[index];
		const polygon: Polygon = contextMenuInfo.area.polygons[index];

		const self = this;

		if ((<any>polygon).editing) {
			if (contextMenuInfo.area.editModes[index]) {
				(<AreaWithMapInfoLeaflet>(
					contextMenuInfo.area
				)).shapeEditingFunctions[index] = function () {
					self.mapService.updateAreaGeoItem(
						contextMenuInfo.area.id,
						contextMenuInfo.geoItem.id,
						<LatLng[]>(<unknown>polygon.getLatLngs()[0])
					);
				};
				(<any>polygon).editing.enable();
				this.map.on(
					'draw:editvertex',
					contextMenuInfo.area.shapeEditingFunctions[index]
				);
			} else {
				(<any>polygon).editing.disable();
				this.map.off(
					'draw:editvertex',
					contextMenuInfo.area.shapeEditingFunctions[index]
				);
			}
		}
	}

	removeSensor(sensorId: number) {
		const sensor = this.sensors.find((s) => s.id === sensorId);
		if (!sensor) return;

		const mbi = MessageBoxInfo.create(
			this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_SENSOR_FROM_MAP', {
				name: sensor.name,
			}),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				// Clear Marker
				sensor.visible = false;
				sensor.marker.remove();
				sensor.latitude = null;
				sensor.longitude = null;
				// This will hide the marker.
				sensor.visible = false;
				this.removeLayerFromMap(sensor.marker);

				// Update sensor in db.
				this.sensorManager
					.updateSensors([sensorId], {
						latitude: null,
						longitude: null,
					})
					.subscribe(() => this.sensorRemoved.next(null));
			},
			null,
			RbEnums.Common.MessageBoxButtons.YesNo
		);
		this.broadcastService.showMessageBox.next(mbi);
	}

	removeStation(stationId: number) {
		const station = this.stations.find((s) => s.id === stationId);
		if (!station) return;

		const mbi = MessageBoxInfo.create(
			this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_STATION_FROM_MAP', {
				name: station.name,
			}),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				// Clear Marker
				this.detachStationMarkerFromMap(station);
				station.marker = null;
				station.latitude = null;
				station.longitude = null;
				station.hasLocation = false;

				// Update station in db.
				this.stationManager
					.updateStations([stationId], {
						latitude: null,
						longitude: null,
					})
					.subscribe(() => this.stationRemoved.next(null));
			},
			null,
			RbEnums.Common.MessageBoxButtons.YesNo
		);
		this.broadcastService.showMessageBox.next(mbi);
	}

	removeController(controllerId: number) {
		const controller = this.controllers.find((s) => s.id === controllerId);
		if (!controller) return;

		const mbi = MessageBoxInfo.create(
			this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_STATION_FROM_MAP', {
				name: controller.name,
			}),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				// Clear Marker

				// Remove Server/Clients relationship
				this.removeServerClientRelationship(controller);

				// controller.marker.setMap(null);
				controller.latitude = null;
				controller.longitude = null;

				// Update station in db.
				this.controllerManager
					.updateControllers([controllerId], {
						latitude: null,
						longitude: null,
					})
					.subscribe();
			},
			null,
			RbEnums.Common.MessageBoxButtons.YesNo
		);
		this.broadcastService.showMessageBox.next(mbi);
	}

	areaHasLocation(hole, area): boolean {
		const geoGroup = this.geoGroups.find(
			(r) =>
				r.siteId === hole.siteId &&
				r.areaLevel2Id === hole.id &&
				r.areaLevel3Id === area.id
		);
		if (geoGroup == null) return false;
		return geoGroup.geoItem.length > 0;
	}

	stationAreaHasLocation(stationId: number): boolean {
		const geoGroup = this.stationAreas.find(stationArea => stationArea.stationId === stationId);
		if (geoGroup == null) return false;
		return geoGroup.geoItem.length > 0;
	}

	stationAreaHasCalculate(geoGroup: StationAreaWithMapInfoLeaflet): boolean {
		if (!geoGroup?.labels) return false;
		let count = 0;
		geoGroup.labels.forEach((lm) => {
			if (this.map.hasLayer(lm)) count++;
		});
		if (count === geoGroup.labels.length) {
			this.isShowAllCalculateAreas = true;
			return true
		}
		if (count > 0) return true;
		return false;
	}

	private hasStationMapStatusChanged(
		station: StationWithMapInfoLeaflet,
		updateItem: StationListItem,
		isSkipStationPlacedOnMapCondition?: boolean // As We don't need to have station marker on Map to update station status
	): boolean {
		// Checking if the station is in the map view.
		// Consider it a change if it changed its visibility
		if (!isSkipStationPlacedOnMapCondition) {
			const wasOnMap = station.isInMapView;
			const isOnMap =
				this.mapBounds != null &&
				updateItem.latitude != null &&
				updateItem.longitude != null &&
				this.mapBounds.contains({
					lat: updateItem.latitude,
					lng: updateItem.longitude,
				});
			if (wasOnMap !== isOnMap) return true;
			if (!wasOnMap) return false; // wasn't on map and still isn't
		}

		// Check for change in running status
		const isRunning = updateItem.status !== '-';
		if (
			station.isStationRunning !== isRunning ||
			station.irrigationStatus !== updateItem.irrigationStatus
		)
			return true;

		// Check for change in running status with runTimeRemaining
		if (station.runTimeRemaining !== updateItem.secondsRemaining) return true;

		// Check for error status changes
		if (station.addressInt !== updateItem.address) return true;
		if (this.isGolfSite) {
			if (station.isConnected !== updateItem.isConnected) return true;
			if (station.fastConnectStationNumber !== updateItem.fastConnectStationNumber) return true;
			if (station.channel !== updateItem.channel) return true;
			// Suspended or unsuspended station has been changed then go to update station marker
			if (station.suspended !== updateItem.suspended) return true;
		}

		return false;
	}

	private showStationAtAllZoomLevels(station: StationWithMapInfoLeaflet): boolean {
		// const selectedStations = this.mapService.multiSelectService.getSelectedStations();
		// const itemIdx = selectedStations.findIndex(item => item.station.id === station.id);
		const itemIdx = this.mapService.multiSelectService.findIndexOfStation(station.id);
		return this.pref.sitePreferences.visibility.showingIrrigation &&
			(station.isStationRunning || station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running || itemIdx > -1);
	}

	private updateStationStatus(
		station: StationWithMapInfoLeaflet,
		updateItem: StationListItem
	): void {
		if (updateItem == null) return;

		station.status = updateItem.status;
		station.irrigationStatus = updateItem.irrigationStatus;
		station.isStationRunning = updateItem.irrigationStatus === RbEnums.Common.IrrigationStatus.Running;

		station.runTimeRemaining = updateItem.runTimeRemaining;

		if (station.isStationRunning) {
			station.runTimeRemaining = updateItem.secondsRemaining;
		}
		const classStatus = RbEnums.Common.EClassStatus;
		station.classStatus = classStatus.Default;
		switch (station.irrigationStatus) {
			default: // If we don't know the status, choose Idle
			case RbEnums.Common.IrrigationStatus.Idle:
				break;
			case RbEnums.Common.IrrigationStatus.Pending:
				station.classStatus = classStatus.Pending;
				break;
			case RbEnums.Common.IrrigationStatus.Running:
				station.classStatus = classStatus.Running;
				break;
			case RbEnums.Common.IrrigationStatus.Paused:
				station.classStatus = classStatus.Paused;
				break;
			case RbEnums.Common.IrrigationStatus.Soaking:
				station.classStatus = classStatus.Soaking;
				break;
		}
		station.suspended = updateItem.suspended;
		station.terminal = updateItem.terminal;
		station.fastConnectStationNumber = updateItem.fastConnectStationNumber;
		station.channel = updateItem.channel;
	}

	controllerHasLocation(controller: ControllerListItem): boolean {
		return controller.latitude != null && controller.longitude != null;
	}

	getGeoItemsForArea(holeId: number, areaId: number): GeoItem[] {
		const geoGroup = this.geoGroups.find(
			(r) => r.areaLevel2Id === holeId && r.areaLevel3Id === areaId
		);
		if (geoGroup == null) return [];

		return geoGroup.geoItem;
	}

	getGeoItemsForStationArea(stationId: number): GeoItem[] {
		const geoGroup = this.geoGroups.find(stationArea => stationArea.stationId === stationId);
		if (geoGroup == null) return [];

		return geoGroup.geoItem;
	}

	areasForHole(holeId: number): Area[] {
		const stationsForHole = this.stationsForHole(holeId);
		return this.areasForStation(stationsForHole);
	}

	private latLngToPixel(latlng: LatLng): Point {
		// RB-9849: Set zero zoom to avoid variations in projection calculations
		const zeroZoom = 0;
		const latLngPoint = this.map.project(latlng, zeroZoom);
		const centerLatLng = this.map.getBounds().getCenter();
		const center = this.map.project(centerLatLng, zeroZoom);

		const isFullScreen = TriPaneComponent.isFullScreen;
		const mapBounds = this.map.getContainer().getBoundingClientRect();
		const adjustedCenterX = isFullScreen
			? window.innerWidth / 2 - mapBounds.left
			: mapBounds.width / 2;
		const adjustedCenterY = isFullScreen
			? window.innerHeight / 2 - mapBounds.top
			: mapBounds.height / 2;

		// eslint-disable-next-line no-bitwise
		const scale = 1 << this.map.getZoom();
		return newPoint(
			adjustedCenterX + Math.floor((latLngPoint.x - center.x) * scale),
			adjustedCenterY + Math.floor((latLngPoint.y - center.y) * scale)
		);
	}

	private pixelToLatlng(xcoor, ycoor): LatLng {
		const mapBounds = this.map.getContainer().getBoundingClientRect();
		const pixelCenterX = mapBounds.width / 2;
		const pixelCenterY = mapBounds.height / 2;
		const offsetFromCenterX = xcoor - pixelCenterX;
		const offsetFromCenterY = ycoor - pixelCenterY;

		const centerLatLng = this.map.getBounds().getCenter();
		const center = this.map.project(centerLatLng, 0);

		// eslint-disable-next-line no-bitwise
		const scale = 1 << this.map.getZoom();
		return this.map.unproject(
			newPoint(
				offsetFromCenterX / scale + center.x,
				offsetFromCenterY / scale + center.y
			),
			0
		);
	}

	getAreasForHole(holeId: number, siteId: number) {
		// areasObj is a variable (object type) to quickly identify if an area has already been identified before.
		// This object is then changed to array (arrayAreas) to return it.
		let areasObj = {};
		this.stations.forEach((station: StationWithMapInfoLeaflet) => {
			station.isSelected = false;
			const saFoundIndex = station.stationArea.findIndex(sa => sa.areaId === holeId);

			if (saFoundIndex >= 0) {
				station.stationArea.forEach((stationArea: StationArea, index: number) => {
					if (index != saFoundIndex) {
						const areaId = stationArea.areaId;

						station.areaId = areaId;
						station.holeId = holeId;

						if (!areasObj[areaId]) {
							let currentArea: AreaWithMapInfoLeaflet;
							this.areas.forEach(area => {
								if (areaId === area.id) {
									currentArea = area;
									return false;
								}
								return true;
							});

							let currentSubAreas = [];
							currentArea.subArea.forEach((subArea: Subarea) => {
								currentSubAreas.push({
									...subArea,
									isSelected: false,
									childrenSelected: 0,
									holeId
								});
							});

							areasObj[areaId] = {
								...currentArea,
								holeId,
								subAreas: currentSubAreas,
								subArea: undefined,
								icon: this.areaHasLocation(
									{ id: holeId, siteId: siteId },
									{ id: areaId }
								),
								isSelected: false,
								childrenSelected: 0
							};
						}
					}
				})
			}

			if (station.holeId === holeId) {
				areasObj[station.areaId].subAreas.forEach(subArea => {
					if (station.subAreaId === subArea.id) {
						if (subArea.stations === undefined) {
							subArea.stations = [station];
						} else {
							subArea.stations.push(station);
						}
						return false;
					}
					return true;
				});
			}
		});

		let arrayAreas = [];
		for (let area in areasObj) {
			let arraySubA = areasObj[area].subAreas.filter(subArea => subArea.stations != undefined);
			areasObj[area].subAreas = arraySubA;

			for (let subArea in areasObj[area].subAreas) {
				areasObj[area].subAreas[subArea].stations.sort(this.sortStations);
			}

			areasObj[area].subAreas.sort(this.compareNames);
			arrayAreas.push(areasObj[area]);
		}

		arrayAreas.sort(this.compareNames);

		return arrayAreas;
	}

	private compareNames(a, b) {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	}

	private sortStations(a: StationWithMapInfoLeaflet, b: StationWithMapInfoLeaflet) {
		const numberA = a.areaId == a.stationArea[0].areaId ? a.stationArea[0].number : a.stationArea[1].number;
		const numberB = b.areaId == b.stationArea[0].areaId ? b.stationArea[0].number : b.stationArea[1].number;
		return numberA - numberB;
	}

	stationsForHole(holeId: number): Station[] {
		return this.stations.filter((station) =>
			station.stationArea.some((sa) => sa.areaId === holeId)
		);
	}

	stationsForHoleAndArea(holeId: number, areaId: number): Station[] {
		const stationsForHole = this.stationsForHole(holeId);

		return stationsForHole
			.filter((station) =>
				station.stationArea.some((sa) => sa.areaId === areaId)
			)
			.sort((a, b) => {
				const stationAreaA = a.stationArea.find(
					(sa) => sa.areaId === areaId
				);
				const stationAreaB = b.stationArea.find(
					(sa) => sa.areaId === areaId
				);
				return stationAreaA.number - stationAreaB.number;
			});
	}

	sensorHasLocation(sensor: Sensor): boolean {
		return sensor.latitude != null && sensor.longitude != null;
	}

	// Delete
	stationHasLocation(station: Station): boolean {
		return station.latitude != null && station.longitude != null;
	}

	sensorsForController(satelliteId: number): Sensor[] {
		const sensors = this.sensors.filter(
			(s) => s.satelliteId === satelliteId
		);
		return sensors;
	}

	stationForArea(stationId: number): Station {
		const station = this.stations.find(
			(s) => s.id === stationId
		);
		return station;
	}

	stationsForController(satelliteId: number): Station[] {
		const stations = this.stations.filter(
			(s) => s.satelliteId === satelliteId
		);
		return stations;
	}

	toolTipWrapper(text: string): string {
		return `<div style="color:${this.pref.sitePreferences.visibility.textColor};">${text}</div>`;
	}

	turnOffEvents() {
		this.map.off('zoomend');
		this.map.off('moveend');
		this.map.off('click');
	}

	updateRasterItem(layer: RasterItem) {
		return this.mapService.updateRasterItem(layer);
	}

	updateKmzItem(layer: KMZItem) {
		return this.mapService.updateKmzItem(layer);
	}

	deleteRasterItem(layer: RasterItem) {
		return this.mapService.deleteRasterItem(layer);
	}

	deleteKmzItem(layer: KMZItem) {
		return this.mapService.deleteKmzItem(layer);
	}

	editDistortableImage(item: RasterItem) {
		if (item.imageLayer) {
			this.removeLayerFromMap(item.imageLayer);
		}

		const img = L.distortableImageOverlay(item.imageUrl, {
			corners: [...item.corners],
			mode: 'scale',
			actions: [
				L.ScaleAction,
				L.RotateAction,
				L.DistortAction,
				L.OpacityAction,
			],
			selected: true,
		});

		img.setZIndex(this.rasterItems[0].index + 10);

		img.addTo(this.map);

		this.map.setMinZoom(14);
		this.map.panTo(img.getCenter());

		const updateRaster = async (rasterObject: RasterItem) => {
			this.busy.next(true);
			try {
				item.corners = rasterObject.corners;
				await this.leafletManager
					.updateRasterItem(rasterObject.id, rasterObject)
					.toPromise();
				this.map.removeLayer(img);
				item.editing = false;
				item.corners = JSON.parse(item.corners as string);
				this.mapService.getRasterItems(this.siteId, true).subscribe(rasterItems => {
					this.getRasterItemsHandler(rasterItems);
					this.busy.next(false);
				});
			} catch (error) {
				this.busy.next(false);
				console.error(error);
			}
		};

		// Save button
		const newAction = L.EditAction.extend({
			initialize: function (leafletMap: any, overlay: any, options: any) {
				options = options || {};
				options.toolbarIcon = {
					html: '<i class="material-icons" style="line-height:30px;font-size:18px">save</i>',
					tooltip: 'Save changes',
				};
				L.EditAction.prototype.initialize.call(
					this,
					leafletMap,
					overlay,
					options
				);
			},
			addHooks: () => {
				item.corners = JSON.stringify(img.getCorners());
				updateRaster(item);
				this.map.setMinZoom(0);
			},
		});

		// Cancel button
		const cancelAction = L.EditAction.extend({
			initialize: function (leafletMap: any, overlay: any, options: any) {
				options = options || {};
				options.toolbarIcon = {
					html: '<i class="material-icons" style="line-height:30px;font-size:18px">close</i>',
					tooltip: 'Cancel',
				};
				L.EditAction.prototype.initialize.call(
					this,
					leafletMap,
					overlay,
					options
				);
			},
			addHooks: async () => {
				this.map.removeLayer(img);
				this.map.setMinZoom(0);
				item.editing = false;
				if (item.visible) {
					item.imageLayer = await this.mapService.createDistortableImage(item);
					this.addLayerToMap(item.imageLayer);
					this.mapService.updateRastersZIndex(this, false);
				}
			},
		});

		L.DomEvent.on(img._image, 'load', function () {
			img.editing.addTool(newAction);
			img.editing.addTool(cancelAction);
		});
	}

	/**
	 * Processes the updated list of vector layers creating objects for new layers or disposing of deleted layers.
	 * 
	 * This function assigns geoJSONs to each vector item. If a list of geoJSONs is not provided, this function will try
	 * to reuse layers from a previously created object for each item. If the previous object doesn't have a geoJSON then
	 * the geoJSON is fetched individually
	 * 
	 * @param newList List of vector layers fetched from the database
	 * @param geoJSONs List of geoJSONs that correspond to the vectors in `newList`. 
	 */
	getKMZItemsHandler = (newList: KMZItem[], geoJSONs?: KMZItem[]) => {

		const oldList = this.kmzItems;
		newList = newList.map(item => new KMZItem(item));
		newList.sort((a, b) => b.index - a.index);

		this._kmzItems = newList;

		// if (this.vectorItemsChanged) {
		this.vectorItemsChanged?.emit();
		// }

		const oldPrefs = this.pref.sitePreferences.kmzItems || {};
		this.pref.sitePreferences.kmzItems = {};

		const geoJSONsCalls: Promise<void>[] = [];
		let newKMZItemFound = false;

		for (let i = this.kmzItems.length - 1; i > -1; i--) {

			const newItem = this.kmzItems[i];
			let isVisible = oldPrefs[newItem.id];

			if (isVisible == null) {
				// New item found, should be visible by default.
				// Fetch its geoJSON from the database
				newKMZItemFound = true;
				isVisible = true;

				// collect a promise for each new geoJSON petition
				geoJSONsCalls.push(this.mapService.loadItemGeoJSON(newItem));

			} else if (geoJSONs?.length) {
				// If we have a geoJSONs list use that.
				const index = geoJSONs.findIndex(kmz => kmz.id === newItem.id);
				if (index > -1) {
					newItem.geoJson = geoJSONs[index].geoJson;
					geoJSONs.splice(index, 1);
				}
			} else if (oldList.length > 0) {
				// Reuse previous geoJson and layer
				const index = oldList.findIndex(oldItem => oldItem.id === newItem.id);
				if (index > -1) {
					const prevItem = oldList[index];
					newItem.geoJson = prevItem.geoJson;
					newItem.layer = prevItem.layer;
					
					if (newItem.layer) {
						// Apply styles from newly fetched item to the reused GeoJSON layer just in case they changed
						const newStyle = {...newItem.properties};
						newStyle.opacity /= 100;
						newStyle.fillOpacity /= 100;
						newItem.layer?.setStyle(newStyle);

						// Same for the "show tooltips" option
						if (newItem.properties.showTooltips !== prevItem.properties.showTooltips) {

							if (newItem.properties.showTooltips) {
								newItem.layer.eachLayer(layer => {
									const feature: GeoJSON.Feature<null, GeoJSONProperties> = layer["feature"];
									if (feature) {
										const tooltip = L.tooltip({
											permanent: true,
											direction: 'right',
											className: 'leafletMarkerTooltip'
										}).setContent(feature.properties.tooltip);
										layer.bindTooltip(tooltip);
									}
								});
							} else {
								newItem.layer.eachLayer(layer => {
									layer.unbindTooltip();
								});
							}
						}
					}
					

					oldList.splice(index, 1);
				}
			}

			if (oldPrefs[newItem.id] && !newItem.geoJson?.features?.length) {
				// If the layer should be visible but we don't have its geoJSON data in memory (can happen when
				// a layer is made visible on a different device but it's never been visible on the current 
				// device), go and ask for it 
				// * Using `oldPrefs[newItem.id]` to ignore new items

				// collect a promise
				geoJSONsCalls.push(this.mapService.loadItemGeoJSON(newItem));
			}

			newItem.visible = isVisible;
			this.pref.sitePreferences.kmzItems[newItem.id] = isVisible;
			delete oldPrefs[newItem.id];
		}

		// Fetch the missing geoJSONs and then re-render the layers on the map
		// * Using Promise.all() because the functions that load the geoJSONs data use await so that
		//   we know when all items have their data loaded. Even if we used an API call, the subscription
		//   is converted to a promise using rxjs' firstValueFrom()
		Promise.all(geoJSONsCalls).then(() => this.mapService.updateVectorsZIndex(this, false));

		const remainingLayers = Object.keys(oldPrefs);

		this.mapService.removeGeoJSONs(remainingLayers);

		if (newKMZItemFound || remainingLayers.length > 0) {
			// There were layers added or deleted. Save the new list of layer visiblity preferences
			this.pref.save();
		}

	}

	getRasterItemsHandler = async (list: any[]) => {
		// This removes all current raster layers
		this.removeAllRasterLayersFromMap();
		this._rasterItems = [];

		list.sort((a, b) => b.index - a.index);

		for (const ri of list) {
			this.rasterItems.push(new RasterItem(ri, this));
		}

		let shouldUpdatePrefs = false;

		const rasterPrefs = this.pref.sitePreferences.rasterItems || {};
		this.pref.sitePreferences.rasterItems = {};

		if (list.length !== Object.keys(rasterPrefs).length) {
			shouldUpdatePrefs = true;
		}

		for (const item of this.rasterItems) {
			item.editing = false;
			item.corners = this.processCorners(item.corners as string);
			const isVisible = rasterPrefs[item.id];

			switch (isVisible) {
				case undefined:
					shouldUpdatePrefs = true;
				/* falls through */
				case true:
					if (this.canShowCustomLayers && this.layerVisibility.showingRasters) {
						item.imageLayer = await this.mapService.createDistortableImage(item);
						this.addLayerToMap(item.imageLayer);
					}
					item.visible = true;
					this.pref.sitePreferences.rasterItems[item.id] = true;
					break;
				case false:
					item.visible = false;
					this.pref.sitePreferences.rasterItems[item.id] = false;
					break;
			}
		}

		if (shouldUpdatePrefs) {
			this.pref.save();
		}

		this.mapService.updateRastersZIndex(this, false);

		if (this.rasterItemsChanged) {
			this.rasterItemsChanged.emit();
		}

		// Set the z-index of the canvas that shows the vector layers
		// The canvas may not exist the moment we finish processing the raster layers,
		// so check very 2 seconds
		this.canvasInterval = window.setInterval(() => {
			const canvas = this.map.getPane('overlayPane').querySelector('canvas');
			if (canvas) {
				canvas.style.zIndex = '' + (this.rasterItems.length + 3);
				clearInterval(this.canvasInterval);
				this.canvasInterval = undefined;
			}
		}, 2000);
	}

	setCustomLayerVisibility(
		customLayer: RasterItem | KMZItem | KMZGroup,
		isVisible: boolean
	) {
		if (customLayer instanceof RasterItem) {
			this.pref.sitePreferences.rasterItems[customLayer.id] = isVisible;
		} else if (customLayer instanceof KMZItem) {
			this.pref.sitePreferences.kmzItems[customLayer.id] = isVisible;
		}

		this.pref.save();
	}

	/**
	 * Adds or removes all (applicable) raster layers from the map.
	 *
	 * Used by the "show/hide" button on the Custom Layer Editor
	 *
	 * @param show Wheter to show or hide the layers
	 */
	setRasterLayersVisibility(show: boolean) {
		if (show) {
			this.showRasterLayers();
		} else {
			this.removeAllRasterLayersFromMap();
		}
	}

	/**
	 * Adds or removes all (applicable) vector (KMZ/KML) layers from the map.
	 *
	 * Used by the "show/hide" button on the Custom Layer Editor
	 * @param show Wheter to show or hide the layers
	 */
	setVectorLayersVisibility(show: boolean) {
		if (show) {
			this.showVectorLayers();
		} else {
			this.removeAllVectorLayersFromMap();
		}
	}

	/**
	 * Removes all raster & kmz layers from the map and sets the layer reference to undefined
	 */
	removeAllVectorLayersFromMap() {
		this.kmzItems.forEach((item) => {
			if (item.layer) {
				this.removeLayerFromMap(item.layer);
				// item.layer = undefined;
			}
		});
	}

	updateStationById(id: number) {
		this.stationManager.getStation(id).pipe(take(1)).subscribe((newStationData: StationWithMapInfoLeaflet) => {
			const station = this.stations.find(s => s.id === id);
			if (station) {
				const newKeys = Object.keys(newStationData);

				newKeys.forEach(key => {
					if (newStationData[key] !== undefined && newStationData[key] !== null
						&& station[key] !== newStationData[key]) {
						station[key] = newStationData[key];
					}
				});

				RbUtils.Stations.checkIfHasAdjustment(station);
				this.createMarkerForStation(station);
			}
		});
	}

	async checkCustomLayers() {

		if (this.showingCustomLayers === undefined) {
			this.showingCustomLayers = this.canShowCustomLayers;
			return;
		}

		if (this.showingCustomLayers && !this.canShowCustomLayers) {
			for (const item of this.rasterItems) {
				if (item.visible) {
					this.removeLayerFromMap(item.imageLayer);
				}
			}

			for (const item of this.kmzItems) {
				if (item.visible) {
					this.removeLayerFromMap(item.layer);
				}
			}

			this.showingCustomLayers = false;
		} else if (!this.showingCustomLayers && this.canShowCustomLayers) {

			if (this.layerVisibility.showingRasters) {
				for (const item of this.rasterItems) {
					if (item.visible && !item.editing) {
						if (item.imageLayer) {
							this.removeLayerFromMap(item.imageLayer);
						}
						item.imageLayer = await this.mapService.createDistortableImage(item);
						this.addLayerToMap(item.imageLayer);
					}
				}
				this.mapService.updateRastersZIndex(this, false);
			}

			if (this.layerVisibility.showingVectors) {
				const geoJSONsCalls: Promise<void>[] = [];
				for (const item of this.kmzItems) {
					if (item.visible) {
						geoJSONsCalls.push(this.mapService.loadItemGeoJSON(item));
					}
				}
				Promise.all(geoJSONsCalls).then(() => this.mapService.updateVectorsZIndex(this, false));
			}

			this.showingCustomLayers = true;
		} else if (this.showingCustomLayers !== this.canShowCustomLayers) {
			this.showingCustomLayers = this.canShowCustomLayers;
			this.checkCustomLayers();
		}
	}

	private removeAllRasterLayersFromMap() {
		this.rasterItems.forEach((item) => {
			if (item.imageLayer) {
				this.removeLayerFromMap(item.imageLayer);
				item.imageLayer = undefined;
			}
		});
	}

	async showRasterLayers() {
		for (const item of this.rasterItems) {
			if (item.visible && this.canShowCustomLayers && this.layerVisibility.showingRasters) {
				if (item.imageLayer) {
					this.removeLayerFromMap(item.imageLayer);
				}
				item.imageLayer = await this.mapService.createDistortableImage(item);
				this.addLayerToMap(item.imageLayer);
			}
		}
		this.mapService.updateRastersZIndex(this, false);
	}

	async showVectorLayers() {
		this.mapService.updateVectorsZIndex(this, false);
	}

	private processCorners(array: string) {
		if (!array) {
			return [];
		}
		const coords: number[][] = JSON.parse(array);
		return coords.map((coord) => {
			return { lat: coord[1], lng: coord[0] };
		});
	}

	/**
	 * Loads both vector and raster layers at the same time
	 */
	async loadVectorAndRasterLayers() {
		this.mapService.getRasterItems(this.siteId, true).subscribe(this.getRasterItemsHandler);
		this.loadingVectorLayers.next(true);
		this.busy.next(true);

		forkJoin({
			kmzItems: this.mapService.getKmzItemList(this.siteId, true),
			geoJsons: await this.mapService.getActiveGeoJsons(this.siteId, `mapPref_${this.siteId}_${this.uniqueId}`, this.prefs.sitePreferences.kmzItems)
		}).subscribe(async response => {
			await this.getKMZItemsHandler(response.kmzItems, response.geoJsons);
			this.loadingVectorLayers.next(false);
		}, (error) => {
			this.loadingVectorLayers.next(false);
			this.busy.next(false);
			throw error;
		});
	}

	/**
	 * Returns a configurable function
	 */
	private siteAddressLookupCallbackFactory = (options: lookupSiteAddressOptions) => {
		return (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
			if (
				status !== google.maps.GeocoderStatus.OK ||
				results == null ||
				results.length === 0
			) {
				this.siteAddressLookupFailed.next(null);
				return;
			}

			const latLong = latLng(
				results[0].geometry.location.lat(),
				results[0].geometry.location.lng()
			);

			this.site.latitude = latLong.lat;
			this.site.longitude = latLong.lng;
			this.pref.settingPreferences = true;

			if (options.centerOnAddress) {
				this.map.panTo(
					latLong,
				);
			}

			this.pref.settingPreferences = false;
			if (options.savePreferences) this.pref.save();

			if (this.courseMarker != null) {
				this.removeLayerFromMap(this.courseMarker);
			}

			this.courseMarker = marker(latLong, {
				icon: L.divIcon({
					className: 'course-marker',
					html: '<div class="outer-circle"><div class="inner-circle"></div></div>',
					iconSize: [40, 40],
					// iconAnchor: [20, 64],
				}),
			});

			this.courseMarker.bindTooltip(
				this.toolTipWrapper(this.site.name),
				{
					permanent: true,
					direction: 'right',
					// offset: [17, -45],
					offset: [10, -20],
					className: 'leafletMarkerTooltip',
				}
			);

			// only show site popup in IQ4
			if (!this.isGolfSite) {
				this.courseMarker.on('click', (event: LeafletMouseEvent) => this.handleSiteAddressMarkerClick(event));
			};

			this.addLayerToMap(this.courseMarker);
			this.updateMapBounds();

			if (options.fitMapBounds) {
				this.fitMapBoundsByMarker(this.courseMarker);
			}

			this.busy.next(false);
		}
	}

	private handleSiteAddressMarkerClick(event: LeafletMouseEvent) {
		const latLngPoint = this.latLngToPixel(event.latlng);
		this.contextMenuInvoked.next(this.contextMenu.siteOptions(this.site, latLngPoint.x, latLngPoint.y, this.areItemsMovable));
	}

	/**
	 * Callback triggered by Google lookup of address. The post-condition of this callback is that
	 * this.companyAddressLookupComplete will be set true, and this.companyLatitude and this.companyLongitude 
	 * will be set either to valid geolocation or null depending on the validity of the address data 
	 * provided for lookup.
	 */
	private companyAddressLookupCallbackFactory = () => {
		return (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
			// Indicate that an address lookup was completed. May be successful, or not. This just
			// indicates completion.
			this.companyLatitude = this.companyLongitude = null;
			this.companyAddressLookupComplete = true;

			if (
				status !== google.maps.GeocoderStatus.OK ||
				results == null ||
				results.length === 0
			) {
				return;
			}

			const latLong = latLng(
				results[0].geometry.location.lat(),
				results[0].geometry.location.lng()
			);
			this.companyLatitude = latLong.lat;
			this.companyLongitude = latLong.lng;
		}
	}

	/**
	 * Automatically adjust the zoom level by marker,
	 * Maximum zoom level is 18 for better view
	 *
	 * @param marker Marker
	 */
	private fitMapBoundsByMarker(marker: L.Marker) {
		const group = L.featureGroup([marker]);
		// Animation from flyToBounds doesn't look good when adjusted from min zoom level,
		// this.map.flyToBounds(group.getBounds(), { maxZoom: 18 });
		// use fitBounds for now
		this.map.fitBounds(group.getBounds(), { maxZoom: 18 });
	}

	centerMap(latlng: L.LatLngLiteral, zoom: number, padding?: any) {
		let targetPoint = this.map.project(latlng, zoom);
		if (padding) {
			targetPoint = targetPoint.subtract(padding)
		}
		const targetLatLng = this.map.unproject(targetPoint, zoom);
		this.map.setView(targetLatLng, zoom);
	}

	selectNearbyStations(latlng, radius = 100) {
		const selectedStations = this.selectMarkersWithinRadius(latlng, radius)
		if (selectedStations.length) {
			for (const station of selectedStations) {
				this.selectStation(station)
			}
			this.toggleMultiSelectMode(true, true)
		}
	}

	private selectMarkersWithinRadius(center, radiusInMeters) {
		const selectedMarkers = [];
		for (const station of this.stations) {
			if (station.latitude && station.longitude) {
				const stationLatLng = L.latLng(station.latitude, station.longitude)
				const distance = L.latLng(center).distanceTo(stationLatLng);
				if (distance <= radiusInMeters) {
					selectedMarkers.push(station);
				}
			}
		}
		return selectedMarkers;
	}

	/**
	 * Automatically adjust the zoom level by LatLng,
	 * Maximum zoom level is 18 for better view
	 *
	 * @param latLngs LatLngBoundsLiteral
	 */
	private fitMapBoundsByLatLngs(latLngs: L.LatLngBoundsLiteral) {
		const bounds = new L.LatLngBounds(latLngs);
		this.map.fitBounds(bounds, { maxZoom: 18 });
	}

	private validateInternetConnection(callbackFn: () => void) {
		const s = this.mapService.internetService.testConnectionFunction().subscribe((isConnected) => {
			if (isConnected) {
				callbackFn();
				if (this.internetCheckInterval) {
					clearInterval(this.internetCheckInterval);
					this.internetCheckInterval = undefined;
				}
			} else {
				this.mapService.toastService.showToaster(this.translate.instant('STRINGS.NO_INTERNET'), 5000);
				if (!this.internetCheckInterval) {
					this.internetCheckInterval = setInterval(() => {
						this.mapService.internetService.testConnectionFunction().subscribe((isConnected) => {
							if (isConnected) {
								clearInterval(this.internetCheckInterval);
								this.internetCheckInterval = undefined;
							}
						});
					}, 120000);
				}
			}
			s.unsubscribe();
		});
	}

	private get areaManager(): AreaManagerService {
		return this.mapService.areaManager;
	}
	private get authManager(): AuthManagerService {
		return this.mapService.authManager;
	}
	private get broadcastService(): BroadcastService {
		return this.mapService.broadcastService;
	}
	private get companyManager(): CompanyManagerService {
		return this.mapService.companyManager;
	}
	private get controllerManager(): ControllerManagerService {
		return this.mapService.controllerManager;
	}
	private get deviceManager(): DeviceManagerService {
		return this.mapService.deviceManager;
	}
	private get env(): EnvironmentService {
		return this.mapService.env;
	}
	private get geoGroupManager(): GeoGroupManagerService {
		return this.mapService.geoGroupManager;
	}
	// private get leafletManager(): LeafletManagerService { return this.mapService.leafletMgrService }
	private get manualOpsManager(): ManualOpsManagerService {
		return this.mapService.manualOpsManager;
	}
	private get sensorManager(): SensorManagerService {
		return this.mapService.sensorManager;
	}
	// private get renderer(): Renderer2 { return MapService.renderer; } %%
	private get stationManager(): StationManagerService {
		return this.mapService.stationManager;
	}
	private get systemStatusService(): SystemStatusService {
		return this.mapService.systemStatusService;
	}
	public get siteManager(): SiteManagerService {
		return this.mapService.siteManager;
	}
	private get leafletManager(): LeafletManagerService {
		return this.mapService.leafletMgrService;
	}
	private get translate(): TranslateService {
		return this.mapService.translate;
	}
	private get uiSettingsService(): UiSettingsService {
		return this.mapService.uiSettingsService;
	}
	private get isCurrent(): boolean {
		return this.mapService.currentSiteId === this.siteId;
	}
	private get voltageDiagnosticManager(): VoltageDiagnosticManagerService {
		return this.mapService.voltageDiagnosticManager;
	}
	private get moduleApiService(): ModuleApiService {
		return this.mapService.moduleApiService;
	}
	private get stickyNoteManager(): StickyNoteManagerService {
		return this.mapService.stickyNoteManager;
	}

	get messageBoxService(): MessageBoxService { return this.mapService.messageBoxService; }
	get downloadingTiles(): boolean { return this.mapService.downloadingTiles; }
	set downloadingTiles(downloadingTiles: boolean) { this.mapService.downloadingTiles = downloadingTiles; }
	get removingTiles(): boolean { return this.mapService.removingTiles; }
	set removingTiles(removingTiles: boolean) { this.mapService.removingTiles = removingTiles; }
	get downloadTilesBtnReadyDelay(): number { return this.mapService.downloadTilesBtnReadyDelay; }

	// map related selectors
	get leafletMapContainerNodeElement(): any {
		if (this.leafletMap != null && this.leafletMap.getContainer != null)
			return this.leafletMap.getContainer();

		return null;
	}

	get offLineToggleNodeElement(): HTMLInputElement {
		if (this.leafletMapContainerNodeElement)
			return this.leafletMapContainerNodeElement.querySelector('input.leaflet-control-layers-selector');

		return null;
	}

	get offLineToggleContainerNodeElement(): HTMLElement {
		if (!this.offLineToggleNodeElement)
			return null;

		return this.offLineToggleNodeElement
			.parentElement
			.parentElement
			.parentElement
			.parentElement
			.parentElement;
	}

	get canAddNewStickyNote() {
		return this.pref.sitePreferences.visibility.showingStickyNotes && !this.stickyNotes.find(s => s.id === 0);
	}

}

declare module 'leaflet' {
	namespace control {
		function savetiles(layer: any, options: any);
	}
	namespace Control {
		function addOverlay(layer: any, name: string);
	}
	namespace tileLayer {
		function offline(url: string, options: any);
	}

	function kmzLayer();

	export function tileLayer(
		urlTemplate: string,
		options?: L.TileLayerOptions
	): L.TileLayer;
	export namespace tileLayer {
		function offline(
			url: String,
			tilesDb: Object,
			options: Object
		): LO.TileLayerOffline;
	}

	export function control();
	export namespace control {
		export function offline(
			baseLayer: Object,
			tilesDb: Object,
			options: Object
		): LO.ControlOffline;
	}
}

interface lookupSiteAddressOptions {
	centerOnAddress: boolean,
	savePreferences?: boolean,
	fitMapBounds?: boolean
}
import { Component, EventEmitter, Input, Output, } from '@angular/core';

/**
 * The SystemStatusButtonComponent is a shared component used when displaying the golf system status (On/Off/Paused).
 * We have used direct CSS-based construction of the button for display on the system status page and the system status
 * toolbar, but that's too complicated to replicate onto the sensor desktop and mobile tabs. We need two states for
 * this button right now: Paused and Off. Paused uses an outline ring around the 'paused' material icon, while Off uses
 * an outline ring around the 'clear' X material icon. The whole button needs to be scalable in pixels and should
 * be reasonably centerable.
 */
@Component({
	selector: 'rb-system-status-button',
	templateUrl: './system-status-button.component.html',
	styleUrls: ['./system-status-button.component.scss'],
})
export class SystemStatusButtonComponent {

	@Input() disabled = false;
	@Input() matIconName = 'paused';
	@Input() ringThicknessPixels = 3;

	// 24px is the normal size for the system status toolbar, or at least matches it relatively closely.
	// Note that you can specify these in em, rem, px, whatever you want. Alignment of the matIcon may
	// vary using other measurements.
	@Input() buttonWidth = '24px';
	@Input() buttonHeight = '24px';
	@Input() buttonFontSize = '24px';
	@Input() buttonLineHeight = '24px';
	@Input() buttonColor = '#008080ff';
	// Some useful values:
	// System shutdown button rgb(245, 115, 54)
	// System pause button rgb(243, 181, 67)

	// 17px is the normal size for the system status toolbar.
	// Note that you can specify these in em, rem, px, whatever you want. Alignment of the matIcon may
	// vary using other measurements.
	@Input() iconWidth = '17px';
	@Input() iconHeight = '17px';
	@Input() iconFontSize = '17px';
	@Input() iconLineHeight = '17px';
	@Input() iconColor = 'white';

	@Output() buttonClicked = new EventEmitter<Event>();

	/**
	 * Return the style string for the ngStyle attribute of the button element.
	 */
	public buttonStyle(): any {
		return {
			'backgroundColor': this.buttonColor,
			'width': this.buttonWidth,
			'height': this.buttonHeight,
			'font-size': this.buttonFontSize,
			'line-height': this.buttonLineHeight
		};
	}

	public iconStyle(): any {
		return {
			'color': this.iconColor,
			'width': this.iconWidth,
			'height': this.iconHeight,
			'font-size': this.iconFontSize,
			'line-height': this.iconLineHeight,
			'vertical-align': 'super',	// Align to the center of the parent button.
			'margin-top': `calc(-1 * 0.5 * ${this.iconLineHeight})`	// Unfortunately we have to adjust where the icon appears in the container
		};
	}
}

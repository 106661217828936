import { GolfSensor } from '../../../../api/sensors/models/golf-sensor.model';
import { HoleAreaPair } from '../../../../api/sites/models/hole-area-pair.model';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { ReportFilterDateRange } from './report-filter-date-range.model';
import { ReportFilterTimePeriod } from './report-filter-time-period.model';
import { SiteTreeView } from '../../../../api/sites/models/site-tree-view.model';

export class ReportFilter {

	constructor(controllerIds: number[] = null) {
		this.controllerIds = controllerIds;
	}
	courseId: number;
	holeAreaPairs: HoleAreaPair[] = null;
	controllerIds: number[] = null;
	selectedControllerTreeViewItems: SiteTreeView[] = [];
	dateRange: ReportFilterDateRange = null;
	eventLogTypes: RbEnums.Common.EventLogType[] = null;
	selectedFlowSensors: GolfSensor[] = [];
	month: number = null;
	timePeriod: ReportFilterTimePeriod = null;
}

import * as moment from 'moment';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { RbUtils } from '../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-sensor-duration-selector',
	templateUrl: './sensor-duration-selector.component.html',
	styleUrls: ['./sensor-duration-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SensorDurationSelectorComponent),
			multi: true
		}
	]
})
export class SensorDurationSelectorComponent implements OnInit, ControlValueAccessor {
	@ViewChild('durationDialog') durationDialog;

	@Output() durationValueChange = new EventEmitter<moment.Duration>();

	@Input() hideDays = true;
	@Input() hideHours = false;
	@Input() hideMinutes = false;
	@Input() hideSeconds = false;
	@Input() placeholder: string;
	@Input() showDurationDialog = true;
	@Input() dialogTitle: string = null;

	private _maxDuration: moment.Duration = null;
	@Input() set maxDuration(value: moment.Duration) { if (value != null) this._maxDuration = value; }
	get maxDuration(): moment.Duration { return this._maxDuration; }

	private _isReadOnly = false;
	@Input() set isReadOnly(value: boolean) { if (value != null) this._isReadOnly = value; }
	get isReadOnly(): boolean { return this._isReadOnly; }

	private duration: moment.Duration;

	durationDialogRef: MatDialogRef<any>;
	durationString = '';
	isDisabled = false;

	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => {};
	private onTouched = () => {};

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				public dialog: MatDialog) { }

	ngOnInit(): void {
		this.isReadOnly = this.authManager.isReadOnly;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	durationTimeClicked() {
		this.onTouched();
		this.durationDialogRef = this.dialog.open(this.durationDialog);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	get value(): moment.Duration { return this.duration; }

	writeValue(duration: any): void {
		this.duration = duration;
		if (this.showDurationDialog) {
			this.updateDuration();
			this.updateDurationString();
		}
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onDurationChanged(duration: moment.Duration) {
		this.duration = duration;

		if (this.showDurationDialog) {
			this.updateDuration();
			this.updateDurationString();
		} else {
			this.updateDuration();
		}
		this.onChange(this.duration);
		this.durationValueChange.emit(this.duration);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private updateDuration() {
		if (!this.duration) return;

		// Handle a maximum allowable duration. If set, maxDuration will override maxHours and maxMinutes.
		if (this.maxDuration !== null && this.duration.asSeconds() > this.maxDuration.asSeconds()) {
			this.setMaxDuration();
			return;
		}
	}

	private updateDurationString() {
		if (!this.duration) return;

		this.durationString = '';

		if (!this.hideDays) this.addValueToDurationString(this.duration.days());
		if (!this.hideHours) {
			let hours = this.duration.hours();
			if (this.hideDays && this.duration.days() > 0) { hours += this.duration.days() * 24; }
			this.addValueToDurationString(hours);
		}
		if (!this.hideMinutes) this.addValueToDurationString(this.duration.minutes());
		if (!this.hideSeconds) this.addValueToDurationString(this.duration.seconds());
	}

	private setMaxDuration() {
		if (this.maxDuration === null || this.duration.asSeconds() < this.maxDuration.asSeconds()) { return; }

		this.duration = this.maxDuration.clone();
	}

	private addValueToDurationString(value: number) {
		if (this.durationString.length > 0) { this.durationString += ':'; }

		this.durationString += RbUtils.Common.pad(value);
	}

}

import { MapInfoLeaflet } from './map-info-leaflet.model';
import { RbEnums } from '../enumerations/_rb.enums';
import { SiteManagerService } from '../../api/sites/site-manager.service';
import { UiSettingsService } from '../../api/ui-settings/ui-settings.service';

import Timer = NodeJS.Timer;
import { SitePreferences } from './site-preferences.model';

export class MapInfoPreferencesLeaflet {

	public static defaultSitePreferences: any = {
		visibility: {
			showingAlerts: true,
			showingAreas: true,
			showingHoles: true,
			showingIrrigation: true,
			showingSensors: true,
			showingSensorNames: true,
			showingRasters: true,
			showingVectors: true,
			showingStations: true,
			showingStationNames: true,
			showingStationRuntimes: true,
			showingNozzleColors: true,
			showingStationAdjustments: true,
			showingStationCycleSoak: true,
			showingStationGeoAreas: true,
			showingMasterValves: true,
			showingMasterValvesName: true,
			showingMasterValvesGeoAreas: true,
			showingControllers: true,
			showingControllersName: true,
			showingOffline: false,
			showingStickyNotes: false,
			textColor: '#FFF',
			moveable: false,
			selectedBaseLayer: RbEnums.Map.BackgroundLayer.Esri
		}
	};

	sitePreferences: SitePreferences = MapInfoPreferencesLeaflet.defaultSitePreferences;
	settingPreferences = false;

	private savePreferencesTimer: Timer;

	constructor(private mapInfo: MapInfoLeaflet, private uiSettingsService: UiSettingsService) { }

	load() {
		return new Promise<void>((resolve, reject) => {
			this.uiSettingsService.getPreference(this.sitePreferenceId).subscribe(sitePreferences => {
				this.sitePreferences = this.safeVisibilitySettings(sitePreferences || MapInfoPreferencesLeaflet.defaultSitePreferences);
				resolve();
			});
		});
	}

	save(): void {
		if (this.savePreferencesTimer != null) clearTimeout(this.savePreferencesTimer);
		if (this.settingPreferences) return;
		const map = this.mapInfo.map;

		if (!map) return;

		// Prevent saving preferences many times in response to map updates
		this.savePreferencesTimer = setTimeout(() => {
			this.savePreferencesTimer = null;
			const center = map.getCenter();
			this.sitePreferences.center = {
				latitude: center.lat,
				longitude: center.lng
			};
			this.sitePreferences.zoom = map.getZoom();
			// this.sitePreferences.heading = map.getHeading(); %% Not present in leaflet
			// this.sitePreferences.tilt = map.getTilt(); %% Not present in leaflet
			this.uiSettingsService.setPreference(this.sitePreferenceId, this.sitePreferences).subscribe();
		}, 500);
	}

	private get sitePreferenceId(): string {
		return `${SiteManagerService.MAP_PREFERENCE_KEY_BASE}_${this.mapInfo.siteId}_${this.mapInfo.uniqueId}`;
	}

	/**
	 * Because we've changed the layout of the visibility settings in our site preferences, we need to be sure that some
	 * user, upgrading from an earlier version where settings were saved, will not get a bunch of errors because we're
	 * assuming that this.sitePreferences.visibility is present and has the expected format. This is a central location
	 * to clean up/default any missing or reformatted site preferences.
	 */
	private safeVisibilitySettings(sitePreferences: any): any {
		// Use the default visibility settings as a model, adding any that the user's data is missing. If the user data
		// is present, ignore that property. This is done with the spread operator. Note that when there's a collision,
		// the right-most object in the list wins.
		const ret = { ...MapInfoPreferencesLeaflet.defaultSitePreferences, ...sitePreferences };
		ret.visibility = { ...MapInfoPreferencesLeaflet.defaultSitePreferences.visibility, ...sitePreferences.visibility };
		ret.visibility.moveable = false;
		return ret;
	}
}

import * as moment from 'moment';
import { RainWatchData } from '../../../api/signalR/rain-watch-data.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SensorStatus } from './sensor-status.model';

export class GolfSensorListItem {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.pauseTimeUTC) {
				this.pauseTimeUTC = RbUtils.Conversion.convertStringToDate(json.pauseTimeUTC);
			}
			if (json.shutdownTimeUTC) {
				this.shutdownTimeUTC = RbUtils.Conversion.convertStringToDate(json.shutdownTimeUTC);
			}
			if (json.windowStartTimeUTC) {
				this.windowStartTimeUTC = RbUtils.Conversion.convertStringToDate(json.windowStartTimeUTC);
			}
		}
	}

	addressInt: number;
	flowSensorModel: RbEnums.Common.FlowSensorModelType;
	floZoneName: string;
	golfWeatherSensorModel: RbEnums.Common.GolfWeatherSensorModel;
	id: number;
	kingdomId: RbEnums.Common.GolfSensorKingdom;
	name: string;
	notes: string;
	satelliteId: number;
	satelliteName: string;
	satelliteType: RbEnums.Common.DeviceType;
	type: string;
	typeId: RbEnums.Common.SensorType;
	status: string;
	pauseThreshold?: number = null;
	shutdownThreshold?: number = null;
	channel: number;
	groupNumber: number;
	subchannel?: string = null;

	/**
	 * Status of interface on which sensor resides (or perhaps satellite, if it's a satellite-based sensor). This is not
	 * populated from the database, only based on UI-calculated data.
	 */
	isInterfaceConnected = false;

	/**
	 * Suspended status of the sensor. Should always be false for now. We use a common renderer for connected status with
	 * stations which *do* have a suspended status, so we need the same.
	 */
	suspended = false;

	/**
	 * Connected status of the sensor device. This property matches a similar one in station list items so we can share
	 * the same list renderer. We actually retrieve the value from our status data.
	 */
	get isConnected() {
		return (this.sensorStatus != null				// No status for the sensor at all yet.
			&& this.sensorStatus.sensorStatusItem != null	// No SignalR data for the sensor yet.
			&& (this.sensorStatus.sensorStatusItem.reasonCode === null ||
				this.sensorStatus.sensorStatusItem.reasonCode === RbEnums.SignalR.SensorFailureReasonCode.NoError));
				// Return true if we have a good sensor value packet from SignalR, false otherwise (on error, for example).
	}

	/**
	 * Amount of rain accumulated toward the Pause threshold in Rain Watch by a rain can sensor (inches).
	 */
	pauseTotalInches?: number = null;

	/**
	 * If non-null, the UTC time when pause started.
	 */
	pauseTimeUTC: Date = null;

	/**
	 * Amount of rain accumulated toward the Rain Shut Down threshold in Rain Watch by a rain can sensor
	 * (inches).
	 */
	shutdownTotalInches?: number = null;

	/**
	 * If non-null, the UTC time when shutdown started
	 */
	shutdownTimeUTC: Date = null;

	/**
	 * Incremental rain accumulated in Rain Watch (inches).
	 */
	incrementalTotalInches?: number = null;

	/**
	 * If non-null, the UTC time of the last rain.
	 */
	windowStartTimeUTC: Date = null;

	/**
	 * If non-null, specifies whether the corresponding sensor's flow value (it's a FlowSensor), should be
	 * displayed on the FloGraph.
	 */
	displayFlowOnFloGraph?: boolean = null;

	/**
	 * Saves satellite address with a string format like the following "InterfaceName-GroupNumber-SatelliteNumber-Subchannel",
	 * NOT set by the API.
	 */
	satelliteSensorAddress?: string;
	/**
	 * Name of the parent interface of the satellite, e.g: MIM LINK, MIM.
	 * NOT set by the API.
	 */
	satelliteInterfaceName?: string;
	/**
	 * ID of the parent interface of the satellite
	 * NOT set by the API.
	 */
	satelliteInterfaceId?: number;

	get address() {
		// If we've set the satelliteSensorAddress for this sensor, then we return it.
		if (this.satelliteSensorAddress) return this.satelliteSensorAddress;

		return RbUtils.Stations.addressString(this.addressInt, this.satelliteType);
	}

	/**
	 * @summary sensorStatus returns the SensorStatus object for the sensor.
	 */
	get sensorStatus() {
		return this._sensorStatus;
	}
	set sensorStatus(value: SensorStatus) {
		this._sensorStatus = value;
	}
	private _sensorStatus: SensorStatus;

	/**
	 * @summary programmableSensorActiveIndicators uses the current sensor status and the trigger levels and
	 * indicators to return a list of those indicator indices which are active (hot, in the out of range condition).
	 * An empty list is returned if no indicators are active.
	 */
	get programmableSensorActiveIndicators(): number[] {
		// Checking the triggers for the sensor, determine which indicator indices should be returned.
		return this._programmableSensorActiveIndicators;
	}
	set programmableSensorActiveIndicators(indices: number[]) {
		this._programmableSensorActiveIndicators = indices;
	}
	private _programmableSensorActiveIndicators: number[] = [];

	/**
	 * Return the rain watch data for the sensor, either the initial data from the sensor database entries or the
	 * status item, if SignalR has sent one. This should give the latest data in either case, allowing us to show
	 * sensible status immediately after retrieving the sensor from the database while updating immediately when
	 * new data arrives.
	 */
	get rainWatchData(): RainWatchData {
		if (this._sensorStatus != null
			&& this._sensorStatus.sensorStatusItem != null
			&& this._sensorStatus.sensorStatusItem.rainWatchData != null) {
			return this._sensorStatus.sensorStatusItem.rainWatchData;
		}

		// No SignalR-based sensor rain watch data. Return the initial data we got from the database, if any.
		return this.rainWatchInitialData;
	}

	private _initialRainWatchData: RainWatchData = null;

	get rainWatchInitialData(): RainWatchData {
		// If we already have calculated the information, return that.
		if (this._initialRainWatchData != null) {
			return this._initialRainWatchData;
		}
		// If not calculated yet, but if we have it, create, cache and return new RainWatchData.
		if (this.pauseTotalInches != null
			|| this.shutdownTotalInches != null
			|| this.incrementalTotalInches != null
			|| this.shutdownTimeUTC != null
			|| this.pauseTimeUTC != null
			|| this.windowStartTimeUTC != null) {
				this._initialRainWatchData = new RainWatchData();
				const nowUtc = moment.utc();
				this._initialRainWatchData.rainWatchIncrementalRainAccumulated = this.incrementalTotalInches;
				if (this.windowStartTimeUTC != null) {
					this._initialRainWatchData.rainWatchTimeSinceLastRain = moment.duration(nowUtc.diff(moment(this.windowStartTimeUTC)));
				}
				this._initialRainWatchData.rainWatchPauseRainAccumulated = this.pauseTotalInches;
				if (this.pauseTimeUTC != null) {
					this._initialRainWatchData.rainWatchTimeSincePause = moment.duration(nowUtc.diff(moment(this.pauseTimeUTC)));
				}
				this._initialRainWatchData.rainWatchShutDownRainAccumulated = this.shutdownTotalInches;
				if (this.shutdownTimeUTC != null) {
					this._initialRainWatchData.rainWatchTimeSinceShutDown = moment.duration(nowUtc.diff(moment(this.shutdownTimeUTC)));
				}
				return this._initialRainWatchData;
		}
		return null;
	}
}

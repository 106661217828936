<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-alert-mobile-cell-container pt2 pb2">
	<div class="flex justify-between">
		<div class ="f7-m w-50-m pr3">{{scheduledActivity?.name}} </div>
		<div class ="f7-m w-50-m ellipsis-text"> {{scheduledActivity?.satelliteName}} </div>
	</div>
	<div class="flex fw-w">
		<div class ="f7-m w-50-m pr1 label">{{(isGolfSite ? 'STRINGS.PROGRAM_STARTS_GOLF' : 'STRINGS.PROGRAM_STARTS')| translate}}: </div>
		<div class ="f7-m w-50-m ellipsis-text mnw-mxc"> {{getDateTimeString(scheduledActivity?.startTime)}} </div>
	</div>
	<div class="flex">
		<div class ="f7-m w-50-m pr1 label">{{'STRINGS.ESTIMATED_START_TIME' | translate}}: </div>
		<div class ="f7-m w-50-m ellipsis-text"> {{getDateTimeString(scheduledActivity?.estimatedStartTime)}} </div>
	</div>
	<div class="flex">
		<div class ="f7-m w-50-m pr1 label">{{'STRINGS.ESTIMATED_END_TIME' | translate}}: </div>
		<div class ="f7-m w-50-m ellipsis-text"> {{getDateTimeString(scheduledActivity?.estimatedEndTime)}} </div>
	</div>
</div>

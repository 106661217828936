import { MapInfoLeaflet } from '../../../common/models/map-info-leaflet.model';

export class RasterItem {
	id: number;
	siteId: number;
	companyId?: number;
	sourceName: string;
	corners: string | { lat: number; lng: number }[];

	/**
	 * Image opacity percentage.
	 *
	 * When applying on a layer remember to divide by 100 to make the value between 0 and 1
	 */
	opacity: number;
	imageUrl: string;
	visible: boolean;
	/** Index of KMZ item relative to the rest of the layers */
	index: number;

	/**
	 * Reference to the leaflet object generated through the DistortableImage library
	 *
	 * @Note Do not send this property to the backend
	 */
	imageLayer: any;

	/**
	 * Property that keeps track of whether this image is being edited or not
	 *
	 * @Note Do not send this property to the backend
	 */
	editing: boolean;

	/**
	 * Property that keeps track of whether this image failed to load
	 *
	 * @Note Do not send this property to the backend
	 */
	error: string;

	/**
	 * The type of this object. Used to identify if an object is a raster item or a kmz item
	 *
	 * @Note Do not send this property to the backend
	 */
	readonly type: string = 'rasteritem';

	/**
	 * The map to which this raster item belongs
	 *
	 * @Note Do not send this property to the backend
	 */
	private mapInfo: MapInfoLeaflet;

	constructor(json: any = null, mapInfo?: MapInfoLeaflet) {
		if (json) {
			Object.assign(this, json);
		}

		if (mapInfo) {
			this.mapInfo = mapInfo;
		}
	}

	/**
	 * Removes the layer from the leaflet array in the MapInfo object this layer belongs to
	 */
	removeFromMap() {
		this.mapInfo.removeLayerFromMap(this.imageLayer);
	}

	/**
	 * Show or hide this raster item. This setting is persisted to the database and also alters the active status of the CLE toggle of this layer.
	 *
	 * @param visible Whether this raster item is visible or not
	 */
	setVisibility(visible: boolean) {
		this.mapInfo.prefs.sitePreferences.rasterItems[this.id] = visible;
		this.mapInfo.prefs.save();
		this.visible = visible;
	}

}


export class ReportCsvInfo {

	private csvString = '';

	constructor() {
	}

	get text(): string { return this.csvString; }

	setHeadersIgnoringNulls(headers: string[]) {
		this.addOutputIgnoringNulls(headers);
	}

	setMultilineHeadersIgnoringNulls(headers: string[]) {
		this.addMultilineOutputIgnoringNulls(headers);
	}

	addLineIgnoringNulls(columnValues: string[]) {
		this.addOutputIgnoringNulls(columnValues);
	}

	private addOutputIgnoringNulls(outputValues: string[]) {
		outputValues.forEach((outputValue, index) => {
			if (outputValue == null) return;
			if (index > 0) this.csvString += ',';
			this.csvString += `"${outputValue}"`;
		});
		this.csvString += '\r\n';
	}

	private addMultilineOutputIgnoringNulls(outputValues: string[]) {
		outputValues.forEach((outputValue, index) => {
			if (outputValue == null) return;
			if (index > 0) this.csvString += '\r\n';
			this.csvString += `"${outputValue}"`;
		});
		this.csvString += '\r\n';
	}

	// Since the input string has special character such as ™ (e.g: IQNet™ Client) in data content CSV file.
	// Then we will have an issue throw exception: "The string to be encoded contains characters outside of the latin1 range"
	// So we will fix issue by Solution #1 – escaping the string before encoding it regarding link
	// https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
	get base64String(): string {
		return window.btoa(unescape(encodeURIComponent(this.csvString)));
	}
}

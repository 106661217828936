import { RbUtils } from '../../../common/utils/_rb.utils';

export class TwoWireData {

	constructor(json: any = null, controllerId: number, changeDateTime: string) {
		if (json) {
			Object.assign(this, json);
		}

		this.changeDateTime = RbUtils.Conversion.convertStringToDate(changeDateTime);
		this.controllerId = controllerId;
	}

	controllerId: number;
	changeDateTime: Date;
	beforeCurrent: number;
	dLevel: number;
	dataValid: number;
	decoderAddress: number;
	decoderCoolingFinTemp: number;
	decoderIndex: number;
	holdCurrent: number;
	inrushCurrent: number;
	isCurrentLimited: boolean;
	isOverloadDetected: boolean;
	isPSUTemperatureLimited: boolean;
	lineSurveyCurrentThreshold: number;
	milliampsA: number;
	milliampsB: number;
	pingRequestType: string;
	pingStatus: string;
	senCtl: number;
	voltage1A: number;
	voltage1B: number;
	voltage2A: number;
	voltage2B: number;
}

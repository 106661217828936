<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="drm-cell-container">

	<div class="drmc-pane">
		<div class="drmc-labels">
			<div>{{'STRINGS.TYPE' | translate}}:</div>
			<div>{{'STRINGS.NAME' | translate}}:</div>
		</div>
		<div class="drmc-values">
			<div class="ellipsis-text">{{activeFlowListItem?.type}}</div>
			<div class="ellipsis-text">{{activeFlowListItem?.name}}</div>
		</div>
	</div>

	<div class="drmc-pane">
		<div class="drmc-labels">
			<div>{{'STRINGS.STATUS' | translate}}:</div>
<!--			<div>{{'STRINGS.START_TIME' | translate}}:</div>-->
			<div>
				{{'STRINGS.RUNTIME' | translate}}:
			</div>
		</div>
		<div class="drmc-values">
			<div class="ellipsis-text">{{activeFlowListItem?.status}}</div>
<!--			<div class="ellipsis-text">{{startTime}}</div>-->
			<div class="ellipsis-text">
				{{activeFlowListItem?.runtimeRemaining || '-'}}
			</div>
		</div>
	</div>

</div>


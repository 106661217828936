import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ProgramManagerService } from '../../../api/programs/program-manager.service';
import { StationManagerService } from '../../../api/stations/station-manager.service';
import { take } from 'rxjs/operators';

@Component({
	selector: 'rb-start-controller-modal',
	templateUrl: './start-controller-modal.component.html',
	styleUrls: [ './start-controller-modal.component.scss' ]
})
export class StartControllerModalComponent implements OnInit {

	@Input() controllerId: number;
	@Output() complete = new EventEmitter<{ programIds: number[], stationIds: number[] }>();

	programs: any[] = []; // really ProgramListItem with an extra "selected" property
	selectStations = true;
	stations: any[] = []; // really StationListItem with an extra "selected" property

	constructor(private dialog: MatDialog,
				private programManager: ProgramManagerService,
				private stationManager: StationManagerService) { }

	ngOnInit() {
		this.loadData();
	}

	get isItemSelected(): boolean {
		return (this.selectStations && this.stations.some(s => s.selected)) || (!this.selectStations && this.programs.some(p => p.selected));
	}

	private loadData() {
		const sources: Observable<any>[] = [
			this.programManager.getProgramsList().pipe(take(1)),
			this.stationManager.getStationsList(this.controllerId, false)
		];

		forkJoin(sources).subscribe(([ programs, stations ]) => {
			programs.forEach(p => p.selected = false);
			stations.forEach(p => p.selected = false);
			this.programs = programs.sort((a, b) => a.name.localeCompare(b.name));
			this.stations = stations.sort((a, b) => a.name.localeCompare(b.name));
		});
	}

	onSave() {
		this.dialog.closeAll();
		this.complete.emit({
			stationIds: this.selectStations ? this.stations.filter(s => s.selected).map(s => s.id) : null,
			programIds: this.selectStations ? null : this.programs.filter(p => p.selected).map(p => p.id)
		});
	}

	handleProgramRadioSelected() {
		this.programs.forEach(p => p.selected = false);
		this.stations.forEach(p => p.selected = false);
		this.selectStations = false;
	}

	handleStationRadioSelected() {
		this.programs.forEach(p => p.selected = false);
		this.stations.forEach(p => p.selected = false);
		this.selectStations = true;
	}

}

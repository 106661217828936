<div *ngIf="data.testType === RbEnums.Common.StationDiagnosticRunType.VoltageCheck; else statusCell " class="station-status-cell"
	[class.ok]="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK"
	[class.no-fb]="data.result === RbEnums.Common.DiagnosticFeedbackResult.NO_FB"
	[class.error]="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error">
	<span id="voltage-pass-icon" *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK" class="rb-status-icon icon-voltage_green" [matTooltip]="'STRINGS.VOLTAGE_TEST_PASS' | translate"></span>
	<span id="voltage-failed-icon" *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error" class="rb-status-icon icon-voltage_red" [matTooltip]="'STRINGS.VOLTAGE_TEST_FAILED' | translate"></span>
	<span id="voltage-noFeedback-icon" *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.NO_FB" class="rb-status-icon icon-help" [matTooltip]="'STRINGS.NO_FEEDBACK_COLUMN' | translate"></span>
</div>

<ng-template #statusCell>
	<div class="station-status-cell"
		[class.ok]="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK"
		[class.no-fb]="data.result === RbEnums.Common.DiagnosticFeedbackResult.NO_FB"
		[class.error]="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error">
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK" class="rb-status-icon icon-confirmation"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error" class="rb-status-icon icon-warning"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.NO_FB" class="rb-status-icon icon-help"></span>
	</div>
</ng-template>
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DropDownOption } from '../../../dashboard/components/widgets/dashboard-widget-toolbar/models/dropdown-option.model';

@Component({
	selector: 'rb-dashboard-widget-container',
	templateUrl: './dashboard-widget-container.component.html',
	styleUrls: [ './dashboard-widget-container.component.scss' ]
})
export class DashboardWidgetContainerComponent implements OnInit {

	@Input() title: string;
	@Input() subTitle: string;
	@Input() summaryValue: string;
	@Input() titleIcon: string | TemplateRef<any>;
	@Input() toolbar: TemplateRef<any>;
	@Input() busy = false;
	@Input() widgetId: number;
	@Input() withRefresh = false;
	@Input() withSettings = false;
	@Input() showSettings: boolean;
	@Input() hideRemoveWidget = false;
	@Input() theme = '';
	@Input() customDropDownOptions: DropDownOption[];
	@Input() wrapTitleLanguages: string[];
	// RB-14242: If sent from a widget component,it will be passed to widget toolbar and title will be wrapped in languages included.Example:['en', 'pt', 'sp']

	@Output() removeWidget = new EventEmitter();
	@Output() widgetRefresh = new EventEmitter();
	@Output() widgetSettings = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}

	onWidgetRefresh() {
		this.widgetRefresh.emit();
	}

	onWidgetSettings() {
		this.widgetSettings.emit();
	}
}

import { ApiService, HttpMethod } from '../_common/api.service';

import { Adjustment } from './models/adjustment.model';
import { AllProgramsProgramStepList } from './models/all-programs-program-step-list.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProgramStep } from '../programs/models/program-step.model';
import { ProgramStepListItem } from './models/program-step-list-item.model';
import { RbUtils } from '../../common/utils/_rb.utils';

@Injectable({
	providedIn: 'root'
})
export class ProgramStepApiService extends ApiService {
	// =========================================================================================================================================================
	// API Calls - Public
	// =========================================================================================================================================================

	createProgramSteps(programId: number, apiData: any[], isGolf: boolean) {
		return this.apiRequest<any>(this.createProgramStepsUrl, HttpMethod.Post, apiData);
	}

	deleteProgramSteps(programStepIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.deleteProgramStepsUrl, HttpMethod.Delete, programStepIds);
	}

	getProgramStepsList(programId: number): Observable<ProgramStepListItem[]> {
		return this.apiRequest<any>(this.getProgramStepsUrl(programId), HttpMethod.Get)
			.pipe(map(programSteps => programSteps.map(p => new ProgramStepListItem(p))));
	}

	getProgramsAssignedAndRunTimeBySatelliteId(controllerId: number, bypassCache = false): Observable<ProgramStepListItem[]> {
		return this.apiRequest<any>(this.getProgramsAssignedAndRunTimeBySatelliteUrl(controllerId), HttpMethod.Get, null, null, bypassCache);
	}

	getRunStationStatusForAllPrograms(): Observable<AllProgramsProgramStepList[]> {
		return this.apiRequest<any>(this.getRunStationStatusForAllProgramsUrl, HttpMethod.Get)
			.pipe(map(result => result.map(item => new AllProgramsProgramStepList(item))));
	}

	getRunStationStatusForSatellite(satelliteId: number = null): Observable<AllProgramsProgramStepList[]> {
		return this.apiRequest<any>(this.getRunStationStatusForSatelliteUrl(satelliteId), HttpMethod.Get)
			.pipe(map(result => result.map(item => new AllProgramsProgramStepList(item))));
	}

	getProgramStep(id: number): Observable<ProgramStep> {
		return this.apiRequest<any>(this.getProgramStepUrl(id), HttpMethod.Get)
			.pipe(map(json => new ProgramStep(json)));
	}

	getProgramStepRunTimeAdjustments(id: number, startTime: Date): Observable<Adjustment[]> {
		return this.apiRequest<any>(this.getProgramStepRunTimeAdjustmentsUrl(id, startTime), HttpMethod.Get)
			.pipe(map(adjustments => new adjustments.map(p => new Adjustment(p))));
	}

	updateProgramSteps(programStepIds: Array<number>, updateData: any) {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch,
			Object.assign({ids: programStepIds, extData: this.patchTransform(updateData)}));
	}

	// =========================================================================================================================================================
	// URLs - Private
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}ProgramStep`; }

	private get createProgramStepsUrl(): string { return `${this.baseUrl}/CreateProgramSteps`; }

	private get deleteProgramStepsUrl(): string { return `${this.baseUrl}/DeleteProgramSteps`; }

	private getProgramStepUrl(id: number): string { return `${this.baseUrl}/GetProgramStepById?programStepId=${id}`; }

	private getProgramStepsUrl(id: number): string { return `${this.baseUrl}/GetRunStationStatus?programId=${id}`; }

	private get getRunStationStatusForAllProgramsUrl(): string { return `${this.baseUrl}/GetRunStationStatusForAllPrograms`; }

	private getRunStationStatusForSatelliteUrl(satelliteId: number | null = null): string { 
		return `${this.baseUrl}/GetRunStationStatusForSatellite?satelliteId=${satelliteId}`; }

	private getProgramStepRunTimeAdjustmentsUrl(id: number, startTime: Date): string {
		return `${this.baseUrl}/GetProgramStepRunTimeAdjustments?programStepId=${id}&` +
		`startTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(startTime)}&` +
		`simplifyReturnData=true`;
	}

	private getProgramsAssignedAndRunTimeBySatelliteUrl(controllerId: number) {
		return `${this.baseUrl}/GetProgramsAssignedAndRunTimeBySatelliteId?satelliteId=${controllerId}`
	}

	private get updateBatchesUrl(): string { return `${this.baseUrl}/v2/UpdateBatches`; }
}

/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Programs.isETAdjusted
 * 		 See: _rb.utils.ts
 */

import * as moment from 'moment';

import { RbConstants } from '../constants/_rb.constants';
import { RbUtils } from './_rb.utils';

export namespace XXUseRbUtilsNamespace {

	export abstract class Conversion {

		static convertHMSObjectToDuration(timespanString: string): moment.Duration | null {
			const timespanRegex = /([0-9][0-9]+:[0-9][0-9]+:[0-9][0-9]+?)/;
			// example 2018-08-10T00:00:20
			const match = timespanRegex.exec(timespanString); // 00:00:20
			return moment.duration(match[1]); // 20000
		}

		static convertHourToMS(hours: number) {
			return  hours * 60 * 60 *  1000;
		}

		static convertMinToHMSObject(minutes: number) { return moment(minutes, 'm').format('HH:mm:ss'); }

		// Conversion to "ticks"
		static convertDurationToTicks(duration: moment.Duration) { return this.convertMSToTicks(duration.asMilliseconds()); }

		static convertMSToTicks(ms: number) { return ms * 10000; }

		static convertMinToTicks(minutes: number) { return minutes * 60000 * 10000; }

		static convertDaysToTicks(days: number) { return days * 24 * 3600000 * 10000; }

		static convertTimeToTicks(time: string) {
			const [hours, minutes, seconds] = time.split(/:/);
			const totalSeconds = +hours * 3600 + +minutes * 60 + +seconds;
			const totalMs = totalSeconds * 1000;
			return this.convertMSToTicks(totalMs);
		}

		// Conversion from "ticks"
		static convertTicksToDuration(ticks: number): moment.Duration { return moment.duration(Conversion.convertTicksToMSRound(ticks)); }

		static convertTicksToMinutesRound = ticks => Math.round(Conversion.convertTicksToMSRound(ticks) / 60000);
		static convertTicksToSecondsRound = ticks => Math.round(Conversion.convertTicksToMSRound(ticks) / 1000);
		static convertTicksToMSCeil = ticks => Math.ceil(ticks / 10000);
		static convertTicksToMSRound = ticks => Math.round(ticks / 10000);

		static convertTicksToHMSObject(ticks: number): moment.Duration | null {
			return moment.duration(Conversion.convertTicksToMSCeil(ticks ? ticks : 0), 'milliseconds');
		}

		static convertTicksToHMSString(ticks: number): string {
			return moment.utc(Conversion.convertTicksToHMSObject(ticks).as('milliseconds')).format('HH:mm:ss');
		}

		// Date and Timespan conversions

		// Convert a time-span string into a duration which can be used to get hours, minutes, and seconds
		// from the object. For safety and because we are often sending DateTime over the network from the
		// API, we make sure we "filter" the date portion of any string that might arrive.
		static convertTimeSpanStringToHMSObject(timespanString: string): string | null {
			// If the timespan string actually has date info, it's likely delimited by one of three characters:
			// T (time-zone specific time), Z (Zulu, CUT time, Grenwich), or <space>. If we find those, strip
			// them off the front of the string.
			// (?:.*) match anything or nothing. Don't save to capture groups.
			// [?:TZ ] match T, Z or <space>. Don't save to capture groups.
			// ([0-9]+:[0-9]+:[0-9]?) - match digits, then colon, then digits, then colon, then digits, saving
			// the matching string in a capture group (?)
			// i ignore case during match, basically TZ.
			const timespanRegex = /([0-9][0-9]+:[0-9][0-9]+:[0-9][0-9]+?)/;
			// example 2018-08-10T00:00:20
			const match = timespanRegex.exec(timespanString); // array
			// We only save one capture group, so we should want the first capture group.
			const duration = moment.duration(match[1]); // 20000
			// Oddly, the first item in the match array is the original string. [1] is our item.
			return moment.utc(duration.as('milliseconds')).format('HH:mm:ss'); // 00:00:20
		}

		static convertDateToMilliseconds(date: Date): number {
			const dt = new Date(date);
			const year = dt.getFullYear();
			const month = dt.getMonth();
			const day = dt.getDate();
			const hour = dt.getHours();
			const min = dt.getMinutes();
			const second = dt.getSeconds();
			const ms = dt.getMilliseconds();
			return Date.UTC(year, month, day, hour, min, second, ms);
		}

		static convertDateToUTCMilliseconds(date: Date): number {
			const dt = new Date(date);
			const year = dt.getUTCFullYear();
			const month = dt.getUTCMonth();
			const day = dt.getUTCDate();
			const hour = dt.getUTCHours();
			const min = dt.getUTCMinutes();
			const second = dt.getUTCSeconds();
			const ms = dt.getUTCMilliseconds();
			return Date.UTC(year, month, day, hour, min, second, ms);
		}

		static convertTimeToMilliseconds(date: Date): number {
			const dt = new Date(date);
			const hour = dt.getHours();
			const min = dt.getMinutes();
			const second = dt.getSeconds();
			const ms = dt.getMilliseconds();
			return hour * 60 * 60 * 1000 + min * 60 * 1000 + second * 1000 + ms;
		}

		static convertStringToDate(dateString: string): Date {
			return moment(dateString).toDate();
		}

		static convertStringUTCToLocalDate(dateString: string): Date {
			return moment.utc(dateString).local().toDate();
		}

		static convertStringToTime(timeString: string): Date {
			return moment(timeString, ['H:m:s']).toDate();
		}

		/**
		 * @summary Convert a Date value into an API parameter string format, after converting it to UTC time. That is,
		 * use this utility when the date/time parameter contract calls for UTC time to be passed.
		 * @param date Date value for conversion to API-suitable string format.
		 * @returns string containing date/time in YYYY-MM-DDTHH:mm:ss format and adjusted to UTC.
		 */
		static convertDateToUTCDateTimeParameterString(date: Date): string {
			return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
		}

		static convertDateToUTCDateTimeOffsetParameterString(date: Date): string {
			return moment.utc(date).format('YYYY-MM-DDTHH:mm:ssZ');
		}

		static convertDateToDateTimeParameterString(date: Date): string {
			return moment(date).format('YYYY-MM-DDTHH:mm:ss');
		}

		static convertDateToDateTimeParameterStringWithMs(date: Date): string {
			return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
		}

		// NOTE: This only works properly if duration is less than 1 day.
		static convertDurationToTimeSpanString(duration: moment.Duration): string {
			return moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
		}

		static convertTimeSpanStringToDuration(timespanString: string): moment.Duration | null {
			return timespanString ? moment.duration(timespanString) : moment.duration('0');
		}

		static convertTimeStringToDate(timeString: string): Date {
			timeString = timeString.toLowerCase();
			const m = moment(timeString, 'HH:mm:ss');
			const duration = moment.duration({ hours: m.hours(), minutes: m.minutes() });
			return RbUtils.Common.currentDateWithMeridiemTime(duration, RbConstants.Form.MERIDIEM_OPTIONS[timeString.includes('am') ? 0 : 1]);
		}

		static convertStringToDecimal(value: string): number {
			const floatValue = parseFloat(value.replace(new RegExp(',', 'g'), ''));
			return isNaN(floatValue) ? 0 : floatValue;
		}

		/**
		 * @param number the int number we want to convert to Hex string it could either decimal or hexadecimal number eg: 0x300000
		 * @returns Hexadecimal converted to string 6 digits
		 */
		static convertNumberToHexString(number: number): string {
			return ('00000000' + number.toString(16)).slice(-6).toUpperCase();
		}

		/**
		 * @param hexString the hex string we want to convert to number.
		 * @returns decimal number, if fails to convert returns -1
		 */
		static convertHexStringToDecimal(hexString: string): number {
			const floatValue = parseInt(hexString, 16);
			return isNaN(floatValue) ? -1 : floatValue;
		}

		static convertTimeZoneToUTCOffset(timezone: string): string  {
			const offsetPart =  timezone.match(/\(([^)]+)\)/)[1];
			const utcOffset = `${offsetPart.substring(0, 3)} ${offsetPart.substring(3)}`;
			return utcOffset;
		}
	}
}

import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Area } from '../../../api/areas/models/area.model';
import { AreaManagerService } from '../../../api/areas/area-manager.service';
import { ControllerListItem } from '../../../api/controllers/models/controller-list-item.model';
import { ControllerManagerService } from '../../../api/controllers/controller-manager.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { FrontBackDropdownComponent } from '../../../core/components/front-back-dropdown/front-back-dropdown.component';
import { MobileDiagnosticsFilterComponent } from './sub-components/mobile-diagnostics-filter/mobile-diagnostics-filter.component';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { SelectHolesDropdownComponent } from '../../../core/components/select-holes-dropdown/select-holes-dropdown.component';
import { Site } from '../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { Station } from '../../../api/stations/models/station.model';
import { StationManagerService } from '../../../api/stations/station-manager.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'rb-diagnostics-filter',
	templateUrl: './diagnostics-filter.component.html',
	styleUrls: ['./diagnostics-filter.component.scss'],
})
export class DiagnosticsFilterComponent implements OnInit, OnDestroy, AfterViewInit {

	@HostBinding('class') class = 'rb-main-panel-toolbar diagnostics-filter';
	@ViewChild('holesDropdown') holesDropdownComponent: SelectHolesDropdownComponent;
	@ViewChild('areasDropdown') areasDropdownComponent: FrontBackDropdownComponent;

	/**
	 * RB-14723: There is a race condition between building of the MobileDiagnosticsFilterComponent (when in mobile sizing),
	 * and the call getting the holes or areas drop-down list reference, which uses mobileFilter to retrieve that item.
	 */
	@ViewChild('mobileFilter') mobileFilter: MobileDiagnosticsFilterComponent;

	@Output() cancelButtonClicked = new EventEmitter();
	@Output() runButtonClicked = new EventEmitter();
	@Input() selectedDiagnosticTypeId = RbEnums.Common.DiagnosticType.Course; // default type Course
	@Input() isDecoderDiagnostics: boolean;
	areas: Area[] = [];
	course: Site;
	courses: Site[] = [];
	courseAreas: Area[] = [];
	diagnosticsTypes: { name: string, id: RbEnums.Common.DiagnosticType, disableFor: RbEnums.Common.DiagnosticTestType[] }[] = [];
	holesAndPlaces: Area[] = [];
	interfaces: ControllerListItem[] = [];
	isMobile = false;
	loadError: string;
	selectedAllInterfaces = false;
	selectedAllWirePaths = false;
	selectedCourseId: number;
	selectedInterfaceId: number;
	selectedTestTypeId: number;
	selectedWirePathIds: number[] = [];
	stations: Station[] = [];
	testTypes: { name: string, id: RbEnums.Common.DiagnosticTestType }[] = [];
	wirePaths: any[] = [ { id: -1, name: this.translate.instant('STRINGS.SELECT_ALL') },
		{ id: 1, name: '1' }, { id: 2, name: '2' }, { id: 3, name: '3' }, { id: 4, name: '4' } ];

	/**
	 * @summary Set to true to mark the filter 'busy', meaning it's not yet ready to run the selected test
	 * on the selected areas/stations/interfaces/system. Set to false when ready. A reference count is used
	 * to return isBusy = false only when no one thinks it should be busy.
	 */
	public get isBusy(): boolean {
		return this.isBusyRefCount > 0;
	}
	public set isBusy(busy: boolean) {
		if (busy) {
			this.isBusyRefCount++;
		} else if (this.isBusyRefCount > 0) {
			this.isBusyRefCount--;
		}
	}

	/**
	 * Return available DiagnosticTypes (Course, Area, Wirepath), given the currently-selected DiagnosticTestType (Status, 
	 * Voltage, QuickCheck) and the general list of types available. Some DiagnosticTypes are not sensible for certain
	 * DiagnosticTestTypes.
	 */
	public get availableDiagnosticTypes(): { name: string, id: RbEnums.Common.DiagnosticType, disableFor: RbEnums.Common.DiagnosticTestType[] }[] {
		// RB-9289: Remove any diagnostic types (All, Course, etc.) where that type is non-sensical for corresponding
		// test type. For example, Course-wide QuickCheck is not reasonable!
		const avail = this.diagnosticsTypes.filter(dt => !dt.disableFor.some( dis => dis == this.selectedTestTypeId ));

		// If the currently-selected diagnostic type is not in the available list, pick the first list item setting
		// selectedDiagnosticTypeId.
		if (!avail.some(a => a.id === this.selectedDiagnosticTypeId)) {
			setTimeout(() => {
				// Set the selectedDiagnosticTypeId to an available value in the list.
				this.selectedDiagnosticTypeId = avail[0].id;
			})
		}
		return avail;
	}

	/**
	 * @summary Private ref count for tracking busy state. This allows us to set busy to true several
	 * times and keep track of how many "busies" are pending to decide what value to return from isBusy.
	 */
	private isBusyRefCount = 0;

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(private areaManager: AreaManagerService,
				private controllerManager: ControllerManagerService,
				private deviceManager: DeviceManagerService,
				private siteManager: SiteManagerService,
				private stationManager: StationManagerService,
				private translate: TranslateService) { }

	ngOnInit(): void {
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe(isMobile => this.isMobile = isMobile);

		this.isMobile = this.deviceManager.isMobile;

		// RB-14723: Do NOT loadCourses() here, as that generates a race condition with completion of one of our
		// subcomponents, the mobile diagnostics filter component. We should not loadCourses() until after our 
		// view is completely loaded; see ngAfterViewInit().

		// RB-14504: Set the appropriate tests for decoder diagnostics only.
		if (this.isDecoderDiagnostics) {
			this.testTypes = [
				{ name: this.translate.instant('STRINGS.ON_OFF_TEST'), id: RbEnums.Common.DiagnosticTestType.QuickCheck },
			];

			this.diagnosticsTypes = [
				{
					name: this.translate.instant('STRINGS.BY_HOLE_AREA'),
					id: RbEnums.Common.DiagnosticType.Area,
					disableFor: []
				},
			].sort((a, b) => b.id - a.id); // Sort in descending order by id.

			this.selectedTestTypeId = this.testTypes[0].id;
			return;
		}

		this.testTypes = [
			{ name: this.translate.instant('STRINGS.ICM_STATUS'), id: RbEnums.Common.DiagnosticTestType.StatusPoll },
			{ name: this.translate.instant('STRINGS.ICM_VOLTAGE'), id: RbEnums.Common.DiagnosticTestType.VoltageTest },
			{ name: this.translate.instant('STRINGS.QUICK_CHECK'), id: RbEnums.Common.DiagnosticTestType.QuickCheck },
		];
		this.diagnosticsTypes = [
			{
				name: this.translate.instant('STRINGS.WIREPATH'),
				id: RbEnums.Common.DiagnosticType.Address,
				disableFor: [ RbEnums.Common.DiagnosticTestType.QuickCheck ]	// RB-9289: No full wire path QuickCheck
			},
			{ 
				name: this.translate.instant('STRINGS.BY_HOLE_AREA'),
				id: RbEnums.Common.DiagnosticType.Area,
				disableFor: []
			},
			{ 
				name: this.translate.instant('STRINGS.SYSTEM'),
				id: RbEnums.Common.DiagnosticType.Course,
				disableFor: [ RbEnums.Common.DiagnosticTestType.QuickCheck ]	// RB-9289: No system QuickCheck
			},
		].sort((a, b) =>  b.id - a.id); // Sort in descending order by id.

		this.selectedTestTypeId = this.testTypes[0].id;
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	ngAfterViewInit() {
		// RB-14723: Because the subscription here assumes that the mobile diagnostics filter component is fully
		// loaded, we either have to do something custom assuring that, or not get the courses until our subcomponents
		// are all loaded (here). This may slightly delay display of the completed component but it avoids a race
		// condition which can intermittently cause a toast message because the subcomponent isn't ready yet.
		setTimeout(() => {
			this.loadCourses();
		}, 250);
	}

	/**
	 * runButtonEnabled should return true if we are ready to run the test selected. There are a couple of things to
	 * worry about:
	 * - If we're running by Wire Path, we need an interface and at least one wire path selected
	 * - If we're running by Areas, we need at least one hole and one area selected
	 * - RB-9691: In order to properly handle the diagnostic results, we have to have the needed station data for
	 *   the selected items. This is a timing thing. If we're busy, we're likely retrieving the course data and,
	 *   until that's done, we are NOT READY TO HANDLE THE DIAGNOSTIC RESULTS!
	 */
	get runButtonEnabled(): boolean {

		switch (this.selectedDiagnosticTypeId) {
			case RbEnums.Common.DiagnosticType.Address:
				return this.selectedWirePathIds.length > 0 && this.selectedInterfaceId != null && !this.isBusy;

			case RbEnums.Common.DiagnosticType.Area:
				return ((this.holesDropdown != null && this.holesDropdown.selectedHoles.length > 0) ||
					(this.areasDropdown != null && this.areasDropdown.selectedItems.length > 0))
					&& !this.isBusy;

			case RbEnums.Common.DiagnosticType.Course:
				return !this.isBusy;

			default:
				return false;
		}
	}

	/**
	 * cancelButtonEnables should return true. We don't know what someone else is doing with diagnostics, necessarily,
	 * but we want to be able to cancel her QuickCheck if we need to!
	 */
	get cancelButtonEnabled(): boolean {
		return true;
	}

	get holesDropdown(): SelectHolesDropdownComponent {
		return this.isMobile ? this.mobileFilter.holesDropdown : this.holesDropdownComponent;
	}

	get areasDropdown(): FrontBackDropdownComponent {
		return this.isMobile ? this.mobileFilter.areasDropdown : this.areasDropdownComponent;
	}

	get selectedHolesAndAreasIds(): number[] {
		if (this.holesDropdown == null || this.areasDropdown == null) return [];
		return this.holesDropdown.selectedHoles.map(h => h.id).concat(this.areasDropdown.selectedItems.map(a => a.id));
	}

	get selectedStations(): Station[] {
		if (this.holesDropdown == null || this.areasDropdown == null) return this.stations;
		return this.stations.filter(station => {
			// Make sure that, when we get the stations, we also get the stationArea values so the code below works.
			const stationAreas = station.stationArea.map(sa => this.areas.find(a => a.id === sa.areaId));
			const hole = stationAreas.find(sa => sa.level === 2 && sa.isExclusive);
			const area = stationAreas.find(sa => sa.level === 3 && sa.isExclusive);

			return (this.holesDropdown.selectedHoles.length === 0 || this.holesDropdown.selectedHoles.some(i => i.id === hole.id)) &&
				(this.areasDropdown.selectedItems.length === 0 || this.areasDropdown.selectedItems.some(i => i.id === area.id));
		});
	}
	// =========================================================================================================================================================
	// Data Loading
	// =========================================================================================================================================================

	private loadCourses() {
		this.isBusy = true;
		this.siteManager.getSites(true).pipe(take(1)).subscribe(courses => {
			this.courses = courses || [];
			if (this.ShouldSelectFirstCourse()) {
				this.SelectFirstCourse();
			}
			this.isBusy = false;
		}, error => {
			this.loadError = error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
		});
	}

	private ShouldSelectFirstCourse(): boolean {
		return this.courses.length > 0;
	}

	private SelectFirstCourse(): void {
		this.selectedCourseId = this.courses[0].id;
		this.onCourseSelected();
	}

	/**
	 * Retrieve all Areas in the current company from CoreApi. Note this includes holes, areas, tags, etc.
	 * @returns Observable<Area[]> containing all Area instances in the company.
	 */
	private loadAreas(): Observable<Area[]> {
		// RB-10096: We need to get *all* the areas and we have to be sure to bypass the cache. Otherwise, visiting the
		// Course | Area | Greens screen will give the wrong answer for the list of areas (because a different set of
		// areas were recently requested from CoreApi), causing weird results in the diagnostic screen.
		return (this.areas.length > 0) ?
			of(this.areas) :
			this.areaManager.getAllAreas(/* includeSubAreas */ true, /* bypasssCache */ true).pipe(take(1));
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onRunButtonClicked() {
		this.getSelectedInterfaceStations();
		this.runButtonClicked.emit();
	}

	onCancelButtonClicked() {
		this.cancelButtonClicked.emit();
	}

	onActionErrorAcknowledged() {
		this.loadError = null;
		this.isBusy = false;
	}

	onCourseSelected() {
		this.isBusy = true;
		this.course = this.courses.find(c => c.id === this.selectedCourseId);
		this.onDiagnosticTypeSelected();
		if (this.holesDropdown != null) this.holesDropdown.selectedHoles = [];
		if (this.areasDropdown != null) this.areasDropdown.selectedItems = [];

		// We do not want to get all the stations on the course using getStationsByAreasAndSites; that will limit
		// the list to stations on satellites on the indicated site(s), not what we want. Get all the stations
		// on all the courses and use their area tags to limit them to the selected course, instead.
		forkJoin([
			// We assume golf-only: this.stationManager.getStationsByAreasAndSites(null, [ this.selectedCourseId ]).pipe(take(1)),
			// We need to get the station.StationArea.Area data so we can look up the station areas for a given
			// station when the user selects filtering by hole or area.
			this.stationManager.getAllStations({ includeStationAreas: true }).pipe(take(1)),
			this.loadAreas(),
			this.controllerManager.getControllersList(true).pipe(take(1)),
			this.areaManager.getHoles(this.selectedCourseId).pipe(take(1)),
		]).subscribe(([stations, areas, interfaces, holes]) => {
			this.stations = stations;
			this.areas = areas;	// This has to be a cache issue. We're getting different lists of areas depending on what else you do.
			this.courseAreas = areas.filter(area => area.siteId === this.selectedCourseId && area.level === 3).sort((a, b) => a.number - b.number);
			this.interfaces = interfaces;

			// RB-16698: We don't simply limit the diagnostics to golf areas! Other Areas are also reasonable diagnostic targets.
			this.holesAndPlaces = holes.filter(h => h.level === 2).sort((a, b) => a.number - b.number);

			this.isBusy = false;
		}, error => {
			this.loadError = error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
		});
	}

	onDiagnosticTypeSelected() {
		this.selectedAllInterfaces = false;
		this.selectedInterfaceId = null;
		this.selectedAllWirePaths = false;
		this.selectedWirePathIds = [];
	}

	onWirePathSelected() {
		const selectedAllItem = this.selectedWirePathIds.some(id => id === -1);
		const selectedAllChanged = selectedAllItem !== this.selectedAllWirePaths;
		if (selectedAllChanged) {
			this.selectedWirePathIds = selectedAllItem ? this.wirePaths.map(i => i.id) : []; // Select all or none
		} else {
			const numSelected = this.selectedWirePathIds.filter(id => id !== -1).length;
			if (numSelected === this.wirePaths.length - 1) {
				this.selectedWirePathIds = this.wirePaths.map(i => i.id); // Select "all"
			} else {
				this.selectedWirePathIds = this.selectedWirePathIds.filter(id => id !== -1); // Deselect "all"
			}
		}
		this.selectedAllWirePaths = this.selectedWirePathIds.some(id => id === -1);
	}

	private getSelectedInterfaceStations() {
		if (this.selectedInterfaceId) {
			const siteId = this.interfaces.find(i => i.id === this.selectedInterfaceId).siteId;
			this.stationManager.getStationsByAreasAndSites(null, [ siteId ]).pipe(take(1))
			.subscribe((stations: Station[]) => this.stations = stations);
		}
	}
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { CCWeatherDataService } from '../../../api/ccweather/ccweather-data.service';
import { GwpSidebarWidgetSettingsModel } from '../dashboard-widget-container/models/gwp-sidebar-widget-settings.model';
import { InternetService } from '../../../api/internet/internet.service';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbGridsterWidget } from '../../../dashboard/components/widgets/rb-gridster-widget';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Site } from '../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { take } from 'rxjs/operators';
import { UiSettingsService } from '../../../api/ui-settings/ui-settings.service';

import UserSettingsKey = RbEnums.Common.UserSettingsKey;

@UntilDestroy()
@Component({
	selector: 'rb-dashboard-ccweather-widget',
	templateUrl: './dashboard-ccweather-widget.component.html',
	styleUrls: ['./dashboard-ccweather-widget.component.scss']
})
export class DashboardCCWeatherWidgetComponent implements OnInit, OnDestroy {

	@Input() siteId: number;
	@Input() isIq4SidebarWidget = false;
	@Input() theme = '';

	@Input() set associatedWidget(widget: RbGridsterWidget) {
		this._associatedWidget = widget;
		if (widget && !this._associatedWidget.initialized) {
			this.onWidgetSettings();
		}
	}

	get associatedWidget(): RbGridsterWidget {
		return this._associatedWidget;
	}

	@Output() settingsChanged = new EventEmitter();

	callRefresh = new EventEmitter();
	showSettings: boolean;
	title: string;

	get subTitle(): string {
		// The subtitle is the course name selected or, if showing settings, the translated version of 'Settings'.
		if (this.showSettings || !this.associatedWidget || this.associatedWidget.siteId == null) {
			return RbUtils.Translate.instant('STRINGS.SETTINGS');
		}

		// IQ4 Sidebar Widget
		if (this.siteManager.isGolfSite && !this.isIq4SidebarWidget) {
			const currentSite = this.sites ? this.sites.find(site => site.id === this.associatedWidget.siteId) : null;
			return currentSite ? currentSite.name : this.site.name;
		} else {
			// IQ4 Sidebar Widget
			return this.associatedWidget?.gwpWeatherSource?.name || RbUtils.Translate.instant('STRINGS.SETTINGS');
		}
	}

	busy = false;
	site: Site;
	sites: Site[];
	noInternet = false;
	userId: number;
	companyId: number;
	requiredConditions: string[] = [];

	private _associatedWidget: RbGridsterWidget;

	constructor(private broadcastService: BroadcastService,
				private authManager: AuthManagerService,
				private siteManager: SiteManagerService,
				private internetService: InternetService,
				private ccWeatherDataService: CCWeatherDataService,
				private uiSettingsService: UiSettingsService
	) {
	}

	ngOnInit() {
		this.requiredConditions = ['temperature', 'pressureSurfaceLevel', 'temperatureMinMax', 'humidity', 'wind', 'precipitationIntensity', 'dewPoint', 'et0'];
		this.title = RbUtils.Translate.instant('DASHBOARD.WEATHER_FORECAST_UPPERCASE');
		this.noInternet = !this.internetService.connState;

		this.internetService.connectionChanged
			.pipe(untilDestroyed(this))
			.subscribe(() => this.noInternet = false/*!connected*/);

		this.broadcastService.selectedEntityChange
			.pipe(untilDestroyed(this))
			.subscribe((entity) => {
				if (entity instanceof Site) this.loadData();
			});

		this.internetService.testConnection();
		this.loadData();
	}

	ngOnDestroy(): void { /** Required by untilDestroyed */
	}

	private loadData() {
		this.site = this.siteManager.selectedSite;
		this.siteId = this.site == null ? null : this.site.id;

		this.siteManager.getSites()
			.pipe(take(1))
			.subscribe(sites => {
				this.sites = sites;
			});

		// Build settings from the default set, then adjust to match associated widget configuration, if any.
		// This will be called both in the uninitialized case and the fully-configured state.
		this.ccWeatherDataService.initSettings(this.associatedWidget);

		const userProfile = this.authManager.getUserProfile();
		this.userId = userProfile.userId;
		this.companyId = userProfile.companyId;
	}

	onWidgetRefresh() {
		this.callRefresh.emit();
	}

	onWidgetSettings() {
		this.showSettings = true;
	}

	onSettingsClosed(isSave) {
		this.showSettings = false;
		if (isSave) {
			this.associatedWidget[ 'initialized' ] = true;
			const settings = this.ccWeatherDataService.getSettings(this.associatedWidget.uniqueId, this.requiredConditions, true);
			const visibleConditions = [];
			settings.conditions.forEach(condition => {
				visibleConditions.push(condition.fieldName);
			});
			const visibleBlocks = [];
			settings.visibleBlocks.forEach(block => {
				if (block.show) {
					visibleBlocks.push(block.name);
				}
			});

			// IQ4 Sidebar Widget
			if (!this.isIq4SidebarWidget) {
				// Dashboard widget
				settings.conditions = visibleConditions;
				settings.visibleBlocks = visibleBlocks;
				Object.assign(this.associatedWidget, settings);
			} else {
				// IQ4 Sidebar Widget
				this.associatedWidget.visibleConditions = visibleConditions;
				this.associatedWidget.visibleBlocks = visibleBlocks;

				const settingsModel = new GwpSidebarWidgetSettingsModel(visibleBlocks, visibleConditions);
				this.uiSettingsService.setPreference(UserSettingsKey.GwpSidebarWidgetSettings, settingsModel)
					.subscribe();
			}

			this.settingsChanged.emit();
		} else {
			if (!this.associatedWidget[ 'initialized' ]) {
				this.broadcastService.removeWidget.next(this.associatedWidget.uniqueId);
			}
		}
	}
}

import { Component, Injector } from '@angular/core';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { ControllerListItem } from '../../../../../api/controllers/models/controller-list-item.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-ctrl-diff-status-cell-renderer',
	templateUrl: './controller-difference-status-cell-renderer.component.html',
	styleUrls: ['./controller-difference-status-cell-renderer.component.scss']
})
export class ControllerDifferenceStatusCellRendererComponent implements ICellRendererAngularComp {
	controllerListItem: ControllerListItem;
	authManager: AuthManagerService;
	isGolfSite = false;

	constructor(private baseInjector: Injector) {
		this.authManager = this.baseInjector.get(AuthManagerService);
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.setCellData(params);
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
	}

	refresh(params: any): boolean {
		this.setCellData(params);
		return true;
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================

	get controllerStatusCellRenderer(): string {
		return RbUtils.GridCellStyles.getControllerStatusCell(this.controllerListItem, this.isGolfSite, true);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setCellData(params: any) {
		this.controllerListItem = params.data;
	}

}

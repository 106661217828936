import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { SelectListItem } from '../../../../api/_common/models/select-list-item.model';

@Component({
	selector: 'rb-alerts-filter-dropdown',
	templateUrl: './alerts-filter-dropdown.component.html',
	styleUrls: ['./alerts-filter-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AlertsFilterDropdownComponent {
	@ViewChild('matSelect', { static: true }) matSelect: MatSelect;

	@Input() items: Array<SelectListItem>;
	@Input() label = 'Hello!';  // label contains the initial text displayed in the un-expanded dropdown (Select Filter or whatever).
	@Input() selectedItems: Array<SelectListItem>;

	// @event selectChanged emits ONCE when the user closes the selection dialog. The parameter contains the list of DropdownElement
	// objects (value: number, display: string) which are selected after the selection change.
	@Output() selectChanged = new EventEmitter();

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor() { }

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	handleChange() {
		// Update the selectedItems property to match what the control says.
		this.selectedItems = this.matSelect.value;
	}

	handleDone() {
		// Only send the selectChanged event ONCE when the user closes the selection dialog (re: RB-8558).
		this.selectChanged.emit(this.matSelect.value);	// Send the list of selected items.

		// Close the select window.
		this.matSelect.close();
	}

	// =========================================================================================================================================================
	// Public Helper Methods
	// =========================================================================================================================================================

	equals(objOne, objTwo) {
		if (objOne && objTwo) { return objOne.value === objTwo.value; }
	}

	selectAll() {
		// Update the selectedItems property to match what the control says.
		this.selectedItems = this.items;
		this.selectChanged.emit(this.selectedItems);
	}

	deselectAll() {
		// Update the selectedItems property to match what the control says.
		this.selectedItems = new Array<SelectListItem>();
		this.selectChanged.emit(this.selectedItems);
	}
}

export class DropdownElement {
	constructor(v: number, d: string) {
		this.value = v;
		this.display = d;
	}

	public value: number;
	public display: string;
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<mat-form-field>
	<mat-select #controllerSelector class="single-controller-select" [panelClass]="'single-controller-select-panel'" placeholder="{{selectedControllerName | translate}}">
		<mat-option class="single-controller-select-header" disabled>{{'STRINGS.SELECT_CONTROLLER' | translate}}</mat-option>

		<div class="single-controller-select-radio-container">
			<mat-radio-group class="rb-radio-group">
				<mat-radio-button
					*ngFor="let item of items"
					class="rb-radio-button"
					[value]="item"
					(change)="handleSelection(item)"
				>
					{{item.name}}
				</mat-radio-button>
			</mat-radio-group>
		</div>

	</mat-select>
</mat-form-field>

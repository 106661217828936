import { ScheduledDiagnostic } from './scheduled-diagnostic.model';

export class ScheduledDiagnostics {

	items: ScheduledDiagnostic[] = [];

	constructor(json: any = null) {
		if (json) {
			this.items = json.map((item: any) => new ScheduledDiagnostic(item));
		}
	}
}

<rb-dialog
  [title]="'STRINGS.STATION_DIAGNOSTIC_HEADER' | translate: { stationName: stations.length > 1 ? stationIdString : stations[0].name }"
  [hideCloseButton]="true"
  [submitButtonLabel]="'STRINGS.CLOSE' | translate"
  [showSubmitButton]="true"
  (onSubmit)="onClose()">
  <div class="station-diagnostic-container"
    [ngClass]="{ 'mobile-container': isMobile, 'not-connected': isStationNotAvailable }">
    <div *ngIf="isStationSuspended && stations.length == 1" class="diagnostic-warning">
      {{ 'STRINGS.STATION_DIAGNOSTIC_WARNING' | translate: { stationStatus: ('STRINGS.SUSPENDED' | translate) } }}
    </div>

    <ng-container *ngIf="shouldNotConnectedStationWarningShown && stations.length == 1 && !isStationSuspended">
      <div class="diagnostic-warning">
        {{ 'STRINGS.STATION_DIAGNOSTIC_WARNING' | translate: { stationStatus: ('SPECIAL_MSG.NOT_CONNECTED' | translate) } }}
      </div>
    </ng-container>
    
    <div class="complex-toolbar" [ngClass]="{ 'mobile': isMobile, 'hidden': isMobile && dataLoaded }">
      <div class="filter-container" [ngClass]="{ 'mobile': isMobile }">
        <rb-station-diagnostic-filter
          *ngIf="!isMobile"
          [testTypes]="StationDiagnosticRunTypes"
          [isSelectTestTypeDisabled]="isInProgress"
          [isStartButtonDisabled]="unableToTest"
          [testTypeId]="currentlyRunningTestType"
          (testTypeIdChange)="changeTestType($event)"
          (runButtonClicked)="runButtonClicked()"
          [cancelButtonEnabled]="isDiagnosticRunning"
          (cancelButtonClicked)="cancelButtonClicked()">
        </rb-station-diagnostic-filter>

        <rb-station-diagnostic-filter-mobile
          #mobileFilter
          *ngIf="isMobile"
          class="mobile-diagnostics-filter"
          [testTypes]="StationDiagnosticRunTypes"
          [isSelectTestTypeDisabled]="isInProgress"
          [isStartButtonDisabled]="unableToTest"
          [testTypeId]="currentlyRunningTestType"
          (testTypeIdChange)="changeTestType($event)"
          (runButtonClicked)="runButtonClicked()"
          [cancelButtonEnabled]="isDiagnosticRunning"
          (cancelButtonClicked)="cancelButtonClicked()">
        </rb-station-diagnostic-filter-mobile>
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="diagnosticsStatusbar"></ng-container>
    <div class="station-diagnostic-grid-container">
        <rb-grid-container
        #gridContainer
        *ngIf="isGridDataLoaded"
        class="diagnostics-grid"
        [isGridDisplayable]="isGridDisplayable"
        [noGridMargin]="true"
        [rowData]="diagnosticItems"
        (cellClick)="onGridCellClick($event)"
        [hideRowSelection]="true"
        [includeCheckbox]="false"
        [mobileNavigation]="false"
        [mobileRightArrow]="false"
        [mobileCellRendererComponent]="stationDiagnosticMobileCellRenderer">
        <rb-column
          [headerName]="'STRINGS.RESULT' | translate"
          field="result"
          [cellRendererFramework]="resultCellRender"
          [minWidth]="120"
          [maxWidth]="120"
          [headerClass]="'small-padding'" [cellClass]="'small-padding cellStyle'"
          filter="checkboxTableFilter" [filterParams]="resultFilterParams" [comparator]="resultComparator"></rb-column>
        <rb-column
          *ngIf="stations.length == 1 && !(currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck)"
          [headerName]="'STRINGS.DATE_SLASH_TIME' | translate"
          field="updateTime"
          [minWidth]="120"
          [maxWidth]="250"
          [headerClass]="'small-padding'" [cellClass]="'small-padding cellStyle'"
          [comparator]="dateComparator"></rb-column>
        <rb-column
          *ngIf="currentlyRunningTestType == StationDiagnosticRunType.VoltageCheck"
          [headerName]="'STRINGS.VOLTAGE' | translate"
          field="voltage"
          [minWidth]="120"
          [maxWidth]="200"
          [headerClass]="'small-padding'" [cellClass]="'small-padding cellStyle'"
          filter="agNumberColumnFilter"></rb-column>
        <rb-column
          *ngIf="stations.length > 1 || currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck"
          [headerName]="'STRINGS.LOCATION' | translate"
          field="location"
          [minWidth]="150"
          [maxWidth]="150"></rb-column>
        <rb-column
          *ngIf="stations.length > 1 || currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck"
          [headerName]="'STRINGS.INTERFACE' | translate"
          field="interface"
          [minWidth]="150"></rb-column>
        <rb-column
          *ngIf="currentlyRunningTestType === RbEnums.Common.DiagnosticTestType.StatusPoll ||
            currentlyRunningTestType === RbEnums.Common.DiagnosticTestType.VoltageTest"
          [headerName]="'STRINGS.WIREPATH' | translate"
          field="wirePath"
          [minWidth]="100"
          [maxWidth]="200"
          [headerClass]="'small-padding'" [cellClass]="'small-padding cellStyle'"
          filter="checkboxTableFilter" [filterParams]="wirepathFilterParams"></rb-column>
        <rb-column *ngIf="currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck" [headerName]="'STRINGS.RESTING_CURRENT_MA' | translate" field="atRest"
          [minWidth]="150"></rb-column>
        <rb-column *ngIf="currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck" [headerName]="'STRINGS.IN_RUSH_CURRENT_MA' | translate" field="inRush"
          [minWidth]="150"></rb-column>
        <rb-column *ngIf="currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck" [headerName]="'STRINGS.HOLDING_CURRENT_MA' | translate" field="hold"
          [minWidth]="150"></rb-column>
        <rb-column
          *ngIf="stations.length > 1 || currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck"
          [headerName]="'STRINGS.HOLE' | translate"
          field="hole"
          [minWidth]="100"
          [maxWidth]="150"
          [headerClass]="'small-padding'" [cellClass]="'small-padding cellStyle'"
          filter="agNumberColumnFilter"></rb-column>
        <rb-column
          *ngIf="stations.length > 1 || currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck"
          [headerName]="'STRINGS.AREA' | translate"
          field="area"
          [minWidth]="150"
          [maxWidth]="250"></rb-column>
        <rb-column
          *ngIf="stations.length > 1 || currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck"
          [headerName]="'STRINGS.SUB_AREA' | translate"
          field="subArea"
          [minWidth]="150"
          [maxWidth]="250"></rb-column>
        <rb-column
          *ngIf="stations.length > 1 || currentlyRunningTestType == StationDiagnosticRunType.OnOffCheck"
          [headerName]="'STRINGS.COURSE' | translate"
          field="course"
          [minWidth]="150"></rb-column>
      </rb-grid-container>
    </div>
  </div>
</rb-dialog>

<ng-template #diagnosticsStatusbar>
	<div *ngIf="dataLoaded" class="diagnostic-status-bar" [class.mobile]="isMobile">
		<div class="status-bar-left-side">
			<div class="tested">{{ 'STRINGS.TESTED' | translate }}:</div>
			<div class="value">{{ resultCount }}<span *ngIf="stations.length > 1"> / {{ totalResultCount }}</span></div>
			<div *ngIf="isInProgress" class="progress">
				<mat-progress-spinner mode="indeterminate" color="primary" diameter="30"></mat-progress-spinner>
			</div>
			<div class="pass-fail-area">
				<span *ngIf="isVoltageCheck; else notVoltageCheckPass" class="rb-status-icon icon-voltage_green pass"></span>
				<ng-template #notVoltageCheckPass>
          <span class="rb-status-icon icon-confirmation pass"></span>
        </ng-template>
				<div class="result">
					<div class="value">{{numPassed}}</div>
					<div>{{'STRINGS.PASSED' | translate}}</div>
				</div>

				<span *ngIf="shouldShowFailure()"
						class="rb-status-icon icon-voltage_red fail"></span>
				<div *ngIf="shouldShowFailure()" class="result">
					<div class="value">{{numFailed}}</div>
					<div>{{'STRINGS.FAILED' | translate}}</div>
				</div>

				<span *ngIf="!shouldShowFailure()" class="rb-status-icon icon-help no_feedback"></span>
				<div *ngIf="!shouldShowFailure()" class="result">
					<div class="value">{{numFailed}}</div>
					<div>{{'STRINGS.NO_FEEDBACK_FILTER' | translate}}</div>
				</div>
			</div>
		</div>
		<div class="status-bar-right-side">
			<div class="status-bar-last-updated">{{'STRINGS.LAST_UPDATED' | translate}}&nbsp;{{lastUpdated || translate.instant('STRINGS.UNKNOWN')}}</div>
		</div>
	</div>
</ng-template>

import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

import SensorType = RbEnums.Common.SensorType;

export class SensorListItem {
	private readonly NO_VALUE = '-';

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	addressInt: number;
	borrowedWsid: number;
	borrowState: RbEnums.Common.BorrowState;
	channel: number;
	flowSensorModel: number;
	floZoneName: string;
	floZoneId: number;
	id: number;
	latitude: number;
	longitude: number;
	model: string;
	name: string;
	ordinal: number;
	satelliteId: number;
	satelliteName: string;
	settlingTime: string;
	siteId: number;
	siteName: string;
	subChannel: string;
	type: SensorType;
	typeName: string;
	weatherSensorModel: number;
	weatherSourceId: number;
	secondsPerUpdate: number;
	automaticActions: string;
	
	get hasLocation(): boolean { return this.latitude != null && this.longitude != null; }

	get settlingTimeValue(): string {
		if (!this.settlingTime) return this.NO_VALUE;

		const value = RbUtils.Conversion.convertTimeSpanStringToHMSObject(this.settlingTime).slice(3);
		return (value !== '00:00') ? value : this.NO_VALUE;
	}

	get floZoneGridDisplay(): string {
		return this.floZoneName || this.NO_VALUE;
	}

	get isDisabled(): boolean {
		return !this.model || this.model === 'Disabled';
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>
<div class="absolute right-0 closeButton">
	<i class="material-icons f5" mat-button (click)="cancel()" data-qaid="dialog-cancel-button">close</i>
</div>
<div class="pb3"></div>
<header *ngIf="!hideHeader" class="flex rb-dialog--header w-100">
	<h3 mat-dialog-title class="header3 pb3 mb2">{{ title }}</h3>
</header>
<div class="rb-dialog--content w-100 pa4">
	<ng-content select="[section1]"></ng-content>
</div>
<div class="rb-dialog--content mat-dialog-content absolute w-100 overflow-y-scroll pa4">
	<ng-content select="[section2]"></ng-content>
</div>
<div class="rb-dialog--content w-100 pa4">
	<ng-content select="[section3]"></ng-content>
</div>

<div class="rb-dialog--footer flex justify-end absolute bottom-0 w-100 pt3 pb3 z-9999 bg-white">
<!--	<div class="rb-dialog&#45;&#45;footer-bg bg-white absolute w-100 h-100 left-0"></div>-->
	<div *ngIf="showFooterSeparator" class="rb-dialog-footer-sep"></div>
	<mat-dialog-actions class="flex justify-end">
		<button
			mat-button
			(click)="cancel()"
			data-qaid="dialog-cancel-button"
			>
			{{ closeButtonLabel }}
		</button>
		<button
			mat-button
			*ngIf="showSubmitButton"
			(click)="submit()"
			color="primary"
			data-qaid="dialog-submit-button"
			[disabled]="saveDisabled"
		>
			{{ submitButtonLabel }}
			<i [hidden]="!isLoading" class="fa fa-circle-o-notch fa-spin"></i>
		</button>
	</mat-dialog-actions>
</div>

import { ChangeBase } from './change-base.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';

export class JobChange extends ChangeBase {

	constructor(json: any = null) {

		super(json);

		if (json) {
			this.cancelled = json.cancelled;
			this.changeType = json.changeType;
			this.syncType = json.syncType;
			this.jobId = json.jobId;
			this.satelliteId = json.satelliteId;
			this.firmwareHeaderDeviceType = json.firmwareHeaderDeviceType;
		}
	}

	cancelled = false;
	changeType: RbEnums.SignalR.JobType;
	firmwareHeaderDeviceType: string;
	rasterTestHeaderDeviceType: string;
	syncType: string;
	jobId?: string;
	satelliteId?: number;
	progress?: number;

	get firmwareDeviceTypeLabel(): string {
		switch (this.firmwareHeaderDeviceType) {
			case 'Unknown':
				return '';
			case 'LXIVMPlus':
				return RbUtils.Translate.instant('STRINGS.LX_IVM_PRO');
			case 'NCC':
				return RbUtils.Translate.instant('STRINGS.CARTRIDGE');
			case 'FSM2':
				return RbUtils.Translate.instant('STRINGS.FSM2');
			case 'FSCM2':
				return RbUtils.Translate.instant('STRINGS.FSCM2');
			default:
				return this.firmwareHeaderDeviceType;
		}
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="custom-report-tri-pane" [ngClass]="{ 'mobile': deviceManager.isMobile}">

	<div class="report-toolbar" [ngClass]="{ 'mobile': deviceManager.isMobile, 'hidden': deviceManager.isMobile && dataLoaded, 'no-padding': noToolbarPadding }">
		<ng-container [ngTemplateOutlet]="toolbar"></ng-container>
	</div>

	<div *ngIf="!deviceManager.isMobile || dataLoaded" class="main-content" [ngClass]="{ 'mobile': deviceManager.isMobile, 'no-margin': noMainContentMargin }">
		<div *ngIf="leftPane != null && dataLoaded && (!deviceManager.isTablet)" class="left-pane">
			<div class="pane-content">
				<ng-container [ngTemplateOutlet]="leftPane"></ng-container>
			</div>
		</div>
		<div *ngIf="dataLoaded" class="middle-pane">
			<div *ngIf="displayTopActionPane && !deviceManager.isMobile && (!deviceManager.isTablet || !deviceManager.isPortrait)" class="top-pane">
				<div class="top-pane-buttons">
					<button mat-button type="button" class="outlined-button" color="primary" (click)="onViewPdf()">{{'STRINGS.VIEW_PDF' | translate}}</button>
					<button mat-button type="button" class="outlined-button" color="primary" (click)="onExportCsv()">{{'STRINGS.EXPORT_TO_CSV' | translate}}</button>
					<button mat-button type="button" class="outlined-button share-button" (click)="onShare()">
						<mat-icon>share</mat-icon>
						<span>{{'STRINGS.SHARE' | translate}}</span>
					</button>
				</div>
			</div>
			<div class="pane-content">
				<ng-container [ngTemplateOutlet]="middlePane"></ng-container>
			</div>
		</div>

		<div *ngIf="dataLoaded && displayRightPane && !deviceManager.isMobile && (!deviceManager.isTablet || !deviceManager.isPortrait)" class="right-pane">
			<div class="right-pane-top-buttons">
				<button mat-button type="button" class="outlined-button" color="primary" (click)="onViewPdf()">{{'STRINGS.VIEW_PDF' | translate}}</button>
				<button mat-button type="button" class="outlined-button" color="primary" (click)="onExportCsv()">{{'STRINGS.EXPORT_TO_CSV' | translate}}</button>
			</div>

			<div class="right-pane-bottom-buttons">
				<button type="button" class="inverted-button" (click)="onShare()">
					<mat-icon>share</mat-icon>
					<span>{{'STRINGS.SHARE' | translate}}</span>
				</button>
			</div>
		</div>
	</div>

	<rb-breadcrumb-trail *ngIf="!deviceManager.isMobile" ></rb-breadcrumb-trail>

	<div *ngIf="!deviceManager.isMobile" class="bottom-spacing">
	</div>

	<div *ngIf="busy && progress >= 0 && !loadError" class="progress-bar-container">
		<mat-progress-bar [value]="progress"></mat-progress-bar>
		<div class="progress-value">{{progress}} %</div>
	</div>

	<rb-waiting-indicator
		*ngIf="(busy && progress < 0) || loadError"
		[isVisible]="busy"
		[text]="!dataLoaded ? progressService.reportRunningLabel : ''"
		[percentComplete]="!dataLoaded ? progressService.currentPercentageProgress : -1"
		[error]="loadError"
		(okClick)="onActionErrorAcknowledged()">
	</rb-waiting-indicator>
</div>

<ng-template #selectRecipientsDialog>
	<rb-share-recipients-dialog
	[contactsList]="contactsList"
	[showFormatSelection]="showFormatSelection"
	(complete)="onShareRecipientsComplete($event)"></rb-share-recipients-dialog>
</ng-template>

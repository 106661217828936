<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-weather-source-mobile-cell-container">

	<div class="flex justify-between">
		<div [innerHTML]="getConnectedStateRenderer(golfSensorListItem)"></div>
		<div class="sensor-name-text ellipsis-text">{{golfSensorListItem?.name}}</div>
	</div>

	<span *ngIf="!golfSensorListItem?.rainWatchData; else checkRainWatch">
		<span>{{ 'STRINGS.STATUS' | translate }}</span>
		&nbsp;
		<span *ngIf="golfSensorListItem == null || golfSensorListItem.sensorStatus == null || golfSensorListItem.sensorStatus.sensorStatusItem == null; else haveValue">
			-
		</span>
		<ng-template #haveValue>
			{{ getFlowOrProgrammableSensorStatus(golfSensorListItem) }}
		</ng-template>
	</span>
	<ng-template #checkRainWatch>
		<span *ngIf="golfSensorListItem?.rainWatchData?.rainWatchTimeSinceShutDown != null; else checkPaused">
			<table>
				<tr>
					<td rowspan="3" class="sensor-status-icon-td-mat">
						<rb-system-status-button 
							matIconName="clear" 
							buttonColor="rgb(245, 115, 54)" 
							>
						</rb-system-status-button>
					</td>
				</tr>
				<tr>
					<td class=shutdown-text>
						{{ 'RAINWATCH_STATUS.SHUTDOWN_START_LABEL' | translate }} {{ durationFormat(golfSensorListItem.rainWatchData.rainWatchTimeSinceShutDown) }}
					</td>
				</tr>
				<tr>
					<td class="secondary-status-text">
						{{ 'RAINWATCH_STATUS.TOTAL_RAIN_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchIncrementalRainAccumulated) }}
					</td>
				</tr>
			</table>
		</span>
		<ng-template #checkPaused> 
			<span *ngIf="golfSensorListItem?.rainWatchData?.rainWatchTimeSincePause != null; else checkRaining">
				<table>
					<tr>
						<td rowspan="4" class="sensor-status-icon-td-mat">
							<rb-system-status-button 
								matIconName="paused" 
								buttonColor="rgb(243, 181, 67)" 
								>
							</rb-system-status-button>
						</td>
						<td class="pause-text">
							{{ 'RAINWATCH_STATUS.PAUSE_START_LABEL' | translate }} {{ durationFormat(golfSensorListItem.rainWatchData.rainWatchTimeSincePause) }}
						</td>
					</tr>
					<tr>
						<td class="secondary-status-text">
							{{ 'RAINWATCH_STATUS.TOTAL_RAIN_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchIncrementalRainAccumulated) }}
						</td>
					</tr>
					<tr>
						<td class="secondary-status-text">
							{{ 'RAINWATCH_STATUS.SHUTDOWN_ACCUMULATION_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchShutDownRainAccumulated) }}
						</td>
					</tr>
				</table>
			</span>
			<ng-template #checkRaining>
				<span *ngIf="golfSensorListItem?.rainWatchData?.rainWatchTimeSinceLastRain != null; else noRain">
					<table>
						<tr>
							<td rowspan="5" class="sensor-status-icon-td icon-status-rain">
								<!-- This needs to be a button! It's a bit of an unusual button, controlled by the CSS -->
								<button
									id="sensorStatusIcon"
									class="system-status-button"
									>
									<i class="climacon-rain-cloud"></i>
								</button>
							</td>
							<td class="rain-text">
								{{ 'RAINWATCH_STATUS.LAST_RAIN_LABEL' | translate }} {{ durationFormat(golfSensorListItem.rainWatchData.rainWatchTimeSinceLastRain) }}
							</td>
						</tr>
						<tr>
							<td class="rain-text">
								{{ 'RAINWATCH_STATUS.TOTAL_RAIN_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchIncrementalRainAccumulated) }}
							</td>
						</tr>
						<tr>
							<td class="secondary-status-text">
								{{ 'RAINWATCH_STATUS.PAUSE_ACCUMULATION_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchPauseRainAccumulated) }}
							</td>
						</tr>
						<tr>
							<td class="secondary-status-text">
								{{ 'RAINWATCH_STATUS.SHUTDOWN_ACCUMULATION_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchShutDownRainAccumulated) }}
							</td>
						</tr>
					</table>
				</span>
				<ng-template #noRain>
					<span>-</span>
				</ng-template>
			</ng-template>
		</ng-template>
	</ng-template>
</div>

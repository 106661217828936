export class ICIGroupFaultFindingChange {
	satelliteId: number;
	group: number;
	faultFindingON: boolean;
	timeRemaining: number;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}
}

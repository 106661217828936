/* eslint-disable no-bitwise, @typescript-eslint/member-ordering, @angular-eslint/directive-class-suffix */

import { AppInjector } from '../../../core/core.module';
import { Controller } from './controller.model';
import { Directive } from '@angular/core';
import { FirmwareUpdateProgress } from '../../../api/signalR/firmware-update-progress.model';
import { FlowZone } from '../../flow-elements/models/flow-zone.model';
import { RasterTestProgress } from '../../../api/signalR/raster-test-progress.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationValveType } from '../../station-valve-types/station-valve-type.model';
import { UtilityService } from '../../../common/services/utility.service';

import ControllerStatusFlag = RbEnums.Common.ControllerStatusFlag;
import ControllerSyncState = RbEnums.Common.ControllerSyncState;
import DeviceType = RbEnums.Common.DeviceType;

@Directive()
export class ControllerListItem {

	private _syncState: RbEnums.Common.ControllerSyncState;
	private _isDetecting = false;
	private _gettingLogs = false;
	private _queued = false;
	private _utilityService: UtilityService;
	private _stationCount: number;
	private _simultaneousStations: number;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.firmwareUpdateProgress) this.firmwareUpdateProgress = new FirmwareUpdateProgress(json.firmwareUpdateProgress);
			if (json.lastRetrieveLogsCompleted) { this.lastRetrieveLogsCompleted = RbUtils.Conversion.convertStringToDate(json.lastRetrieveLogsCompleted); }
			if (json.lastContact) { this.lastContact = RbUtils.Conversion.convertStringToDate(json.lastContact); }
			if (json.lastPhysicalRetrieve) { this.lastPhysicalRetrieve = RbUtils.Conversion.convertStringToDate(json.lastPhysicalRetrieve); }
			if (json.rasterTestProgress) this.rasterTestProgress = new RasterTestProgress(json.rasterTestProgress);

			if (json.upcomingAutoContactScheduledTime) {
				this.upcomingAutoContactScheduledTime = RbUtils.Conversion.convertStringToDate(json.upcomingAutoContactScheduledTime);
			}
		}

		this.syncState = RbUtils.Controllers.getSyncStateFromFrontPanelState(this.frontPanelState);
		this.updateAfterConstruction();
	}

	private updateAfterConstruction() {
		this._utilityService = AppInjector.get(UtilityService);
	}
	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================

	commInterfaceType: RbEnums.Common.CommInterfaceType;
	hasLastSyncDifferences = false;
	id: number;
	iqNetType: RbEnums.Common.IqNetType;
	isConnected = false;
	isShutdown = false;
	frontPanelState: RbEnums.Common.FrontPanelState;
	name = '';
	latitude: number;
	longitude: number;
	parentId: number;						// IQNet Server if 'this' controller is an IQNet Client.
	programsUsedCount: number;
	rainDelay = 0;
	siteId: number;
	siteName: string;
	stationsProgrammedCount: number;
	type: RbEnums.Common.DeviceType;
	firmwareUpdateProgress: FirmwareUpdateProgress = null;
	actualFlowRate = 0;
	/**
	 * RB-15025: Store the actual flow rate list returned from SignalR/AppSync.
	 * Same as aggregate actual flow rate above
	 */
	actualFlowRateList: number[] = [];
	maxFlow = 0;
	lastRetrieveLogsCompleted: Date;
	lastContact: Date;
	lastPhysicalRetrieve: Date;
	hasFlowAlarms = false;
	rasterTestProgress: RasterTestProgress = null;
	ipAddress: string;
	mrm: any;
	stationValveType: StationValveType[] = [];
	flowZone: FlowZone[] = [];
	description: string;
	syncMode?: RbEnums.Common.AutoContactMode;
	autoContactsScheduleByString?: string;
	upcomingAutoContactScheduledTime?: Date;

	/**
	 * This is the value for the whole satellite, including any modules. For PAR+ES, for example, this
	 * might be 72 while satellite.stationCount would only be 16, the base number of stations on a PAR+ES.
	 */
	get stationCount(): number {
		if (DeviceType.PulseDecoderTwoWire === this.type || DeviceType.SensorDecoderTwoWire === this.type) {
			return null;		
		}
		return this._stationCount;
	}

	set stationCount(value: number) {
		this._stationCount = value;
	}

	channelA: number;
	channelB: number;
	channelC: number;

	get simultaneousStations(): number {
		if (DeviceType.PulseDecoderTwoWire === this.type || DeviceType.SensorDecoderTwoWire === this.type) {
			return null;		
		}
		return this._simultaneousStations;
	}

	set simultaneousStations(value: number) {
		this._simultaneousStations = value;
	}

	simultaneousStationsPerChannel: number;
	maxSolenoids: number;
	groupNumber: number;
	address: number;
	satelliteEnabled = true;

	get isDetecting(): boolean {
		return this._isDetecting;
	}

	set isDetecting(isDetecting: boolean) {
		this._isDetecting = isDetecting;
	}

	get syncState(): ControllerSyncState {
		return this._syncState;
	}

	set syncState(syncState: ControllerSyncState) {
		this._syncState = syncState;
	}

	/**
	 * queued indicates that a communication process is pending, but not yet started, as when it's throttled
	 * by the service. RB-7650.
	 */
	get queued(): boolean {
		return this._queued;
	}

	set queued(queued: boolean) {
		this._queued = queued;
	}

	get gettingLogs(): boolean {
		return this._gettingLogs;
	}

	set gettingLogs(gettingLogs: boolean) {
		this._gettingLogs = gettingLogs;
	}

	gettingPhysicalData = false;

	// it could be either the controller is a link interface or 
	// is a child of a link interface
	linkInterface: boolean = false;
	get typeDescription(): string {
		return RbUtils.Controllers.getDisplayName(this.type, this.linkInterface);
	}

	get iqNetTypeString(): string {
		switch (this.iqNetType) {
			case RbEnums.Common.IqNetType.DirectSatellite:
				return RbUtils.Translate.instant('STRINGS.IQ_NET_TYPE_DIRECT_SATELLITE');
			case RbEnums.Common.IqNetType.IQNetClient:
				return RbUtils.Translate.instant('STRINGS.IQ_NET_TYPE_CLIENT');
			case RbEnums.Common.IqNetType.IQNetServer:
				return RbUtils.Translate.instant('STRINGS.IQ_NET_TYPE_SERVER');
		}
	}

	get iqNetTypeShortDescription(): string {
		const isSatelliteTbos = this._utilityService.isSatelliteTbos(this.type)
		if (this.type === RbEnums.Common.DeviceType.IQI &&
			this.commInterfaceType === RbEnums.Common.CommInterfaceType.None) {
			return RbUtils.Controllers.getIqNetTypeShortDescription(this.iqNetType, isSatelliteTbos);
		}
		if (this._utilityService.isInDemoMode(this)) {
			return RbUtils.Controllers.getIqNetTypeShortDescription(this.iqNetType, isSatelliteTbos) + ' *';
		}
		return RbUtils.Controllers.getIqNetTypeShortDescription(this.iqNetType, isSatelliteTbos);
	}

	get isInRainDelay(): boolean {
		return this.rainDelay > 0;
	}

	get isInFlowAlarms(): boolean {
		return this.hasFlowAlarms;
	}

	get isTwoWireController(): boolean {
		return RbUtils.Controllers.checkTwoWireController(this.type);
	}

	// Used for Status field on Controller Tab Component Grid.
	get statusColumnValue(): ControllerStatusFlag {
		let value = this.syncState === ControllerSyncState.Incomplete ? ControllerStatusFlag.Incomplete :
			this.syncState === ControllerSyncState.Synchronized ? ControllerStatusFlag.Synchronized : ControllerStatusFlag.OutOfSync;

		if (this.queued) { value |= ControllerStatusFlag.Queued; }
		if (this.isConnected) { value |= ControllerStatusFlag.Connected; }

		return value;
	}

	static fromController(controller: Controller, existingListItem: ControllerListItem = null): ControllerListItem {
		const listItem = new ControllerListItem();
		listItem.commInterfaceType = controller.commInterface == null || controller.commInterface.length === 0 ? null : controller.commInterface[0].type;
		listItem.hasLastSyncDifferences = controller.hasLastSyncDifferences;
		listItem.id = controller.id;
		listItem.iqNetType = controller.iqNetType;
		listItem.isConnected = false;
		listItem.isShutdown = controller.isShutdown;
		listItem.frontPanelState = controller.frontPanelState;
		listItem.name = controller.name;
		listItem.latitude = controller.latitude;
		listItem.longitude = controller.longitude;
		listItem.parentId = controller.parentId;
		listItem.programsUsedCount = controller.programsUsed;
		listItem.rainDelay = controller.rainDelayDaysRemaining;
		listItem.siteId = controller.siteId;
		listItem.siteName = controller.site.name;
		listItem.stationsProgrammedCount = controller.stationsProgrammed;
		listItem.type = controller.type;
		listItem.actualFlowRate = controller.flowZone == null || controller.flowZone.length === 0 ? 0 : controller.flowZone[0].flowRate;
		listItem.maxFlow = controller.maxFlow;
		listItem.lastRetrieveLogsCompleted = controller.lastRetrieveLogsCompletedDateTimeOffset;
		listItem.lastContact = controller.lastContactDateTimeOffset;
		listItem.lastPhysicalRetrieve = controller.lastPhysicalRetrieveDateTimeOffset;
		listItem.hasFlowAlarms = false;
		listItem.address = controller.address;
		listItem.groupNumber = controller.groupNumber;
		listItem.channelA = controller.channelA;
		listItem.channelB = controller.channelB;
		listItem.channelC = controller.channelC;
		listItem.simultaneousStations = controller.simultaneousStations;
		listItem.simultaneousStationsPerChannel = controller.simultaneousStationsPerChannel;
		listItem.maxSolenoids = controller.maxSolenoids;
		listItem.satelliteEnabled = controller.satelliteEnabled;

		if (existingListItem != null) {
			listItem.isConnected = existingListItem.isConnected;
			listItem.syncState = existingListItem.syncState;
			listItem.firmwareUpdateProgress = existingListItem.firmwareUpdateProgress;
			if (existingListItem.syncState === RbEnums.Common.ControllerSyncState.Syncing ||
				existingListItem.syncState === RbEnums.Common.ControllerSyncState.ReverseSyncing) {

				listItem.syncState = existingListItem.syncState;
			}
			listItem._isDetecting = existingListItem._isDetecting;
			listItem._gettingLogs = existingListItem._gettingLogs;
			listItem.gettingPhysicalData = existingListItem.gettingPhysicalData;
			listItem._queued = existingListItem._queued;
			listItem.hasFlowAlarms = existingListItem.hasFlowAlarms;
			listItem.rasterTestProgress = existingListItem.rasterTestProgress;
			listItem.lastRetrieveLogsCompleted = existingListItem.lastRetrieveLogsCompleted;
			listItem.hasLastSyncDifferences = existingListItem.hasLastSyncDifferences;
		}

		listItem.updateAfterConstruction();
		return listItem;
	}
}

import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActiveFlowChartItem } from './models/active-flow-chart-item.model';
import { AuthManagerService } from '../auth/auth-manager-service';
import { FlowElementManagerService } from '../flow-elements/flow-element-manager.service';
import { GolfSensorListItem } from '../sensors/models/golf-sensor-list-item.model';
import { InactiveFlowListItem } from './models/inactive-flow-list-item.model';
import { ProgramChange } from '../signalR/program-change.model';
import { ProgramGroupManagerService } from '../program-groups/program-group-manager.service';
import { ProgramManagerService } from '../programs/program-manager.service';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { SensorListChange } from '../sensors/models/sensor-list-change.model';
import { SensorListItem } from '../sensors/models/sensor-list-item.model';
import { SensorManagerService } from '../sensors/sensor-manager.service';
import { SensorStatus } from '../sensors/models/sensor-status.model';
import { SensorStatusChange } from '../signalR/sensor-status-change.model';
import { StationListItem } from '../stations/models/station-list-item.model';
import { StationManagerService } from '../stations/station-manager.service';
import { StationsListChange } from '../stations/models/stations-list-change.model';
import { StationStatusChange } from '../signalR/station-status-change.model';
import { take } from 'rxjs/operators';

import Timer = NodeJS.Timer;

/**
 * IrrigationActivityService stores real-time status data for programs, program groups, and stations (and
 * anything else we might need). This data is generally used for real-time display items but might also
 * be used on an Activity | Active screen to show what's running. To that end, although we receive *all* the
 * program, program group, and station updates, we save only those which are real-time status-based (started,
 * stopped, waiting, paused, etc.); that is, we do not store Updated, Added, Deleted.
 */
@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class IrrigationActivityService implements OnDestroy {

	programStatusChange = new Subject<string>();
	activeFlowChartUpdate = new Subject<ActiveFlowChartItem>();
	inactiveFlowListUpdate = new Subject<InactiveFlowListItem>();

	/**
	 * activeSensorUpdate will be fired when we get a new sensor status (real-time) change. The data passed
	 * includes the sensor status change details.
	 */
	activeFlowSensorUpdate = new Subject<SensorStatusChange>();

	programGroupActivityDict: { [id: number]: ProgramChange } = {};
	programActivityDict: { [id: number]: ProgramChange } = {};
	stationActivityDict: { [id: number]: StationStatusChange } = {};
	sensorActivityDict: { [id: number]: SensorStatusChange } = {};

	private stations: StationListItem[] = [];
	private sensors: SensorListItem[] = [];
	private totalFlowByPump: Map<number, number> = new Map<number, number>();

	/**
	 * golfSensors is a simple list of GolfSensorListItem entries. Please do not use the golfSensors SETTER
	 * externally.
	 */
	private _golfSensors: GolfSensorListItem[] = [];
	public get golfSensors(): GolfSensorListItem[] {
		return this._golfSensors;
	}
	public set golfSensors(list: GolfSensorListItem[]) {
		this._golfSensors = list;
		this._golfSensorsObservable.next(list);
	}

	/**
	 * golfSensorsObservable returns new sensor list item sets, or the most-recent value. This allows
	 * a) multiple clients to connect and get the data and, b) clients to always use this item for
	 * sensor list access, regardless of whether sensors have already arrived, have not arrived or
	 * have arrived multiple times and will arrive again later.
	 */
	private _golfSensorsObservable: BehaviorSubject<GolfSensorListItem[]> = new BehaviorSubject<GolfSensorListItem[]>([]);
	public get golfSensorsObservable(): BehaviorSubject<GolfSensorListItem[]> {
		return this._golfSensorsObservable;
	}

	private _isChartUpdateActive = false;
	private displayTimer: Timer;
	public isGolf = false;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private programManager: ProgramManagerService,
				private programGroupManager: ProgramGroupManagerService,
				private stationManager: StationManagerService,
				private sensorManager: SensorManagerService,
				private flowElementManagerService: FlowElementManagerService
	) {
		// RB-7095: Bail out if user is not logged in as it will throw unauthorized errors when attempting to fetch data.
		// This constructor WILL be called again when user logs in.
		this.displayTimer = setInterval(() => {
			if (this.authManager.isLoggedIn) {
				clearInterval(this.displayTimer);
				this.isGolf = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
				this.getServiceData();
			}
		}, 100);

		// Monitor for the sole purpose of updating the stations 'lookup' list.
		this.stationManager.stationsListChange
			.pipe(untilDestroyed(this))
			.subscribe((change: StationsListChange) => {
				this.stations = change.stations;
				if (this.stations.length) {
					for (const key of Object.keys(this.stationActivityDict)) {
						if (!this.stationActivityDict[key].stationName) {
							this.stationActivityDict[key].stationName = this.getStationName(+key);
						}
					}
				}
			});

		// Monitor for the sole purpose of updating the sensors 'lookup' list.
		this.sensorManager.sensorsListChange
			.pipe(untilDestroyed(this))
			.subscribe((change: SensorListChange) => {
				if (change && change.sensors) this.sensors = change.sensors;

				// If the list changed, that might mean deletion or addition, so we need to refresh the
				// list.
				if (this.isGolf) {
					this.getGolfSensors();
				}
			});
		
		if (this.isGolf) {
			// RB-10805: Getting pump flow changes
			this.flowElementManagerService.getCompanyPumps(true)
				.pipe(untilDestroyed(this))
				.subscribe(companyPumps => {
					this.flowElementManagerService.flowElementsFlowDataChange
						.pipe(untilDestroyed(this))
						.subscribe(flowElementChange => {
							this.totalFlowByPump = new Map<number, number>();
							companyPumps.forEach((pump) => {
								if (flowElementChange.flowRateUpdates[pump.id]){
									this.totalFlowByPump.set(pump.id, flowElementChange.flowRateUpdates[pump.id].flowRateGPM);
								} else {
									this.totalFlowByPump.set(pump.id, 0);
								}
							});
						});
				});
			// RB-14318 IQ4 - GetProgramGroups should be called in Golf only 	
			this.programGroupManager.getProgramGroupsList().subscribe();
		}

		// RB-7496: If the Activity screen is the first one opened when the user enters, we need to have the
		// programs and groups loaded or the new chart items will be wrong.
		// RB-14318: we only need to list programs in IQ4 instead of both programs and groups loaded in Golf.
		this.programManager.getProgramsList().subscribe();	
	}

	ngOnDestroy(): void {
		/** Required by untilDestroyed */
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get isChartUpdateActive(): boolean {
		return this._isChartUpdateActive;
	}

	// NOTE: The Irrigation Engine refers to Programs as Schedules.
	addProgramActivity(programChange: ProgramChange) {
		const id = programChange.programId;

		// RB-7496: We only update/save the new change data if it representing real-time status data.
		if (RbUtils.Programs.isProgramRealTimeStatusMessage(programChange.changeType)) {
			programChange.programName = this.getProgramName(id);
			programChange.programGroupId = this.getProgramProgramGroupId(id);

			if (!this.programActivityDict[id]) {
				this.programActivityDict[id] = programChange;
				this.programStatusChange.next(programChange.changeType);
			} else {
				const existingDateTime = this.programActivityDict[id].changeDateTime;
				if (programChange.changeDateTime.valueOf() >= existingDateTime.valueOf() ||
					(programChange.changeDateTime.valueOf() === existingDateTime.valueOf()
						&& programChange.changeType !== this.programActivityDict[id].changeType)) {
					this.programActivityDict[id] = programChange;
					this.programStatusChange.next(programChange.changeType);
				} else {
					console.error('addProgramActivity: out of date (or duplicate) status received. stored=%o, new=%o',
						this.programActivityDict[id], programChange);
				}
			}
			this.updateList();
		}
	}

	/**
	 * setProgramActivity replaces all program status data with the new status items passed. RB-8985
	 * @param changeDateTime - Date/time of all of the programChanges entries. This is used to clear the existing data of
	 * older items before adding programChanges
	 * @param programChanges - list of ProgramChange entries representing all program states. NOTE: THE PROGRAM CHANGES
	 * MUST ALL PASS THE isProgramRealTimeStatusMessage() TEST. DO NOT PASS UPDATED, CREATED, DELETED, ETC.
	 */
	setProgramActivity(changeDateTime: Date, programChanges: ProgramChange[]) {
		// Since we are replacing all program group activity, clear existing activity first, but obviously not activity
		// later than the replacement date/time.
		for (const key of Object.keys(this.programActivityDict)) {
			// If the item's date is earlier than changeDate, remove it. If later or equal, leave it.
			if (this.programActivityDict[key].changeDateTime.valueOf() < changeDateTime.valueOf()) {
				delete this.programActivityDict[key];
			}
		}

		// Enumerate all of the changes, adding each to the dictionary.
		programChanges.forEach(p => {
			// Do some simple verification assuring we're keeping the latest item, just in case the same program group
			// has multiple entries or we get an asynchronous update while processing this list.
			const id = p.programId;
			p.programName = this.getProgramName(id);
			p.programGroupId = this.getProgramProgramGroupId(id);
			if ((this.programActivityDict[id] == null) || (this.programActivityDict[id].changeDateTime.valueOf()) < changeDateTime.valueOf()) {
				this.programActivityDict[id] = p;
				this.programStatusChange.next(p.changeType);
			}
		});
		this.updateList();
	}

	addProgramGroupActivity(programChange: ProgramChange) {
		const id = programChange.programGroupId;

		// RB-7496: We only update/save the new change data if it representing real-time status data.
		if (RbUtils.Programs.isProgramRealTimeStatusMessage(programChange.changeType)) {
			programChange.programGroupName = this.getProgramGroupName(id);

			if (!this.programGroupActivityDict[id]) {
				this.programGroupActivityDict[id] = programChange;
			} else {
				const existingDateTime = this.programGroupActivityDict[id].changeDateTime;
				if (programChange.changeDateTime.valueOf() >= existingDateTime.valueOf() ||
					(programChange.changeDateTime.valueOf() === existingDateTime.valueOf()
						&& programChange.changeType !== this.programGroupActivityDict[id].changeType)) {
					this.programGroupActivityDict[id] = programChange;
				} else {
					console.error('addProgramGroupActivity: out of date (or duplicate) status received. stored=%o, new=%o',
						this.programGroupActivityDict[id], programChange);
				}
			}

			this.updateList();
		}
	}

	/**
	 * setProgramGroupActivity replaces all program group status data with the new status items passed. RB-8985
	 * @param changeDateTime - Date/time of all of the programGroupChanges entries. This is used to clear the existing data
	 * of older items before adding programGroupChanges
	 * @param programChanges - list of ProgramChange entries representing all program group states. NOTE: THE PROGRAM CHANGES
	 * MUST ALL PASS THE isProgramRealTimeStatusMessage() TEST. DO NOT PASS UPDATED, CREATED, DELETED, ETC.
	 */
	setProgramGroupActivity(changeDateTime: Date, programChanges: ProgramChange[]) {
		// Since we are replacing all program group activity, clear existing activity first, but obviously not activity
		// later than the replacement date/time.
		for (const key of Object.keys(this.programGroupActivityDict)) {
			// If the item's date is earlier than changeDate, remove it. If later or equal, leave it.
			if (this.programGroupActivityDict[key].changeDateTime.valueOf() < changeDateTime.valueOf()) {
				delete this.programGroupActivityDict[key];
			}
		}

		// Enumerate all of the changes, adding each to the dictionary.
		programChanges.forEach(pg => {
			// Do some simple verification assuring we're keeping the latest item, just in case the same program group
			// has multiple entries or we get an asynchronous update while processing this list.
			const id = pg.programGroupId;
			pg.programGroupName = this.getProgramGroupName(id);
			if ((this.programGroupActivityDict[id] == null) || (this.programGroupActivityDict[id].changeDateTime.valueOf()) < changeDateTime.valueOf()) {
				this.programGroupActivityDict[id] = pg;
			}
		});
		this.updateList();
	}

	addStationsActivity(stationStatusChanges: StationStatusChange[]) {
		if (!stationStatusChanges) return;
		if (this.stations.length > 0) {
			this.processStationsActivity(stationStatusChanges);
			return;
		}

		// this is only for update station name
		if (this.isGolf){
			// Don't have stations list yet - load it
			this.stationManager.getStationsList().subscribe(stations => {

				this.stations = stations;

				for (const key of Object.keys(this.stationActivityDict)) {
					this.stationActivityDict[key].stationName = this.getStationName(+key);
				}

				this.processStationsActivity(stationStatusChanges);
			});
		} else{
			this.processStationsActivity(stationStatusChanges);
		}

	}

	private processStationsActivity(stationStatusChanges: StationStatusChange[]): void {
		stationStatusChanges.forEach( stationStatusChange => {
			stationStatusChange.stationName = this.getStationName(stationStatusChange.stationId);

			// RB-7496: We only update/save the new change data if it representing real-time status data.
			if (RbUtils.Stations.isStationRealTimeStatusMessage(stationStatusChange.changeType)) {
				// RB-8570: Save the data if there is no station data yet, or if the data is newer than the last-received
				// station data (based on changeDateTime).
				const id = stationStatusChange.stationId;
				if (!this.stationActivityDict[id]) {
					this.stationActivityDict[id] = stationStatusChange;
				} else {
					const existingDateTime = this.stationActivityDict[id].changeDateTime;
					if (stationStatusChange.changeDateTime.valueOf() >= existingDateTime.valueOf()) {
						this.stationActivityDict[id] = stationStatusChange;
					} else {
						console.error('addStationActivity: out of date (or duplicate) status received. stored=%o, new=%o',
							this.stationActivityDict[id], stationStatusChange);
						stationStatusChange.clearCountdownTimer();
					}
				}
			}
		});

		this.updateList();
		this.updateChart();
	}

	addSensorsActivity(sensorStatusChanges: SensorStatusChange[]) {
		if (this.sensors.length > 0) {
			this.processSensorsActivity(sensorStatusChanges);
			return;
		}

		// Don't have sensors list yet - load it
		this.sensorManager.getSensorsList().subscribe(sensors => {
			this.sensors = sensors;

			// Update Sensor Names
			for (const key of Object.keys(this.sensorActivityDict)) {
				this.sensorActivityDict[key].sensorName = this.getSensorName(+key);
			}

			this.processSensorsActivity(sensorStatusChanges);
		});
	}

	private processSensorsActivity(sensorStatusChanges: SensorStatusChange[]): void {
		let isFlowSensor = false;
		sensorStatusChanges.forEach( sensorStatusChange => {
			sensorStatusChange.sensorName = this.getSensorName(sensorStatusChange.sensorId);

			if (RbUtils.Sensor.isSensorRealTimeStatusMessage(sensorStatusChange.changeType)) {
				// RB-8246: We don't really want a history of status for the sensor, just the most-recent value. Set the
				// dictionary entry to [sensorStatusChange], basically.
				this.sensorActivityDict[sensorStatusChange.sensorId] = sensorStatusChange;
				// Fire an event to any interested subscribers, if the sensor is a flow sensor and is set for FloGraph
				// display. this.golfSensors should either be an empty list or indexed array on sensorId.
				if (this.isGolf) {
					const sensor = this.golfSensors.find(s => s.id === sensorStatusChange.sensorId);
					if (sensor && sensor.kingdomId === RbEnums.Common.GolfSensorKingdom.Flow) {
						isFlowSensor = true;
						this.activeFlowSensorUpdate.next(sensorStatusChange);
					}
				}
			}
		});
		if (isFlowSensor) {

			this.updateList();
			this.updateChart();
		}
	}

	getCurrentActiveFlowChartItem() {
		const chartItem = new ActiveFlowChartItem({timestamp : (new Date().toString())});
		chartItem.runningStations = this.getRunningStations();
		chartItem.runningPrograms = this.getRunningPrograms(chartItem.runningStations);
		chartItem.runningProgramGroups = this.getRunningProgramGroups(chartItem.runningPrograms);
		chartItem.totalFlowByPump = this.totalFlowByPump;
		return chartItem;
	}

	getInactiveFlowListItem() {
		const listItem = new InactiveFlowListItem(new Date());
		listItem.inactiveStations = this.getInactiveStations();
		listItem.inactivePrograms = this.getInactivePrograms(listItem.inactiveStations);
		listItem.inactiveProgramGroups = this.getInactiveProgramGroups(listItem.inactivePrograms);
		return listItem;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getServiceData() {
		// For golf we need to get the sensor list *as golf sensors* so we can see the types, etc.
		if (this.isGolf) {
			this.sensorManager.getGolfSensorList().subscribe(golfSensors => {
				this.golfSensors = golfSensors ? golfSensors : [];	// May be empty.

				this.updateActiveFlowElementsOnLoad();
			});
		}
	}

	/**
	 * RB-8243: When we are displaying, potentially, FloGraph flow sensors from golf, we may need to reload the
	 * golf sensor list when a new sensor is added or removed.
	 */
	private getGolfSensors() {
		this.sensorManager.getGolfSensorList(true)
			.pipe(take(1))
			.subscribe(list => this.golfSensors = list);
	}

	// Method to update Active Flow Element names when the site first loads.
	// It is possible to receive SignalR events for StationStatusChange and ProgramChange before we have had a
	// chance to retrieve the respective lookup lists. When this happens, we have flow elements with no names.
	// This method is to remedy that. It only needs to be called once after the lookup lists have been fetched.
	private updateActiveFlowElementsOnLoad() {
		// Update Program Names
		for (const key of Object.keys(this.programGroupActivityDict)) {
			this.programGroupActivityDict[key].programGroupName = this.getProgramGroupName(+key);
		}

		// Update Schedule Names
		for (const key of Object.keys(this.programActivityDict)) {
			this.programActivityDict[key].programName = this.getProgramName(+key);
		}

		// Update Station Names
		for (const key of Object.keys(this.stationActivityDict)) {
			this.stationActivityDict[key].stationName = this.getStationName(+key);
		}

		// Update Sensor Names
		for (const key of Object.keys(this.sensorActivityDict)) {
			this.sensorActivityDict[key].sensorName = this.getSensorName(+key);
		}
	}

	private getProgramGroupName(programGroupId): string {
		const pgli = this.programGroupManager.cachedProgramGroups.find(pg => pg.id === programGroupId);
		return pgli ? pgli.name : RbUtils.Translate.instant('STRINGS.NO_PROGRAM_GROUP_NAME');
	}

	private getProgramName(programId): string {
		const pli = this.programManager.cachedPrograms.find(p => p.id === programId);
		return pli ? pli.name : RbUtils.Translate.instant('STRINGS.NO_PROGRAM_NAME');
	}

	private getProgramProgramGroupId(programId): number {
		const pli = this.programManager.cachedPrograms.find(p => p.id === programId);
		return pli ? pli.programGroupId : null;
	}

	getStationName(stationId): string {
		const sli = this.stations.find(s => s.id === stationId) || '';
		return sli ? sli.name : null;
	}

	private getSensorName(sensorId): string {
		const sli = this.sensors.find(s => s.id === sensorId) || '';
		return sli ? sli.name : '';
	}

	/**
	 * This method gets the running stations, running programs/program groups from the lists
	 * that we save program changes/station changes received from the SignalR.
	 * Then, the method will broadcast the data to subscribers.
	 * Theoretically, This should be called whenever the irrigation activity service receives a program change/station change.
	 **/
	private updateChart() {
		this._isChartUpdateActive = true;
		const chartItem = new ActiveFlowChartItem({timestamp : (new Date().toString())});

		// RB-7230: We don't only have running items when some stations are running! A Posted program group or program
		// should still be shown, even if no stations are running yet.
		// RB-7496: RB-7230 is wrong, now. Programs are shown as "running" only when they have stations running and
		// program groups are only shown as "running" when one or more of their programs are running. For this reason,
		// we pass the list of running stations when calcuating running programs and the list of running programs when
		// calculating the running program groups below.
		chartItem.runningStations = this.getRunningStations();
		chartItem.runningPrograms = this.getRunningPrograms(chartItem.runningStations);
		chartItem.runningProgramGroups = this.getRunningProgramGroups(chartItem.runningPrograms);
		chartItem.totalFlowByPump = this.totalFlowByPump;

		// Always display a data point for the requested time slice. Either we display real-time activity
		// received via SignalR, or we display an empty data point (representing no activity).
		this.activeFlowChartUpdate.next(chartItem);
		this._isChartUpdateActive = false;
	}

	/**
	 * This method gets the inactive (paused) stations, inactive programs/program groups from the lists
	 * that we save program changes/station changes received from the SignalR.
	 * Then, the method will broadcast the data to subscribers.
	 * This should be called whenever the irrigation activity service receives a program change/station change.
	 * Call this method as soon as possible after update data to make sure subscribers won't keep outdated data.
	 **/
	private updateList() {
		this.inactiveFlowListUpdate.next(this.getInactiveFlowListItem());
	}

	private getRunningStations(): StationStatusChange[] {
		// No status items at all?
		if (Object.keys(this.stationActivityDict).length < 1) return [];

		const runningStations: StationStatusChange[] = [];

		for (const key of Object.keys(this.stationActivityDict)) {
			if (!this.stationActivityDict[key]) continue;

			// RB-7496: Note that we assume stationActivityDict only contains real-time status entries.
			if (RbUtils.Stations.isStationRunningStatus(this.stationActivityDict[key].changeType)) {
				runningStations.push(this.stationActivityDict[key]);
			}
		}

		return runningStations;
	}

	private getInactiveStations(): StationStatusChange[] {
		// No status items at all?
		if (Object.keys(this.stationActivityDict).length < 1) return [];

		const inactiveStations: StationStatusChange[] = [];

		for (const key of Object.keys(this.stationActivityDict)) {
			if (!this.stationActivityDict[key]) continue;

			if (RbUtils.Stations.isStationInactiveStatus(this.stationActivityDict[key].changeType)) {
				inactiveStations.push(this.stationActivityDict[key]);
			}
		}

		return inactiveStations;
	}

	/**
	 * @summary RB-7496: Calculate the list of running programs. For golf, we have to know that at least one
	 * station in the program is running before calculating that program is running, even if we have a Started
	 * message from it. For that reason, we take the list of runningStations as a parameter (used only for golf).
	 * @param runningStations - StationChange[] containing all running stations where we can look up whether any
	 * stations in a given program are running.
	 */
	private getRunningPrograms(runningStations?: StationStatusChange[]): ProgramChange[] {
		if (Object.keys(this.programActivityDict).length < 1) return [];

		const runningPrograms: ProgramChange[] = [];

		for (const key of Object.keys(this.programActivityDict)) {
			if (!this.programActivityDict[key]) continue;

			// RB-7496: Note that we assume programActivityDict only contains real-time status entries.
			const status = this.programActivityDict[key] as ProgramChange;
			if (RbUtils.Programs.isProgramRunningStatus(status.changeType)) {
				// RB-7496: For golf, skip listing a program if none of its stations are running. For commercial,
				// just add it as "running".
				if (!this.isGolf || runningStations.find(runningStation => runningStation.programId === status.programId)) {
					runningPrograms.push(status);
				}
			}
		}

		return runningPrograms;
	}

	private getInactivePrograms(inactiveStations?: StationStatusChange[]): ProgramChange[] {
		if (Object.keys(this.programActivityDict).length < 1) return [];

		const inactivePrograms: ProgramChange[] = [];

		for (const key of Object.keys(this.programActivityDict)) {
			if (!this.programActivityDict[key]) continue;
			const status = this.programActivityDict[key] as ProgramChange;
			if (RbUtils.Programs.isProgramInactiveStatus(status.changeType)) {
				if (!this.isGolf || inactiveStations.find(inactiveStation => inactiveStation.programId === status.programId)) {
					inactivePrograms.push(status);
				}
			}
		}

		return inactivePrograms;
	}

	/**
	 * @summary RB-7496: For golf, we only mark a programGroup as running if one or more of its programs are running,
	 * which are only running if one or more of their stations are running. Since we presumably are calling
	 * getRunningPrograms() just before we call here, there's no use recalculating that needed data here;
	 * reuse it.
	 * @param runningPrograms: required for golf, optional for commercial, the list of already calcuated running
	 * programs. We use this list in the golf case to decide if a program group is actually running.
	 */
	private getRunningProgramGroups(runningPrograms?: ProgramChange[]): ProgramChange[] {
		if (Object.keys(this.programGroupActivityDict).length < 1) return [];

		const runningProgramGroups: ProgramChange[] = [];

		let runningProgramGroupIds: number[] = [];
		if (this.isGolf) {
			// RB-7496: For golf, we need to establish a candidate list of program groups that could be
			// running, based on which programs are running. Do that here.
			runningProgramGroupIds = [...new Set(
					// Map the running program change values into programGroupId values, via the this.programs list we
					// keep updated. Items which are not found in our this.programs list are ignored.
					runningPrograms.map(runningProgram => {
						const programListItem = this.programManager.cachedPrograms.find(listItem => listItem.id === runningProgram.programId);
						if (programListItem != null) {
							return programListItem.programGroupId;
						} else {
							return 0;
						}
					})
				)];
		}

		for (const key of Object.keys(this.programGroupActivityDict)) {
			if (!this.programGroupActivityDict[key]) continue;

			// RB-7496: Note that we assume programGroupActivityDict only contains real-time status entries.
			const status = this.programGroupActivityDict[key] as ProgramChange;
			if (RbUtils.Programs.isProgramRunningStatus(status.changeType)) {
				// RB-7496: For golf, skip listing a program group if none of its programs are running. For
				// commercial, just add it as "running".
				if (!this.isGolf || runningProgramGroupIds.some(pgId => pgId === status.programGroupId)) {
					status.programGroupName = this.getProgramGroupName(status.programGroupId);
					runningProgramGroups.push(status);
				}
			}
		}

		return runningProgramGroups;
	}

	private getInactiveProgramGroups(inactivePrograms?: ProgramChange[]): ProgramChange[] {
		if (Object.keys(this.programGroupActivityDict).length < 1) return [];

		const inactiveProgramGroups: ProgramChange[] = [];

		let inactiveProgramGroupIds: number[] = [];
		if (this.isGolf) {
			inactiveProgramGroupIds = [...new Set(
					// Map the running program change values into programGroupId values, via the this.programs list we
					// keep updated. Items which are not found in our this.programs list are ignored.
					inactivePrograms.map(runningProgram => {
						const programListItem = this.programManager.cachedPrograms.find(listItem => listItem.id === runningProgram.programId);
						if (programListItem != null) {
							return programListItem.programGroupId;
						} else {
							return 0;
						}
					})
				)];
		}

		for (const key of Object.keys(this.programGroupActivityDict)) {
			if (!this.programGroupActivityDict[key]) continue;
			const status = this.programGroupActivityDict[key] as ProgramChange;
			if (RbUtils.Programs.isProgramInactiveStatus(status.changeType)) {
				if (!this.isGolf || inactiveProgramGroupIds.some(pgId => pgId === status.programGroupId)) {
					status.programGroupName = this.getProgramGroupName(status.programGroupId);
					inactiveProgramGroups.push(status);
				}
			}
		}

		return inactiveProgramGroups;
	}

	/**
	 * Return the SensorStatus object describing the last-received sensor status for the indicated sensorId. If no
	 * status is known, an "empty" SensorStatus object will be returned.
	 * @param sensorId number sensor Id for which to get status.
	 * @returns SensorStatus object describing the status (or an empty object, if no status has been received yet)
	 */
	public getGolfSensorStatus(sensorId: number): SensorStatus {
		const ret = new SensorStatus();
		// Handle null and undefined basically the same.
		if (this.sensorActivityDict == null || this.sensorActivityDict[sensorId] == null) {
			// Return empty object.
			ret.sensorStatusItem = null;
			ret.status = '-';
			return ret;
		} else {
			ret.sensorStatusItem = this.sensorActivityDict[sensorId];
			ret.status = '';
		}
		return ret;
	}

}

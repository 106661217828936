import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-program-status-cell-renderer',
	templateUrl: './program-status-cell-renderer.component.html',
	styleUrls: ['./program-status-cell-renderer.component.scss']
})
export class ProgramStatusCellRendererComponent implements ICellRendererAngularComp {

	_statusString: string;

	/**
	 * isRunning is not a true indication of program running. It's an indication that the "hot" style should
	 * be applied to the status value. Could be "Paused" (or the French equivalent), or "Running", etc.
	 */
	isRunning = false;

	constructor(protected translate: TranslateService,
	) { }

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.updateProgramStatus(params);
	}

	refresh(params: any): boolean {
		this.updateProgramStatus(params);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	get statusString(): string {
		return this._statusString;
	}

	private updateProgramStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		this._statusString = params.value;
		this.isRunning = false;

		switch (this._statusString) {
			case '-':
				if (rowNode.data.isEnabled === false)
					this._statusString = this.translate.instant('STRINGS.DISABLED');
				break;
			case this.translate.instant('STRINGS.IDLE'):
				break;

			// We want to highlight any status except "-".
			default:
				this.isRunning = true;	// Show the "hot" styling.
				params.api.flashCells({
					rowNodes: [rowNode],
					columns: ['status']
				});
				break;
		}
	}

}

import { RbUtils } from '../../../common/utils/_rb.utils';

export class UniversalDeviceResponseStatuses {

	constructor(json: any = null, controllerId: number, changeDateTime: string) {
		if (json) {
			Object.assign(this, json);
		}

		this.changeDateTime = RbUtils.Conversion.convertStringToDate(changeDateTime);
		this.controllerId = controllerId;
	}

	controllerId: number;
	changeDateTime: Date;
	flowSensor: {};
	masterValve: {};
	station: {};
	weatherSensor: {};
}

import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
	selector: 'rb-carousel-indicator',
	templateUrl: './carousel-indicator.component.html',
	styleUrls: ['./carousel-indicator.component.scss']
})
export class CarouselIndicatorComponent {
	@HostBinding('class') class = 'rb-carousel-indicator';

	@Output() selected = new EventEmitter<number>();

	@Input() isSelected = false;
	@Input() itemIndex: number;
	@Input() totalItems: number;

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onSelect() {
		if (this.isSelected) { return; }

		this.isSelected = !this.isSelected;
		this.selected.emit(this.itemIndex);
	}
}

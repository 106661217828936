import { RbUtils } from "../../../common/utils/_rb.utils";

export class ScheduledProgramTreeViewItem {
	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.startTime) { this.startTime = RbUtils.Conversion.convertStringToDate(json.startTime); }

			this.name = this.siteName;
		}
	}

	name: string;
	siteName: string;
	siteId: number;
	satelliteId: number;
	satelliteName: string;
	programId: number;
	startTime: Date;
}
<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<mat-form-field class="front-back-dropdown" 
	[class.white-background]="isWhiteBackground" 
	[class.multi-item-selection]="selectedItems?.length > 0" 
	[class.flat]="flat"
	[class.label-left-justified]="isLeftJustified"
	[class.compressed]="isCompressed"
	>

	<mat-select #matSelect
		[placeholder]="label"
		multiple="true"
		#dataSelect="ngModel"
		[panelClass]="'multiselect-dropdown' + (isAreas ? ' areas' : '')"
		[compareWith]="equals"
		[ngModel]="selectedItems"
		(ngModelChange)="handleChange($event)"
		(closed)="onSelectClosed()"
		data-qaid="front-back-dropdown"
	>

		<div *ngIf="!isAreas"
			disabled="disabled"
			class="mat-option db mat-select-all-options">
			<button
				class="fl"
				[ngClass]="{'wrap-button': wrapButtons}"
				mat-button
				color="primary"
				type="button"
				(click)="frontNineClicked(dataArray)"
				data-qaid="FBD-front-nine-button">
				{{'STRINGS.FRONT_NINE_UPPERCASE' | translate}}
			</button>
			<button
				class="fl"
				[ngClass]="{'wrap-button': wrapButtons}"
				mat-button
				color="primary"
				type="button"
				(click)="backNineClicked(dataArray)"
				data-qaid="FBD-back-nine-button">
				{{'STRINGS.BACK_NINE_UPPERCASE' | translate}}
			</button>
			<button *ngIf="extraDataArray != null && extraDataArray.length > 0"
				class="fl"
				[ngClass]="{'wrap-button': wrapButtons}"
				mat-button
				color="primary"
				type="button"
				(click)="placesClicked(extraDataArray)"
				data-qaid="FBD-places-button">
				{{'STRINGS.PLACES_UPPERCASE' | translate}}
			</button>
			<button
				class="fr"
				[ngClass]="{'wrap-button': wrapButtons}"
				mat-button
				color="primary"
				type="button"
				(click)="deselectAll()"
				data-qaid="FBD-clear-button">
				{{'STRINGS.CLEAR_UPPERCASE' | translate}}
			</button>
		</div>

		<mat-select-trigger class="mat-select-trigger">
			<span *ngIf="!isAreas">{{selectedItems && selectedItems.length > 0 ? (selectedItems[0].isGolfArea ? selectedItems[0].number : formatAreaName(selectedItems[0])) : ''}}</span>
			<span *ngIf="isAreas">{{selectedItems && selectedItems.length > 0 ? formatAreaName(selectedItems[0]) : ''}}</span>
			<span *ngIf="selectedItems && selectedItems.length > 1">
				(+{{selectedItems.length - 1}} more)
			</span>
		</mat-select-trigger>

		<!-- Holes -->
		<div class="area-selection-container">
			<div *ngIf="!isAreas" class="area-selection">
				<div class="area-list">
					<div *ngFor="let item of frontNine" class="area-item">
						<mat-option [value]="item" attr.data-qaid="FBD-hole-CB-{{item.number}}">{{item.number}}</mat-option>
					</div>
				</div>
				<div class="area-list">
					<div *ngFor="let item of dataArray" class="area-item">
						<mat-option *ngIf="item.number > 9 && item.number < 19" [value]="item" attr.data-qaid="FBD-hole-CB-{{item.number}}">{{item.number}}</mat-option>
					</div>
				</div>
				<div *ngIf="extraDataArray != null && extraDataArray.length > 0" class="area-list places">
					<div *ngFor="let item of extraDataArray" class="area-item">
						<mat-option [value]="item" attr.data-qaid="FBD-hole-CB-{{item.name | replaceWhitespace: '_' }}">{{formatAreaName(item)}}</mat-option>
					</div>
				</div>
				<div class="area-list">
					<div *ngFor="let item of dataArray" class="area-item">
						<mat-option *ngIf="item.number >= 19" [value]="item" attr.data-qaid="FBD-hole-CB-{{item.number}}">{{item.number}}</mat-option>
					</div>
				</div>
			</div>

			<!-- Areas -->
			<div *ngIf="isAreas" class="area-selection areas">
				<div *ngFor="let item of dataArray">
					<mat-option [value]="item" attr.data-qaid="FBD-area-CB-{{item.name | replaceWhitespace: '_' }}">{{formatAreaName(item)}}</mat-option>
				</div>
			</div>
		</div>

		<div class="mat-option flex">
			<div *ngIf="showSelectionCount" class="pa3 mat-form-field-label">{{selectedItems ? selectedItems.length : 0 }} / {{dataArray?.length}}</div>
			<button
				mat-raised-button
				class="fr"
				color="primary"
				type="button"
				(click)="matSelect.close(); onDoneClick()"
				data-qaid="FBD-done-button">
				{{'STRINGS.DONE' | translate}}
			</button>
		</div>
	</mat-select>
</mat-form-field>

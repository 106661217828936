import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ProgramProjectedTime } from '../../../../../api/programs/models/program-projected-time.model';

@Component({
	selector: 'rb-dry-run-schedule-mobile-cell-renderer',
	templateUrl: './dry-run-schedule-mobile-cell-renderer.component.html',
	styleUrls: ['./dry-run-schedule-mobile-cell-renderer.component.scss']
})
export class DryRunScheduleMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	programProjectedTime: ProgramProjectedTime;
	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.programProjectedTime = params.data;
	}
}

import { Control } from 'leaflet';
import { IStation } from './station.interface';
import { MapInfoLeaflet } from './map-info-leaflet.model';
import { MultiSelectService } from '../services/multi-select.service';
import { Observable } from 'rxjs';
import { RbEnums } from '../enumerations/_rb.enums';
import { StationWithMapInfoLeaflet } from './station-with-map-info-leaflet.model';
import { TranslateService } from '@ngx-translate/core';

export class MultiSelectModeControl {

	static create(
		mapInfo: MapInfoLeaflet,
		translate: TranslateService,
		parentDiv: HTMLElement,
		isGolfSite: boolean,
		stationSelectionChange: Observable<IStation[]> = null,
		multiSelectService: MultiSelectService
	) {
		const ua = navigator.userAgent;
		const isIos = ua.match(/iPhone/i) || ua.match(/iPad/i);
		const event = (isIos ? 'touchstart' : 'click');
		let timeStamp;

		// Set CSS for the control border.
		const multiControlUI = document.createElement('div');
		multiControlUI.className = 'rb-leaflet-control';
		multiControlUI.title = translate.instant('STRINGS.MULTISELECT_MODE_ON');
		multiControlUI.id = 'multi-select-button';
		multiControlUI.style.zIndex = '2';
		parentDiv.appendChild(multiControlUI);

		const icon = document.createElement('i');
		icon.classList.add('mdi');
		icon.classList.add('mdi-checkbox-multiple-marked-circle-outline');
		icon.id = 'multi-select-icon';
		multiControlUI.appendChild(icon);

		multiControlUI.addEventListener(event, function (e) {
			if (timeStamp == null || timeStamp + 500 < e.timeStamp) {
				mapInfo.toggleMultiSelectMode();
				timeStamp = e.timeStamp;
    	}
		});

		const actionBarDiv = document.createElement('div');
		actionBarDiv.classList.add('rb-control-bar');
		actionBarDiv.classList.add(isGolfSite ? 'action-bar-4-items' : 'action-bar-2-items');
		actionBarDiv.id = 'multi-select-action-bar';

		const actionBarChild1 = document.createElement('div');
		actionBarChild1.classList.add('action-container');
		actionBarDiv.appendChild(actionBarChild1);
		
		const actionBarChild2 = document.createElement('div');
		actionBarChild2.classList.add('action-container');
		
		const actionBarChild3 = document.createElement('div');
		actionBarChild3.classList.add('action-container');
		
		const actionBarChild4 = document.createElement('div');
		actionBarChild4.classList.add('badge-container');

		const startControlUI = document.createElement('button');
		startControlUI.className = 'rb-leaflet-control';
		startControlUI.title = translate.instant('STRINGS.MULTISELECT_START_HINT');
		const startIcon = document.createElement('i');
		startIcon.classList.add('mdi');
		startIcon.classList.add('mdi-play');
		startControlUI.appendChild(startIcon);
		startControlUI.addEventListener(event, function() { mapInfo.startSelectedStations(); });
		actionBarChild1.appendChild(startControlUI);
		
		// available options for golf are: start stop resume pause
		// available options for iq4 are: start stop
		if (isGolfSite) {
			const stopControlUI = document.createElement('button');
			stopControlUI.className = 'rb-leaflet-control';
			stopControlUI.title = translate.instant('STRINGS.MULTISELECT_STOP_HINT');
			const stopIcon = document.createElement('i');
			stopIcon.classList.add('mdi');
			stopIcon.classList.add('mdi-stop');
			stopControlUI.appendChild(stopIcon);
			stopControlUI.addEventListener(event, function() { mapInfo.stopSelectedStations(); });
			actionBarChild1.appendChild(stopControlUI);

			const pauseControlUI = document.createElement('button');
			pauseControlUI.className = 'rb-leaflet-control';
			pauseControlUI.title = translate.instant('STRINGS.MULTISELECT_PAUSE_HINT');
			const pauseIcon = document.createElement('i');
			pauseIcon.classList.add('mdi');
			pauseIcon.classList.add('mdi-pause');
			pauseControlUI.appendChild(pauseIcon);
			pauseControlUI.addEventListener(event, function() { mapInfo.pauseSelectedStations(); });
			actionBarChild1.appendChild(pauseControlUI);
	
			const resumeControlUI = document.createElement('button');
			resumeControlUI.className = 'rb-leaflet-control';
			resumeControlUI.title = translate.instant('STRINGS.MULTISELECT_RESUME_HINT');
			resumeControlUI.id = 'multi-station-selection-resume-button-001';
			resumeControlUI.disabled = true;
			const resumeIcon = document.createElement('i');
			resumeIcon.classList.add('icon-resume');
			resumeControlUI.appendChild(resumeIcon);
			resumeControlUI.addEventListener(event, function() { mapInfo.resumeSelectedStations(); });
			actionBarChild1.appendChild(resumeControlUI);

			actionBarDiv.appendChild(actionBarChild2);
			const batchEditUI = document.createElement('button');
			batchEditUI.className = 'rb-leaflet-control';
			batchEditUI.id = 'edit-selected-stations';
			batchEditUI.title = translate.instant('STRINGS.MULTISELECT_BATCH_EDIT_HINT');
			const batchEditIcon = document.createElement('i');
			batchEditIcon.classList.add('mdi');
			batchEditIcon.classList.add('mdi-pencil');
			batchEditUI.appendChild(batchEditIcon);
			batchEditUI.addEventListener(event, function() { mapInfo.batchEditSelectedStations(); });
			actionBarChild2.appendChild(batchEditUI);

			const diagnosticsControlUI = document.createElement('button');
			diagnosticsControlUI.className = 'rb-leaflet-control';
			diagnosticsControlUI.title = translate.instant('STRINGS.MULTISELECT_DIAGNOSTICS_HINT');
			const diagnosticsIcon = document.createElement('i');
			diagnosticsIcon.classList.add('mdi');
			diagnosticsIcon.classList.add('mdi-help-network-outline');
			diagnosticsControlUI.appendChild(diagnosticsIcon);
			diagnosticsControlUI.addEventListener(event, function() { mapInfo.diagnosticsSelectedStations(); });
			actionBarChild2.appendChild(diagnosticsControlUI);

		} else {
			const stopControlUI = document.createElement('button');
			stopControlUI.className = 'rb-leaflet-control';
			stopControlUI.title = translate.instant('STRINGS.MULTISELECT_STOP_HINT');
			const stopIcon = document.createElement('i');
			stopIcon.classList.add('mdi');
			stopIcon.classList.add('mdi-stop');
			stopControlUI.appendChild(stopIcon);
			stopControlUI.addEventListener(event, () => { mapInfo.stopAllSelectedStations(); });
			actionBarChild1.appendChild(stopControlUI);
		}

		actionBarDiv.appendChild(actionBarChild3);
		const reselectControlUI = document.createElement('button');
		reselectControlUI.setAttribute('disabled', 'true');
		reselectControlUI.className = 'rb-leaflet-control';
		reselectControlUI.id = 'btn-reselect-stations';
		reselectControlUI.title = translate.instant('STRINGS.REPEAT_LAST_SELECTION');
		const reselectIcon = document.createElement('i');
		reselectIcon.classList.add('mdi');
		reselectIcon.classList.add('mdi-backup-restore');
		reselectControlUI.appendChild(reselectIcon);
		reselectControlUI.addEventListener(event, function() { mapInfo.repeatLastSelection(); });
		actionBarChild3.appendChild(reselectControlUI);

		actionBarDiv.appendChild(actionBarChild4);
		const badgeContainerUI = document.createElement('div');
		badgeContainerUI.className = 'rb-leaflet-badge';
		badgeContainerUI.title = translate.instant('STRINGS.SELECTED_STATIONS');
		const counterBadge = document.createElement('span');
		counterBadge.classList.add('mat-badge-content');
		counterBadge.classList.add('mat-badge-active');
		counterBadge.id = 'multi-select-counter';
		counterBadge.innerHTML = '0';
		badgeContainerUI.appendChild(counterBadge);
		
		actionBarChild4.appendChild(badgeContainerUI);

		parentDiv.appendChild(actionBarDiv);

		parentDiv.className = 'rb-multiselect-container';

		const MultiSelectControl = Control.extend({
			onAdd: function (map) {
				return parentDiv;
			},
		});

		if (stationSelectionChange != null){
			stationSelectionChange.subscribe((selectedStations) => {
				this.setResumeDisabled(
					selectedStations.map(x => x.station),
					multiSelectService
				);
			});
		}
		
		return new MultiSelectControl({ position: 'bottomleft' });
	}

	static toggle(
		translate: TranslateService, 
		isActive: boolean, 
		multiSelectService: MultiSelectService
	): void {
		const button = document.getElementById('multi-select-button');
		const icon = document.getElementById('multi-select-icon');
		const actionBar = document.getElementById('multi-select-action-bar');

		if (button == null) return;
		if (icon == null) return;
		if (actionBar == null) return;

		if (isActive) {
			console.log('Multiselect mode enabled...');
			button.title = translate.instant('STRINGS.MULTISELECT_MODE_OFF');
			button.classList.add('dangerColor');
			icon.classList.replace('mdi-checkbox-multiple-marked-circle-outline', 'mdi-close');
			actionBar.classList.add('active');
		} else {
			console.log('Multiselect mode disabled...');
			actionBar.classList.remove('active');
			button.title = translate.instant('STRINGS.MULTISELECT_MODE_ON');
			button.classList.remove('dangerColor');
			icon.classList.replace('mdi-close', 'mdi-checkbox-multiple-marked-circle-outline');
		}

		this.toggleResumeButtonDisabled(true, multiSelectService);
	}

	static updateCounter(numberStations) {
		const counter = document.getElementById('multi-select-counter');
		if(counter){
			counter.innerHTML = numberStations;
		}
	}

	//
	// Private methods
	//

	private static setResumeDisabled(
		selectedStations: StationWithMapInfoLeaflet[],
		multiSelectService: MultiSelectService
	): void {
		var notPausedStation = selectedStations.find(x => x.irrigationStatus !== RbEnums.Common.IrrigationStatus.Paused);

		this.toggleResumeButtonDisabled(
			notPausedStation != null, 
			multiSelectService
		);
	}

	private static toggleResumeButtonDisabled(
		isDisabled: boolean, 
		multiSelectService:MultiSelectService
	): void {
		const resumeButton = <HTMLButtonElement>document.getElementById('multi-station-selection-resume-button-001');
		if (resumeButton == null) return;

		resumeButton.disabled = isDisabled;
		multiSelectService.setDisableResume(isDisabled);
	}

	static disableReselectButton(disable: boolean) {
		const button = <HTMLButtonElement>document.getElementById('btn-reselect-stations');
		button.disabled = disable;
	}
}

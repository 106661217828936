import { EventEmitter } from '@angular/core';
import { IIrrigationEngineStationStatusItem } from '../../../api/signalR/_irrigation-engine-station-status-item.interface';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

import IrrigationEventSource = RbEnums.Common.IrrigationEventSource;

export class IrrigationQueueItem implements IIrrigationEngineStationStatusItem {

	// Events
	secondsRemainingChange = new EventEmitter<number>();
	stoppingIrrigation = new EventEmitter();

	private _timerRef;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null, irrigationState: string = null) {
		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			if ((!irrigationState || irrigationState !== 'LearnedFlow') && this.showTimeRemaining && this.secondsRemaining && this.secondsRemaining > 0) {
				this.startCountdownTimer();
			}
		}
	}

	additionalTime = 0;
	canAdvance = false;
	isPending = false;
	program: number;
	source: IrrigationEventSource;
	stationNumber: number;
	stationState = RbEnums.Common.StationStatus.Idle;

	// secondsRemaining will contain the seconds remaining for the current irrigation state. I.e., it will be the number of
	// soak seconds remaining when soaking and the number of cycle seconds remaining when cycling. This is unlike Golf, which uses separate variables.
	secondsRemaining: number;

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	stopIrrigation() {
		if (this._timerRef) {
			clearInterval(this._timerRef);
		}
		this.stoppingIrrigation.next(null);
	}

	get sourceDescription() {
		switch (this.source) {
			case IrrigationEventSource.ManualTest:
			case IrrigationEventSource.CentralTest:
				return RbUtils.Translate.instant('STRINGS.MANUAL_TEST');
			case IrrigationEventSource.RemoteTest:
				return RbUtils.Translate.instant('STRINGS.REMOTE_TEST');
			case IrrigationEventSource.ManualValve:
			case IrrigationEventSource.CentralValve:
				return RbUtils.Translate.instant('STRINGS.MANUAL_STATION');
			case IrrigationEventSource.RemoteValve:
				return RbUtils.Translate.instant('STRINGS.REMOTE_STATION');
			case IrrigationEventSource.ManualProgram:
			case IrrigationEventSource.CentralProgram:
				return RbUtils.Translate.instant('STRINGS.MANUAL_PROGRAM');
			case IrrigationEventSource.RemoteProgram:
				return RbUtils.Translate.instant('STRINGS.REMOTE_PROGRAM');
			case IrrigationEventSource.AutoProgram:
				return RbUtils.Translate.instant('STRINGS.AUTO_PROGRAM');
			case IrrigationEventSource.Unknown:
			default:
				return RbUtils.Translate.instant('STRINGS.UNKNOWN');
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	// Method to start secondsRemaining countdown timer. This timer will tick down the remaining seconds in between receiving new ConnectDataPacks
	// via SignalR. The event fired within the timer will be caught by StationListItems and ProgramStepListItems to update the time remaining in the UI.
	private startCountdownTimer() {
		if (this._timerRef) clearInterval(this._timerRef);

		this._timerRef = setInterval(() => {
			if (this.secondsRemaining - 1 >= 0) {
				--this.secondsRemaining;
				this.secondsRemainingChange.emit(this.secondsRemaining);
			} else {
				clearInterval(this._timerRef);
			}
		}, 1000);
	}

	private get showTimeRemaining(): boolean {
		return this.stationState === RbEnums.Common.StationStatus.On || this.stationState === RbEnums.Common.StationStatus.Soaking;
	}
}

import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class UserInfo {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}
	
	email?: string;
	groupLevel?: RbEnums.Common.GroupLevel;
	id: number | string;
	mobileNumber?: string;
	name: string;
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-dialog
	[title]="'SPECIAL_MSG.SELECT_ITEMS_TO_BATCH_EDIT' | translate"
	[closeButtonLabel]="'STRINGS.CANCEL' | translate "
	[submitButtonLabel]="'STRINGS.DONE' | translate"
	[showSubmitButton]="true"
	[closeOnSubmit]="true"
	(onSubmit) = "onBatchEditSubmitted()"
	(onCancel)="onBatchEditCanceled()"
	[saveDisabled]="!form.valid"
>
	<form #form="ngForm" class="flex flex-column overflow-y-hidden ph1 pv3">
		<div class="fl w-100 f5 pv1" *ngFor="let option of batchOptions">
			<mat-checkbox [(ngModel)]="option.selected" name="option" color="primary">{{option.description}}</mat-checkbox>
		</div>
	</form>
	<div class="under-line"></div>
</rb-dialog>

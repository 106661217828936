import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { Site } from '../../../../../api/sites/models/site.model';

@Component({
	selector: 'rb-site-mobile-cell-renderer',
	templateUrl: './site-mobile-cell-renderer.component.html',
	styleUrls: ['./site-mobile-cell-renderer.component.scss']
})
export class SiteMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	site: Site;

	constructor(injector: Injector,
	) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.site = params.data;
	}
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { LxdPingTestItem } from '../../../../../sections/controllers/manual-ops/manual-ops-diagnostics-tab/models/lxd-ping-test-item.model';

@Component({
	selector: 'rb-ping-decoder-mobile-cell-renderer',
	templateUrl: './ping-decoder-mobile-cell-renderer.component.html',
	styleUrls: ['./ping-decoder-mobile-cell-renderer.component.scss']
})
export class PingDecoderMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	pingTestItem: LxdPingTestItem;

	constructor(public appDateAdapter: AppDateAdapter,
				injector: Injector
	) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.pingTestItem = params.data;
	}

}

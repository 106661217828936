/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Controllers.isGolfController
 * 		 See: _rb.utils.ts
 */

import * as moment from 'moment';
import { CommInterface } from '../../api/controllers/models/comm-interface.model';
import { Controller } from '../../api/controllers/models/controller.model';
import { ControllerListItem } from '../../api/controllers/models/controller-list-item.model';
import { ControllerModuleType } from '../../api/modules/models/controller-module-type.model';
import { ControllerSyncMode } from '../../api/controllers/models/controller-sync-mode.model';
import { RbConstants } from '../constants/_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from './_rb.utils';

export namespace XXUseRbUtilsNamespace {

	export abstract class Controllers {

		// This function checks special rules for module types. Note: it is assumed that only legal module types for the controller
		// are passed in (those retrieved from getSupportedModulesForControllerTypeAndSlot)
		static isModuleAllowedInSlot(controllerType: RbEnums.Common.DeviceType, moduleType: number, slot: number, existingModules: ControllerModuleType[]) {

			if (controllerType === RbEnums.Common.DeviceType.LXD) {
				if (slot === 0) return true;

				let otherLXMDecoderModules = 0;
				let otherMRRModules = 0;
				// let otherLXMDecoderModuleExpansions = 0;
				for (let iExistingModule = 0; iExistingModule < existingModules.length; iExistingModule++) {
					if (iExistingModule === slot) continue;
					const existingModule = existingModules[iExistingModule];
					if (existingModule == null) continue;
					if (existingModule.value === RbEnums.Common.DeviceType.LXMDecoderModule) otherLXMDecoderModules++;
					if (existingModule.value === RbEnums.Common.DeviceType.TBOSMRR) otherMRRModules++;
					// if (existingModule.value === RbEnums.Common.DeviceType.LXMDecoderModuleExpansion) otherLXMDecoderModuleExpansions++;
				}

				// Allow maximum of 1 LXMDecoderModule
				if (moduleType === RbEnums.Common.DeviceType.LXMDecoderModule && otherLXMDecoderModules > 0) return false;

				// Allow LXMDecoderModule in slots 1 and 2 only
				if (moduleType === RbEnums.Common.DeviceType.LXMDecoderModule && (slot === 3 || slot === 4)) return false;

				// Only allow LXMDecoderModuleExpansion after a LXMDecoderModule
				if (moduleType === RbEnums.Common.DeviceType.LXMDecoderModuleExpansion && otherLXMDecoderModules === 0) return false;

				// Allow maximum of 2 LXMDecoderModuleExpansion
				if (moduleType === RbEnums.Common.DeviceType.LXMDecoderModuleExpansion && otherLXMDecoderModules > 1) return false;

				// Allow maximum of 1 MRRModuleExpansion
				if (moduleType === RbEnums.Common.DeviceType.TBOSMRR && otherMRRModules > 0) return false;

				return true;
			}

			if (controllerType === RbEnums.Common.DeviceType.LXME || controllerType === RbEnums.Common.DeviceType.LXME2) {
				if (slot === 0) return true;

				let otherMRRModules = 0;
				for (let iExistingModule = 0; iExistingModule < existingModules.length; iExistingModule++) {
					if (iExistingModule === slot) continue;
					const existingModule = existingModules[iExistingModule];
					if (existingModule == null) continue;
					if (existingModule.value === RbEnums.Common.DeviceType.TBOSMRR) otherMRRModules++;
				}

				// Allow maximum of 1 MRRModuleExpansion
				if (moduleType === RbEnums.Common.DeviceType.TBOSMRR && otherMRRModules > 0) return false;

				return true;
			}

			if (controllerType === RbEnums.Common.DeviceType.LXIVM || controllerType === RbEnums.Common.DeviceType.LXIVMPlus) {
				if (slot === 0) return true;

				let otherIcsDecoderModules = 0;
				let otherMRRModules = 0;
				for (let iExistingModule = 0; iExistingModule < existingModules.length; iExistingModule++) {
					if (iExistingModule === slot) continue;
					const existingModule = existingModules[iExistingModule];
					if (existingModule == null) continue;
					if (existingModule.value === RbEnums.Common.DeviceType.ICSDecoderModule) otherIcsDecoderModules++;
					if (existingModule.value === RbEnums.Common.DeviceType.TBOSMRR) otherMRRModules++;
				}

				// Allow maximum of 1 ICSDecoderModule
				if (moduleType === RbEnums.Common.DeviceType.ICSDecoderModule && otherIcsDecoderModules > 0) return false;

				// Allow LXMDecoderModule in slots 1 and 2 only
				if (moduleType === RbEnums.Common.DeviceType.ICSDecoderModule && (slot === 3 || slot === 4)) return false;

				// Allow maximum of 1 MRRModuleExpansion
				if (moduleType === RbEnums.Common.DeviceType.TBOSMRR && otherMRRModules > 0) return false;

				return true;
			}

			return true;
		}

		/**
		 * Return an indicator of whether the module is allowed to add in the UI.
		 * @param iqNetType iqNetType - iqNetType of controller to check
		 * @param moduleType moduleType - type of module to check
		 */
		static isModuleAllowedByIqNetType(iqNetType: RbEnums.Common.IqNetType, moduleType: number) {
			if (iqNetType !== RbEnums.Common.IqNetType.IQNetServer && this.isMRRModule(moduleType)) {
				return false;
			}
			return true;
		}

		static getComPort(connectionTypeId): string {
			if ((connectionTypeId != null) && connectionTypeId.includes(RbConstants.Form.COM_PORT)) {
				return connectionTypeId.split('|').pop().trim();
			}

			return '';
		}
		static hasValidConnectionSettings(controller: Controller, value: CommInterface) {
			let isValid = false;
			const commInterface = controller.commInterface[0];
			if (value) {
				if (value.type) commInterface.type = value.type;
				if (value.phoneNumber) commInterface.phoneNumber = value.phoneNumber;
				if (value.comPort) commInterface.comPort = value.comPort;
				if (value.ipString) commInterface.ipString = value.ipString;
				if (value.ipPort) commInterface.ipPort = value.ipPort;
			}
			switch (commInterface.type) {
				case RbEnums.Common.CommInterfaceType.Modem:
					if ((commInterface.phoneNumber != null) && (commInterface.phoneNumber.length > 0)) {
						isValid = true;
					}
					break;
				case RbEnums.Common.CommInterfaceType.Serial:
					if ((commInterface.comPort != null) && (commInterface.comPort.length > 0)) {
						isValid = true;
					}
					break;
				case RbEnums.Common.CommInterfaceType.Ethernet:
					if ((commInterface.ipString != null) && (commInterface.ipString.length > 6)) {
						isValid = true;
					}
					break;
				case RbEnums.Common.CommInterfaceType.Radio:
					if ((commInterface.comPort != null) && (commInterface.comPort.length > 0)) {
						isValid = true;
					}
					break;
				case RbEnums.Common.CommInterfaceType.Client:
					if (controller.parentId != null) {
						isValid = true;
					} else {
						isValid = true;
					}
					break;
			}
			return isValid;
		}
		static hideStationAddress(controllerType: RbEnums.Common.DeviceType): boolean {
			return (RbUtils.Common.isLxmeTypeController(controllerType));
		}

		static isCCUController(type: number): boolean {
			switch (type) {
				case RbEnums.Common.DeviceType.ESPSITE:
				case RbEnums.Common.DeviceType.CCU6WIRED:
				case RbEnums.Common.DeviceType.CCU6LINK:
				case RbEnums.Common.DeviceType.CCU28WIRED:
				case RbEnums.Common.DeviceType.CCU28LINK:
					return true;
				default:
					return false;
			}
		}

		static isContractorSBUController(type: RbEnums.Common.DeviceType): boolean{
			const DeviceType = RbEnums.Common.DeviceType;
			return [DeviceType.ESPME3,
				DeviceType.ARC8,
				DeviceType.RC2_4Station,
				DeviceType.RC2_6Station,
				DeviceType.RC2_8Station,
				DeviceType.ESP2Wire,
				DeviceType.TM2_4Station,
				DeviceType.TM2_6Station,
				DeviceType.TM2_8Station,
				DeviceType.TM2_12Station
			].includes(type);
		}

		static isDecoderModule(type: number) {
			switch (type) {
				case RbEnums.Common.DeviceType.LXMDecoderModule:
				case RbEnums.Common.DeviceType.ICSDecoderModule:
					return true;
				default:
					return false;
			}
		}

		static isMRRModule(type: number) {
			return type === RbEnums.Common.DeviceType.TBOSMRR;
		}

		static isGolfController(type: number): boolean {
			switch (type) {
				case RbEnums.Common.DeviceType.MIM:
				case RbEnums.Common.DeviceType.MIM_LINK:
				case RbEnums.Common.DeviceType.LDISDI:
				case RbEnums.Common.DeviceType.ICI:
				case RbEnums.Common.DeviceType.PAR_ES:
				case RbEnums.Common.DeviceType.PARplus:
				case RbEnums.Common.DeviceType.MSCplus:
				case RbEnums.Common.DeviceType.ESP_MC:
				case RbEnums.Common.DeviceType.SensorDecoderTwoWire:
				case RbEnums.Common.DeviceType.PulseDecoderTwoWire:
				case RbEnums.Common.DeviceType.ESPSAT:
					return true;
				default:
					return false;
			}
		}

		static getSupportedModulesForControllerTypeAndSlot(type: number, isPrimarySlot: boolean): RbEnums.Common.DeviceType[] {
			switch (type) {
				case RbEnums.Common.DeviceType.LXME:
					return isPrimarySlot ? Controllers.supportedModules.lxm.base : Controllers.supportedModules.lxm.extension;
				case RbEnums.Common.DeviceType.LXME2:
					return isPrimarySlot ? Controllers.supportedModules.lxme2.base : Controllers.supportedModules.lxme2.extension;
				case RbEnums.Common.DeviceType.LXD:
					return isPrimarySlot ? Controllers.supportedModules.lxd.base : Controllers.supportedModules.lxd.extension;
				case RbEnums.Common.DeviceType.LXIVM:
				case RbEnums.Common.DeviceType.LXIVMPlus:
					return isPrimarySlot ? Controllers.supportedModules.lxivm.base : Controllers.supportedModules.lxivm.extension;
				case RbEnums.Common.DeviceType.ESPME3:
					return isPrimarySlot ? Controllers.supportedModules.espme.base : Controllers.supportedModules.espme.extension;
				default:
					return [];
			}
		}

		static get supportedModules(): any {
			return {
				lxm: {
					// Note: These integers are numerically out of order to give a proper Sort Order.  Don't move them.
					base: [
						RbEnums.Common.DeviceType.BaseModule,
						RbEnums.Common.DeviceType.FlowSmartModule,
						RbEnums.Common.DeviceType.FlowSmartConnectionModule
					],
					extension: [
						RbEnums.Common.DeviceType.LXMModule4,
						RbEnums.Common.DeviceType.LXMModule8,
						RbEnums.Common.DeviceType.LXMModule12,
						RbEnums.Common.DeviceType.TBOSMRR,
					]
				},
				lxme2: {
					// Note: These integers are numerically out of order to give a proper Sort Order.  Don't move them.
					base: [
						RbEnums.Common.DeviceType.BaseModule,
						RbEnums.Common.DeviceType.FlowSmartModule2,
						RbEnums.Common.DeviceType.FlowSmartConnectionModule2
					],
					extension: [
						RbEnums.Common.DeviceType.LXMModule12,
						RbEnums.Common.DeviceType.TBOSMRR,
					]
				},
				lxd: {
					base: [RbEnums.Common.DeviceType.ConnectionModule],
					extension: [
						RbEnums.Common.DeviceType.LXMDecoderModule,
						RbEnums.Common.DeviceType.LXMDecoderModuleExpansion,
						RbEnums.Common.DeviceType.TBOSMRR,
					]
				},
				lxivm: {
					base: [RbEnums.Common.DeviceType.ConnectionModule],
					extension: [
						RbEnums.Common.DeviceType.ICSDecoderModule,
						RbEnums.Common.DeviceType.TBOSMRR,
					]
				},
				espme: {
					base: [RbEnums.Common.DeviceType.BaseModule],
					extension: [
						RbEnums.Common.DeviceType.ESPSM3Module,
						RbEnums.Common.DeviceType.ESPSM6Module,
					]
				},
			};
		}

		static sortControllersList(s1: ControllerListItem, s2: ControllerListItem): number {
			// Sort by site name...
			if (s1.siteName > s2.siteName) { return 1; }
			if (s1.siteName < s2.siteName) { return -1; }

			// ...then by satellite name
			if (s1.name > s2.name) { return 1; }
			if (s1.name < s2.name) { return -1; }

			// or perhaps they are the same
			return 0;
		}

		static sortControllers(s1: Controller, s2: Controller): number {
			// Sort by site name...
			if (s1.site.name > s2.site.name) { return 1; }
			if (s1.site.name < s2.site.name) { return -1; }

			// ...then by satellite name
			if (s1.name > s2.name) { return 1; }
			if (s1.name < s2.name) { return -1; }

			// or perhaps they are the same
			return 0;
		}

		static checkTwoWireController(type: RbEnums.Common.DeviceType): boolean {
			return [
				RbEnums.Common.DeviceType.LXD,
				RbEnums.Common.DeviceType.LXIVM,
				RbEnums.Common.DeviceType.LXIVMPlus
			].includes(type);
		}

		static getDisplayName(controllerType: RbEnums.Common.DeviceType, isLinkInterface:boolean = false): string {
			// TODO: RBCC - We should centralize the names of these devices. The Api has resources for them. Seems like
			// we should use them.
			switch (controllerType) {
				case RbEnums.Common.DeviceType.LXIVM: {
					return RbUtils.Translate.instant('STRINGS.LX_IVM');
				}
				case RbEnums.Common.DeviceType.LXIVMPlus: {
					return RbUtils.Translate.instant('STRINGS.LX_IVM_PRO');
				}
				case RbEnums.Common.DeviceType.ARC8: {
					return RbUtils.Translate.instant('STRINGS.ESP_ISK_ARC8');
				}
				case RbEnums.Common.DeviceType.RC2_4Station: {
					return RbUtils.Translate.instant('STRINGS.ESP_ISK_RC2_4STATION');
				}
				case RbEnums.Common.DeviceType.RC2_6Station: {
					return RbUtils.Translate.instant('STRINGS.ESP_ISK_RC2_6STATION');
				}
				case RbEnums.Common.DeviceType.RC2_8Station: {
					return RbUtils.Translate.instant('STRINGS.ESP_ISK_RC2_8STATION');
				}
				case RbEnums.Common.DeviceType.ICI: {
						return RbUtils.Translate.instant('STRINGS.ICI');
				}
				case RbEnums.Common.DeviceType.MIM: {
					return RbUtils.Translate.instant('STRINGS.MIM');
				}
				case RbEnums.Common.DeviceType.MIM_LINK: {
					return RbUtils.Translate.instant('STRINGS.MIM-LINK');
				}

				case RbEnums.Common.DeviceType.PARplus: {
					return isLinkInterface ? RbUtils.Translate.instant('STRINGS.PARplus_LINK'): RbUtils.Translate.instant('STRINGS.PARplus');
				}

				case RbEnums.Common.DeviceType.MSCplus: {
					return isLinkInterface ?  RbUtils.Translate.instant('STRINGS.MSC_PLUS_LINK') :  RbUtils.Translate.instant('STRINGS.MSC_PLUS');
				}
				case RbEnums.Common.DeviceType.PAR_ES: {
					return isLinkInterface
						? RbUtils.Translate.instant('STRINGS.PAR_ES_LINK')
						: RbUtils.Translate.instant('STRINGS.PAR_ES');
				}
				case RbEnums.Common.DeviceType.ESP_MC: {
					return RbUtils.Translate.instant('STRINGS.ESP_MC');
				}
				case RbEnums.Common.DeviceType.ESPSAT: {
					return RbUtils.Translate.instant('STRINGS.ESP_SAT');
				}
				case RbEnums.Common.DeviceType.TBOS1: {
					return RbUtils.Translate.instant('TBOS.TBOS_1S_CTRL');
				}
				case RbEnums.Common.DeviceType.TBOS2: {
					return RbUtils.Translate.instant('TBOS.TBOS_2S_CTRL');
				}
				case RbEnums.Common.DeviceType.TBOS4: {
					return RbUtils.Translate.instant('TBOS.TBOS_4S_CTRL');
				}
				case RbEnums.Common.DeviceType.TBOS6: {
					return RbUtils.Translate.instant('TBOS.TBOS_6S_CTRL');
				}
				case RbEnums.Common.DeviceType.ESPME3: {
					return RbUtils.Translate.instant('STRINGS.ESP_ME3');
				}
				case RbEnums.Common.DeviceType.ESP2Wire: {
					return RbUtils.Translate.instant('STRINGS.ESP_2Wire');
				}
				case RbEnums.Common.DeviceType.TM2_12Station: {
					return RbUtils.Translate.instant('STRINGS.TM2_12STATION');
				}
				case RbEnums.Common.DeviceType.TM2_4Station: {
					return RbUtils.Translate.instant('STRINGS.TM2_4STATION');
				}
				case RbEnums.Common.DeviceType.TM2_6Station: {
					return RbUtils.Translate.instant('STRINGS.TM2_6STATION');
				}
				case RbEnums.Common.DeviceType.TM2_8Station: {
					return RbUtils.Translate.instant('STRINGS.TM2_8STATION');
				}
				case RbEnums.Common.DeviceType.PulseDecoderTwoWire: {
					return RbUtils.Translate.instant('STRINGS.PULSE_DECODER');
                }
				case RbEnums.Common.DeviceType.SensorDecoderTwoWire: {
					return RbUtils.Translate.instant('STRINGS.SENSOR_DECODER');
                }
					
				case RbEnums.Common.DeviceType.LDISDI: 
					return RbUtils.Translate.instant('STRINGS.LDISDI');
					
				default:
					return RbEnums.Common.DeviceType[controllerType];
			}
		}

		/**
		 * @summary Return array of firmware versions for the controller currently selected. This data comes from the database
		 * and is intended primarily for golf use, but should work for commercial if the data formats stored match.
		 * @returns string[] with main firmware version first, wire paths or subsidiary versions following. The data is formatted
		 * with titling for each item, so something like ["CPU: 3.26", "Wire group 1: 1.11"].
		 */
		static getControllerVersionStrings(controller: Controller): string[] {
			const noVersionKnownString = '---';

			let ret: string[] = [];
			ret.push(RbUtils.Translate.instant('STRINGS.INTERFACE_MAIN_VERSION',
				{
					version: (!controller.versionString || (+controller.versionString === 0)) ?
						noVersionKnownString :
						controller.versionString,
				}));

			// If this is an ICI, it will have a groupVersionsString property containing a comma-separated list of versions
			// by wire group from 1-n. Get those and push them onto the return value.
			if (controller['groupVersionsString']) {
				let groupVersions = (<string>controller['groupVersionsString']).split(',');

				// Adjust any missing versions to "---". Put the wire group number (index+1) in the heading text from
				// INTERFACE_WIREPATH_VERSION.
				groupVersions = groupVersions.map((item, index) =>
					RbUtils.Translate.instant('STRINGS.INTERFACE_WIREPATH_VERSION',
						{
							index: index + 1,
							version: (!item || (+item === 0)) ?
								noVersionKnownString :
								item,
						})
				);

				ret = ret.concat(groupVersions);
			}

			return ret;
		}

		static getFrontPanelStateFromSyncState(syncState: RbEnums.Common.ControllerSyncState): RbEnums.Common.FrontPanelState {
			switch (syncState) {
				case RbEnums.Common.ControllerSyncState.Synchronized:
					return RbEnums.Common.FrontPanelState.Synchronized;
				case RbEnums.Common.ControllerSyncState.NotSynchronized:
					return RbEnums.Common.FrontPanelState.OutOfSync;
				case RbEnums.Common.ControllerSyncState.Incomplete:
					return RbEnums.Common.FrontPanelState.Incomplete;
			}
		}

		static getSyncStateFromFrontPanelState(frontPanelState: RbEnums.Common.FrontPanelState): RbEnums.Common.ControllerSyncState {
			switch (frontPanelState) {
				case RbEnums.Common.FrontPanelState.Synchronized:
					return RbEnums.Common.ControllerSyncState.Synchronized;
				case RbEnums.Common.FrontPanelState.OutOfSync:
					return RbEnums.Common.ControllerSyncState.NotSynchronized;
				case RbEnums.Common.FrontPanelState.Incomplete:
					return RbEnums.Common.ControllerSyncState.Incomplete;
			}
		}

		static getSyncStateFromFrontPanelStateString(frontPanelState: RbEnums.SignalR.FrontPanelSyncState): RbEnums.Common.ControllerSyncState {
			switch (frontPanelState) {
				case RbEnums.SignalR.FrontPanelSyncState.Synchronized:
					return RbEnums.Common.ControllerSyncState.Synchronized;
				case RbEnums.SignalR.FrontPanelSyncState.OutOfSync:
					return RbEnums.Common.ControllerSyncState.NotSynchronized;
				case RbEnums.SignalR.FrontPanelSyncState.Incomplete:
					return RbEnums.Common.ControllerSyncState.Incomplete;
			}
		}

		static getDurationString(durationValue: any): string {
			let durationString = '';
			const inverseOffset = moment(new Date()).utcOffset() * -1;
			const utcMoment = moment().utcOffset(inverseOffset);
			const exp = moment(durationValue);

			const days = Math.abs(utcMoment.diff(exp, 'days'));
			if (days <= 0) {
				const hours = Math.abs(utcMoment.subtract(days, 'days').diff(exp, 'hours'));
				if (hours === 0) {
					const minutes = Math.abs(utcMoment.diff(exp, 'minutes'));
					durationString = (minutes === 1)
						? RbUtils.Translate.instant('STRINGS.MIN_AGO', { minutes })
						: RbUtils.Translate.instant('STRINGS.MINS_AGO', { minutes });
				} else
					durationString = (hours === 1)
						? RbUtils.Translate.instant('STRINGS.HR_AGO', { hours })
						: RbUtils.Translate.instant('STRINGS.HRS_AGO', { hours });
			} else
				durationString = (days === 1)
					? RbUtils.Translate.instant('STRINGS.DAY_AGO', { days })
					: RbUtils.Translate.instant('STRINGS.DAYS_AGO', { days });

			return durationString;
		}

		static getIqNetTypeShortDescription(iqNetType: RbEnums.Common.IqNetType, isSatelliteTbos: boolean) {
			switch (iqNetType) {
				case RbEnums.Common.IqNetType.IQNetClient:
					return RbUtils.Translate.instant('STRINGS.IQ_NET_TYPE_SHORT_CLIENT');
				case RbEnums.Common.IqNetType.IQNetServer:
					return RbUtils.Translate.instant('STRINGS.IQ_NET_TYPE_SHORT_SERVER');
				case RbEnums.Common.IqNetType.DirectSatellite:
					return isSatelliteTbos ? RbUtils.Translate.instant('STRINGS.IQ_NET_TYPE_SHORT_TBOS_CLIENT')
						: RbUtils.Translate.instant('STRINGS.IQ_NET_TYPE_SHORT_DIRECT_SATELLITE');
			}
		}

		/**
		* Define getting indicating whether a menu should be allowed for reprogramming a station. For example, when you select
		* an ICI, we want to be able to reprogram the ICM station fast-connect addresses. This method returns true when, based
		* on the parameters, that operation makes sense. Note that the caller may need to also determine if a set of **selected**
		* stations can be reprogrammed; for that we'd expect to check station.isConnected as well as this result before enabling
		* the reconnect operation.
		* @param controllerType? - RbEnums.Common.DeviceType of the station's parent controller. If null, false is returned.
		* @return - boolean true if controllers of the indicated type allow station reprogramming; false otherwise
		*/
		static getEnableStationReprogram(controllerType?: RbEnums.Common.DeviceType): boolean {
			return (controllerType != null && 
				(controllerType === RbEnums.Common.DeviceType.ICI || controllerType === RbEnums.Common.DeviceType.IQI));
		}

		/**
         * @param satellite - Should be a Satellite (PAR+ES, ESP_MC, etc.)
         * @param satellite - If should include the groupNumber in the returned format
         * @returns string similar to "1: PAR+ES" if includeGroup=true returns "1-1: PAR+ES"
         */
		static getSatelliteStringFormat(satellite: ControllerListItem, includeGroup: boolean = false): string {
			if (!satellite)  {
				console.warn('satellite does not have a valid value for getSatelliteGroupAddressFormat(), received: ', satellite);
				return '';
			}
			let format = includeGroup ? satellite.groupNumber + '-' : '';
			format += `${satellite.address}: ${satellite.name}`;
			return format;
		}

		static isDecoderInterface(controllerType: RbEnums.Common.DeviceType): boolean {
			if (controllerType === RbEnums.Common.DeviceType.LDISDI) return true;

			return false;
		}

		static isICSInterface(controllerType: RbEnums.Common.DeviceType): boolean {
			if (controllerType === RbEnums.Common.DeviceType.ICI) return true;

			return false;
		}

		/**
         * Return the string table reference which should be used when displaying the indicated wire path/group on a controller 
         * of the indicated type. For example, ICI's have "Wire Paths", so we might return 'STRINGS.WIREPATH_4' for groupNumber 4.
         * If groupNumber is null, we return a more-generic description of the group/path without a numeric value, 'STRINGS.WIREPATH'
         * for ICI. NOTE: We don't return the user-displayable string, just the lookup information in the string table. You must
         * still call RbUtils.Translate.instant() to get the right string.
         * @param controllerType - RbEnums.Common.DeviceType describing the parent which has these groups/paths.
         * @param groupNumber - number value indicating which group is to be described by the returned value.
         * @returns string describing the string table entry which should be translated to display the indicated wire path/group
         * to the user.
         */
        static getInterfaceWireGroupName(controllerType: RbEnums.Common.DeviceType, groupNumber?: number): string {
            switch(controllerType) {
                case RbEnums.Common.DeviceType.MIM:
                case RbEnums.Common.DeviceType.ICI:
                case RbEnums.Common.DeviceType.IQI:
                    switch(groupNumber) {
                        case 1:
                            return RbUtils.Translate.instant('STRINGS.WIREPATH_1');
                        case 2:
                            return RbUtils.Translate.instant('STRINGS.WIREPATH_2');;
                        case 3:
                            return RbUtils.Translate.instant('STRINGS.WIREPATH_3');;
                        case 4:
                            return RbUtils.Translate.instant('STRINGS.WIREPATH_4');;
						default:
							return RbUtils.Translate.instant('STRINGS.WIREPATH');;
                    }
                    break;
                case RbEnums.Common.DeviceType.MIM_LINK:
                    switch(groupNumber) {
                        case 1:
                            return RbUtils.Translate.instant('STRINGS.GROUP_1');
                        case 2:
                            return RbUtils.Translate.instant('STRINGS.GROUP_2');;
                        case 3:
                            return RbUtils.Translate.instant('STRINGS.GROUP_3');;
                        case 4:
                            return RbUtils.Translate.instant('STRINGS.GROUP_4');;
						default:
							return RbUtils.Translate.instant('STRINGS.GROUP');;

                    }
                    break;
				default:
					return '';
            }
            return '';
        }

		static getControllerSyncSetting(parentController: ControllerListItem, clientController: ControllerListItem): ControllerSyncMode {
			return {
				syncMode: parentController.syncMode === RbEnums.Common.AutoContactMode.Scheduled ? 
					parentController.syncMode : clientController.syncMode,
				upcomingAutoContactScheduledTime: parentController.syncMode === RbEnums.Common.AutoContactMode.Scheduled ? 
					parentController.upcomingAutoContactScheduledTime : clientController.upcomingAutoContactScheduledTime
			}
		}
	}
}

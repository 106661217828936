import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { FlowElement } from './models/flow-element.model';
import { FlowElementTypeListItem } from './models/flow-element-type-list-item.model';
import { GetFlowElementParams } from './models/get-flow-element-params.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@Injectable({
	providedIn: 'root'
})
export class FlowElementApiService extends ApiService {

	clearCache() {
		super.clearCache(this.baseGetCompanyFlowElementsUrl);
		super.clearCache(this.getFlowElementTypesUrl);
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	createNewFlowElement(name: string, flowCapacity: number): Observable<null> {
		return this.apiRequest<any>(this.createNewFlowElementUrl, HttpMethod.Post,
			{ flowCapacity: flowCapacity, name: name, role: RbEnums.Common.FlowElementType.Pump });
	}

	createNewFlowElementBranch(flowElementParentId: number, name: string, flowCapacity: number): Observable<null> {
		return this.apiRequest<any>(this.createNewFlowElementUrl, HttpMethod.Post,
			{ AConnectionId: flowElementParentId.toString(), flowCapacity: flowCapacity, name: name, role: RbEnums.Common.FlowElementType.Branch });
	}

	createFlowElement(flowElementParentId: number, name: string, flowCapacity: number, role: RbEnums.Common.FlowElementType): Observable<null> {
		return this.apiRequest<any>(this.createNewFlowElementUrl, HttpMethod.Post,
			{ AConnectionId: flowElementParentId.toString(), flowCapacity: flowCapacity, name: name, role });
	}

	createBoosterPump(flowElementParentId: number, name: string, flowCapacity: number, stationId: number, role: RbEnums.Common.FlowElementType):
		Observable<null> {
		return this.apiRequest<any>(this.createNewFlowElementUrl, HttpMethod.Post,
			{ AConnectionId: flowElementParentId.toString(), flowCapacity: flowCapacity, name: name, triggerStationId: stationId, role: role });
	}

	deleteFlowElement(flowElementId: number): Observable<void> {
		return this.apiRequest<any>(this.deleteFlowElementUrl(flowElementId), HttpMethod.Delete);
	}

	getFlowElement(flowElementId: number, params: GetFlowElementParams, bypassCache: boolean): Observable<FlowElement> {
		return this.apiRequest<any>(this.getFlowElementUrl(flowElementId, params), HttpMethod.Get, /* httpBody */ null,
			/* useRefRequest */ null, bypassCache)
			.pipe(map(flowElement => new FlowElement(flowElement)));
	}

	getFlowElements(parentId?: number, controllerId?: number, includeHiddenFlowZones?: boolean): Observable<FlowElement[]> {
		return this.apiRequest<any>(this.getFlowElementsUrl(parentId, controllerId, includeHiddenFlowZones), HttpMethod.Get)
			.pipe(map(list => list.map(item => new FlowElement(item)).sort((a, b) => a.ordinal - b.ordinal)));
	}

	areAnyCompanyFlowElementsLocked(): Observable<boolean> {
		return this.apiRequest<any>(this.areAnyCompanyFlowElementsLockedUrl, HttpMethod.Get);
	}

	getNextPumpName(): Observable<string> {
		return this.apiRequestWithCache<any>(this.getNextPumpNameUrl(), true).pipe(map(response => response.value.stringValue));
	}

	getNextBranchName(): Observable<string> {
		return this.apiRequestWithCache<any>(this.getNextBranchNameUrl(), true).pipe(map(response => response.value.stringValue));
	}

	getNextFloZoneName(): Observable<string> {
		return this.apiRequestWithCache<any>(this.getNextFloZoneNameUrl(), true).pipe(map(response => response.value.stringValue));
	}

	getCompanyFlowElements(bypassCache = false, siteId?: number, satelliteId?: number, rootOnly?: boolean)
		: Observable<ApiCachedRequestResponse<FlowElement[]>> {
		return this.apiRequestWithCache<any>(this.getCompanyFlowElementsUrl(siteId, satelliteId, rootOnly), bypassCache,
				result => result.map(f => new FlowElement(f)).sort((a, b) => a.ordinal - b.ordinal), null, true);
	}

	getFlowElementTypes(): Observable<ApiCachedRequestResponse<FlowElementTypeListItem[]>> {
		return this.apiRequestWithCache<any>(this.getFlowElementTypesUrl, false,
			result => result.map(f => new FlowElementTypeListItem(f)), null, true);
	}

	getFlowZones(): Observable<FlowElement[]> {
		return this.apiRequest<any>(this.getFlowZonesUrl, HttpMethod.Get)
			.pipe(map(list => list.map(item => new FlowElement(item)).sort((a, b) => a.ordinal - b.ordinal)));
	}

	getPump(flowElementId: number, params: GetFlowElementParams): Observable<FlowElement> {
		return this.apiRequest<any>(this.getPumpUrl(flowElementId, params), HttpMethod.Get);
	}

	updateFlowElementRate(flowZoneId: number, flowRate: number): Observable<null> {
		return this.apiRequest<any>(this.updateFlowElementUrl(flowZoneId), HttpMethod.Patch, [
			this.patchTransform({ flowRate: flowRate })[0],
			this.patchTransform({ id: flowZoneId })[0],
		]);
	}

	updateFlowElements(flowElementIds: number[], updateData: any): Observable<null> {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch,
			{ ids: flowElementIds, patch: this.patchTransform(updateData) });
	}

	moveFlowElement(flowElementId: number, toFlowElementId: number): Observable<null> {
		return this.apiRequest<any>(this.moveFlowElementUrl(flowElementId, toFlowElementId), HttpMethod.Put);
	}

	getNameUniqueness(type: RbEnums.Common.FlowElementType, name: string, id: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNameUniquenessUrl(type, name, id), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getMinFloZoneCapacity(stationIds: number[]): Observable<number> {
		return this.apiRequest<any>(this.getMinFloZoneCapacityUrl, HttpMethod.Post,
			stationIds).pipe(map(result => {
				return result;
			}));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}FlowElement`; }

	private get createNewFlowElementUrl(): string { return `${this.baseUrl}/CreateFlowElement`; }

	private deleteFlowElementUrl(flowElementIds: number): string { return `${this.baseUrl}/DeleteFlowElement?flowElementId=${flowElementIds}`; }

	private getFlowElementUrl(flowElementId: number, queryParams: GetFlowElementParams): string {
		let queryString = queryParams ? queryParams.queryString : '';
		queryString += (queryString.length > 0) ? `&flowElementId=${flowElementId}` : `?flowElementId=${flowElementId}`;
		return `${this.baseUrl}/GetFlowElement${queryString}`;
	}

	private get baseGetCompanyFlowElementsUrl(): string { return `${this.baseUrl}/GetCompanyFlowElements`; }
	private getCompanyFlowElementsUrl(siteId?: number, satelliteId?: number, rootOnly?: boolean): string {
		return `${this.baseGetCompanyFlowElementsUrl}?siteId=${siteId}&satelliteId=${satelliteId}&rootOnly=${rootOnly}`;
	}

	private getFlowElementsUrl(parentId?: number, controllerId?: number, includeHiddenFlowZones?: boolean): string {
		return `${this.baseUrl}/GetFlowElements?parentId=${parentId}&satelliteId=${controllerId}&includeHiddenFlowZones=${includeHiddenFlowZones}`;
	}

	private get areAnyCompanyFlowElementsLockedUrl(): string { return `${this.baseUrl}/AreAnyCompanyFlowElementsLocked`; }

	private moveFlowElementUrl(flowElementId: number, toFlowElementId: number): string {
		return `${this.baseUrl}/MoveFlowElement?flowElementId=${flowElementId}&toFlowElementId=${toFlowElementId}`;
	}

	private getNextPumpNameUrl(): string { return `${this.baseUrl}/getNextPumpName`; }

	private getNextBranchNameUrl(): string { return `${this.baseUrl}/getNextBranchName`; }

	private getNextFloZoneNameUrl(): string { return `${this.baseUrl}/getNextFloZoneName`; }

	private get getFlowElementTypesUrl(): string { return `${this.baseUrl}/GetFlowElementTypes`; }

	private get getFlowZonesUrl(): string { return `${this.baseUrl}/GetFlowZones`; }

	private get updateBatchesUrl(): string { return `${this.baseUrl}/v2/UpdateBatches`; }

	private updateFlowElementUrl(flowZoneId: number): string { return `${this.baseUrl}/Update?flowElementId=${flowZoneId}`; }

	private getPumpUrl(flowElementId, queryParams: GetFlowElementParams): string {
		let queryString = queryParams ? queryParams.queryString : '';
		queryString += (queryString.length > 0) ? `&flowElementId=${flowElementId}` : `?flowElementId=${flowElementId}`;
		return `${this.baseUrl}/GetPump${queryString}`;
	}

	private getNameUniquenessUrl(type: RbEnums.Common.FlowElementType, name: string, id: number): string {
		return `${this.baseUrl}/IsNameUnique?type=${type}&name=${encodeURIComponent(name)}&id=${id}`;
	}

	private get getMinFloZoneCapacityUrl(): string { return `${this.baseUrl}/GetMinFloZoneCapacity`; }
}

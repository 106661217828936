import { ActiveFlowStationListItem } from '../../../../../../api/irrigation-activity/models/active-flow-station-list-item.model';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'rb-active-flow-runtime-cell-renderer',
	templateUrl: './active-flow-runtime-cell-renderer.component.html',
	styleUrls: ['./active-flow-runtime-cell-renderer.component.scss']
})
export class ActiveFlowRuntimeCellRendererComponent implements ICellRendererAngularComp {

	_runtimeString: string;
	isRunning = false;

	constructor() { }

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.updateProgramStatus(params);
	}

	refresh(params: any): boolean {
		this.updateProgramStatus(params);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	get runtimeString(): string {
		return this._runtimeString;
	}

	private updateProgramStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		const activeFlowListItem = <ActiveFlowStationListItem>params.data;
		this._runtimeString = params.value;
		this.isRunning = false;

		switch (this._runtimeString) {
			case '-':
				break;

			default:
				this.isRunning = true;
				break;
		}

		if (activeFlowListItem.isRuntimeChange) {
			params.api.flashCells({
				rowNodes: [rowNode],
				columns: ['runtimeRemaining']
			});
		}

	}

}

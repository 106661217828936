import { Control, DomEvent } from 'leaflet';
import { MapInfoLeaflet } from './map-info-leaflet.model';
import { TranslateService } from '@ngx-translate/core';

export class CenterOnSiteControlLeaflet {

	static create(mapInfo: MapInfoLeaflet, translate: TranslateService, parentDiv: HTMLElement) {

		// Set CSS for the control border.
		const controlUI = document.createElement('div');
		controlUI.className = 'rb-leaflet-control';
		controlUI.title = translate.instant('STRINGS.HOME');
		parentDiv.appendChild(controlUI);

		const icon = document.createElement('i');
		icon.classList.add('mdi');
		// icon.classList.add('mdi-office-building-outline');
		icon.classList.add('home-map-pin');
		icon.id = 'course-location';
		controlUI.appendChild(icon);

		controlUI.addEventListener('click', function(evt) {
			mapInfo.goToCourseLocation();
		});

		// Disable click prop. to avoid zooming in on clicking/tapping point when double clicking/tapping the button
		DomEvent.disableClickPropagation(controlUI);

		const CenterControl = Control.extend({
			onAdd: function(map) {
				return controlUI;
			},
		});

		return new CenterControl({ position: 'bottomright' });

	}
}

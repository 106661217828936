import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Area } from '../../../api/areas/models/area.model';
import { AreaManagerService } from '../../../api/areas/area-manager.service';
import { Station } from '../../../api/stations/models/station.model';
import { StationManagerService } from '../../../api/stations/station-manager.service';
import { TableWrapperComponent } from '../tables/table-wrapper/table-wrapper.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-select-stations-dialog',
	templateUrl: './select-stations-dialog.component.html',
	styleUrls: ['./select-stations-dialog.component.scss'],
})

export class SelectStationsDialogComponent implements OnInit, OnDestroy {

	@ViewChild('stationListTable', { static: true }) table: TableWrapperComponent;

	@Input() controllerId: number;
	@Input() initialSelectionStationIds: number[];
	@Input() isGolfController: boolean;
	@Input() programId: number;
	@Input() siteId: number;

	@Output() complete = new EventEmitter<{ savedData: boolean }>();

	areas: Area[] = [];
	busy = false;
	filteredStations: Station[] = [];
	loadError: string;

	private stations: Station[] = [];
	private selectedStation: Station;

	constructor(private areaManager: AreaManagerService,
				private stationManager: StationManagerService,
				private translate: TranslateService) {}

	ngOnInit(): void {
		this.loadData();
	}

	ngOnDestroy(): void {
	}

	private loadData() {
		this.busy = true;

		const sources: Observable<any>[] = [
			this.getAreasAndStations(),
		];

		forkJoin(sources)
			.subscribe(([stations]) => {
				this.stations = stations;
				this.filteredStations = stations;
				// this.updateForm();
				this.busy = false;
			}, error => {
				this.loadError = error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
			});
	}

	private getAreasAndStations(): Observable<Station[]> {
		return this.areaManager.getAreas(this.siteId).pipe(
			take(1),
			switchMap(areas => {
				this.areas = areas.sort((a, b) => a.number - b.number);
				return this.stationManager.getStationsByAreasAndSites(areas.map(a => a.id), [this.siteId]);
			}));
	}

	onSave() {
		this.complete.emit({ savedData: this.selectedStation != null });
	}

	onCancel() {
		this.complete.emit({ savedData: false });
	}

	onStationSelect(selectedStations: any) {
		this.selectedStation = selectedStations.length === 0 ? null : selectedStations[0];
	}

	onSelectPanelChange(selectedHoles: Area[]) {
		if (selectedHoles.length === 0) {
			this.filteredStations = this.stations;
			return;
		}

		const selectedHoleIds = selectedHoles.map(hole => hole.id);
		this.filteredStations = this.stations.filter(station => {
			return station.stationArea.find(sa => selectedHoleIds.find(id => id === sa.areaId) != null) != null;
		});
	}
}

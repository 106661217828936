import { ApiService, HttpMethod } from '../_common/api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Module } from './models/module.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ModuleApiService extends ApiService {

	getControllerModules(controllerId: number): Observable<Module[]> {
		return this.apiRequest<any>(this.getModulesForControllerUrl(controllerId), HttpMethod.Get)
			.pipe(map(modules => modules.map(module => new Module(module))));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}Module`; }
	private getModulesForControllerUrl(controllerId: number): string { return `${this.baseUrl}/GetModules?satelliteId=${controllerId}`; }
}

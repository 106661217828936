import * as moment from 'moment';

import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { CultureSettings } from '../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../api/culture-settings/culture-settings-manager.service';
import { DatePipe } from '@angular/common';
import { delay } from 'rxjs/operators';
import { NativeDateAdapter } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { RbConstants } from '../../common/constants/_rb.constants';
import { RbUtils } from '../../common/utils/_rb.utils';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class AppDateAdapter extends NativeDateAdapter implements OnDestroy {

	cultureSettings: CultureSettings;

	static getCurrentDate(): Date {
		return moment().toDate();
	}

	static getLaterDate(first: Date, second: Date) {
		if (!first || !second) {
			return first || second;
		}

		const momentDate1 = moment(first);
		const momentDate2 = moment(second);
		return momentDate1.isBefore(momentDate2) ? second : first;
	}

	static getLocalDate(date: Date | string) {
		return moment.utc(date).toDate();
	}

	/**
	 * getting the total seconds
	 * @param timeString HH:mm:ss (duration standard format)
	 * @returns seconds
	 */
	static getSecondsFromTime(timeString = "") {
		return moment.duration(timeString).asSeconds();
	}

	/**
	 * 1 ticks is 10_000 milis
	 * @param seconds
	 * @returns ticks
	 */
	static getTicksFromSeconds(seconds = 0) {
		return seconds * 10_000_000;
	}

	private static invalidDate(date: Date): boolean {
		return date == null || !(date instanceof Date) || isNaN(date.getTime());
	}

	get dateFormat(): string {
		this.cultureSettings = this.authManager.userCulture;
		return this.cultureSettings != null ? RbUtils.Common.getDateFormat(this.cultureSettings) : RbConstants.Form.DATE_FORMAT.MMDDYYYY_Slash;
	}

	get timeFormat(): string {
		this.cultureSettings = this.authManager.userCulture;
		return this.cultureSettings != null ? RbUtils.Common.getTimeFormat(this.cultureSettings) : RbConstants.Form.TIME_FORMAT_DISPLAY_OPTIONS.amPm;
	}

	get timeWithSecondsFormat(): string {
		this.cultureSettings = this.authManager.userCulture;
		return this.cultureSettings != null
			? RbUtils.Common.getTimeWithSecondsFormat(this.cultureSettings) : RbConstants.Form.TIME_FORMAT_WITH_SECONDS_DISPLAY_OPTIONS.amPm;
	}

	get dateTimeFormat(): string {
		return this.dateFormat + ' ' + this.timeFormat;
	}

	get dateTimeWithSecondsFormat(): string {
		return this.dateFormat + ' ' + this.timeWithSecondsFormat;
	}

	constructor(platform: Platform,
				private authManager: AuthManagerService,
				private cultureSettingsManager: CultureSettingsManagerService,
				private datePipe: DatePipe) {
		super('en', platform);

		// Force adapter to re-format date by setting locale. This is required to pick up culture changes
		// when user preferences are changed or page is refreshed.
		// also this is the normal case. We have to add delay() to our pipe in RxJs to avoid an ExpressionChangedAfterItHasBeenCheckedError
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this), delay(250))
			.subscribe(() => this.updateCultureSettingsLocale());

		// Initialize from the operational cultureSettingsManager, if it already exists. If the culture
		// settings manager has the data by the time we're instantiated, we need to get it; we can't wait
		// for the next culture change.
		this.updateCultureSettingsLocale();
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	format(date: Date): string {
		return AppDateAdapter.invalidDate(date) ? '' : this.datePipe.transform(date, this.dateFormat);
	}

	formatTime(date: Date): string {
		return AppDateAdapter.invalidDate(date) ? '' : this.datePipe.transform(date, this.timeFormat);
	}

	formatTimeWithSeconds(date: Date): string {
		return AppDateAdapter.invalidDate(date) ? '' : this.datePipe.transform(date, this.timeWithSecondsFormat);
	}

	formatDate(date: Date): string {
		return AppDateAdapter.invalidDate(date) ? '' : this.datePipe.transform(date, this.dateFormat);
	}

	formatDateTime(date: Date): string {
		return AppDateAdapter.invalidDate(date) ? '' : this.datePipe.transform(date, this.dateTimeFormat);
	}

	formatMonthYear(date: Date): string {
		return AppDateAdapter.invalidDate(date) ? '' : `${RbUtils.Months.monthAbbrevString(date.getMonth() + 1)} ${date.getFullYear()}`;
	}

	formatDateTimeWithSeconds(date: Date): string {
		return AppDateAdapter.invalidDate(date) ? '' : this.datePipe.transform(date, this.dateTimeWithSecondsFormat);
	}

	getMoment(dateString: string) {
		return moment(dateString, this.dateTimeFormat);
	}

	getTime(timeString: string) {
		return moment(timeString, this.timeFormat);
	}

	private updateCultureSettingsLocale() {
		if (this.cultureSettingsManager.cultureSetting) {
			// The cultureSettingsManager already has the settings. Use them to set the date adapter's language/locale.
			const cultureString = this.cultureSettingsManager.getCultureAsciiName(this.cultureSettingsManager.cultureSetting.cultureId);
			this.setLocale(cultureString);
		}
	}
}

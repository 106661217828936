import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { ActiveFlowListItem } from '../../../../../api/irrigation-activity/models/active-flow-list-item.model';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { DateAndTimeService } from '../../../../../common/services/date-and-time.service';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-active-flow-mobile-cell-renderer',
	templateUrl: './active-flow-mobile-cell-renderer.component.html',
	styleUrls: ['./active-flow-mobile-cell-renderer.component.scss']
})
export class ActiveFlowMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	ActivityTypes = RbEnums.Common.ActivityType;

	activeFlowListItem: ActiveFlowListItem;
	activityType: RbEnums.Common.ActivityType;

	constructor(injector: Injector,
				private dateAndTimeService: DateAndTimeService
	) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.activeFlowListItem = params.data;
		this.activityType = params.activityType;
	}

	get startTime(): string {
		if (!this.activeFlowListItem.startTime) return '-';

		return `${this.dateAndTimeService.dateToUserDateString(this.activeFlowListItem.startTime)} - ` +
			`${this.dateAndTimeService.dateToUserTimeString(this.activeFlowListItem.startTime)}`;
	}

}

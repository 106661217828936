import { RbConstants } from '../../../../../common/constants/_rb.constants';
import { ToolbarItemInfo } from './toolbar-item-info.model';

export class AnimatedToolbarItemInfo extends ToolbarItemInfo {

	constructor(qaIdString: string,
				label: string,
				icon: string,
				buttonClickFunc: Function,
				isDisabledFunc: Function = null,
				animationType: any = null,
				isAnimatingFunc: Function = null,
				colorFunc: Function = null,
	) {
		super(qaIdString, label, icon, buttonClickFunc, isDisabledFunc, RbConstants.Form.CUSTOM_ICON_NUMBER.standard,
			animationType, isAnimatingFunc, colorFunc);
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<mat-form-field class="">
	<mat-select
		[placeholder]="'STRINGS.ACTIVITY_TYPE' | translate"
		[ngModel]="selectedActivityType"
		name="activityType"
		(ngModelChange)="onActivityTypeChange($event)">

		<mat-select-trigger>{{selectedActivityTypeName}}</mat-select-trigger>

		<mat-option *ngFor="let aType of activityTypes" [value]="aType.value" class="pv1" color="primary">
			{{aType.name}}
		</mat-option>
	</mat-select>
</mat-form-field>

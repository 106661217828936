<div class="rb-cell-editor-container">
	<input #i
		oninput="this.value = this.value.toUpperCase()"
		[value]="value"
		[maxLength]="maxLength"
		(keypress)="onKeyPress($event)"
		(keyup)="onKeyUp($event)"
		(keydown)="onKeyDown($event)"
	/>
</div>


import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Area } from '../../../../../api/areas/models/area.model';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { MapInfoLeaflet } from '../../../../../common/models/map-info-leaflet.model';
import { MultiSelectService } from '../../../../../common/services/multi-select.service';
import { Station } from '../../../../../api/stations/models/station.model';


@Component({
  selector: 'rb-stations-list',
  templateUrl: './stations-list.component.html',
  styleUrls: ['./stations-list.component.scss']
})

export class StationsListComponent implements OnDestroy, OnInit, OnChanges {
  
	@ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

	@Input() busy;
  @Input() mapInfo: MapInfoLeaflet;
  @Input() map;
  @Input() dragDisabled: boolean;
  @Input() isWidget: boolean = false;

  @Output() holeClicked = new EventEmitter<{event: any, hole: Area}>();
  @Output() areaClicked = new EventEmitter<{event: any, holeId: number, area: Area}>();
  @Output() stationClicked = new EventEmitter<{event: any, station: Station}>();
  @Output() closeLeftPanel = new EventEmitter();

  @Output() onDragMoved = new EventEmitter<CdkDragMove>();
  @Output() onDragEnded = new EventEmitter();

  private subscriptionNotifier$ = new Subject<void>();

  public expandedHoleId = -1;
  public expandedAreaId = -1;
  public expandedSubAreaId = -1;
  public isMobile = false;
  public treeStructure = [];

  isSearching = false;
  searchFilter = "";

  constructor(
    private deviceManager: DeviceManagerService,
    public multiSelectService: MultiSelectService
  ) {
		this.multiSelectService.getStation()
			.asObservable().pipe(takeUntil(this.subscriptionNotifier$))
			.subscribe(value => 
				this.onChangeStation(value, true)
			);
		this.multiSelectService.getClearAll()
			.asObservable().pipe(takeUntil(this.subscriptionNotifier$))
			.subscribe(value =>
				this.deselectAllTreeStructure()
			);
		this.multiSelectService.getIcon()
			.asObservable().pipe(takeUntil(this.subscriptionNotifier$))
			.subscribe(value => this.addIcon(value));
  }

  private fillTreeStructure(holes){
		this.treeStructure = [];
		holes.forEach(hole => {
			const areas = this.mapInfo.getAreasForHole(hole.id, hole.siteId);
			this.treeStructure.push({
				id: hole.id,
				icon: this.mapInfo.holeHasLocation({
					id: hole.id,
					siteId: hole.siteId
				}),
				marker: hole.marker,
				name: hole.name,
				number: hole.number,
				siteId: hole.siteId,
				isSelected: false,
				childrenSelected: 0,
				areas
			});
		});

		this.treeStructure.sort(this.compareNumbers);
 
		let treeHoles = this.treeStructure;	
		
		treeHoles.forEach((hole, i) => {
			hole.index = i;
			hole.checkState = false;

			hole.areas.forEach((area, j) => {
				area.index = j;
				area.holeIndex = i;
				area.checkState = false;

				area.subAreas.forEach((subArea, k) => {
					subArea.index = k;
					subArea.holeIndex = i;
					subArea.areaIndex = j;
					subArea.checkState = false;

					// This validation is necessary to add the subArea on the tree.
					// If it doesn't have stations (undefined or null) then the subArea is not added.
					if(subArea.stations != null){
						subArea.stations.forEach(station => {
							station.holeIndex = i;
							station.areaIndex = j;
							station.subAreaIndex = k;
						});
					}
				});
			});
		});
	}

	private compareNumbers( a, b ) {
		if ( a.number < b.number ){
			return -1;
		}
		if ( a.number > b.number ){
			return 1;
		}
		return 0;
	}

	private addIcon(value: {holeId: number; areaId: number, show: boolean}){
		if(this.treeStructure){
			for(let i = 0; i < this.treeStructure.length; i++){
				let hole = this.treeStructure[i];
				if(hole.id === value.holeId){
					if(value.areaId){
						for(let j = 0; j < hole.areas.length; j++){
							if(hole.areas[j].id === value.areaId){
								this.treeStructure[i].areas[j].icon = value.show;
							}
						}
					} else {
						this.treeStructure[i].icon = value.show;
					}
				}
			}
		}
	}

	ngOnInit(): void {
		this.isMobile = this.deviceManager.isMobile;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.mapInfo?.currentValue?.holes?.length > 0){
		const holes = changes.mapInfo.currentValue.holes;
		this.multiSelectService.deselectAllStations();
		this.fillTreeStructure(holes);
		}
	}

	ngOnDestroy(): void {
		this.subscriptionNotifier$.next();
		this.subscriptionNotifier$.complete();
	}

	expandSubArea(id: number){
		this.expandedSubAreaId = this.expandedSubAreaId === id ? -1 : id;
	}

	expandArea(id: number) { 
		this.expandedAreaId = this.expandedAreaId === id ? -1 : id;
		this.expandedSubAreaId = -1;
	}

	expandHole(id: number) {
		this.expandedHoleId = this.expandedHoleId === id ? -1 : id;
		this.expandedAreaId = -1;
		this.expandedSubAreaId = -1;
	}

	onChangeStation(station, fromMap = false){
		// checkState:
		// 0: uncheck, 1: undefined, 2: check

		let holeFather = this.treeStructure[station.holeIndex];
		let areaFather = holeFather.areas[station.areaIndex];
		let subAreaFather = areaFather.subAreas[station.subAreaIndex];

		if(station.isSelected){
			if(!fromMap){
				station.isSelected = false;
				this.multiSelectService.selectStation(station);
			}
			subAreaFather.childrenSelected++;
		} else {
			if(!fromMap){
				station.isSelected = true;
				this.multiSelectService.deselectStation(station);
			}
			subAreaFather.childrenSelected--;
		}

		this.defineFatherState(subAreaFather);

		this.verifyAndDefineFatherState(areaFather);

		this.verifyAndDefineFatherState(holeFather);
	}

	onChangeSubArea(subArea){
		let holeFather = this.treeStructure[subArea.holeIndex];
		let areaFather = holeFather.areas[subArea.areaIndex];

		if(subArea.isSelected){
			this.selectSubAreaAndChildren(subArea);
			areaFather.childrenSelected++;
		} else {
			this.deselectSubAreaAndChildren(subArea);
			areaFather.childrenSelected--;
		}

		this.defineFatherState(areaFather);

		this.verifyAndDefineFatherState(holeFather);
	}

	onChangeArea(area){
		// checkState:
		// 0: uncheck, 1: undefined, 2: check
		let holeFather = this.treeStructure[area.holeIndex];

		if(area.isSelected){
			this.selectAreaAndChildren(area);
			holeFather.childrenSelected++;
		} else {
			this.deselectAreaAndChildren(area);
			holeFather.childrenSelected--;
		}

		this.defineFatherState(holeFather);
	}

	onChangeHole(hole){
		// checkState:
		// 0: uncheck, 1: undefined, 2: check
		if(hole.isSelected){
			this.selectHoleAndChildren(hole);	
		} else {
			this.deselectHoleAndChildren(hole);
		}
	}

	private deselectSubAreaAndChildren(subArea, changeStations = true){
		subArea.isSelected = false;
		subArea.checkState = 0;

		if(changeStations){
			subArea.stations.forEach(station => {
				this.multiSelectService.deselectStation(station);
			});
		}
		
		subArea.childrenSelected = 0;
	}

	private deselectAreaAndChildren(area, changeStations = true){
		area.isSelected = false;
		area.checkState = 0;

		area.subAreas.forEach(subArea => {
			this.deselectSubAreaAndChildren(subArea, changeStations);
		});

		area.childrenSelected = 0;
	}

	private deselectHoleAndChildren(hole, changeStations = true){
		hole.isSelected = false;
		hole.checkState = 0;

		hole.areas.forEach(area => {
			this.deselectAreaAndChildren(area, changeStations);
		});

		hole.childrenSelected = 0;
	}

	private selectSubAreaAndChildren(subArea){
		subArea.isSelected = true;
		subArea.checkState = 2;

		subArea.stations.forEach(station => {
			this.multiSelectService.selectStation(station);
		});

		subArea.childrenSelected = subArea.stations.length;
	}

	private selectAreaAndChildren(area){
		area.isSelected = true;
		area.checkState = 2;

		area.subAreas.forEach(subArea => {
			this.selectSubAreaAndChildren(subArea);
		});

		area.childrenSelected = area.subAreas.length;
	}

	private selectHoleAndChildren(hole){
		hole.isSelected = true;
		hole.checkState = 2;

		hole.areas.forEach(area => {
			this.selectAreaAndChildren(area);
		});

		hole.childrenSelected = hole.areas.length;	
	}

	private verifyAndDefineFatherState(father){
		let childrenSelected = 0;
		let thereIsState1 = false;
		const children = father.areas || father.subAreas;

		children.forEach(child => {
			if(child.isSelected){
				childrenSelected++;
			}
			if(child.checkState === 1){
				thereIsState1 = true;
			}
		});

		father.childrenSelected = childrenSelected;

		if(thereIsState1){
			father.checkState = 1;
			father.isSelected = false;
		} else {
			this.defineFatherState(father);
		}
	}

	private defineFatherState(father){
		const fatherLength = father.stations?.length || father.subAreas?.length || father.areas?.length;

		if(father.childrenSelected === 0){
			father.checkState = 0;
			father.isSelected = false;
		} else if (father.childrenSelected === fatherLength){
			father.checkState = 2;
			father.isSelected = true;
		} else {
			father.checkState = 1;
			father.isSelected = false;
		}
	}

  	private deselectAllTreeStructure(){
		let treeHoles = this.treeStructure;

		treeHoles.forEach(hole => {
			this.deselectHoleAndChildren(hole, false);
		});
	}

	onHoleClicked(event: any, hole: Area){
		this.holeClicked.emit({event, hole});
	}
	
	onAreaClicked(event: any, holeId: number, area: Area){
		this.areaClicked.emit({event, holeId, area});
	}

	onStationClicked(event: any, station: Station){
		this.stationClicked.emit({event, station});
	}
}

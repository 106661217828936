import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { FlowAlarmsModel } from '../../../../../api/controllers/models/flow-alarms.model';

@Component({
	selector: 'rb-flow-alarm-mobile-cell-renderer',
	templateUrl: './flow-alarm-mobile-cell-renderer.component.html',
	styleUrls: ['./flow-alarm-mobile-cell-renderer.component.scss']
})
export class FlowAlarmMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	alarm: FlowAlarmsModel;
	showAddress: boolean;

	constructor(public appDateAdapter: AppDateAdapter,
				injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.alarm = params.data;
		this.showAddress = params.showAddress;
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} << </div>

<div class="comp-container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
	<div class="header" cdkDragHandle *ngIf="!isCompact">
		<i class="mdi mdi-import"></i>
		<h3 mat-dialog-title class="header3 pb3 mb2">
			{{ !isVectorEdit ? 
        ('STRINGS.LAYER_IMPORT_TOOL' | translate) :
        (!isCompact ?
            ('STRINGS.EDITING_LAYER' | translate : { layer: currentFile.name || '' }) :
            ('STRINGS.EDIT_LAYER' | translate))
    	}}
		</h3>
		<button class="button-close" (click)="close()">
			<i class="mdi mdi-close"></i>
		</button>
	</div>

	<div class="body"
		[class.is-vector-edit]="isVectorEdit"
		[class.is-compact]="isCompact">
		<div class="file-upload-container">
			<h3 class="title">{{ 'STRINGS.FILE_SELECTION' | translate }}</h3>
			<div class="content">
				<div class="dropzone-container" [class.dragging]="isFile">
					<ngx-dropzone (change)="onFileSelect($event)"
						[accept]="'.kmz, .kml, .shp, .dbf, .shx, .jpg, .jpeg, .png, .zip'" [multiple]="true">
						<ngx-dropzone-label>
							<div class="icon">
								<i class="mdi mdi-plus-circle"></i>
							</div>
							<div class="label">
								<span>{{ 'STRINGS.DRAG_DROP_IMAGES_OR_VECTOR_FILES' | translate }}!</span>
								<small>{{ 'STRINGS.OR_CLICK_TO_BROWSE_FILES' | translate }}</small>
							</div>
						</ngx-dropzone-label>
					</ngx-dropzone>
				</div>
				<div class="file-list" *ngIf="files && files.length">
					<div class="file-container"
						*ngFor="let f of files"
						(click)="setCurrentFile(f)"
						[class.current]="f.status === 'current'"
						[class.success]="f.status === 'success'"
						[class.error]="f.status === 'error'"
						[class.warning]="f.status === 'warning'"
						[matTooltip]="f.statusMessage"
						[matTooltipPosition]="'right'">
						<div class="icon">
							<img src="../../../../assets/images/file_icons/kml_file.svg" 
								*ngIf="f.extension === 'kml'">
							<img src="../../../../assets/images/file_icons/kmz_file.svg" 
								*ngIf="f.extension === 'kmz'">
							<img src="../../../../assets/images/file_icons/shp_file.svg" 
								*ngIf="f.extension === 'shp'">
							<img src="../../../../assets/images/file_icons/jpg_file.svg" 
								*ngIf="f.extension === 'jpg' || f.extension === 'jpeg'">
							<img src="../../../../assets/images/file_icons/png_file.svg" 
								*ngIf="f.extension === 'png'">
						</div>
						<div class="info">
							<span class="filename"
								[class.text-danger]="!f.file">{{ f.file ? f.file.name : f.name + '.shp' }}</span>
							<span class="filename" *ngIf="f.extension === 'shp'"
								[class.text-danger]="!f.shxFile">{{ f.shxFile ? f.shxFile.name : f.name + '.shx' }}</span>
							<span class="filename" *ngIf="f.extension === 'shp'"
								[class.text-danger]="!f.dbfFile">{{ f.dbfFile ? f.dbfFile.name : f.name + '.dbf' }}</span>
						</div>
						<div class="status">
							<div class="icon">
								<i class="mdi mdi-timer-sand" *ngIf="f.status === 'waiting'"></i>
								<i class="mdi mdi-arrow-right-bold" *ngIf="f.status === 'current'"></i>
								<i class="mdi mdi-check-circle" *ngIf="f.status === 'success'"></i>
								<i class="mdi mdi-alert" *ngIf="f.status === 'error'"></i>
								<i class="mdi mdi-alert-outline" *ngIf="f.status === 'warning'"></i>
							</div>
						</div>
						<div class="actions">
							<button class="delete" (click)="onFileRemove(f)" [matTooltip]="'STRINGS.REMOVE_FILE' | translate">
								<i class="mdi mdi-close"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="action-container">
					<button mat-button color="primary" class="button-close" (click)="close()" data-qaid="dialog-cancel-button">{{ 'STRINGS.CANCEL' | translate }}</button>
					<button mat-raised-button color="primary" *ngIf="files && files.length && !canProcess && !isCurrentFileImage"
						[disabled]="!allowToSave || saving" (click)="saveCurrentFile()">{{ filesWaiting ? ('STRINGS.SAVE_AND_CONTINUE' | translate) : ('STRINGS.SAVE' | translate) }}</button>
					<button mat-raised-button color="primary" *ngIf="canProcess" (click)="saveAllFiles()" [disabled]="saving">{{ saving ? ('STRINGS.IMPORTING_FILES' | translate) : ('STRINGS.IMPORT_FILES' | translate) }}</button>
					<span class="image-label" *ngIf="isCurrentFileImage">{{ 'STRINGS.PLEASE_USE_IMAGE_ACTION_BAR_TO_SAVE' | translate }}</span>
				</div>
			</div>
		</div>
		<div class="preview-container">
			<h3 class="title">{{ 'STRINGS.FILE_PREVIEW' | translate }} <small>{{ 'STRINGS.SET_PROPER_ADJUSTMENTS_IN_PREVIEW' | translate }}</small></h3>
			<div leaflet id="map"
				[leafletOptions]="leafletMapOptions"
				[leafletLayers]="layers"
				(leafletMapReady)="onLeafletMapReady($event)">
			</div>
			<div class="message-backdrop" *ngIf="!currentFile">
				<div class="message-container empty" *ngIf="!files || !files.length">
					<div class="icon-container">
						<img src="../../../../assets/images/illustrations/empty-box.png" alt="">
					</div>
					<div class="message">
						<h4>{{ 'STRINGS.SELECT_VECTOR_OR_IMAGE_FILE' | translate }}</h4>
						<span>{{ 'STRINGS.OR_DRAG_THEM_INTO_THE_DROPZONE' | translate }}</span>
					</div>
				</div>
				<div class="message-container warning" *ngIf="files && files.length && !currentFile && filesWarning">
					<div class="icon-container">
						<img src="../../../../assets/images/illustrations/warning-circle.png" alt="">
					</div>
					<div class="message">
						<h4>{{ 'STRINGS.SOME_FILES_HAVE_WARNINGS' | translate }}</h4>
						<span>{{ 'STRINGS.IF_YOU_CLOSE_FILES_WONT_BE_IMPORTED' | translate }}</span>
					</div>
				</div>
				<div class="message-container done" *ngIf="files && files.length && canProcess">
					<div class="icon-container">
						<img src="../../../../assets/images/illustrations/check-circle.png" alt="">
					</div>
					<div class="message">
						<h4>{{ 'STRINGS.ALL_FILES_SET' | translate }}!</h4>
						<span>{{ 'STRINGS.CLICK_IMPORT_FILES_BUTTON_TO_FINISH' | translate }}</span>
					</div>
				</div>
			</div>
			<div class="message-backdrop loading" *ngIf="innerLoader">
				<div class="message-container">
					<div class="icon-container">
						<rb-waiting-indicator></rb-waiting-indicator>
					</div>
				</div>
			</div>
		</div>
		<div class="file-attributes-container"
			*ngIf="currentFile?.extension === 'kml' || currentFile?.extension === 'kmz' ||
				(currentFile?.extension === 'shp' && currentFile?.file && currentFile?.shxFile && currentFile?.dbfFile)">
			<h3 class="title" *ngIf="!isVectorEdit">{{ 'STRINGS.FILE_ATTRIBUTES' | translate }}</h3>
			<div class="editor">
				<div class="form">
					<ng-template [ngTemplateOutlet]="isCurrentFileImage ? rasterItemFormTemplate : customLayerForm"></ng-template>
				</div>
			</div>
			<div class="message-backdrop loading" *ngIf="innerLoader">
				<div class="message-container">
					<div class="icon-container">
						<rb-waiting-indicator></rb-waiting-indicator>
					</div>
				</div>
			</div>
			<div class="action-container" *ngIf="isVectorEdit">
				<button mat-button color="primary" class="button-close" (click)="close()" data-qaid="dialog-cancel-button">{{ 'STRINGS.CANCEL' | translate }}</button>
				<button mat-raised-button color="primary" (click)="saveEditingFile()">{{ 'STRINGS.SAVE' | translate }}</button>
			</div>
		</div>
	</div>
</div>

<!-- Custom layers editing form -->

<ng-template class="mat-menu-content editor fl w-50" #customLayerForm>
	<form [formGroup]="kmzItemsForm">

		<mat-form-field class="form-field-item" *ngIf="currentFile?.extension === 'shp'">
			<mat-label>{{ 'STRINGS.COORDINATE_SYSTEM' | translate }}</mat-label>
			<input matInput
				(click)="$event.target.select()"
				[matAutocomplete]="auto"
				[formControl]="epsgCtrl">
			<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [panelWidth]="520"
				(optionSelected)='selectCoordinateSystem($event.option.value)' [displayWith]="displayFn">
				<mat-option *ngFor="let cs of filteredCoordinateSystems | async" [value]="cs">
					<span class="dsname-text">{{ cs.dsname }}</span> |
					<small class="aoi-text">{{ cs.aoi }}</small>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>

		<mat-form-field class="form-field-item">
			<mat-label>{{ 'STRINGS.NAME' | translate }}</mat-label>
			<input
				matInput
				type="text"
				formControlName="fileName"/>
		</mat-form-field>

		<div class="form-field-item">
			<mat-slide-toggle color="primary" formControlName="showTooltips">
        {{ 'STRINGS.SHOW_LABELS' | translate }} <small class="text-italic">({{ 'STRINGS.IF_AVAILABLE' | translate }})</small>
      </mat-slide-toggle>
		</div>

		<div class="form-field-item no-border">
			<mat-label>{{ 'STRINGS.COLOR' | translate }}</mat-label>
			<rb-color-picker formControlName="color"></rb-color-picker>
		</div>

		<div class="form-field-item pt-0">
			<mat-checkbox color="primary" formControlName="matchColors">
				<small>{{ 'STRINGS.MATCH_LINE_AND_FILL_COLORS' | translate }}</small>
			</mat-checkbox>
		</div>

		<div class="form-field-item">
			<mat-label>{{ 'STRINGS.OPACITY' | translate }}</mat-label>
			<mat-slider
				color="primary"
				style="margin-bottom: 12px"
				min="0"
				max="100"
				step="10"
				aria-label="units"
				formControlName="opacity"
				ticks="1"
				thumbLabel>
			</mat-slider>
		</div>

		<div class="form-field-item">
			<mat-label>{{ 'STRINGS.SIZE' | translate }}</mat-label>
			<mat-slider
				color="primary"
				style="margin-bottom: 12px"
				min="0"
				max="12"
				step="1"
				aria-label="units"
				formControlName="weight"
				ticks="1"
				thumbLabel>
			</mat-slider>
		</div>

		<mat-form-field class="form-field-item">
			<mat-label>{{ 'STRINGS.STROKE_STYLE' | translate }}</mat-label>
			<mat-select formControlName="stroke_style">
				<mat-option value="solid">{{ 'STRINGS.SOLID' | translate }}</mat-option>
				<mat-option value="dashed">{{ 'STRINGS.DASHED' | translate }}</mat-option>
				<mat-option value="dotted">{{ 'STRINGS.DOTTED' | translate }}</mat-option>
			</mat-select>
		</mat-form-field>

		<div class="form-field-item">
			<mat-label>{{ 'STRINGS.FILL_COLOR' | translate }}</mat-label>
			<rb-color-picker formControlName="fillColor"> </rb-color-picker>
		</div>

		<div class="form-field-item">
			<mat-label>{{ 'STRINGS.FILL_OPACITY' | translate }}</mat-label>
			<mat-slider
				color="primary"
				min="0"
				max="100"
				step="10"
				tickInterval="10"
				aria-label="units"
				formControlName="fillOpacity"
				thumbLabel>
			</mat-slider>
		</div>
	</form>
</ng-template>

<!-- Raster image editing form -->

<ng-template #rasterItemFormTemplate>
	<form [formGroup]="rasterItemsForm">

		<mat-form-field class="form-field-item">
			<mat-label>{{ 'STRINGS.NAME' | translate }}</mat-label>
			<input
				matInput
				type="text"
				formControlName="sourceName"
			/>
		</mat-form-field>

		<div class="form-field-item">
			<mat-label>{{ 'STRINGS.OPACITY' | translate }}</mat-label>
			<mat-slider color="primary" min="0" max="100" step="10" tickInterval="1" aria-label="units" formControlName="opacity"
				thumbLabel [displayWith]="formatPercentage">
			</mat-slider>
		</div>

		<button mat-flat-button color="primary" type="button" (click)="editImage()"
			[disabled]="currentFile.rasterToDatabase.editing || currentFile.rasterToDatabase.error">
			{{ 'STRINGS.EDIT_ON_MAP' | translate }}
		</button>

	</form>
</ng-template>
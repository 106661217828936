import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { BehaviorSubject } from 'rxjs';
import { SiteManagerService } from '../../api/sites/site-manager.service';

/** Service to manage which map to show */
@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
// this class is deprecated after gmaps was removed, consider to remove it and all their references
export class MapManagementService implements OnDestroy {

	public currentMap = new BehaviorSubject<number>(1);

	constructor(
		private siteManager: SiteManagerService,
		private authManager: AuthManagerService
	) {
		// comment this line to remove currentMap persistence
		this.initializeMapManager();
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	get isGolfSite (): boolean {
		if (this.siteManager ==  null || this.siteManager.isGolfSite == null)
			return false;

		return this.siteManager.isGolfSite;
	}

	get allowFieldTestFeatures(): boolean {
		if (this.authManager ==  null || this.authManager.allowFieldTestFeatures == null)
			return false;

		return this.authManager.allowFieldTestFeatures;
	}

	get mapSwitchEnabled(): boolean {
		return true || (this.isGolfSite && this.allowFieldTestFeatures);
		// return this.isGolfSite && this.allowFieldTestFeatures;
	}

	get defaultMap (): number {
		return 1;
	}

	private initializeMapManager() {
		if (this.mapSwitchEnabled) {
			const rbPersistedMap: number = Number(localStorage.getItem('rbPersistedMap'));
			this.currentMap.pipe(untilDestroyed(this))
				.subscribe(currentMap => {
					localStorage.setItem('rbPersistedMap', currentMap.toString());
				});

			if (rbPersistedMap != null && ['1', '2'].includes(rbPersistedMap.toString())) {
				this.currentMap.next(rbPersistedMap);
				return ;
			}
		}

		this.currentMap.next(this.defaultMap);
	}
}

export class CimisStation {

	elevation: number;
	id: number;
	isActive: boolean;
	name: string;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

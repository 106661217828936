import * as AwsSDK from 'aws-sdk';

import { catchError, forkJoin, from, map, Observable, of, switchMap } from 'rxjs';

import { CloudDryRunItem } from './models/cloud-dry-run-item.model';
import { CompanyManagerService } from '../companies/company-manager.service';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { DryRunProgramDatum } from './models/dry-run-program-datum.model';
import { DryRunResult } from './models/dry-run-result.model';
import { EnvironmentService } from './../../common/services/environment.service';
import { Injectable } from '@angular/core';
import { Program } from '../programs/models/program.model';
import { ProgramManagerService } from '../programs/program-manager.service';
import { StationListItem } from '../stations/models/station-list-item.model';
import { StationManagerService } from '../stations/station-manager.service';
import { TokenVendingMachineManager } from '../token-vending-machine/token-vending-machine-manager.service';

@Injectable({
	providedIn: 'root',
})
export class DryrunCloudManagerService {
	constructor(
		private companyManagerService: CompanyManagerService,
		private programManagerService: ProgramManagerService,
		private stationManagerService: StationManagerService,
		private env: EnvironmentService,
		private tokenVendingMachine: TokenVendingMachineManager
	) {}

	getDryRunDataCloud(dryrunUUID: string): Observable<DryRunResult> {
		if (!dryrunUUID?.length) {
			return of(null)
		}
		const params: DocumentClient.QueryInput = {
			TableName: this.DryRunTableName,
			KeyConditionExpression: 'PK = :pk and begins_with(SK, :sk)',
			ExpressionAttributeValues: {
				':pk': this.companyManagerService._companyPreferences.uuid,
				':sk': this.getDryRunSortKey(dryrunUUID),
			},
		};

		return this.tokenVendingMachine.getAwsCredentials().pipe(
			switchMap(_ => {
				const ddb = new AwsSDK.DynamoDB.DocumentClient();
				const observable = from(ddb.query(params).promise());
				return observable
			}),
			switchMap((data) => {
				if (!data.Items?.length) {
					return of([null]);
				}
				const convertedData = data.Items.map(item => new  CloudDryRunItem(item));
				const satelliteId = convertedData.find(x => x.satelliteId)?.satelliteId;
				return forkJoin(
					[
						of(convertedData),
						satelliteId ? this.programManagerService.getPrograms(satelliteId): of([]),
						satelliteId ? this.stationManagerService.getStationsListForSatellite(satelliteId): of([])
					]
				);
			}),
			map(([originalData, programs, stations]) => {
				if (!originalData) {
					return null;
				}
				return this.handleDataForItem(originalData, programs, stations);
			}),
			catchError((err) => {
				console.error('AWS DynamoDB Error: ', err);
				return of(null);
			})
		);
	}

	// Manage sort key

	private handleDataForItem(data: CloudDryRunItem[], programList: Program[], stationList: StationListItem[]): DryRunResult {
		const result = {
			DryRun: [],
			DryRunFlowDatum: [],
			DryRunFlowDatumDict: {},
			DryRunProgramDatum: {},
			DryRunFlowDatumStation: []

		};
		data.forEach(item => {
			const sortKey = item.sK;
			if(sortKey.includes('DryRunProgramDatum')) {
				if (item.programShortName) {
					const programShortNameKey = `${item.programShortName}`;
					if(!result.DryRunProgramDatum[programShortNameKey]) {
						result.DryRunProgramDatum[programShortNameKey] = [];
					}
					const dryRunProgramDatum = new DryRunProgramDatum(item);
					const programData = programList.find(p => p.id === dryRunProgramDatum.programId);
					dryRunProgramDatum.program = programData;
					dryRunProgramDatum.programName = programData?.name;
					result.DryRunProgramDatum[programShortNameKey].push(dryRunProgramDatum);
				}
			} else if (sortKey.includes('DryRunFlowDatum')) {
				if (sortKey.includes('DryRunFlowDatumStation')) {
					result.DryRunFlowDatumStation.push(item);
				} else {
					item['dryRunFlowDatumStation'] = [];
					result.DryRunFlowDatum.push(item);
					result.DryRunFlowDatumDict[item.sK] = item;
				}
			} else {
				result.DryRun.push(item);
			}
		});

		// Handle DryRunFlowDatum join DryRunFlowDatumStation
		result.DryRunFlowDatumStation.forEach(item => {
			const separatedSK = item.sK.split("#");
			const sryRunFlowDatumSK = `${separatedSK[0]}#${separatedSK[1]}#${separatedSK[2]}#${separatedSK[3]}`;
			if(result.DryRunFlowDatumDict[sryRunFlowDatumSK]) {
				if (!result.DryRunFlowDatumDict[sryRunFlowDatumSK]['dryRunFlowDatumStation']) {
					result.DryRunFlowDatumDict[sryRunFlowDatumSK]['dryRunFlowDatumStation'] = [];
				}
				const station = stationList.find(s => s.id === item.stationId);
				item.stationName = station?.name;
				result.DryRunFlowDatumDict[sryRunFlowDatumSK]['dryRunFlowDatumStation'].push(item);
			}
		});

		const dryRunResult: DryRunResult = new DryRunResult({
			flowData: result.DryRunFlowDatum,
			runningPrograms: result.DryRunProgramDatum,
			totalFlow: result.DryRun[0].totalFlow,
			dryRunId: null, // this is cloud, it's always null
			dryRunUUID: result.DryRun[0].sK.split(/#/)[1]
		});

		dryRunResult.flowData = dryRunResult.flowData.sort((a, b) => {return (a.timeOffset.valueOf() - b.timeOffset.valueOf())});
		return dryRunResult;
	}

	private get DryRunTableName(): string {
		return this.env.cloudDryRunTableName;
	}

	private getDryRunSortKey(id: string) {
		return `DryRun#${id}`;
	}

}

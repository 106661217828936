<ng-template #container>
	<rb-notification-snackbar
		*ngIf="userRequestAccess"
		class="notification-snackbar"
		[header]="'STRINGS.ACCESS_REQUESTED' | translate"
		[message]="requestAccessNote"
		[type]="RbEnums.Common.NotificationSnackbarType.Action"
		[disabledCloseFromOutside]="true">

		<div handlingActions class="action-container">
			<span>{{'STRINGS.ADMIN_REQUESTED_ACCESS_ACTION_NOTE' | translate}}<span class="request-access-code">{{userRequestAccess?.rbtsCode}}</span></span>
			<button
				mat-button
				type="button"
				class="rb-secondary-button dismiss-button"
				color="primary"
				(click)="dismiss()"
			>{{'STRINGS.DISMISS' | translate}}</button>
		</div>
	</rb-notification-snackbar>
</ng-template>

<div class="report-site-list-wrapper">
	<div *ngIf="reportName.length > 0" class="report-name-section">
		<div class="report-name-label">{{'STRINGS.REPORT_NAME' | translate}}*</div>
		<div class="report-name">{{reportName}}
			<span *ngIf="startDateString.length > 0 && endDateString.length > 0"> -
				<span class="date">{{startDateString}}</span> -
				<span class="date">{{endDateString}}</span>
			</span>
		</div>
		<hr />
	</div>

	<div class="site-list-container">
		<div *ngFor="let site of sitesList" class="site" [class.selected]="selectedSite.name === site.name && selectedSite.id === site.id"
			 (click)="onSiteClicked(site)">
			{{site.name}}
		</div>
	</div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddressBookEntry } from '../../../api/address-book/models/address-book-entry.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import ReportFormatType = RbEnums.Common.ReportFormatType;

@Component({
	selector: 'rb-share-recipients-dialog',
	templateUrl: './share-recipients-dialog.component.html',
	styleUrls: [ './share-recipients-dialog.component.scss' ]
})
export class ShareRecipientsDialogComponent {

	@Input() isSelfHandleDialogClose = false;
	@Input() contactsList: AddressBookEntry[];
	@Input() showFormatSelection = true;
	@Output() complete = new EventEmitter<AddressBookEntry[]>();

	selectedContacts: AddressBookEntry[] = [];
	selectedReportFormat = ReportFormatType.PDF;

	constructor() { }

	get allContactsSelected(): boolean { return this.selectedContacts.length === this.contactsList.length; }

	isContactSelected(contact: AddressBookEntry) {
		return this.selectedContacts.some(c => c.id === contact.id);
	}

	onAllContactSelectionChange(checked: boolean) {
		if (checked) {
			this.selectedContacts = [...this.contactsList];
		} else {
			this.selectedContacts = [];
		}
	}

	onContactSelectionChange(contact: AddressBookEntry, checked: boolean) {
		if (checked) {
			this.selectedContacts.push(contact);
		} else {
			this.selectedContacts = this.selectedContacts.filter(c => c.id !== contact.id);
		}
	}

	onSave() {
		this.selectedContacts.map(x => x.reportFormat = this.selectedReportFormat);
		this.complete.emit(this.selectedContacts);
	}

	onCancel() {
		this.complete.emit([]);
	}

	onReportFormatChange(event) {
		this.selectedReportFormat = event.value;
	}
}

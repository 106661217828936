import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
// import { NotificationActions as Notifications } from '../core/actions/notification.actions';
// import { NotificationsSelectors } from './../core/selectors/notifications.selectors';

@Component({
	selector: 'rb-global-snack-bar',
	styleUrls: ['./global-snack-bar.component.scss'],
	templateUrl: './global-snack-bar.component.html',
	animations: [
		trigger('slideInFadeOut', [
			state('in', style({ opacity: 1, transform: 'translateY(0%)'})),
			transition('void => *', [
				style({
					opacity: 0,
					transform: 'translateY(100%)',
				}),
				animate('0.2s ease-out'),
			]),
			transition('* => void', [
				animate('0.6s ease-out', style({
					opacity: 0,
					transform: 'translateY(0%)',
				})),
			]),
		]),
	],
})
export class GlobalSnackBarComponent {

	notifications: any[];		// TODO: MW - Strongly type (INotification)

	constructor (
		// TODO: MW - Wire up Notifications if desired. Does not appear to be used anywhere (other than test).

		// private notificationsSelectors: NotificationsSelectors,
		// private notifications: Notifications,
	) { }

	// notifications$ = this.notificationsSelectors.getNotifications();
	//
	// dismissPrompt = (id: number): void => this.notifications.dismissNotification(id);

	dismissPrompt(id: any) {
		// this.notifications.dismissNotification(id)
	}
}

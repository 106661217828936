import { ProgramIdentifier } from './program-identifier.model';

export class StationPrograms {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.programReferencers) {
				this.programReferences = json.programReferencers.map(item => new ProgramIdentifier(item));
				delete this['programReferencers'];
			}
		}
	}

	/**
	 * Station ID of a station for which the user searched.
	 */
	stationId: number;

	/**
	 * Station name for the station.
	 */
	stationName: string;

	/**
	 * List(ProgramIdentifier) describing which programs, groups, steps reference the station. Note that since a station can
	 * be in multiple programs and can be referenced multiple times in a single program in golf, this will represent each of
	 * those cases. If the list is empty, the station is not referenced in a program.
	 */
	programReferences: ProgramIdentifier[];
}

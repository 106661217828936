<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} << </div>

<div
  cdkDrag
  [cdkDragDisabled]="!isDraggable"
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragBoundary=".cdk-overlay-container"
  class="note-container has-meta"
  [class.unread]="!isRead"
  [class.editing]="note.isEditing"
  [class.compact]="noteLayout === NoteLayout.Compact"
  [class.new-note]="noteLayout === NoteLayout.Single"
  *ngIf="!isLoading">
  <div class="meta" cdkDragHandle>
    <div class="attached-to-type" *ngIf="noteLayout !== NoteLayout.Normal">
      <div class="icon-container">
        <i class="mdi mdi-golf text-yellow" *ngIf="note.attachedToType === NoteAnchor.Hole"></i>
        <i class="mdi mdi-vector-square text-green" *ngIf="note.attachedToType === NoteAnchor.Area"></i>
        <i class="station no-drag-icon" *ngIf="note.attachedToType === NoteAnchor.Station">
          <div class="outer-circle">
            <div class="inner-circle">
            </div>
          </div>
        </i>
        <i class="mdi mdi-message-processing" *ngIf="note.attachedToType === NoteAnchor.Note"></i>
      </div>
      <span class="name" *ngIf="noteLayout !== NoteLayout.Compact">{{ note.attachedToName }}</span>
    </div>
    <span class="badge bg-red" *ngIf="note.notePriority !== NotePriority.None && (noteLayout === NoteLayout.Compact || noteLayout === NoteLayout.Single)">
      {{ 'STRINGS.URGENT' | translate }}
    </span>
    <div class="author" *ngIf="noteLayout !== NoteLayout.Compact && noteLayout !== NoteLayout.Single">
      <div class="avatar">
        <span class="initials">{{ getUserInitials(note.authorName) }}</span>
      </div>
      <strong>{{ note.authorName }}</strong>
    </div>
    <i class="mdi mdi-check-circle text-light-green resolved-icon" *ngIf="note.status === NoteStatus.Resolved"></i>
    <div class="button-container ml-auto" *ngIf="showLocateButton">
      <button mat-button class="icon-only" (click)="onNoteLocateClick($event, note)" [matTooltip]="'STRINGS.LOCATE_NOTE' | translate">
        <i class="mdi mdi-crosshairs"></i>
      </button>
    </div>
    <div class="button-container ml-auto" *ngIf="canPin && showPinButton(note)">
      <button mat-button class="icon-only text-green" (click)="unpinNote()" *ngIf="note.isPinned">
        <i class="mdi mdi-pin-off-outline"></i>
      </button>
    </div>
    <div class="button-container" [class.ml-auto]="!showLocateButton && (!canPin || !showPinButton(note))"
      *ngIf="(!note.isEditing && noteLayout !== NoteLayout.Single)">
      <button mat-button [matMenuTriggerFor]="noteMenu" class="icon-only" *ngIf="note.status === NoteStatus.Active">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #noteMenu="matMenu" class="note-menu">
        <button mat-menu-item class="icon-only" (click)="note.isEditing = true">
          <i class="mdi mdi-pencil text-yellow"></i>
          <span>{{ 'STRINGS.EDIT' | translate }}</span>
        </button>
        <button mat-menu-item class="icon-only" (click)="pinNote()"
          *ngIf="canPin && showPinButton(note) && !note.isPinned">
          <i class="mdi mdi-pin-outline"></i>
          <span>{{ 'STRINGS.PIN_TO_TOP' | translate }}</span>
        </button>
        <button mat-menu-item class="icon-only" (click)="unpinNote()"
          *ngIf="canPin && showPinButton(note) && note.isPinned">
          <i class="mdi mdi-pin-off-outline"></i>
          <span>{{ 'STRINGS.UNPIN' | translate }}</span>
        </button>
        <button mat-menu-item class="icon-only" (click)="resolveNote(note)">
          <i class="mdi mdi-check-circle-outline text-light-green"></i>
          <span>{{ 'STRINGS.RESOLVE' | translate }}</span>
        </button>
      </mat-menu>
    </div>
    <div class="button-container ml-auto" *ngIf="note.isEditing">
      <button mat-button class="icon-only" (click)="note.isEditing = false">
        <i class="mdi mdi-close"></i>
      </button>
    </div>
  </div>
  <div class="meta bottom" *ngIf="noteLayout !== NoteLayout.Single">
    <div class="attached-to-type" *ngIf="noteLayout !== NoteLayout.Compact">
      <div class="icon-container">
        <i class="mdi mdi-golf text-yellow" *ngIf="note.attachedToType === NoteAnchor.Hole"></i>
        <i class="mdi mdi-vector-square text-green" *ngIf="note.attachedToType === NoteAnchor.Area"></i>
        <i class="station no-drag-icon" *ngIf="note.attachedToType === NoteAnchor.Station">
          <div class="outer-circle">
            <div class="inner-circle"></div>
          </div>
        </i>
        <i class="mdi mdi-message-processing" *ngIf="note.attachedToType === NoteAnchor.Note"></i>
      </div>
      <span class="name" *ngIf="noteLayout !== NoteLayout.Compact">{{ note.attachedToName }}</span>
    </div>
    <span class="badge bg-red" *ngIf="note.notePriority > 1 && noteLayout !== NoteLayout.Compact">
      {{ 'STRINGS.URGENT' | translate }}
    </span>
    <div class="author" *ngIf="noteLayout === NoteLayout.Compact">
      <div class="avatar">
        <span class="initials">{{ getUserInitials(note.authorName) }}</span>
      </div>
      <strong>{{ note.authorName }}</strong>
    </div>
    <span class="date ml-auto">{{ note.createdDateUtc ? (note.createdDateUtc | date: 'short') : 'just now' }}</span>
  </div>

  <div class="message-container" cdkDragDisabled>
    <textarea *ngIf="(user.userId === note.authorId && note.isEditing) || noteLayout === NoteLayout.Single; else notEditable"
      [(ngModel)]='note.content'
      (input)="onInput($event)"
      [mentionConfig]="mentionConfig"
      placeholder="Type your message here...">
    </textarea> 
    <ng-template #notEditable>
      <span class="textarea" [innerHtml]="highlightUserTags(note.content)"></span>
    </ng-template>
  </div>

  <div class="replies-container" *ngIf="note.attachedToType === NoteAnchor.Note &&
  note.replies && note.replies.length">
    <div class="note-container" *ngFor="let reply of note.replies">
      {{ reply.content }}
    </div>
  </div>

  <div class="note-actions" *ngIf="(user.userId === note.authorId && note.isEditing) || noteLayout === NoteLayout.Single">
    <div class="button-container">
      <button mat-button class="priority-button"
        [matTooltip]="'STRINGS.MARK_AS_URGENT' | translate"
        [class.bg-red]="note.notePriority === 5"
        (click)="toggleNoteUrgency(note)">
        <i class="mdi mdi-alert-outline"></i>
      </button>
      <rb-tip-container [tipText]="'STRINGS.NOTE_MENTION_TIP' | translate"></rb-tip-container>
    </div>
    <div class="button-container mr-0 ml-auto">
      <button mat-button color="primary" class="save-button" (click)="saveNote()" [disabled]="note.content === ''">
        <i class="mdi mdi-plus-circle" *ngIf="!note.isEditing"></i>
        <i class="mdi mdi-content-save" *ngIf="note.isEditing"></i>
      </button>
    </div>
  </div>
</div>
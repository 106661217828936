export class CourseViewHole {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	id: number;
	name: string;
	number: number;
	isGolfArea: boolean;
}

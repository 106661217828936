import {
	HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthManagerService } from './auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from '../../common/services/environment.service';
import { Injectable } from '@angular/core';
import { MessageBoxInfo } from '../../core/components/global-message-box/message-box-info.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	private networkErrorActive = false;

	constructor(private authManager: AuthManagerService,
				private translate: TranslateService,
				private broadcastService: BroadcastService,
				private env: EnvironmentService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler):
		Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

		const accessToken = this.authManager.accessToken;
		const tokenType = this.authManager.tokenType;
		let copiedRequest = req;

		if (accessToken != null && tokenType != null && (req.url.startsWith(this.env.apiUrl) || req.url.startsWith(this.env.licenseApiCloudUrl))) {
			// Add authorization header to calls to our API
			copiedRequest = req.clone({ headers: req.headers.append('Authorization', `${tokenType} ${accessToken}`) });
			copiedRequest = copiedRequest.clone({ headers: copiedRequest.headers.append('Cache-Control', 'no-cache') });
			copiedRequest = copiedRequest.clone({ headers: copiedRequest.headers.append('Pragma', 'no-cache') });
		} else if (req.url.startsWith("assets/")) {
			// RB-14741: Add cache-control: no-cache to assets folder items. We do this in code hoping to make the cache behavior
			// happen with the current deployment without forcing a manual cache-clear as we need if we're changing the server
			// headers.
			copiedRequest = copiedRequest.clone({ headers: copiedRequest.headers.append('Cache-Control', 'no-cache') });
		}

		// Reset the "active" timeout timer
		if (copiedRequest.url.indexOf('banner-message.json') === -1) {
			this.authManager.setUserIsActive();
		}

		// Make the network call
		return next.handle(copiedRequest)
			.pipe(
				catchError((error: any) => {
					if (error instanceof HttpErrorResponse && !this.networkErrorActive && (error.status === 0 || (error.status > 400 && error.status < 500))) {
						this.networkErrorActive = true;
						if (error.url.indexOf('api.worldweatheronline.com') < 0) {
							const mbi =
								MessageBoxInfo.create(this.translate.instant('STRINGS.NETWORK_ERROR'), this.translate.instant('STRINGS.NETWORK_ERROR_RETRY'),
							RbEnums.Common.MessageBoxIcon.Error, this.onNetworkErrorDismiss.bind(this), null, RbEnums.Common.MessageBoxButtons.Ok);
							this.broadcastService.showMessageBox.next(mbi); // Do this directly rather than thru MessageBoxService because of injection issues
						}
					}
					if (typeof(error.error) !== 'string') { error.error = error.message; }
					return throwError(error);
				}));
	}

	private onNetworkErrorDismiss() {
		this.networkErrorActive = false;
	}
}

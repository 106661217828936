import { CCWeatherCondition } from './ccweather-condition.model';

export class CCWeatherSettings {

	static readonly DefaultElevationFT: number = 500.0;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	/**
	 * The dashboard widget ID value. This allows settings lookup based on the dashboard info.
	 */
	widgetId: number = null;

	conditions: CCWeatherCondition[] = [];
	visibleBlocks: {name: string, show: boolean}[] = [];
	siteId: number = null;
	weatherSourceId?: number = null;

	/**
	 * The database widget ID value. This allows settings lookup based on the database info.
	 */
	databaseWidgetId: number = null;

	/**
	 * The database location ID value.
	 */
	locationId: number = null;

	/**
	 * Elevation in ft is used to calculate ET0 so we need a real, default value. This will be settable in the widget settings in the
	 * user's unit system.
	 */
	elevationFT: number = CCWeatherSettings.DefaultElevationFT;
}

import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'rb-new-software-version-available',
	templateUrl: './new-software-version-available.component.html',
	styleUrls: ['./new-software-version-available.component.scss'],
})
export class NewSoftwareVersionAvailableComponent {
	@HostBinding('class') class = 'rb-new-software-version-available';

	// =========================================================================================================================================================
	// C'tor and Init
	// =========================================================================================================================================================

	constructor(private router: Router) { }

	onLearnMoreButtonClick() {
		this.router.navigate(['/systemsetup/admin/softwareupdate']);
	}
}

import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { Sensor } from './sensor.model';
import GolfSensorKingdom = RbEnums.Common.GolfSensorKingdom;

export abstract class GolfSensor extends Sensor {

	constructor(json: any = null) {
		super(json);

		if (json) {
			this.golfSensorKingdom = json.golfSensorKingdom;
		}
	}

	golfSensorKingdom: GolfSensorKingdom;

}

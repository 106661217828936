import * as moment from 'moment';

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { filter, finalize, forkJoin, take } from 'rxjs';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ActivatedRoute } from '@angular/router';
import { AdditionalAction } from '../../../../../core/components/dialog/dialog-additional-action.model';
import { AdvanceStation } from '../../../../../api/stations/models/advance-station.model';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { ConnectDataPackManagerService } from '../../../../../api/connect-data-pack/connect-data-pack-manager.service';
import { Controller } from '../../../../../api/controllers/models/controller.model';
import { ControllerManagerService } from '../../../../../api/controllers/controller-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { JobChange } from '../../../../../api/signalR/job-change.model';
import { ManualOpsManagerService } from '../../../../../api/manual-ops/manual-ops-manager.service';
import { MessageBoxInfo } from '../../../../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from '../../../../../common/services/message-box.service';
import { ProgramListItem } from '../../../../../api/programs/models/program-list-item.model';
import { ProgramManagerService } from '../../../../../api/programs/program-manager.service';
import {
	ProgramMobileCellRendererComponent
} from '../../../../../shared-ui/components/tables/mobile-cell-renderers/program-mobile-cell-renderer/program-mobile-cell-renderer.component';
import { RbConstants } from '../../../../../common/constants/_rb.constants';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { StartStationModel } from '../../../../../api/manual-ops/models/start-station.model';
import { StationListItem } from '../../../../../api/stations/models/station-list-item.model';
import { StationManagerService } from '../../../../../api/stations/station-manager.service';
import {
	StationMobileCellRendererNewComponent
} from '../../../../../shared-ui/components/tables/mobile-cell-renderers/station-mobile-cell-renderer-new/station-mobile-cell-renderer-new.component';
import { StationsListChange } from '../../../../../api/stations/models/stations-list-change.model';
import {
	StationStatusCellRendererNewComponent
} from '../../../../../shared-ui/components/tables/cell-renderers/station-status-cell-renderer-new/station-status-cell-renderer-new.component';
import { TableWrapperComponent } from '../../../../../shared-ui/components/tables/table-wrapper/table-wrapper.component';
import { TbosManagerService } from '../../../../../api/tbos/tbos-manager.service';
import { TranslateService } from '@ngx-translate/core';

import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;

enum Tab {
	Stations,
	Programs
}

interface IDurationSettings {
	hours: number;
	minutes: number;
	seconds: number;
}

@UntilDestroy()
@Component({
  selector: 'rb-irrigation-queue-commands',
  templateUrl: './irrigation-queue-commands.component.html',
  styleUrls: ['./irrigation-queue-commands.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IrrigationQueueCommandsComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild("manualOpsTab", { static: false }) manualOpsTab: MatTabGroup;
	@ViewChild('stationsTable') stationsTable: TableWrapperComponent;
	@ViewChild('programsTable') programsTable: TableWrapperComponent;
	@ViewChild('durationDialog', { static: true }) durationDialog;

	StationMobileCellRendererNewComponent = StationMobileCellRendererNewComponent;
	ProgramMobileCellRendererComponent = ProgramMobileCellRendererComponent;
	StationStatusCellRendererNewComponent = StationStatusCellRendererNewComponent;

	@Output() complete = new EventEmitter<{ savedData: boolean }>();
	@Output() startStations = new EventEmitter();
	@Output() onControllerConnect = new EventEmitter();

	private _isMatDialogOpen = false;
	@Input() set isMatDialogOpen(value: boolean) {
		this._isMatDialogOpen = value;
	}
	@Input() isConnected;
	@Input() isSatelliteTbos;
	get isMatDialogOpen(): boolean { return this._isMatDialogOpen; }

	isBusy = false;
	isMobile = false;
	canDialogBoxComponentBeShown = false;
	isRetrievingDataPacks = false;
	showWaitingIndicator = false;
	componentIsInitialized = false;
	stations: StationListItem[];
	programs: ProgramListItem[];
	loadError = false;
	selectedTabIndex = 0;
	selectedStations: StationListItem[];
	selectedPrograms: ProgramListItem[];
	spinnerText = '';
	duration = moment.duration({ minutes: 5 });
	selectedStartingStationIds: number[] = [];
	showMinuteOnly = true;
	controller: Controller;
	tbosServerId: number;
	cancelAllAction: AdditionalAction = {
		actionText: this.translateService.instant('STRINGS.CANCEL_ALL_UPPERCASE'),
		color: 'warn',
		emit: () => this.cancelAll(),
		isDisabled: true,
		isLoading: false,
	};

	private controllerId: number;
	private _isConnecting = false;
	private _isCancelingQueue = false;


	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private broadcastService: BroadcastService,
				private controllerManager: ControllerManagerService,
				private connectDataPackManager: ConnectDataPackManagerService,
				private deviceManager: DeviceManagerService,
				private manualOpsManager: ManualOpsManagerService,
				private messageBoxService: MessageBoxService,
				private programManager: ProgramManagerService,
				private route: ActivatedRoute,
				private stationManager: StationManagerService,
				private cdr: ChangeDetectorRef,
				private translateService: TranslateService,
				private tbosService: TbosManagerService
	) { }

	ngOnInit() {
		this.isMobile = this.deviceManager.isMobile;
		this.controllerId = +this.route.snapshot.queryParams.controllerId;
		if (this.controllerId && this.isSatelliteTbos) {
			this.tbosService.getServerSatellite(this.controllerId)
			.subscribe((tbosServerController: Controller) => this.tbosServerId = tbosServerController.id);		
		}	
		this.watchComponentChange();
		this.setStationManagerSubscriptions();
		this.setConnectDataPackManagerSubscriptions();
		this.getComponentData();

		this.initializeDuration();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.isConnected) {
			if (!changes.isConnected.currentValue) {
				this.connectController();
			} else {
				// get the controller id if it's not set yet
				if (!this.controllerId){
					this.controllerId = +this.route.snapshot.queryParams.controllerId;
				}
				this.getComponentData();
			}
		}
	}

	ngOnDestroy() {
		/** Required by untilDestroyed() */
	}

	@Input() set isConnecting(value: boolean) {
		if (value) {
			this.spinnerText = this.translateService.instant('STRINGS.CONNECTING_TO_CONTROLLER');
		}
		this._isConnecting = value;
		this.showWaitingIndicator = value;
	}

	get isConnecting() {
		return this._isConnecting;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	cancelAll() {
		this.cancelAllAction.isLoading = true;
		this.controllerManager.stopAllIrrigation([this.controllerId])
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.cancelAllAction.isLoading = false;
					this.cancelAllAction.isDisabled = true;
					this.showWaitingIndicator = false;
				})
			)
			.subscribe({
				next: () => {
					this.cancelAllAction.isDisabled = true;
					this._isCancelingQueue = true;
				},
				error: _ => {
					this.resetIsRetrievingFlag();
					this.messageBoxService.showMessageBox('SPECIAL_MSG.UNABLE_TO_STOP_IRRIGATION');
				}
			});
	}

	onStationSelect(selectedStations: StationListItem[]) {
		this.selectedStations = selectedStations;
	}

	onProgramSelect(selectedPrograms: ProgramListItem[]) {
		this.selectedPrograms = selectedPrograms;
	}

	onCancel() {
		this.complete.emit({ savedData: false });
	}

	/**
	 * Call from StationStatusCellRendererNewComponent to avoid row and cell click duplicated event
	 *
	 * @param data
	 * @returns
	 */
	onCellClick = (data: StationListItem) => {
		if (this.selectedTabIndex === Tab.Stations) {
			this.isBusy = true;
			const existedIndex = this.selectedStations?.find(selected => selected.id === data.id);
			if (!!existedIndex) {
				this.onStart();
				return;
			}

			this.updateDurationToLocalStorage();
			this.startSingleStation(data);
		}
	}

	onStart() {
		if (this.selectedTabIndex === Tab.Programs) {
			if (!this.selectedPrograms || this.selectedPrograms.length < 1) { return; }
			this.confirmProgramStart();
		} else {
			if (!this.selectedStations || this.selectedStations.length < 1) { return; }
			this.updateDurationToLocalStorage();
			this.startStationQueue();
		}
	}

	onDurationSet(duration: moment.Duration) {
		if (!duration) {
			return;
		}

		this.duration = duration;
	}

	onActionErrorAcknowledged() {
		this.complete.emit({ savedData: false });
	}

	onTabChange(event: MatTabChangeEvent) {
		this.selectedTabIndex = event.index;

		if (this.selectedTabIndex === Tab.Programs) {
			this.selectedStations = [];
			this.stationsTable?.unSelectAll();
		} else {
			this.selectedPrograms = [];
			this.programsTable?.unSelectAll();
		}
	}

	connectController() {
		this.onControllerConnect.emit();
	}

	// =========================================================================================================================================================
	// Getters/Setters
	// =========================================================================================================================================================

	get isAbleToStart() {
		return this.selectedPrograms?.length >= 1 || this.selectedStations?.length >= 1;
	}

	get durationInSecond() {
		return this.duration.asMilliseconds() / 1000;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private watchComponentChange() {
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => this.isMobile = isMobile);

		this.broadcastService.getDataPacksRequestComplete
			.pipe(untilDestroyed(this))
			.subscribe(() => this.resetIsRetrievingFlag());

		this.broadcastService.startProgramJobRequestCompleted
			.pipe(untilDestroyed(this))
			.subscribe(() => this.resetIsRetrievingFlag());

		this.broadcastService.startStationJobRequestCompleted
			.pipe(
				untilDestroyed(this),
				filter((jobChange: JobChange) => jobChange.satelliteId === this.controllerId)
			)
			.subscribe(() => this.resetIsRetrievingFlag());

		this.broadcastService.tbosManualCommandJobRequestCompleted
			.pipe(
				untilDestroyed(this),
				filter((jobChange: JobChange) => jobChange.satelliteId === this.tbosServerId)
			)
			.subscribe(() => this.resetIsRetrievingFlagAndCloseDialog());		


		this.broadcastService.advanceStationJobRequestCompleted
			.pipe(
				untilDestroyed(this),
				filter((jobChange: JobChange) => jobChange.satelliteId === this.controllerId)
			)
			.subscribe(() => this.resetIsRetrievingFlag());

		// Monitor changes to the programs
		this.programManager.programsListChange
			.pipe(untilDestroyed(this))
			.subscribe((programs: ProgramListItem[]) => {
				if (this.controllerId && programs) {
					const newPrograms = programs.filter(p => p.satelliteId === this.controllerId);
					if (!newPrograms?.length) return;
					// Try to merge lists rather than updating whole grid
					if (RbUtils.Collection.update(this.programs, newPrograms)) {
						if (this.programsTable) this.programsTable.refreshCells(true);
					} else {
						this.programs = newPrograms;
					}
				} else {
					this.getComponentData();
				}
			});

		// Monitor changes to the stations
		this.stationManager.stationsListChange
			.pipe(
				untilDestroyed(this),
				filter((stationsListChange: StationsListChange) => stationsListChange.controllerId === this.controllerId)
			)
			.subscribe(() => this.getComponentData());
	}

	private setStationManagerSubscriptions() {
		this.stationManager.stationsStatusesUpdateCompleted
			.pipe(
				untilDestroyed(this),
				filter((controllerId: number) => this.controllerId === controllerId)
			)
			.subscribe(() => this.getComponentData());
	}

	private setConnectDataPackManagerSubscriptions() {
		this.connectDataPackManager.connectDataPacksJobStart
			.pipe(
				untilDestroyed(this),
				filter((data: { controllerId: number }) => data.controllerId === this.controllerId)
			)
			.subscribe(() => this.isRetrievingDataPacks = true);

		this.connectDataPackManager.connectDataPacksJobFinish
			.pipe(
				untilDestroyed(this),
				filter((data: { controllerId: number }) => data.controllerId === this.controllerId)
			)
			.subscribe(() => this.resetIsRetrievingFlag());
	}

	private getComponentData() {
		const sources = {
			stations: this.stationManager.getStationsList(this.controllerId).pipe(take(1)),
			programs: this.programManager.getProgramsListForController(this.controllerId).pipe(take(1))
		};

		forkJoin(sources).subscribe({
			next: ({ stations, programs }) => {
				// Try to merge lists rather than updating whole grid
				if (RbUtils.Collection.update(this.stations, stations)) {
					if (this.stationsTable) this.stationsTable.refreshCells(true);
				} else {
					this.stations = stations;
				}
				if (RbUtils.Collection.update(this.programs, programs)) {
					if (this.programsTable) this.programsTable.refreshCells(true);
				} else {
					this.programs = programs;
				}

				const hasRunningStations = stations.some(
					s => ![RbEnums.Common.IrrigationStatus.Dash, RbEnums.Common.IrrigationStatus.Idle,
						RbEnums.Common.IrrigationStatus.ReadyToRun, RbEnums.Common.IrrigationStatus.Stopping].includes(s.irrigationStatus)
				);
				if (hasRunningStations) {
					if (this.cancelAllAction.isDisabled) {
						this.cancelAllAction.isDisabled = false;
					}
				} else {
					this.cancelAllAction.isDisabled = true;
				}

				this.modifyStartStationsStatus(stations);
				this.cdr.detectChanges();
			},
			error: (error) => {
				this.loadError = error.error || error.message || this.translateService.instant('STRINGS.NETWORK_ERROR_RETRY');
			}
		});
	}

	private modifyStartStationsStatus(stations: StationListItem[]) {
		if (this.isSatelliteTbos){
			stations.forEach(s => {
				if (s.irrigationStatus === RbEnums.Common.IrrigationStatus.Dash) {
					s.irrigationStatus = RbEnums.Common.IrrigationStatus.Idle;
				}
			});
		}
		if (this.selectedStartingStationIds?.length) {
			const startingStations = stations.filter(s =>
				!!this.selectedStartingStationIds.find(
					selected => selected === s.id
				)
			);

			// RB-13964: if we cancel all queued items, we won't modify the status anymore
			if (this._isCancelingQueue) {
				this._isCancelingQueue = false;
				this.selectedStartingStationIds = [];
				return;
			}

			startingStations.forEach(s => {
				if (s.irrigationStatus === RbEnums.Common.IrrigationStatus.Idle || s.irrigationStatus === RbEnums.Common.IrrigationStatus.Pending) {
					s.irrigationStatus = RbEnums.Common.IrrigationStatus.Pending;
					s.status = this.translateService.instant('STRINGS.PENDING');
				} else {
					this.selectedStartingStationIds = startingStations.map(starting => starting.id).filter(filtering => filtering !== s.id);
				}
			});
		}
	}

	private requestStartStations(data: any) {
		this.selectedStartingStationIds.push(data.id);
		this.manualOpsManager.startStations(new StartStationModel([data?.id], [this.durationInSecond]), this.controllerId)
			.pipe(take(1))
			.pipe(finalize(() => this.getComponentData()))
			.subscribe({
				next: () => {
					this.modifyStartStationsStatus(this.stations.slice(0, 2));
					this.getDataPacks(false);
					this.cdr.detectChanges();
				},
				error: () => this.showDataFetchError()
			});
	}

	private requestAdvanceStations(data: any) {
		const advanceStation = new AdvanceStation(data.programId, data.id);
		this.manualOpsManager.advanceStations([advanceStation], true, this.controllerId)
			.pipe(take(1))
			.pipe(finalize(() => this.getComponentData()))
			.subscribe({
				next: () => {
					this.getDataPacks(false);
					this.cdr.detectChanges();
				},
				error: () => this.showDataFetchError()
			});
	}

	private getDataPacks(showWaitIndicator = false) {
		this.controllerManager.getDatapacks(this.controllerId)
			.pipe(untilDestroyed(this))
			.pipe(finalize(() => {
				this.isRetrievingDataPacks = true;
				if (showWaitIndicator) {
					this.showWaitingIndicator = true;
				}
			}))
			.subscribe({
				next: () => {},
				error: () => this.showDataFetchError()
			});
	}

	private showDataFetchError() {
		this.isBusy = false;
		this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED');
	}

	private resetIsRetrievingFlag() {
		this.isRetrievingDataPacks = false;
		this.showWaitingIndicator = false;
	}

	private startStationQueue() {
		this.isBusy = true;
		this.spinnerText = `${this.translateService.instant(this.selectedStations.length > 1 ? 'STRINGS.STARTING_STATIONS' : 'STRINGS.STARTING_STATION')}...`;
		const stationIds = this.selectedStations.map(s => s.id);
		const runDurations = stationIds.map(_ => this.durationInSecond);

		const newStationIdInQueues = stationIds.filter(selected => this.selectedStartingStationIds.findIndex(existed => selected === existed) < 0);
		this.selectedStartingStationIds = [...this.selectedStartingStationIds, ...newStationIdInQueues];
		this.modifyStartStationsStatus(this.stations);

		this.manualOpsManager.startStations(new StartStationModel(stationIds, runDurations), this.controllerId)
			.pipe(take(1))
			.pipe(finalize(() => this.getComponentData()))
			.subscribe({
				next: () => {
					this.getDataPacks();
					this.stationsTable?.unSelectAll();
				},
				error: () => this.showDataFetchError()
			});
	}

	private confirmProgramStart() {
		const msgInfo = new MessageBoxInfo(
			'STRINGS.START_PROGRAMS_HINT',
			MessageBoxIcon.Information,
			'STRINGS.START_PROGRAMS',
			() => this.startProgramQueue(),
			null,
			RbEnums.Common.MessageBoxButtons.OkCancel
		);
		this.messageBoxService.showMessageBox(msgInfo);
	}

	private startProgramQueue() {
		this.isBusy = true;
		this.spinnerText =
			`${this.translateService.instant(this.selectedPrograms.length > 1 ? 'STRINGS.STARTING_PROGRAMS' : 'STRINGS.STARTING_PROGRAM')}...`;
		const selectedProgramIds = this.selectedPrograms.map(p => p.id);

		this.manualOpsManager.startPrograms(selectedProgramIds)
			.pipe(finalize(() => this.getComponentData()))
			.subscribe({
				next: () => {
					this.getDataPacks(true);
					this.manualOpsTab.selectedIndex = Tab.Stations;
					this.selectedPrograms = [];
					this.programsTable?.unSelectAll();
				},
				error: () => this.showDataFetchError()
			});
	}

	private startSingleStation(data: StationListItem) {
		switch (data.irrigationStatus) {
			case RbEnums.Common.IrrigationStatus.Running: {
				data.irrigationStatus = RbEnums.Common.IrrigationStatus.Advancing;
				data.status = `${this.translateService.instant('STRINGS.ADVANCING_STATION')}...`;

				this.requestAdvanceStations(data);
				return;
			}
			case RbEnums.Common.IrrigationStatus.Idle:
			case RbEnums.Common.IrrigationStatus.Dash: {
				this.requestStartStations(data);
				return;
			}
			default: {
				return;
			}
		}
	}

	private initializeDuration() {
		const durationSettings: IDurationSettings = JSON.parse(localStorage.getItem(RbConstants.Common.MANUAL_OPS_DURATION_KEY));
		if (durationSettings) {
			if (durationSettings.hours || durationSettings.seconds) {
				this.showMinuteOnly = false;
			}
			this.duration = moment.duration(durationSettings);
		}
	}

	private updateDurationToLocalStorage() {
		const durationSettings = {
			hours: this.duration.hours(),
			minutes: this.duration.minutes(),
			seconds: this.duration.seconds(),
		}
		localStorage.setItem(RbConstants.Common.MANUAL_OPS_DURATION_KEY, JSON.stringify(durationSettings));
	}

	private resetIsRetrievingFlagAndCloseDialog() {
		this.isRetrievingDataPacks = false;
		this.showWaitingIndicator = false;
		this.complete.emit({ savedData: false });
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-contact-mobile-cell-container">
	<div class="mobile-cell-fav-container">
		<i class="material-icons star-icon" [class.mobile-selected]="contact.isFavorite">{{contact.isFavorite ? 'star' : 'star_border'}}</i>
	</div>
	<div class="ellipsis-text">{{contact?.name}}</div>
</div>


import { FlowChartItem } from './flow-chart-item.model';
import { ProgramChange } from '../../signalR/program-change.model';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationStatusChange } from '../../signalR/station-status-change.model';

export class ActiveFlowChartItem extends FlowChartItem {

	constructor(json: any = null) {
		super();

		if (json) {
			Object.assign(this, json);

			if (json.timestamp) { this.timestamp = RbUtils.Conversion.convertStringToDate(json.timestamp); }
			if (json.runningProgramGroups) { this.runningProgramGroups = json.runningProgramGroups.map(rpg => new ProgramChange(rpg)); }
			if (json.runningPrograms) { this.runningPrograms = json.runningPrograms.map(rp => new ProgramChange(rp)); }
			if (json.runningStations) { this.runningStations = json.runningStations.map(rs => new StationStatusChange(rs)); }
			if (json.totalFlowByPump) { 
				this.totalFlowByPump = new Map<number, number>(
					Object.entries(json.totalFlowByPump).map(([key, value]) => [+key, +value])); 
			}
		}
	}

	runningProgramGroups: ProgramChange[] = [];
	runningPrograms: ProgramChange[] = [];
	runningStations: StationStatusChange[] = [];

	get totalFlow(): number {
		if (this._totalFlow != null) { return this._totalFlow; }

		if (this.runningStations.length < 1) return 0;

		return this.runningStations.reduce((a, b) => a + b.currentFlowRateGPM, 0);
	}

	set totalFlow(value: number) {
		this._totalFlow = value;
	}

	/**
	 * totalFlowByPump, calculated by the API, gives the flow amount in GPM for each pump. If stations are not
	 * associated with a pump, they will be totaled in pumpId=0, so that may need some special handling. NOTE:
	 * It is NOT the case that every pump will be represented here; if a pump is not listed, its flow should be
	 * treated as 0 GPM.
	 */
	totalFlowByPump: Map<number, number> = new Map<number, number>();
}

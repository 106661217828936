import { Component } from '@angular/core';
import { DryRunEventListItem } from '../../../../../api/dry-run/models/dry-run-event-list-item.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-time-cell-renderer',
	templateUrl: './time-cell-renderer.component.html',
	styleUrls: ['./time-cell-renderer.component.scss']
})
export class TimeCellRendererComponent implements ICellRendererAngularComp {
	dryRunEventListItem: DryRunEventListItem;

	constructor() {
	}
	RbEnums = RbEnums;
	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.setCellData(params);
	}

	refresh(params: any): boolean {
		this.setCellData(params);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setCellData(params: any) {
		this.dryRunEventListItem = params.data;
	}
}

import { CCWeatherDataService, WeatherTimeStep } from '../../../../api/ccweather/ccweather-data.service';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CCWeatherApiService } from '../../../../api/ccweather/ccweather-api.service';
import { CultureSettings } from '../../../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../../../api/culture-settings/culture-settings-manager.service';
import { RbGridsterWidget } from '../../../../dashboard/components/widgets/rb-gridster-widget';
import { RbUtils } from '../../../../common/utils/_rb.utils';
import { SiteManagerService } from '../../../../api/sites/site-manager.service';
import { WeatherSource } from '../../../../api/weather-sources/models/weather-source.model';
import { WeatherSourceManagerService } from '../../../../api/weather-sources/weather-source-manager.service';

@UntilDestroy()
@Component({
	selector: 'rb-ccweather-widget',
	templateUrl: './ccweather-widget.component.html',
	styleUrls: ['./ccweather-widget.component.scss']
})
export class CCWeatherWidgetComponent implements OnInit, OnDestroy, OnChanges {
	@Input() userId: number;
	@Input() widgetId: number;
	@Input() databaseWidgetId: number;
	@Input() isInitialized: boolean;
	@Input() visibleBlocks: string[];
	@Input() showSettings: boolean;
	@Input() isIq4SidebarWidget = false;
	@Input() associatedWidget: RbGridsterWidget;
	@Input() theme = '';

	private _siteId: number;
	@Input() set siteId(value: number) {
		if (!value || value === this._siteId) { return; }

		this._siteId = value;
		/*this.fetchWeather();*/
	}

	private _companyId: number;
	@Input() set companyId(value: number) {
		if (!value || value === this._companyId) { return; }

		this._companyId = value;
	}

	@Input() callRefresh = new EventEmitter();
	@Input() callSettings = new EventEmitter();

	@Output() settingsClosed = new EventEmitter<boolean>();

	isHourlyVisible = true;
	isDailyVisible = true;
	lastUpdated = '';

	/**
	 * Abbreviation string describing the timezone. For Arizona, this is something like "USMST", while for Colorado, it's more like "". It's used
	 * for time conversion to course time for Last Updated display, etc.
	 */
	timezoneAbbreviation = '';

	timezoneName = '';
	getWeatherInterval = null;
	cultureSettings: CultureSettings;
	dataFetchRetryCount = 0;

	constructor(private ccWeatherApiService: CCWeatherApiService,
				private ccWeatherDataService: CCWeatherDataService,
				private siteManager: SiteManagerService,
				private cultureSettingsManager: CultureSettingsManagerService,
				private weatherSourceManager: WeatherSourceManagerService
	) {
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe((cultureSettings: CultureSettings) => this.cultureSettings = cultureSettings);

		this.getCultureSettings();

		// Handle site changes. Some of the things we're worried about are address/location changes and time zone changes.
		this.siteManager.sitesUpdate
			.pipe(untilDestroyed(this))
			.subscribe((updates: { siteIds: number[], data: any }) => this.refreshSiteInfo());

		this.siteManager.siteDeleted
			.pipe(untilDestroyed(this))
			.subscribe(() => this.refreshSiteInfo());
	}

	ngOnInit() {
		this.showSettings = !this.isInitialized;

		this.ccWeatherDataService.noDataForWidget
			.pipe(
				untilDestroyed(this),
				switchMap(() => {
					if (this.siteManager.isGolfSite) {
						this.ccWeatherApiService.updateGolfForecasts().subscribe(() => this.callRefresh.emit());
						return;
					}

					// If we have no data for the selected weather location, try no more than 3 additional times to get data.
					if (this.dataFetchRetryCount++ < 3) {
						return this.weatherSourceManager.collectWeatherData(this.associatedWidget.gwpWeatherSource.id);
					}
				})
			)
			.subscribe(() => setTimeout(() => this.callRefresh.emit(), 1000));

		this.ccWeatherDataService.weatherWidgetNotInitialized
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				// Need to initialize location weather forecast - i.e., "save settings"'

				// IQ4 Sidebar Widget
				if (!this.isIq4SidebarWidget || this.associatedWidget?.gwpWeatherSource == null) return;

				const gwpWeatherSource = <WeatherSource>this.associatedWidget.gwpWeatherSource;
				this.ccWeatherApiService.createLocation(
					{
						name: gwpWeatherSource.name,		// this.selectedSiteName,
						siteId: this.associatedWidget.siteId,
						weatherSourceId: this.isIq4SidebarWidget ? this.associatedWidget.gwpWeatherSource.id : null,
						latitude: gwpWeatherSource.latitude,
						longitude: gwpWeatherSource.longitude,
						elevationFT: gwpWeatherSource.elevation,
						timeZone: gwpWeatherSource.timeZone
					})
					.pipe(
						take(1),
						untilDestroyed(this)
					)
					.subscribe((data) => {
						// Update associated widget with 'data' info.
						this.associatedWidget.databaseWidgetId = data.widgetId;
						this.associatedWidget.locationId = data.locationId;

						// Now that we've created the location/widget, save the information about it.
						this.ccWeatherDataService.setSettings(this.associatedWidget.conditions, this.associatedWidget.visibleBlocks,
							this.associatedWidget.siteId, this.widgetId, data.locationId, data.widgetId, gwpWeatherSource.elevation);

						this.callRefresh.emit();
					});
			});

		this.callRefresh.subscribe(() => {
			if (this.isInitialized) {
				if (!this.ccWeatherDataService.getWeatherForecastRequest(this.widgetId, true)) {
					// There was an error in the widget. Show the settings page.
					console.error(`Weather forecast widget getWeatherForecastRequest returned false during refresh. Showing settings page.`);
					this.isInitialized = false;
					this.showSettings = true;
				}
				this.startTimer(this.widgetId);
			}
		});

		if (this.isInitialized) {
			if (!this.ccWeatherDataService.getWeatherForecastRequest(this.widgetId)) {
				// There was an error in the widget. Show the settings page.
				console.error(`Weather forecast widget getWeatherForecastRequest returned false when isInitialized already true. Showing settings page.`);

				// IQ4 Sidebar Widget
				if (!this.isIq4SidebarWidget) {
					this.isInitialized = false;
					this.showSettings = true;
				} else {
					this.ccWeatherDataService.weatherWidgetNotInitialized.emit();
				}
			}
			this.startTimer(this.widgetId);
		}
		this.getLastUpdated();
		this.ccWeatherDataService.weatherForecastLoaded.subscribe(() => {
			this.getLastUpdated();
		});

		if (this._siteId != null) {
			this.refreshSiteInfo();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.visibleBlocks) {
			this.isHourlyVisible = this.visibleBlocks.find(block => block === 'HOURLY_FORECAST') !== undefined;
			this.isDailyVisible = this.visibleBlocks.find(block => block === 'DAILY_FORECAST') !== undefined;
		}
	}

	ngOnDestroy() {
		clearInterval(this.getWeatherInterval);
	}

	/** Get the selected site if any and update the time zone information used elsewhere. */
	private refreshSiteInfo() {
		if (this._siteId != null) {
			// Use the site's time zone *abbreviation*. Note that this is NOT "UTC-07:00" or something like that, as that does not
			// capture DST information; Denver and Tucson would have the same time zone offset string, but their times during the
			// summer are different.
			this.siteManager.getSite(this._siteId)
				.pipe(take(1))
				.subscribe(site => {
						this.timezoneName = site.timeZone;
						this.timezoneAbbreviation = site.timeZoneAbbreviation;

						// Get the last-updated data and display it in the time zone of the site we reference.
						this.getLastUpdated();
					},
					error => {
						// Clear the site, as we apparently don't have one (it may have been deleted, for example).
						this._siteId = null;
					});
		}
	}

	private getCultureSettings() {
		this.cultureSettings = RbUtils.User.cultureSettings;

		if (this.cultureSettings === undefined || this.cultureSettings === null) {
			setTimeout(() => this.getCultureSettings(), 100);
			return;
		}
	}

	getLastUpdated() {
		const lastUpdated = this.ccWeatherDataService.getWeatherForecast(this.widgetId, WeatherTimeStep.CURRENT).intervals[0].startTime;
		if (lastUpdated) {
			const date = new Date(lastUpdated);
			const dateFormat = RbUtils.Common.getDateFormat(this.cultureSettings);
			const timeFormat = RbUtils.Common.getTimeFormat(this.cultureSettings);
			this.lastUpdated = RbUtils.Date.transform(date, dateFormat + ' ' + timeFormat, this.timezoneAbbreviation);
		} else {
			this.lastUpdated = '-';
			if (this.getWeatherInterval) {
				clearInterval(this.getWeatherInterval);
			}
			this.getWeatherInterval = setInterval(() => {
				if (!this.ccWeatherDataService.getWeatherForecastRequest(this.widgetId)) {
					// Although this would be odd, the widget is apparently mis-configured. Show the settings page.
					console.error(`Weather forecast widget getWeatherForecastRequest returned false in getLastUpdated. Showing settings page.`);
					this.isInitialized = false;
					this.showSettings = true;
				}
			}, 5 * 60 * 1000 /*5min*/);
		}
	}

	onCloseSettings(isSaveSettings) {
		this.showSettings = false;
		this.settingsClosed.emit(isSaveSettings);
		if (isSaveSettings) {
			if (!this.ccWeatherDataService.getWeatherForecastRequest(this.widgetId)) {
				// This should not happen, so if it does console log an error. Note that we don't prevent the settings
				// from closing or reshow them.
				console.error(`Weather forecast widget getWeatherForecastRequest returned false in onCloseSettings. Contact the factory!`);
			}
			this.startTimer(this.widgetId);
		}
	}

	startTimer(widgetId: number) {
		if (this.getWeatherInterval) {
			clearInterval(this.getWeatherInterval);
		}
		this.getWeatherInterval = setInterval(() => {
			if (!this.ccWeatherDataService.getWeatherForecastRequest(this.widgetId)) {
				// There was some sort of lookup issue for the widget. This is highly unexpected, but we show settings as
				// an indicator that work needs to be done.
				console.log(`Weather forecast widget getWeatherForecastRequest returned false in startTimer. Showing settings page.`);
				this.isInitialized = false;
				this.showSettings = true;
			}
		}, 60 * 60 * 1000 /*1h*/);
	}

}

<ng-container *ngIf="options">
	<div class="alert-modal-container"
		[ngStyle]="{'max-width': options.maxWidth}"
		[ngClass]="[options.alertStyle]">
		<i *ngIf="options.crossBtnEvent" class="icon-cancel" (click)="onCrossBtnClick()"></i>
		<div class="a-header">
			<i *ngIf="options?.iconColor" [ngClass]="[options.icon]" [ngStyle]="{'color': options.iconColor}"></i>
			<div class="a-title" *ngIf="options?.title">{{options.title }}</div>
		</div>

		<div class="a-content">
			<ng-content></ng-content>
		</div>

		<div class="a-bottom">
			<div class="a-btn-group">
				<ng-container *ngFor="let btn of options.buttonGroups">
					<button role="button" (click)="btn.action()" class="a-btn in-arr-btn" [ngClass]="btn.classString" [disabled]="btn.isDisabled">{{btn.text}}</button>
				</ng-container>
				<div *ngIf="options.secondaryBtnText" (click)="onSecondaryClick()" class="a-btn a-secondary-btn">{{options.secondaryBtnText }}</div>
				<div *ngIf="options.primaryBtnText"
					(click)="onPrimaryClick()" class="a-btn a-primary-btn"
					[ngStyle]="{'background': options.primaryBtnColor}"
					[ngClass]="{'alert-style': options.alertStyle === 'alert'}"
					>
					<i *ngIf="options?.primaryBtnIcon"
						class="button-icon"
						[ngClass]="[options.primaryBtnIcon]"
						[ngStyle]="{'color': options.primaryBtnTextColor}"></i>
					{{options.primaryBtnText }}
				</div>
			</div>
		</div>

		<div class="rb-sub-alert-modal-body">
			<ng-content select="[sub-content]"></ng-content>
		</div>
	</div>

	
</ng-container>
import * as moment from 'moment';
import { Moment } from 'moment';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { WorldWeatherWeatherDesc } from './world-weather-weather-desc.model';
import { WorldWeatherWeatherIconUrl } from './world-weather-weather-icon-url.model';

export class WorldWeatherCurrentCondition {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.weatherIconUrl) this.weatherIconUrl = json.weatherIconUrl.map(url => new WorldWeatherWeatherIconUrl(url));
			if (json.weatherDesc) this.weatherDesc = json.weatherDesc.map(desc => new WorldWeatherWeatherDesc(desc));
			if (json.observation_time) {
				// RB-7148: When sent by WWO, the time of the last observation is in normal English format hh:mm:ss ap.
				// Take care when calling moment.utc(). Previous code converted to string, then passed to moment.utc()
				// which won't work if string is formatted differently than moment expects because the OS has different
				// settings. Calling moment.utc(date) works fine.
				const date = RbUtils.Conversion.convertTimeStringToDate(json.observation_time);
				this.observation_time = moment.utc(date);
			}
		}
	}

	observation_time: Moment;
	temp_C: string;
	temp_F: string;
	weatherCode: string;
	weatherIconUrl: WorldWeatherWeatherIconUrl[];
	weatherDesc: WorldWeatherWeatherDesc[];
	windspeedMiles: string;
	windspeedKmph: string;
	winddirDegree: string;
	winddir16Point: string;
	precipMM: string;
	precipInches: string;
	humidity: string;
	visibility: string;
	visibilityMiles: string;
	pressure: string;
	pressureInches: string;
	cloudcover: string;
	FeelsLikeC: string;
	FeelsLikeF: string;
	uvIndex: number;

	private _timeZone = '';
	get currentTemp(): string {
		return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ? this.temp_F : this.temp_C;
	}

	get dateString(): string {
		return RbUtils.Date.transform(this.observation_time, RbUtils.User.dateFormat);
	}

	get timeString(): string {
		return RbUtils.Date.transform(moment(), RbUtils.User.timeFormat, this._timeZone);
	}

	set timeZone(value) {
		this._timeZone = value;
	}
}

<button
	class="flex items-center button-with-spinner"
	[ngStyle]="{'width': width, 'justify-content': justifyContent, 'margin-top': marginTop, 'margin-bottom': marginBottom }"
	[disabled]="disabled"
>
	<span>{{ label }}</span>
	<span *ngIf="showSpinner" class="flex items-center pl2">
		<img class="progress-spinner--button" src="../../../assets/loader.svg" />
	</span>
</button>

/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, ViewChild } from '@angular/core';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { CellEditorBase } from './_cell-editor-base';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class TextInputCellEditorBase  extends CellEditorBase implements ICellEditorAngularComp {
	@ViewChild('i') textInput;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected broadcastService: BroadcastService,
				protected translateService: TranslateService,
	) {
		super(broadcastService, translateService);
	}

	// =========================================================================================================================================================
	// agGrid Methods
	// =========================================================================================================================================================

	afterGuiAttached(params?: IAfterGuiAttachedParams) {
		this.textInput.nativeElement.focus();
		if (!this.params.charPress) { setTimeout(() => this.textInput.nativeElement.select()); }
	}

	// Return the value from this component to the grid.
	getValue() {
		return this.textInput.nativeElement.value;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onKeyDown(event) {
		if (this.isNavigationKey(event.key)) {
			event.stopPropagation();

			if (!this.hasFieldValidation) {
				this.navigateOnKeyDown(event.key, event.shiftKey);
			} else {
				this.validateAndNavigate(this.textInput.nativeElement.value, event.key, event.shiftKey);
			}
		}
	}

}

import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class StartTimeAndStatus {

	groupSource = false;
	startTime: Date;
	status: RbEnums.Common.ScheduleStatusType;
	startTimeId: number;
	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.startTime) { this.startTime = RbUtils.Conversion.convertStringToDate(json.startTime); }
		}
	}
}

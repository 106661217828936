export class CommResource {

	displayName: string;
	id: string;
	interfaceTypeId: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

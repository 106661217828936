<div *ngIf="!isGolfSite" class="areas-mobile-cell commercial">
	<div class="name">{{area.name}}</div>
	<div class="number">
		<span class="label">{{'STRINGS.NUMBER' | translate}}:</span>
		<span class="value">{{area.number}}</span>
	</div>
</div>

<div *ngIf="isGolfSite && !isSubarea" class="areas-mobile-cell">
	<div class="flex">
		<div class="name-and-stations">
			<div class="name">{{area.name}}</div>
			<div class="stations">
				<span class="label">{{'STRINGS.STATIONS' | translate}}:</span>
				<span class="value">{{area.numberOfStations}}</span>
			</div>
		</div>
		<button mat-button type="button" class="mat-button mat-primary bordered-icon no-text" color="primary" (click)="addSubarea($event, area)">
			<mat-icon class="mat-icon material-icons mat-icon-no-color">add</mat-icon>
		</button>
	</div>
</div>

<div *ngIf="isGolfSite && isSubarea" class="areas-mobile-cell">
	<div class="name-and-runtime">
		<div class="name ellipsis-text">{{subarea.name}}</div>
		<div class="stations">
			<span class="label">{{'STRINGS.STATIONS' | translate}}:</span>
			<span class="value">{{area.numberOfStations}}</span>
		</div>
	</div>
	<div class="soak">
		<span class="label">{{'STRINGS.DEFAULT_RUNTIME' | translate}}:</span>
		<span class="value">{{subarea.defaultRunTime}}</span>
	</div>
</div>

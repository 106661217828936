import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CultureSettings } from '../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../api/culture-settings/culture-settings-manager.service';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class ConversionService implements OnDestroy {

	cultureSettings: CultureSettings;

	// =========================================================================================================================================================
	// C'tor, Destroy
	// =========================================================================================================================================================

	constructor(private cultureSettingsManager: CultureSettingsManagerService
	) {
		// Monitor culture changes to show proper units.
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe((cultureSettings: CultureSettings) => {
				this.cultureSettings = cultureSettings;
			});

		this.getCultureSettings();
	}

	ngOnDestroy(): void {
		/** Required for untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	/** Converts Total Flow Rate from English (gallons) to user's selected Unit Type. */
	getUserTotalFlowString(value: number): string {
		const convertedRate = RbUtils.Common.ToVolume(value, RbEnums.Common.UnitsType.English, this.cultureSettings.unitType);
		return Number.isInteger(convertedRate)
			? convertedRate.toString()
			: RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator, convertedRate.toString());
	}

	getUserLargeVolumeValueString(value: number): string {
		const convertedValue = RbUtils.Common.ToVolume(value, RbEnums.Common.UnitsType.English, this.cultureSettings.unitType);
		return Number.isInteger(convertedValue)
			? convertedValue.toString()
			: RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator,
													convertedValue.toString(),
													this.cultureSettings.unitType === RbEnums.Common.UnitsType.Metric2 ? 1 : 0);
	}

	getUserFlowRate(value: number): number {
		return RbUtils.Common.ToFlow(value, RbEnums.Common.UnitsType.English, this.cultureSettings.unitType);
	}

	/** Converts Flow Rate from English (gpm) to user's selected Unit Type. */
	getUserFlowRateString(value: number, precision = 2): string {
		if ( value === null || value === undefined ) return '';
		const convertedRate = this.getUserFlowRate(value);
		return Number.isInteger(convertedRate)
			? convertedRate.toFixed(precision)
			: RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator, convertedRate.toString());
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getCultureSettings() {
		this.cultureSettings = RbUtils.User.cultureSettings;

		if (this.cultureSettings === undefined || this.cultureSettings === null) {
			setTimeout(() => this.getCultureSettings(), 100);
			return;
		}
	}
}

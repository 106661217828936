import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { MatMenuPanel } from '@angular/material/menu';
import { RbUtils } from '../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-sidebar-header',
	templateUrl: './sidebar-header.component.html',
	styleUrls: ['./sidebar-header.component.scss']
})
export class SidebarHeaderComponent implements OnInit {

	@Output() isFavoriteChange = new EventEmitter<boolean>();
	@Output() searchClick = new EventEmitter();
	@Output() onDiagnosticRun = new EventEmitter();
	@Output() onBackSidebar = new EventEmitter();
	@Output() onShowNotes = new EventEmitter();
	
	@Input() showDiagnosticButton = false;
	@Input() showNotesButton = false;
	@Input() overflowMenu: MatMenuPanel;
	@Input() helpContent: MatMenuPanel;
	@Input() showFavButton = false;
	@Input() showHelpButton = false;
	@Input() showOverflowButton = false;
	@Input() showStatusButton = false;
	@Input() showSearchButton = false;
	@Input() statusColor: string;
	@Input() title: string = null;
	@Input() showBackButton = false;

	private _isFavorite = false;
	matToolTip: string;
	isGolfSite = false;
	@Input() set isFavorite(value: boolean) {
		this._isFavorite = value;
		this.isFavoriteChange.next(value);
	}
	get isFavorite(): boolean { return this._isFavorite; }

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService) { }

	ngOnInit() {
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		this.matToolTip = this.isGolfSite ? 'STRINGS.PROGRAMS_AND_SCHEDULES' : 'STRINGS.PROGRAMS';
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onSearch() {
		this.searchClick.emit();
	}

	// =========================================================================================================================================================
	// Emit diagnostic run
	// =========================================================================================================================================================
	showDiagnostic() {
		this.onDiagnosticRun.emit();
	}

	goBack() {
		this.onBackSidebar.emit();
	}
	
	showNotes() {
		this.onShowNotes.emit();
	}

}

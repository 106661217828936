import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { ApiService } from '../_common/api.service';
import { CommResource } from './models/comm-resource.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CommInterfaceApiService extends ApiService {

	clearCache() {
		super.clearCache(this.getCommResourcesUrl);
		super.clearCache(this.getCommResourcesTypeUrl);
		super.clearCache(this.getCommResourcesPortUrl);
	}

	getCommResources(bypassCache = false): Observable<ApiCachedRequestResponse<CommResource[]>> {
		return this.apiRequestWithCache<any>(this.getCommResourcesUrl, bypassCache, result => result.map(c => new CommResource(c)));
	}

	getCommResourcesType(bypassCache = false): Observable<ApiCachedRequestResponse<CommResource[]>> {
		return this.apiRequestWithCache<any>(this.getCommResourcesTypeUrl, bypassCache, result => result.map(c => new CommResource(c)));
	}

	getCommResourcesPort(bypassCache = false): Observable<ApiCachedRequestResponse<CommResource[]>> {
		return this.apiRequestWithCache<any>(this.getCommResourcesPortUrl, bypassCache, result => result.map(c => new CommResource(c)));
	}
	getUniqueIPAddressesForSatellites(bypassCache = false): Observable<ApiCachedRequestResponse<string[]>> {
		return this.apiRequestWithCache<any>(this.getUniqueIPAddressesForSatellitesUrl, bypassCache, result => result.map(c => new String(c)));
	}
	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}CommInterface`; }
	private get getCommResourcesUrl(): string { return `${this.baseUrl}/GetCommResources`; }
	private get getUniqueIPAddressesForSatellitesUrl(): string { return `${this.baseUrl}/GetUniqueIPAddressesForSatellites`; }
	private get getCommResourcesTypeUrl(): string { return `${this.baseUrl}/GetCommResourcesType`; }
	private get getCommResourcesPortUrl(): string { return `${this.baseUrl}/GetCommResourcesPort`; }
}

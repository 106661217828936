import { forkJoin, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthManagerService } from '../auth/auth-manager-service';
import { AvailableUpdate } from './models/available-update.model';
import { BroadcastService } from '../../common/services/broadcast.service';
import { EntityVersionManagerService } from '../entity-versions/entity-version-manager.service';
import { Injectable } from '@angular/core';
import { LicenseManagerService } from '../license/license-manager.service';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { SoftwareUpdateApiService } from './software-update-api.service';
import { UiSettingsService } from '../ui-settings/ui-settings.service';
import { UpdateHistory } from './models/update-history.model';

@Injectable({
	providedIn: 'root'
})
export class SoftwareUpdateManagerService extends ServiceManagerBase {
	// The customer number is used as key to retrieve the dismissed date/time
	customerNumber: string;
	newUpdateAvailable = new Subject();

	constructor(private softwareUpdateApiService: SoftwareUpdateApiService,
				protected broadcastService: BroadcastService,
				private licenseManager: LicenseManagerService,
				private versionManager: EntityVersionManagerService,
				private uiSettingService: UiSettingsService,
				private authManager: AuthManagerService
				) {

		super(broadcastService);
	}

	protected clearCache() {
		this.softwareUpdateApiService.clearCache();
	}

	GetApplicableUpdates(customerNumber: number, activationCode: string, installedVersion: string): Observable<AvailableUpdate[]> {
		return this.softwareUpdateApiService.GetApplicableUpdates(customerNumber, activationCode, installedVersion);
	}

	GetUpdateHistory(customerNumber: number, activationCode: string): Observable<UpdateHistory[]> {
		return this.softwareUpdateApiService.GetUpdateHistory(customerNumber, activationCode);
	}

	GetUpdateDismissTime(): Observable<Date> {
		return this.uiSettingService.getPreference(this.customerNumber).pipe(map(x => {
			if (x) {
				return new Date(x);
			} else {
				return null;
			}
		}));
	}

	UpdateDismissTime(dismissUntilDate: Date) {
		return this.uiSettingService.setPreference(this.customerNumber, dismissUntilDate);
	}

	CheckForNewUpdate() {
		forkJoin([this.licenseManager.getComputerIdAndActivationCodes(), this.versionManager.getCoreVersion()])
			.pipe(take(1))
			.subscribe(([computerIdAndActivationCodes, versionInfo]) => {
				const currentVersion = versionInfo.coreVersion;
				const computerId = computerIdAndActivationCodes.item1;
				this.customerNumber = computerId;
				const activationCode = computerIdAndActivationCodes.item2;
				if (activationCode && computerId && currentVersion) {
					this.GetApplicableUpdates(computerId, activationCode, currentVersion)
					.pipe(take(1))
						.subscribe((updateResults) => {
							const updateForCurrentComputer = updateResults.find(update => update.activationCode === activationCode);
							if (updateForCurrentComputer && updateForCurrentComputer.updates && updateForCurrentComputer.updates.length > 0) {
								this.newUpdateAvailable.next(null);
							}
						},
						_ => {
							// error on get new updates
						}
						);
				}
			},
			_ => {
				// error on get current customer information
			});
	}

	onNewUpdateAvailable(updateInfos: AvailableUpdate[]) {
		// ignore: user is not admin, user is not owner
		if (!this.authManager.isAdmin && !this.authManager.isOwner) {
			return;
		}
		// something wrongs with signalr message, check manually
		if (!updateInfos) {
			this.CheckForNewUpdate();
		}

		// check if update is for this machine
		this.licenseManager.getComputerIdAndActivationCodes().pipe(take(1)).subscribe(computerIdAndActivationCodes => {
			this.customerNumber = computerIdAndActivationCodes.item1;
			const activationCode = computerIdAndActivationCodes.item2;
			if (activationCode) {
				const updateForThisMachine = updateInfos.find(update => update.activationCode === activationCode);
				if (updateForThisMachine && updateForThisMachine.updates && updateForThisMachine.updates.length > 0) {
					this.newUpdateAvailable.next(null);
				}
			}
		});
	}
}

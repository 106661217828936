import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export class InputSelectListItem {
	constructor(public value: any, public display: string) { }
}
@Component({
	selector: 'rb-input-select',
	templateUrl: './input-select.component.html',
	styleUrls: [ './input-select.component.scss' ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputSelectComponent),
			multi: true
		}
	]
})
export class InputSelectComponent implements OnInit {
	@Input() inputType = 'number';
	@Input() list: InputSelectListItem[] = [];
	@Input() placeholder: string;
	@Input() isRequired = false;
	@Input() readonly = false;
	@Input() minVal: number = null;
	@Input() maxVal: number = null;
	@Input() step = 1;

	resetting = false;
	value: any;

	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => { };
	private onTouched = () => { };

	constructor() { }

	ngOnInit() {
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
	}

	writeValue(value: any): void {
		this.value = value;
	}
	//
	// weekdayChecked(weekday: number) {
	// 	return this._weekdays.charAt(weekday) === '1';
	// }
	//
	// onWeekdayToggled(event: MatButtonToggleChange) {
	// 	this._weekdays = RbUtils.Common.replaceCharInStringAtIndex(this._weekdays, event.value, event.source.checked ? '1' : '0');
	// 	this.onTouched();
	// 	this.onChange(this._weekdays);
	// }
	onValueChanged() {
		this.onTouched();
		this.onChange(this.value);
	}

	reset() {
		this.value = null;

		// This is a hack to *ngIf (hide and show) the controls so they reset their touched/dirty state
		setTimeout(() => {
			this.resetting = true;
			setTimeout(() => this.resetting = false, 10);
		});
	}
}

<div class="course-viewer_container" [ngClass]="{'is-displayable': isDisplayable}">

	<div class="course-viewer-toolbar">
		<div class="cv-left-toolbar">
			<div *ngIf="!hideCourseSelector" class="cv-tb-select cv-tb-site-select">
				<mat-select [(ngModel)]="selectedSiteId" (selectionChange)="onSiteSelected()">
					<mat-option *ngFor="let site of sites" [value]="site.id">{{site.name}}</mat-option>
				</mat-select>
			</div>

			<div class="cv-tb-select cv-tb-view-select">
				<mat-select [(ngModel)]="selectedView" (selectionChange)="onViewSelected()">
					<mat-option *ngFor="let view of courseViewViews" [value]="view.value">{{view.name}}</mat-option>
				</mat-select>
			</div>
		</div>

		<div class="cv-right-toolbar" >
			<div class="cv-expando-buttons">
				<rb-icon-button
					icon="mdi mdi-arrow-expand-vertical"
					[customIconNumber]="CustomFontIconNumber.mdi_icons"
					[matTooltip]="'STRINGS.EXPAND_ALL' | translate"
					(click)="accordion.openAll()"
				></rb-icon-button>
				<rb-icon-button
					icon="mdi mdi-format-vertical-align-center"
					[customIconNumber]="CustomFontIconNumber.mdi_icons"
					[matTooltip]="'STRINGS.COLLAPSE_ALL' | translate"
					(click)="accordion.closeAll()"
				></rb-icon-button>
			</div>

			<rb-icon-button
				*ngIf="activityType !== CourseViewActivityType.Diagnostics"
				[matMenuTriggerFor]="settings"
				icon="mdi mdi-dots-horizontal"
				[customIconNumber]="CustomFontIconNumber.mdi_icons"
				[matTooltip]="'STRINGS.COURSE_VIEWER_SETTINGS' | translate"
			></rb-icon-button>
			<mat-menu #settings="matMenu" class="cv-settings-menu" [overlapTrigger]="false">
				<div *ngIf="activityType !== CourseViewActivityType.Active">
					<button mat-menu-item (click)="onToggleGradient()">{{'STRINGS.SHOW_HIDE_COLOR_GRADIENT' | translate}}</button>
					<div class="mat-menu-separator"><hr></div>
				</div>
				<mat-radio-group [(ngModel)]="selectedDataView">
					<div class="cv-data-view-rg-container">
						<div class="cv-data-view-rg-title">Data View:</div>
						<mat-radio-button [value]="CourseViewData.Time">{{'STRINGS.TIME' | translate}}</mat-radio-button>
						<mat-radio-button [value]="CourseViewData.Application" >{{'STRINGS.APPLICATION' | translate}}</mat-radio-button>
						<mat-radio-button [value]="CourseViewData.Rotation" >{{'STRINGS.PASSES' | translate}}</mat-radio-button>
					</div>
				</mat-radio-group>
			</mat-menu>

		</div>
	</div>

	<div id="cv-toolbar-spacer"></div>

	<div class="absolute-child-flex-parent cv-grid-row cv-holes-row">
		<div #holesRow (scroll)="onUpdateHolesRowScroll()" class="absolute-child holes-labels-table-container holes-row-div">

			<table>
				<tr>
					<td *ngFor="let h of selectedViewHoles" class="rb-input-shield-host">
						{{h.number}}
						<div class="rb-input-shield"></div>
					</td>
				</tr>
			</table>

		</div>
	</div>

	<!--    Outline of data area-->
	<div class="cv-hole-data-container">
		<div class="absolute-child cv-accordion-container">

			<mat-accordion [multi]="true" togglePosition="before" displayMode="flat">
				<mat-expansion-panel *ngFor="let area of selectedViewAreas; let i = index" [expanded]="expandedAccordion ? area.ordinals.length > 0 : false">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{area.name}}
							<span class="active-area-stations"
								  [ngClass]="{'not-active': activityType !== CourseViewActivityType.Active}"
								  *ngIf="areaActiveStationsDict[area.id] && areaActiveStationsDict[area.id] > 0"
							>
								<i [class]="areaHeaderIcon"></i>
<!--								<span class="active-area-stations-count"-->
<!--									  [ngClass]="{'is-diagnostics': activityType === CourseViewActivityType.Diagnostics}">{{areaActiveStationsDict[area.id]}}-->
<!--								</span>-->
							</span>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<!-- Station Labels Col -->
					<div *ngIf="area.ordinals.length > 0" class="cv-stations-container">
						<table>
							<tr *ngFor="let areaOrdinal of area.ordinals">
								<td class="rb-input-shield-host">
									{{areaOrdinal.label}}
									<div class="rb-input-shield"></div>
								</td>
							</tr>
						</table>
					</div>


					<!-- Place in template with directive to lazy load panel content -->
					<ng-template matExpansionPanelContent>
						<!-- Station Values Table -->
						<div class="cv-hole-values-container">

							<div #dataRow *ngIf="area.ordinals.length > 0" class="cvhv-table-container" (scroll)="onDataRowScroll($event)">
								<table>
									<tr *ngFor="let areaOrdinal of area.ordinals">
										<td *ngFor="let s of (selectedView === CourseViewView.Holes ? areaOrdinal.holesStationIds : selectedView === CourseViewView.Places ? areaOrdinal.placesStationIds : areaOrdinal.stationIds)">
											<div *ngIf="s === 0" class="no-station-cell rb-input-shield-host">
												-
												<div class="rb-input-shield" [ngClass]="{'not-selectable': activityType === CourseViewActivityType.Active}"></div>
											</div>
											<rb-course-viewer-station
												*ngIf="s !== 0 && activityType !== CourseViewActivityType.Diagnostics"
												[parent]="this"
												[stationId]="s"
												[activityType]="activityType"
												[uniqueValues]="uniqueValues"
												[showGradient]="showGradient"
												[dataView]="selectedDataView"
												[stationData]="stationsDataDict[s]"
												[siteId]="selectedSiteId"
												(selectionChange)="onStationSelectionChange($event)"
												(invokeCreateUser)="onInvokeCreateUser($event)"
											></rb-course-viewer-station>
											<rb-course-viewer-diagnostics-station
												*ngIf="s !== 0 && activityType === CourseViewActivityType.Diagnostics"
												[parent]="this"
												[stationId]="s"
												[stationData]="stationsDataDict[s]"
												[diagnosticsData]="diagnosticsData"
											></rb-course-viewer-diagnostics-station>
										</td>
									</tr>
								</table>
							</div>

							<div id="cv-hole-values-no-stations" *ngIf="area.ordinals.length <= 0">
								{{'STRINGS.NO_STATIONS_IN_SELECTED_AREA' | translate}}
							</div>

						</div>
					</ng-template>


				</mat-expansion-panel>
			</mat-accordion>

		</div>
	</div>

	<div class="absolute-child-flex-parent cv-grid-row cv-holes-row" style="max-height: 25px;">
		<div class="cv-data-view-legend" >{{dataViewLegend | translate}}</div>
		<div #scroller (scroll)="onUpdateScroll()" class="absolute-child holes-labels-table-container scroller-div">
			<table>
				<tr>
					<td *ngFor="let h of selectedViewHoles.length | fill"></td>
				</tr>
			</table>
		</div>
	</div>

</div>

<rb-waiting-indicator
	*ngIf="!hideWaitingIndicator && isBusy"
	[isVisible]="isBusy"
	[text]="spinnerText"
	[error]="loadError">
</rb-waiting-indicator>

<ng-template #stationSearchModal>
	<rb-station-search [stationId]="stationSearchStationId"></rb-station-search>
</ng-template>

<ng-template #stationDiagnosticModal>
	<rb-station-diagnostic *ngIf="stationObs | async as stations" [stations]="stations"></rb-station-diagnostic>
</ng-template>


export class FlowMonitoringStatusResult {

	constructor(json: any = null) {
		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);
		}
	}

	flowZonesShutdownForFlow: number[];

	numberOfFlowZones: number;

	numberOfStations: number;

	stationsShutdownForFlow: number[];

}

export class PinCodeListItem {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	companyId: number;
	description: string;
	id: number;
	userId: number;
	selected: boolean;
}

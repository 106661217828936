<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>
<div class="new-software-version-available">
	<div class="new-software-version-background">
        <div class="center">
		    <div class="text-space">
			    <div>{{'STRINGS.NEW_SOFTWARE_VERSION_AVAILABLE' | translate}}</div>
		    </div>
			<button mat-button class="button-learn-more" (click)="onLearnMoreButtonClick()">{{'STRINGS.LEARN_MORE' | translate}}</button>
        </div>
	</div>
</div>

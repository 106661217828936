/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.SwitchCode.checkValidity
 * 		 See: _rb.utils.ts
 */


import { RbConstants } from '../constants/_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from './_rb.utils';

export namespace XXUseRbUtilsNamespace {

	export abstract class SwitchCode {

		/**
		 * Return an RegEx matching string which specifies the basic format of the golf switchcode value.
		 * @param controllerType - RbEnums.Common.DeviceType describing the controller/interface type for
		 * which the switchcode pattern is needed. For golf, LDISDI, probably.
		 * @returns RegEx value describing valid patterns. Note that we're matching the beginning
		 * and end of the string, so no funny business with non-matching characters in there.
		 */
		static switchCodePattern(controllerType?: RbEnums.Common.DeviceType): { pattern: string, humanReadableString: string } {
			switch(controllerType) {
				case RbEnums.Common.DeviceType.LDISDI:
					// Golf, basically.
					return { pattern: '^59F[3-9A-F][3-9A-F]0$', humanReadableString: '59F[3-F][3-F]0' };
				case null:
				case undefined:
				default:
					// Everything else, especially LXD. Note that this is the custom switch code pattern used when
					// custom is the type.
					return { pattern: '[0-9a-fA-F]{6}$', humanReadableString: '[0-F][0-F][0-F][0-F][0-F][0-F]' };
			}
		}


		/**
		 * Return indication of switchcode validity. We check the value against a regex value returned above
		 * and if matching, valid, if not, invalid.
		 * @param switchCode - string containing the potential switchcode
		 * @param controllerType - RbEnums.Common.DeviceType describing the parent controller for the switch
		 * code.
		 * @returns boolean true if the switchcode should be treated as valid; false if invalid.
		 */
		static isSwitchCodeValidByPattern(switchCode: string, controllerType?: RbEnums.Common.DeviceType): boolean {
			const regEx = new RegExp(this.switchCodePattern(controllerType).pattern);
			return regEx.test(switchCode);
		}

		/**
		 * Validate the hex switchcode value for non-golf controller types. This is a simple range validation,
		 * 0x300000 - 0x5FFFFF.
		 * @param value - string value to be validated
		 * @returns boolean true if valid (in range, inclusive); false otherwise (out of range)
		 */
		static validateHexCodeRange(value: string): boolean {
			// check value is in valid range
			const newValue = RbUtils.Conversion.convertHexStringToDecimal(value);
			return newValue >= RbConstants.Form.SWITCH_CODE_RANGE.min && newValue <= RbConstants.Form.SWITCH_CODE_RANGE.max;
		}


	}
}

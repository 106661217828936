import { Controller } from '../../controllers/models/controller.model';
import { DryRunFlowDatumStation } from './dry-run-flow-datum-station.model';
import { RbUtils } from '../../../common/utils/_rb.utils';
export class DryRunFlowDatum {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.timestamp) this.timestamp = RbUtils.Conversion.convertStringToDate(json.timestamp); // Cloud
			if (json.timeOffset) {
				this.timeOffset = RbUtils.Common.timeOffsetToDisplayTimeDryRun(json.timeOffset);
			}
			if (json.dryRunFlowDatumStation) this.dryRunFlowDatumStation = json.dryRunFlowDatumStation.map(datum => new DryRunFlowDatumStation(datum));
			if (json.satellite) this.satellite = new Controller(json.satellite);
		}
	}

	// RB-12041: DryRunFlowDatumStation and DryRunFlowDatum IDs are now bigint
	id: bigint;
	timeOffset: Date;
	timestamp: Date;
	flowRate = 0;
	stillWateringWhenWaterWindowCloses = false;
	satelliteId: number;
	companyId: number;
	dryRunId: number;
	dryRunFlowDatumStation: DryRunFlowDatumStation[];
	satellite: Controller = null;
	dryRun: any = null;									// TODO: MW - Use Proper Type
	isNull = false;
	params: string;
	isZeroFlowRateDatum = false;
}

import { filter, map, take, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Adjustment } from './models/adjustment.model';
import { AllProgramsProgramStepList } from './models/all-programs-program-step-list.model';
import { AreaManagerService } from '../areas/area-manager.service';
import { AuthManagerService } from '../auth/auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CachedCollection } from '../_common/cached-collection';
import { ConnectDataPackChange } from '../connect-data-pack/models/connect-data-pack-change.model';
import { ConnectDataPackManagerService } from '../connect-data-pack/connect-data-pack-manager.service';
import { ConnectDataPacksDict } from '../connect-data-pack/models/connect-data-packs-dict.model';
import { ControllerSyncState } from '../signalR/controller-sync-state.model';
import { IStationAssignedProgram } from '../stations/models/station-assigned-program.model';
import { ProgramStep } from '../programs/models/program-step.model';
import { ProgramStepApiService } from './program-step-api.service';
import { ProgramStepListItem } from './models/program-step-list-item.model';
import { ProgramStepsListChange } from './models/program-steps-list-change.model';
import { RbConstants } from '../../common/constants/_rb.constants';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { SiteManagerService } from '../sites/site-manager.service';
import { Station } from '../stations/models/station.model';
import { StationListItem } from '../stations/models/station-list-item.model';
import { StationStatusChange } from '../signalR/station-status-change.model';
import { SystemStatusService } from '../../common/services/system-status.service';
import { WeatherSourceManagerService } from '../weather-sources/weather-source-manager.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class ProgramStepManagerService extends ServiceManagerBase implements OnDestroy {

    // Subjects
    programStepsListChange = new Subject<ProgramStepsListChange>();
    allProgramStepStatusesUpdated = new Subject();
    sprinklerDetailRequiredChange = new Subject<boolean>();

    // Cache Containers
    // Key is `${controllerId}-${programId}` for commercial OR `${ProgramGroupId}-${programId}` for Golf
    // It caches the program steps for the controller/program group and program ID combinations to allow easy
    // clearing of the cache for all controller-related, program group related, or program related items.
    _programStepsDict: { [key: string]: CachedCollection<ProgramStepListItem> } = {};

    // =========================================================================================================================================================
    // C'tor, Destroy and ServiceManagerBase Members
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                private areaManager: AreaManagerService,
                protected broadcastService: BroadcastService,
                private connectDataPackManager: ConnectDataPackManagerService,
                private programStepApiService: ProgramStepApiService,
                private systemStatusService: SystemStatusService,
                private weatherSourceManagerService: WeatherSourceManagerService,
                private siteManager: SiteManagerService
    ) {
        super(broadcastService);

        // RB-6156: Make sure that, if we reverse sync back some new program steps, the user will see them the next
        // time the program step data is loaded and won't see a cached version. It's a bit aggressive to clear the
        // whole cache, including steps for programs on controllers that haven't been synched, but the number of
        // loaded steps should not be too large and the number needed at any given moment for user display is quite
        // low.
        this.broadcastService.syncStateChange
            .pipe(
                untilDestroyed(this),
                filter((syncState: ControllerSyncState) => syncState.syncState === RbEnums.Common.ControllerSyncState.Synchronized)
            )
            .subscribe((syncState: ControllerSyncState) => {
                if (syncState.isSynchronizing) return;
                // Find all references to the changed controller and update the program steps for all those programs.
				this.getRunStationStatusForSatellite(syncState.controllerId).subscribe(result => {
                    const statuses = result.filter(r => r.satelliteId === syncState.controllerId);
                    const keysToUpdate = Object.keys(this._programStepsDict).filter(key => key.startsWith(`${syncState.controllerId}-`));
                    keysToUpdate.forEach(key => {
                        const programId = +(key.split('-')[1]);
                        const programResult = statuses.find(s => s.programId === programId);
                        if (programResult == null) return;
                        this.updateFromProgramStepList(syncState.controllerId, programId, programResult.runStationStatuses);
                    });
                });
            });

        this.connectDataPackManager.connectDataPacksChange
            .pipe(untilDestroyed(this))
            .subscribe((connectDataPackChange: ConnectDataPackChange) => {
                setTimeout(() => this.updateProgramStepStatuses(connectDataPackChange));
            });

        this.systemStatusService.stationStatusChange
            .pipe(untilDestroyed(this))
            .subscribe((stationStatusChange: StationStatusChange) => this.updateGolfProgramStepStatuses(stationStatusChange));

        this.broadcastService.associatedEntityChange
            .pipe(
                untilDestroyed(this),
                filter(entity => entity === RbConstants.Cache.PROGRAM_STEP)
            )
            .subscribe(() => this.clearCache());

        // Since we localize values within the data, we must clear the cache when the app language is changed.
        this.broadcastService.cultureChanged
            .pipe(untilDestroyed(this))
            .subscribe(() => this.clearCache());

        // we must clear the cache when the Water Budget is changed.
        this.systemStatusService.companyStatusUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() => this.clearCache());

        // We need to clear the cache when Site Seasonal Adjust is changed
        this.siteManager.sitesUpdate
            .pipe(
                untilDestroyed(this),
                // [RB-11198]: only clear cache if change contains seasonal adjust
                filter(changes => changes &&
                    changes.data &&
                    Array.isArray(changes.data) &&
                    changes.data.find(data => data && data.path === '/seasonalAdjust'))
            )
            .subscribe(_ => {
                this.clearCache();
            });

        this.areaManager.areaChange.pipe(untilDestroyed(this)).subscribe(() => this.clearCache());
        this.areaManager.subAreaChange.pipe(untilDestroyed(this)).subscribe(() => this.clearCache());

        if (RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType)) {
            this.weatherSourceManagerService.weatherSourcesChange
                .pipe(untilDestroyed(this))
                .subscribe(() => this.clearCache());
        }
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    protected clearCache() {
        this._programStepsDict = {};
    }

    // =========================================================================================================================================================
    // Public Properties and Methods
    // =========================================================================================================================================================

    createProgramSteps(controllerId: number, programId: number, stations: Station[], isGolf: boolean): Observable<null> {
        const apiData = stations.map(station => ({
            actionId: 'RunStation',
            programId: programId.toString(),
            runTimeLong: (isGolf) ? null : station.defaultRunTimeLong,
            stationId: station.id,
        }));
        return this.programStepApiService.createProgramSteps(programId, apiData, isGolf)
            .pipe(tap(() => this.updateProgramStepsListAndNotify(controllerId, programId)));
    }

    createProgramStepsForStationListItem(controllerId: number, programId: number, stations: StationListItem[]): Observable<null> {
        const apiData = stations.map(station => ({
            actionId: 'RunStation',
            programId: programId.toString(),
            runTimeLong: station.defaultRunTimeLong,
            stationId: station.id,
        }));
        return this.programStepApiService.createProgramSteps(programId, apiData, false)
            .pipe(tap(() => this.updateProgramStepsListAndNotify(controllerId, programId)));
    }

    deleteProgramSteps(controllerId: number, programId: number, programStepIds: number[]): Observable<void> {
        return this.programStepApiService.deleteProgramSteps(programStepIds)
            .pipe(tap(() => this.updateProgramStepsListAndNotify(controllerId, programId)));
    }

    getProgramStep(programId: number, programStepId: number): Observable<ProgramStep> {
        return this.programStepApiService.getProgramStep(programStepId);
    }

    getProgramStepRunTimeAdjustments(programStepId: number, startTime: Date): Observable<Adjustment[]> {
        return this.programStepApiService.getProgramStepRunTimeAdjustments(programStepId, startTime);
    }

    getRunStationStatusForAllPrograms(): Observable<AllProgramsProgramStepList[]> {
        return this.programStepApiService.getRunStationStatusForAllPrograms()
            .pipe(
                tap((allResults: AllProgramsProgramStepList[]) => {
                    allResults.forEach(result => {
                        this.updateProgramStepsStationStatusOnFetch(
                            result.satelliteId,
                            result.runStationStatuses,
                            this.connectDataPackManager.connectDataPacks
                        );
                        this._programStepsDict[`${result.satelliteId}-${result.programId}`] = new CachedCollection(result.runStationStatuses);
                    });
                })
            );
	}

	getRunStationStatusForSatellite(satelliteId: number = null): Observable<AllProgramsProgramStepList[]> {
		return this.programStepApiService.getRunStationStatusForSatellite(satelliteId)
			.pipe(
				tap((allResults: AllProgramsProgramStepList[]) => {
					allResults.forEach(result => {
						this.updateProgramStepsStationStatusOnFetch(
							result.satelliteId,
							result.runStationStatuses,
							this.connectDataPackManager.connectDataPacks
						);
						this._programStepsDict[`${result.satelliteId}-${result.programId}`] = new CachedCollection(result.runStationStatuses);
					});
				})
			);
	}

    getProgramStepsList(controllerOrProgramGroupId: number, programId: number, bypassCache = false, notify = false): Observable<ProgramStepListItem[]> {
        if (!bypassCache && this._programStepsDict[`${controllerOrProgramGroupId}-${programId}`]
            && !this._programStepsDict[`${controllerOrProgramGroupId}-${programId}`].isExpired) {
            return of(this._programStepsDict[`${controllerOrProgramGroupId}-${programId}`].collection);
        }

        return this.programStepApiService.getProgramStepsList(programId)
            .pipe(
                map((programSteps: ProgramStepListItem[]) => {
                    this.updateFromProgramStepList(controllerOrProgramGroupId, programId, programSteps);
                    if (notify) this.programStepsListChange.next(new ProgramStepsListChange(programId, programSteps));
                    return programSteps;
                })
            );
    }

    getProgramsAssignedAndRunTimeBySatelliteId(controllerId: number, bypassCache = false): Observable<IStationAssignedProgram[]> {
        return this.programStepApiService.getProgramsAssignedAndRunTimeBySatelliteId(controllerId, bypassCache);
    }

    private updateFromProgramStepList(controllerOrProgramGroupId: number, programId: number, programSteps: ProgramStepListItem[]): void {
        this.updateProgramStepsStationStatusOnFetch(controllerOrProgramGroupId, programSteps, this.connectDataPackManager.connectDataPacks);
        this._programStepsDict[`${controllerOrProgramGroupId}-${programId}`] = new CachedCollection(programSteps);
    }

    getProgramStepListItem(controllerOrProgramGroupId: number, programId: number, programStepId: number): Observable<ProgramStepListItem> {
        return this.getProgramStepsList(controllerOrProgramGroupId, programId).pipe(map(list => {
            return list.find(programStep => programStep.programStepId === programStepId);
        }));
    }

    programsOrProgramGroupsUpdated(programOrProgramGroupIds: number[]) {
        let keys;

        // If we know the program Ids that were updated, only remove the program steps related to those programs, otherwise dump the entire cache.
        // Dumping the entire cache is less than optimal, but in some cases, like golf, there is not program associated.
        if (programOrProgramGroupIds !== null) {
            keys = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType)
                ? this.removeFromProgramStepsDictByProgramGroupIds(programOrProgramGroupIds)
                : this.removeFromProgramStepsDictByProgramIds(programOrProgramGroupIds);
        } else {
            keys = Object.keys(this._programStepsDict);
            this.clearCache();
        }

        // Firing an event in a large collection forEach is not optimal, but w/o knowing all of the downstream
        // effects, we'll leave as is for now.
        keys.forEach((key) => {
            const values = key.split('-');
            const programId = +(values[1]);
            this.programStepsListChange.next(new ProgramStepsListChange(programId, null));
        });
    }

    stationsUpdated(stationIds: number[]) {
        const keys = this.removeFromProgramStepsDictByStationIds(stationIds);

        // Firing an event in a large collection forEach is not optimal, but w/o knowing all of the downstream
        // effects, we'll leave as is for now.
        keys.forEach((key) => {
            const values = key.split('-');
            const programId = +(values[1]);
            this.programStepsListChange.next(new ProgramStepsListChange(programId, null));
        });
    }

    updateProgramSteps(programId: number, programStepIds: Array<number>, updateData: any): Observable<null> {
        return this.programStepApiService.updateProgramSteps(programStepIds, updateData).pipe(tap(() => {
            this.removeFromProgramStepsDictByProgramIds([programId]);
        }));
    }

    updateProgramsSteps(programIds: number[], programStepIds: Array<number>, updateData: any): Observable<null> {
        return this.programStepApiService.updateProgramSteps(programStepIds, updateData).pipe(tap(() => {
            this.removeFromProgramStepsDictByProgramIds(programIds);
        }));
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private removeFromProgramStepsDictByProgramIds(programIds: number[]): string[] {
        if (!programIds || programIds.length < 1) return [];

        const keys = Object.keys(this._programStepsDict).filter(k => programIds.includes(+k.split('-')[1]));
        keys.forEach(key => {
            delete this._programStepsDict[key];
        });

        return keys;
    }

    private removeFromProgramStepsDictByProgramGroupIds(programGroupIds: number[]): string[] {
        if (!programGroupIds || programGroupIds.length < 1) return [];

        const keys = Object.keys(this._programStepsDict).filter(k => programGroupIds.includes(+k.split('-')[0]));
        keys.forEach(key => {
            delete this._programStepsDict[key];
        });

        return keys;
    }

    private removeFromProgramStepsDictByStationIds(stationIds: number[]): string[] {
        // Search for any collections that contain one or more of the station IDs
        const keys = Object.keys(this._programStepsDict)
            .filter(k => this._programStepsDict[k].collection.some(li => stationIds.some(id => id === li.stationId)));

        keys.forEach(key => delete this._programStepsDict[key]);

        return keys;
    }

    private updateProgramStepsListAndNotify(controllerId: number, programId: number) {
        // Fetch an updated Program Steps List and notify any interested parties (via getProgramStepsList()).
        this.getProgramStepsList(controllerId, programId, true, true).pipe(take(1)).subscribe();
    }

    private updateProgramStepStatuses(connectDataPackChange: ConnectDataPackChange) {
        for (const key of Object.keys(this._programStepsDict)) {
            const separatorIndex = key.indexOf('-');
            const controllerId = +key.substr(0, separatorIndex);
            const programId = +key.substr(separatorIndex + 1);

            if (controllerId === connectDataPackChange.controllerId) {
                this.updateProgramStepsStationStatusOnFetch(controllerId, this._programStepsDict[key].collection, connectDataPackChange.connectDataPacks);
                this.programStepsListChange.next(new ProgramStepsListChange(programId, this._programStepsDict[key].collection.slice()));
            }
        }
        this.allProgramStepStatusesUpdated.next(null);
    }

    private updateGolfProgramStepStatuses(change: StationStatusChange) {
        const updatedObj = RbUtils.Common.getUpdateObjectFromItemsChanged(change.itemsChanged);
        if (updatedObj.yearlyAdjFactor != null
            || updatedObj.tempStationAdjust != null
            || updatedObj.tempAdjustDays != null
            || updatedObj.nozzleId != null
            || updatedObj.priority != null	// RB-8959: Priority is used to set non-irrigation state; if it changes, run time may, too.
        ) {
            // We need to reload the data next time it is retrieved because the SignalR data doesn't contain enough info for us to update our cache.
            this.removeFromProgramStepsDictByStationIds(change.itemsChanged.ids);
        }
        for (const key of Object.keys(this._programStepsDict)) {
            const separatorIndex = key.indexOf('-');
            const programId = +key.substr(separatorIndex + 1);
            let changed = false;
            this._programStepsDict[key].collection.forEach(programStep => {
                if (change && change.stationId === programStep.stationId
                    || (change.changeType === RbEnums.SignalR.StationStatusChangeType.BatchUpdated
                        && change.itemsChanged != null
                        && change.itemsChanged.ids != null
                        && change.itemsChanged.ids.some(id => id === programStep.stationId))) {
                    programStep.setStationStatus(RbUtils.Stations.getStationStatusFromStatusChange(
                        change,
                        programStep.master,
                       programStep.priority === RbEnums.Common.Priority.NonIrrigation)
                    );

                    if (updatedObj.precRateFinal != null)
                        programStep.precRateFinal = updatedObj.precRateFinal;
                    if (updatedObj.arc != null)
                        programStep.arc = updatedObj.arc;
                    if (updatedObj.rotation != null)
                        programStep.rotation = updatedObj.rotation;
                    changed = true;
                } else if (!programStep.status) {
                    programStep.status = '-';
                    changed = true;
                }
            });

            // only notify subscribers if we really change something in program step collection,
            // else this might broadcast thousand of events, and cause the subscribers to call API to get getProgramsList multiple times,
            // if API cache is timed-out, the API service has to call actual API. Then the tab will freeze (100% CPU usage, heap memory keeps growing,
            // and finally awsnap error).
            if (changed) {
                this.programStepsListChange.next(new ProgramStepsListChange(programId, this._programStepsDict[key].collection.slice()));
            }
        }
    }

    private updateProgramStepsStationStatusOnFetch(controllerId: number, programSteps: ProgramStepListItem[], connectDataPacks: ConnectDataPacksDict) {
        if (RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType)) {
            programSteps.forEach(programStep => {
                const golfStationStatus = this.systemStatusService.getGolfStationStatus(programStep.stationId);
                if (golfStationStatus) {
                    programStep.setStationStatus(RbUtils.Stations
                        .getStationStatusFromStatusChange(
                            golfStationStatus,
                            programStep.master,
                            programStep.priority === RbEnums.Common.Priority.NonIrrigation)
                    );
                } else if (!programStep.status) programStep.status = '-';
            });

        } else {
            if (!connectDataPacks) return;

            programSteps.forEach(programStep => {
                programStep.setStationStatus(RbUtils.Stations.getStationStatus(programStep.stationTerminal, controllerId, connectDataPacks));
            });
        }
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { SelectListItem } from '../../../../api/_common/models/select-list-item.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-activity-type-picker',
	templateUrl: './activity-type-picker.component.html',
	styleUrls: ['./activity-type-picker.component.scss']
})
export class ActivityTypePickerComponent implements OnInit {

	@Input() selectedActivityType = RbEnums.Common.ActivityType.Station;
	@Output() activityTypeChange: EventEmitter<number> = new EventEmitter();

	activityTypes: SelectListItem[];
	selectedActivityTypeName: string;

	// =========================================================================================================================================================
	// C'tor and Init
	// =========================================================================================================================================================

	constructor(private translateService: TranslateService) { }

	ngOnInit() {
		this.setupActivityTypesList();
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onActivityTypeChange(selectedActivityTypeValue: number) {
		this.selectedActivityType = selectedActivityTypeValue;
		this.setSelectedActivityTypeName();
		this.activityTypeChange.emit(selectedActivityTypeValue);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setupActivityTypesList() {
		this.activityTypes = [
			{ name: this.translateService.instant('STRINGS.PROGRAMS'), value: RbEnums.Common.ActivityType.ProgramGroup },
			{ name: this.translateService.instant('STRINGS.SCHEDULES_VERB'), value: RbEnums.Common.ActivityType.Program },
			{ name: this.translateService.instant('STRINGS.STATIONS'), value: RbEnums.Common.ActivityType.Station },
		];

		this.setSelectedActivityTypeName();
	}

	private setSelectedActivityTypeName() {
		// RB-6291: Don't test if(array); you must check for null and undefined because arrays are always truthy.
		if (this.activityTypes == null || this.selectedActivityType == null) return;

		this.selectedActivityTypeName = this.activityTypes.find(t => t.value === this.selectedActivityType).name;
	}
}

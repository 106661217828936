import { Component, HostBinding, Injector } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import {
	IrrigationQueueCommandsComponent
} from '../../../../../sections/controllers/manual-ops/manual-ops-irrigation-queue-tab/irrigation-queue-commands/irrigation-queue-commands.component';
import { ProgramListItem } from '../../../../../api/programs/models/program-list-item.model';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-program-mobile-cell-renderer',
	templateUrl: './program-mobile-cell-renderer.component.html',
	styleUrls: ['./program-mobile-cell-renderer.component.scss']
})
export class ProgramMobileCellRendererComponent extends BaseMobileCellRendererComponent {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	startDisplayTime: string;
	endDisplayTime: string;
	program: ProgramListItem;
	_statusString: string;
	isRunning = false;
	isQueueCommandsGrid = false;
	selectedStartTime = 0;
	showArrows = false;
	startTimes: Date[] = [];
	endTimes: Date[] = [];

	RbUtils = RbUtils;

	private selectedTimeChanged: any;
	private data: any;

	constructor(injector: Injector) {
		super(injector);
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		super.agInit(params);
		this.selectedTimeChanged = params.selectedTimeChanged;
		this.refresh(params);
	}

	refresh(params: any): boolean {
		this.isQueueCommandsGrid = params.parent != null && params.parent instanceof IrrigationQueueCommandsComponent;
		this.program = params.data;
		if (!this.isQueueCommandsGrid) { this.updateProgramStatus(params); }
		this.showArrows = false;
		if (params.startTimes != null) {
			const startTimeOrTimes = params.startTimes(params.data);
			this.showArrows = startTimeOrTimes instanceof Array && this.isTimeValid(startTimeOrTimes[ 0 ]);
			this.startTimes = startTimeOrTimes instanceof Array ? startTimeOrTimes : [ startTimeOrTimes ];
		}
		if (params.endTimes != null) {
			this.endTimes = params.endTimes(params.data);
		}
		this.selectedStartTime = 0;
		this.updateDisplayTimes();
		if (this.selectedTimeChanged != null) this.selectedTimeChanged(this.data, this.selectedStartTime);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	get statusString(): string {
		return this._statusString;
	}

	get adjust(): string {
		const tempAdjustDays = this.program.tempAdjustDays;
		if (tempAdjustDays > 0) {
			return this.program.tempProgramAdjust + '%' + ' ' + '(' + (tempAdjustDays + 'd') + ')';
		} else
			return this.program.programAdjust + '%';
	}

	private updateProgramStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		this._statusString = params.data.status;
		this.isRunning = false;

		switch (this._statusString) {
			case '-':
				if (rowNode.data.isEnabled === false)
					this._statusString = this.translate.instant('STRINGS.DISABLED');
				break;

			case RbUtils.Translate.instant(`STRINGS.RUNNING`):
				this.isRunning = true;
				this._statusString = RbUtils.Translate.instant(`STRINGS.RUNNING`);
				params.api.flashCells({
					rowNodes: [rowNode],
				});
				break;
		}
	}

	private updateDisplayTimes() {
		const startTime = this.startTimes.length === 0 ? null : this.startTimes[this.selectedStartTime];
		this.startDisplayTime = startTime == null || !this.isTimeValid(startTime) ? this.program.startTimeGridCell
			: RbUtils.Date.transform(startTime, RbUtils.User.timeFormat);

		if (this.endTimes == null || this.endTimes.length === 0) return '';
		const endTime = this.endTimes[this.selectedStartTime];
		this.endDisplayTime = RbUtils.Date.transform(endTime, RbUtils.User.timeFormat);
	}

	private isTimeValid(time: Date): boolean { return time != null && time.getFullYear() > 1901; }

	onSelectPrevious(event: any): void {
		if (event) event.stopPropagation();
		if (this.selectedStartTime === 0) return;
		this.selectedStartTime -= 1;
		this.updateDisplayTimes();
		if (this.selectedTimeChanged != null) this.selectedTimeChanged(this.data, this.selectedStartTime);
	}

	onSelectNext(event: any): void {
		if (event) event.stopPropagation();
		if (this.selectedStartTime >= this.startTimes.length - 1) return;
		this.selectedStartTime += 1;
		this.updateDisplayTimes();
		if (this.selectedTimeChanged != null) this.selectedTimeChanged(this.data, this.selectedStartTime);
	}
}

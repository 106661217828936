import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivityManagerService } from '../../../api/activity/activity-manager.service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { CatalogManagerService } from '../../../api/catalog/catalog-manager.service';
import { CatalogNozzle } from '../../../api/catalog/models/catalog-nozzle.model';
import { CourseViewActiveStation } from '../../../api/activity/models/course-view-active-station.model';
import { CourseViewArea } from '../../../api/activity/models/course-view-area.model';
import { CourseViewerBase } from './course-viewer-base';
import { CourseViewHole } from '../../../api/activity/models/course-view-hole.model';
import { CourseViewLayout } from '../../../api/activity/models/course-view-layout.model';
import { CourseViewStationData } from '../../../api/activity/models/course-view-station-data.model';
import { EditableGridUtil } from '../editable-grid/cell-editors/_utils/editable-grid.util';
import { GetStationQueryParams } from '../../../api/stations/models/get-station-params.model';
import { IcmDiagnosticResult } from '../../../sections/diagnostics/diagnostics-container/icm-diagnostics/models/icm-diagnostic-result.model';
import { MatDialog } from '@angular/material/dialog';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { SelectedStationInfo } from './models/selected-station-info.model';
import { SelectListItem } from '../../../api/_common/models/select-list-item.model';
import { Site } from '../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { Station } from '../../../api/stations/models/station.model';
import { StationListItem } from '../../../api/stations/models/station-list-item.model';
import { StationManagerService } from '../../../api/stations/station-manager.service';
import { TranslateService } from '@ngx-translate/core';

import CourseViewActivityType = RbEnums.Common.CourseViewActivityType;
import CourseViewData = RbEnums.Common.CourseViewData;
import CourseViewView = RbEnums.Common.CourseViewView;

enum cvLocalStorageKey {
	SiteId = 'CourseViewerSiteId',
	CompletedTabShowGradient = 'CvCtShowGradient',
	ScheduledTabShowGradient = 'CvStShowGradient',
	DryRunTabShowGradient = 'CvDrShowGradient',
	ViewId = 'CvViewId',
	ShowGradient = 'CvShowGradient',
	DataView = 'CvDataView'
}

@UntilDestroy()
@Component({
	selector: 'rb-course-viewer',
	templateUrl: './course-viewer.component.html',
	styleUrls: ['./course-viewer.component.scss']
})
export class CourseViewerComponent extends CourseViewerBase implements OnInit, OnDestroy {
	@ViewChild('stationSearchModal') stationSearchModal;
	@ViewChild('stationDiagnosticModal') stationDiagnosticModal;

	CourseViewActivityType = CourseViewActivityType;
	CourseViewView = CourseViewView;
	CourseViewData = CourseViewData;

	// Intended only to be subscribed to by child CourseViewerStation components.
	clearSelectedStations = new EventEmitter();

	@Output() selectedStationsChange = new EventEmitter<SelectedStationInfo[]>();
	@Output() invokeCreateUser = new EventEmitter<any>(); // Invoke the Create User sidebar 

	private _startTime: Date;
	@Input() set startTime(value: Date) {
		const changed = this._startTime != value;
		this._startTime = value;
		
		// RB-12136: Reload the data as our start time has changed. Since we now cancel existing requests-in-
		// progress if new ones arrive, this will just cancel any open request replacing it with the new date 
		// value.
		if (changed) {
			this.getComponentData();
		}
	}
	get startTime(): Date {
		return this._startTime;
	}

	private _endTime: Date;
	@Input() set endTime(value : Date) {
		const changed = this._endTime != value;
		this._endTime = value;
		
		// RB-12136: Reload the data as our end time has changed. Since we now cancel existing requests-in-
		// progress if new ones arrive, this will just cancel any open request replacing it with the new date 
		// value.
		if (changed) {
			this.getComponentData();
		}
	}
	get endTime(): Date {
		return this._endTime;
	}

	@Input() activityType: CourseViewActivityType = CourseViewActivityType.DryRun;
	@Input() hideCourseSelector = false;
	@Input() hideWaitingIndicator = false;

	// expandedAccordion is used to define the accordion is expanded or not
	// true: value by default, the accordion is expanded
	// false: the accordion is collapsed
	@Input() expandedAccordion = true;

	private _dryRunId: number;
	@Input() set dryRunId(value: number) {
		this._dryRunId = value;
		if (this.isComponentInitialized) { this.getCourseViewActivityData(); }
	}

	get dryRunId(): number {
		return this._dryRunId;
	}

	private _diagnosticsData: IcmDiagnosticResult<StationListItem>[] = [];
	@Input() set diagnosticsData(value: IcmDiagnosticResult<StationListItem>[]) {
		if (this.activityType !== CourseViewActivityType.Diagnostics) { return; }

		this._diagnosticsData = value;
		this.updateAreaActiveDiagnosticsStations();
	}

	get diagnosticsData(): IcmDiagnosticResult<StationListItem>[] {
		return this._diagnosticsData;
	}

	private _selectedDataView = CourseViewData.Time;
	set selectedDataView(value: CourseViewData) {
		this._selectedDataView = value;
		this.dataViewLegend = value === CourseViewData.Time ? 'STRINGS.TIME' : value === CourseViewData.Application ? 'STRINGS.APPLICATION' : 'STRINGS.PASSES';

		localStorage[cvLocalStorageKey.DataView] = value;
	}

	get selectedDataView(): CourseViewData {
		return this._selectedDataView;
	}

	isComponentInitialized = false;
	sites: Site[] = [];
	selectedSiteId: number;
	selectedView = CourseViewView.Holes;
	courseViewLayout: CourseViewLayout;
	stationData: CourseViewStationData[] | StationListItem[] = [];
	selectedViewAreas: CourseViewArea[] = [];
	selectedViewHoles: CourseViewHole[] = [];
	selectedStations: SelectedStationInfo[] = [];
	areaActiveStationsDict: { [areaId: number]: number } = {};
	uniqueValues: number[][] = [];
	showGradient = true;
	courseViewViews: SelectListItem[] = [];
	nonGolfHoleIndex: number[] = [];
	areaHeaderIcon = 'icon-water';
	spinnerText = '';
	stationSearchStationId: number;
	stationObs: Observable<Station[]>;
	stationsDataDict: { [stationId: number]: CourseViewStationData | CourseViewActiveStation } = {};
	nozzles: CatalogNozzle[];
	// stations: Station[];
	dataViewLegend = 'STRINGS.TIME';
	isCourseViewerInit = false;

	courseViewDataSubscription: Subscription = null;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private activityManager: ActivityManagerService,
				private broadcastService: BroadcastService,
				private catalogManager: CatalogManagerService,
				private dialog: MatDialog,
				private siteManager: SiteManagerService,
				private stationManager: StationManagerService,
				private translateService: TranslateService
	) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		this.areaHeaderIcon = this.activityType === CourseViewActivityType.Diagnostics ? 'icon-info' : 'icon-water';
		this.getStoredSettings();

		// Configure Course Viewer Views options
		this.courseViewViews.push(new SelectListItem({ value: CourseViewView.Holes, name: this.translateService.instant('STRINGS.GOLF_AREAS') }));
		this.courseViewViews.push(new SelectListItem({ value: CourseViewView.Places, name: this.translateService.instant('STRINGS.NON_GOLF_AREAS') }));
		this.courseViewViews.push(new SelectListItem({ value: CourseViewView.All, name: this.translateService.instant('STRINGS.ALL') }));

		this.broadcastService.showStationSearch
			.pipe(untilDestroyed(this))
			.subscribe((stationId: number) => {
				this.stationSearchStationId = stationId;
				this.dialog.open(this.stationSearchModal, EditableGridUtil.getDialogConfig(1121, 1000));
			});

		this.broadcastService.showStationDiagnostics
			.pipe(untilDestroyed(this))
			.subscribe((stationIds: number[]) => this.showStationDiagnostics(stationIds));

		// Subscribe to station status update event
		if (this.activityType === CourseViewActivityType.Active) {
			this.stationManager.stationStatusUpdateCompleted
				.pipe(untilDestroyed(this))
				.subscribe(() => {
					if (!this.isCourseViewerInit) { return; }
					this.getCourseViewActivityData();
				});
		}

		this.selectedSiteId = +localStorage[cvLocalStorageKey.SiteId];
		this.getComponentData();

		// Allow the component to fade in gracefully.
		setTimeout(() => this.isDisplayable = true, 250);

		// Used to ignore initial setting of dryRunId before component has been initialized.
		this.isComponentInitialized = true;
	}

	ngOnDestroy() {
		/** Required by UntilDestroy **/
	}

	clearAllSelected() {
		// Send to children to deselect themselves.
		this.clearSelectedStations.emit();
		this.selectedStations = [];
		this.selectedStationsChange.emit(this.selectedStations);
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	refreshData() {
		this.getComponentData();
	}

	// =========================================================================================================================================================
	// General Event Handlers
	// =========================================================================================================================================================

	onSiteSelected() {
		localStorage[cvLocalStorageKey.SiteId] = this.selectedSiteId;
		this.getComponentData();
	}

	onViewSelected() {
		localStorage[cvLocalStorageKey.ViewId] = this.selectedView;
		this.setSelectedViewAreasAndHoles();
	}

	onStationSelectionChange(stationInfo: SelectedStationInfo) {
		if (stationInfo.isSelected) {
			if (this.selectedStations == null) { this.selectedStations = []; }
			this.selectedStations.push(stationInfo);
		} else {
			this.selectedStations = this.selectedStations.filter(s => s.stationId !== stationInfo.stationId);
		}

		this.selectedStationsChange.emit(this.selectedStations);
	}

	onToggleGradient() {
		this.showGradient = !this.showGradient;
		localStorage[cvLocalStorageKey.ShowGradient] = this.showGradient;
	}

	onInvokeCreateUser(event) {
		this.invokeCreateUser.emit(event);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getStoredSettings() {
		let showGradient: string;
		let dataView: string;

		showGradient = localStorage[cvLocalStorageKey.ShowGradient];
		if (showGradient != null) { this.showGradient = JSON.parse(showGradient); }

		dataView = localStorage[cvLocalStorageKey.DataView];
		if (dataView != null) { this.selectedDataView = JSON.parse(dataView); }

		const viewId = localStorage[cvLocalStorageKey.ViewId];
		if (viewId != null) { this.selectedView = +viewId; }
	}

	private getComponentData() {
		// RB-12136: If we don't have date/times yet, there's no point in requesting the data as the result will be
		// meaningless. We allow for the end time to be empty, as that might make some sense, but if the start time
		// is empty we just return.
		// RB - 12432: We won't get a start time for Scheduled, so don't skip data retrieval
		// for that case.
		// RB-12391: In ICM Diagnostics, data is missing and retreival is being skipped since is not passing start 
		// time, adding diagnosticsData to the condition.
		if (this.activityType == RbEnums.Common.CourseViewActivityType.Completed
			&& this.startTime == null && (this.diagnosticsData == null || this.diagnosticsData.length == 0)) {
			return;
		} 

		this.isBusy = true;
		this.spinnerText = `${this.translateService.instant('STRINGS.LOADING_COURSE_VIEW_DATA')}...`;

		if (this.sites == null || this.sites.length < 1) {
			this.siteManager.getSites()
				.pipe(take(1))
				.subscribe((sites: Site[]) => {
					this.sites = sites.sort((a, b) => a.name.localeCompare(b.name));

					if (this.selectedSiteId == null || !this.sites.map(s => s.id).includes(this.selectedSiteId)) {
						this.selectedSiteId = sites[0].id;
						localStorage[cvLocalStorageKey.SiteId] = this.selectedSiteId;
					}

					this.getCourseViewLayoutAndStaticData();
				}, error => {
					this.loadError = error.error || this.translateService.instant('STRINGS.NETWORK_ERROR_RETRY');
					this.resetBusy();
				});

			return;
		}

		this.getCourseViewLayoutAndStaticData();
	}

	private getCourseViewLayoutAndStaticData() {
		const sources: Observable<any>[] = [
			this.activityManager.getCourseViewActivityLayout(this.selectedSiteId).pipe(take(1)),
			this.catalogManager.getAllNozzles().pipe(take(1)),
			// this.stationManager.getAllStations() // RB-12130 loading stations to show on Active | Course view
		];

		forkJoin(sources)
			// this.activityManager.getCourseViewActivityLayout(this.selectedSiteId)
			.subscribe(([layout, nozzles]) => {
				this.nozzles = nozzles;
				this.courseViewLayout = layout;
				// this.stations = stations; // RB-12130: this.stations is unused
				this.setNonGolfHoleIndexesArray();
				this.setSelectedViewAreasAndHoles();
				this.getCourseViewActivityData();
				this.isCourseViewerInit = true;
			}, error => {
				this.loadError = error.error || this.translateService.instant('STRINGS.NETWORK_ERROR_RETRY');
				this.resetBusy();
			});
	}

	private getCourseViewActivityData() {
		let source: Observable<any>;

		switch (this.activityType) {
			case CourseViewActivityType.DryRun:
			case CourseViewActivityType.Scheduled:
				if (this.dryRunId) { source = this.activityManager.getCourseViewActivityDryRun(this.dryRunId, this.selectedSiteId); }
				break;
			case CourseViewActivityType.Active:
			case CourseViewActivityType.Diagnostics:
				source = this.stationManager.getStationsListBySiteHolesAndHoleSections(this.selectedSiteId, [], [], false);
				break;
			case CourseViewActivityType.Completed:
				source = this.activityManager.getCourseViewActivityCompleted(this.startTime, this.endTime, this.selectedSiteId);
				break;
		}

		// Bail out if for some reason we do not have a data source observable.
		if (source == null) {
			this.resetBusy();
			return;
		}

		// RB-12136: If we have an existing in-progress subscription to the same data, kill it early and start a new one;
		// we only want one running at a time.
		if (this.courseViewDataSubscription) {
			// In this case, we are still busy so don't resetBusy() or anything.
			this.courseViewDataSubscription.unsubscribe();
			this.courseViewDataSubscription = null;
		}

		this.courseViewDataSubscription = source
			.pipe(finalize(() => this.resetBusy()))
			.subscribe({
				next: (stationData: CourseViewStationData[] | StationListItem[]) => {
					this.stationData = stationData;
					this.populateStationsDataDictionary();
					this.updateAreaActiveStations();
					this.extractUniqueValues();
				},
				error: (error) => {
					this.loadError = error.error || this.translateService.instant('STRINGS.NETWORK_ERROR_RETRY');
				}
			});
	}

	private async showStationDiagnostics(stationIds: number[]) {
		const obs: Observable<Station>[] = [];
		for (const id of stationIds) {
			obs.push(this.stationManager.getStation(id, new GetStationQueryParams(false, false, true)).pipe(take(1)));
		}
		this.stationObs = forkJoin(obs);
		this.dialog.open(this.stationDiagnosticModal, EditableGridUtil.getDialogConfig(1121, 1000));
	}

	private setNonGolfHoleIndexesArray() {
		this.nonGolfHoleIndex = [];

		this.courseViewLayout.holes.forEach((value, index) => {
			if (!value.isGolfArea) { this.nonGolfHoleIndex.push(index); }
		});
	}

	private setSelectedViewAreasAndHoles() {
		switch (this.selectedView) {
			case CourseViewView.Holes:
				this.selectedViewHoles = this.courseViewLayout.holes.filter(h => h.isGolfArea === true);
				this.selectedViewAreas = this.courseViewLayout.areas.filter(a => a.isGolfArea === true);
				break;
			case CourseViewView.Places:
				this.selectedViewHoles = this.courseViewLayout.holes.filter(h => h.isGolfArea === false);
				this.selectedViewAreas = this.courseViewLayout.areas.filter(a => a.isGolfArea === false);
				break;
			case CourseViewView.All:
			default:
				this.selectedViewHoles = this.courseViewLayout.holes.slice();
				this.selectedViewAreas = this.courseViewLayout.areas.slice();
				break;
		}
	}

	private populateStationsDataDictionary() {
		if (this.stationData == null || this.stationData.length < 1 || this.nozzles == null) { return; }

		this.stationsDataDict = {};

		if (this.stationData[0] instanceof StationListItem) {
			// The incoming data is just StationListItems/active stations.
			this.stationData.forEach(sd => {
				this.stationsDataDict[sd.id] = new CourseViewActiveStation(
					sd
				);
			});
		} else {
			// The incoming data is CourseViewStationData. Clone the objects into the dictionary.
			this.stationData.forEach(sd => {
				this.stationsDataDict[sd.id] = new CourseViewStationData({
					...sd
				});
			});
		}
	}

	private updateAreaActiveStations() {
		this.areaActiveStationsDict = {};

		if (this.stationData == null || this.stationData.length < 1) { return; }

		// For Active tab, we are working with StationListItems
		if (this.activityType === CourseViewActivityType.Active) {
			this.courseViewLayout.areas.forEach(area => {
				this.areaActiveStationsDict[area.id] =
					(<StationListItem[]>this.stationData).filter(s => s.areaLevel3Id === area.id && s.isActivelyCyclingOrSoaking).length;
			});
			return;
		}

		// For all other tabs, we are working with CourseViewStationData.
		this.courseViewLayout.areas.forEach(area => {
			this.areaActiveStationsDict[area.id]
				= (<CourseViewStationData[]>this.stationData).filter(s => s.areaLevel3Id === area.id && s.runningTimeInSeconds > 0).length;
		});
	}

	private updateAreaActiveDiagnosticsStations() {
		this.areaActiveStationsDict = {};

		if (this.courseViewLayout == null || this.diagnosticsData == null || this.diagnosticsData.length < 1) { return; }

		// For all other tabs, we are working with CourseViewStationData.
		this.courseViewLayout.areas.forEach(area => {
			this.areaActiveStationsDict[area.id] = this.diagnosticsData.filter(d => d.station.areaLevel3Id === area.id).length;
		});
	}

	private extractUniqueValues() {
		let runtimesInMinutes: number[] = [];

		if (this.activityType === CourseViewActivityType.Active) {
			return;
		}

		runtimesInMinutes = (<CourseViewStationData[]>this.stationData).map(s => Math.round(s.runningTimeInSeconds / 60));

		// Add a 1 minute value if we have runtimes < 1 minute and > 0;
		const shortRuntime = (<CourseViewStationData[]>this.stationData).find(s => s.runningTimeInSeconds < 60 && s.runningTimeInSeconds > 0);
		if (shortRuntime != null) { runtimesInMinutes.push(1); }

		this.uniqueValues[CourseViewData.Time] = runtimesInMinutes.filter(this.onlyUnique).sort((v1, v2) => v1 - v2);
		this.uniqueValues[CourseViewData.Application] =
			(<CourseViewStationData[]>this.stationData)
				.filter(s => s.application !== '-')
				.map(s => +s.application)
				.filter(this.onlyUnique)
				.sort((v1, v2) => v1 - v2);
		this.uniqueValues[CourseViewData.Rotation] =
			(<CourseViewStationData[]>this.stationData)
				.filter(s => s.passes !== '-')
				.map(s => +s.passes)
				.filter(this.onlyUnique)
				.sort((v1, v2) => v1 - v2);
	}

	private onlyUnique(value, index, self) {
		if (value === 0) return false;
		return self.indexOf(value) === index;
	}

	private resetBusy() {
		this.isBusy = false;
		this.spinnerText = '';
	}

}

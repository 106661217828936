<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="software-info-mobile-cell">
	<div>
		<span class="field-label">{{'STRINGS.VERSION_NUMBER' | translate}}:</span>
		<span class="field-value">{{softwareUpdateInfo.versionNumber}}</span>
	</div>

	<div>
		<span class="field-label">{{'STRINGS.DATE' | translate}}:</span>
		<span class="field-value">{{softwareUpdateInfo.releaseDate}}</span>
	</div>

	<div>
		<span class="field-label">{{'STRINGS.STATUS' | translate}}:</span>
		<span class="field-value">{{getStatus()}}</span>
	</div>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'rb-no-data-overlay',
	templateUrl: './no-data-overlay.component.html',
	styleUrls: ['./no-data-overlay.component.scss']
})
export class NoDataOverlayComponent implements OnInit {

	@Input() displayText = 'No data to display';
	@Input() instructionsText: string = null;

	constructor() { }

	ngOnInit() {
	}

}

/*
 * NOTE: DO NOT USE THESE ENUMS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbEnums Namespace.
 * 		 E.g., RbEnums.Common.ControllersSyncState
 * 		 See: _rb.enums.ts
 */

export namespace XXUseRbEnumsNamespace.IQEvents {

	export enum IQEvent {
		EngineStarting = 12101,					// Irrigation engine is starting up. This usually occurs after reboot.
		EngineShuttingDown = 12102,				// Posted when the engine is stopped cleanly. If this is not found but
												// EngineStarting is, there was probably a system crash.

		EngineProgramStationOn = 12301,          // Irrigation engine station operations. We can't use the existing ProgramStationOn,
		EngineProgramStationOff = 12302,         // etc. because they assume one of the parameters is a program *index*, not compatible
		EngineProgramStationPause = 12303,       // with irrigation engine.
		EngineProgramStationResume = 12304,
		EngineManualStationOn = 12305,
		EngineManualStationOff = 12306,
		EngineManualStationPause = 12307,
		EngineManualStationResume = 12308,
		EngineStationInProcess = 12309,
		EngineStationWaitToRun = 12310,
		EngineStationSoaking = 12311,
		EngineStationPosted = 12312,

		EngineStationError = 12340,
		EngineStationInvalid = 12341,
		EngineStationLocked = 12342,                // There was an issue with a master valve or booster pump; the station can't run.
		EngineStationSuspended = 12343,
		EngineStationError_InterfaceDown = 12344,   // The station couldn't be operated because the interface went down.
		EngineStationError_ProgrammingFailed = 12345,	// The ICM couldn't be programmed with fast connect address

		// Irrigation engine schedule operations.
		// Param1 = scheduleID or 0
		// Param2 = programID (this should not normally be zero, but that case should be handled gracefully)
		// Param4 = failure reason code
		EngineScheduleOn = 12350,
		EngineScheduleOff = 12351,
		EngineSchedulePause = 12352,
		EngineScheduleResume = 12353,
		EngineScheduleWaitToRun = 12354,
		EngineSchedulePosted = 12355,

		EngineScheduleError = 12360,
		EngineScheduleInvalid = 12361,

		// Irrigation engine program operations.
		// Param1 = scheduleID or 0
		// Param2 = programID (this should not normally be zero, but that case should be handled gracefully)
		// Param4 = failure reason code
		EngineProgramOn = 12370,
		EngineProgramOff = 12371,
		EngineProgramPause = 12372,
		EngineProgramResume = 12373,
		EngineProgramWaitToRun = 12374,
		EngineProgramPosted = 12375,

		EngineProgramError = 12380,
		EngineProgramInvalid = 12381,

		EngineAutomaticIrrigationPaused = 12400,
		EngineAutomaticIrrigationUnPaused = 12401,

		EngineInterfaceLinkConnected = 12402,       // param1 = interface ID
		EngineInterfaceLinkBroken = 12403,          // param1 = interface ID, param2 = reason code

		EngineAutomaticIrrigationOff = 12404,
		EngineAutomaticIrrigationOn = 12405,
		EngineIrrigationCancelled = 12406,

		EngineStationDiagnosticVoltageTooLow = 12901,
		EngineStationDiagnosticVoltageTooHigh = 12902

	}

}

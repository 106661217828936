<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<mat-form-field *ngIf="dateRangeDropdown != null && dateRangeDropdown.length > 0" class="ml0 reports-date-range-select">
	<mat-select
		[placeholder]="placeHolder"
		name="dateRange"
		#dateRange="ngModel"
		[ngModel]="selectedDateRange"
		rbSelect
		(ngModelChange)="handleDateRangeChange($event)"
	>
		<mat-option *ngFor="let dateRange of dateRangeValues" [value]="dateRange.id" class="pv1" color="primary">
			{{dateRange.name | translate}}
		</mat-option>
	</mat-select>
</mat-form-field>

<div class="date-time-pair" [ngClass]="{'is-mobile': isMobile}">
	<mat-form-field *ngIf="!(selectedDateRange === alarm) && !hideDates" [ngClass]="{'border-right-none': !hideTimeForDateRange}" class="reports-start-date" [class.mr2]="hideTimeForDateRange">
		<span class="dtp-label">{{'STRINGS.START' | translate}}</span>
		<input matInput
			class="reports-start-date--input"
			placeholder="{{ startPlaceholder }}"
			[min]="minDate"
			[max]="maxDate"
			[ngModel]="startDateValue"
			[matDatepicker]="startDatePicker"
			(dateChange)="onUserChangedStartDate($event)"
			(focus)="$any($event.target).select()"
		/>
		<mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
		<mat-datepicker #startDatePicker></mat-datepicker>
	</mat-form-field>

	<rb-input-time-picker *ngIf="!(selectedDateRange === alarm) && !hideDates && !hideTimeForDateRange" class="reports-start-time" [(ngModel)]="startTimeValue" (ngModelChange)="onStartTimeChanged($event)">
	</rb-input-time-picker>
</div>

<div class="date-time-pair" [ngClass]="{'is-mobile': isMobile}">
	<mat-form-field *ngIf="!(selectedDateRange === alarm) && !hideDates" [ngClass]="{'border-right-none': !hideTimeForDateRange}" class="reports-end-date">
		<span class="dtp-label">{{'STRINGS.END' | translate}}</span>
		<input matInput
			class="reports-end-date--input"
			placeholder="{{ endPlaceholder }}"
			[ngModel]="endDateValue"
			[min]="minEndDate"
			[max]="maxDate"
			[matDatepicker]="endDatePicker"
			(dateChange)="onUserChangedEndDate($event)"
			(focus)="$any($event.target).select()"
		/>
		<mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
		<mat-datepicker #endDatePicker></mat-datepicker>
	</mat-form-field>

	<rb-input-time-picker *ngIf="!(selectedDateRange === alarm) && !hideDates && !hideTimeForDateRange" class="reports-end-time" [(ngModel)]="endTimeValue" (ngModelChange)="onEndTimeChanged($event)">
	</rb-input-time-picker>
</div>

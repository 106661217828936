import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import { ProgramIdentifier } from '../../../../../api/stations/models/program-identifier.model';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { Router } from '@angular/router';

@Component({
	selector: 'rb-station-search-mobile-cell-renderer',
	templateUrl: './station-search-mobile-cell-renderer.component.html',
	styleUrls: ['./station-search-mobile-cell-renderer.component.scss']
})
export class StationSearchMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	data: ProgramIdentifier;
	isGolfSite = false;

	constructor(public appDateAdapter: AppDateAdapter,
				private dialog: MatDialog,
				injector: Injector,
				private router: Router
	) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();

		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
	}

	agInit(params: any): void {
		super.agInit(params);
		this.data = params.data;
	}

	onProgramClick() {
		const route = this.isGolfSite ? '/programs/programgroups/edit' : '';
		this.router.navigate([route], { queryParams: { programGroupId: this.data.programGroupId } });

		this.dialog.closeAll();
	}

	onScheduleClick() {
		if (this.isGolfSite) {
			this.router.navigate(['/programs/programgroups/editprogram'],
				{ queryParams: { programGroupId: this.data.programGroupId, programId: this.data.programId } });

		} else {
			this.router.navigate(['/programs/programsteps'],
				{ queryParams: { programId: this.data.programId } });
		}

		this.dialog.closeAll();
	}

}

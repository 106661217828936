import * as L from 'leaflet';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { latLng, Map, MapOptions } from "leaflet";
import { map, Observable, startWith, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ImportedLayersEditorData } from "../../../common/models/imported-layers-editor-data.model";
import { KMZItem } from "../../../api/leaflet/models/kmz-Item.model";
import { LeafletManagerService } from './../../../api/leaflet/leaflet-manager.service';
import { MapLeafletService } from "../../../common/services/map-leaflet.service";
import { MessageBoxInfo } from "../global-message-box/message-box-info.model";
import { MessageBoxService } from "../../../common/services/message-box.service";
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { ToasterService } from "../../../common/services/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ZipService } from '../../../common/services/zip.service';

import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;
import { GeoJSONProperties } from '../../../api/leaflet/models/geojson-properties.model';
import { KMZItemProperties } from '../../../api/leaflet/models/kmz-item-properties.model';

@UntilDestroy()
@Component({
	selector: 'rb-layer-import-dialog',
	templateUrl: './layer-import-dialog.component.html',
	styleUrls: ['./layer-import-dialog.component.scss']
}) 
export class LayerImportDialogComponent implements OnInit {
	@ViewChild('mapContainer') mapContainer: ElementRef;
	
	@Input() noInternet = false;
	
	busy = false;
	isFile = false; // A flag to indicate if the user is dragging a file
	
	private subscriptions = new Subscription();
	private _allowToSave = false;
	private _saving = false;
	private minScale = 4;

	map: Map;

	private _leafletMapOptions: MapOptions;
	private _layers: L.Layer[];

	get leafletMapOptions() {
		if (!this._leafletMapOptions) {
			this._leafletMapOptions = this.mapService.getDefaultLeafletMapOptions();
			this._leafletMapOptions.preferCanvas = true;
			this._leafletMapOptions.layers = [this.mapService.getEsriTileLayer()];
		}
		return this._leafletMapOptions;
	}

	get layers() {
		if (!this._layers) {
			this._layers = [];
		}
		return this._layers;
	}

	get allowToSave() {
		return this._allowToSave;
	}

	get saving() {
		return this._saving;
	}

	innerLoader = false;

	siteId: number;

	isVectorEdit = false
	isCompact = false

	files: SelectedFile[] = [];
	currentFile: SelectedFile;

	epsgCtrl = new FormControl('');
	coordinateSystems: ICoordinateSystem[];
	filteredCoordinateSystems: Observable<ICoordinateSystem[]>;
	selectedEpsg = '4326';

	get isCurrentFileImage() {
		return this.currentFile?.extension === 'jpg' || this.currentFile?.extension === 'jpeg' || this.currentFile?.extension === 'png'
	}

	get filesWaiting() {
		return this.files.some(file => file.status === RbEnums.Map.ImportedFileStatus.Waiting);
	}

	get filesWarning() {
		return this.files.some(file => file.status === RbEnums.Map.ImportedFileStatus.Warning);
	}

	get filesError() {
		return this.files.some(file => file.status === RbEnums.Map.ImportedFileStatus.Error);
	}

	get filesSucceeded() {
		return this.files.some(file => file.status === RbEnums.Map.ImportedFileStatus.Success);
	}

	get canProcessSucceeded() {
		return !this.filesWaiting && this.filesSucceeded && !this.currentFile;
	}

	get canProcessWithWarnings() {
		return !this.filesWaiting && this.filesSucceeded && this.filesWarning && !this.currentFile;
	}

	get canProcessWithErrors() {
		return !this.filesWaiting && this.filesSucceeded && this.filesWarning && !this.currentFile;
	}

	get canProcess() {
		return (this.canProcessSucceeded || this.canProcessWithWarnings || this.canProcessWithErrors)
	}

	get noFilesToProcess() {
		return !this.filesWaiting && !this.filesSucceeded
	}

	// Form controls

	kmzItemsForm = this.fb.group({
		fileName: ['', Validators.required],
		color: ['', Validators.required],
		weight: ['', Validators.required],
		opacity: [100, [Validators.required, Validators.min(0), Validators.max(100)]],
		stroke_style: ['', Validators.required],
		fillColor: ['', Validators.required],
		fillOpacity: ['', Validators.required],
		dashArray: [''],
		matchColors: [true],
		showTooltips: [true]
	});

	rasterItemsForm = this.fb.group({
		sourceName: ['', Validators.required],
		opacity: [100, [Validators.required, Validators.min(0), Validators.max(100)]],
	});

	get KMZItemFileNameControl() {
		return this.kmzItemsForm.controls.fileName;
	}

	get KMZItemColorControl() {
		return this.kmzItemsForm.controls.color;
	}

	get KMZItemFillColorControl() {
		return this.kmzItemsForm.controls.fillColor;
	}

	get KMZItemSizeControl() {
		return this.kmzItemsForm.controls.weight;
	}

	get KMZItemStrokeStyleControl() {
		return this.kmzItemsForm.controls.stroke_style;
	}

	get KMZItemDashArrayControl() {
		return this.kmzItemsForm.controls.dashArray;
	}

	get KMZItemMatchColorsControl() {
		return this.kmzItemsForm.controls.matchColors;
	}

	get KMZItemShowTooltipsControl() {
		return this.kmzItemsForm.controls.showTooltips;
	}

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public editorData: ImportedLayersEditorData,
		private ref: MatDialogRef<LayerImportDialogComponent>,
		private route: ActivatedRoute,
		private messageBoxService: MessageBoxService,
		public mapService: MapLeafletService,
		private leafletManagerService: LeafletManagerService,
		public zipService: ZipService,
		public toastService: ToasterService,
		public translate: TranslateService
	) { }

	ngOnInit() {
		this.siteId = this.route.snapshot.queryParams.siteId;
		this.initSubscriptions();
		this.initEpsgData();
		if (this.editorData.layer) {
			this.isVectorEdit = true;
			this.loadEditingVector(this.editorData.layer)
			if (this.editorData.compact) {
				this.isCompact = true;
			}
		}
	}

	close(changes = false) {
		this.ref.close(changes);
	}

	onLeafletMapReady(map: L.Map) {
		map.doubleClickZoom.disable();
		this.map = map;
	}

	initSubscriptions() {
		//#region Form subsriptions
		this.subscriptions.add(
			this.kmzItemsForm.valueChanges.subscribe(() => {
				this.canSave();
			})
		);

		this.subscriptions.add(
			this.rasterItemsForm.valueChanges.subscribe(() => {
				this.canSave();
			})
		);

		this.subscriptions.add(
			this.KMZItemMatchColorsControl.valueChanges.subscribe(
				this.onMatchColorsChanged
			)
		);

		this.subscriptions.add(
			this.KMZItemFileNameControl.valueChanges.subscribe(
				this.onFileNameChanged
			)
		);
		this.subscriptions.add(
			this.KMZItemColorControl.valueChanges.subscribe(
				this.onColorChanged
			)
		);
		this.subscriptions.add(
			this.KMZItemSizeControl.valueChanges.subscribe(
				this.onSizeChanged
			)
		);
		this.subscriptions.add(
			this.kmzItemsForm.controls['opacity'].valueChanges.subscribe(
				this.onOpacityChanged
			)
		);
		this.subscriptions.add(
			this.KMZItemStrokeStyleControl.valueChanges.subscribe(
				this.onStrokeStyleChanged
			)
		);
		this.subscriptions.add(
			this.KMZItemFillColorControl.valueChanges.subscribe(
				this.onFillColorChanged
			)
		);
		this.subscriptions.add(
			this.kmzItemsForm.controls['fillOpacity'].valueChanges.subscribe(
				this.onFillOpacityChanged
			)
		);

		this.subscriptions.add(
			this.rasterItemsForm.controls['opacity'].valueChanges.subscribe(
				this.onOpacityChanged
			)
		);

		this.subscriptions.add(
			this.KMZItemShowTooltipsControl.valueChanges.subscribe(
				this.onShowTooltipsChanged
			)
		);
		//#endregion
	}

	initEpsgData() {
		this.mapService.getCoordinateSystemsList().subscribe({
			next:(coordSys) => {
				this.coordinateSystems = coordSys.coordinateSystems.map((cs: ICoordinateSystem) => {
					return { aoi: cs.aoi, dsname: cs.dsname, epsg: cs.epsg } });
				const lastEpsgId = this.getLastEpsgValue();
				this.selectedEpsg = lastEpsgId ? lastEpsgId : this.coordinateSystems[0].epsg;
				const lastEpsgValue = coordSys.coordinateSystems.find(cs => cs.epsg == lastEpsgId)
				setTimeout(() => {
					this.epsgCtrl.patchValue(lastEpsgValue ? lastEpsgValue : this.coordinateSystems[0]);
				}, 500);

				this.filteredCoordinateSystems = this.epsgCtrl.valueChanges.pipe(
					startWith(''),
					map(value => {
						return value ? this.coordinateSystems.filter(cs => {
							return (`${cs.epsg} ${cs.aoi.toString().toLowerCase()} ${cs.dsname.toString().toLowerCase()}`).includes(
								value.toString().toLowerCase());
						}) : this.coordinateSystems.slice();
					})
				);
			},
			error:(error: any) => {
				const msg: any = error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
				this.messageBoxService.showMessageBox(new MessageBoxInfo(
						msg, RbEnums.Common.MessageBoxIcon.Error, '', null, null, RbEnums.Common.MessageBoxButtons.Ok
				));
			}
		})
	}

	private canSave() {
		if (!this.isCurrentFileImage) {
			this._allowToSave = this.kmzItemsForm.valid && this.kmzItemsForm.dirty;
		} else {
			this._allowToSave = this.rasterItemsForm.valid && this.rasterItemsForm.dirty;
		}
	}

	formatPercentage(value: number) {
		return value + '%';
	}


	// =========================================================================================================================================================
	// Import functionality
	// =========================================================================================================================================================

	deconstructFileName(fileName: string) {
		const splitted = fileName.split(".");

		if (fileName.split('.').length < 2) {
			this.toastService.showToaster(
				RbUtils.Translate.instant('STRINGS.UNKNOWN_FILE_TYPE_ERROR')
				, 10000);
			return;
		}

		const ext = splitted.pop().toLowerCase();
		const name = splitted.join('');
		return { name, ext }
	}

	async onFileSelect(event: NgxDropzoneChangeEvent) {
		let invalidFileTypes: String[] = [];
		this.isFile = false;

		// If unsupported files are selected...
		for (let i = 0; i < event.rejectedFiles.length; i++) {

			const ext = this.deconstructFileName(event.rejectedFiles[i].name).ext;
			if (!['png', 'jpg', 'jpeg', 'kml', 'kmz', 'shp', 'shx', 'dbf', 'zip'].includes(ext)) {
				if(!invalidFileTypes.includes(ext)) {
					invalidFileTypes.push(ext);
				}
			}
		}

		for (let i = 0; i < event.addedFiles.length; i++) {

			const name = this.deconstructFileName(event.addedFiles[i].name).name;
			const ext = this.deconstructFileName(event.addedFiles[i].name).ext;

			if (!['png', 'jpg', 'jpeg', 'kml', 'kmz', 'shp', 'shx', 'dbf', 'zip'].includes(ext)) {
				invalidFileTypes.push(ext);
				continue;
			}

			const maxAllowedSize = 10 * 1024 * 1024;
			if (event.addedFiles[i].size > maxAllowedSize) {
				const fileSize = (Math.trunc(event.addedFiles[i].size / 10485.76)) / 100;
				this.toastService.showToaster(
					RbUtils.Translate.instant('STRINGS.FILE_TOO_BIG_ERROR', {fileSize})
					, 10000);
				return;
			}

			if (ext === 'zip') {
				const zippedFiles = await this.zipService.unzip(event.addedFiles[i]);
				if (zippedFiles && zippedFiles.length) {
					const filteredFiles = zippedFiles.filter(file => {
						const fExt = this.deconstructFileName(file.name).ext;
						if (['png', 'jpg', 'jpeg', 'kml', 'kmz', 'shp', 'shx', 'dbf', 'zip'].includes(fExt)) {
							return true;
						} else if(!invalidFileTypes.includes(fExt)) {
							invalidFileTypes.push(fExt);
						}
					})
					event.addedFiles = [...event.addedFiles, ...filteredFiles];
				}
				continue;
			}

			const foundFile = this.files.find(ff => ff.name.toLowerCase() === name.toLowerCase())
			if (foundFile) {
				if (foundFile.extension === 'shp') {
					if (ext === 'shp') {
						foundFile.file = event.addedFiles[i];
					}
					if (ext === 'shx') {
						foundFile.shxFile = event.addedFiles[i];
					}
					if (ext === 'dbf') {
						foundFile.dbfFile = event.addedFiles[i];
					}
					this.checkFiles()
				}
			} else {
				let selectedFile: SelectedFile;
 
				if (ext === 'shx') {
					selectedFile = { name, extension: 'shp', shxFile: event.addedFiles[i], status: RbEnums.Map.ImportedFileStatus.Waiting };
				} else if (ext === 'dbf') {
					selectedFile = { name, extension: 'shp', dbfFile: event.addedFiles[i], status: RbEnums.Map.ImportedFileStatus.Waiting };
				} else {
					selectedFile = { name, extension: ext, file: event.addedFiles[i], status: RbEnums.Map.ImportedFileStatus.Waiting };
				}
	
				this.files.push(selectedFile);
			}
		}  

		if(invalidFileTypes.length) {
			this.toastService.showToaster(
				RbUtils.Translate.instant('STRINGS.INVALID_FILE_TYPE', { extension: invalidFileTypes.join(", ") })
				, 10000);
		}

		this.checkFiles()
	}

	setCurrentFile(file: SelectedFile) {
		if (file.status === RbEnums.Map.ImportedFileStatus.Warning) {
			this.toastService.showToaster(
				RbUtils.Translate.instant('Shape File is missing some required files. Make sure you selected the "shp", "shx" and "dbf" files.')
				, 10000);
				return;
		} else if (file.status === RbEnums.Map.ImportedFileStatus.Error) {
			this.toastService.showToaster(
				RbUtils.Translate.instant('This file cannot be imported because it is invalid or damaged.')
				, 10000);
				return;
		} else {
			this._layers = []
			const currentFile = this.files.find(file => file.status === RbEnums.Map.ImportedFileStatus.Current);
			if (currentFile) {
				this.currentFile = null;
				if (currentFile.saved) {
					currentFile.status = RbEnums.Map.ImportedFileStatus.Success;
				} else {
					currentFile.status = RbEnums.Map.ImportedFileStatus.Waiting;
				}			
			}
			file.status = RbEnums.Map.ImportedFileStatus.Current;
			this.currentFile = file;
			this.processCurrentFile()
		}
	}

	onFileRemove(event: SelectedFile) {
		if (event.status === RbEnums.Map.ImportedFileStatus.Current) this.currentFile = null;
		this.files.splice(this.files.indexOf(event), 1);
		this.checkFiles();
	}
	
	checkFiles() {
		for (const file of this.files) {
			file.statusMessage = null;
			if (file.status === RbEnums.Map.ImportedFileStatus.Waiting ||
				file.status === RbEnums.Map.ImportedFileStatus.Warning ||
				file.status === RbEnums.Map.ImportedFileStatus.Error) {
				if (file.extension === 'shp' && (!file.file || !file.shxFile || !file.dbfFile)) {
					file.status = RbEnums.Map.ImportedFileStatus.Warning;
				} else {
					file.status = RbEnums.Map.ImportedFileStatus.Waiting;
				}
			}
		}
		if (!this.currentFile) {
			this.selectNextWaiting();
		}
	}

	selectNextWaiting() {
		const nextWaiting = this.files.find(file => file.status === RbEnums.Map.ImportedFileStatus.Waiting);
		if (nextWaiting) {
			this.setCurrentFile(nextWaiting)
		}
	}

	processCurrentFile = () => {
		let foundIndex;
		if (this.isCurrentFileImage) {
			foundIndex = this.editorData.mapInfo.rasterItems.findIndex(
				(layer: any) => layer.sourceName === this.currentFile.name || layer.fileName === this.currentFile.name);
		} else {
			foundIndex = this.editorData.mapInfo.kmzItems.findIndex(
				(layer: any) => layer.sourceName === this.currentFile.name || layer.fileName === this.currentFile.name);
		}
		if (foundIndex > -1) {
			const areYouSureMsg = RbUtils.Translate.instant('STRINGS.ARE_YOU_SURE_IMPORT_DUPLICATE_LAYER_MESSAGE');
			const areYouSureTitle = RbUtils.Translate.instant('STRINGS.ARE_YOU_SURE_IMPORT_DUPLICATE_LAYER_TITLE');
			this.messageBoxService.showMessageBox(
				new MessageBoxInfo(areYouSureMsg, MessageBoxIcon.Warning, areYouSureTitle,
					() => {
						this.processCurrentFileAccept();
					},
					() => {
						this.files = this.files.filter(file => file.file.name !== this.currentFile.file.name);
						this.currentFile = null;
						this.selectNextWaiting();
					},
					RbEnums.Common.MessageBoxButtons.YesNo));
		} else {
			this.processCurrentFileAccept();
		}
	}

	async processCurrentFileAccept() {
		this.innerLoader = true;
		if (this.currentFile.file.type.substring(0, 5) === 'image') {
			const center = this.editorData.mapInfo.site.latitude ?
				latLng([this.editorData.mapInfo.site.latitude, this.editorData.mapInfo.site.longitude]) :
				this.editorData.mapInfo?.userLocationMarker?.getLatLng();
			this.map.panTo(center);
			this.rasterItemsForm.patchValue(this.currentFile);
			const reader = new FileReader();
      reader.readAsDataURL(this.currentFile.file);
      reader.onload =  () => {
				this.map.invalidateSize();
				this.validateImage(reader)
					.then((validImage) => {
						if (validImage) {
							this.startRasterImport(reader.result);
						}
					});
			}
		} else {
			if (!this.currentFile.vectorItem) {
				this.innerLoader = true;
				// This will be ran on file load. If is selected after this, it won't get the initial properties.
				if (this.currentFile.extension === 'shp') {
					this.currentFile = await this.getShapeFileGeojsonAttribute(this.selectedEpsg, this.currentFile);
					this.currentFile.vectorItem.properties = this.getDefaultShapeProperties();
					this.setLastEpsgValue(this.selectedEpsg);
				} else {
					this.currentFile = await this.getKmzFileGeojsonAttribute(this.currentFile);
				}
				this.currentFile.vectorItem.fileName = this.currentFile.file.name;
				this.innerLoader = false;
			}
			try {
				this.currentFile.vectorItem.layer = this.mapService.createLeafletGeoJSONLayer(this.currentFile.vectorItem);
				this.kmzItemsForm.patchValue(this.currentFile.vectorItem.properties);
				this.KMZItemFileNameControl.patchValue(this.currentFile.name);
				Object.keys(this.kmzItemsForm.controls).forEach( controlKey => {
					this.kmzItemsForm.controls[controlKey].markAsDirty();
					this.kmzItemsForm.controls[controlKey].markAsTouched();
				});
				this.layers.push(this.currentFile.vectorItem.layer);
				this.map.invalidateSize();
				this.map.fitBounds(this.currentFile.vectorItem.layer.getBounds());
			} catch (error) {
				console.error(error);
				this.currentFile.status = RbEnums.Map.ImportedFileStatus.Error;
				this.currentFile.statusMessage = `Error: Invalid ${ this.currentFile.extension.toUpperCase() } file`;
				this.selectNextWaiting();
			}
		}
		this.canSave();
		this.innerLoader = false;
	}

	async loadEditingVector(originalLayer: KMZItem) {
		this.currentFile = {
			id: originalLayer.id,
			name: originalLayer.fileName,
			extension: 'kmz',
			status: RbEnums.Map.ImportedFileStatus.Current
		}

		// Clone the original layer and create a new leaflet layer so that we don't 
		// modify the original's properties.
		this.currentFile.vectorItem = Object.assign({}, originalLayer);
		this.currentFile.vectorItem.properties = Object.assign({}, originalLayer.properties);
		// Make sure the layer has its geoJSON data loaded. Useful when the layer is "off" on the map
		// but the user chooses to edit it
		await this.mapService.loadItemGeoJSON(this.currentFile.vectorItem);
		this.currentFile.vectorItem.layer = this.mapService.createLeafletGeoJSONLayer(this.currentFile.vectorItem);

		if (originalLayer.properties.dashArray === null) {
			delete originalLayer.properties.dashArray			
		}

		this.KMZItemFileNameControl.patchValue(originalLayer.fileName);
		this.kmzItemsForm.patchValue(originalLayer.properties);
		Object.keys(originalLayer.properties).forEach(name => {
			if (this.kmzItemsForm.controls[name]) {
				this.kmzItemsForm.controls[name].patchValue(originalLayer.properties[name], { onlySelf: true, emitEvent: true });
				this.kmzItemsForm.controls[name].markAsDirty();
				this.kmzItemsForm.controls[name].markAsTouched();
			}
		});
		this.layers.push(this.currentFile.vectorItem.layer);
		this.map.fitBounds(this.currentFile.vectorItem.layer.getBounds());
	}

	async saveEditingFile() {
		this._saving = true;
		const id = this.currentFile.vectorItem.id;
		const kmz: KMZItem = this.currentFile.vectorItem;
		delete kmz['name'];
		delete kmz['index'];
		try {
			await this.leafletManagerService.updateKmzItem(id, kmz).toPromise();
		} catch (error) {
			console.error(error)
			this.toastService.showToaster(RbUtils.Translate.instant('STRINGS.IMAGE_IMPORT_FAILED') + ': '
				+ (error.error || error.message), 10000);
		}
		this._saving = false;
		this.close(true);
	}

	saveCurrentFile() {
		const current = this.files.find(file => file.status === RbEnums.Map.ImportedFileStatus.Current);
		if (current) {
			if (current.extension === 'shp') {
				current.epsg = this.selectedEpsg;
			}				
			current.status = RbEnums.Map.ImportedFileStatus.Success;
			current.saved = true;
		}
		this.currentFile = null;
		this.selectNextWaiting();
	}

	async saveAllFiles() {
		this._saving = true;
		const readyFiles = this.files.filter(file => file.status === RbEnums.Map.ImportedFileStatus.Success);
		for (const file of readyFiles) {
			try {
				if (file.extension === 'jpg' || file.extension === 'jpeg' || file.extension === 'png') {
					const formData = new FormData();
					formData.append('image', file.file);
					formData.append('siteId', this.siteId.toString());
					formData.append('corners', JSON.stringify(file.rasterToDatabase.getCorners()));
					await this.leafletManagerService.addRasterItem(this.siteId, formData).toPromise();
				} else {
					const payload = this.getDatabasePayload(file.name, file.vectorItem.geoJson)
					payload.geoJson.features.forEach(feature => {
						if (feature.properties && feature.properties["renderer"]) {
							delete feature.properties["renderer"];
						}
					});
					payload.properties = file.vectorItem.properties;
					if (payload.properties.renderer) delete payload.properties.renderer;
					await this.leafletManagerService.addKmzItem(payload).toPromise();
				}
			} catch (error) {
				console.error(error)
				this.toastService.showToaster(RbUtils.Translate.instant('STRINGS.IMAGE_IMPORT_FAILED') + ': '
					+ (error.error || error.message), 10000);
			}
		}
		this._saving = false;
		this.close(true);
	}

	async getKmzFileGeojsonAttribute(file: SelectedFile): Promise<SelectedFile> {
		try {
			const fileURL = URL.createObjectURL(file.file);
			const geojson: any = await this.mapService.kmzToGeojson(fileURL);
			file.vectorItem = this.getDatabasePayload(file.name, geojson);
			return file;
			
		} catch (error) {
			// this.editorData.mapInfo.busy.next(false);
			this.toastService.showToaster(RbUtils.Translate.instant('STRINGS.KMZ_IMPORT_FAILED') + ': ' + (error.error || error.message), 10000);
			throw error;
		}
	}

	getShapeFileGeojsonAttribute(epsg: string, file: SelectedFile): Promise<SelectedFile> {
		return new Promise((resolve, reject) => {
			this.mapService.getGeojsonFromShapeFiles(epsg, file.file, file.dbfFile, file.shxFile).subscribe({
				next: (res) => {
					if (res.body) {
						file.vectorItem = this.getDatabasePayload(file.name, res.body.geoJson);
						resolve(file);
					}
				},
				error: (e) => reject(e)
			})
		})
	}

	getDatabasePayload(name: string, geojson: any): KMZItem {
		return {
			fileName: name,
			siteId: this.siteId,
			geoJson: {
				type: 'FeatureCollection',
				features: geojson.features,
			},
			properties: geojson.properties,
			visible: false,
			index: 2
		} as KMZItem;
	}

	getDefaultShapeProperties(): KMZItemProperties {
		return {
			color: "#ff0000",
			opacity: 100,
			weight: 2,
			fillColor: "#000000",
			fillOpacity: 50,
			stroke_style: "solid",
			matchColors: false,
			showTooltips: false,
		}
	}

	async startRasterImport(blob: any) {
		this.map.setMinZoom(14);

		try {
			this.map.whenReady(() => {
				const img = L.distortableImageOverlay(blob, {
					mode: 'scale',
					actions: [
						L.ScaleAction,
						L.RotateAction,
						L.DistortAction,
						L.OpacityAction,
					],
					selected: true,
				});

				img.setZIndex(102);

				img.addTo(this.map);

				const newAction = L.EditAction.extend({
					initialize: function (
						map: any,
						overlay: any,
						options: any
					) {
						options = options || {};
						options.toolbarIcon = {
							html: '<i class="material-icons" style="line-height:30px;font-size:18px">save</i>',
							tooltip: RbUtils.Translate.instant('STRINGS.SAVE'),
						};
						L.EditAction.prototype.initialize.call(
							this,
							map,
							overlay,
							options
						);
					},
					addHooks: () => {
						this.currentFile.rasterToDatabase = img;
						this.saveCurrentFile();
						this.map.removeLayer(img);
						this.map.setMinZoom(0);
					},
				});

				// Cancel button
				const cancelAction = L.EditAction.extend({
					initialize: function (
						leafletMap: any,
						overlay: any,
						options: any
					) {
						options = options || {};
						options.toolbarIcon = {
							html: '<i class="material-icons" style="line-height:30px;font-size:18px">close</i>',
							tooltip: RbUtils.Translate.instant('STRINGS.CANCEL'),
						};
						L.EditAction.prototype.initialize.call(
							this,
							leafletMap,
							overlay,
							options
						);
					},
					addHooks: () => {
						this.onFileRemove(this.currentFile);
						this.map.removeLayer(img);
						this.map.setMinZoom(0);
					},
				});

				L.DomEvent.on(img._image, 'load', function () {
					img.editing.addTool(newAction);
					img.editing.addTool(cancelAction);
				});
			});
		} catch (error) {
			this.toastService.showToaster(RbUtils.Translate.instant('STRINGS.IMAGE_IMPORT_FAILED') + ': ' + (error.error || error.message), 10000);
		}

		// this.close();
	}

	getLastEpsgValue() {
		return localStorage.getItem('lastEpsg');
	}

	setLastEpsgValue(epsg) {
		localStorage.setItem('lastEpsg', epsg);
	}


	private validateImage = (reader: FileReader) => {
		return new Promise<boolean>((resolve, reject) => {
			const image = new Image();
			image.onload = () => {
				resolve(true);
			}
			image.onerror = () => {
				this.toastService.showToaster(
					RbUtils.Translate.instant('STRINGS.IMAGE_FORMAT_NOT_SUPPORTED'),
					10000);
				reject(false);
			}
			image.src = reader.result as string;
		});
	}

	private getDashArrayScale() {
		const currentScale = this.minScale * this.KMZItemSizeControl.value;
		return `${currentScale} ${currentScale * 1.2}`;
	}

	private getDottedScale() {
		return `1 ${this.KMZItemSizeControl.value * 2}`;
	}

	private getPattern() {
		switch (this.KMZItemStrokeStyleControl.value) {
			case 'solid':
				return null;
			case 'dashed':
				return this.getDashArrayScale();
			case 'dotted':
				return this.getDottedScale();
		}
	}

	// Event handlers
	private onFileNameChanged = (value: string) => {
		this.currentFile.vectorItem.fileName = value;
		this.currentFile.name = value;
	}

	private onColorChanged = (value: string) => {
		if (!this.currentFile.vectorItem ||
			!this.currentFile.vectorItem.layer) return;

		const newStyle: any = { color: value };

		// Check if should match line and fill colors
		if (this.KMZItemMatchColorsControl.value) {
			this.KMZItemFillColorControl.setValue(value, {emitEvent: false});
			newStyle.fillColor = value;
		}
		this.currentFile.vectorItem.properties.color = value;
		this.currentFile.vectorItem.layer.setStyle(newStyle);
	}

	private onFillColorChanged = (value: string) => {
		if (!this.currentFile.vectorItem ||
			!this.currentFile.vectorItem.layer) return;

		const newStyle: any = { fillColor: value, };

		// Check if should match line and fill colors
		if (this.KMZItemMatchColorsControl.value) {
			this.KMZItemColorControl.setValue(value, {emitEvent: false});
			newStyle.color = value;
		}

		this.currentFile.vectorItem.properties.fillColor = value;
		this.currentFile.vectorItem.layer.setStyle(newStyle);
	}

	private onMatchColorsChanged = (value: boolean) => {
		if (!this.currentFile.vectorItem ||
			!this.currentFile.vectorItem.layer) return;

		if (value) {
			this.KMZItemFillColorControl.setValue(this.KMZItemColorControl.value);
		}
	}

	private onShowTooltipsChanged = (value: boolean) => {
		if (!this.currentFile.vectorItem ||
			!this.currentFile.vectorItem.layer) return;
		console.log('Show tooltips:', value);
		if (this.currentFile.vectorItem.geoJson.features[0].properties?.tooltip) {
			if (value) {
				this.currentFile.vectorItem.layer.eachLayer(layer => {
					const feature: GeoJSON.Feature<null, GeoJSONProperties> = layer["feature"];
					if (feature) {
						const tooltip = L.tooltip({
							permanent: true,
							direction: 'right',
							className: 'leafletMarkerTooltip'
						}).setContent(feature.properties.tooltip);
						layer.bindTooltip(tooltip);
					}
				});
			} else {
				this.currentFile.vectorItem.layer.eachLayer(layer => {
					layer.unbindTooltip();
				});
			}
		}
		this.currentFile.vectorItem.properties.showTooltips = value;
	}

	private onSizeChanged = (value: string) => {
		if (!this.currentFile.vectorItem ||
			!this.currentFile.vectorItem.layer) return;
		const newStyle: any = { weight: value };

		if (this.KMZItemStrokeStyleControl.value !== 'solid') {
			newStyle.dashArray = this.getPattern();
			this.KMZItemDashArrayControl.setValue(newStyle.dashArray);
		}

		this.currentFile.vectorItem.properties.weight = +value;
		this.currentFile.vectorItem.layer.setStyle(newStyle);
	}

	private onOpacityChanged = (value: number) => {
		if (this.currentFile.vectorItem) {
			if (this.currentFile.vectorItem.layer) {
				this.currentFile.vectorItem.layer.setStyle({
					opacity: value / 100,
				});
			}
			this.currentFile.vectorItem.properties.opacity = value;
		} else if (this.currentFile.rasterToDatabase) {
			this.currentFile.rasterToDatabase.properties.opacity = value;
			this.currentFile.rasterToDatabase.setOpacity(value / 100);
		}
	}

	private onFillOpacityChanged = (value: number) => {
		if (this.currentFile.vectorItem?.layer) {
			this.currentFile.vectorItem.layer.setStyle({
				fillOpacity: value / 100,
			});
			this.currentFile.vectorItem.properties.fillOpacity = value;
		}
	}

	private onStrokeStyleChanged = (value: string) => {
		if (!this.currentFile.vectorItem ||
			!this.currentFile.vectorItem.layer) return;
		const pattern = this.getPattern();

		this.currentFile.vectorItem.layer.setStyle({
			dashArray: pattern,
		});

		this.currentFile.vectorItem.properties.dashArray = pattern;
		this.KMZItemDashArrayControl.setValue(pattern);
	}
	
  displayFn(cs?: ICoordinateSystem): String | undefined {
    return cs ? cs.dsname : undefined;
  }

  async selectCoordinateSystem(cs: ICoordinateSystem) {
    this.selectedEpsg = cs.epsg
		delete this.currentFile.vectorItem;
		this.processCurrentFileAccept();
  }

  @HostListener('window:dragenter', ['$event'])
  handleDragEnter(event: DragEvent) {
    // make sure we're dragging a file
    const dt = event.dataTransfer;
    this.isFile = dt && dt.types && dt.types.length === 1 && dt.types[0] === 'Files';
  }

  @HostListener('window:dragleave', ['$event'])
  handleDragLeave(event: DragEvent) {
    // was our dragleave off the page?
    if (event.pageX === 0 && event.pageY === 0) {
      // then hide the overlay
      this.isFile = false;
    }
  }

}

interface SelectedFile {
	id?: number;
	name: string;
	extension?: string;
	file?: File;
	shxFile?: File;
	dbfFile?: File;
	epsg?: string;
	status: RbEnums.Map.ImportedFileStatus;
	statusMessage?: string;
	vectorItem?: KMZItem;
	rasterToDatabase?: IRasterToDatabase;
	saved?: boolean;
}

interface ICoordinateSystem {
	aoi: string;
	dsname: string;
	epsg: string;
}

interface IRasterToDatabase {
  editing: boolean;
  error: boolean;
	properties: any;
	setOpacity(value: number): any;
	getCorners(): any;
}
<div class="ccweather-settings">
	<div *ngIf='!isIq4SidebarWidget' class="ccweather-settings-section">
		<span class='ccweather-settings-section-header'>{{(isGolfSite ? 'STRINGS.GOLF_COURSES' : 'STRINGS.WEATHER_SOURCES') | translate}}</span>
		<div class='ccweather-settings-row'>
			<div class='ccweather-settings-dropdown' (click)='onDropdownClick()'>
				<span>{{isGolfSite ? selectedSiteName : selectedWeatherSourceName}}</span>
				<mat-icon>{{isDropdownOpened ? 'expand_less' : 'expand_more'}}</mat-icon>
			</div>

			<div *ngIf='isGolfSite' class='ccweather-settings-dropdown-container' [ngClass]="{'show-dropdown': isDropdownOpened}">
				<div *ngFor='let site of sites'
					 class='site-list-item'
					 [ngClass]="{'hidden': !site.address}"
					 (click)='onItemPick(site)'>
					{{site.name}}
				</div>
			</div>

			<div *ngIf='!isGolfSite' class='ccweather-settings-dropdown-container' [ngClass]="{'show-dropdown': isDropdownOpened}">
				<div *ngFor='let source of weatherSources'
					 class='site-list-item'
					 (click)='onItemPick(source)'>
					{{source.name}}
				</div>
			</div>
		</div>
	</div>
	<div class="ccweather-settings-section">
		<span class="ccweather-settings-section-header">{{'STRINGS.PAGE_LAYOUT' | translate}}</span>
		<div class="ccweather-settings-row ccweather-settings-conditions-row" *ngFor="let block of visibleBlocks">
			<div class="ccweather-settings-icon"></div>
			<button class="ccweather-settings-show"
					[ngClass]="{'ccweather-settings-hide': !block.show}"
					(click)="onBlockShowHide(block)"
			>
				<mat-icon *ngIf="block.show">checkmark</mat-icon>
				<span>{{'STRINGS.SHOW' | translate}}</span>
			</button>
			<span class="ccweather-settings-tooltip" [ngClass]="{'ccweather-settings-tooltip-off': !block.show}">{{('STRINGS.' + block.name) | translate}}</span>
		</div>
	</div>
	<div class="ccweather-settings-section">
		<span class="ccweather-settings-section-header">{{'STRINGS.HOURLY_FORECAST_CONDITIONS' | translate}}</span>
		<ng-container *ngFor="let field of conditions">
			<div class="ccweather-settings-row ccweather-settings-conditions-row" *ngIf="field.fieldName !== 'temperatureMinMax'">
				<div class="ccweather-settings-icon">
					<img [src]="'../../../../../assets/images/ccweather_icons/hourly/' + field.iconName + '.svg'">
				</div>
				<button class="ccweather-settings-show"
						[ngClass]="{'ccweather-settings-hide': !field.show, 'cursor-auto': isShowConditionsMin() && field.show}"
						[disabled]="!visibleBlocks[0].show"
						(click)="onFieldShowHide(field)"
				>
					<mat-icon *ngIf="field.show">checkmark</mat-icon>
					<span>{{'STRINGS.SHOW' | translate}}</span>
				</button>
				<span class="ccweather-settings-tooltip" [ngClass]="{'ccweather-settings-tooltip-off': !field.show}">{{('STRINGS.' + field.hint) | translate}}</span>
			</div>
		</ng-container>
	</div>
	<div *ngIf='isGolfSite' class='ccweather-settings-section'>
		<span class='ccweather-settings-section-header'>{{'STRINGS.FORECAST_SITE_SETTINGS' | translate}}</span>
		<div class='ccweather-settings-row ccweather-settings-conditions-row'>
			<span class='ccweather-settings-tooltip'>{{'STRINGS.ELEVATION' | translate}}</span>
			<input class='ccweather-settings-input' matInput name='elevation' [(ngModel)]='elevation'>
			<span class='ccweather-settings-tooltip'>{{getElevationUnitsString()}}</span>
		</div>
	</div>
	<div class="ccweather-settings-buttons">
		<button class="save-button" [disabled]="(isGolfSite && selectedSiteName === '') || (!isGolfSite && !selectedWeatherSourceName)" (click)="onConfirmButtonClick(true)">
			{{'STRINGS.SAVE' | translate}}
		</button>
		<button class="cancel-button" (click)="onConfirmButtonClick(false)">
			{{'STRINGS.CANCEL' | translate}}
		</button>
	</div>
</div>

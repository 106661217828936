import * as moment from 'moment';
import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { ActivityItem } from '../../../../../api/activity/models/activity-item.model';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { DatePipe } from '@angular/common';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-activity-mobile-cell-renderer',
	templateUrl: './activity-mobile-cell-renderer.component.html',
	styleUrls: ['./activity-mobile-cell-renderer.component.scss']
})
export class ActivityMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	activity: ActivityItem;

	protected datePipe: DatePipe;

	constructor(injector: Injector) {
		super(injector);

		this.datePipe = injector.get(DatePipe);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);

		// Save the activity item.
		this.activity = params.data;

		// Get the indent level from the activity item and use it to set the object class for proper indent
		// based on ag-row-group-indent-<n>.
		if (this.activity.treeLevel) {
			this.class = this.class + ` ag-row-group-indent-${this.activity.treeLevel}`;
		}
	}

	dateTimeCellRenderer(value) {
		if (!value) return '';

		const userCulture = this.authManager.userCulture;

		const timeFormatString = RbUtils.Common.getTimePickerDisplayValue(userCulture);
		const dateFormat = RbUtils.Common.getDateFormat(userCulture);

		return this.datePipe.transform(value, dateFormat + ' ' + timeFormatString);
	}

	durationCellRenderer(value) {
		if (!value) return '';

		let dur = <moment.Duration>value;

		// We really want to round milliseconds to the nearest second (500+ --> +1 second, 499- --> truncate).
		// To do this, add 500ms to the duration and then use startOf('second')
		dur = RbUtils.Common.roundDurationTo(dur, 'second');

		return (dur.days() > 0 ? `${(dur.days() < 10 ? '0' : '') + dur.days().toString()}:` : '') + moment.utc(dur.asMilliseconds()).format('HH:mm:ss');
	}

	emptyIfZeroCellRenderer(value) {
		if (!value) return '';

		return value;
	}
}

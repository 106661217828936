<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div [class.rain-can-filter-desktop]="!deviceManager.isMobile" [class.full-screen-dialog]="deviceManager.isMobile">
	<rb-multi-select-dropdown2
		class="rain-can-filter"
		[label]="'TBD'"
		[selectedItems]="selectedRainCans"
		[items]="rainCans"
		(selectionComplete)="rainCanSelectionComplete($event)">
	</rb-multi-select-dropdown2>
	<div *ngIf="deviceManager.isMobile" class="button-area justify-end">
		<button mat-button (click)="onCancel()" data-qaid="dialog-cancel-button">{{'STRINGS.CANCEL' | translate }}</button>
		<button mat-button (click)="onSubmit()" color="primary" data-qaid="dialog-submit-button">{{'STRINGS.OK' | translate }}</button>
	</div>
</div>
<rb-waiting-indicator
	*ngIf="busy"
	[isVisible]="busy"
	[error]="loadError"
	(okClick)="onActionErrorAcknowledged()">
</rb-waiting-indicator>

import * as moment from 'moment';

import { AppInjector } from '../../../core/core.module';
import { Cic } from './cic.model';
import { CommInterface } from './comm-interface.model';
import { ControllerSeasonalAdjust } from './controller-seasonal-adjust.model';
import { DecoderModule } from './decoder-module.model';
import { DecoderWeatherSensor } from '../../sensors/models/decoder-weather-sensor.model';
import { EventDayOff } from '../../event-day-off/models/event-day-off.model';
import { FlowMonitoring } from '../../flow-monitoring/models/flow-monitoring.model';
import { FlowSensor } from './flow-sensor.model';
import { FlowZone } from '../../flow-elements/models/flow-zone.model';
import { IPChangeReason } from './ip-change-reason';
import { LocalSensorModule } from '../../sensors/models/local-sensor-module.model';
import { Module } from '../../modules/models/module.model';
import { Mrm } from './mrm.model';
import { Program } from '../../programs/models/program.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Site } from '../../sites/models/site.model';
import { Station } from '../../stations/models/station.model';
import { StationValveType } from '../../station-valve-types/station-valve-type.model';
import { UtilityService } from '../../../common/services/utility.service';
import { WeatherSensor } from '../../sensors/models/weather-sensor.model';

export class Controller {
	private _syncState: RbEnums.Common.ControllerSyncState;
	private _isDetecting = false;
	private _utilityService: UtilityService;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {
		this._utilityService = AppInjector.get(UtilityService);
		if (json) {
			Object.assign(this, json);

			if (json.cic) { this.cic = new Cic(json.cic); }
			if (json.commInterface) { this.commInterface = json.commInterface.map(ci => new CommInterface(ci)); }
			if (json.creationDate) { this.creationDate = moment.utc(json.creationDate).toDate(); } // Data is UTC
			if (json.decoderModule) { this.decoderModule = new DecoderModule(json.decoderModule); }
			if (json.decoderWeatherSensors) { this.decoderWeatherSensors = json.decoderWeatherSensors.map(dws => new DecoderWeatherSensor(dws)); }
			if (json.eventDayOff) { this.eventDayOff = json.eventDayOff.map(item => new EventDayOff(item)); }
			if (json.flowMonitoring) { this.flowMonitoring = json.flowMonitoring.map(fm => new FlowMonitoring(fm)); }
			if (json.flowSensors) { this.flowSensors = json.flowSensors.map(fs => new FlowSensor(fs)); }
			if (json.flowZone) { this.flowZone = json.flowZone.map(fz => new FlowZone(fz)); }
			if (json.lastContact) { this.lastContact = moment.utc(json.lastContact).toDate(); } // Data is UTC
			if (json.lastContactDateTimeOffset) {
				this.lastContactDateTimeOffset = moment.utc(json.lastContactDateTimeOffset).toDate();
			} // Data is UTC
			if (json.lastContactStarted) { this.lastContactStarted = moment.utc(json.lastContactStarted).toDate(); } // Data is UTC
			if (json.lastContactStartedDateTimeOffset) {
				this.lastContactStartedDateTimeOffset = moment.utc(json.lastContactStartedDateTimeOffset).toDate();
			} // Data is UTC
			if (json.lastPhysicalRetrieve) {
				this.lastPhysicalRetrieve = moment.utc(json.lastPhysicalRetrieve).toDate();
			} // Data is UTC
			if (json.lastPhysicalRetrieveDateTimeOffset) {
				this.lastPhysicalRetrieveDateTimeOffset = moment.utc(json.lastPhysicalRetrieveDateTimeOffset).toDate(); 
			} // Data is UTC
			
			if (json.lastRetrieveLogsCompleted) {
					this.lastRetrieveLogsCompleted = moment.utc(json.lastRetrieveLogsCompleted).toDate(); 
			} // Data is UTC

			if (json.lastRetrieveLogsCompletedDateTimeOffset) { 
				this.lastRetrieveLogsCompletedDateTimeOffset = moment.utc(json.lastRetrieveLogsCompletedDateTimeOffset).toDate(); 
			} // Data is UTC

			if (json.localSensorModule) { this.localSensorModule = new LocalSensorModule(json.localSensorModule); }
			if (json.localWeatherSensor) { this.localWeatherSensor = new WeatherSensor(json.localWeatherSensor); }
			if (json.localWeatherSensors) { this.localWeatherSensors = json.localWeatherSensors.map(lws => new WeatherSensor(lws)); }
			if (json.masterValves) { this.masterValves = json.masterValves.map(mv => new Station(mv)); }
			if (json.module) { this.module = json.module.map(m => new Module(m)); }
			if (json.monthlyCyclingTime) { this.monthlyCyclingTime = RbUtils.Conversion.convertStringToDate(json.monthlyCyclingTime); }
			if (json.mrm) { this.mrm = new Mrm(json.mrm); }
			if (json.program) { this.program = json.program.map(p => new Program(p)); }
			if (json.rainDelayStart) { this.rainDelayStart = RbUtils.Conversion.convertStringToDate(json.rainDelayStart); }
			if (json.satelliteSeasonalAdjust) { this.satelliteSeasonalAdjust = json.satelliteSeasonalAdjust.map(sa => new ControllerSeasonalAdjust(sa)); }
			if (json.sensor) { this.sensor = json.sensor.map(fs => new FlowSensor(fs)); }
			if (json.site) { this.site = new Site(json.site); }
			if (json.station) { this.station = json.station.map(s => new Station(s)); }
			if (json.stationValveType) { this.stationValveType = json.stationValveType.map(svt => new StationValveType(svt)); }
			if (json.waterWindowCloseTime) { this.waterWindowCloseTime = RbUtils.Conversion.convertStringToDate(json.waterWindowCloseTime); }
			if (json.waterWindowOpenTime) { this.waterWindowOpenTime = RbUtils.Conversion.convertStringToDate(json.waterWindowOpenTime); }
			if (json.weatherSensors) { this.weatherSensors = json.weatherSensors.map(ws => new WeatherSensor(ws)); }
			if (json.autoackAlarmsEnabled) { this.autoAckAlarmsEnabled = json.autoackAlarmsEnabled; }
		}
		this.syncState = RbUtils.Controllers.getSyncStateFromFrontPanelState(this.frontPanelState);
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================

	address: number;
	binaryTransferSupported = false;
	ccuConfigCode: string;
	channelA: number;
	channelB: number;
	channelC: number;
	check24HourLimitation = false;
	cic: Cic;
	commInterface: CommInterface[] = [];
	companyId: number;
	creationDate: Date;
	decoderModule: DecoderModule;
	
	/**
	 * For decoder interfaces, users can configure the number of current samples. The DI devices detect successful
	 * ON commands by measuring wire path current draw several times after sending the ON command. The default
	 * measurement pattern lasts approximately 160ms, 4 samples, 40ms/sample. However, this seems to be often
	 * overridden in Classic, so may change. The default will be used by irrigation engine if this value is null;
	 * otherwise total number of current samples.
	 */
	decoderOnCommandCurrentSamples?: number;

	/**
	 * For decoder interfaces, users can configure the interval between samples. The DI devices detect successful
	 * ON commands by measuring wire path current draw several times after sending the ON command. The default
	 * measurement pattern lasts approximately 160ms, 4 samples, 40ms/sample. However, this seems to be often
	 * overridden in Classic, so may change. The default will be used by irrigation engine if this value is null;
	 * otherwise sample interval in ms.
	 */
	decoderOnCommandSampleIntervalMS?: number;
 
	decoderWeatherSensors: DecoderWeatherSensor[] = [];
	description = '';
	eventDayOff: EventDayOff[] = [];
	firstReverseSyncDone = false;
	floManagerEnabled = false;
	autoAckAlarmsEnabled = false;
	flowMonitoring: FlowMonitoring[] = [];
	flowSensors: FlowSensor[] = [];
	flowZone: FlowZone[] = [];
	groupNumber: number;
	hasBaseModuleWithSensors = false;
	hasDynamicStationNumbering = false;
	hasLastSyncDifferences = false;
	hasMrm = false;
	id: number;
	iqNetType: RbEnums.Common.IqNetType;
	iqnetTypeId: number;
	isIrrigationEngineBased = false;
	isIrrigationTimeFlexible = false;
	isNetworkServer = false;
	isProgramListExtensible = false;
	isStationListExtensible = false;
	frontPanelState: RbEnums.Common.FrontPanelState;
	last30DaysFlow: number;
	lastContact: Date;
	lastContactStarted: Date;
	lastPhysicalRetrieve: Date;
	lastRetrieveLogsCompleted: Date;
	lastContactStartedDateTimeOffset: Date;
	lastContactDateTimeOffset: Date;
	lastRetrieveLogsCompletedDateTimeOffset: Date;
	lastPhysicalRetrieveDateTimeOffset: Date;
	latitude: number;
	localSensor: number;
	localSensorModule: LocalSensorModule;
	localWeatherSensor: WeatherSensor;
	localWeatherSensors: WeatherSensor[] = [];
	logicalDialPos: RbEnums.Common.RotarySwitchPosition;
	logRetrieveMode: RbEnums.Common.AutoContactMode;
	longitude: number;
	masterValveType: RbEnums.Common.MasterValveType;
	masterValves: Station[] = [];
	maxEventDaysOff: number;
	maxFloZonesCount: number;
	maxFlow: number;
	maxMasterValveCount: number;
	maxNameLength: number;
	maxNetworkAddress: number;
	maxSimultaneousStationCount: number;
	maxStationAdjustedRuntime: string;
	maxSupportedDecoderWeatherSensorsCount: number;
	maxSupportedFlowSensorsCount: number;
	maxSupportedLocalWeatherSensorsCount: number;
	maxSupportedStationsCount: number;
	minNetworkAddress: number;
	minSimultaneousStationCount: number;
	module: Module[] = [];
	monthlyCyclingTime: Date;
	mrm: Mrm;
	name = '';
	nonIrrigationSimultaneousStations?: number;
	parentId: number;
	pinLockOutType: RbEnums.Common.PinLockoutType;
	prior30DaysFlow: number;
	program: Program[] = [];
	programCount: number;
	programsUsed: number;
	programmingResumes: Date;
	rainDelay: string;
	rainDelayDaysRemaining: number;
	rainDelayLong: number;
	rainDelayStart: Date;
	satelliteEnabled = true;
	satelliteSeasonalAdjust: ControllerSeasonalAdjust[] = [];
	sensor: FlowSensor[] = [];
	serialNumber: string;
	simultaneousStations: number;
	simulStations: number;
	simultaneousStationsPerChannel: number;
	site: Site;
	siteId: number;
	station: Station[] = [];
	stationSequencing: RbEnums.Common.StationSequencingType;
	stationsProgrammed: number;
	stationValveType: StationValveType[] = [];
	syncMode: RbEnums.Common.AutoContactMode;
	type: RbEnums.Common.DeviceType;
	versionString: string;
	waterWindowCloseTime: Date;
	waterWindowOpenTime: Date;
	weatherSensors: WeatherSensor[] = [];
	weatherSourceId: number;
	maxSolenoids: number;
	wirePath: number;
	ipChangeReason: IPChangeReason;
	reverseSyncMode: RbEnums.Common.AutoContactMode;

	get hideControllerModules(): boolean {
		return this.type !== RbEnums.Common.DeviceType.LXME &&
			this.type !== RbEnums.Common.DeviceType.LXME2 &&
			this.type !== RbEnums.Common.DeviceType.LXD &&
			this.type !== RbEnums.Common.DeviceType.LXIVM &&
			this.type !== RbEnums.Common.DeviceType.LXIVMPlus &&
			this.type !== RbEnums.Common.DeviceType.ESPME3;
	}

	get hideFloManager() {
		return this.type !== RbEnums.Common.DeviceType.LXME &&
			this.type !== RbEnums.Common.DeviceType.LXME2 &&
			this.type !== RbEnums.Common.DeviceType.LXD &&
			this.type !== RbEnums.Common.DeviceType.LXIVM &&
			this.type !== RbEnums.Common.DeviceType.LXIVMPlus;
	}

	get hideMaxFlow(): boolean {
		switch (this.type) {
			case RbEnums.Common.DeviceType.TBOS1:
			case RbEnums.Common.DeviceType.TBOS2:
			case RbEnums.Common.DeviceType.TBOS4:
			case RbEnums.Common.DeviceType.TBOS6:
				
			case RbEnums.Common.DeviceType.LXME:
			case RbEnums.Common.DeviceType.LXME2:
			case RbEnums.Common.DeviceType.LXD:
			case RbEnums.Common.DeviceType.LXIVM:
			case RbEnums.Common.DeviceType.LXIVMPlus:
				return false;
			default:
				return true;
		}
	}

	get hideNonIrrSimulStations(): boolean {
		switch (this.type) {
			case RbEnums.Common.DeviceType.LXME:
			case RbEnums.Common.DeviceType.LXME2:
			case RbEnums.Common.DeviceType.LXD:
			case RbEnums.Common.DeviceType.LXIVM:
			case RbEnums.Common.DeviceType.LXIVMPlus:
				return false;
			default:
				return true;
		}
	}

	get hideSimulStations(): boolean {
		switch (this.type) {
			case RbEnums.Common.DeviceType.LXME:
			case RbEnums.Common.DeviceType.LXME2:
			case RbEnums.Common.DeviceType.LXD:
			case RbEnums.Common.DeviceType.LXIVM:
			case RbEnums.Common.DeviceType.PAR_ES:
			case RbEnums.Common.DeviceType.LDISDI:
			case RbEnums.Common.DeviceType.LXIVMPlus:
				return false;
			default:
				return true;
		}
	}

	get hideWeatherSource() {
		switch (this.type) {
			case RbEnums.Common.DeviceType.LXME:
			case RbEnums.Common.DeviceType.LXME2:
			case RbEnums.Common.DeviceType.LXD:
			case RbEnums.Common.DeviceType.LXIVM:
			case RbEnums.Common.DeviceType.LXIVMPlus:
				return false;
			default:
				return true;
		}
	}

	get hideSensorSwitch() {
		switch (this.type) {
			case RbEnums.Common.DeviceType.LXME:
			case RbEnums.Common.DeviceType.LXD:
			case RbEnums.Common.DeviceType.LXME2:
			case RbEnums.Common.DeviceType.LXIVM:
			case RbEnums.Common.DeviceType.LXIVMPlus:
				return false;
			default:
				return true;
		}
	}

	get hideTwoWirePath() {
		switch (this.type) {
			case RbEnums.Common.DeviceType.LXD:
			case RbEnums.Common.DeviceType.LXIVM:
			case RbEnums.Common.DeviceType.LXIVMPlus:
				return false;
			default:
				return true;
		}
	}

	get iqNetTypeDescription(): string {
		switch (this.iqNetType) {
			case RbEnums.Common.IqNetType.IQNetClient:
				return 'STRINGS.IQ_NET_TYPE_CLIENT';
			case RbEnums.Common.IqNetType.IQNetServer:
				return 'STRINGS.IQ_NET_TYPE_SERVER';
			case RbEnums.Common.IqNetType.DirectSatellite:
				return 'STRINGS.IQ_NET_TYPE_DIRECT_SATELLITE';
		}
	}

	get iqNetTypeShortDescription(): string {
		const isSatelliteTbos = this._utilityService.isSatelliteTbos(this.type)
		return RbUtils.Controllers.getIqNetTypeShortDescription(this.iqNetType, isSatelliteTbos);
	}

	get hasProgramUsingSimpleEt(): boolean {
		let isUsingSimpleEt = false;

		if (!this.program || this.program.length < 1) return;

		this.program.forEach(p => {
			if (isUsingSimpleEt) return;

			if (p.etAdjustType === RbEnums.Common.EtAdjustType.SimpleET) {
				isUsingSimpleEt = true;
				return;
			}
		});

		return isUsingSimpleEt;
	}

	get isUniversalController(): boolean {
		switch (this.type) {
			case RbEnums.Common.DeviceType.LXME2:
			case RbEnums.Common.DeviceType.LXIVM:
			case RbEnums.Common.DeviceType.LXIVMPlus:
				return true;
		}
	}

	get isDetecting(): boolean { return this._isDetecting; }

	set isDetecting(isDetecting: boolean) { this._isDetecting = isDetecting; }

	get showBaud(): boolean { return this.commInterface.length === 0 ? false : this.commInterface[0].type === RbEnums.Common.CommInterfaceType.Serial; }

	get showControllerModules(): boolean { return !this.hideControllerModules; }

	get showFloManager(): boolean { return !this.hideFloManager; }

	get showIp(): boolean { return this.commInterface.length === 0 ? false : this.commInterface[0].type === RbEnums.Common.CommInterfaceType.Ethernet; }

	get showRadioAddress(): boolean { return this.commInterface.length === 0 ? false : this.commInterface[0].type === RbEnums.Common.CommInterfaceType.Radio; }

	get showMaxFlow(): boolean { return !this.hideMaxFlow; }

	get showWeatherSource(): boolean { return !this.hideWeatherSource; }

	get showSensorSwitch(): boolean { return !this.hideSensorSwitch; }

	get showTwoWirePath(): boolean { return !this.hideTwoWirePath; }

	get showSimulStations(): boolean { return !this.hideSimulStations; }

	get syncState(): RbEnums.Common.ControllerSyncState { return this._syncState; }

	set syncState(syncState: RbEnums.Common.ControllerSyncState) { this._syncState = syncState; }

	get showStationSequencing(): boolean { return !this.hideNonIrrSimulStations; }

	linkInterface:boolean = false;
	get typeDescription(): string { 
		return RbUtils.Controllers.getDisplayName(this.type, this.linkInterface); 
	}

	get isShutdown(): boolean {return this.logicalDialPos ===  RbEnums.Common.RotarySwitchPosition.Off; }
	// =========================================================================================================================================================
	// Class Methods
	// =========================================================================================================================================================

	static createDefault(type?: RbEnums.Common.DeviceType, iqNetType?: RbEnums.Common.IqNetType, wirePath?: number): Controller {
		const sat = new Controller();
		if (type != null) sat.type = type;
		if (iqNetType != null) sat.iqNetType = iqNetType;
		if (wirePath != null) sat.wirePath = wirePath;
		sat.commInterface = [CommInterface.createDefault()];
		sat.description = '';
		sat.latitude = 0;
		sat.logicalDialPos = RbEnums.Common.RotarySwitchPosition.Off;
		sat.longitude = 0;
		sat.maxFlow = 0;
		sat.maxSolenoids = 0;
		sat.name = '';
		sat.nonIrrigationSimultaneousStations = 1;
		sat.rainDelayDaysRemaining = 0;
		sat.simulStations = 0;
		sat.simultaneousStations = 1;
		sat.stationSequencing = RbEnums.Common.StationSequencingType.TerminalNumber;
		sat.wirePath = wirePath;
		sat.satelliteEnabled = true;
		return sat;
	}

	get isTwoWireController(): boolean {
		return RbUtils.Controllers.checkTwoWireController(this.type);
	}
}

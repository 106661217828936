import * as lodash from 'lodash';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { MapInfoLeaflet } from '../../../common/models/map-info-leaflet.model';
import { Note } from '../../../api/sticky-notes/models/sticky-note.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StickyNoteManagerService } from '../../../api/sticky-notes/sticky-note-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { UserInfo } from '../../../api/users/models/rbcc-user-info.model';
import { UserProfile } from '../../../api/auth/models/user-profile.model';

@Component({
	selector: 'rb-note-item',
	templateUrl: './note-item.component.html',
	styleUrls: ['./note-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteItemComponent implements OnInit {

	@Input() note: Note;
	@Input() noteList: Note[] = [];
	@Input() user: UserProfile;
	@Input() mapInfo: MapInfoLeaflet;
	@Input() noteLayout: RbEnums.Note.NoteLayout = RbEnums.Note.NoteLayout.Normal;
	@Input() showLocateButton = false;
	@Input() canPin = false;
	@Input() isWidget = false;
	@Input() isDraggable = false;
	@Input() siteId;

	@Output() invokeSaveNote = new EventEmitter<Note>();
	@Output() invokeResolveNote = new EventEmitter<Note>();
	@Output() invokeDeleteNote = new EventEmitter<Note>();
	@Output() invokePinNote = new EventEmitter<Note>();
	@Output() invokeShowRightPane = new EventEmitter<Boolean>();
	@Output() noteLocationClicked = new EventEmitter<{ event: any, note: Note }>();

	NoteAnchor = RbEnums.Note.NoteAnchor
	NoteLayout = RbEnums.Note.NoteLayout;
	NotePriority = RbEnums.Note.NotePriority;
	NoteStatus = RbEnums.Note.NoteStatus;
	NoteType = RbEnums.Note.NoteType;

	showPinButtonMemoized = lodash.memoize(this.showPinButton);

	isAdmin = false;
	availableUsers: UserInfo[] = [];

	// Angular-mentions plugin configuration.
	mentionConfig = {
		items: [],
		labelKey: 'name',
		mentionSelect: (e: { id: number | string, name: string } | UserInfo) => {
			if (e instanceof UserInfo) {
				return '@' + e.name;
			} else {
				if (e.id === 'newUser') {
					this.invokeShowRightPane.emit(true);
				}
				return '';
			}
		}
	};

	isLoading = false;

	constructor(
		private authManager: AuthManagerService,
		private changeDetectorRef: ChangeDetectorRef,
		public notesManager: StickyNoteManagerService,
		public translate: TranslateService
	) {
	}

	ngOnInit(): void {
		this.isAdmin = RbUtils.Common.isAdmin(this.authManager.getUserProfile().groupLevel);
		if (this.noteLayout === RbEnums.Note.NoteLayout.Compact) {
			this.readNote();
		}
		this.loadAvailableUsers();
	}

	loadAvailableUsers(): void {
		if (this.siteId) {
			this.isLoading = true;
			this.notesManager.getAvailableUsers(this.siteId).subscribe({
				next: (users: UserInfo[]) => {
					this.availableUsers = [...users];
					this.mentionConfig.items = this.availableUsers;
					if (this.isAdmin) {
						if (!this.isWidget) {
							this.mentionConfig.items.unshift({ id: 'newUser', name: this.translate.instant('STRINGS.ADD_NEW_USER') });
						} else {
							this.mentionConfig.items.unshift({ id: 'notAvailable', name: this.translate.instant('STRINGS.ADD_NEW_USER_NOT_AVAILABLE') });
						}
					}
				},
				error: (err: any) => {
					throw err
				},
				complete: () => {
					this.isLoading = false;
					this.changeDetectorRef.detectChanges();
				}
			})
		}
	}

	onSelectNoteType(type: RbEnums.Note.NoteType) {
		this.note.noteType = type;
	}

	onSelectNotePriority(priority: RbEnums.Note.NotePriority) {
		this.note.notePriority = priority;
	}

	get isRead() {
		return this.note.readBy?.includes(this.user.userId)
	}

	readNote() {
		if (this.note.readBy && this.note.readBy.length) {
			const foundId = this.note.readBy.find(id => id === this.user.userId);
			if (foundId) {
				return
			} else {
				this.note.readBy.push(this.user.userId)
			}
		} else {
			this.note.readBy = [this.user.userId]
		}
		this.notesManager.updateNote(this.note.id, { readBy: this.note.readBy }).subscribe({
			next: () => {
				this.notesManager.getUnreadNotesCounter();
				this.notesManager.broadcastNote(this.note);
			},
			error: (e) => {
				console.log(e);
			}
		});
	}

	showPinButton(note: Note): boolean {
		if (note.attachedToType === RbEnums.Note.NoteAnchor.Note) {
			return false;
		}

		const anchoredNotes = this.noteList.filter(n => n.attachedToId === note.attachedToId && n.attachedToType === note.attachedToType);
		const pinnedNote = anchoredNotes.find(n => n.isPinned);

		if (pinnedNote) {
			return pinnedNote.id === note.id;
		} else {
			return true;
		}
	}

	pinNote() {
		this.notesManager.updateNote(this.note.id, { isPinned: true }).subscribe({
			next: () => {
				this.note.isPinned = true;
				this.invokePinNote.emit(this.note)
			},
			error: (e) => {
				throw e;
			}
		});
	}

	unpinNote() {
		this.notesManager.updateNote(this.note.id, { isPinned: false }).subscribe({
			next: () => {
				this.note.isPinned = false;
				this.invokePinNote.emit(this.note)
			},
			error: (e) => {
				throw e;
			}
		});
	}

	toggleNoteUrgency(note: Note) {
		if (note.notePriority === 1) {
			note.notePriority = 5;
		} else {
			note.notePriority = 1
		}
	}

	saveNote() {
		this.note.mentionedUserIds = [];
		this.mentionConfig.items.forEach(user => {
			if (this.note.content.includes(`${user.name}`)) {
				this.note.mentionedUserIds.push(user.id);
			}
		})
		this.invokeSaveNote.emit(this.note)
	}

	resolveNote() {
		this.invokeResolveNote.emit(this.note)
	}

	deleteNote() {
		this.invokeDeleteNote.emit(this.note)
	}

	onInput(event) {
		if (event.target.scrollHeight > 56) {
			event.target.style.height = "0px";
			event.target.style.height = (event.target.scrollHeight) + "px";
		}
	}

	onNoteLocateClick(event: any, note: Note) {
		this.noteLocationClicked.emit({ event, note });
	}

	getUserInitials(name) {
		if (name) {
			const nameSegments = name.split(" ");
			switch (nameSegments.length) {
				case 1:
					return nameSegments[0].charAt(0).toUpperCase();
				case 2:
					return (nameSegments[0].charAt(0) + nameSegments[1].charAt(0)).toUpperCase();
				case 3:
				case 4:
					return (nameSegments[0].charAt(0) + nameSegments[2].charAt(0)).toUpperCase();
				default:
					return '';
			}
		} else {
			return ''
		}
	}

	highlightUserTags(str: string): string {
		const regex = /@(\w+)/g;
		return str.replace(regex, '<span class="highlight">@$1</span>');
	}
}
<div class="weather-widget-tile-container" (click)="onTileClick()">

	<div *ngIf="isSummaryPanelVisible" class="weather-widget-tile weather-widget-tile-contracted" [ngClass]="{'ww-show-expanded-panel': showExpandedPanel}">
		<div class="ww-dow-n-forecast">
			<div class="ww-dow">{{ tileData?.shortDayOfWeek }}</div>
			<div class="ww-forecast-icon"><i [class]="tileData?.icon?.bottomIcon"></i></div>
		</div>
		<div class="ww-forecast-temp">{{ currentTemperature }} &deg;{{tileData?.tempScale}}</div>
	</div>

	<div *ngIf="isDetailPanelVisible" class="weather-widget-tile weather-widget-tile-expanded" [ngClass]="{'ww-show-expanded-panel': showExpandedPanel}">
		<div class="ww-location">{{ location}}</div>
		<div class="ww-dow">{{ tileData?.shortDayOfWeek }} {{ clockString }}</div>
		<div class="ww-forecast-details">
			<div class="ww-forecast-icon-n-description">
				<div class="rb-stacked-weather-icon-container">
					<i class="fa rb-stacked-weather-icon ww-forecast-icon" [ngClass]="tileData?.icon?.bottomIcon" [ngStyle]="{'color': tileData?.icon?.bottomIconColor}"></i>
					<i class="fa rb-stacked-weather-icon ww-forecast-icon"  [ngClass]="tileData?.icon?.topIcon" [ngStyle]="{'color': tileData?.icon?.topIconColor}"></i>
				</div>

				<div class="ww-forecast-description">{{ tileData?.forecastDescription | translate }}</div>
				<div *ngIf="isChanceofRain" class="rb-stacked-weather-icon-container">
					<span class="mdi mdi-water ww-chanceofRain-icon"></span>
					<div class="ww-forecast-chanceofRain" [innerHTML]="chanceofRainPercent"></div>
				</div>
			</div>
			<div class="ww-forecast-temp-detail">
				<div class="ww-forecast-temp">{{ currentTemperature }}<span> &deg;{{tileData?.tempScale}}</span></div>
				<div class="ww-forecast-high-low" [innerHTML]="'WEATHER.WEATHER_TEMP_RANGE_HTML' | translate:tempRange">
				</div>
			</div>
		</div>
	</div>
</div>

export class GolfWeatherSensorType {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	name: string;
	value: number;
}

import { AuthManagerService } from '../auth/auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CustomerEntitlement } from './models/customer-entitlement.model';
import { EntitlementsApiService } from './entitlements-api.service';
import { EnvironmentService } from '../../common/services/environment.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { StoreTransactionToken } from './models/store-transaction-token';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class EntitlementsManagerService extends ServiceManagerBase {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private env: EnvironmentService,
				private entitlementsApiService: EntitlementsApiService,
				private authManager: AuthManagerService,
				protected broadcastService: BroadcastService,
				private translate: TranslateService) {

		super(broadcastService);
	}

	protected clearCache() {
		this.entitlementsApiService.clearCache();
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getEntitlements(): Observable<CustomerEntitlement[]> {
		return this.entitlementsApiService.getEntitlements();
	}

	openStore(): void {

		const w = window.open('', '_blank');
		w.document.write(`<html lang="en"><head><title>Rain Bird Store</title></head><body>
			${this.translate.instant('SUBSCRIPTIONS.PROFEATURES.REDIRECT_MESSAGE')}</body></html>`);
		w.document.close();
		this.entitlementsApiService.getStoreTransactionToken().subscribe({
			next: (result: StoreTransactionToken) => {
				w.location = this.env.rainbirdStoreUrl + result.token;
			}
		});
	}

	// NOTE: If you call this method, you should call the unsubscribe method in your ngDestroy so everything can be cleaned up.
	subscribeToAppSync() {
		return this.entitlementsApiService.subscribeToAppSync(this.authManager.currentUser.profile[ 'company_uuid' ], 
			this.authManager.accessToken);
	}

	unsubscribeFromAppSync(): void {
		this.entitlementsApiService.unsubscribeFromAppSync();
	}
}

import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Area } from '../../api/areas/models/area.model';
import { AreaManagerService } from '../../api/areas/area-manager.service';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { Breadcrumb } from '../models/breadcrumb.model';
import { ControllerLocalChange } from '../../api/signalR/controller-change.model';
import { ControllerManagerService } from '../../api/controllers/controller-manager.service';
import { ProgramGroupListItem } from '../../api/program-groups/models/program-group-list-item.model';
import { ProgramGroupManagerService } from '../../api/program-groups/program-group-manager.service';
import { ProgramListItem } from '../../api/programs/models/program-list-item.model';
import { ProgramManagerService } from '../../api/programs/program-manager.service';
import { RbUtils } from '../utils/_rb.utils';
import { SiteLocalChange } from '../../api/sites/models/site.model';
import { SiteManagerService } from '../../api/sites/site-manager.service';
import { Subarea } from '../../api/areas/models/subarea.model';
import { TranslateService } from '@ngx-translate/core';
import { WeatherSource } from '../../api/weather-sources/models/weather-source.model';
import { WeatherSourceManagerService } from './../../api/weather-sources/weather-source-manager.service';

interface IRouteMap {
	[index: string]: {
		route: string;
		queryParams?: any;
	}
}
@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService implements OnDestroy {

	breadcrumbTrailChange = new Subject<Breadcrumb[]>();

	private routeMap: IRouteMap = {
		dashboard: { route: '/' },
		dashboard_controllers: { route: '/controllers/controllers', queryParams: { siteId: '#', parentId: '#' } },
		dashboard_controllers_CONTROLLER_NAME: { route: '/controllers/programs', queryParams: { controllerId: '#', parentId: "#" } },
		dashboard_controllers_CONTROLLER_NAME_PROGRAM_NAME: { route: '/controllers/programsteps', queryParams: { controllerId: '#', programId: '#' } },
		dashboard_controllers_CONTROLLER_NAME_CHILD_CONTROLLER_NAME: {
			route: '/controllers/interfaces/satellites/controller',
			queryParams: { controllerId: '#', childControllerId: '#' }
		},

		dashboard_programs: { route: '/programs/programs' },
		dashboard_programs_PROGRAM_NAME: { route: '/programs/programsteps', queryParams: { programId: '#' } },
		dashboard_programs_PROGRAM_NAME_stations: { route: '/programs/programsteps', queryParams: { programId: '#' } },

		dashboard_activity: { route: '/activity/active' },

		dashboard_sites: { route: '/systemsetup/sites' },
		dashboard_sites_SITE_NAME: { route: '/systemsetup/siteconfig/controllers', queryParams: { siteId: '#' } },
		dashboard_sensors: { route: '/systemsetup/sensors' },
		dashboard_weather: { route: '/systemsetup/weather' },
		dashboard_weather_WEATHER_SOURCE_NAME: { route: '/systemsetup/weather/et-calendar', queryParams: { weatherSourceId: '#' } },
		dashboard_admin: { route: '/systemsetup/admin/users' },
		dashboard_admin_softwareupdate: { route: '/systemsetup/admin/softwareupdate' },
		dashboard_admin_apikey: { route: '/systemsetup/admin/apikey' },
		dashboard_admin_firmwareautoupdates: { route: '/systemsetup/admin/firmwareautoupdates' },
		dashboard_admin_restoredata: { route: '/systemsetup/admin/restoredata' },
		dashboard_admin_subscriptions: { route: '/systemsetup/admin/subscriptions/overview' },
		dashboard_admin_subscriptions_overview: { route: '/systemsetup/admin/subscriptions/overview' },
		dashboard_admin_subscriptions_profeatures: { route: '/systemsetup/admin/subscriptions/pro-features' },
		dashboard_admin_subscriptions_dataplan: { route: '/systemsetup/admin/subscriptions/data-plan' },
		dashboard_licensing: { route: '/systemsetup/license' },

		dashboard_diagnostics: { route: '/diagnostics' },
		dashboard_diagnostics_decoderstations: { route: '/diagnostics/decoder-stations' },
		dashboard_diagnostics_icm: { route: '/diagnostics/icm' },
		dashboard_diagnostics_ici: { route: '/diagnostics/ici' },
		dashboard_diagnostics_link: { route: '/diagnostics/link' },
		dashboard_diagnostics_schedule: { route: '/diagnostics/schedule' },

		dashboard_reports: { route: '/reports/customreports' },
		dashboard_reports_stationRunTime: { route: '/reports/customreports/stationruntime' },
		dashboard_reports_alarmsAndWarnings: { route: '/reports/customreports/alarmsandwarnings' },
		dashboard_reports_monthlyReportCard: { route: '/reports/customreports/monthlyreportcard' },
		dashboard_reports_monthlyFlow: { route: '/reports/customreports/monthlyflow' },
		dashboard_reports_flowLogGraph: { route: '/reports/customreports/flowloggraph' },
		dashboard_reports_waterManagement: { route: '/reports/customreports/watermanagement' },
		dashboard_reports_waterUseByFlozone: { route: '/reports/customreports/waterusebyflozone' },
		dashboard_reports_yearlyWaterConsumption: { route: '/reports/customreports/yearlywaterconsumption' },
		dashboard_reports_rainfall: { route: '/reports/customreports/rainfall' },
		dashboard_reports_pinCodeAudit: { route: '/reports/customreports/pincode' },
		dashboard_reports_systemChangeLog: { route: '/reports/customreports/systemchangelog' },
		dashboard_reports_iqNetConfiguration: { route: '/reports/customreports/iqnetconfiguration' },
		dashboard_reports_controllerDifferences: { route: '/reports/customreports/controllerdifferences' },
		dashboard_reports_siteConfiguration: { route: '/reports/customreports/siteconfiguration' },
		dashboard_reports_controllerConfiguration: { route: '/reports/customreports/controllerconfiguration' },
		dashboard_reports_programSettings: { route: '/reports/customreports/programsettings' },
		dashboard_reports_dailyControllerIrrigation: { route: '/reports/customreports/dailycontrollerirrigation' },

		systemStatus: { route: '/systemstatus/outofsync/controllers' },
		systemStatus_outOfSyncControllers: { route: '/systemstatus/outofsync/controllers' },
		systemStatus_controllerDifferences: { route: '/systemstatus/controllerdifferences' },
		systemStatus_controllersInRainDelay: { route: '/systemstatus/raindelay' },
		systemStatus_controllersInShutdown: { route: '/systemstatus/autooff' },
		systemStatus_controllersConnected: { route: '/systemstatus/connections/controllers' },
		systemStatus_waterBudget: { route: '/systemstatus/waterbudget' },
		systemStatus_status: { route: '/systemstatus/status' },

		dashboard_profile: { route: '/systemsetup/profile/preferences' },
		dashboard_about: { route: '/systemsetup/about' },

		//
		// Golf Specific
		//

		dashboard_courses: { route: '/systemsetup/courses' },
		dashboard_courses_SITE_NAME: { route: '/systemsetup/courseconfig/stations', queryParams: { siteId: '#' } },
		dashboard_courses_SITE_NAME_HOLE_NAME: { route: '/systemsetup/courseconfig/holes/stations', queryParams: { siteId: '#', holeId: '#' } },
		dashboard_courses_SITE_NAME_AREA_NAME: { route: '/systemsetup/courseconfig/areas/stations', queryParams: { siteId: '#', areaId: '#' } },
		dashboard_courses_SITE_NAME_CONTROLLER_NAME: {
			route: '/systemsetup/courseconfig/interfaces/stations',
			queryParams: { controllerId: '#', siteId: '#' }
		},
		dashboard_courses_SITE_NAME_CONTROLLER_NAME_CHILD_CONTROLLER_NAME: {
			route: '/systemsetup/courseconfig/interfaces/satellites/controller',
			queryParams: { controllerId: '#', siteId: '#', childControllerId: '#' }
		},

		dashboard_programGroups: { route: '/programs/programgroups' },
		dashboard_programGroups_PROGRAM_GROUP_NAME: { route: '/programs/programgroups/edit', queryParams: { programGroupId: '#' } },
		dashboard_programGroups_PROGRAM_GROUP_NAME_PROGRAM_NAME: {
			route: '/programs/programgroups/editprogram',
			queryParams: { programGroupId: '#', programId: '#' }
		},
		dashboard_flo_manager: { route: '/systemsetup/flo_manager' },
		dashboard_flo_manager_tree: { route: '/systemsetup/flo_manager/tree' },
		dashboard_flo_manager_branch: { route: '/systemsetup/flo_manager/branch' },
		dashboard_flo_manager_flozone: { route: '/systemsetup/flo_manager/flozone' },

		// controllers
		server_controller: { route: 'controllers/clients' },
		server_tbos_controller: { route: 'controllers/tbos' },
	};

	private _breadcrumbTrail: Breadcrumb[] = [];
	private controllerId: number;
	private childControllerId: number;
	private parentId: number;
	private programId: number;
	private siteId: number;
	private holeId: number;
	private areaId: number;
	private subAreaId: number;
	private programGroupId: number;
	private weatherSourceId: number;
	private specCrumb = {
		controllers: 'controllers'
	}

	private controllerUpdated = new BehaviorSubject<ControllerLocalChange>(null);
	private siteUpdated = new BehaviorSubject<SiteLocalChange>(null);

	// =========================================================================================================================================================
	// C'tor and Destroy
	// =========================================================================================================================================================

	constructor(private areaManager: AreaManagerService,
		private authManager: AuthManagerService,
		private controllerManager: ControllerManagerService,
		private programGroupManager: ProgramGroupManagerService,
		private programManager: ProgramManagerService,
		private weatherSourceManager: WeatherSourceManagerService,
		private route: ActivatedRoute,
		private router: Router,
		private siteManager: SiteManagerService,
		private translateService: TranslateService
	) {
		if (this.authManager.isLoggedIn) this.configureEventHandlers();

		this.watchControllerChange();
		this.watchSiteChange();
	}

	isGolfSite: boolean;
	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Public Methods and Properties
	// =========================================================================================================================================================

	get breadcrumbTrail(): Breadcrumb[] {
		return this._breadcrumbTrail;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private watchControllerChange() {
		combineLatest({
			controllerUpdated: this.controllerManager.controllerLocalUpdated,
			routeParams: this.route.queryParams
		})
		.pipe(
			untilDestroyed(this)
		)
		.subscribe(({controllerUpdated, routeParams}) => {
			const { controllerId } = routeParams;
			if (controllerId && controllerUpdated.controllerIds.indexOf(+controllerId) >= 0) {
				this.controllerUpdated.next(controllerUpdated);
			} else {
				this.controllerUpdated.next(null);
			}
		});
	}

	private watchSiteChange() {
		combineLatest({
			siteUpdated: this.siteManager.siteLocalChange,
			routeParams: this.route.queryParams
		})
		.pipe(
			untilDestroyed(this)
		)
		.subscribe(({siteUpdated, routeParams}) => {
			const { siteId } = routeParams;
			if (siteId && siteUpdated.siteIds.indexOf(+siteId) >= 0) {
				this.siteUpdated.next(siteUpdated);
			} else {
				this.siteUpdated.next(null);
			}
		});
	}

	private configureEventHandlers() {
		// Monitor router navigation
		this.router.events
			.pipe(
				untilDestroyed(this),
				filter(event => event instanceof NavigationEnd)
			)
			.subscribe(() => {
				this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
				// Clear the breadcrumb during each navigation.
				this._breadcrumbTrail = [];
				this.breadcrumbTrailChange.next(this._breadcrumbTrail.slice());

				this.parsePathIntoBreadcrumbs(this.route.snapshot);
			});
	}

	private parsePathIntoBreadcrumbs(routeSnapshot: ActivatedRouteSnapshot) {
		if (routeSnapshot === null) return;
		if (routeSnapshot.data?.crumb) {
			this.controllerId = +routeSnapshot.queryParams['controllerId'];
			this.childControllerId = +routeSnapshot.queryParams['childControllerId'];
			this.parentId = +routeSnapshot.queryParams['parentId'];
			this.programId = +routeSnapshot.queryParams['programId'];
			this.siteId = +routeSnapshot.queryParams['siteId'];
			this.holeId = +routeSnapshot.queryParams['holeId'];
			this.areaId = +routeSnapshot.queryParams['areaId'];
			this.subAreaId = +routeSnapshot.queryParams['subAreaId'];
			this.programGroupId = +routeSnapshot.queryParams['programGroupId'];
			this.weatherSourceId = +routeSnapshot.queryParams['weatherSourceId'];

			this._breadcrumbTrail = [];
			const crumbs = routeSnapshot.data.crumb.split('|');

			// Build up the individual breadcrumbs of the breadcrumb trail from the routes data.crumb segments.
			for (let i = 0; i < crumbs.length; i++) {
				let path = '';
				for (let j = 0; j <= i; j++) {
					if (j > 0) path += '_';
					path += crumbs[j];
				}

				// Clean-up the constructed path to allow for lookup in our routeMap dictionary, then extract the route path and query params
				// for the given breadcrumb.
				const lookupPath =
					path.replace('[[controllerName]]', 'CONTROLLER_NAME')
						.replace('[[programName]]', 'PROGRAM_NAME')
						.replace('[[siteName]]', 'SITE_NAME')
						.replace('[[holeName]]', 'HOLE_NAME')
						.replace('[[areaName]]', 'AREA_NAME')
						.replace('[[programGroupName]]', 'PROGRAM_GROUP_NAME')
						.replace('[[weatherSourceName]]', 'WEATHER_SOURCE_NAME')
						.replace('[[childControllerName]]', 'CHILD_CONTROLLER_NAME');

				const routePath = this.routeMap[lookupPath]?.route;
				const queryParams = this.routeMap[lookupPath]?.queryParams;

				// Update the query params; replacing placeholder values.
				if (queryParams?.['controllerId']) queryParams['controllerId'] = this.controllerId;
				if (queryParams?.['childControllerId']) queryParams['childControllerId'] = this.childControllerId;
				if (queryParams?.['parentId']) queryParams['parentId'] = this.parentId || null;
				if (queryParams?.['programId']) queryParams['programId'] = this.programId;
				if (queryParams?.['siteId']) queryParams['siteId'] = this.siteId || null;
				if (queryParams?.['holeId']) queryParams['holeId'] = this.holeId;
				if (queryParams?.['areaId']) queryParams['areaId'] = this.areaId;
				if (queryParams?.['subAreaId']) queryParams['subAreaId'] = this.subAreaId;
				if (queryParams?.['programGroupId']) queryParams['programGroupId'] = this.programGroupId;
				if (queryParams?.['programGroupId']) queryParams['programGroupId'] = this.programGroupId;
				if (queryParams?.['weatherSourceId']) queryParams['weatherSourceId'] = this.weatherSourceId;

				// Create breadcrumb and update Controller and Program Name as appropriate.
				// RB-11872: controller routes need to keep the previous pages for navigation
				const breadcrumb = crumbs[i] === this.specCrumb.controllers
					? this.getControllerBreadCrumb({
							...queryParams,
							siteId: this.siteId || null,
							parentId: this.parentId || null
					})
					: new Breadcrumb(this.translateBreadcrumbName(crumbs[i]), routePath, queryParams);
				if (Array.isArray(breadcrumb)) {
					this._breadcrumbTrail.push(...breadcrumb);
				} else {
					this.updateBreadCrumbDisplay(breadcrumb);
					this._breadcrumbTrail.push(breadcrumb);
				}
			}

			// Alert interested parties that the Breadcrumb Trail has changed.
			this.breadcrumbTrailChange.next(this._breadcrumbTrail.slice());
		}

		this.parsePathIntoBreadcrumbs(routeSnapshot.firstChild);
	}

	private updateBreadCrumbDisplay(breadcrumb: Breadcrumb) {
		// We'll update the controller and program name asynchronously as we may need to hit the api to retrieve the value(s).
		if (breadcrumb.name.includes('[[controllerName]]')) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithControllerName(breadcrumb, this.controllerId);
		}
		if (breadcrumb.name.includes('[[childControllerName]]')) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithControllerName(breadcrumb, this.childControllerId);
		}
		if (breadcrumb.name.includes('[[childControllerName]]')) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithControllerName(breadcrumb, this.childControllerId);
		}
		if (breadcrumb.name.includes('[[programName]]')) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithProgramName(breadcrumb, this.programId);
		}
		if (breadcrumb.name.includes('[[siteName]]')) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithSiteName(breadcrumb, this.siteId);
		}
		if (breadcrumb.name.includes('[[holeName]]')) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithHoleName(breadcrumb, this.holeId);
		}
		if (breadcrumb.name.includes('[[areaName]]') && !isNaN(this.areaId)) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithAreaName(breadcrumb, this.areaId);
		}
		if (breadcrumb.name.includes('[[areaName]]') && !isNaN(this.subAreaId)) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithSubareaName(breadcrumb, this.subAreaId);
		}
		if (breadcrumb.name.includes('[[programGroupName]]')) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithProgramGroupName(breadcrumb, this.programGroupId);
		}
		if (breadcrumb.name.includes('[[weatherSourceName]]')) {
			breadcrumb.name = '-';
			this.updateBreadcrumbWithWeatherSourceName(breadcrumb, this.weatherSourceId);
		}
	}

	private getControllerBreadCrumb(urlQueryParams: any = {}): Breadcrumb | Breadcrumb[] {
		const { parentId = null } = urlQueryParams;
		if (!!parentId) {
			return this.getServerControllerBreadCrumbs(urlQueryParams);
		}
		return this.getDirectControllerBreadCrumbs(urlQueryParams);
	}

	getDirectControllerBreadCrumbs(urlQueryParams: any) {
		const { siteId = null} = urlQueryParams;
		if (!!siteId) {
			return this.createSiteBreadCrumbs(siteId);;
		}

		return new Breadcrumb(
			this.translateBreadcrumbName(this.specCrumb.controllers),
			this.routeMap.dashboard_controllers.route);
	}

	getServerControllerBreadCrumbs(urlQueryParams: any) {
		const { siteId = null, parentId = null } = urlQueryParams;
		const serverControllerScrumb = new Breadcrumb(
			this.translateBreadcrumbName('SERVER_CONTROLLER'),
			this.routeMap.server_controller.route,
			{
				controllerId: parentId
			}
		)

		if (!!siteId) {
			const siteBreadCrumbs = this.createSiteBreadCrumbs(siteId);
			return [...siteBreadCrumbs, serverControllerScrumb];
		} else {
			const globalCtrlList =  new Breadcrumb(
				this.translateBreadcrumbName(this.specCrumb.controllers),
				this.routeMap.dashboard_controllers.route);
				return [globalCtrlList, serverControllerScrumb];
		}

	}

	private createSiteBreadCrumbs(siteId: number): Breadcrumb[] {
		const siteListBreadCrumb = new Breadcrumb(
			this.translateBreadcrumbName('SITES'),
			this.routeMap.dashboard_sites.route,
		);
		const siteBreadCrumb = new Breadcrumb(
			this.translateBreadcrumbName('SITE_NAME'),
			this.routeMap.dashboard_sites_SITE_NAME.route,
			{
				siteId
			});
		this.updateBreadcrumbWithSiteName(siteBreadCrumb, siteId);

		return [siteListBreadCrumb, siteBreadCrumb]
	}

	// Call into the ControlManager to get the current controller. The controllerList collection will be cached after the initial call. Hitting
	// page refresh on a component whose crumb requires a name lookup will guarantee that the controllerList has not yet been fetched.
	private updateBreadcrumbWithControllerName(breadcrumb: Breadcrumb, controllerId: number) {
		combineLatest({
			controller: this.controllerManager.getControllerListItem(controllerId),
			controllerUpdated: this.controllerUpdated
		})
		.pipe(untilDestroyed(this))
			.subscribe(({controller, controllerUpdated}) => {
				let breadcrumbTrail = this._breadcrumbTrail.slice();
				if (controllerUpdated?.patchValues?.name) {
					breadcrumb.name = controllerUpdated.patchValues.name;
				} else {
					const isTbos = this.controllerManager.isSatelliteTbos(controller.type);
					if (isTbos) {
						breadcrumbTrail = this.getTbosServerControllerTrail(breadcrumbTrail)
					}
					breadcrumb.name = controller.name;
				}

				this.breadcrumbTrailChange.next(breadcrumbTrail);
			});
	}

	private getTbosServerControllerTrail(breadcrumbTrail: Breadcrumb[]): Breadcrumb[] {
			return [...breadcrumbTrail].map(crumb => {
				if (crumb.route === this.routeMap.server_controller.route) {
					crumb.route = this.routeMap.server_tbos_controller.route;
				}
				return crumb;
			});
	}

	// Call into the ProgramManager to get the current program. The programList collection will be cached after the initial call. Hitting
	// page refresh on a component whose crumb requires a name lookup will guarantee that the programList has not yet been fetched.
	private updateBreadcrumbWithProgramName(breadcrumb: Breadcrumb, programId: number) {
		this.programManager.getProgramListItem(programId)
			.pipe(take(1))
			.subscribe((program: ProgramListItem) => {
				breadcrumb.name = program.name;
				this.breadcrumbTrailChange.next(this._breadcrumbTrail.slice());
			});
	}

	private updateBreadcrumbWithSiteName(breadcrumb: Breadcrumb, siteId: number) {
		combineLatest({
			site: this.siteManager.getSiteFromSitesList(siteId),
			siteUpdated: this.siteUpdated
		})
		.pipe(untilDestroyed(this))
		.subscribe(({site, siteUpdated}) => {
			if (siteUpdated?.patchValues?.name) {
				breadcrumb.name = siteUpdated.patchValues.name;
			} else {
				breadcrumb.name = site.name;
			}

			this.breadcrumbTrailChange.next(this._breadcrumbTrail.slice());
		});
	}

	private updateBreadcrumbWithHoleName(breadcrumb: Breadcrumb, holeId: number) {
		this.areaManager.getHole(holeId)
			.pipe(take(1))
			.subscribe((hole: Area) => {
				breadcrumb.name = hole.name;
				this.breadcrumbTrailChange.next(this._breadcrumbTrail.slice());
			});
	}

	private updateBreadcrumbWithAreaName(breadcrumb: Breadcrumb, holeSectionId: number) {
		this.areaManager.getHoleSection(holeSectionId)
			.pipe(take(1))
			.subscribe((holeSection: Area) => {
				breadcrumb.name = holeSection.name;
				this.breadcrumbTrailChange.next(this._breadcrumbTrail.slice());
			});
	}

	private updateBreadcrumbWithSubareaName(breadcrumb: Breadcrumb, subareaId: number) {
		this.areaManager.getHoleSubareaSection(subareaId)
			.pipe(take(1))
			.subscribe((holeSection: Subarea) => {
				if (holeSection)
					breadcrumb.name = holeSection.name;
				this.breadcrumbTrailChange.next(this._breadcrumbTrail.slice());
			});
	}

	private updateBreadcrumbWithProgramGroupName(breadcrumb: Breadcrumb, programGroupId: number) {
		this.programGroupManager.getProgramGroupListItem(programGroupId)
			.pipe(take(1))
			.subscribe((programGroup: ProgramGroupListItem) => {
				breadcrumb.name = programGroup.name;
				this.breadcrumbTrailChange.next(this._breadcrumbTrail.slice());
			});
	}

	private updateBreadcrumbWithWeatherSourceName(breadcrumb: Breadcrumb, weatherSourceId: number) {
		this.weatherSourceManager.getWeatherSource(weatherSourceId)
			.pipe(take(1))
			.subscribe((weatherSource: WeatherSource) => {
				breadcrumb.name = weatherSource.name;
				this.breadcrumbTrailChange.next(this._breadcrumbTrail.slice());
			});
	}

	private translateBreadcrumbName(name: string) {
		name = (this.isGolfSite && name === 'programGroups') ? 'programs' : name;
		// Convert camel cased strings to underscored separated strings for lookup in translation file.
		// e.g., 'customReports' will be converted to 'custom_Reports'
		const parsedName = name.replace(/([a-z])([A-Z])/g, '$1_$2');

		if (name.startsWith('[[') && name.endsWith(']]')) return name;

		const stringName = `STRINGS.${parsedName.toUpperCase()}`;

		return this.translateService.instant(stringName);
	}

	/**
	 * Simple method to check the breadcrumb is allow to display support contact information
	 * to the bottom of all the pages accessible from the top-level navigation:
	 * Sites List
	 * Controllers List
	 * Activity List
	 * Programs List
	 * Reports
	 * Weather
	 * Admin
	 * @param breadcrumb - The breadcrumb need to check.
	 */
	isShowSupportContactInfo(breadcrumb: Breadcrumb) {
		switch (breadcrumb?.route) {
			case this.routeMap.dashboard_sites.route:
			case this.routeMap.dashboard_controllers.route:
			case this.routeMap.dashboard_activity.route:
			case this.routeMap.dashboard_programs.route:
			case this.routeMap.dashboard_reports.route:
			case this.routeMap.dashboard_weather.route:
			case this.routeMap.dashboard_admin.route:
				return true;
			default:
				return false;
		}
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div [ngClass]="{'hole-area-filter-desktop': !isMobile, 'full-screen-dialog': isMobile}">
	<div *ngIf="isMobile" class="header" >
		<div>{{ 'STRINGS.AREAS' | translate }}</div>
	</div>
	<div [ngClass]="{'left-side': !isMobile, 'main-body': isMobile}">
		<!-- Select course -->
		<div [ngClass]="{'selection-container': !isMobile, 'selection-container-mobile': isMobile}">
			<mat-select [placeholder]="'STRINGS.COURSE' | translate" [(ngModel)]="selectedCourseId" (ngModelChange)="onCourseSelected()">
				<mat-option *ngFor="let course of courses" [value]="course.id">{{course.name}}</mat-option>
			</mat-select>
		</div>
		<!-- Select holes -->
		<rb-select-holes-dropdown #holesDropdown 
			[isMobile]="isMobile" 
			[holesAndPlaces]="holesAndPlaces" 
			[isLeftJustified]="isMobile"
			[isWhiteBackground]="isMobile"
			[isMobile]="isMobile"
			(holesSelected)="onSelectedHolesChange($event)"
			>
		</rb-select-holes-dropdown>
		<!-- Select areas -->
		<rb-front-back-dropdown #areasDropdown 
			[label]="'STRINGS.AREAS' | translate" 
			[dataArray]="courseAreas"
			[isWhiteBackground]="isMobile"
			[isLeftJustified]="isMobile"
			(itemsSelected)="onSelectedAreasChange($event)"
			[isAreas]="true" 
			[showSelectionCount]="true"
			>
		</rb-front-back-dropdown>
	</div>
	<div *ngIf="isMobile" class="button-area justify-end">
		<button mat-button (click)="onCancel()" data-qaid="dialog-cancel-button">{{'STRINGS.CANCEL' | translate }}</button>
		<button mat-button (click)="onSubmit()" color="primary" data-qaid="dialog-submit-button">{{'STRINGS.OK' | translate }}</button>
	</div>
</div>
<rb-waiting-indicator
	*ngIf="busy"
	[isVisible]="busy"
	[error]="loadError"
	(okClick)="onActionErrorAcknowledged()">
</rb-waiting-indicator>


import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AddressBookEntry } from '../../../api/address-book/models/address-book-entry.model';
import { AddressBookManagerService } from '../../../api/address-book/address-book-manager.service';
import { BehaviorSubject } from 'rxjs';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { ICustomReportTriPaneHost } from './custom-report-tri-pane-host.interface';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxService } from '../../../common/services/message-box.service';
import { ProgressService } from '../../../common/services/progress.service';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { ReportsContainerComponent } from '../../../sections/reports/reports-container/reports-container.component';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'rb-custom-reports-tri-pane',
	templateUrl: './custom-report-tri-pane.component.html',
	styleUrls: ['./custom-report-tri-pane.component.scss'],
	animations: [
		trigger('transitionInLeft', [
			transition(':enter', [   // :enter is alias to 'void => *'
				style({ width: 0 }),
				animate(500, style({ width: '22.5rem' }))
			]),
			transition(':leave', [   // :enter is alias to 'void => *'
				style({ width: '22.5rem' }),
				animate(500, style({ width: 0 }))
			])
		])
	]
})
export class CustomReportTriPaneComponent implements OnInit, OnDestroy, AfterViewInit {
	@HostBinding('class') class = 'rb-custom-reports-tri-pane';

	// @ViewChild('leftPaneElement') leftPaneElement: ElementRef;
	// @ViewChild('middlePane') middlePane: ElementRef;
	// @ViewChild('middlePaneBodyContainer') middlePaneBodyContainer: ElementRef;
	@ViewChild('toolbarContainer') toolbarContainer: ElementRef;
	@ViewChild('selectRecipientsDialog', { static: true }) selectRecipientsDialog;

	@Input() progress = -1;
	@Input() busy = false;
	@Input() reportContainer: ReportsContainerComponent;
	@Input() displayRightPane = false;
	@Input() displayTopActionPane = false;
	@Input() hostComponent: ICustomReportTriPaneHost;
	@Input() leftPane: TemplateRef<any>;
	@Input() loadError: string;
	@Input() noToolbarPadding = false;
	@Input() noMainContentMargin = false;
	@Input() toolbar: TemplateRef<any>;
	@Input() middlePane: TemplateRef<any>;
	@Input() rightPaneBody: TemplateRef<any>;
	@Input() showRefreshButton = true;
	@Input() showFormatSelection = true;
	@Input() isCustomReport = false;

	private _dataLoaded = false;
	@Input() set dataLoaded(value: boolean) {
		this._dataLoaded = value;
		this.dataLoadedChange.next(value);
		if (this.reportContainer != null) {
			setTimeout(() => {
				this.reportContainer.includeCsv = value && this.isActionsShown;
				this.reportContainer.includePdf = value && this.isActionsShown;
				this.reportContainer.includeShare = value && this.isActionsShown;
			});
		}
	}
	get dataLoaded(): boolean { return this._dataLoaded; }
	@Output() dataLoadedChange = new EventEmitter<boolean>();

	@Output() viewPdf = new EventEmitter();
	@Output() exportCsv = new EventEmitter();
	@Output() share = new EventEmitter<AddressBookEntry[]>(); // List of recipients
	@Output() errorDismissed = new EventEmitter();

	contactsList;
	constructor(private broadcastService: BroadcastService,
				public progressService: ProgressService,
				public deviceManager: DeviceManagerService,
				private dialog: MatDialog,
				private addressBookManager: AddressBookManagerService,
				private messageBoxService: MessageBoxService,
				private translate: TranslateService,
				private location: Location) {
		this.progressService.reportRunningLabel = RbUtils.Translate.instant('STRINGS.RUNNING_REPORT');
		this.progressService.isInProgress$ = new BehaviorSubject(true);
	}

	ngOnInit() {
		this.progressService.isInProgress$.subscribe((loading) => {
			if (loading) {
				if (!this.progressService.isRemainingProgress) {
					this.progressService.countApi++;
					this.progressService.showPercentageProgress(this.progressService.countApi * RbConstants.Ui.PERCENTAGE_PROGRESS_AN_API);
				} else {
					// Final running to complete remaining percentage progress
					if (this.progressService.lastPercentageProgress < RbConstants.Ui.PERCENTAGE_PROGRESS_MAX &&
						this.progressService.currentPercentageProgress !== RbConstants.Ui.PERCENTAGE_PROGRESS_MAX) {
						// console.log('Final running percentage progress');
						this.progressService.showPercentageProgress(RbConstants.Ui.PERCENTAGE_PROGRESS_MAX);
					}
				}
			} else if (this.dataLoaded && this.progressService.isLoadedData) {
				this.progressService.initializeProgress();
			}
		});

		this.broadcastService.backClick
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				if (this.dataLoaded) {
					this.dataLoaded = false;
					return;
				}
				this.location.back();
			});

		this.broadcastService.pdfClick.pipe(untilDestroyed(this)).subscribe(() => this.onViewPdf());
		this.broadcastService.csvClick.pipe(untilDestroyed(this)).subscribe(() => this.onExportCsv());
		this.broadcastService.shareClick.pipe(untilDestroyed(this)).subscribe(() => this.onShare());
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	ngAfterViewInit(): void {
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	onViewPdf() {
		this.viewPdf.emit();
	}

	onExportCsv() {
		this.exportCsv.emit();
	}

	onShare() {
		this.addressBookManager.getContacts(true)
		.pipe(take(1))
		.subscribe((contactsList: AddressBookEntry[]) => {
			if (contactsList.length > 0 ) {
				this.contactsList = contactsList;
				this.dialog.open(this.selectRecipientsDialog);
			} else {
			this.messageBoxService.showMessageBox(this.translate.instant('REPORTS.NO_CONTACT_LIST'));
			}
		});
	}

	onActionErrorAcknowledged() {
		this.errorDismissed.emit();
	}

	onShareRecipientsComplete(result: AddressBookEntry[]) {
		this.share.emit(result);
	}

	// =========================================================================================================================================================
	// Getters/Setters
	// =========================================================================================================================================================

	get isActionsShown() {

		return this.displayRightPane || this.displayTopActionPane;
	}
}

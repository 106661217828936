import { Component, HostBinding } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-icm-diagnostic-mobile-cell-renderer',
	templateUrl: './icm-diagnostic-mobile-cell-renderer.component.html',
	styleUrls: ['./icm-diagnostics-mobile-cell-renderer.component.scss']
})
export class IcmDiagnosticMobileCellRendererComponent implements ICellRendererAngularComp {

	@HostBinding('class') class = 'rb-icm-diagnostic-mobile-cell-renderer';

	data: any;

	RbEnums = RbEnums;

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.data = params.data;
	}

	refresh(params: any): boolean {
		this.data = params.data;
		return true;
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================
}

import { Pipe, PipeTransform } from '@angular/core';

import { RbUtils } from '../../common/utils/_rb.utils';

@Pipe({
	name: 'runtimeToDuration'
})
export class RuntimeToDurationPipe implements PipeTransform {

	transform(value: number): unknown {
		return RbUtils.Stations.convertRuntimeToDuration(value);
	}
}

import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { INavIcon } from '../../../../common/constants/rb-ui.constants';
import { RbConstants } from '../../../../common/constants/_rb.constants';
import { SidebarNavRoute } from '../models/sidebar-nav-route.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-sidebar-route-nav-panel',
	templateUrl: './sidebar-route-nav-panel.component.html',
	styleUrls: ['./sidebar-route-nav-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarRouteNavPanelComponent implements OnInit {
	@Input() sidebarNavRoute: SidebarNavRoute;

	navIcon: INavIcon;

	constructor(private route: ActivatedRoute,
				private router: Router,
				private translate: TranslateService) { }

	ngOnInit() {
	}

	onNavigate() {
		this.router.navigate([this.sidebarNavRoute.route], {
			relativeTo: this.route,
			queryParams: this.sidebarNavRoute.queryParams,
		});
	}

	getNavName(key: string): string {
		this.navIcon = RbConstants.Ui.NAVIGATION_ICONS.find(x => x.key === key);
		return this.translate.instant(key);
	}
}

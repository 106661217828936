export class About {

	coreVersion: string;
	serviceVersion: string;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}
}

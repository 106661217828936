import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class PinLockoutListItemType {

	name: string;
	value: RbEnums.Common.PinLockoutType;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

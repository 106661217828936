import { forkJoin, Observable, of, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';

import { AuthManagerService } from '../auth/auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CachedCollection } from '../_common/cached-collection';
import { DryRun } from './models/dry-run.model';
import { DryRunApiService } from './dry-run-api.service';
import { DryrunCloudManagerService } from './dry-run-manager.cloud.service';
import { DryRunFlowDatumStation } from './models/dry-run-flow-datum-station.model';
import { DryRunResult } from './models/dry-run-result.model';
import { DryRunStatus } from './models/dry-run-status.model';
import { LicenseManagerService } from '../license/license-manager.service';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class DryRunManagerService extends ServiceManagerBase implements OnDestroy {
	dryRunResultChange = new Subject<DryRunStatus>();
	historicalDryRunResultChange = new Subject<DryRunStatus>();
	dryRunType: RbEnums.Common.DryRunType;
	private _dryRunList: CachedCollection<DryRunResult>;
	private _startDate;
	private _endDate;
	private _dryRunListEndTime: CachedCollection<DryRunResult>;
	private _startDateEndTime;
	private _endDateEndTime;
	private _isDryrunInProgess = false;
	private _dryStartTime: Date;
	dryRunId: any = 0;
	dryRunIdForSchedule: any = 0;
	isRunningSimulation = false;
	isRunningSimulation_schedule = false;
	isRunningSimulationCalculate = false;
	isRunningSimulationDryrun = false;
	loadEndTime = false;
	dryRunPercentComplete = 0;
	get startDate() {return this._startDate; }
	get endDate() { return this._endDate; }
	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(protected broadcastService: BroadcastService,
				private dryRunApiService: DryRunApiService,
				private dryrunCloudManagerService: DryrunCloudManagerService,
				private authManager: AuthManagerService,
				private licenseManagerService: LicenseManagerService,
	) {
		super(broadcastService);
	}

	protected clearCache() {

	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	dryRunSimulation(controllerId: number, startDate: Date, endDate: Date,  dryRunType: RbEnums.Common.DryRunType): Observable<any> {
		this.dryRunType = dryRunType;
		if (this.dryRunType === RbEnums.Common.DryRunType.Dryrun) {
			this._startDate = startDate;
			this._endDate = endDate;
		} else {
			this._startDateEndTime = startDate;
			this._endDateEndTime = endDate;
		}
		return this.dryRunApiService.dryRunSimulation(controllerId, startDate, endDate);
	}

	isDryRunResultAvailable(dryRunType: RbEnums.Common.DryRunType): boolean {
		if (dryRunType === RbEnums.Common.DryRunType.Dryrun) {
			if (this._dryRunList) {
				return true;
			}
		} else {
			if (this._dryRunListEndTime) {
				return true;
			}
		}
		return false;
	}

	get isDryrunInProgess() {
		if (this._isDryrunInProgess && this._dryStartTime) {
			const now = new Date();
			const duration = (now.getTime() - this._dryStartTime.getTime()) / 60000;  // to mins
			if (duration > 10) this._isDryrunInProgess = false;
		}
		return this._isDryrunInProgess;
	}

	set isDryrunInProgess(value: boolean) {
		if (value) this._dryStartTime = new Date();
		this._isDryrunInProgess = value;
	}

	isStationLoaded() {
		if (this._dryRunList) {
			if (this._dryRunList.collection[0].dryRunUUID) {
				// For the dynamodb dryrun, the station has been loaded
				return true;
			}
			const dryRunFlowDatum = this._dryRunList.collection[0].flowData;
			const count = dryRunFlowDatum.length;
			const countStation = dryRunFlowDatum.filter(flow => (flow.dryRunFlowDatumStation && flow.dryRunFlowDatumStation.length > 0)).length;
			return count === countStation;
		} else {
			return false;
		}
	}

	/**
	 * Request all dry run flow datum stations from the caller, updating the currently-active dryrun with the station data.
	 * NOTE: The percent complete data will be updated during the load progress.
	 * @returns Observable<DryRunResult> containing the updated dryrun data with station information.
	 */
	getAllDryRunFlowDatumStations(): Observable<DryRunResult> {
		if (this._dryRunList) {
			const dryRunFlowData = this._dryRunList.collection[0].flowData;
			const flowDatumIds = dryRunFlowData
				.filter(flow => (!flow.dryRunFlowDatumStation || flow.dryRunFlowDatumStation.length < 1))
				.map(flow => flow.id);

			// RB-9701: We want chunks of ids no more than 512 per set a) minimizing the transfer time for each chunk and the blocked
			// time, and b) keeping us below the .In() limit used for SQL Server access by the API call, c) not having *so* many requests
			// that we're blocked by the maximum simultaneous requests limit in the browser.
			const totalFlowDatumIds = flowDatumIds.length;
			let idsCompleted = 0;
			const resultArray: Observable<DryRunResult>[] = [];
			while (flowDatumIds.length > 0) {
				const ids = flowDatumIds.splice(0, 512);
				resultArray.push(this.dryRunApiService.getDryRunFlowDatumStations(ids)
					.pipe(
						map(fdStations => {
							idsCompleted += ids.length;

							dryRunFlowData.forEach(flowDatum => {
								const stations = fdStations.filter(flowDatumStation => flowDatumStation.dryRunFlowDatumId === flowDatum.id);
								if (stations && stations.length > 0) flowDatum.dryRunFlowDatumStation = stations;
							});

							// RB-9701: Update progress indicator. We skip this if the caller set it to < 0.
							if (this.dryRunPercentComplete >= 0) {
								this.dryRunPercentComplete = Math.floor(idsCompleted / totalFlowDatumIds * 100);
							}
							return (this._dryRunList.collection[0]);
						})));
			}

			// Return the last final result :-).
			return forkJoin(resultArray).pipe(map(results => {
				return this._dryRunList.collection[0];
			}));
		} else {
			return of(this._dryRunList.collection[0]);
		}
	}

	getDryRuns(start: Date, end: Date): Observable<DryRun[]> {
		return this.dryRunApiService.getDryRuns(start, end);
	}

	getDryRunResult(dryRunIdOrUUID: (number | string) = 0, dryRunType: RbEnums.Common.DryRunType = RbEnums.Common.DryRunType.Dryrun): Observable<DryRunResult> {
		if (dryRunType === RbEnums.Common.DryRunType.Dryrun) {
			if (dryRunIdOrUUID === 0 && this._dryRunList) {
				return of(this._dryRunList.collection[0]);
			}
		} else {
			if (dryRunIdOrUUID === 0 && this._dryRunListEndTime) {
				return of(this._dryRunListEndTime.collection[0]);
			}
		}
		// Guard against call after user logs out.
		if (!this.authManager.isLoggedIn) return of(null);
		if (dryRunIdOrUUID) {
			return this.licenseManagerService.isCloud().pipe(
				switchMap(isCloud => {
					return isCloud ? this.dryrunCloudManagerService.getDryRunDataCloud(dryRunIdOrUUID as string) : 
					this.dryRunApiService.getDryRunResult(dryRunIdOrUUID as number);
				}),
				map(result => {
					if (!result) {
						return null;
					}
					if (dryRunType === RbEnums.Common.DryRunType.Dryrun) {
						result.dryRunStart = this._startDate;
						result.dryRunEnd = this._endDate;
						this._dryRunList = new CachedCollection([result]);
					} else {
						result.dryRunStart = this._startDateEndTime;
						result.dryRunEnd = this._endDateEndTime;
						this._dryRunListEndTime = new CachedCollection([result]);
					}
					return result;
				})
			);
		} else {
			return of(null);
		}
	}

	getDryRunFlowDatumStations(dryRunFlowDatumId: bigint): Observable<DryRunFlowDatumStation[]> {
		if (this._dryRunList && !this._dryRunList.isExpired) {
			const dryRunFlowDatum = this._dryRunList.collection[0].flowData.find(fd => fd.id === dryRunFlowDatumId);
			if (dryRunFlowDatum.dryRunFlowDatumStation && dryRunFlowDatum.dryRunFlowDatumStation.length > 0)
				return of(dryRunFlowDatum.dryRunFlowDatumStation);
		}

		// Guard against call after user logs out.
		if (!this.authManager.isLoggedIn) return of(null);

		return this.dryRunApiService.getDryRunFlowDatumStations([dryRunFlowDatumId])
			.pipe(
				map(result => {
					const dryRunFlowDatum = this._dryRunList.collection[0].flowData.find(fd => fd.id === dryRunFlowDatumId);
					dryRunFlowDatum.dryRunFlowDatumStation = result;
					return result;
				})
			);
	}

	deleteDryRun(dryRunId: number) {
		return this.dryRunApiService.deleteDryRun(dryRunId);
	}

	updateDryrunResult(params: {dryRunId: number, status: RbEnums.SignalR.DryRunFailureReasonCode, dryrunUUID: string}) {
		this.dryRunResultChange.next(new DryRunStatus({
			dryRunId: params.dryRunId,
			dryType: this.dryRunType,
			dryStatus: RbEnums.SignalR.DryrunChangeType.DryRunCompleted, 
			progress: 0,
			reasonCode: params.status,
			dryRunUUID: params.dryrunUUID
		}));
	}
	updateHistoricalDryrunResult(params: {dryRunId: number, status: RbEnums.SignalR.DryRunFailureReasonCode, dryrunUUID: string}) {
		this.historicalDryRunResultChange.next(new DryRunStatus({
			dryRunId: params.dryRunId,
			dryType: this.dryRunType,
			dryStatus: RbEnums.SignalR.DryrunChangeType.HistoricalDryRunCompleted, 
			progress: 0,
			reasonCode: params.status,
			dryRunUUID: params.dryrunUUID
		}));
	}
	updateDryrunProgress(params: {dryRunId: number, progress: number, dryrunUUID: string}) {
		this.dryRunResultChange.next(new DryRunStatus({
			dryRunId: params.dryRunId,
			dryType: this.dryRunType,
			dryStatus: RbEnums.SignalR.DryrunChangeType.DryRunInProgress, 
			progress: params.progress,
			reasonCode: null,
			dryRunUUID: params.dryrunUUID
		}));
	}

	getDryRunErrorMessage(errorCode: RbEnums.SignalR.DryRunFailureReasonCode) {
		switch (errorCode) {
			case RbEnums.SignalR.DryRunFailureReasonCode.AlreadyRunning:
				return 'SPECIAL_MSG.DRY_RUN_STARTED';
			case RbEnums.SignalR.DryRunFailureReasonCode.NoSimulator:
			case RbEnums.SignalR.DryRunFailureReasonCode.Error:
			default:
				return 'SPECIAL_MSG.DRY_RUN_FAILED_RUNTIME';
		}
	}
}

import { Company } from '../../address-book/models/company.model';
import { CultureSettings } from '../../culture-settings/models/culture-settings.model';
import { PinCode } from '../../pin-codes/models/pin-code.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class RbccUser {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.company) { this.company = new Company(json.company); }
			if (json.cultureSettings) { this.cultureSettings = new CultureSettings(json.cultureSettings); }
			if (json.pincode) { this.pincode = json.pincode.map(item => new PinCode(item)); }
		}
	}

	challengeQuestion: RbEnums.Common.ChallengeQuestion;
	ChallengeAnswer: string;
	company: Company;
	companyId: number;
	confirmPassword: string;
	cultureSettings: CultureSettings;
	cultureSettingsId: number;
	email: string;
	groupLevel: RbEnums.Common.GroupLevel;
	id: number;
	isEmailConfirmed = false;
	isImportInProgress = false;
	mobileNumber: string;
	uiSettingsJson: string;
	name: string;
	Password: string;
	pincode: PinCode[] = [];
	timeZone: string;
	workNumber: string;

	static createDefault(companyId: number, cultureSettingsId: number, timeZone: string): RbccUser {
		const user = new RbccUser();

		user.ChallengeAnswer = 'Jonesy';
		user.companyId = companyId;
		user.confirmPassword = '';
		user.cultureSettingsId = cultureSettingsId;
		user.email = '';
		user.name = '';
		user.Password = '';
		user.timeZone = timeZone;
		return user;
	}
}

export class DIDiagnosticSummaryData
{
	/// <summary>
	/// SatelliteId for the LDI/MDI/SDI device described.
	/// </summary>
	satelliteId: number;

	/// <summary>
	/// Satellite Name for the device.
	/// </summary>
	name: string;

	/// <summary>
	/// Last ongoing diagnostic current draw value for the device. NOTE: Unlike the ICI, a value is reported here, even if the
	/// device is in Demo mode, if there are any current values saved. If no values are found, null is returned.
	/// </summary>
	currentmA: number;

	/// <summary>
	/// Last ongoing diagnostic over-current indicator for the device.
	/// </summary>
	overCurrent: boolean;

	/// <summary>
	/// Total station count currently configured for the device.
	/// </summary>
	decoderTotalCount: number;

	/// <summary>
	/// Station counts by decoder type (null is also a valid lookup key), allowing clients to figure out how many stations
	/// with each type of decoder are configured. NOTE: FOR DecoderModel = null STATIONS, THE KEY VALUE WILL BE -1, CASTED
	/// TO (DecoderModel).
	/// </summary>
	stationCountsByDecoderModel: { [decoderType: number /* RbEnums.Common.DecoderModelType */]: number };

	/// <summary>
	/// RB-15106: Number of stations connected to the decoder model. This allows to show the correct number in the UI and
	/// make the correct calculations.
	/// </summary>
	addressCountByDecoderModel: { [decoderType: number /* RbEnums.Common.DecoderModelType */]: number };

	/// <summary>
	/// Indicator of whether device is in demo mode or not.
	/// </summary>
	isDemoMode: boolean;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.stationCountsByDecoderModel) {
				// Clear existing data that Object.assign() may have applied.
				this.stationCountsByDecoderModel = {};

				// Convert list of items, each with decoderType and stationCount to correct dictionary format.
				json.stationCountsByDecoderModel.forEach(msc => {
					// Convert the ModelStationCount list item to dictionary entry.
					this.stationCountsByDecoderModel[msc.decoderModel] = msc.stationCount;
				});
			}
			if(json.addressCountByDecoderModel){
				// Clear existing data that Object.assign() may have applied.
				this.addressCountByDecoderModel = {};

				// Convert list of items, each with decoderType and stationCount to correct dictionary format.
				json.addressCountByDecoderModel.forEach(msc => {
					// Convert the ModelStationCount list item to dictionary entry.
					this.addressCountByDecoderModel[msc.decoderModel] = msc.addressCount;
				});
			}
		}
	}
}

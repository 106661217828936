import { ActiveFlowListItem } from './active-flow-list-item.model';
import { ProgramChange } from '../../../api/signalR/program-change.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { StationStatusChange } from '../../../api/signalR/station-status-change.model';

export class InactiveFlowListItem extends ActiveFlowListItem {

	constructor(changeDate: Date) {
		super(changeDate);
		this.timestamp = changeDate;
	}

	get status(): string {
		throw new Error('Method not implemented.');
	}
	get startTime(): Date {
		throw new Error('Method not implemented.');
	}
	get runtimeRemaining(): string {
		throw new Error('Method not implemented.');
	}

	get id(): number {
		throw new Error('Method not implemented.');
	}
	get name(): string {
		throw new Error('Method not implemented.');
	}
	get typeId(): RbEnums.Common.ActivityType {
		throw new Error('Method not implemented.');
	}
	get type(): string {
		throw new Error('Method not implemented.');
	}
	get typeSortValue(): number {
		throw new Error('Method not implemented.');
	}
	get changeType(): string {
		throw new Error('Method not implemented.');
	}

	timestamp: Date;
	inactiveProgramGroups: ProgramChange[] = [];
	inactivePrograms: ProgramChange[] = [];
	inactiveStations: StationStatusChange[] = [];
}

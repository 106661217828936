import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'rb-radio-group',
	templateUrl: './radio-group.component.html',
	styleUrls: ['./radio-group.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class RadioGroupComponent {
	@ViewChild('controllerSelector', { static: true }) controllerSelector;

	@Input() items;
	@Output() selectionEvent = new EventEmitter;

	selectedControllerName = 'STRINGS.SELECT_CONTROLLER';

	constructor() { }

	handleSelection(item) {
		this.selectionEvent.emit(item.value);
		this.selectedControllerName = item.name;
		this.controllerSelector.close();
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-sidebar-container">

	<div class="rb-sidebar-header">
		<rb-sidebar-header
			[title]="sidebarTitle"
			[showOverflowButton]="false"
			[showFavButton]="false"
			[overflowMenu]="overflowMenu"
		>
			<!-- Optional ellipsis menu -->
			<mat-menu #overflowMenu="matMenu" [overlapTrigger]="false" class="qaid-header-subnav-identity">
				<ng-container>
<!--					<button mat-menu-item data-qaid="header-nav-sort" (click)="onClose()">-->
<!--						<mat-icon>close</mat-icon>-->
<!--						{{'Cancel' | translate}}-->
<!--					</button>-->
				</ng-container>
			</mat-menu>

		</rb-sidebar-header>
	</div>

	<!-- Place Sidebar content in here -->
	<div class="rb-sidebar-body">
		<rb-edit-user
			class="rb-edit-user"
			[canLoadData]="isSidebarOpenComplete"
			[(sidebarTitle)]="sidebarTitle"
			[selectedUserId]="selectedUserId"
			(complete)="onEditComplete($event)"
		>
		</rb-edit-user>
	</div>
</div>

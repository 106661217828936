import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccessRequestManagerService } from '../../../api/access-request/access-request-manager.service';
import { AppDateAdapter } from '../../custom/date-adapter';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { ToasterService } from '../../../common/services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { UserAccessRequestInfoUI } from '../../../api/access-request/models/access-request.model';

@Component({
	selector: 'rb-request-access-snackbar',
	templateUrl: './request-access-snackbar.component.html',
	styleUrls: ['./request-access-snackbar.component.scss'],
})
export class RequestAccessSnackbarComponent implements OnInit, AfterViewInit {
	@ViewChild('container', { static: true }) container;
	@Input() userRequestAccess: UserAccessRequestInfoUI;

	selectedValue: number;
	RbEnums =  RbEnums;
	companyId: number;

	private readonly showNotificationDelay = 2000;
	private readonly focusNotificationDelay = 1000;

	constructor(
		public snackBar: MatSnackBar,
		private toasterService: ToasterService,
		private translate: TranslateService,
		private appDateAdapter: AppDateAdapter,
		private authManager: AuthManagerService,
		private accessRequestManager: AccessRequestManagerService,
		private broadcastService: BroadcastService
	) { }

	get requestAccessNote(): string {
		return this.userRequestAccess
			? this.translate.instant('STRINGS.ADMIN_REQUESTED_ACCESS_NOTE', { date: this.getDateTimeString(this.userRequestAccess.rbtsCodeExpiration) })
			: '';
	}

	showNotification() {
		setTimeout(()  =>  {
			this.toasterService.showNotificationToaster(this.container);
		}, this.showNotificationDelay)
	}

	ngOnInit(): void {
		this.companyId = this.authManager.getUserProfile().companyId;
		this.broadcastService.accessRequestCanceled.subscribe(name => {
			if (this.authManager.currentUser.profile.name === name) {
				this.snackBar.dismiss()
			}
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.container.nativeElement?.focus();
		}, this.focusNotificationDelay);
	}

	dismiss() {
		this.accessRequestManager.dismissRequest()
			.subscribe(_ => this.snackBar.dismiss());
	}

	private getDateTimeString(dateTime: Date): string {
		return dateTime ? this.appDateAdapter.formatDateTime(dateTime) : '-';
	}
}

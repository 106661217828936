import { RbUtils } from '../../../common/utils/_rb.utils';

export class RealTimeWeatherData {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.timestamp && typeof json.timestamp === 'string') this.timestamp = RbUtils.Conversion.convertStringToDate(json.timestamp);
			if (json.temperatureAirMaxTime && typeof json.temperatureAirMaxTime === 'string')
				this.temperatureAirMaxTime = RbUtils.Conversion.convertStringToDate(json.temperatureAirMaxTime);
			if (json.temperatureAirMinTime && typeof json.temperatureAirMinTime === 'string')
				this.temperatureAirMinTime = RbUtils.Conversion.convertStringToDate(json.temperatureAirMinTime);
		}
	}

	/**
	 * Timestamp for weather data entry. EXPRESSED IN THE LOCAL TIME OF THE WEATHER SOURCE.
	 */
	timestamp: Date;
	temperatureAirAverage: number;
	temperatureAirMax: number;
	temperatureAirMin: number;
	temperatureAirMaxTime: Date;
	temperatureAirMinTime: Date;
	temperatureSoilAverage: number;
	temperatureSoilMax: number;
	temperatureSoilMin: number;
	humidityAirAverage: number;
	dewPoint: number;
	windSpeedRun: number;
	windSpeedAverage: number;
	windSpeedMax: number;
	windSpeedMaxDirection: number;
	windDirectionAverage: number;
	windDirectionStDev: number;
	solarRadiation: number;
	solarRadiationToday: number;
	rainCurrent: number;
	rain24Hours: number;
	rainToday: number;
	rainYesterday: number;
	vaporPressureNormal: number;
	etcalculated: number;
	etgiven: number;
	etreplacement: number;

	static createDefault(): RealTimeWeatherData {
		const source = new RealTimeWeatherData();
		source.temperatureAirAverage = 0;
		source.temperatureAirMax = 0;
		source.temperatureAirMin = 0;
		source.temperatureSoilAverage = 0;
		source.temperatureSoilMax = 0;
		source.temperatureSoilMin = 0;
		source.humidityAirAverage = 0;
		source.dewPoint = 0;
		source.windSpeedRun = 0;
		source.windSpeedAverage = 0;
		source.windSpeedMax = 0;
		source.windSpeedMaxDirection = 0;
		source.windDirectionAverage = 0;
		source.windDirectionStDev = 0;
		source.solarRadiation = 0;
		source.rainCurrent = 0;
		source.rain24Hours = 0;
		source.rainToday = 0;
		source.rainYesterday = 0;
		source.vaporPressureNormal = 0;
		source.etgiven = 0;
		source.etreplacement = 0;
		source.etcalculated = 0;

		return source;
	}
}

import { AutoContact } from '../../auto-contact/models/auto-contact.model';
import { CommResource } from '../../comm-interfaces/models/comm-resource.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class WeatherSource {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here
			if (json.commInterface) { this.commInterface = new CommResource(json.commInterface); }
			if (json.earliestRecordDate) { this.earliestRecordDate = RbUtils.Conversion.convertStringToDate(json.earliestRecordDate); }
			if (json.osdate) { this.osdate = RbUtils.Conversion.convertStringToDate(json.osdate); }
			if (json.lastPhysicalRetrieve) { this.lastPhysicalRetrieve = RbUtils.Conversion.convertStringToDate(json.lastPhysicalRetrieve); }
			if (json.userRequestedStartDate) { this.userRequestedStartDate = RbUtils.Conversion.convertStringToDate(json.userRequestedStartDate); }
		}
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================

	assignedControllerIds: number[];
	assignedSiteIds: number[]
	altitude: number;
	autoContact: AutoContact;
	autoContactEnabled: boolean;
	autoContactRetryDelay: string;
	autoContactRetries: string;
	calculatedEt: number;
	commInterfaceId: number;
	company: any;
	companyId: number;
	commInterface: any;
	currentEt = 0;
	description: string;
	earliestRecordDate: Date;
	elevation: number;
	id: number;
	latitude: number;
	lastPhysicalRetrieve: Date;
	longitude: number;
	name: string;
	osdate: Date;
	osversion: string;
	pakBusAddress: number;
	password: string;
	previousPassword: string;
	program: any[];
	programName: string;
	satellite: any[];
	securityCode: number;
	serialNumber: string;
	stationId: number;
	timeZone: string;
	type: RbEnums.Common.WeatherSourceType;
	userName: string;
	userRequestedStartDate: Date;
	contactType: RbEnums.Common.ContactType;
	wsAutoContactTime: Date[];
	highestAvgET: number;
	apiKey: string;

	get typeDescription(): string {
		switch (this.type) {
			case RbEnums.Common.WeatherSourceType.WsProLT:
				return RbUtils.Translate.instant('STRINGS.WEATHER_SOURCE_TYPE_WS_PRO_LT');
			case RbEnums.Common.WeatherSourceType.WsPro2:
				return RbUtils.Translate.instant('STRINGS.WEATHER_SOURCE_TYPE_WS_PRO_2');
			case RbEnums.Common.WeatherSourceType.Cimis:
				return RbUtils.Translate.instant('STRINGS.WEATHER_SOURCE_TYPE_CIMIS');
			case RbEnums.Common.WeatherSourceType.GlobalWeather:
				return RbUtils.Translate.instant('STRINGS.WEATHER_SOURCE_TYPE_GLOBAL_WEATHER');
			case RbEnums.Common.WeatherSourceType.GlobalWeatherPro:
				return RbUtils.Translate.instant('STRINGS.WEATHER_SOURCE_TYPE_GLOBAL_WEATHER_PRO');
			case RbEnums.Common.WeatherSourceType.ManualWeatherSource:
				return RbUtils.Translate.instant('STRINGS.MANUAL_ENTRY');
			case RbEnums.Common.WeatherSourceType.WatchDogWeatherSource:
				return RbUtils.Translate.instant('STRINGS.WATCH_DOG');
		}
	}

	static createDefault(): WeatherSource {
		const source = new WeatherSource();
		source.altitude = 0;
		source.autoContactEnabled = false;
		source.autoContactRetries = '';
		source.commInterface = [ new CommResource({ type: RbEnums.Common.CommInterfaceType.Ethernet, commPort: '', ipString: '0.0.0.0', ipPort: 50005 } ) ];
		source.commInterfaceId = 0;
		source.companyId = 0;
		source.elevation = 0;
		source.latitude = 0;
		source.longitude = 0;
		source.osversion = '';
		source.pakBusAddress = 1;
		source.password = '';
		source.previousPassword = '';
		source.programName = '';
		source.securityCode = 0;
		source.serialNumber = '';
		source.timeZone = 'Pacific Standard Time';
		source.userName = '';

		return source;
	}
}

import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { WeatherSource } from '../../weather-sources/models/weather-source.model';

export class CommInterface {

	comPort: string;
	companyID: number;
	id: number;
	ipPort: number;
	ipString: string;
	lastGoodBaud: number;
	name: string = null;
	phoneNumber: string;
	radioAddress: number;
	satelliteID: number;
	type: number;
	useVpn = false;
	weatherSource: WeatherSource;
	weatherSourceID: number;

	constructor(json: any = null) {

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			if (json.weatherSource) { this.weatherSource = json.weatherSource.map(item => new WeatherSource(item)); }
		}
	}

	static createDefault(): CommInterface {
		const commInterface = new CommInterface();
		commInterface.type = RbEnums.Common.CommInterfaceType.None;
		commInterface.comPort = '';
		commInterface.ipPort = RbConstants.Form.IP_PORT;
		commInterface.ipString = RbConstants.Form.IP_NAME;
		commInterface.radioAddress = 0;
		return commInterface;
	}
}

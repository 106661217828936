import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { DownloadTilesControl } from '../../../../../common/models/download-tiles-control';
import { LicenseManagerService } from '../../../../../api/license/license-manager.service';
import { MapLeafletService } from '../../../../../common/services/map-leaflet.service';
import { MapManagementService } from '../../../../../common/services/map-management.service';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { SiteManagerService } from '../../../../../api/sites/site-manager.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'rb-common-layers',
	templateUrl: './common-layers.component.html',
	styleUrls: ['./common-layers.component.scss'],
})
export class CommonLayersComponent implements OnDestroy, OnInit {

	@HostBinding('class') class = 'rb-common-layers';

	@Input() detailOptionsDisabled = false;
	@Input() busy;
	@Input() set noInternet(value) {
		this._noInternet = value;
		if (!value) {
			if (this.selectedBackground !== this.preferredBackground) {
				this.selectedBackground = this.preferredBackground;
				this.changeBackground(this.selectedBackground, false);
			}
			this.backgroundOptions.forEach(option => {
				option.disabled = false;
			});
		} else if (DownloadTilesControl.hasDownloadedTiles) {
			this.selectedBackground = RbEnums.Map.BackgroundLayer.Offline;
			this.changeBackground(RbEnums.Map.BackgroundLayer.Offline, false);

			this.backgroundOptions.forEach(option => {
				if (option.value === RbEnums.Map.BackgroundLayer.Offline) {
					return;
				}
				option.disabled = true;
			});
		}
	
	}

	@Output() layerSelectionChange = new EventEmitter<{ layer: RbEnums.Map.MapLayer, selected: boolean }>();
	@Output() baseLayerSelectionChange = new EventEmitter<{ baseLayerId: string, saveLayerConfig: boolean }>();
	@Output() textColorChange = new EventEmitter<string>();
	@Output() downloadTiles = new EventEmitter();
	@Output() removeTiles = new EventEmitter();
	@Output() exportPdf = new EventEmitter();

	layerOptions = [
		{
			text: this.translate.instant('STRINGS.HOLES'),
			value: RbEnums.Map.MapLayer.Holes,
			selected: true,
			visible: true,
			isDetailOption: false,
		},
		{
			text: this.translate.instant('STRINGS.AREAS'),
			value: RbEnums.Map.MapLayer.Areas,
			selected: true,
			visible: true,
			isDetailOption: false,
		},
		{
			text: this.translate.instant('STRINGS.STATIONS'),
			value: RbEnums.Map.MapLayer.Stations,
			selected: true,
			visible: true,
			isDetailOption: false,
			submenuOpen: false,
			submenu: [
				{
					text: this.translate.instant('STRINGS.NAME'),
					value: RbEnums.Map.MapLayer.StationNames,
					selected: false,
					visible: true,
					isDetailOption: false,
				},
				{
					text: this.translate.instant('STRINGS.NOZZLE_COLOR'),
					value: RbEnums.Map.MapLayer.NozzleColor,
					selected: false,
					visible: true,
					isDetailOption: false,
				},
				{
					text: this.translate.instant(
						'STRINGS.ADJUSTMENTS_BADGE'
					),
					value: RbEnums.Map.MapLayer.StationAdjustments,
					selected: false,
					visible: true,
					isDetailOption: false,
				},
				{
					text: this.translate.instant(
						'STRINGS.CYCLE_SOAK_BADGE'
					),
					value: RbEnums.Map.MapLayer.StationCycleSoak,
					selected: false,
					visible: true,
					isDetailOption: false,
				},
			],
		},
		{
			text: this.translate.instant('STRINGS.IRRIGATION'),
			value: RbEnums.Map.MapLayer.Irrigation,
			selected: true,
			visible: true,
			isDetailOption: false,
			submenuOpen: false,
			submenu: [
				{
					text: this.translate.instant('STRINGS.RUNTIME'),
					value: RbEnums.Map.MapLayer.StationRuntimes,
					selected: false,
					visible: true,
					isDetailOption: false,
				},
			],
		},
		{
			text: this.translate.instant('STRINGS.ALERTS'),
			value: RbEnums.Map.MapLayer.Alerts,
			selected: true,
			visible: true,
			isDetailOption: false,
		},
		{
			text: this.translate.instant('STRINGS.ALLOW_MAP_ITEMS_TO_MOVE'),
			value: RbEnums.Map.MapLayer.Moveable,
			selected: false,
			visible: true,
			isDetailOption: false,
		},
	];
	backgroundOptions = [
		{
			text: 'Esri',
			value: RbEnums.Map.BackgroundLayer.Esri,
			selected: true,
			visible: true,
			disabled: false,
		},
		{
			text: this.translate.instant('STRINGS.DOWNLOADED_ESRI_TILES'),
			value: RbEnums.Map.BackgroundLayer.Offline,
			selected: false,
			visible: false,
			disabled: false,
			suboption: true,
		},
		{
			text: 'Google Maps Satellite',
			value: RbEnums.Map.BackgroundLayer.GoogleStreetMap,
			selected: false,
			visible: true,
			disabled: false,
		},
		{
			text: 'Google Maps Roadmap',
			value: RbEnums.Map.BackgroundLayer.GoogleRoadMap,
			selected: false,
			visible: true,
			disabled: false,
		},
		{
			text: 'Open Street Map',
			value: RbEnums.Map.BackgroundLayer.OSM,
			selected: false,
			visible: true,
			disabled: false,
		},
		{
			text: 'No Background',
			value: RbEnums.Map.BackgroundLayer.None,
			selected: false,
			visible: true,
			disabled: false,
		},
	];

	selectedBackground = RbEnums.Map.BackgroundLayer.Esri;
	presetTextColors = ['#FFF', '#BBB'];
	textColor = '#FFF';
	isGolfSite: boolean;
	allowFieldTestFeatures: boolean;
	isDownloadTilesShown = true;

	isMobile = false;
	private subscriptions = new Subscription();
	preferredBackground: RbEnums.Map.BackgroundLayer;
	_noInternet: boolean;

	get currentMap() {
		return this.mapManager.currentMap.value;
	}

	set currentMap(value: number) {
		this.mapManager.currentMap.next(value);
	}

	get mapSwitchEnabled() {
		if (this.mapManager == null || this.mapManager.mapSwitchEnabled == null)
			return false;

		return this.mapManager.mapSwitchEnabled;
	}

	get hasDownloadedTiles() {
		return DownloadTilesControl.hasDownloadedTiles;
	}

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================
	constructor(
		private authManager: AuthManagerService,
		private deviceManager: DeviceManagerService,
		private licenseManager: LicenseManagerService,
		private mapManager: MapManagementService,
		public mapService: MapLeafletService,
		private siteManager: SiteManagerService,
		private translate: TranslateService,
	) { }

	ngOnInit() {
		this.isGolfSite = this.siteManager.isGolfSite;
		this.allowFieldTestFeatures = this.authManager.allowFieldTestFeatures;
		if (!this.siteManager.isGolfSite) {
			// RB-13612: IQ4 - Hiding the Download Tiles from the Maps feature for IQ4 Cloud users (isCloud) only
			this.licenseManager.isCloud().subscribe(isCloud => {
				this.isDownloadTilesShown = !isCloud;
			});

			this.layerOptions.splice(0, 2,
				{
					text: this.translate.instant('STRINGS.CONTROLLERS'),
					value: RbEnums.Map.MapLayer.Controllers,
					selected: true,
					visible: true,
					isDetailOption: false,
					submenuOpen: false,
					submenu: [
						{
							text: this.translate.instant('STRINGS.SHOW_SERVER_CLIENT_STATUS'),
							value: RbEnums.Map.MapLayer.ControllerStatus,
							selected: false,
							visible: true,
							isDetailOption: true
						},
						{
							text: this.translate.instant('STRINGS.SHOW_SERVER_CLIENT_RELATIONSHIP'),
							value: RbEnums.Map.MapLayer.ControllerRelationships,
							selected: false,
							visible: true,
							isDetailOption: true
						},
					]
				}
			);
			this.layerOptions[1].submenu.push(
				{
					text: this.translate.instant('STRINGS.SHOW_STATION_AREAS'),
					value: RbEnums.Map.MapLayer.StationAreas,
					selected: false,
					visible: true,
					isDetailOption: true
				});
			this.layerOptions[1].submenu.push(
				{
					text: this.translate.instant('STRINGS.SHOW_SHAPES'),
					value: RbEnums.Map.MapLayer.StationAreas,
					selected: false,
					visible: true,
					isDetailOption: true
				});
			this.layerOptions.splice(1, 0,
				{
					text: this.translate.instant('STRINGS.SENSORS'),
					value: RbEnums.Map.MapLayer.Sensors,
					selected: true,
					visible: true,
					isDetailOption: true,
					submenuOpen: false,
					submenu: [
						{
							text: this.translate.instant('STRINGS.NAME'),
							value: RbEnums.Map.MapLayer.SensorNames,
							selected: false,
							visible: true,
							isDetailOption: true
						}
					]
				}
			);
			this.layerOptions.splice(2, 0,
				{
					text: this.translate.instant('STRINGS.SHOW_STICKY_NOTES'),
					value: RbEnums.Map.MapLayer.StickyNotes,
					selected: false,
					visible: true,
					isDetailOption: true,
					submenuOpen: false,
					submenu: [
						{
							text: this.translate.instant('STRINGS.ADD_STICKY_NOTE'),
							value: RbEnums.Map.MapLayer.AddStickyNote,
							selected: false, visible: true, isDetailOption: true
						}
					]
				}
			);

			this.layerOptions.splice(1, 0,
				{
					text: this.translate.instant('STRINGS.MASTER_VALVES'),
					value: RbEnums.Map.MapLayer.MasterValves,
					selected: true, visible: true, isDetailOption: false,
					submenuOpen: false,
					submenu: [
						{
							text: this.translate.instant('STRINGS.SHOW_SHAPES'),
							value: RbEnums.Map.MapLayer.MasterValvesAreas,
							selected: false,
							visible: true,
							isDetailOption: true
						}
					]
				}
			);

			// RB-12622: IQ4 - Nozzle Color should not be visible for IQ4
			{
				const stationLayerOptions = this.layerOptions?.find(x => x.value === RbEnums.Map.MapLayer.Stations);
				const nozzleColorSubMenu = stationLayerOptions?.submenu?.find(x => x.value === RbEnums.Map.MapLayer.NozzleColor);
				if (nozzleColorSubMenu) {
					nozzleColorSubMenu.visible = false;
				}
			}
		} else {
			this.layerOptions.push({
				text: this.translate.instant('STRINGS.NOTES'),
				value: RbEnums.Map.MapLayer.Notes,
				selected: false,
				visible: true,
				isDetailOption: false,
				submenuOpen: false,
				submenu: [
					{
						text: this.translate.instant('STRINGS.ANIMATE_NOTES'),
						value: RbEnums.Map.MapLayer.NoteAnimation,
						selected: true,
						visible: true,
						isDetailOption: false,
					},
				],
			});
		}
		this.isMobile = this.deviceManager.isMobile;

		// Monitor mobile resizing
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => this.isMobile = isMobile);
	}

	ngOnDestroy(): void {
		/** Required for untilDestroyed() */
		this.subscriptions.unsubscribe();
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onOptionChanged(option: any) {
		this.layerSelectionChange.emit({ layer: option.value, selected: option.selected });

		if (option.submenu) {
			option.submenu.forEach(subOption => {
				subOption.disabled = !option.selected;
			});
		}
	}

	onAddStickyNote(option: any) {
		this.layerSelectionChange.emit({ layer: option.value, selected: option.selected });
	}

	onTextColorChanged() {
		this.textColorChange.emit(this.textColor);
	}

	updateLayerVisibility(layerVisibility: any, areItemsMoveable: boolean) {
		this.applyLayerVisibilitySettings({ layerVisibility, areItemsMoveable });

		// Background layer
		if (this._noInternet) {
			if (!this.preferredBackground) {
				this.preferredBackground = layerVisibility.selectedBaseLayer;
			}
		} else {
			this.selectedBackground = layerVisibility.selectedBaseLayer;
			this.preferredBackground = layerVisibility.selectedBaseLayer;
		}

		if (layerVisibility.textColor != null) this.textColor = layerVisibility.textColor;

		// Monitor downloaded map tiles after receiving the user's map preferences
		this.subscriptions.add(DownloadTilesControl.downloadedTilesChange.subscribe(this.onTilesDownload));
	}

	changeBackground(event: RbEnums.Map.BackgroundLayer, savePreferredBackground = true) {
		
		if (event == null) {
			return;
		}

		this.baseLayerSelectionChange.emit({ baseLayerId: event, saveLayerConfig: savePreferredBackground });
		if (savePreferredBackground) {
			this.preferredBackground = event;
		}
	}

	onDownloadTilesClick() {
		this.downloadTiles.emit();
	}

	onExportPDFClick() {
		this.exportPdf.emit();
	}

	onRemoveTiles() {
		this.removeTiles.emit();
	}

	private applyLayerVisibilitySettings(config: { layerVisibility: any, areItemsMoveable?: boolean, layerOptions?: any, parentOffOrDisabled?: boolean }) {
		if (!config.layerOptions) {
			config.layerOptions = this.layerOptions;
		}

		config.layerOptions.forEach(option => {
			switch (option.value) {
				case RbEnums.Map.MapLayer.StationAdjustments:
					option.selected = config.layerVisibility.showingStationAdjustments;
					break;
				case RbEnums.Map.MapLayer.Areas:
					option.selected = config.layerVisibility.showingAreas;
					break;
				case RbEnums.Map.MapLayer.Alerts:
					option.selected = config.layerVisibility.showingAlerts;
					break;
				case RbEnums.Map.MapLayer.Holes:
					option.selected = config.layerVisibility.showingHoles;
					break;
				case RbEnums.Map.MapLayer.Irrigation:
					option.selected = config.layerVisibility.showingIrrigation;
					break;
				case RbEnums.Map.MapLayer.Moveable:
					option.selected = config.layerVisibility.moveable;
					option.visible = config.areItemsMoveable;
					break;
				case RbEnums.Map.MapLayer.Controllers:
					if (!this.siteManager.isGolfSite) {
						option.selected = config.layerVisibility.showingControllers;
					}
					break;
				case RbEnums.Map.MapLayer.ControllerStatus:
					if (!this.siteManager.isGolfSite) {
						option.selected = config.layerVisibility.showingServerClientStatus;
					}
					break;
				case RbEnums.Map.MapLayer.ControllerRelationships:
					if (!this.siteManager.isGolfSite) {
						option.selected = config.layerVisibility.showingServerClientRelationships;
					}
					break;
				case RbEnums.Map.MapLayer.Sensors:
					option.selected = config.layerVisibility.showingSensors;
					break;
				case RbEnums.Map.MapLayer.SensorNames:
					option.selected = config.layerVisibility.showingSensorNames;
					break;
				case RbEnums.Map.MapLayer.Stations:
					option.selected = config.layerVisibility.showingStations;
					break;
				case RbEnums.Map.MapLayer.StationNames:
					option.selected = config.layerVisibility.showingStationNames;
					break;
				case RbEnums.Map.MapLayer.StationRuntimes:
					option.selected = config.layerVisibility.showingStationRuntimes;
					break;
				case RbEnums.Map.MapLayer.NozzleColor:
					option.selected = config.layerVisibility.showingNozzleColors;
					break;
				case RbEnums.Map.MapLayer.StationCycleSoak:
					option.selected = config.layerVisibility.showingStationCycleSoak;
					break;
				case RbEnums.Map.MapLayer.StickyNotes:
					option.selected = config.layerVisibility.showingStickyNotes;
					break;
				case RbEnums.Map.MapLayer.Notes:
					option.selected = config.layerVisibility.showingNotes;
					break;
				case RbEnums.Map.MapLayer.NoteAnimation:
					option.selected = config.layerVisibility.showingNotesAnimation;
					break;
				case RbEnums.Map.MapLayer.StationAreas:
					option.selected = config.layerVisibility.showingStationGeoAreas;
					break;
				case RbEnums.Map.MapLayer.MasterValves:
					if (!this.isGolfSite) {
						option.selected = config.layerVisibility.showingMasterValves;
					}
					break;
				case RbEnums.Map.MapLayer.MasterValvesAreas:
					if (!this.isGolfSite) {
						option.selected = config.layerVisibility.showingMasterValvesGeoAreas;
					}
					break;
			}
			option.disabled = config.parentOffOrDisabled;

			if (option.submenu) {
				this.applyLayerVisibilitySettings({
					layerOptions: option.submenu,
					layerVisibility: config.layerVisibility,
					parentOffOrDisabled: !option.selected || option.disabled
				});
			}
		});
	}

	// /**
	//  * Event handler that hides/shows the Offline background layer option
	//  * based on whether the device has downloaded map tiles or not.
	//  *
	//  * It also changes the background layer selection to Esri in case the
	//  * offline layer was selected and there are no downloaded map tiles.
	//  */
	private onTilesDownload = (hasDownloadedTiles: boolean) => {
		this.backgroundOptions
			.find(option => option.value === RbEnums.Map.BackgroundLayer.Offline)
			.visible = this.isDownloadTilesShown ? hasDownloadedTiles : false;

		if (!hasDownloadedTiles && this.selectedBackground === RbEnums.Map.BackgroundLayer.Offline) {
			this.selectedBackground = RbEnums.Map.BackgroundLayer.Esri;
			this.changeBackground(this.selectedBackground);
		}
	}

	// =========================================================================================================================================================
	// Map Layer Methods
	// =========================================================================================================================================================

	isLayerVisible(layer: RbEnums.Map.MapLayer): boolean {
		const layerOption = this.layerOptions.find(l => l.value === layer);
		return layerOption == null ? false : layerOption.selected;
	}

	isAddLayer(layerValue: number): boolean {
		return layerValue === RbEnums.Map.MapLayer.AddStickyNote;
	}

}

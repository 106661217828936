/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Report.reportCard
 * 		 See: _rb.utils.ts
 */
import { RbEnums } from '../enumerations/_rb.enums';
import { XXUseRbEnumsNamespace } from '../enumerations/rb-common.enums';

export enum ReportCardCategory {
	WATER_MANAGEMENT = 'WATER_MANAGEMENT',
	CONFIGURATION = 'CONFIGURATION',
	AUDIT = 'AUDIT'
}
export interface IReportCard {
	id: number;
	img: string;
	iconClass?: string;
	title: string;
	content: string;
	color?: string;
	gradient: string;
	route: string;
	category: ReportCardCategory;
}
export namespace XXUseRbUtilsNamespace {

	import ReportCard = XXUseRbEnumsNamespace.Common.ReportCard;

	export abstract class Reports {

		static getReportCards(isGolf: boolean, isMobile: boolean): any {

			const reportsList: IReportCard[] = [
				{
					id: ReportCard.StationRuntime,
					img: '../../../assets/run_time.svg',
					iconClass: 'mdi mdi-timer-outline',
					title: 'STRINGS.STATION_RUN_TIME',
					content: 'REPORT_CARD.STATION_RUN_TIME_CONTENT',
					color: '#15AD79',
					gradient: 'linear-gradient(0deg, #203A43 0%, #2C5364 87%)',
					route: './stationruntime',
					category: ReportCardCategory.WATER_MANAGEMENT,
				},
				{
					id: ReportCard.WaterManagement,
					img: '../../../assets/water_management.svg',
					iconClass: 'mdi mdi-water-sync',
					title: 'STRINGS.WATER_MANAGEMENT',
					content: 'REPORT_CARD.WATER_MANAGEMENT_CONTENT',
					color: '#2C91E2',
					gradient: 'linear-gradient(-180deg, #00C9FF 0%, #92FE9D 87%)',
					route: './watermanagement',
					category: ReportCardCategory.WATER_MANAGEMENT,
				},
				{
					id: ReportCard.MonthlyReportCard,
					img: '../../../assets/monthly_report_card.svg',
					iconClass: 'mdi mdi-text-box-check-outline',
					title: 'REPORT_CARD.MONTHLY_REPORT_CARD_TITLE',
					content: 'REPORT_CARD.MONTHLY_REPORT_CARD_CONTENT',
					color: '#FF961B',
					gradient: 'linear-gradient(-180deg, #E866D0 5%, #FB9B59 86%)',
					route: './monthlyreportcard',
					category: ReportCardCategory.WATER_MANAGEMENT,
				},
				{
					id: ReportCard.FlowLogGraph,
					img: '../../../assets/flowlog.svg',
					iconClass: 'mdi mdi-chart-bell-curve-cumulative',
					title: 'STRINGS.FLOW_LOG_GRAPH',
					content: isGolf ? 'REPORT_CARD.FLOW_LOG_GRAPH_CONTENT_GOLF' : 'REPORT_CARD.FLOW_LOG_GRAPH_CONTENT',
					color: '#FFC924',
					gradient: 'linear-gradient(0deg, #0A49B5 0%, #2BEEF1 100%)',
					route: './flowloggraph',
					category: ReportCardCategory.WATER_MANAGEMENT,
				},
				{
					id: ReportCard.MonthlyFlow,
					img: '../../../assets/monthly_flow.svg',
					iconClass: 'mdi mdi-calendar-arrow-right',
					title: 'STRINGS.MONTHLY_FLOW',
					content: 'REPORT_CARD.MONTHLY_FLOW_CONTENT',
					color: '#C96FF5',
					gradient: 'linear-gradient(0deg, #E586F2 15%, #33A1F2 99%)',
					route: './monthlyflow',
					category: ReportCardCategory.WATER_MANAGEMENT,
				},
				{
					id: ReportCard.YearlyWaterConsumption,
					img: '../../../assets/annual_water_consumption.svg',
					iconClass:'mdi mdi-calendar-search-outline',
					title: 'STRINGS.YEARLY_WATER_CONSUMPTION',
					content: 'REPORT_CARD.YEARLY_WATER_CONSUMPTION_CONTENT',
					color: '#8319B6',
					gradient: 'linear-gradient(0deg, #E55D87 0%, #5FC3E4 77%)',
					route: './yearlywaterconsumption',
					category: ReportCardCategory.WATER_MANAGEMENT,
				},
				{
					id: ReportCard.WaterUseByFloZone,
					img: '../../../assets/water_use.svg',
					iconClass: 'mdi mdi-water-alert-outline',
					title: 'STRINGS.WATER_USE_BY_FLOZONE',
					content: 'REPORT_CARD.WATER_USE_BY_FLOWZONE_CONTENT',
					color: '#534CFF',
					gradient: 'linear-gradient(-180deg, #D3DD1D 0%, #534CFF 100%)',
					route: './waterusebyflozone',
					category: ReportCardCategory.WATER_MANAGEMENT,
				},
				{
					id: ReportCard.Rainfall,
					img: '../../../assets/weather-pouring.svg',
					iconClass: 'mdi mdi-weather-pouring',
					title: 'STRINGS.RAINFALL',
					content: 'REPORT_CARD.RAINFALL_CONTENT',
					color: '#005694',
					gradient: 'linear-gradient(0deg, #E5E5E9 0%, #0D194E 100%)',
					route: './rainfall',
					category: ReportCardCategory.WATER_MANAGEMENT,
				},
				{
					id: ReportCard.Weather,
					img: '../../../assets/weather-pouring.svg',
					iconClass: 'mdi mdi-weather-partly-cloudy',
					title: 'STRINGS.WEATHER',
					content: 'REPORT_CARD.WEATHER_CONTENT',
					color: '#83D9F0',
					gradient: 'linear-gradient(0deg, #FBE184 0%, #5BBEF3 100%)',
					route: './weather',
					category: ReportCardCategory.WATER_MANAGEMENT,
				},
				{
					id: ReportCard.AlarmsAndWarning,
					img: '../../../assets/alarms.svg',
					iconClass: 'mdi mdi-alert-decagram-outline',
					title: 'STRINGS.ALARMS_AND_WARNINGS',
					content: 'REPORT_CARD.ALARMS_AND_WARNINGS_CONTENT',
					color: '#DA2036',
					gradient: 'linear-gradient(-180deg, #f12711 0%, #f5af19 57%)',
					route: './alarmsandwarnings',
					category: ReportCardCategory.AUDIT,
				},

			];

			if (!isGolf) {
				reportsList.push(
				// Commented-out the code for now to hide the System Change Log until fix the scalability and speed performance.
				// {
				// 	id: ReportCard.SystemChangeLog,
				// 	img: '../../../assets/differences.svg',
				// 	title: 'STRINGS.SYSTEM_CHANGE_LOG',
				// 	content: isGolf ? 'REPORT_CARD.SYSTEM_CHANGE_LOG_CONTENT_GOLF' : 'REPORT_CARD.SYSTEM_CHANGE_LOG_CONTENT',
				// 	gradient: 'linear-gradient(0deg, #FDDB92 0%, #D1FDFF 100%)',
				// 	route: './systemchangelog',
				// 	category: ReportCardCategory.AUDIT,
				// },
				{
					id: ReportCard.PinCodeAudit,
					img: '../../../assets/security.svg',
					iconClass: 'mdi mdi-security',
					title: 'STRINGS.PIN_CODE_AUDIT',
					content: 'REPORT_CARD.PIN_CODE_AUDIT_CONTENT',
					gradient: 'linear-gradient(-180deg, #F26609 0%, #FFC050 87%)',
					route: './pincode',
					category: ReportCardCategory.AUDIT,
				},
				{
					id: ReportCard.IQNetConfiguration,
					img: '../../../assets/iqnet_configuration_report.svg',
					title: 'REPORT_CARD.IQNET_CONFIGURATION_TITLE',
					content: 'REPORT_CARD.IQNET_CONFIGURATION_CONTENT',
					gradient: 'linear-gradient(0deg, #E56F9B 0%, #ACC6F3 100%)',
					route: './iqnetconfiguration',
					category: ReportCardCategory.CONFIGURATION,
				},
				{
					id: ReportCard.ControllerDifferences,
					img: '../../../assets/differences.svg',
					iconClass: 'mdi mdi-triangle-outline',
					title: 'STRINGS.CONTROLLER_DIFFERENCES',
					content: 'REPORT_CARD.CONTROLLER_DIFFERENCES_CONTENT',
					gradient: 'linear-gradient(-180deg, #02474D 0%, #16C2C7 87%)',
					route: './controllerdifferences',
					category: ReportCardCategory.AUDIT,
				},
				{
					id: ReportCard.SiteConfiguration,
					img: '../../../assets/site_configuration.svg',
					title: 'STRINGS.SITE_CONFIGURATION',
					content: 'REPORT_CARD.SITE_CONFIGURATION_CONTENT',
					gradient: 'linear-gradient(0deg, #D7CED1 0%, #49E9B2 100%)',
					route: './siteconfiguration',
					category: ReportCardCategory.CONFIGURATION,
				},
				{
					id: ReportCard.ControllerConfiguration,
					img: '../../../assets/controller-configuration-report.svg',
					title: 'STRINGS.CONTROLLER_CONFIGURATION',
					content: 'REPORT_CARD.CONTROLLER_CONFIGURATION_CONTENT',
					gradient: 'linear-gradient(0deg, #21914b 0%, #42D435 100%)',
					route: './controllerconfiguration',
					category: ReportCardCategory.CONFIGURATION,
				},
				{
					id: ReportCard.Program,
					img: '../../../assets/program_report.svg',
					title: 'REPORT_CARD.PROGRAM_TITLE',
					content: 'REPORT_CARD.PROGRAM_CONTENT',
					gradient: 'linear-gradient(0deg, #135058 0%, #F1F2B5 100%)',
					route: './programsettings',
					category: ReportCardCategory.CONFIGURATION,
				},
				{
					id: ReportCard.DailyControllerIrrigation,
					img: '../../../assets/daily-controller-irrigation.svg',
					// iconClass: 'mdi mdi-water-outline',
					title: 'STRINGS.DAILY_CONTROLLER_IRRIGATION',
					content: 'REPORT_CARD.DAILY_CONTROLLER_IRRIGATION_CONTENT',
					gradient: 'linear-gradient(0deg, #c2d993 0%, #d4a335 100%)',
					route: './dailycontrollerirrigation',
					category: ReportCardCategory.AUDIT,
				});
			}

			return reportsList;
		}

		static getReportCardsByType(isGolf: boolean, isAdmin = false): {[index: string]: IReportCard[]} {
			const audits: IReportCard[] = [];
			const configurations: IReportCard[] = [];
			const waterManagements: IReportCard[] = [];

			// the isMobile haven't been implemented yet, put false as default
			const list: IReportCard[] = Reports.getReportCards(isGolf, false);
			list.forEach((report: IReportCard) => {
				switch (report.category) {
					case ReportCardCategory.WATER_MANAGEMENT: {
						// Only IQ4 can see rain fall
						// Only CirrusIC can see Water Use By FloZone
						if ((isGolf && report.id !== ReportCard.WaterUseByFloZone) || (!isGolf && report.id !== ReportCard.Rainfall)) {
							waterManagements.push(report);
						}
						break;
					}
					case ReportCardCategory.CONFIGURATION: {
						configurations.push(report);
						break;
					}
					case ReportCardCategory.AUDIT: {
						// only admin can see system change log report
						if (isAdmin || report.id !==  ReportCard.SystemChangeLog) {
							audits.push(report);
						}
						break;
					}
					default: {
						break;
					}
				}
			});

			return {
				audits,
				configurations,
				waterManagements,
			};
		}

		static get scheduledTypes(): any {
			const reportTypes = [
				{
					id: 1,
					type: RbEnums.Common.ReportType.RunTime,
					title: 'STRINGS.STATION_RUN_TIME_UPPERCASE',
					img: '../../../assets/run_time.svg',
					gradient: 'linear-gradient(-180deg, #638378 0%, #F7FFE3 87%)',
				},
				{
					id: 2,
					type: RbEnums.Common.ReportType.AlarmsAndWarnings,
					title: 'STRINGS.ALARMS_AND_WARNINGS_UPPERCASE',
					img: '../../../assets/alarms.svg',
					gradient: 'linear-gradient(-180deg, #D92727 0%, #DDE05B 57%)',
				},
				{
					id: 3,
					type: RbEnums.Common.ReportType.PINCodeAudit,
					title: 'STRINGS.PIN_CODE_AUDIT_UPPERCASE',
					img: '../../../assets/security.svg',
					gradient: 'linear-gradient(-180deg, #F26609 0%, #FFC050 87%)',
				},
				{
					id: 4,
					type: RbEnums.Common.ReportType.SatelliteDifferences,
					title: 'STRINGS.CONTROLLER_DIFFERENCES_UPPERCASE',
					img: '../../../assets/differences.svg',
					gradient: 'linear-gradient(-180deg, #02474D 0%, #16C2C7 87%)',
				},
				{
					id: 5,
					type: RbEnums.Common.ReportType.DailyControllerIrrigation,
					title: 'STRINGS.DAILY_CONTROLLER_IRRIGATION_UPPERCASE',
					img: '../../../assets/daily-controller-irrigation.svg',
					gradient: 'linear-gradient(0deg, #c2d993 0%, #d4a335 100%)',
				},
			];
			return reportTypes;
		}
	}

}

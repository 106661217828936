import { RbUtils } from '../../common/utils/_rb.utils';

export class ProgramChange {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.changeDateTime
				&& json.changeDateTime instanceof String) { this.changeDateTime = RbUtils.Conversion.convertStringToDate(json.changeDateTime); }
		}
	}

	changeDateTime: Date;
	changeType: string;
	companyId?: number;
	errorMessage?: string = null;
	itemsChanged: any;
	programGroupId: number = null;
	programId?: number;
	reasonCode?: any = null;				// TODO: MW: Change TYPE once we know type

	programName?: string;
	programGroupName?: string;

	/**
	 * When changeType is ProgramsActiveUpdate, this list contains active program group Id and status values
	 * indicating some form of program activity. All other program groups are assumed to be idle.
	 */
	activeProgramGroups: { programGroupId: number, status: string }[];

	/**
	 * When changeType is ProgramsActiveUpdate, this list contains active program (schedule) Id and status values
	 * indicating some form of program activity. All other programs are assumed to be idle.
	 */
	activePrograms: { programId: number, status: string }[];
}

import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-month-picker',
	templateUrl: './month-picker.component.html',
	styleUrls: ['./month-picker.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MonthPickerComponent implements OnChanges {

	@Input() selectedMonth;

	@Output() monthChange: EventEmitter<number> = new EventEmitter();

	monthValues = RbUtils.Months.months;
	selectedMonthString = '';

	constructor() { }

	ngOnChanges() {
		this.selectedMonthString = RbUtils.Months.monthString(this.selectedMonth);
		this.monthChange.emit(this.selectedMonth);
	}

	handleMonthChange(month) {
		this.selectedMonth = month;
		this.selectedMonthString = RbUtils.Months.monthString(month);
		this.monthChange.emit(month);
	}
}

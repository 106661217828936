import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class EventLogEntry {

	constructor(json: any = null) {
		if (!json){
			return;
		}

		Object.assign(this, json);

		if (json.timestamp) {
			this.timestamp = RbUtils.Conversion.convertStringToDate(json.timestamp);
		}

		if (json.lastLogRetrieveTime) {
			this.lastLogRetrieveTime = RbUtils.Conversion.convertStringToDate(json.lastLogRetrieveTime);
		}
	}

	ack: boolean;
	description: string;
	eventNumber: number;
	eventParameter1: number;
	id: number;
	satelliteId: number;
	satelliteName: string;
	siteId: number;
	siteName: string;
	source: string;
	timestamp: Date;
	type: RbEnums.Common.EventLogType;
	weatherSourceId: number;
	lastLogRetrieveTime: any;
	timeZoneShortFormat: string;

	// noinspection JSUnusedGlobalSymbols
	get typeString(): string {
		switch (this.type) {
			case RbEnums.Common.EventLogType.OpenAlarm:
				return RbUtils.Translate.instant('STRINGS.ALARM');
			case RbEnums.Common.EventLogType.OpenWarning:
				return RbUtils.Translate.instant('STRINGS.WARNING');
			case RbEnums.Common.EventLogType.Diagnostic:
				return RbUtils.Translate.instant('STRINGS.DIAGNOSTIC');
			case RbEnums.Common.EventLogType.Information:
				return RbUtils.Translate.instant('STRINGS.INFORMATION');
			case RbEnums.Common.EventLogType.AcknowledgedAlarms:
				return RbUtils.Translate.instant('STRINGS.ACKNOWLEDGEDALARMS');
			case RbEnums.Common.EventLogType.AcknowledgedWarnings:
				return RbUtils.Translate.instant('STRINGS.ACKNOWLEDGEDWARNINGS');
			case RbEnums.Common.EventLogType.AcknowledgedAlarmsAndWarnings:
				return RbUtils.Translate.instant('STRINGS.ACKNOWLEDGED_ALARMS_AND_WARNINGS');
			default:
				return '-';
		}
	}
}

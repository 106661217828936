<mat-form-field class="">
	<mat-select class="multi-controller-select" [panelClass]="matSelectPanelClass" placeholder="{{selectedControllersPlaceholder}}" #matSelect>
			<mat-option class="multi-controller-select-header" disabled>{{selectedControllersPlaceholder | translate}}</mat-option>
		<mat-radio-group [ngModel]="currentlySelectedControllerId">  
		<div class="multi-controller-select-radio-container">
			<mat-tree [dataSource]="treeViewDataSource" [treeControl]="treeControl" class="example-tree">
				<!-- This is the tree node template for leaf nodes -->
				<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
				  <li class="mat-tree-node">
					<!-- use a disabled button to provide padding for tree leaf -->
					<button mat-icon-button disabled></button>
					<mat-radio-button
						[disabled]="node.isDisabled"
						[value]="node.id"
						(change)="onSelectedItemsChanged(node.item, $event)">{{node.item}}</mat-radio-button>
				  </li>
				</mat-tree-node>
				<!-- This is the tree node template for expandable nodes -->
				<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
					<li>
					<div class="mat-tree-node">
						<button mat-icon-button matTreeNodeToggle
								[attr.aria-label]="'toggle ' + node.item">
						<mat-icon class="mat-icon-rtl-mirror">
							{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
						</mat-icon>
						</button>
							<label>{{node.item}}</label>
				
					</div>
					<ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
						<ng-container matTreeNodeOutlet></ng-container>
					</ul>
					</li>
				</mat-nested-tree-node>
			</mat-tree>
		</div>
	</mat-radio-group>
	</mat-select>
</mat-form-field>

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="select-panel">
	<div>
		<mat-form-field *ngIf="isGolf">
			<mat-select [(ngModel)]="selectedCourseId" (selectionChange)="onCourseChanged()">
				<mat-option *ngFor="let course of courses" [value]="course.id" class="pv1" color="primary">{{course.name}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div>
		<rb-select-holes-dropdown 
			#holeDropdown
			*ngIf="holes"
			[isWhiteBackground]="isWhiteBackground"
			[holesAndPlaces]="holes"
			(holesSelected)="onSelectedHolesChange($event)"
		>
		</rb-select-holes-dropdown>
	</div>

	<div>
		<rb-front-back-dropdown
			#areasDropdown
			*ngIf="courseAreas"
			[isWhiteBackground]="isWhiteBackground"
			[label]="'STRINGS.AREAS' | translate"
			[dataArray]="courseAreas"
			[showSelectionCount]="true"
			[isAreas]="true"
			(itemsSelected)="onSelectedAreasChange($event)"
		>
		</rb-front-back-dropdown>
	</div>

	<div *ngIf="showSubAreas && isGolf">
		<rb-front-back-dropdown
			#subAreasDropdown
			*ngIf="subAreas"
			[isWhiteBackground]="isWhiteBackground"
			[label]="'STRINGS.SUB_AREAS' | translate"
			[dataArray]="subAreas"
			[showSelectionCount]="true"
			[isAreas]="true"
			(itemsSelected)="onSelectedSubAreasChange($event)"
		>
		</rb-front-back-dropdown>
	</div>
</div>

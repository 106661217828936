import * as AwsSDK from 'aws-sdk';

import { ApiService } from '../_common/api.service';
import { EnvironmentService } from '../../common/services/environment.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
	providedIn: 'root',
})
export class TokenVendingMachineApi extends ApiService {

	constructor(protected http: HttpClient, protected env: EnvironmentService) {
		super(http, env);
	}

	private get baseUrl(): string {
		return `${this.baseApiUrl}TokenVendingMachine`;
	}

	getAwsCredentials(): Observable<AwsSDK.Credentials> {
		return this.apiRequestWithCache<any>(this.getAwsCredentialsUrl, false, null, 60 * 15).pipe(
			map((val) => {
				const value = val?.value;
				if (!value) {
					console.error('Aws Credential Error:', 'Credential is empty or expired, please check your aws configuration.');
				}
				return new AwsSDK.Credentials({
										accessKeyId: value?.accessKey,
										secretAccessKey: value?.secretKey,
										sessionToken: value?.token
									});
			})
	);
	}

	private get getAwsCredentialsUrl(): string { return `${this.baseUrl}/Credentials`; }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'rb-show-station-notes-cell-renderer',
	templateUrl: './show-station-notes-cell-renderer.component.html',
	styleUrls: ['./show-station-notes-cell-renderer.component.scss']
})
export class ShowStationNotesCellRendererComponent implements ICellRendererAngularComp {

	notesCount = 0
	
	constructor() {
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.setCellData(params);
	}

	refresh(params: any): boolean {
		this.setCellData(params);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setCellData(params: any) {
		this.notesCount = params.data.notesCount;
	}
}

import { Injectable } from '@angular/core';
import { ReportCsvInfo } from './report-csv-info';

@Injectable()
export class ReportCsvService {

	constructor() { }

	createCsvDocument(): ReportCsvInfo {
		const info = new ReportCsvInfo();
		return info;
	}

	save(csvInfo: ReportCsvInfo, filename: string, window: Window) {
		// Adding \uFEFF at the start of csv string, support downloading csv file
		// contains unicode characters such as IQNet™, FloZone™
		const bom = "\uFEFF";
		const csv = bom + csvInfo.text;
		const type = 'text/csv;encoding:utf-8';
		const link = document.createElement('a');

		if (window != null) {
			window.location.href = URL.createObjectURL(new Blob([ csv ], { type }));
			return;
		}

		link.href = URL.createObjectURL(new Blob([csv], { type }));
		if (navigator.msSaveBlob) { // IE10
			navigator.msSaveBlob(new Blob([ csv ], { type }), filename);
			return;
		}

		if (URL && 'download' in link) { // html5 A[download]
			link.setAttribute('download', filename);
		} else {
			link.setAttribute('target', '_blank');
		}
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(link.href);
	}
}




<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="fl">
	<div *ngIf="!isChangingNonCurrentUserPassword" class="fl w-50">
 		<mat-form-field>
			<input
				matInput
				type="password"
				[placeholder]="'STRINGS.PASSWORD' | translate"
				(focus)="$event.target.select()"
				name="Password"
				value="******"
			/>
		</mat-form-field>
	</div>
	<div [ngClass]="{'fl w-50 pa2': !isChangingNonCurrentUserPassword}">
 		<button type="button" id="chg-pw-btn" class="outlined-button" (click)="handleChangePassword()">{{changeButtonText | translate }}</button>
	</div>
</div>
<ng-template #updatePassword>
	<rb-dialog
		[title]="'STRINGS.CHANGE_PASSWORD' | translate"
		[closeButtonLabel]="'STRINGS.CANCEL' | translate "
		[submitButtonLabel]="'STRINGS.OK' | translate"
		[showSubmitButton]="true"
		[closeOnSubmit]="true"
		(onSubmit) = "onSubmit()"
		(onCancel)="onCancel()"
		[saveDisabled]="!editForm?.valid || !editForm?.dirty"
		class="change-password-dialog"
	>
		<form [formGroup]="editForm" class="side-panel-body">
			<fieldset>
				<mat-form-field *ngIf="!isChangingNonCurrentUserPassword" class="pt4">
					<input formControlName="currentPassword" matInput type="password" [placeholder]="'STRINGS.CURRENT_PASSWORD' | translate"
					(focus)="$event.target.select()"/>
				</mat-form-field>
				<div *ngIf="currentPasswordErrorMessage" class="warnColorText">{{currentPasswordErrorMessage | translate}}</div>

				<mat-form-field [ngClass]="{'pt4': isChangingNonCurrentUserPassword}">
					<input formControlName="Password" matInput type="password" [placeholder]="'STRINGS.NEW_PASSWORD' | translate"
						(focus)="$event.target.select()" />
				</mat-form-field>
				<div *ngIf="PasswordErrorMessage && (isCloud ? (getActivedFormControlName() !== 'Password') : true)" class="warnColorText">{{PasswordErrorMessage | translate}}</div>
				<div *ngIf="isCloud && getActivedFormControlName() === 'Password'">
					<div *ngFor="let passwordValidationMessage of PasswordValidationMessages" class="rb-dialog-password-validation">
						<div class="warnColorText helperErrorColorText"
							[ngClass]="{'helperSuccessColorText': passwordValidationMessage.valid}">
							<mat-icon class="validation-icon">{{passwordValidationMessage.valid?'check_circle':'error'}}</mat-icon>
							{{passwordValidationMessage.message}}
						</div>
					</div>
				</div>
				<mat-form-field>
					<input formControlName="confirmPassword" matInput type="password" [placeholder]="'STRINGS.CONFIRM_PASSWORD' | translate"
					(focus)="$event.target.select()" />
				</mat-form-field>
				<div *ngIf="confirmPasswordErrorMessage" class="warnColorText">{{confirmPasswordErrorMessage | translate}}</div>
			</fieldset>
		</form>
	</rb-dialog>
</ng-template>

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SiteTreeView } from '../../../../../api/sites/models/site-tree-view.model';
import { TranslateService } from '@ngx-translate/core';
import { TreeViewFlatNode } from '../../../../../api/sites/models/tree-view-flat-node.model';

@Component({
	selector: 'rb-tree-view-radiobutton',
	templateUrl: './tree-view-radiobutton.component.html',
	styleUrls: ['./tree-view-radiobutton.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TreeViewRadioButtonComponent implements OnChanges, OnInit {
	@ViewChild('matSelect', { static: true }) matSelect: MatSelect;

	@Input() requestTreeLevel: number;
	@Input() treeViewItems: SiteTreeView[] = [];
	@Input() selectedControllerIds: number[] = [];
	@Input() isClosedAfterSelect = false;
	@Input() isItemNameDisplayed = false;
	@Input() isExpandedDefaultItem = false;
	@Output() valueChange = new EventEmitter();

	data = [];
	dataChange: BehaviorSubject<SiteTreeView[]> = new BehaviorSubject<SiteTreeView[]>([]);
	treeViewDataSource = new MatTreeNestedDataSource<SiteTreeView>();
	nestedNodeMap = new Map<SiteTreeView, TreeViewFlatNode>();  // Map from nested node to flattened node. This helps us to keep the same object for selection
	// selectedParent: TreeViewFlatNode | null = null; // A selected parent node to be inserted
	treeControl = new NestedTreeControl<SiteTreeView>(node => node.children);
	selectedControllersPlaceholder;
	currentlySelectedControllerId;

	private _matSelectPanelClass: string[] = ['multi-controller-select-panel'];

	constructor(private translateService: TranslateService, private cdr: ChangeDetectorRef) { }

	ngOnInit() {
		this.selectedControllersPlaceholder = this.selectedControllersPlaceholder || this.translateService.instant('STRINGS.CONTROLLERS_SELECTED');
		this.treeViewDataSource.data = this.treeViewItems;
	}

	@Input() set matSelectPanelClass(value: string | string[]) {
		if (Array.isArray(value)) {
			this._matSelectPanelClass = [...this._matSelectPanelClass, ...value];
		} else {
			this._matSelectPanelClass = [...this._matSelectPanelClass, value];
		}
	}
	
	get matSelectPanelClass(): string {
		return this._matSelectPanelClass.join(' ');
	}

	hasChild = (_: number, node: SiteTreeView) => node.level === 0 ;

	ngOnChanges(changes: SimpleChanges) {
		this.data = this.treeViewItems;
		// Notify the change.
		this.dataChange.next(this.data);
		if (this.data !== undefined) {
			this.treeViewDataSource.data = this.data;
		}

		this.currentlySelectedControllerId = this.selectedControllerIds == null || this.selectedControllerIds.length < 1
			? 0
			: this.selectedControllerIds[0];

		if (changes.treeViewItems?.currentValue && this.isItemNameDisplayed) {
			this.updateSelectedItem(changes.treeViewItems.currentValue);
		}

		// RB-14646: IQ4 - Landing page preference bug
		// We have to show up "Controller list" default item or controller has been selected in "Landing page"
		// This indicator isExpandedDefaultItem is just working properly when it set true in this custom control
		if (this.isExpandedDefaultItem) {
			this.expandedControllersDefault();
		}
	}

	expandedControllersDefault() {
		if (this.treeViewDataSource.data && this.treeControl) {
			if (this.currentlySelectedControllerId === 0) {
				const defaultValue = this.treeViewDataSource.data[0];
				const isExpanded = this.treeControl.isExpanded(this.treeViewDataSource.data[this.currentlySelectedControllerId]);
				if (!isExpanded && defaultValue) {
					this.treeControl.expand(defaultValue);
				}
			} else {
				this.treeViewDataSource.data.forEach(site => {
					if (site.id && site.children) {
						const defaultValue = site.children.find(x => x.id === this.currentlySelectedControllerId);
						if (defaultValue) {
							const isExpanded = this.treeControl.isExpanded(site);
							if (!isExpanded && defaultValue) {
								this.treeControl.expand(site);
							}
						}
					}
				});
			}
		}
	}

	updateSelectedItem(siteTreeView: SiteTreeView[] = []) {
		siteTreeView.find(treeView => {
			if (treeView.id === this.currentlySelectedControllerId) {
				this.selectedControllersPlaceholder = treeView.item;
				return true;
			} else if (treeView.children?.length > 0) {
				return treeView.children.find(child => {
					if (child.id === this.currentlySelectedControllerId) {
						this.selectedControllersPlaceholder = child.item;
						return true;
					}
					return false;
				});
			}
			return false;
		});
		this.cdr.detectChanges();
	}

	/** Emits the selected nodes on node selection change */
	onSelectedItemsChanged(label: string, node) {
		this.emitSelectionChange(label, node.value);
	}

	/** Notify callers of our selection(s). */
	private emitSelectionChange(label: string, id) {
		this.valueChange.emit([id]);
		this.selectedControllersPlaceholder = this.isItemNameDisplayed ? label :`1 ${this.translateService.instant('STRINGS.CONTROLLER_SELECTED')}`;
		if (this.isClosedAfterSelect) {
			this.matSelect.close();
		}
	}

}

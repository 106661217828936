import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { QuickIrrArea } from '../../../../../sections/program-groups/quick-programming-dialog/models/quick-irr-area.model';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { TableWrapperComponent } from '../../table-wrapper/table-wrapper.component';

@Component({
	selector: 'rb-quick-irr-area-mobile-cell-renderer-component',
	templateUrl: './quick-irr-area-mobile-cell-renderer-component.html',
	styleUrls: ['./quick-irr-area-mobile-cell-renderer-component.scss']
})
export class QuickIrrAreaMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	RbUtils = RbUtils;

	area: QuickIrrArea;
	runTimeInputType: RbEnums.Common.RunTimeGolfInputType;
	RbEnums = RbEnums;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.area = params.data;
		this.runTimeInputType = params.runTimeInputType;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onSetRuntime(event, area) {
		const tableWrapper = this.params.context.componentParent as TableWrapperComponent;
		if (tableWrapper.onMobileCommandRequested == null) return;

		event.stopPropagation();
		tableWrapper.onMobileCommandRequested.emit({ command: 'setRuntime', data: { area: area, domEvent: event } });
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get inputValue(): string {
		if (this.runTimeInputType === RbEnums.Common.RunTimeGolfInputType.Volume || this.runTimeInputType === RbEnums.Common.RunTimeGolfInputType.Passes) {
			return this.area.inputValue == null || this.area.inputValue === 0
				? RbUtils.Translate.instant('STRINGS.DEFAULT')
				: this.area.inputValue;
		}

	}

	get runtime(): string {
		return this.area.inputValue.asMilliseconds() === 0
			? RbUtils.Translate.instant('STRINGS.DEFAULT')
			: RbUtils.Conversion.convertDurationToTimeSpanString(this.area.inputValue);
	}
}

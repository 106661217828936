import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class ProgramStep {

	actionId: RbEnums.Common.ProgramStepActionType;
	companyId: number;
	id: number;
	programId: number;
	runTime: string;
	runTimeEtlong: number;
	runTimeFinalLong: number;
	runTimeLong: number;
	sequenceNumber: number;
	stationId: number;
	useRunTimeCalc = false;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}
}

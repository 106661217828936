/*
 * NOTE: DO NOT USE THESE CONSTANTS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbConstants Namespace.
 * 		 E.g., RbConstants.MapIcons.DEFAULT_ICON_URL
 * 		 See: _rb.constants.ts
 */

export namespace XXUseRbConstantsNamespace {

	export abstract class MapIcons {

		static readonly DEFAULT_ICON_URL = 'leaflet/marker-icon.png';
		static readonly DEFAULT_ICON_SHADOW_URL = 'leaflet/marker-shadow.png';
		static readonly ALERT_ICON_COLOR = '#ff0000';
		static readonly NON_ALERT_ICON_COLOR = '#228B22';
		static readonly OFFLINE_ICON_COLOR = '#A9A9A9';
		static readonly DRAW_CIRCLE_COLOR = '#AAAAAA';
		static readonly CONTROLLER_ICON_RADIUS = 6;
		static readonly STATION_ICON_RADIUS = 8;
		static readonly stationMarkerInfo = { width: 12, height: 12 };
		static readonly controllerSyncingMarkerInfo = { width: 12, height: 12 };
		static readonly controllerReverseSyncingMarkerInfo = { width: 12, height: 12 };
		static readonly controllerGettingLogsMarkerInfo = { width: 12, height: 12 };

		static readonly ICON_OBJECT: any = {
			iconSize: [ 25, 41 ],
			iconAnchor: [ 13, 41 ],
			iconUrl: MapIcons.DEFAULT_ICON_URL,
			shadowUrl: MapIcons.DEFAULT_ICON_SHADOW_URL,
		};

		static readonly SITE_ICON_OBJECT: any = {
			iconSize: [ 25, 41 ],
			iconAnchor: [ 13, 41 ],
			iconUrl: MapIcons.DEFAULT_ICON_URL,
			shadowUrl: MapIcons.DEFAULT_ICON_SHADOW_URL,
		};

		static readonly STATION_DEFAULT_SVG = [
			'<?xml version="1.0"?>',
			'<svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
			'<circle id="my-circle" stroke="black" stroke-width="1.5" fill="yellow" fill-opacity="0.8" cx="8" cy="8" r="7"></circle>',
			'</svg>'
		].join('\n');

		/**
		 * The default station shape is a circle with a filled circle in the middle, both black.
		 */
		static readonly STATION_IDLE_SVG = [
			'<?xml version="1.0"?>',
			'<svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
			'<circle stroke="black" stroke-width="1.5" fill-opacity="0" cx="8" cy="8" r="7"/>',
			'<circle stroke="black" stroke-width="1.5" fill-opacity="0" cx="8" cy="8" r="3"/>',
			'</svg>'
		].join('\n');

		/**
		 * The suspended station shape is a orange circle with a diagonal line.
		 */
		static readonly STATION_SUSPENDED_SVG = [
			`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">`,
			// eslint-disable-next-line max-len
			`<path d="M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM20 12C20 16.42 16.42 20 12 20C10.15 20 8.45 19.37` +
			` 7.1 18.31L18.31 7.1C19.37 8.45 20 10.15 20 12ZM16.9 5.69L5.69 16.9C4.63 15.55 4 13.85 4 12C4` +
			` 7.58 7.58 4 12 4C13.85 4 15.55 4.63 16.9 5.69Z" stroke-width="2" fill="#F09212"/>`,
			`</svg>`
		].join('\n');

		static readonly STATION_PENDING_SVG = [
			'<?xml version="1.0"?>',
			'<svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
			'<circle id="my-circle" stroke="black" stroke-width="1.5" fill="light-blue" fill-opacity="0.8" cx="8" cy="8" r="7"></circle>',
			'</svg>'
		].join('\n');

		static readonly STATION_RUNNING_SVG = [
			'<?xml version="1.0"?>',
			'<svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
			'<circle id="my-circle" stroke="black" fill="#1976d2" cx="8" cy="8" r="7">',
			'</circle>',
			'</svg>'
		].join('\n');

		// static readonly STATION_RUNNING_SVG = [
		// 	'<?xml version="1.0"?>',
		// 	'<svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
		// 	'<circle id="my-circle" stroke="black" fill="#1976d2" cx="8" cy="8" r="7">',
		// 	'<animate attributeName="r" begin="0s" dur="2s" values="7;2" keyTimes="0;1" repeatCount="indefinite">' +
		// 	'</animate>',
		// 	'</circle>',
		// 	'</svg>'
		// ].join('\n');

		static readonly STATION_PAUSED_SVG = [
			'<?xml version="1.0"?>',
			'<svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
			'<circle id="my-circle" stroke="black" stroke-width="1.5" fill="yellow" fill-opacity="0.8" cx="8" cy="8" r="7"></circle>',
			'<line x1="6" y1="4" x2="6" y2="12" stroke="black" stroke-width="2" />',
			'<line x1="10" y1="4" x2="10" y2="12" stroke="black" stroke-width="2" />',
			'</svg>'
		].join('\n');

		static readonly STATION_SOAKING_SVG = [
			'<?xml version="1.0"?>',
			'<svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
			'<circle id="my-circle" stroke="black" stroke-width="1.5" fill="cyan" fill-opacity="0.8" cx="8" cy="8" r="7"></circle>',
			'</svg>'
		].join('\n');

		static readonly STATION_ERROR_SVG = [
			'<?xml version="1.0"?>',
			`<svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">`,
			`<circle cx="10" cy="10" r="10" fill="#BD2622"/>`,
			`<path d="M14.0365 10.6251L9.87751 6.5622C9.54591 6.23826 9.38011 6.07629 9.17486 6.00269C9.00337 5.94119 ` +
			`8.78854 5.9287 8.61108 5.96991C8.39868 6.01924 8.23221 6.14779 7.89927 6.4049C6.61032 7.40024 5.77896 8.47938 ` +
			`5.82968 10.1754C5.90441 12.6738 7.90426 14.6275 10.4784 14.7166C12.0952 14.7726 13.1204 13.9193 14.1031 12.8693C14.4753 ` +
			// eslint-disable-next-line max-len
			`12.4716 14.6614 12.2728 14.7285 12.0458C14.7874 11.8466 14.7826 11.6324 14.7148 11.4361C14.6376 11.2123 14.4373 11.0166 14.0365 10.6251Z" fill="white"/>`,
			`<path d="M10.546 8.72386L9.48532 7.66318L11.932 5.21669C12.2249 4.92384 12.6997 4.92386 12.9926 5.21673C13.2854 5.5096 13.2854 5.98441 12.9925 ` +
			`6.27727L10.546 8.72386Z" fill="white"/>`,
			`<path d="M12.8395 10.9587L11.7789 9.89808L14.2256 7.45159C14.5184 7.15874 14.9933 7.15875 15.2861 7.45162C15.579 ` +
			`7.74449 15.579 8.21931 15.2861 8.51217L12.8395 10.9587Z" fill="white"/>`,
			`<rect x="6.65683" y="13.3201" width="1" height="2" rx="0.5" transform="rotate(45 6.65683 13.3201)" fill="white"/>`,
			`<path d="M5.28098 5.57391L15.0004 15.5005" stroke="white" stroke-linecap="square"/>`,
			`<line x1="4.35355" y1="5.64645" x2="14.9602" y2="16.253" stroke="#BD2622"/>`,
			`</svg>`
		].join('\n');

		static readonly STATION_NO_FEEDBACK_SVG = [
			'<?xml version="1.0"?>',
			'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"',
			'fill="none" stroke="goldenrod" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">',
			'<circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>',
			'<line x1="12" y1="17" x2="12.01" y2="17"></line>',
			'</svg>'
		].join('\n');

		static readonly CONTROLLER_SYNCING_SVG = [
			'<?xml version="1.0"?>',
			'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="170" height="200" viewBox="0 0 170 200"',
			' style="enable-background:new 0 0 170 200;" xml:space="preserve">',
			'<defs>',
			'<path id="glyph"',
			' d="M134.600000,60.800000',
			' L121.000000,74.600000',
			' C125.133354,82.866708,127.200000,91.333290,127.200000,100.000000',
			' C127.200000,109.866716,124.766691,118.966625,119.900000,127.300000',
			' C115.033309,135.633375,108.466708,142.266642,100.200000,147.200000',
			' C91.799958,152.133358,82.666716,154.600000,72.800000,154.600000',
			' L72.800000,127.200000',
			' L36.400000,163.600000',
			' L72.800000,200.000000',
			' L72.800000,172.800000',
			' C85.866732,172.800000,98.066610,169.466700,109.400000,162.800000',
			' C120.333388,156.399968,128.999968,147.666722,135.400000,136.600000',
			' C142.066700,125.266610,145.400000,113.066732,145.400000,100.000000',
			' C145.400000,93.066632,144.400010,86.200034,142.400000,79.400000',
			' C140.666658,73.533304,138.066684,67.333366,134.600000,60.800000',
			' Z',
			' M72.800000,45.400000',
			' L72.800000,72.800000',
			' L109.200000,36.400000',
			' L72.800000,0.000000',
			' L72.800000,27.200000',
			' C59.733268,27.200000,47.533390,30.533300,36.200000,37.200000',
			' C25.133278,43.600032,16.400032,52.333278,10.000000,63.400000',
			' C3.333300,74.733390,0.000000,86.933268,0.000000,100.000000',
			' C0.000000,106.800034,1.066656,113.666632,3.200000,120.600000',
			' C4.933342,126.466696,7.533316,132.599968,11.000000,139.000000',
			' L24.600000,125.400000',
			' C20.333312,118.733300,18.200000,110.266718,18.200000,100.000000',
			' C18.200000,90.133284,20.666642,81.000042,25.600000,72.600000',
			' C30.533358,64.333292,37.133292,57.733358,45.400000,52.800000',
			' C53.800042,47.866642,62.933284,45.400000,72.800000,45.400000',
			' Z',
			' M170.000000,170.000000">',
			'<animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 80 98" to="360 65 98" dur="2.0s"',
			' additive="sum" repeatCount="indefinite" />',
			'</path>',
			'</defs>',
			'<use xlink:href="#glyph" fill="#f1ac3b" />',
			'</svg>',
		].join('\n');

		static readonly CONTROLLER_REVERSE_SYNCING_SVG = [
			'<?xml version="1.0"?>',
			'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="156" height="200" viewBox="0 0 156 200"',
			' style="enable-background:new 0 0 156 200;" xml:space="preserve">',
			'<defs>',
			'<path id="glyph"',
			' d="M100.000000,77.800000',
			' L100.000000,155.600000',
			' L66.600000,155.600000',
			' L111.200000,200.000000',
			' L155.600000,155.600000',
			' L122.200000,155.600000',
			' L122.200000,77.800000',
			' Z',
			' M44.400000,0.000000',
			' L0.000000,44.400000',
			' L33.400000,44.400000',
			' L33.400000,122.200000',
			' L55.600000,122.200000',
			' L55.600000,44.400000',
			' L89.000000,44.400000',
			' Z',
			' M180.000000,170.000000">',
			'<animateTransform id="squeeze" attributeName="transform" type="scale" from="1 1" to="0.1 1" begin="0s; stretch.end" dur="1s"/>',
			'<animateTransform id="stretch" attributeName="transform" type="scale" from="0.1 1" to="1 1" begin="squeeze.end" dur="1s" />',
			'</path>',
			'</defs>',
			'<use xlink:href="#glyph"',
			' fill="#f1ac3b" />',
			'</svg>',
		].join('\n');

		static readonly CONTROLLER_LOGS_SVG = [
			'<?xml version="1.0"?>',
			'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200" viewBox="0 0 200 200"',
			' style="enable-background:new 0 0 200 200;" xml:space="preserve">',
			'<defs>',
			'<path id="glyph"',
			' d="M22.200000,100.000000',
			' L22.200000,177.800000',
			' L177.800000,177.800000',
			' L177.800000,100.000000',
			' L200.000000,100.000000',
			' L200.000000,177.800000',
			' C200.000000,181.800020,199.000010,185.499983,197.000000,188.900000',
			' C194.999990,192.300017,192.300017,194.999990,188.900000,197.000000',
			' C185.499983,199.000010,181.800020,200.000000,177.800000,200.000000',
			' L22.200000,200.000000',
			' C18.199980,200.000000,14.500017,199.000010,11.100000,197.000000',
			' C7.699983,194.999990,5.000010,192.300017,3.000000,188.900000',
			' C0.999990,185.499983,0.000000,181.800020,0.000000,177.800000',
			' L0.000000,100.000000',
			' Z',
			' M111.200000,107.400000',
			' L139.800000,78.800000',
			' L155.600000,94.400000',
			' L100.000000,150.000000',
			' L44.400000,94.400000',
			' L60.200000,78.800000',
			' L88.800000,107.400000',
			' L88.800000,0.000000',
			' L111.200000,0.000000',
			' Z',
			' M200.000000,170.000000">',
			'<animateTransform id="squeeze" attributeName="transform" type="scale" from="1 1" to="0.1 1" begin="0s; stretch.end" dur="1s"/>',
			'<animateTransform id="stretch" attributeName="transform" type="scale" from="0.1 1" to="1 1" begin="squeeze.end" dur="1s" />',
			'</path>',
			'</defs>',
			'<use xlink:href="#glyph" fill="#FFFFFF" />',
			'</svg>',
		].join('\n');
	}
}

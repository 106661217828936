import {
	Component,
	EventEmitter,
	Input, OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { Area } from '../../../api/areas/models/area.model';
import { FrontBackDropdownComponent } from '../../../core/components/front-back-dropdown/front-back-dropdown.component';
import { Site } from '../../../api/sites/models/site.model';
import { Subarea } from '../../../api/areas/models/subarea.model';

@Component({
	selector: 'rb-select-panel',
	templateUrl: './select-panel.component.html',
	styleUrls: ['./select-panel.component.scss'],
})
export class SelectPanelComponent implements OnInit {

	@ViewChild('holeDropdown') holeDropdown: FrontBackDropdownComponent;
	@ViewChild('areasDropdown') areasDropdown: FrontBackDropdownComponent;
	@ViewChild('subAreasDropdown') subAreasDropdown: FrontBackDropdownComponent;

	@Input() areas: Area[] = [];
	@Input() isGolf = false;
	@Input() courses: Site[] = [];
	@Input() isWhiteBackground = false;

	@Input() showSubAreas = false;

	@Output() courseSelected = new EventEmitter<number>();
	@Output() holesSelected = new EventEmitter<Area[]>();
	@Output() areasSelected = new EventEmitter<Area[]>();
	@Output() subAreasSelected = new EventEmitter<Subarea[]>();

	holes: Area[] = [];
	courseAreas: Area[] = [];
	subAreas: Subarea[] = [];
	selectedCourseId: number;

	ngOnInit(): void {
		this.selectedCourseId = this.courses[0].id;
		setTimeout(() => this.onCourseChanged(), 100);
	}

	onSelectedHolesChange(selectedHoles: Area[]) {
		this.holesSelected.emit(selectedHoles);
	}

	onSelectedAreasChange(selectedAreas: Area[]) {
		this.areasSelected.emit(selectedAreas);

		if(this.isGolf && this.showSubAreas){
			if(selectedAreas.length === 0) {			
				this.filterSubAreas(this.courseAreas);
			} else {
				let currentAreas = [];
				for(let i = 0; i < selectedAreas.length; i++) {
					currentAreas.push(this.courseAreas.find(area => area.id === selectedAreas[i].id));
				}
				this.filterSubAreas(currentAreas);
			}
		}
	}

	onSelectedSubAreasChange(selectedSubAreas: Subarea[]) {
		this.subAreasSelected.emit(selectedSubAreas);
	}

	onCourseChanged() {
		this.courseSelected.emit(this.selectedCourseId);
		this.holes = this.areas.filter(a => a.siteId === this.selectedCourseId && a.level === 2);
		this.courseAreas = this.areas.filter(area => area.siteId === this.selectedCourseId && area.level === 3).sort((a, b) => a.number - b.number);
		
		if(this.isGolf && this.showSubAreas){
			this.filterSubAreas(this.courseAreas);
		}

		if (this.holeDropdown != null) this.holeDropdown.deselectAll();
		if (this.areasDropdown != null) this.areasDropdown.deselectAll();
		if (this.subAreasDropdown != null) this.subAreasDropdown.deselectAll();

		this.onSelectedHolesChange([]);
		this.onSelectedAreasChange([]);
		this.onSelectedSubAreasChange([]);
	}

	private filterSubAreas(arrayAreas: Area[]){
		this.subAreas = [];
		for(let i = 0; i < arrayAreas.length; i++) {
			this.subAreas = this.subAreas.concat(arrayAreas[i].subArea);
		}
		this.subAreas.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
	}
}

import { Component, HostBinding, Injector } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ControllerListItem } from '../../../../../api/controllers/models/controller-list-item.model';
import { FirmwareUpdateProgress } from '../../../../../api/signalR/firmware-update-progress.model';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-controller-mobile-cell-renderer',
	templateUrl: 'controller-mobile-cell-renderer.component.html',
	styleUrls: [ 'controller-mobile-cell-renderer.component.scss' ]
})
export class ControllerMobileCellRendererComponent extends BaseMobileCellRendererComponent {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	controller: ControllerListItem;
	firmwareUpdateProgress: FirmwareUpdateProgress = null;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(injector: Injector) {
		super(injector);
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		super.agInit(params);
		this.setRendererData(params);
	}

	refresh(params: any): boolean {
		this.setRendererData(params);
		return true;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getControllerStatusCellRenderer() {
		return RbUtils.GridCellStyles.getControllerStatusCell(this.controller, this.isGolfSite);
	}

	// TODO - MW: Implement proper status rendering.
	getInterfaceStatusCellRenderer(): string {
		return '-';
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setRendererData(params: any) {
		this.controller = params.data;
		this.firmwareUpdateProgress = this.controller.firmwareUpdateProgress;
	}
}

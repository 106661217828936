import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
	MatBottomSheet,
	MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { CustomModal } from './models/custom-modal.interface';
import { DeviceManagerService } from '../../../common/services/device-manager.service';

@Component({
	selector: 'rb-custom-modal',
	templateUrl: './custom-modal.component.html',
	styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent implements OnInit, CustomModal {
	@ViewChild('main_modal_template', { static: true }) mainModalTemplate;

	@Input() useMobileModalStyle: boolean = false;
	@Input() overriedModalStyle: string;
	@Input() disabledCloseFromOutside: boolean = false;
	matDialogRef: MatDialogRef<any>;
	bottomSheetRef: MatBottomSheetRef<any>;
	constructor(
		private matDialog: MatDialog,
		private bottomSheet: MatBottomSheet,
		private deviceManager: DeviceManagerService
	) {}

	openModal() {
		if (this.useMobileModalStyle && this.deviceManager.isMobile) {
			this.bottomSheetRef = this.bottomSheet.open(this.mainModalTemplate, {
				panelClass: ["rb-custom-bottom-sheet"],
				disableClose: this.disabledCloseFromOutside
			});
		}  else {
			this.matDialogRef = this.matDialog.open(this.mainModalTemplate, {
				panelClass: ["rb-custom-modal"],
				disableClose: this.disabledCloseFromOutside
			});
		}
	}

	closeModal() {
		if(this.matDialogRef) {
			this.matDialogRef.close();
		}
		if (this.bottomSheetRef) {
			this.bottomSheetRef.dismiss();
		}
	}

	ngOnInit() {}
}

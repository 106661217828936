import * as moment from 'moment';

import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { GroupStartTime } from '../../../api/programs/models/group-start-time.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StartTime } from '../../../api/programs/models/start-time.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-edit-start-time',
	templateUrl: './edit-start-time.component.html',
	styleUrls: [ './edit-start-time.component.scss' ]
})
export class EditStartTimeComponent implements OnInit {
	@Input() public index: number;
	@Input() public startTimeFormGroup: FormGroup;
	@Input() public startTimesListFormGroup: FormGroup[];
	@Input() public disabled = false;
	@Input() public showDelayOption = false;
	@Input() public isDelayed = false;
	@Input() public warn = false;
	@Input() public hideDeleteBtn = false;
	@Input() public shouldHideDelete = false;
	@Input() public isProgramTool = false;

	@Output() public addStartTime = new EventEmitter();
	@Output() public removed = new EventEmitter();
	@Output() public updated = new EventEmitter<string>();
	@Output() dialogDisplayed = new EventEmitter<boolean>();

	timePickerDisplay: string;
	cultureSettings: CultureSettings;
	format: number;
	isReadOnly = false;

	static buildItem(startTime: StartTime) {
		return new FormGroup({
			id: new FormControl(startTime.id),
			enabled: new FormControl(startTime.enabled),
			dateTime: new FormControl(startTime.dateTime),
			isProgramGroup: new FormControl(startTime.isProgramGroup)
		});
	}

	static buildGroupItem(startTime: GroupStartTime) {
		return new FormGroup({
			id: new FormControl(startTime.id),
			enabled: new FormControl(startTime.enabled),
			dateTime: new FormControl(startTime.dateTime),
			isProgramGroup: new FormControl(false) // so it is editable
		});
	}

	constructor(private elRef: ElementRef,
				private authManager: AuthManagerService,
				public translate: TranslateService) {}

	ngOnInit() {
		this.isReadOnly = this.authManager.isReadOnly || this.disabled;
		this.getTimePickerData();
	}

	get enabled(): Date { return this.startTimeFormGroup.get('enabled').value; }
	get startTime(): Date { return this.startTimeFormGroup.get('dateTime').value; }
	get isProgramGroup(): boolean { return this.startTimeFormGroup.get('isProgramGroup').value; }

	onAddStartTime() {
		this.addStartTime.emit();
	}

	onDeleteStartTime() {
		this.removed.emit();
	}

	getTimePickerData() {
		this.cultureSettings = this.authManager.userCulture;
		this.timePickerDisplay = RbUtils.Common.getTimePickerDisplayValue(this.cultureSettings);
		this.format = RbUtils.Common.getTimePickerFormat(this.cultureSettings);
	}

	onStartTimeUpdate(timeString: string) {
		const startDate = RbUtils.Common.getCultureFormat(timeString, this.cultureSettings);
		const control = this.startTimeFormGroup.get('dateTime');
		const isSameTime = this.startTimesListFormGroup && this.startTimesListFormGroup.some((st) =>
			this.cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm
				? RbUtils.Common.isSameTime(startDate as Date, moment(st.value["dateTime"]).toDate())
				: RbUtils.Common.isSameTime((startDate as any).toDate(), moment(st.value["dateTime"]).toDate())
		);
		if (!isSameTime) {
			control.setValue(startDate);
			control.markAsDirty();
			this.updated.emit();
		}
	}

	onEnableToggled() {
		const control = this.startTimeFormGroup.get('enabled');
		control.setValue(!control.value);
		control.markAsDirty();
		this.updated.emit();
		this.onStartTimeClosed();
	}

	onStartTimeOpened() {
		this.dialogDisplayed.emit(true);
	}

	onStartTimeClosed() {
		this.dialogDisplayed.emit(false);
		setTimeout(() => this.elRef.nativeElement.ownerDocument.activeElement['blur'](), 100);
	}
}

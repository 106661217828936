import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

/**
 * This component will be used to show custom tooltip
 * 
 * This component will be rendered using OverlayModule of angular material
 * This component will be rendered by directive on an Overlay
 * 
 * CONSUMER will not be using this component directly
 * This component will be hosted in an overlay by TooltipRenderer directive
 * This component exposes two properties. These two properties will be filled by TooltipRenderer directive
 * 1. text - This is a simple string which is to be shown in the tooltip; This will be injected in the TooltipRenderer directive
 *    by the consumer
 * 2. contentTemplate - This gives finer control on the content to be shwon in the tooltip
 * 
 * NOTE - ONLY one should be specified; If BOTH are specified then "template" will be rendered and "text" will be ignored
 */
@Component({
	selector: 'rb-custom-tooltip',
	templateUrl: './custom-tooltip.component.html',
	styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements OnInit, OnDestroy {
	/**
     * This is simple text which is to be shown in the tooltip
     */
	@Input() text: string;

	/**
	 * This provides finer control on the content to be visible on the tooltip
	 * This template will be injected in CustomTooltipRenderer directive in the consumer template
	 * <ng-template #template>
	 *  content.....
	 * </ng-template>
	 */
	@Input() contentTemplate: TemplateRef<any>;
  
	constructor() { }
  
	ngOnInit() { }

	ngOnDestroy(): void { }
}

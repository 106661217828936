import * as moment from 'moment';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationArea } from '../../station-areas/station-area.model';
import { StationSensor } from '../../station-sensors/station-sensor.model';

export class Station {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.autoResumeDate) { this.autoResumeDate = RbUtils.Conversion.convertStringToDate(json.autoResumeDate); }
			if (json.stationArea) { this.stationArea = json.stationArea.map(item => new StationArea(item)); }
			if (json.stationSensor) { this.stationSensor = json.stationSensor.map(ss => new StationSensor(ss)); }
			if (json.tempAdjustStart) { this.tempAdjustStart = RbUtils.Conversion.convertStringToDate(json.tempAdjustStart); }
			if (json.tempAdjustStop) { this.tempAdjustStop = RbUtils.Conversion.convertStringToDate(json.tempAdjustStop); }
		}
	}

	addressInt: number;
	adjustableArc: boolean;
	aprilAdjFactor: number;
	arc: number;
	augustAdjFactor: number;
	autoResumeDate: Date;
	borrowState: RbEnums.Common.BorrowState;
	borrowedMvid: number;
	channel: number;
	companyId: number;
	connectState: number;
	cycleTimeCalcLong: number;
	cycleTimeLong: number;
	decemberAdjFactor: number;
	decoderModel: RbEnums.Common.DecoderModelType;
	defaultRunTimeLong: number;
	description = '';
	notes = '';
	distUniformityFinal: number;
	fastConnectStationNumber: number;
	februaryAdjFactor: number;
	flowMonitoringId: number;
	flowRate: number;
	flowRateLearned = false;
	flowZoneId: number;
	groupNumber: number;
	headFlowRate: number;
	headRadius: number;
	headSpacing: RbEnums.Common.HeadSpacingType;
	id: number;
	januaryAdjFactor: number;
	julyAdjFactor: number;
	juneAdjFactor: number;
	latitude: number;
	longitude: number;
	lockout: number;
	marchAdjFactor: number;
	master = false;
	maxicomColorCode: number;
	mayAdjFactor: number;
	monthlyCyclingEnabled = false;
	name: string;
	nonIrr = false;
	novemberAdjFactor: number;
	nozzleId: number;
	nozzleType: string;
	octoberAdjFactor: number;
	overridePause = false;
	parentSatelliteType: RbEnums.Common.DeviceType;
	precRateFinal: number;
	pressure: number;
	priority: RbEnums.Common.Priority;
	rowRadius: number;
	satelliteId: number;
	sensor = false;
	septemberAdjFactor: number;
	slope: number;
	soakTimeCalcLong: number;
	soakTimeLong: number;
	sprinklerCategory: RbEnums.Common.SprinklerCategoryType;
	stationArea: StationArea[] = [];
	stationSensor: StationSensor[] = [];
	tempAdjustStart: Date;
	tempAdjustStop: Date;
	tempStationAdjust: number;
	tempAdjustDays: number;
	terminal: number;
	type: number;
	useCycleSoakTimeCalc: boolean;
	useLocalMv = false;
	useMonthlyAdjFactor: boolean;
	useMv = false;
	usePrecRateCalc: boolean;
	valveType: number;
	yearlyAdjFactor: number;
	subAreaId: number;
	weatherSourceId: number;
	etAdjustFactor: number;
	suspended: boolean;
	voltage: number;
	feedback: number;
	pumpId: number;
	cropCoefficient: number;
	headPerStation: number;
	customRotation: number;
	rotationTime: number;
	hasLocation: boolean;
	lastManualRunStartTime?: Date;
	lastRunTimeSeconds?: number;

	// NOTE: This value is currently set external to this class. The api does not populate this field.
	addressString = '';

	get priorityString(): string {
		const priorityString = {
			0: 'NonIrrigation',
			1: 'High',
			2: 'Medium',
			3: 'Low',
		};

		return priorityString[this.priority];
	}

	get isConnected(): boolean {
		return RbUtils.Stations.isStationConnected(this);
	}

	get defaultRuntime(): string {
		return moment.utc(RbUtils.Conversion.convertTicksToMSRound(this.defaultRunTimeLong)).format('HH:mm:ss');
	}
	get defaultRuntimeDuration() {
		return  RbUtils.Conversion.convertTicksToDuration(this.defaultRunTimeLong);
	}

	// Gets the string to identify the Location, ie:  1G1, or 2F11
	get locationString(): string {

		const holeSA = this.stationArea.find(s => s.area != null && s.area.level === 2 && s.area.isExclusive);
		const hole = holeSA == null ? null : holeSA.area;
		const stationArea = this.stationArea.find(s => s.area != null && s.area.level === 3 && s.area.isExclusive);

		return hole == null || stationArea == null ? '-' : hole.number + stationArea.area.shortName + stationArea.number;
	}

	get isSprinklerDetailSetUp(): boolean {
		return this.sprinklerCategory === 0 || !!this.sprinklerCategory;
	}

	static createDefault(): Station {
		const station = new Station();
		station.id = 0;
		station.satelliteId = 0;
		station.name = '';
		station.defaultRunTimeLong = 600000000; // 1 minute
		station.description = '';
		station.cycleTimeLong = 600000000; // 1 minute
		station.soakTimeLong = 0;
		station.terminal = 0;
		station.flowRate = 0;
		station.yearlyAdjFactor = 100;
		station.etAdjustFactor = 100;
		station.tempAdjustDays = 0;
		station.tempStationAdjust = 100;
		station.priority = 2;
		station.cropCoefficient = 1.0;
		return station;
	}
}

export class DecimalSeparatorListItem {

	name: string;
	value: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

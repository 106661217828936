import { Observable, of } from 'rxjs';
import { ActivityApiService } from './activity-api.service';
import { ActivityItem } from './models/activity-item.model';
import { ActivityStatusType } from './models/activity-status-type.model';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CourseViewLayout } from './models/course-view-layout.model';
import { CourseViewStationData } from './models/course-view-station-data.model';
import { Injectable } from '@angular/core';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ActivityManagerService extends ServiceManagerBase {

	// Subjects
	// activityChange = new Subject<ActivityItem[]>();

	// Cache Containers
	// No cache, for now. It changes too much and too unexpectedly.
	// private _activityColl: CachedCollection<ActivityItem>;
	private _activityStatusTypes: ActivityStatusType[];

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private activityApiService: ActivityApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	protected clearCache() {
		this._activityStatusTypes = null;
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getCompletedProgramGroupActivity(startTime: Date, endTime: Date, selectedSiteIds: number[] = null,
		bypassCache = false): Observable<ActivityItem[]> {
		// No caching.
		return this.activityApiService.getCompletedProgramGroupActivity(startTime, endTime, selectedSiteIds)
			// .pipe(
			// 	map(list => {
			// 		this._activityColl = new CachedCollection(list);
			// 		return list;
			// 	})
			// )
			;
	}

	getCompletedProgramActivity(startTime: Date, endTime: Date, selectedSiteIds: number[] = null,
		bypassCache = false): Observable<ActivityItem[]> {
		// No caching.
		return this.activityApiService.getCompletedProgramActivity(startTime, endTime, selectedSiteIds)
			// .pipe(
			// 	map(list => {
			// 		this._activityColl = new CachedCollection(list);
			// 		return list;
			// 	})
			// )
			;
	}

	getCompletedStationActivity(startTime: Date, endTime: Date, selectedSiteIds: number[] = null,
		bypassCache = false): Observable<ActivityItem[]> {
		// No caching.
		return this.activityApiService.getCompletedStationActivity(startTime, endTime, selectedSiteIds)
			// .pipe(
			// 	map(list => {
			// 		this._activityColl = new CachedCollection(list);
			// 		return list;
			// 	})
			// )
			;
	}

	getCourseViewActivityLayout(siteId: number): Observable<CourseViewLayout> {
		return this.activityApiService.getCourseViewActivityStructure(siteId);
	}

	getCourseViewActivityCompleted(startTime: Date, endTime: Date, siteId: number): Observable<CourseViewStationData[]> {
		return this.activityApiService.getCourseViewActivityCompleted(startTime, endTime, siteId);
	}

	getCourseViewActivityDryRun(dryRunId: number, siteId: number): Observable<CourseViewStationData[]> {
		return this.activityApiService.getCourseViewActivityDryRun(dryRunId, siteId);
	}

	// getCourseViewActivityScheduled(startTime: Date, endTime: Date, siteId: number): Observable<CourseViewStationData[]> {
	// 	return this.activityApiService.getCourseViewActivityScheduled(startTime, endTime, siteId);
	// }

	getActivityStatusTypes(): Observable<ActivityStatusType[]> {
		// RB-6291: Don't test if(array); you must check for null and undefined. Prefer the != test, rather than
		// two !== tests.
		if (this._activityStatusTypes != null) { return of(this._activityStatusTypes); }

		return this.activityApiService.getActivityStatusTypes()
			.pipe(tap(response => this._activityStatusTypes = response));
	}

}

import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { CompanyPreferences } from './models/company-preferences.model';
import { CompanyStatus } from './models/company-status.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SystemChangeLogEntry } from './models/system-change-log-entry.model';

@Injectable({
	providedIn: 'root'
})
export class CompanyApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================
	clearCache() {
		super.clearCache(this.baseGetCompanyPreferencesUrl);
	}

	getCompanyName(): Observable<string> {
		return this.apiRequest(this.getCompanyNameUrl(), HttpMethod.Get);
	}

	getCompanyPreferences(): Observable<ApiCachedRequestResponse<CompanyPreferences>> {
		return this.apiRequestWithCache<any>(this.getCompanyPreferencesUrl(), false, result => new CompanyPreferences(result));
	}

	getCompanyStatusCore(): Observable<CompanyStatus> {
		return this.apiRequest(this.getCompanyStatusCoreUrl, HttpMethod.Get)
			.pipe(map(response => new CompanyStatus(response)));
	}

	getSystemChangeLog(siteIds: number[], startTime: string, endTime: string): Observable<SystemChangeLogEntry[]> {
		return this.apiRequest(this.getSystemChangeLogUrl(startTime, endTime), HttpMethod.Post, siteIds);
	}

	getSystemFlowCapacity(): Observable<number> {
		return this.apiRequest(this.getSystemFlowCapacityUrl, HttpMethod.Get);
	}

	updateCompany(id: number[], companyData: any): Observable<any> {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch, { ids: id, patch: companyData });
	}

	updateCompanyDiagnosticSettings(companyPreferences: CompanyPreferences): Observable<any> {
		return this.apiRequest<any>(this.updateCompanyDiagnosticSettingsUrl(), HttpMethod.Put, companyPreferences);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseCompanyApiUrl(): string { return `${this.baseApiUrl}Company`; }

	private getCompanyNameUrl(): string {
		return `${this.baseCompanyApiUrl}/GetCompanyName`;
	}

	private get baseGetCompanyPreferencesUrl(): string { return `${this.baseCompanyApiUrl}/GetCompanyPreferences`; }

	private getCompanyPreferencesUrl(): string { return `${this.baseGetCompanyPreferencesUrl}`; }

	private get getCompanyStatusCoreUrl(): string { return `${this.baseCompanyApiUrl}/GetCompanyStatusCore`; }
	private get getSystemFlowCapacityUrl(): string { return `${this.baseCompanyApiUrl}/GetSystemFlowCapacity`; }

	private getSystemChangeLogUrl(startTime: string, endTime: string): string {
		return `${this.baseCompanyApiUrl}/GetSystemChangeLog?startTime=${startTime}&endTime=${endTime}`;
	}

	private get updateBatchesUrl(): string { return `${this.baseCompanyApiUrl}/UpdateBatches`; }

	private updateCompanyDiagnosticSettingsUrl(): string {
		return `${this.baseCompanyApiUrl}/UpdateDiagnosticSettings`;
	}
}

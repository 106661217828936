import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'rb-sidebar-sub-edit-selector',
	templateUrl: './sidebar-sub-edit-selector.component.html',
	styleUrls: ['./sidebar-sub-edit-selector.component.scss']
})
export class SidebarSubEditSelectorComponent implements OnInit {
	// Propagate the default click event of the component.
	@Output() click = new EventEmitter();

	@Input() selectorLabel: string;
	@Input() selectorValue: string;
	@Input() isRequired = false;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor() { }

	ngOnInit() {
	}

	// =========================================================================================================================================================
	//
	// =========================================================================================================================================================
}

import { RouterModule, Routes } from '@angular/router';
import { CustomModulePreloadingStrategy } from './core/components/custom-preloader/custom-module-preloading-strategy';
import { UnderConstructionComponent } from './core/components/under-construction/under-construction.component';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
	},
	{
		path: 'sites',
		loadChildren: () => import('./sections/sections.module').then(m => m.SectionsModule),
	},
	{
		path: 'activity',
		loadChildren: () => import('./sections/activity/activity.module').then(m => m.ActivityModule)
	},
	{
		path: 'controllers',
		loadChildren: () => import('./sections/controllers/controllers.module').then(m => m.ControllersModule),
		data: { preload: true, delay: 5000 }
	},
	{
		path: 'programs',
		loadChildren: () => import('./sections/programs/programs.module').then(m => m.ProgramsModule),
		// data: { preload: false, delay: -1 }
	},
	{
		path: 'reports',
		loadChildren: () => import('./sections/reports/reports.module').then(m => m.ReportsModule)
	},
	{
		path: 'systemsetup',
		loadChildren: () => import('./sections/system-setup/system-setup.module').then(m => m.SystemSetupModule)
	},
	{
		path: 'systemstatus',
		loadChildren: () => import('./sections/system-status/system-status.module').then(m => m.SystemStatusModule)
	},
	{
		path: 'diagnostics',
		loadChildren: () => import('./sections/diagnostics/diagnostics.module').then(m => m.DiagnosticsModule)
	},
	{ path: '**', component: UnderConstructionComponent }
];

export const AppRouting = RouterModule.forRoot(
	routes,
	{
		preloadingStrategy: CustomModulePreloadingStrategy
	}
);

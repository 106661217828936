/*
 * NOTE: DO NOT USE THESE ENUMS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbEnums Namespace.
 * 		 E.g., RbEnums.Common.ControllersSyncState
 * 		 See: _rb.enums.ts
 */

export namespace XXUseRbEnumsNamespace.Note {

	/* PLEASE ALPHABETIZE WHEN ADDING ENUMERATIONS */
	export enum NoteAnchor {
		Area,
		Controller,
		Hole,
		Note,
		Site,
		Station
	}

	export enum NoteLayout {
		Normal = 1,
		Compact = 2,
		Single = 3
	}

	export enum NotePriority {
		None = 1,
		Low = 2,
		Moderate = 3,
		High = 4,
		Urgent = 5
	}

	export enum NoteStatus {
		Active = 1,
		Resolved = 2,
		Discarded = 3
	}

	export enum NoteType {
		Note = 1,
		Task = 2,
		Action = 3
	}

}

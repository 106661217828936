import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'rb-golf-license-warning',
	templateUrl: './golf-license-warning.component.html',
	styleUrls: ['./golf-license-warning.component.scss'],
})
export class GolfLicenseWarningComponent {
	@HostBinding('class') class = 'rb-golf-license-warning';

	// =========================================================================================================================================================
	// C'tor and Init
	// =========================================================================================================================================================

	constructor( ) { }

}

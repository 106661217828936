import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

import DiagnosticsMode = RbEnums.Common.DiagnosticsMode;

export class DiagnosticsSegmentInfo {

	constructor(json: any = null, controllerId: number, changeDateTime: string) {
		if (json) {
			Object.assign(this, json);
		}

		this.changeDateTime = RbUtils.Conversion.convertStringToDate(changeDateTime);
		this.controllerId = controllerId;
	}

	controllerId: number;
	changeDateTime: Date;
	current: number;
	mode: string;
	rawMode: DiagnosticsMode;
	rawShortedMap: number;
	shortedPaths: boolean[] = [];
	testPath: number;

}

import { CCWeatherValuesData } from './ccweather-values-data.model';

export class CCWeatherIntervalData {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.values) {
				this.values = new CCWeatherValuesData(json.values);
		}
	}
	}

	startTime: string = null;
	values: CCWeatherValuesData = new CCWeatherValuesData();
}

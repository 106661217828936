import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class SimpleProgramGroup {

	constructor(json: any = null) {

		if (json) {
			delete json.program;
			delete json.groupStartTime;

			Object.assign(this, json);

			if (json.nextCyclicalStartDate) { this.nextCyclicalStartDate = RbUtils.Conversion.convertStringToDate(json.nextCyclicalStartDate); }
			if (json.stopTime) { this.stopTime = RbUtils.Conversion.convertStringToDate(json.stopTime); }
			if (json.tempAdjustStart) { this.tempAdjustStart = RbUtils.Conversion.convertStringToDate(json.tempAdjustStart); }
			if (json.tempAdjustStop) { this.tempAdjustStop = RbUtils.Conversion.convertStringToDate(json.tempAdjustStop); }
		}
	}

	companyId: number;
	etAdjustType: RbEnums.Common.EtAdjustType;
	forcedProgression: boolean;
	hybridWeekDays: string;
	id: number;
	isEnabled: boolean;
	isSequential: boolean;
	isVertical: boolean;
	name: string;
	nextCyclicalStartDate: Date;
	number: number;
	overrideCycleSoak: boolean;
	overrideStationAdjust: boolean;
	overrideWB: boolean;
	priority: number;
	programGroupAdjust: number;
	programType: RbEnums.Common.ProgramType;
	skipDays: number;
	stopTime: Date;
	systemOffOverride: boolean;
	tempAdjustStart: Date;
	tempAdjustStop: Date;
	tempProgramGroupAdjust: number;
	weekDays: string;
}

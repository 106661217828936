import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ProgramListItem } from '../../../../../api/programs/models/program-list-item.model';

@Component({
	selector: 'rb-program-group-schedule-mobile-cell-renderer',
	templateUrl: './program-group-schedule-mobile-cell-renderer.component.html',
	styleUrls: ['./program-group-schedule-mobile-cell-renderer.component.scss']
})
export class ProgramGroupScheduleMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	program: ProgramListItem;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.program = params.data;
	}

	get adjust(): string {
		return isNaN(this.program.programAdjust) ? '-' : `${this.program.programAdjust}`;
	}
}

import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Area } from '../../../api/areas/models/area.model';
import { ControllerListItem } from '../../../api/controllers/models/controller-list-item.model';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { Station } from '../../../api/stations/models/station.model';

@UntilDestroy()
@Component({
	selector: 'rb-station-diagnostic-filter',
	templateUrl: './station-diagnostic-filter.component.html',
	styleUrls: ['./station-diagnostic-filter.component.scss'],
})
export class StationDiagnosticFilterComponent implements OnInit, OnDestroy {

	@HostBinding('class') class = 'station-diagnostic-filter';

	@Output() cancelButtonClicked = new EventEmitter();
	@Output() runButtonClicked = new EventEmitter();

	@Input() isSelectTestTypeDisabled: boolean;
	@Input() isStartButtonDisabled: boolean;
	@Input() cancelButtonEnabled = false;

	diagnosticsTypes: any[] = [];
	interfaces: ControllerListItem[] = [];
	isMobile = false;
	loadError: string;
	@Input() testTypeId: number;
	@Output() testTypeIdChange = new EventEmitter<number>();
	stations: Station[] = [];
	@Input() testTypes: {name: string, id: RbEnums.Common.DiagnosticTestType} [] = [];
	areas: Area[] = [];
	holes: Area[] = [];
	courseAreas: Area[] = [];
	/**
	 * @summary Set to true to mark the filter 'busy', meaning it's not yet ready to run the selected test
	 * on the selected areas/stations/interfaces/system. Set to false when ready. A reference count is used
	 * to return isBusy = false only when no one thinks it should be busy.
	 */
	public get isBusy(): boolean {
		return this.isBusyRefCount > 0;
	}
	public set isBusy(busy: boolean) {
		if (busy) {
			this.isBusyRefCount++;
		} else if (this.isBusyRefCount > 0) {
			this.isBusyRefCount--;
		}
	}

	/**
	 * @summary Private ref count for tracking busy state. This allows us to set busy to true several
	 * times and keep track of how many "busies" are pending to decide what value to return from isBusy.
	 */
	private isBusyRefCount = 0;

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(
				private deviceManager: DeviceManagerService) { }

	ngOnInit(): void {
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe(isMobile => this.isMobile = isMobile);

		this.isMobile = this.deviceManager.isMobile;
	}

	changeTestType(type: number) {
		this.testTypeIdChange.emit(type);
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onRunButtonClicked() {
		this.runButtonClicked.emit();
	}

	onCancelButtonClicked() {
		this.cancelButtonClicked.emit();
	}

	onActionErrorAcknowledged() {
		this.loadError = null;
		this.isBusy = false;
	}

}

import { ApiService, HttpMethod } from '../_common/api.service';

import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { Controller } from './models/controller.model';
import { ControllerDifferenceItem } from './models/controller-differences/controller-difference-item.model';
import { ControllerListItem } from './models/controller-list-item.model';
import { ControllerModuleType } from '../modules/models/controller-module-type.model';
import { ControllerStatusItem } from './models/controller-status-item.model';
import { ControllerType } from './models/controller-type.model';
import { EnvironmentService } from '../../common/services/environment.service';
import { FormattedControllerDifference } from './models/controller-differences/formatted-controller-difference.model';
import { GetControllerQueryParams } from './models/get-controller-params.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPChangeReason } from './models/ip-change-reason';
import { IQNetType } from './models/iqnet-type.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PinLockoutListItemType } from '../pin-codes/models/pin-lockout-list-item-type.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { Snapshot } from './models/snapshot';
import { StationSequenceType } from './models/station-sequence-type.model';
import { TranslateService } from '@ngx-translate/core';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@Injectable({
	providedIn: 'root'
})
export class ControllerApiService extends ApiService {

	constructor(protected http: HttpClient,
				protected env: EnvironmentService,
				private translate: TranslateService) { super(http, env); }

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================
	clearCache() {
		super.clearCache(this.baseGetControllerUrl);
		super.clearCache(this.getControllersListUrl);
		super.clearCache(this.getControllerModuleTypesUrl);
		super.clearCache(this.getControllerTypesUrl);
		super.clearCache(this.getIQNetTypesUrl);
		super.clearCache(this.getPinLockoutTypesUrl);
		super.clearCache(this.getStationSequenceTypesUrl);
	}

	clearCacheForController(id: number) {
		super.clearCache(this.baseGetControllerWithIdUrl(id));
	}

	clearCacheForControllersList() {
		super.clearCache(this.getControllersListUrl);
	}

	copyController(satelliteId: number, destinationSiteId: number, destinationSatelliteId: number): Observable<Controller> {
		return this.apiRequest<any>(this.copyControllerUrl(satelliteId, destinationSiteId, destinationSatelliteId), HttpMethod.Post)
			.pipe(map(response => new Controller(response)));
	}

	createController(controllerUpdate: object): Observable<null> {
		return this.apiRequest<any>(this.createControllerUrl, HttpMethod.Post, controllerUpdate);
	}

	deleteControllers(controllerIds: number[]): Observable<null> {
		return this.apiRequest<any>(this.deleteControllersUrl, HttpMethod.Delete, controllerIds);
	}

	detectModules(controllerId: number): Observable<null> {
		return this.apiRequest<any>(this.detectModulesUrl(controllerId), HttpMethod.Post);
	}

	getConnectedControllersCountForCurrentUser(): Observable<number> {
		return this.apiRequest<any>(this.getUserConnectedControllerCount(), HttpMethod.Get)
			.pipe(map(response => response.value));
	}

	getController(controllerId: number, queryParams: GetControllerQueryParams, bypassCache = false): Observable<ApiCachedRequestResponse<Controller>> {
		return this.apiRequestWithCache<any>(this.getControllerUrl(controllerId, queryParams), bypassCache);
	}

	getControllerDifferences(controllerId: number): Observable<ControllerDifferenceItem[]> {
		return this.apiRequest<any>(this.getControllerDifferencesUrl(controllerId), HttpMethod.Get)
			.pipe(map(items => items.map(item => new ControllerDifferenceItem(item))));
	}

	getControllerModuleTypes(): Observable<ApiCachedRequestResponse<ControllerModuleType[]>> {
		return this.apiRequestWithCache<any>(this.getControllerModuleTypesUrl, false, result => result.map(type => new ControllerModuleType(type)));
	}

	getControllersList(bypassCache = false): Observable<ApiCachedRequestResponse<ControllerListItem[]>> {
		return this.apiRequestWithCache<any>(this.getControllersListUrl, bypassCache, result => result?.map(c => new ControllerListItem(c)));
	}

	getControllerStatusesList(satIds: Number[]): Observable<ControllerStatusItem[]> {
		return this.apiRequest<any>(this.getControllersStatusesUrl, HttpMethod.Post, satIds);
	}

	getControllerItem(controllerId: number) : Observable<ControllerListItem> {
		return this.apiRequest<any>(this.getControllerItemUrl(controllerId), HttpMethod.Get)
			.pipe(map((controller: ControllerListItem) => new ControllerListItem(controller)));
	}

	getClientControllerIds(controllerId: number): Observable<number[]> {
		return this.apiRequest<any>(this.getClientControllerIdsUrl(controllerId), HttpMethod.Get);
	}

	getControllerTypes(): Observable<ApiCachedRequestResponse<ControllerType[]>> {
		return this.apiRequestWithCache<any>(this.getControllerTypesUrl, false, result => result.map(type => new ControllerType(type)));
	}

	getFormattedControllerDifferences(controllerId: number): Observable<FormattedControllerDifference[]> {
		return this.apiRequest<any>(this.getFormattedControllerDifferencesUrl(controllerId), HttpMethod.Get)
			.pipe(map(items => items.map(item => new FormattedControllerDifference(item))));
	}
	getDataPacks(controllerId: number): Observable<void> {
		return this.apiRequest<any>(this.getDataPacksUrl(controllerId), HttpMethod.Get);
	}

	getIQNetTypes(): Observable<ApiCachedRequestResponse<IQNetType[]>> {
		return this.apiRequestWithCache<any>(this.getIQNetTypesUrl, false, result => result.map(type => new IQNetType(type)));
	}

	getNextDefaultName(siteId: number, deviceType: number): Observable<string> {
		return this.apiRequestWithCache<any>(this.getNextDefaultNameUrl(siteId, deviceType), true)
			.pipe(map(response => response.value.stringValue));
	}

	getNextSatelliteNumber(parentDeviceId: number, groupNumber: number): Observable<number> {
		return this.apiRequestWithCache<any>(this.getNextSatelliteNumberUrl(parentDeviceId, groupNumber), true)
			.pipe(map(response => response.value.value));
	}

	getPhysicalData(controllerIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.getPhysicalDataUrl, HttpMethod.Post, controllerIds);
	}

	getFlowMonitoringStatus(getFlowMonitoringStatusSatellite: object): Observable<void> {
		return this.apiRequest<any>(this.getFlowMonitoringStatusUrl, HttpMethod.Post, getFlowMonitoringStatusSatellite);
	}

	healAlarmsAndGetFlowMonitoringStatus(getFlowMonitoringStatusSatellite: object): Observable<void> {
		return this.apiRequest<any>(this.healAlarmsAndGetFlowMonitoringStatusUrl, HttpMethod.Post, getFlowMonitoringStatusSatellite);
	}

	getPinLockoutTypes(): Observable<ApiCachedRequestResponse<PinLockoutListItemType[]>> {
		return this.apiRequestWithCache<any>(this.getPinLockoutTypesUrl, false, result => {
			const list = result.map(type => new PinLockoutListItemType(type));
			list.sort((a, b) => a.value - b.value);
			list.forEach(item => item.name = this.getPinLockoutTypeName(item.value));
			return list;
		});
	}

	private getPinLockoutTypeName(lockoutType: RbEnums.Common.PinLockoutType) {
		if (lockoutType === RbEnums.Common.PinLockoutType.Unprotected) return this.translate.instant('STRINGS.UNPROTECTED');
		if (lockoutType === RbEnums.Common.PinLockoutType.Partial) return this.translate.instant('STRINGS.PARTIAL');
		if (lockoutType === RbEnums.Common.PinLockoutType.Full) return this.translate.instant('STRINGS.FULL');
		return '';
	}

	getStationSequenceTypes(): Observable<ApiCachedRequestResponse<StationSequenceType[]>> {
		return this.apiRequestWithCache<any>(this.getStationSequenceTypesUrl, false, result => result.map(type => new StationSequenceType(type)));
	}

	getCompanyTotalSatelliteCount(): Observable<number> {
		return this.apiRequest<any>(this.companyTotalSatelliteCountUrl, HttpMethod.Get);
	}

	moveController(controllerId: number, destinationSiteId: number, destinationControllerId?: number): Observable<Controller> {
		return this.apiRequest<any>(this.moveSatelliteUrl(controllerId, destinationSiteId, destinationControllerId), HttpMethod.Post)
			.pipe(map(response => new Controller(response)));
	}

	reverseSynchronize(controllerIds: number[]): Observable<null> {
		return this.apiRequest<any>(this.reverseSynchronizeUrl, HttpMethod.Post, controllerIds);
	}

	stopAllIrrigation(controllerIds: number[]): Observable<null> {
		return this.apiRequest<any>(this.stopAllIrrigationUrl, HttpMethod.Post, controllerIds);
	}

	synchronize(controllerIds: number[], syncIrrigationInProgress: boolean): Observable<null> {
		return this.apiRequest<any>(this.synchronizeUrl(syncIrrigationInProgress), HttpMethod.Post, controllerIds);
	}

	updateControllers(controllerIds: number[], controllerUpdate: object): Observable<null> {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch, { ids: controllerIds, patch: this.patchTransform(controllerUpdate) });
	}

	getNameUniqueness(name: string, id: number, siteId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNameUniquenessUrl(name, id, siteId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getClientAddressUniqueness(address: number, id: number, parentId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getClientAddressUniquenessUrl(address, id, parentId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}
	getGprsIps(): Observable<string[]> {
		return this.apiRequest<any>(this.getGprsIpsUrl, HttpMethod.Get);
	}

	getIPChangeReasons(): Observable<ApiCachedRequestResponse<IPChangeReason[]>> {
		return this.apiRequestWithCache<any>(this.getIPChangeReasonsUrl, false, result => {
			const list = result.map(type => new IPChangeReason(type));
			list.sort((a, b) => a.value - b.value);
			return list;
		});
	}

	createSnapshots(controllerIds: number[]): Observable<any> {
		return this.apiRequest<any>(this.createSnapshotsUrl, HttpMethod.Post, controllerIds);
	}

	getSnapshots(controllerId: number): Observable<Snapshot[]> {
		// return controllerId !== 1641 ? of([])
		// 	: of([ { satelliteId: 1641, companyId: 2, reason: 0, json: 'Reverse Sync Result 1', date: '2022-08-07T15:12:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 1', date: '2022-08-07T01:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 2', date: '2022-08-07T02:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 3', date: '2022-08-07T03:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 4', date: '2022-08-07T04:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 5', date: '2022-08-07T05:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 6', date: '2022-08-07T06:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 7', date: '2022-08-07T07:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 8', date: '2022-08-07T08:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 9', date: '2022-08-07T09:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 10', date: '2022-08-07T10:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 11', date: '2022-08-07T11:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 12', date: '2022-08-07T12:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 13', date: '2022-08-07T13:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 14', date: '2022-08-07T14:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 15', date: '2022-08-07T15:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 16', date: '2022-08-07T16:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 17', date: '2022-08-07T17:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 18', date: '2022-08-07T18:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 19', date: '2022-08-07T19:13:22.587-05:00' },
		// 		{ satelliteId: 1641, companyId: 2, reason: 1, json: 'Manual Result 20', date: '2022-08-07T20:13:22.587-05:00' }
		// 	]
		// 		.map(item => new Snapshot(item)));
		return this.apiRequest<any>(this.getSnapshotsUrl(controllerId), HttpMethod.Get)
			.pipe(map(items => items.map(item => new Snapshot(item))));
	}

	restoreSnapshot(controllerId: number, date: Date, forceRestore: boolean): Observable<null> {
		return this.apiRequest<any>(this.restoreSnapshotUrl, HttpMethod.Put, { satelliteId: controllerId, date, forceRestore });
	}

	// =========================================================================================================================================================
	// Methods for updating and returning cached collections
	// =========================================================================================================================================================

	updateAndReturnCachedControllerListItems(updatedItems: ControllerListItem[]) : ControllerListItem[] {
		return this.updateAndReturnCachedCollection<ControllerListItem>(updatedItems, 'id', this.getControllersListUrl);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}Satellite`; }

	private copyControllerUrl(satelliteId: number, destinationSiteId: number, destinationSatelliteId: number): string {
		// eslint-disable-next-line max-len
		return `${this.baseUrl}/CopySatellite?satelliteId=${satelliteId}&destinationSiteId=${destinationSiteId}&destinationSatelliteId=${destinationSatelliteId}`;
	}

	private get createControllerUrl(): string { return `${this.baseUrl}/CreateSatellite`; }

	private get deleteControllersUrl(): string { return `${this.baseUrl}/DeleteSatellites`; }

	private detectModulesUrl(controllerId: number): string { return `${this.baseUrl}/DetectModules?satelliteId=${controllerId}`; }

	private getClientAddressUniquenessUrl(address: number, id: number, parentId: number): string {
		return `${this.baseUrl}/IsAddressUnique?parentId=${parentId}&address=${address}&satelliteId=${id}`;
	}

	private getClientControllerIdsUrl(controllerId: number): string {
		return `${this.baseUrl}/GetClientSatelliteIds?satelliteId=${controllerId}`;
	}
	private getDataPacksUrl(controllerId: number): string {
		return `${this.baseUrl}/GetDataPacks?satelliteId=${controllerId}`;
	}

	private getControllerDifferencesUrl(controllerId: number) {
		return `${this.baseUrl}/GetSatelliteDifferences?satelliteId=${controllerId}`;
	}

	private getControllerItemUrl(controllerId: number): string {
		return `${this.baseUrl}/GetSatelliteItem?satelliteId=${controllerId}`;
	}

	private get getControllerModuleTypesUrl(): string { return `${this.baseUrl}/GetModuleTypes`; }

	private get getControllerTypesUrl(): string { return `${this.baseUrl}/GetControllerTypes`; }

	private get getControllersListUrl(): string {
		return `${this.baseUrl}/GetSatelliteList`;
	}

	private get getControllersStatusesUrl(): string {
		return `${this.baseUrl}/GetSatelliteStatusList`;
	}

	private get baseGetControllerUrl(): string { return `${this.baseUrl}/GetSatellite`; }
	private baseGetControllerWithIdUrl(controllerId: number): string { return `${this.baseGetControllerUrl}?satelliteId=${controllerId}`; }

	private getControllerUrl(controllerId: number, queryParams: GetControllerQueryParams): string {
		const queryString = queryParams ? queryParams.queryString : '';
		return `${this.baseGetControllerWithIdUrl(controllerId)}&${queryString}`;
	}

	private getFormattedControllerDifferencesUrl(controllerId: number) {
		return `${this.baseUrl}/GetFormattedSatelliteDifferences?satelliteId=${controllerId}`;
	}

	private get getIQNetTypesUrl(): string { return `${this.baseUrl}/GetIQNetTypes`; }

	private getNameUniquenessUrl(name: string, id: number, siteId: number): string {
		return `${this.baseUrl}/IsNameUnique?siteId=${siteId}&controllerName=${encodeURIComponent(name)}&controllerId=${id}`;
	}

	private getNextDefaultNameUrl(siteId: number, deviceType: number): string {
		return `${this.baseUrl}/GetNextDefaultName?siteId=${siteId}&type=${deviceType}`;
	}

	private getNextSatelliteNumberUrl(parentDeviceId, groupNumber): string {
		return `${this.baseUrl}/GetNextSatelliteNumber?parentDeviceId=${parentDeviceId}&groupNumber=${groupNumber}`;
	}

	private get getPhysicalDataUrl(): string { return `${this.baseUrl}/GetPhysicalData`; }

	private get getFlowMonitoringStatusUrl(): string { return `${this.baseUrl}/GetFlowMonitoringStatus`; }

	private get healAlarmsAndGetFlowMonitoringStatusUrl(): string { return `${this.baseUrl}/HealAlarmsAndGetFlowMonitoringStatus`; }

	private get getPinLockoutTypesUrl(): string { return `${this.baseUrl}/GetPinLockOutTypes`; }

	private get getStationSequenceTypesUrl(): string { return `${this.baseUrl}/GetStationSequenceTypes`; }

	private get companyTotalSatelliteCountUrl(): string { return `${this.baseUrl}/GetCompanyTotalSatelliteCount`; }

	private getUserConnectedControllerCount(): string {
		return `${this.baseUrl}/GetUserConnectedControllerCount`;
	}

	private moveSatelliteUrl(satelliteId: number, destinationSiteId: number, destinationControllerId?: number): string {
		let queryParams = `?satelliteId=${satelliteId}&destinationSiteId=${destinationSiteId}`;
		if (destinationControllerId) { queryParams += `&destinationSatelliteId=${destinationControllerId}`; }
		return `${this.baseUrl}/MoveSatellite${queryParams}`;
	}

	private get reverseSynchronizeUrl(): string { return `${this.baseUrl}/ReverseSynchronize`; }

	private get stopAllIrrigationUrl(): string { return `${this.baseUrl}/StopAllIrrigation`; }

	private synchronizeUrl(syncIrrigationInProgress: boolean): string {
		return `${this.baseUrl}/Synchronize?syncIrrigationInProgress=${syncIrrigationInProgress}`;
	}

	private get updateBatchesUrl(): string { return `${this.baseUrl}/v2/UpdateBatches`; }

	private get getGprsIpsUrl(): string { return `${this.baseUrl}/GetGprsIps`; }

	private get getIPChangeReasonsUrl(): string { return `${this.baseUrl}/GetIPChangeReasons`; }

	private getSnapshotsUrl(controllerId: number): string { return `${this.baseUrl}/GetSatelliteRestoreSnapshots?satelliteId=${controllerId}`; }

	private get restoreSnapshotUrl(): string { return `${this.baseUrl}/RestoreSnapshot`; }

	private get createSnapshotsUrl(): string { return `${this.baseUrl}/CreateSnapshots`; }
}

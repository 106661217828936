/*
 * NOTE: DO NOT USE THESE CONSTANTS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbConstants Namespace.
 * 		 E.g., RbConstants.Ui.AppName
 * 		 See: _rb.constants.ts
 */

import { RbEnums } from '../enumerations/_rb.enums';

export interface INavIcon {
	name: string,
	key: string,
	label: string,
	cssClass: string
}
export namespace XXUseRbConstantsNamespace {

	export abstract class Ui {

		static readonly CONNECT_QAID = 'Connect';
		static readonly COLOR_PICKER_WIDTH = '235';					// px
		static readonly ICON_COLOUR = '#008658';
		static readonly ICON_SYNC = 'sync';
		static readonly ICON_SYNC_PROBLEM = 'sync_problem';
		static readonly MAIN_TABLE_CLASS = 'main-table';
		static readonly MOBILE_MAIN_NAV_HEIGHT = 64;				// px
		static readonly MOBILE_SYSTEM_STATUS_HEIGHT = 59;			// px
		static readonly MOBILE_FOOTER_HEIGHT = 40;					// px
		static readonly MOBILE_TOOLBAR_HEIGHT = 40;					// px
		static readonly MOBILE_SUB_NAV_HEADER_HEIGHT = 40;			// px
		static readonly MOBILE_SUB_NAV_ROUTES_HEIGHT = 40;			// px
		static readonly MOBILE_SUB_NAV_REPORT_DETAIL_HEIGHT = 64;	// px
		static readonly SELECTED_GRID_ITEMS_COUNT_HEIGHT = 37;		// PX

		static readonly MOBILE_SCROLLABLE_FOOTER_HEIGHT = 65;		// px
		static readonly PERCENTAGE_PROGRESS_MAX = 100;
		static readonly PERCENTAGE_PROGRESS_AN_API = 10;
		static readonly PERCENTAGE_PROGRESS_DELAY = 20;			// milliseconds

		static readonly STATION_TOGGLE_OPTIONS = [
			{
				id: 0,
				label: 'STRINGS.VIEW_BY_AREA',
				dataqaid: 'system-setup-area-toggle',
			},
			{
				id: 1,
				label: 'STRINGS.VIEW_BY_CONTROLLER',
				dataqaid: 'system-setup-controller-toggle',
			},
		];

		static readonly SEASONAL_ADJUST_TYPE_OPTIONS = [
			// {name: 'STRINGS.NONE', value: RbEnums.Common.AdjustType.None, title: null, unit: null},
			{name: 'STRINGS.DAILY_PERCENT', value: RbEnums.Common.AdjustType.DailySA, title: 'STRINGS.DAILY_PERCENT_TITLE', unit: '%'},
			{name: 'STRINGS.MONTHLY_PERCENT', value: RbEnums.Common.AdjustType.MonthlySA, title: 'STRINGS.MONTHLY_PERCENT_TITLE', unit: '%'},
			{name: 'STRINGS.DAILY_ET', value: RbEnums.Common.AdjustType.DailyET, title: 'Daily ET (in)', unit: 'in'},
			{name: 'STRINGS.MONTHLY_ET', value: RbEnums.Common.AdjustType.MonthlyET, title: 'Monthly ET (in)', unit: 'in'}
		];
		
		static readonly NAVIGATION_ICONS: INavIcon[] = [
			// {name: 'NONE', key: 'STRINGS.NONE', label: 'STRINGS.BETA_ICON_LABEL', cssClass: 'beta-feature-icon'} // Example data
		];

		static readonly FIELD_TECH_EDITABLE_OPTIONS = [
			{name: 'STRINGS.CONTACTS', path: 'contacts'}
		];

		static readonly ROUTING_FRAGMENT = {
			OpenCreatingSiteSidebar: 'OpenCreatingSiteSidebar',
			OpenSharingSiteDialog: 'OpenSharingSiteDialog',
			OpenViewProgramDetails: 'OpenViewProgramDetails'
		}

		static readonly RB_LINK = {
			BMS_TRAINING_LINK: 'https://www.rainbird.com/media/16422',
			BMS_DOCUMENTATION: 'https://iq4bms.readme.io/reference/how-to-use-this-guide'
		}
	}
}
<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} << </div>

<div class="comp-container modern">
  <div class="body w-100">
    <div class="new-note-container">
      <rb-note-item
        [note]="selectedNote"
        [user]="user"
        [mapInfo]="dialogData?.mapInfo"
        [isWidget]="isWidget"
        [isDraggable]="true"
        [siteId]="siteId"
        [noteLayout]="(selectedNote.id && selectedNote.attachedToType === NoteAnchor.Note)
          ? NoteLayout.Compact : NoteLayout.Single"
        (invokeSaveNote)="saveNote($event)"
        (invokeResolveNote)="resolveNote($event)"
        (invokeShowRightPane)="onInvokeRightPane($event)"
        (noteLocationClicked)="onNoteLocationClicked($event, selectedNote)"
      ></rb-note-item>
    </div>
    <div class="notes-feed" *ngIf="selectedNote.attachedToType !== NoteAnchor.Note">
      <rb-note-item *ngFor="let note of selectedNote.replies"
        [note]="note"
        [noteList]="filteredNotes"
        [user]="user"
        [mapInfo]="dialogData?.mapInfo"
        [noteLayout]="2"
        [isWidget]="isWidget"
        [siteId]="siteId"
        [canPin]="true"
        (invokeSaveNote)="saveNote($event)"
        (invokeResolveNote)="resolveNote($event)"
        (invokePinNote)="onNotePin($event)"
        (invokeShowRightPane)="onInvokeRightPane($event)"
        (noteLocationClicked)="onNoteLocationClicked($event, note)"
        ></rb-note-item>
    </div>
    <div class="bottom-decor"
      *ngIf="selectedNote.attachedToType === NoteAnchor.Station && selectedNote.replies?.length"></div>
  </div>
</div>
/*
 * NOTE: DO NOT USE THESE ENUMS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbEnums Namespace.
 * 		 E.g., RbEnums.SignalR.CommandMethod
 * 		 See: _rb.enums.ts
 */

export namespace XXUseRbEnumsNamespace.SignalR {

	export enum CommandMethod {
		DryRunCommand = 'DryRunCommand',
		FlowElementCommand = 'FlowElementCommand',
		IrrigationEngineCommand = 'IrrigationEngineCommand',
		ProgramCommand = 'ProgramCommand',
		SatelliteCommand = 'SatelliteCommand',
		SensorCommand = 'SensorCommand',
		StationCommand = 'StationCommand',
		StationValveTypeCommand = 'StationValveTypeCommand',
	}

	export enum JobPhase { Request, RequestComplete, Start, Finish, InProcess, Queued };
	export enum JobType {
		AdvanceStation = 'AdvanceStation',
		Beacon = 'Beacon',
		CancelAllIrrigationQueue = 'CancelAllIrrigationQueue',
		ClearAllNetHealthLogs = 'ClearAllNetHealthLogs',
		ClearAllSensor = 'ClearAllSensor',
		CloseMasterValves = 'CloseMasterValves',
		CollectWeatherData = 'CollectWeatherData',
		CompanyStatusChange = 'CompanyStatusChange',
		ConnectionStarted = 'ConnectionStarted',
		ConnectionStopped = 'ConnectionStopped',
		ConnectionFailed = 'ConnectionFailed',
		ConnectionCompleted = 'ConnectionCompleted',
		FlowMonitoringControl = 'FlowMonitoringControl',
		GetDataPacks = 'GetDataPacks',
		Get2WireData = 'Get2WireData',
		GetFlowEventLogs = 'GetFlowEventLogs',
		GetEventLogs = 'GetEventLogs',
		GetFlowMonitoringRate = 'GetFlowMonitoringRate',
		GetFlowMonitoringStatus = 'GetFlowMonitoringStatus',
		GetIrrigationQueue = 'GetIrrigationQueue',
		GetIrrigationState = 'GetIrrigationState',
		GetMasterValveStatus = 'GetMasterValveStatus',
		GetNetHealthEventLogs = 'GetNetHealthEventLogs',
		GetNetHealthPacketLogs = 'GetNetHealthPacketLogs',
		GetShortReport = 'GetShortReport',
		GetPhysicalData = 'GetPhysicalData',
		GetWeatherSensorStatus = 'GetWeatherSensorStatus',
		InitiateLearnedFlow = 'InitiateLearnedFlow',
		IrrigationCancelled = 'IrrigationCancelled',
		IrrigationPaused = 'IrrigationPaused',
		IrrigationResumed = 'IrrigationResumed',
		LxivmTwoWireLineSurvey = 'LxivmTwoWireLineSurvey',
		Lxivm2WireDeviceResponseStatuses = 'Lxivm2WireDeviceResponseStatuses',
		Lxivm2WireDevicePaths = 'Lxivm2WireDevicePaths',
		SystemModeAuto_Irrigating = 'SystemModeAuto_Irrigating',
		SystemModeOff_Irrigating = 'SystemModeOff_Irrigating',
		SystemModeOff_NotIrrigating = 'SystemModeOff_NotIrrigating',
		SystemModeAuto_NotIrrigating = 'SystemModeAuto_NotIrrigating',
		SystemModePaused_Irrigating = 'SystemModePaused_Irrigating',
		SystemModePaused_NotIrrigating = 'SystemModePaused_NotIrrigating',
		LogsCountChanged = 'LogsCountChanged',
		ManualConnect = 'ManualConnect',
		ManualMVWaterWindow = 'ManualMVWaterWindow',
		NewSoftwareVersionAvailable = 'NewSoftwareVersionAvailable',
		OverrideDial = 'OverrideDial',
		OverrideSensor = 'OverrideSensor',
		OverrideTwoWirePath = 'OverrideTwoWirePath',
		Ping = 'Ping',
		RasterTest = 'RasterTest',
		ResetMrr = 'ResetMrr',
		ReverseSynchronizeDevice = 'ReverseSynchronizeDevice',
		SatelliteConnectionChanged = 'SatelliteConnectionChanged',
		SetRainDelay = 'SetRainDelay',
		SrrPing = 'SrrPing',
		StartLxdDecoderTest = 'StartLxdDecoderTest',
		StartStation = 'StartStation',
		StartProgram = 'StartProgram',
		Synchronize = 'Synchronize',
		TbosManualCommand = 'TbosManualCommand',
		TbosSensorAlerts = 'TbosSensorAlerts',
		TbosStatus = 'TbosStatus',
		TheoreticalFlowRateTotalChanged = 'TheoreticalFlowRateTotalChanged',
		UniversalTwoWireGetDiagnosticsSegmentInfo = 'UniversalTwoWireGetDiagnosticsSegmentInfo',
		UniversalTwoWireDiagnosticsAcCurrentTest = 'UniversalTwoWireDiagnosticsAcCurrentTest',
		Unsupported = 'Unsupported',
		UpdateFirmware = 'UpdateFirmware',
		UniversalDryRun = 'UniversalDryRun',
		VoltagePollDataChange = 'VoltagePollDataChange',
		ShortAddressPollDataChange = 'ShortAddressPollDataChange',
		LongAddressPollDataChange = 'LongAddressPollDataChange',
		QuickCheckDataChange = 'QuickCheckDataChange',
		Updated = 'Updated',
		UnlockAll = 'UnlockAll',
		GroupFaultFindingModeDataChange = 'GroupFaultFindingModeDataChange',
		TbosNetworkConfiguration = 'TbosNetworkConfiguration',
		TbosDiscovery = 'TbosDiscovery',
		NewAccessRequest = 'NewAccessRequest',
		AccessRequestCanceled = 'AccessRequestCanceled',
	}

	export enum ManualControlProperty {
		IsAuto = 'isAuto',
		IsClearingFlowLogs = 'isClearingFlowLogs',
		IsClosingMasterValves = 'isClosingMasterValves',
		IsConnecting = 'isConnecting',
		IsFloManagerEnabled = 'isFloManagerEnabled',
		IsSensorActive = 'isSensorActive',
		IsSettingWaterWindow = 'isSettingWaterWindow',
		RainDelayDays = 'rainDelayDays',
		isFloWatchEnabled = 'isFloWatchEnabled',
		IsTwoWirePathOn = 'isTwoWirePathOn',
	}

	export enum ProgramStatusChangeType {
		ProgramStarted = 'ProgramStarted',
		ProgramCompleted = 'ProgramCompleted',
		ProgramCancelled = 'ProgramCancelled',
		ProgramRunningUpdate = 'ProgramRunningUpdate',
		ProgramPaused = 'ProgramPaused',
		ProgramResumed = 'ProgramResumed',
		Waiting = 'Waiting',
		Posted = 'Posted',

		Added = 'Added',
		Deleted = 'Deleted',
		Updated = 'Updated',
		ProgramGroupBatchUpdated = 'ProgramGroupBatchUpdated',

		StartTimeChanged = 'StartTimeChanged',

		/**
		 * Add another value for a periodic "programs are active" status message. When this value is sent, it is accompanied
		 * with a list of programGroupIds and a list of programIds. Those items are "active". This does not necessarily mean
		 * running, but could be soaking, posted, waiting, etc. This periodic update is simply intended to give a tickle to
		 * the UI when there are long-running programs whose Started messages may not have been seen by the UI because they
		 * were long ago. See RB-8985.
		 */
		ProgramsActiveUpdate = 'ProgramsActiveUpdate',
	}

	export enum SatelliteChangeType {
		ActualFlowMonitoringRate = 'ActualFlowMonitoringRate',
		ConnectionStarted = 'ConnectionStarted',
		ConnectionStopped = 'ConnectionStopped',
		ConnectionFailed = 'ConnectionFailed',
		Added = 'Added',
		Deleted = 'Deleted',
		Updated = 'Updated',
		ConnectionCompleted = 'ConnectionCompleted',
		ReverseSyncStarted = 'ReverseSyncStarted',
		ReverseSyncCompleted = 'ReverseSyncCompleted',
		GetModuleListStarted = 'GetModuleListStarted',
		GetModuleListCompleted = 'GetModuleListCompleted',
		ManualIrrigationRunningUpdate = 'ManualIrrigationRunningUpdate',
		ManualIrrigationStopped = 'ManualIrrigationStopped',
		ControllerDataUpdate = 'ControllerDataUpdate',
		GetFlowMonitoringRate = 'GetFlowMonitoringRate',
		StationShortReportChanged = 'StationShortReportChanged',
		SyncStateChanged = 'SyncStateChanged',
		FlowMonitoringStatusChanged = 'FlowMonitoringStatusChanged',
		WeatherSensorStatusChanged = 'WeatherSensorStatusChanged',
		TwoWireDataChanged = 'TwoWireDataChanged',
		MasterValveStatusChanged = 'MasterValveStatusChanged',
		UniversalTwoWireDataChanged = 'UniversalTwoWireDataChanged',
		UniversalDeviceResponseStatusesChanged = 'UniversalDeviceResponseStatusesChanged',
		Universal2WireDevicePathsChanged = 'Universal2WireDevicePathsChanged',
		DiagnosticsSegmentInfoChanged = 'DiagnosticsSegmentInfoChanged',
		UniversalMasterValveStatusChanged = 'UniversalMasterValveStatusChanged',
		UniversalWeatherSensorStatusChanged = 'UniversalWeatherSensorStatusChanged',
		IrrigationStateChanged = 'IrrigationStateChanged',
		PingResult = 'PingResult',
		PingCommunicationFailed = 'PingCommunicationFailed',
		HasLastSyncDifferenceChanged = 'HasLastSyncDifferenceChanged'
	}

	export enum RadioRelayChangeType {
		Added = 'Added',
		Deleted = 'Deleted',
		Updated = 'Updated',
	}

	export enum SensorState {
		Bypassed = 0,
		Active = 1,
	}

	export enum StationStatusChangeType {
		Started = 'Started',
		RunningUpdate = 'RunningUpdate',
		SoakStart = 'SoakStart',
		SoakUpdate = 'SoakUpdate',
		Stopped = 'Stopped',
		Paused = 'Paused',
		PausedUpdate = 'PausedUpdate',
		Waiting = 'Waiting',
		Resumed = 'Resumed',

		Added = 'Added',
		Deleted = 'Deleted',
		Updated = 'Updated',
		BatchUpdated = 'BatchUpdated',
		Unlocked = 'Unlocked',
	}
	export enum DryrunChangeType {
		DryRunStarted = 'DryRunStarted',
		DryRunCompleted = 'DryRunCompleted',
		DryRunCancelled = 'DryRunCancelled',
		DryRunUpdate = 'DryRunUpdate',
		DryRunInProgress = 'DryRunInProgress',
		Added = 'Added',
		Deleted = 'Deleted',
		Updated = 'Updated',
		DryRunMoreDataAvailable = 'DryRunMoreDataAvailable',
		HistoricalDryRunCompleted = 'HistoricalDryRunCompleted',
	}
	export enum StatusMethod {
		DryRunStatusChange = 'DryRunStatusChange',
		FlowElementStatusChange = 'FlowElementStatusChange',
		IrrigationEngineStatusChange = 'IrrigationEngineStatusChange',
		JobFinish = 'JobFinish',
		JobProgressStatus = 'JobProgressStatus',
		JobQueued = 'JobQueued',
		JobRequestCompleted = 'JobRequestCompleted',
		JobRequested = 'JobRequested',
		JobStart = 'JobStart',
		ProgramChange = 'ProgramChange',
		ProgramStatusChange = 'ProgramStatusChange',
		RadioRelayStatusChange = 'RadioRelayStatusChange',
		SatelliteStatusChange = 'SatelliteStatusChange',
		SensorStatusChange = 'SensorStatusChange',
		StationStatusChange = 'StationStatusChange',
		StationValveTypeStatusChange = 'StationValveTypeStatusChange',
		SiteStatusChange = 'SiteStatusChange',
		WeatherSourceStatusChange = 'WeatherSourceStatusChange',
		ScheduledReportStatusChange = 'ScheduledReportStatusChange',
	}

	export enum SyncType {
		Modules = 'Modules',
		ReverseSync = 'Modules, Configuration, Programming, LearnedFlow',
		PhysicalData = 'Configuration, Programming, LearnedFlow'
	}

	export enum SensorStatusChangeType {
		SensorValue = 'SensorValue',

		Added = 'Added',
		Deleted = 'Deleted',
		Updated = 'Updated',
	}

	export enum SensorFailureReasonCode {
		NoError = 'NoError',
		NoFieldConnection = 'NoFieldConnection',
		NoFeedback = 'NoFeedback',
		BadStationData = 'BadStationData',

		NoGroup = 'NoGroup',
		Error = 'Error'
	}

	export enum FrontPanelSyncState {
		Synchronized = 'Synchronized',
		OutOfSync = 'OutOfSync',
		Incomplete = 'Incomplete',
	}

	export enum FlowElementChangeType {
		Added = 'Added',
		Deleted = 'Deleted',
		Updated = 'Updated',
		FlowRateUpdate = 'FlowRateUpdate',
	}

	export enum IrrigationEngineChangeType {
		IrrigationCancelled = 'IrrigationCancelled',
		IrrigationActiveUpdate = 'IrrigationActiveUpdate',

		Added = 'Added', Deleted = 'Deleted',	// Not sure we'd use these in the UI
		Updated = 'Updated',

		IrrigationPaused = 'IrrigationPaused',
		IrrigationResumed = 'IrrigationResumed',

		Ready = 'Ready',
		ShuttingDown = 'ShuttingDown',

		ShortAddressPollDataChange = 'ShortAddressPollDataChange',
		VoltagePollDataChange = 'VoltagePollDataChange',
		LongAddressPollDataChange = 'LongAddressPollDataChange',
		GroupCurrentDataChange = 'GroupCurrentDataChange',
		QuickCheckDataChange = 'QuickCheckDataChange',
		SolenoidResistanceCheckDataChange = 'SolenoidResistanceCheckDataChange',
		BranchOverCurrentPollDataChange = 'BranchOverCurrentPollDataChange',
		LINKDataChange = 'LINKDataChange',

		LogsCountChanged = 'LogsCountChanged',
		NewSoftwareVersionAvailable = 'NewSoftwareVersionAvailable',

		SatelliteConnectionChanged = 'SatelliteConnectionChanged',

		SystemModeAuto_Irrigating = 'SystemModeAuto_Irrigating',
		SystemModeOff_Irrigating = 'SystemModeOff_Irrigating',
		SystemModeAuto_NotIrrigating = 'SystemModeAuto_NotIrrigating',
		SystemModeOff_NotIrrigating = 'SystemModeOff_NotIrrigating',
		SystemModePaused_Irrigating = 'SystemModePaused_Irrigating',
		SystemModePaused_NotIrrigating = 'SystemModePaused_NotIrrigating',

		TheoreticalFlowRateTotalChanged = 'TheoreticalFlowRateTotalChanged',

		TheFREEDOMSystemLinked = 'TheFREEDOMSystemLinked',
		TheFREEDOMSystemUnlinked = 'TheFREEDOMSystemUnlinked',
		TheFREEDOMSystemFailed = 'TheFREEDOMSystemFailed',
	}

	export enum SiteStatusChangeType {
		/**
		 * Sent from Core when a new Site is added to the database.
		 */
		Added = 'Added',

		/**
		 * Sent from Core when a Site is deleted from the database.
		 */
		Deleted = 'Deleted',

		/**
		 * Sent from Core when a Site is updated (at least via the API), in the database.
		 */
		Updated = 'Updated',

		CalendarEventAdded = 'CalendarEventAdded',
		CalendarEventDeleted = 'CalendarEventDeleted',
		CalendarEventUpdated = 'CalendarEventUpdated'
	}

	export enum WeatherSourceStatusChangeType {
		WeatherSourceValue = 'WeatherSourceValue',

		Added = 'Added',
		Deleted = 'Deleted',
		Updated = 'Updated',
	}

	export enum DryRunFailureReasonCode
	{
		NoError = 'NoError',
		AlreadyRunning = 'AlreadyRunning',
		Error = 'Error',           // Unknown errors
		NoSimulator = 'NoSimulator'		// No DryRun simulators found.
	};

	export enum ScheduledReportChangeType
	{
		Added = 'Added',
		Deleted = 'Deleted',
		Updated = 'Updated'
	};
}

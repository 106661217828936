<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="isGolfSite" class="logo-icon logo-icon-cirrus">
	<div *ngIf="showCirrusProLogo" >
		<button mat-button icon="dashboard" [routerLink]="['.']" data-qaid="header-nav-dashboard">
			<div class="logo-wrapper">
				<img class="logo cover cirruspro-logo" data-qa-id="logo" src="../../../../assets/images/cirrus_pro_logo_white.svg" alt="rb-logo"/>
			</div>
		</button>
	</div>
	<div *ngIf="!showCirrusProLogo" >
		<button mat-button icon="dashboard" [routerLink]="['.']" data-qaid="header-nav-dashboard">
			<div class="logo-wrapper">
				<img class="logo cover cirruspro-logo" data-qa-id="logo" src="../../../../assets/images/cirrus_pro_logo_white.svg" alt="rb-logo"/>
			</div>
		</button>
	</div>
</div>

<div *ngIf="!isGolfSite" class="logo-icon">
	<button mat-button icon="dashboard" [routerLink]="['.']" data-qaid="header-nav-dashboard">
		<div class="logo-wrapper">
			<img class="logo cover iq-logo" data-qa-id="logo" src="../../../../assets/images/iq4_logo.svg" alt="iq-logo"/>
		</div>
	</button>
</div>

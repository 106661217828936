import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { CourseViewActiveStation } from '../../../../api/activity/models/course-view-active-station.model';
import { CourseViewerStationBase } from '../course-viewer-station-base';
import { CourseViewStationData } from '../../../../api/activity/models/course-view-station-data.model';
import { CultureSettings } from '../../../../api/culture-settings/models/culture-settings.model';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { filter } from 'rxjs/internal/operators/filter';
import { MatDialog } from '@angular/material/dialog';
import { Note } from '../../../../api/sticky-notes/models/sticky-note.model';
import { NotePopupComponent } from '../../../../core/components/note-popup/note-popup.component';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../common/utils/_rb.utils';
import { StationListItem } from '../../../../api/stations/models/station-list-item.model';
import { StationManagerService } from '../../../../api/stations/station-manager.service';
import { StationWithMapInfoLeaflet } from '../../../../common/models/station-with-map-info-leaflet.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import CourseViewActivityType = RbEnums.Common.CourseViewActivityType;
import CourseViewData = RbEnums.Common.CourseViewData;

@Component({
	selector: 'rb-course-viewer-station',
	templateUrl: './course-viewer-station.component.html',
	styleUrls: ['./course-viewer-station.component.scss']
})
export class CourseViewerStationComponent extends CourseViewerStationBase implements OnInit, AfterViewInit, OnDestroy {
	// Subscriptions
	secondsRemainingChangeSub: Subscription;

	// Constants
	CourseViewData = CourseViewData;

	private readonly applicationOrRotationUpdateThresholdSeconds = 5;
	private applicationOrThresholdUpdateCount = 0;

	@Output() invokeCreateUser = new EventEmitter<any>(); // Invoke the Create User sidebar 

	@Input() uniqueValues: number[][] = [];
	@Input() showGradient = true;
	@Input() siteId;

	private _dataView = CourseViewData.Time;
	@Input() set dataView(value: CourseViewData) {
		this._dataView = value;
		// The this.station can be null at this point, so setupApplicationOrRotationSubscription may not get executed at this moment
		this.setupApplicationOrRotation();

		this.setDisplayValue();
	}

	get dataView(): CourseViewData {
		return this._dataView;
	}

	private _stationData: CourseViewStationData | CourseViewActiveStation;
	@Input() set stationData(stationData: CourseViewStationData | CourseViewActiveStation) {
		if (stationData == null) { return; }

		this._stationData = stationData;

		let station: CourseViewStationData | StationListItem;
		if (stationData instanceof CourseViewStationData) {
			station = stationData;
			this.runtimeInSeconds = station ? station.runningTimeInSeconds : null;
		} else if (stationData instanceof CourseViewActiveStation) {
			station = stationData.station;

			if (this.activityType === CourseViewActivityType.Active) {
				this.station = station;
				this.setApplicationOrRotation();
				if (this.dataView === CourseViewData.Rotation || this.dataView === CourseViewData.Application) {
					this.setupApplicationOrRotationSubscription();
				}

				this.tooltip = station.secondsRemaining > 0 ? RbUtils.Stations.secondsToHhMmSs(station.secondsRemaining) : '';
				this.setRunnableState();

				this.setupApplicationOrRotation();
				return;
			}
		}

		this.setDisplayValue();
	}

	get stationData(): CourseViewStationData | CourseViewActiveStation {
		return this._stationData;
	}

	get stationIsPaused(): boolean {
		return this.station?.status === this.translateService.instant('STRINGS.PAUSED');
	}

	runtimeInSeconds: number;
	displayValue: string;
	backgroundColor: any;
	isOverWater = false;
	isUnderWater = false;
	userCulture: CultureSettings;
	decimalSeparator: string;
	applicationOrRotation: string;

	isBusy = false;

	// ========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// ========================================================================================================================================================

	constructor(
		private authManager: AuthManagerService,
		protected dialog: MatDialog,
		private broadcastService: BroadcastService,
		protected deviceManager: DeviceManagerService,
		protected translateService: TranslateService,
		private stationManager: StationManagerService
	) {
		super(deviceManager, translateService);

		this.userCulture = this.authManager.userCulture;
		this.decimalSeparator = this.userCulture.decimalSeparator;
	}

	ngOnDestroy() {
		this.cancelApplicationOrRotationSubscription();
		super.ngOnDestroy();
	}

	// ========================================================================================================================================================
	// Public Methods
	// ========================================================================================================================================================

	setApplicationOrRotation(): string {
		this.applicationOrRotation = '';

		if (!(this.station instanceof StationListItem)) { return; }

		if (this.station.secondsRemaining <= 0 && !this.station.isActivelyCyclingOrSoaking) {
			this.applicationOrRotation = this.station.courseViewStatus;
			return;
		}
		if (this.station.secondsRemaining <= 0) { return; }

		if (this.dataView === CourseViewData.Application) {
			this.applicationOrRotation = (<CourseViewActiveStation>this.stationData).application;
			return;
		} else if (this.dataView === CourseViewData.Rotation) {
			this.applicationOrRotation = (<CourseViewActiveStation>this.stationData).passes;
			return;
		}

		return;
	}

	get dynamicTooltip(): string {
		if (this.activityType !== CourseViewActivityType.Active || !this.station || this.station.courseViewStatus === '') { return ''; }
		if (this.stationIsPaused) return '';
		if (this.station.secondsRemaining > 0) return RbUtils.Stations.secondsToHhMmSs(this.station.secondsRemaining);
		
		if (this.station.statusReason == null || this.station.statusReason === RbEnums.Common.StationFailureReasonCode.NoError
			|| this.station.irrigationStatus !== RbEnums.Common.IrrigationStatus.Pending) {
			return '';
		}

		return RbUtils.Stations.getStationStatusReasonCodeTooltipPlainText(
			this.station.name, 
			this.station.status,
			this.station.statusReason);
	}

	get isCycling(): boolean {
		if (this.station == null) { return false; }
		return this.station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running;
	}

	get isSoaking(): boolean {
		if (this.station == null) { return false; }
		return this.station.irrigationStatus === RbEnums.Common.IrrigationStatus.Soaking;
	}

	// ========================================================================================================================================================
	// Event Handlers
	// ========================================================================================================================================================

	onStationSearch() {
		this.broadcastService.showStationSearch.next(this.stationId);
	}

	onRunDiagnostic() {
		this.broadcastService.showStationDiagnostics.next([this.stationId]);
	}
	
	onShowNotes() {
		
		this.isBusy = true;
		const user = this.authManager.getUserProfile();

		const note: Note = {
			id: this.stationId,
			companyId: user.companyId,
			siteId: this.siteId,
			authorId: user.userId,
			latitude: 0,
			longitude: 0,
			content: '',
			isMinimized: true,
			notePriority: 1,
			noteType: 1,
			status: RbEnums.Note.NoteStatus.Active,
			attachedToType: RbEnums.Note.NoteAnchor.Station,
			isEditing: false,
			replies: []
		};

		this.stationManager.getStation(this.stationId).subscribe({
			next: (station: StationWithMapInfoLeaflet) => {

				this.isBusy = false;

				note.latitude = station.latitude;
				note.longitude = station.longitude;
				note.attachedToName = station.name;

				let dialogRef = this.dialog.open(NotePopupComponent, {
					width: '280px',
					maxWidth: '90%',
					data: { note, user },
					closeOnNavigation: true,
					hasBackdrop: true,
					panelClass: 'rb-note-panel'
				});
		
				dialogRef.afterClosed().subscribe((data) => {
					dialogRef = null;
				});
				dialogRef.componentInstance.invokeCreateUser.subscribe((event) => this.invokeCreateUser.emit(event));
			},
			error: (e) => {
				this.isBusy = false;
				throw e;
			},
		})
	}

	// ========================================================================================================================================================
	// Helper Methods
	// ========================================================================================================================================================
	private setupApplicationOrRotation() {
		// since this.station has been set upto this point, call for setupApplicationOrRotationSubscription
		if ((this._dataView === CourseViewData.Rotation || this._dataView === CourseViewData.Application) && this.station != null) {
			this.setupApplicationOrRotationSubscription();
		} else {
			this.cancelApplicationOrRotationSubscription();
		}
	}

	private setDisplayValue() {
		// No time
		if (!this.runtimeInSeconds) {
			this.displayValue = '';
			this.tooltip = null;
			this.backgroundColor = 'white';
			return;
		}

		if (this.dataView === CourseViewData.Time) {
			if (this.runtimeInSeconds >= 60) {
				// Minutes
				const minutes = Math.round(this.runtimeInSeconds / 60);
				this.displayValue = minutes.toString();
				// this.setBackgroundColor(minutes);
				this.setSingleColorBackgroundColor(minutes);
			} else {
				// Seconds
				this.displayValue = `${this.runtimeInSeconds} s`;
				// this.setBackgroundColor(1);
				this.setSingleColorBackgroundColor(1);
			}
		} else if (this.stationData instanceof CourseViewStationData) {
			// CourseViewStationListItem objects will dynamically update their application and rotation values.
			if (this.dataView === CourseViewData.Application) {
				this.displayValue = this.stationData.application;
			} else if (this.dataView === CourseViewData.Rotation) {
				this.displayValue = this.stationData.passes;
			}
			this.setSingleColorBackgroundColor(+this.displayValue);
		}

		this.tooltip = RbUtils.Stations.secondsToHhMmSs(this.runtimeInSeconds);
	}

	// private setBackgroundColor(minutes: number) {
	// 	const indexOfValue = this.uniqueValues.indexOf(minutes);
	// 	const medianIndex = this.uniqueValues.length / 2;
	//
	// 	// If we have a true middle value set its color to white.
	// 	if (medianIndex > 1 && medianIndex % 2 > 0 && indexOfValue === Math.trunc(medianIndex)) {
	// 		this.backgroundColor = 'white';
	// 		return;
	// 	}
	//
	// 	const gradientBasis = 1 / Math.trunc(medianIndex);
	// 	const aValue =
	// 		Math.abs((indexOfValue < medianIndex) ? 1 - (indexOfValue * gradientBasis) : 1 - ((this.uniqueValues.length - (indexOfValue + 1)) * gradientBasis));
	// 	const rootColor = indexOfValue < medianIndex ? this.LOW_BACKGROUND_COLOR : this.HIGH_BACKGROUND_COLOR;
	//
	// 	this.backgroundColor = `rgba(${rootColor[0]}, ${rootColor[1]}, ${rootColor[2]}, ${aValue})`;
	// }

	private setSingleColorBackgroundColor(value: number) {
		if (this.uniqueValues.length <= 0) return;

		const indexOfValue = this.uniqueValues[this.dataView].indexOf(value);
		if (indexOfValue < 0) return;

		const gradientBasis = 1 / this.uniqueValues[this.dataView].length;
		const aValue = gradientBasis * (indexOfValue + 1);
		const rootColor = this.HIGH_BACKGROUND_COLOR;

		this.backgroundColor = `rgba(${rootColor[0]}, ${rootColor[1]}, ${rootColor[2]}, ${aValue})`;
	}

	private setupApplicationOrRotationSubscription() {
		// Subscribe to the StationListItem::secondsRemainingChange event to allow us to 'efficiently' update the rotation or application value.
		// Because calculating the rotation or application value is relatively expensive, we throttle the updates to only occur every
		// applicationOrRotationUpdateThresholdSeconds.
		this.cancelApplicationOrRotationSubscription();
		this.secondsRemainingChangeSub = this.station.secondsRemainingChange
			.pipe(filter((stationInfo: {stationId: number}) => stationInfo.stationId === this.stationId))
			.subscribe(() => {
				if (this.applicationOrThresholdUpdateCount++ % this.applicationOrRotationUpdateThresholdSeconds !== 0) { return; }
				this.setApplicationOrRotation();
			});
	}

	private cancelApplicationOrRotationSubscription() {
		this.applicationOrThresholdUpdateCount = 0;
		if (this.secondsRemainingChangeSub) { this.secondsRemainingChangeSub.unsubscribe(); }
	}
}

<div class="site-and-controllers-list">
	<div *ngIf="reportName.length > 0" class="report-name-section">
		<div class="report-name-label">{{'STRINGS.REPORT_NAME' | translate}}*</div>
		<div class="report-name">{{reportName}}
			<span *ngIf="startDateString.length > 0 && endDateString.length > 0"> -
				<span class="date">{{startDateString}}</span> -
				<span class="date">{{endDateString}}</span>
			</span>
			<span *ngIf="monthString.length > 0" class="date"> - {{monthString}}</span>
		</div>
		<hr />
	</div>

	<div *ngFor="let site of sitesAndControllerList">
		<div class="site">
			{{site.name}}
		</div>

		<div *ngFor="let controller of site.controllers" class="controller" [class.selected]="selectedController.name === controller.name && selectedController.id === controller.id"
		(click)="onControllerClicked(controller)">
			{{controller.name}}
		</div>
	</div>
</div>

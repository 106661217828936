import { XXUseRbEnumsNamespace } from '../../../common/enumerations/rb-common.enums';
import FlowSensorModelType = XXUseRbEnumsNamespace.Common.FlowSensorModelType;

export class FlowSensorModel {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}

	name: string;
	value: FlowSensorModelType;
}

import { Injectable, OnDestroy } from '@angular/core';
import { ConnectDataPack } from '../connect-data-pack/models/connect-data-pack.model';
import { ControlRequestError } from './models/control-request-error.model';
import { ControlRequestState } from './models/control-request-state.model';
import { ManualControlDict } from './models/manual-control-dict.model';
import { ManualControlState } from './models/manual-control-state.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ManualControlManagerService implements OnDestroy {

	// Subjects (events)
	manualControlStateChange = new Subject<ManualControlState>();
	isConnecting = new Subject<number>();
	manualConnectFailed = new Subject<number>();

	private manualControlDict: ManualControlDict = {};

	// =========================================================================================================================================================
	// C'tor and Destroy
	// =========================================================================================================================================================

	constructor() {}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() **/
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get isManualControlDictEmpty(): boolean {
		return !this.manualControlDict || Object.keys(this.manualControlDict).length < 1;
	}

	getManualControlState(controllerId: number): ManualControlState {
		const manualControlState = this.manualControlDict[controllerId];

		if (!manualControlState || manualControlState.isStale) return null;

		return manualControlState;
	}

	initOrAddToControllersDictionary(controllerId: number, connectDataPack?: ConnectDataPack) {
		let manualControlState = this.manualControlDict[controllerId];

		try {
			// If our dictionary already contains an entry for the given controllerId, update it.
			if (manualControlState) {
				console.log(`initOrAddToControllersDictionary(${controllerId}): manualControlState exists. Updating state from data pack.`);

				// Update the copied manualControlState with values from the controller object passed in.
				// manualControlState.updateStateFromController(controller);
				manualControlState.updateStateFromConnectDataPack(connectDataPack);
				return;
			}

			// If our dictionary does not contain an entry for the given controllerId, add it.
			manualControlState = new ManualControlState(controllerId, connectDataPack);
			this.manualControlDict[controllerId] = manualControlState;

			console.log(`initOrAddToControllersDictionary(${controllerId}): new manualControlState created. Updating state from data pack.`);

			// Send it, too, or we'll miss the first instance every time we don't have a ManualControlState yet.
			// RB-6203: We were missing the first DataPack message for a client controller being connected.
			manualControlState.updateStateFromConnectDataPack(connectDataPack);
		} finally {
			console.log(`initOrAddToControllersDictionary(${controllerId}): sending manualControlStateChange.next()`);

			this.manualControlStateChange.next(manualControlState);
		}
	}

	/**
	 * @summary This method is called during start/complete processing for various manual operations (set rain delay, etc.)
	 * @param requestState - ControlRequestState describing what is being set (RainDelayDays, etc.) and what the status of
	 * that set operation is (updating, etc.)
	 */
	setControlItemStatus(requestState: ControlRequestState) {
		let manualControlState = this.manualControlDict[requestState.controllerId];

		// If one does not already exist, create a new, temporary one to allow for notification to interested parties.
		// This can occur, among other reasons, if rain delay is update via the Tbos Settings Panel.
		if (!manualControlState) manualControlState = new ManualControlState(requestState.controllerId, null);

		// Get the property representing the indicated property (RainDelayDays, for example).
		const controlStateProperty = manualControlState[requestState.property];
		controlStateProperty.error = null;
		controlStateProperty.isUpdating = requestState.isUpdating;

		// If the new state info has a value, replace the existing .value. If the incoming status item has no property
		// value, keep the existing value.
		controlStateProperty.value = (requestState.value !== undefined) ? requestState.value : controlStateProperty.value;

		// Send the new info to any interested parties.
		this.manualControlStateChange.next(manualControlState);
	}

	setControlItemError(requestError: ControlRequestError) {
		let manualControlState = this.manualControlDict[requestError.controllerId];

		if (manualControlState === undefined && requestError.property === RbEnums.SignalR.ManualControlProperty.IsConnecting
			&& requestError.error) {
			manualControlState = new ManualControlState(requestError.controllerId, null);
			manualControlState.isConnecting.isUpdating = false;
			manualControlState.isConnecting.error = requestError.error;
			this.manualControlStateChange.next(manualControlState);
			return;
		}

		if (!manualControlState) return;

		const controlStateProperty = manualControlState[requestError.property];
		controlStateProperty.isUpdating = false;
		controlStateProperty.error = requestError.error;

		this.manualControlStateChange.next(manualControlState);
	}

	clearControlItem(controllerId: number) {
		delete this.manualControlDict[controllerId];
	}

}

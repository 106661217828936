import * as moment from 'moment';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ManualOpsManagerService } from '../../../api/manual-ops/manual-ops-manager.service';
import { MessageBoxService } from '../../../common/services/message-box.service';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StartStationModel } from '../../../api/manual-ops/models/start-station.model';

@Component({
	selector: 'rb-start-station-modal',
	templateUrl: './start-station-modal.component.html',
	styleUrls: ['./start-station-modal.component.scss'],
})
export class StartStationModalComponent implements OnInit {
	@HostBinding('class') class = 'rb-start-station-modal';

	@Output() stationStartRequested = new EventEmitter();

	@Input() baseRunTimeTicks: number;
	@Input() controllerId: number;
	@Input() isGolfController: boolean;
	@Input() isProgramStepStation: boolean;
	@Input() programId: number;
	@Input() selectedItemIds: Array<number>;
	@Input() selectedStationIds: Array<number>;
	@Input() maxHours = RbConstants.Form.DURATION_HOURS.twelveHrs;
	@Input() station;

	initBaseRunTime: moment.Duration;
	seconds: number;

	// =========================================================================================================================================================
	// C'tor and Lifecycle hooks
	// =========================================================================================================================================================

	constructor(private manualOpsManager: ManualOpsManagerService,
				private messageBoxService: MessageBoxService) { }

	ngOnInit() {
		this.initBaseRunTime = this.getInitBaseRunTime();
		if (this.maxHours == null) this.maxHours = RbConstants.Form.DURATION_HOURS.twelveHrs;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	startStations(runtime: moment.Duration) {
		if (!runtime) {
			return;
		}
		const seconds: number[] = [];
		if (this.selectedStationIds) {
			this.selectedStationIds.forEach(element => {
				seconds.push(runtime.asSeconds());
			});
		}
		this.manualOpsManager.startStations(new StartStationModel(this.selectedStationIds, seconds))
			.subscribe(response => {
				this.stationStartRequested.emit();
			}, error => {
				this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED');
			});
		this.seconds = runtime.asSeconds();
	}

	getInitBaseRunTime(): moment.Duration {
		return RbUtils.Conversion.convertTicksToHMSObject(this.baseRunTimeTicks);
	}
}

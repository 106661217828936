import { ApiService, HttpMethod } from '../_common/api.service';

import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { ETAdjustType } from './models/et-adjust-type.model';
import { ETCheckbookUpdate } from './models/et-checkbook-update';
import { GetProgramQueryParams } from './models/get-program-params.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Program } from './models/program.model';
import { ProgramETInformation } from './models/program-et-information.model';
import { ProgramListItem } from './models/program-list-item.model';
import { ProgramScheduledTime } from './models/program-scheduled-time.model';
import { ScheduledProgram } from './models/scheduled-program.model';
import { ScheduledProgramTreeViewItem } from './models/scheduled-program-treeview.model';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';


@Injectable({
	providedIn: 'root'
})
export class ProgramApiService extends ApiService {
	clearCache() {
		super.clearCache(this.programsListUrl);
		super.clearCache(`${this.baseUrl}/GetPrograms?satelliteId=`);
	}

	// =========================================================================================================================================================
	// API Calls - Public
	// =========================================================================================================================================================

	addProgramToProgramGroup(programId: number, programGroupId: number): Observable<void> {
		return this.apiRequest<any>(this.addProgramToProgramGroupUrl(programId, programGroupId), HttpMethod.Put);
	}

	createProgram(programUpdate: any): Observable<Program> {
		return this.apiRequest<any>(this.createProgramUrl, HttpMethod.Post, programUpdate);
	}

	deletePrograms(programIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.deleteProgramsUrl, HttpMethod.Delete, programIds);
	}

	getEtAdjustTypes(): Observable<ApiCachedRequestResponse<ETAdjustType[]>> {
		return this.apiRequestWithCache<any>(this.getEtAdjustTypesUrl(), false, result => result.map(json => new ETAdjustType(json)));
	}

	getNameUniqueness(name: string, satelliteId: number, programGroupId: number, programId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNameUniquenessUrl(name, satelliteId, programGroupId, programId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getNumberUniqueness(number: number, programGroupId: number, programId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNumberUniquenessUrl(number, programGroupId, programId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getNextStartTime(): Observable<string> {
		return this.apiRequestWithCache<any>(this.getNextStartTimeUrl(), true)
			.pipe(map(response => response.value.stringValue));
	}

	getScheduledStartTimes(controllerId: number): Observable<ProgramScheduledTime[]> {
		return this.apiRequest<any>(this.getScheduledStartTimesUrl(controllerId), HttpMethod.Get);
	}

	getProgram(id: number, queryParams: GetProgramQueryParams, bypassCache = false): Observable<Program> {
		return this.apiRequest<any>(this.getProgramUrl(id, queryParams), HttpMethod.Get, null, null, bypassCache)
			.pipe(map(program => new Program(program)));
	}

	getProgramSimpleETInformation(id: number, ): Observable<ProgramETInformation> {
		return this.apiRequest<any>(this.getProgramSimpleETInformationUrl(id), HttpMethod.Get)
			.pipe(map(programEt => new ProgramETInformation(programEt)));
	}

	getProgramSiteSeasonalAdjustInformation(siteId: number): Observable<any> {
		return this.apiRequest<any>(this.getSiteSeasonalAdjustInformationUrl(siteId), HttpMethod.Get)
			.pipe(map(programEt => new ProgramETInformation(programEt)));
	}

	getProgramsList(bypassCache: boolean = false): Observable<ApiCachedRequestResponse<ProgramListItem[]>> {
		return this.apiRequestWithCache<any>(this.programsListUrl, bypassCache, result => result.map(p => new ProgramListItem(p)));
	}

	// RB-13990: Method to get a single ProgramListItem. DO NOT CACHE THIS CALL. The purpose of this method is to retrieve
	// a single ProgramListItem in response to a SignalR (or potentially other event) where we know a single ProgramListItem
	// has changed, but we only know its Id. The value returned from this method will be used to update the ProgramListItem cached collection.
	getUncachedProgramsListForController(controllerId: number, bypassCache: boolean = false): Observable<ProgramListItem[]> {

		// RB-14643: no cache for this API to get the latest data
		return this.apiRequest<any>(this.getProgramsListForControllerUrl(controllerId), HttpMethod.Get, null, null, bypassCache)
			.pipe(map(programs => programs.map(p => new ProgramListItem(p))));
	}

	getPrograms(controllerId: number, bypassCache: boolean = false): Observable<ApiCachedRequestResponse<Program[]>> {
		return this.apiRequestWithCache<any>(this.getProgramsUrl(controllerId), bypassCache, result => result.map(p => new Program(p)));
	}

	getScheduledPrograms(siteIds: Array<number>, hoursAhead: number): Observable<ScheduledProgram[]> {
		return this.apiRequest<any>(this.getScheduledProgramsUrl(hoursAhead), HttpMethod.Post, siteIds)
			.pipe(map((schedulePrograms: ScheduledProgram[]) => schedulePrograms.map(program => new ScheduledProgram(program))));
	}

	getScheduledProgramsTreeViewForMultiSites(siteIds: Array<number>, hoursAhead: number): Observable<ScheduledProgramTreeViewItem[]> {
		return this.apiRequest<any>(this.getScheduledProgramsTreeViewForMultiSitesURL(hoursAhead), HttpMethod.Post, siteIds)
			.pipe(map((schedulePrograms: any[]) => schedulePrograms.map(program => new ScheduledProgramTreeViewItem(program))));
	}

	updatePrograms(programIds: number[], programUpdate: object): Observable<null> {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch,
			{ ids: programIds, patch: this.patchTransform(programUpdate) });
	}

	addEtCheckbookForPrograms(etChecbookUpdate: ETCheckbookUpdate){
		return this.apiRequest<any>(this.addEtCheckbookForProgramsUrl(), HttpMethod.Post, etChecbookUpdate);
	}

	getProgramsForWeatherSource(id: number) {
		return this.apiRequest<any>(this.getProgramsForWeatherSourceUrl(id), HttpMethod.Get);
	}

	checkProgramHasStationInOtherAdvancedEtPrograms(programId: number): Observable<boolean> {
		return this.apiRequest<any>(this.checkProgramHasStationInOtherAdvancedEtProgramsUrl(programId), HttpMethod.Get);
	}

	// =========================================================================================================================================================
	// Methods for updating and returning cached collections
	// =========================================================================================================================================================

	updateAndReturnCachedProgramListItems(updatedItems: ProgramListItem[]) : ProgramListItem[] {
		return this.updateAndReturnCachedCollection<ProgramListItem>(updatedItems, 'id', this.programsListUrl);
	}

	// =========================================================================================================================================================
	// URLs - Private
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}Program`; }
	private get seasonalAdjustBaseUrl(): string { return `${this.baseApiUrl}SeasonalAdjust`; }

	private addProgramToProgramGroupUrl(programId: number, programGroupId: number): string {
		return `${this.baseUrl}/AddProgramToGroup?programGroupid=${programGroupId}&programId=${programId}`;
	}

	private get createProgramUrl(): string { return `${this.baseUrl}/CreateProgram`; }

	private get deleteProgramsUrl(): string { return `${this.baseUrl}/DeletePrograms`; }

	private getEtAdjustTypesUrl(): string { return `${this.baseUrl}/GetProgramETAdjustTypes`; }

	private getNameUniquenessUrl(name: string, satelliteId: number, programGroupId: number, programId: number): string {
		return `${this.baseUrl}/IsNameUnique?programName=${encodeURIComponent(name)}`
			+ `&satelliteId=${satelliteId}&programGroupId=${programGroupId}&programId=${programId}`;
	}

	private getNumberUniquenessUrl(number: number, programGroupId: number, programId: number): string {
		return `${this.baseUrl}/IsNumberUnique?programNumber=${number}`
			+ `&programGroupId=${programGroupId}&programId=${programId}`;
	}

	private getNextStartTimeUrl(): string { return `${this.baseUrl}/GetNextStartTime`; }

	private get programsListUrl() { return `${this.baseUrl}/GetProgramListForMultiSites`; }

	private getProgramsListForControllerUrl(controllerId: number) { return `${this.baseUrl}/GetProgramList?satelliteId=${controllerId}`; }

	private getProgramsUrl(controllerId: number) { return `${this.baseUrl}/GetPrograms?satelliteId=${controllerId}`; }

	private getProgramUrl(id: number, queryParams: GetProgramQueryParams): string {
		let queryString = queryParams ? queryParams.queryString : '';
		queryString += (queryString.length > 0) ? `&programId=${id}` : `?programId=${id}`;
		return `${this.baseUrl}/GetProgram${queryString}`;
	}

	private getScheduledStartTimesUrl(controllerId: number): string {
		return `${this.baseUrl}/GetScheduledStartTimes?satelliteId=${controllerId}`;
	}

	private getProgramSimpleETInformationUrl(id: number): string {
		return `${this.baseUrl}/GetProgramSimpleETInformation?programId=${id}`;
	}

	private getSiteSeasonalAdjustInformationUrl(siteId: number): string {
		return `${this.seasonalAdjustBaseUrl}/GetSeasonalAdjustForSite?siteId=${siteId}`;
	}

	private checkProgramHasStationInOtherAdvancedEtProgramsUrl(programId: number): string {
		return `${this.baseUrl}/CheckProgramHasStationInOtherAdvancedEtPrograms?programId=${programId}`;
	}

	private getScheduledProgramsUrl(hoursAhead: number): string {
		return `${this.baseUrl}/GetScheduledProgramsForMultiSites_V2?hoursAhead=${hoursAhead}`;
	}

	private getScheduledProgramsTreeViewForMultiSitesURL(hoursAhead: number): string {
		return `${this.baseUrl}/GetScheduledProgramsTreeViewForMultiSites?hoursAhead=${hoursAhead}`;
	}

	private addEtCheckbookForProgramsUrl(): string {
		return `${this.baseUrl}/AddEtCheckbookForPrograms`;
	}

	private get updateBatchesUrl(): string { return `${this.baseUrl}/UpdateBatches`; }

	private getProgramsForWeatherSourceUrl(id: number): string { return `${this.baseUrl}/GetProgramsForWeatherSource?weatherSourceId=${id}`; }
}

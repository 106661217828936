<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="header">
	<p class="f6 mr0">{{title}}</p>
	<div class="flex center f2">
		<input *ngIf="!hideHours"
			class="time-input-hours"
			matInput
			name="headerHours"
			[ngModel]="durationHours"
			(focus)="$event.target.select()"
		/>
		<span matSuffix *ngIf="!hideMinutes">:</span>
		<input *ngIf="!hideMinutes"
			class="time-input-minutes"
			matInput
			name="headerMinutes"
			[ngModel]="getDurationMinutes(durationMinutes)"
			(focus)="$event.target.select()"
		/>
		<span matSuffix *ngIf="!hideSeconds">:</span>
		<input *ngIf="!hideSeconds"
			class="time-input-seconds"
			matInput
			name="headerSeconds"
			[ngModel]="getDurationSeconds(durationSeconds)"
			(focus)="$event.target.select()"
		/>
	</div>
</div>
<div class="flex">
	<div class="textWidth pt3 mr4" *ngIf="!hideHours">
		<mat-form-field>
			<input
				matInput
				[placeholder]="'STRINGS.HOURS' | translate"
				min="0"
				[max]="maxHours"
				oninput="validity.valid||(value=max)"
				onkeypress='return event.charCode >= 48 && event.charCode <= 57'
				type="number"
				name="hoursText"
				#hoursText="ngModel"
				[ngModel]="durationHours"
				(ngModelChange)="handleInputChanged($event, 'hours')"
				(focus)="$event.target.select()"
			/>
		</mat-form-field>
	</div>
	<div class="pt4" *ngIf="!hideHours">
		<span matSuffix>{{RbFormConstants.DURATION_HOURS.min}}</span>
		<mat-slider
			color="primary"
			[thumbLabel]="true"
			[min]="RbFormConstants.DURATION_HOURS.min"
			[max]="maxHours"
			[ngModel]="durationHours"
			(ngModelChange)="handleInputChanged($event, 'hours')"
			name="hoursSlider"
			[step]="RbFormConstants.STEP"
		>
		</mat-slider>
		<span matSuffix>{{maxHours}}</span>
	</div>
</div>
<div class="flex">
	<div class="textWidth pt3 mr4" *ngIf="!hideMinutes">
		<mat-form-field>
			<input
				matInput
				[max]="RbFormConstants.DURATION_MINUTES.max"
				[min]="RbFormConstants.DURATION_MINUTES.min"
				oninput="validity.valid||(value=max);"
				onkeypress='return event.charCode >= 48 && event.charCode <= 57'
				[placeholder]="'STRINGS.MIN' | translate"
				type="number"
				name="minsText"
				#minsText="ngModel"
				[ngModel]="getDurationMinutes(durationMinutes)"
				(ngModelChange)="handleInputChanged($event, 'minutes')"
				[disabled]="durationHours >= maxHours"
				(focus)="$event.target.select()"
			/>
		</mat-form-field>
	</div>
	<div class="pt4 mr1" *ngIf="!hideMinutes">
		<span matSuffix>{{RbFormConstants.DURATION_MINUTES.min}}</span>
		<mat-slider
			color="primary"
			[thumbLabel]="true"
			[max]="RbFormConstants.DURATION_MINUTES.max"
			[min]="RbFormConstants.DURATION_MINUTES.min"
			name="minsSlider"
			[displayWith]="formatLabel"
			[ngModel]="durationMinutes"
			(ngModelChange)="handleInputChanged($event, 'minutes')"
			[disabled]="durationHours >= maxHours"
			[step]="RbFormConstants.STEP"
		>
		</mat-slider>
		<span matSuffix>{{RbFormConstants.DURATION_MINUTES.max}}</span>
	</div>
</div>
<div class="flex">
	<div class="textWidth pt3 mr4" *ngIf="!hideSeconds">
		<mat-form-field>
			<input
				matInput
				[max]="RbFormConstants.DURATION_SECONDS.max"
				[min]="RbFormConstants.DURATION_SECONDS.min"
				[placeholder]="'STRINGS.SEC' | translate"
				type="number"
				name="secText"
				#secText="ngModel"
				oninput="validity.valid||(value=max);"
				onkeypress='return event.charCode >= 48 && event.charCode <= 57'
				[ngModel]="getDurationSeconds(durationSeconds)"
				(ngModelChange)="handleInputChanged($event, 'seconds')"
				[disabled]="durationHours >= maxHours"
				(focus)="$event.target.select()"
			/>
		</mat-form-field>
	</div>
	<div class="pt4" *ngIf="!hideSeconds">
		<span matSuffix>{{RbFormConstants.DURATION_SECONDS.min}}</span>
		<mat-slider
			color="primary"
			[thumbLabel]="true"
			[max]="RbFormConstants.DURATION_SECONDS.max"
			[min]="RbFormConstants.DURATION_SECONDS.min"
			name="secSlider"
			[displayWith]="formatLabel"
			[ngModel]="durationSeconds"
			(ngModelChange)="handleInputChanged($event, 'seconds')"

			[disabled]="durationHours >= maxHours"
			[step]="RbFormConstants.STEP"
		>
		</mat-slider>
		<span matSuffix>{{RbFormConstants.DURATION_SECONDS.max}}</span>
	</div>
</div>

export class WorldWeatherArea {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.areaName) this.areaName = (json.areaName.length > 0) ? json.areaName[0].value : null;
			if (json.region) this.region = (json.region.length > 0) ? json.region[0].value : null;
			if (json.country) this.country = (json.country.length > 0) ? json.country[0].value : null;
		}
	}

	areaName: string = null;
	region: string = null;
	country: string = null;

	get location(): string {
		let loc = '';

		if (this.areaName) loc = this.areaName;

		if (this.region) {
			if (loc.length > 0) loc += ', ';
			loc += this.region;
		}

		if (this.country) {
			if (loc.length > 0) loc += '  ';
			loc += this.country;
		}

		return loc;
	}
}

import { Component, Injector, OnInit } from '@angular/core';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-mobile-cell-renderer',
	templateUrl: './base-mobile-cell-renderer.component.html',
	styleUrls: [ './base-mobile-cell-renderer.component.scss' ]
})
export class BaseMobileCellRendererComponent implements OnInit, ICellRendererAngularComp {

	header: string;
	isGolfSite = false;
	protected params: any;

	protected authManager: AuthManagerService;
	protected translate: TranslateService;

	constructor(private baseInjector: Injector) {
		this.authManager = this.baseInjector.get(AuthManagerService);
		this.translate = this.baseInjector.get(TranslateService);
	}

	ngOnInit() {
	}

	agInit(params: any): void {
		this.params = params;
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		this.header = this.isGolfSite ? this.translate.instant('STRINGS.SCHEDULES_NOUN'): this.translate.instant('STRINGS.PROGRAMS');
	}

	refresh(params: any): boolean {
		return false;
	}
}

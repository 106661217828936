<mat-form-field *ngIf="useMatFormField">
	<input matInput [ngxTimepicker]="timepicker" [format]="format"
		[ngModel]="timePickerValue | date: timePickerDisplay"
		[placeholder]="name"
		[disabled]="isReadOnly"
		(ngModelChange)="selectChanged($event)"
		(focus)="$any($event.target).select()"/>
	<mat-hint *ngIf="hintText">{{hintText}}</mat-hint>
</mat-form-field>

<div *ngIf="!useMatFormField">
	<div class="rb-input-shield-host">
		<input class="itp-time-input"
			[ngxTimepicker]="timepicker" [format]="format"
			[ngModel]="timePickerValue | date: timePickerDisplay"
			[placeholder]="name"
			(ngModelChange)="selectChanged($event)"
			(focus)="$any($event.target).select()"/>
		<mat-hint *ngIf="hintText">{{hintText}}</mat-hint>
		<div class="rb-input-shield--w-pointer" (click)="timepicker.open()" attr.data-qaid="timepicker-open-{{qaidLabel | replaceWhitespace: '_' }}"></div>
	</div>
</div>

<ngx-material-timepicker
	#timepicker
	(timeSet)="onTimeUpdate($event)"
	[cancelBtnTmpl]="cancelBtn"
	[confirmBtnTmpl]="confirmBtn"
	(opened)="onStartTimeOpened()"
	(closed)="onStartTimeClosed()"
>
</ngx-material-timepicker>

<ng-template #cancelBtn>
	<button
		mat-button
		type="button"
		class="rb-secondary-button mat-primary"
		data-qaid="dialog-cancel-button"
	>
		{{ 'STRINGS.CANCEL' | translate }}
	</button>
</ng-template>
<ng-template #confirmBtn>
	<button
		mat-raised-button
		class="rb-primary-button mat-primary"
		data-qaid="dialog-submit-button"
	>
		{{ 'STRINGS.OK' | translate }}
	</button>
</ng-template>

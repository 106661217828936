import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'rb-select-batch-options-dialog',
	templateUrl: './select-batch-options-dialog.component.html',
	styleUrls: [ './select-batch-options-dialog.component.scss' ]
})
export class SelectBatchOptionsDialogComponent implements OnInit {

	@Input() batchOptions: any[] = [];

	@Output() complete = new EventEmitter<{ selectedOptions: any[] }>();

	constructor() { }

	ngOnInit() {
	}

	onBatchEditCanceled() {
		this.complete.emit({ selectedOptions: [] });
		// this.ngGrid.unSelectAll();
	}

	onBatchEditSubmitted() {
		const selectedOptions = this.batchOptions.filter(opt => opt.selected);
		if (selectedOptions.length === 0) {
			this.onBatchEditCanceled();
			return;
		}

		this.complete.emit({ selectedOptions: selectedOptions });
	}
}

import { Observable, Subject } from 'rxjs';
import { BroadcastService } from "../../common/services/broadcast.service";
import { Controller } from '../controllers/models/controller.model';
import { Injectable } from '@angular/core';
import { RadioRelay } from "./models/radio-relay.model";
import { RadioRelayChange } from "../signalR/radio-relay-change.model";
import { RbEnums } from "../../common/enumerations/_rb.enums";
import { ServiceManagerBase } from "../_common/service-manager-base";
import { TbosNetwork } from "./models/tbos-network.model";
import { TbosService } from "./tbos.service";


@Injectable({
	providedIn: 'root'
})
export class TbosManagerService extends ServiceManagerBase {

	/**
	 * radioRelayListChange will be fired when one or more radio relays in the system have been changed as in
	 * created, deleted, or updated. This event will be fired when we learn of the change. Clients should request
	 * any items they need from the manager, not setting bypassCache; the manager will handle invalidating any
	 * cache items needing update. The changed items in the list are specified by RadioRelayChange[] parameter.
	 * The clients can decide from this if they should reload the object from the API, patch the local object
	 * with the list of changes indicated by a RadioRelayChange, or reload the whole list. If the value passed
	 * is null, the clients should reload the whole radio relay list, if they need it.
	 */
	radioRelayListChange = new Subject<RadioRelayChange[]>();

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private tbosService: TbosService,
				protected broadcastService: BroadcastService
	) {
		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getTbosNetwork(companyId: number, controllerId: number): Observable<TbosNetwork[]> {
        return this.tbosService.getTbosNetwork(companyId, controllerId);
    }

    updateRadioRelays(radioRelayIds: number[], radioRelayUpdate: object): Observable<null> {
		return this.tbosService.updateRadioRelays(radioRelayIds, radioRelayUpdate);
	}

    learnTbosNetwork(satelliteId: number, learningType: RbEnums.Common.TbosNetworkLearningType, connectionLifetime?: number) {
		return this.tbosService.learnTbosNetwork(satelliteId, learningType, connectionLifetime);
	}

	getRadioRelay(radioRelayId: number): Observable<RadioRelay> {
		return this.tbosService.getRadioRelay(radioRelayId);
	}

	getServerSatellite(controllerId: number): Observable<Controller> {
		return this.tbosService.getServerSatellite(controllerId);
	}

	clearAlarms(satelliteId: number) {
		return this.tbosService.clearAlarms(satelliteId);
	}

	clearGlobalFlowCount(tbosId: number): Observable<any> {
		return this.tbosService.clearGlobalFlowCount(tbosId);
	}

	createRadioRelayPatchObject(change: RadioRelayChange) {
		if (change.itemsChanged == null || change.itemsChanged.patch == null) return;

		const updateObject = {};
		change.itemsChanged.patch.forEach(item => {
			if (item.op === 'replace') updateObject[item.path.replace('/', '')] = item.value;
		});
		return updateObject;
	}
}

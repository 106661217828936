import { MapService } from '../services/map.service';
import { Observable } from 'rxjs';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from './_rb.utils';
import { WeatherConditionIcon } from '../../api/weather/models/weather-condition-icon.model';
import { WeatherConditionInfo } from '../../api/weather/models/weather-condition-info.model';
import { WeatherData } from '../../api/weather-data/models/weather-data.model';
import { XXUseRbEnumsNamespace } from '../enumerations/rb-common.enums';

export namespace XXUseRbUtilsNamespace {

	import CountryCode = XXUseRbEnumsNamespace.Common.CountryCode;
	import WeatherAttribute = RbEnums.Common.WeatherAttribute;
	
	export abstract class Weather {

		private static readonly rainColor = '#2E86C1';
		private static readonly sunColor = '#F39C12';
		private static readonly cloudColor = 'slategrey';
		private static readonly sleetColor = 'slategrey';
		private static readonly hailColor = 'slategrey';
		private static readonly snowColor = 'slategrey';

		static getWeatherConditionInfo(conditionCode: number): WeatherConditionInfo {
			switch (conditionCode) {
				case 113:
					// 'Clear/Sunny' wsymbol_0001_sunny	wsymbol_0008_clear_sky_night
					return new WeatherConditionInfo('WEATHER.CLEAR_SUNNY',
						new WeatherConditionIcon('climacon-sun', Weather.sunColor));
				case 116:
					// 'Partly Cloudy' wsymbol_0002_sunny_intervals	wsymbol_0041_partly_cloudy_night
					return new WeatherConditionInfo('WEATHER.PARTLY_CLOUDY',
						new WeatherConditionIcon('climacon-cloud-sun', Weather.sunColor, 'climacon-cloud', Weather.cloudColor));
				case 119:
					// 'Cloudy' wsymbol_0003_white_cloud	wsymbol_0044_mostly_cloudy_night
					return new WeatherConditionInfo('WEATHER.CLOUDY',
						new WeatherConditionIcon('climacon-cloud', Weather.cloudColor));
				case 122:
					// 'Overcast' wsymbol_0004_black_low_cloud	wsymbol_0042_cloudy_night~
					return new WeatherConditionInfo('WEATHER.OVERCAST',
						new WeatherConditionIcon('climacon-cloud', Weather.cloudColor));
				case 143:
					// 'Mist' wsymbol_0006_mist	wsymbol_0063_mist_night
					return new WeatherConditionInfo('WEATHER.MIST',
						new WeatherConditionIcon('climacon-cloud', Weather.cloudColor));
				case 176:
					// 'Patchy rain nearby' wsymbol_0009_light_rain_showers	wsymbol_0025_light_rain_showers_night
					return new WeatherConditionInfo('WEATHER.PATCHY_RAIN',
						new WeatherConditionIcon('climacon-rain-sun', Weather.sunColor, 'climacon-rain', Weather.rainColor));
				case 179:
					// 'Patchy snow nearby' wsymbol_0011_light_snow_showers	wsymbol_0027_light_snow_showers_night
					return new WeatherConditionInfo('WEATHER.PATCHY_SNOW',
						new WeatherConditionIcon('climacon-flurries-sun', Weather.sunColor, 'climacon-flurries', Weather.cloudColor));
				case 182:
					// 'Patchy sleet nearby' wsymbol_0013_sleet_showers	wsymbol_0029_sleet_showers_night
					return new WeatherConditionInfo('WEATHER.PATCHY_SLEET',
						new WeatherConditionIcon('climacon-sleet-sun', Weather.sunColor, 'climacon-sleet', Weather.sleetColor));
				case 185:
					// 'Patchy freezing drizzle nearby' wsymbol_0049_freezing_drizzle	wsymbol_0067_freezing_drizzle_night
					return new WeatherConditionInfo('WEATHER.PATCHY_FREEZING_DRIZZLE',
						new WeatherConditionIcon('climacon-hail-sun', Weather.sunColor, 'climacon-hail', Weather.hailColor));
				case 200:
					// 'Thundery outbreaks in nearby' wsymbol_0016_thundery_showers	wsymbol_0032_thundery_showers_night
					return new WeatherConditionInfo('WEATHER.THUNDER_OUTBREAKS',
						new WeatherConditionIcon('climacon-lightning-cloud', Weather.cloudColor));
				case 227:
					// 'Blowing snow' wsymbol_0053_blowing_snow	wsymbol_0071_blowing_snow_night
					return new WeatherConditionInfo('WEATHER.BLOWING_SNOW',
						new WeatherConditionIcon('climacon-snow-cloud', Weather.snowColor));
				case 230:
					// 'Blizzard' wsymbol_0054_blizzard	wsymbol_0072_blizzard_night
					return new WeatherConditionInfo('WEATHER.BLIZZARD',
						new WeatherConditionIcon('climacon-snow-cloud', Weather.snowColor));
				case 248:
					// 'Fog' wsymbol_0007_fog	wsymbol_0064_fog_night
					return new WeatherConditionInfo('WEATHER.FOG',
						new WeatherConditionIcon('climacon-fog-cloud', Weather.cloudColor));
				case 260:
					// 'Freezing fog' wsymbol_0047_freezing_fog	wsymbol_0065_freezing_fog_night
					return new WeatherConditionInfo('WEATHER.FREEZING_FOG',
						new WeatherConditionIcon('climacon-fog-cloud', Weather.cloudColor));
				case 263:
					// 'Patchy light drizzle' wsymbol_0048_drizzle	wsymbol_0066_drizzle_night
					return new WeatherConditionInfo('WEATHER.PATCHY_LIGHT_DRIZZLE',
						new WeatherConditionIcon('climacon-drizzle-sun', Weather.sunColor, 'climacon-drizzle', Weather.rainColor));
				case 266:
					// 'Light drizzle' wsymbol_0048_drizzle	wsymbol_0066_drizzle_night
					return new WeatherConditionInfo('WEATHER.LIGHT_DRIZZLE',
						new WeatherConditionIcon('climacon-drizzle', Weather.rainColor));
				case 281:
					// 'Freezing drizzle' wsymbol_0049_freezing_drizzle	wsymbol_0067_freezing_drizzle_night
					return new WeatherConditionInfo('WEATHER.FREEZING_DRIZZLE',
						new WeatherConditionIcon('climacon-sleet', Weather.sleetColor));
				case 284:
					// 'Heavy freezing drizzle' wsymbol_0083_heavy_freezing_drizzle	wsymbol_0084_heavy_freezing_drizzle_night
					return new WeatherConditionInfo('WEATHER.HEAVY_FREEZING_DRIZZLE',
						new WeatherConditionIcon('climacon-sleet', Weather.sleetColor));
				case 293:
					// 'Patchy light rain' wsymbol_0009_light_rain_showers	wsymbol_0025_light_rain_showers_night
					return new WeatherConditionInfo('WEATHER.PATCHY_LIGHT_RAIN',
						new WeatherConditionIcon('climacon-rain-cloud-sun', Weather.sunColor, 'climacon-rain-cloud', Weather.rainColor));
				case 296:
					// 'Light rain' wsymbol_0017_cloudy_with_light_rain	wsymbol_0033_cloudy_with_light_rain_night
					return new WeatherConditionInfo('WEATHER.LIGHT_RAIN',
						new WeatherConditionIcon('climacon-rain-cloud', Weather.rainColor));
				case 299:
					// 'Moderate rain at times' wsymbol_0009_light_rain_showers	wsymbol_0025_light_rain_showers_night
					return new WeatherConditionInfo('WEATHER.MODERATE_RAIN_AT_TIMES',
						new WeatherConditionIcon('climacon-rain-cloud', Weather.rainColor));
				case 302:
					// 'Moderate rain' wsymbol_0017_cloudy_with_light_rain	wsymbol_0033_cloudy_with_light_rain_night
					return new WeatherConditionInfo('WEATHER.MODERATE_RAIN',
						new WeatherConditionIcon('climacon-rain-cloud', Weather.rainColor));
				case 305:
					// 'Heavy rain at times' wsymbol_0010_heavy_rain_showers	wsymbol_0026_heavy_rain_showers_night
					return new WeatherConditionInfo('WEATHER.HEAVY_RAIN_AT_TIMES',
						new WeatherConditionIcon('climacon-downpour-cloud', Weather.rainColor));
				case 308:
					// 'Heavy rain' wsymbol_0018_cloudy_with_heavy_rain	wsymbol_0034_cloudy_with_heavy_rain_night
					return new WeatherConditionInfo('WEATHER.HEAVY_RAIN',
						new WeatherConditionIcon('climacon-downpour-cloud', Weather.rainColor));
				case 311:
					// 'Light freezing rain' wsymbol_0050_freezing_rain	wsymbol_0068_freezing_rain_night
					return new WeatherConditionInfo('WEATHER.LIGHT_FREEZING_RAIN',
						new WeatherConditionIcon('climacon-sleet-cloud', Weather.sleetColor));
				case 314:
					// 'Moderate or Heavy freezing rain' wsymbol_0050_freezing_rain	wsymbol_0068_freezing_rain_night
					return new WeatherConditionInfo('WEATHER.MODERATE_OR_HEAVY_FREEZING_RAIN',
						new WeatherConditionIcon('climacon-sleet-cloud', Weather.sleetColor));
				case 317:
					// 'Light sleet' wsymbol_0021_cloudy_with_sleet	wsymbol_0037_cloudy_with_sleet_night
					return new WeatherConditionInfo('WEATHER.LIGHT_SLEET',
						new WeatherConditionIcon('climacon-sleet-cloud', Weather.sleetColor));
				case 320:
					// 'Moderate or heavy sleet' wsymbol_0021_cloudy_with_sleet	wsymbol_0037_cloudy_with_sleet_night
					return new WeatherConditionInfo('WEATHER.MODERATE_OR_HEAVY_SLEET',
						new WeatherConditionIcon('climacon-sleet-cloud', Weather.sleetColor));
				case 323:
					// 'Patchy light snow' wsymbol_0011_light_snow_showers	wsymbol_0027_light_snow_showers_night
					return new WeatherConditionInfo('WEATHER.PATCHY_LIGHT_SNOW',
						new WeatherConditionIcon('climacon-flurries-sun', Weather.sunColor, 'climacon-flurries', Weather.snowColor));
				case 326:
					// 'Light snow' wsymbol_0019_cloudy_with_light_snow	wsymbol_0035_cloudy_with_light_snow_night
					return new WeatherConditionInfo('WEATHER.LIGHT_SNOW',
						new WeatherConditionIcon('climacon-flurries', Weather.snowColor));
				case 329:
					// 'Patchy moderate snow' wsymbol_0011_light_snow_showers	wsymbol_0027_light_snow_showers_night
					return new WeatherConditionInfo('WEATHER.PATCHY_MODERATE_SNOW',
						new WeatherConditionIcon('climacon-flurries-sun', Weather.sunColor, 'climacon-flurries', Weather.snowColor));
				case 332:
					// 'Moderate snow' wsymbol_0019_cloudy_with_light_snow	wsymbol_0035_cloudy_with_light_snow_night
					return new WeatherConditionInfo('WEATHER.MODERATE_SNOW',
						new WeatherConditionIcon('climacon-flurries-cloud', Weather.snowColor));
				case 335:
					// 'Patchy heavy snow' wsymbol_0012_heavy_snow_showers	wsymbol_0027_light_snow_showers_night
					return new WeatherConditionInfo('WEATHER.PATCHY_HEAVY_SNOW',
						new WeatherConditionIcon('climacon-snow-cloud-sun', Weather.sunColor, 'climacon-snow-cloud', Weather.snowColor));
				case 338:
					// 'Heavy snow' wsymbol_0020_cloudy_with_heavy_snow	wsymbol_0036_cloudy_with_heavy_snow_night
					return new WeatherConditionInfo('WEATHER.HEAVY_SNOW',
						new WeatherConditionIcon('climacon-snow-cloud', Weather.snowColor));
				case 350:
					// 'Ice pellets' symbol_0022_cloudy_with_light_hail	wsymbol_0038_cloudy_with_light_hail_night
					return new WeatherConditionInfo('WEATHER.ICE_PELLETS',
						new WeatherConditionIcon('climacon-hail-cloud', Weather.hailColor));
				case 353:
					// 'Light rain shower' wsymbol_0009_light_rain_showers	wsymbol_0025_light_rain_showers_night
					return new WeatherConditionInfo('WEATHER.LIGHT_RAIN_SHOWERS',
						new WeatherConditionIcon('climacon-showers', Weather.rainColor));
				case 356:
					// 'Moderate or heavy rain shower' wsymbol_0010_heavy_rain_showers	wsymbol_0026_heavy_rain_showers_night
					return new WeatherConditionInfo('WEATHER.MODERATE_OR_HEAVY_RAIN_SHOWERS',
						new WeatherConditionIcon('climacon-showers', Weather.rainColor));
				case 359:
					// 'Torrential rain shower' wsymbol_0085_extreme_rain_showers	wsymbol_0086_extreme_rain_showers_night
					return new WeatherConditionInfo('WEATHER.TORRENTIAL_RAIN_SHOWERS',
						new WeatherConditionIcon('climacon-downpour', Weather.rainColor));
				case 362:
					// 'Light sleet showers' wsymbol_0013_sleet_showers	wsymbol_0029_sleet_showers_night
					return new WeatherConditionInfo('WEATHER.LIGHT_SLEET_SHOWERS',
						new WeatherConditionIcon('climacon-sleet', Weather.sleetColor));
				case 365:
					// 'Moderate or heavy sleet showers' wsymbol_0087_heavy_sleet_showers	wsymbol_0088_heavy_sleet_showers_night
					return new WeatherConditionInfo('WEATHER.MODERATE_OR_HEAVY_SLEET_SHOWERS',
						new WeatherConditionIcon('climacon-sleet', Weather.sleetColor));
				case 368:
					// 'Light snow showers' wsymbol_0011_light_snow_showers	wsymbol_0027_light_snow_showers_night
					return new WeatherConditionInfo('WEATHER.LIGHT_SNOW_SHOWERS',
						new WeatherConditionIcon('climacon-flurries', Weather.snowColor));
				case 371:
					// 'Moderate or heavy snow showers' wsymbol_0012_heavy_snow_showers	wsymbol_0028_heavy_snow_showers_night
					return new WeatherConditionInfo('WEATHER.MODERATE_OR_HEAVY_SNOW_SHOWERS',
						new WeatherConditionIcon('climacon-snow', Weather.snowColor));
				case 374:
					// 'Light showers of ice pellets' wsymbol_0014_light_hail_showers	wsymbol_0030_light_hail_showers_night
					return new WeatherConditionInfo('WEATHER.LIGHT_SHOWERS_OF_ICE_PELLETS',
						new WeatherConditionIcon('climacon-hail-cloud', Weather.hailColor));
				case 377:
					// 'Moderate or heavy showers of ice pellets' wsymbol_0015_heavy_hail_showers	wsymbol_0031_heavy_hail_showers_night
					return new WeatherConditionInfo('WEATHER.MODERATE_OR_HEAVY_SHOWERS_OF_ICE_PELLETS',
						new WeatherConditionIcon('climacon-hail-cloud', Weather.hailColor));
				case 386:
					// 'Patchy light rain in area with thunder' wsymbol_0016_thundery_showers	wsymbol_0032_thundery_showers_night
					return new WeatherConditionInfo('WEATHER.PATCHY_LIGHT_RAIN_IN_AREA_WITH_THUNDER',
						new WeatherConditionIcon('climacon-lightning-cloud-sun', Weather.sunColor, 'climacon-lightning-cloud', Weather.cloudColor));
				case 389:
					// 'Moderate or heavy rain in area with thunder' wsymbol_0024_thunderstorms	wsymbol_0040_thunderstorms_night
					return new WeatherConditionInfo('WEATHER.MODERATE_OR_HEAVY_RAIN_IN_AREA_WITH_THUNDER',
						new WeatherConditionIcon('climacon-lightning-cloud', Weather.cloudColor));
				case 392:
					// 'Patchy light snow in area with thunder' wsymbol_0057_thundery_snow_showers	wsymbol_0075_thundery_snow_showers_night
					return new WeatherConditionInfo('WEATHER.PATCHY_LIGHT_SNOW_IN_AREA_WITH_THUNDER',
						new WeatherConditionIcon('climacon-lightning-cloud-sun', Weather.sunColor, 'climacon-lightning-cloud', Weather.cloudColor));
				case 395:
					// 'Moderate or heavy snow in area with thunder' wsymbol_0058_thunderstorms_with_snow	wsymbol_0076_thunderstorms_with_snow_night
					return new WeatherConditionInfo('WEATHER.MODERATE_OR_HEAVY_SNOW_IN_AREA_WITH_THUNDER',
						new WeatherConditionIcon('climacon-lightning-cloud', Weather.cloudColor));
			}
		}

		/**
		 * We need to handle addresses, translating them into query parameters for WWO in several scenarios.
		 * RB-8585: We should NOT USE ZIP CODE AS THOUGH IT'S ADEQUATE TO IDENTIFY THE LOCATION. That only works
		 * in the USA while virtually every country on earth has a postal code.
		 */
		public static GetWWOQueryParams(address: string, city: string, state: string, country: string, zip: string): string {
			if (zip && country == CountryCode.UNITED_STATES) {
				// The zip or postal code is only sufficient if the country is USA. Virtually every Western and
				// most countries in general have postal codes.
				return zip;
			}

			let query = '';

			// Current World Weather Online API only wants a zipcode or a city, state, country - not an address
			// if (this.address) query += this.address.replace(/ /g, '+');

			if (city) {
				if (query.length > 0) query += ',';
				query += city.replace(/ /g, '+');
			}

			if (state) {
				if (query.length > 0) query += ',';
				query += state.replace(/ /g, '+');
			}

			if (country) {
				if (query.length > 0) query += ',';
				query += country.replace(/[,' ]/g, '+').replace(/[()]/g, '');
			}

			return query;
		}

		/**
		 * When sending address, city, state, country, zip, etc to the weather service, we really need to
		 * be more-precise than those items, as WWO will find some city in the same state, if it can't find
		 * the target city, but it might be 700 miles away. We really want to send WWO the lat/long location
		 * so it can find the geographically-close location. This is processed using geocoding from the map
		 * service. This method will wait for the MapService and the maps API to be initialized before
		 * returning.
		 * RB-9518
		 * @param queryParams - string query params from prior call to GetWWOQueryParams()
		 * @returns Observable<string> containing the correct lat/long query string to send to the WWO service.
		 */
		public static GetWWOQueryParamsPrecise(queryParams: string): Observable<string> {
			return Observable.create(observer => {
				// Wait for the map service to complete initialization.
				MapService.initializingGoogleMapsSubject.subscribe(mapServiceReady => {
					const par = queryParams.split(',');	// Should be either city,state,country or zip, depending on
														// the country.
					if (par == null || par.length === 0) {
						observer.next(queryParams);
						observer.complete();
						return;
					}
					let city: string = null;
					let state: string = null;
					let country: string = null;
					let zip: string = null;
					if (par.length === 1) {
						// Zip code.
						zip = par[0];
					} else {
						city = par[0];
						state = par[1];
						country = par[2];
					}
					MapService.LookupAddressLatLng(null, city, state, zip, country,
						function(results, status) {
							// If error in the query or no results, return the standard WWO query string rather than
							// the good, precise lat/long-based one.
							if (status !== google.maps.GeocoderStatus.OK || results == null || results.length === 0) {
								// Can't geocode. Use the less-precise location.
								observer.next(queryParams);
								observer.complete();
								return;
							}

							// We were successful. Build the string using lat/long data. The correct format is
							// ##.###,##.### (latitude, longitude decimal degrees).
							const latLong = results[0].geometry.location;
							observer.next(`${latLong.lat()},${latLong.lng()}`);
							observer.complete();
					});
				});
			});
		}
		

		static getAttributeUnits(attributeName: WeatherAttribute): string {
			switch (attributeName) {
				case WeatherAttribute.etFinal:
				case WeatherAttribute.rainCurrent:
					return RbUtils.Weather.getSmallLengthUnits();
					
				case WeatherAttribute.temperatureAirMin:
				case WeatherAttribute.temperatureAirAverage:
				case WeatherAttribute.temperatureAirMax:
					return RbUtils.Weather.getTemperatureUnits();
					
				case WeatherAttribute.windSpeedAverage:
					return RbUtils.Weather.getWindSpeedUnits();
					
				case WeatherAttribute.solarRadiation:
					return RbUtils.Weather.getSolarRadiationUnits();

				case WeatherAttribute.humidityAirAverage:
					return RbUtils.Weather.getHumidityUnits();

				default:
					return "";
			}
		}
	  
		/**
		* RB-16201: Converts the WeatherData to user profile configured units, the enum WeatherAttribute matches the fields of the
		* WeatherData model and we use the sensor utils for each case. Please note that we don't convert ALL WeatherData fields
		* and those should be added as needed.
		* @param item - The object with the data we want to be converted.
		* it will return a number type instead.
		* @param includeUnits - Whether we want the value to include the units; if TRUE, this will return a string, while if FALSE
		* @param decimalPlaces - Number of decimal places to return on the converted units.
		* @returns The same item-object with the converted values, only on fields that match WeatherAttribute enum, using user's units profile configuration.
		*/
		static convertToUserCultureUnits(item: WeatherData | any, includeUnits: boolean = true, decimalPlaces: number = 2): any {
			for (const field in item) {
				// If no value, then skip it.
				if (!item[field]) continue;

				switch (field) {
					case WeatherAttribute.temperatureAirMin:
					case WeatherAttribute.temperatureAirAverage:
					case WeatherAttribute.temperatureAirMax:
						// This value must be Fahrenheit.
						item[field] = RbUtils.Sensor.getGolfSensorTemperatureString(item[field], includeUnits, decimalPlaces);
						// If no units included, then we can replace the separator with a dot to convert it to numeric value.
						if (!includeUnits)
							item[field] = parseFloat(item[field].replace(',', '.'));
						break;

					case WeatherAttribute.rainCurrent:
						// This value must be Inches.
						item[field] = RbUtils.Sensor.getGolfSensorRainfallString(item[field], includeUnits, decimalPlaces);
						if (!includeUnits)
							item[field] = parseFloat(item[field].replace(',', '.'));
						break;

					case WeatherAttribute.etFinal:
						// This value must be Inches, this will also include the units even if not specified.
						item[field] = RbUtils.Sensor.getGolfSensorETString(item[field], includeUnits, decimalPlaces);
						if (!includeUnits)
							item[field] = parseFloat(item[field].replace(',', '.'));
						break;

					case WeatherAttribute.windSpeedAverage:
						// This value must be Inches.
						item[field] = RbUtils.Sensor.getSensorWindSpeedString(item[field], includeUnits, decimalPlaces);
						if (!includeUnits)
							item[field] = parseFloat(item[field].replace(',', '.'));
						break;

					case WeatherAttribute.solarRadiation:
						item[field] = RbUtils.Sensor.getGolfSensorSolarRadiationString(item[field], includeUnits, decimalPlaces);
						if (!includeUnits)
							item[field] = parseFloat(item[field].replace(',', '.'));
						break;

					case WeatherAttribute.humidityAirAverage:
						item[field] = RbUtils.Sensor.getHumidityString(item[field], includeUnits);
						if (!includeUnits)
							item[field] = parseFloat(item[field].replace(',', '.'));
						break;
				}
			}
			return item;
		}

		static getSmallLengthUnits(): string {
			return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ?
				RbUtils.Translate.instant('UNIT_TYPE.INCH') :
				RbUtils.Translate.instant('UNIT_TYPE.MILLIMETER');
		}

		static getMediumLengthUnits(): string {
			return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ?
				RbUtils.Translate.instant('UNIT_TYPE.FOOT') :
				RbUtils.Translate.instant('UNIT_TYPE.METER');
		}

		static getLargeLengthUnits(): string {
			return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ?
				RbUtils.Translate.instant('UNIT_TYPE.MILE') :
				RbUtils.Translate.instant('UNIT_TYPE.KILOMETER');
		}

		static getWindSpeedUnits(): string {
			return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ?
				RbUtils.Translate.instant('UNIT_TYPE.MILES_PER_HOUR') :
				RbUtils.Translate.instant('UNIT_TYPE.KILOMETERS_PER_HOUR');
		}

		static getSolarRadiationUnits(): string {
			return RbUtils.Translate.instant('UNIT_TYPE.LY');
		}

		static getTemperatureUnits(): string {
			return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ?
				RbUtils.Translate.instant('UNIT_TYPE.DEGREES_F') :
				RbUtils.Translate.instant('UNIT_TYPE.DEGREES_C');
		}

		static getHumidityUnits(): string {
			return "%";
		}


	}

}

import { catchError, map, take, } from 'rxjs/operators';
import { forkJoin, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProgramGroupManagerService } from '../../api/program-groups/program-group-manager.service';

@Injectable({
	providedIn: 'root'
})
export class PreloaderService {

	constructor(private programGroupManager: ProgramGroupManagerService) { }

	/**
	 * Method to load any api data and populate any collections required at app start up.
	 * This method is called once from the AppComponent after the user has logged in.
	 */
	load(): Observable<boolean> {
		const sources: Observable<any>[] = [
			this.programGroupManager.getProgramTypes().pipe(take(1)),
		];

		return forkJoin(sources)
			.pipe(
				map(() => true),
				catchError(() => {
					// TODO: Display/log data retrieval errors as desired.
					return throwError(false);
				})
		);
	}
}

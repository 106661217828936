<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<mat-form-field>
	<mat-select #matSelect
		[placeholder]="label"
		(ngModelChange)="handleChange()"
		[ngModel]="selectedItems"
		multiple="true"
		#dataSelect="ngModel"
		[compareWith]="equals"
		[panelClass]="'multiselect-dropdown'"
	>
		<div
			disabled="disabled"
			class="mat-option db mat-select-all-options">
			<button
				class="fl"
				mat-button
				color="primary"
				type="button"
				(click)="selectAll()">
				{{'STRINGS.SELECT_ALL_UPPERCASE' | translate}}
			</button>
			<button
				class="fr"
				mat-button
				color="primary"
				type="button"
				(click)="deselectAll()">
				{{'STRINGS.CLEAR_UPPERCASE' | translate}}
			</button>
		</div>
		<mat-select-trigger class="mat-select-trigger">
			{{(selectedItems && (selectedItems.length > 0)) ? selectedItems[0].name : ''}}
			<span *ngIf="selectedItems && selectedItems.length > 1">
					(+{{selectedItems.length - 1}})
				</span>
		</mat-select-trigger>
		<div class="options-list alert-filter">
			<mat-option
				*ngFor="let item of items"
				[value]="item"
			>
				{{ item.name }}
			</mat-option>
		</div>
		<div class="mat-option alert-filter flex">
			<button
				mat-raised-button
				class="fr"
				color="primary"
				type="button"
				(click)="handleDone()">
				{{'STRINGS.DONE' | translate}}
			</button>
		</div>
	</mat-select>
</mat-form-field>

import { ApiService, HttpMethod } from '../_common/api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WorldWeatherData } from './models/world-weather-data.model';

@Injectable({
	providedIn: 'root'
})
export class WeatherApiService extends ApiService {

	private readonly worldWeatherApiKey = `${this.env.wwoKey}`;

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	/**
	 * Retrieve 7-day weather forecast from WWO.
	 * @param qValue - Query data specifying location for forecast. Could be a zip code, city, etc.
	 * @param lang - String language code specifying the language of the result ("es", "pt", "en", etc.) We use the
	 * current conditions data returned by the call, so we need to tell it what language to use.
	 */
	getSevenDayForecast(qValue: string, lang: string): Observable<WorldWeatherData> {
		// const key = '1cb050e81a834950ad3235653190509';
		// const url = `http://api.worldweatheronline.com/premium/v1/weather.ashx?key=${key}&num_of_days=5&q=92121&mca=no&tp=24&format=json`;
		// return this.apiRequest<any>(url, HttpMethod.Get);

		return this.apiRequest<any>(this.getSevenDayForecastUrl(qValue, lang), HttpMethod.Get)
			.pipe(map(response => new WorldWeatherData(response.data)));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	// RB-6598: Use the environment, as we have to switch to using https on production, but not other servers.
	private get baseUrl(): string { return `${this.env.wwoUrl}`; }

	/**
	 * Get the 7-day forecast from WorldWeatherOnline for a given location and in a given language.
	 * @param qValue - The location to query for weather forecast. Could be a zip code, city, etc.
	 * @param lang - Two-character language code (like "es", "en", "fr") describing the language in which data
	 * will be returned. The reason for this is that we use the "current conditions" and want those ("Cloudy")
	 * in the user-selected language.
	 */
	private getSevenDayForecastUrl(qValue: string, lang: string): string {
		return `${this.baseUrl}/weather.ashx?key=${this.worldWeatherApiKey}` +
				`&q=${qValue}&num_of_days=7&mca=no&tp=24&includelocation=yes&format=json&lang=${lang}`;
	}

}

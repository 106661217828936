import { AppDateAdapter } from '../../../shared-ui/custom/date-adapter';
import { GroupStartTime } from '../../programs/models/group-start-time.model';
import { PriorityGolfInputType } from '../../programs/models/priority-input-type.model';
import { Program } from '../../programs/models/program.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class ProgramGroupListItem {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.groupStartTime) { this.groupStartTimes = json.groupStartTime.map(item => new GroupStartTime(item)); }
			if (json.program) { this.program = json.program.map(item => new Program(item)); }
			if (json.nextCyclicalStartDate) { this.nextCyclicalStartDate = RbUtils.Conversion.convertStringToDate(json.nextCyclicalStartDate); }
			if (json.stopTime) { this.stopTime = RbUtils.Conversion.convertStringToDate(json.stopTime); }
			if (json.tempAdjustStart) { this.tempAdjustStart = RbUtils.Conversion.convertStringToDate(json.tempAdjustStart); }
			if (json.tempAdjustStop) { this.tempAdjustStop = RbUtils.Conversion.convertStringToDate(json.tempAdjustStop); }
		}
	}

	companyId: number;
	etAdjustType: RbEnums.Common.EtAdjustType;
	forcedProgression: boolean;
	groupStartTimes: GroupStartTime[];
	hybridWeekDays: string;
	id: number;
	isEnabled: boolean;
	isSequential: boolean;
	isVertical: boolean;
	name: string;
	nextCyclicalStartDate: Date;
	number: number;
	overrideCycleSoak: boolean;
	overrideStationAdjust: boolean;
	overrideStationPause: boolean;
	overrideWB: boolean;
	priority: number;
	program: Program[];
	programGroupAdjust: number;
	programType: RbEnums.Common.ProgramType;
	skipDays: number;
	stopTime: Date;
	systemOffOverride: boolean;
	tempAdjustStart: Date;
	tempAdjustStop: Date;
	tempAdjustDays: number;
	tempProgramGroupAdjust: number;
	weekDays: string;
	runTimeInputType: RbEnums.Common.RunTimeGolfInputType;
	runTimeGolfInputType: RbEnums.Common.RunTimeGolfInputType;
	rainCanSensorId: number;
	status: string;
	nextStartTime: string;

	static get priorityInputTypes(): PriorityGolfInputType[] {
		return PriorityGolfInputType.getDefaultPrioritySet();
	}

	get noOfPrograms(): number {
		return this.program.length;
	}

	// NOTE: Used by program-groups-tab component for hyperlink grid cell.
	get programsCountForHyperlink(): string {
		return this.program.length === 0
			? RbUtils.Translate.instant('STRINGS.ADD_SCHEDULE')
			: `${this.program.length} ${RbUtils.Translate.instant(this.program.length === 1 ? 'STRINGS.SCHEDULE_NOUN' : 'STRINGS.SCHEDULES_NOUN')}`;
	}

	get controls(): string {
		return '-';
	}

	get start(): string {
		return '-';
	}

	get end(): string {
		return '-';
	}

	get waterCycle(): string {
		return RbUtils.Weekdays.getStartDays(this.programType, this.weekDays, this.hybridWeekDays);
	}

	// Return sorted start times as ticks (with normalized dates)
	get sortedStartTimes(): number[] {
		if (this.groupStartTimes == null || this.groupStartTimes.length === 0) return [];

		return this.groupStartTimes.map(st => ProgramGroupListItem.getTimeComparisonData(st.dateTime)).sort((st1, st2) => st1 - st2);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	private static getTimeComparisonData(dateTime: Date): number {
		return new Date(2000, 1, 1, dateTime.getHours(), dateTime.getMinutes()).getTime();
	}

	getStartTimeGridCell(appDateAdapter: AppDateAdapter): string {
		// return this.startTime.getFullYear() > 1901 ? appDateAdapter.formatTime(this.startTime) : '-';
		return '-';
	}

	updateRunTimeInfo() {
		// update runTimeInputType, etAdjustType base on runTimeGolfInputType,
		// this is a copy of ProgramGroupRepository.GetProgramGroupInfoByRunTime function
		if (this.runTimeGolfInputType === null) {
			return;
		}

		switch (this.runTimeGolfInputType) {
			case RbEnums.Common.RunTimeGolfInputType.Passes:
				this.runTimeInputType = RbEnums.Common.RunTimeGolfInputType.Passes;
				this.etAdjustType = RbEnums.Common.EtAdjustType.None;
				break;
			case RbEnums.Common.RunTimeGolfInputType.Volume:
				this.runTimeInputType = RbEnums.Common.RunTimeGolfInputType.Volume;
				this.etAdjustType = RbEnums.Common.EtAdjustType.None;
				break;
			case RbEnums.Common.RunTimeGolfInputType.WeatherSource:
				this.runTimeInputType = RbEnums.Common.RunTimeGolfInputType.RunTime;
				this.etAdjustType = RbEnums.Common.EtAdjustType.WeatherSource;
				break;
			default:
				this.runTimeInputType = RbEnums.Common.RunTimeGolfInputType.RunTime;
				this.etAdjustType = RbEnums.Common.EtAdjustType.None;
				break;
		}
	}

	get etAdjustTypeName(): string { return Program.getEtAdjustTypeName(this.etAdjustType); }

	get runTimeInputTypeName(): string {
		if (this.etAdjustType === RbEnums.Common.EtAdjustType.WeatherSource)
			return RbUtils.Translate.instant('STRINGS.ET_PERCENT');
		else {
			switch (this.runTimeInputType) {
				case RbEnums.Common.RunTimeGolfInputType.Passes:
					return RbUtils.Translate.instant('STRINGS.PASSES');
				case RbEnums.Common.RunTimeGolfInputType.Volume:
					return RbUtils.Translate.instant('STRINGS.APPLICATION');
				default:
					return RbUtils.Translate.instant('STRINGS.RUNTIME');
			}
		}
	}

	get priorityForDisplay(): string {
		// Display the priority value string associated with the value, or - if the priority is null or zero.
		if (this.priority != null && this.priority >= 0 && this.priority <= 12) {
			return ProgramGroupListItem.priorityInputTypes[this.priority].name;
		} else {
			// If null, or 0, or out of range, return the equivalent of 0.
			return ProgramGroupListItem.priorityInputTypes[0].name;
		}
	}
}

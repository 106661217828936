<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="flex justify-between">
	<div>{{user?.name}}</div>
</div>
<div class="flex justify-between">
	<div [innerHTML]="getOwnerCellRenderer()"></div>
</div>



import { About } from './models/about.model';
import { BroadcastService } from '../../common/services/broadcast.service';
import { EntityVersionApiService } from './entity-version-api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class EntityVersionManagerService extends ServiceManagerBase {
	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private entityVersionApiService: EntityVersionApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	protected clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getCoreVersion(): Observable<About> {
		return this.entityVersionApiService.getCoreVersion();
	}
}

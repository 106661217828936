<div class="rb-dry-run-chart-container" [ngClass]="{'is-mobile': isMobile}">

	<div class="drc-pane-header">
		<div class="drc-chart-title">
			{{ chartTitle }}
		</div>
		<div id="drc-flow-summary">
			<div id="drc-flow-icon"><i class="icon-flow"></i></div>
			<div>{{ totalFlowHeader }}</div>
		</div>
	</div>

	<div class="drc-pane-body">
		<div class="drc-pane-body-static-container">
			<div id="drc-chart-container" [ngClass]="{'is-mobile': isMobile}">

				<highcharts-chart
					*ngIf="showChart"
					[Highcharts]="Highcharts"
					[options]="chartOptions"
					[(update)]="updateFlag"
					[oneToOne]="true"
					[callbackFunction]="chartCallback.bind(this)"
					(chartInstance)='onChartInstance($event)'
					(mousemove)="onMouseMove($event)"
					(touchmove)="onMouseMove($event)"
					(click)="onMouseMove(null)"
				></highcharts-chart>

			</div>

			<!-- Time Traveler -->
			<div id="drc-chart-datum-info" *ngIf="!isMobile" style="justify-content: flex-start;">
				<div id="drc-chart-datum-left">
					<div id="drc-flow-datum-rate">
						<span class="drc-tnr-lbl">{{ 'STRINGS.RATE' | translate }} ({{flowRateLabel}}): </span><span
						class="drc-rate-val">{{currentFlowDataFlowRate}}</span>
					</div>
				</div>

				<div id="drc-chart-datum-center">
					<ng-container *ngTemplateOutlet="timeTravelerTemplate"></ng-container>
				</div>

				<div id="drc-chart-datum-right">
					<!-- Serves as a spacer	-->
					<!-- <button mat-button id="drc-view-pdf-btn" (click)="generatePdf()">{{'STRINGS.VIEW_PDF' | translate}}</button> -->
				</div>
			</div>
		</div>

		<!-- Point Details (Desktop) -->
		<div class="drc-chart-details-container" *ngIf="!isMobile">

			<ng-container *ngTemplateOutlet="activePumpsTemplate"></ng-container>

			<ng-container *ngTemplateOutlet="activeProgramsTemplate"></ng-container>

			<ng-container *ngTemplateOutlet="activeStationsTemplate"></ng-container>

		</div>

		<!-- Point Details (Mobile) -->
		<div class="drc-chart-details-container is-mobile" *ngIf="isMobile">

			<div class="drc-mobile-time-traveler-container">
				<ng-container *ngTemplateOutlet="timeTravelerTemplate"></ng-container>
			</div>

			<rb-carousel>
				<rb-carousel-item>
					<ng-container *ngTemplateOutlet="activeStationsTemplate"></ng-container>
				</rb-carousel-item>
				<rb-carousel-item>
					<ng-container *ngTemplateOutlet="activeProgramsTemplate"></ng-container>
				</rb-carousel-item>
				<rb-carousel-item *ngIf="isGolfSite">
					<ng-container *ngTemplateOutlet="activePumpsTemplate"></ng-container>
				</rb-carousel-item>
			</rb-carousel>
		</div>

	</div>

</div>

<ng-template #timeTravelerTemplate>
	<div class="drc-time-traveler-wrapper" [ngClass]="{'is-mobile': isMobile}">
		<rb-chart-time-traveler
			[currentDateString]="currentFlowDataDate"
			[currentTimeString]="currentFlowDataTime"
			(timeTravel)="onTimeTravel($event)"
		>
		</rb-chart-time-traveler>
	</div>
</ng-template>

<ng-template #activeStationsTemplate>
	<div class="drc-details-section" [ngClass]="{'is-mobile': isMobile}">
		<div class="drc-details-section-header">{{'STRINGS.ACTIVE_STATIONS' | translate}}</div>
		<div class="drc-details-section-scroll-container">
			<div class="drc-details-section-body" id="drc-active-stations">
				<div *ngFor="let station of currentActiveStations" class="drc-active-station">
					<div class="drc-as-div drc-as-name">{{ station.name }}</div>
					<div class="drc-as-div drc-as-rate">{{ station.rate }}</div>
				</div>
				<div class="drc-existing-zfs-lbl" *ngIf="existingZeroFlowRateStation">
					{{'STRINGS.EXISTING_ZERO_FLOW_RATE_STATION' | translate}}
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #activeProgramsTemplate>
	<div class="drc-details-section" [ngClass]="{'is-mobile': isMobile}">
		<div class="drc-details-section-header">{{'STRINGS.ACTIVE_PROGRAMS' | translate}}</div>
		<div class="drc-details-section-scroll-container">
			<div class="drc-details-section-body" id="drc-programs-list">

				<div *ngIf="!isGolfSite">
					<div class="drc-program-item" *ngFor="let program of currentRunningPrograms">
						{{ program.programShortName }} - {{ program.programName }}
					</div>
				</div>
				<div *ngIf="isGolfSite">
					<div class="drc-program-group-item" *ngFor="let programGroup of currentRunningProgramGroups">
						<div class="drc-program-group-label">{{ programGroup.name }}</div>
						<div class="drc-program-item" *ngFor="let program of getProgramGroupPrograms(programGroup.id)">
							{{ program.programName }}
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</ng-template>

<ng-template #activePumpsTemplate>
	<div class="drc-details-section" *ngIf="isGolfSite" [ngClass]="{'is-mobile': isMobile}">
		<div
			class="drc-details-section-header">{{'STRINGS.ACTIVE_PUMPS' | translate}}</div>
		<div class="drc-details-section-scroll-container">
			<div class="drc-details-section-body" id="drc-pumps-list">
				<div class="drc-active-pump" *ngFor="let pump of currentActivePumps">
					<div class="drc-as-div drc-as-name">{{ pump.name }}</div>
					<div class="drc-as-div drc-as-rate">{{ pump.flowRate }}</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

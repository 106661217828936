<div class="rb-cell-editor-container">
	<input #i
		[ngClass]="{'text-align-right': alignTextRight}"
		[value]="value"
		(keypress)="onKeyPress($event)"
		(keydown)="onKeyDown($event)"
		(keyup)="onKeyUp($event)"
	/>
</div>


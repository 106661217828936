import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationListItem } from '../../stations/models/station-list-item.model';

export class CourseViewActiveStation {

	constructor(station: StationListItem) {
		this.station = station;
	}

	station: StationListItem;

	/**
	 * Precipitation rate. This value is set both when extracted from catalog data *and* when set as custom rate
	 * by the user. Units are inches.
	 */
	public get precRateFinal(): number {
		return this.station.precRateFinal;
	}

	/**
	 * Flag indicating whether the user has chosen a custom rate (false), or the catalog rate based on rotor, nozzle,
	 * pressure, etc. (true). This flag is used to decide whether things like arc from the nozzle are used or not.
	 */
	public get usePrecRateCalc(): boolean {
		return this.station.usePrecRateCalc;
	}

	/**
	 * Station rotation time in seconds/cycle. May be set by the user as a custom value or calculated based on a
	 * catalog value.
	 */
	public get rotationTime(): number {
		return this.station.rotationTime;
	}

	/**
	 * Station arc value in degrees. This could be the catalog arc for a fixed-arc rotor, a user-entered arc for an
	 * adjustable rotor, or 360 for a non-catalog based custom user entry.
	 */
	public get arc(): number {
		return this.station.arc;
	}

	/**
	 * Return display formatted number of rotations represented by the secondsRemaining as a string. This calculation takes into
	 * account custom or catalog rotation time, as well as arc value.
	 */
	public get passes(): string {
		return RbUtils.Stations.stationRotationsDisplay(this.arc, this.rotationTime, this.station.secondsRemaining);
	}

	/**
	 * Return display formatted amount of pending irrigation, expressed in the user's units system, represented by the secondsRemaining
	 * value.
	 */
	public get application(): string {
		return RbUtils.Stations.applicationAmountDisplay(this.precRateFinal, this.station.secondsRemaining);
	}
}

<rb-dashboard-widget-container class="weather-widget-container"
							   [title]="title"
							   [subTitle]="subTitle"
							   [titleIcon]="titleIcon"
							   [busy]="busy"
							   [widgetId]="associatedWidget?.uniqueId"
							   [withRefresh]="true"
							   [withSettings]="true"
							   [showSettings]="showSettings"
							   [hideRemoveWidget]='isIq4SidebarWidget'
							   [theme]='theme'
							   (widgetRefresh)="onWidgetRefresh()"
							   (widgetSettings)="onWidgetSettings()">
	<div class="dash-weather-widget" *ngIf="!noInternet" >
		<div *ngIf="!isIq4SidebarWidget && this.siteId == null && !busy" class="message-area">
			{{'DASHBOARD.PLEASE_SELECT_SITE' | translate}}
		</div>

		<div *ngIf="this.siteId != null && !busy" class="data-area">
			<rb-ccweather-widget [siteId]="site?.id"
								 [userId]="userId"
								 [companyId]="companyId"
								 [callRefresh]="callRefresh"
								 [showSettings]="showSettings"
								 [widgetId]="associatedWidget?.uniqueId"
								 [databaseWidgetId]="associatedWidget?.databaseWidgetId"
								 [isInitialized]="associatedWidget?.initialized"
								 [visibleBlocks]="associatedWidget?.visibleBlocks"
								 [isIq4SidebarWidget]='isIq4SidebarWidget'
								 [associatedWidget]='associatedWidget'
								 [theme]='theme'
								 (settingsClosed)="onSettingsClosed($event)"
			></rb-ccweather-widget>
		</div>
	</div>
	<rb-no-internet  class="w-100 h-100" *ngIf="noInternet" ></rb-no-internet>
</rb-dashboard-widget-container>
<ng-template #titleIcon>
	<mat-icon class="rb-icon-font icon-cloud"></mat-icon>
</ng-template>

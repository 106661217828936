import { Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ColumnComponent } from '../tables/column/column.component';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { TableColumn } from '../tables/column/table-column.model';
import { TableWrapperComponent } from '../tables/table-wrapper/table-wrapper.component';

@UntilDestroy()
@Component({
	selector: 'rb-grid-container',
	templateUrl: './grid-container.component.html',
	styleUrls: ['./grid-container.component.scss'],
})
export class GridContainerComponent implements OnInit, OnDestroy {
	@ViewChild('tableWrapper', { static: true })
	tableWrapper: TableWrapperComponent;
	@ContentChildren(ColumnComponent) columns: QueryList<TableColumn>;

	@Input() addFabOptions = new Map<string, any>();
	@Input() domLayout: 'normal' | 'autoHeight' | 'print' | undefined =
		'normal'; // ag-grid v20+ uses normal as the basic value, not ''.
	@Input() dragFieldName = '';
	@Input() enableSorting = true;
	@Input() hideRowSelection = false;
	@Input() hideRowSelectionButLeaveSpace = false;
	@Input() includeCheckbox = false;
	@Input() checkboxClass = '';
	@Input() checkboxClassCallback?: (params: any) => string = null; // RB-9654: qaid hook support
	@Input() headerCheckboxSelection?: boolean;
	@Input() includeFabButton = false;
	@Input() includeFabGroupButton = false;
	@Input() isClickable = false;
	@Input() isFadableGrid = false;
	@Input() isGridDisplayable = false;
	@Input() noGridMargin = false;
	@Input() rowSelection = 'multiple';
	@Input() rowData = [];
	@Input() rowDrag = false;
	@Input() rowHeight = RbConstants.Form.DEFAULT_ROW_HEIGHT;
	@Input() showGrid = true;
	@Input() suppressScrollOnNewData = true;
	@Input() filterIcon = '';
	@Input() useManagedRowDragging = false;
	@Input() rowClassCallback?: (params: any) => string = null;
	@Input() instructionsText: string = null;
	@Input() groupDefaultExpanded: 0 | -1 = 0;
	@Input() isRowSelectable: (params: any) => boolean = null;

	// Mobile input fields

	/**
	 * This item will be set for all cells in the mobile view of the table as the cellRendererFramework property. The mobile
	 * view uses a single cell for what would normally be each row of a non-mobile table.
	 */
	@Input() mobileCellRendererComponent: any;

	/**
	 * RB-8358: Parameters passed to the agInit method of the renderer component, along with the normal stuff that agGrid
	 * passes. You can find these parameters in the 'params' method parameter. For example if mobileCellRendererParams is
	 * {thisNewValue: 17}, the agInit params property would include params.thisNewValue with a value of 17.
	 */
	@Input() mobileCellRendererParams: any;

	@Input() mobileNavigation = false;
	@Input() mobileRightArrow = true;
	@Input() mobileRendererDesiredWidth = 0;

	/**
	 * Similar to mobileCellRendererComponent, mobileExtraColumns provides access to one or more specially created
	 * columns in the mobile case. Normally we have only one column in the grid in mobile, usually with a renderer
	 * attached. However, in certain cases, we may want to add an extra column or two for additional status, links to
	 * other pages, etc. This element provides that access. Normally it is null and the result is a single-column
	 * mobile table. A typical entry in this array might look something like this:
	 *	{
	 *		autoHeight: true,
	 *		suppressSizeToFit: true,
	 *		width: 30,
	 *		cellRendererFramework: LinkDiagnosticsCellRendererComponent,
	 *		cellClass: 'lastCellMobile',
	 *	};
	 * setting a fixed-width (does not auto-resize) column with a width of 30px, a cellRendererFramework of
	 * LinkDiagnosticsCellRendererComponent, and an SCSS class of lastCellMobile.
	 * We typically do NOT want to add more than one extra column. Also note that extra columns are always added at the
	 * end of the row, never the beginning.
	 */
	@Input() mobileExtraColumns: any[] = null;

	// Tree view input fields
	@Input() treeNodeCallback?: (data: any) => any = null; // NOTE: If this is not null, the other treeXYZ input fields below must be defined
	@Input() treeNodeOrder = 0; // 0 = first column, 1 = second column, ...
	@Input() treeNodeField: string;
	@Input() treeNodeHeaderName: string;
	@Input() treeNodeDesiredWidth = 0;
	@Input() treeNodeUseMobileRendererWithHeader = false;
	@Input() treeNodeUseMobileRendererWithNonHeader = false;
	@Input() treeNodeIsHeaderCallback?: (params: any) => boolean = null;
	@Input() treeInnerRendererCallback?: (params: any) => string;
	@Input() treeNodeMobileExpanderColWidth?: number = null;
	@Input() treeSuppressMenu: boolean;
	@Input() treeSortable: boolean;
	@Input() includeTreeInnerRendererCallback = false;
	@Output() treeNodeExpanded = new EventEmitter();
	@Input() masterDetail = false;
	@Input() isRowMaster: (dataItem: any) => boolean = null;
	@Input() detailCellRenderer: any = null;
	@Input() detailCellRendererParams: any = null;
	@Input() detailRowHeight = 200;
	@Input() detailRowAutoHeight = false;
	@Input() getRowHeight?: (params: any) => number = null;
	@Input() totalRecords?: number;
	@Input() selectedData?: any[];
	@Input() hideMainTreeNodeColumn: boolean = false;

	// Treeview Server Side
	// document: https://www.ag-grid.com/angular-data-grid/server-side-model-tree-data/
	@Input() isServerSideGroupOpenByDefault: (params) => boolean = null;
	@Input() isServerSideGroup: (dataItem: any) => any = null;
	@Input() getServerSideGroupKey: (dataItem: any) => any = null;
	@Input() rowModelType = 'clientSide';

	// Hyperlink support
	@Input() treeInnerRendererComponent: ICellRendererAngularComp;
	@Input() treeInnerRendererComponentParams: {};
	@Input() ignoreMobileView = false;

	@Output() addFabClick = new EventEmitter();
	@Output() cellClick = new EventEmitter();
	@Output() fabOptionClick = new EventEmitter();
	@Output() rowClick = new EventEmitter();
	@Output() rowDataChanged = new EventEmitter();
	@Output() rowDragCompleted = new EventEmitter();
	@Output() rowSelect = new EventEmitter<any>();
	@Output() mobileCommandRequested = new EventEmitter<{
		command: string;
		data: any;
	}>();
	@Output() gridReady = new EventEmitter();

	isMobile = false;
	isTablet = false;

	constructor(private deviceManager: DeviceManagerService) {}

	ngOnInit() {
		if (!this.ignoreMobileView) {
			this.deviceManager.isMobileChange
				.pipe(untilDestroyed(this))
				.subscribe((isMobile: boolean) => {
					this.isMobile = isMobile;
					this.isTablet = this.deviceManager.isTablet;
				});

			this.isMobile = this.deviceManager.isMobile;
			this.isTablet = this.deviceManager.isTablet;
		}
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed */
	}

	refreshCells(forceRefresh = false) {
		if (this.tableWrapper) this.tableWrapper.refreshCells(forceRefresh);
	}

	refreshHeader() {
		if (this.tableWrapper) {
			this.tableWrapper.gridOptions.api.refreshHeader();
		}
	}

	getColumnDefs(): any {
		if (this.tableWrapper) {
			return this.tableWrapper.gridOptions.columnDefs;
		}
	}

	setColumnDefs(columnDefs: any) {
		if (this.tableWrapper) {
			this.tableWrapper.gridOptions.api.setColumnDefs(columnDefs);
		}
	}

	/**
	 * Method to cause a single cell to be refreshed. The cell is first searched for using the function passed. Those which
	 * match are passed to the refreshCells API method.
	 * @param filterFn - function taking a single row's user data value (any) and returning true if the row should
	 * be refreshed and false if not
	 * @param columns - string array containing the set of columns to be refreshed
	 * @param forceRefresh - boolean value (default = false) indicating whether we should force this operation or
	 * just suggest it
	 */
	refreshCellsWhere(
		filterFn: (rowData: any) => boolean,
		columns?: string[],
		forceRefresh = false
	) {
		if (this.tableWrapper)
			this.tableWrapper.refreshCellsWhere(
				filterFn,
				columns,
				forceRefresh
			);
	}

	ensureNodeVisible(comparator: any, position?: 'top' | 'bottom' | 'middle') {
		if (this.tableWrapper)
			this.tableWrapper.ensureNodeVisible(comparator, position);
	}

	/**
	 * @summary Assure that all parent nodes in a tree are open until the indicated node is identified.
	 * @param comparator - function returning true when the indicated node matches. All parents for such
	 * a node will be expanded.
	 */
	expandTo(comparator: (node: any) => boolean) {
		if (this.tableWrapper) {
			this.tableWrapper.expandTo(comparator);
		}
	}

	/**
	 * Expand/collapse the tree for any values which match the comparator.
	 * @param comparator - Typically a function taking a tree node value and index and returning true if the
	 * node should be expanded.
	 */
	expandCollapse(comparator: (node: any, index: number) => boolean) {
		if (this.tableWrapper) {
			this.tableWrapper.expandCollapse(comparator);
		}
	}

	updateRowData(rowData: any) {
		this.tableWrapper.updateRowData(rowData);
	}
	setRowData(rowData: any) {
		this.tableWrapper.setRowData(rowData);
	}

	addItems(rowData: any[]) {
		this.tableWrapper.addItems(rowData);
	}

	onSelectionChanged(selectedGridItems) {
		this.rowSelect.emit(selectedGridItems);
	}
	sizeColumnsToFit() {
		this.tableWrapper.sizeColumnsToFit();
	}
	autoSizeColumns() {
		this.tableWrapper.autoSizeColumns();
	}
	onRowClicked(event) {
		this.rowClick.emit(event);
	}

	onCellClicked(event) {
		this.cellClick.emit(event);
	}

	handleRowChanged(event) {
		this.rowDataChanged.emit(event);
	}

	onDragCompleted(event) {
		this.rowDragCompleted.emit(event);
	}

	onTreeNodeExpanded(event) {
		this.treeNodeExpanded.emit(event);
	}

	onGridReady(event) {
		this.gridReady.emit(event);
	}

	handleFabClick() {
		this.addFabClick.emit();
	}

	onFabOptionSelected(opt) {
		if (opt) {
			this.fabOptionClick.emit(opt);
		}
	}

	/**
	 * Sorts by specified columns, uses the default comparator function for the sorting
	 * behavior defined in inside buildDefaultColDefs().
	 * The sorting takes priority in the order the columns are sent as parameters,
	 * eg: ['address', 'name', 'channel'] - address takes higher priority over name,
	 * name higher priority over channel, and so on.
	 * Must be called on ngAfterViewInit() once we have the child reference component.
	 * @param sortColumn - Must match the sorting column, can be either a string or an array of strings,
	 * if sortColumn is type string[], array order matter to set higher->lower priority based on the array index,
	 * NOTE: this will enable sorting property on the specified column(s).
	 * @param sortType - The sort type we want to use, this can be either string or array, if parameter is string, it will set
	 * the same sort type for single (sortColumn: string) or multiple (sortColumn: string[]) columns,
	 * if array, it must match the length of the sortColumn array, setting the sort type for each position on the array,
	 * eg: (['address', 'name'], ['asc', 'desc']) - 'address' column is set sort ascendant and 'name' sort descendant.
	 * @param clearOtherSortColumns - If true, this will be the only sortable column and will disable sorting for other columns,
	 * NOTE: Cannot be used in a sortColumn type string[].
	 */
	sortByColumn(
		sortColumn: string | string[],
		sortType: 'desc' | 'asc' | ('desc' | 'asc')[],
		clearOtherSortColumns: boolean = false
	) {
		this.tableWrapper.sortByColumn(
			sortColumn,
			sortType,
			clearOtherSortColumns
		);
	}

	/**
	 * Clears the sorting on the specified column(s), this does not enable or disable
	 * any sortable property, just clears any sorting applied.
	 * If leave empty, it will clear all columns from sorting.
	 * @param clearColumn the column we want to clear the sorting.
	 */
	clearSort(clearColumn?: string | string[]) {
		this.tableWrapper.clearSort(clearColumn);
	}

	unSelectAll() {
		this.tableWrapper.unSelectAll();
	}

	resetRowHeights() {
		this.tableWrapper.resetRowHeights();
	}

	onMobileCommandRequested(event) {
		this.mobileCommandRequested.emit(event);
	}
}

/* NOTE: DO NOT USE THESE CONSTANTS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbConstants Namespace.
 * 		 E.g., RbConstants.AppState.ALERT_LOG_TYPE
 * 		 See: _rb.constants.ts
 */

export namespace XXUseRbConstantsNamespace {

	export abstract class Cache {

		static readonly PROGRAM_STEP = 'PROGRAM_STEP';

	}
}

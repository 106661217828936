import * as moment from 'moment';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { EditStartTimeComponent } from './edit-start-time.component';
import { GroupStartTime } from '../../../api/programs/models/group-start-time.model';
import { MatDialog } from '@angular/material/dialog';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StartTime } from '../../../api/programs/models/start-time.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-edit-start-times-array',
	templateUrl: './edit-start-times-array.component.html',
	styleUrls: [ './edit-start-times-array.component.scss' ]
})
export class EditStartTimesArrayComponent implements OnInit {
	@ViewChild('startTimeWarning', { static: true }) startTimeWarning;
	@ViewChild('addStartTime', { static: true }) addStartTime: NgxMaterialTimepickerComponent;

	@Output() dialogDisplayed = new EventEmitter<boolean>();

	@Input() timesFormArray: FormArray;
	@Input() disableAddStartTime = false;
	@Input() isProgram = false;
	@Input() isProgramTool = false;
	@Input() addStartTimeLabel = 'STRINGS.PROGRAM_START_TIMES';
	@Input() maxStartTimes = RbConstants.Form.MAX_START_TIMES_DEFAULT.max;
	@Input() iqNetClient = false;

	RbFormConstants = RbConstants.Form;

	title: string;
	isGolfSite: boolean;
	maxStartTimesReached = false;
	cultureSettings: CultureSettings;
	format: number;
	timePickerValue: string;
	groupStartTimes: FormGroup[] = []; // Program start times for Commercial and Program Group start times for Golf
	delayedUntilStartTime: FormGroup; // First schedule start time for Golf
	scheduleStartTimes: FormGroup[] = []; // Other schedule start times for Golf
	isReadOnly = false;
	maxStartTimesReachedMsg: string;
	patchProgramGroup: any = {
		add: [],
		delete: { ids: [] },
		update: [],
	};

	deletedStartTimes: StartTime[] = [];

	static buildItems(formArray: FormArray, startTimes: StartTime[]) {
		startTimes.forEach(startTime => formArray.push(EditStartTimeComponent.buildItem(startTime)));
	}

	static reBuildItemsForProgramTool(formArray: FormArray, startTimes: StartTime[]) {

		startTimes.forEach(startTime => formArray.push(EditStartTimeComponent.buildItem(startTime)));
	}

	static buildGroupItems(formArray: FormArray, startTimes: GroupStartTime[]) {
		startTimes.forEach(startTime => formArray.push(EditStartTimeComponent.buildGroupItem(startTime)));
	}

	constructor(private authManager: AuthManagerService,
				public dialog: MatDialog,
				private translate: TranslateService) { }

	getPatchValues(id: number, isGroup: boolean): any {

		const patch: any = {
			add: [],
			delete: { id: id, ids: [] },
			update: [],
		};
		this.timesFormArray.controls.forEach(control => {
			const startTime = <StartTime>control.value;
			if (startTime.id === -1) {
				if (isGroup) {
					patch.add.push({ dateTime: RbUtils.Common.formatDateForApi(startTime.dateTime), enabled: startTime.enabled, programGroupId: id });
				} else {
					patch.add.push({ dateTime: RbUtils.Common.formatDateForApi(startTime.dateTime), enabled: startTime.enabled, programId: id });
				}
			} else if (control.dirty) {
				if (isGroup) {
					patch.update.push(
						{ id: startTime.id, dateTime: RbUtils.Common.formatDateForApi(startTime.dateTime), enabled: startTime.enabled, programGroupId: id });
				} else {
					patch.update.push(
						{ id: startTime.id, dateTime: RbUtils.Common.formatDateForApi(startTime.dateTime), enabled: startTime.enabled, programId: id });
				}
			}
		});
		this.deletedStartTimes.forEach(startTime => {
			patch.delete.ids.push(startTime.id);
		});

		return patch;
	}

	getPatchValuesForProgramGroup(id: number): any {
		this.timesFormArray.controls.forEach(control => {
			const startTime = <StartTime>control.value;
			if (startTime.id === -1) {
				this.patchProgramGroup.add.push({ 
					dateTime: RbUtils.Common.formatDateForApi(startTime.dateTime), enabled: startTime.enabled, programGroupId: id 
				});
			} else if (control.dirty) {
				this.patchProgramGroup.update.push({
					id: startTime.id, dateTime: RbUtils.Common.formatDateForApi(startTime.dateTime), enabled: startTime.enabled, programGroupId: id
				});
			}
		});
		this.deletedStartTimes.forEach(startTime => {
			this.patchProgramGroup.delete.ids.push(startTime.id);
		});

		return this.patchProgramGroup;
	}

	ngOnInit() {
		this.isReadOnly = this.authManager.isReadOnly || this.iqNetClient;
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		this.getTimePickerData();
	}

	getTimePickerData() {
		this.cultureSettings = this.authManager.userCulture;
		this.format = RbUtils.Common.getTimePickerFormat(this.cultureSettings);
	}

	onTimeAdded(timeString: string) {
		let startTime;
		if (this.cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm) {
			const startDate = RbUtils.Conversion.convertTimeStringToDate(timeString);
			startTime = new StartTime({ id: -1, dateTime: startDate, enabled: true });
		} else {
			startTime = new StartTime({
				id: -1,
				dateTime: moment(timeString, 'HH:mm:ss').toDate(),
				enabled: true,
			});
		}
		if (this.timesFormArray.value && this.timesFormArray.value.length > 0)  {
			if (!this.timesFormArray.value.find(st => RbUtils.Common.isSameTime(moment(st.dateTime).toDate(), startTime.dateTime)))
				this.timesFormArray.push(EditStartTimeComponent.buildItem(startTime));
		} else {
			this.timesFormArray.push(EditStartTimeComponent.buildItem(startTime));
		}
		this.timesFormArray.markAsDirty();
		this.timesUpdated();
	}

	addStartTimeClicked() {
		this.addStartTime.updateTime(RbUtils.Common.getTimePickerInitialFormat(this.cultureSettings));
		this.maxStartTimesReached = !this.isGolfSite && (this.timesFormArray.controls.length >= this.maxStartTimes);
		if (this.maxStartTimesReached) {
			this.maxStartTimesReachedMsg = this.translate.instant('STRINGS.MAX_START_TIMES_REACHED', { maxStartTimes: this.maxStartTimes });
			this.dialog.open(this.startTimeWarning);
			return;
		}

		this.addStartTime.open();
	}

	onRemoveStartTime(formGroup: FormGroup) {
		const index = this.timesFormArray.controls.findIndex(c => c.get('dateTime').value === formGroup.controls['dateTime'].value);
		if (this.timesFormArray.controls[index].get('id').value !== -1) {
			this.deletedStartTimes.push(this.timesFormArray.controls[index].value);
		}
		this.timesFormArray.removeAt(index);
		this.timesFormArray.markAsDirty();
		this.timesUpdated();
	}

	onUpdateStartTime() {
		this.timesFormArray.markAsDirty();
		this.timesUpdated();
	}

	onTimePickerOpened() {
		this.dialogDisplayed.emit(true);
	}

	onTimePickerClosed() {
		this.dialogDisplayed.emit(false);
	}

	timesUpdated() {
		if (!this.isGolfSite) {
			this.groupStartTimes = <FormGroup []>this.timesFormArray.controls;
			return;
		}

		this.groupStartTimes = <FormGroup []>this.timesFormArray.controls.filter(c => this.isProgram || c.get('isProgramGroup').value === true);
		this.scheduleStartTimes = <FormGroup []>this.timesFormArray.controls.filter(c => !this.isProgram && c.get('isProgramGroup').value === false);
		this.delayedUntilStartTime = null;

		if (this.groupStartTimes.length > 1 || this.scheduleStartTimes.length === 0) return;

		// Sort the schedule start times. This special sort places schedule start times that occur "today" (after the group start time) first in the
		// list and schedule start times that occur "tomorrow" (before the group start time) last in the list.
		const groupStartTimeDate = this.groupStartTimes.length === 0 ? new Date(2020, 0, 1, 0, 0, 0) : this.groupStartTimes[0].get('dateTime').value;
		const groupStartTime = RbUtils.Conversion.convertTimeToMilliseconds(groupStartTimeDate);
		this.scheduleStartTimes.sort((control1, control2) => {
			const st1 = RbUtils.Conversion.convertTimeToMilliseconds(control1.get('dateTime').value);
			const st2 = RbUtils.Conversion.convertTimeToMilliseconds(control2.get('dateTime').value);
			if (st1 < groupStartTime && st2 > groupStartTime) return 1; // st2 is today and st1 is tomorrow
			if (st2 < groupStartTime && st1 > groupStartTime) return -1; // st1 is today and st2 is tomorrow
			return st1 - st2;
		});

		this.delayedUntilStartTime = this.scheduleStartTimes[0];
		this.scheduleStartTimes = this.scheduleStartTimes.slice(1);
	}
}

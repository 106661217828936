export class StationSensor {

	companyId: number;
	id: number;
	sensorId: number;
	stationId: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

import { ActiveFlowListItem } from '../../../../../../api/irrigation-activity/models/active-flow-list-item.model';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbUtils } from '../../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-active-flow-status-cell-renderer',
	templateUrl: './active-flow-status-cell-renderer.component.html',
	styleUrls: ['./active-flow-status-cell-renderer.component.scss']
})
export class ActiveFlowStatusCellRendererComponent implements ICellRendererAngularComp {

	_statusString: string;
	isRunning = false;

	constructor() { }

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.updateProgramStatus(params);
	}

	refresh(params: any): boolean {
		this.updateProgramStatus(params);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	get statusString(): string {
		return this._statusString;
	}

	private updateProgramStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		const activeFlowListItem = <ActiveFlowListItem>params.data;
		this._statusString = params.value;
		this.isRunning = false;

		switch (this._statusString) {
			case '-':
				break;
			case RbUtils.Translate.instant(`STRINGS.STARTED`):
				this.isRunning = true;
				this._statusString = RbUtils.Translate.instant(`STRINGS.STARTED`);
				break;
			case RbUtils.Translate.instant(`STRINGS.RUNNING`):
				this.isRunning = true;
				this._statusString = RbUtils.Translate.instant(`STRINGS.RUNNING`);
				break;
		}

		if (activeFlowListItem.isStatusChange) {
			params.api.flashCells({
				rowNodes: [rowNode],
				columns: ['status']
			});
		}

	}

}

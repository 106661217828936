export class CollectionChange {

	constructor(collection: any[], isStatusUpdate = false) {
		this.collection = collection;
		this.isStatusUpdate = isStatusUpdate;
	}

	collection: any[];

	/** Flag to alert subscribers that the update was to existing items only. No new items were added nor were items removed. */
	isStatusUpdate = false;
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';

@Component({
	selector: 'rb-timer-countdown',
	templateUrl: './timer-countdown.component.html',
	styleUrls: ['./timer-countdown.component.scss'],
})
export class TimerCountdownComponent {
	private timer;
	private subTimer;
	countdownInSeconds = 5 * 60; // 5 minutes
	countdownString = TimerCountdownComponent.formCountdown(this.countdownInSeconds);

	private static formCountdown(countdown) {
		return `${(Math.floor(countdown / 60))} minutes ${countdown % 60} seconds`;
	}

	constructor(public dialogRef: MatDialogRef<TimerCountdownComponent>) {
		dialogRef.disableClose = true;
		this.start();
	}

	handleLogout() {
		this.closeDialog(true);
	}

	handleContinue() {
		this.closeDialog(false);
	}

	start() {
		this.timer = timer(0, 1000);
		this.subTimer = this.timer
			.subscribe(t => this.tickerFunc(t));
	}

	stop() {
		if (this.subTimer) {
			this.subTimer.unsubscribe();
		}
	}

	tickerFunc(tick) {
		const count = this.countdownInSeconds - tick;
		if (count < 1) {
			this.closeDialog(true);
		} else {
			this.countdownString = TimerCountdownComponent.formCountdown(count);
		}
	}

	closeDialog(bLogout) {
		this.stop();
		this.dialogRef.close(bLogout);
	}
}

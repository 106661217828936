import * as _common from './rb-common.enums';
import * as _iqEvents from './rb-iq-event.enums';
import * as _map from './rb-map.enums';
import * as _note from './rb-note.enums';
import * as _signalR from './rb-signalR.enums';

export namespace RbEnums {

	export import Common = _common.XXUseRbEnumsNamespace.Common;
	export import IQEvents = _iqEvents.XXUseRbEnumsNamespace.IQEvents;
	export import Map = _map.XXUseRbEnumsNamespace.Map;
	export import Note = _note.XXUseRbEnumsNamespace.Note;
	export import SignalR = _signalR.XXUseRbEnumsNamespace.SignalR;
	
}

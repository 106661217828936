/* eslint-disable @typescript-eslint/member-ordering */
import { GolfSensor } from './golf-sensor.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class GolfProgrammableSensor extends GolfSensor {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(sensorType: RbEnums.Common.SensorType, json = null) {
		super(json);

		if (!json) { this.initialize(sensorType); }
	}

	// =========================================================================================================================================================
	// Public Members
	// =========================================================================================================================================================

	updateFromPatch(patch: {}) {
		Object.keys(patch).forEach(name => {
			if (name === 'addressInt') {
				this[name] = +patch[name];
			}
			else if (name === 'secondsPerUpdate') {
				// RB-9864: If we have a sensor polling interval, save it. There are a couple of special cases:
				// 1. If the value is empty string or whitespace or null, save 'null' to the sensor image.
				// 2. If the value is not-empty and not whitespace only or null, save the value as a number.
				this[name] = RbUtils.Common.isNullOrWhiteSpace(patch[name]) ?
					null :
					+patch[name];
			}
			else {
				this[name] = patch[name];
			}
		});
	}

	toPlainObj() {
		return {
			name: this.name,
			type: this.type,
			isIrrigationEngineBased: this.isIrrigationEngineBased,
			satelliteId: this.satelliteId,
			groupNumber: this.groupNumber,
			addressInt: this.addressInt,
			notes: this.notes,
			channel: this.channel,
			subChannel: this.subChannel,
			secondsPerUpdate: this.secondsPerUpdate,
		};
	}

}

import { getStorageInfo } from 'leaflet.offline';
import { MapInfoLeaflet } from './map-info-leaflet.model';
import { MapLeafletService } from '../services/map-leaflet.service';
import { MessageBoxInfo } from '../../core/components/global-message-box/message-box-info.model';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';
import { ReplaySubject } from 'rxjs';

export class DownloadTilesControl {

	private static _hasDownloadedTiles: boolean;
	private static _downloadedTilesChange: ReplaySubject<boolean>;

	/**
	 * Fires a boolean value based on whether we have downloaded map tiles or not
	 */
	static get downloadedTilesChange() {
		if (!DownloadTilesControl._downloadedTilesChange) {
			DownloadTilesControl._downloadedTilesChange = new ReplaySubject(1);
			DownloadTilesControl._downloadedTilesChange.subscribe(value => DownloadTilesControl._hasDownloadedTiles = value);
			getStorageInfo(MapLeafletService.EsriURL)
				.then(info => DownloadTilesControl._downloadedTilesChange.next(info.length > 0));
		}
		return DownloadTilesControl._downloadedTilesChange;
	}

	static get hasDownloadedTiles() {
		if (DownloadTilesControl._hasDownloadedTiles === undefined ) {
			const s = DownloadTilesControl.downloadedTilesChange.subscribe(() => { s.unsubscribe(); });
		}
		return DownloadTilesControl._hasDownloadedTiles;
	}

	static addProgressBar(mapInfo: MapInfoLeaflet) {
		return new Promise((resolve, reject) => {
			if (mapInfo.leafletMapContainerNodeElement == null) {
				reject();

				return ;
			}
			mapInfo.downloadingTiles = true;
			const downloadingProgress = document.querySelectorAll<HTMLElement>('.button-container.downloading-tiles span');
			downloadingProgress.forEach(function(progressBar) {
				progressBar.parentElement.classList.remove('d-none');
				progressBar.innerHTML = '0';
			});
			resolve(true);
		});
	}

	static updateProgressBar(mapInfo: MapInfoLeaflet, progress: number, total: number) {
		return new Promise((resolve) => {
			mapInfo.downloadingTiles = true;
			const downloadingProgress = document.querySelectorAll<HTMLElement>('.button-container.downloading-tiles span');
			downloadingProgress.forEach(function(progressBar) {
				progressBar.parentElement.classList.remove('d-none');
				progressBar.parentElement.querySelector('.bg').setAttribute('style', `width: ${Math.ceil((progress / total) * 100)}%`);
				progressBar.innerHTML = Math.ceil((progress / total) * 100) + '%';
			});
			resolve(true);
		});
	}

	static progressBarCompleted(mapInfo: MapInfoLeaflet) {
		return new Promise((resolve) => {
			setTimeout(async () => {
				mapInfo.downloadingTiles = false;
				const downloadingProgress = document.querySelectorAll<HTMLElement>('.button-container.downloading-tiles span');
				downloadingProgress.forEach(function(progressBar) {
					progressBar.innerHTML = 'Complete';
					setTimeout(() => {
						progressBar.parentElement.classList.add('d-none');
						progressBar.parentElement.querySelector('.bg').removeAttribute('style');
					}, 1000);
				});
				resolve(true);
			}, 100);
		});
	}

	static downloadInProgressAlert(mapInfo: MapInfoLeaflet) {
		const mbi = MessageBoxInfo.create(
			RbUtils.Translate.instant('STRINGS.OFFLINE_TILES'),
			RbUtils.Translate.instant('STRINGS.TILES_DOWNLOAD_IN_PROGRESS'),
			null,
			null,
			null,
			RbEnums.Common.MessageBoxButtons.Ok
		);
		mapInfo.messageBoxService.showMessageBox(mbi);
	}

	static removeInProgressAlert(mapInfo: MapInfoLeaflet) {
		const mbi = MessageBoxInfo.create(
			RbUtils.Translate.instant('STRINGS.OFFLINE_TILES'),
			RbUtils.Translate.instant('STRINGS.TILES_REMOVE_IN_PROGRESS'),
			null,
			null,
			null,
			RbEnums.Common.MessageBoxButtons.Ok
		);
		mapInfo.messageBoxService.showMessageBox(mbi);
	}

}

import { DiagnosticData } from './diagnostic-data.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class IcVoltagePollData extends DiagnosticData {

	feedback: string;
	group: number;
	result: number;
	satelliteId: number;
	sensorId: number;
	stationId: number;

	constructor(json: any = null) {
		super(json);

		if (json) {
			Object.assign(this, json);
		}
		this.cdType = RbEnums.Common.DiagnosticDataType.ICVoltage;
	}
}

import { Component, HostBinding, OnInit } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

/**
 * BaseCellTooltipComponent handles display of a tooltip (when the tooltip text is non-empty). Instances
 * of this class are dynamically created when the user mouses over a cell. That is, the tooltip is not
 * created until needed.
 * - Tooltip text is provided by the renderCallback element of the agInit parameters for this class
 * which are of type ITooltipParams, which the callers add to the ITooltipParams properties.
 * - The component can use the data for the row which is provided by the agGrid API. The 'data' item is
 * typically the ProgramStep or Station, etc. the list of which is being displayed in the agGrid.
 * - The component exports a hideTooltip property which, when true, causes the mouseOver event for the
 * cell to be ignored.
 */
@Component({
	selector: 'rb-base-cell-tooltip',
	templateUrl: './base-cell-tooltip.component.html',
	styleUrls: [ './base-cell-tooltip.component.scss' ]
})
export class BaseCellTooltipComponent implements OnInit, ITooltipAngularComp {

	@HostBinding('class') class = 'base-cell-tooltip-host';

	hideTooltip = true;

	// private params: any;
	private data: any;
	private renderCallback: any;

	constructor() { }

	ngOnInit() {
	}

	/**
	 * Initialize the tooltip rendering component. This occurs when the mouseEnter operation occurs, I believe.
	 * We conventionally require that params include a 'renderCallback' property consisting of a method/function
	 * pointer taking an instance of the 'data' value for the row for which the tooltip is fired. The column
	 * for which it is fired can be extracted from other params, if necessary.
	 * @param params -
	 * - api: GridAPI
	 * - colDef: column definition
	 * - column: Column instance describing the actual column whose tooltip is being shown. { colId: name of the
	 * column from the table definition for RBCC }
	 * - columnAPI: ColumnApi
	 * - context: { componentParent: TableWrapperComponent }
	 * - rowIndex: number index of the row, 0-based
	 * - value: any value of the current column
	 */
	agInit(params: ITooltipParams): void {
		// this.params = params;
		const row = params.api.getDisplayedRowAtIndex(params.rowIndex);
		if (row == null) return;

		this.data = row.data;
		this.renderCallback = (<any><unknown>params).renderCallback;
		if (this.renderCallback == null) {
			console.error('Tooltip for grid cell renderCallback missing. The tooltip will not render.');
		}
	}

	/**
	 * Returns the correct HTML for rendering in the tooltip. We set our hideTooltip property to true if the
	 * returned HTML from renderCallback() is empty (length === 0). This getter will be called repeatedly while
	 * the tooltip is displayed.
	 */
	get cellHtml(): string {
		let rendererHtml = '';
		if (this.renderCallback != null) {
			rendererHtml = this.renderCallback(this.data);
		}
		this.hideTooltip = rendererHtml.length === 0;
		return rendererHtml;
	}
}

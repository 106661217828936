import { BehaviorSubject, Observable, of } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { HoleAreaPair } from '../../../../../api/sites/models/hole-area-pair.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { SelectionModel } from '@angular/cdk/collections';
import { SiteTreeView } from '../../../../../api/sites/models/site-tree-view.model';
import { TranslateService } from '@ngx-translate/core';
import { TreeViewFlatNode } from '../../../../../api/sites/models/tree-view-flat-node.model';

@Component({
	selector: 'rb-tree-view-checkbox',
	templateUrl: './tree-view-checkbox.component.html',
	styleUrls: ['./tree-view-checkbox.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeViewCheckboxComponent implements OnChanges, OnInit {
	@ViewChild('matSelect', { static: true }) matSelect: MatSelect;

	@Output() valueChange = new EventEmitter();

	@Input() requestTreeLevel: number;
	@Input() treeViewItems: SiteTreeView[] = [];

	checklistSelection;
	data = [];
	dataChange: BehaviorSubject<SiteTreeView[]> = new BehaviorSubject<SiteTreeView[]>([]);
	dataSource: MatTreeFlatDataSource<SiteTreeView, TreeViewFlatNode>;
	flatNodeMap = new Map<TreeViewFlatNode, SiteTreeView>();
	nestedNodeMap = new Map<SiteTreeView, TreeViewFlatNode>();  // Map from nested node to flattened node. This helps us to keep the same object for selection
	treeControl: FlatTreeControl<TreeViewFlatNode>;
	treeFlattener: MatTreeFlattener<SiteTreeView, TreeViewFlatNode>;
	selectedControllerIds: number[] = [];
	selectedControllersPlaceholder = 'STRINGS.SELECT_CONTROLLERS';
	controllersSelectedPlaceholder = 'STRINGS.CONTROLLERS_SELECTED';
	isGolfSite;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private translateService: TranslateService, private authManager: AuthManagerService) { }

	ngOnInit() {
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		if (this.isGolfSite) {
			this.selectedControllersPlaceholder = 'STRINGS.SELECT_AREAS';
			this.controllersSelectedPlaceholder = 'STRINGS.AREAS_SELECTED';
		}
		this.checklistSelection = new SelectionModel<TreeViewFlatNode>(true /* multiple */); // The selection for checklist
	}

	ngOnChanges() {
		this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
		this.treeControl = new FlatTreeControl<TreeViewFlatNode>(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
		this.data = this.treeViewItems;

		// Notify the change.
		this.dataChange.next(this.data);
		if (this.data !== undefined) {
			this.dataSource.data = this.data;
		}
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getLevel = (node: TreeViewFlatNode) => node.level;

	isExpandable = (node: TreeViewFlatNode) => node.expandable;

	getChildren = (node: SiteTreeView): Observable<SiteTreeView[]> => of(node.children);

	hasChild = (_: number, _nodeData: TreeViewFlatNode) => _nodeData.expandable;

	/**
	 * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
	 */
	transformer = (node: SiteTreeView, level: number) => {
		const existingNode = this.nestedNodeMap.get(node);
		/* eslint-disable-next-line @typescript-eslint/no-use-before-define */
		const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TreeViewFlatNode();
		flatNode.item = node.item;
		flatNode.isDisabled = node.isDisabled;
		flatNode.id = node.id;
		flatNode.level = level;
		flatNode.parentId = node.parentId;
		flatNode.expandable = node.children.length > 0;
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	}

	isSelected(node: TreeViewFlatNode) {
		return this.checklistSelection.isSelected(node);
	}

	/** Whether all the descendants of the node are selected */
	descendantsAllSelected(node: TreeViewFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		return descendants.every(child => this.checklistSelection.isSelected(child));
	}

	/** Whether part of the descendants are selected */
	descendantsPartiallySelected(node: TreeViewFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const result = descendants.some(child => this.checklistSelection.isSelected(child));
		return result && !this.descendantsAllSelected(node);
	}

	/** Toggle the to-do item selection. Select/deselect all the descendants node */
	itemSelectionToggle($event: MatCheckboxChange, node: TreeViewFlatNode): void {
		const descendants = this.treeControl.getDescendants(node);
		this.checklistSelection.toggle(node);
		$event.checked
			? this.checklistSelection.select(...descendants)
			: this.checklistSelection.deselect(...descendants);
		this.emitSelectionChange();
	}

	/** Emits the selected nodes on node selection change */
	onSelectedItemsChanged(node) {
		this.checklistSelection.toggle(node);
		this.emitSelectionChange();
	}

	onSelectAllChange($event: MatCheckboxChange) {
		if ($event.checked) {
			this.selectAllNode();
			return;
		}

		this.deselectAllNode();
	}

	get isAllNodesSelected() {
		const checker = true;
		if (!this.treeControl.dataNodes || this.treeControl.dataNodes.length === 0) {
			return false;
		}

		for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
			if (!this.isSingleOrAllDescendantsSelected(this.treeControl.dataNodes[i])) {
				return false;
			}
		}

		return checker;
	}

	private isSingleOrAllDescendantsSelected(node: TreeViewFlatNode) {
		if (!node.expandable) {
			return this.checklistSelection.isSelected(node);
		}

		return this.descendantsAllSelected(node);
	}

	private selectAllNode() {
		if (!this.treeControl.dataNodes || this.treeControl.dataNodes.length === 0) {
			return false;
		}

		this.treeControl.dataNodes.forEach((node) => {
			if (!node.isDisabled && !this.checklistSelection.isSelected(node)) {
				this.checklistSelection.select(node);
			}
		});
		this.emitSelectionChange();
	}

	private deselectAllNode() {
		if (!this.treeControl.dataNodes || this.treeControl.dataNodes.length === 0) {
			return false;
		}

		this.treeControl.dataNodes.forEach((node) => {
			if (this.checklistSelection.isSelected(node)) {
				this.checklistSelection.deselect(node);
			}
		});
		this.emitSelectionChange();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	/** Notify callers of our selection(s). */
	private emitSelectionChange() {
		const list = this.checklistSelection.selected.filter(x => x.level === this.requestTreeLevel);

		if (this.isGolfSite) {
			this.selectedControllerIds = list.map(y => new HoleAreaPair(y.parentId, y.id));
		} else {
			this.selectedControllerIds = list.map(y => y.id);
		}

		this.valueChange.emit(this.selectedControllerIds);

		switch (this.selectedControllerIds.length) {
			case 0:
				this.selectedControllersPlaceholder = 'STRINGS.SELECT_CONTROLLERS';
				break;
			default:
				this.selectedControllersPlaceholder =
					`${this.selectedControllerIds.length} ${this.translateService.instant(this.controllersSelectedPlaceholder)}`;
				break;
		}
	}

}

import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class NavigationHistoryService {
	private history: string[] = [];

	invokeOpenMenu = new EventEmitter<void>();

	constructor(
		private router: Router
	) {
		this.loadHistory();

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if (this.history.length === 0 || this.history[this.history.length - 1] != event.urlAfterRedirects) {
					this.addToHistory(event.urlAfterRedirects);
					this.saveHistory();
				}
			});
	}

	private addToHistory(url: string) {
		this.history = [...this.history, url];
	}

	getHistory(): string[] {
		return this.history;
	}

	canGoBack(): boolean {
		return this.history.length > 1;
	}

	navigateBack(): void {
		if (this.history.length > 1) {
			this.history.pop();
			const previousUrl = this.history[this.history.length - 1];
			this.router.navigateByUrl(previousUrl);
			this.saveHistory();
		}
	}

	private saveHistory() {
		localStorage.setItem('navigationHistory', JSON.stringify(this.history));
	}

	private loadHistory() {
		const storedHistory = localStorage.getItem('navigationHistory');
		if (storedHistory) {
			this.history = JSON.parse(storedHistory);
		}
	}
}
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class IrrigationEventLogListItem {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.timestamp) this.timestamp = RbUtils.Conversion.convertStringToDate(json.timestamp);
		}
	}

	id: number;
	timestamp: Date;
	eventType: RbEnums.Common.EventLogType;
	eventNumber: RbEnums.IQEvents.IQEvent;
	eventName: string;
	programId: number;
	scheduleId: number;
	stationId: number;
	programName: string = null;
	scheduleName: string = null;
	stationName: string = null;
	flowRateGPM: number;
	errorCode: number;

}

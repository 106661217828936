export class CCWeatherValuesData {

	weatherCode: number = null;
	temperature: number = null;
	humidity: number = null;
	windSpeed: number = null;
	windDirection: string = null;
	windDirectionDegrees: number = null;
	temperatureMin: number = null;
	temperatureMax: number = null;
	precipitationIntensity: number = null;
	dewPoint: number = null;
	pressureSurfaceLevel: number = null;
	sunriseTime: string = null;
	sunsetTime: string = null;
	et0: number = null;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.temperature) {
				// Temperature is in degrees C.
				this.temperature = json.temperature ? parseFloat(String(json.temperature)) : null;
			}
			if (json.temperatureMin) {
				// degrees C
				this.temperatureMin = json.temperatureMin ? parseFloat(String(json.temperatureMin)) : null;
			}
			if (json.temperatureMax) {
				// degrees C
				this.temperatureMax = json.temperatureMax ? parseFloat(String(json.temperatureMax)) : null;
			}
			if (json.humidity) {
				// %
				this.humidity = json.humidity ? parseFloat(String(json.humidity)) : null;
			}
			if (json.windSpeed) {
				// m/s
				this.windSpeed = json.windSpeed ? parseFloat(String(json.windSpeed)) : null;
			}
			if (json.precipitationIntensity) {
				this.precipitationIntensity = json.precipitationIntensity ? parseFloat(String(json.precipitationIntensity)) : null;
			}
			if (json.dewPoint) {
				// degrees C
				this.dewPoint = json.dewPoint ? parseFloat(String(json.dewPoint)) : null;
			}
			if (json.pressureSurfaceLevel) {
				// Pressure expressed in hPa. We leave rounding for later where we check the user's unit
				// system selection and get the right conversion and decimal places.
				this.pressureSurfaceLevel = json.pressureSurfaceLevel ? parseFloat(json.pressureSurfaceLevel) : null;
			}
			if (json.et0) {
				// mm
				this.et0 = json.et0 ? parseFloat(String(json.et0)) : null;
			}
			if (json.windDirection) {
				const windObj = CCWeatherValuesData.getWindDirection(json.windDirection);
				this.windDirection = windObj.direction;
				this.windDirectionDegrees = windObj.degrees;
			}
		}
	}

	/**
	 * Convert wind direction in degrees to a combination of direction name (NNW, SE, etc.) and the corresponding degrees indicator
	 * of that direction. This effectively stepifies the wind direction, reducing its resolution.
	 * @param windDirectionDegrees - direction in degrees as a string or number
	 * @returns {direction: string, degrees: number} containing the adjust direction information
	 */
	static getWindDirection(windDirectionDegrees: string | number): {direction: string, degrees: number} {
		// We use 11.25 degrees "steps" to convert wind direction to directional names.
		switch (Math.round(parseFloat(String(windDirectionDegrees)) / 11.25)) {
			case 0:
			case 31:
				return {direction: 'N', degrees: 0};
			case 1:
			case 2:
				return {direction: 'NNE', degrees: 22.5};
			case 3:
			case 4:
				return {direction: 'NE', degrees: 45};
			case 5:
			case 6:
				return {direction: 'ENE', degrees: 67.5};
			case 7:
			case 8:
				return {direction: 'E', degrees: 90};
			case 9:
			case 10:
				return {direction: 'ESE', degrees: 112.5};
			case 11:
			case 12:
				return {direction: 'SE', degrees: 135};
			case 13:
			case 14:
				return {direction: 'SSE', degrees: 157.5};
			case 15:
			case 16:
				return {direction: 'S', degrees: 180};
			case 17:
			case 18:
				return {direction: 'SSW', degrees: 202.5};
			case 19:
			case 20:
				return {direction: 'SW', degrees: 225};
			case 21:
			case 22:
				return {direction: 'WSW', degrees: 247.5};
			case 23:
			case 24:
				return {direction: 'W', degrees: 270};
			case 25:
			case 26:
				return {direction: 'WNW', degrees: 292.5};
			case 27:
			case 28:
				return {direction: 'NW', degrees: 315};
			case 29:
			case 30:
				return {direction: 'NNW', degrees: 337.5};
			default:
				return {direction: null, degrees: null};
		}
	}
}

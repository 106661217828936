import { Controller } from '../../controllers/models/controller.model';
import { ProgramGroup } from '../../program-groups/models/program-group.model';
import { ProgramStep } from './program-step.model';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StartTime } from './start-time.model';
import { WeatherSource } from '../../weather-sources/models/weather-source.model';

export class Program {

	readonly maxDelayBetweenStationsInMinutes = 10;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.lastWddateAdvEt) { this.lastWddateAdvEt = RbUtils.Conversion.convertStringToDate(json.lastWddateAdvEt); }
			if (json.nextCyclicalStartDate) { this.nextCyclicalStartDate = RbUtils.Conversion.convertStringToDate(json.nextCyclicalStartDate); }
			if (json.programGroup) { this.programGroup = new ProgramGroup(json.programGroup); }
			if (json.programStep) { this.programStep = json.programStep.map(ps => new ProgramStep(ps)); }
			if (json.restrictionEnd) { this.restrictionEnd = RbUtils.Conversion.convertStringToDate(json.restrictionEnd); }
			if (json.restrictionStart) { this.restrictionStart = RbUtils.Conversion.convertStringToDate(json.restrictionStart); }
			if (json.scheduleStartAdvEt) { this.scheduleStartAdvEt = RbUtils.Conversion.convertStringToDate(json.scheduleStartAdvEt); }
			if (json.startTime) { this.startTime = json.startTime.map(st => new StartTime(st)); }
			if (json.stopTime) { this.stopTime = RbUtils.Conversion.convertStringToDate(json.stopTime); }
			if (json.tempAdjustStart) { this.tempAdjustStart = RbUtils.Conversion.convertStringToDate(json.tempAdjustStart); }
			if (json.tempAdjustStop) { this.tempAdjustStop = RbUtils.Conversion.convertStringToDate(json.tempAdjustStop); }
			if (json.weatherSource) { this.weatherSource = new WeatherSource(json.weatherSource); }
		}
	}

	autoSend: boolean;
	companyId: number;
	daysRemaining: number;
	etAdjustType: RbEnums.Common.EtAdjustType;
	excludedDays: string;
	floManaged: boolean;
	forcedProgression: boolean;
	hybridWeekDays: string;
	id: number;
	isEnabled = false;
	isSequential = false;
	language: string;
	lastWddateAdvEt: Date;
	mcInstructions: string;
	minimumProgramSkipDaysToDaysRemainingDifference: number;
	name: string;
	nextCyclicalStartDate: Date;
	nominalET: number;
	number: number;
	overrideCycleSoak = false;
	overrideStationAdjust = false;
	overrideWB = false;
	priority: number;
	programAdjust: number;
	programGroup: ProgramGroup;
	programGroupId: number;
	programStep: ProgramStep[];
	protected: boolean;
	repeats: number;
	restrictionEnd: Date;
	restrictionStart: Date;
	satellite: Controller;
	satelliteId: number;
	scheduleStartAdvEt: Date;
	shortName: string;
	simultaneousStations: number;
	skipDays: number;
	startTime: StartTime[];
	stationDelayLong: number;
	stopTime: Date;
	tempAdjustStart: Date;
	tempAdjustStop: Date;
	tempAdjustDays: number;
	tempProgramAdjust: number;
	type: RbEnums.Common.ProgramType;
	wbMacroID: number;
	weatherSource: WeatherSource;
	weatherSourceId: number;
	weekDays: string;
	weekDaysAdvEt: string;

	get etAdjustTypeName(): string { return Program.getEtAdjustTypeName(this.etAdjustType); }

	static getEtAdjustTypeName(etAdjustType: RbEnums.Common.EtAdjustType) {
		switch (etAdjustType) {
			case RbEnums.Common.EtAdjustType.None:
				return RbUtils.Translate.instant('STRINGS.TIME_BASED');
			case RbEnums.Common.EtAdjustType.SeasonalAdjust:
				return RbUtils.Translate.instant('STRINGS.SITE_SEASONAL_ADJUST');
			case RbEnums.Common.EtAdjustType.WeatherSource:
				return RbUtils.Translate.instant('STRINGS.ADVANCED_ET');
			case RbEnums.Common.EtAdjustType.SiteWaterBudget:
				return RbUtils.Translate.instant('STRINGS.SITE_WATER_BUDGET');
			case RbEnums.Common.EtAdjustType.WaterBudget:
				return RbUtils.Translate.instant('STRINGS.WATER_BUDGET');
			case RbEnums.Common.EtAdjustType.SimpleET:
				return RbUtils.Translate.instant('STRINGS.SIMPLE_ET');
		}
	}

	// get isEtAdjusted(): boolean { return Program.getIsEtAdjusted(this.etAdjustType); }

	static getIsEtAdjusted(etAdjustType: RbEnums.Common.EtAdjustType): boolean {
		switch (etAdjustType) {
			case RbEnums.Common.EtAdjustType.SimpleET:
			case RbEnums.Common.EtAdjustType.WeatherSource:
			case RbEnums.Common.EtAdjustType.SeasonalAdjust:
				return true;
			default:
				return false;
		}
	}

	get waterCycle(): string {
		return RbUtils.Weekdays.getStartDays(this.type, this.weekDays, this.hybridWeekDays);
	}

	get isAdvancedET(): boolean {
		// RB-13399: UNDER NO CIRCUMSTANCES SHOULD THIS EVER RETURN TRUE FOR GOLF -- EVER! Checking the program.satelliteId is 
		// a fair way to distinguish a commercial program from a golf program.
		return this.satelliteId != null && this.etAdjustType === RbEnums.Common.EtAdjustType.WeatherSource;
	}

	static createDefault(isGolf: boolean): Program {
		const program = new Program();
		program.daysRemaining = 0;
		program.minimumProgramSkipDaysToDaysRemainingDifference = 1;
		program.id = 0;
		program.name = '';
		program.type = 0;
		// program.weekDays = isGolf ? '1111111' : '0000000';
		program.weekDays = '0000000';
		program.hybridWeekDays = '1111111';
		program.skipDays = 1;
		program.shortName = '';
		program.programAdjust = RbConstants.Form.PROGRAM_ADJUST;
		program.stationDelayLong = 0;
		program.simultaneousStations = RbConstants.Form.DEFAULT_SIMULTANEOUS_STATIONS;
		program.etAdjustType = 0;
		program.weekDaysAdvEt = '0000000';
		program.isSequential = false;
		program.forcedProgression = false;
		program.overrideCycleSoak = false;
		program.overrideWB = false;
		program.overrideStationAdjust = false;
		program.companyId = 0;
		program.isEnabled = true;
		program.restrictionStart = null;
		program.restrictionEnd = null;
		program.number = 0;
		program.tempProgramAdjust = 100;
		program.tempAdjustDays = 0;
		return program;
	}
}

import * as moment from 'moment';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Sensor } from './sensor.model';

export class WeatherSensor extends Sensor {

	borrowedWsid: number;
	borrowState: RbEnums.Common.BorrowState;
	localWeatherSensorTypeId: number;
	settlingTime: moment.Duration;
	weatherSensorModel: number;

	constructor(json: any = null) {

		super(json);

		if (json) {
			if (json.settlingTime) { this.settlingTime = RbUtils.Conversion.convertHMSObjectToDuration(json.settlingTime); }
		}
	}

	static createDefault(sensorType: RbEnums.Common.SensorType): WeatherSensor {
		const sensor = new WeatherSensor();
		sensor.initialize(sensorType);
		sensor.settlingTime = moment.duration({ minutes: 1});
		return sensor;
	}

	static createLocalDefault(sensorType: RbEnums.Common.SensorType): WeatherSensor {
		const sensor = this.createDefault(sensorType);
		sensor.localWeatherSensorTypeId = -1;
		return sensor;
	}
}

<mat-form-field>
	<mat-select #matSelect
		[placeholder]="label"
		multiple="true"
		#dataSelect="ngModel"
		[ngModel]="selectedItems"
		[compareWith]="equals"
		[panelClass]="'multiselect-dropdown'"
		(selectionChange)="onSelectionChange($event.value)"
		(closed)="onSelectClosed($event)"
		(opened)="onSelectOpened($event)"
	>
		<div
			disabled="disabled"
			class="mat-option db mat-select-all-options">
			<button
				class="fl"
				mat-button
				color="primary"
				type="button"
				(click)="selectAll(items)">
				{{'STRINGS.SELECT_ALL_UPPERCASE' | translate}}
			</button>
			<button
				class="fr"
				mat-button
				color="primary"
				type="button"
				(click)="deselectAll()">
				{{'STRINGS.CLEAR_UPPERCASE' | translate}}
			</button>
		</div>
		<mat-select-trigger class="mat-select-trigger">
			{{selectedItems && selectedItems.length > 0 ? selectedItems[0].name : ''}}
			<span *ngIf="selectedItems && selectedItems.length > 1">
				(+{{selectedItems.length - 1}} more)
			</span>
		</mat-select-trigger>
		<div class="options-list">
			<mat-option
				*ngFor="let item of items"
				[value]="item"
			>
				{{ item.name }}
			</mat-option>
		</div>
		<div class="mat-option flex">
			<div *ngIf="showSelectionCount" class="pa3 mat-form-field-label fl">{{selectedItems ? selectedItems.length : 0 }} / {{items?.length}}</div>
			<button
				mat-raised-button
				class="flex-shrink-0 flex-grow-1"
				color="primary"
				type="button"
				(click)="onDoneClick()">
				{{'STRINGS.DONE' | translate}}
			</button>
		</div>
	</mat-select>
</mat-form-field>

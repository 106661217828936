import * as moment from 'moment';
import { ApiService, HttpMethod } from '../_common/api.service';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { WeatherData } from './models/weather-data.model';

import WeatherSourceType = RbEnums.Common.WeatherSourceType;


@Injectable({
	providedIn: 'root'
})
export class WeatherDataApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getWeatherData(weatherSourceId: number, startDate: string, endDate: string): Observable<WeatherData> {
		return this.apiRequest<any>(this.getWeatherDataUrl(weatherSourceId, startDate, endDate), HttpMethod.Get)
			.pipe(map(response => new WeatherData(response)));
	}

	getWeatherSensorData(sensorId: number, startDate: string, endDate: string): Observable<WeatherData[]> {
		// TODO: Remove mock data - hourly
		if (false) {
			const start = moment(startDate);
			const end = moment(endDate);
			let hour = start;
			const results: WeatherData[] = [];
			if (end.diff(start, 'days') !== 5) {
				if (RbUtils.Common.randomNumber(1, 2) < 2) {
					while (hour < end) {
						let rainAmount = RbUtils.Common.randomNumber(0, 200) - 150;
						rainAmount = Math.max(0, rainAmount / 200);
						if (RbUtils.Common.randomNumber(1, 2) < 2) {
							results.push(new WeatherData({
								sensorId: sensorId,
								timestamp: hour.toDate(),
								rainCurrent: rainAmount,
							}));
						}
						hour = hour.add(1, 'hour');
					}
				}
			}
			return of(results);
		}

		return this.apiRequest<any>(this.getWeatherSensorDataUrl(sensorId, startDate, endDate), HttpMethod.Get)
			.pipe(map(results => results.map(json => new WeatherData(json))));
	}

	getWeatherSensorDataHistory(sensorIds: number[], lookbackHours: number): Observable<{ [sensorId: number]: WeatherData[] }> {
		return this.apiRequest<{ [sensorId: number]: any[] }>(this.getWeatherSensorDataHistoryUrl(sensorIds, lookbackHours), HttpMethod.Get)
			.pipe(map(response => {
				Object.keys(response).forEach(sensorId => {
					response[sensorId] = response[sensorId].map(json => new WeatherData(json));
				});
				return response;
			}));
	}

	getLatestWeatherData(weatherSourceId: number): Observable<WeatherData> {
		return this.apiRequest<any>(this.getLatestWeatherDataUrl(weatherSourceId), HttpMethod.Get)
			.pipe(map(response => new WeatherData(response)));
	}

	getWeatherDataOfWeatherSource(weatherSourceId: number, startTime: string, endTime: string, includeWeatherSource = false): Observable<WeatherData[]> {
		return this.apiRequest<any>(this.getWeatherDataOfWeatherSourceUrl(weatherSourceId, startTime, endTime, includeWeatherSource),
			HttpMethod.Get,
			{weatherSourceId, startTime, endTime})
			.pipe(map(results => results.map(json => new WeatherData(json))));
	}

	updateWeatherData(weatherData: WeatherData[]) {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Put, weatherData);
	}

	getWeatherForecast(weatherSourceType: WeatherSourceType, location: string): Observable<any> {
		return this.apiRequest<any>(this.getWeatherForecastUrl(weatherSourceType, location), HttpMethod.Get);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}WeatherData`; }

	private get updateBatchesUrl(): string { return `${this.baseUrl}/UpdateWeatherData`; }

	private getWeatherDataUrl(id: number, startDate: string, endDate: string): string {
		return `${this.baseUrl}/GetWeatherData?weatherSourceId=${id}&startTime=${startDate}&endTime=${endDate}`;
	}
	private getWeatherSensorDataUrl(id: number, startDate: string, endDate: string): string {
		return `${this.baseUrl}/GetWeatherSensorData?sensorId=${id}&startTime=${startDate}&endTime=${endDate}`;
	}
	private getWeatherSensorDataHistoryUrl(sensorIds: number[], lookbackHours: number): string {
		const idsParam = sensorIds.reduce((list, sensorId) => `${list.length > 0 ? list + '&' : ''}sensorIds=${sensorId}`, '');
		return `${this.baseUrl}/GetWeatherSensorDataHistory?lookbackHours=${lookbackHours}&${idsParam}`;
	}
	private getLatestWeatherDataUrl(id: number): string {
		return `${this.baseUrl}/GetLatestWeatherData?weatherSourceId=${id}`;
	}

	private getWeatherDataOfWeatherSourceUrl(weatherSourceId: number, startTime: string, endTime: string, includeWeatherSource: boolean) {
		return `${this.baseUrl}/GetWeatherDataOfWeatherSource?weatherSourceId=${weatherSourceId}
		&startTime=${startTime}&endTime=${endTime}&IncludeWeatherSource=${includeWeatherSource}`;
	}

	private getWeatherForecastUrl(type: number, location: string): string {
		const encodedLocation = encodeURIComponent(location);

		return `${this.baseUrl}/Forecast?type=${type}&location=${encodedLocation}`;
	}
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { Snapshot } from '../../../../../api/controllers/models/snapshot';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-restore-data-mobile-cell-renderer',
	templateUrl: './restore-data-mobile-cell-renderer.component.html',
	styleUrls: ['./restore-data-mobile-cell-renderer.component.scss']
})
export class RestoreDataMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	snapshot: Snapshot;

	constructor(injector: Injector,
				private appDateAdapter: AppDateAdapter,
				private translateService: TranslateService) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.snapshot = params.data;
	}

	get dateString(): string {
		return this.appDateAdapter.formatDateTime(new Date(this.snapshot.date));
	}

	get reasonString(): string {
		if (this.snapshot.reason === RbEnums.Common.SnapshotReasonType.Manual) return this.translateService.instant('STRINGS.MANUAL');
		return this.translateService.instant('STRINGS.REVERSE_SYNC');
	}
}

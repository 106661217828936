import { WorldWeatherArea } from './world-weather-area.model';
import { WorldWeatherCurrentCondition } from './world-weather-current-condition.model';
import { WorldWeatherRequest } from './world-weather-request.model';
import { WorldWeatherWeather } from './world-weather-weather.model';

export class WorldWeatherData {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.request) this.request = json.request.map(r => new WorldWeatherRequest(r));
			if (json.nearest_area) this.nearest_area = json.nearest_area.map(a => new WorldWeatherArea(a));
			if (json.current_condition) this.current_condition = json.current_condition.map(cc => new WorldWeatherCurrentCondition(cc));
			if (json.weather) this.weather = json.weather.map(w => new WorldWeatherWeather(w));
		}
	}

	request: WorldWeatherRequest[];
	nearest_area: WorldWeatherArea[];
	current_condition: WorldWeatherCurrentCondition[];
	weather: WorldWeatherWeather[];

}

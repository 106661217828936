import { CCWeatherDataService, WeatherTimeStep } from '../../../../../api/ccweather/ccweather-data.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CCWeatherCondition } from '../../../../../api/ccweather/models/ccweather-condition.model';
import { CCWeatherValuesData } from '../../../../../api/ccweather/models/ccweather-values-data.model';
import { CultureSettings } from '../../../../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../../../../api/culture-settings/culture-settings-manager.service';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@UntilDestroy()
@Component({
	selector: 'rb-ccweather-widget-hourly',
	templateUrl: './ccweather-widget-hourly.component.html',
	styleUrls: ['./ccweather-widget-hourly.component.scss']
})
export class CCWeatherWidgetHourlyComponent implements OnInit, OnDestroy {

	private readonly HOURS_NUMBER_EARLIER_NOW = 3;
	private readonly HOURS_NUMBER_LATER_NOW = 7;

	@Input() widgetId: number;
	@Input() isIq4SidebarWidget = false;
	@Input() theme = '';

	@Input() set timezone(value: string) {
		this._timezone = value;
		if (this._timezone && !this.sunriseTime) {
			this.getSunriseAndSunsetTimes();
		}
	}

	get timezone(): string {
		return this._timezone;
	}

	isBusy = false;

	requiredConditions: string[] = [];
	conditions: CCWeatherCondition[] = [];

	hoursFull = [];
	hourlyData: CCWeatherValuesData[] = [];

	isExpanded = false;
	startHour = this.HOURS_NUMBER_EARLIER_NOW;

	sunriseTime = '';
	sunsetTime = '';
	todayDate = '';

	cultureSettings: CultureSettings;

	private _timezone: string;

	constructor(private ccWeatherDataService: CCWeatherDataService,
				private cultureSettingsManager: CultureSettingsManagerService
	) {
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe((cultureSettings: CultureSettings) => {
				this.cultureSettings = cultureSettings;
			});

		this.getCultureSettings();
	}

	ngOnInit() {
		this.requiredConditions = ['temperature', 'wind', 'dewPoint', 'pressureSurfaceLevel', 'humidity', 'precipitationIntensity', 'et0'];
		this.conditions = this.ccWeatherDataService.getSettings(this.widgetId, this.requiredConditions, true).conditions;
		this.isExpanded = false;
		this.getHours();
		this.ccWeatherDataService.weatherForecastLoaded.subscribe(() => {
			this.getHours();
		});
	}

	ngOnDestroy() {
	}

	private getHours() {
		const startDate = new Date();
		startDate.setHours(startDate.getHours() - this.HOURS_NUMBER_EARLIER_NOW);
		const endDate = new Date();
		endDate.setHours(endDate.getHours() + this.HOURS_NUMBER_LATER_NOW);
		const hourly = this.ccWeatherDataService.getWeatherForecast(this.widgetId, WeatherTimeStep.HOUR);
		this.hourlyData = [];
		const totalHours = this.HOURS_NUMBER_EARLIER_NOW + 1 + this.HOURS_NUMBER_LATER_NOW;
		for (let i = 0; i < totalHours; i++) {
			if (hourly.intervals[i]) {
				this.hourlyData.push(hourly.intervals[i].values);
			} else {
				this.hourlyData.push(new CCWeatherValuesData());
			}
		}
		const today = new Date();
		this.todayDate = RbUtils.Date.transform(today, 'd', this.timezone) + ' ' +
			RbUtils.Months.monthAbbrevString(Number(RbUtils.Date.transform(today, 'M', this.timezone))).toUpperCase();
		if (this.timezone) {
			this.getSunriseAndSunsetTimes();
		}
		this.hoursFull = [];
		while (this.hoursFull.length < totalHours) {
			this.hoursFull.push(this.hoursFull.length === 3 ?
				RbUtils.Translate.instant('DATE_RANGES.NOW') :
				RbUtils.Date.transform(startDate, this.is12h() ? 'h a' : 'HH:00', this.timezone));
			startDate.setHours(startDate.getHours() + 1);
		}
	}

	private getSunriseAndSunsetTimes(): void {
		const unavailableTime = '-';
		this.sunriseTime = (!this.hourlyData[3] || !this.hourlyData[3].sunriseTime) ? unavailableTime :
			RbUtils.Date.transform(new Date(this.hourlyData[3].sunriseTime), this.is12h() ? 'h:mm a' : 'H:mm', this.timezone);
		this.sunsetTime = (!this.hourlyData[3] || !this.hourlyData[3].sunsetTime) ? unavailableTime :
			RbUtils.Date.transform(new Date(this.hourlyData[3].sunsetTime), this.is12h() ? 'h:mm a' : 'H:mm', this.timezone);
	}

	private is12h(): boolean {
		return this.cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm;
	}

	onTableScroll(increaseValue) {
		this.startHour += increaseValue;
	}

	expandIcons() {
		if (this.isExpanded && this.startHour === 7) {
			this.onTableScroll(-1);
		}
		this.isExpanded = !this.isExpanded;
	}

	getFieldValue(field, idx) {
		let value;
		switch (field.fieldName) {
			case 'temperature':
				value = this.hourlyData[this.startHour + idx]['temperatureMax'] === null ? '-' :
					this.hourlyData[this.startHour + idx]['temperatureMax'];
				break;
			case 'wind':
				value = this.hourlyData[this.startHour + idx].windDirection === null ? '-' :
					this.hourlyData[this.startHour + idx].windDirection + '\n' + this.hourlyData[this.startHour + idx].windSpeed;
				break;
			default:
				value = this.hourlyData[this.startHour + idx][field.fieldName] === null ? '-' :
					this.hourlyData[this.startHour + idx][field.fieldName];
				break;
		}
		return value;
	}

	private getCultureSettings() {
		this.cultureSettings = RbUtils.User.cultureSettings;

		if (this.cultureSettings === undefined || this.cultureSettings === null) {
			setTimeout(() => this.getCultureSettings(), 100);
			return;
		}
	}
}

import 'jspdf-autotable';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ReportPdfInfo } from './report-pdf-info';
// eslint-disable-next-line sort-imports
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ReportPdfService {
	info: ReportPdfInfo
	constructor(protected deviceManager: DeviceManagerService, protected httpClient: HttpClient) {
		if (!this.info) {
			this.initPdfDocument();
		}
	}

	createPdfDocument(orientation: 'p' | 'portrait' | 'l' | 'landscape' = 'p', isReinitializeDocument = false): ReportPdfInfo {
		this.initPdfDocument(orientation);
		return this.info;
	}

	initPdfDocument(orientation: 'p' | 'portrait' | 'l' | 'landscape' = 'p') {
		this.info = new ReportPdfInfo(this.httpClient);
		this.info.pdf = new jsPDF(orientation, 'px', 'letter');
		this.info.addFont();
	}

	save(pdfInfo: ReportPdfInfo, filename: string, window: Window) {
		if (window != null) {
			window.location.href = pdfInfo.pdf.output('bloburl').toString();
		} else {
			pdfInfo.pdf.save(filename);
		}
	}
}

import { ToolbarItemInfo } from './toolbar-item-info.model';

export class ToolbarInfoGroup {

	constructor() {
		this.buttons = [];
	}

	buttons: ToolbarItemInfo[];

}

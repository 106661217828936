import { GeoItem } from './geo-item.model';

export class GeoGroup {

	companyId: number;
	id: number;
	siteId: number;
	areaLevel2Id: number;
	areaLevel3Id: number;
	geoItem: GeoItem[] = [];
	stationId?: number;
	uiSettingsJson: string;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.geoItem) { this.geoItem = json.geoItem.map(item => new GeoItem(item)); }
		}
	}
}

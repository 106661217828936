import { Observable, of, timer } from 'rxjs';
import { PreloadingStrategy, Route } from '@angular/router';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomModulePreloadingStrategy implements PreloadingStrategy {

	private static DEFAULT_PRELOAD_DELAY = 10000; // 10 seconds
	private readonly siteType: string;

	constructor(private authManager: AuthManagerService ) {
		this.siteType = this.authManager.getUserProfile().siteType;
	}

	preload(route: Route, loadModule: () => Observable<any>): Observable<any> {
		const preload = route.data != null && route.data.preload === true;
		let delay: number = route.data == null || route.data.delay == null ? 0 : route.data.delay;
		if (delay === -1) delay = CustomModulePreloadingStrategy.DEFAULT_PRELOAD_DELAY;
		const siteType: string = route.data == null ? null : route.data.siteType;
		if (siteType != null && this.siteType !== siteType) return of(null);
		if (preload && delay > 0) {
			console.log('preload specified on ' + route.path + ' delay is ' + delay);
			return timer(delay).pipe(
				flatMap(_ => {
					console.log('Loading now ' + route.path);
					return loadModule();
				}));
		} else if (preload) {
			console.log('no preload parameters specified for the path ' + route.path);
			return loadModule();
			// return of(null);
		} else {
			console.log('No preloading for the path ' + route.path);
			return of(null);
		}
	}
}


export class TreeViewFlatNode {
	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}
	parentId: number;
	item: string;
	id: number;
	level: number;
	expandable: boolean;
	isDisabled: boolean;
}

import { ApiService, HttpMethod } from '../_common/api.service';
import { AvailableUpdate } from './models/available-update.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UpdateHistory } from './models/update-history.model';

@Injectable({
	providedIn: 'root'
})
export class SoftwareUpdateApiService extends ApiService {

	clearCache() {
	}

	GetApplicableUpdates(customerNumber: number, activationCode: string, installedVersion: string)
		: Observable<AvailableUpdate[]> {
		return this.apiRequest<any>(this.getApplicableUpdatesUrl(customerNumber, activationCode, installedVersion),
			HttpMethod.Post, [installedVersion])
			.pipe(map(result => result.map(item => new AvailableUpdate(item))));
	}

	GetUpdateHistory(customerNumber: number, activationCode: string): Observable<UpdateHistory[]> {
		return this.apiRequest<any>(this.getUpdateHistoryUrl(customerNumber, activationCode), HttpMethod.Get)
			.pipe(map(result => result[0] != null ? result[0].map(json => new UpdateHistory(json)) : []));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}Update`; }

	private getUpdateHistoryUrl(customerNumber: number, activationCode: string)
		: string {
		return `${this.baseUrl}/GetUpdateHistory?customerNumber=${customerNumber}&activationCodes=${activationCode}`;
	}

	private getApplicableUpdatesUrl(customerNumber: number, activationCode: string, installedVersion: string)
		: string {
		return `${this.baseUrl}/GetApplicableUpdates?customerNumber=${customerNumber}` +
			`&activationCodes=${activationCode}`;
			// RB-9558: Installed versions list is in the request body, not the query.
	}
}

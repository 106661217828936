import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AddressBookEntry } from '../../../../../api/address-book/models/address-book-entry.model';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';

@Component({
	selector: 'rb-contact-mobile-cell-renderer',
	templateUrl: './contact-mobile-cell-renderer.component.html',
	styleUrls: ['./contact-mobile-cell-renderer.component.scss']
})
export class ContactMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	contact: AddressBookEntry;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
	}

	agInit(params: any): void {
		super.agInit(params);
		this.contact = params.data;
	}
}

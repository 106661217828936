/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IIrrigationEngineStationStatusItem } from '../../signalR/_irrigation-engine-station-status-item.interface';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationStatus } from './station-status.model';
import { Subscription } from 'rxjs';

@UntilDestroy()
@Directive()
export abstract class StationStatusListItem implements OnDestroy {
	// Subscriptions
	stationStatusChangeSubscription: Subscription;
	stoppingIrrigationSubscription: Subscription;

	// Constants
	protected readonly INDETERMINATE_STATE = '-';
	protected readonly COURSE_VIEW_INDETERMINATE_STATE = '';

	// Event to notify users of class (as desired).
	@Output() secondsRemainingChange = new EventEmitter<{stationId: number}>();

	private _irrigationStatus = RbEnums.Common.IrrigationStatus.Dash;

	// Member Vars
	status = '-';
	mapStatus = '';
	courseViewStatus = '';
	isActivelyCyclingOrSoaking = false;
	secondsRemaining = 0;

	set irrigationStatus(value: RbEnums.Common.IrrigationStatus) {
		this._irrigationStatus = value;
		this.isActivelyCyclingOrSoaking = value === RbEnums.Common.IrrigationStatus.Running || value === RbEnums.Common.IrrigationStatus.Soaking;
	}

	get irrigationStatus(): RbEnums.Common.IrrigationStatus {
		return this._irrigationStatus;
	}

	/**
	 * When provided by the SignalR message updating the station status, the reason for the "failure". This could be
	 * something like NoFlowCapacity, etc. If null, no failure is assumed.
	 * RB-9052
	 */
	statusReason: RbEnums.Common.StationFailureReasonCode;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	protected constructor(public stationId: number) {}

	ngOnDestroy(): void {}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	set irrigationEngineStationStatusItem(value: IIrrigationEngineStationStatusItem) {
		if (!value) return;

		if (this.stationStatusChangeSubscription) this.stationStatusChangeSubscription.unsubscribe();

		this.stationStatusChangeSubscription = value.secondsRemainingChange
			.pipe(untilDestroyed(this))
			.subscribe((secondsRemaining: number) => {
				this.secondsRemaining = secondsRemaining;

				if (this.stationId != null) { this.secondsRemainingChange.emit({stationId: this.stationId}); }

				if (secondsRemaining <= 0 || this.irrigationStatus === RbEnums.Common.IrrigationStatus.Dash ||
					this.irrigationStatus === RbEnums.Common.IrrigationStatus.Idle) {
					this.isActivelyCyclingOrSoaking = false;
					this.irrigationStatus = RbEnums.Common.IrrigationStatus.Dash;
					this.status = this.INDETERMINATE_STATE;
					this.courseViewStatus = this.COURSE_VIEW_INDETERMINATE_STATE;
					this.mapStatus = '';
					return;
				}

				// RB-12321: This event is triggered at least from 2 different places
				// by the SignalR service, when it receives a message
				// and by the time that refreshes the remainig time in live time
				// in any case, the different status'es are already set at this point, so here 
				// we only care for udating the real time remaining seconds
				if (this.irrigationStatus === RbEnums.Common.IrrigationStatus.Running 
					|| this.irrigationStatus === RbEnums.Common.IrrigationStatus.Soaking) {
					this.status = RbUtils.Stations.secondsToFriendlyString(secondsRemaining);
					this.mapStatus = RbUtils.Stations.secondsToHhMmSs(secondsRemaining);
				}

				this.courseViewStatus = RbUtils.Stations.secondsToCourseViewFriendlyString(secondsRemaining);
			});

		if (this.stoppingIrrigationSubscription) this.stoppingIrrigationSubscription.unsubscribe();

		this.stoppingIrrigationSubscription = value.stoppingIrrigation
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.isActivelyCyclingOrSoaking = false;
				this.irrigationStatus = RbEnums.Common.IrrigationStatus.Dash;
				this.secondsRemaining = 0;
				this.status = `${RbUtils.Translate.instant('STRINGS.STOPPING')}...`;
				this.courseViewStatus = `${RbUtils.Translate.instant('STRINGS.STOPPING')}...`;
			});
	}

	// =========================================================================================================================================================
	// Protected Methods
	// =========================================================================================================================================================

	abstract setStationStatus(status: StationStatus);

}

export enum ControllerConnectionStatus {
	CONNECTED,
	CONNECTING,
	DISCONNECT,
	ERROR
}

export class ControllerStatusChange {
	controllerId: number
	status: ControllerConnectionStatus;
	message?: string
}
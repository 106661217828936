import 'ag-grid-enterprise';
import {
   AfterContentInit,
   Component,
   ContentChildren,
   EventEmitter,
   Input, OnDestroy, OnInit,
   Output,
   QueryList, ViewChild,
   ViewEncapsulation
} from '@angular/core';
import { ColDef, Column, ColumnState, GridOptions, RowDataChangedEvent, RowNode } from 'ag-grid-community';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseCellTooltipComponent } from '../cell-tooltips/base-cell-tooltip/base-cell-tooltip.component';
import { CheckboxTableFilterComponent } from '../filters/checkbox-table-filter/checkbox-table-filter.component';
import { CheckboxTableFlagFilterComponent } from '../filters/checkbox-table-flag-filter/checkbox-table-flag-filter.component';
import { ColumnComponent } from '../column/column.component';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NavMobileCellRendererComponent } from '../mobile-cell-renderers/nav-mobile-cell-renderer/nav-mobile-cell-renderer.component';
import { RbConstants } from '../../../../common/constants/_rb.constants';
import { RbUtils } from '../../../../common/utils/_rb.utils';
import { TableColumn } from '../column/table-column.model';
import { TranslateService } from '@ngx-translate/core';

// eslint-disable-next-line no-duplicate-imports
import { LicenseManager } from 'ag-grid-enterprise';
import { SiteManagerService } from '../../../../api/sites/site-manager.service';
// eslint-disable-next-line max-len
LicenseManager.setLicenseKey('CompanyName=Rain Bird Corporation,LicensedGroup=IQ4/ CIRRUSPRO,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=15,LicensedProductionInstancesCount=-1,AssetReference=AG-031531,SupportServicesEnd=17_August_2023_[v2]_MTY5MjIyNjgwMDAwMA==ffa1a485e348d482571a6ba494e6c9b2')

@UntilDestroy()
@Component({
   selector: 'rb-table-wrapper',
   templateUrl: './table-wrapper.component.html',
   styleUrls: ['./table-wrapper.component.scss'],
   // TODO: MW - WE DON'T WANT TO TURN OFF VIEW ENCAPSULATION!
   encapsulation: ViewEncapsulation.None, // Expose styles globally
})

export class TableWrapperComponent implements OnInit, AfterContentInit, OnDestroy {

   @ContentChildren(ColumnComponent) childColumns: QueryList<TableColumn>;
   @ViewChild('agGrid', { static: true }) agGrid;

   @Input() columns: QueryList<TableColumn>;
   @Input() disableTableResize = false;
   // 'normal' (empty string), 'autoHeight', or 'print' v20+ now uses 'normal' and doesn't allow ''.
   @Input() domLayout: 'normal' | 'autoHeight' | 'print' | undefined = 'normal';
   @Input() groupDefaultExpanded: 0 | -1 = 0; // ag-grid: -1 will expand the treeview automatically, 0 will not
   @Input() enableSorting = true;	// ag-grid v20+ moves this from the options to the default column def.
   @Input() headerCheckboxSelection?: boolean
   @Input() hideRowSelection = false;
   @Input() hideRowSelectionButLeaveSpace = false;
   @Input() includeButton = false;
   @Input() includeCheckbox = false;
   @Input() checkboxClass = '';
   @Input() checkboxClassCallback?: (params: any) => string = null; // RB-9654: qaid hook support
   @Input() isClickable = false;
   @Input() isMultiSelect = true;
   @Input() rowData = [];
   @Input() rowDrag = false;
   @Input() rowDragFieldName = '';
   @Input() rowHeight = RbConstants.Form.DEFAULT_ROW_HEIGHT;
   @Input() rowSelection = 'multiple';
   @Input() showGrid = true;
   @Input() suppressScrollOnNewData = true;
   @Input() useManagedRowDragging = false;
   @Input() rowClassCallback?: (params: any) => string = null;
   @Input() instructionsText: string = null;

	@Input() masterDetail = false; // to set up masterDetail, the cellRenderer need to be the 'agGroupCellRenderer'
	@Input() isRowMaster: (dataItem: any) => boolean = null;
	@Input() detailCellRenderer: any = null
	@Input() detailCellRendererParams: any = null
	@Input() detailRowHeight = 200;
	@Input() detailRowAutoHeight = false;
	@Input() getRowHeight?: (params: any) => number = null;
	@Input() totalRecords?: number;
	@Input() selectedData?: any[]
   // Using this to hide the tree node column then customizing tree view yourself.
	@Input() hideMainTreeNodeColumn: boolean = false;
	// Ignore mobile handling, keep the desktop view with a horizontal scrollbar bar
	@Input() ignoreMobileView = false;
	

   // Tree view input fields
   @Input() treeNodeCallback?: (data: any) => any = null; // NOTE: If this is not null, the other treeXYZ input fields below must be defined
   @Input() treeNodeOrder = 0; // 0 = first column, 1 = second column, ...
   @Input() treeNodeField: string;
   @Input() treeNodeHeaderName: string;
   @Input() treeInnerRendererCallback?: (params: any) => string = null;
   @Input() treeNodeDesiredWidth = 0;
   @Input() treeNodeUseMobileRendererWithHeader = false;
   @Input() treeNodeUseMobileRendererWithNonHeader = false;
   @Input() treeNodeMobileExpanderColWidth?: number = null;
   @Input() treeNodeIsHeaderCallback?: (params: any) => boolean = null;
   @Input() treeSuppressMenu: boolean;
   @Input() treeSortable: boolean;
   @Input() includeTreeInnerRendererCallback = false;
   @Input() isRowSelectable: (params: any) => boolean = null;

   // Treeview Server Side
   // document: https://www.ag-grid.com/angular-data-grid/server-side-model-tree-data/
   @Input() rowModelType = 'clientSide';
   @Input() isServerSideGroupOpenByDefault: (params) => boolean = null;
   @Input() isServerSideGroup: (dataItem: any) => any = null;
   @Input() getServerSideGroupKey: (dataItem: any) => any = null;

   // Hyperlink support
   @Input() treeInnerRendererComponent: ICellRendererAngularComp;
   @Input() treeInnerRendererComponentParams: {};

   // Mobile-related input properties

   /**
    * This item will be set for all cells in the mobile view of the table as the cellRendererFramework property. The mobile
    * view uses a single cell for what would normally be each row of a non-mobile table.
    */
   @Input() mobileCellRendererComponent: any;

   /**
    * RB-8358: Parameters passed to the agInit method of the renderer component, along with the normal stuff that agGrid
    * passes. You can find these parameters in the 'params' method parameter. For example if mobileCellRendererParams is
    * {thisNewValue: 17}, the agInit params property would include params.thisNewValue with a value of 17.
    */
   @Input() mobileCellRendererParams: any;
   @Input() mobileNavigation = false;
   @Input() mobileRightArrow = true;
   @Input() mobileRendererDesiredWidth = 0;

   /**
    * Similar to mobileCellRendererComponent, mobileExtraColumns provides access to one or more specially created
    * columns in the mobile case. Normally we have only one column in the grid in mobile, usually with a renderer
    * attached. However, in certain cases, we may want to add an extra column or two for additional status, links to
    * other pages, etc. This element provides that access. Normally it is null and the result is a single-column
    * mobile table. A typical entry in this array might look something like this:
    *	{
    *		autoHeight: true,
    *		suppressSizeToFit: true,
    *		width: 30,
    *		cellRendererFramework: LinkDiagnosticsCellRendererComponent,
    *		cellClass: 'lastCellMobile',
    *	};
    * setting a fixed-width (does not auto-resize) column with a width of 30px, a cellRendererFramework of 
    * LinkDiagnosticsCellRendererComponent, and an SCSS class of 'lastCellMobile'. We define 'lastCellMobile'
    * appropriately for most cases with some padding settings. It can be used safely for the last column in the
    * mobile table.
    * We typically do NOT want to add more than one extra column. Also note that extra columns are always added at the
    * end of the row, never the beginning.
    */
   @Input() mobileExtraColumns: any[] = null;

   @Output() onCellClick = new EventEmitter();
   @Output() dragCompleted = new EventEmitter();
   @Output() onGridReady = new EventEmitter();
   @Output() onRowClick = new EventEmitter();
   @Output() onRowDataChanged = new EventEmitter();
   @Output() onRowSelect = new EventEmitter();
   @Output() treeNodeExpanded = new EventEmitter();
   @Output() onMobileCommandRequested = new EventEmitter<{ command: string, data: any }>();

   private _isMobile = false;
   @Input() set isMobile(value) {
      if (value !== this._isMobile) {
         this._isMobile = value;
         this.initColumnsAndRowHeight();
      }
   }

   get isMobile(): boolean { return this._isMobile; }

   columnDefs = [];
   components = {
      checkboxTableFilter: CheckboxTableFilterComponent,
      checkboxTableFlagFilter: CheckboxTableFlagFilterComponent,
      baseCellTooltip: BaseCellTooltipComponent,
   };
   frameworkComponents = {};
   defaultColDef: ColDef = null;
   gridHeightStyle: {};
   mobileColumnDefs: ColDef[] = [];
   selected = [];
   totalNodes = 0;
   hasNoData = false;
   isMobileStyle = false;
   isGolfSite = false;

   gridOptions: GridOptions = <GridOptions>{
      suppressPropertyNamesCheck: true,
      getRowClass: this.rowClassCallbackInternal.bind(this),
      suppressDragLeaveHidesColumns: true,
      getLocaleText: function (params) {
         // To avoid key clash with external keys, we add 'TABLE_WRAPPER.' to the start of each key.
         const gridKey = 'TABLE_WRAPPER.' + params.key;

         // Use the translate service to get the value, instantly.
         const value = RbUtils.Translate.instant(gridKey);

         // Work around the case where we start displaying TABLE_WRAPPER.copy or whatever, instead of
         // a valid string. If we don't find a translation, we use the agGrid-passed default value.
         return (value === gridKey) ? params.defaultValue : value;
      },
      context: {
         componentParent: this
      },
      suppressAggAtRootLevel: true,
      getRowHeight: this.getRowHeight,
   };

   // =========================================================================================================================================================
   // C'tor, Init and Destroy
   // =========================================================================================================================================================

   constructor(private deviceManager: DeviceManagerService,
      private siteManager: SiteManagerService,
      private translate: TranslateService) {
      this.isGolfSite = this.siteManager.isGolfSite;
   }

	ngOnInit(): void {
		// Monitor app resize
		if (!this.ignoreMobileView) {
			this.isMobile = window.innerWidth < RbConstants.Common.MaxMobileWindowWidth;
			this.isMobileStyle = this.isMobile && !this.includeTreeInnerRendererCallback;
			this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => {
				this.isMobile = isMobile;
			});
		}
		if (this.treeInnerRendererComponent != null) {
			this.components[(<any>this.treeInnerRendererComponent).name] = this.treeInnerRendererComponent;
		}
	}

   ngAfterContentInit() {
      if (!this.columns) {
         this.columns = this.childColumns;
      }

      this.initColumnsAndRowHeight();
   }

   ngOnDestroy(): void {
      /** Implemented to support untilDestroy */
   }

   // =========================================================================================================================================================
   //
   // =========================================================================================================================================================

   /** Method to cause ICellRendererAngularComp.refresh() method to be called in cell renderer.
    *  Setting forceRefresh to true will cause the agGrid to call the ICellRendererAngularComp.refresh() method regardless of whether
    *  standard agGrid changeDetection has detected a change to any cell contents. This must be set to true for mobile cell renderers. */
   refreshCells(forceRefresh = false) {
      if (this.gridOptions && this.gridOptions.api)
         this.gridOptions.api.refreshCells({ force: forceRefresh });
   }

   /**
    * Method to cause a single cell to be refreshed. The cell is first searched for using the function passed. Those which
    * match are passed to the refreshCells API method.
    * @param filterFn - function taking a single row's user data value (any) and returning true if the row should
    * be refreshed and false if not
    * @param columns - string or Column array containing the set of columns to be refreshed
    * @param forceRefresh - boolean value (default = false) indicating whether we should force this operation or
    * just suggest it
    */
   refreshCellsWhere(filterFn: (rowData: any) => boolean,
      columns?: (string | Column)[],
      forceRefresh = false) {

      // Keep a list of matching row items for later refresh.
      const selectedRows: RowNode[] = [];
      const rowModel = this.gridOptions.api.getModel();

      // Enumerate the cells.
      rowModel.forEachNode((rowNode, index) => {
         // Call comparison function for the rowNode. If true is returned, refresh the row; if not, ignore it.
         if (filterFn(rowNode.data)) {
            selectedRows.push(rowNode);
         }
      });

      if (selectedRows.length > 0) {
         // Refresh them all.
         this.gridOptions.api.refreshCells({
            rowNodes: selectedRows,
            columns: columns,
            force: forceRefresh
         });
      }
   }

   // comparator is a function that must return TRUE for the matching node and FALSE otherwise
   // position: see ag-grid documentation
   ensureNodeVisible(comparator: any, position?: 'top' | 'bottom' | 'middle') {
      if (this.gridOptions && this.gridOptions.api)
         this.gridOptions.api.ensureNodeVisible(comparator, position);
   }

   /**
    * @summary Assure that all parent nodes in a tree are open until the indicated node is identified.
    * @param comparator - function returning true when the indicated node matches. All parents for such
    * a node will be expanded.
    */
   expandTo(comparator: (node: any) => boolean) {
      if (this.gridOptions && this.gridOptions.api) {
         this.gridOptions.api.forEachLeafNode(node => {
            // Check the node for a match. If matched, open all its parents.
            if (comparator(node)) {
               // Expand node's parent and that node's parent, etc.
               this.expandNodeAndAncestors(node.parent);
            }
         });
      }
   }

   /**
    * Expand/collapse the tree for any values which match the comparator.
    * @param comparator - Typically a function taking a tree node value and index and returning true if the
    * node should be expanded.
    */
   expandCollapse(comparator: (node: any, index: number) => boolean) {
      if (this.gridOptions && this.gridOptions.api) {
         this.gridOptions.api.forEachNode((node, index) => {
            // Check the node for a match. If matched, expand it; if not, collapse it.
            const expanded = comparator(node, index);
            if (node.expanded !== expanded) {
               node.setExpanded(expanded);
            }
         });
      }
   }

   /**
    * @summary Expand the indicated node, showing its children, and recursively expand its parent and that
    * node's parent all the way to the top of the tree.
    * @param node - RowNode to be expanded.
    */
   expandNodeAndAncestors(node: RowNode) {
      // Expand to the parent, if there is one and if the parent's level is 0 or higher.
      if (node.parent != null && node.parent.level >= 0) {
         this.expandNodeAndAncestors(node.parent);
      }

      // Then expand the node itself, if not already expanded.
      if (!node.expanded) {
         node.setExpanded(true);
      }
   }

   updateRowData(rowData: any) {
      this.gridOptions.api.updateRowData(rowData);
      this.hasNoData = false;
   }
   setRowData(rowData: any) {
      this.gridOptions.api.setRowData(rowData);
   }
   addItems(rowData: any[]) {
      this.gridOptions.api.addItems(rowData);
      this.hasNoData = false;
   }

   private rowClassCallbackInternal(params) {
      return this.rowClassCallback ? this.rowClassCallback(params) : null;
   }

   /**
    * Allows setting class with row-data as qaid hook for automation related to checkbox-selection
    * RB-9654
    */
   private checkboxClassCallbackInternal(params) {
      const checkboxClasses = ['cellStyleCheckbox', this.checkboxClass];
      if (this.isMobile) {
         checkboxClasses.push('mobile');
      }
      if (this.checkboxClassCallback) {
         checkboxClasses.push(this.checkboxClassCallback(params));
      }
      return checkboxClasses;
   }

   private initColumnsAndRowHeight() {
      this.columnDefs = [];
      this.mobileColumnDefs = [];

      this.gridOptions.rowHeight = this._isMobile ? 64 : this.rowHeight;
      if (this.gridOptions.api) {
         // resetRowHeights makes no sense when autoHeight is set to true.
         if (!!this.columns.find(col => col.autoHeight)) return;

         this.gridOptions.api.resetRowHeights();
      }
      this.buildColumnDefs();
      this.buildDefaultColDefs();
   }

   onRowDragEnd(event) {
      const rows = this.getRowData();
      const sourceIndex = rows.findIndex((c) => c['id'] === event.node.data.id);
      const targetIndex = event.overIndex;
      const item = rows[sourceIndex];
      rows.splice(sourceIndex, 1);
      rows.splice(targetIndex, 0, item);
      this.dragCompleted.emit({
         rowsAfterDrag: rows,
      });
   }
   postProcessPopup(params: any) {
      console.log(params);
   }
   buildColumnDefs() {

      const checkBoxDef = {
         headerName: '',
         headerClass: this.checkboxClass,
         suppressSizeToFit: true,
         width: 47,
         sortable: false,
         headerCheckboxSelection: () => this.rowSelection === 'multiple' && 	// This field is used! This enables header checkbox.
            (this.headerCheckboxSelection !== undefined ? this.headerCheckboxSelection : true), // use headerCheckboxSelection input if it is defined
         headerCheckboxSelectionFilteredOnly: true,
         checkboxSelection: true,
         hide: this.isMobile && !this.isMultiSelect,
         cellClass: this.checkboxClassCallbackInternal.bind(this),
         suppressMenu: true
      };

      const cellRendererParams = {
         checkbox: this.includeCheckbox,
         suppressDoubleClickExpand: true,
         suppressEnterExpand: true,
         suppressCount: true,
         // Include checkbox in tree rendering if includeCheckbox is TRUE
         innerRenderer: this.treeInnerRendererComponent != null ? (<any>this.treeInnerRendererComponent).name : this.treeInnerRenderer.bind(this),
      };
      if (this.treeInnerRendererComponentParams != null) {
         Object.keys(this.treeInnerRendererComponentParams).forEach(key => cellRendererParams[key] = this.treeInnerRendererComponentParams[key]);
      }
      const treeColumnDefWithInnerRender = {
         autoHeight: true,
         showRowGroup: true,
         cellRenderer: 'agGroupCellRenderer',
         cellRendererParams: cellRendererParams,
         headerName: this.treeNodeHeaderName,
         field: this.treeNodeField,
         colSpan: this.colSpanCallback.bind(this),
         suppressMenu: this.treeSuppressMenu != undefined ? this.treeSuppressMenu : false,
         sortable: this.treeSortable != undefined ? this.treeSortable : true
      };
      const treeColumnDef = {
         autoHeight: true,
         cellRenderer: 'agGroupCellRenderer',
         // Include checkbox in tree rendering if includeCheckbox is TRUE
         cellRendererParams: { checkbox: this.includeCheckbox, suppressCount: true },
         headerName: this.treeNodeHeaderName,
         field: this.treeNodeField,
         colSpan: this.colSpanCallback.bind(this),
         suppressMenu: this.treeSuppressMenu != undefined ? this.treeSuppressMenu : false,
         sortable: this.treeSortable != undefined ? this.treeSortable : true,
         hide: this.hideMainTreeNodeColumn
      };
      const dragHandlerColDef = {
         headerName: '',
         rowDrag: true,
         width: 30,
         maxWidth: 30,
         cellClass: 'cellStyleDrag',
         suppressMenu: true,
         sortable: false,
         suppressSizeToFit: true,
         field: this.rowDragFieldName,
      };

      if (this.isMobile) {

         if (this.rowDrag) {
            this.mobileColumnDefs.push(dragHandlerColDef);
         }

         // Create the checkbox column if it is included and we are not using tree rendering.
         if (this.includeCheckbox && this.treeNodeCallback == null && this.detailCellRenderer == null) {
            checkBoxDef.headerName = this.translate.instant('STRINGS.SELECT_ALL');
            checkBoxDef.headerClass = checkBoxDef.headerClass + ' keep-front';
            this.mobileColumnDefs.push(checkBoxDef);
         }

         // Create the tree column and use special (text-based) rendering for the content
         if (this.treeNodeCallback != null) {
            // Only make this column wide enough to show the non-text portion of the tree column. Note: this is adjusted by styles for indented rows
            if (!this.includeTreeInnerRendererCallback) {
               // We need a wider column for Activity | Completed, but don't want to force on all other
               // mobile trees.
               treeColumnDef['width'] = this.treeNodeMobileExpanderColWidth ? this.treeNodeMobileExpanderColWidth : (this.includeCheckbox ? 55 : 45);
               treeColumnDef.cellRendererParams['innerRenderer'] = function () { return ''; }; // Empty - the mobile renderer will take care of it
               treeColumnDef['suppressSizeToFit'] = true;
               this.mobileColumnDefs.push(treeColumnDef);
            } else {
               this.mobileColumnDefs.push(treeColumnDefWithInnerRender);
            }
         }

         if (this.detailCellRenderer !== null) {
            this.mobileColumnDefs.push({
               width: this.includeCheckbox ? 75 : 45,
               autoHeight: true,
               showRowGroup: true,
               cellRenderer: 'agGroupCellRenderer',
               cellRendererParams: cellRendererParams,
               headerName: " ",
               field: this.treeNodeField,
               suppressSizeToFit: true,
               colSpan: this.colSpanCallback.bind(this),
            });
         }

         if (!this.includeTreeInnerRendererCallback) {
            this.mobileColumnDefs.push({
               width: this.mobileRendererDesiredWidth,
               suppressSizeToFit: this.mobileRendererDesiredWidth > 0,
               autoHeight: true,
               sortable: false,
               cellRendererFramework: this.mobileCellRendererComponent,
               cellRendererParams: this.mobileCellRendererParams,	// RB-8358: pass the params so we can send messages from the cells
               cellClass: 'tableCellStyle dn-m',
               suppressMenu: true,
               headerClass: 'mobile-main-header'
            });
         }


         if (this.mobileNavigation) {
            this.mobileColumnDefs.push({
               suppressSizeToFit: true,
               width: 100,
               cellRendererFramework: NavMobileCellRendererComponent,
               cellClass: 'tableCellStyle dn-m',
            });
         }

         if (this.mobileRightArrow) {
            // RB-14911: If we need a right-arrow (go to some suitable page) as the last element of the row, add
            // that column. We set suppressSizeToFit so we don't make the width of this little arrow half the width
            // of the screen.
            this.mobileColumnDefs.push({
               autoHeight: true,
               width: 30,
               cellRenderer: () => '<i class="arrow-icon fa fa-chevron-right"></i>',
               cellClass: 'cellStyleDisclosure mobile-arrow',
            });
         }

         // RB-14911: If there are extra mobile columns requested, add them to the mobileColumnDefs array (without
         // validation). Styles may require some careful handling in the caller.
         if (this.mobileExtraColumns != null) {
            this.mobileColumnDefs.push(...this.mobileExtraColumns);
         }

         // RB-14635: Customize filter for the mobile view
         const lastColumnView = this.mobileColumnDefs[this.mobileColumnDefs.length - 1];
         const mobileDefaultFilter = this.columns?.toArray().find(x => {
            return x.isMobileFilter
         });
         if (mobileDefaultFilter && lastColumnView) {
            // Add the menu icon for the last column.
            this.mobileColumnDefs.forEach(x => {
               x.suppressMenu = true;
            });
            lastColumnView.suppressMenu = false;
            lastColumnView.headerName = '';
            lastColumnView.field = mobileDefaultFilter.field;
            lastColumnView.filter = mobileDefaultFilter.filter || 'agTextColumnFilter';
         }

         return;
      }

      if (this.rowDrag) {
         this.columnDefs.push(dragHandlerColDef);
      }

      // Create the checkbox column if it is included and we are not using tree rendering.
      if (this.includeCheckbox && this.treeNodeCallback == null && this.detailCellRenderer == null) {
         this.columnDefs.push(checkBoxDef);
      }

      if (this.useManagedRowDragging) {
         this.columnDefs.push({
            headerName: '',
            rowDrag: true,
            width: 45,
            cellClass: 'cellStyleDrag',
            suppressMenu: true,
            sortable: false,
            suppressSizeToFit: true,
            field: this.rowDragFieldName,
         });
      }

      // Map the rb-column list (now in this.columns) into columnDefs for each corresponding agGrid column.
      // Also insert the tree node (if required) at the appropriate location
      this.columns.forEach((column, index) => {
         if (this.treeNodeCallback != null && this.treeNodeOrder === index) {
            if (this.includeTreeInnerRendererCallback || this.treeInnerRendererComponent != null) {
               treeColumnDefWithInnerRender['colSpan'] = this.colSpanCallback.bind(this);
               if (this.treeNodeDesiredWidth > 0) {
                  treeColumnDefWithInnerRender['width'] = this.treeNodeDesiredWidth;
                  treeColumnDefWithInnerRender['suppressSizeToFit'] = false;
               }
               this.columnDefs.push(treeColumnDefWithInnerRender);
            } else {
               treeColumnDef['colSpan'] = this.colSpanCallback.bind(this);
               if (this.treeNodeDesiredWidth > 0) {
                  treeColumnDef['width'] = this.treeNodeDesiredWidth;
                  treeColumnDef['suppressSizeToFit'] = true;
               }
               this.columnDefs.push(treeColumnDef);
            }
         }

         if (this.detailCellRenderer !== null && index === 0) {
            column["cellRendererParams"] = cellRendererParams
         }

         this.columnDefs.push({
            ...column, suppressSizeToFit: false, suppressMenu: column.suppressMenu, sortable: column.sortable !== false,
            width: (column.width ? Number(column.width) : null)
         });
      });

      if (this.isClickable) {
         this.columnDefs.push({
            headerName: '',
            suppressSizeToFit: true,
            width: 80,
            cellRenderer: () => '<i class="arrow-icon fa fa-chevron-right"></i>',
            cellClass: 'cellStyle',
         });
      }
   }

   buildDefaultColDefs() {
      // This sets the default sort order of all columns on the ag-grid to be case-insensitive.
      this.defaultColDef = {
         sortable: this.enableSorting,
         resizable: this.includeTreeInnerRendererCallback,
         menuTabs: ['filterMenuTab'],
         filter: 'agTextColumnFilter',
         cellClass: 'cellStyle',
         comparator: function (a, b) {
            if (typeof a === 'string') {
               return a.localeCompare(b);
            } else {
               return (a > b ? 1 : (a < b ? -1 : 0));
            }
         },
      };
   }

   onAgGridReady(params) {
      // Copy the API pointers back into our grid options.
      this.gridOptions.api = params.api;
      this.gridOptions.columnApi = params.columnApi;

      // this.api = param.api; // Copy the API from the incoming data to the gridOptions (this).
      // this.columnApi = param.columnApi; // Copy the column API, too.
      this.onGridReady.emit(params);
      if (!this.includeTreeInnerRendererCallback) {
         // Size everything correctly for the content.
         this.sizeColumnsToFit();
      }
      // Setup the default column filters
      let hasDefaultFilter = false;
      this.columns.forEach(column => {
         if (column.filterDefaultValue != null) {
            const filterInstance = this.gridOptions.api.getFilterInstance(column.field);
            if (filterInstance == null) return;
            hasDefaultFilter = true;
            filterInstance.setModel({
               type: column.filterDefaultValue.type,
               filter: column.filterDefaultValue.filter,
               filterTo: column.filterDefaultValue.filterTo,
            });
         }
      });
      if (hasDefaultFilter) this.gridOptions.api.onFilterChanged();
      // Set default filters

      // TODO: ag-grid Migration - This no longer works in ag-grid 27.
      // This is unsupported and may break at any time!
      // This is a requested ag-grid feature AG-2785 found at https://www.ag-grid.com/ag-grid-pipeline/
      // try {
      // 	(params.api as any).context.beanWrappers.tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 500;
      // } catch (e) {
      // 	console.error(e);
      // }

      this.updateNodeCounter();
   }

   sizeColumnsToFit() {
      // Protect against AG Grid warning when grid is zero size
      if (this.agGrid == null || this.agGrid.nativeElement.offsetWidth === 0) return;
      this.gridOptions.api.sizeColumnsToFit();
   }

   // updateFilter(event) {
   // 	this.gridOptions.api.setQuickFilter(event.target.value);
   // }

   onSelectionChanged(event) {
      this.selected = event.api.getSelectedNodes().map((node) => node.data);
      this.onRowSelect.emit(this.selected);
   }

   onRowClicked(event) {
      this.onRowClick.emit(event);
   }

   onColumnResized(event) {
      if (!!this.columns.find(col => col.autoHeight)) return;

      this.gridOptions.api.resetRowHeights();
   }

   autoSizeColumns() {
      const allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
         allColumnIds.push(column.getColId());
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
   }

   resetSelection() {
      this.selected = [];
   }

   getRowData() {
      return this.gridOptions.rowData;
   }

   // selectRow(row: number): void {
   // 	const node = this.gridOptions.api.getRowNode(row.toString());
   // 	node.setSelected(true);
   // }
   //
   // selectRowById(id: number): void {
   // 	this.gridOptions.api.forEachNode(n => {
   // 		if (n.data.id === id) {
   // 			n.setSelected(true);
   // 		}
   // 	});
   // }

   onCellClicked(event) {
      this.onCellClick.emit(event);
      const node = event.node;
      const isSelected = node.selected === true;
      const hasValue = event.value !== undefined;

      if (isSelected) {
         if (!hasValue) {
            node.setSelected(false);
         }
      } else {
         this.unSelectAll();
         if (hasValue) {
            node.setSelected(true);
         } else {
            node.setSelected(false);
         }
      }
   }

   unSelectAll() {
      this.gridOptions.api.deselectAll();
   }

   resetRowHeights() {
      // resetRowHeights makes no sense when autoHeight is set to true.
      if (!!this.columns.find(col => col.autoHeight)) return;

      if (this.gridOptions && this.gridOptions.api)
         this.gridOptions.api.resetRowHeights();
   }

   // rowDataChanged is sent when the ag-grid table detects a data change. Our default behavior
   // is to reset the selection and fire our own onRowDataChanged() event. That event can be used
   // to reselect the same items as before, but in the new data, if desired.
   rowDataChanged(event: RowDataChangedEvent) {
      this.resetSelection();
      this.onRowDataChanged.emit(event);
      //[RB-13198]: only run this for client renderring
      this.hasNoData = !this.isServerSiderRendering && (this.rowData != null && this.rowData.length === 0);
      // RB-10874: IQ4 - Station count is not updated when new stations added to controller
      this.updateNodeCounter();
   }

   /**
    * Sorts by specified columns, uses the default comparator function for the sorting
    * behavior defined in inside buildDefaultColDefs().
    * Must be called on ngAfterViewInit() once we have the child reference component.
    * There is different valid scenarios and each depends on the parameters that we get:
    * 1- both params as non-array: when sortColumn is string and sortType is string, 
    * it will set a single column to sort and single sort type, only on this scenario we can 
    * accept clearOtherSortColumns value as true.
    * 
    * 2- sortType param as non-array: when sortColumn is string[] and sortType string, 
    * it will set all the specified columns to be sorted with the sortType, The sorting takes priority in
    * the order the columns are sent as parameters, eg: ['address', 'name', 'channel'] - address takes higher priority over name,
    * name higher priority over channel, and so on, the same is true for any scenario where sortColumn is string[].
    * 
    * 3- both params array: when sortColumn is string[] and sortType string[],
    * it will set specified columns sort types matching both arrays element positions, see sortType param example.
    * 
    * all other cases will show an error in the console, meaning that the sort failed.
    * @param sortColumn - Must match the sorting column, can be either a string or an array of strings, 
    * if sortColumn is type string[], array order matter to set higher->lower priority based on the array index,
    * NOTE: this will enable sorting property on the specified column(s).
    * @param sortType - The sort type we want to use, this can be either string or array, if parameter is string, it will set
    * the same sort type for single (sortColumn: string) or multiple (sortColumn: string[]) columns, 
    * if array, it must match the length of the sortColumn array, matching the sort type for each position on the array,
    * eg: (['address', 'name'], ['asc', 'desc']) - 'address' column is set sort ascendant and 'name' sort descendant.
    * @param clearOtherSortColumns - If true, this will be the only sortable column and will disable sorting for other columns,
    * NOTE: Cannot be used in a sortColumn type string[].
    * @returns 
    */
   sortByColumn(sortColumn: string | string[], sortType: 'desc' | 'asc' | ('desc' | 'asc')[], clearOtherSortColumns: boolean = false) {
      if (!sortColumn || !sortType) return;
      const isSortingTypeArray = (sortType instanceof Array && typeof (sortType[0]) === 'string');

      // multiple columns sorting 
      if (sortColumn instanceof Array && typeof (sortColumn[0]) === 'string') {
         if (clearOtherSortColumns) {
            console.error('Cannot clear other columns sorting when trying to sort on multiple columns.');
            return;
         }

         if (isSortingTypeArray) {
            if (sortType.length !== sortColumn.length) {
               console.error('sortColumn and sortType length must be equal.');
               return;
            }
         }

         // we enable sorting for specified columns
         const columnDefs: ColDef[] = this.gridOptions.api.getColumnDefs();
         const columnState: ColumnState[] = [];
         for (let i = 0; i < sortColumn.length; i++) {
            // enable sorting for that column
            const colDef: ColDef = columnDefs.find(column => column.colId === sortColumn[i]);
            if (!colDef) {
               console.error(`Sorting column ${sortColumn[i]} NOT found, verify column name, sorting is case-sensitive.`);
               continue;
            }
            colDef.sortable = true

            // multiple columns sorting and multiple sort types 
            if (isSortingTypeArray) {
               columnState.push({ colId: sortColumn[i], sort: sortType[i], sortIndex: i });
               continue;
            }

            // multiple columns but single sort type
            columnState.push({ colId: sortColumn[i], sort: <'desc' | 'asc'>sortType, sortIndex: i });
         }
         this.gridOptions.api.setColumnDefs(columnDefs);

         // we have to wait a bit it seems before we can set sorting
         setTimeout(() => {
            this.gridOptions.columnApi.applyColumnState({ state: columnState });
         }, 500);
         return;
      }

      if (isSortingTypeArray) {
         console.error(`Cannot use multiple sorting types array for a single column.`);
         return;
      }

      // single column sorting and single sorting type
      // we get the columns again to re-define the sortable property.
      const columnDefs: ColDef[] = this.gridOptions.api.getColumnDefs();
      columnDefs.forEach((colDef: ColDef, index: number) => {
         // we disable it for all others and enable it for this column
         if (clearOtherSortColumns) {
            colDef.sortable = colDef.colId === sortColumn;
            return;
         }
         // enable it for one column
         if (colDef.colId === sortColumn) {
            colDef.sortable = true;
         }
      });
      this.gridOptions.api.setColumnDefs(columnDefs);

      if (clearOtherSortColumns) {
         this.clearSort();
      }

      // again, we have to wait a bit it seems before we can set sorting
      setTimeout(() => {
         this.gridOptions.columnApi.applyColumnState({ state: [{ colId: <string>sortColumn, sort: <'desc' | 'asc'>sortType }] });
      }, 500);
   }

   // this does not enable or disable any sortable proerty, just clears any sorting applied
   clearSort(clearColumn?: string | string[]) {
      // clear sorting on multiple columns
      if (clearColumn instanceof Array && typeof (clearColumn[0]) === 'string') {
         const columnState: ColumnState[] = [];
         for (let i = 0; i < clearColumn.length; i++) {
            columnState.push({ colId: clearColumn[i], sort: null });
         }

         this.gridOptions.columnApi.applyColumnState({ state: columnState });
         return;
      }

      // clear sorting on single column
      if (clearColumn) {
         this.gridOptions.columnApi.applyColumnState({ state: [{ colId: <string>clearColumn, sort: null }] });
         return;
      }

      // clear sorting on all columns
      this.gridOptions.columnApi.applyColumnState({ defaultState: { sort: null } });
   }

   colSpanCallback(params: any) {
      if (this.isMobile) {
         return 1;
         // const isHeader = this.treeNodeIsHeaderCallback(params.data);
         // const singleColumn = (isHeader && this.treeNodeUseMobileRendererWithHeader) || (!isHeader && this.treeNodeUseMobileRendererWithNonHeader);
         // return singleColumn ? 1 : 2;
      }

      // Not mobile - make the header span multiple columns
      return this.treeNodeIsHeaderCallback(params.data) ? this.columns.length - this.treeNodeOrder - 1 : 1;
   }

   treeInnerRenderer(params: any) {
      if (!this.includeTreeInnerRendererCallback) {
         return params.node.group ? params.value : `<span style="margin-left: 30px;">${params.value}</span>`;
      } else {
         const innerRenderer = this.treeInnerRendererCallback(params);
         return innerRenderer ? innerRenderer : params.value;
      }
   }

   headerInnerRendererCallback(params: any): string {
      return '';
   }

   onRowGroupOpened(event: any) {
      this.treeNodeExpanded.emit(event);
   }

   updateNodeCounter() {
      if (this.totalRecords) {
         this.totalNodes = this.totalRecords; //Customize totalNodes if input totalRecords for grid
         return
      }

      if (this.gridOptions.api) {
         this.totalNodes = 0;
         this.gridOptions.api.forEachNode((node, index) => {
            this.totalNodes++;
         });
      }
   }

   private get isServerSiderRendering(): boolean {
      return this.rowModelType === 'serverSide'
   }
}

<span>
	<span *ngIf="!golfSensorListItem?.rainWatchData; else checkRainWatch">
		<span *ngIf="golfSensorListItem == null || golfSensorListItem.sensorStatus == null || golfSensorListItem.sensorStatus.sensorStatusItem == null; else haveValue">
			-
		</span>
		<ng-template #haveValue>
			<span class="sensor-trigger-symbol-and-sensor-status">
				<span *ngFor="let indicatorIndex of getActiveIndicators(golfSensorListItem)">
					<rb-golf-sensor-trigger-symbol
						attr.data-qaid="sensor-indicator-{{indicatorIndex}}"
						[triggerSymbol]="indicatorIndex">
					</rb-golf-sensor-trigger-symbol>
				</span>
				<span data-qaid="sensor-status">{{ getFlowOrProgrammableSensorStatus(golfSensorListItem) }}</span>
			</span>
		</ng-template>
	</span>
	<ng-template #checkRainWatch>
		<span *ngIf="golfSensorListItem?.rainWatchData?.rainWatchTimeSinceShutDown != null; else checkPaused">
			<table>
				<tr>
					<td rowspan="2" class="sensor-status-icon-td-mat">
						<rb-system-status-button
							attr.data-qaid="rainwatch-status-clear-btn"
							matIconName="clear"
							buttonColor="rgb(245, 115, 54)" 
							>
						</rb-system-status-button>
					</td>
					<td data-qaid="rainwatch-shutdown-start" class="shutdown-text">
						{{ 'RAINWATCH_STATUS.SHUTDOWN_START_LABEL' | translate }}	{{ durationFormat(golfSensorListItem.rainWatchData.rainWatchTimeSinceShutDown) }}
					</td>
				</tr>
				<tr>
					<td class="secondary-status-text">
						{{ 'RAINWATCH_STATUS.TOTAL_RAIN_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchIncrementalRainAccumulated) }}
					</td>
				</tr>
			</table>
		</span>
		<ng-template #checkPaused> 
			<span *ngIf="golfSensorListItem?.rainWatchData?.rainWatchTimeSincePause != null; else checkRaining">
				<table>
					<tr>
						<td rowspan="3" class="sensor-status-icon-td-mat">
							<rb-system-status-button 
								attr.data-qaid="rainwatch-status-clear-btn"
								matIconName="paused" 
								buttonColor="rgb(243, 181, 67)" 
								>
							</rb-system-status-button>
						</td>
						<td data-qaid="rainwatch-pause-start" class="pause-text">
							{{ 'RAINWATCH_STATUS.PAUSE_START_LABEL' | translate }} {{ durationFormat(golfSensorListItem.rainWatchData.rainWatchTimeSincePause) }}
						</td>
					</tr>
					<tr>
						<td class="secondary-status-text">
							{{ 'RAINWATCH_STATUS.TOTAL_RAIN_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchIncrementalRainAccumulated) }}
						</td>
					</tr>
					<tr>
						<td class="secondary-status-text">
							{{ 'RAINWATCH_STATUS.SHUTDOWN_ACCUMULATION_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchShutDownRainAccumulated) }}
						</td>
					</tr>
				</table>
			</span>
			<ng-template #checkRaining>
				<span *ngIf="golfSensorListItem?.rainWatchData?.rainWatchTimeSinceLastRain != null; else noRain">
					<table>
						<tr>
							<td rowspan="4" class="sensor-status-icon-td icon-status-rain">
								<!-- This needs to be a button! It's a bit of an unusual button, controlled by the CSS -->
								<button
									attr.data-qaid="rainwatch-status-clear-btn"
									id="sensorStatusIcon"
									class="system-status-button"
									>
									<i class="climacon-rain-cloud"></i>
								</button>
							</td>
							<td class="rain-text" data-qaid="rainwatch-rain-start" >
								{{ 'RAINWATCH_STATUS.LAST_RAIN_LABEL' | translate }} {{ durationFormat(golfSensorListItem.rainWatchData.rainWatchTimeSinceLastRain) }}
							</td>
						</tr>
						<tr>
							<td class="secondary-status-text">
								{{ 'RAINWATCH_STATUS.TOTAL_RAIN_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchIncrementalRainAccumulated) }}
							</td>
						</tr>
						<tr>
							<td class="secondary-status-text">
								{{ 'RAINWATCH_STATUS.PAUSE_ACCUMULATION_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchPauseRainAccumulated) }}
							</td>
						</tr>
						<tr>
							<td class="secondary-status-text">
								{{ 'RAINWATCH_STATUS.SHUTDOWN_ACCUMULATION_LABEL' | translate }} {{ getGolfSensorRainfallString(golfSensorListItem.rainWatchData.rainWatchShutDownRainAccumulated) }}
							</td>
						</tr>
					</table>
				</span>
				<ng-template #noRain>
					<span>-</span>
				</ng-template>
			</ng-template>
		</ng-template>
	</ng-template>
</span>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Area } from '../../../api/areas/models/area.model';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { CommonLayersComponent } from './tabs/common-layers/common-layers.component';
import { ControllerListItem } from '../../../api/controllers/models/controller-list-item.model';
import { LeftPanelTabProgramListComponent } from './tabs/program-list/program-list.component';
import { Map } from 'leaflet';
import { MapInfoLeaflet } from '../../../common/models/map-info-leaflet.model';
import { MultiSelectService } from '../../../common/services/multi-select.service';
import { Note } from '../../../api/sticky-notes/models/sticky-note.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { Sensor } from '../../../api/sensors/models/sensor.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { Station } from '../../../api/stations/models/station.model';

@Component({
  selector: 'rb-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})

export class LeftPanelComponent implements OnInit, OnDestroy {
  
	@ViewChild('commonLayers') commonLayers: CommonLayersComponent;
  @ViewChild(LeftPanelTabProgramListComponent) programTreeComponent: LeftPanelTabProgramListComponent;

	@Input() detailOptionsDisabled = false;
	@Input() busy = false;
  @Input() mapInfo: MapInfoLeaflet;
  @Input() map: Map;
  @Input() noInternet;
  @Input() showVisibleSelector = false;
  @Input() dragDisabled: boolean;
  @Input() isWidget: boolean = false;

  @Output() holeClicked = new EventEmitter<{event: any, hole: Area}>();
  @Output() areaClicked = new EventEmitter<{event: any, holeId: number, area: Area}>();
  @Output() stationClicked = new EventEmitter<{event: any, station: Station}>();
  @Output() noteLocationClicked = new EventEmitter<{event: any, note: Note}>();

  @Output() controllerClicked = new EventEmitter<{event: any, controller: ControllerListItem}>();
  @Output() sensorClicked = new EventEmitter<{event: any, sensor: Sensor}>();

  @Output() closeLeftPanel = new EventEmitter();

  @Output() layerSelectionChange = new EventEmitter<{ layer: RbEnums.Map.MapLayer, selected: boolean }>();
	@Output() baseLayerSelectionChange = new EventEmitter<{ baseLayerId: string, saveLayerConfig: boolean }>();
	@Output() textColorChange = new EventEmitter<string>();
	@Output() downloadTiles = new EventEmitter();
	@Output() removeTiles = new EventEmitter();
	@Output() exportPdf = new EventEmitter();

  public isGolfSite: boolean;
  private _isOpen = false;

  get isOpen() {
    return this._isOpen;
  }

  stationIcon = `
  <i class="station">
    <div class="outer-circle">
      <div class="inner-circle"></div>
    </div>
  </i>`;

  /** 
   * Used for "manual" lazy loading in the html template, since using angular material's own 
   * lazy loading results in the component being re-created everytime you navigate back to 
   * the programs tab
   */
  showPrograms = false;
  selectedTabIndex = 0;
  tabOptions = [
    { id: RbEnums.Map.LeftSidebarTab.Stations,
      icon: this.stationIcon, title: "Stations" },
    { id: RbEnums.Map.LeftSidebarTab.Notes,
      icon: '<i class="mdi mdi-message-processing-outline"></i>', title: "Notes" },
    { id: RbEnums.Map.LeftSidebarTab.Programs,
      icon: '<i class="mdi mdi-calendar-clock-outline"></i>', title: "Programs" },
    { id: RbEnums.Map.LeftSidebarTab.Imported,
      icon: '<i class="mdi mdi-file-image-marker-outline"></i>', title: "Imported Layers" },
    { id: RbEnums.Map.LeftSidebarTab.Common,
      icon: '<i class="mdi mdi-layers-triple-outline"></i>', title: "Common Layers" }
  ]

  private selectedID: number = 1;

  constructor(
    private multiSelectService: MultiSelectService,
    private route: ActivatedRoute,
    private siteManager: SiteManagerService
  ) {
    this.route.queryParams.subscribe(params => {
      const showNotes = params['showNotes'];
      if (showNotes && showNotes === 'true') {
        const notesIdx = this.tabOptions.findIndex(tab => tab.id === RbEnums.Map.LeftSidebarTab.Notes);
        this.selectedTabIndex = notesIdx;
        this.open();
      }
    });
  }

  ngOnInit(): void {
    this.isGolfSite = this.siteManager.isGolfSite;
  }

  ngOnDestroy(): void {
    this.clearSpecificSelections();
  }

  activateTab(newIndex) {
    if (newIndex === 0) {
      this.mapInfo.toggleMultiSelectMode(true, true);
    } else if (this.selectedTabIndex === 0) {
      if (this.multiSelectService.getSelectedStations().length) {
        this.multiSelectService.deselectAllStations();
      }
      this.mapInfo.toggleMultiSelectMode(true, false);
    }

    if (this.tabOptions[newIndex].id == RbEnums.Map.LeftSidebarTab.Programs) {
      this.showPrograms = true;
    }
    
    this.clearSpecificSelections();

    this.selectedTabIndex = newIndex;
    this.selectedID = this.tabOptions[this.selectedTabIndex].id
    }

  closeSidebar() {
    this.closeLeftPanel.emit(true);
    this.clearSpecificSelections();
  }

  updateLayerVisibility(layerVisibility: any, areItemsMoveable: boolean) {
    this.commonLayers.updateLayerVisibility(layerVisibility, areItemsMoveable);
  }
  
	isLayerVisible(layer: RbEnums.Map.MapLayer): boolean {
		return this.commonLayers.isLayerVisible(layer);
	}

  open() {
    this._isOpen = true;
  }

  close() {
    this._isOpen = false;
  }

  // =========================================================================================================================================================
	// Drag and drop support
	// =========================================================================================================================================================

	onDragMoved(event: CdkDragMove<any>) {
		this.mapInfo.updateDragPosition(event);
	}

	onDragEnded() {
    this.mapInfo.dragEnded();
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================
  
  onLayerSelectedChanged(event: any) {
		this.layerSelectionChange.emit(event);
	}

	onBaseLayerSelectedChanged(event: any) {
		this.baseLayerSelectionChange.emit(event);
	}

	onDownloadTiles() {
		this.downloadTiles.emit();
	}

	onExportPDF() {
		this.exportPdf.emit();
	}

	onRemoveTiles() {
		this.removeTiles.emit();
	}

	onTextColorChanged(event: any) {
		this.textColorChange.emit(event);
	}

	onNoteLocationClicked(event: any) {
		this.noteLocationClicked.emit(event);
	}

  /**
     * Only a couple of tabs with selection capabilities need to clear their selected items on
     * tab change and/or when the panel closes. The Holes tree is NOT one of them.
     */
  private clearSpecificSelections() {
    switch (this.selectedID) {
      case RbEnums.Map.LeftSidebarTab.Programs:
        this.programTreeComponent.clearSelection();
        break;
    }
  }

}


export class Entitlement {

	id: number;
	name: string;
	description: string;

	constructor(json?: any) {

		if (json)
		{
			Object.assign(this, json);
		}
	}
}

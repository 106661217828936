<div id="system-status-flow-container">

	<div id="ss-flow-progress-bar-container">
		<div id="ss-progress-bar-container">
			<mat-progress-bar [value]="percentageFlowRate"></mat-progress-bar>
		</div>
		<div id="ss-progress-bar-range">{{maxFlowRangeDisplayValue}}</div>
	</div>

	<div id="ss-flow-progress-info" class="dont-wrap-lbl">
		<div id="ss-tf-lbl-container">
			<div id="ss-tf-lbl">{{'STRINGS.TOTAL_FLOW' | translate}}</div>
			<div id="ss-tf-sep">:</div>
		</div>
		<div><span id="ss-total-flow-value">{{totalFlowDisplayText}}</span> {{totalFlowUnits}}</div>
	</div>

</div>

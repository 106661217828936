<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>
<span *ngIf="programProjectedTime.scheduleType === RbEnums.Common.ScheduleType.Program" > 
	{{programProjectedTime.programName}} 
</span>
<span *ngIf="programProjectedTime.scheduleType === RbEnums.Common.ScheduleType.Sync && programProjectedTime.programName && programProjectedTime.programName.length > 0" > 
	<mat-icon>sync</mat-icon> {{programProjectedTime.programName}} 
</span>
<span *ngIf="programProjectedTime.scheduleType === RbEnums.Common.ScheduleType.ReverseSync && programProjectedTime.programName && programProjectedTime.programName.length > 0" > 
	<mat-icon>swap_vert</mat-icon> {{programProjectedTime.programName}} 
</span>
<span *ngIf="programProjectedTime.scheduleType === RbEnums.Common.ScheduleType.Logs && programProjectedTime.programName && programProjectedTime.programName.length > 0" > 
	<i class="icon-log"></i> {{programProjectedTime.programName}} 
</span>

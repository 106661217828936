<div class="station-diagnostic-mobile-cell">

	<div class="status"
		 [class.ok]="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK"
		 [class.no-fb]="data.result === RbEnums.Common.DiagnosticFeedbackResult.NO_FB"
		 [class.error]="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error">
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK && data.testType 
			=== RbEnums.Common.StationDiagnosticRunType.VoltageCheck" class="rb-status-icon icon-voltage_green"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error  && data.testType 
			=== RbEnums.Common.StationDiagnosticRunType.VoltageCheck" class="rb-status-icon icon-voltage_red"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK  && data.testType 
			!== RbEnums.Common.StationDiagnosticRunType.VoltageCheck" class="rb-status-icon icon-confirmation"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error  && data.testType 
			!== RbEnums.Common.StationDiagnosticRunType.VoltageCheck" class="rb-status-icon icon-warning"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.NO_FB" class="rb-status-icon icon-help"></span>
	</div>

	<div class="properties">
        <div class="updateTime">
			<span class="diagnostic-label">{{ 'STRINGS.DATE_SLASH_TIME' | translate }} :</span>
			<span class="diagnostic-value">{{data?.updateTime}}</span>
		</div>
		<div class="voltage" *ngIf="data.testType === RbEnums.Common.StationDiagnosticRunType.VoltageCheck">
			<span class="diagnostic-label">{{ 'STRINGS.VOLTAGE' | translate }} :</span>
			<span class="diagnostic-value">{{ data?.voltage }}</span>
		</div>
	</div>
</div>
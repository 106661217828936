<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="drm-cell-container">

	<div class="drmc-pane">
		<div class="drmc-labels">
			<div *ngIf="programProjectedTime?.programName">{{'STRINGS.TYPE' | translate}}:</div>
			<div *ngIf="programProjectedTime?.scheduledStart">{{'STRINGS.SCHEDULED_START' | translate}}:</div>
			<div *ngIf="programProjectedTime?.projectedStart">{{'STRINGS.PROJECTED_START' | translate}}:</div>
			<div class="start-time" *ngIf="!programProjectedTime?.projectedStart" >{{'STRINGS.PROJECTED_START' | translate}}:</div>
			<div *ngIf="programProjectedTime?.projectedEnd" >{{'STRINGS.PROJECTED_END' | translate}}:</div>
			<div class="end-time" *ngIf="!programProjectedTime?.projectedEnd" >{{'STRINGS.PROJECTED_END' | translate}}:</div>
		</div>
		<div class="drmc-values">
			<div *ngIf="programProjectedTime?.programName" class="ellipsis-text">{{programProjectedTime?.programName}}</div>
			<div *ngIf="programProjectedTime?.scheduledStart" class="ellipsis-text">{{programProjectedTime?.scheduledStartTimeString}}</div>
			<div *ngIf="programProjectedTime?.projectedStart" class="ellipsis-text">
				{{programProjectedTime?.projectedStartString}}
			</div>
			<div *ngIf="!programProjectedTime?.projectedStart" class="ellipsis-text">
				{{'STRINGS.OUT_OF_RANGE' | translate}}  
				<button mat-icon-button class="help" [matMenuTriggerFor]="helpContent" >
					<mat-icon class="help-icon" >info_outline</mat-icon>
				</button>
				<mat-menu #helpContent="matMenu" [overlapTrigger]="false" xPosition="before" class="qaid-header-subnav-identity">
				<ng-container>
					<div>
						<div class="ma3  info-line">{{ 'STRINGS.OUT_OF_RANGE_START' | translate }}</div>
					</div>
				</ng-container>
				</mat-menu>		
			</div>
			<div *ngIf="programProjectedTime?.projectedEnd || programProjectedTime?.scheduleType !== 0" class="ellipsis-text">
				{{programProjectedTime?.projectedEndString}}
			</div>
			<div *ngIf="!programProjectedTime?.projectedEnd && programProjectedTime?.scheduleType === 0" class="ellipsis-text">
				{{'STRINGS.OUT_OF_RANGE' | translate}}  
				<button mat-icon-button class="help" [matMenuTriggerFor]="helpContent" >
					<mat-icon class="help-icon" >info_outline</mat-icon>
				</button>
				<mat-menu #helpContent="matMenu" [overlapTrigger]="false" xPosition="before" class="qaid-header-subnav-identity">
				<ng-container>
					<div>
						<div class="ma3  info-line">{{ 'STRINGS.OUT_OF_RANGE_HELP' | translate }}</div>
					</div>
				</ng-container>
				</mat-menu>		
			</div>
		</div>
	</div>
</div>

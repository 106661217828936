<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="runtimes-mobile-cell flex flex-column">
	<div class="flex justify-between">
		<div class="ellipsis-text b w-60-m"> {{runtime?.sequenceNumber}} </div>
		<div class="ellipsis-text b w-60-m"> {{runtime?.area}} </div>
		<div class="ellipsis-text b w-60-m"> {{runtime?.tag}} </div>
		<div class="ellipsis-text b w-60-m"> {{runtime?.runTime}} </div>
	</div>
</div>

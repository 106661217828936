import { ChangeBase } from './change-base.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';

export class DryrunChange extends ChangeBase {

	constructor(json: any = null) {

		super(json);

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here

			if (json.changeDateTime) { this.changeDateTime = RbUtils.Conversion.convertStringToDate(json.changeDateTime); }
		}
	}
	dryRunId: number;
	changeType: string;
	reasonCode: RbEnums.SignalR.DryRunFailureReasonCode;
	progress: number;
	dryRunUUID: string
}

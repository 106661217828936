import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
	selector: '[rbSelect][formControlName],[rbSelect][formControl],[rbSelect][ngModel]',
	providers: [{ provide: NG_VALIDATORS, useExisting: RbSelectDirective, multi: true }],
})

export class RbSelectDirective implements Validator {
	@Input() rbSelect: any;

	validate(c: FormControl): { [key: string]: any } {
		const v = c.value;
		return (!v) ? { 'rbSelect': true } : null;
	}
}

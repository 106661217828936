export class ManualControlItem {

	constructor(manualControlItem?: ManualControlItem) {
		this.value = manualControlItem ? manualControlItem.value : null;
		this.isUpdating = manualControlItem ? manualControlItem.isUpdating : false;
		this.error = manualControlItem ? manualControlItem.error : null;
	}

	error?: string;
	isUpdating: boolean;
	value: any;
}

import * as moment from 'moment';
import { Controller } from '../../controllers/models/controller.model';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class DryRunProgramDatum {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.start) this.start = RbUtils.Conversion.convertStringToDate(json.start); // Cloud
			if (json.stop) this.stop = RbUtils.Conversion.convertStringToDate(json.stop); // Cloud

			if (json.startTimeOffset) {
				this.startTimeOffset = RbUtils.Common.timeOffsetToDisplayTimeDryRun(json.startTimeOffset);
			} 
			if (json.stopTimeOffset) {
				this.stopTimeOffset = RbUtils.Common.timeOffsetToDisplayTimeDryRun(json.stopTimeOffset);
			} 
			if (json.satellite) this.satellite = new Controller(json.satellite);
		}
	}

	id: number;
	programShortName: string;
	programId: number = null;
	parentId: number = null;		// null for IQ
	parentName: string = null;		// null for IQ
	start: Date; // Cloud
	stop: Date; // Cloud
	startTimeIndex: number;
	startTimeOffset: Date;
	stopTimeOffset: Date;
	satelliteId: number;
	companyId: number;
	dryRunId: number;
	programName: string;
	satellite: Controller = null;
	dryRun: any = null;				// TODO: MW - USE PROPER TYPE - DryRun
	program: any = null;			// TODO: MW - USE PROPER TYPE - Program
	parent: any = null;				// TODO: MW - USE PROPER TYPE - ProgramGroup
	isRunning = false;
	isCloud = false;
	isRunningAtDateTime(date: Date) {
		const timestamp = moment(date);
		return timestamp >= moment(this.startTimeOffset) && (timestamp <= moment(this.stopTimeOffset)
		|| this.stopTimeOffset.getTime() === new Date(2000, 0, 1).getTime());
	}
}

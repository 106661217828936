import { Observable, of } from 'rxjs';
import { BroadcastService } from '../../common/services/broadcast.service';
import { Injectable } from '@angular/core';
import { LicenseApiService } from './license-api.service';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class LicenseManagerService extends ServiceManagerBase {

	// Cache Containers
	private _isCloud: boolean = null;
	private _allowGolfUpgraded: boolean = null;
	_isActivate: boolean = null;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private licenseApiService: LicenseApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	protected clearCache() {
		// Cloud can't change state, so we don't clear it. Once we have a value, it sticks: this._isCloud = null;
		this._allowGolfUpgraded = null;
		this._isActivate = null;
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	activateLicense(activationCode: string): Observable<boolean> {
		return this.licenseApiService.activateLicense(activationCode)
		.pipe(tap(isActivate => {
				// Clear the state of the AllowGolfUpgraded flag. It might have just changed.
				this.clearCache();

				this._isActivate = isActivate;

				return isActivate;
			})
		);
		// return this.licenseApiService.activateLicense(activationCode);
	}

	allowGolfUpgraded(bypassCache = false): Observable<boolean> {
		// RB-7990: Return false in the cloud.
		if (this.isCloud) {
			return of(false);
		}

		if (!bypassCache && (this._allowGolfUpgraded != null)) {
			return of(this._allowGolfUpgraded);
		}

		return this.licenseApiService.allowGolfUpgraded()
			.pipe(tap(_allowGolfUpgraded => {
					this._allowGolfUpgraded = _allowGolfUpgraded;
					return _allowGolfUpgraded;
				})
			);
	}

	getComputerId(): Observable<any> {
		return this.licenseApiService.getComputerId();
	}

	getComputerIdAndActivationCodes(): Observable<any> {
		return this.licenseApiService.getComputerIdAndActivationCodes();
	}

	getFeatureNames(): Observable<any> {
		return this.licenseApiService.getFeatureNames();
	}

	getProductName(): Observable<any> {
		return this.licenseApiService.getProductName();
	}

	isActivated(): Observable<boolean> {
		return this.licenseApiService.isActivated();
	}

	allowGolf(): Observable<boolean> {
		return this.licenseApiService.allowGolf();
	}

	isCloud(bypassCache = true): Observable<boolean> {
		if (!bypassCache && (this._isCloud != null)) {
			return of(this._isCloud);
		}

		return this.licenseApiService.isCloud()
			.pipe(tap(iscloud => {
					this._isCloud = iscloud;
					return iscloud;
				})
			);
	}

	/**
	 * Get indication of whether current company database activation code is "right" for the machine where it's running.
	 * If not, this may indicate that we're running a customer database on an engineering or QA machine, or that the
	 * customer's machine has changed, invalidating the license. We want to know this in case the "change" was accidental
	 * (using a different active network adapter). It allows us to notify the user that their DNS IP address won't be 
	 * updated, their certificate won't be updated, etc.
	 * @returns boolean - true if the license is valid for this machine; false if not.
	 */
	isCompanyLicenseValid(): Observable<boolean> {
		return this.licenseApiService.isCompanyLicenseValid();
	}
}

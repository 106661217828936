export class OnOffStateModel {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}

	name: string;
	value: number;
}

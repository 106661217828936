<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="!hideCloseButton">
	<div class="absolute right-0 closeButton">
		<i class="material-icons f5"
			mat-button (click)="cancel()"
			data-qaid="dialog-cancel-button">
			{{ 'STRINGS.CLOSE_LOWER_CASE' | translate }}
		</i>
	</div>
	<div class="pb3"></div>
</div>

<header *ngIf="!hideHeader" class="flex rb-dialog--header w-100">
	<mat-icon *ngIf="headerIcon != null">{{headerIcon}}</mat-icon>
	<h3 mat-dialog-title class="header3 pb3 mb2" title="{{title}}">{{ title }}</h3>
	<div *ngIf="showExport" class="absolute right-0 rb-dialog-export-icon">
		<rb-icon-button
			[icon]="exportCSVIcon"
			[label]="'STRINGS.EXPORT' | translate"
			(iconButtonClicked)="handleExportCSVClick()"
		>
		</rb-icon-button>
	</div>
</header>
<mat-dialog-content class="rb-dialog--content mat-dialog-content absolute w-100 overflow-y-scroll pa4">
	<ng-content></ng-content>
</mat-dialog-content>
<div class="rb-dialog--footer flex justify-end absolute bottom-0 w-100 pt3 pb3 z-9999 bg-white">
	<!--	<div class="rb-dialog&#45;&#45;footer-bg bg-white absolute w-100 h-100 left-0"></div>-->
	<div *ngIf="showFooterSeparator" class="rb-dialog-footer-sep"></div>
	<mat-dialog-actions class="flex justify-end" *ngIf="showDialogAction">
		<button
			mat-button
			(click)="cancel()"
			data-qaid="dialog-cancel-button"
		>
			{{ closeButtonLabel }}
		</button>
		<button
			mat-button
			*ngIf="showSubmitButton"
			(click)="submit()"
			color="primary"
			data-qaid="dialog-submit-button"
			[disabled]="saveDisabled"
		>
			{{ submitButtonLabel }}
			<i [hidden]="!isLoading" class="fa fa-circle-o-notch fa-spin"></i>
		</button>

		<ng-container *ngIf="additionalActions?.length">
			<button
				mat-button
				*ngFor="let action of additionalActions; index as i"
				data-qaid="dialog-additional-{{i}}"
				[color]="action.color"
				[disabled]="action.isDisabled || action.isLoading"
				(click)="action?.emit()"
			>
				{{ action.actionText }}
				<i [hidden]="!action.isLoading" class="fa fa-circle-o-notch fa-spin"></i>
			</button>
		</ng-container>
	</mat-dialog-actions>
</div>

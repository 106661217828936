import { AvailableUpdate } from '../software-update/models/available-update.model';
import { ChangeBase } from './change-base.model';
import { CompanyStatus } from '../companies/models/company-status.model';
import { RbUtils } from '../../common/utils/_rb.utils';

export class CompanyStatusChange extends ChangeBase {
	constructor(json: any = null) {

		super(json);

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			if (json.changeDateTime) { this.changeDateTime = RbUtils.Conversion.convertStringToDate(json.changeDateTime); }
			if (json.companyCounts) { this.companyCounts = new CompanyStatus(json.companyCounts); }
			if (json.userConnectedControllerCount) { this.connectedControllers = json.userConnectedControllerCount[RbUtils.User.currentUserId] || 0; }
		}
	}
	userName: string
	changeDateTime: Date;
	changeType: string;
	companyCounts: CompanyStatus;
	companyId: number;
	connectedControllers: number;
	diagnosticData: any;
	dValue: number;
	irrigationEngineId: number;
	reasonCode:  number;
	applicableSoftwareUpdates: AvailableUpdate[] = [];
}

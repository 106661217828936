import { Component, Input } from '@angular/core';

@Component({
	selector: 'rb-report-cover-page',
	templateUrl: './report-cover-page.component.html',
	styleUrls: ['./report-cover-page.component.scss'],
})
export class ReportCoverPageComponent {

	@Input() companyname: string;
	@Input() dateCaption: string;
	@Input() reportdate: Date;
	@Input() reportname: string;
	@Input() sites: string[];
	@Input() username: string;

	today() {
		const d = new Date();
		return d;
	}
}

<div class="station-list-container">
	<div class="header">
		<div class="title-search">
			<h3 class="title">{{ 'STRINGS.STATIONS_BY_HOLE' | translate}}</h3>
			<!-- <input type="text" class="search-input" [(ngModel)]="searchFilter" #searchInput [class.open]="isSearching"
				placeholder="Search"> -->
		</div>
		<!-- <div class="filter-options">
			<div class="button-container">
				<button mat-button class="search-button" (click)="toggleSearch()">
					<i class="mdi mdi-magnify" *ngIf="!isSearching"></i>
					<i class="mdi mdi-magnify-close" *ngIf="isSearching"></i>
				</button>
			</div>
			<div class="button-container">
				<button mat-button class="search-button" (click)="toggleSearch()">
					<i class="mdi mdi-close"></i>
				</button>
			</div>
		</div> -->
	</div>
	<div class="body">

		<mat-accordion class="tree-list">
			<!-- Holes -->
			<mat-expansion-panel *ngFor="let hole of treeStructure" [expanded]="expandedHoleId === hole.id" [disabled]="true">
				<mat-expansion-panel-header class="item-list">
					<div class="panel-header-wrapper" cdkDropList cdkDropListConnectedTo="map">
						<div class="title-content" cdkDrag [cdkDragDisabled]="hole.icon || dragDisabled" [cdkDragData]="hole"
							(cdkDragMoved)="onDragMoved.emit($event)" (cdkDragReleased)="onDragEnded.emit()">

							<div class="div-expansion-icon">
								<mat-icon class="expansion-icon" (click)="expandHole(hole.id)">
									{{expandedHoleId === hole.id ? 'expand_more' : 'chevron_right'}}
								</mat-icon>
							</div>

							<div class="div-icon">
								<mat-icon class="location-icon-font" *ngIf="!hole.icon">
									add_location
								</mat-icon>
								<i class="mdi mdi-golf text-yellow no-drag-icon" cdkDragHandle *ngIf="hole.icon">
								</i>
								<i class="mdi mdi-golf text-yellow drag-icon" [style.font-size]="'34px'" *cdkDragPreview>
								</i>
							</div>

							<div class="div-name">
								<span class="ellipsis-text" [class.has-location]="hole.icon" (click)="onHoleClicked($event, hole)"
									cdkDragHandle [cdkDragHandleDisabled]="isMobile || hole.icon">
									{{hole.name}}
								</span>
							</div>

							<div class="div-checkbox" *ngIf="!isWidget">
								<mat-checkbox class="ml-auto" [(ngModel)]=hole.isSelected (change)="onChangeHole(hole)"
									[indeterminate]="hole.checkState === 1">
								</mat-checkbox>
							</div>

						</div>
					</div>
				</mat-expansion-panel-header>

				<!-- Holes panel = areas -->
				<mat-accordion>
					<mat-expansion-panel *ngFor="let holeArea of hole.areas"
						[expanded]="expandedHoleId === hole.id && expandedAreaId === holeArea.id" [disabled]="true">
						<mat-expansion-panel-header class="item-list">
							<div class="panel-header-wrapper" cdkDropList cdkDropListConnectedTo="map">
								<div class="title-content" cdkDrag [cdkDragDisabled]="holeArea.icon || dragDisabled"
									[cdkDragData]="{ holeId: hole.id, area: holeArea }" (cdkDragMoved)="onDragMoved.emit($event)"
									(cdkDragReleased)="onDragEnded.emit()">

									<div class="div-expansion-icon">
										<mat-icon class="expansion-icon" (click)="expandArea(holeArea.id)">
											{{expandedHoleId === hole.id && expandedAreaId === holeArea.id
											? 'expand_more'
											: 'chevron_right'
											}}
										</mat-icon>
									</div>

									<div class="div-icon">
										<mat-icon class="location-icon-font" *ngIf="!holeArea.icon">
											add_location
										</mat-icon>
										<i class="mdi mdi-vector-square text-green no-drag-icon" cdkDragHandle *ngIf="holeArea.icon">
										</i>
										<i class="mdi mdi-vector-square text-green drag-icon" [style.font-size]="'34px'" *cdkDragPreview>
										</i>
									</div>

									<div class="div-name">
										<span class="ellipsis-text" [class.has-location]="holeArea.icon"
											(click)="onAreaClicked($event, hole.id, holeArea)" cdkDragHandle
											[cdkDragHandleDisabled]="isMobile || holeArea.icon">
											{{holeArea.name}}
										</span>
									</div>

									<div class="div-checkbox" *ngIf="!isWidget">
										<mat-checkbox class="ml-auto" [(ngModel)]=holeArea.isSelected (change)="onChangeArea(holeArea)"
											[indeterminate]="holeArea.checkState === 1">
										</mat-checkbox>
									</div>
								</div>
							</div>
						</mat-expansion-panel-header>

						<!-- Areas panel = sub areas -->
						<mat-accordion>
							<mat-expansion-panel *ngFor="let subArea of holeArea.subAreas"
								[expanded]="expandedHoleId === hole.id && expandedAreaId === holeArea.id && expandedSubAreaId === subArea.id"
								[disabled]="true">
								<mat-expansion-panel-header class="item-list">
									<div class="panel-header-wrapper">
										<div class="title-content">

											<div class="div-expansion-icon">
												<mat-icon class="expansion-icon" *ngIf="subArea.stations" (click)="expandSubArea(subArea.id)">
													{{expandedHoleId === hole.id && expandedAreaId === holeArea.id && expandedSubAreaId ===
													subArea.id
													? 'expand_more'
													: 'chevron_right'
													}}
												</mat-icon>
											</div>

											<!-- <div class="div-icon">
																			</div> -->

											<div class="div-name">
												<span class="ellipsis-text" [class.has-location]="subArea.icon">
													{{subArea.name}}
												</span>
											</div>

											<div class="div-checkbox" *ngIf="!isWidget">
												<mat-checkbox class="ml-auto" [(ngModel)]=subArea.isSelected (change)="onChangeSubArea(subArea)"
													[indeterminate]="subArea.checkState === 1">
												</mat-checkbox>
											</div>
										</div>
									</div>
								</mat-expansion-panel-header>


								<!-- Sub Areas panel = stations -->
								<div class="station-accordion" *ngIf="expandedHoleId === hole.id 
																			&& expandedAreaId === holeArea.id 
																			&& expandedSubAreaId === subArea.id
																			&& subArea.stations
																	">
									<div *ngFor="let station of subArea.stations" class="mat-expansion-panel">
										<div class="mat-expansion-panel-header item-list">
											<span class="mat-content">
												<div class="panel-header-wrapper" cdkDropList cdkDropListConnectedTo="map">
													<div class="title-content" cdkDrag [cdkDragDisabled]="station.hasLocation || dragDisabled"
														[cdkDragData]="station" (cdkDragMoved)="onDragMoved.emit($event)"
														(cdkDragReleased)="onDragEnded.emit()">

														<div class="div-icon father-without-icon">
															<mat-icon class="location-icon-font" *ngIf="!station.hasLocation">
																add_location
															</mat-icon>
															<i class="station no-drag-icon" cdkDragHandle *ngIf="station.hasLocation"
																(click)="onStationClicked($event, station)">
																<div class="outer-circle">
																	<div class="inner-circle"
																		[style.background]="station.nozzleColor ? station.nozzleColor.toLowerCase() : '#ddd' ">
																	</div>
																</div>
															</i>
															<i class="mdi mdi-circle-double text-orange drag-icon" [style.font-size]="'34px'"
																*cdkDragPreview>
															</i>
														</div>

														<div class="div-name">
															<span class="station-name title-text ellipsis-text no-select"
																[class.has-location]="station.hasLocation" (click)="onStationClicked($event, station)"
																cdkDragHandle [cdkDragHandleDisabled]="isMobile || station.hasLocation">
																{{station.name}}
															</span>
														</div>

														<div class="div-checkbox" *ngIf="!isWidget">
															<mat-checkbox class="tree-checkbox ml-auto" [(ngModel)]=station.isSelected
																(change)="onChangeStation(station)">
															</mat-checkbox>
														</div>

													</div>
												</div>
											</span>
										</div>
									</div>


								</div>
							</mat-expansion-panel>
						</mat-accordion>

					</mat-expansion-panel>
				</mat-accordion>

			</mat-expansion-panel>
		</mat-accordion>
	</div>
	<div class="footer" *ngIf="!isWidget">
		<rb-multi-select-buttons [mapInfo]="mapInfo" (closeLeftPanel)="closeLeftPanel.emit($event)">
		</rb-multi-select-buttons>
	</div>
</div>
<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>
<div class="gprs-renew-warning">
	<div class="renewal-background" [ngClass]="{'expired-background':expiryState == ExpiringConstants.expired,
	'expiring-background':expiryState == ExpiringConstants.expiring,
	'info-background':expiryState == ExpiringConstants.info}">
		<div *ngIf="expiryState == ExpiringConstants.expired" class="icon-space icon-space-expired">
				<mat-icon [inline]="true" >error_outline</mat-icon>
		</div>
		<div *ngIf="expiryState == ExpiringConstants.expiring" class="icon-space icon-space-expiring">
				<mat-icon [inline]="true"><i class="icon-warning"></i></mat-icon>
		</div>
		<div *ngIf="expiryState == ExpiringConstants.info" class="icon-space icon-space-info">
				<mat-icon [inline]="true" >error_outline</mat-icon>
		</div>
		<div class="text-space" *ngIf="hasExpirationDate" >
			<div>{{ warningText }}<span  class="expiry-date"> {{expiryDate}}.</span></div>
			<mat-label class="hyperlink" (click) = "onNavigate()">
				<u>{{ 'SPECIAL_MSG.GPRS_LICENSE_RENEW_NOW' | translate}}.</u>
			</mat-label>
		</div>
	</div>
</div>

import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { GprsLicenseListItem } from './models/gprs-license-list-item.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LicenseApiCloudService extends ApiService { 

	// =========================================================================================================================================================
	// LicenseApiCloud Properties and Methods
	// =========================================================================================================================================================
	getMostExpiredLicenseData(): Observable<GprsLicenseListItem> {
		return this.apiRequest(this.getMostExpiredLicenseDataUrl, HttpMethod.Get)
			.pipe(map(response => response !== null ? new GprsLicenseListItem(response) : null));
	}
	getMostExpiredLicenseDataForIP(ips: string[]): Observable<GprsLicenseListItem> {
		return this.apiRequest(this.getMostExpiredLicenseDataForIPUrl(ips), HttpMethod.Get)
			.pipe(map(response => response !== null ? new GprsLicenseListItem(response) : null));
	}
	getGprsLicenseDataForCompany(bypassCache: boolean): Observable<ApiCachedRequestResponse<GprsLicenseListItem[]>> {
		return this.apiRequestWithCache<any>(this.getGprsLicenseDataForCompanyUrl, bypassCache,
			result => result.map(item => new GprsLicenseListItem(item), 60 * 60 /* cache duration of 1 hour */));
	}
	getGprsLicenseDataForIP(ips: string[], bypassCache: boolean): Observable<ApiCachedRequestResponse<GprsLicenseListItem[]>> {
		return this.apiRequestWithCache<any>(this.getGprsLicenseDataForIPUrl(ips), bypassCache,
			result => result.map(item => new GprsLicenseListItem(item), 60 * 60 /* cache duration of 1 hour */));
	}
	
	updateGprsLicenseByChangingIp(oldIpAddr: string, newIpAddr: string, remainingDays: number): Observable<void> {
		return this.apiRequest<any>(this.updateGprsLicenseByChangingIpUrl(oldIpAddr, newIpAddr, remainingDays ), HttpMethod.Put);
	}
	// =========================================================================================================================================================
	// LicenseApiCloud URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseLicenseApiCloudUrl}Licenses`; }
	private get getMostExpiredLicenseDataUrl(): string { return `${this.baseUrl}/GetMostExpiredLicenseData`; }
	private getMostExpiredLicenseDataForIPUrl(ips: string[]): string { 
		const ipsParam = (ips || []).reduce((list, ip) => `${list ? list + '&' : ''}ipAddresses=${ip}`, '');
		const url = `${this.baseUrl}/GetMostExpiredLicenseDataForIP?${ipsParam}`;
		return url;; 
	}
	private get getGprsLicenseDataForCompanyUrl(): string {	return `${this.baseUrl}/GetGprsLicenseDataForCompany`;}
	private getGprsLicenseDataForIPUrl(ips: string[]): string {
		const ipsParam = ips == null ? '' : ips.reduce((list, ip) => `${list ? list + '&' : ''}ipAddresses=${ip}`, '');
		return `${this.baseUrl}/GetGprsLicenseDataForIP?${ipsParam}`;
	}
	private updateGprsLicenseByChangingIpUrl(oldIpAddr: string, newIpAddr: string, remainingDays: number): string {
		return `${this.baseUrl}/UpdateGprsLicenseByChangingIp?oldIpAddr=${oldIpAddr}&newIpAddr=${newIpAddr}&remainingDays=${remainingDays}`;
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="program-mobile-cell flex flex-column">

	<div class="flex justify-between">
		<div>{{program?.name}}</div>
		<div>{{program?.waterCycle}}</div>
	</div>

	<div class="flex justify-between">
		<div>{{program?.status}}</div>
		<div>{{program?.startTime  | date: 'h:mm a'}}</div>
		<div>{{adjust}} {{'STRINGS.INCHES_ABBREVIATION' | translate}}</div>
	</div>

</div>

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>
<span *ngIf="dryRunEventListItem.dateTimeString" >
	{{dryRunEventListItem.dateTimeString}}
</span>
<span *ngIf="!dryRunEventListItem.dateTimeString" >
	{{'STRINGS.OUT_OF_RANGE' | translate}}
	<button mat-icon-button class="help" [matMenuTriggerFor]="helpContent" >
		<mat-icon class="help-icon" >info_outline</mat-icon>
	</button>
</span>
<mat-menu #helpContent="matMenu" [overlapTrigger]="false" xPosition="before" class="qaid-header-subnav-identity">
<ng-container>
	<div>
		<div class="ma3  info-line">{{dryRunEventListItem.dateOutOfRangeHelpString}}</div>
	</div>
</ng-container>
</mat-menu>

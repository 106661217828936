import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'rb-report-button',
	templateUrl: './report-button.component.html',
	styleUrls: ['./report-button.component.scss'],
})
export class ReportButtonComponent {

	@Input() label: string;
	@Input() modalSubmitting: boolean;
	@Input() disabled = false;

	@Output() reportButtonClicked = new EventEmitter();
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="drm-cell-container">

	<div class="drmc-pane">
		<div class="drmc-labels">
			<div>{{'STRINGS.TYPE' | translate}}:</div>
			<div>{{'STRINGS.NAME' | translate}}:</div>
		</div>
		<div class="drmc-values">
			<div class="ellipsis-text">{{dryRunEvent?.entityString}}</div>
			<div class="ellipsis-text">{{dryRunEvent?.name}}</div>
		</div>
	</div>

	<div *ngIf="dryRunEvent?.eventString==='Started' && dryRunEvent?.dateTimeString" class="drmc-pane">
		<div class="drmc-labels">
			<div>{{'STRINGS.EVENT' | translate}}:</div>
			<div>{{'STRINGS.DATE' | translate}}:</div>
			<div>{{'STRINGS.TIME' | translate}}:</div>
		</div>
		<div class="drmc-values">
			<div class="ellipsis-text">{{dryRunEvent?.eventString}}</div>
			<div class="ellipsis-text">{{dryRunEvent?.dateString}}</div>
			<div class="ellipsis-text">{{dryRunEvent?.timeString}}</div>
		</div>
	</div>
	<div *ngIf="dryRunEvent?.eventString==='Started' && !dryRunEvent?.dateTimeString" class="drmc-pane">
		<div class="drmc-labels">
			<div>{{'STRINGS.EVENT' | translate}}:</div>
			<div>{{'STRINGS.DATE' | translate}}:</div>
			<div>{{'STRINGS.TIME' | translate}}:</div>
		</div>
		<div class="drmc-values">
			<div class="ellipsis-text">{{dryRunEvent?.eventString}}</div>
			{{'STRINGS.OUT_OF_RANGE' | translate}}
			<button mat-icon-button class="help" [matMenuTriggerFor]="helpContent" >
				<mat-icon class="help-icon" >info_outline</mat-icon>
			</button>
			<mat-menu #helpContent="matMenu" [overlapTrigger]="false" xPosition="before" class="qaid-header-subnav-identity">
			<ng-container>
				<div>
					<div class="ma3  info-line">{{ 'STRINGS.OUT_OF_RANGE_START' | translate }}</div>
				</div>
			</ng-container>
			</mat-menu>
		</div>
	</div>
	<div *ngIf="dryRunEvent?.eventString==='Completed' && dryRunEvent?.dateTimeString" class="drmc-pane">
		<div class="drmc-labels">
			<div>{{'STRINGS.EVENT' | translate}}:</div>
			<div>{{'STRINGS.DATE' | translate}}:</div>
			<div>{{'STRINGS.TIME' | translate}}:</div>
		</div>
		<div class="drmc-values">
			<div class="ellipsis-text">{{dryRunEvent?.eventString}}</div>
			<div class="ellipsis-text">{{dryRunEvent?.dateString}}</div>
			<div class="ellipsis-text">{{dryRunEvent?.timeString}}</div>
		</div>
	</div>
	<div *ngIf="dryRunEvent?.eventString==='Completed' && !dryRunEvent?.dateTimeString" class="drmc-pane">
		<div class="drmc-labels">
			<div>{{'STRINGS.EVENT' | translate}}:</div>
			<div>{{'STRINGS.DATE' | translate}}:</div>
			<div>{{'STRINGS.TIME' | translate}}:</div>
		</div>
		<div class="drmc-values">
			<div class="ellipsis-text">{{dryRunEvent?.eventString}}</div>
			{{'STRINGS.OUT_OF_RANGE' | translate}}
			<button mat-icon-button class="help" [matMenuTriggerFor]="helpContent" >
				<mat-icon class="help-icon" >info_outline</mat-icon>
			</button>
			<mat-menu #helpContent="matMenu" [overlapTrigger]="false" xPosition="before" class="qaid-header-subnav-identity">
			<ng-container>
				<div>
					<div class="ma3  info-line">{{ 'STRINGS.OUT_OF_RANGE_HELP' | translate }}</div>
				</div>
			</ng-container>
			</mat-menu>
		</div>
	</div>
<!--	<div class="flex justify-between">-->
<!--		<div><span class="drmc-label">{{'STRINGS.TYPE' | translate}}:</span>{{dryRunEvent?.entityString}}</div>-->
<!--		<div><span class="drmc-label">{{'STRINGS.DATE' | translate}}:</span>{{dryRunEvent?.dateString}}</div>-->
<!--	</div>-->
<!--	<div class="flex justify-between">-->
<!--		<div><span class="drmc-label">{{'STRINGS.NAME' | translate}}:</span>{{dryRunEvent?.name}}</div>-->
<!--		<div><span class="drmc-label">{{'STRINGS.TIME' | translate}}:</span>{{dryRunEvent?.timeString}}</div>-->
<!--	</div>-->
<!--	<div class="flex justify-between">-->
<!--		<div><span class="drmc-label">{{'STRINGS.EVENT' | translate}}:</span>{{dryRunEvent?.eventString}}</div>-->
<!--	</div>-->

</div>

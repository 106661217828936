import * as moment from 'moment';
import { ConnectDataPack } from '../../connect-data-pack/models/connect-data-pack.model';
import { IrrigationQueueItem } from '../../connect-data-pack/models/irrigation-queue-item.model';
import { IrrigationStatus } from '../../connect-data-pack/models/irrigation-status.model';
import { ManualControlItem } from './manual-control-item.model';
import { RbConstants } from '../../../common/constants/_rb.constants';

export class ManualControlState {

	constructor(controllerId: number, connectDataPack: ConnectDataPack) {

		this.controllerId = controllerId;
		this.irrigationState = '---';
		this.isAuto = new ManualControlItem();
		this.isClearingFlowLogs = new ManualControlItem();
		this.isClosingMasterValves = new ManualControlItem();
		this.isConnecting = new ManualControlItem();
		this.isFloManagerEnabled = new ManualControlItem();
		this.isFloWatchEnabled = new ManualControlItem();
		this.isSensorActive = new ManualControlItem();
		this.isTwoWirePathOn = new  ManualControlItem();
		this.isSettingWaterWindow = new ManualControlItem();
		this.rainDelayDays = new ManualControlItem();
		this.hasFlowAlarms = new ManualControlItem();
		this.flowAlarmCount = new ManualControlItem();

		this.updateStateFromConnectDataPack(connectDataPack);
	}

	controllerId: number;
	irrigationState: string;
	isAuto: ManualControlItem;
	isClearingFlowLogs: ManualControlItem;
	isClosingMasterValves: ManualControlItem;
	isConnecting: ManualControlItem;
	isFloManagerEnabled: ManualControlItem;
	isFloWatchEnabled: ManualControlItem;
	isTwoWirePathOn: ManualControlItem;
	isSensorActive: ManualControlItem;
	isSettingWaterWindow: ManualControlItem;
	rainDelayDays: ManualControlItem;
	hasFlowAlarms: ManualControlItem;
	flowAlarmCount: ManualControlItem;
	timeStamp: Date;
	hasReceivedConnectDataPack = false;
	irrigationQueue?: IrrigationQueueItem[];
	irrigationStatus?: IrrigationStatus;
	updateStateFromConnectDataPack(connectDataPack: ConnectDataPack) {
		if (!connectDataPack || !connectDataPack.irrigationStatus) return;

		this.hasReceivedConnectDataPack = true;

		this.irrigationState = connectDataPack.irrigationStatus.irrigationState;
		this.isAuto.value = connectDataPack.logicalRotaryPosition !== 'Off';
		this.isSensorActive.value = connectDataPack.logicalSensorPosition === 'Active';
		this.isFloManagerEnabled.value = connectDataPack.irrigationStatus.floManagerState;
		this.isFloWatchEnabled.value = connectDataPack.irrigationStatus.flowSensingState;
		this.isTwoWirePathOn.value = connectDataPack.irrigationStatus.twoWirePathState;
		this.rainDelayDays.value = connectDataPack.rainDelayDays;
		this.timeStamp = new Date(connectDataPack.timeStamp);
		this.hasFlowAlarms.value = connectDataPack.hasFlowAlarms;
		this.flowAlarmCount.value = connectDataPack.flowAlarmCount;
		this.irrigationQueue = connectDataPack.irrigationQueue;
		this.irrigationStatus = connectDataPack.irrigationStatus;
	}

	get isStale(): boolean {
		const minutesSinceUpdate = moment.duration(moment(new Date()).diff(moment(this.timeStamp))).asMinutes();
		return minutesSinceUpdate >= RbConstants.Common.MaxAgeOfSatelliteChangeInMinutes;
	}
}

export class ControllerSeasonalAdjust {

	aprAdjust: number;
	augAdjust: number;
	companyId: number;
	decAdjust: number;
	febAdjust: number;
	id: number;
	janAdjust: number;
	julAdjust: number;
	junAdjust: number;
	marAdjust: number;
	mayAdjust: number;
	novAdjust: number;
	octAdjust: number;
	satelliteId: number;
	sepAdjust: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges, } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AdditionalAction } from './dialog-additional-action.model';

@Component({
	selector: 'rb-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnChanges {
	@HostBinding('class') class = 'rb-dialog';

	@Input() closeButtonLabel: string;
	@Input() closeOnSubmit: boolean;
	@Input() closeOnClick = true;
	@Input() isLoading: boolean;
	@Input() saveDisabled: boolean;
	@Input() showSubmitButton: boolean;
	@Input() submitButtonLabel: string;
	@Input() title: string;
	@Input() hideHeader = false;
	@Input() showFooterSeparator = false;
	@Input() hideCloseButton = false;
	@Input() headerIcon: string;
	@Input() showExport = false;
	@Input() exportCSVIcon: string;
	@Input() showDialogAction = true;
	@Input() isSelfHandleDialogClose = false;
	@Input() additionalActions: AdditionalAction[] = null;

	@Output() onCancel = new EventEmitter();
	@Output() onLoading = new EventEmitter();
	@Output() onSubmit = new EventEmitter();
	@Output() exportCSVClick = new EventEmitter<any>();

	init = false;
	private dialogRef: MatDialogRef<any>;

	constructor(public dialog: MatDialog) {
		this.dialog.afterOpened.subscribe((ref: MatDialogRef<any>) => {
			this.dialogRef = ref;
			this.dialogRef.backdropClick().subscribe(() => this.onCancel.emit(event) );
		});
	}

	cancel() {
		this.onCancel.emit(event);
		if (this.closeOnClick && !this.isSelfHandleDialogClose) {
			this.dialog.closeAll();
		}
	}

	submit() {
		this.onSubmit.emit();
		if (this.closeOnSubmit && !this.isSelfHandleDialogClose) {
			this.dialog.closeAll();
		}
	}

	handleExportCSVClick() {
		this.exportCSVClick.emit();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.isLoading !== undefined && changes.isLoading.currentValue !== changes.isLoading.previousValue) {
			if (!this.init) {
				this.init = true;
			} else {
				if (!changes.isLoading.currentValue) {
					setTimeout(() => this.onLoading.emit(), 100);
				}
			}
		}
	}
}

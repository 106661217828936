/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, DoCheck, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { RbConstants } from '../../../common/constants/_rb.constants';

@Directive()
export class CourseViewerBase implements OnInit, DoCheck {
	@ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
	@ViewChild('scroller', { static: true }) scroller: ElementRef;
	@ViewChild('holesRow', { static: true }) holesRow: ElementRef;
	@ViewChildren('dataRow') dataRows: QueryList<ElementRef>;

	CustomFontIconNumber = RbConstants.Form.CUSTOM_ICON_NUMBER;

	scrollerNatEl: HTMLElement;
	holesRowNatEl: HTMLElement;
	isDataRowScrolling = false;
	isHolesRowScrolling = false;
	isScrollerScrolling = false;
	dataRowScrollingTimerRef: any;
	holesRowScrollingTimerRef: any;
	scrollerScrollingTimerRef: any;
	activeScrollEventSrcElement: any;
	isBusy = false;
	loadError: string;
	isDisplayable = false;

	private readonly TOOLBAR_WIDTH = 100;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor() {}

	ngOnInit() {
		this.scrollerNatEl = this.scroller.nativeElement as HTMLElement;
		this.holesRowNatEl = this.holesRow.nativeElement as HTMLElement;
	}

	ngDoCheck() {
		if (this.dataRows && this.dataRows.first) {
			const dataRowWidth = this.dataRows.first.nativeElement.clientWidth + 2;

			this.holesRow.nativeElement.parentNode.style.width = dataRowWidth + this.TOOLBAR_WIDTH + 'px';
			this.scroller.nativeElement.parentNode.style.width = dataRowWidth + this.TOOLBAR_WIDTH + 'px';
		}
	}

// =========================================================================================================================================================
	// Scrolling Event Handlers
	// =========================================================================================================================================================

	onUpdateHolesRowScroll() {
		if (this.isScrollerScrolling || this.isDataRowScrolling) { return; }

		this.isHolesRowScrolling = true;

		this.scrollerNatEl.scrollLeft = this.holesRowNatEl.scrollLeft;
		this.dataRows.forEach(elRef => { elRef.nativeElement.scrollLeft = this.holesRowNatEl.scrollLeft; });

		clearTimeout(this.holesRowScrollingTimerRef);
		this.holesRowScrollingTimerRef = setTimeout(() => {
			this.isHolesRowScrolling = false;
		}, 500);
	}

	onDataRowScroll(event: any) {
		if (this.isScrollerScrolling || this.isHolesRowScrolling) { return; }

		if (!this.isDataRowScrolling && this.activeScrollEventSrcElement == null) { this.activeScrollEventSrcElement = event.target; }
		this.isDataRowScrolling = true;

		if (event.target !== this.activeScrollEventSrcElement) { return; }

		const scrollLeft = event.target.scrollLeft;
		this.holesRowNatEl.scrollLeft = scrollLeft;
		this.scrollerNatEl.scrollLeft = scrollLeft;

		this.dataRows.forEach(elRef => {
			if (event.target === elRef.nativeElement) { return; }
			elRef.nativeElement.scrollLeft = scrollLeft;
		});

		clearTimeout(this.dataRowScrollingTimerRef);
		this.dataRowScrollingTimerRef = setTimeout(() => {
			this.isDataRowScrolling = false;
			this.activeScrollEventSrcElement = null;
		}, 500);
	}

	onUpdateScroll() {
		if (this.isDataRowScrolling || this.isHolesRowScrolling) { return; }

		this.isScrollerScrolling = true;

		this.holesRowNatEl.scrollLeft = this.scrollerNatEl.scrollLeft;
		this.dataRows.forEach(elRef => { elRef.nativeElement.scrollLeft = this.scrollerNatEl.scrollLeft; });

		clearTimeout(this.scrollerScrollingTimerRef);
		this.scrollerScrollingTimerRef = setTimeout(() => {
			this.isScrollerScrolling = false;
		}, 500);
	}

}

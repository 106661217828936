import { AfterContentInit, Component, ContentChildren, HostBinding, OnInit, QueryList } from '@angular/core';
import { CarouselItemComponent } from './carousel-item/carousel-item.component';
import { CarouselItemTransition } from './models/carousel-item-transition';
import { CarouselTransitionDirection } from './enums/carousel.enums';

@Component({
	selector: 'rb-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, AfterContentInit {
	@HostBinding('class') class = 'rb-carousel';

	@ContentChildren(CarouselItemComponent) carouselItemsQl: QueryList<CarouselItemComponent>;

	selectedIndex = 0;
	previouslySelectedIndex = 0;
	pageCount = 0;
	carouselItems: CarouselItemComponent[];

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor() { }

	ngOnInit() {
	}

	ngAfterContentInit() {
		if (this.carouselItemsQl != null) {
			this.pageCount = this.carouselItemsQl.length;
			this.carouselItems = this.carouselItemsQl.toArray();
			this.showSelectedCarouselItem();
		}
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onIndicatorSelected(itemIndex: number) {
		if ((itemIndex <= this.selectedIndex && this.selectedIndex < 1) || (itemIndex >= this.selectedIndex && this.selectedIndex >= this.pageCount - 1)) {
			return;
		}

		this.selectedIndex = itemIndex;
		this.hideSelectedCarouselItem();
		this.showSelectedCarouselItem();
	}

	onPrevious() {
		if (this.selectedIndex < 1) { return; }

		this.selectedIndex--;
		this.hideSelectedCarouselItem();
		this.showSelectedCarouselItem();
	}

	onNext() {
		if (this.selectedIndex >= this.pageCount - 1) { return; }

		this.selectedIndex++;
		this.hideSelectedCarouselItem();
		this.showSelectedCarouselItem();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private hideSelectedCarouselItem() {
		const x = new CarouselItemTransition(false, this.getTransitionDirection());
		this.carouselItems[this.previouslySelectedIndex].transitionInfo = x;
	}

	private showSelectedCarouselItem() {
		const y = new CarouselItemTransition(true, this.getTransitionDirection());
		this.carouselItems[this.selectedIndex].transitionInfo = y;
		this.previouslySelectedIndex = this.selectedIndex;
	}

	private getTransitionDirection() {
		if ( this.previouslySelectedIndex === this.selectedIndex) { return CarouselTransitionDirection.None; }
		return this.previouslySelectedIndex < this.selectedIndex ? CarouselTransitionDirection.Left : CarouselTransitionDirection.Right;
	}

}

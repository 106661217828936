/* eslint-disable @typescript-eslint/member-ordering */
import { GolfProgrammableSensor } from './golf-programmable-sensor.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

import GolfWeatherSensorModel = RbEnums.Common.GolfWeatherSensorModel;
import SensorType = RbEnums.Common.SensorType;

export class GolfWeatherSensor extends GolfProgrammableSensor {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(sensorType: RbEnums.Common.SensorType, json = null) {
		super(sensorType, json);

		this.golfWeatherSensorModel = json ? json.golfWeatherSensorModel : GolfWeatherSensorModel.RainCan;
	}

	// =========================================================================================================================================================
	// Public Members
	// =========================================================================================================================================================

	golfWeatherSensorModel: GolfWeatherSensorModel;

	updateFromPatch(patch: {}) {
		Object.keys(patch).forEach(name => {
			switch (name) {
				case 'addressInt':
					this[name] = +patch[name];
					return;
				case 'type':
					this.golfWeatherSensorModel = +patch[name];
					this.type = GolfWeatherSensor.getSensorTypeForWeatherSensorModal(this.golfWeatherSensorModel);
					return;
			}

			this[name] = patch[name];
		});
	}

	toPlainObj() {
		return {
			name: this.name,
			type: this.type,
			isIrrigationEngineBased: this.isIrrigationEngineBased,
			satelliteId: this.satelliteId,
			groupNumber: this.groupNumber,
			addressInt: this.addressInt,
			notes: this.notes,
			golfWeatherSensorModel: this.golfWeatherSensorModel,
			pulseValue: this.pulseValue,
			channel: this.channel,
			subChannel: this.subChannel,
			secondsPerUpdate: this.secondsPerUpdate,
		};
	}

	get programmableSensorType(): SensorType {
		return GolfWeatherSensor.getSensorTypeForWeatherSensorModal(this.golfWeatherSensorModel);
	}

	static getSensorTypeForWeatherSensorModal(golfWeatherSensorModel: GolfWeatherSensorModel): SensorType {
		switch (golfWeatherSensorModel) {
			case GolfWeatherSensorModel.RainSwitch:
				return SensorType.SwitchOpenClose;
			case GolfWeatherSensorModel.TemperatureSwitch:
				return SensorType.OnOff;
			case GolfWeatherSensorModel.Hygrometer:
			case GolfWeatherSensorModel.BarometricPressure:
			case GolfWeatherSensorModel.Temperature:
			case GolfWeatherSensorModel.WindSpeed:
				return SensorType.Voltage;
			case GolfWeatherSensorModel.RainCan:
				return SensorType.PulseAccumulative;		// TODO: What is the correct type?
		}

		return null;
	}

}

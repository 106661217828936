import { WorldWeatherWeatherDesc } from './world-weather-weather-desc.model';
import { WorldWeatherWeatherIconUrl } from './world-weather-weather-icon-url.model';

export class WorldWeatherHourly {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.weatherIconUrl) this.weatherIconUrl = json.weatherIconUrl.map(url => new WorldWeatherWeatherIconUrl(url));
			if (json.weatherDesc) this.weatherDesc = json.weatherDesc.map(desc => new WorldWeatherWeatherDesc(desc));
			if (json.chanceofrain) this.chanceofrain = json.chanceofrain;
		}
	}

	time: string;
	tempC: string;
	tempF: string;
	windspeedMiles: string;
	windspeedKmph: string;
	winddirDegree: string;
	winddir16Point: string;
	weatherCode: string;
	weatherIconUrl: WorldWeatherWeatherIconUrl[];
	weatherDesc: WorldWeatherWeatherDesc[];
	precipMM: string;
	precipInches: string;
	humidity: string;
	visiblity: string;
	visibilityMiles: string;
	pressure: string;
	pressureInches: string;
	cloudcover: string;
	HeatIndexC: string;
	HeatIndexF: string;
	DewPointC: string;
	DewPointF: string;
	WindChillC: string;
	WindChillF: string;
	WindGustMiles: string;
	WindGustKmph: string;
	FeelsLikeC: string;
	FeelsLikeF: string;
	chanceofrain: string;
	chanceofremdry: string;
	chanceofwindy: string;
	chanceofovercast: string;
	chanceofsunshine: string;
	chanceoffrost: string;
	chanceofhightemp: string;
	chanceoffog: string;
	chanceofsnow: string;
	chanceofthunder: string;
	uvindex: string;

}

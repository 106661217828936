import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { CollectionChange } from '../../../../common/models/collection-change.model';
import { ControllerGetLogsState } from '../../../../api/signalR/controller-get-logs-state.model';
import { ControllerListItem } from '../../../../api/controllers/models/controller-list-item.model';
import { ControllerManagerService } from '../../../../api/controllers/controller-manager.service';
import { ControllerSyncState } from '../../../../api/signalR/controller-sync-state.model';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { DynamicToolbarBase } from '../dynamic-toolbar-base';
import { filter } from 'rxjs/internal/operators/filter';
import { ManualControlManagerService } from '../../../../api/manual-control/manual-control-manager.service';
import { ManualControlState } from '../../../../api/manual-control/models/manual-control-state.model';
import { RbConstants } from '../../../../common/constants/_rb.constants';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../common/utils/_rb.utils';
import { take } from 'rxjs/operators';

import ManualControlIcon = RbEnums.Common.ManualControlIcon;
import ToolbarButton = RbEnums.Common.ToolbarButton;

@UntilDestroy()
@Component({
	selector: 'rb-detail-panel-toolbar',
	templateUrl: './detail-panel-toolbar.component.html',
	styleUrls: ['./detail-panel-toolbar.component.scss'],
})
export class DetailPanelToolbarComponent extends DynamicToolbarBase implements OnInit {
	@Input() isGridToolbar = false;								// true if toolbar is associated with a grid.
	@Input() selectedControllers: ControllerListItem[];			// Only used if isGridToolbar === true
	@Input() exportMenu = null;

	@Output() editClick = new EventEmitter<number>();
	@Output() connectClick = new EventEmitter<{controllerId: number, isConnected: boolean}>();
	@Output() refreshClick = new EventEmitter<number>();
	@Output() retrieveClick = new EventEmitter<number>();
	@Output() syncClick = new EventEmitter<number>();
	@Output() reverseClick = new EventEmitter<number>();
	@Output() playClick = new EventEmitter<number>();

	controllerId: number;
	controllerListItem: ControllerListItem;
	controlIcon = 'panorama_fish_eye';
	isConnected = false;
	localConnectingController = null;
	connectQaId = RbConstants.Ui.CONNECT_QAID;
	syncIcon = RbConstants.Ui.ICON_SYNC;
	syncProblemIcon = RbConstants.Ui.ICON_SYNC_PROBLEM;

	private _isConnecting = false;
	private _isDisconnecting = false;

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(protected authManager: AuthManagerService,
				private broadcastService: BroadcastService,
				protected controllerManager: ControllerManagerService,
				protected deviceManager: DeviceManagerService,
				private manualControlManager: ManualControlManagerService,
				protected route: ActivatedRoute,
	) {
		super(authManager, route, controllerManager,  deviceManager, true);
	}

	ngOnInit() {
		super.ngOnInit();

		this.controllerId = +this.route.snapshot.queryParams.controllerId;
		if (this.controllerId) {
			this.reactiveConnecting();
		}
		if (!this.isGridToolbar) {
			this.setupSubscriptionsForSingleController();
		}
	}

	// =========================================================================================================================================================
	// public Methods
	// =========================================================================================================================================================

	get showToolbar(): boolean {
		return this.includeEdit || this.includeConnect || this.includeRetrieve || this.includeSync || this.includeReverse
				|| this.includeRefresh || this.includeUnlockSystem;
	}

	// =========================================================================================================================================================
	// Required base class overrides
	// =========================================================================================================================================================

	get toolbarButtons(): ToolbarButton[] {
		return [ToolbarButton.Edit, ToolbarButton.Connect, ToolbarButton.RetrieveLogs, ToolbarButton.Sync, ToolbarButton.ReverseSync, ToolbarButton.Refresh];
	}

	get desktopToolbarContainer() {
		return 'desktop-left-pane-toolbar';
	}

	get mobileToolbarContainer() {
		return 'mobile-left-pane-toolbar';
	}

	// =========================================================================================================================================================
	// Base Class Toolbar Click Handler Overrides
	// =========================================================================================================================================================

	handleEditClick() {
		this.editClick.emit(this.controllerId);
	}

	handleConnectClick() {
		if (!this.controllerId) { return; }
		if (this.isConnected) {
			this.setIsDisconnecting(true);
			this.controllerManager.setLocalConnectingController(this.controllerId, this.controllerListItem.parentId, false);
			this.reactiveConnecting();
		} else {
			this.setIsConnecting(true);
			this.controllerManager.setLocalConnectingController(this.controllerId, this.controllerListItem.parentId);
			this.reactiveConnecting();
		}
		this.connectClick.emit({ controllerId: this.controllerId, isConnected: this.isConnected });
	}

	handleRetrieveClick() {
		this.retrieveClick.emit(this.controllerId);
	}

	handleSyncClick() {
		this.syncClick.emit(this.controllerId);
	}

	handleReverseSyncClick() {
		this.reverseClick.emit(this.controllerId);
	}

	handleRefreshClick() {
		this.refreshClick.emit();
	}

	// =========================================================================================================================================================
	// Base Class Overrides for Enabling/disabling toolbar buttons
	// =========================================================================================================================================================

	isConnectDisabled(): boolean {
		if (!this.isConnected && this.controlIcon === RbEnums.Common.ManualControlIcon.Connecting) {
			return true;
		}

		return this.controllerListItem && this.controllerListItem.firmwareUpdateProgress !== null;
	}

	isRetrieveDisabled(): boolean {
		return this.controllerListItem && (this.controllerListItem.gettingLogs || this.controllerListItem.firmwareUpdateProgress !== null);
	}

	isSyncDisabled(): boolean {
		if (!this.isGridToolbar) {
			return !!this.controllerListItem?.firmwareUpdateProgress || this.isReverseSyncing();
		} else {
			// RB-6291: Don't test if(array); you must check for null and undefined.
			return this.selectedControllers == null || this.selectedControllers.length < 1;
		}
	}

	isReverseSyncDisabled(): boolean { 
		if (!this.isGridToolbar) {
			return !!this.controllerListItem?.firmwareUpdateProgress || this.isSyncing();
		}
		return this.isSyncDisabled();
	}

	isRefreshDisabled(): boolean {
		// RB-6291: Don't test if(array); you must check for null and undefined.
		return !this.isGridToolbar || this.selectedControllers == null || this.selectedControllers.length < 1;
	}

	// =========================================================================================================================================================
	// Base Class Overrides for controlling toolbar animations
	// =========================================================================================================================================================

	get isConnecting(): boolean {
		return this._isConnecting;
	}

	get isDisconnecting(): boolean {
		return this._isDisconnecting;
	}

	isGettingLogs(): boolean {
		return this.controllerListItem && this.controllerListItem.gettingLogs;
	}

	isSyncing(): boolean {
		return this.controllerListItem && this.controllerListItem.syncState === this.SyncState.Syncing;
	}

	isReverseSyncing(): boolean {
		return this.controllerListItem && this.controllerListItem.syncState === this.SyncState.ReverseSyncing;
	}

	// =========================================================================================================================================================
	// Methods for dynamically customizing toolbar buttons
	// =========================================================================================================================================================

	get isPerformingSyncOp(): boolean {
		return this.controllerListItem.syncState === this.SyncState.Syncing || this.controllerListItem.syncState === this.SyncState.ReverseSyncing;
	}

	getSyncIconColor(): string {
		if (!this.isGridToolbar && this.controllerListItem) {
			if ((this.getSyncStateIcon() || this.controllerListItem.syncState === this.SyncState.Incomplete) && !this.isPerformingSyncOp) {
				return this.IndicatorColor.Incomplete;
			}

			return this.controllerListItem.syncState === this.SyncState.Synchronized && !this.isPerformingSyncOp
				? this.IndicatorColor.Synchronized : this.IndicatorColor.OutOfSync;
		}

		return 'white';
	}

	getReverseSyncIconColor(): string {
		if (!this.isGridToolbar && this.controllerListItem) {
			return !this.controllerListItem.hasLastSyncDifferences && !this.isPerformingSyncOp
				? this.IndicatorColor.Synchronized : this.IndicatorColor.OutOfSync;
		}

		return 'white';
	}

	getSyncStateIcon(): boolean {
		if (!this.isGridToolbar && this.controllerListItem) {
			return (this.controllerListItem.frontPanelState === RbEnums.Common.FrontPanelState.Incomplete);
		}
	}

	getConnectIcon(): string {
		return this.controlIcon;
	}

	getConnectLabel(): string {
		if (this.isMobile) { return 'STRINGS.CONTROL'; }

		if (this.isConnecting) {
			return 'STRINGS.CONNECTING';
		}

		if (this.isDisconnecting) {
			return 'STRINGS.DISCONNECTING';
		}

		return this.isConnected ? 'STRINGS.DISCONNECT' : 'STRINGS.CONNECT';
	}

	getConnectNgClass(): {} {
		return { 'connected': this.isConnected };
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setupSubscriptionsForSingleController() {

		// Get the current ControllerListItem from cache or api.
		this.controllerManager.getControllerListItem(this.controllerId)
			.pipe(take(1))
			.subscribe((controllerListItem: ControllerListItem) =>
				this.controllerListItem = controllerListItem
			);

		// Monitor changes to the ManualControlState for the given controller.
		this.manualControlManager.manualControlStateChange
			.pipe(
				untilDestroyed(this),
				filter((mcs: ManualControlState) => mcs && mcs.controllerId === this.controllerId)
			)
			.subscribe((manualControlState: ManualControlState) => {
				this.updateManualControlStateDisplay(manualControlState);
			});

		// Monitor Manual Control Connecting Status
		this.manualControlManager.isConnecting
			.pipe(
				untilDestroyed(this),
				filter((satelliteId: number) => satelliteId === this.controllerId)
			)
			.subscribe(() => {
				this.controlIcon = RbEnums.Common.ManualControlIcon.Connecting;
				this.setIsConnecting(true);
				this.reactiveConnecting();
			});

		// Monitor Controller Connection Stopped event
		this.broadcastService.connectionStopped
			.pipe(
				untilDestroyed(this),
				filter((satelliteId: number) => satelliteId === this.controllerId)
			)
			.subscribe((satelliteId: number) => {
				this.updateManualControlStateDisplay();
				this.setConnectionState(ManualControlIcon.NotConnected);
				this.removeConnectingControllers(satelliteId);
			});

		// Subscribe to any Sync State changes for this.controllerId
		this.broadcastService.syncStateChange
			.pipe(
				untilDestroyed(this),
				filter(ss => ss.controllerId === this.controllerId)
			)
			.subscribe((syncState: ControllerSyncState) => {
				if (!this.controllerListItem) return;
				// Update the isSynchronized flag on our in memory controller object. This will properly set the sync state icon.
				// We could alternatively fetch a new controllerListItem, but I felt this was more efficient.
				this.controllerListItem.frontPanelState = RbUtils.Controllers.getFrontPanelStateFromSyncState(syncState.syncState);

				if (!this.isGolfSite) {
					this.controllerListItem.syncState = syncState.syncState;
					switch (syncState.syncState) {
						case RbEnums.Common.ControllerSyncState.Syncing:
							this.toolbarInfo?.groups.forEach(g => {
								const syncBtnIndex = g.buttons.findIndex(b => b.icon === this.syncProblemIcon);
								g.buttons[syncBtnIndex] = this.buildSyncButton(false);
							});
							break;
						case RbEnums.Common.ControllerSyncState.SyncFailed:
							if (this.controllerListItem.frontPanelState === RbEnums.Common.FrontPanelState.Incomplete) {
								this.toolbarInfo?.groups.forEach(g => {
									const syncBtnIndex = g.buttons.findIndex(b => b.icon === this.syncIcon);
									g.buttons[syncBtnIndex] = this.buildSyncButton(true);
								});
							}
							break;
						case RbEnums.Common.ControllerSyncState.Synchronized:
							this.controllerListItem.hasLastSyncDifferences = false;
							this.toolbarInfo?.groups.forEach(g => {
								const syncBtnIndex = g.buttons.findIndex(b => b.icon === this.syncProblemIcon);
								g.buttons[syncBtnIndex] = this.buildSyncButton(false);
							});
							break;
						default:
							if (this.controllerListItem.frontPanelState === RbEnums.Common.FrontPanelState.Incomplete) {
								this.toolbarInfo?.groups.forEach(g => {
									const syncBtnIndex = g.buttons.findIndex(b => b.icon === this.syncIcon);
									g.buttons[syncBtnIndex] = this.buildSyncButton(true);
								});
							}
							break;
					}
				} else {
					this.controllerListItem.syncState =
						(syncState.syncState === RbEnums.Common.ControllerSyncState.SyncFailed)
							? RbEnums.Common.ControllerSyncState.NotSynchronized
							: syncState.syncState;
				}

				this.controllerListItem.queued = syncState.isQueued;
			});

		// Monitor controller collection changes to properly update the sync state icon. This is necessary to identify UI changes to the controller object.
		// The signalR event that triggers a syncStateChange cannot be used for this because it does not properly set the syncState for Controller specific
		// changes (e.g., No. Simulstations).
		// NOTE: If we are able to patch up the syncStateChange signalR event to 'properly' set the syncState flag, we will not need this block of code.
		this.broadcastService.controllerCollectionChange
			.pipe(untilDestroyed(this))
			.subscribe((collectionChange: CollectionChange) => {
				const controllers = <ControllerListItem[]>collectionChange.collection;
				this.controllerListItem = controllers.find(c => c.id === this.controllerId);
				if (this.controllerListItem?.frontPanelState === RbEnums.Common.FrontPanelState.Incomplete) {
					this.toolbarInfo?.groups.forEach(g => {
						const syncBtnIndex = g.buttons.findIndex(b => b.icon === this.syncIcon);
						g.buttons[syncBtnIndex] = this.buildSyncButton(true);
					});
				} else {
					this.toolbarInfo?.groups.forEach(g => {
						const syncBtnIndex = g.buttons.findIndex(b => b.icon === this.syncProblemIcon);
						g.buttons[syncBtnIndex] = this.buildSyncButton(false);
					});
				}
			});

		this.controllerManager.controllerListItemsCacheUpdated
			.pipe(untilDestroyed(this))
			.subscribe((controllersList: ControllerListItem[]) => {
				const latestControllerListItem = controllersList.find(c => c.id === this.controllerId);
				if (!!latestControllerListItem) { this.controllerListItem = latestControllerListItem; }

				if (this.controllerListItem?.frontPanelState === RbEnums.Common.FrontPanelState.Incomplete) {
					this.toolbarInfo?.groups.forEach(g => {
						const syncBtnIndex = g.buttons.findIndex(b => b.icon === this.syncIcon);
						g.buttons[syncBtnIndex] = this.buildSyncButton(true);
					});
				}
			});

		this.broadcastService.eventLogsStateChange
			.pipe(
				untilDestroyed(this),
				filter(ss => ss.controllerId === this.controllerId)
			)
			.subscribe((eventLogsState: ControllerGetLogsState) => {
				if (this.controllerListItem && eventLogsState)
					this.controllerListItem.gettingLogs = eventLogsState.gettingLogs;
			});

		/** ====================================================================================================================================================
		 * Get last ManualControlState if it exists and is not stale. Allows for immediate loading of component if we are already connected.
		 *
		 * NOTE: If the user has refreshed the browser while this component is loaded, we need to delay the call to getManualControlState() to ensure the
		 * system has had ample time to load the current connection state. W/o the delay, this call often occurs before the system has had enough time to load
		 * the current connection state. In this scenario, the connection state will not be accurately displayed until the next 'connection state' SignalR
		 * callback occurs (which can be upwards of 1 minute). This issue only occurs during a page refresh. During normal navigation, the state is always up to
		 * date and available. Through testing, a one second delay has proven to provide enough time for the current connection state to be accurately loaded.
		 * ================================================================================================================================================== */
		if (!this.deviceManager.browserRefreshed) {
			this.updateManualControlStateDisplay(this.manualControlManager.getManualControlState(this.controllerId));
		} else {
			setTimeout(() => this.updateManualControlStateDisplay(this.manualControlManager.getManualControlState(this.controllerId)), 1000);
		}
	}

	private updateManualControlStateDisplay(manualControlState?: ManualControlState, isConnected?: boolean) {
		if (!isConnected) {
			// RB-9646: We may not yet have a manualControlState for the given controller or the manualControlState may be null. In this case,
			// we will fallback to checking the isConnected state of the associated controllerListItem. From time to time, we may hit this method (on init)
			// before the controllerListItem retrieval has returned. In that case, we will recursively call this method until we have a controllerListItem.
			if (!manualControlState && !this.controllerListItem) {
				setTimeout(() => this.updateManualControlStateDisplay(manualControlState, isConnected), 100);
				return;
			}

			if (!manualControlState && this.controllerListItem?.isConnected) {
				this.setConnectionState(ManualControlIcon.Connected);
				this.setIsConnecting(false);
				// If the ManualControlManager ManualControlDict is empty, initiate a data packs retrieval to pick up any existing connection status.
				if (!this.isGolfSite && this.manualControlManager.isManualControlDictEmpty) {
					this.controllerManager.getDatapacks(this.controllerId).subscribe();
				}

				return;
			}

			if (!this.isGolfSite && !manualControlState && this.controllerListItem) {
				if (this.controllerListItem.syncState === RbEnums.Common.ControllerSyncState.ReverseSyncing ||
					this.controllerListItem.syncState === RbEnums.Common.ControllerSyncState.Syncing) {
					this.setIsConnecting(true);
					this.setConnectionState(ManualControlIcon.Connecting);
					return;
				}
			}
			if (!this.isGolfSite && manualControlState?.isConnecting.error) {
				this.setIsConnecting(false);
				this.removeConnectingControllers(this.controllerId);
			}

			if (!manualControlState || (manualControlState.isConnecting.error && !manualControlState.isConnecting.isUpdating)) {
				this.setConnectionState(ManualControlIcon.NotConnected);
				this.setIsConnecting(false);
				return;
			}

			if (!this.isConnected && (manualControlState.isConnecting.isUpdating || !manualControlState.hasReceivedConnectDataPack)) {
				this.setIsConnecting(true);
				this.setConnectionState(ManualControlIcon.Connecting);
				return;
			}
		}

		if (isConnected || this.controllerListItem?.isConnected) {
			this.setConnectionState(ManualControlIcon.Connected);
			this.setIsConnecting(false);
			this.removeConnectingControllers(this.controllerId);
		}
	}

	private setConnectionState(icon: ManualControlIcon) {
		this.isConnected = icon === ManualControlIcon.Connected;
		this.controlIcon = icon;
	}

	private setIsConnecting(value: boolean) {
		if (value) {
			this.controlIcon = ManualControlIcon.Connecting;
		}
		this._isConnecting = value;
	}

	private setIsDisconnecting(value: boolean) {
		if (value) {
			this.controlIcon = ManualControlIcon.Disconnecting;
		}
		this._isDisconnecting = value;
	}

	private reactiveConnecting() {
		this.localConnectingController = this.controllerManager.getLocalConnectingController(this.controllerId);
		if (this.localConnectingController?.isConnecting || this.isConnecting) {
			this.setIsConnecting(true);
			this.controlIcon = ManualControlIcon.Connecting;

			this.toolbarInfo?.groups.forEach(g => {
				const syncBtnIndex = g.buttons.findIndex(b => b.qaId === this.connectQaId);
				if (!!g.buttons[syncBtnIndex]) {
					g.buttons[syncBtnIndex] = this.buildConnectButton(true);
				}
			});
			return;
		}
		if (this.localConnectingController?.isConnecting === false || this.isDisconnecting) {
			this.setIsDisconnecting(true);

			this.toolbarInfo?.groups.forEach(g => {
				const syncBtnIndex = g.buttons.findIndex(b => b.qaId === this.connectQaId);
				if (!!g.buttons[syncBtnIndex]) {
					g.buttons[syncBtnIndex] = this.buildConnectButton(false);
				}
			});
			return;
		}

	}

	private removeConnectingControllers(satelliteId: number) {
		this.controllerManager.removeLocalConnectingController(satelliteId);
		this.setIsConnecting(false);
		this.setIsDisconnecting(false);
		this.reactiveConnecting();
	}
}


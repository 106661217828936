<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="hole" class="holes-mobile-cell">

	<div class="name">
		<i *ngIf="hole.isGolfArea" class="icon-flag title-icon"></i>
		<i *ngIf="!hole.isGolfArea" class="icon-map-pin title-icon"></i>
		<span class="ellipsis-text">{{hole.name}}</span>
	</div>

	<div class="number">
		<span class="label">{{'STRINGS.NUMBER' | translate}}:</span>
		<span class="value">{{hole.number}}</span>
	</div>
</div>

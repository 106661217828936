import { ApiService, HttpMethod } from '../_common/api.service';

import { GeoGroup } from './models/geo-group.model';
import { GeoItem } from './models/geo-item.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GeoGroupApiService extends ApiService {

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getGeoGroup(id: number): Observable<GeoGroup> {
		return this.apiRequest<any>(this.getGeoGroupUrl(id), HttpMethod.Get)
			.pipe(map(response => new GeoGroup(response)));
	}

	getGeoGroupForStation(siteId: number, stationId: number, bypassCache = false): Observable<GeoGroup> {
		return this.apiRequest<any>(this.getGeoGroupForStationUrl(siteId, stationId), HttpMethod.Get, bypassCache)
		.pipe(map(response => new GeoGroup(response)));
	}

	getGeoGroupsForSite(siteId: number): Observable<GeoGroup[]> {
		return this.apiRequest<any>(this.getGeoGroupsForSiteUrl(siteId), HttpMethod.Get)
			.pipe(map(list => list.map(item => new GeoGroup(item))));
	}

	createGeoItem(siteId: number, areaLevel2Id: number, areaLevel3Id: number, geoItemData: any): Observable<GeoItem> {
		return this.apiRequest<any>(this.createGeoItemUrl(siteId, areaLevel2Id, areaLevel3Id), HttpMethod.Post, geoItemData);
	}

	createGeoItemForStation(siteId: number, stationId: number, geoItemData: any) {
		return this.apiRequest<any>(this.createGeoItemForStationUrl(siteId, stationId), HttpMethod.Post, geoItemData);
	}

	updateGeoItem(geoItemData: any): Observable<GeoItem> {
		return this.apiRequest<any>(this.updateGeoItemUrl, HttpMethod.Put, geoItemData);
	}

	updateStationGeoGroupSettings(siteId: number, stationId: number, settings: string): Observable<GeoItem> {
		return this.apiRequest<any>(this.updateStationGeoGroupSettingsUrl(siteId, stationId), HttpMethod.Put, settings);
	}

	deleteGeoItem(id: number): Observable<null> {
		return this.apiRequest<any>(this.deleteGeoItemUrl(id), HttpMethod.Delete);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}GeoGroup`; }

	private get geoItemBaseUrl(): string { return `${this.baseApiUrl}GeoItem`; }

	private getGeoGroupUrl(id: number): string { return `${this.baseUrl}/GetGeoGroup?geoGroupId=${id}`; }

	private getGeoGroupForStationUrl(siteId: number, stationId: number): string {
		return `${this.baseUrl}/GetGeoGroupForStations?siteId=${siteId}&stationId=${stationId}`; }

	private getGeoGroupsForSiteUrl(siteId: number): string { return `${this.baseUrl}/GetGeoGroupsForSite?siteId=${siteId}`; }

	private createGeoItemUrl(siteId: number, areaLevel2Id: number, areaLevel3Id: number): string {
		let url = `${this.geoItemBaseUrl}/CreateGeoItem?siteId=${siteId}&areaLevel2Id=${areaLevel2Id}`;
		if (areaLevel3Id != null) url += `&areaLevel3Id=${areaLevel3Id}`;
		return url;
	}

	private createGeoItemForStationUrl(siteId: number, stationId: number): string {
		let url = `${this.geoItemBaseUrl}/CreateGeoItemForStation?siteId=${siteId}&stationId=${stationId}`;
		return url;
	}

	private get updateGeoItemUrl(): string { return `${this.geoItemBaseUrl}/UpdateGeoItem`; }

	private updateStationGeoGroupSettingsUrl(siteId: number, stationId: number): string {
		return `${this.baseUrl}/UpdateStationGeoGroupSettings?siteId=${siteId}&stationId=${stationId}`
	};

	private deleteGeoItemUrl(id: number): string { return `${this.geoItemBaseUrl}/DeleteGeoItem?id=${id}`; }
}

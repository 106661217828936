import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LatLng } from 'leaflet';

@Component({
  selector: 'rb-nearby-station-select',
  templateUrl: './nearby-station-select.component.html',
  styleUrls: ['./nearby-station-select.component.scss']
})
export class NearbyStationSelectComponent {

  @Input() latLng: LatLng;
  @Output() radiusChange = new EventEmitter<number>();
	@Output() close = new EventEmitter<number>();

  maxRadius = 200;
  step = 10;
  radius = 50;
	
  onRadiusChange(value: number) {
    this.radius = value;
    this.radiusChange.emit(value);
  }

	onClose() {
		this.close.emit();
	}

	onAccept() {
		this.close.emit(this.radius);
	}

}
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Area } from '../../../api/areas/models/area.model';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-front-back-dropdown',
	templateUrl: './front-back-dropdown.component.html',
	styleUrls: ['./front-back-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class FrontBackDropdownComponent implements OnChanges {
	@ViewChild('matSelect', { static: true }) matSelect: MatSelect;

	@Input() dataArray: Array<Area>;
	@Input() extraDataArray: Array<Area>;

	@Input() isAreas = false;
	@Input() label: string;
	@Input() routeType = '';
	@Input() showSelectionCount = false;

	/**
	 * Flag indicating that the dropdown will be displayed on a gray background. When flagged, background will be #666, text will
	 * be left-aligned, font weight will be lighter, and the dropdown arrow color will be #888.
	 */
	 @Input() flat = false;

	 /**
	 * Flag indicating that the dropdown will be drawn on top of a white background. When flagged, text color will be #000 (black),
	 * dropdown arrow color will be #666, and the dropdown underline color will be #888.
	 */
	@Input() isWhiteBackground = false;

	/**
	 * Flag indicating that the dropdown should be displayed in a relatively compressed layout (mobile, for example). When flagged,
	 * the layout is designed to be vertically displayed (single column of full-width material design form fields).
	 */
	@Input() isCompressed = false;

	/**
	 * Flag indicating that the drop-down label text will be left-justified. When not flagged, the label is centered.
	 */
	@Input() isLeftJustified = false;

	@Output() itemsSelected = new EventEmitter<Area[]>();

	frontNine: Area[] = [];
	isAddingFrontNine = true;
	isAddingPlaces = true;
	isAddingBackNine = true;
	selectedItems: Array<Area> = [];
	wrapButtons: boolean = false;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private translate: TranslateService) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.dataArray != null && this.dataArray != null) {
			this.frontNine = this.dataArray.filter(area => area.number < 10);
		}
		//When language is Portuguese or French, text will wrap on header buttons
		if (this.translate.currentLang === 'pt' || this.translate.currentLang === 'fr') {
			this.wrapButtons = true;
		}
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	handleChange(selectedItems: any[]) {
		this.selectedItems = selectedItems;
	}

	onDoneClick() {
		// NOTE: Handled in onSelectClosed()
		// this.holesSelected.emit(this.selectedItems);
	}

	onSelectClosed() {
		this.itemsSelected.emit(this.selectedItems);
	}

	equals(objOne, objTwo) {
		if (objOne && objTwo) {
			return objOne.id === objTwo.id;
		}
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	deselectAll() {
		this.selectedItems = [];
		this.isAddingFrontNine = true;
		this.isAddingBackNine = true;
		this.isAddingPlaces = true;
	}

	frontNineClicked(values) {
		const objects = this.selectedItems.slice();

		values.forEach(element => {
			if (element.number > 9) return;
			this.addOrRemoveFromSelectedItems(this.isAddingFrontNine, objects, element);
		});

		this.isAddingFrontNine = !this.isAddingFrontNine;
		this.selectedItems = objects;
	}

	backNineClicked(values) {
		const objects = this.selectedItems.slice();

		values.forEach(element => {
			if (element.number < 10 || element.number > 18) return;
			this.addOrRemoveFromSelectedItems(this.isAddingBackNine, objects, element);
		});

		this.isAddingBackNine = !this.isAddingBackNine;
		this.selectedItems = objects;
	}

	placesClicked(values) {
		const objects = this.selectedItems.slice();

		values.forEach(element => {
			this.addOrRemoveFromSelectedItems(this.isAddingPlaces, objects, element);
		});

		this.isAddingPlaces = !this.isAddingPlaces;
		this.selectedItems = objects;
	}

	invokeRemotely() {
		(<HTMLElement>this.matSelect._elementRef.nativeElement).click();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private addOrRemoveFromSelectedItems(isAdding: boolean, selectedList: any[], element: any) {
		if (isAdding) {
			if (selectedList.indexOf(element) >= 0) return;
			selectedList.push(element);
		} else {
			const index = selectedList.indexOf(element);
			if (index < 0) return;
			selectedList.splice(index, 1);
		}
	}

	/**
	 * Given the indicated Area (which could be a Hole, of course), and the current configuration of the component, return the
	 * correct display string for this Area. For holes, this is just the name. For areas, it should include the short name for
	 * the area, suitably formatted in an localizable way.
	 * @param area - Area to be formatted for string display
	 * @returns string containg the text to be displayed for the area
	 */
	public formatAreaName(area: Area): string {
		// This should result in the old behavior when there is no short name: the name only is displayed.
		if (area.level == 3 && area.shortName != null) {
			return this.translate.instant('STRINGS.AREA_FILTER_NAME_FORMAT', { name: area.name, shortName: area.shortName });
		}
		return area.name;
	}

}

import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { ControllerWithMapInfoLeaflet } from '../../../common/models/controller-with-map-info-leaflet.model';
import { ManualOpsManagerService } from '../../../api/manual-ops/manual-ops-manager.service';
import { MapLeafletService } from '../../../common/services/map-leaflet.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rb-start-controller-auto-connect-modal',
  templateUrl: './start-controller-auto-connect-modal.component.html',
  styleUrls: ['./start-controller-auto-connect-modal.component.scss']
})
export class StartControllerAutoConnectModalComponent implements OnInit {

	@Input() selectedControllerWithMapInfoLeaflet: ControllerWithMapInfoLeaflet[];
	@Output() startClick = new EventEmitter();
	unconnectedControllerIds: number[] = [];
	connectionNotStartedControllerIds: number[] = [];
	connectControllersResult: SelectedControllersResult[] = [];
	loadError = false;

	constructor(
		private translateService: TranslateService,
		private manualOpsManagerService: ManualOpsManagerService,
		private broadcastService: BroadcastService,
		public mapService: MapLeafletService
		) {
		
	}

	ngOnInit() {
		this.connectControllersResult = this.selectedControllerWithMapInfoLeaflet.map(
			item => {
				let selectedControllersResult: SelectedControllersResult;
				selectedControllersResult = {
					id: item.id,
					name: item.name,
					isConnected: item.isConnected,
					statusMessage: this.translateService.instant('STRINGS.CONNECTION_PENDING')
				};
				return selectedControllersResult;
			}
		);
		const requestConnectControllerObservable: Observable<SelectedControllersResult>[] = [];
		this.unconnectedControllerIds = this.selectedControllerWithMapInfoLeaflet.filter(x => !x.isConnected).map(x => {
			requestConnectControllerObservable.push(this.connectController(x.id));
			return x.id;
		});

		this.connectionNotStartedControllerIds = this.unconnectedControllerIds;
		forkJoin(requestConnectControllerObservable).subscribe({
			next: () => {
				this.connectControllersResult.map((x => {
					x.statusMessage = this.translateService.instant('STRINGS.CONNECTING')
				}));
			},
			error: (error) => {
				this.loadError = error.error || error.message || this.translateService.instant('STRINGS.NETWORK_ERROR_RETRY');
			}
		})

		this.watchComponentChange();	
	}


	onSetDuration() {
		this.startClick.emit();
	}

	private connectController(controllerId): Observable<any> {
		return this.manualOpsManagerService.connect(controllerId);
	}

	private watchComponentChange() {
		this.broadcastService.connectionFailed.subscribe(
			satelliteId => {
				this.unconnectedControllerIds = this.unconnectedControllerIds.filter(x => x !== satelliteId);
				const controllerConnected = this.connectControllersResult.find(x => x.id === satelliteId);
				if (!!controllerConnected) {
					controllerConnected.isConnected = false;
					controllerConnected.statusMessage = this.translateService.instant('STRINGS.CONNECTION_FAILED');
				}
		});
		this.broadcastService.connectionStopped.subscribe(
			satelliteId => {
				this.unconnectedControllerIds = this.unconnectedControllerIds.filter(x => x !== satelliteId);
				const controllerConnected = this.connectControllersResult.find(x => x.id === satelliteId);
				if (!!controllerConnected) {
					controllerConnected.isConnected = false;
					controllerConnected.statusMessage = this.translateService.instant('STRINGS.CONNECTION_FAILED');
				}
		});
		this.broadcastService.connectionStarted.subscribe(
			satelliteId => {
				this.unconnectedControllerIds = this.unconnectedControllerIds.filter(x => x !== satelliteId);
				this.connectionNotStartedControllerIds = this.connectionNotStartedControllerIds.filter(x => x !== satelliteId);
				const controllerConnected = this.connectControllersResult.find(x => x.id === satelliteId);
				if (!!controllerConnected) {
					controllerConnected.isConnected = true;
					controllerConnected.statusMessage = this.translateService.instant('STRINGS.CONNECTED');
				}
		});
	}

	get isAbleToStart() {
		return this.unconnectedControllerIds?.length == 0 && this.connectionNotStartedControllerIds?.length == 0;
	}
}
interface SelectedControllersResult {
	id: number;
	name: string;
	isConnected: boolean;
	isConnectFailed?: boolean;
	statusMessage?: string;
}
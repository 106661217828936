<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="full-screen-dialog">
	<div class="header" >
		<div>{{ 'STRINGS.CONTROLLERS' | translate }}</div>
	</div>

	<div class="main-body">
		<mat-checkbox color="accent" class="select-all" [checked]="allSitesSelected" (change)="onSelectAll($event);">{{'STRINGS.SELECT_ALL_UPPERCASE' | translate}}</mat-checkbox>
		<div *ngFor="let site of treeItems" class="site">
			<mat-checkbox
				color="accent"
				[indeterminate]="isSomeControllerSelected(site) && !isSiteSelected(site)"
				[checked]="isSiteSelected(site)"
				(change)="onSelectSite(site, $event);"
			>
				{{site.item}}
			</mat-checkbox>
			<div *ngFor="let controller of site.children" class="controller">
				<mat-checkbox
					color="accent"
					[checked]="isControllerSelected(controller)"
					[disabled]="controller.isDisabled"
					(change)="onSelectController(controller, $event);"
				>
					{{controller.item}}
				</mat-checkbox>
			</div>
		</div>
	</div>

	<div class="button-area justify-end">
		<button mat-button (click)="onCancel()" data-qaid="dialog-cancel-button">{{'STRINGS.CANCEL' | translate }}</button>
		<button mat-button (click)="onSubmit()" color="primary" data-qaid="dialog-submit-button" [disabled]="currentlySelectedControllerIds.length === 0">{{'STRINGS.OK' | translate }}</button>
	</div>
</div>>

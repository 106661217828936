import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { GolfSensorListItem } from '../../../../api/sensors/models/golf-sensor-list-item.model';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { SensorManagerService } from '../../../../api/sensors/sensor-manager.service';
import { Site } from '../../../../api/sites/models/site.model';
import { TranslateService } from '@ngx-translate/core';
import { WeatherDataManagerService } from '../../../../api/weather-data/weather-data-manager.service';

@Component({
	selector: 'rb-golf-rain-can-filter',
	templateUrl: './golf-rain-can-filter.component.html',
	styleUrls: ['./golf-rain-can-filter.component.scss']
})
export class GolfRainCanFilterComponent implements OnInit {

	@HostBinding('class') class = 'rb-main-panel-toolbar rain-can-filter';

	@Input() selectedRainCanIds: number[] = [];
	@Output() valueChange = new EventEmitter<{ selectedRainCanIds: number[] }>();
	@Output() complete = new EventEmitter<number[]>();

	rainCans: GolfSensorListItem[] = [];
	selectedRainCans: GolfSensorListItem[] = [];
	busy = false;
	// course: Site;
	courses: Site[] = [];
	loadError: string;
	assignSelected = false;

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(public deviceManager: DeviceManagerService,
				private sensorManager: SensorManagerService,
				private weatherDataManager: WeatherDataManagerService,
				private translate: TranslateService) { }

	ngOnInit(): void {
		this.loadData();
	}

	// =========================================================================================================================================================
	// Data Loading
	// =========================================================================================================================================================

	private loadData() {
		this.busy = true;

		const sources: Observable<any>[] = [
			this.sensorManager.getGolfSensorList(true),
			this.weatherDataManager.getWeatherSensorData(52333, '2021-04-25T00:00', '2021-04-30T:23:59:59'),
		];
		forkJoin(sources).subscribe(([sensors, data]) => {
			this.rainCans = sensors.filter(s => s.kingdomId === RbEnums.Common.GolfSensorKingdom.Weather &&
				s.golfWeatherSensorModel === RbEnums.Common.GolfWeatherSensorModel.RainCan);
			this.busy = false;
		}, error => {
			this.loadError = error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
		});
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================
	onActionErrorAcknowledged() {
		this.loadError = null;
		this.busy = false;
	}

	onSelectedSensorsChanged(event) {
		this.valueChange.emit( { selectedRainCanIds: this.selectedRainCans.map(rc => rc.id) });
	}

	onCancel() {
		this.complete.emit(null);
	}

	onSubmit() {
		this.complete.emit(this.selectedRainCans.map(rc => rc.id));
	}

	rainCanSelectionComplete(rainCans: GolfSensorListItem[]) {
		console.log(rainCans);
	}
}

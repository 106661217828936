import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { RbConstants } from '../../../../../common/constants/_rb.constants';

@Component({
	selector: 'rb-nav-mobile-cell-renderer',
	templateUrl: './nav-mobile-cell-renderer.component.html',
	styleUrls: [ './nav-mobile-cell-renderer.component.scss' ]
})
export class NavMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	isNavData: boolean;
	navData: any;
	navigationName = '';

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);

		if (params.data.numberOfSatellites) {
			this.isNavData = true;
			this.navData = params.data.numberOfSatellites;
			this.navigationName = RbConstants.Form.MOBILE_NAVIGATION_NAME.controller;
			return;
		} else if (params.data.noOfPrograms || params.data.noOfPrograms === 0) {
			this.isNavData = true;
			this.navData = params.data.noOfPrograms;
			this.navigationName = (this.isGolfSite) ? RbConstants.Form.MOBILE_NAVIGATION_NAME.schedules : RbConstants.Form.MOBILE_NAVIGATION_NAME.programs;
		} else if (params.data.numberOfSubBranches || params.data.numberOfSubBranches === 0) {
			this.isNavData = true;
			this.navData = params.data.numberOfSubBranches;
			// this.navigationName = RbConstants.Form.MOBILE_NAVIGATION_NAME.subBranches;
		}
		if (params.data.stations) {
			this.isNavData = true;
			this.navData = params.data.stations;
			this.navigationName = RbConstants.Form.MOBILE_NAVIGATION_NAME.station;
		}
	}
}

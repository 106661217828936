<mat-form-field>
	<input matInput type="text"
		[placeholder]="name"
		rbGroupDecimalSeparator [allowNegativeValue]="allowNegativeValue"
		(change)="onMatInputChange()"
		[ngModel]="separatorString"
		[required]="isRequired"
		(ngModelChange)="onSeparatorChanged('separatorString', $event)"
		[disabled]="isDisabled"
		(focus)="$event.target.select()" />
</mat-form-field>

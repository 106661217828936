<div class="ccweather-widget-section ccweather-widget-daily-section" [ngClass]="{'is-sidebar-widget': isIq4SidebarWidget, 'light-theme': theme === 'light'}">
	<div class="ccweather-widget-section-header-row">
		<span class="ccweather-section-header">{{('STRINGS.DAILY_FORECAST' | translate).toUpperCase()}}</span>
	</div>
	<div class="ccweather-widget-daily-table">
		<div class="ccweather-widget-daily-column" *ngFor="let daily of dailyData; let idx = index;">
			<div class="ccweather-widget-daily-header-row"
				 [ngClass]="{'ccweather-widget-daily-header-row-underline': idx === 0}"
			>
				{{(('WEEKDAYS.' + daysFull[idx]) | translate).toUpperCase()}}
			</div>
			<div class="ccweather-widget-daily-temp-row">{{getTemperature(daily, false)}}</div>
			<div class="ccweather-widget-daily-temp-row">{{getTemperature(daily, true)}}</div>
			<div class="ccweather-widget-daily-weather-code-row">
				<img *ngIf="daily.weatherCode !== null"
					 class="ccweather-widget-daily-image"
					 [src]="'../../../../../assets/images/ccweather_icons/' + daily.weatherCode + '.png'"
					 [matTooltip]="getWeatherName(daily.weatherCode)"
					 #tooltip="matTooltip"
					 (wheel)="tooltip.hide()"
					 matTooltipPosition="below"
					 (contextmenu)="onWeatherIconClick($event)"
				>
			</div>
		</div>
	</div>
</div>

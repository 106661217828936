export class DetectModulesState {

	constructor(controllerId: number, isDetecting: boolean, error?: string, isQueued: boolean = false) {
		this.controllerId = controllerId;
		this.isDetecting = isDetecting;
		this.error = error;
		this.isQueued = isQueued;
	}

	controllerId: number;
	error: string;
	isDetecting: boolean;
	isQueued: boolean;
}

<div class="notes-list-container" [class.is-busy]="busy">
	<div class="header">
		<div class="title-search">
			<h3 class="title">{{ 'STRINGS.NOTES' | translate }}</h3>
			<input type="text" class="search-input" [(ngModel)]="searchFilter" (ngModelChange)="getFilteredNotes()"
				#searchInput [class.open]="isSearching" placeholder="{{ 'STRINGS.SEARCH' | translate }}">
		</div>
		<div class="filter-options">
			<div class="button-container">
				<button mat-button class="search-button" (click)="toggleSearch()">
					<i class="mdi mdi-magnify" *ngIf="!isSearching"></i>
					<i class="mdi mdi-magnify-close" *ngIf="isSearching"></i>
				</button>
			</div>
			<div class="button-container">
				<button mat-button [matMenuTriggerFor]="noteFilterMenu" class="filter-button"
					[class.expanded]="trigger.menuOpen" #trigger="matMenuTrigger">
					<i class="mdi mdi-filter"></i>
				</button>
				<mat-menu #noteFilterMenu="matMenu" class="note-filter-menu">
					<!-- Radio button group to filter by "View all" and "View only my mentions" -->
					<div class="section-container" (click)="$event.stopPropagation();">
						<mat-radio-group [(ngModel)]="filterByMentions" (ngModelChange)="getFilteredNotes()">
							<mat-radio-button [value]="0">{{ 'STRINGS.VIEW_ALL_NOTES' | translate }}</mat-radio-button>
							<mat-radio-button [value]="1">{{ 'STRINGS.ONLY_MY_MENTIONS' | translate }}</mat-radio-button>
						</mat-radio-group>
					</div>
					<!-- Radio button group to sort the list by Date, Priority, and read/unread -->
					<div class="section-container" (click)="$event.stopPropagation();">
						<mat-radio-group [(ngModel)]="sortNotesBy" (ngModelChange)="getFilteredNotes()">
							<mat-radio-button [value]="0">{{ 'STRINGS.SORT_BY_DATE' | translate }}</mat-radio-button>
							<mat-radio-button [value]="1">{{ 'STRINGS.SORT_BY_PRIORITY' | translate }}</mat-radio-button>
							<mat-radio-button [value]="2">{{ 'STRINGS.SORT_BY_READ_UNREAD' | translate }}</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="section-container" (click)="$event.stopPropagation();">
						<mat-checkbox class="mat-menu-item" color="primary" [(ngModel)]="showResolved">{{ 'STRINGS.SHOW_RESOLVED' | translate }}</mat-checkbox>
					</div>
				</mat-menu>
			</div>
		</div>
	</div>
	<div class="body">
		<div class="notes-feed" *ngIf="filteredNotes && filteredNotes.length">
			<div class="note-item-container" *ngFor="let note of filteredNotes; trackBy: trackNoteById">
				<rb-note-item
					[note]="note"
					[user]="user"
					[showLocateButton]="true"
					[class.d-none]="!showResolved && note.status === NoteStatus.Resolved"
					(click)="readNote(note)"
					(noteLocationClicked)="onNoteLocationClicked($event, note)"
					(invokeSaveNote)="saveNote($event)"	
					(invokeResolveNote)="resolveNote($event)"></rb-note-item>

				<!-- TODO: Later on we will add the support for note replies
				<ng-template *ngFor="let child of note.replies">
					<rb-note-item [note]="child" [user]="user" (invokeSaveNote)="saveNote($event)"
					(click)="onNoteClicked($event, child)" (invokeResolveNote)="resolveNote($event)"></rb-note-item>
				</ng-template> -->
			</div>
		</div>
		<div class="no-data-container" *ngIf="isNoteListEmpty">
			<div class="no-data">
				<span>{{ 'STRINGS.NO_NOTES_AVAILABLE' | translate }}</span>
			</div>
		</div>
	</div>
</div>
import * as Highcharts from 'highcharts';
import * as moment from 'moment';

import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { RBTSCodeStatus, UserAccessRequestInfoUI } from './api/access-request/models/access-request.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AccessRequestManagerService } from './api/access-request/access-request-manager.service';
import { AppSyncService } from './api/signalR/app-sync.service';
import { AuthManagerService } from './api/auth/auth-manager-service';
import { BackendNotificationService } from './api/signalR/backend-notification.service';
import { BannerMessageService } from './common/services/banner-message.service';
import { BroadcastService } from './common/services/broadcast.service';
import { CommInterfaceManagerService } from './api/comm-interfaces/comm-interface-manager.service';
import { ControllerManagerService } from './api/controllers/controller-manager.service';
import { CultureSettingsManagerService } from './api/culture-settings/culture-settings-manager.service';
import { CustomerEntitlement } from './api/entitlements/models/customer-entitlement.model';
import { CustomModal } from './shared-ui/components/custom-modal/models/custom-modal.interface';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceManagerService } from './common/services/device-manager.service';
import { DIGroupFaultFindingChange } from './api/signalR/DI-group-fault-finding-change.model';
import { EntitlementsManagerService } from './api/entitlements/entitlements-manager.service';
import { environment } from '../environments/environment';
import { ExpiryState } from './api/license-api-cloud/models/expiry-state.model';
import { GlobalCautionMessageBox } from './shared-ui/components/custom-modal/models/alert-modal-options.model';
import { ICIGroupFaultFindingChange } from './api/signalR/ici-group-fault-finding-change.model';
import { LicenseApiCloudManagerService } from './api/license-api-cloud/license-api-cloud-manager.service';
import { LicenseManagerService } from './api/license/license-manager.service';
import { MessageBoxInfo } from './core/components/global-message-box/message-box-info.model';
import { NavigationHistoryService } from './common/services/navigation-history.service';
import { NotificationType } from '../environments/ienvironment';
import { PreloaderService } from './common/services/preloader.service';
import { RbConstants } from './common/constants/_rb.constants';
import { RbEnums } from './common/enumerations/_rb.enums';
import { RbUtils } from './common/utils/_rb.utils';
import { RequestAccessSnackbarComponent } from './shared-ui/components/request-access-snackbar/request-access-snackbar.component';
import { SignalRService } from './api/signalR/signalr.service';
import { Site } from './api/sites/models/site.model';
import { SiteManagerService } from './api/sites/site-manager.service';
import { SoftwareUpdateManagerService } from './api/software-update/software-update-manager.service';
import { TimerCountdownComponent } from './core/components/timer-countdown/timer-countdown.component';
import { TimerCountdownInfo } from './core/components/timer-countdown/timer-countdown-info.model';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UserManagerService } from './api/users/user-manager.service';
import { VersionCheckService } from './common/services/version-check.service';

@UntilDestroy()
@Component({
	selector: 'rb-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
	@ViewChild('messageBox', { static: true }) messageBox;
	@ViewChild('freedomSystemDialog', { static: true }) freedomSystemDialog;
	@ViewChild('chat') chat;
	@ViewChild('drawer', { static: true }) drawer;
	@ViewChild('global_message_box', { static: true }) globalMessageBox: CustomModal;
	@ViewChild('requestAccessSnackbar', { static: true }) requestAccessSnackbar: RequestAccessSnackbarComponent;
	
	EntitlementsRenewalState = RbEnums.Common.EntitlementsRenewalState;
	demoModeWarningMessage: string;
	isGolfSite = false;
	isLoggedIn = false;
	messageBoxInfo: MessageBoxInfo;
	name = '';
	showAdmin = false;
	showMainNav = true;
	isMobile = false;
	showMobileSystemStatus = false;
	hideGlobalBreadcrumbTrail = false;
	gprsSubscriptionExpiryState: ExpiryState;
	entitlementsExpirationDate: Date;
	entitlementsExpirationState = RbEnums.Common.EntitlementsRenewalState.NotExpired;
	showExpiryPanel = false;
	showBannerMessage = false;
	showDemoModeWarning = false;
	showGolfLicenseWarning = false;
	showCompanyLicenseWarning = false;
	showNewSoftwareVersionAvailable = false;
	singleSiteId = 0;
	sites: Site[];
	selectedSite: Site;
	selectedSiteId: number;
	showPasswordExpirationWarning = false;
	pwdExpirationDate: Date = null;
	displayFlatTheme = true;
	isFaultFindingModeOn = false;
	userRequestAccess: UserAccessRequestInfoUI;

	globalMessageBoxOptions: GlobalCautionMessageBox;
	isCloud = false;
	/**
	 * When set to a non-empty string, the load error will usually be displayed in a banner at the top of the screen.
	 * See the HTML priority details for various banners which can be displayed.
	 */
	loadError: string;

	private freedomDialogRef: MatDialogRef<unknown, any>;
	private timerCountdownInfo: TimerCountdownInfo;
	private messageBoxRef;

	/**
	 * notificationService is created during initialization. It may be SignalRService or AppSyncService, depending
	 * on the target platform.
	 */
	private notificationService: BackendNotificationService;

	@HostListener('window:resize')
	onResize() {
		// Set up the host listener to listen for resize events to trigger hiding/showing
		// of hamburger menu and responsive design of navigation pane.
		this.deviceManager.windowResize.next({ width: window.innerWidth, height: window.innerHeight });
	}

	@HostListener('document:keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		// Guard against the cat sitting on the enter key while the mouse is positioned over a button.
		if ((e.key === 'Enter' || e.keyCode === 13) && e.repeat) {
			e.preventDefault();
		}
	}

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(
		public authManager: AuthManagerService,
		private bannerMessage: BannerMessageService,
		private broadcastService: BroadcastService,
		private commInterfaceManager: CommInterfaceManagerService,
		private controllerManager: ControllerManagerService,
		private cultureSettingsManager: CultureSettingsManagerService,
		private deviceDetector: DeviceDetectorService,
		private deviceManager: DeviceManagerService,
		private entitlementsManager: EntitlementsManagerService,
		private licenseApiCloudManagerService: LicenseApiCloudManagerService,
		private licenseManager: LicenseManagerService,
		private matDialog: MatDialog,
		private navigationHistoryService: NavigationHistoryService,
		private preloaderService: PreloaderService,
		private router: Router,
		private siteManager: SiteManagerService,
		private softwareUpdateManager: SoftwareUpdateManagerService,
		private titleService: Title,
		private translate: TranslateService,
		private userService: UserManagerService,
		private versionCheckService: VersionCheckService,
		private accessRequestManager: AccessRequestManagerService,
		public viewContainerRef: ViewContainerRef
	) {
	}

	ngOnInit() {
		this.setBrowserType();

		// Use direct access to get the notification service type. Dependency injection won't work from the environment setting
		// without some structural changes to the notification services.
		switch (environment.notificationType) {
			case NotificationType.SignalR:
			default:
				this.notificationService = new SignalRService();
				break;
			case NotificationType.AppSync:
				this.notificationService = new AppSyncService();
				break;
		}

		// Monitor browser refresh. This can be very useful when the system behaves differently on a browser refresh. E.g., Waiting for
		// Translation Services to load.
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart), untilDestroyed(this))
			.subscribe(() => this.deviceManager.browserRefreshed  = !this.router.navigated);

		this.broadcastService.userLoggedIn
			.pipe(untilDestroyed(this))
			.subscribe(() => this.initInternal());

		// check for update after user roles is updated.
		this.broadcastService.onUserFeaturesUpdated
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.checkForSoftwareUpdate();
			});

		// Show or Hide Main Navigation
		this.deviceManager.showMainNavChange
			.pipe(untilDestroyed(this))
			.subscribe((show: boolean) => this.showMainNav = show);

		// Show or Hide Mobile System Status
		this.deviceManager.showMobileSystemStatus
			.pipe(untilDestroyed(this))
			.subscribe((show: boolean) => this.showMobileSystemStatus = show);

		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => this.isMobile = isMobile);

		// NOTE: Reports uses a slightly different layout than all other pages and has some unwanted drawing aberrations caused by the placement of the
		// 		 global breadCrumbTrail. To accommodate this, we hid the global breadCrumbTrail and show a custom report breadCrumbTrail when requested.
		this.broadcastService.hideGlobalBreadcrumbTrail
			.pipe(untilDestroyed(this))
			.subscribe((hide: boolean) => setTimeout(() => this.hideGlobalBreadcrumbTrail = hide));

		this.isMobile = this.deviceManager.isMobile;

		this.bannerMessage.messageChange
			.pipe(untilDestroyed(this))
			.subscribe((message: string) => {
				this.showBannerMessage = !this.isGolfSite && message !== '';
			});

		this.broadcastService.controllerCollectionChange
			.pipe(untilDestroyed(this))
			.subscribe(() => this.updateDemoModeText());

		// RB-14396: We need to check if a fault finding mode is running to show the banner.
		this.broadcastService.groupFaultFindingChange
			.pipe(untilDestroyed(this))
			.subscribe({
				next: (data: DIGroupFaultFindingChange | ICIGroupFaultFindingChange) =>{
					// This will enable/disable the fault finding banner.
					this.isFaultFindingModeOn = data.faultFindingON;
				}
			});

		this.softwareUpdateManager.newUpdateAvailable
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.onShowNewUpdateAvailable();
			});

		this.siteManager.sitesUpdate
			.pipe(untilDestroyed(this))
			.subscribe(() => this.getSites());

		this.siteManager.siteDeleted
			.pipe(untilDestroyed(this))
			.subscribe(() => this.getSites());

		// RB-9978: Add a setTimeout function to avoid ExpressionChangedAfterItHasBeenCheckedError
		setTimeout(() => {
			this.broadcastService.selectedEntityChange
				.pipe(untilDestroyed(this))
				.subscribe((entity) => { if (entity instanceof Site) this.getSelectedSite(); });
		});

		this.initInternal();
		this.getSites();
		this.initDefaultGlobalboxOption();

		// NOTE: initInternal checks for isGolfSite.
		if (!this.isGolfSite) {
			this.commInterfaceManager.commInterfaceSimCardChanged
				.subscribe( () => this.checkForGprsSubscriptionExpiry());

			this.checkUserPasswordExpiration();
			this.checkAccessRequest();
			this.broadcastService.newAccessRequest.pipe(untilDestroyed(this)).subscribe(name => {
				if (this.authManager.currentUser.profile.name === name) {
					this.checkAccessRequest();
				}
			});
		}

		// Open Menu click from rb-back-button
		this.navigationHistoryService.invokeOpenMenu.pipe(untilDestroyed(this)).subscribe(() => {
			this.drawer.open();
		});

		// Beamer integration
		this.injectBeamerScript();
	}

	checkAccessRequest() {
		this.accessRequestManager.getCurrentAccessRequestInfoForUser()
			.subscribe((accessRequest: UserAccessRequestInfoUI) => {
				if (accessRequest && accessRequest.rbtsCode && accessRequest.rbtsCodeExpiration) {
					this.userRequestAccess = accessRequest;
					const isExpired = moment().isSameOrAfter(accessRequest.rbtsCodeExpiration);
					const shouldShowNotification = !isExpired && accessRequest.rbtsCodeStatus === RBTSCodeStatus.PendingVerification;
					if (shouldShowNotification) {
						if (this.requestAccessSnackbar) {
							this.requestAccessSnackbar.showNotification();
						}
						
					}
				}
			});
	}

	checkUserPasswordExpiration() {
		// if this is cloud, will check password expiration after logging in.
		this.licenseManager.isCloud().pipe(take(1)).subscribe((isCloud) => {
			this.isCloud = isCloud;
			if (isCloud) {
				setTimeout(() => {
					this.userService.getCurrentUserPasswordExpirationDate().pipe(take(1)).subscribe(date => {
						if (!date) {
							return;
						}
						this.pwdExpirationDate = date;

						const currentDate = new Date();
						date.setHours(0, 0, 0, 0);
						currentDate.setHours(0, 0, 0, 0);
						const timeDiff = Math.abs(date.getTime() - currentDate.getTime());
						const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
						if (diffDays <= 30 && this.needToShowExpiredPasswordForToday()) {
							this.showPasswordExpirationWarning = true;
							this.setShowExpiredPasswordDate();
						}
					}
					)
				}, 1000);
			}
		});
	}

	setShowExpiredPasswordDate() {
		localStorage.setItem(`expired_pwd_notify_date_userid_${this.authManager.currentUser.profile.name}`, new Date().toLocaleDateString('en-US'));
	}

	needToShowExpiredPasswordForToday(): boolean {
		const current = new Date().toLocaleDateString('en-US');
		const storedDate = localStorage.getItem(`expired_pwd_notify_date_userid_${this.authManager.currentUser.profile.name}`, );
		if (storedDate !== current) {
			return true;
		}
		return false;
	}

	onClosePasswordExpirationWarn() {
		this.showPasswordExpirationWarning = false;
	}

	ngOnDestroy() {
		this.versionCheckService.stop();
		this.stopServices();
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	logOut() {
		this.stopServices();
		this.clearStorage();
		this.authManager.startOauthSignOut();
	}

	onToggleTheme() {
		this.displayFlatTheme = !this.displayFlatTheme;
	}

	onCloseMessageBox() {
		if (!this.messageBoxRef) { return; }

		this.messageBoxRef.close();
		this.messageBoxRef = null;
	}

	checkForSoftwareUpdate() {
		// Only check for updates in standalone if current user group level is admin or rootadmin (owner)
		this.licenseManager.isCloud().pipe(take(1)).subscribe(isCloud => {
			if (!isCloud && (this.authManager.isOwner || this.authManager.isAdmin)) {
				this.softwareUpdateManager.CheckForNewUpdate();
			}
		});
	}

	onShowNewUpdateAvailable() {
		this.softwareUpdateManager.GetUpdateDismissTime().pipe(take(1)).subscribe(dismissUntilDate => {
			const now = new Date();
			if (!dismissUntilDate || !moment(now).isSameOrBefore(dismissUntilDate)) {
				this.showNewSoftwareVersionAvailable = true;
			}
		});
	}

	dismissUpdateNotification() {
		// dismiss update notification in next seven days
		const dismissUntil = moment().add(RbConstants.Common.NEW_SOFTWARE_AVAILABLE_BANNER_NOT_DISPLAY_IN_SEVEN_DAYS, 'days').toDate();
		this.softwareUpdateManager.UpdateDismissTime(dismissUntil).pipe(take(1)).subscribe(result => {
			// updated dismiss date time
			// then set the flag to show new software version is equal false
			this.showNewSoftwareVersionAvailable = false;
		});
	}

	onBannerClose() {
		// if current banner is new software notification, dismiss the update notification
		if (this.showNewSoftwareVersionAvailable) {
			this.dismissUpdateNotification();
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private initInternal() {
		this.isLoggedIn = this.authManager.isLoggedIn;

		const profile = this.authManager.getUserProfile();
		if (profile == null) return;

		// Since the language is available in the user profile, set it now for any early-in-startup translate needs.
		// RB-6052, in particular, noted that the breadcrumb "Dashboard" string was usually wrong.
		this.cultureSettingsManager.setLanguage(this.cultureSettingsManager.getCultureId(profile.locale));

		// Get the full culture data and set the language from that, although it should be the same as above.
		this.cultureSettingsManager.getCultureSettings(profile.cultureId).pipe(take(1)).subscribe(settings => {
			this.cultureSettingsManager.setLanguage(settings.cultureId);
		});

		this.authManager.start();
		this.isGolfSite = RbUtils.Common.isGolfSite(profile.siteType);
		this.showAdmin = RbUtils.Common.isAdmin(profile.groupLevel);
		this.notificationService.startConnection();
		this.name = profile.name;
		this.bannerMessage.start();

		// RB-12220: We plan to use AngularWorkerService, but we don't need it anymore.
		// The current version check service already implemented.
		this.versionCheckService.initVersionCheck(this.versionCheckUrl);

		this.broadcastService.showMessageBox
			.pipe(untilDestroyed(this))
			.subscribe((messageBoxInfo: MessageBoxInfo) => {
				if (this.messageBoxRef != null) { return; }
				this.messageBoxInfo = messageBoxInfo;
				this.messageBoxRef = this.matDialog.open(this.messageBox, { disableClose: true });
			});

		this.broadcastService.showGlobalCautionMessageBox
			.pipe(untilDestroyed(this))
			.subscribe((messageBoxInfo: GlobalCautionMessageBox) => {
				this.showGlobalMessageBox(messageBoxInfo);
			})

		this.broadcastService.showCountdownDialog
			.pipe(untilDestroyed(this))
			.subscribe((info: TimerCountdownInfo) => {
				this.timerCountdownInfo = info;
				const dialogRef = this.matDialog.open(TimerCountdownComponent);
				dialogRef.afterClosed().subscribe(result => {
					if (this.timerCountdownInfo != null && this.timerCountdownInfo.resultCallback != null) {
						if (result) {
							this.notificationService.stopConnection();
						}
						this.timerCountdownInfo.resultCallback(result);
					}
				});
			});

		Highcharts.setOptions({
			colors: RbConstants.Form.REPORT_PAGE.chartColors
		});

		if (!this.isGolfSite) {
			this.licenseManager.isCloud().pipe(take(1)).subscribe( isCloud => {
				this.isCloud = isCloud;
				this.checkForGprsSubscriptionExpiry();
				this.checkForEntitlementsExpiration();	

				if (!this.isCloud) {
					this.checkStandaloneLicenseValid();
				}
			});
			// RB-6639: For non-golf case, set title to IQ4 since we're in Commercial.
			this.titleService.setTitle(this.translate.instant('PRODUCT_NAME.IQ4'));
		} else {
			// RB-6639: For golf case, set the title based on the product being executed (CirrusIC, CirrusPRO)
			this.licenseManager.allowGolfUpgraded()
				.pipe(take(1))
				.subscribe(allowGolfUpgraded => {
					// We don't have an upgraded version, but we've changed the name from CirrusIC to CIRRUSPRO. Use
					// that as the title.
					this.titleService.setTitle(this.translate.instant('PRODUCT_NAME.CIRRUSPRO'));
				});
			this.checkGolfLicense();
			this.checkStandaloneLicenseValid();
		}

		/* Preload any data we may need at app startup. E.g., localized enum lists form api. */
		this.preloaderService.load().subscribe();
	}

	private getSelectedSite() {
		this.selectedSite = this.siteManager.selectedSite;
		this.selectedSiteId = this.selectedSite == null ? null : this.selectedSite.id;
	}

	private getSites() {
		this.siteManager.getSites(true).pipe(take(1))
			.subscribe((sites: Site[]) => {
				this.sites = sites || [];
				this.singleSiteId = this.sites.length === 1 ? this.sites[0].id : null;

				this.updateDemoModeText();
			});
	}

	private stopServices() {
		this.bannerMessage.stop();
		this.authManager.stop();
		this.notificationService.stopConnection();
	}

	private clearStorage() {
		localStorage.removeItem(RbConstants.Common.MANUAL_OPS_DURATION_KEY);
	}

	private get versionCheckUrl(): string {
		// Strip back the window URL to the last slash. This works around any issue where the HTML file is
		// specified, something like http://servername:port/index.html, for example.
		const fullAppUrl = window.location.href;
		const doubleSlashIndex = fullAppUrl.indexOf('//');
		const strippedAppUrl = fullAppUrl.substring(0, fullAppUrl.substring(doubleSlashIndex + 2).indexOf('/') + doubleSlashIndex + 3);
		return strippedAppUrl + 'version.json';
	}

	private setBrowserType() {
		const el = document.getElementsByTagName('html')[0];

		let attr = document.createAttribute('browser-name');
		attr.value = this.deviceDetector.getDeviceInfo().browser;
		el.attributes.setNamedItem(attr);

		attr = document.createAttribute('browser-version');
		attr.value = this.deviceDetector.getDeviceInfo().browser_version;
		el.attributes.setNamedItem(attr);
	}

	private checkForGprsSubscriptionExpiry() {
		this.licenseApiCloudManagerService.getMostExpiredExpiryStateItem(this.isCloud)
			.pipe(take(1))
			.subscribe((expiryState: ExpiryState) => {
				setTimeout(() => {
					// RB-9813: We don't want to show the expiration panel everytime, only if there's something to notify
					// about! Check the expiryState first. If not-expired, don't show!
					this.showExpiryPanel = RbUtils.Common.isGprsExpried(expiryState);
					this.gprsSubscriptionExpiryState = expiryState;
				}, 1000);

			});
	}

	private checkForEntitlementsExpiration() {
		this.entitlementsManager.getEntitlements().subscribe((entitlements: CustomerEntitlement[]) => {
			entitlements.forEach((entitlement: CustomerEntitlement) => {
				const now = new Date().getTime();
				// Check for expiration
				const trialExpired = entitlement.trialExpiration != null && entitlement.trialExpiration.getTime() < now;
				const fullExpired = entitlement.expiration != null && entitlement.expiration.getTime() < now;
				const moreThan6Months = (fullExpired || trialExpired) &&
					(entitlement.expiration ?? entitlement.trialExpiration).getTime() < moment().subtract(6, 'months').toDate().getTime();
				if (!moreThan6Months && ((entitlement.expiration != null && fullExpired) || (entitlement.expiration == null && trialExpired))) {
					this.entitlementsExpirationDate = entitlement.expiration ?? entitlement.trialExpiration;
					this.entitlementsExpirationState = this.EntitlementsRenewalState.Expired;
					return;
				}

				const suppressExpiringWarning = localStorage.getItem(RbConstants.Common.DO_NOT_SHOW_ENTITLEMENTS_RENEWAL_MESSAGE) === 'true';
				if (suppressExpiringWarning) return;

				// Check for expiring soon
				const expiringDate = moment().add(14, 'days').toDate();
				const trialExpiring = entitlement.trialExpiration != null && entitlement.trialExpiration.getTime() < expiringDate.getTime() &&
					entitlement.trialExpiration.getTime() > now;
				const fullExpiring = entitlement.expiration != null && entitlement.expiration.getTime() < expiringDate.getTime() &&
					entitlement.expiration.getTime() > now;
				if ((entitlement.expiration != null && fullExpiring) || (entitlement.expiration == null && trialExpiring)) {
					this.entitlementsExpirationDate = entitlement.expiration ?? entitlement.trialExpiration;
					this.entitlementsExpirationState = this.EntitlementsRenewalState.Expiring;
					return;
				}
			});
		});
	}

	onFreedomSystemClicked() {
		this.freedomDialogRef = this.matDialog.open(this.freedomSystemDialog);
	}

	onFreedomDialogComplete() {
		this.freedomDialogRef.close();
	}

	/**
	 * Click handler for the (golf) how-to videos menu item. We use a click handler because some browsers seem to
	 * avoid opening new tabs unless we do it from a click handler.
	 */
	howToVideosClick() {
		RbUtils.Browser.openNewTab(RbConstants.Common.GolfHowToVideosURL);
		return true;
	}

	onEntitlementsWarningClosed()
	{
		this.entitlementsExpirationState = this.EntitlementsRenewalState.NotExpired; // to hide the view
	}

	private checkGolfLicense() {
		const sources: Observable<any>[] = [
			this.licenseManager.isActivated().pipe(take(1)),
			this.licenseManager.allowGolf().pipe(take(1)),
		];

		forkJoin(sources)
			.subscribe(([isActivated, allowGolf]) => {
				if (isActivated && allowGolf) return;
				setTimeout(() => {
					this.showGolfLicenseWarning = true;
				}, 1000);
			}, error => {
				// RB-9348: Show a banner message indicating an issue determining the license status. This usually
				// indicates that the service isn't (yet) running, so suggest waiting and retrying, maybe.
				console.error(error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY'));
				this.loadError = this.translate.instant('STRINGS.NO_LICENSE_INFO_RETRIEVED');
			});
	}

	/**
	 * Perform a check of the database-stored activation code against the server machine. This will tell us, 
	 * for example, if the user's machine has changed in some way (changed active network adapter, etc.) or if 
	 * we're running a customer database in engineering or QA where the local machine may be activated, but
	 * the activation code in the database doesn't match the machine.
	 */
	private checkStandaloneLicenseValid(): void {
		this.licenseManager.isCompanyLicenseValid()
			.pipe(take(1))
			.subscribe((isCompanyLicenseValid) => {
				// RB-14717: Only show the license warning (customer number/activation code invalid on
				// this machine), if the current user is the owner.
				if (!isCompanyLicenseValid && this.authManager.isOwner) {
					setTimeout(() => {
						this.showCompanyLicenseWarning = true;
					});
				}
			});
	}

	private updateDemoModeText(): void {
		this.controllerManager.getControllersList().subscribe(controllers => {
			this.showDemoModeWarning = controllers.some(c => c.commInterfaceType === RbEnums.Common.CommInterfaceType.Demo);
			if (this.showDemoModeWarning) {
				this.demoModeWarningMessage = RbUtils.Translate.instant('STRINGS.INTERFACE_IN_DEMO_MODE',
					{ link: this.singleSiteId == null ? RbUtils.Translate.instant('STRINGS.INTERFACES_LOWERCASE') :
							(this.setupPartialDemoModeLink() + `${RbUtils.Translate.instant('STRINGS.INTERFACES_LOWERCASE')}</a>`)});
			}
		});
	}

	private setupPartialDemoModeLink() {
		return this.isGolfSite ? `<a href="#/systemsetup/courseconfig/interfaces?siteId=${this.singleSiteId}">`
			: `<a href="#/systemsetup/siteconfig/controllers?siteId=${this.singleSiteId}">`;
	}

	private injectBeamerScript() {
		let node = document.createElement('script');
		node.text = `var beamer_config = {
						product_id: "${this.isGolfSite ? 
							environment.beamerProductKeyCirrus : 
							environment.beamerProductKeyIq4}",
						selector: "rb-notes-button",
						button: false,

						// NOTE: Gather these values dynamically if we choose to include them.
						// user_id: '123456',
						// user_firstname: 'Mark',
						// user_lastname: 'Wineman',
						// user_email: 'markw@1218team.com'
					};`;
		document.getElementsByTagName('body')[0].appendChild(node);

		node = document.createElement('script');
		node.type = 'text/javascript';
		node.src = 'https://app.getbeamer.com/js/beamer-embed.js';
		node.defer = true;
		document.getElementsByTagName('body')[0].appendChild(node);

		// Ensure that we properly set the language of the Announcements Panel after Beemer is loaded.
		setTimeout(() => {
			const profile = this.authManager.getUserProfile();
			this.cultureSettingsManager.setLanguage(this.cultureSettingsManager.getCultureId(profile.locale));
		}, 1000)
	}

	private initDefaultGlobalboxOption() {
		this.globalMessageBoxOptions = {
			alertStyle: 'primary',
			icon: 'icon-warning',
			iconColor: '#757575',
			primaryBtnEvent: () => {
			},
			secondaryBtnEvent: () => {
				this.globalMessageBox.closeModal();
			},
			crossBtnEvent: () => {
				this.globalMessageBox.closeModal();
			},
			primaryBtnText: this.translate.instant('STRINGS.ACCEPT_UPPERCASE'),
			secondaryBtnText: this.translate.instant('STRINGS.CANCEL'),
			title: this.translate.instant('STRINGS.CAUTION')
		}
	}

	private showGlobalMessageBox(globalMessageBoxOptions: GlobalCautionMessageBox) {
		if (this.globalMessageBox) {
			this.initDefaultGlobalboxOption();
			this.globalMessageBoxOptions = {
				...this.globalMessageBoxOptions,
				...globalMessageBoxOptions,
			}
			this.globalMessageBoxOptions.primaryBtnEvent = () => {
				if (globalMessageBoxOptions.primaryBtnEvent) {
					globalMessageBoxOptions.primaryBtnEvent();
				}
				this.globalMessageBox.closeModal();
			}
			this.globalMessageBoxOptions.secondaryBtnEvent = () => {
				if (globalMessageBoxOptions.secondaryBtnEvent) {
					globalMessageBoxOptions.secondaryBtnEvent();
				}
				this.globalMessageBox.closeModal();
			}
			this.globalMessageBoxOptions.crossBtnEvent = () => {
				if (globalMessageBoxOptions.crossBtnEvent) {
					globalMessageBoxOptions.crossBtnEvent();
				}
				this.globalMessageBox.closeModal();
			}
			this.globalMessageBox.openModal();
		}
	}
}

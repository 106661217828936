import { JobChange } from './job-change.model';

export class FirmwareUpdateProgress extends JobChange {

	constructor(jobChange: JobChange, isCompleted = false, isQueued = false) {

		super(jobChange);

		if (jobChange) {
			Object.assign(this, jobChange);
		}

		this.isCompleted = isCompleted;
		// RB-7708: We save the queued state, in case some UI will show this.
		this.isQueued = isQueued;
	}

	progress: number;
	isCompleted = false;
	isQueued = false;
}

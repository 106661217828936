import * as moment from 'moment';
import { Moment } from 'moment';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { WeatherConditionIcon } from './weather-condition-icon.model';
import { WeatherConditionInfo } from './weather-condition-info.model';
import { WorldWeatherAstronomy } from './world-weather-astronomy.model';
import { WorldWeatherHourly } from './world-weather-hourly.model';

export class WorldWeatherWeather {

	private readonly daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
	private _weatherConditionInfo: WeatherConditionInfo = null;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.date) this.date = moment(json.date);
			if (json.astronomy) this.astronomy = json.astronomy.map(ast => new WorldWeatherAstronomy(ast));
			if (json.hourly) this.hourly = json.hourly.map(h => new WorldWeatherHourly(h));
		}
	}

	date: Moment;
	astronomy: WorldWeatherAstronomy[];
	maxtempC: string;
	maxtempF: string;
	mintempC: string;
	mintempF: string;
	// avgtempC: string;
	// avgtempF: string;
	totoalSnow_cm: string;
	sunHour: string;
	uvIndex: string;
	hourly: WorldWeatherHourly[];

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get shortDayOfWeek(): string {
		return RbUtils.Translate.instant(`WEEKDAYS.${this.daysOfWeek[this.date.day()]}`);
	}

	get tempScale(): string {
		return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ? 'F' : 'C';
	}

	get averageTemp(): string {
		if (!this.hourly || this.hourly.length < 1) return '--';

		return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ? this.hourly[0].tempF : this.hourly[0].tempC;
	}

	get minTemp(): string {
		return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ? this.mintempF : this.mintempC;
	}

	get maxTemp(): string {
		return RbUtils.User.unitsType === RbEnums.Common.UnitsType.English ? this.maxtempF : this.maxtempC;
	}

	get icon(): WeatherConditionIcon {
		return this.weatherConditionInfo.icon;
	}

	get forecastDescription(): string {
		return this.weatherConditionInfo.description;
	}

	get forecastChanceOfRain(): string {
		if (!this.hourly || this.hourly.length < 1) return '--';

		return this.hourly[0].chanceofrain;
	}
	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	get weatherConditionInfo(): WeatherConditionInfo {
		if (!this._weatherConditionInfo) {
			this._weatherConditionInfo = RbUtils.Weather.getWeatherConditionInfo(+this.hourly[0].weatherCode);
		}

		return this._weatherConditionInfo;
	}
}

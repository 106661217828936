import { ControllerFirmwareInfo } from './controller-firmware-info.model';
import { FirmwareVersion } from './firmware-version.model';
import { FlowModuleInfo } from './flow-module-info.model';
import { ModuleFirmwareInfo } from './module-firmware-info.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class ControllerFirmwareVersions {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.current) this.current = new ControllerFirmwareInfo(json.current);
			if (json.currentModules) this.currentModules = json.currentModules.map(m => new ModuleFirmwareInfo(m));
			if (json.latest) this.latest = new FirmwareVersion(json.latest, RbEnums.Common.FirmwareType.Controller);
			if (json.latestCic) this.latestCic = new FirmwareVersion(json.latestCic, RbEnums.Common.FirmwareType.Cic);
			if (json.latestBootloader) this.latestBootloader = new FirmwareVersion(json.latestBootloader, RbEnums.Common.FirmwareType.Bootloader);
			if (json.latestModuleBootloader) this.latestModuleBootloader = new FirmwareVersion(json.latestModuleBootloader,
				RbEnums.Common.FirmwareType.ModuleBootloader);
			if (json.latest2WireModule) this.latest2WireModule = new FirmwareVersion(json.latest2WireModule, RbEnums.Common.FirmwareType.Module2Wire);
			if (json.latestSMBusModule) this.latestSMBusModule = new FirmwareVersion(json.latestSMBusModule, RbEnums.Common.FirmwareType.ModuleSMBus);
			if (json.others) this.others = json.others.map(o => new FirmwareVersion(o, RbEnums.Common.FirmwareType.Controller));
			if (json.otherCics) this.otherCics = json.otherCics.map(o => new FirmwareVersion(o, RbEnums.Common.FirmwareType.Cic));
			if (json.otherBootloaders) this.otherBootloaders = json.otherBootloaders.map(o => new FirmwareVersion(o, RbEnums.Common.FirmwareType.Bootloader));
			if (json.other2WireModules) {
				this.other2WireModules = json.other2WireModules.map(o => new FirmwareVersion(o, RbEnums.Common.FirmwareType.Module2Wire));
			}
			if (json.otherModuleBootloaders) this.otherModuleBootloaders = json.otherModuleBootloaders.map(o => new FirmwareVersion(o,
				RbEnums.Common.FirmwareType.ModuleBootloader));
			if (json.otherSMBusModules) {
				this.otherSMBusModules = json.otherSMBusModules.map(o => new FirmwareVersion(o, RbEnums.Common.FirmwareType.ModuleSMBus));
			}
			if (json.latestFsm2Module) this.latestFsm2Module = new FirmwareVersion(json.latestFsm2Module, RbEnums.Common.FirmwareType.FSM2);
			if (json.otherFsm2Modules) this.otherFsm2Modules = json.otherFsm2Modules.map(o => new FirmwareVersion(o, RbEnums.Common.FirmwareType.FSM2));
			if (json.latestFscm2Module) this.latestFscm2Module = new FirmwareVersion(json.latestFscm2Module, RbEnums.Common.FirmwareType.FSCM2);
			if (json.otherFscm2Modules) this.otherFscm2Modules = json.otherFscm2Modules.map(o => new FirmwareVersion(o, RbEnums.Common.FirmwareType.FSCM2));
		}
	}

	current: ControllerFirmwareInfo = null;
	currentModules: ModuleFirmwareInfo[] = null;
	currentFlowModule: FlowModuleInfo = null;
	latest: FirmwareVersion = null;
	latestCic: FirmwareVersion = null;
	latestBootloader: FirmwareVersion = null;
	latest2WireModule: FirmwareVersion = null;
	latestModuleBootloader: FirmwareVersion = null;
	latestSMBusModule: FirmwareVersion = null;
	others: FirmwareVersion[] = null;
	otherCics: FirmwareVersion[] = null;
	other2WireModules: FirmwareVersion[] = null;
	otherModuleBootloaders: FirmwareVersion[] = null;
	otherSMBusModules: FirmwareVersion[] = null;
	otherBootloaders: FirmwareVersion[] = null;
	latestFsm2Module: FirmwareVersion = null;
	otherFsm2Modules: FirmwareVersion[] = null;
	latestFscm2Module: FirmwareVersion = null;
	otherFscm2Modules: FirmwareVersion[] = null;
}

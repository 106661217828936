import { ToolbarInfoGroup } from './toolbar-info-group.model';

export class ToolbarInfo {

	constructor() {
		this.groups = [];
	}

	groups: ToolbarInfoGroup[];

}

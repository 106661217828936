import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { AreaManagerService } from '../../api/areas/area-manager.service';
import { ControllerManagerService } from '../../api/controllers/controller-manager.service';
import { FlowElementManagerService } from '../../api/flow-elements/flow-element-manager.service';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { PinCodeApiService } from '../../api/pin-codes/pin-code-api.service';
import { ProgramGroupManagerService } from '../../api/program-groups/program-group-manager.service';
import { ProgramManagerService } from '../../api/programs/program-manager.service';
import { RbConstants } from '../constants/_rb.constants';
import { RbUtils } from '../utils/_rb.utils';
import { SensorManagerService } from '../../api/sensors/sensor-manager.service';
import { SiteManagerService } from '../../api/sites/site-manager.service';
import { StationManagerService } from '../../api/stations/station-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { UniquenessResponse } from '../../api/_common/models/uniqueness-response.model';
import { UserManagerService } from '../../api/users/user-manager.service';
import { WeatherSourceManagerService } from '../../api/weather-sources/weather-source-manager.service';

@Injectable({
	providedIn: 'root'
})
export class FormValidationService {

	private validationMessages = {
		required: 'VALIDATION.REQUIRED',
		pattern: 'VALIDATION.PATTERN',
		minlength: 'VALIDATION.MIN_LENGTH',
		maxlength: 'VALIDATION.MAX_LENGTH',
		min: 'VALIDATION.MIN',
		max: 'VALIDATION.MAX',
		rbEqual: 'VALIDATION.NOT_EQUAL',
		rbMinMax: 'VALIDATION.RANGE_INVALID',
		rbMultiRange: 'VALIDATION.RANGE_INVALID_MULTI',
		rbUniqueName: 'VALIDATION.UNIQUE_NAME',
		rbPattern: 'VALIDATION.PATTERN',
		rbPhone: 'VALIDATION.PHONE',
		rbPin: 'VALIDATION.PIN',
		rbArc: 'VALIDATION.ARC',
		rbArcFullRange: 'VALIDATION.ARC_FULL_RANGE',
		rbArcRange: 'VALIDATION.ARC_RANGE',
		rbArcRangeOrValue: 'VALIDATION.ARC_RANGE_OR_VALUE',
		rbNoWhitespace: 'VALIDATION.NO_WHITE_SPACE_IP',
		rbGreaterThanOrEqual: 'VALIDATION.MUST_BE_GREATER_THAN'
	};

	private static checkUniquenessResponse(result: UniquenessResponse) {
		return result.isUnique === false ? { rbUniqueName: true } : null;
	}

	constructor(private translate: TranslateService,
				private weatherService: WeatherSourceManagerService,
				private siteService: SiteManagerService,
				private controllerService: ControllerManagerService,
				private userService: UserManagerService,
				private areaService: AreaManagerService,
				private stationService: StationManagerService,
				private sensorService: SensorManagerService,
				private programGroupService: ProgramGroupManagerService,
				private programService: ProgramManagerService,
				private floManagerService: FlowElementManagerService,
				private pinCodeService: PinCodeApiService) { }

	rbMinMaxValidation(minValue: number, maxValue: number, isHex = false, errorStringPrecision: number = 0,
		allowEmpty: boolean = false): ValidatorFn {
		return (c: AbstractControl): ValidationErrors | null => {
			if (c.value == null) { return null; }

			let numberValue: number;
			if (typeof (c.value) === 'number') {
				numberValue = c.value;
			} else if (typeof (c.value) === 'string') {
				// If the string is empty and allowEmpty is true, we let it go.
				if (allowEmpty && RbUtils.Common.isNullOrWhiteSpace(c.value)) {
					return null;
				}

				// Zero length strings cause validation error
				if (c.value.length === 0) { return { rbMinMax: { min: minValue, max: maxValue, isHex: isHex, errorStringPrecision: errorStringPrecision } }; }

				// Substitute '.' for any non-digits (except leading negative sign) then convert to a number
				const stringValue = isHex ? c.value : c.value.replace(/(?!\-)\D/g, '.');
				numberValue = isHex ? parseInt(stringValue, 16) : parseFloat(stringValue);
			} else {
				return null;
			}

			if ((minValue != null && numberValue < minValue) || (maxValue != null && numberValue > maxValue)) {
				return { rbMinMax: { min: minValue, max: maxValue, isHex: isHex, errorStringPrecision: errorStringPrecision } };
			}
			return null;
		};
	}

	rbGreaterThanOrEqualValidation(compareControlName: string, control1DisplayName: string = null, control2DisplayName: string = null) {
		let comparisonControl: AbstractControl;

		return function (c: AbstractControl) {
			if (c == null || c.parent == null || c.value == null) { return null; }

			// Add a valueChanges watcher for the "other" control, too.
			if (!comparisonControl) {
				comparisonControl = c.parent.get(compareControlName) as AbstractControl;
				comparisonControl.valueChanges.pipe(debounceTime(RbConstants.Form.DEBOUNCE_TIME_MS)).subscribe(() => c.updateValueAndValidity());
			}

			if (+c.value < +comparisonControl.value) {
				const control1Name = this.translate.instant((control1DisplayName != null ? control1DisplayName : 'STRINGS.THRESHOLD_1'));
				const control2Name = this.translate.instant((control2DisplayName != null ? control2DisplayName : 'STRINGS.THRESHOLD_2'));
				return { rbGreaterThanOrEqual: { control1: control1Name, control2: control2Name } };
			}
			return null;
		}.bind(this);
	}
	
	rbTimeValidation(startTimeName: string = null, endTimeName: string = null): ValidatorFn {
		return (ctrl: FormGroup) => {
			const startTime = ctrl.get(startTimeName)?.value;
  			const endTime = ctrl.get(endTimeName)?.value;

			if (startTime && endTime) {
				return moment(startTime).isAfter(moment(endTime)) ? { rbTimeValidation: true } : null;
			}
			return null;
		}
	}

	rbNoWhitespaceValidator(): ValidatorFn {
		return (c: AbstractControl): ValidationErrors | null => {
			if (c.value == null || c.value.length === 0) { return null; }
			const str = c.value.toString() as string;
			if (str.indexOf(' ') > -1) {
				return { rbNoWhitespace: true };
			}
			return null;
		};
	}

	/**
	 * RB-8013: Utility converting value number to hex, if isHex is true and to decimal if not.
	 * @param val Number value to be converted to string.
	 * @param isHex Boolean indicator of whether to convert to hex (or false for decimal).
	 */
	rbMultiRangeBuildValueHex(val: number, isHex: boolean): string {
		return isHex ? val.toString(16).toUpperCase() : val.toString();
	}

	/**
	 * RB-8013: Return a list of ranges usable in the error message when an rbMultiRangeValidation fails.
	 */
	rbMultiRangeBuildRangesString(ranges: { min: number, max: number }[], isHex = false): string {
		// Convert the min/max values for each range into a suitable string. Note that we have to handle isHex here.
		const rangesSep = this.translate.instant('VALIDATION.RANGE_INVALID_MULTI_RANGE_FORMAT_SEPARATOR');
		const rangesString = ranges.map(r => this.translate.instant('VALIDATION.RANGE_INVALID_MULTI_RANGE_FORMAT',
			{ min: this.rbMultiRangeBuildValueHex(r.min, isHex), max: this.rbMultiRangeBuildValueHex(r.max, isHex) }))
			.join(rangesSep);
		return rangesString;
	}

	/**
	 * RB-8013: Return an error string suitable for use in a form, or otherwise, describing an error when an
	 * input is not in any of the rbMulti ranges.
	 * @param rbMulti
	 */
	rbMultiRangeErrorString(name: string, ranges: { min: number, max: number }[], isHex: boolean): string {
		// We split the work into two cases: one range in the ranges list and more than one. In one case
		// we use the single min/max range error string. In the other, we indicate the value must be in
		// one of the several ranges.
		let message: string;
		if (ranges.length > 1) {
			// Multiple ranges.
			const rangesString = this.rbMultiRangeBuildRangesString(ranges, isHex);
			message = this.translate.instant('VALIDATION.RANGE_INVALID_MULTI', {
					name: name,
					ranges: rangesString
				}
			);
		} else {
			message = this.translate.instant('VALIDATION.RANGE_INVALID', {
					name: name,
					min: this.rbMultiRangeBuildValueHex(ranges[0].min, isHex),
					max: this.rbMultiRangeBuildValueHex(ranges[0].max, isHex)
				}
			);
		}

		return message;
	}

	rbMultiRangeValidation(ranges: { min: number, max: number }[], isHex = false): ValidatorFn {
		return (c: AbstractControl): ValidationErrors | null => {
			if (c.value == null) { return null; }

			let numberValue: number;
			if (typeof (c.value) === 'number') {
				numberValue = c.value;
			} else if (typeof (c.value) === 'string') {

				// Zero length strings cause validation error
				if (c.value.length === 0) {
					return { rbMultiRange: { ranges: ranges, isHex: isHex } };
				}

				// Substitute '.' for any non-digits (except leading negative sign) then convert to a number
				const stringValue = isHex ? c.value : c.value.replace(/(?!\-)\D/g, '.');
				numberValue = isHex ? parseInt(stringValue, 16) : parseFloat(stringValue);
			} else {
				return null;
			}

			const valid = ranges.some((r) => (r.min != null && numberValue >= r.min) && (r.max != null && numberValue <= r.max));
			if (!valid) {
				// Value was not in any of the ranges.
				return { rbMultiRange: { ranges: ranges, isHex: isHex } };
			}
			return null;
		};
	}

	rbPatternValidation(pattern: string | RegExp, checkZeroLength: boolean = false): ValidatorFn {
		return (c: AbstractControl): ValidationErrors | null => {
			if (c.value == null || (checkZeroLength && c.value.length === 0)) { return null; }

			const re = new RegExp(pattern);
			if (!re.test(c.value)) {
				return { rbPattern: { pattern: pattern } };
			}
			return null;
		};
	}

	rbPhoneValidation(minLength: number, maxLength: number, maxTotalLength: number): ValidatorFn {
		return (c: AbstractControl): ValidationErrors | null => {
			if (c.value == null || c.value.length === 0) { return null; }
			const totalLength = c.value.length;
			const length = c.value.replace(/\D/g, '').length;
			if (length < minLength || length > maxLength || totalLength > maxTotalLength) {
				return { rbPhone: true };
			}
			return null;
		};
	}

	rbEqualValidation(compareControlName: string) {
		let comparisonControl: AbstractControl;

		return function (c: AbstractControl) {
			if (c.value == null || c.value.length === 0) { return null; }

			// Add a valueChanges watcher for the "other" control, too.
			if (!comparisonControl) {
				comparisonControl = c.parent.get(compareControlName) as AbstractControl;
				comparisonControl.valueChanges.pipe(debounceTime(RbConstants.Form.DEBOUNCE_TIME_MS)).subscribe(() => c.updateValueAndValidity());
			}

			if (c.value !== comparisonControl.value) return { rbEqual: true };
			return null;
		};
	}

	rbArcValidation(arc: number, isAdjustable: boolean, required = true): ValidatorFn {
		return (c: AbstractControl): ValidationErrors | null => {
			if (c.value == null) { return null; }

			// allow not required
			if (!required && !c.value) {
				return null;
			}

			const numberValue = +(c.value);
			if (isAdjustable === false) {
				if (numberValue === arc) return null;
				return { rbArc: { arc: arc, isAdjustable: isAdjustable, value: numberValue } };
			}

			// Check 30-345
			if (numberValue >= RbConstants.Form.ARC_VALIDATION.minAdjustable && numberValue <= RbConstants.Form.ARC_VALIDATION.maxIntermediate) return null;

			if (arc == null) {
				if (numberValue >= RbConstants.Form.ARC_VALIDATION.minAdjustable && numberValue <= RbConstants.Form.ARC_VALIDATION.maxAdjustable) return null;
				return { rbArc: { arc: arc, isAdjustable: isAdjustable, value: numberValue } };
			}

			if (arc === RbConstants.Form.ARC_VALIDATION.midAdjustable || arc === RbConstants.Form.ARC_VALIDATION.maxAdjustable) {
				// 360 is also allowed
				if (numberValue === RbConstants.Form.ARC_VALIDATION.maxAdjustable) return null;
				return { rbArc: { arc: arc, isAdjustable: isAdjustable, value: numberValue } };
			}

			// Out of range
			return { rbArc: { arc: arc, isAdjustable: isAdjustable, value: numberValue } };
		};
	}

	rbUniqueNameValidation(nameType: string, validationParams: any = {}): AsyncValidatorFn {
		return (c: AbstractControl): Observable<ValidationErrors | null> => {
			if (c.pristine || c.value == null || c.value.length === 0 || validationParams['previousValue'] === c.value) { return of(null); }
			validationParams['previousValue'] = c.value; // To avoid unnecessary validation when onBlur occurs on number input controls

			return this.getUniqueNameObservable(nameType, c.value, validationParams);
		};
	}

	rbPasswordValidation(): ValidatorFn {
		return (c: AbstractControl) => {
			if (c.pristine) { return of(null); }
			let rbPassword
			if (!(c.value && c.value.match(RbConstants.Form.USER_PASSWORD_VALIDATION_REGEX.containsNumber))) {
				rbPassword = { ...rbPassword, isContainsNumber: false }
			}
			if (!(c.value && c.value.match(RbConstants.Form.USER_PASSWORD_VALIDATION_REGEX.containsLowerCase))) {
				rbPassword = { ...rbPassword, isContainsLowerCase: false }
			}
			if (!(c.value && c.value.match(RbConstants.Form.USER_PASSWORD_VALIDATION_REGEX.containsUpperCase))) {
				rbPassword = { ...rbPassword, isContainsUpperCase: false }
			}
			if (!(c.value && c.value.match(RbConstants.Form.USER_PASSWORD_VALIDATION_REGEX.containsSpecialCharacter))) {
				rbPassword = { ...rbPassword, isContainsSpecialCharacter: false }
			}
			if (rbPassword) {
				return rbPassword;
			} else {
				return null;
			}
		};
	}

	checkUniqueName(nameType: string, value: any, validationParams: any, messageLabel: string): Observable<string> {
		return this.getUniqueNameObservable(nameType, value, validationParams).pipe(map(result => {
			return result == null ? null : this.translate.instant(this.validationMessages.rbUniqueName)
				.replace('{{name}}', messageLabel);
		}));
	}

	private getUniqueNameObservable(nameType: string, value: any, validationParams: any): Observable<any> {
		switch (nameType) {
			case RbConstants.Form.UNIQUE_NAMES.weatherSource:
				return this.weatherService.getNameUniqueness(value, validationParams.itemId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.flowElement:
				return this.floManagerService.getNameUniqueness(validationParams.role, value, validationParams.itemId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.site:
				return this.siteService.getNameUniqueness(value, validationParams.itemId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.controller:
				return this.controllerService.getNameUniqueness(value, validationParams.itemId, validationParams.siteId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.user:
				return this.userService.getNameUniqueness(value, validationParams.itemId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.email:
				return this.userService.getEmailUniqueness(value, validationParams.itemId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.area:
				return this.areaService.getNameUniqueness(validationParams.areaLevel, validationParams.itemId, value, validationParams.siteId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.subarea:
				return this.areaService.getSubareaNameUniqueness(validationParams.itemId, value, validationParams.areaId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.areaNumber:
				return this.areaService.getNumberUniqueness(validationParams.areaLevel, validationParams.itemId, value, validationParams.siteId,
					validationParams.isGolfArea)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.areaShortName:
				return this.areaService.getShortNameUniqueness(validationParams.areaLevel, validationParams.itemId, value, validationParams.siteId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.stationName:
				return this.stationService.getStationNameUniqueness(value, validationParams.itemId, validationParams.controllerId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.selectedSensor:
				return this.sensorService.getNameUniqueness(value, validationParams.itemId, validationParams.controllerId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.program:
				return this.programService.getNameUniqueness(value, validationParams.controllerId, validationParams.programGroupId, validationParams.itemId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.programNumber:
				return this.programService.getNumberUniqueness(value, validationParams.programGroupId, validationParams.itemId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.programGroup:
				return this.programGroupService.getNameUniqueness(value, validationParams.itemId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.programGroupNumber:
				return this.programGroupService.getNumberUniqueness(value, validationParams.itemId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.stationAddress:
				const address = RbUtils.Stations.addressFromString(value, validationParams.type).toString();
				return this.stationService.getAddressUniqueness(address, validationParams.itemId, validationParams.controllerId, validationParams.groupNumber)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.sensorAddress:
				const addressAsBaseTen = RbUtils.Stations.addressFromString(value, validationParams.interfaceType).toString();
				return this.sensorService.getAddressUniqueness(addressAsBaseTen, validationParams.itemId, validationParams.controllerId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.sensorName:
				return this.sensorService.getNameUniqueness(value, validationParams.itemId, validationParams.controllerId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.clientAddress:
				return this.controllerService.getClientAddressUniqueness(value, validationParams.itemId, validationParams.parentId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbUniqueName: true })));
			case RbConstants.Form.UNIQUE_NAMES.pinNumber:
				return this.pinCodeService.getPinUniqueness(value, validationParams.pinId)
					.pipe(map(res => FormValidationService.checkUniquenessResponse(res)), catchError(() => of({ rbPin: true })));
			default:
				return of(null);
		}
	}

	/**
	 * @summary setupValidation prepares a given formGroup item to be validated and, when the validation fails, to display
	 * a suitable error message about the failing field.
	 */
	setupValidation(component: any, form: FormGroup, controlName: string, messageLabel: string = null, messageArrayIndex: number = null) {
		if (messageLabel == null) { messageLabel = controlName; }
		const control = form.get(controlName);
		control.valueChanges.pipe(debounceTime(RbConstants.Form.DEBOUNCE_TIME_MS)).subscribe(() => {
			if (messageArrayIndex != null) {
				component[controlName + 'ErrorMessage'][messageArrayIndex] = this.getValidationMessage(control, controlName, messageLabel);
			} else {
				component[controlName + 'ErrorMessage'] = this.getValidationMessage(control, controlName, messageLabel);
			}
			if (controlName === 'Password') {
				component[controlName + 'ValidationMessages'] = this.getPasswordValidationMessages(control, controlName, messageLabel);
			}
		});
	}

	getValidationMessageForOneCase(control: AbstractControl, messageLabel: string) {
		if (control.errors == null || (!control.dirty && !control.touched )) { return null; }

		let message;
		const key = Object.keys(control.errors)[0];
		if (key === 'rbEqual' || key === 'rbPattern' || key === 'rbPhone' || key === 'rbPin' || key === 'rbNoWhitespace') {
			message = this.translate.instant(this.validationMessages[key])
				.replace('{{name}}', messageLabel);
		} else if (key === 'rbMinMax') {
			if (control.errors[key].min == null) {
				message = this.translate.instant(this.validationMessages.max)
					.replace('{{name}}', messageLabel)
					.replace('{{length}}', RbUtils.Common.roundToPrecision(control.errors[key].max, control.errors[key].errorStringPrecision, false));
			} else if (control.errors[key].max == null) {
				message = this.translate.instant(this.validationMessages.min)
					.replace('{{name}}', messageLabel)
					.replace('{{length}}', RbUtils.Common.roundToPrecision(control.errors[key].min, control.errors[key].errorStringPrecision, true));
			} else {
				message = this.translate.instant(this.validationMessages[key])
					.replace('{{name}}', messageLabel)
					.replace('{{min}}', control.errors[key].isHex ? control.errors[key].min.toString(16).toUpperCase() :
						RbUtils.Common.roundToPrecision(control.errors[key].min, control.errors[key].errorStringPrecision, true))
					.replace('{{max}}', control.errors[key].isHex ? control.errors[key].max.toString(16).toUpperCase() :
						RbUtils.Common.roundToPrecision(control.errors[key].max, control.errors[key].errorStringPrecision, false));
			}
		} else if (key === 'rbMultiRange') {
			message = this.rbMultiRangeErrorString(messageLabel, control.errors[key].ranges, control.errors[key].isHex);
		} else if (key === 'rbArc') {
			if (control.errors[key].isAdjustable === true) {
				if (control.errors[key].arc === 360) {
					message = this.translate.instant(this.validationMessages.rbArcRangeOrValue);
				} else {
					message = this.translate.instant(this.validationMessages.rbArcRange);
				}
			} else if (control.errors[key].isAdjustable == null) {
				message = this.translate.instant(this.validationMessages.rbArcFullRange);
			} else {
				message = this.translate.instant(this.validationMessages.rbArc);
			}
		} else if (key === 'rbGreaterThanOrEqual') {
			const error = control.errors[key];
			message = this.translate.instant(this.validationMessages[key], { control1: error.control1, control2: error.control2 });
		} else if (this.validationMessages[key] != null) {
			message = this.translate.instant(this.validationMessages[key]).replace('{{name}}', messageLabel);
			if (key === 'minlength' || key === 'maxlength') {
				message = message.replace('{{length}}', control.errors[key].requiredLength);
			}
			if (key === 'min') {
				message = message.replace('{{length}}', control.errors[key].min);
			}
			if (key === 'max') {
				message = message.replace('{{length}}', control.errors[key].max);
			}
		}
		return message;
	}
	// noinspection JSUnusedLocalSymbols
	private getValidationMessage(control: AbstractControl, controlName: string, messageLabel: string) {
		if (control.errors == null || (!control.dirty && !control.touched && control.value == null)) { return null; }

		return Object.keys(control.errors).map(key => {

			// We need to do special substitution into the error message string based on the type of validation we are doing.
			let message;
			if (key === 'rbEqual' || key === 'rbPattern' || key === 'rbPhone' || key === 'rbPin' || key === 'rbNoWhitespace') {
				message = this.translate.instant(this.validationMessages[key])
					.replace('{{name}}', messageLabel);
			} else if (key === 'rbMinMax') {
				if (control.errors[key].min == null) {
					message = this.translate.instant(this.validationMessages.max)
						.replace('{{name}}', messageLabel)
						.replace('{{length}}', RbUtils.Common.roundToPrecision(control.errors[key].max, control.errors[key].errorStringPrecision, false));
				} else if (control.errors[key].max == null) {
					message = this.translate.instant(this.validationMessages.min)
						.replace('{{name}}', messageLabel)
						.replace('{{length}}', RbUtils.Common.roundToPrecision(control.errors[key].min, control.errors[key].errorStringPrecision, true));
				} else {
					message = this.translate.instant(this.validationMessages[key])
						.replace('{{name}}', messageLabel)
						.replace('{{min}}', control.errors[key].isHex ? control.errors[key].min.toString(16).toUpperCase() :
							RbUtils.Common.roundToPrecision(control.errors[key].min, control.errors[key].errorStringPrecision, true))
						.replace('{{max}}', control.errors[key].isHex ? control.errors[key].max.toString(16).toUpperCase() :
							RbUtils.Common.roundToPrecision(control.errors[key].max, control.errors[key].errorStringPrecision, false));
				}
			} else if (key === 'rbMultiRange') {
				message = this.rbMultiRangeErrorString(messageLabel, control.errors[key].ranges, control.errors[key].isHex);
			} else if (key === 'rbArc') {
				if (control.errors[key].isAdjustable === true) {
					if (control.errors[key].arc === 360) {
						message = this.translate.instant(this.validationMessages.rbArcRangeOrValue);
					} else {
						message = this.translate.instant(this.validationMessages.rbArcRange);
					}
				} else if (control.errors[key].isAdjustable == null) {
					message = this.translate.instant(this.validationMessages.rbArcFullRange);
				} else {
					message = this.translate.instant(this.validationMessages.rbArc);
				}
			} else if (key === 'rbGreaterThanOrEqual') {
				const error = control.errors[key];
				message = this.translate.instant(this.validationMessages[key], { control1: error.control1, control2: error.control2 });
			} else if (this.validationMessages[key] != null) {
				message = this.translate.instant(this.validationMessages[key]).replace('{{name}}', messageLabel);
				if (key === 'minlength' || key === 'maxlength') {
					message = message.replace('{{length}}', control.errors[key].requiredLength);
				}
				if (key === 'min') {
					message = message.replace('{{length}}', control.errors[key].min);
				}
				if (key === 'max') {
					message = message.replace('{{length}}', control.errors[key].max);
				}
			}
			return message;
		}).join(' ');
	}

	private getPasswordValidationMessages(control: AbstractControl, controlName: string, messageLabel: string) {
		if (!control.dirty && !control.touched) { return null; }
		let passwordValidation = {
			required: false,
			minlength: null,
			isContainsNumber: true,
			isContainsLowerCase: true,
			isContainsUpperCase: true,
			isContainsSpecialCharacter: true
		}
		if (!!control.errors) {
			passwordValidation = { ...passwordValidation, ...control.errors }
		}
		return [{
			message: this.translate.instant('VALIDATION.CONTAINS_LOWERCASE_UPPERCASE_LETTERS'),
			valid: passwordValidation.isContainsLowerCase && passwordValidation.isContainsUpperCase
		},
		{ message: this.translate.instant('VALIDATION.CONTAINS_NUMBER'), valid: passwordValidation.isContainsNumber },
		{ message: this.translate.instant('VALIDATION.CONTAINS_SYMPOL'), valid: passwordValidation.isContainsSpecialCharacter },
		{
			message: this.translate.instant('VALIDATION.MIN_LENGTH_SHORT').replace('{{length}}', RbConstants.Form.USER_PASSWORD_LENGTH_RANGE.min),
			valid: !passwordValidation.minlength && !passwordValidation.required
		}]
	}
}

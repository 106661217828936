import { Component, EventEmitter, Input, Output, } from '@angular/core';

@Component({
	selector: 'rb-fab-button',
	templateUrl: './fab-button.component.html',
	styleUrls: ['./fab-button.component.scss'],
})
export class FabButtonComponent {

	@Input() buttonText = '';
	@Input() disabled = false;

	@Output() fabButtonClicked = new EventEmitter();

	get showIcon() {
		return this.buttonText == null || this.buttonText === undefined || this.buttonText === '';
	}

	get hasButtonText() {
		return this.buttonText != null && this.buttonText !== undefined && this.buttonText !== '';
	}
}

import { AreaUiSettings } from './area-ui-settings.model';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Subarea } from './subarea.model';

export class Area {
	companyId: number;
	id: number;
	isExclusive: boolean;
	isGolfArea: boolean;
	latitude: number;
	level: number;
	name: string;
	number: number;
	shortName: string;
	siteId: number;
	subArea: Subarea[] = [];
	numberOfStations: number;
	uiSettingsJson: string;
	uiSettings = new AreaUiSettings();

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.subArea) { this.subArea = json.subArea.map(item => new Subarea(item)); }
			if (json.uiSettingsJson) { this.uiSettings = new AreaUiSettings(JSON.parse(json.uiSettingsJson)); }
		}
	}

	// NOTE: Used by Stations Column on program-group-schedule-tab component grid.
	get stationsCountForHyperlink(): string {
		return this.numberOfStations === 0
			? RbUtils.Translate.instant('STRINGS.ADD_STATION')
			: `${this.numberOfStations} ${RbUtils.Translate.instant(this.numberOfStations === 1 ? 'STRINGS.STATION_LOWERCASE' : 'STRINGS.STATIONS_LOWERCASE')}`;
	}

	static createDefault(): Area {
		const area = new Area();
		return area;
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="!isQueueCommandsGrid && program" class="program-mobile-cell">
	<div class="program-name"><span class="ellipsis-text">{{program.name}}</span></div>
	<div class ="site"><span class="ellipsis-text">{{program.siteName}}</span></div>

	<div class="stations">
		<span class="label">{{'STRINGS.STATIONS' | translate}}:</span>
		<span class="value">{{program.numberOfProgramSteps}}</span>
	</div>

	<div class="status">
		<span class="label">{{'STRINGS.STATUS' | translate}}:</span>
		<span class="value" [ngClass]="{'station-status-running': isRunning}">{{ statusString }}</span>
	</div>

	<div class="start">
		<span class="label">{{'STRINGS.START' | translate}}:</span>
<!--		<span class="value">{{program.startTimeGridCell}}</span>-->
		<span class="value">{{startDisplayTime}}</span>
		<span *ngIf="showArrows" class="arrows">
			<mat-icon (click)="onSelectPrevious($event)" [class.disabled]='selectedStartTime === 0'>arrow_upward</mat-icon>
			<mat-icon (click)="onSelectNext($event)" [class.disabled]='selectedStartTime >= startTimes.length - 1'>arrow_downward</mat-icon>
		</span>
	</div>

	<div *ngIf="showArrows" class="end">
		<span class="label">{{'STRINGS.PROJECTED_END' | translate}}:</span>
		<span class="value">{{endDisplayTime}}</span>
	</div>

	<div class="water-days">
		<span class="label">{{'STRINGS.WATER_DAYS' | translate}}:</span>
		<span class="value ellipsis-text">{{program.waterCycle}}</span>
	</div>

	<div class="adjust">
		<span class="label">{{'STRINGS.ADJUST' | translate}}:</span>
		<span class="value">{{adjust}}</span>
	</div>
</div>

<div *ngIf="isQueueCommandsGrid && program" class="program-mobile-cell">
	<div class="program-name"><span class="ellipsis-text">{{program.shortName}} - {{program.name}}</span></div>
</div>

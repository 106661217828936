<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="flex justify-between station-mobile-cell" id="generic-info">
	<div *ngIf="!isGolfSite" class="ellipsis-text"><b>{{station?.terminal}}. {{station?.name}}</b></div>
	<div *ngIf="isGolfSite" class="flex">
		<div *ngIf="!station.suspended && (station.isConnected && station.isInterfaceConnected)" [matTooltip]="'STRINGS.CONNECTED' | translate">
			<i id="station-connected-mobile" class="material-icons icon-mobile-station-connected-style icon-station_connected"></i>
		</div>
		<div *ngIf="!station.suspended && !(station.isConnected && station.isInterfaceConnected)" [matTooltip]="'SPECIAL_MSG.NOT_CONNECTED' | translate">
			<i id="station-disconnected-mobile" class="material-icons icon-mobile-station-disconnected-style icon-station_disconnected"></i>
		</div>
		<div *ngIf="station.suspended"  [matTooltip]="'STRINGS.SUSPENDED' | translate">
			<span><i id="station-suspended-mobile" class="material-icons icon-mobile-station-suspended-style icon-station_suspended"></i></span>
		</div>
		<div>
			<b>{{station?.locationString}}{{station?.locationString ? ' - ': ''}}{{station?.name}}</b>
		</div>
	</div>
	<div *ngIf="!isStationListItem">{{station?.addressString}}</div>
	<div *ngIf="!isGolfSite" class="ellipsis-text">{{satelliteName}}</div>
</div>

<div *ngIf="!isQueueCommandsGrid && isStationListItem" class="flex justify-between" id="lanscape-info">
	<div>{{landscape}}</div>
	<div>
		<span class="fr-cell-icon"><i [class]="flowRateIcon"></i></span>
		<span>{{flowRateString}}</span>
		<span class="fr-cell-units">{{flowRateUnits}}</span>
	</div>
</div>


<div *ngIf="!isQueueCommandsGrid" class="flex justify-between" id="status-info">
	<div [ngClass]="{'station-status-running': isRunning}">
		<span *ngIf="isActivelyCyclingOrSoaking && isCycling" class="station-status-cycling" [matTooltip]="'STRINGS.CYCLING' | translate">
			<i class="icon-water"></i>
		</span>
			<span *ngIf="isActivelyCyclingOrSoaking && isSoaking" class="station-status-soaking" [matTooltip]="'STRINGS.SOAKING' | translate">
			<i class="icon-timer"></i>
		</span>
		{{ statusString }}
	</div>
	<div *ngIf="isStationListItem">{{sprinkler}}</div>
</div>

<div *ngIf="isStationListItem" class="flex justify-between" id="assigned-programs-info" (click)="triggerAction($event)">
	<div>
		<strong>Assigned Programs: </strong>
		<span [ngClass]="{'is-clickable': isAssignedProgramsLongList}">
			{{ programsDisplay }}
		</span>
	</div>
	<div>
		<span [ngClass]="{'is-clickable': isRuntimeProgramsLongList}">{{ runtimeDisplay }}</span>
	</div>
</div>

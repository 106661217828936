import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'rb-top-banner-panel',
	templateUrl: './top-banner-panel.component.html',
	styleUrls: ['./top-banner-panel.component.scss']
})
export class TopBannerPanelComponent implements OnInit {
	@Input() color = 'white';

	// ------------------------------------------------------------------------
	// showPanel (begin) - Two way bound property
	// ------------------------------------------------------------------------
	private _showPanel = false;
	@Input() set showPanel(value: boolean) {

		this.isPanelActive = value;
		setTimeout(() => {
			this._showPanel = value;
			this.showPanelChange.emit(value);
		}, 500);
	}

	get showPanel(): boolean {
		return this._showPanel;
	}

	@Output() onClose = new EventEmitter<boolean>();
	@Output() showPanelChange = new EventEmitter<boolean>();

	// ------------------------------------------------------------------------
	// showPanel (end) - Two way bound property
	// ------------------------------------------------------------------------

	isPanelActive = false;

	// =========================================================================================================================================================
	// C'tor and Init
	// =========================================================================================================================================================

	constructor() { }

	ngOnInit() {
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	onHidePanel() {
		this.showPanel = false;
		this.onClose.emit(true);
	}
}

import { Component, OnInit } from '@angular/core';
import { IFilterParams, ValueGetterFunc, ValueGetterParams } from 'ag-grid-community';
import { IFilterAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'rb-checkbox-table-flag-filter',
	templateUrl: './checkbox-table-flag-filter.component.html',
	styleUrls: ['./checkbox-table-flag-filter.component.scss']
})
export class CheckboxTableFlagFilterComponent implements OnInit, IFilterAngularComp {

	private params: IFilterParams;
	private valueGetter: ValueGetterFunc;

	choices: any[] = [];
	selections: boolean[] = [];

	constructor() { }

	ngOnInit() {
	}

	agInit(params: IFilterParams): void {
		this.params = params;
		this.valueGetter = params.valueGetter;
		this.choices = params['choicesCallback'](this);
		this.clearSelections();
	}

	doesFilterPass(params: ValueGetterParams): boolean {
		const value = this.valueGetter(params);

		for (let i = 0; i < this.choices.length; i++) {
			// eslint-disable-next-line no-bitwise
			const flag = this.choices[i].value & value;
			if (flag === 0 || !this.selections[i]) { continue; }

			return true;
		}

		return false;
	}

	getModel(): any {
	}

	isFilterActive(): boolean {
		return this.selections.some(s => s);
	}

	setModel(model: any): void {
	}

	choiceSelectChanged(choice: any, checked: boolean) {
		this.params.filterChangedCallback();
	}

	clearSelections() {
		this.selections = this.choices.map(() => false);
		this.params.filterChangedCallback();
	}

	// ag-grid documentation indicates this should be called on IFilter
	// but it is not. This needs to be manually called by component changing rows
	onNewRowsLoaded(): void {
		this.choices = this.params['choicesCallback'](this);
	}
}

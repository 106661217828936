<!--suppress TypeScriptUnresolvedVariable, AngularUndefinedBinding -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="duration-content-container">
	<mat-dialog-content>
		<form data-qaid="duration-form" #form="ngForm">
			<div class="header" *ngIf="showHeader" >
				<p class="pl3 pt3 f5 mr0 mv0" [ngClass]="{'no-duration-header' : !showDurationHeader}">{{title}}</p>
				<div *ngIf="showDurationHeader" [ngClass]="{'rb-w-6-rem' : hideHours, 'rb-w-10-rem' : !hideHours}" class="duration-header-hours f2">
					<input *ngIf="!hideHours" class="headerHours" matInput name="headerHours" [ngModel]="hours" readonly>
					<span matSuffix *ngIf="!hideHours || hideMinutes">:</span>
					<input *ngIf="!hideMinutes" matInput name="headerMinutes" [ngModel]="padNumber(minutes)" readonly>
					<span matSuffix *ngIf="!hideSeconds">:</span>
					<input *ngIf="!hideSeconds" matInput name="headerSeconds" [ngModel]="padNumber(seconds)" readonly>
				</div>
			</div>
	
			<mat-tab-group dynamicHeight [disablePagination]="true" *ngIf="showTabs && (station || stations) else runtimeInputs" (selectedTabChange)="selectedTabChange($event)">

				<!-- RunTime -->
				<mat-tab>

					<ng-template mat-tab-label>
						<div class="flex flex-column items-center pv1">
							<mat-icon>av_timer</mat-icon>
							<div class="text">{{(programStep?.isEtBasedProgram ? 'STRINGS.FORECASTED_RUN_TIME' : 'STRINGS.RUN_TIME') | translate}}</div>
						</div>
					</ng-template>

					<ng-container *ngTemplateOutlet="runtimeInputs"></ng-container>

				</mat-tab>

				<!-- Rotations -->
				<mat-tab *ngIf="isGolfSite">

					<ng-template mat-tab-label>
						<div class="flex flex-column items-center pv1">
							<mat-icon>rotate_right</mat-icon>
							<div class="text">{{ 'STRINGS.PASSES' | translate }}</div>
						</div>
					</ng-template>

					<ng-container *ngTemplateOutlet="rotationInputs"></ng-container>

				</mat-tab>

				<!-- Application -->
				<mat-tab *ngIf="isGolfSite">
					<ng-template mat-tab-label>
						<div class="flex flex-column items-center">
							<mat-icon class="icon-inch-value volume-icon"></mat-icon>
							<div class="text">{{'STRINGS.APPLICATION' | translate}}</div>
						</div>
					</ng-template>

					<ng-container *ngTemplateOutlet="applicationInputs"></ng-container>

				</mat-tab>

			</mat-tab-group>

		</form>
	</mat-dialog-content>
	
	<div *ngIf="showButtons" class="btn-group-container">
		<mat-dialog-actions class="flex justify-end">
			<button mat-button (click)="cancel()" data-qaid="dialog-cancel-button">{{'STRINGS.CANCEL' | translate }}</button>
			<button mat-button (click)="submit()" color="primary" data-qaid="dialog-submit-button" [disabled]="form.invalid || !isDurationSet">{{submitLabel}}</button>
		</mat-dialog-actions>
	</div>
</div>

<!-- ---------------------- -->
<!-- Runtime Input Template -->
<!-- ---------------------- -->

<ng-template #runtimeInputs>
	
	<div *ngIf="showSlider" class="no-select runtime-inputs-container">
		<!-- Days -->
		<div *ngIf="!hideDays" class="duration-section">
			<div class="label">{{'STRINGS.DAYS' | translate}} </div>
			<div class="duration-group">
				<!-- Field Tech User -->
				<button *ngIf="isReadOnly" [disabled]="days === RbConstants.Form.DURATION_DAYS.min || isReadOnly === true"
						(mousedown)="onButtonMouseDown('days', -1, -1, onDaysChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('days', -1, -1, onDaysChanged)"  (touchend)="onTouchEnd()">
					<i class="rb-icon icon-minus"></i>
				</button>
				<div *ngIf="isReadOnly" class="input-holder">
					<input class="hide-spinner" [disabled]="true" readonly [placeholder]="'STRINGS.DAY' | translate" [min]="0" [max]="maxDays" name="daysInput" type="number"
						   [disabled]="days === RbConstants.Form.DURATION_DAYS.min && days === maxDays"
						   [ngModel]="daysString" (ngModelChange)="onDaysChanged($event, '')"
						   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						   (focus)="$any($event.target).select()" [@scrollNumber]="daysScrollState" (@scrollNumber.done)="scrollDone()" />
				</div>
				<button *ngIf="isReadOnly" [disabled]="days === maxDays || isReadOnly === true"
						(mousedown)="onButtonMouseDown('days', 1, 1, onDaysChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('days', 1, 1, onDaysChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-plus"></i>
				</button>
				<!-- Normal User -->
				<button *ngIf="!isReadOnly" [disabled]="days === RbConstants.Form.DURATION_DAYS.min"
						(mousedown)="onButtonMouseDown('days', -1, -1, onDaysChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('days', -1, -1, onDaysChanged)"  (touchend)="onTouchEnd()">
					<i class="rb-icon icon-minus"></i>
				</button>
				<div *ngIf="!isReadOnly" class="input-holder">
					<input class="hide-spinner" [placeholder]="'STRINGS.DAY' | translate" [min]="0" [max]="maxDays" name="daysInput" type="number"
						   [disabled]="days === RbConstants.Form.DURATION_DAYS.min && days === maxDays"
						   [ngModel]="daysString" (ngModelChange)="onDaysChanged($event, '')"
						   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						   (focus)="$any($event.target).select()" [@scrollNumber]="daysScrollState" (@scrollNumber.done)="scrollDone()" />
				</div>
				<button *ngIf="!isReadOnly" [disabled]="days === maxDays"
						(mousedown)="onButtonMouseDown('days', 1, 1, onDaysChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('days', 1, 1, onDaysChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-plus"></i>
				</button>
			</div>
		</div>

		<!-- Hours -->
		<div *ngIf="!hideHours" class="duration-section">
			<div class="label">{{'STRINGS.HOURS' | translate}} </div>
			<div class="duration-group">
				<!-- Field Tech User -->
				<button *ngIf="isReadOnly" [disabled]="hours === RbConstants.Form.DURATION_HOURS.min || isReadOnly === true"
						(mousedown)="onButtonMouseDown('hours', -1, -1, onHoursChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('hours', -1, -1, onHoursChanged)"  (touchend)="onTouchEnd()">
					<i class="rb-icon icon-minus"></i>
				</button>
				<div *ngIf="isReadOnly" class="input-holder">
					<input class="hide-spinner" [disabled]="true" readonly [placeholder]="'STRINGS.HR' | translate" [min]="0" [max]="maxHours" name="hoursInput" type="number"
						   [disabled]="hours === RbConstants.Form.DURATION_HOURS.min && hours === maxHours"
						   [ngModel]="hoursString" (ngModelChange)="onHoursChanged($event, '')"
						   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						   (focus)="$any($event.target).select()" [@scrollNumber]="hoursScrollState" (@scrollNumber.done)="scrollDone()" />
				</div>
				<button *ngIf="isReadOnly" [disabled]="(!hideDays && (days === maxDays)) || hours === maxHours || isReadOnly === true"
						(mousedown)="onButtonMouseDown('hours', 1, 1, onHoursChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('hours', 1, 1, onHoursChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-plus"></i>
				</button>
				<!-- Normal User -->
				<button *ngIf="!isReadOnly" [disabled]="hours === RbConstants.Form.DURATION_HOURS.min"
						(mousedown)="onButtonMouseDown('hours', -1, -1, onHoursChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('hours', -1, -1, onHoursChanged)"  (touchend)="onTouchEnd()">
					<i class="rb-icon icon-minus"></i>
				</button>
				<div *ngIf="!isReadOnly" class="input-holder">
					<input class="hide-spinner" [placeholder]="'STRINGS.HR' | translate" [min]="0" [max]="maxHours" name="hoursInput" type="number"
						   [disabled]="hours === RbConstants.Form.DURATION_HOURS.min && hours === maxHours"
						   [ngModel]="hoursString" (ngModelChange)="onHoursChanged($event, '')"
						   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						   (focus)="$any($event.target).select()" [@scrollNumber]="hoursScrollState" (@scrollNumber.done)="scrollDone()" />
				</div>
				<button *ngIf="!isReadOnly" [disabled]="(!hideDays && (days === maxDays)) || hours === maxHours"
						(mousedown)="onButtonMouseDown('hours', 1, 1, onHoursChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('hours', 1, 1, onHoursChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-plus"></i>
				</button>
			</div>
		</div>

		<!-- Minutes -->
		<div *ngIf="!hideMinutes" class="duration-section">
			<div class="label">{{'STRINGS.MINUTES' | translate}} </div>
			<div class="duration-group">
				<!-- Field Tech User -->
				<button *ngIf="isReadOnly" [disabled]="minutes === minMinutes || isReadOnly === true"
						(mousedown)="onButtonMouseDown('minutes', -1, -1, onMinutesChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('minutes', -1, -1, onMinutesChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-minus"></i>
				</button>
				<div *ngIf="isReadOnly" class="input-holder">
					<input class="hide-spinner" [disabled]="true" readonly [min]="RbConstants.Form.DURATION_MINUTES.min" [max]="maxMinutes" name="minutesInput" type="number"
						   [disabled]="minutes === RbConstants.Form.DURATION_MINUTES.min && ((!hideHours && (hours === maxHours)) || minutes === maxMinutes)"
						   [ngModel]="minutesString" (ngModelChange)="onMinutesChanged($event, '')"
						   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						   (focus)="$any($event.target).select()" [@scrollNumber]="minutesScrollState" (@scrollNumber.done)="scrollDone()" />
				</div>
				<button *ngIf="isReadOnly" [disabled]="(!hideDays && (days === maxDays)) || (!hideHours && (hours === maxHours)) || minutes === maxMinutes || isReadOnly === true"
						(mousedown)="onButtonMouseDown('minutes', 1, 1, onMinutesChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('minutes', 1, 1, onMinutesChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-plus"></i>
				</button>
				<!-- Normal User -->
				<button *ngIf="!isReadOnly" [disabled]="minutes === minMinutes"
						(mousedown)="onButtonMouseDown('minutes', -1, -1, onMinutesChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('minutes', -1, -1, onMinutesChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-minus"></i>
				</button>
				<div *ngIf="!isReadOnly" class="input-holder">
					<input class="hide-spinner" [min]="RbConstants.Form.DURATION_MINUTES.min" [max]="maxMinutes" name="minutesInput" type="number"
						   [disabled]="minutes === RbConstants.Form.DURATION_MINUTES.min && ((!hideHours && (hours === maxHours)) || minutes === maxMinutes)"
						   [ngModel]="minutesString" (ngModelChange)="onMinutesChanged($event, '')"
						   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						   (focus)="$any($event.target).select()" [@scrollNumber]="minutesScrollState" (@scrollNumber.done)="scrollDone()" />
				</div>
				<button *ngIf="!isReadOnly" [disabled]="(!hideDays && (days === maxDays)) || (!hideHours && (hours === maxHours)) || minutes === maxMinutes"
						(mousedown)="onButtonMouseDown('minutes', 1, 1, onMinutesChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('minutes', 1, 1, onMinutesChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-plus"></i>
				</button>
			</div>
		</div>

		<!-- Seconds -->
		<div *ngIf="!hideSeconds" class="duration-section">
			<div class="label">{{'STRINGS.SECONDS' | translate}} </div>
			<div class="duration-group">
				<!-- Field Tech User -->
				<button *ngIf="isReadOnly" [disabled]="seconds === RbConstants.Form.DURATION_SECONDS.min || isReadOnly === true"
						(mousedown)="onButtonMouseDown('seconds', -1, -1, onSecondsChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('seconds', -1, -1, onSecondsChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-minus"></i>
				</button>
				<div *ngIf="isReadOnly" class="input-holder">
					<input class="hide-spinner" [disabled]="true" readonly [min]="RbConstants.Form.DURATION_SECONDS.min" [max]="RbConstants.Form.DURATION_SECONDS.max" name="secondsInput" type="number"
						   [disabled]="seconds === RbConstants.Form.DURATION_SECONDS.min && (hours === maxHours || (minutes === maxMinutes && hideHours) || seconds === RbConstants.Form.DURATION_SECONDS.max)"
						   [ngModel]="secondsString" (ngModelChange)="onSecondsChanged($event, '')"
						   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						   (focus)="$any($event.target).select()" [@scrollNumber]="secondsScrollState" (@scrollNumber.done)="scrollDone()" />
				</div>
				<button *ngIf="isReadOnly" [disabled]="(!hideDays && (days === maxDays)) || hours === maxHours || (minutes === maxMinutes && hideHours) || seconds === RbConstants.Form.DURATION_SECONDS.max || isReadOnly === true"
						(mousedown)="onButtonMouseDown('seconds', 1, 1, onSecondsChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('seconds', 1, 1, onSecondsChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-plus"></i>
				</button>
				<!-- Normal User -->
				<button *ngIf="!isReadOnly" [disabled]="seconds === RbConstants.Form.DURATION_SECONDS.min"
						(mousedown)="onButtonMouseDown('seconds', -1, -1, onSecondsChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('seconds', -1, -1, onSecondsChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-minus"></i>
				</button>
				<div *ngIf="!isReadOnly" class="input-holder">
					<input class="hide-spinner" [min]="RbConstants.Form.DURATION_SECONDS.min" [max]="RbConstants.Form.DURATION_SECONDS.max" name="secondsInput" type="number"
						   [disabled]="seconds === RbConstants.Form.DURATION_SECONDS.min && (hours === maxHours || (minutes === maxMinutes && hideHours) || seconds === RbConstants.Form.DURATION_SECONDS.max)"
						   [ngModel]="secondsString" (ngModelChange)="onSecondsChanged($event, '')"
						   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						   (focus)="$any($event.target).select()" [@scrollNumber]="secondsScrollState" (@scrollNumber.done)="scrollDone()" />
				</div>
				<button *ngIf="!isReadOnly" [disabled]="(!hideDays && (days === maxDays)) || hours === maxHours || (minutes === maxMinutes && hideHours) || seconds === RbConstants.Form.DURATION_SECONDS.max"
						(mousedown)="onButtonMouseDown('seconds', 1, 1, onSecondsChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('seconds', 1, 1, onSecondsChanged)" (touchend)="onTouchEnd()">
					<i class="rb-icon icon-plus"></i>
				</button>
			</div>
		</div>
	</div>

	<div *ngIf="!showSlider" class="textWidth hr-min-sec pt3 mr4">
		<div class="hrs">
			<!-- Field Tech User -->
			<mat-form-field *ngIf="isReadOnly">
				<input matInput [disabled]="true" readonly [placeholder]="'STRINGS.HR' | translate" [min]="0" [max]="maxHours" name="hoursInput" type="number"
					   [ngModel]="hours" (ngModelChange)="onHoursChanged($event, '')"
					   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
					   (focus)="$any($event.target).select()"/>
			</mat-form-field>
			<!-- Normal User -->
			<mat-form-field *ngIf="!isReadOnly" class="hrs-input">
				<input matInput [placeholder]="'STRINGS.HR' | translate" [min]="0" [max]="maxHours" name="hoursInput" type="number"
					   [ngModel]="hours" (ngModelChange)="onHoursChanged($event, '')"
					   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
					   (focus)="$any($event.target).select()"/>
			</mat-form-field>
		</div>
		<div class="hrs">
			<!-- Field Tech User -->
			<mat-form-field *ngIf="isReadOnly">
				<input matInput [disabled]="true" readonly [placeholder]="'STRINGS.MIN' | translate" [min]="0" [max]="maxMinutes" name="minutesInput" type="number"
					   [ngModel]="minutes" (ngModelChange)="onMinutesChanged($event, '')" [disabled]="hours === maxHours"
					   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
					   (focus)="$any($event.target).select()"/>
			</mat-form-field>
			<!-- Normal User -->
			<mat-form-field *ngIf="!isReadOnly" class="hrs-input">
				<input matInput [placeholder]="'STRINGS.MIN' | translate" [min]="0" [max]="maxMinutes" name="minutesInput" type="number"
					   [ngModel]="minutes" (ngModelChange)="onMinutesChanged($event, '')" [disabled]="hours === maxHours"
					   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
					   (focus)="$any($event.target).select()"/>
			</mat-form-field>
		</div>
		<div class="hrs">
			<!-- Field Tech User -->
			<mat-form-field *ngIf="isReadOnly">
				<input matInput [disabled]="true" readonly [placeholder]="'STRINGS.SEC' | translate" name="secondsInput" type="number"
					   [disabled]="hours === maxHours || (minutes === maxMinutes && hideHours)"
					   [(ngModel)]="seconds" (ngModelChange)="onSecondsChanged($event,'')"
					   [min]="RbConstants.Form.DURATION_SECONDS.min" [max]="RbConstants.Form.DURATION_SECONDS.max"
					   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
					   (focus)="$any($event.target).select()"/>
			</mat-form-field>
			<!-- Normal User -->
			<mat-form-field *ngIf="!isReadOnly" class="hrs-input">
				<input matInput [placeholder]="'STRINGS.SEC' | translate" name="secondsInput" type="number"
					   [disabled]="hours === maxHours || (minutes === maxMinutes && hideHours)"
					   [(ngModel)]="seconds" (ngModelChange)="onSecondsChanged($event,'')"
					   [min]="RbConstants.Form.DURATION_SECONDS.min" [max]="RbConstants.Form.DURATION_SECONDS.max"
					   onkeypress='return event.charCode >= 48 && event.charCode <= 57'
					   (focus)="$any($event.target).select()"/>
			</mat-form-field>
		</div>
	</div>
</ng-template>

<!-- ----------------------- -->
<!-- Rotation Input Template -->
<!-- ----------------------- -->

<ng-template #rotationInputs>
	
	<div class="rotation-inputs-container">

		<div *ngIf="passesErrorMessage" class="not-available">
			<div class="message">{{ passesErrorMessage }}</div>

			<div class="list">
				<div *ngFor="let station of stationsWithNoArcOrRotationTimeArray">
					{{station}}
				</div>
			</div>			
		</div>

		<div class="duration-section">
			<div class="label">{{ 'STRINGS.PASSES_LABEL' | translate }}</div>
			<div class="duration-group">
				<div class="input-holder">
					<rb-group-decimal-separator
						class="fake-input"
						[ngModel]="passesField"
						[precision]="2"
						[isRequired]="true"
						(valueChanges)="passesChanges($event)"></rb-group-decimal-separator>
				</div>
			</div>
		</div>

		<div class="duration-section" *ngIf="!inBatchMode">
			<div class="label">{{ 'STRINGS.APPLICATION' | translate }} <i>({{ hintLabel }})</i></div>
			<div class="duration-group">
				<div class="input-holder">
					<span class="fake-input read-only">{{ volumeForRotations }}</span>
				</div>
			</div>
		</div>

		<div class="duration-section" *ngIf="!inBatchMode">
			<div class="label">{{ 'STRINGS.RUN_TIME' | translate }}</div>
			<div class="duration-group">
				<div class="input-holder">
					<span class="fake-input read-only">{{ readOnlyRuntimeForRotations }}</span>
				</div>
			</div>
		</div>

		<div *ngIf="inBatchMode && !passesErrorMessage" class="mt3 f6 batchModeMessage">
			{{'STRINGS.DURATION_DIALOG_BATCH_MODE_MESSAGE' | translate }}</div>

	</div>	

</ng-template>

<!-- ----------------------- -->
<!-- Application Input Template -->
<!-- ----------------------- -->

<ng-template #applicationInputs>
	
	<div class="application-inputs-container">

		<div *ngIf="volumeErrorMessage" class="not-available">	

			<div class="message">{{ volumeErrorMessage }}</div>

			<div class="list">
				<div *ngFor="let station of stationsWithNoPrecRateArray">
					{{station}}
				</div>
			</div>		
		
		</div>

		<div class="duration-section">
			<div class="label">{{ 'STRINGS.DEPTH' | translate }}</div>
			<div class="duration-group">
				<div class="input-holder">
					<rb-units-of-measure-length
						class="fake-input"
						[ngModel]="volumeField"
						[precision]="precision"
						[isRequired]="true"
						[lengthFromUnit]="RbEnums.Common.LengthUnit.Inch"
						[lengthToUnit]="convertLengthType"
						[isDisabled]="isApplicationDisabled"
						(ngModelChange)="lengthChange($event)">
					</rb-units-of-measure-length>
				</div>
			</div>
		</div>

		<div class="duration-section" *ngIf="!inBatchMode">
			<div class="label">{{ 'STRINGS.RUN_TIME' | translate }}</div>
			<div class="duration-group">
				<div class="input-holder">
					<span class="fake-input read-only">{{ readOnlyRuntimeForVolume }}</span>
				</div>
			</div>
		</div>

		<div class="duration-section" *ngIf="!inBatchMode">
			<div class="label">{{ 'STRINGS.PASSES' | translate }}</div>
			<div class="duration-group">
				<div class="input-holder">
					<span class="fake-input read-only">{{ passesForVolume }}</span>
				</div>
			</div>
		</div>

		<div *ngIf="inBatchMode && !volumeErrorMessage" class="mt3 f6 batchModeMessage">
			{{'STRINGS.DURATION_DIALOG_BATCH_MODE_MESSAGE' | translate }}</div>

	</div>	

</ng-template>
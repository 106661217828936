import { CdkDragDrop, CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { ContextMenuInfoTypes } from '../../../common/models/context-menu-info-types';
import { ControllerListItem } from '../../../api/controllers/models/controller-list-item.model';
import { ControllerManagerService } from '../../../api/controllers/controller-manager.service';
import { ControllerWithMapInfoLeaflet } from '../../../common/models/controller-with-map-info-leaflet.model';
import { ConversionService } from '../../../common/services/conversion.service';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { ManualControlManagerService } from '../../../api/manual-control/manual-control-manager.service';
import { ManualControlState } from '../../../api/manual-control/models/manual-control-state.model';
import { ManualOpsManagerService } from '../../../api/manual-ops/manual-ops-manager.service';
import { MapInfoLeaflet } from '../../../common/models/map-info-leaflet.model';
import { MapLeafletService } from '../../../common/services/map-leaflet.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MessageBoxInfo } from '../global-message-box/message-box-info.model';
import { MessageBoxService } from '../../../common/services/message-box.service';
import moment from 'moment';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { StationManagerService } from '../../../api/stations/station-manager.service';
import { StationOptions } from '../../../common/models/station-options';
import { StationWithMapInfoLeaflet } from '../../../common/models/station-with-map-info-leaflet.model';
import { TranslateService } from '@ngx-translate/core';
import { UnitLabelService } from '../../../common/services/unit-label.service';

import IqNetType = RbEnums.Common.IqNetType;

/**
 * Component for map objects'context menu
 * 
 * When you click on any hole, station or area on the map, this is the menu that shows up
 */
@Component({
	selector: 'rb-map-context-menu',
	templateUrl: './map-context-menu.component.html',
	styleUrls: ['./map-context-menu.component.scss']
})
export class MapContextMenuComponent implements OnInit {

	@Input() contextMenuInfo: ContextMenuInfoTypes;
	@Input() contextMenuTrigger: MatMenuTrigger;
	@Input() isEditingAreaColor: boolean;
	@Input() isEditingFromStationAreaContextMenu: boolean;
	@Input() lastIrrigatedDateTimeFormat: string;

	@Output() contextMenuItemSelected = new EventEmitter<number>();
	@Output() closeStationContextMenu = new EventEmitter<boolean>();
	@Output() closeStationAreaContextMenu = new EventEmitter<boolean>();

	readonly noTimeString = this.translate.instant('STRINGS.NO_TIME_HMS');
	dragging = false;
	isGolfSite: boolean = false;
	cultureSettings: CultureSettings;
	isSiteMenuContext: boolean = false;
	isSensorClicked: boolean = false;
	isTextCommentsChange = false;
	isTextDescriptionsChange = false;
	isConnectingController = false;
	textComments = '';
	textDescriptions = '';
	// AccessBetaFeatures 
	accessBetaFeatures = false;
	irrigationStatus = RbEnums.Common.IrrigationStatus;

	private stationAreaMarkerInfo = { width: 80, height: 50, mapIconHotspotX: 40, mapIconHotspotY: 25, dragIconHotspotX: 15, dragIconHotspotY: 15 };
	private saveCommentsButton: HTMLButtonElement;
	private saveDescriptionsButton: HTMLButtonElement;
	private controllerId;
	private _textComments = '';
	private _textDescriptions = '';
	private _controllerType = RbEnums.Common.DeviceType.None;
	private _selectedControllerItem: ControllerListItem;

	//#region Getters and setters

	get controllers(): ControllerWithMapInfoLeaflet[] {
		return this.contextMenuInfo.mapInfo.controllers;
	}

	get stations(): StationWithMapInfoLeaflet[] {
		return this.contextMenuInfo.mapInfo.stations;
	}

	get mapInfo(): MapInfoLeaflet {
		return this.contextMenuInfo.mapInfo;
	}

	get site() {
		return this.mapInfo?.site;
	}

	get station() {
		if ("station" in this.contextMenuInfo){
			return this.contextMenuInfo.station;
		} else {
			return null;
		}
	}

	//#endregion

	constructor(
		private authManager: AuthManagerService,
		private controllerManager: ControllerManagerService,
		private conversionService: ConversionService,
		private manualControlManager: ManualControlManagerService,
		private manualOpsManager: ManualOpsManagerService,
		private messageBoxService: MessageBoxService,
		public mapService: MapLeafletService,
		private siteManager: SiteManagerService,
		private stationManager: StationManagerService,
		private translate: TranslateService,
		private unitLabelService: UnitLabelService
	) {
	}

	ngOnInit(): void {
		this.isGolfSite = this.siteManager.isGolfSite;
		if (this.contextMenuInfo) {
			this.isSiteMenuContext = "site" in this.contextMenuInfo;
			this.isSensorClicked = "sensor" in this.contextMenuInfo;
			this.initializeSaveButton();
		}

		if (!this.isGolfSite) {
			this.cultureSettings = this.authManager.userCulture;
			this.accessBetaFeatures = this.authManager.accessBetaFeatures;

			// RB-13283: IQ4 - Performance issue GetSatelliteList API in database production
			// This case which directly working popup menu for Master Valve on map and we didn't select controller in list 
			// so we need to check the selectedControllerItem object
			// If the selectedControllerItem object is different with previous controller then we also need to call api to get extra data for Master Valve  
			if (this.contextMenuInfo && "station" in this.contextMenuInfo) {
				if (this.contextMenuInfo.station.master && 
					this.controllerManager.selectedControllerItem?.id !== this.contextMenuInfo.station.satelliteId) {
					this.controllerId = this.contextMenuInfo.station.satelliteId;
					this.controllerManager.getControllerItem(this.controllerId)
						.subscribe((controllerItem: ControllerListItem) => {
							this._selectedControllerItem = controllerItem;
						});
				} else {
					this._selectedControllerItem = this.controllerManager.selectedControllerItem;
				}
			}

			// Monitor changes to the ManualControlState for the given controller.
			this.manualControlManager.manualControlStateChange
			.subscribe((manualControlState: ManualControlState) => {
				this.updateManualControlStateDisplay(manualControlState);
			});
		}
	}

	private updateManualControlStateDisplay(state: ManualControlState) {

		if (!state || state.isConnecting.error) {
			this.isConnectingController = false;
		}

		if (!state || (state.isConnecting.error && !state.isConnecting.isUpdating)) {
			return;
		}

		if (state.isConnecting.isUpdating || !state.hasReceivedConnectDataPack) {
			return;
		}

		const isConnected = state != null &&
			state.controllerId === this.controllerId &&
			!state.isConnecting.error &&
			!state.isConnecting.isUpdating &&
			state.hasReceivedConnectDataPack;
		if (isConnected) this.isConnectingController = false;
    }

	getLastRuntime(seconds) {
		return RbUtils.Stations.secondsToHhMmSs(seconds);
	}

	getStationPopupIcon(station) {
		if (this.contextMenuInfo && "station" in this.contextMenuInfo) {
			const marker = RbUtils.Stations.iconForStation(station, false, true, true, this.mapInfo.getHaloHTML(station?.id, !!station?.master));
			return {
				status: marker.options.className.replace('station-', '').replace('station', '').trim(),
				className: marker.options.className,
				html: marker.options.html
			};
		}
		return null;
	}

	isCommercialLXME(id: any) : boolean {
		const controller = this.getCommercialController(id);
		this._controllerType = controller.type;
		return this._controllerType === RbEnums.Common.DeviceType.LXME ||
			this._controllerType === RbEnums.Common.DeviceType.LXME2;
	}

	isCommercialLXD(id: any): boolean {
		const controller = this.getCommercialController(id);
		this._controllerType = controller.type;
		return this._controllerType === RbEnums.Common.DeviceType.LXD;
	}

	isCommercialLXIVM(id: any): boolean {
		const controller = this.getCommercialController(id);
		this._controllerType = controller.type;
		return this._controllerType === RbEnums.Common.DeviceType.LXIVM ||
			this._controllerType === RbEnums.Common.DeviceType.LXIVMPlus;
	}

	isMasterValveUnused(id: any, station: any): boolean {
		if (station.master &&
			station.pump === false &&
			(this.isCommercialLXME(id) || this.isCommercialLXIVM(id) || this.isCommercialLXD(id)) && 
			(station.valueType === RbEnums.Common.StationType.NormallyOpen || 
			RbEnums.Common.StationType.NormallyClosed ||
			RbEnums.Common.StationType.Unused)) 
			return true;
		return false;
	}

	showCommercialOperation(id: any, station: any): boolean {
		if (station.master && station.pump &&
			this.isCommercialLXME(id) && 
			(station.valveType === RbEnums.Common.ValveType.None || 
			station.valveType === RbEnums.Common.OperationType.Unused ||
			station.valveType === RbEnums.Common.OperationType.MasterValve ||
			station.valveType === RbEnums.Common.OperationType.Pump))
			return true;
		return false;
	}

	showCommercialIqNetSharing(id: any, station: any): boolean {
		const controller = this.getCommercialController(id);
		if (station.master && RbUtils.Common.isShowIQNetSharing(controller.type, this.accessBetaFeatures) && 
			controller.iqNetType !== RbEnums.Common.IqNetType.DirectSatellite) {
			return true;
		}
		return false;
	}

	showCommercialSolenoidsQuantity(id: any, station: any): boolean {
		if (station.master && this.isCommercialLXME(id)) {
			if ((station.pump && station.valveType === RbEnums.Common.ValveType.None) || 
				(!station.pump && station.type === RbEnums.Common.StationType.Unused)) {
				return false;
			}
			return true;
		}
			
		return false;
	}

	showCommercialFloZone(id: any, station: any): boolean {
		const controller = this.getCommercialController(id);
		this._controllerType = controller.type;
		return station.master && (this._controllerType === RbEnums.Common.DeviceType.LXD ||
			this._controllerType === RbEnums.Common.DeviceType.LXIVM ||
			this._controllerType === RbEnums.Common.DeviceType.LXIVMPlus);
	}

	showCommercialFlowRate(id: any, station: any): boolean {
		if (station.master && this.isCommercialLXME(id) && 
			(station.type === RbEnums.Common.StationType.Unused || station.pump)) {
			return false;
		}
		if (station.master && (this.isCommercialLXME(id) || this.isCommercialLXIVM(id) || this.isCommercialLXD(id))) {
			return true;
		}
		return false;
	}

	showCommercialValveType(id: any, station: any): boolean {
		const controller = this.getCommercialController(id);
		this._controllerType = controller.type;
		return station.master && this._controllerType === RbEnums.Common.DeviceType.LXD;
	}

	showCommercialDelay(id: any, station: any): boolean {
		if (station.master && this.isCommercialLXME(id)) {
			if (station.pump && station.valveType === RbEnums.Common.ValveType.None) {
				return false;
			}
		}
		if (station.master && this.isCommercialLXME(id) && 
			station.type !== RbEnums.Common.StationType.Unused &&
			(station?.mvDelayLong != null || station?.mvDelayLong === 0)) {
			return true;
		}
		
		return false;
	}

	showMonthlyCyclingTime(station: any): boolean {
		if (station.master && 
			(station.type === RbEnums.Common.StationType.Unused || station.pump)) {
			return false;
		}
		if (station.master && !station.monthlyCyclingEnabled) {
			return true;
		}
		return station.master && station.monthlyCyclingEnabled;
	}

	private formatMonthlyCyclingTime(station: any): string {
		if (!station.monthlyCyclingEnabled || 
			station.type === RbEnums.Common.StationType.NormallyClosed) {
			return RbUtils.Translate.instant('STRINGS.NOT_ENABLED');
		}
		if (station.monthlyCyclingEnabled && station.type === RbEnums.Common.StationType.NormallyOpen) {
			return this.authManager.userCulture.toUserTimeString(station.monthlyCyclingTime);
		}
		return '-';
	}

	getMonthlyCyclingTime(station: any): string {
		return this.formatMonthlyCyclingTime(station);
	}

	getCommercialOperation(station: any): string {
		if (station.pump) {
			if (station.valveType === RbEnums.Common.ValveType.None) {
				const operation = this.stationManager.getPumpOperationTypes()
					.filter(o => o.key === RbEnums.Common.OperationType.Unused);
				if (operation && operation.length === 1) {
					return operation[0].value;
				}
			} else if (station.valveType !== RbEnums.Common.ValveType.None) {
				const operation = this.stationManager.getPumpOperationTypes()
					.filter(o => o.key === RbEnums.Common.OperationType.Pump);
				if (operation && operation.length === 1) {
					return operation[0].value;
				}
			}			
		} else {
			if (station.valveType === RbEnums.Common.ValveType.None) {
				const operationMv = this.stationManager.getMVOperationTypes()
					.filter(o => o.key === RbEnums.Common.OperationType.Unused);
				if (operationMv && operationMv.length === 1) {
					return operationMv[0].value;
				}
			} else if (station.valveType !== RbEnums.Common.ValveType.None) {
				const operationMv = this.stationManager.getMVOperationTypes()
					.filter(o => o.key === RbEnums.Common.OperationType.MasterValve);
				if (operationMv && operationMv.length === 1) {
					return operationMv[0].value;
				}
			}
		}
	}

	getCommercialIqNetSharingLabel(station: any): string {
		if (!station) return '';
		return RbUtils.Stations.getCommercialIqNetSharingLabel(station.borrowedMvid);
	}

	getCommercialIqNetSharingValue(station: any): string {
		if (!station) return '-';
		return RbUtils.Stations.getCommercialIqNetSharingValue(station.borrowState);
	}

	private getFloZoneName(zoneName: any): any {
		const zoneValue = zoneName.substring(zoneName.length - 2, zoneName.length);
		if (zoneValue) {
			const zoneIndex = zoneValue.replace(/\s/g, "");
			if (zoneIndex === '0') {
				return RbUtils.Translate.instant('STRINGS.NONE');
			} else {
				return zoneName;
			}
		}
	}

	getCommercialFlowZoneValue(station: any): string {
		if (!station.flowZone) return '';
		if (station.flowZone.name) {
			return this.getFloZoneName(station.flowZone.name);
		}
		return station.flowZone.name;
	}

	getCommercialFlowZoneName(id: any, station: any): string {
		if (!this.isCommercialLXME(id) && !this.isCommercialLXD(id) && !this.isCommercialLXIVM(id)) {
			return RbUtils.Translate.instant('STRINGS.FLOW_RATE');
		}
			
		if (this.isCommercialLXME(id) && station.valveType === RbEnums.Common.ValveType.None) {
			return RbUtils.Translate.instant('STRINGS.FLOW_RATE');
		}
			
		if (!station.stationValveType || !station.flowZone) return '';
		var flowZone = '';
		if (this.isCommercialLXME(id)) {
			let valveType = 1;
			if (station.stationValveType.valveType > 0) {
				valveType = station.stationValveType.valveType;
			}
			flowZone = RbUtils.Translate.instant('STRINGS.FLOZONE_NAME_VALVETYPE_FORMAT', { valveType });
		} else {
			flowZone = this.getFloZoneName(station.flowZone.name);
		}
		
		return RbUtils.Translate.instant('STRINGS.FLOWZONE_FLOWRATE', { flowZone });
	}

	getCommercialFlowRateValue(station: any): string {
		var unitTypeLabel = '';
		if (!this.cultureSettings) {
			return station.flowZone ? station.flowZone.flowRate : station.flowRate;
		}
		else {
			unitTypeLabel = this.unitLabelService.getFlowRateLabel();
		}
		const flowRateValue = station.flowRate ? this.conversionService.getUserFlowRateString(station.flowRate) : 
			station.flowZone ? this.conversionService.getUserFlowRateString(station.flowZone.flowRate) : this.conversionService.getUserFlowRateString(0);
        return `${flowRateValue} ${unitTypeLabel}`;
    }

	private durationFormatString(duration: moment.Duration): string {
		if (duration != null) {
			const hours = duration.hours();
			const minutes = duration.minutes();
			const seconds = duration.seconds();

			const formatHour = RbUtils.Translate.instant('RAINWATCH_DASHBOARD.FORMAT_HOUR');
			const formatMinute = RbUtils.Translate.instant('RAINWATCH_DASHBOARD.FORMAT_MINUTE');
			const formatSecond = RbUtils.Translate.instant('RAINWATCH_DASHBOARD.FORMAT_SECOND');

			if (hours > 0) {
				if (minutes > 0 && seconds > 0) {
					return `${hours} ${formatHour} ${minutes} ${formatMinute} ${seconds} ${formatSecond}`;
				} else if (minutes > 0) {
					return `${hours} ${formatHour} ${minutes} ${formatMinute}`;
				}
				return `${hours} ${formatHour} ${seconds} ${formatSecond}`;
			} else {
				if (minutes > 0 && seconds > 0) {
					return `${minutes} ${formatMinute} ${seconds} ${formatSecond}`;
				} else if (minutes > 0) {
					return `${minutes} ${formatMinute}`;
				}
				return `${seconds} ${formatSecond}`;
			}
		}
		return '';
	}

	getCommercialDelay(station: any) : string {
		if (station?.mvDelayLong == null) {
			return '-';
		}
		if (station.mvDelayLong === 0) {
			return `${station.mvDelayLong} ${RbUtils.Translate.instant('RAINWATCH_DASHBOARD.FORMAT_SECOND')}`;
		}

		return this.durationFormatString(RbUtils.Conversion.convertTicksToDuration(station.mvDelayLong));
	}

	private getCommercialController(id: any) : ControllerWithMapInfoLeaflet {
		let controller = this.controllers.find((c) => c.id === this.contextMenuInfo['station']?.satelliteId);
		if (this._selectedControllerItem && 
			controller.id === this._selectedControllerItem.id &&
			controller.siteId === this._selectedControllerItem.siteId) {
			controller.flowZone = this._selectedControllerItem.flowZone;
			controller.stationValveType = this._selectedControllerItem.stationValveType;
		}
		return controller;
	}

	getControllerConnectionForStation(id: any) {
		if (id === this.contextMenuInfo['station']?.id) {
			const controller = this.getCommercialController(id);
			if (controller.iqNetType === IqNetType.IQNetClient && controller.parentId) {
				const parentController = this.controllers.find((c) => c.id === controller.parentId);
				if (parentController.isConnected) return parentController.isConnected;
			}
			if (controller) return controller.isConnected;
			
		}
		return false;
	}

	isRunningStationStatus(id: any) {
		const stationContextMenu = this.contextMenuInfo as StationOptions;
		if (
			stationContextMenu.station instanceof StationWithMapInfoLeaflet &&
			stationContextMenu.station?.id === id &&
			stationContextMenu.station?.isStationRunning &&
			stationContextMenu.station?.stationListItem.irrigationStatus === this.irrigationStatus.Running &&
			this.getControllerConnectionForStation(id)
		) {
			return true;
		} else  {
			return false;
		}
	}

	getCommercialStationStatusLabel(id: any): string {
		if (!this.getControllerConnectionForStation(id)) {
			return this.translate.instant('STRINGS.CONNECT_TO_SEE_STATUS');
		} else if (this.isRunningStationStatus(id)) {
			return this.translate.instant('STRINGS.REMAINING_TIME');
		} else {
			return this.translate.instant('STRINGS.NOT_RUNNING');
		}
	}

	getCommercialTypeString(station: any): string {
		var typeString = '';
		switch (station.type) {
			case RbEnums.Common.StationType.NormallyClosed:
				typeString = RbUtils.Translate.instant('STRINGS.STATION_TYPE_NORMALLY_CLOSED');
				break;
			case RbEnums.Common.StationType.NormallyOpen:
				typeString = RbUtils.Translate.instant('STRINGS.STATION_TYPE_NORMALLY_OPEN');
				break;
			default:
				typeString = RbUtils.Translate.instant('STRINGS.STATION_TYPE_NORMALLY_UNUSED');
				break;
		}
		return typeString;
	}

	getCommercialValveTypeValue(station: any): string {
		if (!station.stationValveType) return '';
		switch(station.stationValveType.valveType) {
			case RbEnums.Common.ValveType.V1:
				return 'V1';
			case RbEnums.Common.ValveType.V2:
				return 'V2';
			case RbEnums.Common.ValveType.V3:
				return 'V3';
			case RbEnums.Common.ValveType.V4:
				return 'V4';
			case RbEnums.Common.ValveType.V5:
				return 'V5';
			case RbEnums.Common.ValveType.None:
				return this.translate.instant('STRINGS.NONE');
			default:
				return '';
		}
	}

	onCommercialStationStatusClicked() {
		const controller = this.controllers.find((c) => c.id === this.contextMenuInfo['station']?.satelliteId);
		const stationContextMenu = this.contextMenuInfo as StationOptions;
		if ((controller && controller.isConnected) ||
			(stationContextMenu.station instanceof StationWithMapInfoLeaflet &&
				stationContextMenu.station?.isStationRunning)) {
			// Controller for station in pop-up menu already connected or station is running
			// then we won't connect to controller.
			return;
		}
		if (controller.iqNetType === IqNetType.IQNetClient && controller.parentId) {
			const parentController = this.controllers.find((c) => c.id === controller.parentId);
			if (parentController && parentController.isConnected ||
				(stationContextMenu.station instanceof StationWithMapInfoLeaflet &&
					stationContextMenu.station?.isStationRunning)) {
				return;
			}
		}
		if (controller && !controller.isConnected) {
			this.handleControllerConnection(controller);
		}
	}

	private handleControllerConnection(controller: ControllerListItem) {
		this.controllerManager.isControllerInDemoMode(controller.id).subscribe((isInDemoMode: boolean) => {
			if (isInDemoMode) {
				const mbi = new MessageBoxInfo('SPECIAL_MSG.DEMO_MODE_CONNECT_UNAVAILABLE', RbEnums.Common.MessageBoxIcon.Information);
				this.messageBoxService.showMessageBox(mbi);
				return;
			}

			this.controllerId = controller.id;
			if (!this.isConnectingController) {
				this.contextMenuTrigger.closeMenu();
				this.isConnectingController = true;
				// Make API call to connect to the specified controller which station is belong to the controller.
				this.manualOpsManager.connect(controller.id).subscribe({
					error: () => {
						this.showMessageBox('SPECIAL_MSG.UNABLE_TO_CONNECT', RbEnums.Common.MessageBoxIcon.Error,
							'SPECIAL_MSG.CONTROLLER_CONNECTION_FAILED');
						this.isConnectingController = false;
					}
				});
			}
		});
	}

	private showMessageBox(message: string, icon: RbEnums.Common.MessageBoxIcon, title?: string) {
		this.messageBoxService.showMessageBox(new MessageBoxInfo(message, icon, title));
	}

	handleCloseStationContextMenu() {
		this.closeStationContextMenu.emit(true);
	}

	handleCloseStationAreaContextMenu() {
		this.closeStationAreaContextMenu.emit(true);
	}

	onSaveCommentsClicked() {
		this.stationManager.updateStations([this.contextMenuInfo['station']?.id], {
				description: this._textComments
			})
			.subscribe(() => {
				const mapStation = this.stations?.find(
					(s) => s.id === this.contextMenuInfo['station']?.id
				);
				if (mapStation) {
					mapStation.notes = this._textComments;
					mapStation.description = this._textComments;
				}
			});
		this.initializeSaveButton();
		this.closeMenu();
	}

	onSaveDescriptionsClicked() {
		this.controllerManager
			.updateControllers([this.contextMenuInfo['controller']?.id], {
				description: this._textDescriptions
			})
			.subscribe(() => {
				const mapController = this.controllers.find(
					(c) => c.id === this.contextMenuInfo['controller']?.id
				);
				if (mapController) {
					mapController.description = this._textDescriptions;
				}
			});
		this.initializeSaveButton();
		this.closeMenu();
	}

	onTextDescriptionsChanged(descriptions: any) {
		this.saveDescriptionsButton = <HTMLButtonElement>document.getElementById('btn-save-description-controller');
		if (descriptions !== this.contextMenuInfo['controller']?.description) {
			this.saveDescriptionsButton.style.backgroundColor = '#008658';
			this.saveDescriptionsButton.style.color = 'white';
			this._textDescriptions = descriptions;
			this.isTextDescriptionsChange = true;
		} else {
			this.saveDescriptionsButton.style.backgroundColor = '#e0e0e0';
			this.saveDescriptionsButton.style.color = '#a6a6a6';
			this.isTextDescriptionsChange = false;
		}
	}

	onTextCommentsChanged(comments: any) {
		this.saveCommentsButton = <HTMLButtonElement>document.getElementById('btn-save-comments-station');
		if (comments !== this.contextMenuInfo['station']?.description) {
			this.saveCommentsButton.style.backgroundColor = '#008658';
			this.saveCommentsButton.style.color = 'white';
			this._textComments = comments;
			this.isTextCommentsChange = true;
		} else {
			this.saveCommentsButton.style.backgroundColor = '#e0e0e0';
			this.saveCommentsButton.style.color = '#a6a6a6';
			this.isTextCommentsChange = false;
		}
	}

	secondsToHhMmSs(seconds: number) {
		return RbUtils.Stations.secondsToHhMmSs(seconds);
	}

	//#region Event handlers

	onContextMenuItemSelected(value: number) {
		this.contextMenuItemSelected.emit(value);
	}

	closeMenu() {
		this.contextMenuTrigger.closeMenu();
	}

	//#endregion

	private initializeSaveButton() {
		if (this.contextMenuInfo['station']?.id) {
			this.saveCommentsButton = <HTMLButtonElement>document.getElementById('btn-save-comments-station');
			if (this.saveCommentsButton) {
				this.textComments = this.contextMenuInfo['station']?.description !== '' ? this.contextMenuInfo['station']?.description : '';
				this.textDescriptions = '';
				this.isTextCommentsChange = false;
				this.saveCommentsButton.style.backgroundColor = '#e0e0e0';
				this.saveCommentsButton.style.color = '#a6a6a6';
			} else {
				this.textComments = this.contextMenuInfo['station']?.description !== '' ? this.contextMenuInfo['station']?.description : '';
			}
		}
		if (this.contextMenuInfo['controller']?.id) {
			this.saveDescriptionsButton = <HTMLButtonElement>document.getElementById('btn-save-description-controller');
			if (this.saveDescriptionsButton) {
				this.textDescriptions = this.contextMenuInfo['controller']?.description !== '' ? this.contextMenuInfo['controller']?.description : '';
				this.textComments = '';
				this.isTextDescriptionsChange = false;
				this.saveDescriptionsButton.style.backgroundColor = '#e0e0e0';
				this.saveDescriptionsButton.style.color = '#a6a6a6';
			} else {
				this.textDescriptions = this.contextMenuInfo['controller']?.description !== '' ? this.contextMenuInfo['controller']?.description : '';
			}
		}
	}

	// =========================================================================================================================================================
	// Drag and drop support
	// =========================================================================================================================================================

	onDrop(event: CdkDragDrop<any, any>) {
		if (!!event.item.data.addShapeToStationId) {
			this.mapInfo.drop(event, { offsetX: this.stationAreaMarkerInfo.dragIconHotspotX, offsetY: this.stationAreaMarkerInfo.dragIconHotspotY });
		}
	}

	onDragMoved(event: CdkDragMove<any>) {
		this.mapInfo.updateDragPosition(event);
	}

	onDragEnded() {
		this.dragging = false;
		this.mapInfo.dragEnded();
	}

	isAddShape(menuContextValue: number) {
		if (menuContextValue === RbEnums.Map.StationContextMenu.AddShape)	return true;
		return false;
	}
}

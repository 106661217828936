import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { EnvironmentService } from './environment.service';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { InternetService } from '../../api/internet/internet.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class AppInitializerService {

	constructor(
		private authManager: AuthManagerService,
		private translateService: TranslateService,
		private internetService: InternetService,
		private env: EnvironmentService
	) { }

	load() {
		return new Promise((resolve, reject) => {
			// RB-8057: It's essential that we complete environment setup before initializing authManager.init().
			// The authManager uses environment elements and will get unresolved failures in standalone if this
			// isn't done.
			this.env.load().subscribe(() => {
				this.authManager.init();

				this.authManager.isLoggedInObservable
					.pipe(take(1))
					.subscribe(isLoggedIn => {
						if (isLoggedIn) {
							
							this.checkPasswordResettedSuccess();
							this.doInitialization(resolve);
							return;
						}

						if (this.authManager.currentUser) {
							this.authManager.startOauthSignOut();
						} else {
							this.authManager.startOauthSignIn();
						}
					});
			});
		});
	}

	checkPasswordResettedSuccess() {
		const needLogout = location.href.endsWith("resetpasswordsuccess");
		if (needLogout) {
			this.authManager.startOauthSignOut();
		}
	}

	private doInitialization(resolve: any) {
		// RB-9212: Ensure 'en' is always added as the last language.
		this.translateService.addLangs(['fr', 'tr', 'de', 'es', 'it', 'pt', 'sv', 'ja', 'ko', 'vi', 'zh']);
		this.translateService.langs.push('en');
		this.translateService.setDefaultLang('en');

		// Perform any actions below that need to be complete before the app component is bootstrapped.
		const actions = [
			this.translateService.langs.map((lang) => this.translateService.getTranslation(lang)),
			this.internetService.testConnectionFunction(),
		];
		// Do all the retrievals and resolve our promise when complete.
		forkJoin(actions)
			.subscribe(() => {
				// NOTE: setTimeout is necessary!! Among other things, this gives the language (i18n) files a chance to load (especially on slow connections).
				setTimeout(() => resolve(true), 1000);
			});
	}
}

export class IrrigationStatus {

	constructor(json: any = null) {
		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here
		}
	}

	floManagerState = false;
	flowSensingState = false;
	irrigationState: string;
	startsPending = 0;
	stationsIrrigating = 0;
	stationsPending = 0;
	twoWirePathState = false;
}

import { RbEnums } from "../../../common/enumerations/_rb.enums";

export interface IStationAssignedProgram {
	stationId: number;
	runtimeProgramAssignedList?: IProgramRuntimeList[]
}

export interface IProgramRuntimeList {
	adjustedRunTime?: string;
	programId: number;
	programShortName?: string;
	baseRunTime?: string;
	etAdjustType?: RbEnums.Common.EtAdjustType;
	etAdjustFactor: number;
	programStepId: number;
	nextStart?: string;
}

export enum StationAssignedColumnKey {
	RunTime = 'runtime',
	AssignedPrograms = 'assignedPrograms'
}
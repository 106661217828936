import * as moment from 'moment';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppDateAdapter, } from '../../../../custom/date-adapter';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { CompanyManagerService } from '../../../../../api/companies/company-manager.service';
import { DateAdapter } from '@angular/material/core';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { forkJoin } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RbConstants } from '../../../../../common/constants/_rb.constants';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { TranslateService } from '@ngx-translate/core';
import { UserManagerService } from './../../../../../api/users/user-manager.service';
@UntilDestroy()
@Component({
	selector: 'rb-date-range-dropdown',
	templateUrl: './date-range-dropdown.component.html',
	styleUrls: ['./date-range-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: DateAdapter, useClass: AppDateAdapter
		},
	]
})

export class DateRangeDropdownComponent implements OnInit, OnDestroy {
	@Output() dateRangeChange = new EventEmitter();
	@Output() endDateChange: EventEmitter<Date> = new EventEmitter();
	@Output() startDateChange: EventEmitter<Date> = new EventEmitter();

	@Input() dateRangeDropdown: { id: number, name: string }[] = []	;
	@Input() hideDates = false;
	@Input() hideTimeForDateRange = true;
	@Input() useStartOfDayForDateRange = true;
	@Input() startDateValue: Date;
	@Input() endDateValue: Date;
	@Input() rangeDirection = RbEnums.Common.RangeDirection.PriorToDate;

	private _selectedDateRange = RbConstants.Form.DATE_RANGE.alarm;
	// @ts-ignore
	@Input() set selectedDateRange(value: RbConstants.Form.DATE_RANGE) {
		this._selectedDateRange = value;

		if (this._selectedDateRange !== RbConstants.Form.DATE_RANGE.custom) {
			const dateRange = RbUtils.DateRange.getDateRange(value, null, this.rangeDirection);
			// this.startDateValue = dateRange.startDate;
			this.minEndDate = dateRange.startDate;
		}
	}

	// @ts-ignore
	get selectedDateRange(): RbConstants.Form.DATE_RANGE {
		return this._selectedDateRange;
	}

	/**
	 * @summary Set placeHolder to the string displayed above the Date Range dropdown. We default this value
	 * to "Compare" for reports, "Date Range" for other situations, but you can set it to "" or any other string,
	 * instead. NOTE: null is used to detect that the default is desired. If you want empty, use "".
	 */
	@Input() placeHolder: string = null;

	/**
	 * @summary Set startPlaceholder to the string to be displayed for the Start date entry. For most situations,
	 * you won't want to set this, but there might be report cases where it's needed.
	 */
	@Input() startPlaceholder = '';

	/**
	 * @summary Set endPlaceholder to the string to be displayed for the End date entry. For most situations,
	 * you won't want to set this, but there might be report cases where it's needed.
	 */
	@Input() endPlaceholder = '';

	/**
	 * @summary minDate is the earliest date value acceptable by the entry
	 */
	@Input() minDate: Date;

	/**
	 * @summary maxDate is the latest date value acceptable by the entry. By default, this value is Now but to allow
	 * for date entry in time zones other than the local one to the browser, we permit it to be overridden by the caller.
	 */
	@Input() maxDate = new Date();

	startTimeValue: Date;
	endTimeValue: Date;
	alarm: number;
	dateRangeValues;
	currentUserDateTime: Date;
	/**
	 * @summary minEndDate is the lower limit of values allowed in the endDate field. Just as you might guess, this is
	 * normally set to the startDate value so end is not less than start.
	 */
	minEndDate = new Date();

	isMobile = false;

	private initialStartTimeHoursOffset = 0;

	isGolfSite = false;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private companyManager: CompanyManagerService,
				private userManagerService: UserManagerService, 
				private dateAdapter: DateAdapter<Date>,
				private deviceManager: DeviceManagerService,
				private translate: TranslateService,
	) { }

	ngOnInit() {
		this.dateRangeValues = this.dateRangeDropdown;

		this.alarm = RbConstants.Form.DATE_RANGE.alarm;

		// If the placeHolder was set as an input to this component, don't overwrite it.
		// Otherwise, use a string based on the date range selected for the drop-down, one for comparison reports,
		// the other for general date ranges.
		this.placeHolder = (this.placeHolder === null)	// No external setting?
			? RbUtils.DateRange.isWaterReportsDateRange(this.dateRangeDropdown)	// Set default based on date range specified
				? this.translate.instant('STRINGS.COMPARE')
				: this.translate.instant('STRINGS.DATE_RANGE')
			: this.placeHolder;	// External setting was selected, don't overwrite it.

		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		this.initialStartTimeHoursOffset = this.isGolfSite ? 12 : 0;
		forkJoin([
			this.companyManager.getCompanyPreferences(),
			this.userManagerService.getUserCurrentDateTime(),
		]).subscribe(([company, currentUserDateTime]) => {
			if (company != null && company.irrigationDayStartTime != null) {
				this.initialStartTimeHoursOffset = company.irrigationDayStartTime.getHours();
			}
			this.currentUserDateTime = currentUserDateTime;
			this.setDatesAndRanges();
		});

		// According to the Culture setting, set Calender start Day
		this.dateAdapter.getFirstDayOfWeek = () => this.authManager.userCulture.calendarFormatId;

		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => this.isMobile = isMobile);

		this.isMobile = this.deviceManager.isMobile;

		// RB-9472: IQ4 - Reports: Previous period shows incorrect date in default water management report
		// For Water reports, need to initialize the startDatePrevious and endDatePrevious using handleDateRangeChange
		if (RbUtils.DateRange.isWaterReportsDateRange(this.dateRangeDropdown)) {
			this.handleDateRangeChange(this.selectedDateRange);
		}
	}

	ngOnDestroy() {
		/** Required by untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onUserChangedStartDate(startDateEvent: MatDatepickerInputEvent<any>) {
		const previousStartDate = this.startDateValue;

		if (startDateEvent.value === null) {
			startDateEvent.target.value = previousStartDate;
			return;
		}

		const m = moment(this.startDateValue);
		this.startDateValue = moment(startDateEvent.value).startOf('day').add(m.hours(), 'hour').add(m.minutes(), 'minutes').toDate();
		this.validateAndEmitStartDate();
	}

	private validateAndEmitStartDate() {
		if (this.startDateValue > this.maxDate) this.startDateValue = this.maxDate;
		this.minEndDate = this.startDateValue;
		// if (moment(this.startDateValue) > moment(this.endDateValue)) {
		// 	this.endDateValue = this.startDateValue;
		// }

		this.startTimeValue = this.startDateValue;
		this.selectedDateRange = RbConstants.Form.DATE_RANGE.custom;
		this.startDateChange.emit(this.startDateValue);
		this.dateRangeChange.emit({ rangeValue: this.selectedDateRange, startDate: this.startDateValue, endDate: this.endDateValue });
	}

	onUserChangedEndDate(endDateEvent: MatDatepickerInputEvent<any>) {
		const previousEndDate = this.endDateValue;

		if (endDateEvent.value === null) {
			endDateEvent.target.value = previousEndDate;
			return;
		}

		const m = moment(this.endDateValue);
		this.endDateValue = moment(endDateEvent.value).startOf('day').add(m.hours(), 'hour').add(m.minutes(), 'minutes').toDate();
		this.validateAndEmitEndDate();
	}

	private validateAndEmitEndDate() {
		if (this.endDateValue > this.maxDate) this.endDateValue = this.maxDate;
		// if (this.endDateValue < this.minEndDate) this.endDateValue = this.minEndDate;

		this.endTimeValue = this.endDateValue;
		this.selectedDateRange = RbConstants.Form.DATE_RANGE.custom;
		this.endDateChange.emit(this.endDateValue);
		this.dateRangeChange.emit({ rangeValue: this.selectedDateRange, startDate: this.startDateValue, endDate: this.endDateValue });
	}

	handleDateRangeChange(rangeValue: number) {
		this.selectedDateRange = rangeValue;
		if (rangeValue === RbConstants.Form.DATE_RANGE.alarm) {
			this.dateRangeChange.emit({ rangeValue });
			return;
		}

		const startDate = this.getStartDate(rangeValue);
		const endDate = this.getEndDate(rangeValue);
		this.startDateValue = startDate;
		this.startTimeValue = this.startDateValue;
		this.minEndDate = rangeValue === RbConstants.Form.DATE_RANGE.custom ? startDate : endDate;
		this.endDateValue = endDate;
		this.endTimeValue = this.endDateValue;

		let param: any = { rangeValue, startDate, endDate };
		if (RbUtils.DateRange.isWaterReportsDateRange(this.dateRangeDropdown)) {
			let startDatePrevious;
			let endDatePrevious;
			if (rangeValue === RbConstants.Form.DATE_RANGE.monthToDate_wm) {
				const diffInDays = Math.abs(moment(endDate).diff(moment(startDate), 'days'));
				startDatePrevious = this.setToStartOfMonth(moment(startDate).subtract(1, 'months'));
				endDatePrevious = this.setToEndOfDay(moment(startDatePrevious).add(diffInDays, 'days'));
			} else if (rangeValue === RbConstants.Form.DATE_RANGE.yearToDate_wm ) {
				const diffInDays = Math.abs(moment(endDate).diff(moment(startDate), 'days'));
				startDatePrevious = this.setToStartOfMonth(moment(startDate).subtract(1, 'years'));
				endDatePrevious = this.setToEndOfDay(moment(startDatePrevious).add(diffInDays, 'days'));
			} else {
				startDatePrevious = this.getStartDate(rangeValue, startDate);
				endDatePrevious = this.getEndDate(rangeValue, startDate);
			}
			param = {
				rangeValue: rangeValue,
				startDatePrevious: startDatePrevious,
				endDatePrevious: endDatePrevious,
				startDate: startDate,
				endDate: endDate
			};
		}
		this.dateRangeChange.emit(param);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setDatesAndRanges() {
		const isCustomRange = this.selectedDateRange === RbConstants.Form.DATE_RANGE.custom;

		// If we have passed in start and end dates, don't reset them!
		const startDate = isCustomRange && this.startDateValue ? this.startDateValue : this.getStartDate(this.selectedDateRange);
		const endDate = isCustomRange && this.endDateValue ? this.endDateValue : this.getEndDate(this.selectedDateRange);

		this.startDateValue = startDate;
		this.startTimeValue = this.startDateValue;
		this.minEndDate = startDate;
		this.endDateValue = endDate;
		this.endTimeValue = this.endDateValue;
		this.startDateChange.emit(this.startDateValue);
		this.endDateChange.emit(this.endDateValue);
	}

	private getStartDate(rangeValue: number, startDate?: any): Date {
		startDate = startDate ? moment(startDate) : moment(this.currentUserDateTime);
		switch (rangeValue) {
			case RbConstants.Form.DATE_RANGE.monthToDate:
				return this.setToStartHour(startDate.startOf('month'));
			case RbConstants.Form.DATE_RANGE.oneDay:
				return this.setToStartHour(startDate.subtract(1, 'days'));
			case RbConstants.Form.DATE_RANGE.threeDays:
				return this.setToStartHour(startDate.subtract(3, 'days'));
			case RbConstants.Form.DATE_RANGE.oneWeek:
				return this.setToStartHour(startDate.subtract(7, 'days'));
			case RbConstants.Form.DATE_RANGE.thirtyDays:
				return this.setToStartHour(startDate.subtract(30, 'days'));
			case RbConstants.Form.DATE_RANGE.threeMonths:
				return this.setToStartHour(startDate.subtract(3, 'months'));
			case RbConstants.Form.DATE_RANGE.sixMonths:
				return this.setToStartHour(startDate.subtract(6, 'months'));
			case RbConstants.Form.DATE_RANGE.yearToDate:
				return this.setToStartHour(startDate.startOf('year'));
			case RbConstants.Form.DATE_RANGE.oneYear:
				return this.setToStartHour(startDate.subtract(365, 'd'));
			case RbConstants.Form.DATE_RANGE.nintyDays:
				return this.setToStartHour(startDate.subtract(90, 'd'));
			case RbConstants.Form.DATE_RANGE.oneEightyDays:
				return this.setToStartHour(startDate.subtract(180, 'd'));
			case RbConstants.Form.DATE_RANGE.threesixtyFiveDays:
				return this.setToStartHour(startDate.subtract(365, 'd'));
			case RbConstants.Form.DATE_RANGE.twelveHours:
				if (!this.useStartOfDayForDateRange && this.isGolfSite) {
					return moment().startOf('date').toDate();
				} else {
					return startDate.subtract(12, 'h').toDate();
				}
			case RbConstants.Form.DATE_RANGE.twelveHours_wm:
				return startDate.subtract(12, 'h').toDate();
			case RbConstants.Form.DATE_RANGE.oneDay_wm:
				return this.setToStartOfDay(startDate.subtract(1, 'days'));
			case RbConstants.Form.DATE_RANGE.oneWeek_wm:
				return this.setToStartOfDay(startDate.subtract(7, 'days'));
			case RbConstants.Form.DATE_RANGE.thirtyDays_wm:
				return this.setToStartOfDay(startDate.subtract(30, 'days'));
			case RbConstants.Form.DATE_RANGE.nintyDays_wm:
				return this.setToStartOfDay(startDate.subtract(90, 'd'));
			case RbConstants.Form.DATE_RANGE.oneEightyDays_wm:
				return this.setToStartOfDay(startDate.subtract(180, 'd'));
			case RbConstants.Form.DATE_RANGE.threesixtyFiveDays_wm:
				return this.setToStartOfDay(startDate.subtract(365, 'd'));
			case RbConstants.Form.DATE_RANGE.monthToDate_wm:
					return this.setToStartOfDay(startDate.startOf('month'));
			case RbConstants.Form.DATE_RANGE.yearToDate_wm:
				return this.setToStartOfDay(startDate.startOf('year'));
			default:
				return this.setToStartHour(this.startDateValue != null ? moment(this.startDateValue) : moment());
		}
	}

	private getEndDate(rangeValue: number, endDate?: any): Date {
		endDate = endDate ? moment(endDate) : moment(this.currentUserDateTime);
		switch (rangeValue) {
			case RbConstants.Form.DATE_RANGE.oneDay_wm:
			case RbConstants.Form.DATE_RANGE.oneWeek_wm:
			case RbConstants.Form.DATE_RANGE.thirtyDays_wm:
			case RbConstants.Form.DATE_RANGE.nintyDays_wm:
			case RbConstants.Form.DATE_RANGE.oneEightyDays_wm:
			case RbConstants.Form.DATE_RANGE.threesixtyFiveDays_wm:
				return moment(this.setToStartOfDay(endDate)).subtract(1, 'seconds').toDate(); // 11:59pm of the previous day
			default:
				return !this.useStartOfDayForDateRange && this.isGolfSite
					? moment(this.setToStartHour(endDate)).subtract(1, 'seconds').toDate() : endDate.toDate();
			}
	}

	private setToStartOfMonth(value: moment.Moment): Date {
		return value.startOf('month').toDate();
	}

	private setToStartOfDay(value: moment.Moment): Date {
		return value.startOf('day').toDate();
	}

	private setToStartHour(value: moment.Moment): Date {
		return value.startOf('day').add(this.useStartOfDayForDateRange ? 0 : this.initialStartTimeHoursOffset, 'hour').toDate();
	}

	private setToEndOfDay(value: moment.Moment): Date {
		return value.endOf('day').toDate();
	}

	onStartTimeChanged(date: Date) {
		// Only the time portion of "date" is valid, so use that
		const m = moment(date);
		this.startDateValue = moment(this.startDateValue).startOf('day').add(m.hours(), 'hour').add(m.minutes(), 'minutes').toDate();
		this.validateAndEmitStartDate();
	}

	onEndTimeChanged(date: Date) {
		// Only the time portion of "date" is valid, so use that
		const m = moment(date);
		this.endDateValue = moment(this.endDateValue).startOf('day').add(m.hours(), 'hour').add(m.minutes(), 'minutes').toDate();
		this.validateAndEmitEndDate();
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { SidebarNavRoute } from './models/sidebar-nav-route.model';

@Component({
	selector: 'rb-sidebar-routes-panel',
	templateUrl: './sidebar-routes-panel.component.html',
	styleUrls: ['./sidebar-routes-panel.component.scss']
})
export class SidebarRoutesPanelComponent implements OnInit {

	@Input() routes: SidebarNavRoute[];

	constructor() { }

	ngOnInit() {

		// this.routes = [];
		// this.routes.push(new SidebarNavRoute('Route 1', ''));
		// this.routes.push(new SidebarNavRoute('Route 2', ''));
		// this.routes.push(new SidebarNavRoute('Route 3', ''));
		// this.routes.push(new SidebarNavRoute('Route 4', ''));
	}

}

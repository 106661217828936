<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-weather-source-mobile-cell-container">

	<div class="weather-source-mobile-cell">
		<div class="weather-source-mobile-info">
			<div class="weather-source-name ellipsis-text">{{weatherSource?.name}}</div>
			<button
				*ngIf="!isGolfSite && isShowViewWeather"
				mat-button
				type="button"
				(click)="navigateToETCalendarView()"
				class="mat-button mat-primary bordered-icon text-wrapping-button"
				color="primary">
				{{'STRINGS.VIEW_ET_CALENDAR' | translate}}
			</button>
		</div>
		<div *ngIf="isGolfSite" class="weather-source-item">
			<span class="weather-source-value-label">{{'STRINGS.CURRENT_ET' | translate}}:</span>
			<span class="weather-source-value">{{etRenderer(weatherSource?.calculatedEt)}}</span>
		</div>
		<div *ngIf="isGolfSite" class="weather-source-item">
			<span class="weather-source-value-label">{{'STRINGS.NEXT_CONTACT' | translate}}:</span>
			<span class="weather-source-value">{{nextContactRenderer(weatherSource?.nextContact)}}</span>
		</div>
	</div>

</div>

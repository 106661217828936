<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="segment-info-container" style="flex-direction: column">

    <div style="flex: 1; display: flex;">
        <div class="si-pane left">
            <div class="si-labels">
                <div>{{'STRINGS.NAME' | translate}}:</div>
                <div>{{'STRINGS.STATUS' | translate}}:</div>
            </div>
            <div class="si-values">
                <div class="ellipsis-text">{{deviceResponseItem?.name || '--'}}</div>
                <div class="ellipsis-text response-status" [ngClass]="{'is-responding': deviceResponseItem?.isResponding}">
                    {{(deviceResponseItem?.isResponding ? 'STRINGS.RESPONDING' : 'STRINGS.NOT_RESPONDING') | translate}}
                </div>
            </div>
        </div>

        <div class="si-pane right">
            <div class="si-labels">
                <div>{{'STRINGS.TERMINAL' | translate}}:</div>
                <div>{{'STRINGS.ADDRESS' | translate}}:</div>
            </div>
            <div class="si-values">
                <div class="ellipsis-text">{{deviceResponseItem?.terminal}}</div>
                <div class="ellipsis-text">{{deviceResponseItem?.address}}</div>
            </div>
        </div>
    </div>

    <div class="si-pane rb-small-label">
        <div class="si-labels">
            <div>{{'STRINGS.LAST_RESPONSE' | translate}}:</div>
        </div>
        <div class="si-values">
            <div class="ellipsis-text">{{appDateAdapter.formatDateTime(deviceResponseItem?.lastResponse) || '--'}}</div>
        </div>
    </div>

</div>



import { Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { RbConstants } from '../../../common/constants/_rb.constants';

@Component({
	selector: 'rb-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: [ './color-picker.component.scss' ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ColorPickerComponent),
			multi: true
		}
	]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {

	@Input() label: string;
	@Input() position = 'right';
	@Input() positionOffset = '-400px';
	@Input() presetColors: string[] = [];
	@Input() color: string;

	@Output() colorPickerChange = new EventEmitter<string>();

	colorPickerHeight: string = 'auto';
	colorPickerWidth = RbConstants.Ui.COLOR_PICKER_WIDTH;

	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => { };
	private onTouched = () => { };

	constructor(public deviceManager: DeviceManagerService) { }

	ngOnInit() {
		setTimeout(() => {
			this.updatePickerHeight();
		}, 100);
	}

	get value(): string { return this.color; }

	writeValue(value: any): void {
		this.color = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onColorChanged(event: string) {
		this.onTouched();
		this.onChange(this.color);
		this.colorPickerChange.emit(this.color);
	}
	
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updatePickerHeight();
	}

	private updatePickerHeight() {
		if (window.innerHeight >= 1000) {
			this.colorPickerHeight = 'auto';
			return;
		} else if (window.innerHeight >=300) {
			this.colorPickerHeight = '300';
		} else {
			this.colorPickerHeight = '200';
		}
	}
}

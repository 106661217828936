import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-start-time-cell-renderer',
	templateUrl: './multiple-times-cell-renderer.component.html',
	styleUrls: ['./multiple-times-cell-renderer.component.scss']
})
export class MultipleTimesCellRendererComponent implements ICellRendererAngularComp {

	displayTime: string;
	selectedStartTime = 0;
	showArrows = false;
	startTimes: Date[] = [];

	RbUtils = RbUtils;

	private selectedTimeChanged: any;
	private data: any;

	constructor() {
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.selectedTimeChanged = params.selectedTimeChanged;
		this.refresh(params);
	}

	refresh(params: any): boolean {
		this.data = params.data;
		const startTimeOrTimes = params.startTimes(params.data);
		this.showArrows = startTimeOrTimes instanceof Array && this.isTimeValid(startTimeOrTimes[0]);
		this.startTimes = startTimeOrTimes instanceof Array ? startTimeOrTimes : [ startTimeOrTimes];
		this.selectedStartTime = 0;
		this.updateDisplayTime();
		if (this.selectedTimeChanged != null) this.selectedTimeChanged(this.data, this.selectedStartTime);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private updateDisplayTime() {
		const startTime = this.startTimes.length === 0 ? null : this.startTimes[this.selectedStartTime];
		this.displayTime = startTime == null || !this.isTimeValid(startTime) ? '-' : RbUtils.Date.transform(startTime, RbUtils.User.timeFormat);
	}

	private isTimeValid(time: Date): boolean { return time != null && time.getFullYear() > 1901; }

	onSelectPrevious(event: any): void {
		if (event) event.stopPropagation();
		if (this.selectedStartTime === 0) return;
		this.selectedStartTime -= 1;
		this.updateDisplayTime();
		if (this.selectedTimeChanged != null) this.selectedTimeChanged(this.data, this.selectedStartTime);
	}

	onSelectNext(event: any): void {
		if (event) event.stopPropagation();
		if (this.selectedStartTime >= this.startTimes.length - 1) return;
		this.selectedStartTime += 1;
		this.updateDisplayTime();
		if (this.selectedTimeChanged != null) this.selectedTimeChanged(this.data, this.selectedStartTime);
	}
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ControllerListItem } from '../../../../../api/controllers/models/controller-list-item.model';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-controller-delta-mobile-cell-renderer',
	templateUrl: './controller-delta-mobile-cell-renderer.component.html',
	styleUrls: ['./controller-delta-mobile-cell-renderer.component.scss']
})
export class ControllerDeltaMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	controller: ControllerListItem;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.controller = params.data;
	}

	getControllerStatusCellRenderer() {
		return RbUtils.GridCellStyles.getControllerStatusCell(this.controller, true, true);
	}

	// TODO - MW: Implement proper status rendering.
	getInterfaceStatusCellRenderer(): string {
		return '-';
	}
}

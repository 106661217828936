import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../../api/culture-settings/models/culture-settings.model';
import { MatMenuPanel } from '@angular/material/menu';
import { RbUtils } from '../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-units-of-measure-flow',
	templateUrl: './units-of-measure-flow.component.html',
	styleUrls: ['./units-of-measure-flow.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UnitsOfMeasureFlowComponent),
			multi: true
		}
	]
})
export class UnitsOfMeasureFlowComponent implements OnInit, ControlValueAccessor {

	@Input() name = '';
	@Input() hintLabel: string;
	@Input() isDisabled = false;
	@Input() helpContent: MatMenuPanel;
	@Input() showHelpButton = false;
	@Input() bypassUnitConversion = false;

	private _precision = 2;
	@Input() set precision(value: number) {
		if (value == null || this._precision === value) { return; }

		this._precision = value;

		// Allows for changing and updating decimal precision after component init.
		this.updateSeparatorString(this.currentValue);
	}
	@Output() valueChanges = new EventEmitter<number>();

	get precision(): number { return this._precision; }

	separatorString: string;
	cultureSettings: CultureSettings;
	loadError: string;
	isReadOnly = false;

	private currentValue: number;

	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => { };
	private onTouched = () => { };

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService) { }

	ngOnInit(): void {
		this.isReadOnly = this.authManager.isReadOnly;
		this.isDisabled = this.isReadOnly;
		this.cultureSettings = this.authManager.userCulture;
		this.loadData();
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get value(): string { return this.separatorString; }

	/**
	 * @summary According to the Angular forms API, this method is called when *programmatic* changes to the
	 * form value are made. Presumably this is NOT called when user changes are made.
	 * @param value - new value for the control from the form.
	 */
	writeValue(value: any): void {
		if (value === null) return;

		this.currentValue = value;
		this.updateSeparatorString(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onSeparatorChanged(paramName: string, paramValue: any) {
		this.onTouched();
		this.separatorString = paramValue;
		this.onChange(this.separatorString);
	}

	onMatInputChange() {
		this.separatorString = RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator, this.separatorString, this.precision);
		this.valueChanges.emit(Number(this.separatorString));
		return this.separatorString;
	}

	/**
	 * @summary This utility method is intended to allow a programmatic caller to set the flow value as
	 * though the user typed the value in the UI. This works around issues created when you attempt to
	 * .setValue() on the form object, etc. When you do that, since we adjust the value, you end up with
	 * the raw value in the validator but the adjusted value in the UI, so you see a valid value on the
	 * screen but a validation error.
	 * @param newSepString - New flow value, in the localized format used by the user, as a string.
	 */
	setValueAsFromUser(newSepString: string) {
		this.separatorString = newSepString;
		this.onChange(this.separatorString);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private loadData() {
		if (this.hintLabel != null) return;

		this.hintLabel = this.cultureSettings.unitTypeString;
	}

	private updateSeparatorString(value: number) {
		if (value == null || value === 0) {
			this.separatorString = '0';
			return;
		}

		if (this.bypassUnitConversion) {
			this.separatorString = RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator, value.toString(), this.precision);
			return;
		}

		this.separatorString = RbUtils.Common.convertFlowToUserCulture(this.cultureSettings, value, this.precision);
	}

}

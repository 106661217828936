export abstract class EntityQueryParamsBase {

	protected _queryString = '';

	protected addQueryVariable(variable: string) {
		if (this._queryString.length > 1) { this._queryString += '&'; }
		this._queryString += variable;
	}

}

<div class="rb-color-picker">
	<label>{{label}}</label>
	<input class="color-input"
		readonly
		[(colorPicker)]="color"
		[style.background]="color"
		[cpPresetColors]="presetColors"
		[cpPresetLabel]="null"
		[cpAlphaChannel]="'disabled'"
		[cpHeight]="colorPickerHeight"
		[cpWidth]="colorPickerWidth"
		[cpPosition]="deviceManager.isMobile ? position : 'auto'"
		[cpPositionOffset]="deviceManager.isMobile ? positionOffset : '0'"
		[cpOKButton]="true" 
		[cpOKButtonText]="'STRINGS.SAVE' | translate"
		[cpOKButtonClass]="'mat-raised-button mat-button-base mat-primary primaryColor'"
		[cpCancelButton]="true"
		[cpCancelButtonText]="'STRINGS.CANCEL' | translate"  
		[cpCancelButtonClass]="'mat-button mat-button-base mat-primary'"
		[cpUseRootViewContainer]="true"
		(colorPickerSelect)="onColorChanged($event)"
		(click)="$event.stopPropagation()"/>
</div>

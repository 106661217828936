<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-custom-range-panel></rb-custom-range-panel>

<div class="datepicker-header">
  <!-- "previous" buttons -->
  <button
    mat-icon-button
    class="double-arrow"
    (click)="previousClicked('year')"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button (click)="previousClicked('month')">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>

  <!-- center label -->
  <span class="datepicker-header-label">{{ periodLabel }}</span>

  <!-- "next" buttons -->
  <button mat-icon-button (click)="nextClicked('month')">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <button
    mat-icon-button
    class="double-arrow"
    (click)="nextClicked('year')"
  >
    <mat-icon>keyboard_arrow_right</mat-icon>
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
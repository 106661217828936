import { ScheduledReport } from "./scheduled-report.model";

export class ScheduledReportItem extends ScheduledReport {

	numberOfSites: number;
	numberOfRecipients: number;
	scheduledTimesString: string;
	constructor(json: any = null) {
		super(json);
		if (json) {
			Object.assign(this, json);
		}
	}
}
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { HoleAreaPair } from '../../../../../api/sites/models/hole-area-pair.model';
import { MatDialog } from '@angular/material/dialog';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { ReportFilterComponent } from '../../report-filter.component';
import { Site } from '../../../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../../../api/sites/site-manager.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-mobile-report-filter',
	templateUrl: './mobile-report-filter.component.html',
	styleUrls: ['./mobile-report-filter.component.scss']
})
export class MobileReportFilterComponent implements OnInit {
	@ViewChild('controllerSelector') controllerSelector;

	// Generally, we are just a UI proxy on top of the parent report filter
	@Input() parentReportFilter: ReportFilterComponent;
	@Input() singleSelection = false;

	controllerSelectorVisible = false;
	areaSelectorVisible = false;
	RbUtils = RbUtils;
	isGolfSite = false;
	selectControllers = 'STRINGS.SELECT_CONTROLLERS';
	controllersSelected;
	noOfSelectedControllers;
	selectedHoleAreaPairs = Array<HoleAreaPair>();
	controller = 'STRINGS.CONTROLLER';
	selectedSites: Site[];
	sites: Site[];

	constructor(private authManager: AuthManagerService,
		public dialog: MatDialog,
				private siteManager: SiteManagerService,
				public translate: TranslateService
	) { }

	ngOnInit(): void {
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);

		if (this.isGolfSite) {
			this.selectControllers = 'STRINGS.SELECT_AREAS';
			this.controller = 'STRINGS.AREA';
		} else {
			this.noOfSelectedControllers = this.parentReportFilter.controllerIds ? this.parentReportFilter.controllerIds.length : 0;
			if (!this.parentReportFilter.controllerIds || this.parentReportFilter.controllerIds.length === 1)
				this.controllersSelected = 'STRINGS.CONTROLLER';
			else
				this.controllersSelected = 'STRINGS.CONTROLLER';
		}

		this.siteManager.getSites()
			.pipe(take(1))
			.subscribe((sites: Site[]) => this.sites = sites);

		this.siteManager.getSelectedSites()
			.pipe(take(1))
			.subscribe((sites: Site[]) => this.selectedSites = sites);
	}

	onSelectControllers() {
		if (this.isGolfSite)
			this.areaSelectorVisible = true;
		else
			this.controllerSelectorVisible = true;
	}

	onSelectControllersComplete(selectedControllerIds: number[]) {
		if (selectedControllerIds != null) {
			this.parentReportFilter.controllerIds = selectedControllerIds;
			this.noOfSelectedControllers = selectedControllerIds.length;
		}
		this.controllerSelectorVisible = false;
		this.areaSelectorVisible = false;
		if (!this.parentReportFilter.controllerIds || this.parentReportFilter.controllerIds.length === 1)
			this.controllersSelected = 'STRINGS.CONTROLLER';
		else
			this.controllersSelected = 'STRINGS.CONTROLLER';
	}

	onSelectAreasComplete(selectedHoleAreaPairs: HoleAreaPair[]) {
		if (selectedHoleAreaPairs != null) {
			this.parentReportFilter.selectedHoleAreaPairs = selectedHoleAreaPairs;
			this.noOfSelectedControllers = selectedHoleAreaPairs.length;
		}
		this.areaSelectorVisible = false;
		if (!this.parentReportFilter.selectedHoleAreaPairs || this.parentReportFilter.selectedHoleAreaPairs.length === 1)
			this.controllersSelected = 'STRINGS.AREA';
		else
			this.controllersSelected = 'STRINGS.AREAS';
	}

	handleHoleAreaChange(value) {
		if (value.selectedCourseId) this.parentReportFilter.selectedCourseId = value.selectedCourseId;
		if (value.selectedHoles) this.parentReportFilter.selectedHoles = value.selectedHoles;
		if (value.selectedAreas) this.parentReportFilter.selectedAreas = value.selectedAreas;
		if (value.selectedHoleAreaPairs) {
			this.parentReportFilter.selectedHoleAreaPairs = value.selectedHoleAreaPairs;
			if (value.selectedHoleAreaPairs != null) {
				this.parentReportFilter.selectedHoleAreaPairs = value.selectedHoleAreaPairs;
				this.noOfSelectedControllers = value.selectedHoleAreaPairs.length;
			}
			if (!this.parentReportFilter.selectedHoleAreaPairs || this.parentReportFilter.selectedHoleAreaPairs.length === 1)
				this.controllersSelected = 'STRINGS.AREA';
			else
				this.controllersSelected = 'STRINGS.AREAS';
		}
	}

	onSiteSelectionComplete(selectedSiteIds: number[]) {
		this.siteManager.selectedSiteIds = selectedSiteIds;
	}

}

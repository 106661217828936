import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import moment from 'moment';

@Component({
	selector: 'rb-invalid-run-station-icon-renderer',
	templateUrl: './invalid-run-station-icon-renderer.component.html',
	styleUrls: ['./invalid-run-station-icon-renderer.component.scss']
})
export class InvalidRunStationIconRendererComponent implements ICellRendererAngularComp {

	data: any;

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.data = params.data;
	}

	refresh(params: any): boolean {
		this.data = params.data;
		return true;
	}

	get isStationAddressInvalid() {
		// If the station address needs a value to be run and if the step run-time is non-zero, the step is
		// invalid and won't actually execute. We show an icon in that case.
		const ms = moment.duration(this.data.baseRunTime).asMilliseconds();
		return !this.data.stationAddressValid && (ms > 0);
	}
}

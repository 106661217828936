<div class="duration-shortcut-container">
    <rb-duration-dialog
        [duration]="defaultRunStationDuration"
        [showHeader]="showHeader"
        [showButtons]="showButtons"
        [hideHours]="showMinuteOnly"
        [hideSeconds]="showMinuteOnly"
        (durationChange)="onDurationSet($event)"
    >
    </rb-duration-dialog>

    <p *ngIf="showMinuteOnly">
        <button
            mat-button
            type="button"
            (click)="showHourAndSecond()"
            class="mat-button mat-primary bordered-icon text-wrapping-button"
            color="primary">
            {{ 'STRINGS.ADJUST_HOURS_SEC' | translate }}
        </button>
    </p>
</div>
import { AcCurrentTestOptions } from '../../api/manual-ops/models/ac-current-test-options.model';
import { CollectionChange } from '../models/collection-change.model';
import { DetectModulesState } from '../../api/controllers/models/detect-modules-state.model';
import { DiagnosticData } from '../../api/manual-ops/models/diagnostic-data.model';
import { DiagnosticsSegmentInfo } from '../../api/manual-ops/models/diagnostics-segment-info.model';
import { GlobalCautionMessageBox } from '../../shared-ui/components/custom-modal/models/alert-modal-options.model';
import { Injectable } from '@angular/core';
import { LxdPingTestItem } from '../../sections/controllers/manual-ops/manual-ops-diagnostics-tab/models/lxd-ping-test-item.model';
import { LxIvmPingTestItem } from '../../sections/controllers/manual-ops/manual-ops-diagnostics-tab/models/lx-ivm-ping-test-item.model';
import { MasterValveStatus } from '../../api/manual-ops/models/master-valve-status.model';
import { MessageBoxInfo } from '../../core/components/global-message-box/message-box-info.model';
import { PingTestItem } from '../../sections/controllers/manual-ops/manual-ops-diagnostics-tab/models/ping-test-item.model';
import { SegmentInfoItem } from '../../sections/controllers/manual-ops/manual-ops-diagnostics-tab/models/segment-info-item.model';
import { Sensor } from '../../api/sensors/models/sensor.model';
import { SensorListItem } from '../../api/sensors/models/sensor-list-item.model';
import { Station } from '../../api/stations/models/station.model';
import { StationListItem } from '../../api/stations/models/station-list-item.model';
import { StationShortReport } from '../../api/manual-ops/models/station-short-report.model';
import { Subject } from 'rxjs';
import { TimerCountdownInfo } from '../../core/components/timer-countdown/timer-countdown-info.model';
import { TwoWireData } from '../../api/manual-ops/models/two-wire-data.model';
import { UniversalDeviceResponseStatuses } from '../../api/manual-ops/models/universal-device-response-statuses.model';
import { UniversalMasterValveStatus } from '../../api/manual-ops/models/universal-master-valve-status.model';
import { UniversalPingResultData } from '../../api/manual-ops/models/universal-ping-result-data.model';
import { UniversalTwoWireData } from '../../api/manual-ops/models/universal-two-wire-data.model';
import { UniversalTwoWireDevicePaths } from '../../api/manual-ops/models/universal-two-wire-device-paths.model';
import { UniversalWeatherSensorStatus } from '../../api/manual-ops/models/universal-weather-sensor-status.model';
import { WeatherSensorStatus } from '../../api/manual-ops/models/weather-sensor-status.model';

import { ControllerChange } from '../../api/signalR/controller-change.model';
import { ControllerDryRunState } from '../../api/signalR/controller-dry-run-state.model';
import { ControllerGetLogsState } from '../../api/signalR/controller-get-logs-state.model';
import { ControllerGetPhysicalDataState } from '../../api/signalR/controller-get-physical-data-state.model';
import { ControllerSyncState } from '../../api/signalR/controller-sync-state.model';
import { FirmwareUpdateProgress } from '../../api/signalR/firmware-update-progress.model';
import { ICIGroupFaultFindingChange } from '../../api/signalR/ici-group-fault-finding-change.model';
import { JobChange } from '../../api/signalR/job-change.model';
import { ProgramChange } from '../../api/signalR/program-change.model';
import { RadioRelayChange } from '../../api/signalR/radio-relay-change.model';
import { RasterTestProgress } from '../../api/signalR/raster-test-progress.model';
import { SensorStatusChange } from '../../api/signalR/sensor-status-change.model';
import { SiteStatusChange } from '../../api/signalR/site-status-change.model';

@Injectable({
	providedIn: 'root',
})
export class BroadcastService {

	showMessageBox = new Subject<MessageBoxInfo>();
	showGlobalCautionMessageBox = new Subject<GlobalCautionMessageBox>();
	showCountdownDialog = new Subject<TimerCountdownInfo>();

	userLoggedIn = new Subject();
	userSignedOut = new Subject();
	cultureChanged = new Subject();
	onUserFeaturesUpdated = new Subject();

	// =========================================================================================================================================================
	// SignalR related Events
	// =========================================================================================================================================================

	detectModulesStateChange = new Subject<DetectModulesState>();
	eventLogsStateChange = new Subject<ControllerGetLogsState>();	// Occurs when GetEventLogs JobRequest and JobCompleted is received.
	getEventLogsJobFinished = new Subject();								// Occurs when GetEventLogs JonFinished is received.
	syncStateChange = new Subject<ControllerSyncState>();
	dryRunStateChange = new Subject<ControllerDryRunState>();
	connectionStarted = new Subject<number>();
	connectionStopped = new Subject<number>();
	connectionFailed = new Subject<number>();
	connectionCompleted = new Subject<number>();
	controllerMoved = new Subject<number>();
	controllerAdded = new Subject<ControllerChange>();
	controllerUpdated = new Subject<ControllerChange>();
	programGroupsBatchUpdated = new Subject<ProgramChange>();
	programGroupsUpdated = new Subject<ProgramChange>();
	programsUpdated = new Subject<ProgramChange[]>();
	diagnosticDataReceived = new Subject<DiagnosticData>();
	firmwareUpdateProgressChange = new Subject<FirmwareUpdateProgress>();
	sensorsUpdated = new Subject<SensorStatusChange[]>();
	radioRelaysUpdated = new Subject<RadioRelayChange>();
	siteStatusChangeCompleted = new Subject<SiteStatusChange[]>();
	startStationJobRequest = new Subject<JobChange>();
	startStationJobRequestCompleted = new Subject<JobChange>();
	tbosManualCommandJobRequestCompleted = new Subject<JobChange>();
	advanceStationJobRequest = new Subject<JobChange>();
	advanceStationJobRequestCompleted = new Subject<JobChange>();
	startLxdDecoderTestJobRequestCompleted = new Subject<JobChange>();
	startProgramJobRequest = new Subject<JobChange>();
	startProgramJobRequestCompleted = new Subject<JobChange>();
	controllerActualAggregateFlowRateChange = new Subject<ControllerChange>();
	irrigationCancelled = new Subject();
	rasterTestProgressChange = new Subject<RasterTestProgress>();
	rasterTestRequestComplete = new Subject<JobChange>();
	weatherSensorStatusChange = new Subject<WeatherSensorStatus>();
	twoWireDataChange = new Subject<TwoWireData>();
	masterValveStatusChange = new Subject<MasterValveStatus>();
	unlockAll = new Subject();
	groupFaultFindingChange = new Subject<ICIGroupFaultFindingChange>();
	controllerRestored = new Subject();
	siteRestored = new Subject();
	userRestored = new Subject();

	universalTwoWireDataChanged = new Subject<UniversalTwoWireData>();
	universalDeviceResponseStatusesChanged = new Subject<UniversalDeviceResponseStatuses>();
	universal2WireDevicePathsChanged = new Subject<UniversalTwoWireDevicePaths>();
	diagnosticsSegmentInfoChanged = new Subject<DiagnosticsSegmentInfo>();
	universalMasterValveStatusChange = new Subject<UniversalMasterValveStatus>();
	universalWeatherSensorStatusChange = new Subject<UniversalWeatherSensorStatus>();
	stationShortReportChange = new Subject<StationShortReport>();
	pingResult = new Subject<TwoWireData>();
	universalPingResult = new Subject<UniversalPingResultData>();
	pingCommunicationFailed = new Subject<ControllerChange>();

	getWeatherSensorStatusCompleted = new Subject<JobChange>();
	getWeatherSensorStatusFailed = new Subject();
	get2WireDataFailed = new Subject();
	getMasterValveStatusCompleted = new Subject<JobChange>();
	getMasterValveStatusFailed = new Subject<string>();
	getUniversal2WireLineSurveyFailed = new Subject<string>();
	getUniversalDeviceResponseStatusesFailed = new Subject<string>();
	getUniversal2WireDevicePathsFailed = new Subject<string>();
	getDiagnosticsSegmentInfoFailed = new Subject<string>();
	getDataPacksRequestComplete = new Subject<string>();
	getShortReportCompleted = new Subject<JobChange>();
	pingRequestCompleted = new Subject<JobChange>();
	universalTwoWireDiagnosticsAcCurrentTestCompleted = new Subject<string>();
	initiateLearnedFlowCompleted = new Subject<JobChange>();

	tbosNetworkConfigurationRequest = new Subject<JobChange>();
	tbosDiscoveryRequest = new Subject<JobChange>();
	tbosNetworkConfigurationRequestCompleted = new Subject<JobChange>();
	tbosDiscoveryRequestCompleted = new Subject<JobChange>();
	
	// Get Physical Data
	physicalDataStateChange = new Subject<ControllerGetPhysicalDataState>();
	getPhysicalDataJobFinished = new Subject();
	getPhysicalDataComplete = new Subject<ControllerGetPhysicalDataState>();
	hasLastSyncDifferenceChanged = new Subject<ControllerChange>();
	
	// =========================================================================================================================================================
	// Generic Broadcast Events
	// =========================================================================================================================================================

	selectedEntityChange = new Subject<any>();
	collectionChange = new Subject<any[]>();
	controllerCollectionChange = new Subject<CollectionChange>();
	associatedEntityChange = new Subject<any>();
	hideGlobalBreadcrumbTrail = new Subject<any>();
	showStationSearch = new Subject<number>();
	showStationDiagnostics = new Subject<number[]>();
	weatherSourcesDeleted = new Subject<number[]>();

	// =========================================================================================================================================================
	// Controller Container Overflow Menu
	// =========================================================================================================================================================

	overFlowManualWaterWindowClick = new Subject();
	overFlowValveTypeClick = new Subject();

	// =========================================================================================================================================================
	// Reports Container Events
	// =========================================================================================================================================================

	backClick = new Subject();
	csvClick = new Subject();
	pdfClick = new Subject();
	shareClick = new Subject();

	// =========================================================================================================================================================
	// Main Navbar Events
	// =========================================================================================================================================================

	alarmEventLogsRequested = new Subject();

	// =========================================================================================================================================================
	// Dashboard Events
	// =========================================================================================================================================================

	removeWidget = new Subject<number>();
	removeLocationByCCWeatherWidget = new Subject<number>();
	canRemoveCCWeatherWidget = new Subject<number>();

	// =========================================================================================================================================================
	// Map Events
	// =========================================================================================================================================================

	stationLocationChanged = new Subject<Station | StationListItem>();
	sensorLocationChanged = new Subject<Sensor | SensorListItem>();
	fullScreenToggleRequested = new Subject();

	// =========================================================================================================================================================
	// Controller Diagnostics Related Events
	// =========================================================================================================================================================

	pingTestItemsChange = new Subject<PingTestItem[]>();
	pingClick = new Subject();
	shortTestItemsChange = new Subject<SegmentInfoItem[]>();
	shortTestClick = new Subject();
	diagRefreshClick = new Subject();
	raterTestClick = new Subject();
	testAllClick = new Subject();
	lxdPingTestItemsChange = new Subject<LxdPingTestItem[]>();
	lxdPingDecodersClick = new Subject();
	lxIvmPingTestItemsChange = new Subject<LxIvmPingTestItem[]>();
	lxIvmPingDecodersClick = new Subject();
	diagnosticShortTestClick = new Subject();
	enableDiagnosticDynamicActionButton = new Subject<boolean>();
	toggleEnergizePath = new Subject<{controllerId: number, options: AcCurrentTestOptions}>();

	// =========================================================================================================================================================
	// Access Request events
	// =========================================================================================================================================================
	newAccessRequest = new Subject<string>();
	accessRequestCanceled = new Subject<string>();

	// =========================================================================================================================================================
	// Browser visibility state changes. May also be used to detect sleep on iPad, etc. Sending the hidden data in an object allows for
	// future expansion.
	// =========================================================================================================================================================

	private visibilityChangeListenerInitialized = false;

	visibilityChange = new Subject<{hidden: boolean}>();

	public initializeVisibilityChangeListener(): void {
		if (!this.visibilityChangeListenerInitialized) {
			// Set up the visibility state change operations, since we can manage those from here for everyone.
			document.addEventListener('visibilitychange', () => {
				// Send broadcast.
				this.visibilityChange.next( { hidden: document.hidden } );
			});
			this.visibilityChangeListenerInitialized = true;
		}
	}
}

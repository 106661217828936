import { Observable, of, switchMap, tap } from 'rxjs';

import { AuthManagerService } from '../auth/auth-manager-service';
import { GprsLicenseListItem } from '../license-api-cloud/models/gprs-license-list-item.model';
import { Injectable } from '@angular/core';
import { LicenseApiCloudManagerService } from '../license-api-cloud/license-api-cloud-manager.service';
import { LicenseManagerService } from '../license/license-manager.service';

@Injectable({
providedIn: 'root'
})
export class DigitalManagerService {
	private readonly baseUrl = 'http://digitalservices.rainbird.com/index.php?';

	constructor(
		private authManager: AuthManagerService,
		private licenseManager: LicenseManagerService,
		private licenseApiCloudManagerService: LicenseApiCloudManagerService
	) { }

	openDigitalUrl(): Observable<any> {
		return this.licenseManager.isCloud().pipe(
			switchMap((isCloud) => {
				if (isCloud) {
					this.openCloudUrl();
					return of(true);
				} else {
					return this.licenseApiCloudManagerService.getGprsLicenseDataForCompany(false)
						.pipe(tap((list) => {
							this.setStandaloneUrl(list);
						}));
				}
			})
		);
	}

	private openCloudUrl() {
		const userProfile = this.authManager.getUserProfile();
		window.open(`${this.baseUrl}cid=${userProfile.companyId}&api=4`);
	}

	private setStandaloneUrl(list: GprsLicenseListItem[]) {
		// Standalone URL won't work.
		// According to product owner(Rick) We do not have any standalone users and
		// we will not for a while so we will implement correct URL later
		const ipArray = list.map(item => item.ipAddress);
		window.open(`${this.baseUrl}data=${ipArray.join()}`, '_blank');
	}
}

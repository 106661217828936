import { ApiService, HttpMethod } from '../_common/api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LicenseApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	activateLicense(activationCode: string): Observable<boolean> {
		return this.apiRequest<any>(this.activateUrl(activationCode), HttpMethod.Get);
	}

	allowGolfUpgraded(): Observable<boolean> {
		return this.apiRequest<boolean>(this.allowGolfUpgradedUrl(), HttpMethod.Get);
	}

	getComputerId(): Observable<any> {
		return this.apiRequest<any>(this.computerId(), HttpMethod.Get);
	}

	getComputerIdAndActivationCodes(): Observable<any> {
		return this.apiRequest<any>(this.getComputerIdAndActivationCodesUrl(), HttpMethod.Get);
	}

	getFeatureNames(): Observable<string[]> {
		return this.apiRequest<any>(this.featureNames(), HttpMethod.Get)
			.pipe(map(list => list));
	}

	getProductName(): Observable<string> {
		return this.apiRequest<any>(this.productName, HttpMethod.Get)
			.pipe(map(result => result.stringValue));
	}

	isCloud(): Observable<boolean> {
		return this.apiRequest<boolean>(this.isCloudUrl(), HttpMethod.Get);
	}

	isActivated(): Observable<boolean> {
		return this.apiRequest<boolean>(this.isActivatedUrl, HttpMethod.Get);
	}

	allowGolf(): Observable<boolean> {
		return this.apiRequest<boolean>(this.allowGolfUrl, HttpMethod.Get);
	}

	/**
	 * Get indication of whether current company database activation code is "right" for the machine where it's running.
	 * If not, this may indicate that we're running a customer database on an engineering or QA machine, or that the
	 * customer's machine has changed, invalidating the license. We want to know this in case the "change" was accidental
	 * (using a different active network adapter). It allows us to notify the user that their DNS IP address won't be 
	 * updated, their certificate won't be updated, etc.
	 * @returns boolean - true if the license is valid for this machine; false if not.
	 */
	isCompanyLicenseValid(): Observable<boolean> {
		return this.apiRequest<boolean>(this.isCompanyLicenseValidUrl, HttpMethod.Get);
	}
	
	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}License`; }

	private activateUrl(activationCode: string): string {
		// const t = `${this.baseUrl}/Activate?license=${activationCode}`;
		return `${this.baseUrl}/Activate?license=${activationCode}`;
	}

	private allowGolfUpgradedUrl(): string {
		return `${this.baseUrl}/AllowGolfUpgraded`;
	}

	private computerId(): string {
		return `${this.baseUrl}/GetComputerId`;
	}

	private featureNames(): string {
		return `${this.baseUrl}/GetFeatureNames`;
	}

	private get isActivatedUrl(): string {
		return `${this.baseUrl}/IsActivated`;
	}

	private get allowGolfUrl(): string {
		return `${this.baseUrl}/AllowGolf`;
	}

	private isCloudUrl(): string {
		return `${this.baseUrl}/IsCloud`;
	}

	private getComputerIdAndActivationCodesUrl(): string {
		return `${this.baseUrl}/GetComputerIdAndActivationCodes`;
	}

	private get isCompanyLicenseValidUrl(): string {
		return `${this.baseUrl}/IsCompanyLicenseValid`;
	}

	private get productName(): string { return `${this.baseUrl}/GetProductName`; }
}

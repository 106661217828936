import { EventEmitter, Injectable } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CCWeatherApiService } from './ccweather-api.service';
import { CCWeatherCondition } from './models/ccweather-condition.model';
import { CCWeatherData } from './models/ccweather-data.model';
import { CCWeatherIntervalData } from './models/ccweather-interval-data.model';
import { CCWeatherSettings } from './models/ccweather-settings.model';
import { CCWeatherValuesData } from './models/ccweather-values-data.model';
import { RbGridsterWidget } from '../../dashboard/components/widgets/rb-gridster-widget';
import { RbUtils } from '../../common/utils/_rb.utils';
import { SiteManagerService } from '../sites/site-manager.service';
import { UnitLabelService } from '../../common/services/unit-label.service';

export enum WeatherTimeStep {
	CURRENT = 'current',
	HOUR = '1h',
	DAY = '1d'
}

@Injectable({
	providedIn: 'root'
})
export class CCWeatherDataService {
	weatherForecastLoaded = new EventEmitter();
	weatherWidgetNotInitialized = new EventEmitter();
	noDataForWidget = new EventEmitter();

	readonly DAY_NIGHT_DOUBLE_CODES = [1000, 1100, 1101];
	readonly NIGHT_CODE_PART = '_night';

	settingsCollection: CCWeatherSettings[] = [];
	weatherForecastData: { widgetId: number, weatherData: CCWeatherData[] }[] = [];
	isGolfSite = false;

	conditionsTemplate: CCWeatherCondition[] = [
		{fieldName: 'temperature', units: '°C', iconName: 'temp_small', hint: 'TEMPERATURE', show: true},
		{fieldName: 'temperatureMinMax', units: '°C', iconName: 'temp_lo_hi', hint: 'TEMPERATURE_MIN_MAX', show: false},
		{fieldName: 'wind', units: 'm/s', iconName: 'wind_small', hint: 'WIND', show: true},
		{fieldName: 'dewPoint', units: '°C', iconName: 'dew_point_small', hint: 'DEW_POINT', show: true},
		{fieldName: 'pressureSurfaceLevel', units: 'hPa', iconName: 'pressure_small', hint: 'PRESSURE', show: true},
		{fieldName: 'humidity', units: '%', iconName: 'humidity_small', hint: 'HUMIDITY', show: true},
		{fieldName: 'precipitationIntensity', units: 'mm', iconName: 'precipitation_small', hint: 'PRECIPITATION', show: true},
		{fieldName: 'et0', units: 'mm', iconName: 'ET0_small', hint: 'ET0', show: true},
	];

	blocksTemplate: { name: string, show: boolean }[] = [
		{name: 'HOURLY_FORECAST', show: true},
		{name: 'DAILY_FORECAST', show: true},
	];

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private broadcastService: BroadcastService,
				private ccWeatherApiService: CCWeatherApiService,
				private siteManager: SiteManagerService,
				private unitLabelService: UnitLabelService
	) {
		this.broadcastService.removeLocationByCCWeatherWidget
			.subscribe(widgetId => this.removeWidget(widgetId));

		this.isGolfSite = this.siteManager.isGolfSite;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	/**
	 * Start a weather forecast request and save the resulting data based on the target widgetId value.
	 * @param widgetId - Id value for the widget. Generally this is the number assigned to the widget when it was inserted
	 * into the dashboard, so will be a small integer value
	 * @param forced - boolean set to true indicating that we should not reuse any existing cached forecast data but should
	 * request the latest data from Tomorrow.io. NOTE: WE ALWAYS CALL THE CORE API HERE; THIS PARAMETER IS PASSED TO CORE API
	 * INDICATING THAT A TRANSACTION WITH Tomorrow.io SHOULD OCCUR.
	 * @returns boolean true if the necessary settings and configuration parameters to make the Tomorrow.io call were located;
	 * false on error. If false, the caller should treat the widget as misconfigured and should suggest the user reconfigure
	 * or replace it
	 */
	getWeatherForecastRequest(widgetId: number, forced: boolean = false): boolean {
		// Get the widget's database Id and ask for the forecast.
		const settings = this.settingsCollection.find(s => s.widgetId === widgetId);

		// RB-10385: If the settings were not found (!), don't ask for the forecast. Indicate that the user should recreate
		// the widget or reselect the right location. When upgrading from earlier versions of the widget, databaseWidgetId
		// may not exist. In that case, we tell the caller that we can't get it and expect it to show a suitable message or
		// force the user to reenter settings.
		if (!(settings && settings.databaseWidgetId)) {
			console.error(`Weather forecast widget must be reconfigured. Remove and recreate it.`);
			return false;
		}

		// Get the forecast data and set the attributes based on the widget Id.
		this.ccWeatherApiService.getForecast(settings.databaseWidgetId, forced).pipe(
			take(1),
			finalize(() => {
				this.weatherForecastLoaded.emit();
			})
		).subscribe(weatherData => {
			const forecast = this.weatherForecastData.find(data => data.widgetId === widgetId);
			const currentWeather = new CCWeatherData(weatherData.find(interval => interval.timestep === WeatherTimeStep.CURRENT));
			const hourlyWeather = new CCWeatherData(weatherData.find(interval => interval.timestep === WeatherTimeStep.HOUR));
			const dailyWeather = new CCWeatherData(weatherData.find(interval => interval.timestep === WeatherTimeStep.DAY));
			const currentDate = new Date().getDate();
			const currentHour = new Date().getHours();

			// RB-14384: Take care with assuming we'll find an intervals[0] value that works for "today"; we may not. For example, 
			// when there's an issue with the widget information or just no data from today. If we don't find anything, we drop 
			// through and continue.
			while (dailyWeather.intervals[0] != null && currentDate !== new Date(dailyWeather.intervals[0].startTime).getDate()) {
				dailyWeather.intervals.shift();
				if (dailyWeather.intervals.length === 0) {
					if (forecast) {
						forecast.weatherData.length = 0;
						forecast.weatherData.push(currentWeather);
						forecast.weatherData.push(new CCWeatherData({timestep: WeatherTimeStep.HOUR}));
						forecast.weatherData.push(new CCWeatherData({
							timestep: WeatherTimeStep.DAY,
							intervals: [new CCWeatherIntervalData({
								startTime: currentWeather.intervals[0].startTime
							})]
						}));
					} else {
						this.weatherForecastData.push({
							widgetId: widgetId,
							weatherData: [
								currentWeather,
								new CCWeatherData({timestep: WeatherTimeStep.HOUR}),
								new CCWeatherData({
									timestep: WeatherTimeStep.DAY,
									intervals: [new CCWeatherIntervalData({
										startTime: currentWeather.intervals[0].startTime
									})]
								})]
						});
					}
				}
			}

			// RB-14384: Same note as above: Don't assume that we'll always get weather! Our customer number might have changed, 
			// for example, causing us to not authenticate with the widget data server correctly or something. If we don't find
			// the data we want, we fall through.
			while (hourlyWeather.intervals[3] != null && (currentDate !== new Date(hourlyWeather.intervals[3].startTime).getDate() ||
				currentHour !== new Date(hourlyWeather.intervals[3].startTime).getHours())) {
				hourlyWeather.intervals.shift();
			}

			// RB-14384: If we don't have the data, return false.
			if (currentWeather.intervals[0] == null || hourlyWeather.intervals[3] == null || dailyWeather.intervals[0] == null) {
				console.warn('Unable to retrieve weather forecast. Check customer number and activation code.');
				return false;
			}

			currentWeather.intervals[0].values.temperature = currentWeather.intervals[0].values.temperatureMax;
			currentWeather.intervals[0].values.temperatureMin = dailyWeather.intervals[0].values.temperatureMin;
			currentWeather.intervals[0].values.temperatureMax = dailyWeather.intervals[0].values.temperatureMax;
			currentWeather.intervals[0].values.sunriseTime = dailyWeather.intervals[0].values.sunriseTime;
			currentWeather.intervals[0].values.sunsetTime = dailyWeather.intervals[0].values.sunsetTime;
			hourlyWeather.intervals[3].values.sunriseTime = dailyWeather.intervals[0].values.sunriseTime;
			hourlyWeather.intervals[3].values.sunsetTime = dailyWeather.intervals[0].values.sunsetTime;
			dailyWeather.intervals[0].startTime = currentWeather.intervals[0].startTime;
			currentWeather.intervals.forEach(interval => {
				interval.values = this.updateValues(interval.values);
			});
			hourlyWeather.intervals.forEach(interval => {
				interval.values = this.updateValues(interval.values);
			});
			dailyWeather.intervals.forEach(interval => {
				interval.values = this.updateValues(interval.values);
			});
			if (forecast) {
				forecast.weatherData.length = 0;
				forecast.weatherData.push(currentWeather);
				forecast.weatherData.push(hourlyWeather);
				forecast.weatherData.push(dailyWeather);
			} else {
				this.weatherForecastData.push({widgetId: widgetId, weatherData: [currentWeather, hourlyWeather, dailyWeather]});
			}
		}, (error) => {
			// IQ4 Sidebar Widget
			// When used as part of the controller sidebar, we will initially have no WeatherForecastWidget in the database. This error handler
			// traps that state and emits the following event, which will trigger the creation of thw Widget/Location.
			if (error.error.indexOf("No data found for widget") !== -1) {
				this.noDataForWidget.emit();
			} else {
				this.weatherWidgetNotInitialized.emit();
			}
			return false;
		});
		return true;
	}

	getWeatherForecast(widgetId: number, type: WeatherTimeStep): CCWeatherData {
		const forecastData = this.weatherForecastData.find(forecast => forecast.widgetId === widgetId);
		if (forecastData) {
			const forecast = forecastData.weatherData.find(forecast => forecast.timestep === type);
			return forecast ? forecast : new CCWeatherData(); 
		} else {
			return new CCWeatherData();
		}
	}

	getWeatherName(weatherCode) {
		switch (weatherCode) {
			case 0:
				return 'Unknown';
			case 1000:
				return 'Sunny';
			case 1000 + this.NIGHT_CODE_PART:
				return 'Clear';
			case 1001:
				return 'Cloudy';
			case 1100:
				return 'Mostly Clear';
			case 1100 + this.NIGHT_CODE_PART:
				return 'Mostly Clear';
			case 1101:
				return 'Partly Cloudy';
			case 1101 + this.NIGHT_CODE_PART:
				return 'Partly Cloudy';
			case 1102:
				return 'Mostly Cloudy';
			case 2000:
				return 'Fog';
			case 2100:
				return 'Light Fog';
			case 3000:
				return 'Light Wind';
			case 3001:
				return 'Wind';
			case 3002:
				return 'Strong Wind';
			case 4000:
				return 'Drizzle';
			case 4001:
				return 'Rain';
			case 4200:
				return 'Light Rain';
			case 4201:
				return 'Heavy Rain';
			case 5000:
				return 'Snow';
			case 5001:
				return 'Flurries';
			case 5100:
				return 'Light Snow';
			case 5101:
				return 'Heavy Snow';
			case 6000:
				return 'Freezing Drizzle';
			case 6001:
				return 'Freezing Rain';
			case 6200:
				return 'Light Freezing Rain';
			case 6201:
				return 'Heavy Freezing Rain';
			case 7000:
				return 'Ice Pellets';
			case 7101:
				return 'Heavy Ice Pellets';
			case 7102:
				return 'Light Ice Pellets';
			case 8000:
				return 'Thunderstorm';
			default:
				return 'Unknown';
		}
	}

	getSettings(widgetId: number, conditionsArray: string[], isVisibleOnly: boolean = false): CCWeatherSettings {
		// The JSON code below essentially clones the settings so we can modify our own copy as-necessary.
		const settings: CCWeatherSettings = JSON.parse(JSON.stringify(this.settingsCollection.find(s => s.widgetId === widgetId)));
		const conditionList: CCWeatherCondition[] = [];
		conditionsArray.forEach(conditionName => {
			const conditionObj: CCWeatherCondition = settings.conditions.find(condition => condition.fieldName === conditionName);
			if (conditionObj && (!isVisibleOnly || conditionObj.show)) {
				conditionList.push(conditionObj);
			}
		});
		settings.conditions = conditionList;
		return settings;
	}

	/**
	 * Initialize the widget settings data. This involves an API call, so do not expect immediate results. This is needed because the
	 * location elevation is part of the settings, but it has to be retrieved from the API database so we have the latest value. When
	 * the data is loaded, we update the settingsCollection with the latest settings, if needed.
	 * @param associatedWidget - RbGridsterWidget which is really a weather forecast widget.
	 */
	initSettings(associatedWidget: RbGridsterWidget) {
		let settings = this.settingsCollection.find(s => s.widgetId === associatedWidget.uniqueId);
		if (!settings) {
			// Clone the default settings. Our new settings entry will be based on these defaults.
			const conditionsTemplate = JSON.parse(JSON.stringify(this.conditionsTemplate));
			const blocksTemplate = JSON.parse(JSON.stringify(this.blocksTemplate));
			settings = {
					widgetId: associatedWidget.uniqueId,
					conditions: conditionsTemplate,
					visibleBlocks: blocksTemplate,
					siteId: associatedWidget.siteId,
					databaseWidgetId: associatedWidget.databaseWidgetId,
					locationId: associatedWidget.locationId,

					// We have to put something in the settings for the elevation.
					elevationFT: CCWeatherSettings.DefaultElevationFT,
			};
			this.settingsCollection.push(settings);
		}

		// Adjust display unit strings based on user-selected units system.
		this.updateUnits(settings.conditions);

		// Hide any conditions in the condition list for settings which are not in the visible list for the widget.
		settings.conditions.forEach(condition => {
			condition.show = associatedWidget.visibleConditions.find(fieldName => fieldName === condition.fieldName) !== undefined;
		});

		// Hide any conditions in the settings which the widget does not display at all. This might be used when
		// displaying some combination of things where one or more of the items don't make sense.
		if (associatedWidget.conditions && associatedWidget.conditions.length > 0) {
			settings.conditions.forEach(condition => {
				condition.show = associatedWidget.conditions.find(fieldName => fieldName === condition.fieldName) !== undefined;
			});
		}

		// Copy the visibleBlocks data from the widget to the settings.
		settings.visibleBlocks.forEach(block => {
			block.show = associatedWidget.visibleBlocks.find(blockName => blockName === block.name) !== undefined;
		});

		// While we can process some of the data locally, we need the elevation data for the widget's location from the API. We'll look
		// it up and fill-in the values when it arrives.
		if (associatedWidget.locationId != null) {
			this.ccWeatherApiService.getLocation(associatedWidget.locationId).pipe(take(1)).subscribe(location => {
				// Location can be null, if the location was not found by the API. No need to do anything else if that occurs.
				if (location != null) {
					// Find the settings and fill-in the location elevation.
					const esettings = this.settingsCollection.find(s => s.widgetId === associatedWidget.uniqueId);
					if (esettings != null) {
						esettings.elevationFT = location.elevationFT;
					}
				}
			});
		}
	}

	setSettings(
		conditionsArray: CCWeatherCondition[],
		visibleBlocks: { name: string, show: boolean }[],
		siteId: number,
		widgetId: number,
		locationId: number,
		databaseWidgetId: number,
		elevationFT: number,
		weatherSourceId?: number
	) {
		const settings = this.settingsCollection.find(s => s.widgetId === widgetId);
		// IQ4 Sidebar Widget
		if (conditionsArray != null) settings.conditions = conditionsArray;
		if (visibleBlocks && visibleBlocks.length > 0 && visibleBlocks[0] instanceof Object) settings.visibleBlocks = visibleBlocks;
		settings.siteId = siteId;
		settings.databaseWidgetId = databaseWidgetId;
		settings.locationId = locationId;
		settings.elevationFT = elevationFT;
		if (weatherSourceId != null) { settings.weatherSourceId = weatherSourceId; }
	}

	private removeWidget(widgetId: number) {
		const settingsIndex = this.settingsCollection.findIndex(s => s.widgetId === widgetId);
		if (settingsIndex < 0 || !this.settingsCollection[settingsIndex].locationId) {
			this.broadcastService.canRemoveCCWeatherWidget.next(widgetId);
			return;
		}
		const settings = this.settingsCollection[settingsIndex];

		// RB-10385: We have to handle the case where the settings are from an old version of the widget and the user
		// wants to delete it. In that case, we take a somewhat unusual approach: go ahead and call the API, which will
		// fail, and handle the failure by allowing the widget to be deleted. This minimizes the number of paths through
		// the code. We don't show a toast message for errors in this API call, so the user is informed of the situation
		// only through the console log.
		this.ccWeatherApiService.deleteWidget(settings.databaseWidgetId)
			.pipe(
				take(1),
				finalize(() => {
				})
			)
			.subscribe(() => {
					this.settingsCollection.splice(settingsIndex, 1);
					const weatherDataIndex = this.weatherForecastData.findIndex(obj => obj.widgetId === widgetId);
					if (weatherDataIndex > -1) {
						this.weatherForecastData.splice(weatherDataIndex, 1);
					}
					this.broadcastService.canRemoveCCWeatherWidget.next(widgetId);
				},
				error => {
					console.warn('ccWeatherApiService.deleteLocation error %o. Removing the widget and continuing...', error);

					// When we handle an error from deleteLocation, remove the widget anyway. There's little reason to leave it around if
					// the location failed or something. The original code is copied below showing which errors were allowed to prevent
					// removal previously.
					// if (error && error.status < 500 && error.status >= 400) {
					this.broadcastService.canRemoveCCWeatherWidget.next(widgetId);
				}
			);
	}

	updateUnits(conditionsArray) {
		const obj = this.unitLabelService.getWeatherLabels();
		conditionsArray.forEach(value => {
			switch (value.fieldName) {
				case 'temperature':
				case 'temperatureMinMax':
				case 'dewPoint':
					value.units = obj.TEMP;
					break;
				case 'wind':
					value.units = obj.WIND;
					break;
				case 'pressureSurfaceLevel':
					value.units = obj.PRESSURE;
					break;
				case 'humidity':
					value.units = obj.HUMIDITY;
					break;
				case 'precipitationIntensity':
				case 'et0':
					value.units = obj.AMOUNT;
					break;
			}
		});
	}

	/**
	 * updateValues adjusts values into the user's units system, modifying the incoming valuesData object directly. Note:
	 * If an incoming data value is unknown/null, we do not perform a conversion leaving the value as-is in the data structure.
	 * @param valuesData - CCWeatherValuesData object containing properties for each of the weather data values received from
	 * Tomorrow.io
	 */
	updateValues(valuesData: CCWeatherValuesData) {
		// RB-13753: removed the + sign from RbUtils.Sensor... as those methods always return a string,
		// but when there's a comma, is causing to show values as NaN in the weather forecast widget.
		for (const key in valuesData) {
			if (valuesData[key] !== '-') {
				switch (key) {
					case 'temperature':
					case 'temperatureMin':
					case 'temperatureMax':
					case 'dewPoint':
						// Incoming data is in degrees C. Adjust if in English units. Do not include the units string in the result, just
						// the value.
						valuesData[key] = valuesData[key] == null ? null :
							RbUtils.Sensor.getGolfSensorTemperatureStringForC(valuesData[key], false);
						break;
					case 'precipitationIntensity':
					case 'et0':
						// mm
						valuesData[key] = valuesData[key] == null ? null :
							RbUtils.Sensor.getGolfSensorRainfallStringForMM(valuesData[key], false);
						break;
					case 'pressureSurfaceLevel':
						// hPa
						valuesData[key] = valuesData[key] == null ? null :
							RbUtils.Sensor.getBarometricPressureString(valuesData[key], false);
						break;
					case 'windSpeed':
						// m/s
						valuesData[key] = valuesData[key] == null ? null :
							RbUtils.Sensor.getGolfSensorWindSpeedStringForMS(valuesData[key], false);
						break;
					case 'humidity':
						// %, but round to significant decimal places. Note that if the fractional part is zero this may be rendered as
						// "31" rather than "31.0", so you may need to stringify with desired decimal place count.
						valuesData[key] = valuesData[key] == null ? null :
							RbUtils.Sensor.getHumidityString(valuesData[key]);
						break;
					default:
						break;
				}
			}
		}
		return valuesData;
	}
}

<div class="rb-editable-grid-container">
	<ag-grid-angular
		#agGrid
		class="ag-theme-material"
		[ngStyle]="{'height': height, 'width': width}"
		[gridOptions]="gridOptions"
		[defaultColDef]="defaultColumnDef"
		[columnDefs]="columnDefs"
		[rowData]="rowData"
		[frameworkComponents]="frameworkComponents"
		[enterMovesDown]="true"
		[enterMovesDownAfterEdit]="true"
	></ag-grid-angular>
</div>

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="showToolbar" [ngClass]="{'player-menu': !isMobile, 'player-menu-mobile': isMobile}">
	<div [ngClass]="{'player-menu-sub-container': !isMobile, 'player-menu-mobile-sub-container': isMobile}">

		<ul *ngFor="let g of toolbarInfo?.groups" [ngClass]="{'player-menu-mobile-container list justify-center': isMobile}">
			<li *ngFor="let btn of g.buttons">
				<rb-icon-button
					[id]="btn.qaId"
					[class]="btn.itemClass"
					[ngClass]="btn.ngClass"
					[icon]="btn.icon ? btn.icon : btn.dynamicIcon"
					[label]="(btn.label ? btn.label : btn.dynamicLabel) | translate"
					[animationType]="btn.animationType"
					[isAnimating]="btn.isAnimating"
					[controllerListItem]="controllerListItem"
					[isControllerListItemEventDisabled]="btn.qaId == 'Edit'"
					[color]="btn.color"
					[disabled]="btn.isDisabled"
					(iconButtonClicked)="btn.onButtonClick()"
					attr.data-qaid="detail-panel-button-{{(btn.label || btn.dynamicLabel) | replaceWhitespace: '_' }}"
				>
				</rb-icon-button>
			</li>
		</ul>

		<ul *ngIf="hasMoreButton" [ngClass]="{'list': isMobile}">
			<li class="ma0">
				<rb-icon-button
					id="More"
					[mat-menu-trigger-for]="moreMenu"
					icon="more_horiz"
					[label]="'STRINGS.MORE' | translate"
					data-qaid="detail-panel-more-menu-button">
				</rb-icon-button>

				<mat-menu #moreMenu="matMenu" class="rb-more-menu" [overlapTrigger]="false">
					<!-- Toolbar overflow goes here -->
					<button
						*ngFor="let item of toolbarMenuItems"
						class="rb-toolbar-menu-item"
						mat-menu-item
						[disabled]="item.isDisabled"
						(click)="item.onButtonClick()"
						attr.data-qaid="detail-panel-more-menu-item-{{(item.label || item.dynamicLabel) | replaceWhitespace: '_' }}"
					>
						<rb-icon-button
							[hideLabel]="true"
							[class]="item.itemClass"
							[ngClass]="item.ngClass"
							[icon]="item.icon ? item.icon : item.dynamicIcon"
							[label]="(item.label ? item.label : item.dynamicLabel) | translate"
							[animationType]="item.animationType"
							[isAnimating]="item.isAnimating"
							[color]="item.color"
							[disabled]="item.isDisabled"
							(iconButtonClicked)="item.onButtonClick()"
						>
						</rb-icon-button>
						{{item.label | translate}}
					</button>

					<div *ngIf="toolbarMenuItems.length > 0 && overflowMenu" class="more-menu-separator"><hr></div>

					<ng-container [ngTemplateOutlet]="overflowMenu"></ng-container>

					<ng-container [ngTemplateOutlet]="exportMenu"></ng-container>

				</mat-menu>

			</li>
		</ul>

	</div>
</div>


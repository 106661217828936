import * as moment from 'moment';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { RbConstants } from '../../../../common/constants/_rb.constants';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../common/utils/_rb.utils';
import { SelectedStationInfo } from '../../course-viewer/models/selected-station-info.model';
import { SelectListItem } from '../../../../api/_common/models/select-list-item.model';
import { TranslateService } from '@ngx-translate/core';

import ActiveFlowView = RbEnums.Common.ActiveFlowView;

@UntilDestroy()
@Component({
	selector: 'rb-active-flow-toolbar',
	templateUrl: './active-flow-toolbar.component.html',
	styleUrls: ['./active-flow-toolbar.component.scss']
})
export class ActiveFlowToolbarComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'rb-active-flow-toolbar';

	// @ts-ignore
	@Output() dateRangeChange = new EventEmitter<RbConstants.Form.DATE_RANGE>();
	@Output() activeFlowViewChange = new EventEmitter<RbEnums.Common.ActiveFlowView>();
	@Output() rangeDatesChange = new EventEmitter<{ startDate: Date, endDate: Date }>();
	@Output() activityTypeChange = new EventEmitter<RbEnums.Common.ActivityType>();
	@Output() generatePdfClick = new EventEmitter();
	@Output() stopStations = new EventEmitter();
	@Output() startStations = new EventEmitter();
	@Output() pauseStations = new EventEmitter();
	@Output() resumeStations = new EventEmitter();
	@Output() selectedCourseViewStationsChange = new EventEmitter<SelectedStationInfo[]>();
	@Output() onShowPumpDataChange = new EventEmitter<boolean>();

	// Constant / Enum References
	RbUtilsDateRange = RbUtils.DateRange;
	rbDateRange = RbConstants.Form.DATE_RANGE;
	ActiveFlowView = RbEnums.Common.ActiveFlowView;

	private _activeFlowView = RbEnums.Common.ActiveFlowView.Chart;
	private _dateRange = RbConstants.Form.DATE_RANGE.twelveHours;
	private _activityType = RbEnums.Common.ActivityType.Station;
	private _showPumpData: boolean;

	@Input() selectedCourseViewStations: SelectedStationInfo[];
	@Input() isPumpDataCheckIndeterminate: boolean = false;

	@Input() set showPumpData(value: boolean) {
		if (this._showPumpData === value) return;
		this.onShowPumpDataChange.emit(value);
		this._showPumpData = value;
	}

	get showPumpData(): boolean {
		return this._showPumpData;
	}

	@Input() set activeFlowView(value: RbEnums.Common.ActiveFlowView) {
		if (this._activeFlowView === value) return;

		this._activeFlowView = value;
		this.activeFlowViewChange.emit(value);
	}

	get activeFlowView(): RbEnums.Common.ActiveFlowView {
		return this._activeFlowView;
	}

	// @ts-ignore
	@Input() set dateRange(value: RbConstants.Form.DATE_RANGE) {
		if (this._dateRange === value) return;

		this._dateRange = value;
		this.dateRangeChange.emit(value);
		this.setRangeDates();
	}

	// @ts-ignore
	get dateRange(): RbConstants.Form.DATE_RANGE {
		return this._dateRange;
	}

	@Input() set activityType(value: RbEnums.Common.ActivityType) {
		if (this._activityType === value) return;

		this._activityType = value;
		this.activityTypeChange.emit(value);
	}

	get activityType(): RbEnums.Common.ActivityType {
		return this._activityType;
	}

	@Input() activityTypes: SelectListItem[];
	@Input() disabled = false;
	@Input() isPrintEnabled = false;
	@Input() hidePrintButton = false;
	@Input() disableResume = true;

	isMobile = false;
	startDate: Date;
	endDate: Date;

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(private deviceManager: DeviceManagerService,
		private translateService: TranslateService,
	) { }

	ngOnInit() {
		this.isMobile = this.deviceManager.isMobile;

		if (this.deviceManager.isMobile && this.activeFlowView === ActiveFlowView.Chart) {
			setTimeout(() => this.activeFlowView = RbEnums.Common.ActiveFlowView.Chart);
		}

		// Monitor mobile resizing
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => this.isMobile = isMobile);

		if (!this.startDate || !this.endDate) {
			this.setRangeDates();
		}
	}

	ngOnDestroy(): void {
		/** Required for untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	generatePdf() {
		this.generatePdfClick.emit();
	}

	onStopStations() {
		this.stopStations.emit();
	}

	onStartStations() {
		this.startStations.emit();
	}

	onPauseStations() {
		this.pauseStations.emit();
	}

	onResumeStations() {
		this.resumeStations.emit();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	// @ts-ignore
	private setRangeDates() {
		const endDateMoment = moment();
		this.endDate = endDateMoment.toDate();

		switch (this.dateRange) {
			case RbConstants.Form.DATE_RANGE.twelveHours:
				this.startDate = endDateMoment.subtract(12, 'hours').toDate();
				break;
			case RbConstants.Form.DATE_RANGE.oneDay:
				this.startDate = endDateMoment.subtract(1, 'days').toDate();
				break;
		}

		this.rangeDatesChange.emit({ startDate: this.startDate, endDate: this.endDate });
	}

	getHourShortString(hours: number) {
		return this.translateService.instant('DATE_RANGES.HOUR_SHORT_FORMAT', { hours: hours });
	}
}

import { Program } from './program.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

/**
 * Type returned from: /api/Program/GetProgramListForMultiSites
 */
export class ProgramListItem {

	constructor(json: any = null, programStatus: string = null) {
		if (json) {
			Object.assign(this, json);

			if (json.nextCyclicalStartDate) { this.nextCyclicalStartDate = RbUtils.Conversion.convertStringToDate(json.nextCyclicalStartDate); }
			if (json.startTime) {
				this.startTime = RbUtils.Conversion.convertStringToDate(json.startTime);
				if (this.startTime.getFullYear() > 1901) {
					this.startTime.setFullYear(2000, 1, 1);
				}
			}
		} else {
			this.programGroupId = null;
		}

		// RB-12641: status is being changed back to '-' after updating a program while is running,
		// the model by default has status of '-', causing to be overwrited.
		// We pass programStatus to the constructor to preserve the status.
		if (programStatus) this.status = programStatus;
	}

	etAdjustType: RbEnums.Common.EtAdjustType;
	id: number;
	hybridWeekDays: string;
	name: string;
	nextCyclicalStartDate: Date;
	numberOfProgramSteps: number;
	programAdjust: number;
	tempAdjustStart: Date;
	tempAdjustStop: Date;
	tempAdjustDays: number;
	tempProgramAdjust: number;
	programGroupId: number;
	satelliteId: number;
	satelliteName: string;
	shortName: string;
	simultaneousStations: number;
	siteId: number;
	siteName: string;
	skipDays: number;
	stationDelay: string;
	stationDelayLong: number;
	startTime: Date;
	type: RbEnums.Common.ProgramType;
	waterDays: string;
	weekDays: string;
	number: number;
	isEnabled: boolean;
	weekDaysAdvEt: string;

	// NOTE: DO NOT PERFORM ANY TRANSLATION HERE! We rely on the english value of the status string in the ProgramStatusCellRenderer.
	status = '-';

	get isEtAdjusted(): boolean { return Program.getIsEtAdjusted(this.etAdjustType); }

	get stations(): string {
		return this.numberOfProgramSteps === 0 ? '-' : `${this.numberOfProgramSteps}`;
	}

	// NOTE: Used by Stations Column on program-group-schedule-tab component grid.
	get stationsCountForHyperlink(): string {
		return this.numberOfProgramSteps === 0
			? RbUtils.Translate.instant('STRINGS.ADD_STATION')
			: `${this.numberOfProgramSteps} ${RbUtils.Translate.instant(this.numberOfProgramSteps === 1 
				? 'STRINGS.STATION_LOWERCASE' : 'STRINGS.STATIONS_LOWERCASE')}`;
	}

	get siteNameGridColumn(): string {
		return this.siteName ? this.siteName : '-';
	}

	get satelliteNameGridColumn(): string {
		return this.satelliteName ? this.satelliteName : '-';
	}

	get waterCycle(): string {
		return RbUtils.Weekdays.getStartDays(this.type, this.weekDays, this.hybridWeekDays);
	}

	get etAdjustTypeName(): string { return Program.getEtAdjustTypeName(this.etAdjustType); }

	// NOTE: This field IS used by one or more grids (e.g., ProgramsTabComponent).
	get startTimeGridCell(): string {
		return this.startTime.getFullYear() > 1901 ? RbUtils.Date.transform(this.startTime, 'h:mm a') : '-';
	}

	get isAdvancedEtProgram(): boolean {
		return this.etAdjustType === RbEnums.Common.EtAdjustType.WeatherSource
	}
}
export class ProgramColumnViewConfigUI {
	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}
	programListFiltered: number[];
	programListSorted: number[];
	stationListFiltered: number[];
}
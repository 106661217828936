import { Injector, NgModule } from '@angular/core';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { ActiveFlowChartComponent } from './components/active-flow-chart/active-flow-chart.component';
import {
	ActiveFlowMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/active-flow-mobile-cell-renderer/active-flow-mobile-cell-renderer.component';
import {
	ActiveFlowRuntimeCellRendererComponent
} from './components/tables/cell-renderers/active-flow/active-flow-runtime-cell-renderer/active-flow-runtime-cell-renderer.component';
import {
	ActiveFlowStatusCellRendererComponent
} from './components/tables/cell-renderers/active-flow/active-flow-status-cell-renderer/active-flow-status-cell-renderer.component';
import { ActiveFlowToolbarComponent } from './components/toolbars/active-flow-toolbar/active-flow-toolbar.component';
import {
	ActivityMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/activity-mobile-cell-renderer/activity-mobile-cell-renderer.component';
import { ActivityTypePickerComponent } from './components/alerts-filter/activity-type-picker/activity-type-picker.component';
import {
	AdminMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/admin-mobile-cell-renderer/admin-mobile-cell-renderer.component';
import { AgGridUiModule } from './aggrid-ui.module';
import { AlarmDataComponent } from './components/report-filter/sub-components/alarm-data-filter/alarm-data.component';
import { AlertFilterComponent } from './components/alerts-filter/alert-filter.component';
import {
	AlertMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/alert-mobile-cell-renderer/alert-mobile-cell-renderer.component';
import { AlertModalComponent } from './components/custom-modal/modal-styles/alert-modal/alert-modal.component';
import { AlertsFilterDropdownComponent } from './components/alerts-filter/alerts-filter-dropdown/alerts-filter-dropdown.component';
import {
	AreasMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/areas-mobile-cell-renderer/areas-mobile-cell-renderer.component';
import { AutoContactModeComponent } from './components/auto-manual-scheduled/auto-manual-scheduled.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import {
	BaseMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonWithSpinnerComponent } from './components/button-with-spinner/button-with-spinner.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselIndicatorComponent } from './components/carousel/carousel-indicator/carousel-indicator.component';
import { CarouselItemComponent } from './components/carousel/carousel-item/carousel-item.component';
import { ChangePasswordComponent } from '../sections/system-setup/profile-container/user-preferences-tab/change-password/change-password.component';
import { ChangePinCodeComponent } from '../sections/system-setup/profile-container/user-preferences-tab/change-pin-code/change-pin-code.component';
import { ChartTimeTravelerComponent } from './components/chart-time-traveler/chart-time-traveler.component';
import {
    ContactMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/contact-mobile-cell-renderer/contact-mobile-cell-renderer.component';
import {
	ControllerDeltaDetailsMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/controller-delta-details-mobile-cell-renderer/controller-delta-details-mobile-cell-renderer.component';
import {
	ControllerDeltaMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/controller-delta-mobile-cell-renderer/controller-delta-mobile-cell-renderer.component';
import {
ControllerDifferenceStatusCellRendererComponent
} from './components/tables/cell-renderers/controller-difference-status-cell-renderer/controller-difference-status-cell-renderer.component';
import {
	ControllerMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/controller-mobile-cell-renderer/controller-mobile-cell-renderer.component';
import {
    ControllerStatusCellRendererComponent
} from './components/tables/cell-renderers/controller-status-cell-renderer/controller-status-cell-renderer.component';
import { CoreModule } from '../core/core.module';
import { CourseViewerComponent } from './components/course-viewer/course-viewer.component';
import {
	CourseViewerDiagnosticsStationComponent
} from './components/course-viewer/course-viewer-diagnostics-station/course-viewer-diagnostics-station.component';
import { CourseViewerStationComponent } from './components/course-viewer/course-viewer-station/course-viewer-station.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { CustomRangePanelComponent } from './components/datepicker-header/custom-range-panel.component';
import { CustomReportControllerListComponent } from './components/custom-report-controller-list/custom-report-controller-list.component';
import { CustomReportSiteListComponent } from './components/custom-report-site-list/custom-report-site-list.component';
import { CustomReportTriPaneComponent } from './components/custom-report-tri-pane/custom-report-tri-pane.component';
import { DateListCardComponent } from './components/date-list-selector/date-list-card/date-list-card.component';
import { DateListSelectorComponent } from './components/date-list-selector/date-list-selector.component';
import { DatepickerHeaderComponent } from './components/datepicker-header/datepicker-header.component';
import { DatePipe } from '@angular/common';
import { DateRangeDropdownComponent } from './components/report-filter/sub-components/date-range-dropdown/date-range-dropdown.component';
import { DetailPanelToolbarComponent } from './components/toolbars/detail-panel-toolbar/detail-panel-toolbar.component';
import { DiagnosticsFilterComponent } from './components/diagnostics-filter/diagnostics-filter.component';
import { DialogMultiSectionsComponent } from './components/dialog-multi-section/dialog-multi-sections.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DryRunChartComponent } from './components/dry-run-chart/dry-run-chart.component';
import { DryRunDateTimeSelectorComponent } from './components/toolbars/dry-run-toolbar/dry-run-date-time-selector/dry-run-date-time-selector.component';
import {
	DryRunMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/dry-run-mobile-cell-renderer/dry-run-mobile-cell-renderer.component';
import {
	DryRunScheduleMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/dry-run-schedule-mobile-cell-renderer/dry-run-schedule-mobile-cell-renderer.component';
import { DryRunToolbarComponent } from './components/toolbars/dry-run-toolbar/dry-run-toolbar.component';
import { EditPanelComponent } from './components/edit-panel/edit-panel.component';
import { EditStartTimeComponent } from './components/edit-start-times/edit-start-time.component';
import { EditStartTimesArrayComponent } from './components/edit-start-times/edit-start-times-array.component';
import { EditUserComponent } from '../sections/system-setup/edit-user/edit-user.component';
import { EndTimeCellRendererComponent } from './components/tables/cell-renderers/end-time-cell-renderer/end-time-cell-renderer.component';
import { FillPipe } from './pipes/fill.pipe';
import {
	FirmwareUpdateMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/firmware-update-mobile-cell-renderer/firmware-update-mobile-cell-renderer.component';
import {
	FloManagerMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/flo-manager-mobile-cell-renderer/flo-manager-mobile-cell-renderer.component';
import {
	FlowAlarmMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/flow-alarm-mobile-cell-renderer/flow-alarm-mobile-cell-renderer.component';
import { FormattedTimePipe } from './pipes/formatted-time.pipe';
import {
	GlobalWeatherMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/global-weather-mobile-cell-renderer/global-weather-mobile-cell-renderer.component';
import { GolfRainCanFilterComponent } from './components/report-filter/golf-rain-can-filter/golf-rain-can-filter.component';
import {
	GolfSensorMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/golf-sensor-mobile-cell-renderer/golf-sensor-mobile-cell-renderer.component';
import {
	GolfSensorStatusCellRendererComponent
} from './components/tables/cell-renderers/golf-sensor-status-cell-renderer/golf-sensor-status-cell-renderer.component';
import {
	GolfSensorTriggerSymbolComponent
} from '../sections/golf-sensors/edit-golf-sensor/edit-golf-sensor-action/golf-sensor-trigger-symbol/golf-sensor-trigger-symbol.component';
import { HoleAreaFilterComponent } from './components/report-filter/golf-hole-area-filter/hole-area-filter.component';
import { HolesMobileCellRendererComponent } from './components/tables/mobile-cell-renderers/holes-mobile-cell-renderer/holes-mobile-cell-renderer.component';
import {
	HyperlinkCellRendererComponent
} from './components/tables/cell-renderers/hyperlink-cell-renderer/hyperlink-cell-renderer.component';
import {
	IciDiagnosticMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/ici-diagnostic-mobile-cell-renderer/ici-diagnostic-mobile-cell-renderer.component';
import {
	IciStatusCellRendererComponent
} from './components/tables/cell-renderers/ici-status-cell-renderer/ici-status-cell-renderer.component';
import {
	IcmDiagnosticMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/icm-diagnostic-mobile-cell-renderer/icm-diagnostic-mobile-cell-renderer.component';
import {
	IcmQuickCheckStatusCellRendererComponent
} from './components/tables/cell-renderers/icm-quick-check-status-cell-renderer/icm-quick-check-status-cell-renderer.component';
import {
	IcmStatusCellRendererComponent
} from './components/tables/cell-renderers/icm-status-cell-renderer/icm-status-cell-renderer.component';
import { IcmVoltageCellRendererComponent } from './components/tables/cell-renderers/icm-voltage-cell-renderer/icm-voltage-cell-renderer.component';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { InputTimePickerComponent } from './components/input-time-picker/input-time-picker.component';
import { InvalidRunStationIconRendererComponent } from './components/tables/cell-renderers/invalid-run-station-icon-renderer/invalid-run-station-icon-renderer.component';
import {
	IrrigationQueueMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/irrigation-queue-mobile-cell-renderer/irrigation-queue-mobile-cell-renderer.component';
import {
	IvmPingTestMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/ivm-ping-test-mobile-cell-renderer/ivm-ping-test-mobile-cell-renderer.component';
import {
	IvmResponseListMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/ivm-response-list-mobile-cell-renderer/ivm-response-list-mobile-cell-renderer.component';
import {
	IvmShortReportMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/ivm-short-report-mobile-cell-renderer/ivm-short-report-mobile-cell-renderer.component';
import { IvmTestPathCellRendererComponent } from './components/tables/cell-renderers/ivm-test-path-cell-renderer/ivm-test-path-cell-renderer.component';
import {
	LearnFlowStationMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/learn-flow-station-mobile-cell-renderer/learn-flow-station-mobile-cell-renderer.component';
import { LinkDiagnosticsCellRendererComponent } from './components/tables/mobile-cell-renderers/link-diagnostics-cell-renderer/link-diagnostics-cell-renderer.component';
import { ManualSyncDialogComponent } from './components/manual-sync-dialog/manual-sync-dialog.component';
import {
	MasterValveMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/master-valve-mobile-cell-renderer/master-valve-mobile-cell-renderer.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MobileDiagnosticsFilterComponent } from './components/diagnostics-filter/sub-components/mobile-diagnostics-filter/mobile-diagnostics-filter.component';
import { MobileReportFilterComponent } from './components/report-filter/sub-components/mobile-report-filter/mobile-report-filter.component';
import { MobileSidebarHeaderComponent } from './components/mobile-sidebar-header/mobile-sidebar-header.component';
import { MonthPickerComponent } from './components/report-filter/sub-components/month-picker/month-picker.component';
import { MultipleTimesCellRendererComponent } from './components/tables/cell-renderers/multiple-times-cell-renderer/multiple-times-cell-renderer.component';
import { MultiSelectDropdown2Component } from './components/dropdown/multi-select-dropdown2.component';
import { MultiSelectDropdownComponent } from './components/dropdown/multi-select-dropdown.component';
import { NavMobileCellRendererComponent } from './components/tables/mobile-cell-renderers/nav-mobile-cell-renderer/nav-mobile-cell-renderer.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NotificationSnackbarComponent } from './components/notification-snackbar/notification-snackbar.component';
import { NumberArrowButtonComponent } from './components/buttons/number-arrow-button/number-arrow-button.component';
import { NumberWithCommaPipe } from './pipes/number-with-comma.pipe';
import { NumericCellEditorComponent } from './components/editable-grid/cell-editors/numeric-cell-editor/numeric-cell-editor.component';
import { PasswordExpirationSnackbarComponent } from './components/password-expiration-snackbar/password-expiration-snackbar.component';
import {
	PinCodeMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/pin-code-mobile-cell-renderer/pin-code-mobile-cell-renderer.component';
import {
	PingDecoderMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/ping-decoder-mobile-cell-renderer/ping-decoder-mobile-cell-renderer.component';
import {
	ProgramGroupMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/program-group-mobile-cell-renderer/program-group-mobile-cell-renderer.component';
import {
	ProgramGroupScheduleMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/program-group-schedule-mobile-cell-renderer/program-group-schedule-mobile-cell-renderer.component';
import { ProgramStatusCellRendererComponent } from './components/tables/cell-renderers/program-status-cell-renderer/program-status-cell-renderer.component';
import {
	ProgramStepMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/program-step-mobile-cell-renderer/program-step-mobile-cell-renderer.component';
import {
	QuickIrrAreaMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/quick-irr-area-mobile-cell-renderer/quick-irr-area-mobile-cell-renderer-component';
import { RadioGroupComponent } from './components/report-filter/sub-components/radio-group/radio-group.component';
import { RbFormValidationDirective } from './directives/rb-form-validation.directive';
import { RbSelectDirective } from './directives/rb-select.directive';
import { ReportButtonComponent } from './components/report-filter/sub-components/report-button/report-button.component';
import { ReportFilterComponent } from './components/report-filter/report-filter.component';
import { ReportsSharedModule } from '../sections/reports/reports-shared.module';
import { RequestAccessSnackbarComponent } from './components/request-access-snackbar/request-access-snackbar.component';
import {
	RestoreDataMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/restore-data-mobile-cell-renderer/restore-data-mobile-cell-renderer.component';
import { RunTimeInputComponent } from './components/run-time-input/run-time-input.component';
import {
	RuntimeMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/runtime-mobile-cell-renderer/runtime-mobile-cell-renderer.component';
import { RuntimeToDurationPipe } from './pipes/runtime-to-duration.pipe';
import {
	ScheduledActivityMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/scheduled-activity-mobile-cell-renderer/scheduled-activity-mobile-cell-renderer.component';
import {
	ScheduledReportMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/scheduled-report-mobile-cell-renderer/scheduled-report-mobile-cell-renderer.component';
import {
	ScheduleProgramCellRendererComponent
} from './components/tables/cell-renderers/schedule-program-cell-renderer/schedule-program-cell-renderer.component';
import { ScrollRouterLinkIntoViewDirective } from './directives/scroll-router-link-into-view.directive';
import { SelectBatchOptionsDialogComponent } from './components/select-batch-options-dialog/select-batch-options-dialog.component';
import { SelectControllerDialogComponent } from './components/report-filter/sub-components/select-controllers-dialog/select-controller-dialog.component';
import { SelectPanelComponent } from './components/select-panel/select-panel.component';
import { SelectSingleControllerComponent } from './components/report-filter/sub-components/select-single-controller/select-single-controller.component';
import { SelectStationsDialogComponent } from './components/select-stations-dialog/select-stations-dialog.component';
import { SensorDurationSelectorComponent } from './components/sensor-duration-selector/sensor-duration-selector.component';
import {
	SensorDurationSelectorDialogComponent
} from './components/sensor-duration-selector/sensor-duration-selector-dialog/sensor-duration-selector-dialog.component';
import { SensorMobileCellRendererComponent } from './components/tables/mobile-cell-renderers/sensor-mobile-cell-renderer/sensor-mobile-cell-renderer.component';
import { SetDurationComponent } from './components/set-duration/set-duration.component';
import { ShareRecipientsDialogComponent } from './components/share-recipients-dialog/share-recipients-dialog.component';
import {
	ShortFindingMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/short-finding-mobile-cell-renderer/short-finding-mobile-cell-renderer.component';
import { ShowStationNotesCellRendererComponent } from './components/tables/cell-renderers/show-station-notes-cell-renderer/show-station-notes-cell-renderer.component';
import { SidebarHeaderComponent } from './components/sidebar-header/sidebar-header.component';
import { SidebarRouteNavPanelComponent } from './components/sidebar-routes-panel/sidebar-route-nav-panel/sidebar-route-nav-panel.component';
import { SidebarRoutesPanelComponent } from './components/sidebar-routes-panel/sidebar-routes-panel.component';
import { SidebarSubEditSelectorComponent } from './components/sidebar-sub-edit-selector/sidebar-sub-edit-selector.component';
import { SiteMobileCellRendererComponent } from './components/tables/mobile-cell-renderers/site-mobile-cell-renderer/site-mobile-cell-renderer.component';
import {
	SoftwareInfoMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/software-info-mobile-cell-renderer/software-info-mobile-cell-renderer.component';
import {
	SprinklerTypeMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/sprinkler-type-mobile-cell-renderer/sprinkler-type-mobile-cell-renderer.component';
import { StartTimeCellRendererComponent } from './components/tables/cell-renderers/start-time-cell-renderer/start-time-cell-renderer.component';
import {
	StationDiagnosticCellRendererComponent
} from './components/tables/cell-renderers/station-diagnostic-cell-renderer/station-diagnostic-cell-renderer.component';
import { StationDiagnosticComponent } from '../sections/stations/station-diagnostic/station-diagnostic.component';
import { StationDiagnosticFilterComponent } from './components/station-diagnostic-filter/station-diagnostic-filter.component';
import { StationDiagnosticFilterMobileComponent } from './components/station-diagnostic-filter-mobile/station-diagnostic-filter-mobile.component';
import {
	StationDiagnosticMobileRendererComponent
} from './components/tables/mobile-cell-renderers/station-diagnostic-mobile-renderer/station-diagnostic-mobile-renderer.component';
import {
	StationMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/station-mobile-cell-renderer/station-mobile-cell-renderer.component';
import {
	StationMobileCellRendererNewComponent
} from './components/tables/mobile-cell-renderers/station-mobile-cell-renderer-new/station-mobile-cell-renderer-new.component';
import { StationProgramsAssignedComponent } from './components/tables/cell-renderers/station-programs-assigned/station-programs-assigned.component';
import { StationSearchComponent } from './components/station-search/station-search.component';
import {
	StationSearchMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/station-search-mobile-cell-renderer/station-search-mobile-cell-renderer.component';
import { StationStatusCellRendererComponent } from './components/tables/cell-renderers/station-status-cell-renderer/station-status-cell-renderer.component';
import {
	StationStatusCellRendererNewComponent
} from './components/tables/cell-renderers/station-status-cell-renderer-new/station-status-cell-renderer-new.component';
import { SubNavbarComponent } from './components/sub-navbar/sub-navbar.component';
import { SystemStatusButtonComponent } from './components/buttons/system-status-button/system-status-button.component';
import { TimeCellRendererComponent } from './components/tables/cell-renderers/time-cell-renderer/time-cell-renderer.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { TimePeriodComponent } from './components/report-filter/sub-components/time-period/time-period.component';
import { TranslateModule } from '@ngx-translate/core';
import { TreeViewCheckboxComponent } from './components/report-filter/sub-components/tree-view-checkbox/tree-view-checkbox.component';
import { TreeViewComponent } from './components/report-filter/sub-components/tree-view/tree-view.component';
import { TreeViewRadioButtonComponent } from './components/report-filter/sub-components/tree-view-radiobutton/tree-view-radiobutton.component';
import { TriPaneComponent } from './components/tri-pane/tri-pane.component';
import { UnitsOfMeasureFlowComponent } from './components/regional-settings/units-of-measure-flow/units-of-measure-flow.component';
import { UnitsOfMeasurePressureComponent } from './components/regional-settings/units-of-measure-pressure/units-of-measure-pressure.component';
import { UserRightSidebarComponent } from '../sections/system-setup/user-right-sidebar/user-right-sidebar.component';
import {
	WeatherSourcesMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/weather-sources-mobile-cell-renderer/weather-sources-mobile-cell-renderer.component';

@NgModule({
    imports: [
        AgGridUiModule,
        CoreModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatChipsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatNativeDateModule,
        MatOptionModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSliderModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTreeModule,
        NgxMaterialTimepickerModule,
        ReportsSharedModule
    ],
    declarations: [
        ActiveFlowChartComponent,
        ActiveFlowToolbarComponent,
        ActivityMobileCellRendererComponent,
        ActivityTypePickerComponent,
        AdminMobileCellRendererComponent,
        AlarmDataComponent,
        AlertFilterComponent,
        AlertsFilterDropdownComponent,
        AlertMobileCellRendererComponent,
        AreasMobileCellRendererComponent,
        BackButtonComponent,
        BaseMobileCellRendererComponent,
        BreadcrumbComponent,
        ButtonWithSpinnerComponent,
        ContactMobileCellRendererComponent,
        ControllerDeltaMobileCellRendererComponent,
        ControllerDeltaDetailsMobileCellRendererComponent,
        ControllerStatusCellRendererComponent,
        ControllerMobileCellRendererComponent,
        CustomRangePanelComponent,
        CustomReportControllerListComponent,
        CustomReportTriPaneComponent,
        DatepickerHeaderComponent,
        DateRangeDropdownComponent,
        DetailPanelToolbarComponent,
        DiagnosticsFilterComponent,
        DropdownComponent,
        EditPanelComponent,
        FillPipe,
        FloManagerMobileCellRendererComponent,
        GlobalWeatherMobileCellRendererComponent,
        HolesMobileCellRendererComponent,
        IciDiagnosticMobileCellRendererComponent,
        IciStatusCellRendererComponent,
        IcmDiagnosticMobileCellRendererComponent,
        IcmQuickCheckStatusCellRendererComponent,
        IcmStatusCellRendererComponent,
        IcmVoltageCellRendererComponent,
        InputSelectComponent,
        InputTimePickerComponent,
		InvalidRunStationIconRendererComponent,
        LinkDiagnosticsCellRendererComponent,
        MasterValveMobileCellRendererComponent,
        MobileDiagnosticsFilterComponent,
        MobileReportFilterComponent,
        MobileSidebarHeaderComponent,
        MonthPickerComponent,
        MultiSelectDropdownComponent,
        MultiSelectDropdown2Component,
        NavMobileCellRendererComponent,
        PinCodeMobileCellRendererComponent,
        ProgramGroupMobileCellRendererComponent,
        ProgramGroupScheduleMobileCellRendererComponent,
        ProgramStepMobileCellRendererComponent,
        ProgramStatusCellRendererComponent,
        QuickIrrAreaMobileCellRendererComponent,
        RadioGroupComponent,
        RbSelectDirective,
        ReportButtonComponent,
        ReportFilterComponent,
		RestoreDataMobileCellRendererComponent,
        RuntimeMobileCellRendererComponent,
        ScrollRouterLinkIntoViewDirective,
        SelectPanelComponent,
        SelectStationsDialogComponent,
        ScheduledActivityMobileCellRendererComponent,
        SensorMobileCellRendererComponent,
        SetDurationComponent,
        SystemStatusButtonComponent,
        UnitsOfMeasureFlowComponent,
        InputTimePickerComponent,
        UnitsOfMeasurePressureComponent,
        SelectControllerDialogComponent,
        GolfSensorStatusCellRendererComponent,
        GolfSensorTriggerSymbolComponent,
        ShareRecipientsDialogComponent,
        SidebarHeaderComponent,
        SidebarRouteNavPanelComponent,
        SidebarRoutesPanelComponent,
        SiteMobileCellRendererComponent,
        SprinklerTypeMobileCellRendererComponent,
        StationMobileCellRendererComponent,
        StationStatusCellRendererComponent,
        SubNavbarComponent,
        TimeInputComponent,
        TimePeriodComponent,
        TreeViewCheckboxComponent,
        TreeViewComponent,
        TriPaneComponent,
        UnitsOfMeasureFlowComponent,
        UnitsOfMeasurePressureComponent,
        WeatherSourcesMobileCellRendererComponent,
        ActivityTypePickerComponent,
        ControllerDeltaMobileCellRendererComponent,
        ControllerDeltaDetailsMobileCellRendererComponent,
        SidebarRouteNavPanelComponent,
        SidebarRoutesPanelComponent,
        ScrollRouterLinkIntoViewDirective,
        ProgramStatusCellRendererComponent,
        DryRunToolbarComponent,
        DryRunChartComponent,
        DryRunMobileCellRendererComponent,
        DryRunScheduleMobileCellRendererComponent,
        ActiveFlowMobileCellRendererComponent,
        ActiveFlowStatusCellRendererComponent,
        ActiveFlowRuntimeCellRendererComponent,
        HyperlinkCellRendererComponent,
        ChartTimeTravelerComponent,
        HoleAreaFilterComponent,
        SelectBatchOptionsDialogComponent,
        ManualSyncDialogComponent,
        TreeViewRadioButtonComponent,
        SelectSingleControllerComponent,
        NumericCellEditorComponent,
        DialogMultiSectionsComponent,
        GolfSensorMobileCellRendererComponent,
        SensorDurationSelectorComponent,
        SensorDurationSelectorDialogComponent,
        CarouselComponent,
        CarouselItemComponent,
        CarouselIndicatorComponent,
        SidebarSubEditSelectorComponent,
        AutoContactModeComponent,
        EditStartTimeComponent,
        EditStartTimesArrayComponent,
        DryRunDateTimeSelectorComponent,
        DateListSelectorComponent,
        DateListCardComponent,
        EndTimeCellRendererComponent,
        StartTimeCellRendererComponent,
        TimeCellRendererComponent,
        MultipleTimesCellRendererComponent,
        GolfRainCanFilterComponent,
        ScheduleProgramCellRendererComponent,
        IrrigationQueueMobileCellRendererComponent,
        CourseViewerComponent,
        CourseViewerStationComponent,
        FlowAlarmMobileCellRendererComponent,
        ScheduledReportMobileCellRendererComponent,
        SoftwareInfoMobileCellRendererComponent,
        CourseViewerDiagnosticsStationComponent,
        ShortFindingMobileCellRendererComponent,
        PingDecoderMobileCellRendererComponent,
        IvmShortReportMobileCellRendererComponent,
        IvmResponseListMobileCellRendererComponent,
        IvmPingTestMobileCellRendererComponent,
        StationDiagnosticComponent,
        StationSearchComponent,
        StationSearchMobileCellRendererComponent,
        LearnFlowStationMobileCellRendererComponent,
        CustomReportSiteListComponent,
        LearnFlowStationMobileCellRendererComponent,
        IvmTestPathCellRendererComponent,
        StationDiagnosticCellRendererComponent,
        StationDiagnosticMobileRendererComponent,
        StationDiagnosticFilterComponent,
        StationDiagnosticFilterMobileComponent,
        StationStatusCellRendererNewComponent,
        StationMobileCellRendererNewComponent,
        FirmwareUpdateMobileCellRendererComponent,
		NumberArrowButtonComponent,
		RbFormValidationDirective,
		StationProgramsAssignedComponent,
        RuntimeToDurationPipe,
        ControllerDifferenceStatusCellRendererComponent,
        RunTimeInputComponent,
        FormattedTimePipe,
        PasswordExpirationSnackbarComponent,
        NotificationSnackbarComponent,
        CustomModalComponent,
        AlertModalComponent,
        RequestAccessSnackbarComponent,
        NumberWithCommaPipe,
        ShowStationNotesCellRendererComponent,
		UserRightSidebarComponent,
		EditUserComponent,
		ChangePasswordComponent,
		ChangePinCodeComponent
    ],
    exports: [
        ActiveFlowChartComponent,
        ActiveFlowMobileCellRendererComponent,
        ActiveFlowToolbarComponent,
        ActivityTypePickerComponent,
        AlarmDataComponent,
        AlertFilterComponent,
        AlertsFilterDropdownComponent,
        BackButtonComponent,
        BaseMobileCellRendererComponent,
        BreadcrumbComponent,
        ButtonWithSpinnerComponent,
        ChartTimeTravelerComponent,
        CustomReportControllerListComponent,
        CustomReportTriPaneComponent,
        DatepickerHeaderComponent,
        DateRangeDropdownComponent,
        DetailPanelToolbarComponent,
        DiagnosticsFilterComponent,
        DropdownComponent,
        EditPanelComponent,
        FillPipe,
        InputSelectComponent,
        InputTimePickerComponent,
        MatButtonToggleModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSliderModule,
        MatSortModule,
        MobileSidebarHeaderComponent,
        MonthPickerComponent,
        MultiSelectDropdownComponent,
        MultiSelectDropdown2Component,
        RadioGroupComponent,
        RbSelectDirective,
        ReportButtonComponent,
        ReportFilterComponent,
        ScrollRouterLinkIntoViewDirective,
        SelectControllerDialogComponent,
        SelectPanelComponent,
        SetDurationComponent,
        SidebarRouteNavPanelComponent,
        SidebarRoutesPanelComponent,
        SystemStatusButtonComponent,
        UnitsOfMeasureFlowComponent,
        UnitsOfMeasurePressureComponent,
        InputTimePickerComponent,
        SidebarHeaderComponent,
        SubNavbarComponent,
        TimeInputComponent,
        TimePeriodComponent,
        TranslateModule,
        TreeViewCheckboxComponent,
        TreeViewComponent,
        TriPaneComponent,
        SidebarRouteNavPanelComponent,
        SidebarRoutesPanelComponent,
        ScrollRouterLinkIntoViewDirective,
        DryRunToolbarComponent,
        DryRunChartComponent,
        HoleAreaFilterComponent,
        SelectBatchOptionsDialogComponent,
        ManualSyncDialogComponent,
        TreeViewRadioButtonComponent,
        SelectSingleControllerComponent,
        DialogMultiSectionsComponent,
        GolfSensorMobileCellRendererComponent,
        GolfSensorTriggerSymbolComponent,
        SensorDurationSelectorComponent,
        SensorDurationSelectorDialogComponent,
        CarouselComponent,
        CarouselItemComponent,
        SidebarSubEditSelectorComponent,
        AutoContactModeComponent,
        EditStartTimeComponent,
        EditStartTimesArrayComponent,
        DateListSelectorComponent,
        DateListCardComponent,
        CourseViewerComponent,
        MatExpansionModule,
        StationDiagnosticComponent,
        StationSearchComponent,
        CustomReportSiteListComponent,
        StationDiagnosticFilterComponent,
        StationDiagnosticFilterMobileComponent,
        StationStatusCellRendererNewComponent,
        StationMobileCellRendererNewComponent,
		NumberArrowButtonComponent,
		RbFormValidationDirective,
		ShareRecipientsDialogComponent,
		RuntimeToDurationPipe,
		RestoreDataMobileCellRendererComponent,
		RunTimeInputComponent,
        FormattedTimePipe,
        PasswordExpirationSnackbarComponent,
        NotificationSnackbarComponent,
        CustomModalComponent,
        AlertModalComponent,
        RequestAccessSnackbarComponent,
        AgGridUiModule,
        NumberWithCommaPipe,
		UserRightSidebarComponent,
		EditUserComponent,
		ChangePasswordComponent,
		ChangePinCodeComponent
	],
	providers: [
		DatePipe,
		NumberWithCommaPipe
	]
})
export class SharedUiModule {
	constructor(injector: Injector) {
		// AppInjector = injector;
	}
}
import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-ici-diagnostic-mobile-cell-renderer',
	templateUrl: './ici-diagnostic-mobile-cell-renderer.component.html',
	styleUrls: ['./ici-diagnostics-mobile-cell-renderer.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class IciDiagnosticMobileCellRendererComponent implements ICellRendererAngularComp {

	@HostBinding('class') class = 'rb-ici-diagnostic-mobile-cell-renderer';

	/**
	 * We allow some special values to be added to params for this renderer. This is done via the mobileCellRendererParams
	 * parameter to the rb-grid-container, if needed.
	 * - currentValueGetter - function(params) => any returning the current value to be displayed in mA
	 */
	params: any;

	data: any;

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.params = params;
		this.data = params.data;
	}

	refresh(params: any): boolean {
		this.params = params;
		this.data = params.data;
		return true;
	}

	convertToSecondToFriendlyString(value: number) {
		return RbUtils.Stations.secondsToFriendlyString(value) ||
			`${value} ${RbUtils.Translate.instant('STRINGS.SEC')}`;
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================
}

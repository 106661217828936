import { Component, Input, OnInit } from '@angular/core';
import { RbConstants } from '../../../../../common/constants/_rb.constants';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-golf-sensor-trigger-symbol',
	templateUrl: './golf-sensor-trigger-symbol.component.html',
	styleUrls: ['./golf-sensor-trigger-symbol.component.scss']
})
export class GolfSensorTriggerSymbolComponent implements OnInit {
	TriggerSymbolColors = RbConstants.Form.TRIGGER_SYMBOL_COLORS;

	@Input() triggerSymbol: RbEnums.Common.SensorTriggerSymbol;

	symbolStyle: string;

	constructor() { }

	ngOnInit() {
		this.symbolStyle = `linear-gradient(-45deg, ${this.TriggerSymbolColors[this.triggerSymbol]}, ${this.TriggerSymbolColors[this.triggerSymbol]}, white)`;
	}

}

import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { Area } from './models/area.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable} from 'rxjs';
import { Subarea } from './models/subarea.model';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@Injectable({
	providedIn: 'root'
})
export class AreaApiService extends ApiService {

	clearCache() {
		super.clearCache(this.getAllAreasUrl);
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	createArea(areaData: any): Observable<Area> {
		return this.apiRequest<any>(this.createAreaUrl, HttpMethod.Post, areaData);
	}

	createSubarea(subareaData: any): Observable<Subarea> {
		return this.apiRequest<any>(this.createSubareaUrl, HttpMethod.Post, subareaData);
	}

	deleteAreas(areaIds: number[]): Observable<null> {
		return this.apiRequest(this.deleteAreasUrl(areaIds), HttpMethod.Delete);
	}

	deleteSubareas(ids: number[]): Observable<null> {
		return this.apiRequest(this.deleteSubareasUrl(ids), HttpMethod.Delete, ids);
	}

	getAllHoleSections(bypassCache: boolean): Observable<ApiCachedRequestResponse<Area[]>> {
		return this.apiRequestWithCache<any>(this.getAllAreasUrl, bypassCache,
			result => result.filter(a => a.level === 3).map(item => new Area(item)));
	}

	getAllHoles(bypassCache: boolean): Observable<ApiCachedRequestResponse<Area[]>> {
		return this.apiRequestWithCache<any>(this.getAllAreasUrl, bypassCache,
				result => result.filter(a => a.level === 2).map(item => new Area(item)));
	}

	getArea(areaId: number, includeSubAreas: boolean = false): Observable<Area> {
		return this.apiRequest<any>(this.getAreaUrl(areaId, includeSubAreas), HttpMethod.Get)
			.pipe(map(response => new Area(response)));
	}

	getSubarea(id: number): Observable<Subarea> {
		return this.apiRequest<any>(this.getSubareaUrl(id), HttpMethod.Get)
			.pipe(map(response => new Subarea(response)));
	}
	getSubareasByAreaId(id: number): Observable<Subarea[]> {
		return this.apiRequest<any>(this.getSubareasByAreaIdUrl(id), HttpMethod.Get)
		.pipe(map(list => list.map(item => new Subarea(item))));
	}
	getAreas(siteId: number, includeSubAreas: boolean = false, bypassCache: boolean = false): Observable<Area[]> {
		return this.apiRequest<any>(siteId == null ? this.getAllAreasUrl : this.getAreasUrl(siteId, includeSubAreas), HttpMethod.Get,
			null, null, bypassCache)
			.pipe(map(list => list.map(item => new Area(item))));
	}

	getAreasWithLevel(siteId: number, level: number, includeSubAreas: boolean = false) {
		return this.apiRequest<any>(this.getAreasUrl(siteId, includeSubAreas), HttpMethod.Get)
		.pipe(map(list => list.filter(item => item.level === level).map(item => new Area(item))));
	}

	getNameUniqueness(areaLevel: number, id: number, name: string, siteId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNameUniquenessUrl(areaLevel, id, name, siteId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getSubareaNameUniqueness(id: number, name: string, areaId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getSubareaNameUniquenessUrl(id, name, areaId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getNumberUniqueness(areaLevel: number, id: number, areaNumber: number, siteId: number, isGolfArea: boolean): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNumberUniquenessUrl(areaLevel, id, areaNumber, siteId, isGolfArea), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getShortNameUniqueness(areaLevel: number, id: number, shortName: string, siteId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getShortNameUniquenessUrl(areaLevel, id, shortName, siteId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	updateArea(areaId: number, updateData: any) {
		return this.apiRequest<any>(this.updateAreaUrl(areaId), HttpMethod.Patch, this.patchTransform(updateData));
	}

	updateSubarea(id: number, updateData: any) {
		return this.apiRequest<any>(this.updateSubareaUrl(id), HttpMethod.Patch, this.patchTransform(updateData));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}Area`; }
	private get baseSubareaUrl(): string { return `${this.baseApiUrl}SubArea`; }

	private get createAreaUrl(): string { return `${this.baseUrl}/CreateArea`; }
	private get createSubareaUrl(): string { return `${this.baseSubareaUrl}/CreateSubArea`; }

	private deleteAreasUrl(areaIds: number[]): string {
		const queryParam = areaIds.reduce((list, areaId) => `${list ? list + '&' : ''}areaIds=${areaId}`, '');
		return `${this.baseUrl}/DeleteAreas?${queryParam}`;
	}

	private deleteSubareasUrl(ids: number[]): string {
		return `${this.baseSubareaUrl}/DeleteSubAreas`;
	}

	private get getAllAreasUrl(): string { return `${this.baseUrl}/GetAreas?IncludeSubAreas=true`; }

	private getAreaUrl(areaId: number, includeSubAreas: boolean): string {
		return `${this.baseUrl}/GetArea?areaId=${areaId}&IncludeSubAreas=${includeSubAreas}`;
	}
	private getSubareasByAreaIdUrl(id: number): string {
		return `${this.baseSubareaUrl}/GetSubAreaByAreaId?areaId=${id}`;
	}
	private getSubareaUrl(id: number): string {
		return `${this.baseSubareaUrl}/GetSubArea?subAreaId=${id}`;
	}

	private getAreasUrl(siteId: number, includeSubAreas: boolean): string {
		return `${this.baseUrl}/GetAreas?siteId=${siteId}&IncludeSubAreas=${includeSubAreas}`;
	}

	private getNameUniquenessUrl(areaLevel: number, id: number, name: string, siteId: number): string {
		return `${this.baseUrl}/IsAreaNameUnique?areaLevel=${areaLevel}&areaName=${encodeURIComponent(name)}&areaId=${id}&siteId=${siteId}`;
	}

	private getSubareaNameUniquenessUrl(id: number, name: string, areaId: number): string {
		return `${this.baseSubareaUrl}/IsNameUnique?subAreaName=${encodeURIComponent(name)}&areaId=${areaId}&subAreaId=${id}`;
	}

	private getNumberUniquenessUrl(areaLevel: number, id: number, areaNumber: number, siteId: number, isGolfArea: boolean): string {
		return `${this.baseUrl}/IsAreaNumberUnique?areaLevel=${areaLevel}&areaNumber=${areaNumber}&areaId=${id}&siteId=${siteId}&isGolfArea=${isGolfArea}`;
	}

	private getShortNameUniquenessUrl(areaLevel: number, id: number, shortName: string, siteId: number): string {
		return `${this.baseUrl}/IsAreaShortNameUnique?areaLevel=${areaLevel}&shortName=${encodeURIComponent(shortName)} &areaId=${id}&siteId=${siteId}`;
	}

	private updateAreaUrl(areaId: number): string { return `${this.baseUrl}/Update?areaId=${areaId}`; }
	private updateSubareaUrl(id: number): string { return `${this.baseSubareaUrl}/Update?subAreaId=${id}`; }
}

import { Component, HostBinding, Injector, OnDestroy } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { SoftwareUpdateInfo } from '../../../../../common/models/software-update-info.model';

@Component({
	selector: 'rb-software-info-mobile-cell-renderer',
	templateUrl: './software-info-mobile-cell-renderer.component.html',
	styleUrls: ['./software-info-mobile-cell-renderer.component.scss']
})
export class SoftwareInfoMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnDestroy {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';
	softwareUpdateInfo: SoftwareUpdateInfo;
	isRunning = false;
	isLocal = false;
	isOwner = false;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnDestroy(): void {}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		super.agInit(params);
		this.softwareUpdateInfo = params.data;
		this.isOwner = this.authManager.isOwner;
		this.isLocal = RbUtils.Common.isRunningLocally();
	}

	refresh(params: any): boolean {
		this.softwareUpdateInfo = params.data;
		return true;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================
	getStatus() {
		if (this.softwareUpdateInfo.isInstalled) {
			return `${RbUtils.Translate.instant('STRINGS.INSTALLED_ON')}${this.softwareUpdateInfo.installedDate}`;
		} else if (!this.isOwner) {
			return `${RbUtils.Translate.instant('STRINGS.SOFTWARE_UPDATE_NONE_OWNER')}`;
		} else if (!this.isLocal) {
			return `${RbUtils.Translate.instant('STRINGS.SOFTWARE_UPDATE_NOT_LOCAL')}`;
		} else {
			return `${RbUtils.Translate.instant('STRINGS.UPGRADABLE')}`;
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================
}

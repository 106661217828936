import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-station-diagnostic-filter-mobile',
	templateUrl: './station-diagnostic-filter-mobile.component.html',
	styleUrls: ['./station-diagnostic-filter-mobile.component.scss']
})
export class StationDiagnosticFilterMobileComponent implements OnInit {

	// Generally, we are just a UI proxy on top of the parent filter
	@Input() isSelectTestTypeDisabled: boolean;
	@Input() isStartButtonDisabled: boolean;
	@Input() cancelButtonEnabled = false;

	@Output() cancelButtonClicked = new EventEmitter();
	@Output() runButtonClicked = new EventEmitter();
	@Input() testTypeId: number;
	@Output() testTypeIdChange = new EventEmitter<number>();
	@Input() testTypes: {name: string, id: RbEnums.Common.DiagnosticTestType} [] = [];

	RbUtils = RbUtils;

	constructor(public dialog: MatDialog) { }

	ngOnInit(): void {
		// setTimeout(() => this.onSelectControllers(), 500);
	}

	changeTestType(type: number) {
		this.testTypeIdChange.emit(type);
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onRunButtonClicked() {
		this.runButtonClicked.emit();
	}

	onCancelButtonClicked() {
		this.cancelButtonClicked.emit();
	}
}

import * as moment from 'moment/moment';
import { Entitlement } from './entitlement.model';

export enum EntitlementType {
	Demo,
	Monthly,
	Annual,
	Deleted
}

export class CustomerEntitlement {

	entitlement: Entitlement;

	expiration: Date;
	magentoID: number;
	purchaseDate: Date;
	trialExpiration: Date;
	trialStartDate: Date;
	type: EntitlementType;

	constructor(json?: any) {

		if (json)
		{
			Object.assign(this, json);
			this.entitlement = new Entitlement(json.entitlement);
			if (json.expiration) { this.expiration = moment.utc(json.expiration).toDate(); } // Data is UTC
			if (json.purchaseDate) { this.purchaseDate = moment.utc(json.purchaseDate).toDate(); } // Data is UTC
			if (json.trialStartDate) { this.trialStartDate = moment.utc(json.trialStartDate).toDate(); } // Data is UTC
			if (json.trialExpiration) { this.trialExpiration = moment.utc(json.trialExpiration).toDate(); } // Data is UTC
		}
	}
}

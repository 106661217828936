import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, } from '@angular/core';
import { MatSelect } from '@angular/material/select';

export interface INameAndIDItem {
	name: string;
	id: number;
}

@Component({
	selector: 'rb-multi-select-dropdown2',
	templateUrl: './multi-select-dropdown2.component.html',
	styleUrls: ['./multi-select-dropdown2.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MultiSelectDropdown2Component<T extends INameAndIDItem> implements OnInit {

	@ViewChild('matSelect', { static: true }) matSelect: MatSelect;

	@Input() label: string;
	@Input() selectedItems: Array<T>;
	@Input() showSelectionCount = false;
	@Input() items: Array<T>;

	/**
	 * @summary When the user accepts the selection, this event is fired. It carries the list of selected
	 * items with it as a T[]. This array could be empty, so handle that in the event handler.
	 */
	@Output() selectionComplete = new EventEmitter<T[]>();

	doneFired = false;

	constructor () { }

	ngOnInit() {
	}

	equals(objOne, objTwo) {
		if (objOne && objTwo) {
			return objOne.id === objTwo.id;
		}
	}

	selectAll(items: T[]) {
		this.selectedItems = items;
	}

	deselectAll() {
		this.selectedItems = [];
	}

	onSelectionChange(selectedItems: T[]) {
		this.selectedItems = selectedItems;
	}

	onSelectOpened(event) {
		// Just clear the user-pressed-Done flag.
		this.doneFired = false;
	}

	/**
	 * @summary Fired when the user closes the dropdown. Note that this will be fired whether she clicks Done
	 * or just clicks outside the dropdown. Because the selection is updated whether the Done button is clicked
	 * or not, we should emit on clicked-outside, too.
	 * @param event closed event.
	 */
	onSelectClosed(event) {
		if (!this.doneFired) {
			this.selectionComplete.emit((<Array<any>>this.matSelect.selected).map(option => option.value));
		}
	}

	onDoneClick() {
		this.doneFired = true;
		this.selectionComplete.emit((<Array<any>>this.matSelect.selected).map(option => option.value));
		this.matSelect.close();
	}

}

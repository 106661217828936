<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-dialog *ngIf="isMatDialogOpen"
    data-qaid="irrigation-queue-command-dialog"
    [title]="'STRINGS.MANUAL_COMMANDS' | translate"
    [closeButtonLabel]="'STRINGS.CLOSE_UPPERCASE' | translate"
    [submitButtonLabel]="'STRINGS.START_UPPERCASE' | translate"
    [showSubmitButton]="true"
    [saveDisabled]="!isAbleToStart"
    [additionalActions]="[cancelAllAction]"
    [ngClass]="{'edit-panel-fade-in': canDialogBoxComponentBeShown && isMatDialogOpen}"
    (onCancel)="onCancel()"
    (onSubmit)="onStart()"
>

    <div class="queue-commands-container">
        <mat-tab-group #manualOpsTab animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
            <mat-tab [label]="(isMobile ? 'STRINGS.STATIONS' : 'STRINGS.START_STATIONS') | translate">
                <div class="qc-tab-content flex-panel-container flex-d-column-panel" [ngClass]="{'fade-in': selectedTabIndex === 0}">
                    <ng-container *ngIf="!isConnecting">
                        <ng-container *ngIf="isConnected; else connectionRetry">
                            <div>
                                <rb-duration-shortcut
                                    [defaultRunStationDuration]="duration"
                                    [showHeader]="false"
                                    [showButtons]="false"
                                    [showMinuteOnly]="showMinuteOnly"
                                    (durationChange)="onDurationSet($event)"
                                ></rb-duration-shortcut>
                            </div>
                            <hr>
                            <rb-table-wrapper
                                #stationsTable
                                [rowData]="stations"
                                [includeCheckbox]="true"
                                [hideRowSelection]="true"
                                (onRowSelect)="onStationSelect($event)"
                                [mobileCellRendererComponent]="StationMobileCellRendererNewComponent"
                                [mobileCellRendererParams]="{onCellClick}"
                                [mobileRightArrow]="false"
                            >
                                <rb-column [headerName]="'STRINGS.NUMBER_SIGN' | translate" field="terminal" [minWidth]="100" [maxWidth]="100"></rb-column>
                                <rb-column [headerName]="'STRINGS.NAME' | translate" field="name" [minWidth]="70"></rb-column>
                                <rb-column
                                    [headerName]="'STRINGS.STATUS' | translate"
                                    field="statusGridDisplay"
                                    [cellRenderer]="StationStatusCellRendererNewComponent"
                                    [cellRendererParams]="{onCellClick}"
                                    [minWidth]="150"
                                ></rb-column>
                            </rb-table-wrapper>
                        </ng-container>
                    </ng-container>
                </div>
            </mat-tab>

            <mat-tab [label]="(isMobile ? 'STRINGS.PROGRAMS' : 'STRINGS.START_PROGRAMS') | translate">
                <div class="qc-tab-content flex-panel-container flex-d-column-panel" [ngClass]="{'fade-in': selectedTabIndex === 1}">
                    <ng-container *ngIf="!isConnecting">
                        <ng-container *ngIf="isConnected; else connectionRetry">
                            <rb-table-wrapper
                                #programsTable
                                [rowData]="programs"
                                [includeCheckbox]="true"
                                [hideRowSelection]="true"
                                (onRowSelect)="onProgramSelect($event)"
                                [mobileRightArrow]="false"
                                [mobileCellRendererComponent]="ProgramMobileCellRendererComponent"
                                [mobileCellRendererParams]="{ parent: this}"
                            >
                                <rb-column [headerName]="'STRINGS.NUMBER_SIGN' | translate" field="shortName" [minWidth]="100" [maxWidth]="100"></rb-column>
                                <rb-column [headerName]="'STRINGS.NAME' | translate" field="name" [minWidth]="98"></rb-column>
                            </rb-table-wrapper>
                        </ng-container>
                    </ng-container>

                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

    <rb-waiting-indicator
            *ngIf="showWaitingIndicator"
            [backgroundColor]="'rgba(0,0,0, 0.1)'"
            [text]="spinnerText"
            [error]="loadError"
            (okClick)="onActionErrorAcknowledged()">
    </rb-waiting-indicator>

</rb-dialog>


<ng-template #connectionRetry>
    <div class="connection-try">
        {{ 'SPECIAL_MSG.CONTROLLER_CONNECTION_FAILED' | translate }}
        &nbsp;
        <button
            mat-raised-button
            color="primary"
            (click)="connectController()"
        >
            {{ 'STRINGS.RETRY_UPPERCASE' | translate }}
        </button>
    </div>
</ng-template>

import { MapInfo } from './map-info.model';
import { TranslateService } from '@ngx-translate/core';

export class CenterOnLocationControl {

	static create(mapInfo: MapInfo, translate: TranslateService, parentDiv: HTMLElement) {

		// Set CSS for the control border.
		const controlUI = document.createElement('div');
		controlUI.style.backgroundColor = '#fff';
		controlUI.style.border = '2px solid #fff';
		controlUI.style.borderRadius = '3px';
		controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
		controlUI.style.cursor = 'pointer';
		controlUI.style.marginBottom = '10px';
		controlUI.style.marginRight = '11px';
		controlUI.style.textAlign = 'center';
		controlUI.title = translate.instant('STRINGS.CENTER_MAP');
		parentDiv.appendChild(controlUI);

		const controlText = document.createElement('div');
		controlText.style.margin = '0';
		controlText.style.width = '36px';
		controlText.style.height = '36px';
		controlText.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-2x.png)';
		controlText.style.backgroundSize = '360px 36px';
		controlText.style.backgroundPosition = '0px 0px';
		controlText.style.backgroundRepeat = 'no-repeat';
		controlText.style.transform = 'scale(0.7)';
		controlText.id = 'your_location_img';
		controlUI.appendChild(controlText);

		controlUI.addEventListener('click', function(evt) {
			mapInfo.goToCurrentLocation();
		});
		controlUI.addEventListener('dblclick', function(evt) {
			// If the user double-clicks on the find-me control, we might change some "hidden" option. Send a
			// notification.
			mapInfo.goToCurrentLocationSpecialActivated();
		});
	}
}

import { ApiService, HttpMethod } from '../_common/api.service';
import { Injectable } from '@angular/core';
import { KMZItem } from './models/kmz-Item.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RasterItem } from './models/raster-file.model';

@Injectable({
	providedIn: 'root'
})
export class LeafletApiService extends ApiService {

	// =========================================================================================================================================================
	// Raster Properties and Methods
	// =========================================================================================================================================================

	getRasterItemsForSite(siteId: number, bypassCache: boolean): Observable<RasterItem[]> {
		return this.apiRequest<any>(this.getRasterItemsForSiteUrl(siteId), HttpMethod.Get, null, null, bypassCache);
	}

	addRasterItem(siteId: number, rasterItemData: any): Observable<any> {
		return this.http.post<any>(this.addRasterItemUrl(siteId), rasterItemData, {
			reportProgress: true,
			observe: 'events'
		});
	}

	updateRasterItem(rasterItemId: number, updateValues: any): Observable<RasterItem> {
		const rasterItemClone = Object.assign({}, updateValues);
		delete rasterItemClone.imageLayer;
		delete rasterItemClone.editing;
		delete rasterItemClone.image;
		delete rasterItemClone.type;
		delete rasterItemClone.mapInfo;
		delete rasterItemClone.imageUrl;

		if (typeof updateValues.corners !== 'string') {
			rasterItemClone.corners = JSON.stringify(updateValues.corners);
		}
		return this.apiRequest<any>(this.updateRasterItemUrl(rasterItemId), HttpMethod.Put, rasterItemClone);
	}

	updateRasterItemList(updateValues: {id: number, item: RasterItem}[]) {
		return this.apiRequest<any>(
			this.updateRasterItemListUrl(),
			HttpMethod.Patch,
			updateValues.map((item) => ({
				id: item.id,
				patch: this.patchTransform(item.item),
			}))
		);
	}

	deleteRasterItem(siteId: number, id: any): Observable<null> {
		const ids = [id];
		return this.apiRequest<any>(this.deleteRasterItemUrl(siteId), HttpMethod.Delete, ids);
	}

	// =========================================================================================================================================================
	// Raster URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get rasterItemBaseUrl(): string { return `${this.baseApiUrl}RasterItem`; }

	private getRasterItemsForSiteUrl(siteId: number): string { return `${this.rasterItemBaseUrl}/GetRasterItems?siteId=${siteId}`; }

	private addRasterItemUrl(siteId: number): string { return `${this.rasterItemBaseUrl}/CreateRasterItem?siteId=${siteId}`; }

	private updateRasterItemUrl(id: number): string { return `${this.rasterItemBaseUrl}/UpdateRasterItem?rasterItemId=${id}`; }

	private updateRasterItemListUrl(): string { return `${this.rasterItemBaseUrl}/UpdateRasterItemList`; }

	private deleteRasterItemUrl(siteId: number): string { return `${this.rasterItemBaseUrl}/delete?siteId=${siteId}`; }

	// =========================================================================================================================================================
	// KMZ Properties and Methods
	// =========================================================================================================================================================

	getKmzItemById(kmzItemId: number): Observable<KMZItem> {
		return this.apiRequest<any>(this.getKmzItemByIdUrl(kmzItemId), HttpMethod.Get)
			.pipe(map(res => {
				return new KMZItem(res);
			}));
	}

	getActiveKmzItems(siteId: number, mapPref: string): Observable<KMZItem[]> {
		return this.apiRequest<any>(this.getActiveKmzItemsUrl(siteId, mapPref), HttpMethod.Get);
	}

	getKmzItemsForSite(siteId: number, bypassCache: boolean): Observable<KMZItem[]> {
		return this.apiRequest<any>(this.getKmzItemsForSiteUrl(siteId), HttpMethod.Get, null, null, bypassCache)
			.pipe(map(response => {
				const array = [];
				for (const res of response) {
					array.push(new KMZItem(res));
				}
				return array;
			}));
	}

	addKmzItem(kmzItemData: any): Observable<any> {
		const kmzItemClone = Object.assign({}, kmzItemData);
		delete kmzItemClone.type;

		return this.http.post<any>(this.addKmzItemUrl(kmzItemClone.siteId), kmzItemClone, {
			reportProgress: true,
			observe: 'events'
		});
	}

	updateKmzItem(kmzItemId: number, updateValues: any): Observable<KMZItem> {
		const kmzItemClone = Object.assign({}, updateValues);
		// clone properties so that the `delete properties.renderer` statement
		// doesn't delete the original renderer reference
		kmzItemClone.properties = Object.assign({}, updateValues.properties);

		delete kmzItemClone.layer;
		delete kmzItemClone.geoJson;
		delete kmzItemClone.id;
		delete kmzItemClone.visible;
		delete kmzItemClone.type;
		delete kmzItemClone.properties.renderer;
		delete kmzItemClone.loadingKMZ;

		return this.apiRequest<any>(this.updateKmzItemUrl(kmzItemId), HttpMethod.Patch, this.patchTransform(kmzItemClone));
	}

	updateKmzItemList(updateValues: {id: number, item: KMZItem}[]): Observable<KMZItem> {
		return this.apiRequest<any>(
			this.updateKmzItemListUrl(),
			HttpMethod.Patch,
			updateValues.map(item => ({id: item.id, patch: this.patchTransform(item.item)}))
		);
	}

	deleteKmzItem(siteId: number, id: any): Observable<null> {
		const ids = [id];
		return this.apiRequest<any>(this.deleteKmzItemUrl(siteId), HttpMethod.Delete, ids);
	}

	// =========================================================================================================================================================
	// KMZ URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get kmzItemBaseUrl(): string { return `${this.baseApiUrl}KMZItem`; }

	private getKmzItemByIdUrl(kmzItemId: number): string { return `${this.kmzItemBaseUrl}/ById?itemId=${kmzItemId}`; }

	private getActiveKmzItemsUrl(siteId: number, mapPref: string): string {
		return `${this.kmzItemBaseUrl}/GetActiveKmzItems?siteId=${siteId}&mapPref=${mapPref}`;
	}

	private getKmzItemsForSiteUrl(siteId: number): string { return `${this.kmzItemBaseUrl}/getKmzItemsForSite?siteId=${siteId}`; }

	private addKmzItemUrl(siteId: number): string { return `${this.kmzItemBaseUrl}/CreateKmzItem?siteId=${siteId}`; }

	private updateKmzItemUrl(id: number): string { return `${this.kmzItemBaseUrl}/UpdateKmzItem?kmzId=${id}`; }

	private updateKmzItemListUrl(): string { return `${this.kmzItemBaseUrl}/UpdateKmzItemList`; }

	private deleteKmzItemUrl(siteId: number): string { return `${this.kmzItemBaseUrl}/delete?siteId=${siteId}`; }

	// =========================================================================================================================================================
	// Shape Files Properties and Methods
	// =========================================================================================================================================================

	getCoordinateSystemsList(): Observable<any> {
		return this.apiRequest<any>(this.getCoordinateSystemsListUrl(), HttpMethod.Get);
	}

	getGeojsonFromShapeFiles(epsg: string, shpFile: File, dbfFile: File, shxFile: File): Observable<any> {
		const formData = new FormData();
		formData.append('ShapeFiles', shpFile);
		formData.append('ShapeFiles', dbfFile);
		formData.append('ShapeFiles', shxFile);
		return this.http.post<any>(this.getGeojsonFromShapeFilesUrl(epsg), formData, {
			reportProgress: true,
			observe: 'events'
		});
	}

	// =========================================================================================================================================================
	// Shape Files URLs
	// =========================================================================================================================================================

	private getCoordinateSystemsListUrl(): string { return `${this.baseApiUrl}ShapeItem/GetCoordinateSystems`; }

	private getGeojsonFromShapeFilesUrl(epsg: string): string { return `${this.baseApiUrl}ShapeItem/PostShapeItem?epsg=${epsg}` }

}

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { CompanyManagerService } from '../../../api/companies/company-manager.service';
import { CompanyStatus } from '../../../api/companies/models/company-status.model';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../../api/culture-settings/culture-settings-manager.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { LicenseManagerService } from '../../../api/license/license-manager.service';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Site } from '../../../api/sites/models/site.model';
import { SiteCount } from '../../../api/companies/models/site-count.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { StickyNoteManagerService } from '../../../api/sticky-notes/sticky-note-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { UiSettingsService } from '../../../api/ui-settings/ui-settings.service';

@UntilDestroy()
@Component({
	selector: 'rb-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
	private static SITE_PREFERENCE_KEY = 'dashboardSiteId';

	@ViewChild('coursesMenu') MatMenu;
	@ViewChild('freedomSystemDialog', { static: true }) freedomSystemDialog;

	@Output() navClick = new EventEmitter();
	@Output() logOutEvent = new EventEmitter();
	@Output() themeToggle = new EventEmitter();

	eventLogType = RbConstants.Alert.ALERT_LOG_TYPE;

	availablePages = ['', 'activity', 'controllers', 'programs', 'reports', 'systemsetup', 'playground'];
	companyStatus: CompanyStatus;
	currentPage: string;
	isAdmin = false;
	isRBDev = false;
	isOwner = false;
	isGolfSite = false;
	title: string;
	intervalSubscription: Subscription;
	isMobile = false;
	isTablet = false;
	showLicense = false;
	cultureName = 'en';
	singleSiteId = 0;
	sites: Site[];
	selectedSite: Site;
	selectedSiteId: number;
	visibleSiteCount: SiteCount[] = [];
	unackedAlarmCount = 0;
	unackedWarningCount = 0;
	unreadNotes = [];

	private freedomDialogRef: MatDialogRef<unknown, any>;

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private broadcastService: BroadcastService,
				private companyManager: CompanyManagerService,
				private cultureSettingsManager: CultureSettingsManagerService,
				private deviceManager: DeviceManagerService,
				private dialog: MatDialog,
				private route: ActivatedRoute,
				private router: Router,
				private siteManager: SiteManagerService,
				private translate: TranslateService,
				private licenseManager: LicenseManagerService,
				private uiSettingsService: UiSettingsService,
				private stickyNoteManager: StickyNoteManagerService
	) {}

	ngOnInit() {
		this.router.events
			.pipe(
				untilDestroyed(this),
				filter(event => event instanceof NavigationEnd),
				map(() => this.doPostRouteNavigationUpdate())
			)
			.subscribe();

		this.isMobile = this.deviceManager.isMobile;
		this.setTabletDisplayBreakpointsForLanguage();

		this.isAdmin = RbUtils.Common.isAdmin(this.authManager.getUserProfile().groupLevel);
		this.isRBDev = RbUtils.Common.isRBDev(this.authManager.getUserProfile().groupLevel);
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);

		this.licenseManager.isCloud()
			.pipe(take(1))
			.subscribe(isCloud => this.showLicense = (!(isCloud) && this.authManager.isOwner));

			this.companyManager.getCompanyStatusCore()
			.pipe(take(1))
			.subscribe((companyStatus: CompanyStatus) => {
				this.companyStatus = companyStatus;
				// Get Visible Sites
				this.siteManager.getSites().pipe(take(1))
					.subscribe((sites: Site[]) => {
						this.sites = sites || [];
						if (this.sites.length > 0) {
							this.uiSettingsService.getPreference(NavbarComponent.SITE_PREFERENCE_KEY).subscribe(preferredSiteId => {
								const site = this.sites.find(s => s.id === preferredSiteId);
								this.selectedSite = site || this.siteManager.selectedSite || this.sites[0];
								this.siteManager.selectedSite = this.selectedSite;
							});
						}
						this.getSelectedSite();
					
						if (this.isGolfSite) {
							this.stickyNoteManager.getUnreadNotesCounter(sites);
							this.intervalSubscription = interval(10 * 60000)
								.subscribe(val => this.stickyNoteManager.getUnreadNotesCounter(sites));
						}
						// Get the status for visible sites
						this.getVisibleStatusChange();

					});
			});

		// Monitor Company Status changes to update Alarm Log Count.
		this.companyManager.companyStatusChange
			.pipe(untilDestroyed(this))
			.subscribe((companyStatus: CompanyStatus) => {
				this.companyStatus = companyStatus;
				// Get the status for visible sites
				this.getVisibleStatusChange();
			});

		// Monitor isMobile state changes. Used for responsive design.
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => {
				this.isMobile = isMobile;
			});

		// Monitor Window resize to properly show/hide menu elements.
		this.deviceManager.windowResize
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.setTabletDisplayBreakpointsForLanguage();
			});

		// Monitor language changes to update display breakpoints
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe((cultureSettings: CultureSettings) => {
				this.cultureName = this.cultureSettingsManager.getCultureAsciiName(cultureSettings.cultureId);
				this.setTabletDisplayBreakpointsForLanguage();
			});

		// RB-9978: Add a setTimeout function to avoid ExpressionChangedAfterItHasBeenCheckedError
		setTimeout(() => {
			this.broadcastService.selectedEntityChange
				.pipe(untilDestroyed(this))
				.subscribe((entity) => { if (entity instanceof Site) this.getSelectedSite(); });
		});

		this.doPostRouteNavigationUpdate();

		this.getSites();
		this.siteManager.sitesUpdate.pipe(untilDestroyed(this)).subscribe(() => this.getSites());
		this.siteManager.siteDeleted.pipe(untilDestroyed(this)).subscribe(() => this.getSites());
	}

	ngOnDestroy() {
		/* Implemented to support untilDestroyed() */
		this.intervalSubscription?.unsubscribe();
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	hamburgerClick() {
		this.navClick.emit();
	}

	isActivePage(pageName: string): boolean {
		return this.currentPage === pageName;
	}

	isActiveSitePage(): boolean {
		return this.router?.url?.includes('/systemsetup/sites');
	}

	setEmptyPage() {
		this.currentPage = '';
	}

	logOut() {
		this.logOutEvent.emit();
	}

	toggleTheme() {
		this.themeToggle.emit();
	}

	alarmClick() {
		this.eventLogType = RbConstants.Alert.ALERT_LOG_TYPE;
		this.broadcastService.alarmEventLogsRequested.next(null);
	}

	goToNotes(siteId: number) {
		this.router.navigateByUrl(`/systemsetup/courseconfig/map?siteId=${ siteId }&showNotes=true`);
	}

	toggleSystemStatus() {
		this.deviceManager.toggleMobileSystemStatus();
	}

	/**
	 * Click handler for the (golf) how-to videos menu item. We use a click handler because some browsers seem to
	 * avoid opening new tabs unless we do it from a click handler.
	 */
	howToVideosClick() {
		RbUtils.Browser.openNewTab(RbConstants.Common.GolfHowToVideosURL);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	get unreadNotesCount(): number {
		if (this.stickyNoteManager.loggedUserUnreadNotesCount.length) {
			return this.stickyNoteManager.loggedUserUnreadNotesCount.reduce((acc, obj) => acc + obj.count, 0);
		} else {
			return 0
		}
	}

	getActivityRoute(): string {
		return !this.isGolfSite ? 'scheduled' : 'dryrun';
	}

	private getSelectedSite() {
		this.selectedSite = this.siteManager.selectedSite;
		this.selectedSiteId = this.selectedSite == null ? null : this.selectedSite.id;
	}

	private getVisibleStatusChange() {
		// include the company level counts by using them to initialize counts if existing
		this.unackedAlarmCount = this.companyStatus ? this.companyStatus.unackedAlarmCount : 0;
		this.unackedWarningCount = this.companyStatus ? this.companyStatus.unackedWarningCount : 0;
		if (this.sites !== undefined && this.sites.length > 0 && this.companyStatus !== undefined) {
			[this.companyStatus.siteCountMap].forEach(siteCounts => {
				Object.keys(siteCounts).forEach(prop => {
					if (this.sites.find(site => site.id === Number(prop))) {
						this.unackedAlarmCount = this.unackedAlarmCount + siteCounts[prop].unackedAlarmCount;
						this.unackedWarningCount = this.unackedWarningCount + siteCounts[prop].unackedWarningCount;
					}
				});
			});
		}
	}

	private getSites() {
		this.siteManager.getSites(true).pipe(take(1))
			.subscribe((sites: Site[]) => {
				this.sites = sites || [];
				this.singleSiteId = this.sites.length === 1 ? this.sites[0].id : null;
				// Get the status for the sites
				this.getVisibleStatusChange();
			});
	}

	private doPostRouteNavigationUpdate() {
		this.currentPage = '';

		if (!this.route.root.firstChild) return;

		if (this.route.root.firstChild.snapshot.url && this.route.root.firstChild.snapshot.url.length > 0) {
			const rootPath = this.route.root.firstChild.snapshot.url[0].path;
			this.currentPage = this.availablePages.indexOf(rootPath) !== -1 ? rootPath : '';
		}

		let route = this.route;
		while (route.firstChild) {
			route = route.firstChild;
		}

		if (route.outlet !== 'primary') return;

		// RB-9978: Since course map's root path is also /systemsetup,
		// adding this checking will distinguish active route between them.
		route.url.subscribe(url => {
			if (url.length > 0 && url[0] && url[0].path === 'map') {
				this.currentPage = 'map';
			}
		});

		route.data.subscribe(data => {
			switch (data['titleType']) {
				case 'siteOrCourse':
					this.title = this.isGolfSite ? 'STRINGS.COURSES' : 'STRINGS.SITES';
					break;

				case 'controllername':
				case 'programname':
				case 'programgrpname':
					this.title = null;
					break;

				default:
					this.translate.get(data['titleKey'] ? data['titleKey'] : 'STRINGS.TITLE_DASHBOARD')
						.pipe(take(1))
						.subscribe(title => this.title = title);
					break;
			}
		});
	}

	/** Method to set culture specific Tablet (smaller than desktop and larger than mobile) display breakpoints for various Main Navigation elements.
	 *  Values are in pixels. */
	private setTabletDisplayBreakpointsForLanguage() {
		let maxTabletWindowWidth: number;

		switch (this.cultureName) {
			case 'en':
				maxTabletWindowWidth = !this.isGolfSite ? RbConstants.Common.DefaultMaxTabletWindowWidth : 1172;
				break;
			case 'fr':
				maxTabletWindowWidth = !this.isGolfSite ? 1370 : 1307;
				break;
			case 'de':
				maxTabletWindowWidth = !this.isGolfSite ? 1281 : 1248;
				break;
			case 'it':
				maxTabletWindowWidth = !this.isGolfSite ? 1307 : 1257;
				break;
			case 'pt':
				maxTabletWindowWidth = !this.isGolfSite ? 1363 : 1313;
				break;
			case 'es':
				maxTabletWindowWidth = !this.isGolfSite ? 1364 : 1313;
				break;
			case 'tr':
				maxTabletWindowWidth = RbConstants.Common.DefaultMaxTabletWindowWidth;
				break;
			case 'zh':
				maxTabletWindowWidth = RbConstants.Common.DefaultMaxTabletWindowWidth;
				break;
			case 'ja':
			case 'sv':
				// ????
			default:
				maxTabletWindowWidth = RbConstants.Common.DefaultMaxTabletWindowWidth;
				break;
		}

		this.isTablet = window.innerWidth >= RbConstants.Common.MaxMobileWindowWidth && window.innerWidth <= maxTabletWindowWidth;
	}

	onFreedomSystemClicked() {
		this.freedomDialogRef = this.dialog.open(this.freedomSystemDialog);
	}

	onFreedomDialogComplete() {
		this.freedomDialogRef.close();
	}
}

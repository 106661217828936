import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Area } from '../../../api/areas/models/area.model';
import { AreaManagerService } from '../../../api/areas/area-manager.service';
import { HoleGroup } from '../../../api/areas/models/holeGroup.model';
import { MatSelect } from '@angular/material/select';

@Component({
	selector: 'rb-select-holes-dropdown',
	templateUrl: './select-holes-dropdown.component.html',
	styleUrls: [ './select-holes-dropdown.component.scss' ]
})
export class SelectHolesDropdownComponent implements OnInit {

	@ViewChild('matSelect', { static: true }) matSelect: MatSelect;

	@Input() flat = false;
	@Input() isMobile: boolean;
	@Input() set holesAndPlaces(value: Area[]) {
		this.holesAndAreas = value || [];
		this.holeGroups = this.areaManager.createGroupsFromHolesAndPlaces(this.holesAndAreas);
	}
	@Input() isWhiteBackground = false;

	/**
	 * Flag indicating that the drop-down label text will be left-justified. When not flagged, the label is centered.
	 */
	@Input() isLeftJustified = false;

	@Output() holesSelected = new EventEmitter<Area[]>();

	holesAndAreas: Area[] = [];
	holeGroups: HoleGroup[] = [];
	selectedHoles: Array<Area> = [];

	constructor(private areaManager: AreaManagerService) { }

	ngOnInit() {
		console.log('select holes dropdown')
	}

	invokeRemotely() {
		(<HTMLElement>this.matSelect._elementRef.nativeElement).click();
	}

	deselectAll() {
		this.selectedHoles = [];
	}

	equals(objOne, objTwo) {
		if (objOne && objTwo) {
			return objOne.id === objTwo.id;
		}
	}

	areAllHolesSelected(holeGroup: HoleGroup) {
		const numHolesInGroup = holeGroup.areas.length;
		const numHolesSelectedInGroup = this.selectedHoles.filter(h => holeGroup.areas.some(a => a.number === h.number)).length;
		// const numHolesSelectedInGroup =
		// 	this.selectedHoles.filter(h => h.number >= holeGroup.minHoleNumber && h.number <= holeGroup.maxHoleNumber && h.isGolfArea).length;
		return numHolesInGroup === numHolesSelectedInGroup;
	}

	areSomeHolesSelected(holeGroup: any) {
		const numHolesInGroup = holeGroup.areas.length;
		const numHolesSelectedInGroup = this.selectedHoles.filter(h => holeGroup.areas.some(a => a.number === h.number)).length;
		return numHolesSelectedInGroup > 0 && numHolesSelectedInGroup < numHolesInGroup;
	}

	changeHoleSelections(holeGroup: HoleGroup, selected: boolean) {

		const otherHolesSelected = this.selectedHoles.filter(h => !holeGroup.areas.some(a => a.number === h.number));
		if (!selected) {
			this.selectedHoles = otherHolesSelected;
			return;
		}

		this.selectedHoles = otherHolesSelected.concat(holeGroup.areas);
	}

	onSelectClosed() {
		this.holesSelected.emit(this.selectedHoles);
	}
}

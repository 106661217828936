<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="drm-cell-container">
    <div class="sf-top-pane top" >
        <div class="drmc-pane no-max">
            <div class="drmc-labels">
                <div>{{'STRINGS.NAME' | translate}}:</div>
            </div>
            <div class="drmc-values">
                <div class="ellipsis-text">{{pingTestItem?.name}}</div>
            </div>
        </div>
    </div>

    <div class="sf-top-pane">
        <div class="drmc-pane left">
            <div class="drmc-labels">
                <div>{{'STRINGS.TERMINAL' | translate}}:</div>
                <div>{{'STRINGS.TYPE' | translate}}:</div>
                <div>{{'STRINGS.ADDRESS' | translate}}:</div>
            </div>
            <div class="drmc-values">
                <div class="ellipsis-text">{{pingTestItem?.terminal}}</div>
                <div class="ellipsis-text">{{pingTestItem?.deviceTypeString}}</div>
                <div class="ellipsis-text">{{pingTestItem?.address}}</div>
            </div>
        </div>

        <div class="drmc-pane right">
            <div class="drmc-labels">
                <div>{{'STRINGS.VOLTAGE_V' | translate}}:</div>
                <div>{{'STRINGS.CURRENT_MA' | translate}}:</div>
            </div>
            <div class="drmc-values">
                <div class="ellipsis-text">{{pingTestItem?.voltage != null ? pingTestItem.voltage : '--'}}</div>
                <div class="ellipsis-text">{{pingTestItem?.current != null ? pingTestItem.current : '--'}}</div>
            </div>
        </div>
    </div>
    <div class="sf-top-pane bottom rb-small-label">
        <div class="drmc-pane no-max">
            <div class="drmc-labels">
                <div>{{'STRINGS.LAST_PING' | translate}}:</div>
            </div>
            <div class="drmc-values">
                <div class="ellipsis-text">{{appDateAdapter.formatDateTime(pingTestItem?.lastPing) || '--'}}</div>
            </div>
        </div>
    </div>
</div>


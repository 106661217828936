
export class ControllerFirmwareInfo {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	companyID: number = null;
	siteID: number = null;
	id: number;
	type: any;					// TODO - MW: Use correct enum type.
	cicType: any;				// TODO - MW: Use correct enum type.
	isCic256 = false;
	hasMrm = false;
	version: string;
	latestVersion: string = null;
	cicVersion: string;
	latestCicVersion: string = null;
	bootloaderVersion: string = null;
}

import { Component, ElementRef, HostBinding, Injector, OnInit, ViewChild } from '@angular/core';
import { Area } from '../../../../../api/areas/models/area.model';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { Subarea } from '../../../../../api/areas/models/subarea.model';
import { TableWrapperComponent } from '../../table-wrapper/table-wrapper.component';

@Component({
	selector: 'rb-areas-mobile-cell-renderer',
	templateUrl: './areas-mobile-cell-renderer.component.html',
	styleUrls: ['./areas-mobile-cell-renderer.component.scss']
})
export class AreasMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	@ViewChild('mobileDiv') mobileDiv: ElementRef;

	area: Area;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.area = params.data;
	}

	get isSubarea(): boolean { return this.area instanceof Subarea; }
	get subarea(): Subarea { return (this.area as unknown) as Subarea; }

	addSubarea(event: any, area: Area) {
		const tableWrapper = this.params.context.componentParent as TableWrapperComponent;
		if (tableWrapper.onMobileCommandRequested == null) return;

		event.stopPropagation();
		tableWrapper.onMobileCommandRequested.emit({ command: 'addSubarea', data: area });
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>
<div class="golf-license-warning">
	<div class="license-background">
        <div class="center">
		    <div class="icon-space icon-space-warning ">
				<mat-icon [inline]="true"><i class="icon-warning"></i></mat-icon>
		    </div>

		    <div class="text-space">
			    <div>{{'STRINGS.DEMO_MODE' | translate}}</div>
		    </div>
        </div>
	</div>
</div>

import { RbUtils } from "../../../common/utils/_rb.utils";
export class CloudDryRunItem {
	constructor(json: any = null) {
		if (json) {
			const convertedObject = Object.keys(json).reduce((acc, key) => {
				acc[`${key.charAt(0).toLowerCase()}${key.slice(1)}`] = json[key];
				return acc;
				}, {});
			// Dynamo db stored stillWateringWhenWaterWindowCloses under a binary type
			convertedObject['stillWateringWhenWaterWindowCloses'] = convertedObject?.['stillWateringWhenWaterWindowCloses'] === 1;
			Object.assign(this, convertedObject);

			if (convertedObject['timestamp']) {
				this.timestamp = RbUtils.Conversion.convertDateToUTCDateTimeParameterString(convertedObject['timestamp']);
				this.timeOffset = RbUtils.Conversion.convertDateToUTCDateTimeOffsetParameterString(convertedObject['timestamp']);
			}

			if (convertedObject['start']) {
				this.start = RbUtils.Conversion.convertDateToUTCDateTimeParameterString(convertedObject['start']);
				this.startTimeOffset = RbUtils.Conversion.convertDateToUTCDateTimeOffsetParameterString(convertedObject['start']);
			}

			if (convertedObject['stop']) {
				this.stop = RbUtils.Conversion.convertDateToUTCDateTimeParameterString(convertedObject['stop']);
				this.stopTimeOffset = RbUtils.Conversion.convertDateToUTCDateTimeOffsetParameterString(convertedObject['stop']);
			}
		}
	}

	pK: string;
	sK: string;
	companyId: number;
	creationTime: string;
	endTime: string;
	expiration: number;
	flowRate: number;
	isHistoricalDryrun: number;
	name: string;
	params: number;
	programId: number;
	programShortName: any;
	satelliteId: number;
	start: string;
	startTimeOffset: string;
	startTime: string;
	startTimeIndex: number;
	stationId: number;
	stillWateringWhenWaterWindowCloses: boolean;
	stop: string;
	stopTimeOffset: string;
	terminal: number;
	timestamp: string;
	timeOffset: string;
	totalFlow: number;
}

import { Company } from '../../address-book/models/company.model';
import { RbccUser } from '../../users/models/rbcc-user.model';

export class PinCode {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.company) { this.company = new Company(json.company); }
			if (json.user) { this.user = new RbccUser(json.user); }
		}
	}

	company: Company;
	companyId: number;
	description: string;
	id: number;
	pin: number;
	user: RbccUser;
	userId: number;
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="main-app-container" [ngClass]="{'rb-flat-theme': displayFlatTheme}">

	<mat-drawer-container class="mat-drawer-container root-container" hasBackdrop="true">

		<!-- Mobile -->
		<mat-drawer #drawer [mode]="'over'" class="mobile-drawer dn-m">
			<div class="pv3 v-mid w-100 primaryColor">
				<div class="mobile-logo-icon">
					<rb-logo [isGolfSite]="isGolfSite"></rb-logo>
				</div>
				<div>
					<mat-accordion>
						<mat-expansion-panel class="boxShadow header-expansion-panel">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<span class="menu-text pl3">{{name}}</span>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="ml4">
								<button mat-menu-item [routerLink]="['./systemsetup', 'profile']"
									(click)="drawer.toggle()">{{'STRINGS.PROFILE' | translate}}</button>
								<button mat-menu-item [routerLink]="['./systemsetup', 'about']" (click)="drawer.toggle()">
									<span class="menu-text">{{'STRINGS.ABOUT' | translate}}</span>
								</button>
								<button *ngIf="isGolfSite" mat-menu-item [routerLink]="null"
									(click)="drawer.toggle(); howToVideosClick();">
									{{'STRINGS.HOW_TO_VIDEOS' | translate}}
								</button>
								<button *ngIf="!isGolfSite && authManager.isOwner && authManager.allowSaaS" mat-menu-item [routerLink]="['./systemsetup', 'admin', 'subscriptions']" (click)="drawer.toggle()">
									<span class="menu-text">{{'STRINGS.SUBSCRIPTIONS' | translate}}</span>
								</button>
								<button mat-menu-item (click)="logOut()">
									<mat-icon>exit_to_app</mat-icon>
									<span class="menu-text pl3">{{'STRINGS.LOG_OUT' | translate}}</span>
								</button>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</div>
			<div class="pv3 pl4-l mt1 w-100 v-mid menu-button">
				<span *ngIf="isGolfSite">
					<button *ngIf="sites == null || sites.length === 0" mat-menu-item
							[routerLink]="['/']"
							(click)="drawer.toggle()">
						<mat-icon>golf_course</mat-icon>
						<span class="menu-text pl3">{{'STRINGS.COURSE' | translate}}</span>
					</button>
					<button *ngIf="sites != null && sites.length === 1" mat-menu-item
							[routerLink]="['/systemsetup','courseconfig', 'stations']" [queryParams]="{ siteId: singleSiteId, showLeftSidebar: true }"
							(click)="drawer.toggle()">
						<mat-icon>golf_course</mat-icon>
						<span class="menu-text pl3">{{'STRINGS.COURSE' | translate}}</span>
					</button>
					<mat-accordion *ngIf="sites != null && sites.length > 1">
						<mat-expansion-panel class="boxShadow">
							<mat-expansion-panel-header>
								<mat-panel-title>
										<mat-icon class="iconColor">golf_course</mat-icon>
										<span class="menu-text pl3">{{'STRINGS.COURSE' | translate}}</span>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="ml4 courses-expansion-panel">
								<button *ngFor="let site of sites" mat-menu-item
										[routerLink]="['/systemsetup', 'courseconfig', 'stations']"
										[queryParams]="{ siteId: site.id, showLeftSidebar: true }"
										(click)="drawer.toggle()">
									{{site.name}}
								</button>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</span>
				<span *ngIf="isGolfSite">
					<button mat-menu-item [routerLink]="['./activity','dryrun']" (click)="drawer.toggle()">
						<mat-icon>leak_add</mat-icon>
						<span class="menu-text pl3">{{'STRINGS.ACTIVITY' | translate}}</span>
					</button>
				</span>
				<span *ngIf="!isGolfSite">
					<button mat-menu-item [routerLink]="['./activity','scheduled']" (click)="drawer.toggle()">
						<mat-icon>leak_add</mat-icon>
						<span class="menu-text pl3">{{'STRINGS.ACTIVITY' | translate}}</span>
					</button>
				</span>
				<span *ngIf="!isGolfSite">
					<button mat-menu-item [routerLink]="['./systemsetup','sites']" (click)="drawer.toggle()">
						<img
							class="svg-icon"
							src="../assets/icon-white-position.svg">
						<span class="menu-text pl3">{{'STRINGS.SITES' | translate}}</span>
					</button>
				</span>
				<span *ngIf="!isGolfSite">
					<button mat-menu-item [routerLink]="['./controllers','controllers']" (click)="drawer.toggle()">
						<mat-icon>picture_in_picture</mat-icon>
						<span class="menu-text pl3">{{'STRINGS.CONTROLLERS' | translate}}</span>
					</button>
				</span>
				<span *ngIf="isGolfSite">
					<button mat-menu-item [routerLink]="['./programs','programgroups']" (click)="drawer.toggle()">
						<mat-icon>timer</mat-icon>
						<span class="menu-text pl3">{{'STRINGS.PROGRAMS' | translate}}</span>
					</button>
				</span>
					<span *ngIf="!isGolfSite">
					<button mat-menu-item [routerLink]="['./programs','programs']" (click)="drawer.toggle()">
						<mat-icon>timer</mat-icon>
						<span class="menu-text pl3">{{'STRINGS.PROGRAMS' | translate}}</span>
					</button>
				</span>
				<span *ngIf="isMobile && !isGolfSite">
					<button mat-menu-item (click)="drawer.toggle(); chat.openMobileChat()">                                 					<mat-icon>chat_bubble_outline</mat-icon>
						<span class="menu-text pl3">{{'STRINGS.CHAT' | translate}}</span>
					</button>
				</span>
				<button mat-menu-item [routerLink]="['./reports']" (click)="drawer.toggle()">
					<img
						class="svg-icon"
						src="../assets/icon-white-accessment.svg">
					<span class="menu-text pl3">{{'STRINGS.REPORTS' | translate}}</span>
				</button>
				<a *ngIf="!isGolfSite" type="button" mat-menu-item href="https://www.rainbird.com/products/iq4" target="_blank" (click)="drawer.toggle()">
					<mat-icon>help_outline</mat-icon>
					<span class="menu-text pl3">{{'STRINGS.SUPPORT' | translate}}</span>
				</a>
				<mat-accordion>
					<mat-expansion-panel class="boxShadow">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<img
									class="svg-icon"
									src="../assets/icon-white-chrome.svg">
								<span class="menu-text pl3">{{(isGolfSite ? 'STRINGS.SYSTEM_SETTINGS' : 'STRINGS.SYSTEM_SETUP') | translate}}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="ml4">
							<button mat-menu-item [routerLink]="['./systemsetup', (isGolfSite ? 'courses' : 'sites')]"
								(click)="drawer.toggle()">{{(isGolfSite ? 'STRINGS.COURSES' : 'STRINGS.SITES') | translate}}</button>

							<button *ngIf="isGolfSite" mat-menu-item [routerLink]="['./systemsetup', 'flo_manager']"
								(click)="drawer.toggle()">{{'STRINGS.FLO_MANAGER' | translate}}</button>

							<button *ngIf="isGolfSite" mat-menu-item [routerLink]="['./systemsetup', 'sensors']"
								(click)="drawer.toggle()">{{'STRINGS.SENSORS' | translate}}</button>

							<button mat-menu-item [routerLink]="['./systemsetup', 'weather']"
								(click)="drawer.toggle()">{{'STRINGS.WEATHER' | translate}}</button>

							<button *ngIf="isGolfSite" mat-menu-item [routerLink]="['./diagnostics']"
								(click)="drawer.toggle()">{{'STRINGS.DIAGNOSTICS' | translate}}</button>

							<button *ngIf="isGolfSite" mat-menu-item
									(click)="drawer.toggle(); onFreedomSystemClicked()">{{'STRINGS.FREEDOM_SYSTEM' | translate}}</button>

							<div *ngIf="showAdmin">
								<button mat-menu-item [routerLink]="['./systemsetup','admin']"
									(click)="drawer.toggle()">{{'STRINGS.ADMIN' | translate}}</button>
							</div>

						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</mat-drawer>

		<!-- Main Content -->
		<mat-drawer-content>

			<div class="main-content-container">

				<!-- Persistent banner used for ICI and DI -->
				<rb-interface-fault-finding-warning *ngIf="isFaultFindingModeOn"></rb-interface-fault-finding-warning>

				<!-- Dismissable banner for Golf -->
				<rb-top-banner-panel
					(onClose)="onBannerClose()"
					[showPanel]="showExpiryPanel || showBannerMessage || showGolfLicenseWarning || showDemoModeWarning || showNewSoftwareVersionAvailable ||
						entitlementsExpirationState !== EntitlementsRenewalState.NotExpired || (loadError != null) || showCompanyLicenseWarning">
					<rb-banner-message *ngIf="showBannerMessage"></rb-banner-message>
					<rb-gprs-renew-warning *ngIf="showExpiryPanel"
						id="top-banner-gprs-expiry-warning"
						[expiryState]="gprsSubscriptionExpiryState?.expiryState"
						[warningText]="gprsSubscriptionExpiryState?.expiryText"
						[expiryDate]="gprsSubscriptionExpiryState?.expiryDateString">
					</rb-gprs-renew-warning>
					<rb-entitlements-renewal-warning *ngIf="entitlementsExpirationState !== EntitlementsRenewalState.NotExpired"
						[expirationDate]="entitlementsExpirationDate" [renewalState]="entitlementsExpirationState"
					(messageClosed)="onEntitlementsWarningClosed()">
					</rb-entitlements-renewal-warning>
					<div *ngIf="loadError" id="top-banner-load-error" [innerHTML]="loadError"></div>
					<rb-golf-license-warning *ngIf="showGolfLicenseWarning"></rb-golf-license-warning>
					<div *ngIf="showDemoModeWarning && 
						!loadError && 
						!showGolfLicenseWarning && 
						!showNewSoftwareVersionAvailable" 
						id="top-banner-demo-mode-warning" 
						[innerHTML]="demoModeWarningMessage">
					</div>
					<div *ngIf="showCompanyLicenseWarning && 
						!showDemoModeWarning && 
						!loadError && 
						!showGolfLicenseWarning && 
						!showNewSoftwareVersionAvailable"
						id="top-banner-license-warning">
						{{'STRINGS.LICENSE_INVALID_ON_THIS_MACHINE' | translate}}
					</div>
					<rb-new-software-version-available *ngIf="showNewSoftwareVersionAvailable"></rb-new-software-version-available>
				</rb-top-banner-panel>

			
				<rb-navbar
					class="rb-main-navbar rb-navbar primaryColor "
					*ngIf="isLoggedIn && showMainNav"
					(logOutEvent)="logOut()"
					(themeToggle)="onToggleTheme()"
					(navClick)="drawer.toggle()">
				</rb-navbar>

				<div *ngIf="isLoggedIn && isMobile && showMainNav" class="rb-mobile-system-status" [ngClass]="{'show': showMobileSystemStatus}">
					<rb-system-status-toolbar></rb-system-status-toolbar>
				</div>

				<div class="main-body">
					<div class="container">
						<router-outlet></router-outlet>
					</div>

					<div class="snacks fixed mw6 rb-min-width-2">
						<rb-global-snack-bar></rb-global-snack-bar>
					</div>

					<rb-password-expiration-snackbar
						*ngIf="showPasswordExpirationWarning"
						class="password-expiration-notification-snackbar"
						[expirationDate]="pwdExpirationDate"
						(onClose)="onClosePasswordExpirationWarn()">
					</rb-password-expiration-snackbar>
				</div>

			</div>

			<rb-breadcrumb-trail class="rb-breadcrumb-trail" *ngIf="!isMobile && !hideGlobalBreadcrumbTrail"></rb-breadcrumb-trail>

		</mat-drawer-content>

	</mat-drawer-container>

	<canvas id="scratchPad"></canvas>
</div>

<!-- Common Message Box -->
<ng-template #messageBox>
	<rb-global-message-box
		[title]="messageBoxInfo.title"
		[message]="messageBoxInfo.message"
		[okButtonName]="messageBoxInfo.okButtonName"
		[infoText]="messageBoxInfo.infoText"
		[icon]="messageBoxInfo.icon"
		[buttons]="messageBoxInfo.buttons"
		[okYesCallback]="messageBoxInfo.okYesCallback"
		[cancelNoCallback]="messageBoxInfo.cancelNoCallback"
		(cancelNoClick)="onCloseMessageBox()"
		(okYesClick)="onCloseMessageBox()">
	</rb-global-message-box>
</ng-template>


<!-- This is new common message box -->
<rb-custom-modal
	#global_message_box
	[useMobileModalStyle]="true"
>
	<rb-alert-modal [options]="globalMessageBoxOptions">
		<div class="global-message-box" [innerHTML]="globalMessageBoxOptions?.messageContent">
		</div>
	</rb-alert-modal>
</rb-custom-modal>


<!-- Freedom System dialog -->
<ng-template #freedomSystemDialog>
	<rb-freedom-system-dialog (complete)="onFreedomDialogComplete()"></rb-freedom-system-dialog>
</ng-template>

<div *ngIf="!isGolfSite">
	<rb-chat #chat></rb-chat>
</div>

<!-- Global Snackbars -->
<rb-request-access-snackbar
	#requestAccessSnackbar
	[userRequestAccess]="userRequestAccess"
></rb-request-access-snackbar>

import * as moment from 'moment';
import { Controller } from '../../controllers/models/controller.model';
import { EventDayOff } from '../../event-day-off/models/event-day-off.model';
import { Program } from '../../programs/models/program.model';
import { ProgramGroup } from '../../program-groups/models/program-group.model';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SeasonalAdjust } from '../../sites/models/seasonal-adjust.model';
import { Site } from '../../sites/models/site.model';
import { Station } from '../../stations/models/station.model';

/**
 * Adjustment describes a single adjustment that a run-time might undergo. It could be the base run time, the
 * ET base run time, SimpleET adjustment, station adjustment, program adjustment, etc., etc. A list of these
 * from the API represents the same set of adjustments applied to get the final program step run time, so we
 * can use that to detail why a given station has a given run time in a given program.
 */
export class Adjustment {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.baseRunTime) { this.baseRunTime = RbUtils.Conversion.convertTimeSpanStringToDuration(json.baseRunTime); }
			if (json.maxRunTime) { this.maxRunTime = RbUtils.Conversion.convertTimeSpanStringToDuration(json.maxRunTime); }
		}
	}

	source: AdjustmentType;
	sourceType: string;
	baseRunTime: moment.Duration;
	adjustmentPercentage = -1;
	maxRunTime: moment.Duration;
	maxAdjustment: number;
	targetInches = -1;
	precipitationRate_in_hour = -1;
	station: Station = null;
	program: Program = null;
	programGroup: ProgramGroup = null;
	satellite: Controller = null;
	eventDayOff: EventDayOff = null;
	site: Site = null;
	siteSeasonalAdjust: SeasonalAdjust = null;
	landscapeInfo: any;
}

/**
 * AdjustmentType describes the sources of station run time adjustment which might be applied.
 */
export enum AdjustmentType {
	BaseRunTime = 0,              // Base starting points
	ETInches = 1,
	LandscapeInfoInches = 2,
	// Add ONLY base starting points here, not percentage adjustment types.

	ProgramAdjust = 11,              // Program- and program-group-based adjustments
	ProgramTemporaryAdjust = 12,
	ProgramGroupAdjust = 13,
	ProgramGroupTemporaryAdjust = 14,
	// Add ONLY program- and program group-based adjustment types here.

	StationYearlyAdjust = 21,        // Station-based adjustments
	StationMonthlyAdjust = 22,
	StationTemporaryAdjust = 23,
	ProgramStationAdjustOverride = 24,
	ProgramGroupStationAdjustOverride = 25,
	DistributionUniformityETAdjust = 26,
	/// <summary>
	/// When the golf station is used in an ET environment, it has another adjustment which can be applied, ETAdjustFactor.
	/// </summary>
	StationETAdjust = 27,
	// Add ONLY station-based adjustments types here.

	SiteSeasonalAdjust = 41,            // Site-based adjustments here
													// Add ONLY site-based adjustment types here.

	SatelliteSeasonalAdjust = 51,    // Satellite-based adjustments here
	SatelliteMaxRunTimeAdjust = 52,
	// Add ONLY satellite-based adjustment types here.

	RainDelay = 61,                  // Miscellaneous adjustment types
	RainAdjust = 62,
	EventDayOffAdjust = 63,
	// Add all other miscelleaneous adjustments here. This might, in the future, include
	// sensor-based adjustment (or maybe several types of sensor-based adjustment),
	// capacity limitations, etc.
	SimpleET = 64,
	SimpleETFinalAdjustMaximum = 65,

	SystemWaterBudget = 66,
	ProgramSystemWaterBudgetAdjustOverride = 67,
	ProgramGroupSystemWaterBudgetAdjustOverride = 68,
}

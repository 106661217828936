// errors-handler.ts

import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { MessageBoxInfo } from '../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from './message-box.service';
import { RbConstants } from '../constants/_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';
import { ToasterService } from './toaster.service';

// errors-handler.ts
@Injectable({
	providedIn: 'root'
})
export class RbccErrorHandlerService implements ErrorHandler {

	constructor(private authManager: AuthManagerService,
				private toasterService: ToasterService,
				private messageBoxService: MessageBoxService
	) { }

	handleError(error: Error | HttpErrorResponse) {
		// Suppress jsPDF promise error. not showing toaster message in the case.
		if (error.message === 'Uncaught (in promise): [object Undefined]') return;

		// If there is ChunkLoadError, notify user to reload the page
		if (error.message?.includes('ChunkLoadError')) {
			this.promptUserToReload();
			return;
		}

		// RB-7095: Don't show toast if user is unauthorized before they've had a chance to login. This can happen on app load and post log out.
		if (error instanceof HttpErrorResponse && error.status === 401 && !this.authManager.isLoggedIn) return;

		// RB-13575: Don't show toast message if a 500 (InternalServerError) is received.
		// TODO: Remove toast message for all HTTP errors if we don't need it.
		if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.InternalServerError) {
			this.logHttpError(error);
			return;
		};

		const userProfile = this.authManager.getUserProfile();

		if ((error instanceof HttpErrorResponse && error.status) || (userProfile && RbUtils.Common.isGolfSite(userProfile.siteType))) {
			// RB-14362: error.status = 0 means that this is an unknown error. Don’t show a toast for it.
			this.toasterService.showToaster(error.message, RbConstants.Form.TOAST_TIME);
		}

		console.error(error);
	}

	/**
	 * Utility for errors that have previously been shown as toasts but are now not.
	 * @param error - HTTP error that would previously have been shown in a toast message.
	 */
	private logHttpError(error: HttpErrorResponse) {
		// Later we can integrate with any cloud logging service.
		// For now, just save the error to the browser console so it's not totally lost for for debugging/fixing purposes.
		console.error(error);
	}

	/**
	 * Prompt user to reload the page if there is an error loading the application
	 * TODO: If the reload works well, skip the message box button click and just show a box for a second or two before reloading
	 */
	private promptUserToReload(): void {
		const mbi = MessageBoxInfo.create(
			RbUtils.Translate.instant('STRINGS.RELOAD_APPLICATION_TITLE'),
			RbUtils.Translate.instant('STRINGS.RELOAD_APPLICATION_ERROR_MESSAGE'),
			null,
			() => {
				window.location.reload();
			},
			null,
			RbEnums.Common.MessageBoxButtons.Ok
		);
		this.messageBoxService.showMessageBox(mbi);
	}
}

import { DryRunFlowDatum } from './dry-run-flow-datum.model';

export class DryRunFlowDatumStation {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.dryRunFlowDatum) this.dryRunFlowDatum = new DryRunFlowDatum(json.dryRunFlowDatum);
		}
	}

	// RB-12041: DryRunFlowDatumStation and DryRunFlowDatum IDs are now bigint
	id: bigint;
	dryRunFlowDatumId: bigint;

	companyId: number;
	stationId: number;
	stationName: string;
	flowRate: number;
	dryRunFlowDatum: DryRunFlowDatum = null;
	terminal: number;
	pumpId: number;
	pumpName: string;

}

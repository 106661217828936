import { RbUtils } from '../../../common/utils/_rb.utils';

export class FlowLog {

	companyId: number;
	flowRate1: number;
	flowRate2: number;
	flowRate3: number;
	flowRate4: number;
	flowRate5: number;
	flowSensorId: number;
	id: number;
	recordNumber: number;
	satelliteId: number;
	siteId: number;
	timestamp: Date;
	// satellite: ISatellite;  // This will cause circular dependency

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.timestamp) { this.timestamp = RbUtils.Conversion.convertStringToDate(json.timestamp); }
			// if (json.satellite) { this.satellite = new Satellite(json.satellite); }
		}
	}
}

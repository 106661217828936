import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppDateAdapter } from '../../../shared-ui/custom/date-adapter';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { CheckboxTableFilterComponent } from '../../../shared-ui/components/tables/filters/checkbox-table-filter/checkbox-table-filter.component';
import { ColumnFilterDefaultValue } from '../../../shared-ui/components/tables/column/column.component';
import { CompanyManagerService } from '../../../api/companies/company-manager.service';
import { CompanyPreferences } from '../../../api/companies/models/company-preferences.model';
import { ControllerManagerService } from '../../../api/controllers/controller-manager.service';
import { DecoderStationDiagnosticResult } from '../../diagnostics/diagnostics-container/decoder-station-diagnostics/models/decoder-station-diagnostic-result.model';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { DiagnosticData } from '../../../api/manual-ops/models/diagnostic-data.model';
import { DiagnosticLogManagerService } from '../../../api/diagnostic-log/diagnostic-log-manager.service';
import { GridContainerComponent } from '../../../shared-ui/components/grid-container/grid-container.component';
import { IcmDiagnosticResult } from '../../diagnostics/diagnostics-container/icm-diagnostics/models/icm-diagnostic-result.model';
import { IcShortAddressPollData } from '../../../api/manual-ops/models/ic-short-address-poll-data.model';
import { IcVoltagePollData } from '../../../api/manual-ops/models/ic-voltage-poll-data.model';
import { IStationDiagnosticHistory } from '../../../api/diagnostic-log/models/station-diagnostic';
import { ManualOpsManagerService } from '../../../api/manual-ops/manual-ops-manager.service';
import { MapInfoLeaflet } from '../../../common/models/map-info-leaflet.model';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxInfo } from '../../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from '../../../common/services/message-box.service';
import { QuickCheckData } from '../../../api/manual-ops/models/quick-check-data.model';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Station } from '../../../api/stations/models/station.model';
import {
	StationDiagnosticCellRendererComponent
} from '../../../shared-ui/components/tables/cell-renderers/station-diagnostic-cell-renderer/station-diagnostic-cell-renderer.component';
import {
	StationDiagnosticMobileRendererComponent
} from '../../../shared-ui/components/tables/mobile-cell-renderers/station-diagnostic-mobile-renderer/station-diagnostic-mobile-renderer.component';
import { StationListItem } from '../../../api/stations/models/station-list-item.model';
import { Subarea } from '../../../api/areas/models/subarea.model';
import { TranslateService } from '@ngx-translate/core';

import ActivityViewType = RbEnums.Common.ActivityViewType;
import StationDiagnosticRunType = RbEnums.Common.StationDiagnosticRunType;
import { MapLeafletService } from '../../../common/services/map-leaflet.service';

@UntilDestroy()
@Component({
	selector: 'rb-station-diagnostic',
	templateUrl: './station-diagnostic.component.html',
	styleUrls: ['./station-diagnostic.component.scss']
})
export class StationDiagnosticComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'rb-station-diagnostic';
	@ViewChild('gridContainer') gridContainer: GridContainerComponent;
	diagnosticItems: IcmDiagnosticResult<Station>[] = [];
	currentlyRunningTestType: StationDiagnosticRunType;
	isGridDisplayable = true;
	RbEnums = RbEnums;
	ActivityViewType = ActivityViewType;
	voltageColumnDefaultValue: ColumnFilterDefaultValue;
	isDataLoading = false;
	dataLoaded = false;
	numFailed = 0;
	numPassed = 0;
	onOffDelay: number;
	resultCount = 0;
	totalResultCount = 0;
	notPassingString: string;
	isCancelled = false;
	isTestPassed = false;
	isGridDataLoaded = false;
	hasError = false;
	lastUpdated: string;
	selectedStation: StationListItem = null;
	isMobile = false;
	isDiagnosticRunning = false;
	isHistoryLoading = false;
	canSidePanelComponentBeShown = false;
	isRightMatDrawerOpen = false;
	visibleFilterChoices: any[] = [];
	stationHistory: IStationDiagnosticHistory[] = [];
	minGoodVoltage: number;
	maxGoodVoltage: number;
	checkboxComponent: CheckboxTableFilterComponent = null;
	activityView = ActivityViewType.List;
	resultFilterParams: any;
	stationDiagnosticMobileCellRenderer = StationDiagnosticMobileRendererComponent;
	StationDiagnosticRunTypes: { name: string, id: StationDiagnosticRunType }[] = [];
	
	StationDiagnosticRunType = StationDiagnosticRunType;

	@Input() stations: Station[];

	private expectedStationIds: number[] = null;
	
	private endRunningProcessObs: Subject<boolean>;
	private mapInfo: MapInfoLeaflet;
	private readonly areYouSureMsg = RbUtils.Translate.instant('SPECIAL_MSG.ON_OFF_TEST_CANCEL_IRRIGATION_PROMPT');
	private readonly areYouSureTitle = RbUtils.Translate.instant('SPECIAL_MSG.ON_OFF_TEST_CANCEL_IRRIGATION_TITLE');
	private StationDiagnosticRunTypesCatalog: {name: string, id: StationDiagnosticRunType} [] = [
		{ name: this.translate.instant('STRINGS.ICM_STATUS'), id: StationDiagnosticRunType.StatusCheck },
		{ name: this.translate.instant('STRINGS.ICM_VOLTAGE'), id: StationDiagnosticRunType.VoltageCheck },
		{ name: this.translate.instant('STRINGS.ON_OFF_TEST'), id: StationDiagnosticRunType.OnOffCheck}
	];

	private stationUtils = RbUtils.Stations;

	resultComparator = (result1, result2) => {
		return this.resultSortableValue(result1) - this.resultSortableValue(result2);
	}

	dateComparator = (d1, d2) => {
		const day1 = this.appDateAdapter.getMoment(d1);
		const day2 = this.appDateAdapter.getMoment(d2);
		return day1.isBefore(day2) ? 1 : -1;
	}

	get stationIdString() {
		if (this.stations.length > 1) {
			return this.stations.map(station => station.name).sort().join(', ')
		} else {
			return '';
		}
	}

	constructor(
		public translate: TranslateService,
		protected deviceManager: DeviceManagerService,
		protected broadcastService: BroadcastService,
		private appDateAdapter: AppDateAdapter,
		private dialog: MatDialog,
		private companyManager: CompanyManagerService,
		private manualOpsManager: ManualOpsManagerService,
		private messageBoxService: MessageBoxService,
		private readonly diagnoticLog: DiagnosticLogManagerService,
		private mapService: MapLeafletService,
		protected controllerManager: ControllerManagerService,
	) {}

	ngOnInit() {
		this.isMobile = this.deviceManager.isMobile;
		this.deviceManager.isMobileChange
				.pipe(untilDestroyed(this))
				.subscribe(isMobile => this.isMobile = isMobile);
		this.companyManager.getCompanyPreferences()
				.pipe(take(1))
				.subscribe((companyPreferences: CompanyPreferences) => {
					this.minGoodVoltage = companyPreferences.diagnosticVoltageLowRange;
					this.maxGoodVoltage = companyPreferences.diagnosticVoltageHighRange;
					this.onOffDelay = companyPreferences.diagnosticOnOffDelaySeconds;
					if (this.minGoodVoltage != null) {
						this.voltageColumnDefaultValue = new ColumnFilterDefaultValue('lessThanOrEqual', this.minGoodVoltage);
					}
				});
		this.resultFilterParams = { choicesCallback: this.getResultsFilter.bind(this) };

		if (this.stations.length === 1) {
			// RB-15044: We will notify the user if the selected station is invalid for diagnostics. 
			const station = this.stations[0];
			const invalidStation = 
				this.controllerManager.isSatelliteControllerType(station.parentSatelliteType) || 
				this.controllerManager.isSatelliteInterface(station.parentSatelliteType);
			if(invalidStation){
				this.StationDiagnosticRunTypes = null;
				var message = "";
				station.parentSatelliteType === RbEnums.Common.DeviceType.MIM_LINK ? 
					message = this.translate.instant('STRINGS.STATION_DIAGNOSTIC_UNAVAILABLE_WARNING_LINK') :
					message = this.translate.instant('STRINGS.STATION_DIAGNOSTIC_UNAVAILABLE_WARNING_SATELLITE');
				const mbi = MessageBoxInfo.create(
					this.translate.instant('STRINGS.DIAGNOSTICS'),
					message,
					RbEnums.Common.MessageBoxIcon.None,
					() => {
						this.dialog.closeAll();
					},
					null,
					RbEnums.Common.MessageBoxButtons.Ok
				);
				this.broadcastService.showMessageBox.next(mbi);
			} else {
				this.getCurrentStationHistory();

				this.StationDiagnosticRunTypes = this.StationDiagnosticRunTypesCatalog.filter(t => 
					RbConstants.Common.DiagnosticTestsPerStationType[station.parentSatelliteType].includes(t.id)
				);
				
				this.changeTestType(this.StationDiagnosticRunTypes[0].id);
			}

		} else if (this.stations.length > 1) {
			// We have multiple stations selected. We need to:
			// 1. Create a list of appropriate test options
			// 2. Know if we have multiple station types selected

			this.isGridDataLoaded = true;
			const diagnosticsAdded = {
				ICM: false,
				decoder: false
			};
			
			for (const station of this.stations) {

				// Add test options based on types of station selected
				if (RbUtils.Controllers.isICSInterface(station.parentSatelliteType) && !diagnosticsAdded.ICM) {
					this.StationDiagnosticRunTypes.push(
						...this.StationDiagnosticRunTypesCatalog.filter(t =>
							RbConstants.Common.DiagnosticTestsPerStationType[station.parentSatelliteType].includes(t.id)
						)
					);
					diagnosticsAdded.ICM = true;
				} else if (RbUtils.Controllers.isDecoderInterface(station.parentSatelliteType) && !diagnosticsAdded.decoder) {
					this.StationDiagnosticRunTypes.push(
						...this.StationDiagnosticRunTypesCatalog.filter(t =>
							RbConstants.Common.DiagnosticTestsPerStationType[station.parentSatelliteType].includes(t.id)
						)
					);
					diagnosticsAdded.decoder = true;
				}	
			}

			this.StationDiagnosticRunTypes = [... new Set(this.StationDiagnosticRunTypes)];

			this.changeTestType(this.StationDiagnosticRunTypes[0].id);

		}

		this.mapInfo = this.mapService.currentMapInfo;
		this.dataLoaded = true;
	}

	ngOnDestroy(): void { }

	addClassToRow(params) {
		if (params && params.data && params.data.isNew) {
			return 'new-station-diagnostic';
		}
		return '';
	}

	public shouldShowProgress(): boolean {
		
		return this.totalResultCount > 0
			&& this.resultCount < this.totalResultCount
			&& !this.isCancelled;
	}

	public shouldShowGrid(): boolean {
		return !this.deviceManager.isMobile || this.dataLoaded;
	}

	resultSortableValue(result): number {
		if (result === RbEnums.Common.DiagnosticFeedbackResult.Error)
			return 0;
		if (result === RbEnums.Common.DiagnosticFeedbackResult.NO_FB)
			return 1;
		if (result === RbEnums.Common.DiagnosticFeedbackResult.OK)
			return 3;
		return 4;
	}

	updateVisibleFilterChoicesForAddition(result: RbEnums.Common.DiagnosticFeedbackResult): void {
		const choice = this.getBaselineResultsFilter().find((c) => c.value === result);
		// if the choice is not defined as valid option
		if (!choice)
			return;
		const currentIndex = this.visibleFilterChoices.findIndex((c) => c.value === result);
		// if choice doesn't exist that matches result then add and sort
		if (currentIndex === -1) {
			this.visibleFilterChoices.push(choice);
			this.visibleFilterChoices.sort((a, b) => a.order - b.order);
		}
	}

	updateVisibleFilterChoicesForRemoval(result: RbEnums.Common.DiagnosticFeedbackResult): void {
		const choice = this.getBaselineResultsFilter().find((c) => c.value === result);
		// if the choice is not defined as valid option don't bother
		if (!choice)
			return;
		const currentIndex = this.visibleFilterChoices.findIndex((c) => c.value === result);
		// if no matching choice visible then exit
		if (currentIndex === -1)
			return;
		// if there are no gridItems that have the result, remove the filtering choice
		if (!this.diagnosticItems.some(item => item.result === result))
			this.visibleFilterChoices.splice(currentIndex, 1);
	}

	shouldShowFailure(): boolean {
		return this.currentlyRunningTestType === StationDiagnosticRunType.VoltageCheck;
	}

	onClose() {
		this.dialog.closeAll();
	}

	// RB-14245: This might be also called from startDiagnosticOperation() to stop the operation.
	cancelButtonClicked() {
		this.manualOpsManager.cancelDiagnostics().subscribe();
		this.isCancelled = true;
		// emit to cancel process
		this.endRunningProcessObs.next(true);
		this.endRunningProcessObs.complete();
		this.endRunningProcessObs = null;
	}

	runButtonClicked() {
		// Each test returns an array of the station Ids expected in the result.
		let testToRun: Observable<IStationDiagnosticHistory[]>;
		switch (this.currentlyRunningTestType) {
			case StationDiagnosticRunType.StatusCheck:
				testToRun = this.statusPollTest;
				break;
			case StationDiagnosticRunType.VoltageCheck:
				testToRun = this.voltageTest;
				break;
			case StationDiagnosticRunType.OnOffCheck:
				// Confirm irrigation cancellation with user before continuing.
				this.messageBoxService.showMessageBox(
					new MessageBoxInfo(this.areYouSureMsg, RbEnums.Common.MessageBoxIcon.Question, this.areYouSureTitle,
						() => {
							// Only perform the QuickCheck test if the user accepts the are-you-sure.
							this.startDiagnosticOperation(this.onOffTest);
						},
						null,
						RbEnums.Common.MessageBoxButtons.YesNo));
				
				break;
		}
		if (testToRun != null) {
			this.startDiagnosticOperation(testToRun);
		}
	}

	updateTimeCellRenderer(item: any) {
		return this.appDateAdapter.formatDateTime(new Date(item.value));
	}

	onGridCellClick(event: any) {
		this.selectedStation = event.data.station;
		this.canSidePanelComponentBeShown = true;
	}

	canDeactivate(): Observable<boolean> {
		return of(true);
	}

	rightPaneOpened() {
		this.isRightMatDrawerOpen = true;
	}

	rightPaneClosed() {
		this.isRightMatDrawerOpen = false;
	}

	onRightSidebarComplete(event: { savedData: boolean }) {
		this.gridContainer.unSelectAll();
		this.canSidePanelComponentBeShown = false;
	}

	changeTestType(newTestType: StationDiagnosticRunType) {
		this.currentlyRunningTestType = newTestType;
		if (this.stations.length == 1 && newTestType !== StationDiagnosticRunType.OnOffCheck) {
			this.getCurrentStationHistory();
		}
	}

	getCurrentStationHistory() {
		this.resetTableData();
		this.isGridDataLoaded = false;
		this.dataLoaded = true;
		this.isHistoryLoading = true;

		this.diagnoticLog.getStationDiagnosticLogHistory(this.stations[0].id, this.currentlyRunningTestType)
			.pipe(take(1), untilDestroyed(this), finalize(() => {
				this.isHistoryLoading = false;
				setTimeout(() => {
					this.isGridDataLoaded = true; // To allow grid to reload columns
				}, 200);
			})).subscribe(history => {
				this.stationHistory = history || [];
				this.totalResultCount = this.stationHistory.length;
				for(let i = 0; i < this.totalResultCount; i++){
					this.stationHistory[i].groupNumber = this.stations[0].groupNumber;
				}
				this.addDiagnosticHistory();
			});
	}

	// ================================================================================================================================================
	// Getters/Setters
	// ================================================================================================================================================
	get isInProgress(): boolean {
		return this.isDiagnosticRunning || this.isHistoryLoading;
	}

	get selectedStationIds(): number[] {
		return this.selectedStation
			? [this.selectedStation.id]
			: [];
	}

	get isStatusCheck() {
		return this.currentlyRunningTestType === StationDiagnosticRunType.StatusCheck;
	}

	get isVoltageCheck() {
		return this.currentlyRunningTestType === StationDiagnosticRunType.VoltageCheck;
	}

	get isOnOffCheck() {
		return this.currentlyRunningTestType === StationDiagnosticRunType.OnOffCheck;
	}

	get unableToTest(): boolean {
		return this.isDiagnosticRunning || this.isStationSuspended || (this.isStationNotConnected && this.isStatusCheck);
	}

	get isStationSuspended() {
		return !this.stations.length && this.stations[0].suspended;
	}

	get isStationNotConnected() {
		return !this.stations.length && !this.stations[0].suspended && !this.stations[0].isConnected;
	}

	get isStationNotAvailable(): boolean {
		return !this.stations.length && !this.stations[0].isConnected;
	}

	get resultCellRender() {
		return StationDiagnosticCellRendererComponent;
	}

	get shouldNotConnectedStationWarningShown(): boolean {

		return this.isStationNotConnected && this.isStatusCheck;
	}

	get isVoltageTest() {
		return this.currentlyRunningTestType === StationDiagnosticRunType.VoltageCheck;
	}

	private getHistoryItemResult(historyItem: IStationDiagnosticHistory): RbEnums.Common.DiagnosticFeedbackResult {
		switch (this.currentlyRunningTestType) {
			case StationDiagnosticRunType.StatusCheck:
				return historyItem.pollResult;
			case StationDiagnosticRunType.VoltageCheck:
				const voltagePollResult = new IcVoltagePollData(historyItem);
				voltagePollResult.result = historyItem.voltage;
				voltagePollResult.feedback = this.getVoltageHistoryFeedbackResult(historyItem.feedback);
				return this.getVoltageResult(voltagePollResult);
			default:
				return RbEnums.Common.DiagnosticFeedbackResult.Error;
		}
	}

	private getVoltageHistoryFeedbackResult(historicalFeedback) {
		switch (historicalFeedback) {
			case RbEnums.Common.DiagnosticFeedbackResult.OK: {
				return 'OK';
			}
			case RbEnums.Common.DiagnosticFeedbackResult.NO_FB: {
				return 'NO_FB';
			}
			default: {
				return 'Error';
			}
		}
	}

	/**
	 * Perform an ICM status poll test, returning an Observable with the list of station Ids to be tested.
	 * @returns Observable<number[]>
	 */
	private get statusPollTest(): Observable<any[]> {
		return this.manualOpsManager.runDiagnosticsStatusCheckByStationList(this.stations.map(s => s.id));	
	}
	/**
	 * Peform an ICM voltage test returning an Observable with the list of expected station Ids to be tested.
	 * @returns Observable<number[]>
	 */
	private get voltageTest(): Observable<any[]> {
		return this.manualOpsManager.runDiagnosticsVoltageCheckByStationList(this.stations.map(s => s.id));
	}

	/**
	 * Peform an On/Off test returning an Observable with the list of expected station Ids to be tested.
	 * @returns Observable<number[]>
	 */
	private get onOffTest(): Observable<any[]> {
		return this.manualOpsManager.runDecoderOnOff(this.stations.map(s => s.id));
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================
	/**
	 * Perform the indicated test to run, which returns an Observable of the stations to be tested.
	 * @param testToRun - Observable<number[]> containing the list of Ids to be tested and indicating therefore the
	 * number of items to be tested.
	 */
	private startDiagnosticOperation(testToRun: Observable<any[]>) {

		// start to watch the update from signalR for diagnostic log
		this.checkingDiagnosticLogChange();

		this.isDiagnosticRunning = true;

		if (this.stations.length) {
			// RB-8999: Moved clear of diagnostic results until the next test actually starts. If the user declines
			// to stop irrigation for Quick Check, there's no reason to clear previous diagnostic results, for example.
			this.diagnosticItems = [];
			this.dataLoaded = true;
			this.lastUpdated = this.appDateAdapter.formatDateTime(new Date());
			this.resultCount = 0;
			this.totalResultCount = 0;
			this.numPassed = 0;
			this.numFailed = 0;
			this.isGridDataLoaded = false;
			this.isCancelled = true;
			this.visibleFilterChoices = [];

			/**
			 * We now get back the list of station Ids that will be run, rather than the count, for status poll, voltage
			 * poll and quick check. Save that and set the total result count based on how many were found.
			 */
			testToRun.subscribe(v => {
				this.expectedStationIds = v;
				this.totalResultCount = v.length;
				// RB-14245: If we get 0 stations, it means there's no runnable ICM stations for diagnostic, therefore we cancel it and inform the user.
				if (v.length === 0) {
					this.cancelButtonClicked();
					const mbi = MessageBoxInfo.create("", this.translate.instant("STRINGS.STATION_DIAGNOSTIC_NO_ICM_WARNING"),
						RbEnums.Common.MessageBoxIcon.Warning);
					this.messageBoxService.showMessageBox(mbi);
				}
			});
			setTimeout(() => this.isGridDataLoaded = true, 200); // To allow grid to reload columns
		} else {
			// RB-8999: Moved clear of diagnostic results until the next test actually starts. If the user declines
			// to stop irrigation for Quick Check, there's no reason to clear previous diagnostic results, for example.
			if (this.diagnosticItems.length > 0 ) {
				this.diagnosticItems.forEach(item => {
					// unset new item to be an old one
					if (item['isNew']) {
						item['isNew'] = false;
					}
				});
			}
			
			/**
			 * We now get back the list of station Ids that will be run, rather than the count, for status poll, voltage
			 * poll and quick check. Save that and set the total result count based on how many were found.
			 */
			testToRun.subscribe();
		}
	}

	private addDiagnosticHistory() {
		// show the last run datetime
		// API returns the list which has been ordered by time already
		if (this.stationHistory.length > 0) {
			const [lastRun] = this.stationHistory;
			const date = new Date(lastRun.updateTime);
			this.lastUpdated = RbUtils.Date.transform(date, this.appDateAdapter.dateTimeFormat);
		}

		this.stationHistory.forEach(his => {
			const result = this.getHistoryItemResult(his);
			const item = this.buildGridItem(this.stations[0], result, result === RbEnums.Common.DiagnosticFeedbackResult.OK );
				const newDiagnosticitem = new IcmDiagnosticResult(item);
				const updateTime = new Date(his.updateTime);
				newDiagnosticitem.updateTime = RbUtils.Date.transform(updateTime, this.appDateAdapter.dateTimeFormat);
				if (this.isVoltageTest) {
					newDiagnosticitem.voltage = his.voltage;
					newDiagnosticitem.wirePath = his.groupNumber;
				}
				this.updateDataAndGrid({...newDiagnosticitem, testType: this.currentlyRunningTestType}, true);
		});
	}

	private checkingDiagnosticLogChange() {
		this.endRunningProcessObs = new Subject();
		this.broadcastService.diagnosticDataReceived
		.pipe(
			untilDestroyed(this),
			takeUntil(this.endRunningProcessObs),
			finalize(() => {
				this.isDiagnosticRunning = false;
			}
		))
		.subscribe((data: any) => {
			if ((this.currentlyRunningTestType === StationDiagnosticRunType.StatusCheck &&
				data.cdType !== RbEnums.Common.DiagnosticDataType.ICShortAddress) ||
				(this.currentlyRunningTestType === StationDiagnosticRunType.VoltageCheck &&
					data.cdType !== RbEnums.Common.DiagnosticDataType.ICVoltage)) {
				return;
			}
			this.lastUpdated = RbUtils.Date.transform(new Date(), this.appDateAdapter.dateTimeFormat);
			switch (this.currentlyRunningTestType) {
				case StationDiagnosticRunType.StatusCheck:
					this.handleStatusCheckResult(data);
					break;
				case StationDiagnosticRunType.VoltageCheck:
					this.handleVoltageCheckResult(data);
					break;
				case StationDiagnosticRunType.OnOffCheck:
					this.handleOnOffTestResult(data);
					break;
			}
		});
	}

	private getResultsFilter(checkboxComponent: CheckboxTableFilterComponent): any {
		this.checkboxComponent = checkboxComponent;
		return this.visibleFilterChoices;
	}

	private getBaselineResultsFilter(): any[] {
		return this.shouldShowFailure()
			? [
				{ order: 1, text: this.translate.instant('STRINGS.PASSED'), value: RbEnums.Common.DiagnosticFeedbackResult.OK },
				{ order: 2, text: this.translate.instant('STRINGS.FAILED'), value: RbEnums.Common.DiagnosticFeedbackResult.Error },
				{ order: 3, text: this.translate.instant('STRINGS.NO_FEEDBACK_FILTER'), value: RbEnums.Common.DiagnosticFeedbackResult.NO_FB }
			]
			: [
				{ order: 1, text: this.translate.instant('STRINGS.PASSED'), value: RbEnums.Common.DiagnosticFeedbackResult.OK },
				{ order: 2, text: this.translate.instant('STRINGS.NO_FEEDBACK_FILTER'), value: RbEnums.Common.DiagnosticFeedbackResult.NO_FB }
			];
	}

	private adjustCounts(isSuccess: boolean, value: number) {
		this.resultCount += value;
		if (isSuccess)
			this.numPassed += value;
		else
			this.numFailed += value;

		if (this.resultCount >= this.totalResultCount && this.endRunningProcessObs) {
			this.endRunningProcessObs.next(true);
			this.endRunningProcessObs.complete();
			this.endRunningProcessObs = null;
		}
	}

	private updateDataAndGrid(gridItem: any, isHistory = false) {
		// // apply sorting at the top of the function to keep the latest item at the top
		// this.applyHistorySort();
		// add new item to local data array, put the signalR data to the top
		if (!isHistory && !!gridItem) {
			gridItem.isNew = true;
		}
		this.diagnosticItems = isHistory ? [...this.diagnosticItems, gridItem] : [gridItem, ...this.diagnosticItems];
		this.updateVisibleFilterChoicesForAddition(gridItem.result);

		this.applyDefaultSort();

		// update grid, if there's no user specified sort, the addIndex will maintain the default sort desired
		if (this.gridContainer != null) { this.gridContainer.updateRowData({ add: [gridItem], addIndex: this.getDataIndex(gridItem) }); }
		// the ag-grid documentation indicates that onNewRowsLoaded should be called when grid is updated however it does not
		// and needs to be called manually after
		if (this.checkboxComponent) { this.checkboxComponent.onNewRowsLoaded(); }
		this.adjustCounts(gridItem.isSuccess, 1);
	}

	private getDataIndex(gridItem: any): number {
		return this.diagnosticItems.findIndex(gi => gridItem === gi);
	}

	private handleVoltageCheckResult(data: IcVoltagePollData) {
		console.log('handleVoltageResult: ', data);
		// const diagResult = this.getDiagnosticFeedbackResult(data.feedback, false);
		const result = this.getVoltageResult(data);
		// need to convert from general diagnostic to station diagnostic cdType
		const item = this.buildGridItem(
			this.stations.find(s => s.id === data.stationId),
			result,
			result === RbEnums.Common.DiagnosticFeedbackResult.OK
		) as IcmDiagnosticResult;

		item.wirePath = item.station.groupNumber;
		item.voltage = data.result;

		if (data.feedback === 'OK') {
			item.feedback = 1;
		} else if (data.feedback === 'NO_FB') {
			item.feedback = 0;
		} else {
			item.feedback = 2;
		}
		const newDiagnosticitem = new IcmDiagnosticResult(item);
		if (!newDiagnosticitem.updateTime) {
			newDiagnosticitem.updateTime = RbUtils.Date.transform(new Date(), this.appDateAdapter.dateTimeFormat);
		}
		this.updateDataAndGrid({ ...newDiagnosticitem, testType: this.currentlyRunningTestType });
	}

	private getVoltageResult(data: IcVoltagePollData) {
		const result = this.isVoltageResultOk(data.result)
		? RbEnums.Common.DiagnosticFeedbackResult.OK
		: RbEnums.Common.DiagnosticFeedbackResult.Error;
		return result;
	}

	private isVoltageResultOk(result: number): boolean {
		return (this.minGoodVoltage == null || result > this.minGoodVoltage)
			&& (this.maxGoodVoltage == null || result < this.maxGoodVoltage);
	}

	private getDiagnosticFeedbackResult(value: any, reportErrorAsNoFeedback: boolean): RbEnums.Common.DiagnosticFeedbackResult {
		value = reportErrorAsNoFeedback && value.toLowerCase() === 'error' ? 'NO_FB' : value;
		const resultString: keyof typeof RbEnums.Common.DiagnosticFeedbackResult = value;
		return RbEnums.Common.DiagnosticFeedbackResult[resultString];
	}

	private buildGridItem(station: Station, result: any, isSuccess: boolean, data?: DiagnosticData): IcmDiagnosticResult | DecoderStationDiagnosticResult {
		const areas = this.mapInfo?.areas.filter(a => station.stationArea.some(sa => sa.areaId === a.id));
		const hole = this.mapInfo?.holes.find(h => station.stationArea.some(sa => sa.areaId === h.id) && h.level === 2 && h.isExclusive);
		const area = areas?.find(sa => sa.level === 3 && sa.isExclusive);
		const stationArea = station.stationArea.find(sa => sa.areaId === area?.id);
		let subArea: Subarea = null;
		areas?.forEach(a => a.subArea.forEach(sa => { if (sa.id === station.subAreaId) { subArea = sa; } }));

		let resultObject: IcmDiagnosticResult | DecoderStationDiagnosticResult;

		switch (this.currentlyRunningTestType) {
			case StationDiagnosticRunType.OnOffCheck:
				resultObject = new DecoderStationDiagnosticResult({
					station: this.stations.find(s => s.id === station.id),
					cdType: data.cdType,
					interface: this.mapInfo?.controllers.find(i => i.id === station.satelliteId).name,
					location: hole == null || area == null || stationArea == null
						? this.translate.instant('STRINGS.UNKNOWN')
						: (hole.number + area.shortName + stationArea.number),
					hole: hole == null ? this.translate.instant('STRINGS.UNKNOWN') : hole.name,
					area: area == null ? this.translate.instant('STRINGS.UNKNOWN') : area.name,
					subArea: subArea == null ? this.translate.instant('STRINGS.UNKNOWN') : subArea.name,

					// Using the filter's course name is fine when you're selecting stations by course but when selecting
					// by wire path/interface, you must use the course of the station's hole/area or the answer will be wrong.
					// Even though there's no wire path/interface diagnostic for decoders, this will get the course correctly always for other cases.
					course: RbUtils.Stations.getGolfStationSiteName(this.mapInfo?.siteManager, hole, area),

					// Set the currents received. Note that the incoming data is expressed in Amps so, since we want mA, we have to multiply.
					atRest: data[RbEnums.Common.DecoderQuickCheckFields.atRest] * 1000,
					inRush: data[RbEnums.Common.DecoderQuickCheckFields.inRush] * 1000,
					hold: data[RbEnums.Common.DecoderQuickCheckFields.hold] * 1000,

					result: result,
					isSuccess: isSuccess
				});
				break;

			// Same result object for status and voltage check
			case StationDiagnosticRunType.StatusCheck:
			case StationDiagnosticRunType.VoltageCheck:
				resultObject = new IcmDiagnosticResult({
					station: new StationListItem(station),
					interface: this.mapInfo?.controllers.find(c => c.id === station.satelliteId)?.name,
					location: hole == null || area == null || stationArea == null
						? this.translate.instant('STRINGS.UNKNOWN')
						: (hole?.number + area?.shortName + stationArea.number),
					hole: hole == null
						? this.translate.instant('STRINGS.UNKNOWN')
						: hole?.number,
					area: area == null ? this.translate.instant('STRINGS.UNKNOWN') : area?.name,
					subArea: subArea == null ? this.translate.instant('STRINGS.UNKNOWN') : subArea.name,
					course: RbUtils.Stations.getGolfStationSiteName(this.mapInfo?.siteManager, hole, area),

					result,
					isSuccess
				});
		}

		return resultObject;

	}

	// =========================================================================================================================================================
	// Diagnostic result handling
	// =========================================================================================================================================================
	private handleStatusCheckResult(data: IcShortAddressPollData) {
		console.log('handleStatusCheckResult: ', data);

		for(let i = this.expectedStationIds.length - 1; i >= 0 ; i--) {
			if (data.resultByStationId.hasOwnProperty(this.expectedStationIds[i])) {
				const result = this.getDiagnosticFeedbackResult(data.resultByStationId[this.expectedStationIds[i]], true);
				// need to convert from general diagnostic to station diagnostic cdType
				const item = this.buildGridItem(
					this.stations.find(st => st.id === this.expectedStationIds[i]),
					result,
					result === RbEnums.Common.DiagnosticFeedbackResult.OK
				);
				const newDiagnosticitem = new IcmDiagnosticResult(item);
				if (!newDiagnosticitem.updateTime) {
					newDiagnosticitem.updateTime = RbUtils.Date.transform(new Date(), this.appDateAdapter.dateTimeFormat);
				}
				this.updateDataAndGrid(newDiagnosticitem);
				this.expectedStationIds.splice(i, 1);
			}
		}
	}

	private handleOnOffTestResult(data: QuickCheckData) {
		
		const result = data.result === RbEnums.Common.DiagnosticQuickCheckResult.Pass
			? RbEnums.Common.DiagnosticFeedbackResult.OK
			: RbEnums.Common.DiagnosticFeedbackResult.NO_FB;
		const isSuccess: boolean = result === RbEnums.Common.DiagnosticFeedbackResult.OK;

		this.updateDataAndGrid(this.buildGridItem(this.stations.find(st => st.id === data.stationId), result, isSuccess, data));
	}

	private resetTableData() {
		this.lastUpdated = null;
		this.stationHistory = [];
		this.diagnosticItems = [];
		this.resultCount = 0;
		this.totalResultCount = 0;
		this.numPassed = 0;
		this.numFailed = 0;
		this.visibleFilterChoices = [];
	}

	private applyDefaultSort(): void {
		this.diagnosticItems
			.sort((a, b) => this.stationUtils.getStationNumberInArea(a.station) - this.stationUtils.getStationNumberInArea(b.station))
			.sort((a, b) => this.stationUtils.getAreaNumber(a.station) - this.stationUtils.getAreaNumber(b.station))
			.sort((a, b) => this.stationUtils.getHoleNumber(a.station) - this.stationUtils.getHoleNumber(b.station))
			.sort((a, b) => this.resultSortableValue(a.result) - this.resultSortableValue(b.result));
	}

}

import { Component } from '@angular/core';

@Component({
	selector: 'rb-number-formatter',
	templateUrl: './number-formatter.component.html',
	styleUrls: ['./number-formatter.component.scss']
})
export class NumberFormatterComponent {
	params: any;

	agInit(params: any): void {
		this.params = params;
	}

}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { AlertFilter } from './models/alert-filter.model';
import { ControllerManagerService } from '../../../api/controllers/controller-manager.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { FilterChangeModel } from './models/filter-change.model';
import { MatDialog } from '@angular/material/dialog';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SelectListItem } from '../../../api/_common/models/select-list-item.model';
import { TranslateService } from '@ngx-translate/core';

import ActivityViewType = RbEnums.Common.ActivityViewType;
import { AuthManagerService } from '../../../api/auth/auth-manager-service';

@UntilDestroy()
@Component({
	selector: 'rb-alert-filter',
	templateUrl: './alert-filter.component.html',
	styleUrls: ['./alert-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertFilterComponent implements OnInit, OnDestroy {
	@ViewChild('alertWarningModal', { static: true }) alertWarningModal;
	@ViewChild('alertsFilterDropdown') alertsFilterDropdown;

	ActivityViewType = ActivityViewType;
	isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);

	@Input() dateRangeDropdown = '';
	@Input() filterLabel = '';
	@Input() hideDateRange = true;
	@Input() hideDates = false;
	@Input() useStartOfDayForDateRange = true;
	@Input() hideTimeForDateRange = true;
	@Input() showViewOptions = false;

	private _filterMask = 0;
	@Input() set filterMask(value: number) {
		this._filterMask = value;
		this.setSelectedEventLogTypes();
	}

	get filterMask(): number {
		return this._filterMask;
	}

	/**
	 * @summary Label for the activity types selection drop-down (Programs, Schedules, Stations).
	 */
	@Input() typesLabel = '';

	/**
	 * @summary hideAlertsFilterDropdown is synonymous with 'in non-Custom mode'. In this mode, the filter selection is
	 * hidden, the date range is 1 Day, 3 Days, 1 Week, etc. as selected from the date range dropdown. When
	 *  hideAlertsFilterDropdown is false, we are in Custom mode, show the filter selection, etc.
	 */
		//
	@Input() hideAlertsFilterDropdown = true;

	/**
	 * @summary Set disableAlertsFilter to never show the Filter drop down list. Otherwise, it will be shown
	 * when the date range is set to Custom by the user.
	 */
	@Input() disableAlertsFilter = false;

	private _hideActivityTypeDropdown = true;
	/**
	 * @summary hideActivityTypeDropdown to keep selection of "Programs", "Schedules", "Stations" from being
	 * visible. set can be passed "true", "false", or a boolean value. get always returns the boolean value.
	 */
	@Input() set hideActivityTypeDropdown(hide: 'true' | 'false' | boolean) {
		// If string, interpret as boolean. If boolean, copy to _hideActivityTypeDropdown.
		if (hide === 'true') {
			this._hideActivityTypeDropdown = true;
		} else if (hide === 'false') {
			this._hideActivityTypeDropdown = false;
		} else {
			this._hideActivityTypeDropdown = hide;
		}
	}

	get hideActivityTypeDropdown(): 'true' | 'false' | boolean { return this._hideActivityTypeDropdown; }

	private _selectedDateRange = RbConstants.Form.DATE_RANGE.oneWeek;
	@Input() set selectedDateRange(value: number) {
		this._selectedDateRange = value;
		this.selectedDateRangeChange.next(value);
	}

	get selectedDateRange(): number { return this._selectedDateRange; }

	private _startDate: Date;
	@Input() set startDate(value: Date) {
		this._startDate = value;
		this.startDateChange.next(value);
	}

	get startDate(): Date { return this._startDate; }

	private _endDate: Date;
	@Input() set endDate(value: Date) {
		this._endDate = value;
		this.endDateChange.next(value);
	}

	private _activityView = ActivityViewType.List;
	@Input() set activityView(value: ActivityViewType) {
		this._activityView = value;
		this.activityViewChange.next(value);
	}

	get activityView(): ActivityViewType {
		return this._activityView;
	}

	get endDate(): Date { return this._endDate; }

	// Types of activities that can be selected for display (Programs, Schedules, Stations).
	@Input() activityTypes: SelectListItem[];

	// Single selection for Programs, Schedules, Stations. This is the currently-selected SelectListItem.value.
	@Input() selectedActivityType: RbEnums.Common.ActivityType;
	@Input() shouldShowAcknowledgeButton = false;
	@Input() shouldShowAckAllButton = false;
	@Input() shouldShowCSVButton = false;
	@Input() isTriPaneChild = false;								// If component is hosted in reduced with TriPane Body (w/left sidebar displayed)

	@Output() filterChange = new EventEmitter<FilterChangeModel>();
	@Output() ackButtonClicked = new EventEmitter();
	@Output() ackAllButtonClicked = new EventEmitter();
	@Output() dateRangeChange = new EventEmitter();
	@Output() endDateChange = new EventEmitter();			// Likely redundant with the addition of selectedDateRangeChange, but used by Activity | Alerts tab
	@Output() startDateChange = new EventEmitter();			// Likely redundant with the addition of selectedDateRangeChange, but used by Activity | Alerts tab
	@Output() selectedDateRangeChange = new EventEmitter();
	@Output() activityViewChange = new EventEmitter();

	/**
	 * @event filterSelectChange is fired when the selected filters type list is changed by the user. The parameter is
	 * the list of selected filters after the change (value: number, display: string).
	 */
	@Output() filterSelectChange = new EventEmitter();

	/**
	 * @event selectedActivityTypeChange is fired when the selected activity type is changed (Programs, Schedules,
	 * Stations). The parameter is the list of selected types after the change (value: number, display: string).
	 */
	@Output() selectedActivityTypeChange = new EventEmitter();

	@Output() exportCsv = new EventEmitter();

	private _eventLogTypes: SelectListItem[] = [];

	private _selectedEventLogTypes: SelectListItem[];
	set selectedEventLogTypes(value: SelectListItem[]) {
		this._selectedEventLogTypes = value;
		this.selectedEventLogTypeIds = this.selectedEventLogTypes.map(t => t.value);
	}

	get selectedEventLogTypes(): SelectListItem[] {
		return this._selectedEventLogTypes;
	}

	ACK_BTN_LBL_SHORTEN_WIDTH = 1050;
	TRI_PANE_CHILD_ACK_BTN_LBL_SHORTEN_WIDTH = 1500;

	alertAckValidationMessage = '';
	selectedEventLogTypeIds: number[];
	ackButtonLabel = '';
	ackAllButtonLabel = '';
	ackButtonLabelTransitionWidth: number;
	controllerId: number;

	// =========================================================================================================================================================
	// C'tor and Init
	// =========================================================================================================================================================

	constructor(private controllerManager: ControllerManagerService,
				private deviceManager: DeviceManagerService,
				private dialog: MatDialog,
				private route: ActivatedRoute,
				private translate: TranslateService,
				protected authManager: AuthManagerService,
	) { }

	ngOnInit(): void {
		this.hideAlertsFilterDropdown = this.disableAlertsFilter;
		this.ackButtonLabelTransitionWidth = !this.isTriPaneChild ? this.ACK_BTN_LBL_SHORTEN_WIDTH : this.TRI_PANE_CHILD_ACK_BTN_LBL_SHORTEN_WIDTH;
		this.controllerId = +this.route.snapshot.queryParams.controllerId;

		// Monitor Application Window resizing and take action as desired.
		this.deviceManager.windowResize
			.pipe(untilDestroyed(this))
			.subscribe(() => this.setAckButtonLabels());

		this.setAckButtonLabels();
	}

	ngOnDestroy() {
		/** Required by untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	handleChange(param, value) {
		if (value === undefined) return;

		try {
			switch (param) {
				case 'alarmTypes':
					this.selectedEventLogTypes = value;
					return;
				case 'startDate':
					this.startDate = value;
					return;
				case 'endDate':
					this.endDate = value;
					return;
				case 'dateRange':
					this.selectedDateRange = value.rangeValue;
					if (value.rangeValue === RbConstants.Form.DATE_RANGE.alarm) {
						this.hideAlertsFilterDropdown = true;
						this.dateRangeChange.emit(new AlertFilter(RbConstants.Form.DATE_RANGE.alarm));
						return;
					} else {
						this._startDate = value.startDate;			// We set the private variable so we don't fire a redundant event.
						this._endDate = value.endDate;				// We set the private variable so we don't fire a redundant event.
					}

					this.hideAlertsFilterDropdown = this.disableAlertsFilter;
					this.dateRangeChange.emit(new AlertFilter(this.selectedDateRange, this.startDate, this.endDate));
					return;
			}
		}
		finally {
			this.emitFilterChangeEvent();
		}
	}

	AckButton() {
		this.alertAckValidationMessage = '';

		if (!(this.selectedDateRange === RbConstants.Form.DATE_RANGE.alarm)) {

			if (!this.hideDateRange && (!this.startDate || !this.endDate || this.startDate > this.endDate)) {
				this.alertAckValidationMessage += this.translate.instant('VALIDATION.INVALID_DATE_RANGE');
			}
		}
		if (this.alertAckValidationMessage.length > 0) {
			this.dialog.open(this.alertWarningModal);
		} else {
			this.handleAck();
		}
	}

	handleAck() {
		const dateRange = new AlertFilter(this.selectedDateRange);
		if (!this.hideDateRange) {
			dateRange.startDate = this.startDate;
			dateRange.endDate = this.endDate;
		}
		this.ackButtonClicked.emit(dateRange);
	}

	/**
	 * RB-8010: Handler for the Acknowledge All button which allows the user to ack everything with a single click.
	 * This was added primarily to handle the case of tens of thousands golf messages added during station programming
	 * before we limited the number of duplicate errors like that.
	 */
	handleAckAll() {
		this.controllerManager.doIfNotDemoModeController(this.controllerId, this.doAckAll.bind(this));
	}

	onExportCSV() {
		this.exportCsv.emit();
	}

	onTypeSelectChanged(event) {
		this.selectedActivityTypeChange.emit(event);
		this.emitFilterChangeEvent();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private doAckAll() {
		const dateRange = new AlertFilter(this.selectedDateRange);
		if (!this.hideDateRange) {
			dateRange.startDate = this.startDate;
			dateRange.endDate = this.endDate;
		}
		this.ackAllButtonClicked.emit(dateRange);
	}

	private setSelectedEventLogTypes() {
		// eslint-disable-next-line no-bitwise
		this.selectedEventLogTypes = this.eventLogTypes.filter(t => (t.value & this.filterMask) !== 0);
	}

	private get eventLogTypes(): SelectListItem[] {
		if (this._eventLogTypes.length > 0) return this._eventLogTypes;
		const keys = Object.keys(RbEnums.Common.EventLogType)
						.filter(key => !isNaN(Number(RbEnums.Common.EventLogType[key])) &&
						(!this.isGolfSite ? (RbEnums.Common.EventLogType[key] !== RbEnums.Common.EventLogType.AcknowledgedAlarmsAndWarnings)
						: (RbEnums.Common.EventLogType[key] !== RbEnums.Common.EventLogType.AcknowledgedAlarms &&
						RbEnums.Common.EventLogType[key] !== RbEnums.Common.EventLogType.AcknowledgedWarnings)));
		keys.forEach(key => {
			this._eventLogTypes.push(new SelectListItem({
				name: RbUtils.Translate.instant(`STRINGS.${key.toUpperCase()}`),
				value: RbEnums.Common.EventLogType[key]
			}));
		});

		return this._eventLogTypes;
	}

	private emitFilterChangeEvent() {
		this.alertAckValidationMessage = '';
		if (!this.hideDateRange && this.startDate && this.endDate && this.startDate > this.endDate) {
			this.alertAckValidationMessage += this.translate.instant('VALIDATION.INVALID_DATE_RANGE');
		}
		if (this.alertAckValidationMessage.length > 0) {
			this.dialog.open(this.alertWarningModal);
		} else {
			this.filterChange.emit(
				new FilterChangeModel(this.selectedDateRange, this.startDate, this.endDate, this.selectedActivityType, this.selectedEventLogTypeIds)
			);
		}
	}

	private setAckButtonLabels() {
		this.ackButtonLabel = window.innerWidth > this.ackButtonLabelTransitionWidth ? 'STRINGS.ACKNOWLEDGE_UPPERCASE' : 'STRINGS.ACK_UPPERCASE';
		this.ackAllButtonLabel = window.innerWidth > this.ackButtonLabelTransitionWidth ? 'STRINGS.ACKNOWLEDGE_ALL_UPPERCASE' : 'STRINGS.ACK_ALL_UPPERCASE';
		this._eventLogTypes.forEach(item => {
			switch (item.value) {
				case RbEnums.Common.EventLogType.AcknowledgedAlarms:
					item.name = window.innerWidth > this.ackButtonLabelTransitionWidth ?
						RbUtils.Translate.instant('STRINGS.ACKNOWLEDGEDALARMS') : RbUtils.Translate.instant('STRINGS.ACK_ALARMS');
					break;
				case RbEnums.Common.EventLogType.AcknowledgedWarnings:
					item.name = window.innerWidth > this.ackButtonLabelTransitionWidth ?
						RbUtils.Translate.instant('STRINGS.ACKNOWLEDGEDWARNINGS') : RbUtils.Translate.instant('STRINGS.ACK_WARNINGS');
					break;
				default:
					break;
			}
		});
	}

}

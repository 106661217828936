import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class DryRunStatus {

	constructor(json: DryRunStatus) {
		Object.assign(this, json);
	}

	progress: number;
	reasonCode: RbEnums.SignalR.DryRunFailureReasonCode;
	dryRunId: number;
	dryStatus: RbEnums.SignalR.DryrunChangeType;
	dryType: RbEnums.Common.DryRunType;
	dryRunUUID: string;
}

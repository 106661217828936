import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { filter, map, take, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CompanyApiService } from './company-api.service';
import { CompanyPreferences } from './models/company-preferences.model';
import { CompanyStatus } from './models/company-status.model';
import { CompanyStatusChange } from '../signalR/company-status-change.model';
import { EventLogEntry } from '../event-logs/models/event-log-entry.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { SystemChangeLogEntry } from './models/system-change-log-entry.model';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class CompanyManagerService extends ServiceManagerBase implements OnDestroy {

	// Subjects
	logsCountChange = new EventEmitter();
	companyStatusChange = new EventEmitter<CompanyStatus>();
	connectedControllersCountChange = new EventEmitter<number>();
	companyPreferencesChange = new EventEmitter<CompanyPreferences>();
	companyChange = new Subject<void>();

	// Cached objects (non-expiring)
	_companyStatus: CompanyStatus;
	_companyPreferences: CompanyPreferences;

	isProgramGroupUpdated = true;
	isDryRunUpdated = true;
	isScheduleUpdated = true;
	prevSystemModeOn = true;

	// =========================================================================================================================================================
	// C'tor and Destroy
	// =========================================================================================================================================================

	constructor(protected broadcastService: BroadcastService,
				private companyApiService: CompanyApiService
	) {
		super(broadcastService);

		this.broadcastService.collectionChange
			.pipe(
				untilDestroyed(this),
				filter(collection => collection instanceof Array && collection[0] instanceof EventLogEntry)
			)
			.subscribe(() => this.getCompanyStatusCore(true));

	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */

		super.ngOnDestroy();
	}

	private resetUpdatedFlags(systemMode:  RbEnums.Common.SystemMode) {
		const systemModeOn = systemMode === RbEnums.Common.SystemMode.Auto;
		if (this.prevSystemModeOn !== systemModeOn) {
			this.isProgramGroupUpdated = false;
			this.isDryRunUpdated = false;
			this.isScheduleUpdated = false;
		}
		this.prevSystemModeOn = systemModeOn;
	}
	// =========================================================================================================================================================
	// Base class overrides
	// =========================================================================================================================================================

	protected clearCache() {
		this.companyApiService.clearCache();
		this._companyStatus = null;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	updateSystemMode(type: RbEnums.Common.IrrigationEngineCommandType) {
		// RB-8623: _companyPreferences is null immediately after the cache is cleared. Make sure we don't
		// dereference through it.
		if (!this._companyPreferences) return;
		switch (type) {
			case RbEnums.Common.IrrigationEngineCommandType.SystemAuto:
			case RbEnums.Common.IrrigationEngineCommandType.SystemResume:
				this._companyPreferences.systemMode = RbEnums.Common.SystemMode.Auto;
				break;
			case RbEnums.Common.IrrigationEngineCommandType.SystemOff:
				// RB-8533: SystemShutDown is similar to CancelAll. It does not change the operating mode, just cancels
				// stations: case RbEnums.Common.IrrigationEngineCommandType.SystemShutDown:
				this._companyPreferences.systemMode = RbEnums.Common.SystemMode.Off;
				break;
			case RbEnums.Common.IrrigationEngineCommandType.SystemPause:
				this._companyPreferences.systemMode = RbEnums.Common.SystemMode.Paused;
				break;
		}
		this.resetUpdatedFlags(this._companyPreferences.systemMode);
	}

	getCompanyName(): Observable<string> {
		return this.companyApiService.getCompanyName();
	}

	getCompanyPreferences(): Observable<CompanyPreferences> {
		return this.companyApiService.getCompanyPreferences().pipe(map(result => {
			this._companyPreferences = result.value;
			return result.value;
		}));
	}

	getCompanyStatusCore(bypassCache = false): Observable<CompanyStatus> {
		if (!bypassCache && this._companyStatus && !this._companyStatus.isExpired) {
			return of(this._companyStatus);
		}

		return this.companyApiService.getCompanyStatusCore()
			.pipe(tap((companyStatus: CompanyStatus) => {
				this._companyStatus = companyStatus;
				this.companyStatusChange.next(companyStatus);
			}));
	}

	getSystemChangeLog(siteIds: number[], startTime: string, endTime: string): Observable<SystemChangeLogEntry[]> {
		return this.companyApiService.getSystemChangeLog(siteIds, startTime, endTime)
			.pipe(map((changeLog: SystemChangeLogEntry[]) => changeLog.map(e => new SystemChangeLogEntry(e))));
	}

	getSystemFlowCapacity(): Observable<number> {
		return this.companyApiService.getSystemFlowCapacity();
	}

	updateCompany(companyId: number, companyData: any): Observable<any> {
		return this.companyApiService.updateCompany([companyId], companyData)
			.pipe(tap(() => {
				this.clearCache();
				this.getCompanyPreferences()
					.pipe(take(1))
					.subscribe((companyPreference: CompanyPreferences) => this.companyPreferencesChange.next(companyPreference));
			}));
	}

	updateCompanyDiagnosticSettings(companyPreferences: CompanyPreferences): Observable<any> {
		return this.companyApiService.updateCompanyDiagnosticSettings(companyPreferences);
	}

	/**
	 * Handle SignalR or other notifications involving company changes. We can handle marking the cache invalid or
	 * updating the cached items and provide external notifications for any interested parties.
	 * @param changes - IrrigationEngineChange[] containing the changes arriving from SignalR.
	 */
	statusChange(changes: CompanyStatusChange[]) {
		// For company changes, basically any change invalidates the list, since the only real change is Updated.
		let changed = false;
		changes.forEach(c => {
			switch (c.changeType) {
				case RbEnums.SignalR.IrrigationEngineChangeType.Updated:
					changed = true;
					break;
			}
		});

		// Notify any clients that there has been a change.
		if (changed) {
			this.clearCache();

			this.companyChange.next(null);
		}
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="segment-info-container">

    <div class="si-top-content">
        <div class="si-pane left">
            <div class="si-labels">
                <div>{{'STRINGS.TERMINAL' | translate}}:</div>
                <div class="sip-row2">{{'STRINGS.PATH' | translate}}:</div>
            </div>
            <div class="si-values">
                <div class="ellipsis-text">{{segmentInfo?.terminal}}</div>
                <div class="ellipsis-text sip-row2">{{segmentInfo?.name}}</div>
            </div>
        </div>

        <div class="si-pane right">
            <div class="si-labels">
                <div>{{'STRINGS.STATUS' | translate}}:</div>
                <div class="sip-row2">{{'STRINGS.ENERGIZE' | translate}}:</div>
            </div>
            <div class="si-values">
                <div class="ellipsis-text short-status" [ngClass]="{'is-shorted': segmentInfo?.isShorted}">{{segmentInfo?.status}}</div>
                <div id="controller-control-container" class="sip-row2">
                    <mat-slide-toggle
                        #energizeToggle
                        class="energize-toggle"
                        color="primary"
                        [ngModel]="segmentInfo?.isEnergized"
                        [disabled]="isDisabled"
                        (change)="onToggleEnergizePath($event)"
                    ></mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>

    <div class="si-pane rb-small-label">
        <div class="si-labels">
            <div>{{'STRINGS.LAST_TEST' | translate}}:</div>
        </div>
        <div class="si-values">
            <div class="ellipsis-text">{{appDateAdapter.formatDateTime(segmentInfo?.changeDateTime) || '--'}}</div>
        </div>
    </div>

</div>



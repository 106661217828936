<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="system-status-tb-container">

	<div *ngIf="!isGolfSite" class="ss-tb-inner-container">
		<button id="OutOfSync" mat-button class="main-nav-menu-item ss-tb-button commercial" matTooltip="{{ 'TOOLTIP.OUT_OF_SYNC_CONTROLLERS' | translate }}"
			routerLinkActive="active" [routerLink]="['/systemstatus','outofsync']" data-qaid="ss-tb-button-outofsync">
			<mat-icon>autorenew</mat-icon>
			<span class="status-count">{{outOfSyncCount}}</span>
		</button>

		<button id="ControllerDifferences" mat-button class="main-nav-menu-item ss-tb-button commercial" matTooltip="{{ 'TOOLTIP.CONTROLLER_DIFFERENCES' | translate }}"
			routerLinkActive="active" [routerLink]="['/systemstatus','controllerdifferences']" [queryParams]="{'showLeftSidebar': true}" data-qaid="ss-tb-button-controllerdifferences">
			<mat-icon class="rb-icon-font icon-field-changes"></mat-icon>
			<span class="status-count">{{differencesCount}}</span>
		</button>

		<button id="RainDelay" mat-button class="main-nav-menu-item ss-tb-button commercial" matTooltip="{{ 'TOOLTIP.CONTROLLERS_IN_RAIN_DELAY' | translate }}"
			routerLinkActive="active" [routerLink]="['/systemstatus','raindelay']" data-qaid="ss-tb-button-raindelay">
			<mat-icon class="rb-icon-font"><i class="icon-rain-delay"></i>raindelay</mat-icon>
			<span class="status-count">{{rainDelayCount}}</span>
		</button>

		<button id="InShutdown" mat-button class="main-nav-menu-item ss-tb-button commercial" matTooltip="{{ 'TOOLTIP.CONTROLLERS_IN_SHUTDOWN' | translate }}"
			routerLinkActive="active" [routerLink]="['/systemstatus','autooff']" data-qaid="ss-tb-button-autooff">
			<mat-icon>highlight_off</mat-icon>
			<span class="status-count">{{shutdownCount}}</span>
		</button>

		<button id="ConnectedControllers"mat-button class="main-nav-menu-item ss-tb-button commercial last-button" matTooltip="{{ 'TOOLTIP.CONTROLLERS_CONNECTED' | translate }}"
			routerLinkActive="active" [routerLink]="['/systemstatus','connections']" data-qaid="ss-tb-button-connections">
			<span class="fa-stack">
				<mat-icon class="fa-stack-1x connected" *ngIf="connectedControllersCount > 0">lens</mat-icon>
				<mat-icon class="fa-stack-1x">panorama_fish_eye</mat-icon>
			</span>
			<span class="status-count">{{connectedControllersCount}}</span>
		</button>
	</div>

	<div *ngIf="isGolfSite" class="ss-tb-inner-container">
		<button mat-button class="main-nav-menu-item ss-tb-button golf large-button ss-tb-flow"
			routerLinkActive="active" [routerLink]="['./activity','active']">
			<rb-system-status-flow></rb-system-status-flow>
		</button>
		<div class="ss-tb-button-wrapper">
			<button mat-button class="main-nav-menu-item ss-tb-button golf" matTooltip="{{ 'TOOLTIP.NEXT_START' | translate }}"
				routerLinkActive="active" [routerLink]="['./activity','scheduled']" data-qaid="ss-tb-button-scheduled">
				<mat-icon>schedule</mat-icon>
				<span class="status-count">{{timeString}}</span>
			</button>

			<button mat-button class="main-nav-menu-item ss-tb-button golf" matTooltip="{{ 'TOOLTIP.WATER_BUDGET' | translate }}"
				routerLinkActive="active" [routerLink]="['./systemstatus','waterbudget']" data-qaid="ss-tb-button-waterbudget">
				<mat-icon class="rb-icon-font icon-water"></mat-icon>
				<span class="status-count">{{companyPreference?.waterBudget || 0}}%</span>
			</button>

			<button mat-button class="main-nav-menu-item ss-tb-button golf" matTooltip="{{ 'TOOLTIP.ET' | translate }}"
				routerLinkActive="active" [routerLink]="['./systemsetup','weather']" data-qaid="ss-tb-button-weather">
				<mat-icon class="rb-icon-font icon-inch-value"></mat-icon>
				<span class="status-count">{{etDisplayValue}}</span>
			</button>

			<button mat-button class="main-nav-menu-item ss-tb-button golf last-button ss-tb-status" matTooltip="{{ 'TOOLTIP.STATUS' | translate }}"
				routerLinkActive="active" [routerLink]="['./systemstatus','status']" data-qaid="ss-tb-button-status">
				<div class="outer-circle" [ngStyle]="{'background-color': background}">
					<mat-icon class="mat-icon-status" [ngStyle]="{'color': color}">{{icon}}</mat-icon>
					<div *ngIf="isSystemLocked">
						<mat-icon class="lock">lock</mat-icon>
					</div>
				</div>
				<span class="status-count">{{systemStatusMsg}}</span>
			</button>
		</div>

	</div>

</div>

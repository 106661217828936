export class Breadcrumb {

	constructor(name: string, route: string, queryParams?: {}) {
		this.name = name;
		this.route = route;

		if (queryParams) this.queryParams = queryParams;
	}

	name: string;
	route: string;
	queryParams: {};
}

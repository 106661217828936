import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-ici-status-cell-renderer',
	templateUrl: './ici-status-cell-renderer.component.html',
	styleUrls: ['./ici-status-cell-renderer.component.scss']
})
export class IciStatusCellRendererComponent implements ICellRendererAngularComp {

	data: any;

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.data = params.data;
	}

	refresh(params: any): boolean {
		this.data = params.data;
		return true;
	}

	convertToSecondToFriendlyString(value: number) {
		return RbUtils.Stations.secondsToFriendlyString(value) ||
			`${value} ${RbUtils.Translate.instant('STRINGS.SEC')}`;
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================
}

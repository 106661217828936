import { ApiService, HttpMethod } from '../_common/api.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { RbccUser } from '../users/models/rbcc-user.model';
import { RbUtils } from '../../common/utils/_rb.utils';

@Injectable({
	providedIn: 'root'
})
export class UiSettingsService extends ApiService {

	private _preferences: Object = null;

	setPreference(key: string, value: any): Observable<any> {
		// Ensure we have loaded preferences first
		return this.getPreference(key).pipe(switchMap(() => {
			if (value == null)
				delete this._preferences[key];
			else
				this._preferences[key] = value;
			const json = JSON.stringify(this._preferences);

			return this.apiRequest<any>(this.updateUserPreferencesUrl, HttpMethod.Patch,
				{ ids: [RbUtils.User.currentUserId], patch: this.patchTransform({ UiSettingsJson: json }) });
		}));
		// localStorage[key] = JSON.stringify(value);
	}

	getPreference(key: string): Observable<any> {
		if (this._preferences != null) return of(this._preferences[key]);

		return this.apiRequest<any>(this.getUserUrl(RbUtils.User.currentUserId), HttpMethod.Get)
			.pipe(map(result => {
				const user = new RbccUser(result);
				this._preferences = user.uiSettingsJson == null ? {} : JSON.parse(user.uiSettingsJson);
				return this._preferences[key];
			}));
		// return localStorage[key] == null ? null : JSON.parse(localStorage[key]);
	}

	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}User`; }
	private get updateUserPreferencesUrl(): string { return `${this.baseUrl}/v2/UpdateUserPreference`; }
	private getUserUrl(userId: number): string { return `${this.baseUrl}/GetUser?userId=${userId}&IncludeCompany=true`; }
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="flex justify-between station-mobile-cell">
	<div class="ellipsis-text"><b>{{station?.terminal}}. {{station?.name}}</b></div>
	<div>{{station['programName']}}</div>
<!--	<div>{{'STRINGS.PROGRAM_N' | translate: { programName: station?.programName } }}</div>-->
</div>
<div class="flex justify-between">
	<div [ngClass]="{'station-status-running': isRunning, 'status-icon-adjust': isActivelyCyclingOrSoaking && (isCycling || isSoaking)}">
		<span *ngIf="isActivelyCyclingOrSoaking && isCycling" class="station-status-cycling" [matTooltip]="'STRINGS.CYCLING' | translate">
			<i class="icon-water"></i>
		</span>
		<span *ngIf="isActivelyCyclingOrSoaking && isSoaking" class="station-status-soaking" [matTooltip]="'STRINGS.SOAKING' | translate">
			<i class="icon-timer"></i>
		</span>
		{{ statusString }}
	</div>
	<div>{{station?.irrigationSourceDescription}}</div>
</div>

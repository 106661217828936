import { ToolbarItemInfo } from './toolbar-item-info.model';

export class DynamicToolbarItemInfo extends ToolbarItemInfo {

	constructor(qaIdString: string,
				label: string,
				dynamicIconFunc: Function,
				dynamicLabelFunc: Function,
				itemClass: string,
				buttonClickFunc: Function,
				isDisabledFunc: Function,
				ngClassFunc: Function,
				customIconNumber: number = null,
				customIconNumberFunc: Function = null,
				animationType: any = null,
				isAnimatingFunc: Function = null
	) {
		super(qaIdString, label, null, buttonClickFunc, isDisabledFunc, customIconNumber, animationType, isAnimatingFunc,
			null, itemClass, dynamicIconFunc, dynamicLabelFunc, ngClassFunc, null, null, customIconNumberFunc);
	}

}

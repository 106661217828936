import { BehaviorSubject, from, of } from 'rxjs';
import { concatMap, delay, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { RbConstants } from '../constants/_rb.constants';

@Injectable({
	providedIn: 'root'
})
export class ProgressService {
	isInProgress$: BehaviorSubject<boolean> = null;
	isLoadedData = false;
	isRemainingProgress = false;
	lastPercentageProgress = 0;
	currentPercentageProgress = 0;
	countApi = 0;
	reportRunningLabel = '';

	constructor() {
	}

	initializeProgress() {
		this.lastPercentageProgress = 0;
		this.currentPercentageProgress = 0;
		this.countApi = 0;
		this.isLoadedData = false;
		this.isRemainingProgress = false;
	}

	remainingPercentageProgress() {
		this.isRemainingProgress = true;
		if (this.isInProgress$) {
			this.isInProgress$.next(true);
		}
	}

	completedPercentageProgress() {
		this.isLoadedData = true;
		if (this.isInProgress$) {
			this.isInProgress$.next(false);
		}
	}

	showPercentageProgress(nextValue: any) {
		this.showProgress(nextValue);
	}

	private showProgress(nextValue: any) {
		this.currentPercentageProgress = 0;
		// console.log('nextValue = ', nextValue);

		if (nextValue === RbConstants.Ui.PERCENTAGE_PROGRESS_MAX) {
			const percentages = [];
			for (let p = this.lastPercentageProgress; p < nextValue; p++) {
				percentages.push(p);
			}
			from(percentages)
				.pipe(concatMap(item => of(item).pipe(
					tap(percent => this.percentageProgress(percent)),
					delay(RbConstants.Ui.PERCENTAGE_PROGRESS_DELAY))))
				.subscribe({
					next: percent => this.percentageProgress(percent),
					error: e => console.log(e),
					complete: () => {
						this.completedPercentageProgress();
						console.log('Completed progress');
					}
				});
		} else {
			for (let i = this.lastPercentageProgress; i < nextValue; i++) {
				this.percentageProgress(i);
			}
		}

		this.lastPercentageProgress = nextValue;
		if (this.lastPercentageProgress >= RbConstants.Ui.PERCENTAGE_PROGRESS_MAX) {
			this.lastPercentageProgress = RbConstants.Ui.PERCENTAGE_PROGRESS_MAX;
		}
	}

	private percentageProgress(currentValue: any) {
		if (this.currentPercentageProgress >= RbConstants.Ui.PERCENTAGE_PROGRESS_MAX) {
			this.currentPercentageProgress = RbConstants.Ui.PERCENTAGE_PROGRESS_MAX;
		} else {
			if (this.currentPercentageProgress === 0) {
				this.currentPercentageProgress = currentValue + 1;
			} else {
				this.currentPercentageProgress = currentValue + 1;
			}
		}
	}
}

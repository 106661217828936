import { RbEnums } from "../../../common/enumerations/_rb.enums";

export class StickyNote {
	id: number;
	companyId: number;
	siteId: number;
	content: string;
	latitude: number;
	longitude: number;
	isMinimized: boolean;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

export class Note extends StickyNote {
	
	/**
	 * Notes can be either Default Note, Task Note or Action Note.
	 * TODO: Task and Action Notes will be implemented later on.
	 */
	noteType: RbEnums.Note.NoteType;

	/**
	 * Notes can have normal and urgent priorities for now. Later on will add Low, Moderate and High.
	 */
	notePriority?: RbEnums.Note.NotePriority;

	/**
	 * Notes can attached to Stations, Holes, Areas and other Notes.
	 * TODO: Hole and Area Notes will be implemented later on.
	 */
	attachedToType?: RbEnums.Note.NoteAnchor;

	/**
	 * The ID of the anchor the note is attached to.
	 */
	attachedToId?: number;

	/**
	 * 
	 * The name of the anchor the note is attached to.
	 */
	attachedToName?: string;
	
	/**
	 * The ID array of the users mentioned on a note.
	 */
	mentionedUserIds?: number[];
	
	/**
	 * The date of the action on a note (reply, resolve or discard).
	 */
	actionDate?: Date;
	
	/**
	 * The ID array of the users mentioned on a note. [Not yet implemented]
	 */
	authorId?: number;
	
	/**
	 * The name of the author of the note.
	 */
	authorName?: string;
	
	/**
	 * The Datetime of the creation of the note.
	 */
	createdDateUtc?: Date;
	
	/**
	 * The status of the note. Can be Active, Resolved or Discarded.
	 * TODO: Discarded will be implemented when we start using other Note Types
	 */
	status?: RbEnums.Note.NoteStatus;
	
	/**
	 * When the note is editing this bool value changes to reflect the editing UI.
	 */
	isEditing?: boolean;
	
	/**
	 * The ID array of the users that have read this note.
	 * This will give feedback to the user when a note is still unread.
	 */
	readBy?: number[];
	
	/**
	 * This is the array of the attached notes of a Station/Hole/Area/Note.
	 */
	replies?: Note[];
	
	/**
	 * This property pins any reply from the replies array to the top of the list in the UI.
	 */
	isPinned?: boolean;

	constructor(json: any = null) {
		super(json);
		if (json) {
			Object.assign(this, json);
		}
	}
}
import { BroadcastService } from '../../common/services/broadcast.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { WeatherData } from './models/weather-data.model';
import { WeatherDataApiService } from './weather-data-api.service';

import WeatherSourceType = RbEnums.Common.WeatherSourceType;


@Injectable({
	providedIn: 'root'
})
export class WeatherDataManagerService extends ServiceManagerBase {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private weatherDataApiService: WeatherDataApiService,
				protected broadcastService: BroadcastService
	) {
		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getWeatherData(id: number, startDate: string, endDate: string): Observable<WeatherData> {
		return this.weatherDataApiService.getWeatherData(id, startDate, endDate);
	}	

	getWeatherSensorData(id: number, startDate: string, endDate: string): Observable<WeatherData[]> {
		return this.weatherDataApiService.getWeatherSensorData(id, startDate, endDate);
	}

	getWeatherSensorDataHistory(sensorIds: number[], lookbackHours: number): Observable<{ [sensorId: number]: WeatherData[] }> {
		return this.weatherDataApiService.getWeatherSensorDataHistory(sensorIds, lookbackHours);
	}

	getLatestWeatherData(id: number): Observable<WeatherData> {
		return this.weatherDataApiService.getLatestWeatherData(id);
	}

	getWeatherDataOfWeatherSource(weatherSourceId: number, startTime: string, endTime: string): Observable<WeatherData[]> {
		return this.weatherDataApiService.getWeatherDataOfWeatherSource(weatherSourceId, startTime, endTime);
	}

	updateWeatherData(weatherData: WeatherData[]): Observable<null> {
		return this.weatherDataApiService.updateWeatherData(weatherData);
	}

	getWeatherForecast(weatherSourceType: WeatherSourceType, location: string): Observable<any> {
		return this.weatherDataApiService.getWeatherForecast(weatherSourceType, location);
	}

}

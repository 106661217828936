<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<!-- Desktop Menu -->
<div class="player-menu">

	<div [ngClass]="{'player-menu': !isMobile, 'player-menu-mobile': isMobile}">
		<div [ngClass]="{
			'player-menu-sub-container': !isMobile,
			'player-menu-mobile-sub-container': isMobile,
			'mobile-overflow-menu': isOverFlowToolbarDisplay
		}" style="overflow: hidden;">
			<ng-container *ngIf="isOverFlowToolbarDisplay" [ngTemplateOutlet]="overflowSecondaryMenu"></ng-container>
			<ul *ngFor="let g of toolbarInfo?.groups" [ngClass]="{'player-menu-mobile-container list justify-center': isMobile,
				'export-csv': g.buttons[0]?.qaId == 'ExportCSV', 'view-type-selection-container': g.buttons[0]?.qaId == 'ListView'}">
				<li *ngFor="let btn of g.buttons" attr.data-qaid="btn-group-list">
					<rb-icon-button
						[id]="btn.qaId"
						[class]="btn.itemClass"
						[ngClass]="btn.ngClass"
						class="dr-view-type"
						[icon]="btn.icon ? btn.icon : btn.dynamicIcon"
						[label]="(btn.label ? btn.label : btn.dynamicLabel) | translate"
						[animationType]="btn.animationType"
						[isAnimating]="btn.isAnimating"
						[color]="btn.color"
						[disabled]="btn.isDisabled"
						[badgeCount]="btn.badgeCount"
						[iconContainerClass]="btn.iconContainerClass"
						[customIconNumber]="btn.customIconNumber != null ? btn.customIconNumber : btn.dynamicCustomIconNumber"
						(iconButtonClicked)="btn.onButtonClick()"
						attr.data-qaid="main-panel-button-{{(btn.label || btn.dynamicLabel) | replaceWhitespace: '_' }}"
					>
					</rb-icon-button>
				</li>
			</ul>

			<ng-container *ngIf="!isOverFlowToolbarDisplay">
				<ul *ngIf="hasMoreButton" [ngClass]="{'list': isMobile}">
					<li class="ma0">
						<rb-icon-button
							id="More"
							[mat-menu-trigger-for]="moreMenu"
							icon="more_horiz"
							[label]="'STRINGS.MORE' | translate"
							data-qaid="main-panel-more-menu-button">
						</rb-icon-button>
						<mat-menu #moreMenu="matMenu" class="rb-more-menu" [overlapTrigger]="false">
							<!-- Toolbar overflow goes here -->
							<div *ngIf="sensorExtraMenuItems.length > 0">
								<button
									*ngFor="let item of sensorExtraMenuItems"
									class="rb-toolbar-menu-item"
									mat-menu-item
									[disabled]="item.isDisabled"
									(click)="item.onButtonClick()"
									attr.data-qaid="main-panel-more-menu-item-{{item.label | replaceWhitespace: '_' }}"
								>
									<rb-icon-button
										[hideLabel]="true"
										[class]="item.itemClass"
										[ngClass]="item.ngClass"
										[icon]="item.icon ? item.icon : item.dynamicIcon"
										[label]="item.label | translate"
										[animationType]="item.animationType"
										[isAnimating]="item.isAnimating"
										[color]="item.color"
										[disabled]="item.isDisabled"
										[customIconNumber]="item.customIconNumber != null ? item.customIconNumber : item.dynamicCustomIconNumber"
										(iconButtonClicked)="item.onButtonClick()"
									>
									</rb-icon-button>
									{{item.label | translate}}
								</button>
								<hr class="hrline"/>
							</div>
							<button
								*ngFor="let item of toolbarMenuItems"
								class="rb-toolbar-menu-item"
								mat-menu-item
								[disabled]="item.isDisabled"
								(click)="item.onButtonClick()"
								attr.data-qaid="main-panel-more-menu-item-{{(item.label || item.dynamicLabel) | replaceWhitespace: '_' }}"
							>
								<rb-icon-button
									[hideLabel]="true"
									[class]="item.itemClass"
									[ngClass]="item.ngClass"
									[icon]="item.icon ? item.icon : item.dynamicIcon"
									[label]="(item.label ? item.label : item.dynamicLabel) | translate"
									[animationType]="item.animationType"
									[isAnimating]="item.isAnimating"
									[color]="item.color"
									[disabled]="item.isDisabled"
									[customIconNumber]="item.customIconNumber != null ? item.customIconNumber : item.dynamicCustomIconNumber"
									(iconButtonClicked)="item.onButtonClick()"
								>
								</rb-icon-button>
								{{(item.label ? item.label : item.dynamicLabel) | translate}}
							</button>
							<div *ngIf="toolbarMenuItems.length > 0 && overflowMenu" class="more-menu-separator">
								<hr>
							</div>
							<ng-container [ngTemplateOutlet]="overflowMenu"></ng-container>
							<ng-container [ngTemplateOutlet]="exportMenu"></ng-container>
						</mat-menu>
					</li>
				</ul>
			</ng-container>
			<ul *ngIf="masterValvesMenu" [ngClass]="{'player-menu-mobile-container list justify-center': isMobile}">
				<ng-container [ngTemplateOutlet]="masterValvesMenu"></ng-container>
			</ul>

			<rb-select-holes-dropdown
				id="mpt-holes-filter"
				#holesFilter 
				*ngIf="includeHolesFilter" 
				[hidden]="isMobile" 
				[holesAndPlaces]="holes" 
				(holesSelected)="onHolesSelected($event)"
			>
			</rb-select-holes-dropdown>
			<rb-front-back-dropdown
				#areasFilter
				id="mpt-areas-filter"
				*ngIf="includeAreasFilter"
				[hidden]="isMobile"
				[label]="'STRINGS.AREAS' | translate"
				[dataArray]="holeSections"
				[showSelectionCount]="true"
				[isAreas]="true"
				(itemsSelected)="onHoleSectionsSelected($event)"
			>
			</rb-front-back-dropdown>

		</div>

		<div class="right-btn-group" *ngIf="!deviceManager.isMobile && (includePdfExport || includeCsvExport)">
			<button mat-button type="button" class="outlined-button" color="primary" (click)="handleExportPdfClick()" *ngIf="includePdfExport">{{'STRINGS.VIEW_PDF' | translate}}</button>
			<button mat-button type="button" class="outlined-button" color="primary" (click)="handleExportCSVClick()" *ngIf="includeCsvExport">{{'STRINGS.EXPORT_TO_CSV' | translate}}</button>
		</div>
	</div>

</div>

<ng-template #overflowSecondaryMenu>
	<ul class="extra-menu-mobile-container" [ngClass]="{'list justify-center': isMobile }">
		<li *ngFor="let btn of toolbarMenuItems">
			<rb-icon-button
				[id]="btn.qaId"
				[class]="btn.itemClass"
				[ngClass]="btn.ngClass"
				class="dr-view-type"
				[icon]="btn.icon ? btn.icon : btn.dynamicIcon"
				[label]="(btn.label ? btn.label : btn.dynamicLabel) | translate"
				[animationType]="btn.animationType"
				[isAnimating]="btn.isAnimating"
				[color]="btn.color"
				[disabled]="btn.isDisabled"
				[badgeCount]="btn.badgeCount"
				[iconContainerClass]="btn.iconContainerClass"
				[customIconNumber]="btn.customIconNumber != null ? btn.customIconNumber : btn.dynamicCustomIconNumber"
				(iconButtonClicked)="btn.onButtonClick()"
				attr.data-qaid="main-panel-button-{{(btn.label || btn.dynamicLabel) | replaceWhitespace: '_' }}"
			>
			</rb-icon-button>
		</li>
	</ul>
</ng-template>


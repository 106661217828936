import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class Mrm {

	activityState: string;
	companyId: number;
	id: number;
	isUpsideDown: boolean;
	masterValveType: RbEnums.Common.MasterValveType;
	minimumOrdinal: number;
	maximumOrdinal: number;
	mrr: any;
	mrrId: number;
	ordinal: number;
	rbbusVersionString: string;
	stationCount: number;
	sku: RbEnums.Common.SkuType;
	satelliteId: number;
	type: RbEnums.Common.DeviceType;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

import { ReportFilterDateRange } from './report-filter-date-range.model';

export class ReportFilterTimePeriod {

	currentDateRange: ReportFilterDateRange = null;
	previousDateRange: ReportFilterDateRange = null;

	constructor(currentDateRange: ReportFilterDateRange, previousDateRange: ReportFilterDateRange = null) {
		this.currentDateRange = currentDateRange;
		this.previousDateRange = previousDateRange;
	}

	get currrentDateRangeStartDateString(): string {
		return this.currentDateRange == null ? '' : this.currentDateRange.startDateString;
	}

	get currrentDateRangeEndDateString(): string {
		return this.currentDateRange == null ? '' : this.currentDateRange.endDateString;
	}

	get previousDateRangeStartDateString(): string {
		return this.previousDateRange == null ? '' : this.previousDateRange.startDateString;
	}

	get previousDateRangeEndDateString(): string {
		return this.previousDateRange == null ? '' : this.previousDateRange.endDateString;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { StationWithMapInfoLeaflet } from '../../common/models/station-with-map-info-leaflet.model';

/**
 * Returns the remaining runtime as a formatted string
 */
@Pipe({
  name: 'remainingRunTimeDisplay'
})
export class RemainingRunTimeDisplayPipe implements PipeTransform {

  private irrigationStatus = RbEnums.Common.IrrigationStatus;

  readonly noTimeString = RbUtils.Translate.instant('STRINGS.NO_TIME_HMS');

  /**
   * We need 
   * @param runTimeRemaining 
   * @param station 
   * @returns 
   */
  transform(runTimeRemaining: number, station: StationWithMapInfoLeaflet): unknown {
    if (station.irrigationStatus === this.irrigationStatus.Dash || station.irrigationStatus === this.irrigationStatus.Idle ||
      (station.irrigationStatus === this.irrigationStatus.Pending && (station.runTimeRemaining == null || station.runTimeRemaining
        === 0))) {
      return this.noTimeString;

    } else if (station.runTimeRemaining != null && station.irrigationStatus !== this.irrigationStatus.Soaking) {
      return RbUtils.Stations.secondsToHhMmSs(station.runTimeRemaining);
    } else {
      return station.mapStatus ? station.mapStatus : this.noTimeString
    }
  }

}

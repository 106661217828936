<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="!isMobile" class="diagnostics-filter-desktop">
	<div class="left-side">
		<div class="selection-container">
				<mat-select [placeholder]="'STRINGS.TEST_TYPE' | translate" [(ngModel)]="selectedTestTypeId" [disabled]="selectedCourseId == null">
					<mat-option *ngFor="let testType of testTypes" [value]="testType.id">{{testType.name}}</mat-option>
				</mat-select>
		</div>

		<div class="selection-container">
			<mat-select [placeholder]="'STRINGS.TYPE' | translate" [disabled]="selectedCourseId == null" [(ngModel)]="selectedDiagnosticTypeId" (ngModelChange)="onDiagnosticTypeSelected()">
				<mat-option *ngFor="let diagnosticsType of availableDiagnosticTypes" 
					[value]="diagnosticsType.id">
					{{diagnosticsType.name}}
				</mat-option>
			</mat-select>
		</div>

		<div *ngIf="selectedDiagnosticTypeId !== 1" class="selection-container">
			<mat-select [placeholder]="'STRINGS.COURSE' | translate" [(ngModel)]="selectedCourseId" (ngModelChange)="onCourseSelected()">
				<mat-option *ngFor="let course of courses" [value]="course.id">{{course.name}}</mat-option>
			</mat-select>
		</div>

		<!-- Address options -->
		<div *ngIf="selectedDiagnosticTypeId === 1" class="selection-container">
			<mat-select [placeholder]="'STRINGS.INTERFACE' | translate" [(ngModel)]="selectedInterfaceId">
				<mat-option *ngFor="let i of interfaces" [class.all-item]="i.id === -1" [value]="i.id">
					{{i.name}}
				</mat-option>
			</mat-select>
		</div>

		<div *ngIf="selectedDiagnosticTypeId === 1" class="selection-container">
			<mat-select [placeholder]="'STRINGS.WIREPATH_NUMBER' | translate" [disabled]="selectedInterfaceId == null" [(ngModel)]="selectedWirePathIds" multiple (selectionChange)="onWirePathSelected()">
				<mat-option *ngFor="let wirePath of wirePaths" [class.all-item]="wirePath.id === -1" [value]="wirePath.id">
					{{wirePath.name}}
				</mat-option>
			</mat-select>
		</div>

		<!-- Course options -->
		<div *ngIf="selectedDiagnosticTypeId === 2" class="selection-container with-nested-dropdown">
			<rb-select-holes-dropdown #holesDropdown 
				[flat]="true" 
				[holesAndPlaces]="holesAndPlaces" 
				[showSelectionCount]="true">
			</rb-select-holes-dropdown>
		</div>

		<div *ngIf="selectedDiagnosticTypeId === 2" class="selection-container with-nested-dropdown">
			<rb-front-back-dropdown #areasDropdown flat="true" [label]="'STRINGS.AREAS' | translate" [dataArray]="courseAreas" [isAreas]="true" [showSelectionCount]="true">
			</rb-front-back-dropdown>
		</div>

		<div class="button-area">
			<button (click)="onRunButtonClicked()" 
				[disabled]="!runButtonEnabled">
				{{'STRINGS.RUN_TEST_UPPERCASE' | translate}}
			</button>
			<button (click)="onCancelButtonClicked()" 
				style="margin-left: 10px;" 
				[disabled]="!cancelButtonEnabled">
				{{'STRINGS.CANCEL_ALL_UPPERCASE' | translate}}
			</button>
		</div>
	</div>

<!--	<div class="right-side">-->
<!--		FILTER-->
<!--	</div>-->
</div>

<rb-mobile-diagnostics-filter #mobileFilter *ngIf="isMobile" class="mobile-diagnostics-filter" [parentDiagnosticsFilter]="this"></rb-mobile-diagnostics-filter>

<rb-waiting-indicator
	*ngIf="isBusy"
	[isVisible]="isBusy"
	[error]="loadError"
	(okClick)="onActionErrorAcknowledged()">
</rb-waiting-indicator>


<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="drm-cell-container">
    <div class="sf-top-pane">
        <div class="drmc-pane left">
            <div class="drmc-labels">
                <div>{{'STRINGS.NAME' | translate}}:</div>
                <div>{{'STRINGS.TYPE' | translate}}:</div>
                <div>{{'STRINGS.RESULT' | translate}}:</div>
            </div>
            <div class="drmc-values">
                <div class="ellipsis-text">{{shortFindingItem?.name}}</div>
                <div class="ellipsis-text">{{shortFindingItem?.deviceTypeString}}</div>
                <div class="ellipsis-text">{{shortFindingItem?.resultString}}</div>
            </div>
        </div>

        <div class="drmc-pane right">
            <div class="drmc-labels">
                <div>{{'STRINGS.TERMINAL' | translate}}:</div>
                <div>{{'STRINGS.ADDRESS' | translate}}:</div>
            </div>
            <div class="drmc-values">
                <div class="ellipsis-text">{{shortFindingItem?.terminal}}</div>
                <div class="ellipsis-text">{{shortFindingItem?.address}}</div>
            </div>
        </div>
    </div>
    <div class="sf-top-pane bottom" >
        <div class="drmc-pane no-max">
            <div class="drmc-labels">
                <div>{{'STRINGS.TROUBLESHOOTING' | translate}}:</div>
            </div>
            <div class="drmc-values">
                <div class="ellipsis-text">{{shortFindingItem?.troubleshooting}}</div>
            </div>
        </div>
    </div>
</div>


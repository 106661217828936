import { Component, Injector } from '@angular/core';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { ControllerListItem } from '../../../../../api/controllers/models/controller-list-item.model';
import { FirmwareUpdateProgress } from '../../../../../api/signalR/firmware-update-progress.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-fw-progress-cell-renderer',
	templateUrl: './controller-status-cell-renderer.component.html',
	styleUrls: ['./controller-status-cell-renderer.component.scss']
})
export class ControllerStatusCellRendererComponent implements ICellRendererAngularComp {
	color = 'dodgerblue';
	mode = 'determinate';
	controllerListItem: ControllerListItem;
	firmwareUpdateProgress: FirmwareUpdateProgress = null;
	authManager: AuthManagerService;
	isGolfSite = false;

	constructor(private baseInjector: Injector) {
		this.authManager = this.baseInjector.get(AuthManagerService);
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.setCellData(params);
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
	}

	refresh(params: any): boolean {
		this.setCellData(params);
		return true;
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================

	get controllerStatusCellRenderer(): string {
		return RbUtils.GridCellStyles.getControllerStatusCell(this.controllerListItem, this.isGolfSite);
	}

	get fwProgressTooltip(): string {
		if (!this.controllerListItem.firmwareUpdateProgress) return;

		const fup = this.controllerListItem.firmwareUpdateProgress;
		const firmwareUpdateDeviceType = (fup.firmwareHeaderDeviceType === 'Unknown') ? '' : `${fup.firmwareDeviceTypeLabel} `;

		return RbUtils.Translate.instant('STRINGS.FIRMWARE_UPDATE_TOOLTIP',
			{
				firmwareUpdateDeviceType: firmwareUpdateDeviceType,
				firmwareUpdatePercentComplete: this.controllerListItem.firmwareUpdateProgress.progress
			});
	}

	get fwProgress(): number {
		if (!this.controllerListItem.firmwareUpdateProgress) return;

		return this.controllerListItem.firmwareUpdateProgress.progress;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setCellData(params: any) {
		this.controllerListItem = params.data;
		this.firmwareUpdateProgress = this.controllerListItem.firmwareUpdateProgress;
	}

}

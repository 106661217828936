/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Date.transform
 * 		 See: _rb.utils.ts
 */

import { AppInjector } from '../../core/core.module';
import { DatePipe } from '@angular/common';

export namespace XXUseRbUtilsNamespace {

	export abstract class Date {

		private static _datePipe: DatePipe;

		private static get datePipe() {
			if (!Date._datePipe) {
				Date._datePipe = AppInjector.get(DatePipe);
			}
			return Date._datePipe;
		}

		static transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
			return Date.datePipe.transform(value, format, timezone, locale);
		}

		static numberOfDayBetween(date1: Date, date2: Date) {
			if (date1 == null || date2 == null) return 0;
			return Math.round(Math.abs((+date1) - (+date2)) / (24 * 60 * 60 * 1000));
		}
		static timeStringToSeconds(runtime: string): number {
			const runtimeArray = runtime.split(':');
			return +runtimeArray[0] * 60 * 60 + +runtimeArray[1] * 60 + +runtimeArray[2];
		}
	}
}

export class StartStationModel {

	constructor(stationIds: number[], seconds: number[]) {
		this.stationIds = stationIds;
		this.seconds = seconds;
	}

	seconds: number[];
	stationIds: number[];
}

export class UserSite {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}

	companyId: number;
	siteId: number;
	userId: number;
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ControllerManagerService } from '../../../../../api/controllers/controller-manager.service';
import { CultureSettings } from '../../../../../api/culture-settings/models/culture-settings.model';
import { DatePipe } from '@angular/common';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { ScheduledFirmwareUpdate } from '../../../../../api/auto-contact/models/auto-contact.model';

@Component({
	selector: 'rb-firmware-update-mobile-cell-renderer',
	templateUrl: './firmware-update-mobile-cell-renderer.component.html',
	styleUrls: ['./firmware-update-mobile-cell-renderer.component.scss']
})
export class FirmwareUpdateMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	scheduled: ScheduledFirmwareUpdate;
	cultureSettings: CultureSettings;
	constructor(
		protected authManager: AuthManagerService,
		private datePipe: DatePipe,
		private controllerManager: ControllerManagerService,
		injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
		this.cultureSettings = this.authManager.userCulture;
	}

	agInit(params: any): void {
		super.agInit(params);
		this.scheduled = params.data;
	}

	// =========================================================================================================================================================
	// Cell Renderer
	// =========================================================================================================================================================

	getTimeCellRenderer() {
		if (!this.scheduled.time) {
			return '-';
		}
		const timetimePickerDisplay = RbUtils.Common.getTimePickerDisplayValue(
			this.cultureSettings
		);
		const dateFormat = RbUtils.Common.getDateFormat(this.cultureSettings);

		return this.datePipe.transform(
			this.scheduled.time,
			dateFormat + ' ' + timetimePickerDisplay
		);
	}

	get controllerNamesRenderer() {
		if (!this.scheduled.autoContacts) {
				return `<i class="mobile-controller-name-display">-</i>`;
		}

		const fullListName = this.getFullListControllerName(this.scheduled.autoContacts);
			if (this.scheduled.autoContacts.length <= 3) {
				return `<i class="mobile-controller-name-display">${fullListName}</i>`;
		}

		const displayName = this.getDisplayControllerName(this.scheduled.autoContacts);
		return `<i class="mobile-controller-name-display" title="${fullListName}">${displayName}</i>`;
	}

	getWeekDaysRenderer() {
		return RbUtils.Weekdays.getStartDays(
			RbEnums.Common.ProgramType.Custom,
			this.scheduled.weekDays,
			''
		);
	}

	/**
	 * Get full list of controller name from contact list
	 * @param contactList
	 * @returns
	 */
	private getFullListControllerName(contactList: any[]) {
		return contactList.map((autoContact: any) =>
				autoContact.satellite == null
					? this.controllerManager.getControllerName(
							autoContact.satelliteId)
					: autoContact.satellite.name
			).join(', ');
	}

	/**
	 * get controller name list for displaying, limit by 3
	 * @param contactList
	 * @returns
	 */
	private getDisplayControllerName(contactList: any[]) {
		return contactList.slice(0, 3).map((autoContact: any) =>
				autoContact.satellite == null
					? this.controllerManager.getControllerName(
							autoContact.satelliteId)
					: autoContact.satellite.name
			).join(', ') + '...';
	}
}

import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { ReportRecipient } from '../../address-book/models/report-recipient.model';
import { ReportSite } from '../../reports/models/report-site.model';
import { ScheduledReportTime } from './scheduled-report-time.model';

export class ScheduledReport {

	id: number;
	companyId: number;
	subject: string;
	scheduledReportTime: ScheduledReportTime[];
	disableReport: boolean;
	notSendBlank: boolean;
	timeZone: string;
	typeId: RbEnums.Common.ReportType;
	reportRecipients: ReportRecipient[];
	reportSites: ReportSite[];
	whoFrom: string;
	sendToMyself: boolean;
	time: Date;
	fileFormat: RbEnums.Common.ReportFormatType;
	
	constructor(json: any = null) {
		this.reportRecipients = [];
		this.reportSites = [];
		if (json) {
			Object.assign(this, json);
			if (json.reportRecipient) { this.reportRecipients = json.reportRecipient.map(item => new ReportRecipient(item)); }
			if (json.reportSite) { this.reportSites = json.reportSite.map(item => new ReportSite(item)); }
			if (json.scheduledReportTime) {
				this.scheduledReportTime = json.scheduledReportTime.map(scheduledTimeJson => new ScheduledReportTime(scheduledTimeJson));
			}

			if (json.timeOffset) {
				this.time = RbUtils.Common.timeOffsetToDisplayTime(json.timeOffset)
			}
		}
	}
}

<div class="rb-tree-view">
    <div class="text-and-icon-container" *ngIf="showInfoOutline; else noInfoOutline">
        <span>{{selectedControllersPlaceholder | translate}}</span>
        <div class="info-icon-container">
            <button mat-icon-button class="blue" [matMenuTriggerFor]="helpContent">
                <mat-icon class="help-icon">info_outline</mat-icon>
            </button>
        </div>
    </div>
    <mat-menu #helpContent="matMenu" [overlapTrigger]="false" >
        <ng-container>
            <div class="ams-help-container" >
                <div class="ams-help-row">
                    {{'STRINGS.FIRMWARE_AUTO_UPDATE_HINT' | translate}}
                </div>
                <div class="ams-help-row">
                    {{'STRINGS.FIRMWARE_AUTO_UPDATE_WARNING_1' | translate}}
                </div>
                <div class="ams-help-row">
                    {{'STRINGS.FIRMWARE_AUTO_UPDATE_WARNING_2' | translate}}
                </div>
            </div>
        </ng-container>
    </mat-menu>
    <ng-template #noInfoOutline>
        <span>{{selectedControllersPlaceholder | translate}}</span>
    </ng-template>
    <div class="select-all-controls">
        <mat-checkbox color="primary" [checked]="isAllNodesSelected" (change)="onSelectAllChange($event)"
            class="select-all checklist-leaf-node">
            {{ 'STRINGS.SELECT_ALL_UPPERCASE' | translate }}
        </mat-checkbox>
    </div>
    <div class="multi-controller-select-radio-container">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox color="primary" class="checklist-leaf-node" [checked]="isSelected(node)"
                    (change)="onSelectedItemsChanged(node);">{{node.item}}
                </mat-checkbox>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasChild " matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <mat-checkbox color="primary" [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)" (change)="itemSelectionToggle($event, node)">
                    {{node.item}}
                </mat-checkbox>
            </mat-tree-node>
        </mat-tree>
    </div>
</div>

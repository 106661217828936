<div class="action-container"> 
	<div class="buttons-container" *ngFor="let button of buttons"> 
		<button (click)="handleClick(button.id)" title="{{ button.title }}"
			*ngIf="button.condition" [disabled]="button.disabled"> 
			<i class="{{ button.icon }}"></i>
			<span class="icon-button-text">{{ button.text }}</span>
		</button> 
	</div> 
</div>
<div class="bottom-container">
	<div class="counter-container">
		<span class="badge">
			{{ numberStations }}
		</span>
		<span>{{ 'STRINGS.SELECTED_STATIONS' | translate }}</span>
	</div>
</div>
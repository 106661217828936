import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as moment from 'moment';

@Component({
	selector: 'rb-duration-shortcut',
	templateUrl: './duration-shortcut.component.html',
	styleUrls: ['./duration-shortcut.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DurationShortcutComponent implements OnInit {
	@Input() showHeader = true;
	@Input() showButtons = true;

	@Input() defaultRunStationDuration = moment.duration({ minutes: 5 });

	@Output() durationChange = new EventEmitter();

	private adjustedDurationDisplay = false;
	private _showMinuteOnly = false;
	
	constructor() { }

	ngOnInit(): void {
	}

	@Input() set showMinuteOnly(value: boolean) {
		this._showMinuteOnly = value;
	}

	get showMinuteOnly() {
		return !this.adjustedDurationDisplay && this._showMinuteOnly;
	}

	onDurationSet(event) {
		this.durationChange.emit(event);
	}

	showHourAndSecond() {
		this.adjustedDurationDisplay = true;
	}
}

import { ProgramStepListItem } from './program-step-list-item.model';

export class ProgramStepsListChange {

	constructor(programId: number, programSteps: ProgramStepListItem[]) {
		this.programId = programId;
		this.programSteps = programSteps;
	}

	programId: number;
	programSteps: ProgramStepListItem[];
}

import { RbConstants } from '../../../../common/constants/_rb.constants';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';

export class FilterChangeModel {

	// @ts-ignore
	constructor(dateRange: RbConstants.Form.DATE_RANGE, startDate: Date, endDate: Date, activityType: RbEnums.Common.ActivityType, eventLogTypes?: number[]) {
		this.dateRange = dateRange;
		this.startDate = startDate;
		this.endDate = endDate;
		this.activityType = activityType;

		if (eventLogTypes) {
			this.eventLogTypes = eventLogTypes;
			// eslint-disable-next-line no-bitwise
			this.eventLogTypesFlag = eventLogTypes.length > 0 ? eventLogTypes.reduce((acc, type) => acc | type) : 0;
		}
	}

	// @ts-ignore
	dateRange: RbConstants.Form.DATE_RANGE;
	startDate: Date;
	endDate: Date;
	activityType: RbEnums.Common.ActivityType;
	eventLogTypes: number[] = null;
	eventLogTypesFlag: number = null;
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="sub-edit-selection-area">
	<div class="sub-edit-label"><span *ngIf="selectorLabel">{{selectorLabel}}</span> <span *ngIf="isRequired">*</span></div>
	<div class="sub-edit-value-and-arrow">
		<div class="sub-edit-value"><span *ngIf="selectorValue">{{selectorValue}}</span></div>
		<div class="sub-edit-arrow"><i class="material-icons">navigate_next</i></div>
	</div>
</div>

import { ApiService, HttpMethod } from '../_common/api.service';
import { UserAccessRequestInfo, UserAccessRequestInfoUI } from './models/access-request.model';

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AccessRequestApiService extends ApiService {

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getCurrentAccessRequestInfoForUser(): Observable<UserAccessRequestInfoUI> {
		return this.apiRequest(this.getCurrentAccessRequestInfoForUserUrl(), HttpMethod.Get).pipe(
			map((userAccessRequestInfo: UserAccessRequestInfo )=> {
				return new UserAccessRequestInfo(userAccessRequestInfo);
			})
		)
	}

	dismissRequest(): Observable<UserAccessRequestInfo> {
		return this.apiRequest(this.getDismissRequestUrl(), HttpMethod.Post).pipe(
			map((result: UserAccessRequestInfo )=> {
				return new UserAccessRequestInfo(result);
			})
		)
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================

	private get baseCompanyApiUrl(): string { return `${this.baseApiUrl}AccessRequest`; }

	private getCurrentAccessRequestInfoForUserUrl(): string {
		return `${this.baseCompanyApiUrl}/GetCurrentAccessRequestInfoForUser`;
	}

	private getDismissRequestUrl(): string {
		return `${this.baseCompanyApiUrl}/DismissRequest`;
	}
}

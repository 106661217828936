<div id="message-box-container" class="message-box-container">
	<div id="message-box-title" class="message-box-title" mat-dialog-title>
		<span>{{ title | translate }}</span>
		<div class="info-icon-container" *ngIf="infoText">
			<button mat-icon-button class="blue info-icon-button" [matMenuTriggerFor]="helpContent">
				<mat-icon class="help-icon" style="color: white;">info_outline</mat-icon>
			</button>
		</div>
	</div>
	<div id="message-box-content" mat-dialog-content>
		<ng-container *ngIf="message; else innerChildContent">
			<div *ngIf="messageBoxIcon" id="message-box-icon" class="message-box-icon">
				<i class="material-icons" [ngStyle]="{'color': color}">{{ messageBoxIcon }}</i>
			</div>
			<div id="message-box-text" class="message-box-text">
				<span [innerHTML]="message | translate"></span>
			</div>
		</ng-container>
	</div>

	<div *ngIf="buttons === MessageBoxButtons.Ok" class="message-box-actions" class="message-box-actions" mat-dialog-actions>
		<button mat-flat-button cdkFocusInitial (click)="onOkYesClick()">{{okButtonName || ('STRINGS.OK' | translate)}}</button>
	</div>
	<div *ngIf="buttons === MessageBoxButtons.YesNo"  class="message-box-actions rb-yes-no-buttons"  mat-dialog-actions>
		<button mat-flat-button cdkFocusInitial class="rb-button rb-no-button" (click)="onNoClick()">{{ 'STRINGS.NO' | translate }}</button>
		<button mat-flat-button class="rb-button rb-yes-button" (click)="onOkYesClick()">{{ 'STRINGS.YES' | translate }}</button>
	</div>
	<div *ngIf="buttons === MessageBoxButtons.OkCancel"  class="message-box-actions rb-yes-no-buttons"  mat-dialog-actions>
		<button mat-flat-button cdkFocusInitial class="rb-button rb-no-button" (click)="onNoClick()">{{ 'STRINGS.CANCEL' | translate }}</button>
		<button mat-flat-button class="rb-button rb-yes-button" (click)="onOkYesClick()">{{okButtonName || ('STRINGS.OK' | translate)}}</button>
	</div>
</div>

<mat-menu #helpContent="matMenu" [overlapTrigger]="false" >
	<ng-container>
		<div class="msg-box-info-container" [innerHTML]="infoText | translate" ></div>
	</ng-container>
</mat-menu>

<ng-template #innerChildContent>
	<div class="child-content">
		<ng-content></ng-content>
	</div>
</ng-template>

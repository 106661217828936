import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AreaUiSettings } from '../../../api/areas/models/area-ui-settings.model';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { FormValidationService } from '../../../common/services/form-validation.service';
import { GeoGroupManagerService } from './../../../api/regions/geo-group-manager.service';
import { MapInfoLeaflet } from '../../../common/models/map-info-leaflet.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import Timer = NodeJS.Timer;


@UntilDestroy()
@Component({
	selector: 'rb-custom-station-area-style',
	templateUrl: './custom-station-area-style.component.html',
	styleUrls: ['./custom-station-area-style.component.scss'],
})
export class CustomStationAreaStyleComponent implements OnInit, OnDestroy {
	@Input() siteId: number;
	@Input() stationId: number;
	@Input() mapInfo: MapInfoLeaflet;
	@Output() closed = new EventEmitter<boolean>();

	settings: AreaUiSettings;
	isGolfSite: boolean;
	editForm: FormGroup;
	presetColors = [ '#FE4947', '#FD2018', '#FD0600', '#B60300', '#670000', '#588f27', '#8ABE00', '#279B3A', '#196B34', '#2F5331', '#6DB5E9', '#30A2E5',
	'#2176AC', '#15538A', '#061D43' ];

	fillOpacityErrorMessage: string;
	lineOpacityErrorMessage: string;
	lineWidthErrorMessage: string;

	private displayTimer: Timer;

	constructor(
		private fb: FormBuilder,
		private siteManager: SiteManagerService,
		private geoGroupManager: GeoGroupManagerService,
		public translate: TranslateService,
		private formValidation: FormValidationService,
		public deviceManager: DeviceManagerService
	) {}

	ngOnInit() {
		this.isGolfSite = this.siteManager.isGolfSite;
		const geoGroup = this.mapInfo.stationAreas.find(station => station.stationId === this.stationId);
		this.settings = geoGroup ? JSON.parse(geoGroup?.uiSettingsJson) : new AreaUiSettings();
		this.editForm = this.fb.group({
			fillColor: this.settings.fillColor,
			fillOpacity: [this.settings.fillOpacity, [ this.formValidation.rbMinMaxValidation(0, 100) ]],
			lineColor: this.settings.lineColor,
			lineOpacity: [this.settings.lineOpacity, [ this.formValidation.rbMinMaxValidation(0, 100) ]],
			lineWidth: [this.settings.lineWidth, [ this.formValidation.rbMinMaxValidation(0, 10) ]],
		});

		this.formValidation.setupValidation(this, this.editForm, 'fillOpacity', this.translate.instant('STRINGS.FILL_OPACITY'));
		this.formValidation.setupValidation(this, this.editForm, 'lineOpacity', this.translate.instant('STRINGS.LINE_OPACITY'));
		this.formValidation.setupValidation(this, this.editForm, 'lineWidth', this.translate.instant('STRINGS.LINE_STROKE'));

		this.displayTimer = setInterval(() => {
			clearInterval(this.displayTimer);
		}, 100);
	}

	ngOnDestroy() {
		clearInterval(this.displayTimer);
	}

	saveStationAreaStyles() {
		const settings = this.editForm.value;
		this.geoGroupManager.updateStationGeoGroupSettings(this.siteId, this.stationId, `'${JSON.stringify(settings)}'`).subscribe(() => {
			this.mapInfo.updateStationAreaStyle(this.stationId, JSON.stringify(settings));
			this.closed.emit(true);
		})
	}

	cancelEditStationAreaStyle() {
		this.closed.emit(true);
	}

}

import { LatLng, Marker, Popup, popup } from 'leaflet';

import { FlowZone } from '../../api/flow-elements/models/flow-zone.model';
import { MapInfoLeaflet } from './map-info-leaflet.model';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';
import { Station } from '../../api/stations/models/station.model';
import { StationListItem } from '../../api/stations/models/station-list-item.model';
import { StationValveType } from '../../api/station-valve-types/station-valve-type.model';

export class AlertPopupLeaflet {

	get popup() {
		return this.encapsulatedPopup;
	}

	private readonly containerDiv: HTMLElement;

	private encapsulatedPopup: Popup;

	constructor(latlng: LatLng, contentDiv: HTMLElement) {

		this.encapsulatedPopup = popup({className: 'alert-popup', closeButton: false}).setLatLng(latlng);

		contentDiv.classList.add('map-alert-content');

		// Zero height div positioned at the bottom of the alert
		const anchor = document.createElement('div');
		anchor.classList.add('map-alert-popup-anchor');
		anchor.appendChild(contentDiv);

		// Zero-height div positioned at the bottom of the tip
		this.containerDiv = document.createElement('div');
		this.containerDiv.classList.add('map-alert-popup-container');
		this.containerDiv.appendChild(anchor);

		this.encapsulatedPopup.setContent(this.containerDiv);
	}

	remove() {
		this.encapsulatedPopup.remove();
	}
}

export class StationWithMapInfoLeaflet extends Station {
	classStatus = RbEnums.Common.EClassStatus.Default;
	isSelected = false;
	areaIndex = -1;
	areaId: number;
	holeId: number;
	holeIndex = -1;
	hasLocation = false;
	holeNumber: number;
	alertPopup: AlertPopupLeaflet;
	isStationRunning = false;
	irrigationStatus = RbEnums.Common.IrrigationStatus.Idle;
	status = '-';
	hoverTimer: number;
	error: string;
	inViewport = false;
	/** Whether the station has an adjustment applied (adjustment not equal to 100%) */
	hasAdjustments = false;
	/**
	 * This is the result of applying the temporary adjustment percent
	 * to the regular adjustment IF the temporary adjustment is active.
	 *
	 * If not, the resulting adjustment is equal to the regular adjustment.
	 *
	 * Example:
	 * 	regAdj = 200;
	 *  tempAdj = 50;
	 *  resultingAdjustment = (50% of 200%) = 100%;
	 */
	resultingAdjustment;
	secondsRemaining: number;
	runTimeRemaining: number;
	runTimeSoFar: number;
	nozzleColor?: string;
	catalogRotationTime?: number;
	isInMapView = false;
	programId = 0;
	canAdvance = false;
	isTempAdjustActive = false;
	monthlyCyclingTime: Date;	
	stationListItem?: StationListItem;
	stationValveType: StationValveType;
	flowZone: FlowZone;
	iqNetType: RbEnums.Common.IqNetType;
	statusText?: string;
	
	private _mapInfo: MapInfoLeaflet;
	private _marker: Marker;
	private _selected = false;
	private _visible = false;

	//#region Getters and setters

	public get mapInfo(): MapInfoLeaflet {
		return this._mapInfo;
	}

	public get mapStatus(): string {
		return this.stationListItem?.mapStatus || '';
	}

	public get marker(): Marker {
		return this._marker;
	}

	public set marker(value: Marker) {
		
		if (!value) {
			this._marker = null;
			return;
		}
		
		if (this._marker) {
			this._marker.remove();
			this._mapInfo.removeLayerFromMap(this._marker);
		}

		this._marker = value;
		this._marker.addTo(this._mapInfo.map);
		this._mapInfo.addLayerToMap(this._marker);

		if (!this._visible) {
			this.marker.getElement().classList.add('d-none');
			this.marker.closeTooltip();
		}
	}
	
	get selected() {
		return this._selected;
	}
	
	set selected(selected: boolean) {
		this._selected = selected;

	}

	public get visible(): boolean {
		return this._visible;
	}
	
	public set visible(value: boolean) {
		this._visible = value;

		if (this.marker) {
			if (this._visible) {
				// this.mapInfo.addLayerToMap(this.marker);
				this.marker.getElement()?.classList.remove('d-none');
				
			} else {
				// this.mapInfo.removeLayerFromMap(this.marker);
				this.marker.getElement()?.classList.add('d-none');
				
			}
		}
	}

	//#endregion

	constructor(stationInfo: any, mapInfo: MapInfoLeaflet) {
		super();
		Object.assign(this, stationInfo);
		this._mapInfo = mapInfo;
		RbUtils.Stations.checkIfHasAdjustment(this);
		this.mapInfo.createMarkerForStation(this);
	}

	/**
	 * Update the marker info (name and badges)
	 */
	updateMarkerInfo() {
		const markerElement = this.marker?.getElement();
		if (markerElement) {
			// Update marker name
			markerElement
				.querySelector('.station-name')
				.innerHTML = this.name;
			// Update marker badges
			markerElement
				.querySelector('.badges')
				.innerHTML = RbUtils.Stations.getBadges(this);
		}
	}

	updateRuntimeStatus() {
		const markerElement = this.marker?.getElement();
		if (markerElement) {
			const remainingTime = markerElement.querySelector('.remaining-time');
			const pie = markerElement.querySelector('.pie');

			switch (this.irrigationStatus) {
				case RbEnums.Common.IrrigationStatus.Running:
					if (this.runTimeRemaining - 1 >= 0) {
						this.runTimeSoFar = this.runTimeSoFar || 0;
						--this.runTimeRemaining;
						++this.runTimeSoFar;
						const timeString = RbUtils.Stations.secondsToHhMmSs(this.runTimeRemaining);
						const percentage = (this.runTimeSoFar / (this.runTimeRemaining + this.runTimeSoFar)) * 100;
						if (remainingTime) remainingTime.innerHTML = timeString;
						if (pie) pie.setAttribute('style', `--p: ${percentage}`);
					} else if (this.runTimeRemaining === undefined) {
						if (remainingTime) remainingTime.innerHTML = this.mapStatus;
						if (pie) pie.setAttribute('style', `--p: 0`);
					}
					break;

				case RbEnums.Common.IrrigationStatus.Soaking:
					if (remainingTime) remainingTime.innerHTML = this.mapStatus
					break;
			}

		}// Updating the runtime status of the station without markerElement
		else {
			switch (this.irrigationStatus) {
				case RbEnums.Common.IrrigationStatus.Running:
					if (this.runTimeRemaining - 1 >= 0) {
						this.runTimeSoFar = this.runTimeSoFar || 0;
						--this.runTimeRemaining;
						++this.runTimeSoFar;
					}
					break;
				case RbEnums.Common.IrrigationStatus.Soaking:
					break;
			}
		}
	}

	showName(visible: boolean) {
		const markerElement = this.marker?.getElement();
		if (markerElement) {
			const stationName = markerElement.querySelector('.station-name');
			if (visible) {
				stationName.classList.remove('dn');
				stationName.classList.add('db');
			} else {
				stationName.classList.remove('db');
				stationName.classList.add('dn');
			}
		}
	}

	showRuntime(visible: boolean) {
		const markerElement = this.marker?.getElement();
		if (markerElement) {
			const remainingTime = markerElement.querySelector('.remaining-time');
			if (remainingTime && this.irrigationStatus === RbEnums.Common.IrrigationStatus.Running) {
				if (visible) {
					remainingTime.classList.remove('dn');
					remainingTime.classList.add('db');
				} else {
					remainingTime.classList.remove('db');
					remainingTime.classList.add('dn');
				}
			}
		}
	}

	showAdjustment(visible: boolean) {
		const markerElement = this.marker?.getElement();
		if (markerElement) {
			if (visible) {
				markerElement.querySelector('.mdi.adjustment')?.classList.remove('dn');
			} else {
				markerElement.querySelector('.mdi.adjustment')?.classList.add('dn');
			}
		}
	}

	showNotes(visible: boolean) {
		const markerElement = this.marker?.getElement();
		if (markerElement) {
			if (visible) {
				markerElement.querySelector('i.note')?.classList.remove('dn');
			} else {
				markerElement.querySelector('i.note')?.classList.add('dn');
			}
		}
	}

	showNotesAnimation(visible: boolean) {
		const markerElement = this.marker?.getElement();
		if (markerElement) {
			if (visible) {
				markerElement.querySelector('i.note')?.classList.add('animate');
			} else {
				markerElement.querySelector('i.note')?.classList.remove('animate');
			}
		}
	}

	showNozzleColor(visible: boolean) {
		const markerElement = this.marker?.getElement();
		if (markerElement) {
			const innerCircle = markerElement.querySelector('.inner-circle');
			if (this.irrigationStatus === RbEnums.Common.IrrigationStatus.Idle ||
				this.irrigationStatus === RbEnums.Common.IrrigationStatus.Dash ||
				(this.irrigationStatus === RbEnums.Common.IrrigationStatus.Running &&
					!this.mapInfo.layerVisibility.showingIrrigation)) {
				if (visible) {
					innerCircle.classList.add('db');
					innerCircle.classList.remove('d-none');
				} else {
					innerCircle.classList.remove('db');
					innerCircle.classList.add('d-none');
				}
			}
		}
	}

	showCycleSoak(visible: boolean) {
		const markerElement = this.marker?.getElement();
		if (markerElement) {
			if (visible) {
				markerElement.querySelector('.mdi.cycle-soak')?.classList.remove('dn');
			} else {
				markerElement.querySelector('.mdi.cycle-soak')?.classList.add('dn');
			}
		}
	}
}

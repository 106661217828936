export class WeatherConditionIcon {

	constructor(bottomIcon: string, bottomIconColor: string, topIcon = null, topIconColor = null) {
		this.bottomIcon = bottomIcon;
		this.bottomIconColor = bottomIconColor;
		this.topIcon = topIcon;
		this.topIconColor = topIconColor;
	}

	bottomIcon: string;
	bottomIconColor: string;
	topIcon: string;
	topIconColor: string;

}

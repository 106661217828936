import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'rb-toolbar-export-menu',
	templateUrl: './toolbar-export-menu.component.html',
	styleUrls: ['./toolbar-export-menu.component.scss'],
})
export class ToolbarExportMenuComponent {

	@Input() showCSV = true;
	@Input() showPDF = true;
	@Input() showEmail = true;

	@Output() exportCSV = new EventEmitter();
	@Output() exportPdf = new EventEmitter();
	@Output() sendEmail = new EventEmitter();

	constructor() {}

  exportToCSV() {
		this.exportCSV.emit();
  }

  exportToPDF() {
		this.exportPdf.emit();
  }

  sendToEmail() {
		this.sendEmail.emit();
  }
	
}

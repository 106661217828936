/* eslint-disable @angular-eslint/directive-class-suffix */
import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CourseViewerComponent } from './course-viewer.component';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { filter } from 'rxjs/operators';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SelectedStationInfo } from './models/selected-station-info.model';
import { StationListItem } from '../../../api/stations/models/station-list-item.model';
import { TranslateService } from '@ngx-translate/core';

import CourseViewActivityType = RbEnums.Common.CourseViewActivityType;

@UntilDestroy()
@Directive()
export class CourseViewerStationBase implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('stationContainer') stationContainer: ElementRef;

	CourseViewActivityType = CourseViewActivityType;

	@Output() selectionChange = new EventEmitter<SelectedStationInfo>();

	@Input() parent: CourseViewerComponent;
	@Input() stationId: number;
	@Input() activityType: CourseViewActivityType;

	protected readonly NARROW_COL_WIDTH = 54;
	protected readonly LOW_BACKGROUND_COLOR: number[] = [248, 105, 107];
	protected readonly HIGH_BACKGROUND_COLOR: number[] = [90, 138, 198];

	station: StationListItem;
	isNarrowColumn: boolean;
	isSelected: boolean;
	tooltip: string = null;
	isStationRunnable = true;
	nonRunnableInnerHtml: string;

	// ========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// ========================================================================================================================================================

	constructor(protected deviceManager: DeviceManagerService,
				protected translateService: TranslateService
	) {

	}

	ngOnInit() {
		this.deviceManager.windowResize
			.pipe(untilDestroyed(this))
			.subscribe(() => this.checkComponentWidth());

		if (this.parent) {
			this.parent.clearSelectedStations
				.pipe(
					untilDestroyed(this),
					filter(() => this.isSelected)
				)
				.subscribe(() => this.isSelected = false);
		}
	}

	ngAfterViewInit() {
		setTimeout(() => this.checkComponentWidth());
	}

	ngOnDestroy() {
		/** Required by untilDestroyed */
	}

	// ========================================================================================================================================================
	// Protected Methods
	// ========================================================================================================================================================

	protected setRunnableState() {
		this.isStationRunnable = (this.station.isInterfaceConnected || this.station.isConnected) && !this.station.suspended;
		if (!this.isStationRunnable) {
			this.tooltip = this.translateService.instant(this.station.suspended
				? 'STRINGS.SUSPENDED'
				: !this.station.isInterfaceConnected ? 'STRINGS.INTERFACE_NOT_CONNECTED' : 'STRINGS.NOT_CONNECTED');
			this.nonRunnableInnerHtml = RbUtils.GridCellStyles.getStationConnected(this.station);
		}
	}

	// ========================================================================================================================================================
	// Event Handlers
	// ========================================================================================================================================================

	onToggleSelection() {
		this.isSelected = !this.isSelected;
		this.selectionChange.emit(new SelectedStationInfo(this.stationId, this.isSelected, this.station));
	}

	// ========================================================================================================================================================
	// Helper Methods
	// ========================================================================================================================================================

	private checkComponentWidth() {
		const isNarrowColumn = this.stationContainer.nativeElement.clientWidth < this.NARROW_COL_WIDTH;
		if (this.isNarrowColumn !== isNarrowColumn) { this.isNarrowColumn = isNarrowColumn; }
	}
}

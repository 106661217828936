import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { IStationFilter, StationApiService } from './station-api.service';
import { Observable, Subject } from 'rxjs';
import { StationExtensionData, StationListItem } from './models/station-list-item.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { AppInjector } from '../../core/core.module';
import { AuthManagerService } from '../auth/auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CommInterfaceManagerService } from '../comm-interfaces/comm-interface-manager.service';
import { ConnectDataPack } from '../connect-data-pack/models/connect-data-pack.model';
import { ConnectDataPackChange } from '../connect-data-pack/models/connect-data-pack-change.model';
import { ConnectDataPackManagerService } from '../connect-data-pack/connect-data-pack-manager.service';
import { ControllerManagerService } from '../controllers/controller-manager.service';
import { ControllerSyncState } from '../signalR/controller-sync-state.model';
import { GetStationQueryParams } from './models/get-station-params.model';
import { MasterValve } from './models/master-valve.model';
import { MasterValveListItem } from './models/master-valve-list-item.model';
import { ProgramStepManagerService } from '../program-steps/program-step-manager.service';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { Station } from './models/station.model';
import { StationForTableEdit } from './models/station-for-table-edit.model';
import { StationPriority } from './models/station-priority.model';
import { StationPrograms } from './models/station-programs.model';
import { StationsListChange } from './models/stations-list-change.model';
import { StationStatus } from './models/station-status.model';
import { StationStatusChange } from '../signalR/station-status-change.model';
import { SystemStatusService } from '../../common/services/system-status.service';
import { TranslateService } from '@ngx-translate/core';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class StationManagerService extends ServiceManagerBase implements OnDestroy {

	isGolfSite = false;
	// Subjects
	stationsListChange = new Subject<StationsListChange>();
	stationStatusUpdateCompleted = new Subject();
	stationChange = new Subject<StationListItem>();
	stationsChange = new Subject<StationListItem[]>();
	stationsAdded = new Subject();
	stationsDeleted = new Subject<number []>();
	stationsUpdated = new Subject<number[]>();
	masterValvesListChange = new Subject<MasterValveListItem[]>();
	stationsStatusesUpdateCompleted = new Subject<number>();

	sprinklerCategoryChoices = this.getSprinklerCategoryChoices();

	//RB-14409: localAdvancingStationsDict is used for storing advancing stations temporarily while waiting for actual signal R result.
	private localIrrigationQueueDict: {[controllerId: number]: {[stationId:number]: StationStatus}} = {};
	private localStoppingIrrigationQueueDict: {[controllerId: number]: boolean} = {};

	// Cache Containers (Expiring)
	private _apiResult: ApiCachedRequestResponse<StationListItem[]>;
	private _mvApiResult: ApiCachedRequestResponse<MasterValveListItem[]>;
	private _controllerSyncState = {}; // Dictionary of controller sync states by controller ID

	private lastGetStationQueryParams: GetStationQueryParams;

	private _controllerManager: ControllerManagerService = null;

	/**
	 * Get an instance of the ControllerManagerService. We use this only in a subset of situations so don't bother
	 * constructing it until/unless needed. Note that having this circular dependency (controller manager also
	 * depends on us), requires some injector use, rather than direct constructor mentions of the dependency.
	 */
	private get controllerManager(): ControllerManagerService {
		if (this._controllerManager != null) return this._controllerManager;

		// Create an instance of the manager and save it before returning.
		this._controllerManager = AppInjector.get(ControllerManagerService);

		return this._controllerManager;
	}

	// =========================================================================================================================================================
	// C'tor and Destroy
	// =========================================================================================================================================================

	constructor(private stationApiService: StationApiService,
				protected broadcastService: BroadcastService,
				private connectDataPackManager: ConnectDataPackManagerService,
				private programStepsManager: ProgramStepManagerService,
				private translate: TranslateService,
				private systemStatusService: SystemStatusService,
				private commInterfaceManager: CommInterfaceManagerService,
				private authManager: AuthManagerService
	) {

		super(broadcastService);

		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);

		this.connectDataPackManager.connectDataPacksChange
			.pipe(untilDestroyed(this))
			.subscribe((connectDataPackChange: ConnectDataPackChange) => {
				setTimeout(() => this.updateStationsStatuses(connectDataPackChange));
			});
		
		this.connectDataPackManager.cancelAllIrrigationForController
			.pipe(untilDestroyed(this))
			.subscribe((controllerInfo: {controllerId: number}) => {
				this.cancelLocalIrrigationQueue(controllerInfo.controllerId);
		});
		this.systemStatusService.stationStatusChange
			.pipe(
				untilDestroyed(this)
			)
			.subscribe((stationStatusChange: StationStatusChange) => {
				let cacheStale = this.updateStationStatuses(stationStatusChange);
				cacheStale = cacheStale || this.updateMasterValveList(stationStatusChange);

				// RB-12148: Reload the list if the cache is stale. This will only occur once per edit (including batch
				// edit of several stations). If needed we could use an observable and debounce reloading the list. For
				// now this seems OK as-is.
				if (cacheStale && this.isGolfSite) {
					this.loadStationsList(true);
				}
			});

		this.systemStatusService.stationStatusUpdateCompleted
			.pipe(untilDestroyed(this)).subscribe(() => this.stationStatusUpdateCompleted.next(null));

		this.broadcastService.syncStateChange
			.pipe(
				untilDestroyed(this),
				filter((syncState: ControllerSyncState) => syncState.syncState === RbEnums.Common.ControllerSyncState.Synchronized &&
					this._controllerSyncState[syncState.controllerId] !== syncState.syncState)
			)
			.subscribe((syncState: ControllerSyncState) => {
				this._controllerSyncState[syncState.controllerId] = syncState.syncState;
				this.stationsStatusesUpdateCompleted.next(syncState.controllerId);
				this.getStationsList(syncState.controllerId, true).subscribe(stations => {
					this.stationsListChange.next(new StationsListChange(syncState.controllerId, stations));
				});
			});

		this.broadcastService.controllerCollectionChange
			.pipe(untilDestroyed(this))
			.subscribe(change => {
				if (!change.isStatusUpdate) {
					this.clearCache();
				}
			});

		this.broadcastService.controllerRestored.pipe(untilDestroyed(this)).subscribe(() => this.clearCache());

		// this.broadcastService.controllerUpdated.pipe(untilDestroyed(this)).subscribe((change: ControllerChange) => {
		// There is some data (Monthly Cycling) that does not come back via station changed; rather it comes back in ControllerChange and we need to ditch cache
		// if (change.changeType === RbEnums.SignalR.JobType.Updated &&
		// 	change.itemsChanged != null && change.itemsChanged.patch != null && change.itemsChanged.patch.monthlyCyclingTime != null) {
		// 	this._mvApiResult = null;
		// 	this.stationApiService.clearMasterValveCache();
		// 	this.masterValvesListChange.next(null);
		// }
		// });

		// Since we localize values within the data, we must clear the cache when the app language is changed.
		this.broadcastService.cultureChanged
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				const reloadStationList = this._apiResult != null;
				this.clearCache();
				if (reloadStationList && this.isGolfSite) {
					this.loadStationsList(true);
				}
			});

		this.broadcastService.irrigationCancelled
			.pipe(
				untilDestroyed(this),
			)
			.subscribe(() => {
				this.updateStationsAfterCancel();
			});

		this.broadcastService.unlockAll
			.pipe(
				untilDestroyed(this),
			)
			.subscribe(() => {
				this.unlockAllStations();
			});

		// clear cache and reload the station when communication interface is changing.
		this.commInterfaceManager.commInterfaceModeChanged
			.pipe(untilDestroyed(this))
			.subscribe((controllerId) => {
				const reloadStationList = this._apiResult != null;
				this.clearCache();
				if (reloadStationList) {
					this.getStationsList(controllerId, true).pipe(take(1)).subscribe(stations => {
						// notify any page showing stations that stations has been reloaded in the manager.
						this.stationsChange.next(stations);
					});
				}
			});
		this.broadcastService.connectionCompleted
			.pipe(untilDestroyed(this))
			.subscribe((interfaceId) => {
				this.updateStationInterfaceConnection(interfaceId, true);
			});
		this.broadcastService.connectionFailed
			.pipe(untilDestroyed(this))
			.subscribe((interfaceId) => {
				this.updateStationInterfaceConnection(interfaceId, false);
			});
		
		// clean station area from cache when moving controller
		if (!this.isGolfSite) {
			this.broadcastService.controllerMoved
				.pipe(untilDestroyed(this))
				.subscribe((controllerId) => {
					this.cleanStationAreaAfterMovingSite(controllerId);
				});
			
			this.systemStatusService.stationCacheShouldBeUpdated
				.pipe(
					untilDestroyed(this)
				)
				.subscribe((stationStatusChange: StationStatusChange) => {
					if (stationStatusChange?.stationId) {
						switch(stationStatusChange.changeType) {
							case RbEnums.SignalR.StationStatusChangeType.Added:
							case RbEnums.SignalR.StationStatusChangeType.Updated:
								if (stationStatusChange.changeType === RbEnums.SignalR.StationStatusChangeType.Updated && 
									!stationStatusChange.itemsChanged) {
									break;
								}
								this.stationApiService.getStationListItem(stationStatusChange.stationId, true)
									.pipe(take(1))
									.subscribe(x => {
										this.stationApiService.updateStationCache(x.value);
									});
								break;
							case RbEnums.SignalR.StationStatusChangeType.Deleted:
								this.stationApiService.removeStationFromCache( new StationListItem({id : stationStatusChange.stationId}));
								break;
							default:
								return;
						}
					}
				});
		}
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	clearCache() {
		this.stationApiService.clearCache();
		this._apiResult = null;
		this._mvApiResult = null;
		this._controllerSyncState = {};
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	createMultiStationsInAreas(formValues: {
		controllerId: number;
		stationCount: number;
		holeIds: number[];
		areaId: number,
		subAreaId: number,
		wirepath: number,
	}) {
		return this.stationApiService.createMultiStationsInAreas(formValues).pipe(tap(() => this.stationsAdded.next(null)));
	}

	createMultiStations(stationCount: number, areaIds: number[], body: any) {
		return this.stationApiService.createMultiStations(stationCount, areaIds, body).pipe(tap(() => this.stationsAdded.next(null)));
	}

	deleteStations(stationIds: number[]): Observable<void> {
		return this.stationApiService.deleteStations(stationIds).pipe(tap(() => {
			this.stationsDeleted.next(stationIds);
			this.programStepsManager.programsOrProgramGroupsUpdated(null);
		}));
	}

	getAddressUniqueness(address: string, id: number, controllerId: number, groupNumber: number): Observable<UniquenessResponse> {
		return this.stationApiService.getAddressUniqueness(address, id, controllerId, groupNumber);
	}

	getCommercialPriorities(): Observable<StationPriority[]> {
		return this.getPriorities().pipe(map(priorities => priorities.filter(p => p.value <= 3)));
	}

	getGolfPriorities(): Observable<StationPriority[]> {
		return this.getPriorities();
	}

	getMasterValve(controllerId: number, masterValveId: number) {
		return this.getMasterValves(controllerId).pipe(map(list => list.find(mv => mv.id === masterValveId)));
	}

	getStationbyFlowZoneId(flowZoneId: number) {
		return this.stationApiService.getStationbyFlowZoneId(flowZoneId);
	}

	getMasterValvesList(controllerId: number = null, bypassCache = false): Observable<MasterValveListItem[]> {
		if (controllerId) {
			return this.stationApiService.getMasterValvesListBySatelliteId(controllerId, bypassCache).pipe(map(result => {
				if (!this._mvApiResult) {
					this._mvApiResult = result;
				} else {
					result.value?.forEach(element => {
						if (this._mvApiResult.value?.filter(x => x.id == element.id).length == 0) {
							this._mvApiResult.value.push(element);
						}
					});
				}

				return result.value;
			}));
		}

		return this.stationApiService.getMasterValvesList(bypassCache).pipe(map(result => {
			this._mvApiResult = result;
			return controllerId ? result.value.filter(mv => mv.satelliteId === controllerId) : result.value;
		}));
	}

	getExtDataForProgamSummary(controllerId: number) {
		return this.stationApiService.getExtDataForProgamSummary(controllerId).pipe(
			map((listData: any[]) => listData.map(d => new StationExtensionData(d)))
		);
	}

	isInterfaceIrrigating(interfaceId: number) {
		if (this._apiResult)
			return this._apiResult.value.filter(item => item.satelliteId === interfaceId && item.isIrrigating_Golf).length > 0;
		else
			return false;
	}

	/**
	 * RB-8556: Return true if some stations are irrigating or, if stations are specified, if the selected stations are irrigating.
	 * We use the station list collection to check the status.
	 * @param stationIds - Array<number> containing the station Ids we're checking, or null to check for any stations
	 * irrigating.
	 */
	areStationsIrrigating(stationIds?: Array<number>): boolean {
		if (this._apiResult)
			return this._apiResult.value.filter(item => item.isIrrigating_Golf &&
				(stationIds == null || stationIds.includes(item.id))).length > 0;
		else
			return false;
	}

	getMasterValveListItem(masterValveId: number) {
		return this.stationApiService.getMasterValveListItem(masterValveId);
	}

	getMasterValves(controllerId: number): Observable<MasterValve[]> {
		return this.stationApiService.getMasterValves(controllerId);
	}

	getSharedMasterValves(controllerId: number): Observable<MasterValve[]> {
		return this.stationApiService.getSharedMasterValves(controllerId);
	}

	getStation(id: number, queryParams?: GetStationQueryParams, bypassCache = false): Observable<Station> {
		const selectedQueryParams = queryParams ? queryParams : this.lastGetStationQueryParams;
		return this.stationApiService.getStation(id, selectedQueryParams, bypassCache);
	}

	/**
	 * Return the stations where each station is tagged with all the specified areaIds. NOTE: DO NOT USE THIS
	 * METHOD TO LIMIT GOLF STATIONS BY COURSE! The siteIds list, if provided is checked against the sites
	 * associated with the station satellites (interfaces), NOT THE SITE WHERE THE STATIONS ARE ACTUALLY
	 * LOCATED.
	 */
	getStationsByAreasAndSites(areaIds?: number[], siteIds?: number[]): Observable<Station[]> {
		return this.stationApiService.getStationsByAreasAndSites(areaIds, siteIds);
	}

	getStationsByAreas(areaIds?: number[], siteId?: number): Observable<Station[]> {
		return this.stationApiService.getStationsByAreas(areaIds, siteId);
	}

	getStationsListBySiteId(siteId: number, bypassCache = false): Observable<StationListItem[]> {
		if (this.isGolfSite){
			return this.loadStationsList(bypassCache).pipe(map(list => list.filter(s => s.siteId === siteId)));
		} else{
			return this.stationApiService.getStationsBySiteIdCommercial(siteId, bypassCache).pipe(map(x => {
				const stationListItems = x.value;
				stationListItems.forEach(stationListItem => {
					this.updateStationsStatusesOnFetch(stationListItem.satelliteId, [stationListItem]);
				});
				return x.value
			}));
		}
	}

	/**
	 * This was a private function before, but we needed a way to get all stations faster; alternatively to getAllStations() which is
	 * pretty expensive, this method retrieves data from cache if available, but the disadventage could be that there's no IStationFilter
	 * implemented for this when requesting info, therefore, we can't include satellites, sub areas, etc. As the other function does.
	 * So use this when you don't need a bunch of data inside the station but just all the stations itself.
	 */
	loadStationsList(bypassCache: boolean): Observable<StationListItem[]> {
		return this.stationApiService.getStationsList(bypassCache)
			.pipe(map(result => {
				this._apiResult = result;
				return this._apiResult.value;
			}));
	}

	getStationsListBySiteHolesAndHoleSections(siteId: number, holeIds?: number[], holeSectionIds?: number[], bypassCache = false)
		: Observable<StationListItem[]> {
		if (this.isGolfSite){
			return this.loadStationsList(bypassCache)
			.pipe(map(list => this.getStationsByHolesAndHoleSections(list.filter(s => s.siteId === siteId), holeIds, holeSectionIds)));
		} else{ // this path is called by IQ4 MAP
			return this.getStationsListBySiteId(siteId, bypassCache)
			.pipe(map(list => this.getStationsByHolesAndHoleSections(list, holeIds, holeSectionIds)));
		}
	}

	getStationsListByHolesAndHoleSections(holeIds?: number[], holeSectionIds?: number[], bypassCache = false)
		: Observable<StationListItem[]> {
			return this.loadStationsList(bypassCache)
			.pipe(map(list => this.getStationsByHolesAndHoleSections(list, holeIds, holeSectionIds)));
	}

	getStationsList(controllerId?: number, bypassCache = false): Observable<StationListItem[]> {
		if (controllerId && !this.isGolfSite) {
			return this.stationApiService.getStationsListForSatellite(controllerId, bypassCache)
			.pipe(map((list) => {
				if (!this._apiResult) {
					this._apiResult = list;
				}
				
				list.value.forEach(element => {
					if (this._apiResult.value.filter(x => x.id == element.id).length == 0) {
						this._apiResult.value.push(element);
					}
				});

				const controllerList = this._apiResult.value.filter(s => s.satelliteId === controllerId);
				this.updateStationsStatusesOnFetch(controllerId, controllerList);
				return controllerList;
				})
			);
		}

		return this.loadStationsList(bypassCache)
			.pipe(map(list => {
					if (controllerId) {
						const controllerList = list.filter(s => s.satelliteId === controllerId);
						this.updateStationsStatusesOnFetch(controllerId, controllerList);
						return controllerList;
					} else {
						return list;
					}

				})
			);
	}

	getStationsListByControllerIds(siteId: number, controllerIds: number[], bypassCache = false): Observable<StationListItem[]> {
		return this.getStationsListBySiteId(siteId, bypassCache)
			.pipe(map(list => {
				if (controllerIds && controllerIds.length > 0) {
					list = list.filter(s=> controllerIds.includes(s.satelliteId));
				}
				if (list != null && list.length > 0) {
					this.updateGolfStationsStatusesOnFetch(list);
				}
				return list;
			}));
	}

	getStationsListForSatellite(controllerId: number, bypassCache: boolean = false): Observable<StationListItem[]> {
		return this.stationApiService.getStationsListForSatellite(controllerId ,bypassCache).pipe(
			map((list) => {
				return list.value;
			})
		);
	}

	getStationsListByWirePath(controllerId: number, wireGroup?: number, bypassCache = false): Observable<StationListItem[]> {
		return this.stationApiService.getStationsListForSatellite(controllerId ,bypassCache)
			.pipe(map((list) => {
				    let stationList = list.value.filter(s => s.satelliteId === controllerId);
					if (wireGroup) {
						stationList = stationList.filter(s => s.groupNumber === wireGroup)
					}
					if (stationList != null && stationList.length > 0) {
						this.updateGolfStationsStatusesOnFetch(stationList);
					}
					return stationList;
				})
			);
	}

	/**
	 * Return all the stations for the current company. This might be a huge number for commercial, so this
	 * method is mostly limited to golf sites, where you want all the stations and don't want to use
	 * getStationsByAreasAndSites() because it filters by site using station.Satellite.Site, not correct
	 * for golf.
	 * @param includeSatellite - should be set to true to return the Satellite navigation property value for each
	 * returned Station; false returns no Satellite value.
	 * @param includeProgramSteps - should be set to true to return the RunStationStep navigation property for each
	 * Station (list of RunStationSteps); false returns no program step information.
	 * @param includeStationAreas - when set instructs the code to return the StationArea navigation property
	 * *and* the corresponding Area for each StationArea entry.
	 * @param includeRBCatalog_Nozzle - when set, return the catalog data for the station (nozzle, etc.)
	 * @param sortByTerminal - should be set to true to sort the Stations by Terminal number. Set to null/false to
	 * skip this sort. If both SortByTerminal and SortByName are set to true, SortByTerminal wins.
	 * @param sortByName - should be set to true to sort the returned Stations by Name property. Set to null/false
	 * to skip name sorting. If both SortByTerminal and SortByName are set to true, SortByTerminal wins.
	 * @param visibleStationsOnly - optional method of demanding not the complete Commercial controller station
	 * list, but only those stations which are actually present, given the installed modules. The default is
	 * 'false', returning all stations.
	 */
	getAllStations(stationFilter?: IStationFilter): Observable<Station[]> {
		return this.stationApiService.getAllStations(stationFilter);
	}

	isFlowRateSet(controllerId: number): Observable<boolean> {
		return this.stationApiService.isFlowRateSet(controllerId);
	}

	getStationsNotInProgram(controllerId: number, programId: number): Observable<Station[]> {
		return this.stationApiService.getStationsNotInProgram(controllerId, programId);
	}

	getStationPrograms(stationIds: number[]): Observable<StationPrograms[]> {
		return this.stationApiService.getStationPrograms(stationIds);
	}

	getStations(controllerId: number, visibleStationsOnly = false): Observable<Station[]> {
		return this.stationApiService.getStations(controllerId, visibleStationsOnly);
	}

	getStationsForTableEditByIds(stationIds: number[]): Observable<StationForTableEdit[]> {
		return this.stationApiService.getStationsForTableEditByIds(stationIds);
	}

	getStationNameUniqueness(name: string, id: number, controllerId: number): Observable<UniquenessResponse> {
		return this.stationApiService.getValveNameUniqueness(name, id, controllerId);
	}

	getValueTypes(): any[] {
		return [
			{ key: RbEnums.Common.StationType.NormallyClosed, value: this.translate.instant('STRINGS.STATION_TYPE_NORMALLY_CLOSED') },
			{ key: RbEnums.Common.StationType.NormallyOpen, value: this.translate.instant('STRINGS.STATION_TYPE_NORMALLY_OPEN') },
			{ key: RbEnums.Common.StationType.Unused, value: this.translate.instant('STRINGS.STATION_TYPE_NORMALLY_UNUSED') },
		];
	}

	getMVOperationTypes(): any[] {
		return [
			{ key: RbEnums.Common.OperationType.MasterValve, value: this.translate.instant('STRINGS.MASTER_VALVES_OPERATION_TYPE_MV') },
			{ key: RbEnums.Common.OperationType.Unused, value: this.translate.instant('STRINGS.MASTER_VALVES_OPERATION_TYPE_UNUSED') },
		];
	}

	getPumpOperationTypes(): any[] {
		return [
			{ key: RbEnums.Common.OperationType.Pump, value: this.translate.instant('STRINGS.MASTER_VALVES_OPERATION_TYPE_PUMP') },
			{ key: RbEnums.Common.OperationType.Unused, value: this.translate.instant('STRINGS.MASTER_VALVES_OPERATION_TYPE_UNUSED') },
		];
	}

	getMVPumpUsedOptions(): any[] {
		return [
			{ key: RbEnums.Common.MVPumpOptions.Yes, value: this.translate.instant('STRINGS.YES') },
			{ key: RbEnums.Common.MVPumpOptions.No, value: this.translate.instant('STRINGS.NO') },
		];
	}

	getRequiredMVOptions(): any[] {
		return [
			{ id: RbEnums.Common.RequiredMVOptions.None, name: this.translate.instant('STRINGS.NONE') },
			{ id: RbEnums.Common.RequiredMVOptions.MV1, name: this.translate.instant('STRINGS.MV_1') },
			{ id: RbEnums.Common.RequiredMVOptions.MV2, name: this.translate.instant('STRINGS.MV_2') },
			{ id: RbEnums.Common.RequiredMVOptions.MV1And2, name: this.translate.instant('STRINGS.MV_1_AND_2') },
		];
	}

	getSprinklerCategoryChoices(): any[] {
		return [
			{ value: RbEnums.Common.SprinklerCategoryType.Rotors,
				name: this.getSprinklerCatString(RbEnums.Common.SprinklerCategoryType.Rotors) },
			{ value: RbEnums.Common.SprinklerCategoryType.ImpactSprinklers,
				name: this.getSprinklerCatString(RbEnums.Common.SprinklerCategoryType.ImpactSprinklers) },
			{ value: RbEnums.Common.SprinklerCategoryType.SprayHeads,
				name: this.getSprinklerCatString(RbEnums.Common.SprinklerCategoryType.SprayHeads) },
			{ value: RbEnums.Common.SprinklerCategoryType.RotaryNozzles,
				name: this.getSprinklerCatString(RbEnums.Common.SprinklerCategoryType.RotaryNozzles) },
			{ value: RbEnums.Common.SprinklerCategoryType.DripEmitter,
				name: this.getSprinklerCatString(RbEnums.Common.SprinklerCategoryType.DripEmitter) },
			{ value: RbEnums.Common.SprinklerCategoryType.DripInLine,
				name: this.getSprinklerCatString(RbEnums.Common.SprinklerCategoryType.DripInLine) },
			{ value: RbEnums.Common.SprinklerCategoryType.Bubblers,
				name: this.getSprinklerCatString(RbEnums.Common.SprinklerCategoryType.Bubblers) },
		];
	}

	getOperationType(masterValve: MasterValveListItem): any {
		if (masterValve.valveType > 2) {
			return RbEnums.Common.OperationType.Unused;
		} else if (masterValve.pump)
			return RbEnums.Common.OperationType.Pump;
		else
			return RbEnums.Common.OperationType.MasterValve;
	}

	updateMasterValves(masterValveIds: number[], masterValveData: any): Observable<null> {
		return this.stationApiService.updateMasterValves(masterValveIds, masterValveData);
	}

	/**
	 * Update the master valve(s) indicated in the StationStatusChange based on the change identified ('added',
	 * 'deleted', 'updated', etc.)
	 * @param change - StationStatusChange describing the change
	 * @returns boolean true if the cache should be marked stale as a result of the change; false otherwise.
	 */
	private updateMasterValveList(change: StationStatusChange): boolean {
		if (!this._mvApiResult) return false;

		let cacheStale = false;
		let valves = this._mvApiResult.value;

		// Update the list item
		const listItems = change.changeType === RbEnums.SignalR.StationStatusChangeType.BatchUpdated
			? valves.filter(s => change.itemsChanged.ids.some(id => id === s.id))
			: valves.filter(s => s.id === change.stationId);
		if (listItems.length === 0) return;

		const updatedObj = RbUtils.Common.getUpdateObjectFromItemsChanged(change.itemsChanged);
		if (updatedObj.flowZone != null) {
			delete updatedObj['flowZone'];
		}

		// Don't have sufficient data in the status change (or the update data is incompatible) to update our list - flush the cache
		if (updatedObj.flowZoneId != null) {
			this._mvApiResult = null;
			// valves also need to set to NULL same as _mvApiResult
			valves = null;
			this.stationApiService.clearMasterValveCache();
		}

		// RB-12148: Allow for master valves moving from satellite to satellite in golf/hybrid scenarios.
		if (updatedObj.satelliteId != null) {
			cacheStale = true;
		}

		listItems.forEach(listItem => {
			Object.assign(listItem, updatedObj);
		});
		this.masterValvesListChange.next(valves);

		return cacheStale;
	}

	private cleanStationAreaAfterMovingSite(controllerId: number) {
		if (!this._apiResult) {
			return;
		}
		const stations = this._apiResult.value.filter(x => x.satelliteId == controllerId);
		if (stations?.length) {
			stations.forEach(st => {
				st.areaLevel3Name = null;
				st.areaLevel2Name = null;
				st.areaLevel2Id = null;
				st.areaLevel3Id = null;
			});
			// reload stations of the moved controller
			this.getStationsList(controllerId, true).pipe(take(1)).subscribe();
		}
	}

	// Update the master valves and don't return until the list is reloaded with the latest data.
	updateMultipleMasterValves(flowMonitoringId: number, masterValveStates: any): Observable<any> {
		return this.stationApiService.updateMultipleMasterValves(flowMonitoringId, masterValveStates)
			.pipe(
				switchMap(() => this.getMasterValvesList(null, true).pipe(map(() => null))));
	}

	updateStations(stationIds: number[], updateData: any): Observable<null> {
		return this.stationApiService.updateStations(stationIds, updateData)
			.pipe(tap(() => {
				this.stationsUpdated.next(stationIds);
			}));
	}
	
	updateMultipleStations(stationUpdates: {ids: number[]; patch: any;}[], stationIds: number[]) : Observable<[number[], any]>{
		return this.stationApiService.updateMultipleStations(stationUpdates)
		.pipe(tap(() => {
			this.stationsUpdated.next(stationIds);
		}));
	}

	reConnectStation(stationIds: number[]): Observable<null> {
		return this.stationApiService.reConnectStation(stationIds).pipe(tap(() => {
			this.programStepsManager.programsOrProgramGroupsUpdated(null);
		}));
	}

	getSprinklerCatString(type: RbEnums.Common.SprinklerCategoryType): string {
		if (type == null) { return ''; }
		switch (type) {
			case RbEnums.Common.SprinklerCategoryType.Rotors:
				return RbUtils.Translate.instant('STRINGS.ROTOR');
			case RbEnums.Common.SprinklerCategoryType.SprayHeads:
				return RbUtils.Translate.instant('STRINGS.SPRAY_HEADS');
			case RbEnums.Common.SprinklerCategoryType.DripInLine:
				return RbUtils.Translate.instant('STRINGS.DRIP_IN_LINE');
			case RbEnums.Common.SprinklerCategoryType.DripEmitter:
				return RbUtils.Translate.instant('STRINGS.DRIP_EMITTER');
			case RbEnums.Common.SprinklerCategoryType.Bubblers:
				return RbUtils.Translate.instant('STRINGS.SPRINKLER_CATEGORY_BUBBLERS');
			case RbEnums.Common.SprinklerCategoryType.ImpactSprinklers:
				return RbUtils.Translate.instant('STRINGS.SPRINKLER_CATEGORY_IMPACT_SPRINKLERS');
			case RbEnums.Common.SprinklerCategoryType.RotaryNozzles:
				return RbUtils.Translate.instant('STRINGS.SPRINKLER_CATEGORY_ROTARY_NOZZLES');
		}
	}

	moveStations(stationIds: number[], holeId: number, areaId: number, subAreaId: number, applyAreaDefaults: boolean, applyNewDefaultNames: boolean) {
		return this.stationApiService.moveStations(stationIds, holeId, areaId, subAreaId, applyAreaDefaults, applyNewDefaultNames);
	}	
	
	/**
	 * Checks if any of the selected stations will run in a program in the next (time in minutes)
	 * 
	 * @param stationIds array of stations ids
	 * @param minutes time in minutes, e.g. 1 = minute not 60
	 * @returns [{ stationId: number, stationName: string, startTimes: number[] }]
	 */
	 getNextScheduledIrrigation(stationIds: number[], minutes: number) {
		return this.stationApiService.getNextScheduledIrrigation(stationIds, minutes);
	}

	/**
	 * Checks if the destination Satellite has enough space for the stations to be moved
	 * 
	 * @param stationIds array of stations ids
	 * @param destinationSatelliteId the destination satellite to move selected stations
	 * @param destinationGroupNumber the destination satellite to move selected stations, NOTE this is required 
	 * if moving to an ICI, but for other satellites might be null
	 * @returns true | false: depends on whether is OK to move.
	 */
	isOKToMove(stationIds: number[], destinationSatelliteId: number, destinationGroupNumber?: number) {
		return this.stationApiService.isOKToMove(stationIds, destinationSatelliteId, destinationGroupNumber);
	}

	/**
	 * Move the indicated list of stations to a specified new parent satellite. The existing stations must:
	 * 1) be on the same source satellite. That is, you cannot move stations 1, 2, 3 from satellite 1 
	 * AND stations 1, 2, 3 from satellite 2 to satellite 3.
	 * 2) stations must be golf stations and both satellites must have flexible station lists (normal for golf),
	 * 
	 * @param stationIds array of stations ids
	 * @param destinationSatelliteId the destination satellite to move selected stations
	 * @param destinationWireGroup the destination satellite to move selected stations, NOTE this is required 
	 * if moving to an ICI, but for other satellites might be null
	 * @returns null if the operation was successful, returns error message on fail
	 */
	moveStationsToSatellite(stationIds: number[], destinationSatelliteId: number, destinationGroupNumber?: number) {
		return this.stationApiService.moveStationsToSatellite(stationIds, destinationSatelliteId, destinationGroupNumber);
	}

	/**
	 * For Golf
	 * @param siteId 
	 * @param holeId 
	 * @param areaId 
	 * @param patchObject 
	 * @returns 
	 */
	reorderStations(siteId: number, holeId: number, areaId: number, patchObject: any) {
		return this.stationApiService.reorderStations(siteId, holeId, areaId, patchObject).pipe(tap(() => {
			if (this._apiResult != null) {
				// update name
				const stations = [];
				patchObject.stations.forEach(sta => {
					const station = this._apiResult.value.find(s => s.id === sta.stationId);
					if (station == null) return;
					station['name'] = sta.name;
					stations.push(station);
				});
				patchObject.stationAreas.forEach(sta => {
					const station = this._apiResult.value.find(s => s.id === sta.stationId);
					if (station == null) return;
					station['areaLevel3Number'] = sta.number;
					if (!stations.find(s => s.id === sta.stationId)) stations.push(station);
				});
				if (stations.length > 0) this.stationsChange.next(stations);
			}
		}));
	}

	masterValveListInvalid() {
		this._mvApiResult = null;
		this.stationApiService.clearMasterValveCache();
		this.masterValvesListChange.next(null);
	}

	updateGolfStationsStatusesOnFetch(stations: StationListItem[]) {
		stations.forEach(station => {
			if (station.isLocked) {
				station.status = RbUtils.Translate.instant('STRINGS.LOCKED');
				station.courseViewStatus = RbUtils.Translate.instant('STRINGS.LOCKED');
			} else {
				const golfStationStatus = this.systemStatusService.getGolfStationStatus(station.id);
				// If the station has SignalR change, format the status to display the friendly message in UI
				// Otherwise, set status to "-" if not present
				if (golfStationStatus) {
					station.setStationStatus(
						RbUtils.Stations.getStationStatusFromStatusChange(golfStationStatus,
							station.master, station.priority === RbEnums.Common.Priority.NonIrrigation));
				} else if (!station.status) {
					station.status = '-';
					station.courseViewStatus = '';
				}
			}
		});
	}

// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private updateStationsAfterCancel() {
		if (!this._apiResult) return;

		let controllerId = 0;

		this._apiResult.value.forEach(station => {
			station.status = '-';
			station.courseViewStatus = '';
			controllerId = station.satelliteId;
		});

		this.stationsStatusesUpdateCompleted.next(controllerId);
		this.stationsListChange.next(new StationsListChange(controllerId, this._apiResult.value));
	}

	private getPriorities(): Observable<StationPriority[]> {
		return this.stationApiService.getPriorities().pipe(map(priorities => priorities.sort((a, b) => (b.value - a.value))));
	}

	/**
	 * CirrusIC only
	 * Update all stations connected to the indicated interface, marking them with interfaceConnected = connected.
	 * NOTE: For satellite based stations, this also drills down into child satellites.
	 * @param interfaceId - ID of the interface (MIM, ICI, etc.) whose connection state is changing. Note that, if
	 * this method is called recursively for child satellites this could also be the ID of the child satellite
	 * @param connected - boolean true if the interface is now connected; false if disconnected
	 */
	private updateStationInterfaceConnection(interfaceId: number, connected: boolean) {
		if (!this._apiResult) return;

		// Get the satellites based on the interfaceId provided. That is, for an ICI, we get only the ICI.
		// For a MIM we get the MIM and all of its child satellites.
		this.controllerManager.getControllersList(false)
			.subscribe(controllersList => {
				// Get the indicated interface and all of its child satellites (if any; an ICI has none,
				// unless maybe we're running in hybrid satellite/ICM mode, possibly).
				const childSatelliteIds = controllersList
					.filter(c => c.parentId === interfaceId)
					.map(c => c.id);

				const stations = (this._apiResult.value).filter(station =>
					station.satelliteId === interfaceId ||
					childSatelliteIds.includes(station.satelliteId));

				stations.forEach(s => {
					s.isInterfaceConnected = connected;
				});
			});
	}

	updateLocalStationStatusDict(stationStatus: StationStatus[], controllerId: number) {
		if (this.isGolfSite) {
			return;
		}
		if(!this.localIrrigationQueueDict[controllerId]) {
			this.localIrrigationQueueDict[controllerId] = {};
		}

		stationStatus.forEach(item => {
			this.localIrrigationQueueDict[controllerId][item.stationId] = {
					...item, 
					status: RbUtils.Stations.secondsToFriendlyString(item.secondsRemaining),
					irrigationStatus: item.irrigationStatus,
					stationId: item.stationId

				}
			});
		this.stationsStatusesUpdateCompleted.next(controllerId);
	}

	updateStationsBySiteCache(stationListItem: StationListItem[], siteId: number) {
		this.stationApiService.updateStationsBySiteCache(stationListItem, siteId);
	}

	cancelLocalIrrigationQueue(controllerId: number) {
		if (this.isGolfSite) {
			return;
		}
		if (!this.localStoppingIrrigationQueueDict[controllerId]) {
			this.localStoppingIrrigationQueueDict[controllerId] = true;
			this.stationsStatusesUpdateCompleted.next(controllerId);
		}
		
	}

	private updateStationStatusBasedOnLocal(stationListItem: StationListItem, stationStatus: StationStatus){
		// RB-14409: This will update station status based on localIrrigationQueueDict. When users conduct an action (Run, Advance), 
		// we will set its temporary status while waiting for signalR result 
		if (this.isGolfSite || !stationListItem ) {
			return;
		}

		if (this.localStoppingIrrigationQueueDict[stationListItem.satelliteId]) {
			// This controller is stopping, don't update other statuses.
			if (RbEnums.Common.IrrigationStatus.Stopping === stationStatus.irrigationStatus)  {
			stationStatus.status = `${RbUtils.Translate.instant('STRINGS.STOPPING')}`;
			stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Stopping;
			}
			return;
		}

		const stationId = stationListItem.stationId;
		const localControllerIrrigationDict = this.localIrrigationQueueDict?.[stationListItem.satelliteId];
		const localStationStatus = localControllerIrrigationDict?.[stationListItem.stationId];
		if (localControllerIrrigationDict && localStationStatus) {
			switch(localStationStatus.irrigationStatus) {
				case RbEnums.Common.IrrigationStatus.ReadyToRun:
					if ([RbEnums.Common.IrrigationStatus.Pending, RbEnums.Common.IrrigationStatus.Idle].includes(stationStatus.irrigationStatus)) {
						stationStatus.status = localStationStatus.status;
						stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.ReadyToRun;
					} else {
						delete localControllerIrrigationDict[stationListItem.stationId];
					}
					break;
				case RbEnums.Common.IrrigationStatus.Advancing:
					if (stationStatus.irrigationStatus === RbEnums.Common.IrrigationStatus.Running) {
						stationStatus.status = `${RbUtils.Translate.instant('STRINGS.ADVANCING')}`;
						stationStatus.irrigationStatus = RbEnums.Common.IrrigationStatus.Advancing;
					} else {
						delete localControllerIrrigationDict[stationId];
					}
					break;
				default:
					delete localControllerIrrigationDict[stationId];
					break;
	
			}
		}
	}

	private updateStationsStatuses(connectDataPackChange: ConnectDataPackChange) {
		if (!this._apiResult) return;

		const stations = (this._apiResult.value).filter(station => station.satelliteId === connectDataPackChange.controllerId);
		const controllerDataPack = connectDataPackChange.connectDataPacks[connectDataPackChange.controllerId];

		if (!controllerDataPack?.irrigationQueue?.length && this.localStoppingIrrigationQueueDict[connectDataPackChange.controllerId]) {
			// RB-14409: Waiting signal R for informing the controller cancel all of stations
			delete this.localStoppingIrrigationQueueDict[connectDataPackChange.controllerId];
			this.localIrrigationQueueDict[connectDataPackChange.controllerId] = {};
		}
		let anyStationStatusChanged = false;
		stations.forEach(station => {
			const status = RbUtils.Stations.getStationStatus(station.terminal, connectDataPackChange.controllerId, connectDataPackChange.connectDataPacks);
			this.updateStationStatusBasedOnLocal(station, status);
			anyStationStatusChanged = anyStationStatusChanged || station.setStationStatus(status);
			this.setStationIrrigationSource(station, controllerDataPack);
		});

		if (anyStationStatusChanged) {
			this.stationsStatusesUpdateCompleted.next(connectDataPackChange.controllerId);
			this.stationsListChange.next(new StationsListChange(connectDataPackChange.controllerId, stations, true));
		}
	}

	private setStationIrrigationSource(station: StationListItem, connectDataPack: ConnectDataPack) {
		let irrigationSource = '--';

		if (connectDataPack && connectDataPack.irrigationQueue && connectDataPack.irrigationQueue.length > 0) {
			const irrigationForStation = connectDataPack.irrigationQueue.find(i => i.stationNumber === station.terminal);
			if (irrigationForStation) {
				irrigationSource = irrigationForStation.sourceDescription;
			}
		}

		station.irrigationSourceDescription = irrigationSource;
	}

	/**
	 * Handle station status updates (including 'added', 'deleted', 'updated').
	 * @param change - StationStatusChange (SignalR) describing the change.
	 * @returns - boolean true if the cache should be declared stale as a result of the change; false otherwise
	 */
	private updateStationStatuses(change: StationStatusChange): boolean {
		if (!this._apiResult) return false;

		let cacheStale = false;
		const stations = this._apiResult.value;

		// Update the list item
		const listItems = change.changeType === RbEnums.SignalR.StationStatusChangeType.BatchUpdated
			? stations.filter(s => change.itemsChanged.ids.some(id => id === s.id))
			: stations.filter(s => s.id === change.stationId);
		if (listItems.length === 0) return;

		const updatedObj = RbUtils.Common.getUpdateObjectFromItemsChanged(change.itemsChanged);
		// Convert from "Station" data to "StationListItem" data
		if (updatedObj.description != null) {
			updatedObj.notes = updatedObj.description;
			delete updatedObj.description;
		}
		if (updatedObj.addressInt != null) {
			updatedObj.address = updatedObj.addressInt;
			delete updatedObj.addressInt;
		}
		if (updatedObj.Terminal != null) {
			updatedObj.terminal = updatedObj.Terminal;
			delete updatedObj.Terminal;
		}
		if (updatedObj.FastConnectStationNumber != null) {
			updatedObj.fastConnectStationNumber = updatedObj.FastConnectStationNumber;
			delete updatedObj.FastConnectStationNumber;
		}
		if (updatedObj.Channel != null) {
			updatedObj.channel = updatedObj.Channel;
			delete updatedObj.Channel;
		}
		if (updatedObj.IsLocked != null) {
			updatedObj.isLocked = updatedObj.IsLocked;
			delete updatedObj.IsLocked;
		}
		if (!updatedObj.runTimeRemaining && change.runTimeRemaining != null) {
			updatedObj.runTimeRemaining = change.runTimeRemaining;
		}
		if (!updatedObj.runTimeSoFar && change.runTimeSoFar != null) {
			updatedObj.runTimeSoFar = change.runTimeSoFar;
		}

		// RB-12148: If the station's satelliteId changed, we can't simply change it in the local list; we have to 
		// declare the cache stale and reload. There are several property values which change as a consequence of
		// the satelliteId change which can't be listed. This only impacts golf/hybrid where stations can relocate
		// to other satellites or from MIM to satellite.
		if (updatedObj.satelliteId != null) {
			cacheStale = true;
		}

		updatedObj.signalREventType = change.changeType;

		const updatedSatellites: number[] = [];
		const updatedPrograms: number[] = [];
		listItems.forEach(listItem => {
			Object.assign(listItem, updatedObj);

			const isNonIrrigation = listItem.priority === RbEnums.Common.Priority.NonIrrigation;
			const stationStatus = RbUtils.Stations.getStationStatusFromStatusChange(change, listItem.master, isNonIrrigation);
			listItem.setStationStatus(stationStatus);

			if (listItem.satelliteId) {
				if (updatedSatellites.every(id => id !== listItem.satelliteId)) updatedSatellites.push(listItem.satelliteId);
			}
			if (updatedPrograms.every(id => id !== listItem.programId)) updatedPrograms.push(listItem.programId);
		});
		this.stationsChange.next(listItems);
		updatedSatellites.forEach(id => {
			this.stationsStatusesUpdateCompleted.next(id);
			const stationsInSatellite = stations.filter(s => s.satelliteId === id);
			this.stationsListChange.next(new StationsListChange(id, stationsInSatellite, true,
				change.changeType === RbEnums.SignalR.StationStatusChangeType.BatchUpdated ? null : change.stationId));
		});
		updatedPrograms.forEach(id => this.programStepsManager.programsOrProgramGroupsUpdated([id]));

		return cacheStale;
	}

	private updateStationsStatusesOnFetch(controllerId: number, stations: StationListItem[]) {
		const connectDataPacks = this.connectDataPackManager.connectDataPacks;
		if (!connectDataPacks) return;

		stations.forEach(station => {
			const status = RbUtils.Stations.getStationStatus(station.terminal, controllerId, connectDataPacks);
			this.updateStationStatusBasedOnLocal(station, status);
			station.setStationStatus(status);
			this.setStationIrrigationSource(station, connectDataPacks[controllerId]);
		});
	}

	private unlockAllStations() {
		if (!this._apiResult) return;

		const lockedItems = this._apiResult.value.filter(s => s.isLocked);
		lockedItems.forEach(station => {
			station.isLocked = false;
		});

		this.stationsChange.next(lockedItems);
	}

	private getStationsByHolesAndHoleSections(stations: StationListItem[], holeIds?: number[], holeSectionIds?: number[]): StationListItem[] {
		// RB-6291: Don't test if(array); you must check for null and undefined. This is an exception because we're
		// declaring the parameter ident?: type[].
		if (holeIds && holeIds.length > 0) {
			stations = stations.filter(station => holeIds.includes(station.areaLevel2Id));
		}
		// RB-6291: Don't test if(array); you must check for null and undefined. This is an exception because we're
		// declaring the parameter ident?: type[].
		if (holeSectionIds && holeSectionIds.length > 0) {
			stations = stations.filter(station => holeSectionIds.includes(station.areaLevel3Id));
		}

		if (stations != null && stations.length > 0) {
			stations.forEach(station => {
				if (station.isLocked) {
					station.status = RbUtils.Translate.instant('STRINGS.LOCKED');
					station.courseViewStatus = RbUtils.Translate.instant('STRINGS.LOCKED');
				} else {
					const golfStationStatus = this.systemStatusService.getGolfStationStatus(station.id);
					if (golfStationStatus) {
						station.setStationStatus(
							RbUtils.Stations.getStationStatusFromStatusChange(golfStationStatus,
								station.master, station.priority === RbEnums.Common.Priority.NonIrrigation));
					} else if (!station.status) {
						station.status = '-';
						station.courseViewStatus = '';
					}
				}
			});
		}

		return stations
			.sort((a, b) => a.areaLevel3Number - b.areaLevel3Number)
			.sort((a, b) => a.areaLevel3AreaNumber - b.areaLevel3AreaNumber)
			.sort((a, b) => a.areaLevel2Number - b.areaLevel2Number);
	}
}

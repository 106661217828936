import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class MessageBoxInfo {

	constructor(message: string,
				icon?: RbEnums.Common.MessageBoxIcon,
				title?: string,
				okYesCallback?: Function,
				cancelNoCallback?: Function,
				buttons?: RbEnums.Common.MessageBoxButtons,
				infoText?: string,
				okButtonName?: string
	) {
		this.message = RbUtils.Translate.instant(message);
		if (title) this.title = RbUtils.Translate.instant(title);
		if (icon) this.icon = icon;
		if (okYesCallback) this.okYesCallback = okYesCallback;
		if (cancelNoCallback) this.cancelNoCallback = cancelNoCallback;
		if (buttons) this.buttons = buttons;
		if (infoText) this.infoText = RbUtils.Translate.instant(infoText);
		if (okButtonName) this.okButtonName = RbUtils.Translate.instant(okButtonName);
	}

	buttons?: RbEnums.Common.MessageBoxButtons = RbEnums.Common.MessageBoxButtons.Ok;
	icon?: RbEnums.Common.MessageBoxIcon = RbEnums.Common.MessageBoxIcon.None;
	message: string;
	okYesCallback?: Function = null;
	cancelNoCallback?: Function = null;
	title?: string = null;
	infoText?: string = null;
	okButtonName?: string = "";

	static createDeleteMessage(
		titleType: string,
		msgType: string,
		useMultiple: boolean,
		icon?: RbEnums.Common.MessageBoxIcon,
		okYesCallback?: Function,
		cancelNoCallback?: Function,
		buttons?: RbEnums.Common.MessageBoxButtons,
		customMessage?: boolean
	) {
		const title = RbUtils.Translate.instant('STRINGS.DELETE_ITEM_TITLE', { type: titleType });
		let msg = '';
		if (customMessage) {
			msg = msgType;
		} else {
			msg = RbUtils.Translate.instant(useMultiple ? 'STRINGS.DELETE_MULTIPLE_PROMPT' : 'STRINGS.DELETE_SINGLE_PROMPT', { type: msgType });
		}
		return new MessageBoxInfo(msg, icon, title, okYesCallback, cancelNoCallback, buttons);
	}

	static createMessage(
		title: string,
		icon?: RbEnums.Common.MessageBoxIcon,
		okYesCallback?: Function,
		cancelNoCallback?: Function,
		buttons?: RbEnums.Common.MessageBoxButtons,
	) {
		return new MessageBoxInfo(title, icon, title, okYesCallback, cancelNoCallback, buttons);
	}

	static create(
		title: string,
		message: string,
		icon?: RbEnums.Common.MessageBoxIcon,
		okYesCallback?: Function,
		cancelNoCallback?: Function,
		buttons?: RbEnums.Common.MessageBoxButtons,
		infoText?: string,
		okButtonName?: string
	) {
		return new MessageBoxInfo(message, icon, title, okYesCallback, cancelNoCallback, buttons, infoText, okButtonName);
	}
}

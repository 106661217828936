<div>
	<div>
		<div class="w-50 dib f7"></div>
		<div class="w-50 dib f7">
			<div class="tr">{{today() | date: 'MM/dd/yyyy'}}</div>
			<div class="tr">{{today() | date: 'hh:mm:ss aa'}}</div>
		</div>
	</div>
	<div class="tc cover-logo">
		<img data-qa-id="logo" class="mw-100 h-100" src="../../../../assets/rblogo_pms348.svg" alt="rb-logo" />
	</div>
	<div class="block w-100 tc">
		{{companyname}}
	</div>
	<div class="report-title block white tc mv0 center w-70">{{reportname}}
	</div>
	<div class="block w-100 tc">{{dateCaption}}</div>
</div>

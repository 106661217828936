<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<!-- Desktop -->
<div class="drdts-container" *ngIf="!isMobile">
	<ng-container [ngTemplateOutlet]="dateRangeSelector"></ng-container>
	<ng-container [ngTemplateOutlet]="startDateTime"></ng-container>
	<ng-container [ngTemplateOutlet]="endDateTime"></ng-container>
</div>

<!-- Mobile -->
<div class="drdts-container is-mobile" *ngIf="isMobile">
	<div id="maf-icon-container">
		<button data-qaid="drdts-filter-button" mat-icon-button class="white" (click)="onFilterClick()">
			<i class="rb-icon icon-filter"></i>
		</button>
	</div>
	<div id="maf-dates-container" class="maf-header-text">
		<div class="maf-header-row">
			<div class="maf-header-label">{{'STRINGS.START' | translate}}:</div>
			<span class="maf-header-value">{{headerStartDate}} {{headerStartTime}}</span></div>
		<div class="maf-header-row">
			<div class="maf-header-label">{{'STRINGS.END' | translate}}:</div>
			<span class="maf-header-value">{{headerEndDate}} {{headerEndTime}}</span></div>
	</div>

	<div id="drdts-filter-panel" *ngIf="showFilterPanel" [ngClass]="{'animate-filter-panel': animateFilterPanel}">
		<div id="drdts-filter-panel-body">
			<ng-container [ngTemplateOutlet]="dateRangeSelector"></ng-container>
			<ng-container [ngTemplateOutlet]="startDateTime"></ng-container>
			<ng-container [ngTemplateOutlet]="endDateTime"></ng-container>
		</div>

		<div id="drdts-filter-panel-footer">
			<button class="rb-primary-button mat-raised-button mat-primary" (click)="onFilterClick()" data-qaid="drdts-filter-done-button" >{{'STRINGS.DONE' | translate}}</button>
		</div>
	</div>

	<div id="drdts-filter-backdrop" *ngIf="showFilterPanel" [ngClass]="{'animate-filter-panel': animateFilterPanel}"></div>
</div>

<ng-template #dateRangeSelector>
	<div *ngIf="dateRanges?.length > 0" class="drdts-range-selector">
		<div *ngIf="isMobile" class="drdts-range-label">{{'STRINGS.RANGE' | translate}}</div>
		<mat-select [ngModel]="selectedDateRange" (ngModelChange)="onDateRangeChange($event)" data-qaid="drdts-range-selector">
			<mat-option *ngFor="let dateRange of dateRangeValues" [value]="dateRange.value" class="pv1" color="primary" attr.data-qaid="drdts-range-option-{{dateRange.name | replaceWhitespace: '_' }}">
				{{dateRange.name | translate}}
			</mat-option>
		</mat-select>
	</div>
</ng-template>

<ng-template #startDateTime>
	<div class="drdts-date-time-pair drdts-start-time" [ngClass]="{'is-mobile': isMobile}">
		<div class="drdts-date-container">
			<span class="dtp-label">{{'STRINGS.START' | translate}}</span>
			<div class="rb-input-shield-host">
				<input
					class="drdts-date-input"
					[min]="minStartDate"
					[max]="maxStartDate"
					[ngModel]="startDate"
					[matDatepicker]="startDatePicker"
					(dateChange)="onUserChangedStartDate($event)"
					(focus)="$any($event.target).select()"
					(click)="startDatePicker.open()"
					data-qaid="drdts-start-date-input"
				/>
				<div class="rb-input-shield--w-pointer" (click)="startDatePicker.open()" data-qaid="drdts-start-date"></div>
			</div>
			<mat-datepicker-toggle matSuffix [for]="startDatePicker" data-qaid="drdts-start-date-toggle"></mat-datepicker-toggle>
			<mat-datepicker #startDatePicker data-qaid="drdts-input-start" disabled="false"></mat-datepicker>
		</div>

		<rb-input-time-picker class="drdts-time-picker" qaidLabel="startTime" [useMatFormField]="false" [(ngModel)]="startTime" (ngModelChange)="onStartTimeChanged($event)">
		</rb-input-time-picker>
	</div>
</ng-template>

<ng-template #endDateTime>
	<div class="drdts-date-time-pair" [ngClass]="{'is-mobile': isMobile}">
		<div class="drdts-date-container">
			<span class="dtp-label">{{'STRINGS.END' | translate}}</span>
			<div class="rb-input-shield-host">
				<input
					class="drdts-date-input"
					[ngModel]="endDate"
					[min]="minEndDate"
					[max]="maxEndDate"
					[matDatepicker]="endDatePicker"
					(dateChange)="onUserChangedEndDate($event)"
					(focus)="$any($event.target).select()"
					data-qaid="drdts-end-date-input"
				/>
				<div class="rb-input-shield--w-pointer" (click)="endDatePicker.open()" data-qaid="drdts-end-date"></div>
			</div>
			<mat-datepicker-toggle matSuffix [for]="endDatePicker" data-qaid="drdts-end-date-toggle"></mat-datepicker-toggle>
			<mat-datepicker #endDatePicker data-qaid="drdts-input-end"></mat-datepicker>
		</div>

		<rb-input-time-picker class="drdts-time-picker"  qaidLabel="endTime" [(ngModel)]="endTime" [useMatFormField]="false" (ngModelChange)="onEndTimeChanged($event)">
		</rb-input-time-picker>
	</div>
</ng-template>

import { catchError, map, share } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { ApiCachedRequestResponse } from './api-cached-request-response';
import { ApiServiceCacheItem } from './api-service-cache-item';
import { CachedValue } from './cached-value';
import { EnvironmentService } from '../../common/services/environment.service';
import { Injectable } from '@angular/core';

export enum HttpMethod {
	Get,
	Post,
	Put,
	Patch,
	Delete
}

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	// RB-10433: With the evolution of the code, we either update our collections or request a new dataset
	// from the API. There should be no reason we need to arbitrarily throw away our cache. To test out this
	// theory, I have increased the data cache timeout to 24hrs, essentially creating a permanent cache.
	private readonly DEFAULT_DATA_CACHE_TIMEOUT_IN_SEC = 86400;

	// Cache API requests to avoid multiple calls to APIs. This is a dictionary of information based on the URL and contains:
	// observable: Observable<any> if the request is already in progress
	// result: any if the request is already complete
	private currentApiRequests: ApiServiceCacheItem<any>[] = [];

	protected get baseApiUrl(): string { return `${this.env.apiUrl}/`; }

	protected get baseLicenseApiCloudUrl(): string { return `${this.env.licenseApiCloudUrl}/`; }

	constructor(protected http: HttpClient,
				protected env: EnvironmentService) {
	}

	private readonly headers = new HttpHeaders().set('Content-Type', 'application/json');
	private readonly refHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json-ref').set('Response-Type', 'text');

	/**
	 * Make the indicated URL request to our CoreApi. You can specify the method (POST, PATCH, etc.), as well as sending
	 * a body for the request. json-ref format can be specified and you can force any recent duplicate of the request
	 * already in the cache to be bypassed, if necessary.
	 * @param url - string specifying the URL to access. This should include any FromQuery parameters to the API
	 * @param httpMethod - HttpMethod specifying which HTTP request type will be used (GET, POST, PATCH, etc.)
	 * @param httpBody - any? specifying the body of the request or null if no body should be sent
	 * @param useRefRequest - boolean? set to true to use json-ref format request such that the response can skip duplicate
	 * copies of objects referenced multiple times in the response object(s)
	 * @param bypassCache - boolean? set to true to skip retrieving the API result from a recent request with the same
	 * parameters from cache, forcing the API call
	 * @returns Observable<T> where T is the result type
	 */
	apiRequest<T>(url: string, httpMethod: HttpMethod, httpBody?: any, useRefRequest?: boolean, bypassCache?: boolean): Observable<T> {
		// RB-2115: Check URL length. If > 2080, it probably will fail during the call (after cutting off the end
		// of the URL and potentially doing something unintended). The problem that generated this bug report was
		// deleting ProgramSteps. If you wanted to delete a LOT of them (say 200 stations on an LXD), somewhere
		// in the middle of the list, which was in the URL at that time, it would get cut off. The last one in the
		// list might have a portion of its ID value cut-off, potentially deleting a different ProgramStep
		// entirely. Very difficult to find. Here, we throw an exception if the URL is too long, so we can find
		// the problem before a customer reports a missing ProgramStep that we could never duplicate.
		if (url.length > 2080) {
			// The url should be made up of the baseApiUrl plus the controller plus the method name, followed by
			// a large number of parameters. We don't need the parameters, but do need the controller/method
			// combination to help debug.
			// (protocol, http:)//(server, ip/name:port)/(basepath, CoreApi or empty)/(controller)/(method)?(params)
			const urlWithoutParams = url.split('?');
			throw new URIError(`apiRequest URI (${urlWithoutParams[0]}) is too long. This may indicate an API error. Consult the factory.`);
		}

		// Only allow caching of GET calls
		if (httpMethod !== HttpMethod.Get) {
			return this.http.request<T>(HttpMethod[httpMethod], url, { headers: this.headers, body: httpBody });
		}

		// Use the "regular" GET function and use a very short cache expiration
		// RB-10048: When we're doing an immediate follow-up to an update, where perhaps we don't have the patching details,
		// we may need to skip the cache. This also occurs when the pattern is something like:
		// 1. Get object to be edited
		// 2. Edit the object, completed quickly by the user
		// 3. Update the object
		// 4. Reload the object
		// If 4 is within the short cache duration below, unless you set bypassCache, you could get the pre-edit value.
		return this.apiRequestWithCache<T>(url, bypassCache === true, /* transformCallback */ null,
			/* cacheDurationSeconds */ 5, useRefRequest).pipe(map(result => result.value));
	}

	apiRequestWithCache<T>(url: string, bypassCache: boolean, transformCallback?: (value: any) => T,
						   cacheDurationSeconds?: number, useRefRequest?: boolean): Observable<ApiCachedRequestResponse<T>> {
		if (url.length > 2080) {
			const urlWithoutParams = url.split('?');
			throw new URIError(`apiRequest URI (${urlWithoutParams[0]}) is too long. This may indicate an API error. Consult the factory.`);
		}

		// See if we already have an entry for this URL and if it is current in-progress
		let entry = this.currentApiRequests.find(c => c.url === url);
		if (entry != null && entry.observable != null) {
			return entry.observable;
		}

		// See if we have a cached value (and we are allowing using the cached value)
		if (!bypassCache && entry != null && !entry.cachedValue.isExpired) {
			entry.cachedResponse.isFromCache = true;
			return of(entry.cachedResponse);
		}

		// Add an entry to the list. Note: this is guaranteed to be populated below with an observable before we return
		if (entry == null) {
			entry = new ApiServiceCacheItem(url);
			this.currentApiRequests.push(entry);
		}

		// Make the call. Cache the result (if desired) and handle errors by eliminating the URL from the list
		const observable = this.http.request<T>(HttpMethod[HttpMethod.Get], url, { headers: useRefRequest ? this.refHeaders : this.headers })
			.pipe(share(),
				map(result => {
						entry.observable = null;
						const transformedResult = useRefRequest ? this.parseAndResolveJsonRef(JSON.stringify(result)) : result;
						entry.cachedValue = new CachedValue<any>(transformCallback
							? transformCallback(transformedResult) : transformedResult, cacheDurationSeconds ?? this.DEFAULT_DATA_CACHE_TIMEOUT_IN_SEC);
						entry.cachedResponse = new ApiCachedRequestResponse(entry.cachedValue.value, false, new Date());
						return entry.cachedResponse;
					},
					catchError(error => {
						this.currentApiRequests = this.currentApiRequests.filter(r => r.url !== url);
						return throwError(error);
					})));
		entry.observable = observable;
		return observable;
	}

	patchTransform(object: object, op: string = 'replace') {
		return Object.keys(object).map(key => ({
			op,
			path: `/${key}`,
			value: object[key]
		}));
	}

	multiplePatchTransform(object: object) {
		const patchObj = {};

		if (object.hasOwnProperty('add')) {
			patchObj['add'] = [];
			object['add'].forEach((item: object) => {
				patchObj['add'].push({
					id: 0, // Id = 0 for creating new object
					patch: this.patchTransform(item, /* op */ 'add')
				});
			});
		}

		if (object.hasOwnProperty('update')) {
			patchObj['update'] = [];
			object['update'].forEach((item: object) => {
				const id = item['id'];
				delete item['id'];
				patchObj['update'].push({
					id,
					patch: this.patchTransform(item, /* op */ 'replace')
				});
			});
		}

		if (object.hasOwnProperty('delete')) {
			// The delete only contains Id,
			// No action from here, go with the original object
			patchObj['delete'] = object['delete'];
		}

		return patchObj;
	}

	private parseAndResolveJsonRef(json: any): any {
		const refDict = {};
		return JSON.parse(json, function(key, value) {
			if (key === '$id') {
				refDict[value] = this;
				return void (0);
			}

			if (value && value.$ref) { return refDict[value.$ref]; }
			return value;
		});
	}

	replacePathObject(itemsChanged: any) {
		if (itemsChanged == null) return;

		var itemsChangedObject: any = [];
		itemsChanged.add.forEach(item => {
			const addObject = {};
			item.patch.forEach(item => {
				if (item.op === 'add') addObject[item.path.replace('/', '')] = item.value;
			});
			addObject['id'] = item.id;
			itemsChangedObject.push(addObject);
		});

		itemsChanged.update.forEach(item => {
			const updateObject = {};
			item.patch.forEach(item => {
				if (item.op === 'replace') updateObject[item.path.replace('/', '')] = item.value;
			});
			updateObject['id'] = item.id;
			itemsChangedObject.push(updateObject);
		});
		return itemsChangedObject;
	}

	clearCache(urlPrefix: string) {
		// Get rid of all cached data
		this.currentApiRequests = this.currentApiRequests.filter(req => req.observable != null || req.url.indexOf(urlPrefix) !== 0);
	}

	// Update Cached Results

	/**
	 * RB-13990: This is a generic method to update one or more elements in a cached collection previously retrieved from the API. The goal of this method
	 * is to avoid throwing away entire cached collections when all we want to do is update a small subset of a cached collection. This will dramatically
	 * improve performance. This method is intended to only be called from the entity specific API Services (e.g., ControllerApiService) that extend
	 * ApiService. See updateAndReturnCachedProgramListItems in the ProgramApiService for an example usage.
	 *
	 * items: 		This is a collection of STRONGLY TYPED items (of the exact same type as the cached collection) we want to use to replace existing items
	 * 		  		in the cached collection. These are the updated items. MAKE SURE the items[] IS STRONGLY TYPED. We don't want to simply pass in Object[].
	 * itemKey: 	This is the property in the object T of the cached items that uniquely identifies the item (think primary key) in the cache. This value
	 * 				will be used to find the old item in the cache that is to be replaced with the new item from the items array.
	 * cacheUrl:	This is the same, EXACT url that is used to make the API call that represents the Key in the cache dictionary. It is highly
	 * 				recommended that the api url as it is defined in each of the specific API Services (e.g, ControllerApiService) be used to ensure the
	 * 			    values are an exact match. THIS IS CRITICAL.
	 * */
	updateAndReturnCachedCollection<T>(items: T[], itemKey: any, cacheUrl): T[]  {
		let entry = this.currentApiRequests.find(c => c.url === cacheUrl);
		if (entry == null || entry.cachedResponse == null) return [];

		const cachedResponse = entry.cachedResponse;
		if (!Array.isArray(cachedResponse.value)) throw new Error('Attempting to update a non collection class')

		items.forEach(item => {
			const cachedItemIndex = cachedResponse.value.findIndex(i => i[itemKey] === item[itemKey]);
			if (cachedItemIndex != -1) {
				cachedResponse.value[cachedItemIndex] = item;
				return;
			}

			// Add any new ones we encounter. This should rarely if ever happen.
			cachedResponse.value.push(item);

			// TODO: We don't handle deletes with this logic. We would either need to pass that information along via SignalR
			//		 or we would need to get an entirely new collection (which would defeat the purpose here).
			// NOTE: Deletion of items from the cache should happen via SignalR 'Delete' notifications. This occurs for Controllers
			//		 for both Add and Delete. We may need to add similar logic for other cached collections.
		})

		return cachedResponse.value as T[];
	}
	/**
	 * 
	 * @param items list of items to remove from all API cache collections
	 * @param itemKey 
	 */
	removeItemFromCachedCollection<T>(items: T[], itemKey: any) {
		this.currentApiRequests.forEach(entry => {
		  if (entry == null || entry.cachedResponse == null) return [];
		  const cachedResponse = entry.cachedResponse;
		  if (Array.isArray(cachedResponse.value) && typeof(cachedResponse.value) === typeof(items)) {
			items.forEach(item => {
			  const cachedItemIndex = cachedResponse.value.findIndex(i => i[itemKey] === item[itemKey]);
			  if (cachedItemIndex != -1) {
				cachedResponse.value.splice(cachedItemIndex, 1);
			  }
			})
		  }
		});
	}

}

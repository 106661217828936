export enum NotificationType {
	SignalR,
	AppSync
}

export interface IEnvironment {
	production: boolean;
	environmentOption: number;
	useRequestUrl: boolean;				// If true, client will base origin of initial url request for api, identity and rbcc_ui urls.
	preferApiAndIdentityServerPort: boolean;	// If true, client will force itself to use port number from the ApiUrl, etc. in the
										// environment, if present, rather than always trying to use the incoming URL's port. This
										// should only be set in the rbdevmobile and similar environments where we're running the
										// API and IdentityServer in the debugger as well as the UI.

	apiUrl: string;						// Ignored if useRequestUrl === true;

	/**
	 * SignalR hub path (including hub name for StatusHub). There are different values you might need to use here:
	 * null - Calculate SignalR hub location relative to apiUrl above (replacing /api with /signalr/hub/statusHub),
	 * using assets/environment.dynamic.json to replace the value at run time.
	 * string - Use the path directly with no substitution. The URL must include the SignalR path.
	 * NOTE: If useRequestUrl is true, this value is ignored entirely and the apiUrl-relative mode is used, as though
	 * this value was null.
	 */
	signalRUrl: string;
	
	identityServer4Url: string;			// Ignored if useRequestUrl === true;
	licenseApiCloudUrl: string;
	rbcc_ui: string;					// Ignored if useRequestUrl === true;
	rainbirdStoreUrl: string;
	awsRBCCAppSyncUrl: string;			// This is the AppSync URL for RBCC notifications. If AppSync is not active, this can be ''
	awsRBCCAppSyncRegion: string;		// This is the AWS region for RBCC notifications. If AppSync is not active, this can be ''
	awsRBCCAppSyncChannelFormat: string;		// This is the channel name for subscriptions. Available parameters will be:
										// id = company ID
										// uuid = company UUID
										// So a string might be '{{uuid}}' to use the company UUID as the channel name, or '{{id}}'
										// to use the company ID as the channel name.
	awsRBCCAppSyncApiKey: string;		// If available, this is the API key used for unauthenticated access to the AppSync service.
										// The value should always be null for any real cloud service, where we use the OIDC token
										// from IdentityServer instead of an API key.

	wwoUrl: string;
	wwoKey: string;
	VERSION: any;
	client_id: string;
	bingKey: string;
	beamerProductKeyIq4: string;
	beamerProductKeyCirrus: string;
	useMockData: boolean;
	cloudDryRunTableName: string;
	awsDynamoDBRegion: string;
	notificationType: NotificationType;
}

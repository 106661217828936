import { Area } from '../../areas/models/area.model';
import { CompanyPreferences } from '../../companies/models/company-preferences.model';
import { Controller } from '../../controllers/models/controller.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SeasonalAdjust } from './seasonal-adjust.model';

export class Site {

	address: string;
	area: Area;

	/**
	 * @summary Not returned by API but represents the list of golf and non-golf areas when the site is a "course". May
	 * or may not be set based on context.
	 */
	areas?: Area[];

	autoContactOverride: boolean;
	autoContactRetries: number;
	autoContactRetryDelay: string;
	city: string;
	companyId: number;
	controlledShutdownTimeLong: number;
	country: string;
	countryCode: string;
	defaultWeatherSourceId: number;
	description: string;
	floGuardCapacity: number;

	/**
	 * @summary Not returned by API but represents the list of golf holes when the site is a "course". May or may not be
	 * set based on context.
	 */
	holes?: Area[];

	id: number;
	irrigationDayStartTime: string;
	latitude: number;
	longitude: number;
	name: string;
	number: number = null;
	numberOfHoles: number = null;			// Number of Golf Holes (only relevant for Golf Site.
	numberOfSatellites: number = null;
	numberOfInterfaces: number = null; // ? used only in golf
	overrideBranchFZCapacities = false;
	postalCode: string;
	powerLineFrequency: number;
	satellite: Controller[];
	seasonalAdjust: SeasonalAdjust[];
	state: string;
	systemMode: number;
	timeZone: string;
	type: number;
	waterWindowCloseTime: Date;
	waterWindowOpenTime: Date;
	zip: string;
	timeZoneAbbreviation: string;
	timeZoneLocalizedName: string;
	utcOffset: string;
	timeZoneShortFormat: string;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.area) { this.area = new Area(json.area); }
			if (json.satellite) { this.satellite = json.satellite.map(s => new Controller(s)); }
			if (json.seasonalAdjust) { this.seasonalAdjust = json.seasonalAdjust.map(sa => new SeasonalAdjust(sa)); }
			if (json.waterWindowCloseTime) { this.waterWindowCloseTime = RbUtils.Conversion.convertStringToDate(json.waterWindowCloseTime); }
			if (json.waterWindowOpenTime) { this.waterWindowOpenTime = RbUtils.Conversion.convertStringToDate(json.waterWindowOpenTime); }

			if (json.numberOfHoles === undefined || json.numberOfHoles === null) {
				this.numberOfHoles = 0;
			}
			if (json.numberOfSatellites === undefined || json.numberOfSatellites === null) {
				this.numberOfSatellites = 0;
			}
			if (json.numberOfInterfaces === undefined || json.numberOfInterfaces === null) {
				this.numberOfInterfaces = 0;
			}

			if (json.timeZoneLocalizedName) {
				this.utcOffset = RbUtils.Conversion.convertTimeZoneToUTCOffset(this.timeZoneLocalizedName);
				this.timeZoneShortFormat = `${this.timeZoneAbbreviation} (${this.utcOffset})`;
			}
		}
	}

	static createDefault(companyId: number, type: number, companyPrefs: CompanyPreferences): Site {
		const site = new Site();
		site.companyId = companyId;
		site.numberOfSatellites = 0;
		site.type = type;
		site.timeZone = companyPrefs.timeZone;
		site.floGuardCapacity = companyPrefs.floGuardCapacity;
		site.powerLineFrequency = 60;
		return site;
	}

	/**
	 * The intent here is to return the smallest possible reference to the site which WWO will understand
	 * as a location query.
	 */
	get weatherForecastQueryParam(): string {
		return RbUtils.Weather.GetWWOQueryParams(this.address, this.city, this.state, this.countryCode, this.zip);
	}

	// NOTE: This property IS USED ON sites-tab.component.html
	get satellitesCountForHyperlink(): string {
		const satelliteLabel = RbUtils.Translate.instant((this.type === RbEnums.Common.SiteType.Commercial)
			? this.numberOfSatellites === 1 ? 'STRINGS.CONTROLLER' : 'STRINGS.CONTROLLERS'
			: this.numberOfSatellites === 1 ? 'STRINGS.INTERFACE' : 'STRINGS.INTERFACES');

		return RbUtils.Translate.instant(this.numberOfSatellites === 0
			? this.type === RbEnums.Common.SiteType.Commercial ? 'STRINGS.ADD_CONTROLLER' : 'STRINGS.ADD_INTERFACE'
			: 'STRINGS.COL_COUNT_LINK_TEMPLATE', { value: this.numberOfSatellites, entity: satelliteLabel } );

	}

	// NOTE: This property IS USED ON sites-tab.component.html
	get interfaceCountForHyperlink(): string {
		const satelliteLabel = RbUtils.Translate.instant('STRINGS.INTERFACES');

		return RbUtils.Translate.instant(
			this.numberOfInterfaces === 0
				? 'STRINGS.ADD_INTERFACE' : 'STRINGS.COL_COUNT_LINK_TEMPLATE', { value: this.numberOfInterfaces, entity: satelliteLabel }
			);
	}

	get isGolfSite(): boolean {
		return this.type === RbEnums.Common.SiteType.Golf;
	}
}


export class SiteLocalChange {
	siteIds: number[] = [];
	patchValues: { [key: string]: any} = {};

	constructor(siteIds: number[] = [], patchValues: {[key: string]: any} = {}) {
		this.siteIds = siteIds;
		this.patchValues = patchValues;
	}
}
import { ApiService, HttpMethod } from '../_common/api.service';

import { DryRun } from './models/dry-run.model';
import { DryRunFlowDatumStation } from './models/dry-run-flow-datum-station.model';
import { DryRunResult } from './models/dry-run-result.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RbUtils } from '../../common/utils/_rb.utils';

@Injectable({
	providedIn: 'root'
})
export class DryRunApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	dryRunSimulation(controllerId: number, startDate: Date, endDate: Date): Observable<any> {
		return this.apiRequest<any>(this.dryRunSimulationUrl(controllerId, startDate, endDate), 
				HttpMethod.Get, null, null, true /** set by pass cache as this need to run every time user request */)
			.pipe(map(result => {
				return result.value;
			}));
	}

	getDryRuns(startDate: Date, endDate: Date): Observable<DryRun[]> {
		return this.apiRequest<any>(this.getDryRunsUrl(startDate, endDate), HttpMethod.Get)
			.pipe(map(list => list.map(item => new DryRun(item))));
	}

	// Dry runs will be cached for 1 hour
	getDryRunResult(dryRunId: number): Observable<DryRunResult> {
		return this.apiRequestWithCache<any>(this.getDryRunResultUrl(dryRunId), false, null, 3600)
			.pipe(map(response => new DryRunResult(response.value)));
	}

	deleteDryRun(dryRunId: number) {
		return this.apiRequest<any>(this.deleteDryRunUrl(dryRunId), HttpMethod.Delete);
	}

	getDryRunFlowDatumStations(dryRunFlowDatumIds: bigint[]): Observable<DryRunFlowDatumStation[]> {
		return this.apiRequest<DryRunFlowDatumStation[]>(this.getDryRunFlowDatumStationsUrl(), HttpMethod.Post, dryRunFlowDatumIds);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}DryRun`; }

	private getDryRunsUrl(startDate: Date, endDate: Date) {
		return `${this.baseUrl}/GetDryRuns`
			+ `?start=${RbUtils.Conversion.convertDateToDateTimeParameterString(startDate)}`
			+ `&end=${RbUtils.Conversion.convertDateToDateTimeParameterString(endDate)}`;
	}

	private dryRunSimulationUrl(controllerId: number, startDate: Date, endDate: Date) {
		return `${this.baseUrl}/DryRunSimulation`
			+ `?satelliteId=${controllerId}`
			+ `&start=${RbUtils.Conversion.convertDateToDateTimeParameterString(startDate)}`
			+ `&end=${RbUtils.Conversion.convertDateToDateTimeParameterString(endDate)}`;
	}

	private getDryRunResultUrl(dryRunId: number) { return `${this.baseUrl}/GetDryRunResult?dryRunId=${dryRunId}`; }

	private getDryRunFlowDatumStationsUrl() { return `${this.baseUrl}/GetDryRunFlowDatumStations`; }

	private deleteDryRunUrl(dryRunId: number) { return `${this.baseUrl}/DeleteDryRun?dryRunId=${dryRunId}`; }
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ControllerDifferenceItem } from '../../../../../api/controllers/models/controller-differences/controller-difference-item.model';

@Component({
	selector: 'rb-controller-delta-details-mobile-cell-renderer',
	templateUrl: './controller-delta-details-mobile-cell-renderer.component.html',
	styleUrls: ['./controller-delta-details-mobile-cell-renderer.component.scss']
})
export class ControllerDeltaDetailsMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	deltaItem: ControllerDifferenceItem;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.deltaItem = params.data;
	}
}

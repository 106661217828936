import { MapInfo } from './map-info.model';
import { TranslateService } from '@ngx-translate/core';

export class FullScreenControl {

	static create(mapInfo: MapInfo, translate: TranslateService, parentDiv: HTMLElement) {

		// Set CSS for the control border.
		const controlUI = document.createElement('div');
		controlUI.style.backgroundColor = '#fff';
		controlUI.style.border = '2px solid #fff';
		controlUI.style.borderRadius = '3px';
		controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
		controlUI.style.cursor = 'pointer';
		controlUI.style.marginBottom = '10px';
		controlUI.style.marginRight = '11px';
		controlUI.style.marginTop = '8px';
		controlUI.style.padding = '4px 4px 1px';
		controlUI.style.textAlign = 'center';
		controlUI.title = translate.instant('STRINGS.FULL_SCREEN');
		parentDiv.appendChild(controlUI);

		const icon = document.createElement('i');
		icon.innerHTML = 'crop_free';
		icon.classList.add('material-icons');
		icon.style.fontSize = '28px';
		icon.style.color = 'darkslategray';
		icon.id = 'full-screen';
		controlUI.appendChild(icon);

		controlUI.addEventListener('click', function() { mapInfo.toggleFullScreen(); });
	}

	static setIcon(fullScreen: boolean): void {
		const icon = document.getElementById('full-screen');
		if (icon == null) return;
		icon.innerHTML = fullScreen ? 'zoom_out_map' : 'crop_free';
	}
}

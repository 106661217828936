import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Area } from '../../../api/areas/models/area.model';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { ControllerListItem } from '../../../api/controllers/models/controller-list-item.model';
import { ControllerManagerService } from '../../../api/controllers/controller-manager.service';
import { ControllerSyncState } from '../../../api/signalR/controller-sync-state.model';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { DynamicToolbarBase } from '../../../shared-ui/components/toolbars/dynamic-toolbar-base';
import { filter } from 'rxjs/internal/operators/filter';
import { FrontBackDropdownComponent } from '../front-back-dropdown/front-back-dropdown.component';
import { ManualControlManagerService } from '../../../api/manual-control/manual-control-manager.service';
import { ManualControlState } from '../../../api/manual-control/models/manual-control-state.model';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { take } from 'rxjs/operators';

import ActivityViewType = RbEnums.Common.ActivityViewType;
import ManualControlIcon = RbEnums.Common.ManualControlIcon;
import ManualOpsDiagnosticsView = RbEnums.Common.ManualOpsDiagnosticsView;
import ToolbarButton = RbEnums.Common.ToolbarButton;

@UntilDestroy()
@Component({
	selector: 'rb-main-panel-toolbar',
	templateUrl: './main-panel-toolbar.component.html',
	styleUrls: ['./main-panel-toolbar.component.scss']
})
export class MainPanelToolbarComponent extends DynamicToolbarBase implements OnInit, OnChanges {
	@HostBinding('class') class = 'rb-main-panel-toolbar';

	@ViewChild('holesFilter') holesFilterElRef: ElementRef;
	@ViewChild('areasFilter') areasFilterElRef: ElementRef;

	@Input() styleClass: string = null;
	@Input() allowBatchEdit = true;
	@Input() awaitingSyncStart = false;
	@Input() disableAdvance = false;
	@Input() disableTableEdit = false;
	@Input() disableResume = true;
	@Input() disablePause = false;
	@Input() disableRetrieve = false;
	@Input() disableRefresh = false;
	@Input() disableConnect = false;
	@Input() disableControl = false;
	@Input() disableFlowAlarms = false;
	@Input() flowAlarmsCount = 0;
	@Input() iconContainerClass = '';
	@Input() disableCancelAll = false;
	@Input() disableCalculate = false;
	@Input() holes: Area[];
	@Input() holeSections: Area[];
	@Input() selectedGridItems: any[];
	@Input() showOverflowButton = true;
	@Input() disableCommunicationTypeActions = false;
	@Input() isRefreshingData = false;
	@Input() disableStart = true;
	@Input() stopAllLabel: string;
	@Input() disableUnlockSystem = false;
	@Input() activityView = ActivityViewType.List;
	@Input() disableExport = true;
	@Input() disableSnapshot = true;
	@Input() disableRestore = true;
	@Input() exportMenu = null;
	@Input() parentId = 0;

	@Output() editClick = new EventEmitter<any>();
	@Output() tableEditClick = new EventEmitter();
	@Output() calculateClick = new EventEmitter();
	@Output() connectClick = new EventEmitter<number>();
	@Output() controlClick = new EventEmitter<number>();
	@Output() flowAlarmsClick = new EventEmitter<number>();
	@Output() retrieveClick = new EventEmitter<any>();
	@Output() syncClick = new EventEmitter<any>();
	@Output() reverseSyncClick = new EventEmitter<any>();
	@Output() playClick = new EventEmitter<any>();
	@Output() pauseClick = new EventEmitter<any>();
	@Output() resumeClick = new EventEmitter<any>();
	@Output() stopClick = new EventEmitter<any>();
	@Output() advanceClick = new EventEmitter<any>();
	@Output() deleteClick = new EventEmitter<any>();
	@Output() clearClick = new EventEmitter<any>();
	@Output() resetClick = new EventEmitter<any>();
	@Output() simulateClick = new EventEmitter<any>();
	@Output() holesSelected = new EventEmitter<Area[]>();
	@Output() holeSectionsSelected = new EventEmitter<Area[]>();
	@Output() cancelAllClick = new EventEmitter<any>();
	@Output() refreshClick = new EventEmitter();
	@Output() manualSensorReadClick = new EventEmitter<any>();
	@Output() unlockSystemClick = new EventEmitter<any>();
	@Output() activityViewChange = new EventEmitter<ActivityViewType>();
	@Output() isConnectedChange = new EventEmitter<boolean>();
	@Output() exportCSVClick = new EventEmitter<any>();
	@Output() snapshotClick = new EventEmitter<any>();
	@Output() restoreClick = new EventEmitter<any>();
	@Output() exportPdfClick = new EventEmitter<any>();
	@Output() summaryClick = new EventEmitter<any>();

	@Input() disableRasterTest = false;
	@Input() disableTestAll = false;
	@Input() disableDecoderTest = false;
	@Input() disableDynamicAction = false;
	@Input() dynamicActionLabel: string;
	@Input() dynamicActionIcon: string;
	@Input() dynamicActionCustomIconNumber: number;
	@Input() selectedWireGroup: RbEnums.Common.WireGroupOptions;

	@Input() diagnosticsView = ManualOpsDiagnosticsView.Status;
	@Output() diagnosticsViewChange = new EventEmitter<ManualOpsDiagnosticsView>();

	@Output() rasterTestClick = new EventEmitter();
	@Output() testAllClick = new EventEmitter();
	@Output() shortFindingClick = new EventEmitter();
	@Output() decoderTestClick = new EventEmitter();
	@Output() pingDecodersClick = new EventEmitter();
	@Output() dynamicActionClick = new EventEmitter();
	@Output() wireGroupActionClick = new EventEmitter();

	controllerId: number;
	controllerListItem: ControllerListItem;
	controlIcon = 'panorama_fish_eye';
	isConnected = false;
	localConnectingController = null;
	connectQaId = RbConstants.Ui.CONNECT_QAID;
	isActivedConnecting = false;

	private _isConnecting = false;
	private _isDisconnecting = false;
	private _isSyncing = false;
	private _isReverseSyncing = false;
	private _isGettingLogs = false;

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(protected authManager: AuthManagerService,
		private broadcastService: BroadcastService,
		protected controllerManager: ControllerManagerService,
		public deviceManager: DeviceManagerService,
		private manualControlManager: ManualControlManagerService,
		protected route: ActivatedRoute,
		private router: Router
	) {
		super(authManager, route, controllerManager, deviceManager);
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.styleClass != null) { this.class += ` ${this.styleClass}`; }
		this.controllerId = +this.route.snapshot.queryParams.controllerId;
		if (this.controllerId) {
			this.reactiveConnecting();
			// Get the current ControllerListItem from cache or api.
			this.controllerManager.getControllerListItem(this.controllerId)
				.pipe(take(1))
				.subscribe((controllerListItem: ControllerListItem) => {
					this.controllerListItem = controllerListItem;
					this.isConnected = this.controllerListItem.isConnected;
				});

			this.controllerManager.controllerListItemsCacheUpdated
				.pipe(untilDestroyed(this))
				.subscribe((controllersList: ControllerListItem[]) => {
					const latestControllerListItem = controllersList.find(c => c.id === this.controllerId);
					if (latestControllerListItem != null) { this.controllerListItem = latestControllerListItem; }
				});

			// Monitor changes to the ManualControlState for the given controller.
			this.manualControlManager.manualControlStateChange
				.pipe(
					untilDestroyed(this),
					filter((mcs: ManualControlState) => mcs && mcs.controllerId === this.controllerId)
				)
				.subscribe((manualControlState: ManualControlState) => {
					this.updateManualControlStateDisplay(manualControlState);
				});

			// Monitor Manual Control Connecting Status
			this.manualControlManager.isConnecting
				.pipe(
					untilDestroyed(this),
					filter((satelliteId: number) => satelliteId === this.controllerId)
				)
				.subscribe((satelliteId: number) => {
					this.controlIcon = RbEnums.Common.ManualControlIcon.Connecting;
					this.setIsConnecting(true);
					this.reactiveConnecting();
				});

			// Monitor Controller Connection Stopped event
			this.broadcastService.connectionStopped
				.pipe(
					untilDestroyed(this),
					filter((satelliteId: number) => satelliteId === this.controllerId || satelliteId === this.parentId)
				)
				.subscribe((satelliteId: number) => {
					this.updateManualControlStateDisplay();
					this.setConnectionState(ManualControlIcon.NotConnected);
					this.removeConnectingControllers(satelliteId);
				});

			if (!this.deviceManager.browserRefreshed) {
				this.updateManualControlStateDisplay(this.manualControlManager.getManualControlState(this.controllerId));
			} else {
				setTimeout(() => this.updateManualControlStateDisplay(this.manualControlManager.getManualControlState(this.controllerId)), 1000);
			}

			if (!this.isGolfSite) {
				this.broadcastService.syncStateChange
					.pipe(
						untilDestroyed(this),
						filter(ss => ss.controllerId === this.controllerId)
					)
					.subscribe((syncState: ControllerSyncState) => {
						if (!this.controllerListItem) return;
						this.controllerListItem.syncState = syncState.syncState;
						this.updateManualControlStateDisplay();
					});
				this.manualControlManager.manualConnectFailed
					.pipe(untilDestroyed(this),
						filter((satelliteId: number) => satelliteId === this.parentId || satelliteId === this.controllerId)
					)
					.subscribe((satelliteId: number) => {
						this.setIsConnecting(false);
						this.setConnectionState(ManualControlIcon.NotConnected);
						this.removeConnectingControllers(satelliteId);
					});
			}
		}

		// Subscribe to any Sync State changes
		this.broadcastService.syncStateChange
			.pipe(untilDestroyed(this))
			.subscribe(() => this.setSyncStates());

		this.broadcastService.eventLogsStateChange
			.pipe(untilDestroyed(this))
			.subscribe(() => this.setLogRetrievalState());

		this.setSyncStates();
		this.setLogRetrievalState();
	}

	ngOnChanges(changes: SimpleChanges) {
		// Rebuild denamic toolbar if any input changed
		this.buildDynamicToolbar();
	}

	// =========================================================================================================================================================
	// Required base class overrides
	// =========================================================================================================================================================

	get toolbarButtons(): ToolbarButton[] {
		if (this.deviceManager.isMobile) {
			return [
				ToolbarButton.ChartView, ToolbarButton.ScheduleView, ToolbarButton.ListView, ToolbarButton.CourseView, 
				ToolbarButton.Edit, ToolbarButton.Connect, ToolbarButton.Control, ToolbarButton.Summary,
				ToolbarButton.FlowAlarms, ToolbarButton.Stop, ToolbarButton.Start, ToolbarButton.CancelAll, ToolbarButton.Pause, ToolbarButton.Resume,
				ToolbarButton.TableEdit, ToolbarButton.HolesFilter, ToolbarButton.AreasFilter, ToolbarButton.RetrieveLogs, ToolbarButton.Sync,
				ToolbarButton.ReverseSync, ToolbarButton.Advance, ToolbarButton.Simulate, ToolbarButton.Reset, ToolbarButton.ManualSensorRead,
				ToolbarButton.Delete, ToolbarButton.Refresh, ToolbarButton.UnlockSystem, ToolbarButton.Calculate, ToolbarButton.Status,
				ToolbarButton.RasterTest, ToolbarButton.TestAll, ToolbarButton.ShortFinding, ToolbarButton.DecoderTest, ToolbarButton.PingDecoders,
				ToolbarButton.RespondList, ToolbarButton.PingValvesSensors, ToolbarButton.ShortReport, ToolbarButton.DynamicAction, ToolbarButton.Export,
				ToolbarButton.SitesView, ToolbarButton.UsersView, ToolbarButton.Snapshot, ToolbarButton.Restore,
				ToolbarButton.WireGroup1, ToolbarButton.WireGroup2, ToolbarButton.WireGroup3, ToolbarButton.WireGroup4, ToolbarButton.pdfExport,
				ToolbarButton.csvExport, ToolbarButton.Clear
			];
		}

		return [
			ToolbarButton.ChartView, ToolbarButton.ColumnView, ToolbarButton.ListView,
			ToolbarButton.CourseView, ToolbarButton.Edit, ToolbarButton.Summary, ToolbarButton.TableEdit,
			ToolbarButton.Connect,ToolbarButton.Control, ToolbarButton.FlowAlarms, ToolbarButton.RetrieveLogs, ToolbarButton.Sync, ToolbarButton.ReverseSync,
			ToolbarButton.Stop,ToolbarButton.Start, ToolbarButton.Pause, ToolbarButton.Resume, ToolbarButton.CancelAll, ToolbarButton.Advance, 
			ToolbarButton.Simulate, ToolbarButton.Reset, ToolbarButton.ManualSensorRead, ToolbarButton.Delete, ToolbarButton.HolesFilter, 
			ToolbarButton.AreasFilter,
			ToolbarButton.Refresh, ToolbarButton.UnlockSystem, ToolbarButton.Calculate, ToolbarButton.Status, ToolbarButton.RasterTest, ToolbarButton.TestAll,
			ToolbarButton.ShortFinding, ToolbarButton.DecoderTest, ToolbarButton.PingDecoders, ToolbarButton.RespondList, ToolbarButton.PingValvesSensors,
			ToolbarButton.SitesView, ToolbarButton.UsersView, ToolbarButton.Snapshot, ToolbarButton.Restore,
			ToolbarButton.ShortReport, ToolbarButton.DynamicAction, ToolbarButton.Export, ToolbarButton.WireGroup1, ToolbarButton.WireGroup2,
			ToolbarButton.WireGroup3, ToolbarButton.WireGroup4, ToolbarButton.pdfExport, ToolbarButton.csvExport, ToolbarButton.Clear
		];
	}

	get desktopToolbarContainer() {
		return 'desktop-main-pane-toolbar';
	}

	get mobileToolbarContainer() {
		return 'mobile-main-pane-toolbar';
	}

	// =========================================================================================================================================================
	// Base Class Toolbar Click Handler Overrides
	// =========================================================================================================================================================

	handleChartViewClick() {
		this.activityView = ActivityViewType.Chart;
		this.activityViewChange.emit(ActivityViewType.Chart);
	}

	handleScheduleViewClick(): void {
		this.activityView = ActivityViewType.Schedule;
		this.activityViewChange.emit(ActivityViewType.Schedule);
	}

	handleListViewClick() {
		this.activityView = ActivityViewType.List;
		this.activityViewChange.emit(ActivityViewType.List);
	}

	handleColumnViewClick() { 
		this.activityView = ActivityViewType.Column;
		this.activityViewChange.emit(ActivityViewType.Column);
	 }

	handleCourseViewClick() {
		this.activityView = ActivityViewType.Course;
		this.activityViewChange.emit(ActivityViewType.Course);
	}

	handleSitesViewClick() {
		this.activityView = ActivityViewType.Sites;
		this.activityViewChange.emit(ActivityViewType.Sites);
	}

	handleUsersViewClick() {
		this.activityView = ActivityViewType.Users;
		this.activityViewChange.emit(ActivityViewType.Users);
	}

	handleEditClick() {
		if (this.hasSelectedRows() && !(this.hasMultipleSelectedRows() && !this.allowBatchEdit)) {
			this.editClick.emit(this.selectedGridItems);
		}
	}

	handleTableEditClick() {
		if (this.disableTableEdit) { return; }
		this.tableEditClick.emit();
	}

	handleCalculateClick() {
		this.calculateClick.emit();
	}

	handleConnectClick() {
		if (!this.controllerId) { return; }
		if (this.isConnected) {
			this.setIsDisconnecting(true);
			this.controllerManager.setLocalConnectingController(this.controllerId, this.controllerListItem.parentId, false);
			this.reactiveConnecting();
		} else {
			this.setIsConnecting(true);
			this.controllerManager.setLocalConnectingController(this.controllerId, this.controllerListItem.parentId);
			this.reactiveConnecting();
		}
		this.connectClick.emit(this.controllerId);
	}

	handleControlClick() {
		if (!this.controllerId) { return; }

		this.controlClick.emit(this.controllerId);
	}

	handleFlowAlarmsClick() {
		if (!this.controllerId) { return; }

		this.flowAlarmsClick.emit(this.controllerId);
	}

	handleRetrieveClick() {
		if (this.hasSelectedRows()) { this.retrieveClick.emit(this.selectedGridItems); }
	}

	handleSyncClick() {
		if (this.hasSelectedRows()) { this.syncClick.emit(this.selectedGridItems); }
	}

	handleReverseSyncClick() {
		if (this.hasSelectedRows()) { this.reverseSyncClick.emit(this.selectedGridItems); }
	}

	handlePlayClick() {
		if (!this.disableStart || this.hasSelectedRows()) { this.playClick.emit(this.selectedGridItems); }
	}

	handlePauseClick() {
		if (this.hasSelectedRows()) { this.pauseClick.emit(this.selectedGridItems); }
	}

	handleResumeClick() {
		if (this.hasSelectedRows()) { this.resumeClick.emit(this.selectedGridItems); }
	}

	handleStopClick() {
		if (this.hasSelectedRows()) { this.stopClick.emit(this.selectedGridItems); }
	}

	handleCancelAllClick() {
		this.cancelAllClick.emit();
	}

	handleRefreshClick() {
		this.refreshClick.emit();
	}

	handleAdvanceClick() {
		if (this.hasSelectedRows()) { this.advanceClick.emit(this.selectedGridItems); }
	}

	handleDeleteClick() {
		if (this.hasSelectedRows()) { this.deleteClick.emit(this.selectedGridItems); }
	}	
	
	handleClearClick() {
		if (this.hasSelectedRows()) { this.clearClick.emit(this.selectedGridItems); }
	}

	handleResetClick() {
		if (this.hasSelectedRows()) { this.resetClick.emit(this.selectedGridItems); }
	}

	handleHolesFilterClick() {
		(<FrontBackDropdownComponent><unknown>this.holesFilterElRef).invokeRemotely();
	}

	handleAreasFilterClick() {
		(<FrontBackDropdownComponent><unknown>this.areasFilterElRef).invokeRemotely();
	}

	handleSimulateClick() {
		if (this.hasSelectedRows()) { this.simulateClick.emit(this.selectedGridItems); }
	}

	handleManualSensorReadClick() {
		if (this.hasSelectedRows()) { this.manualSensorReadClick.emit(this.selectedGridItems); }
	}

	handleUnlockSystemClick() {
		this.unlockSystemClick.emit();
	}

	handleExportCSVClick() {
		this.exportCSVClick.emit();
	}

	handleSnapshotClick() {
		this.snapshotClick.emit();
	}

	handleRestoreClick() {
		this.restoreClick.emit();
	}

	handleExportPdfClick() {
		this.exportPdfClick.emit();
	}

	handleSummaryClick(): void {
		this.summaryClick.emit();
	}

	handleDiagnosticsViewChange(view: ManualOpsDiagnosticsView) {
		this.diagnosticsViewChange.emit(view);
	}

	handleRasterTestClick() {
		this.rasterTestClick.emit();
	}

	handleTestAllClick() {
		this.testAllClick.emit();
	}

	handleShortFindingClick() {
		this.shortFindingClick.emit();
	}

	handleDecoderTestClick() {
		this.decoderTestClick.emit();
	}

	handlePingDecodersClick() {
		this.pingDecodersClick.emit();
	}

	handleDynamicActionClick() {
		this.dynamicActionClick.emit();
	}

	handleWireGroupClick(selectedGroup: RbEnums.Common.WireGroupOptions): void {
		this.wireGroupActionClick.emit(selectedGroup);
	}

	// =========================================================================================================================================================
	// Additional Toolbar Click Handlers
	// =========================================================================================================================================================

	onHolesSelected(holes: Area[]) {
		this.holesSelected.emit(holes);
	}

	onHoleSectionsSelected(holes: Area[]) {
		this.holeSectionsSelected.emit(holes);
	}

	// =========================================================================================================================================================
	// Base Class Overrides for Enabling/disabling toolbar buttons
	// =========================================================================================================================================================

	isEditDisabled(): boolean {
		return !this.hasSelectedRows() || (this.hasMultipleSelectedRows() && !this.allowBatchEdit);
	}

	isTableEditDisabled(): boolean {
		return this.disableTableEdit;
	}

	isRetrieveDisabled(): boolean {
		return !this.hasSelectedRows() || this.disableRetrieve || this.disableCommunicationTypeActions;
	}

	isSyncDisabled(): boolean {
		return !this.hasSelectedRows() || this.disableCommunicationTypeActions || this.awaitingSyncStart;
	}

	isReverseSyncDisabled(): boolean {
		return !this.hasSelectedRows() || this.disableCommunicationTypeActions;
	}

	isStartDisabled(): boolean {
		return this.disableStart && (!this.hasSelectedRows() || this.disableCommunicationTypeActions);
	}

	isPauseDisabled(): boolean {
		return !this.hasSelectedRows() || this.disableCommunicationTypeActions || this.disablePause;
	}

	isResumeDisabled(): boolean {
		return !this.hasSelectedRows() || this.disableResume || this.disableCommunicationTypeActions;
	}

	isStopDisabled(): boolean {
		return !this.hasSelectedRows() || this.disableCommunicationTypeActions;
	}

	isAdvanceDisabled(): boolean {
		return !this.hasSelectedRows() || this.disableAdvance || this.disableCommunicationTypeActions;
	}

	isCancelAllDisabled(): boolean {
		return this.disableCancelAll || this.disableCommunicationTypeActions;
	}

	isDeleteDisabled(canDeleteMultiple = true): boolean {
		return !this.hasSelectedRows() || (!canDeleteMultiple && this.selectedGridItems.length > 1);
	}	
	
	isClearDisabled(canClearMultiple = true): boolean {
		return !this.hasSelectedRows() || (!canClearMultiple && this.selectedGridItems.length > 1);
	}

	isResetDisabled(): boolean {
		return this.selectedGridItems === null || this.selectedGridItems.length !== 1;
	}

	isSimulateDisabled(): boolean {
		return this.selectedGridItems === null || this.selectedGridItems.length !== 1;
	}

	isManualSensorReadDisabled(): boolean {
		return !this.hasSelectedRows();
	}

	isRefreshDisabled(): boolean {
		return this.disableRefresh;
	}

	isConnectDisabled(): boolean {
		if (this.disableConnect || this.isConnecting || this.isDisconnecting) { return true; }

		return this.controllerListItem?.firmwareUpdateProgress !== null;
	}

	isControlDisabled(): boolean {
		return this.disableControl || this.disableUnlockSystem;
	}

	isCalculateDisabled(): boolean { return this.disableCalculate; }

	isFlowAlarmsDisabled(): boolean {
		return this.disableFlowAlarms;
	}

	getFlowAlarmsCount(): number {
		return this.flowAlarmsCount;
	}

	getIconContainerClass(): string {
		return this.iconContainerClass;
	}

	isExportDisabled(): boolean {
		return this.disableExport;
	}

	isSnapshotDisabled(): boolean {
		return !this.hasSelectedRows() || this.disableSnapshot;
	}

	isRestoreDisabled(): boolean {
		return !this.hasSelectedRows() || this.disableRestore;
	}

	isRasterTestDisabled(): boolean {
		return this.disableRasterTest;
	}

	isTestAllDisabled(): boolean {
		return this.disableTestAll;
	}

	isDecoderTestDisabled(): boolean {
		return this.disableDecoderTest;
	}

	isDynamicActionDisabled(): boolean {
		return this.disableDynamicAction;
	}

	// =========================================================================================================================================================
	// Base Class Overrides for controlling toolbar animations
	// =========================================================================================================================================================
	get isConnecting(): boolean {
		return this._isConnecting;
	}

	get isDisconnecting(): boolean {
		return this._isDisconnecting;
	}

	isGettingLogs(): boolean {
		return this._isGettingLogs;
	}

	isSyncing(): boolean {
		return this._isSyncing;
	}

	isReverseSyncing(): boolean {
		return this._isReverseSyncing;
	}

	isRefreshing(): boolean {
		return this.isRefreshingData;
	}

	// =========================================================================================================================================================
	// Methods for dynamically customizing toolbar buttons
	// =========================================================================================================================================================

	getConnectIcon(): string {
		return this.controlIcon;
	}

	getConnectLabel(): string {
		if (this.isConnecting) {
			return 'STRINGS.CONNECTING';
		}

		if (this.isDisconnecting) {
			return 'STRINGS.DISCONNECTING';
		}

		return this.isConnected ? 'STRINGS.DISCONNECT' : 'STRINGS.CONNECT';
	}

	getStopAllLabel(): string {
		return this.stopAllLabel != null ? this.stopAllLabel : super.getStopAllLabel();
	}

	getConnectNgClass(): {} {
		return { 'connected': this.isConnected };
	}

	getChartViewIconColor(): string {
		return this.activityView === ActivityViewType.Chart ? 'orange' : 'white';
	}

	getScheduleViewIconColor(): string {
		return this.activityView === ActivityViewType.Schedule ? 'orange' : 'white';
	}

	getListViewIconColor(): string {
		return this.activityView === ActivityViewType.List ? 'orange' : 'white';
	}

	getColumnViewIconColor(): string {
		return this.activityView === ActivityViewType.Column ? 'orange' : 'white';
	}

	getCourseViewIconColor(): string {
		return this.activityView === ActivityViewType.Course ? 'orange' : 'white';
	}

	getSitesViewIconColor(): string {
		return this.activityView === ActivityViewType.Sites ? 'orange' : 'white';
	}

	getUsersViewIconColor(): string {
		return this.activityView === ActivityViewType.Users ? 'orange' : 'white';
	}

	getStatusIconColor(): string {
		return this.diagnosticsView === ManualOpsDiagnosticsView.Status ? 'orange' : 'unset';
	}

	getShorFindingIconColor(): string {
		return this.diagnosticsView === ManualOpsDiagnosticsView.ShortFinding ? 'orange' : 'unset';
	}

	getPingDecodersIconColor(): string {
		return this.diagnosticsView === ManualOpsDiagnosticsView.PingDecoders ? 'orange' : 'unset';
	}

	getRespondListIconColor(): string {
		return this.diagnosticsView === ManualOpsDiagnosticsView.ResponseList ? 'orange' : 'unset';
	}

	getPingValvesSensorsIconColor(): string {
		return this.diagnosticsView === ManualOpsDiagnosticsView.PingValvesSensors ? 'orange' : 'unset';
	}

	getShorReportIconColor(): string {
		return this.diagnosticsView === ManualOpsDiagnosticsView.ShortReport ? 'orange' : 'unset';
	}

	getDynamicActionIcon(): string {
		return this.dynamicActionIcon != null ? this.dynamicActionIcon : super.getDynamicActionIcon();
	}

	getDynamicActionLabel(): string {
		return this.dynamicActionLabel != null ? this.dynamicActionLabel : super.getDynamicActionLabel();
	}

	getDynamicActionCustomIconNumber(): number {
		return this.dynamicActionCustomIconNumber != null ? this.dynamicActionCustomIconNumber : super.getDynamicActionCustomIconNumber();
	}

	getNgClass(args: RbEnums.Common.WireGroupOptions): {} {
		if (args === this.selectedWireGroup) {
			return { 'toolbar-item-selected': true };
		}
		return null;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private get isClientsTab(): boolean {
		return this.router.routerState.snapshot.url.includes('/controllers/clients');
	}

	private get clientControllerParentId(): number {
		if (!this.isClientsTab || !this.controllerId) return null;

		return this.controllerId;
	}

	private hasSelectedRows(): boolean {
		// RB-6291: Don't test if(array); you must check for null and undefined.
		return this.selectedGridItems != null && this.selectedGridItems.length > 0;
	}

	private hasMultipleSelectedRows(): boolean {
		// RB-6291: Don't test if(array); you must check for null and undefined.
		return this.selectedGridItems != null && this.selectedGridItems.length > 1;
	}

	private setSyncStates() {
		this.awaitingSyncStart = false;
		this._isSyncing = this.controllerManager.isAnyControllerSyncing(this.clientControllerParentId);
		this._isReverseSyncing = this.controllerManager.isAnyControllerReverseSyncing(this.clientControllerParentId);
	}

	private setLogRetrievalState() {
		this._isGettingLogs = this.controllerManager.isAnyControllerGettingLogs(this.clientControllerParentId);
	}

	private updateManualControlStateDisplay(manualControlState?: ManualControlState, isConnected?: boolean) {
		if (!isConnected) {
			// RB-9646: We may not yet have a manualControlState for the given controller or the manualControlState may be null. In this case,
			// we will fallback to checking the isConnected state of the associated controllerListItem. From time to time, we may hit this method (on init)
			// before the controllerListItem retrieval has returned. In that case, we will recursively call this method until we have a controllerListItem.
			if (!manualControlState && !this.controllerListItem) {
				setTimeout(() => this.updateManualControlStateDisplay(manualControlState, isConnected), 100);
				return;
			}

			if (!manualControlState && this.controllerListItem?.isConnected) {
				this.setConnectionState(ManualControlIcon.Connected);

				// If the ManualControlManager ManualControlDict is empty, initiate a data packs retrieval to pick up any existing connection status.
				if (!this.isGolfSite && this.manualControlManager.isManualControlDictEmpty) {
					this.controllerManager.getDatapacks(this.controllerId).subscribe();
				}

				return;
			}

			if (!this.isGolfSite && !manualControlState && this.controllerListItem) {
				if (this.controllerListItem.syncState === RbEnums.Common.ControllerSyncState.ReverseSyncing ||
					this.controllerListItem.syncState === RbEnums.Common.ControllerSyncState.Syncing) {
					this.setIsConnecting(true);
					this.setConnectionState(ManualControlIcon.Connecting);
					return;
				}
			}

			if (!this.isGolfSite && manualControlState?.isConnecting?.error) {
				this.removeConnectingControllers(this.controllerId);
				return;
			}

			if (manualControlState?.isConnecting?.error && !manualControlState?.isConnecting?.isUpdating) {
				this.setConnectionState(ManualControlIcon.NotConnected);
				this.setIsConnecting(false);
				return;
			}

			if (!this.isConnected && !!manualControlState &&
				(manualControlState?.isConnecting?.isUpdating || !manualControlState?.hasReceivedConnectDataPack)) {
				this.setIsConnecting(true);
				this.setConnectionState(ManualControlIcon.Connecting);
				return;
			}

		}

		if (isConnected || this.controllerListItem?.isConnected) {
			this.setConnectionState(ManualControlIcon.Connected);
			this.removeConnectingControllers(this.controllerId);
		}
	}

	private setConnectionState(icon: ManualControlIcon) {
		this.isConnected = icon === ManualControlIcon.Connected;
		this.controlIcon = icon;
		setTimeout(() => { this.isConnectedChange.emit(this.isConnected); });
	}

	private setIsConnecting(value: boolean) {
		this._isConnecting = value;
	}

	private setIsDisconnecting(value: boolean) {
		if (value) {
			this.controlIcon = ManualControlIcon.Disconnecting;
		}
		this._isDisconnecting = value;
	}

	private reactiveConnecting() {
		this.localConnectingController = this.controllerManager.getLocalConnectingController(this.controllerId);
		if (this.localConnectingController?.isConnecting && !this.isActivedConnecting) {
			this.isActivedConnecting = true;
			this.setIsConnecting(true);
			this.setConnectionState(ManualControlIcon.Connecting);

			this.toolbarInfo?.groups.forEach(g => {
				const syncBtnIndex = g.buttons.findIndex(b => b.qaId === this.connectQaId);
				if (!!g.buttons[syncBtnIndex]) {
					g.buttons[syncBtnIndex] = this.buildConnectButton(true);
				}
			});
			return;
		}
		if (this.localConnectingController?.isConnecting === false) {
			this.setIsDisconnecting(true);
			this.toolbarInfo?.groups.forEach(g => {
				const syncBtnIndex = g.buttons.findIndex(b => b.qaId === this.connectQaId);
				if (!!g.buttons[syncBtnIndex]) {
					g.buttons[syncBtnIndex] = this.buildConnectButton(false);
				}
			});
			return;
		}
	}

	private removeConnectingControllers(satelliteId: number) {
		this.controllerManager.removeLocalConnectingController(satelliteId);
		this.isActivedConnecting = false;
		this.setIsConnecting(false);
		this.setIsDisconnecting(false);
		this.reactiveConnecting();
	}
}

export class WeatherSourceType {

	constructor(json: any = null) {

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);
		}
	}

	name: string;
	value: string;
}

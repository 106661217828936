import { CachedObject } from '../../_common/cached-object';
import { SiteCount } from './site-count.model';
import { SiteCountMap } from './site-count-map.model';

export class CompanyStatus extends CachedObject {

	constructor(json: any = null) {
		super();

		if (json) {
			Object.assign(this, json);

			if (json.siteCountMap) {
				this.siteCountMap = new SiteCountMap();

				Object.keys(json.siteCountMap).map(key => {
					this.siteCountMap[key] = new SiteCount(json.siteCountMap[key]);
					this.calculatedUnAckedAlarmCount += json.siteCountMap[key].unackedAlarmCount;
					this.calculatedUnAckedWarningCount += json.siteCountMap[key].unackedWarningCount;

					this.aggregateOutOfSyncCount += json.siteCountMap[key].outOfSyncCount;
					this.aggregateDifferencesCount += json.siteCountMap[key].differencesCount;
					this.aggregateShutdownCount += json.siteCountMap[key].shutdownCount;
					this.aggregateRainDelayCount += json.siteCountMap[key].rainDelayCount;
				});
			}
		}
	}

	calculatedUnAckedAlarmCount = 0;			// Aggregate of all sites
	calculatedUnAckedWarningCount = 0;			// Aggregate of all sites
	siteCountMap: SiteCountMap;
	unackedAlarmCount: number;
	unackedWarningCount: number;

	aggregateOutOfSyncCount = 0;
	aggregateDifferencesCount = 0;
	aggregateShutdownCount = 0;
	aggregateRainDelayCount = 0;
}

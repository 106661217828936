import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ConversionService } from '../../../../../common/services/conversion.service';
import { Duration } from 'moment';
import { GolfSensorListItem } from '../../../../../api/sensors/models/golf-sensor-list-item.model';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { SensorManagerService } from '../../../../../api/sensors/sensor-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { UnitLabelService } from '../../../../../common/services/unit-label.service';

@Component({
	selector: 'rb-golf-sensor-mobile-cell-renderer',
	templateUrl: './golf-sensor-mobile-cell-renderer.component.html',
	styleUrls: ['./golf-sensor-mobile-cell-renderer.component.scss']
})
export class GolfSensorMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	golfSensorListItem: GolfSensorListItem;
	sensorManager: SensorManagerService = null;

	get translateManager(): TranslateService {
		if (this._translateManager == null) {
			this._translateManager = this.injector.get(TranslateService);
		}
		return this._translateManager;
	}
	private _translateManager: TranslateService;

	get conversionService(): ConversionService {
		if (this._conversionService == null) {
			this._conversionService = this.injector.get(ConversionService);
		}
		return this._conversionService;
	}
	private _conversionService: ConversionService;

	get unitLabelService(): UnitLabelService {
		if (this._unitLabelService == null) {
			this._unitLabelService = this.injector.get(UnitLabelService);
		}
		return this._unitLabelService;
	}
	private _unitLabelService: UnitLabelService;

	constructor(private injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.golfSensorListItem = params.data;
		this.updateStationStatus(params);
	}

	refresh(params: any): boolean {
		this.golfSensorListItem = params.data;
		this.updateStationStatus(params);
		return true;
	}

	/**
	 * Return the HTML to render the connection state based on the sensor list item data.
	 * @param params - the golfSensorListItem which is to have connection state shown.
	 */
	getConnectedStateRenderer(golfSensorListItem: GolfSensorListItem) {
		if (golfSensorListItem != null) {
			return RbUtils.GridCellStyles.getStationConnected(golfSensorListItem);
		}
		return '';
	}

	/**
	* Return the golf rainfall string for Rain Watch, in the user's profile units (mm or inch).
	*/
	getGolfSensorRainfallString(rainfall: number): any {
		// Access the sensor manager to get the string.
		if (this.sensorManager == null) {
			this.sensorManager = this.injector.get(SensorManagerService);
		}
		return this.sensorManager.getGolfSensorRainfallString(rainfall);
	}

	/**
	 * Utility returning string formatted duration. We need to handle times greater than one day, as the user might set
	 * her shutdown time to two days or 36 hours, etc.
	 */
	durationFormat(duration: Duration): string {
		// Access the sensor manager to get the string.
		if (this.sensorManager == null) {
			this.sensorManager = this.injector.get(SensorManagerService);
		}
		return this.sensorManager.durationFormatString(duration);
	}

	// Method to flash cell when status updates occur.
	private updateStationStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		params.api.flashCells({
			rowNodes: [rowNode],
		});
	}

	/**
	 * Called to generate a cell value when the sensor does not have rainWatchData. This should indicate the sensor value
	 * and units. For FlowSensor, we convert the incoming sensor value from GPM to the user's chosen units and display
	 * that with the units. For other sensor types, we just grab the sensor value and units directly from the status
	 * message.
	 */
	getFlowOrProgrammableSensorStatus(item: GolfSensorListItem): string {
		return RbUtils.Sensor.getSensorStatus(this.conversionService, this.translateManager, this.unitLabelService, item);
	}
}

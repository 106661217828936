/* eslint-disable @typescript-eslint/indent */

import { forkJoin, iif, Observable, of, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { ProgramColumnViewConfigUI, ProgramListItem } from './models/program-list-item.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { AuthManagerService } from '../auth/auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { ConnectDataPackChange } from '../connect-data-pack/models/connect-data-pack-change.model';
import { ConnectDataPackManagerService } from '../connect-data-pack/connect-data-pack-manager.service';
import { ConnectDataPacksDict } from '../connect-data-pack/models/connect-data-packs-dict.model';
import { ControllerSyncState } from '../signalR/controller-sync-state.model';
import { ETAdjustType } from './models/et-adjust-type.model';
import { ETCheckbookUpdate } from './models/et-checkbook-update';
import { filter } from 'rxjs/internal/operators/filter';
import { GetProgramQueryParams } from './models/get-program-params.model';
import { Program } from './models/program.model';
import { ProgramApiService } from './program-api.service';
import { ProgramChange } from '../signalR/program-change.model';
import { ProgramETInformation } from './models/program-et-information.model';
import { ProgramScheduledTime } from './models/program-scheduled-time.model';
import { ProgramStepManagerService } from '../program-steps/program-step-manager.service';
import { RbConstants } from '../../common/constants/_rb.constants';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { ScheduledProgram } from './models/scheduled-program.model';
import { SeasonalAdjust } from '../sites/models/seasonal-adjust.model';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { SiteManagerService } from '../sites/site-manager.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { SystemStatusService } from '../../common/services/system-status.service';
import { TranslateService } from '@ngx-translate/core';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class ProgramManagerService extends ServiceManagerBase implements OnDestroy {
	// Subjects
	programsListChange = new Subject<ProgramListItem[]>();
	programsChange = new Subject();
	etAdjustTypeChange = new Subject<any>();
	programsUpdate = new Subject<number[]>();

	// Cache Containers
	private lastGetProgramQueryParams: GetProgramQueryParams;

	// NOTE: This manager assumes that this collection will always contain all programs. This collection should never be
	// 	     a filter or subset of programs. If you need a subset, create a separate collection. This manager also assumes
	// 		 that from load time, this collection is always populated (unless truly desiring to clear cache). There is a
	// 		 lot of logic in this manager that makes decisions whether or not this collection is populated. DO NOT BLINDLY SET IT TO NULL!
	private _apiResult: ApiCachedRequestResponse<ProgramListItem[]>;

	private defaultHoursAhead = 12;

	// =========================================================================================================================================================
	// C'tor and Destroy
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private programApiService: ProgramApiService,
				private siteManager: SiteManagerService,
				private translate: TranslateService,
				private connectDataPackManager: ConnectDataPackManagerService,
				private programStepManager: ProgramStepManagerService,
				protected broadcastService: BroadcastService,
				private systemStatusService: SystemStatusService
	) {
		super(broadcastService);

		// Monitor Site selection changes.
		this.siteManager.selectedSitesChange
			.pipe(
				untilDestroyed(this),
				switchMap(() => this.getProgramsList())
			)
			.subscribe((programs: ProgramListItem[]) => {
				this.programsListChange.next(programs);
			});

		// Monitor Sync State Changes.
		this.broadcastService.syncStateChange
			.pipe(
				untilDestroyed(this),
				filter((syncState: ControllerSyncState) => syncState.syncState === RbEnums.Common.ControllerSyncState.Synchronized),
				switchMap((syncState: ControllerSyncState) => this.getUncachedProgramsListForController(syncState.controllerId, true))
			)
			.subscribe((programs: ProgramListItem[]) => {
				this.programsListChange.next(this.programApiService.updateAndReturnCachedProgramListItems(programs));
			});

		// check if there is a program running
		this.connectDataPackManager.connectDataPacksChange
			.pipe(untilDestroyed(this))
			.subscribe((connectDataPackChange: ConnectDataPackChange) => {
				this.handleDataPackChange(connectDataPackChange);
			});

		this.systemStatusService.golfProgramStatusChange
			.pipe(untilDestroyed(this))
			.subscribe((programChange: ProgramChange) => {
				this.updateGolfProgramStatuses(programChange);
			});

		this.broadcastService.irrigationCancelled
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.updateProgramGroupsAfterCancel();
			});

		this.broadcastService.controllerRestored
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.clearCache();
			});

		this.broadcastService.programsUpdated
			.pipe(untilDestroyed(this))
			.subscribe((changes: ProgramChange[]) => {
				if (this._apiResult == null) return;
				const updatedPrograms = [];
				changes.forEach(change => {
					// Handle add, delete and update.
					if (change.changeType === RbEnums.SignalR.ProgramStatusChangeType.Deleted) {
						this._apiResult.value = this._apiResult.value.filter(item => item.id !== change.programId);
					} else if (change.changeType === RbEnums.SignalR.ProgramStatusChangeType.Added) {
						this.getAllProgramListItems(true).subscribe();
					} else if (change.changeType === RbEnums.SignalR.ProgramStatusChangeType.Updated) {
						const program = this._apiResult.value.find(p => p.id === change.programId);
						if (program == null) return;
						var changeObj = ProgramManagerService.createSimplePatchObject(change);
						if (changeObj == null) return;
						if (!this.siteManager.isGolfSite) {
							this.etAdjustTypeChange.next(changeObj);
						}
						
						Object.assign(program, new ProgramListItem(changeObj, program.status));
						updatedPrograms.push(program);
					}
				});

				this.programsListChange.next(this._apiResult.value);
			});

		// If Program Steps are added or removed, clear the cached Program List so the next caller will fetch a new one.
		// NOTE: IT IS CRITICAL THAT THE _apiResult.value always has a collection. THIS MANAGER ASSUMES THAT IS THE CASE. DO NOT SET IT TO NULL!
		// 		 Unless truly desiring to clear Cache.
		this.programStepManager.programStepsListChange
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.getProgramsList().subscribe();
			});
	}

	updateAndReturnCachedProgramListItems(programs: ProgramListItem[]) : ProgramListItem[] {
		return this.programApiService.updateAndReturnCachedProgramListItems(programs)
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	etAdjustTypeChanged() {
		this.broadcastService.associatedEntityChange.next(RbConstants.Cache.PROGRAM_STEP);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
		this.programApiService.clearCache();
		this._apiResult = null;
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================
	get cachedPrograms(): ProgramListItem[] { return this._apiResult == null ? [] : this._apiResult.value; }

	addProgramToProgramGroup(programId: number, programGroupId: number): Observable<void> {
		return this.programApiService.addProgramToProgramGroup(programId, programGroupId)
			.pipe(tap(() => this.broadcastService.collectionChange.next(this._apiResult.value.slice())));
	}

	createProgram(programUpdate: any): Observable<Program> {
		return this.programApiService.createProgram(programUpdate)
			.pipe(tap(() => this.programsChange.next(null)));
	}

	deletePrograms(programIds: number[]): Observable<void> {
		return this.programApiService.deletePrograms(programIds)
			.pipe(tap(() => {
					this._apiResult.value = this._apiResult.value.filter(p => programIds.indexOf(p.id) === -1);
					this.programsListChange.next([]);

					// NOTE: Purposely firing a seemingly redundant event so it can be consumed by other services without creating a
					// 		 potentially circular reference.
					this.broadcastService.collectionChange.next(this._apiResult.value.slice());
				}
			));
	}

	getEtAdjustTypes(): Observable<ETAdjustType[]> {
		return this.programApiService.getEtAdjustTypes().pipe(map(response => response.value));
	}

	getNameUniqueness(name: string, satelliteId: number, programGroupId: number, programId: number)
		: Observable<UniquenessResponse> {
		return this.programApiService.getNameUniqueness(name, satelliteId, programGroupId, programId);
	}

	getNumberUniqueness(number: number, programGroupId: number, programId: number): Observable<UniquenessResponse> {
		return this.programApiService.getNumberUniqueness(number, programGroupId, programId);
	}

	getNextStartTime(): Observable<string> {
		return this.programApiService.getNextStartTime();
	}

	getScheduledStartTimes(controllerId: number): Observable<ProgramScheduledTime[]> {
		return this.programApiService.getScheduledStartTimes(controllerId);
	}

	getProgram(id: number, queryParams?: GetProgramQueryParams, bypassCache = false): Observable<Program> {
		const selectedQueryParams = queryParams ? queryParams : this.lastGetProgramQueryParams;
		return this.programApiService.getProgram(id, selectedQueryParams, bypassCache);
	}

	getProgramSimpleETInformation(id: number): Observable<ProgramETInformation> {
		return this.programApiService.getProgramSimpleETInformation(id);
	}

	getProgramSiteSeasonalAdjustInformation(siteId: number): Observable<SeasonalAdjust> {
		return this.programApiService.getProgramSiteSeasonalAdjustInformation(siteId);
	}

	getProgramsByProgramGroupId(programGroupId: number, bypassCache = false): Observable<ProgramListItem[]> {
		return this.getProgramsList(bypassCache)
			.pipe(map((programsList: ProgramListItem[]) => {
				return programsList.filter(p => p.programGroupId === programGroupId).sort((a, b) => a.name.localeCompare(b.name));
			}));
	}

	getProgramFromProgramsList(id: number): Observable<ProgramListItem> {
		return this.getProgramsList().pipe(map((programsList: ProgramListItem[]) => programsList.find(pli => pli.id === id)));
	}

	updateProgramsListForDeletedControllers(controllerIds: number[]): Observable<ProgramListItem[]> {
		if (!this._apiResult?.value) {
			return of([]);
		}
		controllerIds.forEach(id => {
			this._apiResult.value = this._apiResult.value.filter(p => p.satelliteId !== id);
		});
		return of(this._apiResult.value);
	}

	getProgramsList(bypassCache = false, bypassSiteFilter = false): Observable<ProgramListItem[]> {
		return this.programApiService.getProgramsList(bypassCache).pipe(
			switchMap((result) => {
				this._apiResult = result;
				return iif(() => result.isFromCache, of(result.value), this.updateProgramStatusesOnFetch(result.value))
			}),
			map(ProgramListItems => {
				// Sometimes we need the entire list for lookup purposes.
				// NOTE: This is required for System Change Log Report.
				if (bypassSiteFilter) { return ProgramListItems; }

				return this.getProgramListItemsForSelectedSites(ProgramListItems);
		}));
	}

	getProgramsListForController(controllerId: number): Observable<ProgramListItem[]> {
		return this.getProgramsList()
			.pipe(map(programs => programs.filter(p => p.satelliteId === controllerId)));
	}

	getUncachedProgramsListForController(controllerId: number, bypassCache = false): Observable<ProgramListItem[]> {
		return this.programApiService.getUncachedProgramsListForController(controllerId, bypassCache);
	}

	getPrograms(controllerId: number, bypassCache = false): Observable<Program[]> {
		return this.programApiService.getPrograms(controllerId, bypassCache).pipe(map(result => result.value));
	}

	getScheduledProgramsForSelectedSites(hoursAhead: number = 12): Observable<ScheduledProgram[]> {
		const siteIds = this.siteManager.selectedSiteIds.length > 0 ? this.siteManager.selectedSiteIds : this.siteManager.siteIds;

		// RB-6291: Don't test if(array); you must check for null and undefined.
		if (siteIds == null || siteIds.length < 1) {
			return this.siteManager.getSites(true)
				.pipe(switchMap(() => this.programApiService.getScheduledPrograms(this.siteManager.siteIds, hoursAhead)));
		}

		return this.programApiService.getScheduledPrograms(siteIds, hoursAhead);
	}

	getScheduledProgramsForSite(siteId: number, hoursAhead: number = 12): Observable<ScheduledProgram[]> {
		return this.programApiService.getScheduledPrograms([siteId], hoursAhead);
	}

	getScheduledProgramsTreeViewForMultiSites(hoursAhead = this.defaultHoursAhead) {
		const siteIds = this.siteManager.selectedSiteIds.length > 0 ? this.siteManager.selectedSiteIds : this.siteManager.siteIds;
		if (!siteIds || siteIds.length < 1) {
			return this.siteManager.getSites(true)
				.pipe(switchMap(() => this.programApiService.getScheduledProgramsTreeViewForMultiSites(this.siteManager.siteIds, hoursAhead)));
		}

		return this.programApiService.getScheduledProgramsTreeViewForMultiSites(siteIds, hoursAhead);
	}

	getLocalStoreProgramColumnViewConfig(key: string) : ProgramColumnViewConfigUI {
		return new ProgramColumnViewConfigUI(JSON.parse(localStorage.getItem(key)));
	};

	setLocalStoreProgramColumnViewConfig(key: string, data: ProgramColumnViewConfigUI) {
		localStorage.setItem(key, JSON.stringify(data));
	};

	addEtCheckbookForPrograms(etChecbookUpdate: ETCheckbookUpdate){
		return this.programApiService.addEtCheckbookForPrograms(etChecbookUpdate);
	}

	updatePrograms(programIds: number[], programUpdate: any, programGroupId?: number[]): Observable<null> {
		return this.programApiService.updatePrograms(programIds, programUpdate)
			.pipe(tap(() => {
				this.programsUpdate.next(programIds);
				if (programUpdate['programAdjust'] != null || programUpdate['tempProgramAdjust'] != null) {
					this.programStepManager.programsOrProgramGroupsUpdated(programGroupId != null ? programGroupId : programIds);
				}
				this.programsChange.next(null);
				this.programsListChange.next([]);
			}));
	}

	// ====================================================================================================
	// Fetch and cache all program lists items. Used by BreadCrumb Service for fast lookup of program names
	// ====================================================================================================

	getAllProgramListItems(bypassCache = false): Observable<ProgramListItem[]> {
		return this.getProgramsList(bypassCache);
	}

	getProgramListItem(programId: number, bypassCache = false) {
		return this.getAllProgramListItems(bypassCache)
			.pipe(map((programsList: ProgramListItem[]) => programsList.find(c => c.id === programId)));
	}

	getControllerIdsFromProgramIds(programIds: number[]): Observable<number[]> {
		return this.getAllProgramListItems()
			.pipe(
				take(1),
				map(response => {
					const programsOfInterest = response.filter(p => programIds.indexOf(p.id) > -1).filter((value, index, self) => self
						.indexOf(value) === index);
					return programsOfInterest.map(p => p.satelliteId);
				})
			);
	}

	getProgramsForWeatherSource(weatherSourceId: number): Observable<any | null> {
		return this.programApiService.getProgramsForWeatherSource(weatherSourceId);
	}

	checkProgramHasStationInOtherAdvancedEtPrograms(programId: number): Observable<boolean> {
		return this.programApiService.checkProgramHasStationInOtherAdvancedEtPrograms(programId);
	}

	getProgramListItemsForSelectedSites(programs: ProgramListItem[]): ProgramListItem[] {
		if (this.siteManager.selectedSiteIds.length) {
			return programs.filter(s => this.siteManager.selectedSiteIds.includes(s.siteId));
		}
		return programs;
	}
	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================
	private updateProgramGroupsAfterCancel() {
		if (this._apiResult && this._apiResult.value.length > 0) {
			this._apiResult.value.forEach(s => s.status = this.getGolfStatusString(null));
			this.programsListChange.next(null);
		}
	}

	/**
	 * @summary Update the status of all cached programs when a new ConnectDataPack arrives.
	 * @param connectDataPackChange - The details of the newly arrived connect data pack (status) information.
	 */
	private handleDataPackChange(connectDataPackChange: ConnectDataPackChange) {
		const controllerId = connectDataPackChange.controllerId;

		if (!this._apiResult || this._apiResult.value.length < 1) return;

		const list = this._apiResult.value.filter(program => program.satelliteId === controllerId);
		// RB-6291: Don't test if(array); you must check for null and undefined.
		if (list == null || list.length < 1) return;

		// RB-6599: We'll handle the updates asynchronously and send a programsListChange.next() if anything actually changed.
		const results = list.map(program => this.updateProgramListItemWithStatusResultFast(program, connectDataPackChange.connectDataPacks));
		if (results.some((value, index, array) => value === true)) {
			// Send a notification.
			this.programsListChange.next(list);
		}
	}

	private updateGolfProgramStatuses(golfProgramStatus: ProgramChange) {
		if (!this._apiResult || this._apiResult.value.length < 1) return;

		this._apiResult.value.forEach(program => {
			if (program.id === golfProgramStatus.programId &&
				RbUtils.Programs.isProgramRealTimeStatusMessage(golfProgramStatus.changeType)) {
					const newStatusString = this.getGolfStatusString(golfProgramStatus);
					program.status = newStatusString;
			}
		});

		this.programsListChange.next(this._apiResult.value);
	}

	/**
	 * @summary Update the status of a list of programs about to be cached after fetch from the API. This method
	 * is typically called when the cache is invalid or empty and must be completely repopulated, with status
	 * retrieved for all programs.
	 * @param programs - ProgramListItem[] to be updated.
	 */
	private updateProgramStatusesOnFetch(programs: ProgramListItem[]): Observable<ProgramListItem[]> {
		// RB-6599: Make sure we send a list-change notification. We do everything in parallel until the end,
		// then use the accumulated change indicator to call next() or not.
		const isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		const operations = isGolfSite ?
			programs.map(program => this.updateGolfProgramStatus(program, this.systemStatusService.getGolfProgramStatus(program.id))) :
			// programs.map(program => this.updateProgramStatus(program, this.connectDataPackManager.connectDataPacks));
			[this.updateProgramStatuses(programs, this.connectDataPackManager.connectDataPacks)];
		return forkJoin(operations).pipe(
			take(1),
			switchMap((results) =>{
				if(isGolfSite) {
					// RB-123362: This is an old logic, so we keep this logic for the golfsite
					if (results.some((value, index, array) => value === true)) {
						if (this._apiResult)
							this.programsListChange.next(this._apiResult.value);
					}
				}
				return of(programs)
			}));
	}

	private updateProgramStatuses(programs: ProgramListItem[], connectDataPacks: ConnectDataPacksDict): Observable<any> {
		const changes = [];
		programs.forEach(program => {
			const change = this.updateProgramListItemWithStatusResultFast(program, connectDataPacks ? 
				connectDataPacks : this.connectDataPackManager.connectDataPacks);
			changes.push(change);
		});
		return of(changes);
	}

	private updateProgramListItemWithStatusResultFast(programListItem: ProgramListItem,
		connectDataPacks: ConnectDataPacksDict): boolean {
		const oldStatus = programListItem.status;
		let status = RbEnums.Common.IrrigationStatus.Dash;

		// RB-14842: To update program status, instead of check if any runsteps of program is in datapack,
		// we will check if datapack contains any records for the program.
		// This way, we don't need to get runsteps terminal, therefore, we can drop the API of GetRunStationInfoOfPrograms
		const connectDataPack = connectDataPacks ? connectDataPacks[programListItem.satelliteId] : null;
		if (connectDataPack && connectDataPack.irrigationQueue && connectDataPack.irrigationQueue.length > 0) {
			connectDataPack.irrigationQueue.forEach(queueItem => {
				const newStatus = RbUtils.Stations.getStationStatus(queueItem.stationNumber, programListItem.satelliteId, connectDataPacks);
				if (newStatus.programId + 1 === programListItem.number && status.valueOf() < newStatus.irrigationStatus.valueOf()) {
					status = newStatus.irrigationStatus;
				}
			});
		}

		this.updateProgramListItemWithStatusEx(programListItem, connectDataPacks, status);
		return oldStatus != programListItem.status;
	}

	private updateProgramListItemWithStatusEx(programListItem: ProgramListItem, connectDataPacks: ConnectDataPacksDict,
		status: RbEnums.Common.IrrigationStatus) {
		// Handle 'Posted' Programs.
		// These are Programs that have been started, but have not received any info from the Irrigation Engine (yet).
		if (status === RbEnums.Common.IrrigationStatus.Dash && connectDataPacks && connectDataPacks[programListItem.satelliteId]) {
			status = RbEnums.Common.IrrigationStatus.Idle;
			if (connectDataPacks[programListItem.satelliteId].postedPrograms.includes(programListItem.id)) {
				status = RbEnums.Common.IrrigationStatus.ManuallyStarted;
			}
		}

		programListItem.status = this.getStatusString(status);
	}

	private updateGolfProgramStatus(program: ProgramListItem, programChange?: ProgramChange): Observable<boolean> {
		// If no programChange, return with 'no status'.
		if (programChange != null && 
			(program.id !== programChange.programId || !RbUtils.Programs.isProgramRealTimeStatusMessage(programChange.changeType))) {
				return of(false);
			}

		const newStatusString = this.getGolfStatusString(programChange);
		const diff = (newStatusString !== program.status);
		program.status = newStatusString;
		return of(diff);
	}

	private getStatusString(status: RbEnums.Common.IrrigationStatus): string {
		switch (status) {
			case RbEnums.Common.IrrigationStatus.Dash:
				return '-';
			case RbEnums.Common.IrrigationStatus.Idle:
				return this.translate.instant('STRINGS.IDLE');
			case RbEnums.Common.IrrigationStatus.Pending:
				return this.translate.instant('STRINGS.PENDING');
			case RbEnums.Common.IrrigationStatus.Delaying:
					return this.translate.instant('STRINGS.DELAYING');
			case RbEnums.Common.IrrigationStatus.Soaking:
				return this.translate.instant('STRINGS.SOAKING');
			case RbEnums.Common.IrrigationStatus.Paused:
				return this.translate.instant('STRINGS.PAUSED');
			case RbEnums.Common.IrrigationStatus.Running:
				return this.translate.instant('STRINGS.RUNNING');
			case RbEnums.Common.IrrigationStatus.ManuallyStarted:
				return this.translate.instant('STRINGS.POSTED');
			default:
				return '-';
		}
	}

	private getGolfStatusString(programChange: ProgramChange) {
		if (programChange == null)
			return '-';

		switch (programChange.changeType) {
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramStarted:
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramResumed:
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramRunningUpdate:
				return this.translate.instant('STRINGS.RUNNING');
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramPaused:
				return RbUtils.Translate.instant('STRINGS.PAUSED');
			case RbEnums.SignalR.ProgramStatusChangeType.Waiting:
				return RbUtils.Translate.instant('STRINGS.WAITING');
			case RbEnums.SignalR.ProgramStatusChangeType.Posted:
				return RbUtils.Translate.instant('STRINGS.POSTED');
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted:
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramCancelled:
			default:
				return '-';
		}

	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	static createSimplePatchObject(change: ProgramChange) {
		if (change.itemsChanged == null || change.itemsChanged.patch == null) return;

		const updateObject = {};
		change.itemsChanged.patch.forEach(item => {
			if (item.op === 'replace') updateObject[item.path.replace('/', '')] = item.value;
		});
		return updateObject;
	}
}

<div class="ccweather-widget-hourly-section" [ngClass]="{'is-sidebar-widget': isIq4SidebarWidget, 'light-theme': theme === 'light'}">
	<div class="ccweather-hourly-header-row">
		<span class="ccweather-hourly-header">{{('STRINGS.HOURLY_FORECAST' | translate).toUpperCase()}}</span>
		<span class="ccweather-hourly-header-date">{{todayDate}}</span>
	</div>
	<div class="ccweather-widget-hourly-time-row">
		<div class="ccweather-widget-hourly-time-row-item">
			<img [src]="'../../../../../assets/images/ccweather_icons/hourly/sunrise_small.svg'">
			<span>{{sunriseTime}}</span>
		</div>
		<div class="ccweather-widget-hourly-time-row-item">
			<span>{{sunsetTime}}</span>
			<img [src]="'../../../../../assets/images/ccweather_icons/hourly/sunset_small.svg'">
		</div>
	</div>
	<div class="ccweather-hourly-table-row" *ngIf="!isBusy">
		<div class="ccweather-hourly-table-column ccweather-hourly-table-first-column"
			 [ngClass]="{'ccweather-hourly-table-first-column-expanded': isExpanded}"
		>
			<div class="ccweather-hourly-table-cell ccweather-hourly-table-header-cell">
				<button mat-icon-button [disabled]="startHour === 0" (click)="onTableScroll(-1)"><mat-icon>chevron_left</mat-icon></button>
			</div>
			<div class="ccweather-hourly-table-values-column" (click)="expandIcons()">
				<div class="ccweather-hourly-table-cell"
					 *ngFor="let item of conditions"
					 [ngClass]="{'ccweather-hourly-wind-table-cell': item.fieldName === 'wind'}"
				>
					<div class="ccweather-hourly-table-icon">
						<img [src]="'../../../../../../assets/images/ccweather_icons/hourly/' + item.iconName + '.svg'">
					</div>
					<div *ngIf="isExpanded" class="ccweather-hourly-table-expanded-text">{{('STRINGS.' + item.hint) | translate}}</div>
				</div>
			</div>
		</div>
		<div class="ccweather-hourly-table-column ccweather-hourly-table-column-no-margin">
			<div class="ccweather-hourly-table-cell ccweather-hourly-table-header-cell" [ngClass]="{'ccweather-hourly-underline': hoursFull[startHour] === 'Now'}">
				{{hoursFull[startHour]}}
			</div>
			<div class="ccweather-hourly-table-values-column">
				<div class="ccweather-hourly-table-cell"
					 *ngFor="let item of conditions"
					 [ngClass]="{'ccweather-hourly-wind-table-cell': item.fieldName === 'wind'}"
				>
					{{getFieldValue(item, 0)}}
				</div>
			</div>
		</div>
		<div class="ccweather-hourly-table-column">
			<div class="ccweather-hourly-table-cell ccweather-hourly-table-header-cell" [ngClass]="{'ccweather-hourly-underline': hoursFull[startHour + 1] === 'Now'}">
				{{hoursFull[startHour + 1]}}
			</div>
			<div class="ccweather-hourly-table-values-column">
				<div class="ccweather-hourly-table-cell"
					 *ngFor="let item of conditions"
					 [ngClass]="{'ccweather-hourly-wind-table-cell': item.fieldName === 'wind'}"
				>
					{{getFieldValue(item, 1)}}
				</div>
			</div>
		</div>
		<div class="ccweather-hourly-table-column">
			<div class="ccweather-hourly-table-cell ccweather-hourly-table-header-cell" [ngClass]="{'ccweather-hourly-underline': hoursFull[startHour + 2] === 'Now'}">
				{{hoursFull[startHour + 2]}}
			</div>
			<div class="ccweather-hourly-table-values-column">
				<div class="ccweather-hourly-table-cell"
					 *ngFor="let item of conditions"
					 [ngClass]="{'ccweather-hourly-wind-table-cell': item.fieldName === 'wind'}"
				>
					{{getFieldValue(item, 2)}}
				</div>
			</div>
		</div>
		<div class="ccweather-hourly-table-column">
			<div class="ccweather-hourly-table-cell ccweather-hourly-table-header-cell" [ngClass]="{'ccweather-hourly-underline': hoursFull[startHour + 3] === 'Now'}">
				{{hoursFull[startHour + 3]}}
			</div>
			<div class="ccweather-hourly-table-values-column">
				<div class="ccweather-hourly-table-cell"
					 *ngFor="let item of conditions"
					 [ngClass]="{'ccweather-hourly-wind-table-cell': item.fieldName === 'wind'}"
				>
					{{getFieldValue(item, 3)}}
				</div>
			</div>
		</div>
		<div class="ccweather-hourly-table-column" *ngIf="!isExpanded">
			<div class="ccweather-hourly-table-cell ccweather-hourly-table-header-cell" [ngClass]="{'ccweather-hourly-underline': hoursFull[startHour + 4] === 'Now'}">
				{{hoursFull[startHour + 4]}}
			</div>
			<div class="ccweather-hourly-table-values-column">
				<div class="ccweather-hourly-table-cell"
					 *ngFor="let item of conditions"
					 [ngClass]="{'ccweather-hourly-wind-table-cell': item.fieldName === 'wind'}"
				>
					{{getFieldValue(item, 4)}}
				</div>
			</div>
		</div>
		<div class="ccweather-hourly-table-column ccweather-hourly-table-last-column">
			<div class="ccweather-hourly-table-cell ccweather-hourly-table-header-cell">
				<button mat-icon-button [disabled]="startHour === (6 + (isExpanded ? 1 : 0))" (click)="onTableScroll(1)"><mat-icon>chevron_right</mat-icon></button>
			</div>
			<div class="ccweather-hourly-table-values-column">
				<div class="ccweather-hourly-table-cell"
					 *ngFor="let item of conditions"
					 [ngClass]="{'ccweather-hourly-wind-table-cell': item.fieldName === 'wind'}"
				>
					{{item.units}}
				</div>
			</div>
		</div>
	</div>
</div>

import { RbUtils } from '../../../common/utils/_rb.utils';

export class VoltageDiagnostic {

	stationId: number;
	voltage: number;
	id: number;
	companyId: number;
	updateTime: Date;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.updateTime) { this.updateTime = RbUtils.Conversion.convertStringToDate(json.updateTime); }
		}
	}
}

import * as moment from 'moment';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { DateAdapter } from '@angular/material/core';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { RbConstants } from '../../../../../common/constants/_rb.constants';

@Component({
	selector: 'rb-time-period',
	templateUrl: './time-period.component.html',
	styleUrls: ['./time-period.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: DateAdapter, useClass: AppDateAdapter
		},
	]
})

export class TimePeriodComponent implements OnInit {

	@Input() oneTimePeriod = true;

	@Output() timePeriodChange = new EventEmitter();

	endDateCurrent;
	endDatePrevious;
	startDateCurrent;
	startDatePrevious;

	RbFormConstants = RbConstants.Form;

	constructor(public deviceManager: DeviceManagerService) { }

	ngOnInit() {
		this.endDateCurrent = this.formatMomentDate(moment());
		if (this.oneTimePeriod) {
			this.startDateCurrent = moment().subtract(1, 'weeks');
		} else {
			this.startDateCurrent = moment().subtract(1, 'months');
		}
		this.endDatePrevious = this.startDateCurrent;
		this.startDatePrevious = moment().subtract(2, 'months');
		this.startDateCurrent = this.formatMomentDate(this.startDateCurrent);
		this.endDatePrevious = this.formatMomentDate(this.endDatePrevious);
		this.startDatePrevious = this.formatMomentDate(this.startDatePrevious);
		this.timePeriodChange.emit(this.checkDateRange());
	}

	checkDateRange() {
		return {
			startDateCurrent: this.startDateCurrent,
			endDateCurrent: this.endDateCurrent,
			startDatePrevious: this.startDatePrevious,
			endDatePrevious: this.endDatePrevious,
		};
	}

	changeTimePeriod(tag: string, date) {
		const dateMoment = moment(date.value);
		switch (tag) {
			case RbConstants.Form.TIME_PERIOD.currentStart:
				this.startDateCurrent = this.formatMomentDate(dateMoment);
				break;
			case RbConstants.Form.TIME_PERIOD.currentEnd:
				this.endDateCurrent = this.formatEndTime(dateMoment);
				break;
			case RbConstants.Form.TIME_PERIOD.previousStart:
				this.startDatePrevious = this.formatMomentDate(dateMoment);
				break;
			case RbConstants.Form.TIME_PERIOD.previousEnd:
				this.endDatePrevious = this.formatEndTime(dateMoment);
				break;
		}
		this.timePeriodChange.emit(this.checkDateRange());
	}
	private formatEndTime(value: moment.Moment) {
		const endOfDay = value.endOf('day').toDate();
		const now = new Date();
		return now < endOfDay ? now : endOfDay;
	}
	private formatMomentDate(value) {
		return value.toDate();
	}
}

import { CAROUSEL_TRANSITION_DURATION, slideInLeft, slideInRight, slideOutLeft, slideOutRight } from '../carousel.animations';
import { CarouselTransition, CarouselTransitionDirection } from '../enums/carousel.enums';
import { Component, HostBinding, Input } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { CarouselItemTransition } from '../models/carousel-item-transition';

@Component({
	selector: 'rb-carousel-item',
	templateUrl: './carousel-item.component.html',
	styleUrls: ['./carousel-item.component.scss'],
	animations: [
		trigger('carouselAnimation', [
			transition(`* => ${CarouselTransition.InLeft}`, [useAnimation(slideInLeft)]),
			transition(`* => ${CarouselTransition.OutLeft}`, [useAnimation(slideOutLeft)]),
			transition(`* => ${CarouselTransition.InRight}`, [useAnimation(slideInRight)]),
			transition(`* => ${CarouselTransition.OutRight}`, [useAnimation(slideOutRight)]),
		]),
	]
})
export class CarouselItemComponent {
	@HostBinding('class') class = 'rb-carousel-item is-hidden';

	carouselAnimationState: string = null;
	isVisible = false;

	@Input() set transitionInfo(value: CarouselItemTransition) {

		switch (value.direction) {
			case CarouselTransitionDirection.None:
				this.carouselAnimationState = null;
				break;
			case CarouselTransitionDirection.Left:
				this.carouselAnimationState = value.isVisible ? CarouselTransition.InLeft : CarouselTransition.OutLeft;
				break;
			case CarouselTransitionDirection.Right:
				this.carouselAnimationState = value.isVisible ? CarouselTransition.InRight : CarouselTransition.OutRight;
				break;
		}

		if (value.isVisible) {
			this.isVisible = value.isVisible;
			this.class = 'rb-carousel-item';
		} else {
			setTimeout(() => {
				this.isVisible = value.isVisible;
				this.class = 'rb-carousel-item is-hidden';
			}, CAROUSEL_TRANSITION_DURATION - 50);
		}
	}

}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { DatePipe } from '@angular/common';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { Router } from '@angular/router';
import { WeatherSource } from '../../../../../api/weather-sources/models/weather-source.model';

@Component({
	selector: 'rb-weather-sources-mobile-cell-renderer',
	templateUrl: './weather-sources-mobile-cell-renderer.component.html',
	styleUrls: ['./weather-sources-mobile-cell-renderer.component.scss']
})
export class WeatherSourcesMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	weatherSource: WeatherSource;
	datePipe: DatePipe;
	isShowViewWeather = false;

	constructor(private injector: Injector, protected router: Router) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();

		this.datePipe = this.injector.get(DatePipe);
		this.isShowViewWeather = RbUtils.Common.isSupportedWeatherSourceForCalendar(this.weatherSource.type) && !this.isGolfSite;
	}

	agInit(params: any): void {
		super.agInit(params);
		this.weatherSource = params.data;
	}

	navigateToETCalendarView() {
		this.router.navigate(['systemsetup/weather/et-calendar'], { queryParams: { weatherSourceId: this.weatherSource.id }});
	}

	/**
	 * For golf, used to render the WeatherSource ET value and a label describing it.
	 * @param etValue - the inches of ET to be rendered here
	 * @returns string containing the text to be rendered in the ET portion of the cell
	 */
	etRenderer(inchesET: number) {
		return (inchesET) ? RbUtils.Sensor.getGolfSensorETString(inchesET) : '--';
	}

	/**
	 * For golf, used to render the WeatherSource next contact time with a label describing it.
	 * @param nextContactDate - Date containing the next contact date/time
	 * @returns string containing the text to be rendered in the next contact portion of the cell
	 */
	nextContactRenderer(nextContactDate: Date) {
		const timePickerDisplay = RbUtils.Common.getTimePickerDisplayValue(this.authManager.userCulture);

		return (nextContactDate) ? this.datePipe.transform(nextContactDate, timePickerDisplay) : '--';
	}
}

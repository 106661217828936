<div class="rb-direct-time-input-container" [formGroup]="mainForm" #runTimeContainer>
	<input #hours class="rb-duration-cell" 
		rbGroupDecimalSeparator
		[isOnlyInteger]="true"
		[min]="0"
		[max]="maxHours"
		[maxlength]="2"
		formControlName="hour"
		/>
	<span class="rb-duration-cell-sep">:</span>
	<input #minutes class="rb-duration-cell"
		rbGroupDecimalSeparator
		[isOnlyInteger]="true"
		[min]="0"
		[max]="59"
		[maxlength]="2"
		formControlName="minute"
		/>
	<span class="rb-duration-cell-sep">:</span>
	<input #seconds class="rb-duration-cell" 
		rbGroupDecimalSeparator
		[isOnlyInteger]="true"
		[min]="0"
		[max]="59"
		[maxlength]="2"
		formControlName="second"
		/>
</div>

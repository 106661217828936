import * as moment from 'moment';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { NgForm } from '@angular/forms';
import { RbConstants } from '../../../../common/constants/_rb.constants';
import { TranslateService } from '@ngx-translate/core';

import Timer = NodeJS.Timer;

enum TimePart {
	Day,
	Hour,
	Minute,
	Second
}

enum TimeField {
	Days = 'days',
	Hours = 'hours',
	Minutes = 'minutes',
	Seconds = 'seconds'
}

@Component({
	selector: 'rb-sensor-duration-selector-dialog',
	templateUrl: './sensor-duration-selector-dialog.component.html',
	styleUrls: ['./sensor-duration-selector-dialog.component.scss'],
	animations: [
		trigger('scrollNumber', [
			state('up', style({ transform: 'translateY(0)' })),
			state('down', style({ transform: 'translateY(0)' })),
			transition('* => up', [
				animate(80, keyframes([
					style({ transform: 'translateY(0%)', offset: 0 }),
					style({ transform: 'translateY(-50%)', offset: 1 })
				]))
			]),
			transition('* => down', [
				animate(80, keyframes([
					style({ transform: 'translateY(0%)', offset: 0 }),
					style({ transform: 'translateY(50%)', offset: 1 })
				]))
			])
		])
	]
})
export class SensorDurationSelectorDialogComponent implements OnInit {
	@HostBinding('class') class = 'rb-duration';

	@ViewChild(NgForm, { static: true }) form: NgForm;

	@Output() durationChange = new EventEmitter<moment.Duration>();

	@Input() duration = moment.duration();
	@Input() maxDuration: moment.Duration = null;
	@Input() hideDays = true;
	@Input() hideHours = false;
	@Input() hideMinutes = false;
	@Input() hideSeconds = false;
	@Input() showHeader = true;
	@Input() showButtons = true;
	@Input() showSlider = true;
	@Input() dialogRef: MatDialogRef<any>;
	@Input() title: string;

	private _isReadOnly = false;
	@Input() set isReadOnly(value: boolean) { if (value != null) this._isReadOnly = value; }
	get isReadOnly(): boolean { return this._isReadOnly; }

	days = 0;
	daysString: String;
	hours = 0;
	hoursString: String;
	minutes = 0;
	minutesString: String;
	seconds = 0;
	secondsString: String;
	daysScrollState = '';
	hoursScrollState = '';
	minutesScrollState = '';
	secondsScrollState = '';
	durationDialogTitle: string = null;

	isMaxDuration = false;
	isMaxDays = false;
	isMaxHours = false;
	isMaxMinutes = false;
	isMaxSeconds = false;
	maxDays = RbConstants.Form.DURATION_DAYS.max;
	maxHours = RbConstants.Form.DURATION_HOURS.max;
	maxMinutes = RbConstants.Form.DURATION_MINUTES.max;
	maxSeconds = RbConstants.Form.DURATION_SECONDS.max;

	RbConstants = RbConstants;

	private isButtonDown = false;
	private receivedTouchEvent = false;
	private repeatIncrement = 0;
	private repeatSpeedIncrement = 0;
	private repeatTimer: Timer;
	private repeatIncrementFunction: any;
	private repeatFieldToIncrement: string;
	private repeatIntervalFiredCount = 0;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				public dialog: MatDialog,
				private translate: TranslateService) { }

	ngOnInit() {
		this.isReadOnly = this.authManager.isReadOnly;
		if (this.title == null) this.title = this.translate.instant('STRINGS.SET_DURATION');

		this.setInitialTimeFields();
		this.setInitialButtonState();
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	padNumber(value: number | null) {
		return (value == null ? '0' : value.toString()).padStart(2, '0');
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	cancel() {
		this.dialogRef == null ? this.dialog.closeAll() : this.dialogRef.close();
	}

	submit() {
		const duration = moment.duration({
			days: this.days,
			hours: this.hours,
			minutes: this.minutes,
			seconds: this.seconds,
		});
		this.durationChange.emit(duration);
		this.dialogRef == null ? this.dialog.closeAll() : this.dialogRef.close();
	}

	onDaysChanged(days: number, scrollDirection: string): boolean {
		if (days < RbConstants.Form.DURATION_DAYS.min) return false;

		// Touch events are not disabled when the buttons associated with this event is disabled (mobile). To avoid
		// undesired behavior (i.e., incrementing beyond max boundary), check if we are already at max value. If so, bail out.
		if (scrollDirection === 'up' && this.isFieldAtMaxValue(TimeField.Days)) { return false; }

		let valid = true;
		const maxDays = this.maxDuration.days();
		this.isMaxDays = !this.isDurationValid(days);

		if (this.isMaxDuration) {
			this.days = maxDays;
			this.hours = 0;
			this.minutes = 0;
			this.seconds = 0;
			valid = false;
		} else {
			this.days = days;
			this.daysScrollState = scrollDirection;
		}

		this.setIncrementControlsState(TimePart.Hour);

		if (!this.showHeader) {this.onValueChange(); }
		this.updateTimeStrings();

		return valid;
	}

	onHoursChanged(hours: number, scrollDirection: string): boolean {
		if (hours < RbConstants.Form.DURATION_HOURS.min) { return false; }

		// Touch events are not disabled when the buttons associated with this event is disabled (mobile). To avoid
		// undesired behavior (i.e., incrementing beyond max boundary), check if we are already at max value. If so, bail out.
		if (scrollDirection === 'up' && this.isFieldAtMaxValue(TimeField.Hours)) { return false; }

		let valid = true;

		let maxHours = this.isMaxDays ? this.maxDuration.hours() : this.maxHours;
		if (this.hideDays) {
			const tempHours = this.maxDuration.hours() + this.maxDuration.days() * 24;
			if (tempHours <= this.maxHours) { maxHours = tempHours; }
		}

		this.isMaxHours = hours >= maxHours;

		if (!this.isDurationValid(null, hours) && this.isMaxHours) {
			this.hours = maxHours;
			this.minutes = 0;
			this.seconds = 0;
			valid = false;
		} else {
			this.hours = hours;
			this.hoursScrollState = scrollDirection;
		}

		this.setIncrementControlsState(TimePart.Minute);

		if (!this.showHeader)
			this.onValueChange();
		else
			this.updateTimeStrings();

		return valid;
	}

	onMinutesChanged(minutes, scrollDirection: string) {
		if (minutes < RbConstants.Form.DURATION_MINUTES.min) { return false; }

		// Touch events are not disabled when the buttons associated with this event is disabled (mobile). To avoid
		// undesired behavior (i.e., incrementing beyond max boundary), check if we are already at max value. If so, bail out.
		if (scrollDirection === 'up' && this.isFieldAtMaxValue(TimeField.Minutes)) { return false; }

		let valid = true;
		const maxMinutes = this.isMaxDays && this.isMaxHours ? this.maxDuration.minutes() : this.maxMinutes;
		this.isMaxMinutes = minutes >= maxMinutes;

		if (!this.isDurationValid(null, null, minutes) && this.isMaxMinutes) {
			this.minutes = maxMinutes;
			valid = false;
		} else {
			this.minutes = minutes;
			this.minutesScrollState = scrollDirection;
		}

		this.setIncrementControlsState(TimePart.Second);

		if (this.minutes === maxMinutes && this.hideHours) { this.seconds = 0; }

		if (!this.showHeader)
			this.onValueChange();
		else
			this.updateTimeStrings();

		return valid;
	}

	onSecondsChanged(seconds: number, scrollDirection: string) {
		if (seconds < RbConstants.Form.DURATION_SECONDS.min) { return false; }

		// Touch events are not disabled when the buttons associated with this event is disabled (mobile). To avoid
		// undesired behavior (i.e., incrementing beyond max boundary), check if we are already at max value. If so, bail out.
		if (scrollDirection === 'up' && this.isFieldAtMaxValue(TimeField.Seconds)) { return false; }

		let valid = true;
		const maxSeconds = this.isMaxDays && this.isMaxHours && this.isMaxMinutes ? this.maxDuration.seconds() : RbConstants.Form.DURATION_SECONDS.max;
		this.isMaxSeconds = seconds >= maxSeconds;

		if (!this.isDurationValid(null, null, null, seconds) && this.isMaxSeconds) {
			this.seconds = maxSeconds;
			valid = false;
		} else {
			this.seconds = seconds;
			this.secondsScrollState = scrollDirection;
		}

		if (!this.showHeader)
			this.onValueChange();
		else
			this.updateTimeStrings();

		return valid;
	}

	onValueChange() {
		const duration = moment.duration({
			days: this.days,
			hours: this.hours,
			minutes: this.minutes,
			seconds: this.seconds,
		});
		this.durationChange.emit(duration);
	}

	scrollDone() {
		this.daysScrollState = '';
		this.hoursScrollState = '';
		this.minutesScrollState = '';
		this.secondsScrollState = '';
	}

	onButtonMouseDown(fieldToIncrement: string, increment: number, speedIncrement: number, incrementFunction: any) {
		if (this.receivedTouchEvent) return;
		this.onTouchDown(fieldToIncrement, increment, speedIncrement, incrementFunction, true);
	}

	onTouchDown(fieldToIncrement: string, increment: number, speedIncrement: number, incrementFunction: any, calledFromMouseEvent: boolean = false) {
		this.repeatIncrement = increment;
		this.repeatSpeedIncrement = speedIncrement;
		this.repeatIncrementFunction = incrementFunction;
		this.repeatFieldToIncrement = fieldToIncrement;
		this.repeatIntervalFiredCount = 0;
		this.isButtonDown = true;
		if (!calledFromMouseEvent) this.receivedTouchEvent = true;
		this.setRepeatTimer();
	}

	onButtonMouseUp() {
		if (this.receivedTouchEvent) return;
		this.onTouchEnd();
	}

	onTouchEnd() {
		this.isButtonDown = false;
		clearTimeout(this.repeatTimer);
		if (this.repeatIntervalFiredCount > 0) return;
		this.repeatIncrementFunction(this[this.repeatFieldToIncrement] + this.repeatIncrement, this.repeatIncrement > 0 ? 'up' : 'down');
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private get isMaxDurationSet(): boolean {
		return this.maxDuration != null;
	}

	private setInitialTimeFields() {
		if (this.duration == null) return;

		// NOTE: When entering this method, which is only called once in ngOnInit, this.days, this.hours, this.minutes
		// 		 and this.seconds are all equal to 0 (zero).

		// Days
		if (this.hideDays) {
			this.maxDays = 0;
			this.hours = this.duration.hours() + this.duration.days() * 24;
			this.isMaxDays = true;
		} else {
			this.days = this.duration.days();
			this.maxDays = this.isMaxDurationSet ? this.maxDuration.days() : this.maxDays;
			if (this.days >= this.maxDays) {
				this.days = this.maxDays;
				this.isMaxDays = true;
			}
		}

		// Will set this.isMaxDuration (as appropriate).
		this.isDurationValid(this.days);

		// Hours
		if (this.hideHours) {
			this.maxHours = 0;
			if (this.hours === 0) { this.hours = this.duration.hours(); }
			this.minutes = this.duration.minutes() + this.hours * 60;
			this.hours = 0;
			this.isMaxHours = true;
		} else {
			if (this.hours === 0 && (!this.isMaxDays || !this.isMaxDuration)) { this.hours = this.duration.hours(); }
			// this.maxHours = (this.isMaxDays && this.isMaxDurationSet) ? this.maxDuration.hours() : this.maxDays === 0 ? this.maxHours : this.maxHours - 1;
			if (this.isMaxDays && this.isMaxDurationSet) {
				const tempMaxHours = !this.hideDays ? this.maxDuration.hours() : this.maxDuration.hours() + this.maxDuration.days() * 24;
				if (tempMaxHours < this.maxHours) { this.maxHours = tempMaxHours; }
			} else {
				this.maxHours = this.maxDays === 0 ? this.maxHours : this.maxHours - 1;
			}

			if (this.hours >= this.maxHours) {
				this.hours = this.maxHours;
				this.isMaxHours = true;
			}
		}

		// Minutes
		if (this.hideMinutes) {
			this.maxMinutes = 0;
			if (this.minutes === 0) { this.minutes = this.duration.minutes(); }
			this.seconds = this.duration.seconds() + this.minutes * 60;
			this.minutes = 0;
			this.isMaxMinutes = true;
		} else {
			if (this.minutes === 0 && (!this.isMaxDays || !this.isMaxHours)) { this.minutes = this.duration.minutes(); }
			this.maxMinutes = (this.isMaxDays && this.isMaxHours && this.isMaxDurationSet) ? this.maxDuration.minutes() : this.maxMinutes;
			if (this.minutes >= this.maxMinutes) {
				this.minutes = this.maxMinutes;
				this.isMaxMinutes = true;
			}
		}

		// Seconds
		if (this.hideSeconds) {
			this.maxSeconds = 0;
			this.seconds = 0;
			this.isMaxSeconds = true;
		} else {
			if (this.seconds === 0 && (!this.maxDays || !this.maxHours || !this.isMaxMinutes)) { this.seconds = this.duration.seconds(); }
			this.maxSeconds = (this.isMaxDays && this.isMaxHours && this.isMaxMinutes && this.isMaxDurationSet) ? this.maxDuration.seconds() : this.maxSeconds;
			if (this.seconds >= this.maxSeconds) {
				this.seconds = this.maxSeconds;
				this.isMaxSeconds = true;
			}
		}

		this.updateTimeStrings();
	}

	private updateTimeStrings() {
		this.daysString = String(this.days);
		this.hoursString = String(this.hours);
		this.minutesString = String(this.minutes);
		this.secondsString = String(this.seconds);
	}

	private setRepeatTimer() {
		this.repeatTimer = setTimeout(() => {
			if (this.isFieldAtMaxValue(<TimeField>this.repeatFieldToIncrement)) { return; }

			this.repeatIntervalFiredCount++;
			const oldValue = this[this.repeatFieldToIncrement];
			const newValue = (this.repeatIntervalFiredCount <= 4 || this.repeatSpeedIncrement === this.repeatIncrement) ?
				(oldValue + this.repeatIncrement) :
				(this.repeatSpeedIncrement > 0 ?
					(Math.floor((oldValue + this.repeatSpeedIncrement) / this.repeatSpeedIncrement) * this.repeatSpeedIncrement) :
					(Math.ceil((oldValue + this.repeatSpeedIncrement) / -this.repeatSpeedIncrement) * -this.repeatSpeedIncrement));

			// If the increment function returns false then the new value is invalid so stop repeating.
			if (!this.repeatIncrementFunction(newValue, this.repeatIncrement > 0 ? 'up' : 'down')) return;
			if (!this.isButtonDown) return;

			this.setRepeatTimer();
		}, this.repeatIntervalFiredCount === 0 ? 0 : this.repeatIntervalFiredCount === 1 ? 500 : 100);
	}

	private isFieldAtMaxValue(timeField: TimeField) {
		switch (timeField) {
			case TimeField.Days:
				return this.isMaxDays;
			case TimeField.Hours:
				return this.isMaxHours;
			case TimeField.Minutes:
				return this.isMaxMinutes;
			case TimeField.Seconds:
				return this.isMaxSeconds;
		}
	}

	// =========================================================================================================================================================
	// Helper Methods associated with setting maxDuration
	// =========================================================================================================================================================

	private currentDuration(days: number, hours: number, minutes: number, seconds: number): moment.Duration {
		return moment.duration({
			days: days != null ? days : this.days,
			hours: hours != null ? hours : this.hours,
			minutes: minutes != null ? minutes : this.minutes,
			seconds: seconds != null ? seconds : this.seconds,
		});
	}

	private isDurationValid(days: number = null, hours: number = null, minutes: number = null, seconds: number = null): boolean {
		let isValid = true;

		if (days != null) {
			this.isMaxDuration = this.currentDuration(days, this.hours, this.minutes, this.seconds).asSeconds() > this.maxDuration.asSeconds();
			isValid = days < this.maxDuration.days();
		}

		if (hours != null) {
			isValid = this.hours < (this.isMaxDays ? this.maxDuration.hours() : this.maxHours);
		}
		if (isValid && minutes != null) {
			isValid = this.minutes < (this.isMaxHours ? this.maxDuration.minutes() : this.maxMinutes);
		}
		if (isValid && seconds != null) {
			isValid = this.seconds < (this.isMaxMinutes ? this.maxDuration.seconds() : RbConstants.Form.DURATION_SECONDS.max);
		}

		return isValid && this.currentDuration(days, hours, minutes, seconds).asSeconds() <= this.maxDuration.asSeconds();
	}

	// Method to enable/disable time field increment buttons.
	private setIncrementControlsState(timePart: TimePart) {
		switch (timePart) {
			case TimePart.Hour:
				this.isMaxHours = this.hours >= (this.isMaxDays ? this.maxDuration.hours() : this.maxHours);
			// eslint-disable-next-line no-fallthrough
			case TimePart.Minute:
				const tempMaxMinutes = this.isMaxDays && this.isMaxHours ? this.maxDuration.minutes() : this.maxMinutes;
				if (tempMaxMinutes === 0 && !this.isMaxHours) {this.maxMinutes = RbConstants.Form.DURATION_MINUTES.max; }
				this.isMaxMinutes = this.minutes >= this.maxMinutes;
			// eslint-disable-next-line no-fallthrough
			case TimePart.Second:
				const maxSeconds = this.isMaxDays && this.isMaxHours && this.isMaxMinutes ? this.maxDuration.seconds() : RbConstants.Form.DURATION_SECONDS.max;
				this.isMaxSeconds = this.seconds >= maxSeconds;
				break;
		}
	}

	private setInitialButtonState() {

	}
}

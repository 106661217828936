import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class ModuleFirmwareInfo {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	satelliteID: number;
	id: number;
	type: RbEnums.Common.DeviceType;
	smBusFirmwareVersion: string = null;
	smBusBootloaderVersion: string = null;
	twoWireFirmwareVersion: string = null;
	latestSmBusFirmwareVersion: string = null;
	latestTwoWireFirmwareVersion: string = null;
	latestModuleBootloaderVersion: string = null;
}

import { Injectable, TemplateRef } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../../shared-ui/components/snack-bar/snack-bar.component';

@Injectable({
	providedIn: 'root',
})
export class ToasterService {

	constructor(private snackBar: MatSnackBar
	) {
	}

	showToaster(msg: string, duration: number, header: string = null) {
		this.openSnackBar(header, msg, duration);
	}

	openSnackBar(header: string, message: string, duration: number) {
		this.snackBar.openFromComponent(SnackBarComponent, {
			data: {
				header: header,
				html: message
			},
			duration: duration
		});
	}

	showNotificationToaster(template: TemplateRef<any>) {
		this.snackBar.openFromTemplate(template,
			{
				horizontalPosition: 'right',
				verticalPosition: 'top',
				panelClass: 'notification-snackbar-container'
			});
	}
}

import { ActiveFlowChartItem } from './active-flow-chart-item.model';

export class FlowChartData {
	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.activeFlowChartItems) { this.activeFlowChartItems = json.activeFlowChartItems.map(item => new ActiveFlowChartItem(item)); }
		}
	}

	activeFlowChartItems: ActiveFlowChartItem[] = [];
	totalFlow: number;
}

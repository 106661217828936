<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-global-weather-mobile-cell-container">

	<div class="ellipsis-text area-name">{{location?.areaName}}</div>
	<div class="flex justify-between">
		<div class="ellipsis-text">{{location?.region}}</div>
		<div class="ellipsis-text">{{location?.country}}</div>
	</div>
</div>

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BroadcastService } from '../../../common/services/broadcast.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { ITriPaneComponent } from './tri-pane-component.interface';
import { ITriPaneHost } from './tri-pane-host.interface';
import { MatDrawer } from '@angular/material/sidenav';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'rb-tri-pane',
	templateUrl: './tri-pane.component.html',
	styleUrls: ['./tri-pane.component.scss'],
	animations: [
		trigger('transitionInLeft', [
			transition(':enter', [   // :enter is alias to 'void => *'
				style({ width: 0 }),
				animate(500, style({ width: '22.5rem' }))
			]),
			transition(':leave', [   // :enter is alias to 'void => *'
				style({ width: '22.5rem' }),
				animate(500, style({ width: 0 }))
			])
		])
	]
})
export class TriPaneComponent implements OnInit, OnDestroy, ITriPaneComponent {
	@HostBinding('class') class = 'rb-tri-pane';

	@ViewChild('leftPane') leftPane: ElementRef;
	@ViewChild('leftPaneToolbarContainer') leftPaneToolbarContainer: ElementRef;
	@ViewChild('leftPaneBodyContainer') leftPaneBodyContainer: ElementRef;
	@ViewChild('middlePane', { static: true }) middlePane: ElementRef;
	@ViewChild('middlePaneBodyContainer', { static: true }) middlePaneBodyContainer: ElementRef;
	@ViewChild('playMenuContainer') playMenuContainer: ElementRef;
	@ViewChild('rightPane', { static: true }) rightPane: MatDrawer;
	@ViewChild('rightPaneBodyContainer', { static: true }) rightPaneBodyContainer: ElementRef;
	@ViewChild('triPaneContainer', { static: true }) triPaneContainer: ElementRef;

	@Input() hidePlayMenuPanel = false;
	@Input() alwaysKeepPlayMenuAtTop = false;
	@Input() hideGridPlacemat = false;
	@Input() hideMiddlePaneBody = false;
	@Input() hostComponent: ITriPaneHost;
	@Input() leftPaneToolbar: TemplateRef<any>;
	@Input() leftPaneBody: TemplateRef<any>;
	@Input() playMenu: TemplateRef<any>;
	@Input() middlePaneBody: TemplateRef<any>;
	@Input() rightPaneBody: TemplateRef<any>;
	@Input() showRefreshButton = true;
	@Input() mobileFilter: TemplateRef<any>;
	@Input() isTriPaneChild = false;
	
	private readonly leftSidebarStateCacheKey = RbConstants.Common.LEFT_SIDEBAR_STATE_KEY;

	isLeftPaneDisplayed = false;
	isLeftPaneLoading = false;
	isMobile = false;
	isLeftPaneMinimized = false;
	hasTabletLeftPaneBeenExpanded = false;
	isRightPaneDisplayed = false;
	isRightPaneLoading = false;
	loadingError: string;
	showMobileLeftSidebar = false;
	togglingLeftPane = true;
	triPaneContainerHeight: {};
	toggleIcon = 'navigate_before';
	toggleTooltip: string;

	TriPaneComponent = TriPaneComponent;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private hostElement: ElementRef,
				private broadcastService: BroadcastService,
				private deviceManager: DeviceManagerService,
				private translateService: TranslateService,
	) { }

	ngOnInit() {
		this.isMobile = this.deviceManager.isMobile;
		this.toggleTooltip = this.translateService.instant('STRINGS.HIDE_SIDEBAR');

		// Monitor app resize
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => this.isMobile = isMobile);

		this.deviceManager.isTabletChange
			.pipe(untilDestroyed(this))
			.subscribe(() => this.hasTabletLeftPaneBeenExpanded = false);

		this.broadcastService
			.fullScreenToggleRequested
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				if (!TriPaneComponent.isFullScreen) {
					if (this.hostElement.nativeElement.requestFullscreen != null) this.hostElement.nativeElement.requestFullscreen();
					else if (this.hostElement.nativeElement.webkitRequestFullscreen != null) this.hostElement.nativeElement.webkitRequestFullscreen();
					else if (this.hostElement.nativeElement.msRequestFullscreen != null) this.hostElement.nativeElement.msRequestFullscreen();
				} else {
					if (document.exitFullscreen != null) document.exitFullscreen();
					// @ts-ignore
					else if (document.webkitExitFullscreen != null) document.webkitExitFullscreen();
					// @ts-ignore
					else if (document.msExitFullscreen != null) document.msExitFullscreen();
				}
			});
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onRightPaneOpenChanged(isOpen: boolean) {
		if (isOpen && this.hostComponent.rightPaneOpened) {
			this.hostComponent.rightPaneOpened();
		} else if (!isOpen && this.hostComponent.rightPaneClosed) {
			this.hostComponent.rightPaneClosed();
		}
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	static get isFullScreen(): boolean {
		// @ts-ignore
		return document.webkitFullscreenElement != null || document.fullscreenElement != null;
	}

	showLeftPane(showLoadingIndicator = false, showMobileLeftSidebar = false) {
		if (this.leftPaneBody == null && this.leftPaneToolbar == null) { return; }

		this.showMobileLeftSidebar = showMobileLeftSidebar;

		// Set LeftSidebar minimized state to the previously saved state (if it exists). If not set, default tablet to minimized.
		if (localStorage[this.leftSidebarStateCacheKey] == null && this.deviceManager.isTablet) {
			localStorage[this.leftSidebarStateCacheKey] = this.isLeftPaneMinimized = true;
		} else {
			this.isLeftPaneMinimized = localStorage[this.leftSidebarStateCacheKey] === 'true';
		}
		this.toggleLeftPane(this.isLeftPaneMinimized);

		this.togglingLeftPane = false;
		this.isLeftPaneLoading = showLoadingIndicator;
		this.isLeftPaneDisplayed = true;
	}

	toggleLeftPane(isMinimized?: boolean, isStorageUpdate = true) {
		// Toggle unless explicitly set.
		this.isLeftPaneMinimized = isMinimized != null ? isMinimized : !this.isLeftPaneMinimized;

		this.toggleIcon = this.isLeftPaneMinimized ? 'navigate_next' : 'navigate_before';
		this.toggleTooltip = this.translateService.instant(this.isLeftPaneMinimized ? 'STRINGS.SHOW_SIDEBAR' : 'STRINGS.HIDE_SIDEBAR');
		if (isStorageUpdate) {
			localStorage[this.leftSidebarStateCacheKey] = this.isLeftPaneMinimized;
		}
		if (!this.isLeftPaneMinimized) { this.hasTabletLeftPaneBeenExpanded = true; }
	}

	closeLeftPane() {
		this.togglingLeftPane = true;
		setTimeout(() => {
			this.isLeftPaneDisplayed = this.isMobile ? false : this.isLeftPaneDisplayed;
			this.showMobileLeftSidebar = false;
		});
	}

	showRightPane(showLoadingIndicator = false) {
		if (this.isRightPaneDisplayed) { return; }

		this.isRightPaneLoading = showLoadingIndicator;
		this.isRightPaneDisplayed = true;
		this.rightPane.toggle();
	}

	closeRightPane() {
		this.isRightPaneDisplayed = false;
		this.rightPane.close();
	}

	setLeftPaneIsLoading(isLoading: boolean) {
		this.isLeftPaneLoading = isLoading;
	}

	setRightPaneIsLoading(isLoading: boolean, error?: string) {
		this.isRightPaneLoading = isLoading;
		this.loadingError = error;
	}

}

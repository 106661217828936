<div class="report-filter-components">

	<div class="report-name">
		<div>
			<label>{{'STRINGS.REPORT_NAME' | translate}}</label>
		</div>
		<div>
			<mat-form-field class="formField w-100">
				<input matInput type="text" [disabled]="true" [ngModel]="parentReportFilter.reportName + ' ' + translate.instant('STRINGS.REPORT')"
					   (focus)="$event.target.select()" />
			</mat-form-field>
		</div>
	</div>

	<div *ngIf="parentReportFilter.showMobileSiteSelector">
		<label>{{(!isGolfSite ? 'STRINGS.SITE' : 'STRINGS.COURSE') | translate}}*</label>
		<div class="rb-flex-grow-0">
			<rb-multi-select-dropdown
				data-qaid="report-filter-select-site"
				class="multi-select-site-selection mobile-report-filter"
				[label]="(isGolfSite ? 'STRINGS.SELECT_COURSE' : 'STRINGS.SELECT_SITE') | translate"
				[selectedSites]="selectedSites"
				[sites]="sites"
				[showSelectionCount]="true"
				[isShowSelectAllAsCheckbox]="true"
				(siteSelectionComplete)="onSiteSelectionComplete($event)"
			></rb-multi-select-dropdown>
		</div>
	</div>

	<div *ngIf="!parentReportFilter.hideSelectController" class="w-80 mt2 button-dropdown multi-controller-selector">
		<div>
			<label>{{controller | translate}}*</label>
		</div>
		<div class="selector-input" (click)="onSelectControllers()">
			<span *ngIf="!noOfSelectedControllers">{{selectControllers | translate}}</span>
			<span *ngIf="noOfSelectedControllers">{{noOfSelectedControllers}} {{controllersSelected | translate}}
			</span>
			<mat-icon>play_arrow</mat-icon>
		</div>
	</div>
	<div *ngIf="!parentReportFilter.hideSelectHoleArea" class="w-80 mt2 button-dropdown multi-controller-selector">
		<div>
			<label>{{'STRINGS.AREA' | translate}}*</label>
		</div>
		<div class="selector-input" (click)="onSelectControllers()">
			<span *ngIf="!noOfSelectedControllers">{{selectControllers | translate}}</span>
			<span *ngIf="noOfSelectedControllers">{{noOfSelectedControllers}} {{controllersSelected | translate}}
			</span>
			<mat-icon>play_arrow</mat-icon>
		</div>
	</div>

	<div *ngIf="!parentReportFilter.hideRadioGroup" class="w-80 mt2 button-dropdown single-controller-selector">
		<div>
			<label>{{controller | translate}}*</label>
		</div>
		<rb-radio-group class="dib controller-selection" [items]="parentReportFilter.items"
						(selectionEvent)="parentReportFilter.handleChange('controllerId',$event)"></rb-radio-group>
	</div>

	<div *ngIf="parentReportFilter.showAlarmData" class="w-80 mt2 button-dropdown alarm-data-selector mobile">
		<rb-alarm-data class="dib alarm-data mobile" (selectionEvent)="parentReportFilter.handleChange('alarmTypes',$event)"></rb-alarm-data>
	</div>

	<!-- Date range	-->
	<div>
		<div *ngIf="!parentReportFilter.hideDateRange || !parentReportFilter.hideTimePeriod || !parentReportFilter.hideMonthPicker">
			<label>{{(RbUtils.DateRange.isWaterReportsDateRange(parentReportFilter.dateRangeDropdown) ? 'STRINGS.COMPARE' : 'STRINGS.DATE_RANGE') | translate}}
				*</label>
		</div>
		<rb-date-range-dropdown
			*ngIf="!parentReportFilter.hideDateRange"
			class="w-80 rb-date-range-dropdown button-dropdown-daterange mobile"
			[dateRangeDropdown]="parentReportFilter.dateRangeDropdown"
			[selectedDateRange]="parentReportFilter.selectedDateRange"
			[hideTimeForDateRange]="parentReportFilter.hideTimeForDateRange"
			[hideDates]="parentReportFilter.hideDates"
			[useStartOfDayForDateRange]="parentReportFilter.useStartOfDayForDateRange"
			[maxDate]="parentReportFilter.nowPlus24h"
			(startDateChange)="parentReportFilter.handleChange('startDate',$event)"
			(endDateChange)="parentReportFilter.handleChange('endDate',$event)"
			(dateRangeChange)="parentReportFilter.handleChange('dateRange',$event)">
		</rb-date-range-dropdown>

		<rb-time-period
			*ngIf="!parentReportFilter.hideTimePeriod" class="rb-date-range-dropdown mt2 items-center button-dropdown-daterange mobile"
			[oneTimePeriod]="parentReportFilter.oneTimePeriod" (timePeriodChange)="parentReportFilter.handleChange('timePeriod',$event)">
		</rb-time-period>

		<rb-month-picker
			*ngIf="!parentReportFilter.hideMonthPicker" class="rb-month-picker mh1 mt2 button-dropdown-daterange mobile"
			[selectedMonth]="parentReportFilter.selectedMonth" (monthChange)="parentReportFilter.handleChange('month',$event)">
		</rb-month-picker>
	</div>
	<div *ngIf="!parentReportFilter.hideSelectFlowSensors" class="flow-sensor-filter multi-select-selection">
		<rb-multi-select-dropdown2
			class="flow-sensor-filter-mobile"
			[label]="parentReportFilter.sensorSelectLabel"
			[selectedItems]="parentReportFilter.selectedFlowSensors"
			[items]="parentReportFilter.flowSensors"
			(selectionComplete)="parentReportFilter.handleChange('flowSensors', $event)">
		</rb-multi-select-dropdown2>
	</div>
</div>

<div class="report-filter-button">
	<rb-report-button
		[label]="'REPORTS.RUN_REPORT_UPPERCASE' | translate"
		(reportButtonClicked)="parentReportFilter.reportButton()">
	</rb-report-button>
</div>
<div *ngIf="controllerSelectorVisible">
	<rb-select-controller-dialog
		*ngIf="!singleSelection"
		(complete)="onSelectControllersComplete($event)"
		[treeItems]="parentReportFilter.treeViewItems"
		[selectedControllerIds]="parentReportFilter.controllerIds">
	</rb-select-controller-dialog>
	<rb-select-single-controller
		*ngIf="singleSelection"
		(complete)="onSelectControllersComplete($event)"
		[treeItems]="parentReportFilter.treeViewItems"
		[selectedControllerIds]="parentReportFilter.controllerIds">
	</rb-select-single-controller>

</div>
<rb-hole-area-filter
	*ngIf="areaSelectorVisible"
	[isMobile]="true"
	[selectedCourseId]="parentReportFilter.selectedCourseId"
	[selectedAreas]="parentReportFilter.selectedAreas"
	[selectedHoles]="parentReportFilter.selectedHoles"
	(valueChange)="handleHoleAreaChange($event)"
	(complete)="onSelectAreasComplete($event)">
</rb-hole-area-filter>


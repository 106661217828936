import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class DiagnosticData {

	static MAX_STATIONS_PER_CHANNEL = 32;

	cdType = RbEnums.Common.DiagnosticDataType.Unspecified;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}
}

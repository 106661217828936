export class GprsLicenseListItem {

	id: number;
	simNo: string;
	ipAddress: string;
	activated?: Date;
	initialExpiration: number;
	expiration?: Date;
	suspended: boolean;
	firstUsed?: Date;
	type: number;
	name: string;
	description: string;
	cicSerialNum: number;
	extraInitialDays: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

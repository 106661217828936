import * as moment from 'moment';
import { Duration } from 'moment';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class ActivityItem {

	/**
	 * @summary Name of program group, program, or station.
	 */
	name: string;

	/**
	 * @summary Station ID value or null, if the activity is for a program group or program instead.
	 */
	stationId: number | null;

	/**
	 * @summary Program ID value or null, if the activity is for a program group or station instead.
	 */
	programId: number | null;

	/**
	 * @summary ProgramGroup ID value or null, if the activity is for a program or station instead.
	 */
	programGroupId: number | null;

	/**
	 * @summary Site ID to which the activity belongs. If it's station activity, siteId should be non-null.
	 * If program activity, it will be null for golf, non-null for commercial. If program group activity,
	 * always null, as program groups are golf-only.
	 */
	siteId: number | null;

	/**
	 * @summary For Commercial Programs, the name of the Satellite running the program.
	 */
	satelliteName: string;

	/**
	 * @summary Start Time of the Program or Group
	 */
	start: Date;

	/**
	 * @summary End Time of the Program or Group
	 */
	end: Date;

	/**
	 * @summary Elapsed Time. This property will return the following bits of data depending on the state of the program, program
	 * group, or station:
	 * Completed: The total time for which the program group, program, or station was running.
	 * Active: The total time for which the program group, program, or station has been running, so far.
	 * Scheduled: Zero/null
	 */
	elapsedTime: Duration;

	/**
	 * @summary Estimated Time Remaining. This property will return the following bits of data depending on the state of the program,
	 * program group, or station:
	 * Completed: Zero/null
	 * Active: Total time remaining for the program group, program, or station.
	 * Scheduled: Total time expected for scheduled program group, program, or station run.
	 */
	timeRemaining: Duration;

	/**
	 * @summary Flow rate of the station/program/item.
	 */
	flowRate: number | null;

	/**
	 * @summary ActivityStatus for the activity represented. This has values like Completed, OnTime, etc.,
	 * depending on the type of activity represented.
	 */
	status: RbEnums.Common.ActivityStatus;

	/**
	 * @summary String value corresponding to the value of the status field.
	 */
	statusString: string;

	alarm: string;

	/**
	 * @summary Alerts will be used to implment the Status requirement from the specification. It will be 0 if the
	 * program group, program, or station has no active errors, but will have a non-zero value if there were
	 * issues completing, scheduling the run.
	 */
	alerts: number;
	totalElapsedTime: any;
	siteName: string;
	children: any;

	/**
	 * @summary Hole ID for golf station activity. We categorize the activity on the Activity | Completed,
	 * for example, by course, hole, and area, so we need some lookup to find the right parents. Not present,
	 * in other scenarios.
	 */
	holeId?: number;

	/**
	 * @summary Area ID for golf station activity. We categorize the activity on the Activity | Completed,
	 * for example, by course, hole, and area, so we need some lookup to find the right parents. Not present,
	 * in other scenarios.
	 */
	areaId?: number;

	/**
	 * @summary Station Number for golf stations. Not returned by the API, but used on the Activity | Completed screen
	 * simplifying sorting. Not present in other scenarios.
	 */
	stationNumber?: number;

	/**
	 * @summary Depth in the activity "tree" of the current item. For example, if we are display golf stations and this
	 * is an Area entry on a multi-course system, level 0 is the course, level 1 is the hole, and level 2 is the area,
	 * so this value should be 2. The renderers can use this information in mobile, particularly, to adjust layout for
	 * proper display. Not present in scenarios not using tree display.
	 */
	treeLevel?: number;

	// v27 ag-grid tree data. We need the parent id of the Activity Item to properly construct the tree hierarchy.
	get treeItemId(): any {
		switch (this.treeLevel) {
			case 0:
				return this.siteId;
			case 1:
				return this.holeId;
			case 2:
				return this.areaId;
			case 3:
				return this.name;
			case 4:
				return this.key;
			// TODO: Flesh out other levels if they exist to provide specific Id as opposed to name, which may not be unique.
			default:
				return this.name;
		}
	}

	/**
	 * @sumary key, setting the data to an unique value avoids rows getting grouped and warnings for a tree list view
	 * NOTE: used in case treeLevel is 4 on getter for treeItemId
	 */
	key?: any = null;

	// v27 ag-grid tree data activityItem parent activityItem (if it has one).
	treeParent?: ActivityItem;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			// Dates:
			if (json.start) { this.start = RbUtils.Conversion.convertStringToDate(json.start); }
			if (json.end) { this.end = RbUtils.Conversion.convertStringToDate(json.end); }

			// Durations:
			if (json.elapsedTime) { this.elapsedTime = moment.duration(json.elapsedTime); }
			if (json.timeRemaining) { this.timeRemaining = moment.duration(json.timeRemaining); }

			// Special assignment stuff?
		}
	}

	// =========================================================================================================================================================
	// Class Methods
	// =========================================================================================================================================================

}

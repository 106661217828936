<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="flex justify-between station-mobile-cell">
    <div class="ellipsis-text"><b>{{station?.terminal}}. {{station?.name}}</b></div>
    <div>{{station?.address}}</div>
</div>

<div class="flex justify-between">
    <div>{{station.landscape}}</div>
    <div>{{station.sprinkler}}</div>
</div>


<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="drm-cell-container">
    <div class="sf-top-pane top" >
        <div class="drmc-pane no-max">
            <div class="drmc-labels">
                <div>{{'STRINGS.PROGRAM' | translate}}:</div>
                <div *ngIf="isGolfSite">{{'STRINGS.SCHEDULE_VERB' | translate}}:</div>
            </div>
            <div class="drmc-values">
                <div *ngIf="isGolfSite" class="ellipsis-text link" (click)="onProgramClick()">{{data?.programGroupName}}</div>
                <div class="ellipsis-text link" (click)="onScheduleClick()">{{data?.programName}}</div>
            </div>
        </div>
    </div>
</div>

import { BroadcastService } from '../../../../common/services/broadcast.service';
import { CellEditorValidationParams } from './_models/cell-editor-validation-params';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { IRbCellEditorParams } from './_models/rb-cell-editor-params.interface';
import { IRbEditableGridColDef } from './_models/rb-editable-grid-col-def.interface';
import { MessageBoxInfo } from '../../../../core/components/global-message-box/message-box-info.model';
import { of } from 'rxjs';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { TranslateService } from '@ngx-translate/core';

import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;

export abstract class CellEditorBase implements ICellEditorAngularComp {
	params: IRbCellEditorParams;
	colDef: IRbEditableGridColDef;
	private originalValue: any;

	constructor(protected broadcastService: BroadcastService,
				protected translateService: TranslateService
	) {}

	// =========================================================================================================================================================
	// ICellEditorAngularComp Members
	// =========================================================================================================================================================

	abstract getValue();

	agInit(params: any): void {
		this.params = params;
		this.colDef = this.params.colDef;
		this.originalValue = this.params.data[this.colDef.field];

		if (this.hasFieldValidation) {
			this.colDef.isValid = false;
		}
	}

	// =========================================================================================================================================================
	// Protected Methods
	// =========================================================================================================================================================

	protected get hasFieldValidation(): boolean {
		return this.colDef.cellEditorParams && this.colDef.cellEditorParams.validate;
	}

	protected isNavigationKey(key: string) {
		return key === 'Tab' || key === 'Enter' || key === 'ArrowUp' || key === 'ArrowDown';
	}

	protected isSelectListNavigationKey(key: string) {
		return key === 'Tab' || key === 'Enter';
	}

	protected validateAndNavigate(value: any, key: string, shiftKey: boolean) {
		const subscription = this.originalValue === value ? of(true)
			: this.colDef.cellEditorParams.validate(new CellEditorValidationParams(value, this.params));
		subscription.subscribe((response: boolean) => {
				if (response) {
					this.colDef.isValid = true;

					this.navigateOnKeyDown(key, shiftKey);

					// if (key === 'Tab') {
					// 	if (!shiftKey) {
					// 		this.params.api.tabToNextCell();
					// 	} else {
					// 		this.params.api.tabToPreviousCell();
					// 	}
					// } else if (key === 'ArrowUp') {
					// 	if (this.params.rowIndex > 0) {
					// 		this.params.api.stopEditing();
					// 		this.setCellFocusAndStartEditing(this.params.rowIndex - 1);
					// 	}
					// } else {	// Enter or ArrowDown
					// 	this.params.api.stopEditing();
					//
					// 	if (this.params.rowIndex + 1 < this.params.api.getDisplayedRowCount()) {
					// 		this.setCellFocusAndStartEditing(this.params.rowIndex + 1);
					// 	}
					// }
				}
			});
	}

	protected navigateOnKeyDown(key: string, shiftKey: boolean) {
		if (key === 'Tab') {
			if (!shiftKey) {
				this.params.api.tabToNextCell();
			} else {
				this.params.api.tabToPreviousCell();
			}
		} else if (key === 'ArrowUp') {
			if (this.params.rowIndex > 0) {
				this.params.api.stopEditing();
				this.setCellFocusAndStartEditing(this.params.rowIndex - 1);
			}
		} else {	// Enter or ArrowDown
			this.params.api.stopEditing();

			if (this.params.rowIndex + 1 < this.params.api.getDisplayedRowCount()) {
				this.setCellFocusAndStartEditing(this.params.rowIndex + 1);
			}
		}
	}

	protected showInvalidFieldDialog(message: string) {
		const mbi = MessageBoxInfo.create(this.translateService.instant('STRINGS.FIELD_VALIDATION'), message, MessageBoxIcon.Exclamation);
		this.broadcastService.showMessageBox.next(mbi);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setCellFocusAndStartEditing(rowIndex: number) {
		this.params.api.setFocusedCell(rowIndex, this.colDef.field);
		const focusedCell = this.params.api.getFocusedCell();
		this.params.api.startEditingCell({ rowIndex: focusedCell.rowIndex, colKey: focusedCell.column.getColId() });
	}
}

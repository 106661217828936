import { IIdentityObject } from '../../_common/models/IIdentityObject.model';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class CourseViewStationData implements IIdentityObject {
	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}

		this.arc = json.arc ? json.arc : 0 ;
		this.rotationTime = json.rotationTime ? json.rotationTime : 0;
		this.precRateFinal = json.precRateFinal;
	}

	id: number;
	areaLevel3Id: number;
	runningTimeInSeconds: number;

	/**
	 * Degrees of arc spanned by the sprinkler's output. Could be a fixed arc for some rotor types, or a user-entered arc
	 * for others, or 360, if the user is not using catalog data for station configuration.
	 */
	arc: number;

	/**
	 * Precipitation rate for the station in inches/hour. This value may come from either a custom user-entered precip rate
	 * or a calculated rate based on rotor, nozzle, pressure, etc. We don't need to know that.
	 */
	precRateFinal: number;

	/**
	 * Rotation time, seconds/cycle, for the station.
	 */
	rotationTime: number;

	/**
	 * Return display formatted number of rotations represented by the runningTimeInSeconds as a string. This calculation takes into
	 * account custom or catalog rotation time, as well as arc value.
	 */
	public get passes(): string {
		return RbUtils.Stations.stationRotationsDisplay(this.arc, this.rotationTime, this.runningTimeInSeconds);
	}

	/**
	 * Return display formatted amount of applied irrigation, expressed in the user's units system, represented by the runningTimeInSeconds
	 * value.
	 */
	public get application(): string {
		return RbUtils.Stations.applicationAmountDisplay(this.precRateFinal, this.runningTimeInSeconds);
	}
}

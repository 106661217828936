import { ApiCachedRequestResponse } from './api-cached-request-response';
import { CachedValue } from './cached-value';
import { Observable } from 'rxjs';

export class ApiServiceCacheItem<T> {
	observable: Observable<T>;
	cachedValue: CachedValue<T>;
	cachedResponse: ApiCachedRequestResponse<T>;

	constructor(public url: string) {
	}

	get value(): T { return this.cachedValue == null ? null : this.cachedValue.value; }
	set value(val: T) { this.cachedValue = new CachedValue<T>(val); }
}

import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActiveFlowChartItem } from '../../../api/irrigation-activity/models/active-flow-chart-item.model';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { ConversionService } from '../../../common/services/conversion.service';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../../api/culture-settings/culture-settings-manager.service';
import { DateAndTimeService } from '../../../common/services/date-and-time.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { FlowChartItem } from '../../../api/irrigation-activity/models/flow-chart-item.model';
import { FlowDatumActiveStation } from '../../../api/dry-run/models/flow-datum-active-station.model';
import { FlowElement } from '../../../api/flow-elements/models/flow-element.model';
import HighchartsBoost from 'highcharts/modules/boost';
import { IrrigationActivityService } from '../../../api/irrigation-activity/irrigation-activity.service';
import { ProgramChange } from '../../../api/signalR/program-change.model';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { ReportPdfService } from '../../../sections/reports/common/pdf/report-pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { UnitLabelService } from '../../../common/services/unit-label.service';

HighchartsBoost(Highcharts);

@UntilDestroy()
@Component({
	selector: 'rb-active-flow-chart',
	templateUrl: './active-flow-chart.component.html',
	styleUrls: ['./active-flow-chart.component.scss']
})
export class ActiveFlowChartComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'rb-dry-run-chart';

	private readonly CHART_MARKER_FILL_COLOR = '#eea148';
	private readonly CHART_MARKER_LINE_COLOR = 'white';
	private readonly CHART_LINEAR_FILL_TOP = '#80b579';
	private readonly CHART_LINEAR_FILL_BOTTOM = '#80b579';

	private _companyPumps: FlowElement[];

	@Output() chartRedraw = new EventEmitter();
	@Output() onLinesVisibilityChanged = new EventEmitter<Map<string, boolean>>();

	@Input() chartTitle = '';
	@Input() totalFlow = 0;
	@Input() set companyPumps(value: FlowElement[]){
		this._companyPumps = value;
		this.updateChartSeries(true);
	}

	get companyPumps():FlowElement[]{
		return this._companyPumps;
	}

	@Input() set showPumpData(value: boolean) {
		if (this.companyPumps.length === 0) return;

		this.companyPumps.forEach(x => {
			this.setLineVisibility(x.name, value);
		});

		this.updateChartSeries();
	}

	private _activeFlowChartItems: ActiveFlowChartItem[] = null;

	/**
	 * activeFlowChartItems contains the list of data points for the main FloGraph/FlowChart trace. For
	 * commercial, this is usually the retrieved-from-the-controller flow data. For golf, this is the
	 * theoretical, active irrigation level (the sum of all station theoretical flow rates).
	 */
	@Input() set activeFlowChartItems(value: ActiveFlowChartItem[]) {
		if (value == null || value.length < 1) return;

		this._activeFlowChartItems = value;

		// If updates are temporarily suspended, don't call setValues() and update the chart.
		if (this.suspended) return;

		this.setValues();
	}

	get activeFlowChartItems(): ActiveFlowChartItem[] {
		return this._activeFlowChartItems;
	}

	/**
	 * Provides a list of data applicable to the sets of data stored as "secondaryFlowChartItems", the data for
	 * the display. For example, you might use this to display flow sensor values, in which case you'd need the
	 * flow sensor id, so you can cross-reference with the data, and the name, possibly for display on the legend.
	 */
	private _secondaryFlowChartItemInfo: { id: number; name: string }[] = [];

	/**
	 * {id: number; name: string}[] list of items to be displayed on the chart. The id is used to cross-reference
	 * with the data stored in secondaryFlowChartItems. The name can be used in the legend.
	 */
	@Input() set secondaryFlowChartItemInfo(value: { id: number; name: string }[]) {
		// If updates are temporarily suspended, don't call setValues() and update the chart.
		if (this.suspended) return;

		this._secondaryFlowChartItemInfo = value;

		this.updateChartSeries();
	}

	get secondaryFlowChartItemInfo(): { id: number; name: string }[] {
		return this._secondaryFlowChartItemInfo;
	}

	/**
	 * A dictionary where the key is the sensor ID or other unique identifier and the value for each key is the
	 * data, ActiveFlowChartItem[].
	 */
	private _secondaryFlowChartItems: { [id: number]: FlowChartItem[] } = {};

	/**
	 * secondaryFlowChartItems is a dictionary where the key is the sensor ID or other unique identifier and the
	 * value is an array of data points.
	 */
	@Input() set secondaryFlowChartItems(value: { [id: number]: FlowChartItem[] }) {
		if (value == null || Object.keys(value).length < 1) return;	// No items defined.

		this._secondaryFlowChartItems = value;

		// If updates are temporarily suspended, don't call setValues() and update the chart.
		if (this.suspended) return;

		this.updateChartSeries();
	}

	get secondaryFlowChartItems(): { [id: number]: FlowChartItem[] } {
		return this._secondaryFlowChartItems;
	}

	cultureSettings: CultureSettings;
	_isGolfSite: boolean = null;

	/**
	 * We need to know if we are sitting on a golf site because we render the secondary flow chart items in that
	 * case and not display the chart differently for golf and commercial.
	 */
	get isGolfSite(): boolean {
		if (this._isGolfSite == null) {
			// Get the value from the auth manager.
			this._isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		}
		return this._isGolfSite;
	}

	totalFlowHeader = '-';
	flowRateLabel = '';

	currentHoverPointIndex = 0;
	currentFlowDataIndex = 0;
	currentFlowDataDate = '-';
	currentFlowDataTime = '-';
	currentFlowDataFlowRate = '-';

	currentActiveStations: FlowDatumActiveStation[];
	currentRunningPrograms: ProgramChange[];
	currentRunningProgramGroups: ProgramChange[];
	currentActivePumps:FlowElement[];

	Highcharts = Highcharts;
	showChart = false;
	chartOptions: any = null;

	secondaryChartColors: Highcharts.ColorString[] = RbConstants.Form.FLO_GRAPH_TRACE_COLORS;

	private chart: Highcharts.Chart;

	private _suspended = false;

	// RB-10805: Map to preserve the visibility status of the pump data lines in the graph
	private _lineVisibility: Map<string, boolean> = new Map<string, boolean>();

	/**
	 * The suspended state allows several items to be changed in the active flow chart component without
	 * triggering multiple chart refreshes. For example, if you're updating the primary flow chart data,
	 * the secondary data, and the secondary item info, you don't want to redo the chart three times!
	 * Instead, set suspended = true, then send the new data values, then set suspended = false. When
	 * suspended transitions from true to false, setValues() is triggered and the chart is updated.
	 */
	@Input() set suspended(value: boolean) {
		const oldValue = this._suspended;
		this._suspended = value;

		// If we are changing from suspended to not-suspended, call setValues().
		if (oldValue && !value) {
			this.setValues();
		}
	}

	get suspended(): boolean {
		return this._suspended;
	}

	flowDataArray: { x: number, y: number }[] = [];
	//
	// highchart boost options
	/*
	useGPUTranslations: boolean
	Enable or disable GPU translations. GPU translations are faster than doing the translation in JavaScript.
	This option may cause rendering issues with certain datasets. Namely, if your dataset has large numbers with
 	small increments (such as timestamps), it won't work correctly.
	This is due to floating point precission.
	*/
	useGPUTranslations = true;
	/*
	seriesThreshold: number
	Set the series threshold for when the boost should kick in globally.
	Setting to e.g. 20 will cause the whole chart to enter boost mode if there are 20 or more series
	active. When the chart is in boost mode, every series in it will be rendered to a common canvas.
	This offers a significant speed improvment in charts with a very high amount of series.
	Defaults to 50.
	*/
	seriesThreshold = 1;
	/*
	boostThreshold: number
	Set the point threshold for when a series should enter boost mode.
	Setting it to e.g. 2000 will cause the series to enter boost mode when there are 2000 or more points in the series.
	To disable boosting on the series, set the boostThreshold to 0. Setting it to 1 will force boosting.
	Note that the cropThreshold also affects this setting. When zooming in on a series that has fewer points than
	the cropThreshold, all points are rendered although outside the visible plot area, and the boostThreshold won't take
	effect.
	Defaults to 5000.
	*/
	boostThreshHold = 100;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private cultureSettingsManager: CultureSettingsManagerService,
				private conversionService: ConversionService,
				private dateAndTimeService: DateAndTimeService,
				private deviceManager: DeviceManagerService,
				private irrigationActivityService: IrrigationActivityService,
				private reportPdfService: ReportPdfService,
				private translateService: TranslateService,
				private unitLabelService: UnitLabelService,
				private irrigationService: IrrigationActivityService
	) { }

	ngOnInit() {
		// Monitor culture changes to show proper units.
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe((cultureSettings: CultureSettings) => {
				this.cultureSettings = cultureSettings;

				// If updates are temporarily suspended, don't call setValues() and update the chart.
				if (this.suspended) return;

				this.setValues();
			});

		this.reportPdfService.initPdfDocument('l');
		setTimeout(() => this.setupChart());
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Highchart Event Handlers
	// =========================================================================================================================================================

	// TODO: Angular Migration - It appears this callback no longer get called.
	// Get reference to HighChart Chart object.
	chartCallback(chart) {
		// Avoid getting a chart object during exportPdf that becomes invalid
		if (this.chart) return;

		this.chart = chart;
	}

	// TODO: Angular Migration - This event appears to replace the chartCallback. Test and remove chartCallback.
	onChartInstance(chart) {
		// Avoid getting a chart object during exportPdf that becomes invalid
		if (this.chart) return;

		this.chart = chart;
	}

	onMouseMove(event: any) {
		if (!this.chart) return;

		const hoverPoint = this.chart.hoverPoint;

		if (hoverPoint !== null && hoverPoint !== undefined) {
			// Update related component data.
			this.currentHoverPointIndex = (<any>hoverPoint).index;
			const currentGraphIndex = (<any>hoverPoint).series.index;
			this.currentFlowDataIndex = this.currentHoverPointIndex;

			// Chart.series[0] is always the theoretical flow. currentGraphIndex represents the currently selected series.
			if (currentGraphIndex > 0) {
				this.updateDatumDetailsForSecondaryGraph(hoverPoint);
			} else if (currentGraphIndex === 0 && this.currentFlowDataIndex > 0) {
				this.updateDatumDetails();
			}
		}
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getProgramGroupPrograms(programGroupId: number): ProgramChange[] {
		if (this.currentRunningProgramGroups.length === 1) return this.currentRunningPrograms;

		return this.currentRunningPrograms.filter(p => p.programGroupId === programGroupId);
	}

	async generatePdf() {
		const pdfBrowserWindow = this.deviceManager.isSafari && !this.deviceManager.isDesktop ? window.open() : null;
		const pdfInfo = this.reportPdfService.createPdfDocument('l');

		// Add Header
		pdfInfo.pdf.setFont(pdfInfo.getFont);
		pdfInfo.pdf.setFontSize(pdfInfo.dim.veryLargeFontSize);
		let blockHeight = pdfInfo.outputTextBlock(pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom, pdfInfo.bodyWidth,
			this.translateService.instant('STRINGS.ACTUAL_FLOW'), RbConstants.Form.REPORT_PAGE.primary, '#FFFFFF');
		pdfInfo.outputBottom += blockHeight - 1;

		// Controller / Interface Name
		pdfInfo.pdf.setFontSize(pdfInfo.dim.largeFontSize);
		pdfInfo.pdf.setTextColor(RbConstants.Form.REPORT_PAGE.grayText);
		blockHeight = pdfInfo.outputWrappedText(pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom, pdfInfo.bodyWidth * .5, this.chartTitle);

		// Total Flow Info
		pdfInfo.outputRightAlignedText(pdfInfo.pageWidth - (pdfInfo.dim.horizontalMargin + 2), pdfInfo.outputBottom, this.totalFlowHeader);
		pdfInfo.outputBottom += blockHeight - 15;

		// Line
		pdfInfo.pdf.setDrawColor(RbConstants.Form.REPORT_PAGE.primary);
		pdfInfo.pdf.setLineWidth(3);
		pdfInfo.pdf.line(pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom, pdfInfo.pageWidth - pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom);
		pdfInfo.outputBottom += 12;

		// Time Period
		if (this.activeFlowChartItems.length > 0) {
			pdfInfo.pdf.setFontSize(pdfInfo.dim.regularFontSize);
			pdfInfo.pdf.text(this.translateService.instant('REPORTS.TIME_PERIOD', this.getTimePeriod()), pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom);
			pdfInfo.outputBottom += pdfInfo.lineHeight;
		}

		// Generate and save the PDF.
		pdfInfo.outputChart(this.chart);
		this.reportPdfService.save(pdfInfo, this.translateService.instant('REPORTS.ACTUAL_FLOW_FILE', { type: 'chart' }), pdfBrowserWindow);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getTimePeriod(): { start: string, stop: string } {
		const startDate = this.activeFlowChartItems[0].timestamp;
		const endDate = this.activeFlowChartItems[this.activeFlowChartItems.length - 1].timestamp;

		return {
			start: `${this.dateAndTimeService.dateToUserDateString(startDate)} ${this.dateAndTimeService.dateToUserTimeString(startDate)}`,
			stop: `${this.dateAndTimeService.dateToUserDateString(endDate)} ${this.dateAndTimeService.dateToUserTimeString(endDate)}`
		};
	}

	private setValues() {
		if (this.activeFlowChartItems == null) return;

		// Set flow rate label for flow data.
		this.flowRateLabel = this.unitLabelService.getFlowRateLabel();

		// Total Flow
		const convertedTotalFlow = this.totalFlow ? this.conversionService.getUserTotalFlowString(this.totalFlow) : 0.0;
		this.totalFlowHeader = this.isGolfSite ?
			`${RbUtils.Translate.instant('STRINGS.TOTAL_THEORETICAL_FLOW_VOLUME_LABEL')} ${convertedTotalFlow} ${this.unitLabelService.getFlowLabel()}` :
			`${RbUtils.Translate.instant('STRINGS.TOTAL_FLOW_LABEL')} ${convertedTotalFlow} ${this.unitLabelService.getFlowLabel()}`;

		// Configure the chart. We use the result of the data conversions above to set up data series, so this must follow the code above.
		this.flowDataArray = [];

		// The activeFlowChartItems we receive from the API ONLY include points when an irrigation event changes (e.g., irrigation starts, irrigation stops,
		// etc.), If we simply plotted these points, that chart would plot a series of diagonal lines between the various events. To avoid this, and draw
		// the chart the way we want (i.e., flat, horizontal lines when an event is active), we inject points 1ms prior to each real irrigation event, which
		// contains the event information of the previous real irrigation event. The code below handles this logic.

		// Starting from the end of the array, compare a 'current' and 'previous' item. If their totalFlow values are different AND their timestamps are not
		// 1ms apart (i.e., we have not already injected an extra 'plot point'), insert a new 'plot point' into the array 1ms ahead of the 'current' point
		// that contains the data of the 'previous' point, updating the new 'plot points' timestamp to be 1ms ahead of the 'current' point.
		for (let i = this.activeFlowChartItems.length - 1; i > 0; i--) {
			if (this.activeFlowChartItems[i - 1].totalFlow !== this.activeFlowChartItems[i].totalFlow
				&& (this.activeFlowChartItems[i - 1].timestamp.valueOf() + 1) !== this.activeFlowChartItems[i].timestamp.valueOf()) {

				const item = new ActiveFlowChartItem(this.activeFlowChartItems[i - 1]);
				item.timestamp = new Date(this.activeFlowChartItems[i].timestamp.valueOf() - 1);
				this.activeFlowChartItems.splice(i, 0, item);
			}
		}

		this.activeFlowChartItems.forEach((datum: ActiveFlowChartItem) => this.addPoint(datum));
		this.updateChartSeries();
	}

	// Method to update the chart series w/o recreating the entire chart.
	// oneToOne for cases when we're adding/removing elements to series collection
	private updateChartSeries(oneToOne: boolean = false) {
		if (this.chart) {
			this.chart.update({
				series: this.getSeriesData()
			}, true, oneToOne);
		}
	}

	private addPoint(flowData: ActiveFlowChartItem) {
		this.flowDataArray.push({
			x: RbUtils.Conversion.convertDateToUTCMilliseconds(flowData.timestamp),
			y: this.conversionService.getUserFlowRate(flowData.totalFlow)
		});
	}

	private updateDatumDetailsForSecondaryGraph(selectedPoint: any) {
		// update date time and flow rate labels
		this.currentFlowDataFlowRate = this.conversionService.getUserFlowRateString(selectedPoint.y);
		this.currentFlowDataDate = this.dateAndTimeService.dateToUserDateString(selectedPoint.x);
		this.currentFlowDataTime = this.dateAndTimeService.dateToUserTimeString(selectedPoint.x, true);

		// get active station and programs from theoretical flow data
		this.currentActiveStations = [];
		this.currentRunningPrograms = [];
		this.currentRunningProgramGroups = [];

		if (!this.activeFlowChartItems) { return; }

		// get nearest earlier data point from from theoretical flow data
		const nearestItem = this.activeFlowChartItems.slice().reverse().find(item => item.timestamp <= moment(selectedPoint.x).toDate());
		if (nearestItem != null) {
			this.updateActiveStationsAndProgramGroup(nearestItem);

			// Always show Flow Rate for primary data series (if it exists) on datum info line (light blue area)
			this.currentFlowDataFlowRate = this.conversionService.getUserFlowRateString(nearestItem.totalFlow);
		}
	}

	private updateDatumDetails() {
		// Current Flow Data Info
		if (this.activeFlowChartItems && this.activeFlowChartItems.length > 0) {
			const currentFlowDatum = this.activeFlowChartItems[this.currentFlowDataIndex];
			if (currentFlowDatum === undefined) return;

			this.currentFlowDataFlowRate = this.conversionService.getUserFlowRateString(currentFlowDatum.totalFlow);
			this.currentFlowDataDate = this.dateAndTimeService.dateToUserDateString(currentFlowDatum.timestamp);
			this.currentFlowDataTime = this.dateAndTimeService.dateToUserTimeString(currentFlowDatum.timestamp, true);

			this.updateActiveStationsAndProgramGroup(currentFlowDatum);
		}
	}

	private updateActiveStationsAndProgramGroup(currentFlowDatum: ActiveFlowChartItem) {
		// Current Flow Datum Station Information
		this.currentActiveStations = [];
		if (currentFlowDatum.runningStations && currentFlowDatum.runningStations.length > 0) {
			currentFlowDatum.runningStations.forEach(stationDatum => {
				const flowRateString = `${this.conversionService.getUserFlowRateString(stationDatum.currentFlowRateGPM || 0)} ${this.flowRateLabel}`;

				if (!stationDatum.stationName) {
					stationDatum.stationName = this.irrigationService.getStationName(stationDatum.stationId)
				}

				this.currentActiveStations.push(new FlowDatumActiveStation(stationDatum.stationName, flowRateString));
			});
		}

		// Current Running unique Program Group
		this.currentRunningProgramGroups = (currentFlowDatum && currentFlowDatum.runningProgramGroups.length > 0) ?
			[...new Map(currentFlowDatum.runningProgramGroups.map(pg => [pg.programGroupId, pg])).values()] : [];
		// Current Running Programs
		this.currentRunningPrograms = currentFlowDatum.runningPrograms;

		// RB-10805: Current running pumps
		this.currentActivePumps = this.getCurrentActivePumps(currentFlowDatum.totalFlowByPump);
	}
	
	private getCurrentActivePumps(totalFlowByPump: Map<number, number>): FlowElement[] {
		const activePumps:FlowElement[] = [];

		totalFlowByPump.forEach((value, key) => {
			var index = this.companyPumps.findIndex(x => x.id === key);
			if (index < 0 || value <= 0) return;

			activePumps.push(this.companyPumps[index]);
		});

		return activePumps;
	}

	private getXAxisLabel(utcTime: number) {
		const timestamp = new Date(utcTime);
		if (!(timestamp instanceof Date)) return '';

		const date = this.dateAndTimeService.dateToUserDateString(timestamp);
		const time = this.dateAndTimeService.dateToUserTimeString(timestamp);

		return `${date}<br />${time}`;
	}

	private getTooltipDateTime(utcTime: number, isForTooltip = false) {
		const timestamp = new Date(utcTime);
		if (!(timestamp instanceof Date)) return '';

		return `${this.dateAndTimeService.dateToUserDateString(timestamp)} - ${this.dateAndTimeService.dateToUserTimeString(timestamp, true)}`;
	}

	private chartRedrawn() {
		this.chartRedraw.emit();
	}

	private setupChart() {
		const self = this;

		// RB-8243: Since we have multiple total values to compare for Golf, we don't use an area chart as we do
		// in Commercial. The lines for golf will be the theoretical value (sum of station flow rates expected),
		// and, typically, flow sensor values for any flow sensors marked to be shown on the FloGraph.
		let chartType = 'area';
		let tooltip: any;

		// RB-8243: There are a couple of differences between Golf and Commercial here. The flow rate from the
		// stations in Golf is called "Theoretical", because that assumes the idealized flow rate of the station
		// given rotor type, nozzle, system pressure, etc. all of which have notable variation from place to
		// place and part to part. If we have any flow sensors, those would be Actual flow for golf.
		// For Commercial the active flow chart is based on the flow rate reported from the controller (similarly
		// to the flow sensor in the golf case), and there isn't really a theoretical value.

		// Add series for each FlowSensor. We'll have to get the flow sensor historical data and update the
		// data set as new sensor values arrive.
		if (this.isGolfSite) {
			// Golf default series is the Theoretical station total and uses the same color as the FloGraph on the
			// dashboard.
			chartType = 'line';
			// Set a different tooltip format for Golf than Commercial.
			tooltip = {
				enabled: true,
				style: {
					fontSize: RbConstants.Form.FLO_GRAPH_ACTIVE_CHART_GOLF.tooltipFontSize,
					fontFamily: RbConstants.Form.FLO_GRAPH_ACTIVE_CHART_GOLF.fontFamily,
					color: RbConstants.Form.FLO_GRAPH_ACTIVE_CHART_GOLF.textColor
				},
				// backgroundColor: RbConstants.Form.FLO_GRAPH_DASHBOARD_WIDGET.lineColor,
				formatter: function() {
					if (this.x) {
						return `${self.getTooltipDateTime(this.x, true)}<br><b>${this.point.y.toFixed(2)} ${self.flowRateLabel}</b>`;
					} else {
						return `<b>${this.point.y.toFixed(2)} ${self.flowRateLabel}</b>`;
					}
				}
			};
		} else {
			// Commercial default series is the Actual data and uses an area fill with linear gradient.
			tooltip = {
				enabled: this.irrigationActivityService.isGolf,
				pointFormat: `${RbUtils.Translate.instant('STRINGS.FLOW_RATE')}: <b>{point.y:,.2f} ${self.flowRateLabel}</b>`
			};
		}

		this.chartOptions = {
			boost: {
				// NOTE: Point selection is not possible when using boost mode. We also run into issues with displaying the proper running items
				//       when boost mode is enabled.
				enabled: false,
				useGPUTranslations: this.useGPUTranslations,
				seriesThreshold: this.seriesThreshold
			},
			chart: {
				type: chartType,
				zoomType: 'x',
				marginRight: 15,
				plotBorderWidth: 1,
				events: {
					redraw: function() {
						self.chartRedrawn();
					}
				}
			},
			credits: { enabled: false },
			exporting: { enabled: false },
			accessibility: { description: `${RbUtils.Translate.instant('STRINGS.DRY_RUN_RATE_OVER_TIME')}` },
			title: { text: '' },
			xAxis: {
				type: 'datetime',
				labels: {
					formatter: function() {
						return self.getXAxisLabel(this.value);
					},
					step: 2
				},
				crosshair: {
					width: 1,
					color: 'gray',
					snap: false,
					zIndex: 5
				}
			},
			yAxis: {
				title: {
					text: `${RbUtils.Translate.instant('STRINGS.FLOW_RATE')} (${self.flowRateLabel})`
				},
				labels: {
					allowDecimals: true,
					formatter: function() {
						return this.value;
					}
				},
				crosshair: {
					width: 1,
					color: 'gray',
					snap: true,
					zIndex: 5
				}
			},
			tooltip: tooltip,
			legend: {
				align: 'right',
				verticalAlign: 'top'
			},
			plotOptions: {
				area: {
					marker: {
						enabled: false,
						symbol: 'circle',
						radius: 4,
						states: {
							hover: {
								enabled: true,
								animation: { 'duration': 0 }
							},
							select: {
								radius: 6,
								fillColor: self.CHART_MARKER_FILL_COLOR,
								lineColor: self.CHART_MARKER_LINE_COLOR
							}
						}
					}
				},
				line: {
					marker: {
						enabled: false,
						symbol: 'circle',
						radius: 4,
						states: {
							hover: {
								enabled: true,
								animation: { 'duration': 0 }
							},
							select: {
								enabled: false,
								radius: 6,
								fillColor: self.CHART_MARKER_FILL_COLOR,
								lineColor: self.CHART_MARKER_LINE_COLOR
							}
						}
					}
				}
			},
			series: self.getSeriesData()
		};

		this.showChart = true;
	}

	private getSeriesData() {
		const self = this;
		const seriesData: any[] = [];
		if (this.isGolfSite) {
			seriesData.push({
				name: `${RbUtils.Translate.instant('STRINGS.THEORETICAL')}`,
				// NOTE: Using step will draw the chart properly w/o the need to inject extra points; however, running items will not be shown properly
				//          when the mouse is moved across a runtime event. This occurs because the behavior of the chart is to select the nearest point,
				//          which is the 'next' point when the mouse is over half way between two activity points. So instead of showing what is currently
				//          running, we end up showing the activity of the next point while the mouse is still to the left of that point.
				// step: 'left',
				color: RbConstants.Form.FLO_GRAPH_DASHBOARD_WIDGET.lineColor,
				data: this.flowDataArray,
				turboThreshold: 0,
				boostThreshold: this.boostThreshHold,
				events: {
					legendItemClick: function() { return true; }
				}
			});

			// RB-10805: Adding series for each active pump
			if (this.activeFlowChartItems && this.companyPumps){
				this.companyPumps.forEach((x, index) => {
					seriesData.push({
						name: x.name,
						color: this.secondaryChartColors[index % this.secondaryChartColors.length],
						data: this.convertToPumpChartData(x, this.activeFlowChartItems),
						turboThreshold: 0,
						boostThreshold: this.boostThreshHold,
						visible: this.getLineVisibility(x.name),
						events: {
							legendItemClick: function (event: any):boolean { 
								self.setLineVisibility(event.target.name, !event.target.visible);
								self.onLinesVisibilityChanged.emit(self._lineVisibility);
								return true;
							}
						}
					});
				});
			}
			
			if (this._secondaryFlowChartItemInfo && this._secondaryFlowChartItemInfo.length > 0) {
				this._secondaryFlowChartItemInfo.forEach((info, index) => {
					// The items in the list are probably flow sensors, but we really don't care. Just push
					// the name and other information from the 'info'.
					seriesData.push({
						// We don't really use the id for much, but when adding/removing series after the chart is initially
						// built, Highcharts will use the ids to "correlate" previous displays with new ones, allowing the display
						// name to be updated, for example, without completely rebuilding the series. This improves display responsiveness
						// without requiring lots of redrawing.
						id: info.id,
						name: info.name,
						color: this.secondaryChartColors[index % this.secondaryChartColors.length],

						// Rather than reworking the data into an array of numbers somewhere, it seemed easier to use map inline and
						// convert the incoming data values, in gpm, to the user's chosen measurement system.
						data: this.convertToChartData(this.secondaryFlowChartItems[info.id]),
						turboThreshold: 0,
						boostThreshold: this.boostThreshHold,
						events: {
							legendItemClick: function() { return true; }
						}
					});
				});
			}
		} else {
			// non golf site
			seriesData.push({
				name: `${RbUtils.Translate.instant('STRINGS.ACTUAL')}`,
				color: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0, this.CHART_LINEAR_FILL_TOP],
						[1, this.CHART_LINEAR_FILL_BOTTOM]
					]
				},
				data: this.flowDataArray,
				turboThreshold: 0,
				events: {
					legendItemClick: function() { return true; }
				}
			});
		}
		return seriesData;
	}

	private getLineVisibility(lineName: string){
		if (this._lineVisibility.has(lineName))
			return this._lineVisibility.get(lineName);
		
		return false;
	}
	
	private setLineVisibility(lineName:string, isVisible: boolean){
		if (!lineName) return;
		
		this._lineVisibility.set(lineName, isVisible)
	}

	private convertToPumpChartData(companyPump:FlowElement, items: ActiveFlowChartItem[]):{x:number, y:number}[] {
		const pumpChartData:{x:number, y:number}[] = [];

		items.filter(x => x.totalFlowByPump.size > 0).forEach((element) => {
			var flowRate:number = 0;

			if (element.totalFlowByPump.has(companyPump.id)) {
				flowRate = element.totalFlowByPump.get(companyPump.id);
			}

			pumpChartData.push({
				x: RbUtils.Conversion.convertDateToUTCMilliseconds(element.timestamp),
				y: this.conversionService.getUserFlowRate(flowRate)
			});
		});

		return pumpChartData;
	}

	private convertToChartData(items: FlowChartItem[]) {
		const sensorDataArray:{x:number, y:number}[] = [];
		if (items) {
			for (let i = 0; i < items.length; i++) {
				sensorDataArray.push({
					x: RbUtils.Conversion.convertDateToUTCMilliseconds(items[i].timestamp),
					y: this.conversionService.getUserFlowRate(items[i].totalFlow)
				});
			}
		}
		for (let i = sensorDataArray.length - 1; i > 0; i--) {
			if (sensorDataArray[i - 1].y !== sensorDataArray[i].y) {
				sensorDataArray.splice(i, 0, { x: sensorDataArray[i].x - 1, y: sensorDataArray[i - 1].y });
			}
		}
		return sensorDataArray;
	}
}

<button
	mat-raised-button
	color="primary"
	type="button"
	[disabled]="modalSubmitting || disabled"
	(click)="primaryButtonClicked.emit()"
>
	{{ label }}
	<i [hidden]="!isLoading" class="fa fa-circle-o-notch fa-spin"></i>
</button>

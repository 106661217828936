import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { SelectListItem } from '../../../../../api/_common/models/select-list-item.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-alarm-data',
	templateUrl: './alarm-data.component.html',
	styleUrls: ['./alarm-data.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AlarmDataComponent implements OnInit {
	@Input() hidePlaceholder = false;

	private _selectedValue = [RbEnums.Common.EventLogType.OpenAlarm, RbEnums.Common.EventLogType.OpenWarning];
	@Input() set selectedValues(value: RbEnums.Common.EventLogType[]) {
		if (!value) return;

		this._selectedValue = value;
	}
	get selectedValues(): RbEnums.Common.EventLogType[] {
		return this._selectedValue;
	}

	@Output() selectionEvent = new EventEmitter;

	currentSelectionText = this.translate.instant('STRINGS.SELECT_DATA');
	items: SelectListItem[];

	constructor(private translate: TranslateService) { }

	ngOnInit() {
		this.items = [];
		const keys = Object.keys(RbEnums.Common.EventLogType).filter(key => !isNaN(Number(RbEnums.Common.EventLogType[key])) &&
					RbEnums.Common.EventLogType[key] !== RbEnums.Common.EventLogType.AcknowledgedAlarms &&
					RbEnums.Common.EventLogType[key] !== RbEnums.Common.EventLogType.AcknowledgedWarnings);
		keys.forEach(key => {
			this.items.push(new SelectListItem({ name: RbUtils.Translate.instant(`STRINGS.${key.toUpperCase()}`), value: RbEnums.Common.EventLogType[key] }));
		});
		this.currentSelectionText = this.selectionText;
		this.selectionEvent.emit(this.selectedValues);
	}

	handleSelection(selection) {
		this.selectedValues = selection.value;
		this.currentSelectionText = this.selectionText;
		this.selectionEvent.emit(selection.value);
	}

	private get selectionText(): string {
		return this.selectedValues.map(value => this.items.find(item => item.value === value).name).join(', ');
	}
}

import * as moment from 'moment';
import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CultureSettings } from '../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../api/culture-settings/culture-settings-manager.service';
import { RbConstants } from '../constants/_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class DateAndTimeService implements OnDestroy {

	cultureSettings: CultureSettings;

	// =========================================================================================================================================================
	// C'tor, Destroy
	// =========================================================================================================================================================

	constructor(private cultureSettingsManager: CultureSettingsManagerService
	) {
		// Monitor culture changes to show proper units.
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe((cultureSettings: CultureSettings) => {
				this.cultureSettings = cultureSettings;
			});

		this.getCultureSettings();
	}

	ngOnDestroy(): void {
		/** Required for untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	dateToUserDateString(date: Date): string {
		return moment(date).format(this.getUserDateFormat());
	}

	dateToUserTimeString(date: Date, includeSeconds = false): string {
		if (!includeSeconds) return moment(date).format(this.getUserTimeFormat());

		return moment(date).format(this.getUserTimeFormatWithSeconds());
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getCultureSettings() {
		this.cultureSettings = RbUtils.User.cultureSettings;

		if (this.cultureSettings === undefined || this.cultureSettings === null) {
			setTimeout(() => this.getCultureSettings(), 100);
			return;
		}
	}

	private getUserDateFormat() {
		return RbUtils.Common.getDateFormat(this.cultureSettings).toLocaleUpperCase();
	}

	private getUserTimeFormat() {
		return (this.cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm)
			? RbConstants.Form.TIME_FORMAT_DISPLAY_OPTIONS.amPm
			: RbConstants.Form.TIME_FORMAT_DISPLAY_OPTIONS.hours;
	}

	private getUserTimeFormatWithSeconds() {
		return (this.cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm)
			? RbConstants.Form.TIME_FORMAT_WITH_SECONDS_DISPLAY_OPTIONS.amPm
			: RbConstants.Form.TIME_FORMAT_WITH_SECONDS_DISPLAY_OPTIONS.hours;
	}

}

/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.GridCellStyles.getControllerStatusCellStyle
 * 		 See: _rb.utils.ts
 */

import { AppInjector } from '../../core/core.module';
import { ControllerListItem } from '../../api/controllers/models/controller-list-item.model';
import { GroupLevel } from '../../api/users/models/group-level.model';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from './_rb.utils';
import { TitleCasePipe } from '@angular/common';

export namespace XXUseRbUtilsNamespace {

	export abstract class GridCellStyles {

		private static _tileCasePipe: TitleCasePipe;

		private static get tileCasePipe() {
			if (!this._tileCasePipe) {
				this._tileCasePipe = AppInjector.get(TitleCasePipe);
			}
			return this._tileCasePipe;
		}

		// =====================================================================================================================================================
		// Public Methods
		// =====================================================================================================================================================

		static getControllerStatusCell(data: ControllerListItem, isGolf: boolean, isControllerDifferencesCell = false) {
			
			// IQI controller is treated as Golf interface
			if (data.type === RbEnums.Common.DeviceType.IQI){
				return GridCellStyles.getInterfaceStatusCell(data);
			}
			
			let cellHtml = '<div class="controller-grid-status-container">';

			cellHtml += GridCellStyles.getControllerSyncState(data.syncState, data.queued, isGolf);
			
			cellHtml += GridCellStyles.getControllerGetPhysicalDataState(data.gettingPhysicalData, data.queued);
			if (!isControllerDifferencesCell) {
				cellHtml += GridCellStyles.getControllerLogsDownloadState(data.gettingLogs, data.queued);
			}
			cellHtml += GridCellStyles.getShutdownOrRainDelay(data);
			if (isControllerDifferencesCell) {
				cellHtml += GridCellStyles.getControllerDeltaState(data.hasLastSyncDifferences);
			}
			if (!isControllerDifferencesCell) {
				cellHtml += GridCellStyles.getConnectedState(data.isConnected, isGolf);
				cellHtml += GridCellStyles.getFlowAlarmsState(data);
			}

			cellHtml += '</div>';

			return cellHtml;
		}

		static getLockStatus(tooltipString: string) {
			const tooltipAttribute = `title='${tooltipString}'`;
			return `<i class="material-icons icon-station-error-style" ${tooltipAttribute}>lock</i>`;
		}

		static getInterfaceStatusCell(data: ControllerListItem) {
			let cellHtml = '<div class="controller-grid-status-container">';

			if (data.commInterfaceType === RbEnums.Common.CommInterfaceType.Demo) {
				cellHtml += RbUtils.Translate.instant('STRINGS.DEMO');
			} else {
				cellHtml += GridCellStyles.getConnectedState(data.isConnected, true /* isGolf */);
			}
			cellHtml += '</div>';

			return cellHtml;
		}
		static getEmailCellStyle(params) {
			return '<a href="mailto:' + params.value + '">' + params.value + '</a>';
		}

		static getFavoriteHeaderTemplate() {
			return '<i class="material-icons star-icon selected">star<i>';
		}

		static getFavoriteCellStyle(params) {
			return '<i class="material-icons star-icon' + (params.value === true ? ' selected' : '') + '">' +
				(params.value === true ? 'star' : 'star_border') + '</i>';
		}

		static getOwnerCellStyle(groupLevelItem: GroupLevel, isMobile = false) {
			if (groupLevelItem.value === RbEnums.Common.GroupLevel.RootAdmin) {
				return '<span class="flex items-center"><span class="flex items-center pr1"><i class="material-icons">supervisor_account</i></span>' +
					'<span class="flex items-center">' + groupLevelItem.name + '</span></span>';
			} else {
				if (!isMobile) {
					return '<span class="flex items-center rb-admin-user">' + groupLevelItem.name + '</span>';
				}
				return '<span class="flex items-center">' + groupLevelItem.name + '</span>';
			}
		}

		static etBasedProgramStepCellRenderer(runtime) {
			return '<span>' + '<span>' + runtime +
				'</span><span><mat-icon class="icon-station-et icon-timer"></mat-icon></span></span>';
		}

		static etIconCellRenderer() {
			return '<span> <mat-icon class="icon-station-et icon-inch-value"></mat-icon> </span>';
		}

		static getSelectableCountCellStyle(params) {
			const value = params.value === 0 ? '-' : params.value;
			return '<button class="no-underline br2 bn w2 ml1 dib primaryColor">' + value + '</button>';
		}

		static getFadingCircleHtml() {
			return '<div class="sk-fading-circle">'
				+ '<div class="sk-circle1 sk-circle"></div>'
				+ '<div class="sk-circle2 sk-circle"></div>'
				+ '<div class="sk-circle3 sk-circle"></div>'
				+ '<div class="sk-circle4 sk-circle"></div>'
				+ '<div class="sk-circle5 sk-circle"></div>'
				+ '<div class="sk-circle6 sk-circle"></div>'
				+ '<div class="sk-circle7 sk-circle"></div>'
				+ '<div class="sk-circle8 sk-circle"></div>'
				+ '<div class="sk-circle9 sk-circle"></div>'
				+ '<div class="sk-circle10 sk-circle"></div>'
				+ '<div class="sk-circle11 sk-circle"></div>'
				+ '<div class="sk-circle12 sk-circle"></div>'
				+ '</div>';
		}

		/**
		 * Return HTML for "connected" state icon used in a grid cell, etc. This is generally a green circle
		 * for connected, red outline with ! for error, black block for suspended.
		 */
		static getStationConnected(params, tooltipString?: string) {
			// The way tooltips work is:
			// * icons take them as their "title" attributes
			// * text values have problems
			let result = '';
			let tooltipAttribute = '';
			if (tooltipString != null) {
				tooltipAttribute = `title='${tooltipString}'`;
			}
			if (params.suspended) {
				result = `<i id="station-suspended" class="material-icons icon-station_suspended icon-station-suspended-style" ${tooltipAttribute}></i>`;
			} else if (params.isConnected && params.isInterfaceConnected) {
				result = `<i id="station-connected" class="material-icons icon-station_connected icon-station-connected-style" ${tooltipAttribute}></i>`;
			} else {
				result =
					`<i id="station-disconnected" class="material-icons icon-station_disconnected icon-station-disconnected-style" ${tooltipAttribute}></i>`;
			}

			return result;
		}

		static getReportTypeCell(reportType, subject) {
			let cellHtml = '';
			cellHtml = `<img class="report-type-img" src="${reportType.img}" style="background-image: ${reportType.gradient}"><p>${subject}</p>`;
			return cellHtml;
		}

		/**
		 * Return a css-class as a qaid hook for QA automation into selection-checkboxes
		 */
		static getDataQaidCheckboxClass(name) {
			return `data-qaid-CB-${RbUtils.Common.replaceWhitespace(name, '_')}`;
		}

		/**
		 * Return a css-class as a qaid hook for QA automation for each row
		 */
		static getDataQaidRowClass(name) {
			return `data-qaid-row-${RbUtils.Common.replaceWhitespace(name, '_')}`;
		}

		// =====================================================================================================================================================
		// Helper Methods
		// =====================================================================================================================================================

		private static getControllerSyncState(syncState: RbEnums.Common.ControllerSyncState, queued: boolean, isGolf: boolean) {
			// RB-7650: If we are queued for the operation, show a badge on the icon indicating that it hasn't started yet, but
			// is waiting.
			switch (syncState) {
				case RbEnums.Common.ControllerSyncState.Synchronized:
					return `<i class="material-icons controller-sync-status-icon controllers-grid" \
					title="${this.formatTooltip('STRINGS.SYNCHRONIZED')}">sync</i>`;
				case RbEnums.Common.ControllerSyncState.NotSynchronized:
					return `<i class="material-icons controller-sync-status-icon not-synced controllers-grid" \
					title="${this.formatTooltip('STRINGS.OUT_OF_SYNC')}">sync</i>`;
				case RbEnums.Common.ControllerSyncState.Incomplete:
					return `<i class="material-icons controller-sync-status-icon incomplete controllers-grid" \
					title="${this.formatTooltip('STRINGS.INCOMPLETE_SYNCHRONIZE')}">sync_problem</i>`;
				case RbEnums.Common.ControllerSyncState.HasDifferences:
					return `<i class="material-icons controller-sync-status-icon not-synced controllers-grid" \
					title="${this.formatTooltip('STRINGS.CONTROLLER_DIFFERENCES')}">swap_vert</i>`;
				case RbEnums.Common.ControllerSyncState.Syncing:
					// For the queued case, choose a different display. Once the sync actually starts, the old spinner will
					// return. Possible glyphs are: hourglass_empty, hourglass_full, hourglass_bottom, hourglass_top,
					// watch, watch_later, access_time, playlist_add_check.
					if (queued) {
						return `<i class="material-icons controller-sync-status-icon not-synced controllers-grid rb-is-queued rb-delayed-spin" \
								title="${this.formatPendingTooltip('STRINGS.SYNCHRONIZE_PENDING')}">hourglass_empty</i>`;
					} else {
						return `<i class="material-icons controller-sync-status-icon rb-spin-counterclockwise not-synced controllers-grid" \
						title="${this.formatPendingTooltip('STRINGS.SYNCING')}">sync</i>`;
					}
				case RbEnums.Common.ControllerSyncState.ReverseSyncing:
					if (queued) {
						return `<i class="material-icons controller-sync-status-icon not-synced controllers-grid  rb-is-queued rb-delayed-spin" \
								title="${this.formatPendingTooltip('STRINGS.REVERSE_SYNCHRONIZE_PENDING')}">hourglass_empty</i>`;
					} else {
						return `<i class="material-icons controller-sync-status-icon rb-rotate-y not-synced controllers-grid" \
						title="${this.formatPendingTooltip('STRINGS.REVERSE_SYNCHRONIZING')}">swap_vert</i>`;
					}
				default:
					return `<i class="material-icons controller-sync-status-icon sync-error controllers-grid" \
					title="${isGolf ? "" : this.formatTooltip('STRINGS.SYNC_FAILED')}">report_problem</i>`;
			}
		}

		private static formatPendingTooltip(tip: string) { return `${this.tileCasePipe.transform(RbUtils.Translate.instant(tip))}...`; }

		private static formatTooltip(tip: string) { return `${this.tileCasePipe.transform(RbUtils.Translate.instant(tip))}`; }

		private static getControllerLogsDownloadState(downloadRunning: boolean, isQueued: boolean) {
			if (downloadRunning) {
				if (isQueued) {
					return `<i class="material-icons controller-sync-status-icon controllers-grid rb-delayed-spin rb-is-queued" \
							title="${this.formatPendingTooltip('STRINGS.RETRIEVE_LOGS_PENDING')}">hourglass_empty</i>`;
				}

				// Wrap with class that will rotate-y the get-logs icon.
				return `<i class="material-icons controller-sync-status-icon rb-rotate-y controllers-grid" \
				title="${this.formatTooltip('STRINGS.RETRIEVE_LOGS')}">vertical_align_bottom</i>`;
			}
			return '';
		}

		private static getControllerGetPhysicalDataState(downloadRunning: boolean, isQueued: boolean) {
			if (downloadRunning) {
				if (isQueued) {
					return `<i class="material-icons controller-sync-status-icon controllers-grid rb-delayed-spin rb-is-queued" \
							title="GetPhysicalData">hourglass_empty</i>`;
				}

				return `<i class="material-icons controller-sync-status-icon rb-spin-counterclockwise not-synced controllers-grid" \
				title="GetPhysicalData">refresh</i>`;
			}
			return '';
		}

		private static getShutdownOrRainDelay(data: ControllerListItem) {
			if (data.isShutdown) {
				return `<i class="rb-status-icon icon-auto-off" \
				title="${this.formatTooltip('STRINGS.CONTROLLER_OFF')}"></i>`;
			}

			if (data.isInRainDelay) {
				const dayLabel = data.rainDelay > 1 ? RbUtils.Translate.instant('STRINGS.DAYS') : RbUtils.Translate.instant('STRINGS.DAY');
				const tooltip = `${RbUtils.Translate.instant('STRINGS.RAIN_DELAY')}: ${data.rainDelay} ${dayLabel}`;
				return `<i class="rb-status-icon icon-rain-delay" title="${tooltip}"></i>`;
			}

			return '';
		}

		private static getFlowAlarmsState(data: ControllerListItem) {
			if (data.hasFlowAlarms) {
				const tooltip = RbUtils.Translate.instant('STRINGS.FLOW_ALARMS');
				return `<i class="material-icons icon-error-style" title="${tooltip}">error_outline</i>`;
			}
			return '';
		}

		private static getConnectedState(isConnected: boolean, isGolf: boolean) {
			if (isConnected) {
				return `<i class="material-icons connection-status" \
				title="${this.formatTooltip('STRINGS.CONNECTED_TO_CONTROLLER')}">lens</i>`;
			}
			return isGolf ? '<i class="material-icons connection-status-disconnected">error_outline</i>' : '';
		}

		private static getControllerDeltaState(hasDifferences: boolean) {
			if (hasDifferences) {
				return `<i class="material-icons rb-icon-font icon-field-changes not-synced controllers-grid"" \
				title="${this.formatTooltip('STRINGS.CONTROLLER_DIFFERENCES')}"></i>`;
			}

			return '';
		}

	}

}

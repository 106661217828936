<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-dialog
	[title]="'STRINGS.ADD_ADDRESS' | translate"
	[closeButtonLabel]="'STRINGS.CANCEL' | translate "
	[submitButtonLabel]="'STRINGS.SAVE' | translate"
	[showSubmitButton]="true"
	[closeOnSubmit]="true"
	(onCancel)="onAddressDialogCanceled()"
	(onSubmit) = "onAddressDialogSubmitted()"
	[saveDisabled]="!addressForm?.valid || !addressForm?.dirty">
	<form [formGroup]="addressForm" class="add-site-address-form">
		<mat-form-field>
			<input formControlName="address" matInput [placeholder]="'STRINGS.ADDRESS' | translate" required
					[minLength]="RbConstants.Form.CHARACTER_LENGTH_RANGE.min" [maxLength]="RbConstants.Form.CHARACTER_LENGTH_RANGE.max"
					(focus)="$any($event.target).select()"/>
		</mat-form-field>
		<div *ngIf="addressErrorMessage" class="warnColorText">{{addressErrorMessage | translate}}</div>
		<mat-form-field>
			<input formControlName="city" matInput [placeholder]="'STRINGS.CITY' | translate"
					[minLength]="RbConstants.Form.CHARACTER_LENGTH_RANGE.min" [maxLength]="RbConstants.Form.CHARACTER_LENGTH_RANGE.max"
					(focus)="$any($event.target).select()"/>
		</mat-form-field>
		<div *ngIf="cityErrorMessage" class="warnColorText">{{cityErrorMessage | translate}}</div>
		<div class="state-and-zip">
			<div class="state">
				<mat-form-field>
					<input formControlName="state" matInput [placeholder]="'STRINGS.STATE' | translate"
							[minLength]="RbConstants.Form.STATE_LENGTH_RANGE.min"
							[maxLength]="RbConstants.Form.STATE_LENGTH_RANGE.max"
							(focus)="$any($event.target).select()"/>
				</mat-form-field>
			</div>
			<div class="zip">
				<mat-form-field>
					<input formControlName="zip" matInput [placeholder]="'STRINGS.ZIP' | translate"
							[minLength]="RbConstants.Form.ZIP_LENGTH_RANGE.min"
							[maxLength]="RbConstants.Form.ZIP_LENGTH_RANGE.max"
							(focus)="$any($event.target).select()"/>
				</mat-form-field>
			</div>
		</div>
		<div *ngIf="stateErrorMessage" class="warnColorText">{{stateErrorMessage | translate}}</div>
		<div *ngIf="zipErrorMessage" class="warnColorText">{{zipErrorMessage | translate}}</div>
	</form>
</rb-dialog>
import { FlowElement } from '../../api/flow-elements/models/flow-element.model';

export namespace XXUseRbUtilsNamespace {
	export abstract class Tree {
		static addTreeElement(parent: FlowElement, sibling: FlowElement, flowElement: FlowElement) {
			if (sibling && parent.bConnections && parent.bConnections.length > 0 )	{
				for ( let i = 0; i < parent.bConnections.length; i++) {
					if ( parent.bConnections[i].id === sibling.id) {
						parent.bConnections.splice(i, 0, flowElement);
					}
				}
			} else {
				parent.bConnections.push(flowElement);
			}
			return parent;
		}
		static deleteTreeElement(parent: FlowElement, flowElement: FlowElement) {
			if (parent.bConnections && parent.bConnections.length > 0 )	{
				for ( let i = 0; i < parent.bConnections.length; i++) {
					if ( parent.bConnections[i].id === flowElement.id) {
						parent.bConnections.splice(i, 1);
					}
				}
			}
			return parent;
		}
		static updateTreeElement(parent: FlowElement, flowElement: FlowElement) {
			if (parent.bConnections && parent.bConnections.length > 0 )	{
				for ( let i = 0; i < parent.bConnections.length; i++) {
					if ( parent.bConnections[i].id === flowElement.id) {
						parent.bConnections[i] = flowElement;
					}
				}
			}
			return parent;
		}
	}
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';

@Component({
	selector: 'rb-link-diagnostics-cell-renderer',
	templateUrl: './link-diagnostics-cell-renderer.component.html',
	styleUrls: [ './link-diagnostics-cell-renderer.component.scss' ]
})
export class LinkDiagnosticsCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';
	node = {
		level: 0,
		class: '',
		data: ''
	}

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);

		if(params.data){
			const ld = params.data.lastDiagnostic;
			const availability = (ld?.responses / ld?.calls) * 100;

			this.node.level = params.data.nodeLevel;
			this.node.data = `${Math.round(availability) || 0}%`;

			if(availability <= 50){
				this.node.class = 'text-red';
			} else if(availability > 50 && availability <= 80){
				this.node.class = 'text-yellow';
			}
		}
	}
}

import { CCWeatherDataService, WeatherTimeStep } from '../../../../../api/ccweather/ccweather-data.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CCWeatherCondition } from '../../../../../api/ccweather/models/ccweather-condition.model';
import { CCWeatherValuesData } from '../../../../../api/ccweather/models/ccweather-values-data.model';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-ccweather-widget-daily',
	templateUrl: './ccweather-widget-daily.component.html',
	styleUrls: ['./ccweather-widget-daily.component.scss']
})
export class CCWeatherWidgetDailyComponent implements OnInit, OnDestroy {

	@Input() widgetId: number;
	@Input() timezone: string;
	@Input() isIq4SidebarWidget = false;
	@Input() theme = '';

	requiredConditions: string[] = [];
	conditions: CCWeatherCondition[] = [];
	dailyData: CCWeatherValuesData[] = [];
	daysFull = [];

	constructor(private ccWeatherDataService: CCWeatherDataService) { }

	ngOnInit() {
		this.requiredConditions = ['temperatureMinMax'];
		this.conditions = this.ccWeatherDataService.getSettings(this.widgetId, this.requiredConditions).conditions;
		this.getDaily();
		this.ccWeatherDataService.weatherForecastLoaded.subscribe(() => {
			this.getDaily();
		});
	}

	ngOnDestroy() {}

	private getDaily() {
		const startDate = new Date();
		startDate.setDate(startDate.getDate());
		const daily = this.ccWeatherDataService.getWeatherForecast(this.widgetId, WeatherTimeStep.DAY);
		this.daysFull = [];
		this.dailyData = [];
		let idx = 0;
		while (this.dailyData.length !== 7) {
			this.daysFull.push(idx === 0 ?
				RbUtils.Translate.instant('WEEKDAYS.TODAY') :
				RbUtils.Date.transform(startDate, 'EEE', this.timezone).toUpperCase());
			if (daily.intervals[idx]) {
				this.dailyData.push(daily.intervals[idx].values);
			} else {
				this.dailyData.push(new CCWeatherValuesData());
			}
			startDate.setDate(startDate.getDate() + 1);
			idx++;
		}
	}

	getTemperature(daily: CCWeatherValuesData, min: boolean) {
		return daily['temperature' + (min ? 'Min' : 'Max')] === null ? '-' : (daily['temperature' + (min ? 'Min' : 'Max')] + this.conditions[0].units);
	}

	getWeatherName(weatherCode) {
		return this.ccWeatherDataService.getWeatherName(weatherCode);
	}

	onWeatherIconClick(event) {
		event.preventDefault();
		event.stopPropagation();
		return false;
	}
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { CompanyManagerService } from '../../../api/companies/company-manager.service';
import { CompanyPreferences } from '../../../api/companies/models/company-preferences.model';
import { CompanyStatus } from '../../../api/companies/models/company-status.model';
import { ControllerChange } from '../../../api/signalR/controller-change.model';
import { ControllerGetPhysicalDataState } from '../../../api/signalR/controller-get-physical-data-state.model';
import { ControllerManagerService } from '../../../api/controllers/controller-manager.service';
import { FlowElementManagerService } from '../../../api/flow-elements/flow-element-manager.service';
import { IrrigationActivityService } from '../../../api/irrigation-activity/irrigation-activity.service';
import { ProgramChange } from '../../../api/signalR/program-change.model';
import { ProgramGroupManagerService } from '../../../api/program-groups/program-group-manager.service';
import { ProgramManagerService } from '../../../api/programs/program-manager.service';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Site } from '../../../api/sites/models/site.model';
import { SiteCount } from '../../../api/companies/models/site-count.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { SystemStatusService } from '../../../common/services/system-status.service';
import { TranslateService } from '@ngx-translate/core';
import { WeatherSourceManagerService } from '../../../api/weather-sources/weather-source-manager.service';

@UntilDestroy()
@Component({
	selector: 'rb-system-status-toolbar',
	templateUrl: './system-status-toolbar.component.html',
	styleUrls: ['./system-status-toolbar.component.scss']
})
export class SystemStatusToolbarComponent implements OnInit, OnDestroy {

	isGolfSite = false;

	// Commercial
	companyStatus: CompanyStatus;
	sites: Site[];
	connectedControllersCount = 0;
	visibleSiteCount: SiteCount[] = [];
	differencesCount = 0;
	outOfSyncCount = 0;
	rainDelayCount = 0;
	shutdownCount = 0;
	timeString: string;
	// Golf
	companyPreference: CompanyPreferences;
	systemStatus: RbEnums.Common.SystemStatus;
	isSystemLocked: boolean;
	background: string;
	color: string;
	icon: string;
	RbColorConstants = RbConstants.Form.SYSTEM_STATUS_COLOR;
	RbSystemStatusConstants = RbConstants.Form.SYSTEM_STATUS;
	RbColorBackGroundConstants = RbConstants.Form.SYSTEM_STATUS_DASH_BOARD_COLOR;
	systemStatusMsg: string;
	private etValue = 0;

	get etDisplayValue(): string {
		if (!this.authManager.userCulture) return '0';
		return RbUtils.Common.convertLengthToUserCulture(this.authManager.userCulture, this.etValue, RbEnums.Common.LengthUnit.Inch, 2)
			+ ' ' + RbUtils.Weather.getSmallLengthUnits();
	}

	// =========================================================================================================================================================
	// C'tor, Init, and Destroy
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private companyManager: CompanyManagerService,
				private controllerManager: ControllerManagerService,
				private systemStatusService: SystemStatusService,
				private siteManager: SiteManagerService,
				private translateService: TranslateService,
				private weatherSourceManager: WeatherSourceManagerService,
				private programManager: ProgramManagerService,
				private programGroupManager: ProgramGroupManagerService,
				private irrigationActivityService: IrrigationActivityService,
				private flowElementManager: FlowElementManagerService,
				private broadcastService: BroadcastService,
	) { }

	ngOnInit() {
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);

		if (!this.isGolfSite) {
			this.setupCommercialSubscriptions();
		} else {
			this.setupGolfSubscriptions();
			this.irrigationActivityService.programStatusChange.pipe(untilDestroyed(this))
				.subscribe( programStatusChange => {
					if (programStatusChange === RbEnums.SignalR.ProgramStatusChangeType.ProgramStarted
						|| programStatusChange === RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted
						|| programStatusChange === RbEnums.SignalR.ProgramStatusChangeType.ProgramCancelled
						// Note: we ignore ProgramRunningUpdate because it doesn't indicate a change of program state.
					) {
						this.getNextStartTime();
					}
				});
			this.programGroupManager.programGroupsChange.pipe(untilDestroyed(this))
				.subscribe( () => {
						this.getNextStartTime();
				});
			this.broadcastService.programsUpdated
			.pipe(untilDestroyed(this))
			.subscribe((changes: ProgramChange[]) => {
				for (let i = 0; i < changes.length; i++) {
					if (changes[i].changeType === RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted) {
						this.getNextStartTime();
						break;
					}
				}
			});
			this.getNextStartTime();
			this.updateSystemMode(this.systemStatusService.lastReportedFieldActivity);
		}
	}
	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getCompanyStatusCore(bypassCache = false) {
		this.companyManager.getCompanyStatusCore(bypassCache)
			.pipe(take(1))
			.subscribe((companyStatus: CompanyStatus) => {
				this.companyStatus = companyStatus;
				// Get user Sites
				this.getUserSite();
			});
	}
	private setupCommercialSubscriptions() {
		this.areAnyFlowElementsLocked();
		// Get Company Status Counts.
		this.getCompanyStatusCore(true);

		this.broadcastService.physicalDataStateChange
			.pipe(untilDestroyed(this))
			.subscribe((state: ControllerGetPhysicalDataState) => {
				if (!state.gettingPhysicalData) {
					this.getCompanyStatusCore(true);
				}
			});
		this.broadcastService.programsUpdated
			.pipe(untilDestroyed(this))
			.subscribe((changes: ProgramChange[]) => {
				this.getCompanyStatusCore(true);
			});

		this.broadcastService.controllerUpdated.subscribe((change: ControllerChange) => {
			if (change.itemsChanged && change.itemsChanged?.ids?.length === 1) {
				this.getCompanyStatusCore(true);
			}
		});

		this.broadcastService.hasLastSyncDifferenceChanged.subscribe((change: ControllerChange) => {
			if (change.itemsChanged && change.itemsChanged?.ids?.length === 1) {
				this.getCompanyStatusCore(true);
			}
		});

		// Get connected controllers count.
		this.controllerManager.getConnectedControllersCountForCurrentUser()
			.pipe(take(1))
			.subscribe((connectedControllers: number) => this.connectedControllersCount = connectedControllers);

		// Monitor Company Status changes to update status counts
		this.companyManager.companyStatusChange
			.pipe(untilDestroyed(this))
			.subscribe((companyStatus: CompanyStatus) => {
				this.companyStatus = companyStatus;
				// Get the status for visible sites
				this.getVisibleStatusChange();
			});

		// Monitor connected controllers count changes.
		this.companyManager.connectedControllersCountChange
			.pipe(untilDestroyed(this))
			.subscribe((connectedControllers: number) => this.connectedControllersCount = connectedControllers);

		this.siteManager.sitesUpdate
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				// Get user Sites
				this.getUserSite(true);
			});

		this.siteManager.siteDeleted
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				// Get user Sites
				this.getUserSite(true);
			});
	}

	private getUserSite(bypassCache = false) {
		// Get Sites
		this.siteManager.getSites(bypassCache).pipe(take(1)).pipe(take(1))
			.subscribe((sites: Site[]) => {
				this.sites = sites;
				// Get the status for the sites
				this.getVisibleStatusChange();
			});
	}
	getNextStartTime() {
		this.programManager.getNextStartTime()
		.pipe(take(1))
		.subscribe( nextStartTime => {
				this.timeString = nextStartTime;
			}
		);
	}

	areAnyFlowElementsLocked() {
		this.flowElementManager.areAnyCompanyFlowElementsLocked()
			.subscribe(locked => {
				this.systemStatusService.isSystemLocked = locked;
				this.isSystemLocked = this.systemStatusService.isSystemLocked;
			});
	}

	private setupGolfSubscriptions() {
		this.areAnyFlowElementsLocked();
		this.systemStatus  = this.systemStatusService.lastReportedSystemStatus;
		this.isSystemLocked = this.systemStatusService.isSystemLocked;

		this.companyManager.getCompanyPreferences()
			.pipe(take(1))
			.subscribe((companyPreference: CompanyPreferences) => {
				this.companyPreference = companyPreference;
			});

		this.getEtValue();

		this.weatherSourceManager.weatherSourcesChange
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.getEtValue(true);
			});

		this.systemStatusService.irrigationEngineChange
			.pipe(
				untilDestroyed(this)
			)
			.subscribe((irrigationState: RbEnums.Common.IrrigationEngineChangeType) => {
				this.updateSystemMode(irrigationState);
			});

		this.systemStatusService.irrigationEngineStatusChange
			.pipe(
				untilDestroyed(this)
			)
			.subscribe((irrigationStatus: RbEnums.Common.SystemStatus) => {
				this.systemStatus = irrigationStatus;
				this.updateSystemMode(this.systemStatusService.lastReportedFieldActivity);
			});

		this.companyManager.companyPreferencesChange
			.pipe(
				untilDestroyed(this),
				filter((companyPreference: CompanyPreferences) => companyPreference.id === this.companyPreference.id)
			)
			.subscribe((companyPreference: CompanyPreferences) => this.companyPreference = companyPreference);

		this.flowElementManager.flowElementListChange.pipe(untilDestroyed(this))
			.subscribe((changeList) => {
				// We assume that we may need to reload the whole list rather than checking each individual change
				// noted by the manager.
				this.areAnyFlowElementsLocked();
			});
	}

	private getEtValue(bypassCache = false) {
		this.weatherSourceManager.getEtValue(bypassCache)
			.pipe(take(1))
			.subscribe((etValue: number) => {
				this.etValue = etValue;
			});
	}

	private getVisibleStatusChange() {
		if (this.sites !== undefined && this.sites.length > 0 && this.companyStatus !== undefined) {
			this.differencesCount = this.outOfSyncCount = this.rainDelayCount = this.shutdownCount = 0;
			[this.companyStatus.siteCountMap].forEach(siteCounts => {
				Object.keys(siteCounts).forEach(prop => {
					if (this.sites.find(site => site.id === Number(prop))) {
						this.differencesCount = this.differencesCount + siteCounts[prop].differencesCount;
						this.outOfSyncCount = this.outOfSyncCount + siteCounts[prop].outOfSyncCount;
						this.rainDelayCount = this.rainDelayCount + siteCounts[prop].rainDelayCount;
						this.shutdownCount = this.shutdownCount + siteCounts[prop].shutdownCount;
					}
				});
			});
		}
	}

	private updateSystemMode(irrigationState: RbEnums.Common.IrrigationEngineChangeType) {
		this.isSystemLocked = this.systemStatusService.isSystemLocked;
		if (this.systemStatus === RbEnums.Common.SystemStatus.Failure) { // TODO May add more failures
			this.getSystemStatus(this.RbColorBackGroundConstants.red, this.RbColorConstants.white, 'priority_high');
			this.systemStatusMsg = this.translateService.instant('STRINGS.ERROR');

		} else {
			switch (irrigationState) {
				case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_Irrigating):
					this.getSystemStatusColor(RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_Irrigating);
					break;
				case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModeOff_Irrigating):
					this.getSystemStatusColor(RbEnums.Common.IrrigationEngineChangeType.SystemModeOff_Irrigating);
					break;
				case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_NotIrrigating):
				case Number(RbEnums.Common.IrrigationEngineChangeType.IrrigationResumed):
					this.getSystemStatusColor(RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_NotIrrigating);
					break;
				case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModeOff_NotIrrigating):
					this.getSystemStatusColor(RbEnums.Common.IrrigationEngineChangeType.SystemModeOff_NotIrrigating);
					break;
				case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModePaused_NotIrrigating):
					this.getSystemStatusColor(RbEnums.Common.IrrigationEngineChangeType.SystemModePaused_NotIrrigating);
					break;
				case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModePaused_Irrigating):
					this.getSystemStatusColor(RbEnums.Common.IrrigationEngineChangeType.SystemModePaused_Irrigating);
					break;
				default:
					return;
			}
		}
	}

	private getSystemStatus(background, color, icon) {
		this.background = background;
		this.color = color;
		this.icon = icon;
	}

	private getSystemStatusColor(activity) {
		switch (activity) {
			case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_Irrigating):
				this.getSystemStatus(this.RbColorBackGroundConstants.blue, this.RbColorConstants.white, 'power_settings_new');
				this.systemStatusMsg = this.translateService.instant('STRINGS.ON');
				break;
			case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModeOff_Irrigating):
				this.getSystemStatus(this.RbColorBackGroundConstants.lightBlue, this.RbColorConstants.orange, 'clear');
				this.systemStatusMsg = this.translateService.instant('STRINGS.OFF');
				break;
			case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_NotIrrigating):
				this.getSystemStatus(this.RbColorBackGroundConstants.darkGreen, this.RbColorConstants.white, 'power_settings_new');
				this.systemStatusMsg = this.translateService.instant('STRINGS.ON');
				break;
			case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModeOff_NotIrrigating):
				this.getSystemStatus(this.RbColorBackGroundConstants.orange, this.RbColorConstants.white, 'clear');
				this.systemStatusMsg = this.translateService.instant('STRINGS.OFF');
				break;
			case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModePaused_NotIrrigating):
				this.getSystemStatus(this.RbColorBackGroundConstants.yellow, this.RbColorConstants.white, 'pause');
				this.systemStatusMsg = this.translateService.instant('STRINGS.PAUSED');
				break;
			case Number(RbEnums.Common.IrrigationEngineChangeType.SystemModePaused_Irrigating):
				this.getSystemStatus(this.RbColorBackGroundConstants.lightBlue, this.RbColorConstants.yellow, 'pause');
				this.systemStatusMsg = this.translateService.instant('STRINGS.PAUSED');
				break;
			default:
				return;
		}
	}

}

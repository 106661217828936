import * as moment from 'moment';
import { IrrigationQueueItem } from './irrigation-queue-item.model';
import { IrrigationStatus } from './irrigation-status.model';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class ConnectDataPack {

	private _postedPrograms: number[] = [];

	constructor(json: any = null) {
		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here
			if (json.irrigationStatus) { this.irrigationStatus = new IrrigationStatus(json.irrigationStatus); }

			let irrigationState: string = null;
			if (this.irrigationStatus && this.irrigationStatus.irrigationState) { irrigationState = this.irrigationStatus.irrigationState; }

			if (json.irrigationQueue) { this.irrigationQueue = json.irrigationQueue.map(i => new IrrigationQueueItem(i, irrigationState)); }
		}

		this.timeStamp = new Date();
	}

	error?: string = null;
	isRetrieving?: boolean;
	isRetrievingManualOpsState?: boolean;		// Retrieval state for Manual Ops values such as: Rain Delay Days, Auto/Off, Enable Flo-Manager, etc.
	isSynchronizing?: boolean;
	timeStamp: Date;

	cicVersion?: string;
	connectionLifetime?: string;
	dtcSerialNumber?: number;
	hasFlowAlarms?: boolean;
	flowAlarmCount?: number;
	hasModuleDiscrepancies?: boolean;
	irrigationQueue?: IrrigationQueueItem[];
	irrigationStatus?: IrrigationStatus;
	logicalRotaryPosition?: string;
	logicalSensorPosition?: string;
	physicalRotaryPosition?: string;
	physicalSensorPosition?: string;
	rainDelayDays?: number;
	sensorState?: RbEnums.SignalR.SyncType;

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get isStale(): boolean {
		const minutesSinceUpdate = moment.duration(moment(new Date()).diff(moment(this.timeStamp))).asMinutes();
		return minutesSinceUpdate >= RbConstants.Common.MaxAgeOfSatelliteChangeInMinutes;
	}

	get postedPrograms(): number[] {
		return this._postedPrograms;
	}

	addPostedPrograms(programIds: number[]) {
		const newProgramIds = programIds.filter(id => !this._postedPrograms.includes(id));
		newProgramIds.forEach(id => this._postedPrograms.push(id));
	}

	stopIrrigation() {
		if (!this.irrigationQueue) { return; }
		this.irrigationQueue.forEach(item => item.stopIrrigation());
	}
}

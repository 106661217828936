import * as moment from 'moment';
import { Controller } from '../../controllers/models/controller.model';
import { RbUtils } from '../../../common/utils/_rb.utils';
export class EventDayOff {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.dayOffset) { this.dayOffset = RbUtils.Common.createDateAsAConstantToShow(moment(json.dayOffset)); }
			if (json.satellite) { this.satellite = new Controller(json.satellite); }
		}
	}

	id?: number;
	companyId: number;
	day: Date;
	dayOffset: Date;
	satelliteId: number;
	satellite?: Controller;

}

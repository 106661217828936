import { RbUtils } from '../../../common/utils/_rb.utils';

export class GroupStartTime {

	companyId: number;
	dateTime: Date;
	enabled = false;
	id: number;
	programGroupId: number;
	dateTimeOffset?: Date;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.dateTime) { this.dateTime = RbUtils.Conversion.convertStringToDate(json.dateTime); }
			if (json.dateTimeOffset) { this.dateTimeOffset = this.dateTime = RbUtils.Common.timeOffsetToDisplayTime(json.dateTimeOffset); }
		}
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>
<span *ngIf="programProjectedTime.projectedStart" > 
	{{programProjectedTime.projectedStartString}} 
</span>
<span *ngIf="!programProjectedTime.projectedStart" > 
	{{'STRINGS.OUT_OF_RANGE' | translate}}  
	<button mat-icon-button class="help" [matMenuTriggerFor]="helpContent" >
		<mat-icon class="help-icon" >info_outline</mat-icon>
	</button>
</span>
<mat-menu #helpContent="matMenu" [overlapTrigger]="false" xPosition="before" class="qaid-header-subnav-identity">
<ng-container>
	<div>
		<div class="ma3  info-line">{{ 'STRINGS.OUT_OF_RANGE_START' | translate }}</div>
	</div>
</ng-container>
</mat-menu>
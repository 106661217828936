import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { MasterValveListItem } from '../../../../../api/stations/models/master-valve-list-item.model';

@Component({
	selector: 'rb-master-valve-mobile-cell-renderer',
	templateUrl: './master-valve-mobile-cell-renderer.component.html',
	styleUrls: ['./master-valve-mobile-cell-renderer.component.scss']
})
export class MasterValveMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	masterValve: MasterValveListItem;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.masterValve = params.data;
	}
}

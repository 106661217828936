import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceManagerService } from './../../../common/services/device-manager.service';

@UntilDestroy()
@Component({
	selector: 'rb-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ChatComponent implements OnInit, OnDestroy {

	isDesktopInMobileStyle = false;

	constructor(
		private deviceManager: DeviceManagerService,
	) {}

	ngOnInit() {
		if (!this.deviceManager.isDesktop) {
			// mobile device
			// ensure that when the device width is big it does not causes the iframe#chat_window_container width to differ from 100% on the overlay.
			document.body.classList.add('rb-mobile-chat');
		} else {
			// desktop device
			// handle desktop and mobile style transitions
			// fires on any window resize
			this.deviceManager.isMobileChange
				.pipe(untilDestroyed(this))
				.subscribe(() => {
					if (this.deviceManager.isMobile) {
						if (!this.isDesktopInMobileStyle) {
							this.isDesktopInMobileStyle = true;
							document.body.classList.remove('rb-desktop-chat');
							// When the chat window is closed the width is set to 0px.
							const window = document.querySelector('#chat_window_container') as HTMLElement;
							if (window !== null && window.style.width !== '0px') {
								document.body.classList.add('rb-desktop-mobile-chat');
							}
						}
					} else {
						if (this.isDesktopInMobileStyle) {
							this.isDesktopInMobileStyle = false;
							document.body.classList.remove('rb-desktop-mobile-chat');
							document.body.classList.add('rb-desktop-chat');
						}
					}
				});
			if (this.deviceManager.isMobile) {
				// mobile style
				this.isDesktopInMobileStyle = true;
				// set CSS class on chat button click
			} else {
				// desktop style
				this.isDesktopInMobileStyle = false;
				document.body.classList.add('rb-desktop-chat');
			}
		}
		this.loadScript();
	}

	/** Implemented to support untilDestroyed() */
	ngOnDestroy(): void {}

	/**
	 * After the first chat button click this button is hidden and a new chat button and chat window is generated in div#comm100-container.
	 */
	openMobileChat() {
		document.body.classList.add('rb-desktop-mobile-chat');
		let buttonIframe = (document.querySelector('iframe[title="Chat icon for chat window"]') as HTMLElement);
		if (buttonIframe !== null) {
			const button = (buttonIframe['contentWindow'].document.querySelector('a.chatButton') as HTMLElement);
			if (button !== null) {
				button.click();
				return;
			}
		}
		buttonIframe = (document.querySelector('iframe[title="Chat button"]') as HTMLElement);
		(buttonIframe['contentWindow'].document.querySelector('a.chatButton') as HTMLElement).click();
	}

	private loadScript() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.text =
			`var Comm100API=Comm100API||{};
			(function(t){
				function e(e){
					var a=document.createElement("script"),
					c=document.getElementsByTagName("script")[0];
					a.type="text/javascript",
					a.async=!0,
					a.src=e+t.site_id,
					c.parentNode.insertBefore(a,c)
				}
				t.chat_buttons=t.chat_buttons||[],
				t.chat_buttons.push(
					{code_plan:"f0f890fb-4812-4127-8b39-6d6e047411c5",
					div_id:"comm100-button-f0f890fb-4812-4127-8b39-6d6e047411c5"}),
				t.site_id=220748,
				t.main_code_plan="f0f890fb-4812-4127-8b39-6d6e047411c5",
				e("https://vue.comm100.com/livechat.ashx?siteId="),
				setTimeout(function(){
					t.loaded||e("https://standby.comm100vue.com/livechat.ashx?siteId=")
				},5e3)
			})(Comm100API||{})`;
		document.body.appendChild(script);
	}
}

import { SensorStatusChange } from '../../../api/signalR/sensor-status-change.model';

export class SensorStatus {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	status = '-';
	sensorStatusItem: SensorStatusChange;
}

/*
 * NOTE: DO NOT USE THESE ENUMS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbEnums Namespace.
 * 		 E.g., RbEnums.Common.ControllersSyncState
 * 		 See: _rb.enums.ts
 */

export namespace XXUseRbEnumsNamespace.Map {

	/* PLEASE ALPHABETIZE WHEN ADDING ENUMERATIONS */

	export enum HoleContextMenu {
		Pause = 1,
		Resume = 2,
		Start = 3,
		Stop = 4,
		Edit = 5,
		Remove = 6,
		AddToMap = 7,
		FindOnMap = 8,
		SelectStations = 9
	}

	export enum AreaContextMenu {
		Pause = 101,
		Resume = 102,
		Start = 103,
		Stop = 104,
		Advance = 105,
		Edit = 106,
		EditShape = 107,
		ViewShape = 108,
		Remove = 109,
		CalculateArea = 110,
		AddToMap = 111,
		FindOnMap = 112,
		SelectStations = 113
	}

	export enum ControllerContextMenu {
		Edit = 201,
		Remove = 202,
		Sync = 203,
		ReverseSync = 204,
		Logs = 205,
		Start = 206,
		StopAll = 207,
		Connect = 208,
		Disconnect = 209,
		AddToMap = 210,
		FindOnMap = 211,
		Incomplete = 212
	}

	export enum SensorContextMenu {
		Edit = 501,
		Remove = 502,
		AddToMap = 503,
		FindOnMap = 504
	}

	export enum StationContextMenu {
		AddShape = 301,
		AddToMap = 302,
		Advance = 303,
		CalculateAreas = 304,
		Diagnostics = 305,
		Edit = 306,
		EditAreaColor = 307,
		FindOnMap = 308,
		Notes = 309,
		Pause = 310,
		ProgramsAndSchedules = 311,
		Remove = 312,
		Resume = 313,
		Start = 314,
		Stop = 315
	}

	export enum SiteContextMenu {
		Edit = 401,
	}

	export enum StationAreaContextMenu {
		AddToMap = 601,
		CalculateArea = 602,
		Edit = 603,
		EditShape = 604,
		Remove = 605,
		ViewShape = 606,
		EditAreaColor = 607
	}

	export enum MapLayer {
		Holes = 1,
		Areas = 2,
		Stations = 3,
		Irrigation = 4,
		Alerts = 5,
		Controllers = 6,
		TextColor = 7,
		Moveable = 8,
		StationAdjustments = 10,
		StationNames = 11,
		StationRuntimes = 12,
		NozzleColor = 13,
		StationCycleSoak = 14,
		Sensors = 15,
		SensorNames = 16,
		ControllerStatus = 17,
		ControllerRelationships = 18,
		StickyNotes = 19,
		AddStickyNote = 20,
		StationAreas = 21,
		MasterValves = 22,
		MasterValvesAreas = 23,
		ControllerName = 24,
		MasterValvesName = 25,
		Notes = 26,
		NoteAnimation = 27
	}

	export enum BackgroundLayer {
		Esri = 'esri',
		OSM = 'osm',
		GoogleRoadMap = 'google-rm',
		GoogleStreetMap = 'google-st',
		Offline = 'offline',
		None = 'none'
	}

	export enum ImportedFileStatus {
		Waiting = 'waiting',
		Current = 'current',
		Success = 'success',
		Error = 'error',
		Warning = 'warning'
	}

	export enum LeftSidebarTab {
		Stations = 1,
		Programs = 2,
		Notes = 3,
		Imported = 4,
		Custom = 5,
		Common = 6
	}
}

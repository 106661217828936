import { AppInjector } from '../../../../../core/core.module';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../../../api/culture-settings/models/culture-settings.model';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import Priority = RbEnums.Common.Priority;
import UnitsType = RbEnums.Common.UnitsType;
import SprinklerCategoryType = RbEnums.Common.SprinklerCategoryType;

export class EditableGridUtil {
	private static _authManager: AuthManagerService;
	private static get authManager() {
		if (!this._authManager) {
			this._authManager = AppInjector.get(AuthManagerService);
		}
		return this._authManager;
	}

	/** Method to determine whether or not a sprinkler type is a per hour type. */
	static isSprinklerPerHour(sprinklerCategory: SprinklerCategoryType): boolean {
		// RB-10776, RB-12684: There should not be any PH values in IQ4. All values are PM.
		if (!RbUtils.Common.isGolfSite(this.authManager?.getUserProfile()?.siteType)) {
			return false;
		}
		return sprinklerCategory === RbEnums.Common.SprinklerCategoryType.DripInLine
			|| sprinklerCategory === RbEnums.Common.SprinklerCategoryType.DripEmitter;
	}

	/** Method to get localized priority name for editable grid cell renderer. */
	static getPriorityName(priority: Priority): string {
		switch (priority) {
			case RbEnums.Common.Priority.NonIrrigation:
				return RbUtils.Translate.instant('STRINGS.NON_IRRIGATION');
			case RbEnums.Common.Priority.High:
				return RbUtils.Translate.instant('STRINGS.HIGH');
			case RbEnums.Common.Priority.Medium:
				return RbUtils.Translate.instant('STRINGS.MEDIUM');
			case RbEnums.Common.Priority.Low:
				return RbUtils.Translate.instant('STRINGS.LOW');
			case RbEnums.Common.Priority.Lower:
				return RbUtils.Translate.instant('STRINGS.LOWER');
			case RbEnums.Common.Priority.Lowest:
				return RbUtils.Translate.instant('STRINGS.LOWEST');
		}
	}

	/** Method to convert flowRate from GPM to arbitrary unitType. */
	static convertFlowTo(flowRateInGpm: number, toUnitsType: UnitsType, sprinklerCategory: SprinklerCategoryType, precision?: number): number {
		const isPerHourSprinkler = EditableGridUtil.isSprinklerPerHour(sprinklerCategory);

		// If Drip Line or Drip Emitter convert to LPM and factor up GPM to GPH;
		if (isPerHourSprinkler) {
			flowRateInGpm *= 60;
			if (toUnitsType !== UnitsType.English) { toUnitsType = UnitsType.Metric3; }
		}

		const convertedVal = RbUtils.Common.ToFlow(flowRateInGpm, UnitsType.English, toUnitsType);
		return precision != null ? +convertedVal.toFixed(precision) : convertedVal;
	}

	/** Method to convert flowRate from arbitrary unitType to GPM. */
	static convertFlowFrom(flowRate: number, fromUnitsType: UnitsType, sprinklerCategory: SprinklerCategoryType) {
		const isPerHourSprinkler = EditableGridUtil.isSprinklerPerHour(sprinklerCategory);

		// If Drip Line or Drip Emitter convert to LPM and factor up GPM to GPH;
		if (isPerHourSprinkler) {
			flowRate /= 60;
			if (fromUnitsType !== UnitsType.English) { fromUnitsType = UnitsType.Metric3; }
		}

		return RbUtils.Common.convertFlowToSaveData(flowRate, fromUnitsType);
	}

	/** Method to get proper flow rate unit of measure for flow rate editor and renderer on editable grid. */
	static getFlowRateUnits(units: UnitsType, sprinklerCategory: SprinklerCategoryType, isVerbose = false): string {
		let unitsString = '';
		const isPerHourSprinkler = EditableGridUtil.isSprinklerPerHour(sprinklerCategory);

		switch (units) {
			case UnitsType.English:
				unitsString =
					!isVerbose ? !isPerHourSprinkler ? 'UNIT_TYPE.GPM' : 'UNIT_TYPE.GPH' : !isPerHourSprinkler ? 'UNIT_TYPE.US' : 'UNIT_TYPE.GAL_PER_HR';
				break;
			case UnitsType.Metric1:
				unitsString =
					!isVerbose ? !isPerHourSprinkler ? 'UNIT_TYPE.LPS' : 'UNIT_TYPE.LPH' : !isPerHourSprinkler ? 'UNIT_TYPE.METRIC1' : 'UNIT_TYPE.LITER_PER_HR';
				break;
			case UnitsType.Metric2:
				unitsString = !isVerbose ? !isPerHourSprinkler ? 'UNIT_TYPE.METRIC2' : 'UNIT_TYPE.LPH' : !isPerHourSprinkler ?
				'UNIT_TYPE.METRIC2' : 'UNIT_TYPE.LITER_PER_HR';
				break;
			case UnitsType.Metric3:
				unitsString =
					!isVerbose ? !isPerHourSprinkler ? 'UNIT_TYPE.LPM' : 'UNIT_TYPE.LPH' : !isPerHourSprinkler ? 'UNIT_TYPE.METRIC3' : 'UNIT_TYPE.LITER_PER_HR';
				break;
			default:
				return '';
		}

		return RbUtils.Translate.instant(unitsString);
	}

	/** Method to format number using user format inclusive of thousands separator. */
	static formatNumberForDisplay(value: number, userCulture: CultureSettings, precision?: number): string {
		const valString = precision != null ? value.toFixed(precision) : value.toString();
		return userCulture.toUserNumber(valString, true);
	}

	/** Method to get general dialog configuration for editable grid dialog. */
	static getDialogConfig(widthInPixels: number, heightInPixels: number) {
		return {
			disableClose: true,
			panelClass: 'table-editor-dlg-wrapper',
			width: `${widthInPixels}px`,
			height: `${heightInPixels}px`,
			maxHeight: '95%',
			maxWidth: '95%'
		};
	}

	/** Method to get general dialog configuration for editable grid dialog with percents */
	static getDialogConfigInPercent(width: number, height: number) {
		return {
			disableClose: true,
			panelClass: 'table-editor-dlg-wrapper',
			width: `${width}%`,
			height: `${height}%`,
			maxHeight: '95%',
			maxWidth: '95%'
		}
	}

	/** RB-11216: Show the FloZone in the station table edit for LXIVM, LXIVM PRO and LXD Controllers. */
	static showFloZone(controllerType: RbEnums.Common.DeviceType): boolean {
		return controllerType === RbEnums.Common.DeviceType.LXD || controllerType === RbEnums.Common.DeviceType.LXIVM
			|| controllerType === RbEnums.Common.DeviceType.LXIVMPlus;
	}

	/** Method to get the flow zone name for editable grid cell renderer. */
	static getFlowZoneName(flowZoneId: number, flowElements: any[]) {
		const flowZone = flowElements.find(fz => fz.id === flowZoneId);
		return flowZone ? flowZone.name : '';
	}
}

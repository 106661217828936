<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="label">
	{{'STRINGS.DATA' | translate}}*
</div>

<mat-form-field>
	<mat-select
		[panelClass]="'multiselect-dropdown'"
		[ngModel]="selectedValues"
		[placeholder]="'STRINGS.SELECT_DATA' | translate"
		multiple="true"
		name="item"
		#item="ngModel"
		rbSelect
		(selectionChange)="handleSelection($event)"
	>
		<mat-select-trigger>{{currentSelectionText}}</mat-select-trigger>
		<mat-option *ngFor="let item of items" [value]="item.value">
			{{item.name}}
		</mat-option>
	</mat-select>
</mat-form-field>


import * as L from 'leaflet';
import { Styles, UserOptions } from 'jspdf-autotable';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { CompanyManagerService } from '../../api/companies/company-manager.service';
import { ControlOptions } from 'leaflet';
import { CultureSettings } from '../../api/culture-settings/models/culture-settings.model';
import domToImage from "dom-to-image-more";// This extra library has fixed some issue from dom-to-image to use all sources Map. This lib also needs dom-to-image lib in packages
import { EnvironmentService } from './environment.service';
import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import { MapInfoLeaflet } from '../models/map-info-leaflet.model';
import moment from 'moment';
import { RbConstants } from '../constants/_rb.constants';
import { ReportCsvInfo } from '../../sections/reports/common/csv/report-csv-info';
import { ReportPdfInfo } from '../../sections/reports/common/pdf/report-pdf-info';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class ExportService {
	onExportCsvEvent$ = new Subject();
	onExportPdfEvent$ = new Subject();
	onChildLoadingCompleted$ = new Subject();
	mapInfo: MapInfoLeaflet;

	constructor(private authManager: AuthManagerService,
				private companyManager: CompanyManagerService,
				private translateService: TranslateService) {
	}

	generateCsvFile(csvInfo: ReportCsvInfo,
		headerTableData: any,
		bodyTableData: any[]
	) {
			csvInfo.setHeadersIgnoringNulls(headerTableData.reportHeader);
			bodyTableData.forEach(item => {
				csvInfo.addLineIgnoringNulls(item)
			});
	}

	generatePdfFile(
		pdfInfo: ReportPdfInfo,
		headerTableData: any,
		bodyTableData: any[]
	) {
		const genericStyle = {
			font: pdfInfo.getFont
		}
		const headerTable = [
			[
				{
					content: headerTableData.reportPageLabel,
					styles: {
						fillColor: RbConstants.Form.REPORT_PAGE.primary,
						halign: 'center',
						textColor: RbConstants.Form.REPORT_PAGE.whiteColorHex,
						...genericStyle
					},
					colSpan: headerTableData.reportHeader.length,
				},
			],
			headerTableData.reportHeader.map((item) => {
				return {
					content: item,
					styles: {
						fillColor: RbConstants.Form.REPORT_PAGE.grayText,
						halign: 'center',
						...genericStyle
					},
				};
			}),
		];

		const headerString = headerTableData.reportNameLabel || '';
		pdfInfo.pdf.setFont(pdfInfo.getFont);
		pdfInfo.pdf.setFontSize(pdfInfo.dim.largeFontSize);
		pdfInfo.outputBottom += 12;
		pdfInfo.pdf.setFontSize(pdfInfo.dim.smallFontSize);

		pdfInfo.outputTableData(
			[
				[
					{
						content: headerString,
						colSpan: 2,
						styles: {
							halign: 'left',
							fontSize: 20,
						},
					},
				],
				[
					{
						content: headerTableData.reportGeneratedOnLabel,
						styles: { halign: 'left' },
					},
					{
						content: headerTableData.reportTypeLabel,
						styles: { halign: 'right' },
					},
				],
			],
			null,
			{
				headStyles: {
					fillColor: RbConstants.Form.REPORT_PAGE.primary,
				},
				tableWidth: pdfInfo.bodyWidth,
			}
		);

		pdfInfo.outputBottom += 5;

		const tableOptions: UserOptions = {
			styles: pdfInfo.tables.defaultStyles,
			headStyles: pdfInfo.tables.defaultHeadStyles,
			bodyStyles: <Styles>{
				fillColor:
					RbConstants.Form.REPORT_PAGE.alternatingTableHeaderColorHex,
				halign: 'center',
				...genericStyle
			},
			alternateRowStyles: { fillColor: RbConstants.Form.REPORT_PAGE.whiteColorHex },
		};

		if(bodyTableData != null) {
			pdfInfo.outputTableData(headerTable, bodyTableData, tableOptions);
			if (bodyTableData.length === 0) {
				pdfInfo.outputBottom += 5;
				pdfInfo.pdf.text(this.translateService.instant('STRINGS.NO_ACTIVITY_TO_DISPLAY'), pdfInfo.center, pdfInfo.outputBottom, { align: 'center' });
			}
		}
	}

	public printMaps(pdfInfo: ReportPdfInfo, env: EnvironmentService) {
		const user = this.authManager.getUserProfile().name;
		const regExpParentheses = /\(([^)]+)\)/;
		const localizedTimeZone = regExpParentheses.exec(this.mapInfo.site.timeZoneLocalizedName ? this.mapInfo.site.timeZoneLocalizedName : '');
		let timeZone = null;
		if (localizedTimeZone && localizedTimeZone.length === 2) {
			timeZone = moment(new Date()).zone(localizedTimeZone[1].replace('UTC',''));
		}
		const cultureSettings = this.authManager?.userCulture || new CultureSettings();
		const generatedOn = cultureSettings.toUserDateTimeString(timeZone ? timeZone : new Date());

		const description = this.mapInfo.site.description;
		var options: MapOptions = {
			siteName: this.mapInfo.site.name,
			mapReport: this.translateService.instant('STRINGS.MAP_REPORT'),
			reportTitle: '',
			companyName: '',
			user: user,
			baseUri: env.rbcc_ui,
			labelGeneratedOn: this.translateService.instant('STRINGS.EXPORT_FILE_GENERATED_ON', {generatedOn: ':'}),
			generatedOn: generatedOn,
			labelSiteDescription: this.translateService.instant('STRINGS.SITE_DESCRIPTION') + ' :',
			siteDescription: description ? description.replace(/\n/g, ' ') : '',
			labelTimeZone: this.translateService.instant('STRINGS.TIME_ZONE') + ' :',
			timeZone: this.mapInfo.site.timeZone
		}
		
		this.companyManager.getCompanyName().subscribe((companyName) => {
			options.companyName = companyName;
			try {
				// Download map
				var printMap = new PrintMaps(options).addTo(this.mapInfo.map);
				const fileName = this.translateService.instant('STRINGS.MAP_DOWNLOAD_FILE_NAME', {site: this.mapInfo.site.name, type: 'pdf'});
				printMap.downloadMap(pdfInfo, fileName, this);
			} catch (error) {
				if (this.onExportPdfEvent$) {
					this.onExportPdfEvent$.next(true);
				}
				console.log('Exception: ', error);
			}
		});
	}
}

declare module 'leaflet' {
	export class PrintMaps extends Control {
		constructor(options?: MapOptions);
	}

	namespace Control {
		function downloadMap(pdfInfo?: ReportPdfInfo, fileName?: string, exportService?: ExportService): Blob;
	}
}

interface MapOptions extends ControlOptions {
	siteName?: string,
	mapReport?: string,
	reportTitle?: string,
	companyName?: string,
	user?: string,
	baseUri?: string,
	labelGeneratedOn?: string,
	generatedOn?: string,
	labelSiteDescription?: string,
	siteDescription?: string,
	labelTimeZone?: string,
	timeZone?: string,
}

export const PrintMaps = L.Control.extend({
	options: {
		siteName: '',
		mapReport: '',
		reportTitle: '',
		companyName: '',
		user: '',
		baseUri: '',
		labelGeneratedOn: '',
		generatedOn: '',
		labelSiteDescription: '',
		siteDescription: '',
		labelTimeZone: '',
		timeZone: '',
	},

	onAdd: function (map) {
		this._map = map;
		return this._createControl(this);
	},

	_createControl: function (context) {
		this._container = document.createElement('div');
		this._container.id = 'print-container';
		this._container.classList.add('leaflet-print-map');

		L.DomEvent.disableScrollPropagation(this._container);
		L.DomEvent.disableClickPropagation(this._container);

		return this._container;
	},

	_generatePdf: function(pdfInfo?: ReportPdfInfo, imageData?: string) {
		let self = this;
		/// Cover page
		pdfInfo.pdf.setFont(pdfInfo.getFont, 'normal');
		pdfInfo.pdf.setFontSize(pdfInfo.dim.smallFontSize);
		pdfInfo.outputBottom = 40;
		pdfInfo.pdf.text(self.options.user, pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom);
		pdfInfo.outputRightAlignedText(pdfInfo.pageWidth - pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom, self.options.generatedOn);
		pdfInfo.outputBottom += pdfInfo.lineHeight;

		// Logo
		const logoWidth = 392;
		const logoHeight = 72;
		const sectionBounds = { left: pdfInfo.center - logoWidth / 2, top: pdfInfo.outputBottom, right: 0, bottom: 0 };
		const img = new Image();
		img.src = self.options.baseUri + '/assets/rblogo.png';
		pdfInfo.pdf.addImage(img, 'PNG', sectionBounds.left, sectionBounds.top, logoWidth, logoHeight);

		// Company name
		pdfInfo.pdf.setFont(pdfInfo.getFont, 'bold');
		pdfInfo.pdf.setFontSize(pdfInfo.dim.largeFontSize);
		pdfInfo.outputBottom += logoHeight + 40;
		pdfInfo.pdf.text(self.options.companyName, pdfInfo.center, pdfInfo.outputBottom, { align: 'center', baseline: 'top' });

		// Title
		pdfInfo.pdf.setFontSize(pdfInfo.dim.veryLargeFontSize);
		let blockHeight = pdfInfo.outputTextBlock(pdfInfo.dim.horizontalMargin, pdfInfo.pageHeight / 2, pdfInfo.bodyWidth, 
			self.options.mapReport, RbConstants.Form.REPORT_PAGE.primary, '#FFFFFF');

		// List of sections
		pdfInfo.outputBottom = pdfInfo.pageHeight / 2 + blockHeight + pdfInfo.lineHeight;
		pdfInfo.pdf.setFont(pdfInfo.getFont, 'normal');
		pdfInfo.pdf.setFontSize(pdfInfo.dim.regularFontSize);
		blockHeight = pdfInfo.outputTextBlock(pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom, pdfInfo.bodyWidth, self.options.siteName, 
			'#93c08d', '#000000');
		pdfInfo.outputBottom += blockHeight + pdfInfo.lineHeight * 3;

		/// Map page
		pdfInfo.createNewPage();

		// Site name generated date header
		const headerString = self.options.siteName || '';
		pdfInfo.pdf.setFont(pdfInfo.getFont);
		pdfInfo.pdf.setFontSize(pdfInfo.dim.largeFontSize);
		pdfInfo.outputBottom += 12;
		pdfInfo.pdf.setFontSize(pdfInfo.dim.smallFontSize);

		pdfInfo.outputTableData(
			[
				[
					{
						content: headerString,
						colSpan: 2,
						styles: {
							halign: 'left',
							fontSize: 20,
						},
					},
				],
				
			],
			null,
			{
				headStyles: {
					fillColor: RbConstants.Form.REPORT_PAGE.primary,
				},
			}
		);
		pdfInfo.outputBottom += 12;
		pdfInfo.pdf.setFont(pdfInfo.getFont, 'normal');
		pdfInfo.pdf.setFontSize(pdfInfo.dim.regularFontSize);
		
		pdfInfo.pdf.text(self.options.labelGeneratedOn, 40, pdfInfo.outputBottom, { align: 'left' });
		pdfInfo.pdf.text(self.options.generatedOn, 160, pdfInfo.outputBottom, { align: 'left' });
		const textWidth = pdfInfo.textWidth(self.options.siteDescription);
		if (self.options.siteDescription.length > 0) {
			pdfInfo.outputBottom += 14;
			pdfInfo.pdf.text(self.options.labelSiteDescription, 40, pdfInfo.outputBottom, { align: 'left' });
			pdfInfo.outputWrappedText(160, pdfInfo.outputBottom, self.canvas.width - 160, self.options.siteDescription);
		}
		if (textWidth > 0 && textWidth > self.canvas.width - 160) {
			pdfInfo.outputBottom += 28;
		} else {
			pdfInfo.outputBottom += 14;
		}
		
		pdfInfo.pdf.text(self.options.labelTimeZone, 40, pdfInfo.outputBottom, { align: 'left' });
		pdfInfo.pdf.text(self.options.timeZone, 160, pdfInfo.outputBottom, { align: 'left' });

		// Map content
		pdfInfo.pdf.addImage(imageData, 'PNG', 40, self.options.siteDescription.length > 0 ? textWidth > self.canvas.width - 160 ? 145 : 135 
			: 125, self.canvas.width, self.canvas.height);

		return pdfInfo;
	},

	downloadMap: function (pdfInfo?: ReportPdfInfo, fileName?: string, exportService?: ExportService) {
		let self = this;

		var reader = new FileReader();
		self.canvas = document.createElement('canvas');

		const mapElementHTML = document.querySelector(".leaflet-map");
		const mapCanvas = mapElementHTML as HTMLElement;
		self.canvas.width = mapCanvas.clientWidth > 0 ? mapCanvas.clientWidth : mapCanvas.scrollWidth;
		self.canvas.height = mapCanvas.clientHeight > 0 ? mapCanvas.clientHeight: mapCanvas.scrollHeight;
		self.ctx = self.canvas.getContext('2d');
		domToImage.toBlob(mapCanvas, {scale: 1, width: self.canvas.width, height: self.canvas.height}).then((blob) => {
			reader.readAsDataURL(blob);
			reader.onload = function () {
				var imageData = reader.result?.toString();
				if (imageData) {
					pdfInfo.pdf = new jsPDF({
						orientation: self.canvas.width > self.canvas.height ? 'l' : 'p',
						unit: 'pt',
						format: [self.canvas.width + 80, self.canvas.height + 160]
					});
					pdfInfo = self._generatePdf(pdfInfo, imageData);

					pdfInfo.pdf.save(fileName, { returnPromise: true }).then(() => {
						if (exportService.onExportPdfEvent$) {
							exportService.onExportPdfEvent$.next(true);
						}
					});
				}
			};
		});
	}
});

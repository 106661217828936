import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { StartTime } from '../../../../api/programs/models/start-time.model';

export class AutoContactModeModel {

	constructor(mode: RbEnums.Common.AutoContactMode, scheduledTimes: any[], syncIfChangesExists = false) {
		this.mode = mode;

		if (!(scheduledTimes instanceof Array)) { return; }

		this.scheduledTimes = scheduledTimes.map(t => new StartTime(t));
		this.syncIfChangesExist = syncIfChangesExists;
	}

	mode: RbEnums.Common.AutoContactMode;
	scheduledTimes: StartTime[] = [];
	syncIfChangesExist = false;
}

/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Programs.isProgramRunningStatus
 * 		 See: _rb.utils.ts
 */

import { RbEnums } from '../enumerations/_rb.enums';

export namespace XXUseRbUtilsNamespace {

	export abstract class Programs {

		/**
		 * Return indicator of whether program or program group with indicated SignalR changeType should be considered
		 * running or not.
		 */
		static isProgramRunningStatus(changeType: string): boolean {
			switch (changeType) {
				// RB-7496: Per Pat, Posted and Waiting are NOT active. For golf, programs that are running are also
				// limited to those with running schedules and running schedules are only those which have running
				// stations.
				case RbEnums.SignalR.ProgramStatusChangeType.Posted:
				case RbEnums.SignalR.ProgramStatusChangeType.Waiting:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramCancelled:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramPaused:
					return false;

				default:
					// Anything else returns true, indicating the program is active (or potentially active for changes like Updated, etc.)
					return true;
			}
		}

		static isProgramInactiveStatus(changeType: string): boolean {
			switch (changeType) {
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramPaused:
					return true;

				default:
					return false;
			}
		}

		/**
		 * Return an indicator of whether the specified ProgramChange.ChangeType value indicates a program running/not-running
		 * state. This centralizes separating things like Added, Deleted, Updated from things like Running, Paused, Waiting,
		 * Posted, etc.
		 * @param changeType - string change type from a ProgramChange.
		 * @returns true if the program state (running, paused, stopped, waiting, etc.) is defined by the message; false if
		 * the message is not status-related.
		 */
		static isProgramRealTimeStatusMessage(changeType: string): boolean {
			switch (changeType) {
				case RbEnums.SignalR.ProgramStatusChangeType.Added:
				case RbEnums.SignalR.ProgramStatusChangeType.Deleted:
				case RbEnums.SignalR.ProgramStatusChangeType.StartTimeChanged:
				case RbEnums.SignalR.ProgramStatusChangeType.Updated:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramGroupBatchUpdated:
					return false;

				case RbEnums.SignalR.ProgramStatusChangeType.Posted:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramCancelled:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramPaused:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramResumed:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramRunningUpdate:
				case RbEnums.SignalR.ProgramStatusChangeType.ProgramStarted:
				case RbEnums.SignalR.ProgramStatusChangeType.Waiting:
					return true;

				default:
					// Anything else returns false, for now. It's probably an error, however, so drop a messsage in the
					// console to indicate something is amiss.
					console.log('UNEXPECTED PROGRAM/GROUP STATUS MESSAGE: %o. See isProgramRealTimeStatus', changeType);
					return false;
			}
		}
	}
}

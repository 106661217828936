import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { Component } from '@angular/core';
import { NumericCellEditorBase } from '../_numeric-cell-editor-base';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-numeric-cell-editor',
	templateUrl: './numeric-cell-editor.component.html',
	styleUrls: ['./numeric-cell-editor.component.scss']
})
export class NumericCellEditorComponent extends NumericCellEditorBase {

	value: string;
	valueRange: { min: number, max: number };

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected authManager: AuthManagerService,
				protected broadcastService: BroadcastService,
				protected translateService: TranslateService,
	) {
		super(authManager, broadcastService, translateService);
	}

	// =========================================================================================================================================================
	// agGrid Methods
	// =========================================================================================================================================================

	// Get the value from the grid.
	agInit(params: any): void {
		super.agInit(params);

		this.value = this.numberRegEx.test(this.params.charPress) ? this.params.charPress : params.value;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onKeyUp(event) {
		// if (!this.valueRange) { return; }
		//
		// // Validate address range for given controller type.
		// if (!this.validateFlowRateRange()) {
		// 	const message = this.translateService.instant('VALIDATION.RANGE_INVALID', {
		// 			name: this.colDef.headerName,
		// 			min: this.valueRange.min || 0,
		// 			max: this.valueRange.max || Number.MAX_SAFE_INTEGER
		// 		}
		// 	);
		// 	this.showInvalidFieldDialog(message);
		// 	this.textInput.nativeElement.select();
		// }
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	// private validateFlowRateRange(): boolean {
	// 	// const newValue = this.getAddressInt();
	// 	const newValue = this.textInput.nativeElement.value;
	//
	// 	if (newValue < this.valueRange.min) {
	// 		this.textInput.nativeElement.value = this.valueRange.min;
	// 		return false;
	// 	}
	//
	// 	if (newValue > this.valueRange.max) {
	// 		this.textInput.nativeElement.value = this.valueRange.max;
	// 		return false;
	// 	}
	//
	// 	return true;
	// }

}

import { GeoItemPoint } from './geo-item-point.model';

export class GeoItem {

	companyId: number;
	id: number;
	point: GeoItemPoint;
	multiPoint: GeoItemPoint[];
	geoGroupId: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.point) this.point = new GeoItemPoint(json.point);
			if (json.multiPoint) this.multiPoint = json.multiPoint.map(mp => new GeoItemPoint(mp));
		}
	}
}

import { MasterValveStatusMvInfo } from './master-valve-status-mv-info.model';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class UniversalMasterValveStatus {

	constructor(json: any = null, controllerId: number, changeDateTime: string) {
		this.changeDateTime = RbUtils.Conversion.convertStringToDate(changeDateTime);
		this.controllerId = controllerId;
		if (json.universalMasterValveData) { this.universalMasterValveData = json.universalMasterValveData.map(mv => new MasterValveStatusMvInfo(mv)); }
	}

	controllerId: number;
	changeDateTime: Date;
	universalMasterValveData: MasterValveStatusMvInfo;
}

export namespace XXUseRbUtilsNamespace {
	export abstract class Browser {
		/**
		 * Utility to open a given URL in a new tab. Note that the browser may not allow this or may only allow
		 * it in a click() handler.
		 * @param url - string containing the absolute URL to open.
		 */
		static openNewTab(url: string) {
			console.log('Opening tab at %o', url);
			const w = window.open(url, '_blank');
			console.log('    window=%o', w);
		}
	}
}

import { ChangeBase } from './change-base.model';

export class FlowElementChange extends ChangeBase {

	constructor(json: any = null) {

		super(json);

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here
		}
	}

	flowElementId: number;
	reasonCode: string;
	changeType: string;
	itemsChanged: any;

	/**
	 * This property is set to a dictionary cross reference mapping flowElementId to various real-time data
	 * about the flow element (theoretical flow, primarily). Items listed here are those flow elements with
	 * non-zero theoretical flow, so users should treat all other flow elements as having zero flow.
	 * Changes of this type are sent periodically. They may also be sent on-change.
	 */
	flowRateUpdates: { [id: number]: FlowRateUpdateData };
}

export class FlowRateUpdateData {
	flowRateGPM: number;
}

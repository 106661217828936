import { BroadcastService } from './broadcast.service';
import { GlobalCautionMessageBox } from '../../shared-ui/components/custom-modal/models/alert-modal-options.model';
import { Injectable } from '@angular/core';
import { MessageBoxInfo } from '../../core/components/global-message-box/message-box-info.model';

@Injectable({
	providedIn: 'root'
})
export class MessageBoxService {

	constructor(private broadcastService: BroadcastService) { }

	showMessageBox(info: string | MessageBoxInfo) {
		this.broadcastService.showMessageBox.next(info instanceof MessageBoxInfo ? info : new MessageBoxInfo(info));
	}

	showGlobalCautionMessageBox(info: GlobalCautionMessageBox) {
		this.broadcastService.showGlobalCautionMessageBox.next(info);
	}
}

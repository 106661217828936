import { ApiService, HttpMethod } from '../_common/api.service';

import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Site } from './models/site.model';
import { SiteTreeView } from './models/site-tree-view.model';
import { Snapshot } from '../controllers/models/snapshot';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';
import { UserSite } from './models/user-site.model';

@Injectable({
	providedIn: 'root'
})
export class SiteApiService extends ApiService {
	clearCache() {
		super.clearCache(this.getSitesUrl);
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	createSite(siteData: any): Observable<null> {
		return this.apiRequest<any>(this.createSiteUrl, HttpMethod.Post, siteData);
	}

	deleteSites(siteIds: number[]): Observable<null> {
		return this.apiRequest(this.deleteSitesUrl(), HttpMethod.Delete, siteIds);
	}

	getNameUniqueness(name: string, id: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNameUniquenessUrl(name, id), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getNextDefaultName(siteType: number): Observable<string> {
		return this.apiRequestWithCache<any>(this.getNextDefaultNameUrl(siteType), true)
			.pipe(map(response => response.value.stringValue));
	}

	getSite(id: number): Observable<Site> {
		return this.apiRequest<any>(this.getSiteUrl(id), HttpMethod.Get)
			.pipe(map(response => new Site(response)));
	}

	getSites(bypassCache = false): Observable<ApiCachedRequestResponse<Site[]>> {
		return this.apiRequestWithCache<any>(this.getSitesUrl, bypassCache, result => result.map(c => new Site(c)));
	}

	getTreeViewSites(checkContractorSBUController: boolean): Observable<SiteTreeView[]> {
		return this.apiRequest<any>(this.getTreeViewSitesUrl, HttpMethod.Get)
			.pipe(map((response: SiteTreeView[]) => response.map(obj => new SiteTreeView(obj, {checkContractorSBUController: checkContractorSBUController}))));
	}

	getTreeViewSitesWithDeletedSatellites(): Observable<SiteTreeView[]> {
		return this.apiRequest<any>(this.getTreeViewSitesWithDeletedSatellitesUrl, HttpMethod.Get)
			.pipe(map((response: SiteTreeView[]) => response.map(obj => new SiteTreeView(obj))));
	}

	getTreeViewSiteWithDeletedSatellites(siteId: number): Observable<SiteTreeView> {
		return this.apiRequest<any>(this.getTreeViewSiteWithDeletedSatellitesUrl(siteId), HttpMethod.Get)
			.pipe(map((response: SiteTreeView) => new SiteTreeView(response)));
	}

	getUserSites(userId: number): Observable<UserSite[]> {
		return this.apiRequest<any>(this.getUserSitesUrl(userId), HttpMethod.Get)
			.pipe(map(sites => sites.map(s => new UserSite(s))));
	}

	getVisibleSites(): Observable<Site[]> {
		return this.apiRequest<any>(this.getVisibleSitesUrl, HttpMethod.Get)
			.pipe(map(sites => sites.map(s => new Site(s))));
	}

	updateSite(siteId: number, updateData: any) {
		return this.apiRequest<any>(this.updatePatchUrl(siteId), HttpMethod.Patch, this.patchTransform(updateData));
	}

	updateSites(siteIds: number[], siteUpdate: object): Observable<null> {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch,
			{ ids: siteIds, patch: this.patchTransform(siteUpdate) });
	}

	updateUserSites(userSites: UserSite[]) {
		return this.apiRequest<any>(this.updateUserSitesUrl, HttpMethod.Put,
			userSites.map(userSite => ({ siteId: userSite.siteId, userId: userSite.userId })));
	}

	createSnapshot(siteId: number): Observable<null> {
		return this.apiRequest<any>(this.createSnapshotUrl(siteId), HttpMethod.Get);
	}

	createSnapshots(siteIds: number[]): Observable<any> {
		return this.apiRequest<any>(this.createSnapshotsUrl, HttpMethod.Post, siteIds);
	}

	getSnapshots(siteId: number): Observable<Snapshot[]> {
		return this.apiRequest<any>(this.getSnapshotsUrl(siteId), HttpMethod.Get)
			.pipe(map(items => items.map(item => new Snapshot(item))));
	}

	restoreSnapshot(siteId: number, date: Date, forceRestore: boolean): Observable<null> {
		return this.apiRequest<any>(this.restoreSnapshotUrl, HttpMethod.Put, { siteId, date, forceRestore });
	}


	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}Site`; }

	private get baseUserSiteUrl(): string { return `${this.baseApiUrl}UserSite`; }

	private get createSiteUrl(): string { return `${this.baseUrl}/CreateSite`; }

	private deleteSitesUrl(): string {
		return `${this.baseUrl}/DeleteSites_V2`;
	}

	private getNameUniquenessUrl(name: string, id: number): string {
		return `${this.baseUrl}/IsNameUnique?siteName=${encodeURIComponent(name)}&siteId=${id}`;
	}

	private getNextDefaultNameUrl(siteType: number): string {
		return `${this.baseUrl}/GetNextDefaultName?siteType=${siteType}`;
	}

	private getSiteUrl(id: number): string { return `${this.baseUrl}/GetSite?siteId=${id}`; }

	private get getSitesUrl(): string { return `${this.baseUrl}/GetSites`; }

	private get getTreeViewSitesUrl(): string { return `${this.baseUrl}/GetTreeViewSites`; }
	private get getTreeViewSitesWithDeletedSatellitesUrl(): string { return `${this.baseUrl}/GetTreeViewSitesWithDeletedSatellites`; }
	private getTreeViewSiteWithDeletedSatellitesUrl(siteId: number): string { return `${this.baseUrl}/GetTreeViewSiteWithDeletedSatellites?siteId=${siteId}`; }

	private getUserSitesUrl(userId: number): string { return `${this.baseUserSiteUrl}/GetUserSite?userId=${userId}`; }

	private get getVisibleSitesUrl(): string { return `${this.baseUrl}/GetVisibleUSites`; }

	private updatePatchUrl(id: number): string { return `${this.baseUrl}/UpdatePatch?siteId=${id}`; }

	private get updateUserSitesUrl(): string { return `${this.baseUserSiteUrl}/UpdateUserSites`; }

	private get updateBatchesUrl(): string { return `${this.baseUrl}/UpdateBatches`; }

	private createSnapshotUrl(siteId: number): string { return `${this.baseUrl}/CreateSnapshot?siteId=${siteId}`; }
	
	private get createSnapshotsUrl(): string { return `${this.baseUrl}/CreateSnapshots`; }

	private getSnapshotsUrl(siteId: number): string { return `${this.baseUrl}/GetSnapshots?siteId=${siteId}`; }
	private get restoreSnapshotUrl(): string { return `${this.baseUrl}/RestoreSnapshot`; }
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-alert-mobile-cell-container">

	<div class="flex info-container">
		<div class ="f7-m pr3 date-time">{{appDateAdapter.formatDateTime(alert?.timestamp)}}</div>
		<div class ="f7-m info">
			<div class="description">{{alert?.description}}</div>
			<div style="margin-top: 2px;"><span class="label">{{'STRINGS.TYPE' | translate}}:</span><span class="value">{{alert?.typeString}}</span></div>
			<div><span class="label">{{(isGolfSite ? 'STRINGS.COURSE' : 'STRINGS.SITE') | translate}}:</span><span class="value">{{alert?.siteName}}</span></div>
			<div><span class="label">{{'STRINGS.SOURCE' | translate}}:</span><span class="value">{{alert?.satelliteName}}</span></div>
		</div>
	</div>
</div>

import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DropDownOption } from './models/dropdown-option.model';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-dashboard-widget-toolbar',
	templateUrl: './dashboard-widget-toolbar.component.html',
	styleUrls: ['./dashboard-widget-toolbar.component.scss']
})
export class DashboardWidgetToolbarComponent implements OnInit {

	@Input() title: string;
	@Input() subTitle: string;
	@Input() summaryValue: string;
	@Input() titleIcon: string | TemplateRef<any>;
	@Input() toolbar: TemplateRef<any>;
	@Input() widgetId: number;
	@Input() withRefresh: boolean;
	@Input() withSettings: boolean;
	@Input() showSettings: boolean;
	@Input() hideRemoveWidget = false;
	@Input() customDropDownOptions: DropDownOption[];
	@Input() wrapTitleLanguages: string[];
	// RB-14242: If sent from a widget component, title will be wrapped in languages included. Example: ['en', 'pt', 'sp']
	@Output() widgetSettings = new EventEmitter();
	@Output() widgetRefresh = new EventEmitter();

	wrapTitle: boolean = false;

	get isTitleIconString(): boolean {
		return this.titleIcon instanceof String;
	}

	get isTitleIconTemplate(): boolean {
		return this.titleIcon instanceof TemplateRef;
	}

	constructor(private broadcastService: BroadcastService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private translate: TranslateService) {
		// Create the mat-icon value used in our HTML. Since the URL reference has to be a SafeResourceUrl, we must pass the SVG
		// file location through the DomSanitizer to reference it. Note that we should NOT pass user-entered data here but we are
		// fine directly referencing the URL path since it's part of the app. If you don't agree that the path is safe, pass the
		// URL to .sanitize() instead to clean it of any embedded JavaScript, etc.
		this.matIconRegistry.addSvgIcon(
			'refresh_small',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/ccweather_icons/refresh_small.svg')
		);
	}

	ngOnInit() {
		if (this.wrapTitleLanguages && this.wrapTitleLanguages.includes(this.translate.currentLang)) {
			this.wrapTitle = true;
		}
	}

	onWidgetRefresh() {
		this.widgetRefresh.emit();
	}

	onRemoveWidget() {
		this.broadcastService.removeWidget.next(this.widgetId);
	}

	onWidgetSettings() {
		this.widgetSettings.emit();
	}
}

import { ApiService, HttpMethod } from '../_common/api.service';
import { DIDiagnosticSummaryData } from './models/di-diagnostic-summary-data';
import { GetICIDiagnosticLogSummaryDataResponse } from './models/get-ici-diagnostic-log-summary-data-response';
import { Injectable } from '@angular/core';
import { IStationDiagnosticHistory } from './models/station-diagnostic';
import { LINKChannelDiagnosticData } from '../manual-ops/models/ic-link-channel-diagnostic-data.model';
import { LINKHourlyDiagnosticData } from './../manual-ops/models/ic-link-hourly-diagnostic-data.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RbEnums } from '../../common/enumerations/_rb.enums';

@Injectable({
	providedIn: 'root'
})
export class DiagnosticLogApiService extends ApiService {
	// =========================================================================================================================================================
	// API Calls - Public
	// =========================================================================================================================================================

	getICIDiagnosticLogSummaryData(): Observable<GetICIDiagnosticLogSummaryDataResponse> {
		return this.apiRequest<any>(this.getICIDiagnosticLogSummaryDataUrl, HttpMethod.Get)
			.pipe(map(response => new GetICIDiagnosticLogSummaryDataResponse(response)));
	}

	getDIDiagnosticLogSummaryData(): Observable<DIDiagnosticSummaryData[]> {
		return this.apiRequest<any>(this.getDIDiagnosticLogSummaryDataUrl, HttpMethod.Get)
			.pipe(map(response => response.map(r => new DIDiagnosticSummaryData(r))));
	}

	getStationDiagnosticLogHistory(stationId: number, diagnosticRunType: RbEnums.Common.StationDiagnosticRunType) {
		return this.apiRequest<IStationDiagnosticHistory[]>(this.getStationDiagnosticLogHistoryUrl(stationId, diagnosticRunType), HttpMethod.Get);
	}

	getMimLinkHourlyDiagnosticLog(satelliteId: number, hour?: number): Observable<LINKHourlyDiagnosticData> {
		return this.apiRequest<any>(this.getMimLinkHourlyDiagnosticLogUrl(satelliteId, hour), HttpMethod.Get)
			.pipe(map(response => new LINKHourlyDiagnosticData(response)));
	}

	getMimLinkChannelDiagnosticLog(satelliteId: number, groupNumber: number, channel: number): Observable<LINKChannelDiagnosticData> {
		return this.apiRequest<any>(this.getMimLinkChannelDiagnosticLogUrl(satelliteId, groupNumber, channel), HttpMethod.Get)
			.pipe(map(response => new LINKChannelDiagnosticData(response)));
	}

	resetMIM_LINKHourlyDiagnosticLog(satelliteId: number): Observable<null> {
		return this.apiRequest<any>(this.resetMIM_LINKHourlyDiagnosticLogUrl(satelliteId), HttpMethod.Post)
	}

	resetMIM_LINKChannelDiagnosticLog(satelliteId: number, groupNumber?: number, channel?: number): Observable<null> {
		return this.apiRequest<any>(this.resetMIM_LINKChannelDiagnosticLogUrl(satelliteId, groupNumber, channel), HttpMethod.Post)
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}DiagnosticLog`; }

	private get getICIDiagnosticLogSummaryDataUrl(): string { return `${this.baseUrl}/GetICIDiagnosticSummaryData`; }

	private get getDIDiagnosticLogSummaryDataUrl(): string { return `${this.baseUrl}/GetDIDiagnosticSummaryData`; }

	private getStationDiagnosticLogHistoryUrl(stationId: number, diagnosticRunType: RbEnums.Common.StationDiagnosticRunType): string {
		return `${this.baseUrl}/GetStationDiagnosticHistory?stationId=${stationId}&diagnosticRunType=${diagnosticRunType}`;
	}

	private getMimLinkHourlyDiagnosticLogUrl(satelliteId: number, hour?: number): string {
		return `${this.baseUrl}/GetMIM_LINKHourlyDiagnosticLog?satelliteId=${satelliteId}&hour=${hour}`;
	}

	private getMimLinkChannelDiagnosticLogUrl(satelliteId: number, groupNumber: number, channel: number): string {
		return `${this.baseUrl}/GetMIM_LINKChannelDiagnosticLog?satelliteId=${satelliteId}&groupNumber=${groupNumber}&channel=${channel}`;
	}

	private resetMIM_LINKHourlyDiagnosticLogUrl(satelliteId: number): string {
		return `${this.baseUrl}/ResetMIM_LINKHourlyDiagnosticLog?satelliteId=${satelliteId}`;
	}

	private resetMIM_LINKChannelDiagnosticLogUrl(satelliteId: number, groupNumber?: number, channel?: number): string {
		return `${this.baseUrl}/ResetMIM_LINKChannelDiagnosticLog?satelliteId=${satelliteId}&groupNumber=${groupNumber}&channel=${channel}`;
	}
}

import { FlattenedFormattedControllerDifferenceItem } from './flattened-formatted-controller-difference-item.model';
import { FormattedControllerDifferenceItem } from './formatted-controller-difference-item.model';

export class FormattedControllerDifference {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.differences) this.differences = json.differences.map(d => new FormattedControllerDifferenceItem(d));
		}
	}

	key: string;
	id: number;
	name: string;
	differences: FormattedControllerDifferenceItem[] = [];

	get flatList(): FlattenedFormattedControllerDifferenceItem[] {
		const list = [];

		this.differences.forEach(d => {
			list.push(new FlattenedFormattedControllerDifferenceItem(this.name, d.name, d.database, d.physicalData));
		});

		return list;
	}

}

<mat-form-field>
		<mat-select
		[placeholder]="placeholder"
		(selectionChange)="handleChange($event)"
		[(ngModel)]="dropdownValue"
		[multiple]="allowMultiple"
		[panelClass]="'dropdown'"
		>
		<mat-option *ngIf="showAllOption">{{ 'STRINGS.ALL' | translate }}</mat-option>

		<mat-option
			*ngFor="let item of dataArray"
			[value]="item.id"
			>
			{{ item.name }}
		</mat-option>
	</mat-select>
</mat-form-field>


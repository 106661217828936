import { catchError, map, take, tap } from 'rxjs/operators';
import { forkJoin, Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { AppInjector } from '../../core/core.module';
import { AuthManagerService } from '../auth/auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CollectionChange } from '../../common/models/collection-change.model';
import { Controller } from './models/controller.model';
import { ControllerApiService } from './controller-api.service';

import { ControllerListItem } from './models/controller-list-item.model';
import { ControllerModuleType } from '../modules/models/controller-module-type.model';
import { ControllerStatusChangesManager } from './controller-change-managers/controller-status-changes-manager.service';
import { ControllerStatusItem } from './models/controller-status-item.model';
import { ControllerType } from './models/controller-type.model';
import { DetectModulesState } from './models/detect-modules-state.model';
import { FlowMonitoringStatusResult } from './models/flow-monitoring-status-result.model';
import { FormattedControllerDifference } from './models/controller-differences/formatted-controller-difference.model';
import { GetControllerQueryParams } from './models/get-controller-params.model';
import { IPChangeReason } from './models/ip-change-reason';
import { IQNetType } from './models/iqnet-type.model';
import { LicenseManagerService } from '../license/license-manager.service';
import { MessageBoxInfo } from '../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from '../../common/services/message-box.service';
import { of } from 'rxjs/internal/observable/of';
import { PinLockoutListItemType } from '../pin-codes/models/pin-lockout-list-item-type.model';
import { ProgramManagerService } from '../programs/program-manager.service';
import { RbConstants } from '../../common/constants/_rb.constants';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { Site } from '../sites/models/site.model';
import { SiteManagerService } from '../sites/site-manager.service';
import { Snapshot } from './models/snapshot';
import { StationManagerService } from '../stations/station-manager.service';
import { StationSequenceType } from './models/station-sequence-type.model';
import { StationsListChange } from '../stations/models/stations-list-change.model';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { TbosManagerService } from '../tbos/tbos-manager.service';
import { ToasterService } from '../../common/services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';
import { UtilityService } from '../../common/services/utility.service';
import CommInterfaceType = RbEnums.Common.CommInterfaceType;
import DeviceType = RbEnums.Common.DeviceType;
import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;
import ControllerSyncStateEnum = RbEnums.Common.ControllerSyncState;

import { ControllerChange, ControllerLocalChange } from '../signalR/controller-change.model';
import { ControllerGetLogsState } from '../signalR/controller-get-logs-state.model';
import { ControllerGetPhysicalDataState } from '../signalR/controller-get-physical-data-state.model';
import { ControllerSyncState } from '../signalR/controller-sync-state.model';
import { FirmwareUpdateProgress } from '../signalR/firmware-update-progress.model';
import { RasterTestProgress } from '../signalR/raster-test-progress.model';
import { UiSettingsService } from '../ui-settings/ui-settings.service';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class ControllerManagerService extends ServiceManagerBase implements OnDestroy {
	private readonly NO_VALUE = '-';

	private stationManager: StationManagerService;

	ControllerSyncState = RbEnums.Common.ControllerSyncState;

	// Subjects
	selectedControllerPropertiesChange = new Subject<Controller>();
	detectModulesStateChange = new Subject<DetectModulesState>();
	controllerFirmwareUpdateProgressChange = new Subject<ControllerListItem>();
	controllerMonthlyCyclingChange = new Subject<{ controllerIds: number[] }>();
	controllerFlowMonitoringChange = new Subject<{ controllerId: number }>();
	controllerFlowMonitoringStatusError = new Subject<{ controllerId: number }>();
	controllerFlowMonitoringStatusChange = new Subject<{ controllerId: number, flowMonitoringStatusResult: FlowMonitoringStatusResult }>();
	controllerRasterTestUpdateProgressChange = new Subject<ControllerListItem>();
	controllerListItemsCacheUpdated = new Subject<ControllerListItem[]>();
	controllerConnectedStatusUpdated = new Subject<ControllerStatusItem[]>();
	controllerLocalUpdated = new Subject<ControllerLocalChange>()

	// Cache Containers
	private _apiResult: ApiCachedRequestResponse<ControllerListItem[]>;

	// Member Vars
	private lastGetControllerQueryParams: GetControllerQueryParams;
	private _selectedController: Controller;
	private _selectedControllerItem: ControllerListItem;
	private _allowGolfUpgraded = false;
	private getSatelliteStatusesSub = null;

	private localConnectingControllers: {[controllerId: number]: {isConnecting: boolean, parentId: number, clientId: number}} = {};

	public satelliteTbos: RbEnums.Common.DeviceType[] = [
		RbEnums.Common.DeviceType.TBOS1,
		RbEnums.Common.DeviceType.TBOS2,
		RbEnums.Common.DeviceType.TBOS4,
		RbEnums.Common.DeviceType.TBOS6
	];

	public satelliteInterfaces: DeviceType[] = [
		DeviceType.MIM,
		DeviceType.MIM_LINK,
		DeviceType.IQI
	];
	public satelliteControllerTypes: DeviceType[] = [
		DeviceType.PAR_ES,
		DeviceType.PARplus,
		DeviceType.ESP_MC,
		DeviceType.MSCplus,
		DeviceType.ESPSAT,
		DeviceType.PulseDecoderTwoWire,
		DeviceType.SensorDecoderTwoWire,
	];
	public readonly maxStationsPerChannel = 24; // ? used in satellite configuration
	public readonly maxChannelsPerWire = RbConstants.Form.CHANNEL_LIMIT.channelCount; // ? used in satellite configuration
	public readonly maxWirePaths = [1, 2, 3, 4]; // ? used in satellite configuration
	public devicesToTranslate: DeviceType[] = [DeviceType.ICI, DeviceType.MIM]; // translate ICI to IC System, etc.
	public satelliteDevices: DeviceType[] = [DeviceType.MIM];
	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private controllerApiService: ControllerApiService,
				private siteManager: SiteManagerService,
				protected broadcastService: BroadcastService,
				private translate: TranslateService,
				private toasterService: ToasterService,
				private licenseManager: LicenseManagerService,
				private messageBoxService: MessageBoxService,
				private programManager: ProgramManagerService,
				private tbosService: TbosManagerService,
				private utilityService: UtilityService,
				private uiSettingsService: UiSettingsService,
				public controllerStatusChangesManager: ControllerStatusChangesManager
	) {
		super(broadcastService);
		this.stationManager = AppInjector.get(StationManagerService);

		// Monitor Site selection changes.
		siteManager.selectedSitesChange
			.pipe(
				untilDestroyed(this),
				switchMap(() => this.getControllersList())
			)
			.subscribe((controllers: ControllerListItem[]) => {
				this.broadcastControllerCollectionChanges(controllers);
			});

		// Update ControllersList when a site is deleted.
		siteManager.siteDeleted
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.getControllersList(true).subscribe(controllers => {
					this.broadcastControllerCollectionChanges(controllers);
				});
			});

		// Monitor Sync State Changes.
		broadcastService.syncStateChange
			.pipe(untilDestroyed(this))
			.subscribe((syncState: ControllerSyncState) => {
				if (!this._apiResult) { return; }

				const controller = this._apiResult.value.find(s => s.id === syncState.controllerId);
				// Update Sync State object and fire Controller Collection Changes
				if (controller) {
					// RB-13899: Although you might consider reloading the controller list, or at least the
					// controller indicating reverse-sync-complete, that's NOT necessary because changes
					// found after reverse-sync will trigger SatelliteChange messages, updating the cached
					// controller(s). Instead, just update the sync state.
					controller.syncState = (syncState.syncState === RbEnums.Common.ControllerSyncState.SyncFailed)
						? RbEnums.Common.ControllerSyncState.NotSynchronized
						: syncState.syncState;

					// Save queued state. Should always be false when sync is finished.
					controller.queued = syncState.isQueued;
					controller.frontPanelState = RbUtils.Controllers.getFrontPanelStateFromSyncState(controller.syncState);
					this.broadcastControllerCollectionChanges(this._apiResult.value, true);
				}
			});

		// Monitor Detect Modules State Changes
		broadcastService.detectModulesStateChange
			.pipe(untilDestroyed(this))
			.subscribe((state: DetectModulesState) => {
				if (!this._apiResult) { return; }

				const controller = <ControllerListItem>this._apiResult.value.find(c => c.id === state.controllerId);
				if (controller) {
					controller.isDetecting = state.isDetecting;
					this.detectModulesStateChange.next(state);
				}
			});

		// Monitor event logs changes
		broadcastService.eventLogsStateChange
			.pipe(untilDestroyed(this))
			.subscribe((state: ControllerGetLogsState) => {
				if (!this._apiResult) { return; }

				const controller = <ControllerListItem>this._apiResult.value.find(c => c.id === state.controllerId);
				if (controller) {
					controller.gettingLogs = state.gettingLogs;
				}
			});

		broadcastService.physicalDataStateChange
			.pipe(untilDestroyed(this))
			.subscribe((state: ControllerGetPhysicalDataState) => {
				if (!this._apiResult) { return; }

				const controller = <ControllerListItem>this._apiResult.value.find(c => c.id === state.controllerId);
				if (controller) {
					controller.gettingPhysicalData = state.gettingPhysicalData;
				}
			});

		// Monitor Firmware Progress
		broadcastService.firmwareUpdateProgressChange
			.pipe(untilDestroyed(this))
			.subscribe((firmwareUpdateProgress: FirmwareUpdateProgress) => {
				if (!this._apiResult) { return; }

				const controller = <ControllerListItem>this._apiResult.value.find(c => c.id === firmwareUpdateProgress.satelliteId);
				if (controller) {
					if (firmwareUpdateProgress.cancelled || firmwareUpdateProgress.errorMessage !== null || firmwareUpdateProgress.isCompleted) {
						if (firmwareUpdateProgress.isCompleted && controller.firmwareUpdateProgress != null
							&& controller.firmwareUpdateProgress.progress === 100 &&
							controller.type !== RbEnums.Common.DeviceType.LXIVM && controller.type !== RbEnums.Common.DeviceType.LXIVMPlus) {
							this.toasterService.showToaster(
								this.translate.instant(
									controller.firmwareUpdateProgress.firmwareHeaderDeviceType === 'NCC' ?
										'STRINGS.FIRMWARE_AFTER_NCC_UPDATE' : 'STRINGS.FIRMWARE_AFTER_CONTROLLER_UPDATE'),
								10000, controller.siteName + ' - ' + controller.name);
						}
						controller.firmwareUpdateProgress = null;
					} else {
						// Mitigate against over eager SignalR Firmware Progress updates!
						if (controller.firmwareUpdateProgress && controller.firmwareUpdateProgress.progress === firmwareUpdateProgress.progress) return;

						controller.firmwareUpdateProgress = firmwareUpdateProgress;
					}

					// Alert any subscribers that use the Controller List Collection of the change (e.g., Controllers Tab)
					this.getControllersList()
						.pipe(take(1))
						.subscribe((controllersList: ControllerListItem[]) => this.broadcastControllerCollectionChanges(controllersList.slice(), true));

					// Alert any subscribers that are using a single controller (e.g., Controller Left Sidebar)
					this.controllerFirmwareUpdateProgressChange.next(controller);
				}
			});

		// Monitor Raster Test Progress
		broadcastService.rasterTestProgressChange
			.pipe(untilDestroyed(this))
			.subscribe((rasterTestProgress: RasterTestProgress) => {
				if (!this._apiResult) { return; }

				const controller = <ControllerListItem>this._apiResult.value.find(c => c.id === rasterTestProgress.satelliteId);
				if (controller) {
					if (rasterTestProgress.cancelled || rasterTestProgress.errorMessage !== null || rasterTestProgress.isCompleted) {
						if (rasterTestProgress.errorMessage !== null) {
							this.toasterService.showToaster(
								rasterTestProgress.errorMessage,
								20000, controller.siteName + ' - ' + controller.name);
						} else if (rasterTestProgress.isCompleted && controller.rasterTestProgress != null) {
							controller.rasterTestProgress = rasterTestProgress;
							this.toasterService.showToaster(
								this.translate.instant('STRINGS.RASTER_TEST_COMPLETED'),
								20000, controller.siteName + ' - ' + controller.name);
						}
						controller.rasterTestProgress = null;
					} else {
						// Mitigate against over eager SignalR Raster Test Progress updates!
						if (controller.rasterTestProgress && controller.rasterTestProgress.progress === rasterTestProgress.progress) return;
						// After Raster Test event log needs to run so we will show progress until 95%
						if (rasterTestProgress.progress <= 95)
							controller.rasterTestProgress = rasterTestProgress;
					}

					// Alert any subscribers that use the Controller List Collection of the change (e.g., Controllers Tab)
					this.getControllersList()
						.pipe(take(1))
						.subscribe((controllersList: ControllerListItem[]) => this.broadcastControllerCollectionChanges(controllersList.slice(), true));

					// Alert any subscribers that are using a single controller (e.g., Controller Left Sidebar)
					this.controllerRasterTestUpdateProgressChange.next(controller);
				}
			});

		// Monitor Flow Rate Change
		broadcastService.controllerActualAggregateFlowRateChange
			.pipe(untilDestroyed(this))
			.subscribe((controllerChange: ControllerChange) => {
				if (!this._apiResult) return;

				const controller = <ControllerListItem>this._apiResult.value.find(c => c.id === controllerChange.satelliteId);
				if (controller) {
					controller.actualFlowRate = controllerChange.aggregateActualFlowRate;
					// Same as the aggregate actual flow rate, store the flowZoneFlowRates from signalR/AppSync
					// To display in the flow zone details page
					controller.actualFlowRateList = controllerChange.flowZoneFlowRates;
				} 
			});

		// RB-13990 - Properly handle adding a new controller. Instead of refetching the entire Controllers List, we simply insert the new
		// one into our cached list of controllers. Because getControllerItem can return a large object for mrm, we set it to null. We also
		// need to set the initial syncState, which we can guarantee is NotSynchronized at this point.
		broadcastService.controllerAdded
			.pipe(untilDestroyed(this))
			.subscribe((change: ControllerChange) => {
				// Check to see if the new controller is already in our cache. A simple guard against adding an entity twice.
				const listItem = this._apiResult == null ? null : this._apiResult.value.find(c => c.id === change.satelliteId);
				if (listItem != null) return;

				this.getControllerItem(change.satelliteId)
					.pipe(take(1))
					.subscribe((controller: ControllerListItem) => {
						controller.mrm = null;
						controller.syncState = ControllerSyncStateEnum.NotSynchronized;
						this._apiResult.value.push(controller);

						//Get stations of added controller to update to Stations By Site Cache
						this.stationManager.getStationsList(controller.id).pipe(take(1)).subscribe(stations => {
							this.stationManager.updateStationsBySiteCache(stations, controller.siteId)
						});
						
						this.broadcastControllerCollectionChanges(this._apiResult.value);
					});
			});

		broadcastService.controllerUpdated.pipe(untilDestroyed(this)).subscribe((change: ControllerChange) => {

			this.controllerApiService.clearCacheForController(change.satelliteId);

			// If a signal for a deleted controller is received, remove it from the cached list
			if (this._apiResult && change.changeType === RbEnums.SignalR.SatelliteChangeType.Deleted) {
				this._apiResult.value = this._apiResult?.value.filter(s => s.id !== change.satelliteId);
				this.broadcastControllerCollectionChanges(this._apiResult.value);
				return;
			}

			const listItem = this._apiResult == null ? null : this._apiResult.value.find(c => c.id === change.satelliteId);
			if (listItem != null && change.itemsChanged != null && change.itemsChanged.patch != null) {
				Object.assign(listItem, change.itemsChanged.patch);
				if (change.itemsChanged.patch.rainDelayLong != null)
					listItem.rainDelay = RbUtils.Conversion.convertTicksToDuration(change.itemsChanged.patch.rainDelayLong).asDays();
				if (change.itemsChanged.patch.logicalDialPos != null)
					listItem.isShutdown = change.itemsChanged.patch.logicalDialPos === RbEnums.Common.RotarySwitchPosition.Off;
				if (change.itemsChanged.patch.commInterface != null && change.itemsChanged.patch.commInterface.type != null)
					listItem.commInterfaceType = change.itemsChanged.patch.commInterface.type;
				if (change.frontPanelSyncState === RbEnums.SignalR.FrontPanelSyncState.OutOfSync ||
					listItem.frontPanelState === RbEnums.Common.FrontPanelState.OutOfSync) {
					listItem.frontPanelState = RbEnums.Common.FrontPanelState.OutOfSync;
					listItem.syncState = RbEnums.Common.ControllerSyncState.NotSynchronized;
				}

				const syncSettings = change.itemsChanged?.patch?.syncSettings;
				if (!syncSettings) {
					return;
				}

				const scheduledTime = syncSettings?.scheduledTimes[0]?.dateTime;
				if (!scheduledTime) {
					return;
				}

				// RB-14633: the controller scheduled time should be update when signalR notifies new data.
				listItem.upcomingAutoContactScheduledTime = RbUtils.Conversion.convertStringToDate(
					RbUtils.Conversion.convertDateToUTCDateTimeParameterString(scheduledTime)
				);
				this.broadcastControllerCollectionChanges(this._apiResult.value, true);
			}
		});

		broadcastService.hasLastSyncDifferenceChanged.pipe(untilDestroyed(this)).subscribe((change: ControllerChange) => {
			this.controllerApiService.clearCacheForController(change.satelliteId);
			const listItem = this._apiResult == null ? null : this._apiResult.value.find(c => c.id === change.satelliteId);
			if (listItem != null && change.hasLastSyncDifferences != null) {
				listItem.hasLastSyncDifferences = change.hasLastSyncDifferences;
				this.broadcastControllerCollectionChanges(this._apiResult.value, true);
			}
		});

		// Monitor allow golf upgraded state.
		this.licenseManager.allowGolfUpgraded()
			.pipe()
			.subscribe(allowGolfUpgraded => this._allowGolfUpgraded = allowGolfUpgraded);

		// Get initial list of controllers.
		this.getControllersList().pipe(take(1)).subscribe();
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	protected clearCache() {
		this.controllerApiService.clearCache();
		this._apiResult = null;
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	isAnyControllerSyncing(clientControllerParentId = null): boolean {
		if (!this._apiResult) return false;

		const controllersList = this.getControllersListForStatusLookup(clientControllerParentId);
		return controllersList.find(c => c.syncState === RbEnums.Common.ControllerSyncState.Syncing) !== undefined;
	}

	isAnyControllerReverseSyncing(clientControllerParentId = null): boolean {
		if (!this._apiResult) return false;

		const controllersList = this.getControllersListForStatusLookup(clientControllerParentId);
		return controllersList.find(c => c.syncState === RbEnums.Common.ControllerSyncState.ReverseSyncing) !== undefined;
	}

	isAnyControllerGettingLogs(clientControllerParentId = null): boolean {
		if (!this._apiResult) return false;

		const controllersList = this.getControllersListForStatusLookup(clientControllerParentId);
		return controllersList.find(c => c.gettingLogs === true) !== undefined;
	}

	isAnyControllerGettingPhysicalData(clientControllerParentId = null): boolean {
		if (!this._apiResult) return false;

		const controllersList = this.getControllersListForStatusLookup(clientControllerParentId);
		return !!controllersList.find(c => c.gettingPhysicalData);
	}

	get selectedController(): Controller {
		return this._selectedController;
	}

	set selectedController(controller: Controller) {
		this._selectedController = controller;
		this.broadcastService.selectedEntityChange.next(controller);
	}

	get selectedControllerItem(): ControllerListItem {
		return this._selectedControllerItem;
	}

	set selectedControllerItem(controllerItem: ControllerListItem) {
		this._selectedControllerItem = controllerItem;
	}

	getLocalConnectingController(controllerId: number): any {
		return this.localConnectingControllers[controllerId];
	}
	
	setLocalConnectingController(controllerId: number, parentId = null, isConnecting = true) {
		this.localConnectingControllers[controllerId] = {isConnecting: isConnecting, parentId: parentId, clientId: null};
		if (!!parentId) {
			this.localConnectingControllers[parentId] = {isConnecting: isConnecting, parentId: null, clientId: controllerId};
		}
	}

	removeLocalConnectingController(controllerId: number) {
		const localConnectingController = this.localConnectingControllers[controllerId];
		if (localConnectingController?.clientId) {
			delete this.localConnectingControllers[localConnectingController.clientId];
		}
		delete this.localConnectingControllers[controllerId];
	}

	weatherSourceChanged() {
		this.broadcastService.associatedEntityChange.next(RbConstants.Cache.PROGRAM_STEP);
	}

	copyController(satelliteId: number, destinationSiteId: number, destinationSatelliteId: number): Observable<Controller> {
		return this.controllerApiService.copyController(satelliteId, destinationSiteId, destinationSatelliteId)
			.pipe(
				tap(() => {
					// Update the list of stations
					this.programManager.getProgramsList(true).pipe(take(1)).subscribe();
				})
			);
	}

	createController(controllerUpdate: any, site: Site): Observable<Controller> {
		const addController = {
			name: controllerUpdate.name,
			siteId: controllerUpdate.siteId,
			iqNetType: controllerUpdate.iqNetType,
			parentId: controllerUpdate.parentId || null,
			description: controllerUpdate.description,
			deviceType: controllerUpdate.deviceType,
			address: controllerUpdate.address,
			groupNumber: controllerUpdate.groupNumber
		};
		if (controllerUpdate.weatherSourceId != null) addController['weatherSourceId'] = controllerUpdate.weatherSourceId;
		if (controllerUpdate.commInterface != null) {
			addController['connectionType'] = controllerUpdate.commInterface.type;

			switch (controllerUpdate.commInterface.type) {
				case RbEnums.Common.CommInterfaceType.Ethernet:
					addController['ipName'] = controllerUpdate.commInterface.ipString;
					addController['ipPort'] = controllerUpdate.commInterface.ipPort;
					addController['useVpn'] = controllerUpdate.commInterface.useVpn;
					break;
				case RbEnums.Common.CommInterfaceType.Serial:
					addController['comPort'] = controllerUpdate.commInterface.comPort;
					break;
				case RbEnums.Common.CommInterfaceType.Radio:
					addController['comPort'] = controllerUpdate.commInterface.comPort;
					addController['radioAddress'] = controllerUpdate.commInterface.radioAddress;
					break;
			}
		}

		if (this.isSatelliteControllerType(addController.deviceType)) {
			addController['channelA'] = controllerUpdate.channel_a === 0 ? null : controllerUpdate.channel_a;
			addController['channelB'] = controllerUpdate.channel_b === 0 ? null : controllerUpdate.channel_b;
			addController['channelC'] = controllerUpdate.channel_c === 0 ? null : controllerUpdate.channel_c;
			addController['stationCount'] = controllerUpdate.stations;
			addController['maxSolenoids'] = controllerUpdate.maxSolenoids;
			addController['simulStations'] = controllerUpdate.simulStations;
			addController['satelliteEnabled'] = controllerUpdate.performIrrigation;
		}

		// RB-10607: For LDISDI devices the max number of simultaneous solenoids is equal to the maax number of simultaneous stations
		if (addController.deviceType === RbEnums.Common.DeviceType.LDISDI){
			addController['maxSolenoids'] = controllerUpdate.maxSolenoids;
			addController['simulStations'] = controllerUpdate.maxSolenoids;
		}

		return this.controllerApiService.createController(addController)
			.pipe(
				tap(() => {
					// Update the list of sites
					this.siteManager.getSites(true).pipe(take(1)).subscribe();
					// Update the list of stations
					this.programManager.getProgramsList(true).pipe(take(1)).subscribe();
				})
			);
	}

	deleteControllers(controllerIds: number[]): Observable<null> {
		return this.controllerApiService.deleteControllers(controllerIds)
			.pipe(
				tap(() => {
					// Update the list of sites
					this.siteManager.getSites(true).pipe(take(1)).subscribe();
					// Update the list of stations
					this.programManager.updateProgramsListForDeletedControllers(controllerIds).pipe(take(1)).subscribe();

					// Reset controller landing page to "Controller List" if we delele controller being set for landing page
					if (!this.siteManager.isGolfSite) {
						forkJoin([
							this.uiSettingsService.getPreference('landingPageId'),
							this.uiSettingsService.getPreference('dashboardControllerId')
						]).pipe(
							switchMap(([landingPageId, dashboardControllerId]) => {
								return landingPageId === RbEnums.Common.CommercialHomepageDestination.Controller
									&& controllerIds.includes(dashboardControllerId)
										? this.uiSettingsService.setPreference('dashboardControllerId', 0)
										: of(null);
							})
						).subscribe();
					}

					// Remove the deleted controller (and any client controllers) from our collection and let interested parties know.
					this._apiResult.value = this._apiResult.value
						.filter(s => controllerIds.indexOf(s.id) === -1 && controllerIds.indexOf(s.parentId) === -1);

					this.broadcastControllerCollectionChanges(this.getControllersListForSelectedSites(this._apiResult.value));
				})
			);
	}

	detectModules(controllerId: number): Observable<null> {
		return this.controllerApiService.detectModules(controllerId);
	}

	doesAnyControllerInListHaveActiveFirmwareUpdate(controllerIds: number[]): Observable<boolean> {
		return Observable.create(observer => {
			this.getControllersList()
				.pipe(take(1))
				.subscribe((controllerListItems: ControllerListItem[]) => {
					for (let i = 0; i < controllerIds.length; i++) {
						const cli = controllerListItems.find(c => c.id === controllerIds[i]);
						if (cli && cli.firmwareUpdateProgress) {
							observer.next(true);
							observer.complete();
						}
					}

					observer.next(false);
					observer.complete();
				});
		});
	}

	doesAnyControllerInListHaveActiveRasterTest(controllerIds: number[]): Observable<boolean> {
		return Observable.create(observer => {
			this.getControllersList()
				.pipe(take(1))
				.subscribe((controllerListItems: ControllerListItem[]) => {
					for (let i = 0; i < controllerIds.length; i++) {
						const cli = controllerListItems.find(c => c.id === controllerIds[i]);
						if (cli && cli.rasterTestProgress) {
							observer.next(true);
							observer.complete();
						}
					}

					observer.next(false);
					observer.complete();
				});
		});
	}

	getLowestCommonMaxDaysOff(controllerIds: number[]): Observable<number> {
		return this.getControllersList()
			.pipe(
				take(1),
				switchMap((controllers: ControllerListItem[]) => {
					const controllersOfInterest = controllers.filter(r => controllerIds.includes(r.id));
					if (controllersOfInterest.length < 1) { return of(5); }

					// TODO: Enhance filter logic to include additional parameters that determine controllers supporting > 5 days off (e.g., IVM w/new FW).
					const controllerTypesWith15DaysOff: DeviceType[] = [DeviceType.LXME2];
					const controllersWith5DaysOff = controllersOfInterest.filter(c => !controllerTypesWith15DaysOff.includes(c.type));

					return of(controllersWith5DaysOff.length > 0 ? 5 : 15);
				})
			);
	}

	getClientAddressUniqueness(address: number, id: number, parentId: number): Observable<UniquenessResponse> {
		return this.controllerApiService.getClientAddressUniqueness(address, id, parentId);
	}

	getClientControllersList(controllerId: number, bypassCache = false, systemSortStatus: RbEnums.Common.StatusSort = RbEnums.Common.StatusSort.None)
		: Observable<ControllerListItem[]> {

		return this.getControllersList(bypassCache, systemSortStatus)
			.pipe(map((controllers: ControllerListItem[]) => controllers.filter(c => c.parentId === controllerId &&
				!this.isSatelliteTbos(c.type))));
	}

	getParentController(controller: ControllerListItem): Observable<ControllerListItem> {
		if (controller?.iqNetType === RbEnums.Common.IqNetType.IQNetClient) {
			return of(this.getControllerListItemFromCache(controller.parentId));
		}
		if (this.isSatelliteTbos(controller?.type)) {
			return this.tbosService.getServerSatellite(controller?.id).pipe(
				switchMap((controller: Controller) => of(this.getControllerListItemFromCache(controller?.id))
				));
		}
		return of(controller);
	}

	getControllerItem(controllerId: number): Observable<ControllerListItem> {
		return this.controllerApiService.getControllerItem(controllerId);
	}

	getControllerStatusesList(controllerIds: Number[]): Observable<ControllerStatusItem[]> {
		return this.controllerApiService.getControllerStatusesList(controllerIds);
	}

	getConnectedControllersCountForCurrentUser() {
		return this.controllerApiService.getConnectedControllersCountForCurrentUser();
	}

	getControllerListItemFromCache(controllerId: number): ControllerListItem {
		return this._apiResult == null ? null : this._apiResult.value.find(c => c.id === controllerId);
	}

	getController(controllerId: number, queryParams?: GetControllerQueryParams, bypassCache = false): Observable<Controller> {
		const selectedQueryParams = queryParams ? queryParams : this.lastGetControllerQueryParams;

		return this.controllerApiService.getController(controllerId, selectedQueryParams, bypassCache)
			.pipe(map(response => new Controller(response.value)));
	}

	getFormattedControllerDifferences(controllerId: number): Observable<FormattedControllerDifference[]> {
		return this.controllerApiService.getFormattedControllerDifferences(controllerId);
	}

	getControllerModuleTypes(): Observable<ControllerModuleType[]> {
		return this.controllerApiService.getControllerModuleTypes().pipe(map(response => response.value));
	}

	haveControllersBeenSynced(controllerIds: number[]): Observable<boolean> {
		// getControllerListItem instead of getController to use cache
		const sources = controllerIds.map(id => this.getControllerListItem(id));
		return forkJoin(sources)
			.pipe(map((results: ControllerListItem[]) => {
				return results.every(controller => controller.lastContact.getFullYear() !== 2000 || controller.lastPhysicalRetrieve.getFullYear() !== 2000);
			}));
	}

	getGprsIps(): Observable<string[]> {
		return this.controllerApiService.getGprsIps();
	}

	updateControllerItem(change: ControllerChange) {
		if (!this._apiResult) return;

		if (change.changeType === RbEnums.SignalR.JobType.ConnectionStopped) {
			for (const controllerItem of this._apiResult.value) {
				if (controllerItem.id === change.satelliteId) {
					controllerItem.isConnected = false;
					break;
				}
			}
		}
		if (change.connectDataPack) {
			for (const controllerItem of this._apiResult.value) {
				if (controllerItem.id === change.satelliteId) {
					controllerItem.isConnected = true;
					break;
				}
			}
		}
		if (change.changeType === RbEnums.SignalR.JobType.ConnectionCompleted) {
			for (const controllerItem of this._apiResult.value) {
				if (controllerItem.id === change.satelliteId) {
					controllerItem.isConnected = true;
					break;
				}
			}
		}
		if (change.changeType === RbEnums.SignalR.JobType.ConnectionFailed) {
			for (const controllerItem of this._apiResult.value) {
				if (controllerItem.id === change.satelliteId) {
					controllerItem.isConnected = false;
					break;
				}
			}
		}
	}

	getControllersList(bypassCache = false, systemSortStatus: RbEnums.Common.StatusSort = RbEnums.Common.StatusSort.None): Observable<ControllerListItem[]> {
		// Guard against call after user logs out.
		if (!this.authManager.isLoggedIn) return of([]);

		return this.controllerApiService.getControllersList(bypassCache).pipe(map(response => {
			const list = response.value;
			this.updateDynamicControllerData(list);
			list.sort(RbUtils.Controllers.sortControllersList);
			this._apiResult = response;

			if (!response.isFromCache && !this.siteManager.isGolfSite) {
				if (!this.getSatelliteStatusesSub && this._apiResult.value && this._apiResult.value.length > 0) {
					const satIds = this._apiResult.value.map(x => x.id);
					this.getSatelliteStatusesSub = this.controllerApiService.getControllerStatusesList(satIds).pipe(take(1)).subscribe(statuses => {
						this._apiResult.value.forEach(sat => {
							const status = statuses?.find(x => x.id === sat.id);
							sat.isConnected = status ? status.isConnected : false;
						});
						this.controllerConnectedStatusUpdated.next(statuses);
						this.getSatelliteStatusesSub = null;
					});
				}
			}

			const selectedSitesControllersList = this.getControllersListForSelectedSites(this._apiResult.value, systemSortStatus);

			// Some components hold onto a cached controller list collection. When the collection is updated via a background process (e.g., SignalR or
			// SignalR related - i.e., downstream event) those components may want to update their local collection. In order to allow them to do that
			// we will fire off an event if the current Controller List collection cache has been updated from the api.
			if (!this._apiResult.isFromCache) {
				// Send out notification to those that care.
				this.controllerListItemsCacheUpdated.next(selectedSitesControllersList);
			}

			return selectedSitesControllersList;
		}));
	}

	getControllerListItem(controllerId: number, bypassCache = false): Observable<ControllerListItem> {
		return this.getControllersList(bypassCache)
			.pipe(map((controllersList: ControllerListItem[]) => controllersList.find(c => c.id === controllerId)));
	}

	getControllerName(controllerId: number): string {
		if (!this._apiResult || this._apiResult.value.length < 1) return this.NO_VALUE;

		const controller = this._apiResult.value.find(c => c.id === controllerId);
		return controller ? controller.name : this.NO_VALUE;
	}

	getControllerType(controllerId: number): RbEnums.Common.DeviceType {
		if (!this._apiResult || this._apiResult.value.length < 1) return null;

		const controller = this._apiResult.value.find(c => c.id === controllerId);
		return controller ? controller.type : null;
	}

	getControllerTypes(): Observable<ControllerType[]> {
		return this.controllerApiService.getControllerTypes().pipe(map(response => response.value));
	}

	getIqNetServersForSite(siteId: number): Observable<ControllerListItem[]> {
		return this.getControllersList()
			.pipe(map((controllers: ControllerListItem[]) => {
				return controllers.filter(c => c.siteId === siteId && c.iqNetType === RbEnums.Common.IqNetType.IQNetServer);
			}));
	}

	getIQNetTypes(): Observable<IQNetType[]> {
		return this.controllerApiService.getIQNetTypes().pipe(map(response => response.value));
	}

	getNameUniqueness(name: string, id: number, siteId: number): Observable<UniquenessResponse> {
		return this.controllerApiService.getNameUniqueness(name, id, siteId);
	}

	getNextDefaultName(siteId: number, deviceType: number): Observable<string> {
		return this.controllerApiService.getNextDefaultName(siteId, deviceType);
	}

	getNextSatelliteNumber(parentDeviceId: number, groupNumber: number): Observable<number> {
		return this.controllerApiService.getNextSatelliteNumber(parentDeviceId, groupNumber);
	}

	getPhysicalData(controllerIds: number[]): Observable<void> {
		return this.controllerApiService.getPhysicalData(controllerIds);
	}

	getFlowMonitoringStatus(getFlowMonitoringStatusSatellite: object): Observable<void> {
		return this.controllerApiService.getFlowMonitoringStatus(getFlowMonitoringStatusSatellite);
	}

	healAlarmsAndGetFlowMonitoringStatus(getFlowMonitoringStatusSatellite: object): Observable<void> {
		return this.controllerApiService.healAlarmsAndGetFlowMonitoringStatus(getFlowMonitoringStatusSatellite);
	}

	getDatapacks(controllerId: number): Observable<void> {
		return this.controllerApiService.getDataPacks(controllerId);
	}

	getPinLockoutTypes(): Observable<PinLockoutListItemType[]> {
		return this.controllerApiService.getPinLockoutTypes().pipe(map(response => response.value));
	}

	getStationSequenceTypes(): Observable<StationSequenceType[]> {
		return this.controllerApiService.getStationSequenceTypes().pipe(map(response => response.value));
	}

	getSiteControllersList(
		siteId: number,
		bypassCache = false,
		systemSortStatus: RbEnums.Common.StatusSort = RbEnums.Common.StatusSort.None,
		onlyParents: boolean = false): Observable<ControllerListItem[]> {

		return this.getControllersList(bypassCache, systemSortStatus)
			.pipe(map((controllersList: ControllerListItem[]) => {
				controllersList = controllersList.filter(x => x.siteId === siteId);

				if (onlyParents)
					controllersList = controllersList.filter(x => x.parentId === null);

				return controllersList;
			}));
	}

	getSiteSatellitesList(
		siteId: number,
		parentId: number,
		wireGroup: number,
		bypassCache = false,
		systemSortStatus: RbEnums.Common.StatusSort = RbEnums.Common.StatusSort.None): Observable<ControllerListItem[]> {

		return this.getControllersList(bypassCache, systemSortStatus)
			.pipe(map((controllerList: ControllerListItem[]) => {
				controllerList = controllerList.filter(x =>
					x.siteId === siteId
					&& x.parentId === parentId);
				if (!!wireGroup)
					controllerList = controllerList.filter(x => x.groupNumber === wireGroup);
				return controllerList;
			}));
	}

	getVisibleControllerTypes(): Observable<ControllerType[]> {
		return this.getControllerTypes().pipe(map(types => this.filterVisibleControllerTypes(types)));
	}

	getParentControllerByControllerId(controllerId: number, suppressErrorNotification = false): Observable<ControllerListItem> {
		return this.getControllerListItem(controllerId)
			.pipe(
				take(1),
				catchError(error => {
					if (!suppressErrorNotification) {
						this.messageBoxService.showMessageBox(`${this.translate.instant('SPECIAL_MSG.REQUESTED_OPERATION_FAILED')} ${error.error}`);
					}
					throw error;
				}),
				switchMap((cli: any) => this.getParentController(cli).pipe(take(1)))
			);
	}

	isControllerInDemoMode(controllerId: number, suppressErrorNotification = false): Observable<boolean> {
		return this.getParentControllerByControllerId(controllerId, suppressErrorNotification)
			.pipe(
				take(1),
				map((controller: ControllerListItem) => (!this.siteManager.isGolfSite
					&& (controller?.commInterfaceType === CommInterfaceType.None ||
						controller?.commInterfaceType === CommInterfaceType.Demo)))
			);
	}

	getCompanyTotalSatelliteCount() {
		return this.controllerApiService.getCompanyTotalSatelliteCount();
	}

	doIfNotDemoModeController(controllerId: number, callback: Function, argument?: any) {
		if (this.siteManager.isGolfSite) {
			callback(argument == null ? controllerId : argument);
			return;
		}

		this.isControllerInDemoMode(controllerId).subscribe((isInDemoMode: boolean) => {
			if (!isInDemoMode) {
				callback(argument == null ? controllerId : argument);
			} else {
				this.messageBoxService.showMessageBox(new MessageBoxInfo('SPECIAL_MSG.DEMO_MODE_FEATURE_UNAVAILABLE', MessageBoxIcon.Information));
			}
		});
	}

	doIfNotContainsDemoModeController(controllerIds: number[], callback: Function, argument?: any) {
		if (this.siteManager.isGolfSite) {
			callback(argument == null ? controllerIds : argument);
			return;
		}

		let containsDemoController = false;

		this.getControllersList()
			.pipe(take(1))
			.subscribe((controllers: ControllerListItem[]) => {
				for (let i = 0; i < controllerIds.length; i++) {
					const cli = controllers.find(c => c.id === controllerIds[i]);
					if (cli && this.utilityService.isInDemoMode(cli)) {
						containsDemoController = true;
						break;
					}
				}

				if (!containsDemoController) {
					callback(argument == null ? controllerIds : argument);
				} else {
					this.messageBoxService.showMessageBox(new MessageBoxInfo('SPECIAL_MSG.DEMO_MODE_FEATURE_UNAVAILABLE_MULTI', MessageBoxIcon.Information));
				}
			});
	}

	isDetecting(controllerId: number): boolean {
		if (this._apiResult == null) return false;
		const controller = this._apiResult.value.find(s => s.id === controllerId);
		return controller == null ? false : controller.isDetecting;
	}

	moveController(controllerId: number, destinationSiteId: number, destinationControllerId?: number): Observable<Controller> {
		return this.controllerApiService.moveController(controllerId, destinationSiteId, destinationControllerId);
	}

	reverseSynchronize(controllerIds: number[]): Observable<null> {
		return this.controllerApiService.reverseSynchronize(controllerIds);
	}

	stopAllIrrigation(controllerIds: number[]): Observable<null> {
		controllerIds.forEach((controllerId) => {
			this.stationManager.cancelLocalIrrigationQueue(controllerId);
		})
		return this.controllerApiService.stopAllIrrigation(controllerIds);
	}

	synchronize(controllerIds: number[], syncIrrigationInProgress: boolean = false): Observable<null> {
		return this.controllerApiService.synchronize(controllerIds, syncIrrigationInProgress);
	}

	updateFlowMonitoringStatus(controllerId: number, result: FlowMonitoringStatusResult) {
		this.controllerFlowMonitoringStatusChange.next({ controllerId: controllerId, flowMonitoringStatusResult: result });
	}

	handleFlowMonitoringStatusError(controllerId: number) {
		this.controllerFlowMonitoringStatusError.next({ controllerId: controllerId });
	}

	updateControllers(controllerIds: number[], controllerUpdate: any): Observable<null> {
		return this.controllerApiService.updateControllers(controllerIds, controllerUpdate)
			.pipe(
				tap(() => {

					this.controllerLocalUpdated.next(new ControllerLocalChange(controllerIds, controllerUpdate));
					// Remove cached individual controller data
					controllerIds.forEach(id => this.controllerApiService.clearCacheForController(id));

					if (this.selectedController != null &&
						!!controllerIds.find(id => id === this.selectedController.id) &&
						(controllerUpdate.name != null || controllerUpdate.rainDelayLong != null
							|| controllerUpdate.maxFlow != null || controllerUpdate.weatherSourceId !== this.selectedController.weatherSourceId
							|| controllerUpdate.logicalDialPos != null || controllerUpdate.commInterface != null)) {
						if (controllerUpdate.name != null) {
							this.selectedController.name = controllerUpdate.name;
						}
						if (controllerUpdate.rainDelayLong != null) {
							this.selectedController.rainDelayDaysRemaining
								= RbUtils.Conversion.convertTicksToDuration(controllerUpdate.rainDelayLong).asDays();
						}
						if (controllerUpdate.logicalDialPos != null) {
							this.selectedController.logicalDialPos = controllerUpdate.logicalDialPos;
						}
						if (controllerUpdate.maxFlow != null) {
							this.selectedController.maxFlow = controllerUpdate.maxFlow;
						}
						if (controllerUpdate.commInterface != null && controllerUpdate.commInterface.type != null &&
							this.selectedController.commInterface.length === 1) {
							this.selectedController.commInterface[0].type = controllerUpdate.commInterface.type;
						}
						if (controllerUpdate.monthlyCyclingTime != null) {
							this.controllerMonthlyCyclingChange.next({ controllerIds: controllerIds });
						}
						this.selectedController.weatherSourceId = controllerUpdate.weatherSourceId;
						this.selectedControllerPropertiesChange.next(this.selectedController);
					}

					// Broadcast out Flow Monitoring changes. Useful for Controller Flow Indicator
					if (controllerUpdate['flowMonitoring']) {
						controllerIds.forEach((id: number) => this.controllerFlowMonitoringChange.next({ controllerId: id }));
					}

					// Broadcast out station changes according to the modules
					if (controllerUpdate['modules']) {
						controllerIds.forEach((id: number) => {
							this.stationManager.getStationsList(id, true).subscribe(stations => {
								this.stationManager.stationsListChange.next(new StationsListChange(id, stations));
							});
						});
					}
				})
			);
	}

	getIPChangeReasons(): Observable<IPChangeReason[]> {
		return this.controllerApiService.getIPChangeReasons().pipe(map(response => response.value));
	}

	isSatelliteInterface(type: number): boolean {
		if (!type) return false;
		return (this.satelliteInterfaces.findIndex(d => d === type) !== -1);
	}

	isSatelliteTbos(type: number): boolean {
		if (!type) return false;
		return (this.satelliteTbos.findIndex(d => d === type) !== -1);
	}

	// Controllers for MIM interface
	isSatelliteControllerType(type: number): boolean {
		if (!type) return false;
		return (this.satelliteControllerTypes.findIndex(d => d === type) !== -1);
	}

	/**
	 * Return a dictionary from satellite ID to satellite's connected state, true or false. This operation
	 * handles the case of child satellites, whose connected state should match their parent satellite, as well as
	 * the interfaces themselves.
	 * @param controllersList - ControllerListItem[] of satellites to be included in the dictionary. It should
	 * include any interfaces for which child satellites are also included but can contain a subset of the
	 * interfaces otherwise.
	 * @return { [id: number]: boolean } where id is the satellite id for connected state retrieval and the
	 * value is the connected state for that satellite, true for connected, false for unconnected.
	 */
	public getControllerConnectedDict(controllersList: ControllerListItem[]): { [id: number]: boolean } {
		if (!controllersList) return {};

		// Get the satellites based on the interfaceId provided. That is, for an ICI, we get only the ICI.
		// For a MIM we get the MIM and all of its child satellites.
		const controllerStateDictionary: { [id: number]: boolean } = {};

		// Get the states of each interface, adding those to the dictionary.
		controllersList
			.filter(c => c.parentId == null)
			.forEach(c => controllerStateDictionary[c.id] = c.isConnected);

		// For child controllers, set their connection status to the connected status of their parent
		// interface (already in the dictionary).
		controllersList
			.filter(c => c.parentId != null)
			.forEach(c => controllerStateDictionary[c.id] = controllerStateDictionary[c.parentId]);

		return controllerStateDictionary;
	}

	createSnapshots(controllerIds: number[]): Observable<any> {
		return this.controllerApiService.createSnapshots(controllerIds);
	}

	getSnapshots(controllerId: number): Observable<Snapshot[]> {
		return this.controllerApiService.getSnapshots(controllerId);
	}

	restoreSnapshot(controllerId: number, date: Date, forceRestore: boolean): Observable<null> {
		return this.controllerApiService.restoreSnapshot(controllerId, date, forceRestore).pipe(tap(() => {
			this.clearCache();
			this.broadcastService.controllerRestored.next(null);
		}));
	}

	getWirePathChannels(): number[] {
		const wirePathChannels = [];
		wirePathChannels.push(null); // ? Add empty option to select
		for (let i = 0; i < RbConstants.Form.CHANNEL_LIMIT.channelCount; i++) {
			wirePathChannels.push(i + 1);
		}

		return wirePathChannels;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private broadcastControllerCollectionChanges(controllers: ControllerListItem[], isStatusUpdate = false) {
		this.broadcastService.controllerCollectionChange.next(new CollectionChange(controllers, isStatusUpdate));
	}

	private getControllersListForSelectedSites(controllers: ControllerListItem[], systemSortStatus: RbEnums.Common.StatusSort = RbEnums.Common.StatusSort.None)
		: ControllerListItem[] {

		if (this.siteManager.selectedSiteIds.length < 1) {
			return this.SortController(controllers, systemSortStatus);
		} else {
			return this.SortController(controllers.filter(s => this.siteManager.selectedSiteIds.indexOf(s.siteId) !== -1), systemSortStatus);
		}
	}

	private SortController(controllers: ControllerListItem[], systemSortStatus: RbEnums.Common.StatusSort) {
		switch (systemSortStatus) {
			case RbEnums.Common.StatusSort.AutoOff:
				controllers = controllers.sort((a, b) => RbUtils.Common.compareBoolean(a.isShutdown, b.isShutdown));
				break;
			case RbEnums.Common.StatusSort.Connections:
				controllers = controllers.sort((a, b) => RbUtils.Common.compareBoolean(a.isConnected, b.isConnected));
				break;
			case RbEnums.Common.StatusSort.Differences:
				controllers = controllers.sort((a, b) => RbUtils.Common.compareBoolean(a.hasLastSyncDifferences, b.hasLastSyncDifferences));
				break;
			case RbEnums.Common.StatusSort.RainDelay:
				controllers = controllers.sort((a, b) => RbUtils.Common.compareBoolean(a.isInRainDelay, b.isInRainDelay));
				break;
			case RbEnums.Common.StatusSort.OutOfSync:
				controllers = controllers.sort((a, b) => RbUtils.Common.compareBoolean(b.frontPanelState === RbEnums.Common.FrontPanelState.OutOfSync,
					a.frontPanelState === RbEnums.Common.FrontPanelState.OutOfSync));
				break;
		}
		return controllers;
	}

	private updateDynamicControllerData(newControllersList: ControllerListItem[]): void {
		if (!this._apiResult) { return; }

		newControllersList.forEach(newController => {
			const oldController = this._apiResult.value.find(s => s.id === newController.id);
			if (oldController) {
				if (oldController.syncState === RbEnums.Common.ControllerSyncState.Syncing
					|| oldController.syncState === RbEnums.Common.ControllerSyncState.ReverseSyncing) {

					newController.syncState = oldController.syncState;
					newController.frontPanelState = oldController.frontPanelState;
				}

				newController.gettingLogs = oldController.gettingLogs;
				newController.gettingPhysicalData = oldController.gettingPhysicalData;
				newController.firmwareUpdateProgress = oldController.firmwareUpdateProgress;
				newController.actualFlowRate = oldController.actualFlowRate;
				newController.actualFlowRateList = oldController.actualFlowRateList;
				newController.isConnected = oldController.isConnected;
				newController.queued = oldController.queued;
				newController.rasterTestProgress = oldController.rasterTestProgress;
			}
		});
	}

	private filterVisibleControllerTypes(types: ControllerType[]): ControllerType[] {
		if (this.siteManager.isGolfSite) {
			if (!this._allowGolfUpgraded) {
				const allowedInterfaces:RbEnums.Common.DeviceType[] = [
					RbEnums.Common.DeviceType.ICI,
					RbEnums.Common.DeviceType.MIM,
					RbEnums.Common.DeviceType.MIM_LINK,
					RbEnums.Common.DeviceType.LDISDI,
				];

				return types.filter(t => allowedInterfaces.includes(t.value));
			}

			// RB-6874: We need to filter based on if it isGolfController
			return types.filter(t => RbUtils.Controllers.isGolfController(t.value));
		} else if (!this.authManager.hasDeveloperAccess) { // remove ESPME3 other than Developer Access user
			types = types.filter(t => t.value !== RbEnums.Common.DeviceType.ESPME3);
		}

		// only show one TM2, RC2
		types = types.filter(t => t.value !== RbEnums.Common.DeviceType.TM2_6Station);
		types = types.filter(t => t.value !== RbEnums.Common.DeviceType.TM2_8Station);
		types = types.filter(t => t.value !== RbEnums.Common.DeviceType.TM2_12Station);
		types = types.filter(t => t.value !== RbEnums.Common.DeviceType.RC2_6Station);
		types = types.filter(t => t.value !== RbEnums.Common.DeviceType.RC2_8Station);

		// only RBAdmin can Add ESP-ISK new controller type
		if (!this.authManager.hasDeveloperAccess) {
			types = types.filter(t => t.value !== RbEnums.Common.DeviceType.ARC8);
			types = types.filter(t => t.value !== RbEnums.Common.DeviceType.RC2_4Station);
			types = types.filter(t => t.value !== RbEnums.Common.DeviceType.ESP2Wire);
			types = types.filter(t => t.value !== RbEnums.Common.DeviceType.TM2_4Station);

		}

		// RB-11610: IQ4 - IQI: Limit access to IQI to users other than Developer Access
		if (!this.siteManager.isCommercialHybridSite || !this.authManager.hasDeveloperAccess) {
			types = types.filter(t => t.value !== RbEnums.Common.DeviceType.IQI);
		}

		return types.filter(t => !RbUtils.Controllers.isGolfController(t.value) && !RbUtils.Controllers.isCCUController(t.value));
	}

	/** DO NOT MODIFY THIS METHOD */
	private getControllersListForStatusLookup(clientControllerParentId: number = null) {
		return !clientControllerParentId
			? this._apiResult.value
			: this._apiResult.value.filter(c => c.parentId === clientControllerParentId);
	}

}

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConnectDataPack } from './models/connect-data-pack.model';
import { ConnectDataPackChange } from './models/connect-data-pack-change.model';
import { ConnectDataPacksDict } from './models/connect-data-packs-dict.model';
import { DataPackRequestState } from './models/data-pack-request-state.model';
import { Injectable } from '@angular/core';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { Subject } from 'rxjs';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class ConnectDataPackManagerService {

	// Subjects
	connectDataPacksChange = new Subject<ConnectDataPackChange>();
	cancelAllIrrigationForController = new Subject<{ controllerId: number }>();
	connectDataPacksJobStart = new Subject<{controllerId: number}>();
	connectDataPacksJobFinish = new Subject<{controllerId: number, error?: string}>();
	connectDataPacksDiscrepancy = new Subject<ConnectDataPackChange>();

	// Cache Containers (Non-expiring)
	private connectDataPacksDict: ConnectDataPacksDict = {};
	controllerModuleDiscrepancisDict: ConnectDataPacksDict = {};

	// =========================================================================================================================================================
	// C'tor and Destroy
	// =========================================================================================================================================================

	constructor() {
		this.cancelAllIrrigationForController
			.pipe(untilDestroyed(this))
			.subscribe((controllerInfo: {controllerId: number}) => {
				// RB-15041: IQ4 - LXIVM controller got "reading stopIrrigation" error
				if (this.connectDataPacksDict[controllerInfo.controllerId]) {
					this.connectDataPacksDict[controllerInfo.controllerId].stopIrrigation()
				}
			});
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get connectDataPacks(): ConnectDataPacksDict {
		return this.connectDataPacksDict;
	}

	initOrAddToControllersDictionary(requestState: DataPackRequestState): ConnectDataPacksDict {
		const connectDataPack = this.connectDataPacksDict[requestState.controllerId];

		// If our dictionary already contains an entry for the given controllerId
		if (connectDataPack) {
			connectDataPack.isRetrieving = true;
			connectDataPack.isRetrievingManualOpsState = true;
			connectDataPack.isSynchronizing = requestState.isSynchronizing;
			return;
		}

		const newDataPack = new ConnectDataPack();
		newDataPack.isRetrieving = requestState.isRetrieving;
		newDataPack.isRetrievingManualOpsState = requestState.isRetrieving;
		newDataPack.isSynchronizing = requestState.isSynchronizing;

		this.connectDataPacksDict[requestState.controllerId] = newDataPack;
		this.connectDataPacksChange.next(new ConnectDataPackChange(requestState.controllerId, this.connectDataPacksDict));
	}

	updateConnectDataPack(controllerId: number, newConnectDataPack: ConnectDataPack) {
		newConnectDataPack.timeStamp = new Date();

		const previousConnectDataPack = this.connectDataPacksDict[controllerId];

		if (previousConnectDataPack != null) {
			newConnectDataPack.error = previousConnectDataPack.error;
			newConnectDataPack.isRetrieving = previousConnectDataPack.isRetrieving;
			if (previousConnectDataPack.irrigationQueue != null && newConnectDataPack.irrigationQueue == null) {
				newConnectDataPack.irrigationQueue = previousConnectDataPack.irrigationQueue;
			}
			newConnectDataPack.isRetrievingManualOpsState = false;
		}

		this.connectDataPacksDict[controllerId] = newConnectDataPack;
		this.connectDataPacksChange.next(new ConnectDataPackChange(controllerId, this.connectDataPacksDict));

		/**
		 * RB-9834: As we have a module discrepancy between IQ and the Physical Controller.
		 * User should be informed about the discrepancy when they connect to the controller.
		 * If user navigate away from the page, do not show the error message.
		 * If they return to the page while still connected, show it then.
		*/
		if (newConnectDataPack?.hasModuleDiscrepancies) {
			this.controllerModuleDiscrepancisDict[controllerId] = newConnectDataPack;
			this.connectDataPacksDiscrepancy.next(new ConnectDataPackChange(controllerId, this.connectDataPacksDict));
		}
	}

	deleteConnectDataPack(controllerId: number) {
		delete this.connectDataPacksDict[controllerId];
		this.connectDataPacksChange.next(new ConnectDataPackChange(controllerId, this.connectDataPacksDict));
	}

	updateConnectDataPacksRetrievalState(controllerId: number, isRetrieving: boolean) {
		const connectDataPack = this.connectDataPacksDict[controllerId];

		if (!connectDataPack) return;

		connectDataPack.isRetrieving = isRetrieving;
		connectDataPack.isRetrievingManualOpsState = isRetrieving;
	}

	updateErrorState(error: string, controllerId?: number) {
		// Update state of single controller in the dictionary.
		if (controllerId) {
			const connectDataPack = this.connectDataPacksDict[controllerId];
			if (!connectDataPack) return;

			connectDataPack.error = error;
			connectDataPack.isRetrieving = false;
			connectDataPack.isRetrievingManualOpsState = false;
			return;
		}

		// Update state of all controllers in SyncControllers Dictionary.
		Object.entries(this.connectDataPacksDict).forEach(
			([key, value]) => {
				this.connectDataPacksDict[key].error = error;
				this.connectDataPacksDict[key].isRetrieving = false;
				this.connectDataPacksDict[key].isRetrievingManualOpsState = false;
			}
		);
	}

	setManualStationStartStatus(controllerId: number, stationIndexes: number[]) {
		// Fetch ConnectDataPack for current controller. It should exist.
		const existingDataPack = this.connectDataPacksDict[controllerId];

		// A legit station start request will result in a DataPack being returned by the Irrigation Engine, so we should always have one. Just in case...
		if (existingDataPack === undefined || !existingDataPack.irrigationQueue || existingDataPack.irrigationQueue.length < 1) return;

		// Update the stationState for the manually started stations.
		stationIndexes.forEach((index: number) => {
			if (existingDataPack.irrigationQueue[index]) {
				existingDataPack.irrigationQueue[index].stationState = RbEnums.Common.StationStatus.ManuallyStarted;
			}
		});

		// Alert subscribers of change. This will result in respective Station Status UIs being updated with 'Started' state.
		this.connectDataPacksChange.next(new ConnectDataPackChange(controllerId, this.connectDataPacksDict));
	}

	setManualProgramStartStatus(controllerId: number, programIndexes: number[]) {
		// Fetch ConnectDataPack for current controller. It should exist.
		const existingDataPack = this.connectDataPacksDict[controllerId];

		// A legit station start request will result in a DataPack being returned by the Irrigation Engine, so we should always have one. Just in case...
		if (existingDataPack === undefined || !existingDataPack.irrigationQueue || existingDataPack.irrigationQueue.length < 1) return;

		// Update 'Program Posted' list.
		existingDataPack.addPostedPrograms(programIndexes);

		// Alert subscribers of change. This will result in respective Station Status UIs being updated with 'Started' state.
		this.connectDataPacksChange.next(new ConnectDataPackChange(controllerId, this.connectDataPacksDict));
	}

	setManualStationAdvanceStatus(controllerId: number, stationNumbers: number[]) {
		// Fetch ConnectDataPack for current controller. It should exist.
		const existingDataPack = this.connectDataPacksDict[controllerId];

		// A legit station start request will result in a DataPack being returned by the Irrigation Engine, so we should always have one. Just in case...
		if (existingDataPack === undefined || !existingDataPack.irrigationQueue || existingDataPack.irrigationQueue.length < 1) return;

		// Update the stationState for the manually advanced stations.
		stationNumbers.forEach((stationNumber: number) => {
			const item = existingDataPack.irrigationQueue.find(i => i.stationNumber === stationNumber);
			if (item) item.stationState = RbEnums.Common.StationStatus.ManuallyAdvanced;
		});

		// Alert subscribers of change. This will result in respective Station Status UIs being updated with 'advanced' state.
		this.connectDataPacksChange.next(new ConnectDataPackChange(controllerId, this.connectDataPacksDict));
	}
}

import { DiagnosticData } from './diagnostic-data.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class IcLongAddressPollData extends DiagnosticData {

	satelliteId: number;
	group: number;
	stationId: number;
	sensorId: number;
	result: RbEnums.Common.DiagnosticStateResult;

	constructor(json: any = null) {
		super(json);

		if (json) {
			Object.assign(this, json);
		}

		this.cdType = RbEnums.Common.DiagnosticDataType.ICLongAddress;
	}
}

import * as moment from 'moment';

import { map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { AuthManagerService } from '../auth/auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { GroupLevel } from './models/group-level.model';
import { Injectable } from '@angular/core';
import { PasswordChange } from './models/password-change.model';
import { RbccUser } from './models/rbcc-user.model';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { Snapshot } from '../controllers/models/snapshot';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';
import { User } from 'oidc-client';
import { UserApiService } from './user-api.service';
import { UserEventSubscription } from './models/user-event-subscription.model';
import { UserEventSubscriptionType } from './models/user-event-subscription-type.model';
import { UserInfo } from './models/rbcc-user-info.model';
import { UserListItem } from './models/user-list-item.model';
import { UserSite } from '../sites/models/user-site.model';
import { UserSnapshotListItem } from './models/user-snapshot-list-item.model';

@Injectable({
	providedIn: 'root'
})
export class UserManagerService extends ServiceManagerBase {

	// Subjects
	usersListChange = new Subject<UserListItem[]>();

	// Cache Containers
	private _usersApiResult: ApiCachedRequestResponse<UserListItem[]>;
	private _groupApiResult: ApiCachedRequestResponse<GroupLevel[]>;

	private _userUtcOffset: string;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private authManagerService: AuthManagerService,
				private userApiService: UserApiService,
				protected broadcastService: BroadcastService,
	) {
		super(broadcastService);

		this.initService();
	}

	protected clearCache() {
		this.userApiService.clearCache();
		this._usersApiResult = null;
		this._groupApiResult = null;
	}

	private initService() {
		this.getGroupLevels();
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	createNewUser(user: RbccUser, userSites: UserSite[]) {
		return this.userApiService.createNewUser(user, userSites);
	}

	createIQ4NewUser(user: RbccUser, userSites: UserSite[]) {
		return this.userApiService.createIQ4NewUser(user, userSites);
	}

	deleteUsers(userIds: number[]): Observable<void> {
		return this.userApiService.deleteUsers(userIds)
			.pipe(
				tap(() => {
					// Remove the deleted controller from our collection and let interested parties know.
					this._usersApiResult.value = this._usersApiResult.value.filter(s => userIds.indexOf(s.id) === -1);
					this.usersListChange.next(this._usersApiResult.value);
				})
			);
	}

	getEmailUniqueness(email: string, id: number): Observable<UniquenessResponse> {
		return this.userApiService.getEmailUniqueness(email, id);
	}

	getGroupLevels(): Observable<GroupLevel[]> {
		return this.userApiService.getGroupLevels().pipe(map(response => {
			this._groupApiResult = response;
			return this._groupApiResult.value;
		}));
	}

	getGroupLevel(groupLevelId: number): GroupLevel {
		if (!this._groupApiResult || this._groupApiResult.value.length < 1) return null;
		return this._groupApiResult.value.find(gl => gl.value === groupLevelId);
	}

	getNameUniqueness(name: string, id: number): Observable<UniquenessResponse> {
		return this.userApiService.getNameUniqueness(name, id);
	}

	getUser(userId: number): Observable<RbccUser> {
		return this.userApiService.getUser(userId);
	}

	getUserInfoBySite(siteId: number): Observable<UserInfo[]> {
		return this.userApiService.getUserInfoBySite(siteId);
	}

	getUserDateTime(dateTime: Date):  Observable<Date> {
		return this.userApiService.getUserDateTime(dateTime);
	}
	getUserCurrentDateTime():  Observable<Date> {
		return this.userApiService.getUserCurrentDateTime();
	}
	getUserUtcOffset(dateTime: Date): Observable<string> {
		if (this._userUtcOffset) { return of(this._userUtcOffset); }

		return this.userApiService.getUserUtcOffset(dateTime)
			.pipe(tap(userUtcOffset => this._userUtcOffset = userUtcOffset));
	}

	getUserDateTimeInternal(dateTime: Date): Observable<Date> {
		return this.getUserUtcOffset(dateTime)
			.pipe(map(userUtcOffset => moment.utc(dateTime).utcOffset(userUtcOffset).toDate()));
	}

	getVisibleUsers(bypassCache = false): Observable<UserListItem[]> {
		return this.userApiService.getVisibleUsers(bypassCache).pipe(map(response => {
			this._usersApiResult = response;
			return this._usersApiResult.value;
		}));
	}

	getSnapshotUsers(): Observable<UserSnapshotListItem[]> {
		return this.userApiService.getUsersIncludingDeleted();
	}

	getSnapshots(userId: number): Observable<Snapshot[]> {
		return this.userApiService.getSnapshots(userId);
	}

	restoreSnapshot(userId: number, date: Date, forceRestore: boolean): Observable<null> {
		return this.userApiService.restoreSnapshot(userId, date, forceRestore).pipe(tap(() => {
			this.clearCache();
			this.broadcastService.userRestored.next(null);
		}));
	}

	setNewOwner(userId: number): Observable<void> {
		return this.userApiService.setNewOwner(userId)
			.pipe(tap(() => {
				this.getVisibleUsers(true)
					.pipe(take(1))
					.subscribe((users: UserListItem[]) => this.usersListChange.next(users));
			}));
	}

	updatePassword(passwordChange: PasswordChange): Observable<void> {
		return this.userApiService.updatePassword(passwordChange);
	}

	getCurrentUserPasswordExpirationDate(): Observable<Date> {
		return this.userApiService.getCurrentUserPasswordExpirationDate().pipe(map(response => response.value));
	}

	getCodeToResetPassword(url: string): Observable<string> {
		return this.userApiService.getCodeToResetPassword(url);
	}

	updateUser(userId: number, updateValues: any): Observable<null> {
		return this.userApiService.updateUser(userId, updateValues);
	}

	/**
	 * @summary updateUserPreferences sends the indicated Json patch object to the API. We also do one extra thing
	 * when changing the preferences: refreshing the sign in of the user silently to be sure that, if we changed
	 * his language selection, the token we use has the new language.
	 */
	updateUserPreferences(userId: number, userPreference: any): Observable<User> {
		// After the call is successful, refresh the signin. We don't block until this happens; we just handle it at the right time.
		return this.userApiService.updateUserPreferences(userId, userPreference).pipe(switchMap(() => this.authManagerService.refreshSignIn()));
	}

	getUserEventSubscriptionTypes(): Observable<UserEventSubscriptionType[]> {
		return this.userApiService.getUserEventSubscriptionTypes().pipe(map(response => response.value));
	}

	getUserEventSubscriptions(bypassCache: boolean, userId: number = null): Observable<UserEventSubscription[]> {
		return this.userApiService.getUserEventSubscriptions(userId, bypassCache);
	}

	updateAllUserEventSubscriptions(subscriptions: UserEventSubscription[], userId: number = null): Observable<any> {
		return this.userApiService.updateAllUserEventSubscriptions(subscriptions, userId);
	}

	getUsersSummary() {
		return this.userApiService.getUsersSummary();
	}

}

/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AnimatedToolbarItemInfo } from './detail-panel-toolbar/models/animated-toolbar-item-info.model';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { ControllerManagerService } from '../../../api/controllers/controller-manager.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { DynamicToolbarItemInfo } from './detail-panel-toolbar/models/dynamic-toolbar-item-info.model';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { ToolbarInfo } from './detail-panel-toolbar/models/toolbar-info.model';
import { ToolbarInfoGroup } from './detail-panel-toolbar/models/toolbar-info-group.model';
import { ToolbarItemInfo } from './detail-panel-toolbar/models/toolbar-item-info.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import ToolbarButton = RbEnums.Common.ToolbarButton;
import ManualOpsDiagnosticsView = RbEnums.Common.ManualOpsDiagnosticsView;

export enum ToolbarMobileMenuDisplay {
    collapsed = "collapsed", // display the menu ellipsed list with More button
    overflow = "overflow", // display the actions as the secondary list
}

@UntilDestroy()
@Directive()
export abstract class DynamicToolbarBase implements OnInit, OnDestroy {
	@Input() includeChartView = false;
	@Input() includeListView = false;
	@Input() includeColumnView = false;
	@Input() includeCourseView = false;
	@Input() includeEdit = false;
	@Input() includeTableEdit = false;
	@Input() includeCalculate = false;
	@Input() includeConnect = false;
	@Input() includeControl = false;
	@Input() includeFlowAlarms = false;
	@Input() includeRetrieve = false;
	@Input() includeSync = false;
	@Input() includeReverse = false;
	@Input() includeRefresh = false;
	@Input() includePlay = false;
	@Input() includePause = false;
	@Input() includeResume = false;
	@Input() includeStop = false;
	@Input() includeAdvance = false;
	@Input() includeDelete = false;
	@Input() includeClear = false;
	@Input() canDeleteMultiple = true;
	@Input() canClearMultiple = true;
	@Input() includeReset = false;
	@Input() includeSimulate = false;
	@Input() includeManualSensorRead = false;
	@Input() includeAreasFilter = false;
	@Input() includeHolesFilter = false;
	@Input() includeCancelAll = false;
	@Input() includeUnlockSystem = false;
	@Input() includeExport = false;
	@Input() exportMenu = null;
	@Input() includeWireGroup = false;
	@Input() includeSitesView = false;
	@Input() includeUsersView = false;
	@Input() includeRestore = false;
	@Input() includeSnapshot = false;
	@Input() includeCsvExport = false;
	@Input() includePdfExport = false;
	@Input() includeSchedule = false;
	@Input() overflowMenu: TemplateRef<any> = null;
	@Input() masterValvesMenu: TemplateRef<any> = null;
	@Input() customExtraMenuItems: ToolbarItemInfo[] = [];
	@Input() includeSummary = false;

	// Diagnostics
	@Input() includeStatus = false;
	@Input() includeRasterTest = false;
	@Input() includeTestAll = false;
	@Input() includeShortFinding = false;
	@Input() includeDecoderTest = false;
	@Input() includePingDecoders = false;
	@Input() includeRespondList = false;
	@Input() includePingValvesSensors = false;
	@Input() includeShortReport = false;
	@Input() includeDynamicAction = false;

	// hard update
	@Input() fixedToolbarInfo: ToolbarButton[] = [];
	@Input() fixedExtraToolbarMenuItems: ToolbarButton[];
	@Input() isOverFlowToolbarDisplay = false;

	IconAnimationType = RbEnums.Common.IconAnimationType;
	IndicatorColor = RbEnums.Common.IndicatorColor;
	SyncState = RbEnums.Common.ControllerSyncState;

	private readonly GROUP_PADDING = 4;						// left and right padding for button groups (in pixels).
	private readonly BUTTON_PADDING = 2;					// left and right padding for button, between button border and button text (in pixels).
	private readonly BUTTON_LI_PADDING = 2;					// left and right padding for li surrounding button. This equates to spacing between buttons (in px)
	private readonly MIN_BUTTON_WIDTH = 44;					// (in pixels)
	private readonly GROUP_RIGHT_BORDER_WIDTH = 1;			// Width of right border of group (ul) (in pixels).
	private readonly MOBILE_MIN_BUTTON_WIDTH = 73;			// CSS min-width + border-left width
	private readonly MOBILE_BUTTON_LEFT_BORDER_WIDTH = 1;
	private readonly MOBILE_GROUP_RIGHT_BORDER_WIDTH = 1;

	private _lastToolbarWidth = 0;
	private groupCount = 0;
	private subscriptionNotifier$ = new Subject<void>();

	hasMoreButton = false;
	toolbarInfo: ToolbarInfo;
	toolbarMenuItems: ToolbarItemInfo[] = [];
	sensorExtraMenuItems: ToolbarItemInfo[] = [];
	isMobile = false;
	isGolfSite = false;
	controllerId: number;
	controllerType: number;
	
	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(protected authManager: AuthManagerService,
		protected route: ActivatedRoute,
		protected controllerManager: ControllerManagerService,
		protected deviceManager: DeviceManagerService,
		private isDetailPanelToolbar = false,
	) {
	}

	ngOnInit(): void {
		this.isMobile = this.deviceManager.isMobile;
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);

		// Monitor app resize
		this.deviceManager.isMobileChange
			.pipe(takeUntil(this.subscriptionNotifier$))
			.subscribe((isMobile: boolean) => {
				this.isMobile = isMobile;

				this.toolbarMenuItems = [];
				this.sensorExtraMenuItems = [];
				this.hasMoreButton = false;
				this.buildDynamicToolbar(true);
			});
		if (this.route.snapshot.queryParams.controllerId) this.controllerId = +this.route.snapshot.queryParams.controllerId;
		
		//RB-13741 We get the type to handle wirepath/group
		if (this.controllerId) {
			this.controllerManager.getControllerListItem(this.controllerId)
			.pipe(takeUntil(this.subscriptionNotifier$))
			.subscribe(controller => {
				if(controller.type){
					this.controllerType = controller.type;
				}
			});
		}

		// If user refreshed browser, delay the loading of the toolbar to ensure Translation Service is ready to go.
		if (!this.deviceManager.browserRefreshed)
			this.buildDynamicToolbar();
		else
			setTimeout(() => this.buildDynamicToolbar(), 1000);
	}

	ngOnDestroy(): void {
		this.subscriptionNotifier$.next();
		this.subscriptionNotifier$.complete();
	}

	// =========================================================================================================================================================
	// Abstract Methods to be overridden in derived classes.
	// =========================================================================================================================================================

	abstract get toolbarButtons(): ToolbarButton[];

	/** This is the id of the div that contains the 'desktop' toolbar. This div will be used to determine the available width for the toolbar. */
	abstract get desktopToolbarContainer(): string;

	/** This is the id of the div that contains the 'mobile' toolbar. This div will be used to determine the available width for the toolbar. */
	abstract get mobileToolbarContainer(): string;

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	/** Force redraw (w/o re-init) */
	public redrawToolbar() {
		this.toolbarMenuItems = [];
		this.sensorExtraMenuItems = [];
		this.hasMoreButton = false;
		this.buildDynamicToolbar();
	}

	// =========================================================================================================================================================
	// Virtual Toolbar Click Handlers - to be overridden in derived class if desired.
	// =========================================================================================================================================================
	handleChartViewClick() { this.notImplemented(); }

	handleScheduleViewClick() { this.notImplemented(); }

	handleListViewClick() { this.notImplemented(); }

	handleColumnViewClick() { this.notImplemented(); }

	handleCourseViewClick() { this.notImplemented(); }

	handleEditClick() { this.notImplemented(); }

	handleSummaryClick() { this.notImplemented(); }

	handleTableEditClick() { this.notImplemented(); }

	handleCalculateClick() { this.notImplemented(); }

	handleConnectClick() { this.notImplemented(); }

	handleControlClick() { this.notImplemented(); }

	handleFlowAlarmsClick() { this.notImplemented(); }

	handleRetrieveClick() { this.notImplemented(); }

	handleSyncClick() { this.notImplemented(); }

	handleReverseSyncClick() { this.notImplemented(); }

	handleRefreshClick() { this.notImplemented(); }

	handlePlayClick() { this.notImplemented(); }

	handlePauseClick() { this.notImplemented(); }

	handleStopClick() { this.notImplemented(); }

	handleResumeClick() { this.notImplemented(); }

	handleCancelAllClick() { this.notImplemented(); }

	handleAdvanceClick() { this.notImplemented(); }

	handleDeleteClick() { this.notImplemented(); }

	handleClearClick() { this.notImplemented(); }

	handleHolesFilterClick() { this.notImplemented(); }

	handleUnlockSystemClick() { this.notImplemented(); }

	handleAreasFilterClick() { this.notImplemented(); }

	handleResetClick() { this.notImplemented(); }

	handleSimulateClick() { this.notImplemented(); }

	handleManualSensorReadClick() { this.notImplemented(); }

	handleDiagnosticsViewChange(view: ManualOpsDiagnosticsView) { this.notImplemented(); }

	handleRasterTestClick() { this.notImplemented(); }

	handleTestAllClick() { this.notImplemented(); }

	handleShortFindingClick() { this.notImplemented(); }

	handleDecoderTestClick() { this.notImplemented(); }

	handlePingDecodersClick() { this.notImplemented(); }

	handleDynamicActionClick() { this.notImplemented(); }

	handleExportCSVClick() { this.notImplemented(); }

	handleExportPdfClick() {this.notImplemented();}

	handleSitesViewClick() { this.notImplemented(); }

	handleUsersViewClick() { this.notImplemented(); }

	handleSnapshotClick() { this.notImplemented(); }

	handleRestoreClick() { this.notImplemented(); }

	handleWireGroupClick(selectedGroup: number) { this.notImplemented(); }

	// =========================================================================================================================================================
	// Virtual Methods for enabling/disabling toolbar buttons
	// =========================================================================================================================================================

	isEditDisabled(): boolean {
		return false;
	}

	isSummaryDisabled(): boolean {
		return false;
	}

	isTableEditDisabled(): boolean {
		return false;
	}

	isCalculateDisabled(): boolean { return false; }

	isConnectDisabled(): boolean {
		return false;
	}

	isControlDisabled(): boolean {
		return false;
	}

	isFlowAlarmsDisabled(): boolean {
		return false;
	}

	getFlowAlarmsCount(): number {
		return 0;
	}

	getIconContainerClass(): string {
		return '';
	}

	isRetrieveDisabled(): boolean {
		return false;
	}

	isSyncDisabled(): boolean {
		return false;
	}

	isReverseSyncDisabled(): boolean {
		return false;
	}

	isStartDisabled(): boolean {
		return false;
	}

	isPauseDisabled(): boolean {
		return false;
	}

	isResumeDisabled(): boolean {
		return false;
	}

	isStopDisabled(): boolean {
		return false;
	}

	isCancelAllDisabled(): boolean {
		return false;
	}

	isAdvanceDisabled(): boolean {
		return false;
	}

	isDeleteDisabled(): boolean {
		return false;
	}

	isClearDisabled(): boolean {
		return false;
	}

	isRefreshDisabled(): boolean {
		return false;
	}

	isResetDisabled(): boolean {
		return false;
	}

	isSimulateDisabled(): boolean {
		return false;
	}

	isManualSensorReadDisabled(): boolean {
		return false;
	}

	isUnlockDisabled(): boolean {
		return false;
	}

	isExportDisabled(): boolean {
		return false;
	}

	isRestoreDisabled(): boolean {
		return false;
	}

	isSnapshotDisabled(): boolean {
		return false;
	}

	isRasterTestDisabled(): boolean {
		return false;
	}

	isTestAllDisabled(): boolean {
		return false;
	}

	isDecoderTestDisabled(): boolean {
		return false;
	}

	isDynamicActionDisabled(): boolean {
		return false;
	}

	// =========================================================================================================================================================
	// Virtual Methods for controlling toolbar button animations.
	// =========================================================================================================================================================

	get isConnecting(): boolean {
		return false;
	}

	get isDisconnecting(): boolean {
		return false;
	}

	isGettingLogs(): boolean {
		return false;
	}

	isSyncing(): boolean {
		return false;
	}

	isReverseSyncing(): boolean {
		return false;
	}

	isRefreshing(): boolean {
		return false;
	}

	// =========================================================================================================================================================
	// Virtual Methods for dynamically customizing toolbar buttons.
	// =========================================================================================================================================================
	getNgClass(args: any): any {
		this.notImplemented();
	}

	getConnectIcon(): string {
		return '';
	}

	getConnectLabel(): string {
		return '';
	}

	getStopAllLabel(): string {
		return 'STRINGS.STOP_ALL';
	}

	getConnectNgClass(): {} {
		return {};
	}

	getSyncIconColor(): string {
		return 'white';
	}

	getReverseSyncIconColor(): string {
		return !this.isMobile ? 'white' : 'inherit';
	}

	getSyncStateIcon(): boolean {
		return false;
	}

	getChartViewIconColor(): string {
		return 'white';
	}

	getScheduleViewIconColor(): string {
		return 'white';
	}

	getListViewIconColor(): string {
		return 'white';
	}

	getColumnViewIconColor(): string {
		return 'white';
	}

	getCourseViewIconColor(): string {
		return 'white';
	}

	getSitesViewIconColor(): string {
		return 'white';
	}

	getUsersViewIconColor(): string {
		return 'white';
	}

	getStatusIconColor(): string {
		return 'white';
	}

	getShorFindingIconColor(): string {
		return 'white';
	}

	getPingDecodersIconColor(): string {
		return 'white';
	}

	getRespondListIconColor(): string {
		return 'white';
	}

	getPingValvesSensorsIconColor(): string {
		return 'unset';
	}

	getShorReportIconColor(): string {
		return 'unset';
	}

	getDiagnosticsGroupNgStyle(): {} {
		return {};
	}

	getDynamicActionIcon(): string {
		return 'play_arrow';
	}

	getDynamicActionLabel(): string {
		return 'STRINGS.START';
	}

	getDynamicActionCustomIconNumber(): number {
		return RbConstants.Form.CUSTOM_ICON_NUMBER.standard;
	}

	protected buildSyncButton(isProblem: boolean) {
		return new AnimatedToolbarItemInfo(
			'Sync',
			'STRINGS.SYNC',
			isProblem ? 'sync_problem': 'sync',
			this.handleSyncClick.bind(this),
			this.isSyncDisabled.bind(this),
			this.IconAnimationType.SpinCounterclockwise,
			this.isSyncing.bind(this),
			this.getSyncIconColor.bind(this))
		;
	}

	protected buildConnectButton(isConnecting: boolean) {
		return new DynamicToolbarItemInfo(
		'Connect',
		null,
		this.getConnectIcon.bind(this),
		this.getConnectLabel.bind(this),
		'manual_control_button',
		this.handleConnectClick.bind(this),
		this.isConnectDisabled.bind(this),
		this.getConnectNgClass.bind(this),
		RbConstants.Form.CUSTOM_ICON_NUMBER.standard,
		null,
		isConnecting ? this.IconAnimationType.ControllerConnecting : this.IconAnimationType.ControllerDisconnecting,
		() => {
			return isConnecting ? this.isConnecting : this.isDisconnecting
		}
		);
	}
	// =========================================================================================================================================================
	// Dynamic Toolbar Construction Methods
	// =========================================================================================================================================================

	/** Method to dynamically build toolbar and 'more menu.' */
	protected buildDynamicToolbar(isResizingApp = false) {
		this.toolbarMenuItems = [];
		this.sensorExtraMenuItems = []; // RB-12269
		const toolbarContainerWidth = this.getToolbarContainerWidth();

		// When switching between desktop and mobile, the left sidebar toolbar container may not be 'visible' and/or fully sized yet.
		// If that is the case, loop until it is.
		if (!isResizingApp && (toolbarContainerWidth === null || toolbarContainerWidth === 0 || toolbarContainerWidth !== this._lastToolbarWidth)) {
			this._lastToolbarWidth = toolbarContainerWidth;
			setTimeout(() => this.buildDynamicToolbar(), 25);
			return;
		}

		this._lastToolbarWidth = toolbarContainerWidth;
		const widthOfToolbarElements = this.getWidthOfToolbarElements();

		this.hasMoreButton = !this.isOverFlowToolbarDisplay && (this.overflowMenu !== null || this.exportMenu !== null);

		// If the width of the individual toolbar elements is greater than the toolbar container, we're going to need a more button/menu.
		if (!this.hasMoreButton && widthOfToolbarElements > toolbarContainerWidth && !this.isOverFlowToolbarDisplay) {
			this.hasMoreButton = true;
		}

		if (this.fixedToolbarInfo?.length) {
			this.setUpFixedToolbar();
		} else {
			this.setUpToolbar(toolbarContainerWidth);
		}

		if (this.fixedExtraToolbarMenuItems?.length) {
			this.setUpFixedToolbarMenuItems();
		}

		if (this.customExtraMenuItems?.length) {
			this.sensorExtraMenuItems = [...this.sensorExtraMenuItems, ...this.customExtraMenuItems];
		}
	}

	private setUpFixedToolbarMenuItems() {
		this.toolbarMenuItems = [];
		this.fixedExtraToolbarMenuItems.forEach(btn =>
			{
				const toolbarItemInfo = this.getToolbarItemInfo(btn);
				if (!toolbarItemInfo) return;
				this.toolbarMenuItems.push(toolbarItemInfo);
			}
		)
	}

	private setUpFixedToolbar() {
		this.toolbarInfo = new ToolbarInfo();
		const customGroup = new ToolbarInfoGroup();
		this.fixedToolbarInfo.forEach(btn => {
			const toolbarItemInfo = this.getToolbarItemInfo(btn);
			if (!toolbarItemInfo) return;
			customGroup.buttons.push(toolbarItemInfo);
		});

		this.toolbarInfo.groups.push(customGroup);
	}

	private setUpToolbar(toolbarContainerWidth: number) {
		const canvasContext = this.getCanvasContext();
		const maxWidth = !this.hasMoreButton ? toolbarContainerWidth : toolbarContainerWidth - this.getWidthOfMoreGroup(canvasContext);
		let toolbarWidth = 0;
		let buttonWidths = 0;
		let viewGroup: ToolbarInfoGroup;
		let editGroup: ToolbarInfoGroup;
		let syncGroup: ToolbarInfoGroup;
		let startGroup: ToolbarInfoGroup;
		let filterGroup: ToolbarInfoGroup;
		let exportGroup: ToolbarInfoGroup;
		let diagnosticsGroup: ToolbarInfoGroup;
		let dynamicActionsGroup: ToolbarInfoGroup;
		let wireGroupGroup: ToolbarInfoGroup;

		this.toolbarButtons.forEach(btn => {
			const toolbarItemInfo = this.getToolbarItemInfo(btn);
			if (!toolbarItemInfo) return;

			buttonWidths += this.getButtonWidth(canvasContext, toolbarItemInfo.translatedLabel);
			toolbarWidth = buttonWidths + this.getGroupWidth(this.groupCount);

			if (toolbarWidth <= maxWidth
				&& btn !== ToolbarButton.Delete
				&& btn !== ToolbarButton.Reset
				&& btn !== ToolbarButton.Simulate
				&& btn !== ToolbarButton.ManualSensorRead) {

				//
				// Add Toolbar Button
				//
				switch (btn) {
					case ToolbarButton.ChartView:
					case ToolbarButton.ScheduleView:
					case ToolbarButton.ListView:
					case ToolbarButton.CourseView:
					case ToolbarButton.ColumnView:
						if (!viewGroup) viewGroup = new ToolbarInfoGroup();
						viewGroup.buttons.push(toolbarItemInfo);
						break;

					case ToolbarButton.Clear:
					case ToolbarButton.Edit:
					case ToolbarButton.Connect:
					case ToolbarButton.Control:
					case ToolbarButton.FlowAlarms:
					case ToolbarButton.TableEdit:
					case ToolbarButton.UnlockSystem:
					case ToolbarButton.Summary:
						if (!editGroup) editGroup = new ToolbarInfoGroup();
						editGroup.buttons.push(toolbarItemInfo);
						break;

					case ToolbarButton.RetrieveLogs:
					case ToolbarButton.Sync:
					case ToolbarButton.ReverseSync:
					case ToolbarButton.Refresh:
						if (!syncGroup) syncGroup = new ToolbarInfoGroup();
						syncGroup.buttons.push(toolbarItemInfo);
						break;

					case ToolbarButton.Calculate:
					case ToolbarButton.Start:
					case ToolbarButton.Pause:
					case ToolbarButton.Stop:
					case ToolbarButton.Resume:
					case ToolbarButton.Advance:
					case ToolbarButton.CancelAll:
					case ToolbarButton.SitesView:
					case ToolbarButton.UsersView:
					case ToolbarButton.Snapshot:
					case ToolbarButton.Restore:
						if (!startGroup) startGroup = new ToolbarInfoGroup();
						startGroup.buttons.push(toolbarItemInfo);
						break;

					case ToolbarButton.HolesFilter:
					case ToolbarButton.AreasFilter:
						if (!filterGroup) filterGroup = new ToolbarInfoGroup();
						filterGroup.buttons.push(toolbarItemInfo);
						break;
					case ToolbarButton.Export:
						if (!exportGroup) exportGroup = new ToolbarInfoGroup();
						exportGroup.buttons.push(toolbarItemInfo);
						break;
					case ToolbarButton.Status:
					case ToolbarButton.RasterTest:
					case ToolbarButton.TestAll:
					case ToolbarButton.ShortFinding:
					case ToolbarButton.DecoderTest:
					case ToolbarButton.PingDecoders:
					case ToolbarButton.RespondList:
					case ToolbarButton.PingValvesSensors:
					case ToolbarButton.ShortReport:
						if (!diagnosticsGroup) { diagnosticsGroup = new ToolbarInfoGroup(); }
						diagnosticsGroup.buttons.push(toolbarItemInfo);
						break;

					case ToolbarButton.DynamicAction:
						if (!dynamicActionsGroup) { dynamicActionsGroup = new ToolbarInfoGroup(); }
						dynamicActionsGroup.buttons.push(toolbarItemInfo);
						break;
					case ToolbarButton.WireGroup1:
					case ToolbarButton.WireGroup2:
					case ToolbarButton.WireGroup3:
					case ToolbarButton.WireGroup4:
						if (!wireGroupGroup)
							wireGroupGroup = new ToolbarInfoGroup();
						wireGroupGroup.buttons.push(toolbarItemInfo);
						break;
					case ToolbarButton.pdfExport:
					case ToolbarButton.csvExport:
						if (this.deviceManager.isMobile) {
							if (!dynamicActionsGroup) { dynamicActionsGroup = new ToolbarInfoGroup(); }
							dynamicActionsGroup.buttons.push(toolbarItemInfo);
						}
						break;
				}
			} else {
				//
				// Add More Menu Item
				//
				if (btn === ToolbarButton.Reset || btn === ToolbarButton.Simulate || btn === ToolbarButton.ManualSensorRead) {
					this.sensorExtraMenuItems.push(toolbarItemInfo);
				} else {
					this.toolbarMenuItems.push(toolbarItemInfo);
				}

				// Ensure we are adding a more button.
				if (!this.hasMoreButton) { this.hasMoreButton = true; }
			}
		});

		this.toolbarInfo = new ToolbarInfo();
		if (viewGroup) this.toolbarInfo.groups.push(viewGroup);
		if (editGroup) this.toolbarInfo.groups.push(editGroup);
		if (wireGroupGroup) this.toolbarInfo.groups.push(wireGroupGroup);
		if (syncGroup) this.toolbarInfo.groups.push(syncGroup);
		if (startGroup) this.toolbarInfo.groups.push(startGroup);
		if (filterGroup) this.toolbarInfo.groups.push(filterGroup);
		if (exportGroup) this.toolbarInfo.groups.push(exportGroup);
		if (diagnosticsGroup) this.toolbarInfo.groups.push(diagnosticsGroup);
		if (dynamicActionsGroup) this.toolbarInfo.groups.push(dynamicActionsGroup);
	}

	/** Method to retrieve toolbar container width for both mobile and desktop. This tells us the maximum width available for our toolbar. */
	private getToolbarContainerWidth(): number {
		if (!this.isMobile) {
			const toolbarContainer = document.getElementById(this.desktopToolbarContainer);

			if (!toolbarContainer) {
				return null;
			}

			let filterWidth = 0;

			// Account for "non dynamic' addition of holes and areas filter (golf) on main panel toolbar - If one or both exists.
			if (!this.isDetailPanelToolbar) {
				const holesFilter = document.getElementById('mpt-holes-filter');
				const areasFilter = document.getElementById('mpt-areas-filter');

				if (holesFilter) {
					filterWidth += holesFilter.getBoundingClientRect().width;
				}
				if (areasFilter) {
					filterWidth += areasFilter.getBoundingClientRect().width;
				}
			}

			return toolbarContainer.getBoundingClientRect().width - filterWidth;
		}

		const mobileLeftPaneToolbarContainer = document.getElementById(this.mobileToolbarContainer);
		return (mobileLeftPaneToolbarContainer) ? mobileLeftPaneToolbarContainer.getBoundingClientRect().width : null;
	}

	/** Method to calculate total width of requested toolbar elements. This will be used to determine
	 *  if all requested elements will fit within the toolbar container or if we need a 'more menu.' */
	private getWidthOfToolbarElements() {
		const canvasContext = this.getCanvasContext();
		let toolbarWidth = 0;
		let buttonWidths = 0;
		let hasViewGroup = false;
		let hasEditGroup = false;
		let hasSyncGroup = false;
		let hasStartGroup = false;
		let hasDeleteGroup = false;
		let hasFilterGroup = false;
		let hasDiagnosticsGroup = false;
		let hasDynamicActionsGroup = false;
		let hasWireGroup = false;

		this.groupCount = this.hasMoreButton ? 1 : 0;

		this.toolbarButtons.forEach(btn => {
			const toolbarItemInfo = this.getToolbarItemInfo(btn);
			if (!toolbarItemInfo) return;

			buttonWidths += this.getButtonWidth(canvasContext, toolbarItemInfo.translatedLabel);
			toolbarWidth = buttonWidths + this.getGroupWidth(this.groupCount);

			switch (btn) {
				case ToolbarButton.ChartView:
				case ToolbarButton.ListView:
				case ToolbarButton.CourseView:
					if (!hasViewGroup) {
						hasViewGroup = true;
						this.groupCount++;
					}
					break;

				case ToolbarButton.Edit:
				case ToolbarButton.TableEdit:
				case ToolbarButton.Clear:
				case ToolbarButton.Connect:
				case ToolbarButton.UnlockSystem:
					if (!hasEditGroup) {
						hasEditGroup = true;
						this.groupCount++;
					}
					break;
				case ToolbarButton.RetrieveLogs:
				case ToolbarButton.Sync:
				case ToolbarButton.ReverseSync:
				case ToolbarButton.Refresh:
					if (!hasSyncGroup) {
						hasSyncGroup = true;
						this.groupCount++;
					}
					break;
				case ToolbarButton.Start:
				case ToolbarButton.Reset:
				case ToolbarButton.Pause:
				case ToolbarButton.Stop:
				case ToolbarButton.Resume:
				case ToolbarButton.Advance:
				case ToolbarButton.CancelAll:
					if (!hasStartGroup) {
						hasStartGroup = true;
						this.groupCount++;
					}
					break;

				case ToolbarButton.Delete:
					if (!hasDeleteGroup) {
						hasDeleteGroup = true;
						this.groupCount++;
					}
					break;

				case ToolbarButton.HolesFilter:
				case ToolbarButton.AreasFilter:
					if (!hasFilterGroup) {
						hasFilterGroup = true;
						this.groupCount++;
					}
					break;

				case ToolbarButton.Status:
				case ToolbarButton.RasterTest:
				case ToolbarButton.TestAll:
				case ToolbarButton.ShortFinding:
				case ToolbarButton.DecoderTest:
				case ToolbarButton.PingDecoders:
				case ToolbarButton.RespondList:
				case ToolbarButton.PingValvesSensors:
				case ToolbarButton.ShortReport:
					if (!hasDiagnosticsGroup) {
						hasDiagnosticsGroup = true;
						this.groupCount++;
					}
					break;

				case ToolbarButton.DynamicAction:
					if (!hasDynamicActionsGroup) {
						hasDynamicActionsGroup = true;
						this.groupCount++;
					}
					break;
				case ToolbarButton.WireGroup1:
				case ToolbarButton.WireGroup2:
				case ToolbarButton.WireGroup3:
				case ToolbarButton.WireGroup4:
					if (!hasWireGroup) {
						hasWireGroup = true;
						this.groupCount++;
					}
					break;
			}
		});

		return toolbarWidth;
	}

	/** Method to retrieve the 'scratchpad' canvas element to enable us to draw text and grab its exact width.  */
	private getCanvasContext() {
		const canvas = <HTMLCanvasElement>document.getElementById('scratchPad');
		const ctx = canvas.getContext('2d');
		ctx.font = '10px lato, sans-serif';
		return ctx;
	}

	/** Method to calculate a toolbar button's width based on its text and whether it is for mobile or desktop. */
	private getButtonWidth(ctx: CanvasRenderingContext2D, text: string) {
		let buttonWidth;

		if (!this.isMobile) {
			// RB-6309: NEVER, NEVER, NEVER use toLowerCase() for all languages!
			buttonWidth = ctx.measureText(text).width + (this.BUTTON_PADDING * 2) + (this.BUTTON_LI_PADDING * 2);
			return buttonWidth >= this.MIN_BUTTON_WIDTH ? buttonWidth : this.MIN_BUTTON_WIDTH;
		}

		// RB-6309: NEVER, NEVER, NEVER use toLowerCase() for all languages!
		buttonWidth = ctx.measureText(text).width + (this.BUTTON_PADDING * 2) + (this.BUTTON_LI_PADDING * 2) + this.MOBILE_BUTTON_LEFT_BORDER_WIDTH;
		return buttonWidth >= this.MOBILE_MIN_BUTTON_WIDTH ? buttonWidth : this.MOBILE_MIN_BUTTON_WIDTH;
	}

	/** Method to calculate a toolbar group's width based on whether it is for mobile or desktop. */
	private getGroupWidth(groupCount: number) {
		if (!this.isMobile) {
			return (groupCount * 2 - 2) * this.GROUP_PADDING + ((groupCount - 1) * this.GROUP_RIGHT_BORDER_WIDTH);
		}

		return this.MOBILE_GROUP_RIGHT_BORDER_WIDTH;	// Only last group has right border
	}

	private getWidthOfMoreGroup(ctx: CanvasRenderingContext2D) {
		return this.getButtonWidth(ctx, RbUtils.Translate.instant('STRINGS.MORE')) + this.GROUP_PADDING;
	}

	private getToolbarItemInfo(toolbarButton: ToolbarButton) {
		switch (toolbarButton) {
			case ToolbarButton.ChartView:
				return !this.includeChartView ? null : new ToolbarItemInfo(
					'ChartView',
					'STRINGS.CHART',
					'equalizer',
					this.handleChartViewClick.bind(this),
					null, RbConstants.Form.CUSTOM_ICON_NUMBER.standard, null, null,
					this.getChartViewIconColor.bind(this));
			case ToolbarButton.ScheduleView:
				return !this.includeSchedule ? null : new ToolbarItemInfo(
					'ScheduleView',
					'STRINGS.SCHEDULE_NOUN',
					'icon-pending_actions',
					this.handleScheduleViewClick.bind(this),
					null, RbConstants.Form.CUSTOM_ICON_NUMBER.pending_actions, null, null,
					this.getScheduleViewIconColor.bind(this));
			case ToolbarButton.ListView:
				return !this.includeListView ? null : new ToolbarItemInfo(
					'ListView',
					'STRINGS.LIST',
					'format_list_bulleted',
					this.handleListViewClick.bind(this),
					null, RbConstants.Form.CUSTOM_ICON_NUMBER.standard, null, null,
					this.getListViewIconColor.bind(this));
			case ToolbarButton.ColumnView:
				return !this.includeColumnView ? null : new ToolbarItemInfo(
					'ColumnView',
					'STRINGS.COLUMN',
					'view_column',
					this.handleColumnViewClick.bind(this),
					null, RbConstants.Form.CUSTOM_ICON_NUMBER.standard, null, null,
					this.getColumnViewIconColor.bind(this));

			case ToolbarButton.CourseView:
				return !this.includeCourseView ? null : new ToolbarItemInfo(
					'CourseView',
					'STRINGS.COURSE',
					'golf_course',
					this.handleCourseViewClick.bind(this),
					null, RbConstants.Form.CUSTOM_ICON_NUMBER.standard, null, null,
					this.getCourseViewIconColor.bind(this));

			case ToolbarButton.Edit:
				return !this.includeEdit ? null : new ToolbarItemInfo(
					'Edit',
					'STRINGS.EDIT',
					'edit',
					this.handleEditClick.bind(this),
					this.isEditDisabled.bind(this));

			case ToolbarButton.Summary:
				return !this.includeSummary ? null : new ToolbarItemInfo(
					'summary',
					'STRINGS.SUMMARY',
					'launch',
					() => this.handleSummaryClick(),
					() => this.isSummaryDisabled());

			case ToolbarButton.TableEdit:
				return !this.includeTableEdit ? null : new ToolbarItemInfo(
					'QuickEdit',
					'STRINGS.TABLE_EDIT',
					'grid_on',
					this.handleTableEditClick.bind(this),
					this.isTableEditDisabled.bind(this));

			case ToolbarButton.Calculate:
				return !this.includeCalculate ? null : new ToolbarItemInfo(
					'Calculate',
					'STRINGS.CALCULATE',
					'schedule',
					this.handleCalculateClick.bind(this),
					this.isCalculateDisabled.bind(this));

			case ToolbarButton.Connect:
				return !this.includeConnect || this.isGolfSite ? null : this.buildConnectButton(this.isConnecting);

			case ToolbarButton.Control:
				return !this.includeControl ? null : new ToolbarItemInfo(
					'Control',
					'STRINGS.SETTINGS',
					'icon-settings',
					this.handleControlClick.bind(this),
					this.isControlDisabled.bind(this),
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons);

			case ToolbarButton.FlowAlarms:
				return !this.includeFlowAlarms ? null : new ToolbarItemInfo(
					'FlowAlarms',
					'STRINGS.FLOW_ALARMS',
					'notifications',
					this.handleFlowAlarmsClick.bind(this),
					this.isFlowAlarmsDisabled.bind(this),
					RbConstants.Form.CUSTOM_ICON_NUMBER.standard,
					null, null, null, null, null, null, null,
					this.getFlowAlarmsCount.bind(this),
					this.getIconContainerClass.bind(this)
				);

			case ToolbarButton.RetrieveLogs:
				return !this.includeRetrieve || this.isGolfSite ? null : new AnimatedToolbarItemInfo(
					'Logs',
					'STRINGS.LOGS',
					'vertical_align_bottom',
					this.handleRetrieveClick.bind(this),
					this.isRetrieveDisabled.bind(this),
					this.IconAnimationType.RotateY,
					this.isGettingLogs.bind(this));

			case ToolbarButton.Sync:
				return !this.includeSync || this.isGolfSite ? null : this.buildSyncButton(this.getSyncStateIcon());
			case ToolbarButton.ReverseSync:
				return !this.includeReverse || this.isGolfSite ? null : new AnimatedToolbarItemInfo(
					'RevSync',
					'STRINGS.REVERSE',
					'swap_vert',
					this.handleReverseSyncClick.bind(this),
					this.isReverseSyncDisabled.bind(this),
					this.IconAnimationType.RotateY,
					this.isReverseSyncing.bind(this),
					this.getReverseSyncIconColor.bind(this));

			case ToolbarButton.Refresh:
				return !this.includeRefresh ? null : new AnimatedToolbarItemInfo(
					'Refresh',
					'STRINGS.REFRESH',
					'refresh',
					this.handleRefreshClick.bind(this),
					this.isRefreshDisabled.bind(this),
					this.IconAnimationType.Spin,
					this.isRefreshing.bind(this),
					null);

			case ToolbarButton.Start:
				return !this.includePlay ? null : new ToolbarItemInfo(
					'Start',
					'STRINGS.START',
					'play_arrow',
					this.handlePlayClick.bind(this),
					this.isStartDisabled.bind(this));

			case ToolbarButton.Pause:
				return !this.includePause ? null : new ToolbarItemInfo(
					'Pause',
					'STRINGS.PAUSE',
					'pause',
					this.handlePauseClick.bind(this),
					this.isPauseDisabled.bind(this));

			case ToolbarButton.Resume:
				return !this.includeResume ? null : new ToolbarItemInfo(
					'Resume',
					'STRINGS.RESUME',
					'resume',
					this.handleResumeClick.bind(this),
					this.isResumeDisabled.bind(this),
					RbConstants.Form.CUSTOM_ICON_NUMBER.resume);

			case ToolbarButton.Stop:
				return !this.includeStop ? null : new ToolbarItemInfo(
					'Stop',
					'STRINGS.STOP',
					'stop',
					this.handleStopClick.bind(this),
					this.isStopDisabled.bind(this));

			case ToolbarButton.CancelAll:
				return !this.includeCancelAll ? null : new ToolbarItemInfo(
					'CancelAll',
					null,
					'stop',
					this.handleCancelAllClick.bind(this),
					this.isCancelAllDisabled.bind(this),
					RbConstants.Form.CUSTOM_ICON_NUMBER.standard,
					null, null, null, null, null,
					this.getStopAllLabel.bind(this));

			case ToolbarButton.Advance:
				return !this.includeAdvance ? null : new ToolbarItemInfo(
					'Advance',
					'STRINGS.ADVANCE',
					'fast_forward',
					this.handleAdvanceClick.bind(this),
					this.isAdvanceDisabled.bind(this));

			case ToolbarButton.Delete:
				return !this.includeDelete ? null : new ToolbarItemInfo(
					'Delete',
					'STRINGS.DELETE',
					'delete',
					this.handleDeleteClick.bind(this),
					this.isDeleteDisabled.bind(this, this.canDeleteMultiple));

			case ToolbarButton.Clear:
				return !this.includeClear ? null : new ToolbarItemInfo(
					'Clear',
					'STRINGS.CLEAR',
					'clear',
					this.handleClearClick.bind(this),
					this.isClearDisabled.bind(this, this.canClearMultiple));

			case ToolbarButton.Reset:
				return !this.includeReset ? null : new ToolbarItemInfo(
					'Reset',
					'STRINGS.RESET',
					'low_priority',
					this.handleResetClick.bind(this),
					this.isResetDisabled.bind(this));

			case ToolbarButton.Simulate:
				return !this.includeSimulate ? null : new ToolbarItemInfo(
					'Simulate',
					'STRINGS.SIMULATE',
					'calculate',
					this.handleSimulateClick.bind(this),
					this.isSimulateDisabled.bind(this),
					RbConstants.Form.CUSTOM_ICON_NUMBER.simulate);

			case ToolbarButton.ManualSensorRead:
				return !this.includeManualSensorRead ? null : new ToolbarItemInfo(
					'ManualSensorRead',
					'STRINGS.MANUAL_SENSOR_READ',
					'binoculars',
					this.handleManualSensorReadClick.bind(this),
					this.isManualSensorReadDisabled.bind(this),
					RbConstants.Form.CUSTOM_ICON_NUMBER.manual_sensor_read);

			case ToolbarButton.HolesFilter:
				return !this.includeHolesFilter || !this.isMobile ? null : new ToolbarItemInfo(
					'Holes',
					'STRINGS.HOLES',
					'arrow_drop_down',
					this.handleHolesFilterClick.bind(this));

			case ToolbarButton.AreasFilter:
				return !this.includeAreasFilter || !this.isMobile ? null : new ToolbarItemInfo(
					'Areas',
					'STRINGS.AREAS',
					'arrow_drop_down',
					this.handleAreasFilterClick.bind(this));

			case ToolbarButton.UnlockSystem:
				return !this.includeUnlockSystem ? null : new ToolbarItemInfo(
					'Unlock System',
					'STRINGS.UNLOCK_SYSTEM',
					'lock_open',
					this.handleUnlockSystemClick.bind(this),
					this.isUnlockDisabled.bind(this));

			case ToolbarButton.Export:
				return this.isGolfSite || !this.includeExport ? null : new ToolbarItemInfo(
					'ExportCSV',
					'STRINGS.EXPORT',
					'launch',
					this.handleExportCSVClick.bind(this),
					this.isExportDisabled.bind(this));
			case ToolbarButton.pdfExport:
				return !this.includePdfExport ? null : new ToolbarItemInfo(
					'ExportCSV',
					'STRINGS.VIEW_PDF',
					'picture_as_pdf',
					this.handleExportPdfClick.bind(this));

			case ToolbarButton.csvExport:
				return !this.includeCsvExport ? null : new ToolbarItemInfo(
					'ExportCSV',
					'STRINGS.EXPORT_TO_CSV',
					'launch',
					this.handleExportCSVClick.bind(this))

			case ToolbarButton.SitesView:
				return !this.includeSitesView ? null : new ToolbarItemInfo(
					'Sites',
					'STRINGS.SITES',
					'camera',
					this.handleSitesViewClick.bind(this),
					null, RbConstants.Form.CUSTOM_ICON_NUMBER.standard, null, null,
					this.getSitesViewIconColor.bind(this));

			case ToolbarButton.UsersView:
				return !this.includeUsersView ? null : new ToolbarItemInfo(
					'Users',
					'STRINGS.USERS',
					'group',
					this.handleUsersViewClick.bind(this),
					null, RbConstants.Form.CUSTOM_ICON_NUMBER.standard, null, null,
					this.getUsersViewIconColor.bind(this));

			case ToolbarButton.Snapshot:
				return !this.includeSnapshot ? null : new ToolbarItemInfo(
					'Snapshot',
					'STRINGS.SNAPSHOT',
					'camera',
					this.handleSnapshotClick.bind(this),
					this.isSnapshotDisabled.bind(this));

			case ToolbarButton.Restore:
				return !this.includeRestore ? null : new ToolbarItemInfo(
					'Restore',
					'STRINGS.RESTORE',
					'restore',
					this.handleRestoreClick.bind(this),
					this.isRestoreDisabled.bind(this));

			case ToolbarButton.Status:
				return !this.includeStatus ? null : new ToolbarItemInfo(
					'diagnostics status',
					'STRINGS.STATUS',
					'icon-status',
					this.handleDiagnosticsViewChange.bind(this, ManualOpsDiagnosticsView.Status),
					null,
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons,
					null, null,
					this.getStatusIconColor.bind(this),
				);

			case ToolbarButton.RasterTest:
				return !this.includeRasterTest ? null : new ToolbarItemInfo(
					'diagnostics raster test',
					'STRINGS.RASTER_TEST',
					'icon-raster-test',
					this.handleRasterTestClick.bind(this),
					this.isRasterTestDisabled.bind(this),
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons,
				);

			case ToolbarButton.TestAll:
				return !this.includeTestAll ? null : new ToolbarItemInfo(
					'diagnostics status',
					'STRINGS.TEST_ALL',
					'icon-test-all',
					this.handleTestAllClick.bind(this),
					this.isTestAllDisabled.bind(this),
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons,
				);

			case ToolbarButton.ShortFinding:
				return !this.includeShortFinding ? null : new ToolbarItemInfo(
					'diagnostics short finding',
					'STRINGS.SHORT_FINDING',
					'icon-short-finding',
					this.handleDiagnosticsViewChange.bind(this, ManualOpsDiagnosticsView.ShortFinding),
					null,
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons,
					null, null,
					this.getShorFindingIconColor.bind(this)
				);

			case ToolbarButton.DecoderTest:
				return !this.includeDecoderTest ? null : new ToolbarItemInfo(
					'diagnostics decoder test',
					'STRINGS.DECODER_TEST',
					'icon-decoder-test',
					this.handleDecoderTestClick.bind(this),
					this.isDecoderTestDisabled.bind(this),
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons
				);

			case ToolbarButton.PingDecoders:
				return !this.includePingDecoders ? null : new ToolbarItemInfo(
					'diagnostics ping decoders',
					'STRINGS.PING_DECODERS',
					'icon-ping-decoders',
					this.handleDiagnosticsViewChange.bind(this, ManualOpsDiagnosticsView.PingDecoders),
					null,
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons,
					null, null,
					this.getPingDecodersIconColor.bind(this)
				);

			case ToolbarButton.RespondList:
				return !this.includeRespondList ? null : new ToolbarItemInfo(
					'diagnostics respond list',
					'STRINGS.DEVICE_STATUS',
					'icon-list-responding',
					this.handleDiagnosticsViewChange.bind(this, ManualOpsDiagnosticsView.ResponseList),
					null,
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons,
					null, null,
					this.getRespondListIconColor.bind(this)
				);

			case ToolbarButton.PingValvesSensors:
				return !this.includePingValvesSensors ? null : new ToolbarItemInfo(
					'diagnostics ping valves sensors',
					'STRINGS.PING_TEST',
					'icon-ping-valves',
					this.handleDiagnosticsViewChange.bind(this, ManualOpsDiagnosticsView.PingValvesSensors),
					null,
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons,
					null, null,
					this.getPingValvesSensorsIconColor.bind(this)
				);

			case ToolbarButton.ShortReport:
				return !this.includeShortReport ? null : new ToolbarItemInfo(
					'diagnostics short Report',
					'STRINGS.SHORT_REPORT',
					'icon-test-shorted-paths',
					this.handleDiagnosticsViewChange.bind(this, ManualOpsDiagnosticsView.ShortReport),
					null,
					RbConstants.Form.CUSTOM_ICON_NUMBER.rb_icons,
					null, null,
					this.getShorReportIconColor.bind(this)
				);

			case ToolbarButton.DynamicAction:
				return !this.includeDynamicAction ? null : new DynamicToolbarItemInfo(
					'diagnostics action',
					null,
					this.getDynamicActionIcon.bind(this),
					this.getDynamicActionLabel.bind(this),
					null,
					this.handleDynamicActionClick.bind(this),
					this.isDynamicActionDisabled.bind(this),
					null, null,
					this.getDynamicActionCustomIconNumber.bind(this)
				);

			case ToolbarButton.WireGroup1:
				return !this.includeWireGroup ? null : new ToolbarItemInfo(
					'wire group 1',
					RbUtils.Controllers.getInterfaceWireGroupName(this.controllerType, 1),
					'group_work',
					this.handleWireGroupClick.bind(this, RbEnums.Common.WireGroupOptions.Group1),
					null, undefined, null, null, null, null, null, null,
					this.getNgClass.bind(this, RbEnums.Common.WireGroupOptions.Group1)
				);
				
			case ToolbarButton.WireGroup2:
				return !this.includeWireGroup ? null : new ToolbarItemInfo(
					'wire group 2',
					RbUtils.Controllers.getInterfaceWireGroupName(this.controllerType, 2),
					'group_work',
					this.handleWireGroupClick.bind(this, RbEnums.Common.WireGroupOptions.Group2),
					null, undefined, null, null, null, null, null, null,
					this.getNgClass.bind(this, RbEnums.Common.WireGroupOptions.Group2)
				);
				
			case ToolbarButton.WireGroup3:
				return !this.includeWireGroup ? null : new ToolbarItemInfo(
					'wire group 3',
					RbUtils.Controllers.getInterfaceWireGroupName(this.controllerType, 3),
					'group_work',
					this.handleWireGroupClick.bind(this, RbEnums.Common.WireGroupOptions.Group3),
					null, undefined, null, null, null, null, null, null,
					this.getNgClass.bind(this, RbEnums.Common.WireGroupOptions.Group3)
				);
				
			case ToolbarButton.WireGroup4:
				return !this.includeWireGroup ? null : new ToolbarItemInfo(
					'wire group 4',
					RbUtils.Controllers.getInterfaceWireGroupName(this.controllerType, 4),
					'group_work',
					this.handleWireGroupClick.bind(this, RbEnums.Common.WireGroupOptions.Group4),
					null, undefined, null, null, null, null, null, null,
					this.getNgClass.bind(this, RbEnums.Common.WireGroupOptions.Group4)
				);		
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private notImplemented() {
		alert('Not implemented in derived class.');
	}
}

import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { CreateProgramGroup } from './models/create-program-group.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProgramGroup } from './models/program-group.model';
import { ProgramGroupListItem } from './models/program-group-list-item.model';
import { ProgramType } from '../programs/models/program-type.model';
import { RunTimeGolfInputType } from '../programs/models/run-time-input-Type.model';
import { SimpleProgramGroup } from './models/simple-program-group.model';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@Injectable({
	providedIn: 'root'
})
export class ProgramGroupApiService extends ApiService {

	clearCache() {
		super.clearCache(this.getProgramGroupsListUrl);
	}

	// =========================================================================================================================================================
	// API Calls
	// =========================================================================================================================================================

	createProgramGroup(programGroup: CreateProgramGroup): Observable<void> {
		return this.apiRequest<any>(this.createProgramGroupUrl, HttpMethod.Post, programGroup);
	}

	createQuickIrr(update: any): Observable<null> {
		return this.apiRequest<any>(this.createQuickIrrUrl, HttpMethod.Post, update);
	}

	deleteProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.deleteProgramGroupsUrl, HttpMethod.Delete, programGroupIds);
	}

	getNameUniqueness(name: string, id: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNameUniquenessUrl(name, id), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getNumberUniqueness(number: number, id: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNumberUniquenessUrl(number, id), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getNextDefaultQuickIrrName(): Observable<string> {
		return this.apiRequestWithCache<any>(this.getNextDefaultQuickIrrNameUrl, true)
			.pipe(map(response => response.value.stringValue));
	}

	getProgramGroupsList(bypassCache = false, forceGet = false): Observable<ApiCachedRequestResponse<ProgramGroupListItem[]>> {
		return this.apiRequestWithCache<any>(forceGet ? this.getProgramGroupsListWithUniqueQueryParamUrl : this.getProgramGroupsListUrl, bypassCache,
			result => {
				return result.map(item => new ProgramGroupListItem(item));
			}, 300, true);
	}

	getProgramGroup(id: number): Observable<ProgramGroup> {
		return this.apiRequest<any>(this.getProgramGroupUrl(id), HttpMethod.Get)
			.pipe(map(json => new ProgramGroup(json)));
	}

	getProgramTypes(): Observable<ProgramType[]> {
		return this.apiRequest<any>(this.getProgramTypesUrl, HttpMethod.Get)
			.pipe(map(programTypes => programTypes.map(json => new ProgramType(json))));
	}

	getRunTimeInputTypes(): Observable<RunTimeGolfInputType[]> {
		return this.apiRequest<any>(this.getRunTimeInputTypesUrl(), HttpMethod.Get)
			.pipe(map(runTimeInputTypes => runTimeInputTypes.map(json => new RunTimeGolfInputType(json))));
	}

	getSimpleProgramGroup(programGroupId: number): Observable<SimpleProgramGroup> {
		return this.apiRequest<any>(this.getSimpleProgramGroupUrl(programGroupId), HttpMethod.Get)
			.pipe(map((programGroup: ProgramGroup) => new SimpleProgramGroup(programGroup)));
	}

	updateProgramGroups(programGroupIds: number[], programGroupUpdate: object): Observable<null> {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch,
			{ ids: programGroupIds, patch: this.patchTransform(programGroupUpdate) });
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}ProgramGroup`; }

	private get createProgramGroupUrl(): string { return `${this.baseUrl}/CreateProgramGroup`; }

	private get createQuickIrrUrl(): string { return `${this.baseUrl}/CreateQuickIRR`; }

	private get deleteProgramGroupsUrl(): string { return `${this.baseUrl}/DeleteProgramGroups`; }

	private getNameUniquenessUrl(name: string, id: number): string {
		return `${this.baseUrl}/IsNameUnique?groupName=${encodeURIComponent(name)}&groupId=${id}`;
	}

	private getNumberUniquenessUrl(number: number, id: number): string { return `${this.baseUrl}/IsNumberUnique?groupNumber=${number}&groupId=${id}`; }

	private get getNextDefaultQuickIrrNameUrl(): string { return `${this.baseUrl}/GetNextDefaultName`; }

	private get getProgramGroupsListUrl(): string { return `${this.baseUrl}/GetProgramGroups`; }

	private get getProgramGroupsListWithUniqueQueryParamUrl(): string { return `${this.baseUrl}/GetProgramGroups?q=${Math.floor(Math.random() * 9999999999)}`; }

	private getProgramGroupUrl(id: number): string { return `${this.baseUrl}/GetProgramGroup?id=${id}&includeStartTimes=true`; }

	private get getProgramTypesUrl(): string { return `${this.baseUrl}/GetProgramTypes`; }

	private getSimpleProgramGroupUrl(programGroupId): string {
		return `${this.baseUrl}/GetProgramGroup?id=${programGroupId}&includePrograms=false&includeStartTimes=false&includeProgramSteps=false`;
	}

	private get updateBatchesUrl(): string { return `${this.baseUrl}/UpdateBatches`; }

	private getRunTimeInputTypesUrl(): string { return `${this.baseUrl}/GetRunTimeInputTypes`; }
}

import { Observable, Subject } from 'rxjs';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CommInterfaceApiService } from './comm-interface-api.service';
import { CommResource } from './models/comm-resource.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class CommInterfaceManagerService extends ServiceManagerBase {

	constructor(private commInterfaceApiService: CommInterfaceApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	commInterfaceModeChanged = new Subject<number>();
	commInterfaceSimCardChanged = new Subject();
	protected clearCache() {
		this.commInterfaceApiService.clearCache();
	}

	getCommResources(bypassCache = false): Observable<CommResource[]> {
		return this.commInterfaceApiService.getCommResources(bypassCache).pipe(map(response => response.value));
	}

	getCommResourcesType(bypassCache = false): Observable<CommResource[]> {
		return this.commInterfaceApiService.getCommResourcesType(bypassCache).pipe(map(response => response.value));
	}

	getCommResourcesPort(bypassCache = false): Observable<CommResource[]> {
		return this.commInterfaceApiService.getCommResourcesPort(bypassCache).pipe(map(response => response.value));
	}
	getUniqueIPAddressesForSatellites(bypassCache = false): Observable<string[]> {
		return this.commInterfaceApiService.getUniqueIPAddressesForSatellites(bypassCache).pipe(map(response => response.value));
	}
}

/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { FlowElement } from '../../../api/flow-elements/models/flow-element.model';
import { FlowElementManagerService } from '../../../api/flow-elements/flow-element-manager.service';
import { FlowRateUpdateData } from '../../../api/signalR/flow-element-change.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationListItem } from '../../../api/stations/models/station-list-item.model';
import { Subject } from 'rxjs';

/**
 * FloManagerTabUtils provides centralized processing of capacity, current flow, and remaining capacity columns for
 * FloManager tabs. For the tree view, it handles stations (requiring the client component to send it station list
 * updates when they arrive). It provides a notification for clients to refresh their data when new flow data arrives
 * from the FlowElementManager.
 */
@UntilDestroy()
@Directive()
export class FloManagerTabUtils implements OnDestroy {

	/**
	 * We need a copy of the station items so we can get the current flow for any station which is running. The caller
	 * must set this any time it changes, if they are displaying station flows.
	 */
	stations: StationListItem[] = [];

	/**
	 * This property holds only the latest flow data from FlowRateUpdates. This is a list of non-zero-flow FlowElements.
	 * This allows us to render the current flow rate for each item in the table or tree.
	 */
	latestFlowElementData: { [id: number]: FlowRateUpdateData } = {};

	/**
	 * Clients should subscribe to this Subject. It will fire each time a flow data update is received and they need to
	 * refresh their display.
	 */
	refreshFlowRequired = new Subject<void>();

	constructor(protected authManager: AuthManagerService,
		protected flowElementManager: FlowElementManagerService) {

		// Get the last-received flow data received by FlowElementManager, using that as our initial value.
		// NOTE: This could be null/undefined. Make sure we check before using.
		this.latestFlowElementData = (this.flowElementManager.lastFlowRateUpdate != null) ?
			this.flowElementManager.lastFlowRateUpdate.flowRateUpdates :
			null;

		// Subscribe to the FlowElementManager's flow-data-updated Subject. This will allow us to update flow data
		// for all items in the tree/table. As soon as we have this data, we can render the current flow for
		// any flow element we find, via lookup.
		this.flowElementManager.flowElementsFlowDataChange
			.pipe(untilDestroyed(this))
			.subscribe(flowElementData => {
				this.latestFlowElementData = flowElementData.flowRateUpdates;
				setTimeout(() => this.refreshFlowRequired.next(null));
			});
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	convertCapacity(params) {
		const flowElement = <FlowElement> params.data;

		// We only display the capacity for non-Station items.
		if (flowElement.role === RbEnums.Common.FlowElementType.Station) {
			return '';	// Just return blank. A station doesn't have a capacity.
		}

		if (flowElement.flowCapacity == null) return '';
		else
		return RbUtils.Common.convertFlowToUserCulture(this.authManager.userCulture, flowElement.flowCapacity,
			RbUtils.Common.getPrecisionForFlow(this.authManager.userCulture));
	}

	convertCurrentFlow(params) {
		const flowElement = <FlowElement> params.data;
		const flowValueGPM = this.getCurrentFlowGPM(flowElement);
		if (flowValueGPM > 0) {
			return RbUtils.Common.convertFlowToUserCulture(this.authManager.userCulture, flowValueGPM,
				RbUtils.Common.getPrecisionForFlow(this.authManager.userCulture));
		}
		return '';
	}

	convertAvailableFlow(params) {
		const flowElement = <FlowElement> params.data;

		// We only display the available flow for non-Station items.
		if (flowElement.role === RbEnums.Common.FlowElementType.Station ||
			flowElement.isFloGuardStationsGroup) {
			return '';	// Just return blank. A station doesn't have a capacity.
		}

		const flowValueGPM = this.getCurrentFlowGPM(flowElement);

		// Current available flow is just the difference between capacity and current.
		const availableFlowGPM = flowElement.flowCapacity - flowValueGPM;
		return RbUtils.Common.convertFlowToUserCulture(this.authManager.userCulture, availableFlowGPM,
			RbUtils.Common.getPrecisionForFlow(this.authManager.userCulture));
	}

	/**
	 * If the flowElement is a non-Station item, using the flow data dictionary provided by SignalR, perform a lookup of
	 * the current flow rate for the indicated FlowElement, returning the value (in GPM). If the flowElement is a station,
	 * perform lookup of station status and set it's flow rate if running.
	 * @param flowElement - the target FlowElement to lookup
	 * @returns current flow in GPM (which may be zero, of course)
	 */
	getCurrentFlowGPM(flowElement: FlowElement): number {
		let flowValueGPM = 0;

		if (flowElement.role === RbEnums.Common.FlowElementType.Station) {
			// Find the station status and, if running, set flowValueGPM to station flow rate.
			const station = this.stations.find(s => s.id === flowElement.id);
			if (station == null) return;

			if (station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running) {
				flowValueGPM = station.flowRate;
			}
		} else if (this.latestFlowElementData != null && this.latestFlowElementData[flowElement.id] != null) {
			flowValueGPM = this.latestFlowElementData[flowElement.id].flowRateGPM;
		}
		return flowValueGPM;
	}
}

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { HashLocationStrategy, LocationStrategy, TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppInitializerService } from './common/services/app-initializer.service';
import { AppRouting } from './app.routing';
import { AuthInterceptor } from './api/auth/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { CustomModulePreloadingStrategy } from './core/components/custom-preloader/custom-module-preloading-strategy';
import { CustomTranslateHttpLoaderService } from './common/services/custom-translate-http-loader.service';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavigationHistoryService } from './common/services/navigation-history.service';
import { RbccErrorHandlerService } from './common/services/rbcc-error-handler.service';
import { SharedUiModule } from './shared-ui/shared-ui.module';
import { SnackBarComponent } from './shared-ui/components/snack-bar/snack-bar.component';

export function HttpLoaderFactory(http: HttpClient) {
	// RB-14741: Enforce cache-control: no-cache on translation files. We try to do this from the code avoiding a
	// required manual browser cache clear, since the server has not been setting these headers so far.
	return new CustomTranslateHttpLoaderService(http, './assets/i18n/', '.json',
		{ ['Cache-Control']: 'no-cache' });
}

export function appInitializerFactory(provider: AppInitializerService) {
	return () => provider.load();
}

declare global {
	interface Navigator {
		msSaveBlob?: (blob: any, defaultName?: string) => boolean;
		msMaxTouchPoints?: number;
	}
}

@NgModule({
	declarations: [
		AppComponent,
		SnackBarComponent
	],
	imports: [
		AppRouting,
		BrowserAnimationsModule,
		CoreModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		LeafletModule.forRoot(),
		MatSidenavModule,
		MatExpansionModule,
		SharedUiModule
	],
	providers: [
		{ provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [AppInitializerService, NavigationHistoryService], multi: true },
		{ provide: ErrorHandler, useClass: RbccErrorHandlerService },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		// Any of the direct provide...useClass operations work fine if explicit here, but if you specify them
		// in the build environment (which is desireable), they don't work. There must be a circular dependency
		// or something similar somewhere, but I haven't spotted and fixed it.
		// { provide: BackendNotificationService, useFactory: forwardRef(() => new SignalRService()) },
		// { provide: BackendNotificationService, useFactory: notificationFactory, deps: [EnvironmentService], multi: false},
		// { provide: BackendNotificationService, useClass: SignalRService },
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: OverlayContainer, useClass: FullscreenOverlayContainer },
		TitleCasePipe,
		CustomModulePreloadingStrategy
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

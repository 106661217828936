/*
 * NOTE: DO NOT USE THESE ENUMS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbEnums Namespace.
 * 		 E.g., RbEnums.Common.ControllersSyncState
 * 		 See: _rb.enums.ts
 */

export namespace XXUseRbEnumsNamespace.Common {

	/* PLEASE ALPHABETIZE WHEN ADDING ENUMERATIONS */

	export enum ActivityStatus {
		Running,
		Paused,
		OnTime,
		Completed
	}

	export enum ActivityTab {
		Scheduled,
		Completed,
		Events,
		Alarms
	}

	export enum ActivityType {
		Station,
		Program,
		ProgramGroup,
		All
	}

	export enum AreaLeftSidebarType {
		Info,
		Edit,
	}

	export enum AreaRightSidebarType {
		Area,
		Subarea,
	}

	export enum BorrowState {
		None,
		Shared,
		Borrow,
	}

	export enum ChallengeQuestion {
		PetName = 10,
		HighSchoolSubject = 15,
		MotherMaidenName = 20,
		ColorFirstCar = 25,
		CityOfBirth = 30,
		TimeOfTheDay = 35,
		Other = 100
	}

	export enum CommDeviceType {
		Unknown = 0,
		DTC_PR = 1,
		DTC_R = 2,
		ETC = 3,
		PBC = 5,
		NCC_EN = 9,
		NCC_RS = 11,
		NCC_GP = 13,
		NCC_WF = 14,
		NCC_PH = 15,
	}

	export enum CommInterfaceType {
		Modem = 0,
		Serial = 1,
		Ethernet = 2,
		IP_Modem = 3,
		None = 4,
		Radio = 5,
		Client = 6,
		Demo = 7,
	}

	export enum StepRunTime {
		Default = 1,
		Custom = 2,
	}

	export enum ContactReason {
		Synchronize = 0x01,
		RetrieveLogs = 0x02,
		ReverseSynchronize = 0x04,
		GetWSData = 3,
		FirmwareUpdatesAutomatically = 5,
	}

	export enum ContactType {
		None = 0,
		EveryDay = 1,
		EveryDayIfOutOfSync = 2,
		PastHour = 4,
		CustomTimes = 5
	}

	export enum ControllerSidebarType {
		Info,
		Edit,
		Control,
		FlowZoneSensor
	}

	export enum ControllerSyncState {
		Synchronized,
		NotSynchronized,
		HasDifferences,
		Syncing,
		ReverseSyncing,
		SyncFailed,
		Incomplete,
	}

	// In API we are calling SyncState. ControllerSyncState is
	// already in use in UI side, that why naming Enum as FrontPanelState
	export enum FrontPanelState {
		Synchronized = 1,
		OutOfSync = 2,
		Incomplete = 3,
	}

	export enum ControllerStatusFlag {
		Incomplete = 0x01,
		OutOfSync = 0x02,
		Synchronized = 0x04,
		Queued = 0x08,
		Connected = 0x10
	}

	export enum DeviceType {
		CCU = -2,
		None = -1,
		WirelessValveBox = 0,
		LXM4 = 1,
		LXMModule8 = 2,
		VirtualRvu = 4,
		LXMModule4 = 5,
		LXMDecoderModule = 7,
		LXD = 8,
		LXMDecoderModuleExpansion = 9,
		ETManagerWeatherSource = 10,
		WSProLTWeatherSource = 11,
		WSPro2WeatherSource = 12,
		CimisWeatherSource = 13,
		LXMModule12 = 14,
		TBOS1 = 15,
		TBOS2 = 16,
		TBOS4 = 17,
		TBOS6 = 18,
		TBOSMRR = 19,
		LXME = 20,
		TBOSSRR = 21,
		BaseModule = 22,
		FlowSmartConnectionModule = 23,
		FlowSmartModule = 24,
		ConnectionModule = 25,
		WwoWeatherSource = 26,
		LXIVM = 27,
		LXIVMPlus = 28,
		ICSDecoderModule = 29,
		ICI = 30,
		ESPSITE = 31,
		CCU6WIRED = 32,
		CCU6LINK = 33,
		CCU6MUX = 34,
		CCU28WIRED = 35,
		CCU28LINK = 36,
		CCU28MUX = 37,
		WSProWeatherSource = 38,
		RainPulseWeatherSource = 39,
		ETPulseWeatherSource = 40,
		MaxicomWiredSatellite = 41,
		MaxicomLinkSatellite = 42,
		LDISDI = 43,
		MIM = 44,
		MIM_LINK = 45,
		PAR_ES = 46,
		OSMModule8 = 47,
		OSMModule4 = 48,
		ESP_MC = 49,
		SensorDecoderTwoWire = 50,
		PulseDecoderTwoWire = 51,
		MSCplus = 52,
		ManualWeatherSource = 53,
		LXME2 = 54,
		FlowSmartModule2 = 55,
		FlowSmartConnectionModule2 = 56,
		ESPME3 = 57,
		ARC8 = 58,
		IQI = 59,
		ESPSAT = 60,
		WwoProWeatherSource = 61,
		PARplus = 64,
		RC2_8Station = 65,
		ESPSM3Module = 66,
		ESPSM6Module = 67,
		ESP2Wire = 68,
		TM2_4Station = 69,
		TM2_6Station = 70,
		TM2_8Station = 71,
		TM2_12Station = 72,
		WatchDogWeatherSource = 73,
		RC2_4Station = 74,
		RC2_6Station = 75,
	}

	export enum DecoderModelType {
		FD101TURF = 0,
		FD102TURF = 1,
		FD202TURF = 2,
		FD401TURF = 3,
		FD601TURF = 4,
		FD101 = 10,
		FD102 = 11,
		FD202 = 12,
		FD401 = 13,
		FD601 = 14,
	}

	// We use this to centralize the match of fields from the returned voltage results of QuickCheck API,
	// for decoder diagnostics; value1, value2 and value3, to match their respective field.
	export enum DecoderQuickCheckFields {
		atRest = "value1",
		inRush = "value2",
		hold = "value3",
	}

	export enum DiagnosticDataType {
		Unspecified,
		ICShortAddress,
		ICVoltage,
		ICLongAddress,
		ICIGroupCurrent,
		LINKDiagnostics,
		QuickCheckDiagnostics,
		DIBranchIdleCurrent,
	}

	export enum DiagnosticFeedbackResult {
		NO_FB,
		OK,
		Error
	}

	export enum DiagnosticQuickCheckResult {
		Pass = 'Pass',
		Fail = 'Fail',
		NoFeedback = 'NoFeedback',
	}

	export enum DiagnosticStateResult {
		Off = 'OFF',
		On = 'ON',
		Error = 'ERROR',
		No_Data = 'NO_DATA'
	}

	export enum DiagnosticTestType {
		StatusPoll = 1,
		VoltageTest,
		QuickCheck,
	}

	export enum EntitlementsRenewalState {
		NotExpired,
		Expired,
		Expiring
	}

	export enum StationDiagnosticRunType {
		StatusCheck,
		VoltageCheck,
		QuickCheck,
		OnOffCheck
	}

	export enum ScheduledDiagnosticTestType {
		ICMStatusSystemPoll = 1,
		ICMVoltageSystemPoll = 2,
	}

	export enum DiagnosticType {
		Address = 1,
		Area,
		Course,
	}

	export enum EtAdjustType {
		None,
		SeasonalAdjust,
		WeatherSource,
		SiteWaterBudget,
		WaterBudget,
		SimpleET,
	}

	export enum RunTimeGolfInputType {
		RunTime = 0,
		Volume = 1,
		WeatherSource = 2,
		Passes = 3,
	}

	export enum EventLogType {
		/// <summary>
		/// Indicates that the <see cref="EventLog"/> is an alarm.
		/// </summary>
		OpenAlarm = 0x01,

		/// <summary>
		/// Indicates that the <see cref="EventLog"/> is a warning.
		/// </summary>
		OpenWarning = 0x02,

		/// <summary>
		/// Indicates that the <see cref="EventLog"/> is informational.
		/// </summary>
		Information = 0x04,

		/// <summary>
		/// Indicates that the <see cref="EventLog"/> is for diagnostic purposes.
		/// </summary>
		Diagnostic = 0x08,

		/// <summary>
		/// Indicates that the <see cref="EventLog"/> is for already acknowledged alarm items.
		/// </summary>
		AcknowledgedAlarms = 0x10,

		/// <summary>
		/// Indicates that the <see cref="EventLog"/> is for already acknowledged warning items.
		/// </summary>
		AcknowledgedWarnings = 0x20,

		/// <summary>
		/// Indicates that the <see cref="EventLog"/> is for already acknowledged items.
		/// </summary>
		AcknowledgedAlarmsAndWarnings = 0x40,
	}

	export enum EventLogVisibility {
		/// <summary>
		/// The <see cref="EventLog"/> is discarded by IQ, and is not visible anywhere.
		/// </summary>
		None = 0x00,
		/// <summary>
		/// The <see cref="EventLog"/> is visible in the GUI Status window.
		/// </summary>
		GuiStatus = 0x01,
		/// <summary>
		/// The <see cref="EventLog"/> is visible in the Event Log screen.
		/// </summary>
		EventLogScreen = 0x02
	}

	export enum EventLogCategory {
		IrrigationIssue = 0,
		DeviceIssue = 1,
		FlowAlert = 2,
		SensorEvent = 3,
	}

	export enum FlowActionType {
		AlarmOnly,
		DiagnoseAndEliminate,
		ShutdownAndAlarm,
	}

	export enum FlowSensorModelType {
		Disabled = 0,
		FS150P = 1,
		FS200P = 2,
		FS300P = 3,
		FS400P = 4,
		FS100B = 5,
		FS350B = 6,
		FS350SS = 7,
		Custom = 8,
		FS050P = 9,
		FS075P = 10,
		FS100P = 11,
		FS150B = 12,
		FS200B = 13,
		MJ100B = 20,
		Unknown = 256
	}

	export enum OnOffState {
		On = 0,
		Off = 1,
	}

	export enum FlowElementType {
		FlowZone = 0,
		Pump = 1,
		Branch = 3,
		MasterValve = 4,
		Station = 5,
	}

	export enum GroupLevel {
		ReadOnlyUser = 2,
		FieldTech = 6,
		User = 10,
		Demo = 15,
		PowerUser = 20,
		Admin = 30,
		RootAdmin = 35,
		RBAdmin = 40,
		RBDev = 50,
		GspUser = 205,
		GspAdmin = 210,
		GspSuperAdmin = 215,
	}

	export enum HeadSpacingType {
		Rectangular,
		Triangular,
		SingleRow,
	}

	export enum IconAnimationType {
		None,
		Spin,
		SpinCounterclockwise,
		RotateX,
		RotateY,
		ControllerConnecting,
		ControllerDisconnecting
	}

	export enum IndicatorColor {
		Default = 'white',
		Synchronized = '#80b579',
		OutOfSync = '#f1ac3b',
		Incomplete = '#b9121b',
	}

	export enum IQEvent {
		None = 0,
		ACPowerRestored = 1,
		ProgramAStationTurningOn = 2,
		ProgramAStationTurningOff = 3,
		MasterValveTurningOn = 4,
		MasterValveTurningOff = 5,
		ProgramStarted = 6,
		ProgramFinished = 7,
		SensorStateChanged = 8,
		LXMFirmwareRestarting = 10,
		RevertedToAuto = 11,
		ZeroAdjustment = 12,
		ShortCircuitDetected = 13,
		OpenCircuitDetected = 14,
		OperationStatusChanged = 15,
		CommunicationWithModuleLost = 16,
		SensorSuspendedWatering = 17,
		AlarmLightOn = 18,
		AlarmLightOff = 19,
		ProgramBStationTurningOn = 20,
		ProgramBStationTurningOff = 21,
		ProgramCStationTurningOn = 22,
		ProgramCStationTurningOff = 23,
		ProgramDStationTurningOn = 24,
		ProgramDStationTurningOff = 25,

		ManualOrTestValveTurningOn = 26,
		ManualOrTestValveTurningOff = 27,
		LastStationOfProgramRunning = 28,

		AutomaticIrrigationPaused = 29,
		AutomaticIrrigationUnPaused = 30,
		SensorPausedWatering = 31,
		MasterValveWaterWindowClosed = 32,
		MasterValveWaterWindowOpened = 33,
		PingFault = 34,
		LowVoltageFault = 35,
		LostTwoWireComms = 36,
		TwoWireCommsRestored = 37,
		PhysicalDialOff = 43,
		PhysicalDialAuto = 44,
		RasterTestCompleted = 45,
		FrontPanelDateChanged = 46,
		FrontPanelTimeChanged = 47,
		TestProgramActivated = 48,
		RasterTestActivated = 49,
		SAETChanged = 50,
		RainDelayChanged = 51,
		CalendarDayOffChanged = 52,
		LanguageChanged = 53,
		BackupProgramStored = 54,
		BackupProgramRecalled = 55,
		DelayRecallProgramRecalled = 56,
		SmartModuleStatusAccessed = 57,
		SmartModuleProgrammingAccessed = 58,
		ProgrammingCleared = 59,
		PhysicalSensorSwitchChanged = 60,
		ModuleInserted = 61,
		ModuleInsertedSlot = 62,
		ManualAdvanceStationCmd = 64,
		CycleSoakChanged = 65,
		MVPumpChanged = 66,
		SensorOverrideChanged = 67,
		StationStartedManually = 68,
		ProgramCustomDayCycleChanged = 69,
		ProgramStartTimesChanged = 70,
		ProgramRunTimesChanged = 71,
		ProgramProgramAdjustChanged = 72,
		ProgramSAETChanged = 73,
		ProgramDayCycleChanged = 74,
		ProgramStationDelayChanged = 75,
		ProgramCleared = 76,
		AllProgramsCleared = 77,
		ProgramStartedManually = 78,
		HHRemoteStartedStation = 87,
		HHRemoteStartedProgram = 88,
		HHRemoteChangedRunTime = 89,
		HHRemoteCancelledWateringEvent = 90,
		LogicalDialChanged = 129,
		LogicalSensorSwitchChanged = 130,
		CentralCancelledWateringEvent = 131,
		CentralManuallyAdvancedStation = 132,
		CentralManuallyStartedStation = 133,
		CentralManuallyStartedProgram = 134,
		CentralResettingClock = 135,
		CentralResetClock = 136,
		ProgramReceivedFromCentral = 137,
		ProgramSentToCentral = 138,
		CentralInitiatedRasterTest = 139,
		ReceivedProgramForInvalidStation = 140,
		SyncCancelledWatering = 141,
		OffCmdCancelledWatering = 142,
		CentralStartedTestProgram = 143,
		UnlockedByPINCode = 145,
		PINCodeUnlockAttemptFailed = 146,
		UnlockedByBackdoorPINCode = 147,
		BackdoorPINCodeUnlockAttemptFailed = 148,
		MissingModule = 149,
		ModuleMismatch = 150,
		DTCFirmwareRestarting = 172,
		DTCAnsweringCall = 173,
		DTCAuthenticationFailed = 174,
		DTCCallingCentral = 175,
		DTCManuallyReset = 176,
		DTCEventLogCleared = 177,
		FirmwareUpdateStatus = 178,
		DTCLXMAuthenticationFailed = 179,
		LearnFlowStarted = 190,
		LearnFlowCompleted = 191,
		ManualWateringLikelyDetected = 192,
		LowFlowStationSkipped = 193,
		NoRuntimeStationSkipped = 194,
		FlowMonitoringEnabled = 195,
		FlowMonitoringDisabled = 196,
		FlowMonitoringBlocked = 197,
		UserAcknowledgedAlarms = 198,
		UserClearedListOfAlarms = 199,
		AbortLearnFlowOperation = 200,
		HighFlowDetectedFlowZone = 201,
		HighFlowDetected = 202,
		LowFlowDetectedFlowZone = 203,
		LowFlowDetected = 204,
		SEEFStartedOnFlowZone = 205,
		SELFStartedOnFlowZone = 206,
		HighFlowFalseAlarmOnFlowZone = 207,
		LowFlowFalseAlarmOnFlowZone = 208,
		HighFlowMainlineBreakFlowZone = 209,
		HighFlowMainlineBreak = 210,
		HighFlowLateralLineBreak = 211,
		HighFlowZoneShutdown = 212,
		HighFlowZoneAlarm = 213,
		MainlineBreakManualMVFlowZone = 214,
		MainlineBreakManualMV = 215,
		LateralLineLowFlowStation = 216,
		LateralLineLowFlow = 217,
		LowFlowZoneShutdown = 218,
		LowFlowZoneAlarm = 219,
		NGC_EVENT_LX_APPLICATION_RESTARTING_VER_HI = 230,
		NGC_EVENT_LX_APPLICATION_RESTARTING_VER_LO = 231,
		LearnedFlowStation = 232,
		LearnedFlowRate = 233,
		LearnedFlowPeak = 234,
		HighFlowLateralLineBreakStation = 235,
		ServerCannotConnectToClient = 256,
		ServerConnectionLost = 257,
		NetworkServiceLost = 258,
		NetworkServiceRegained = 259,
		MasterValveOpenRequestRefusedByOwner = 260,
		MasterValveConfigurationError = 261,
		ModemSelfTestsPassed = 262,
		ModemSelfTestsFailed = 263,
		PacketDropped = 264,

		PingResult = 512,
		PhysicalConnection = 513,
		ListenStarted = 514,
		SocketState = 515,
		DialInRing = 516,
		NccConfiguratorMode = 517,
		GpModemType = 518,
		GpModemNetworkRegistration = 519,
		NccPhExtensionPickup = 521,
		SignalStrength = 522,
		PingStreak = 523,
		CicLostCommsWithFrontPanel = 524,
		CicRebootWithReason = 525,
		RegisteredOpFound = 526,
		PingAttemptMade = 527,
		IQInactivityTimeout = 528,
		SecondarySitePing = 529,

		ControllerDataChangeMadeAtController1 = 800,

		CentralAutoConnected = 9000,
		CentralConnected = 9001,
		SatelliteConnectedToCentral = 9002,
		SentProgramsAndConfig = 9003,
		RetrievedEventLogs = 9004,
		SentRainDelay = 9005,
		SentCalendarDayOff = 9006,
		SentOverrideSensorBypass = 9007,
		SentOverrideSensorActive = 9008,
		SentOverrideDialAuto = 9009,
		SentOverrideDialOff = 9010,
		UpdatedDTCFirmware = 9011,
		UpdatedSatelliteFirmware = 9012,
		RanWiringTest = 9013,
		CentralSoftwareLaunched = 9014,
		UserLoggedOn = 9015,
		NewKeycodeInstalled = 9017,
		DatabaseBackedUp = 9018,
		DatabaseRestored = 9019,
		CentralCompletedReverseSynchronization = 9020,
		JobFailed = 9021,
		SiteDeleted = 9022,
		SatelliteDeleted = 9023,
		SiteETSAZeroed = 9024,
		ProgramAdjustZeroed = 9025,
		ProgramStartTimesDeleted = 9026,
		WindowsDateTimeChanged = 9027,
		ModuleDiscrepancy = 9028,
		CentralSoftwareClosed = 9029,
		CentralDisconnected = 9030,
		CentralConnecting = 9031,
		CentralDisconnecting = 9032,
		UnsupportedModuleType = 9033,
		DeviceTimeDrifted = 9034,
		DataSaved = 9035,
		CentralCancelled = 9036,
		DatabaseUpgraded = 9037,
		CentralStartedSynchronization = 9038,
		CentralCompletedSynchronization = 9039,
		CentralEstablishing = 9040,
		EmailFailed = 9041,
		EmailSucceeded = 9042,
		ServiceNotRunning = 9043,
		CentralCompletedCollectWD = 9044,
		CentralFailedCollectWD = 9045,
		ETCheckbookNoWeathersource = 9046,
		ETCheckbookNoLandscape = 9047,
		ETCheckbookNoSchedDays = 9048,
		ETCheckbookForecastDone = 9049,
		ETCheckbookProcessWDDone = 9050,
		ETCheckbookLowBalance = 9051,
		ETCheckbookNoCBEntries = 9052,
		CentralStartedCollectWD = 9053,
		MultipleBadWeatherData = 9054,
		FirstWeatherData = 9055,
		NoWeatherData = 9056,
		FirstGPRSConnection = 9057,

		RASNotReady = 9102,
		ErrorProcessingConnections = 9103,
		TooManyConnectionRequests = 9104,
		NoDialTone = 9105,
		ComPortUnavailable = 9107,
		NullModemInstallFailed = 9108,
		CorruptEventLogFile = 9109,
		ExceededMaxResources = 9110,
		ExceededMaxResourcesDoRetry = 9111,
		ComPortBusy = 9112,
		ResourceDisabled = 9114,

		AuthenticationFailedDoRetry = 9200,
		AuthenticationFailed = 9201,
		ReceivedInvalidResponseDoRetry = 9202,
		ReceivedInvalidResponse = 9203,
		ConnectFailedDoRetry = 9204,
		ConnectFailed = 9205,
		ConnectFailedLineBusyDoRetry = 9206,
		ConnectFailedLineBusy = 9207,
		ConnectFailedNoAnswerDoRetry = 9208,
		ConnectFailedNoAnswer = 9209,
		ConnectFailedVoiceAnsweredDoRetry = 9210,
		ConnectFailedVoiceAnswered = 9211,
		DirectConnectFailedDoRetry = 9212,
		DirectConnectFailed = 9213,
		EthernetConnectFailedDoRetry = 9214,
		EthernetConnectFailed = 9215,
		CommunicationLostDoRetry = 9216,
		CommunicationLost = 9217,
		ReceivedBadEventLog = 9218,
		ConnectFailedNoCarrierDoRetry = 9219,
		ConnectFailedNoCarrier = 9220,
		NewDTCDetected = 9221,
		IncompatibleFirmwareDetected = 9222,
		RadioConnectFailed = 9223,
		AutoContactStarting = 9224,
		CentralStationsRenumbered = 9229,
		InvalidControllerType = 9230,
		PhysicalModuleMismatchDetected = 9231,
		Central2WirePathOff = 9232,
		Central2WirePathOn = 9233,
		WeatherDataUpToDate = 9234,
		CentralReceivedInvalidSatelliteData = 9235,
		SyncingWithDuplicateDecoderAddresses = 9236,
		InvalidModuleDetected = 9237,
		NccSubscriptionsExpireSoon = 9238,
		NccSubscriptionsAboutToExpire = 9239,
		NccSubscriptionsInGracePeriod = 9240,
		NccSubscriptionsExpired = 9241,
		IQDemoPeriodAboutToExpire = 9242,
		TBOSActiveAlertNak = 9243,
		MemoryErrorNak = 9244,

		RetryAutoJob = 9300,

		SRRLowBattery = 9400,
		TBOSControlModuleLowBattery = 9401,
		TBOSRadioAdapterLowBattery = 9402,
		TBOSRadioActionError = 9403,
		TBOSExceedLicenseError = 9404,
		MRRSRRReflashFailed = 9405,
		TBOSControlModuleIDChanged = 9406,
		TBOSFlowMeterError = 9407,
		TBOSInvalidStationCount = 9408,
		UnroutablePacketError = 9409,
		AutoSyncWithoutManualSyncFirstError = 9410,
		SyncSkippedIrrigationInProgress = 9411,
		AutoSyncTurnedOffRepeatedFailure = 9412,
		IQ3DataTransferFailed = 9413,
		IQ3DataTransferSucceeded = 9414,
		UserNameChangedDuringIQ3DataTransfer = 9415,
		TBOSCommandStatus = 9999,
	}

	export enum IqNetType {
		IQNetClient,
		IQNetServer,
		DirectSatellite,
	}

	export enum IrrigationState {
		OFF = 1,
		MasterValvesOff = 2,
		AutomaticIrrigation = 4,
		ManualStation = 5,
		SEEFStations = 6,
		SELF = 7,
		LearnedFlow = 8,
		Raster = 9,
		TestAllValves = 10,
		SEEFMasterValves = 12,
		SystemFault = 13,
		NOMVCycling = 14,
		IQSync = 100,
		DecoderTestPingInProgress = 101,
	}

	export enum LogicalDialPos {
		MakeNoChange,
		Off,
		Auto,
		SetCurrentDate,
		SetCurrentTime,
		SetWateringStartTimes,
		Mon,
		Tue,
		Wed,
		Thu,
		Fri,
		Sat,
		Sun,
		SetValveRunTimes,
		TroubleShooting,
		SeasonalAdjust,
		DelayWatering,
		AdvancedCycles,
		ValveSettings,
		SpecialFeatures,
		ModuleProgramming,
		ModuleStatus,
		Communications,
		ClearPrograms,
		ManualWatering,
		OffWithAllMVsClosed,
		SyncToPhysicalPos
	}

	export enum MailServerSecurityType {
		None,
		SSL,
		TLS,
	}

	export enum MailSettingsType {
		Email = 1,
		SMS = 2
	}

	export enum ManualControlIcon {
		NotConnected = 'panorama_fish_eye',
		Connecting = 'lens',
		Connected = 'lens',
		Disconnecting = 'lens'
	}

	export enum MasterValveType {
		None,
		MasterValve,
		BoosterPump,
	}

	export enum MessageBoxButtons {
		Ok,
		YesNo,
		OkCancel
	}

	export enum MessageBoxIcon {
		Asterisk,
		Error,
		Exclamation,
		Information,
		None,
		Question,
		Stop,
		Success,
		Warning,
	}

	export enum PinLockoutType {
		Unprotected,
		Partial,
		Full,
	}

	export enum Priority {
		NonIrrigation = 0,
		High = 1,
		Medium = 2,
		Low = 3,
		Lower = 4,
		Lowest = 5,
	}

	export enum ProgramGroupRightSidebarType {
		Edit,
		QuickIrr,
	}

	export enum ProgramStepActionType {
		RunStation,
		Wait,
		StartProgram,
		CancelProgram,
		StartProgramGroup,
		CancelProgramGroup,
		PauseProgram,
		ResumeProgram,
		PauseProgramGroup,
		ResumeProgramGroup,
		SystemON,
		SystemOFF,
	}

	export enum ProgramType {
		Custom = 0,
		Odd = 2,
		Odd31 = 3,
		Even = 4,
		Cyclical = 5,
		MonthDates = 6,
		CalendarDate = 7
	}

	export enum ReportType {
		RunTime,
		AlarmsAndWarnings,
		PINCodeAudit,
		SatelliteDifferences,
		DailyControllerIrrigation,
	}

	export enum RotarySwitchPosition {
		MakeNoChange = 0,
		Off = 1,
		Auto = 2,
		SetCurrentDate = 3,
		SetCurrentTime = 4,
		SetWateringStartTimes = 5,
		Mon = 6,
		Tue = 7,
		Wed = 8,
		Thu = 9,
		Fri = 10,
		Sat = 11,
		Sun = 12,
		SetValveRunTimes = 13,
		TroubleShooting = 14,
		SeasonalAdjust = 15,
		DelayWatering = 16,
		AdvancedCycles = 17,
		ValveSettings = 18,
		SpecialFeatures = 19,
		ModuleProgramming = 20,
		ModuleStatus = 21,
		Communications = 22,
		ClearPrograms = 23,
		ManualWatering = 24,
		OffWithAllMVsClosed = 25,
		SyncToPhysicalPos = 255,
	}

	export enum ScheduleStatusType {
		Scheduled = 0,
		Running = 1,
		Completed = 2,
	}

	export enum AddStationType {
		IVMDevice = 1,
		SatelliteStation = 2,
	}

	export enum SensorResponseType {
		NoAction = 100,
		SystemAutoOn = 101,
		SystemAutoOff = 102,
		SystemTurnOff = 103,
		SystemPause = 105,
		SystemResume = 106,

		// --- Program ---
		ProgramStart = 201,
		ProgramCancel = 202,
		ProgramAdvance = 204,
		ProgramPause = 205,
		ProgramResume = 206,

		ProgramGroupStart = 211,
		ProgramGroupCancel = 212,
		ProgramGroupAdvance = 214,
		ProgramGroupPause = 215,
		ProgramGroupResume = 216,

		// --- Area ---
		AreaTurnOn = 301,
		AreaTurnOff = 302,
		AreaAdvance = 304,
		AreaPause = 305,
		AreaResume = 306,

		// --- Station ---
		StationTurnOn = 401,
		StationTurnOff = 402,
		StationTurnOffAndLock = 403,
		StationAdvance = 404,
		StationPause = 405,
		StationResume = 406,

		// --- Flow ---
		FlowTurnOn = 501,
		FlowTurnOff = 502,
		FlowTurnOffAndLock = 503,
		FlowAdvance = 504,
		FlowPause = 505,
		FlowResume = 506,

		// --- Others ---
		SEEF = 803,
	}

	export enum SensorSwitchPosition {
		MakeNoChange = 0,
		Bypassed = 1,
		Active = 2,
		SyncToPhysicalPos = 255
	}

	export enum TwoWirePath {
		On = 253,
		Off = 254
	}

	export enum SensorType {
		None = -1,
		RainSensorPreventAlarm = 0x00,
		RainSensorPreventWarning = 0x80,
		FreezeSensorPreventAlarm = 0x01,
		FreezeSensorPreventWarning = 0x81,
		HighWindSensorPauseAlarm = 0x02,
		HighWindSensorPauseWarning = 0x82,
		MoistureSensorPreventAlarm = 0x03,
		MoistureSensorPreventWarning = 0x83,
		CustomPauseAlarm = 0x04,
		CustomPauseWarning = 0x84,
		CustomPreventAlarm = 0x05,
		CustomPreventWarning = 0x85,
		RainFreezeSensorPreventAlarm = 0x06,
		RainFreezeSensorPreventWarning = 0x86,

		MS100 = 10,
		DI330 = 110,
		PS332Wind = 115,
		RSD = 120,
		WRFSD = 150,
		Warning = 198,
		Alarm = 199,

		BadgerFlowSensor15 = 217,
		BadgerFlowSensor20 = 218,
		BadgerFlowSensor30 = 219,
		BadgerFlowSensor40 = 220,
		CustomTbosFlowSensor = 221,

		DecoderWeather = 299,
		Weather = 300,
		Flow = 301,

		OutputDecoder = 302,
		SoilMoisture = 303,
		Pressure = 304,
		FlowLimit = 305,
		WindLimit = 306,

		OnOff = 307,

		WindMeter = 308,
		TrafficMeter = 309,

		Pulse = 310,

		ETGauge = 311,
		RainGauge = 312,
		SiteRainCollector = 313,
		SiteETCollector = 314,

		// Golf sensor types:
		Voltage = 400,
		Current = 401,
		SwitchOpenClose = OnOff,
		PulseAccumulative = Pulse,
		PulseNonAccumulative = 404,
		TimeBetweenPulses = 405,
		PulsesIn10Seconds = 406,
	}

	export enum SiteType {
		Commercial = 0,
		Golf = 1,
	}

	export enum SiteSidebarType {
		Info,
		Edit,
	}

	export enum SkuType {
		None = 0,
		Module8 = 101,
		Module4 = 102,
		Module12 = 103,
		Decoder = 104,
		DecoderExpansion = 105,
		FlowSmart = 106,
		FlowSmartConnection = 107,
		TBOSMRR = 108,
		TBOSSRR = 109,
		Base = 110,
		Connection = 111,
		LxIvmDecoder = 112,
		LxIvmExpansion = 113,
		LxIvmConnection = 114,
		EspME2WireModule = 115,
		FlowSmart2 = 116,
		FlowSmartConnection2 = 117,
		OSMModule8 = 118,
		OSMModule4 = 119,
	}

	export enum SnapshotReasonType {
		ReverseSync = 0,
		Manual = 1,
		Deleted = 2
	}

	export enum SprinklerCategoryType {
		Rotors = 0,
		SprayHeads = 1,
		RotaryNozzles = 2,
		DripEmitter = 3,
		Bubblers = 4,
		ImpactSprinklers = 5,
		DripInLine = 6,
	}

	export enum SprinklerType {
		SprayNozzles = 1,
		RotaryNozzles = 2,
		Rotors = 3,
		Impacts = 4,
		Bubblers = 5,
		Driplines = 6,
		DripEmitters = 7
	}

	export enum SoilType {
		Clay,
		SiltyClay,
		ClayLoam,
		Loam,
		SandyLoam,
		LoamySand,
		Sand
	}

	export enum PlantMaterial {
		Trees,
		Shrubs,
		GroundCover,
		Mixed,
		CoolSeasonTurfgrass,
		WarmSeasonTurfgrass
	}

	export enum ForecastingMethod {
		MAD,
		DailyET
	}

	export enum SolenoidType {
		PGA_PEB_PESB_GB_EFB_CP,
		BPEB_BPES_GolfVIHRotors,
		PumpStartRelay,
		Custom,
	}

	export enum StationSequencingType {
		TerminalNumber = 0,
		StationPriority = 1,
	}

	export enum StationType {
		NormallyOpen = 0,
		NormallyClosed = 1,
		Unused = 2
	}

	export enum OperationType {
		Unused = 0,
		MasterValve = 1,
		Pump = 2,
	}

	export enum MVPumpOptions {
		Yes = 0,
		No = 1,
	}

	/// UserEventSubscriptionType includes values which can be used as flags, or-ing them together to form combinations.
	/// The example is (UserEventSubscriptionType.Email | UserEventSubscriptionType.TextMessage), with a numeric value
	/// of 3, tells the API to send the notifications both as an email and a SMS message.
	export enum UserEventSubscriptionType {
		/// <summary>
		/// None indicates that the user or address book entry is not interested in any subscriptions. User should not
		/// have any entries like this (just don't create the UserEventSubscription if you don't want it!), but AddressBook
		/// entries that should not receive the same notifications as their parent user will use it.
		/// </summary>
		None = 0,

		/// <summary>
		/// Send notifications via email. A suitable subject line is applied.
		/// </summary>
		Email = 1,

		/// <summary>
		/// Send notifications via SMS.
		/// </summary>
		TextMessage = 2,

		/// <summary>
		/// Special value for AddressBook entries only, when the AddressBook person should receive the same notification type
		/// as its parent user. Not used in the User case.
		/// </summary>
		SameAsParent = 4,

		/// <summary>
		/// Special value when a user wants to receive both Email and TextMessage for an event.
		/// </summary>
		Both = Email + TextMessage,
	}

	export enum ValveType {
		V1 = 0,
		V2 = 1,
		V3 = 2,
		V4 = 3,
		V5 = 4,
		None = 255
	}

	export enum UnitsType {
		English = 0,
		Metric1 = 1,
		Metric2 = 2,
		Metric3 = 3
	}

	export enum LengthUnit {
		Millimeter = 0,
		Inch = 1,
		Centimeter = 2,
		Meter = 3,
		Foot = 4,
		Kilometer = 5,
		Mile = 6,
	}

	export enum PressureUnit {
		bar = 0,
		psi = 1,
	}

	export enum WeatherSourceType {
		WsProLT = DeviceType.WSProLTWeatherSource,
		WsPro2 = DeviceType.WSPro2WeatherSource,
		Cimis = DeviceType.CimisWeatherSource,
		GlobalWeather = DeviceType.WwoWeatherSource,
		GlobalWeatherPro = DeviceType.WwoProWeatherSource,
		ManualWeatherSource = DeviceType.ManualWeatherSource,
		WatchDogWeatherSource = DeviceType.WatchDogWeatherSource
	}

	export enum WeatherAttribute {
		etFinal = 'etFinal',
		rainCurrent = 'rainCurrent',
		temperatureAirMin = 'temperatureAirMin',
		temperatureAirAverage = 'temperatureAirAverage',
		temperatureAirMax = 'temperatureAirMax',
		windSpeedAverage = 'windSpeedAverage',
		solarRadiation = 'solarRadiation',
		humidityAirAverage = 'humidityAirAverage',
	}

	export enum TimeFormat {
		AmPm = 0,
		Hours = 1,
	}

	export enum DateFormat {
		MMDDYYYY_Dash = 0, // MM-DD-YYYY
		MMDDYYYY_Slash = 1, // MM/DD/YYYY
		MMDDYYYY_Dot = 2, // MM.DD.YYYY
		DDMMYYYY_Dash = 3, // DD-MM-YYYY
		DDMMYYYY_Slash = 4, // DD/MM/YYYY
		DDMMYYYY_Dot = 5, // DD.MM.YYYY
		YYYYMMDD_Dot = 6 // YYYY/MM/DD - For some reason we use the slash, not the dot.  Probably should be renamed.
	}

	export enum AreaFormat {
		SQ_FT = 0,
		SQ_YD = 1,
		SQ_MT = 2,
	}

	export enum FirmwareType {
		Controller,
		Cic,
		Bootloader,
		ModuleBootloader,
		Module2Wire,
		ModuleSMBus,
		FSM2,
		FSCM2
	}

	export enum EntityDifferenceType {
		Satellite = 0,
		Program = 1
	}

	export enum PropertyDifferenceType {
		RainDelayDaysRemaining = 0,
		LogicalDialPosition,
		SimultaneousStations,
		NonIrrigationSimultaneousStations,
		EventDayOff,
		ProgramType,
		ProgramSkipDays,
		ProgramDaysRemaining,
		ProgramWeekdays,
		ProgramHybridWeekDays,
		ProgramStepDelay,
		ProgramSimultaneousStations,
		ProgramRestrictionStart,
		ProgramRestrictionEnd,
		ProgramProgramAdjust,
		ProgramET,
		StartTime,
		ProgramStepRuntime,
		StationUseMV,
		StationSensor,
		StationCycle,
		StationSoak,
		StationPriority,
		StationValveType,
		StationAddress,
		StationType,
		StationFlowRate,
		StationFlowRateLearned,
		StationMonthlyCyclingEnabled,
		StationUseLocalMV,
		DeviceSeasonalAdjust,
		LXDFloManagerEnabled,
		LXDMonthlyCyclingTime,
		LXDStationSequencing,
		StationValveTypeSolenoidsQuantity,
		StationValveTypeSolenoidType,
		StationValveTypeSwitchCode,
		FlowMonitoringFloWatchEnabled,
		FlowMonitoringWateringStartTime,
		FlowMonitoringWateringEndTime,
		FlowMonitoringWeekDays,
		FlowMonitoringMaxFlowRate,
		FlowMonitoringHighFlowThreshold,
		FlowMonitoringHighFlowSettlingTime,
		FlowMonitoringHighFlowAction,
		FlowMonitoringLowFlowThreshold,
		FlowMonitoringLowFlowSettlingTime,
		FlowMonitoringLowFlowAction,
		FlowMonitoringTimedReEnableDelay,
		FlowMonitoringStation,
		SensorAddress,
		WeatherSensorSensorModel,
		WeatherSensorSettlingTime,
		WeatherSensorStation,
		WeatherSensorSensorType,
		LocalWeatherSensor,
		FlowSensorModel,
		FlowSensorPipeDiameter,
		FlowSensorKFactor,
		FlowSensorOffset,
		FlowSensorStation,
		FlowZoneFlowRate,
		FlowZoneFlowRateLearned,
		FlowZoneStations
	}

	export enum DifferenceChangeType {
		Added = 0,
		Removed,
		Modified
	}

	export enum ToolbarButton {
		Advance,
		AreasFilter,
		Calculate,
		CancelAll,
		ChartView,
		Clear,
		ColumnView,
		Connect,
		Control,
		CourseView,
		DecoderTest,
		Delete,
		Diagnostics,
		DynamicAction,
		Edit,
		Export,
		FlowAlarms,
		HolesFilter,
		ListView,
		ManualSensorRead,
		Pause,
		PingDecoders,
		PingValvesSensors,
		RasterTest,
		Refresh,
		ReselectStations,
		Reset,
		RespondList,
		Restore,
		Resume,
		RetrieveLogs,
		ReverseSync,
		ScheduleView,
		ShortFinding,
		ShortReport,
		Simulate,
		SitesView,
		Snapshot,
		Start,
		Status,
		Stop,
		Summary,
		Sync,
		TableEdit,
		TestAll,
		UnlockSystem,
		UsersView,
		WireGroup1,
		WireGroup2,
		WireGroup3,
		WireGroup4,
		csvExport,
		pdfExport,
	}

	export enum WireGroupOptions {
		Group1 = 1,
		Group2 = 2,
		Group3 = 3,
		Group4 = 4,
	}

	export enum IrrigationStatus {
		Dash,
		Idle,
		Pending,
		Soaking,
		Delaying,
		Running,
		Paused,
		LearnedFlow = 8,
		ManuallyStarted = 255,
		ManuallyAdvanced = 256,		// Added for manual advance
		Suspended,
		Prevented,
		Advancing,
		ReadyToRun,
		Stopping
	}

	// This is the enum used by the Irrigation Engine in the IQServices Project.
	// NOTE: I THINK THIS SHOULD BE ALIGNED WITH THE ENUMERATION ABOVE (OR REPLACE IT).
	export enum StationStatus {
		Idle = 0,
		Pending = 1,
		On = 2,
		Soaking = 3,
		Delaying = 4,
		Suspended = 5,
		Prevented = 6,
		Paused = 7,
		ManuallyStarted = 255,		// Added for manual start
		ManuallyAdvanced = 256,		// Added for manual advance
	}

	export enum StatusSort {
		None,
		OutOfSync,
		Differences,
		RainDelay,
		AutoOff,
		Connections,
	}

	export enum ProgramStatus {
		Posted = 'Posted',		// 1
		Running = 'Running',	// 2
		Waiting = 'Waiting',	// 3
		Paused = 'Paused',		// 4
	}

	export enum DayOfWeek {
		Sunday,
		Monday,
		Tuesday,
		Wednesday,
		Thursday,
		Friday,
		Saturday
	}

	export enum WeatherForecast {
		Sunny,
		Cloudy,
		PartlyCloudy,
		Rain,
		Snow
	}

	// TODO: MW - This enumeration is incomplete.
	export enum JobPhase {
		Finish = 'Finish',
		Request = 'Request',
		RequestComplete = 'RequestComplete',
		Start = 'Start'
	}

	export enum SystemMode {
		Off,
		Auto,
		Paused,
	}

	export enum SystemStatus {
		FieldActivity,
		NoFieldActivity,
		Paused,
		Failure,
	}

	export enum IrrigationEngineCommandType {
		/// <summary>
		/// Perform a short address poll on all IC-based devices in the system
		/// </summary>
		SystemPoll = 1,

		/// <summary>
		/// Perform a QuickCheck operation for hardware. Note that this item requires a list of station ID values
		/// (stationIds) and a run time value (runTimeInSeconds). The stations are fired one after the other, in the
		/// order provided. Each station runs for the run time value (seconds), then switches off, starting the next
		/// station. The onBeforeOff property specifies whether station #2 starts before or after station #1 is switched
		/// off.
		/// NOTE: QUICK CHECK OPERATION STOPS ALL RUNNING IRRIGATION AND DISABLES ANY NORMAL IRRIGATION UNTIL THE
		/// QUICK CHECK PROCESS COMPLETES.
		/// </summary>
		QuickCheck = 2,

		/// <summary>
		/// Perform a (decoder) Simple test for decoder hardware. Note that this item requires a list of station ID values
		/// (stationIds) and a run time value (runTimeInSeconds). The stations are fired one after the other, using special
		/// switch code values based on station configuration, in the order provided. The current draw for each station is
		/// examined to determine if a station passes or fails the test.
		/// NOTE: SIMPLE CHECK OPERATION STOPS ALL RUNNING IRRIGATION AND DISABLES ANY NORMAL IRRIGATION UNTIL THE
		/// SIMPLE CHECK PROCESS COMPLETES.
		/// </summary>
		SolenoidResistanceCheck = 3,

		/// <summary>
		/// Stop any of the diagnostic operations started using IrrigationEngineCommand. This does not select one process or
		/// another to be terminated, but terminates all.
		/// </summary>
		CancelActiveDiagnostics = 50,

		/// <summary>
		/// Notify the engine that any currently-locked elements should be unlocked and allowed to operate per normal.
		/// This should release branches, zones, stations locked during FlowWatch operations. This operation is global
		/// to the engine and will unlock everything.
		/// </summary>
		UnlockAll = 100,

		/// <summary>
		/// SystemPause orders a pause on all irrigation managed for the system. There are override options for stations
		/// which should not stop for system pause.
		/// </summary>
		SystemPause = 150,

		/// <summary>
		/// SystemResume orders a resume on all irrigation managed for the system.
		/// </summary>
		SystemResume = 151,

		/// <summary>
		/// SystemOff performs a managed/controlled shutdown.
		/// SystemOff orders a off on all irrigation managed for the system.	Need to implement SystemOff in irrigation engine.
		/// </summary>
		SystemOff = 152,

		/// <summary>
		/// SystemShutDown performs an immediate shutdown.
		/// SystemShutDown orders to shutdown the system. We might also call this CancelAll, as that's the UI operation
		/// sending it.
		/// </summary>
		SystemShutDown = 153,

		/// <summary>
		/// SystemAuto changes the system state to Auto. Programs will run when they should.
		/// </summary>
		SystemAuto = 154,

		/// <summary>
		/// CheckInterfaceConnection checks the connection of all Golf interfaces.
		/// </summary>
		CheckInterfaceConnection = 155,
	}

	export enum IrrigationEngineChangeType {
		IrrigationCancelled = 2,
		IrrigationActiveUpdate = 3,
		IrrigationPaused = 20,
		IrrigationResumed = 22,
		GroupFaultFindingModeDataChange = 275,
		SystemModeAuto_Irrigating = 401,
		SystemModeOff_Irrigating = 402,
		SystemModeAuto_NotIrrigating = 403,
		SystemModeOff_NotIrrigating = 404,
		SystemModePaused_Irrigating = 405,
		SystemModePaused_NotIrrigating = 406,
		/// <summary>
		/// NewSoftwareVersionAvailable checks the new software version available for company.
		/// </summary>
		NewSoftwareVersionAvailable = 701,
	}

	export enum DryRunView {
		Chart,
		List,
		Schedule,
		Course
	}

	export enum ActivityViewType {
		Chart,
		List,
		Schedule,
		Course,
		Sites,
		Users,
		Column
	}

	export enum DryRunEvent {
		Started,
		Completed,
		StationCompleted,
		ScheduleCompleted,
		ProgramCompleted,
		ProgramStarted,
		ScheduleStarted,
		StationStarted,
	}

	export enum DryRunEntity {
		Program,
		Schedule,
		Station,
		QuickIRR
	}

	export enum ManualOpsDiagnosticsView {
		Status,
		PingDecoders,
		ShortFinding,
		StationResponse,
		TestAll,
		DecoderTest,
		ResponseList,
		PingValvesSensors,
		ShortReport
	}

	export enum RangeDirection {
		PriorToDate,		// Will create a range that ends on the provided date.
		FromDate,			// Will create a range that starts on the provided date.
	}

	export enum DashboardToolbarTool {
		SiteSelector,
		Calendar,
		Weather,
		AddWidget,
	}

	export enum StationStatusChangeType {
		Started,
		RunningUpdate,
		SoakStart,
		Stopped
	}

	export enum StationFailureReasonCode {
		NoError = 0,

		NoFieldConnection = 1,  // The interface is down, disconnected, etc.
		NoFlowCapacity = 2,     // The flow network cannot supply the station needs for run.
		NoElectricCapacity = 3, // Simultaneous station count already at maximum or no electrical capacity available
		NoFeedback = 4,         // We believe we have sent the message, but no feedback was received
		AlreadyRunning = 5,
		AlreadySoaking = 6,
		NotRunning = 7,         // Cannot pause
		NotPaused = 8,       	// Cannot resume
		BadStationData = 9,     // Not-found, incorrect address, other bad information
		BadFlowNode = 10,       // Flow node not-found, incorrect node IDs set or requested, other bad information.
		Locked = 11,         	// The station, or its master-valve, or it connected flow branch/flow-zone is locked.
		CommandTimeOut = 12,    // The engine didn't complete the On command before timing out so the station never ran.

		NoGroup = 13,           // The ICI reported that the specified wire path doesn't exist.

		NoSimulStations = 14,	// The schedule does not have any remaining simulstations to run this station.

		FlowNetworkWait = 15,	// The flow network needs to do something, like start a booster pump, before the station runs.

		InterfaceWait = 16,		// The interface was not ready to receive our command.

		Error = 1000,           // Unknown errors
	}

	export enum ProgramChangeType {
		Dash,
		ProgramStarted,
		ProgramCompleted,
	}

	export enum ActiveFlowView {
		Chart,
		List,
		Course
	}

	export enum TimeTravelUnit {
		All,			// Used to go to the beginning or end of a period.
		Hour,
		Minute,
		TimeStamp
	}

	export enum GolfSensorKingdom {
		Flow = 0,
		Weather = 1,
		Programmable = 2,
	}

	export enum GolfWeatherSensorModel {
		RainSwitch = 0x01,
		TemperatureSwitch = 0x02,
		Hygrometer = 0x03,
		BarometricPressure = 0x04,
		RainCan = 0x05,
		Temperature = 0x06,
		WindSpeed = 0x07,
	}

	export enum ComparisonType {
		NotEqualTo = 0,
		EqualTo = 1,
		// < or >, NOT inclusive.
		GreaterThan = 2,
		LessThan = 3,
		// <= or >=
		GreaterOrEqualTo = 4,
		LessOrEqualTo = 5,
		// > low threshold && < high threshold
		InsideRange = 6,
		// < low threshold || > high threshold
		OutsideRange = 7,
		// >= low threshold && <= high threshold
		InsideRangeInclusive = 8,
		// <= low threshold || >= high threshold
		OutsideRangeInclusive = 9,
	}

	export enum ScheduleType {
		Program,
		Sync,
		Logs,
		ReverseSync
	}

	export enum SensorTriggerSymbol {
		One,
		Two,
		Three,
		Four,
		Five,
		Six,
		Seven,
		Eight,
		Nine,
		Ten,
		Eleven,
		Twelve
	}

	export enum SensorState {
		Open,
		Closed
	}

	export enum DialogButton {
		Cancel,
		Save,
		Done
	}

	export enum DryRunType {
		Dryrun,
		ScheduleEndTime,
		FlowLog
	}

	export enum AutoContactMode {
		Manual = 0,
		Automatic = 1,
		Scheduled = 2
	}

	export enum ReportFormatType {
		PDF = 0,
		CSV = 1,
	}

	export enum WeatherSensorStatus {
		Bypassed = 0x00,
		Monitoring = 0x01,
		PreventingIrrigation = 0x02,
		PausingIrrigation = 0x03,
		Inactive = 0x04,
	}

	export enum Language {
		English = 'en',
		French = 'fr',
		German = 'de',
		Italian = 'it',
		Portuguese = 'pt',
		Spanish = 'es',
		Turkish = 'tr',
		Chinese = 'zh',
		Japanese = 'ja',
		Swedish = 'sv',
	}

	export enum IrrigationEventSource {
		Unknown = 0,
		ManualTest = 1,
		RemoteTest = 2,
		ManualValve = 3,
		RemoteValve = 4,
		ManualProgram = 5,
		RemoteProgram = 6,
		AutoProgram = 7,
		CentralTest = 8,
		CentralValve = 9,
		CentralProgram = 10
	}

	export enum Culture {
		English = 1033,
		French = 1036,
		German = 1031,
		Italian = 1040,
		Portuguese = 2070,
		Spanish = 3082,
		Turkish = 1055,
		Chinese = 2052,
		Japanese = 1041,
		Swedish = 1053,
		Vietnamese = 1066,
		Korean = 1042,
	}

	export enum CourseViewActivityType {
		DryRun,
		Active,
		Scheduled,
		Completed,
		Diagnostics
	}

	export enum CourseViewView {
		Holes,
		Places,
		All
	}

	export enum CourseViewData {
		Time,
		Application,
		Rotation
	}

	export enum ShortFindingDeviceType {
		Station,
		MasterValve
	}

	export enum ShortFindingResult {
		Open,
		Short
	}

	export enum PingRequestType {
		Indeterminate = 0,
		Station = 1,
		MasterValve = 2,
		FlowSensor = 3,
		WeatherSensor = 4
	}

	export enum IPChangeReason {
		Upgrade3Gto4G = 0,
		Other = 1
	}

	export enum ReportCard {
		StationRuntime = 1,
		AlarmsAndWarning = 2,
		MonthlyReportCard = 3,
		MonthlyFlow = 4,
		FlowLogGraph = 5,
		WaterManagement = 6,
		WaterUseByFloZone = 7,
		YearlyWaterConsumption = 8,
		Rainfall = 9,
		SystemChangeLog = 10,
		SiteConfiguration = 11,
		IQNetConfiguration = 12,
		ControllerDifferences = 13,
		PinCodeAudit = 14,
		Program = 15,
		ControllerConfiguration = 16,
		DailyControllerIrrigation = 17,
		Weather = 18
	}

	export enum ChangeLogAction {
		Unknown,
		Modified,
		Added,
		Removed,
		Moved
	}

	export enum AreaLevel {
		Hole = 2,
		GolfArea = 3
	}

	export enum DiagnosticsMode {
		Off = 0,
		Shorted = 1,
		Energized = 2,
		AutoShortIsolation = 3,
		Resume = 4,
		Mapping = 5
	}

	export enum SourceState {
		On,
		Off
	}

	export enum AdjustType {
		MonthlySA = 0,
		MonthlyET = 1,
		DailyET = 2,
		DailySA = 3,
	}

	export enum SlopeType {
		Slope_0_3 = 0,
		Slope_4_6 = 1,
		Slope_7_12 = 2,
		Slope_13 = 3,
	}

	export enum TransactionType {
		Initial = 0,
		ResetBalance = 10,
		UserAdjustment = 101,
		UserAdjustmentTooBig = 102,
		UserAdjustmentTooSmall = 103,
		NewPaw = 110,
		ET = 201,
		ETBad = 202,
		ETAv = 203,
		ETAvBad = 204,
		ETDef = 205,
		ETDefBad = 206,
		EtCorrection = 207,
		EtCorrectionBad = 208,
		Rain = 300,
		RainCorrection = 301,
		RainCorrectionBad = 302,
		UserETAdjustment = 400,
		UserETAdjustmentBad = 401,
		Sync = 500,
		Irrigation = 600
	}

	export enum AdjustmentType {
		Deposit = 1,
		Withdrawal = 2,
		SetToFC = 3,
		SetToRefill = 4,
		SetToMAD = 5
	}

	export enum ETCheckbookWaterLevel {
		SetToMAD = 0,
		SetToRefill = 1,
		SetToFC = 2,
		Deposit = 3,
		Withdrawal = 4
	}

	export enum EClassStatus {
		Default = '',
		Suspended = 'suspended',
		NoFeedback = 'no-feedback',
		Error = 'error',
		Idle = 'idle',
		Pending = 'pending',
		Running = 'running',
		Paused = 'paused',
		Soaking = 'soaking'
	}

	export enum EFilters {
		Idle = 'Idle',
		Running = 'Running',
		StandingBy = 'Standing by',
		Suspended = 'Suspended',
		Disconnected = 'Disconnected',
		NotInMap = 'Not in map',
		NotInAProgram = 'Not in a program',
		HasZeroAdjustment = 'Has zero adjustment'
	}

	export enum UserSettingsKey {
		GwpSidebarWidgetSettings = 'gwpSidebarWidgetSettings'
	}

	export enum SitePrimaryTab {
		Controller = 1,
		LandscapeTypes,
		SprinklerTypes,
		Map
	}

	export enum ControllerPrimaryTab {
		ManualOpsIrrgationQueue = 1,
		ManualOpsEvents,
		ManualOpsDiagnostics,
		Programs,
		Dryrun,
		Stations,
		MasterValves,
		Sensors,
		Map
	}

	export enum CommercialHomepageDestination {
		Dashboard = 'dashboard',
		Activity = 'activity',
		Site = 'site',
		Controller = 'controller',
		Program = 'program',
		Report = 'report'
	}

	// ControllerData Id Enum
	export enum Id {
		IrrigationStationSequence = 0x001F, //31
		UiLogicalDialRequested = 0x0067, //103
	}

	export enum CountryCode {
		AFGHANISTAN = 'AF',
		ALBANIA = 'AL',
		ALGERIA = 'DZ',
		ARGENTINA = 'AR',
		ARMENIA = 'AM',
		AUSTRALIA = 'AU',
		AUSTRIA = 'AT',
		AZERBAIJAN = 'AZ',
		BAHRAIN = 'BH',
		BANGLADESH = 'BD',
		BELARUS = 'BY',
		BELGIUM = 'BE',
		BELIZE = 'BZ',
		BHUTAN = 'BT',
		BOLIVIA = 'BO',
		BOSNIA_AND_HERZEGOVINA = 'BA',
		BOTSWANA = 'BW',
		BRAZIL = 'BR',
		BRUNEI = 'BN',
		BULGARIA = 'BG',
		CAMBODIA = 'KH',
		CAMEROON = 'CM',
		CANADA = 'CA',
		CHILE = 'CL',
		CHINA = 'CN',
		COLOMBIA = 'CO',
		COMOROS = 'KM',
		CONGO_DRC = 'CD',
		COSTA_RICA = 'CR',
		CÔTE_D_IVOIRE = 'CI',
		CROATIA = 'HR',
		CUBA = 'CU',
		CZECHIA = 'CZ',
		DENMARK = 'DK',
		DJIBOUTI = 'DJ',
		DOMINICA = 'DM',
		DOMINICAN_REPUBLIC = 'DO',
		ECUADOR = 'EC',
		EGYPT = 'EG',
		EL_SALVADOR = 'SV',
		ERITREA = 'ER',
		ESTONIA = 'EE',
		ETHIOPIA = 'ET',
		FAROE_ISLANDS = 'FO',
		FINLAND = 'FI',
		FRANCE = 'FR',
		GEORGIA = 'GE',
		GERMANY = 'DE',
		GREECE = 'GR',
		GREENLAND = 'GL',
		GUATEMALA = 'GT',
		HAITI = 'HT',
		HONDURAS = 'HN',
		HONG_KONG_SAR = 'HK',
		HUNGARY = 'HU',
		ICELAND = 'IS',
		INDIA = 'IN',
		INDONESIA = 'ID',
		IRAN = 'IR',
		IRAQ = 'IQ',
		IRELAND = 'IE',
		ISRAEL = 'IL',
		ITALY = 'IT',
		JAMAICA = 'JM',
		JAPAN = 'JP',
		JORDAN = 'JO',
		KAZAKHSTAN = 'KZ',
		KENYA = 'KE',
		KOREA = 'KR',
		KUWAIT = 'KW',
		KYRGYZSTAN = 'KG',
		LAOS = 'LA',
		LATVIA = 'LV',
		LEBANON = 'LB',
		LIBYA = 'LY',
		LIECHTENSTEIN = 'LI',
		LITHUANIA = 'LT',
		LUXEMBOURG = 'LU',
		MACAO_SAR = 'MO',
		MALAYSIA = 'MY',
		MALDIVES = 'MV',
		MALI = 'ML',
		MALTA = 'MT',
		MEXICO = 'MX',
		MOLDOVA = 'MD',
		MONACO = 'MC',
		MONGOLIA = 'MN',
		MONTENEGRO = 'ME',
		MOROCCO = 'MA',
		MYANMAR = 'MM',
		NEPAL = 'NP',
		NETHERLANDS = 'NL',
		NEW_ZEALAND = 'NZ',
		NICARAGUA = 'NI',
		NIGERIA = 'NG',
		NORTH_MACEDONIA = 'MK',
		NORWAY = 'NO',
		OMAN = 'OM',
		PAKISTAN = 'PK',
		PANAMA = 'PA',
		PARAGUAY = 'PY',
		PERU = 'PE',
		PHILIPPINES = 'PH',
		POLAND = 'PL',
		PORTUGAL = 'PT',
		PUERTO_RICO = 'PR',
		QATAR = 'QA',
		RÉUNION = 'RE',
		ROMANIA = 'RO',
		RUSSIA = 'RU',
		RWANDA = 'RW',
		SAUDI_ARABIA = 'SA',
		SENEGAL = 'SN',
		SERBIA = 'RS',
		SINGAPORE = 'SG',
		SLOVAKIA = 'SK',
		SLOVENIA = 'SI',
		SOMALIA = 'SO',
		SOUTH_AFRICA = 'ZA',
		SPAIN = 'ES',
		SRI_LANKA = 'LK',
		SWEDEN = 'SE',
		SWITZERLAND = 'CH',
		SYRIA = 'SY',
		TAIWAN = 'TW',
		TAJIKISTAN = 'TJ',
		THAILAND = 'TH',
		TRINIDAD_AND_TOBAGO = 'TT',
		TUNISIA = 'TN',
		TURKEY = 'TR',
		TURKMENISTAN = 'TM',
		UKRAINE = 'UA',
		UNITED_ARAB_EMIRATES = 'AE',
		UNITED_KINGDOM = 'GB',
		UNITED_STATES = 'US',
		URUGUAY = 'UY',
		UZBEKISTAN = 'UZ',
		VENEZUELA = 'VE',
		VIETNAM = 'VN',
		YEMEN = 'YE',
		ZIMBABWE = 'ZW'
	}

	export enum TbosNetworkLearningType {
		Full,
		NewTbosOnly
	}

	export enum FlowUnit {
		Gallons,
		Liters
	}

	export enum FilterType {
		Program = 1,
		Station = 2
	}

	export enum NotificationSnackbarType {
		Success = 0,
		Warning = 1,
		Error = 2,
		Action = 3
	}

	export enum ViewOptions {
		COLUMN = 'column_view',
		LIST = 'list_view'
	}

	export enum RequiredMVOptions {
		None,
		MV1,
		MV2,
		MV1And2,
	}

	export enum StationCellEditField {
		FlowZone = "flowZoneId",
		Address = "address",
		Sensor = "sensor",
		Cycle = "cycle",
		Soak = "soak"
	}
}

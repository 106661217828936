import { Component, HostBinding } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-station-diagnostic-mobile-renderer',
	templateUrl: './station-diagnostic-mobile-renderer.component.html',
	styleUrls: ['./station-diagnostic-mobile-renderer.component.scss']
})
export class StationDiagnosticMobileRendererComponent implements ICellRendererAngularComp {

	@HostBinding('class') class = 'rb-station-diagnostic-mobile-cell-renderer';

	data: any;

	RbEnums = RbEnums;

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.data = params.data;
	}

	refresh(params: any): boolean {
		this.data = params.data;
		return true;
	}
}

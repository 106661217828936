<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="nav-mobile-cell flex flex-column">
	<div [hidden]="!isNavData" class="flex flex-column">
		<div class="text-color tc">{{navigationName}}</div>
		<button class="br1 bn w2 ph2 pv1 white secondaryColor center">{{navData}} </button>
	</div>
	<div [hidden]="isNavData"><i class="material-icons settings-icon">settings</i></div>
</div>

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<mat-form-field class="">
	<mat-select
		[placeholder]="'STRINGS.MONTHS' | translate"
		[ngModel]="selectedMonth"
		name="month"
		required
		rbSelect
		(ngModelChange)="handleMonthChange($event)">

		<mat-select-trigger>{{selectedMonthString}}</mat-select-trigger>

		<mat-option *ngFor="let month of monthValues" [value]="month.value" class="pv1" color="primary">
			{{month.label | translate}}
		</mat-option>
	</mat-select>
</mat-form-field>

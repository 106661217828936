import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { LxdShortFindingItem } from '../../../../../sections/controllers/manual-ops/manual-ops-diagnostics-tab/models/lxd-short-finding-item.model';

@Component({
	selector: 'rb-short-finding-mobile-cell-renderer',
	templateUrl: './short-finding-mobile-cell-renderer.component.html',
	styleUrls: ['./short-finding-mobile-cell-renderer.component.scss']
})
export class ShortFindingMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	shortFindingItem: LxdShortFindingItem;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.shortFindingItem = params.data;
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="site" class="site-mobile-cell">
	<div class="name"><span class="ellipsis-text">{{site.name}}</span></div>

	<div class="controllers">
		<span class="label">{{(isGolfSite ? 'STRINGS.INTERFACES' : 'STRINGS.CONTROLLERS') | translate}}:</span>
		<span class="value">{{site.numberOfSatellites}}</span>
	</div>

	<div class="time-zone">
		<span class="label">{{'STRINGS.TIME_ZONE' | translate}}:</span>
		<span class="value">{{site.timeZone}}</span>
	</div>

	<div class="description">
		<span class="label">{{'STRINGS.DESCRIPTION' | translate}}:</span>
		<span class="value ellipsis-text">{{site.description}}</span>
	</div>
</div>

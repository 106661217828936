import { BroadcastService } from '../../common/services/broadcast.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { VoltageDiagnostic } from './models/voltage-diagnostic.model';
import { VoltageDiagnosticApiService } from './voltage-diagnostic-api.service';

@Injectable({
	providedIn: 'root'
})
export class VoltageDiagnosticManagerService extends ServiceManagerBase {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private voltageDiagnosticApiService: VoltageDiagnosticApiService,
				protected broadcastService: BroadcastService
	) {
		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getLastVoltageDiagnosticLogs(): Observable<VoltageDiagnostic[]> {
		return this.voltageDiagnosticApiService.getLastVoltageDiagnosticLogs();
	}
}

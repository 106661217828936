import { ICIDiagnosticLogSummaryData } from './ici-diagnostic-log-summary-data';

export class GetICIDiagnosticLogSummaryDataResponse {

	data: ICIDiagnosticLogSummaryData[] = [];

	constructor(json: any = null) {

		if (json) {
			this.data = json.map(item => new ICIDiagnosticLogSummaryData(item));
		}
	}
}

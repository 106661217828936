import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormGroup,
	NG_VALUE_ACCESSOR,
	Validators,
} from '@angular/forms';

import { debounceTime } from 'rxjs';
import { RbConstants } from '../../../common/constants/_rb.constants';

@Component({
	selector: 'rb-run-time-input',
	templateUrl: './run-time-input.component.html',
	styleUrls: ['./run-time-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RunTimeInputComponent),
			multi: true,
		},
	],
})
export class RunTimeInputComponent implements OnInit, ControlValueAccessor, AfterViewInit {
	@Input() maxHours: number = RbConstants.Form.DURATION_HOURS.ninetySixHrs;

	@ViewChild('hours') hours: ElementRef;
	@ViewChild('runTimeContainer') runTimeContainer: ElementRef;

	@Output() enterMoveDown = new EventEmitter<boolean>();

	onChange: Function;
	onTouched: Function;

	isFirstClickOut = true;
	mainForm: FormGroup;
	formDisabled: boolean;
	constructor(
		private fb: FormBuilder,
	) {}

	writeValue(obj: string): void {
		this.setValue(obj);
	}
	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.formDisabled = isDisabled;
		if(isDisabled) {
			this.mainForm.disable();
		}
	}

	ngOnInit() {
		this.mainForm = this.fb.group({
			hour: [0, [Validators.min(0), Validators.max(this.maxHours)]],
			minute: [0, [Validators.min(0), Validators.max(59)]],
			second: [0, [Validators.min(0), Validators.max(59)]]
		})
		this.mainForm
			.get('hour')
			.valueChanges.pipe(debounceTime(500))
			.subscribe((hour) => {
				if (+hour >= this.maxHours) {
					this.mainForm.patchValue(
						{
							hour: this.maxHours,
							minute: 0,
							second: 0,
						},
						{ emitEvent: false }
					);
				}
			});

		this.mainForm
			.get('minute')
			.valueChanges.pipe(debounceTime(500))
			.subscribe((minute) => {
				const isMaxHour = +this.mainForm.get('hour').value >= this.maxHours;
				if (isMaxHour && +minute > 0) {
					this.mainForm.patchValue(
						{
							hour: 0,
							minute: +minute > 59 ? 59 : +minute
						},
						{ emitEvent: false }
					);
				} else if (+minute > 59) {
					this.mainForm.patchValue(
						{
							minute: 59,
						},
						{ emitEvent: false }
					);
				}
			});

		this.mainForm
			.get('second')
			.valueChanges.pipe(debounceTime(500))
			.subscribe((second) => {
				const isMaxHour = +this.mainForm.get('hour').value >= this.maxHours;
				if (isMaxHour && +second > 0) {
					this.mainForm.patchValue(
						{
							hour: 0,
							second: +second > 59 ? 59 : +second
						},
						{ emitEvent: false }
					);
				} else if (+second > 59) {
					this.mainForm.patchValue(
						{
							second: 59,
						},
						{ emitEvent: false }
					);
				}
			});
	}

	private setValue(runTime: string) {
		if (runTime) {
			const [hour = 0, minute = 0, second = 0] = runTime.split(/:/);
			this.mainForm.patchValue({
				hour: +hour,
				minute: +minute,
				second: +second
			});
		}
	}

	ngAfterViewInit() {
		this.hours.nativeElement.focus();
	}

	@HostListener('document:mousedown', ['$event'])
	onGlobalClick(event): void {
		if (!this.runTimeContainer.nativeElement.contains(event.target)) {
			this.updateRunTimeForCellLocal();
		}
	}

	@HostListener('document:keydown.enter', ['$event']) enterKeyDown(
		event: KeyboardEvent
	) {
		this.enterMoveDown?.emit();
		this.updateRunTimeForCellLocal();
	}

	private updateRunTimeForCellLocal() {
		if (this.mainForm.invalid || this.formDisabled) {
			return;
		}
		const isMaxHour = +this.mainForm.get('hour').value >= this.maxHours;
		const hours = `${+this.mainForm.get('hour').value}`.padStart(2, '0');
		const minutes = `${+this.mainForm.get('minute').value}`.padStart(2, '0')
		const seconds = `${+this.mainForm.get('second').value}`.padStart(2, '0')
		const changedValue = isMaxHour ? `${hours}:00:00` : `${hours}:${minutes}:${seconds}`;
		this.onChange && this.onChange(changedValue);
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppDateAdapter } from '../../custom/date-adapter';
import { ControllerReportListItem } from '../../../api/reports/models/controller-report-list-item.model';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SiteReportListItem } from '../../../api/reports/models/site-report-list-item.model';

@Component({
	selector: 'rb-custom-report-controller-list',
	templateUrl: './custom-report-controller-list.component.html',
	styleUrls: [ './custom-report-controller-list.component.scss' ]
})
export class CustomReportControllerListComponent implements OnInit {

	selectedController: ControllerReportListItem;
	startDateString = '';
	endDateString = '';
	monthString = '';

	@Input() reportName = '';
	@Input() set startDate(value: Date) {
		this.startDateString = value == null ? '' : this.appDateAdapter.format(value);
	}
	@Input() set endDate(value: Date) {
		this.endDateString = value == null ? '' : this.appDateAdapter.format(value);
	}
	@Input() set month(value: number) {
		this.monthString = RbUtils.Months.monthString(value);
	}

	private _sitesAndControllerList: SiteReportListItem[] = [];
	@Input() set sitesAndControllerList(value: SiteReportListItem[]) {
		this._sitesAndControllerList = value || [];
		this.sitesAndControllerListChange.next(value);
		this.selectedController = this._sitesAndControllerList.length === 0 ? null : this._sitesAndControllerList[0].controllers[0];
	}
	get sitesAndControllerList(): SiteReportListItem[] { return this._sitesAndControllerList; }
	@Output() sitesAndControllerListChange = new EventEmitter<SiteReportListItem[]>();

	@Output() controllerSelected = new EventEmitter<ControllerReportListItem>();
	constructor(public appDateAdapter: AppDateAdapter) { }

	ngOnInit() {
	}

	onControllerClicked(controller) {
		this.selectedController = controller;
		this.controllerSelected.emit(controller);
	}
}

import { IIrrigationEngineStationStatusItem } from '../../../api/signalR/_irrigation-engine-station-status-item.interface';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class StationStatus {

	constructor(json: any = null) {
		this.irrigationStatus = RbEnums.Common.IrrigationStatus.Dash;

		if (json) {
			Object.assign(this, json);
		}
	}

	/**
	 * @summary The program number (A=0, B=1, etc.) for which the station is running, pending, etc. In
	 * manual-start-station conditions, the value should be 255.
	 */
	programId = 0;

	canAdvance = false;
	status = '-';
	mapStatus = '';
	irrigationStatus: RbEnums.Common.IrrigationStatus;
	irrigationEngineStationStatusItem: IIrrigationEngineStationStatusItem;
	courseViewStatus = '';	// Similar to status but uses different formatting for Course Viewer.
	secondsRemaining = 0;
	stationId: number;

	/**
	 * When provided by the SignalR message updating the station status, the reason for the "failure". This could be
	 * something like NoFlowCapacity, etc. If null, no failure is assumed.
	 */
	statusReason: RbEnums.Common.StationFailureReasonCode;
}

import * as moment from 'moment';
import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { GroupDecimalSeparatorComponent } from '../../../shared-ui/components/regional-settings/group-separator/group-decimal-separator.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NgForm } from '@angular/forms';
import { RbConstants } from '../../../common/constants/_rb.constants';
import Timer = NodeJS.Timer;
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { Station } from '../../../api/stations/models/station.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-duration-dialog',
	templateUrl: './duration-dialog.component.html',
	styleUrls: ['./duration-dialog.component.scss'],
	animations: [
		trigger('scrollNumber', [
			state('up', style({ transform: 'translateY(0)' })),
			state('down', style({ transform: 'translateY(0)' })),
			transition('* => up', [
				animate(80, keyframes([
					style({transform: 'translateY(0%)', offset: 0}),
					style({transform: 'translateY(-50%)', offset: 1})
				]))
			]),
			transition('* => down', [
				animate(80, keyframes([
					style({transform: 'translateY(0%)', offset: 0}),
					style({transform: 'translateY(50%)', offset: 1})
				]))
			])
		])
	]
})
export class DurationDialogComponent implements OnInit, AfterViewInit {
	@HostBinding('class') class = 'rb-duration';

	@ViewChild(NgForm, { static: true }) form: NgForm;

	@ViewChild(GroupDecimalSeparatorComponent, { static: false }) groupDecimalSeparatorComponent: GroupDecimalSeparatorComponent;

	private _duration = moment.duration();
	@Input() set duration(value: any) {
		if (value == null) return;
		this._duration = value;
		this.setDurationValue();
	}

	private _maxHour = RbConstants.Form.DURATION_HOURS.max;
	@Input() set maxHours(value: any) {
		this._maxHour = value;
		if (this.hours >= this.maxHours && this.hideDays) {
			this.minutes = 0;
		} else {
		this.minutes = this._duration.minutes();
		}
	}

	get maxHours(): any {
		return this._maxHour;
	}

	private _isReadOnly = false;
	@Input() set isReadOnly(value: boolean) { if (value != null) this._isReadOnly = value; }
	get isReadOnly(): boolean { return this._isReadOnly; }

	@Input() hideDays = true;
	@Input() hideHours = false;
	@Input() hideMinutes = false;
	@Input() hideSeconds = false;
	@Input() showButtons = true;
	@Input() showHeader = true;
	@Input() showDurationHeader = false;
	@Input() showSlider = true;
	@Input() maxDays = RbConstants.Form.DURATION_DAYS.max;
	@Input() maxMinutes = RbConstants.Form.DURATION_MINUTES.max;
	@Input() minMinutes = RbConstants.Form.DURATION_MINUTES.min;
	@Input() dialogRef: MatDialogRef<any>;
	@Input() title: string;
	@Input() station;
	@Input() stations: Station[];
	@Input() showTabs = false;
	@Input() isMultiSelectFromMap = false;

	@Output() durationChange = new EventEmitter<moment.Duration>();
	@Output() multipleDurationChange = new EventEmitter<{ [stationId: string]: moment.Duration }>();
	@Output() cancelClick = new EventEmitter();

	days = 0;
	daysString: String;
	hours = 0;
	hoursString: String;
	minutes = 0;
	minutesString: String;
	seconds = 0;
	secondsString: String;
	daysScrollState = '';
	hoursScrollState = '';
	minutesScrollState = '';
	secondsScrollState = '';
	passesField = 0;
	passesForVolume: any;
	precision = 2;
	isGolfSite: boolean;
	inBatchMode = false;

	RbConstants = RbConstants;

	showReadOnlyVolume = true;
	showReadOnlyPasses = true;
	volumeForRotations: any;
	volumeField: any;
	readOnlyRuntimeForRotations = '';
	readOnlyRuntimeForVolume = '';
	passesErrorMessage = '';
	volumeErrorMessage = '';
	convertLengthType: RbEnums.Common.LengthUnit;
	RbEnums = RbEnums;
	isApplicationDisabled = false;
	hintLabel = '';
	submitLabel = '';
	stationsWithNoArcOrRotationTime = '';
	stationsWithNoArcOrRotationTimeArray;
	stationsWithNoPrecRate = '';
	stationsWithNoPrecRateArray;

	private isButtonDown = false;
	private receivedTouchEvent = false;
	private repeatIncrement = 0;
	private repeatSpeedIncrement = 0;
	private repeatTimer: Timer;
	private repeatIncrementFunction: any;
	private repeatFieldToIncrement: string;
	private repeatIntervalFiredCount = 0;
	private cultureSettings: CultureSettings;
	private selectedTab = 0;
	private calculatedRuntimeForRotations: moment.Duration;
	private calculatedRuntimeForApplication: moment.Duration;
	private stationRuntimes: { [stationId: string]: { application: moment.Duration, rotation: moment.Duration } } = {};

	constructor(
		private authManager: AuthManagerService,
		public dialog: MatDialog,
		private siteManager: SiteManagerService,
		private translate: TranslateService) { }

	ngOnInit() {
		this.isGolfSite = this.siteManager.isGolfSite;
		this.days = this._duration.days();
		if (this.days >= this.maxDays) {
			if (this.hideDays) {
				this.days = 0;
				this.hours = RbConstants.Form.DURATION_HOURS.max;
			} else {
				this.days = this.maxDays;
				this.hours = 0;
			}
			this.minutes = 0;
			this.seconds = 0;
			return;
		}
		if (this.hideDays) {
			this.days = 0;
		}
		this.minutes = this._duration.minutes();
		this.seconds = this._duration.seconds();
		this.submitLabel = this.translate.instant('STRINGS.OK');
		if (this.title == null) this.title = this.translate.instant('STRINGS.SET_DURATION');
		if (!this.isGolfSite && this.isMultiSelectFromMap) {
			this.submitLabel = this.translate.instant('STRINGS.START_UPPERCASE');
		}
		this.cultureSettings = this.authManager.userCulture;
		this.getCultureLengthType();
		this.getHintLabel();
	}

	ngAfterViewInit() {
		// RB-15047: This arc and rotationTime time validation should only be in Golf
		if (this.isGolfSite && this.showTabs) {
			setTimeout(() => {
				if (this.station) {
					if (!this.station.arc || !this.station.rotationTime) {
						// RB-15047: If IQ4 station doesn't setup sprinkler detail, we won't have arc and rotation time
						// That station is valid in IQ4. Remove the validation for IQ4
						this.groupDecimalSeparatorComponent.setDisabledState(true);
						this.passesErrorMessage = this.translate.instant('STRINGS.ERROR_NO_ARC_OR_ROTATION');
					}
	
					if (!this.station.precRateFinal) {
						this.isApplicationDisabled = true;
						this.volumeErrorMessage = this.translate.instant('STRINGS.ERROR_NO_PRECIPITATION_RATE');
					}
				} else if (this.stations) {

					this.inBatchMode = true;

					this.volumeForRotations = '-';
					this.readOnlyRuntimeForRotations = '-';
					this.readOnlyRuntimeForVolume = '-';
					this.passesForVolume = '-';

	
					const zeroSeconds = moment.duration(0);
					for (let i = 0; i < this.stations.length; i++) {
						this.stationRuntimes[this.stations[i].id] = { application: zeroSeconds, rotation: zeroSeconds };
					}

					this.stationsWithNoArcOrRotationTimeArray = this.stations
						.reduce<string[]>((prev, current) => { if (!current.arc || !current.rotationTime) { prev.push(current.name); } return prev; }, []);
	
					if (this.stationsWithNoArcOrRotationTimeArray.length > 0) {
						this.groupDecimalSeparatorComponent.setDisabledState(true);
						this.passesErrorMessage = this.translate.instant('STRINGS.ERROR_NO_ARC_OR_ROTATION_MULTIPLE_STATIONS');
					}

					this.stationsWithNoPrecRateArray = this.stations
						.reduce<string[]>((prev, current) => { if (!current.precRateFinal) { prev.push(current.name); } return prev; }, []);
	
					if (this.stationsWithNoPrecRateArray.length > 0) {
						this.isApplicationDisabled = true;
						this.volumeErrorMessage = this.translate.instant('STRINGS.ERROR_NO_PRECIPITATION_RATE_MULTIPLE_STATIONS');
					}
				}
			});
		}
	}

	padNumber(value: number | null) {
		return (value == null ? '0' : value.toString()).padStart(2, '0');
	}

	cancel() {
		this.dialogRef == null ? this.dialog.closeAll() : this.dialogRef.close();
		this.durationChange.emit(null);
		this.cancelClick.emit();
	}

	submit() {
		let duration: moment.Duration;
		let multipleDurations: { [stationId: string]: moment.Duration };

		if (this.showTabs && this.selectedTab != 0) {
			switch (this.selectedTab) {
				
				// Rotations
				case 1:
					if ( this.stations ) {
						multipleDurations = {};
						Object.keys(this.stationRuntimes).forEach(s => multipleDurations[s] = this.stationRuntimes[s].rotation);
					} else {
						duration = this.calculatedRuntimeForRotations
					}
					break;

				// Application
				case 2:
					if ( this.stations ) {
						multipleDurations = {};
						Object.keys(this.stationRuntimes).forEach(s => multipleDurations[s] = this.stationRuntimes[s].application);
					} else {
						duration = this.calculatedRuntimeForApplication;
					}
					break;
			}
		} else {
			duration = moment.duration({
				days: this.days,
				hours: this.hours,
				minutes: this.minutes,
				seconds: this.seconds,
			});
		}

		if (duration) {
			this.durationChange.emit(duration);
		} else {
			this.multipleDurationChange.emit(multipleDurations);
		}
		this.dialogRef == null ? this.dialog.closeAll() : this.dialogRef.close();
	}

	onDaysChanged(days: number, scrollDirection: string): boolean {
		if (days < RbConstants.Form.DURATION_DAYS.min) return false;
		let valid = true;
		if (days >= this.maxDays) {
			this.days = this.maxDays;
			this.hours = 0;
			this.minutes = 0;
			this.seconds = 0;
			valid = false;
		} else {
			this.days = days;
			this.daysScrollState = scrollDirection;
		}

		if (!this.showHeader)
			this.onValueChange();
		this.updateTimeStrings();

		return valid;
	}

	onHoursChanged(hours: number, scrollDirection: string): boolean {
		if (hours < RbConstants.Form.DURATION_HOURS.min) return false;
		let valid = true;
		if (hours >= this.maxHours) {
			this.hours = this.maxHours;
			this.minutes = 0;
			this.seconds = 0;
			valid = false;
		} else {
			this.hours = hours;
			this.hoursScrollState = scrollDirection;
		}

		if (!this.showHeader)
			this.onValueChange();
		else
			this.updateTimeStrings();

		return valid;
	}

	onMinutesChanged(minutes, scrollDirection: string) {
		if (minutes < RbConstants.Form.DURATION_MINUTES.min) return false;
		let valid = true;
		if (this.hours >= this.maxHours && this.hideDays) {
			this.hours = 0;
		}
		if (minutes > this.maxMinutes) {
			this.minutes = this.maxMinutes;
			valid = false;
		} else if (minutes < this.minMinutes) {
			this.minutes = this.minMinutes;
			valid = false;
		} else {
			this.minutes = minutes;
			this.minutesScrollState = scrollDirection;
		}

		if (this.minutes === this.maxMinutes && this.hideHours)	this.seconds = 0;

		if (!this.showHeader)
			this.onValueChange();
		else
			this.updateTimeStrings();

		return valid;
	}

	onSecondsChanged(seconds: number, scrollDirection: string) {
		if (seconds < RbConstants.Form.DURATION_SECONDS.min) return false;
		let valid = true;
		if (seconds > RbConstants.Form.DURATION_SECONDS.max) {
			this.seconds = RbConstants.Form.DURATION_SECONDS.max;
			valid = false;
		} else {
			this.seconds = seconds;
			this.secondsScrollState = scrollDirection;
		}

		if (!this.showHeader)
			this.onValueChange();
		else
			this.updateTimeStrings();

		return valid;
	}

	private setDurationValue() {
		if (this._duration == null) return;
		this.days = this._duration.days();
		this.hours = this._duration.hours();
		if (this.days > 0 && this.hideDays) {
			this.hours = this.hours + (this.days * 24);
			this.days = 0;
		}
		this.minutes = this._duration.minutes();
		this.seconds = this._duration.seconds();
		this.updateTimeStrings();
	}

	private updateTimeStrings() {
		// eslint-disable-next-line no-new-wrappers
		this.daysString = new String(this.days);
		// eslint-disable-next-line no-new-wrappers
		this.hoursString = new String(this.hours);
		// eslint-disable-next-line no-new-wrappers
		this.minutesString = new String(this.minutes);
		// eslint-disable-next-line no-new-wrappers
		this.secondsString = new String(this.seconds);
	}

	scrollDone() {
		this.daysScrollState = '';
		this.hoursScrollState = '';
		this.minutesScrollState = '';
		this.secondsScrollState = '';
	}

	onButtonMouseDown(fieldToIncrement: string, increment: number, speedIncrement: number, incrementFunction: any) {
		if (this.receivedTouchEvent) return;
		this.onTouchDown(fieldToIncrement, increment, speedIncrement, incrementFunction, true);
	}

	onTouchDown(fieldToIncrement: string, increment: number, speedIncrement: number, incrementFunction: any, calledFromMouseEvent: boolean = false) {
		this.repeatIncrement = increment;
		this.repeatSpeedIncrement = speedIncrement;
		this.repeatIncrementFunction = incrementFunction;
		this.repeatFieldToIncrement = fieldToIncrement;
		this.repeatIntervalFiredCount = 0;
		this.isButtonDown = true;
		if (!calledFromMouseEvent) this.receivedTouchEvent = true;
		this.setRepeatTimer();
	}

	onButtonMouseUp() {
		if (this.receivedTouchEvent) return;
		this.onTouchEnd();
	}

	onTouchEnd() {
		this.isButtonDown = false;
		clearTimeout(this.repeatTimer);
		if (this.repeatIntervalFiredCount > 0) return;
		this.repeatIncrementFunction(this[this.repeatFieldToIncrement] + this.repeatIncrement, this.repeatIncrement > 0 ? 'up' : 'down');
	}

	private setRepeatTimer() {
		this.repeatTimer = setTimeout(() => {
			this.repeatIntervalFiredCount++;
			const oldValue = this[this.repeatFieldToIncrement];
			const newValue = (this.repeatIntervalFiredCount <= 4 || this.repeatSpeedIncrement === this.repeatIncrement) ?
				(oldValue + this.repeatIncrement) :
				(this.repeatSpeedIncrement > 0 ?
					(Math.floor((oldValue + this.repeatSpeedIncrement) / this.repeatSpeedIncrement) * this.repeatSpeedIncrement) :
					(Math.ceil((oldValue + this.repeatSpeedIncrement) / -this.repeatSpeedIncrement) * -this.repeatSpeedIncrement));
			// If the increment function returns false then the new value is invalid so stop repeating.
			if (!this.repeatIncrementFunction(newValue,	this.repeatIncrement > 0 ? 'up' : 'down')) return;
			if (!this.isButtonDown) return;
			this.setRepeatTimer();
		}, this.repeatIntervalFiredCount === 0 ? 0 : this.repeatIntervalFiredCount === 1 ? 500 : 100);
	}

	get isDurationSet(): boolean {
		return this.days + this.hours + this.minutes + this.seconds >= 0;
	}

	lengthChange(value) {
		this.volumeField = value;
		if (this.station) {
			this.calculatedRuntimeForApplication = RbUtils.Stations.getRuntimeFromApplication(this.station, this.volumeField, this.convertLengthType);
			this.getReadonlyRuntime(this.calculatedRuntimeForApplication, 'volume');

			// Calculate the secs for one pass takes
			this.calculatePasses(this.calculatedRuntimeForApplication);
		} else if (this.stations) {
			for(let i = 0; i < this.stations.length; i++) {
				this.stationRuntimes[this.stations[i].id].application = 
					RbUtils.Stations.getRuntimeFromApplication(this.stations[i], this.volumeField, this.convertLengthType);
			}
		}
	}

	passesChanges(passes: number) {
		if (this.station) {
			// Calculate the secs for one pass takes
			const runtimeMoment = RbUtils.Stations.getRuntimeFromRotation(this.station, passes);
			this.calculatedRuntimeForRotations = runtimeMoment;
			this.getReadonlyRuntime(runtimeMoment, 'rotations');
			this.volumeForRotations = (runtimeMoment.asHours() * this.station.precRateFinal).toFixed(this.precision);
			this.volumeForRotations = this.getVolumeCalculated(this.volumeForRotations);
		} else {
			for(let i = 0; i < this.stations.length; i++) {
				this.stationRuntimes[this.stations[i].id].rotation = RbUtils.Stations.getRuntimeFromRotation(this.stations[i], passes);
			}
		}
	}

	onValueChange() {
		const duration = moment.duration({
			days: this.days,
			hours: this.hours,
			minutes: this.minutes,
			seconds: this.seconds,
		});
		this.durationChange.emit(duration);
	}
	
	selectedTabChange(event: MatTabChangeEvent) {
		this.selectedTab = event.index;
	}

	private calculatePasses(calculatedBaseRunTime: moment.Duration) {
		if (this.station.arc && this.station.rotationTime) {
			const onePass = (this.station.arc / 360) * this.station.rotationTime;
			this.passesForVolume = (calculatedBaseRunTime.asSeconds() / onePass).toFixed(this.precision);
		} else
			// RbConstants.Form.PASSES.min
			this.passesForVolume = '-';
	}

	private getReadonlyRuntime(duration: moment.Duration, origin: 'volume' | 'rotations') {
		if (origin == 'volume') {
			this.readOnlyRuntimeForVolume = RbUtils.Conversion.convertDurationToTimeSpanString(duration);
		} else {
			this.readOnlyRuntimeForRotations = RbUtils.Conversion.convertDurationToTimeSpanString(duration);
		}
	}

	private getVolumeCalculated(value: number) {
		const unit = this.cultureSettings.unitType === RbEnums.Common.UnitsType.English ? RbEnums.Common.LengthUnit.Inch : RbEnums.Common.LengthUnit.Millimeter;
		return +RbUtils.Common.convertLengthToSaveData(value, RbEnums.Common.LengthUnit.Inch, unit).toFixed(2);
	}

	private getCultureLengthType() {
		if (this.cultureSettings.unitType === RbEnums.Common.UnitsType.English)
			this.convertLengthType = RbEnums.Common.LengthUnit.Inch;
		else
			this.convertLengthType = RbEnums.Common.LengthUnit.Millimeter;
	}

	private getHintLabel() {
		this.hintLabel = RbUtils.Common.getLengthUnitsString(this.convertLengthType);
	}

}

import { CellEditorValidationParams } from './cell-editor-validation-params';
import { ControllerListItem } from '../../../../../api/controllers/models/controller-list-item.model';
import { ICellEditorParams } from 'ag-grid-community';
import { IRbEditableGridColDef } from './rb-editable-grid-col-def.interface';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';
import { SelectListItem } from '../../../../../api/_common/models/select-list-item.model';

export enum CycleSoakRuntime {
	Runtime,
	Cycle,
	Soak
}

export interface IRbCellEditorParams extends ICellEditorParams {

	colDef: IRbEditableGridColDef;
	validate?: (params: CellEditorValidationParams) => Observable<boolean>;
	minLength?: number;
	maxLength?: number;
	textAlign?: string;
	controller?: ControllerListItem;
	maxValue?: number;
	minValue?: number;
	cycleSoakRuntime?: CycleSoakRuntime;
	getListItems?: (params?: any, selectList?: MatSelect) => SelectListItem[];
	getSelectedValue?: (value?: any) => any;
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class CustomTranslateHttpLoaderService implements TranslateLoader {

	/**
	 * CustomTranslateHttpLoaderService provides translation file loading from HttpClient, as TranslateHttpLoader does,
	 * but with access to the options/headers for the request.
	 * @param http - HttpClient used for translation file retrieval
	 * @param prefix - path and file name prefix(es) for each language file. These will be prepended to the language name
	 * during retrieval
	 * @param suffix - suffix for path and file name, usually the file extension (ie. .json)
	 * @param headers - headers that must be attached to the request
	 */
	constructor(private http: HttpClient, public prefix?: string, public suffix?: string, 
		public headers?: HttpHeaders | { [header: string]: string | string[]; }) {
			if (prefix === void 0) { prefix = "/assets/i18n/"; }
			if (suffix === void 0) { suffix = ".json"; }
			this.prefix = prefix;
			this.suffix = suffix;
		}

    /**
     * Gets the translations from the server. Chooses the default request, if headers were not configured. Sends the
	 * headers, if configured.
     * @param lang - string like 'en' or 'fr', etc.
     * @returns result of HttpClient.get()
     */
    getTranslation(lang: string): any {
		if (!this.headers) {
			return this.http.get("" + this.prefix + lang + this.suffix);
		} else {
			// Apply the headers to the http.options entry.
			return this.http.get("" + this.prefix + lang + this.suffix, this.headers ? { headers: this.headers } : null);
		}
	}
}

import { AppDateAdapter } from '../../shared-ui/custom/date-adapter';
import { ChangeBase } from './change-base.model';
import { ConnectDataPack } from '../connect-data-pack/models/connect-data-pack.model';
import { DiagnosticsSegmentInfo } from '../manual-ops/models/diagnostics-segment-info.model';
import { FlowMonitoringStatusResult } from '../controllers/models/flow-monitoring-status-result.model';
import { MasterValveStatus } from '../manual-ops/models/master-valve-status.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { StationShortReport } from '../manual-ops/models/station-short-report.model';
import { TwoWireData } from '../manual-ops/models/two-wire-data.model';
import { UniversalDeviceResponseStatuses } from '../manual-ops/models/universal-device-response-statuses.model';
import { UniversalMasterValveStatus } from '../manual-ops/models/universal-master-valve-status.model';
import { UniversalPingResultData } from '../manual-ops/models/universal-ping-result-data.model';
import { UniversalTwoWireData } from '../manual-ops/models/universal-two-wire-data.model';
import { UniversalTwoWireDevicePaths } from '../manual-ops/models/universal-two-wire-device-paths.model';
import { UniversalWeatherSensorStatus } from '../manual-ops/models/universal-weather-sensor-status.model';
import { WeatherSensorStatus } from '../manual-ops/models/weather-sensor-status.model';

export class ControllerChange extends ChangeBase {

	constructor(json: any = null) {

		super(json);

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here

			if (json.changeDateTime) { this.changeDateTime = RbUtils.Conversion.convertStringToDate(json.changeDateTime); }
			if (json.connectDataPack) { this.connectDataPack = new ConnectDataPack(json.connectDataPack); }
			if (json.weatherSensorStatus) {
				this.weatherSensorStatus = new WeatherSensorStatus(json.weatherSensorStatus, json.satelliteId, json.changeDateTime);
			}
			if (json.twoWireData) { this.twoWireData = new TwoWireData(json.twoWireData, json.satelliteId, json.changeDateTime); }
			if (json.masterValveStatus) { this.masterValveStatus = new MasterValveStatus(json.masterValveStatus, json.satelliteId, json.changeDateTime); }
			if (json.universalTwoWireData) {
				this.universalTwoWireData = new UniversalTwoWireData(json.universalTwoWireData, json.satelliteId, json.changeDateTime);
			}
			if (json.universalDeviceResponseStatuses) {
				this.universalDeviceResponseStatuses =
					new UniversalDeviceResponseStatuses(json.universalDeviceResponseStatuses, json.satelliteId, json.changeDateTime);
			}
			if (json.universal2WireDevicePaths) {
				this.universal2WireDevicePaths = new UniversalTwoWireDevicePaths(json.universal2WireDevicePaths, json.satelliteId, json.changeDateTime);
			}
			if (json.diagnosticsSegmentInfo) {
				this.diagnosticsSegmentInfo = new DiagnosticsSegmentInfo(json.diagnosticsSegmentInfo, json.satelliteId, json.changeDateTime);
			}
			if (json.universalMasterValveStatus) {
				this.universalMasterValveStatus = new UniversalMasterValveStatus(json.universalMasterValveStatus, json.satelliteId, json.changeDateTime);
			}
			if (json.universalWeatherSensorStatus) {
				this.universalWeatherSensorStatus = new UniversalWeatherSensorStatus(json.universalWeatherSensorStatus, json.satelliteId, json.changeDateTime);
			}

			if (json.lastRetrieveLogsCompleted) {
				this.lastRetrieveLogsCompleted = json.lastRetrieveLogsCompleted ? AppDateAdapter.getLocalDate(json.lastRetrieveLogsCompleted) : null;
			}

			if (json.hasLastSyncDifferences) {
				this.hasLastSyncDifferences = json.hasLastSyncDifferences;
			}

		}
	}

	changeDateTime: Date;
	changeType: string;
	channelNumber: number = null;
	companyId: number;
	connectDataPack: ConnectDataPack;
	errorMessage: string = null;
	flowZoneFlowRates: number[] = null;
	groupNumber: number = null;
	itemsChanged: any;
	jobId: any = null;						// TODO: MW: Change TYPE once we know type
	reasonCode: string;
	satelliteId: number;
	syncState: any = null;					// TODO: MW: Change Type once we know it.
	frontPanelSyncState: RbEnums.SignalR.FrontPanelSyncState;
	flowMonitoringStatusResult: FlowMonitoringStatusResult;
	weatherSensorStatus: WeatherSensorStatus;
	twoWireData: TwoWireData;
	masterValveStatus: MasterValveStatus;
	universalTwoWireData: UniversalTwoWireData;
	universalDeviceResponseStatuses: UniversalDeviceResponseStatuses;
	universal2WireDevicePaths: UniversalTwoWireDevicePaths;
	universalPingResultData: UniversalPingResultData;
	diagnosticsSegmentInfo: DiagnosticsSegmentInfo;
	universalMasterValveStatus: UniversalMasterValveStatus;
	universalWeatherSensorStatus: UniversalWeatherSensorStatus;
	irrigationState: string;
	stationShortReport: StationShortReport;
	lastRetrieveLogsCompleted: Date;
	hasLastSyncDifferences: boolean | null = null;	
	
	get aggregateActualFlowRate(): number {
		if (this.flowZoneFlowRates === null || this.flowZoneFlowRates.length < 1) return 0;

		return this.flowZoneFlowRates.reduce((a, b) => a + b, 0);
	}
}

export class ControllerLocalChange {
	controllerIds: number[] = [];
	patchValues: { [key: string]: any} = {};

	constructor(controllerIds: number[] = [], patchValues: {[key: string]: any} = {}) {
		this.controllerIds = controllerIds;
		this.patchValues = patchValues;
	}
}

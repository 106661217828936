import { DomUtil } from "leaflet";
import { Injectable } from "@angular/core";
import { IStation } from "../models/station.interface";
import { StationWithMapInfoLeaflet } from "../models/station-with-map-info-leaflet.model";
import { Subject } from "rxjs/internal/Subject";

@Injectable({
	providedIn: 'root',
})

export class MultiSelectService {
    private selectedStations: IStation[] = [];
    private selectedStationsCount = new Subject<number>();
    private disableReselect = new Subject<boolean>();
    private disableResume = new Subject<boolean>();
    private station = new Subject<StationWithMapInfoLeaflet>();
    private clearAll = new Subject<boolean>();
    private holeAndArea = new Subject<{holeId: number; areaId: number, show: boolean}>();

    setDisableReselect(disable: boolean){
        this.disableReselect.next(disable);
    }

    getDisableReselect(){
        return this.disableReselect;
    }

    setDisableResume(disable: boolean){
        this.disableResume.next(disable);
    }

    getDisableResume(){
        return this.disableResume;
    }

    getSelectedStations() {
        return this.selectedStations;
    }

    getStationId():number[] {
        return this.selectedStations.map(item => item.station.id);
    }

    getSatelliteId():number[] {
        return this.selectedStations.map(item => item.station.satelliteId);
    }

    findIndexOfStation(id: number){
        const itemIdx = this.selectedStations.findIndex(
            item => item.station.id === id
        );
        return itemIdx;
    }

    selectStation(station: StationWithMapInfoLeaflet){
        if(station.marker && station.marker['_icon']){
            DomUtil.addClass(station.marker['_icon'], 'station-selected');
        }
        if(!station.isSelected){
            station.isSelected = true;
            this.selectedStations.push({station});
            this.selectedStationsCount.next(this.selectedStations.length);
        }
    }

    selectStationMap(station: StationWithMapInfoLeaflet){
        this.selectStation(station);
        this.station.next(station);
    }

    deselectStation(station: StationWithMapInfoLeaflet){        
        if(station.marker && station.marker['_icon']){
            DomUtil.removeClass(station.marker['_icon'], 'station-selected');
        }

        station.isSelected = false;
        
		const itemIdx = this.selectedStations.findIndex(
			item => item.station.id === station.id
		);
        
		if (itemIdx > -1) {
            this.selectedStations.splice(itemIdx, 1);
		}
        this.selectedStationsCount.next(this.selectedStations.length);
    }

    deselectStationMap(station: StationWithMapInfoLeaflet){
        this.deselectStation(station);
        this.station.next(station);
    }

    getStation(){
        return this.station;
    }

    getClearAll(){
        return this.clearAll;
    }

    getNumberStations(){
        return this.selectedStationsCount;
    }

    deselectAllStations(){
        for(let index = 0; index < this.selectedStations.length; index++){
            if(this.selectedStations[index].station.marker && this.selectedStations[index].station.marker['_icon']){
                DomUtil.removeClass(this.selectedStations[index].station.marker['_icon'], 'station-selected');
            }
            this.selectedStations[index].station.isSelected = false;
		}
        this.selectedStations = [];
        this.selectedStationsCount.next(0);
        this.clearAll.next(true);
    }

    setIcon(holeAndArea: {holeId: number; areaId: number, show: boolean}){
        this.holeAndArea.next(holeAndArea);
    }

    getIcon(){
        return this.holeAndArea;
    }
}
<div id='ccweather-widget-container'>
	<ng-container *ngIf='!showSettings'>
		<rb-ccweather-widget-current class='ccweather-widget-section-border'
									 [widgetId]='widgetId'
									 [isIq4SidebarWidget]='isIq4SidebarWidget'
									 [theme]='theme'
		></rb-ccweather-widget-current>
		<rb-ccweather-widget-hourly *ngIf='isHourlyVisible'
									[ngClass]="{ 'ccweather-widget-section-border': isDailyVisible}"
									[widgetId]='widgetId'
									[timezone]='timezoneAbbreviation'
									[isIq4SidebarWidget]='isIq4SidebarWidget'
									[theme]='theme'
		></rb-ccweather-widget-hourly>
		<rb-ccweather-widget-daily *ngIf='isDailyVisible'
								   [widgetId]='widgetId'
								   [timezone]='timezoneAbbreviation'
								   [isIq4SidebarWidget]='isIq4SidebarWidget'
								   [theme]='theme'
		></rb-ccweather-widget-daily>
		<div class='ccweather-last-updated'>
			<span>{{'STRINGS.LAST_UPDATED' | translate}}: {{lastUpdated}}</span>
		</div>
	</ng-container>
	<ng-container *ngIf='showSettings'>
		<rb-ccweather-widget-settings
			[isInitialized]='isInitialized'
			[widgetId]='widgetId'
			[isIq4SidebarWidget]='isIq4SidebarWidget'
			[associatedWidget]='associatedWidget'
			[theme]='theme'
			(settingsClosed)='onCloseSettings($event)'
		></rb-ccweather-widget-settings>
	</ng-container>
</div>

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ProgramProjectedTime } from '../../../../../api/programs/models/program-projected-time.model';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-schedule-program-cell-renderer',
	templateUrl: './schedule-program-cell-renderer.component.html',
	styleUrls: ['./schedule-program-cell-renderer.component.scss']
})
export class ScheduleProgramCellRendererComponent implements ICellRendererAngularComp {
	programProjectedTime: ProgramProjectedTime;
	RbEnums = RbEnums;
	constructor() {
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.setCellData(params);
	}

	refresh(params: any): boolean {
		this.setCellData(params);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setCellData(params: any) {
		this.programProjectedTime = params.data;
	}
}

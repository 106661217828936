import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'rb-no-internet',
	templateUrl: './no-internet.component.html',
	styleUrls: ['./no-internet.component.scss']
})

export class NoInternetComponent implements OnInit {
	constructor() {

	}

	ngOnInit() {
	}
}

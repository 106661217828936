import { BroadcastService } from '../../common/services/broadcast.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PinCode } from './models/pin-code.model';
import { PinCodeApiService } from './pin-code-api.service';
import { PinCodeListItem } from './models/pin-code-list-item.model';
import { PinCodeUpdate } from './models/pin-code-update.model';
import { SatellitePinCode } from './models/satellite-pin-code.model';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class PinCodeManagerService extends ServiceManagerBase {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private pinCodeApiService: PinCodeApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getMyPinCode(): Observable<PinCode> {
		return this.pinCodeApiService.getMyPinCode();
	}

	getPinCodes(): Observable<PinCodeListItem[]> {
		return this.pinCodeApiService.getPinCodes();
	}

	getSatellitePinCodes(controllerId: number): Observable<SatellitePinCode[]> {
		return this.pinCodeApiService.getSatellitePinCodes(controllerId);
	}

	getPinCodeByUserId(userId: number): Observable<PinCode> {
		return this.pinCodeApiService.getPinCodeByUserId(userId);
	}

	saveSatellitePinCodes(controllerId: number, pinCodeIds: number[]): Observable<null> {
		return this.pinCodeApiService.saveSatellitePinCodes(controllerId, pinCodeIds);
	}

	updatePinCode(pinCodeUpdate: PinCodeUpdate): Observable<void> {
		return this.pinCodeApiService.updatePinCode(pinCodeUpdate);
	}

}

import { Area } from '../../api/areas/models/area.model';
import { ControllerListItem } from '../../api/controllers/models/controller-list-item.model';
import { GeoItem } from '../../api/regions/models/geo-item.model';
import { MapInfo } from './map-info.model';
import { RbEnums } from '../enumerations/_rb.enums';
import { Station } from '../../api/stations/models/station.model';
import { StationListItem } from '../../api/stations/models/station-list-item.model';
import { TranslateService } from '@ngx-translate/core';

export class MapInfoContextMenu {

	// Context-menu data
	private _holeOptions = [
		{ text: this.translate.instant('STRINGS.PAUSE'), value: RbEnums.Map.HoleContextMenu.Pause, icon: 'icon-pause' },
		{ text: this.translate.instant('STRINGS.RESUME'), value: RbEnums.Map.HoleContextMenu.Resume, icon: 'icon-advance' },
		{ text: this.translate.instant('STRINGS.START'), value: RbEnums.Map.HoleContextMenu.Start, icon: 'icon-start' },
		{ text: this.translate.instant('STRINGS.STOP'), value: RbEnums.Map.HoleContextMenu.Stop, icon: 'icon-stop' },
		{ text: this.translate.instant('STRINGS.ADD_TO_MAP'), value: RbEnums.Map.HoleContextMenu.AddToMap, icon: 'icon-add' },
		{ text: this.translate.instant('STRINGS.FIND_ON_MAP'), value: RbEnums.Map.HoleContextMenu.FindOnMap, icon: 'icon-compass' },
		{ text: this.translate.instant('STRINGS.REMOVE'), value: RbEnums.Map.HoleContextMenu.Remove, icon: 'icon-remove' },
		{ text: this.translate.instant('STRINGS.EDIT'), value: RbEnums.Map.HoleContextMenu.Edit, icon: 'icon-edit' },
	];
	private _areaOptions = [
		{ text: this.translate.instant('STRINGS.PAUSE'), value: RbEnums.Map.AreaContextMenu.Pause, icon: 'icon-pause' },
		{ text: this.translate.instant('STRINGS.RESUME'), value: RbEnums.Map.AreaContextMenu.Resume, icon: 'icon-advance' },
		{ text: this.translate.instant('STRINGS.START'), value: RbEnums.Map.AreaContextMenu.Start, icon: 'icon-start' },
		{ text: this.translate.instant('STRINGS.STOP'), value: RbEnums.Map.AreaContextMenu.Stop, icon: 'icon-stop' },
		{ text: this.translate.instant('STRINGS.ADVANCE'), value: RbEnums.Map.AreaContextMenu.Advance, icon: 'icon-advance' },
		{ text: this.translate.instant('STRINGS.CALCULATE_AREA'), value: RbEnums.Map.AreaContextMenu.CalculateArea, icon: 'icon-area' },
		{ text: this.translate.instant('STRINGS.ADD_TO_MAP'), value: RbEnums.Map.AreaContextMenu.AddToMap, icon: 'icon-add' },
		{ text: this.translate.instant('STRINGS.FIND_ON_MAP'), value: RbEnums.Map.AreaContextMenu.FindOnMap, icon: 'icon-compass' },
		{ text: this.translate.instant('STRINGS.EDIT_SHAPE'), value: RbEnums.Map.AreaContextMenu.EditShape, icon: 'icon-drawing-lasso' },
		{ text: this.translate.instant('STRINGS.VIEW_SHAPE'), value: RbEnums.Map.AreaContextMenu.ViewShape, icon: 'icon-drawing-lasso' },
		{ text: this.translate.instant('STRINGS.REMOVE'), value: RbEnums.Map.AreaContextMenu.Remove, icon: 'icon-remove' },
		{ text: this.translate.instant('STRINGS.EDIT'), value: RbEnums.Map.AreaContextMenu.Edit, icon: 'icon-edit' },
	];

	private _stationOptions = [
		{ text: this.translate.instant('STRINGS.PAUSE'), value: RbEnums.Map.StationContextMenu.Pause, icon: 'icon-pause' },
		{ text: this.translate.instant('STRINGS.RESUME'), value: RbEnums.Map.StationContextMenu.Resume, icon: 'icon-advance' },
		{ text: this.translate.instant('STRINGS.START'), value: RbEnums.Map.StationContextMenu.Start, icon: 'icon-start' },
		{ text: this.translate.instant('STRINGS.STOP'), value: RbEnums.Map.StationContextMenu.Stop, icon: 'icon-stop' },
		{ text: this.translate.instant('STRINGS.ADD_TO_MAP'), value: RbEnums.Map.StationContextMenu.AddToMap, icon: 'icon-add' },
		{ text: this.translate.instant('STRINGS.FIND_ON_MAP'), value: RbEnums.Map.StationContextMenu.FindOnMap, icon: 'icon-compass' },
		{ text: this.translate.instant('STRINGS.REMOVE'), value: RbEnums.Map.StationContextMenu.Remove, icon: 'icon-remove' },
		// { text: this.translate.instant('STRINGS.PROGRAMS_AND_SCHEDULES'), value: RbEnums.Map.StationContextMenu.ProgramsAndSchedules, icon: 'icon-search' },
		{ text: this.translate.instant('STRINGS.EDIT'), value: RbEnums.Map.StationContextMenu.Edit, icon: 'icon-edit' },
	];

	private _controllerOptions = [
		{ text: this.translate.instant('STRINGS.SYNC'), value: RbEnums.Map.ControllerContextMenu.Sync, icon: 'icon-snyc' },
		{ text: this.translate.instant('STRINGS.REVERSE'), value: RbEnums.Map.ControllerContextMenu.ReverseSync, icon: 'icon-reverse' },
		{ text: this.translate.instant('STRINGS.INCOMPLETE'), value: RbEnums.Map.ControllerContextMenu.Incomplete, icon: 'icon-sync_problem' },
		{ text: this.translate.instant('STRINGS.LOGS'), value: RbEnums.Map.ControllerContextMenu.Logs, icon: 'icon-log' },
		{ text: this.translate.instant('STRINGS.CONNECT'), value: RbEnums.Map.ControllerContextMenu.Connect, icon: 'icon-connection' },
		{ text: this.translate.instant('STRINGS.DISCONNECT'), value: RbEnums.Map.ControllerContextMenu.Disconnect, icon: 'icon-disconnect' },
		{ text: this.translate.instant('STRINGS.STOP'), value: RbEnums.Map.ControllerContextMenu.StopAll, icon: 'icon-stop' },
		{ text: this.translate.instant('STRINGS.ADD_TO_MAP'), value: RbEnums.Map.ControllerContextMenu.AddToMap, icon: 'icon-add' },
		{ text: this.translate.instant('STRINGS.FIND_ON_MAP'), value: RbEnums.Map.ControllerContextMenu.FindOnMap, icon: 'icon-compass' },
		{ text: this.translate.instant('STRINGS.REMOVE'), value: RbEnums.Map.ControllerContextMenu.Remove, icon: 'icon-remove' },
		{ text: this.translate.instant('STRINGS.EDIT'), value: RbEnums.Map.ControllerContextMenu.Edit, icon: 'icon-edit' },
	];

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private mapInfo: MapInfo,
				private isGolfSite: boolean,
				private translate: TranslateService
	) {
		// Insert 'Programs and Schedules' just above 'Edit' for Golf only.
		if (this.isGolfSite) {
			this._stationOptions.splice(-1, 0, {
					text: this.translate.instant('STRINGS.PROGRAMS_AND_SCHEDULES'),
					value: RbEnums.Map.StationContextMenu.ProgramsAndSchedules,
					icon: 'icon-search'
				}
			);
		}
	}

	// =========================================================================================================================================================
	// Pubic Methods
	// =========================================================================================================================================================

	stationOptions(station: Station | StationListItem, menuLocationX: number, menuLocationY: number,
				useFullMenu: boolean = false, includeFind: boolean = false): any {
		let options = !useFullMenu ? this._stationOptions.filter(o => o.value === RbEnums.Map.StationContextMenu.FindOnMap &&
				includeFind && station.latitude != null && station.longitude != null) :
			this._stationOptions.filter(o =>
				(o.value !== RbEnums.Map.StationContextMenu.AddToMap || (includeFind && !station.hasLocation)) &&
				(o.value !== RbEnums.Map.StationContextMenu.FindOnMap || (includeFind && station.hasLocation)) &&
				(o.value !== RbEnums.Map.StationContextMenu.Edit || useFullMenu) &&
				(o.value !== RbEnums.Map.StationContextMenu.Remove || station.hasLocation));
		if (!this.isGolfSite) {
			const stopOption = options.find(opt => opt.value === RbEnums.Map.StationContextMenu.Stop);
			if (stopOption != null) {
				stopOption.text = this.translate.instant('STRINGS.ADVANCE');
				stopOption.value = RbEnums.Map.StationContextMenu.Advance;
				stopOption.icon = 'icon-advance';
			}
			options = options.filter(o => o.value !== RbEnums.Map.StationContextMenu.Pause && o.value !== RbEnums.Map.StationContextMenu.Resume);
		}
		return {
			station: station,
			menuOptions: options,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'station.png',
			title: station.name,
		};
	}

	areaOptions(holeId: number, area: Area, geoItem: GeoItem, menuLocationX: number, menuLocationY: number,
				useFullMenu: boolean, includeFind: boolean, mapMarker: google.maps.Polygon): any {
		const areaHasStations = this.mapInfo.stationsForHoleAndArea(holeId, area.id).length > 0;
		const hasGeoItems = this.mapInfo.getGeoItemsForArea(holeId, area.id).length > 0;
		const options = !useFullMenu ? this._areaOptions.filter(o => o.value === RbEnums.Map.AreaContextMenu.FindOnMap && hasGeoItems) :
			this._areaOptions.filter(o =>
				(o.value !== RbEnums.Map.AreaContextMenu.AddToMap || includeFind) &&
				(o.value !== RbEnums.Map.AreaContextMenu.FindOnMap || (includeFind && hasGeoItems)) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Start || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Stop || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Pause || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Resume || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Advance || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Edit || useFullMenu) &&
				(o.value !== RbEnums.Map.AreaContextMenu.EditShape || (useFullMenu && mapMarker != null && !mapMarker.getEditable())) &&
				(o.value !== RbEnums.Map.AreaContextMenu.ViewShape || (useFullMenu && mapMarker != null && mapMarker.getEditable())) &&
				(o.value !== RbEnums.Map.AreaContextMenu.CalculateArea || (useFullMenu && hasGeoItems)) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Remove || geoItem != null));

		const hole = this.mapInfo.holes.find(h => h.id === holeId);
		return {
			holeId: holeId,
			area: area,
			geoItem: geoItem,
			menuOptions: options,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'location_colored.png',
			title: `${hole.name} - ${area.name}`,
		};
	}

	holeOptions(hole: Area, menuLocationX: number, menuLocationY: number,
				useFullMenu: boolean = false, includeFind: boolean = false): any {
		const holeHasStations = this.mapInfo.stationsForHole(hole.id).length > 0;
		const geoItem = this.mapInfo.getGeoItemForHole(hole.id);
		const options = !useFullMenu ? this._holeOptions.filter(o => o.value === RbEnums.Map.HoleContextMenu.FindOnMap && geoItem != null) :
			this._holeOptions.filter(o =>
				(o.value !== RbEnums.Map.HoleContextMenu.AddToMap || (includeFind && geoItem == null)) &&
				(o.value !== RbEnums.Map.HoleContextMenu.FindOnMap || (includeFind && geoItem != null)) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Start || holeHasStations) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Stop || holeHasStations) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Pause || holeHasStations) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Resume || holeHasStations) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Edit || useFullMenu) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Remove || geoItem != null));
		return {
			hole: hole,
			menuOptions: options,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'flag_colored.png',
			title: hole.name,
		};
	}

	controllerOptions(controller: ControllerListItem, menuLocationX: number, menuLocationY: number,
					useFullMenu: boolean = false, includeFind: boolean = false): any {
		const hasCoordinates = controller.latitude != null && controller.longitude != null;
		const options = !useFullMenu ? this._controllerOptions.filter(o => o.value === RbEnums.Map.ControllerContextMenu.FindOnMap &&
				includeFind && hasCoordinates) :
			this._controllerOptions.filter(o =>
				(o.value !== RbEnums.Map.ControllerContextMenu.AddToMap || (includeFind && !hasCoordinates)) &&
				(o.value !== RbEnums.Map.ControllerContextMenu.FindOnMap || (includeFind && hasCoordinates)) &&
				(o.value !== RbEnums.Map.ControllerContextMenu.Edit || useFullMenu) &&
				(o.value !== RbEnums.Map.ControllerContextMenu.Remove || hasCoordinates));
		return {
			controller: controller,
			menuOptions: options,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'controller_icon.jpg',
			title: controller.name,
		};
	}
}

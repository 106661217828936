/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';

@UntilDestroy()
@Directive()
export abstract class ServiceManagerBase implements OnDestroy {

	protected constructor(protected broadcastService: BroadcastService) {

		this.broadcastService.userSignedOut.pipe(untilDestroyed(this)).subscribe(() => this.clearAllCaches());
		this.broadcastService.cultureChanged.pipe(untilDestroyed(this)).subscribe(() => this.clearCultureCache());
	}

	ngOnDestroy(): void {}

	protected clearCache() {}

	protected clearCultureCache() {}

	protected clearAllCaches() {
		this.clearCache();
		this.clearCultureCache();
	}
}

/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Weekdays.getStartDays
 * 		 See: _rb.utils.ts
 */

import { AppInjector } from '../../core/core.module';
import { CultureSettings } from '../../api/culture-settings/models/culture-settings.model';
import { DayOfWeekListItem } from '../../api/culture-settings/models/day-of-week-list-item.model';
import { ProgramGroupManagerService } from '../../api/program-groups/program-group-manager.service';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from './_rb.utils';

export namespace XXUseRbUtilsNamespace {

	/**
	 * @summary Weekdays provides utilities for display of start days/program types used in program display
	 * and editing. This class assumes that the ProgramTypes[] list will be available by the time any of
	 * the public calls are made (so those calls can return string, rather than Observable<string>). This
	 * means that the ProgramGroupManagerService must auto-start an API request to get the types when it
	 * is instantiated.
	 */
	export abstract class Weekdays {

		// =====================================================================================================================================================
		// Public Methods
		// =====================================================================================================================================================

		static getStartDays(type: number, weekDays: string, hybridWeekDays: string): string {
			let startDays = '';

			switch (type) {
				case RbEnums.Common.ProgramType.Custom:
					startDays = this.getWeekDays(weekDays);
					break;

				case RbEnums.Common.ProgramType.Cyclical:
				case RbEnums.Common.ProgramType.Even:
				case RbEnums.Common.ProgramType.Odd31:
				case RbEnums.Common.ProgramType.Odd:
					startDays = this.getHybridWeekDays(Weekdays.getProgramTypeString(type), hybridWeekDays);
					break;
			}

			return (startDays !== '') ? startDays : '-';
		}

		static getStartDaysData(type: number, weekDays: string, hybridWeekDays: string): string {
			switch (type) {
				case RbEnums.Common.ProgramType.Custom:
					return weekDays;
				case RbEnums.Common.ProgramType.Cyclical:
				case RbEnums.Common.ProgramType.Even:
				case RbEnums.Common.ProgramType.Odd31:
				case RbEnums.Common.ProgramType.Odd:
					return hybridWeekDays;
			}
			return '';
		}

		static getCultureSettingWeekDay(cultureSettings: CultureSettings) {
			return this.cultureSettingWeekDays(cultureSettings);
		}

		// =====================================================================================================================================================
		// Helper Methods
		// =====================================================================================================================================================

		private static getProgramTypeString(type: number): string {
			const programGroupManager = AppInjector.get(ProgramGroupManagerService);
			return programGroupManager.getProgramTypesImmediate().find(t => t.value === type).name;
		}

		static getDaysOfWeek(): DayOfWeekListItem[] {
			return [
				{
					name: RbUtils.Translate.instant('WEEKDAYS.SUNDAY'),
					value: 0,
				},
				{
					name: RbUtils.Translate.instant('WEEKDAYS.MONDAY'),
					value: 1,
				},
				{
					name: RbUtils.Translate.instant('WEEKDAYS.TUESDAY'),
					value: 2,
				},
				{
					name: RbUtils.Translate.instant('WEEKDAYS.WEDNESDAY'),
					value: 3,
				},
				{
					name: RbUtils.Translate.instant('WEEKDAYS.THURSDAY'),
					value: 4,
				},
				{
					name: RbUtils.Translate.instant('WEEKDAYS.FRIDAY'),
					value: 5,
				},
				{
					name: RbUtils.Translate.instant('WEEKDAYS.SATURDAY'),
					value: 6,
				},
			];
		}

		static getWeekDayAbbreviation(i: number): string {
			switch (i) {
				case 1: return RbUtils.Translate.instant('WEEKDAYS.MON');
				case 2: return RbUtils.Translate.instant('WEEKDAYS.TUE');
				case 3: return RbUtils.Translate.instant('WEEKDAYS.WED');
				case 4: return RbUtils.Translate.instant('WEEKDAYS.THU');
				case 5: return RbUtils.Translate.instant('WEEKDAYS.FRI');
				case 6: return RbUtils.Translate.instant('WEEKDAYS.SAT');
				case 0: return RbUtils.Translate.instant('WEEKDAYS.SUN');
			}
		}

		static getWeekDayAbbreviationUppercase(i: number): string {
			switch (i) {
				case 1: return RbUtils.Translate.instant('WEEKDAYS.MON_UPPERCASE');
				case 2: return RbUtils.Translate.instant('WEEKDAYS.TUE_UPPERCASE');
				case 3: return RbUtils.Translate.instant('WEEKDAYS.WED_UPPERCASE');
				case 4: return RbUtils.Translate.instant('WEEKDAYS.THU_UPPERCASE');
				case 5: return RbUtils.Translate.instant('WEEKDAYS.FRI_UPPERCASE');
				case 6: return RbUtils.Translate.instant('WEEKDAYS.SAT_UPPERCASE');
				case 0: return RbUtils.Translate.instant('WEEKDAYS.SUN_UPPERCASE');
			}
		}

		private static getWeekDay(i: number, str: string): string {
			switch (i) {
				case 1:
					str += RbUtils.Translate.instant('WEEKDAYS.MONDAY_ABBREVIATION');
					break;
				case 2:
					str += RbUtils.Translate.instant('WEEKDAYS.TUESDAY_ABBREVIATION');
					break;
				case 3:
					str += RbUtils.Translate.instant('WEEKDAYS.WEDNESDAY_ABBREVIATION');
					break;
				case 4:
					str += RbUtils.Translate.instant('WEEKDAYS.THURSDAY_ABBREVIATION');
					break;
				case 5:
					str += RbUtils.Translate.instant('WEEKDAYS.FRIDAY_ABBREVIATION');
					break;
				case 6:
					str += RbUtils.Translate.instant('WEEKDAYS.SATURDAY_ABBREVIATION');
					break;
				case 0:
					str += RbUtils.Translate.instant('WEEKDAYS.SUNDAY_ABBREVIATION');
					break;
			}
			return str;
		}

		static getWeekDays(weekDaysIn: string): string {
			let str = '';
			const weekDays = weekDaysIn == null ? '0000000' : weekDaysIn;
			if (weekDays != null) {
				for (let i = 0; i < weekDays.length; i++) {
					if (+weekDays.charAt(i) === 0) {
						continue;
					}
					if (str.length > 0) {
						str += ' ';
					}
					str = this.getWeekDay(i, str);
				}
			}
			return str;
		}

		private static getHybridWeekDays(typeString: string, weekDaysIn: string): string {
			let str = '';
			const weekDays = weekDaysIn == null ? '0000000' : weekDaysIn;
			if (weekDays != null) {
				for (let i = 0; i < weekDays.length; i++) {
					if (+weekDays.charAt(i) === 1) {
						continue;
					}
					if (str.length > 0) {
						str += ' ';
					}
					str = this.getWeekDay(i, str);
				}
			}
			if (str.length > 0) {
				// For localization, we should allow the not string to be anywhere and have any format. For that to
				// work, we get the *format* of this string, including {{programTypeString}} and {{weekdaysString}} tags
				// and format our data for those two items into the result.
				str = RbUtils.Translate.instant('STRINGS.NOT_WEEKDAYS_FORMAT',
					{ programTypeString: typeString, weekdaysString: str });
			} else {
				str = typeString;
			}
			return str;
		}

		private static cultureSettingWeekDays(cultureSettings: CultureSettings) {
			const calendarFormatId = cultureSettings.calendarFormatId;
			const weekdays =  [{ id: 0, name: RbUtils.Translate.instant('WEEKDAYS.SUNDAY_ABBREVIATION') },
			{ id: 1, name: RbUtils.Translate.instant('WEEKDAYS.MONDAY_ABBREVIATION') },
			{ id: 2, name: RbUtils.Translate.instant('WEEKDAYS.TUESDAY_ABBREVIATION') },
			{ id: 3, name: RbUtils.Translate.instant('WEEKDAYS.WEDNESDAY_ABBREVIATION') },
			{ id: 4, name: RbUtils.Translate.instant('WEEKDAYS.THURSDAY_ABBREVIATION') },
			{ id: 5, name: RbUtils.Translate.instant('WEEKDAYS.FRIDAY_ABBREVIATION') },
			{ id: 6, name: RbUtils.Translate.instant('WEEKDAYS.SATURDAY_ABBREVIATION') },
			];

			const firstHalfWeek = weekdays.filter(x => x.id >= calendarFormatId);
			const secondHalfWeek = weekdays.filter(x => x.id < calendarFormatId);
			return [...firstHalfWeek, ...secondHalfWeek];
		}

	}
}

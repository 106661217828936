import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class Cic {

	companyId: number;
	id: number;
	lastEventRecNum: number;
	lastEventRecNumLong: number;
	lastFlowEventRecNum: number;
	lastFlowEventRecNumLong: number;
	lastNetHealthEventRecNum: number;
	lastNetHealthEventRecNumLong: number;
	modemVersion: string;
	satelliteId: number;
	serialNumber: number;
	type: RbEnums.Common.CommDeviceType;
	versionString: string;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

/*
 * NOTE: DO NOT USE THESE CONSTANTS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbConstants Namespace.
 * 		 E.g., RbConstants.Alert.ALERT_LOG_TYPE
 * 		 See: _rb.constants.ts
 */

export namespace XXUseRbConstantsNamespace {

	export abstract class Alert {

		static readonly EVENT_LOG_TYPE = 7;
		static readonly ALERT_LOG_TYPE = 1;
		static readonly WARNING_LOG_TYPE = 2;

		static readonly DEFAULT_EVENT_LOG_DAYS = 7;
		static readonly CentralAutoConnected = 9000;
		static readonly LINE_PER_PAGE = 50;
	}

}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class='edit-start-times-container'>

	<div *ngIf="addStartTimeLabel" class="section-header">{{addStartTimeLabel | translate}}</div>
	<!-- Programs for Commercial and Program Groups for Golf -->
	<div *ngIf="(!isGolfSite || isProgram) && !isProgramTool" id="est-click-to-add">
		<div class="mat-body-2 start-times-label"><span>{{'STRINGS.CLICK_TO_ADD' | translate}}</span></div>

		<div>
			<button
				type="button"
				mat-icon-button
				data-qaid="add-start-time-btn"
				class="add-start-time-btn"
				[disabled]="disableAddStartTime || isReadOnly"
				(click)="addStartTimeClicked()"
			>
				<mat-icon class="color">add_circle</mat-icon>
			</button>
		</div>
	</div>

	<rb-edit-start-time
		*ngFor="let startTime of groupStartTimes; let i = index" [index]="i"
		[startTimeFormGroup]="startTime"
		[startTimesListFormGroup]="groupStartTimes"
		[disabled]="isGolfSite && !isProgram || isReadOnly"
		[isProgramTool]="isProgramTool"
		[showDelayOption]="isGolfSite && !isProgram && groupStartTimes.length === 1 && delayedUntilStartTime == null"
		(addStartTime)="addStartTimeClicked()"
		(updated)="onUpdateStartTime()"
		(removed)="onRemoveStartTime(startTime)"
		(dialogDisplayed)="dialogDisplayed.emit($event)">
	</rb-edit-start-time>

	<div *ngIf="isGolfSite && !isProgram && groupStartTimes.length === 0" class="no-group-start-time">{{'STRINGS.NONE' | translate}}</div>

	<!-- "Delayed until" entry -->
	<rb-edit-start-time
		*ngIf="delayedUntilStartTime != null"
		[startTimeFormGroup]="delayedUntilStartTime"
		[isDelayed]="true"
		[disabled]="isReadOnly"
		[warn]="isGolfSite && groupStartTimes.length === 0"
		(updated)="onUpdateStartTime()"
		(removed)="onRemoveStartTime(delayedUntilStartTime)"
		(dialogDisplayed)="dialogDisplayed.emit($event)">
	</rb-edit-start-time>

	<!-- Schedules for Golf	-->
	<div *ngIf="isGolfSite && !isProgram && delayedUntilStartTime != null && groupStartTimes.length < 2">
		<div class="flex flex-row justify-between v-mid">
			<div class="mat-body-2 start-times-label"><span>{{'STRINGS.ADDITIONAL_SCHEDULE_START_TIMES' | translate}}</span>
			</div>
			<div>
				<button
					type="button"
					mat-icon-button
					data-qaid="add-additional-start-time-btn"
					class="add-start-time-btn"
					[disabled]="disableAddStartTime || isReadOnly"
					(click)="addStartTimeClicked()"
				>
					<mat-icon class="color">add_circle</mat-icon>
				</button>
			</div>
		</div>
		<rb-edit-start-time *ngFor="let startTime of scheduleStartTimes; let i = index" [index]="i" [startTimeFormGroup]="startTime"
			[warn]="isGolfSite && groupStartTimes.length === 0"
			(updated)="onUpdateStartTime()" (removed)="onRemoveStartTime(startTime)" (dialogDisplayed)="dialogDisplayed.emit($event)">
		</rb-edit-start-time>
	</div>

	<div *ngIf="!isGolfSite && isProgramTool" id="est-click-to-add" class="program-tool-start-time">
			<button
				type="button"
				mat-icon-button
				data-qaid="add-start-time-btn"
				class="add-start-time-btn"
				[disabled]="disableAddStartTime || isReadOnly"
				(click)="addStartTimeClicked()"
			>
				<mat-icon class="color">add_circle</mat-icon>
			</button>
			<div class="program-tool-start-time-label">
				<span>{{'STRINGS.ADD_START_TIME' | translate}}</span>
			</div>
	</div>

	<ngx-material-timepicker
		#addStartTime
		class="timepicker__header"
		timepickerClass="data-qaid-add-start-timepicker"
		[enableKeyboardInput]="true"
		[format]="format"
		(timeSet)="onTimeAdded($event)"
		[hidden]="maxStartTimesReached"
		[cancelBtnTmpl]="cancelBtn"
		[confirmBtnTmpl]="confirmBtn"
		(opened)="onTimePickerOpened()"
		(closed)="onTimePickerClosed()">
	</ngx-material-timepicker>
</div>

<div>
	<ng-template #startTimeWarning>
		<rb-dialog [title]="'STRINGS.ADD_START_TIMES_ERROR' | translate" [closeButtonLabel]="'STRINGS.CLOSE' | translate" [showSubmitButton]="false">
			<p>{{maxStartTimesReachedMsg}}</p>
		</rb-dialog>
	</ng-template>
</div>

<ng-template #cancelBtn>
	<button
		mat-button
		type="button"
		class="rb-secondary-button mat-primary"
		data-qaid="dialog-cancel-button"
	>
		{{ 'STRINGS.CANCEL' | translate  }}
	</button>
</ng-template>
<ng-template #confirmBtn>
	<button
		mat-raised-button
		class="rb-primary-button mat-primary"
		data-qaid="dialog-submit-button"
	>
		{{ 'STRINGS.OK' | translate }}
	</button>
</ng-template>

import { RbUtils } from '../../../common/utils/_rb.utils';

export class WeatherSensorStatus {

	constructor(json: any = null, controllerId: number, changeDateTime: string) {
		if (json) {
			Object.assign(this, json);
		}

		this.changeDateTime = RbUtils.Conversion.convertStringToDate(changeDateTime);
		this.controllerId = controllerId;
	}

	isWeatherSensorActive: boolean[] = [];
	weatherSensorCount = 0;
	weatherSensorStatus: number[] = [];
	controllerId: number;
	changeDateTime: Date;
}

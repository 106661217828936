import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';
import { RbConstants } from '../constants/_rb.constants';
import { Subject } from 'rxjs';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class DeviceManagerService implements OnDestroy {

	// Subjects
	windowResize = new Subject();						// Fired when application window is resized.
	isMobileChange = new Subject<boolean>();			// Fired when isMobile state of application is changed.
	isTabletChange = new Subject<boolean>();			// Fired when isTablet state of application is changed.
	isPortraitChange = new Subject<boolean>();			// Fired when isPortrait state of application is changed.
	showMainNavChange = new Subject<boolean>();			// Fired when Main navigation is shown or hidden.
	showMobileSystemStatus = new Subject<boolean>();

	// Member Vars
	isMobile = false;
	isTablet = false;
	isPortrait = false;
	isShowingMobileSystemStatus = false;

	hasMainNav = false;
	hasSubNav = false;
	hasSubNavHeader = true;
	hasSubNavRoutes = false;
	isLeftSidebarDisplayed = false;
	browserRefreshed = false;

	private _routingHistory: string[] = [];
	// =========================================================================================================================================================
	// C'tor and Destroy
	// =========================================================================================================================================================

	constructor(private deviceDetector: DeviceDetectorService,
				private route: ActivatedRoute,
				private router: Router) {

		// Monitor Application Window resizing and take action as desired.
		this.windowResize
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.dispatchResizeEvents(window.innerWidth);
				this.showHideMainNavigation();
			});

		// Monitor router navigation
		this.router.events
			.pipe(
				untilDestroyed(this),
				filter(event => event instanceof NavigationEnd)
			)
			.subscribe((event: NavigationEnd) => {
				this.routingHistory.push(event.urlAfterRedirects);
				this.isLeftSidebarDisplayed = this.route.snapshot.queryParams['showLeftSidebar'];
				if(event.url === '/') {
					this.showMainNavChange.next(true); // set main menu
				}
			});

		this.dispatchResizeEvents(window.innerWidth);
		this.showHideMainNavigation();
		
		// disable pull-to-refresh in Safari
		if (this.isSafari) {
			document.querySelector('html').classList.add('overflow-hidden');
		}
	}

	ngOnDestroy(): void {
		/* Implemented to support untilDestroyed */
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get browser(): string {
		return this.deviceDetector.browser;
	}

	get isFirefox(): boolean {
		return this.browser === 'Firefox';
	}

	get isEdge(): boolean {
		return this.browser === 'MS-Edge';
	}

	get isSafari(): boolean {
		return this.browser === 'Safari';
	}

	get isDesktop(): boolean {
		return this.deviceDetector.isDesktop();
	}

	get isTouchDevice(): boolean {
		return 'ontouchstart' in window || navigator.msMaxTouchPoints > 0 || this.deviceDetector.isMobile() || this.deviceDetector.isTablet();
	}

	/**
	 * Support for responsive design (i.e., mobile)
	 * Track which navigation elements are being displayed to adjust calculated container heights properly.
	 */
	setMobileNavigation(hasMainNav = true, hasSubNav = false, hasSubNavHeader = true, hasSubNavRoutes = false) {
		this.hasMainNav = hasMainNav;
		this.hasSubNav = hasSubNav;
		this.hasSubNavHeader = hasSubNavHeader;
		this.hasSubNavRoutes = hasSubNavRoutes;

		this.showHideMainNavigation();
	}

	toggleMobileSystemStatus() {
		this.isShowingMobileSystemStatus = !this.isShowingMobileSystemStatus;
		this.showMobileSystemStatus.next(this.isShowingMobileSystemStatus);
		this.showHideMainNavigation();
	}

	get previousUrl(): string {
		return this.routingHistory[this.routingHistory.length - 2] || null;
	}

	get routingHistory(): string[] {
		return this._routingHistory;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private showHideMainNavigation() {
		setTimeout(() => {
			this.showMainNavChange.next(!this.isMobile || (this.isMobile && this.hasMainNav));
		});
	}

	private dispatchResizeEvents(windowInnerWidth: number) {
		this.isMobile = window.innerWidth < RbConstants.Common.MaxMobileWindowWidth;
		this.isTablet = this.deviceDetector.isTablet();
		this.isPortrait = window.innerWidth < window.innerHeight;

		this.isMobileChange.next(this.isMobile);
		this.isTabletChange.next(this.isTablet);
		this.isPortraitChange.next(this.isTablet);
	}
}

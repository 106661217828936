import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-edit-panel',
	templateUrl: './edit-panel.component.html',
	styleUrls: ['./edit-panel.component.scss']
})
export class EditPanelComponent implements OnInit {
	@Output() cancel = new EventEmitter();
	@Output() save = new EventEmitter();
	@Output() done = new EventEmitter();

	@Input() cancelDisabled = false;
	@Input() saveDisabled = false;
	@Input() hideCancelAndSave = false;
	@Input() saveString = 'STRINGS.SAVE';
	@Input() cancelString = 'STRINGS.CANCEL';
	@Input() showSubPanelSaveButton = false;
	@Input() isEditingController = false;

	private _showSubPanelBackArrow = false;
	@Input() set showSubPanelBackArrow(value: boolean) {
		this._showSubPanelBackArrow = value;
		this.showSubPanelSaveButton = value;
	}

	globalWeather = false;
	isReadOnly = false;
	private isTemporarilyDiabled = false;

	constructor(
		private authManager: AuthManagerService,
		protected route: ActivatedRoute) { }

	get showSubPanelBackArrow(): boolean { return this._showSubPanelBackArrow; }

	ngOnInit() {
		this.isReadOnly = this.authManager.isReadOnly;

		// RB-14317: IQ4 - Allow add Contact in Field Tech account
		const isGolf = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		if (!isGolf && this.authManager.isFieldTech) {
			const currentPatch = this.route.snapshot.url[0].path;
			this.isReadOnly = !RbConstants.Ui.FIELD_TECH_EDITABLE_OPTIONS.find(x => x.path === currentPatch);
		}

		if (this.isReadOnly) {
			this.cancelString = 'STRINGS.CLOSE_UPPERCASE';
		}
	}

	saveClick () {
		if (this.isTemporarilyDiabled) { return; }
		this.isTemporarilyDiabled = true;

		this.save.emit();

		// Mitigate rapid clicking
		setTimeout(() => this.isTemporarilyDiabled = false, 1000);
	}
}

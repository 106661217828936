<div *ngIf="!isSiteMenuContext" class="map-context-menu" [ngClass]="{ 'station': contextMenuInfo?.station }">
	<!-- Popup header -->
	<div class="context-menu-header" (click)="$event.stopPropagation();">
		<div class="title">
			<div class="icon-container">
				<i *ngIf="contextMenuInfo?.icon" class="{{ contextMenuInfo?.icon }}"
					[class.station-idle]="contextMenuInfo?.station || contextMenuInfo?.sensor">
					<div class="outer-circle">
						<div class="inner-circle" *ngIf="contextMenuInfo?.station || contextMenuInfo?.sensor"
							[style.background]="contextMenuInfo?.station?.nozzleColor ? contextMenuInfo?.station?.nozzleColor.toLowerCase() : '#ddd' ">
						</div>
					</div>
				</i>
				<img *ngIf="!contextMenuInfo?.icon && contextMenuInfo?.image"
					[src]="'/assets/images/' + contextMenuInfo?.image">
			</div>
			<span>{{ contextMenuInfo?.title }}
				<small *ngIf="contextMenuInfo?.station">({{
					contextMenuInfo.station.classStatus
					}})</small>
			</span>
		</div>
		<div class="toolbar">
			<button mat-icon-button [matMenuTriggerFor]="operationsMenu">
				<mat-icon style="margin-top: -2px;">more_vert</mat-icon>
			</button>
			<mat-menu #operationsMenu="matMenu" class="contextmenu-toolbarmenu unset-min-height">
				<button mat-menu-item *ngIf="contextMenuInfo?.editOption"
					(click)="onContextMenuItemSelected(contextMenuInfo?.editOption)">
					<mat-icon>edit</mat-icon>
					<span>{{ 'STRINGS.EDIT' | translate }}</span>
				</button>
				<button mat-menu-item *ngIf="contextMenuInfo?.removeOption"
					(click)="onContextMenuItemSelected(contextMenuInfo?.removeOption)">
					<mat-icon class="text-red">delete_forever</mat-icon>
					<span>{{ (contextMenuInfo?.fromStationArea ? 'STRINGS.DELETE_SHAPE' : 'STRINGS.REMOVE_FROM_MAP') |
						translate }}</span>
				</button>
			</mat-menu>
			<rb-icon-button icon="close" (iconButtonClicked)="closeMenu()"></rb-icon-button>
		</div>
	</div>

	<!-- Popup body -->
	<div class="context-menu-body" (click)="$event.stopPropagation();">

		<!-- Popup vertical action bar -->
		<div class="action-bar">
			<ng-container *ngFor="let option of contextMenuInfo?.menuOptions">
				<button mat-menu-item (click)="onContextMenuItemSelected(option.value)"
					[matTooltip]="station ? option.text : ''" matTooltipPosition="right"
					[disabled]="mapService.contextMenuDisabled(contextMenuInfo, option)">

					<mat-icon class="context-menu-icon" *ngIf="!isAddShape(option.value)"
						[ngClass]="mapService.contextMenuClass(contextMenuInfo, option)"></mat-icon>
					<div *ngIf="isAddShape(option.value)" cdkDropList cdkDropListConnectedTo="map"
						(cdkDropListDropped)="onDrop($event)">
						<mat-icon class="context-menu-icon" cdkDrag [cdkDragDisabled]="false"
							[cdkDragData]="{ addShapeToStationId: contextMenuInfo.stationId }"
							(cdkDragStarted)="dragging = true" (cdkDragEnded)="dragging = false"
							(cdkDragMoved)="onDragMoved($event)" (cdkDragReleased)="onDragEnded()" cdkDragHandle
							[ngClass]="mapService.contextMenuClass(contextMenuInfo, option)"></mat-icon>
						<i class="mdi mdi-vector-square text-green drag-icon" *cdkDragPreview [style.font-size]="'34px'"></i>
					</div>
					<span *ngIf="!station">{{ option.text }}</span>
					<span class="badge" *ngIf="option.badge">{{ option.badge }}</span>
				</button>
			</ng-container>
			<!-- Description for controller -->
			<ng-container *ngIf="!isGolfSite && contextMenuInfo?.controller?.description != null">
				<div class="descriptions-adjustments" (click)="$event.stopPropagation();">
					<ul>
						<li>
							<span>
								{{ 'STRINGS.DESCRIPTION' | translate }}
								<button mat-flat-button type="button" [disabled]="!isTextDescriptionsChange"
									id="btn-save-description-controller" (click)="onSaveDescriptionsClicked()">{{ 'STRINGS.SAVE'
									|
									translate }}</button>
							</span>
						</li>
						<li>
							<textarea [(ngModel)]="textDescriptions" (ngModelChange)="onTextDescriptionsChanged($event)"
								maxlength="255"></textarea>
						</li>
					</ul>
				</div>
			</ng-container>
		</div>

		<!-- Popup detail -->
		<div class="detail-panel"
			*ngIf="contextMenuInfo?.station && !isSensorClicked && !isEditingAreaColor && !contextMenuInfo?.fromStationArea"
			(click)="$event.stopPropagation();">
			<div class="tab-panel">
				<div class="menu-item column" *ngIf="isGolfSite || (!isGolfSite && !station?.master)">
					<div *ngIf="isGolfSite">
						<span class="item-title">{{station.isStationRunning ? ('STRINGS.REMAINING_TIME' | translate) :
							('STRINGS.NOT_RUNNING' | translate) }}</span>
					</div>
					<div *ngIf="!isGolfSite && !station?.master">
						<span class="item-title station-status-label" (click)="onCommercialStationStatusClicked()">
							{{getCommercialStationStatusLabel(contextMenuInfo?.station.id)}}
						</span>
					</div>
					<div class="item-content" *ngIf="isGolfSite || (!isGolfSite && !station?.master)">
						<div class="remaining-time-container" [ngClass]="contextMenuInfo.station.classStatus">

							<!-- 
								We use the `remainingRunTimeDisplay` pipe to show a formatted remaining time ('hh:mm:ss') based on
								multiple properties in the station object.

								This pipe doesn't use the interpolated value directly, but rather the entire station object.
								
								We add up `secondsRemaining`, `runTimeRemaining` and `irrigationStatus`'s numeric value so that we 
								can react to a change in any of these values and update the displayed value accordingly. 
								(`secondsRemaining` and `runTimeRemaining` are used in different scenarios)
								
								One of the benefits of doing this is that the pipe's code will not run until any of these values change 
								(while a function would run more frequently even when there is no change in the data)

								RB-15679: `station.stationListItem` is a property that is set/updated when there's an update through signalR.
								Marking it as possibly undefined to avoid 'reading from undefined' error messages when it's not yet been set
							-->
							<h1 class="popup-runtime">
								{{
									(station.stationListItem?.secondsRemaining || 0) +
									(station.runTimeRemaining || 0) +
									station.irrigationStatus
								
									| remainingRunTimeDisplay: station
								}}
							</h1>
							
                        <div class="pie" [style.--p]="station.runTimeRemaining | remainingRuntimePercent: station">
								<div class="icon-container">
									<i class="mdi"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="menu-item column" *ngIf="isGolfSite || (!isGolfSite && !station?.master)">
					<span class="item-title">{{ 'STRINGS.LAST_IRRIGATED' | translate }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ 'STRINGS.DATE' | translate }}</span>
								<span class="d-flex">
									<span class="value"
										*ngIf="station.lastRunTimeSeconds && station.lastRunTimeSeconds !== '0001-01-01T00:00:00+00:00' else noLastRunStartTime">
										{{ station.lastManualRunStartTime | date: lastIrrigatedDateTimeFormat }}
									</span>
									<ng-template #noLastRunStartTime>
										<span class="light-silver">--</span>
									</ng-template>
								</span>
							</li>
							<li>
								<span class="label nowrap">{{ 'STRINGS.RUNTIME' | translate }}</span>
								<span class="value" [class.light-silver]="!station.lastRunTimeSeconds">
									<i class="mdi mdi-clock-outline text-green mr-1"></i>
									{{ secondsToHhMmSs(station.lastRunTimeSeconds) }}</span>
							</li>
							<li *ngIf="false">
								<span class="label nowrap">{{ 'STRINGS.USER' | translate }}</span>
								<span class="value"
									[class.light-silver]="!station.lastRunTimeSeconds"
									[class.text-blue]="station.lastRunTimeSeconds">
									{{ 'golfadmin' }}</span>
							</li>
						</ul>
					</div>
				</div>
				<div class="menu-item column" *ngIf="isGolfSite || (!isGolfSite && !station?.master && !isSensorClicked)">
					<div class="d-flex justify-between w-100">
						<span class="item-title">{{ 'STRINGS.ACTIVE_ADJUSTMENTS' | translate }}</span>
						<button class="title-toolbar-button text-yellow" *ngIf="false">{{ 'STRINGS.EDIT' | translate }}</button>
					</div>
					<div class="item-content">
						<ul class="active-adjustments">
							<li *ngIf="station.yearlyAdjFactor || station.yearlyAdjFactor === 0">
								<span class="label nowrap">{{ 'STRINGS.STATION_ADJUST' | translate }}</span>
								<span [ngClass]="{
																			'value': true,
																			'blue': station.yearlyAdjFactor > 100,
																			'yellow': station.yearlyAdjFactor < 100,
																			'red': station.yearlyAdjFactor === 0
																	}">{{ station.yearlyAdjFactor }}%</span>
							</li>
							<li *ngIf="station.tempStationAdjust || station.tempStationAdjust === 0"
								[class.light-silver]="!station.isTempAdjustActive">
								<span class="label nowrap">
									<span class="mr2">{{ 'STRINGS.TEMPORARY_ADJUST' | translate }}</span>
									<span *ngIf="station.isTempAdjustActive">
										({{'STRINGS.DAYS_LEFT' | translate: {days: station.tempAdjustDays} }})
									</span>
								</span>
								<span [ngClass]="{
																			'value': true,
																			'blue': station.tempStationAdjust > 100 && station.isTempAdjustActive,
																			'yellow': station.tempStationAdjust < 100 && station.isTempAdjustActive,
																			'red': station.tempStationAdjust === 0 && station.isTempAdjustActive
																	}">{{ (station.isTempAdjustActive? 'STRINGS.PERCENT' : '--') | translate: {percent:
									station.tempStationAdjust} }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- Address  -->
				<div class="menu-item column"
					*ngIf="!isGolfSite && station?.master && !isCommercialLXME(contextMenuInfo?.station.id)">
					<span class="item-title">{{ 'STRINGS.ADDRESS' | translate }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ station.addressInt }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- Type  -->
				<div class="menu-item column"
					*ngIf="!isGolfSite && isMasterValveUnused(contextMenuInfo?.station.id, station) && !showCommercialOperation(contextMenuInfo?.station.id, station)">
					<span class="item-title">{{ 'STRINGS.TYPE' | translate }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ getCommercialTypeString(station) }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- Operation  -->
				<div class="menu-item column"
					*ngIf="!isGolfSite && showCommercialOperation(contextMenuInfo?.station.id, station)">
					<span class="item-title">{{ 'STRINGS.OPERATION' | translate }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ getCommercialOperation(station) }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- Solenoids Quantity  -->
				<div class="menu-item column"
					*ngIf="!isGolfSite && showCommercialSolenoidsQuantity(contextMenuInfo?.station.id, station)">
					<span class="item-title">{{ 'STRINGS.SOLENOIDS_QUANTITY' | translate }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ station.stationValveType?.solenoidsQuantity }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- ValveType  -->
				<div class="menu-item column"
					*ngIf="!isGolfSite && showCommercialValveType(contextMenuInfo?.station.id, station)">
					<span class="item-title">{{ 'STRINGS.VALVE_TYPE' | translate }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ getCommercialValveTypeValue(station) }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- FloZone  -->
				<div class="menu-item column"
					*ngIf="!isGolfSite && showCommercialFloZone(contextMenuInfo?.station.id, station)">
					<span class="item-title">{{ 'STRINGS.FLOZONE' | translate }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ getCommercialFlowZoneValue(station) }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- FloZone TM Flow Rate  -->
				<div class="menu-item column"
					*ngIf="!isGolfSite && showCommercialFlowRate(contextMenuInfo?.station.id, station)">
					<span class="item-title">{{ getCommercialFlowZoneName(contextMenuInfo?.station.id, station) }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ getCommercialFlowRateValue(station) }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- Delay  -->
				<div class="menu-item column" *ngIf="!isGolfSite && showCommercialDelay(contextMenuInfo?.station.id, station)">
					<span class="item-title">{{ 'STRINGS.DELAY' | translate }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ getCommercialDelay(station) }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- Monthly Cycling Time  -->
				<div class="menu-item column" *ngIf="!isGolfSite && showMonthlyCyclingTime(station)">
					<span class="item-title">{{ 'STRINGS.MONTHLY_CYCLING_TIME' | translate }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ getMonthlyCyclingTime(station) }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- iqNet Sharing  -->
				<div class="menu-item column"
					*ngIf="!isGolfSite && showCommercialIqNetSharing(contextMenuInfo?.station.id, station)">
					<span class="item-title">{{ getCommercialIqNetSharingLabel(station) }}</span>
					<div class="item-content">
						<ul class="active-adjustments">
							<li>
								<span class="label nowrap">{{ getCommercialIqNetSharingValue(station) }}</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- Comments/Notes for station, Master Valve -->
				<div class="menu-item column" *ngIf="!isGolfSite">
					<span class="item-title">{{ 'STRINGS.COMMENTS_NOTES' | translate }}</span>
					<div class="item-button"><button [disabled]="!isTextCommentsChange" id="btn-save-comments-station"
							(click)="onSaveCommentsClicked()">{{ 'STRINGS.SAVE' | translate }}</button></div>
					<div class="item-content">
						<ul class="comments-adjustments">
							<li>
								<textarea [(ngModel)]="textComments" (ngModelChange)="onTextCommentsChanged($event)"
									maxlength="255"></textarea>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- <div class="tab-buttons">
				<button mat-button>Summary</button>
				<button mat-button>Adjustments</button>
				<button mat-button>Runtime</button>
			</div> -->
		</div>
		<div class="detail-panel"
			*ngIf="contextMenuInfo?.station && !isSensorClicked && isEditingAreaColor && !contextMenuInfo?.fromStationArea"
			(click)="$event.stopPropagation();">
			<rb-custom-station-area-style [stationId]="contextMenuInfo.stationId" [siteId]="site.id" [mapInfo]="mapInfo"
				(closed)="handleCloseStationContextMenu()">
			</rb-custom-station-area-style>
		</div>
	</div>
	<div class="edit-station-area-styles" *ngIf="contextMenuInfo?.fromStationArea && isEditingFromStationAreaContextMenu"
		(click)="$event.stopPropagation()">
		<rb-custom-station-area-style [stationId]="contextMenuInfo.stationId" [siteId]="site.id" [mapInfo]="mapInfo"
			(closed)="handleCloseStationAreaContextMenu()">
		</rb-custom-station-area-style>
	</div>
</div>

<!-- Menu context for Site in IQ4 -->
<div *ngIf="!isGolfSite && isSiteMenuContext" class="map-context-menu">
	<div class="context-menu-header" (click)="$event.stopPropagation();">
		<button mat-menu-item *ngIf="contextMenuInfo?.editOption"
			(click)="onContextMenuItemSelected(contextMenuInfo?.editOption)">
			<mat-icon>edit</mat-icon>
			<span>{{ 'STRINGS.EDIT' | translate }}</span>
		</button>
		<div class="toolbar">
			<rb-icon-button icon="close" (iconButtonClicked)="closeMenu()"></rb-icon-button>
		</div>
	</div>
</div>
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { RbUtils } from '../../../common/utils/_rb.utils';

export class StartTime {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.dateTime != null && typeof(json.dateTime) === 'string') { this.dateTime = RbUtils.Conversion.convertStringToDate(json.dateTime); }

			if (json.dateTimeOffset != null && typeof (json.dateTimeOffset) === 'string') {
				this.dateTimeOffset = this.dateTime = RbUtils.Common.timeOffsetToDisplayTime(json.dateTimeOffset);
			}
		}
	}

	companyId?: number;
	dateTime: Date;
	enabled = false;
	id?: number;
	isProgramGroup?: boolean = false;
	programId?: number;
	dateTimeOffset?: Date;
}
import { Company } from './company.model';
import { CultureSettings } from '../../culture-settings/models/culture-settings.model';
import { RbccUser } from '../../users/models/rbcc-user.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { ReportRecipient } from './report-recipient.model';

export class AddressBookEntry {

	address: string;
	company: Company;
	companyId: number;
	companyName: string;
	cultureSettings: CultureSettings;
	cultureSettingsId: number;
	email: string;
	firstName: string;
	jobTitle: string;
	lastName: string;
	id: number;
	isFavorite = false;
	mobileNumber: string;
	/**
	 * @description notificationOption controls which, if any, events already subscribed by the AddressBook's parent User will
	 * be sent to the AddressBook entry. It also defines which transmission method will be used. The default value is
	 * None (no notifications). Other values are SameAsParent, which sends the notifications in the same format(s) as the parent
	 * in each case, Email, and TextMessage.
	 */
	notificationOption: RbEnums.Common.UserEventSubscriptionType = RbEnums.Common.UserEventSubscriptionType.None;
	reportRecipient: ReportRecipient[] = [];
	workNumber: string;
	user: RbccUser;
	userId: number;
	reportFormat: RbEnums.Common.ReportFormatType;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.company) { this.company = new Company(json.company); }
			if (json.cultureSettings) { this.cultureSettings = new CultureSettings(json.cultureSettings); }
			if (json.reportRecipient) { this.reportRecipient = json.reportRecipient.map(item => new ReportRecipient(item)); }
			if (json.user) { this.user = new RbccUser(json.user); }
		}
	}

	get name(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	get notificationOptionDescription(): string {
		return RbEnums.Common.UserEventSubscriptionType[this.notificationOption];
	}

	// =========================================================================================================================================================
	// Class Methods
	// =========================================================================================================================================================

	static createDefault(companyId: number, cultureSettingsId: number): AddressBookEntry {
		const contact = new AddressBookEntry();

		contact.companyId = companyId;
		contact.cultureSettingsId = cultureSettingsId;
		contact.lastName = '';
		return contact;
	}
}

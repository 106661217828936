import {
	CellEditingStartedEvent, CellEditingStoppedEvent,
	CellFocusedEvent,
	CellValueChangedEvent, ColDef,
	GridOptions,
} from 'ag-grid-community';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { CellEditorValidationParams } from './cell-editors/_models/cell-editor-validation-params';
import { NumberFormatterComponent } from './cell-renderers/number-formatter/number-formatter.component';

@Component({
	selector: 'rb-editable-grid',
	templateUrl: './editable-grid.component.html',
	styleUrls: ['./editable-grid.component.scss']
})
export class EditableGridComponent implements OnInit, OnDestroy {
	@ViewChild('agGrid') agGrid: AgGridAngular;

	@Output() cellValueChange = new EventEmitter<{ rowIndex: number, cell: any}>();
	@Output() rowValueChange = new EventEmitter<{ rowIndex: number, patchValues: {} }>();
	@Output() cellClicked = new EventEmitter<{ rowIndex: number }>();
	@Output() isEditingChange = new EventEmitter<boolean>();
	@Output() cellFocus = new EventEmitter<CellFocusedEvent>();

	gridOptions: GridOptions;

	frameworkComponents = {
		numberFormatterComponent: NumberFormatterComponent,
	};

	@Input() height = '400px';
	@Input() width = '600px';

	@Input() rowData: any[];
	@Input() columnDefs: any[];
	@Input() defaultColumnDef: any;

	@Input() updateGrid: Subject<boolean>;
	@Input() updateGridRow: Subject<any>;

	private _isEditing = false;
	@Input() set isEditing(value: boolean) {
		if (this._isEditing === value) { return; }
		this._isEditing = value;
		this.isEditingChange.emit(value);
	}

	get isEditing(): boolean {
		return this._isEditing;
	}

	/**
	 * We expose the currently selected row index so that clients don't have to implement this themselves
	 * by tracking clicks, etc.
	 */
	public currentRowIndex = -1;

	private currentRowIsDirty = false;
	private changedRowProperties = {};
	private backgroundSaveTimerRef: any;
	private lastOldData: any;
	private stoppedEditing = new Subject<void>();

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor() { }

	ngOnInit() {
		this.gridOptions = <GridOptions>{
			onCellFocused: (event: CellFocusedEvent) => {
				// If we've changed rows, stop the delayed save in the background timer and explicity and synchronously
				// start the save operation here.
				if (this.currentRowIndex >= 0 && this.currentRowIndex !== event.rowIndex && this.currentRowIsDirty) {
					this.stopBackgroundSaveTimer();
					this.rowValueChange.emit({ rowIndex: this.currentRowIndex, patchValues: this.changedRowProperties });
					this.changedRowProperties = {};
					this.currentRowIsDirty = false;
				}
				//RB-14905 Added greater equals zero because condition was not met when event was on the first row.
				if (event.rowIndex != null && event.rowIndex >= 0) {
					this.currentRowIndex = +event.api.getDisplayedRowAtIndex(event.rowIndex).id;
					this.cellClicked.emit( {rowIndex: this.currentRowIndex} );
				}
				
				this.cellFocus.emit(event);	
			},
			onCellValueChanged: (event: CellValueChangedEvent) => {
				if (event.newValue !== event.oldValue) {
					if (!event.colDef['isValid']) {
						this.lastOldData = event.oldValue;
					}

					this.changedRowProperties[event.colDef.field] = event.newValue;
					this.currentRowIsDirty = true;
					const rowIndex = +event.api.getDisplayedRowAtIndex(event.rowIndex).id;
					this.cellValueChange.emit({rowIndex: rowIndex, cell: event});
				}

				// Get the 'row index' from the displayed row collection. This is necessary to support sorting and filtering
				// of the grid. NOTE: This relies on the fact that the default row id is equivalent to the row index.
				this.currentRowIndex = +event.api.getDisplayedRowAtIndex(event.rowIndex).id;
			},
			onCellEditingStarted: (event: CellEditingStartedEvent) => {
				// If we've changed rows, stop the delayed save in the background timer and explicity and synchronously
				// start the save operation here. If we've only changed cells, we stop the delayed save operation, but
				// don't immediately send the to-be-saved data. There will, apparently, be more edits very soon.
				this.isEditing = true;
				this.stopBackgroundSaveTimer();
				if (this.currentRowIndex >= 0 && this.currentRowIndex !== event.rowIndex && this.currentRowIsDirty) {
					this.rowValueChange.emit({ rowIndex: this.currentRowIndex, patchValues: this.changedRowProperties });
					this.changedRowProperties = {};
					this.currentRowIsDirty = false;
				}
			},
			onCellEditingStopped: (event: CellEditingStoppedEvent) => {
				this.isEditing = false;

				if (this.currentRowIsDirty) {
					// Don't immediately start the background save timer. We may find that validations fail, etc. Start the 
					// timer only if validations are unnecessary or pass.

					if (this.hasFieldValidation(event.colDef)) {
						event.colDef.cellEditorParams.validate(new CellEditorValidationParams(event.value, event))
							.subscribe(response => {
								if (!response) {
									// There was a validation error.
									this.currentRowIsDirty = false;

									event.data[event.colDef.field] = this.lastOldData;
									event.node.setDataValue(event.colDef.field, this.lastOldData);
									event.api.setFocusedCell(event.rowIndex, event.colDef.field);
									event.api.startEditingCell({ rowIndex: event.rowIndex, colKey: event.colDef.field });

									// RB-9033: There was a validation error. We want to reset the cell value to the old
									// value (saved before the last change). BUT, we also need to clear the change from
									// the saved changedRowProperties, or that change can be transmitted depending on what
									// other changes the user performs.
									delete this.changedRowProperties[event.colDef.field];

									return;
								} else {
									this.startBackgroundSaveTimer();
								}
							}, error => {
								console.log('>> Field validation failed.');
							});

					} else {
						if(event.colDef.cellEditorParams?.getListItems){
							const listItems = event.colDef.cellEditorParams.getListItems();
							if (!listItems) return;
							
							const foundValue = listItems.find(
								value => event.value === value.value
							);

							if(foundValue !== undefined){
								event.data[event.colDef.field] = foundValue.name;
								event.node.setDataValue(event.colDef.field, foundValue.name);
							}
						}
						
						this.startBackgroundSaveTimer();
					}
				} else {
					this.stoppedEditing.next();
				}
			}
		};

		// ? used to refresh when changing satellite number/address
		this.updateGrid?.subscribe(forceRefresh => {
			this.refreshGrid(forceRefresh);
		});
		// ? used to refresh row when changing satellite number/address
		this.updateGridRow?.subscribe(params => {
			this.refreshGridRowAtIndex(params.index, params.forceRefresh);
		});
	}

	ngOnDestroy() {
		clearTimeout(this.backgroundSaveTimerRef);
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	refreshGrid(forceRefresh?: boolean, suppressFlash: boolean = false) {
		const params = {
			force: forceRefresh,
			suppressFlash: suppressFlash,
		};
		this.agGrid.api.refreshCells(params);
	}

	refreshGridRowAtIndex(index: number, forceRefresh?: boolean, suppressFlash: boolean = false) {
		const rowNode = this.agGrid.api.getRowNode(index.toString());
		const rowNodes = [rowNode]; // params needs an array
		const params = {
			force: forceRefresh,
			suppressFlash: suppressFlash,
			rowNodes: rowNodes
		};
		this.agGrid.api.refreshCells(params);
	}

	// Method to be called by 'table editor' dialogs to perform final validation when attempting to close while editing is still active.
	// If the cell currently being edited passes validation then the calling dialog can close; else it should not.
	canCloseAfterEdit(): Observable<boolean> {
		return Observable.create(observer => {
			this.agGrid.api.stopEditing();

			// NOTE: The timeout delay is arbitrary and may need t be adjusted to allow for field validation to complete
			// in the OnCellEditingStopped callback (above). Server response time is the determining factor.
			// RB-9198: TODO: RBCC: This is a poor way to handle synchronizing with a server-response-time-dependent call above.
			setTimeout(() => {
				// If validation passed, alert any subscribers of change to allow them to persist final changes.
				if (!this.isEditing && this.currentRowIsDirty) {
					this.rowValueChange.emit({ rowIndex: this.currentRowIndex, patchValues: this.changedRowProperties });
				}

				observer.next(!this.isEditing);
				observer.complete();
			}, 1000);
		});
	}

	/**
	 * If there is any cell currently being edited, this will either discard or apply the new value
	 * and will let the caller know that the grid is no longer being edited. By default, this will 
	 * accept the new value.
	 * 
	 * This is important when trying to change the grid's data set.
	 * 
	 * RB-16452: Cells with a dropdown element do not update the cell's value the moment a 
	 * new option is selected, but rather when another cell is focused. So if the user doesn't 
	 * click on a different cell after selecting any option from the dropdown, the cell is still 
	 * considered to be 'in editing mode'. This causes an issue when changing the grid's data set.
	 * So now, before trying to change the grid's data set, we should stop editing the grid (which means 
	 * discarding or applying the new value of any cell that's still being edited).
	 */
	stopEditing(discardChanges = false) {
		return new Promise<void>(resolve => {

			if (this.isEditing) {
				// If the grid is being edited, send the stop command and wait for our event handlers to finish 
				// reacting to the grid's "editingStopped" event and then resolve the promise.
				const s = this.stoppedEditing.subscribe((value) => {
					s.unsubscribe();
					resolve();
				});
				this.agGrid.api.stopEditing(discardChanges);
			} else {
				resolve();
			}

		});
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private startBackgroundSaveTimer() {
		this.backgroundSaveTimerRef = setTimeout(() => {
			// RB-14066: We don't want to fire off a change just for the heck of it. If there are no changed properties 
			// and we don't think the row is dirty, there are no changes!
			if (Object.keys(this.changedRowProperties).length > 0 || this.currentRowIsDirty) {
				this.rowValueChange.emit({ rowIndex: this.currentRowIndex, patchValues: this.changedRowProperties });
				this.currentRowIsDirty = false;
				this.changedRowProperties = {};
			}

			this.stoppedEditing.next();
		}, 500);
	}

	private stopBackgroundSaveTimer() {
		clearTimeout(this.backgroundSaveTimerRef);
	}

	private hasFieldValidation(colDef: ColDef): boolean {
		return colDef.cellEditorParams && colDef.cellEditorParams.validate;
	}

}

export class StationShortReport {

	constructor(stationShortReport: StationShortReport, controllerId: number) {
		if (stationShortReport) {
			Object.assign(this, stationShortReport);
		}

		this.controllerId = controllerId;
	}

	numberOfStations: number;
	stationsShorted: number[] = [];
	stationsOpen: number[] = [];
	masterValvesShorted: number[] = [];
	masterValvesOpen: number[] = [];
	controllerId: number;
}

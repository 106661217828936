import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { AutoContactModeModel } from '../../../sections/controllers/edit-controller/models/AutoContactModeModel';
import { EditStartTimesArrayComponent } from '../edit-start-times/edit-start-times-array.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StartTime } from '../../../api/programs/models/start-time.model';

import AutoContactMode = RbEnums.Common.AutoContactMode;
import ContactReason = RbEnums.Common.ContactReason;

@Component({
	selector: 'rb-auto-manual-scheduled',
	templateUrl: './auto-manual-scheduled.component.html',
	styleUrls: ['./auto-manual-scheduled.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AutoContactModeComponent),
			multi: true
		}
	]
})
export class AutoContactModeComponent implements OnInit, ControlValueAccessor {
	@ViewChild('startTimes') startTimes: EditStartTimesArrayComponent;

	@Input() maxScheduledTimes = 1;
	@Input() hideSyncIf = false;
	@Input() manualHelpText: string;
	@Input() automaticHelpText: string;
	@Input() scheduledHelpText: string;
	@Input() iqNetTypeId: Number;
	@Input() isDemoMode = false;
	@Input() contactReason: ContactReason;
	@Input() scheduleTimeZoneText: string;

	ContactReason = ContactReason;
	AutoContactModeType = AutoContactMode;

	editForm: FormGroup;
	syncIfChangesExist = false;
	hasHelpText = false;
	isReadOnly = false;
	isServer = true;
	IqNetType = RbEnums.Common.IqNetType;
	isIqNetClient = false;

	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => {};
	private onTouched = () => {};

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private fb: FormBuilder,
				private authManager: AuthManagerService
	) { }

	ngOnInit() {
		this.isIqNetClient = this.iqNetTypeId === this.IqNetType.IQNetClient;
		this.isReadOnly = this.authManager.isReadOnly;
		this.isServer = this.iqNetTypeId === this.IqNetType.IQNetServer ? true : false;
		this.editForm = this.fb.group({
			startTimes: this.fb.array([]),
			autoManualSchedule: { value: 0, disabled: this.isReadOnly },
			syncIfChanges: { value: false, disabled: this.isReadOnly }
		});

		this.editForm.get('startTimes').valueChanges.subscribe(value => this.onScheduledTimesChange(value));
		this.editForm.get('autoManualSchedule').valueChanges.subscribe(value => this.onAutoContactModeChange(value));

		this.hasHelpText = this.manualHelpText != null || this.automaticHelpText != null || this.scheduledHelpText != null;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onScheduledTimesChange(value: any) {
		if (value.length < 1) { this.syncIfChangesExist = false; }

		this.onChange(new AutoContactModeModel(AutoContactMode.Scheduled, value, this.syncIfChangesExist));
		this.onTouched();
	}

	onAutoContactModeChange(value: AutoContactMode) {
		let startTimes = [];

		if (value === AutoContactMode.Scheduled) {
			startTimes = this.editForm.get('startTimes').value.map(t => new StartTime(t));

			this.setStartTimes();
		}

		this.onChange(new AutoContactModeModel(value, startTimes, this.syncIfChangesExist));
		this.onTouched();
	}

	onSyncIfChangesChange(change: MatCheckboxChange) {
		const startTimes = this.editForm.get('startTimes').value.map(t => new StartTime(t));
		this.syncIfChangesExist = change.checked;

		this.onChange(new AutoContactModeModel(AutoContactMode.Scheduled, startTimes, change.checked));
		this.onTouched();
	}

	// =========================================================================================================================================================
	// ControlValueAccessor Members
	// =========================================================================================================================================================

	writeValue(value: AutoContactModeModel): void {
		if (value == null) { return; }

		this.editForm.get('autoManualSchedule').setValue(value.mode);

		this.syncIfChangesExist = value.syncIfChangesExist;

		if (value.scheduledTimes.length > 0) {
			EditStartTimesArrayComponent.buildItems(<FormArray>this.editForm.get('startTimes'), value.scheduledTimes.sort((a, b) => {
					const st1 = RbUtils.Conversion.convertTimeToMilliseconds(a.dateTime);
					const st2 = RbUtils.Conversion.convertTimeToMilliseconds(b.dateTime);
					return st1 - st2;
				})
			);
			this.setStartTimes();
		}

	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setStartTimes() {
		setTimeout(() => {
			if (this.startTimes != null) this.startTimes.timesUpdated();

			const syncIfChangesControl = this.editForm.get('syncIfChanges');
			if (syncIfChangesControl) { syncIfChangesControl.setValue(this.syncIfChangesExist); }
		});
	}
}

import { Component, HostBinding, Injector } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { StationListItem } from '../../../../../api/stations/models/station-list-item.model';
import { StationStatusListItem } from '../../../../../api/stations/models/_station-status-list-item.model';

@Component({
	selector: 'rb-irrigation-queue-mobile-cell-renderer',
	templateUrl: './irrigation-queue-mobile-cell-renderer.component.html',
	styleUrls: ['./irrigation-queue-mobile-cell-renderer.component.scss']
})
export class IrrigationQueueMobileCellRendererComponent extends BaseMobileCellRendererComponent {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	station: StationListItem;
	_statusString: string;
	isRunning = false;

	constructor(injector: Injector) {
		super(injector);
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		super.agInit(params);
		this.station = params.data;
		this.updateProgramStatus(params);
	}

	refresh(params: any): boolean {
		this.station = params.data;
		this.updateProgramStatus(params);
		return true;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get isActivelyCyclingOrSoaking(): boolean {
		if (!(this.station instanceof StationStatusListItem)) return false;
		return this.station.isActivelyCyclingOrSoaking;
	}

	get isCycling(): boolean {
		if (!(this.station instanceof StationStatusListItem)) return false;
		return this.station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running;
	}

	get isSoaking(): boolean {
		if (!(this.station instanceof StationStatusListItem)) return false;
		return this.station.irrigationStatus === RbEnums.Common.IrrigationStatus.Soaking;
	}

	get statusString(): string {
		return (this.station instanceof StationStatusListItem) ? this.station.status : this._statusString;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	// get statusString(): string {
	// 	return this._statusString;
	// }

	// get adjust(): string {
	// 	const tempAdjustDays = this.program.tempAdjustDays;
	// 	if (tempAdjustDays > 0) {
	// 		return this.program.tempProgramAdjust + '%' + ' ' + '(' + (tempAdjustDays + 'd') + ')';
	// 	} else
	// 		return this.program.programAdjust + '%';
	// }

	private updateProgramStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		this.isRunning = false;

		if (this.params.data.irrigationStatus === RbEnums.Common.IrrigationStatus.Running) {
			this.isRunning = true;
			params.api.flashCells({
				rowNodes: [rowNode]
			});
		}

		// this._statusString = params.data.status;
		// this.isRunning = false;
		//
		// switch (this._statusString.toLowerCase()) {
		// 	case '-':
		// 		if (rowNode.data.isEnabled === false)
		// 			this._statusString = this.translate.instant('STRINGS.DISABLED');
		// 		break;
		//
		// 	case 'running':
		// 		this.isRunning = true;
		// 		this._statusString = RbUtils.Translate.instant(`STRINGS.${this._statusString.toUpperCase()}`);
		// 		params.api.flashCells({
		// 			rowNodes: [rowNode]
		// 		});
		// 		break;
		// }
	}
}

/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, Injector } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CachedCollection } from '../_common/cached-collection';
import { Duration } from 'moment';
import { FlowSensorModel } from './models/flow-sensor-model.model';
import { GetSensorQueryParams } from './models/get-sensor-params.model';
import { GolfSensorKingdomType } from './models/golf-sensor-kingdom-type.model';
import { GolfSensorListItem } from './models/golf-sensor-list-item.model';
import { GolfWeatherSensorType } from './models/golf-weather-sensor-type.model';
import { IrrigationActivityService } from '../irrigation-activity/irrigation-activity.service';
import { OnOffStateModel } from './models/on-off-state.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { SelectListItem } from '../_common/models/select-list-item.model';
import { Sensor } from './models/sensor.model';
import { SensorApiService } from './sensor-api.service';
import { SensorListChange } from './models/sensor-list-change.model';
import { SensorListItem } from './models/sensor-list-item.model';
import { SensorStatusChange } from '../signalR/sensor-status-change.model';
import { SensorTriggerManagerService } from '../sensor-trigger/sensor-trigger-manager.service';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { SystemStatusService } from '../../common/services/system-status.service';
import { TriggerThresholdInfo } from '../sensor-trigger/models/trigger-threshold-info.model';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';
import { WeatherSensor } from './models/weather-sensor.model';
import { WeatherSensorModel } from './models/weather-sensor-model.model';

import GolfWeatherSensorModel = RbEnums.Common.GolfWeatherSensorModel;
import UnitsType = RbEnums.Common.UnitsType;

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class SensorManagerService extends ServiceManagerBase {

	// Subjects
	sensorsListChange = new Subject<SensorListChange>();
	sensorStatusUpdateCompleted = new Subject();

	// Cache Containers (Expiring)
	private _golfSensorsListItemColl: CachedCollection<GolfSensorListItem>;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private sensorApiService: SensorApiService,
				protected broadcastService: BroadcastService,
				private systemStatusService: SystemStatusService,
				private injector: Injector,
				private sensorTriggerManager: SensorTriggerManagerService) {

		super(broadcastService);

		this.broadcastService.sensorsUpdated
			.pipe(untilDestroyed(this))
			.subscribe((changes: SensorStatusChange[]) => {
				changes.filter(c => c.satelliteId != null).forEach(change => {
					this.getSensorsList(change.satelliteId, true)
						.subscribe((sensors: SensorListItem[]) => this.sensorsListChange.next(new SensorListChange(change.satelliteId, sensors)));
				});
			});

		this.systemStatusService.sensorStatusUpdateCompleted
			.pipe(untilDestroyed(this))
			.subscribe(() => this.sensorStatusUpdateCompleted.next(null));

		this.broadcastService.controllerCollectionChange
			.pipe(untilDestroyed(this))
			.subscribe(() => this.clearCache());

		this.systemStatusService.golfSensorStatusChange
			.pipe(
				untilDestroyed(this)
			)
			.subscribe((sensorStatusChange: SensorStatusChange) => {
				// RB-8243: This could be a status update. Check for that and fire updateGolfSensorStatuses, if so.
				if (RbUtils.Sensor.isSensorRealTimeStatusMessage(sensorStatusChange.changeType)) {
					this.updateGolfSensorStatuses(sensorStatusChange);
				} else {
					// RB-8243: This could be an add/delete/update item. If so, send the list-changed event. We don't
					// have the complete sensor list here, so inform clients to load the list, if they want it.
					switch (sensorStatusChange.changeType) {
						case RbEnums.SignalR.SensorStatusChangeType.Added:
						case RbEnums.SignalR.SensorStatusChangeType.Deleted:
						case RbEnums.SignalR.SensorStatusChangeType.Updated:
							this.sensorsListChange.next(null);
							break;
					}
				}
			});
		this.broadcastService.connectionCompleted
			.pipe(untilDestroyed(this))
			.subscribe((interfaceId) => {
				this.updateSensorInterfaceConnection(interfaceId, true);
			});

		this.broadcastService.connectionFailed
			.pipe(untilDestroyed(this))
			.subscribe((interfaceId) => {
				this.updateSensorInterfaceConnection(interfaceId, false);
			});

		this.broadcastService.controllerRestored
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.clearCache();
			});
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	clearCache() {
		this.sensorApiService.clearCache();
		this._golfSensorsListItemColl = null;
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	deleteSensors(sensorIds: number[]): Observable<void> {
		return this.sensorApiService.deleteSensors(sensorIds);
	}

	getAddressUniqueness(address: string, id: number, controllerId: number): Observable<UniquenessResponse> {
		return this.sensorApiService.getAddressUniqueness(address, id, controllerId);
	}

	getFlowSensorModels(): Observable<FlowSensorModel[]> {
		return this.sensorApiService.getFlowSensorModels();
	}

	getNameUniqueness(name: string, id: number, controllerId: number): Observable<UniquenessResponse> {
		return this.sensorApiService.getNameUniqueness(name, id, controllerId);
	}

	getSensorsList(controllerId: number = null, bypassCache = false): Observable<SensorListItem[]> {
		if (controllerId) {
			return this.sensorApiService.getSensorsListBySatelliteId(controllerId, bypassCache).pipe(map(response => {
				return this.sort(response.value);
			}));
		}
		return this.sensorApiService.getSensorsList(bypassCache).pipe(map(response => {
			return this.sort(response.value.filter(s => controllerId == null ? true : s.satelliteId === controllerId));
		}));
	}

	getSensorsListBySiteId(siteId: number = null, bypassCache = false): Observable<SensorListItem[]> {
		return this.sensorApiService.getSensorsList(bypassCache).pipe(map(response => {
			return this.sort(response.value.filter(s => !siteId || s.siteId === siteId));
		}));
	}

	getSensorListItem(sensorId: number, bypassCache = false): Observable<SensorListItem> {
		return this.getSensorsList(null, bypassCache).pipe(map((sensors => sensors.find(s => s.id === sensorId))));
	}

	getGolfSensorList(bypassCache = false): Observable<GolfSensorListItem[]> {
		// Create an instance of the irrigationActivityService and look up sensor status for each golf sensor,
		// as we go. We can't inject this into our constructor because there's a circular dependency there (the
		// IrrigationActivityService depends on us already).
		const irrigationActivityService = this.injector.get(IrrigationActivityService);
		return this.sensorApiService.getGolfSensorsList(bypassCache).pipe(map(response => {
			if (!response.isFromCache) {
				// Query the irrigation activity service for any existing status for list items before returning them.
				response.value.forEach(sensor => sensor.sensorStatus = irrigationActivityService.getGolfSensorStatus(sensor.id));
			}
			this._golfSensorsListItemColl = new CachedCollection(response.value);
			return this.sort(response.value);
		}));
	}

	getSensor(id: number, queryParams?: GetSensorQueryParams): Observable<Sensor> {
		return this.sensorApiService.getSensor(id, queryParams);
	}

	getWeatherSensorModels(): Observable<WeatherSensorModel[]> {
		return this.sensorApiService.getWeatherSensorModels();
	}

	getLocalSensorModels(parentControllerId: number): Observable<WeatherSensorModel[]> {
		return this.sensorApiService.getLocalSensorModels(parentControllerId);
	}

	updateSensors(sensorIds: number[], updateData: any): Observable<null> {
		return this.sensorApiService.updateSensors(sensorIds, updateData)
			.pipe(tap(() => this.sensorsListChange.next(null)));
	}

	getOnOffStates(): Observable<OnOffStateModel[]> {
		return this.sensorApiService.getOnOffStates();
	}

	getGolfProgrammableSensorModel(): Observable<SelectListItem[]> {
		return this.sensorApiService.getGolfProgrammableSensorModel();
	}

	getGolfSensorKingdom(): Observable<GolfSensorKingdomType[]> {
		return this.sensorApiService.getGolfSensorKingdom().pipe(tap(list => {
			list.sort((a, b) => a.value - b.value);
		}));
	}

	getGolfWeatherSensorModel(): Observable<GolfWeatherSensorType[]> {
		return this.sensorApiService.getGolfWeatherSensorModel();
	}

	createSensor(sensor: any): Observable<Sensor> {
		return this.sensorApiService.createSensor(sensor)
			.pipe(tap(() => this.sensorsListChange.next(null)));
	}

	getSharedWeatherSensors(controllerId: number): Observable<WeatherSensor[]> {
		return this.sensorApiService.getSharedWeatherSensors(controllerId);
	}

	/**
	* Return the golf rainfall string for Rain Watch, in the user's profile units (mm or inch).
	* @param rainfall - Rainfall value in inches. Will be converted to mm, if Metric units currently
	*	selected by the user.
	* @returns string containing rainfall amount and units.
	*/
	getGolfSensorRainfallString(rainfall: number): any {
		return RbUtils.Sensor.getGolfSensorRainfallString(rainfall);
	}

	static getGolfSensorThresholdInfo(sensorModel: GolfWeatherSensorModel, unitsType: UnitsType): TriggerThresholdInfo {
		let thresholdInfo: TriggerThresholdInfo;

		switch (sensorModel) {
			case GolfWeatherSensorModel.RainCan:
				thresholdInfo = {
					minValue: unitsType === UnitsType.English ? 0.01 : 0.1,
					maxValue: unitsType === UnitsType.English ? 10 : 10 * 25.4,
					increment: unitsType === UnitsType.English ? .01 : .1,
					defaultValue: unitsType === UnitsType.English ? 0.01 : 0.2,
					decimalPlaces: unitsType === UnitsType.English ? 2 : 3,
					units: unitsType === UnitsType.English ? 'UNIT_TYPE.IN/PULSE' : 'UNIT_TYPE.MILLIMETER/PULSE'
				};
				break;
		}

		return thresholdInfo;
	}

	/**
	 * Return a string describing how long ago a sensor event happened. This will be applied to pause events,
	 * rain events, and shutdown events in Rain Watch, for example. We want to give a more-accurate description
	 * of how long ago things occurred than we do for Log Retrieval in commercial as the sensor operations are
	 * more time-critical.
	 * @param duration - moment.Duration describing how long ago the event occurred.
	 * @returns string containing something of the general form "3 hours 20 minutes ago", etc.
	 */
	durationFormatString(duration: Duration): string {
		if (duration != null) {
			if (duration.asHours() >= 24) {
				// Handle the > 24 hour case. We want days and hours for that one.
				const days = duration.days();	// Whole number of days
				const hours = duration.hours();	// Get whole number hours. We count 23 hours 59 minutes
												// as 23 for this.

				// Select the right format based on the singular-ness of days and hours.
				let daysString = '';
				if (days === 1) {
					// Exactly one day.
					daysString = RbUtils.Translate.instant('STRINGS.DAY_LOWERCASE');
				} else {
					// More than one day.
					daysString = RbUtils.Translate.instant('STRINGS.DAYS_LOWERCASE');
				}
				let hoursString = '';
				if (hours === 1) {
					hoursString = RbUtils.Translate.instant('STRINGS.HOUR_LOWERCASE');
				} else {
					hoursString = RbUtils.Translate.instant('STRINGS.HOURS_LOWERCASE');
				}

				// Convert the information and units values into a period of time. In English, this is something
				// like "{{days}} {{daysString}}, {{hours}} {{hoursString}} ago"
				return RbUtils.Translate.instant('STRINGS.DAYS_HOURS_AGO', { days, daysString, hours, hoursString });
			} else if (duration.asHours() >= 1) {
				// More than an hour but less than a day. We want hours and minutes. We want the minutes rounded up
				// if the seconds are >= 30. TODO: RBCC - Currently we don't worry about rounding up seconds, but that
				// would give a better answer.
				const hours = duration.hours();
				const minutes = duration.minutes();

				let hoursString = '';
				if (hours === 1) {
					hoursString = RbUtils.Translate.instant('STRINGS.HOUR_LOWERCASE');
				} else {
					hoursString = RbUtils.Translate.instant('STRINGS.HOURS_LOWERCASE');
				}
				let minutesString = '';
				if (minutes === 1) {
					minutesString = RbUtils.Translate.instant('STRINGS.MINUTE_LOWERCASE');
				} else {
					minutesString = RbUtils.Translate.instant('STRINGS.MINUTES_LOWERCASE');
				}

				// Convert the information and units values into a period of time. In English, this is something
				// like "{{hours}} {{hoursString}}, {{minutes}} {{minutesString}} ago"
				return RbUtils.Translate.instant('STRINGS.HOURS_MINUTES_AGO', { hours, hoursString, minutes, minutesString });
			} else {
				// We're talking less than an hour. Round the minutes up or down to the nearest minute. This
				// avoids an issue where our second read of a sensor occurs 59.99 seconds after the last one
				// but the minutes-ago value doesn't change.
				const minutes = Math.round(duration.asMinutes());
				return (minutes === 1) ?
					RbUtils.Translate.instant('STRINGS.MIN_AGO', { minutes })
					: RbUtils.Translate.instant('STRINGS.MINS_AGO', { minutes });
			}
		} else {
			return '';
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private sort(list) {
		return list.sort((a, b) => a.type.valueOf() > b.type.valueOf() ? 1 : a.type.valueOf() < b.type.valueOf() ? -1 : 0 );
	}

	/**
	 * When new sensor status arrives, update golf sensor list items. We handle the case where sensor status arrives before
	 * the sensor list is loaded separately. NOTE: We can't be sure that the SignalR object passed is a value-changed
	 * message, so double-check before updating status.
	 * @param sensorStatusChange SensorStatusChange from SignalR to be assigned to the sensor list item that corresponds with
	 * it.
	 */
	private updateGolfSensorStatuses(sensorStatusChange: SensorStatusChange) {
		// If no sensors yet loaded, nothing more to do.
		if (!this._golfSensorsListItemColl) {
			return;
		}

		// If the update is not a real-time update, ignore it here.
		if (RbUtils.Sensor.isSensorRealTimeStatusMessage(sensorStatusChange.changeType)) {
			const golfSensors = (this._golfSensorsListItemColl.collection);

			golfSensors.forEach(sensor => {
				if (sensorStatusChange != null && sensorStatusChange.sensorId === sensor.id) {
					sensor.sensorStatus = RbUtils.Sensor.updateGolfSensorStatus(sensorStatusChange, sensor,
						(sensor.kingdomId === RbEnums.Common.GolfSensorKingdom.Programmable) ?
							this.sensorTriggerManager.getSensorTriggersForSensor(sensor.id, false /* use cache */) :
							of([]));
				}
			});
		}
	}
	private updateSensorInterfaceConnection(interfaceId: number, connected: boolean) {
		// If no sensors yet loaded, nothing more to do.
		if (!this._golfSensorsListItemColl) {
			return;
		}
		const golfSensors = (this._golfSensorsListItemColl.collection);
		golfSensors.forEach(sensor => {
			if (sensor.satelliteId === interfaceId) {
				sensor.isInterfaceConnected = connected;
			}
		});
	}
}

import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Station } from '../../stations/models/station.model';
import { StationListItem } from '../../stations/models/station-list-item.model';

export class FlowElement {

	aConnectionId: number;
	companyId: number;
	description: string;
	flowCapacity: number;
	id: number;
	name: string;
	numberOfSubBranches: number;
	ordinal: number;
	isHidden: boolean;
	aConnection: FlowElement;
	bConnections: FlowElement[];
	role: RbEnums.Common.FlowElementType;
	triggerStation: any;
	triggerStationId: number;
	currentFlow: number;
	availableFlow: number;
	stations: Station[];
	pump: string;
	source: string;
	isLocked: boolean;

	/**
	 * Flag used in the Flo-Manager tree to display visual indicators for
	 * new flow elements
	 */
	isNew: boolean;

	/** 
	 * Flag used in the Flo-Manager tree to render the appropriate visual elements and to  
	 * know which element node is the "parent" of all unassigned stations
	 */
	isFloGuardStationsGroup?: boolean;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.bConnections) {
				this.bConnections = json.bConnections.map(c => new FlowElement(c)).sort((a, b) => FlowElement.compareFlowElementsForSorting(a, b));
			}
		}
	}

	get typeName(): string {
		switch (this.role) {
			case RbEnums.Common.FlowElementType.FlowZone:
				return RbUtils.Translate.instant('STRINGS.FLOZONE_TM');
			case RbEnums.Common.FlowElementType.Pump:
				return RbUtils.Translate.instant('STRINGS.PUMP_STATION');
			case RbEnums.Common.FlowElementType.Branch:
				return RbUtils.Translate.instant('STRINGS.BRANCH');
			case RbEnums.Common.FlowElementType.MasterValve:
				return RbUtils.Translate.instant('STRINGS.MASTER_VALVE');
		}
	}

	get subBranchesCountGridColumn(): string {
		return this.numberOfSubBranches > 0 ? this.numberOfSubBranches.toString() : '-';
	}

	static compareFlowElementsForSorting(a: FlowElement, b: FlowElement, stations: StationListItem[] = []): number {
		// Sort for for display: Master Valve, Pump, Branch, Flow Zone, Station
		switch (a.role) {
			case RbEnums.Common.FlowElementType.MasterValve:
				return b.role === RbEnums.Common.FlowElementType.MasterValve ? a.ordinal - b.ordinal : 1;
			case RbEnums.Common.FlowElementType.Pump:
				return b.role === RbEnums.Common.FlowElementType.Pump ? a.ordinal - b.ordinal :
				(b.role === RbEnums.Common.FlowElementType.MasterValve ? 1 : -1);
			case RbEnums.Common.FlowElementType.Branch:
				return b.role === RbEnums.Common.FlowElementType.Branch ? a.ordinal - b.ordinal :
				(b.role === RbEnums.Common.FlowElementType.MasterValve || b.role === RbEnums.Common.FlowElementType.Pump ? 1 : -1);
			case RbEnums.Common.FlowElementType.FlowZone:
				return b.role === RbEnums.Common.FlowElementType.FlowZone ? a.ordinal - b.ordinal :
				(b.role === RbEnums.Common.FlowElementType.Station ? -1 : 1);
			case RbEnums.Common.FlowElementType.Station:
				return b.role === RbEnums.Common.FlowElementType.Station ? FlowElement.compareStations(a, b, stations) : 1;
		}
	}

	static compareStations(a: FlowElement, b: FlowElement, stations: StationListItem[]) {
		const aStation = stations.find(s => s.id === a.id);
		const bStation = stations.find(s => s.id === b.id);
		if (aStation == null || bStation == null) return a.name.localeCompare(b.name);

		// return aStation.locationString.localeCompare(bStation.locationString);
		return aStation.areaLevel2Number !== bStation.areaLevel2Number ? aStation.areaLevel2Number - bStation.areaLevel2Number :
			(aStation.areaLevel3AreaNumber !== bStation.areaLevel3AreaNumber ? aStation.areaLevel3AreaNumber - bStation.areaLevel3AreaNumber :
				(aStation.areaLevel3Number - bStation.areaLevel3Number));
	}

	static createDefault(role: RbEnums.Common.FlowElementType) {
		const element = new FlowElement();
		element.role = role;
		element.flowCapacity = 0;
		return element;
	}
}

import { ChangeBase } from './change-base.model';
import { RbUtils } from '../../common/utils/_rb.utils';

export class RadioRelayChange extends ChangeBase {

	constructor(json: any = null) {

		super(json);

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here
			if (json.changeDateTime && json.changeDateTime instanceof String) {
				this.changeDateTime = RbUtils.Conversion.convertStringToDate(json.changeDateTime);
			}
		}
	}

	changeDateTime: Date;
	changeType: string;
	errorMessage: string = null;
	itemsChanged: any;
	reasonCode: string;
	radioRelayId: number;
}
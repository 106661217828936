<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-dialog
	[title]="'STRINGS.START_CONTROLLER' | translate"
	[closeButtonLabel]="'STRINGS.CANCEL' | translate "
	[submitButtonLabel]="'STRINGS.SAVE' | translate"
	[showSubmitButton]="true"
	[saveDisabled]="!isItemSelected"
	(onSubmit)="onSave()">

	<div class="start-controller-dialog">
		<form>
			<div class="radio-buttons">
				<mat-radio-button color="primary" data-qaid="program-radio" [checked]="selectStations" (change)="handleStationRadioSelected()">
					{{ 'STRINGS.STATIONS' | translate }}
				</mat-radio-button>
				<mat-radio-button color="primary" data-qaid="program-radio" [checked]="!selectStations" (change)="handleProgramRadioSelected()">
					{{ 'STRINGS.PROGRAMS' | translate }}
				</mat-radio-button>
			</div>

			<div class="main-content">
				<div *ngIf="!selectStations">
					<div class="" *ngFor="let program of programs">
						<mat-checkbox [(ngModel)]="program.selected" name="program" color="primary">{{program.name}}</mat-checkbox>
					</div>
				</div>

				<div *ngIf="selectStations">
					<div class="" *ngFor="let station of stations">
						<mat-checkbox [(ngModel)]="station.selected" name="station" color="primary">{{station.name}}</mat-checkbox>
					</div>
				</div>
			</div>
		</form>
	</div>
</rb-dialog>

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div id="active-flow-toolbar-container" [ngClass]="{'aft-mobile': isMobile}">

	<div id="aft-left-pane">

		<div class="view-type-selection-container" *ngIf="!isMobile">
			<rb-icon-button
				[label]="'STRINGS.CHART' | translate"
				icon="equalizer"
				class="dr-view-type"
				[disabled]="disabled"
				[ngClass]="{'dr-view-selected':activeFlowView === ActiveFlowView.Chart}"
				(click)="activeFlowView = ActiveFlowView.Chart"
			></rb-icon-button>
		</div>

		<div class="view-type-selection-container" *ngIf="!isMobile">
			<rb-icon-button
				[label]="'STRINGS.LIST' | translate"
				icon="format_list_bulleted"
				class="dr-view-type"
				[disabled]="disabled"
				[ngClass]="{'dr-view-selected': activeFlowView === ActiveFlowView.List}"
				(click)="activeFlowView = ActiveFlowView.List"
			></rb-icon-button>
		</div>

		<div class="view-type-selection-container" *ngIf="!isMobile">
			<rb-icon-button
					[label]="'STRINGS.COURSE' | translate"
					icon="golf_course"
					class="dr-view-type"
					[disabled]="disabled"
					[ngClass]="{'dr-view-selected': activeFlowView === ActiveFlowView.Course}"
					(click)="activeFlowView = ActiveFlowView.Course"
			></rb-icon-button>
		</div>

		<div id="date-selection-container" *ngIf="activeFlowView === ActiveFlowView.Chart">
			<div *ngIf="!isMobile">
				<button mat-icon-button
						class="fixed-date-btn"
						[disabled]="disabled"
						[ngClass]="{'selected-date-range': dateRange === rbDateRange.twelveHours}"
						(click)="dateRange = rbDateRange.twelveHours"
				>
					{{ getHourShortString(12) }}
				</button>
				<button mat-icon-button
						class="fixed-date-btn"
						[disabled]="disabled"
						[ngClass]="{'selected-date-range': dateRange === rbDateRange.oneDay}"
						(click)="dateRange = rbDateRange.oneDay"
				>
					{{ getHourShortString(24) }}
				</button>
			</div>
		</div>

		<div class="view-type-selection-container" *ngIf="!isMobile">
			<mat-checkbox 
				[(ngModel)]="showPumpData"
				class="dr-view-type pt2"
				[indeterminate]="isPumpDataCheckIndeterminate"
				data-qaid="drt-show-pumps"
			>
			{{ 'STRINGS.SHOW_SEPARATE_PUMPS' | translate }}
			</mat-checkbox>
		</div>

		<div id="active-flow-activity-type-selector" *ngIf="activeFlowView === ActiveFlowView.List">
			<mat-form-field class="ml2 mr2">
				<mat-select
					[disabled]="disabled"
					[placeholder]="'STRINGS.ACTIVITY_TYPE' | translate"
					[(ngModel)]="activityType"
				>
					<mat-option *ngFor="let aType of activityTypes" [value]="aType.value" class="pv1">
						{{aType.name | translate}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<rb-main-panel-toolbar
			*ngIf="activeFlowView === ActiveFlowView.Course"
			[includePlay]="true"
			[includeStop]="true"
			[includePause]="true"
			[includeResume]="true"
			[disableResume]="disableResume"
			[selectedGridItems]="selectedCourseViewStations"
			(stopClick)="onStopStations()"
			(playClick)="onStartStations()"
			(pauseClick)="onPauseStations()"
			(resumeClick)="onResumeStations()"
		>
		</rb-main-panel-toolbar>
	</div>

	<div id="aft-right-pane">
		<div id="aft-print-btn-container">
			<button *ngIf="!hidePrintButton" mat-button id="drc-view-pdf-btn" (click)="generatePdf()" [ngClass]="{'show-border': !isMobile}" [disabled]="!isPrintEnabled">
				<mat-icon *ngIf="isMobile">picture_as_pdf</mat-icon>
				<span *ngIf="!isMobile">{{ 'STRINGS.VIEW_PDF' | translate }}</span>
			</button>
		</div>
	</div>
</div>

export class WorldWeatherRequest {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	type: string;
	query: string;

}

import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'rb-number-arrow-button',
	templateUrl: './number-arrow-button.component.html',
	styleUrls: ['./number-arrow-button.component.scss'],
})
export class NumberArrowButtonComponent implements OnInit {
	@Input() fControl: FormControl;
	@Input() step: number;
	@Input() precison: number;
	@Input() min: number;
	@Input() max: number;
	isMobile = false;
	allowComma = false;

	constructor(
		private elRef: ElementRef,
		private deviceManager: DeviceManagerService,
		private authManager: AuthManagerService
	) {
		this.isMobile = this.deviceManager.isMobile;
		this.allowComma = this.authManager.userCulture.allowComma;
	}

	ngOnInit() {
		const parentElement = this.elRef.nativeElement.parentElement;
		parentElement.className = `${parentElement.className} arrow-btn-input`;
	}

	increaseNumber() {
		if (this.isIncreaseNumberDisable) return;
		const updatedValue = +(this.convertToNumber(this.fControl.value) + this.step).toFixed(this.precison);
		this.fControl.setValue(this.convertToCommaNumber(updatedValue));
		this.fControl.markAsDirty();
	}

	decreaseNumber() {
		if (this.isDecreaseNumberDisable) return;
		const updatedValue = +(this.convertToNumber(this.fControl.value) - this.step).toFixed(this.precison);
		this.fControl.setValue(this.convertToCommaNumber(updatedValue));
		this.fControl.markAsDirty();
	}

	get isIncreaseNumberDisable() {
		if (this.max === undefined) return false;
		return this.convertToNumber(this.fControl.value) >= this.max;
	}

	get isDecreaseNumberDisable() {
		if (this.min === undefined) return false;
		return this.convertToNumber(this.fControl.value) <= this.min;
	}

	private convertToCommaNumber(value: number) {
		if (this.allowComma) {
			return `${value}`.replace(/\./, ',');
		}
		return `${value}`;
	}

	private convertToNumber(value: string) {
		let convertedValue = 0;
		if (this.allowComma) {
			convertedValue = +`${value}`.replace(/\,/, '.');
		} else {
			convertedValue = +`${value}`;
		}

		if (isNaN(convertedValue) || !convertedValue) {
			convertedValue = 0;
		}
		return convertedValue;
	}
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { DryRunEventListItem } from '../../../../../api/dry-run/models/dry-run-event-list-item.model';

@Component({
	selector: 'rb-dry-run-mobile-cell-renderer',
	templateUrl: './dry-run-mobile-cell-renderer.component.html',
	styleUrls: ['./dry-run-mobile-cell-renderer.component.scss']
})
export class DryRunMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	dryRunEvent: DryRunEventListItem;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.dryRunEvent = params.data;
	}
}

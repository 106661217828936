import { EventEmitter, Injectable } from '@angular/core';
import { Subject, take } from 'rxjs';
import { CompanyManagerService } from '../../api/companies/company-manager.service';
import { ProgramChange } from '../../api/signalR/program-change.model';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';
import { SensorStatusChange } from '../../api/signalR/sensor-status-change.model';
import { StationStatusChange } from '../../api/signalR/station-status-change.model';
import { StationValveTypeChange } from '../../api/signalR/station-valve-type-change.model';

@Injectable({
	providedIn: 'root'
})
export class SystemStatusService {

	// Events
	irrigationEngineChange = new Subject<RbEnums.Common.IrrigationEngineChangeType>();
	irrigationEngineStatusChange = new Subject<RbEnums.Common.SystemStatus>();
	theoreticalFlowRateTotalChange = new Subject<number>();
	stationStatusChange = new Subject<StationStatusChange>();
	stationCacheShouldBeUpdated = new Subject<StationStatusChange>();
	stationValveTypeStatusChange = new Subject<StationValveTypeChange>();
	golfSensorStatusChange = new Subject<SensorStatusChange>();
	stationStatusUpdateCompleted = new Subject();
	sensorStatusUpdateCompleted = new Subject();
	golfProgramStatusChange = new Subject<ProgramChange>();
	golfProgramGroupStatusChange = new Subject<ProgramChange>();
	companyStatusUpdated = new EventEmitter();

	// Private Members
	private _lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_NotIrrigating;
	private _lastReportedSystemStatus = RbEnums.Common.SystemStatus.NoFieldActivity;
	private _isSystemLocked = false;
	private _programStatusChange: { [id: number]: ProgramChange; } = {};
	private _programGroupStatusChange: { [id: number]: ProgramChange; } = {};
	private _stationStatusChange: { [id: number]: StationStatusChange; } = {};
	private _sensorStatusChange: { [id: number]: SensorStatusChange; } = {};

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================
	constructor(private companyManager: CompanyManagerService) { 
		// RB-12857: We can't count on SignalR messages to give us the correct initial states. Imagine
		// the case where the system is OFF and the user reboots the system (or turns off the system for
		// six months during winter). On restart, we need to recover the system state from the database
		// or we'll have the wrong value.
		this.companyManager.getCompanyPreferences().pipe(take(1)).subscribe(cp => {
			// Convert the preferences data into the right initial state. Use the setter for the property
			// so these updates will be sent to subscribers.
			switch(cp.systemMode) {
				case RbEnums.Common.SystemMode.Off:
					this.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModeOff_NotIrrigating;
					break;
				case RbEnums.Common.SystemMode.Paused:
					this.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModePaused_NotIrrigating;
					break;
				case RbEnums.Common.SystemMode.Auto:
					this.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_NotIrrigating;
					break;
			}
		});
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get lastReportedFieldActivity(): RbEnums.Common.IrrigationEngineChangeType {
		return this._lastReportedFieldActivity;
	}

	set lastReportedFieldActivity(value: RbEnums.Common.IrrigationEngineChangeType) {
		this._lastReportedFieldActivity = value;
		this.irrigationEngineChange.next(this._lastReportedFieldActivity);
	}

	get isSystemLocked(): boolean {
		return this._isSystemLocked;
	}

	set isSystemLocked(value: boolean) {
		this._isSystemLocked = value;
	}

	get lastReportedSystemStatus(): RbEnums.Common.SystemStatus {
		return this._lastReportedSystemStatus;
	}

	set lastReportedSystemStatus(value: RbEnums.Common.SystemStatus) {
		this._lastReportedSystemStatus = value;
		this.irrigationEngineStatusChange.next(this._lastReportedSystemStatus);
	}

	getGolfStationStatus(stationId: number): StationStatusChange {
		// Note that the stationId might be undefined/null, as the program step might not be a RunStationStep.
		if (!stationId) {
			return null;
		}
		return this._stationStatusChange[stationId];	// May be undefined. Caller should be ready.
	}

	// NOTE: THIS METHOD IS USED BY COMMERCIAL TOO! This method will properly update station information
	//       on the map if, for example, the station name is changed. Perhaps the method should be renamed.
	setStationStatus(value: StationStatusChange) {
		// Filter the values for actual "status". We save those, but use things like Added, Deleted differently.
		let notify = true;
		// RB-7496: Assure that only the real station status values are saved and notified. Added, for example,
		// is not a "status" in this sense. We handle Deleted by removing any existing station status data.
		if (RbUtils.Stations.isStationRealTimeStatusMessage(value.changeType)) {
			// notify should be true and we should save the message as the last-received status.
			this._stationStatusChange[value.stationId] = value;
		} else {
			notify = value.changeType === RbEnums.SignalR.StationStatusChangeType.Updated ||
				value.changeType === RbEnums.SignalR.StationStatusChangeType.BatchUpdated; // Only notify for "Updated"

			// Special handling for the deleted message. Otherwise, simply do not save or notify.
			switch (value.changeType) {
				case RbEnums.SignalR.StationStatusChangeType.Deleted:	// Remove any existing status (!)
					delete this._stationStatusChange[value.stationId];
					break;
			}
		}

		// Notify subscribers of the change.
		if (notify) {
			this.stationStatusChange.next(value);
		}

		if (value.changeType === RbEnums.SignalR.StationStatusChangeType.Updated ||
			value.changeType === RbEnums.SignalR.StationStatusChangeType.Added ||
			value.changeType === RbEnums.SignalR.StationStatusChangeType.Deleted) {
			this.stationCacheShouldBeUpdated.next(value);
		}
	}

	stationStatusUpdateComplete() {
		this.stationStatusUpdateCompleted.next(null);
	}

	setStationValveTypeStatus(value: StationValveTypeChange) {
		this.stationValveTypeStatusChange.next(value);
	}

	getGolfProgramStatus(programId: number): ProgramChange {
		if (!programId) {
			return null;
		}
		return this._programStatusChange[programId];
	}

	setGolfProgramStatus(value: ProgramChange) {
		// Filter the values for actual "status". We save those, but use things like Added, Deleted and Updated
		// differently.
		let notify = true;
		// RB-7496: Assure that only the real program status values are saved and notified. Added, for example,
		// is not a "status" in this sense. We handle Deleted by removing any existing status data.
		if (RbUtils.Programs.isProgramRealTimeStatusMessage(value.changeType)) {
			// notify should be true and we should save the message as the last-received status.
			this._programStatusChange[value.programId] = value;
		} else {
			// Special handling for the deleted message: no notification, delete the existing status.
			// NOTE: THIS METHOD IS USED BY COMMERCIAL TOO! This method will properly update station information
			//       on the map if, for example, the station name is changed. Perhaps the method should be renamed.
			switch (value.changeType) {
				case RbEnums.SignalR.ProgramStatusChangeType.Deleted:	// Remove any existing status (!)
					notify = false;
					delete this._programStatusChange[value.programId];
					break;
			}
		}

		// Notify subscribers of the change.
		if (notify) {
			this.golfProgramStatusChange.next(value);
		}
	}

	/**
	 * setGolfGroupAndProgramStatuses operates on a SET of changes where all other programs and program groups should
	 * be assumed to be idle. This method is called when we get a periodic "everything" update. NOTE: ALL UPDATES
	 * PASSED TO THIS METHOD MUST BE ACTUAL REAL-TIME STATUS UPDATES, NOT Updated, Created, Deleted, ETC. RB-8985
	 * @param changeDateTime - Date specifying the change date/time for all program and program group changes passed
	 * @param programGroupChanges - ProgramChange[] containing info on all active program groups
	 * @param programChanges - ProgramChange[] containing info on all active programs.
	 */
	setGolfGroupAndProgramStatuses(changeDateTime: Date, programGroupChanges: ProgramChange[], programChanges: ProgramChange[]) {
		// Since we are replacing all program group activity, clear existing activity first, but obviously not activity
		// later than the replacement date/time. Repeat the operation for program activity.
		for (const key of Object.keys(this._programGroupStatusChange)) {
			// If the item's date is earlier than changeDateTime, remove it. If later or equal, leave it.
			const item = this._programGroupStatusChange[key];
			if (item.changeDateTime.valueOf() < changeDateTime) {
				// Notify subscribers of the change and remove the active entry. To simplify this operation, just set the
				// existing item status to finished and send that.
				delete this._programGroupStatusChange[key];

				if (item.changeType !== RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted) {
					item.changeType = RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted;
					item.changeDateTime = changeDateTime;
					this.golfProgramGroupStatusChange.next(item);
				}
			}
		}
		for (const key of Object.keys(this._programStatusChange)) {
			// If the item's date is earlier than changeDateTime, remove it. If later or equal, leave it.
			const item = this._programStatusChange[key];
			if (item.changeDateTime.valueOf() < changeDateTime) {
				// Remove and send update for item being removed. Set status to finished.
				delete this._programStatusChange[key];

				if (item.changeType !== RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted) {
					item.changeType = RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted;
					item.changeDateTime = changeDateTime;
					this.golfProgramStatusChange.next(item);
				}
			}
		}

		// Enumerate all of the changes, adding each to the correct dictionary.
		programGroupChanges.forEach(pg => {
			// Do some simple verification assuring we're keeping the latest item, just in case the same program group
			// has multiple entries or we get an asynchronous update while processing this list.
			const id = pg.programGroupId;
			if ((this._programGroupStatusChange[id] == null) ||
				(changeDateTime.valueOf() > this._programGroupStatusChange[id].changeDateTime.valueOf())) {
				this._programGroupStatusChange[id] = pg;

				// Notify subscribers of the change.
				this.golfProgramGroupStatusChange.next(pg);
			}
		});
		programChanges.forEach(p => {
			// Do some simple verification assuring we're keeping the latest item, just in case the same program group
			// has multiple entries or we get an asynchronous update while processing this list.
			const id = p.programId;
			if ((this._programStatusChange[id] == null) ||
				(changeDateTime.valueOf() > this._programStatusChange[id].changeDateTime.valueOf())) {
				this._programStatusChange[id] = p;

				// Notify subscribers of the change.
				this.golfProgramStatusChange.next(p);
			}
		});
	}

	getGolfProgramGroupStatus(programGroupId: number): ProgramChange {
		if (!programGroupId) {
			return null;
		}
		return this._programGroupStatusChange[programGroupId];
	}

	/**
	 * Save and forward a program group (golf Program) status change indication. We save program status for quick
	 * and diverse access to the running status of programs. We use some of the other status items (deleted) to
	 * update our list in other ways.
	 */
	setGolfProgramGroupStatus(value: ProgramChange) {
		// Filter the values for actual "status". We save those, but use things like Added, Deleted and Updated
		// differently.
		let notify = true;
		// RB-7496: Assure that only the real program status values are saved and notified. Added, for example,
		// is not a "status" in this sense. We handle Deleted by removing any existing status data.
		if (RbUtils.Programs.isProgramRealTimeStatusMessage(value.changeType)) {
			// notify should be true and we should save the message as the last-received status.
			this._programGroupStatusChange[value.programGroupId] = value;
		} else {
			// Special handling for the deleted message: no notification, delete the existing status.
			// NOTE: THIS METHOD IS USED BY COMMERCIAL TOO! This method will properly update station information
			//       on the map if, for example, the station name is changed. Perhaps the method should be renamed.
			switch (value.changeType) {
				case RbEnums.SignalR.ProgramStatusChangeType.Deleted:	// Remove any existing status (!)
					notify = false;
					delete this._programGroupStatusChange[value.programGroupId];
					break;
			}
		}

		// Notify subscribers of the change.
		if (notify) {
			this.golfProgramGroupStatusChange.next(value);
		}
	}

	getGolfSensorStatus(sensorId: number): SensorStatusChange {
		// Note that the sensorId might be undefined/null.
		if (!sensorId) {
			return null;
		}
		return this._sensorStatusChange[sensorId];	// May be undefined. Caller should be ready.
	}

	setGolfSensorStatus(value: SensorStatusChange) {
		// Filter the values for actual "status". We save those, but use things like Added, Deleted differently.
		const notify = true;
		if (RbUtils.Sensor.isSensorRealTimeStatusMessage(value.changeType)) {
			// notify should be true and we should save the message as the last-received status.
			this._sensorStatusChange[value.sensorId] = value;
		} else {
			// RB-8243: We do also need to notify the sensor manager that the list was changed by delete or
			// add, as well as update.
			// notify = value.changeType === RbEnums.SignalR.SensorStatusChangeType.Updated; // Only notify for "Updated"

			// Special handling for the deleted message. Otherwise, simply do not save or notify.
			switch (value.changeType) {
				case RbEnums.SignalR.SensorStatusChangeType.Deleted:	// Remove any existing status (!)
					delete this._sensorStatusChange[value.sensorId];
					break;
			}
		}

		// Notify subscribers of the change.
		if (notify) {
			this.golfSensorStatusChange.next(value);
		}
	}

	sensorStatusUpdateComplete() {
		this.sensorStatusUpdateCompleted.next(null);
	}

}

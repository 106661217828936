import { RbUtils } from '../../../common/utils/_rb.utils';

export class PriorityGolfInputType {

	name: string;
	value: number;
	hideFromUI?: boolean;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}

	static priorityComparator = (value1: number, value2: number): number => {
		// Priorities are ordered as shown below:
		// 1 - highest
		// ...
		// 12 - lowest prioritized
		// 13/0/null - unprioritized is lowest.
		const sort1 = value1 != null && value1 !== 0 ? value1 : 13;
		const sort2 = value2 != null && value2 !== 0 ? value2 : 13;

		return sort1 - sort2;
	}

	static getDefaultPrioritySet(): PriorityGolfInputType[] {
		return [
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_0'),
				value: 0
			},
			// Priority 1 is used for manually-started stations. This should not be displayed
			// for program group priority selection.
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_1'),
				value: 1,
				hideFromUI: true,
			},
			// Priority 2 is used for manually-started schedules. This should not be displayed
			// for program group priority selection.
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_2'),
				value: 2,
				hideFromUI: true,
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_3'),
				value: 3
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_4'),
				value: 4
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_5'),
				value: 5
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_6'),
				value: 6
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_7'),
				value: 7
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_8'),
				value: 8
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_9'),
				value: 9
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_10'),
				value: 10
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_11'),
				value: 11
			},
			{
				name: RbUtils.Translate.instant('PRIORITIES.PRIORITY_12'),
				value: 12
			},
		];
	}
}

import * as moment from 'moment';

export abstract class CachedObject {

	constructor(private cacheDurationSeconds: number = 300) {
		this.timestamp = new Date();
	}

	timestamp: Date;

	public get isExpired(): boolean {
		return moment(new Date()).diff(moment(this.timestamp), 'seconds') >= this.cacheDurationSeconds;
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-activity-mobile-cell-container">
	<div class="flex justify-between rba-mobile-cell-row">
		<div *ngIf="!isGolfSite" class="ellipsis-text f7-m w-50-m">{{activity?.satelliteName}} </div>
		<div *ngIf="!isGolfSite" class="ellipsis-text f7-m w-50-m">{{activity?.siteName}} </div>
	</div>
	<div class="flex justify-between rba-mobile-cell-row">
		<div class="f7-m tr w-50-m mr-1"> {{activity?.name}} </div>
	</div>
	<div *ngIf="activity != null && activity.totalElapsedTime != null"  class="flex justify-between rba-mobile-cell-row">
		<div class="f7-m w-20-m ellipsis-text"> {{durationCellRenderer(activity?.totalElapsedTime)}} </div>
	</div>
	<div *ngIf="activity != null && activity.children == null"  class="flex justify-between rba-mobile-cell-row">
		<div class="f7-m w-20-m ellipsis-text"> {{dateTimeCellRenderer(activity?.start)}} </div>
		<div class="f7-m w-20-m ellipsis-text"> {{dateTimeCellRenderer(activity?.end)}} </div>
	</div>
	<div *ngIf="activity != null && activity.totalElapsedTime == null" class="flex justify-between rba-mobile-cell-row">
		<div class="f7-m w-10-m ellipsis-text"> {{durationCellRenderer(activity?.elapsedTime)}} </div>
		<div class="f7-m w-10-m ellipsis-text"> {{durationCellRenderer(activity?.timeRemaining)}} </div>
	</div>
	<div class="flex justify-between rba-mobile-cell-row">
		<div class="f7-m w-30-m ellipsis-text red"> {{activity?.alarm}} </div>
	</div>
</div>

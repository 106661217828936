import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { CatalogFlowRate } from './models/catalog-flow-rate.model';
import { CatalogModel } from './models/catalog-model.model';
import { CatalogNozzle } from './models/catalog-nozzle.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CatalogApiService extends ApiService {
	// =========================================================================================================================================================
	// API Calls
	// =========================================================================================================================================================

	getModels(isCommercial: boolean): Observable<CatalogModel[]> {
		return this.apiRequest<any>(this.getModelsUrl(isCommercial), HttpMethod.Get)
			.pipe(map(jsonList => jsonList.map(item => new CatalogModel(item)).sort((m1, m2) => m1.name.localeCompare(m2.name))));
	}

	getAllNozzles(bypassCache = false): Observable<ApiCachedRequestResponse<CatalogNozzle[]>> {
		return this.apiRequestWithCache<any>(this.allNozzlesUrl, bypassCache,
			result => result.map(item => new CatalogNozzle(item)));
	}

	getNozzles(modelId: number): Observable<CatalogNozzle[]> {
		return this.apiRequest<any>(this.getNozzlesUrl(modelId), HttpMethod.Get)
			.pipe(map(jsonList => jsonList.map(item => new CatalogNozzle(item)).sort((n1, n2) => n1.name.localeCompare(n2.name))));
	}

	getNozzle(id: number): Observable<CatalogNozzle> {
		return this.apiRequest<any>(this.getNozzleUrl(id), HttpMethod.Get)
			.pipe(map(json => new CatalogNozzle(json)));
	}

	getFlowRates(nozzleId: number): Observable<CatalogFlowRate[]> {
		return this.apiRequest<any>(this.getFlowRatesUrl(nozzleId), HttpMethod.Get)
			.pipe(map(jsonList => jsonList.map(item => new CatalogFlowRate(item))));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}RBCatalog`; }

	private getModelsUrl(isCommercial: boolean): string { return `${this.baseUrl}/GetModels?isCommercial=${isCommercial}`; }

	private get allNozzlesUrl(): string { return `${this.baseUrl}/GetAllNozzles`; }

	private getNozzlesUrl(modelId): string { return `${this.baseUrl}/GetNozzles?modelId=${modelId}`; }

	private getNozzleUrl(id): string { return `${this.baseUrl}/GetNozzle?id=${id}`; }

	private getFlowRatesUrl(nozzleId): string { return `${this.baseUrl}/GetFlowRates?nozzleId=${nozzleId}`; }
}

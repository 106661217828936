export class WorldWeatherWeatherDesc {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	value: string;
}

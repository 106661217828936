import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Station } from '../../stations/models/station.model';

export class FlowMonitoring {

	companyId: number;
	floWatchEnabled = false;
	highFlowAction: RbEnums.Common.FlowActionType;
	highFlowSettlingTime: string;
	highFlowSettlingTimeLong: number;
	highFlowThreshold: number;
	id: number;
	leakPulseCount: number;
	lowFlowAction: RbEnums.Common.FlowActionType;
	lowFlowSettlingTime: string;
	lowFlowSettlingTimeLong: number;
	lowFlowThreshold: number;
	maxFlowRate = 0.0;
	name: string;
	ordinal: number;
	satelliteId: number;
	station: Station[] = [];
	timedReEnableDelay: string;
	timedReEnableDelayLong: number;
	wateringEndTime: Date;
	wateringStartTime: Date;
	weekDays: string;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.station) { this.station = json.station.map(item => new Station(item)); }
			if (json.wateringEndTime) { this.wateringEndTime = RbUtils.Conversion.convertStringToDate(json.wateringEndTime); }
			if (json.wateringStartTime) { this.wateringStartTime = RbUtils.Conversion.convertStringToDate(json.wateringStartTime); }
		}
	}

	static createDefault(): FlowMonitoring {
		const flowMonitoring = new FlowMonitoring();
		flowMonitoring.highFlowThreshold = 130;
		flowMonitoring.highFlowSettlingTimeLong = 1800000000;
		flowMonitoring.highFlowSettlingTime = '03';
		flowMonitoring.lowFlowThreshold = 70;
		flowMonitoring.lowFlowSettlingTimeLong = 1800000000;
		flowMonitoring.lowFlowSettlingTime = '03';
		flowMonitoring.timedReEnableDelayLong = 0;
		flowMonitoring.timedReEnableDelay = RbUtils.Conversion.convertHMSObjectToDuration('00:00:00').toISOString();
		flowMonitoring.lowFlowAction = 1;
		flowMonitoring.highFlowAction = 1;
		flowMonitoring.wateringStartTime = null;
		flowMonitoring.wateringEndTime = null;
		flowMonitoring.weekDays = '0000000';
		flowMonitoring.leakPulseCount = 10;
		flowMonitoring.companyId = 0;
		flowMonitoring.satelliteId = 0;
		flowMonitoring.name = '';
		flowMonitoring.ordinal = 0;

		return flowMonitoring;
	}
}

import { FeatureCollection, Geometry } from 'geojson';
import { GeoJSON } from "leaflet";
import { GeoJSONProperties } from "./geojson-properties.model";
import { KMZItemProperties } from './kmz-item-properties.model';
import { MapInfoLeaflet } from "../../../common/models/map-info-leaflet.model";

/**
 * An object with all the information related to the layers
 * imported via a KMZ file
 */
export class KMZItem {
	/** ID of KMZ item */
	id: number;

	/** Index of KMZ item relative to the rest of the layers */
	index: number;

	/** ID of the group this layer belongs to */
	groupID: number;

	/** Name of the group this layer belongs to */
	groupName: string;

	/** Name of the layer */
	fileName: string;

	/** If the layer is visible or not */
	visible: boolean;

	/** GeoJSON data */
	geoJson: FeatureCollection<Geometry, GeoJSONProperties>;

	/** The layer properties are moved into this node */
	properties: KMZItemProperties;

	/** Leaflet layer generated with the GeoJSON data */
	layer?: GeoJSON<GeoJSONProperties>;

	siteId: number;

	companyId?: number;

	/**
	 * If we are loading
	 *
	 * @Note Do not send this property to the backend
	 */
	loadingKMZ: boolean;

	/**
	 * Property that keeps track of whether this image failed to load
	 *
	 * @Note Do not send this property to the backend
	 */
	error: string;

	/**
	 * The type of this object. Used to identify if an object is a raster item or a kmz item
	 *
	 * @Note Do not send this property to the backend
	 */
	readonly type: string = 'kmzitem';

	/**
	 * The map to which this vector item belongs
	 *
	 * @Note Do not send this property to the backend
	 */
	private mapInfo: MapInfoLeaflet;

	constructor(json: any = null, mapInfo?: MapInfoLeaflet) {
		if (json) {
			Object.assign(this, json);
		}

		if (mapInfo) {
			this.mapInfo = mapInfo;
		}
	}

	/**
	 * Show or hide this vector item. This setting is persisted to the database and also alters the active status of the CLE toggle of this layer.
	 *
	 * @param visible Whether this vector item is visible or not
	 */
	setVisibility(visible: boolean) {
		this.mapInfo.prefs.sitePreferences.kmzItems[this.id] = visible;
		this.mapInfo.prefs.save();
		this.visible = visible;
	}
}

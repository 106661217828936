import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-global-message-box',
	templateUrl: './global-message-box.component.html',
	styleUrls: ['./global-message-box.component.scss'],
})
export class GlobalMessageBoxComponent implements OnInit {
	readonly icon_default = 'black';
	readonly icon_red = '#C70039';
	readonly icon_gold = '#FFC300';
	readonly icon_blue = '#2196F3';
	readonly icon_green = '#008658';

	@Input() buttons: RbEnums.Common.MessageBoxButtons = RbEnums.Common.MessageBoxButtons.Ok;
	@Input() icon: RbEnums.Common.MessageBoxIcon = RbEnums.Common.MessageBoxIcon.None;
	@Input() message: string;
	@Input() okYesCallback: Function;
	@Input() cancelNoCallback: Function;
	@Input() title: string = RbConstants.Common.AppName;
	@Input() infoText: string;
	@Input() okButtonName: string;

	@Output() okYesClick = new EventEmitter();
	@Output() cancelNoClick = new EventEmitter();

	MessageBoxButtons = RbEnums.Common.MessageBoxButtons;

	color = 'black';
	messageBoxIcon: string = null;

	constructor() {
	}

	ngOnInit() {
		if (!this.title) { this.title = RbConstants.Common.AppName; }

		this.setMessageBoxIcon();
	}

	onOkYesClick() {

		if (this.okYesCallback) {
			this.okYesCallback();
		}

		this.okYesClick.emit();
	}

	onNoClick() {
		if (this.cancelNoCallback) {
			this.cancelNoCallback();
		}

		this.cancelNoClick.emit();
	}

	setMessageBoxIcon() {
		switch (this.icon) {
			case RbEnums.Common.MessageBoxIcon.Asterisk:
				this.color = this.icon_default;
				this.messageBoxIcon = 'error_outline';
				break;
			case RbEnums.Common.MessageBoxIcon.Error:
				this.color = this.icon_red;
				this.messageBoxIcon = 'error_outline';
				break;
			case RbEnums.Common.MessageBoxIcon.Exclamation:
				this.color = this.icon_default;
				this.messageBoxIcon = 'error_outline';
				break;
			case RbEnums.Common.MessageBoxIcon.Information:
				this.color = this.icon_blue;
				this.messageBoxIcon = 'info_outline';
				break;
			case RbEnums.Common.MessageBoxIcon.Question:
				this.color = this.icon_default;
				this.messageBoxIcon = 'help_outline';
				break;
			case RbEnums.Common.MessageBoxIcon.Stop:
				this.color = this.icon_red;
				this.messageBoxIcon = 'waterConsumptionReport';
				break;
			case RbEnums.Common.MessageBoxIcon.Success:
				this.color = this.icon_green;
				this.messageBoxIcon = 'check_circle_outline';
				break;
			case RbEnums.Common.MessageBoxIcon.Warning:
				this.color = this.icon_gold;
				this.messageBoxIcon = 'warning_outline';
				break;
			default:
				this.messageBoxIcon = null;
		}
	}

}

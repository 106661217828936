import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, take } from 'rxjs/operators';

import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { CompanyManagerService } from '../../../api/companies/company-manager.service';
import { FormValidationService } from '../../../common/services/form-validation.service';
import { GroupLevel } from '../../../api/users/models/group-level.model';
import { LicenseManagerService } from '../../../api/license/license-manager.service';
import { MessageBoxInfo } from '../../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from '../../../common/services/message-box.service';
import { PinCodeManagerService } from '../../../api/pin-codes/pin-code-manager.service';
import { PinCodeUpdate } from '../../../api/pin-codes/models/pin-code-update.model';
import { RbccUser } from '../../../api/users/models/rbcc-user.model';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Site } from '../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { UserManagerService } from '../../../api/users/user-manager.service';
import { UserSite } from '../../../api/sites/models/user-site.model';

@Component({
	selector: 'rb-edit-user',
	templateUrl: './edit-user.component.html',
	styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'sidebar-component';

	@Output() complete = new EventEmitter<{ savedData: boolean }>();
	@Output() sidebarTitleChange = new EventEmitter<string>();
	@Output() waiting = new EventEmitter<{ waiting: boolean }>();

	@Input() canLoadData = true;
	@Input() selectedUserId: number;

	private _sidebarTitle = '';
	@Input() set sidebarTitle(value: string) {
		this._sidebarTitle = value;
		this.sidebarTitleChange.next(value);
	}

	get sidebarTitle(): string {
		return this._sidebarTitle;
	}

	creatingNew = false;
	editForm: FormGroup;
	groupLevels: GroupLevel[] = [];
	loadError: string;
	sites: Site[] = [];
	userSites: UserSite[] = [];
	pinCode: PinCodeUpdate;
	userSitesChanged = false;
	isCurrentUserOwner = false;
	pinCodeChanged = false;
	isGolfSite = false;
	isReadOnly = false;
	isCloud = false;


	// Validation messages
	confirmPasswordErrorMessage: string;
	emailErrorMessage: string;
	nameErrorMessage: string;
	PasswordErrorMessage: string;
	PasswordValidationMessages: [];

	RbConstants = RbConstants;
	RbEnums = RbEnums;

	private _busy = false;
	set busy(value: boolean) {
		this._busy = value;
		this.waiting.emit({ waiting: value });
	}

	get busy(): boolean { return this._busy; }

	private displayTimer: NodeJS.Timer;
	private user: RbccUser;
	private emailNotRequiredValidators = [
		this.formValidation.rbPatternValidation(RbConstants.Form.EMAIL_OPTIONAL.pattern),
		Validators.minLength(RbConstants.Form.EMAIL_OPTIONAL.minLength),
		Validators.maxLength(RbConstants.Form.EMAIL_OPTIONAL.maxLength)
	];

	private emailRequiredValidators = [
		Validators.required,
		this.formValidation.rbPatternValidation(RbConstants.Form.EMAIL.pattern),
		Validators.minLength(RbConstants.Form.EMAIL.minLength),
		Validators.maxLength(RbConstants.Form.EMAIL.maxLength)
	];

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private fb: FormBuilder,
				private formValidation: FormValidationService,
				private messageBoxService: MessageBoxService,
				private siteManager: SiteManagerService,
				private translate: TranslateService,
				private pinCodeManager: PinCodeManagerService,
				private userManager: UserManagerService,
				private companyManager: CompanyManagerService,
				private licenseManager: LicenseManagerService,
	) { }

	ngOnInit() {
		this.creatingNew = this.selectedUserId === 0;
		this.isReadOnly = this.authManager.isReadOnly;
		this.editForm = this.fb.group({
			name: [{ value: '', disabled: this.isReadOnly },
				[
					Validators.required,
					Validators.minLength(RbConstants.Form.USER_LENGTH_RANGE.min),
					Validators.maxLength(RbConstants.Form.USER_LENGTH_RANGE.max)
				]],
			email: [{ value: '', disabled: this.isReadOnly },
					this.isGolfSite ? this.emailRequiredValidators : this.emailNotRequiredValidators
				],
			Password: ['',
				[
					Validators.required,
					Validators.minLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.min),
					Validators.maxLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.max)
				]],
			confirmPassword: ['',
				[
					Validators.required,
					this.formValidation.rbEqualValidation('Password')
				]],
			pinCode: this.fb.group({}),
			groupLevel: [{ value: null, disabled: this.isReadOnly }, [Validators.required]],
			userSites: this.fb.group({}),
		});

		this.licenseManager.isCloud().pipe(take(1)).subscribe(isCloud => {
			this.isCloud = isCloud;
			if (this.isCloud) {
				this.editForm.get('Password').setValidators([Validators.required,
					Validators.minLength(RbConstants.Form.USER_PASSWORD_LENGTH_RANGE.min),
					Validators.pattern(RbConstants.Form.USER_PASSWORD_VALIDATION_REGEX.pattern),
					this.formValidation.rbPasswordValidation()
				]);
			}
		});
		

		this.formValidation.setupValidation(this, this.editForm, 'name',
		this.translate.instant('STRINGS.USER') + ' ' + this.translate.instant('STRINGS.NAME'));
		this.formValidation.setupValidation(this, this.editForm, 'email', this.translate.instant('STRINGS.EMAIL'));
		this.formValidation.setupValidation(this, this.editForm, 'Password', this.translate.instant('STRINGS.PASSWORD'));
		this.formValidation.setupValidation(this, this.editForm, 'confirmPassword', this.translate.instant('STRINGS.CONFIRM_PASSWORD'));

		this.busy = true;
		this.displayTimer = setInterval(() => {
			if (this.canLoadData) {
				clearInterval(this.displayTimer);
				this.loadData();
			}
		}, 100);

		// Current User is the currently logged in user, NOT the user being edited.
		this.isCurrentUserOwner = this.authManager.getUserProfile().groupLevel >= RbEnums.Common.GroupLevel.RootAdmin;
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		if (!this.isGolfSite) {
			this.editForm.get('groupLevel').valueChanges.subscribe(() => {
				this.updateEmailValidatorsBaseOnUserGroupLevel();
			});
		}

	}

	updateEmailValidatorsBaseOnUserGroupLevel() {
		const accountType = this.editForm.get('groupLevel').value;
		switch (accountType) {
			// make email as optional for field tech, user and site admin
			case RbEnums.Common.GroupLevel.FieldTech:
			case RbEnums.Common.GroupLevel.User:
			case RbEnums.Common.GroupLevel.PowerUser:
				this.editForm.get('email').setValidators(this.emailNotRequiredValidators);
				break;
			default:
				this.editForm.get('email').setValidators(this.emailRequiredValidators);
		}
		this.editForm.get('email').updateValueAndValidity();
	}

	ngOnDestroy() {
		clearInterval(this.displayTimer);
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get isOwner() {
		return this.user != null && this.user.groupLevel === RbEnums.Common.GroupLevel.RootAdmin;
	}

	get isUserSiteDisabled() {
		return this.authManager.getUserProfile().groupLevel === RbEnums.Common.GroupLevel.PowerUser &&
			this.user.groupLevel === RbEnums.Common.GroupLevel.PowerUser;
	}

	isUserSite(siteId: number) {
		return this.userSites.find(userSite => userSite.siteId === siteId) != null;
	}

	get isAllUserSitesSelected(): boolean {
		return this.userSites?.length === this.sites?.length;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onPasswordChange(event: {newPassword: string}) {
		const passwordField = this.editForm.get('Password');
		passwordField.setValue(event.newPassword);
		passwordField.markAsDirty();
	}

	onPinCodeChange(event: { newPinCode: number, id: number }) {
		this.pinCode = {
			id: event.id,
			pin: event.newPinCode,
		};
		this.pinCodeChanged = true;
		this.editForm.markAsDirty();
	}

	onCancel() {
		this.complete.emit({ savedData: false });
	}

	onSave() {
		const email = this.editForm.get('email').value;
		const accountType = this.editForm.get('groupLevel').value;
		if (!email && !(accountType === RbEnums.Common.GroupLevel.PowerUser ||
			accountType === RbEnums.Common.GroupLevel.FieldTech ||
			accountType === RbEnums.Common.GroupLevel.User
			)) {
			return;
		}
		if (!this.isGolfSite && !email) {
			this.messageBoxService.showMessageBox(
				new MessageBoxInfo(
					this.translate.instant('STRINGS.USER_EMAIL_EMPTY_WARN'), null,
					RbConstants.Common.AppName,
					() => {
						this.onSaveUser();
					},
					() => {
						// canceled
					},
					RbEnums.Common.MessageBoxButtons.YesNo));
		} else {
			this.onSaveUser();
		}
	}

	onSaveUser() {
		this.checkUniqueNameValidators().subscribe(success => {
			if (!success) return;
			const patchValues = RbUtils.Forms.getPatchValuesForFormGroup(this.editForm);
			if (patchValues == null && !this.userSitesChanged && !this.pinCodeChanged) return;
			this.busy = true;

			const sources: Observable<any>[] = [];
			if (this.creatingNew) {
				this.user.name = patchValues.name;
				this.user.email = patchValues.email;
				this.user.Password = patchValues.Password;
				this.user.groupLevel = patchValues.groupLevel;
				const createNewUser$ = this.isGolfSite
					? this.userManager.createNewUser(this.user, this.userSites)
					: this.userManager.createIQ4NewUser(this.user, this.userSites)
				sources.push(createNewUser$);
			} else {
				if (patchValues != null) {
					sources.push(this.userManager.updateUser(this.selectedUserId, patchValues));
				}
				if (this.userSitesChanged) {
					// sources.push(this.userManager.updateUser(this.selectedUserId, patchValues));
					// RB-14365: need to put siteId to pass the API validation
					if (this.userSites.length === 0) {
						this.userSites.push(new UserSite({ siteId: 0, userId: this.user.id }));
					}
					sources.push(this.siteManager.updateUserSites(this.userSites));
				}
				if (this.pinCodeChanged) {
					sources.push(this.pinCodeManager.updatePinCode(this.pinCode));
				}
			}

			forkJoin(sources)
				.subscribe(() => {
					this.busy = false;
					this.complete.emit({ savedData: true });
				}, error => {
					this.loadError = error.error || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
				});
		});
	}

	onActionErrorAcknowledged() {
		this.loadError = null;
		this.busy = false;
		if (this.user == null) {
			// Error while loading - cancel dialog
			this.onCancel();
		}
	}

	onUserSiteChanged(siteId: number, checked: boolean) {
		if (checked) {
			this.userSites.push(new UserSite({ siteId: siteId, userId: this.user.id }));
		} else {
			this.userSites = this.userSites.filter(userSite => userSite.siteId !== siteId);
		}
		this.userSitesChanged = true;
		this.editForm.markAsDirty();
	}

	selectAllUserSites(isChecked = false) {
		if (isChecked) {
			this.userSites = this.sites.map(s => new UserSite({ siteId: s.id, userId: this.user.id }))
		} else {
			this.userSites = [];
		}
		this.userSitesChanged = true;
		this.editForm.markAsDirty();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private loadData() {
		this.busy = true;

		const sources: Observable<any>[] = [
			this.getUser(),
			this.userManager.getGroupLevels().pipe(take(1)),
			this.siteManager.getVisibleSites().pipe(take(1)),
		];
		if (!this.creatingNew) {
			sources.push(this.siteManager.getUserSites(this.selectedUserId).pipe(take(1)));
		}

		forkJoin(sources)
			.subscribe(([user, groupLevels, sites, userSites, pinCode]) => {
				this.user = user;
				this.groupLevels = this.filterGroupLevels(groupLevels);
				this.sites = sites;
				this.userSites = userSites || [];
				this.sidebarTitle = this.creatingNew ? this.translate.instant('STRINGS.ADD_USER_UPPERCASE') : this.user.name;
				this.updateForm();
				this.busy = false;
			}, error => {
				this.loadError = error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
			});
	}

	private getUser(): Observable<RbccUser> {
		if (this.creatingNew) {
			// Get the company time zone and attach that as the user's time zone. This is more-sensible than choosing
			// PST or something randomly.
			return this.companyManager.getCompanyPreferences().pipe(
				take(1),
				map(pref => {
					return RbccUser.createDefault(this.authManager.getUserProfile().companyId, this.authManager.getUserProfile().cultureId,
						pref.timeZone);
				})
			);
		}

		return this.userManager.getUser(this.selectedUserId).pipe(take(1));
	}

	private updateForm() {
		this.editForm.patchValue({
			name: this.user.name,
			email: this.user.email,
			groupLevel: this.user.groupLevel,
		}, { emitEvent: false });
		if (this.isOwner) {
			this.editForm.get('name').disable();
			this.editForm.get('email').disable();
			this.editForm.get('groupLevel').disable();
		}
		this.editForm.markAsPristine();
		this.editForm.markAsUntouched();

		if (!this.creatingNew) {
			this.editForm.get('Password').clearValidators();
			this.editForm.get('Password').setErrors(null);
			this.editForm.get('confirmPassword').clearValidators();
			this.editForm.get('confirmPassword').setErrors(null);
		}
		this.updateEmailValidatorsBaseOnUserGroupLevel();
	}

	private checkUniqueNameValidators(): Observable<boolean> {
		const sources: Observable<any>[] = [
			this.formValidation.checkUniqueName(RbConstants.Form.UNIQUE_NAMES.user, this.editForm.get('name').value,
				{ itemId: this.user.id }, this.translate.instant('STRINGS.NAME')),
			this.formValidation.checkUniqueName(RbConstants.Form.UNIQUE_NAMES.email, this.editForm.get('email').value,
				{ itemId: this.user.id }, this.translate.instant('STRINGS.EMAIL'))
		];

		return forkJoin(sources)
			.pipe(map(results => {
				const msg = results.reduce((list, error) => (list.length > 0 ? list + '<br />' : '') + (error == null ? '' : error), '');
				if (msg.length > 0) this.messageBoxService.showMessageBox(new MessageBoxInfo(msg, RbEnums.Common.MessageBoxIcon.Error));
				return msg.length === 0;
			}));
	}

	/**
	 * Filter group level values, returning those groupLevels from the input which are less than
	 * or equal to the logged-in user's group level.
	 * @param groupLevels - incoming GroupLevel values to be filtered and sorted
	 * @returns GroupLevel[] subset of groupLevels where each is less than or equal to the current
	 * logged-in user's group level, sorted from most-privileged to least-privileged.
	 */
	private filterGroupLevels(groupLevels: GroupLevel[]): GroupLevel[] {
		if (groupLevels == null || this.authManager.getUserProfile().groupLevel == null) return [];

		const currentUserGroupLevel = this.authManager.getUserProfile().groupLevel;
		const includeOwner = this.isOwner;

		const filteredGroupLevels = groupLevels.filter(groupLevel => {
			return groupLevel.value <= currentUserGroupLevel && groupLevel.value !== RbEnums.Common.GroupLevel.Demo &&
				groupLevel.value !== RbEnums.Common.GroupLevel.ReadOnlyUser &&
				(includeOwner || groupLevel.value !== RbConstants.Form.ROOT_ADMIN_ID);
		});
		return filteredGroupLevels.sort((a, b) => b.value - a.value); // Descending
	}
	
	getActivedFormControlName() {
		return document.activeElement.getAttribute('formcontrolname')
	}
}

<div id="fab-dismiss" 
	*ngIf="fabTogglerState==='active'"
	(click)="hideItems()"
	class="fab-dismiss">
</div>

<div class="fab-container flex flex-column flex-column-reverse-ns items-center">
	<button mat-fab class="fab-toggler no-hover" (click)="onToggleFab()" data-qaid="fab-button-toggler">
		<i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i>
	</button>
	<div [@speedDialStagger]="buttons.length" class="flex flex-column flex-column-reverse-ns">
		<button *ngFor="let btn of buttons"
				mat-mini-fab
				class="rb-fab-speed-dial fab-button-line-break"
				attr.data-qaid="fab-button-{{btn | replaceWhitespace: '_'}}"
				(click)="onToggleFab(btn)">
			<span>{{btn}}</span>
		</button>
	</div>
</div>

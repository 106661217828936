import * as moment from 'moment';
import { RbUtils } from '../../../../common/utils/_rb.utils';

export class ReportFilterDateRange {

	endDate: Date = null;
	startDate: Date = null;

	constructor(startDate: string, endDate: string, useEndOfDayForEndDate = false) {
		if (startDate) { this.startDate = new Date(startDate); }
		if (endDate) {
			this.endDate = new Date(endDate);
			if (useEndOfDayForEndDate) this.endDate = moment(this.endDate).endOf('day').toDate();
		}
	}

	get startDateString(): string {
		return this.startDate == null ? '' : RbUtils.Conversion.convertDateToDateTimeParameterString(this.startDate);
	}

	get endDateString(): string {
		return this.endDate == null ? '' : RbUtils.Conversion.convertDateToDateTimeParameterString(this.endDate);
	}
}

import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CalendarEvent } from './models/calendar-event.model';
import { CalendarEventApiService } from './calendar-event-api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { SiteStatusChange } from '../signalR/site-status-change.model';

@Injectable({
    providedIn: 'root'
})
export class CalendarEventManagerService extends ServiceManagerBase {
    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private calendarEventApiService: CalendarEventApiService,
                protected broadcastService: BroadcastService) {

        super(broadcastService);
    }

    private _apiResults = new Map<string, CalendarEvent[]>();

    // =========================================================================================================================================================
    // Public Properties and Methods
    // =========================================================================================================================================================

    calendarEventListChanged = new Subject<CalendarEvent>();

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    protected clearCache() {
    }

    createCalendarEvent(updateData: any): Observable<CalendarEvent> {
        const addCalendarEvent = {
            description: updateData.name,
            siteId: updateData.siteId,
            startTime: updateData.startTime,
            endTime: updateData.endTime,
        };

        return this.calendarEventApiService.createCalendarEvent(addCalendarEvent);
    }

    /** ========================================================================================================================================================
     * Method will be called by the calendar component or who want to get calendar event.
     * This method will always return cached event list if it is available for request.
     * ====================================================================================================================================================== */
    getCalendarEvents(startDate: string, endDate: string, siteId: number): Observable<CalendarEvent[]> {
        const requestingUrlKey = this.getUniqueKeyForRequestUrl(startDate, endDate, siteId);

        if (this._apiResults.has(requestingUrlKey)) {
            return Observable.create(observer => {
                observer.next(this._apiResults.get(requestingUrlKey));
                observer.complete();
            });
        }

        return this.calendarEventApiService.getCalendarEvents(startDate, endDate, siteId).pipe(map(result => {
            this._apiResults.set(requestingUrlKey, result);
            return result;
        }));
    }

    deleteCalendarEvents(calendarEventIds: number[]): Observable<void> {
        return this.calendarEventApiService.deleteCalendarEvents(calendarEventIds);
    }

    UpdateCalendarEvent(programGroupIds: number[], calendarEvent: any): Observable<null> {
        return this.calendarEventApiService.updateCalendarEvents(programGroupIds, calendarEvent);
    }

    /** ========================================================================================================================================================
     * Method called by SignalR Hub when an event is changed or added
     * Use this method to update event list cached in memory for each request url
     * ====================================================================================================================================================== */

    onCalendarEventChange(changes: SiteStatusChange[]) {
        changes.forEach(c => {
            switch (c.changeType) {
                // remove deteted item from cache and notify subscribers
                case RbEnums.SignalR.SiteStatusChangeType.CalendarEventDeleted:
                    for (const [key, value] of this._apiResults.entries()) {
                        if (value.find(evt => evt.id === c.calendarEvent.id)) {
                            this._apiResults.set(key, value.filter(x => x.id !== c.calendarEvent.id));
                            this.calendarEventListChanged.next(c.calendarEvent);
                            break;
                        }
                    }
                    break;
                // update event in cache and notify subscribers
                case RbEnums.SignalR.SiteStatusChangeType.CalendarEventUpdated:
                    for (const [key, value] of this._apiResults.entries()) {
                        const requestInfo = this.getRequestInfoFromKey(key);
                        const canUpdate = requestInfo.siteId === c.siteId
                            && moment(requestInfo.startDate).toDate() <= moment(c.calendarEvent.endTime).toDate()
                            && moment(requestInfo.endDate).toDate() >= moment(c.calendarEvent.endTime).toDate();

                        const localEvent = value.find(evt => evt.id === c.calendarEvent.id);
                        if (localEvent) {
                            const events = value.filter(x => x.id !== c.calendarEvent.id);
                            if (canUpdate) {
                                events.push(c.calendarEvent);
                            }
                            this._apiResults.set(key, events);
                            this.calendarEventListChanged.next(localEvent);
                            if (canUpdate) {
                                break;
                            }
                            // date of event are changed.
                        } else if (canUpdate) {
                            value.push(c.calendarEvent);
                            this._apiResults.set(key, value);
                            this.calendarEventListChanged.next(c.calendarEvent);
                            break;
                        }
                    }
                    break;
                // add event into cache if there is a request matched condition for new added event.
                case RbEnums.SignalR.SiteStatusChangeType.CalendarEventAdded:
                    for (const [key, value] of this._apiResults.entries()) {
                        const requestInfo = this.getRequestInfoFromKey(key);
                        if (requestInfo.siteId === c.siteId
                            && !value.find(evt => evt.id === c.calendarEvent.id)
                            && moment(requestInfo.startDate).toDate() <= moment(c.calendarEvent.endTime).toDate()
                            && moment(requestInfo.endDate).toDate() >= moment(c.calendarEvent.endTime).toDate()
                        ) {
                            this._apiResults.get(key).push(c.calendarEvent);
                            this.calendarEventListChanged.next(c.calendarEvent);
                            break;
                        }
                    }
                    break;
            }
        });
    }

    private getUniqueKeyForRequestUrl(startDate: string, endDate: string, siteId: number) {
        return JSON.stringify({ startDate, endDate, siteId });
    }

    private getRequestInfoFromKey(key: string) {
        return JSON.parse(key);
    }
}

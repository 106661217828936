<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="snackBar white">
	<ul class="ma0 pa0 list">
		<li
			*ngFor="let prompt of notifications"
			[@slideInFadeOut]="'in'"
			class="flex justify-between items-center pa3 w-100 absolute bottom-0"
		>
			<div class="message">{{ prompt.message | translate }}</div>
			<div class="action">
				<rb-icon-button
					label="Dismiss Notification"
					color="white"
					icon="close"
					(iconButtonClicked)="dismissPrompt(prompt.id)"
				>
				</rb-icon-button>
			</div>
		</li>
	</ul>
</div>

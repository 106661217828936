import { DryRunFlowDatum } from './dry-run-flow-datum.model';
import { DryRunProgramDatum } from './dry-run-program-datum.model';
import { DryRunRunningProgramsDict } from './dry-run-running-programs-dict.model';

export class DryRunResult {

	constructor(json: any | DryRunResult = null) {
		if (json) {
			Object.assign(this, json);

			if (json.flowData) this.flowData = json.flowData.map(datum => new DryRunFlowDatum(datum));
			if (json.runningPrograms) {
				this.runningPrograms = new DryRunRunningProgramsDict();
				for (const key of Object.keys(json.runningPrograms)) {
					this.runningPrograms[key] = json.runningPrograms[key].map(datum => new DryRunProgramDatum(datum));
				}
			}
			if (json.runningProgramGroups) {
				this.runningProgramGroups = new DryRunRunningProgramsDict();
				for (const key of Object.keys(json.runningProgramGroups)) {
					this.runningProgramGroups[key] = json.runningProgramGroups[key].map(datum => new DryRunProgramDatum(datum));
				}
			}
		}
	}

	flowData: DryRunFlowDatum[];
	runningPrograms: DryRunRunningProgramsDict;					// { [key: string]: DryRunProgramDatum[] } ;
	runningProgramGroups: DryRunRunningProgramsDict;
	totalFlow: number;

	dryRunStart: Date;
	dryRunEnd: Date;
	dryRunId: number;
	dryRunUUID: string;
	companyId: number;
	satelliteId: number;
}

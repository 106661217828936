import { CachedObject } from './cached-object';

export class CachedValue<T> extends CachedObject {

	constructor(v: T, cacheDurationSeconds: number = 300) {
		super(cacheDurationSeconds);

		this.value = v;
	}

	value: T;
}

export class ICIDiagnosticLogSummaryData {

	iciName: string;
	wirepath: number;

	/**
	 * Sent from the API as a decimal?. When null, either there is no data yet or the ICI is in demo mode. Show a -
	 * in the UI.
	 */
	currentmA: number;

	icmCount: number;
	icmFailureCount: number;
	icmSuspendedCount: number;
	icmPassed: number;
	icmNoFeedbackCount: number;
	isDemoMode: boolean;
	satelliteId: number;
	status: string;
	isWaiting = false;
	faultFindingON = false;
	private _timeRemainingForStatusChange: number;
	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	// private startCountdown() {
	// 	if (this._timerRef) {
	// 		clearInterval(this._timerRef);
	// 	}
	// 	this._timerRef = setInterval(() => {
	// 		if (this._timeRemainingForStatusChange - 1 >= 0) {
	// 			--this._timeRemainingForStatusChange;
	// 		} else {
	// 			clearInterval(this._timerRef);
	// 		}
	// 	}, 1000);
	// }

	set timeRemainingForStatusChange(data: number) {
		this._timeRemainingForStatusChange = data;
		// Temporarily startCountdown was removed, _timeRemainingForStatusChange is only updated
		// based on signalR
		// this.startCountdown();
	}

	get timeRemainingForStatusChange(): number {
		return this._timeRemainingForStatusChange;
	}
}

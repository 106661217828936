import { AppInjector } from '../../../core/core.module';
import { Company } from '../../address-book/models/company.model';
import { CultureSettings } from '../../culture-settings/models/culture-settings.model';
import { GroupLevel } from './group-level.model';
import { PinCode } from '../../pin-codes/models/pin-code.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { UserManagerService } from '../user-manager.service';

export class UserListItem {
	private userManager: UserManagerService;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.company) { this.company = new Company(json.company); }
		}

		this.userManager = AppInjector.get(UserManagerService);
	}

	ChallengeAnswer: string;
	challengeQuestion: RbEnums.Common.ChallengeQuestion;
	company: Company;
	companyId: number;
	cultureSettings: CultureSettings;
	cultureSettingsId: number;
	email: string;
	groupLevel: number;
	id: number;
	isEmailConfirmed = false;
	isImportInProgress = false;
	mobileNumber: string;
	name: string;
	Password: string;					// NOTE: THIS IS NOT A TYPO! Password is capitalized.
	pincode: PinCode[] = [];
	timeZone: string;
	workNumber: string;

	get groupLevelItem(): GroupLevel {
		const groupLevel = this.userManager.getGroupLevel(this.groupLevel);
		if (!groupLevel) return;

		return groupLevel;
	}

}

import { AfterContentInit, ContentChild, Directive, ElementRef} from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { DeviceManagerService } from '../../common/services/device-manager.service';

@Directive({
	selector: '[rbScrollRouterLinkIntoView]'
})
export class ScrollRouterLinkIntoViewDirective implements AfterContentInit {

	// @ContentChild(RouterLinkActive, /* TODO: add static flag */ {}) private link: RouterLinkActive;
	@ContentChild(RouterLinkActive, {static: true}) private link: RouterLinkActive;

	constructor(private deviceManager: DeviceManagerService,
				private elementRef: ElementRef,
				private router: Router) {
	}

	ngAfterContentInit(): void {
		this.scrollIntoView();
	}

	private scrollIntoView() {
		if (!this.deviceManager.isMobile) return;

		if (!this.link || !this.link.links || !this.link.linksWithHrefs || !this.router.navigated)
			return;

		if ((this.link as any).hasActiveLinks()) {
			setTimeout(() => this.elementRef.nativeElement.scrollIntoView());
		}
	}

}

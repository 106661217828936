import { ApiService, HttpMethod } from '../_common/api.service';
import { CalendarEvent } from './models/calendar-event.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CalendarEventApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	createCalendarEvent(calendarEventData: any): Observable<null> {
		return this.apiRequest<any>(this.createCalendarEventUrl, HttpMethod.Post, calendarEventData);
	}

	getCalendarEvents(startDate: string, endDate: string, siteId: number): Observable<CalendarEvent[]> {
		return this.apiRequest<any>(this.getCalendarEventsUrl(startDate, endDate, siteId), HttpMethod.Get)
			.pipe(map(list => list.map(item => new CalendarEvent(item))));
	}

	deleteCalendarEvents(ids: number[]): Observable<void> {
		return this.apiRequest<any>(this.deleteCalendarEventsUrl, HttpMethod.Delete, ids);
	}

	updateCalendarEvents(calendarEventIds: number[], calendarEventUpdate: object): Observable<null> {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch,
			{ ids: calendarEventIds, patch: this.patchTransform(calendarEventUpdate) });
	}
	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}CalendarEvent`; }

	private get createCalendarEventUrl(): string { return `${this.baseUrl}/CreateCalendarEvent`; }

	private get deleteCalendarEventsUrl(): string { return `${this.baseUrl}/DeleteCalendarEvents`; }

	private getCalendarEventsUrl(startDate: string, endDate: string, siteId: number): string {
		return `${this.baseUrl}/GetCalendarEvents?startTime=${startDate}&endTime=${endDate}&siteId=${siteId}`;
	}

	private get updateBatchesUrl(): string { return `${this.baseUrl}/UpdateBatches`; }
}

import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { Component } from '@angular/core';
import { EditableGridUtil } from '../_utils/editable-grid.util';
import { NumericCellEditorBase } from '../_numeric-cell-editor-base';
import { RbConstants } from '../../../../../common/constants/_rb.constants';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-station-flow-rate-cell-editor',
	templateUrl: './station-flow-rate-cell-editor.component.html',
	styleUrls: ['./station-flow-rate-cell-editor.component.scss']
})
export class StationFlowRateCellEditorComponent extends NumericCellEditorBase {

	value: string;
	valueRange: { min: number, max: number };
	gpmValueRange: { min: number, max: number };
	isUniversalController = false;
	unitsString = '';

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected authManager: AuthManagerService,
				protected broadcastService: BroadcastService,
				protected translateService: TranslateService,
	) {
		super(authManager, broadcastService, translateService);
	}

	// =========================================================================================================================================================
	// agGrid Methods
	// =========================================================================================================================================================

	// Get the value from the grid.
	agInit(params: any): void {
		this.maxDecimalPlaces = this.maxDecimalPlaces = params.colDef.isGolf ? RbConstants.Form.FLOWRATE_PRECISION.golf :
			RbConstants.Form.FLOWRATE_PRECISION.commercial;

		super.agInit(params);

		// If the cellEditorParams is null or does not contain the controller object, throw error and abort.
		if (!this.params) { throw new Error('Missing CellEditorParamS'); }
		this.isUniversalController = RbUtils.Common.isUniversalController(this.params.data.parentSatelliteType);

		this.gpmValueRange = {
			min: params.colDef.isGolf
			? RbConstants.Form.FLOWRATE_RANGE_GOLF.min : RbConstants.Form.FLOWRATE_RANGE_COMMERCIAL.min,
			max: this.isUniversalController ? RbConstants.Form.FLOWRATE_RANGE_COMMERCIAL.universalMax : RbConstants.Form.FLOWRATE_RANGE_GOLF.max,
		};

		// RB-9889 Flow Rate value goes with round to 3 decimal places
		this.valueRange = {
			min: this.convertToUserFlow(this.gpmValueRange.min, 1),
			max: this.convertToUserFlow(this.gpmValueRange.max, params.colDef.isGolf
				? RbConstants.Form.FLOWRATE_PRECISION.golf : RbConstants.Form.FLOWRATE_PRECISION.commercial)
		};

		const userFlowRate = this.convertToUserFlow(this.params.value).toFixed(params.colDef.isGolf
			? RbConstants.Form.FLOWRATE_PRECISION.golf : RbConstants.Form.FLOWRATE_PRECISION.commercial);
		this.value = this.numberRegEx.test(this.params.charPress) ? this.params.charPress : userFlowRate;
		this.setUnitsString();
	}

	// Override base class method
	getValue() {
		// Clean input to a string that can be converted into a number.
		const cleanValue = this.textInput.nativeElement.value.replace(',', '.');

		// Ensure converted value is within the allowable gpm range. The value can be outside the range at the range boundaries.
		let gpm = EditableGridUtil.convertFlowFrom(cleanValue, this.authManager.userCulture.unitType, this.params.data.sprinklerCategory);
		if (gpm < this.gpmValueRange.min) {
			gpm = this.gpmValueRange.min;
		} else if (gpm > this.gpmValueRange.max) {
			gpm = this.gpmValueRange.max;
		}

		return gpm;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onKeyUp(event) {
		if (!this.valueRange) { return; }

		// Validate address range for given controller type.
		if (!this.validateFlowRateRange()) {
			const message = this.translateService.instant('VALIDATION.RANGE_INVALID', {
					name: this.colDef.headerName,
					min: this.valueRange.min || 0,
					max: this.valueRange.max || Number.MAX_SAFE_INTEGER
				}
			);
			this.showInvalidFieldDialog(message);
			this.textInput.nativeElement.select();
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private validateFlowRateRange(): boolean {
		const newValue = this.textInput.nativeElement.value;

		if (newValue < this.valueRange.min) {
			this.textInput.nativeElement.value = this.valueRange.min;
			return false;
		}

		if (newValue > this.valueRange.max) {
			this.textInput.nativeElement.value = this.valueRange.max;
			return false;
		}

		return true;
	}

	private convertToUserFlow(flowRateInGpm: number, precision?: number): number {
		return EditableGridUtil.convertFlowTo(flowRateInGpm, this.authManager.userCulture.unitType, this.params.data.sprinklerCategory, precision);
	}

	private setUnitsString() {
		this.unitsString = EditableGridUtil.getFlowRateUnits(this.authManager.userCulture.unitType, this.params.data.sprinklerCategory);
	}
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationService } from '../../../../../common/services/form-validation.service';
import { LicenseManagerService } from '../../../../../api/license/license-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxService } from '../../../../../common/services/message-box.service';
import { PasswordChange } from '../../../../../api/users/models/password-change.model';
import { RbConstants } from '../../../../../common/constants/_rb.constants';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserManagerService } from '../../../../../api/users/user-manager.service';

@Component({
	selector: 'rb-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
	@ViewChild('updatePassword', { static: true }) updatePassword;

	@Output() passwordChange = new EventEmitter<{newPassword: string}>();

	// Set this flag when this component is being used in scenarios where the current user is changing the password of another user.
	@Input() isChangingNonCurrentUserPassword = false;
	@Input() changeButtonText = 'STRINGS.CHANGE';

	editForm: FormGroup;

	// Validation messages
	confirmPasswordErrorMessage: string;
	currentPasswordErrorMessage: string;
	PasswordErrorMessage: string;
	PasswordValidationMessages: [];

	RbConstants = RbConstants;
	PasswordRequired = true;
	isCloud = false;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(public dialog: MatDialog,
				private fb: FormBuilder,
				private formValidation: FormValidationService,
				private licenseManager: LicenseManagerService,
				private messageBoxService: MessageBoxService,
				private translate: TranslateService,
				private userManager: UserManagerService,
	) {}

	ngOnInit() {
		this.editForm = this.fb.group({
			currentPassword: [ '',
				[
					Validators.minLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.min),
					Validators.maxLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.max)
				] ],
			Password: [ '',
				[
					Validators.minLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.min),
					Validators.maxLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.max)
				] ],
			confirmPassword: [ '',
				[
					Validators.minLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.min),
					Validators.maxLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.max),
					this.formValidation.rbEqualValidation('Password')
				] ],
		});

		this.formValidation.setupValidation(this, this.editForm, 'currentPassword', this.translate.instant('STRINGS.CURRENT_PASSWORD'));
		this.formValidation.setupValidation(this, this.editForm, 'Password', this.translate.instant('STRINGS.NEW_PASSWORD'));
		this.formValidation.setupValidation(this, this.editForm, 'confirmPassword', this.translate.instant('STRINGS.CONFIRM_PASSWORD'));

		// On creation, ask the license manager if we are NOT running in the cloud. If not, empty passwords are allowed.
		this.licenseManager.isCloud().pipe(take(1)).subscribe(isCloud => {
			this.isCloud = isCloud;
			this.PasswordRequired = isCloud;
			if (this.PasswordRequired) this.updatePasswordValidators();
		});
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	handleChangePassword() {
		this.onCancel();
		this.dialog.open(this.updatePassword);
	}

	onSubmit() {
		const passwordChange: PasswordChange = {
			currentPassword: this.editForm.get('currentPassword').value,
			newPassword: this.editForm.get('Password').value,
		};

		// If this is a scenario where we are changing the password of another, don't update here, but rather pass back the new password to the caller.
		if (this.isChangingNonCurrentUserPassword) {
			this.passwordChange.emit({newPassword: passwordChange.newPassword});
			return;
		}

		this.userManager.updatePassword(passwordChange)
			.subscribe(() => {
				this.messageBoxService.showMessageBox(this.translate.instant('STRINGS.CHANGE_PASSWORD_SUCCESSFULLY'));
			}, error => {
				// this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED');
				this.messageBoxService.showMessageBox(error.error);
			});
	}

	onCancel() {
		this.editForm.get('currentPassword').setValue(null);
		this.editForm.get('Password').setValue(null);
		this.editForm.get('confirmPassword').setValue(null);
		this.editForm.markAsPristine();
		this.editForm.markAsUntouched();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private updatePasswordValidators() {
		if (!this.isChangingNonCurrentUserPassword) {
			this.editForm.get('currentPassword').setValidators([Validators.required,
				Validators.minLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.min),
				Validators.maxLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.max)
			]);
		}

		this.editForm.get('Password').setValidators([Validators.required,
			Validators.minLength(RbConstants.Form.USER_PASSWORD_LENGTH_RANGE.min),
			Validators.pattern(RbConstants.Form.USER_PASSWORD_VALIDATION_REGEX.pattern),
			this.formValidation.rbPasswordValidation()
		]);
		this.editForm.get('confirmPassword').setValidators([Validators.required,
			Validators.minLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.min),
			Validators.maxLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.max),
			this.formValidation.rbEqualValidation('Password')
		]);
	}

	getActivedFormControlName() {
		return document.activeElement.getAttribute('formcontrolname')
	}
}

// import * as JSZip from 'jszip';
// import * as JSZipType from 'jszip';
import JSZip from 'jszip';
// eslint-disable-next-line no-duplicate-imports
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ZipService {

	// Get the content
	public getZipContent(file: File): Promise<JSZip> {
		const jszip = new JSZip();
		return jszip.loadAsync(file)
	}

	// Get the actual blobs from the zip
	public unzip(file: File) {
		const jszip = new JSZip();
		return jszip.loadAsync(file).then(async (zip) => {
			const blobs = []
			for (const filename of Object.keys(zip.files)) {
				const blobFile = await zip.files[filename].async('blob')
				const file = new File([blobFile], filename);
				blobs.push(file)
			}
			return blobs;
		});
	}
	
}

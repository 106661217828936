<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="flex justify-between">
	<div>{{getTimeCellRenderer()}}</div>
</div>
<div class="flex justify-between">
	<div>{{getWeekDaysRenderer()}}</div>
</div>
<div class="flex justify-between name-container">
	<div [innerHTML]="controllerNamesRenderer"></div>
</div>


<div class="nearby-station-select-container">
  <div class="slider-container">
    <mat-slider
      min="0" 
      [max]="maxRadius" 
      [step]="step"
      [value]="radius"
      (input)="onRadiusChange($event.value)"
    ></mat-slider>
    <!-- <mat-hint><strong>{{ radius }}</strong> meters</mat-hint> -->
  </div>
  <div class="footer">
    <div class="actions-container">
      <button mat-button (click)="onClose()">Cancel</button>
      <button mat-button mat-flat-button color="primary" (click)="onAccept()">Accept</button>
    </div>
  </div>
</div>
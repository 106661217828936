import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-chart-time-traveler',
	templateUrl: './chart-time-traveler.component.html',
	styleUrls: ['./chart-time-traveler.component.scss']
})
export class ChartTimeTravelerComponent implements OnInit {

	@Output() timeTravel = new EventEmitter<{ amount: number, unit: RbEnums.Common.TimeTravelUnit }>();

	@Input() currentDateString = '-';
	@Input() currentTimeString = '-';

	TimeTravelUnit = RbEnums.Common.TimeTravelUnit;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor() { }

	ngOnInit() {
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onTimeTravel(amount: number, unit: RbEnums.Common.TimeTravelUnit) {
		this.timeTravel.emit({ amount: amount, unit: unit });
	}
}

import { ScheduledReport } from './scheduled-report.model';

export class ScheduledReports {

	items: ScheduledReport[] = [];

	constructor(json: any = null) {
		if (json) {
			this.items = json.map((item: any) => new ScheduledReport(item));
		}
	}
}

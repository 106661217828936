import * as moment from 'moment';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import ChangeLogAction = RbEnums.Common.ChangeLogAction;

export class SystemChangeLogEntry {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.sysStartTime) { this.sysStartTime = moment.utc(json.sysStartTime).toDate(); }
			if (json.sysEndTime) { this.sysEndTime = moment.utc(json.sysEndTime).toDate(); }
			if (json.when) { this.when = moment.utc(json.when).toDate(); }
		}
	}

	siteId: number;
	action: string;
	tableName: string;
	columnName: string;
	description: string;
	oldValue: any;
	newValue: any;
	baseType: string;
	lastEditUserId: number;
	lastEditUserName: string;
	sysStartTime: Date;
	sysEndTime: Date;
	when: Date;
	pK1Name: string;
	pK1: number;
	pK2Name: string;
	pK2: number;
	pK3Name: string;
	pK3: number;
	pK4Name: string;
	pK4: number;

	// =========================================================================================================================================================
	// Public Display Properties - For beautifying grid display
	// =========================================================================================================================================================

	private _whatChanged: string;
	get whatChanged(): string {
		return this._whatChanged;
	}

	set whatChanged(value: string) {
		this._whatChanged = value;
	}

	private _convertedOldValue: string;
	get convertedOldValue(): string {
		return this._convertedOldValue;
	}

	set convertedOldValue(value: string) {
		this._convertedOldValue = value;
	}

	private _convertedNewValue: string;
	get convertedNewValue(): string {
		return this._convertedNewValue
;	}

	set convertedNewValue(value: string) {
		this._convertedNewValue = value;
	}

	private _formattedWhen: string;
	get formattedWhen(): string {
		return this._formattedWhen;
	}

	set formattedWhen(value: string) {
		this._formattedWhen = value;
	}

	get actionType(): ChangeLogAction {
		switch (this.action) {
			case 'Modified':
				return ChangeLogAction.Modified;
			case 'Added':
				return ChangeLogAction.Added;
			case 'Removed':
				return ChangeLogAction.Removed;
			case 'Moved':
				return ChangeLogAction.Moved;
			default:
				return ChangeLogAction.Unknown;
		}
	}

	get actionLabel(): string {
		let label = this.action;

		switch (this.actionType) {
			case ChangeLogAction.Modified:
				label = 'STRINGS.MODIFIED';
				break;
			case ChangeLogAction.Added:
				label = 'STRINGS.ADDED';
				break;
			case ChangeLogAction.Removed:
				label = 'STRINGS.REMOVED';
				break;
			case ChangeLogAction.Moved:
				label = 'STRINGS.MOVED';
				break;
			case ChangeLogAction.Unknown:
			default:
				break;
		}

		return label;
	}

	get actionIconClass(): string {
		switch (this.actionType) {
			case ChangeLogAction.Modified:
				return 'mdi mdi-pencil-outline';
			case ChangeLogAction.Added:
				return 'mdi mdi-plus-circle-outline';
			case ChangeLogAction.Removed:
				return 'mdi mdi-trash-can-outline';
			case ChangeLogAction.Moved:
				return 'mdi mdi-folder-move';
			case ChangeLogAction.Unknown:
			default:
				return 'mdi mdi-help-circle-outline';
		}
	}
}

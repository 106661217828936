import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

/**
 * Directive that activates the text scroll animation loop when the child element exceeds 
 * a defined minimum width
 * 
 * It works by adding/removing the `.scroll-text-loop` CSS class to the host element
 * 
 * ### Usage
 * 
 * This element should contain only one built in HTML element with the text to display
 * 
 * ```HTML
 * <div [rbScrollText]="100">
 * 	<span>{{text}}</span>
 * </div>
 * ```
 */
@Directive({
	selector: '[rbScrollText]'
})
export class ScrollTextDirective implements OnInit, OnDestroy {

	/** 
	 * Min width that will trigger the addition or removal of the `.scroll-text` CSS class 
	 * 
	 * Exposed with same name as the directive's selector to avoid needing 2 directives
	 * (1 for applying this directive and the second one to set the min width property)
	 */
	@Input('rbScrollText') minWidth: number;

	/** 
	 * Binds to the host's class attribute. 
	 * 
	 * Any text assigned to this variable is set as classes
	 */
	@HostBinding('class') classes = '';


	private textContainer: Element;
	private observer: ResizeObserver;


	constructor(private el: ElementRef<HTMLElement>) { }

	ngOnInit(): void {
		this.textContainer = this.el.nativeElement.firstElementChild;
		this.observer = new ResizeObserver(this.checkWidth)
		this.observer.observe(this.textContainer);
	}

	ngOnDestroy(): void {
		this.observer.disconnect();
	}

	/**
	 * Function that runs every time the observer object detects a change in size of the
	 * observed element
	 */
	private checkWidth = () => {
		if (this.textContainer && !isNaN(this.minWidth) && this.textContainer.clientWidth >= this.minWidth) {
			this.classes = 'scroll-text-loop';
		} else {
			this.classes = '';
		}
	}


}

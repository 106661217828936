import * as moment from 'moment';

import { Component, ElementRef, ViewChild } from '@angular/core';

import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { CellEditorBase } from '../_cell-editor-base';
import { CycleSoakRuntime } from '../_models/rb-cell-editor-params.interface';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { TranslateService } from '@ngx-translate/core';

enum Fields {
	Hours,
	Minutes,
	Seconds
}

@Component({
	selector: 'rb-cycle-soak-cell-editor',
	templateUrl: './cycle-soak-cell-editor.component.html',
	styleUrls: ['./cycle-soak-cell-editor.component.scss']
})
export class CycleSoakCellEditorComponent extends CellEditorBase implements ICellEditorAngularComp {
	@ViewChild('hours') tiHours;
	@ViewChild('minutes') tiMinutes;
	@ViewChild('seconds') tiSeconds;

	Fields = Fields;

	value: number;
	numberRegEx: RegExp;
	maxHours: number;
	maxLengthHours = 2;
	minSeconds = 0;
	activeElement: ElementRef;
	internalControls: ElementRef[];
	activeControlIndex: number;
	cycleSoakRuntimeOption: CycleSoakRuntime = CycleSoakRuntime.Cycle;
	durationHours = 0;
	durationMinutes = 0;
	durationSeconds = 0;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected broadcastService: BroadcastService,
				protected translateService: TranslateService,
	) {
		super(broadcastService, translateService);
	}

	// =========================================================================================================================================================
	// agGrid Methods
	// =========================================================================================================================================================

	// Get the value from the grid.
	agInit(params: any): void {
		super.agInit(params);

		// If the cellEditorParams is null or does not contain required values, throw error and abort.
		if (!this.params || this.params.maxValue == null) { throw new Error('Missing \'maxValue\' CellEditorParam'); }
		if (this.params.cycleSoakRuntime == null) { throw new Error('Missing \'cycleSoakRuntime\' CellEditorParam'); }

		// Configure editor, set constraints and get initial value
		this.maxHours = this.params.maxValue;
		this.maxLengthHours = this.maxHours.toString().length;
		this.minSeconds = this.params.minValue != null ? this.params.minValue : 0;	// RB-9964: Set min seconds to zero if unspecified
		this.cycleSoakRuntimeOption = this.params.cycleSoakRuntime;
		switch (this.cycleSoakRuntimeOption) {
			default:
			case CycleSoakRuntime.Cycle:
				this.value = params.data.cycleTimeLong;
				break;
			case CycleSoakRuntime.Soak:
				this.value = params.data.soakTimeLong;
				break;
			case CycleSoakRuntime.Runtime:
				this.value = params.data.defaultRunTimeLong;
				break;
		}

		// Set RegEx for input validation.
		this.numberRegEx = new RegExp('^\\d*$');

		// Set the initial editor input field values.
		if (this.numberRegEx.test(this.params.charPress)) {
			this.durationMinutes = +this.params.charPress;
		} else {
			const duration = RbUtils.Conversion.convertTicksToDuration(this.value);
			this.durationHours = duration.hours();
			this.durationMinutes = duration.minutes();
			this.durationSeconds = duration.seconds();
		}
	}

	afterGuiAttached(params?: IAfterGuiAttachedParams) {
		// Set control array for easy navigation traversal.
		this.internalControls = [this.tiHours, this.tiMinutes, this.tiSeconds];

		// Set initial control focus to the minutes field.
		this.activeControlIndex = 1;
		this.tiMinutes.nativeElement.focus();
		if (!this.params.charPress) { setTimeout(() => this.tiMinutes.nativeElement.select()); }
	}

	// Return the value from this component to the grid.
	getValue() {
		let duration = moment.duration({
			hours: +this.tiHours.nativeElement.value,
			minutes: +this.tiMinutes.nativeElement.value,
			seconds: +this.tiSeconds.nativeElement.value,
		});

		// Handle minSeconds checking. Anything less than the minimum returns the minimum, unless the value is zero.
		const seconds = duration.asSeconds();
		if (seconds > 0 && seconds < this.minSeconds) {
			duration = moment.duration({
				seconds: this.minSeconds
			});
		}

		return RbUtils.Conversion.convertDurationToTicks(duration);
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onFocus(event, field: Fields) {
		this.activeControlIndex = field === Fields.Hours ? 0 : field === Fields.Minutes ? 1 : 2;
		this.activeElement = this.internalControls[this.activeControlIndex];
	}

	onKeyPress(event) {
		// Disallow any invalid key presses.
		if (!this.numberRegEx.test(this.getPotentialValueString(event.key))) {
			event.preventDefault();
		}
	}

	onKeyDown(event) {
		if (this.isNavigationKey(event.key)) {
			event.stopPropagation();

			// Handle internal control navigation.
			if (this.isInternalNavigation(event)) {
				return;
			}

			// Handle cell editor validation and grid navigation.
			if (!this.hasFieldValidation) {
				this.navigateOnKeyDown(event.key, event.shiftKey);
			} else {
				this.validateAndNavigate(this.activeElement.nativeElement.value, event.key, event.shiftKey);
			}
		}
	}

	onKeyUp(event) {
		this.validateField();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getPotentialValueString(key: string) {
		const ctl = this.tiMinutes.nativeElement;
		const chars = ctl.value.split('');
		chars.splice(ctl.selectionStart, ctl.selectionEnd - ctl.selectionStart, key);
		return chars.join('');
	}

	// Method to handle field constraints.
	private validateField() {
		if (this.activeElement.nativeElement.value < 0) {
			this.activeElement.nativeElement.value = 0;
		}

		if (this.activeElement === this.tiHours) {
			if (this.tiHours.nativeElement.value > this.maxHours) { this.tiHours.nativeElement.value = this.maxHours; }
			if (this.tiHours.nativeElement.value >= this.maxHours) {
				this.tiMinutes.nativeElement.value = '00';
				this.tiSeconds.nativeElement.value = '00';
				// this.showMaxValueExceededDialog();
			}
		} else if (this.activeElement === this.tiMinutes) {
			if (this.tiMinutes.nativeElement.value > 59) { this.tiMinutes.nativeElement.value = 59; }
			if (this.tiHours.nativeElement.value >= this.maxHours && this.tiMinutes.nativeElement.value > 0) {
				this.tiHours.nativeElement.value = '0';
				// this.showMaxValueExceededDialog();
			}
		} else {
			if (this.tiSeconds.nativeElement.value > 59) { this.tiSeconds.nativeElement.value = 59; }
			if (this.tiHours.nativeElement.value >= this.maxHours && this.tiSeconds.nativeElement.value > 0) {
				this.tiHours.nativeElement.value = '0';
				// this.showMaxValueExceededDialog();
			}
		}
	}

	// private showMaxValueExceededDialog() {
	// 	let hoursLabel = this.maxHours === 1 ? 'STRINGS.HOUR' : 'STRINGS.HOURS';
	// 	hoursLabel = this.translateService.instant(hoursLabel);
	//
	// 	const message = this.translateService.instant('VALIDATION.MAX', {
	// 			name: this.colDef.headerName,
	// 			length: `${this.maxHours} ${hoursLabel}`
	// 		}
	// 	);
	// 	this.showInvalidFieldDialog(message);
	// 	this.activeElement.nativeElement.select();
	// }

	// Method to determine if and handle internal control navigation.
	private isInternalNavigation(event): boolean {
		// Handle internal navigation between input fields.
		if (event.key === 'Tab') {
			if (!event.shiftKey) {
				if (this.activeControlIndex < Fields.Seconds) {
					this.setFocusAndSelection(++this.activeControlIndex);
					return true;
				}
			} else {
				if (this.activeControlIndex > Fields.Hours) {
					this.setFocusAndSelection(--this.activeControlIndex);
					return true;
				}
			}
		} else if (event.key === 'ArrowUp') {
			this.activeElement.nativeElement.value++;
			this.validateField();
			return true;
		} else if (event.key === 'ArrowDown') {
			this.activeElement.nativeElement.value--;
			this.validateField();
			return true;
		}

		return false;
	}

	private setFocusAndSelection(activeControlIndex: number) {
		this.activeElement = this.internalControls[activeControlIndex];
		setTimeout(() => {
			this.activeElement.nativeElement.focus();
			this.activeElement.nativeElement.select();
		});
	}
}

import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomUtil, Map } from 'leaflet';
import { forkJoin, take } from 'rxjs';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { AdvanceStation } from '../../../../../api/stations/models/advance-station.model';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { ManualOpsManagerService } from '../../../../../api/manual-ops/manual-ops-manager.service';
import { MapInfoLeaflet } from '../../../../../common/models/map-info-leaflet.model';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MessageBoxService } from '../../../../../common/services/message-box.service';
import { MultiSelectService } from '../../../../../common/services/multi-select.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { PriorityGolfInputType } from '../../../../../api/programs/models/priority-input-type.model';
import { ProgramChange } from '../../../../../api/signalR/program-change.model';
import { ProgramGroupListItem } from '../../../../../api/program-groups/models/program-group-list-item.model';
import { ProgramGroupManagerService } from '../../../../../api/program-groups/program-group-manager.service';
import { ProgramManagerService } from '../../../../../api/programs/program-manager.service';
import { Program as ProgramModel } from '../../../../../api/programs/models/program.model';
import { ProgramStep } from '../../../../../api/programs/models/program-step.model';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { SiteManagerService } from '../../../../../api/sites/site-manager.service';
import { StationListItem } from '../../../../../api/stations/models/station-list-item.model';
import { StationWithMapInfoLeaflet } from '../../../../../common/models/station-with-map-info-leaflet.model';

@UntilDestroy()
@Component({
	selector: 'rb-program-list',
	templateUrl: './program-list.component.html',
	styleUrls: ['./program-list.component.scss',
		'../../../../../../styles/components/_RBCC-tree-list.scss'
	]
})
export class LeftPanelTabProgramListComponent implements OnInit, OnChanges {
	@Input() mapInfo: MapInfoLeaflet;
	@Input() map: Map;
	@Input() dragDisabled: boolean;
	@Input() isWidget: boolean = false;

	@ViewChild('startStationModal', { static: true }) startStationModal;

	treeControl = new NestedTreeControl<TreeNode<any>>(node => node.children);
  	dataSource = new MatTreeNestedDataSource<TreeNode<any>>();
	loading = false;

	public isMobile = false;
	public disableResume = true;
	public irrigationStatus = RbEnums.Common.IrrigationStatus;
	/** Array needed by the control toolbar */
	public selectedItems: any[];
	public selectedStationIds: number[] = [];
	
	/** 
	 * List of local station objects. 
	 * These are reused when a station is in multiple schedules or programs 
	*/
	private stations: { [stationID: string]: Station[] } = {};
	private treeStructure: Program[] = [];
	private isGolfSite = true;
	private _getProgramGroupUpdatesTimerRef: number;
	private _getScheduleUpdatesTimerRef: number;
	/** The 'paused' string in the current language. Needed to identify paused programs, schedules and stations */
	private pausedString = RbUtils.Translate.instant('STRINGS.PAUSED');

	constructor(
		private siteManager: SiteManagerService,
		private deviceManager: DeviceManagerService,
		private programGroupManager: ProgramGroupManagerService,
		private programManager: ProgramManagerService,
		private manualOpsManager: ManualOpsManagerService,
		private messageBoxService: MessageBoxService,
		public multiSelectService: MultiSelectService,
		private dialog: MatDialog,
		private broadcastService: BroadcastService
	) {
	}

	ngOnInit(): void {
		this.isMobile = this.deviceManager.isMobile;
		this.isGolfSite = this.siteManager.isGolfSite;

		this.programGroupManager.programGroupsListChange
			.pipe(untilDestroyed(this))
			.subscribe((programList) => {
				this.getProgramGroupUpdates(true);
			});
	
		this.broadcastService.programsUpdated
			.pipe(untilDestroyed(this))
			.subscribe((programChanges: ProgramChange[]) => {
				if (programChanges.some(p => p.changeType === "Added" || p.changeType === "Deleted")) {
					this.getProgramGroupUpdates(true, true);
				} else {
					this.getScheduleUpdates();
				}
			});	
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.mapInfo?.currentValue) {
			this.loadData();
		}
	}

	onNodeChange(node: TreeNode<any>) {
		if ('program' in node) {
			this.onProgramChange(node as Program);
		} else {
			this.onScheduleChange(node as Schedule);
		}
	}

	private loadData() {
		this.loading = true;
		forkJoin([
			this.programGroupManager.getProgramGroupsList(),
			this.mapInfo.loadLatestStationRunningStatus(true)
		])
			.subscribe({
				next: ([programGroups]) => {
					this.multiSelectService.deselectAllStations();
					this.getScheduleUpdates();
					this.fillTreeStructure(programGroups as ProgramGroupListItem[]);
				},
				error: (err) => console.error("### ERROR LOADING SOMETHING", err),
				complete: () => this.loading = false
			});
	}
	
	private onProgramChange(program: Program) {
		const programSelected = program.isSelected;
		program.children.forEach(schedule => {
			if (programSelected) {
				if (!schedule.isSelected) {
					schedule.isSelected = true;
					this.onScheduleChange(schedule);
				}
			} else {
				if (schedule.isSelected) {
					schedule.isSelected = false;
					this.onScheduleChange(schedule);
				}
			}
		});
	}

	private onScheduleChange(schedule: Schedule) {
		const scheduleSelected = schedule.isSelected;

		if (schedule.children.length === 0) {
			this.checkScheduleSelectionState(schedule);
			this.checkProgramSelectionState(schedule.parent);
		}

		schedule.children.forEach(station => {
			if (scheduleSelected) {
				if (!station.isSelected) {
					station.isSelected = true;
					this.onStationChange({ station });
				}
			} else {
				if (station.isSelected) {
					station.isSelected = false;
					this.onStationChange({ station });
				}
			}
		});
	}

	onStationChange(stationRef: {station?: Station, stationId?: number }) {
		let station: Station;
		let stationsList: Station[];

		// If a station object was provided use that. Use the stationID to find the station otherwise.
		if (stationRef.station == null) {
			if (stationRef.stationId == null) {
				throw new Error('No station info provided. ProgramListComponent.onStationChange()');
			} else {
				stationsList = this.stations[stationRef.stationId];
			}
		} else {
			station = stationRef.station;
			stationsList = this.stations[station.id];
		}

		if (station) {
			
			if (station.isSelected) {
				station.parent.childrenSelected++;
				
				if (stationsList) {
					const selectedStations = stationsList.reduce((p, s) => s.isSelected? p + 1 : p, 0)
					if (selectedStations == 1 && station.station instanceof StationWithMapInfoLeaflet) {
						if(station.station.marker && station.station.marker['_icon']){
							DomUtil.addClass(station.station.marker['_icon'], 'station-selected');
							station.station.isSelected = true;
						}
					}
				}
			} else {
				station.parent.childrenSelected--;
				if (stationsList && stationsList.every(s => !s.isSelected) && station.station instanceof StationWithMapInfoLeaflet) {
					if(station.station.marker && station.station.marker['_icon']){
						DomUtil.removeClass(station.station.marker['_icon'], 'station-selected');
						station.station.isSelected = false;
					}
				}
			}

			this.checkScheduleSelectionState(station.parent);
			this.checkProgramSelectionState(station.parent.parent);

			this.updateSelectedItems();
		}

	}

	onStartClick() {
		
		const selectedItems = this.getSelectedItems();

		if (selectedItems.programs.length > 0) {
			// RB-10203: If Golf, order the program groups by priority (and secondarily by ordinal value). If we don't
			// do this, lower-priority program groups might start before higher-priority ones, blocking the higher-priority.
			const selectedPGs = [...selectedItems.programs];
			if (this.isGolfSite) {
				// Order the groups by priority.
				selectedPGs.sort(this.programGroupPriorityComparator);
			}
	
			this.manualOpsManager
				.startProgramGroups(selectedPGs.map(pg => pg.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));		
		}

		if (selectedItems.schedules.length > 0) {
			this.manualOpsManager.startPrograms(selectedItems.schedules.map(sch => sch.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

		if (selectedItems.stations.length > 0) {
			this.selectedStationIds = selectedItems.stations.map(s => s.id);
			this.dialog.open(this.startStationModal);
		}
			
	}

	onStopClick() {
		const selectedItems = this.getSelectedItems();

		if (selectedItems.programs.length > 0) {	
			// RB-6936: Call ManualOpsController to Stop programs.
			this.manualOpsManager.stopProgramGroups(selectedItems.programs.map(pg => pg.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

		if (selectedItems.schedules.length > 0) {
			// RB-6936: Call manual ops controller to stop the programs.
			this.manualOpsManager.stopPrograms(selectedItems.schedules.map(sch => sch.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

		if (selectedItems.stations.length > 0) {
			this.manualOpsManager.stopStations(selectedItems.stations.map(sch => sch.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectStations(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}
		
	}

	onPauseClick() {
		const selectedItems = this.getSelectedItems();

		if (selectedItems.programs.length > 0) {	
			// RB-6936: Call ManualOpsController to Pause programs.
			this.manualOpsManager.pauseProgramGroups(selectedItems.programs.map(pg => pg.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

		if (selectedItems.schedules.length > 0) {
			this.manualOpsManager.pausePrograms(selectedItems.schedules.map(sch => sch.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

		if (selectedItems.stations.length > 0) {
			this.manualOpsManager.pauseStations(selectedItems.stations.map(sch => sch.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectStations(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}
			
	}

	onAdvanceClick() {
		const selectedItems = this.getSelectedItems();

		if (selectedItems.programs.length > 0) {	
			this.manualOpsManager.advanceProgramGroups(selectedItems.programs.map(pg => pg.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

		if (selectedItems.schedules.length > 0) {
			this.manualOpsManager.advancePrograms(selectedItems.schedules.map(sch => sch.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

		if (selectedItems.stations.length > 0) {
			const advanceStations: AdvanceStation[] = selectedItems.stations.map(station => new AdvanceStation(station.parent.id, station.id));
			if (!advanceStations || advanceStations.length < 1) return;
			this.manualOpsManager.advanceStations(advanceStations)
				.pipe(take(1))
				.subscribe(() => {
					this.deselectStations(selectedItems);
				}, error => {
					this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED');
				});
		}
	}

	onResumeClick() {
		const selectedItems = this.getSelectedItems();

		if (selectedItems.programs.length > 0) {	
			this.manualOpsManager.resumeProgramGroups(selectedItems.programs.map(pg => pg.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

		if (selectedItems.schedules.length > 0) {
			this.manualOpsManager.resumePrograms(selectedItems.schedules.map(sch => sch.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectProgramsAndSchedules(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

		if (selectedItems.stations.length > 0) {
			this.manualOpsManager.resumeStations(selectedItems.stations.map(sch => sch.id))
				.pipe(take(1))
				.subscribe(() => {
					this.deselectStations(selectedItems);
				}, () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED'));
		}

	}

	/** 
	 * Helper function used in the tree to determine if a node is a program or schedule so that we render
	 * the apropriate elements. Programs and schedules use the same element, while a station node uses
	 * a different one.
	 * 
	 * This works by checking if the node has either a "schedule" or a "program" property. 
	 * 
	 * @see Program 
	 * @see Schedule
	 */
	isProgramOrSchedule = (index: number, node: TreeNode<any>) => "schedule" in node || "program" in node;

	trackByFn = (i: number, node: TreeNode<any>) => node.id;

	onProgramStepStartRequested() {
		this.dialog.closeAll();
		this.deselectStations(this.getSelectedItems());
	}

	clearSelection() {
		const selectedItems = this.getSelectedItems();
		this.deselectProgramsAndSchedules(selectedItems);
		this.deselectStations(selectedItems);
	}

	private compareNumbers(a, b) {
		if (a.number < b.number) {
			return -1;
		}
		if (a.number > b.number) {
			return 1;
		}
		return 0;
	}

	private deselectStations(selectedItems: SelectedItems) {
		selectedItems.stations.forEach(station => {
			station.isSelected = false;
			this.onStationChange({ station });
		});
	}

	/** 
	 * Deselects only programs and schedules 
	 * Should be run only AFTER calling any operation on selected programs or schedules.
	 * Stations are deselected separately because a list of stations needs to be kept
	 * for the Set duration modal
	 */
	private deselectProgramsAndSchedules(selectedItems: SelectedItems) {
		selectedItems.programs.forEach(program => {
			program.isSelected = false;
			this.onProgramChange(program);
		});

		selectedItems.schedules.forEach(schedule => {
			schedule.isSelected = false;
			this.onScheduleChange(schedule);
		});
	}

	private fillTreeStructure(programGroups: ProgramGroupListItem[]) {
		this.treeStructure = programGroups.map<Program>(pg => {
			const program: Program = {
				id: pg.id,
				name: pg.name,
				number: pg.number,
				isSelected: false,
				childrenSelected: 0,
				checkState: 0,
				program: pg,
				status: pg.status,
				priority: pg.priority,
				children: null
			};

			program.children = pg.program.map<Schedule>(sch => this.createSchedule(sch, program)).sort(this.compareNumbers); // sort schedules

			return program
		}).sort(this.compareNumbers); // Sort programs

		this.dataSource.data = this.treeStructure;
	}

	private checkScheduleSelectionState(schedule: Schedule) {
		if(schedule.childrenSelected === 0){
			if (schedule.children.length === 0) {
				if (schedule.isSelected) {
					schedule.parent.childrenSelected++;
					schedule.checkState = 2;
				} else {
					schedule.parent.childrenSelected--;
					schedule.checkState = 0;
				}
			} else {
				schedule.checkState = 0;
				schedule.isSelected = false;
			}
		} else if (schedule.childrenSelected === schedule.children.length) {
			// this.schedulesSelected++;
			schedule.parent.childrenSelected++;
			schedule.checkState = 2;
			schedule.isSelected = true;
		} else {
			// Not all stations are selected

			if (schedule.checkState === 2) {
				// change originated from a station, so schedule.isSelected hasn't changed yet
				// this.schedulesSelected--;
				schedule.parent.childrenSelected--;
			}

			schedule.checkState = 1;
			schedule.isSelected = false;			
		}
		this.updateSelectedItems();
	}

	private checkProgramSelectionState(program: Program) {
		if (program.childrenSelected === 0) {
			if (program.children.some(s => s.checkState == 1)) {
				program.checkState = 1;
			} else {
				program.checkState = 0;
			}
		} else if (program.childrenSelected === program.children.length) {
			// this.programsSelected++;
			program.checkState = 2;
			program.isSelected = true;
		} else {
			// Not al schedules are selected
			// if (program.checkState === 2) {
			// 	this.programsSelected--;
			// }
			program.checkState = 1;
			program.isSelected = false;
		}
		this.updateSelectedItems();
	}

	private getSelectedItems() {
		
		// These 3 properties should never be undefined.
		const objects: SelectedItems = 
			{ 
				programs: [], 
				schedules: [], 
				stations: [] 
			};
		
		this.treeStructure.forEach(program => {

			// If a program is selected, use its ID and ignore its schedules.
			// If it's not selected but some of its schedules are, loop through them
			// to find which ones
			if (program.isSelected) {
				objects.programs.push(program);
			} else if (program.checkState === 1) {
				program.children.forEach(schedule => {

					// Same for a schedule. If selected use its ID and ignore its stations
					// If partially selected, get its selected station's IDs
					if (schedule.isSelected) {
						objects.schedules.push(schedule);
					} else if (schedule.checkState === 1) {
						schedule.children.forEach(station => {

							// If a station is selected get its ID
							if (station.isSelected) {
								objects.stations.push(station);
							}
						});
					}
				})
			}
		});

		return objects;
	}

	private programGroupPriorityComparator = (pg1: Program, pg2: Program) => {
		const sortResult = PriorityGolfInputType.priorityComparator(pg1.priority, pg2.priority);
		if (!sortResult) {
			// Secondary sort on program group number (ordinal).
			return +pg1.number - +pg2.number;
		}
		return sortResult;
	}

	private pausedItemsCountReduceFn = (total: number, item: Program | Schedule | Station) => {
		// Station items do not have a status property. But `item.station` does.
		// Program and schedule items do have a status property.
		return total + (('station' in item ? item.station : item).status === this.pausedString ? 1 : 0);				
	};

	private setOrUpdateProgramGroups(programGroups: ProgramGroupListItem[], forceNewTree = false) {
		const anyAddedOrRemoved = this.anyAddedOrRemoved(this.treeStructure.map(pg => pg.id), programGroups.map(pg => pg.id));

		if (!this.treeStructure || this.treeStructure.length < 1 || anyAddedOrRemoved || forceNewTree) {
			this.fillTreeStructure(programGroups);
			return;
		}

		let orderChanged = false;
		// Update the entire existing programGroups list
		programGroups.forEach(pg => {
			const programGroup = this.treeStructure.find(g => g.id === pg.id);
			if (programGroup) {
				if (!orderChanged && programGroup.number != pg.number) {
					orderChanged = true;
				}
				programGroup.number = pg.number;
				programGroup.name = pg.name;
				programGroup.status = pg.status;
				programGroup.priority = pg.priority;
			}
		});

		if (orderChanged) {
			this.treeStructure.sort(this.compareNumbers);
			// Trigger ngFor re-renderization
			this.treeStructure = this.treeStructure;
		}

		this.dataSource.data = this.treeStructure;

	}

	private getScheduleUpdates() {
		if (this._getScheduleUpdatesTimerRef != null) { clearTimeout(this._getScheduleUpdatesTimerRef); }
		this._getScheduleUpdatesTimerRef = window.setTimeout(() => {
			// this.isBusy = true;
			this.programManager.getProgramsList(true)
					.pipe(take(1),
					// finalize(() => this.isBusy = false)
					)
					.subscribe(schedules => {

						const scheduleList: Schedule[] = [];
						this.treeStructure.forEach(p => {
							p.children.forEach(s => scheduleList.push(s));
						});

						let orderChanged = false;

						schedules.forEach(sch => {
							const index = scheduleList.findIndex(s => s.id === sch.id);
							if (index > -1) {

								if (!orderChanged && scheduleList[index].number != sch.number) {
									orderChanged = true;
								}

								scheduleList[index].name = sch.name;
								scheduleList[index].number = sch.number;
								scheduleList[index].status = sch.status;
							}
						});

						if (orderChanged) {
							this.treeStructure.forEach(p => {
								p.children.sort(this.compareNumbers);
							});
							// Trigger ngFor re-renderization
							this.treeStructure = this.treeStructure;
						}

					}, () => {
						this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED');
					});
		}, 500);

	}

	private getProgramGroupUpdates(bypassCache: boolean, forceNewTree = false) {
		// Placing this call in a setTimeout to mitigate against flooding due to multiple events all calling getComponent Data simultaneously.
		if (this._getProgramGroupUpdatesTimerRef != null) { clearTimeout(this._getProgramGroupUpdatesTimerRef); }
		this._getProgramGroupUpdatesTimerRef = window.setTimeout(() => {
			// this.isBusy = true;
			
			const sources = {
				programs: this.programGroupManager.getProgramGroupsList(bypassCache),
				schedules: this.programManager.getProgramsList(bypassCache)
			};

			forkJoin(sources).subscribe((results) => {
				results.programs.forEach(p => {
					p.program.forEach(s => {
						const index = results.schedules.findIndex(sch => sch.id == s.id);
						if (index > -1) {
							s['status'] = results.schedules[index].status;
							results.schedules.splice(index, 1);
						}
					})
				});
				this.setOrUpdateProgramGroups(results.programs, forceNewTree);
			});
		}, 500);
	}

	private updateSelectedItems() {
		const selectedItems = this.getSelectedItems();
		if (selectedItems.programs.length > 0) {
			this.selectedItems = selectedItems.programs;
		} else if (selectedItems.schedules.length > 0) {
			this.selectedItems = selectedItems.schedules;
		} else if (selectedItems.stations.length > 0) {
			this.selectedItems = selectedItems.stations;
		} else {
			this.selectedItems = null;
		}

		// The resume button should be enabled only when all selected items are paused.
		this.disableResume =
			selectedItems.programs.reduce(this.pausedItemsCountReduceFn, 0) < selectedItems.programs.length
			|| selectedItems.schedules.reduce(this.pausedItemsCountReduceFn, 0) < selectedItems.schedules.length
			|| selectedItems.stations.reduce(this.pausedItemsCountReduceFn, 0) < selectedItems.stations.length

	}

	private createSchedule = (sch: ProgramModel, program: Program) => {
		const schedule: Schedule = {
			id: sch.id,
			name: sch.name,
			number: sch.number,
			isSelected: false,
			childrenSelected: 0,
			checkState: 0,
			parent: program,
			schedule: sch,
			status: '',
			children: null
		};

		schedule.children = sch.programStep.map<Station>(step => this.createStation(step, schedule))
			.filter(s => s != null)
			.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

		return schedule;
	}

	private createStation = (step: ProgramStep, schedule: Schedule) => {

		let station: Station;

		// Check if the MapInfo object has a station object for this ID
		const stationFromMap = this.mapInfo.stations.find(s => s.id == step.stationId);

		if (stationFromMap == null) {
			const stationFromDifferentSite = this.mapInfo.stationsCompleteList.find(s => s.id == step.stationId);
			if (stationFromDifferentSite != null) {
				station = {
					id: stationFromDifferentSite.id,
					isSelected: false,
					currentSite: false,
					parent: schedule,
					station: stationFromDifferentSite,
					sequenceNumber: step.sequenceNumber
				}
			}
		} else {
			station = {
				id: stationFromMap.id,
				isSelected: false,
				currentSite: true,
				parent: schedule,
				station: stationFromMap,
				sequenceNumber: step.sequenceNumber
			};

			// Add the new local-only station object to the array
			if (this.stations[step.stationId] == null) {
				this.stations[step.stationId] = [station];
			} else {
				this.stations[step.stationId].push(station);
			}
		}

		return station;
	}

	private anyAddedOrRemoved(currentIds: number[], newIds: number[]) {
		return currentIds.filter(id => !newIds.includes(id)).length > 0 ||
			newIds.filter(id => !currentIds.includes(id)).length > 0;

	}

}

interface TreeNode<T> {
	/** The schedule's ID */
	id: number;
	/** The schedule's name */
	name: string;
	/** The schedules order */
	number: number;
	/** Whether the schedule is selected */
	isSelected: boolean;
	/** Number of children selected  */
	childrenSelected: number;
	/** The state of the checkbox */
	checkState: number;
	/** The list of children in this node */
	children: T[];

	status: string;
}

/**
 * Local only interface for stations in a schedule
 */
interface Station {
	/** The station's ID */
	id: number;
	/** Whether the station is selected */
	isSelected: boolean;
	/** The parent schedule of this station */
	parent: Schedule;
	/** Whether this station belongs to the current site */
	currentSite: boolean;
	/** The station object */
	station: StationWithMapInfoLeaflet | StationListItem;
	/** The program step's sequence number in the schedule */
	sequenceNumber: number;
}

/**
 * Local only interface for schedules in a program
 */
interface Schedule extends TreeNode<Station> {
	/** The parent program of this schedule */
	parent: Program;
	/** Reference to the actual shcedule list item */
	schedule: any;
}

/**
 * Local only interface for a program
 */
interface Program extends TreeNode<Schedule> {
	/** Reference to the actual program group list item */
	program: ProgramGroupListItem;

	priority: number;
}

interface SelectedItems {
	programs: Program[];
	schedules: Schedule[];
	stations: Station[];
}
import { ApiService, HttpMethod } from '../_common/api.service';
import { About } from './models/about.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class EntityVersionApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getCoreVersion() {
		return this.apiRequest<any>(this.getCoreVersionUrl, HttpMethod.Get)
			.pipe(map(json => new About(json)));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}EntityVersion`; }

	private get getCoreVersionUrl(): string { return `${this.baseUrl}/GetAppVersion`; }

}

import { RbUtils } from '../../../common/utils/_rb.utils';

export class UniversalTwoWireData {

	constructor(json: any = null, controllerId: number, changeDateTime: string) {
		if (json) {
			Object.assign(this, json);
		}

		this.changeDateTime = RbUtils.Conversion.convertStringToDate(changeDateTime);
		this.controllerId = controllerId;
	}

	controllerId: number;
	changeDateTime: Date;
	current: number;
	currentLimitThreshold: number;
	flags: number;
	timestamp: number;
	voltage: number;
}

import { Component, OnInit } from '@angular/core';
import { NavigationHistoryService } from '../../../common/services/navigation-history.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rb-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  isMapPage = false;
  
  constructor(
    private navigationHistoryService: NavigationHistoryService,
		private router: Router
  ) {}

  ngOnInit(): void {
    this.checkIfMapPage();
  }

  goBack(): void {
    this.navigationHistoryService.navigateBack();
  }

  canGoBack(): boolean {
    return this.navigationHistoryService.canGoBack();
  }

  openMenu() {
    this.navigationHistoryService.invokeOpenMenu.emit()
  }

  checkIfMapPage(): void {
    const url = this.router.url;
    if (!url) return;
      
    const segments = url.split('/').filter(segment => segment);
    if (segments.length === 0) return;
  
    let currentPage = segments[segments.length - 1];
  
    const queryParamIndex = currentPage.indexOf('?');
    if (queryParamIndex !== -1) {
      currentPage = currentPage.substring(0, queryParamIndex);
    }  
    if (!currentPage) return;
  
    if (currentPage === 'map') {
      this.isMapPage = true;
    } else {
      this.isMapPage = false;
    }
  }

}

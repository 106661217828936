import { RbConstants } from '../../../common/constants/_rb.constants';

export class AreaUiSettings {

	fillColor: string = RbConstants.Form.AREA_DEFAULT_UI_SETTINGS.fillColor;
	fillOpacity: number = RbConstants.Form.AREA_DEFAULT_UI_SETTINGS.fillOpacity;
	lineColor: string = RbConstants.Form.AREA_DEFAULT_UI_SETTINGS.lineColor;
	lineOpacity: number = RbConstants.Form.AREA_DEFAULT_UI_SETTINGS.lineOpacity;
	lineWidth: number = RbConstants.Form.AREA_DEFAULT_UI_SETTINGS.lineWidth;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

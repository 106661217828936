import { BroadcastService } from '../../common/services/broadcast.service';
import { DiagnosticLogApiService } from './diagnostic-log-api.service';
import { DIDiagnosticSummaryData } from './models/di-diagnostic-summary-data';
import { GetICIDiagnosticLogSummaryDataResponse } from './models/get-ici-diagnostic-log-summary-data-response';
import { Injectable } from '@angular/core';
import { IStationDiagnosticHistory } from './models/station-diagnostic';
import { LINKChannelDiagnosticData } from './../manual-ops/models/ic-link-channel-diagnostic-data.model';
import { LINKHourlyDiagnosticData } from './../manual-ops/models/ic-link-hourly-diagnostic-data.model';
import { Observable } from 'rxjs';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class DiagnosticLogManagerService extends ServiceManagerBase {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private diagnosticLogApiService: DiagnosticLogApiService,
				protected broadcastService: BroadcastService
	) {
		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getICIDiagnosticLogSummaryData(): Observable<GetICIDiagnosticLogSummaryDataResponse> {
		return this.diagnosticLogApiService.getICIDiagnosticLogSummaryData();
	}

	getDIDiagnosticLogSummaryData(): Observable<DIDiagnosticSummaryData[]> {
		return this.diagnosticLogApiService.getDIDiagnosticLogSummaryData();
	}

	getStationDiagnosticLogHistory(stationId: number, diagnosticRunType: RbEnums.Common.StationDiagnosticRunType): Observable<IStationDiagnosticHistory[]> {
		return this.diagnosticLogApiService.getStationDiagnosticLogHistory(stationId, diagnosticRunType);
	}

	getMimLinkHourlyDiagnosticLog(satelliteId: number, hour?: number): Observable<LINKHourlyDiagnosticData> {
		return this.diagnosticLogApiService.getMimLinkHourlyDiagnosticLog(satelliteId, hour);
	}

	getMimLinkChannelDiagnosticLog(satelliteId: number, groupNumber: number, channel: number): Observable<LINKChannelDiagnosticData> {
		return this.diagnosticLogApiService.getMimLinkChannelDiagnosticLog(satelliteId, groupNumber, channel);
	}

	resetMimLinkHourlyDiagnosticLog(satelliteId: number): Observable<null> {
		return this.diagnosticLogApiService.resetMIM_LINKHourlyDiagnosticLog(satelliteId);
	}

	resetMimLinkChannelDiagnosticLog(satelliteId: number, groupNumber?: number, channel?: number): Observable<null> {
		return this.diagnosticLogApiService.resetMIM_LINKChannelDiagnosticLog(satelliteId, groupNumber, channel);
	}

}

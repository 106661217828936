<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-dialog
	[title]="'STRINGS.SELECT_A_TRIGGER_STATION' | translate"
	[closeButtonLabel]="'STRINGS.CANCEL' | translate"
	[submitButtonLabel]="'STRINGS.SUBMIT' | translate"
	[showSubmitButton]="true"
	[closeOnSubmit]="true"
	(onSubmit)="onSave()"
	(onCancel)="onCancel()"
	data-qaid="select-stations-dialog"
>
	<rb-select-panel *ngIf="areas.length > 0" [areas]="areas" (holesSelected)="onSelectPanelChange($event)"></rb-select-panel>
	<rb-table-wrapper
			#stationListTable
			[rowData]="filteredStations"
			[includeCheckbox]="true"
			[hideRowSelection]="true"
			[rowSelection]="'single'"
			(onRowSelect)="onStationSelect($event)"
			class="rb-table-wrapper"
	>
		<rb-column [headerName]="'STRINGS.NAME' | translate" field="name"></rb-column>
		<rb-column [headerName]="'STRINGS.BASE_RUN_TIME' | translate" field="defaultRuntime"></rb-column>
	</rb-table-wrapper>
</rb-dialog>

import { RbUtils } from '../../../common/utils/_rb.utils';

export class UpdateHistory {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.updateTimestamp) { this.updateTimestamp = RbUtils.Conversion.convertStringToDate(json.updateTimestamp); }
			if (json.releaseTimestamp) { this.releaseTimestamp = RbUtils.Conversion.convertStringToDate(json.releaseTimestamp); }
		}
	}

	customerNumber: number;
	id: number;
	updateTimestamp: Date;
	versionAfterUpdate: string;
	activationCode: string;
	releaseTimestamp: Date;
	releaseNotes: string;
}

import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AddressBookApiService } from './address-book-api.service';
import { AddressBookEntry } from './models/address-book-entry.model';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { BroadcastService } from '../../common/services/broadcast.service';
import { Injectable } from '@angular/core';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class AddressBookManagerService extends ServiceManagerBase {

	// Subjects
	addressBookChange = new Subject<AddressBookEntry[]>();

	// Cache Containers
	private _apiResult: ApiCachedRequestResponse<AddressBookEntry[]>;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private addressBookApiService: AddressBookApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	protected clearCache() {
		this.addressBookApiService.clearCache();
		this._apiResult = null;
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	createNewContact(contact: AddressBookEntry): Observable<null> {
		return this.addressBookApiService.createNewContact(contact);
	}

	deleteContacts(contactIds: number[]): Observable<void> {
		return this.addressBookApiService.deleteContacts(contactIds)
			.pipe(
				tap(() => {
					this._apiResult.value = this._apiResult.value.filter(s => contactIds.indexOf(s.id) === -1);
					this.addressBookChange.next(this._apiResult.value);
				})
			);
	}

	getContact(contactId: number): Observable<AddressBookEntry> {
		return this.getContacts(false).pipe(map(contacts => contacts.find(contact => contact.id === contactId)));
	}

	getContacts(bypassCache = false): Observable<AddressBookEntry[]> {
		return this.addressBookApiService.getContacts(bypassCache, false /* IncludeCultureSettings */)
			.pipe(map(response => {
				this._apiResult = response;
				return this._apiResult.value;
			}));
	}

	updateContact(contactId: number, updateValues: any): Observable<null> {
		return this.addressBookApiService.updateContact(contactId, updateValues);
	}
}

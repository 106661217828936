import { ApiService, HttpMethod } from '../_common/api.service';
import { AUTH_TYPE, AWSAppSyncClient } from 'aws-appsync';
import { map, share } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { CustomerEntitlement } from './models/customer-entitlement.model';
import { EnvironmentService } from '../../common/services/environment.service';
import { gql } from 'graphql-tag';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreTransactionToken } from './models/store-transaction-token';

@Injectable({
	providedIn: 'root'
})
export class EntitlementsApiService extends ApiService {

	private subscribeDoc = `
    subscription IQ4Subscription {
        subscribe(name: "$channel") {
            data
        }
    }
`
	private _appSyncObservable: Observable<any>;
	private _appSyncObserverCount = 0;
	private _awsAppSyncClient: AWSAppSyncClient<any>;
	private _token: string;
	private _hydratedClientObservable: Subscription;

	constructor(protected http: HttpClient,
				protected env: EnvironmentService) { super(http, env); }

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	clearCache(): void {
		if (this._hydratedClientObservable === undefined) return;
		this._hydratedClientObservable.unsubscribe();
		this._hydratedClientObservable = undefined;
		this._appSyncObservable = undefined;
	}

	getEntitlements(): Observable<CustomerEntitlement[]> {
		return this.apiRequest<any>(this.getEntitlementsUrl, HttpMethod.Get)
			.pipe(map((json: any) => json.map((itemJson: any) => new CustomerEntitlement(itemJson))));
	}

	getStoreTransactionToken(): Observable<StoreTransactionToken> {
		return this.apiRequest<any>(this.getStoreTransactionTokenUrl, HttpMethod.Get)
			.pipe(map((json: any) => new StoreTransactionToken(json)));
	}

	subscribeToAppSync(name: any, token: string): Observable<any> {
		this._appSyncObserverCount++;
		if (this._appSyncObservable !== undefined) return this._appSyncObservable;
		this._token = token;

		// Create an observable that will pass-thru the data we receive from AppSync.
		// That way we can control the life-cycle of our observables more carefully.
		this._appSyncObservable = new Observable(observer => {

			this.awsAppSyncClient.hydrated().then((hydratedClient: any) => {
				// Don't subscribe to the hydrated client more than once
				if (this._hydratedClientObservable !== undefined) return;
				const subscribeString = this.subscribeDoc.replace('$channel', name);
				this._hydratedClientObservable = hydratedClient.subscribe({ query: gql(subscribeString), variables: {} }).subscribe(result => {
					observer.next(result);
				});
			});
		});
		return this._appSyncObservable.pipe(share());
	}

	unsubscribeFromAppSync(): void {
		if (--this._appSyncObserverCount > 0) return;
		this.clearCache();
	}

	private get awsAppSyncClient(): AWSAppSyncClient<any> {
		if (this._awsAppSyncClient != null) return this._awsAppSyncClient;
		this._awsAppSyncClient = new AWSAppSyncClient({
			url: this.env.awsRBCCAppSyncUrl,
			region: this.env.awsRBCCAppSyncRegion,
			auth: {
				type: AUTH_TYPE.OPENID_CONNECT,
				jwtToken: this._token
			}
		});
		return this._awsAppSyncClient;
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseLicenseApiCloudUrl}`; }

	private get getEntitlementsUrl(): string { return `${this.baseUrl}companies/entitlements`; }
	private get getStoreTransactionTokenUrl(): string { return `${this.baseUrl}entitlements/transactionToken`; }
}

import { RbUtils } from '../../../common/utils/_rb.utils';

export class UniversalTwoWireDevicePaths {

	constructor(json: any = null, controllerId: number, changeDateTime: string) {
		if (json) {
			Object.assign(this, json);
		}

		this.changeDateTime = RbUtils.Conversion.convertStringToDate(changeDateTime);
		this.controllerId = controllerId;
	}

	controllerId: number;
	changeDateTime: Date;
	deviceCount: number;
	devicePathsBitmaps: any[];
	deviceType: number;
	startingDeviceNumber: number;

}

import { Control } from 'leaflet';
import { MapInfoLeaflet } from './map-info-leaflet.model';
import { TranslateService } from '@ngx-translate/core';

export class FullScreenControlLeaflet {

	static create(mapInfo: MapInfoLeaflet, translate: TranslateService, parentDiv: HTMLElement) {

		// Set CSS for the control border.
		const controlUI = document.createElement('div');
		controlUI.className = 'rb-leaflet-control leaflet-fullscreen-control';
		controlUI.title = translate.instant('STRINGS.FULL_SCREEN');
		parentDiv.appendChild(controlUI);

		const icon = document.createElement('i');
		icon.innerHTML = 'crop_free';
		icon.classList.add('material-icons');
		icon.id = 'full-screen';
		controlUI.appendChild(icon);

		controlUI.addEventListener('click', function() {
			mapInfo.toggleFullScreen();
		});

		const FullScreenControl = Control.extend({
			onAdd: function(map) {
				return parentDiv;
			},
		});

		return new FullScreenControl({ position: 'topright' });

	}

	static setIcon(fullScreen: boolean): void {
		const icon = document.getElementById('full-screen');
		if (icon == null) return;
		icon.innerHTML = fullScreen ? 'zoom_out_map' : 'crop_free';
	}
}

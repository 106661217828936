import { ProgramStepListItem } from './program-step-list-item.model';

export class AllProgramsProgramStepList {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.runStationStatuses != null) this.runStationStatuses = this.runStationStatuses.map(item => new ProgramStepListItem(item));
		}
	}

	programId: number;
	satelliteId: number;
	runStationStatuses: ProgramStepListItem[];
}

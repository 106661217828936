import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rb-interface-fault-finding-warning',
  templateUrl: './interface-fault-finding-warning.component.html',
  styleUrls: ['./interface-fault-finding-warning.component.scss']
})
export class InterfaceFaultFindingWarningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

export class CourseViewAreaOrdinal {

	constructor(json: any = null, nonGolfHoleIndexesArray: number[]) {
		if (json) {
			Object.assign(this, json);
		}

		this.holesStationIds = this.stationIds.filter((value, index) => !nonGolfHoleIndexesArray.includes(index));
		this.placesStationIds = this.stationIds.filter((value, index) => nonGolfHoleIndexesArray.includes(index));
	}

	label: string;
	stationIds: number[] = [];				// All
	holesStationIds: number[] = [];			// Golf Only
	placesStationIds: number[] = [];		// Golf + Places
}

import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { SensorTrigger } from '../../sensor-trigger/models/sensor-trigger.model';
import { WeatherSource } from '../../weather-sources/models/weather-source.model';

import SensorType = RbEnums.Common.SensorType;
import FlowSensorModelType = RbEnums.Common.FlowSensorModelType;
import OnOffState = RbEnums.Common.OnOffState;

export class Sensor {
	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.sensorTrigger) { this.sensorTrigger = json.sensorTrigger.map(item => new SensorTrigger(item)); }
			if (json.weatherSource) { this.weatherSource = new WeatherSource(json.weatherSource); }
		}
	}

	addressInt: number;
	ccuConfigCode: string;
	channel: number;
	channelString: string;
	companyId: number;
	flowSensorModel: FlowSensorModelType;
	groupNumber: number;
	id: number;
	isIrrigationEngineBased = false;
	latitude: number;
	longitude: number;
	name: string;
	offset: number;
	ordinal: number;
	pipeDiameter: number;
	pulseValue: any;
	satelliteId: number;
	sensorTrigger: SensorTrigger[] = [];
	subChannel: string;
	type: SensorType;
	weatherSensorModel: number;
	weatherSource: WeatherSource;
	weatherSourceID: number;
	onOffState: OnOffState;
	localWeatherSensorTypeId: number;
	isLocal: boolean;
	notes: string;
	secondsPerUpdate: number = null;

	get hasLocation(): boolean { return this.latitude != null && this.longitude != null; }

	static createDefault( sensorType: RbEnums.Common.SensorType = RbEnums.Common.SensorType.Flow): Sensor {
		const sensor = new Sensor();
		sensor.initialize(sensorType);
		return sensor;
	}

	initialize(sensorType: RbEnums.Common.SensorType) {
		this.addressInt = 0;
		this.channelString = '';
		this.companyId = 0;
		this.id = 0;
		this.name = '';
		this.offset = 0;
		this.ordinal = 0;
		this.pipeDiameter = 0;
		// this.satelliteId = 0;	// DO NOT SET THIS. IT NEEDS TO BE UNDEFINED TO TRIGGER PROPER VALIDATION ON SENSOR ADD/EDIT FORM.
		this.type = sensorType;
		this.onOffState = OnOffState.Off;
		this.secondsPerUpdate = null;	// Null => default value which is generally 60 seconds.
	}
}

import { CachedObject } from './cached-object';

export class CachedCollection<T> extends CachedObject {

	constructor(collection: T[]) {
		super();

		this.collection = collection;
	}

	collection: T[];

	addItem(item: T) {
		this.collection.unshift(item);
	}

	removeItem(item: T) {
		const index = this.collection.indexOf(item);
		if (index === -1) { return; }
		this.collection.splice(index, 1);
	}
}

import { Area } from '../../api/areas/models/area.model';
import { AreaOptions } from './area-options';
import { AreaUiSettings } from '../../api/areas/models/area-ui-settings.model';
import { ContextMenuOptions } from './context-menu-options';
import { ControllerListItem } from '../../api/controllers/models/controller-list-item.model';
import { ControllerOptions } from './controller-options';
import { GeoGroup } from '../../api/regions/models/geo-group.model';
import { GeoItem } from '../../api/regions/models/geo-item.model';
import { HoleOptions } from './hole-options';
import { MapInfoLeaflet } from './map-info-leaflet.model';
import { Polygon } from 'leaflet';
import { RbEnums } from '../enumerations/_rb.enums';
import { Sensor } from '../../api/sensors/models/sensor.model';
import { SensorListItem } from '../../api/sensors/models/sensor-list-item.model';
import { SensorOptions } from './sensor-options';
import { Site } from '../../api/sites/models/site.model';
import { SiteOptions } from './site-options';
import { StationAreaOptions } from './station-area-options';
import { StationOptions } from './station-options';
import { StationWithMapInfoLeaflet } from './station-with-map-info-leaflet.model';
import { TranslateService } from '@ngx-translate/core';

export class MapInfoContextMenuLeaflet {

	// Context-menu data
	private _holeOptions: ContextMenuOptions<RbEnums.Map.HoleContextMenu>[] = [
		{ text: this.translate.instant('STRINGS.START'), value: RbEnums.Map.HoleContextMenu.Start, icon: 'mdi mdi-play' },
		{ text: this.translate.instant('STRINGS.PAUSE'), value: RbEnums.Map.HoleContextMenu.Pause, icon: 'mdi mdi-pause' },
		{ text: this.translate.instant('STRINGS.RESUME'), value: RbEnums.Map.HoleContextMenu.Resume, icon: 'icon-resume' },
		{ text: this.translate.instant('STRINGS.STOP'), value: RbEnums.Map.HoleContextMenu.Stop, icon: 'mdi mdi-stop' },
		{ text: this.translate.instant('STRINGS.ADD_TO_MAP'), value: RbEnums.Map.HoleContextMenu.AddToMap, icon: 'mdi mdi-map-marker-plus' },
		{ text: this.translate.instant('STRINGS.FIND_ON_MAP'), value: RbEnums.Map.HoleContextMenu.FindOnMap, icon: 'mdi mdi-map-search' },
		// { text: this.translate.instant('STRINGS.SELECT_STATIONS'), value: RbEnums.Map.HoleContextMenu.SelectStations,
		// 	icon: 'mdi mdi-checkbox-multiple-marked-circle-outline' },
		// { text: this.translate.instant('STRINGS.EDIT'), value: RbEnums.Map.HoleContextMenu.Edit, icon: 'mdi mdi-pencil' },
		// { text: this.translate.instant('STRINGS.REMOVE_FROM_MAP'), value: RbEnums.Map.HoleContextMenu.Remove, icon: 'mdi mdi-delete-forever text-red' },
	];
	private _areaOptions: ContextMenuOptions<RbEnums.Map.AreaContextMenu>[] = [
		{ text: this.translate.instant('STRINGS.START'), value: RbEnums.Map.AreaContextMenu.Start, icon: 'mdi mdi-play' },
		{ text: this.translate.instant('STRINGS.PAUSE'), value: RbEnums.Map.AreaContextMenu.Pause, icon: 'mdi mdi-pause' },
		{ text: this.translate.instant('STRINGS.RESUME'), value: RbEnums.Map.AreaContextMenu.Resume, icon: 'icon-resume' },
		{ text: this.translate.instant('STRINGS.STOP'), value: RbEnums.Map.AreaContextMenu.Stop, icon: 'mdi mdi-stop' },
		{ text: this.translate.instant('STRINGS.ADVANCE'), value: RbEnums.Map.AreaContextMenu.Advance, icon: 'mdi mdi-fast-forward' },
		{ text: this.translate.instant('STRINGS.CALCULATE_AREA'), value: RbEnums.Map.AreaContextMenu.CalculateArea, icon: 'mdi mdi-texture-box' },
		{ text: this.translate.instant('STRINGS.ADD_TO_MAP'), value: RbEnums.Map.AreaContextMenu.AddToMap, icon: 'mdi mdi-map-marker-plus' },
		{ text: this.translate.instant('STRINGS.FIND_ON_MAP'), value: RbEnums.Map.AreaContextMenu.FindOnMap, icon: 'mdi mdi-map-search' },
		{ text: this.translate.instant('STRINGS.VIEW_SHAPE'), value: RbEnums.Map.AreaContextMenu.ViewShape, icon: 'mdi mdi-vector-square' },
		{ text: this.translate.instant('STRINGS.EDIT_SHAPE'), value: RbEnums.Map.AreaContextMenu.EditShape, icon: 'mdi mdi-vector-square-edit' },
		// { text: this.translate.instant('STRINGS.SELECT_STATIONS'), value: RbEnums.Map.AreaContextMenu.SelectStations,
		// 	icon: 'mdi mdi-checkbox-multiple-marked-circle-outline' },
		// { text: this.translate.instant('STRINGS.EDIT'), value: RbEnums.Map.AreaContextMenu.Edit, icon: 'mdi mdi-pencil' },
		// { text: this.translate.instant('STRINGS.REMOVE_FROM_MAP'), value: RbEnums.Map.AreaContextMenu.Remove, icon: 'mdi mdi-delete-forever text-red' },
	];

	private _stationAreaOptions: ContextMenuOptions<RbEnums.Map.StationAreaContextMenu>[]  = [
		{ text: this.translate.instant('STRINGS.CALCULATE_AREA'), value: RbEnums.Map.StationAreaContextMenu.CalculateArea, icon: 'mdi mdi-texture-box' },
		{ text: this.translate.instant('STRINGS.EDIT_SHAPE'), value: RbEnums.Map.StationAreaContextMenu.EditShape, icon: 'mdi mdi-vector-square-edit' },
		{ text: this.translate.instant('STRINGS.VIEW_SHAPE'), value: RbEnums.Map.StationAreaContextMenu.ViewShape, icon: 'mdi mdi-vector-square' },
		{ text: this.translate.instant('STRINGS.EDIT_SHAPE_COLOR'), value: RbEnums.Map.StationAreaContextMenu.EditAreaColor, icon: 'mdi mdi-palette' }
	];
	private _sensorOptions: ContextMenuOptions<RbEnums.Map.SensorContextMenu>[] = [
		{ text: this.translate.instant('STRINGS.ADD_TO_MAP'), value: RbEnums.Map.SensorContextMenu.AddToMap, icon: 'mdi mdi-map-marker-plus' },
		{ text: this.translate.instant('STRINGS.FIND_ON_MAP'), value: RbEnums.Map.SensorContextMenu.FindOnMap, icon: 'mdi mdi-map-search' }
	];
	private _stationOptions: ContextMenuOptions<RbEnums.Map.StationContextMenu>[] = [
		{ text: this.translate.instant('STRINGS.START'), value: RbEnums.Map.StationContextMenu.Start, icon: 'mdi mdi-play' },
		{ text: this.translate.instant('STRINGS.PAUSE'), value: RbEnums.Map.StationContextMenu.Pause, icon: 'mdi mdi-pause' },
		{ text: this.translate.instant('STRINGS.RESUME'), value: RbEnums.Map.StationContextMenu.Resume, icon: 'icon-resume' },
		{ text: this.translate.instant('STRINGS.STOP'), value: RbEnums.Map.StationContextMenu.Stop, icon: 'mdi mdi-stop' },
		{ text: this.translate.instant('STRINGS.ADD_TO_MAP'), value: RbEnums.Map.StationContextMenu.AddToMap, icon: 'mdi mdi-map-marker-plus' },
		{ text: this.translate.instant('STRINGS.FIND_ON_MAP'), value: RbEnums.Map.StationContextMenu.FindOnMap, icon: 'mdi mdi-map-search' },
		{ text: this.translate.instant('STRINGS.NOTES'), value: RbEnums.Map.StationContextMenu.Notes, icon: 'mdi mdi-message-processing' },
		{ text: this.translate.instant('STRINGS.ADD_SHAPE'), value: RbEnums.Map.StationContextMenu.AddShape, icon: 'mdi mdi-vector-square  text-green' },
		{ text: this.translate.instant('STRINGS.CALCULATE_AREA'), value: RbEnums.Map.StationContextMenu.CalculateAreas, icon: 'mdi mdi-texture-box' },
		{ text: this.translate.instant('STRINGS.EDIT_SHAPE_COLOR'), value: RbEnums.Map.StationContextMenu.EditAreaColor, icon: 'mdi mdi-palette' }
	];
	private _controllerOptions: ContextMenuOptions<RbEnums.Map.ControllerContextMenu>[] = [
		{ text: this.translate.instant('STRINGS.SYNC'), value: RbEnums.Map.ControllerContextMenu.Sync, icon: 'mdi mdi-sync' },
		{ text: this.translate.instant('STRINGS.REVERSE'), value: RbEnums.Map.ControllerContextMenu.ReverseSync, icon: 'mdi mdi-autorenew' },
		{ text: this.translate.instant('STRINGS.LOGS'), value: RbEnums.Map.ControllerContextMenu.Logs, icon: 'icon-log' },
		{ text: this.translate.instant('STRINGS.CONNECT'), value: RbEnums.Map.ControllerContextMenu.Connect, icon: 'icon-connection' },
		{ text: this.translate.instant('STRINGS.DISCONNECT'), value: RbEnums.Map.ControllerContextMenu.Disconnect, icon: 'icon-disconnect' },
		{ text: this.translate.instant('STRINGS.STOP'), value: RbEnums.Map.ControllerContextMenu.StopAll, icon: 'mdi mdi-stop' },
		{ text: this.translate.instant('STRINGS.ADD_TO_MAP'), value: RbEnums.Map.ControllerContextMenu.AddToMap, icon: 'mdi mdi-map-marker-plus' },
		{ text: this.translate.instant('STRINGS.FIND_ON_MAP'), value: RbEnums.Map.ControllerContextMenu.FindOnMap, icon: 'mdi mdi-map-search' },
	];
	private _siteOptions: ContextMenuOptions<RbEnums.Map.SiteContextMenu>[] = [
		{ text: this.translate.instant('STRINGS.EDIT'), value: RbEnums.Map.SiteContextMenu.Edit, icon: 'icon-edit' },
	];

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private mapInfo: MapInfoLeaflet, private isGolfSite: boolean, private translate: TranslateService) {
		// Insert 'Programs and Schedules' just above 'Edit' for Golf only.
		if (this.isGolfSite) {
			// Remove 3 Station contect menus: "Add Area", "Calculate Areas" and "EditAreaColor"
			this._stationOptions.splice(-3, 3);
			this._stationOptions.splice(-2, 0, {
					text: this.translate.instant('STRINGS.PROGRAMS_AND_SCHEDULES'),
					value: RbEnums.Map.StationContextMenu.ProgramsAndSchedules,
					icon: 'mdi mdi-magnify'
				}
			);
			this._stationOptions.splice(-2, 0, {
					text: this.translate.instant('STRINGS.SHOW_STATION_DIAGNOSTIC'),
					value: RbEnums.Map.StationContextMenu.Diagnostics,
					icon: 'mdi mdi-help-network-outline'
				}
			);
		}
	}

	// =========================================================================================================================================================
	// Pubic Methods
	// =========================================================================================================================================================

	sensorOptions(sensor: Sensor | SensorListItem, menuLocationX: number, menuLocationY: number,
		useFullMenu: boolean = false, includeFind: boolean = false): SensorOptions {
		const sensorOptions = this._sensorOptions.filter(o => this.isValidSensorOption(o, sensor, useFullMenu, includeFind));
		const sensorFullOptions = this._sensorOptions.filter(o => this.isValidSensorOption(o, sensor, useFullMenu, includeFind));
		return {
			sensor: sensor,
			mapInfo: this.mapInfo,
			menuOptions: useFullMenu ? sensorFullOptions : sensorOptions,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'station.png',
			icon: 'station',
			title: sensor.name,
			editOption: RbEnums.Map.SensorContextMenu.Edit,
			removeOption: sensor.latitude ? RbEnums.Map.SensorContextMenu.Remove : null
		};
	}

	private masterValveOptions(value: RbEnums.Map.StationContextMenu) {
		if (value === RbEnums.Map.StationContextMenu.Edit || 
			value === RbEnums.Map.StationContextMenu.Remove ||
			value === RbEnums.Map.StationContextMenu.AddToMap ||
			value === RbEnums.Map.StationContextMenu.FindOnMap ||
			value === RbEnums.Map.StationContextMenu.AddShape ||
			value === RbEnums.Map.StationContextMenu.EditAreaColor ||
			value === RbEnums.Map.StationContextMenu.CalculateAreas) {
			return value;
		}
		return -1;
	}

	stationOptions(station: StationWithMapInfoLeaflet, menuLocationX: number, menuLocationY: number,
				useFullMenu: boolean = false, includeFind: boolean = false, areasStyleSetting?: AreaUiSettings): StationOptions {
		const hasStationArea = this.mapInfo.stationAreaHasLocation(station.id);
		let options = !useFullMenu ? this._stationOptions.filter(o => o.value === RbEnums.Map.StationContextMenu.FindOnMap &&
			includeFind && station.latitude != null && station.longitude != null) :
			this._stationOptions.filter(o =>
				(o.value !== RbEnums.Map.StationContextMenu.AddToMap || (includeFind && !station.hasLocation)) &&
				(o.value !== RbEnums.Map.StationContextMenu.FindOnMap || (includeFind && station.hasLocation)) &&
				(o.value !== RbEnums.Map.StationContextMenu.CalculateAreas || hasStationArea) &&
				(o.value !== RbEnums.Map.StationContextMenu.EditAreaColor || hasStationArea) &&
				(o.value !== RbEnums.Map.StationContextMenu.Edit || useFullMenu) &&
				(o.value !== RbEnums.Map.StationContextMenu.Remove || station.hasLocation));
		if (!this.isGolfSite) {
			const stopOption = options.find(opt => opt.value === RbEnums.Map.StationContextMenu.Stop);
			if (stopOption != null) {
				stopOption.text = this.translate.instant('STRINGS.ADVANCE');
				stopOption.value = RbEnums.Map.StationContextMenu.Advance;
				stopOption.icon = 'mdi mdi-fast-forward';
			}
			if (!station?.master) {
				options = options.filter(o => o.value !== RbEnums.Map.StationContextMenu.Pause && o.value !== RbEnums.Map.StationContextMenu.Resume);
			} else {
				options = options.filter(o => o.value === this.masterValveOptions(o.value));
				const controller = this.mapInfo.getCommercialControllerById(station.satelliteId);
				station.stationValveType = controller.stationValveType.find(s => s.valveType === station.valveType);
				station.flowZone = controller.flowZone.find(f => f.id === station.flowZoneId);
				station.iqNetType = controller.iqNetType;
			}
		}
		return {
			stationId: station.id,
			station: station,
			mapInfo: this.mapInfo,
			menuOptions: options,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'station.png',
			icon: 'station',
			title: station.name,
			description: station.notes,
			editOption: RbEnums.Map.StationContextMenu.Edit,
			removeOption: station.latitude ? RbEnums.Map.StationContextMenu.Remove : null,
			areasStyleSetting: areasStyleSetting ?? new AreaUiSettings()
		};
	}

	areaOptions(holeId: number, area: Area, geoItem: GeoItem, menuLocationX: number, menuLocationY: number,
				useFullMenu: boolean, includeFind: boolean, mapMarker: Polygon): AreaOptions {
		const areaHasStations = this.mapInfo.stationsForHoleAndArea(holeId, area.id).length > 0;
		const hasGeoItems = this.mapInfo.getGeoItemsForArea(holeId, area.id).length > 0;
		const options = !useFullMenu ? this._areaOptions.filter(o => o.value === RbEnums.Map.AreaContextMenu.FindOnMap && hasGeoItems) :
			this._areaOptions.filter(o =>
				(o.value !== RbEnums.Map.AreaContextMenu.AddToMap || (includeFind && !hasGeoItems)) &&
				(o.value !== RbEnums.Map.AreaContextMenu.FindOnMap || (includeFind && hasGeoItems)) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Start || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Stop || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Pause || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Resume || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Advance || areaHasStations) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Edit || useFullMenu) &&
				(o.value !== RbEnums.Map.AreaContextMenu.EditShape || (useFullMenu && mapMarker != null && !(<any>mapMarker).editing.enabled())) &&
				(o.value !== RbEnums.Map.AreaContextMenu.ViewShape || (useFullMenu && mapMarker != null && (<any>mapMarker).editing.enabled())) &&
				(o.value !== RbEnums.Map.AreaContextMenu.CalculateArea || (useFullMenu && hasGeoItems)) &&
				(o.value !== RbEnums.Map.AreaContextMenu.Remove || geoItem != null));

		const hole = this.mapInfo.holes.find(h => h.id === holeId);
		return {
			holeId: holeId,
			area: area,
			geoItem: geoItem,
			mapInfo: this.mapInfo,
			menuOptions: options,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'location_colored.png',
			icon: 'mdi mdi-vector-square text-green',
			title: `${hole.name} / ${area.name}`,
			editOption: RbEnums.Map.AreaContextMenu.Edit,
			removeOption: hasGeoItems ? RbEnums.Map.AreaContextMenu.Remove : null
		};
	}

	stationAreaOptions(stationId: number, geoGroup: GeoGroup, geoItem: GeoItem, menuLocationX: number, menuLocationY: number,
		useFullMenu: boolean, includeFind: boolean, mapMarker: Polygon): StationAreaOptions {
		const options = !useFullMenu ? this._stationAreaOptions.filter(o => !!geoItem) :
			this._stationAreaOptions.filter(o =>
				(o.value !== RbEnums.Map.StationAreaContextMenu.EditShape || (useFullMenu && mapMarker != null && !(<any>mapMarker).editing.enabled())) &&
				(o.value !== RbEnums.Map.StationAreaContextMenu.ViewShape || (useFullMenu && mapMarker != null && (<any>mapMarker).editing.enabled())) &&
				(o.value !== RbEnums.Map.StationAreaContextMenu.CalculateArea || (useFullMenu && !!geoItem)));

		const station = this.mapInfo.stationForArea(stationId);
		return {
			stationId,
			stationName: station?.name,
			geoGroup: geoGroup,
			geoItem: geoItem,
			mapInfo: this.mapInfo,
			menuOptions: options,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'location_colored.png',
			icon: 'mdi mdi-vector-square text-green',
			title: this.translate.instant('STRINGS.STATION_AREA_TITLE', { stationName: station?.name}),
			editOption: null,
			removeOption: geoItem.multiPoint ? RbEnums.Map.StationAreaContextMenu.Remove : null,
			fromStationArea: true
		};
	}

	holeOptions(hole: Area, menuLocationX: number, menuLocationY: number,
				useFullMenu: boolean = false, includeFind: boolean = false): HoleOptions {
		const holeHasStations = this.mapInfo.stationsForHole(hole.id).length > 0;
		const geoItem = this.mapInfo.getGeoItemForHole(hole.id);
		const options = !useFullMenu ? this._holeOptions.filter(o => o.value === RbEnums.Map.HoleContextMenu.FindOnMap && geoItem != null) :
			this._holeOptions.filter(o =>
				(o.value !== RbEnums.Map.HoleContextMenu.AddToMap || (includeFind && geoItem == null)) &&
				(o.value !== RbEnums.Map.HoleContextMenu.FindOnMap || (includeFind && geoItem != null)) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Start || holeHasStations) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Stop || holeHasStations) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Pause || holeHasStations) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Resume || holeHasStations) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Edit || useFullMenu) &&
				(o.value !== RbEnums.Map.HoleContextMenu.Remove || geoItem != null));
		return {
			hole: hole,
			mapInfo: this.mapInfo,
			menuOptions: options,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'flag_colored.png',
			icon: 'mdi mdi-golf text-yellow',
			title: hole.name,
			editOption: RbEnums.Map.HoleContextMenu.Edit,
			removeOption: geoItem != null ? RbEnums.Map.HoleContextMenu.Remove : null
		};
	}

	controllerOptions(controller: ControllerListItem, menuLocationX: number, menuLocationY: number,
					useFullMenu: boolean = false, includeFind: boolean = false): ControllerOptions {
		const hasCoordinates = controller.latitude != null && controller.longitude != null;
		const options = !useFullMenu ? this._controllerOptions.filter(o => o.value === RbEnums.Map.ControllerContextMenu.FindOnMap &&
			includeFind && hasCoordinates) :
			this._controllerOptions.filter(o =>
				(o.value !== RbEnums.Map.ControllerContextMenu.AddToMap || (includeFind && !hasCoordinates)) &&
				(o.value !== RbEnums.Map.ControllerContextMenu.FindOnMap || (includeFind && hasCoordinates)) &&
				(o.value !== RbEnums.Map.ControllerContextMenu.Edit || useFullMenu) &&
				(o.value !== RbEnums.Map.ControllerContextMenu.Remove || hasCoordinates));
		return {
			controller: controller,
			mapInfo: this.mapInfo,
			menuOptions: options,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'controller_icon.jpg',
			title: controller.name,
			description: controller.description,
			editOption: RbEnums.Map.ControllerContextMenu.Edit,
			removeOption: controller.latitude ? RbEnums.Map.ControllerContextMenu.Remove : null
		};
	}

	siteOptions(site: Site, menuLocationX: number, menuLocationY: number,
		useFullMenu: boolean = false, includeFind: boolean = false): SiteOptions
		{
		const hasCoordinates = site.latitude != null && site.longitude != null;
		const options = !useFullMenu ? this._siteOptions.filter(o => o.value === RbEnums.Map.SiteContextMenu.Edit && includeFind && hasCoordinates) :
			this._siteOptions.filter(o => (o.value !== RbEnums.Map.SiteContextMenu.Edit || useFullMenu));
		return {
			site: site,
			menuOptions: options,
			mapInfo: this.mapInfo,
			menuPosition: { x: menuLocationX, y: menuLocationY },
			image: 'rb-marker2-150.png',
			icon: null,
			title: site.name,
			editOption: RbEnums.Map.SiteContextMenu.Edit,
			removeOption: null
		};
	}

	isValidSensorOption(sensorOption: any, sensor: Sensor | SensorListItem,
		useFullMenu = false, includeFind = false): boolean {
			if (useFullMenu) {
				return (sensorOption.value !== RbEnums.Map.SensorContextMenu.AddToMap || (includeFind && !sensor.hasLocation)) &&
					(sensorOption.value !== RbEnums.Map.SensorContextMenu.FindOnMap || (includeFind && sensor.hasLocation)) &&
					(sensorOption.value !== RbEnums.Map.SensorContextMenu.Edit || useFullMenu) &&
					(sensorOption.value !== RbEnums.Map.SensorContextMenu.Remove || sensor.hasLocation);
			}

			return sensorOption.value === RbEnums.Map.SensorContextMenu.FindOnMap &&
				includeFind && sensor.hasLocation;
	}
}

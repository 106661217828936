import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Area } from './models/area.model';
import { AreaApiService } from './area-api.service';
import { AuthManagerService } from '../auth/auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { HoleGroup } from './models/holeGroup.model';
import { Injectable } from '@angular/core';
import { RbUtils } from '../../common/utils/_rb.utils';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { Subarea } from './models/subarea.model';
import { TranslateService } from '@ngx-translate/core';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@Injectable({
	providedIn: 'root'
})
export class AreaManagerService extends ServiceManagerBase {

	// Subjects
	areaChange = new Subject<Area>();
	subAreaChange = new Subject();

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private areaApiService: AreaApiService,
				protected broadcastService: BroadcastService,
				private translate: TranslateService) {

		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
		this.areaApiService.clearCache();
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	deleteAreas(areaIds: number[]): Observable<null> {
		return this.areaApiService.deleteAreas(areaIds);
	}

	deleteSubareas(ids: number[]): Observable<null> {
		return this.areaApiService.deleteSubareas(ids);
	}

	/**
	 * Determine whether the indicated name is unique. Area names must be unique in the same level,
	 * across golf/non-golf, but can be duplicated on different sites.
	 * @param areaLevel - level 2 (hole) or level 3 (area)
	 * @param id - ID of the existing area, so we don't reject a name because we're finding ourself
	 * in the database.
	 * @param name - string name for the area or new area
	 * @param siteId - site/course ID
	 */
	getNameUniqueness(areaLevel: number, id: number, name: string, siteId: number): Observable<UniquenessResponse> {
		return this.areaApiService.getNameUniqueness(areaLevel, id, name, siteId);
	}

	getSubareaNameUniqueness(id: number, name: string, areaId: number): Observable<UniquenessResponse> {
		return this.areaApiService.getSubareaNameUniqueness(id, name, areaId);
	}

	getShortNameUniqueness(areaLevel: number, id: number, shortName: string, siteId: number): Observable<UniquenessResponse> {
		return this.areaApiService.getShortNameUniqueness(areaLevel, id, shortName, siteId);
	}

	getNumberUniqueness(areaLevel: number, id: number, areaNumber: number, siteId: number, isGolfArea: boolean)
		: Observable<UniquenessResponse> {
		return this.areaApiService.getNumberUniqueness(areaLevel, id, areaNumber, siteId, isGolfArea);
	}

	/**
	 * Get all of the Areas in the whole golf company from CoreApi.
	 * @param includeSubAreas - boolean? set to true to force retrieval of the list of SubAreas for each Area,
	 * otherwise they may not be included in the result
	 * @param bypassCache - boolean? set to true to assure that API level caching will be bypassed and the values
	 * will be requested from CoreApi.
	 * @returns Observable<Area[]> containing the Area list
	 */
	getAllAreas(includeSubAreas?: boolean, bypassCache?: boolean): Observable<Area[]> {
		return this.areaApiService.getAreas(null, includeSubAreas, bypassCache);
	}

	getAreas(siteId: number, includeSubareas: boolean = false): Observable<Area[]> {
		return this.areaApiService.getAreas(siteId, includeSubareas);
	}

	getArea(areaId: number): Observable<Area> {
		return this.areaApiService.getArea(areaId);
	}
	getSubareasByAreaId(areaId: number): Observable<Subarea[]> {
		return this.areaApiService.getSubareasByAreaId(areaId);
	}

	getSubarea(id: number): Observable<Subarea> {
		return this.areaApiService.getSubarea(id);
	}

	/**
	 * Create a new area in a given level.
	 * @param updateData - name, shortName?
	 * @param siteId
	 * @param level - level 2 = hole, level 3 = area for golf, level 2 = landscape type, level 3 = sprinkler type
	 * for commercial
	 * @param areaIsGolf - false for level 2 and commercial level 3. true for a golf area and false for non-golf
	 * in golf.
	 */
	createArea(updateData: any, siteId: number, level: number, areaIsGolf: boolean): Observable<Area> {
		const addArea = {
			name: updateData.name,
			isExclusive: true,
			level: level,
			siteId: siteId,
			isGolfArea: areaIsGolf,
		};
		if (updateData.shortName != null) addArea['shortName'] = updateData.shortName;

		return this.areaApiService.createArea(addArea);
	}

	createSubarea(updateData: any, areaId: number): Observable<Subarea> {
		const subarea = {
			name: updateData.name,
			areaId: areaId,
			defaultRunTimeLong: updateData.defaultRunTimeLong,
			defaultCycleTimeLong: updateData.defaultCycleTimeLong,
			defaultSoakTimeLong: updateData.defaultSoakTimeLong,
			adjustment: updateData.adjustment,
			defaultWeatherSourceId: updateData.defaultWeatherSourceId,
			sprinklerCategory: updateData.sprinklerCategory,
			pressure: updateData.pressure,
			nozzleId: updateData.nozzleId,
			arc: updateData.arc,
			headSpacing: updateData.headSpacing,
			headRadius: updateData.headRadius,
			rowRadius: updateData.rowRadius,
			distUniformityFinal: updateData.distUniformityFinal,
			slope: updateData.slope,
			usePrecRateCalc: updateData.usePrecRateCalc,
			precRateFinal: updateData.precRateFinal,
			flowRate: updateData.flowRate,
		};

		return this.areaApiService.createSubarea(subarea)
		.pipe(tap(() => {
			this.getAllHoleSections(true);
		}));
	}

	updateArea(areaId: number, updateData: any, area: Area): Observable<null> {
		const uiSettings = updateData.uiSettings;
		delete updateData.uiSettings;
		return this.areaApiService.updateArea(areaId, updateData).pipe(tap(() => {
			if (uiSettings != null) updateData.uiSettings = uiSettings;
			for (const key of Object.keys(updateData)) {
				area[key] = updateData[key];
			}
			this.areaChange.next(area);
		}));
	}

	updateSubarea(id: number, updateData: any): Observable<null>  {
		return this.areaApiService.updateSubarea(id, updateData)
			.pipe(tap(() => {
				if (updateData.hasOwnProperty('defaultWeatherSourceId'))
					this.subAreaChange.next(null);
			}));
	}

	getHoles(siteId: number): Observable<Area[]> {
		return this.getAreasWithLevel(siteId, 2)
			.pipe(map(list => list.sort((a, b) => (a.number - b.number))));
	}

	getHoleSections(siteId: number, includeSubareas: boolean = false): Observable<Area[]> {
		return (RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType)) ?
			this.getAreasWithLevel(siteId, 3, includeSubareas).pipe(map(list => list.sort((a, b) => (a.number - b.number)))) :
			this.getAreasWithLevel(siteId, 3, includeSubareas).pipe(map(list => list.sort((a, b) => a.name.localeCompare(b.name))));
	}

	getAllHoles(bypassCache = false): Observable<Area[]> {
		return this.areaApiService.getAllHoles(bypassCache).pipe(map(response => {
			return response.isFromCache ? response.value.slice() : response.value;
		}));
	}

	getHole(holeId: number) {
		return this.getAllHoles().pipe(map((holes: Area[]) => holes.find(h => h.id === holeId)));
	}

	getAllHoleSections(bypassCache = false): Observable<Area[]> {
		return this.areaApiService.getAllHoleSections(bypassCache).pipe(map(response => {
			return response.isFromCache ? response.value.slice() : response.value;
		}));
	}

	getHoleSection(areaId: number) {
		return this.getAllHoleSections().pipe(map((holeSections: Area[]) => holeSections.find(hs => hs.id === areaId)));
	}

	getHoleSubareaSection(subAreaId: number) {
		return this.getAllHoleSections().pipe(map((holeSections: Area[]) => {
			const area = holeSections.find(hs => hs.subArea.some(sa => sa.id === subAreaId));
			return area == null ? area : area.subArea.find(sa => sa.id === subAreaId);
		}));
	}

	createGroupsFromHolesAndPlaces(holesAndPlaces: Area[]): HoleGroup[] {
		if (holesAndPlaces == null) return [];

		const holeGroups: HoleGroup[] = [];
		const allHoleNumbers = holesAndPlaces.filter(h => h.isGolfArea && h.level === 2).map(h  => h.number);

		const numHoleGroups = Math.ceil(Math.max.apply(Math, allHoleNumbers) / 9);
		for (let iGroup = 0; iGroup < numHoleGroups; iGroup++) {
			const minHoleNumber = iGroup * 9 + 1;
			const maxHoleNumber = (iGroup + 1) * 9;
			const areas = holesAndPlaces.filter(a => a.isGolfArea && a.number >= minHoleNumber && a.number <= maxHoleNumber)
				.sort((a1, a2) => a1.number - a2.number);
			if (areas.length === 0) continue;
			holeGroups.push(new HoleGroup(iGroup, areas, true, `${minHoleNumber}-${maxHoleNumber}`));
		}

		const places = holesAndPlaces.filter(h => !h.isGolfArea && h.level === 2);
		// RB-9563: "Other Areas" is untranslated
		// IF there is only one entry in places, rename the string by the translate-service
		// IF there are multiple entries in places, just use the places list from database
		// Note: in these multiple entries cases, we will have to hope users created their database based on primary language
		// so the database entry is translated correctly
		if (places.length === 1) {
			const mappedPlaces = places.map(place => {
				place.name = this.translate.instant('STRINGS.OTHER_AREAS');
				return place;
			});

			holeGroups.push(new HoleGroup(numHoleGroups, mappedPlaces, false, this.translate.instant('STRINGS.PLACES')));
		} else if (places.length > 1) {
			holeGroups.push(new HoleGroup(numHoleGroups, places, false, this.translate.instant('STRINGS.PLACES')));
		}

		return holeGroups;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getAreasWithLevel(siteId: number, level: number, includeSubareas: boolean = false): Observable<Area[]> {
		return this.areaApiService.getAreasWithLevel(siteId, level, includeSubareas);
	}
}

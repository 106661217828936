import { AppInjector } from "../../core/core.module";
import { AuthManagerService } from "../../api/auth/auth-manager-service";
import { BroadcastService } from "../../common/services/broadcast.service";
import { CalendarEventManagerService } from "../../api/calendar-event/calendar-event-manager.service";
import { CompanyManagerService } from "../../api/companies/company-manager.service";
import { CompanyStatusChange } from "./company-status-change.model";
import { ConnectDataPack } from "../../api/connect-data-pack/models/connect-data-pack.model";
import { ConnectDataPackManagerService } from "../../api/connect-data-pack/connect-data-pack-manager.service";
import { ControllerChange } from "./controller-change.model";
import { ControllerDryRunState } from "./controller-dry-run-state.model";
import { ControllerGetLogsState } from "./controller-get-logs-state.model";
import { ControllerGetPhysicalDataState } from "./controller-get-physical-data-state.model";
import { ControllerListItem } from "../../api/controllers/models/controller-list-item.model";
import { ControllerManagerService } from "../../api/controllers/controller-manager.service";
import { ControllerSyncState } from "./controller-sync-state.model";
import { ControlRequestError } from "../../api/manual-control/models/control-request-error.model";
import { ControlRequestState } from "../../api/manual-control/models/control-request-state.model";
import { DataPackRequestState } from "../../api/connect-data-pack/models/data-pack-request-state.model";
import { DetectModulesState } from "../../api/controllers/models/detect-modules-state.model";
import { DiagnosticData } from "../../api/manual-ops/models/diagnostic-data.model";
import { DryrunChange } from "./dryrun-change.model";
import { DryRunManagerService } from "../../api/dry-run/dry-run-manager.service";
import { EnvironmentService } from "../../common/services/environment.service";
import { FirmwareUpdateProgress } from "./firmware-update-progress.model";
import { FlowElementChange } from "./flow-element-change.model";
import { FlowElementManagerService } from "../../api/flow-elements/flow-element-manager.service";
import { FlowMonitoringStatusResult } from "../../api/controllers/models/flow-monitoring-status-result.model";
import { ICIGroupFaultFindingChange } from "./ici-group-fault-finding-change.model";
import { IcLongAddressPollData } from "../../api/manual-ops/models/ic-long-address-poll-data.model";
import { IcShortAddressPollData } from "../../api/manual-ops/models/ic-short-address-poll-data.model";
import { IcVoltagePollData } from "../../api/manual-ops/models/ic-voltage-poll-data.model";
import { IrrigationActivityService } from "../../api/irrigation-activity/irrigation-activity.service";
import { JobChange } from "./job-change.model";
import { ManualControlManagerService } from "../../api/manual-control/manual-control-manager.service";
import moment from "moment";
import { ProgramChange } from "./program-change.model";
import { QuickCheckData } from "../../api/manual-ops/models/quick-check-data.model";
import { RadioRelayChange } from "./radio-relay-change.model";
import { RasterTestProgress } from "./raster-test-progress.model";
import { RbConstants } from "../../common/constants/_rb.constants";
import { RbEnums } from "../../common/enumerations/_rb.enums";
import { RbUtils } from "../../common/utils/_rb.utils";
import { ScheduledReportChange } from "./scheduled-report-change.model";
import { ScheduledReportManagerService } from "../scheduled-reports/scheduled-report-manager.service";
import { SensorStatusChange } from "./sensor-status-change.model";
import { SiteManagerService } from "../../api/sites/site-manager.service";
import { SiteStatusChange } from "./site-status-change.model";
import { SoftwareUpdateManagerService } from "../../api/software-update/software-update-manager.service";
import { StationShortReport } from "../../api/manual-ops/models/station-short-report.model";
import { StationStatusChange } from "./station-status-change.model";
import { StationValveTypeChange } from "./station-valve-type-change.model";
import { SystemStatusService } from "../../common/services/system-status.service";
import { take } from "rxjs";
import { ToasterService } from "../../common/services/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { UniversalPingResultData } from "../../api/manual-ops/models/universal-ping-result-data.model";
import { WeatherSourceManagerService } from "../../api/weather-sources/weather-source-manager.service";
import { WeatherSourceStatusChange } from "./weather-source-status-change.model";

/**
 * BackendNotificationService acts as a base class for anything receiving notifications. For example, SignalRService inherits from
 * BackendNotificationService. This base class provides the basic dispatch operations needed for sending different message types to
 * subscribers in the UI. 
 */
export abstract class BackendNotificationService {
	protected selectedControllerId = -1;
	protected readonly onlyLogForSelectedController = false;
	protected SyncState = RbEnums.Common.ControllerSyncState;
	protected retry = 0;

	protected abstract get notificationServiceName(): string;

	// =========================================================================================================================================================
	// Properties - each uses AppInjector to build the desired type the first time it is called, then reuses that instance. This saves
	// us from having to dependency inject everything into the constructor which is problematic during startup when we are changing
	// implementation classes depending on execution environment.
	// =========================================================================================================================================================

	private _authManager: AuthManagerService;
	protected get authManager(): AuthManagerService {
		if (this._authManager == null) {
			this._authManager = AppInjector.get(AuthManagerService);
		}
		return this._authManager;
	}

	private _broadcast: BroadcastService;
	protected get broadcast(): BroadcastService {
		if (this._broadcast == null) {
			this._broadcast = AppInjector.get(BroadcastService);
		}
		return this._broadcast;
	}

	private _calendarEventManager: CalendarEventManagerService;
	protected get calendarEventManager(): CalendarEventManagerService {
		if (this._calendarEventManager == null) {
			this._calendarEventManager = AppInjector.get(CalendarEventManagerService);
		}
		return this._calendarEventManager;
	}

	private _companyManager: CompanyManagerService;
	protected get companyManager(): CompanyManagerService {
		if (this._companyManager == null) {
			this._companyManager = AppInjector.get(CompanyManagerService);
		}
		return this._companyManager;
	}

	private _connectDataPackManager: ConnectDataPackManagerService;
	protected get connectDataPackManager(): ConnectDataPackManagerService {
		if (this._connectDataPackManager == null) {
			this._connectDataPackManager = AppInjector.get(ConnectDataPackManagerService);
		}
		return this._connectDataPackManager;
	}

	private _controllerManager: ControllerManagerService;
	protected get controllerManager(): ControllerManagerService {
		if (this._controllerManager == null) {
			this._controllerManager = AppInjector.get(ControllerManagerService);
		}
		return this._controllerManager;
	}

	private _dryRunManger: DryRunManagerService;
	protected get dryRunManger(): DryRunManagerService {
		if (this._dryRunManger == null) {
			this._dryRunManger = AppInjector.get(DryRunManagerService);
		}
		return this._dryRunManger;
	}

	private _env: EnvironmentService;
	protected get env(): EnvironmentService {
		if (this._env == null) {
			this._env = AppInjector.get(EnvironmentService);
		}
		return this._env;
	}

	private _irrigationActivity: IrrigationActivityService;
	protected get irrigationActivity(): IrrigationActivityService {
		if (this._irrigationActivity == null) {
			this._irrigationActivity = AppInjector.get(IrrigationActivityService);
		}
		return this._irrigationActivity;
	}

	private _manualControlManager: ManualControlManagerService;
	protected get manualControlManager(): ManualControlManagerService {
		if (this._manualControlManager == null) {
			this._manualControlManager = AppInjector.get(ManualControlManagerService);
		}
		return this._manualControlManager;
	}

	private _siteManager: SiteManagerService;
	protected get siteManager(): SiteManagerService {
		if (this._siteManager == null) {
			this._siteManager = AppInjector.get(SiteManagerService);
		}
		return this._siteManager;
	}

	private _softwareUpdateManager: SoftwareUpdateManagerService;
	protected get softwareUpdateManager(): SoftwareUpdateManagerService {
		if (this._softwareUpdateManager == null) {
			this._softwareUpdateManager = AppInjector.get(SoftwareUpdateManagerService);
		}
		return this._softwareUpdateManager;
	}

	private _systemStatus: SystemStatusService;
	protected get systemStatus(): SystemStatusService {
		if (this._systemStatus == null) {
			this._systemStatus = AppInjector.get(SystemStatusService);
		}
		return this._systemStatus;
	}

	private _toaster: ToasterService;
	protected get toaster(): ToasterService {
		if (this._toaster == null) {
			this._toaster = AppInjector.get(ToasterService);
		}
		return this._toaster;
	}

	private _translate: TranslateService;
	protected get translate(): TranslateService {
		if (this._translate == null) {
			this._translate = AppInjector.get(TranslateService);
		}
		return this._translate;
	}

	private _weatherSourceManager: WeatherSourceManagerService;
	protected get weatherSourceManager(): WeatherSourceManagerService {
		if (this._weatherSourceManager == null) {
			this._weatherSourceManager = AppInjector.get(WeatherSourceManagerService);
		}
		return this._weatherSourceManager;
	}

	private _scheduledReportManager: ScheduledReportManagerService;
	protected get scheduledReportManager(): ScheduledReportManagerService {
		if (this._scheduledReportManager == null) {
			this._scheduledReportManager = AppInjector.get(ScheduledReportManagerService);
		}
		return this._scheduledReportManager;
	}

	// =========================================================================================================================================================
	// Public methods
	// =========================================================================================================================================================

	constructor() { }

	public abstract startConnection(): void;

	public abstract stopConnection();

	// =========================================================================================================================================================
	// Message handlers. These are called from each subclass when message of a given type is received
	// =========================================================================================================================================================

	/** ========================================================================================================================================================
	 * Method called by SignalR Hub when a background job has been queued. When this message is sent, there's a reasonable chance
	 * that the JobRequested message will be delayed because of throttling. The JobRequested message could occur immediately, but the
	 * UI should assume the user needs to know if "queued" is fired.
	 * ====================================================================================================================================================== */
	protected onJobQueued(changes: JobChange[]) {
		changes.forEach(c => {
			switch (c.changeType) {
				// The most-likely messages to fire "queued" will be get-logs, sync and reverse-sync. It can be
				// fired both by auto- background jobs as well as manually-triggered foreground jobs.
				case RbEnums.SignalR.JobType.GetEventLogs:
					this.onQueuedGetEventLogs(c);
					break;
				case RbEnums.SignalR.JobType.ReverseSynchronizeDevice:
					if (c.syncType === RbEnums.SignalR.SyncType.Modules) {
						this.onQueuedDetectModules(c);
					} else {
						// RbEnums.SignalR.SyncType.ReverseSync, we expect.
						this.onQueuedReverseSyncController(c);
					}
					break;
				case RbEnums.SignalR.JobType.Synchronize:
					this.onQueuedSyncController(c);
					break;
				case RbEnums.SignalR.JobType.UpdateFirmware:
					this.onQueuedFirmwareProgress(c);
					break;
				case RbEnums.SignalR.JobType.RasterTest:
					this.onQueuedRasterTestProgress(c);
					break;
				case RbEnums.SignalR.JobType.GetPhysicalData:
					this.onQueuedGetPhysicalDataLogs(c);
					break;
			}
		});
	}

	/** ========================================================================================================================================================
	 * Method called by SignalR Hub when a background job has been requested.
	 * Use this method to handle SignalR callbacks that resulted from actions taken in another client. E.g., The user clicked on a button
	 * in a different browser or device.
	 * NOTE: This method is 99.99% guaranteed to fire for a given Job Request.
	 * ====================================================================================================================================================== */
	protected onJobRequested(changes: JobChange[]) {
		changes.forEach(c => {
			switch (c.changeType) {
				case RbEnums.SignalR.JobType.GetEventLogs:
					this.onStartGetEventLogs(c);
					break;
				case RbEnums.SignalR.JobType.ManualConnect:
					this.onStartManualConnect(c);
					break;
				case RbEnums.SignalR.JobType.ReverseSynchronizeDevice:
					if (c.syncType === RbEnums.SignalR.SyncType.Modules) {
						this.onStartDetectModules(c);
					} else {
						// RbEnums.SignalR.SyncType.ReverseSync, we expect.
						this.onStartReverseSyncController(c);
					}
					break;
				case RbEnums.SignalR.JobType.StartProgram:
					this.broadcast.startProgramJobRequest.next(c);
					break;
				case RbEnums.SignalR.JobType.StartStation:
					this.broadcast.startStationJobRequest.next(c);
					break;
				case RbEnums.SignalR.JobType.Synchronize:
					this.onStartSyncController(c);
					break;
				case RbEnums.SignalR.JobType.RasterTest:
					this.onRasterTestProgress(c);
					break;
				case RbEnums.SignalR.JobType.TbosNetworkConfiguration:
					this.broadcast.tbosNetworkConfigurationRequest.next(c);
					break;
				case RbEnums.SignalR.JobType.TbosDiscovery:
					this.broadcast.tbosDiscoveryRequest.next(c);
					break;
				case RbEnums.SignalR.JobType.GetPhysicalData:
					this.onStartGetPhysicalData(c);
					break;
			}
		});
	}

	/** ========================================================================================================================================================
	 * Method called by SignalR Hub when a background job has been started.
	 * Use this method to handle SignalR callbacks that resulted from actions taken in another client. E.g., The user clicked on a button
	 * in a different browser or device.
	 * NOTE: This method may not always fire for a given Job Request. This method will not fire if the background process encounters an
	 *         error when initializing the background process. Look for and handle these types of errors in JobRequestCompleted.
	 * ====================================================================================================================================================== */
	protected onJobStart(changes: JobChange[]) {
		changes.forEach(c => {
			switch (c.changeType) {
				// Will occur as part of OverrideDial request or Sync request.
				case RbEnums.SignalR.JobType.OverrideDial:
					this.onStartOverrideDial(c);
					break;
				case RbEnums.SignalR.JobType.GetDataPacks:
					this.connectDataPackManager.connectDataPacksJobStart.next({ controllerId: c.satelliteId });
					this.onStartGetDataPacks(c);
					break;
				case RbEnums.SignalR.JobType.SetRainDelay:
					this.onStartSetRainDelay(c);
					break;
				case RbEnums.SignalR.JobType.OverrideSensor:
					this.onStartOverrideSensor(c);
					break;
				case RbEnums.SignalR.JobType.FlowMonitoringControl:
					this.onStartEnableFlowManager(c);
					break;
				case RbEnums.SignalR.JobType.OverrideTwoWirePath:
					this.onStartTwoWirePath(c);
					break;
				case RbEnums.SignalR.JobType.UpdateFirmware:
					this.onUpdateFirmwareProgress(c);
					break;
				case RbEnums.SignalR.JobType.UniversalDryRun:
					this.onUpdateDryRunProgress(c);
					break;
				case RbEnums.SignalR.JobType.AdvanceStation:
					this.broadcast.advanceStationJobRequest.next(c);
					break;
				case RbEnums.SignalR.JobType.RasterTest:
					this.onRasterTestProgress(c);
					break;
			}
		});
	}

	/** ========================================================================================================================================================
	 * Method called by SignalR Hub when a background job has completed.
	 * Use this method to update related redux store(s) and UI elements that are affected be an individual background job type completing. This will
	 * happen for Jobs that are part of a request that initiates multiple 'sub jobs'.
	 * ====================================================================================================================================================== */
	protected onJobFinish(changes: JobChange[]) {
		changes.forEach(c => {
			switch (c.changeType) {
				case RbEnums.SignalR.JobType.GetEventLogs:
					this.onGetEventLogsJobFinish(c);
					break;
				case RbEnums.SignalR.JobType.GetPhysicalData:
					switch (c.syncType) {
						case RbEnums.SignalR.SyncType.Modules:
							this.onDetectModulesComplete(c);
							break;
						case RbEnums.SignalR.SyncType.PhysicalData:
							this.onGetPhysicalDataJobFinished(c);
							break;
					}
					break;
				case RbEnums.SignalR.JobType.OverrideDial:
					this.onOverrideDialComplete(c);
					break;
				case RbEnums.SignalR.JobType.GetDataPacks:
					this.connectDataPackManager.connectDataPacksJobFinish.next({ controllerId: c.satelliteId });
					this.onGetDataPacksComplete(c);
					break;
				case RbEnums.SignalR.JobType.SetRainDelay:
					this.onSetRainDelayComplete(c);
					break;
				case RbEnums.SignalR.JobType.FlowMonitoringControl:
					this.onEnableFlowManagerComplete(c);
					break;
				case RbEnums.SignalR.JobType.UpdateFirmware:
					this.onUpdateFirmwareProgress(c, true);
					break;
				case RbEnums.SignalR.JobType.RasterTest:
					this.onRasterTestProgress(c, true);
					break;
			}
		});
	}

	/** ========================================================================================================================================================
	 * Method called by SignalR Hub when a Job Request has been completed.
	 * NOTE: This method is 99.99% guaranteed to fire when an API call that initiates a background process returns successfully. Use this
	 *         method as a safety net for resetting the UI if JobStart/JobFinish methods are not received. This can happen if a job throws
	 *         an error prior to a JobStart notification being received.
	 * ====================================================================================================================================================== */
	protected onJobRequestCompleted(changes: JobChange[]) {
		changes.forEach(c => {
			switch (c.changeType) {
				case RbEnums.SignalR.JobType.CloseMasterValves:
					this.onCloseMasterValvesComplete(c);
					break;
				case RbEnums.SignalR.JobType.GetEventLogs:
					this.onGetEventLogsComplete(c);
					break;
				case RbEnums.SignalR.JobType.GetFlowMonitoringRate:
					// This JobType is associated with the request to Clear Flow Logs.
					this.onClearFlowLogsComplete(c);
					break;
				case RbEnums.SignalR.JobType.GetPhysicalData:
					this.onGetPhysicalDataComplete(c);
					break;
				case RbEnums.SignalR.JobType.ManualConnect:
					this.onManualConnectComplete(c);
					break;
				case RbEnums.SignalR.JobType.ManualMVWaterWindow:
					this.onSetManualWaterWindowComplete(c);
					break;
				case RbEnums.SignalR.JobType.OverrideDial:
					if (c.errorMessage) {
						// RB-13264: this.showToast(`${this.translateService.instant('STRINGS.OVERRIDE_DIAL_POS_FAILED')} ${c.errorMessage}`);
						this.logNotificationError(`${this.translate.instant('STRINGS.OVERRIDE_DIAL_POS_FAILED')} ${c.errorMessage}`);
					}
					break;
				case RbEnums.SignalR.JobType.OverrideSensor:
					this.onOverrideSensorComplete(c);
					break;
				case RbEnums.SignalR.JobType.OverrideTwoWirePath:
					this.onOverrideTwoWireComplete(c);
					break;
				case RbEnums.SignalR.JobType.ReverseSynchronizeDevice:
					if (c.syncType === RbEnums.SignalR.SyncType.Modules) {
						this.onDetectModulesComplete(c);
					} else {
						this.onReverseSyncControllerComplete(c);
					}
					break;
				case RbEnums.SignalR.JobType.StartLxdDecoderTest:
					this.broadcast.startLxdDecoderTestJobRequestCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.StartProgram:
					this.broadcast.startProgramJobRequestCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.StartStation:
					this.broadcast.startStationJobRequestCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.TbosManualCommand:
					this.broadcast.tbosManualCommandJobRequestCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.Synchronize:
					this.onSyncControllerComplete(c);
					break;
				case RbEnums.SignalR.JobType.UpdateFirmware:
					this.onUpdateFirmwareProgress(c, true);
					break;
				case RbEnums.SignalR.JobType.GetFlowMonitoringStatus:
					this.onFlowMonitoringStatusComplete(c);
					break;
				case RbEnums.SignalR.JobType.CancelAllIrrigationQueue:
					this.onCancelAllIrrigation(c);
					break;
				case RbEnums.SignalR.JobType.RasterTest:
					this.onRasterTestProgress(c, true);
					this.broadcast.rasterTestRequestComplete.next(c);
					break;
				case RbEnums.SignalR.JobType.GetWeatherSensorStatus:
					if (c.errorMessage !== null) { this.broadcast.getWeatherSensorStatusFailed.next(c.errorMessage); }
					this.broadcast.getWeatherSensorStatusCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.Get2WireData:
					if (c.errorMessage !== null) { this.broadcast.get2WireDataFailed.next(c.errorMessage); }
					break;
				case RbEnums.SignalR.JobType.GetMasterValveStatus:
					if (c.errorMessage !== null) { this.broadcast.getMasterValveStatusFailed.next(c.errorMessage); }
					this.broadcast.getMasterValveStatusCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.LxivmTwoWireLineSurvey:
					if (c.errorMessage !== null) { this.broadcast.getUniversal2WireLineSurveyFailed.next(c.errorMessage); }
					break;
				case RbEnums.SignalR.JobType.Lxivm2WireDeviceResponseStatuses:
					if (c.errorMessage !== null) { this.broadcast.getUniversalDeviceResponseStatusesFailed.next(c.errorMessage); }
					break;
				case RbEnums.SignalR.JobType.Lxivm2WireDevicePaths:
					if (c.errorMessage !== null) { this.broadcast.getUniversal2WireDevicePathsFailed.next(c.errorMessage); }
					break;
				case RbEnums.SignalR.JobType.UniversalTwoWireGetDiagnosticsSegmentInfo:
					if (c.errorMessage !== null) { this.broadcast.getDiagnosticsSegmentInfoFailed.next(c.errorMessage); }
					break;
				case RbEnums.SignalR.JobType.UniversalTwoWireDiagnosticsAcCurrentTest:
					this.broadcast.universalTwoWireDiagnosticsAcCurrentTestCompleted.next(c.errorMessage);
					break;
				case RbEnums.SignalR.JobType.GetDataPacks:
					this.broadcast.getDataPacksRequestComplete.next(c.errorMessage);
					break;
				case RbEnums.SignalR.JobType.AdvanceStation:
					this.broadcast.advanceStationJobRequestCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.GetShortReport:
					this.broadcast.getShortReportCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.Ping:
					this.broadcast.pingRequestCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.InitiateLearnedFlow:
					this.broadcast.initiateLearnedFlowCompleted.next(c);
					break;
					
				case RbEnums.SignalR.JobType.TbosNetworkConfiguration:
					this.broadcast.tbosNetworkConfigurationRequestCompleted.next(c);
					break;
				case RbEnums.SignalR.JobType.TbosDiscovery:
					this.broadcast.tbosDiscoveryRequestCompleted.next(c);
					break;
			}
		});
	}

	// =========================================================================================================================================================
	// Individual SignalR Callback Helper Methods
	// =========================================================================================================================================================

	protected onIrrigationEngineStatusChange(changes: CompanyStatusChange[]) {
		changes.forEach(c => {
			switch (c.changeType) {
				case RbEnums.SignalR.JobType.LogsCountChanged:
					this.companyManager.logsCountChange.next(null);
					this.companyManager.companyStatusChange.next(c.companyCounts);
					break;
				case RbEnums.SignalR.JobType.CompanyStatusChange:
					this.companyManager.companyStatusChange.next(c.companyCounts);
					break;
				case RbEnums.SignalR.JobType.Updated: // If Water Budget gets Update, it fires Updated changeType
					this.systemStatus.companyStatusUpdated.next(null);
					break;
				case RbEnums.SignalR.JobType.SatelliteConnectionChanged:
					this.companyManager.connectedControllersCountChange.next(c.connectedControllers);
					break;
				case RbEnums.SignalR.JobType.IrrigationCancelled:
					this.handleIrrigationCancelled(c);
					break;
				case RbEnums.SignalR.JobType.SystemModePaused_NotIrrigating:
					this.systemStatus.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModePaused_NotIrrigating;
					break;
				case RbEnums.SignalR.JobType.SystemModePaused_Irrigating:
					this.systemStatus.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModePaused_Irrigating;
					break;
				case RbEnums.SignalR.JobType.IrrigationPaused:
					this.systemStatus.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.IrrigationPaused;
					break;
				case RbEnums.SignalR.JobType.IrrigationResumed:
					this.systemStatus.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.IrrigationResumed;
					break;
				case RbEnums.SignalR.JobType.SystemModeAuto_Irrigating:
					this.systemStatus.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_Irrigating;
					break;
				case RbEnums.SignalR.JobType.SystemModeOff_Irrigating:
					this.systemStatus.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModeOff_Irrigating;
					break;
				case RbEnums.SignalR.JobType.SystemModeOff_NotIrrigating:
					this.systemStatus.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModeOff_NotIrrigating;
					break;
				case RbEnums.SignalR.JobType.SystemModeAuto_NotIrrigating:
					this.systemStatus.lastReportedFieldActivity = RbEnums.Common.IrrigationEngineChangeType.SystemModeAuto_NotIrrigating;
					break;
				case RbEnums.SignalR.JobType.TheoreticalFlowRateTotalChanged:
					this.systemStatus.theoreticalFlowRateTotalChange.next(c.dValue || 0);
					break;
				case RbEnums.SignalR.JobType.GroupFaultFindingModeDataChange:
					this.broadcast.groupFaultFindingChange.next(new ICIGroupFaultFindingChange(c.diagnosticData));
					break;
				case RbEnums.SignalR.JobType.NewSoftwareVersionAvailable:
					this.handleNewSoftwareAvailable(c);
					break;
				case RbEnums.SignalR.JobType.VoltagePollDataChange:
				case RbEnums.SignalR.JobType.QuickCheckDataChange:
				case RbEnums.SignalR.JobType.ShortAddressPollDataChange:
				case RbEnums.SignalR.JobType.LongAddressPollDataChange:
					this.handleDiagnosticData(c.changeType, c.diagnosticData);
					break;

				case RbEnums.SignalR.JobType.NewAccessRequest:
					this.broadcast.newAccessRequest.next(c.userName);
					break;
				case RbEnums.SignalR.JobType.AccessRequestCanceled:
					this.broadcast.accessRequestCanceled.next(c.userName);
					break;
				case RbEnums.SignalR.JobType.UnlockAll:
					this.broadcast.unlockAll.next(null);
					break;
			}
		});

		// Send the changes to the company manager service where it can notify interested clients, invalidate the cache,
		// etc.
		const companyManager = AppInjector.get(CompanyManagerService);
		companyManager.statusChange(changes);
	}

	protected handleIrrigationCancelled(change: CompanyStatusChange) {
		console.log(this.notificationServiceName + ': ' + change);
		this.broadcast.irrigationCancelled.next(null);
	}

	protected onSatelliteStatusChange(changes: ControllerChange[]) {
		changes.forEach(c => {
			this.controllerManager.updateControllerItem(c);
			switch (c.changeType) {
				case RbEnums.SignalR.JobType.ConnectionStarted:
					this.broadcast.connectionStarted.next(c.satelliteId);
					break;
				case RbEnums.SignalR.JobType.ConnectionStopped:
					this.connectDataPackManager.deleteConnectDataPack(c.satelliteId);
					this.manualControlManager.clearControlItem(c.satelliteId);
					this.broadcast.connectionStopped.next(c.satelliteId);
					this.onInterfaceStatusChange(c.satelliteId);
					break;
				case RbEnums.SignalR.SatelliteChangeType.Updated:
					this.broadcast.controllerUpdated.next(c);
					this.sendSyncStateChange(c.satelliteId, c.frontPanelSyncState, false);
					break;
				case RbEnums.SignalR.SatelliteChangeType.Added:
					this.broadcast.controllerAdded.next(c);
					break;
				case RbEnums.SignalR.SatelliteChangeType.Deleted:
					this.broadcast.controllerUpdated.next(c);
					break;
				case RbEnums.SignalR.SatelliteChangeType.SyncStateChanged:
					this.sendSyncStateChange(c.satelliteId, c.frontPanelSyncState, false);
					break;
				case RbEnums.SignalR.SatelliteChangeType.ActualFlowMonitoringRate:
					this.broadcast.controllerActualAggregateFlowRateChange.next(c);
					break;
				case RbEnums.SignalR.JobType.ConnectionFailed:
					this.broadcast.connectionFailed.next(c.satelliteId);
					this.onInterfaceStatusChange(c.satelliteId);
					break;
				case RbEnums.SignalR.JobType.ConnectionCompleted:
					this.systemStatus.lastReportedSystemStatus = RbEnums.Common.SystemStatus.NoFieldActivity;
					this.broadcast.connectionCompleted.next(c.satelliteId);
					break;
				case RbEnums.SignalR.SatelliteChangeType.FlowMonitoringStatusChanged:
					this.onFlowMonitoringStatusReceived(c);
					break;
				case RbEnums.SignalR.SatelliteChangeType.WeatherSensorStatusChanged:
					this.broadcast.weatherSensorStatusChange.next(c.weatherSensorStatus);
					break;
				case RbEnums.SignalR.SatelliteChangeType.TwoWireDataChanged:
					this.broadcast.twoWireDataChange.next(c.twoWireData);
					break;
				case RbEnums.SignalR.SatelliteChangeType.MasterValveStatusChanged:
					this.broadcast.masterValveStatusChange.next(c.masterValveStatus);
					break;
				case RbEnums.SignalR.SatelliteChangeType.StationShortReportChanged:
					this.broadcast.stationShortReportChange.next(new StationShortReport(c.stationShortReport, c.satelliteId));
					break;
				case RbEnums.SignalR.SatelliteChangeType.UniversalTwoWireDataChanged:
					this.broadcast.universalTwoWireDataChanged.next(c.universalTwoWireData);
					break;
				case RbEnums.SignalR.SatelliteChangeType.UniversalDeviceResponseStatusesChanged:
					this.broadcast.universalDeviceResponseStatusesChanged.next(c.universalDeviceResponseStatuses);
					break;
				case RbEnums.SignalR.SatelliteChangeType.Universal2WireDevicePathsChanged:
					this.broadcast.universal2WireDevicePathsChanged.next(c.universal2WireDevicePaths);
					break;
				case RbEnums.SignalR.SatelliteChangeType.DiagnosticsSegmentInfoChanged:
					this.broadcast.diagnosticsSegmentInfoChanged.next(c.diagnosticsSegmentInfo);
					break;
				case RbEnums.SignalR.SatelliteChangeType.UniversalMasterValveStatusChanged:
					this.broadcast.universalMasterValveStatusChange.next(c.universalMasterValveStatus);
					break;
				case RbEnums.SignalR.SatelliteChangeType.UniversalWeatherSensorStatusChanged:
					this.broadcast.universalWeatherSensorStatusChange.next(c.universalWeatherSensorStatus);
					break;
				case RbEnums.SignalR.SatelliteChangeType.PingResult:
					if (c.twoWireData != null) {
						this.broadcast.pingResult.next(c.twoWireData);
					} else {
						this.broadcast.universalPingResult.next(new UniversalPingResultData(c.universalPingResultData, c.satelliteId, c.changeDateTime));
					}
					break;
				case RbEnums.SignalR.SatelliteChangeType.PingCommunicationFailed:
					this.broadcast.pingCommunicationFailed.next(c);
					break;
				case RbEnums.SignalR.SatelliteChangeType.HasLastSyncDifferenceChanged:
					this.broadcast.hasLastSyncDifferenceChanged.next(c);
					this.sendSyncStateChange(c.satelliteId, c.frontPanelSyncState, false);
					break;

				default:
					break;
			}
			if (c.connectDataPack) {
				const minutesSinceChange = moment.duration(moment(new Date()).diff(moment(c.changeDateTime))).asMinutes();
				if (minutesSinceChange >= RbConstants.Common.MaxAgeOfSatelliteChangeInMinutes) { return; }
				this.onConnectDataPackReceived(c);
			}
		});
	}

	protected onInterfaceStatusChange(satelliteId: number) {
		this.controllerManager.getControllerListItem(satelliteId)
			.pipe(take(1))
			.subscribe((cli: ControllerListItem) => {
				if (!cli) return;
				this.systemStatus.lastReportedSystemStatus = RbEnums.Common.SystemStatus.Failure;
			});
	}

	protected onProgramStatusChange(changes: ProgramChange[]) {
		const batchChange = changes.find(c => c.changeType === RbEnums.SignalR.ProgramStatusChangeType.ProgramGroupBatchUpdated);
		if (batchChange != null) {
			batchChange.programGroupId = batchChange.itemsChanged.ids[0];
			this.broadcast.programGroupsBatchUpdated.next(batchChange);
		}

		// Program group changes
		// RB-9460: We should also filter by programId == null, because addProgramActivity(c) on Program changes (not group)
		// will set that property, causing to misinterpreting Program as a ProgramGroup here.
		const programGroupChanges = changes.filter(c => c.programId == null && c.programGroupId != null);
		programGroupChanges.forEach(c => {
			this.broadcast.programGroupsUpdated.next(c);
			this.irrigationActivity.addProgramGroupActivity(c);
			this.systemStatus.setGolfProgramGroupStatus(c);
		});

		// Program changes
		const programChanges = changes.filter(c => c.programId != null);
		programChanges.forEach(c => {
			this.irrigationActivity.addProgramActivity(c);
			this.systemStatus.setGolfProgramStatus(c);
		});

		// RB-8985: Finally, check for the running update of program groups and programs. There should probably never be more than
		// one of these sent, as we only keep one in the hub and only send one every few moments, but we'll allow for something
		// to change in that pattern. We remove these before sending the programsUpdated notification, replacing with the
		// derived program and program group status updates created by buildProgramChangesFromProgramActiveUpdate().
		const activeProgramUpdates = changes.filter(c => c.changeType === RbEnums.SignalR.ProgramStatusChangeType.ProgramsActiveUpdate);
		activeProgramUpdates.forEach(c => {
			// Build a list of changes represented in the data. Note that we have to flag the changes as being the complete
			// set, so those programs and groups which are stopped (not included in the data set), will be marked inactive
			// by irrigationActivityService and systemStatusService.
			const groupAndProgramUpdates = this.buildProgramChangesFromProgramsActiveUpdate(c);
			// Enumerate the program group activity and send new notifications for the states.
			this.irrigationActivity.setProgramGroupActivity(c.changeDateTime, groupAndProgramUpdates.programGroupChanges);
			// Enumerate the program activity and send new notifications for the states.
			this.irrigationActivity.setProgramActivity(c.changeDateTime, groupAndProgramUpdates.programChanges);
			// Handle update to the golf system status information.
			this.systemStatus.setGolfGroupAndProgramStatuses(c.changeDateTime, groupAndProgramUpdates.programGroupChanges,
				groupAndProgramUpdates.programChanges);
			// Add the new programGroup and program changes to the master change list in enclosing scope. These items will be sent
			// in programsUpdated notification.
			changes.push(...groupAndProgramUpdates.programGroupChanges);
			changes.push(...groupAndProgramUpdates.programChanges);
		});

		// Filter any remaining ProgramRunningUpdate items in the list before sending the changes to programsUpdated notification.
		changes.filter(c => c.changeType !== RbEnums.SignalR.ProgramStatusChangeType.ProgramRunningUpdate);
		this.broadcast.programsUpdated.next(changes);
	}

	protected onRadioRelayStatusChange(changes: RadioRelayChange[]) {
		const batchChange = changes.find(c => c.changeType === RbEnums.SignalR.RadioRelayChangeType.Updated);
		if (batchChange != null) {
			batchChange.radioRelayId = batchChange.itemsChanged.ids[0];
			this.broadcast.radioRelaysUpdated.next(batchChange);
		}
	}

	protected onStationStatusChange(changes: StationStatusChange[]) {
		this.irrigationActivity.addStationsActivity(changes);
		changes.forEach(c => {
			// NOTE: THIS METHOD IS USED BY COMMERCIAL TOO! This method will properly update station information
			// 		 on the map if, for example, the station name is changed. Perhaps the method should be renamed.
			this.systemStatus.setStationStatus(c);
		});
		this.systemStatus.stationStatusUpdateComplete();
	}

	protected onStationValveTypeStatusChange(changes: StationValveTypeChange[]) {
		changes.forEach(c => {
			this.systemStatus.setStationValveTypeStatus(c);
		});
	}

	protected onSensorStatusChange(changes: SensorStatusChange[]) {
		this.irrigationActivity.addSensorsActivity(changes);
		changes.forEach(c => {
			this.systemStatus.setGolfSensorStatus(c);
		});
		this.systemStatus.sensorStatusUpdateComplete();
		this.broadcast.sensorsUpdated.next(changes);
	}

	protected onSiteStatusChange(changes: SiteStatusChange[]) {
		this.calendarEventManager.onCalendarEventChange(changes);
		let isChangedSitesList: boolean = false;
		changes.forEach(change => {
			// Send an update change, if appropriate for each change.
			if (change.changeType === RbEnums.SignalR.SiteStatusChangeType.Updated) {
				// Send the change with the list of ids, if present, and the patch data, if present.
				const ids = change.itemsChanged != null ? change.itemsChanged.ids : null;
				const patch = change.itemsChanged != null ? change.itemsChanged.patch : null;
				this.siteManager.sitesUpdate.next({siteIds: ids, data: patch});
			} else if (change.changeType === RbEnums.SignalR.SiteStatusChangeType.Added || 
					   change.changeType === RbEnums.SignalR.SiteStatusChangeType.Deleted) {
				this.siteManager.updateSitesListItemsFromCache(change.siteId, change);
				isChangedSitesList = true;
			}
		});
		if (isChangedSitesList) {
			this.broadcast.siteStatusChangeCompleted.next(null);
		}
	}

	protected onWeatherSourceStatusChange(changes: WeatherSourceStatusChange[]) {
		changes.forEach(c => {
			switch (c.changeType) {
				case RbEnums.SignalR.WeatherSourceStatusChangeType.Added:
					this.weatherSourceManager.weatherSourceAdded.next(c.weatherSourceId);
					break;
				case RbEnums.SignalR.WeatherSourceStatusChangeType.Deleted:
					this.weatherSourceManager.weatherSourceDeleted.next(c.weatherSourceId);
					break;
				case RbEnums.SignalR.WeatherSourceStatusChangeType.Updated:
					this.weatherSourceManager.weatherSourceUpdated.next(c.weatherSourceId);
					break;
				case RbEnums.SignalR.WeatherSourceStatusChangeType.WeatherSourceValue:
					// Send the weather source data change "raw" to any interested clients.
					this.weatherSourceManager.weatherSourceDataChange.next(c);
					break;
				default:
					break;
			}});
		this.weatherSourceManager.weatherSourcesChange.next(null);
	}

	protected onJobProgressStatus(changes: JobChange[]) {
		changes.forEach(c => {
			if (c.changeType === RbEnums.SignalR.JobType.UniversalDryRun) {
				this.onUpdateDryRunProgress(c);
			}
		});
	}

	protected onFlowElementStatusChange(changes: FlowElementChange[]) {
		// Notify the manager directly that something changed.
		const flowElementManager = AppInjector.get(FlowElementManagerService);
		flowElementManager.statusChange(changes);
	}

	protected onScheduledReportChangeType(changes: ScheduledReportChange[]) {
		changes.forEach(c => {
			switch (c.changeType) {
				case RbEnums.SignalR.ScheduledReportChangeType.Added:
				case RbEnums.SignalR.ScheduledReportChangeType.Deleted:
				case RbEnums.SignalR.ScheduledReportChangeType.Updated:
					this.scheduledReportManager.scheduledReportChange.next(c);
					break;
			}});
	}

	// =========================================================================================================================================================
	// Detect Modules Handlers - Begin
	// =========================================================================================================================================================

	// RB-7650: This message will be sent when the manual command to detect modules is queued, awaiting resources to run.
	// There's a small chance that this message will immediately be followed by the normal Requested/Started events, but
	// usually there will be a noticable delay.
	protected onQueuedDetectModules(change: JobChange) {
		this.broadcast.detectModulesStateChange.next(new DetectModulesState(change.satelliteId, true /* running */,
			null,
			true /* queued */));
	}

	protected onStartDetectModules(change: JobChange) {
		this.broadcast.detectModulesStateChange.next(new DetectModulesState(change.satelliteId, true));
	}

	protected onDetectModulesComplete(change: JobChange) {
		if (change.cancelled || change.errorMessage) {
			this.broadcast.detectModulesStateChange.next(new DetectModulesState(change.satelliteId, false, change.errorMessage));
			this.showToast(`${this.translate.instant('STRINGS.MODULE_DETECTION_FAILED')} ${change.errorMessage}`);
			return;
		}
		this.broadcast.detectModulesStateChange.next(new DetectModulesState(change.satelliteId, false));
	}

	// =========================================================================================================================================================
	// Detect Modules Handlers - End
	// =========================================================================================================================================================

	// =========================================================================================================================================================
	// Reverse Sync Controller Handlers - Begin
	// =========================================================================================================================================================

	protected onQueuedReverseSyncController(change: JobChange) {
		this.broadcast.syncStateChange.next(new ControllerSyncState(change.satelliteId, RbEnums.Common.ControllerSyncState.ReverseSyncing, true));
	}

	protected onStartReverseSyncController(change: JobChange) {
		this.broadcast.syncStateChange.next(new ControllerSyncState(change.satelliteId, RbEnums.Common.ControllerSyncState.ReverseSyncing));
	}

	protected onReverseSyncControllerComplete(change: JobChange) {
		if (change.cancelled || change.errorMessage) {
			// RB-13264: Don't show toast when logging this error.
			this.outputToastMessageForControllerFailure('STRINGS.CONTROLLER_REVERSE_SYNC_FAILED', change.errorMessage, change.satelliteId, 
				false);
			this.broadcast.syncStateChange.next(new ControllerSyncState(change.satelliteId, RbEnums.Common.ControllerSyncState.HasDifferences));
			return;
		}
		if (change.jobPhase === RbEnums.Common.JobPhase.RequestComplete) {
			this.sendSyncStateChange(change.satelliteId, RbEnums.SignalR.FrontPanelSyncState.Synchronized, true);
		}
		// RB-6602 - See note in OnSyncControllerComplete (below).
	}

	// =========================================================================================================================================================
	// Reverse Sync Controller Handlers - End
	// =========================================================================================================================================================

	// =========================================================================================================================================================
	// Sync Controller Handlers - Begin
	// =========================================================================================================================================================

	// RB-7650: Add a state for the controller: queued-for-sync. This message will normally be passed when the manual or
	// auto-sync operation is hitting a throttling limit, but it could occasionally be sent immediately before a normal
	// Request or Start message.
	protected onQueuedSyncController(change: JobChange) {
		// ????
		// this.manualControlManager.initOrAddToControllersDictionary(change.satelliteId);
		this.broadcast.syncStateChange.next(new ControllerSyncState(change.satelliteId, RbEnums.Common.ControllerSyncState.Syncing, true /* queued */));
	}

	protected onStartSyncController(change: JobChange) {
		this.manualControlManager.initOrAddToControllersDictionary(change.satelliteId);
		this.broadcast.syncStateChange.next(new ControllerSyncState(change.satelliteId, RbEnums.Common.ControllerSyncState.Syncing));
	}

	protected onSyncControllerComplete(change: JobChange) {
		if (change.cancelled || change.errorMessage) {
			// RB-13264: Don't show toast when logging this error.
			this.outputToastMessageForControllerFailure('STRINGS.CONTROLLER_SYNC_FAILED', change.errorMessage, change.satelliteId,
				false);
			this.broadcast.syncStateChange.next(new ControllerSyncState(change.satelliteId, RbEnums.Common.ControllerSyncState.SyncFailed));
			return;
		}

		this.sendSyncStateChange(change.satelliteId, RbEnums.SignalR.FrontPanelSyncState.Synchronized, true);
		// RB-6602 - With the creation of the SatelliteStatusChange event with a changeType of 'SyncStateChanged' we no longer broadcast the
		// 			 SyncStateChange event from this method. We were blindly assuming that if the JobChange passed into this method did not include an error
		// 			 that the sync was successful. This is wrong in the case where the controller is irrigating. In this case this method is fired with no
		// 			 error, but the sync operation was skipped (by design). It should be the case that we always receive a SatelliteStatusChange with
		// 			 changeType of SyncStateChanged at the end of a sync op just before this method is called; however, I may have seen a test scenario where
		// 			 that assumption failed - resulting in the sync animation continuing even though the sync op had completed. The ideal solution would be to
		// 			 pass in the controller sync state when this method is called as this method is always called in response to the guaranteed
		// 			 JobRequestCompleted with changeType of 'Synchronize'.
	}

	// =========================================================================================================================================================
	// Sync Controller Handlers - End
	// =========================================================================================================================================================

	// =========================================================================================================================================================
	// Manual Ops Handlers - Begin
	// =========================================================================================================================================================

	protected onStartManualConnect(change: JobChange) {
		this.manualControlManager.initOrAddToControllersDictionary(change.satelliteId);
		this.manualControlManager.isConnecting.next(change.satelliteId);
	}

	protected onStartOverrideDial(change: JobChange) {
		this.handleManualOpStart(change, RbEnums.SignalR.ManualControlProperty.IsAuto);
	}

	protected onOverrideDialComplete(change: JobChange) {
		this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.IsAuto, 'STRINGS.OVERRIDE_DIAL_POS_FAILED',
			false);
	}

	protected onStartOverrideSensor(change: JobChange) {
		this.handleManualOpStart(change, RbEnums.SignalR.ManualControlProperty.IsSensorActive);
	}

	protected onOverrideSensorComplete(change: JobChange) {
		this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.IsSensorActive, 'STRINGS.FAILED_TO_OVERRIDE_SENSOR',
			false);
	}

	protected onStartEnableFlowManager(change: JobChange) {
		this.handleManualOpStart(change, RbEnums.SignalR.ManualControlProperty.IsFloManagerEnabled);
		this.handleManualOpStart(change, RbEnums.SignalR.ManualControlProperty.isFloWatchEnabled);
	}

	protected onEnableFlowManagerComplete(change: JobChange) {
		this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.IsFloManagerEnabled, 'STRINGS.FAILED_TO_ENABLE_FLO_MANAGER',
			false);
		this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.isFloWatchEnabled, 'STRINGS.FAILED_TO_ENABLE_FLO_MANAGER',
			false);
	}

	protected onStartTwoWirePath(change: JobChange) {
		this.handleManualOpStart(change, RbEnums.SignalR.ManualControlProperty.IsTwoWirePathOn);
	}

	protected onOverrideTwoWireComplete(change: JobChange) {
		this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.IsTwoWirePathOn, 'STRINGS.FAILED_TO_OVERRIDE_TWO_WIRE_PATH',
			false);
	}

	protected onClearFlowLogsComplete(change: JobChange) {
		this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.IsClearingFlowLogs, 'STRINGS.FAILED_TO_CLEAR_FLOW_LOGS',
			false);
	}

	protected onStartSetRainDelay(change: JobChange) {
		this.handleManualOpStart(change, RbEnums.SignalR.ManualControlProperty.RainDelayDays);
	}

	protected onSetRainDelayComplete(change: JobChange) {
		this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.RainDelayDays, 'STRINGS.FAILED_TO_SET_RAIN_DELAY',
			false);
	}

	protected onSetManualWaterWindowComplete(change: JobChange) {
		this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.IsSettingWaterWindow, 'STRINGS.FAILED_TO_SET_MANUAL_WATER_WINDOW',
			false);
	}

	protected onCloseMasterValvesComplete(change: JobChange) {
		this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.IsClosingMasterValves, 'STRINGS.CLOSE_MASTER_VALVES_FAILED',
			false);
	}

	protected handleManualOpStart(change: JobChange, property: RbEnums.SignalR.ManualControlProperty) {
		// Turn on the updating flag for the property, but don't set a value in the control state.
		this.manualControlManager.setControlItemStatus(new ControlRequestState(change.satelliteId, property, true));
	}

	protected handleManualOpComplete(change: JobChange, property: RbEnums.SignalR.ManualControlProperty, toastHeader: string, isToastable: boolean) {
		if (change.cancelled || change.errorMessage) {
			this.outputToastMessageForControllerFailure(toastHeader, change.errorMessage, change.satelliteId, isToastable);
			const requestError = new ControlRequestError(change.satelliteId, property, change.errorMessage);
			this.manualControlManager.setControlItemError(requestError);
			return;
		}

		// Turn off the 'updating' flag, but don't set a value in the control state.
		const requestState = new ControlRequestState(change.satelliteId, property, false);
		this.manualControlManager.setControlItemStatus(requestState);
	}

	protected onQueuedFirmwareProgress(change: JobChange) {
		// RB-7708: Add queued status = true. Fire the firmware progress update, though, so we know that
		// something is happening.
		this.broadcast.firmwareUpdateProgressChange.next(new FirmwareUpdateProgress(change, false /* isCompleted */,
			true /* isQueued */));
	}

	protected onUpdateFirmwareProgress(change: JobChange, isCompleted = false) {
		if (!change.cancelled && change.errorMessage !== null) {
			this.showToast(`${this.translate.instant('STRINGS.FIRMWARE_UPDATE_FAILED')} ${change.errorMessage}`);
		}

		this.broadcast.firmwareUpdateProgressChange.next(new FirmwareUpdateProgress(change, isCompleted));
	}

	protected onQueuedRasterTestProgress(change: JobChange) {
		// Fire the raster test progress update, though, so we know that something is happening.
		this.broadcast.rasterTestProgressChange.next(new RasterTestProgress(change, false /* isCompleted */,
			true /* isQueued */));
	}

	protected onRasterTestProgress(change: JobChange, isCompleted = false) {
		if (change.cancelled || change.errorMessage) {
			this.showToast(`${this.translate.instant('STRINGS.RASTER_TEST_FAILED')} ${change.errorMessage}`);
		}

		this.broadcast.rasterTestProgressChange.next(new RasterTestProgress(change, isCompleted));
	}

	protected onUpdateDryRunProgress(change: JobChange) {
		this.broadcast.dryRunStateChange.next(new ControllerDryRunState(change.satelliteId, change.progress || 0));
	}

	protected onFlowMonitoringStatusReceived(change: ControllerChange) {
		const flowMonitoringStatusResult = new FlowMonitoringStatusResult(change.flowMonitoringStatusResult);
		this.controllerManager.updateFlowMonitoringStatus(change.satelliteId, flowMonitoringStatusResult);
	}

	private onDryRunResultReceived(changes: DryrunChange[]) {
		changes.forEach(c => {
			switch (c.changeType) {
				case RbEnums.SignalR.DryrunChangeType.DryRunCompleted:
					this.dryRunManger.updateDryrunResult({dryRunId: c.dryRunId, status: c.reasonCode, dryrunUUID: c.dryRunUUID});
					break;
				case RbEnums.SignalR.DryrunChangeType.HistoricalDryRunCompleted:
					this.dryRunManger.updateHistoricalDryrunResult({dryRunId: c.dryRunId, status: c.reasonCode, dryrunUUID: c.dryRunUUID});
					break;
				case RbEnums.SignalR.DryrunChangeType.DryRunInProgress:
					this.dryRunManger.updateDryrunProgress({dryRunId: c.dryRunId, progress: c.progress , dryrunUUID: c.dryRunUUID});
					break;
				case RbEnums.SignalR.DryrunChangeType.Added:
					this.dryRunManger.updateDryrunProgress({dryRunId: c.dryRunId, progress: c.progress , dryrunUUID: c.dryRunUUID});
					break;
				default:
					break;
			}
		});
	}

	protected onFlowMonitoringStatusComplete(change: JobChange) {
		if (change.cancelled || change.errorMessage) {
			// RB-13264: this.showToast(`${this.translateService.instant('STRINGS.FAILED_TO_GET_FLOW_MONITORING_STATUS')} ${change.errorMessage}`);
			this.logNotificationError(`${this.translate.instant('STRINGS.FAILED_TO_GET_FLOW_MONITORING_STATUS')} ${change.errorMessage}`);
			this.controllerManager.handleFlowMonitoringStatusError(change.satelliteId);
			return;
		}
	}

	// =========================================================================================================================================================
	// Manual Ops Handlers - End
	// =========================================================================================================================================================

	// =========================================================================================================================================================
	// Get Event Logs Handlers - Begin
	// =========================================================================================================================================================

	// RB-7650: Called when GetEventLogs JobQueued event is received. This occurs when user specifically requests Event Logs to
	// be retrieved, or auto-retrieve is running. This message can be sent when an immediate Start will occur, but mostly you'll
	// see it when the job really is queued.
	protected onQueuedGetEventLogs(change: JobChange) {
		this.broadcast.eventLogsStateChange.next(new ControllerGetLogsState(change.satelliteId, true /* running */, true /* queued */));
	}

	// Called when GetEventLogs JobRequested event is received. This occurs when user specifically requests Event Logs to be retrieved.
	protected onStartGetEventLogs(change: JobChange) {
		this.broadcast.eventLogsStateChange.next(new ControllerGetLogsState(change.satelliteId, true));
	}

	// Called when GetEventLogs JobCompleted event is received. This occurs when the GetEventLogs job has completed after the user has specifically requested
	// Event Logs to be retrieved.
	protected onGetEventLogsComplete(change: JobChange) {
		if (change.cancelled || change.errorMessage) {
			// RB-13264: this.showToast(`${this.translateService.instant('STRINGS.CONTROLLER_GET_EVENT_LOGS_FAILED')} ${change.errorMessage}`);
			this.logNotificationError(`${this.translate.instant('STRINGS.CONTROLLER_GET_EVENT_LOGS_FAILED')} ${change.errorMessage}`);
		}

		this.broadcast.eventLogsStateChange.next(new ControllerGetLogsState(change.satelliteId, false));
	}

	/**
	 * GetPhysicalData JobChange - OnQueued
	 */
	protected onQueuedGetPhysicalDataLogs(change: JobChange) {
		this.broadcast.physicalDataStateChange.next(new ControllerGetPhysicalDataState(change.satelliteId, true /* running */, true /* queued */));
	}

	/**
	 * GetPhysicalData JobChange - Job is running
	 */
	protected onStartGetPhysicalData(change: JobChange) {
		this.broadcast.physicalDataStateChange.next(new ControllerGetPhysicalDataState(change.satelliteId, true /* running */));
	}

	/**
	 * GetPhysicalData JobChange - Job completed
	 */
	protected onGetPhysicalDataComplete(change: JobChange) {
		if (change.cancelled || change.errorMessage) {
			// RB-13757:IQ4- Don't show the toast msg for Controller get physical data failed
		}

		this.broadcast.physicalDataStateChange.next(new ControllerGetPhysicalDataState(change.satelliteId, false /* completed */));
	}

	protected onGetPhysicalDataJobFinished(change: JobChange) {
		this.broadcast.getPhysicalDataJobFinished.next(null);
	}

	// Called when GetEventLogs JobFinished is received. This will occur as part of a GetEventLogs Job Request, BUT it will also occur when GetEventLogs
	// is part of another Job (e.g., when the controller is manually connected. We will use this method for any callers that are interested in Event Log
	// updates, but not dependent on the JobRequest/JobCompleted (i.e., a user request to retrieve logs).
	protected onGetEventLogsJobFinish(change: JobChange) {
		this.broadcast.getEventLogsJobFinished.next(null);
	}

	// =========================================================================================================================================================
	// Get Event Logs Handlers - End
	// =========================================================================================================================================================

	// =========================================================================================================================================================
	// ConnectDataPack Handlers - Begin
	// =========================================================================================================================================================

	protected onStartGetDataPacks(change: JobChange) {
		const controller = this.controllerManager.getControllerListItemFromCache(change.satelliteId);
		const isSyncing = controller == null ? false :
			controller.syncState === RbEnums.Common.ControllerSyncState.Syncing || controller.syncState === RbEnums.Common.ControllerSyncState.ReverseSyncing;
		this.connectDataPackManager.initOrAddToControllersDictionary(new DataPackRequestState(change.satelliteId, true, isSyncing));
	}

	protected onConnectDataPackReceived(change: ControllerChange) {
		if (!change.connectDataPack) { return; }
		const dataPack = new ConnectDataPack(change.connectDataPack);
		this.manualControlManager.initOrAddToControllersDictionary(change.satelliteId, dataPack);
		const controller = this.controllerManager.getControllerListItemFromCache(change.satelliteId);
		dataPack.isSynchronizing = controller == null ? false :
			controller.syncState === RbEnums.Common.ControllerSyncState.Syncing || controller.syncState === RbEnums.Common.ControllerSyncState.ReverseSyncing;
		this.connectDataPackManager.updateConnectDataPack(change.satelliteId, dataPack);
	}

	protected onGetDataPacksComplete(change: JobChange) {
		if (change.cancelled || change.errorMessage) {
			// Don't show errorMessage if cancelled is true but error message is null. This will happen when we call ManualOps/Disconnect. In this case we
			// do not want to show an error message. This logic may be true for all callbacks (i.e., If cancelled is true, but no error, then no toast).
			if (change.errorMessage) {
				this.connectDataPackManager.updateErrorState(change.errorMessage, change.satelliteId);
				// RB-13264: this.showToast(`${this.translateService.instant('STRINGS.FAILED_TO_GET_DATA_PACK')} ${change.errorMessage}`);
				this.logNotificationError(`${this.translate.instant('STRINGS.FAILED_TO_GET_DATA_PACK')} ${change.errorMessage}`);
			}
			return;
		}

		this.connectDataPackManager.updateConnectDataPacksRetrievalState(change.satelliteId, false);
	}

	protected onManualConnectComplete(change: JobChange) {
		try {
			if (change.cancelled || change.errorMessage) {
				this.onGetDataPacksComplete(change);
				this.manualControlManager.manualConnectFailed.next(change.satelliteId);
				return;
			}
		}
		finally {
			this.handleManualOpComplete(change, RbEnums.SignalR.ManualControlProperty.IsConnecting, 'STRINGS.MANUAL_CONNECT_FAILED',
				false);
		}
	}

	protected onCancelAllIrrigation(change: JobChange) {
		if (change.cancelled || change.errorMessage) {
			// RB-13264: this.showToast(`${this.translateService.instant('SPECIAL_MSG.CONTROLLER_STOP_IRRIGATION_FAILED')} ${change.errorMessage}`);
			this.logNotificationError(`${this.translate.instant('SPECIAL_MSG.CONTROLLER_STOP_IRRIGATION_FAILED')} ${change.errorMessage}`);
			return;
		}

		this.connectDataPackManager.cancelAllIrrigationForController.next({ controllerId: change.satelliteId });
	}

	// =========================================================================================================================================================
	// ConnectDataPack Handlers - End
	// =========================================================================================================================================================

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	protected logCallback(method: RbEnums.SignalR.StatusMethod, data: any) {
		if (method === RbEnums.SignalR.StatusMethod.DryRunStatusChange) {
			this.onDryRunResultReceived(data);
		}
		if (data instanceof Array) {
			if (this.onlyLogForSelectedController && data[0].satelliteId !== this.selectedControllerId) { return; }
			console.log(`>> ${this.notificationServiceName}-${method} <<`);
			data.forEach(c => {
				console.log(c);
			});
			return;
		}

		if (this.onlyLogForSelectedController && data.satelliteId !== this.selectedControllerId) { return; }

		console.log(data);
	}

	protected outputToastMessageForControllerFailure(stringResource: string, errorMessage: string, controllerId: number,
		isToastable: boolean) {
		this.controllerManager.getControllerListItem(controllerId)
			.subscribe(controllerListItem => {
				if (controllerListItem == null) { return; }
				// RB-13264: If we're toastable, show the toast. In either case, log the error out of user view.
				if (isToastable) {
					this.showToast(`${this.translate.instant(stringResource,
						{ controller: controllerListItem.name, site: controllerListItem.siteName })} ${errorMessage}`);
				}
				this.logNotificationError_Controller(stringResource, errorMessage, controllerId, controllerListItem.name, controllerListItem.siteName);
			});
	}

	protected showToast(message: string) {
		this.toaster.showToaster(message, RbConstants.Form.TOAST_TIME);
	}

	/**
	 * Utility for errors that have previously been shown as toasts but are now not, RB-13264. This is a single location
	 * from which we could send such errors to either the cloud or the API for tracking and correction.
	 * @param message - string containing the data that would previously have been shown in a toast message.
	 */
	protected logNotificationError(message: string) {
		// No API/cloud operations defined yet, but we'll save the info to the browser console so it's not totally lost.
		console.warn(message);
	}

	/**
	 * Utility for controller errors, sync, reverse sync, retrieve logs, etc. which were previously shown as toasts but 
	 * were removed by RB-13264. This is a single point at which such errors could be transmitted to the cloud or the API for
	 * analysis.
	 * @param stringResource - string specifying string table string name for the error message.
	 * @param errorMessage - string specifying the error message details based on the error type (from the SignalR message).
	 * @param controllerId - number ID of the controller which generated the error
	 * @param controllerName - string name of the controller which generated the error
	 * @param siteName - string name of the site where the controller is defined.
	 */
	protected logNotificationError_Controller(stringResource: string, errorMessage: string, controllerId: number, controllerName: string,
		siteName: string) {
		// No API/cloud operations defined yet, but we'll save the info to the browser console so it's not totally lost.
		const message = `${this.translate.instant(stringResource, 
			{ controller: controllerName, site: siteName })} ${errorMessage} (ID=${controllerId})`;
		console.warn(message);
	}

	/**
	 * Handle a wholesale update of simple program and program group status values from the SignalR information. We convert
	 * the list of id/status values, each into its own ProgramChange item. These items will then be redistributed by the
	 * caller as though they arrived separately.
	 */
	protected buildProgramChangesFromProgramsActiveUpdate(activeProgramsUpdate: ProgramChange):
		{ programGroupChanges: ProgramChange[], programChanges: ProgramChange[] } {
		// Build lists of ProgramChange, each corresponding to one entry in the activeProgramsUpdate item.
		const programGroupChanges: ProgramChange[] = [];
		const programChanges: ProgramChange[] = [];

		// Enumerate the entries in the incoming ProgramChange for active program group and create a new status item
		// for each one.
		activeProgramsUpdate.activeProgramGroups.forEach(pg => {
			const changeType = this.mapProgramStatusToProgramChangeType(pg.status);
			if (changeType == null) {
				console.error('buildProgramChangesFromProgramsActiveUpdate: ERROR: unknown program group status %o', pg.status);
			} else {
				const pgChange = new ProgramChange();
				pgChange.changeDateTime = activeProgramsUpdate.changeDateTime;
				pgChange.changeType = changeType;
				pgChange.companyId = activeProgramsUpdate.companyId;
				pgChange.programGroupId = pg.programGroupId;
				// pgChange.programGroupName: We need to update this as soon as we can in the caller.
				programGroupChanges.push(pgChange);
			}
		});

		// Enumerate the entries in the incoming ProgramChange for active program and create a new status item
		// for each one.
		activeProgramsUpdate.activePrograms.forEach(p => {
			const changeType = this.mapProgramStatusToProgramChangeType(p.status);
			if (changeType == null) {
				console.error('buildProgramChangesFromProgramsActiveUpdate: ERROR: unknown program status %o', p.status);
			} else {
				const pChange = new ProgramChange();
				pChange.changeDateTime = activeProgramsUpdate.changeDateTime;
				pChange.changeType = changeType;
				pChange.companyId = activeProgramsUpdate.companyId;
				pChange.programId = p.programId;
				// pgChange.programName: We need to update this as soon as possible in the caller
				// pgChange.programGroupId: We need to update this as soon as possible in the caller
				programChanges.push(pChange);
			}
		});

		return { programGroupChanges, programChanges };
	}

	protected mapProgramStatusToProgramChangeType(status: string): string {
		switch (status) {
			case RbEnums.Common.ProgramStatus.Running:
				return RbEnums.SignalR.ProgramStatusChangeType.ProgramStarted;
			case RbEnums.Common.ProgramStatus.Paused:
				return RbEnums.SignalR.ProgramStatusChangeType.ProgramPaused;
			case RbEnums.Common.ProgramStatus.Posted:
				return RbEnums.SignalR.ProgramStatusChangeType.Posted;
			case RbEnums.Common.ProgramStatus.Waiting:
				return RbEnums.SignalR.ProgramStatusChangeType.Waiting;
		}
		return '';
	}

	protected handleDiagnosticData(changeType: RbEnums.SignalR.JobType, data: any) {
		let result: DiagnosticData;
		switch (changeType) {
			case RbEnums.SignalR.JobType.VoltagePollDataChange:
				result = this.createRealVoltageDataItem(data);
				break;
			case RbEnums.SignalR.JobType.ShortAddressPollDataChange:
				result = this.createRealShortAddressDataItem(data);
				break;
			case RbEnums.SignalR.JobType.LongAddressPollDataChange:
				result = this.createRealLongAddressDataItem(data);
				break;
			case RbEnums.SignalR.JobType.QuickCheckDataChange:
				result = this.createRealQuickCheckDataItem(data);
				break;
			default:
				return;
		}
		this.broadcast.diagnosticDataReceived.next(result);
	}

	protected createRealVoltageDataItem(data: any): DiagnosticData {
		return new IcVoltagePollData(data);
	}

	protected createRealShortAddressDataItem(data: any): DiagnosticData {
		return new IcShortAddressPollData(data);
	}

	protected createRealLongAddressDataItem(data: any): DiagnosticData {
		return new IcLongAddressPollData(data);
	}

	protected createRealQuickCheckDataItem(data: any): DiagnosticData {
		return new QuickCheckData(data);
	}

	protected sendSyncStateChange(controllerId: number, frontPanelSyncState: RbEnums.SignalR.FrontPanelSyncState | null, forceSend: boolean) {
		if (frontPanelSyncState == null) return; // Invalid value
		if (!forceSend) {
			const cli = this.controllerManager.getControllerListItemFromCache(controllerId);
			if (cli == null) return;
			// Only broadcast changes, and only when not syncing or reverse syncing.
			if (cli.syncState === this.SyncState.Syncing || cli.syncState === this.SyncState.ReverseSyncing) return;
		}
		this.broadcast.syncStateChange.next(new ControllerSyncState(controllerId,
			RbUtils.Controllers.getSyncStateFromFrontPanelStateString(frontPanelSyncState)));
	}

	protected handleNewSoftwareAvailable(change: CompanyStatusChange) {
		this.softwareUpdateManager.onNewUpdateAvailable(change.applicableSoftwareUpdates);
	}
}
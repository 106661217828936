/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Google-Map.xxx()
 * 		 See: _rb.utils.ts
 */

export namespace XXUseRbUtilsNamespace {

	/**
	 * @summary GoogleMap provides utilities for managing markers, etc. in a GoogleMaps display.
	 */
	export abstract class GoogleMap {

		// =====================================================================================================================================================
		// Public Methods
		// =====================================================================================================================================================

		/**
		 * Remove the indicated list of Markers from their maps. The list passed can have any content type, as long
		 * as each element has a 'marker' property which is a google.maps.Marker.
		 * @param markers - Array of objects containing a 'marker' property of type google.maps.Marker.
		 * @param map? - Nullable object representing the map from which markers are to be removed. If null, every
		 * marker is removed from its map, regardless of which map that might be.
		 */
		static clearMarkersFromMap(markers: { marker: google.maps.Marker }[], map?: any): void {
			markers.forEach(m => {
				if ((map != null && m.marker.getMap() === map) || map == null) {
					// Only remove those markers assigned to the indicated map, unless that map is null, in which
					// case we clear the map for all markers.
					m.marker.setMap(null);
				}
			});
		}

		// =====================================================================================================================================================
		// Helper Methods
		// =====================================================================================================================================================

		// private static getProgramTypeString(type: number): string {
		// 	const programGroupManager = AppInjector.get(ProgramGroupManagerService);
		// 	return programGroupManager.getProgramTypesImmediate().find(t => t.value === type).name;
		// }

	}
}

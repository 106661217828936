/*
 * NOTE: DO NOT USE THESE CONSTANTS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbConstants Namespace.
 * 		 E.g., RbConstants.Map.MAP_DRAW_OPTION_DEFAULTS
 * 		 See: _rb.constants.ts
 */

import { icon, LatLngTuple } from 'leaflet';
import { XXUseRbConstantsNamespace as _mapIcons } from './rb-map-icons.constants';

export namespace XXUseRbConstantsNamespace {

	export abstract class Map {

		static readonly LAYER_DROPDOWN_VALUE_DEFAULTS = ['0', '1', '2'];
		static readonly MAP_CENTER_LOCATION: LatLngTuple = [32.2137518, -110.9142628];

		static readonly MAP_OPTION_DEFAULTS = {
			zoom: 14,
			zoomControl: false,
			worldCopyJump: true,
		};

		static readonly MAP_DRAW_OPTION_DEFAULTS = {
			position: 'topleft',
			draw: {
				marker: {
					icon: icon(_mapIcons.MapIcons.ICON_OBJECT),
				},
				polyline: false,
				circle: {
					shapeOptions: {
						color: _mapIcons.MapIcons.DRAW_CIRCLE_COLOR,
					},
				},
			},
		};

		static readonly MARKER_CLUSTER_OPTION_DEFAULTS = {
			showCoverageOnHover: true, // true by default but just including as example,
			spiderfyOnMaxZoom: true, // if two are really close together, this would connect them with lines
			disableClusteringAtZoom: 18,
		};

	}

}

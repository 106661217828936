import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class UserProfile {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
	companyUUId: string;
	companyId: number;
	cultureId: number;
	groupLevel: RbEnums.Common.GroupLevel;
	locale: string;
	name: string;
	siteType: string;
	// siteType: RbEnums.Common.SiteType;
	userId: number;

	static createFromOidcUserProfile(oidcProfile: any) {
		const userProfile = new UserProfile();
		userProfile.companyId = parseInt(oidcProfile.company_id, 10);
		userProfile.cultureId = oidcProfile.culture_id == null ? 1 : parseInt(oidcProfile.culture_id, 10);
		userProfile.groupLevel = parseInt(oidcProfile.group_level, 10);
		userProfile.locale = oidcProfile.locale;
		userProfile.name = oidcProfile.name;
		userProfile.companyUUId = oidcProfile.company_uuid;
		userProfile.siteType = oidcProfile.site_type;
		userProfile.userId = parseInt(oidcProfile.user_id, 10);

		return userProfile;
	}
}

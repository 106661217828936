import { GroupLevel } from "../../users/models/group-level.model";
import { UserManagerService } from "../../users/user-manager.service";

export enum RBTSCodeStatus
{
	None = 0,
	PendingVerification,
	Verified
}

export class UserAccessRequestInfo {
	private userManager: UserManagerService;
	constructor(json: any) {
		if (json) {
			Object.assign(this, json);
		}
		if (json.rbtsCodeExpiration) {
			this.rbtsCodeExpiration = new Date(json.rbtsCodeExpiration);
		}
	}

	userId: number;
	companyId: number;
	email: string;
	name: string;
	accessLevel: number;
	rbtsCodeExpiration: Date;
	rbtsCodeStatus: RBTSCodeStatus;
	oneTimePassword: string;
	daysRequested: number;

	get groupLevelItem(): GroupLevel {
		const groupLevel = this.userManager.getGroupLevel(this.accessLevel);
		if (!groupLevel) return;

		return groupLevel;
	}
}

export class UserAccessRequestInfoUI {
	constructor(json: any) {
		if (json) {
			Object.assign(this, json);
		}
		if (json.rbtsCodeExpiration) {
			this.rbtsCodeExpiration = new Date(json.rbtsCodeExpiration);
		}
	}
	userId: number;
	rbtsCodeExpiration?: Date;
	rbtsCodeStatus: RBTSCodeStatus;
	daysRequested: number;
	rbtsCode?: number;
}
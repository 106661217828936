<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<ng-template #reportWarningModal>
	<rb-dialog
		[title]="'REPORTS.WARNING' | translate"
		[closeButtonLabel]="'STRINGS.CLOSE' | translate"
		[showSubmitButton]="false"
		data-qaid="run-report-modal"
	>
		<p>{{ runReportValidationMessage }}</p>
	</rb-dialog>
</ng-template>

<div *ngIf="!isMobile" class="report-filter-components report-filter-container" [ngClass]="{'site-selector-visible': showSiteSelector}">
	<div *ngIf="!hideSelectHoleArea" class="hole-area-filter">
		<rb-hole-area-filter
			class="hole-area-filter-desktop"
			[selectedCourseId]="selectedCourseId"
			[selectedAreas]="selectedAreas"
			[selectedHoles]="selectedHoles"
			(valueChange)="handleChange('holeAreaPairs',$event)">
		</rb-hole-area-filter>
	</div>
	<div *ngIf="!hideSelectController" class="button-dropdown rb-flex-grow-1 multi-controller-selector">
		<rb-tree-view-checkbox
			*ngIf="!singleSelection"
			class="rb-tree-view-checkbox"
			[requestTreeLevel]="requestTreeLevel"
			[treeViewItems]="treeViewItems"
			(valueChange)="handleChange('controllerIds',$event)">
		</rb-tree-view-checkbox>
		<rb-tree-view-radiobutton
			*ngIf="singleSelection"
			class="rb-tree-view-checkbox"
			[requestTreeLevel]="requestTreeLevel"
			[selectedControllerIds]="controllerIds"
			[treeViewItems]="treeViewItems"
			(valueChange)="handleChange('controllerIds',$event)">
		</rb-tree-view-radiobutton>
	</div>
	<div *ngIf="!hideRadioGroup" class="button-dropdown rb-flex-grow-1 single-controller-selector">
		<rb-radio-group
			class="dib controller-radio-button"
			[items]="items"
			(selectionEvent)="handleChange('controllerId',$event)">
		</rb-radio-group>
	</div>
	<div *ngIf="showAlarmData" class="button-dropdown rb-flex-grow-1 alarm-data-selector">
		<rb-alarm-data class="alarm-data"
					   (selectionEvent)="handleChange('alarmTypes',$event)">
		</rb-alarm-data>
	</div>
	<div class="flex rb-flex-grow-0">
		<rb-date-range-dropdown
			*ngIf="!hideDateRange"
			class="rb-date-range-dropdown flex button-dropdown-daterange" [class.has-time]="!hideTimeForDateRange"
			[dateRangeDropdown]="dateRangeDropdown"
			[selectedDateRange]="selectedDateRange"
			[hideDates]="hideDates"
			[hideTimeForDateRange]="hideTimeForDateRange"
			[useStartOfDayForDateRange]="useStartOfDayForDateRange"
			[minDate]="minDate"
			[maxDate]="maxDate"
			(startDateChange)="handleChange('startDate',$event)"
			(endDateChange)="handleChange('endDate',$event)"
			(dateRangeChange)="handleChange('dateRange',$event)"
		>
		</rb-date-range-dropdown>
		<rb-time-period
			*ngIf="!hideTimePeriod"
			class="rb-date-range-dropdown flex items-center button-dropdown-daterange"
			[oneTimePeriod]="oneTimePeriod"
			(timePeriodChange)="handleChange('timePeriod',$event)"
		></rb-time-period>

		<rb-month-picker
			class="rb-month-picker mh1 button-dropdown-daterange"
			*ngIf="!hideMonthPicker"
			[selectedMonth]="selectedMonth"
			(monthChange)="handleChange('month',$event)"
		></rb-month-picker>
	</div>
	<div *ngIf="!hideSelectFlowSensors" class="flow-sensor-filter multi-select-selection">
		<rb-multi-select-dropdown2
			class="flow-sensor-filter-desktop"
			[label]="sensorSelectLabel"
			[selectedItems]="selectedFlowSensors"
			[items]="flowSensors"
			(selectionComplete)="handleChange('flowSensors', $event)">
		</rb-multi-select-dropdown2>
	</div>

	<div class="view-type-selection-container" *ngIf="showSeparatePumpsCheck && isGolfSite">
		<mat-checkbox 
						[(ngModel)]="showPumps"
						class="dr-view-type pt2"
						data-qaid="drt-show-pumps"
		>
		{{ 'STRINGS.SHOW_SEPARATE_PUMPS' | translate }}
		</mat-checkbox>
	</div>

	<div class="rf-site-selector" *ngIf="showSiteSelector">
		<rb-multi-select-dropdown
			data-qaid="report-filter-select-site"
			class="multi-select-site-selection"
			[label]="(isGolfSite ? 'STRINGS.SELECT_COURSE' : 'STRINGS.SELECT_SITE') | translate"
			[selectedSites]="selectedSites"
			[sites]="sites"
			[isShowSelectAllAsCheckbox]="true"
			[showSelectionCount]="true"
			(siteSelectionComplete)="onSiteSelectionComplete($event)"
		></rb-multi-select-dropdown>
	</div>

	
	
	<div class="rb-flex-grow-0 report-btn-content">
		<rb-report-button
			[label]="runReportButtonLabel | translate"
			(reportButtonClicked)="reportButton()">
		</rb-report-button>
		<div class="dib-ns main-nav-icons pv0 rb-more" *ngIf="showPdf">
			<button mat-icon-button class="" [matMenuTriggerFor]="more" data-qaid="header-nav-more">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #more="matMenu" [overlapTrigger]="false" class="qaid-header-subnav-more">
				<ng-container>
					<button mat-menu-item data-qaid="header-nav-pdf" (click)="exportPdf.emit()">
						{{'STRINGS.VIEW_PDF' | translate}} </button>
				</ng-container>
			</mat-menu>
		</div>
	</div>
</div>

<rb-mobile-report-filter
	*ngIf="isMobile" class="mobile-report-filter"
	[singleSelection]="singleSelection"
	[parentReportFilter]="this"
></rb-mobile-report-filter>

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="auto-manual-scheduled-container">

	<form [formGroup]="editForm">
		<fieldset>

			<div class="radio-and-icon-container">
				<div class="radio-group-container">
					<mat-radio-group formControlName="autoManualSchedule" [disabled]="isDemoMode">
						<div class="radio-btn-container">
							<mat-radio-button color="primary" [value]="AutoContactModeType.Manual">{{ 'STRINGS.MANUAL' | translate }}</mat-radio-button>
						</div>
						<div class="radio-btn-container" *ngIf="contactReason !== ContactReason.ReverseSynchronize">
							<mat-radio-button color="primary" [value]="AutoContactModeType.Automatic">{{ 'STRINGS.AUTOMATIC' | translate }}</mat-radio-button>
						</div>
						<div *ngIf="!isIqNetClient" class="radio-btn-container">
							<mat-radio-button color="primary" [value]="AutoContactModeType.Scheduled">{{ 'STRINGS.SCHEDULED' | translate }}</mat-radio-button>
						</div>
					</mat-radio-group>
				</div>

				<div class="info-icon-container" *ngIf="hasHelpText">
					<button mat-icon-button class="blue" [matMenuTriggerFor]="helpContent">
						<mat-icon class="help-icon">info_outline</mat-icon>
					</button>
				</div>
			</div>

			<div *ngIf="isDemoMode" class="controller-demo-mode-note">{{'SPECIAL_MSG.DEMO_MODE_SETTING_DISABLED' | translate}}</div>

			<div class="start-times-container">
				<rb-edit-start-times-array
					#startTimes
					formArrayName="startTimes"
					*ngIf="editForm.get('autoManualSchedule')?.value === AutoContactModeType.Scheduled"
					[maxStartTimes]="maxScheduledTimes"
					[timesFormArray]="editForm.get('startTimes')"
					[addStartTimeLabel]="'STRINGS.SCHEDULED_TIME' | translate"
					[disableAddStartTime]="editForm.get('startTimes')?.length >= maxScheduledTimes"
					[iqNetClient]="iqNetTypeId === IqNetType.IQNetClient"
				>

				</rb-edit-start-times-array>

				<mat-checkbox *ngIf="!hideSyncIf && editForm.get('startTimes')?.length > 0 && editForm.get('autoManualSchedule')?.value === AutoContactModeType.Scheduled"
							  formControlName="syncIfChanges"
							  (change)="onSyncIfChangesChange($event)"
							  class="sync-if-changes" color="primary"
				>
					{{'STRINGS.ONLY_SYNC_IF_CHANGES_EXIT' | translate}}
				</mat-checkbox>

				<div *ngIf="scheduleTimeZoneText && editForm.get('startTimes')?.length > 0 && editForm.get('autoManualSchedule')?.value === AutoContactModeType.Scheduled">
					<div class="schedule-time-zone-text ml2 mt2">{{scheduleTimeZoneText}}</div>
				</div>

				<div *ngIf="isServer && editForm.get('autoManualSchedule')?.value === AutoContactModeType.Scheduled && contactReason !== ContactReason.ReverseSynchronize">
					<div class="iqNet-note">
						{{(hideSyncIf ? 'STRINGS.IQ_NET_SATELLITE_NOTE_RETRIEVE_LOGS' : 'STRINGS.IQ_NET_SATELLITE_NOTE_SYNC') | translate}}
					</div>
				</div>
			</div>

		</fieldset>
	</form>

</div>

<mat-menu #helpContent="matMenu" [overlapTrigger]="false" >
	<ng-container>
		<div class="ams-help-container" >
			<div class="ams-help-row" *ngIf="manualHelpText != null">
				<span class="ams-help-type-label">{{'STRINGS.MANUAL' | translate}}:</span>{{manualHelpText | translate}}
			</div>
			<div class="ams-help-row" *ngIf="automaticHelpText != null">
				<span class="ams-help-type-label">{{'STRINGS.AUTOMATIC' | translate}}:</span>{{automaticHelpText | translate}}
			</div>
			<div class="ams-help-row" *ngIf="scheduledHelpText != null && !isIqNetClient">
				<span class="ams-help-type-label">{{'STRINGS.SCHEDULED' | translate}}:</span>{{scheduledHelpText | translate}}
			</div>
		</div>
	</ng-container>
</mat-menu>

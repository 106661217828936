import { GroupStartTime } from '../../programs/models/group-start-time.model';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class ProgramGroup {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.groupStartTime) { this.groupStartTime = json.groupStartTime.map(item => new GroupStartTime(item)); }
			// if (json.program) { this.programs = json.program.map(item => new Program(item)); }
			if (json.nextCyclicalStartDate) { this.nextCyclicalStartDate = RbUtils.Conversion.convertStringToDate(json.nextCyclicalStartDate); }
			if (json.stopTime) { this.stopTime = RbUtils.Conversion.convertStringToDate(json.stopTime); }
			if (json.tempAdjustStart) { this.tempAdjustStart = RbUtils.Conversion.convertStringToDate(json.tempAdjustStart); }
			if (json.tempAdjustStop) { this.tempAdjustStop = RbUtils.Conversion.convertStringToDate(json.tempAdjustStop); }
		}
	}

	companyId: number;
	daysRemaining: number;
	etAdjustType: RbEnums.Common.EtAdjustType;
	runTimeGolfInputType: RbEnums.Common.RunTimeGolfInputType;
	forcedProgression: boolean;
	groupStartTime: GroupStartTime[];
	hybridWeekDays: string;
	id: number;
	isEnabled: boolean;
	isSequential: boolean;
	isVertical: boolean;
	name: string;
	nextCyclicalStartDate: Date;
	number: number;
	overrideCycleSoak: boolean;
	overrideStationAdjust: boolean;
	overrideWB: boolean;
	priority: number;
	// programs: Program[]; // Causes circular dependency
	programGroupAdjust: number;
	programType: RbEnums.Common.ProgramType;
	skipDays: number;
	stopTime: Date;
	systemOffOverride: boolean;
	tempProgramGroupAdjust: number;
	tempAdjustStart: Date;
	tempAdjustStop: Date;
	tempAdjustDays: number;
	weekDays: string;

	/**
	 * Id of rain can/sensor associated with this program or null, if the program is not rain can-
	 * referenced (maybe it moves water between lakes or runs the lighting, for example). A
	 * SensorResponse could still cause a ProgramGroup to be paused, stopped, etc. in response to
	 * a sensor in this scenario. Associating ProgramGroup <--> Sensor in this way allows a single
	 * SensorResponse with Initial/FollowUpActionProgramGroupId = null to act on many, many
	 * ProgramGroups, as is done in Cirrus. This implicit association is only for rain cans.
	 */
	rainCanSensorId: number;

	static createDefault(isGolf: boolean): ProgramGroup {
		const pg = new ProgramGroup();
		pg.id = 0;
		pg.name = '';
		pg.weekDays = isGolf ? '1111111' : '0000000';
		pg.groupStartTime = [];
		pg.hybridWeekDays = '1111111';
		pg.skipDays = 1;
		pg.programGroupAdjust = RbConstants.Form.PROGRAM_GROUP_ADJUST;
		pg.tempProgramGroupAdjust = 100;
		pg.programType = 0;
		pg.etAdjustType = RbEnums.Common.EtAdjustType.None;
		pg.isSequential = false;
		pg.forcedProgression = false;
		pg.overrideCycleSoak = false;
		pg.overrideWB = false;
		pg.overrideStationAdjust = false;
		pg.companyId = 0;
		pg.isEnabled = true;
		pg.number = 0;
		pg.isVertical = false;
		pg.systemOffOverride = false;
		pg.runTimeGolfInputType = RbEnums.Common.RunTimeGolfInputType.RunTime;
		return pg;
	}
}

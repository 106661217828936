import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MapInfoLeaflet } from '../../../common/models/map-info-leaflet.model';
import { MapLeafletService } from '../../../common/services/map-leaflet.service';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rb-multi-select-buttons',
  templateUrl: './multi-select-buttons.component.html',
  styleUrls: ['./multi-select-buttons.component.scss']
})
export class MultiSelectButtonsComponent implements OnInit, OnDestroy {
  @Input() mapInfo: MapInfoLeaflet;
  @Output() closeLeftPanel = new EventEmitter();
  
  private subscriptionNotifier$ = new Subject<void>();

  public isGolfSite: boolean;
  public numberStations: number = 0;
  public disableReselect = true;
  public disableResume = true;
  buttons = [];

  constructor(
    private mapService: MapLeafletService,
    public translate: TranslateService
  ) {
    this.mapService.multiSelectService
        .getNumberStations()
        .asObservable()
        .pipe(takeUntil(this.subscriptionNotifier$))
        .subscribe(value => this.numberStations = value);
    this.mapService.multiSelectService
        .getDisableReselect()
        .asObservable()
        .pipe(takeUntil(this.subscriptionNotifier$))
        .subscribe(value => this.disableReselect = value);
    this.mapService.multiSelectService
        .getDisableResume()
        .asObservable()
        .pipe(takeUntil(this.subscriptionNotifier$))
        .subscribe(value => this.disableResume = value);
  }

  ngOnInit(): void {
    this.isGolfSite = this.mapService.siteManager.isGolfSite;
    this.initButtons();
  }

  ngOnDestroy(): void {
		this.subscriptionNotifier$.next();
    this.subscriptionNotifier$.complete();
	}

  private initButtons() {
    

    this.buttons = [
      { 
        id: RbEnums.Common.ToolbarButton.Start,
        text: this.translate.instant('STRINGS.START'),
        title: this.translate.instant('STRINGS.MULTISELECT_START_HINT'),
        icon: "mdi mdi-play", click: this.onStart, condition: true,
      },
      {
        id: RbEnums.Common.ToolbarButton.Stop,
        text: this.translate.instant('STRINGS.STOP'),
        title: this.translate.instant('STRINGS.MULTISELECT_STOP_HINT'),
        icon: "mdi mdi-stop", click: this.onStop, condition: this.isGolfSite 
      },
      {
        id: RbEnums.Common.ToolbarButton.Pause,
        title: this.translate.instant('STRINGS.MULTISELECT_PAUSE_HINT'),
        text: this.translate.instant('STRINGS.PAUSE'),
        icon: "mdi mdi-pause", click: this.onPause, condition: this.isGolfSite
      },
      {
        id: RbEnums.Common.ToolbarButton.Resume,
        text: this.translate.instant('STRINGS.RESUME'),
        title: this.translate.instant('STRINGS.MULTISELECT_RESUME_HINT'),
        icon: "icon-resume", click: this.onResume, condition: this.isGolfSite, disabled: this.disableResume
      },
      {
        id: RbEnums.Common.ToolbarButton.Advance,
        text: this.translate.instant('STRINGS.ADVANCE'),
        title: this.translate.instant('STRINGS.MULTISELECT_ADVANCE_HINT'),
        icon: "mdi mdi-fast-forward", click: this.onAdvance, condition: !this.isGolfSite
      },
      {
        id: RbEnums.Common.ToolbarButton.Edit,
        text: this.translate.instant('STRINGS.EDIT'),
        title: this.translate.instant('STRINGS.MULTISELECT_BATCH_EDIT_HINT'),
        icon: "mdi mdi-pencil", click: this.onEdit, condition: this.isGolfSite, disabled: !this.numberStations
      },
      {
        id: RbEnums.Common.ToolbarButton.Diagnostics,
        text: this.translate.instant('STRINGS.DIAGNOSTIC'),
        title: this.translate.instant('STRINGS.MULTISELECT_DIAGNOSTICS_HINT'),
        icon: "mdi mdi-help-network-outline", click: this.onDiagnostics, condition: this.isGolfSite
      },
      {
        id: RbEnums.Common.ToolbarButton.ReselectStations,
        text: this.translate.instant('STRINGS.RESELECT'),
        title: this.translate.instant('STRINGS.REPEAT_LAST_SELECTION'),
        icon: "mdi mdi-backup-restore", click: this.onReselect, condition: true, disabled: this.disableReselect
      }
    ];

  }

  handleClick(action: RbEnums.Common.ToolbarButton): void {
    switch (action) {
      case RbEnums.Common.ToolbarButton.Start:
        this.onStart();
        break;
      case RbEnums.Common.ToolbarButton.Stop:
        this.onStop();
        break;
      case RbEnums.Common.ToolbarButton.Pause:
        this.onPause();
        break;
      case RbEnums.Common.ToolbarButton.Resume:
        this.onResume();
        break;
      case RbEnums.Common.ToolbarButton.Edit:
        this.onEdit();
        break;
      case RbEnums.Common.ToolbarButton.Diagnostics:
        this.onDiagnostics();
        break;
      case RbEnums.Common.ToolbarButton.Advance:
        this.onAdvance();
        break;
      case RbEnums.Common.ToolbarButton.ReselectStations:
        this.onReselect();
        break;
    
      default:
        break;
    }
  }

  onStart(){
    this.mapInfo.startSelectedStations();
  }

  onStop(){
    this.mapInfo.stopSelectedStations();
    this.closeLeftPanel.emit({shown: false});
  }

  onPause(){
    this.mapInfo.pauseSelectedStations();
    this.closeLeftPanel.emit({shown: false});
  }

  onResume(){
    this.mapInfo.resumeSelectedStations();
    this.closeLeftPanel.emit({shown: false});
  }

  onEdit(){
    this.mapInfo.batchEditSelectedStations();
    this.closeLeftPanel.emit({shown: false});
  }
  
  onDiagnostics() {
    this.mapInfo.diagnosticsSelectedStations();
    this.closeLeftPanel.emit({shown: false});
  }

  onAdvance(){
    this.mapInfo.advanceSelectedStations();
  }

  onReselect(){
    this.mapInfo.repeatLastSelection();
  }
}

import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BannerMessageService } from '../../../common/services/banner-message.service';

@UntilDestroy()
@Component({
	selector: 'rb-banner-message',
	templateUrl: './banner-message.component.html',
	styleUrls: ['./banner-message.component.scss']
})
export class BannerMessageComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'rb-banner-message';
	constructor(private bannerMessage: BannerMessageService) { }
	message = '';

	ngOnInit() {
		this.message = this.bannerMessage.message;
		this.bannerMessage.messageChange
			.pipe(untilDestroyed(this))
			.subscribe((message: string) => {
				this.message = message;
			});
	}

	ngOnDestroy(): void { }
}

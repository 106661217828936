import { RbConstants } from '../../../common/constants/_rb.constants';

export class ExpiryState {

	// @ts-ignore
	constructor(expiryState: RbConstants.Form.GPRS_LICENSE, expiryText?: string, expiryDateString?: string) {
		this.expiryState = expiryState;
		if (expiryText) this.expiryText = expiryText;
		this.expiryDateString = expiryDateString;
	}

	// @ts-ignore
	expiryState: RbConstants.Form.GPRS_LICENSE;
	expiryText: string;
	expiryDateString: string;

}

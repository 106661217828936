import { CalendarEvent } from '../calendar-event/models/calendar-event.model';
import { RbUtils } from '../../common/utils/_rb.utils';

export class SiteStatusChange {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.changeDateTime) { this.changeDateTime = RbUtils.Conversion.convertStringToDate(json.changeDateTime); }
		}
	}

	changeDateTime: Date;
	changeType: string;
	companyId?: number;
	errorMessage?: string = null;
	itemsChanged: any;
	programId?: number;
	reasonCode?: any = null;
	siteId: number;
	calendarEvent: CalendarEvent;
	createdUserName: string;
}

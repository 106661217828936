import * as moment from 'moment';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { RbConstants } from '../../../common/constants/_rb.constants';

@Component({
	selector: 'rb-set-duration',
	templateUrl: './set-duration.component.html',
	styleUrls: ['./set-duration.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SetDurationComponent),
			multi: true
		}
	]
})
export class SetDurationComponent implements OnInit, ControlValueAccessor {

	@ViewChild('durationDialog') durationDialog;

	private _isReadOnly = false;
	@Input() set isReadOnly(value: boolean) { if (value != null) this._isReadOnly = value; }
	get isReadOnly(): boolean { return this._isReadOnly; }

	@Input() hideHours = false;
	@Input() hideMinutes = false;
	@Input() hideSeconds = false;
	@Input() placeholder: string;
	@Input() showDurationDialog = true;
	@Input() showSlider = true;
	@Input() station;
	@Input() showTabs = false;
	@Output() durationValueChange = new EventEmitter<moment.Duration>();

	private _maxHours = RbConstants.Form.DURATION_HOURS.max;
	@Input() set maxHours(value: number) { if (value != null) this._maxHours = value; }
	get maxHours(): number { return this._maxHours; }

	private _maxMinutes = RbConstants.Form.DURATION_MINUTES.max;
	@Input() set maxMinutes(value: number) { if (value != null) this._maxMinutes = value; }
	get maxMinutes(): number { return this._maxMinutes; }

	/**
	 * minSeconds is not strictly the minimum value, but the minimum non-zero value, allowing
	 * the user to enter 0, but limiting the output to minSeconds, if a non-zero value is 
	 * entered.
	 */
	private _minSeconds = 0;
	@Input() set minSeconds(value: number) { if (value != null) this._minSeconds = value; }
	get minSeconds(): number { return this._minSeconds; }

	durationDialogRef: MatDialogRef<any>;
	durationString = '';
	isDisabled = false;

	private duration: moment.Duration;

	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => {};
	private onTouched = () => {};

	constructor(private authManager: AuthManagerService,
				public dialog: MatDialog) { }

	ngOnInit(): void {
		this.isReadOnly = this.authManager.isReadOnly;
	}

	durationTimeClicked() {
		this.onTouched();
		this.durationDialogRef = this.dialog.open(this.durationDialog);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	get value(): moment.Duration { return this.duration; }

	writeValue(duration: any): void {
		this.duration = duration;
		if (this.showDurationDialog) {
			this.updateDuration();
			this.updateDurationString();
		}
	}

	onDurationChanged(duration: moment.Duration) {
		if (!duration) {
			return;
		}
		this.duration = duration;

		if (this.showDurationDialog) {
			this.updateDuration();
			this.updateDurationString();
		} else {
			this.updateDuration();
		}
		this.onChange(this.duration);
		this.durationValueChange.emit(this.duration);
	}

	private updateDuration() {
		if (!this.duration) return;

		if (this.duration.days() >= RbConstants.Form.DURATION_DAYS.max) {
			this.duration = moment.duration({ days: RbConstants.Form.DURATION_DAYS.max });
		} else if (this.duration.hours() >= this.maxHours) {
			this.duration = moment.duration({ hours: this.maxHours });
		} else if (this.duration.minutes() > this.maxMinutes) {
			this.duration = moment.duration({ minutes: this.maxMinutes, seconds: 59});
		} else if (this.duration.minutes() === this.maxMinutes && this.hideHours) {
			this.duration = moment.duration({ minutes: this.maxMinutes, seconds: 0});
		} else if (this.duration.asSeconds() > 0 && this.duration.asSeconds() < this.minSeconds) {
			this.duration = moment.duration({ seconds: this._minSeconds });
		}
	}

	private updateDurationString() {
		if (!this.duration) return;

		let formatString = 'H:mm:ss';
		if (this.hideSeconds) formatString = formatString.substring(0, formatString.length - 3);
		if (this.hideMinutes) formatString = formatString.substring(0, formatString.length - 3); // Assumes also hiding seconds
		if (this.hideHours || this.duration.days() > 0) formatString = formatString.substring(2, formatString.length);
		this.durationString = moment.utc(this.duration.asMilliseconds()).format(formatString);
		if (!this.hideHours && this.duration.days() > 0) {
			this.durationString = (this.duration.days() * 24).toString() + ':' + this.durationString;
		}
	}
}

import * as moment from 'moment';
import { forkJoin, map, Observable, switchMap, take } from 'rxjs';
import { AppDateAdapter } from '../../shared-ui/custom/date-adapter';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CommInterfaceManagerService } from '../comm-interfaces/comm-interface-manager.service';
import { ExpiryState } from './models/expiry-state.model';
import { GprsLicenseListItem } from './models/gprs-license-list-item.model';
import { Injectable } from '@angular/core';
import { LicenseApiCloudService } from './license-api-cloud.service';
import { RbConstants } from '../../common/constants/_rb.constants';
import { RbUtils } from '../../common/utils/_rb.utils';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class LicenseApiCloudManagerService extends ServiceManagerBase {
	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private licenseApiCloudService: LicenseApiCloudService,
				private appDateAdapter: AppDateAdapter,
				private commInterfaceManagerService: CommInterfaceManagerService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	clearCache() {}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================
	getGprsExpiryData(ipList: string[], bypassCache: boolean): Observable<ExpiryState> {
		return this.getGprsLicenseDataForIP(ipList, bypassCache)
			.pipe(map((gprsLicenseListItems: GprsLicenseListItem[]) => this.getExpiryStateFromGprsLicenseListItem(gprsLicenseListItems)));
	}
	getMostExpiredExpiryStateItem(isCloud: boolean): Observable<ExpiryState> {
		if (isCloud) {
			return this.getMostExpiredLicenseData()
			.pipe(map((licenseListItem: GprsLicenseListItem) => this.getExpiryStateFromGprsLicenseListItem([licenseListItem])));
		} else {
			return this.commInterfaceManagerService.getUniqueIPAddressesForSatellites()
			.pipe(take(1),
				switchMap((ips: string[]) => 
					this.getMostExpiredLicenseDataForIP(ips)
				.pipe(map((licenseListItem: GprsLicenseListItem) => this.getExpiryStateFromGprsLicenseListItem([licenseListItem])))
			));	
		}
	}
	updateGprsLicenseByChangingIp(oldIpAddr: string, newIpAddr: string, remainingDays: number): Observable<void> {
		return this.licenseApiCloudService.updateGprsLicenseByChangingIp(oldIpAddr, newIpAddr, remainingDays);
	}
	getMostExpiredLicenseData(): Observable<GprsLicenseListItem> {
		return this.licenseApiCloudService.getMostExpiredLicenseData();
	}
	private getMostExpiredLicenseDataForIP(ips:string[]): Observable<GprsLicenseListItem> {
		return this.licenseApiCloudService.getMostExpiredLicenseDataForIP(ips);
	}
	private getExpiryStateFromGprsLicenseListItem(licenseListItems: GprsLicenseListItem[]): ExpiryState {
		const expiryState = new ExpiryState(RbConstants.Form.GPRS_LICENSE.not_expired);
		if ((licenseListItems != null) && licenseListItems.length > 0 && licenseListItems[0] !== null) {
			const expiryItem = this.getMostExpiredItem(licenseListItems);
			const expiry = moment(expiryItem.expiration);
			const minutes = moment(new Date()).diff(expiry, 'minutes');
			const date = this.appDateAdapter.format(expiry.toDate());
			expiryState.expiryDateString = date;

			if (minutes > 0) {
				expiryState.expiryState = RbConstants.Form.GPRS_LICENSE.expired;
				expiryState.expiryText = RbUtils.Translate.instant('SPECIAL_MSG.GPRS_LICENSE_EXPIRED');
			} else {
				const days = moment.duration(-minutes, 'minutes').asDays();
				if (days < RbConstants.Form.GPRS_LICENSE.info && days > RbConstants.Form.GPRS_LICENSE.expiring) {
					expiryState.expiryState = RbConstants.Form.GPRS_LICENSE.info;
					expiryState.expiryText = RbUtils.Translate.instant('SPECIAL_MSG.GPRS_LICENSE_EXPIRING_ON');
				} else if (days < RbConstants.Form.GPRS_LICENSE.expiring) {
					expiryState.expiryState = RbConstants.Form.GPRS_LICENSE.expiring;
					expiryState.expiryText = RbUtils.Translate.instant('SPECIAL_MSG.GPRS_LICENSE_EXPIRING_IN_DAYS', { days: days.toFixed(0) });
				}
			}
		}

		return expiryState;
	}
	private getMostExpiredItem(items: GprsLicenseListItem[]): GprsLicenseListItem {
		if (items.length < 2) return items[0];

		let mostExpiredItem = items[0];
		let mostDaysExpired = -100;

		for (let i = 1; i < items.length; i++) {
			const dateDiff = moment().diff(moment(items[i].expiration), 'days');
			if (dateDiff > mostDaysExpired) {
				mostExpiredItem = items[i];
				mostDaysExpired = dateDiff;
			}
		}

		return mostExpiredItem;
	}
	getGprsLicenseDataForCompany(bypassCache: boolean): Observable<GprsLicenseListItem[]> {
		return this.licenseApiCloudService.getGprsLicenseDataForCompany(bypassCache).pipe(map(res => {
			return res.value;
		}));
	}
	getGprsLicenseDataForIP(ipAddresses: string[], bypassCache: boolean = true): Observable<GprsLicenseListItem[]> {
		const chunkSize = 50;
		const sources: Observable<GprsLicenseListItem[]>[] = [];
		for (let i = 0; i < ipAddresses.length; i += chunkSize) {
			const ips = ipAddresses.slice(i, i + chunkSize);
			sources.push(this.licenseApiCloudService.getGprsLicenseDataForIP(ips, bypassCache).pipe(map(res => {
				return res.value;
			})));
		}
		return forkJoin(sources).pipe(take(1)).pipe((map(results => {
			if (!results) {
				return [];
			}
			return Array.prototype.concat.apply([], results);
		})));
	}
}

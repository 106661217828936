import { ApiService, HttpMethod } from '../_common/api.service';
import { ActivityItem } from './models/activity-item.model';
import { ActivityStatusType } from './models/activity-status-type.model';
import { CourseViewLayout } from './models/course-view-layout.model';
import { CourseViewStationData } from './models/course-view-station-data.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RbUtils } from '../../common/utils/_rb.utils';

@Injectable({
	providedIn: 'root'
})
export class ActivityApiService extends ApiService {

	getCompletedProgramGroupActivity(startTime: Date, endTime: Date, selectedSiteIds: number[]): Observable<ActivityItem[]> {
		return this.apiRequest<any>(
			this.getCompletedProgramGroupActivityUrl(startTime, endTime), HttpMethod.Post, selectedSiteIds)
			.pipe(map(items => items.map(item => new ActivityItem(item))));
	}

	getCompletedProgramActivity(startTime: Date, endTime: Date, selectedSiteIds: number[]): Observable<ActivityItem[]> {
		return this.apiRequest<any>(this.getCompletedProgramActivityUrl(startTime, endTime), HttpMethod.Post, selectedSiteIds)
			.pipe(map(items => items.map(item => new ActivityItem(item))));
	}

	getCompletedStationActivity(startTime: Date, endTime: Date, selectedSiteIds: number[] = null): Observable<ActivityItem[]> {
		return this.apiRequest<any>(
			this.getCompletedStationActivityUrl(startTime, endTime), HttpMethod.Post, selectedSiteIds)
			.pipe(map(items => items.map(item => new ActivityItem(item))));
	}

	getCourseViewActivityStructure(siteId: number): Observable<CourseViewLayout> {
		return this.apiRequest<any>(
			this.getCourseViewActivityStructureUrl(siteId), HttpMethod.Get)
			.pipe(map(json => new CourseViewLayout(json)));
	}

	getCourseViewActivityCompleted(startTime: Date, endTime: Date, siteId: number): Observable<CourseViewStationData[]> {
		return this.apiRequest<any>(this.getCourseViewActivityCompletedUrl(startTime, endTime, siteId), HttpMethod.Get)
			.pipe(map(items => items.map(item => new CourseViewStationData(item))));
	}

	getCourseViewActivityDryRun(dryRunId: number, siteId: number): Observable<CourseViewStationData[]> {
		return this.apiRequest<any>(this.getCourseViewActivityDryRunUrl(dryRunId, siteId), HttpMethod.Get)
			.pipe(map(items => items.map(item => new CourseViewStationData(item))));
	}

	// getCourseViewActivityScheduled(startTime: Date, endTime: Date, siteId: number): Observable<CourseViewStationData[]> {
	// 	return this.apiRequest<any>(this.getCourseViewActivityScheduledUrl(startTime, endTime, siteId), HttpMethod.Get)
	// 		.pipe(map(items => items.map(item => new CourseViewStationData(item))));
	// }

	getActivityStatusTypes(): Observable<ActivityStatusType[]> {
		return this.apiRequest<any>(
			this.getActivityStatusTypesUrl(), HttpMethod.Get)
			.pipe(map(items => items.map(item => new ActivityStatusType(item))));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}Activity`; }

	private getCompletedProgramGroupActivityUrl(startTime: Date, endTime: Date): string {
		// NOTE: Activities are stored and queried using the date/time local to the controller. DO NOT MODIFY the date/time string passed in. If a user queries
		// 		 for 10/11 at 8:00, that is the precise time period that will be used to query the db. No adjustments/offsets will/should be made.

		return `${this.baseUrl}/GetCompletedProgramGroupActivity_V2`
		+ `?startTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(startTime)}`
			+ `&endTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(endTime)}`;
	}

	private getCompletedProgramActivityUrl(startTime: Date, endTime: Date): string {
		// NOTE: Activities are stored and queried using the date/time local to the controller. DO NOT MODIFY the date/time string passed in. If a user queries
		// 		 for 10/11 at 8:00, that is the precise time period that will be used to query the db. No adjustments/offsets will/should be made.

		return `${this.baseUrl}/GetCompletedProgramActivity_V2`
		+ `?startTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(startTime)}`
			+ `&endTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(endTime)}`;
	}

	private getCompletedStationActivityUrl(startTime: Date, endTime: Date): string {
		// NOTE: Activities are stored and queried using the date/time local to the controller. DO NOT MODIFY the date/time string passed in. If a user queries
		// 		 for 10/11 at 8:00, that is the precise time period that will be used to query the db. No adjustments/offsets will/should be made.

		return `${this.baseUrl}/GetCompletedStationActivity_V2`
			+ `?startTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(startTime)}`
			+ `&endTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(endTime)}`;
	}

	private getActivityStatusTypesUrl(): string {
		return `${this.baseUrl}/GetActivityStatusTypes`;
	}

	private getCourseViewActivityStructureUrl(siteId: number): string {
		return `${this.baseUrl}/GetCourseViewActivityStructure?siteId=${siteId}`;
	}

	private getCourseViewActivityCompletedUrl(startTime: Date, endTime: Date, siteId: number): string {
		return `${this.baseUrl}/GetCourseViewActivityCompleted`
			+ `?startTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(startTime)}`
			+ `&endTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(endTime)}`
			+ `&siteId=${siteId}`;
	}

	private getCourseViewActivityDryRunUrl(dryRunId: number, siteId: number) {
		return `${this.baseUrl}/GetCourseViewActivityDryRun?dryRunId=${dryRunId}&siteId=${siteId}`;
	}

}

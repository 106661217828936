import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { StationListItem } from '../../../../../api/stations/models/station-list-item.model';

import DiagnosticDataType = RbEnums.Common.DiagnosticDataType;
import { Station } from '../../../../../api/stations/models/station.model';

export class IcmDiagnosticResult<T = StationListItem | Station> {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	station: T;
	cdType: DiagnosticDataType;
	interface: string;
	location: string;
	hole: number;
	area: string;
	subArea: string;
	course: string;
	result: number;
	isSuccess: boolean;
	voltage: number;
	wirePath: number;

	feedback?: number;
	updateTime?: string;

}

export namespace XXUseRbConstantsNamespace {
	export abstract class RbRoutes {
			// Controller Pages Routes
			static readonly GLOBAL_CONTROLLERS = '/controllers/controllers';
			static readonly CONTROLLERS_PROGRAMS = '/controllers/programs';

			// System Configs Routes
			static readonly SYSTEMSETUP_SITECONFIG_CONTROLLERS = '/systemsetup/siteconfig/controllers'

			// Program Pages Routes
			static readonly GLOBAL_PROGRAMS = '/programs/programs';

	}
}
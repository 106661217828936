import { ApiService, HttpMethod } from '../_common/api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { VoltageDiagnostic } from './models/voltage-diagnostic.model';

@Injectable({
	providedIn: 'root'
})
export class VoltageDiagnosticApiService extends ApiService {
	// =========================================================================================================================================================
	// API Calls - Public
	// =========================================================================================================================================================

	getLastVoltageDiagnosticLogs(): Observable<VoltageDiagnostic[]> {
		return this.apiRequest<any>(this.getLastVoltageDiagnosticLogsUrl, HttpMethod.Get)
			.pipe(map(response => response.map(item => new VoltageDiagnostic(item))));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}VoltageDiagnostic`; }

	private get getLastVoltageDiagnosticLogsUrl(): string { return `${this.baseUrl}/GetLastVoltageDiagnosticLogs`; }
}

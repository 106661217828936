<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div #agGrid class="rb-table-wrapper-grid-container" >
	
	<ag-grid-angular
		*ngIf="showGrid"
		class="ag-theme-material ag-grid-angular rb-system-status-toggle"
		[ngStyle]="gridHeightStyle"
		[ngClass]="{
			'mobile': isMobileStyle,
			'checkbox-mobile-header': includeCheckbox && isMobile,
			'keep-desktop-view': ignoreMobileView
		}"
		[columnDefs]="isMobile ? mobileColumnDefs : columnDefs"
		[components]="components"
		[groupDefaultExpanded]="groupDefaultExpanded"
		[rowSelection]="rowSelection"
		[suppressRowClickSelection]="true"
		[rowData]="rowData"
		[treeData]="treeNodeCallback != null"
		[getDataPath]="treeNodeCallback"
		[groupDisplayType]="'custom'"
		[defaultColDef]="defaultColDef"
		[domLayout]="domLayout"
		[gridOptions]="gridOptions"
		[suppressNoRowsOverlay]="true"
		[rowDragManaged]="rowDrag"
		[suppressHorizontalScroll]="false"
		[suppressScrollOnNewData]="suppressScrollOnNewData"
		(selectionChanged)="onSelectionChanged($event)"
		(rowClicked)="onRowClicked($event)"
		(gridReady)="onAgGridReady($event)"
		(gridSizeChanged)="sizeColumnsToFit()"
		(rowDataChanged)="rowDataChanged($event)"
		(cellClicked)="onCellClicked($event)"
		(rowDragEnd)="onRowDragEnd($event)"
		(columnResized)="onColumnResized($event)"
		(rowGroupOpened)="onRowGroupOpened($event)"
		[suppressMenuHide]="true"
		[headerHeight]="rowSelection === 'single' && isMobile ? 0 : null"
		[masterDetail]="masterDetail"
		[isRowMaster]="isRowMaster"
		[detailCellRenderer] = "detailCellRenderer"
		[detailCellRendererParams]="detailCellRendererParams"
		[detailRowHeight]="detailRowHeight"
		[detailRowAutoHeight]="detailRowAutoHeight"
		[getRowHeight] = "getRowHeight"
		[isServerSideGroupOpenByDefault]="isServerSideGroupOpenByDefault"
		[isServerSideGroup]="isServerSideGroup"
		[getServerSideGroupKey]="getServerSideGroupKey"
		[rowModelType]="rowModelType"
		[isRowSelectable]="isRowSelectable"
		rememberGroupStateWhenNewData="true"
	>
		<ng-content></ng-content>
	</ag-grid-angular>

	<div class="item-selection-container" *ngIf="!hideRowSelection || hideRowSelectionButLeaveSpace">
		<div *ngIf="!hideRowSelection && !hideRowSelectionButLeaveSpace" class="item-selection">{{selectedData ? selectedData.length : selected?.length}} / {{totalNodes}} &nbsp;{{'STRINGS.SELECTED_LOWERCASE' | translate}}</div>
	</div>

	<rb-no-data-overlay
			*ngIf="hasNoData"
			[displayText]="'STRINGS.NO_DATA_TO_DISPLAY' | translate"
			[instructionsText]="instructionsText | translate"
	></rb-no-data-overlay>
</div>

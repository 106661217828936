import { Observable, of } from 'rxjs';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CachedValue } from '../_common/cached-value';
import { Injectable } from '@angular/core';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { tap } from 'rxjs/operators';
import { WeatherApiService } from './weather-api.service';
import { WorldWeatherData } from './models/world-weather-data.model';

@Injectable({
	providedIn: 'root'
})
export class WeatherManagerService extends ServiceManagerBase {
	private readonly WeatherCacheDurationInMinutes: number = 15;
	private _sevenDayForecasts: { [key: string]: CachedValue<WorldWeatherData> } = {};

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private weatherApiService: WeatherApiService,
				protected broadcastService: BroadcastService,
	) {
		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	/**
	 * Retrieve 7-day weather forecast from WWO.
	 * @param qValue - Query data specifying location for forecast. Could be a zip code, city, etc.
	 * @param lang - String language code specifying the language of the result ("es", "pt", "en", etc.) We use the
	 * current conditions data returned by the call, so we need to tell it what language to use.
	 */
	getSevenDayForecast(qValue: string, lang: string, byPassCache = false): Observable<WorldWeatherData> {
		const cacheKey = `${qValue}-${lang}`;

		if (!byPassCache && this._sevenDayForecasts[cacheKey] && !this._sevenDayForecasts[cacheKey].isExpired) {
			return of(this._sevenDayForecasts[cacheKey].value);
		}

		// Adjust the language for the response so we get French if the user language setting is French. Otherwise,
		// we'll get the result in the language of the OS.
		return this.weatherApiService.getSevenDayForecast(qValue, lang)
			.pipe(tap((data: WorldWeatherData) => {
				this._sevenDayForecasts[cacheKey] = new CachedValue<WorldWeatherData>(data, this.WeatherCacheDurationInMinutes * 60);
			}));
	}

}

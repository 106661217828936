import { StationListItem } from '../../../../api/stations/models/station-list-item.model';

export class SelectedStationInfo {

	constructor(stationId: number, isSelected: boolean, station: StationListItem = null) {
		this.stationId = stationId;
		this.isSelected = isSelected;
		this.station = station;
	}

	stationId: number;
	isSelected: boolean;
	station: StationListItem;
}

export class CellEditorValidationParams {

	constructor(value: any, gridParams: any) {
		this.value = value;
		this.gridParams = gridParams;
	}

	value: any;
	gridParams: any;

}

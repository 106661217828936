import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { DatePipe } from '@angular/common';
import { EnvironmentService } from '../../../common/services/environment.service';
import { HttpParams } from '@angular/common/http';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserManagerService } from '../../../api/users/user-manager.service';

@Component({
  selector: 'rb-password-expiration-snackbar',
  templateUrl: './password-expiration-snackbar.component.html',
  styleUrls: ['./password-expiration-snackbar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(+100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(+100%)' }))
      ])
    ])
  ]
})
export class PasswordExpirationSnackbarComponent implements OnInit {

  @Input() expirationDate: Date;
  @Output() onClose = new EventEmitter();
  dateToExpire: number = 0;
  cultureSettings: CultureSettings;
  expiredDateStr: string;
  description: string;

  constructor(private userService: UserManagerService,
    private env: EnvironmentService,
    private authManager: AuthManagerService,
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {
    this.cultureSettings = this.authManager.userCulture;
  }

  ngOnInit() {
    this.initDialogStrings();
  }

  dismiss() {
    this.onClose.emit();
  }

  resetPassword() {
    this.userService.getCodeToResetPassword(`${this.env.rbcc_ui}/#/resetpasswordsuccess`).pipe(take(1)).subscribe(code => {
        const queryParams: any = {
          'code': code
        }
        const queryParamsString = new HttpParams({ fromObject: queryParams }).toString();
        window.open(`${this.env.identityServer4Url}/account/resetpassword?${queryParamsString}`, "_self");
    });
  }

  private initDialogStrings() {
    const today = new Date();
    const expireDate = this.expirationDate;

    const diffInMs = Math.abs(expireDate.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0));
    const daysToExpire = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    var dateFormat = this.cultureSettings != null ? RbUtils.Common.getDateFormat(this.cultureSettings) : RbConstants.Form.DATE_FORMAT.MMDDYYYY_Slash;
    const expiredDateStr = this.datePipe.transform(this.expirationDate, dateFormat);

    this.description = this.translateService.instant('STRINGS.PASSWORD_RESET_WARN', { daysToExpire: daysToExpire, expiredDateStr: expiredDateStr });
  }
}
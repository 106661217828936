import { ApiService, HttpMethod } from '../_common/api.service';
import { EventLogEntry } from './models/event-log-entry.model';
import { FlowChartData } from '../irrigation-activity/models/flow-chart-data.model';
import { Injectable } from '@angular/core';
import { IrrigationEventLogListItem } from './models/irrigation-event-log-list-item.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RbUtils } from '../../common/utils/_rb.utils';

@Injectable({
	providedIn: 'root'
})
export class EventLogApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	/**
	 * ackEventLogs sets the ack property of the indicated EventLog instances to 'true'. NOTE: eventLogIds CAN BE NULL.
	 * IF NULL *ALL* EVENTLOGS IN THE COMPANY WILL BE SET.
	 * @param eventLogIds - list of EventLog id values (should not contain duplicates), or null to ack all EventLogs
	 * in the current company.
	 */
	ackEventLogs(eventLogIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.ackEventLogsUrl, HttpMethod.Post, eventLogIds);
	}

	/**
	 * unAckEventLogs sets the ack property of the indicated EventLog instances to 'false'. NOTE: eventLogIds CAN BE NULL.
	 * IF NULL *ALL* EVENTLOGS IN THE COMPANY WILL BE SET.
	 * @param eventLogIds - list of EventLog id values (should not contain duplicates), or null to unack all EventLogs
	 * in the current company.
	 */
	unAckEventLogs(eventLogIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.unAckEventLogsUrl, HttpMethod.Post, eventLogIds);
	}

	getEventLogs(type: number, startDate: Date, endDate: Date, siteIds: number[], includeAcknowledged: boolean): Observable<EventLogEntry[]> {
		return this.apiRequest<any>(this.getEventLogsUrl(type, startDate, endDate, includeAcknowledged), HttpMethod.Post, siteIds)
			.pipe(map(logEntries => logEntries.map(entry => new EventLogEntry(entry))));
	}

	getEventLogsBySeparatedAcknowledged(type: number, startDate: Date, endDate: Date, siteIds: number[],
		includeAcknowledgedAlarms: boolean, includeAcknowledgedWarnings: boolean): Observable<EventLogEntry[]> {
		return this.apiRequest<any>(this.getEventLogsBySeparatedAcknowledgedUrl(type, startDate, endDate,
			includeAcknowledgedAlarms, includeAcknowledgedWarnings), HttpMethod.Post, siteIds)
			.pipe(map(logEntries => logEntries.map(entry => new EventLogEntry(entry))));
	}

	getEventLogsByControllerIds(controllerIds: number[], startDate: string, endDate: string, eventTypes: number[]): Observable<EventLogEntry[]> {
		return this.apiRequest<any>(this.getEventLogsByControllerIdsUrl(startDate, endDate, eventTypes), HttpMethod.Post, controllerIds)
			.pipe(map(logEntries => logEntries.map(entry => new EventLogEntry(entry))));
	}

	getIrrigationLogs(startDateLocal: Date, endDateLocal: Date): Observable<IrrigationEventLogListItem[]> {
		return this.apiRequest<any>(this.getIrrigationLogsUrl(startDateLocal, endDateLocal), HttpMethod.Get)
			.pipe(map(logEntries => logEntries.map(entry => new IrrigationEventLogListItem(entry))));
	}
	getHistoricalFlowChartItems(startDateLocal: Date, endDateLocal: Date): Observable<FlowChartData> {
		return this.apiRequest<any>(this.getHistoricalFlowChartItemsUrl(startDateLocal, endDateLocal), HttpMethod.Get)
		.pipe(map(entry => new FlowChartData(entry)));
	}
	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}EventLog`; }

	private get ackEventLogsUrl(): string {
		return `${this.baseUrl}/AckEventLogs`;
	}

	private get unAckEventLogsUrl(): string {
		return `${this.baseUrl}/UnAckEventLogs`;
	}

	private getEventLogsUrl(type: number, startDate: Date, endDate: Date, includeAcknowledged: boolean) {
		return `${this.baseUrl}/GetEventLogs_V2?startTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(startDate)}` +
		`&endTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(endDate)}&types=${type}&includeAcknowledged=${includeAcknowledged}`;
	}

	private getEventLogsBySeparatedAcknowledgedUrl(type: number, startDate: Date, endDate: Date,
		includeAcknowledgedAlarms: boolean, includeAcknowledgedWarnings: boolean) {
			return `${this.baseUrl}/GetEventLogsBySeparatedAcknowledged_V2?startTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(startDate)}` +
			`&endTime=${RbUtils.Conversion.convertDateToDateTimeParameterString(endDate)}&types=${type}` +
		`&includeAcknowledgedAlarms=${includeAcknowledgedAlarms}&includeAcknowledgedWarnings=${includeAcknowledgedWarnings}`;
	}

	private getEventLogsByControllerIdsUrl(startDate: string, endDate: string, eventTypes: number[]): string {
		const eventTypesParam = eventTypes.reduce((list, id) => `${list.length > 0 ? list + ',' : ''}${id}`, '');
		return `${this.baseUrl}/GetEventLogsBySatelliteIds_V2?startTime=${startDate}&endTime=${endDate}&types=${eventTypesParam}`;
	}

	private getIrrigationLogsUrl(startDateLocal: Date, endDateLocal: Date): string {
		// RB-8574: The start and end dates are expressed in UTC so, if the browser is in another time zone, we
		// don't ask for the data for the last twelve hours, but offset by the time zone difference.
		const sDate = RbUtils.Conversion.convertDateToUTCDateTimeParameterString(startDateLocal);
		const eDate = RbUtils.Conversion.convertDateToUTCDateTimeParameterString(endDateLocal);
		return `${this.baseUrl}/GetIrrigationLogs?startTimeUTC=${sDate}&endTimeUTC=${eDate}`;
	}
	private getHistoricalFlowChartItemsUrl(startDateLocal: Date, endDateLocal: Date): string {
		const sDate = RbUtils.Conversion.convertDateToUTCDateTimeParameterString(startDateLocal);
		const eDate = RbUtils.Conversion.convertDateToUTCDateTimeParameterString(endDateLocal);
		return `${this.baseUrl}/GetHistoricalFlowChartItems?startTimeUTC=${sDate}&endTimeUTC=${eDate}`;
	}

}

<!-- Left pane - Golf -->
<div *ngIf="isGolfSite"
	class="div-left-panel"
	[class.open]="isOpen">
	<div class="tabs-container">
		<div class="tab-item menu-toggle"
			(click)="closeSidebar()"
			matTooltip="Close sidebar"
			matTooltipPosition="right">
			<div class="icon-container">
				<i class="mdi mdi-close"></i>
			</div>
		</div>
		<div class="tab-item"
			*ngFor="let item of tabOptions; let i = index"
			[class.active]="selectedTabIndex === i"
			(click)="activateTab(i)"
			[matTooltip]="item.title"
			matTooltipPosition="right">
			<div class="icon-container" [innerHtml]="item.icon"></div>
		</div>
	</div>
	<mat-tab-group mat-stretch-tabs class="left-sidebar-tabs"
		[(selectedIndex)]="selectedTabIndex">
		<mat-tab label="Stations">
			<rb-stations-list
				*ngIf="!busy"
				[busy]="busy"
				[mapInfo]="mapInfo"
				[map]="map"
				[dragDisabled]="dragDisabled"
				[isWidget]="isWidget"
				(holeClicked)="holeClicked.emit($event)"
				(areaClicked)="areaClicked.emit($event)"
				(stationClicked)="stationClicked.emit($event)"
				(onDragMoved)="onDragMoved($event)"
				(onDragEnded)="onDragEnded()">
			</rb-stations-list>
		</mat-tab>
		<mat-tab label="Notes">
			<rb-notes-list
				*ngIf="!busy"
				[busy]="busy"
				[mapInfo]="mapInfo"
				[map]="map"
				(noteLocationClicked)="onNoteLocationClicked($event)">
			</rb-notes-list>
		</mat-tab>
		<mat-tab [label]="'STRINGS.PROGRAMS_AND_SCHEDULES' | translate">
			<div class="h-100 w-100" *ngIf="showPrograms">
				<rb-program-list
					class="h-100 w-100"
					[mapInfo]="mapInfo"
					[map]="map"
					[dragDisabled]="dragDisabled"
					[isWidget]="isWidget">
				</rb-program-list>
			</div>
		</mat-tab>
		<mat-tab [label]="'STRINGS.IMPORTED_LAYERS' | translate">
			<rb-imported-layers
				*ngIf="!busy"
				[busy]="busy"
				[mapInfo]="mapInfo"
				[isWidget]="isWidget">
			</rb-imported-layers>
		</mat-tab>
		<!-- <mat-tab label="Custom Layers">Custom Layers</mat-tab> -->
		<mat-tab [label]="'STRINGS.COMMON_LAYERS' | translate">
			<rb-common-layers #commonLayers
				[detailOptionsDisabled]="!showingDetailMarkers"
				[busy]="busy"
				[showVisibleSelector]="showVisibleSelector"
				[noInternet]="noInternet"
				(layerSelectionChange)="onLayerSelectedChanged($event)"
				(baseLayerSelectionChange)="onBaseLayerSelectedChanged($event)"
				(textColorChange)="onTextColorChanged($event)"
				(exportPdf)="onExportPdf()"
				(downloadTiles)="onDownloadTiles()"
				(removeTiles)="onRemoveTiles()">
			</rb-common-layers>
		</mat-tab>

	</mat-tab-group>
</div>

<!-- Left pane - Commercial -->
<div *ngIf="!isGolfSite"
	class="div-left-panel"
	[class.open]="isOpen">
	<rb-tree-controller-list
		*ngIf="!busy"
		[mapInfo]="mapInfo"
		[map]="map"
		[dragDisabled]="dragDisabled"
		(controllerClicked)="controllerClicked.emit($event)"
		(sensorClicked)="sensorClicked.emit($event)"
		(stationClicked)="stationClicked.emit($event)"
		(onDragMoved)="onDragMoved($event)"
		(onDragEnded)="onDragEnded()"
	>
	</rb-tree-controller-list>
</div>

import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CatalogApiService } from './catalog-api.service';
import { CatalogFlowRate } from './models/catalog-flow-rate.model';
import { CatalogModel } from './models/catalog-model.model';
import { CatalogNozzle } from './models/catalog-nozzle.model';
import { Injectable } from '@angular/core';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class CatalogManagerService extends ServiceManagerBase {

	// Cache Containers
	private commercialModels: CatalogModel[];
	private nonCommercialModels: CatalogModel[];
	private nozzleListDict: { [id: number]: CatalogNozzle[] } = {}; // Map from sprinkler model ID to list of nozzles associated with that sprinkler model
	private flowRateListDict: { [id: number]: CatalogFlowRate[] } = {}; // Map from sprinkler model ID to list of nozzles associated with that sprinkler model
	private nozzleDict: { [id: number]: CatalogNozzle } = {}; // Map from nozzle ID to nozzle

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private catalogApiService: CatalogApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);

		// We don't immediately populate the program types list, as we know that the cache will be cleared when the initial
		// login occurs; it would be useless to get values now.
	}

	// =========================================================================================================================================================
	// Base Overrides
	// =========================================================================================================================================================

	protected clearCache() {
		this.nozzleListDict = {};
		this.flowRateListDict = {};
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getModels(isCommercial: boolean): Observable<CatalogModel[]> {
		if (isCommercial && this.commercialModels != null) return of(this.commercialModels);
		if (!isCommercial && this.nonCommercialModels != null) return of(this.nonCommercialModels);

		return this.catalogApiService.getModels(isCommercial).pipe(tap(list => {
			if (isCommercial) {
				this.commercialModels = list;
			} else {
				this.nonCommercialModels = list;
			}
		}));
	}

	getAllNozzles(bypassCache = false): Observable<CatalogNozzle[]> {
		return this.catalogApiService.getAllNozzles(bypassCache)
			.pipe(map(response => response.isFromCache ? response.value.slice() : response.value));
	}

	getNozzles(modelId: number): Observable<CatalogNozzle[]> {
		const nozzles = this.nozzleListDict[modelId];
		if (nozzles != null) {
			return of(nozzles);
		}

		return this.catalogApiService.getNozzles(modelId).pipe(tap(nozzleList => {
			this.nozzleListDict[modelId] = nozzleList;
			nozzleList.forEach(nozzle => this.nozzleDict[nozzle.id] = nozzle);
		}));
	}

	getNozzleImmediate(id: number) { return this.nozzleDict[id]; }

	getNozzle(id: number): Observable<CatalogNozzle> {
		const nozzle = this.nozzleDict[id];
		if (nozzle != null) {
			return of(nozzle);
		}

		return this.catalogApiService.getNozzle(id).pipe(tap(item => this.nozzleDict[id] = item));
	}
	getFlowRates(nozzleId: number, convertFromMinToHr: boolean): Observable<CatalogFlowRate[]> {
		const flowRates = this.flowRateListDict[nozzleId];
		if (flowRates != null) {
			return of(flowRates);
		}
		return this.catalogApiService.getFlowRates(nozzleId).pipe(map(list => {
			if (convertFromMinToHr) {
				list.forEach(fr => fr.flowRate = +(fr.flowRate * 60).toFixed(3));
			}
			list.sort((fr1, fr2) => fr1.flowRate - fr2.flowRate);
			this.flowRateListDict[nozzleId] = list;
			return list;
		}));	
	}
}

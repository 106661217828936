import * as moment from 'moment';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';

@Component({
	selector: 'rb-date-list-selector',
	templateUrl: './date-list-selector.component.html',
	styleUrls: ['./date-list-selector.component.scss']
})
export class DateListSelectorComponent implements OnInit {
	@ViewChild('picker', { static: true }) picker;

	@Output() dateListChange = new EventEmitter<Date[]>();

	@Input() dateList: Date[] = [];
	@Input() maxDaysOff = 5;
	@Input() minDate = new Date();
	@Input() maxDate = moment(this.minDate).add('days', 364).toDate();
	@Input() headerText = 'STRINGS.DAYS_OFF';

	startAtDate: Date;
	selectedDate: Date;
	isEditing = false;
	dateBeingEdited: Date;
	isReadOnly = false;

	// RB-12037: From angular 10 and above, calling bind in the view causes change detection to run continuously
	// To fix the issue: move bind call into a property
	boundDate = this.dateFilter.bind(this);

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService) { }

	ngOnInit() {
		this.isReadOnly = this.authManager.isReadOnly;
		this.startAtDate = this.minDate;
		if (this.dateList != null) { this.sortListAndNotify(this.dateList, true); }
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onAddDayOff() {
		this.startAtDate = this.minDate;
		this.selectedDate = new Date('1970-1-1');
		this.picker.open();
	}

	onEditDate(date: Date) {
		this.startAtDate = date;
		this.selectedDate = date;

		this.isEditing = true;
		this.dateBeingEdited = date;
		this.picker.open();
	}

	onDeleteDate(date: Date) {
		this.sortListAndNotify(this.dateList.filter(d => d !== date));
	}

	onDateSelected(event: any) {
		// Use temp array to avoid UI redraw artifacts.
		let tempList = this.dateList.slice();

		if (this.isEditing) {
			tempList = tempList.filter(d => moment(this.dateBeingEdited).diff(d, 'days') !== 0);
		}

		tempList.push(event.value);
		this.sortListAndNotify(tempList);
	}

	onDatePickerClosed() {
		this.isEditing = false;
		this.dateBeingEdited = null;
	}

	private dateFilter(date: Date) {
		return !this.dateList?.find(d => moment(date).isSame(d, 'day'));
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private sortListAndNotify(dates: Date[], doNotNotify = false) {
		setTimeout(() => {
			dates.sort((d1, d2) => moment(d1).diff(d2, 'days'));
			this.dateList = dates;
			if (!doNotNotify) { this.dateListChange.emit(this.dateList); }
		});
	}

}

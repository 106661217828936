import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompanyManagerService } from '../../../../api/companies/company-manager.service';
import { CultureSettings } from '../../../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../../../api/culture-settings/culture-settings-manager.service';
import { FlowElementManagerService } from '../../../../api/flow-elements/flow-element-manager.service';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../common/utils/_rb.utils';
import {SystemStatusService} from '../../../../common/services/system-status.service';
import { UnitLabelService } from '../../../../common/services/unit-label.service';

@UntilDestroy()
@Component({
	selector: 'rb-system-status-flow',
	templateUrl: './system-status-flow.component.html',
	styleUrls: ['./system-status-flow.component.scss']
})
export class SystemStatusFlowComponent implements OnInit, OnDestroy {
	percentageFlowRate = 0;
	maxFlowRange = 0;
	totalFlow = 0;
	cultureSettings: CultureSettings;
	maxFlowRangeDisplayValue = '--';
	totalFlowDisplayText = '--';
	totalFlowUnits = '--';

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(private cultureSettingsManager: CultureSettingsManagerService,
				private flowElementManager: FlowElementManagerService,
				private companyManager: CompanyManagerService,
				private systemStatusService: SystemStatusService,
				private unitLabelService: UnitLabelService
	) { }

	ngOnInit() {
		// Attach to the flowElementManager's changed event. When we get that, we'll update the flow rates.
		this.flowElementManager.flowElementListChange
			.pipe(untilDestroyed(this))
			.subscribe((changeList) => {
				// Request our pump list again. We assume that the manager will handle giving us updated items or not,
				// handling the cache state correctly.
				this.getFlowCapacity();
			});

		// Attach to the companyManagers's changed event. When we get that, we potentially update the flow rates.
		this.companyManager.companyChange
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				// Request our pump list again. We assume that the manager will handle giving us updated items or not,
				// handling the cache state correctly.
				this.getFlowCapacity();
			});

		// First see if the system has any pumps. If it does, we will aggregate the flowCapacity of all the pumps.
		this.getFlowCapacity();

		// Monitor culture changes to show proper units.
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe((cultureSettings: CultureSettings) => {
				this.cultureSettings = cultureSettings;
				this.setValues();
			});

		// Setup monitoring of appropriate SignalR event to get current system flow rate.
		this.systemStatusService.theoreticalFlowRateTotalChange
			.pipe(untilDestroyed(this))
			.subscribe((value: number) => {
				this.totalFlow = value;
				this.setValues();
			});
	}

	ngOnDestroy(): void {
		/** Required by untilDestroyed() */
	}
	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getFlowCapacity() {
		this.companyManager.getSystemFlowCapacity().subscribe(capacity => {
			this.maxFlowRange = capacity;
			this.setValues();
		});
	}

	private setValues() {
		if (!this.cultureSettings) {
			this.getCultureSettings();
			setTimeout(() => this.setValues(), 100);
			return;
		}

		this.maxFlowRangeDisplayValue = this.getConvertedRate(this.maxFlowRange);
		this.totalFlowDisplayText = this.getConvertedRate(this.totalFlow);
		this.totalFlowUnits = this.unitLabelService.getFlowRateLabel();
		this.percentageFlowRate = this.totalFlow / this.maxFlowRange * 100;
	}

	private getCultureSettings() {
		this.cultureSettings = RbUtils.User.cultureSettings;

		if (this.cultureSettings === undefined || this.cultureSettings === null) {
			setTimeout(() => this.getCultureSettings(), 100);
			return;
		}
	}

	private getConvertedRate(value: number): string {
		const convertedRate = RbUtils.Common.ToFlow(value, RbEnums.Common.UnitsType.English, this.cultureSettings.unitType);
		return Number.isInteger(convertedRate)
			? convertedRate.toString()
			: RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator, convertedRate.toString());
	}

}

import { map, tap } from 'rxjs/operators';
import { BroadcastService } from '../../common/services/broadcast.service';
import { EventLogApiService } from './event-log-api.service';
import { EventLogEntry } from './models/event-log-entry.model';
import { FlowChartData } from '../irrigation-activity/models/flow-chart-data.model';
import { Injectable } from '@angular/core';
import { IrrigationEventLogListItem } from './models/irrigation-event-log-list-item.model';
import { Observable } from 'rxjs';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class EventLogManagerService extends ServiceManagerBase {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(protected broadcastService: BroadcastService,
				private eventLogApiService: EventLogApiService) {

		super(broadcastService);
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	clearCache() {}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	ackEventLogs(eventLogIds: number[]): Observable<void> {
		return this.eventLogApiService.ackEventLogs(eventLogIds)
			.pipe(
				tap(() => this.broadcastService.collectionChange.next(new Array<EventLogEntry>(new EventLogEntry()))));
	}

	getEventLogs(type: number, startDate: Date, endDate: Date, siteIds: number[], includeAcknowledgedAlarmsAndWarnings = false,
		includeAcknowledgedAlarms = false, includeAcknowledgedWarnings = false): Observable<EventLogEntry[]> {
		let adjustedType;
		let eventLogsData;
		if (includeAcknowledgedAlarms || includeAcknowledgedWarnings) {
			// eslint-disable-next-line no-bitwise
			adjustedType = includeAcknowledgedAlarms ? (type | RbEnums.Common.EventLogType.OpenAlarm ) : type;
			// eslint-disable-next-line no-bitwise
			adjustedType = includeAcknowledgedWarnings ? (adjustedType | RbEnums.Common.EventLogType.OpenWarning) : adjustedType;
			eventLogsData = this.eventLogApiService.getEventLogsBySeparatedAcknowledged(adjustedType, startDate, endDate, siteIds,
				includeAcknowledgedAlarms, includeAcknowledgedWarnings);
		} else {
			// eslint-disable-next-line no-bitwise
			adjustedType = includeAcknowledgedAlarmsAndWarnings
				? (type | RbEnums.Common.EventLogType.OpenAlarm | RbEnums.Common.EventLogType.OpenWarning) : type;
			eventLogsData = this.eventLogApiService.getEventLogs(adjustedType, startDate, endDate, siteIds, includeAcknowledgedAlarmsAndWarnings);
		}
		return eventLogsData.pipe(
				map((eventLogs: EventLogEntry[]) => {
					//RB-12618: filter to hide events that need to be hidden
					eventLogs = eventLogs.filter(eventLog => !RbUtils.Common.isHiddenEventLog(eventLog));
					
					if (!includeAcknowledgedAlarmsAndWarnings &&
						!includeAcknowledgedAlarms && !includeAcknowledgedWarnings) return eventLogs.filter(eventLog => !eventLog.ack);

					// eslint-disable-next-line no-bitwise
					const requestedOpenAlarm = (type & RbEnums.Common.EventLogType.OpenAlarm) !== 0;
					// eslint-disable-next-line no-bitwise
					const requestedOpenWarning = (type & RbEnums.Common.EventLogType.OpenWarning) !== 0;
					eventLogs = eventLogs.filter(eventLog =>
						(eventLog.type !== RbEnums.Common.EventLogType.OpenAlarm && eventLog.type !== RbEnums.Common.EventLogType.OpenWarning) ||
						(eventLog.type === RbEnums.Common.EventLogType.OpenAlarm && (requestedOpenAlarm || eventLog.ack)) ||
						(eventLog.type === RbEnums.Common.EventLogType.OpenWarning && (requestedOpenWarning || eventLog.ack)));

					return eventLogs;
				})
			);
	}

	getEventLogsByControllerIds(controllerIds: number[], startDate: string, endDate: string, eventTypes: number[]): Observable<EventLogEntry[]> {
		return this.eventLogApiService.getEventLogsByControllerIds(controllerIds, startDate, endDate, eventTypes);
	}

	/**
	 * Get irrigation engine log data for the indicated time span. This is normally used by live data display
	 * elements like the IrrigationActivityChartService, so endDate is normally "now". Dates are expressed in
	 * UTC so we can send our local time and get data correctly if the browser is in another time zone.
	 * @param startDateLocal - Date of the start date/time for the retrieval, expressed in browser time zone.
	 * @param endDateLocal - Date of the end date/time for the retrieval, expressed in browser time zone.
	 */
	getIrrigationLogs(startDateLocal: Date, endDateLocal: Date): Observable<IrrigationEventLogListItem[]> {
		return this.eventLogApiService.getIrrigationLogs(startDateLocal, endDateLocal);
	}
	/*
	 * @param startDateLocal - Date of the start date/time for the retrieval, expressed in browser time zone.
	 * @param endDateLocal - Date of the end date/time for the retrieval, expressed in browser time zone.
	 */
	getHistoricalFlowChartItems(startDateLocal: Date, endDateLocal: Date): Observable<FlowChartData> {
		return this.eventLogApiService.getHistoricalFlowChartItems(startDateLocal, endDateLocal);
	}
}

import { MasterValveStatusMvInfo } from './master-valve-status-mv-info.model';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class MasterValveStatus {

	constructor(json: any = null, controllerId: number, changeDateTime: string) {
		if (json) {
			Object.assign(this, json);
		}

		this.changeDateTime = RbUtils.Conversion.convertStringToDate(changeDateTime);
		this.controllerId = controllerId;
		if (json.masterValves) { this.masterValves = json.masterValves.map(mv => new MasterValveStatusMvInfo(mv)); }
	}

	controllerId: number;
	changeDateTime: Date;
	masterValveCount: number;
	masterValves: MasterValveStatusMvInfo;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { Breadcrumb } from '../../../common/models/breadcrumb.model';
import { BreadcrumbService } from '../../../common/services/breadcrumb.service';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';

@UntilDestroy()
@Component({
	selector: 'rb-breadcrumb-trail',
	templateUrl: './breadcrumb-trail.component.html',
	styleUrls: ['./breadcrumb-trail.component.scss']
})
export class BreadcrumbTrailComponent implements OnInit, OnDestroy {

	isGolfSite = false;
	breadcrumbTrail: Breadcrumb[];
	RbConstants = RbConstants;

	constructor(private authManager: AuthManagerService,
				private breadcrumbService: BreadcrumbService) { }

	ngOnInit() {
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		try {
			this.breadcrumbService.breadcrumbTrailChange
				.pipe(untilDestroyed(this))
				.subscribe((breadcrumbTrail: Breadcrumb[]) => {
					this.breadcrumbTrail = breadcrumbTrail;
				});

			this.breadcrumbTrail = this.breadcrumbService.breadcrumbTrail;
		} catch (e) {
			// Guard against undefined service during logout
		}
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	get isShowSupportContactInfo(): boolean {
		if (this.isGolfSite || !this.breadcrumbTrail) {
			return false;
		};

		switch (this.breadcrumbTrail.length) {
			// List with one element is the site list page
			case 1:
				return true;
			// List with two elements are the dashboard page
			case 2:
				return this.breadcrumbService.isShowSupportContactInfo(this.breadcrumbTrail[1]);
			default:
				return false;
		}
	}
}

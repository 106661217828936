import { EntityQueryParamsBase } from '../../_common/_entityQueryParamsBase';

export class GetStationQueryParams extends EntityQueryParamsBase {

	constructor(public includeSatellite = true,
				public includeProgramSteps = true,
				public includeStationAreas = false,
				public visibleStationsOnly = false) {

		super();
	}

	get queryString(): string {
		if (!this.includeSatellite && !this.includeProgramSteps && !this.includeStationAreas && !this.visibleStationsOnly) { return this._queryString; }

		this._queryString = '?';

		if (this.includeSatellite) { this.addQueryVariable('includeSatellite=true'); }
		if (this.includeProgramSteps) { this.addQueryVariable('includeProgramSteps=true'); }
		if (this.includeStationAreas) { this.addQueryVariable('includeStationAreas=true'); }
		if (this.visibleStationsOnly) { this.addQueryVariable('visibleStationsOnly=true'); }

		return this._queryString;
	}

}

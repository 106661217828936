import { ChangeBase } from './change-base.model';

export class StationValveTypeChange extends ChangeBase {

	constructor(json: any = null) {
		super(json);

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);
		}
	}

	changeType: string;
	reasonCode: any;
	valveType: number;
}
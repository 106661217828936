import { RbEnums } from '../enumerations/_rb.enums';
import { Station } from '../../api/stations/models/station.model';

import Timer = NodeJS.Timer;

export class AlertPopup {

	private readonly containerDiv: HTMLElement;

	private encapsulatedPopup: google.maps.OverlayView;

	constructor(public position: google.maps.LatLng, contentDiv: HTMLElement) {

		this.encapsulatedPopup = new google.maps.OverlayView();
		const self = this;

		/** Called when the popup is added to the map. */
		this.encapsulatedPopup.onAdd = function() {
			this.getPanes().floatPane.appendChild(self.containerDiv);
		};

		/** Called when the popup is removed from the map. */
		this.encapsulatedPopup.onRemove = function() {
			if (self.containerDiv.parentElement)  self.containerDiv.parentElement.removeChild(self.containerDiv);
		};

		/** Called each frame when the popup needs to draw itself. */
		this.encapsulatedPopup.draw = function() {
			const divPosition = this.getProjection().fromLatLngToDivPixel(self.position);

			// Hide the popup when it is far out of view.
			const display =
				Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
					'block' :
					'none';

			if (display === 'block') {
				self.containerDiv.style.left = divPosition.x + 'px';
				self.containerDiv.style.top = (divPosition.y - 10) + 'px';
			}
			if (self.containerDiv.style.display !== display) {
				self.containerDiv.style.display = display;
			}
		};

		contentDiv.classList.add('map-alert-content');

		// Zero height div positioned at the bottom of the alert
		const anchor = document.createElement('div');
		anchor.classList.add('map-alert-popup-anchor');
		anchor.appendChild(contentDiv);

		// Zero-height div positioned at the bottom of the tip
		this.containerDiv = document.createElement('div');
		this.containerDiv.classList.add('map-alert-popup-container');
		this.containerDiv.appendChild(anchor);
		this.containerDiv.style.visibility = 'hidden';

		// Stop clicks from bubbling up to the map
		// google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
	}

	show() {
		this.containerDiv.style.visibility = 'visible';
	}

	hide() {
		this.containerDiv.style.visibility = 'hidden';
	}

	setMap(map: google.maps.Map) {
		this.encapsulatedPopup.setMap(map);
	}
}

export class StationWithMapInfo extends Station {
	marker: google.maps.Marker;
	alertPopup: AlertPopup;
	isStationRunning = false;
	irrigationStatus = RbEnums.Common.IrrigationStatus.Idle;
	status = '-';
	hoverTimer: Timer;
	error: string;
}

import { ApiService, HttpMethod } from '../_common/api.service';

import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { GetStationQueryParams } from './models/get-station-params.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MasterValve } from './models/master-valve.model';
import { MasterValveListItem } from './models/master-valve-list-item.model';
import { Observable } from 'rxjs';
import { Station } from './models/station.model';
import { StationForTableEdit } from './models/station-for-table-edit.model';
import { StationListItem } from './models/station-list-item.model';
import { StationPriority } from './models/station-priority.model';
import { StationPrograms } from './models/station-programs.model';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@Injectable({
	providedIn: 'root'
})
export class StationApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================
	clearCache() {
		this.clearMasterValveCache();
		super.clearCache(this.getStationsListUrl);
	}

	updateStationCache(stationListItem: StationListItem) {
		if (stationListItem?.siteId) {
			this.updateAndReturnCachedCollection([stationListItem], 'id', this.getStationsBySiteUrl(stationListItem.siteId));
		}
		if (stationListItem?.satelliteId) {
			this.updateAndReturnCachedCollection([stationListItem], 'id', this.getStationsListForSatelliteUrl(stationListItem.satelliteId));;
		}
	}

	updateStationsBySiteCache(stationListItem: StationListItem[], siteId: number) {
		this.updateAndReturnCachedCollection(stationListItem, 'id', this.getStationsBySiteUrl(siteId));
	}

	removeStationFromCache(stationListItem: StationListItem) {
		this.removeItemFromCachedCollection([stationListItem], 'id');
	}

	clearMasterValveCache() {
		super.clearCache(this.getMasterValvesListUrl);
		super.clearCache(this.baseGetMasterValvesUrl);
		super.clearCache(this.baseGetSharedMasterValvesUrl);
	}

	createMultiStationsInAreas(formValues: {
		controllerId: number;
		stationCount: number;
		holeIds: number[];
		areaId: number,
		subAreaId: number,
		wirepath: number,
	}) {
		return this.apiRequest<any>(this.createMultiStationsInAreasUrl(formValues.holeIds, formValues.areaId,
			formValues.subAreaId, formValues.controllerId, formValues.stationCount, formValues.wirepath), HttpMethod.Post);
	}

	createMultiStations(stationCount: number, areaIds: number[], body: any) {
		return this.apiRequest<any>(this.createMultiStationsUrl(stationCount, areaIds), HttpMethod.Post, body);
	}

	deleteStations(stationIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.deleteStationsUrl, HttpMethod.Delete, stationIds);
	}

	getAddressUniqueness(address: string, id: number, controllerId: number, groupNumber: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getAddressUniquenessUrl(address, id, controllerId, groupNumber), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	isFlowRateSet(controllerId: number): Observable<boolean> {
		return this.apiRequest<any>(this.getIsFlowRateSetUrl(controllerId), HttpMethod.Get)
			.pipe(map(response => response.value));
	}

	getMasterValveListItem(masterValveId: number): Observable<MasterValveListItem> {
		return this.apiRequest<any>(this.getMasterValveListItemUrl(masterValveId), HttpMethod.Get)
			.pipe(map(mvJson => new MasterValveListItem(mvJson)));
	}

	getMasterValves(controllerId: number): Observable<MasterValve[]> {
		return this.apiRequest<any>(this.getMasterValvesUrl(controllerId), HttpMethod.Get)
			.pipe(map(masterValves => masterValves.map(mv => new MasterValve(mv))));
	}

	getSharedMasterValves(controllerId: number): Observable<MasterValve[]> {
		return this.apiRequest<any>(this.getSharedMasterValvesUrl(controllerId), HttpMethod.Get)
			.pipe(map(masterValves => masterValves.map(mv => new MasterValve(mv))));
	}

	getMasterValvesList(bypassCache: boolean): Observable<ApiCachedRequestResponse<MasterValveListItem[]>> {
		return this.apiRequestWithCache<any>(this.getMasterValvesListUrl, bypassCache, result => result.map(mv => new MasterValveListItem(mv)));
	}

	getMasterValvesListBySatelliteId(controllerId: number, bypassCache: boolean): Observable<ApiCachedRequestResponse<MasterValveListItem[]>> {
		return this.apiRequestWithCache<any>(this.getMasterValvesListBySatelliteIdUrl(controllerId), bypassCache, 
			result => result.map(mv => new MasterValveListItem(mv)));
	}

	getPriorities(): Observable<StationPriority[]> {
		return this.apiRequest<any>(this.getPrioritiesUrl, HttpMethod.Get)
			.pipe(map(list => list.map(item => new StationPriority(item))));
	}

	getStation(id: number, queryParams: GetStationQueryParams, bypassCache = false): Observable<Station> {
		return this.apiRequest<any>(this.getStationUrl(id, queryParams), HttpMethod.Get, /* httpBody */ null,
			/* useRefRequest */ null, bypassCache)
			.pipe(map(json => new Station(json)));
	}

	getStationsByAreasAndSites(areaIds?: number[], siteIds?: number[]): Observable<Station[]> {
		return this.apiRequest<any>(this.getStationsByAreasAndSitesUrl(areaIds, siteIds), HttpMethod.Get)
			.pipe(map(list => list.map(item => new Station(item))));
	}

	getStationsByAreas(areaIds?: number[], siteId?: number): Observable<Station[]> {
		return this.apiRequest<any>(this.getStationsByAreasUrl(areaIds, siteId), HttpMethod.Get)
			.pipe(map(list => list.map(item => new Station(item))));
	}

	getStationbyFlowZoneId(flowZoneId: number): Observable<Station[]> {
		return this.apiRequest<any>(this.getStationbyFlowZoneIdUrl(flowZoneId), HttpMethod.Get)
			.pipe(map(list => list.map(item => new Station(item))));
	}

	getStationsList(bypassCache: boolean): Observable<ApiCachedRequestResponse<StationListItem[]>> {
		return this.apiRequestWithCache<any>(this.getStationsListUrl, bypassCache,
				result => result.map(s => new StationListItem(s)));
	}

	getStationsBySiteIdCommercial(siteId: number, bypassCache: boolean): Observable<ApiCachedRequestResponse<StationListItem[]>> {
		return this.apiRequestWithCache<any>(this.getStationsBySiteUrl(siteId), bypassCache,
				result => result.map(s => new StationListItem(s)));
	}

	getStationListItem(stationId: number, bypassCache: boolean): Observable<ApiCachedRequestResponse<StationListItem>> {
		return this.apiRequestWithCache<any>(this.getStationListItemUrl(stationId), bypassCache,
				result => new StationListItem(result));
	}

	getStationsListForSatellite(satId: number, bypassCache: boolean): Observable<ApiCachedRequestResponse<StationListItem[]>> {
		return this.apiRequestWithCache<any>(this.getStationsListForSatelliteUrl(satId), bypassCache,
				result => result.map(s => new StationListItem(s)));
	}

	// getStationsList(): Observable<StationListItem[]> {
	// 	if (this.getStationsListObservable != null) { return this.getStationsListObservable; }
	//
	// 	this.getStationsListObservable = this.apiRequest<any>(this.getStationsListUrl(), HttpMethod.Get)
	// 		.pipe(share(), finalize(() => this.getStationsListObservable = null), map(stations => stations.map(s => new StationListItem(s))));
	//
	// 	return this.getStationsListObservable;
	// }

	getStationsNotInProgram(controllerId: number, programId: number): Observable<Station[]> {
		return this.apiRequest<any>(this.getStationsNotInProgramUrl(controllerId, programId), HttpMethod.Get)
			.pipe(map(list => list.map(item => new Station(item))));
	}

	getStationPrograms(stationIds: number[]): Observable<StationPrograms[]> {
		// GetStationPrograms takes a (potentially long) list of stationIds so they are sent in the body of a POST request.
		return this.apiRequest<any>(this.getStationProgramsUrl(stationIds), HttpMethod.Post, stationIds)
			.pipe(map(list => list.map(item => new StationPrograms(item))));
	}

	getStations(controllerId: number, visibleStationsOnly = false): Observable<Station[]> {
		return this.apiRequest<any>(this.getStationsUrl(controllerId, visibleStationsOnly), HttpMethod.Get)
			.pipe(map(response => response.map(item => new Station(item))));
	}

	getAllStations(filter?: IStationFilter): Observable<Station[]> {
		return this.apiRequest<any>(this.getAllStationsUrl(filter), HttpMethod.Get).pipe(map(response => response.map(item => new Station(item))));
	}

	getStationsForTableEditByIds(stationIds: number[]): Observable<StationForTableEdit[]> {
		return this.apiRequest<any>(
				`${this.baseUrl}/GetStationsForTableEditByIds`, HttpMethod.Post, stationIds)
			.pipe(map(response => response.map(item => new Station(item))));
	}

	getValveNameUniqueness(name: string, id: number, controllerId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getValveNameUniquenessUrl(name, id, controllerId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	updateMasterValves(masterValveIds: number[], masterValveData: any): Observable<null> {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch,
			{ ids: masterValveIds, patch: this.patchTransform(masterValveData) });
	}

	updateMultipleMasterValves(flowMonitoringId: number, masterValveStates: any): Observable<null> {
		const patchData = Object.keys(masterValveStates).map(key => ({
			ids: [parseInt(key, 10)],
			patch: this.patchTransform({ flowMonitoringId: masterValveStates[key] ? flowMonitoringId : null })
		}));
		return this.apiRequest<any>(this.updateMultipleBatchesUrl, HttpMethod.Patch, patchData);
	}

	updateMultipleStations(stations: {ids: number[]; patch: any;}[]) : Observable<[number[], any]>{
		var patchData = [];
		stations.forEach(station => {
			var indPatchData = { ids: station.ids, patch: this.patchTransform(station.patch) };
			patchData.push(indPatchData);
		});
		return this.apiRequest<any>(this.updateMultipleBatchesUrl, HttpMethod.Patch, patchData);
	}

	updateStations(stationIds: number[], updateData: any) {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch,
			{ ids: stationIds, patch: this.patchTransform(updateData) });
	}

	reConnectStation(stationIds: number[]) {
		return this.apiRequest<any>(this.reConnectStations, HttpMethod.Put, stationIds);
	}

	moveStations(stationIds: number[], holeId: number, areaId: number, subAreaId: number, applyAreaDefaults: boolean, applyNewDefaultNames: boolean) {
		return this.apiRequest<any>(this.getMoveStationsUrl(holeId, areaId, subAreaId, applyAreaDefaults, applyNewDefaultNames), HttpMethod.Put, stationIds);
	}

	getNextScheduledIrrigation(stationIds: number[], minutes: number) {
		return this.apiRequest<any>(this.getNextScheduledIrrigationUrl(minutes), HttpMethod.Post, stationIds);
	}

	isOKToMove(stationIds: number[], destinationSatelliteId: number, destinationGroupNumber?: number) {
		return this.apiRequest<any>(this.getIsOKToMoveUrl(destinationSatelliteId, destinationGroupNumber), HttpMethod.Post, stationIds);
	}


	moveStationsToSatellite(stationIds: number[], destinationSatelliteId: number, destinationWireGroup?: number) {
		return this.apiRequest<any>(
			this.moveStationsToSatelliteUrl(destinationSatelliteId, destinationWireGroup),
			HttpMethod.Post, stationIds
		);
	}

	reorderStations(siteId: number, holeId: number, areaId: number, patchObject: any) {
		return this.apiRequest<any>(this.getReorderStationsUrl(siteId, holeId, areaId), HttpMethod.Patch, { patch: this.patchTransform(patchObject)});
	}

	getExtDataForProgamSummary(controllerId: any) {
		return this.apiRequest<any>(this.getExtDataForProgamSummaryUrl(controllerId), HttpMethod.Get);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}Station`; }

	private createMultiStationsInAreasUrl(
		holeIds: number[], areaId: number, subAreaId: number, controllerId: number, stationCount: number, groupNumber: number) {

		let queryParam = '';
		const holesParam = holeIds.reduce((list, holeId) => `${list.length > 0 ? list + '&' : ''}holeIds=${holeId}`, '');

		if (holesParam.length > 0) { queryParam += `&${holesParam}`; }

		return `${this.baseUrl}/CreateMultiStationsInAreas?satelliteId=${controllerId}&stationCount=${stationCount}` +
			`&areaId=${areaId}&subAreaId=${subAreaId}&groupNumber=${groupNumber}${queryParam}`;
	}

	private createMultiStationsUrl(stationCount: number, areaIds: number[]) {
		const areaParams = areaIds.map((item) => `areaIds=${item}`).join('&');
		return `${this.baseUrl}/CreateMultiStations?stationCount=${stationCount}&${areaParams}`;
	}

	private get deleteStationsUrl(): string { return `${this.baseUrl}/DeleteStations`; }

	private getAddressUniquenessUrl(address: string, id: number, controllerId: number, groupNumber: number): string {
		return `${this.baseUrl}/IsAddressUnique?stationId=${id}&stationAddress=${address}&satelliteId=${controllerId}&groupNumber=${groupNumber}`;
	}

	private getIsFlowRateSetUrl(controllerId: number): string {
		return `${this.baseUrl}/IsFlowRateSet?satelliteId=${controllerId}`;
	}

	private get baseGetMasterValvesUrl(): string { return `${this.baseUrl}/GetMasterValves`; }
	private getMasterValveListItemUrl(masterValveId: number): string { return `${this.baseUrl}/GetMasterValveListItem?masterValveId=${masterValveId}`; }
	private getMasterValvesUrl(controllerId: number): string { return `${this.baseGetMasterValvesUrl}?satelliteId=${controllerId}`; }
	private get baseGetSharedMasterValvesUrl(): string { return `${this.baseUrl}/GetSharedMasterValves`; }
	private getSharedMasterValvesUrl(controllerId: number): string { return `${this.baseGetSharedMasterValvesUrl}?satelliteId=${controllerId}`; }

	private get getMasterValvesListUrl(): string { return `${this.baseUrl}/GetMasterValveList`; }
	private getMasterValvesListBySatelliteIdUrl(controllerId: number): string { 
		return `${this.baseUrl}/GetMasterValveListBySatelliteId?satelliteId=${controllerId}`; 
	}

	private get getPrioritiesUrl(): string { return `${this.baseUrl}/GetPriorities`; }

	private getValveNameUniquenessUrl(name: string, id: number, controllerId: number): string {
		return `${this.baseUrl}/IsNameUnique?stationId=${id}&stationName=${encodeURIComponent(name)}&satelliteId=${controllerId}`;
	}

	private getStationUrl(id: number, queryParams: GetStationQueryParams): string {
		let queryString = queryParams ? queryParams.queryString : '';
		queryString += (queryString.length > 0) ? `&stationId=${id}` : `?stationId=${id}`;
		return `${this.baseUrl}/GetStation${queryString}`;
	}

	private getStationsByAreasAndSitesUrl(areaIds?: number[], siteIds?: number[]) {
		let queryParam = '';
		const areasParam = areaIds == null ? '' : areaIds.reduce((list, areaId) => `${list ? list + '&' : ''}areaIds=${areaId}`, '');
		const sitesParam = siteIds == null ? '' : siteIds.reduce((list, siteId) => `${list ? list + '&' : ''}siteIds=${siteId}`, '');

		if (areasParam.length > 0) { queryParam = areasParam; }
		if (sitesParam.length > 0) { queryParam = (queryParam.length > 0) ? `${queryParam}&${sitesParam}` : sitesParam; }
		queryParam = `${queryParam}&includeStationAreas=true`;

		return `${this.baseUrl}/GetStationsByAreasAndSites?${queryParam}`;
	}

	private getStationsByAreasUrl(areaIds?: number[], siteId?: number) {
		let queryParam = '';
		const areasParam = areaIds == null ? '' : areaIds.reduce((list, areaId) => `${list ? list + '&' : ''}areaIds=${areaId}`, '');
		const sitesParam = siteId == null ? '' : `&siteId=${siteId}`;

		if (areasParam.length > 0) { queryParam = areasParam; }
		if (sitesParam.length > 0) { queryParam = (queryParam.length > 0) ? `${queryParam}&${sitesParam}` : sitesParam; }
		queryParam = `${queryParam}&includeStationAreas=true`;

		return `${this.baseUrl}/GetStationsByAreas?${queryParam}`;
	}

	private getStationsBySiteUrl(siteId: number){
		return `${this.baseUrl}/GetStationBySiteIdCommerical?siteId=${siteId}`;
	}

	private getStationbyFlowZoneIdUrl(flowZoneId: number): string {
		return `${this.baseUrl}/getStationbyFlowZoneId?flowZoneId=${flowZoneId}`;
	}

	private get getStationsListUrl(): string {
		return `${this.baseUrl}/GetStationList`;
	}

	private getStationsListForSatelliteUrl(satId: number): string {
		return `${this.baseUrl}/GetStationListForSatellite?satelliteId=${satId}`;
	}

	private getStationListItemUrl(stationId: number): string {
		return `${this.baseUrl}/getStationListItem?stationId=${stationId}`;
	}

	private getMoveStationsUrl(holeId: number, areaId: number, subAreaId: number, applyAreaDefaults: boolean, applyNewDefaultNames: boolean) {
		return `${this.baseUrl}/MoveStations?holeId=${holeId}&areaId=${areaId}&subAreaId=${subAreaId}`
			+ `&applyAreaDefaults=${applyAreaDefaults}&applyNewDefaultNames=${applyNewDefaultNames}`;
	}

	private getNextScheduledIrrigationUrl(minutes: number): string {
		return `${this.baseUrl}/GetNextScheduledIrrigation?minutes=${minutes}`;
	}
	
	private getIsOKToMoveUrl(destinationSatelliteId: number, destinationWireGroup?: number): string {
		return `${this.baseUrl}/IsOKToMove?destinationSatelliteId=${destinationSatelliteId}`
			+ (destinationWireGroup != null ? `&destinationWireGroup=${destinationWireGroup}` : ``);
	}

	private moveStationsToSatelliteUrl(destinationSatelliteId: number, destinationWireGroup?: number): string { 
		return `${this.baseUrl}/MoveStationsToSatellite?destinationSatelliteId=${destinationSatelliteId}`
			+ (destinationWireGroup != null ? `&destinationWireGroup=${destinationWireGroup}` : ``);
	}

	private getReorderStationsUrl(siteId: number, holeId: number, areaId: number) {
		return `${this.baseUrl}/v2/ReorderStations?siteId=${siteId}&holeId=${holeId}&areaId=${areaId}`;
	}

	private getStationsNotInProgramUrl(controllerId: number, programId: number): string {
		return `${this.baseUrl}/GetStationsNotInProgram?programId=${programId}` +
			(controllerId == null ? '' : `&satelliteId=${controllerId}`);
	}

	private getStationProgramsUrl(stationIds: number[]): string {
		return `${this.baseUrl}/GetStationPrograms`;
	}

	private get updateBatchesUrl(): string { return `${this.baseUrl}/UpdateBatches`; }

	private get updateMultipleBatchesUrl(): string { return `${this.baseUrl}/UpdateMultipleBatches`; }

	private get reConnectStations(): string { return `${this.baseUrl}/ReConnectStations`; }

	private getStationsUrl(controllerId: number, visibleStationsOnly = false): string { return `${this.baseUrl}/GetStations?satelliteId=${controllerId}
	&visibleStationsOnly=${visibleStationsOnly}`; }

	private getAllStationsUrl(filter?: IStationFilter): string {
		return `${this.baseUrl}/GetAllStations${(filter == null) ? '' : '?' + this.getStationFilterParams(filter)}`;
	}

	private getExtDataForProgamSummaryUrl(controllerId: any): string {
		return `${this.baseUrl}/GetExtDataForProgamSummary?satelliteId=${controllerId}`;
	}

	private getStationFilterParams(filter: IStationFilter): string {
		const flags: string[] = [];
		if (filter.includeSatellite) {
			flags.push('includeSatellite=true');
		}
		if (filter.includeProgramSteps) {
			flags.push('includeProgramSteps=true');
		}
		if (filter.includeStationAreas) {
			flags.push('includeStationAreas=true');
		}
		if (filter.includeRBCatalog_Nozzle) {
			flags.push('includeRBCatalog_Nozzle=true');
		}

		if (filter.sortByTerminal) {
			flags.push('sortByTerminal=true');
		}
		if (filter.sortByName) {
			flags.push('sortByName=true');
		}

		if (filter.visibleStationsOnly) {
			flags.push('visibleStationsOnly=true');
		}

		// Build &-separated list of the parameters as a string.
		return flags.join('&');
	}
}

export interface IStationFilter {
	includeSatellite?: boolean;
	includeProgramSteps?: boolean;
	includeStationAreas?: boolean;
	includeRBCatalog_Nozzle?: boolean;
	sortByTerminal?: boolean;
	sortByName?: boolean;
	visibleStationsOnly?: boolean;
}

import * as AwsSDK from 'aws-sdk';

import { EnvironmentService } from '../../common/services/environment.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenVendingMachineApi } from './token-vending-machine-api.service';

@Injectable({
	providedIn: 'root',
})
export class TokenVendingMachineManager {
	constructor(
		private tokenVendingMachineApiService: TokenVendingMachineApi,
		protected env: EnvironmentService
	) {
	}

	getAwsCredentials(): Observable<AwsSDK.Credentials> {
		return this.tokenVendingMachineApiService.getAwsCredentials().pipe(
			tap((awsCredentials) => {
				AwsSDK.config.update({
					region: this.env.awsDynamoDBRegion,
					accessKeyId: awsCredentials.accessKeyId,
					secretAccessKey: awsCredentials.secretAccessKey,
					sessionToken: awsCredentials.sessionToken
				})
			})
		)
	}
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-icm-status-cell-renderer',
	templateUrl: './icm-status-cell-renderer.component.html',
	styleUrls: ['./icm-status-cell-renderer.component.scss']
})
export class IcmStatusCellRendererComponent implements ICellRendererAngularComp {

	data: any;

	RbEnums = RbEnums;

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.data = params.data;
	}

	refresh(params: any): boolean {
		this.data = params.data;
		return true;
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================
}

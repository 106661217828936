<div class="rb-units-of-measure-container ">
	<mat-form-field>
		<input matInput type="text"
			[placeholder]="name"
			required
			rbGroupDecimalSeparator
			[disabled]="isDisabled"
			(change)="onMatInputChange()"
			[ngModel]="separatorString"
			(focus)="$event.target.select()"
			(ngModelChange)="onSeparatorChanged('separatorString', $event)"
			(focus)="$event.target.select()" />
			<button *ngIf="showHelpButton" mat-icon-button class="blue" mat-button matSuffix
				[matMenuTriggerFor]="helpContent">
				<mat-icon class="help-icon" >info_outline</mat-icon>
			</button>
	</mat-form-field>
	<div class="tr warnColorText" [ngClass]="{'black' : !isDisabled, 'gray' : isDisabled}" >{{hintLabel}}</div>

	<!-- Placeholder for MatMenu -->
	<ng-content></ng-content>
</div>

<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="!isMobile" class="station-diagnostics-filter-desktop" [class.mobile-display]="isMobile">
	<div class="left-side">
		<div class="selection-container">
				<mat-select [placeholder]="'STRINGS.TEST_TYPE' | translate" [disabled]="isSelectTestTypeDisabled" [ngModel]="testTypeId" (ngModelChange)="changeTestType($event)">
					<mat-option *ngFor="let testType of testTypes" [value]="testType.id">{{testType.name}}</mat-option>
				</mat-select>
		</div>

		<div class="button-area">
			<button (click)="onRunButtonClicked()" [disabled]="isStartButtonDisabled" >
				{{'STRINGS.RUN_TEST_UPPERCASE' | translate}}
			</button>
			<button (click)="onCancelButtonClicked()" 
				style="margin-left: 10px;" 
				[disabled]="!cancelButtonEnabled">
				{{'STRINGS.CANCEL_TEST_UPPERCASE' | translate}}
			</button>
		</div>
	</div>

<!--	<div class="right-side">-->
<!--		FILTER-->
<!--	</div>-->
</div>

<rb-waiting-indicator
	*ngIf="isBusy"
	[isVisible]="isBusy"
	[error]="loadError"
	(okClick)="onActionErrorAcknowledged()">
</rb-waiting-indicator>
import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { CCWeatherApiService } from '../../../../../api/ccweather/ccweather-api.service';
import { CCWeatherCondition } from '../../../../../api/ccweather/models/ccweather-condition.model';
import { CCWeatherDataService } from '../../../../../api/ccweather/ccweather-data.service';
import { DOCUMENT } from '@angular/common';
import { MessageBoxService } from '../../../../../common/services/message-box.service';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbGridsterWidget } from '../../../../../dashboard/components/widgets/rb-gridster-widget';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { Site } from '../../../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../../../api/sites/site-manager.service';
import { take } from 'rxjs/operators';
import { WeatherSource } from '../../../../../api/weather-sources/models/weather-source.model';
import { WeatherSourceManagerService } from '../../../../../api/weather-sources/weather-source-manager.service';
import WeatherSourceType = RbEnums.Common.WeatherSourceType;

@Component({
	selector: 'rb-ccweather-widget-settings',
	templateUrl: './ccweather-widget-settings.component.html',
	styleUrls: ['./ccweather-widget-settings.component.scss']
})
export class CCWeatherWidgetSettingsComponent implements OnInit {
	private readonly MIN_SHOWN_CONDITIONS_NUMBER = 3;

	@Input() widgetId: number;
	@Input() isInitialized: boolean;
	@Input() isIq4SidebarWidget = false;
	@Input() associatedWidget: RbGridsterWidget;
	@Input() theme = '';

	isDropdownOpened = false;
	selectedSiteId;
	selectedSiteName;

	requiredConditions: string[];
	conditions: CCWeatherCondition[] = [];
	visibleBlocks: { name: string, show: boolean }[] = [];
	sites: Site[];
	selectedSite: Site;
	elevation: number;

	isGolfSite = false;
	weatherSources: WeatherSource[];
	selectedWeatherSourceId: number;
	selectedWeatherSourceName: string;
	selectedWeatherSource: WeatherSource;

	get elevationFT(): number {
		// If the user is using English units, easy: return elevation.
		if (RbUtils.User.unitsType === RbEnums.Common.UnitsType.English) {
			return this.elevation;
		}

		return RbUtils.Common.ToLength(this.elevation, RbEnums.Common.LengthUnit.Meter, RbEnums.Common.LengthUnit.Foot);
	}

	set elevationFT(value: number) {
		// Convert if not using English.
		if (RbUtils.User.unitsType === RbEnums.Common.UnitsType.English) {
			this.elevation = value;
		} else {
			this.elevation = RbUtils.Common.ToLength(value, RbEnums.Common.LengthUnit.Foot, RbEnums.Common.LengthUnit.Meter);
		}
	}

	shownConditionsNumber = 6;
	private keywords = ['maps.googleapis'];

	@Output() settingsClosed = new EventEmitter<boolean>();

	constructor(@Inject(DOCUMENT) private document: Document,
				private ccWeatherDataService: CCWeatherDataService,
				private ccWeatherApiService: CCWeatherApiService,
				private renderer2: Renderer2,
				private siteManagerService: SiteManagerService,
				private messageBoxService: MessageBoxService,
				private weatherSourceManager: WeatherSourceManagerService
	) { }

	ngOnInit() {
		this.isGolfSite = this.siteManagerService.isGolfSite;

		if (!this.isGoogleScriptLoaded()) {
			const script = this.renderer2.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAScTFPStgZvcf5r0zKCCBsJgzQ8Gk54cQ';
			script.text = ``;
			script.onload = () => {
			};
			// script.onerror = reject;
			this.renderer2.appendChild(this.document.head, script);
		}
		this.requiredConditions = ['temperature', 'temperatureMinMax', 'wind', 'dewPoint', 'pressureSurfaceLevel', 'humidity', 'precipitationIntensity', 'et0'];
		const settings = this.ccWeatherDataService.getSettings(this.widgetId, this.requiredConditions);
		this.conditions = settings.conditions;
		this.visibleBlocks = settings.visibleBlocks;
		this.selectedSiteId = settings.siteId || this.siteManagerService.selectedSite.id;

		this.siteManagerService.getSites()
			.pipe(take(1))
			.subscribe(sites => {
				this.sites = sites;
				this.selectedSite = this.sites.find(site => {
					return site.id === this.selectedSiteId;
				});
				this.selectedSiteName = this.selectedSite == null ? '' : this.selectedSite.name;
			});

		if (!this.isGolfSite) {
			this.weatherSourceManager.getWeatherSources()
				.pipe(take(1))
				.subscribe((sources: WeatherSource[]) => {
					this.weatherSources = sources.filter(ws => ws.type === WeatherSourceType.GlobalWeatherPro);
					if (this.weatherSources.length > 0) {
						const prevWeatherSourceId = this.associatedWidget?.gwpWeatherSource?.id || -1;
						this.selectedWeatherSource = prevWeatherSourceId >= 0
							? this.weatherSources.find(ws => ws.id === prevWeatherSourceId)
							: this.weatherSources[0];
						if (this.selectedWeatherSource) {
							this.selectedWeatherSourceName = this.selectedWeatherSource.name;
						}
					}
				});
		}

		this.elevationFT = settings.elevationFT;
		this.shownConditionsNumber = 0;
		this.conditions.forEach(condition => {
			if (condition.show) this.shownConditionsNumber++;
		});
	}

	onDropdownClick() {
		this.isDropdownOpened = !this.isDropdownOpened;
	}

	onItemPick(item) {
		if (this.isGolfSite) {
			// For Golf, item is a Site
			this.selectedSite = item;
			this.selectedSiteName = item.name;
			this.selectedSiteId = item.id;
		} else {
			// For Commercial, item is a Weather Source.
			this.selectedWeatherSource = item;
			this.selectedWeatherSourceName = item.name;
			this.selectedWeatherSourceId = item.id;
		}

		this.onDropdownClick();
	}

	isShowConditionsMin(): boolean {
		return this.shownConditionsNumber <= this.MIN_SHOWN_CONDITIONS_NUMBER;
	}

	onFieldShowHide(field): void {
		if (field.show && this.isShowConditionsMin()) {
			return;
		}
		field.show = !field.show;
		this.shownConditionsNumber = this.shownConditionsNumber + (field.show ? 1 : -1);
	}

	onBlockShowHide(block) {
		block.show = !block.show;
	}

	onConfirmButtonClick(isSave: boolean) {
		if (isSave) {
			// IQ4 Sidebar Widget
			if (this.isIq4SidebarWidget) {
				this.ccWeatherDataService.setSettings(this.conditions, this.visibleBlocks,
					this.selectedSiteId, this.widgetId, this.associatedWidget.locationId, this.associatedWidget.databaseWidgetId,
					this.associatedWidget.gwpWeatherSource.elevation);
				this.settingsClosed.emit(true);
				return;
			}

			if (!this.isGolfSite) {
				const ws = this.selectedWeatherSource;
				this.createUpdateLocation(ws.latitude, ws.longitude, ws.elevation, ws.timeZone, ws);
				return;
			}

			const geoCoder = new google.maps.Geocoder();
			const self = this;
			geoCoder.geocode({
				address: `${this.selectedSite.address} ${this.selectedSite.city} ${this.selectedSite.state} ${this.selectedSite.zip}`
			}, function(results, status) {
				if (status !== google.maps.GeocoderStatus.OK || results == null || results.length === 0) {
					// This is an error which the user needs to understand. We can't find the lat/long value from the indicated site
					// address. Of course, this would impact the map positioning, too, but in this case, the widget can't be created,
					// so will suddenly disappear if we don't display a message.
					self.messageBoxService.showMessageBox('DASHBOARD.CCWEATHER_GEOCODE_ERROR');

					self.settingsClosed.emit(false);
					return;
				} else {
					const latLong = results[0].geometry.location;
					const latitude = latLong.lat();
					const longitude = latLong.lng();
					self.createUpdateLocation(latitude, longitude, self.elevationFT, self.selectedSite.timeZone);
				}
			});
		} else {
			this.settingsClosed.emit(false);
		}
	}

	isGoogleScriptLoaded() {
		// find if there is a Google map script
		const scripts = document.head.getElementsByTagName('script');
		for (let i = scripts.length - 1; i >= 0; i--) {
			const scriptSource = scripts[i].getAttribute('src');
			if (scriptSource != null) {
				if (this.keywords.filter(item => scriptSource.includes(item)).length) {
					return true;
				}
			}
		}
		return false;
	}

	createUpdateLocation(latitude: number, longitude: number, elevationFT: number, timeZone: string, weatherSource?: WeatherSource) {
		const settings = this.ccWeatherDataService.getSettings(this.widgetId, [], false);
		let weatherSourceId: number;
		let weatherSourceName = '';

		if (!this.isIq4SidebarWidget && weatherSource != null) {
			weatherSourceId = weatherSource.id;
			weatherSourceName = weatherSource.name;
		}

		if (this.isInitialized) {
			// IQ4 Sidebar Widget
			// if (this.isIq4SidebarWidget) {
			// 	// No changes to the location can be made for IQ4 Sidebar Widget instances.
			// 	this.ccWeatherDataService.setSettings(this.conditions, this.visibleBlocks,
			// 		this.selectedSiteId, this.widgetId, settings.locationId, settings.databaseWidgetId,
			// 		elevationFT);
			// 	this.settingsClosed.emit(true);
			// 	return;
			// 	// weatherSourceId = this.associatedWidget.gwpWeatherSource.id;
			// 	// weatherSourceName = this.associatedWidget.gwpWeatherSource.name;
			// }

			this.ccWeatherApiService.updateLocation(settings.databaseWidgetId, {
				name: this.isGolfSite ? this.selectedSiteName : weatherSourceName,
				siteId: this.selectedSiteId,
				weatherSourceId: weatherSourceId || null,
				latitude: latitude,
				longitude: longitude,
				elevationFT: elevationFT,
				timeZone: timeZone
			})
				.pipe(take(1))
				.subscribe(() => {
					this.ccWeatherDataService.setSettings(this.conditions, this.visibleBlocks,
						this.selectedSiteId, this.widgetId, settings.locationId, settings.databaseWidgetId,
						elevationFT, weatherSourceId);

					// For commercial, associate the selected weather source with the widget.
					if (!this.isGolfSite && weatherSource != null) {
						this.associatedWidget.gwpWeatherSource = weatherSource;
					}

					this.settingsClosed.emit(true);
				});
		} else {
			this.ccWeatherApiService.createLocation(
				{
					name: this.isGolfSite ? this.selectedSiteName : weatherSourceName,
					siteId: this.selectedSiteId,
					weatherSourceId: weatherSourceId || null,
					latitude: latitude,
					longitude: longitude,
					elevationFT: elevationFT,
					timeZone: timeZone
				}
			)
				.pipe(take(1))
				.subscribe((data) => {
					// Now that we've created the location/widget, save the information about it.
					this.ccWeatherDataService.setSettings(this.conditions, this.visibleBlocks,
						this.selectedSiteId, this.widgetId, data.locationId, data.widgetId,
						elevationFT, weatherSourceId);

					// For commercial, associate the selected weather source with the widget.
					if (!this.isGolfSite && weatherSource != null) {
						this.associatedWidget.gwpWeatherSource = weatherSource;
					}

					this.settingsClosed.emit(true);
				});
		}
	}

	/**
	 * Return the units string for elevation value entered in settings. Should be either "ft" or "m", depending on the user selected units system.
	 */
	getElevationUnitsString(): string {
		if (RbUtils.User.unitsType === RbEnums.Common.UnitsType.English) {
			return RbUtils.Common.getLengthUnitsString(RbEnums.Common.LengthUnit.Foot);
		}
		return RbUtils.Common.getLengthUnitsString(RbEnums.Common.LengthUnit.Meter);
	}
}

import * as moment from 'moment';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class MasterValveListItem {
	addressInt: number;
	borrowedMvid: number;
	borrowState: RbEnums.Common.BorrowState;
	flowMonitoringId: number;
	flowRate: number;
	flowZone: string;
	flowZoneId: number;
	id: number;
	monthlyCyclingEnabled = false;
	monthlyCyclingTime: Date;
	name: string;
	satelliteId: number;
	satelliteName: string;
	satelliteIqNetType: RbEnums.Common.IqNetType;
	satelliteFloWatchEnabled = false;
	siteId: number;
	siteName: string;
	terminal: number;
	type: RbEnums.Common.StationType;
	valveType: RbEnums.Common.ValveType;
	pump: boolean;
	mvDelayLong: number;
	useLocalMv = false;

	private readonly NO_VALUE = '-';

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.monthlyCyclingTime) { this.monthlyCyclingTime = RbUtils.Conversion.convertStringToDate(json.monthlyCyclingTime); }
		}
	}

	// noinspection JSUnusedGlobalSymbols - used by grid
	get monthlyCycling(): string {
		if (!this.monthlyCyclingEnabled || this.type === RbEnums.Common.StationType.NormallyClosed) return this.NO_VALUE;
		if (!this.monthlyCyclingTime) return this.NO_VALUE;

		return moment(this.monthlyCyclingTime).format('HH:mm');
	}

	static createDefault(): MasterValveListItem {
		const item = new MasterValveListItem();
		item.addressInt = 0;
		item.flowMonitoringId = 0;
		item.flowRate = 0;
		item.id = 0;
		item.name = '';
		item.satelliteId = 0;
		item.terminal = 0;

		return item;
	}
}

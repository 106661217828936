
export class UniversalPingResultData {

	constructor(json: any = null, controllerId: number, changeDateTime: Date) {
		if (json) {
			Object.assign(this, json);
		}

		this.changeDateTime = changeDateTime;
		this.controllerId = controllerId;
	}

	current: number;
	dataReady = false;
	deviceAddress: number;
	deviceNumber: 1;
	pingRequestType: string;
	responded = false;
	timestamp: number;
	voltage: number;
	changeDateTime: Date;
	controllerId: number;
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { SiteTreeView } from '../../../../../api/sites/models/site-tree-view.model';

@Component({
	selector: 'rb-select-single-controller',
	templateUrl: './select-single-controller.component.html',
	styleUrls: ['./select-single-controller.component.scss'],
})
export class SelectSingleControllerComponent implements OnInit, OnDestroy {
	@Input() selectedControllerIds: number[] = [];
	@Input() treeItems: SiteTreeView[] = [];
	@Output() complete = new EventEmitter<number[]>();

	currentlySelectedControllerId;
	constructor(protected deviceManager: DeviceManagerService) { }

	ngOnInit() {
		this.currentlySelectedControllerId = this.selectedControllerIds == null || this.selectedControllerIds.length < 1 ? 0 : this.selectedControllerIds[0];
	}

	ngOnDestroy(): void {
		/* Implemented to support untilDestroyed */
	}

	onCancel() {
		this.complete.emit(null);
	}

	onSubmit() {
		this.complete.emit([this.currentlySelectedControllerId]);
	}

	onSelectController(node) {
		this.currentlySelectedControllerId = node.value;
	}
}

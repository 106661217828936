import { Injector, NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { BaseCellTooltipComponent } from './components/tables/cell-tooltips/base-cell-tooltip/base-cell-tooltip.component';
import { CheckboxTableFilterComponent } from './components/tables/filters/checkbox-table-filter/checkbox-table-filter.component';
import { CheckboxTableFlagFilterComponent } from './components/tables/filters/checkbox-table-flag-filter/checkbox-table-flag-filter.component';
import { ColumnComponent } from './components/tables/column/column.component';
import { CoreModule } from '../core/core.module';
import { CycleSoakCellEditorComponent } from './components/editable-grid/cell-editors/cycle-soak-cell-editor/cycle-soak-cell-editor.component';
import { DurationShortcutComponent } from './components/duration-shortcut/duration-shortcut.component';
import { EditableGridComponent } from './components/editable-grid/editable-grid.component';
import { FabButtonComponent } from './components/buttons/fab-button/fab-button.component';
import { FabGroupButtonComponent } from './components/buttons/fab-group-button/fab-group-button.component';
import { GridContainerComponent } from './components/grid-container/grid-container.component';
import {
	IrrigationQueueCommandsComponent
} from '../sections/controllers/manual-ops/manual-ops-irrigation-queue-tab/irrigation-queue-commands/irrigation-queue-commands.component';
import { NoDataOverlayComponent } from './components/no-data-overlay/no-data-overlay.component';
import { NumberFormatterComponent } from './components/editable-grid/cell-renderers/number-formatter/number-formatter.component';
import {
	ProgramMobileCellRendererComponent
} from './components/tables/mobile-cell-renderers/program-mobile-cell-renderer/program-mobile-cell-renderer.component';
import { SelectListCellEditorComponent } from './components/editable-grid/cell-editors/select-list-cell-editor/select-list-cell-editor.component';
import { StationAddressCellEditorComponent } from './components/editable-grid/cell-editors/station-address-cell-editor/station-address-cell-editor.component';
import {
	StationFlowRateCellEditorComponent
} from './components/editable-grid/cell-editors/station-flow-rate-cell-editor/station-flow-rate-cell-editor.component';
import { TableWrapperComponent } from './components/tables/table-wrapper/table-wrapper.component';
import { UniqueNameCellEditorComponent } from './components/editable-grid/cell-editors/unique-name-cell-editor/unique-name-cell-editor.component';

@NgModule({
    imports: [
        CoreModule,
        AgGridModule.withComponents([
            CheckboxTableFilterComponent,
            CheckboxTableFlagFilterComponent,
            BaseCellTooltipComponent,
            NumberFormatterComponent,
            UniqueNameCellEditorComponent,
            StationAddressCellEditorComponent,
            StationFlowRateCellEditorComponent,
            CycleSoakCellEditorComponent,
            SelectListCellEditorComponent
        ]),
    ],
    declarations: [
        BaseCellTooltipComponent,
        CheckboxTableFilterComponent,
        CheckboxTableFlagFilterComponent,
        NumberFormatterComponent,
        ProgramMobileCellRendererComponent,
        UniqueNameCellEditorComponent,
        StationAddressCellEditorComponent,
        StationFlowRateCellEditorComponent,
        CycleSoakCellEditorComponent,
        SelectListCellEditorComponent,
        ColumnComponent,
        EditableGridComponent,
        FabButtonComponent,
        FabGroupButtonComponent,
        GridContainerComponent,
        IrrigationQueueCommandsComponent,
        NoDataOverlayComponent,
        TableWrapperComponent,
		DurationShortcutComponent,
    ],
    exports: [
        CoreModule,
        BaseCellTooltipComponent,
        CheckboxTableFilterComponent,
        CheckboxTableFlagFilterComponent,
        ColumnComponent,
        EditableGridComponent,
        GridContainerComponent,
        IrrigationQueueCommandsComponent,
        NoDataOverlayComponent,
        TableWrapperComponent,
		DurationShortcutComponent,
    ],
    providers: []
})
export class AgGridUiModule {
	constructor(injector: Injector) {
		// AppInjector = injector;
	}
}

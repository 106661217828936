<div class="h-100 w-100 flex flex-column overflow-y-hidden program-tree-body">

    <div class="header">
		<div class="title-search">
			<h3 class="title">{{ 'STRINGS.PROGRAMS_AND_SCHEDULES' | translate}}</h3>
		</div>
		
    </div>
    
    <div class="flex-grow-1 overflow-y-auto program-tree-container">
        <mat-tree class="tree-list" [dataSource]="dataSource" [treeControl]="treeControl" [trackBy]="trackByFn">
            
            <!-- Station Node -->
            <mat-tree-node *matTreeNodeDef="let node">
                <div cdkDropList cdkDropListConnectedTo="map" class="item-list w-100">
                    <div 
                        class="title-content item-list overflow-hidden" cdkDrag 
                        [cdkDragDisabled]="!node.station || node.station.hasLocation || dragDisabled || !node.currentSite" 
                        [cdkDragData]="node"
                        (cdkDragMoved)="onDragMoved.emit($event)" 
                        (cdkDragReleased)="onDragEnded.emit()">

                        <div class="div-icon">
                            <mat-icon class="location-icon-font" 
                                *ngIf="node.station && !node.station.hasLocation && node.currentSite">
                                add_location
                            </mat-icon>
                            <i class="station no-drag-icon" 
                                cdkDragHandle *ngIf="!node.station || node.station.hasLocation || !node.currentSite">
                                <div class="outer-circle" >
                                    <div class="inner-circle" 
                                        [style.background]="node.station?.nozzleColor ? node.station.nozzleColor.toLowerCase() : '#ddd' ">
                                    </div>
                                </div>
                            </i>
                            <i class="mdi mdi-circle-double text-orange drag-icon" 
                                [style.font-size]="'34px'"
                                *cdkDragPreview>
                            </i>
                        </div>

                        <div class="div-name">
                            <span class="station-name title-text ellipsis-text no-select" [class.has-location]="!node.station || !node.station.hasLocation"
                                cdkDragHandle [cdkDragHandleDisabled]="isMobile || !node.station || node.station.hasLocation" 
                                matTooltip="{{node.station?.name}}" matTooltipPosition="above" [matTooltipDisabled]="!(node.station?.name.length >= 12)">
                                <!-- Using `!(length >= 12)` to keep the tooltip disabled if we don't have a station -->
                                {{node.station?.name}}
                            </span>
                        </div>

                        <div class="div-status flex items-center">
                            <span *ngIf="node.station?.mapStatus && node.station?.irrigationStatus == irrigationStatus.Running"
                                class="station-status-cycling" [matTooltip]="'STRINGS.CYCLING' | translate">
                                <i class="icon-water"></i>
                            </span>
                            <span *ngIf="node.station?.mapStatus && node.station?.irrigationStatus == irrigationStatus.Soaking"
                                class="station-status-soaking" [matTooltip]="'STRINGS.SOAKING' | translate">
                                <i class="icon-timer"></i>
                            </span>

                            <div class="text" [rbScrollText]="100">
                                <span>{{ node.station?.irrigationStatus == irrigationStatus.Running || node.station?.irrigationStatus == irrigationStatus.Soaking? node.station?.mapStatus :
                                    node.station?.status }}</span>
                            </div>

                        </div>

                        <div class="div-checkbox" *ngIf="!isWidget">
                            <mat-checkbox 
                                class="tree-checkbox ml-auto" 
                                [(ngModel)]="node.isSelected"
                                (change)="onStationChange({station: node})"
                            >
                            </mat-checkbox>
                        </div>

                    </div>
                </div>            
            </mat-tree-node>
            
            <!-- Program or Schedule NodeGroups -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: isProgramOrSchedule" >
    
                <div class="title-content item-list overflow-hidden">

                    <div class="div-expansion-icon" matTreeNodeToggle [class.silver]="!node.children?.length">
                        <mat-icon class="expansion-icon">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </div>

                    <div class="div-name" [class.silver]="!node.children?.length" matTooltip="{{node.name}}" matTooltipPosition="above" [matTooltipDisabled]="node.name.length < 15">
                        {{node.name}}
                    </div>

                    <div class="div-status">
                        <div class="text" [rbScrollText]="100">
                            <span>{{node.status}}</span>
                        </div>
                    </div>
                
                    <div class="div-checkbox" *ngIf="!isWidget">
                        <mat-checkbox class="ml-auto" [(ngModel)]="node.isSelected" (change)="onNodeChange(node)"
                            [indeterminate]="node.checkState === 1">
                        </mat-checkbox>
                    </div>
                    
                </div>

                <div class="children-spacing" *ngIf="treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet *ngIf="node.children?.length > 0; else noData"></ng-container>
                </div>

            </mat-nested-tree-node>
        </mat-tree>
    </div>

    <ng-template #noData>
        <div class="noData flex items-center pl3 silver">{{ 'STRINGS.NO_DATA_TO_DISPLAY' | translate }}</div>
    </ng-template>

    <div *ngIf="!isWidget" id="mobile-main-pane-toolbar" class="main-panel-container" >
        <rb-main-panel-toolbar
            styleClass="rb-map-toolbar"

            [selectedGridItems]="selectedItems"

            [includePlay]="true"
            [includePause]="true"
            [includeStop]="true"
            [includeAdvance]="true"
            [includeResume]="true"
            
            [disableResume]="disableResume"

            (playClick)="onStartClick()"
            (pauseClick)="onPauseClick()"
            (stopClick)="onStopClick()"
            (advanceClick)="onAdvanceClick()"
            
            (resumeClick)="onResumeClick()"
            
        >
        </rb-main-panel-toolbar>
    </div>

    <!-- 
        [includeDelete]="false" 
        (deleteClick)="onDeleteClick()"
    -->

</div>

<ng-template #startStationModal>
	<rb-start-station-modal
		[selectedItemIds]="selectedStationIds"
		[selectedStationIds]="selectedStationIds"
		[isGolfController]="false"
		[isProgramStepStation]="false"
		
		[showTabs]="true"
		[station]="selectedStation"
		(stationStartRequested)="onProgramStepStartRequested()"
	>
    <!-- 
        [controllerId]="controllerId"
        [maxHours]="maxHours"
     -->
	</rb-start-station-modal>
</ng-template>


<mat-form-field class="">
	<mat-select
		class="multi-controller-select"
		[panelClass]="'multi-controller-select-panel'"
		placeholder="{{selectedControllersPlaceholder | translate}}"
		#matSelect
	>
			<mat-option
				class="multi-controller-select-header"
				disabled
			>
				{{selectedControllersPlaceholder | translate}}
			</mat-option>
			<div class="select-all-controls">
				<mat-checkbox
					color="primary"
					[checked]="isAllNodesSelected"
					(change)="onSelectAllChange($event)"
					class="select-all checklist-leaf-node"
				>
					{{ 'STRINGS.SELECT_ALL_UPPERCASE' | translate }}
				</mat-checkbox>
			</div>
		<div class="multi-controller-select-radio-container">
			<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
				<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
					<button mat-icon-button disabled></button>
					<mat-checkbox color="primary" class="checklist-leaf-node"
						[disabled]="node.isDisabled"
						[checked]="isSelected(node)"
						(change)="onSelectedItemsChanged(node);">{{node.item}}
					</mat-checkbox>
				</mat-tree-node>

				<mat-tree-node *matTreeNodeDef="let node; when: hasChild " matTreeNodePadding>
					<button mat-icon-button matTreeNodeToggle
						[attr.aria-label]="'toggle ' + node.filename">
						<mat-icon class="mat-icon-rtl-mirror">
							{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
						</mat-icon>
					</button>
					<mat-checkbox color="primary" 
						[disabled]="node.isDisabled"
						[checked]="descendantsAllSelected(node)"
						[indeterminate]="descendantsPartiallySelected(node)"
						(change)="itemSelectionToggle($event, node)">{{node.item}}
					</mat-checkbox>
				</mat-tree-node>
			</mat-tree>
		</div>

	</mat-select>
</mat-form-field>

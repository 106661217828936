import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { SensorListItem } from '../../../../../api/sensors/models/sensor-list-item.model';

@Component({
	selector: 'rb-sensor-mobile-cell-renderer',
	templateUrl: './sensor-mobile-cell-renderer.component.html',
	styleUrls: ['./sensor-mobile-cell-renderer.component.scss']
})
export class SensorMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	sensor: SensorListItem;
	onConfigureAutomaticActionsLinkClick: (data: any, event: any) => {};
	isAutomaticActionsShownInGrid: boolean
	isAddressShownInGrid: boolean

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.sensor = params.data;
		this.onConfigureAutomaticActionsLinkClick = params.configureAutomaticActionsClick;
		this.isAutomaticActionsShownInGrid = params.isAutomaticActionsShownInGrid;
		this.isAddressShownInGrid = params.isAddressShownInGrid;
	}
}

import { Injectable, OnDestroy } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { Site, SiteLocalChange } from './models/site.model';

import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { AuthManagerService } from '../auth/auth-manager-service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { LicenseManagerService } from '../license/license-manager.service';
import { RbConstants } from '../../common/constants/_rb.constants';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { SiteApiService } from './site-api.service';
import { SiteStatusChange } from '../signalR/site-status-change.model';
import { SiteTreeView } from './models/site-tree-view.model';
import { Snapshot } from '../controllers/models/snapshot';
import { UiSettingsService } from '../ui-settings/ui-settings.service';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';
import { UserSite } from './models/user-site.model';

@Injectable({
	providedIn: 'root'
})
export class SiteManagerService extends ServiceManagerBase implements OnDestroy {

	public static MAP_PREFERENCE_KEY_BASE = 'mapPref';
	private readonly NO_VALUE = '-';

	// Subjects
	selectedSitesChange = new Subject();
	sitesUpdate = new Subject<{ siteIds: number[], data: any }>();
	siteDeleted = new Subject();
	siteHoleCountChange = new Subject();
	siteAddressChange = new Subject<number>();
	siteLocalChange = new Subject<SiteLocalChange>()

	private _apiResult: ApiCachedRequestResponse<Site[]>;
	private sitesListLastReceivedTime: Date = null;

	// Cached Collections (Non-expiring)
	private _selectedSiteIds: number[];

	// Cached Objects (Non-expiring)
	private _selectedSite: Site;

	isCommercialHybridSite = false;

	// =========================================================================================================================================================
	// C'tor and Destroy
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private siteApiService: SiteApiService,
				protected broadcastService: BroadcastService,
				private uiSettingsService: UiSettingsService,
				private licenseManager: LicenseManagerService) {

		super(broadcastService);

		this.selectedSiteIds = [];

		// Get initial list of site ids for user.
		this.getSites().pipe(take(1)).subscribe();
		this.licenseManager.isCloud().pipe(take(1)).subscribe(isCloud => {
			this.isCommercialHybridSite = RbUtils.Common.isCommercialHybridSite(this.authManager.getUserProfile().siteType, isCloud);
		});
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
		this.siteApiService.clearCache();
		this._apiResult = null;
		this.selectedSiteIds = [];
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	get isGolfSite() {
		return RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
	}

	get siteIds(): number[] {
		if (!this._apiResult) return [];

		return this._apiResult.value.map(s => s.id);
	}

	get selectedSite(): Site {
		return this._selectedSite;
	}

	set selectedSite(site: Site) {
		this._selectedSite = site;
		this.broadcastService.selectedEntityChange.next(site);
	}

	get selectedSiteIds(): number[] {
		return this._selectedSiteIds;
	}

	set selectedSiteIds(siteIds: number[]) {
		this._selectedSiteIds = siteIds;
		this.selectedSitesChange.next(null);
	}


	// =========================================================================================================================================================
	// API Calls
	// =========================================================================================================================================================

	createSite(updateData: any): Observable<Site> {
		const addSite = {
			name: updateData.name,
			address: updateData.address,
			city: updateData.city,
			state: updateData.state,
			zip: updateData.zip,
			countryCode: updateData.countryCode,
			defaultWeatherSourceId: updateData.defaultWeatherSourceId,
			description: updateData.description,
			timeZone: updateData.timeZone,
			type: updateData.type,
			floGuardCapacity: updateData.floGuardCapacity,
			numberOfHoles: updateData.numberOfHoles,
			powerLineFrequency: updateData.powerLineFrequency,
			seasonalAdjust: updateData.seasonalAdjust
		};
		if (!addSite.seasonalAdjust) {
			// Make sure the site always have default for seasonal adjust data.
			addSite.seasonalAdjust = [RbConstants.Form.DEFAULT_SEASONAL_ADJUST_DATA]
		}

		return this.siteApiService.createSite(addSite).pipe(tap(site => this.sitesUpdate.next({ siteIds: [site.id], data: null })));
	}

	getNameUniqueness(name: string, id: number): Observable<UniquenessResponse> {
		return this.siteApiService.getNameUniqueness(name, id);
	}

	getNextDefaultName(siteType: number): Observable<string> {
		return this.siteApiService.getNextDefaultName(siteType);
	}

	deleteSites(siteIds: number[]) {
		return this.siteApiService.deleteSites(siteIds)
			.pipe(tap(() => {
				if (this.selectedSiteIds.some(s => siteIds.some(s2 => s === s2))) {
					this.selectedSiteIds = this.selectedSiteIds.filter(s => !siteIds.some(s2 => s === s2));
					this.selectedSitesChange.next(null);
				}
				this.siteDeleted.next(null);
			}));
	}

	getSelectedOrAllSiteIds(): Observable<number[]> {
		// RB-6291: Don't test if(array); you must check for null and undefined.
		if (this.selectedSiteIds != null && this.selectedSiteIds.length > 0) return of(this.selectedSiteIds);

		if (this._apiResult) return of(this.siteIds);

		return this.getSites()
			.pipe(
				take(1),
				map((sites: Site[]) => sites.map(s => s.id))
			);
	}

	getSelectedSites(): Observable<Site[]> {
		return this.getSites()
			.pipe(
				take(1),
				map(sites => sites.filter(s => this.selectedSiteIds.indexOf(s.id) !== -1))
			);
	}

	getSite(id: number): Observable<Site> {
		return this.siteApiService.getSite(id);
	}

	getSiteName(id: number): string {
		if (!this._apiResult || this._apiResult.value.length < 1) return this.NO_VALUE;

		const site = this._apiResult.value.find(s => s.id === id);
		return site ? site.name : this.NO_VALUE;
	}

	getSiteTimeZone(id: number): string {
		if (!this._apiResult || this._apiResult.value.length < 1) return this.NO_VALUE;
		const site = this._apiResult.value.find(s => s.id === id);
		if (site?.timeZoneLocalizedName) {
			const utcOffset = RbUtils.Conversion.convertTimeZoneToUTCOffset(site.timeZoneLocalizedName);
			return `${site.timeZoneAbbreviation} (${utcOffset})`;
		}
		return this.NO_VALUE;
	}

	getSites(bypassCache = false): Observable<Site[]> {
		// Guard against call after user logs out.
		if (!this.authManager.isLoggedIn) return of([]);

		// Secondary cache check - prevent multiple calls to API within 10 seconds regardless of bypassCache setting
		if (this.sitesListLastReceivedTime != null
			&& (new Date().getTime() - this.sitesListLastReceivedTime.getTime() < 10000) && this._apiResult) return of(this._apiResult.value);

		return this.siteApiService.getSites(bypassCache).pipe(map(response => {
			if (bypassCache) this.sitesListLastReceivedTime = new Date();
			this._apiResult = response;
			return this._apiResult.value;
		}));
	}

	// Used by Breadcrumb Service to fetch site name from cached sites list.
	getSiteFromSitesList(siteId: number) {
		return this.getSites().pipe(map((sites: Site[]) => sites.find(s => s.id === siteId)));
	}

	getTreeViewSites(checkContractorSBUController = false): Observable<SiteTreeView[]> {
		return this.siteApiService.getTreeViewSites(checkContractorSBUController);
	}

	getTreeViewSitesWithDeletedSatellites(): Observable<SiteTreeView[]> {
		return this.siteApiService.getTreeViewSitesWithDeletedSatellites();
	}

	getTreeViewSiteWithDeletedSatellites(siteId: number): Observable<SiteTreeView> {
		return this.siteApiService.getTreeViewSiteWithDeletedSatellites(siteId);
	}

	getUserSites(userId: number): Observable<UserSite[]> {
		return this.siteApiService.getUserSites(userId);
	}

	getVisibleSites(): Observable<Site[]> {
		return this.siteApiService.getVisibleSites();
	}

	updateSite(siteId: number, updateData: any): Observable<null> {
		return this.siteApiService.updateSite(siteId, updateData).pipe(tap(site => {
			this.updateCollection(siteId, updateData);
			if (updateData.address != null) {
				this.uiSettingsService.setPreference(`${SiteManagerService.MAP_PREFERENCE_KEY_BASE}_${siteId}`, null).subscribe();
			}

			if (this.selectedSite == null || this.selectedSite.id !== siteId) return;
			this.selectedSite = site;
			this.broadcastService.selectedEntityChange.next(site);
		}));
	}

	updateSites(siteIds: number[], updateData: any): Observable<null> {
		return this.siteApiService.updateSites(siteIds, updateData)
			.pipe(tap(() => {
				this.siteLocalChange.next(new SiteLocalChange(siteIds, updateData));
				siteIds.forEach(id => this.updateCollection(id, updateData));
				if (updateData.address != null) {
					siteIds.forEach(siteId => this.uiSettingsService.setPreference(`${SiteManagerService.MAP_PREFERENCE_KEY_BASE}_${siteId}`, null)
						.subscribe());
				}
				this.sitesUpdate.next({ siteIds: siteIds, data: updateData });
				if (this.selectedSite == null || !siteIds.some(id => id === this.selectedSite.id)) return;
				this.siteApiService.getSite(this.selectedSite.id).subscribe(site => {
					this.selectedSite = site;
					this.broadcastService.selectedEntityChange.next(site);
				});
			}));
	}

	updateUserSites(userSites: UserSite[]): Observable<null> {
		return this.siteApiService.updateUserSites(userSites);
	}

	/**
	 * This method works to update sites list in the secondary CACHE while adding/deleting site
	 * @param siteId Site of identity
	 * @param length
	 * @param siteStatusChange Site status change 
	 */
	updateSitesListItemsFromCache(siteId: number, siteStatusChange: SiteStatusChange) {
		if (this._apiResult && this._apiResult.cacheDatetime > siteStatusChange.changeDateTime) {
			return;
		}
		if (siteStatusChange.changeType === RbEnums.SignalR.SiteStatusChangeType.Deleted) {
			this.updateSitesListItemsByDeleting(siteId);
		} else if (siteStatusChange.changeType === RbEnums.SignalR.SiteStatusChangeType.Added) {
			this.updateSitesListItemsByAdding(siteStatusChange);
		}
	}

	/**
	 * Get list of sites from secondary CACHE which has been updated by getSites api or SignalR
	 * @returns list of sites
	 */
	getSitesListItemsFromCache(): Observable<Site[]> {
		if (this._apiResult) {
			return of(this._apiResult.value);
		} else {
			return of([]);
		}
	}

	/**
	 * Update site list items by site status change type Deleted from SignalR
	 * @param siteId Site identity
	 */
	private updateSitesListItemsByDeleting(siteId: number) {
		const tempSites: Site[] = [];
		if (!this._apiResult) {
			return;
		}
		const site = this._apiResult.value.find(s => s.id === siteId);
		if (site) {
			const index: number = this._apiResult.value.indexOf(site);
			if (index !== -1) {
				this._apiResult.value.splice(index, 1);
				this._apiResult.value = tempSites.concat(this._apiResult.value.sort((a,b) => {
					return a.name > b.name ? 1 : -1
				}));
			}
		}
	}

	/**
	 * Update site list items by site status change type Added from SignalR
	 * @param siteStatusChange Site Status Change
	 */
	private updateSitesListItemsByAdding(siteStatusChange: SiteStatusChange) {
		const tempSites: Site[] = [];
		let canSeeSite = true;
		if (!this.isGolfSite &&
				this.authManager.isLimitedSiteAccess && 
				this.authManager.currentUser.profile.name !== siteStatusChange.createdUserName) {
			canSeeSite = false;
		}

		if (!canSeeSite) {
			return;
		}

		const newSite = new Site(siteStatusChange.itemsChanged ? siteStatusChange.itemsChanged : []);
		if (newSite) {
			const site = this._apiResult.value.filter(s => s.id === newSite.id && s.name === newSite.name);
			if (site.length === 0) {
				this._apiResult.value = this._apiResult.value.concat(newSite);
				this._apiResult.value = tempSites.concat(this._apiResult.value.sort((a,b) => {
					return a.name > b.name ? 1 : -1
				}));
			}
		}
	}

	createSnapshot(siteId: number): Observable<null> {
		return this.siteApiService.createSnapshot(siteId);
	}

	createSnapshots(siteIds: number[]): Observable<any> {
		return this.siteApiService.createSnapshots(siteIds);
	}

	getSnapshots(siteId: number): Observable<Snapshot[]> {
		return this.siteApiService.getSnapshots(siteId);
	}

	restoreSnapshot(siteId: number, date: Date, forceRestore: boolean): Observable<null> {
		return this.siteApiService.restoreSnapshot(siteId, date, forceRestore).pipe(tap(() => {
			this.clearCache();
			this.broadcastService.siteRestored.next(null);
		}));
	}

	private updateCollection(id: number, updateData: any): void {
		const site = this._apiResult.value.find(s => s.id === id);
		if (site == null) return;
		Object.entries(updateData).forEach(([key, value]) => site[key] = value);
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div id="breadcrumb-trail-container">
	<div *ngFor="let bc of breadcrumbTrail; let i = index">
		<span *ngIf="i > 0" class="crumb-separator">&gt;</span>
		<!-- Disable the last crumb in the trail or any crumb w/o a route -->
		<a *ngIf="bc.route !== null && i < breadcrumbTrail.length - 1" class="crumb" [routerLink]="[bc.route]" [queryParams]="bc.queryParams">{{bc.name}}</a>
		<a *ngIf="bc.route === null || i >= breadcrumbTrail.length - 1" class="crumb non-navigable">{{bc.name}}</a>
	</div>
	<div *ngIf="isShowSupportContactInfo">
		<span>
			&nbsp;-&nbsp;
			<a href="https://www.rainbird.com/products/iq4" target="_blank" class="crumb crumb-link">
				{{'STRINGS.RESOURCES' | translate}}
			</a>
		</span>
		<span class="support-info">
			<a href="mailto:{{RbConstants.Common.SUPPORT_CONTACT.US_CANADA.email}}" class="crumb crumb-link">
				{{RbConstants.Common.SUPPORT_CONTACT.US_CANADA.email}}
			</a>
			&nbsp;|&nbsp;
			{{RbConstants.Common.SUPPORT_CONTACT.US_CANADA.phoneNumber}}
			{{'STRINGS.PHONE_SUPPORT_FOR_US_AND_CANADA_WARNING' | translate}}
		</span>
	</div>
</div>

export class ProgramETInformation {

	programId: number;
	companyId: number;
	weatherSourceId: number;
	weatherSourceName: string;
	highestMonthlyAverage: number;
	accumulatedET: number;
	accumulatedRain: number;
	simpleETPercentage: number;
	adjust: number;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}
}

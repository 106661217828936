import { Component, OnDestroy } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { StationStatusListItem } from '../../../../../api/stations/models/_station-status-list-item.model';

@Component({
	selector: 'rb-station-status-cell-renderer',
	templateUrl: './station-status-cell-renderer.component.html',
	styleUrls: ['./station-status-cell-renderer.component.scss']
})
export class StationStatusCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
	isRunning = false;
	isLocked = false;
	private stationStatusListItem: StationStatusListItem;

	// =========================================================================================================================================================
	// C'tor and lifecycle Hooks
	// =========================================================================================================================================================

	constructor() { }

	ngOnDestroy(): void {
		/** Required by untilDestroyed() */
		this.isRunning = false;
		this.isLocked = false;
		this.stationStatusListItem = null;
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.updateStationStatus(params);
	}

	refresh(params: any): boolean {
		this.updateStationStatus(params);
		return true;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get isCycling(): boolean {
		return this.stationStatusListItem.irrigationStatus === RbEnums.Common.IrrigationStatus.Running;
	}

	get isSoaking(): boolean {
		return this.stationStatusListItem.irrigationStatus === RbEnums.Common.IrrigationStatus.Soaking;
	}
	
	get status(): String {
		return this.stationStatusListItem?.status;
	}

	get isActivelyCyclingOrSoaking(): boolean {
		return this.stationStatusListItem?.isActivelyCyclingOrSoaking;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	// Method to flash cell when soaking/running status changes occur.
	private updateStationStatus(params: any): void {
		this.stationStatusListItem = params.data;
		if (params.data.isLocked) {
			this.isLocked = true;
			return;
		}
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		this.isRunning = false;

		switch (params.data.irrigationStatus) {
			case RbEnums.Common.IrrigationStatus.Dash:
			case RbEnums.Common.IrrigationStatus.Idle:
			case RbEnums.Common.IrrigationStatus.Pending:
			case RbEnums.Common.IrrigationStatus.Delaying:
			case RbEnums.Common.IrrigationStatus.Advancing:
			case RbEnums.Common.IrrigationStatus.ReadyToRun:
				break;

			default:
				this.isRunning = true;
				params.api.flashCells({
					rowNodes: [rowNode],
					columns: ['statusGridDisplay', 'status']		// 'statusGridDisplay' is used by Stations Tab, 'status' is used by ProgramSteps Tab
				});
				break;
		}
	}

}

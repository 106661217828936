import * as moment from 'moment';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class CultureSettings {

	FConst = RbConstants.Form;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	calendarFormatId: number;
	companyId: number;
	cultureId: number;
	dateFormatId: number;
	areaFormat: RbEnums.Common.AreaFormat;
	decimalSeparatorId: string;
	id: number;
	timeFormatId: number;
	unitsId: number;

	get decimalSeparator(): string {
		if (this.decimalSeparatorId.toString() === RbConstants.Form.DECIMAL_ASCII_VALUE) {
			return RbConstants.Form.DECIMAL_SEPARATOR;
		} else {
			return RbConstants.Form.COMMA_SEPARATOR;
		}
	}

	get allowComma(): boolean {
		return (this.decimalSeparator === RbConstants.Form.DECIMAL_SEPARATOR) ? false : true;
	}

	get unitType(): RbEnums.Common.UnitsType {
		switch (this.unitsId) {
			case RbEnums.Common.UnitsType.English:
				return RbEnums.Common.UnitsType.English;
			case RbEnums.Common.UnitsType.Metric1:
				return RbEnums.Common.UnitsType.Metric1;
			case RbEnums.Common.UnitsType.Metric2:
				return RbEnums.Common.UnitsType.Metric2;
			case RbEnums.Common.UnitsType.Metric3:
				return RbEnums.Common.UnitsType.Metric3;
			default:
				return 1;
		}
	}

	get unitTypeString(): string {
		switch (this.unitType) {
			case RbEnums.Common.UnitsType.Metric1:
				return RbUtils.Translate.instant('UNIT_TYPE.METRIC1');
			case RbEnums.Common.UnitsType.Metric2:
				return RbUtils.Translate.instant('UNIT_TYPE.METRIC2');
			case RbEnums.Common.UnitsType.Metric3:
				return RbUtils.Translate.instant('UNIT_TYPE.METRIC3');
			default:
				return RbUtils.Translate.instant('UNIT_TYPE.US');
		}
	}

	// Method to convert formatted value string of form "1,123.4" to value using users decimal separator, optionally
	// including thousands separator. NOTE: Method assumes input value uses standard US decimal separator ('.').
	toUserNumber(value: string, showThousandsSeparator = false): string {
		// Remove any commas from value string.
		const cleanValueString = value.split(',').join('');

		// Split on standard decimal separator. This is what we store in the db.
		const parts = cleanValueString.toString().split('.');

		if (showThousandsSeparator) {
			const decimalSeparator = this.decimalSeparator === this.FConst.DECIMAL_SEPARATOR ? this.FConst.COMMA_SEPARATOR : this.FConst.DECIMAL_SEPARATOR;
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, decimalSeparator);
		}

		return parts.join(this.decimalSeparator);
	}

	toUserTimeString(dateTime: Date) {
		return (this.timeFormatId === RbEnums.Common.TimeFormat.AmPm)
			? moment(dateTime).format( 'h:mm A')
			: moment(dateTime).format('HH:mm');
	}

	toUserDateTimeString(dateTime: Date) {
		return (this.timeFormatId === RbEnums.Common.TimeFormat.AmPm)
			? moment(dateTime).format('YYYY/MM/DD h:mm A')
			: moment(dateTime).format('YYYY/MM/DD HH:mm');
	}
}

import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { AreaFormatListItem } from './models/area-format-list-item.model';
import { CultureSettings } from './models/culture-settings.model';
import { DateFormatListItem } from './models/date-format-list-item.model';
import { DayOfWeekListItem } from './models/day-of-week-list-item.model';
import { DecimalSeparatorListItem } from './models/decimal-separator-list-item.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SupportedCultureListItem } from './models/supported-culture-list-item.model';
import { TimeFormatListItem } from './models/time-format-list-item.model';
import { UnitsTypeListItem } from './models/units-type-list-item.model';

@Injectable({
	providedIn: 'root'
})
export class CultureSettingsApiService extends ApiService {

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getCultureSettings(cultureId: number, byPassCache = false): Observable<ApiCachedRequestResponse<CultureSettings>> {
		return this.apiRequestWithCache<any>(this.getCultureSettingsUrl(cultureId), byPassCache, result => new CultureSettings(result));
	}

	getDateFormats(): Observable<DateFormatListItem[]> {
		return this.apiRequest<any>(this.getDateFormatsUrl(), HttpMethod.Get)
			.pipe(map(dateFormats => dateFormats.map(dateFormat => new DateFormatListItem(dateFormat))));
	}

	getDaysOfWeek(): Observable<DayOfWeekListItem[]> {
		return this.apiRequest<any>(this.getDaysOfWeekUrl(), HttpMethod.Get)
			.pipe(map(daysOfWeek => daysOfWeek.map(dayOfWeek => new DayOfWeekListItem(dayOfWeek))));
	}

	getDecimalSeparators(): Observable<DecimalSeparatorListItem[]> {
		return this.apiRequest<any>(this.getDecimalSeparatorsUrl(), HttpMethod.Get)
			.pipe(map(decimalSeparators => decimalSeparators.map(decimalSeparator => new DecimalSeparatorListItem(decimalSeparator))));
	}

	getSupportedCultures(): Observable<SupportedCultureListItem[]> {
		return this.apiRequest<any>(this.getSupportedCulturesUrl(), HttpMethod.Get)
			.pipe(map(supportedCultures => supportedCultures.map(supportedCulture => new SupportedCultureListItem(supportedCulture))));
	}

	getTimeFormats(): Observable<TimeFormatListItem[]> {
		return this.apiRequest<any>(this.getTimeFormatsUrl(), HttpMethod.Get)
			.pipe(map(timeFormats => timeFormats.map(timeFormat => new TimeFormatListItem(timeFormat))));
	}

	getUnitsTypes(): Observable<UnitsTypeListItem[]> {
		return this.apiRequest<any>(this.getUnitsTypesUrl(), HttpMethod.Get)
			.pipe(map(unitsTypes => unitsTypes.map(unitsType => new UnitsTypeListItem(unitsType))));
	}

	getAreaFormats(): Observable<AreaFormatListItem[]> {
		return this.apiRequest<any>(this.getAreaFormatsUrl(), HttpMethod.Get)
			.pipe(map(areaFormats => areaFormats.map(areaFormat => new AreaFormatListItem(areaFormat))));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}CultureSettings`; }

	private getCultureSettingsUrl(cultureId: number): string {
		return `${this.baseUrl}/GetCultureSettingDetail?cultureSettingsId=${cultureId}`;
	}

	private getAreaFormatsUrl(): string { return `${this.baseUrl}/GetAreaFormat`; }

	private getDateFormatsUrl(): string { return `${this.baseUrl}/GetDateFormat`; }

	private getDaysOfWeekUrl(): string { return `${this.baseUrl}/GetDayOfWeek`; }

	private getDecimalSeparatorsUrl(): string { return `${this.baseUrl}/GetDecimalSeparator`; }

	private getSupportedCulturesUrl(): string { return `${this.baseUrl}/GetSupportedCultures`; }

	private getTimeFormatsUrl(): string { return `${this.baseUrl}/GetTimeFormat`; }

	private getUnitsTypesUrl(): string { return `${this.baseUrl}/GetUnitsType`; }
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { EventLogEntry } from '../../../../../api/event-logs/models/event-log-entry.model';

@Component({
	selector: 'rb-alert-mobile-cell-renderer',
	templateUrl: './alert-mobile-cell-renderer.component.html',
	styleUrls: ['./alert-mobile-cell-renderer.component.scss']
})
export class AlertMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	alert: EventLogEntry;

	constructor(public appDateAdapter: AppDateAdapter,
				injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.alert = params.data;
	}
}

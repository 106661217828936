import { AppInjector } from '../../core/core.module';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { Injectable } from '@angular/core';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';

import CommInterfaceType = RbEnums.Common.CommInterfaceType;
import { ControllerListItem } from '../../api/controllers/models/controller-list-item.model';
import { ControllerManagerService } from '../../api/controllers/controller-manager.service';

@Injectable({
	providedIn: 'root'
})
export class UtilityService {

	private _controllerManager: ControllerManagerService = null;
	/**
	 * Get an instance of the ControllerManagerService. We use this only in a subset of situations so don't bother
	 * constructing it until/unless needed. Note that having this circular dependency (controller manager also
	 * depends on us), requires some injector use, rather than direct constructor mentions of the dependency.
	 */
	private get controllerManager(): ControllerManagerService {
		if (this._controllerManager != null) return this._controllerManager;

		// Create an instance of the manager and save it before returning.
		this._controllerManager = AppInjector.get(ControllerManagerService);

		return this._controllerManager;
	}
	
	constructor(private authManager: AuthManagerService) { }

	get siteType(): string {
		return this.authManager.getUserProfile().siteType;
	}

	get isGolfSite() {
		return RbUtils.Common.isGolfSite(this.siteType);
	}

	// Method to determine if a commercial controller is in Demo Mode.
	isControllerInDemoMode(commInterfaceType: CommInterfaceType) {
		return !this.isGolfSite && (commInterfaceType === CommInterfaceType.None || commInterfaceType === CommInterfaceType.Demo);
	}

	isInDemoMode(controller: ControllerListItem) {
		if (controller.iqNetType === RbEnums.Common.IqNetType.IQNetClient || this.isSatelliteTbos(controller.type)) {
			const parentController = this.controllerManager.getControllerListItemFromCache(controller.parentId);
			if (parentController) {
				return this.isControllerInDemoMode(parentController.commInterfaceType);
			}
		}
		return this.isControllerInDemoMode(controller.commInterfaceType);
	}

	isSatelliteTbos(type: number) {
		return this.controllerManager.isSatelliteTbos(type);
	}
}

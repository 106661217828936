import 'jspdf-autotable';
import * as Highcharts from 'highcharts';
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-duplicate-imports
import autoTable, { Styles, UserOptions } from 'jspdf-autotable';
import { from, Observable, tap } from 'rxjs';
import { AppInjector } from '../../../../core/core.module';
import exporting from 'highcharts/modules/exporting';
import jsPDF from 'jspdf';
// eslint-disable-next-line no-duplicate-imports
import { Canvg, presets } from 'canvg';
import { CultureSettingsManagerService } from '../../../../api/culture-settings/culture-settings-manager.service';
import { HttpClient } from '@angular/common/http';
import { RbConstants } from '../../../../common/constants/_rb.constants';

export type PDFTextAlign = 'center' | 'left' | 'right';
export interface Font {
    fontName: string;
    tffName: string;
    fontStyle: string;
    base64?: string;
}
export class ReportPdfInfo {
    pdf: jsPDF;
    cultureSettingsManager: CultureSettingsManagerService = AppInjector.get(CultureSettingsManagerService);
    cultureName: string;
    // General dimensions for all pages
    dim = {
        horizontalMargin: 32,
        verticalMargin: 32,
        textBlockPadding: 2,
        smallFontSize: 10,
        regularFontSize: 12,
        largeFontSize: 18,
        veryLargeFontSize: 22,
    };

    tables = {
        defaultStyles: { fontSize: 8, font: this.getFont },
        defaultHeadStyles: <Styles>{
            fillColor: RbConstants.Form.REPORT_PAGE.tableHeaderColorRGB,
            fontStyle: 'bold', lineWidth: 1, textColor: 64, halign: 'center',
            font: this.getFont
        },
        defaultBodyStyles: <Styles>{ fillColor: RbConstants.Form.REPORT_PAGE.alternatingRowColorRGB, halign: 'center', font: this.getFont },
        defaultAlternateRowStyles: { fillColor: '#FFFFFF' },
    };

    get pageWidth(): number { return this.pdf.internal.pageSize.getWidth(); }

    get pageHeight(): number { return this.pdf.internal.pageSize.getHeight(); }

    get bodyWidth(): number { return this.pageWidth - this.dim.horizontalMargin * 2; }

    get center(): number { return this.pageWidth / 2; }

    get lineHeight(): number { return this.pdf.getFontSize() * this.pdf.getLineHeightFactor(); }

    // Current output status
    currentPageNumber = 1;
    outputBottom = this.dim.verticalMargin; // Should be updated by each component as it generates output down the page


    constructor(public httpClient: HttpClient) {
        this.getFont;
    }

    getFontBase64ByPath(fontName: string) {
        return this.httpClient.get(`assets/fonts/jsPDF/${fontName}.txt`, { responseType: 'text' });
    }

    // add the font to jsPDF use converter: https://rawgit.com/MrRio/jsPDF/master/fontconverter/fontconverter.html
    // note that the test using font converted to base64 works, but loading directly from a file(.ttf) does not work.
    addFontIfNotExists(fonts: Font[]) {
        const font = fonts[0];
        if (!!this.pdf && !!this.pdf.getFontList() && !!font && !this.pdf.getFontList()[font.fontName]) {
            fonts.map((font) => {
                this.getFontBase64ByPath(`${font.fontName}-${font.fontStyle}-base64`).subscribe((base64)=> {
                    font = {...font, base64: base64};
                    this.pdf.addFileToVFS(font.tffName, font.base64)
                    this.pdf.addFont(font.tffName, font.fontName, font.fontStyle);
                })
            })
            this.pdf.setFont(font.fontName);
        }
    }

    addFont() {
        switch (this.cultureName) {
            case 'vi' :
                const viFont: Font[] = [
                    {
                        fontName: 'WorkSans',
                        tffName: 'WorkSans-Normal.ttf',
                        fontStyle: 'normal',
                    },
                    {
                        fontName: 'WorkSans',
                        tffName: 'WorkSans-Bold.ttf',
                        fontStyle: 'bold',
                    },
                ];
                this.addFontIfNotExists(viFont);
            default:
                return;
        }
    }

    createNewPage() {
        this.pdf.addPage();
        this.currentPageNumber++;
        this.outputBottom = this.dim.verticalMargin;
    }

    // textWidth(text): number { return this.pdf.getStringUnitWidth(text) * this.pdf.internal.getFontSize() / this.pdf.internal.scaleFactor; }
    textWidth(text): number { return this.pdf.getStringUnitWidth(text) * this.pdf.getFontSize() / this.pdf.internal.scaleFactor; }

    outputRightAlignedText(right: number, top: number, text: string) {
        this.pdf.text(text, right - this.textWidth(text), top);
    }

    outputCenterAlignedText(center: number, top: number, text: string) {
        this.pdf.text(text, center - this.textWidth(text) / 2, top);
    }

    // Assumes the font and color have already been set up.
    outputWrappedText(x: number, y: number, maxWidth: number, text: string): number {

        if (text == null || text.length === 0) return 0;

        // Divide it into multiple lines (if necessary)
        const splitText = this.pdf.splitTextToSize(text, maxWidth);
        const blockHeight = splitText.length * this.lineHeight;
        let textTop = y;
        splitText.forEach(line => {
            this.pdf.text(line, x, textTop);
            textTop += this.lineHeight;
        });

        return blockHeight;
    }

    // Assumes the font has already been set up. Text will be centered in the block
    outputTextBlock(
        x: number,
        y: number,
        width: number,
        text: string,
        backgroundColor: string,
        textColor: string,
        borderColor: string = null,
        textAlign: PDFTextAlign = 'center'
    ): number {

        if (text == null || text.length === 0) return 0;

        // Divide it into multiple lines (if necessary)
        const splitText = this.pdf.splitTextToSize(text, width - this.dim.textBlockPadding * 2);
        const blockHeight = splitText.length * this.lineHeight + this.dim.textBlockPadding * 2;

        this.pdf.setFillColor(backgroundColor);
        this.pdf.setTextColor(textColor);
        if (borderColor != null) this.pdf.setDrawColor(borderColor);
        this.pdf.setLineWidth(1);
        this.pdf.rect(x, y - blockHeight / 2, width, blockHeight, borderColor == null ? 'F' : 'DF');
        let textTop = y - blockHeight / 2 + this.dim.textBlockPadding + this.lineHeight / 2;
        let startLinePos = x + width / 2;
        if (textAlign === 'left') {
            startLinePos = x + 5;
        } else if (textAlign === 'right') {
            startLinePos = x + width - 5;
        }

        splitText.forEach(line => {
            this.pdf.text(line, startLinePos, textTop, { align: textAlign, baseline: 'middle' });
            textTop += this.lineHeight;
        });

        return blockHeight;
    }

    outputChart(chart: Highcharts.Chart, width: number = null, height: number = null) {
        // RB-14900: If your pdf cannot show the chart, please try using outputChart2. outputChart2 is an asynchonous function 
        exporting(Highcharts);

        // Default width and height are full width and 1/3 page height
        if (width == null) width = this.bodyWidth;
        if (height == null) height = this.pageHeight / 3;

        // Create an SVG that is "double" resolution for better scaling. It is targeted at width = page width, height = 1/3 page height
        const svg = chart.getSVG({ chart: { width: width * 2, height: height * 2 } });

        // Render the svg to the canvas
        const canvas = new OffscreenCanvas(width * 2, height * 2);
        const ctx = canvas.getContext('2d');
        const v = Canvg.fromString(ctx, svg, presets.offscreen())
        v.render();

        // Get image data
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const chartHeight = canvas.height * width / canvas.width;

        // Add image to pdf.
        this.pdf.addImage(imageData, 'PNG', this.dim.horizontalMargin, this.outputBottom, width, chartHeight);
        this.outputBottom += chartHeight;
    }

    outputChart2(chart: Highcharts.Chart, width: number = null, height: number = null): Observable<any> {
        // For some reason, the chart generated by the SVG doesn’t match with the Canvg library used in the outputChart method.
        // This method uses html2canvas instead.”
        exporting(Highcharts);

        // Default width and height are full width and 1/3 page height
        if (width == null) width = this.bodyWidth;
        if (height == null) height = this.pageHeight / 3;

        const svgElement = document.createElement('div');
        svgElement.style.width = `${width * 2}px`
        svgElement.style.height = `${height * 2}px`
        // Create an SVG that is "double" resolution for better scaling. It is targeted at width = page width, height = 1/3 page height
        svgElement.innerHTML = chart.getSVG({ chart: { width: width * 2, height: height * 2 } });
        document.body.appendChild(svgElement);

        return from(html2canvas(svgElement)).pipe(
            tap(canvas => {
                svgElement.remove();
                const base64image = canvas.toDataURL("image/png");
                const imgProps= this.pdf.getImageProperties(base64image);
                const chartHeight = (imgProps.height * width) / imgProps.width;
                this.pdf.addImage(base64image, 'PNG', this.dim.horizontalMargin, this.outputBottom, width, chartHeight);
                this.outputBottom += chartHeight;
            })
        )
    }

    getTableDefaults(): UserOptions {
        return <UserOptions>{
            styles: this.tables.defaultStyles,
            headStyles: this.tables.defaultHeadStyles,
            bodyStyles: this.tables.defaultBodyStyles,
            alternateRowStyles: this.tables.defaultAlternateRowStyles,
        }
    }

    outputTableData(headerCells: any, bodyCells: any, customOptions?: UserOptions) {
        let defaultOptions: UserOptions = {
            head: headerCells,
            body: bodyCells,
            theme: 'grid',
            startY: this.outputBottom,
            styles: {  font: this.getFont},
            didDrawCell: data => {
                this.outputBottom = Math.max(this.outputBottom, data.cursor.y + data.row.height + 1);
            }
        }

        let combinedOptions: UserOptions = (customOptions != null) ? { ...defaultOptions, ...customOptions } : { ...defaultOptions }
        autoTable(this.pdf, combinedOptions);

        this.outputBottom += this.lineHeight / 2;
    }

    get base64String(): string {
        const base64Pdf = this.pdf.output('datauristring');
        return base64Pdf.substring(base64Pdf.indexOf('base64,') + 7);
    }

    get getFont(): string {
        if (!this.cultureName) {
            this.cultureName = this.cultureSettingsManager.getCultureAsciiName(this.cultureSettingsManager?.cultureSetting?.cultureId);
        }
        switch (this.cultureName) {
            case 'en' :
            case 'fr' :
            case 'de' :
            case 'it' :
            case 'pt' :
            case 'es' :
            case 'tr' :
            case 'ja' :
            case 'sv' :
            case 'ko' :
            case 'zh' :
                return 'helvetica'
            case 'vi' :
                return 'WorkSans'
            default:
                return 'helvetica';
        }
    }
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="flowElement" class="rb-flow-manager-mobile-cell-container">
	<div class="name"><span class="ellipsis-text">{{flowElement.name}}</span></div>

	<div class="type">
		<span class="label">{{'STRINGS.TYPE' | translate}}:</span>
		<span class="value">{{flowElement.typeName}}</span>
	</div>
	<div class="capacity">
		<span class="label">{{'STRINGS.CAPACITY_TOTAL' | translate}}:</span>
		<span class="value">{{convertCapacity()}}</span>
	</div>
	<div class="capacity">
		<span class="label">{{'STRINGS.CURRENT_FLOW' | translate}}:</span>
		<span class="value">{{convertCurrentFlow()}}</span>
	</div>
	<div class="capacity">
		<span class="label">{{'STRINGS.FLOW_AVAILABLE' | translate}}:</span>
		<span class="value">{{convertAvailableFlow()}}</span>
	</div>
</div>

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AcCurrentTestOptions } from './models/ac-current-test-options.model';
import { AdvanceStation } from '../stations/models/advance-station.model';
import { BroadcastService } from '../../common/services/broadcast.service';
import { ControllerFirmwareVersions } from './models/controller-firmware-versions.model';
import { DiagnosticsSegmentInfo } from './models/diagnostics-segment-info.model';
import { Injectable } from '@angular/core';
import { IStationDiagnosticHistory } from '../diagnostic-log/models/station-diagnostic';
import { LxdPingDecoderItem } from './models/lxd-ping-decoder-item.model';
import { LxIvmPingRequestItem } from './models/lx-ivm-ping-request-item.model';
import { ManualOpsApiService } from './manual-ops-api.service';
import { MasterValveStatus } from './models/master-valve-status.model';
import { Observable } from 'rxjs';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ScheduledDiagnostic } from './models/scheduled-diagnostic.model';
import { ScheduledDiagnostics } from './models/scheduled-diagnostics.model';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { StartStationModel } from './models/start-station.model';
import { Station } from '../stations/models/station.model';
import { StationManagerService } from '../stations/station-manager.service';
import { StationShortReport } from './models/station-short-report.model';
import { StationStatus } from '../stations/models/station-status.model';
import { TwoWireData } from './models/two-wire-data.model';
import { UniversalDeviceResponseStatuses } from './models/universal-device-response-statuses.model';
import { UniversalMasterValveStatus } from './models/universal-master-valve-status.model';
import { UniversalPingResultData } from './models/universal-ping-result-data.model';
import { UniversalTwoWireData } from './models/universal-two-wire-data.model';
import { UniversalTwoWireDevicePaths } from './models/universal-two-wire-device-paths.model';
import { UniversalWeatherSensorStatus } from './models/universal-weather-sensor-status.model';
import { WeatherSensorStatus } from './models/weather-sensor-status.model';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class ManualOpsManagerService extends ServiceManagerBase {

	private _initialInterfaceConnectionCheck = false;
	private _weatherSensorStatusDict: { [controllerId: number]: WeatherSensorStatus } = {};
	private _twoWireDataDict: { [controllerId: number]: TwoWireData } = {};
	private _masterValveStatusDict: { [controllerId: number]: MasterValveStatus } = {};
	private _universalTwoWireDataDict: { [controllerId: number]: UniversalTwoWireData } = {};
	private _universalDeviceResponseStatusesDict: { [controllerId: number]: UniversalDeviceResponseStatuses } = {};
	private _universal2WireDevicePathsDict: { [controllerId: number]: UniversalTwoWireDevicePaths } = {};
	private _diagnosticsSegmentDict: { [controllerId: number]: DiagnosticsSegmentInfo } = {};
	private _universalWeatherSensorStatusDict: { [controllerId: number]: UniversalWeatherSensorStatus } = {};
	private _universalMasterValveStatusDict: { [controllerId: number]: UniversalMasterValveStatus } = {};
	private _stationShortReportDict: { [controllerId: number]: StationShortReport } = {};
	private _lxdPingDecoderTwoWireDataDict: { [controllerIdAddress: string]: TwoWireData } = {};
	private _universalPingResultDataDict: { [controllerIdAddress: string]: UniversalPingResultData } = {};
	isShortTestInProgress = false;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private manualOpsApiService: ManualOpsApiService,
				protected broadcastService: BroadcastService,
				private stationManagerService: StationManagerService
	) {
		super(broadcastService);

		this.broadcastService.weatherSensorStatusChange
			.pipe(untilDestroyed(this))
			.subscribe((status: WeatherSensorStatus) => this._weatherSensorStatusDict[status.controllerId] = status);

		this.broadcastService.twoWireDataChange
			.pipe(untilDestroyed(this))
			.subscribe((data: TwoWireData) => this._twoWireDataDict[data.controllerId] = data);

		this.broadcastService.masterValveStatusChange
			.pipe(untilDestroyed(this))
			.subscribe((status: MasterValveStatus) => this._masterValveStatusDict[status.controllerId] = status);

		this.broadcastService.universalTwoWireDataChanged
			.pipe(untilDestroyed(this))
			.subscribe((data: UniversalTwoWireData) => this._universalTwoWireDataDict[data.controllerId] = data);

		this.broadcastService.universalDeviceResponseStatusesChanged
			.pipe(untilDestroyed(this))
			.subscribe((data: UniversalDeviceResponseStatuses) => this._universalDeviceResponseStatusesDict[data.controllerId] = data);

		this.broadcastService.universal2WireDevicePathsChanged
			.pipe(untilDestroyed(this))
			.subscribe((paths: UniversalTwoWireDevicePaths) => this._universal2WireDevicePathsDict[paths.controllerId] = paths);

		this.broadcastService.diagnosticsSegmentInfoChanged
			.pipe(untilDestroyed(this))
			.subscribe((info: DiagnosticsSegmentInfo) => this._diagnosticsSegmentDict[info.controllerId] = info);

		this.broadcastService.stationShortReportChange
			.pipe(untilDestroyed(this))
			.subscribe((shortReport: StationShortReport) => this._stationShortReportDict[shortReport.controllerId] = shortReport);

		this.broadcastService.universalWeatherSensorStatusChange
			.pipe(untilDestroyed(this))
			.subscribe((status: UniversalWeatherSensorStatus) => this._universalWeatherSensorStatusDict[status.controllerId] = status);

		this.broadcastService.universalMasterValveStatusChange
			.pipe(untilDestroyed(this))
			.subscribe((status: UniversalMasterValveStatus) => this._universalMasterValveStatusDict[status.controllerId] = status);

		this.broadcastService.pingResult
			.pipe(untilDestroyed(this))
			.subscribe((data: TwoWireData) => {
				this._lxdPingDecoderTwoWireDataDict[`${data.controllerId}-${data.decoderAddress}`] = data;
			});

		this.broadcastService.universalPingResult
			.pipe(untilDestroyed(this))
			.subscribe((data: UniversalPingResultData) => {
				this._universalPingResultDataDict[`${data.controllerId}-${data.deviceAddress}`] = data;
			});

	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	advanceAreas(areaIds: number[]): Observable<void> {
		return this.manualOpsApiService.advanceAreas(areaIds);
	}

	advanceGolfStationsMock(stationIds: number[]) {
		return this.manualOpsApiService.advanceGolfStationsMock(stationIds);
	}

	advancePrograms(programIds: number[]): Observable<void> {
		return this.manualOpsApiService.advancePrograms(programIds);
	}

	advanceProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.manualOpsApiService.advanceProgramGroups(programGroupIds);
	}

	advanceStations(advanceStations: AdvanceStation[], isProgramIndex: boolean = false, controllerId?: number): Observable<void> {
		if (controllerId) {
			advanceStations.forEach(item => {
				this.stationManagerService.updateLocalStationStatusDict([
					new StationStatus({
						stationId: item.stationId,
						irrigationStatus: RbEnums.Common.IrrigationStatus.Advancing
				})],
			controllerId);
			});
		}
		return this.manualOpsApiService.advanceStations(advanceStations, isProgramIndex);
	}

	clearFlowLogs(controllerId: number): Observable<void> {
		return this.manualOpsApiService.clearFlowLogs(controllerId);
	}

	closeMasterValves(controllerId: number, closeValves: boolean): Observable<void> {
		return this.manualOpsApiService.closeMasterValves(controllerId, closeValves);
	}

	connect(controllerId: number): Observable<void> {
		return this.manualOpsApiService.connect(controllerId);
	}

	disconnect(controllerId: number): Observable<void> {
		return this.manualOpsApiService.disconnect(controllerId);
	}

	enableFloManager(controllerId: number, isFloManagerEnabled: boolean, isFloWatchEnabled: boolean): Observable<void> {
		return this.manualOpsApiService.enableFloManager(controllerId, isFloManagerEnabled, isFloWatchEnabled);
	}

	/** Returns the cached TwoWireData for the given controller if it exists. Call request2WireData
	 *  to initiate the TwoWireData retrieval from the physical controller. */
	get2WireData(controllerId: number) {
		return this._twoWireDataDict[controllerId];
	}

	/** Returns the cached DiagnosticsSegmentInfo for the given controller if it exists. Call requestDiagnosticsSegmentInfo
	 *  to initiate the DiagnosticsSegmentInfo retrieval from the physical controller. */
	getDiagnosticsSegmentInfo(controllerId: number) {
		return this._diagnosticsSegmentDict[controllerId];
	}

	/** Returns the cached WeatherSensorStatus for the given controller if it exists. Call requestMasterValveStatus
	 *  to initiate the MasterValveStatus retrieval from the physical controller. */
	getMasterValveStatus(controllerId: number) {
		return this._masterValveStatusDict[controllerId];
	}

	getRasterTest(controllerId: number): Observable<void> {
		return this.manualOpsApiService.getRasterTest(controllerId);
	}
	cancelFirmwareUpdate(controllerId: number): Observable<void> {
		return this.manualOpsApiService.cancelFirmwareUpdate(controllerId);
	}
	getSatelliteFirmwareVersions(controllerId: number): Observable<ControllerFirmwareVersions> {
		return this.manualOpsApiService.getSatelliteFirmwareVersions(controllerId);
	}

	getStationShortReport(controllerId: number): StationShortReport {
		return this._stationShortReportDict[controllerId];
	}

	getLxdPingDecoderTwoWireData(controllerId: number, address: number): TwoWireData {
		return this._lxdPingDecoderTwoWireDataDict[`${controllerId}-${address}`];
	}

	getUniversalPingResultData(controllerId: number, address: number): UniversalPingResultData {
		return this._universalPingResultDataDict[`${controllerId}-${address}`];
	}

	/** Returns the cached Universal2WireDevicePaths for the given controller if it exists. Call requestUniversal2WireDevicePaths
	 *  to initiate the Universal2WireDevicePaths retrieval from the physical controller. */
	getUniversal2WireDevicePaths(controllerId: number) {
		return this._universal2WireDevicePathsDict[controllerId];
	}

	/** Returns the cached UniversalTwoWireData for the given controller if it exists. Call requestUniversal2WireLineSurvey
	 *  to initiate the UniversalTwoWireData retrieval from the physical controller. */
	getUniversalTwoWireData(controllerId: number) {
		return this._universalTwoWireDataDict[controllerId];
	}

	/** Returns the cached UniversalDeviceResponseStatuses for the given controller if it exists. Call requestUniversalDeviceResponseStatuses
	 *  to initiate the UniversalDeviceResponseStatuses retrieval from the physical controller. */
	getUniversalDeviceResponseStatuses(controllerId: number) {
		return this._universalDeviceResponseStatusesDict[controllerId];
	}

	/** Returns the cached UniversalMasterValveStatus for the given LXME2 type controller if it exists. Call requestMasterValveStatus
	 *  to initiate the UniversalMasterValveStatus retrieval from the physical controller. */
	getUniversalMasterValveStatus(controllerId: number) {
		return this._universalMasterValveStatusDict[controllerId];
	}

	/** Returns the cached UniversalWeatherSensorStatus for the given LXME2 type controller if it exists. Call requestWeatherSensorStatus
	 *  to initiate the UniversalWeatherSensorStatus retrieval from the physical controller. */
	getUniversalWeatherSensorStatus(controllerId: number) {
		return this._universalWeatherSensorStatusDict[controllerId];
	}

	/** Returns the cached WeatherSensorStatus for the given controller if it exists. Call requestWeatherSensorStatus
	 *  to initiate the WeatherSensorStatus retrieval from the physical controller. */
	getWeatherSensorStatus(controllerId: number) {
		return this._weatherSensorStatusDict[controllerId];
	}

	initiateLearnedFlow(controllerId: number, stationIds: number[], startTime?: string): Observable<void> {
		return this.manualOpsApiService.initiateLearnedFlow(controllerId, stationIds, startTime);
	}

	overrideDial(controllerId: number, dialPosition: RbEnums.Common.LogicalDialPos): Observable<void> {
		return this.manualOpsApiService.overrideDial(controllerId, dialPosition);
	}

	overrideSensor(controllerId: number, switchPosition: RbEnums.Common.SensorSwitchPosition): Observable<void> {
		return this.manualOpsApiService.overrideSensor(controllerId, switchPosition);
	}

	overrideTwoWirePath(controllerId: number, twoWirePathPosition: RbEnums.Common.TwoWirePath): Observable<void> {
		return this.manualOpsApiService.overrideTwoWirePath(controllerId, twoWirePathPosition);
	}

	pauseGolfStationsMock(stationIds: number[]) {
		return this.manualOpsApiService.pauseGolfStationsMock(stationIds);
	}

	pausePrograms(programIds: number[]): Observable<void> {
		return this.manualOpsApiService.pausePrograms(programIds);
	}

	pauseProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.manualOpsApiService.pauseProgramGroups(programGroupIds);
	}

	pauseStations(stationIds: number[]) {
		return this.manualOpsApiService.pauseStations(stationIds);
	}

	pingDecoder(controllerId: number, items: LxdPingDecoderItem[]): Observable<void> {
		return this.manualOpsApiService.pingDecoder(controllerId, items);
	}

	pingUniversal(controllerId: number, items: LxIvmPingRequestItem[]): Observable<void> {
		return this.manualOpsApiService.pingUniversal(controllerId, items);
	}

	/**
	 * Request a manual poll of the indicated sensors. This is not effective for every type of sensor. MIM-based
	 * sensors, for example, do not have any command or operation to cause their polling. However, SDI/LDI and
	 * ICI-based sensors will accept a poll request, returning data via SignalR (as usual), once the poll operation
	 * is completed.
	 * @param sensorIds - number[] containing the sensor Ids to be polled.
	 * @returns Observable<any> - you must subscribe, but no data is returned.
	 */
	pollSensors(sensorIds: number[]): Observable<any> {
		return this.manualOpsApiService.pollSensors(sensorIds);
	}

	renumberStationAddresses(controllerId: number, groupNumber: number): Observable<void> {
		return this.manualOpsApiService.renumberStationAddresses(controllerId, groupNumber);
	}

	request2WireData(controllerId: number): Observable<void> {
		return this.manualOpsApiService.request2WireData(controllerId);
	}

	requestDiagnosticsSegmentInfo(controllerId: number): Observable<void> {
		return this.manualOpsApiService.requestDiagnosticsSegmentInfo(controllerId);
	}

	requestMasterValveStatus(controllerId: number): Observable<void> {
		return this.manualOpsApiService.requestMasterValveStatus(controllerId);
	}

	requestShortReport(controllerId: number): Observable<void> {
		return this.manualOpsApiService.requestShortReport(controllerId);
	}

	requestUniversal2WireDevicePaths(controllerId: number): Observable<void> {
		return this.manualOpsApiService.requestUniversal2WireDevicePaths(controllerId);
	}

	requestUniversal2WireLineSurvey(controllerId: number): Observable<void> {
		return this.manualOpsApiService.requestUniversal2WireLineSurvey(controllerId);
	}

	requestUniversalDeviceResponseStatuses(controllerId: number): Observable<void> {
		return this.manualOpsApiService.requestUniversalDeviceResponseStatuses(controllerId);
	}

	requestWeatherSensorStatus(controllerId: number): Observable<void> {
		return this.manualOpsApiService.requestWeatherSensorStatus(controllerId);
	}

	/**
	 * Perform a reset operation on the indicated sensor. This should complete any in-process sensor response operations,
	 * reset any accumulated "values" for the sensor, etc. This is most-useful for Rain Watch sensors where it will end
	 * a system pause or shutdown caused by the rain and reset all the accumulators to zero.
	 * @param sensorId - number specifying the sensor to be reset. Although the API permits you to reset an individual
	 * trigger or response, we don't support this in this method.
	 */
	resetSensor(sensorId: number): Observable<any> {
		// Send the reset command and refresh the list. The list will probably not change immediately, as the engine has
		// to get the command, act on it, update the database, etc. before anything changes. We don't update the list
		// immediately; wait for SignalR notification of change.
		return this.manualOpsApiService.resetSensors([sensorId]);
	}

	resumeGolfStationsMock(stationIds: number[]) {
		return this.manualOpsApiService.resumeGolfStationsMock(stationIds);
	}

	resumeStations(stationIds: number[]) {
		return this.manualOpsApiService.resumeStations(stationIds);
	}

	resumePrograms(programIds: number[]): Observable<void> {
		return this.manualOpsApiService.resumePrograms(programIds);
	}

	resumeProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.manualOpsApiService.resumeProgramGroups(programGroupIds);
	}

	retrieveEventLogs(controllerIds: number[]): Observable<void> {
		return this.manualOpsApiService.retrieveEventLogs(controllerIds);
	}

	runTestProgram(controllerId: number, duration: number = 120) {
		return this.manualOpsApiService.runTestProgram(controllerId, duration);
	}

	sendRainDelay(controllerId: number, days: number): Observable<void> {
		return this.manualOpsApiService.sendRainDelay(controllerId, days);
	}

	setDiagnosticsTwoWireAcCurrentTest(controllerId: number, options: AcCurrentTestOptions): Observable<void> {
		return this.manualOpsApiService.setDiagnosticsTwoWireAcCurrentTest(controllerId, options);
	}

	setWaterWindow(controllerId: number, seconds: number): Observable<void> {
		return this.manualOpsApiService.setWaterWindow(controllerId, seconds);
	}

	simulateFREEDOMCommand(freedomCommand: string): Observable<any> {
		return this.manualOpsApiService.simulateFREEDOMCommand(freedomCommand);
	}

	simulateRainCanPulse(sensorId: number, pulseValue?: number): Observable<any> {
		return this.manualOpsApiService.simulateRainCanPulse([sensorId], pulseValue);
	}

	enableShortFindMode(controllerId: number, enableShortFindMode: boolean): Observable<void> {
		return this.manualOpsApiService.enableShortFindMode(controllerId, enableShortFindMode);
	}

	startLxdDecoderTest(controllerId: number): Observable<void> {
		return this.manualOpsApiService.startLxdDecoderTest(controllerId);
	}

	startProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.manualOpsApiService.startProgramGroups(programGroupIds);
	}

	startPrograms(programIds: number[]): Observable<void> {
		return this.manualOpsApiService.startPrograms(programIds);
	}

	startGolfStationsMock(startStationModel: StartStationModel) {
		return this.manualOpsApiService.startGolfStationsMock(startStationModel);
	}

	startStations(startStationModel: StartStationModel, controllerId?: number) {
		if (controllerId)  {
			startStationModel.stationIds.forEach(item => {
				this.stationManagerService.updateLocalStationStatusDict([
					new StationStatus({
						secondsRemaining: startStationModel.seconds[0],
						stationId: item,
						irrigationStatus: RbEnums.Common.IrrigationStatus.ReadyToRun
					})], 
					controllerId)
			});
		}
		return this.manualOpsApiService.startStations(startStationModel);
	}

	stopProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.manualOpsApiService.stopProgramGroups(programGroupIds);
	}

	stopPrograms(programIds: number[]): Observable<void> {
		return this.manualOpsApiService.stopPrograms(programIds);
	}

	stopGolfStationsMock(stationIds: number[]) {
		return this.manualOpsApiService.stopGolfStationsMock(stationIds);
	}

	stopStations(stationIds: number[]): Observable<void> {
		return this.manualOpsApiService.stopStations(stationIds);
	}

	updateFirmware(controllerId: number, filePath: string, verifySatellite = true): Observable<void> {
		return this.manualOpsApiService.updateFirmware(controllerId, filePath, verifySatellite);
	}

	resetNccModem(controllerId: number) {
		return this.manualOpsApiService.resetNccModem(controllerId);
	}

	unlockSystem(): Observable<void> {
		return this.manualOpsApiService.unlockSystem();
	}

	// =========================================================================================================================================================
	// Diagnostic tests
	// =========================================================================================================================================================

	cancelDiagnostics(): Observable<void> {
		return this.manualOpsApiService.cancelDiagnostics();
	}

	checkInterfaceConnection() {
		if (!this._initialInterfaceConnectionCheck) {
			this.manualOpsApiService.checkInterfaceConnection().subscribe(() => {
				this._initialInterfaceConnectionCheck = true;
			});
		}
	}

	// Status Poll Tests
	runDiagnosticsStatusCheckByStation(stationId: number): Observable<IStationDiagnosticHistory[]> {
		return this.manualOpsApiService.runDiagnosticsStatusCheckByStation(stationId);
	}

	runDiagnosticsVoltageCheckByStation(stationId: number): Observable<IStationDiagnosticHistory[]> {
		return this.manualOpsApiService.runDiagnosticsVoltageCheckByStation(stationId);
	}
	runDiagnosticsStatusCheckByStationList(stationIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsStatusCheckByStationList(stationIds);
	}

	runDiagnosticsVoltageCheckByStationList(stationIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsVoltageCheckByStationList(stationIds);
	}

	runDiagnosticsStatusPollByAddress(courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsStatusPollByAddress(courseId, interfaceId, wirePathIds);
	}

	runMockDiagnosticsStatusPollByAddress(stations: Station[], courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runMockDiagnosticsStatusPollByAddress(stations, courseId, interfaceId, wirePathIds);
	}

	runDiagnosticsStatusPollByArea(courseId: number, holeAndAreaIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsStatusPollByArea(courseId, holeAndAreaIds);
	}

	runMockDiagnosticsStatusPollByArea(stations: Station[], courseId: number, holeIds: number[], areaIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runMockDiagnosticsStatusPollByCourse(stations, courseId, holeIds, areaIds);
	}

	runDiagnosticsStatusPollBySystemPoll(courseId: number): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsStatusPollBySite(courseId);
	}

	runMockDiagnosticsStatusPollBySystemPoll(stations: Station[], courseId: number): Observable<number[]> {
		return this.manualOpsApiService.runMockDiagnosticsStatusPollBySystemPoll(stations, courseId);
	}

	// Voltage Tests
	runDiagnosticsVoltageByAddress(courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsVoltageByAddress(courseId, interfaceId, wirePathIds);
	}

	runMockDiagnosticsVoltageByAddress(stations: Station[], courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runMockDiagnosticsVoltageByAddress(stations, courseId, interfaceId, wirePathIds);
	}

	runDiagnosticsVoltageByArea(courseId: number, holeAndAreaIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsVoltageByArea(courseId, holeAndAreaIds);
	}

	runMockDiagnosticsVoltageByArea(stations: Station[], courseId: number, holeIds: number[], areaIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runMockDiagnosticsVoltageByCourse(stations, courseId, holeIds, areaIds);
	}

	runDiagnosticsVoltageBySystemPoll(courseId: number): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsVoltageBySite(courseId);
	}

	runMockDiagnosticsVoltageBySystemPoll(courseId: number, stations: Station[]): Observable<number[]> {
		return this.manualOpsApiService.runMockDiagnosticsVoltageBySystemPoll(courseId, stations);
	}

	// QuickCheck Tests
	runDiagnosticsQuickCheckByAddress(courseId: number, interfaceId: number, wirePathIds: number[], runTimeSeconds: number): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsQuickCheckByAddress(courseId, interfaceId, wirePathIds, runTimeSeconds);
	}

	runMockDiagnosticsQuickCheckByAddress(stations: Station[], courseId: number, interfaceId: number, wirePathIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runMockDiagnosticsQuickCheckByAddress(stations, courseId, interfaceId, wirePathIds);
	}
	
	runDecoderOnOff(stationIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runDecoderOnOff(stationIds);
	}

	runDecoderOnOffByArea(holeAndAreaIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runDecoderOnOffByArea(holeAndAreaIds);
	}
	
	runDiagnosticsQuickCheckByArea(courseId: number, holeAndAreaIds: number[], runTimeSeconds: number): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsQuickCheckByArea(courseId, holeAndAreaIds, runTimeSeconds);
	}

	runMockDiagnosticsQuickCheckByArea(stations: Station[], courseId: number, holeIds: number[], areaIds: number[]): Observable<number[]> {
		return this.manualOpsApiService.runMockDiagnosticsQuickCheckByCourse(stations, courseId, holeIds, areaIds);
	}

	runDiagnosticsQuickCheckBySystemPoll(courseId: number, runTimeSeconds: number): Observable<number[]> {
		return this.manualOpsApiService.runDiagnosticsQuickCheckBySite(courseId, runTimeSeconds);
	}

	runDiagnosticsQuickCheckByStation(stationId: number, runTimeSeconds: number, onBeforeOff = false): Observable<IStationDiagnosticHistory[]> {
		return this.manualOpsApiService.runDiagnosticsQuickCheckByStation(stationId, runTimeSeconds, onBeforeOff);
	}

	runMockDiagnosticsQuickCheckBySystemPoll(courseId: number, stations: Station[]): Observable<number[]> {
		return this.manualOpsApiService.runMockDiagnosticsQuickCheckBySystemPoll(courseId, stations);
	}

	SendIrrigationCommand(commandType: RbEnums.Common.IrrigationEngineCommandType) {
		return this.manualOpsApiService.SendIrrigationCommand(commandType);
	}

	runFaultFindingModeOnOff(satelliteId: number, wirePath: number,
		faultFindingOn: boolean, durationSeconds: number): Observable<void> {
		return this.manualOpsApiService.runFaultFindingModeOnOff(satelliteId, wirePath, faultFindingOn, durationSeconds);
	}

	// =========================================================================================================================================================
	// ScheduledDiagnostic tests
	// =========================================================================================================================================================

	getScheduledDiagnostics(): Observable<ScheduledDiagnostics> {
		return this.manualOpsApiService.getScheduledDiagnostics();
	}

	scheduleDiagnostic(test: ScheduledDiagnostic): Observable<void> {
		return this.manualOpsApiService.scheduleDiagnostic(test);
	}

	deleteScheduledDiagnostics(testType: RbEnums.Common.ScheduledDiagnosticTestType): Observable<void> {
		return this.manualOpsApiService.deleteScheduledDiagnostics(testType);
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { DatePipe } from '@angular/common';
import { RbUtils } from '../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-date-list-card',
	templateUrl: './date-list-card.component.html',
	styleUrls: ['./date-list-card.component.scss']
})
export class DateListCardComponent implements OnInit {
	@Output() deleteDate = new EventEmitter<Date>();
	@Output() editDate = new EventEmitter<Date>();

	@Input() date: Date;
	@Input() isNoneCard = false;
	@Input() noneText = 'STRINGS.NONE';

	formattedDate: string;
	isReadOnly = false;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private datePipe: DatePipe
	) { }

	ngOnInit() {
		this.isReadOnly = this.authManager.isReadOnly;
		this.setFormattedDate();
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onEditDate(data: any) {
		this.editDate.emit(this.date);
	}

	onDeleteDate(date: any) {
		this.deleteDate.emit(this.date);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setFormattedDate() {
		if (!this.date) { return; }

		const cultureSettings = this.authManager.userCulture;
		const dateFormat = RbUtils.Common.getDateFormat(cultureSettings);

		this.formattedDate = this.datePipe.transform(this.date, dateFormat);
	}
}

import { animate, animation, style } from '@angular/animations';

export const CAROUSEL_TRANSITION_DURATION = 400;

export const slideInLeft = animation([
	style({ transform: 'translateX(100%)'}), animate(`${CAROUSEL_TRANSITION_DURATION}ms`, style({ transform: 'translateX(0)'}))
]);

export const slideOutLeft = animation([
	animate(`${CAROUSEL_TRANSITION_DURATION}ms`, style({ transform: 'translateX(-100%)'}))
]);

export const slideInRight = animation([
	style({ transform: 'translateX(-100%)'}), animate(`${CAROUSEL_TRANSITION_DURATION}ms`, style({ transform: 'translateX(0)'}))
]);

export const slideOutRight = animation([
	animate(`${CAROUSEL_TRANSITION_DURATION}ms`, style({ transform: 'translateX(100%)'}))
]);

import { Update } from '../../software-update/models/update.model';

export class AvailableUpdate {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	activationCode: string;
	updates: Update[] = [];
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<mat-drawer-container
	class="flex justify-center tri-pane-container"
	[ngClass]="{'is-tri-pane-child': isTriPaneChild}"
	[ngStyle]="triPaneContainerHeight"
	[class.full-screen]="TriPaneComponent.isFullScreen"
	autosize
	#triPaneContainer>

	<mat-drawer #rightPane mode="over" disableClose="true" position="end" class="right-pane" (openedChange)="onRightPaneOpenChanged($event)">
		<div #rightPaneBodyContainer class="right-pane-body">
			<ng-container [ngTemplateOutlet]="rightPaneBody"></ng-container>
		</div>
	</mat-drawer>

	<div class="tri-pane-container tri-pane-container-sans-drawer" [ngStyle]="triPaneContainerHeight">

		<div class="flex w-100 center main-pane-container" [class.rb-mw10]="!TriPaneComponent.isFullScreen">
			<div #leftPane *ngIf="isLeftPaneDisplayed && (!isMobile || showMobileLeftSidebar)"
				class="left-pane"
				[ngClass]="{'left-pane-mobile': showMobileLeftSidebar, 'minimize-left-pane': isLeftPaneMinimized && !isMobile, 'no-lsb-pad': isMobile}"
			>
				<div *ngIf="!isMobile" class="left-pane-toggle" [matTooltip]="toggleTooltip" matTooltipPosition="above" (click)="toggleLeftPane()">
					<mat-icon>{{toggleIcon}}</mat-icon>
				</div>

				<div *ngIf="!showMobileLeftSidebar" #leftPaneToolbarContainer class="left-pane-toolbar" id="desktop-left-pane-toolbar"
					[ngClass]="{'left-pane-toolbar-mobile': showMobileLeftSidebar}">
					<!-- Desktop configuration: LeftSidebar Toolbar -->
					<ng-container [ngTemplateOutlet]="leftPaneToolbar" *ngIf="!togglingLeftPane && hasTabletLeftPaneBeenExpanded"></ng-container>
<!--					<div id="lp-toggle-btn-container">-->
<!--						<rb-icon-button hideLabel="true" [icon]="toggleIcon" [matTooltip]="toggleTooltip" matTooltipPosition="above" (click)="toggleLeftPane()"></rb-icon-button>-->
<!--					</div>-->
				</div>

				<div #leftPaneBodyContainer class="left-pane-body">
					<ng-container [ngTemplateOutlet]="leftPaneBody" *ngIf="!togglingLeftPane"></ng-container>
				</div>

				<div *ngIf="showMobileLeftSidebar" #leftPaneToolbarContainer class="left-pane-toolbar" id="mobile-left-pane-toolbar">
					<!-- Mobile configuration: LeftSidebar Toolbar -->
					<ng-container [ngTemplateOutlet]="leftPaneToolbar" *ngIf="!togglingLeftPane"></ng-container>
				</div>
			</div>

			<div #middlePane class="middle-pane" [ngClass]="{ 'full-width': !isLeftPaneDisplayed }">
				<div *ngIf="(!isMobile || alwaysKeepPlayMenuAtTop) && !hidePlayMenuPanel" #playMenuContainer class="play-menu dn db-m" id="desktop-main-pane-toolbar">
					<!-- Desktop configuration: Main Toolbar -->
					<ng-container [ngTemplateOutlet]="playMenu"></ng-container>
				</div>

				<div *ngIf="isMobile && mobileFilter">
					<ng-container [ngTemplateOutlet]="mobileFilter"></ng-container>
				</div>

				<div *ngIf="!hideMiddlePaneBody" #middlePaneBodyContainer class="middle-pane-body">
					<div *ngIf="!hideGridPlacemat" class="grid-placemat"></div>
					<ng-container [ngTemplateOutlet]="middlePaneBody"></ng-container>
				</div>

				<div *ngIf="isMobile && !alwaysKeepPlayMenuAtTop" #playMenuContainer id="mobile-main-pane-toolbar">
					<!-- Mobile configuration: Main toolbar -->
					<ng-container [ngTemplateOutlet]="playMenu"></ng-container>
				</div>
			</div>

		</div>

	</div>

</mat-drawer-container>



/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Translate.instant
 * 		 See: _rb.utils.ts
 */

import { AppInjector } from '../../core/core.module';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export namespace XXUseRbUtilsNamespace {

	export abstract class Translate {

		private static _translateService: TranslateService;

		private static get translateService() {
			if (!Translate._translateService) {
				Translate._translateService = AppInjector.get(TranslateService);
			}
			return Translate._translateService;
		}

		static instant(key: string | Array<string>, interpolateParams?: Object): string | any {
			return Translate.translateService.instant(key, interpolateParams);
		}

		static stream(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
			return Translate.translateService.stream(key, interpolateParams);
		}

	}
}

<mat-form-field>
	<input matInput type="text"
		[placeholder]="name"
	    [required]="isRequired"
	    [disabled]="isDisabled"
		rbGroupDecimalSeparator
		[allowNegativeValue]="allowNegativeValue"
		[(ngModel)]="valueString"
	    (ngModelChange)="onValueChanged()"
		(focus)="$any($event.target).select()" />
</mat-form-field>
<div class="tr black warnColorText">{{hintLabel}}</div>

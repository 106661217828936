import { ChangeDetectionStrategy, Component, HostBinding, Injector, OnDestroy } from '@angular/core';

import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { StationStatusListItem } from '../../../../../api/stations/models/_station-status-list-item.model';

@Component({
	selector: 'rb-station-mobile-cell-renderer-new',
	templateUrl: './station-mobile-cell-renderer-new.component.html',
	styleUrls: ['./station-mobile-cell-renderer-new.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StationMobileCellRendererNewComponent extends BaseMobileCellRendererComponent implements OnDestroy {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	RbEnums = RbEnums;
	isRunning = false;
	params: any;
	isLocked = false;
	stationStatusListItem: StationStatusListItem;
	stationStatusIcon: string;
	iconStyle: string;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnDestroy(): void {}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		super.agInit(params);
		this.stationStatusListItem = params.data;
		this.params = params;
		this.updateStationStatus(params);
		this.updateStationStatusIcon();
	}

	refresh(params: any): boolean {
		this.stationStatusListItem = params.data;
		this.params = params;
		this.updateStationStatus(params);
		this.updateStationStatusIcon();
		return true;
	}

	triggerStation(event) {
		event.stopPropagation();
		this.params?.onCellClick(this.stationStatusListItem);
	}

	private updateStationStatusIcon() {
		if (this.isRunning) {
			this.stationStatusIcon = 'fast_forward';
			this.iconStyle = 'play';
			return;
		}
		switch (this.stationStatusListItem.irrigationStatus) {
			case RbEnums.Common.IrrigationStatus.Idle:
				this.stationStatusIcon = 'play_arrow';
				this.iconStyle = 'idle';
				break;
			case RbEnums.Common.IrrigationStatus.Pending:
			case RbEnums.Common.IrrigationStatus.ReadyToRun:
				this.stationStatusIcon = 'fast_forward';
				this.iconStyle = 'pending';
				break;
			default:
				this.stationStatusIcon = null;
				this.iconStyle = 'idle';
		}
	}

	private updateStationStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		this.isRunning = false;

		switch (this.stationStatusListItem?.irrigationStatus) {
			case RbEnums.Common.IrrigationStatus.Dash:
			case RbEnums.Common.IrrigationStatus.Idle:
			case RbEnums.Common.IrrigationStatus.Pending:
			case RbEnums.Common.IrrigationStatus.Delaying:
			case RbEnums.Common.IrrigationStatus.Advancing:
			case RbEnums.Common.IrrigationStatus.ReadyToRun:
			case RbEnums.Common.IrrigationStatus.Stopping:
				break;

			default:
				this.isRunning = true;
				params.api.flashCells({
					rowNodes: [rowNode]
				});
				break;
		}
	}
}

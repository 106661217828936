import { BehaviorSubject, filter, map, Observable, Subject, take } from 'rxjs';
import { Note, StickyNote } from './models/sticky-note.model';
import { BroadcastService } from '../../common/services/broadcast.service';
import { Injectable } from '@angular/core';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { Site } from '../sites/models/site.model';
import { SiteManagerService } from '../sites/site-manager.service';
import { StickyNoteApiService } from './sticky-note-api.service';
import { TranslateService } from '@ngx-translate/core';
import { UserInfo } from '../users/models/rbcc-user-info.model';
import { UserManagerService } from '../users/user-manager.service';

@Injectable({
	providedIn: 'root'
})
export class StickyNoteManagerService extends ServiceManagerBase {

	private siteIdSubject: Subject<number> = new Subject<number>();
	private notesSubject: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>([]);
  	public notes$: Observable<Note[]> = this.notesSubject.asObservable();

	loggedUserUnreadNotesCount: IUnreadNotesCounter[] = [];

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(
		protected broadcastService: BroadcastService,
		private siteManager: SiteManagerService,
		private stickyNoteApiService: StickyNoteApiService,
		private translate: TranslateService,
		private userManager: UserManagerService
	) {
		super(broadcastService);
		this.siteIdSubject.subscribe(siteId => {
			this.getNotes(siteId);
		});
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	clearCache() {
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getStickyNotes(siteId: number): Observable<StickyNote[]> {
		return this.stickyNoteApiService.getStickyNotes(siteId);
	}

	createStickyNote(stickyNote: any): Observable<StickyNote> {
		return this.stickyNoteApiService.createStickyNote(stickyNote);
	}

	updateStickyNote(id: number, patchData: any): Observable<StickyNote> {
		return this.stickyNoteApiService.updateStickyNote(id, patchData);
	}

	deleteStickyNote(id: number): Observable<any> {
		return this.stickyNoteApiService.deleteStickyNote(id);
	}

	// =========================================================================================================================================================
	// Public Properties and Methods for Golf Notes
	// =========================================================================================================================================================

	noteTypes: INoteTypeInfo[] = [
		{
			id: RbEnums.Note.NoteType.Note,
			name: this.translate.instant('STRINGS.NOTE'), icon: "mdi mdi-message-processing"
		},
		{
			id: RbEnums.Note.NoteType.Task,
			name: this.translate.instant('STRINGS.TASK'), icon: "mdi mdi-plus-circle-outline"
		},
		{
			id: RbEnums.Note.NoteType.Action,
			name: this.translate.instant('STRINGS.ACTION'), icon: "mdi mdi-check-circle-outline"
		},
	];

	// Sets the current site ID so when changed it reloads the notes list
	setSiteId(siteId: number) {
		this.siteIdSubject.next(siteId);
	}

	// This method gets all the Notes for the current site. It also appends the Note with its replies.
	private getNotes(siteId: number) {
		this.stickyNoteApiService.getNotes(siteId).pipe(
      	map(notes => {
				return notes.map(note => {
			  	const replies = this.getReplies(note.id, note.attachedToType, notes);
			  return { ...note, replies, isEditing: false };
			});
		 })
	  ).subscribe(processedNotes => {
		 this.notesSubject.next(processedNotes);
	  });
	}

	// Returns the length of the notes list
	getNotesLength(): number {
	  return this.notesSubject.getValue().length;
	}

	// This method fills the loggedUserUnreadNotesCount variable with the user's unread notes by site.
	getUnreadNotesCounter(sites?: Site[]): void {
		if (!sites || !sites.length) {
			this.siteManager.getSites().pipe(take(1))
				.subscribe((sites: Site[]) => {
					this.stickyNoteApiService.getUnreadNotesCounters().pipe(take(1)).subscribe((unreadNotesCount: IUnreadNotesCounter[]) => {
						for (const site of unreadNotesCount) {
							site.name = sites.find(s => s.id === site.siteId)?.name;
						}
						this.loggedUserUnreadNotesCount = unreadNotesCount;
					})
				});
		} else {
			this.stickyNoteApiService.getUnreadNotesCounters().pipe(take(1)).subscribe((unreadNotesCount: IUnreadNotesCounter[]) => {
				for (const site of unreadNotesCount) {
					site.name = sites.find(s => s.id === site.siteId)?.name;
				}
				this.loggedUserUnreadNotesCount = unreadNotesCount;
			})
		}
	}

	// This method constructs the reply array for each parent note.
	getReplies(attachedToId: number, attachedToType: RbEnums.Note.NoteAnchor, noteList: Note[]): Note[] {
		const sortCriteria = (a, b) => <any>new Date(b.createdDateUtc) - <any>new Date(a.createdDateUtc);
		return noteList.filter(note => note.attachedToId === attachedToId &&
			note.attachedToType === attachedToType).sort(sortCriteria) || [];
	}

	// Sets the anchor station name to the notes
	setStationAnchorNames(stationNamesList: { id: number, name: string}[]): void {
		const notes = this.notesSubject.getValue();
		const notesWithStationNames: Note[] = notes.map(note => {
			const station = stationNamesList.find(station => station.id === note.attachedToId && note.attachedToType === RbEnums.Note.NoteAnchor.Station);
		  	return { ...note, attachedToName: station? station.name : null };
		});
		this.notesSubject.next(notesWithStationNames);
	 }

	createNote(note: Note): Observable<Note> {
		return this.stickyNoteApiService.createNote(note);
	}

	updateNote(id: number, patchData: any): Observable<Note> {
		return this.stickyNoteApiService.updateNote(id, patchData);
	}

	resolveNote(id: number, patchData: any): Observable<Note> {
		return this.stickyNoteApiService.updateNote(id, patchData);
	}

	deleteNote(id: number): Observable<any> {
		return this.stickyNoteApiService.deleteNote(id);
	}

	// This method returns the type info by Id
	getNoteType(typeId: number): INoteTypeInfo {
		return this.noteTypes.find(type => type.id === typeId)
	}

	// This method returns the list of available users to mention (@) on notes
	getAvailableUsers(siteId: number): Observable<UserInfo[]> {
		return this.userManager.getUserInfoBySite(siteId).pipe(
			filter(users => users.length > 0),
			take(1)
		);
	}

	getStationAnchorNote(stationId: number): Note {
		const currentNotes = this.notesSubject.getValue();
		return currentNotes.find(note =>
			note.attachedToType === RbEnums.Note.NoteAnchor.Station &&
			note.attachedToId === stationId);
	}

	getStationActiveNotes(stationId: number): Observable<Note[]> {
		return this.notesSubject.pipe(
			map(notes => notes.filter(note => {
				return note.attachedToType === RbEnums.Note.NoteAnchor.Station &&
				note.status === RbEnums.Note.NoteStatus.Active &&
				note.attachedToId === stationId
			}))
		);
	}

	removeNoteById(noteId: number): void {
		const currentNotes = this.notesSubject.getValue();
    	const updatedNotes = currentNotes.filter(note => note.id !== noteId);
    	this.notesSubject.next(updatedNotes);
	}

	// This broadcasts the note to the whole app so it is updated immediately.
	// -- If the note is found (index !== -1), it updates the note in the array. This is done by:
	// -- Creating a new array (updatedNotes) that contains all elements before the updated note (currentNotes.slice(0, index)),
	// -- The updated note itself (which merges properties from the existing note and the updatedNote using object spread syntax),
	// -- And all elements after the updated note (currentNotes.slice(index + 1)).
	// -- If the note is not found (index === -1), it adds the updatedNote to the end of the currentNotes array.
	broadcastNote(updatedNote: Note): void {
		
		let updatedNotes: Note[] = [];
		const currentNotes = this.notesSubject.getValue();
		const index = currentNotes.findIndex(note => note.id === updatedNote.id);
  
		if (index !== -1) {
			updatedNotes = [
				...currentNotes.slice(0, index),
				{ ...currentNotes[index], ...updatedNote },
				...currentNotes.slice(index + 1)
			];
		} else {
			updatedNotes = [...currentNotes, updatedNote];
		}
  
		this.notesSubject.next(updatedNotes);
  }

}

interface INoteTypeInfo {
	id: RbEnums.Note.NoteType;
	name: string;
	icon: string;
}

export interface IUnreadNotesCounter {
	siteId: number;
	count: number;
	name?: string;
}
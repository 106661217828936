<div class="rb-dlc-container" *ngIf="!isNoneCard">
	<div class="rb-dlc-body">
		<div class="rb-dlc-body-icon"><i class="fa fa-circle"></i></div>
		<div class="rb-dlc-body-date">{{formattedDate}}</div>
	</div>
	<div *ngIf="!isReadOnly" class="rb-dlc-toolbar">
		<button type="button"
			mat-icon-button
			(click)="onEditDate($event)"
		>
			<mat-icon aria-label="edit" aria-hidden="true">edit</mat-icon>
		</button>

		<button mat-icon-button
			class="rb-flex-grow-0 nt1"
			(click)="onDeleteDate($event)"
		>
			<mat-icon aria-label="delete" aria-hidden="true">delete</mat-icon>
		</button>
	</div>
</div>

<div class="rb-dlc-container" *ngIf="isNoneCard">
	<div class="rb-dlc-body rb-dlc-none-header">
		<div class="rb-dlc-body-date">{{noneText | translate}}</div>
	</div>
</div>

import * as moment from 'moment';
import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ProgramStepListItem } from '../../../../../api/program-steps/models/program-step-list-item.model';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-program-step-mobile-cell-renderer',
	templateUrl: './program-step-mobile-cell-renderer.component.html',
	styleUrls: ['./program-step-mobile-cell-renderer.component.scss']
})
export class ProgramStepMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	isRunning = false;
	programStep: ProgramStepListItem;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.programStep = params.data;
		this.updateStationStatus(params);
	}

	refresh(params: any): boolean {
		this.programStep = params.data;
		this.updateStationStatus(params);
		return true;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get invalidStation(): boolean {
		return moment.duration(this.programStep.baseRunTime).asMilliseconds() > 0 && !this.programStep.stationAddressValid;
	}

	get isCycling(): boolean {
		return this.programStep.irrigationStatus === RbEnums.Common.IrrigationStatus.Running;
	}

	get isSoaking(): boolean {
		return this.programStep.irrigationStatus === RbEnums.Common.IrrigationStatus.Soaking;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	// Method to flash cell when soaking/running status changes occur.
	private updateStationStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		this.isRunning = false;

		switch (this.programStep.irrigationStatus) {
			case RbEnums.Common.IrrigationStatus.Dash:
			case RbEnums.Common.IrrigationStatus.Idle:
			case RbEnums.Common.IrrigationStatus.Pending:
			case RbEnums.Common.IrrigationStatus.Delaying:
				break;

			default:
				this.isRunning = true;
				params.api.flashCells({
					rowNodes: [rowNode],
				});
				break;
		}

	}

}

<ng-template #alertWarningModal>
	<!--suppress TypeScriptUnresolvedVariable -->
	<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

	<rb-dialog
		[title]="'REPORTS.WARNING' | translate"
		[closeButtonLabel]="'STRINGS.CLOSE' | translate"
		[showSubmitButton]="false"
		data-qaid="alert-ack-modal"
	>
		<p>{{ alertAckValidationMessage }}</p>
	</rb-dialog>
</ng-template>

<div class="report-filter alert-filter-container flex items-center">

	<div class="view-type-selection-container" *ngIf="showViewOptions" >
		<!-- [disabled]="disabled"-->
		<rb-icon-button
				[label]="'STRINGS.LIST' | translate"
				icon="format_list_bulleted"
				class="dr-view-type"
				[ngClass]="{'dr-view-selected': activityView === ActivityViewType.List}"
				(click)="activityView = ActivityViewType.List"
		></rb-icon-button>
	</div>
	<div class="view-type-selection-container" *ngIf="showViewOptions" >
		<rb-icon-button
				[label]="'STRINGS.COURSE' | translate"
				icon="golf_course"
				class="dr-view-type"
				[ngClass]="{'dr-view-selected': activityView === ActivityViewType.Course}"
				(click)="activityView = ActivityViewType.Course"
		></rb-icon-button>
	</div>

	<div class="report-filter-components flex rb-flex-grow-3">
		<rb-date-range-dropdown
			*ngIf="!hideDateRange"
			class="rb-date-range-dropdown button-dropdown-daterange flex items-center"
			placeHolder=""
			[dateRangeDropdown]="dateRangeDropdown"
			[startDateValue]="startDate"
			[endDateValue]="endDate"
			[selectedDateRange]="selectedDateRange"
			(startDateChange)="handleChange('startDate',$event)"
			(endDateChange)="handleChange('endDate',$event)"
			(dateRangeChange)="handleChange('dateRange',$event)"
			[useStartOfDayForDateRange]="useStartOfDayForDateRange"
			[hideTimeForDateRange]="hideTimeForDateRange"
			[hideDates]="hideDates"
		>
		</rb-date-range-dropdown>

		<rb-alerts-filter-dropdown #alertsFilterDropdown *ngIf="!hideAlertsFilterDropdown"
			class="ml2 rb-date-range-dropdown flex items-center button-dropdown-daterange alerts-filter-dropdown"
			[label]="filterLabel"
			[selectedItems]="selectedEventLogTypes"
			[items]="eventLogTypes"
			(selectChanged)="handleChange('alarmTypes',$event)"
		>
		</rb-alerts-filter-dropdown>

		<div class="rb-date-range-dropdown button-dropdown-daterange items-center flex">
			<mat-form-field *ngIf="!hideActivityTypeDropdown" class="ml2 mr2">
				<mat-select
					class="ml0"
					[placeholder]="'STRINGS.ACTIVITY_TYPE' | translate"
					name="activityType"
					#activityType="ngModel"
					[(ngModel)]="selectedActivityType"
					(ngModelChange)="onTypeSelectChanged($event)"
				>
					<mat-option *ngFor="let aType of activityTypes" [value]="aType.value" class="pv1" color="primary">
						{{aType.name | translate}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div *ngIf="shouldShowAcknowledgeButton" class="ml2 rb-flex-grow-0 alert-filter-ack-btn">
		<rb-report-button
			[label]="ackButtonLabel | translate"
			(reportButtonClicked)="AckButton()"
		>
		</rb-report-button>
	</div>
	<div *ngIf="shouldShowAckAllButton" class="ml2 rb-flex-grow-0 alert-filter-ack-btn">
		<rb-report-button
			[label]="ackAllButtonLabel | translate"
			(reportButtonClicked)="handleAckAll()"
		>
		</rb-report-button>
	</div>
	<div *ngIf="shouldShowCSVButton" class="ml2 rb-flex-grow-0 alert-filter-ack-btn">
		<rb-report-button
			[label]="'STRINGS.EXPORT_TO_CSV_UPPERCASE' | translate"
			(reportButtonClicked)="onExportCSV()"
		>
		</rb-report-button>
	</div>
</div>


/*
 * NOTE: DO NOT USE THESE CONSTANTS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbConstants Namespace.
 * 		 E.g., RbConstants.Common.AppName
 * 		 See: _rb.constants.ts
 */

import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';

export namespace XXUseRbConstantsNamespace {

	export abstract class Common {

		static readonly AppName = 'Rain Bird';
		static readonly MaxMobileWindowWidth = 768;
		static readonly DefaultMaxTabletWindowWidth = 1200;
		static readonly MaxAgeOfSatelliteChangeInMinutes = 2;
		static readonly NEW_SOFTWARE_AVAILABLE_BANNER_NOT_DISPLAY_IN_SEVEN_DAYS = 7;
		static readonly MANUAL_OPS_DURATION_KEY = '_manualOpsDuration';
		static readonly DO_NOT_SHOW_ENTITLEMENTS_RENEWAL_MESSAGE = 'DoNotShowEntitlementsRenewalMessage';
		static readonly MAX_NETWORK_RADIO_NUMBER = 4;
		static readonly LEFT_SIDEBAR_STATE_KEY = 'isLsbMinimized';
		static readonly PROGRAM_COLUMN_VIEW_CONFIG_UI_REF_KEY = '_programColumnViewConfigUiRef';
		static get Countries(): string[] {
			// RB-8585: Enumerate the countries from the string table and build an array of their values. We do
			// this in part so we can identify a country name by its string table ID. It also allows country names
			// to be translated into the user's local language. I've left the original array below for validation.
			const countryObject = RbUtils.Translate.instant('COUNTRIES');
			const countryNames = Object.values(countryObject).map(val => <string>val);

			return countryNames;
		}

		/**
		 * Path to the Golf how-to videos. See RB-10334.
		 */
		static get GolfHowToVideosURL(): string {
			return 'https://www.rainbird.com/golf/cirruspro-how-videos';
		}

		/** 
		* A list of alphabet country codes for the European Union. 
		*/
		static readonly EUROPEAN_UNION_CODES = [
			'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE',
			'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV',
			'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL',
			'PT', 'RO', 'SI', 'SK', 'FI', 'SE'
		];

		static readonly SUPPORT_CONTACT = {
			US_CANADA: {
				phoneNumber: '1-800-396-5166',
				email: 'prosupport@rainbird.com',
			},
			US_CANADA_GOLF: {
				phoneNumber: '1-866-477-9778',
				email: 'gsp@rainbird.com',
			},
			EUROPE: {
				phoneNumber: '0034 912 789 532',
				email: 'gsp@rainbird.eu',
			},
			AUSTRALIA: {
				phoneNumber: '1800 225 512',
				email: 'gspservices@rainbird.com.au',
			},
			INTERNATIONAL: {
				phoneNumber: '1-520-434-6216',
				email: 'gsp@rainbird.com',
			},
		};

		static readonly DiagnosticTestsPerStationType: { [stationParentDeviceType: number]: number[] } = {
			[RbEnums.Common.DeviceType.ICI]: [
				RbEnums.Common.StationDiagnosticRunType.StatusCheck,
				RbEnums.Common.StationDiagnosticRunType.VoltageCheck
			],
			[RbEnums.Common.DeviceType.LDISDI]: [
				RbEnums.Common.StationDiagnosticRunType.OnOffCheck
			]
		};
	}

}

import { Controller } from '../../api/controllers/models/controller.model';
import { ControllerListItem } from '../../api/controllers/models/controller-list-item.model';
import { ConversionService } from '../services/conversion.service';
import { FlowSensor } from '../../api/controllers/models/flow-sensor.model';
import { GolfProgrammableSensor } from '../../api/sensors/models/golf-programmable-sensor.model';
import { GolfSensor } from '../../api/sensors/models/golf-sensor.model';
import { GolfSensorListItem } from '../../api/sensors/models/golf-sensor-list-item.model';
import { Observable } from 'rxjs';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from './_rb.utils';
import { SensorStatus } from '../../api/sensors/models/sensor-status.model';
import { SensorStatusChange } from '../../api/signalR/sensor-status-change.model';
import { SensorTrigger } from '../../api/sensor-trigger/models/sensor-trigger.model';
import { TranslateService } from '@ngx-translate/core';
import { UnitLabelService } from '../services/unit-label.service';

import ComparisonType = RbEnums.Common.ComparisonType;

/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Sensor.xxx()
 * 		 See: _rb.utils.ts
 */

export namespace XXUseRbUtilsNamespace {

    export abstract class Sensor {

        static isSwitch(weatherSensorModel: RbEnums.Common.GolfWeatherSensorModel): boolean {
            switch (weatherSensorModel) {
                case RbEnums.Common.GolfWeatherSensorModel.RainSwitch:
                case RbEnums.Common.GolfWeatherSensorModel.TemperatureSwitch:
                case RbEnums.Common.GolfWeatherSensorModel.Hygrometer:
                    return true;
                default:
                    return false;
            }
        }

        static isRainCan(weatherSensorModel: RbEnums.Common.GolfWeatherSensorModel): boolean {
            switch (weatherSensorModel) {
                case RbEnums.Common.GolfWeatherSensorModel.RainCan:
                    return true;
                default:
                    return false;
            }
        }

        /**
         * @summary Update the sensor status for the indicated GolfSensorListItem. If the triggers parameter is
         * provided, this includes updating the trigger state indicators for programmable sensors.
         * @param golfSensorStatus - latest SensorStatusChange SignalR message
         * @param sensor - GolfSensorListItem to be updated
         * @param triggers - Observable<SensorTrigger[]> for the sensor's trigger data, or empty/null if nothing
         * to do with triggers.
         * @returns SensorStatus object containing the SensorStatusChange and a textual description of the sensor
         * status.
         */
        static updateGolfSensorStatus(golfSensorStatus: SensorStatusChange, sensor: GolfSensorListItem,
                                      triggers: Observable<SensorTrigger[]>): SensorStatus {
            const sensorStatus = new SensorStatus();

            // Store the SensorStatusChange in the 'Sensor' List Item. This will allow us to keep track of various things,
            // a bit like how we track station remaining run time.
            sensorStatus.sensorStatusItem = golfSensorStatus;
            sensorStatus.status = 'Hello from rb-sensor.util.ts';

            sensor.sensorStatus = sensorStatus;

            if (sensor.kingdomId === RbEnums.Common.GolfSensorKingdom.Programmable) {
                triggers.subscribe(trigs => {
                    // Accept either null or empty array as "no triggers".
                    if (trigs != null && trigs.length > 0) {
                        // Check each trigger for range against current sensor value and return correct info to the
                        // programmableSensorActiveIndicators property of the sensor. We have a three-state comparison
                        // going here, as isActive can be indeterminate (null). In that case, we don't change the
                        // reference to the existing trigger symbol. This has issues if you have multiple triggers
                        // with the same symbol, however. We handle that here, too.
                        const oldActiveIndices = sensor.programmableSensorActiveIndicators;
                        const newActiveIndices = [];
                        trigs.forEach(t => {
                            // This will include indicators only for absolutely-definitely-active states. If the isActive()
                            // method returns null, we treat that as inactive. This is not the only way we could do it,
                            // leaving active indicators active if we get null. We also prevent duplicates.
                            if (!newActiveIndices.includes(t.symbolId)) {	// If the item is already shown, don't check it or add again
                                const trigActive = t.isActive(sensor, sensorStatus.sensorStatusItem);
                                if (trigActive === true) {
                                    newActiveIndices.push(t.symbolId);
                                } else if (trigActive === false) {
                                    // Do nothing.
                                } else {
                                    // Push the old result for the symbol. If it was present, it's present still. If not, it's
                                    // not.
                                    if (oldActiveIndices.includes(t.symbolId)) {
                                        newActiveIndices.push(t.symbolId);
                                    }
                                }
                            }
                        });
                        sensor.programmableSensorActiveIndicators = newActiveIndices;
                    }
                });
            }

            return sensor.sensorStatus;
        }

        /**
         * Return an indicator of whether the specified SensorChange.ChangeType value indicates a real-time status (sensor
         * update, basically) state. This centralizes separating things like Added, Deleted, Updated from things like
         * SensorValue.
         * @param changeType - string change type from a SensorChange.
         * @returns true if the sensor state is defined by the message; false if the message is not status-related.
         */
        static isSensorRealTimeStatusMessage(changeType: string): boolean {
            switch (changeType) {
                case RbEnums.SignalR.SensorStatusChangeType.Added:
                case RbEnums.SignalR.SensorStatusChangeType.Deleted:
                case RbEnums.SignalR.SensorStatusChangeType.Updated:
                    return false;

                case RbEnums.SignalR.SensorStatusChangeType.SensorValue:
                    return true;

                default:
                    // Anything else returns false, for now. It's probably an error, however, so drop a messsage in the
                    // console to indicate something is amiss.
                    console.log('UNEXPECTED SENSOR STATUS MESSAGE: %o. See isSensorealTimeStatus', changeType);
                    return false;
            }
        }

        /**
         * @summary Return the units for programmable sensor in a given mode. For example, voltage sensors should be in
         * volts, current in mA, etc.
         * @param sensor - GolfSensorListItem | GolfSensor
         * @returns string units value for display.
         */
        static sensorUnits(sensor: GolfSensorListItem | GolfSensor): string {
            let sensorType: RbEnums.Common.SensorType;
            if (sensor instanceof GolfSensorListItem) {
                sensorType = sensor.typeId;
            } else {
                sensorType = sensor.type;
            }
            return this.sensorModeUnits(sensorType);
        }

        /**
         * @summary Convert a sensor data value into a displayable string with the correct resolution.
         * @param decimalSeparator - string specifying the decimal point in the current culture
         * @param sensor - GolfSensorListItem | GolfSensor so we can check the mode
         * @param sensorValue - number containing the value to be displayed
         * @returns string with the correct number of decimal places, formatted correctly for user display
         */
        static roundSensorValueForDisplay(decimalSeparator: string, sensor: GolfSensorListItem | GolfSensor, sensorValue: number): string {
            let sensorType: RbEnums.Common.SensorType;
            if (sensor instanceof GolfSensorListItem) {
                sensorType = sensor.typeId;
            } else {
                sensorType = sensor.type;
            }
            switch (sensorType) {
                case RbEnums.Common.SensorType.Voltage:
                case RbEnums.Common.SensorType.Current:
                    return RbUtils.Common.convertValueToPrecision(decimalSeparator, '' + sensorValue, 2);

                case RbEnums.Common.SensorType.Pulse:
                case RbEnums.Common.SensorType.PulseAccumulative:
                case RbEnums.Common.SensorType.PulseNonAccumulative:
                case RbEnums.Common.SensorType.PulsesIn10Seconds:
                    return RbUtils.Common.convertValueToPrecision(decimalSeparator, '' + sensorValue, 0);

                case RbEnums.Common.SensorType.TimeBetweenPulses:
                    // Units are ms and smallest value allowed is 100ms, so zero decimal places, please.
                    return RbUtils.Common.convertValueToPrecision(decimalSeparator, '' + sensorValue, 0);

                default:	// No rounding
                    return '' + sensorValue;
            }
        }

        /**
         * @summary Return the units for programmable sensor in a given mode. For example, voltage sensors should be in
         * volts, current in mA.
         * @param sensorType - RbEnums.Common.SensorType describing the mode for the sensor.
         * @returns string units value for display
         */
        static sensorModeUnits(sensorType: RbEnums.Common.SensorType) {
            switch (sensorType) {
                case RbEnums.Common.SensorType.Voltage:
                    return RbUtils.Translate.instant('UNIT_TYPE.VOLTS_ABBR');

                case RbEnums.Common.SensorType.Current:
                    return RbUtils.Translate.instant('UNIT_TYPE.MILLIAMPS_ABBR');

                case RbEnums.Common.SensorType.Pulse:
                case RbEnums.Common.SensorType.PulseAccumulative:
                case RbEnums.Common.SensorType.PulseNonAccumulative:
                case RbEnums.Common.SensorType.PulsesIn10Seconds:
                    return RbUtils.Translate.instant('UNIT_TYPE.PULSES');

                case RbEnums.Common.SensorType.TimeBetweenPulses:
                    return RbUtils.Translate.instant('UNIT_TYPE.MILLISECONDS_ABBR');

                // On/Off, etc.
                default:
                    return '';
            }
        }

        /**
         * Return the golf rainfall string for Rain Watch, WeatherData, etc., in the user's profile units (mm or inch).
         * @param rainfallInches - Rainfall value in inches. Will be converted to mm, if Metric units currently
         *    selected by the user.
         * @param includeUnits - boolean set to true (default) to include the rainfall units in the returned string, or false
         * to return only the numeric value
         * @returns string containing rainfall amount and units (if enabled)
         */
        static getGolfSensorRainfallString(rainfallInches: number, includeUnits: boolean = true, decimalPlaces: number = 2): any {
            if (!rainfallInches) rainfallInches = 0;
            const unitsType = RbUtils.User.unitsType;
            const units = this.getGolfRainfallUnits();
            const adjustedRainfall = unitsType === RbEnums.Common.UnitsType.English ? rainfallInches : rainfallInches * 25.4;	// 25.4 mm/inch

            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                adjustedRainfall.toString(),
                decimalPlaces);
            if (includeUnits) {
                ret += ' ' + units;
            }
            return ret;
        }

        /**
         * Return the golf rainfall string for cases where we have mm, not inches. Handles conversion based on user unit system and can
         * include or exclude the units string, also.
         * @param rainfallMM - Rainfall value in mm. Will be converted to inches, if English units currently selected by the user.
         * @param includeUnits - boolean set to true (default) to include the rainfall units in the returned string, or false
         * to return only the numeric value
         * @returns string containing rainfall amount and units (if enabled)
         */
        static getGolfSensorRainfallStringForMM(rainfallMM: number, includeUnits: boolean = true): any {
            if (!rainfallMM) rainfallMM = 0;
            const unitsType = RbUtils.User.unitsType;
            const decimalPlaces = unitsType === RbEnums.Common.UnitsType.English ? 2 : 3;
            const units = this.getGolfRainfallUnits();
            const adjustedRainfall = unitsType === RbEnums.Common.UnitsType.English ? rainfallMM / 25.4 : rainfallMM;

            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                adjustedRainfall.toString(),
                decimalPlaces);
            if (includeUnits) {
                ret += ' ' + units;
            }
            return ret;
        }

        /**
         * Return rainfall units for the user's selected unit system, inches or mm.
         */
        static getGolfRainfallUnits(): string {
            const unitsType = RbUtils.User.unitsType;
            return unitsType === RbEnums.Common.UnitsType.English ?
                RbUtils.Translate.instant('UNIT_TYPE.INCH') :
                RbUtils.Translate.instant('UNIT_TYPE.MILLIMETER');
        }

        /**
         * Return the golf temperature string for WeatherData, etc., in the user's profile units (°F or °C, based on
         * English or Metric setting.
         * @param temperatureF - Temperature value in °F. This will be converted to °C if the user's units are metric.
         * @param includeUnits - boolean set to true to return the units string (default), or false to return only the value.
         * @returns string containing temperature and units.
         */
        static getGolfSensorTemperatureString(temperatureF: number, includeUnits: boolean = true, decimalPlaces: number = 1): any {
            if (!temperatureF) temperatureF = 0;
            const unitsType = RbUtils.User.unitsType;
            const units = unitsType === RbEnums.Common.UnitsType.English ?
                RbUtils.Translate.instant('UNIT_TYPE.DEGREES_F') :
                RbUtils.Translate.instant('UNIT_TYPE.DEGREES_C');
            const adjustedTemperature = unitsType === RbEnums.Common.UnitsType.English ? temperatureF : (temperatureF - 32.0) * 5.0 / 9.0;

            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                adjustedTemperature.toString(),
                decimalPlaces);
            if (includeUnits) {
                ret += units;
            }
            return ret;
        }

        /**
         * Return the golf temperature string for incoming data in °C in the user's profile units (°F or °C, based on
         * English or Metric setting.
         * @param temperatureC - Temperature value in °C. This will be converted to °F if the user's units are English.
         * @param includeUnits - boolean set to true to return the units string (default), or false to return only the value.
         * @returns string containing temperature and units.
         */
        static getGolfSensorTemperatureStringForC(temperatureC: number, includeUnits: boolean = true): any {
            if (!temperatureC) temperatureC = 0;
            const unitsType = RbUtils.User.unitsType;
            const decimalPlaces = 1;	// For most cases, 1 decimal place is right
            const units = unitsType === RbEnums.Common.UnitsType.English ?
                RbUtils.Translate.instant('UNIT_TYPE.DEGREES_F') :
                RbUtils.Translate.instant('UNIT_TYPE.DEGREES_C');
            const adjustedTemperature = unitsType === RbEnums.Common.UnitsType.English ? temperatureC * 9.0 / 5.0 + 32.0 : temperatureC;

            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                adjustedTemperature.toString(),
                decimalPlaces);
            if (includeUnits) {
                ret += units;
            }
            return ret;
        }

        /**
         * Return the golf solar radiation string for WeatherData, etc., in the user's profile units (Langley, for now).
         * @param solarRadiationLangley - Solar radiation in Langley
         * @returns string containing value and units.
         */
        static getGolfSensorSolarRadiationString(solarRadiationLangley: number, includeUnits: boolean = true, decimalPlaces: number = 0): any {
            if (!solarRadiationLangley) solarRadiationLangley = 0;
            const units = RbUtils.Translate.instant('UNIT_TYPE.LANGLEY');

            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                solarRadiationLangley.toString(),
                decimalPlaces);
            if (includeUnits) {
                ret += ' ' + units;
            }
            return ret;
        }

        /**
         * Return the golf ET string for WeatherData, etc., in the user's profile units (in or mm).
         * @param etInches - ET value in inches
         * @returns string containing value and units.
         */
        static getGolfSensorETString(etInches: number, includeUnits: boolean = true, decimalPlaces: number = 2): any {
            // This works the same as rainfall for units and amounts, so just delegate to that call.
            return this.getGolfSensorRainfallString(etInches, includeUnits, decimalPlaces);
        }

        /**
         * Return the golf wind direction string for WeatherData, etc., in the user's profile units, if any units are
         * needed other than degrees
         * @param windDirDegrees - wind direction in degrees with 0 = North
         * @returns string containing value and units.
         */
        static getGolfSensorWindDirectionString(windDirDegrees: number): any {
            if (!windDirDegrees) windDirDegrees = 0;
            const decimalPlaces = 0;
            const units = '°';

            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                windDirDegrees.toString(),
                decimalPlaces);
            ret += units;
            return ret;
        }

        /**
         * Return the golf wind speed string for WeatherData, etc., in the user's profile units, if any units are
         * needed other than degrees
         * @param windSpeedMPH - wind speed in mph
         * @returns string containing value and units.
         */
        static getSensorWindSpeedString(windSpeedMPH: number, includeUnits: boolean, precision = 1): any {
            if (!windSpeedMPH) windSpeedMPH = 0;
            const unitsType = RbUtils.User.unitsType;
            const decimalPlaces = precision;
            const units = includeUnits ? this.getGolfSensorWindSpeedUnits() : '';
            const adjustedSpeed = unitsType === RbEnums.Common.UnitsType.English ? windSpeedMPH : windSpeedMPH * 1.60934;

            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                adjustedSpeed.toString(),
                decimalPlaces);
            ret += units;
            return ret;
        }

        /**
         * Return the golf wind speed string for cases where m/s is passed, translating to the user's selected units and optionally
         * including the units string in the output.
         * @param windSpeedMS - wind speed in m/s
         * @returns string containing value and units.
         */
        static getGolfSensorWindSpeedStringForMS(windSpeedMS: number, includeUnits: boolean, decimalPlaces: number = null): any {
            if (!windSpeedMS) windSpeedMS = 0;
            const unitsType = RbUtils.User.unitsType;
            if (decimalPlaces == null) {
                decimalPlaces = 1;
            }
            const units = includeUnits ? this.getGolfSensorWindSpeedUnits() : '';
            const adjustedSpeed = unitsType === RbEnums.Common.UnitsType.English ? windSpeedMS / 1.60934 : windSpeedMS;

            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                adjustedSpeed.toString(),
                decimalPlaces);
            ret += units;
            return ret;
        }
        /**
         * RB-13753:
         * Return humidity value applying decimal separator and round the value.
         * @param humidity - percentage of humidity.
         * @returns string containing value.
         */
        static getHumidityString(humidity: number, includeUnits: boolean = false, decimalPlaces: number = 1): any {
            const rounded = RbUtils.Common.roundToPrecision(humidity, 1, false);
            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                rounded.toString(),
                decimalPlaces);
            if (includeUnits) {
                ret += this.getGolfHumidityUnits() ;
                return ret;
            }
            return ret;
        }

        /**
         * Return wind speed units string for user's unit system, generally mph or kph.
         */
        static getGolfSensorWindSpeedUnits(): string {
            const unitsType = RbUtils.User.unitsType;
            return (unitsType === RbEnums.Common.UnitsType.English ?
                RbUtils.Translate.instant('UNIT_TYPE.MILES_PER_HOUR') :
                RbUtils.Translate.instant('UNIT_TYPE.KILOMETERS_PER_HOUR'));
        }

        /**
         * Return the golf barometric pressure string, given the incoming pressure expressed in hPa
         * @param baroPressurehPa - barometric pressure in hPa
         * @returns string containing value and units, based on the conversion and significant digits in the result
         */
        static getBarometricPressureString(baroPressurehPa: number, includeUnits: boolean): any {
            if (!baroPressurehPa) baroPressurehPa = 0;	// 0 isn't too valid!
            const unitsType = RbUtils.User.unitsType;
            const decimalPlaces = unitsType === RbEnums.Common.UnitsType.English ? 2 : 0;	// In hPa, zero decimal places is correct; 2 for inHg
            const units = includeUnits ? this.getGolfBarometricPressureUnits() : '';
            const adjustedPressure = unitsType === RbEnums.Common.UnitsType.English ? baroPressurehPa * 0.029529983071445 : baroPressurehPa;

            let ret = RbUtils.Common.convertValueToPrecision(RbUtils.User.cultureSettings.decimalSeparator,
                adjustedPressure.toString(),
                decimalPlaces);
            ret += units;
            return ret;
        }

        /**
         * Return barometric pressure units string for user's unit system, generally inHg or hPa.
         */
        static getGolfBarometricPressureUnits(): string {
            const unitsType = RbUtils.User.unitsType;
            return (unitsType === RbEnums.Common.UnitsType.English ?
                RbUtils.Translate.instant('WEATHER_UNITS.IMPERIAL.PRESSURE') :
                RbUtils.Translate.instant('WEATHER_UNITS.METRIC.PRESSURE'));
        }

        /**
         * Return percent units string for user's unit system, %.
         */
        static getGolfHumidityUnits(): string {
            const unitsType = RbUtils.User.unitsType;
            return (unitsType === RbEnums.Common.UnitsType.English ?
                RbUtils.Translate.instant('WEATHER_UNITS.IMPERIAL.HUMIDITY') :
                RbUtils.Translate.instant('WEATHER_UNITS.METRIC.HUMIDITY'));
        }

        // =====================================================================================================================================================
        // Helper/utility methods
        // =====================================================================================================================================================

        // RB-14909: Used to check which interfaces support what type of sensors or models.
        static readonly SUPPORTED_GOLF_SENSORS = {
            [RbEnums.Common.DeviceType.LDISDI]: {
                [RbEnums.Common.GolfSensorKingdom.Programmable]: [
                    RbEnums.Common.SensorType.SwitchOpenClose,
                    RbEnums.Common.SensorType.Current,
                    RbEnums.Common.SensorType.Pulse,
                    RbEnums.Common.SensorType.PulseNonAccumulative,
                    RbEnums.Common.SensorType.PulsesIn10Seconds,
                ],
                [RbEnums.Common.GolfSensorKingdom.Weather]: [
                    RbEnums.Common.GolfWeatherSensorModel.RainCan,
                    RbEnums.Common.GolfWeatherSensorModel.RainSwitch,
                    RbEnums.Common.GolfWeatherSensorModel.TemperatureSwitch,
                ]
            },
            [RbEnums.Common.DeviceType.MIM]: {
                [RbEnums.Common.GolfSensorKingdom.Programmable]: [
                    // For Sensor decoder satellite.
                    RbEnums.Common.SensorType.SwitchOpenClose,
                    // For Pulse decoder satellite.
                    RbEnums.Common.SensorType.Pulse,
                    RbEnums.Common.SensorType.PulseNonAccumulative,
                    RbEnums.Common.SensorType.TimeBetweenPulses,
                    RbEnums.Common.SensorType.PulsesIn10Seconds,
                ],
                [RbEnums.Common.GolfSensorKingdom.Weather]: [
                    // For Sensor decoder satellite.
                    RbEnums.Common.GolfWeatherSensorModel.RainSwitch,
                    RbEnums.Common.GolfWeatherSensorModel.TemperatureSwitch,
                    // For Pulse decoder satellite.
                    RbEnums.Common.GolfWeatherSensorModel.RainCan,
                    RbEnums.Common.GolfWeatherSensorModel.WindSpeed,
                ]
            },
            // Note that we allow all types below; filtering using isNan() to retreive the numeric values only of the enums since Object.Values() will return
            // both, string enum names and numeric values.
            [RbEnums.Common.DeviceType.MIM_LINK]: {
                [RbEnums.Common.GolfSensorKingdom.Programmable]: [
                    ...Object.values(RbEnums.Common.SensorType).filter(v => !isNaN(+v)),
                ],
                [RbEnums.Common.GolfSensorKingdom.Weather]: [
                    ...Object.values(RbEnums.Common.GolfWeatherSensorModel).filter(v => !isNaN(+v)),
                ]
            },
            [RbEnums.Common.DeviceType.ICI]: {
                [RbEnums.Common.GolfSensorKingdom.Programmable]: [
                    ...Object.values(RbEnums.Common.SensorType).filter(v => !isNaN(+v)),
                ],
                [RbEnums.Common.GolfSensorKingdom.Weather]: [
                    ...Object.values(RbEnums.Common.GolfWeatherSensorModel).filter(v => !isNaN(+v)),
                ]
            },
        }

        /**
         * Gets a list of supported sensors given an interface type and sensor type.
         * @returns List of sensors as an array of numbers.
         */
        static getInterfaceSupportedSensorsTypes(interfaceType: RbEnums.Common.DeviceType, sensorKingdom: RbEnums.Common.GolfSensorKingdom)
            : RbEnums.Common.GolfWeatherSensorModel[] | RbEnums.Common.SensorType[] {
            return RbUtils.Sensor.SUPPORTED_GOLF_SENSORS[interfaceType][sensorKingdom];
        }

        /**
         * @summary Perform a trigger comparison against a sensor value, returning true if the trigger would be called
         * 'active', false if the trigger would be called 'inactive' and 'null' if we can't make a determination.
         * @param currentValue - any sensor value
         * @param units - string sensor units
         * @param operator - ComparisonType describing the comparison performed on the currentValue and trigger thresholds
         * @param threshold1 - number threshold #1 for the comparison
         * @param threshold2 - number threshold #2 for the comparison
         * @returns boolean - true if the triggger comparison is active, false if not, null if indeterminate
         */
        static comparisonResult(currentValue: any, units: string,
                                operator: ComparisonType, threshold1: number, threshold2: number): boolean {
            if (currentValue == null) {
                return null;
            }
            switch (operator) {
                case RbEnums.Common.ComparisonType.EqualTo:
                    return (<number>currentValue) === threshold1;	// Note that we allow auto-conversion of the value.
                case RbEnums.Common.ComparisonType.GreaterOrEqualTo:
                    return currentValue >= threshold1;
                case RbEnums.Common.ComparisonType.GreaterThan:
                    return currentValue > threshold1;
                case RbEnums.Common.ComparisonType.InsideRange:
                    return currentValue > threshold1 && currentValue < threshold2;
                case RbEnums.Common.ComparisonType.InsideRangeInclusive:
                    return currentValue >= threshold1 && currentValue <= threshold2;
                case RbEnums.Common.ComparisonType.LessOrEqualTo:
                    return currentValue <= threshold1;
                case RbEnums.Common.ComparisonType.LessThan:
                    return currentValue < threshold1;
                case RbEnums.Common.ComparisonType.NotEqualTo:
                    return (<number>currentValue) !== threshold1;	// Note that we allow auto-conversion of the value.
                case RbEnums.Common.ComparisonType.OutsideRange:
                    return currentValue < threshold1 || currentValue > threshold2;
                case RbEnums.Common.ComparisonType.OutsideRangeInclusive:
                    return currentValue <= threshold1 || currentValue >= threshold2;
                default:
                    console.error('ERROR: SensorTrigger: Invalid comparison type %o', operator);
                    return null;
            }
        }

        /**
         * @summary Called to generate a cell value for the sensor status
         * This should indicate the sensor value and units
         * @param conversionService - conversion service
         * @param translateService - translate service
         * @param unitLabelService - unit label service
         * @param item - golf sensor list item
         * @returns string - the text is combine value and unit
         */
        static getSensorStatus(conversionService: ConversionService,
                               translateService: TranslateService,
                               unitLabelService: UnitLabelService,
                               item: GolfSensorListItem): string {
            let currentValue = item.sensorStatus.sensorStatusItem.currentValue;
            let units = item.sensorStatus.sensorStatusItem.currentValueUnits;

            // RB-8486: Handle null/undefined.
            if (currentValue == null) {
                return `-`;
            }
            if (item.kingdomId === RbEnums.Common.GolfSensorKingdom.Weather
                && item.golfWeatherSensorModel === RbEnums.Common.GolfWeatherSensorModel.RainSwitch) {
                let result;
                if (+currentValue === 1) {
                    result = translateService.instant('STRINGS.ACTIVE');
                } else {
                    result = translateService.instant('STRINGS.INACTIVE');
                }
                return result;
            }
            if (item.kingdomId === RbEnums.Common.GolfSensorKingdom.Flow) {
                currentValue = conversionService.getUserFlowRateString(item.sensorStatus.sensorStatusItem.currentValue);
                units = unitLabelService.getFlowRateLabel();
            }
            if (units == null) {
                // For programmable sensors, generate units based on UI string table values and sensor mode.
                if (item.kingdomId === RbEnums.Common.GolfSensorKingdom.Programmable) {
                    units = RbUtils.Sensor.sensorUnits(item);
                } else {
                    units = '';
                }
            }
            // Adjust the currentValue to the correct number of decimal places.
            currentValue = RbUtils.Sensor.roundSensorValueForDisplay(conversionService.cultureSettings.decimalSeparator,
                item, currentValue);

            // Combine the values.
            return `${currentValue} ${units}`;
        }

        /**
         * @summary get the address for satellite based sensors in the format of InterfaceName-GroupNumber-SatelliteNumber-Subchannel
         * @param satelliteInterface - parent interface of satellite
         * @param satellite - parent of sensor
         * @param sensor - the sensor we're trying to get the address for
         * @returns string - address
         */
        static getSatelliteBasedSensorAddress(satelliteInterface: ControllerListItem | Controller, 
                                              satellite: ControllerListItem | Controller, 
                                              sensor: GolfSensorListItem | GolfProgrammableSensor | FlowSensor | Sensor): string {
            if (satellite.type === RbEnums.Common.DeviceType.ESP_MC) {
                const subchannel = sensor['subchannel'] ? sensor['subchannel'] : sensor['subChannel'];
                return RbUtils.Sensor.formatSatelliteSensorAddress(satelliteInterface.name, satellite.groupNumber, satellite.address, subchannel);
            }

            return RbUtils.Sensor.formatSatelliteSensorAddress(satelliteInterface.name, satellite.groupNumber, satellite.address);
        }

        /**
         * @summary get the address in the format of InterfaceName-GroupNumber-SatelliteNumber-Subchannel
         * @returns string - satellite address
         */
        static formatSatelliteSensorAddress(interfaceName: string, groupNumber: number, satelliteAddress?: number, subchannel?: string): string {
            if (subchannel) {
                return `${interfaceName}-${groupNumber}-${satelliteAddress}-${subchannel}`;
            }

            if (satelliteAddress) {
                return `${interfaceName}-${groupNumber}-${satelliteAddress}`;
            }
            // when creating a Pulse Decoder or Sensor Decoder it does not have an address yet to display
            return `${interfaceName}-${groupNumber}`;
        }

        static canShowSensorUpdateInterval(deviceType: RbEnums.Common.DeviceType): boolean{
            return deviceType !== RbEnums.Common.DeviceType.ESP_MC;
        }
    }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fabGroupButtonAnimations } from './fab-group-button.animations';

const STATE_ACTIVE = 'active';
const STATE_INACTIVE = 'inactive';

@Component({
	selector: 'rb-fab-group-button',
	templateUrl: './fab-group-button.component.html',
	styleUrls: ['./fab-group-button.component.scss'],
	animations: fabGroupButtonAnimations,
})
export class FabGroupButtonComponent implements OnInit {

	@Input() fabButtons: Map<string, any>;
	@Input() primarySelection: string;

	@Output() optionClick: EventEmitter<any> = new EventEmitter<any>();

	buttons = [];
	buttonText = '';
	fabTogglerState = STATE_INACTIVE;

	constructor() { }

	ngOnInit() {
	}

	showItems() {
		this.fabTogglerState = STATE_ACTIVE;
		this.buttons = Array.from(this.fabButtons.keys());
		this.buttonText = this.primarySelection;
	}

	hideItems() {
		this.fabTogglerState = STATE_INACTIVE;
		this.buttons = [];
		this.buttonText = '';
	}

	onToggleFab(selectedItem?: string) {
		if (this.hasButtonText() && !selectedItem && !this.primarySelection) {
			selectedItem = this.buttonText;
		}
		const itemToEmit = this.fabButtons.get(selectedItem);
		this.optionClick.emit(itemToEmit);
		this.buttons.length ? this.hideItems() : this.showItems();
	}

	hasButtonText() {
		return this.buttonText !== null && this.buttonText !== undefined && this.buttonText !== '';
	}
}

/* eslint-disable @angular-eslint/directive-class-suffix */
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { Directive } from '@angular/core';
import { TextInputCellEditorBase } from './_text-input-cell-editor-base';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class NumericCellEditorBase extends TextInputCellEditorBase {

	alignTextRight = false;
	numberRegEx: RegExp;
	maxDecimalPlaces = 2;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected authManager: AuthManagerService,
				protected broadcastService: BroadcastService,
				protected translateService: TranslateService,
	) {
		super(broadcastService, translateService);
	}

	// =========================================================================================================================================================
	// agGrid Methods
	// =========================================================================================================================================================

	// Get the value from the grid.
	agInit(params: any): void {
		super.agInit(params);

		if (this.params.colDef.cellEditorParams) {
			if (this.params.colDef.cellEditorParams.textAlign) { this.alignTextRight = this.params.colDef.cellEditorParams.textAlign === 'right'; }
		}

		const sep = this.authManager.userCulture.decimalSeparator;
		const regExPattern =
			this.maxDecimalPlaces > 0 ? `^(\\d*(\\${sep}\\d{0,${this.maxDecimalPlaces}})?|\\${sep}?\\d{1,${this.maxDecimalPlaces}})$` : '^\\d+$';
		this.numberRegEx = new RegExp(regExPattern);
	}

	// Override base class.
	getValue() {
		return +this.textInput.nativeElement.value;
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onKeyPress(event) {
		// Disallow any invalid key presses.
		if (!this.numberRegEx.test(this.getPotentialValueString(event.key))) {
			event.preventDefault();
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getPotentialValueString(key: string) {
		const ctl = this.textInput.nativeElement;
		const chars = ctl.value.split('');
		chars.splice(ctl.selectionStart, ctl.selectionEnd - ctl.selectionStart, key);
		return chars.join('');
	}

}

import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { User } from 'oidc-client';

export class UserEventSubscription {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			// You might need to take special measures to convert some properties here...
		}
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================

	userId: number;
	companyId: number;
	iqEvent: RbEnums.Common.IQEvent;
	subscriptionType: RbEnums.Common.UserEventSubscriptionType;
	user: User;

	// =========================================================================================================================================================
	// Static Methods
	// =========================================================================================================================================================

	static createDefault(userId: number, iqEvent: RbEnums.Common.IQEvent): UserEventSubscription {
		const sub = new UserEventSubscription();
		sub.userId = userId;
		sub.iqEvent = iqEvent;
		sub.subscriptionType = RbEnums.Common.UserEventSubscriptionType.Email;
		return sub;
	}

	iqEventDescription(): string { return RbEnums.Common.IQEvent[this.iqEvent]; }

	subscriptionTypeDescription(): string { return RbEnums.Common.UserEventSubscriptionType[this.subscriptionType]; }

	// =========================================================================================================================================================
	// Class Methods
	// =========================================================================================================================================================

}

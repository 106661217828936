import { CourseViewArea } from './course-view-area.model';
import { CourseViewHole } from './course-view-hole.model';

export class CourseViewLayout {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.holes) { this.holes = json.holes.map(item => new CourseViewHole(item)); }
			if (json.areas) { this.areas = json.areas.map(item => new CourseViewArea(item, this.getNonGolfHoleIndexesArray())); }
		}
	}

	holes: CourseViewHole[] = [];
	areas: CourseViewArea[] = [];

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getNonGolfHoleIndexesArray(): number[] {
		const nonGolfHoleIndex: number[] = [];

		this.holes.forEach((value, index) => {
			if (!value.isGolfArea) { nonGolfHoleIndex.push(index); }
		});

		return nonGolfHoleIndex;
	}

}

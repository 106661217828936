<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<nav class="top-nav flex items-center justify-between navbar-color__mobile w-100 overflow-hidden
	pv0 ph4 ph3-ns v-top f9 fw9 rb-mw10" [class.modern]="isGolfSite">

	<!-- Hamburger menu Selector	-->
	<div class="tablet-ham-and-logo">
		<div *ngIf="isTablet" class="mobile-menu flex">
			<div class="menu-icon rb-flex-grow-0">
				<button mat-icon-button (click)="hamburgerClick()" class="" icon="menu" data-qaid="header-nav-hamburger">
					<mat-icon>menu</mat-icon>
				</button>
			</div>
		</div>
		<div class="main-nav-logo dn-ns db pv2-m">
			<rb-logo [isGolfSite]="isGolfSite"></rb-logo>
		</div>
	</div>

	<!-- Main Menu items -->
	<div *ngIf="!isTablet" class="main-nav-menu dn-ns flex-m menu-button">
		<span *ngIf="isGolfSite">
			<button *ngIf="sites == null || sites.length === 0"
				mat-button
				class="main-nav-menu-item pl0-m pl3-l"
				[routerLink]="['/']"
				data-qaid="header-nav-courses">{{'STRINGS.COURSE_UPPERCASE' | translate}}
			</button>
			<button *ngIf="sites != null && sites.length === 1"
				mat-button
				class="main-nav-menu-item pl0-m pl3-l"
				[routerLink]="['/systemsetup','courseconfig', 'stations']"
				[queryParams]="{ siteId: singleSiteId }"
				data-qaid="header-nav-courses">{{'STRINGS.COURSE_UPPERCASE' | translate}}
			</button>
			<button *ngIf="sites != null && sites.length > 1"
				mat-button
				class="main-nav-menu-item pl0-m pl3-l"
				[matMenuTriggerFor]="coursesMenu"
				data-qaid="header-nav-courses">{{'STRINGS.COURSE_UPPERCASE' | translate}}
			</button>

			<mat-menu #coursesMenu="matMenu" [overlapTrigger]="false">
				<button *ngFor="let site of sites" mat-menu-item
						[routerLink]="['/systemsetup', 'courseconfig', 'stations']"
						[queryParams]="{ siteId: site.id }"
						attr.data-qaid="{{'header-nav-' + (isGolfSite ? 'courses' : 'sites')}}">
					{{site.name}}
				</button>
			</mat-menu>
		</span>

		<button
			mat-button
			class="main-nav-menu-item pl0-m pl3-l"
			[routerLink]="['./activity', getActivityRoute()]"
			[class.active]="isActivePage('activity')"
			data-qaid="header-nav-activity">{{'STRINGS.ACTIVITY_UPPERCASE' | translate}}
		</button>

		<button
			*ngIf="!isGolfSite"
			mat-button
			class="main-nav-menu-item pl0-m pl3-l"
			[routerLink]="['./systemsetup', 'sites']"
			[class.active]="isActiveSitePage()"
			data-qaid="header-nav-activity">{{'STRINGS.SITES_UPPERCASE' | translate}}
		</button>

		<button
			*ngIf="!isGolfSite"
			mat-button
			class="main-nav-menu-item pl0-m pl3-l"
			[routerLink]="['./controllers','controllers']"
			[class.active]="isActivePage('controllers')"
			data-qaid="header-nav-controllers">{{'STRINGS.CONTROLLERS_UPPERCASE' | translate}}
		</button>

		<button
			*ngIf="!isGolfSite"
			mat-button
			class="main-nav-menu-item pl0-m pl3-l"
			[routerLink]="['./programs','programs']"
			[class.active]="isActivePage('programs')"
			data-qaid="header-nav-programs">{{'STRINGS.PROGRAMS_UPPERCASE' | translate}}
		</button>

		<button
			*ngIf="isGolfSite"
			mat-button
			class="main-nav-menu-item pl0-m pl3-l"
			[routerLink]="['./programs','programgroups']"
			[class.active]="isActivePage('programs')"
			data-qaid="header-nav-programgroups">{{'STRINGS.PROGRAMS_UPPERCASE' | translate}}
		</button>

		<button
			mat-button
			class="main-nav-menu-item pl0-m pl3-l"
			[routerLink]="['./reports','customreports']"
			[class.active]="isActivePage('reports')"
			data-qaid="header-nav-reports"> {{'STRINGS.REPORTS_UPPERCASE' | translate}}</button>

		<button
			mat-button
			class="main-nav-menu-item pl0-m pl3-l"
			[matMenuTriggerFor]="setup"
			[class.active]="isActivePage('systemsetup') && !isActiveSitePage()"
			data-qaid="header-nav-systemsetup">
			{{(isGolfSite ? 'STRINGS.SYSTEM_SETTINGS_UPPERCASE' : 'STRINGS.SYSTEM_SETUP_UPPERCASE') | translate}}
		</button>
		<mat-menu #setup="matMenu" [overlapTrigger]="false" class="qaid-header-subnav-systemsetup">
			<button
				mat-menu-item
				[routerLink]="['./systemsetup', (isGolfSite ? 'courses' : 'sites')]"
				attr.data-qaid="{{'header-nav-' + (isGolfSite ? 'courses' : 'sites')}}">
				{{(isGolfSite ? 'STRINGS.COURSES' : 'STRINGS.SITES') | translate}}
			</button>
			<button *ngIf="isGolfSite"
				mat-menu-item
				[routerLink]="['./systemsetup', 'flo_manager']"
				data-qaid="header-nav-flomanager">{{'STRINGS.FLO_MANAGER' | translate}}
			</button>
			<button *ngIf="isGolfSite"
				mat-menu-item
				[routerLink]="['./systemsetup', 'sensors']"
				data-qaid="header-nav-sensors">{{'STRINGS.SENSORS' | translate}}
			</button>
			<button
				mat-menu-item
				[routerLink]="['./systemsetup', 'weather']"
				data-qaid="header-nav-weather">{{'STRINGS.WEATHER' | translate}}
			</button>
			<button *ngIf="isGolfSite"
				mat-menu-item
				[routerLink]="['./diagnostics']"
				data-qaid="header-nav-diagnostics">{{'STRINGS.DIAGNOSTICS' | translate}}
			</button>
			<button *ngIf="isGolfSite"
				mat-menu-item
				(click)="onFreedomSystemClicked()"
				data-qaid="header-nav-freedom-system">{{'STRINGS.FREEDOM_SYSTEM' | translate}}
			</button>
			<div *ngIf="isAdmin">
				<button
					mat-menu-item
					[routerLink]="['./systemsetup','admin']"
					data-qaid="header-nav-admin">{{'STRINGS.ADMIN' | translate}}
				</button>
			</div>
			<div *ngIf="showLicense">
				<button
					mat-menu-item
					[routerLink]="['./systemsetup','license']"
					data-qaid="header-nav-license">{{'STRINGS.LICENSING' | translate}}
				</button>
			</div>
		</mat-menu>

		<button
			*ngIf="isRBDev"
			mat-button
			[class.active]="isActivePage('playground')"
			class="main-nav-menu-item pl0-m pl3-l"
			[routerLink]="['./playground','basicstyle']"
			data-qaid="header-nav-playground">{{'STRINGS.PLAYGROUND' | translate}}
		</button>

	</div>

	<!-- System Status -->
	<div *ngIf="!isMobile" class="main-nav-icons dn-ns db-m pl0">
		<rb-system-status-toolbar id="rb-system-status-toolbar"></rb-system-status-toolbar>

		<button mat-icon-button id="rb-notes-button">
			<mat-icon><i class="fa fa-bullhorn"></i></mat-icon>
		</button>

		<button mat-icon-button id="rb-alarms-button" [routerLink]="['./activity','alarms']"
			(click)="alarmClick()" *ngIf="!isGolfSite">
			<mat-icon [matBadgeHidden]="unackedAlarmCount < 1"
				matBadge="{{(unackedAlarmCount > 99) ? '99+' : unackedAlarmCount }}"
				matBadgePosition="above after"><i class="rb-icon-font icon-notification"></i>
			</mat-icon>
		</button>

		<button mat-icon-button id="rb-alarms-button" [matMenuTriggerFor]="notificationsMenu" *ngIf="isGolfSite">
			<mat-icon [matBadgeHidden]="unackedAlarmCount + stickyNoteManager.loggedUserUnreadNotesCount.length < 1"
				matBadge="{{(unackedAlarmCount + unreadNotesCount > 99) ? '99+' : unackedAlarmCount + unreadNotesCount }}"
				matBadgePosition="above after"><i class="rb-icon-font icon-notification"></i>
			</mat-icon>
		</button>

		<mat-menu #notificationsMenu="matMenu" class="notifications-menu">
			<button mat-menu-item [routerLink]="['./activity','alarms']" (click)="alarmClick()">
				<i class="mdi mdi-alert-circle-outline text-red"></i>
				<span>{{ 'STRINGS.SYSTEM_ALARMS' | translate }}</span>
				<span class="badge">{{(unackedAlarmCount > 99) ? '99+' : unackedAlarmCount }}</span>
			</button>
			<button mat-menu-item
				*ngIf="!stickyNoteManager.loggedUserUnreadNotesCount.length || stickyNoteManager.loggedUserUnreadNotesCount.length === 1"
				[disabled]="!stickyNoteManager.loggedUserUnreadNotesCount.length"
				(click)="goToNotes(stickyNoteManager.loggedUserUnreadNotesCount[0].siteId)">
				<i class="mdi mdi-message-processing-outline text-blue"></i>
				<span>{{ 'STRINGS.UNREAD_NOTES' | translate }}</span>
				<span class="badge">{{ stickyNoteManager.loggedUserUnreadNotesCount[0]?.count || 0 }}</span>
			</button>
			<ng-container *ngIf="stickyNoteManager.loggedUserUnreadNotesCount.length > 1">
				<div class="divider">
					<span>{{ 'STRINGS.UNREAD_NOTES' | translate }}</span>
				</div>
				<button mat-menu-item *ngFor="let site of stickyNoteManager.loggedUserUnreadNotesCount" (click)="goToNotes(site.siteId)">
					<i class="mdi mdi-message-processing-outline text-blue"></i>
					<span>{{ site.name }}</span>
					<span class="badge">{{ site.count }}</span>
				</button>
			</ng-container>
		</mat-menu>

		<!--Disabled search icon, it is intended for future release-->
		<button *ngIf="false" mat-icon-button class="" data-qaid="header-nav-search">
			<mat-icon>search</mat-icon>
		</button>
		<button mat-icon-button class="" [matMenuTriggerFor]="identity" data-qaid="header-nav-identity">
			<mat-icon class="rb-icon-font icon-profile"></mat-icon>
		</button>
		<mat-menu #identity="matMenu" [overlapTrigger]="false" class="qaid-header-subnav-identity">
			<button mat-menu-item [routerLink]="['./systemsetup', 'profile']"
				data-qaid="header-nav-user-preferences">{{'STRINGS.PROFILE' | translate}}</button>
			<button mat-menu-item [routerLink]="['./systemsetup', 'about']"
				data-qaid="header-nav-about">{{'STRINGS.ABOUT' | translate}}</button>
			<button *ngIf="isGolfSite" mat-menu-item (click)="howToVideosClick()"
				data-qaid="header-nav-how-to-videos">
				{{'STRINGS.HOW_TO_VIDEOS' | translate}}
			</button>
			<button *ngIf="!isGolfSite && authManager.isOwner && authManager.allowSaaS"
				mat-menu-item [routerLink]="['./systemsetup', 'admin', 'subscriptions']">
				<span class="menu-text">
					{{'STRINGS.SUBSCRIPTIONS' | translate}}
					<img src="../../../../assets/crown.svg" class="orange-crown">
				</span>
			</button>
			<button mat-menu-item (click)="logOut()" data-qaid="header-nav-logout">{{'STRINGS.LOG_OUT' | translate}}</button>
		</mat-menu>
	</div>

	<!-- Mobile Menu -->
	<div class="mobile-menu flex-ns dn-l dn-m w-100 pv2 ph0-ns ph2-m">
		<div class="menu-icon rb-flex-grow-0">
			<button mat-icon-button (click)="hamburgerClick()" class="" icon="menu" data-qaid="header-nav-hamburger">
				<mat-icon>menu</mat-icon>
			</button>
		</div>

		<div class="mobile-menu--title rb-flex-grow-3">{{title | translate}}</div>
		<div class="rb-flex-grow-0">
			<!--Disabled search icon, it is intended for future release-->
			<button *ngIf="false" mat-icon-button class="">
				<mat-icon>search</mat-icon>
			</button>

			<button *ngIf="!isGolfSite" mat-icon-button id="rb-notes-button">
				<mat-icon><i class="fa fa-bullhorn"></i></mat-icon>
			</button>

			<button mat-icon-button
				class="mobile-alerts-btn"
				data-qaid="header-nav-alerts"
				[routerLink]="['./activity','alarms']"
				(click)="alarmClick()"
			>
				<mat-icon [matBadgeHidden]="unackedAlarmCount < 1"
					matBadge="{{( unackedAlarmCount > 99) ? '99+' : unackedAlarmCount }}"
					matBadgePosition="above after"><i class="rb-icon-font icon-notification"></i>
				</mat-icon>
			</button>

			<button mat-icon-button [matMenuTriggerFor]="mobileOverflow">
				<mat-icon>more_vert</mat-icon>
			</button>

			<mat-menu #mobileOverflow="matMenu" [overlapTrigger]="false" class="qaid-header-subnav-identity">
				<button mat-menu-item (click)="toggleSystemStatus()">{{'STRINGS.SHOW_HIDE_SYSTEM_STATUS' | translate}}</button>
			</mat-menu>
		</div>
	</div>

</nav>

<ng-template #freedomSystemDialog>
	<rb-freedom-system-dialog (complete)="onFreedomDialogComplete()"></rb-freedom-system-dialog>
</ng-template>

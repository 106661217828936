import { AppInjector } from '../../../core/core.module';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { TranslateService } from '@ngx-translate/core';

export class FirmwareVersion {

	constructor(json: any = null, firmwareType?: RbEnums.Common.FirmwareType) {
		if (json) {
			if (json.item1) this.version = json.item1;
			if (json.item2) this.filePath = json.item2;
		}

		if (firmwareType !== undefined) {
			this.type = firmwareType;

			this.name = FirmwareVersion.getName(this.type);
		}
	}

	type: RbEnums.Common.FirmwareType;
	version: string;
	filePath: string;
	name: string;

	/**
	 * @summary Return the firmware type name associated with the indicated RbEnums.Common.FirmwareType. This
	 * string is translated into the user's preferred language.
	 * @param type RbEnums.Common.FirmwareType value to be converted to string.
	 */
	public static getName(type: RbEnums.Common.FirmwareType): string {
		// Get the translation service.
		const translate = AppInjector.get(TranslateService);

		switch (type) {
			case RbEnums.Common.FirmwareType.Bootloader:
				return translate.instant('FIRMWARE_TYPES.Bootloader');
			case RbEnums.Common.FirmwareType.ModuleBootloader:
				return translate.instant('FIRMWARE_TYPES.ModuleBootloader');
			case RbEnums.Common.FirmwareType.Module2Wire:
				return translate.instant('FIRMWARE_TYPES.Module2Wire');
			case RbEnums.Common.FirmwareType.ModuleSMBus:
				return translate.instant('FIRMWARE_TYPES.ModuleSMBus');
			case RbEnums.Common.FirmwareType.Cic:
				return translate.instant('FIRMWARE_TYPES.Cic');
			case RbEnums.Common.FirmwareType.FSM2:
				return translate.instant('FIRMWARE_TYPES.FSM2');
			case RbEnums.Common.FirmwareType.FSCM2:
				return translate.instant('FIRMWARE_TYPES.FSCM2');
			default:
				return '';
		}
	}

}

import { Pipe, PipeTransform } from '@angular/core';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { StationWithMapInfoLeaflet } from '../../common/models/station-with-map-info-leaflet.model';

/**
 * Returns the station's remaining runtime percentage
 * 
 * This was made a pipe so that this calculation isn't executed on every change detection, but rather
 * every time the runtimeRemaining changes.
 * 
 */
@Pipe({
	name: 'remainingRuntimePercent'
})
export class StationRemainingRuntimePercentPipe implements PipeTransform {

	transform(runTimeRemaining: number, station: StationWithMapInfoLeaflet): unknown {
		if (station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running && station.runTimeRemaining != null && station.runTimeRemaining > 0) {
			return 100 - ((runTimeRemaining / (runTimeRemaining + (station.runTimeSoFar || 0))) * 100)
		} else {
			return 0;
		}

	}

}

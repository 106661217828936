<span *ngIf="!isLocked"[ngClass]="{'station-status-running': isRunning}">
	<span *ngIf="isActivelyCyclingOrSoaking && isCycling" class="station-status-cycling" [matTooltip]="'STRINGS.CYCLING' | translate">
		<i class="icon-water"></i>
	</span>
	<span *ngIf="isActivelyCyclingOrSoaking && isSoaking" class="station-status-soaking" [matTooltip]="'STRINGS.SOAKING' | translate">
		<i class="icon-timer"></i>
	</span>
	{{ status }}
</span>
<span *ngIf="isLocked" [matTooltip]="'STRINGS.LOCKED' | translate">
	<i class="material-icons icon-station-error-style" >lock</i>
</span>

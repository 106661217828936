import { Component, HostBinding, Injector, OnDestroy } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { StationListItem } from '../../../../../api/stations/models/station-list-item.model';

@Component({
	selector: 'rb-learn-flow-station-mobile-cell-renderer',
	templateUrl: './learn-flow-station-mobile-cell-renderer.component.html',
	styleUrls: ['./learn-flow-station-mobile-cell-renderer.component.scss']
})
export class LearnFlowStationMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnDestroy {
	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	station: StationListItem;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnDestroy(): void {}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		super.agInit(params);

		this.station = params.data;
	}

	refresh(params: any): boolean {
		this.station = params.data;
		return true;
	}

}

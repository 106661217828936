import { RbEnums } from "../../../common/enumerations/_rb.enums";
import { RbUtils } from "../../../common/utils/_rb.utils";

export class SiteTreeView {

	constructor(json: any = null, options?: {checkContractorSBUController: boolean}) {
		if (json) {
			Object.assign(this, json);
			if (options?.checkContractorSBUController) {
				this.isDisabled = RbUtils.Controllers.isContractorSBUController(this.type);
			}
			if (json.children) { this.children = json.children.map(c => new SiteTreeView(c, options)); } else { this.children = []; }
		}
	}

	children: SiteTreeView[] = [];
	id: number;
	parentId: number;
	item: string;
	level:  number;
	orderNumber:  number;
	isDeleted: boolean;
	hasSimpleEtProgram: boolean;
	type: RbEnums.Common.DeviceType;
	isDisabled = false;
}

import { forkJoin, Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';
import { FlowElement } from './models/flow-element.model';
import { FlowElementApiService } from './flow-element-api.service';
import { FlowElementChange } from '../signalR/flow-element-change.model';
import { FlowElementTypeListItem } from './models/flow-element-type-list-item.model';
import { GetFlowElementParams } from './models/get-flow-element-params.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class FlowElementManagerService extends ServiceManagerBase {

	/**
	 * flowElementListChange will be fired when one or more flow elements in the system have been changed as in
	 * created, deleted, or updated. This event will be fired when we learn of the change. Clients should request
	 * any items they need from the manager, not setting bypassCache; the manager will handle invalidating any
	 * cache items needing update. The changed items in the list are specified by FlowElementChange[] parameter.
	 * The clients can decide from this if they should reload the object from the API, patch the local object
	 * with the list of changes indicated by a FlowElementChange, or reload the whole list. If the value passed
	 * is null, the clients should reload the whole flow element list, if they need it.
	 */
	flowElementListChange = new Subject<FlowElementChange[]>();

	/**
	 * flowElementsFlowDataChange is fired each time we get a FlowRateUpdate from the back-end. Updates of this
	 * type report all flow elements with non-zero flow rates; all other flow elements have zero flow. Clients
	 * might use this to update UI showing flow element flow rates. Only the latest value of FlowElementChange
	 * should be kept.
	 */
	flowElementsFlowDataChange = new Subject<FlowElementChange>();

	/**
	 * Since we may be receiving FlowElementChanges periodically, rather than immediately on-change, save the last
	 * one, allowing clients to get the last data we had.
	 */
	lastFlowRateUpdate: FlowElementChange = null;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================
	constructor(private flowElementApiService: FlowElementApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);

		this.broadcastService.controllerCollectionChange
		.pipe(untilDestroyed(this))
		.subscribe(() => this.clearCache());

		this.broadcastService.unlockAll
		.pipe(untilDestroyed(this))
		.subscribe(() => {
			this.clearCache();
			this.flowElementListChange.next(null);	// Tell clients that the list is "new".
		});
	}

	/**
	 * Update the indicated list of properties in newValue onto the indicated list of FlowElements in ids.
	 * The overall list of all flow elements is passed in dest.
	 * @param dest - FlowElement[] containing the total company list of FlowElements
	 * @param ids - number[] containing ids to be modified. Each item gets the values indicated in newValue set
	 * @param newValue - any containing the changed properties to set on each item specified in ids
	 */
	static updateSimpleProperty(dest: FlowElement[], ids: number[], newValue: any) {
		for (let j = 0; j < ids.length; j++ ) {
			for (let i = 0; i < dest.length; i++) {
				const element  = this.searchTree(dest[i], ids[j]);
				if (element) {
					this.updateElementProperty(element,  newValue);
				}
			}
		}
	}
	private static updateElementProperty(element: FlowElement,  newValue: any) {
		const keys = Object.keys(newValue);
		keys.map( x => {
			element[x] =  newValue[x];
		});
	}
	static searchTree(element: FlowElement, id:  number) {
		if (element.id === id) {
			return element;
		} else if (element.bConnections != null) {
			let result = null;
			for (let i = 0; result == null && i < element.bConnections.length; i++) {
				result = this.searchTree(element.bConnections[i], id);
				if (result) break;
			}
			return result;
		}
		return null;
	}
	// Returns true if properties were successfully transferred
	static updateSimpleProperties(src: FlowElement[], dest: FlowElement[]): boolean {
		if (dest === null || src === undefined || (src.length !== dest.length)) {
			return false;
		}

		// Sort the arrays to ensure proper order for comparison.
		src.sort((a,b) => a.id - b.id)
		dest.sort((a,b) => a.id - b.id)

		// These are assumed to be sorted in the same way so we should be able to match item for item
		for (let i = 0; i < src.length; i++) {
			// Compare things that must not change
			if (src[i].id !== dest[i].id ||
				src[i].aConnectionId !== dest[i].aConnectionId ||
				src[i].companyId !== dest[i].companyId ||
				src[i].numberOfSubBranches !== dest[i].numberOfSubBranches ||
				src[i].ordinal !== dest[i].ordinal ||
				src[i].isHidden !== dest[i].isHidden ||
				src[i].role !== dest[i].role ||
				src[i].triggerStationId !== dest[i].triggerStationId ||
				(src[i].bConnections != null && dest[i].bConnections != null && src[i].bConnections.length !== dest[i].bConnections.length)) {
				return false;
			}
			dest[i].description = src[i].description;
			dest[i].flowCapacity = src[i].flowCapacity;
			dest[i].name = src[i].name;
			dest[i].currentFlow = src[i].currentFlow;
			dest[i].availableFlow = src[i].availableFlow;
			dest[i].pump = src[i].pump;
			dest[i].source = src[i].source;
			dest[i].stations = src[i].stations;
			if (!this.updateSimpleProperties(src[i].bConnections, dest[i].bConnections)) return false;
		}

		return true;
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
		this.flowElementApiService.clearCache();
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	clearFlowElementCache() { 
		this.clearCache(); 
	}	

	createNewFlowElement(name: string, flowCapacity: number): Observable<null> {
		return this.flowElementApiService.createNewFlowElement(name, flowCapacity);
	}

	createNewFlowElementBranch(flowElementParentId: number, name: string, flowCapacity: number): Observable<null> {
		return this.flowElementApiService.createNewFlowElementBranch(flowElementParentId, name, flowCapacity);
	}
	createBoosterPump(flowElementParentId: number, name: string, flowCapacity: number, stationId: number, role: RbEnums.Common.FlowElementType)
		: Observable<null> {
		return this.flowElementApiService.createBoosterPump(flowElementParentId, name, flowCapacity, stationId, role);
	}
	createFlowElement(flowElementParentId: number, name: string, flowCapacity: number, role: RbEnums.Common.FlowElementType): Observable<null> {
		return this.flowElementApiService.createFlowElement(flowElementParentId, name, flowCapacity, role);
	}
	deleteFlowElement(flowElementIds: number): Observable<void> {
		return this.flowElementApiService.deleteFlowElement(flowElementIds);
	}

	deleteFlowElements(flowElementIds: number[]): Observable<null> {
		const sources: Observable<void>[] = [];
		flowElementIds.forEach(id => sources.push(this.deleteFlowElement(id)));
		return forkJoin(sources).pipe(map(() => null));
	}

	getFlowElement(flowElementId: number, params?: GetFlowElementParams, bypassCache?: boolean): Observable<FlowElement> {
		return this.flowElementApiService.getFlowElement(flowElementId, params, bypassCache === true);
	}

	getFlowElements(parentId?: number, controllerId?: number, includeHiddenFlowZones?: boolean): Observable<FlowElement[]> {
		return this.flowElementApiService.getFlowElements(parentId, controllerId, includeHiddenFlowZones);
	}

	getCompanyFlowElements(siteId?: number, satelliteId?: number, rootOnly?: boolean, bypassCache = false): Observable<FlowElement[]> {
		return this.flowElementApiService.getCompanyFlowElements(bypassCache, siteId, satelliteId, rootOnly).pipe(map(response => response.value));
	}

	areAnyCompanyFlowElementsLocked(): Observable<boolean> {
		return this.flowElementApiService.areAnyCompanyFlowElementsLocked();
	}

	getNextPumpName(): Observable<string> {
		return this.flowElementApiService.getNextPumpName();
	}
	getNextBranchName(): Observable<string> {
		return this.flowElementApiService.getNextBranchName();
	}
	getNextFloZoneName(): Observable<string> {
		return this.flowElementApiService.getNextFloZoneName();
	}
	getCompanyBranches(bypassCache = false): Observable<FlowElement[]> {
		return this.flowElementApiService.getCompanyFlowElements(bypassCache).pipe(map(response => this.getBranches(response.value)));
	}
	getFlowElementFlowZones(): Observable<FlowElement[]> {
		return this.flowElementApiService.getFlowZones();
	}

	getPump(flowElementId: number, queryParams?: GetFlowElementParams): Observable<FlowElement> {
		return this.flowElementApiService.getPump(flowElementId, queryParams);
	}

	getCompanyPumps(bypassCache = false): Observable<FlowElement[]> {
		return this.flowElementApiService.getCompanyFlowElements(bypassCache).pipe(map(response => this.getPumps(response.value)));
		}

	getCompanyFloZones(bypassCache = false): Observable<FlowElement[]> {
		return this.flowElementApiService.getCompanyFlowElements(bypassCache)
			.pipe(map(response => this.getFlowZones(response.value)));
		}

	private getPumps(flowElements: FlowElement[]) {
		return  flowElements.filter( flowElement => flowElement.role === RbEnums.Common.FlowElementType.Pump);
	}
	private getFlowZones(flowElements: FlowElement[]) {
		return flowElements.filter( flowElement => flowElement.role === RbEnums.Common.FlowElementType.FlowZone);
	}
	private getBranches(flowElements: FlowElement[]) {
		return flowElements.filter( flowElement => flowElement.role === RbEnums.Common.FlowElementType.Branch);
	}

	getFlowElementTypes(): Observable<FlowElementTypeListItem[]> {
		return this.flowElementApiService.getFlowElementTypes().pipe(map(response => response.value));
	}

	updateFlowElementRate(flowZoneId: number, flowRate: number): Observable<null> {
		return this.flowElementApiService.updateFlowElementRate(flowZoneId, flowRate);
	}

	updateFlowElements(flowElementIds: number[], updateData: any): Observable<null> {
		return this.flowElementApiService.updateFlowElements(flowElementIds, updateData);
	}
	moveFlowElement(flowElementId: number, toFlowElementId: number): Observable<null> {
		return this.flowElementApiService.moveFlowElement(flowElementId, toFlowElementId);
	}
	getNameUniqueness(type: RbEnums.Common.FlowElementType, name: string, id: number): Observable<UniquenessResponse> {
		return this.flowElementApiService.getNameUniqueness(type, name, id);
	}
	getMinFloZoneCapacity(stationIds: number[]): Observable<number> {
		return this.flowElementApiService.getMinFloZoneCapacity(stationIds);
	}

	/**
	 * Handle SignalR or other notifications involving flow element changes. We can handle marking the cache invalid or
	 * updating the cached items and provide external notifications for any interested parties.
	 * @param changes - FlowElementChange[] containing the changes arriving from SignalR.
	 */
	statusChange(changes: FlowElementChange[]) {
		// If we have a created operation, invalidate the flow element list. If we have an updated operation, update the
		// corresponding objects already in the cache, if found. If we have a deleted operation, remove the corresponding
		// item from the cache and invalidate the list.
		let listChanged = false;
		const listChanges: FlowElementChange[] = [];
		changes.forEach(c => {
			switch (c.changeType) {
				case RbEnums.SignalR.FlowElementChangeType.Added:
				case RbEnums.SignalR.FlowElementChangeType.Deleted:
				case RbEnums.SignalR.FlowElementChangeType.Updated:
					listChanged = true;
					listChanges.push(c);
					break;
				case RbEnums.SignalR.FlowElementChangeType.FlowRateUpdate:
					// Save the last change received.
					this.lastFlowRateUpdate = c;

					// Note that although we fire these as we see them, our clients should only keep the latest
					// as we're doing above. It has the most-current data for everything with non-zero flow.
					this.flowElementsFlowDataChange.next(c);
					break;
			}
		});

		// Notify any clients that there has been a change.
		if (listChanged) {
			this.clearCache();

			this.flowElementListChange.next(listChanges);
		}
	}
}

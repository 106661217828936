import { RbUtils } from '../../common/utils/_rb.utils';

export class ChangeBase {

	constructor(json: any = null) {

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);

			// Handle any custom mappings here
			this.changeDateTime = (json.changeDateTime instanceof String) ? RbUtils.Conversion.convertStringToDate(json.changeDateTime) : json.changeDateTime;
		}
	}

	changeDateTime: Date;
	companyId: number;
	errorMessage: string;
	jobPhase: string;
}

import * as moment from 'moment';

import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { CreateUserData } from './models/create-user.model';
import { GroupLevel } from './models/group-level.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PasswordChange } from './models/password-change.model';
import { RbccUser } from './models/rbcc-user.model';
import { RbUtils } from '../../common/utils/_rb.utils';
import { Snapshot } from '../controllers/models/snapshot';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';
import { UserEventSubscription } from './models/user-event-subscription.model';
import { UserEventSubscriptionType } from './models/user-event-subscription-type.model';
import { UserInfo } from './models/rbcc-user-info.model';
import { UserListItem } from './models/user-list-item.model';
import { UserSite } from '../sites/models/user-site.model';
import { UserSnapshotListItem } from './models/user-snapshot-list-item.model';
import { UserSummary } from './models/user-summary.model';

@Injectable({
	providedIn: 'root'
})
export class UserApiService extends ApiService {

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================
	clearCache() {
		super.clearCache(this.getGroupLevelsUrl);
		super.clearCache(this.getVisibleUsersUrl);
		super.clearCache(this.getUserEventSubscriptionTypesUrl);
	}

	createNewUser(user: RbccUser, userSites: UserSite[]) {
		const siteIds = userSites.reduce((list, userSite) => `${list ? list + '&' : ''}siteIds=${userSite.siteId}`, '');
		return this.apiRequest<any>(this.createUserUrl(siteIds), HttpMethod.Post, {
			name: user.name,
			email: user.email,
			Password: user.Password,
			groupLevel: user.groupLevel,
			ChallengeAnswer: user.ChallengeAnswer,
			companyId: user.companyId,
			cultureSettingsId: user.cultureSettingsId,
			timeZone: user.timeZone,
		});
	}

	createIQ4NewUser(user: RbccUser, userSites: UserSite[] = []) {
		const siteIds = userSites.map(userSite => userSite.siteId);
		const userData = new RbccUser({
			name: user.name,
			email: user.email,
			Password: user.Password,
			groupLevel: user.groupLevel,
			ChallengeAnswer: user.ChallengeAnswer,
			companyId: user.companyId,
			cultureSettingsId: user.cultureSettingsId,
			timeZone: user.timeZone,
		});
		return this.apiRequest<CreateUserData>(this.createIQ4UserUrl(), HttpMethod.Post, {
			siteIds,
			userData
		});
	}

	deleteUsers(userIds: number[]): Observable<void> {
		return this.apiRequest(this.deleteUsersUrl(userIds), HttpMethod.Delete);
	}

	getEmailUniqueness(email: string, id: number): Observable<UniquenessResponse> {
		return this.apiRequest<any>(this.getEmailUniquenessUrl(), HttpMethod.Post, {
			UserEmail: email,
			UserId: id
		}).pipe(map(response => new UniquenessResponse(response.value)));
	}

	getGroupLevels(): Observable<ApiCachedRequestResponse<GroupLevel[]>> {
		return this.apiRequestWithCache<any>(this.getGroupLevelsUrl, false, result => result.map(c => new GroupLevel(c)), Number.MAX_SAFE_INTEGER);
	}

	getNameUniqueness(name: string, id: number): Observable<UniquenessResponse> {
		return this.apiRequest<any>(this.getNameUniquenessUrl(), HttpMethod.Post, {
			userName: name,
			userId: id
		}).pipe(map(response => new UniquenessResponse(response.value)));
	}

	getUser(userId: number): Observable<RbccUser> {
		return this.apiRequest<any>(this.getUserUrl(userId), HttpMethod.Get)
			.pipe(map(user => new RbccUser(user)));
	}

	getUserDateTime(dateTime: Date): Observable<Date> {
		return this.apiRequest<any>(this.getUserDateUrl(dateTime), HttpMethod.Get)
			.pipe(map(userDate => moment(userDate).toDate()));
	}
	getUserCurrentDateTime(): Observable<Date> {
		return this.apiRequest<any>(this.getUserCurrentDateTimeUrl(), HttpMethod.Get)
			.pipe(map(userDate => 
				moment(userDate.value).toDate()
			));
	}
	getUserUtcOffset(dateTime: Date): Observable<string> {
		return this.apiRequest<any>(this.getUserUtcOffsetUrl(dateTime), HttpMethod.Get);
	}

	getCurrentUserPasswordExpirationDate(bypassCache = false): Observable<ApiCachedRequestResponse<Date>> {
		return this.apiRequestWithCache<any>(this.getCurrentUserPasswordExpirationDateUrl(), bypassCache, result => {
			return result ? moment(result).toDate() : null;
		}
		);
	}

	getCodeToResetPassword(url: string): Observable<string> {
		return this.apiRequest<any>(this.getCodeToResetPasswordUrl(url), HttpMethod.Get).pipe(map(x => x?.code));
	}

	getVisibleUsers(bypassCache = false): Observable<ApiCachedRequestResponse<UserListItem[]>> {
		return this.apiRequestWithCache<any>(this.getVisibleUsersUrl, bypassCache, result => result.map(c => new UserListItem(c)));
	}

	getUsersIncludingDeleted(): Observable<UserSnapshotListItem[]> {
		return this.apiRequest<any>(this.getUsersIncludingDeletedUrl, HttpMethod.Get)
			.pipe(map(users => users.map(user => new UserSnapshotListItem(user))));
	}

	getUsers(): Observable<UserListItem[]> {
		return this.apiRequest<any>(this.getUsersUrl, HttpMethod.Get)
			.pipe(map(users => users.map(user => new UserListItem(user))));
	}

	getUserInfoBySite(siteId: number): Observable<UserInfo[]> {
		return this.apiRequest<any>(this.getUserInfoBySiteUrl(siteId), HttpMethod.Get)
			.pipe(map(users => {
				const rbccUsers: UserInfo[] = []
				for (const user of users) {
					rbccUsers.push(new UserInfo(user))
				}
				return rbccUsers
			}));
	}

	setNewOwner(userId: number): Observable<void> {
		return this.apiRequest<any>(this.setNewOwnerUrl(userId), HttpMethod.Put);
	}

	updatePassword(passwordChange: PasswordChange): Observable<void> {
		return this.apiRequest<any>(this.updatePasswordUrl, HttpMethod.Put, passwordChange);
	}

	updateUser(userId: number, updateValues: any): Observable<null> {
		return this.apiRequest<any>(this.updateUserUrl(userId), HttpMethod.Patch,
			this.patchTransform(updateValues));
	}

	/// <summary>
	/// Get the UserEventSubscriptionType enum values for subscriptions to email/SMS notifications.
	/// </summary>
	/// <returns></returns>
	getUserEventSubscriptionTypes(): Observable<ApiCachedRequestResponse<UserEventSubscriptionType[]>> {
		return this.apiRequestWithCache<any>(this.getUserEventSubscriptionTypesUrl, false,
				result => result.map(c => new UserEventSubscriptionType(c)), Number.MAX_SAFE_INTEGER);
	}

	/**
	 * @description GetUserEventSubscriptions returns configured subscriptions for a given user. If the current user is not at least
	 * the same group level as the specified userId, the list will be empty.
	 * @param userId ID of the target user, or null to use the current logged-in user.
	 */
	getUserEventSubscriptions(userId?: number, bypassCache?: boolean): Observable<UserEventSubscription[]> {
		return this.apiRequest<any>(this.getUserEventSubscriptionsUrl(userId), HttpMethod.Get, null, null, bypassCache)
			.pipe(map(subscriptions => {
				return subscriptions.map(s => new UserEventSubscription(s));
			})
			);
	}

	/**
	 * @description updateAllUserEventSubscriptions completely replaces the indicated user's event subscriptions.
	 * @param subscriptions UserEventSubscription[] containing the list of alarm types and subscription types corresponding
	 * to them (None, Email, TextMessage, Both).
	 * @param (optional) userId User ID for update to be updated or null to update current logged-in user.
	 */
	updateAllUserEventSubscriptions(subscriptions: UserEventSubscription[], userId: number = null): Observable<any> {
		// Note that we're passing the list of events in the body!
		return this.apiRequest<any>(this.updateAllUserEventSubscriptionsUrl(subscriptions, userId), HttpMethod.Put,
			subscriptions);
	}

	updateUserPreferences(userId: number, userPreference: any): Observable<any> {
		return this.apiRequest<any>(this.updateUserPreferencesUrl, HttpMethod.Patch,
			{
				ids: [userId],
				patch: this.patchTransform(userPreference),
			});
	}

	getSnapshots(userId: number): Observable<Snapshot[]> {
		return this.apiRequest<any>(this.getSnapshotsUrl(userId), HttpMethod.Get)
			.pipe(map(items => items.map(item => new Snapshot(item))));
	}

	restoreSnapshot(userId: number, date: Date, forceRestore: boolean): Observable<null> {
		return this.apiRequest<any>(this.restoreSnapshotUrl, HttpMethod.Put, { userId, date, forceRestore });
	}

	getUsersSummary(): Observable<UserSummary[]> {
		return this.apiRequest<any>(this.getUsersSummaryUrl, HttpMethod.Get)
			.pipe(map(items => items.map(item => new UserSummary(item))));
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}User`; }

	private createUserUrl(siteIds: string): string { return `${this.baseUrl}/CreateUser?${siteIds}`; }

	private createIQ4UserUrl(): string { return `${this.baseUrl}/CreateIQ4User`; }

	private deleteUsersUrl(userIds: number[]): string {
		let queryString = '';
		userIds.forEach(id => {
			if (queryString.length > 0) { queryString += '&'; }
			queryString += `userIds=${id}`;
		});
		return `${this.baseUrl}/DeleteUsers?${queryString}`;
	}

	private getEmailUniquenessUrl(): string { return `${this.baseUrl}/IsEmailUnique`; }

	private get getGroupLevelsUrl(): string { return `${this.baseUrl}/GetGroupLevels`; }

	private getNameUniquenessUrl(): string { return `${this.baseUrl}/IsNameUnique`; }

	private getUserUrl(userId: number): string { return `${this.baseUrl}/GetUser?userId=${userId}&IncludeCompany=true`; }

	private getUserInfoBySiteUrl(siteId: number): string {
		return `${this.baseUrl}/GetUserInfoBySite?siteId=${siteId}`;
	}

	private getUserDateUrl(date: Date): string {
		const dateUTC = RbUtils.Conversion.convertDateToUTCDateTimeParameterString(date);
		return `${this.baseUrl}/GetUserDateTime?dateTimeUTC=${dateUTC}`;
	}

	private getUserCurrentDateTimeUrl(): string {
		return `${this.baseUrl}/GetUserCurrentDateTime`;
	}

	private getUserUtcOffsetUrl(date: Date): string {
		const dateUTC = RbUtils.Conversion.convertDateToUTCDateTimeParameterString(date);
		return `${this.baseUrl}/GetUserUtcOffset?dateTimeUTC=${dateUTC}`;
	}

	private get getVisibleUsersUrl(): string { return `${this.baseUrl}/GetVisibleUsers`; }

	private get getUsersIncludingDeletedUrl(): string { return `${this.baseUrl}/GetUsersIncludingDeleted`; }

	private get getUsersUrl(): string { return `${this.baseUrl}/GetUsers?IncludeCompany=true`; }

	private setNewOwnerUrl(userId: number): string { return `${this.baseUrl}/SetNewOwner?userId=${userId}`; }

	private get updatePasswordUrl(): string { return `${this.baseUrl}/UpdatePassword?currentPassword`; }

	private updateUserUrl(id: number): string { return `${this.baseUrl}/Update?userId=${id}`; }

	private get updateUserPreferencesUrl(): string { return `${this.baseUrl}/v2/UpdateUserPreference`; }

	private get getUserEventSubscriptionTypesUrl(): string { return `${this.baseUrl}/GetUserEventSubscriptionTypes`; }

	private getUserEventSubscriptionsUrl(userId?: number): string { return `${this.baseUrl}/GetUserEventSubscriptions?userId=${userId}`; }

	private getCurrentUserPasswordExpirationDateUrl(): string { return `${this.baseUrl}/GetCurrentUserPasswordExpirationDate`; }

	private getCodeToResetPasswordUrl(url: string): string { return `${this.baseUrl}/GetCodeToResetPassword?url=${url}`; }

	private updateAllUserEventSubscriptionsUrl(subscriptions: UserEventSubscription[],
		userId: number = null): string { return `${this.baseUrl}/UpdateAllUserEventSubscriptions?userId=${userId}`; }

	private getSnapshotsUrl(userId: number): string { return `${this.baseUrl}/GetSnapshots?userId=${userId}`; }
	private get restoreSnapshotUrl(): string { return `${this.baseUrl}/RestoreSnapshot`; }

	private get getUsersSummaryUrl(): string { return `${this.baseUrl}/getUsersSummary`}
}

<div class="rb-dashboard-widget-toolbar">
	<div class="main-area">
		<div *ngIf="titleIcon" class="title-icon">
			<ng-container *ngIf="isTitleIconTemplate" [ngTemplateOutlet]="titleIcon"></ng-container>
			<div *ngIf="isTitleIconString">{{titleIcon}}a</div>
		</div>

		<div class="complex-title-container">
			<div class="complex-title">
				<div *ngIf="title" class="title" [ngClass]="{'wrap-title': wrapTitle}">{{title}}<div *ngIf="subTitle" class="sub-title-sep"> - </div></div>
				<div *ngIf="subTitle" class="subTitle">{{subTitle}}</div>
			</div>

			<div *ngIf="summaryValue" class="summaryValue">{{summaryValue}}</div>

			<ng-container *ngIf="toolbar" [ngTemplateOutlet]="toolbar"></ng-container>
		</div>
	</div>

	<div class="dropdown-menu widget-non-draggable">
		<button *ngIf="withRefresh && !showSettings" mat-icon-button>
			<mat-icon class="mat-icon-refresh" [svgIcon]="'refresh_small'" (click)="onWidgetRefresh()"></mat-icon>
		</button>
		<button mat-icon-button [matMenuTriggerFor]="moreMenu">
			<mat-icon class="mat-icon-color">more_vert</mat-icon>
		</button>

		<mat-menu #moreMenu="matMenu" [overlapTrigger]="false" class="widget-dropdown-menu">
			<div *ngIf="customDropDownOptions">
				<div *ngFor="let option of customDropDownOptions">
					<button mat-menu-item *ngIf="option.show" (click)="option.click()">{{option.text | translate}}</button>
				</div>
			</div>
			<button *ngIf="withSettings" mat-menu-item (click)="onWidgetSettings()">{{'STRINGS.SETTINGS' | translate}}</button>
			<button *ngIf='!hideRemoveWidget' mat-menu-item (click)="onRemoveWidget()">{{'STRINGS.REMOVE_WIDGET' | translate}}</button>
		</mat-menu>
	</div>
</div>

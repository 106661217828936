import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { Area } from '../../../../../api/areas/models/area.model';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';

@Component({
	selector: 'rb-holes-mobile-cell-renderer',
	templateUrl: './holes-mobile-cell-renderer.component.html',
	styleUrls: ['./holes-mobile-cell-renderer.component.scss']
})
export class HolesMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	hole: Area;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.hole = params.data;
	}
}

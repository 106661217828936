<!--suppress TypeScriptUnresolvedVariable, AngularUndefinedBinding -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<mat-dialog-content>
	<form data-qaid="duration-form" #form="ngForm">
		<div class="header" *ngIf="showHeader" >
			<p class="pl3 pt3 f5 mr0 mv0">{{title}}</p>
			<div [ngClass]="{'rb-w-6-rem' : hideHours, 'rb-w-10-rem' : !hideHours}" class="duration-header-hours f2">
				<input *ngIf="!hideDays" class="headerHours" matInput name="headerDays" [ngModel]="days" readonly>
				<span matSuffix *ngIf="!hideDays || hideHours">:</span>
				<input *ngIf="!hideHours" class="headerHours" matInput name="headerHours" [ngModel]="padNumber(hours)" readonly>
				<span matSuffix *ngIf="!hideHours || hideMinutes">:</span>
				<input *ngIf="!hideMinutes" matInput name="headerMinutes" [ngModel]="padNumber(minutes)" readonly>
				<span matSuffix *ngIf="!hideSeconds">:</span>
				<input *ngIf="!hideSeconds" matInput name="headerSeconds" [ngModel]="padNumber(seconds)" readonly>
			</div>
		</div>

		<div *ngIf="showSlider" class="no-select">
			<!-- Days -->
			<div *ngIf="!hideDays" class="duration-section">
				<div class="label">{{'STRINGS.DAYS' | translate}} </div>
				<div class="duration-group">
					<!-- Field Tech User -->
					<button *ngIf="isReadOnly" [disabled]="days === RbConstants.Form.DURATION_DAYS.min || isReadOnly === true"
						(mousedown)="onButtonMouseDown('days', -1, -1, onDaysChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('days', -1, -1, onDaysChanged)"  (touchend)="onTouchEnd()">
						<i class="rb-icon icon-minus"></i>
					</button>
					<div *ngIf="isReadOnly" class="input-holder">
						<input class="hide-spinner" [placeholder]="'STRINGS.DAY' | translate" [min]="0" [max]="maxDays" name="daysInput" type="number"
							[disabled]="days === RbConstants.Form.DURATION_DAYS.min && days === maxDays"
							[ngModel]="daysString" (ngModelChange)="onDaysChanged($event, '')"
							onkeypress='return event.charCode >= 48 && event.charCode <= 57'
							(focus)="$any($event.target).select()" [@scrollNumber]="daysScrollState" (@scrollNumber.done)="scrollDone()" />
					</div>
					<button *ngIf="isReadOnly" [disabled]="days === maxDays || isMaxDays || isReadOnly === true"
						(mousedown)="onButtonMouseDown('days', 1, 1, onDaysChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('days', 1, 1, onDaysChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-plus"></i>
					</button>
					<!-- Normal User -->
					<button *ngIf="!isReadOnly" [disabled]="days === RbConstants.Form.DURATION_DAYS.min"
						(mousedown)="onButtonMouseDown('days', -1, -1, onDaysChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('days', -1, -1, onDaysChanged)"  (touchend)="onTouchEnd()">
						<i class="rb-icon icon-minus"></i>
					</button>
					<div *ngIf="!isReadOnly" class="input-holder-hrs">
						<input class="hide-spinner" [placeholder]="'STRINGS.DAY' | translate" [min]="0" [max]="maxDays" name="daysInput" type="number"
							[disabled]="days === RbConstants.Form.DURATION_DAYS.min && days === maxDays"
							[ngModel]="daysString" (ngModelChange)="onDaysChanged($event, '')"
							onkeypress='return event.charCode >= 48 && event.charCode <= 57'
							(focus)="$any($event.target).select()" [@scrollNumber]="daysScrollState" (@scrollNumber.done)="scrollDone()" />
					</div>
					<button *ngIf="!isReadOnly" [disabled]="days === maxDays || isMaxDays"
						(mousedown)="onButtonMouseDown('days', 1, 1, onDaysChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('days', 1, 1, onDaysChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-plus"></i>
					</button>
				</div>
			</div>

			<!-- Hours -->
			<div *ngIf="!hideHours" class="duration-section">
				<div class="label">{{'STRINGS.HOURS' | translate}} </div>
				<div class="duration-group">
					<!-- Field Tech User -->
					<button *ngIf="isReadOnly" [disabled]="hours === RbConstants.Form.DURATION_HOURS.min || isReadOnly === true"
						(mousedown)="onButtonMouseDown('hours', -1, -1, onHoursChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('hours', -1, -1, onHoursChanged)"  (touchend)="onTouchEnd()">
						<i class="rb-icon icon-minus"></i>
					</button>
					<div *ngIf="isReadOnly" class="input-holder">
						<input class="hide-spinner" [placeholder]="'STRINGS.HR' | translate" [min]="0" [max]="maxHours" name="hoursInput" type="number"
							[disabled]="hours === RbConstants.Form.DURATION_HOURS.min && hours === maxHours"
							[ngModel]="hoursString" (ngModelChange)="onHoursChanged($event, '')"
							onkeypress='return event.charCode >= 48 && event.charCode <= 57'
							(focus)="$any($event.target).select()" [@scrollNumber]="hoursScrollState" (@scrollNumber.done)="scrollDone()" />
					</div>
					<button *ngIf="isReadOnly" [disabled]="isMaxHours || isReadOnly === true"
						(mousedown)="onButtonMouseDown('hours', 1, 1, onHoursChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('hours', 1, 1, onHoursChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-plus"></i>
					</button>
					<!-- Normal User -->
					<button *ngIf="!isReadOnly" [disabled]="hours === RbConstants.Form.DURATION_HOURS.min"
						(mousedown)="onButtonMouseDown('hours', -1, -1, onHoursChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('hours', -1, -1, onHoursChanged)"  (touchend)="onTouchEnd()">
						<i class="rb-icon icon-minus"></i>
					</button>
					<div *ngIf="!isReadOnly" class="input-holder-hrs">
						<input class="hide-spinner" [placeholder]="'STRINGS.HR' | translate" [min]="0" [max]="maxHours" name="hoursInput" type="number"
							[disabled]="hours === RbConstants.Form.DURATION_HOURS.min && hours === maxHours"
							[ngModel]="hoursString" (ngModelChange)="onHoursChanged($event, '')"
							onkeypress='return event.charCode >= 48 && event.charCode <= 57'
							(focus)="$any($event.target).select()" [@scrollNumber]="hoursScrollState" (@scrollNumber.done)="scrollDone()" />
					</div>
					<button *ngIf="!isReadOnly" [disabled]="isMaxHours"
						(mousedown)="onButtonMouseDown('hours', 1, 1, onHoursChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('hours', 1, 1, onHoursChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-plus"></i>
					</button>
				</div>
			</div>

			<!-- Minutes -->
			<div *ngIf="!hideMinutes" class="duration-section">
				<div class="label">{{'STRINGS.MINUTES' | translate}} </div>
				<div class="duration-group">
					<!-- Field Tech User -->
					<button *ngIf="isReadOnly" [disabled]="minutes === RbConstants.Form.DURATION_MINUTES.min || isReadOnly === true"
						(mousedown)="onButtonMouseDown('minutes', -1, -1, onMinutesChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('minutes', -1, -1, onMinutesChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-minus"></i>
					</button>
					<div *ngIf="isReadOnly" class="input-holder">
						<input class="hide-spinner" [min]="RbConstants.Form.DURATION_MINUTES.min" [max]="maxMinutes" name="minutesInput" type="number"
							[disabled]="minutes === RbConstants.Form.DURATION_MINUTES.min && ((!hideHours && (hours === maxHours)) || minutes === maxMinutes)"
							[ngModel]="minutesString" (ngModelChange)="onMinutesChanged($event, '')"
							onkeypress='return event.charCode >= 48 && event.charCode <= 57'
							(focus)="$any($event.target).select()" [@scrollNumber]="minutesScrollState" (@scrollNumber.done)="scrollDone()" />
					</div>
					<button *ngIf="isReadOnly" [disabled]="isMaxMinutes || isReadOnly === true"
						(mousedown)="onButtonMouseDown('minutes', 1, 1, onMinutesChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('minutes', 1, 1, onMinutesChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-plus"></i>
					</button>
					<!-- Normal User -->
					<button *ngIf="!isReadOnly" [disabled]="minutes === RbConstants.Form.DURATION_MINUTES.min"
						(mousedown)="onButtonMouseDown('minutes', -1, -1, onMinutesChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('minutes', -1, -1, onMinutesChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-minus"></i>
					</button>
					<div *ngIf="!isReadOnly" class="input-holder-hrs">
						<input class="hide-spinner" [min]="RbConstants.Form.DURATION_MINUTES.min" [max]="maxMinutes" name="minutesInput" type="number"
							[disabled]="minutes === RbConstants.Form.DURATION_MINUTES.min && ((!hideHours && (hours === maxHours)) || minutes === maxMinutes)"
							[ngModel]="minutesString" (ngModelChange)="onMinutesChanged($event, '')"
							onkeypress='return event.charCode >= 48 && event.charCode <= 57'
							(focus)="$any($event.target).select()" [@scrollNumber]="minutesScrollState" (@scrollNumber.done)="scrollDone()" />
					</div>
					<button *ngIf="!isReadOnly" [disabled]="isMaxMinutes"
						(mousedown)="onButtonMouseDown('minutes', 1, 1, onMinutesChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('minutes', 1, 1, onMinutesChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-plus"></i>
					</button>
				</div>
			</div>

			<!-- Seconds -->
			<div *ngIf="!hideSeconds" class="duration-section">
				<div class="label">{{'STRINGS.SECONDS' | translate}} </div>
				<div class="duration-group">
					<!-- Field Tech User -->
					<button *ngIf="isReadOnly" [disabled]="seconds === RbConstants.Form.DURATION_SECONDS.min || isReadOnly === true"
						(mousedown)="onButtonMouseDown('seconds', -1, -1, onSecondsChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('seconds', -1, -1, onSecondsChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-minus"></i>
					</button>
					<div *ngIf="isReadOnly" class="input-holder">
						<input class="hide-spinner" [min]="RbConstants.Form.DURATION_SECONDS.min" [max]="maxSeconds" name="secondsInput" type="number"
							[disabled]="seconds === RbConstants.Form.DURATION_SECONDS.min && (hours === maxHours || (minutes === maxMinutes && hideHours) || seconds === RbConstants.Form.DURATION_SECONDS.max)"
							[ngModel]="secondsString" (ngModelChange)="onSecondsChanged($event, '')"
							onkeypress='return event.charCode >= 48 && event.charCode <= 57'
							(focus)="$any($event.target).select()" [@scrollNumber]="secondsScrollState" (@scrollNumber.done)="scrollDone()" />
					</div>
					<button *ngIf="isReadOnly" [disabled]="isMaxSeconds || isReadOnly === true"
						(mousedown)="onButtonMouseDown('seconds', 1, 1, onSecondsChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('seconds', 1, 1, onSecondsChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-plus"></i>
					</button>
					<!-- Normal User -->
					<button *ngIf="!isReadOnly" [disabled]="seconds === RbConstants.Form.DURATION_SECONDS.min"
						(mousedown)="onButtonMouseDown('seconds', -1, -1, onSecondsChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('seconds', -1, -1, onSecondsChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-minus"></i>
					</button>
					<div *ngIf="!isReadOnly" class="input-holder-hrs">
						<input class="hide-spinner" [min]="RbConstants.Form.DURATION_SECONDS.min" [max]="maxSeconds" name="secondsInput" type="number"
							[disabled]="seconds === RbConstants.Form.DURATION_SECONDS.min && (hours === maxHours || (minutes === maxMinutes && hideHours) || seconds === RbConstants.Form.DURATION_SECONDS.max)"
							[ngModel]="secondsString" (ngModelChange)="onSecondsChanged($event, '')"
							onkeypress='return event.charCode >= 48 && event.charCode <= 57'
							(focus)="$any($event.target).select()" [@scrollNumber]="secondsScrollState" (@scrollNumber.done)="scrollDone()" />
					</div>
					<button *ngIf="!isReadOnly" [disabled]="isMaxSeconds"
						(mousedown)="onButtonMouseDown('seconds', 1, 1, onSecondsChanged)" (mouseup)="onButtonMouseUp()"
						(touchstart)="onTouchDown('seconds', 1, 1, onSecondsChanged)" (touchend)="onTouchEnd()">
						<i class="rb-icon icon-plus"></i>
					</button>
				</div>
			</div>
		</div>

		<div *ngIf="!showSlider" class="textWidth hr-min-sec pt3 mr4">
			<div class="hrs">
				<!-- Field Tech User -->
				<mat-form-field *ngIf="isReadOnly">
					<input matInput [placeholder]="'STRINGS.HR' | translate" [min]="0" [max]="maxHours" name="hoursInput" type="number"
						[ngModel]="hours" (ngModelChange)="onHoursChanged($event, '')"
						onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						(focus)="$any($event.target).select()"/>
				</mat-form-field>
				<!-- Normal User -->
				<mat-form-field *ngIf="!isReadOnly" class="hrs-input">
					<input matInput [placeholder]="'STRINGS.HR' | translate" [min]="0" [max]="maxHours" name="hoursInput" type="number"
						[ngModel]="hours" (ngModelChange)="onHoursChanged($event, '')"
						onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						(focus)="$any($event.target).select()"/>
				</mat-form-field>
			</div>
			<div class="hrs">
				<!-- Field Tech User -->
				<mat-form-field *ngIf="isReadOnly">
					<input matInput [placeholder]="'STRINGS.MIN' | translate" [min]="0" [max]="maxMinutes" name="minutesInput" type="number"
						[ngModel]="minutes" (ngModelChange)="onMinutesChanged($event, '')" [disabled]="hours === maxHours"
						onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						(focus)="$any($event.target).select()"/>
				</mat-form-field>
				<!-- Normal User -->
				<mat-form-field *ngIf="!isReadOnly" class="hrs-input">
					<input matInput [placeholder]="'STRINGS.MIN' | translate" [min]="0" [max]="maxMinutes" name="minutesInput" type="number"
						[ngModel]="minutes" (ngModelChange)="onMinutesChanged($event, '')" [disabled]="hours === maxHours"
						onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						(focus)="$any($event.target).select()"/>
				</mat-form-field>
			</div>
			<div class="hrs">
				<!-- Field Tech User -->
				<mat-form-field *ngIf="isReadOnly">
					<input matInput [placeholder]="'STRINGS.SEC' | translate" name="secondsInput" type="number"
						[disabled]="hours === maxHours || (minutes === maxMinutes && hideHours)"
						[(ngModel)]="seconds" (ngModelChange)="onSecondsChanged($event,'')"
						[min]="RbConstants.Form.DURATION_SECONDS.min" [max]="RbConstants.Form.DURATION_SECONDS.max"
						onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						(focus)="$any($event.target).select()"/>
				</mat-form-field>
				<!--Normal User -->
				<mat-form-field *ngIf="!isReadOnly" class="hrs-input">
					<input matInput [placeholder]="'STRINGS.SEC' | translate" name="secondsInput" type="number"
						[disabled]="hours === maxHours || (minutes === maxMinutes && hideHours)"
						[(ngModel)]="seconds" (ngModelChange)="onSecondsChanged($event,'')"
						[min]="RbConstants.Form.DURATION_SECONDS.min" [max]="RbConstants.Form.DURATION_SECONDS.max"
						onkeypress='return event.charCode >= 48 && event.charCode <= 57'
						(focus)="$any($event.target).select()"/>
				</mat-form-field>
			</div>
		</div>
	</form>
</mat-dialog-content>

<div *ngIf="showButtons">
	<mat-dialog-actions class="flex justify-end">
		<button mat-button (click)="cancel()" data-qaid="dialog-cancel-button">{{'STRINGS.CANCEL' | translate }}</button>
		<button mat-button (click)="submit()" color="primary" data-qaid="dialog-submit-button" [disabled]="!form.valid">{{'STRINGS.OK' | translate }}</button>
	</mat-dialog-actions>
</div>


<div class="rb-active-flow-chart-container">

	<div class="drc-pane-header">
		<div class="drc-chart-title">
			{{ chartTitle }}
		</div>
		<div id="drc-flow-summary">
			<div id="drc-flow-icon"><i class="icon-flow"></i></div>
			<div>{{ totalFlowHeader }}</div>
		</div>
	</div>

	<div class="drc-pane-body">
		<div class="drc-pane-body-static-container">
			<div id="drc-chart-container">
				<highcharts-chart
					*ngIf="showChart"
					[Highcharts]="Highcharts"
					[options]="chartOptions"
					[callbackFunction]="chartCallback.bind(this)"
					(chartInstance)='onChartInstance($event)'
					(mousemove)="onMouseMove($event)"
				></highcharts-chart>
			</div>

			<div id="drc-chart-datum-info">
				<div id="drc-time-n-rate">
					<div id="drc-flow-datum-date">
						<span class="drc-tnr-lbl">{{ 'STRINGS.DATE' | translate }}:</span><span
						class="drc-date-val">{{currentFlowDataDate}}</span>
					</div>
					<div id="drc-flow-datum-time">
						<span class="drc-tnr-lbl">{{ 'STRINGS.TIME' | translate }}:</span><span
						class="drc-time-val">{{currentFlowDataTime}}</span>
					</div>
					<div id="drc-flow-datum-rate">
						<span class="drc-tnr-lbl">{{ 'STRINGS.RATE' | translate }} ({{flowRateLabel}}):</span><span
						class="drc-rate-val">{{currentFlowDataFlowRate}}</span>
					</div>
				</div>
			</div>
		</div>

		<div id="drc-chart-details-container">

			<!-- ACTIVE PUMPS-->
			<div class="drc-details-section">
				<div
					class="drc-details-section-header">{{'STRINGS.ACTIVE_PUMPS' | translate}}</div>
				<div class="drc-details-section-scroll-container">
					<div class="drc-details-section-body" id="drc-programs-list">
						<div *ngFor="let item of currentActivePumps" class="drc-active-station">
							<div class="drc-as-div drc-as-name">{{ item.name }}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- ACTIVE PROGRAMS -->
			<div class="drc-details-section">
				<div
					class="drc-details-section-header">{{'STRINGS.ACTIVE_PROGRAMS' | translate}}</div>
				<div class="drc-details-section-scroll-container">
					<div class="drc-details-section-body" id="drc-programs-list">

						<!-- TODO: Remove extra div -->
						<div>
							<div class="drc-program-group-item" *ngFor="let programGroup of currentRunningProgramGroups; let i = index;">
								<div class="drc-program-group-label">{{ programGroup.programGroupName }}</div>
								<div class="drc-program-item" *ngFor="let program of getProgramGroupPrograms(programGroup.programGroupId)">
									{{ program.programName }}
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

			<!-- ACTIVE STATIONS -->
			<div class="drc-details-section">
				<div
					class="drc-details-section-header">{{'STRINGS.ACTIVE_STATIONS' | translate}}</div>
				<div class="drc-details-section-scroll-container">
					<div class="drc-details-section-body" id="drc-active-stations">
						<div *ngFor="let station of currentActiveStations" class="drc-active-station">
							<div class="drc-as-div drc-as-name">{{ station.name ? station.name : ('STRINGS.NO_STATION_NAME' | translate) }}</div>
							<div class="drc-as-div drc-as-rate">{{ station.rate }}</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>

</div>


export enum CarouselTransitionDirection {
	None,
	Left,
	Right
}

export enum CarouselTransition {
	InLeft = 'inLeft',
	OutLeft = 'outLeft',
	InRight = 'inRight',
	OutRight = 'outRight'
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} </div>

<div class="form-group flex flex-row v-mid" [formGroup]="startTimeFormGroup"
	[class.suppress-click]="isProgramGroup" [class.program-tool-group]="isProgramTool">
	<div class="mat-subheading-1 self-stretch flex-auto start-time-display" [class.disabled]="disabled || warn" data-qaid="start-time-display">
		<span *ngIf="isDelayed" class="delayed-until" [class.delayed-time]="isDelayed">{{'STRINGS.DELAYED_UNTIL' | translate: ({time : startTime | date: timePickerDisplay})}}</span>
		<span *ngIf="!isDelayed"[class.delayed-time]="isDelayed" [class.program-tool-start-time-text]="isProgramTool">{{startTime | date: timePickerDisplay}}</span>
		<i *ngIf="warn" class="material-icons show-warning" [title]="translate.instant('SPECIAL_MSG.NO_PROGRAM_START_TIMES')">error_outline</i>
	</div>

<!--	<label *ngIf="isProgramGroup" class="mr5 ba accentColor br-pill mat-caption ph1"> {{'STRINGS.GROUP' | translate}}</label>-->

	<div class="mat-subheading-1a" *ngIf="showDelayOption">
		<span class="delay-start-time" (click)="onAddStartTime()">{{'STRINGS.DELAY_THIS_START_TIME' | translate}}...</span>
	</div>

	<div *ngIf="!showDelayOption && !isReadOnly" class="mat-subheading-1b" [class.allow-hover]="!isProgramGroup">
		<button type="button"
			mat-icon-button
			data-qaid="edit-start-time-btn"
			[ngxTimepicker]="editStartTime"
			[format]= "format"
			[value]="(startTime | date: timePickerDisplay)">
			<mat-icon aria-label="edit" aria-hidden="true">edit</mat-icon>
		</button>

		<ngx-material-timepicker
			#editStartTime
			[enableKeyboardInput]="true"
			timepickerClass="data-qaid-edit-start-timepicker"
			(timeSet)="onStartTimeUpdate($event)"
			(opened)="onStartTimeOpened()"
			(closed)="onStartTimeClosed()"
			[cancelBtnTmpl]="cancelBtn"
			[confirmBtnTmpl]="confirmBtn"
		>
		</ngx-material-timepicker>

		<button mat-icon-button
			*ngIf="!shouldHideDelete"
			class="rb-flex-grow-0 nt1"
			data-qaid="delete-start-time-btn"
			(click)="onDeleteStartTime()"
			>
			<mat-icon *ngIf="!isProgramTool" aria-label="delete" aria-hidden="true">delete</mat-icon>
			<i *ngIf="isProgramTool" class="program-tool-icon rb-icon icon-delete-with-cross"></i>
		</button>
	</div>

	<!--Not displaying start time enabled/disabled feature in the UI at this time-->
	<mat-slide-toggle *ngIf="false" class="rb-flex-grow-0" color="primary" [checked]="enabled" (change)="onEnableToggled()"></mat-slide-toggle>
</div>

<ng-template #cancelBtn>
	<button
	mat-button
	type="button"
	class="rb-secondary-button mat-primary"
	data-qaid="dialog-cancel-button"
	>
		{{ 'STRINGS.CANCEL' | translate  }}
	</button>
</ng-template>
<ng-template #confirmBtn>
	<button
	mat-raised-button
	class="rb-primary-button mat-primary"
	data-qaid="dialog-submit-button"
	>
		{{ 'STRINGS.OK' | translate }}
	</button>
</ng-template>

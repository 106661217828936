import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class SeasonalAdjust {

	adjustTypeId: RbEnums.Common.AdjustType;
	aprAdjust: number;
	aprInchValue: number;
	augAdjust: number;
	augInchValue: number;
	companyId: number;
	currentAdjustPct: number;
	dailySaValue: number;
	decAdjust: number;
	decInchValue: number;
	febAdjust: number;
	febInchValue: number;
	id: number;
	janAdjust: number;
	janInchValue: number;
	julAdjust: number;
	julInchValue: number;
	junAdjust: number;
	junInchValue: number;
	marAdjust: number;
	marInchValue: number;
	mayAdjust: number;
	mayInchValue: number;
	novAdjust: number;
	novInchValue: number;
	octAdjust: number;
	octInchValue: number;
	periodicReferenceInchValue: number;
	periodicReferenceInchValueInitialized = false;
	periodicValue: number;
	referenceInchValue: number;
	referenceInchValueInitialized = false;
	sepAdjust: number;
	sepInchValue: number;
	siteId: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		} else {
			const defaultETValue = 0.01;
			const defaultPercentageValue = 100;
			Object.assign(this, {
				adjustTypeId: RbEnums.Common.AdjustType.DailySA,
				aprAdjust: defaultPercentageValue,
				aprInchValue: defaultETValue,
				augAdjust: defaultPercentageValue,
				augInchValue: defaultETValue,
				currentAdjustPct: defaultPercentageValue,
				dailySaValue: defaultPercentageValue,
				decAdjust: defaultPercentageValue,
				decInchValue: defaultETValue,
				febAdjust: defaultPercentageValue,
				febInchValue: defaultETValue,
				janAdjust: defaultPercentageValue,
				janInchValue: defaultETValue,
				julAdjust: defaultPercentageValue,
				julInchValue: defaultETValue,
				junAdjust: defaultPercentageValue,
				junInchValue: defaultETValue,
				marAdjust: defaultPercentageValue,
				marInchValue: defaultETValue,
				mayAdjust: defaultPercentageValue,
				mayInchValue: defaultETValue,
				novAdjust: defaultPercentageValue,
				novInchValue: defaultETValue,
				octAdjust: defaultPercentageValue,
				octInchValue: defaultETValue,
				periodicReferenceInchValue: defaultETValue,
				periodicReferenceInchValueInitialized: false,
				periodicValue: defaultETValue,
				referenceInchValue: defaultETValue,
				referenceInchValueInitialized: false,
				sepAdjust: defaultPercentageValue,
				sepInchValue: defaultETValue,
			});
		}
	}
}

import { SensorListItem } from './sensor-list-item.model';

export class SensorListChange {

	constructor(controllerId: number, sensors: SensorListItem[]) {
		this.controllerId = controllerId;
		this.sensors = sensors;
	}

	controllerId: number;
	sensors: SensorListItem[];
}

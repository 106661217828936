import { MapInfo } from './map-info.model';

/**
 * TrackingDataControl is used as one of the Google Maps controls for displaying tracking data,
 * generally for location services debugging, in the Google Map frame.
 */
export class TrackingDataControl {

	static ControlUI: HTMLDivElement = null;

	static create(mapInfo: MapInfo, initialText: string, parentDiv: HTMLElement) {
		// Set CSS for the control border.
		TrackingDataControl.ControlUI = document.createElement('div');

		// Set background color and opacity to generally match the Google notes displayed on the map. Set sizing so that
		// it will not overlap the Google buttons (Keyboard shortcuts, for example) and will grow vertically as necessary
		// to make the data display.
		TrackingDataControl.ControlUI.style.display = 'flex';
		TrackingDataControl.ControlUI.style.flex = '1 1';
		TrackingDataControl.ControlUI.style.maxWidth = '180px';
		TrackingDataControl.ControlUI.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
		TrackingDataControl.ControlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
		TrackingDataControl.ControlUI.style.fontSize = '10px';
		TrackingDataControl.ControlUI.style.marginRight = '11px';
		TrackingDataControl.ControlUI.style.paddingLeft = '5px';
		TrackingDataControl.ControlUI.style.paddingRight = '5px';
		TrackingDataControl.ControlUI.style.textAlign = 'left';
		TrackingDataControl.ControlUI.style.color = 'black';
		parentDiv.appendChild(TrackingDataControl.ControlUI);

		TrackingDataControl.ControlUI.textContent = initialText != null ? initialText : '';
	}

	static setText(newText: string): void {
		TrackingDataControl.ControlUI.textContent = newText;
	}
}

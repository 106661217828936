import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { FormValidationService } from '../../../../../common/services/form-validation.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxInfo } from '../../../../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from '../../../../../common/services/message-box.service';
import { PinCode } from '../../../../../api/pin-codes/models/pin-code.model';
import { PinCodeManagerService } from '../../../../../api/pin-codes/pin-code-manager.service';
import { PinCodeUpdate } from '../../../../../api/pin-codes/models/pin-code-update.model';
import { RbConstants } from '../../../../../common/constants/_rb.constants';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-change-pin-code',
	templateUrl: './change-pin-code.component.html',
	styleUrls: ['./change-pin-code.component.scss'],
})
export class ChangePinCodeComponent implements OnInit {

	@ViewChild('updatePin', { static: true }) updatePin;
	@Output() pinCodeChange = new EventEmitter<{newPinCode: number, id: number}>();

	// Set this flag when this component is being used in scenarios where the current user is changing the pin code of another user.
	@Input() isChangingNonCurrentUserPinCode = false;
	@Input() changeButtonText = 'STRINGS.CHANGE';
	@Input() selectedUserId = 0;
	editForm: FormGroup;
	pinCode: PinCode;
	pinErrorMessage: string;
	RbConstants = RbConstants;

	constructor(public dialog: MatDialog,
		private fb: FormBuilder,
		private messageBoxService: MessageBoxService,
		private formValidation: FormValidationService,
		private translate: TranslateService,
		private pinCodeManager: PinCodeManagerService) { }

	ngOnInit() {
		this.editForm = this.fb.group({
			pin: [0, [Validators.required,
			this.formValidation.rbMinMaxValidation(RbConstants.Form.PIN_CODE_RANGE.min, RbConstants.Form.PIN_CODE_RANGE.max)
			]],
		});
		this.editForm.get('pin').valueChanges.subscribe(value => this.handlePinChange(value));
		this.formValidation.setupValidation(this, this.editForm, 'pin', this.translate.instant('STRINGS.PIN_CODE'));
		this.getFormData();
	}
	handleChangePinCode() {
		this.dialog.open(this.updatePin);
	}

	private getFormData() {
			const sources: Observable<any>[] = [
				this.getPinCode(),
			];

			forkJoin(sources)
				.subscribe(([pinCode]) => {
					this.pinCode = pinCode;
					this.updateForm();
				});
	}

	private getPinCode(): Observable<PinCode> {
		if (this.isChangingNonCurrentUserPinCode)
			return this.pinCodeManager.getPinCodeByUserId(this.selectedUserId).pipe(take(1));
		else
			return this.pinCodeManager.getMyPinCode().pipe(take(1));
	}

	private updateForm() {
		this.editForm.patchValue({
			pin: this.pinCode.pin,
		}, { emitEvent: false });
	}

	private checkUniqueNameValidators(): Observable<boolean> {
		return this.formValidation.checkUniqueName(RbConstants.Form.UNIQUE_NAMES.pinNumber, this.editForm.get('pin').value,
			{ pinId: this.pinCode.id }, this.translate.instant('STRINGS.PIN_CODE')).pipe(map(msg => {
			if (msg != null) this.messageBoxService.showMessageBox(new MessageBoxInfo(msg, RbEnums.Common.MessageBoxIcon.Error));
			return msg == null;
		}));
	}

	onSubmit() {
		this.checkUniqueNameValidators().subscribe(success => {
			if (!success) return;
			this.dialog.closeAll();
			this.pinCode.pin = this.editForm.get('pin').value;
			const pinCodeUpdate: PinCodeUpdate = {
				id: this.pinCode.id,
				pin: this.pinCode.pin,
			};

			// If this is a scenario where we are changing the pin code of another, don't update here, but rather pass back the new pin code to the caller.
			if (this.isChangingNonCurrentUserPinCode) {
				this.pinCodeChange.emit({ newPinCode: pinCodeUpdate.pin, id: pinCodeUpdate.id });
				return;
			}

			this.pinCodeManager.updatePinCode(pinCodeUpdate)
				.subscribe(() => {
				}, error => {
					this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED');
				});
		});
	}

	private handlePinChange(value) {
		const correctedValue = value?.toString().padStart(this.RbConstants.Form.PIN_CODE_PADDING_ZERO, '0');
		this.editForm.patchValue({
			pin: correctedValue,
		}, { emitEvent: false });
	}

	onCancel() {
		this.editForm.get('pin').setValue(this.pinCode.pin);
		this.editForm.markAsPristine();
		this.editForm.markAsUntouched();
	}
}

import { ConnectDataPacksDict } from './connect-data-packs-dict.model';

export class ConnectDataPackChange {

	constructor(controllerId: number, connectDataPacks: ConnectDataPacksDict) {
		this.controllerId = controllerId;
		this.connectDataPacks = connectDataPacks;
	}

	controllerId: number;
	connectDataPacks: ConnectDataPacksDict;
}

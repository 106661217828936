import { CarouselTransitionDirection } from '../enums/carousel.enums';

export class CarouselItemTransition {

	constructor(isVisible: boolean, direction: CarouselTransitionDirection) {
		this.isVisible = isVisible;
		this.direction = direction;
	}

	isVisible = false;
	direction = CarouselTransitionDirection.None;

}

import { ApiService, HttpMethod } from '../_common/api.service';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { AuthManagerService } from '../auth/auth-manager-service';
import { EnvironmentService } from '../../common/services/environment.service';
import { FlowSensor } from '../controllers/models/flow-sensor.model';
import { FlowSensorModel } from './models/flow-sensor-model.model';
import { GetSensorQueryParams } from './models/get-sensor-params.model';
import { GolfProgrammableSensor } from './models/golf-programmable-sensor.model';
import { GolfSensorKingdomType } from './models/golf-sensor-kingdom-type.model';
import { GolfSensorListItem } from './models/golf-sensor-list-item.model';
import { GolfWeatherSensor } from './models/golf-weather-sensor.model';
import { GolfWeatherSensorType } from './models/golf-weather-sensor-type.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalSensorModel } from './models/local-sensor-model.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OnOffStateModel } from './models/on-off-state.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { SelectListItem } from '../_common/models/select-list-item.model';
import { Sensor } from './models/sensor.model';
import { SensorListItem } from './models/sensor-list-item.model';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';
import { WeatherSensor } from './models/weather-sensor.model';
import { WeatherSensorModel } from './models/weather-sensor-model.model';

@Injectable({
	providedIn: 'root'
})
export class SensorApiService extends ApiService {
	clearCache() {
		super.clearCache(this.baseGetSensorsListBySatelliteIdUrl);
		super.clearCache(this.getGolfSensorListUrl);
		super.clearCache(this.getSensorsListUrl);
	}

	constructor(private authManager: AuthManagerService,
				protected http: HttpClient,
				protected env: EnvironmentService
	) {
		super(http, env);
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	deleteSensors(sensorIds: number[]): Observable<void> {
		return this.apiRequest<any>(this.deleteSensorsUrl, HttpMethod.Delete, sensorIds);
	}

	getAddressUniqueness(address: string, id: number, controllerId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getAddressUniquenessUrl(address, id, controllerId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getFlowSensorModels(): Observable<FlowSensorModel[]> {
		return this.apiRequest<any>(this.getFlowSensorModelsUrl, HttpMethod.Get)
			.pipe(map(list => list.map(item => new FlowSensorModel(item))));
	}

	getNameUniqueness(name: string, id: number, controllerId: number): Observable<UniquenessResponse> {
		return this.apiRequestWithCache<any>(this.getNameUniquenessUrl(name, id, controllerId), true)
			.pipe(map(response => new UniquenessResponse(response.value)));
	}

	getSensorsList(bypassCache = false): Observable<ApiCachedRequestResponse<SensorListItem[]>> {
		return this.apiRequestWithCache<any>(this.getSensorsListUrl, bypassCache,
			result => result.map(item => new SensorListItem(item)));
	}

	getSensorsListBySatelliteId(controllerId: number, bypassCache = false): Observable<ApiCachedRequestResponse<SensorListItem[]>> {
		return this.apiRequestWithCache<any>(this.getSensorsListBySatelliteIdUrl(controllerId), bypassCache,
			result => result.map(item => new SensorListItem(item)));
	}

	getSensor(id: number, queryParams?: GetSensorQueryParams): Observable<Sensor> {
		const isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);

		return this.apiRequest<any>(this.getSensorUrl(id, queryParams), HttpMethod.Get)
			.pipe(map(response => isGolfSite ? (response.type === RbEnums.Common.SensorType.Flow ? new FlowSensor(response) : this.getGolfSensor(response)) :
				(response.type === RbEnums.Common.SensorType.Flow ? new FlowSensor(response) : new WeatherSensor(response))));
	}

	getWeatherSensorModels(): Observable<WeatherSensorModel[]> {
		return this.apiRequest<any>(this.getWeatherSensorModelsUrl, HttpMethod.Get)
			.pipe(map(list => list.map(item => new WeatherSensorModel(item))));
	}

	getLocalSensorModels(parentControllerId: number): Observable<LocalSensorModel[]> {
		return this.apiRequest<any>(this.getLocalSensorModelsUrl(parentControllerId), HttpMethod.Get)
			.pipe(map(list => list.map(item => new LocalSensorModel(item))));
	}

	getOnOffStates(): Observable<OnOffStateModel[]> {
		return this.apiRequest<any>(this.getOnOffStatesUrl, HttpMethod.Get)
			.pipe(map(list => list.map(item => new OnOffStateModel(item))));
	}

	getGolfProgrammableSensorModel(): Observable<SelectListItem[]> {
		return this.apiRequest<any>(this.getGolfProgrammableSensorModelUrl, HttpMethod.Get)
			.pipe(map(list => list.map(item => new SelectListItem(item))));
	}

	getGolfSensorKingdom(): Observable<GolfSensorKingdomType[]> {
		return this.apiRequest<any>(this.getGolfSensorKingdomUrl, HttpMethod.Get)
			.pipe(map(list => list.map(item => new GolfSensorKingdomType(item))));
	}

	getGolfWeatherSensorModel(): Observable<GolfWeatherSensorType[]> {
		return this.apiRequest<any>(this.getGolfWeatherSensorModelUrl, HttpMethod.Get)
			.pipe(map(list => list.map(item => new GolfWeatherSensorType(item))));
	}

	updateSensors(sensorIds: number[], updateData: any) {
		return this.apiRequest<any>(this.updateBatchesUrl, HttpMethod.Patch,
			{ ids: sensorIds, patch: this.patchTransform(updateData) });
	}

	createSensor(sensor: any): Observable<Sensor> {
		return this.apiRequest<any>(this.createSensorUrl, HttpMethod.Post, sensor)
			.pipe(map(response => new Sensor(response)));
	}

	getGolfSensorsList(bypassCache = false): Observable<ApiCachedRequestResponse<GolfSensorListItem[]>> {
		return this.apiRequestWithCache<any>(this.getGolfSensorListUrl, bypassCache,
			result => result.map(item => new GolfSensorListItem(item)).sort((a, b) => a.name.localeCompare(b.name)));
	}

	getSharedWeatherSensors(controllerId: number): Observable<WeatherSensor[]> {
		return this.apiRequest<any>(this.getSharedWeatherSensorsUrl(controllerId), HttpMethod.Get)
			.pipe(map(sensors => sensors.map(mv => new WeatherSensor(mv))));
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getGolfSensor(sensorObj: any): GolfProgrammableSensor {
		if (sensorObj.hasOwnProperty('golfWeatherSensorModel')) { return new GolfWeatherSensor(null, sensorObj); }

		return new GolfProgrammableSensor(null, sensorObj);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}Sensor`; }

	private get deleteSensorsUrl() { return `${this.baseUrl}/DeleteSensors`; }

	private getAddressUniquenessUrl(address: string, id: number, controllerId: number): string {
		return `${this.baseUrl}/IsAddressUnique?satelliteId=${controllerId}&sensorAddress=${address}&sensorId=${id}`;
	}

	private get getFlowSensorModelsUrl(): string { return `${this.baseUrl}/GetFlowSensorModels`; }

	private getNameUniquenessUrl(name: string, id: number, controllerId: number): string {
		return `${this.baseUrl}/IsNameUnique?sensorName=${encodeURIComponent(name)}&satelliteId=${controllerId}&sensorId=${id}`;
	}

	private get createSensorUrl(): string { return `${this.baseUrl}/CreateSensor`; }

	private get getSensorsListUrl(): string { return `${this.baseUrl}/GetSensorList`; }

	private get baseGetSensorsListBySatelliteIdUrl(): string { return `${this.baseUrl}/GetSensorListBySatelliteId`; }
	private getSensorsListBySatelliteIdUrl(controllerId: number): string {
		return `${this.baseGetSensorsListBySatelliteIdUrl}?satelliteId=${controllerId}`;
	}

	private getSensorUrl(id: number, queryParams?: GetSensorQueryParams): string {
		let queryString = queryParams ? queryParams.queryString : '';
		queryString += (queryString.length > 0) ? `&sensorId=${id}` : `?sensorId=${id}`;
		return `${this.baseUrl}/GetSensor${queryString}`;
	}

	private get updateBatchesUrl(): string { return `${this.baseUrl}/UpdateBatches`; }

	private get getWeatherSensorModelsUrl(): string { return `${this.baseUrl}/GetWeatherSensorModels`; }

	private getLocalSensorModelsUrl(parentControllerId: number): string {
		return `${this.baseUrl}/GetLocalSensorModels?parentControllerId=${parentControllerId}`;
	}

	private get getOnOffStatesUrl(): string { return `${this.baseUrl}/GetOnOffState`; }

	private get getGolfSensorKingdomUrl(): string { return `${this.baseUrl}/GetGolfSensorKingdom`; }

	private get getGolfWeatherSensorModelUrl(): string { return `${this.baseUrl}/GetGolfWeatherSensorModel`; }

	private get getGolfProgrammableSensorModelUrl(): string { return `${this.baseUrl}/GetProgrammableSensorModel`; }

	private get getGolfSensorListUrl(): string { return `${this.baseUrl}/GetGolfSensorList`; }

	private getSharedWeatherSensorsUrl(parentControllerId: number): string {
		return `${this.baseUrl}/GetSharedWeatherSensors?satelliteId=${parentControllerId}`;
	}
}

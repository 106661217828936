import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppDateAdapter } from '../../../shared-ui/custom/date-adapter';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { EntitlementsManagerService } from '../../../api/entitlements/entitlements-manager.service';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-entitlements-renewal-warning',
	templateUrl: './entitlements-renewal-warning.component.html',
	styleUrls: ['./entitlements-renewal-warning.component.scss']
})
export class EntitlementsRenewalWarningComponent implements OnInit
{
	@Input() renewalState: RbEnums.Common.EntitlementsRenewalState;
	@Input() expirationDate: Date;

	@Output() messageClosed = new EventEmitter();


	message: string;

	EntitlementsRenewalState = RbEnums.Common.EntitlementsRenewalState;

	constructor(private appDateAdapter: AppDateAdapter,
				public deviceManager: DeviceManagerService,
				private entitlementsManager: EntitlementsManagerService,
				private translate: TranslateService) { }

	ngOnInit(): void
	{
		this.message = this.translate.instant(this.renewalState === RbEnums.Common.EntitlementsRenewalState.Expired ?
				'SUBSCRIPTIONS.PROFEATURES.EXPIRED_MESSAGE' : 'SUBSCRIPTIONS.PROFEATURES.EXPIRING_MESSAGE', {
				expirationDate: this.appDateAdapter.format(this.expirationDate)
			});
	}

	onManageSubscription()
	{
		this.entitlementsManager.openStore();
		this.messageClosed.emit();
	}

	onDoNotShowAgain()
	{
		localStorage.setItem(RbConstants.Common.DO_NOT_SHOW_ENTITLEMENTS_RENEWAL_MESSAGE, 'true');
		this.messageClosed.emit();
	}
}

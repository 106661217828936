<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-duration-dialog
	[title]="('STRINGS.STATION_START' | translate) + '(' + selectedItemIds.length + ')'"
	[duration]="initBaseRunTime"
	(durationChange)="startStations($event)"
	[hideHours]="false"
	[hideMinutes]="false"
	[hideSeconds]="false"
	[maxHours]="maxHours"
	[showTabs]="selectedItemIds.length === 1"
	[station]="station">
</rb-duration-dialog>

import { Pipe, PipeTransform } from '@angular/core';
import { RbUtils } from '../../common/utils/_rb.utils';

@Pipe({
	name: 'replaceWhitespace'
})
export class ReplaceWhitespacePipe implements PipeTransform {
	transform(value: any, args?: string): string {
		return RbUtils.Common.replaceWhitespace(value, args);
	}
}

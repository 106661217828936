import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { UserListItem } from '../../../../../api/users/models/user-list-item.model';

@Component({
	selector: 'rb-admin-mobile-cell-renderer',
	templateUrl: './admin-mobile-cell-renderer.component.html',
	styleUrls: ['./admin-mobile-cell-renderer.component.scss']
})
export class AdminMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	user: UserListItem;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.user = params.data;
	}

	getOwnerCellRenderer() {
		return RbUtils.GridCellStyles.getOwnerCellStyle(this.user.groupLevelItem, true);
	}
}

export class FlowDatumActiveStation {

	constructor(name: string, rate: string) {
		this.name = name;
		this.rate = rate;
	}

	name: string;
	rate: string;

}

import { RbEnums } from '../enumerations/_rb.enums';

/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Report.reportCard
 * 		 See: _rb.utils.ts
 */

export namespace XXUseRbUtilsNamespace {

	export abstract class Diagnostics {

		static get regularDiagnosticsCards(): any {

			const diagnosticsList = [
				{
					id: 1,
					iconClass: 'mdi mdi-help-network-outline',
					title: 'STRINGS.ICM_DIAGNOSTICS',
					gradient: 'linear-gradient(90deg, #F4B744 0%, #FED24D 90%)',
					route: './icm',
					settings: 'icmSettings',
					actionText: 'STRINGS.SELECT_TEST'
				},
				{
					id: 2,
					img: '../../../assets/ici.svg',
					title: 'STRINGS.ICI_DIAGNOSTIC_RESULTS',
					gradient: 'linear-gradient(90deg, #F4B744 0%, #FED24D 90%)',
					route: './ici',
					actionText: 'STRINGS.VIEW_RESULTS'
				},
				// {
				// 	id: 3,
				// 	img: '../../../assets/link.svg',
				// 	title: 'STRINGS.LINK_DIAGNOSTICS',
				// 	gradient: 'linear-gradient(90deg, #F4B744 0%, #FED24D 90%)',
				// 	route: './link',
				// },
				// {
				// 	id: 4,
				// 	img: '../../../assets/link.svg',
				// 	title: 'STRINGS.DECODER_DIAGNOSTICS',
				// 	gradient: 'linear-gradient(90deg, #F4B744 0%, #FED24D 90%)',
				// 	route: './decoder',
				// },
			];
			return diagnosticsList;
		}

		static get scheduledDiagnosticsCards(): any {

			const diagnosticsList = [
				{
					id: 101,
					iconClass: 'mdi mdi-help-network-outline',
					title: 'STRINGS.SCHEDULE_ICM_SYSTEM_POLL',
					gradient: 'linear-gradient(90deg, #5A9027 0%, #82BA44 90%)',
					route: './schedule',
					scheduledTime: null,
					testType: RbEnums.Common.ScheduledDiagnosticTestType.ICMStatusSystemPoll,
				},
				{
					id: 102,
					iconClass: 'mdi mdi-help-network-outline',
					title: 'STRINGS.SCHEDULE_ICM_VOLTAGE_TEST',
					gradient: 'linear-gradient(90deg, #5A9027 0%, #82BA44 90%)',
					route: './schedule',
					scheduledTime: null,
					testType: RbEnums.Common.ScheduledDiagnosticTestType.ICMVoltageSystemPoll,
				},
			];
			return diagnosticsList;
		}

		static get satelliteDiagnosticsCards(): any {

			const diagnosticsList = [
				{
					id: 1,
					iconClass: 'mdi mdi-broadcast',
					title: 'STRINGS.LINK_DIAGNOSTICS',
					gradient: 'linear-gradient(90deg, #A40FEA 0%, #C96DF4 90%)',
					route: './link',
					actionText: 'STRINGS.VIEW_RESULTS'
				},
			];
			return diagnosticsList;
		}
		
		static get decoderDiagnosticsCards(): any {
			const diagnosticsList = [
				{
					id: 302,
					iconClass: 'mdi mdi-cylinder',
					title: 'STRINGS.DECODER_STATION_DIAGNOSTICS',
					gradient: 'linear-gradient(90deg, #0EA9E5 0%, #6AC0E1 90%)',
					route: './decoder-stations',
					actionText: 'STRINGS.SELECT_TEST'
				},
				{
					id:303,
					iconClass: 'mdi mdi-help-network-outline',
					title: 'STRINGS.DECODER_INTERFACE_DIAGNOSTICS',
					gradient: 'linear-gradient(90deg, #0EA9E5 0%, #6AC0E1 90%)',
					route: './decoders',
					actionText: 'STRINGS.VIEW_RESULTS'
				},
			];
			return diagnosticsList;
		}
	}
}

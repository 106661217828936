import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationService } from '../../../common/services/form-validation.service';
import { MapLeafletComponent } from '../../../core/components/map-leaflet/map-leaflet.component';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { Site } from '../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-site-address-modal',
	templateUrl: './site-address-modal.component.html',
	styleUrls: ['./site-address-modal.component.scss'],
})
export class SiteAddressModalComponent implements OnInit {
	@HostBinding('class') class = 'rb-site-address-modal';
	
	@Input() site: Site;
	@Input() mapComponent: MapLeafletComponent;

	RbConstants = RbConstants;
	addressForm: FormGroup;
	addressErrorMessage: string;
	cityErrorMessage: string;
	stateErrorMessage: string;
	zipErrorMessage: string;
	loadError: string;

	// =========================================================================================================================================================
	// C'tor and Lifecycle hooks
	// =========================================================================================================================================================

	constructor(private fb: FormBuilder,
				private formValidation: FormValidationService,
				private translate: TranslateService,
				private siteManager: SiteManagerService) { }

	ngOnInit() {
		this.addressForm = this.fb.group({
			address: [
				this.site?.address || '', 
				[ Validators.minLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.min), Validators.maxLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.max) ]
			],
			city: [
				this.site?.city || '', 
				[ Validators.minLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.min), Validators.maxLength(RbConstants.Form.CHARACTER_LENGTH_RANGE.max) ]
			],
			state: [ this.site?.state || '', [ Validators.minLength(RbConstants.Form.STATE_LENGTH_RANGE.min),
				Validators.maxLength(RbConstants.Form.STATE_LENGTH_RANGE.max) ] ],
			zip: [ this.site?.zip || '', [ Validators.minLength(RbConstants.Form.ZIP_LENGTH_RANGE.min),
				Validators.maxLength(RbConstants.Form.ZIP_LENGTH_RANGE.max) ] ],
		});
		this.formValidation.setupValidation(this, this.addressForm, 'address', this.translate.instant('STRINGS.ADDRESS'));
		this.formValidation.setupValidation(this, this.addressForm, 'city', this.translate.instant('STRINGS.CITY'));
		this.formValidation.setupValidation(this, this.addressForm, 'state', this.translate.instant('STRINGS.STATE'));
		this.formValidation.setupValidation(this, this.addressForm, 'zip', this.translate.instant('STRINGS.ZIP'));
	}

	onAddressDialogCanceled() {
		this.mapComponent.busy = false;
		if (this.site && (this.site.address || this.site.city || this.site.state || this.site.zip)) {
			// We already used an address on map
			return;
		}
		// Let the map stay at whatever coordinate it currently is: user's location, last visited coordinate or default (0,0)
	}

	onAddressDialogSubmitted() {
		const patchValues = RbUtils.Forms.getPatchValuesForFormGroup(this.addressForm);
		if (patchValues == null) return;

		this.mapComponent.busy = true;
		this.siteManager.updateSites([ this.site.id ], patchValues)
			.subscribe({
				next: () => {
					this.mapComponent.busy = false;
					this.site.address = this.addressForm.get('address').value;
					this.site.city = this.addressForm.get('city').value;
					this.site.state = this.addressForm.get('state').value;
					this.site.zip = this.addressForm.get('zip').value;
				},
				error: (e) => {
					this.loadError = e.error || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY');
				}
			});

	}
}

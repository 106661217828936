import { Pipe, PipeTransform } from '@angular/core';
import { CultureSettingsManagerService } from '../../api/culture-settings/culture-settings-manager.service';

@Pipe({
  name: 'numberWithComma'
})
export class NumberWithCommaPipe implements PipeTransform {
  constructor(private cultureManager: CultureSettingsManagerService) {}

  transform(value: number, precision?: number): string {
    return this.cultureManager.cultureSetting.toUserNumber(
      `${precision ? value.toFixed(precision) : value}`,
      true
    );
  }
}
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../../api/culture-settings/models/culture-settings.model';
import { RbUtils } from '../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-group-decimal-separator',
	templateUrl: './group-decimal-separator.component.html',
	styleUrls: ['./group-decimal-separator.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GroupDecimalSeparatorComponent),
			multi: true
		}
	]
})
export class GroupDecimalSeparatorComponent implements OnInit, ControlValueAccessor {

	constructor(
		private authManager: AuthManagerService,
	) { }

	@Input() name = '';
	@Input() precision = 2;
	@Input() min = 0;
	@Input() allowNegativeValue = false;
	@Input() isRequired = false;
	@Input() isDisabled = false;
	@Output() valueChanges = new EventEmitter<number>();

	separatorString: string;
	cultureSettings: CultureSettings;
	loadError: string;
	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => { };
	private onTouched = () => { };

	ngOnInit(): void {
		this.cultureSettings = this.authManager.userCulture;
	}

	writeValue(value: any): void {
		if (value == null) return;
		this.updateSeparatorString(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onSeparatorChanged(paramName: string, paramValue: any) {
		this.onTouched();
		this.separatorString = paramValue;

		// Always store the value with standard, english separator.
		this.onChange(this.separatorString.toString().replace(',', '.'));
		this.valueChanges.emit(paramValue);
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	private updateSeparatorString(value: string) {
		this.separatorString = RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator, value.toString(), this.precision);
	}

	onMatInputChange() {
		return this.separatorString = RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator, this.separatorString, this.precision);
	}

}

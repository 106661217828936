import { Area } from '../areas/models/area.model';

export class StationArea {

	areaId: number;
	area: Area;
	companyId: number;
	id: number;
	stationId: number;
	number: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);

			if (json.area) { this.area = new Area(json.area); }
		}
	}
}

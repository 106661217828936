import { first, take } from 'rxjs/operators';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { LicenseManagerService } from '../../api/license/license-manager.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RbConstants } from '../constants/_rb.constants';
import { RbUtils } from '../utils/_rb.utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class VersionCheckService {
	// this will be replaced by actual hash post-build.js
	private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
	sub;
	isGolfSite;
	showCirrusProLogo = false;

	constructor(private http: HttpClient,
				private authManager: AuthManagerService,
				private translate: TranslateService,
				public snackBar: MatSnackBar,
				private licenseManager: LicenseManagerService) {
	}

	/**
	 * Checks in every set frequency the version of frontend application
	 * @param url
	 * @param {number} frequency - in milliseconds, defaults to 5 minutes
	 */
	initVersionCheck(url, frequency = RbConstants.Form.VERSION_CHECK_FREQUENCY) {
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);

		// Don't start version check timer if running on localhost.
		// RB-8364: We DO start version check timer on localhost...for standalone!
		// if (url.includes('//localhost')) return;

		// RB-8364: Cloud is not a prerequisite for version checking! Standalone should also have version checking.
		// Really, the only build that should not is rbdev! If we are in rbdev, the build process does not run
		// post-build.js, so the hash value will still be the {{}}-surrounded value POST_BUILD_ENTERS_HASH_HERE.
		// If we find that, we don't run the checkVersion operation periodically.
		// RB-10292: The same behavior is used in rbdevmobile configuration, FYI, except that we can't use 'locahost'
		// references for API.
		if (!this.currentHash.includes('POST_BUILD_ENTERS_HASH_HERE')) {
			this.sub = interval(frequency).subscribe(() => this.checkVersion(url));
		}

		this.licenseManager.allowGolfUpgraded()
			.pipe(take(1))
			.subscribe(allowGolfUpgraded => {
				// We've changed the name of the product so CIRRUSPRO is all we use.
				this.showCirrusProLogo = true;
			});
	}

	stop() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	/**
	 * Will do the call and check if the hash has changed or not
	 * @param url
	 */
	private checkVersion(url) {
		// timestamp these requests to invalidate caches
		this.http.get(url + '?t=' + new Date().getTime()).pipe(
			first())
			.subscribe(
				(response: any) => {
					const hash = response.hash;
					const hashChanged = this.hasHashChanged(this.currentHash, hash);

					// If new version, do something
					if (hashChanged) {
						// ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
						// for an example: location.reload();
						this.promptUser(hash);
					} else {
						// store the new hash so we wouldn't trigger versionChange again
						// only necessary in case you did not force refresh
						this.currentHash = hash;
					}
				},
				(err) => {
					console.error(err, 'Could not get version');
				}
			);
	}

	/**
	 * Checks if hash has changed.
	 * This file has the JS hash, if it is a different one than in the version.json
	 * we are dealing with version change
	 * @param currentHash
	 * @param newHash
	 * @returns {boolean}
	 */
	private hasHashChanged(currentHash, newHash) {
		if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
			return false;
		}
		return currentHash !== newHash;
	}

	// If there is an update, promt the user
	private promptUser(hash): void {
		const snackBarRef = this.snackBar.open(
			this.isGolfSite ? this.getGolfVersion() :
				this.translate.instant('SPECIAL_MSG.NEW_VERSION_AVAILABLE_COMMERCIAL'),
			this.translate.instant('STRINGS.UPDATE'),
			{ horizontalPosition: 'center' }
		);
		snackBarRef.onAction().subscribe(() => {
			this.currentHash = hash;
			document.location.reload();
		});
	}

	private getGolfVersion() {
		return this.showCirrusProLogo ? this.translate.instant('SPECIAL_MSG.NEW_VERSION_AVAILABLE_GOLF_PRO') :
			this.translate.instant('SPECIAL_MSG.NEW_VERSION_AVAILABLE_GOLF_LT');
	}
}

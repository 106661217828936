export class MasterValveStatusMvInfo {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	name: string;
	status: string;
	type: string;
	waterWindow: boolean;
}

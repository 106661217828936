import { Component, EventEmitter,  Input, OnDestroy, OnInit, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-manual-sync-dialog',
	templateUrl: './manual-sync-dialog.component.html',
	styleUrls: ['./manual-sync-dialog.component.scss'],
})

export class ManualSyncDialogComponent implements OnInit, OnDestroy {

	@Input() selectedControllerName;
	@Output() complete = new EventEmitter<boolean>();
	@Output() cancel = new EventEmitter<{ savedData: boolean }>();

	loadError: string;
	syncIrrigationInProgress = true;
	title: string;

	constructor(private translateService: TranslateService) { }

	ngOnInit(): void {
		this.title = this.translateService.instant('STRINGS.SYNCHRONIZE') + ' ' + this.selectedControllerName;
	}

	ngOnDestroy(): void {
	}

	onSyncPromptChange(event) {
		this.syncIrrigationInProgress = event.value;
	}

	onSave() {

		this.complete.emit(this.syncIrrigationInProgress);
	}

	onCancel() {
		this.cancel.emit({ savedData: false });
	}

}

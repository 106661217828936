<div class="imported-layers-container">
	<div class="header">
		<div class="title-search">
			<h3 class="title">{{ 'STRINGS.IMPORTED_LAYERS' | translate}}</h3>
			<div class="layer-results-label">
				<button mat-button class="red" [matTooltip]="'STRINGS.LAYERS_WITH_ERRORS' | translate"
					[class.active]="filteringErrors" (click)="toggleErrorFilter()" *ngIf="layersWithErrors > 0">
					<mat-icon class="mr1">warning</mat-icon>
					<span>{{ layersWithErrors }}</span>
				</button>
			</div>
			<input type="text" class="search-input" [ngModel]="filterString" #searchInput [class.open]="isSearching"
				placeholder="Search" (ngModelChange)="filterTextSubject.next($event)">
		</div>
		<div class="filter-options">
			<div class="button-container">
				<button mat-button class="search-button" (click)="toggleSearch()">
					<i class="mdi mdi-magnify" *ngIf="!isSearching"></i>
					<i class="mdi mdi-magnify-close" *ngIf="isSearching"></i>
				</button>
			</div>
		</div>
	</div>
	<div class="body">

		<!-- Lists -->
		<div class="layer-list">

			<mat-accordion displayMode="flat" multi="true" class="layer-accordion">

				<mat-expansion-panel hideToggle="true" class="layer-expansion-panel" expanded
					[ngClass]="{'grow-1': vectorPanel.expanded, 'shrink-0': !vectorPanel.expanded}" #vectorPanel>
					<mat-expansion-panel-header collapsedHeight="56px" expandedHeight="56px"
						class="layer-expansion-header shrink-0">
						<mat-panel-title class="header-title">
							<mat-icon class="expansion-icon">{{vectorPanel.expanded ? 'expand_more' : 'chevron_right'}}</mat-icon>
							<div class="icon-container vector text-blue">
								<i class="material-icons">timeline</i>
							</div>
							<span class="no-items">{{ 'STRINGS.VECTOR_LAYERS' | translate }} <small class="i">({{
									filteredVectorLayerItems.length ? filteredVectorLayerItems.length : "No" }} items)</small></span>
						</mat-panel-title>
						<mat-panel-description class="layer-expansion-description">
							<div class="toggle-container" (click)="prevent($event)">
								<mat-slide-toggle color="primary" [checked]="showingVectorLayers" class="visibility-button"
									(change)="toggleVectorLayersVisibility($event)">
								</mat-slide-toggle>
							</div>
						</mat-panel-description>
					</mat-expansion-panel-header>

					<ng-container *ngIf="loadingKMZLayers">
						<ng-container *ngTemplateOutlet="loadingListMessage"></ng-container>
					</ng-container>

					<div cdkDropList
						class="inner-list cdkList"
						[cdkDropListDisabled]="!canEdit || filtering || !showingVectorLayers || isWidget"
						[cdkDropListData]="filteredVectorLayerItems"
						(cdkDropListDropped)="dropLayerItem($event)"
						*ngIf="filteredVectorLayerItems.length else emptyListMessage"
						[class.d-none]="loadingKMZLayers">
						<div class="layer-item" *ngFor="let layer of filteredVectorLayerItems; let i = index"
							cdkDrag
							[cdkDragHandleDisabled]="isWidget"
							[ngClass]="{
								'error': layer.error,
								'disabled': !showingVectorLayers
							}">
							<div cdkDragHandle class="drag-handle" *ngIf="canEdit && !layer.error && !isWidget">
								<span class="mdi mdi-drag silver"></span>
							</div>
							<span class="text"
								(contextmenu)="$event.preventDefault(); menuTrigger.openMenu()"
								#menuTrigger="matMenuTrigger"
								[matMenuTriggerFor]="layerMenu"
								[matMenuTriggerData]="{ layer, index: i }">
								{{ layer.fileName }}
							</span>
							<div class="error layer-toggle" *ngIf="layer.error">
								<i class="material-icons red" [matTooltip]="layer.error">warning</i>
							</div>
							<div class="layer-toggle" *ngIf="!layer.error">
								<mat-slide-toggle color="primary" [ngModel]="layer.visible" (click)="$event.stopPropagation()"
									(change)="visibilityChanged(layer, $event)" [class.mat-disabled]="!showingVectorLayers">
								</mat-slide-toggle>
							</div>
							<!-- <rb-color-picker
								class="quick-color-picker"
								[color]="layer.properties.color"
								(colorPickerChange)="onQuickColorChange($event, layer)">
							</rb-color-picker> -->
							<div
								class="quick-color-picker"
								[style.background-color]="layer.properties.color"
								(click)="openLayerImportTool($event, layer, true)">
							</div>
						</div>
					</div>

				</mat-expansion-panel>

				<mat-expansion-panel #imageExpansionPanel hideToggle="true" expanded class="layer-expansion-panel"
					[ngClass]="{'grow-1': rasterPanel.expanded, 'shrink-0': !rasterPanel.expanded}" #rasterPanel>
					<mat-expansion-panel-header collapsedHeight="56px" expandedHeight="56px"
						class="layer-expansion-header shrink-0">
						<mat-panel-title class="header-title">
							<mat-icon class="expansion-icon">{{rasterPanel.expanded ? 'expand_more' : 'chevron_right'}}</mat-icon>
							<div class="icon-container image text-green">
								<i class="material-icons">image</i>
							</div>
							<span class="no-items">{{'STRINGS.RASTER_LAYERS' | translate}} <small class="i">({{
									filteredRasterLayerItems && filteredRasterLayerItems.length ? filteredRasterLayerItems.length : "No"
									}} items)</small></span>
						</mat-panel-title>
						<mat-panel-description class="layer-expansion-description">
							<div class="toggle-container" (click)="prevent($event)">
								<mat-slide-toggle color="primary" [checked]="showingRasterLayers" class="visibility-button"
									(change)="toggleRasterLayersVisibility($event)">
								</mat-slide-toggle>
							</div>
						</mat-panel-description>
					</mat-expansion-panel-header>

					<ng-container *ngIf="loadingKMZLayers">
						<ng-container *ngTemplateOutlet="loadingListMessage"></ng-container>
					</ng-container>

					<div cdkDropList
						class="inner-list cdkList"
						[cdkDropListDisabled]="!canEdit || filtering || !showingRasterLayers || isWidget"
						[cdkDropListData]="filteredRasterLayerItems"
						(cdkDropListDropped)="dropLayerItem($event)"
						*ngIf="filteredRasterLayerItems.length else emptyListMessage"
						[class.d-none]="loadingKMZLayers">
						<div class="layer-item" *ngFor="let layer of filteredRasterLayerItems; let i = index"
							cdkDrag
							[cdkDragHandleDisabled]="isWidget"
							[ngClass]="{
								'error': layer.error,
								'disabled': !showingRasterLayers
							}">
							<div cdkDragHandle class="drag-handle" *ngIf="canEdit && !layer.error && !isWidget">
								<span class="mdi mdi-drag silver"></span>
							</div>
							<span class="text"
								(contextmenu)="$event.preventDefault(); menuTrigger.openMenu()"
								#menuTrigger="matMenuTrigger"
								[matMenuTriggerFor]="layerMenu"
								[matMenuTriggerData]="{ layer, index: i }">
								{{ layer.sourceName }}
							</span>
							<div class="error layer-toggle" *ngIf="layer.error">
								<i class="material-icons red" [matTooltip]="layer.error">warning</i>
							</div>
							<div class="layer-toggle" *ngIf="!layer.error">
								<mat-slide-toggle color="primary" [ngModel]="layer.visible" (click)="$event.stopPropagation()"
									(change)="visibilityChanged(layer, $event)" [class.mat-disabled]="!showingRasterLayers">
								</mat-slide-toggle>
							</div>
						</div>
					</div>

				</mat-expansion-panel>
			</mat-accordion>

			<mat-menu #layerMenu="matMenu" class="layer-context-menu">
				 <ng-template matMenuContent let-index="index" let-layer="layer">
					<button mat-menu-item *ngIf="!isWidget" (click)="moveToTop(layer, index)">
						<i class="mdi mdi-format-vertical-align-top"></i>
						<span>{{ 'STRINGS.MOVE_TO_TOP' | translate }}</span>
					</button>
					<button mat-menu-item *ngIf="!isWidget" (click)="moveOneUp(layer, index)">
						<i class="mdi mdi-arrow-up-thin"></i>
						<span>{{ 'STRINGS.MOVE_ONE_UP' | translate }}</span>
					</button>
					<button mat-menu-item *ngIf="!isWidget" (click)="moveOneDown(layer, index)">
						<i class="mdi mdi-arrow-down-thin"></i>
							<span>{{ 'STRINGS.MOVE_ONE_DOWN' | translate }}</span>
					</button>
					<button mat-menu-item *ngIf="!isWidget" (click)="moveToBottom(layer, index)">
						<i class="mdi mdi-format-vertical-align-bottom"></i>
							<span>{{ 'STRINGS.MOVE_TO_BOTTOM' | translate }}</span>
					</button>
					<mat-divider *ngIf="!isWidget"></mat-divider>
					<button mat-menu-item (click)="findOnMap(layer)">
						<i class="text-green mdi mdi-map-search"></i>
						<span>{{ 'STRINGS.FIND_ON_MAP' | translate }}</span>
					</button>
					<button mat-menu-item *ngIf="!isWidget" (click)="editLayer(layer)">
						<mat-icon class="text-yellow">edit</mat-icon>
						<span>{{ 'STRINGS.EDIT_LAYER' | translate }}</span>
					</button>
					<button mat-menu-item *ngIf="!isWidget" (click)="removeLayer(layer)">
						<mat-icon class="text-red">delete_forever</mat-icon>
						<span>{{ 'STRINGS.REMOVE_LAYER' | translate }}</span>
					</button>
				</ng-template>
			</mat-menu>

			<div class="message-backdrop loading" *ngIf="innerLoader">
				<div class="message-container">
					<div class="icon-container">
						<rb-waiting-indicator></rb-waiting-indicator>
					</div>
				</div>
			</div>
		</div>

	</div>
	<div class="footer" *ngIf="canEdit && !isWidget">

		<!-- Order buttons -->
		<div class="action-container">
			<div class="buttons-container orderButtons" *ngIf="canEdit">
				<!-- <button matTooltip="Move to top" (click)="moveToFront()">
					<i class="mdi mdi-format-vertical-align-top"></i>
				</button>
				<button matTooltip="Move one up" (click)="moveForward()">
					<i class="mdi mdi-arrow-up-thin"></i>
				</button>
				<button matTooltip="Move one down" (click)="moveBackward()">
					<i class="mdi mdi-arrow-down-thin"></i>
				</button>
				<button matTooltip="Move to bottom" (click)="moveToBack()">
					<i class="mdi mdi-format-vertical-align-bottom"></i>
				</button> -->
				<button mat-raised-button color="primary" (click)="openLayerImportTool()" class="layer-import-button"
					*ngIf="canEdit">{{ 'STRINGS.OPEN_LAYER_IMPORT_TOOL' | translate}}</button>
			</div>
		</div>
		<div class="bottom-container">
			<div class="counter-container">
				<span>{{ 'STRINGS.LISTING' | translate}}
					<span class="badge">{{ filteredVectorLayerItems.length + filteredRasterLayerItems.length }}</span>
					Item{{ (filteredVectorLayerItems.length + filteredRasterLayerItems.length) === 1 ? '' : 's' }}
				</span>
			</div>
		</div>
	</div>
</div>

<!-- Empty message -->

<ng-template #emptyListMessage>
	<p class="message" [class.d-none]="loadingKMZLayers">{{'STRINGS.NO_ITEMS_IN_LIST' | translate}}</p>
</ng-template>

<ng-template #loadingListMessage>
	<p class="message">{{'STRINGS.LOADING' | translate}}...</p>
</ng-template>

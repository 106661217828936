import { Observable, Subject } from 'rxjs';
import { BroadcastService } from '../../common/services/broadcast.service';
import { Injectable } from '@angular/core';
import { ScheduledReport } from './models/scheduled-report.model';
import { ScheduledReportApiService } from './scheduled-report-api.service';
import { ScheduledReportChange } from '../signalR/scheduled-report-change.model';
import { ScheduledReportItem } from './models/scheduled-report-item.model';
import { ScheduledReports } from './models/scheduled-reports.model';
import { ServiceManagerBase } from '../_common/service-manager-base';

@Injectable({
	providedIn: 'root'
})
export class ScheduledReportManagerService extends ServiceManagerBase {

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private reportApiService: ScheduledReportApiService,
				protected broadcastService: BroadcastService) {

		super(broadcastService);
	}

	protected clearCache() {
	}

	scheduledReportChange = new Subject<ScheduledReportChange>();
	
	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getScheduledReports(): Observable<ScheduledReports> {
		return this.reportApiService.getScheduledReports();
	}

	getScheduledReportList(): Observable<ScheduledReportItem[]> {
		return this.reportApiService.getScheduledReportList();
	}
	
	getScheduledReport(id: number): Observable<ScheduledReport> {
		return this.reportApiService.getScheduledReport(id);
	}

	createScheduledReport(task: ScheduledReport): Observable<void> {
		return this.reportApiService.createScheduledReport(task);
	}

	updateScheduledReport(id: number, updateValues: any): Observable<void> {
		return this.reportApiService.updateScheduledReport(id, updateValues);
	}

	updateBatches(ids: number[], updateValues: any): Observable<void> {
		return this.reportApiService.updateBatches(ids, updateValues);
	}

	deleteReports(taskIds: number[]): Observable<void> {
		return this.reportApiService.deleteReports(taskIds);
	}

}


export class GeoItemPoint {

	constructor(json: any = null) {

		if (json) {
			if (json['coordinates'] != null && json['coordinates'].length === 2) {
				this.longitude = json['coordinates'][0];
				this.latitude = json['coordinates'][1];
			}
		}
	}

	longitude: number;	// -180 (west) - 0 (Grenwich) - +180 (east)
	latitude: number;	// -90 (south pole) - 0 (equator) - +90 (north pole)
}

<div class="quick-irr-area-mobile-cell">
	<div class="name">{{area.name}}</div>
	<div class="number">
		<span class="label">{{'STRINGS.NUMBER' | translate}}:</span>
		<span class="value">{{area.number}}</span>
	</div>
	<div *ngIf="this.runTimeInputType === RbEnums.Common.RunTimeGolfInputType.Passes">
		<span class="label">{{'STRINGS.PASSES' | translate}}:</span>
		<span class="value runtime" (click)="onSetRuntime($event, area)">{{inputValue}}</span>
	</div>
	<div *ngIf="this.runTimeInputType === RbEnums.Common.RunTimeGolfInputType.Volume">
		<span class="label">{{'STRINGS.APPLICATION' | translate}}:</span>
		<span class="value runtime" (click)="onSetRuntime($event, area)">{{inputValue}}</span>
	</div>
	<div *ngIf="this.runTimeInputType !== RbEnums.Common.RunTimeGolfInputType.Volume && this.runTimeInputType !== RbEnums.Common.RunTimeGolfInputType.Passes">
		<span class="label">{{'STRINGS.RUN_TIME' | translate}}:</span>
		<span class="value runtime" (click)="onSetRuntime($event, area)">{{runtime}}</span>
	</div>
</div>

<div class="under-construction-container">
	<div class="main-content">
		<div class="middle-message">
			<h1>Under Construction</h1>
			<button mat-raised-button color="primary" (click)="goBack()">
				{{ 'STRINGS.GO_BACK' | translate}}
			</button>
		</div>
	</div>
</div>

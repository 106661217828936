import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { Component } from '@angular/core';
import { FormValidationService } from '../../../../../common/services/form-validation.service';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { TextInputCellEditorBase } from '../_text-input-cell-editor-base';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-station-address-cell-editor',
	templateUrl: './station-address-cell-editor.component.html',
	styleUrls: ['./station-address-cell-editor.component.scss']
})
export class StationAddressCellEditorComponent extends TextInputCellEditorBase {

	value: any;
	valueRange: { min: number, max: number }[];
	maxLength: number;
	isHexAddress: boolean;
	addressRegEx: RegExp;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected broadcastService: BroadcastService,
				protected translateService: TranslateService,
				protected formValidationService: FormValidationService,
	) {
		super(broadcastService, translateService);
	}

	// =========================================================================================================================================================
	// agGrid Methods
	// =========================================================================================================================================================

	agInit(params: any): void {
		super.agInit(params);

		// If the cellEditorParams is null or does not contain the controller object, throw error and abort.
		if (!this.params) { throw new Error('Missing CellEditorParams'); }

		// Get field constraints.
		this.isHexAddress = RbUtils.Stations.isStationAddressHex(this.params.data.parentSatelliteType);
		this.valueRange = RbUtils.Stations.stationAddressRange(this.params.data.parentSatelliteType);
		this.maxLength = this.isHexAddress ? 6 : 5;
		this.addressRegEx = new RegExp(RbUtils.Stations.addressPattern(this.params.data.parentSatelliteType));

		// Set initial editor value.
		this.value = this.addressRegEx.test(this.params.charPress)
			? this.params.charPress : (!this.isHexAddress)
				? this.params.value : this.getAddressString(+this.params.value);
	}

	// Override base class method.
	getValue() {
		if (this.textInput.nativeElement.value === '') { this.textInput.nativeElement.value = 0; }
		return this.getAddressInt();
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onKeyPress(event) {
		// Disallow any invalid key presses.
		if (!this.addressRegEx.test(event.key)) {
			event.preventDefault();
		}
	}

	onKeyUp(event) {
		// Validate address range for given controller type.
		if (!this.validateAddressRange()) {
			const message = this.formValidationService.rbMultiRangeErrorString(this.colDef.headerName, this.valueRange, this.isHexAddress);
			this.showInvalidFieldDialog(message);
			this.textInput.nativeElement.select();
		}
	}

	// Override base class method
	onKeyDown(event) {
		// Handle async field validation if configured and user is attempting to navigate away.
		if (this.hasFieldValidation && this.isNavigationKey(event.key)) {
			event.stopPropagation();
			this.validateAndNavigate(this.getAddressInt(), event.key, event.shiftKey);
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getAddressInt(): number {
		return !this.isHexAddress
			? +this.textInput.nativeElement.value
			: RbUtils.Stations.addressFromString(this.textInput.nativeElement.value, this.params.data.parentSatelliteType);
	}

	private getAddressString(addressInt: number): string {
		return RbUtils.Stations.addressString(addressInt, this.params.data.parentSatelliteType);
	}

	private validateAddressRange(): boolean {
		const newValue = this.getAddressInt();

		// RB-8013: The address range is an array of {min, max} values. Addresses are valid if they fall in any of the ranges.
		const valid = this.valueRange.some((r) => (newValue >= r.min && newValue <= r.max));

		// RB-8013: If not valid, set to a nearby range endpoint (this is harder now that we can have multiple valid ranges!)
		// We choose the first range to make this decision, as that's normally the primary range. For example, for ICM
		// this would be the 0-0xC0FFFF range.
		if (!valid && newValue < this.valueRange[0].min) {
			this.textInput.nativeElement.value = !this.isHexAddress ? this.valueRange[0].min : this.getAddressString(this.valueRange[0].min);
		}
		if (!valid && newValue > this.valueRange[0].max) {
			this.textInput.nativeElement.value = !this.isHexAddress ? this.valueRange[0].max : this.getAddressString(this.valueRange[0].max);
		}

		return valid;
	}

}

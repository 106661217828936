<div class="custom-station-area-style-container gray">
    <form [formGroup]="editForm">
		<div class="fill-color-area" #fillColorArea>
			<rb-color-picker 
				class="fill-color" formControlName="fillColor" [label]="translate.instant('STRINGS.FILL_COLOR')"
				[position]="deviceManager.isMobile ? 'top' : 'auto'"
				[positionOffset]="0"
				[presetColors]="presetColors">
			</rb-color-picker>
			<div class="fill-opacity">
				<mat-form-field>
					<input formControlName="fillOpacity" matInput type="number" min="0" max="100" required
						[placeholder]="'STRINGS.FILL_OPACITY' | translate" (focus)="$any($event.target).select()"/>
					<span matSuffix> %</span>
				</mat-form-field>
			</div>
		</div>
		<div *ngIf="fillOpacityErrorMessage" class="fill-opacity-error warnColorText">{{fillOpacityErrorMessage | translate}}</div>

		<div class="line-color-area">
			<div class="lca-row">
				<rb-color-picker 
					class="line-color"
					formControlName="lineColor"
					[label]="translate.instant('STRINGS.LINE_COLOR')"
					[position]="deviceManager.isMobile ? 'top' : 'auto'"
					[positionOffset]="0"
					[presetColors]="presetColors">
				</rb-color-picker>
				<div class="line-opacity">
					<mat-form-field>
						<input formControlName="lineOpacity" matInput type="number" min="0" max="100" required
							[placeholder]="'STRINGS.LINE_OPACITY' | translate" (focus)="$any($event.target).select()"/>
						<span matSuffix> %</span>
					</mat-form-field>
				</div>
			</div>
			<mat-form-field class="line-stroke">
				<input formControlName="lineWidth" matInput type="number" min="0" max="10" required
					[placeholder]="'STRINGS.LINE_STROKE' | translate" (focus)="$any($event.target).select()"/>
			</mat-form-field>
			<div class="mt-1">
				<div *ngIf="lineOpacityErrorMessage" class="warnColorText">{{lineOpacityErrorMessage | translate}}</div>
				<div *ngIf="lineWidthErrorMessage" class="warnColorText">{{lineWidthErrorMessage | translate}}</div>
			</div>
		</div>
		<div class="custom-station-area-buttons">
			<button mat-raised-button type="button" class="rb-primary-button" color="primary" [disabled]="!editForm?.valid || !editForm?.dirty" (click)="saveStationAreaStyles()">
				{{'STRINGS.SAVE' | translate}}
			</button>
			<button mat-button type="button" class="rb-secondary-button" color="primary" (click)="cancelEditStationAreaStyle()">
				{{'STRINGS.CANCEL' | translate}}
			</button>
		</div>
	</form>
</div>

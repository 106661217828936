import { Component, Injector, OnDestroy } from '@angular/core';
import { ConversionService } from '../../../../../common/services/conversion.service';
import { Duration } from 'moment';
import { GolfSensorListItem } from '../../../../../api/sensors/models/golf-sensor-list-item.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { SensorManagerService } from '../../../../../api/sensors/sensor-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { UnitLabelService } from '../../../../../common/services/unit-label.service';

/**
 * GolfSensorStatusCellRendererComponent shows the sensor status, depending on its type, appropriately for list display
 * of the sensor (Sensors tab, etc.), for Golf.
 */
@Component({
	selector: 'rb-golf-sensor-status-cell-renderer',
	templateUrl: './golf-sensor-status-cell-renderer.component.html',
	styleUrls: ['./golf-sensor-status-cell-renderer.component.scss']
})
export class GolfSensorStatusCellRendererComponent implements ICellRendererAngularComp, OnDestroy {

	// =========================================================================================================================================================
	// C'tor and lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected injector: Injector) { }
	golfSensorListItem: GolfSensorListItem;

	get sensorManager(): SensorManagerService {
		if (this._sensorManager == null) {
			this._sensorManager = this.injector.get(SensorManagerService);
		}
		return this._sensorManager;
	}
	private _sensorManager: SensorManagerService;

	get translateManager(): TranslateService {
		if (this._translateManager == null) {
			this._translateManager = this.injector.get(TranslateService);
		}
		return this._translateManager;
	}
	private _translateManager: TranslateService;

	get conversionService(): ConversionService {
		if (this._conversionService == null) {
			this._conversionService = this.injector.get(ConversionService);
		}
		return this._conversionService;
	}
	private _conversionService: ConversionService;

	get unitLabelService(): UnitLabelService {
		if (this._unitLabelService == null) {
			this._unitLabelService = this.injector.get(UnitLabelService);
		}
		return this._unitLabelService;
	}
	private _unitLabelService: UnitLabelService;

	/**
	* Return the golf rainfall string for Rain Watch, in the user's profile units (mm or inch).
	*/
	getGolfSensorRainfallString(rainfall: number): any {
		// Access the sensor manager to get the string.
		return this.sensorManager.getGolfSensorRainfallString(rainfall);
	}

	ngOnDestroy(): void {
		/** Required by untilDestroyed() */
	}

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.golfSensorListItem = params.data;
		this.updateStationStatus(params);
	}

	refresh(params: any): boolean {
		this.golfSensorListItem = params.data;
		this.updateStationStatus(params);
		return true;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	// Method to flash cell when sensor status changes occur.
	private updateStationStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		params.api.flashCells({
			rowNodes: [rowNode],
			columns: ['statusGridDisplay', 'status']
		});
	}

	/**
	 * Utility returning string formatted duration. We need to handle times greater than one day, as the user might set
	 * her shutdown time to two days or 36 hours, etc.
	 */
	durationFormat(duration: Duration): string {
		return this.sensorManager.durationFormatString(duration);
	}

	/**
	 * Called to generate a cell value when the sensor does not have rainWatchData. This should indicate the sensor value
	 * and units. For FlowSensor, we convert the incoming sensor value from GPM to the user's chosen units and display
	 * that with the units. For other sensor types, we just grab the sensor value and units directly from the status
	 * message.
	 */
	getFlowOrProgrammableSensorStatus(item: GolfSensorListItem): string {
		return RbUtils.Sensor.getSensorStatus(this.conversionService, this.translateManager, this.unitLabelService, item);
	}
	/**
	 * @summary Return the list of active indicators for conditions set for the specified golf sensor list item. This list might
	 * be empty, if no indicators are active.
	 * @param item - GolfSensorListItem for which active indicators are to be returned.
	 * @returns index for each active indicator. index is normally used to pick how the active indicator is rendered.
	 */
	getActiveIndicators(item: GolfSensorListItem): number[] {
		return item.programmableSensorActiveIndicators;
	}
}

import * as moment from 'moment';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../../api/culture-settings/culture-settings-manager.service';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

@UntilDestroy()
@Component({
	selector: 'rb-input-time-picker',
	templateUrl: './input-time-picker.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputTimePickerComponent),
			multi: true
		}
	]
})
export class InputTimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

	constructor(private authManager: AuthManagerService, private cultureSettingsManager: CultureSettingsManagerService) { }

	@Input() hintText: string;
	@Input() inputValue = '';
	@Input() name = '';
	@Output() dialogDisplayed = new EventEmitter<boolean>();
	@Input() useMatFormField = true;
	@Input() qaidLabel = '';

	cultureSettings: CultureSettings;
	format: number;
	locale: string;
	timePickerDisplay: string;
	timePickerValue: any;
	timePickerValueString: string;
	isReadOnly = false;

	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => { };
	private onTouched = () => { };

	ngOnInit(): void {
		this.isReadOnly = this.authManager.isReadOnly;
		this.setupTimeFormats();

		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe(() => this.setupTimeFormats());
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	get value(): string { return this.inputValue; }

	writeValue(value: any): void {
		this.updateTimePickerValue(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onTimeUpdate(time: any) {
		this.onTouched();
		if (time) {
			if (time.substring(0, 2) === '24') time = '00' + time.substring(2);
			if (moment(time, 'HH:mm:ss').isValid) {
				const startDate = (this.cultureSettings.timeFormatId === RbEnums.Common.TimeFormat.AmPm) ?
				RbUtils.Common.convertTimeStringToDate(time) : moment(time, 'HH:mm:ss');
				this.timePickerValue = startDate;
			}
		} else {
			this.timePickerValue = null;
		}
		this.updateTimeString();
		this.onChange(this.timePickerValue);
	}

	private updateTimePickerValue(time: any) {
		this.onTouched();
		if (!time) return;
		const startDate = moment(time, 'HH:mm:ss');
		this.timePickerValue = startDate;
		this.updateTimeString();
	}

	selectChanged(time) {
		this.onTimeUpdate(time);
	}

	onStartTimeOpened() {
		this.dialogDisplayed.emit(true);
	}

	onStartTimeClosed() {
		this.dialogDisplayed.emit(false);
	}

	private setupTimeFormats() {
		this.cultureSettings = this.authManager.userCulture;
		if (this.cultureSettings == null) {
			// Loop until there is a valid culture setting
			setTimeout(() => this.setupTimeFormats(), 200);
			return;
		}

		this.timePickerDisplay = RbUtils.Common.getTimePickerDisplayValue(this.cultureSettings);
		this.format = RbUtils.Common.getTimePickerFormat(this.cultureSettings);
		this.locale = this.authManager.getUserProfile().locale; //
		this.updateTimeString();
	}

	private updateTimeString() {
		this.timePickerValueString = this.timePickerValue == null ? '' : moment(this.timePickerValue).format(this.timePickerDisplay);
	}
}

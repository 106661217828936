<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-dialog
	[title]="title"
	[closeButtonLabel]="'STRINGS.CANCEL' | translate"
	[submitButtonLabel]="'STRINGS.SYNCHRONIZE' | translate"
	[showSubmitButton]="true"
	[closeOnSubmit]="true"
	(onSubmit)="onSave()"
	(onCancel)="onCancel()"
>

    <mat-radio-group labelPosition="after" class="rb-radio-group"
		(change)="onSyncPromptChange($event)">
        <div>
		    <mat-radio-button color="primary" class="rb-radio-button" [value]="true" [checked]="true">{{ 'SPECIAL_MSG.SYNC_AND_STOP_IRRIGATION' | translate }}</mat-radio-button>
        </div>
        <div >
            <mat-radio-button color="primary" class="rb-radio-button" [value]="false">{{ 'SPECIAL_MSG.DO_NOT_SYNC_IF_IRRIGATION' | translate }}</mat-radio-button>
        </div>
    </mat-radio-group>

</rb-dialog>

<div class="mobile-diagnostics-filter-components">

	<div class="selection-container">
		<mat-select [placeholder]="'STRINGS.TEST_TYPE' | translate"
		panelClass="station-mobile-filter"
		[disabled]="isSelectTestTypeDisabled" [ngModel]="testTypeId" (ngModelChange)="changeTestType($event)">
			<mat-option *ngFor="let testType of testTypes" [value]="testType.id">{{testType.name}}</mat-option>
		</mat-select>
	</div>
</div>

<div class="mobile-diagnostics-filter-buttons">
	<button class="run-test" [disabled]="isStartButtonDisabled" (click)="onRunButtonClicked()">{{'STRINGS.RUN_TEST_UPPERCASE' | translate}}</button>
	<button class="run-test cancel" [disabled]="!cancelButtonEnabled" (click)="onCancelButtonClicked()">{{'STRINGS.CANCEL_TEST_UPPERCASE' | translate | uppercase}}</button>
</div>
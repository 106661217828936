import { MapInfo } from './map-info.model';
import { SiteManagerService } from '../../api/sites/site-manager.service';
import { UiSettingsService } from '../../api/ui-settings/ui-settings.service';

import Timer = NodeJS.Timer;

export class MapInfoPreferences {

	private static defaultSitePreferences: any = {
		visibility: {
			showingAlerts: true,
			showingAreas: true,
			showingHoles: true,
			showingIrrigation: true,
			showingStations: true,
			showingSensors: true,
			showingControllers: true,
			showingStickyNotes: false,
			textColor: '#FFF',
			moveable: false,
		}
	};

	sitePreferences: any = MapInfoPreferences.defaultSitePreferences;
	settingPreferences = false;

	private savePreferencesTimer: Timer;

	constructor(private mapInfo: MapInfo, private uiSettingsService: UiSettingsService) { }

	load(): void {
		this.uiSettingsService.getPreference(this.sitePreferenceId).subscribe(sitePreferences => {
			this.sitePreferences = this.safeVisibilitySettings(sitePreferences || MapInfoPreferences.defaultSitePreferences);
		});
	}

	save(): void {
		if (this.savePreferencesTimer != null) clearTimeout(this.savePreferencesTimer);
		if (this.settingPreferences) return;
		const map = this.mapInfo.map;

		// Prevent saving preferences many times in response to map updates
		this.savePreferencesTimer = setTimeout(() => {
			this.savePreferencesTimer = null;
			const center = map.getCenter();
			this.sitePreferences.center = {
				latitude: center.lat(),
				longitude: center.lng()
			};
			this.sitePreferences.zoom = map.getZoom();
			this.sitePreferences.heading = map.getHeading();
			this.sitePreferences.tilt = map.getTilt();

			this.uiSettingsService.setPreference(this.sitePreferenceId, this.sitePreferences).subscribe();
		}, 500);
	}

	private get sitePreferenceId(): string {
		return `${SiteManagerService.MAP_PREFERENCE_KEY_BASE}_${this.mapInfo.siteId}_${this.mapInfo.uniqueId}`;
	}

	/**
	 * Because we've changed the layout of the visibility settings in our site preferences, we need to be sure that some
	 * user, upgrading from an earlier version where settings were saved, will not get a bunch of errors because we're
	 * assuming that this.sitePreferences.visibility is present and has the expected format. This is a central location
	 * to clean up/default any missing or reformatted site preferences.
	 */
	private safeVisibilitySettings(sitePreferences: any): any {
		// Use the default visibility settings as a model, adding any that the user's data is missing. If the user data
		// is present, ignore that property. This is done with the spread operator. Note that when there's a collision,
		// the right-most object in the list wins.
		const ret = { ...MapInfoPreferences.defaultSitePreferences, ...sitePreferences };
		ret.visibility = { ...MapInfoPreferences.defaultSitePreferences.visibility, ...sitePreferences.visibility };
		ret.visibility.moveable = false;
		return ret;
	}
}

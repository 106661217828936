import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReportCoverPageComponent } from './common/report-cover-page/report-cover-page.component';
import { ReportCoverPageService } from './common/report-cover-page/report-cover-page.service';
import { ReportCsvService } from './common/csv/report-csv.service';
import { ReportPdfService } from './common/pdf/report-pdf.service';

@NgModule({
    declarations: [
        ReportCoverPageComponent,
    ],
    imports: [
        CommonModule,
    ],
    providers: [
        ReportCoverPageService,
        ReportCsvService,
        ReportPdfService,
    ],
    exports: []
})
export class ReportsSharedModule {}

import { ApiService, HttpMethod } from '../_common/api.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CCWeatherData } from './models/ccweather-data.model';
import { CreateWeatherForecastLocation } from './models/ccweather-create-weather-forecast-location.model';
import { Injectable } from '@angular/core';
import { WeatherForecastLocationOutput } from './models/ccweather-weather-forecast-location-output.model';

@Injectable({
	providedIn: 'root'
})
export class CCWeatherApiService extends ApiService {
	private get baseUrl(): string {
		return `${this.baseApiUrl}WeatherForecast`;
	}

	/**
	 * Get location described by locationId value. NOTE: IF NOT FOUND, RATHER THAN FORWARDING AN ERROR, NULL WILL BE PASSED THROUGH THE
	 * OBSERVABLE PIPE.
	 * @param locationId - number Id of location
	 * @returns Observable(WeatherForecastLocationCreateModel) or Observable(null), if error occurs.
	 */
	getLocation(locationId: number): Observable<CreateWeatherForecastLocation> {
		return this.apiRequest<CreateWeatherForecastLocation>(this.getLocationUrl(locationId), HttpMethod.Get)
			.pipe(
				catchError(err => of(null)),
			);
	}

	/**
	 * Create a new location/widget. If the same geographical location already is found in the API, the new widget entry will be created
	 * and linked to the existing location. If the location does not exist it will be created and the cloud weather forecast service will
	 * be notified to start tracking that location.
	 * @param locationData - CreateWeatherForecastLocation describing the site, lat/long, elevation, time zone, etc. for the new widget.
	 * @returns WeatherForecastLocationOutput containing the widgetId and locationId created (or linked)
	 */
	createLocation(locationData: CreateWeatherForecastLocation): Observable<WeatherForecastLocationOutput> {
		return this.apiRequest<WeatherForecastLocationOutput>(this.createLocationUrl(), HttpMethod.Post, locationData).pipe();
	}

	updateLocation(databaseWidgetId: number, locationData: CreateWeatherForecastLocation): Observable<any> {
		return this.apiRequest<any>(this.updateLocationUrl(databaseWidgetId), HttpMethod.Post, locationData).pipe();
	}

	deleteWidget(databaseWidgetId: number): Observable<any> {
		return this.apiRequest<any>(this.deleteWidgetUrl(databaseWidgetId), HttpMethod.Post).pipe();
	}

	getForecast(databaseWidgetId: number, forced: boolean): Observable<CCWeatherData[]> {
		return this.apiRequest<any>(this.getForecastUrl(databaseWidgetId, forced), HttpMethod.Get).pipe(map(response => response));
	}

	getWidgetByWeatherSourceId(weatherSourceId: number): Observable<any> {
		return this.apiRequest<any>(this.getWidgetByWeatherSourceIdUrl(weatherSourceId), HttpMethod.Get);
	}

	updateGolfForecasts(): Observable<any> {
		return this.apiRequest<any>(this.updateGolfForecastsUrl, HttpMethod.Get);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private getLocationUrl(databaseLocationId: number): string {
		return `${this.baseUrl}/GetLocation?locationId=${databaseLocationId}`;
	}

	private createLocationUrl(): string {
		return `${this.baseUrl}/CreateLocation`;
	}

	private updateLocationUrl(databaseWidgetId: number): string {
		return `${this.baseUrl}/UpdateLocation?widgetId=${databaseWidgetId}`;
	}

	private deleteWidgetUrl(databaseWidgetId: number): string {
		return `${this.baseUrl}/DeleteWidget?widgetId=${databaseWidgetId}`;
	}

	private getForecastUrl(databaseWidgetId: number, forced: boolean): string {
		return `${this.baseUrl}/GetForecast?widgetId=${databaseWidgetId}&forceUpdate=${forced}`;
	}

	private getWidgetByWeatherSourceIdUrl(weatherSourceId: number): string {
		return `${this.baseUrl}/GetWidgetByWeatherSourceId?weatherSourceId=${weatherSourceId}`;
	}

	private get updateGolfForecastsUrl(): string {
		return `${this.baseUrl}/UpdateForecasts`;
	}
}

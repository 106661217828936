import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { BreadcrumbRoute } from '../breadcrumb/breadcrumb-route.model';
import { MatMenuPanel } from '@angular/material/menu';
import { RbUtils } from '../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-mobile-sidebar-header',
	templateUrl: './mobile-sidebar-header.component.html',
	styleUrls: ['./mobile-sidebar-header.component.scss']
})
export class MobileSidebarHeaderComponent implements OnInit, OnDestroy {
	@Input() breadcrumbRoute: BreadcrumbRoute;
	@Input() includeSettings = false;
	@Input() includeCsv = false;
	@Input() includePdf = false;
	@Input() includeShare = false;
	@Input() overflowMenu: MatMenuPanel;
	@Input() showOverflowButton = false;
	@Input() suppressAutoBackNavigation = false;
	@Input() title: string = null;
	@Input() hideBackArrow = false;

	@Output() backClick = new EventEmitter();
	@Output() settingsClick = new EventEmitter();
	@Output() csvClick = new EventEmitter();
	@Output() pdfClick = new EventEmitter();
	@Output() shareClick = new EventEmitter();

	isGolfSite = false;

	constructor(private authManager: AuthManagerService) { }

	ngOnInit() {
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
	}

	ngOnDestroy(): void {
	}

	onBackClick() {
		this.backClick.emit();
	}

	onSettingsClick() {
		this.settingsClick.emit();
	}

	onPdfClick() {
		this.pdfClick.emit();
	}

	onCsvClick() {
		this.csvClick.emit();
	}

	onShareClick() {
		this.shareClick.emit();
	}
}

import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AppDateAdapter } from '../../../../custom/date-adapter';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { ProgramGroupListItem } from '../../../../../api/program-groups/models/program-group-list-item.model';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

@Component({
	selector: 'rb-program-group-mobile-cell-renderer',
	templateUrl: './program-group-mobile-cell-renderer.component.html',
	styleUrls: ['./program-group-mobile-cell-renderer.component.scss']
})
export class ProgramGroupMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	programGroup: ProgramGroupListItem;
	_statusString: string;
	isRunning = false;

	private appDateAdapter: AppDateAdapter;

	constructor(injector: Injector) {
		super(injector);
		this.appDateAdapter = injector.get(AppDateAdapter);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.programGroup = params.data;
		this.updateProgramGroupStatus(params);
	}

	refresh(params: any): boolean {
		this.programGroup = params.data;
		this.updateProgramGroupStatus(params);
		return true;
	}

	get statusString(): string {
		return this._statusString;
	}

	get startTimes(): string {
		const sortedStartTimes = this.programGroup.sortedStartTimes;
		if (sortedStartTimes.length === 0) return '-';

		return sortedStartTimes.map(st => this.appDateAdapter.formatTime(new Date(st))).join(', ');
	}

	get adjust(): string {
		const tempAdjustDays = this.programGroup.tempAdjustDays;
		if (tempAdjustDays > 0)
			return this.programGroup.tempProgramGroupAdjust + '%' + ' ' + '(' + (tempAdjustDays + 'd') + ')';
		else
			return this.programGroup.programGroupAdjust + '%';
	}

	private updateProgramGroupStatus(params: any): void {
		const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);

		this._statusString = params.data.status;
		this.isRunning = false;

		switch (this._statusString) {
			case '-':
				if (rowNode.data.isEnabled === false)
					this._statusString = this.translate.instant('STRINGS.DISABLED');
				break;

			case RbUtils.Translate.instant(`STRINGS.RUNNING`):
				this.isRunning = true;
				this._statusString = RbUtils.Translate.instant(`STRINGS.RUNNING`);
				params.api.flashCells({
					rowNodes: [rowNode],
				});
				break;
		}
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} << </div>

<div class="rb-sidebar-header-container">

	<header class="rb-dialog--header rb-sidebar-header">

		<h1 mat-dialog-title class="secondaryColor">
			<span *ngIf="showBackButton">
				<i class="icon-back icon-direction-arrow" (click)='goBack()'></i>
			</span>
			<span *ngIf="title">{{ title }}</span>
		</h1>

		<div class="rb-sidebar-header-buttons" [ngClass]="{'rb-sidebar-header-no-title': !title}">
			<span *ngIf="showFavButton" class="fav-icon" (click)="isFavorite = !isFavorite" >
				<i class="material-icons">{{isFavorite ? 'star' : 'star_border'}}</i>
			</span>

			<button *ngIf="showHelpButton && helpContent" mat-icon-button class="blue"
				[matMenuTriggerFor]="helpContent">
				<mat-icon class="mat-icon-color">info_outline</mat-icon>
			</button>

			<span *ngIf="showStatusButton" class="status-button" [style.backgroundColor]="statusColor"></span>

			<button *ngIf="showNotesButton" mat-icon-button class="white"
				[matTooltip]="'STRINGS.SHOW_NOTES' | translate" (click)="showNotes()">
				<i class="mdi mdi-message-processing-outline icon-s-24"></i>
			</button>

			<button *ngIf="showDiagnosticButton" mat-icon-button class="white"
				[matTooltip]="'STRINGS.SHOW_STATION_DIAGNOSTIC' | translate" (click)="showDiagnostic()">
				<i class="mdi mdi-help-network-outline icon-s-24"></i>
			</button>

			<button *ngIf="showSearchButton" mat-icon-button class="white" [matTooltip]="matToolTip | translate"
				(click)="onSearch()">
				<mat-icon class="mat-icon-color">search</mat-icon>
			</button>

			<button *ngIf="showOverflowButton && overflowMenu" mat-icon-button class="white"
				[matMenuTriggerFor]="overflowMenu">
				<mat-icon class="mat-icon-color">more_vert</mat-icon>
			</button>
		</div>
	</header>

	<!-- Placeholder for MatMenu (overflow menu)-->
	<ng-content></ng-content>

</div>
<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div id="dry-run-toolbar-container" [ngClass]="{'drt-mobile': isMobile, 'isListView': dryRunView === dryRunViewType.List}">
    <div id="drt-main-wrapper">
        <div class="view-type-selection-container" *ngIf="!isMobile">
            <rb-icon-button
                [label]="'STRINGS.CHART' | translate"
                icon="equalizer"
                class="dr-view-type"
                [disabled]="isChartViewDisabled"
                [ngClass]="{'dr-view-selected': dryRunView === dryRunViewType.Chart}"
                (click)="onDryRunViewChange(dryRunViewType.Chart)"
                data-qaid="drt-chart-button"
            ></rb-icon-button>
        </div>
        
        <div class="view-type-selection-container" *ngIf="!isMobile && showScheduleViewButton">
            <rb-icon-button 
                [label]="'STRINGS.SCHEDULE_NOUN' | translate"
                icon="pending_actions"
                class="dr-view-type"
                [disabled]="isScheduleViewDisabled"
                [ngClass]="{'dr-view-selected': dryRunView === dryRunViewType.Schedule}"
                (click)="onDryRunViewChange(dryRunViewType.Schedule)"
                [customIconNumber]="customIconNumber"
                data-qaid="drt-schedule-button"
            ></rb-icon-button>
        </div>

        <div class="view-type-selection-container" *ngIf="!isMobile">
            <rb-icon-button
                [label]="'STRINGS.LIST' | translate"
                icon="format_list_bulleted"
                class="dr-view-type"
                [ngClass]="{'dr-view-selected': dryRunView === dryRunViewType.List}"
                (click)="onDryRunViewChange(dryRunViewType.List)"
                data-qaid="drt-list-button"
            ></rb-icon-button>
            <mat-checkbox *ngIf="dryRunView === dryRunViewType.List"
                [(ngModel)]="includeStation"
                class="dr-view-type pt2"
                data-qaid="drt-include-station-CB"
            >
                {{'STRINGS.INCLUDE_STATION' | translate}}
            </mat-checkbox>
        </div>

        <div class="view-type-selection-container" *ngIf="!isMobile && isGolfSite">
            <rb-icon-button
                    [label]="'STRINGS.COURSE' | translate"
                    icon="golf_course"
                    class="dr-view-type"
                    [disabled]="disabled"
                    [ngClass]="{'dr-view-selected': dryRunView === dryRunViewType.Course}"
                    (click)="onDryRunViewChange(dryRunViewType.Course)"
                    data-qaid="drt-course-button"
            ></rb-icon-button>
        </div>


        <div id="date-selection-container" [ngClass]="{'isListView': dryRunView === dryRunViewType.List, 'isIq4': !isGolfSite}">
            <rb-dry-run-date-time-selector
                    [dateRanges]="dayOptions"
                    [selectedDateRange]="DATE_RANGE.oneDay"
                    [forceMobileFilter]="isMobile"
                    [(isMobileFilterDisplayed)]="isMobileFilterDisplayed"
                    (dateRangeChange)="onDateRangeChange($event)"
                    data-qaid="drt-date-time-selector"
            ></rb-dry-run-date-time-selector>
        </div>

        <div class="view-type-selection-container" *ngIf="!isMobile && isGolfSite">
            <mat-checkbox 
                          [(ngModel)]="showPumps"
                          class="dr-view-type pt2"
						  data-qaid="drt-show-pumps"
            >
            {{ 'STRINGS.SHOW_SEPARATE_PUMPS' | translate }}
            </mat-checkbox>
        </div>
        
        

        <div id="drt-run-btn-container">
            <rb-report-button
                    [label]="'STRINGS.RUN_UPPERCASE' | translate"
                    [disabled]="isRunButtonDisabled || isMobileFilterDisplayed"
                    (reportButtonClicked)="onRunClick()"
					data-qaid="drt-run-button"
            ></rb-report-button>
        </div>
    </div>

    <div id="drt-print-btn-container">
        <button mat-button *ngIf="!hidePrintButton"
                id="drc-view-pdf-btn"
                [ngClass]="{'show-border': !isMobile}"
                [disabled]="!isPrintEnabled || isMobileFilterDisplayed"
                (click)="generatePdf()"
				data-qaid="drt-view-pdf-btn"
        >
            <mat-icon *ngIf="isMobile">picture_as_pdf</mat-icon>
            <span *ngIf="!isMobile">{{ 'STRINGS.VIEW_PDF' | translate }}</span>
        </button>
    </div>

</div>

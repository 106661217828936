import * as lodash from 'lodash';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { MapInfoLeaflet } from '../../../common/models/map-info-leaflet.model';
import { MessageBoxInfo } from '../global-message-box/message-box-info.model';
import { Note } from '../../../api/sticky-notes/models/sticky-note.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { StickyNoteManagerService } from '../../../api/sticky-notes/sticky-note-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile } from '../../../api/auth/models/user-profile.model';

@UntilDestroy()
@Component({
   selector: 'rb-note-popup',
   templateUrl: './note-popup.component.html',
   styleUrls: ['./note-popup.component.scss']
})
export class NotePopupComponent implements OnInit, OnDestroy {

   @Output() invokeCreateNote = new EventEmitter<Note>();
   @Output() invokeCreateUser = new EventEmitter<any>();
   @Output() noteDeleted = new EventEmitter<Note>();
   @Output() noteLocationClicked = new EventEmitter<{ event: any, note: Note }>();

   NoteAnchor = RbEnums.Note.NoteAnchor;
   NoteLayout = RbEnums.Note.NoteLayout;

   selectedNote: Note;
   noteTemplate: Note = {
      id: 0,
      companyId: 0,
      siteId: 0,
      authorId: 0,
      latitude: 0,
      longitude: 0,
      content: '',
      isMinimized: true,
      notePriority: 1,
      noteType: 1,
      status: RbEnums.Note.NoteStatus.Active,
      attachedToType: RbEnums.Note.NoteAnchor.Note, // Default to Single Note
      isEditing: false,
      replies: []
   }

   notesSubscription: Subscription;
   filteredNotes: Note[] = [];
   usersSubscription: Subscription;

   // Dialog Data
   mapInfo: MapInfoLeaflet;
   siteId: number;
   user: UserProfile;
   isWidget = false;
   isGolfSite = false;

   constructor(
      @Inject(MAT_DIALOG_DATA) public dialogData: any,
      private ref: MatDialogRef<NotePopupComponent>,
      private notesManager: StickyNoteManagerService,
      public broadcastService: BroadcastService,
      private route: ActivatedRoute,
      private router: Router,
      public translate: TranslateService
   ) {
      this.siteId = this.route.snapshot.queryParams.siteId;
      if (this.dialogData.siteId) {
         this.siteId = this.dialogData.siteId;
      }
      // We use the cloneDeep function to keep the reference of the original note/template intact.
      if (this.dialogData.note) {
         this.selectedNote = lodash.cloneDeep(this.dialogData.note);
         this.siteId = this.selectedNote.siteId;
      } else {
         this.selectedNote = lodash.cloneDeep(this.noteTemplate);
      }
      if (this.dialogData.mapInfo) {
         this.mapInfo = this.dialogData.mapInfo;
      }
      if (this.dialogData.isWidget) {
         this.isWidget = this.dialogData.isWidget;
      }
      if (this.dialogData.isGolfSite) {
         this.isGolfSite = this.dialogData.isGolfSite;
      }
      if (this.dialogData.user) {
         this.user = this.dialogData.user;
      }
   }

   async ngOnInit() {
      this.notesSubscription = this.notesManager.notes$.pipe(untilDestroyed(this))
      .subscribe({
            next: (notes: []) => {
            this.filteredNotes = notes;
            this.setReplies();
         },
         error: (error) => { throw error },
         complete: () => {}
      })
   }

   ngOnDestroy(): void {
      this.notesSubscription.unsubscribe();
   }

   close() {
      this.ref.close({ replies: this.selectedNote.replies });
   }

   getUserInitials(name: string) {
      const nameSegments = name.split(" ");
      switch (nameSegments.length) {
         case 1:
            return nameSegments[0].charAt(0).toUpperCase();
         case 2:
            return (nameSegments[0].charAt(0) + nameSegments[1].charAt(0)).toUpperCase();
         case 3:
         case 4:
            return (nameSegments[0].charAt(0) + nameSegments[2].charAt(0)).toUpperCase();
         default:
            return '';
      }
   }

   prepareNotes(notes: Note[]) {
      this.filteredNotes = notes;
      this.setReplies();
   }

   setReplies() {
      const attachedToType = this.selectedNote.attachedToType;
      const sortCriteria = (a, b) => <any>new Date(b.createdDateUtc) - <any>new Date(a.createdDateUtc);
      switch (attachedToType) {
         case RbEnums.Note.NoteAnchor.Note:
            this.selectedNote.replies = this.filteredNotes
               .filter(note => note.attachedToType === RbEnums.Note.NoteAnchor.Note &&
                  note.attachedToId === this.selectedNote.id && note.status === RbEnums.Note.NoteStatus.Active).sort(sortCriteria)
            break;
         default:
            this.selectedNote.replies = this.filteredNotes
               .filter(note => note.attachedToType === this.selectedNote.attachedToType &&
                  note.attachedToId === this.selectedNote.id && note.status === RbEnums.Note.NoteStatus.Active).sort(sortCriteria)
            const pinnedNote = this.selectedNote.replies.find(note => note.isPinned);
            if (pinnedNote) {
               this.selectedNote.replies = this.selectedNote.replies.filter(reply => reply.id !== pinnedNote.id).sort(sortCriteria);
               this.selectedNote.replies.unshift(pinnedNote);
            }
            break;
      }
   }

   /*
     This method creates/updates the current note without the isEditing property (being a UI-only property)
     and replies (those can be modified separately).
   */
   saveNote(note: Note) {
      
      let savedReplies: Note[];

      if (note.replies?.length) {
         savedReplies = [...note.replies];
      }
      delete note.replies;
      delete note.isEditing;
      const anchorName = note.attachedToName;
      delete note.attachedToName;
      const foundMyId = note.readBy?.find(id => id === this.user.userId);
      if (!foundMyId) {
         if (note.readBy) {
            note.readBy.push(this.user.userId)
         } else {
            note.readBy = [this.user.userId]
         }
      }
      if (note.attachedToType !== RbEnums.Note.NoteAnchor.Note && !note.attachedToId) {
         note.attachedToId = note.id;
         note.id = 0;
      }
      if (!note.id) {
         this.notesManager.createNote(note).subscribe({
            next: async (savedNote: Note) => {
               if (this.mapInfo) {
                  this.mapInfo.onNoteCreated(savedNote);
               }
               if (note.attachedToType === RbEnums.Note.NoteAnchor.Note) {
                  this.selectedNote = savedNote;
               } else {
                  // We again copy the original unmodified note to the selectedNote.
                  this.selectedNote = lodash.cloneDeep(this.dialogData.note);
                  if (savedReplies && savedReplies.length) {
                     this.selectedNote.replies = savedReplies;
                     this.selectedNote.replies.unshift(savedNote);
                  } else {
                     this.selectedNote.replies = [savedNote]
                  }
               }
               savedNote.attachedToName = anchorName;
               this.notesManager.broadcastNote(savedNote);
            },
            error: (e) => { throw e }
         });
      } else {
         this.notesManager.updateNote(note.id, note).subscribe({
            next: async (updatedNote: Note) => {
               note.isEditing = false;
               note.replies = savedReplies;
               if (note.attachedToType === RbEnums.Note.NoteAnchor.Note && this.mapInfo) {
                  this.mapInfo.updateMarkerForNote(note);
               }
               note.attachedToName = anchorName;
               this.notesManager.broadcastNote(note);
            },
            error: (e) => { throw e }
         })
      }
   }

   /* 
     This function changes the status of a note and its replies to Resolved and removes the marker from the Map.
   */
   resolveNote(note: Note) {
      let resolveNoteIds: number[] = [];

      switch (note.attachedToType) {
         case RbEnums.Note.NoteAnchor.Station:
            resolveNoteIds = note.replies?.map(reply => reply.id) || [];
            resolveNoteIds.push(note.id)
            break;

         default:
            if (note.replies && note.replies.length) {
               resolveNoteIds = [note.id, ...note.replies.map(reply => reply.id)];
            } else {
               resolveNoteIds = [note.id];
            }
            break;
      }

      const messageBoxInfo = MessageBoxInfo.create(
         this.translate.instant('STRINGS.RESOLVE'),
         this.translate.instant('STRINGS.RESOLVE_NOTE'),
         RbEnums.Common.MessageBoxIcon.None,
         async () => {
            for (const id of resolveNoteIds) {
               await this.waitFor(this.notesManager.resolveNote(id, { status: RbEnums.Note.NoteStatus.Resolved }));
            }
            if (this.mapInfo) {
               switch (note.attachedToType) {
                  case RbEnums.Note.NoteAnchor.Station:
                     const station = this.mapInfo.stations.find(s => s.id === note.attachedToId);
                     this.notesManager.notes$.pipe(untilDestroyed(this))
                     .subscribe({
                       next: (notes: Note[]) => {
                         if (notes.length) {
                           const siblings = notes.filter(n => n.attachedToId === station.id &&
                              n.status === RbEnums.Note.NoteStatus.Active &&
                              n.id !== note.id);
                           if (station && (!siblings || !siblings.length)) { this.mapInfo.removeNoteBadgeToStation(station) }
                         }
                       },
                       error: (error) => {
                        throw error
                       }
                     })
                     break;

                  default:
                     this.mapInfo.onNoteDeleted(note.attachedToId || note.id)
                     break;
               }
            }
            note.status = RbEnums.Note.NoteStatus.Resolved;
            if ((note.attachedToType !== RbEnums.Note.NoteAnchor.Note && !note.replies) ||
               note.attachedToType === RbEnums.Note.NoteAnchor.Note) {
               this.close()
            }
            this.notesManager.broadcastNote(note);
         },
         null,
         RbEnums.Common.MessageBoxButtons.YesNo
      );

      this.broadcastService.showMessageBox.next(messageBoxInfo);
   }

   onNoteLocationClicked(event: any, note: Note) {
      this.noteLocationClicked.emit({ event, note });
   }

   onNotePin(note: Note) {
      setTimeout(async () => {
         const noteIdx = this.filteredNotes.findIndex(n => n.id === note.id);
         this.filteredNotes[noteIdx] = note;
         this.setReplies();
      }, 10);
   }

   onInvokeRightPane(event: any) {

      if (!this.isWidget) {
         const messageBoxInfo = MessageBoxInfo.create(
            this.translate.instant('STRINGS.ADD_USER'),
            this.translate.instant('STRINGS.ADD_USER_FROM_NOTE_WARNING'),
            RbEnums.Common.MessageBoxIcon.None,
            async () => {
               this.invokeCreateUser.emit(event)
               this.close()
            },
            null,
            RbEnums.Common.MessageBoxButtons.YesNo
         );

         this.broadcastService.showMessageBox.next(messageBoxInfo);
      } else {
         const messageBoxInfo = MessageBoxInfo.create(
            this.translate.instant('STRINGS.ADD_USER_NOT_AVAILABLE'),
            this.translate.instant('STRINGS.ADD_USER_NOT_AVAILABLE_SUGGESTION'),
            RbEnums.Common.MessageBoxIcon.None,
            async () => {
               this.goToCourseMap();
               this.close()
            },
            null,
            RbEnums.Common.MessageBoxButtons.YesNo
         );

         this.broadcastService.showMessageBox.next(messageBoxInfo);
      }
   }

   goToCourseMap() {
      setTimeout(() => {
         const route = this.isGolfSite ? '/systemsetup/courseconfig/map' : '/systemsetup/siteconfig/map';
         this.router.navigate([route], { queryParams: { siteId: this.siteId } });
      }, 10);
   }

   waitFor<T>(obs: Observable<T>): Promise<T> {
      return new Promise(resolve => obs.subscribe(resolve));
   }

}
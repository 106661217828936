export class DryRunPumpDatum {

	constructor(id: number, name: string, flowRate:  string = '') {
		this.id = id;
		this.name = name;
		this.flowRate = flowRate;
	}

	id: number;
	name: string;
	flowRate: string;
}

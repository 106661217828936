<rb-dialog
	[title]="'STRINGS.SESSION_TIMEOUT' | translate"
	[closeButtonLabel]="'STRINGS.CONTINUE' | translate"
	[submitButtonLabel]="'STRINGS.LOG_OUT' | translate "
	[showSubmitButton]="true"
	(onSubmit)="handleLogout()"
	(onCancel)="handleContinue()">
	<form #timerOut="ngForm"
		  data-qaid="timer-countdown-form"
		  class="flex flex-column ph3">
		<label class="mr2 f7-ns f6-m f5-l">     {{'TIMER_COUNTDOWN.SESSION_EXPIRES' | translate}} </label>
		<label class="mr2 mb3 f7-ns f6-m f5-l">     {{countdownString}} </label>
		<label class="rb-small-label">     {{'TIMER_COUNTDOWN.COMMENT_CONTINUE' | translate}} </label>
		<label class="rb-small-label">     {{'TIMER_COUNTDOWN.COMMENT_LOGOUT' | translate}} </label>
	</form>
</rb-dialog>

<div class="rb-no-internet">
    <div class="center-context">
        <div class="icon-section">
            <mat-icon aria-label="cloud off" aria-hidden="true">cloud_off</mat-icon>
        </div>
        <div class="text-section">
            <div class="no-internet-text-title">{{'STRINGS.NO_INTERNET_TITLE' | translate}}</div>
            <div class="no-internet-text">{{'STRINGS.NO_INTERNET' | translate}}</div>
        </div>            
    </div>
</div>
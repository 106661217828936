<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="controller-mobile-cell flex flex-column">
	<div class="flex justify-between">
		<div class="ellipsis-text b w-50-m">{{deltaItem?.entityTypeString}}: {{deltaItem?.name}} </div>
		<div class="w-50-m">{{'STRINGS.IQ4_VALUE' | translate}}: {{deltaItem?.iq4Value}} </div>
	</div>
	<div class="flex justify-between">
		<div class="w-50-m ellipsis-text">Property: {{deltaItem?.property}} </div>
		<div class="w-50-m">{{'STRINGS.CONTROLLER_VALUE' | translate}}: {{deltaItem?.controllerValue}} </div>
	</div>
</div>

export class SiteCount {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	differencesCount: number;
	outOfSyncCount: number;
	shutdownCount: number;
	rainDelayCount: number;
	unackedAlarmCount: number;
	unackedWarningCount: number;
}

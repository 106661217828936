import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'rb-coming-soon',
	templateUrl: './coming-soon.component.html',
	styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

	@Input() displayText = 'STRINGS.COMING_SOON';
	@Input() displayTextPunctuation = '';

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor() { }

	ngOnInit() {
		if (this.displayText == null) { return ''; }
		if (this.displayTextPunctuation == null) { return ''; }
	}

	// =========================================================================================================================================================
	//
	// =========================================================================================================================================================

}

<div class="controller-grid-status-container" *ngIf="controllerListItem.firmwareUpdateProgress">

	<span class="fa-stack">
		<mat-icon class="fw-progress-icons fw-progress-icon-center">adjust</mat-icon>
		<mat-icon class="fw-progress-icons fw-progress-icon-track">panorama_fish_eye</mat-icon>
	</span>

	<mat-progress-spinner
		mode="determinate"
		[attr.color]="color"
		[value]="fwProgress"
		[title]="fwProgressTooltip"
	>
	</mat-progress-spinner>

</div>

<div *ngIf="!controllerListItem.firmwareUpdateProgress" [innerHTML]="controllerStatusCellRenderer"></div>

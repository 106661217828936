<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>
<rb-dialog
    data-qaid="start-controller-auto-connect-modal-dialog"
    [title]="'STRINGS.CONTROLLER_AUTO_CONNECT' | translate"
		[closeButtonLabel]="'STRINGS.CANCEL' | translate "
		[submitButtonLabel]="'STRINGS.START_UPPERCASE'| translate"
		[showSubmitButton]="true"
		[saveDisabled]="!isAbleToStart"
		(onSubmit)="onSetDuration()">
    <div class="start-controller-dialog">
        <rb-waiting-indicator
        *ngIf="unconnectedControllerIds.length"
        [text]="'STRINGS.CONNECTING_TO_CONTROLLER' | translate"
        [error]="loadError"
        (okClick)="onActionErrorAcknowledged()">
      </rb-waiting-indicator>
      <div *ngIf="connectControllersResult.length">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of connectControllersResult; index as i">
              <td>{{item.name}}</td>
              <td>{{item.statusMessage}}</td>
            </tr>
          </tbody>
          </table>
      </div>
    </div>
	</rb-dialog>
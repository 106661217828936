import { WeatherConditionIcon } from './weather-condition-icon.model';

export class WeatherConditionInfo {

	constructor(description: string, icon: WeatherConditionIcon) {
		this.description = description;
		this.icon = icon;
	}

	description: string;
	icon: WeatherConditionIcon;
}

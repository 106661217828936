<div class="rb-dashboard-widget-container" [ngClass]="{'light-theme': theme === 'light'}">
	<rb-dashboard-widget-toolbar [title]="title"
								 [subTitle]="subTitle"
								 [titleIcon]="titleIcon"
								 [toolbar]="toolbar"
								 [widgetId]="widgetId"
								 [summaryValue]="summaryValue"
								 [withRefresh]="withRefresh"
								 [withSettings]="withSettings"
								 [showSettings]="showSettings"
								 [hideRemoveWidget]='hideRemoveWidget'
								 [customDropDownOptions]="customDropDownOptions"
								 [wrapTitleLanguages]="wrapTitleLanguages"
								 (widgetRefresh)="onWidgetRefresh()"
								 (widgetSettings)="onWidgetSettings()"
	>
	</rb-dashboard-widget-toolbar>

	<div class="rb-dashboard-widget-content widget-non-draggable">
		<ng-content></ng-content>
	</div>

	<rb-waiting-indicator
		*ngIf="busy"
		[isVisible]="busy">
	</rb-waiting-indicator>
</div>

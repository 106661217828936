import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class ScheduledDiagnostic {

	id: number;
	companyId: number;
	startTime: string;
	testType: RbEnums.Common.ScheduledDiagnosticTestType;

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}
}

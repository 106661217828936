import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { ControllerListItem } from '../../../api/controllers/models/controller-list-item.model';
import { ControllerManagerService } from '../../../api/controllers/controller-manager.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { Sensor } from '../../../api/sensors/models/sensor.model';
import { Station } from '../../../api/stations/models/station.model';

@Component({
  selector: 'rb-tree-controller-list',
  templateUrl: './tree-controller-list.component.html',
  styleUrls: ['../../../../styles/components/_RBCC-tree-list.scss']
})

export class TreeControllerListComponent implements OnInit, OnChanges {
  @Input() mapInfo;
  @Input() map;
  @Input() dragDisabled: boolean;

  @Output() controllerClicked = new EventEmitter<{event: any, controller: ControllerListItem}>();
  @Output() sensorClicked = new EventEmitter<{event: any, sensor: Sensor}>();
  @Output() stationClicked = new EventEmitter<{event: any, station: Station}>();

  @Output() onDragMoved = new EventEmitter<CdkDragMove>();
  @Output() onDragEnded = new EventEmitter();

  public expandedControllerId = -1;
  public isMobile = false;
  public controllers;
  public showSpinner = false;

  constructor(
    private deviceManager: DeviceManagerService,
    private controllerManager: ControllerManagerService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceManager.isMobile;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if(changes.mapInfo?.currentValue?.controllers?.length > 0){
      this.controllers = changes.mapInfo.currentValue.controllers;
    }
  }

  expandController(controller: any) {
    this.showSpinner = true;
    this.expandedControllerId = this.expandedControllerId === controller.id ? -1 : controller.id;
    if (this.expandedControllerId === -1) return;
    this.controllerManager.getControllerItem(controller.id)
      .subscribe((controllerItem: ControllerListItem) => {
        this.controllerManager.selectedControllerItem = controllerItem;
        controller.flowZone = controllerItem.flowZone;
        controller.stationValveType = controllerItem.stationValveType;
        this.showSpinner = false;
      });
	}

  onControllerClicked(event: any, controller: ControllerListItem) {
		this.controllerClicked.emit({event, controller});
	}

  onSensorClicked(event: any, sensor: Sensor){
    this.sensorClicked.emit({event, sensor});
  }

  onStationClicked(event: any, station: Station){
    this.stationClicked.emit({event, station});
  }
}

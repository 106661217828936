<div class="rb-carousel-container">

	<div class="rb-carousel-content">
		<ng-content></ng-content>
	</div>

	<div class="rb-carousel-toolbar">
		<button mat-icon-button (click)="onPrevious()" [disabled]="selectedIndex < 1"><mat-icon>navigate_before</mat-icon></button>
		<div class="rb-carousel-indicators-container">
			<rb-carousel-indicator *ngFor="let x of carouselItems; let i = index"
				[itemIndex]="i"
				[totalItems]="carouselItems"
				[isSelected]="selectedIndex === i"
				(selected)="onIndicatorSelected($event)"
			></rb-carousel-indicator>
		</div>
		<button mat-icon-button (click)="onNext()" [disabled]="selectedIndex >= pageCount - 1"><mat-icon>navigate_next</mat-icon></button>
	</div>
</div>

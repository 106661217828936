/* eslint-disable @typescript-eslint/member-ordering */
import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { BroadcastService } from '../../common/services/broadcast.service';
import { Injectable } from '@angular/core';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { SelectListItem } from '../_common/models/select-list-item.model';
import { SensorTrigger } from './models/sensor-trigger.model';
import { SensorTriggerApiService } from './sensor-trigger-api.service';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { TriggerThresholdInfo } from './models/trigger-threshold-info.model';

import SensorType = RbEnums.Common.SensorType;
import SensorState = RbEnums.Common.SensorState;
import GolfWeatherSensorModel = RbEnums.Common.GolfWeatherSensorModel;
import UnitsType = RbEnums.Common.UnitsType;

@Injectable({
	providedIn: 'root'
})
export class SensorTriggerManagerService extends ServiceManagerBase {

	// Subjects
	sensorTriggerDeleted = new Subject();

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(protected broadcastService: BroadcastService,
				private sensorTriggerApiService: SensorTriggerApiService,
	) {
		super(broadcastService);
	}

	protected clearCache() {
		this.sensorTriggerApiService.clearCache();
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	createSensorTrigger(sensorTrigger: SensorTrigger): Observable<SensorTrigger> {
		return this.sensorTriggerApiService.createSensorTrigger(sensorTrigger);
	}

	deleteSensorTriggers(sensorTriggerIds: number[]): Observable<void> {
		return this.sensorTriggerApiService.deleteSensorTriggers(sensorTriggerIds)
			.pipe(tap(() => {
				this.sensorTriggerDeleted.next(null);
			}));
	}

	getSensorTrigger(id: number): Observable<SensorTrigger> {
		return this.sensorTriggerApiService.getSensorTrigger(id);
	}

	getSensorTriggers(bypassCache = false): Observable<SensorTrigger[]> {
		return this.sensorTriggerApiService.getSensorTriggers(bypassCache).pipe(map(response => response.value));
	}

	getSensorTriggersForSensor(sensorId: number, bypassCache = false): Observable<SensorTrigger[]> {
		return this.getSensorTriggers(bypassCache)
			.pipe(map((triggers: SensorTrigger[]) => triggers.filter(t => t.sensorId === sensorId).sort((a, b) => a.number - b.number)));
	}

	getTriggerComparisonTypes(bypassCache = false): Observable<SelectListItem[]> {
		return this.sensorTriggerApiService.getTriggerComparisonTypes(bypassCache).pipe(map(response => response.value));
	}

	update(sensorTriggerId: number, updateValues: any): Observable<null> {
		return this.sensorTriggerApiService.update(sensorTriggerId, updateValues);
	}

	updateSensorTrigger(sensorTrigger: SensorTrigger): Observable<void> {
		return this.sensorTriggerApiService.updateSensorTrigger(sensorTrigger);
	}

	getThresholdInfo(sensorType: SensorType, isWeatherSensor = false) {
		let thresholdInfo: TriggerThresholdInfo;

		switch (sensorType) {
			case SensorType.Voltage:
				thresholdInfo = {
					minValue: 0,
					maxValue: 10,
					increment: .05,
					defaultValue: 1,
					decimalPlaces: 2,
					units: 'UNIT_TYPE.VOLTS'
				};
				break;
			case SensorType.Current:
				thresholdInfo = {
					minValue: 4,
					maxValue: 20,
					increment: .08,
					defaultValue: 4,
					decimalPlaces: 2,
					units: 'UNIT_TYPE.MILLIAMPS_ABBR'
				};
				break;
			case SensorType.Pulse:
			case SensorType.PulseAccumulative:
			case SensorType.PulseNonAccumulative:
			case SensorType.PulsesIn10Seconds:
				thresholdInfo = {
					minValue: 0,
					maxValue: 9999,
					increment: 1,
					defaultValue: 0,
					decimalPlaces: 0,
					units: 'UNIT_TYPE.PULSES'
				};
				break;
			case SensorType.TimeBetweenPulses:
					thresholdInfo = {
						minValue: 0,
						maxValue: 9999,
						increment: 100,
						defaultValue: 100,
						decimalPlaces: 0,
						units: 'UNIT_TYPE.MILLISECONDS_ABBR'
					};
					break;
			case SensorType.SwitchOpenClose:
				thresholdInfo = {
					minValue: SensorState.Open,
					maxValue: SensorState.Closed,
					increment: !isWeatherSensor ? 1 : 0,
					defaultValue: 0,
					decimalPlaces: 0,
					units: null
				};
				break;
		}

		return thresholdInfo;
	}

	getGolfSensorThresholdInfo(sensorModel: GolfWeatherSensorModel, unitsType: UnitsType): TriggerThresholdInfo {
		let thresholdInfo: TriggerThresholdInfo;

		switch (sensorModel) {
			case GolfWeatherSensorModel.RainCan:
				thresholdInfo = {
					minValue: unitsType === UnitsType.English ? 0.01 : 0.1,
					maxValue: unitsType === UnitsType.English ? 10 : 10 * 25.4,
					increment: unitsType === UnitsType.English ? .01 : 0.1,
					defaultValue: unitsType === UnitsType.English ? 0.01 : 0.2,
					decimalPlaces: unitsType === UnitsType.English ? 2 : 3,
					units: unitsType === UnitsType.English ? 'UNIT_TYPE.INCH' : 'UNIT_TYPE.MILLIMETER'
				};
				break;
		}

		return thresholdInfo;
	}

	getTriggerDurationLabel(sensorType: SensorType, isWeatherSensor = false, weatherSensorModel: GolfWeatherSensorModel = null): string {

		return 'STRINGS.WINDOW';

	}

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FrontBackDropdownComponent } from '../../../../../core/components/front-back-dropdown/front-back-dropdown.component';
import { MatDialog } from '@angular/material/dialog';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { SelectHolesDropdownComponent } from '../../../../../core/components/select-holes-dropdown/select-holes-dropdown.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-mobile-diagnostics-filter',
	templateUrl: './mobile-diagnostics-filter.component.html',
	styleUrls: [ './mobile-diagnostics-filter.component.scss' ]
})
export class MobileDiagnosticsFilterComponent implements OnInit {

	@ViewChild('holesDropdown') holesDropdown: SelectHolesDropdownComponent;
	@ViewChild('areasDropdown') areasDropdown: FrontBackDropdownComponent;

	// Generally, we are just a UI proxy on top of the parent filter
	@Input() parentDiagnosticsFilter: any;	// DiagnosticsFilterComponent;

	RbUtils = RbUtils;

	constructor(public dialog: MatDialog,
				public translate: TranslateService) { }

	ngOnInit(): void {
		// setTimeout(() => this.onSelectControllers(), 500);
	}

	get runButtonEnabled(): boolean {

		switch (this.parentDiagnosticsFilter.selectedDiagnosticTypeId) {
			case RbEnums.Common.DiagnosticType.Address:
				return this.parentDiagnosticsFilter.selectedWirePathIds.length > 0 && this.parentDiagnosticsFilter.selectedInterfaceId != null;

			case RbEnums.Common.DiagnosticType.Area:
				return (this.holesDropdown != null && this.holesDropdown.selectedHoles.length > 0) ||
					(this.areasDropdown != null && this.areasDropdown.selectedItems.length > 0);

			case RbEnums.Common.DiagnosticType.Course:
				return true;

			default:
				return false;
		}
	}
}

import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AppInjector } from '../../core/core.module';
import { BackendNotificationService } from './backend-notification.service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CompanyStatusChange } from './company-status-change.model';
import { ControllerChange } from './controller-change.model';
import { DiagnosticData } from '../manual-ops/models/diagnostic-data.model';
import { FlowElementChange } from './flow-element-change.model';
import { IcShortAddressPollData } from '../manual-ops/models/ic-short-address-poll-data.model';
import { IcVoltagePollData } from '../manual-ops/models/ic-voltage-poll-data.model';
import { JobChange } from './job-change.model';
import { ManualOpsApiService } from '../manual-ops/manual-ops-api.service';
import { ManualOpsManagerService } from '../../api/manual-ops/manual-ops-manager.service';
import { ProgramChange } from './program-change.model';
import { QuickCheckData } from '../manual-ops/models/quick-check-data.model';
import { RadioRelayChange } from './radio-relay-change.model';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { ScheduledReportChange } from './scheduled-report-change.model';
import { SensorStatusChange } from './sensor-status-change.model';
import { SiteStatusChange } from './site-status-change.model';
import { StartStationModel } from '../manual-ops/models/start-station.model';
import { Station } from '../stations/models/station.model';
import { StationStatusChange } from './station-status-change.model';
import { StationValveTypeChange } from './station-valve-type-change.model';
import { SystemStatusService } from '../../common/services/system-status.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WeatherSourceStatusChange } from './weather-source-status-change.model';

class MockStationData {
	public stationId: number;
	public previousChangeType = RbEnums.SignalR.StationStatusChangeType.Stopped;
	public changeType: RbEnums.SignalR.StationStatusChangeType;
	public runtimeRemaining: number;

	constructor(stationId: number, changeType: RbEnums.SignalR.StationStatusChangeType, runtimeRemaining: number) {
		this.stationId = stationId;
		this.changeType = changeType;
		this.runtimeRemaining = runtimeRemaining;
	}
}

@UntilDestroy()
export class SignalRService extends BackendNotificationService {
	private readonly ConnectionFailRetryTimeMS = 5000;
	private readonly ConnectionBrokenReconnectTimeMS = 15000;
	
	private connectionEstablished = false;
	private hubConnection: HubConnection;
	private mockDiagnosticsInterval: NodeJS.Timer;
	private mockStationsInterval: NodeJS.Timer;
	private mockStationsData: MockStationData[] = [];
	private systemStatusService: SystemStatusService;
	private broadcastService: BroadcastService;
	
	protected override get notificationServiceName(): string {
		return typeof SignalRService;
	}

	constructor() {
		super();

		this.retry = 0;
		
		// Injected here so we receive any WeatherSensorStatusChanged events at startup.
		AppInjector.get(ManualOpsManagerService);
		this.systemStatusService = AppInjector.get(SystemStatusService);
		this.broadcastService = AppInjector.get(BroadcastService);
	}

	public override startConnection(): void {
		if (this.connectionEstablished) {
			this.stopConnection();
		}
		this.createConnection();
		this.registerOnServerEvents();
		this.hubConnection.start()
			.then(() => {
				// On successful connection, reset retry count.
				this.retry = 0;
				// Build a connection closed handler. I believe we might see this when the user moves from a mobile
				// network to a WiFi network, etc., so we want to retry.
				this.hubConnection.onclose((error?: Error) => {
					// If we shut down intentionally, connectionEstablished should be false already. In that case, we
					// don't retry, of course.
					if (this.connectionEstablished) {
						this.stopConnection();
						// eslint-disable-next-line max-len
						console.log(`SignalRService: Connection broken ${(error ? error.message : 'No error')}. Retrying after ${this.ConnectionBrokenReconnectTimeMS} ms...`);
						setTimeout(() => this.startConnection(), this.ConnectionBrokenReconnectTimeMS);
					}
				});
				this.connectionEstablished = true;
				console.log('SignalRService: Hub connection started');
				console.log('  joining company...');
				const connectionJoin = this.hubConnection.invoke('joinCompany');
				// Complete join and yearlyConsumptionData results.
				connectionJoin.then(() => {
					console.log('SignalRService: spJoin complete');
				}, (reason: any) => {
					this.connectionEstablished = false;
					console.log(`SignalRService: spJoin failed $o. Retrying after ${this.ConnectionFailRetryTimeMS} ms...`, reason);
					// We have to retry or we'll never get any messages.
					setTimeout(() => this.startConnection(), this.ConnectionFailRetryTimeMS);
				});
			})
			.catch(() => {
				this.connectionEstablished = false;
				console.log(`SignalRService: Error while establishing connection (retry count = ${this.retry}), ` +
					`retrying again after ${this.ConnectionFailRetryTimeMS} ms...`);
				this.retry++;
				setTimeout(() => this.startConnection(), this.ConnectionFailRetryTimeMS);
			});
	}

	public override stopConnection() {
		this.connectionEstablished = false;
		this.hubConnection.stop();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	protected createConnection() {
		const connectionPoint = `${this.env.apiUrl}`.replace('/api', '');
		this.hubConnection = new HubConnectionBuilder()
			.withUrl(`${connectionPoint}/signalr/hub/statusHub`,
			{
				accessTokenFactory: () => {
					return this.authManager.accessToken;
				}
			})
			//.withAutomaticReconnect()
			.configureLogging(signalR.LogLevel.Debug)
			.build();
	}

	/**
	 * registerOnServerEvents registers listeners for each SignalR message we care about. When a message is received, the
	 * corresponding handler method is called. The handlers are implemented in the base class so once we've dispatched the
	 * messages, we're done.
	 */
	private registerOnServerEvents(): void {
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.DryRunStatusChange, (data: any) => {
			this.logCallback(RbEnums.SignalR.StatusMethod.DryRunStatusChange, data);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.FlowElementStatusChange, (data: any) => {
			const changes: FlowElementChange[] = data.map(c => new FlowElementChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.FlowElementStatusChange, changes);
			this.onFlowElementStatusChange(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.IrrigationEngineStatusChange, (data: CompanyStatusChange[]) => {
			const changes: CompanyStatusChange[] = data.map(c => new CompanyStatusChange(c))
			.filter(c => c.companyId === this.authManager.getUserProfile().companyId);
			if (changes.length > 0) {
				this.logCallback(RbEnums.SignalR.StatusMethod.IrrigationEngineStatusChange, changes);
				this.onIrrigationEngineStatusChange(changes);
			}
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.JobFinish, (data: JobChange[]) => {
			const changes: JobChange[] = data.map(c => new JobChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.JobFinish, changes);
			this.onJobFinish(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.JobProgressStatus, (data: JobChange[]) => {
			const changes: JobChange[] = data.map(c => new JobChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.JobProgressStatus, changes);
			this.onJobProgressStatus(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.JobQueued, (data: JobChange[]) => {
			const changes: JobChange[] = data.map(c => new JobChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.JobQueued, changes);
			this.onJobQueued(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.JobRequestCompleted, (data: JobChange[]) => {
			const changes: JobChange[] = data.map(c => new JobChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.JobRequestCompleted, changes);
			this.onJobRequestCompleted(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.JobRequested, (data: JobChange[]) => {
			const changes: JobChange[] = data.map(c => new JobChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.JobRequested, changes);
			this.onJobRequested(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.JobStart, (data: JobChange[]) => {
			const changes: JobChange[] = data.map(c => new JobChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.JobStart, changes);
			this.onJobStart(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.ProgramStatusChange, (data: ProgramChange[]) => {
			const changes: ProgramChange[] = data.map(c => new ProgramChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.ProgramStatusChange, changes);
			this.onProgramStatusChange(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.SatelliteStatusChange, (data: ControllerChange[]) => {
			const changes: ControllerChange[] = data.map(c => new ControllerChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.SatelliteStatusChange, changes);
			this.onSatelliteStatusChange(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.RadioRelayStatusChange, (data: RadioRelayChange[]) => {
			const changes: RadioRelayChange[] = data.map(c => new RadioRelayChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.RadioRelayStatusChange, changes);
			this.onRadioRelayStatusChange(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.SensorStatusChange, (data: any) => {
			const changes: SensorStatusChange[] = data.map(c => new SensorStatusChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.SensorStatusChange, data);
			this.onSensorStatusChange(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.StationStatusChange, (data: any) => {
			const changes: StationStatusChange[] = data.map(c => new StationStatusChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.StationStatusChange, data);
			this.onStationStatusChange(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.StationValveTypeStatusChange, (data: any) => {
			const changes: StationValveTypeChange[] = data.map(c => new StationValveTypeChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.StationValveTypeStatusChange, data);
			this.onStationValveTypeStatusChange(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.SiteStatusChange, (data: any) => {
			const changes: SiteStatusChange[] = data.map(c => new SiteStatusChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.SiteStatusChange, data);
			this.onSiteStatusChange(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.WeatherSourceStatusChange, (data: any) => {
			const changes: WeatherSourceStatusChange[] = data.map(c => new WeatherSourceStatusChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.WeatherSourceStatusChange, data);
			this.onWeatherSourceStatusChange(changes);
		});
		this.hubConnection.on(RbEnums.SignalR.StatusMethod.ScheduledReportStatusChange, (data: any) => {
			const changes: ScheduledReportChange[] = data.map(c => new ScheduledReportChange(c));
			this.logCallback(RbEnums.SignalR.StatusMethod.ScheduledReportStatusChange, data);
			this.onScheduledReportChangeType(changes);
		});
	}
	createMockAddressDiagnosticsData(ops: ManualOpsApiService, dataType: RbEnums.Common.DiagnosticDataType, courseId: number, stations: Station[],
		interfaceId: number, wirePathIds: number[]): number[] {

		const stationsToTest = stations.filter(s => s.satelliteId === interfaceId && wirePathIds.some(id => id === s.groupNumber));
		return this.createMockSystemPollDiagnosticsData(ops, dataType, courseId, stationsToTest);
	}

	createMockCourseDiagnosticsData(ops: ManualOpsApiService, dataType: RbEnums.Common.DiagnosticDataType, courseId: number, stations: Station[],
									holeIds: number[], areaIds: number[]): number[] {
		const stationsToTest = stations.filter(station => {
			return (holeIds.length === 0 || station.stationArea.some(sa => holeIds.some(id => id === sa.areaId))) &&
				(areaIds.length === 0 || station.stationArea.some(sa => areaIds.some(id => id === sa.areaId)));
		});
		return this.createMockSystemPollDiagnosticsData(ops, dataType, courseId, stationsToTest);
	}

	createMockSystemPollDiagnosticsData(ops: ManualOpsApiService, dataType: RbEnums.Common.DiagnosticDataType, courseId: number, stations: Station[])
		: number[] {

		if (stations.length === 0) return;

		let iStation = 0;

		if (this.mockDiagnosticsInterval != null) clearInterval(this.mockDiagnosticsInterval);
		this.mockDiagnosticsInterval = setInterval(() => {
			if (RbUtils.Common.randomNumber(1, 2) === 1) {
				let data: DiagnosticData;
				switch (dataType) {
					case RbEnums.Common.DiagnosticDataType.ICShortAddress:
						data = this.createMockShortAddressDataItem(stations, iStation, courseId);
						break;

					case RbEnums.Common.DiagnosticDataType.ICVoltage:
						data = this.createMockVoltageDataItem(stations, iStation, courseId);
						break;

					case RbEnums.Common.DiagnosticDataType.QuickCheckDiagnostics:
						const data1 = this.createMockQuickCheckDataItem(stations, iStation, courseId);
						data = data1;
						// for the second station set result to no_feedback and
						// then generate a subsequent passing result for future delivery
						if (iStation === 1) {
							data1.result = RbEnums.Common.DiagnosticQuickCheckResult.NoFeedback;
							const data2 = this.createMockQuickCheckDataItem(stations, iStation, courseId);
							data2.result = RbEnums.Common.DiagnosticQuickCheckResult.Pass;
							setTimeout(() => {
								this.broadcastService.diagnosticDataReceived.next(data2);
							}, 3000);
						}
						break;
				}

				this.broadcastService.diagnosticDataReceived.next(data);

				if (++iStation === stations.length || ops.diagnosticsCancelled) {
					clearInterval(this.mockDiagnosticsInterval);
				}
			}
		}, 750);
		return stations.map(s => s.id);
	}

	startGolfStationsMock(startStationModel: StartStationModel) {

		// Create/reset entries
		startStationModel.stationIds.forEach(stationId => {
			let existing = this.mockStationsData.find(s => s.stationId === stationId);
			const runTimeremainingInSecs = (startStationModel ? (startStationModel.seconds ?
				((startStationModel.seconds.length > 0) ? startStationModel.seconds[0] : 0) : 0 ) : 0);
			if (existing == null) {
				existing = new MockStationData(stationId, RbEnums.SignalR.StationStatusChangeType.RunningUpdate, runTimeremainingInSecs);
				this.mockStationsData.push(existing);
			} else {
				existing.previousChangeType = existing.changeType;
				existing.changeType = RbEnums.SignalR.StationStatusChangeType.RunningUpdate;
				existing.runtimeRemaining = runTimeremainingInSecs;
			}
		});

		this.startStationsMockInterval();
	}

	pauseGolfStationsMock(stationIds: number[]) {
		stationIds.forEach(id => {
			const data = this.mockStationsData.find(s => s.stationId === id);
			if (data == null) return; // Nothing to pause

			data.previousChangeType = data.changeType;
			data.changeType = RbEnums.SignalR.StationStatusChangeType.Paused;
			this.systemStatusService.setStationStatus(new StationStatusChange({
				stationId: data.stationId,
				changeDateTime: new Date(),
				currentFlowRateGPM: 0,
				changeType: RbEnums.SignalR.StationStatusChangeType.Paused,
				runTimeRemaining: data.runtimeRemaining,
			}));
		});
	}

	resumeGolfStationsMock(stationIds: number[]) {
		stationIds.forEach(id => {
			const data = this.mockStationsData.find(s => s.stationId === id);
			if (data == null) return; // Nothing to resume

			data.previousChangeType = data.changeType;
			data.changeType = RbEnums.SignalR.StationStatusChangeType.RunningUpdate;

			this.startStationsMockInterval();
		});
	}

	stopGolfStationsMock(stationIds: number[]) {
		stationIds.forEach(id => {
			const data = this.mockStationsData.find(s => s.stationId === id);
			if (data == null) return; // Nothing to stop

			data.previousChangeType = data.changeType;
			data.changeType = RbEnums.SignalR.StationStatusChangeType.Stopped;
			data.runtimeRemaining = 0;
			this.systemStatusService.setStationStatus(new StationStatusChange({
				stationId: data.stationId,
				changeDateTime: new Date(),
				currentFlowRateGPM: 0,
				changeType: RbEnums.SignalR.StationStatusChangeType.Stopped,
				runTimeRemaining: data.runtimeRemaining,
			}));
		});
	}

	advanceGolfStationsMock(stationIds: number[]) {

		if (stationIds == null || stationIds.length === 0) return;

		let nextId: number = null;
		stationIds.forEach(id => {
			// Stop other stations from running
			const existingMockData = this.mockStationsData.find(data => data.stationId === id && data.stationId !== nextId);
			if (existingMockData && (existingMockData.changeType === RbEnums.SignalR.StationStatusChangeType.RunningUpdate ||
				existingMockData.changeType === RbEnums.SignalR.StationStatusChangeType.Paused)) {
				this.stopGolfStationsMock([id]);
				nextId = null;
				return;
			}

			if (nextId != null) return;
			nextId = id;
		});

		// Start for 3 minutes
		if (nextId == null) return;
		this.startGolfStationsMock(new StartStationModel([nextId], [ 180 ]));
	}

	private startStationsMockInterval() {
		const updateInterval = 5;

		if (this.mockStationsInterval != null) return; // Already running

		this.mockStationsInterval = setInterval(() => {

			// Send notification for every station that is running
			let atLeastOneStationRunning = false;
			this.mockStationsData.filter(d => d.changeType === RbEnums.SignalR.StationStatusChangeType.RunningUpdate).forEach(data => {
				let changeType: RbEnums.SignalR.StationStatusChangeType;
				data.runtimeRemaining -= updateInterval;
				if (data.runtimeRemaining <= 0) {
					data.runtimeRemaining = 0;
					changeType = RbEnums.SignalR.StationStatusChangeType.Stopped;
				} else {
					changeType = RbEnums.SignalR.StationStatusChangeType.RunningUpdate;
					atLeastOneStationRunning = true;
				}
				const transitioning = data.previousChangeType !== changeType;
				data.previousChangeType = changeType;
				const statusChange = new StationStatusChange({
					stationId: data.stationId,
					changeDateTime: new Date(),
					currentFlowRateGPM: 0,
					changeType: changeType,
					runTimeRemaining: data.runtimeRemaining,
				});
				if (transitioning) this.systemStatusService.setStationStatus(statusChange);
			});

			if (!atLeastOneStationRunning) {
				clearInterval(this.mockStationsInterval);
				this.mockStationsInterval = null;
			}
		}, updateInterval * 1000);
	}

	private createMockShortAddressDataItem(stations: Station[], stationIndex: number, courseId: number): IcShortAddressPollData {
		const station = stations[stationIndex];
		const result = RbUtils.Common.randomNumber(0, 4);
		return new IcShortAddressPollData({
			satelliteId: courseId,
			stationId: station.id,
			group: station.groupNumber,
			channel: station.channel,
			resultByStationId: { [station.id.toString()]: result === 0 ? 'NO_FB' : (result === 2 ? 'OK' : 'OK') },
		});
	}

	private createMockVoltageDataItem(stations: Station[], stationIndex: number, courseId: number): IcVoltagePollData {
		const station = stations[stationIndex];
		const feedback = RbUtils.Common.randomNumber(1, 3) === 1 ? 'NO_FB' : 'OK';
		return new IcVoltagePollData({
			satelliteId: courseId,
			stationId: station.id,
			group: station.groupNumber,
			sensorId: station.id,
			result: feedback === 'OK' ? 22 + RbUtils.Common.randomNumber(1, 15) : 0,
			feedback: feedback,
		});
	}

	private createMockQuickCheckDataItem(stations: Station[], stationIndex: number, courseId: number): QuickCheckData {
		const station = stations[stationIndex];
		const index = RbUtils.Common.randomNumber(0, 7);
		return new QuickCheckData({
			satelliteId: courseId,
			stationId: station.id,
			result: index <= 5 ? RbEnums.Common.DiagnosticQuickCheckResult.Pass :
				index === 6 ? RbEnums.Common.DiagnosticQuickCheckResult.Fail :
					RbEnums.Common.DiagnosticQuickCheckResult.NoFeedback,
			value1: Math.random() * 10,
			value2: Math.random() * 10,
			value3: Math.random() * 10,
		});
	}
}

export class WorldWeatherAstronomy {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	sunrise: string;
	sunset: string;
	monnrise: string;
	moonset: string;
	moon_phase: string;
	moon_illumination: string;

}

import { FlowLog } from './flow-log.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { Sensor } from '../../sensors/models/sensor.model';

export class FlowSensor extends Sensor {

	flowLogs: FlowLog[] = [];
	flowSensorModel: RbEnums.Common.FlowSensorModelType;
	kfactor: number;
	offset: number;
	pipeDiameter: number;
	displayFlowOnFloGraph: boolean;
	branchId:  number;
	pumpId:  number;
	siteId: number;

	constructor(json: any = null) {
		super(json);

		if (json) {
			if (json.flowLogs) { this.flowLogs = json.flowLogs.map(item => new FlowLog(item)); }
		}
	}

	static createDefault(sensorType: RbEnums.Common.SensorType): FlowSensor {
		const sensor = new FlowSensor();
		sensor.initialize(sensorType);
		sensor.kfactor = 0;
		sensor.branchId = null;
		sensor.displayFlowOnFloGraph =  false;
		return sensor;
	}
}

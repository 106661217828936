import { Component,  Input } from '@angular/core';

@Component({
  selector: 'rb-tip-container',
  templateUrl: './tip-container.component.html',
  styleUrls: ['./tip-container.component.scss']
})

export class TipContainerComponent {
	@Input() tipText = '';
}
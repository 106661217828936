<div class="ici-diagnostic-mobile-cell">

	<div class="status"
		[class.ok]="data.status === 'OK'"
		[class.error]="data.status === 'WARNING'">
		<ng-container *ngIf="data.isWaiting">
			{{'STRINGS.WAITING' | translate}}
		</ng-container>
		<ng-container *ngIf="!data.isWaiting">
			<ng-container *ngIf="data.faultFindingON">
				<span class="status-running">{{convertToSecondToFriendlyString(data.timeRemainingForStatusChange)}}</span>
			</ng-container>
			<ng-container *ngIf="!data.faultFindingON">
				<span *ngIf="data.status === 'OK'" class="rb-status-icon icon-confirmation"></span>
				<span *ngIf="data.status === 'WARNING'" class="rb-status-icon icon-warning"></span>
			</ng-container>
		</ng-container>
	</div>

	<div class="properties">
		<div>
			<span class="diagnostic-label">{{'STRINGS.INTERFACE' | translate}}:</span>
			<span class="diagnostic-value">{{data?.iciName}}</span>
		</div>

		<div>
			<span class="diagnostic-label">{{'STRINGS.WIREPATH' | translate}}:</span>
			<span class="diagnostic-value">{{data?.wirepath}}</span>
		</div>

		<div>
			<span class="diagnostic-label">{{'STRINGS.CURRENT_MA' | translate}}:</span>
			<span class="diagnostic-value">{{(params.currentValueGetter != null) ? params.currentValueGetter(params) : data?.currentmA }}</span>
		</div>

		<div>
			<span class="diagnostic-label">{{'STRINGS.PASSED' | translate}}:</span>
			<span class="diagnostic-value">{{data?.icmPassed}}</span>
		</div>

		<div>
			<span class="diagnostic-label">{{'STRINGS.NO_FEEDBACK_COLUMN' | translate}}:</span>
			<span class="diagnostic-value">{{data?.icmNoFeedbackCount}}</span>
		</div>

		<div>
			<span class="diagnostic-label">{{'STRINGS.SUSPENDED' | translate}}:</span>
			<span class="diagnostic-value">{{data?.icmSuspendedCount}}</span>
		</div>

		<div>
			<span class="diagnostic-label">{{'SPECIAL_MSG.NOT_CONNECTED' | translate}}:</span>
			<span class="diagnostic-value">{{data?.icmFailingCount}}</span>
		</div>

		<div>
			<span class="diagnostic-label">{{'STRINGS.TOTAL_ICMS' | translate}}:</span>
			<span class="diagnostic-value">{{data?.icmCount}}</span>
		</div>
	</div>
</div>

export namespace XXUseRbConstantsNamespace {
	export abstract class Tbos {
		static readonly TbosAutomaticAlertType = {
			SensorClosed: {
				name: 'TBOS.SENSOR_CLOSED',
				value: '0'
			},
			SensorOpened: {
				name: 'TBOS.SENSOR_OPENED',
				value: '4'
			}
		}

		static readonly TbosAutomaticCommand = {
			// Rain Delay
			TbosRainDelay: {
				name: 'TBOS.TBOS_RAIN_DELAY',
				value: '0'
			},
			RadioRelayRainDelay: {
				name: 'TBOS.RADIO_RELAY_RAIN_DELAY',
				value: '1'
			},
			NetworkRainDelay: {
				name: 'TBOS.NETWORK_RAIN_DELAY',
				value: '2'
			},
			// Turn On
			TbosControllerOn: {
				name: 'TBOS.TBOS_CONTROLLER_ON',
				value: '3'
			},
			RadioRelayControllerOn: {
				name: 'TBOS.RADIO_RELAY_CONTROLLER_ON',
				value: '4'
			},
			NetworkControllerOn: {
				name: 'TBOS.NETWORK_CONTROLLER_ON',
				value: '5'
			},
			// Turn Off
			TbosControllerOff: {
				name: 'TBOS.TBOS_CONTROLLER_OFF',
				value: '6'
			},
			RadioRelayControllerOff: {
				name: 'TBOS.RADIO_RELAY_CONTROLLER_OFF',
				value: '7'
			},
			NetworkControllerOff: {
				name: 'TBOS.NETWORK_CONTROLLER_OFF',
				value: '8'
			},
			// Other...
			TbosRaTbosStartStationinDelay: {
				name: 'TBOS.TBOS_START_STATION',
				value: '9'
			},
			TbosStartProgram: {
				name: 'TBOS.TBOS_START_PROGRAM',
				value: '10'
			},
			TbosCancelIrrigation: {
				name: 'TBOS.TBOS_CANCEL_IRRIGATION',
				value: '11'
			},
		}
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="programGroup" class="program-group-mobile-cell">
	<div class="name"><span class="ellipsis-text">{{programGroup.name}}</span></div>

<!--	<div class="schedules">-->
<!--		<span class="label">{{'STRINGS.SCHEDULES' | translate}}:</span>-->
<!--		<span class="value">{{programGroup.noOfPrograms}}</span>-->
<!--	</div>-->

	<div class="priority">
		<span class="label">{{'STRINGS.PRIORITY' | translate}}:</span>
		<span class="value">{{programGroup.priorityForDisplay}}</span>
	</div>

	<div class="status">
		<span class="label">{{'STRINGS.STATUS' | translate}}:</span>
		<span class="value" [ngClass]="{'station-status-running': isRunning}">{{ statusString }}</span>
	</div>

	<div class="start">
		<span class="label">{{'STRINGS.START_TIMES' | translate}}:</span>
		<span class="value start-times">{{startTimes}}</span>
	</div>

	<div class="type">
		<span class="label">{{'STRINGS.TYPE' | translate}}:</span>
		<span class="value">{{programGroup.runTimeInputTypeName}}</span>
	</div>

	<div class="water-days">
		<span class="label">{{'STRINGS.START_DAYS' | translate}}:</span>
		<span class="value ellipsis-text">{{programGroup.waterCycle}}</span>
	</div>

	<div class="adjust">
		<span class="label">{{'STRINGS.ADJUST' | translate}}:</span>
		<span class="value">{{adjust}}</span>
	</div>

	<div class="adjust">
		<span class="label">{{'STRINGS.SCHEDULES_NOUN' | translate}}:</span>
		<span class="value">{{programGroup.noOfPrograms}}</span>
	</div>
</div>

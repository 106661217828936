import { ApiService, HttpMethod } from '../_common/api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ScheduledReport } from './models/scheduled-report.model';
import { ScheduledReportItem } from './models/scheduled-report-item.model';
import { ScheduledReports } from './models/scheduled-reports.model';

@Injectable({
	providedIn: 'root'
})
export class ScheduledReportApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	getScheduledReports(): Observable<ScheduledReports> {
		return this.apiRequest<any>(this.getScheduledReportsUrl(), HttpMethod.Get)
			.pipe(map(json => new ScheduledReports(json)));
	}

	getScheduledReportList(): Observable<ScheduledReportItem[]> {
		return this.apiRequest<any>(this.getScheduledReportListUrl(), HttpMethod.Get)
			.pipe(map(list => {
				const listItems = [];
				list.forEach(element => {
					listItems.push(new ScheduledReportItem(element))
				});
				return listItems;
			}));
	}

	getActiveScheduledReportsForSite(siteId: number): Observable<ScheduledReports> {
		return this.apiRequest<any>(this.getActiveScheduledReportsForSiteUrl(siteId), HttpMethod.Get)
			.pipe(map(json => new ScheduledReports(json)));
	}

	getScheduledReport(id: number): Observable<ScheduledReport> {
		return this.apiRequest<any>(this.getScheduledReportUrl(id), HttpMethod.Get)
			.pipe(map(json => new ScheduledReport(json)));
	}

	createScheduledReport(task: ScheduledReport/*, reportSites: ReportSite[]*/) {
		return this.apiRequest<any>(this.createSchduledReportUrl(/*siteIds*/), HttpMethod.Post, {
			subject: task.subject,
			scheduledReportTime: task.scheduledReportTime,
			timeZone: task.timeZone,
			companyId: task.companyId,
			typeId: task.typeId,
			reportSite: task.reportSites,
			reportRecipient: task.reportRecipients,
			notSendBlank: task.notSendBlank,
			disableReport: task.disableReport,
			whoFrom: task.whoFrom,
			sendToMyself: task.sendToMyself,
			fileFormat: task.fileFormat
		});
	}

	updateScheduledReport(userId: number, updateValues: any): Observable<null> {
		return this.apiRequest<any>(this.updateScheduledReportUrl(userId), HttpMethod.Patch,
			this.patchTransform(updateValues));
	}

	updateBatches(reportIds: number[], updateValues: any): Observable<null> {
		return this.apiRequest<any>(this.updateBatchUrl, HttpMethod.Patch,
			{ids: reportIds, patch: this.patchTransform(updateValues)});
	}

	deleteReports(taskIds: number[]): Observable<void> {
		return this.apiRequest(this.deleteSchduledReportUrl(taskIds), HttpMethod.Delete);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */

	private get baseUrl(): string { return `${this.baseApiUrl}ScheduledReport`; }

	private get updateBatchUrl(): string {
		return `${this.baseUrl}/UpdateBatches`;
	}

	private getScheduledReportsUrl(): string {
		return `${this.baseUrl}/GetScheduledReports`;
	}

	private getScheduledReportListUrl(): string {
		return `${this.baseUrl}/GetScheduledReportList`;
	}

	private getActiveScheduledReportsForSiteUrl(siteId: number): string {
		return `${this.baseUrl}/GetActiveScheduledReportsForSite?siteId=${siteId}`;
	}

	private getScheduledReportUrl(id: number): string {
		return `${this.baseUrl}/GetScheduledReport?id=${id}`;
	}

	private createSchduledReportUrl(): string {
		return `${this.baseUrl}/CreateScheduledReport`;
	}

	private deleteSchduledReportUrl(taskIds: number[]): string {
		let queryString = '';
		taskIds.forEach(id => {
			if (queryString.length > 0) { queryString += '&'; }
			queryString += `reportIds=${id}`;
		});
		return `${this.baseUrl}/DeleteScheduledReports?${queryString}`;
	}

	private updateScheduledReportUrl(id: number): string {
		return `${this.baseUrl}/UpdateScheduledReport?id=${id}`;
	}

}

<div class="icm-diagnostic-mobile-cell">

	<div class="status"
		 [class.ok]="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK"
		 [class.no-fb]="data.result === RbEnums.Common.DiagnosticFeedbackResult.NO_FB"
		 [class.error]="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error">
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK && data.cdType 
			=== RbEnums.Common.DiagnosticDataType.ICVoltage" class="rb-status-icon icon-voltage_green"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error  && data.cdType 
			=== RbEnums.Common.DiagnosticDataType.ICVoltage" class="rb-status-icon icon-voltage_red"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.OK  && data.cdType 
			!== RbEnums.Common.DiagnosticDataType.ICVoltage" class="rb-status-icon icon-confirmation"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.Error  && data.cdType 
			!== RbEnums.Common.DiagnosticDataType.ICVoltage" class="rb-status-icon icon-warning"></span>
		<span *ngIf="data.result === RbEnums.Common.DiagnosticFeedbackResult.NO_FB" class="rb-status-icon icon-help"></span>
	</div>

	<div class="properties">
		<div *ngIf="data.cdType === RbEnums.Common.DiagnosticDataType.ICVoltage" class="voltage">
			<span class="diagnostic-label">{{'STRINGS.VOLTAGE' | translate}}:</span>
			<span class="diagnostic-value">{{data?.voltage}}</span>
		</div>

		<div class="location">
			<span class="diagnostic-label">{{'STRINGS.LOCATION' | translate}}:</span>
			<span class="diagnostic-value">{{data?.location}}</span>
		</div>

		<div class="interface">
			<span class="diagnostic-label">{{'STRINGS.INTERFACE' | translate}}:</span>
			<span class="diagnostic-value">{{data?.interface}}</span>
		</div>

		<div *ngIf="data.cdType === RbEnums.Common.DiagnosticDataType.ICShortAddress || data.cdType === RbEnums.Common.DiagnosticDataType.ICVoltage" class="wirepath">
			<span class="diagnostic-label">{{'STRINGS.WIREPATH' | translate}}:</span>
			<span class="diagnostic-value">{{data?.wirePath}}</span>
		</div>

		<div *ngIf="data.cdType === RbEnums.Common.DiagnosticDataType.QuickCheckDiagnostics && data.atRest != null" class="voltage">
			<span class="diagnostic-label">{{'STRINGS.RESTING_CURRENT_MA' | translate}}:</span>
			<span class="diagnostic-value">{{data?.atRest}}</span>
		</div>

		<div *ngIf="data.cdType === RbEnums.Common.DiagnosticDataType.QuickCheckDiagnostics && data.inRush != null" class="voltage">
			<span class="diagnostic-label">{{'STRINGS.IN_RUSH_CURRENT_MA' | translate}}:</span>
			<span class="diagnostic-value">{{data?.inRush}}</span>
		</div>

		<div *ngIf="data.cdType === RbEnums.Common.DiagnosticDataType.QuickCheckDiagnostics && data.hold != null" class="voltage">
			<span class="diagnostic-label">{{'STRINGS.HOLDING_CURRENT_MA' | translate}}:</span>
			<span class="diagnostic-value">{{data?.hold}}</span>
		</div>

		<div class="hole">
			<span class="diagnostic-label">{{'STRINGS.HOLE' | translate}}:</span>
			<span class="diagnostic-value">{{data?.hole}}</span>
		</div>

		<div class="area">
			<span class="diagnostic-label">{{'STRINGS.AREA' | translate}}:</span>
			<span class="diagnostic-value">{{data?.area}}</span>
		</div>

		<div *ngIf="data?.subArea != null" class="sub-area">
			<span class="diagnostic-label">{{'STRINGS.SUB_AREA' | translate}}:</span>
			<span class="diagnostic-value">{{data?.subArea}}</span>
		</div>

		<div class="COURSE">
			<span class="diagnostic-label">{{'STRINGS.COURSE' | translate}}:</span>
			<span class="diagnostic-value">{{data?.course}}</span>
		</div>
	</div>
</div>

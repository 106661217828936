import { IEnvironment, NotificationType } from '../../../environments/ienvironment';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

enum EnvironmentProperty {
	UseRequestUrl = 'useRequestUrl',
	PreferApiAndIdentityServerPort = 'preferApiAndIdentityServerPort',
	ApiUrl = 'apiUrl',
	SignalRUrl = 'signalRUrl',
	IdentityServer4Url = 'identityServer4Url',
	LicenseApiCloudUrl = 'licenseApiCloudUrl',
	AwsRBCCAppSyncUrl = 'awsRBCCAppSyncUrl',
	AwsRBCCAppSyncRegion = 'awsRBCCAppSyncRegion',
	AwsRBCCAppSyncChannelFormat = 'awsRBCCAppSyncChannelFormat',
	AwsRBCCAppSyncApiKey = 'awsRBCCAppSyncApiKey',
	RbccUi = 'rbcc_ui',
	RainBirdStoreUrl = 'rainbirdStoreUrl',
	WwoUrl = 'wwoUrl',
	WwoKey = 'wwoKey',
	Version = 'VERSION',
	ClientId = 'client_id',
	BingKey = 'bingKey',
	BeamerProductKeyIq4 = 'beamerProductKeyIq4',
	BeamerProductKeyCirrus = 'beamerProductKeyCirrus',
	UseMockData = 'useMockData',
	CloudDryRunTableName = 'cloudDryRunTableName',
	AwsDynamoDBRegion = 'awsDynamoDBRegion',
	NotificationType = "notificationType",
}

@Injectable({
	providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {

	private env: any = {}; // the dynamic environment settings, default to none
	private pathRegEx: RegExp;
	private requestOrigin: string;
	private requestOriginSansPort: string;
	private _apiUrl: string;
	private _signalRUrl: string;
	private _identityServer4Url: string;
	private _licenseApiCloudUrl: string;
	private _awsRBCCAppSyncUrl: string;
	private _awsRBCCAppSyncRegion: string;
	private _rbcc_ui: string;
	private _rainbird_store_url: string;
	private _useRequestUrl: boolean;
	private _preferApiAndIdentityServerPort: boolean;
	private _wwoUrl: string;
	private _wwoKey: string;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private http: HttpClient) {
		// RB-7777: Set the request origin early so we're less-sensitive to when the URL values are requested. For example,
		// the wwoUrl is requested early during load. If requestOrigin isn't set yet, that's an exception.
		this.requestOrigin = window.location.origin;
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	public load(): Observable<any> {
		return this.http
			.get<any>('assets/environment.dynamic.json', { headers: { 'Cache-Control': 'no-store', 'Pragma': 'no-cache' } })
			.pipe(tap(env => {
				this.env = env || {};

				if (this.useRequestUrl) {
					this.pathRegEx = new RegExp(/\/\/[^:\/\s]+(:\d+)?(.*)/);
					this.requestOriginSansPort = this.getOriginSansPort();
				}
			}, error => {
				console.log(`failed to retrieve dynamic environment settings because: ${error.message}`);
			}));
	}

	get production(): boolean {
		// this is a build-time only property for now
		// don't override this for consistency with main.ts enableProdMode()
		return environment.production;
	}

	get environmentOption(): number {
		return environment.environmentOption;
	}

	get useRequestUrl(): boolean {
		if (this._useRequestUrl) { return this._useRequestUrl; }
		return this._useRequestUrl = this.getBooleanValue(EnvironmentProperty.UseRequestUrl);
	}

	get preferApiAndIdentityServerPort(): boolean {
		if (this._preferApiAndIdentityServerPort) { return this._preferApiAndIdentityServerPort; }
		return this._preferApiAndIdentityServerPort = this.getBooleanValue(EnvironmentProperty.PreferApiAndIdentityServerPort);
	}

	get apiUrl(): string {
		if (!this._apiUrl) {
			this._apiUrl = this.getDynamicEndpointUrl(EnvironmentProperty.ApiUrl, this._apiUrl);
		}

		return this._apiUrl;
	}

	get signalRUrl(): string {
		if (!this._signalRUrl) {
			// Calling getStringValue() will get us the dynamic property if provided in environment.dynamic.json
			// assets file on the server. If not provided, it will get us the static environment (based on build
			// configuration), value. We check useRequestUrl and the identified value, choosing the correct output
			// as follows:
			// 1. If we have useRequestUrl OR the signalRUrl is undefined, we use the apiUrl value, replacing the
			// '/api' portion with '/signalr/hub/statusHub'.
			// 2. If useRequestUrl is false or if we got a value from either the dynamic source or the static
			// source, we use it, as-is.
			// This allows us to address RB-14082 with a cloud-deployed environment.dynamic.json file specifying
			// a different SignalR server URL than one based on CoreApi.
			const envUrl = this.getStringValue(EnvironmentProperty.SignalRUrl);
			console.log('signalRUrl: envUrl=%o', envUrl);
			console.log('signalRUrl: useRequestUrl=%o', this.useRequestUrl);
			if (this.useRequestUrl || !envUrl) {
				// Calculate the SignalR endpoint based on the apiUrl value. Remove the /api portion of the path,
				// substituting /signalr/hub/statusHub instead.
				const connectionPoint = `${this.apiUrl}`.replace('/api', '/signalr/hub/statusHub');
				this._signalRUrl = connectionPoint;
			} else {
				this._signalRUrl = envUrl;
			}
		}

		return this._signalRUrl;
	}

	get identityServer4Url(): string {
		if (!this._identityServer4Url) {
			this._identityServer4Url = this.getDynamicEndpointUrl(EnvironmentProperty.IdentityServer4Url, this._identityServer4Url);
		}

		return this._identityServer4Url;
	}

	get licenseApiCloudUrl(): string {
		if (!this._licenseApiCloudUrl) {
			this._licenseApiCloudUrl = this.getDynamicEndpointUrl(EnvironmentProperty.LicenseApiCloudUrl, this._licenseApiCloudUrl);
		}

		return this._licenseApiCloudUrl;
	}

	get awsRBCCAppSyncUrl(): string {
		if (this._awsRBCCAppSyncUrl) { return this._awsRBCCAppSyncUrl; }
		return this._awsRBCCAppSyncUrl = this.getStringValue(EnvironmentProperty.AwsRBCCAppSyncUrl);
	}

	get awsRBCCAppSyncRegion(): string {
		if (this._awsRBCCAppSyncRegion) { return this._awsRBCCAppSyncRegion; }
		return this._awsRBCCAppSyncRegion = this.getStringValue(EnvironmentProperty.AwsRBCCAppSyncRegion);
	}

	get awsRBCCAppSyncChannelFormat(): string {
		return this.getStringValue(EnvironmentProperty.AwsRBCCAppSyncChannelFormat);
	}

	get awsRBCCAppSyncApiKey(): string {
		return this.getStringValue(EnvironmentProperty.AwsRBCCAppSyncApiKey);
	}

	get rainbirdStoreUrl(): string {
		if (this._rainbird_store_url) { return this._rainbird_store_url; }
		return this._rainbird_store_url = this.getStringValue(EnvironmentProperty.RainBirdStoreUrl);
	}

	get rbcc_ui(): string {
		if (!this._rbcc_ui) {
			this._rbcc_ui = this.getDynamicEndpointUrl(EnvironmentProperty.RbccUi, this._rbcc_ui);
		}

		return this._rbcc_ui;
	}

	/**
	 * RB-7777: Make sure that when we're set to useUrl, we use the same protocol for WWO as the
	 * incoming UI URL does. Otherwise, if you load the UI with https, weather won't work (and
	 * you'll appear to be offline), because you can't call out to http://api.worldweatheronline.com
	 * from an HTTPS page.
	 * wwoUrl should use the same protocol as the page does. That is http --> http, https --> https.
	 */
	get wwoUrl(): string {
		if (!this._wwoUrl) {
			this._wwoUrl = this.getDynamicProtocolUrl(EnvironmentProperty.WwoUrl, this._wwoUrl);
		}
		return this._wwoUrl;
	}

	get wwoKey(): string {
		if (!this._wwoKey) {
			this._wwoKey = this.getStringValue(EnvironmentProperty.WwoKey);
		}
		return this._wwoKey;
	}

	get VERSION(): any {
		return this.getAnyValue( EnvironmentProperty.Version);
	}

	get client_id(): string {
		return this.getStringValue(EnvironmentProperty.ClientId);
	}

	get bingKey(): string {
		return this.getStringValue(EnvironmentProperty.BingKey);
	}

	get beamerProductKeyIq4(): string {
		return this.getStringValue(EnvironmentProperty.BeamerProductKeyIq4);
	}

	get beamerProductKeyCirrus(): string {
		return this.getStringValue(EnvironmentProperty.BeamerProductKeyCirrus);
	}

	get useMockData(): boolean {
		return this.getBooleanValue(EnvironmentProperty.UseMockData);
	}
	
	get notificationType(): NotificationType {
		return this.getDynamicValue<NotificationType>(EnvironmentProperty.NotificationType);
	}

	get cloudDryRunTableName(): string {
		return this.getStringValue(EnvironmentProperty.CloudDryRunTableName);
	}

	get awsDynamoDBRegion(): string {
		return this.getStringValue(EnvironmentProperty.AwsDynamoDBRegion);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private getStringValue(valueName: string): string {
		return this.shouldOverride(valueName, 'string') ? this.getDynamicValue<string>(valueName) : environment[valueName];
	}

	private getBooleanValue(valueName: string): boolean {
		return this.shouldOverride(valueName, 'boolean') ? this.getDynamicValue<boolean>(valueName) : environment[valueName];
	}

	private getAnyValue(valueName: string): any {
		return typeof this.env.VERSION !== 'undefined' ? this.getDynamicValue<any>(valueName) : environment[valueName];
	}

	private shouldOverride(valueName: string, expectedType: string) {
		return typeof this.env[valueName] === expectedType;
	}

	private getDynamicValue<T>(valueName: string): T {
		return this.env[valueName];
	}

	private getOriginSansPort() {
		return window.location.origin.match('^http[s]?:\/\/[^:\/]+')[0];
	}

	/** Method to return proper endpoint url taking into account the environment.dynamic.json file and/or useRequestUrl() property.
	 *  This method starts with the static endpoint defined in the environment.<??>.ts file, then...
	 *  if the environment.dynamic.json file has the 'property' we are evaluating defined, it will use that, then...
	 *  if useRequestUrl === true, it will combine the inbound request origin with the path (sans the host) previously retrieved.
	 *  NOTE: if the inbound request contains a port, we will honor it for the RBCC_UI endpoint, but will strip it off (and use
	 *  any port defined in the environment file) for any others. */
	private getDynamicEndpointUrl(property: EnvironmentProperty, currentValue: string): string {
		if (!currentValue) {
			currentValue = this.getStringValue(property);

			if (this.useRequestUrl) {
				const reResultCurrent = this.pathRegEx.exec(currentValue);
				const reResultRequestOrigin = this.pathRegEx.exec(this.requestOrigin);

				// Use the exact request origin for RBCC_UI, but strip off any port for the others.
				const origin = property === EnvironmentProperty.RbccUi ? this.requestOrigin : this.requestOriginSansPort;

				// Handle the case where the inbound request url utilizes a port, but the statically defined url does not.
				// reResultCurrent[1] is the port (in the format :1234) if it exists.
				// RB-10292: Handle the case where the inbound request utilizes a port and so does the statically defined url.
				// This will be the case when we're running everything in the debugger. In that case we need the port from the
				// corresponding static value (the API url for example).
				const port = ((reResultCurrent[1] && !reResultRequestOrigin) ||
					((property === EnvironmentProperty.ApiUrl || property === EnvironmentProperty.IdentityServer4Url) &&
					reResultCurrent[1] && this.preferApiAndIdentityServerPort)) ?
					reResultCurrent[1] :
					reResultRequestOrigin[1];

				// reResultCurrent[2] is the endpoint path sans request origin (e.g., /coreapi/api)
				currentValue = `${origin}${ (property !== EnvironmentProperty.RbccUi && port) ? port : ''}${reResultCurrent[2]}`;
			}
		}

		return currentValue;
	}

	/** RB-7777: Method to return proper endpoint url taking into account the environment.dynamic.json file and/or
	 * useRequestUrl() property.
	 *  This method starts with the static endpoint defined in the environment.<??>.ts file, then...
	 *  if the environment.dynamic.json file has the 'property' we are evaluating defined, it will use that, then...
	 *  if useRequestUrl === true, it will combine the inbound request protocol (http/https) with the path (sans the protocol)
	 * previously retrieved.
	 * This operation is used for things like WWO, where we need to use the matching protocol to our useUrl.
	 */
	private getDynamicProtocolUrl(property: EnvironmentProperty, currentValue: string): string {
		if (!currentValue) {
			currentValue = this.getStringValue(property);

			if (this.useRequestUrl) {
				// Check for http/https in the requestOrigin, the path where the UI was loaded.
				const usingHttps = this.requestOrigin.toLowerCase().startsWith('https');

				// Swap in the protocol from the request origin for the protocol in the currentValue.
				currentValue = `${(usingHttps ? 'https' : 'http')}${currentValue.substring(currentValue.indexOf(':'))}`;
			}
		}

		return currentValue;
	}
}

import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class DecoderModule {

	companyId: number;
	id: number;
	isUpsideDown = false;
	masterValveType: number;
	maximumOrdinal: number;
	minimumOrdinal: number;
	ordinal: number;
	satelliteId: number;
	sku: number;
	stationCount: number;
	type: RbEnums.Common.DeviceType;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

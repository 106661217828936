import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { AppDateAdapter } from '../../../../../shared-ui/custom/date-adapter';
import { BaseMobileCellRendererComponent } from '../_base-mobile-cell-renderer/base-mobile-cell-renderer.component';
import { FlatScheduledProgram } from '../../../../../api/programs/models/flat-scheduled-program.model';

@Component({
	selector: 'rb-scheduled-activity-mobile-cell-renderer',
	templateUrl: './scheduled-activity-mobile-cell-renderer.component.html',
	styleUrls: ['./scheduled-activity-mobile-cell-renderer.component.scss']
})
export class ScheduledActivityMobileCellRendererComponent extends BaseMobileCellRendererComponent implements OnInit {

	@HostBinding('class') class = 'rb-base-mobile-cell-renderer';

	scheduledActivity: FlatScheduledProgram;
	private appDateAdapter: AppDateAdapter;

	constructor(injector: Injector) {
		super(injector);
		this.appDateAdapter = injector.get(AppDateAdapter);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	agInit(params: any): void {
		super.agInit(params);
		this.scheduledActivity = params.data;
	}

	getDateTimeString(dateTime: Date): string {
		return dateTime ? this.appDateAdapter.formatDateTime(dateTime) : '-';
	}
}

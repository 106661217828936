<div id="rb-date-list-selector-container">

	<div *ngIf="!isReadOnly" id="rb-dls-header">
		<div id="rb-dls-header-title" [ngClass]="{'disabled': dateList?.length === maxDaysOff}">{{headerText| translate}}</div>
		<div>
			<input id="rb-dls-cal-input"
				matInput
				[matDatepicker]="picker"
				[matDatepickerFilter]="boundDate"
				[min]="minDate"
				[max]="maxDate"
				[value]="selectedDate"
				(dateChange)="onDateSelected($event)"
			>
			<button
				type="button"
				mat-icon-button
				class="add-start-time-btn"
				[disabled]="dateList?.length === maxDaysOff"
				(click)="onAddDayOff()"
			>
				<mat-icon class="color">add_circle</mat-icon>
			</button>
		</div>
	</div>
	<div id="rb-dls-date-list">
		<rb-date-list-card *ngFor="let date of dateList"
			[date]="date"
			(deleteDate)="onDeleteDate($event)"
			(editDate)="onEditDate($event)"
		></rb-date-list-card>
		<rb-date-list-card *ngIf="!dateList || dateList.length < 1" [isNoneCard]="true"></rb-date-list-card>
	</div>

	<mat-datepicker #picker disabled="false" [startAt]="startAtDate" (closed)="onDatePickerClosed()"

	></mat-datepicker>
</div>

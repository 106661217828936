<div [@slideInOut] class="pwd-expiration-notification-container">
    <table>
      <tr>
        <td class="warning-icon-rp"><mat-icon [inline]="true"><i class="icon-warning warning-orange-color"></i></mat-icon></td>
        <td><div class="pwd-expiration-title">{{'STRINGS.PASSWORD_RESET_HEADER' | translate}}</div></td>
        <td><button class="pwd-expiration-close-button" (click)="dismiss()"><mat-icon class="expansion-icon">close</mat-icon></button></td>
      </tr>
      <tr>
        <td></td> 
        <td><div class="pwd-expiration-description">{{description}}</div></td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td><button class="pwd-expiration-reset-now" (click)="resetPassword()">{{'STRINGS.PASSWORD_RESET_NOW' | translate}}</button></td>
        <td></td>
      </tr>
  </table>
</div>
import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { CultureSettings } from '../../api/culture-settings/models/culture-settings.model';
import { NumberArrowButtonComponent } from '../components/buttons/number-arrow-button/number-arrow-button.component';
import { RbConstants } from '../../common/constants/_rb.constants';

@Directive({
	selector: 'input[rbGroupDecimalSeparator]'
})

export class RbGroupDecimalSeparatorDirective implements OnInit, OnChanges {

	@Input() allowNegativeValue = false;
	@Input() isOnlyInteger = false;
	@Input() isUsingArrowButton = false;
	@Input() fControl: FormControl;
	@Input() step: number;
	@Input() precison: number;
	@Input() min: number = undefined;
	@Input() max: number = undefined;

	private allowProfileKey: string;
	private separator: string;
	cultureSettings: CultureSettings;

	constructor(
		private authManager: AuthManagerService,
		private elRef: ElementRef,
		private viewContainerRef: ViewContainerRef,
		private control: NgControl
	) {
	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.isUsingArrowButton) {
			this.removeArrowButtons();
			if (changes.isUsingArrowButton.currentValue) {
				this.initArrowButtons();
			}
		}
	}

	@HostListener('keydown', ['$event'])
	KeyPress(e: KeyboardEvent) {
		if (
			['Delete', 'Backspace', 'Clear', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight'].indexOf(e.key) !== -1 ||
			(e.key === 'a' && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+A/Cmd+A
			(e.key === 'c' && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+C/Cmd+C
			(e.key === 'v' && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+V/Cmd+V
			(e.key === 'x' && (e.ctrlKey || e.metaKey)) // Allow: Ctrl+X/Cmd+X
		) {
			return;  // let it happen, don't do anything
		}

		this.cultureSettings = this.authManager.userCulture;
		this.allowProfileKey = this.isOnlyInteger ? null : (this.cultureSettings.allowComma ? ',' : '.');
		this.separator = this.cultureSettings.allowComma ? RbConstants.Form.COMMA_SEPARATOR : RbConstants.Form.DECIMAL_SEPARATOR;

		// Allow minus sign
		const element = e.currentTarget as HTMLInputElement;
		if (this.allowNegativeValue && (e.key === '-') &&
			(element.value.length === 0 || (element.selectionEnd - element.selectionStart === element.value.length) ||
				(element.selectionEnd === 0 && element.value.indexOf('-') === -1))) {
			return;
		}

		// Ensure that it is a number and stop the keypress
		if ((e.key < '0' || e.key > '9') && (this.allowProfileKey !== e.key || this.isSeparatorExist(element.value))) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		event.preventDefault();
		const pastedInput: string = event.clipboardData
			.getData('text/plain')
			.replace(/\D/g, ''); // get a digit-only string
		const target = <any>event.target;
		if (target != null && target.setRangeText) {
			target.setRangeText(pastedInput);
		} else {
			document.execCommand('insertText', false, pastedInput);
		}
	}

	private initArrowButtons() {
		const abstractControl = this.control.control as FormControl;
		const componentRef = this.viewContainerRef.createComponent(NumberArrowButtonComponent);
		componentRef.instance.step = this.step;
		componentRef.instance.precison = this.precison;
		componentRef.instance.min = this.min;
		componentRef.instance.max = this.max;
		componentRef.instance.fControl = abstractControl;
		const host = this.elRef.nativeElement.parentElement;
		host.insertBefore(componentRef.location.nativeElement, host.firstChild);
	}

	private removeArrowButtons() {
		const host = this.elRef.nativeElement.parentElement;
		const rbNumberArrowButton = host.getElementsByTagName('rb-number-arrow-button');
		for (var i = 0; i < rbNumberArrowButton.length; i++) {
			rbNumberArrowButton[i].remove()
		}
	}

	private isSeparatorExist(value: string) {

		if (value.indexOf(this.separator) < 0) {
			return false;
		} else {
			const text = window.getSelection().toString();
			return !((value.indexOf(this.separator) >= 0) && text !== '');
		}
	}
}

import { map, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { AreaFormatListItem } from './models/area-format-list-item.model';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CultureSettings } from './models/culture-settings.model';
import { CultureSettingsApiService } from './culture-settings-api.service';
import { DateFormatListItem } from './models/date-format-list-item.model';
import { DayOfWeekListItem } from './models/day-of-week-list-item.model';
import { DecimalSeparatorListItem } from './models/decimal-separator-list-item.model';
import { Injectable } from '@angular/core';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { SupportedCultureListItem } from './models/supported-culture-list-item.model';
import { TimeFormatListItem } from './models/time-format-list-item.model';
import { TranslateService } from '@ngx-translate/core';
import { UnitsTypeListItem } from './models/units-type-list-item.model';

import Culture = RbEnums.Common.Culture;

declare var Beamer: any;

@Injectable({
    providedIn: 'root'
})
export class CultureSettingsManagerService extends ServiceManagerBase {

    cultureSettingsChange = new Subject<CultureSettings>();

    // Cache Containers (non-expiring)
    private _areaFormats: AreaFormatListItem[] = [];
    private _dateFormats: DateFormatListItem[] = [];
    private _daysOfWeek: DayOfWeekListItem[] = [];
    private _decimalSeparators: DecimalSeparatorListItem[] = [];
    private _supportedCultures: SupportedCultureListItem[] = [];
    private _timeFormats: TimeFormatListItem[] = [];
    private _unitsTypes: UnitsTypeListItem[] = [];

    private _cultureSetting: CultureSettings;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private cultureSettingsApiService: CultureSettingsApiService,
                protected broadcastService: BroadcastService,
                private translate: TranslateService
    ) {
        super(broadcastService);
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public clearCache() {
    }

    public clearCultureCache() {
        this._areaFormats = [];
        this._dateFormats = [];
        this._daysOfWeek = [];
        this._decimalSeparators = [];
        this._supportedCultures = [];
        this._timeFormats = [];
        this._unitsTypes = [];
    }

    // =========================================================================================================================================================
    // Public Properties and Methods
    // =========================================================================================================================================================

    get cultureSetting(): CultureSettings {
        return this._cultureSetting;
    }

    set cultureSetting(value: CultureSettings) {
        this._cultureSetting = value;
        this.cultureSettingsChange.next(value);
    }

    getCultureSettings(cultureId: number, byPassCache = false): Observable<CultureSettings> {
        return this.cultureSettingsApiService.getCultureSettings(cultureId, byPassCache)
            .pipe(map(response => {
                this.setLanguage(response.value.cultureId);
                this.cultureSetting = response.value;
                return response.value;
            }));
    }

    getDateFormats(): Observable<DateFormatListItem[]> {
        if (this._dateFormats.length > 0) return of(this._dateFormats);

        return this.cultureSettingsApiService.getDateFormats()
            .pipe(tap((dateFormats: DateFormatListItem[]) => this._dateFormats = dateFormats));
    }

    getDaysOfWeek(): Observable<DayOfWeekListItem[]> {
        if (this._daysOfWeek.length > 0) return of(this._daysOfWeek);

        return this.cultureSettingsApiService.getDaysOfWeek()
            .pipe(tap((daysOfWeek: DayOfWeekListItem[]) => this._daysOfWeek = daysOfWeek));
    }

    getDecimalSeparators(): Observable<DecimalSeparatorListItem[]> {
        if (this._decimalSeparators.length > 0) return of(this._decimalSeparators);

        return this.cultureSettingsApiService.getDecimalSeparators()
            .pipe(tap((decimalSeparators: DecimalSeparatorListItem[]) => this._decimalSeparators = decimalSeparators));
    }

    getSupportedCultures(): Observable<SupportedCultureListItem[]> {
        if (this._supportedCultures.length > 0) return of(this._supportedCultures);

        return this.cultureSettingsApiService.getSupportedCultures()
            .pipe(tap((supportedCultures: SupportedCultureListItem[]) => this._supportedCultures = supportedCultures));
    }

    getTimeFormats(): Observable<TimeFormatListItem[]> {
        if (this._timeFormats.length > 0) return of(this._timeFormats);

        return this.cultureSettingsApiService.getTimeFormats()
            .pipe(tap((timeFormats: TimeFormatListItem[]) => this._timeFormats = timeFormats));
    }

    getUnitsTypes(): Observable<UnitsTypeListItem[]> {
        if (this._unitsTypes.length > 0) return of(this._unitsTypes);

        return this.cultureSettingsApiService.getUnitsTypes()
            .pipe(tap((unitsTypes: UnitsTypeListItem[]) => this._unitsTypes = unitsTypes));
    }

    getAreaFormats(): Observable<AreaFormatListItem[]> {
        if (this._areaFormats.length > 0) return of(this._areaFormats);

        return this.cultureSettingsApiService.getAreaFormats()
            .pipe(tap((areaFormats: AreaFormatListItem[]) => this._areaFormats = areaFormats));
    }

    setLanguage(cultureId: number) {
        const lang = this.getCultureAsciiName(cultureId);
        this.translate.use(lang.match(/en|fr|tr|de|it|pt|es|sv|ja|vi|zh|ko/) ? lang : 'en');
        this.broadcastService.cultureChanged.next(null);

        // Set User Language attribute on html tag. We will use this to make language specific css adjustments.
        const el = document.getElementsByTagName('html')[0];
        const attr = document.createAttribute('user-language');
        attr.value = lang;
        el.attributes.setNamedItem(attr);

        if (typeof(Beamer) !== 'undefined') {
            // Update language of Beamer Notifications
            Beamer.update({
                language: lang
            });
        }
    }

    /**
     * @summary Public function used, in AppDateAdapter, to get the locale for configuration of the datepicker.
     * @param cultureId Numeric value (1033 = English/US, etc.)
     * @returns string with two-letter language detail, 'en', 'fr', etc.
     */
    getCultureAsciiName(cultureId: number): string {
        switch (cultureId) {
            case Culture.English:
                return 'en'; // English = 1033, English - United States
            case Culture.French:
                return 'fr'; // French - France
            case Culture.German:
                return 'de'; // German - Germany
            case Culture.Italian:
                return 'it'; // Italian - Italy
            case Culture.Portuguese:
                return 'pt'; // Portuguese - Portugal
            case Culture.Spanish:
                return 'es'; // Spanish - Spain
            case Culture.Turkish:
                return 'tr'; // Turkish - Turkey
            case Culture.Chinese:
                return 'zh'; // Chinese - China : zh-CN
            case Culture.Japanese:
                return 'ja'; // Japanese - Japan
            case Culture.Swedish:
                return 'sv'; // Swedish - Sweden
            case Culture.Vietnamese:
                return 'vi'; // Vietnamese - Vietnam
            case Culture.Korean:
                return 'ko'; // Korean - Korea
            default:
                return 'en'; // English = 1033, English - United States
        }
    }

    /**
     * @summary Get the culture ID value (1033 for English, etc.) from the locale value often found in the
     * user profile data, en-US, en-UK, fr-FR, etc.
     * @param cultureName Standard string value representing either the language alone, 'de' for example for
     * German, or the language and locale, 'pt-BR' for Portuguese/Brasil for example. Only the first two
     * characters are used.
     * @returns Culture ID value suitable for call to setLanguage().
     */
    getCultureId(cultureName: string): number {
        // Take the language characters from the standard locale string (en from en-US, for example).
        // If the cultureName input isn't value, use English, 1033.
        const lang = (cultureName && cultureName.length >= 2) ? cultureName.substr(0, 2) : 'en';
        switch (lang) {
            case 'en' :
                return Culture.English; 	// English = 1033, English - United States
            case 'fr' :
                return Culture.French; 		// French - France
            case 'de' :
                return Culture.German; 		// German - Germany
            case 'it' :
                return Culture.Italian; 	// Italian - Italy
            case 'pt' :
                return Culture.Portuguese; 	// Portuguese - Portugal
            case 'es' :
                return Culture.Spanish; 	// Spanish - Spain
            case 'tr' :
                return Culture.Turkish; 	// Turkish - Turkey
            case 'zh' :
                return Culture.Chinese; 	// Chinese - China : zh-CN
            case 'ja' :
                return Culture.Japanese; 	// Japanese - Japan
            case 'sv' :
                return Culture.Swedish; 	// Swedish - Sweden
            case 'vi' :
                return Culture.Vietnamese; 	// Vietnamese - Vietnam
            case 'ko' :
                return Culture.Korean; 		// Korean - Korea
            default:
                return Culture.English; 	// English = 1033, English - United States
        }
    }
}

import { ApiService, HttpMethod } from '../_common/api.service';
import { Note, StickyNote } from './models/sticky-note.model';
import { Injectable } from '@angular/core';
import { IUnreadNotesCounter } from './sticky-note-manager.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StickyNoteApiService extends ApiService {
	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================
	getStickyNotes(siteId: number): Observable<StickyNote[]> {
		return this.apiRequest<any>(this.getStickyNotesUrl(siteId), HttpMethod.Get)
			.pipe(map(list => list.map(item => new StickyNote(item))));
	}

	createStickyNote(stickyNote: any): Observable<StickyNote> {
		return this.apiRequest<any>(this.createStickyNoteUrl(), HttpMethod.Post, stickyNote)
			.pipe(map(response => new StickyNote(response)));
	}

	updateStickyNote(id: number, patchData: any): Observable<StickyNote> {
		return this.apiRequest<any>(this.updateStickyNoteUrl(id), HttpMethod.Patch, this.patchTransform(patchData));
	}

	deleteStickyNote(id: number): Observable<any> {
		return this.apiRequest<any>(this.deleteStickyNoteUrl(id), HttpMethod.Delete);
	}

	// =========================================================================================================================================================
	// Public Properties and Methods for Golf Notes
	// =========================================================================================================================================================
	getNotes(siteId: number): Observable<Note[]> {
		return this.apiRequest<any>(this.getNotesUrl(siteId), HttpMethod.Get)
			.pipe(map(list => list.map(item => new Note(item))));
	}

	getUnreadNotesCounters(): Observable<IUnreadNotesCounter[]> {
		return this.apiRequest<any>(this.getUnreadNotesCounterUrl(), HttpMethod.Get)
			.pipe(map(list => list.map(item => new Note(item))));
	}

	createNote(stickyNote: any): Observable<Note> {
		return this.apiRequest<any>(this.createNoteUrl(), HttpMethod.Post, stickyNote)
			.pipe(map(response => new Note(response)));
	}

	updateNote(id: number, patchData: any): Observable<Note> {
		return this.apiRequest<any>(this.updateNoteUrl(id), HttpMethod.Patch, this.patchTransform(patchData));
	}

	deleteNote(id: number): Observable<any> {
		return this.apiRequest<any>(this.deleteNoteUrl(id), HttpMethod.Delete);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================
	/* eslint-disable @typescript-eslint/member-ordering */
	private get baseUrl(): string { return `${this.baseApiUrl}StickyNote`; }

	private getStickyNotesUrl(siteId: number): string { return `${this.baseUrl}/GetStickyNotesForSite?siteId=${siteId}`; }
	private createStickyNoteUrl(): string { return `${this.baseUrl}/CreateStickyNote`; }
	private updateStickyNoteUrl(id: number): string { return `${this.baseUrl}/UpdateStickyNote?id=${id}`; }
	private deleteStickyNoteUrl(id: number): string { return `${this.baseUrl}/DeleteStickyNote?id=${id}`; }

	/* eslint-disable @typescript-eslint/member-ordering */
	private get noteBaseUrl(): string { return `${this.baseApiUrl}Note`; }

	private getNotesUrl(siteId: number): string { return `${this.noteBaseUrl}/GetNotesForSite?siteId=${siteId}`; }
	private getUnreadNotesCounterUrl(): string { return `${this.noteBaseUrl}/GetNotesCountForUser`; }
	private createNoteUrl(): string { return `${this.noteBaseUrl}/CreateNote`; }
	private updateNoteUrl(id: number): string { return `${this.noteBaseUrl}/UpdateNote?id=${id}`; }
	private deleteNoteUrl(id: number): string { return `${this.noteBaseUrl}/DeleteNote?id=${id}`; }
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<rb-dialog
	[title]="'STRINGS.FREEDOM_SYSTEM_SETUP' | translate"
	[closeButtonLabel]="'STRINGS.CANCEL' | translate"
	[submitButtonLabel]="'STRINGS.OK' | translate"
	[showSubmitButton]="true"
	[saveDisabled]="connectionType == null || connectionType.length === 0"
	(onSubmit)="onSave()"
	class="freedom-system-dialog">

	<form data-qaid="freedom-system-form" #form="ngForm" class="flex flex-column">
		<fieldset>
			<mat-form-field class="input-with-select">
				<input matInput
					   type="text"
					   name="connectionType"
					   [maxLength]="50"
					   [placeholder]="'STRINGS.CONNECTION_TYPE' | translate"
					   [required]="true"
					   [(ngModel)]="connectionType"/>
				<mat-select name="connectionType" [(ngModel)]="connectionType" [placeholder]="'STRINGS.CONNECTION_TYPE' | translate" required>
					<mat-option *ngFor="let ct of connectionTypes" [value]="ct.displayName" class="pv1" color="primary">{{ct.displayName}}</mat-option>
				</mat-select>
			</mat-form-field>
		</fieldset>
	</form>
</rb-dialog>

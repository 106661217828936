<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="sensor-mobile-cell">
	<div class="flex justify-between">
		<div class="sensor-name">{{sensor?.name}}</div>
	</div>
	<div class="flex justify-between">
		<div>
			<span class="label">{{ 'STRINGS.TYPE' | translate }} :</span>
			<span class="value">{{sensor?.typeName}}</span>
		</div>
	</div>
	<div class="flex justify-between">
		<div>
			<span class="label">{{ 'STRINGS.MODEL' | translate }} :</span>
			<span class="value">{{sensor?.model}}</span>
		</div>
	</div>
	<div class="flex justify-between">
		<div *ngIf="isAddressShownInGrid">
			<span class="label">{{ 'STRINGS.ADDRESS' | translate }} :</span>
			<span class="value">{{sensor?.addressInt}}</span>
		</div>
	</div>
	<div class="flex justify-between">
		<div>
			<span class="label">{{ 'STRINGS.SETTLING_TIME' | translate }} :</span>
			<span class="value">{{sensor?.settlingTimeValue}}</span>
		</div>
	</div>
	<div class="flex justify-between">
		<div>
			<span class="label">{{ 'STRINGS.FLOZONE_TM' | translate }} :</span>
			<span class="value">{{sensor?.floZoneGridDisplay}}</span>
		</div>
	</div>
	<div class="flex justify-between">
		<a *ngIf="isAutomaticActionsShownInGrid" (click)="onConfigureAutomaticActionsLinkClick(sensor, $event)"
			class="pseudo-hyperlink">{{ sensor.automaticActions }}</a>
	</div>
	<div>

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<label *ngIf="oneTimePeriod && !deviceManager.isMobile" class="time-period-label">{{'STRINGS.TIME_PERIOD' | translate}}</label>
<label *ngIf="!oneTimePeriod && !deviceManager.isMobile" class="w-60 time-period-label">{{'STRINGS.CURRENT_PERIOD' | translate}}</label>

<mat-form-field class="mh1 reports-start-date"><span>{{'STRINGS.START' | translate}}</span>
	<input matInput
		class="reports-start-date--input"
		placeholder="{{'STRINGS.START' | translate}}"
		[ngModel]="startDateCurrent"
		[matDatepicker]="startDatePicker1"
		(dateChange)="changeTimePeriod(RbFormConstants.TIME_PERIOD.currentStart, $event)"
	>
	<mat-datepicker-toggle matSuffix [for]="startDatePicker1"></mat-datepicker-toggle>
	<mat-datepicker #startDatePicker1></mat-datepicker>
</mat-form-field>

<mat-form-field class="mh1 reports-end-date">
	<span>{{oneTimePeriod ? ('STRINGS.STOP' | translate) : ('STRINGS.END' | translate)}}</span>
	<input matInput
		class="reports-end-date--input"
		placeholder="oneTimePeriod ? ('STRINGS.STOP' | translate): ('STRINGS.END' | translate)"
		[ngModel]="endDateCurrent"
		[matDatepicker]="endDatePicker1"
		(dateChange)="changeTimePeriod(RbFormConstants.TIME_PERIOD.currentEnd,$event)"
		(focus)="$event.target.select()"
	/>
	<mat-datepicker-toggle matSuffix [for]="endDatePicker1"></mat-datepicker-toggle>
	<mat-datepicker #endDatePicker1></mat-datepicker>
</mat-form-field>

<label *ngIf="!oneTimePeriod" class="w-60 time-period-label">{{'STRINGS.PREVIOUS_PERIOD' | translate}}</label>

<mat-form-field *ngIf="!oneTimePeriod" class="mh1 reports-start-date"><span>{{'STRINGS.START' | translate}}</span>
	<input matInput
		class="reports-start-date--input"
		placeholder="{{'STRINGS.START' | translate}}"
		[ngModel]="startDatePrevious"
		[matDatepicker]="startDatePicker2"
		(dateChange)="changeTimePeriod(RbFormConstants.TIME_PERIOD.previousStart,$event)"
		(focus)="$event.target.select()"
	/>
	<mat-datepicker-toggle matSuffix [for]="startDatePicker2"></mat-datepicker-toggle>
	<mat-datepicker #startDatePicker2></mat-datepicker>
</mat-form-field>

<mat-form-field *ngIf="!oneTimePeriod" class="mh1 reports-end-date"><span>{{'STRINGS.END' | translate}}</span>
	<input matInput
		class="reports-end-date--input"
		placeholder="{{'STRINGS.END' | translate}}"
		[ngModel]="endDatePrevious"
		[matDatepicker]="endDatePicker2"
		(dateChange)="changeTimePeriod(RbFormConstants.TIME_PERIOD.previousEnd,$event)"
		(focus)="$event.target.select()"
	/>
	<mat-datepicker-toggle matSuffix [for]="endDatePicker2"></mat-datepicker-toggle>
	<mat-datepicker #endDatePicker2></mat-datepicker>
</mat-form-field>


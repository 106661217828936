import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RbConstants } from '../../../../../common/constants/_rb.constants';
import { SiteTreeView } from '../../../../../api/sites/models/site-tree-view.model';

@Component({
	selector: 'rb-select-controller-dialog',
	templateUrl: './select-controller-dialog.component.html',
	styleUrls: ['./select-controller-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectControllerDialogComponent implements OnInit, OnDestroy {
	@Input() selectedControllerIds: number[] = [];
	@Input() treeItems: SiteTreeView[] = [];
	@Output() complete = new EventEmitter<number[]>();

	currentlySelectedControllerIds: number[];
	RbConstants = RbConstants;

	constructor(protected deviceManager: DeviceManagerService) { }

	ngOnInit() {
		// Make a copy for editing
		this.currentlySelectedControllerIds = this.selectedControllerIds == null ? [] : [...this.selectedControllerIds];
	}

	ngOnDestroy(): void {
		/* Implemented to support untilDestroyed */
	}

	onCancel() {
		this.complete.emit(null);
	}

	onSubmit() {
		this.complete.emit(this.currentlySelectedControllerIds);
	}

	get allSitesSelected() {
		return this.treeItems.every(site => this.isSiteSelected(site));
	}

	onSelectAll(change: MatCheckboxChange) {
		this.currentlySelectedControllerIds = [];
		if (change.checked) {
			this.treeItems.forEach(site => site.children.forEach(controller => {
				if (!controller.isDisabled) {
					this.currentlySelectedControllerIds.push(controller.id)
				}
			}));
		}
	}

	isSiteSelected(site: SiteTreeView): boolean {
		return site.children.length === 0 || site.children.every(controller => this.currentlySelectedControllerIds.some(id => controller.id === id));
	}

	isSomeControllerSelected(site: SiteTreeView): boolean {
		return site.children.length > 0 && site.children.some(controller => this.currentlySelectedControllerIds.some(id => controller.id === id));
	}

	onSelectSite(site: SiteTreeView, change: MatCheckboxChange) {
		if (change.checked) {
			site.children.forEach(controller => {
				if (controller.isDisabled || this.currentlySelectedControllerIds.some(id => controller.id === id)) return;
				this.currentlySelectedControllerIds.push(controller.id);
			});
		} else {
			this.currentlySelectedControllerIds = this.currentlySelectedControllerIds.filter(id => !site.children.some(controller => controller.id === id));
		}
	}

	isControllerSelected(controller: SiteTreeView): boolean {
		return this.currentlySelectedControllerIds.some(id => controller.id === id);
	}

	onSelectController(controller: SiteTreeView, change: MatCheckboxChange) {
		if (change.checked) {
			if (this.currentlySelectedControllerIds.some(id => controller.id === id)) return;
			this.currentlySelectedControllerIds.push(controller.id);
		} else {
			this.currentlySelectedControllerIds = this.currentlySelectedControllerIds.filter(id => controller.id !== id);
		}
	}
}

import { Observable, of, take, tap, timer } from 'rxjs';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CompanyManagerService } from '../companies/company-manager.service';
import { CultureSettings } from '../culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../culture-settings/culture-settings-manager.service';
import { EnvironmentService } from '../../common/services/environment.service';
import { Injectable } from '@angular/core';
import { LicenseManagerService } from '../license/license-manager.service';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { UserProfile } from './models/user-profile.model';

@Injectable({
	providedIn: 'root'
})
export class AuthManagerService {
	hasSettingsFetched = false;

	private static IDLE_CHECK_INTERVAL_IN_MS = 5000;
	private static AUTO_RENEW_SIGN_IN_PERIOD_IN_SECONDS = 10;

	private _currentUser: User;

	private userManager: UserManager;
	private timer: any;
	private gotUserManagerResult = false;
	private loginObservable: Observable<boolean>;
	private loginSubscriber: any;
	private _accessBetaFeatures = false;
	private _allowFieldTestFeatures = false;
	private _allow3rdPartyApi = false;
	private _allowSaaS = false;
	private _allowPST = false;
	private _isOwner = false;
	private _isAdmin = false;
	private _isFieldTech = false;
	private _isRBAdmin = false;
	private _isSiteAdmin = false;
	private _allowManualOps = false; // If User is at least a FieldTech they have access to some edits and Manual Ops
	private _isReadOnly = false;  // If User is Readonly or FieldTech they are View Only (cannot edit)
	private _hasDeveloperAccess = false;
	private _countryCode = '';
	private _limitedSiteAccess  = false;
	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private broadcastService: BroadcastService,
				private companyManager: CompanyManagerService,
				private licenseManager: LicenseManagerService,
				private cultureSettingsManager: CultureSettingsManagerService,
				private env: EnvironmentService
	) { }

	public init() {
		this.userManager = new UserManager(this.userManagerSettings);

		this.userManager.getUser()
			.then(user => {
				this.currentUser = user;
				this.gotUserManagerResult = true;
				if (this.isGspUser(user)) {
					this.startOauthSignOut();
				}
				if (this.currentUser) {
					localStorage.setItem('id_token_hint', this.currentUser.id_token);
				}
				if (this.loginObservable != null) {
					this.loginSubscriber.next(this.currentUser != null && !this.currentUser.expired);
					this.loginSubscriber.complete();
					this.loginObservable = null;
				}
				if (user != null) {
					this.broadcastService.userLoggedIn.next(null);
					this.stop();
				}
			})
			.catch(() => this.currentUser = null);

		this.userManager.events.addUserLoaded(user => this.currentUser = user);
		this.userManager.events.addUserUnloaded(() => this.currentUser = null);
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	public setUserIsActive() { }

	public get accessToken(): string { return this.currentUser == null ? null : this.currentUser.access_token; }

	public get tokenType(): string { return this.currentUser == null ? null : this.currentUser.token_type; }

	/** Guard services and welcome page should use isLoggedInObservable to wait on the response from the UserManager. **/
	public get isLoggedIn(): boolean {
		return this.currentUser != null && !this.currentUser.expired;
	}

	public get isLoggedInObservable(): Observable<boolean> {
		if (this.gotUserManagerResult) return of(this.currentUser != null && !this.currentUser.expired);
		if (this.loginObservable == null) {
			this.loginObservable = Observable.create(observer => this.loginSubscriber = observer);
		}
		return this.loginObservable;
	}

	public startOauthSignIn() {
		this.userManager.signinRedirect();
	}

	public startOauthSignOut() {
		const id_token = localStorage.getItem('id_token_hint');
		if (id_token) {
			this.userManager.signoutRedirect({ 'id_token_hint': id_token });
		} else {
			this.userManager.signoutRedirect();
		}
	}

	/**
	 * @summary refreshSignIn() should be called when some element of the user's token data has changed. Especially this
	 * applies when changing the user's selected language.
	 */
	public refreshSignIn(): Observable<User> {
		return this.renewSignIn();
	}

	private isGspUser(user: User): boolean {
		if (!user) return false;
		return +user.profile.group_level === +RbEnums.Common.GroupLevel.GspAdmin ||
			+user.profile.group_level === +RbEnums.Common.GroupLevel.GspSuperAdmin ||
			+user.profile.group_level === +RbEnums.Common.GroupLevel.GspUser;
	}

	// Start monitoring for "idle" timeouts.
	public start() {
		this.stop();
		if (this.currentUser == null) { return; }

		this.getCompanyPreferences$().subscribe(pref => {
				if (!pref) { return; }
				this.timer = timer(AuthManagerService.IDLE_CHECK_INTERVAL_IN_MS, AuthManagerService.IDLE_CHECK_INTERVAL_IN_MS)
					.subscribe(() => {
						this.isLoggedInObservable.subscribe((loggedIn: boolean) => {

							if (!loggedIn) {
								this.stop();
								this.startOauthSignOut();
								return;
							}

							// See if the user token is about to expire and renew it
							if (this.currentUser.expires_in < AuthManagerService.AUTO_RENEW_SIGN_IN_PERIOD_IN_SECONDS) {
								this.renewSignIn().subscribe();
							}
						});
					});
			});
		if (this.currentUser == null) { return; }
		this.cultureSettingsManager.getCultureSettings(this.currentUser.profile.culture_id)
			.subscribe(culture => {
				if (culture == null) { return; }
				this.cultureSettingsManager.cultureSetting = culture;
			});
		this.licenseManager.isCloud().pipe(take(1)).subscribe();
	}

	getCompanyPreferences$() {

		return this.companyManager.getCompanyPreferences()
		.pipe(
			tap(pref => {
				this.hasSettingsFetched = true;
				if (!pref) { return; }
				this._accessBetaFeatures = pref.accessBetaFeatures;
				this._allowFieldTestFeatures = pref.allowFieldTestFeatures;
				this._allow3rdPartyApi = pref.allow3rdPartyApi;
				this._allowSaaS = pref.allowSaaS;
				this._allowPST = pref.allowPST;
				this._countryCode = pref.countryCode;
			})
		);
	}

	public stop() {
		if (this.timer != null) {
			this.timer.unsubscribe();
			this.timer = null;
		}
	}

	getUserProfile(): UserProfile {
		if (!this.currentUser) { return null; }
		return UserProfile.createFromOidcUserProfile(this.currentUser.profile);
	}

	// RB-11408: Move currentUser to a getter/setter pattern so we can automatically update
	// things like isOwner as soon as we know the answer, rather than spreading the initialization
	// all over the code.
	public get currentUser(): User {
		return this._currentUser;
	}
	public set currentUser(user: User) {
		this._currentUser = user;
		this.setPropertiesFromCurrentUser();
	}

	get userCulture(): CultureSettings {
		if (!this.cultureSettingsManager.cultureSetting) { return null; }
		return this.cultureSettingsManager.cultureSetting;
	}

	get isOwner(): boolean {
		return this._isOwner;
	}

	get isAdmin(): boolean {
		return this._isAdmin;
	}

	get isLimitedSiteAccess(): boolean {
		return this._limitedSiteAccess;
	}

	get isFieldTech(): boolean {
		return this._isFieldTech;
	}

	get isRBAdmin(): boolean {
		return this._isRBAdmin;
	}

	get isSiteAdmin(): boolean {
		return this._isSiteAdmin;
	}

	get allowFieldTestFeatures(): boolean {
		return this._allowFieldTestFeatures;
	}

	get allow3rdPartyApi(): boolean {
		return this._allow3rdPartyApi;
	}

	get accessBetaFeatures(): boolean {
		return this._accessBetaFeatures;
	}

	get allowSaaS(): boolean {
		return this._allowSaaS;
	}

	get allowPST(): boolean {
		return this._allowPST;
	}

	get isReadOnly(): boolean {
		return this._isReadOnly;
	}

	get hasDeveloperAccess(): boolean {
		return this._hasDeveloperAccess;
	}

	get isManualOpsDenied(): boolean {
		return this._allowManualOps;
	}

	// Use this to hide/show features in development for IQ4Pro.
	get showIQ4ProFeatures(): boolean {
		return true;
	}


	get countryCode(): string {
		return this._countryCode;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private renewSignIn(): Observable<User> {
		return Observable.create(observer => {
			this.userManager.signinSilent()
				.then(user => {
					observer.next(user);
					observer.complete();
				});
		});
	}

	private get userManagerSettings(): UserManagerSettings {
		const rbcc_ui = `${this.env.rbcc_ui}`;
		return {
			authority: `${this.env.identityServer4Url}`,
			client_id: `${this.env.client_id}`,
			redirect_uri: `${rbcc_ui}/auth.html`,
			post_logout_redirect_uri: rbcc_ui,
			response_type: 'id_token token',
			scope: 'coreAPI.read coreAPI.write openid profile',
			silent_redirect_uri: `${rbcc_ui}/silent-renew.html`,
			automaticSilentRenew: false,
			accessTokenExpiringNotificationTime: 4,
			filterProtocolClaims: true,
			loadUserInfo: true,
		};
	}

	/**
	 * RB-11408: Copy information from the current user (this.currentUser.profile), to our local "cache" of attribute
	 * items like _isOwner, _isAdmin, etc. If called as soon as the user changes, this will correctly setup those flags
	 * so they can be used.
	 */
	private setPropertiesFromCurrentUser() {
		if (this.currentUser != null && this.currentUser.profile != null) {
			this._isOwner = +this.currentUser.profile.group_level === +RbEnums.Common.GroupLevel.RootAdmin;
			this._isAdmin = +this.currentUser.profile.group_level === +RbEnums.Common.GroupLevel.Admin;
			this._isFieldTech = +this.currentUser.profile.group_level === +RbEnums.Common.GroupLevel.FieldTech;
			this._isRBAdmin = +this.currentUser.profile.group_level === +RbEnums.Common.GroupLevel.RBAdmin;
			this._isSiteAdmin = +this.currentUser.profile.group_level >= +RbEnums.Common.GroupLevel.PowerUser;
			this._allowManualOps = +this.currentUser.profile.group_level > +RbEnums.Common.GroupLevel.ReadOnlyUser; // Field Tech users can access ManualOps
			this._isReadOnly = +this.currentUser.profile.group_level <= +RbEnums.Common.GroupLevel.FieldTech; // Readonly and FieldTech cannot edit
			this._hasDeveloperAccess = +this.currentUser.profile.group_level >= +RbEnums.Common.GroupLevel.RBDev;
			// the users that have limited access to sites, they can only see assigned sites or sites they created
			this._limitedSiteAccess = +this.currentUser.profile.group_level < +RbEnums.Common.GroupLevel.Admin;
		} else {
			this._isOwner = false;
			this._isAdmin = false;
			this._isFieldTech = false;
			this._isRBAdmin = false;
			this._isSiteAdmin = false;
			this._allowManualOps = false;
			this._isReadOnly = true;
			this._hasDeveloperAccess = false;
		}
	}
}

<button
	mat-fab
	class="w-10 z-1"
	type="button"
	[disabled]="disabled"
	(click)="fabButtonClicked.emit()"
>
	<mat-icon aria-label="add" *ngIf="showIcon">add</mat-icon>
	<span *ngIf="hasButtonText">{{buttonText}}</span>
</button>

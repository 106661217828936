import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { debounceTime, Subject, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { AutoContact } from '../../../api/auto-contact/models/auto-contact.model';
import { ConversionService } from '../../../common/services/conversion.service';
import { CultureSettings } from '../../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../../api/culture-settings/culture-settings-manager.service';
import { DateAndTimeService } from '../../../common/services/date-and-time.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { DryRunFlowDatum } from '../../../api/dry-run/models/dry-run-flow-datum.model';
import { DryRunManagerService } from '../../../api/dry-run/dry-run-manager.service';
import { DryRunProgramDatum } from '../../../api/dry-run/models/dry-run-program-datum.model';
import { DryRunProgramGroupInfo } from '../../../api/dry-run/models/dry-run-program-group-info.model';
import { DryRunPumpDatum } from '../../../api/dry-run/models/dry-run-pump-datum.model';
import { DryRunResult } from '../../../api/dry-run/models/dry-run-result.model';
import { EnvironmentService } from '../../../common/services/environment.service';
import { FlowDatumActiveStation } from '../../../api/dry-run/models/flow-datum-active-station.model';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { ReportPdfService } from '../../../sections/reports/common/pdf/report-pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { UnitLabelService } from '../../../common/services/unit-label.service';

const More = require('highcharts/highcharts-more');
const PatterFill = require('highcharts/modules/pattern-fill');
More(Highcharts);
PatterFill(Highcharts);

@UntilDestroy()
@Component({
	selector: 'rb-dry-run-chart',
	templateUrl: './dry-run-chart.component.html',
	styleUrls: ['./dry-run-chart.component.scss']
})
export class DryRunChartComponent implements OnInit, OnDestroy, OnChanges {
	@HostBinding('class') class = 'rb-dry-run-chart';

	@Input() chartTitle = '';
	@Input() maxFlow = 0;
	@Input() rainDelayRemaining = 0;
	@Input() ignoreRainDelay = false;
	// autocontact
	@Input() syncContacts: AutoContact[] = [];
	@Input() retrievalContacts: AutoContact[] = [];
	@Input() reverseSyncContacts: AutoContact[] = [];
	@Input() showPumpsOnGraph = false;
	private _dryRunResult: DryRunResult = null;
	@Input() set dryRunResult(value: DryRunResult) {
		if (value === null || value === undefined || (value.flowData.length < 1 && this.rainDelayRemaining < 1)) return;

		this._dryRunResult = value;
		this.setValues();
	}

	get dryRunResult(): DryRunResult {
		return this._dryRunResult;
	}

	private readonly CHART_MARKER_FILL_COLOR = '#eea148';
	private readonly CHART_MARKER_LINE_COLOR = 'white';
	private readonly CHART_AREA_FILL_COLOR = '#80b579';
	private readonly CHART_LOGS_COLOR = '#BCD2E8';
	private readonly CHART_SYNC_COLOR = '#528AAE';
	private readonly CHART_REVERSE_SYNC_COLOR = '#ADFF2F';
	private chart: Highcharts.Chart;
	private chartMouseMoved$ = new Subject<boolean>();

	TimeTravelUnit = RbEnums.Common.TimeTravelUnit;

	cultureSettings: CultureSettings;
	isGolfSite = false;
	totalFlowHeader = '-';
	flowRateLabel = '';

	currentHoverPointIndex = 0;
	currentFlowDataIndex = 0;

	currentFlowDataDate = '-';
	currentFlowDataTime = '-';
	currentFlowDataFlowRate = '-';
	currentActiveStations: FlowDatumActiveStation[];
	currentActivePumps: DryRunPumpDatum[];
	totalPumps: DryRunPumpDatum[];
	currentRunningPrograms: DryRunProgramDatum[];
	currentRunningProgramGroups: DryRunProgramGroupInfo[];

	Highcharts = Highcharts;
	showChart = false;
	chartOptions: any = null;
	expandedFlowDatum: DryRunFlowDatum[] = [];
	expandedFlowPumpsDatum = [];
	updateFlag = false;

	
	maxFlowRate;
	isMobile = false;
	existingZeroFlowRateStation = false;
	zeroFlowDatumArray: any[] = [];
	rainDelayData: any[] = [];
	flowDatumArray: any[] = [];
	autoContactLines = [];

	// =========================================================================================================================================================
	// C'tor, Init
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private conversionService: ConversionService,
				private cultureSettingsManager: CultureSettingsManagerService,
				private dateAndTimeService: DateAndTimeService,
				private deviceManager: DeviceManagerService,
				private unitLabelService: UnitLabelService,
				private reportPdfService: ReportPdfService,
				private translateService: TranslateService,
				private dryRunManager: DryRunManagerService,
				public env: EnvironmentService
	) {

		// [RB-9911]: need to set value for isGolfSite in contrustror because dryRunResult (Input) runs before ngOnInit
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
	}

	ngOnInit() {
		this.isMobile = this.deviceManager.isMobile;
		this.reportPdfService.initPdfDocument();
		this.watchDataChange();
	}

	// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
	ngOnChanges(changes: SimpleChanges){
		if(changes.showPumpsOnGraph){
			this.setupChart();
			
			this.updateFlag = true;
		}
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Highchart Event Handlers
	// =========================================================================================================================================================

	// TODO: Angular Migration - It appears this callback no longer get called.
	// Get reference to HighChart Chart object.
	chartCallback(chart) {
		// Avoid getting a chart object during exportPdf that becomes invalid
		if (this.chart) return;

		this.chart = chart;
	}

	// TODO: Angular Migration - This event appears to replace the chartCallback. Test and remove chartCallback.
	onChartInstance(chart) {
		// Avoid getting a chart object during exportPdf that becomes invalid
		if (this.chart) return;

		this.chart = chart;
	}

	onMouseMove(event: any) {
		if (!this.chart) return;

		const hoverPoint = this.chart.hoverPoint;
		if (hoverPoint !== null && hoverPoint !== undefined) {
			// Unselect any previously selected points.
			this.chart.getSelectedPoints().forEach(p => {
					p.select();
				});

			// Select new point
			this.chart.hoverPoint.select(true);

			// Update related component data.
			this.currentHoverPointIndex = (<any>hoverPoint).index;
			this.currentFlowDataIndex = this.currentHoverPointIndex;
			// this.updateDatumDetails();
			this.chartMouseMoved$.next(true);
		}
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getProgramGroupPrograms(programGroupId: number) {
		return this.currentRunningPrograms.filter(p => p.parentId === programGroupId);
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	// onTimeTravel(amount: number, unit: RbEnums.Common.TimeTravelUnit) {
	onTimeTravel(travelInfo: { amount: number, unit: RbEnums.Common.TimeTravelUnit }) {
		if (!this.chart || !this.chart.series) return;

		const amount = travelInfo.amount;
		const unit = travelInfo.unit;
		if (unit === RbEnums.Common.TimeTravelUnit.TimeStamp || unit === RbEnums.Common.TimeTravelUnit.Hour ||
			unit === RbEnums.Common.TimeTravelUnit.Minute ) {
			let newFlowDatumItemIndex: number = this.currentFlowDataIndex;
			let newTargetTimestamp: Date;
			if (unit === RbEnums.Common.TimeTravelUnit.TimeStamp) {
				if (amount >= 0) {
					newFlowDatumItemIndex =
						((this.currentFlowDataIndex + 1) < this.expandedFlowDatum.length) ? (this.currentFlowDataIndex + 1) : this.currentFlowDataIndex;
				} else {
					newFlowDatumItemIndex =  ((this.currentFlowDataIndex - 1) > -1) ? (this.currentFlowDataIndex - 1) : 0;
				}
			} else if (unit === RbEnums.Common.TimeTravelUnit.Minute || unit === RbEnums.Common.TimeTravelUnit.Hour) {
				const minutesOffset = Math.abs((unit === RbEnums.Common.TimeTravelUnit.Minute) ? amount : amount * 60);
				const currentTimestamp = this.expandedFlowDatum[this.currentFlowDataIndex].timeOffset;
				if (amount >= 0) {
					newTargetTimestamp = moment(currentTimestamp).add(minutesOffset, 'minute').toDate();
				} else {
					newTargetTimestamp = moment(currentTimestamp).subtract(minutesOffset, 'minute').toDate();
				}
				newFlowDatumItemIndex = this.findIndex(newTargetTimestamp);
			}

			// If we successfully found an expandedFlowDatum item within our new target date range, we set the currentFlowDataIndex (this is the point
			// that will be selected on the chart) to this items index. If we didn't find an expandedFlowDatum item w/in the range, we set the
			// currentFlowDataIndex to the appropriate endpoint of the chart.
			if (newFlowDatumItemIndex !== -1) {
				this.currentFlowDataIndex = newFlowDatumItemIndex;
			} else {
				this.currentFlowDataIndex = (amount >= 0)
					? this.chart.series[0].points[this.chart.series[0].points.length - 1].index
					: this.chart.series[0].points[0].index;
			}
		} else {
			// Set to the appropriate end point.
			this.currentFlowDataIndex = (amount >= 0) ? this.chart.series[0].points.length - 1 : 0;
		}

		// Once we've set the currentFlowDataIndex (above), we attempt to fetch the appropriate point (index) the chart's data series.
		let selectedPoint = this.chart.series[0].points.find(p => p.index === this.currentFlowDataIndex);
		// If the selected point is outside the range of the chart, which can happen if we are zoomed, set the point to the appropriate end point.
		if (selectedPoint === undefined) {
			selectedPoint = (amount >= 0) ? this.chart.series[0].points[this.chart.series[0].points.length - 2] : this.chart.series[0].points[1];
			this.currentFlowDataIndex = selectedPoint.index;
		}

		// A bit of a hack to ensure we have a end point selected when we are zoomed in. By default the end points do not display as selected.
		// When the xAxis is zoomed, the userMin and userMax properties will be defined.
		if ((<any>this.chart.xAxis[0]).userMin !== undefined && (<any>this.chart.xAxis[0]).userMax !== undefined) {
			if (selectedPoint.index === this.chart.series[0].points[this.chart.series[0].points.length - 1].index) {
				selectedPoint = this.chart.series[0].points[this.chart.series[0].points.length - 2];
			} else if (selectedPoint.index === this.chart.series[0].points[0].index) {
				selectedPoint = this.chart.series[0].points[1];
			}
			this.currentFlowDataIndex = selectedPoint.index;
		}
		// Show the selected point on the graph.
		// The following two lines appears a bit redundant, but it is required to ensure selection of the current point.
		selectedPoint.select(false);
		selectedPoint.select(true);
		// updateDatumDetails() uses this.currentFlowDataIndex to show the appropriate expandedFlowDatum details.
		this.updateDatumDetails();
	}

	findIndex(newTargetTimestamp: Date) {
		for (let i = 0; i < this.expandedFlowDatum.length - 1; i++) {
			if (newTargetTimestamp.valueOf() >= this.expandedFlowDatum[i].timeOffset.valueOf() &&
				this.expandedFlowDatum[i + 1].timeOffset.valueOf() >= newTargetTimestamp.valueOf()) {
					if ((newTargetTimestamp.valueOf() - this.expandedFlowDatum[i].timeOffset.valueOf())
					< (this.expandedFlowDatum[i + 1].timeOffset.valueOf() - newTargetTimestamp.valueOf()))
					return i;
				else
					return i + 1;
			}
		}
		return -1;
	}

	async generatePdf() {
		const pdfBrowserWindow = this.deviceManager.isSafari && !this.deviceManager.isDesktop ? window.open() : null;
		const pdfInfo = this.reportPdfService.createPdfDocument('l');

		// Add Header
		pdfInfo.pdf.setFont(pdfInfo.getFont);
		pdfInfo.pdf.setFontSize(pdfInfo.dim.veryLargeFontSize);
		let blockHeight = pdfInfo.outputTextBlock(pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom, pdfInfo.bodyWidth,
			this.translateService.instant('STRINGS.DRY_RUN'), RbConstants.Form.REPORT_PAGE.primary, '#FFFFFF');
		pdfInfo.outputBottom += blockHeight - 1;

		// Controller / Interface Name
		pdfInfo.pdf.setFontSize(pdfInfo.dim.largeFontSize);
		pdfInfo.pdf.setTextColor(RbConstants.Form.REPORT_PAGE.grayText);
		blockHeight = pdfInfo.outputWrappedText(pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom, pdfInfo.bodyWidth * .5, this.chartTitle);

		// Total Flow Info
		pdfInfo.outputRightAlignedText(pdfInfo.pageWidth - (pdfInfo.dim.horizontalMargin + 2), pdfInfo.outputBottom, this.totalFlowHeader);
		pdfInfo.outputBottom += blockHeight - 15;

		// Line
		pdfInfo.pdf.setDrawColor(RbConstants.Form.REPORT_PAGE.primary);
		pdfInfo.pdf.setLineWidth(3);
		pdfInfo.pdf.line(pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom, pdfInfo.pageWidth - pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom);
		pdfInfo.outputBottom += 12;

		// Time Period
		if (this.expandedFlowDatum.length > 0) {
			pdfInfo.pdf.setFontSize(pdfInfo.dim.regularFontSize);
			pdfInfo.pdf.text(this.translateService.instant('REPORTS.TIME_PERIOD', this.getTimePeriod()), pdfInfo.dim.horizontalMargin, pdfInfo.outputBottom);
			pdfInfo.outputBottom += pdfInfo.lineHeight;
		}

		// Generate and save the PDF.
		pdfInfo.outputChart(this.chart);
		this.reportPdfService.save(pdfInfo, this.translateService.instant('REPORTS.DRY_RUN_FILE', { type: 'chart' }), pdfBrowserWindow);
	}

	private getTimePeriod(): { start: string, stop: string } {
		const startDate = this.expandedFlowDatum[0].timeOffset;
		const endDate = this.expandedFlowDatum[this.expandedFlowDatum.length - 1].timeOffset;

		return {
			start: `${this.dateAndTimeService.dateToUserDateString(startDate)} ${this.dateAndTimeService.dateToUserTimeString(startDate)}`,
			stop: `${this.dateAndTimeService.dateToUserDateString(endDate)} ${this.dateAndTimeService.dateToUserTimeString(endDate)}`
		};
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================
	private watchDataChange() {
		// Monitor culture changes to show proper units.
		this.cultureSettingsManager.cultureSettingsChange
			.pipe(untilDestroyed(this))
			.subscribe((cultureSettings: CultureSettings) => {
				this.cultureSettings = cultureSettings;
				this.setValues();
			});
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => this.isMobile = isMobile);

		this.chartMouseMoved$.pipe(untilDestroyed(this), debounceTime(300)).subscribe(_ => {
			this.updateDatumDetails();
		})
	}

	private setRainDelayData(startTime: Date): Date {
		this.rainDelayData = [];
		if (this.ignoreRainDelay || this.rainDelayRemaining < 1) return startTime;
		let endTime = moment(startTime).add(this.rainDelayRemaining, 'days').startOf('day').toDate();
		if (this.dryRunResult.dryRunEnd.getTime() < endTime.getTime())
			endTime = this.dryRunResult.dryRunEnd;
		let maxFlow = 1.0;
		this.addEmptyPoints(startTime, endTime);
		if (this.maxFlowRate > maxFlow) maxFlow = this.maxFlowRate;
		this.rainDelayData.push([RbUtils.Conversion.convertDateToUTCMilliseconds(startTime), 0]);
		this.rainDelayData.push([RbUtils.Conversion.convertDateToUTCMilliseconds(startTime), maxFlow]);
		this.rainDelayData.push([RbUtils.Conversion.convertDateToUTCMilliseconds(moment(startTime).add(1, 'minutes').toDate()), maxFlow]);
		this.rainDelayData.push([RbUtils.Conversion.convertDateToUTCMilliseconds(endTime), maxFlow]);
		this.rainDelayData.push([RbUtils.Conversion.convertDateToUTCMilliseconds(endTime), 0]);
		return endTime;
	}

	// Figure out a station is on but has zero flow rate - IQ4 only
	// if the site is not a golf site and the flow rate is zero and the params are 1,
	// then station is running with zero flow rate.
	private stationOnWithZeroFlowRate(datum: DryRunFlowDatum) {
		return !this.isGolfSite && datum.flowRate === 0 && datum.params == "1";
	}

	// Figure out the last zero entry of a station
	// if the flow rate is zero and the stop time of any running program
	// is the same as the datum timestamp, then we have an entry, zero flow rate
	private stationWithZeroFlowRateAddOn(datum: DryRunFlowDatum) {
		return !this.isGolfSite && datum.flowRate === 0 && Object.keys(this.dryRunResult.runningPrograms)
		.some(rpk => this.dryRunResult.runningPrograms[rpk]
			.some(rp => rp.stopTimeOffset.getTime() !== datum.timeOffset.getTime()));
	}

	// Figure out at least one station has zero flow rate warning
	// if any of the drFlowDatum have a station on with a zero flow rate, it's true
	private hasStationOnWithZeroFlowRate(drFlowDatum: DryRunFlowDatum[]) {
		return drFlowDatum.some(datum => this.stationOnWithZeroFlowRate(datum));
	}

	private setValues() {
		if (!this.dryRunResult) return;
		// Set flow rate label for flow data.
		this.flowRateLabel = this.unitLabelService.getFlowRateLabel();
		// Total Flow
		const convertedTotalFlow = this.conversionService.getUserTotalFlowString(this.dryRunResult.totalFlow);

		// RB-10633: Italian translation fixes noted the difference between total flow (rate), which uses TOTAL_FLOW string, and
		// total flow volume which needs a different translation in Italian (and potentially others). Avoided changing for IQ4.
		this.totalFlowHeader = this.isGolfSite ?
			`${RbUtils.Translate.instant('STRINGS.TOTAL_FLOW_VOLUME')}: ${convertedTotalFlow} ${this.unitLabelService.getFlowLabel()}` :
			`${RbUtils.Translate.instant('STRINGS.TOTAL_FLOW')}: ${convertedTotalFlow} ${this.unitLabelService.getFlowLabel()}`;

		this.expandedFlowDatum = [];
		this.zeroFlowDatumArray = [];
		this.existingZeroFlowRateStation = false;
		let previousFlowDatum: DryRunFlowDatum = null;

		// Create any points that occur between requested dry run start date and first dryRunResult.flowData point. This ensures the chart represents the
		// entire requested period of performance.
		let newStartTime = this.dryRunResult.dryRunStart;

		if (this.dryRunResult.flowData && this.dryRunResult.flowData.length > 0) {
			this.existingZeroFlowRateStation = this.hasStationOnWithZeroFlowRate(this.dryRunResult.flowData);
			this.maxFlowRate = Math.max.apply(Math, this.dryRunResult.flowData.map(flow => flow.flowRate));
			this.maxFlowRate = Math.max(this.maxFlowRate, this.maxFlow);
			this.maxFlowRate = this.conversionService.getUserFlowRate(this.maxFlowRate);
			if (this.maxFlowRate > 0)
				this.maxFlowRate = this.maxFlowRate * 1.1;
			else
				this.maxFlowRate = 1.0;
			newStartTime = this.setRainDelayData(this.dryRunResult.dryRunStart);

			const leadFlowDatum = this.dryRunResult.flowData[0];
			this.addEmptyPoints(newStartTime, leadFlowDatum.timeOffset);

			// If the difference between the StartTime of the simulation and the leadFlowDatum is 0 minutes,
			// we will need to add an entry zero point at the begging of graph - IQ4 only
			const perMinDiff = moment(newStartTime).diff(moment(leadFlowDatum.timeOffset), 'minutes') === 0 && !this.isGolfSite;
			if (perMinDiff) {
				const flowDatum = new DryRunFlowDatum();
				flowDatum.timeOffset = newStartTime;
				// at least a program has stations with zero flow rate is running
				if (this.existingZeroFlowRateStation) {
					if (this.stationOnWithZeroFlowRate(leadFlowDatum)) {
						this.addZeroFlowPoint(flowDatum);
					}
					else if (this.stationWithZeroFlowRateAddOn(leadFlowDatum)) {
						this.addZeroFlowPoint(flowDatum, true);
					}
				}
				this.expandedFlowDatum.push(flowDatum);
			}
			// some points may have got added at the start, so set previousFlowDatum suitably
			if ((this.expandedFlowDatum != null) && (this.expandedFlowDatum.length > 0)) {
				previousFlowDatum = this.expandedFlowDatum[this.expandedFlowDatum.length - 1];
			}
			else if ((this.zeroFlowDatumArray != null) && (this.zeroFlowDatumArray.length > 0)) {
				previousFlowDatum = this.zeroFlowDatumArray[this.zeroFlowDatumArray.length - 1];
			}
		} else {
			newStartTime = this.setRainDelayData(this.dryRunResult.dryRunStart);
			if (this.dryRunResult.dryRunEnd.getTime() > newStartTime.getTime())
				this.addEmptyPoints(newStartTime, this.dryRunResult.dryRunEnd);
		}

		// Iterate through the flowData returned and slice up into one minute intervals.
		this.dryRunResult.flowData.forEach((datum, idx) => {
			if (!previousFlowDatum) {
				previousFlowDatum = datum;
				return;
			}
			// Check datum is zero flow rate datum
			const isZeroFlowRateDatum = this.stationOnWithZeroFlowRate(datum);
			const isZeroFlowRateDatumEntry = this.stationWithZeroFlowRateAddOn(datum) && this.existingZeroFlowRateStation && !isZeroFlowRateDatum;

			// assign isZeroFlowRateDatum to model
			datum.isZeroFlowRateDatum = isZeroFlowRateDatum;
			let isAddedForMinChangeFlowChange = false;

			// Adding empty points to the station zero flow rate.
			if (idx === 0) {
				if (isZeroFlowRateDatumEntry) {
					this.addEmptyPointsToStationZeroFlowRate(newStartTime, datum.timeOffset);
				}
			}
			// let millisecToSetForNewPoint: number = 0;
			/* RB-10219: CirrusIC - Dryrun graph is not accurate with cycle and
			soak and non-irrigation stations. Fix the dryrun data before drawing so that when the dryrun result flowrate
			changes from first timestamp to second timestamp, create a new datum point having flowrate and all properties
			same as first datum and only the timestamp similar to second datum. This would give the continuity of data from the first timestamp to
			the start of the second timestamp and the flow graph would more correct and not show a drop or rise
			in flow rate from first datum to second datum flowrate value for such cases.
			eg. time: 11:56:08 flowrate: 20, time: 11:57:06 flowrate: 10,
			then after the fix we have one additional data point here as below
			time: 11:56:08 flowrate: 20, time: 11:57:06 flowrate: 20, time: 11:57:06 flowrate: 10,
			*/
			if ((previousFlowDatum !== null) && (previousFlowDatum.timeOffset !== datum.timeOffset)
				&& (isZeroFlowRateDatum || previousFlowDatum.isZeroFlowRateDatum || previousFlowDatum.flowRate !== datum.flowRate)) {
					// when flowrate is changing, we want a data point that
					const adjustedDatum = { ...previousFlowDatum, timeOffset: moment(datum.timeOffset).subtract(1, 'milliseconds').toDate() };
					adjustedDatum.isZeroFlowRateDatum = isZeroFlowRateDatum;
					this.expandedFlowDatum.push(adjustedDatum);
					isAddedForMinChangeFlowChange = true;
					this.expandedFlowDatum.push(datum);

					// From zero flow rate to zero flow rate with graph changes
					// If drData is station is running with zero flow rate, we draw the zero flow rate point on the graph
					// eg: drData at time: 10:05:05, we have time 10:05:04, time: 10:05:06
					if (isZeroFlowRateDatum) {
						this.addZeroFlowPoint(adjustedDatum, true);
						this.addZeroFlowPoint(datum);
						if (previousFlowDatum.isZeroFlowRateDatum || previousFlowDatum.flowRate === 0 || previousFlowDatum.flowRate !== datum.flowRate) {
								const nextDummyFlowDatum = new DryRunFlowDatum();
								nextDummyFlowDatum.timeOffset = moment(datum.timeOffset).add(1, 'milliseconds').toDate();
								this.addZeroFlowPoint(nextDummyFlowDatum, true);
						}
					}
					previousFlowDatum = adjustedDatum;
			} else {
				previousFlowDatum = datum;
			}

			if (datum.timeOffset <= newStartTime ) {
				previousFlowDatum = datum;
				previousFlowDatum.timeOffset = newStartTime;
				return;
			}

			// Create 1 minute time slices for each minute between previously provided data point and current data point.
			// This allows us to display proper flow rate between data points provided by DryRunResult.
			const runTimeMinutes = moment(datum.timeOffset).diff(moment(previousFlowDatum.timeOffset), 'minutes');
			// If runtime is less than 1 minute, just add the single point and continue to next data point. No time slices.
			if (runTimeMinutes < 1) {
				if (!isAddedForMinChangeFlowChange) {
					this.expandedFlowDatum.push(previousFlowDatum);
				}
				previousFlowDatum = datum;
				return;
			}
			// Add a data point for each 1 minute time slice.
			for (let i = 0; i < runTimeMinutes; i++) {
				// Add a flowDatum for each minute
				if ((i > 0) ||  ((i === 0) && !isAddedForMinChangeFlowChange) )  {
					const adjustedDatum = { ...previousFlowDatum, timeOffset: moment(previousFlowDatum.timeOffset).add(i, 'minutes').toDate() };
					this.expandedFlowDatum.push(adjustedDatum);
				}
			}

			// Capture last minute (59s) before next provided data point.
			const lastFlowDatum = this.expandedFlowDatum[this.expandedFlowDatum.length - 1];
			const adjDatum = { ...lastFlowDatum, timeOffset: moment(lastFlowDatum.timeOffset).add(59, 'seconds').toDate() };
			this.expandedFlowDatum.push(adjDatum);
		});

		// Add very last data point provided by DryRunResult.
		if (previousFlowDatum) {
			this.expandedFlowDatum.push(previousFlowDatum);
			// Create any points that occur between last dryRunResult.flowData point and requested dry run end date. This ensures the chart represents the
			// entire requested period of performance.
			this.addEmptyPoints(previousFlowDatum.timeOffset, this.dryRunResult.dryRunEnd, true);
		}
		this.currentFlowDataIndex = 0;
		this.flowDatumArray = [];

		// set up chart data
		this.expandedFlowDatum.forEach(flowDatum => {
				this.addPoint(flowDatum);
			});


		this.getPumpsFromDatum();
		this.setupChart();
	}

	private addPoint(flowDatum: DryRunFlowDatum) {
		this.flowDatumArray.push([RbUtils.Conversion.convertDateToUTCMilliseconds(flowDatum.timeOffset),
			this.conversionService.getUserFlowRate(flowDatum.flowRate)]);
	}

	private addZeroFlowPoint(flowDatum: DryRunFlowDatum, isEmpty?: boolean) {
		if(!this.isGolfSite){
			this.zeroFlowDatumArray.push([RbUtils.Conversion.convertDateToUTCMilliseconds(flowDatum.timeOffset),
				this.conversionService.getUserFlowRate(isEmpty ? 0 : this.maxFlowRate / 50)]);
		}
	}

	private addEmptyPoints(startDate: Date, endDate: Date, addExact: boolean = false) {
		const preMinutes = moment(endDate).diff(moment(startDate), 'minutes');

		for (let i = 0; i < preMinutes; i++) {
			// Add a flowDatum for each minute
			const flowDatum = new DryRunFlowDatum();
			flowDatum.timeOffset = moment(startDate).add(i, 'minutes').toDate();
			this.expandedFlowDatum.push(flowDatum);
		}
		if (addExact) {
			if (this.expandedFlowDatum[this.expandedFlowDatum.length - 1].timeOffset < endDate) {
				const flowDatum = new DryRunFlowDatum();
				flowDatum.timeOffset = endDate;
				this.expandedFlowDatum.push(flowDatum);
			}
		}
	}

	private addEmptyPointsToStationZeroFlowRate(startDate: Date, endDate: Date, entryPoint: boolean = false) {
		const preMinutes = moment(endDate).diff(moment(startDate), 'minutes');
		for (let i = 0; i < preMinutes; i++) {
			const flowDatum = new DryRunFlowDatum();
			flowDatum.timeOffset = moment(startDate).add(i, 'minutes').toDate();
			this.addZeroFlowPoint(flowDatum, entryPoint);
		}
	}

	private getFlowDatum(index: number): boolean {
		const currentFlowDatum = this.expandedFlowDatum[this.currentFlowDataIndex];
		if (currentFlowDatum) {
			// Current Flow Datum Station Information
			this.currentActiveStations = [];
			this.currentActivePumps = [];
			this.currentFlowDataFlowRate = this.conversionService.getUserFlowRateString(currentFlowDatum.flowRate);
			this.currentFlowDataDate = this.dateAndTimeService.dateToUserDateString(currentFlowDatum.timeOffset);
			this.currentFlowDataTime = this.dateAndTimeService.dateToUserTimeString(currentFlowDatum.timeOffset, true);
			if (this.isGolfSite && currentFlowDatum.params && currentFlowDatum.params.length > 0) {
				const pumps = currentFlowDatum.params.split(';', 50);
				pumps.forEach(pump => {
					const activePump = pump.split('|', 5);
					const flowRateString = `${this.conversionService.getUserFlowRateString(+activePump[2])} ${this.flowRateLabel}`;
					this.currentActivePumps.push(new DryRunPumpDatum(+activePump[0], activePump[1], flowRateString));
				});
			}
			if (currentFlowDatum.dryRunFlowDatumStation && currentFlowDatum.dryRunFlowDatumStation.length > 0) {
				currentFlowDatum.dryRunFlowDatumStation.forEach(stationDatum => {
					const flowRateString = `${this.conversionService.getUserFlowRateString(stationDatum.flowRate)} ${this.flowRateLabel}`;
					this.currentActiveStations.push(new FlowDatumActiveStation(stationDatum.stationName, flowRateString));
				});
			} else if (currentFlowDatum.id != null) {
				this.getDryRunFlowDatumStations(currentFlowDatum);
			}
			return true;
		} else {
			return false;
		}
	}

	getDryRunFlowDatumStations(currentFlowDatum: DryRunFlowDatum) {
		this.dryRunManager.getDryRunFlowDatumStations(currentFlowDatum.id).pipe(take(1)).subscribe(result => {
			currentFlowDatum.dryRunFlowDatumStation = result;
			this.currentActiveStations = [];
			currentFlowDatum.dryRunFlowDatumStation.forEach(stationDatum => {
				const flowRateString = `${this.conversionService.getUserFlowRateString(stationDatum.flowRate)} ${this.flowRateLabel}`;
				const activeStation = new FlowDatumActiveStation(stationDatum.stationName, flowRateString);
				if (!this.currentActiveStations.find(station => station.name === activeStation.name))
					this.currentActiveStations.push(activeStation);
			});
		});
	}

	getPumpsFromDatum(){
		this.totalPumps = [];
		var pumpsData = [];
		this.expandedFlowPumpsDatum = [];
		this.expandedFlowDatum.forEach(datum => {
			if (this.isGolfSite && datum.params && datum.params.length > 0) {
				const pumps = datum.params.split(';', 50);
				pumps.forEach(pump => {
					const activePump = pump.split('|', 5);
					const foundPump = new DryRunPumpDatum(+activePump[0], activePump[1]);
					if(!this.totalPumps.find(pump => pump.id === foundPump.id)){
						this.totalPumps.push(foundPump);
					}
					var datumFlow = [RbUtils.Conversion.convertDateToUTCMilliseconds(datum.timeOffset),
						this.conversionService.getUserFlowRate(Number(activePump[2]))];
					pumpsData.push([foundPump.name, foundPump.id, datumFlow]);
				});
			}
		});
		
		this.totalPumps.forEach(pump =>{
			var pumpDatums = [];
			this.expandedFlowDatum.forEach(datumEntry => {
				if(this.isGolfSite){
					if(datumEntry.params && datumEntry.params.length > 0){
						const pumps = datumEntry.params.split(';', 50);
						pumps.forEach(datumPump => {
							const activePump = datumPump.split('|', 5);
							const foundPump = new DryRunPumpDatum(+activePump[0], activePump[1]);
							if( pump.id === foundPump.id){
								var datumFlow = [RbUtils.Conversion.convertDateToUTCMilliseconds(datumEntry.timeOffset),
									this.conversionService.getUserFlowRate(Number(activePump[2]))];
								pumpDatums.push(datumFlow);
								
							}
						});
					} else{
						var datumFlow = [RbUtils.Conversion.convertDateToUTCMilliseconds(datumEntry.timeOffset),
							0];
						pumpDatums.push(datumFlow);
					}
				}
			});
			this.expandedFlowPumpsDatum.push([pump.id, pump.name, pumpDatums]);
		});
	}

	private updateDatumDetails() {
		// Current Flow Data Info
		if (this.expandedFlowDatum && this.expandedFlowDatum.length > 0) {
			if (!this.getFlowDatum(this.currentFlowDataIndex)) { return; }

			const currentFlowDatum = this.expandedFlowDatum[this.currentFlowDataIndex];
			// Current Running Programs
			this.currentRunningPrograms = [];
			this.currentRunningProgramGroups = [];

			if (this.dryRunResult.runningPrograms) {
				for (const key of Object.keys(this.dryRunResult.runningPrograms)) {
					this.dryRunResult.runningPrograms[key].forEach((programDatum: DryRunProgramDatum) => {
						// Add any (unique) programs that are running for the given date/time.
						if (!this.isGolfSite) {
							if (programDatum.isRunningAtDateTime(currentFlowDatum.timeOffset)
								&& !this.currentRunningPrograms.find(p => p.programShortName === programDatum.programShortName)) {
								this.currentRunningPrograms.push(programDatum);
							}
						} else {
							if (programDatum.isRunningAtDateTime(currentFlowDatum.timeOffset)
								&& !this.currentRunningPrograms.find(p => p.programId === programDatum.programId)) {
								this.currentRunningPrograms.push(programDatum);

								if (!this.currentRunningProgramGroups.find(p => p.id === programDatum.parentId)) {
									this.currentRunningProgramGroups.push(new DryRunProgramGroupInfo(programDatum.parentId, programDatum.parentName));
								}
							}
						}
					});
				}
			}
		}
	}

	getXAxisLabel(utcTime: number) {
		const timestamp = new Date(utcTime);
		if (!(timestamp instanceof Date)) return '';

		const date = this.dateAndTimeService.dateToUserDateString(timestamp);
		const time = this.dateAndTimeService.dateToUserTimeString(timestamp);

		return `${date}<br />${time}`;
	}

	getXAxisToolTip(utcTime: number) {
		const timestamp = new Date(utcTime);
		if (!(timestamp instanceof Date)) return '';

		const date = this.dateAndTimeService.dateToUserDateString(timestamp);
		const time = this.dateAndTimeService.dateToUserTimeString(timestamp, true);

		return `${date}<br />${time}`;
	}

	formatToolTipData(y: number) {
		return RbUtils.Common.convertValueToPrecision(this.cultureSettingsManager.cultureSetting.decimalSeparator, y.toString(), 2);
	}

	private setupChart() {
		const self = this;
		self.setUpPlotLInes();
		this.chartOptions = {
			boost: {
				enabled: false
			},
			chart: {
				text: 'Combination chart',
				zoomType: 'x',
				// scrollablePlotArea: {
				// 	minWidth: 1000,
				// },
				marginRight: 15,
				plotBorderWidth: 1
			},
			credits: {
				enabled: false
			},
			exporting: {
				enabled: false
			},
			accessibility: {
				description: `${RbUtils.Translate.instant('STRINGS.DRY_RUN_RATE_OVER_TIME')}`
			},
			title: {
				text: ''
			},
			xAxis: {
				type: 'datetime',
				labels: {
					// rotation: -65,
					style: {
						fontSize: '9px',
						fontFamily: 'Verdana, sans-serif'
					},
					formatter: function() {
						return self.getXAxisLabel(this.value);
					}
				},
				crosshair: true,
				plotLines: self.autoContactLines
			},
			yAxis: {
				title: {
					text: `${RbUtils.Translate.instant('STRINGS.FLOW_RATE')} (${self.flowRateLabel})`
				},
				labels: {
					allowDecimals: true,
					formatter: function() {
						return this.value;
					}
				},
				min: 0,
				max: this.maxFlowRate,
				crosshair: {
					width: 1,
					color: 'gray',
					snap: true,
					zIndex: 5
				},
				plotLines: [this.maxFlow > 0 ? {
					color: '#FF0000',
					width: 2,
					dashStyle: 'dash',
					value: this.conversionService.getUserFlowRate(this.maxFlow)
				} : {}]
			},
			tooltip: {
				formatter: function() {
					return '<b>'
						+ `${RbUtils.Translate.instant('STRINGS.FLOW_RATE')}`
						+ ': ' + self.formatToolTipData(this.y) + '</b><br>' + self.getXAxisToolTip(this.x);
				}
			},
			legend: {
				// enabled: false,
				align: 'right',
				verticalAlign: 'top',
				y: -10,
				useHTML: true,
				labelFormatter: function() {
					return `<span class="rb-dry-run-chart-legend-text">${this.name}</span>`;
				  }
			},
			plotOptions: {
				area: {
					marker: {
						enabled: false,
						symbol: 'circle',
						radius: 4,
						// fillColor: self.CHART_MARKER_FILL_COLOR,				// Handled in select - since we auto select the points as we hover.
						states: {
							hover: {
								enabled: true,
								animation: { 'duration': 0 }
							},
							select: {
								radius: 6,
								fillColor: self.CHART_MARKER_FILL_COLOR,
								lineColor: self.CHART_MARKER_LINE_COLOR
							}
						}
					},
					fillOpacity: 1.0
				},
				series: {
					states: {
						hover: {
							enabled: false
						}
					},
					dataLabels: {
						enabled: false,
						format: '{y}'
					},
					allowPointSelect: false
				}
			},
			series: self.getSeriesData()
		};

		setTimeout(() => {
			this.showChart = true;
		});
	}

	private getSeriesData() {
		var returnSelection = false;
		var chartFill =  this.CHART_AREA_FILL_COLOR;
		var chartType = 'line';
		if(!this.showPumpsOnGraph) chartType = 'area';
		if(this.showPumpsOnGraph) returnSelection = true;
		const seriesData: any[] = [{
			name: `${RbUtils.Translate.instant('STRINGS.PROJECTED')}`,
			lineColor: this.CHART_AREA_FILL_COLOR,
			color: chartFill,
			data: this.flowDatumArray,
			turboThreshold: 0,
			type: chartType,
			events: {
				legendItemClick: function() { return returnSelection; }
			}
		}
		]; 


		if(this.showPumpsOnGraph){
			var counter = 0;
			const chartColors = ["#e6194B", "#000000", "#3cb44b", "#000075", "#ffe119", 
			"#911eb4", "#a9a9a9", "#f58231", "#469990", "#9A6324", "#808000", "#42d4f4",
			"#f032e6",];
			
			this.expandedFlowPumpsDatum.forEach(flowDatum => {
				if(counter === 13) counter = 0;
				seriesData.push({
					name: flowDatum[1],
					lineColor: chartColors[counter],
					color: chartColors[counter],
					data: flowDatum[2],
					turboThreshold: 0,
					type: 'line',
					events: {
						legendItemClick: function() { return true; }
					}
		
				});
				counter++;
			});
		}
		

		if (!this.isGolfSite) {
			seriesData.push({
				name: `${RbUtils.Translate.instant('STRINGS.RAIN_DELAY')}`,
				type: 'polygon',
				data: this.rainDelayData,
				color: {
					pattern: {
						path: {
							d: 'M 0 0 L 6 6 M 5 -1 L 7 1 M -1 5 L 1 7',
							strokeWidth: 3
						},
						height: 6,
						width: 6,
						r: 4,
						color: 'lightgrey'
					}
				}
			});

			// Add series data of programs that has zero flow rate
			seriesData.push({
				lineColor: this.CHART_AREA_FILL_COLOR,
				color:  this.CHART_AREA_FILL_COLOR, // fill"#ff2600"
				data: this.zeroFlowDatumArray,
				turboThreshold: 0,
				type: 'area',
				events: {
					legendItemClick: function() { return false; }
				},
				enableMouseTracking: false,
				showInLegend: false
			});

			// Series data to create legend for plot lines
			seriesData.push({
				name: `${RbUtils.Translate.instant('STRINGS.SYNC')}`,
				color: this.CHART_SYNC_COLOR,
				type: 'area'
			});
			seriesData.push({
				name: `${RbUtils.Translate.instant('STRINGS.REVERSE_SYNC')}`,
				color: this.CHART_REVERSE_SYNC_COLOR,
				type: 'area'
			});
			seriesData.push({
				name: `${RbUtils.Translate.instant('STRINGS.LOGS')}`,
				color: this.CHART_LOGS_COLOR,
				type: 'area'
			});
		}
		return seriesData;
	}

	private setUpPlotLInes() {
		this.autoContactLines = [];
		let date = new Date(this.dryRunResult.dryRunStart.getFullYear(), this.dryRunResult.dryRunStart.getMonth(), this.dryRunResult.dryRunStart.getDate());
		while (date.getTime() < this.dryRunResult.dryRunEnd.getTime()) {
			if (this.syncContacts && this.syncContacts.length > 0) {
				this.syncContacts.forEach(sync => {
					const item = {
						color: this.CHART_SYNC_COLOR,
						width: 3,
						dashStyle: 'ShortDash',
						value: this.getContactTime(date, sync.time),
						label: {
							text: `${RbUtils.Translate.instant('STRINGS.SYNC')}`,
							align: 'center',
							rotation: 0,
							y: -10,
							style: {
								display: 'none'
							}
						}
					};
					this.autoContactLines = [...this.autoContactLines, item];
				});
			}
			if (this.reverseSyncContacts && this.reverseSyncContacts.length > 0) {
				this.reverseSyncContacts.forEach(sync => {
					const item = {
						color: this.CHART_REVERSE_SYNC_COLOR,
						width: 3,
						dashStyle: 'ShortDash',
						value: this.getContactTime(date, sync.time),
						label: {
							text: `${RbUtils.Translate.instant('STRINGS.REVERSE_SYNC')}`,
							align: 'center',
							rotation: 0,
							y: -10,
							style: {
								display: 'none'
							}
						}
					};
					this.autoContactLines = [...this.autoContactLines, item];
				});
			}
			if (this.retrievalContacts && this.retrievalContacts.length > 0) {
				this.retrievalContacts.forEach(retrieval => {
					const item = {
						color: this.CHART_LOGS_COLOR,
						width: 3,
						dashStyle: 'ShortDash',
						value: this.getContactTime(date, retrieval.time),
						label: {
							text: `${RbUtils.Translate.instant('STRINGS.LOGS')}`,
							align: 'center',
							rotation: 0,
							y: 20,
							style: {
								display: 'none'
							}
						}
					};
					this.autoContactLines = [...this.autoContactLines, item];
				});
			}
			date = new Date(date.getTime() + 1000 * 60 * 60 * 24);
		}
	}

	private getContactTime(date: Date, time: Date) {
		const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
		return RbUtils.Conversion.convertDateToUTCMilliseconds(newDate);
	}
}

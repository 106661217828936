<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="programStep" class="program-step-mobile-cell">
	<div class="name ellipsis-text"><span class="ellipsis-text">{{programStep.stationName}}</span></div>
	<div class="sequence-number">
		<span>{{'STRINGS.NUMBER' | translate}}:</span>
		<span class="value">{{isGolfSite ? programStep.sequenceNumber : programStep.stationTerminal}}</span>
	</div>

	<div *ngIf="invalidStation" class="invalid-station rb-icon-font-warning"><i class="icon-warning"></i></div>

	<div class="status">
		<span class="label">{{'STRINGS.STATUS' | translate}}:</span>
		<div class="value" [ngClass]="{'station-status-running': isRunning}">
			<span *ngIf="programStep?.isActivelyCyclingOrSoaking && isCycling" class="station-status-cycling" [matTooltip]="'STRINGS.CYCLING' | translate">
				<i class="icon-water"></i>
			</span>
				<span *ngIf="programStep?.isActivelyCyclingOrSoaking && isSoaking" class="station-status-soaking" [matTooltip]="'STRINGS.SOAKING' | translate">
				<i class="icon-timer"></i>
			</span>
			{{ programStep.status }}
		</div>

	</div>

	<div class="base-run-time">
		<span class="label">{{'STRINGS.BASE_RUN_TIME' | translate}}:</span>
		<span class="value">{{programStep.baseRunTime}}</span>
	</div>

	<div class="adjust">
		<span class="label">{{'STRINGS.ADJUST' | translate}}:</span>
		<span class="value">{{programStep.adjustedRunTime}}</span>
	</div>

	<div class="course" *ngIf="isGolfSite">
		<span class="label">{{'STRINGS.COURSE'| translate}}:</span>
		<span class="value ellipsis-text">{{programStep.courseName}}</span>
	</div>

	<div class="landscape">
		<span class="label">{{(isGolfSite ? 'STRINGS.HOLE' : 'STRINGS.LANDSCAPE') | translate}}:</span>
		<span class="value ellipsis-text">{{programStep.landscapeTypeGridColumn}}</span>
	</div>

	<div class="sprinkler">
		<span class="label">{{(isGolfSite ? 'STRINGS.AREA' : 'STRINGS.SPRINKLER') | translate}}:</span>
		<span class="value ellipsis-text">{{programStep.sprinklerTypeGridColumn}}</span>
	</div>

	<div class="sub-area" *ngIf="isGolfSite">
		<span class="label">{{'STRINGS.SUB_AREA'| translate}}:</span>
		<span class="value ellipsis-text">{{programStep.subAreaName}}</span>
	</div>
</div>

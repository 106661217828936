import { StationListItem } from './station-list-item.model';

export class StationsListChange {

	constructor(controllerId: number, stations: StationListItem[], isStatusUpdateOnly = false, updatedStationId: number = null) {
		this.controllerId = controllerId;
		this.stations = stations;
		this.isStatusUpdateOnly = isStatusUpdateOnly;
		this.updatedStationId = updatedStationId;
	}

	controllerId: number;
	stations: StationListItem[];
	isStatusUpdateOnly = false;
	updatedStationId: number;
}

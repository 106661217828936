<div #stationContainer class='cv-station-container rb-input-shield-host' [ngClass]="{'is-narrow-col' : isNarrowColumn}">
	<div *ngIf="isStationRunnable && diagnosticsResult?.cdType !== DiagnosticsDataType.ICVoltage" class="icm-status-cell">
		<span *ngIf="diagnosticsResult?.result === DiagnosticsFeedbackResult.OK" class="rb-status-icon icon-confirmation"></span>
		<span *ngIf="diagnosticsResult?.result === DiagnosticsFeedbackResult.Error" class="rb-status-icon icon-warning"></span>
		<span *ngIf="diagnosticsResult?.result === DiagnosticsFeedbackResult.NO_FB" class="rb-status-icon icon-help"></span>
	</div>

	<div *ngIf="isStationRunnable && diagnosticsResult?.cdType === DiagnosticsDataType.ICVoltage" class="icm-status-cell icm-voltage-status-cell">
		<span *ngIf="diagnosticsResult?.result === DiagnosticsFeedbackResult.OK" class="rb-status-icon icon-confirmation"></span>
		<span *ngIf="diagnosticsResult?.result === DiagnosticsFeedbackResult.Error" class="rb-status-icon icon-warning"></span>
		<span *ngIf="diagnosticsResult?.result === DiagnosticsFeedbackResult.NO_FB" class="rb-status-icon icon-help"></span>

		<span class="icm-status-voltage-value">{{diagnosticsResult?.voltage}}</span>
	</div>

	<div *ngIf="!isStationRunnable" class="icm-status-cell">
		<div class="is-not-runnable" [innerHTML]="nonRunnableInnerHtml"></div>
	</div>

	<div *ngIf="isStationRunnable; then activeTypeAndRunningShield else otherShield"></div>
</div>

<ng-template #activeTypeAndRunningShield>
	<div class="rb-input-shield--w-pointer"
		 [ngClass]="{'is-selected': isSelected}"
		 [matTooltip]="tooltip"
		 matTooltipPosition="above"
		 matTooltipClass="icm-status-tooltip"
		 (click)='onToggleSelection()'
	></div>
</ng-template>

<ng-template #otherShield>
	<div class="rb-input-shield" [matTooltip]="tooltip" matTooltipPosition="above"></div>
</ng-template>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { BreadcrumbRoute } from '../breadcrumb/breadcrumb-route.model';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { INavIcon } from '../../../common/constants/rb-ui.constants';
import { MatMenuPanel } from '@angular/material/menu';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { RouteInfo } from '../../../sections/_common/models/route-info.model';
import { Site } from '../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'rb-sub-navbar',
	templateUrl: './sub-navbar.component.html',
	styleUrls: ['./sub-navbar.component.scss']
})
export class SubNavbarComponent implements OnInit, OnDestroy {
	@Input() breadcrumbRoute: BreadcrumbRoute;
	@Input() header = '';
	@Input() includeSubNavHeader = true;
	@Input() includeBreadCrumb = false;
	@Input() isSecondarySubNav = false;		// When used inside of a parent component that has a subNav.
	@Input() isPreferences = false;
	@Input() overflowMenu: MatMenuPanel;
	@Input() routesList: RouteInfo[] = [];
	@Input() showFilterButton = false;
	@Input() showOverflowButton = false;
	@Input() showSiteSelector = false;
	@Input() showControllerConnectionStatus = false;
	@Input() controllerId: number;
	@Input() titleTooltip: string;

	isAdmin = false;
	isGolfSite = false;
	isOwner = false;
	navIcon: INavIcon;
	selectedSites: Site[];
	showSubNavbar = true;
	sites: Site[];
	isMobile = false;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private deviceManager: DeviceManagerService,
				private siteManager: SiteManagerService,
				private translate: TranslateService
	) { }

	ngOnInit() {
		this.isOwner = this.authManager.isOwner;
		this.isAdmin = RbUtils.Common.isAdmin(this.authManager.getUserProfile().groupLevel);
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		this.isMobile = this.deviceManager.isMobile;

		// Monitor app resize
		this.deviceManager.isMobileChange.pipe(untilDestroyed(this)).subscribe((isMobile: boolean) => this.isMobile = isMobile);
		this.siteManager.selectedSitesChange.pipe(untilDestroyed(this)).subscribe(() => this.getSelectedSites());
		this.siteManager.sitesUpdate.pipe(untilDestroyed(this)).subscribe(() => this.getSites(true));
		this.siteManager.siteDeleted.pipe(untilDestroyed(this)).subscribe(() => this.getSites(true));

		this.getSites();
		this.getSelectedSites();
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onSiteSelectionComplete(selectedSiteIds: number[]) {
		this.siteManager.selectedSiteIds = selectedSiteIds;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	getNavName(key: string, ): any{
		this.navIcon = RbConstants.Ui.NAVIGATION_ICONS.find(x => x.key === key);
		return this.translate.instant(key);
	}

	private getSites(bypassCache: boolean = false) {
		this.siteManager.getSites(bypassCache)
			.pipe(take(1))
			.subscribe((sites: Site[]) => this.sites = sites);
	}

	private getSelectedSites() {
		this.siteManager.getSelectedSites()
			.pipe(take(1))
			.subscribe((sites: Site[]) => this.selectedSites = sites);
	}
}

/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Months.months
 * 		 See: _rb.utils.ts
 */

import { RbConstants } from '../constants/_rb.constants';
import { RbUtils } from './_rb.utils';

export namespace XXUseRbUtilsNamespace {

	export abstract class Months {

		static get months(): any[] {
			const monthEntity = [
				{
					id: 1,
					value: RbConstants.Form.MONTH_NUMBER.jan,
					label: 'MONTHS.JANUARY',
					abbreviationLabel: 'MONTHS.JANUARY_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.JANUARY_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.jan
				},
				{
					id: 2,
					value: RbConstants.Form.MONTH_NUMBER.feb,
					label: 'MONTHS.FEBRUARY',
					abbreviationLabel: 'MONTHS.FEBRUARY_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.FEBRUARY_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.feb
				},
				{
					id: 3,
					value: RbConstants.Form.MONTH_NUMBER.mar,
					label: 'MONTHS.MARCH',
					abbreviationLabel: 'MONTHS.MARCH_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.MARCH_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.mar
				},
				{
					id: 4,
					value: RbConstants.Form.MONTH_NUMBER.apr,
					label: 'MONTHS.APRIL',
					abbreviationLabel: 'MONTHS.APRIL_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.APRIL_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.apr
				},
				{
					id: 5,
					value: RbConstants.Form.MONTH_NUMBER.may,
					label: 'MONTHS.MAY',
					abbreviationLabel: 'MONTHS.MAY_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.MAY_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.may
				},
				{
					id: 6,
					value: RbConstants.Form.MONTH_NUMBER.jun,
					label: 'MONTHS.JUNE',
					abbreviationLabel: 'MONTHS.JUNE_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.JUNE_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.jun
				},
				{
					id: 7,
					value: RbConstants.Form.MONTH_NUMBER.jul,
					label: 'MONTHS.JULY',
					abbreviationLabel: 'MONTHS.JULY_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.JULY_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.jul
				},
				{
					id: 8,
					value: RbConstants.Form.MONTH_NUMBER.aug,
					label: 'MONTHS.AUGUST',
					abbreviationLabel: 'MONTHS.AUGUST_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.AUGUST_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.aug
				},
				{
					id: 9,
					value: RbConstants.Form.MONTH_NUMBER.sep,
					label: 'MONTHS.SEPTEMBER',
					abbreviationLabel: 'MONTHS.SEPTEMBER_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.SEPTEMBER_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.sep
				},
				{
					id: 10,
					value: RbConstants.Form.MONTH_NUMBER.oct,
					label: 'MONTHS.OCTOBER',
					abbreviationLabel: 'MONTHS.OCTOBER_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.OCTOBER_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.oct
				},
				{
					id: 11,
					value: RbConstants.Form.MONTH_NUMBER.nov,
					label: 'MONTHS.NOVEMBER',
					abbreviationLabel: 'MONTHS.NOVEMBER_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.NOVEMBER_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.nov
				},
				{
					id: 12,
					value: RbConstants.Form.MONTH_NUMBER.dec,
					label: 'MONTHS.DECEMBER',
					abbreviationLabel: 'MONTHS.DECEMBER_ABBREVIATION',
					abbreviationUppercaseLabel: 'MONTHS.DECEMBER_ABBREVIATION_UPPERCASE',
					formControlLabel: RbConstants.Form.MONTH_FORM_CONTROL_LABEL.dec
				},
			];
			return monthEntity;
		}

		/**
		 * Return localized name for month indicated by parameter. NOTE: MONTH NUMBERS MUST BE 1-12, NOT Date.getMonth()
		 * WHICH RETURNS 0-11!
		 * @param month - Month number for which to return localized name (1-12).
		 */
		static monthString(month: number): string {
			return RbUtils.Translate.instant(Months.months.find(m => m.value === month).label);
		}

		/**
		 * Return localized abbreviation for month indicated by parameter. NOTE: MONTH NUMBERS MUST BE 1-12, NOT
		 * Date.getMonth() WHICH RETURNS 0-11!
		 * @param month - Month number for which to return localized abbreviation (1-12).
		 */
		static monthAbbrevString(month: number): string {
			return RbUtils.Translate.instant(Months.months.find(m => m.value === month).abbreviationLabel);
		}

		static monthAbbrevUppercaseString(month: number): string {
			return RbUtils.Translate.instant(Months.months.find(m => m.value === month).abbreviationUppercaseLabel);
		}
	}
}

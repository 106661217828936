import { RbEnums } from '../../common/enumerations/_rb.enums';

export class StationValveType {

	companyId: number;
	customSwitchCode: string | number;
	id: number;
	isSwitchCodeCustom = true;
	satelliteId: number;
	solenoidType: RbEnums.Common.SolenoidType;
	solenoidsQuantity: number;
	switchCode: string | number;
	valveType: number;
	valveTypeDescription: string;
	// Saves string to display the selectable item in the dropdown, NOT set by the API.
	selectItemDisplayString: string;

	// Used to display from V1 to V20
	get valveNumber(): string {
		return `V${this.valveType + 1}`;
	}

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

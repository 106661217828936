<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="rb-mobile-sidebar-header">

	<header class="rb-dialog--header rb-sidebar-header">

		<div class="rb-sidebar-header-contents">
			<div class="title-and-breadcrumb-container">
				<rb-breadcrumb
					class="rb-breadcrumb"
					[header]="title"
					[returnRoute]="breadcrumbRoute"
					[suppressAutoBackNavigation]="suppressAutoBackNavigation"
					[hideBackArrow]="hideBackArrow"
					[isMobile]="true"
					[isGolf]="isGolfSite"
					(backClick)="onBackClick()">
				</rb-breadcrumb>
			</div>

			<div class="rb-sidebar-header-buttons">
				<button *ngIf="includeSettings" mat-icon-button class="white" (click)="onSettingsClick()">
					<mat-icon class="mat-icon-color">settings</mat-icon>
				</button>

				<button *ngIf="includePdf" mat-icon-button class="white" (click)="onPdfClick()">
					<mat-icon class="mat-icon-color">picture_as_pdf</mat-icon>
				</button>

				<div *ngIf="includeCsv" class="white csv" (click)="onCsvClick()">{{'STRINGS.CSV' | translate}}</div>
				
				<button *ngIf="includeShare" mat-icon-button class="white" (click)="onShareClick()">
					<mat-icon class="mat-icon-color">share</mat-icon>
				</button>

				<button *ngIf="showOverflowButton && overflowMenu" mat-icon-button class="white" [matMenuTriggerFor]="overflowMenu">
					<mat-icon class="mat-icon-color">more_vert</mat-icon>
				</button>
			</div>
		</div>

	</header>

	<!-- Placeholder for MatMenu (overflow menu)-->
	<ng-content></ng-content>

</div>



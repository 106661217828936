import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'rb-hyperlink-cell-renderer',
	templateUrl: './hyperlink-cell-renderer.component.html',
	styleUrls: ['./hyperlink-cell-renderer.component.scss']
})
export class HyperlinkCellRendererComponent implements ICellRendererAngularComp {

	linkText = '';
	routerLink: string[];
	queryParams: {} = {};
	cellData: any;
	onLinkClick: (data: any, event: any) => {};
	isPlainText = false;

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.setCellData(params);
	}

	refresh(params: any): boolean {
		this.setCellData(params);
		return true;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setCellData(params: any) {
		this.cellData = params.data;
		this.linkText = params.value;

		if (!this.linkText || this.linkText === '' || this.linkText === '-' ) {
			this.isPlainText = true;
		}
		// RB-14184: Sometimes we don't want to show a clickeable link for some elements, we allow to define a logic for those cases.
		if (params.disallowClickingLink != null && params.disallowClickingLink(this.cellData) === true) {
			this.isPlainText = true;
		}

		this.onLinkClick = params.linkClick;

		// If an onLincClick callback has been defined, we're done.
		if (this.onLinkClick) return;

		this.routerLink = params.routerLink;

		// StaticQueryParams allows for passing in fully resolved query parameters at compile time.
		// These will take the form { <propertyName>: string, <value>: any }
		if (params.staticQueryParams) {
			this.queryParams = params.staticQueryParams;
		}

		// DynamicQueryParams retrieve the data for the current cell from the underlying params.data value.
		// The dynamicQueryParams can either be an array of strings representing the data-property/param name, or
		// it can be an array of objects taking the form { propertyName: string, paramName: string }. Use the latter
		// form when the property on the data object differs from the query parameter name.
		if (params.dynamicQueryParams) {
			params.dynamicQueryParams.forEach((p) => {
				// NOTE: If the underlying data object property name is the same as the router parameter name, we simply
				// 		 need to pass in the parameter/property name as a single string. If they are different, then
				// 		 we need to pass in an object which specifically identifies the property name and the parameter name.
				if (!(p instanceof Object)) {
					p = {
						propertyName: p,
						paramName: p
					};
				}
				this.queryParams[p.paramName] = params.data[p.propertyName];
			});
		}
	}

}

/* eslint-disable @typescript-eslint/member-ordering, @angular-eslint/directive-class-suffix */
import { BehaviorSubject, debounceTime, firstValueFrom, Subscription  } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { ImportedLayersEditorData } from '../../../../../common/models/imported-layers-editor-data.model';
import { KMZGroup } from '../../../../../api/leaflet/models/kmz-group.model';
import { KMZItem } from '../../../../../api/leaflet/models/kmz-Item.model';
import { LayerImportDialogComponent } from '../../../layer-import-dialog/layer-import-dialog.component';
import { MapInfoLeaflet } from '../../../../../common/models/map-info-leaflet.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MessageBoxInfo } from '../../../global-message-box/message-box-info.model';
import { MessageBoxService } from '../../../../../common/services/message-box.service';
import { RasterItem } from '../../../../../api/leaflet/models/raster-file.model';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';

import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;

@Component({
	selector: 'rb-imported-layers',
	templateUrl: './imported-layers.component.html',
	styleUrls: ['./imported-layers.component.scss'],
})
export class ImportedLayersComponent implements OnInit, OnDestroy {

	private dialogRef: MatDialogRef<LayerImportDialogComponent>;
	@ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
	

	@Input() busy;
  @Input() mapInfo: MapInfoLeaflet;
  @Input() isWidget: boolean = false;

	public isMobile = false;

	isSearching = false;
	searchFilter = "";

	showingRasterLayers = false;
	showingVectorLayers = false;
	disableForwardMovement = false;
	disableBackwardMovement = false;
	layersWithErrors = 0;
	loadingKMZLayers = false;

	//#region Private members

	private _allowToSave = false;
	private _saving = false;
	private _selectedLayer: KMZItem | RasterItem;
	private selectedLayerArrayIndex: number;
	private _selectedLayerType: 'kmzitem' | 'rasteritem';
	// private minScale = 4;
	private subscriptions = new Subscription();
	private _filterString = '';
	filterTextSubject = new BehaviorSubject('');
	private _filteringErrors = false;
	filteredVectorLayerItems = [];
	filteredRasterLayerItems = [];
	innerLoader = false
	isTouchDevice = false;
	//#endregion

	//#region Getters

	// Item lists

	get rasterItems() {
		return this.mapInfo.rasterItems;
	}

	get kmzItems() {
		return this.mapInfo.kmzItems;
	}

	// General

	get allowToSave() {
		return this._allowToSave;
	}

	get canEdit() {
		return true;
	}

	get filtering() {
		return (this.filterString.length > 0) || this.filteringErrors;
	}

	get filteringErrors() {
		return this._filteringErrors;
	}

	get filterString() {
		return this._filterString;
	}

	get saving() {
		return this._saving;
	}

	get selectedLayer() {
		return this._selectedLayer;
	}

	get selectedLayerType() {
		return this._selectedLayerType;
	}

	//#endregion

	get kmzItemsChanged() {
		return this.mapInfo.vectorItemsChanged;
	}

	get rasterItemsChanged() {
		return this.mapInfo.rasterItemsChanged;
	}

	constructor(
		private deviceManager: DeviceManagerService,
		private dialog: MatDialog,
		private messageBoxService: MessageBoxService
	) { }

	ngOnInit(): void {

		this.isMobile = this.deviceManager.isMobile;

		this.subscriptions.add(
			this.kmzItemsChanged.subscribe(() => {
				this.filterLayers();
				this.calcLayersWithErrors();
			})
		);

		this.subscriptions.add(
			this.rasterItemsChanged.subscribe(() => {
				this.filterLayers();
				this.calcLayersWithErrors();
			})
		);

		this.subscriptions.add(
			this.filterTextSubject
				.pipe(debounceTime(100))
				.subscribe(filterText => this.filterTextChanged(filterText)));

		this.subscriptions.add(
			this.mapInfo.loadingVectorLayers
				.subscribe(loading => this.loadingKMZLayers = loading));

		// this.filteredVectorLayerItems = this.kmzItems;
		// this.filteredRasterLayerItems = this.rasterItems;

		this.showingRasterLayers = this.mapInfo.layerVisibility.showingRasters;
		this.showingVectorLayers = this.mapInfo.layerVisibility.showingVectors;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	toggleSearch() {
		this.searchFilter = "";
		this.isSearching = !this.isSearching
		if (this.isSearching) {
			setTimeout(() => {
				this.searchInput.nativeElement.focus();
			}, 0);
		}
	}


	prevent(event) {
		// event.preventDefault();
		event.stopPropagation();
	}

	/* LIST FUNCTIONS */

	toggleRasterLayersVisibility(event) {
		// event.stopPropagation();
		this.showingRasterLayers = !this.showingRasterLayers;
		this.mapInfo.layerVisibility.showingRasters = this.showingRasterLayers;
		this.mapInfo.prefs.save();
		this.mapInfo.setRasterLayersVisibility(this.showingRasterLayers);
	}

	toggleVectorLayersVisibility(event) {
		// event.stopPropagation();
		this.showingVectorLayers = !this.showingVectorLayers;
		this.mapInfo.layerVisibility.showingVectors = this.showingVectorLayers;
		this.mapInfo.prefs.save();
		this.mapInfo.setVectorLayersVisibility(this.showingVectorLayers);
	}

	toggleErrorFilter() {
		this._filteringErrors = !this._filteringErrors;
		this.filterLayers();
	}

	filterTextChanged(text: string) {
		this._filterString = text;
		this.filterLayers();
	}

	/**
	 * Adds or removes a layer to/from the map
	 *
	 * @param layer The layer to be added or removed from the map
	 * @param event The event of the toggle
	 */
	async visibilityChanged(
		layer: KMZItem | KMZGroup | RasterItem,
		event: MatSlideToggleChange
	) {
		this._selectedLayer = undefined;
		if (event.checked) {
			layer.visible = true;
			if (this.mapInfo.canShowCustomLayers) {
				if (layer instanceof KMZItem) {
					await this.mapInfo.mapService.loadItemGeoJSON(layer);
					this.mapInfo.mapService.updateVectorsZIndex(this.mapInfo, false);
				} else if (layer instanceof RasterItem) {
					layer.imageLayer = await this.mapInfo.mapService.createDistortableImage(layer);
					this.mapInfo.addLayerToMap(layer.imageLayer, null, true);
					this.mapInfo.mapService.updateRastersZIndex(this.mapInfo, false);
				}
			}
		} else {
			layer.visible = false;
			if (layer instanceof KMZItem) {
				this.mapInfo.removeLayerFromMap(layer.layer);
			} else if (layer instanceof RasterItem) {
				if (layer.imageLayer) {
					this.mapInfo.removeLayerFromMap(layer.imageLayer);
				}
				layer.imageLayer = undefined;
			}
		}

		this.mapInfo.setCustomLayerVisibility(layer, event.checked);
		layer.visible = event.checked;
	}

	/**
	 * Selects a layer object to be edited
	 *
	 * @param selection Layer object to be edited
	 */
	async selectLayer(selection: KMZItem | RasterItem) {

		this._selectedLayer = undefined;

		if (selection instanceof KMZItem) {
			this._selectedLayerType = 'kmzitem';
			if (!selection.properties) {
				try {
					selection = await firstValueFrom(this.mapInfo.mapService.getKmzItem(selection.id));
				} catch (error) {
					throw error;
				}
			}
		} else if (selection instanceof RasterItem) {
			this._selectedLayerType = 'rasteritem';
		}

		this._selectedLayer = selection;

		this.calcSelectedLayerArrayIndex(selection instanceof KMZItem);
		// this.calcSaveability();
	}

	//#region Reordering functions

	dropLayerItem(event: CdkDragDrop<KMZItem | RasterItem>) {
		const isKMZItem = event.container.data[event.previousIndex] instanceof KMZItem;
		const layerItems: any[] = isKMZItem ? this.kmzItems : this.rasterItems;

		moveItemInArray(
			layerItems,
			event.previousIndex,
			event.currentIndex
		);

		this.calcSelectedLayerArrayIndex(isKMZItem);
		this.updateZIndex(isKMZItem);
	}

	/**
	 * Move selected layer to the bottom of the list
	 */
	moveToTop(layer: KMZItem | RasterItem, index: number) {
		if (index === 0) {
			return;
		}

		const isKMZItem = layer instanceof KMZItem;
		const layerItems: any[] = isKMZItem ? this.kmzItems : this.rasterItems;

		moveItemInArray(layerItems, index, 0);

		this.calcSelectedLayerArrayIndex(isKMZItem);
		this.updateZIndex(isKMZItem);
	}

	/**
	 * Move selected layer to te top of the list
	 */
	moveToBottom(layer: KMZItem | RasterItem, index: number) {
		const isKMZItem = layer instanceof KMZItem;
		const layerItems: any[] = isKMZItem ? this.kmzItems : this.rasterItems;

		if (index === layerItems.length - 1) {
			return;
		}

		moveItemInArray(layerItems, index, layerItems.length - 1);

		this.calcSelectedLayerArrayIndex(isKMZItem);
		this.updateZIndex(isKMZItem);
	}

	/**
	 * Move selected layer one step backward on the list
	 */
	moveOneUp(layer: KMZItem | RasterItem, index: number) {
		if (index === 0) {
			return;
		}

		const isKMZItem = layer instanceof KMZItem;
		const layerItems: any[] = isKMZItem ? this.kmzItems : this.rasterItems;

		moveItemInArray(
			layerItems,
			index,
			index - 1
		);

		this.calcSelectedLayerArrayIndex(isKMZItem);
		this.updateZIndex(isKMZItem);
	}

	/**
	 * Move selected layer one step forward on the list
	 */
	moveOneDown(layer: KMZItem | RasterItem, index: number) {
		const isKMZItem = layer instanceof KMZItem;
		const layerItems: any[] = isKMZItem ? this.kmzItems : this.rasterItems;

		if (this.selectedLayerArrayIndex === (isKMZItem ? this.kmzItems.length - 1 : this.rasterItems.length - 1)) {
			return;
		}

		moveItemInArray(
			layerItems,
			index,
			index + 1
		);

		this.calcSelectedLayerArrayIndex(isKMZItem);
		this.updateZIndex(isKMZItem);
	}

	//#endregion

	//#region FORM FUNCTIONS

	editLayer(layer: KMZItem | RasterItem) {
		if (layer instanceof KMZItem) {
			this.openLayerImportTool(null, layer)
		} else {
			layer.editing = true;
			this.mapInfo.editDistortableImage(layer);
		}
	}

	removeLayer(layer: KMZItem | RasterItem) {
		const areYouSureMsg = RbUtils.Translate.instant('STRINGS.ARE_YOU_SURE_DELETE_LAYER_MESSAGE');
		const areYouSureTitle = RbUtils.Translate.instant('STRINGS.ARE_YOU_SURE_DELETE_LAYER_TITLE');
		this.messageBoxService.showMessageBox(
			new MessageBoxInfo(areYouSureMsg, MessageBoxIcon.Warning, areYouSureTitle,
				() => { this.confirmLayerDelete(layer); },
				null,
				RbEnums.Common.MessageBoxButtons.YesNo));
	}

	findOnMap(layer) {
		if (layer instanceof KMZItem) {
			this.mapInfo.map.fitBounds(layer.layer.getBounds());
		}
	}

	async confirmLayerDelete(layer: any) {
		this._saving = true;
		this.innerLoader = true;
		console.log(layer)
		try {
			if (layer instanceof KMZItem) {
				await this.mapInfo.deleteKmzItem(layer).toPromise();
				this.mapInfo.removeLayerFromMap(layer.layer);
				this.mapInfo.mapService.getKmzItemList(this.mapInfo.siteId, true).subscribe(async (items) => {
					await this.mapInfo.getKMZItemsHandler(items);
				}, error => { throw error; });
				this._saving = false;
			} else {
				this.mapInfo.deleteRasterItem(layer).subscribe(async () => {
					this.mapInfo.busy.next(true);
					this.mapInfo.removeLayerFromMap(layer.imageLayer);
					this.mapInfo.mapService.getRasterItems(this.mapInfo.siteId, true).subscribe(async rasterItems => {
						await this.mapInfo.getRasterItemsHandler(rasterItems);
						this._saving = false;
						this.mapInfo.busy.next(false);
					}, error => { throw error; });
				}, (error) => {
					console.error(error);
					this.mapInfo.busy.next(false);
					this._saving = false;
				});
			}
			this._selectedLayer = undefined;
		} catch (error) {
			console.error(error);
			this.mapInfo.busy.next(false);
			this._saving = false;
		}
		this.innerLoader = false;
	}

	//#endregion


	/**
	 * Filters the custom layers list based on search text and error setting
	 *
	 * Also de-selects a layer if one is selected
	 */
	private filterLayers() {
		this.filteredVectorLayerItems = this.kmzItems.filter((item: any) =>
			this.filtering ? (this.filterString ? (item.sourceName + item.fileName).toLowerCase()
				.includes(this.filterString.toLowerCase()) : true) &&
				(this.filteringErrors ? item.error : true) : true
		);

		this.filteredRasterLayerItems = this.rasterItems.filter((item: any) =>
			this.filtering ? (this.filterString ? (item.sourceName + item.fileName).toLowerCase()
				.includes(this.filterString.toLowerCase()) : true) &&
				(this.filteringErrors ? item.error : true) : true
		);

		if (!this.filteredVectorLayerItems.includes(this.selectedLayer) &&
			!this.filteredRasterLayerItems.includes(this.selectedLayer)) {
			this._selectedLayer = undefined;
		}

		this.calcOrderButtonsState();
	}

	private calcOrderButtonsState() {
		const layerItems = this._selectedLayerType === 'kmzitem' ? this.kmzItems : this.rasterItems;

		this.disableBackwardMovement = !this.selectedLayer || this.selectedLayerArrayIndex === layerItems.length - 1 || this.filtering;
		this.disableForwardMovement = !this.selectedLayer || this.selectedLayerArrayIndex === 0 || this.filtering;
	}

	private calcLayersWithErrors() {
		this.layersWithErrors = this.rasterItems.reduce((prev, current) => prev += current.error ? 1 : 0, 0);
		this.layersWithErrors += this.kmzItems.reduce((prev, current) => prev += current.error ? 1 : 0, 0);
	}

	private calcSelectedLayerArrayIndex(isKMZItem: boolean) {
		if (isKMZItem) {
			this.selectedLayerArrayIndex = this.kmzItems.findIndex(
				(cl) => cl === this.selectedLayer
			);
		} else {
			this.selectedLayerArrayIndex = this.rasterItems.findIndex(
				(cl) => cl === this.selectedLayer
			);
		}
		this.calcOrderButtonsState();
	}

	private updateZIndex(isKMZItem: boolean) {
		if (isKMZItem) {
			this.mapInfo.mapService.updateVectorsZIndex(this.mapInfo, true);
			this.filteredVectorLayerItems = this.kmzItems;
		} else {
			this.mapInfo.mapService.updateRastersZIndex(this.mapInfo, true);
			this.filteredRasterLayerItems = this.rasterItems;
		}
	}

	onQuickColorChange(newColor, layer) {
		this.mapInfo.updateKmzItem(layer).subscribe({
			next: () => {
				layer.properties.color = newColor;
				layer.layer.setStyle({ color: newColor });
			},
			error: () => this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED')				
		})
	}

	/**
	 * Open Layer Import Tool dialog with info taken from the current mapInfo
	 */
	openLayerImportTool(event, layer?: KMZItem, compact?) {

		if (!this.dialogRef && !this.isWidget) {
			this.dialogRef = this.dialog.open<LayerImportDialogComponent, ImportedLayersEditorData>(
				LayerImportDialogComponent,
				{
					closeOnNavigation: true,
					data: {
						layer,
						compact,
						mapInfo: this.mapInfo,
						canEdit: this.canEdit
					},
					hasBackdrop: compact ? false : true,
					maxWidth: layer ? 'calc(100vw - 2rem)' : '1400px',
        	width: layer ? (compact ? '264px': '840px') : 'calc(100vw - 2rem)',
					panelClass: 'layer-import-tool-modal',
					position: (layer && event !== null) ?
						{ left: event.clientX + 'px', top: event.clientY + 'px'} : null 
				}
			);
			this.dialogRef
				.afterClosed()
				.subscribe(async (changes) => {
					if (changes) {
						try {
							const rasterItems = await firstValueFrom(this.mapInfo.mapService.getRasterItems(this.mapInfo.siteId));
							if (rasterItems) await this.mapInfo.getRasterItemsHandler(rasterItems);
							const vectorItems = await firstValueFrom(this.mapInfo.mapService.getKmzItemList(this.mapInfo.siteId));
							if (vectorItems) await this.mapInfo.getKMZItemsHandler(vectorItems);
						} catch (error) {
							throw error;
						}
					}
					this.dialogRef = undefined;			
				});
		}
	}

}

<div [ngClass]="{'icon-button-container pv0 ph1': label, 'hide-border': hideBorder}">
	<button
		[ngStyle]="{'color': !disabled ? color : null }"
		mat-icon-button
		type="button"
		(click)="onButtonClicked()"
		[disabled]="disabled || isAnimating"
	>
		<div class="icon-container flex flex-column items-center pa0" [ngClass]="iconContainerClass">
			<mat-icon
				*ngIf="customIconNumber === Constants.CUSTOM_ICON_NUMBER.standard || customIconNumber === Constants.CUSTOM_ICON_NUMBER.mdi_icons || customIconNumber === Constants.CUSTOM_ICON_NUMBER.rb_icons"
				[attr.aria-label]="label"
				[ngClass]="animationClass"
				class="player-menu__mat-button--icon"
				[matBadge]="badgeCount && badgeCount > 0 ? badgeCount <= 99 ? badgeCount.toString() : '99+' : null"
			>
				<span *ngIf="customIconNumber !== Constants.CUSTOM_ICON_NUMBER.mdi_icons && customIconNumber !== Constants.CUSTOM_ICON_NUMBER.rb_icons">{{icon}}</span>
				<div *ngIf="customIconNumber === Constants.CUSTOM_ICON_NUMBER.mdi_icons" class="mdi-icon"><i class="{{icon}}"></i></div>
				<div *ngIf="customIconNumber === Constants.CUSTOM_ICON_NUMBER.rb_icons" class="rb-icon" [ngClass]="icon"></div>
			</mat-icon>
			<i *ngIf="customIconNumber === Constants.CUSTOM_ICON_NUMBER.resume" class="player-menu__mat-button--icon icon-resume"></i>
			<i *ngIf="customIconNumber === Constants.CUSTOM_ICON_NUMBER.simulate" class="player-menu__mat-button--icon icon-calculate"></i>
			<i *ngIf="customIconNumber === Constants.CUSTOM_ICON_NUMBER.pending_actions" class="player-menu__mat-button--icon-pending-actions icon-pending_actions"></i>
			<i *ngIf="customIconNumber === Constants.CUSTOM_ICON_NUMBER.manual_sensor_read" class="player-menu__mat-button--icon icon-manual-sensor-read"></i>
			<span *ngIf="!hideLabel && label" class="player-menu__mat-button--icon-button-text">{{label}}</span>
		</div>
	</button>
</div>


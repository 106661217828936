<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="controller-mobile-cell flex flex-column">
	<div class="flex justify-between">
		<div class="ellipsis-text b w-60-m"> {{controller?.name}} </div>
		<div>
			<div *ngIf="!isGolfSite" class="b tc w-40-m mobile-sync-icon-container mr-1" [innerHTML]="getControllerStatusCellRenderer()"></div>
			<div *ngIf="isGolfSite" class="b tc w-40-m mobile-sync-icon-container mr-1"> {{ getInterfaceStatusCellRenderer() }} </div>
		</div>
	</div>
	<div class="flex justify-between">
		<div class="ellipsis-text f7-m w-50-m">{{controller?.siteName}} </div>
	</div>
</div>

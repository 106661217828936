import { CCWeatherIntervalData } from './ccweather-interval-data.model';

export class CCWeatherData {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
			if (json.intervals) {
				this.intervals = [];
				json.intervals.forEach(interval => this.intervals.push(new CCWeatherIntervalData(interval)));
			}
		}
	}

	startTime: string = null;
	endTime: string = null;
	timestep: string = null;
	intervals: CCWeatherIntervalData[] = [new CCWeatherIntervalData()];
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="showSubNavbar" class="top-nav--secondary mediumGreyColor"
	[ngClass]="{'top-nav-secondary-mobile': isMobile }">
	<div class="sub-nav-content" [ngClass]="{'secondary-sub-nav': isSecondarySubNav}">

		<div *ngIf="includeSubNavHeader && !(isSecondarySubNav && isMobile )" class="title-and-breadcrumb-container">
			<div *ngIf="!isSecondarySubNav">
				<div *ngIf="!includeBreadCrumb" class="sub-nav-title-container">
					<h1 class="header1 page-header-title" data-qaid="page-header-title">{{ header }}</h1>
				</div>
				<rb-breadcrumb *ngIf="includeBreadCrumb"
					[header]="header"
					[returnRoute]="breadcrumbRoute"
					[showControllerConnectionStatus]="showControllerConnectionStatus"
					[controllerId]="controllerId"
					[titleTooltip]="titleTooltip"
					[isMobile]="isMobile"
					[isGolf]="isGolfSite"
					class="rb-breadcrumb">
				</rb-breadcrumb>
			</div>

			<div *ngIf="showFilterButton || showOverflowButton" class="mobile-header-buttons">
				<button *ngIf="showFilterButton" mat-icon-button class="white" data-qaid="filter-button">
					<mat-icon class="mat-icon-color">filter_list</mat-icon>
				</button>

				<button *ngIf="showOverflowButton && overflowMenu" mat-icon-button class="white" [matMenuTriggerFor]="overflowMenu" data-qaid="overflow-menu-button">
					<mat-icon class="mat-icon-color">more_vert</mat-icon>
				</button>
			</div>
		</div>

		<!-- Sub-Nav -->
		<div *ngIf="!isPreferences" class="sub-nav-tab"
			[class.golf]="isGolfSite">
			<nav mat-tab-nav-bar>
					<span *ngFor="let item of routesList">
						<a mat-tab-link rbScrollRouterLinkIntoView
							[routerLink]='item.url' [queryParamsHandling]='item.queryParamsHandling'
							*ngIf="(!item.isGolfSite || (item.isGolfSite && isGolfSite) || (!item.isGolfSite && !isGolfSite))
							&& (!item.isCommercialOwner || (item.isCommercialOwner && (isOwner && !isGolfSite)))
							&& (!item.isAdminOrOwner || (item.isAdminOrOwner && (isAdmin || isOwner)))
							" routerLinkActive="active" attr.data-qaid="sub-nav-tab-{{item.name | replaceWhitespace: '_' }}">
							<span>{{getNavName(item.name)}}</span>
							<span *ngIf="navIcon" [ngClass]="navIcon.cssClass">{{navIcon.label | translate}}</span>
						</a>
					</span>
			</nav>
		</div>
		<div *ngIf="isPreferences" class="sub-nav-tab"
			[class.golf]="isGolfSite">
			<nav mat-tab-nav-bar>
					<span *ngFor="let item of routesList">
						<a mat-tab-link [routerLink]='item.url' [queryParamsHandling]='item.queryParamsHandling'
							*ngIf="!item.isGolfSite || (item.isGolfSite && isGolfSite) || (!item.isGolfSite && !isGolfSite)"
							routerLinkActive="active" attr.data-qaid="sub-nav-tab-{{item.name | replaceWhitespace: '_' }}">
							<span>{{getNavName(item.name)}}</span>
							<span *ngIf="navIcon" [ngClass]="navIcon.cssClass">{{navIcon.label | translate}}</span>
						</a>
					</span>
			</nav>
		</div>

		<div class="rb-flex-grow-0">
			<rb-multi-select-dropdown *ngIf="showSiteSelector"
				data-qaid="header-select-site"
				class="multi-select-site-selection"
				[label]="(isGolfSite ? 'STRINGS.SELECT_COURSE' : 'STRINGS.SELECT_SITE') | translate"
				[selectedSites]="selectedSites"
				[sites]="sites"
				[showSelectionCount]="true"
				(siteSelectionComplete)="onSiteSelectionComplete($event)"
			>
			</rb-multi-select-dropdown>
		</div>
	</div>
</div>
<div class="rb-cell-editor-container">
	<input #hours
		class="rb-duration-cell"
		[value]="durationHours"
		[maxLength]="maxLengthHours"
		(focus)="onFocus($event, Fields.Hours)"
		(keypress)="onKeyPress($event)"
		(keydown)="onKeyDown($event)"
		(keyup)="onKeyUp($event)"
	/>
	<span class="rb-duration-cell-sep">:</span>
	<input #minutes
		class="rb-duration-cell"
		[value]="durationMinutes"
		[maxLength]="2"
		(focus)="onFocus($event, Fields.Minutes)"
		(keypress)="onKeyPress($event)"
		(keydown)="onKeyDown($event)"
		(keyup)="onKeyUp($event)"
	/>
	<span class="rb-duration-cell-sep">:</span>
	<input #seconds
		class="rb-duration-cell"
		[value]="durationSeconds"
		[maxLength]="2"
		(focus)="onFocus($event, Fields.Seconds)"
		(keypress)="onKeyPress($event)"
		(keydown)="onKeyDown($event)"
		(keyup)="onKeyUp($event)"
	/>
</div>

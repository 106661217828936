<div class="rb-cell-editor-container">
	<mat-select class="select-list-cell-editor" #selectList
		[(value)]="value"
		(keydown)="onKeyDown($event)"
		(selectionChange)="onSelectItem($event)"
	>
		<mat-option *ngFor="let item of listItems" [value]="item.value">
			{{item.name}}
		</mat-option>
	</mat-select>
</div>

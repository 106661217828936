<div class="rb-cell-editor-container" style="height: 100%;">
	<div class="make-firefox-happy">
		<input #i
			[ngClass]="{'text-align-right': alignTextRight}"
			[value]="value"
			(keypress)="onKeyPress($event)"
			(keydown)="onKeyDown($event)"
			(keyup)="onKeyUp($event)"
		/>
	</div>
	<div class="rb-eg-fr-units">{{unitsString}}</div>
</div>


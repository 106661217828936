import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'rb-button-with-spinner',
	templateUrl: './button-with-spinner.component.html',
	styleUrls: ['./button-with-spinner.component.scss'],
})
export class ButtonWithSpinnerComponent implements OnInit {

	@Input() label = '';
	@Input() justifyContent: string;
	@Input() marginBottom: string;
	@Input() marginTop: string;
	@Input() showSpinner = false;
	@Input() width: string;
	@Input() disabled = false;

	@Output() click = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}
}

import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';

export class CompanyPreferences {

	constructor(json: any = null) {

		if (json) {
			// Map the json object to 'this' objects properties.
			Object.assign(this, json);
		}
	}

	id: number;
	sessionTimeoutMins: number;
	timeZone: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	countryCode: string;
	contactName: string;
	phoneNumber: string;
	email: string;
	overrideBranchFZCapacities: boolean;
	allowManualWhileOFF: boolean;
	controlledShutdownTimeLong: number;
	systemMode: RbEnums.Common.SystemMode;
	waterBudget: number;
	diagnosticOnOffDelaySeconds: number;
	diagnosticVoltageHighRange: number;
	diagnosticVoltageLowRange: number;
	floGuardCapacity: number;
	irrigationDayStartTime: Date;
	accessBetaFeatures: boolean;
	allowFieldTestFeatures: boolean;
	allow3rdPartyApi: boolean;
	allowSaaS: boolean;
	allowPST: boolean;
	freedomPort: string;
	uuid: string;

	/**
	 * Request the weather query parameters given our company address information.
	 */
	get weatherForecastQueryParam(): string {
		return RbUtils.Weather.GetWWOQueryParams('', this.city, this.state, this.countryCode, this.zip);
	}
}

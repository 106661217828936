import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BreadcrumbRoute } from './breadcrumb-route.model';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { ConnectDataPackManagerService } from '../../../api/connect-data-pack/connect-data-pack-manager.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ManualControlManagerService } from '../../../api/manual-control/manual-control-manager.service';
import { ManualControlState } from '../../../api/manual-control/models/manual-control-state.model';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
	selector: 'rb-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'rb-breadcrumb';

	@Input() header;
	@Input() suppressAutoBackNavigation = false;
	@Input() returnRoute: BreadcrumbRoute;
	@Input() hideBackArrow = false;
	@Input() showControllerConnectionStatus = false;
	@Input() titleTooltip: string;
	@Input() isMobile;
	@Input() isGolf;

	@Output() backClick = new EventEmitter();

	isConnected = false;
	isRetrievingDataPacks = false;

	private _controllerId: number;
	get controllerId(): number {
		return this._controllerId;
	}

	@Input() set controllerId(controllerId: number) {
		this._controllerId = controllerId;
		this.updateManualControlStateDisplay(this.manualControlManager.getManualControlState(controllerId));
	}

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(
		private broadcastService: BroadcastService,
		private connectDataPackManager: ConnectDataPackManagerService,
		private deviceManager: DeviceManagerService,
		private location: Location,
		private manualControlManager: ManualControlManagerService,
		private router: Router
	) { }

	ngOnInit() {
		if (this.showControllerConnectionStatus && this.controllerId) {
			/** ================================================================================================================================================
			 * Get last ManualControlState if it exists and is not stale. Allows for immediate loading of component if we are already connected.
			 *
			 * NOTE: If the user has refreshed the browser while this component is loaded, we need to delay the call to getManualControlState() to ensure the
			 * system has had ample time to load the current connection state. W/o the delay, this call often occurs before the system has had enough time to
			 * load the current connection state. In this scenario, the connection state will not be accurately displayed until the next 'connection state'
			 * SignalR callback occurs (which can be upwards of 1 minute). This issue only occurs during a page refresh. During normal navigation, the stat
			 * is always up to date and available. Through testing, a one second delay has proven to provide enough time for the current connection state to
			 * be accurately loaded.
			 * ============================================================================================================================================== */
			if (!this.deviceManager.browserRefreshed) {
				this.updateManualControlStateDisplay(this.manualControlManager.getManualControlState(this.controllerId));
			} else {
				setTimeout(() => this.updateManualControlStateDisplay(this.manualControlManager.getManualControlState(this.controllerId)), 1000);
			}
		}

		// Monitor changes to the ManualControlState for the given controller.
		this.manualControlManager.manualControlStateChange
			.pipe(
				untilDestroyed(this),
				filter((mcs: ManualControlState) => mcs && mcs.controllerId === this.controllerId)
			)
			.subscribe((manualControlState: ManualControlState) => {
				this.updateManualControlStateDisplay(manualControlState);
			});

		// Monitor Manual Control Connecting Status
		// this.manualControlManager.isConnecting
		// 	.pipe(
		// 		untilDestroyed(this),
		// 		filter((satelliteId: number) => satelliteId === this.controllerId)
		// 	)
		// 	.subscribe(() => this.controlIcon = RbEnums.Common.ManualControlIcon.Connecting);

		// Monitor Controller Connection Stopped event
		this.broadcastService.connectionStopped
			.pipe(
				untilDestroyed(this),
				filter((satelliteId: number) => satelliteId === this.controllerId)
			)
			.subscribe(() => {
				this.updateManualControlStateDisplay();
			});
		this.setConnectDataPackManagerSubscriptions();
	}

	ngOnDestroy() {
		/** Required by untilDestroyed */
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onButtonClick() {
		if (this.returnRoute) {
			this.router.navigate([this.returnRoute.path], this.returnRoute.queryParams);
		} else if (!this.suppressAutoBackNavigation) {
			this.location.back();
		}

		this.backClick.emit();
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================
	private setConnectDataPackManagerSubscriptions() {
		this.connectDataPackManager.connectDataPacksJobStart
			.pipe(
				untilDestroyed(this),
				filter((data: { controllerId: number }) => data.controllerId === this.controllerId)
			)
			.subscribe(() => this.isRetrievingDataPacks = true);

		this.connectDataPackManager.connectDataPacksJobFinish
			.pipe(
				untilDestroyed(this),
				filter((data: { controllerId: number }) => data.controllerId === this.controllerId)
			)
			.subscribe(() => this.isRetrievingDataPacks = false);
	}

	private updateManualControlStateDisplay(manualControlState?: ManualControlState, isConnected?: boolean) {
		if (!isConnected) {
			if (!manualControlState || (manualControlState.isConnecting.error && !manualControlState.isConnecting.isUpdating)) {
				this.isConnected = false;
				// this.controlIcon = RbEnums.Common.ManualControlIcon.NotConnected;
				return;
			}

			if (!this.isRetrievingDataPacks && !this.isConnected &&
				(manualControlState.isConnecting.isUpdating || !manualControlState.hasReceivedConnectDataPack)) {
				this.isConnected = false;
				// this.controlIcon = RbEnums.Common.ManualControlIcon.Connecting;
				return;
			}
		}

		this.isConnected = true;
		// this.controlIcon = RbEnums.Common.ManualControlIcon.Connected;
	}
}

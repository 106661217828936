import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../common/utils/_rb.utils';

export class ControllerDifferenceItem {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	entityDifferenceType: RbEnums.Common.EntityDifferenceType;
	entityDifferenceTypeKey: any = null;
	propertyDifferenceType: RbEnums.Common.PropertyDifferenceType;
	changeType: RbEnums.Common.DifferenceChangeType;
	leftSidePropertyValue: any;
	rightSidePropertyValue: any;
	descriptionParameters: string[];

	get name(): string {
		return `${this.entityTypeString} ${this.entityDifferenceTypeKey !== null ? this.entityDifferenceTypeKey : ''}`;
	}

	get property(): string {
		return RbEnums.Common.PropertyDifferenceType[this.propertyDifferenceType]
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, function (str) { return str.toUpperCase(); });
	}

	get iq4Value(): any {
		return this.leftSidePropertyValue || '--';
	}

	get controllerValue(): any {
		return this.rightSidePropertyValue || '--';
	}

	get parameters(): string {
		return this.descriptionParameters.join(', ');
	}

	get entityTypeString(): string {
		return  RbUtils.Translate.instant(`STRINGS.${RbEnums.Common.EntityDifferenceType[this.entityDifferenceType].toUpperCase()}`);
	}
}

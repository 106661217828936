import { CultureSettings } from '../../api/culture-settings/models/culture-settings.model';
import { CultureSettingsManagerService } from '../../api/culture-settings/culture-settings-manager.service';
import { Injectable } from '@angular/core';
import { RbEnums } from '../enumerations/_rb.enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class UnitLabelService {
	constructor(private cultureManager: CultureSettingsManagerService, private translateService: TranslateService) {
	}

	private get cultureSettings(): CultureSettings {
		return this.cultureManager.cultureSetting;
	}

	getFlowRateLabel() {
		if (!this.cultureSettings) return;

		switch (this.cultureSettings.unitType) {
			case RbEnums.Common.UnitsType.Metric1:
				return this.translateService.instant('UNIT_TYPE.METRIC1');
			case RbEnums.Common.UnitsType.Metric2:
				return this.translateService.instant('UNIT_TYPE.METRIC2');
			case RbEnums.Common.UnitsType.Metric3:
				return this.translateService.instant('UNIT_TYPE.METRIC3');
			default:
				return this.translateService.instant('UNIT_TYPE.US');
		}
	}

	getPressureLabel() {
		if (!this.cultureSettings) return;

		switch (this.cultureSettings.unitType) {
			case RbEnums.Common.UnitsType.Metric1:
			case RbEnums.Common.UnitsType.Metric2:
			case RbEnums.Common.UnitsType.Metric3:
				return this.translateService.instant('UNIT_TYPE.BAR');
			case RbEnums.Common.UnitsType.English:
				return this.translateService.instant('UNIT_TYPE.PSI');
		}
	}

	formatFlow(flow: number) {
		return Number(flow).toFixed(2);
	}

	getFlowLabel() {
		switch (this.cultureSettings.unitType) {
			case RbEnums.Common.UnitsType.Metric1:
				return this.translateService.instant('UNIT_TYPE.METRIC1_FLOW');
			case RbEnums.Common.UnitsType.Metric2:
				return this.translateService.instant('UNIT_TYPE.METRIC2_FLOW');
			case RbEnums.Common.UnitsType.Metric3:
				return this.translateService.instant('UNIT_TYPE.METRIC3_FLOW');
			default:
				return this.translateService.instant('UNIT_TYPE.US_FLOW');
		}
	}

	getFlowLabelFull() {
		switch (this.cultureSettings.unitType) {
			case RbEnums.Common.UnitsType.Metric1:
				return this.translateService.instant('UNIT_TYPE.METRIC1_FLOW_FULL');
			case RbEnums.Common.UnitsType.Metric2:
				return this.translateService.instant('UNIT_TYPE.METRIC2_FLOW_FULL');
			case RbEnums.Common.UnitsType.Metric3:
				return this.translateService.instant('UNIT_TYPE.METRIC3_FLOW_FULL');
			default:
				return this.translateService.instant('UNIT_TYPE.US_FLOW_FULL');
		}
	}

	getUnitPerPulseLabel() {
		switch (this.cultureSettings.unitType) {
			case RbEnums.Common.UnitsType.Metric1:
				return this.translateService.instant('STRINGS.METRIC1_PER_PULSE_LABEL');
			case RbEnums.Common.UnitsType.Metric2:
				return this.translateService.instant('STRINGS.METRIC2_PER_PULSE_LABEL');
			case RbEnums.Common.UnitsType.Metric3:
				return this.translateService.instant('STRINGS.METRIC3_PER_PULSE_LABEL');
			default:
				return this.translateService.instant('STRINGS.US_PER_PULSE_LABEL');
		}
	}

	getUnitPerPulse() {
		switch (this.cultureSettings.unitType) {
			case RbEnums.Common.UnitsType.Metric1:
				return this.translateService.instant('UNIT_TYPE.METRIC1_PER_PULSE');
			case RbEnums.Common.UnitsType.Metric2:
				return this.translateService.instant('UNIT_TYPE.METRIC2_PER_PULSE');
			case RbEnums.Common.UnitsType.Metric3:
				return this.translateService.instant('UNIT_TYPE.METRIC3_PER_PULSE');
			default:
				return this.translateService.instant('UNIT_TYPE.US_PER_PULSE');
		}
	}

	getWeatherLabels() {
		switch (this.cultureSettings.unitType) {
			case RbEnums.Common.UnitsType.English:
				return this.translateService.instant('WEATHER_UNITS.IMPERIAL');
			default:
				return this.translateService.instant('WEATHER_UNITS.METRIC');
		}
	}

}


/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., RbUtils.Collection.isGolfSite
 * 		 See: _rb.utils.ts
 */

export namespace XXUseRbUtilsNamespace {

	export abstract class Collection {

		/** ====================================================================================================================================================
		 *  Method to update items in an existing collection with updated elements from a new collection. This allows us to update grid data w/o redrawing
		 *  the entire grid (causing, among other things, unwanted scrolling behavior). If, however, the collection counts are different, we will replace
		 *  the old collection with the new one. NOTE: We can be smarter about this if required.
		 *  ================================================================================================================================================= */
		/**
		 * @summary Update an existing collection with the data found in newCollection. If the existing collection is null
		 * or undefined, this cannot be done. If the lengths of the two collections are not equal, this cannot be done.
		 * If the update cannot be done, false is returned. If the ids of the items in the collections don't match, the
		 * update doesn't make sense and false is returned.
		 * @param originalCollection - Collection of type any[] to be updated. Items in newCollection are copied to
		 * originalCollection by id, if the list lengths match or partial updates are allowed.
		 * May be null or undefined, causing the return value to be false.
		 * @param newCollection - Collection of type any[] with new data for update. Must be non-null and non-undefined.
		 * If length differs from originalCollection and partial updates are not allowed, false will be returned.
		 * @param allowPartialUpdates - Flag to allow the original collection to be partially updated using the new collection.
		 * @param keySelector - function taking a collection element and returning the comparison key. Using this parameter
		 * allows collections where "id" is not the ID of the item to be updated (see ProgramStep!). The function will be
		 * called multiple times to extract the correct key values for comparison. The default function uses .id as the
		 * ID item.
		 * @returns boolean - true if the update was successful; false if it was not successful. If not successful, the
		 * caller should consider replacing the originalCollection array with the content of the newCollection array.
		 */
		static update(originalCollection: any[], newCollection: any[], allowPartialUpdates: boolean = false,
			keySelector: (collectionItem: any) => number = (ci) => ci.id): boolean {
			// RB-6285: Handle null and undefined. Arrays are always truthy so you can't just check if (originalCollection),
			// you have to check for null and undefined explicitly! Since we can't update the original collection completely,
			// callers should not be passing us null or undefined.
			if (originalCollection === null || originalCollection === undefined) {
				return false;	// We can't replace the original collection; it's not passed by reference.
			}

			// If the old and new collection lengths do not match are partial updates are not allowed or we have no original collection,
			// simply replace the old with the new.
			if (originalCollection.length !== newCollection.length && (!allowPartialUpdates || originalCollection.length === 0)) {
				return false;	// Could not update
			}

			// If the collection counts match, update the elements in the old collection.
			let ret = true;	// Assume success.
			newCollection.forEach(item => {
				const index = originalCollection.findIndex(s => keySelector(s) === keySelector(item));
				if (index < 0) {
					// No match. The lengths match but the contents do not. Return false.
					ret = false;
				} else {
					Object.assign(originalCollection[index], item);
				}
			});

			return ret;	// Update successful, unless we found an id mismatch inside the forEach above.
		}

	}

}

import { FlowElement } from './flow-element.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class FlowZone {

	aConnection: FlowElement;
	aConnectionId: number;
	bConnections: FlowElement[] = [];
	companyId: number;
	description: string;
	flowCapacity: number;
	flowRate: number;
	flowRateLearned = false;
	id: number;
	isHidden = false;
	name: string;
	numberOfSubBranches: number;
	ordinal: number;
	role: RbEnums.Common.FlowElementType;
	satelliteId: number;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
			if (json.aConnection) { this.aConnection = new FlowElement(json.aConnection); }
			if (json.bConnections) { this.bConnections = json.bConnections.map(item => new FlowElement(item)); }
		}
	}
}

import { Pipe, PipeTransform } from '@angular/core';

enum RoundingType {
	ROUND = 'round',
	FLOOR = 'floor',
	CEIL = 'ceil',
 }

@Pipe({ name: 'toFixed' })
export class ToFixedNumberPipe implements PipeTransform {
	/**
	 * @param value - number to transform
	 * @param decimalPlaces - number of decimal places
	 * @param roundingType - type of rounding (round, floor, ceil)
	 * @returns {string} formatted number with a fixed number of decimal places
	 */
	transform(
	  value: number,
	  decimalPlaces: number = 0,
	  roundingType: RoundingType = RoundingType.ROUND
	): string {
	  let roundedValue: number;
 
	  switch (roundingType) {
		 case RoundingType.FLOOR:
			roundedValue = Math.floor(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;
			break;
		 case RoundingType.CEIL:
			roundedValue = Math.ceil(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;
			break;
		 default: // RoundingType.ROUND
			roundedValue = Math.round(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;
			break;
	  }
 
	  return roundedValue.toFixed(decimalPlaces);
	}
}
import { of, Subject } from 'rxjs';
import { AppDateAdapter } from '../../shared-ui/custom/date-adapter';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RbUtils } from '../utils/_rb.utils';

@Injectable({
	providedIn: 'root'
})
export class BannerMessageService {
	public messageChange = new Subject<string>();
	public message = '';
	private timer: NodeJS.Timer;
	private previousMessage = 'not_set';
	constructor(private http: HttpClient,
							private dateAdapter: AppDateAdapter) { }

	start() {
		this.timer = setInterval(() => this.getData(), 60000);
	}

	stop() {
		if (this.timer)
			clearInterval(this.timer);
	}

	getData() {
		this.http
				.get<any>('assets/banner-message.json')
				.pipe(catchError(err => {
					return of({shouldDisplay: false});
				}))
				.subscribe((res: any) => {
					this.setData(res);
				});
	}

	setData(data: any) {
		this.message = this.buildMessage(data);
		if (this.message !== this.previousMessage) {
			this.previousMessage = this.message;
			this.messageChange.next(this.message);
		}
	}

	buildMessage(data: any) {
		return data.shouldDisplay
			? RbUtils.Translate.instant(data.messageKey, this.buildAdjustedDataFields(data))
			: '';
	}

	buildAdjustedDataFields(data: any): any {
		const ret = {};
		data.dataFields.forEach(f => {
			ret[f.name] = this[f.formatter](f.value);
		});
		return ret;
	}

	// supported formatters
	date(val: string) {
		return this.dateAdapter.formatDateTime(new Date(val));
	}

	none(val: string) {
		return val;
	}
}

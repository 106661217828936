import { IEnvironment, NotificationType } from './ienvironment';

export const environment: IEnvironment = {
	production: true,
	environmentOption: 1,
	useRequestUrl: true,
	preferApiAndIdentityServerPort: false,
	apiUrl: 'http://localhost/coreapi/api',
	signalRUrl: null,	// Use apiUrl-relative addressing
	identityServer4Url: 'http://localhost/coreidentityserver',
	licenseApiCloudUrl: 'https://license-api-standalone.rainbirdapi.com/api',
	
	awsRBCCAppSyncUrl: '',		// Unused in standalone
	awsRBCCAppSyncRegion: '',	// Unused in standalone
	awsRBCCAppSyncChannelFormat: '',	// Unused in standalone
	awsRBCCAppSyncApiKey: null,	// Unused in standalone

	bingKey: 'Aick-j1KyU4sTSQMEq7qG0P1rfd1tBrA3RyvNPx-RMOSKZn18Vv6cD2KIL3EJ7FJ',
	wwoUrl: 'http://api.worldweatheronline.com/premium/v1',
	wwoKey: '27a43a2610234222ada70524192710',
	VERSION: require('../../package.json').version,
	rbcc_ui: 'http://localhost',
	rainbirdStoreUrl: 'https://m2-storetest.rainbird.com/digital/auth?t=',
	client_id: '5F956F2B-09E2-49C2-9990-ECE0C6BF2EEF',
	beamerProductKeyIq4: 'khMlOSqy47446',
	beamerProductKeyCirrus: 'HQWPEaan53472',
	useMockData: false,
	cloudDryRunTableName: 'sandboxRBCC_Public',
	awsDynamoDBRegion: 'us-west-1',
	notificationType: NotificationType.SignalR
};

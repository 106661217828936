import { Controller } from '../../controllers/models/controller.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { StationArea } from '../../station-areas/station-area.model';
import { StationSensor } from '../../station-sensors/station-sensor.model';

import ValveType = RbEnums.Common.ValveType;
import StationType = RbEnums.Common.StationType;
import HeadSpacingType = RbEnums.Common.HeadSpacingType;
import SprinklerCategoryType = RbEnums.Common.SprinklerCategoryType;
import DecoderModelType = RbEnums.Common.DecoderModelType;

export class MasterValve {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.autoResumeDate != null) { this.autoResumeDate = RbUtils.Conversion.convertStringToDate(json.autoResumeDate); }
			if (json.stationArea != null) { this.stationArea = json.stationArea.map(item => new StationArea(item)); }
			if (json.stationSensor != null) { this.stationSensor = json.stationSensor.map(item => new StationArea(item)); }
			if (json.tempAdjustStart) { this.tempAdjustStart = RbUtils.Conversion.convertStringToDate(json.tempAdjustStart); }
			if (json.tempAdjustStop) { this.tempAdjustStop = RbUtils.Conversion.convertStringToDate(json.tempAdjustStop); }
		}
	}

	addressInt: number;
	adjustableArc: boolean;
	aprilAdjFactor: number;
	arc: number;
	augustAdjFactor: number;
	autoResumeDate: Date = null;
	borrowState: number;
	borrowedMvid: number;
	channel: number;
	companyId: number;
	connectState: number;
	cycleTimeCalcLong: number;
	cycleTimeLong: number;
	decemberAdjFactor: number;
	decoderModel: DecoderModelType;
	defaultRunTimeLong: number;
	description: string;
	distUniformityFinal: number;
	februaryAdjFactor: number;
	flowMonitoringId: number;
	flowRate: number;
	flowRateLearned = false;
	flowZoneId: number;
	groupNumber: number;
	headFlowRate: number;
	headRadius: number;
	headSpacing: HeadSpacingType;
	id: number;
	isAddressValid = false;
	januaryAdjFactor: number;
	juneAdjFactor: number;
	julyAdjFactor: number;
	lockout: any = null;
	marchAdjFactor: number;
	master = false;
	maxicomColorCode: number;
	mayAdjFactor: number;
	monthlyCyclingEnabled = false;
	mvDelayLong: number;
	name: string;
	nonIrr = false;
	novemberAdjFactor: number;
	nozzleId: number;
	nozzleType: string;
	octoberAdjFactor: number;
	overridePause = false;
	precRateFina: number;
	pressure: number;
	priority: number;
	rowRadius: number;
	satellite: Controller;
	satelliteId: number;
	sensor = false;
	septemberAdjFactor: number;
	slope: number;
	soakTimeCalcLong: number;
	soakTimeLong: number;
	sprinklerCategory: SprinklerCategoryType;
	stationArea: StationArea[];
	stationSensor: StationSensor[];
	tempAdjustStart: Date;
	tempAdjustStop: Date;
	tempStationAdjust: number;
	terminal: number;
	type: StationType;
	useCycleSoakTimeCalc: boolean;
	useLocalMv = false;
	useMonthlyAdjFactor: boolean;
	useMv = false;
	usePrecRateCalc: boolean;
	valveType: ValveType;
	yearlyAdjFactor: number;

	static createDefault(): MasterValve {
		const masterValve = new MasterValve();
		return masterValve;
	}
}

import { Component, ViewChild } from '@angular/core';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { CellEditorBase } from '../_cell-editor-base';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { IRbCellEditorParams } from '../_models/rb-cell-editor-params.interface';
import { MatSelect } from '@angular/material/select';
import { SelectListItem } from '../../../../../api/_common/models/select-list-item.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-select-list-cell-editor',
	templateUrl: './select-list-cell-editor.component.html',
	styleUrls: ['./select-list-cell-editor.component.scss']
})
export class SelectListCellEditorComponent extends CellEditorBase implements ICellEditorAngularComp {
	@ViewChild('selectList', { static: true }) selectList: MatSelect;

	value: number;
	listItems: SelectListItem[];

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected broadcastService: BroadcastService,
				protected translateService: TranslateService,
	) {
		super(broadcastService, translateService);
	}

	// =========================================================================================================================================================
	// agGrid Methods
	// =========================================================================================================================================================

	agInit(params: IRbCellEditorParams): void {
		super.agInit(params);

		// If the cellEditorParams is null or does not contain the controller object, throw error and abort.
		if (!this.params || this.params.getListItems == null) {
			throw new Error('Missing \'listItems\' CellEditorParam');
		}

		this.listItems = this.params.getListItems(params, this.selectList);
		const foundValue = this.listItems.find(
			value => this.params.value === value.name
		);
		
		if(foundValue !== undefined){
			this.value = foundValue.value;
		} else {
			this.value = this.params.value;
		}
	}

	afterGuiAttached(params?: IAfterGuiAttachedParams) {
		this.selectList.focus();
	}

	// Return the value from this component to the grid.
	getValue() {
		return this.selectList.value;
	}

	onSelectItem(event: any): void {
		if (this.params.colDef.field === 'terminal') {
			const parentId = this.params.data.satelliteProperties.id;
			this.params.getSelectedValue({ parentId: parentId, value: event.value });
		}
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onKeyDown(event) {
		if (this.isSelectListNavigationKey(event.key)) {
			event.stopPropagation();

			if (!this.hasFieldValidation) {
				this.navigateOnKeyDown(event.key, event.shiftKey);
			} else {
				this.validateAndNavigate(this.selectList.value, event.key, event.shiftKey);
			}
		}
	}

}

import { forkJoin, Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CimisStation } from '../stations/models/cimis-station.model';
import { GetWeatherSourceQueryParams } from '../comm-interfaces/models/get-weather-source-params.model';
import { GlobalWeatherLocation } from './models/global-weather-location.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { RealTimeWeatherData } from '../weather-data/models/real-time-weather-data.model';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { tap } from 'rxjs/internal/operators/tap';
import { UiSettingsService } from '../ui-settings/ui-settings.service';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';
import { WeatherSource } from './models/weather-source.model';
import { WeatherSourceApiService } from './weather-source-api.service';
import { WeatherSourceStatusChange } from '../signalR/weather-source-status-change.model';
import { WeatherSourceType } from './models/weather-source-type.model';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class WeatherSourceManagerService extends ServiceManagerBase {

	// Constants
	private static DEFAULT_ET_DISPLAY_ID_PREFERENCE_KEY = 'defaultWeatherStationETDisplayId';
	private readonly NO_VALUE = '-';

	// Subjects
	weatherSourcesChange = new Subject();
	weatherSourceUpdated = new Subject</* id */ number>();
	weatherSourceAdded = new Subject</* id */ number>();
	weatherSourceDeleted = new Subject</* id */ number>();

	/**
	 * This Subject is sent, along with the SignalR message, whenever a WeatherSourceValueChange is received.
	 */
	weatherSourceDataChange = new Subject<WeatherSourceStatusChange>();

	/**
	 * This dictionary mapping a weather source Id to its most-recently-received RealTimeWeatherData can be used to render the
	 * WeatherSource data. This is preferable to components monitoring weatherSourceDataChange themselves, since it's up-to-date
	 * even when the component is created. If you wait for component creation, you won't get weather data until the next receipt,
	 * typically one minute later.
	 */
	currentConditionsByWeatherSourceId = new Map</* id: */ number, RealTimeWeatherData>();

	weatherSourceIdsHasGotEtCalendarData = [];

	private _apiResult: ApiCachedRequestResponse<WeatherSource[]>;

	private lastGetWeatherSourceQueryParams: GetWeatherSourceQueryParams;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private weatherSourceApiService: WeatherSourceApiService,
				private uiSettingsService: UiSettingsService,
				protected broadcastService: BroadcastService
	) {
		super(broadcastService);

		this.weatherSourceDeleted
			.pipe(untilDestroyed(this))
			.subscribe((weatherSourceId) => {
				// Also remove any stored weather source data for the weather source.
				this.currentConditionsByWeatherSourceId.delete(weatherSourceId);
			});

		// We keep the latest weather source data in a property so we have it from the start of operations.
		this.weatherSourceDataChange
			.pipe(untilDestroyed(this))
			.subscribe((wsChange) => {
				// If there is data for the weather source (there always should be), save it in our dictionary by
				// WeatherSource.Id.
				if (wsChange.weatherData != null) {
					this.currentConditionsByWeatherSourceId[wsChange.weatherSourceId] = wsChange.weatherData;
				}
			});
	}

	// =========================================================================================================================================================
	// Base Class Overrides
	// =========================================================================================================================================================

	protected clearCache() {
		this.weatherSourceApiService.clearCache();
		this._apiResult = null;
	}

	// =========================================================================================================================================================
	// Public Properties and Methods
	// =========================================================================================================================================================

	createWeatherSource(updateData: any): Observable<WeatherSource> {
		const addWeatherSource = {
			name: updateData.name,
			type: updateData.type
		};
		if (updateData.stationId != null) addWeatherSource['stationid'] = updateData.stationId;
		if (updateData.timeZone != null) addWeatherSource['timeZone'] = updateData.timeZone;
		if (updateData.elevation != null) addWeatherSource['elevation'] = updateData.elevation;
		if (updateData.pakBusAddress != null) addWeatherSource['pakBusAddress'] = updateData.pakBusAddress;
		if (updateData.latitude != null) addWeatherSource['latitude'] = updateData.latitude;
		if (updateData.longitude != null) addWeatherSource['longitude'] = updateData.longitude;
		if (updateData.password != null) addWeatherSource['password'] = updateData.password;
		if (updateData.programName != null) addWeatherSource['programName'] = updateData.programName;
		if (updateData.etCalculated != null) addWeatherSource['etCalculated'] = updateData.etCalculated;
		if (updateData.wsAutoContactTime != null) addWeatherSource['wsAutoContactTime'] = updateData.wsAutoContactTime;
		if (updateData.contactType != null) addWeatherSource['contactType'] = updateData.contactType;
		if (updateData.highestAvgET != null) addWeatherSource['highestAvgET'] = updateData.highestAvgET;
		if (updateData.apiKey != null) addWeatherSource['apiKey'] = updateData.apiKey;
		if (updateData.serialNumber != null) addWeatherSource['serialNumber'] = updateData.serialNumber;

		addWeatherSource['siteIds'] = updateData?.siteIds ?? [];
		addWeatherSource['controllerIds'] = updateData?.controllerIds ?? [];

		if (updateData.commInterface != null) {
			switch (updateData.commInterface.type) {
				case RbEnums.Common.CommInterfaceType.Ethernet:
					addWeatherSource['connectionType'] = updateData.commInterface.type;
					addWeatherSource['ipName'] = updateData.commInterface.ipString;
					addWeatherSource['ipPort'] = updateData.commInterface.ipPort;
					break;
				case RbEnums.Common.CommInterfaceType.Serial:
					addWeatherSource['connectionType'] = updateData.commInterface.type;
					addWeatherSource['comPort'] = updateData.commInterface.comPort;
					break;
				default:
					addWeatherSource['connectionType'] = updateData.commInterface.type;
					break;
			}

		}

		return this.weatherSourceApiService.createWeatherSource(addWeatherSource).pipe(tap(response => this.weatherSourcesChange.next(null)));
	}

	deleteWeatherSources(weatherSourceIds: number[]): Observable<null> {
		return this.weatherSourceApiService.deleteWeatherSources(weatherSourceIds).pipe(tap(response => this.weatherSourcesChange.next(null)));
	}

	getCimisStations(): Observable<CimisStation[]> {
		return this.weatherSourceApiService.getCimisStations().pipe(map(response => response.value));
	}

	/**
	 * Return ET value associated with user's chosen "default" ET Weather Source.
	 */
	getEtValue(bypassCache = false): Observable<any> {
		return forkJoin([
			this.getWeatherSources(bypassCache),
			this.uiSettingsService.getPreference(WeatherSourceManagerService.DEFAULT_ET_DISPLAY_ID_PREFERENCE_KEY)
		]).pipe(map(([sources, etDisplayId]) => {
			// If the default ET display id missing or does not exist
			// fall back to the first-weather-source behavior
			const et = sources.find(s => s.id === etDisplayId) || sources.find(s => s.calculatedEt > 0);
			return et ? et.calculatedEt : 0;
		}));
	}

	getNameUniqueness(name: string, id: number): Observable<UniquenessResponse> {
		return this.weatherSourceApiService.getNameUniqueness(name, id);
	}

	getWeatherSource(id: number, queryParams?: GetWeatherSourceQueryParams, bypassCache = false): Observable<WeatherSource> {
		const selectedQueryParams = queryParams ? queryParams : this.lastGetWeatherSourceQueryParams;
		return this.weatherSourceApiService.getWeatherSource(id, selectedQueryParams, bypassCache);
	}

	getWeatherSources(bypassCache = false): Observable<WeatherSource[]> {
		return this.weatherSourceApiService.getWeatherSources(bypassCache).pipe(map(response => {
			this._apiResult = response;
			return this._apiResult.value;
		}));
	}

	getWeatherSourceTypes(): Observable<WeatherSourceType[]> {
		return this.weatherSourceApiService.getWeatherSourceTypes().pipe(map(response => response.value));
	}

	getWeatherSourceName(weatherSourceId: number): string {
		if (!this._apiResult || this._apiResult.value.length < 1) return this.NO_VALUE;

		const weatherSource = this._apiResult.value.find(c => c.id === weatherSourceId);
		return weatherSource ? weatherSource.name : this.NO_VALUE;
	}

	isGlobalWeatherSource(weatherSourceId: number): Observable<boolean> {
		return this.getWeatherSources()
			.pipe(
				map((weatherSources: WeatherSource[]) => {
					const weatherSource = weatherSources.find(ws => ws.id === weatherSourceId);
					return (weatherSource && weatherSource.type === RbEnums.Common.WeatherSourceType.GlobalWeather);
				})
			);
	}

	isWeatherSourceSupportET(weatherSourceId: number): Observable<boolean> {
		return this.getWeatherSources()
			.pipe(
				map((weatherSources: WeatherSource[]) => {
					const weatherSource = weatherSources.find(ws => ws.id === weatherSourceId);
					return (weatherSource && RbUtils.Common.isSupportedWeatherSourceForCalendar(weatherSource.type));
				})
			);
	}

	searchGlobalWeatherLocations(search: string): Observable<GlobalWeatherLocation[]> {
		return this.weatherSourceApiService.searchGlobalWeatherLocations(search);
	}

	getSevenDayForecast() {
		return this.weatherSourceApiService.getSevenDayForecast();
	}

	updateWeatherSource(weatherSourceId: number, updateData: any): Observable<null> {
		return this.weatherSourceApiService.updateWeatherSource(weatherSourceId, updateData).pipe(tap(response => this.weatherSourcesChange.next(null)));
	}

	setDefaultWeatherSource(weatherSourceId: number): Observable<null> {
		return this.uiSettingsService.setPreference(WeatherSourceManagerService.DEFAULT_ET_DISPLAY_ID_PREFERENCE_KEY, weatherSourceId)
			.pipe(tap(() => this.weatherSourcesChange.next(null)));
	}

	collectWeatherData(weatherSourceId: number): Observable<any> {
		if (this.weatherSourceIdsHasGotEtCalendarData.includes((ws: any) => ws.weatherSourceId === weatherSourceId && ws.isRetrievedData)) {
			return of({});
		} else {
			// Add the weather data with default isRetrievedData is True
			if (this.weatherSourceIdsHasGotEtCalendarData.findIndex(w => w.weatherSourceId === weatherSourceId) === -1)
				this.weatherSourceIdsHasGotEtCalendarData.push({ weatherSourceId: weatherSourceId, isRetrievedData: true });

			return this.weatherSourceApiService.collectWeatherData(weatherSourceId);
		}
	}

	updateWeatherDataMonthlyAverages(weatherSrcId: number, wsUpdate: any): Observable<null> {
		return this.weatherSourceApiService.updateWeatherDataMonthlyAverages(weatherSrcId, wsUpdate)
			.pipe(tap(() => {
				this.weatherSourcesChange.next(null);
			}));
	}
}


import { debounceTime } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { FormValidationService } from '../../common/services/form-validation.service';
import {  NgControl } from '@angular/forms';
import { RbConstants } from '../../common/constants/_rb.constants';

import { Directive, ElementRef, HostListener, Inject, Input, OnInit} from '@angular/core';

@Directive({
	selector: 'input[rbFormValidation], textarea[rbFormValidation]',
})
export class RbFormValidationDirective implements OnInit {
	@Input() placeholder: string;
	constructor(
		private formValidationService: FormValidationService,
		private elRef: ElementRef,
		private control: NgControl,
		@Inject(DOCUMENT) private document: Document
	) {}

	ngOnInit(): void {
		this.control.valueChanges.pipe(debounceTime(RbConstants.Form.DEBOUNCE_TIME_MS)).subscribe(() => {
			this.initilizeErrorMessage();
		});
	}

	@HostListener('focusout')
	setInputFocus(): void {
		if (this.control.pristine) {
			this.control.control.markAsDirty();
			this.initilizeErrorMessage();
		}
	}

	private initilizeErrorMessage() {
		const hostElm = this.elRef.nativeElement.closest('mat-form-field');
		const errorMessageClassName = 'warnColorText';
		const errorMessageElm = hostElm.getElementsByClassName(errorMessageClassName)[0];
		const hintElm = hostElm.getElementsByClassName('mat-hint')[0];
		if (errorMessageElm) {
			errorMessageElm.remove();
		}

		if (hintElm) {
			hintElm.style.visibility = 'visible';
		}

		const errorMessage = this.formValidationService.getValidationMessageForOneCase(this.control.control, this.placeholder);
		if (errorMessage && errorMessage.length) {
			const errorElement = this.document.createElement('div');
			errorElement.className = errorMessageClassName;
			errorElement.textContent = errorMessage;
			hostElm.appendChild(errorElement);
			if (hintElm) {
				hintElm.style.visibility = 'hidden';
			}
		}
	}
}

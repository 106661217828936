import { DiagnosticData } from './diagnostic-data.model';
import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class LINKHourlyDiagnosticData extends DiagnosticData {

	hourOfDay: number;
	daysAccumulated: number;
	systemAvailability: number;
	role: number;
	id: number;
	companyId: number;
	updateTime: Date;
	satelliteId: number;
	groupNumber: number;
	channel: number;

	constructor(json: any = null) {
		super(json);
		this.cdType = RbEnums.Common.DiagnosticDataType.LINKDiagnostics;

		if (json) {
			Object.assign(this, json);
		}
	}
}

import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class CatalogModel {

	id: number;
	name: string;
	type: RbEnums.Common.SprinklerType;
	isCommercial: boolean;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}

	/**
	 * RB-14444: We now have all of the category types and each should display only it's own elements (rotors => rotors, etc.)
	 * @param category - The sprinkler category type currently chosen by the user in the UI.
	 * @returns boolean true if the current sprinkler CatalogModel is of a type matching the indicated user-chosen category;
	 * false if not (category = Rotors but catalog model = Impacts, for example).
	 */
	isSprinklerForCategory(category: RbEnums.Common.SprinklerCategoryType) {
		return (category === RbEnums.Common.SprinklerCategoryType.Rotors &&	this.type === RbEnums.Common.SprinklerType.Rotors) || 
			(category === RbEnums.Common.SprinklerCategoryType.RotaryNozzles && this.type === RbEnums.Common.SprinklerType.RotaryNozzles) ||
			(category === RbEnums.Common.SprinklerCategoryType.SprayHeads && this.type === RbEnums.Common.SprinklerType.SprayNozzles) || 
			(category === RbEnums.Common.SprinklerCategoryType.ImpactSprinklers && this.type === RbEnums.Common.SprinklerType.Impacts) ||
			(category === RbEnums.Common.SprinklerCategoryType.DripInLine && this.type === RbEnums.Common.SprinklerType.Driplines) ||
			(category === RbEnums.Common.SprinklerCategoryType.DripEmitter && this.type === RbEnums.Common.SprinklerType.DripEmitters) ||
			(category === RbEnums.Common.SprinklerCategoryType.Bubblers && this.type == RbEnums.Common.SprinklerType.Bubblers)
			;
	}
}

import * as moment from 'moment';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { DryRunPeriod } from './models/dry-run-period.model';
import { RbConstants } from '../../../../common/constants/_rb.constants';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../common/utils/_rb.utils';
import { SelectListItem } from '../../../../api/_common/models/select-list-item.model';
import { TranslateService } from '@ngx-translate/core';

import DryRunView = RbEnums.Common.DryRunView;

@UntilDestroy()
@Component({
	selector: 'rb-dry-run-toolbar',
	templateUrl: './dry-run-toolbar.component.html',
	styleUrls: ['./dry-run-toolbar.component.scss']
})
export class DryRunToolbarComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'rb-dry-run-toolbar';

	@Output() dryRunViewChange = new EventEmitter<RbEnums.Common.DryRunView>();
	@Output() runClick = new EventEmitter<{ startDate: Date, endDate: Date }>();
	@Output() selectedDurationDaysChange = new EventEmitter<number>();
	@Output() generatePdfClick = new EventEmitter();
	@Output() includeStationChange = new EventEmitter<boolean>();
	@Output() showPumpsChange = new EventEmitter<boolean>();

	// Constant / Enum References
	RbUtilsDateRange = RbUtils.DateRange;
	dryRunViewType = RbEnums.Common.DryRunView;
	DATE_RANGE = RbConstants.Form.DATE_RANGE;
	customIconNumber = RbConstants.Form.CUSTOM_ICON_NUMBER.pending_actions;

	@Input() isChartViewDisabled = false;
	@Input() isPrintEnabled = false;
	@Input() hidePrintButton = false;
	@Input() isScheduleViewDisabled = false;
	@Input() showScheduleViewButton = false;
	@Input() isRunButtonDisabled = false;

	private _dryRunView = RbEnums.Common.DryRunView.Chart;
	@Input() set dryRunView(value: RbEnums.Common.DryRunView) {
		this._dryRunView = value;
		this.dryRunViewChange.next(value);
	}

	get dryRunView(): RbEnums.Common.DryRunView {
		return this._dryRunView;
	}

	private _selectedDurationDays: number;

	// @ts-ignore
	@Input() set selectedDurationDays(value: number) {
		if (this._selectedDurationDays === value) return;

		this._selectedDurationDays = value;
		this.updateStartAndEndDates();
	}

	get selectedDurationDays(): number {
		return this._selectedDurationDays;
	}

	private _includeStation: boolean;

	// @ts-ignore
	@Input() set includeStation(value: boolean) {
		if (this._includeStation === value) return;
		this.includeStationChange.emit(value);
		this._includeStation = value;
	}

	get includeStation(): boolean {
		return this._includeStation;
	}

	private _showPumps: boolean;


	@Input() set showPumps(value: boolean) {
		if (this._showPumps === value) return;
		this.showPumpsChange.emit(value);
		this._showPumps = value;
	}

	get showPumps(): boolean {
		return this._showPumps;
	}

	isMobile = false;
	isGolfSite = false;
	dayOptions: SelectListItem[] = [];
	isMobileFilterDisplayed = false;

	private startDate: Date;
	private endDate: Date;

	private readonly GOLF_LIST_VIEW_MOBILE_TOOLBAR_CUTOFF = 880;
	private readonly IQ4_CHART_VIEW_MOBILE_TOOLBAR_CUTOFF = 820;
	private readonly IQ4_LIST_VIEW_MOBILE_TOOLBAR_CUTOFF = 930;

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private deviceManager: DeviceManagerService,
				private translate: TranslateService
	) { }

	ngOnInit() {
		this.isMobile = this.deviceManager.isMobile;
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		

		// Monitor mobile resizing
		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe((isMobile: boolean) => {
				this.isMobile = isMobile || this.isToolbarMobileCutoff(isMobile);
			});

		this.updateStartAndEndDates();
		this.setupDayOptions();
	}

	ngOnDestroy(): void {
		/** Required for untilDestroyed() */
	}

	private isToolbarMobileCutoff(isCurrentlyMobile: boolean): boolean {
		if (isCurrentlyMobile) { return true; }

		if (this.isGolfSite) {
			return this.dryRunView === this.dryRunViewType.List && window.innerWidth <= this.GOLF_LIST_VIEW_MOBILE_TOOLBAR_CUTOFF;
		} else /** Commercial **/ {
			return this.dryRunView !== this.dryRunViewType.List
				? window.innerWidth <= this.IQ4_CHART_VIEW_MOBILE_TOOLBAR_CUTOFF
				: window.innerWidth <= this.IQ4_LIST_VIEW_MOBILE_TOOLBAR_CUTOFF;
		}
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onRunClick() {
		this.runClick.emit({ startDate: this.startDate, endDate: this.endDate });
	}

	getDayString(value: number): string {
		return this.translate.instant(value > 1 ? 'STRINGS.DAYS' : 'STRINGS.DAY');
	}

	generatePdf() {
		this.generatePdfClick.emit();
	}

	onDateRangeChange(dryRunPeriod: DryRunPeriod) {
		this.startDate = dryRunPeriod.startDate;
		this.endDate = dryRunPeriod.endDate;
	}

	onDryRunViewChange(view: DryRunView) {
		this.dryRunView = view;
		this.isMobile = this.isToolbarMobileCutoff(this.isMobile);
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private setupDayOptions() {
		this.dayOptions.push(new SelectListItem({ name: this.translate.instant('STRINGS.CUSTOM'), value: this.DATE_RANGE.custom }));
		this.dayOptions.push(new SelectListItem({ name: this.translate.instant('DATE_RANGES.ONE_DAY'), value: this.DATE_RANGE.oneDay }));
		this.dayOptions.push(new SelectListItem({ name: this.translate.instant('DATE_RANGES.THREE_DAYS'), value: this.DATE_RANGE.threeDays }));
		this.dayOptions.push(new SelectListItem({ name: this.translate.instant('DATE_RANGES.ONE_WEEK'), value: this.DATE_RANGE.oneWeek }));
	}

	private updateStartAndEndDates() {
		const startDateMoment = RbUtils.DateRange.setToNoonHour(moment());
		this.startDate = startDateMoment.toDate();
		this.endDate = startDateMoment.add(this._selectedDurationDays, 'days').subtract(1, 'minutes').toDate();
	}

}

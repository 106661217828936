<div id="controller-control-container">
    <span>{{'STRINGS.OFF' | translate | titlecase}}</span>
    <mat-slide-toggle
            #energizeToggle
            class="energize-toggle"
            color="primary"
            [ngModel]="segmentInfoItem?.isEnergized"
            [disabled]="isDisabled"
            (change)="onToggleEnergizePath($event)"
    ></mat-slide-toggle>
    <span>{{'STRINGS.ENERGIZE' | translate | titlecase}}</span>
</div>

export class CatalogNozzle {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	adjustableArc = false;
	arc: number;
	color: string;
	defaultFlowRateID: number;
	id: number;
	modelId: number;
	name: string;
	rotation: number;

}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { QuickCheckData } from '../../../../../api/manual-ops/models/quick-check-data.model';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';

@Component({
	selector: 'rb-icm-quick-check-cell-renderer',
	templateUrl: './icm-quick-check-status-cell-renderer.component.html',
	styleUrls: ['./icm-quick-check-status-cell-renderer.component.scss']
})
export class IcmQuickCheckStatusCellRendererComponent implements ICellRendererAngularComp {

	data: QuickCheckData;

	RbEnums = RbEnums;

	// =========================================================================================================================================================
	// ICellRendererAngularComp Members
	// =========================================================================================================================================================

	agInit(params: any): void {
		this.data = params.data;
	}

	refresh(params: any): boolean {
		this.data = params.data;
		return true;
	}

	// =========================================================================================================================================================
	// Public Properties
	// =========================================================================================================================================================
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WorldWeatherCurrentCondition } from '../../../../api/weather/models/world-weather-current-condition.model';
import { WorldWeatherWeather } from '../../../../api/weather/models/world-weather-weather.model';

@Component({
	selector: 'rb-weather-widget-tile',
	templateUrl: './weather-widget-tile.component.html',
	styleUrls: ['./weather-widget-tile.component.scss']
})
export class WeatherWidgetTileComponent implements OnInit, OnDestroy {
	@Output() tileClick = new EventEmitter<number>();

	@Input() tileData: WorldWeatherWeather;
	@Input() tileIndex: number;
	@Input() location: string;
	@Input() currentConditions: WorldWeatherCurrentCondition;

	private readonly cssTransitionSpeedInMs = 500;

	@Input() set isExpanded(value: boolean) {
		// Take no action
		if ((this.isDetailPanelVisible && value) || (!this.isDetailPanelVisible && !value)) return;

		// Collapse detail panel
		if (this.isDetailPanelVisible) {
			this.isSummaryPanelVisible = true;
			setTimeout(() => this.showExpandedPanel = false);

			setTimeout(() => {
				this.isDetailPanelVisible = false;
			}, this.cssTransitionSpeedInMs);
			return;
		}

		// Show detail panel
		this.isDetailPanelVisible = true;

		setTimeout(() => {
			this.showExpandedPanel = true;
			setTimeout(() => this.isSummaryPanelVisible = false, this.cssTransitionSpeedInMs);
		});
	}

	showExpandedPanel = false;
	isSummaryPanelVisible = true;
	isDetailPanelVisible = false;
	isChanceofRain = false;
	clockId: NodeJS.Timer;
	clockString = '';

	// =========================================================================================================================================================
	// C'tor and Init
	// =========================================================================================================================================================

	constructor() { }

	ngOnInit() {
		this.clockId = setInterval(() => {
			this.clockString = this.dateTimeString;
		}, 1000);

		this.isChanceofRain = ((this.chanceofRainPercent === '0%') || (this.chanceofRainPercent === '')
			|| (this.chanceofRainPercent === null)) ? false : true;
	}

	ngOnDestroy() {
		clearInterval(this.clockId);
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onTileClick() {
		this.tileClick.emit(this.tileIndex);
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	get dateTimeString(): string {
		return this.currentConditions !== null ? `${this.currentConditions.dateString} - ${this.currentConditions.timeString}` :  '';
	}

	get currentTemperature(): string {
		return this.currentConditions !== null ? this.currentConditions.currentTemp : this.tileData.averageTemp;
	}

	/**
	 * tempRange returns an object used to localize the forecast high/low temperatures. Since the correct format
	 * for such strings will vary with the language/culture, it's stored in translatable HTML string with parameter
	 * substitutions for {{high}} and {{low}}, provided by our return value.
	 */
	get tempRange(): { high: string, low: string } {
		return {
			high: this.tileData ? this.tileData.maxTemp : '',
			low: this.tileData ? this.tileData.minTemp  : ''
		};
	}

	get chanceofRainPercent(): string {
		return ((this.tileData !== null) && (this.tileData.hourly != null) && (this.tileData.hourly.length > 0))
			? (this.tileData.hourly[0].chanceofrain + '%') : '';
	}
}

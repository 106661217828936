import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'rb-user-right-sidebar',
	templateUrl: './user-right-sidebar.component.html',
	styleUrls: ['./user-right-sidebar.component.scss']
})
export class UserRightSidebarComponent implements OnInit {
	@HostBinding('class') class = 'right-sidebar-component';

	@Input() isSidebarOpenComplete = false;
	@Input() selectedUserId: number;

	@Output() complete = new EventEmitter<{ savedData: boolean }>();

	_sidebarTitle = '';
	get sidebarTitle(): string { return this._sidebarTitle; }
	set sidebarTitle(value: string) {
		setTimeout(() => { this._sidebarTitle = value; });
	}

	constructor() { }

	ngOnInit() {
	}

	onClose() {
		this.complete.emit({ savedData: false });
	}

	onEditComplete(event: { savedData: boolean }) {
		this.complete.emit(event);
	}
}

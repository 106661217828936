import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { CommInterfaceManagerService } from '../../../api/comm-interfaces/comm-interface-manager.service';
import { CommResource } from '../../../api/comm-interfaces/models/comm-resource.model';
import { CompanyManagerService } from '../../../api/companies/company-manager.service';
import { MessageBoxService } from '../../../common/services/message-box.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-freedom-system-dialog',
	templateUrl: './freedom-system-dialog.component.html',
	styleUrls: [ './freedom-system-dialog.component.scss' ]
})
export class FreedomSystemDialogComponent implements OnInit {

	@Output() complete = new EventEmitter<{ savedData: boolean }>();

	connectionType: string;
	connectionTypes: CommResource[] = [];
	private companyId: number;
	private noneString: string;

	constructor(private authManager: AuthManagerService,
				private commInterfaceManager: CommInterfaceManagerService,
				private companyManager: CompanyManagerService,
				private messageBoxService: MessageBoxService,
				private translate: TranslateService) { }

	ngOnInit() {
		this.noneString = this.translate.instant('STRINGS.NONE');
		this.companyId = this.authManager.getUserProfile().companyId;

		this.companyManager.getCompanyPreferences().pipe(take(1)).subscribe(preferences => {
			this.connectionType = preferences.freedomPort == null ? this.noneString : preferences.freedomPort;
		});

		// RB-943: Alway refresh the COM port list when we open the editor
		this.commInterfaceManager.getCommResourcesPort(true).pipe(take(1)).subscribe(commResourcePorts => {
			this.connectionTypes = [...commResourcePorts];
			this.connectionTypes.unshift(new CommResource({ displayName: this.noneString }));
		});

	}

	onSave() {
		const noneSelected = this.connectionType === this.noneString;
		this.companyManager.updateCompany(this.companyId, { freedomPort: noneSelected ? null : this.connectionType })
			.subscribe(() => {
				this.complete.emit({ savedData: true });
			}, error => {
				this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED');
			});
	}
}

/* eslint-disable @typescript-eslint/member-ordering */
import * as lodash from 'lodash';
import { AlertPopup, StationWithMapInfo } from './station-with-map-info.model';
import { CdkDragDrop, CdkDragMove } from '@angular/cdk/drag-drop';
import { finalize, share, take } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { AdvanceStation } from '../../api/stations/models/advance-station.model';
import { Area } from '../../api/areas/models/area.model';
import { AreaManagerService } from '../../api/areas/area-manager.service';
import { AreaWithMapInfo } from './area-with-map-info.model';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { BroadcastService } from '../services/broadcast.service';
import { CenterOnLocationControl } from './center-on-location-control';
import { CollectionChange } from './collection-change.model';
import { CompanyManagerService } from '../../api/companies/company-manager.service';
import { CompanyPreferences } from '../../api/companies/models/company-preferences.model';
import { ControllerGetLogsState } from '../../api/signalR/controller-get-logs-state.model';
import { ControllerListItem } from '../../api/controllers/models/controller-list-item.model';
import { ControllerManagerService } from '../../api/controllers/controller-manager.service';
import { ControllerWithMapInfo } from './controller-with-map-info.model';
import { DeviceManagerService } from '../services/device-manager.service';
import { Duration } from 'moment';
import { EnvironmentService } from '../services/environment.service';
import { FullScreenControl } from './full-screen-control';
import { GeoGroup } from '../../api/regions/models/geo-group.model';
import { GeoGroupManagerService } from '../../api/regions/geo-group-manager.service';
import { GeoItem } from '../../api/regions/models/geo-item.model';
import { HoleWithMapInfo } from './hole-with-map-info.model';
import { IcShortAddressPollData } from '../../api/manual-ops/models/ic-short-address-poll-data.model';
import { IcVoltagePollData } from '../../api/manual-ops/models/ic-voltage-poll-data.model';
import { ManualControlState } from '../../api/manual-control/models/manual-control-state.model';
import { ManualOpsManagerService } from '../../api/manual-ops/manual-ops-manager.service';
import { MapInfoContextMenu } from './map-info-context-menu';
import { MapInfoPreferences } from './map-info-preferences';
import { MapService } from '../services/map.service';
import { MessageBoxInfo } from '../../core/components/global-message-box/message-box-info.model';
import { MessageBoxService } from '../services/message-box.service';
import { RbConstants } from '../constants/_rb.constants';
import { RbEnums } from '../enumerations/_rb.enums';
import { RbUtils } from '../utils/_rb.utils';
import { Renderer2 } from '@angular/core';
import { Site } from '../../api/sites/models/site.model';
import { SiteManagerService } from '../../api/sites/site-manager.service';
import { StartStationModel } from '../../api/manual-ops/models/start-station.model';
import { Station } from '../../api/stations/models/station.model';
import { StationListItem } from '../../api/stations/models/station-list-item.model';
import { StationManagerService } from '../../api/stations/station-manager.service';
import { StationsListChange } from '../../api/stations/models/stations-list-change.model';
import { tap } from 'rxjs/internal/operators/tap';
import { TrackingDataControl } from './tracking-data-control';
import { TranslateService } from '@ngx-translate/core';
import { TriPaneComponent } from '../../shared-ui/components/tri-pane/tri-pane.component';
import { UiSettingsService } from '../../api/ui-settings/ui-settings.service';
import { VoltageDiagnosticManagerService } from '../../api/voltage-diagnostic/voltage-diagnostic-manager.service';

import LatLngBounds = google.maps.LatLngBounds;
import Marker = google.maps.Marker;
import MessageBoxIcon = RbEnums.Common.MessageBoxIcon;
import Polygon = google.maps.Polygon;
import Timer = NodeJS.Timer;

/**
 * Usage notes:
 * Stations:
 * 	- Station markers (station.marker) must be created for any station within the bounds of the map, allowing
 * 		marker visibility changes with a simple setMap/setLabel call set.
 * 	- A station's marker can have several appearances: suspended, running, soaking, idle, low-voltage, high-voltage,
 * 		no-feedback, not-connected. There are various visibility scenarios for these states so we have to carefully
 * 		follow the rules for marker creation and destruction.
 *  - Station voltages can be null (no diagnostic result yet received), or zero. Don't treat the two as identical! RB-9921
 */
export class MapInfo {

	private static loadStationObservables = {}; // dictionary of observables by site ID
	private static getAreasObservables = {}; // dictionary of observables by site ID
	private static getGeoGroupsObservables = {}; // dictionary of observables by site ID
	private static getSiteObservables = {}; // dictionary of observables by site ID
	private static loadStationRunningStatusObservables = {}; // dictionary of observables by site ID

	// Location accuracy circle.
	private static locationAccuracyCircleStrokeColor = '#008751';
	private static locationAccuracyCircleStrokeOpacity = 0.6;
	private static locationAccuracyCircleStrokeWeight = 3;
	private static locationAccuracyCircleFillColor = '#008751';
	private static locationAccuracyCircleFillOpacity = 0.3;

	// Location accuracy circle size below which we hide the circle and say "we have a fix".
	private static locationAccuracyToHideCircle = 5.0;

	// Subjects
	busy = new Subject<boolean>();
	contextMenu: MapInfoContextMenu;
	contextMenuInvoked = new Subject<any>(); // Object { menuOptions: any[], menuPosition: google.maps.Point, image: string, title: string }
	editArea = new Subject<number>(); // Area Id
	editController = new Subject<number>(); // Controller Id
	editHole = new Subject<number>(); // Hole Id
	editStation = new Subject<number>(); // Station Id
	holes: HoleWithMapInfo[] = [];
	loadError = new Subject<string>();
	mapClicked = new Subject();
	mapZoomChanged = new Subject<MapInfo>();
	siteAddressLookupFailed = new Subject();
	siteDataLoaded = new Subject<any>(); // Object { site: Site, stations: Station[], holes: Area[], areas: Area[], geoGroups: GeoGroup[] }
	stationRemoved = new Subject();

	areItemsMovable = false;

	private addedCenterOnLocation = false;
	private areas: AreaWithMapInfo[] = [];
	private companyPreferences: CompanyPreferences;
	private controllers: ControllerWithMapInfo[] = [];
	private courseMarker: google.maps.Marker;
	private currentStationForPopup: StationWithMapInfo;
	private draggedArea: AreaWithMapInfo;
	private draggedController: ControllerWithMapInfo;
	private draggedHole: HoleWithMapInfo;
	private draggedStation: StationWithMapInfo;
	private dragging = false;
	private dragPosition: { x: number; y: number };
	private geoGroups: GeoGroup[] = [];
	private googleMap: google.maps.Map;
	private pendingStationsStatusList: StationListItem[];
	private handleCompanyStatusChangeTimer: Timer;
	private isGolfSite: boolean;
	private mapBounds: LatLngBounds;
	private receivedMapMoved = false;
	private pref: MapInfoPreferences;
	private site: Site;

	/**
	 * stations contains an array of StationWithMapInfo which is a subclass of Station (not StationListItem, please note) containing
	 * other information needed to render a station on the map. Some of the items we have to populate are the last voltage value
	 * diagnosed for the station, its last feedback result from a fast-connect poll, etc.
	 */
	private stations: StationWithMapInfo[] = [];

	private updateMarkersTimer: Timer;
	private useMockData = false;
	private userLocationMarker: google.maps.Marker;
	private userLocationAccuracyCircle: google.maps.Circle;
	private zoomRadiusInMeters = 0;

	private controllerMarkerInfo = { width: 20, height: 15, mapIconHotspotX: 10, mapIconHotspotY: 7, dragIconHotspotX: 10, dragIconHotspotY: 7 };
	private holeMarkerInfo = { width: 20, height: 30, mapIconHotspotX: 5, mapIconHotspotY: 30, dragIconHotspotX: 5, dragIconHotspotY: 30 };
	private _controllerImage: any;
	private _holeImage: any;

	/**
	 * Index of the (div) wrapping the TrackingDataControl within the Google Maps control array, or null if we haven't created
	 * the control so far.
	 */
	private trackingDataDivIndex: number = null;

	private static MIN_ZOOM_LEVEL_FOR_STATION_TEXT = 19;

	constructor(private parentDiv: any,
				public siteId: number,
				public uniqueId: number,
				private mapService: MapService,
				specialZoom: boolean
	) {

		this.isGolfSite = this.siteManager.isGolfSite;
		this.useMockData = this.env.useMockData && this.isGolfSite;
		this.pref = new MapInfoPreferences(this, this.uiSettingsService);
		this.contextMenu = new MapInfoContextMenu(this, this.isGolfSite, this.translate);

		const mapDiv = this.renderer.createElement('div');
		this.renderer.setStyle(mapDiv, 'width', '100%');
		this.renderer.setStyle(mapDiv, 'height', '100%');
		this.googleMap = new google.maps.Map(mapDiv, {
			zoom: 19,
			// center: { lat: 45.847955, lng: -90 },
			mapTypeId: 'satellite',
			mapTypeControl: false,
			gestureHandling: 'greedy',
			minZoom: 14,	// RB-8899: Allow more coverage with map, esp for Golf.
			fullscreenControl: !specialZoom,
			// center_changed: function(event) {
			// 	if (event == null) return;
			// }
		});

		const self = this;
		this.googleMap.addListener('zoom_changed', function () {
			self.pref.save();
			self.receivedMapMoved = true;
			self.updateMapBounds();
			self.updateAllMarkers(true);
			self.mapZoomChanged.next(self);
		});

		this.googleMap.addListener('heading_changed', function () { self.pref.save(); });

		this.googleMap.addListener('tilt_changed', function () { self.pref.save(); });

		this.googleMap.addListener('center_changed', function () { self.pref.save(); });

		this.googleMap.addListener('bounds_changed', function () {

			self.receivedMapMoved = true;
			self.updateMapBounds();
			self.updateAllMarkers(false);
		});

		this.googleMap.addListener('click', function () { self.mapClicked.next(null); });

		// Load data only if the siteId has changed. Otherwise, trigger siteDataLoaded with existing data.
		// Slightly delayed so map component can setup data handlers.
		setTimeout(() => this.loadData());

		this.placeInParentDiv(this.parentDiv);
		this.updateMapBounds();

		if (specialZoom && (!mapService.deviceManager.isMobile || !mapService.deviceManager.isSafari)) {
			const fullScreenDiv = document.createElement('div');
			FullScreenControl.create(this, this.translate, fullScreenDiv);
			this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(fullScreenDiv);

			document.onfullscreenchange = function (event) { FullScreenControl.setIcon(TriPaneComponent.isFullScreen); };
			// @ts-ignore
			document.onwebkitfullscreenchange = function (event) { FullScreenControl.setIcon(TriPaneComponent.isFullScreen); };
		}
	}

	get map(): google.maps.Map {
		if (this.googleMap != null) return this.googleMap;
	}

	placeInParentDiv(parentDiv: HTMLElement) {
		this.parentDiv = parentDiv;
		const mapNode = this.googleMap.getDiv();
		const children = parentDiv.children;
		for (let i = 0; i < children.length; i++) {
			this.renderer.removeChild(parentDiv, children.item(i));
		}
		this.renderer.appendChild(parentDiv, mapNode);
	}

	get layerVisibility(): any { return this.pref.sitePreferences.visibility; }

	// =========================================================================================================================================================
	// Public methods to get updated from MapService events
	// =========================================================================================================================================================
	instanceReused(): void {
		// All the clients of this instance to get event handlers set up before we start triggering them
		setTimeout(() => {
			if (this.isGolfSite) {
				this.siteDataLoaded.next({ site: this.site, stations: this.stations, holes: this.holes, areas: this.areas, geoGroups: this.geoGroups });
			} else {
				this.siteDataLoaded.next({ site: this.site, stations: this.stations, controllers: this.controllers });
			}
		});
	}

	areaChanged(updatedArea: Area) {
		let area: any = this.areas.find(a => a.id === updatedArea.id);
		const isArea = area != null;
		if (area == null) area = this.holes.find(a => a.id === updatedArea.id);
		if (area == null) return;
		area.name = updatedArea.name;
		if (area.number !== updatedArea.number) {
			area.number = updatedArea.number;
			if (isArea) this.areas.sort((a, b) => a.number - b.number);
			else this.holes.sort((a, b) => a.number - b.number);
		}
		if (isArea) {
			area.uiSettings = updatedArea.uiSettings;
			area.polygons.forEach((polygon, index) => {
				polygon.setOptions({
					fillColor: area.uiSettings.fillColor,
					fillOpacity: area.uiSettings.fillOpacity / 100,
					strokeColor: area.uiSettings.lineColor,
					strokeOpacity: area.uiSettings.lineOpacity / 100,
					strokeWeight: area.uiSettings.lineWidth,
				});
			});
		}
		if (!isArea && area.marker != null) this.createMarkerForHole(area);
	}

	companyStatusChanged() {
		if (this.handleCompanyStatusChangeTimer != null) clearTimeout(this.handleCompanyStatusChangeTimer);

		// Prevent calling getEventLogs too many times in response to rapid company status changes
		this.handleCompanyStatusChangeTimer = setTimeout(() => {
			this.handleCompanyStatusChangeTimer = null;
			this.stations.forEach(station => {
				// RB-9921: This is a not-quite-right scenario. We really want to check whether the alert has
				// appeared/disappeared *OR* changed, but we can't do that. Unless we didn't have one and
				// still don't need one, we call createMarkerForStation to update it.
				const hasExistingAlert = station.alertPopup != null;
				const needsAlert = this.alertPopupHTMLForStation(station).length > 0;
				if (hasExistingAlert || needsAlert) {
					// Reprocess the station, updating the alert, or removing it if no longer needed.
					this.createMarkerForStation(station);
				}
			});
		}, 1000);
	}

	controllerCollectionChanged(change: CollectionChange) {
		if (change.collection == null || change.collection.length === 0 || !(change.collection[0] instanceof ControllerListItem)) return;
		change.collection.forEach(controller => {
			const existingController = this.controllers.find(c => c.id === controller.id);
			if (existingController == null) return;
			existingController.name = controller.name;
			existingController.syncState = controller.syncState;
			existingController.gettingLogs = controller.gettingLogs;
			existingController.queued = controller.queued;

			if (existingController.marker != null) this.createMarkerForController(existingController);
		});
	}

	cultureSettingsChanged() {
		this.areas.forEach(a => a.geoItemIds.forEach(id => this.updateAreaLabelMarker(a, id, false, false)));
	}

	diagnosticDataReceived(data: any) {
		// Update local StationWithMapData copies including voltage or feedback data of active diagnostics. This should
		// match the values we would normally get if we reloaded the station list from the API.
		if (data instanceof IcVoltagePollData) {
			const stationsUpdated = RbUtils.Stations.updateStationDiagnosticResult_Voltage(this.stations, [data as IcVoltagePollData]);
			stationsUpdated.forEach(s => this.createMarkerForStation(s as StationWithMapInfo));
		} else if (data instanceof IcShortAddressPollData) {
			const stationsUpdated = RbUtils.Stations.updateStationDiagnosticResult_ShortAddress(this.stations, [data as IcShortAddressPollData]);
			stationsUpdated.forEach(s => this.createMarkerForStation(s as StationWithMapInfo));
		}
	}

	eventLogsStateChanged(state: ControllerGetLogsState) {
		const controller = this.controllers.find(c => c.id === state.controllerId);
		if (controller == null || controller.gettingLogs === state.gettingLogs) return;
		controller.gettingLogs = state.gettingLogs;
		if (controller.marker != null) this.createMarkerForController(controller);
	}

	manualControlStateChanged(controllerId: number, state?: ManualControlState) {
		const controller = this.controllers.find(c => c.id === controllerId);
		if (controller == null) return;
		controller.isConnecting = state != null && !state.isConnecting.error && !state.isConnecting.isUpdating && !state.hasReceivedConnectDataPack;
		controller.isConnected = state != null && !state.isConnecting.error && !state.isConnecting.isUpdating && state.hasReceivedConnectDataPack;
		if (controller.marker != null) this.createMarkerForController(controller);
	}

	sitesUpdated(data: any) {
		if (data != null && data.address != null && this.pref != null) this.pref.sitePreferences.center = null;
		this.siteManager.getSite(this.siteId).pipe(take(1)).subscribe(site => {
			this.site = site;
			this.lookupSiteAddress(true);
		});
	}

	stationsListChanged(change: StationsListChange) {
		if (!change.isStatusUpdateOnly) return;

		this.updateRunningStatusForStations(change.stations, change.updatedStationId);
	}

	holeAdded(holeId: number, updatedGeoGroups: GeoGroup[]) {
		const hole = this.holes.find(h => h.id === holeId);
		if (hole == null) return;

		this.geoGroups = lodash.cloneDeep(updatedGeoGroups);
		this.createMarkerForHole(hole);
	}

	holeRemoved(holeId: number): void {

		const hole = this.holes.find(h => h.id === holeId);
		if (hole == null) return;

		const geoGroup = this.geoGroups.find(r => r.areaLevel2Id === holeId && r.areaLevel3Id == null);
		if (geoGroup == null) return;

		geoGroup.geoItem = [];

		// Clear Marker
		hole.marker.setMap(null);
		hole.marker = null;
	}

	areaAdded(holeId: number, areaId: number, geoItem: GeoItem, updatedGeoGroups: GeoGroup [], polygon: { latitude: number, longitude: number }[]) {

		const area = this.areas.find(a => a.id === areaId);
		if (area == null) return;

		area.geoItemIds.push(geoItem.id);
		area.squareAreas.push(0);
		area.editModes.push(false);

		this.geoGroups = lodash.cloneDeep(updatedGeoGroups);
		const geoGroup = updatedGeoGroups.find(r => r.geoItem.some(rl => rl.id === geoItem.id));
		const labelAndPolygon = this.createMarkerForArea(area, geoGroup, geoItem, polygon);
		area.polygons.push(labelAndPolygon.polygon);
		area.labels.push(labelAndPolygon.label);
	}

	areaGeoItemRemoved(holeId: number, areaId: number, geoItemId: number): void {
		const geoGroup = this.geoGroups.find(gg => gg.geoItem.some(gi => gi.id === geoItemId));
		if (geoGroup != null) {
			geoGroup.geoItem = geoGroup.geoItem.filter(rl => rl.id !== geoItemId);
		}

		const area = this.areas.find(a => a.id === areaId);
		if (area == null) return;

		// Clear Markers
		const itemIndex = area.geoItemIds.findIndex(id => id === geoItemId);
		area.polygons[itemIndex].setMap(null);
		area.labels[itemIndex].setMap(null);
		area.polygons.splice(itemIndex, 1);
		area.labels.splice(itemIndex, 1);
		area.geoItemIds.splice(itemIndex, 1);
		area.squareAreas.splice(itemIndex, 1);
	}

	geoItemUpdated(geoItem: GeoItem): void {
		// Search all geo groups for matching GeoItems (there should only be one)
		this.geoGroups.forEach(gg => gg.geoItem.filter(gi => gi.id === geoItem.id).forEach(gi => Object.assign(gi, geoItem)));
	}

	userLocationUpdated(position: any) {
		const pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
		if (this.userLocationMarker == null) {
			this.userLocationMarker = new google.maps.Marker({
				position: pos,
				map: this.googleMap,
				icon: {
					url: 'https://labs.google.com/ridefinder/images/mm_20_green.png',
				},
			});

			// Create the standard location accuracy circle.
			this.userLocationAccuracyCircle = new google.maps.Circle({
				center: pos,
				map: this.googleMap,
				radius: position.coords.accuracy,

				// (R, G, B) = (0, 135, 81) Standard Rain Bird green. Stroke is the outline. Fill is the body of the
				// circle.
				strokeColor: MapInfo.locationAccuracyCircleStrokeColor,
				strokeWeight: MapInfo.locationAccuracyCircleStrokeWeight,
				strokeOpacity: MapInfo.locationAccuracyCircleStrokeOpacity,

				fillColor: MapInfo.locationAccuracyCircleFillColor,
				fillOpacity: MapInfo.locationAccuracyCircleFillOpacity,
			});
		} else {
			this.userLocationMarker.setPosition(pos);
			this.userLocationAccuracyCircle.setCenter(pos);
			this.userLocationAccuracyCircle.setRadius(position.coords.accuracy);
		}

		// If the accuracy circle is really small, hide it so it's not creating a halo around our current location
		// marker; otherwise show it.
		this.userLocationAccuracyCircle.setVisible(
			position.coords.accuracy == null || position.coords.accuracy > MapInfo.locationAccuracyToHideCircle);

		// Add the tool usable for centering the map on the user's current location.
		if (!this.addedCenterOnLocation) {
			this.addedCenterOnLocation = true;
			const centerOnLocationDiv = document.createElement('div');
			CenterOnLocationControl.create(this, this.translate, centerOnLocationDiv);
			this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerOnLocationDiv);
		}

		// If we need to show the tracking data for debugging, add that control to the map.
		this.checkTrackingDataDisplay();

		// See if we have a pending zoom operation
		if (this.zoomRadiusInMeters > 0) {
			const mapNode = this.googleMap.getDiv();
			const numPixels = Math.min(mapNode.getBoundingClientRect().width, mapNode.getBoundingClientRect().height);
			if (numPixels === 0) return; // Try again later after the map has some size

			// Formula based on this discussion thread: https://groups.google.com/forum/#!msg/google-maps-js-api-v3/hDRO4oHVSeM/osOYQYXg2oUJ
			// meters_per_pixel = 156543.03392 * Math.cos(position.coords.latitude * Math.PI / 180) / Math.pow(2, zoom)
			const zoom = Math.log2(156543.03392 * Math.cos(position.coords.latitude * Math.PI / 180) * numPixels / this.zoomRadiusInMeters);
			this.googleMap.setZoom(zoom);
			this.zoomRadiusInMeters = 0;
		}
	}

	areaGeoItemUpdated(areaId: number, geoItemId: number, newPoints: google.maps.LatLng[]) {
		const area = this.areas.find(a => a.id === areaId);
		if (area == null) return;
		const geoItemIndex = area.geoItemIds.findIndex(id => id === geoItemId);
		if (geoItemIndex === -1) return;

		area.polygons[geoItemIndex].setPath(newPoints);

		const self = this;
		google.maps.event.addListener(area.polygons[geoItemIndex].getPath(), 'set_at', function () {
			if (self.dragging) return;
			self.mapService.updateAreaGeoItem(area.id, geoItemId, this.getArray());
		});
		google.maps.event.addListener(area.polygons[geoItemIndex].getPath(), 'insert_at', function () {
			self.mapService.updateAreaGeoItem(area.id, geoItemId, this.getArray());
		});

		this.updateAreaLabelMarker(area, geoItemId, false, false);
	}

	controllerLocationUpdated(id: number, loc: { latitude: any; longitude: any }) {
		const controller = this.controllers.find(c => c.id === id);
		if (controller == null) return;

		controller.latitude = loc.latitude;
		controller.longitude = loc.longitude;
	}

	// =========================================================================================================================================================
	// Public methods to get/update properties (generally used by MapComponent)
	// =========================================================================================================================================================
	changeMoveAbility(moveable: boolean) {
		this.pref.sitePreferences.visibility.moveable = moveable;
		this.setItemMoveAbility();
		this.pref.save();
	}

	getDefaultDuration(selectedItem: number, contextMenuInfo: any): Duration {
		if (selectedItem !== RbEnums.Map.StationContextMenu.Start && selectedItem !== RbEnums.Map.AreaContextMenu.Start &&
			selectedItem !== RbEnums.Map.HoleContextMenu.Start) return null;

		const stationIds = this.stationIdsForMenuSelection(selectedItem, contextMenuInfo);
		if (stationIds.length === 0) return null;
		const station = this.stations.find(s => s.id === stationIds[0]);
		return RbUtils.Conversion.convertTicksToDuration(station.defaultRunTimeLong);
	}

	startPrograms(programIds: number[]) {
		this.manualOpsManager.startPrograms(programIds).subscribe();
	}

	textColorChanged(color: string) {
		this.pref.sitePreferences.visibility.textColor = color;
		this.pref.save();
		this.updateAllMarkers(true);
	}

	holeHasLocation(hole: Area): boolean {
		const geoGroup = this.geoGroups.find(r => r.siteId === hole.siteId && r.areaLevel2Id === hole.id && r.areaLevel3Id == null);
		if (geoGroup == null) return false;
		return geoGroup.geoItem.length > 0;
	}

	showHoles(visible: boolean) {
		this.pref.sitePreferences.visibility.showingHoles = visible;
		this.holes.filter(s => s.marker != null).forEach(s => s.marker.setMap(visible ? this.googleMap : null));
		this.pref.save();
	}

	showAreas(visible: boolean) {
		this.pref.sitePreferences.visibility.showingAreas = visible;
		this.areas.forEach(a => a.polygons.forEach(p => p.setVisible(visible)));
		this.areas.forEach(a => a.labels.forEach(l => l.setVisible(visible)));
		this.pref.save();
	}

	showControllers(visible: boolean) {
		this.pref.sitePreferences.visibility.showingControllers = visible;
		this.controllers.filter(c => c.marker != null).forEach(s => s.marker.setMap(visible ? this.googleMap : null));
		this.pref.save();
	}

	showStations(visible: boolean) {
		this.pref.sitePreferences.visibility.showingStations = visible;
		// RB-9921: Take care with assuming all stations disappear when visible is false! Not so if the have alerts.
		this.stations.filter(s => s.marker != null).forEach(s => this.createMarkerForStation(s));
		this.pref.save();
	}

	showIrrigation(visible: boolean) {
		this.pref.sitePreferences.visibility.showingIrrigation = visible;
		this.stations.filter(s => s.marker != null).forEach(s => this.createMarkerForStation(s));
		this.pref.save();
	}

	showAlerts(visible: boolean) {
		this.pref.sitePreferences.visibility.showingAlerts = visible;
		this.stations.filter(s => s.marker != null).forEach(s => this.createMarkerForStation(s));
		this.pref.save();
	}

	// =========================================================================================================================================================
	// Data loading and updating
	// =========================================================================================================================================================
	private loadData() {
		this.clearMarkers();
		if (this.siteId == null) return;
		this.busy.next(true);
		this.pref.load();

		if (this.isGolfSite) {
			const golfSources: Observable<any>[] = [
				this.getSite(),
				this.getAreasForSite(),
				this.getGeoGroupsForSite(),
				this.companyManager.getCompanyPreferences().pipe(take(1)),
				// this.getVoltageDiagnosticLogs(),
			];

			forkJoin(golfSources).subscribe(([site, areas, geoGroups, companyPreferences]) => {
				this.site = site;
				// this.courseAreas = areas;
				this.areas = <any>lodash.cloneDeep(areas.filter(h => h.level === 3).sort((a, b) => a.number - b.number));
				this.areas.forEach(a => {
					a.polygons = [];
					a.labels = [];
					a.geoItemIds = [];
					a.squareAreas = [];
					a.editModes = [];
				});
				this.holes = <any>lodash.cloneDeep(areas.filter(h => h.level === 2).sort((a, b) => a.number - b.number));
				this.geoGroups = lodash.cloneDeep(geoGroups);
				// this.voltageLogs = voltageLogs;
				this.companyPreferences = companyPreferences;
				this.loadStations().subscribe(stations => {
					this.stations = <any>lodash.cloneDeep(stations);
					this.initializeStationVoltageDiagnostic(/* forceUpdate */ true);
					this.initializeStationStatus(true);
					this.initializeHoleMarkers();
					this.initializeAreaMarkers();
					this.loadLatestStationRunningStatus().subscribe(list => {
						if (this.mapBounds == null) {
							this.pendingStationsStatusList = list;
						} else {
							this.updateRunningStatusForStations(list);
						}
					});
					this.siteDataLoaded.next({ site: this.site, stations: this.stations, holes: this.holes, areas: this.areas, geoGroups: this.geoGroups });
					this.lookupSiteAddress(false);
				});
			}, error => {
				this.busy.next(false);
				this.loadError.next(error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY'));
			});

			return;
		}

		// Commercial
		const sources: Observable<any>[] = [
			this.getSite(),
			this.loadStations(),
			this.controllerManager.getSiteControllersList(this.siteId).pipe(take(1)),
			this.companyManager.getCompanyPreferences().pipe(take(1)),
		];

		forkJoin(sources).subscribe(([site, stations, controllers, companyPreferences]) => {
			this.site = site;
			this.stations = <any>lodash.cloneDeep(stations.sort((a, b) => a.name.localeCompare(b.name))); // Cast to StationWithMapInfo[]
			this.controllers = controllers;
			this.companyPreferences = companyPreferences;

			this.initializeControllerMarkers();
			this.initializeStationStatus(true);
			this.loadLatestStationRunningStatus().subscribe(list => {
				if (this.mapBounds == null) {
					this.pendingStationsStatusList = list;
				} else {
					this.updateRunningStatusForStations(list);
				}
			});
			this.siteDataLoaded.next({ site: this.site, stations: stations, controllers: this.controllers });
			this.lookupSiteAddress(false);

		}, error => {
			this.busy.next(false);
			this.loadError.next(error.error || error.message || this.translate.instant('STRINGS.NETWORK_ERROR_RETRY'));
		});
	}

	private loadLatestStationRunningStatus(): Observable<StationListItem[]> {
		if (this.siteId == null) return;

		const observable = MapInfo.loadStationRunningStatusObservables[this.siteId];
		if (observable != null) return observable;

		MapInfo.loadStationRunningStatusObservables[this.siteId] = this.stationManager.getStationsListBySiteHolesAndHoleSections(this.siteId)
			.pipe(take(1), share());
		return MapInfo.loadStationRunningStatusObservables[this.siteId];
	}

	private loadStations(): Observable<Station[]> {
		if (this.holes == null) return of([]);

		const observable = MapInfo.loadStationObservables[this.siteId];
		if (observable != null) return observable;

		MapInfo.loadStationObservables[this.siteId] =
			this.stationManager.getStationsByAreasAndSites(this.isGolfSite ? this.holes.map(h => h.id) : null, [this.siteId])
				.pipe(share(), tap(() => delete MapInfo.loadStationObservables[this.siteId]));

		return MapInfo.loadStationObservables[this.siteId];
	}

	private getAreasForSite(): Observable<Area[]> {
		const observable = MapInfo.getAreasObservables[this.siteId];
		if (observable != null) return observable;

		MapInfo.getAreasObservables[this.siteId] = this.areaManager.getAreas(this.siteId, true)
			.pipe(share(), tap(() => delete MapInfo.getAreasObservables[this.siteId]));

		return MapInfo.getAreasObservables[this.siteId];
	}

	private getGeoGroupsForSite(): Observable<GeoGroup[]> {
		const observable = MapInfo.getGeoGroupsObservables[this.siteId];
		if (observable != null) return observable;

		MapInfo.getGeoGroupsObservables[this.siteId] = this.geoGroupManager.getGeoGroupsForSite(this.siteId)
			.pipe(share(), tap(() => delete MapInfo.getGeoGroupsObservables[this.siteId]));

		return MapInfo.getGeoGroupsObservables[this.siteId];
	}

	private getSite(): Observable<Site> {

		const observable = MapInfo.getSiteObservables[this.siteId];
		if (observable != null) return observable;

		MapInfo.getSiteObservables[this.siteId] = this.siteManager.getSite(this.siteId)
			.pipe(share(), tap(() => delete MapInfo.getSiteObservables[this.siteId]));

		return MapInfo.getSiteObservables[this.siteId];
	}

	private setItemMoveAbility() {
		const moveable = this.areItemsMovable && this.pref.sitePreferences.visibility.moveable;
		this.stations.forEach(station => { if (station.marker != null) station.marker.setDraggable(moveable); });
		this.holes.forEach(hole => { if (hole.marker != null) hole.marker.setDraggable(moveable); });
		this.controllers.forEach(controller => { if (controller.marker != null) controller.marker.setDraggable(moveable); });
		this.areas.forEach(area => {
			area.polygons.forEach(p => {
				p.setDraggable(moveable);
				p.setEditable(false);
			});
			for (let i = 0; i < area.editModes.length; i++) area.editModes[i] = false;
		});
	}

	private areasForStation(stations: Station[]): Area[] {
		const areasForStation: Area[] = [];
		for (const station of stations) {
			for (const stationArea of station.stationArea) {
				const nonHoleArea = this.areas.find(ca => ca.id === stationArea.areaId);
				if (nonHoleArea != null && areasForStation.every(afs => afs.id !== nonHoleArea.id)) {
					areasForStation.push(nonHoleArea);
				}
			}
		}
		return areasForStation.sort((a, b) => a.number - b.number);
	}

	private updateMapBounds(): void {
		this.mapBounds = this.googleMap.getBounds();
		if (this.mapBounds != null && this.pendingStationsStatusList != null) {
			this.updateRunningStatusForStations(this.pendingStationsStatusList);
			this.pendingStationsStatusList = null;
		}
	}

	/**
	 * If necessary, create a new TrackingDataControl on top of the map showing the current mapService.trackingData string.
	 * If the control has already been created and should be removed, remove it. If the control exists, update it.
	 */
	private checkTrackingDataDisplay(): void {
		const trackingInformationControlLocation = google.maps.ControlPosition.TOP_LEFT;

		if (this.trackingDataDivIndex == null && this.mapService.showTrackingData) {
			const trackingDataDiv = document.createElement('div');
			TrackingDataControl.create(this, this.mapService.trackingData, trackingDataDiv);
			// Push returns the new length. The last index is len - 1. Save it for removal operation.
			this.trackingDataDivIndex = this.googleMap.controls[trackingInformationControlLocation].push(trackingDataDiv) - 1;
		} else if (this.trackingDataDivIndex != null && !this.mapService.showTrackingData) {
			// Remove the control if present.
			this.googleMap.controls[trackingInformationControlLocation].removeAt(this.trackingDataDivIndex);
			this.trackingDataDivIndex = null;
		} else if (this.trackingDataDivIndex != null && this.mapService.showTrackingData) {
			// Update the tracking data.
			TrackingDataControl.setText(this.mapService.trackingData);
		}
	}

	// =========================================================================================================================================================
	// Context-menu handling
	// =========================================================================================================================================================
	private handleMarkerClicked(event: any) {

		this.mapClicked.next(null);

		const point = this.latLngToPixel(event.latLng);
		this.currentStationForPopup = null;

		this.currentStationForPopup = this.stations.find(s => s.latitude === event.latLng.lat() && s.longitude === event.latLng.lng());
		if (this.currentStationForPopup != null) {
			this.contextMenuInvoked.next(this.contextMenu.stationOptions(this.currentStationForPopup, point.x, point.y, this.areItemsMovable));
		}
	}

	contextMenuItemSelected(selectedItem: number, contextMenuInfo: any, duration: Duration = null) {

		const stationIds = this.stationIdsForMenuSelection(selectedItem, contextMenuInfo);

		switch (selectedItem) {
			case RbEnums.Map.StationContextMenu.Pause:
			case RbEnums.Map.AreaContextMenu.Pause:
			case RbEnums.Map.HoleContextMenu.Pause:
				if (stationIds.length === 0) return; // Should never happen...
				if (this.useMockData) {
					this.manualOpsManager.pauseGolfStationsMock(stationIds);
				} else {
					this.manualOpsManager.pauseStations(stationIds).subscribe();
				}				
				break;
			case RbEnums.Map.StationContextMenu.Resume:
			case RbEnums.Map.AreaContextMenu.Resume:
			case RbEnums.Map.HoleContextMenu.Resume:
				if (stationIds.length === 0) return; // Should never happen...
				if (this.useMockData) {
					this.manualOpsManager.resumeGolfStationsMock(stationIds);
				} else {
					this.manualOpsManager.resumeStations(stationIds).subscribe();
					break;
				}
				break;
			case RbEnums.Map.StationContextMenu.Start:
			case RbEnums.Map.AreaContextMenu.Start:
			case RbEnums.Map.HoleContextMenu.Start:
				if (stationIds.length === 0) return; // Should never happen...
				const seconds: number[] = [];
				if (stationIds) {
					stationIds.forEach(element => {
						seconds.push(duration.asSeconds());
					});
				}
				if (this.useMockData) {
					this.manualOpsManager.startGolfStationsMock(new StartStationModel(stationIds, seconds));
				} else {
					this.manualOpsManager.startStations(new StartStationModel(stationIds, seconds)).subscribe();
				}			
				break;
			case RbEnums.Map.StationContextMenu.Stop:
			case RbEnums.Map.AreaContextMenu.Stop:
			case RbEnums.Map.HoleContextMenu.Stop:
			case RbEnums.Map.ControllerContextMenu.StopAll:
				if (stationIds.length === 0) return; // Should never happen...
				if (this.useMockData) {
					this.manualOpsManager.stopGolfStationsMock(stationIds);
				} else {
					if (this.isGolfSite) {
						this.manualOpsManager.stopStations(stationIds).subscribe();
					} else {
						this.controllerManager.stopAllIrrigation([contextMenuInfo.controller.id]).subscribe();
					}
				}
				break;
			case RbEnums.Map.AreaContextMenu.Advance:
				if (stationIds.length === 0) return; // Should never happen...
				if (this.useMockData) {
					this.manualOpsManager.advanceGolfStationsMock(stationIds);
				} else {
					// RB-9541: You have to pass both the hole and area ids. Otherwise you'll be advancing all 18 greens
					// when you click Advance on the 9th green. Not at all what you want.
					const holeAndAreaIds = [contextMenuInfo.holeId, contextMenuInfo.area.id];
					this.manualOpsManager.advanceAreas(holeAndAreaIds).subscribe();
				}
				break;
			case RbEnums.Map.StationContextMenu.Advance:
				if (stationIds.length === 0) return; // Should never happen...
				if (this.useMockData) {
					this.manualOpsManager.advanceGolfStationsMock(stationIds);
				} else {
					const advanceStations = [new AdvanceStation(contextMenuInfo.station.programId, contextMenuInfo.station.id)];
					this.manualOpsManager.advanceStations(advanceStations, true).subscribe();
				}			
				break;
			case RbEnums.Map.HoleContextMenu.Edit:
				this.editHole.next(contextMenuInfo.hole.id);
				break;
			case RbEnums.Map.AreaContextMenu.Edit:
				this.editArea.next(contextMenuInfo.area.id);
				break;
			case RbEnums.Map.StationContextMenu.Edit:
				this.editStation.next(stationIds[0]);
				break;
			case RbEnums.Map.ControllerContextMenu.Edit:
				this.editController.next(contextMenuInfo.controller.id);
				break;
			case RbEnums.Map.AreaContextMenu.EditShape:
			case RbEnums.Map.AreaContextMenu.ViewShape:
				this.toggleAreaGeoItemEditMode(contextMenuInfo);
				break;
			case RbEnums.Map.HoleContextMenu.Remove:
				this.removeHole(contextMenuInfo.hole.id);
				break;
			case RbEnums.Map.AreaContextMenu.Remove:
				this.removeAreaGeoItem(contextMenuInfo);
				break;
			case RbEnums.Map.StationContextMenu.Remove:
				this.removeStation(stationIds[0]);
				break;
			case RbEnums.Map.AreaContextMenu.CalculateArea:
				if (contextMenuInfo.geoItem != null) {
					this.updateAreaLabelMarker(contextMenuInfo.area, contextMenuInfo.geoItem.id, true, true);
				} else {
					const geoItems = this.getGeoItemsForArea(contextMenuInfo.holeId, contextMenuInfo.area.id);
					geoItems.forEach(geoItem => this.updateAreaLabelMarker(contextMenuInfo.area, geoItem.id, true, true));
				}
				break;
			case RbEnums.Map.ControllerContextMenu.Remove:
				this.removeController(contextMenuInfo.controller.id);
				break;
			case RbEnums.Map.HoleContextMenu.AddToMap:
				this.addHole(contextMenuInfo.hole, this.googleMap.getCenter().lat(), this.googleMap.getCenter().lng());
				break;
			case RbEnums.Map.HoleContextMenu.FindOnMap:
				this.googleMap.setCenter(contextMenuInfo.hole.marker.getPosition());
				break;
			case RbEnums.Map.AreaContextMenu.AddToMap:
				contextMenuInfo.area.holeId = contextMenuInfo.holeId;
				this.addArea(contextMenuInfo.area, this.googleMap.getCenter().lat(), this.googleMap.getCenter().lng());
				break;
			case RbEnums.Map.AreaContextMenu.FindOnMap:
				const geoItemsForHoleAndArea = this.getGeoItemsForArea(contextMenuInfo.holeId, contextMenuInfo.area.id);
				const polygons = contextMenuInfo.area.polygons.filter((polygon, index) => {
					return geoItemsForHoleAndArea.some(gi => gi.id === contextMenuInfo.area.geoItemIds[index]);
				});
				this.googleMap.setCenter(this.getCenterOfPolygons(polygons.map(p => p.getPath())));
				break;
			case RbEnums.Map.StationContextMenu.AddToMap:
				contextMenuInfo.station.latitude = this.googleMap.getCenter().lat();
				contextMenuInfo.station.longitude = this.googleMap.getCenter().lng();
				this.addStation(contextMenuInfo.station);
				break;
			case RbEnums.Map.StationContextMenu.FindOnMap:
				this.googleMap.setCenter(new google.maps.LatLng(contextMenuInfo.station.latitude, contextMenuInfo.station.longitude));
				break;
			case RbEnums.Map.ControllerContextMenu.AddToMap:
				this.addController(contextMenuInfo.controller, this.googleMap.getCenter().lat(), this.googleMap.getCenter().lng());
				break;
			case RbEnums.Map.ControllerContextMenu.FindOnMap:
				this.googleMap.setCenter(new google.maps.LatLng(contextMenuInfo.controller.latitude, contextMenuInfo.controller.longitude));
				break;
			case RbEnums.Map.ControllerContextMenu.Sync:
				this.syncControllerSelected(contextMenuInfo, selectedItem);
				break;
			case RbEnums.Map.ControllerContextMenu.ReverseSync:
				this.reverseSyncControllerSelected(contextMenuInfo, selectedItem);
				break;
			case RbEnums.Map.ControllerContextMenu.Logs:
				this.getControllerLogs(contextMenuInfo, selectedItem);
				break;
			case RbEnums.Map.ControllerContextMenu.Connect:
				this.connectControllerSelected(contextMenuInfo, selectedItem);
				break;
			case RbEnums.Map.ControllerContextMenu.Disconnect:
				this.disconnectControllerSelected(contextMenuInfo, selectedItem);
				break;
			case RbEnums.Map.StationContextMenu.ProgramsAndSchedules:
				if (stationIds == null || stationIds.length < 1) { return; }
				this.broadcastService.showStationSearch.next(stationIds[0]);
				break;
		}
	}

	private stationIdsForMenuSelection(selectedItem: number, contextMenuInfo: any) {
		let stationIds: number[];

		if (selectedItem <= RbEnums.Map.HoleContextMenu.FindOnMap) {
			stationIds = this.stationsForHole(contextMenuInfo.hole.id).map(s => s.id);
		} else if (selectedItem <= RbEnums.Map.AreaContextMenu.FindOnMap) {
			stationIds = this.stationsForHoleAndArea(contextMenuInfo.holeId, contextMenuInfo.area.id).map(s => s.id);
		} else if (selectedItem <= RbEnums.Map.ControllerContextMenu.FindOnMap) {
			stationIds = this.stationsForController(contextMenuInfo.controller.id).map(s => s.id);
		} else {
			stationIds = [contextMenuInfo.station.id];
		}

		return stationIds;
	}

	// =========================================================================================================================================================
	// Drag-and-drop support
	// =========================================================================================================================================================
	updateDragPosition(event: CdkDragMove) {
		this.dragPosition = event.pointerPosition;
		this.googleMap.setOptions({ draggableCursor: 'none' });
	}

	dragEnded() {
		this.googleMap.setOptions({ draggableCursor: '' });
	}

	drop(event: CdkDragDrop<any, any>, hotspot: { offsetX: number; offsetY: number }) {
		const mapBounds = this.googleMap.getDiv().getBoundingClientRect();

		// Adjust for image size so the bottom center is used as the drop point

		const dropPointOnMap = {
			x: this.dragPosition.x - mapBounds.left + (this.deviceManager.isTouchDevice ? 0 : hotspot.offsetX),
			y: this.dragPosition.y - mapBounds.top + (this.deviceManager.isTouchDevice ? 0 : hotspot.offsetY)
		};
		const latLng = this.pixelToLatlng(dropPointOnMap.x, dropPointOnMap.y);

		if (event.item.data instanceof Area && this.holes.some(h => h.id === event.item.data.id)) {
			// Hole
			const hole: HoleWithMapInfo = <HoleWithMapInfo>event.item.data;
			this.addHole(hole, latLng.lat(), latLng.lng());
			return;
		}

		const area = event.item.data.area;
		if (area != null) {
			area.holeId = event.item.data.holeId;
			this.addArea(area, latLng.lat(), latLng.lng());
			return;
		}

		const controller = event.item.data.controller;
		if (controller != null) {
			this.addController(controller, latLng.lat(), latLng.lng());
			return;
		}

		// Station
		const station: Station = event.item.data;
		station.latitude = latLng.lat();
		station.longitude = latLng.lng();

		// Save the latitude and longitude, updating the map
		this.addStation(station);
	}

	// =========================================================================================================================================================
	// Geo-location handling
	// =========================================================================================================================================================
	private updateRunningStatusForStations(updatedListItems: StationListItem[], updateStationId: number = null) {
		this.stations.forEach(station => {

			if (updateStationId != null && station.id !== updateStationId) return;

			const updatedItem = updatedListItems.find(s => s.id === station.id);
			if (updatedItem == null) return;
			station.name = updatedItem.name; // Should this be a full Object.assign?

			// Update the marker only if the running status has changed
			if (this.hasStationMapStatusChanged(station, updatedItem)) {
				this.updateStationStatus(station, updatedItem);
				this.createMarkerForStation(station);
			}
		});
	}

	private lookupSiteAddress(centerOnAddress: boolean, savePreferences = false) {
		if (this.site.address == null || this.site.address.length === 0) {
			this.busy.next(false);
			return;
		}

		const self = this;

		// Call MapService to perform geocoding of the address.
		self.mapService.LookupAddressLatLng(this.site.address, this.site.city, this.site.state, this.site.zip, this.site.countryCode,
			function (results, status) {
				if (status !== google.maps.GeocoderStatus.OK || results == null || results.length === 0) {
					self.siteAddressLookupFailed.next(null);
					return;
				}

				const latLong = results[0].geometry.location;
				self.site.latitude = latLong.lat();
				self.site.longitude = latLong.lng();
				self.pref.settingPreferences = true;
				if (self.pref.sitePreferences.center != null && !centerOnAddress) {
					self.googleMap.setCenter(new google.maps.LatLng(self.pref.sitePreferences.center.latitude, self.pref.sitePreferences.center.longitude));
				} else {
					self.googleMap.setCenter(latLong);
				}

				if (self.pref.sitePreferences.zoom != null) self.googleMap.setZoom(self.pref.sitePreferences.zoom);
				if (self.pref.sitePreferences.heading != null) self.googleMap.setHeading(self.pref.sitePreferences.heading);
				if (self.pref.sitePreferences.tilt != null) self.googleMap.setTilt(self.pref.sitePreferences.tilt);
				self.pref.settingPreferences = false;
				if (savePreferences) self.pref.save();

				if (self.courseMarker != null) {
					self.courseMarker.setMap(null);
				}
				self.courseMarker = new google.maps.Marker({
					position: latLong,
					map: self.googleMap,
					icon: {
						url: 'https://maps.google.com/mapfiles/kml/paddle/red-circle.png',
						labelOrigin: new google.maps.Point(57 + RbUtils.Common.getTextWidth(self.site.name, '15px Lato') / 2, 16)
					},
					label: {
						color: self.pref.sitePreferences.visibility.textColor,
						fontSize: '15px',
						fontFamily: 'Lato',
						text: self.site.name,
					},
				});
				self.busy.next(false);
			});
	}

	getGeoItemForHole(holeId: number): GeoItem {
		const geoGroup = this.geoGroups.find(r => r.areaLevel2Id === holeId && r.areaLevel3Id == null);
		if (geoGroup == null || geoGroup.geoItem.length === 0) return null;

		return geoGroup.geoItem[0];
	}

	private getAreaGeoGroupsForHole(hole: Area): GeoGroup[] {
		const geoGroups = this.geoGroups.filter(r => r.areaLevel2Id === hole.id && r.areaLevel3Id != null);
		return geoGroups;
	}

	toggleFullScreen(): void {
		// @ts-ignore
		this.broadcastService.fullScreenToggleRequested.next(null);
	}

	goToCurrentLocation() {
		if (this.userLocationMarker == null) return;
		this.googleMap.setCenter(this.userLocationMarker.getPosition());
	}

	goToCourseLocation() {
		this.lookupSiteAddress(true, true);
	}

	/**
	 * goToCurrentLocationSpecialActivated is called by the center-on-location-control when the user double-taps/clicks on the
	 * "find me" icon. We use this to set special tracking display to on/off.
	 */
	goToCurrentLocationSpecialActivated() {
		this.mapService.monitorUserLocationSpecialActivated();
		this.checkTrackingDataDisplay();
	}

	// =========================================================================================================================================================
	// Methods to deal with markers
	// =========================================================================================================================================================
	private updateAreaLabelMarker(area: AreaWithMapInfo, geoItemId: number, showIfNotVisible: boolean, toggleVisibility: boolean) {
		const index = area.geoItemIds.findIndex(g => g === geoItemId);
		if (index === -1) return;

		const polygon = area.polygons[index];
		const labelMarker = area.labels[index];
		if (labelMarker.getMap() == null && !showIfNotVisible) return;
		if (toggleVisibility && labelMarker.getMap() != null) {
			labelMarker.setMap(null);
			return;
		}
		const path = polygon.getPath();
		const areaInSquareMeters = google.maps.geometry.spherical.computeArea(path);
		labelMarker.setMap(areaInSquareMeters === 0 ? null : this.googleMap);
		labelMarker.setPosition(this.getCenterOfPolygons([path.getArray()]));
		area.squareAreas[index] = areaInSquareMeters;
		const useDecimal = this.authManager.userCulture.decimalSeparator === RbConstants.Form.DECIMAL_SEPARATOR;
		let text = areaInSquareMeters.toLocaleString(useDecimal ? 'en-us' : 'de-de', { maximumFractionDigits: 0 });
		let unitLabel = this.translate.instant('UNIT_TYPE.SQUARE_METERS');
		if (this.authManager.userCulture.areaFormat === RbEnums.Common.AreaFormat.SQ_FT) {
			const areaInSquareFeet = areaInSquareMeters * RbConstants.Form.UNITS_OF_MEASURE_AREA.squareMetersToSquareFeet;
			text = areaInSquareFeet.toLocaleString(useDecimal ? 'en-us' : 'de-de', { maximumFractionDigits: 0 });
			unitLabel = this.translate.instant('UNIT_TYPE.SQUARE_FEET');
		} else if (this.authManager.userCulture.areaFormat === RbEnums.Common.AreaFormat.SQ_YD) {
			const areaInSquareYards = areaInSquareMeters * RbConstants.Form.UNITS_OF_MEASURE_AREA.squareMetersToSquareYards;
			text = areaInSquareYards.toLocaleString(useDecimal ? 'en-us' : 'de-de', { maximumFractionDigits: 0 });
			unitLabel = this.translate.instant('UNIT_TYPE.SQUARE_YARDS');
		}

		labelMarker.setLabel({
			color: '#FFF',
			fontSize: '13px',
			fontFamily: 'Lato',
			text: `${text} ${unitLabel}`,
		});
	}

	private createMarkerForArea(area: AreaWithMapInfo, geoGroup: GeoGroup, geoItem: GeoItem, polygon: { latitude: number, longitude: number }[]):
		{ label: Marker, polygon: Polygon } {

		const latLngPolygon = polygon.map(ll => new google.maps.LatLng({ lat: ll.latitude, lng: ll.longitude }));
		const polygonMarker = new google.maps.Polygon({
			draggable: this.areItemsMovable && this.pref.sitePreferences.visibility.moveable,
			editable: false,
			map: this.googleMap,
			visible: this.pref.sitePreferences.visibility.showingAreas,
			paths: latLngPolygon,
			fillColor: area.uiSettings.fillColor,
			fillOpacity: area.uiSettings.fillOpacity / 100,
			strokeColor: area.uiSettings.lineColor,
			strokeOpacity: area.uiSettings.lineOpacity / 100,
			strokeWeight: area.uiSettings.lineWidth,
		});

		const hole = this.holes.find(h => h.id === geoGroup.areaLevel2Id);
		const labelMarker = new google.maps.Marker({
			// map: this.googleMap,
			map: null,
			visible: this.pref.sitePreferences.visibility.showingAreas,
			position: this.getCenterOfPolygons([latLngPolygon]),
			icon: { // No icon
				anchor: new google.maps.Point(0, 0),
				labelOrigin: new google.maps.Point(0, 0),
				scaledSize: new google.maps.Size(0, 0),
				url: this.env.rbcc_ui + '/assets/images/flag.png',
			},
		});

		const self = this;
		google.maps.event.addListener(polygonMarker, 'click', function (event) {
			const point = self.latLngToPixel(event.latLng);
			self.contextMenuInvoked.next(self.contextMenu.areaOptions(hole.id, area, geoItem, point.x, point.y, self.areItemsMovable, false, polygonMarker));
		});
		google.maps.event.addListener(polygonMarker.getPath(), 'set_at', function () {
			if (self.dragging) return;
			self.mapService.updateAreaGeoItem(area.id, geoItem.id, this.getArray());
		});
		google.maps.event.addListener(polygonMarker.getPath(), 'insert_at', function () {
			self.mapService.updateAreaGeoItem(area.id, geoItem.id, this.getArray());
		});
		google.maps.event.addListener(polygonMarker, 'dragstart', function (event) {
			self.dragging = true;
			self.draggedArea = self.areas.find(a => a.polygons.some(p => google.maps.geometry.poly.containsLocation(event.latLng, p) ||
				google.maps.geometry.poly.isLocationOnEdge(event.latLng, p)));
			if (self.draggedArea == null) return;
			self.draggedArea.holeId = hole.id;
		});
		google.maps.event.addListener(polygonMarker, 'dragend', function () {
			self.dragging = false;
			if (self.draggedArea == null) return;
			self.mapService.updateAreaGeoItem(self.draggedArea.id, geoItem.id, this.latLngs.getArray()[0].getArray());
			self.draggedArea = null;
		});

		return { label: labelMarker, polygon: polygonMarker };
	}

	private createMarkerForController(controller: ControllerWithMapInfo) {
		if (controller.marker != null && (controller.latitude == null || controller.longitude == null)) {
			controller.marker.setMap(null);
			controller.marker = null;
		}

		if (controller.latitude == null || controller.longitude == null) return;

		const icon = this.iconForController(controller);
		if (controller.marker != null) {
			controller.marker.setPosition(new google.maps.LatLng(controller.latitude, controller.longitude));
			controller.marker.setMap(this.pref.sitePreferences.visibility.showingControllers ? this.googleMap : null);
			controller.marker.setLabel({
				color: this.pref.sitePreferences.visibility.textColor,
				fontSize: '15px',
				fontFamily: 'Lato',
				text: `${controller.name}`
			});
			controller.marker.setIcon(icon);
			return;
		}

		controller.marker = new google.maps.Marker({
			position: new google.maps.LatLng(controller.latitude, controller.longitude),
			map: this.pref.sitePreferences.visibility.showingControllers ? this.googleMap : null,
			draggable: this.areItemsMovable && this.pref.sitePreferences.visibility.moveable,
			icon: icon,
			label: {
				color: this.pref.sitePreferences.visibility.textColor,
				fontSize: '15px',
				fontFamily: 'Lato',
				text: `${controller.name}`,
			},
			shape: {
				type: 'rect',
				coords: [0, 0, 20, 20],
			},
		});

		const self = this;
		google.maps.event.addListener(controller.marker, 'click', function (event) {
			const point = self.latLngToPixel(event.latLng);
			self.contextMenuInvoked.next(self.contextMenu.controllerOptions(controller, point.x, point.y, self.areItemsMovable));
		});
		google.maps.event.addListener(controller.marker, 'dragstart', function (event) {
			self.draggedController = controller;
		});
		google.maps.event.addListener(controller.marker, 'dragend', function (event) {
			self.dragging = false;
			if (self.draggedController == null) return;
			self.mapService.updateController(self.draggedController.id, { latitude: event.latLng.lat(), longitude: event.latLng.lng() });
			self.draggedController = null;
		});

		return controller.marker;
	}

	updateAllMarkers(forceUpdate: boolean) {
		if (this.updateMarkersTimer != null) clearTimeout(this.updateMarkersTimer);

		// Prevent updates many times in response to user panning
		this.updateMarkersTimer = setTimeout(() => {
			this.receivedMapMoved = false;
			this.updateMarkersTimer = null;
			this.initializeControllerMarkers();
			this.initializeStationStatus(forceUpdate);
			this.initializeAreaMarkers();
			this.initializeHoleMarkers();
			if (this.courseMarker != null) {
				this.courseMarker.setLabel({
					color: this.pref.sitePreferences.visibility.textColor,
					fontSize: '15px',
					fontFamily: 'Lato',
					text: `${this.site.name}`
				});
			}
		}, 500); // To prevent very fast "duplicate" calls like when zooming the map
	}

	private initializeHoleMarkers() {
		this.holes.forEach(hole => {
			const geoItem = this.getGeoItemForHole(hole.id);
			if (geoItem == null) return;
			this.createMarkerForHole(hole);
		});
	}

	private initializeAreaMarkers() {
		this.holes.forEach(hole => {
			const areaGeoGroups = this.getAreaGeoGroupsForHole(hole);
			const areasForHole = this.areasForHole(hole.id);

			areasForHole.forEach((area: AreaWithMapInfo) => {

				const areaGeoGroup = areaGeoGroups.find(r => r.areaLevel2Id === hole.id && r.areaLevel3Id === area.id);
				if (areaGeoGroup == null) return;

				// const polygon: google.maps.LatLng[] = [];
				areaGeoGroup.geoItem.forEach(rl => {
					if (rl.multiPoint == null || rl.multiPoint.map == null || area.geoItemIds.some(id => id === rl.id)) return;
					area.geoItemIds.push(rl.id);
					const labelAndPolygon = this.createMarkerForArea(area, areaGeoGroup, rl, rl.multiPoint.map(rp => ({
						latitude: rp.latitude,
						longitude: rp.longitude
					})));
					area.polygons.push(labelAndPolygon.polygon);
					area.labels.push(labelAndPolygon.label);
					area.squareAreas.push(0);
					area.editModes.push(false);
				});
			});
		});
	}

	private initializeControllerMarkers() {
		this.controllers.forEach(controller => {
			this.createMarkerForController(controller);
		});
	}

	private initializeStationStatus(forceUpdate: boolean) {
		this.stations.every(station => {
			if (this.receivedMapMoved) return false; // stop if we were interrupted by something that will call this function again.

			if (station.latitude == null || station.longitude == null) return true;
			const wasOnMap = station.marker != null;
			const isOnMap = this.mapBounds != null && station.latitude != null && station.longitude != null &&
				this.mapBounds.contains({ lat: station.latitude, lng: station.longitude });

			if (forceUpdate || wasOnMap !== isOnMap) {
				station.isStationRunning = false;
				this.createMarkerForStation(station);
			}
			return true;
		});
	}

	private initializeStationVoltageDiagnostic(forceUpdate: boolean) {
		// Retrieve the voltage polling results initializing the diagnostic states of the stations. For now, we do this only for golf.
		if (this.isGolfSite) {
			// All satellites all stations, just like the stations list we have. Get most-recent voltage results.
			this.voltageDiagnosticManager.getLastVoltageDiagnosticLogs()
				.subscribe(voltageDiagnostics => {
					// Update the station values with voltage results. Update markers.
					const stationsUpdated = RbUtils.Stations.updateStationDiagnosticResult_VoltageDiagnostic(this.stations, voltageDiagnostics);
					stationsUpdated.forEach(s => {
						this.createMarkerForStation(s as StationWithMapInfo);
					});
				});
		}
	}

	private createMarkerForHole(hole: HoleWithMapInfo): google.maps.Marker {
		// Remove the previous marker from the map
		const geoItem = this.getGeoItemForHole(hole.id);
		if (hole.marker != null && geoItem == null) {
			hole.marker.setMap(null);
			hole.marker = null;
		}

		if (geoItem == null) return;

		const textWidth = RbUtils.Common.getTextWidth(hole.name, '15px Lato');
		const icon = {
			...this.holeImage,
			labelOrigin: new google.maps.Point(this.holeMarkerInfo.width + 6 + textWidth / 2, this.holeMarkerInfo.height / 2)
		};
		if (hole.marker != null) {
			hole.marker.setPosition(new google.maps.LatLng(geoItem.point.latitude, geoItem.point.longitude));
			hole.marker.setMap(this.pref.sitePreferences.visibility.showingHoles ? this.googleMap : null);
			hole.marker.setLabel({ color: this.pref.sitePreferences.visibility.textColor, fontSize: '15px', fontFamily: 'Lato', text: `${hole.name}` });
			hole.marker.setIcon(icon);
			return;
		}

		hole.marker = new google.maps.Marker({
			position: new google.maps.LatLng(geoItem.point.latitude, geoItem.point.longitude),
			map: this.pref.sitePreferences.visibility.showingHoles ? this.googleMap : null,
			draggable: this.areItemsMovable && this.pref.sitePreferences.visibility.moveable,
			icon: icon,
			label: {
				color: this.pref.sitePreferences.visibility.textColor,
				fontSize: '15px',
				fontFamily: 'Lato',
				text: hole.name,
			},
			shape: {
				type: 'rect',
				coords: [0, 0, this.holeMarkerInfo.width + textWidth + 6, this.holeMarkerInfo.height],
			},
		});

		const self = this;
		google.maps.event.addListener(hole.marker, 'click', function (event) {
			const point = self.latLngToPixel(event.latLng);
			self.contextMenuInvoked.next(self.contextMenu.holeOptions(hole, point.x, point.y, self.areItemsMovable));
		});
		google.maps.event.addListener(hole.marker, 'dragstart', function (event) {
			self.draggedHole = hole;
		});
		google.maps.event.addListener(hole.marker, 'dragend', function (event) {
			if (self.draggedHole == null) return;
			self.mapService.holeDragged(geoItem, { longitude: event.latLng.lng(), latitude: event.latLng.lat() });
			self.draggedHole = null;
		});

		return hole.marker;
	}

	/**
	 * Utility to handle everything needed when the station should not be marked. Check whether marker exists
	 * and remove it, if so. Check whether alertPopup exists and detach it, if so.
	 */
	private detachStationMarkerFromMap(station: StationWithMapInfo): void {
		if (station.marker != null) {
			// This will hide the marker.
			station.marker.setMap(null);
			// This will allow it to be disposed.
			station.marker = null;
		}
		if (station.alertPopup != null) {
			// We can safely remove this if we're removing the marker. No marker = no where to show the popup.
			station.alertPopup.setMap(null);
			station.alertPopup = null;
		}
	}

	/**
	 * Create/update/remove marker for station. This method might be called when the map location or zoom moves,
	 * when the system status changes, when diagnostic results are received, when the station's individual status
	 * changes, etc. One critical post-condition is that if the station is in the map bounds, it will have a
	 * marker (station.marker != null) on exit. The same states apply to the station.alertPopup. If station is
	 * on the map, the popup is present; if not, it's removed.
	 * @param station - StationWithMapInfo describing the station.
	 * @returns void
	 */
	private createMarkerForStation(station: StationWithMapInfo): void {

		// See if the marker is going to be removed
		if (station.latitude == null || station.longitude == null) {
			this.detachStationMarkerFromMap(station);
			return;
		}

		// See if the station is outside the bounds of the map
		if (this.mapBounds == null || !this.mapBounds.contains({ lat: station.latitude, lng: station.longitude })) {
			this.detachStationMarkerFromMap(station);
			return;
		}

		// -----
		// From here, the post-condition station.marker != null must be maintained. So no matter what the visibility
		// status of the marker is, the station.marker should always be set when returning from any point below.
		// -----

		const isRunning = station.status != null && station.status !== '-';
		station.isStationRunning = isRunning;

		// Update the station alert based on the error status of the station. From here down, if station.alertPopup
		// is non-null, the station is in alert state; if null, the station is in a normal state.
		this.replaceAlertPopupForStation(station);

		// RB-9921: Decide if the station marker should be visible. This is a complex calculation now that we can show the station
		// when it's in alert status, irrigating, idle, etc. all at various zooms and with various icons. Visibility
		// is controlled by station.marker.map being null (invisible) or the map reference (visible).
		const stationIcon = MapInfo.iconForStation(station,
			station.alertPopup != null && this.pref.sitePreferences.visibility.showingAlerts,
			this.pref.sitePreferences.visibility.showingIrrigation);
		const stationHasAlertPopup = station.alertPopup != null;
		const showStationAllLevels = this.showStationAtAllZoomLevels(station);
		const stationMarkerVisible =
			(stationHasAlertPopup && this.pref.sitePreferences.visibility.showingAlerts) ||
			this.pref.sitePreferences.visibility.showingStations ||
			showStationAllLevels;
		const stationMarkerDraggable = this.areItemsMovable && this.pref.sitePreferences.visibility.moveable;

		// The station already has a marker, update all of its attributes using the setters.
		if (station.marker != null) {
			station.marker.setPosition(new google.maps.LatLng(station.latitude, station.longitude));
			station.marker.setIcon(stationIcon);
			station.marker.setMap(stationMarkerVisible ? this.googleMap : null);
			station.marker.setDraggable(stationMarkerDraggable);
			station.marker.setLabel(this.getStationLabel(station, this.pref.sitePreferences.zoom));

			// Ready to return. The station.marker is non-null. The marker may or may not be visible on the map, depending
			// on the various settings and the station state.
			return;
		}

		// No station marker for this station yet. We must create one. If the marker should be visible based on station
		// state and map settings, show it by setting the map reference to our map; if not visible, set map to null.
		station.marker = new google.maps.Marker({
			position: new google.maps.LatLng(station.latitude, station.longitude),
			map: stationMarkerVisible ? this.googleMap : null,
			draggable: stationMarkerDraggable,
			icon: stationIcon,
			label: this.getStationLabel(station, this.pref.sitePreferences.zoom),
			shape: {
				type: 'rect',
				coords: [0, 0, 20, 20],
			},
		});

		const self = this;
		google.maps.event.addListener(station.marker, 'click', function (event) {
			self.handleMarkerClicked(event);
		});

		google.maps.event.addListener(station.marker, 'mouseover', function (event) {
			if (station.alertPopup == null) return;
			station.hoverTimer = setTimeout(() => {
				station.alertPopup.show();
				station.hoverTimer = null;
			}, 500);
		});

		google.maps.event.addListener(station.marker, 'mouseout', function (event) {
			if (station.alertPopup != null) station.alertPopup.hide();
			if (station.hoverTimer == null) return;
			clearTimeout(station.hoverTimer);
			station.hoverTimer = null;
		});

		google.maps.event.addListener(station.marker, 'dragstart', function (event) {
			self.draggedStation = self.stations.find(s => s.latitude === event.latLng.lat() && s.longitude === event.latLng.lng());
		});

		google.maps.event.addListener(station.marker, 'dragend', function (event) {
			if (self.draggedStation == null) return;
			self.draggedStation.latitude = event.latLng.lat();
			self.draggedStation.longitude = event.latLng.lng();
			self.mapService.stationManager.updateStations([self.draggedStation.id], { latitude: event.latLng.lat(), longitude: event.latLng.lng() })
				.pipe(finalize(() => self.draggedStation = null))
				.subscribe(() => {
					self.mapService.broadcastService.stationLocationChanged.next(self.draggedStation);
				});
		});
	}

	private clearMarkers(): void {
		this.clearControllerMarkers();
		this.clearHoleMarkers();
		this.clearAreaMarkers();
		this.clearStationMarkers();
	}

	private clearHoleMarkers() {
		this.holes.forEach(hole => {
			if (hole.marker != null) {
				hole.marker.setMap(null);
				hole.marker = null;
			}
		});
	}

	private clearControllerMarkers() {
		this.controllers.forEach(controller => {
			if (controller.marker != null) {
				controller.marker.setMap(null);
				controller.marker = null;
			}
		});
	}

	private clearAreaMarkers() {
		this.areas.forEach(area => {
			area.polygons.forEach(p => p.setMap(null));
			area.labels.forEach(p => p.setMap(null));
			area.polygons = [];
			area.labels = [];
			area.editModes = [];
			area.geoItemIds = [];
		});
	}

	private clearStationMarkers() {
		this.stations.forEach(station => this.detachStationMarkerFromMap(station));
	}

	// =========================================================================================================================================================
	// Drawing support
	// =========================================================================================================================================================
	private getCenterOfPolygons(polygons: google.maps.LatLng[][]) {
		const bounds = new google.maps.LatLngBounds();
		polygons.forEach(p => p.forEach(ll => bounds.extend(ll)));
		return bounds.getCenter();
	}

	private static iconForStation(station: StationWithMapInfo, hasError: boolean, showingIrrigation: boolean): any {

		const icon: Object = {
			anchor: new google.maps.Point(10, 10),
			labelOrigin: new google.maps.Point(12 + RbConstants.MapIcons.stationMarkerInfo.width
				+ RbUtils.Common.getTextWidth(station.name, '15px Lato') / 2, 8),
			scaledSize: new google.maps.Size(20, 20),
		};
		// RB-8617: We want to display the error, but only if that's the most-important thing to indicate about the
		// station. Priorities are:
		// Running, Pause, Soaking, Pending
		// Error
		// Idle
		switch (station.irrigationStatus) {
			default:	// If we don't know the status, choose Idle
			case RbEnums.Common.IrrigationStatus.Idle:
				if (hasError) {
					if (station.suspended) {
						icon['url'] = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.STATION_SUSPENDED_SVG);
					} else if (station.feedback === RbEnums.Common.DiagnosticFeedbackResult.NO_FB && !station.suspended) {
						icon['url'] = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.STATION_NO_FEEDBACK_SVG);
					} else {
						icon['url'] = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.STATION_ERROR_SVG);
					}
				} else {
					icon['url'] = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.STATION_IDLE_SVG);
				}
				break;

			case RbEnums.Common.IrrigationStatus.Pending:
				icon['url'] = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.STATION_PENDING_SVG);
				break;

			case RbEnums.Common.IrrigationStatus.Running:
				icon['url'] = 'data:image/svg+xml;charset=UTF-8,' +
					encodeURIComponent(showingIrrigation ? RbConstants.MapIcons.STATION_RUNNING_SVG : RbConstants.MapIcons.STATION_IDLE_SVG);
				break;

			case RbEnums.Common.IrrigationStatus.Paused:
				icon['url'] = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.STATION_PAUSED_SVG);
				break;

			case RbEnums.Common.IrrigationStatus.Soaking:
				icon['url'] = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.STATION_SOAKING_SVG);
				break;
		}

		return icon;
	}

	private get holeImage(): any {
		if (this._holeImage != null) return this._holeImage;

		this._holeImage = {
			url: this.env.rbcc_ui + '/assets/images/flag_yellow.png',
			size: new google.maps.Size(this.holeMarkerInfo.width, this.holeMarkerInfo.height),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(this.holeMarkerInfo.mapIconHotspotX, this.holeMarkerInfo.mapIconHotspotY),
		};
		return this._holeImage;
	}

	private get controllerImage(): any {
		if (this._controllerImage != null) return this._controllerImage;

		this._controllerImage = {
			url: this.env.rbcc_ui + '/assets/images/controller_icon.jpg',
			size: new google.maps.Size(this.controllerMarkerInfo.width, this.controllerMarkerInfo.height),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(this.controllerMarkerInfo.mapIconHotspotX, this.controllerMarkerInfo.mapIconHotspotY),
		};
		return this._controllerImage;
	}

	private iconForController(controller: ControllerListItem): any {
		const textWidth = RbUtils.Common.getTextWidth(controller.name, '15px Lato');
		switch (controller.syncState) {
			case RbEnums.Common.ControllerSyncState.Syncing:
				return {
					anchor: new google.maps.Point(10, 10),
					labelOrigin: new google.maps.Point(12 + RbConstants.MapIcons.controllerSyncingMarkerInfo.width + textWidth / 2, 8),
					scaledSize: new google.maps.Size(20, 20),
					url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.CONTROLLER_SYNCING_SVG)
				};
			case RbEnums.Common.ControllerSyncState.ReverseSyncing:
				return {
					anchor: new google.maps.Point(10, 10),
					labelOrigin: new google.maps.Point(12 + RbConstants.MapIcons.controllerReverseSyncingMarkerInfo.width + textWidth / 2, 8),
					scaledSize: new google.maps.Size(20, 20),
					url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.CONTROLLER_REVERSE_SYNCING_SVG)
				};
		}
		if (controller.gettingLogs) {
			return {
				anchor: new google.maps.Point(10, 10),
				labelOrigin: new google.maps.Point(12 + RbConstants.MapIcons.controllerGettingLogsMarkerInfo.width + textWidth / 2, 8),
				scaledSize: new google.maps.Size(20, 20),
				url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(RbConstants.MapIcons.CONTROLLER_LOGS_SVG)
			};
		}

		return {
			...this.controllerImage,
			labelOrigin: new google.maps.Point(this.controllerMarkerInfo.width + 6 + textWidth / 2, this.controllerMarkerInfo.height / 2)
		};
	}

	/**
	 * Create the HTML describing the alert popup needed for the indicated station (with map info).
	 * @param station - StationWithMapInfo containing the station data used to calculate the HTML needed for the popup.
	 * @returns string containing the basic error HTML describing things, or null, if no error to describe.
	 */
	private alertPopupHTMLForStation(station: StationWithMapInfo): string {
		let errorHtml = '';
		if (station.addressInt === 0) {
			const controller = this.controllers.find(c => c.id === station.satelliteId);
			if (!RbUtils.Stations.isStationAddressValid(station) || controller == null) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant('SPECIAL_MSG.STATION_ADDRESS_INVALID')}</div>`;
			}
		}
		if (this.isGolfSite) { // No other alerts for commercial
			if (station.suspended) {
				errorHtml = `<div class="map-alert-popup-content-info">${this.translate.instant('STRINGS.SUSPENDED')}</div>`;
			} else if (!station.isConnected) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant('SPECIAL_MSG.NOT_CONNECTED')}</div>`;
			} else if (station.fastConnectStationNumber < 0 || station.channel < 0) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant('SPECIAL_MSG.STATION_NOT_PROGRAMMED')}</div>`;
			} else if (station.voltage != null &&	// RB-9921: Treat zero as a reasonable value!
				(this.companyPreferences.diagnosticVoltageLowRange != null) && (station.voltage < this.companyPreferences.diagnosticVoltageLowRange)) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant('SPECIAL_MSG.STATION_LOW_VOLTAGE')}</div>`;
			} else if (station.voltage != null &&
				(this.companyPreferences.diagnosticVoltageHighRange != null) && (station.voltage > this.companyPreferences.diagnosticVoltageHighRange)) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant('SPECIAL_MSG.STATION_HIGH_VOLTAGE')}</div>`;
			} else if (station.feedback === RbEnums.Common.DiagnosticFeedbackResult.NO_FB) {
				errorHtml += `<div class="map-alert-popup-content-info">${this.translate.instant('SPECIAL_MSG.STATION_NO_FEEDBACK')}</div>`;
			}
		}

		return errorHtml;
	}

	/**
	 * Remove any existing alertPopup for the indicated station, check whether the station should have an error popup
	 * and, if so, create a new alertPopup, attach it to the map and save it in the station.
	 * @param station - StationWithMapInfo to have its alertPopup reworked
	 * @returns - void
	 */
	private replaceAlertPopupForStation(station: StationWithMapInfo): void {

		if (station.alertPopup != null) {
			station.alertPopup.setMap(null);
			station.alertPopup = null;
		}
		if (station.latitude == null || station.longitude == null) return null;

		// Get HMTL describing error, or null if no error or no way to describe it.
		const errorHtml = this.alertPopupHTMLForStation(station);

		// If station has no error, we've already removed the old alertPopup from the station and map; just return. Otherwise
		// build a new AlertPopup and save that to the map and station.
		if (errorHtml.length > 0) {
			const content = document.createElement('div');
			content.classList.add('map-alert-popup-content');
			content.innerHTML = errorHtml;

			// const AlertPopupClass = createAlertPopupClass();
			const alertPopup = new AlertPopup(new google.maps.LatLng(station.latitude, station.longitude), content);
			station.alertPopup = alertPopup;
			station.alertPopup.setMap(this.googleMap);
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================
	private addHole(hole: HoleWithMapInfo, latitude: number, longitude: number) {
		this.mapService.addHole(hole, { longitude: longitude, latitude: latitude });
	}

	private addArea(area: AreaWithMapInfo, latitude: number, longitude: number) {
		this.mapService.addArea(this.siteId, area.id, area.holeId, { latitude, longitude });
	}

	private addController(controller: ControllerListItem, latitude: number, longitude: number) {
		this.controllerManager.updateControllers([controller.id], { latitude: latitude, longitude: longitude })
			.subscribe(() => {
				const mapController = this.controllers.find(c => c.id === controller.id);
				mapController.latitude = latitude;
				mapController.longitude = longitude;
				this.createMarkerForController(mapController);
			});
	}

	/**
	 * addStation should be called when adding the indicated Station or StationListItem *to the map*.
	 * That is, setting its latitude and longitude. It should not be called for other purposes. That
	 * allows us to minimize the performance impact of adding the station, as we don't have to worry
	 * about station status, name, run time, etc.
	 */
	private addStation(station: Station | StationListItem) {
		this.mapService.updateStationLatLong(station);
	}

	private syncControllerSelected(contextMenuInfo: any, selectedItem: number) {
		const controllerId = contextMenuInfo.controller.id;
		this.controllerManager.haveControllersBeenSynced([controllerId]).subscribe(hasBeenSynced => {
			if (hasBeenSynced) {
				this.syncController(controllerId);
				return;
			}

			const mbi = MessageBoxInfo.create(this.translate.instant('STRINGS.SYNCHRONIZE'),
				this.translate.instant('SPECIAL_MSG.SYNC_WILL_ERASE_SETTINGS'),
				RbEnums.Common.MessageBoxIcon.Question, this.syncController.bind(this, controllerId), null, RbEnums.Common.MessageBoxButtons.YesNo);
			this.broadcastService.showMessageBox.next(mbi);
		});
	}

	private syncController(controllerId: number) {
		this.controllerManager.synchronize([controllerId]).subscribe(() => {
		}, () => {
			this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED');
		});
	}

	private reverseSyncControllerSelected(contextMenuInfo: any, selectedItem: number) {
		const controllerId = contextMenuInfo.controller.id;
		this.controllerManager.reverseSynchronize([controllerId]).subscribe(() => {
		}, error => {
			this.messageBoxService.showMessageBox(`${this.translate.instant('SPECIAL_MSG.REQUESTED_OPERATION_FAILED')} ${error.error}`);
		});
	}

	private getControllerLogs(contextMenuInfo: any, selectedItem: number) {
		const controllerId = contextMenuInfo.controller.id;
		this.manualOpsManager.retrieveEventLogs([controllerId]).subscribe(() => {
		}, () => {
			this.messageBoxService.showMessageBox('SPECIAL_MSG.REQUESTED_OPERATION_FAILED');
		});
	}

	private connectControllerSelected(contextMenuInfo: any, selectedItem: number) {
		const controllerId = contextMenuInfo.controller.id;
		this.manualOpsManager.connect(controllerId).subscribe(() => {
		}, error => {
			this.messageBoxService.showMessageBox(new MessageBoxInfo('SPECIAL_MSG.UNABLE_TO_CONNECT', MessageBoxIcon.Error,
				'SPECIAL_MSG.CONTROLLER_CONNECTION_FAILED'));
		});
	}

	private disconnectControllerSelected(contextMenuInfo: any, selectedItem: number) {
		this.manualOpsManager.disconnect(contextMenuInfo.controller.id).subscribe(() => {
		}, error => {
			this.messageBoxService.showMessageBox(new MessageBoxInfo('SPECIAL_MSG.UNABLE_TO_DISCONNECT', MessageBoxIcon.Error,
				'SPECIAL_MSG.CONTROLLER_DISCONNECT_FAILED'));
		});
	}

	private removeHole(id: number) {

		const hole = this.holes.find(s => s.id === id);
		if (hole == null || hole.marker == null) return;

		const mbi = MessageBoxInfo.create(this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_STATION_FROM_MAP', { name: hole.name }),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				const geoItem = this.getGeoItemForHole(hole.id);
				this.mapService.removeHole(hole.id, geoItem.id);

			}, null, RbEnums.Common.MessageBoxButtons.YesNo);
		this.broadcastService.showMessageBox.next(mbi);
	}

	private removeAreaGeoItem(contextMenuInfo: any) {
		if (contextMenuInfo.geoItem == null) return;

		const mbi = MessageBoxInfo.create(this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_STATION_FROM_MAP', { name: contextMenuInfo.area.name }),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				this.mapService.removeAreaGeoItem(contextMenuInfo.holeId, contextMenuInfo.area.id, contextMenuInfo.geoItem.id);
			}, null, RbEnums.Common.MessageBoxButtons.YesNo);
		this.broadcastService.showMessageBox.next(mbi);
	}

	private toggleAreaGeoItemEditMode(contextMenuInfo: any) {
		if (contextMenuInfo.geoItem == null) return;

		const index = contextMenuInfo.area.geoItemIds.findIndex(g => g === contextMenuInfo.geoItem.id);
		if (index === -1) return;

		contextMenuInfo.area.editModes[index] = !contextMenuInfo.area.editModes[index];
		const polygon = contextMenuInfo.area.polygons[index];
		polygon.setEditable(contextMenuInfo.area.editModes[index]);
	}

	removeStation(stationId: number) {
		const station = this.stations.find(s => s.id === stationId);
		if (!station) return;

		const mbi = MessageBoxInfo.create(this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_STATION_FROM_MAP', { name: station.name }),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				// Clear Marker
				this.detachStationMarkerFromMap(station);
				station.latitude = null;
				station.longitude = null;

				// Update station in db.
				this.stationManager.updateStations([stationId], { latitude: null, longitude: null })
					.subscribe(() => this.stationRemoved.next(null));
			}, null, RbEnums.Common.MessageBoxButtons.YesNo);
		this.broadcastService.showMessageBox.next(mbi);
	}

	removeController(controllerId: number) {
		const controller = this.controllers.find(s => s.id === controllerId);
		if (!controller) return;

		const mbi = MessageBoxInfo.create(this.translate.instant('STRINGS.REMOVE'),
			this.translate.instant('STRINGS.REMOVE_STATION_FROM_MAP', { name: controller.name }),
			RbEnums.Common.MessageBoxIcon.None,
			() => {
				// Clear Marker
				controller.marker.setMap(null);
				controller.latitude = null;
				controller.longitude = null;

				// Update station in db.
				this.controllerManager.updateControllers([controllerId], { latitude: null, longitude: null }).subscribe();
			}, null, RbEnums.Common.MessageBoxButtons.YesNo);
		this.broadcastService.showMessageBox.next(mbi);
	}

	private getStationLabel(station: Station, zoomLevel: number) {
		return {
			color: zoomLevel < MapInfo.MIN_ZOOM_LEVEL_FOR_STATION_TEXT ? 'rgba(0, 0, 0, 0)' : this.pref.sitePreferences.visibility.textColor,
			fontSize: '12px', fontFamily: 'Lato', text: station.name
		};
	}

	areaHasLocation(hole: Area, area: Area): boolean {
		const geoGroup = this.geoGroups.find(r => r.siteId === hole.siteId && r.areaLevel2Id === hole.id && r.areaLevel3Id === area.id);
		if (geoGroup == null) return false;
		return geoGroup.geoItem.length > 0;
	}

	private hasStationMapStatusChanged(station: StationWithMapInfo, updateItem: StationListItem): boolean {

		// Always consider it a change if it changed its visibility
		const wasOnMap = this.mapBounds != null && station.latitude != null && station.longitude != null &&
			this.mapBounds.contains({ lat: station.latitude, lng: station.longitude });
		const isOnMap = this.mapBounds != null && updateItem.latitude != null && updateItem.longitude != null &&
			this.mapBounds.contains({ lat: updateItem.latitude, lng: updateItem.longitude });
		if (wasOnMap !== isOnMap) return true;
		if (!wasOnMap) return false; // wasn't on map and still isn't

		// Check for change in running status
		const isRunning = updateItem.status !== '-';
		if (station.isStationRunning !== isRunning || station.irrigationStatus !== updateItem.irrigationStatus) return true;

		// Check for error status changes
		if (station.addressInt !== updateItem.address) return true;
		if (this.isGolfSite) {
			if (station.isConnected !== updateItem.isConnected) return true;
			if (station.fastConnectStationNumber !== updateItem.fastConnectStationNumber) return true;
			if (station.channel !== updateItem.channel) return true;
			// Suspended or unsuspended station has been changed then go to update station marker
			if (station.suspended !== updateItem.suspended) return true;
		}

		return false;
	}

	private showStationAtAllZoomLevels(station: StationWithMapInfo): boolean {
		return this.pref.sitePreferences.visibility.showingIrrigation &&
			(station.isStationRunning || station.irrigationStatus === RbEnums.Common.IrrigationStatus.Running);
	}

	private updateStationStatus(station: StationWithMapInfo, updateItem: StationListItem): void {
		if (updateItem == null) return;

		station.status = updateItem.status;
		station.irrigationStatus = updateItem.irrigationStatus;

		station.suspended = updateItem.suspended;
		station.terminal = updateItem.terminal;
		station.fastConnectStationNumber = updateItem.fastConnectStationNumber;
		station.channel = updateItem.channel;
	}

	controllerHasLocation(controller: ControllerListItem): boolean {
		return controller.latitude != null && controller.longitude != null;
	}

	getGeoItemsForArea(holeId: number, areaId: number): GeoItem[] {
		const geoGroup = this.geoGroups.find(r => r.areaLevel2Id === holeId && r.areaLevel3Id === areaId);
		if (geoGroup == null) return [];

		return geoGroup.geoItem;
	}

	areasForHole(holeId: number): Area[] {
		const stationsForHole = this.stationsForHole(holeId);
		return this.areasForStation(stationsForHole);
	}

	private latLngToPixel(latLng: google.maps.LatLng): google.maps.Point {
		const projection = this.googleMap.getProjection();
		const point = projection.fromLatLngToPoint(latLng);
		const centerLatLng = this.googleMap.getBounds().getCenter();
		const center = projection.fromLatLngToPoint(centerLatLng);

		const isFullScreen = TriPaneComponent.isFullScreen;
		const mapBounds = this.googleMap.getDiv().getBoundingClientRect();
		const adjustedCenterX = isFullScreen ? window.innerWidth / 2 - mapBounds.left : mapBounds.width / 2;
		const adjustedCenterY = isFullScreen ? window.innerHeight / 2 - mapBounds.top : mapBounds.height / 2;

		// eslint-disable-next-line no-bitwise
		const scale = 1 << this.googleMap.getZoom();
		return new google.maps.Point(
			adjustedCenterX + Math.floor((point.x - center.x) * scale),
			adjustedCenterY + Math.floor((point.y - center.y) * scale)
		);
	}

	private pixelToLatlng(xcoor, ycoor): google.maps.LatLng {
		const mapBounds = this.googleMap.getDiv().getBoundingClientRect();
		const pixelCenterX = mapBounds.width / 2;
		const pixelCenterY = mapBounds.height / 2;
		const offsetFromCenterX = xcoor - pixelCenterX;
		const offsetFromCenterY = ycoor - pixelCenterY;

		const centerLatLng = this.googleMap.getBounds().getCenter();
		const projection = this.googleMap.getProjection();
		const center = projection.fromLatLngToPoint(centerLatLng);

		// eslint-disable-next-line no-bitwise
		const scale = 1 << this.googleMap.getZoom();
		return projection.fromPointToLatLng(new google.maps.Point(offsetFromCenterX / scale + center.x, offsetFromCenterY / scale + center.y));
	}

	stationsForHole(holeId: number): Station[] {
		return this.stations.filter(station => station.stationArea.some(sa => sa.areaId === holeId));
	}

	stationsForHoleAndArea(holeId: number, areaId: number): Station[] {
		const stationsForHole = this.stationsForHole(holeId);
		return stationsForHole.filter(station => station.stationArea.some(sa => sa.areaId === areaId))
			.sort((a, b) => {
				const stationAreaA = a.stationArea.find(sa => sa.areaId === areaId);
				const stationAreaB = b.stationArea.find(sa => sa.areaId === areaId);
				return stationAreaA.number - stationAreaB.number;
			});
	}

	stationHasLocation(station: Station): boolean {
		return station.latitude != null && station.longitude != null;
	}

	stationsForController(satelliteId: number): Station[] {
		const stations = this.stations.filter(s => s.satelliteId === satelliteId);
		return stations;
	}

	private get areaManager(): AreaManagerService { return this.mapService.areaManager; }

	private get authManager(): AuthManagerService { return this.mapService.authManager; }

	private get broadcastService(): BroadcastService { return this.mapService.broadcastService; }

	private get companyManager(): CompanyManagerService { return this.mapService.companyManager; }

	private get controllerManager(): ControllerManagerService { return this.mapService.controllerManager; }

	private get deviceManager(): DeviceManagerService { return this.mapService.deviceManager; }

	private get env(): EnvironmentService { return this.mapService.env; }

	private get geoGroupManager(): GeoGroupManagerService { return this.mapService.geoGroupManager; }

	private get manualOpsManager(): ManualOpsManagerService { return this.mapService.manualOpsManager; }

	private get messageBoxService(): MessageBoxService { return this.mapService.messageBoxService; }

	private get renderer(): Renderer2 { return MapService.renderer; }

	private get stationManager(): StationManagerService { return this.mapService.stationManager; }

	private get siteManager(): SiteManagerService { return this.mapService.siteManager; }

	private get translate(): TranslateService { return this.mapService.translate; }

	private get uiSettingsService(): UiSettingsService { return this.mapService.uiSettingsService; }

	private get voltageDiagnosticManager(): VoltageDiagnosticManagerService { return this.mapService.voltageDiagnosticManager; }
}

export class GwpSidebarWidgetSettingsModel {

	constructor (visibleBlocks: string[], visibleConditions: string[]) {
		this.visibleBlocks = visibleBlocks;
		this.visibleConditions = visibleConditions;
	}

	visibleBlocks: string[] = [];
	visibleConditions: string[] = [];

}

import { RbEnums } from '../../../common/enumerations/_rb.enums';

export class Company {

	address: string;
	city: string;
	contactName: string;
	country: string;
	email: string;
	enableCommunicationLogging: boolean;	// NOTE: May be null, true or false.
	gspNumber: number;
	id: number;
	name: string;
	nccupgradeLicenses: number;
	phoneNumber: string;
	sendMailAsEmail: string;
	sendMailAsName: string;
	sessionTimeoutMins: number;
	siteType: RbEnums.Common.SiteType;
	state: string;
	timeZone: string;
	zip: string;

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<mat-form-field class="select-holes-dropdown" 
	[class.white-background]="isWhiteBackground" 
	[class.flat]="flat" 
	[class.mobile]="isMobile" 
	[class.multiple-selection]="selectedHoles?.length > 0"
	[class.left-justify-label]="isLeftJustified"
	>
	<mat-select #matSelect
				[placeholder]="'STRINGS.HOLES' | translate"
				multiple="true"
				[panelClass]="'multiselect-dropdown select-holes-panel'"
				[compareWith]="equals"
				[(ngModel)]="selectedHoles"
				(closed)="onSelectClosed()"
				data-qaid="select-holes-dropdown">
		<mat-select-trigger class="mat-select-trigger">
			<span>{{selectedHoles.length > 0 ? selectedHoles[0].number : ''}}</span>
			<span *ngIf="selectedHoles.length > 1">(+{{selectedHoles.length - 1}} {{'STRINGS.MORE' | translate}})</span>
		</mat-select-trigger>

		<!-- Group selection checkboxes -->
		<div class="hole-groups-select-many-area">
			<div *ngFor="let holeGroup of holeGroups" class="hole-group-select-many">
				<mat-checkbox [checked]="areAllHolesSelected(holeGroup)" [indeterminate]="areSomeHolesSelected(holeGroup)"
							  (change)="changeHoleSelections(holeGroup, $event.checked)" color="primary"
							  attr.data-qaid="SHD-CB-holegroup-{{holeGroup.label | replaceWhitespace: '_' }}">
					{{holeGroup.label}}
				</mat-checkbox>
			</div>
		</div>
		<button class="label" mat-button color="primary" type="button" (click)="selectedHoles = []" data-qaid="SHD-clear-holes-button">{{'STRINGS.CLEAR_HOLES_UPPERCASE' | translate}}</button>

		<div class="options-list">
			<!-- List of holes by group -->
			<div *ngFor="let holeGroup of holeGroups" class="hole-group">
				<div class="hole-group-title">{{holeGroup.label}}</div>
				<div class="hole-list" [class.place]="!holeGroup.isGolfArea">
					<ng-container *ngFor="let hole of holeGroup.areas">
						<mat-option [value]="hole" 
							attr.data-qaid="SHD-CB-{{holeGroup.label | replaceWhitespace: '_' }}-{{(holeGroup.isGolfArea ? hole.number : hole.name) | replaceWhitespace: '_' }}">
							{{holeGroup.isGolfArea ? hole.number : hole.name}}
						</mat-option>
					</ng-container>
				</div>
			</div>

			<!-- List of Places -->
<!--			<div *ngIf="places.length > 0" class="hole-group">-->
<!--				<div class="places-title">{{'STRINGS.PLACES' | translate}}</div>-->
<!--				<div class="places-list">-->
<!--					<ng-container *ngFor="let place of places">-->
<!--						<mat-option [value]="place">{{place.name}}</mat-option>-->
<!--					</ng-container>-->
<!--				</div>-->
<!--			</div>-->
		</div>

		<div class="mat-option flex">
			<div class="pa3 mat-form-field-label">{{selectedHoles.length}} / {{holesAndAreas.length}}</div>
			<button mat-raised-button class="fr" color="primary" type="button" data-qaid="SHD-done-button" (click)="matSelect.close()">
				{{'STRINGS.DONE' | translate}}
			</button>
		</div>
	</mat-select>
</mat-form-field>

import { StartTimeAndStatus } from './start-time-and-status.model';

export class ScheduledProgram {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.startTimeAndStatus) { this.startTimeAndStatus = json.startTimeAndStatus.map(item => new StartTimeAndStatus(item)); }
		}
	}

	id: number;
	name: string;
	satelliteId: number;
	satelliteName: string = null;
	startTimeId: number;
	startTimeAndStatus: StartTimeAndStatus[];
}

export class FlattenedFormattedControllerDifferenceItem {

	constructor(name: string, property: string, iq4Value: any, controllerValue: any) {
		this.name = name;
		this.property = property;
		this.iq4Value = iq4Value;
		this.controllerValue = controllerValue;
	}

	name: string;
	property: string;
	iq4Value: any;
	controllerValue: any;

}

import { RbUtils } from '../../../common/utils/_rb.utils';

export class DryRun {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.startTimeOffset) { this.startTimeOffset = RbUtils.Common.timeOffsetToDisplayTime(json.startTime); }
			if (json.endTimeOffset) { this.endTimeOffset = RbUtils.Common.timeOffsetToDisplayTime(json.endTime); }
		}
	}

	id: number;
	companyId: number;
	satelliteId: number;
	isHistoricalDryRun: boolean;
	startTimeOffset: Date;
	endTimeOffset: Date;
	name: string;
	description: string;
	totalFlow: number;
}

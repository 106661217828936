<mat-form-field *ngIf="!resetting" class="input-with-select">
	<input matInput
		   [type]="inputType"
		   [placeholder]="placeholder"
		   [(ngModel)]="value"
		   [required]="isRequired"
		   [min]="minVal"
		   [max]="maxVal"
		   [step]="step"
		   (ngModelChange)="onValueChanged()"
		   [readonly]="readonly"/>
	<mat-select [(ngModel)]="value" (selectionChange)="onValueChanged()" [required]="isRequired" [disabled]="readonly">
		<mat-option *ngFor="let item of list" [value]="item.value">{{item.display}}</mat-option>
	</mat-select>
</mat-form-field>

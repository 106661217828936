<div #container class="notification-container" [ngClass]="styleClass" (focusout)="onFocusout()" tabindex="0">
	<table>
		<tr>
			<td class="notification-icon">
				<i
					class="material-icons"
					[ngClass]="notificationIcon"
				></i>
			</td>
			<td>
				<div class="snackbar-title">
					{{ header }}
				</div>
			</td>
			<td>
				<button class="close-button" (click)="dismiss()">
					<mat-icon class="expansion-icon">close</mat-icon>
				</button>
			</td>
		</tr>
		<tr>
			<td></td>
			<td>
				<div class="message" [innerHTML]="message"></div>
			</td>
			<td></td>
		</tr>
		<tr *ngIf="buttonLabel">
			<td></td>
			<td>
				<button
					class="action-btn"
					(click)="actionClick()"
				>
					{{ buttonLabel }}
				</button>
			</td>
			<td></td>
		</tr>
		<tr>
			<td></td>
			<td><ng-content select="[handlingActions]"></ng-content></td>
			<td></td>
		</tr>
	</table>
</div>

export class CalendarEvent {

	companyId: number;
	id: number;
	siteId: number;
	description: string;
	startTime: Date;
	endTime: Date;
	startDate: Date;
	endDate: Date;

		/// <summary>
	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}
}

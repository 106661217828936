import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Injector, NgModule } from '@angular/core';

import { BannerMessageComponent } from './components/banner-message/banner-message.component';
import { BreadcrumbTrailComponent } from './components/breadcrumb-trail/breadcrumb-trail.component';
import { CardComponent } from './components/card/card.component';
import { CCWeatherWidgetComponent } from './components/dashboard-ccweather-widget/ccweather-widget/ccweather-widget.component';
import { CCWeatherWidgetCurrentComponent } from './components/dashboard-ccweather-widget/ccweather-widget/ccweather-widget-current/ccweather-widget-current.component';
import { CCWeatherWidgetDailyComponent } from './components/dashboard-ccweather-widget/ccweather-widget/ccweather-widget-daily/ccweather-widget-daily.component';
import { CCWeatherWidgetHourlyComponent } from './components/dashboard-ccweather-widget/ccweather-widget/ccweather-widget-hourly/ccweather-widget-hourly.component';
import { CCWeatherWidgetSettingsComponent } from './components/dashboard-ccweather-widget/ccweather-widget/ccweather-widget-settings/ccweather-widget-settings.component';
import { ChatComponent } from './components/chat/chat.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { CommonLayersComponent } from './components/left-panel/tabs/common-layers/common-layers.component';
import { CustomStationAreaStyleComponent } from './components/custom-station-area-style/custom-station-area-style.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { DashboardCCWeatherWidgetComponent } from './components/dashboard-ccweather-widget/dashboard-ccweather-widget.component';
import { DashboardWidgetContainerComponent } from './components/dashboard-widget-container/dashboard-widget-container.component';
import { DashboardWidgetToolbarComponent } from '../dashboard/components/widgets/dashboard-widget-toolbar/dashboard-widget-toolbar.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DurationDialogComponent } from './components/duration-dialog/duration-dialog.component';
import { EntitlementsRenewalWarningComponent } from './components/entitlements-renewal-warning/entitlements-renewal-warning.component';
import { FreedomSystemDialogComponent } from './components/freedom-system-dialog/freedom-system-dialog.component';
import { FrontBackDropdownComponent } from './components/front-back-dropdown/front-back-dropdown.component';
import { GlobalMessageBoxComponent } from './components/global-message-box/global-message-box.component';
import { GlobalSnackBarComponent } from './components/global-snack-bar/global-snack-bar.component';
import { GolfLicenseWarningComponent } from './components/golf-license-warning/golf-license-warning.component';
import { GprsRenewWarningComponent } from './components/gprs-renew-warning/gprs-renew-warning.component';
import { GroupDecimalSeparatorComponent } from '../shared-ui/components/regional-settings/group-separator/group-decimal-separator.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ImportedLayersComponent } from './components/left-panel/tabs/imported-layers/imported-layers.component';
import { InterfaceFaultFindingWarningComponent } from './components/interface-fault-finding-warning/interface-fault-finding-warning.component';
import { LayerImportDialogComponent } from './components/layer-import-dialog/layer-import-dialog.component';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeftPanelComponent } from './components/left-panel/left-panel.component';
import { LeftPanelTabProgramListComponent } from './components/left-panel/tabs/program-list/program-list.component';
import { LogoComponent } from './components/logo/logo.component';
import { MainPanelToolbarComponent } from './components/main-panel-toolbar/main-panel-toolbar.component';
import { MapContextMenuComponent } from './components/map-context-menu/map-context-menu.component';
import { MapLeafletComponent } from './components/map-leaflet/map-leaflet.component';
import { MapToolbarComponent } from './components/map-toolbar/map-toolbar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MentionModule } from 'angular-mentions';
import { MultiSelectButtonsComponent } from './components/multi-select-buttons/multi-select-buttons.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NearbyStationSelectComponent } from './components/nearby-station-select/nearby-station-select.component';
import { NewSoftwareVersionAvailableComponent } from './components/new-software-version-available/new-software-version-available.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { NoteItemComponent } from './components/note-item/note-item.component';
import { NotePopupComponent } from './components/note-popup/note-popup.component';
import { NotesListComponent } from './components/left-panel/tabs/notes-list/notes-list.component';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { RbGroupDecimalSeparatorDirective } from '../shared-ui/directives/rb-group-decimal-separator.directive';
import { RemainingRunTimeDisplayPipe } from './pipes/context-menu-remaining-time-display.pipe';
import { ReplaceWhitespacePipe } from './pipes/replace-whitespace.pipe';
import { RouterModule } from '@angular/router';
import { ScrollTextDirective } from './directives/scroll-text.directive';
import { SelectHolesDropdownComponent } from './components/select-holes-dropdown/select-holes-dropdown.component';
import { SiteAddressModalComponent } from '../shared-ui/components/site-address-modal/site-address-modal.component';
import { StartControllerAutoConnectModalComponent } from '../shared-ui/components/start-controller-auto-connect-modal/start-controller-auto-connect-modal.component';
import { StartControllerModalComponent } from '../shared-ui/components/start-controller-modal/start-controller-modal.component';
import { StartStationModalComponent } from '../shared-ui/components/start-station-modal/start-station-modal.component';
import { StationRemainingRuntimePercentPipe } from './pipes/station-remaining-runtime-percent.pipe';
import { StationsListComponent } from './components/left-panel/tabs/stations-list/stations-list.component';
import { SystemStatusFlowComponent } from './components/system-status-toolbar/system-status-flow/system-status-flow.component';
import { SystemStatusToolbarComponent } from './components/system-status-toolbar/system-status-toolbar.component';
import { TimerCountdownComponent } from './components/timer-countdown/timer-countdown.component';
import { TipContainerComponent } from './components/tip-container/tip-container.component';
import { ToFixedNumberPipe } from './pipes/to-fixed-number.pipe';
import { ToolbarExportMenuComponent } from './components/toolbar-export-menu/toolbar-export-menu.component';
import { TooltipRendererDirective } from './components/custom-tooltip/tooltip-renderer.directive';
import { TopBannerPanelComponent } from './components/top-banner-panel/top-banner-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { TreeControllerListComponent } from './components/tree-controller-list/tree-controller-list.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { UnitsOfMeasureLengthComponent } from '../shared-ui/components/regional-settings/units-of-measure-length/units-of-measure-length.component';
import { WaitingIndicatorComponent } from './components/waiting-indicator/waiting-indicator.component';
import { WeatherWidgetComponent } from './components/weather-widget/weather-widget.component';
import { WeatherWidgetTileComponent } from './components/weather-widget/weather-widget-tile/weather-widget-tile.component';

export let AppInjector: Injector;

@NgModule({
	imports: [
		ColorPickerModule,
		CommonModule,
		DragDropModule,
		FormsModule,
		HighchartsChartModule,
		LeafletDrawModule,
		LeafletModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDialogModule,
		MatBottomSheetModule,
		MatDividerModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatRadioModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatTreeModule,
		MentionModule,
		NgxDropzoneModule,
		NgxMaterialTimepickerModule,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule
	],
	declarations: [
		BannerMessageComponent,
		BreadcrumbTrailComponent,
		CCWeatherWidgetComponent,
		CCWeatherWidgetCurrentComponent,
		CCWeatherWidgetDailyComponent,
		CCWeatherWidgetHourlyComponent,
		CCWeatherWidgetSettingsComponent,
		CardComponent,
		ChatComponent,
		ColorPickerComponent,
		ComingSoonComponent,
		CommonLayersComponent,
		CustomStationAreaStyleComponent,
		CustomTooltipComponent,
		DashboardCCWeatherWidgetComponent,
		DashboardWidgetContainerComponent,
		DashboardWidgetToolbarComponent,
		DialogComponent,
		DurationDialogComponent,
		EntitlementsRenewalWarningComponent,
		FreedomSystemDialogComponent,
		FrontBackDropdownComponent,
		GlobalMessageBoxComponent,
		GlobalSnackBarComponent,
		GolfLicenseWarningComponent,
		GprsRenewWarningComponent,
		GroupDecimalSeparatorComponent,
		IconButtonComponent,
		ImportedLayersComponent,
		InterfaceFaultFindingWarningComponent,
		LayerImportDialogComponent,
		LeftPanelComponent,
		LogoComponent,
		MainPanelToolbarComponent,
		MapContextMenuComponent,
		MapLeafletComponent,
		MapToolbarComponent,
		MultiSelectButtonsComponent,
		NavbarComponent,
		NearbyStationSelectComponent,
		NewSoftwareVersionAvailableComponent,
		NoInternetComponent,
		NoteItemComponent,
		NotePopupComponent,
		NotesListComponent,
		PrimaryButtonComponent,
		RbGroupDecimalSeparatorDirective,
		RemainingRunTimeDisplayPipe,
		ReplaceWhitespacePipe,
		ToFixedNumberPipe,
		ScrollTextDirective,
		SelectHolesDropdownComponent,
		SiteAddressModalComponent,
		StartControllerAutoConnectModalComponent,
		StartControllerModalComponent,
		StartStationModalComponent,
		StationRemainingRuntimePercentPipe,
		StationsListComponent,
		SystemStatusFlowComponent,
		SystemStatusToolbarComponent,
		TimerCountdownComponent,
		TipContainerComponent,
		ToolbarExportMenuComponent,
		TooltipRendererDirective,
		TopBannerPanelComponent,
		TreeControllerListComponent,
		LeftPanelTabProgramListComponent,
		UnderConstructionComponent,
		UnitsOfMeasureLengthComponent,
		WaitingIndicatorComponent,
		WeatherWidgetComponent,
		WeatherWidgetTileComponent
	],
	exports: [
		BannerMessageComponent,
		BreadcrumbTrailComponent,
		CCWeatherWidgetComponent,
		CCWeatherWidgetCurrentComponent,
		CCWeatherWidgetDailyComponent,
		CCWeatherWidgetHourlyComponent,
		CCWeatherWidgetSettingsComponent,
		CardComponent,
		ChatComponent,
		ColorPickerComponent,
		ColorPickerModule,
		ComingSoonComponent,
		CommonLayersComponent,
		CommonModule,
		DashboardCCWeatherWidgetComponent,
		DashboardWidgetContainerComponent,
		DashboardWidgetToolbarComponent,
		DialogComponent,
		DragDropModule,
		DurationDialogComponent,
		EntitlementsRenewalWarningComponent,
		FormsModule,
		FreedomSystemDialogComponent,
		GlobalMessageBoxComponent,
		GlobalSnackBarComponent,
		GolfLicenseWarningComponent,
		GprsRenewWarningComponent,
		GroupDecimalSeparatorComponent,
		HighchartsChartModule,
		IconButtonComponent,
		ImportedLayersComponent,
		InterfaceFaultFindingWarningComponent,
		LeafletModule,
		LogoComponent,
		MapLeafletComponent,
		MapToolbarComponent,
		MatAutocompleteModule,
		MatBadgeModule,
		MatBottomSheetModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatProgressBarModule,
		MatRadioModule,
		MatSelectModule,
		MatSidenavModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatTreeModule,
		MentionModule,
		NavbarComponent,
		NearbyStationSelectComponent,
		NewSoftwareVersionAvailableComponent,
		NgxMaterialTimepickerModule,
		NoInternetComponent,
		NoteItemComponent,
		NotePopupComponent,
		NotesListComponent,
		PrimaryButtonComponent,
		RbGroupDecimalSeparatorDirective,
		ReactiveFormsModule,
		RouterModule,
		ScrollTextDirective,
		SelectHolesDropdownComponent,
		SiteAddressModalComponent,
		StartControllerAutoConnectModalComponent,
		StartStationModalComponent,
		StationRemainingRuntimePercentPipe,
		StationsListComponent,
		SystemStatusToolbarComponent,
		TimerCountdownComponent,
		TipContainerComponent,
		ToolbarExportMenuComponent,
		TooltipRendererDirective,
		TopBannerPanelComponent,
		TranslateModule,
		TreeControllerListComponent,
		UnitsOfMeasureLengthComponent,
		WaitingIndicatorComponent,
		WeatherWidgetComponent,
		WeatherWidgetTileComponent,
		ReplaceWhitespacePipe,
		ToFixedNumberPipe,
		MainPanelToolbarComponent,
		FrontBackDropdownComponent
	],
	providers: [
		DatePipe,
		DecimalPipe
	],
})
export class CoreModule {
	constructor(injector: Injector) {
		AppInjector = injector;
	}
}

<div id="weather-widget-container">
	<div *ngIf="isViewWeatherShown">
		<button
			mat-button
			type="button"
			color="primary"
			(click)="onShowETCalendar()"
			class="show-calendar-button mat-button mat-primary bordered-icon">
			{{'STRINGS.VIEW_ET_CALENDAR' | translate}}
		</button>
	</div>
	<rb-weather-widget-tile
		*ngFor="let td of tileData; let i = index;"
		[tileData]="td"
		[location]="location"
		[currentConditions]="i === 0 ? currentConditions : null"
		[tileIndex]="i"
		[isExpanded]="i === activeTile"
		(tileClick)="onTileClick($event)"
	></rb-weather-widget-tile>

</div>

<rb-waiting-indicator
	*ngIf="isBusy"
	[isVisible]="isBusy"
	[error]="loadError">
</rb-waiting-indicator>

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthManagerService } from '../../../../api/auth/auth-manager-service';
import { CultureSettings } from '../../../../api/culture-settings/models/culture-settings.model';
import { InputSelectListItem } from '../../input-select/input-select.component';
import { RbEnums } from '../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../common/utils/_rb.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'rb-units-of-measure-pressure',
	templateUrl: './units-of-measure-pressure.component.html',
	styleUrls: ['./units-of-measure-pressure.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UnitsOfMeasurePressureComponent),
			multi: true
		}
	]
})
export class UnitsOfMeasurePressureComponent implements OnInit, ControlValueAccessor {

	constructor(private translateService: TranslateService,
				private authManager: AuthManagerService) { }

	@Input() list: InputSelectListItem[] = [];
	@Input() placeholder: string;
	@Input() isRequired = false;
	@Input() readonly = false;
	@Input() minVal: number = null;
	@Input() maxVal: number = null;
	@Input() precision = 2;

	hintLabel: string;
	loadError: string;
	resetting = false;
	valueString: string;

	RbEnums = RbEnums;

	private cultureSettings: CultureSettings;
	private _value: number;

	// noinspection JSUnusedLocalSymbols
	private onChange = (_: any) => { };
	private onTouched = () => { };

	ngOnInit(): void {
		this.cultureSettings = this.authManager.userCulture;
		this.hintLabel = this.translateService.instant(this.cultureSettings.unitType === RbEnums.Common.UnitsType.English ? 'UNIT_TYPE.PSI' : 'UNIT_TYPE.BAR');
	}

	get value(): number { return this._value; }

	writeValue(value: any): void {
		this._value = value;
		this.updateValueString();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onValueChanged() {
		this.onTouched();
		this._value = +this.valueString.toString().replace(/\D/g, '.');
		this.onChange(this._value);
	}

	private updateValueString() {
		this.valueString = this._value == null ? null :
			RbUtils.Common.convertValueToPrecision(this.cultureSettings.decimalSeparator, this._value.toString(), this.precision);
	}

	reset() {
		this.valueString = null;

		// This is a hack to *ngIf (hide and show) the controls so they reset their touched/dirty state
		this.resetting = true;
		setTimeout(() => this.resetting = false, 10);
	}
}

import * as moment from 'moment';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Area } from '../../../api/areas/models/area.model';
import { AuthManagerService } from '../../../api/auth/auth-manager-service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { GolfSensor } from '../../../api/sensors/models/golf-sensor.model';
import { HoleAreaPair } from '../../../api/sites/models/hole-area-pair.model';
import { MatDialog } from '@angular/material/dialog';
import { RbConstants } from '../../../common/constants/_rb.constants';
import { RbEnums } from '../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../common/utils/_rb.utils';
import { ReportFilter } from './models/report-filter.model';
import { ReportFilterDateRange } from './models/report-filter-date-range.model';
import { ReportFilterTimePeriod } from './models/report-filter-time-period.model';
import { Site } from '../../../api/sites/models/site.model';
import { SiteManagerService } from '../../../api/sites/site-manager.service';
import { SiteTreeView } from '../../../api/sites/models/site-tree-view.model';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import ReportCard = RbEnums.Common.ReportCard;

@UntilDestroy()
@Component({
	selector: 'rb-report-filter',
	templateUrl: './report-filter.component.html',
	styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit, OnDestroy {
	@HostBinding('class') class = 'report-filter';

	@ViewChild('reportWarningModal', { static: true }) reportWarningModal;

	/** Dropdown list options (date ranges). */
	@Input() dateRangeDropdown: { id: number, name: string }[];

	/** Show/hide date range portion of DateRange control. */
	@Input() hideDateRange = true;
	@Input() hideTimeForDateRange = true;

	@Input() hideDates = false;
	@Input() hideMonthPicker = true;
	@Input() hideRadioGroup = false;
	@Input() hideSelectController = true;
	@Input() hideSelectHoleArea = true;
	@Input() hideSelectFlowSensors = true;
	@Input() hideTimePeriod = true;
	@Input() showMobileSiteSelector = false;
	@Input() requestTreeLevel = 1;
	@Input() items = [];
	@Input() minDate: Date;
	@Input() maxDate = this.nowPlus24h;
	@Input() oneTimePeriod = false;
	@Input() singleSelection = false;
	@Input() validateDatesWithoutTimes = false;
	@Input() useStartOfDayForDateRange = false;
	@Input() sensorSelectLabel = '';
	@Input() flowSensors: GolfSensor[] = [];
	@Input() reportName: string;
	@Input() showAlarmData = false;
	@Input() showPdf = false;
	@Input() showSiteSelector = false;
	@Input() report: ReportCard = null;
	@Input() showSeparatePumpsCheck = false;

	/** The default dateRangeDrown selected value. */
	@Input() selectedDateRange = RbConstants.Form.DATE_RANGE.alarm;
	@Input() selectedMonth: number;

	/** SiteTreeView[] used to populate Site/Tree Selector. */
	@Input() treeViewItems: SiteTreeView[] = [];

	@Output() exportPdf = new EventEmitter();
	@Output() reportButtonClicked = new EventEmitter<ReportFilter>();
	@Output() showPumpsChange = new EventEmitter<boolean>();
	

	private _showPumps: boolean;


	@Input() set showPumps(value: boolean) {
		if (this._showPumps === value) return;
		this.showPumpsChange.emit(value);
		this._showPumps = value;
	}

	get showPumps(): boolean {
		return this._showPumps;
	}

	// Constants
	readonly sysLogReportTransitionWidth = 905;

	alarmDataTypes;
	controllerIds;
	selectedCourseId: number;
	selectedHoles: Area[] = [];
	selectedAreas: Area[] = [];
	selectedHoleAreaPairs = Array<HoleAreaPair>();
	selectedFlowSensors: GolfSensor[] = [];
	endDate;
	endDateCurrent;
	endDatePrevious;
	isMobile = false;
	month;
	runReportValidationMessage = '';
	startDate;
	startDateCurrent;
	startDatePrevious;
	isGolfSite = false;
	sites: Site[];
	selectedSites: Site[];
	runReportButtonLabel: string;
	

	/**
	 * @summary nowPlus24h returns the current date/time plus 24 hours. We use this to allow the user some flexibility
	 * when selecting report end times, as they are local to the controller/irrigation engine NOT TO THE USER'S
	 * BROWSER, so we can't just limit them to now.
	 *
	 * TODO: RBCC - figure out how to have this update every minute or so. As things work now, the user can open
	 * the config screen for a report, wait 10 minutes to select an end date and not be able to choose a time
	 * later than the open time of the config screen. That's wrong.
	 */
	get nowPlus24h(): Date {
		return moment().add(24, 'hours').toDate();
	}

	// =========================================================================================================================================================
	// C'tor, Init and Destroy
	// =========================================================================================================================================================

	constructor(private authManager: AuthManagerService,
				private deviceManager: DeviceManagerService,
				private dialog: MatDialog,
				private siteManager: SiteManagerService,
				private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.isGolfSite = RbUtils.Common.isGolfSite(this.authManager.getUserProfile().siteType);
		this.isMobile = this.deviceManager.isMobile;
		this.setReportButtonLabel();

		this.deviceManager.isMobileChange
			.pipe(untilDestroyed(this))
			.subscribe(isMobile => this.isMobile = isMobile);

		if (this.showSiteSelector) {
			this.siteManager.getSites()
				.pipe(take(1))
				.subscribe((sites: Site[]) => this.sites = sites);

			this.siteManager.selectedSitesChange
				.pipe(untilDestroyed(this))
				.subscribe(() => this.getSelectedSites());

			if (this.report === ReportCard.SystemChangeLog) {
				this.deviceManager.windowResize
					.pipe(untilDestroyed(this))
					.subscribe(() => this.setReportButtonLabel());
			}

			this.getSelectedSites();
		}
	}

	ngOnDestroy(): void {
		/** Implemented to support untilDestroyed() */
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	handleChange(param, value) {
		switch (param) {
			case 'month':
				this.month = value;
				return;
			case 'holeAreaPairs':
				if (value.selectedCourseId) this.selectedCourseId = value.selectedCourseId;
				if (value.selectedHoles) this.selectedHoles = value.selectedHoles;
				if (value.selectedAreas) this.selectedAreas = value.selectedAreas;
				if (value.selectedHoleAreaPairs) this.selectedHoleAreaPairs = value.selectedHoleAreaPairs;
				return;
			case 'controllerIds':
				this.controllerIds = value;
				return;
			case 'controllerId':
				this.controllerIds = [value];
				return;
			case 'startDate':
				this.startDate = value;
				return;
			case 'endDate':
				this.endDate = value;
				return;
			case 'dateRange':
				if (value.startDate) this.startDate = value.startDate;
				if (value.endDate) this.endDate = value.endDate;
				if (RbUtils.DateRange.isWaterReportsDateRange(this.dateRangeDropdown)) {
					this.startDatePrevious = value.startDatePrevious;
					this.endDatePrevious = value.endDatePrevious;
				}
				return;
			case 'timePeriod':
				this.startDateCurrent = value.startDateCurrent;
				this.endDateCurrent = value.endDateCurrent;
				this.startDatePrevious = value.startDatePrevious;
				this.endDatePrevious = value.endDatePrevious;
				return;
			case 'alarmTypes':
				this.alarmDataTypes = value;
				return;
			case 'flowSensors':
				this.selectedFlowSensors = value;	// Array of flow sensor Id values
				return;
		}
	}

	onSiteSelectionComplete(selectedSiteIds: number[]) {
		this.siteManager.selectedSiteIds = selectedSiteIds;
	}

	reportButton() {
		this.runReportValidationMessage = '';
		if (this.isGolfSite) {
			if ((!this.hideSelectController || !this.hideRadioGroup) && (this.selectedHoleAreaPairs === undefined || this.selectedHoleAreaPairs.length < 1))
				this.runReportValidationMessage = this.translate.instant('VALIDATION.NO_AREA_SELECTED');
		} else {
			if ((!this.hideSelectController || !this.hideRadioGroup) && (this.controllerIds === undefined || this.controllerIds.length < 1))
				this.runReportValidationMessage = this.translate.instant('VALIDATION.NO_CONTROLLER_SELECTED');
		}

		if (!this.hideMonthPicker && !this.month) {
			this.runReportValidationMessage += (this.runReportValidationMessage.length === 0 ? '' : ' ') +
				this.translate.instant('VALIDATION.SELECTED_MONTH_INVALID');
		}
		if (!this.hideDateRange) {
			if (this.startDate == null || this.endDate == null) {
				this.runReportValidationMessage += (this.runReportValidationMessage.length === 0 ? '' : ' ') +
					this.translate.instant('VALIDATION.NO_DATE_RANGE_SELECTED');
			} else if (this.startDate > this.endDate) {
				if (!this.validateDatesWithoutTimes || moment(this.endDate).diff(this.startDate, 'days') < 0) {
					this.runReportValidationMessage += (this.runReportValidationMessage.length === 0 ? '' : ' ') +
						this.translate.instant('VALIDATION.INVALID_DATE_RANGE');
				}
			}
		}
		if (!this.hideTimePeriod) {
			if ((!this.startDateCurrent || !this.endDateCurrent || this.startDateCurrent > this.endDateCurrent)) {
				this.runReportValidationMessage += (this.runReportValidationMessage.length === 0 ? '' : ' ') +
					this.translate.instant(this.oneTimePeriod ? 'VALIDATION.INVALID_DATE_RANGE' : 'VALIDATION.INVALID_DATE_RANGE_CURRENT');
			}
			if (!this.oneTimePeriod) {
				if ((!this.startDatePrevious || !this.endDatePrevious || this.startDatePrevious > this.endDatePrevious)) {
					this.runReportValidationMessage += (this.runReportValidationMessage.length === 0 ? '' : ' ') +
						this.translate.instant('VALIDATION.INVALID_DATE_RANGE_PREVIOUS');
				}
				if ((this.endDatePrevious > this.endDateCurrent)) {
					this.runReportValidationMessage += (this.runReportValidationMessage.length === 0 ? '' : ' ') +
						this.translate.instant('VALIDATION.INVALID_DATE_RANGE_CURRENT_PREVIOUS');
				}
			}
		}
		if (this.runReportValidationMessage.length > 0) {
			this.dialog.open(this.reportWarningModal);
		} else {
			this.handleRunReport();
		}
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private handleRunReport() {
		const reportFilter = new ReportFilter;

		if (!this.hideSelectController || !this.hideRadioGroup || !this.hideSelectHoleArea || !this.hideSelectFlowSensors) {
			reportFilter.controllerIds = this.controllerIds;
			if (this.isGolfSite) {
				// Construct a tree of the selected sites/controllers
				this.selectedHoleAreaPairs.forEach(holeAreaPair => {
					for (let iSite = 0; iSite < this.treeViewItems.length; iSite++) {
						for (let iHole = 0; iHole < this.treeViewItems[iSite].children.length; iHole++) {
							if (this.treeViewItems[iSite].children[iHole].id === holeAreaPair.holeId) {
								let site = reportFilter.selectedControllerTreeViewItems.find(item => item.id === this.treeViewItems[iSite].id);
								if (site == null) {
									site = new SiteTreeView();
									site.id = this.treeViewItems[iSite].id;
									site.item = this.treeViewItems[iSite].item;
									reportFilter.selectedControllerTreeViewItems.push(site);
								}
								const hole = this.treeViewItems[iSite].children[iHole];
								for (let iArea = 0; iArea < hole.children.length; iArea++) {
									if (hole.children[iArea].id === holeAreaPair.areaId) {
										let selectedHole = site.children.find(item => item.id === hole.id);
										if (!selectedHole) {
											selectedHole = new SiteTreeView();
											selectedHole.id = hole.id;
											selectedHole.item = hole.item;
											site.children.push(selectedHole);
										}
										selectedHole.children.push(hole.children[iArea]);
										break;
									}
								}
								break;
							}
						}
					}
				});
				if (reportFilter.selectedControllerTreeViewItems.length > 0) {
					reportFilter.holeAreaPairs = this.selectedHoleAreaPairs;
					// Sort by site name then by controller name
					reportFilter.selectedControllerTreeViewItems = reportFilter.selectedControllerTreeViewItems.sort((a, b) => a.item.localeCompare(b.item));
				}
				reportFilter.selectedFlowSensors = this.selectedFlowSensors;
			} else {
				// Construct a tree of the selected sites/controllers
				this.controllerIds.forEach(controllerId => {
					for (let iSite = 0; iSite < this.treeViewItems.length; iSite++) {
						for (let iController = 0; iController < this.treeViewItems[iSite].children.length; iController++) {
							if (this.treeViewItems[iSite].children[iController].id === controllerId) {
								let site = reportFilter.selectedControllerTreeViewItems.find(item => item.id === this.treeViewItems[iSite].id);
								if (site == null) {
									site = new SiteTreeView();
									site.id = this.treeViewItems[iSite].id;
									site.item = this.treeViewItems[iSite].item;
									reportFilter.selectedControllerTreeViewItems.push(site);
								}

								site.children.push(this.treeViewItems[iSite].children[iController]);
								return;
							}
						}
					}
				});
				if (reportFilter.selectedControllerTreeViewItems.length > 0) {
					reportFilter.controllerIds = [];

					// Sort by site name then by controller name
					reportFilter.selectedControllerTreeViewItems = reportFilter.selectedControllerTreeViewItems.sort((a, b) => a.item.localeCompare(b.item));
					reportFilter.selectedControllerTreeViewItems.forEach(site => {
						site.children = site.children.sort((a, b) => a.item.localeCompare(b.item));
						reportFilter.controllerIds = reportFilter.controllerIds.concat(site.children.map(controller => controller.id));
					});
				}
			}
		}
		if (!this.hideDateRange && !RbUtils.DateRange.isWaterReportsDateRange(this.dateRangeDropdown)) {
			reportFilter.dateRange = new ReportFilterDateRange(this.startDate, this.endDate, this.hideTimeForDateRange);
		}
		if (!this.hideMonthPicker) {
			reportFilter.month = this.month;
		}
		if (!this.hideTimePeriod) {
			reportFilter.timePeriod = new ReportFilterTimePeriod(new ReportFilterDateRange(this.startDateCurrent, this.endDateCurrent));
			if (!this.oneTimePeriod) {
				reportFilter.timePeriod.previousDateRange = new ReportFilterDateRange(this.startDatePrevious, this.endDatePrevious);
			}
		}

		if (RbUtils.DateRange.isWaterReportsDateRange(this.dateRangeDropdown)) {
			reportFilter.timePeriod = new ReportFilterTimePeriod(new ReportFilterDateRange(this.startDate, this.endDate));
			if (!this.oneTimePeriod) {
				reportFilter.timePeriod.previousDateRange = new ReportFilterDateRange(this.startDatePrevious, this.endDatePrevious);
			}
		}

		if (this.showAlarmData) {
			reportFilter.eventLogTypes = this.alarmDataTypes;
		}
		this.reportButtonClicked.emit(reportFilter);
	}

	private setReportButtonLabel() {
		this.runReportButtonLabel = this.report === ReportCard.SystemChangeLog && window.innerWidth <= this.sysLogReportTransitionWidth
			? 'STRINGS.RUN_UPPERCASE'
			: 'REPORTS.RUN_REPORT_UPPERCASE';
	}

	private getSelectedSites() {
		this.siteManager.getSelectedSites()
			.pipe(take(1))
			.subscribe((sites: Site[]) => this.selectedSites = sites);
	}

}

/* eslint-disable @typescript-eslint/member-ordering */
import 'leaflet-toolbar';

import 'leaflet.gridlayer.googlemutant';
import 'leaflet-distortableimage';

import { BehaviorSubject, firstValueFrom, forkJoin, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { circleMarker, distortableImageOverlay, geoJSON, gridLayer, icon, kmzLayer, LatLng, latLng, Map, tooltip as newTooltip, tileLayer } from 'leaflet';
import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { share, switchMap, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Area } from '../../api/areas/models/area.model';
import { AreaManagerService } from '../../api/areas/area-manager.service';
import { AreaUiSettings } from '../../api/areas/models/area-ui-settings.model';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { BroadcastService } from './broadcast.service';
import { CompanyManagerService } from '../../api/companies/company-manager.service';
import { ControllerGetLogsState } from '../../api/signalR/controller-get-logs-state.model';
import { ControllerManagerService } from '../../api/controllers/controller-manager.service';
import { CultureSettingsManagerService } from '../../api/culture-settings/culture-settings-manager.service';
import { DeviceManagerService } from './device-manager.service';
import { DiagnosticLogManagerService } from '../../api/diagnostic-log/diagnostic-log-manager.service';
import { EnvironmentService } from './environment.service';
import { EventLogManagerService } from '../../api/event-logs/event-log-manager.service';
import { FeatureCollection } from 'geojson';
import { GeoGroupManagerService } from '../../api/regions/geo-group-manager.service';
import { GeoItem } from '../../api/regions/models/geo-item.model';
import { GeoJSONProperties } from '../../api/leaflet/models/geojson-properties.model';
import { getTile } from 'leaflet.offline';
import { InternetService } from '../../api/internet/internet.service';
import { KMZItem } from '../../api/leaflet/models/kmz-Item.model';
import { KMZItemProperties } from '../../api/leaflet/models/kmz-item-properties.model';
import { LeafletManagerService } from '../../api/leaflet/leaflet-manager.service';
import localforage from 'localforage';
import { ManualControlManagerService } from '../../api/manual-control/manual-control-manager.service';
import { ManualControlState } from '../../api/manual-control/models/manual-control-state.model';
import { ManualOpsManagerService } from '../../api/manual-ops/manual-ops-manager.service';
import { MapInfoLeaflet } from '../models/map-info-leaflet.model';
import { MapService } from './map.service';
import { MessageBoxService } from './message-box.service';
import { ModuleApiService } from '../../api/modules/module-api.service';
import { MultiSelectService } from './multi-select.service';
import { RasterItem } from '../../api/leaflet/models/raster-file.model';
import { RbEnums } from '../enumerations/_rb.enums';
import { SensorManagerService } from '../../api/sensors/sensor-manager.service';
import { SiteManagerService } from '../../api/sites/site-manager.service';
import { Station } from '../../api/stations/models/station.model';
import { StationListItem } from '../../api/stations/models/station-list-item.model';
import { StationManagerService } from '../../api/stations/station-manager.service';
import { StationsListChange } from '../../api/stations/models/stations-list-change.model';
import { StickyNoteManagerService } from '../../api/sticky-notes/sticky-note-manager.service';
import { SystemStatusService } from './system-status.service';
import { ToasterService } from './toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { UiSettingsService } from '../../api/ui-settings/ui-settings.service';
import { UserManagerService } from '../../api/users/user-manager.service';
import { VoltageDiagnosticManagerService } from '../../api/voltage-diagnostic/voltage-diagnostic-manager.service';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class MapLeafletService implements OnDestroy {

	// Subjects
	userLocationAvailabilityResponse = new BehaviorSubject<boolean>(null);
	offLineTilesSaveStart = new Subject<MapInfoLeaflet>();
	offLineTilesSaved = new Subject<MapInfoLeaflet>();
	offLineTilesRemoved = new Subject<MapInfoLeaflet>();

	static readonly OsmURL = `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`;

	static readonly EsriURL = `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`;

	private url = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAScTFPStgZvcf5r0zKCCBsJgzQ8Gk54cQ';

	static readonly MAX_ZOOM_LEVEL = 20; // RB-13033: The map works well on this max level.
	readonly ZOOM_LEVEL_STREET = 19;

	static renderer: Renderer2;

	private static getActiveGeoJsonObservables: { [mapId: string]: Observable<KMZItem[]> } = {};

	// private courseAreas: Area[] = [];
	private mapInfo: MapInfoLeaflet[] = [];
	private _currentMapInfo: MapInfoLeaflet;
	private serverAddress: string;
	private lastPosition: GeolocationPosition;
	private getKmzItemObservables: { [vectorItemId: string]: Observable<KMZItem> } = {}; // dictionary of observables by Kmz Item ID
	private getKmzItemsObservables: { [siteId: string]: Observable<KMZItem[]> } = {}; // dictionary of observables by site ID
	private getRasterItemsObservables: { [siteId: string]: Observable<RasterItem[]> } = {}; // dictionary of observables by site ID
	/** localForage store for geoJSONs */
	private geoJSONStore: LocalForage;
	/** localForage store for sites for which we have geoJSONs saved */
	private sitesGeoJSONsStore: LocalForage;
	downloadingTiles = false;
	removingTiles = false;
	downloadTilesBtnReadyDelay = 1600;
	isGolfSite = false;

	get currentMapInfo() {
		return this._currentMapInfo;
	}

	/**
	 * watchPositionId is the result from a watchPosition Location API call, or null if no call has been made or no
	 * watch is active.
	 */
	private watchPositionId: number;

	/**
	 * watchPositionStart is the Date (formatted as local time) at which the user position watchPosition() command to
	 * the Location API was most-recently sent. There are debugging situations where this might be valuable. In particular
	 * iOS seems to require a *NEW* watchPosition() call whenever the device is resumed from sleep, brought to the front
	 * after being sent to the back, etc. If we miss a case where this should occur, noting an out-of-date
	 * watchPositionStart value could be reveal it.
	 */
	private watchPositionStart: string;

	/**
	 * showTrackingData is a flag controlling continuous GPS data display by the service. If we're having a "GPS accuracy"
	 * issue of some kind, switching this on can help us find it. The user can toggle it by performing the undocumented
	 * double-click-the-FindMe-tool operation, so it's always available.
	 */
	public showTrackingData = false;

	/**
	 * trackingData is the data to be shown when showTrackingData is true. It basically contains this information:
	 * localTimeOfDay per the device clock
	 * gpsTimestamp of the most-recent data received (time only)
	 * gpsLongitude rounded to 6 decimal places
	 * gpsLatitude rounded to 6 decimal places
	 * gpsAccuracy in meters rounded to 2 decimal places
	 */
	public trackingData = '';

	private subscriber: Subscription;

	/**
	 * initializingGoogleMapsSubject is a ReplaySubject which clients can subscribe waiting for the maps API to be fully
	 * initialized. For example, you might do this before trying to call some method performing geocoding, since that won't
	 * work until the API is initialized.
	 */
	// public static initializingGoogleMapsSubject: ReplaySubject<null>;

	/**
	 * googleScriptInitialized provides a non-async method checking whether the maps API has been loaded and initialized.
	 * You might use this deciding whether to act on some event or skip it, rather than wait for initialization on
	 * initializingGoogleMapsSubject.
	 */
	// public static googleScriptInitialized = false;
	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(rendererFactory: RendererFactory2,
		public areaManager: AreaManagerService,
		public authManager: AuthManagerService,
		public broadcastService: BroadcastService,
		public companyManager: CompanyManagerService,
		private cultureSettingsManager: CultureSettingsManagerService,
		public controllerManager: ControllerManagerService,
		public deviceManager: DeviceManagerService,
		public diagnosticLogManagerService: DiagnosticLogManagerService,
		public env: EnvironmentService,
		public geoGroupManager: GeoGroupManagerService,
		public manualOpsManager: ManualOpsManagerService,
		private manualControlManager: ManualControlManagerService,
		public messageBoxService: MessageBoxService,
		public sensorManager: SensorManagerService,
		public siteManager: SiteManagerService,
		public stationManager: StationManagerService,
		public systemStatusService: SystemStatusService,
		public eventLogManager: EventLogManagerService,
		public translate: TranslateService,
		public uiSettingsService: UiSettingsService,
		public voltageDiagnosticManager: VoltageDiagnosticManagerService,
		public stickyNoteManager: StickyNoteManagerService,
		private mapService: MapService,
		public leafletMgrService: LeafletManagerService,
		public toastService: ToasterService,
		public internetService: InternetService,
		public moduleApiService: ModuleApiService,
		public multiSelectService: MultiSelectService,
		public userManager: UserManagerService,
	) {


		// We don't want several renderers, so we save this one as a static property.
		MapLeafletService.renderer = rendererFactory.createRenderer(null, null);

		// Station status changed based on stationsListChange and stationsStatusesUpdateCompleted
		this.stationManager.stationsListChange
			.pipe(untilDestroyed(this))
			.subscribe((change: StationsListChange) => this.mapInfo.forEach(mi => mi.stationsListChanged(change)));
		this.isGolfSite = this.siteManager.isGolfSite;
		if (!this.isGolfSite) {
			this.stationManager.stationsStatusesUpdateCompleted
			.pipe(
				untilDestroyed(this),
				switchMap((controllerId) => {
					return forkJoin([of(controllerId), this.stationManager.getStationsList(controllerId, true).pipe(take(1))])
				})
				
			)
			.subscribe(([controllerId, stationListItem]) => {
				this.mapInfo.forEach(mi => mi.stationsListChanged(new StationsListChange(controllerId, stationListItem, true)))}
			);
		}

		// this.stationManager.stationsAdded.pipe(untilDestroyed(this)).subscribe(() => this.mapInfo.forEach(mi => mi.stationsAdded()));
		// this.stationManager.stationsDeleted.pipe(untilDestroyed(this)).subscribe(() => this.mapInfo.forEach(mi => mi.stationsDeleted()));
		// this.siteManager.siteAddressChange.pipe(untilDestroyed(this))
		// 	.subscribe(id => this.mapInfo.filter(mi => mi.siteId === id).forEach(mi => mi.siteAddressChange()));

		this.areaManager.areaChange.pipe(untilDestroyed(this)).subscribe((area: Area) => this.mapInfo.forEach(mi => mi.areaChanged(area)));
		// this.systemStatusService.golfStationStatusChange.pipe(untilDestroyed(this))
		// 	.subscribe((change: StationStatusChange) => this.reloadStation(change.stationId));
		// this.companyManager.companyStatusChange.pipe(untilDestroyed(this)).subscribe(() => this.mapInfo.forEach(mi => mi.companyStatusChanged()));
		this.broadcastService.diagnosticDataReceived.pipe(untilDestroyed(this)).subscribe(data => {
			this.mapInfo.forEach(mi => mi.diagnosticDataReceived(data));
		});
		this.broadcastService.controllerCollectionChange.pipe(untilDestroyed(this))
			.subscribe(data => this.mapInfo.forEach(mi => mi.controllerCollectionChanged(data)));
		this.broadcastService.eventLogsStateChange.pipe(untilDestroyed(this))
			.subscribe((state: ControllerGetLogsState) => this.mapInfo.forEach(mi => mi.eventLogsStateChanged(state)));
		this.siteManager.sitesUpdate.pipe(untilDestroyed(this)).subscribe(info => this.mapInfo.forEach(mi => mi.sitesUpdated(info.data)));
		this.cultureSettingsManager.cultureSettingsChange.pipe(untilDestroyed(this))
			.subscribe(() => this.mapInfo.forEach(mi => mi.cultureSettingsChanged()));
		this.manualControlManager.manualControlStateChange.pipe(untilDestroyed(this))
			.subscribe((state: ManualControlState) => this.mapInfo.forEach(mi => mi.manualControlStateChanged(state.controllerId, state)));
		this.broadcastService.connectionStopped.pipe(untilDestroyed(this)).subscribe(id => this.mapInfo.forEach(mi => mi.manualControlStateChanged(id, null)));

		// Initialize the map stuff as soon as one instance of us is created. We use a static property, initializingGoogleMapsSubject,
		// assuring that we don't repeatedly initialize. We don't do anything when initialization is complete; we just need to subscribe
		// to make the operation happen, if we are the first MapService instance.
		//
		// RB-10011: Right now the original Map Service is used to have both mapServices in sync and not initialize Gmaps twice.
		// When choosing one map over the others this should point to the own initializeMaps()
		this.mapService.initializeMaps(this.url).pipe(untilDestroyed(this)).subscribe(() => { });

		// We need to set up to restart this position tracking when the browser visibility changes. The problem is primarily on iOS
		// (of course, where Apple is always right). In that scenario, when the iPad goes to sleep and is later resumed or the browser
		// is sent to the back and restored to the front, the position data is never received again, or has very low accuracy. The
		// intent of this fix is to detect those changes and make sure we restart high-accuracy location polling when Safari comes
		// to the front again. This likely also applies to Chrome, at least on iOS, FYI.
		this.broadcastService.initializeVisibilityChangeListener();
		this.broadcastService.visibilityChange
			.pipe(untilDestroyed(this))
			.subscribe((notification) => {
				if (notification.hidden) {
					// Clear the watch. It doesn't work anyway.
					this.trackingData = 'Going to sleep?';
					this.stopMonitoringUserLocation();
				} else {
					// The document is back in front. Regenerate the position requests. Since (I presume) we might get several of these
					// messages in a row and we really want the last one to be effective, we stop before starting in each case. If we're
					// already stopped, this does nothing.
					this.trackingData = 'Resuming from sleep?';
					this.stopMonitoringUserLocation();
					this.monitorUserLocation();
				}
			});

		// Create stores for geoJSONs
		this.geoJSONStore = localforage.createInstance({ name: 'RBCC', storeName: 'geojsons' });
		this.sitesGeoJSONsStore = localforage.createInstance({ name: 'RBCC', storeName: 'sites-geojsons' });

		// Take the api URL and extract the address (including port number)
		const apiUrl = new URL(this.env.apiUrl)
		this.serverAddress = `${apiUrl.protocol}//${apiUrl.host}`;
	}

	/** Implemented to support untilDestroyed() */
	ngOnDestroy(): void {
		this.stopMonitoringUserLocation();
		this.subscriber.unsubscribe();
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	// This is the main function to re-parent the map to a new UI div and trigger loading of the appropriate site info.
	// It will fire a siteDataLoaded event when the loading is complete to allow users of this service to avoid loading the data themselves.
	getMap(siteId: number, uniqueId: number, map: Map, areItemsMovable: boolean = false, specialZoom: boolean = true, canMultiselect?: boolean)
		: MapInfoLeaflet {

		let mi = this.mapInfo.find(info => info.siteId === siteId && info.uniqueId === uniqueId);

		if (mi == null) {
			mi = new MapInfoLeaflet(map, siteId, uniqueId, this, specialZoom, canMultiselect, this.lastPosition);
			this.mapInfo.push(mi);
		} else {
			mi.map = map;
			mi.instanceReused();
		}
		mi.areItemsMovable = areItemsMovable;

		this._currentMapInfo = mi;
		this.monitorUserLocation();
		return mi;
	}

	/**
	 * Returns a new object with the offline tileLayer
	 */

	getOpenStreetMapTileLayer() {
		return tileLayer(MapLeafletService.OsmURL, {
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			maxZoom: MapLeafletService.MAX_ZOOM_LEVEL,
			maxNativeZoom: 19,
			tileSize: 256,
		});
	}

	getEsriTileLayer() {
		return tileLayer.offline(MapLeafletService.EsriURL, {
			attribution: `Powered by <a href="https://www.esri.com">Esri</a> | <span class="esri-dynamic-attribution" style="max-width: 902px;">
				Esri, HERE, Garmin, METI/NASA, USGS</span>`,
			maxZoom: MapLeafletService.MAX_ZOOM_LEVEL,
			maxNativeZoom: 18,
			tileSize: 256,
		});
	}

	getGoogleLayerSatellite() {
		return gridLayer.googleMutant({
			type: 'satellite'
		});
	}

	getGoogleLayerRoadmap() {
		return gridLayer.googleMutant({
			type: 'roadmap'
		});
	}

	getOfflineLayer() {
		const offlineLayer = tileLayer(MapLeafletService.EsriURL, {
			minZoom: 16,
			maxZoom: MapLeafletService.MAX_ZOOM_LEVEL,
			maxNativeZoom: 19,
		});

		offlineLayer.on('tileloadstart', (event: any) => {
			const { tile } = event;
			const url = tile.src;
			tile.src = '';

			getTile(url).then((blob) => {
				if (blob) {
					tile.src = URL.createObjectURL(blob);
				}
			});
		});

		return offlineLayer;
	}

	/**
	 * Returns a new object with the default Leaflet MapOptions for map initialization
	 */
	getDefaultLeafletMapOptions(): any {
		return {
			layers: [],
			maxZoom: MapLeafletService.MAX_ZOOM_LEVEL,
			maxNativeZoom: 19,
			zoom: 3, // Very low stating zoom so that the user sees the continents just in case they need to wait for site data to load
			zoomControl: false,
			worldCopyJump: true,
			center: latLng([ 0, 0 ]),
			preferCanvas: true
		};
	}

	getBaseLayers(): any {
		return {
			'Google Maps Satellite': gridLayer.googleMutant({
				type: 'satellite'
			}),
			'Google Maps Roadmap': gridLayer.googleMutant({
				type: 'roadmap'
			})
		};
	};

	mapRemoved(siteId: number, uniqueId: number): void {
		this.mapInfo = this.mapInfo.filter(info => info.siteId !== siteId || info.uniqueId !== uniqueId);
	}

	/**
	 * Explicit call to initialize maps if the previous attempt failed
	 */
	initializeMaps() {
		return this.mapService.initializeMaps(this.url);
	}

	runAfterGoogleMapsInitialized(fn: () => void, errorHandler?: () => void) {
		if (MapLeafletService.googleScriptInitialized) {
			fn();
		} else {
			this.initializeMaps().subscribe(fn, errorHandler);
		}
	}


	// =========================================================================================================================================================
	// Geocoding
	// =========================================================================================================================================================

	/**
	 * LookupAddressLatLng is a public utility method which can be used to retrieve some geo-coding information from
	 * an address. The result is ONLY provided in the callback, so no Observable interface, etc.
	 * RB-9518
	 * @param address - string address of the location to geocode
	 * @param city - string city of the location to geocode
	 * @param state - string state of the location to geocode
	 * @param zip - string zip code/postal code of the location to geocode. Use this only in USA for reliable
	 * results
	 * @param callback - (result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) to which the result
	 * is passed
	 */
	public static LookupAddressLatLng(address: string, city: string, state: string, zip: string, country: string,
		callback: (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => void) {
		if (address == null) address = '';
		if (city == null) city = '';
		if (state == null) state = '';
		if (zip == null) zip = '';
		if (country == null) country = '';
		if (typeof google === 'undefined') return;
		const geoCoder = new google.maps.Geocoder();
		geoCoder.geocode({
			address: `${address} ${city} ${state} ${zip} ${country}`
		}, callback);
	}

	/**
	 * LookupAddressLatLng is a public utility method which can be used to retrieve some geo-coding information from
	 * an address. The result is ONLY provided in the callback, so no Observable interface, etc.
	 * RB-9518
	 * @param address - string address of the location to geocode
	 * @param city - string city of the location to geocode
	 * @param state - string state of the location to geocode
	 * @param zip - string zip code/postal code of the location to geocode. Use this only in USA for reliable
	 * results
	 * @param callback - (result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) to which the result
	 * is passed
	 */
	public LookupAddressLatLng(address: string, city: string, state: string, zip: string, country: string,
		callback: (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => void) {
		// This method just calls out to the static method. It provides, however, a "use" for a mapService instance
		// that a client might need to create.
		MapLeafletService.LookupAddressLatLng(address, city, state, zip, country, callback);
	}

	// =========================================================================================================================================================
	// User location
	// =========================================================================================================================================================
	private monitorUserLocation() {
		console.log("Monitoring user location started...");
		if (this.watchPositionId != null) {
			return;
		}

		if (!navigator.geolocation) {
			this.userLocationAvailabilityResponse.next(false);
		}

		// Crank up the positional accuracy of location data.
		const options: PositionOptions = {
			enableHighAccuracy: true,

			// RB-9003: Adjust the maximum age of the positional fix. No idea what the "right" value is, but this is a
			// reasonable starting point for tracking a position. 0 ==> always get the latest position, never using
			// cached data.
			maximumAge: 2000,	// ms
		};

		const self = this;
		this.watchPositionStart = new Date().toLocaleTimeString();	// Save when we made this call.

		// We could use getCurrentPosition, but users will expect a nice tracking experience. WatchPosition seems better
		// for that.
		this.watchPositionId = navigator.geolocation.watchPosition(function (position) {

			self.lastPosition = position;

			// We got an update. Record in a toast message when and what and how many mapInfos we updated, if showTrackingData
			// is set.
			self.trackingData = self.translate.instant('STRINGS.MAP_SPECIAL_INFO_FORMAT',
				{
					currentDateTime: new Date().toLocaleTimeString(),
					gpsTimestamp: new Date(position.timestamp).toLocaleTimeString(),
					gpsLongitude: position.coords.latitude.toFixed(6),
					gpsLatitude: position.coords.longitude.toFixed(6),
					gpsAccuracyM: position.coords.accuracy.toFixed(2),
					watchPositionStart: self.watchPositionStart,
				});

			self.mapInfo.forEach(mi => mi.userLocationUpdated(position));
			
			self.userLocationAvailabilityResponse.next(true);
			
		}, function (err) {

			self.userLocationAvailabilityResponse.next(false);

			if (self.showTrackingData) {
				self.trackingData = self.translate.instant('STRINGS.MAP_SPECIAL_INFO_FORMAT_ERROR',
					{
						currentDateTime: new Date().toLocaleTimeString(),
						errorCode: err.code,
						errorMessage: err.message,
					});
			}
		}, options);
	}

	private stopMonitoringUserLocation() {
		// Turn off map tracking, if enabled.
		if (this.watchPositionId != null) {
			// Show informational message for debugging.
			this.trackingData = this.translate.instant('STRINGS.MAP_SPECIAL_INFO_FORMAT_STOPPING',
				{
					currentDateTime: new Date().toLocaleTimeString(),
				});

			if (navigator.geolocation) {
				navigator.geolocation.clearWatch(this.watchPositionId);
			}

			// Clear the watch Id in all cases so we don't get confused about whether we're tracking or not.
			this.watchPositionId = null;
		}
	}

	/**
	 * Method called by MapInfo when the user performs some special action enabling/disabling special location tracking.
	 * We use this to turn showTrackingData ON/OFF.
	 */
	public monitorUserLocationSpecialActivated() {
		this.showTrackingData = !this.showTrackingData;

		if (this.showTrackingData) {
			// Show something so that, even if we aren't getting updates very fast there's feedback that the feature was
			// activated.
			this.trackingData = this.translate.instant('STRINGS.MAP_SPECIAL_INFO_FORMAT_WAITING',
				{
					currentDateTime: new Date().toLocaleTimeString(),
				});

			// Ask for location updates, if not already doing that.
			this.monitorUserLocation();
		}
	}

	contextMenuDisabled(contextMenuInfo: any, option: any): boolean {
		return (option.value === RbEnums.Map.ControllerContextMenu.Sync &&
			contextMenuInfo.controller.syncState === RbEnums.Common.ControllerSyncState.Syncing) ||
			(option.value === RbEnums.Map.ControllerContextMenu.ReverseSync &&
				contextMenuInfo.controller.syncState === RbEnums.Common.ControllerSyncState.ReverseSyncing) ||
			(option.value === RbEnums.Map.ControllerContextMenu.Logs &&
				(contextMenuInfo.controller.gettingLogs || contextMenuInfo.controller.firmwareUpdateProgress !== null)) ||
			(option.value === RbEnums.Map.ControllerContextMenu.Connect
				&& (contextMenuInfo.controller.isConnecting
					|| contextMenuInfo.controller.isConnected || contextMenuInfo.controller.firmwareUpdateProgress !== null)) ||
			(option.value === RbEnums.Map.StationContextMenu.Resume
				&& (contextMenuInfo?.station?.irrigationStatus !== RbEnums.Common.IrrigationStatus.Paused))
	}

	contextualButtonShow(contextMenuInfo: any, option: any): boolean {		
		return (option.value === RbEnums.Map.StationContextMenu.Start &&
				(contextMenuInfo?.station?.irrigationStatus === RbEnums.Common.StationStatus.Idle)) ||
			(option.value === RbEnums.Map.StationContextMenu.Pause &&
				(contextMenuInfo?.station?.irrigationStatus === RbEnums.Common.StationStatus.On)) ||
			(option.value === RbEnums.Map.StationContextMenu.Resume &&
				(contextMenuInfo?.station?.irrigationStatus === RbEnums.Common.StationStatus.Paused)) ||
			(option.value !== RbEnums.Map.StationContextMenu.Start &&
			option.value !== RbEnums.Map.StationContextMenu.Pause &&
			option.value !== RbEnums.Map.StationContextMenu.Resume)
	}

	contextMenuClass(contextMenuInfo: any, option: any): string {
		let menuClass = option.icon;
		switch (option.value) {
			case RbEnums.Map.ControllerContextMenu.Sync:
			case RbEnums.Map.ControllerContextMenu.ReverseSync:
				switch (contextMenuInfo.controller.syncState) {
					case RbEnums.Common.ControllerSyncState.Syncing:
						menuClass += ' syncing';
						break;
					case RbEnums.Common.ControllerSyncState.ReverseSyncing:
						menuClass += ' reverse-syncing';
						break;
					case RbEnums.Common.ControllerSyncState.Synchronized:
						menuClass += ' synced';
						break;
					case RbEnums.Common.ControllerSyncState.NotSynchronized:
					case RbEnums.Common.ControllerSyncState.HasDifferences:
						menuClass += ' not-synced';
						break;
					case RbEnums.Common.ControllerSyncState.Incomplete:
						menuClass += ' incomplete';
						break;
				}
				break;
			case RbEnums.Map.ControllerContextMenu.Connect:
				if (contextMenuInfo.controller.isConnected) menuClass += ' connected';
		}

		return menuClass;
	}

	/**
	 * updateStationLatLong sends a station update to the API, setting the database entry for the indicated
	 * station or station list item's latitude and longitude to the values in the station parameter. No
	 * follow-up operations on the station are performed.
	 */
	updateStationLatLong(station: Station | StationListItem) {
		this.stationManager.updateStations([station.id], { latitude: station.latitude, longitude: station.longitude })
			.subscribe(() => {
				// RB-8564: We don't need to reload the station; we have the update in-memory.
				// Since we also know that updateStation is only called when the lat/long has changed and nothing
				// else, we don't have to reload the station name, etc. into the mapInfo collection.
			});
	}

	holeDragged(geoItem: GeoItem, point: { latitude: number, longitude: number }): void {
		this.geoGroupManager.updateGeoItem({ id: geoItem.id, point }).subscribe(() => {
			geoItem.point.latitude = point.latitude;
			geoItem.point.longitude = point.longitude;
			this.mapInfo.forEach(mi => mi.geoItemUpdated(geoItem));
		});
	}

	updateAreaGeoItem(areaId: number, geoItemId: number, polygon: LatLng[]) {
		const newPoints = polygon.map(latlng => ({ latitude: latlng.lat, longitude: latlng.lng }));
		this.geoGroupManager.updateGeoItem({ id: geoItemId, polygon: newPoints }).subscribe(() => {
			this.mapInfo.forEach(mi => mi.areaGeoItemUpdated(areaId, geoItemId, polygon));
		});
	}

	updateStationAreaGeoItem(geoItemId: number, polygon: LatLng[]) {
		const newPoints = polygon.map(latlng => ({ latitude: latlng.lat, longitude: latlng.lng }));
		this.geoGroupManager.updateGeoItem({id: geoItemId, polygon: newPoints}).subscribe(() => {
			this.mapInfo.forEach(mi => mi?.stationAreaGeoItemUpdated(geoItemId));
		});
	}

	updateController(id: number, loc: { latitude: any; longitude: any }): void {
		this.controllerManager.updateControllers([id], loc).subscribe(() => this.mapInfo.forEach(mi => mi.controllerLocationUpdated(id, loc)));
	}

	addHole(hole: Area, loc: { latitude: number; longitude: number }): void {
		this.geoGroupManager.createGeoItem(hole.siteId, hole.id, null, { point: { longitude: loc.longitude, latitude: loc.latitude } })
			.subscribe(() => {
				// Get the updated geo group list
				this.geoGroupManager.getGeoGroupsForSite(hole.siteId).pipe(take(1)).subscribe(geoGroups => {
					this.mapInfo.forEach(mi => mi.holeAdded(hole.id, geoGroups));
				});
			});
	}

	removeHole(holeId: number, geoItemId: number) {
		this.geoGroupManager.deleteGeoItem(geoItemId).subscribe(() => {
			this.mapInfo.forEach(mi => mi.holeRemoved(holeId));
		});
	}

	addStationArea(stationId: number, loc: { latitude: number; longitude: number }, isNewGeoGroup: boolean) {
		const defaultAreaRadiusInMeters = 100;
		const latitudeDegreesRadius = defaultAreaRadiusInMeters / 1113200;
		const longitudeDegreesRadius = defaultAreaRadiusInMeters / (1113200 * Math.cos(loc.latitude * Math.PI / 180));
		const polygon = [
			latLng({lat: loc.latitude + latitudeDegreesRadius, lng: loc.longitude - longitudeDegreesRadius}),
			latLng({lat: loc.latitude + latitudeDegreesRadius, lng: loc.longitude}),
			latLng({lat: loc.latitude + latitudeDegreesRadius, lng: loc.longitude + longitudeDegreesRadius}),
			latLng({lat: loc.latitude, lng: loc.longitude + longitudeDegreesRadius}),
			latLng({lat: loc.latitude - latitudeDegreesRadius, lng: loc.longitude + longitudeDegreesRadius}),
			latLng({lat: loc.latitude - latitudeDegreesRadius, lng: loc.longitude}),
			latLng({lat: loc.latitude - latitudeDegreesRadius, lng: loc.longitude - longitudeDegreesRadius}),
			latLng({lat: loc.latitude, lng: loc.longitude - longitudeDegreesRadius}),
		].map(ll => ({ longitude: ll.lng, latitude: ll.lat }));

		if (!isNewGeoGroup) {
			this.geoGroupManager.getGeoGroupForStation(this.currentSiteId, stationId, true).subscribe((geoGroup) => {
				const styleSetting = geoGroup?.uiSettingsJson ? JSON.parse(geoGroup.uiSettingsJson) : JSON.stringify(new AreaUiSettings());
				this.geoGroupManager.createGeoItemForStation(this.currentSiteId, stationId,
					{ polygon: polygon, styleSetting: JSON.stringify(styleSetting)})
					.subscribe((geoItem) => {
						this.mapInfo.forEach(mi => mi.stationGeoAreaAdded(stationId, geoItem, styleSetting, polygon, isNewGeoGroup));
					});
			});
		} else {
			const styleSetting = new AreaUiSettings();
			this.geoGroupManager.createGeoItemForStation(this.currentSiteId, stationId,
				{ polygon: polygon, styleSetting: JSON.stringify(new AreaUiSettings())})
				.subscribe((geoItem) => {
					this.mapInfo.forEach(mi => mi.stationGeoAreaAdded(stationId, geoItem, styleSetting, polygon, isNewGeoGroup));
				});
		}
	}

	addArea(siteId: number, areaId: number, holeId: number, loc: { latitude: number; longitude: number }) {
		// Init Area
		// Create an initial polygon
		const defaultAreaRadiusInMeters = 100;
		const latitudeDegreesRadius = defaultAreaRadiusInMeters / 1113200;
		const longitudeDegreesRadius = defaultAreaRadiusInMeters / (1113200 * Math.cos(loc.latitude * Math.PI / 180));
		const polygon = [
			latLng({lat: loc.latitude + latitudeDegreesRadius, lng: loc.longitude - longitudeDegreesRadius}),
			latLng({lat: loc.latitude + latitudeDegreesRadius, lng: loc.longitude}),
			latLng({lat: loc.latitude + latitudeDegreesRadius, lng: loc.longitude + longitudeDegreesRadius}),
			latLng({lat: loc.latitude, lng: loc.longitude + longitudeDegreesRadius}),
			latLng({lat: loc.latitude - latitudeDegreesRadius, lng: loc.longitude + longitudeDegreesRadius}),
			latLng({lat: loc.latitude - latitudeDegreesRadius, lng: loc.longitude}),
			latLng({lat: loc.latitude - latitudeDegreesRadius, lng: loc.longitude - longitudeDegreesRadius}),
			latLng({lat: loc.latitude, lng: loc.longitude - longitudeDegreesRadius}),
		].map(ll => ({ longitude: ll.lng, latitude: ll.lat }));

		this.geoGroupManager.createGeoItem(siteId, holeId, areaId, { polygon: polygon })
			.subscribe(geoItem => {
				// Get the updated geo group list
				this.geoGroupManager.getGeoGroupsForSite(siteId).pipe(take(1)).subscribe(geoGroups => {
					this.mapInfo.forEach(mi => {
						mi.areaAdded(holeId, areaId, geoItem, geoGroups, polygon)
					});
				});
			});

	}

	removeAreaGeoItem(holeId: number, areaId: number, geoItemId: number): void {
		this.geoGroupManager.deleteGeoItem(geoItemId).subscribe(() => {
			this.mapInfo.forEach(mi => mi.areaGeoItemRemoved(holeId, areaId, geoItemId));
		});

	}

	removeStationAreaGeoItem(geoItemId: number): void {
		this.geoGroupManager.deleteGeoItem(geoItemId).subscribe(() => {
			this.mapInfo.forEach(mi => {
				mi.stationGeoAreaItemRemoved(geoItemId);
			});
		});
	}

	updateRasterItem(layer: RasterItem) {
		return this.leafletMgrService.updateRasterItem(layer.id, layer);
	}

	updateKmzItem(layer: KMZItem) {
		return this.leafletMgrService.updateKmzItem(layer.id, layer);
	}

	deleteRasterItem(layer: RasterItem) {
		return this.leafletMgrService.deleteRasterItem(layer.siteId, layer.id);
	}

	deleteKmzItem(layer: KMZItem) {
		return this.leafletMgrService.deleteKmzItem(layer.siteId, layer.id);
	}

	updateRastersZIndex(mapInfo: MapInfoLeaflet, updateDB: boolean) {
		mapInfo.rasterItems.forEach((item: RasterItem, index: number) => {
			item.index = mapInfo.rasterItems.length - index + 1;
			if (item.imageLayer) item.imageLayer.setZIndex(item.index);
		});

		if (updateDB) {
			const rasterArray = mapInfo.rasterItems.map(raster =>
			({
				id: raster.id,
				item: { index: raster.index } as RasterItem
			})
			);

			this.leafletMgrService.updateRasterItemList(rasterArray).subscribe();
		}
	}

	updateVectorsZIndex(mapInfo: MapInfoLeaflet, updateDB: boolean) {

		mapInfo.kmzItems.forEach((item: KMZItem, index: number) => {
			item.index = mapInfo.kmzItems.length - index;
		});

		mapInfo.removeAllVectorLayersFromMap();

		for (let i = mapInfo.kmzItems.length - 1; i > -1; i--) {
			const kmzItem = mapInfo.kmzItems[i];
			if (kmzItem.visible && mapInfo.canShowCustomLayers && mapInfo.layerVisibility.showingVectors
					&& kmzItem.geoJson.features.length > 0) {
				if (!kmzItem.layer) {
					kmzItem.layer = this.createLeafletGeoJSONLayer(kmzItem);
				}

				mapInfo.addLayerToMap(kmzItem.layer, null, true);
			}
		}

		if (updateDB) {
			const kmzArray = mapInfo.kmzItems.map(kmz =>
			({
				id: kmz.id,
				item: { index: kmz.index } as KMZItem
			})
			);

			this.leafletMgrService.updateKmzItemList(kmzArray).subscribe();
		}

	}

	async createDistortableImage(layer: RasterItem) {

		const imageLayer = distortableImageOverlay(this.getLayersURL(layer), {
			mode: 'lock',
			suppressToolbar: true,
			editable: false,
			corners:
				layer.corners && layer.corners.length > 0
					? layer.corners
					: null,
		});

		imageLayer.on('load', () => {
			imageLayer.setOpacity(layer.opacity / 100);
			if (layer.error) {
				layer.error = undefined;
				this.updateRasterItem(layer).subscribe();
			}
		});

		imageLayer.on('error', error => {
			layer.removeFromMap();
			layer.setVisibility(false);
			this.toastService.showToaster(`Failed to load image layer: ${layer.sourceName}`, 5000);
			if (!layer.error) {
				layer.error = 'Error loading image';
				this.updateRasterItem(layer).subscribe();
			}
		});

		return imageLayer;
	}

	getCoordinateSystemsList() {
		return this.leafletMgrService.getCoordinateSystemsList();
	}

	getGeojsonFromShapeFiles(epsg: string, shpFile: File, dbfFile: File, shxFile: File) {
		return this.leafletMgrService.getGeojsonFromShapeFiles(epsg, shpFile, dbfFile, shxFile);
	}

	get currentSiteId() {
		if (this.currentMapInfo == null || this.currentMapInfo.siteId == null)
			return null;

		return this.currentMapInfo.siteId;
	}

	get courseCount() {
		return this.mapInfo.length;
	}

	/**
	 * Redirects to original MapService.initializingGoogleMapsSubject
	 */
	public static get initializingGoogleMapsSubject(): ReplaySubject<void> {
		return MapService.initializingGoogleMapsSubject;
	}

	/**
	 * Redirects to original MapService.googleStriptInitialized
	 */
	public static get googleScriptInitialized() {
		return MapService.googleScriptInitialized;
	}

	/**
	 * Fetches a list of visible geoJsons.
	 * 
	 * geoJSONS are saved to the device when loaded for the first time from the database, so geoJSONs 
	 * can be retrived from the database (if we don't have any saved on the device) or from the client itself 
	 * (thanks to the library LocalForage.)
	 * 
	 * For the layers not visible at the moment of a first time load, they will be properly loaded
	 * and saved to the device on demand when making them visible on the UI
	 * 
	 * @param siteId The ID of the current site
	 * @param mapPref Map preference ID string: `mapPref_${siteID}_${uniqueID}`
	 * @param activeVectorLayers The list of vector layers with their visibility status (visible/hidden). This can be
	 * undefined if we're loading the map for a new course or from a new map widget
	 * @returns An observable of a list of vector layers
	 */
	async getActiveGeoJsons(siteId: number, mapPref: string, activeVectorLayers?: {'id'?: boolean}) {

		const sites = await this.sitesGeoJSONsStore.getItem<number[]>('sites') || [];

		if (sites.findIndex(sid => sid == siteId) > -1) {
			// We have downladed geoJSONs for this site, use those

			const geoJsons = [];
			await this.geoJSONStore.iterate((value, key) => {
				
				// load the geoJSON data only if we don't have a visibility preference list (new course/map widget)
				// or the vector layer is visible: `activeVectorLayers[key] == true`
				if (activeVectorLayers == null || activeVectorLayers[key]) {
					// * Using `activeVectorLayers == null` here instead of an IF outside of the iterate function because 
					//   there is no "getAllKeysAndValues()" function and we would need multiple calls or the same iterate
					//   function (without filter) to get all stored geoJSONs in case there is no preference list
					//   (Example: 1. Get all keys 2. Get geoJSONs one by one)
					 
					geoJsons.push({ id: +key, geoJson: value });
				}
			});
			
			return of(geoJsons as KMZItem[]);

		} else {
			// We don't have any downloaded geoJSONs, fetch visible layers from the database
			// and save the geoJSONs to the device

			const observable = MapLeafletService.getActiveGeoJsonObservables[mapPref];
			if (observable != null) return observable;
	
			MapLeafletService.getActiveGeoJsonObservables[mapPref] = this.leafletMgrService
				.getActiveKmzItems(siteId, mapPref)
				.pipe(
					share(),
					tap((vectorItems) => {
						delete MapLeafletService.getActiveGeoJsonObservables[mapPref];
						// Save loaded layers to the device
						vectorItems.forEach(item => {
							this.geoJSONStore.setItem(item.id.toString(), item.geoJson);
						});

						sites.push(siteId);
						this.sitesGeoJSONsStore.setItem('sites', sites);
					})
				);
	
			return MapLeafletService.getActiveGeoJsonObservables[mapPref];
		}
	}

	/**
	 * Get one vector item, including its geoJSON.
	 * 
	 * The geoJSON is saved to the device using LocalForage
	 * 
	 * @param vectorItemId The vector item's ID
	 * @returns An observable of the vector item
	 */
	getKmzItem(vectorItemId: number): Observable<KMZItem> {
		const observable = this.getKmzItemObservables[vectorItemId];
		if (observable != null) return observable;

		this.getKmzItemObservables[vectorItemId] = this.leafletMgrService
			.getKmzItemById(vectorItemId)
			.pipe(
				share(),
				tap((vectorItem) => {
					delete this.getKmzItemObservables[vectorItemId];
					this.geoJSONStore.setItem(vectorItem.id.toString(), vectorItem.geoJson);
				}
				)
			);

		return this.getKmzItemObservables[vectorItemId];
	}

	/**
	 * Returns a list of all vector items (without the geoJSON data) in the specidfied site
	 * 
	 * @param siteId The ID of the site that the vector layers belong to
	 * @param bypassCache Whether the cache should be bypassed
	 * @returns An observable of a list of vector items (without the geoJSON data)
	 */
	getKmzItemList(siteId: number, bypassCache = false): Observable<KMZItem[]> {
		const observable = this.getKmzItemsObservables[siteId];
		if (observable != null) return observable;

		this.getKmzItemsObservables[siteId] = this.leafletMgrService
			.getKmzItemsForSite(siteId, bypassCache)
			.pipe(
				share(),
				tap(
					() =>
						delete this.getKmzItemsObservables[
							siteId
						]
				)
			);

		return this.getKmzItemsObservables[siteId];
	}

	/**
	 * Fills the geoJSON property of a vector item, if it doesn't have it already
	 * 
	 */
	async loadItemGeoJSON(item: KMZItem) {

		// This can be written using `then()`, `catch()` and `finally()` but,
		// since the caller needs to know when this whole work is done
		// it is preferred to use `await` and have try..catch..finally
		try {
			item.loadingKMZ = true;
			if (item.geoJson.features.length === 0) {
				item.geoJson = await this.getVectorGeoJSON(item.id);
			}
		} catch (error) {
			item.error = error.message
			item.setVisibility(false);
		} finally {
			item.loadingKMZ = false;
		}
	}
	
	/**
	 * Returns an observable of the geoJSON of a vector item
	 * 
	 * @param vectorItemId The vector item's ID
	 */
	async getVectorGeoJSON(vectorItemId: number) {
		
		let geoJSON: FeatureCollection;

		geoJSON = await this.geoJSONStore.getItem(vectorItemId.toString());

		if (geoJSON == null) {
			// We didn't find the geoJSON on the device. Fetch it from the DB
			geoJSON = (await firstValueFrom(this.getKmzItem(vectorItemId))).geoJson;
		}

		return geoJSON;
	}

	/**
	 * Removes geoJSONs from the device based on the vector items Ids
	 * 
	 * @param itemsId List of vector items Id whose geoJSON is to be removed from the device
	 */
	removeGeoJSONs(itemsId: string[]) {
		if (itemsId.length > 0) {
			itemsId.forEach(value => {
				this.geoJSONStore.removeItem(value);
			});
		}
	}

	createLeafletGeoJSONLayer(kmzItem: KMZItem) {
		return geoJSON<GeoJSONProperties>(kmzItem.geoJson, {
			style: function (feature) {
				const properties = {...kmzItem.properties};
				properties.opacity /= 100;
				properties.fillOpacity /= 100;
				return properties;
			},
			onEachFeature: function (feature, layer) {
				if (feature.properties?.tooltip) {
					if (kmzItem.properties.showTooltips) {
						const tooltip = newTooltip({
							permanent: true,
							direction: 'right',
							className: 'leafletMarkerTooltip'
						}).setContent(feature.properties.tooltip);
						layer.bindTooltip(tooltip);
					} else {
						layer.unbindTooltip();
					}
				}
			},
			pointToLayer: function (feature, latlng) {
				return circleMarker(latlng, {
					radius: 5,
					fillColor: (kmzItem.properties.color ? kmzItem.properties.color : '#000'),
					fillOpacity: 0.5,
					color: (kmzItem.properties.color ? kmzItem.properties.color : '#FFF'),
					weight: 2,
					opacity: 1
				});
			}
		});
	}


	kmzToGeojson(kmzUrl: string) : Promise<{ features, properties }>   {
		const kmz = kmzLayer();
		return new Promise(
			async (resolve, reject) => {
				kmz.on('load', (e: any) => {
					const layerGeoJSONs = [];
					const kmzProperties: KMZItemProperties = {
						stroke_style: 'solid',
						showTooltips: false,
						matchColors: false
					};

					e.layer.eachLayer((layer: any) => {

						Object.assign(kmzProperties, {
							color: layer.feature.properties.stroke,
							opacity: layer.feature.properties['stroke-opacity'] * 100,
							weight: layer.feature.properties['stroke-width'] * 100,
							fillColor: layer.feature.properties.fill,
							fillOpacity: layer.feature.properties['fill-opacity'] * 100,
						});						

						let tooltip = '';
						if (layer.feature && layer.feature.properties) {
							if (layer.feature.properties.name) {
								tooltip = layer.feature.properties.name;
							}
							delete layer.feature.properties;
						}

						const properties: GeoJSONProperties = {};
						
						if (tooltip.trim() !== '') {
							properties.	tooltip =  tooltip.trim();
						}

						if (Object.keys(properties).length > 0) {
							layer.feature.properties = properties;
						}						
						layer.options.fillColor = 'transparent';
						layer.options.fillOpacity = '0';
						const newGeoJSON = layer.toGeoJSON();
						
						// Add `type` property if it doesn't have it.
						// Only case noticed so far is a feature object not having 'Feature' type. 
						// A feature not having a type property breaks leaflet's geoJSON parsing
						if (!newGeoJSON.type && newGeoJSON.geometry) {
							newGeoJSON.type = 'Feature';
						}
						layerGeoJSONs.push(newGeoJSON);
					});

					this.validateKMZProperties(kmzProperties);

					resolve({ features: layerGeoJSONs, properties: kmzProperties });
				});

				kmz.on('error', (error: any) => {
					reject(error);
				});

				try {
					await kmz.load(kmzUrl);
				} catch (err) {
					reject(err);
				}

			}
		);
	}

	/** Make sure required properties are present and have valid values */
	private validateKMZProperties(properties: KMZItemProperties) {

		if (!properties.color) {
			properties.color = '#000000';
		}

		if (!properties.opacity) {
			properties.opacity = 100;
		}

		if (!properties.weight) {
			properties.weight = 3;
		} else if (properties.weight > 12) {
			properties.weight = 12;
		}

		if (!properties.fillColor) {
			properties.fillColor = '#000000';
		}

		if (!properties.fillOpacity) {
			properties.fillOpacity = 50;
		}

		properties.stroke_style = 'solid';
		properties.matchColors = false;

	}

	/**
	 * Returns a list of all raster items in the specified site
	 * 
	 * @param siteId The ID of the site that the raster layers belong to
	 * @param bypassCache Wether the cache should be bypassed
	 * @returns An observable fo a list of raster items
	 */
	getRasterItems(siteId: number, bypassCache = false): Observable<RasterItem[]> {
		const observable =
			this.getRasterItemsObservables[siteId];
		if (observable != null) return observable;

		this.getRasterItemsObservables[siteId] =
			this.leafletMgrService.getRasterItemsForSite(siteId, bypassCache).pipe(
				share(),
				tap(
					() =>
						delete this.getRasterItemsObservables[
							siteId
						]
				)
			);

		return this.getRasterItemsObservables[siteId];
	}

	getMarkerIcon() {
		return icon({
			iconUrl: '../../../assets/images/marker-icon.png',
			shadowUrl: '../../../assets/images/marker-icon.png',
			iconSize:     [35, 35],
			shadowSize:   [35, 35],
			iconAnchor:   [17, 34],
		});
	}

	/**
	 * Process the image's URL so that it works on any address. 
	 * Saves the new address on the layer itself so the image works when editing it
	 */
	private getLayersURL(layer: RasterItem) {
		// The serverAddress here is required when imageUrl is a relative path, 
		// but is ignored when imageUrl is an absolute path
		const path = new URL(layer.imageUrl, this.serverAddress).pathname;
		layer.imageUrl = new URL(path, this.serverAddress).toString();
		return layer.imageUrl;
	}
	
}

declare module 'leaflet' {
	function DragAction();
	function DistortAction();
	function ScaleAction();
	function RotateAction();
	function ScaleAction();
	function OpacityAction();
	namespace EditAction {
		function extend(ops: any);
		namespace prototype {
			namespace initialize {
				function call(arg1: any, arg2: any, arg3: any, arg4: any);
			}
		}
	}
	namespace DomEvent {
		function on(arg1, arg2, callback);
	}
	function distortableImageOverlay(image, options);
}
export class ProgramIdentifier {

	constructor(json: any = null) {

		if (json) {
			Object.assign(this, json);
		}
	}

	/**
	 * The Program ID of a program which references the station. NOTE: This is not a golf Program, but a golf Schedule or
	 * a commercial Program.
	 */
	programId: number;

	/**
	 * The Program Name of a program which references the station. This would be used for display purposes after a search.
	 */
	programName: string;

	/**
	 * IsEnabled flag from the Program referencing the station.
	 */
	programIsEnabled: boolean;

	/**
	 * If known, the ProgramGroup (golf Program) in which the indicated ProgramId above is found. Null otherwise.
	 */
	programGroupId: number;

	/**
	 * If known, the ProgramGroup name which could be used for search results display. Null otherwise.
	 */
	programGroupName: string;

	/**
	 * The IsEnabled of a program wich references the station. This would be used for display purposes after a search.
	 */
	programGroupIsEnabled: boolean;

	/**
	 * UI-only property set, if needed, to "Enabled" or "Disabled", etc. for display. This property is NOT returned by
	 * the API.
	 */
	status?: string;
}

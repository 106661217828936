<button class="reportButton"
	mat-raised-button
	type="button"
	color="accent"
	[disabled]="modalSubmitting || disabled"
	(click)="reportButtonClicked.emit()"
	data-qaid="report-button"
>
	{{ label }}
</button>

/* eslint-disable @typescript-eslint/member-ordering */
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Area } from '../../api/areas/models/area.model';
import { AreaManagerService } from '../../api/areas/area-manager.service';
import { AuthManagerService } from '../../api/auth/auth-manager-service';
import { BroadcastService } from './broadcast.service';
import { CompanyManagerService } from '../../api/companies/company-manager.service';
import { ControllerGetLogsState } from '../../api/signalR/controller-get-logs-state.model';
import { ControllerManagerService } from '../../api/controllers/controller-manager.service';
import { CultureSettingsManagerService } from '../../api/culture-settings/culture-settings-manager.service';
import { DeviceManagerService } from './device-manager.service';
import { DiagnosticLogManagerService } from '../../api/diagnostic-log/diagnostic-log-manager.service';
import { DOCUMENT } from '@angular/common';
import { EnvironmentService } from './environment.service';
import { EventLogManagerService } from '../../api/event-logs/event-log-manager.service';
import { GeoGroupManagerService } from '../../api/regions/geo-group-manager.service';
import { GeoItem } from '../../api/regions/models/geo-item.model';
import { ManualControlManagerService } from '../../api/manual-control/manual-control-manager.service';
import { ManualControlState } from '../../api/manual-control/models/manual-control-state.model';
import { ManualOpsManagerService } from '../../api/manual-ops/manual-ops-manager.service';
import { MapInfo } from '../models/map-info.model';
import { MessageBoxService } from './message-box.service';
import { RbEnums } from '../enumerations/_rb.enums';
import { SiteManagerService } from '../../api/sites/site-manager.service';
import { Station } from '../../api/stations/models/station.model';
import { StationListItem } from '../../api/stations/models/station-list-item.model';
import { StationManagerService } from '../../api/stations/station-manager.service';
import { StationsListChange } from '../../api/stations/models/stations-list-change.model';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UiSettingsService } from '../../api/ui-settings/ui-settings.service';
import { VoltageDiagnosticManagerService } from '../../api/voltage-diagnostic/voltage-diagnostic-manager.service';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class MapService implements OnDestroy {

	// Subjects
	userLocationLookupFailed = new Subject();

	static renderer: Renderer2;

	// private courseAreas: Area[] = [];
	private mapInfo: MapInfo[] = [];

	/**
	 * watchPositionId is the result from a watchPosition Location API call, or null if no call has been made or no
	 * watch is active.
	 */
	private watchPositionId: number;

	/**
	 * watchPositionStart is the Date (formatted as local time) at which the user position watchPosition() command to
	 * the Location API was most-recently sent. There are debugging situations where this might be valuable. In particular
	 * iOS seems to require a *NEW* watchPosition() call whenever the device is resumed from sleep, brought to the front
	 * after being sent to the back, etc. If we miss a case where this should occur, noting an out-of-date
	 * watchPositionStart value could be reveal it.
	 */
	private watchPositionStart: string;

	private url = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyAScTFPStgZvcf5r0zKCCBsJgzQ8Gk54cQ';

	/**
	 * showTrackingData is a flag controlling continuous GPS data display by the service. If we're having a "GPS accuracy"
	 * issue of some kind, switching this on can help us find it. The user can toggle it by performing the undocumented
	 * double-click-the-FindMe-tool operation, so it's always available.
	 */
	public showTrackingData = false;

	/**
	 * trackingData is the data to be shown when showTrackingData is true. It basically contains this information:
	 * localTimeOfDay per the device clock
	 * gpsTimestamp of the most-recent data received (time only)
	 * gpsLongitude rounded to 6 decimal places
	 * gpsLatitude rounded to 6 decimal places
	 * gpsAccuracy in meters rounded to 2 decimal places
	 */
	public trackingData = '';

	/**
	 * initializingGoogleMapsSubject is a ReplaySubject which clients can subscribe waiting for the maps API to be fully
	 * initialized. For example, you might do this before trying to call some method performing geocoding, since that won't
	 * work until the API is initialized.
	 */
	public static initializingGoogleMapsSubject: ReplaySubject<void>;

	/**
	 * googleScriptInitialized provides a non-async method checking whether the maps API has been loaded and initialized.
	 * You might use this deciding whether to act on some event or skip it, rather than wait for initialization on
	 * initializingGoogleMapsSubject.
	 */
	public static googleScriptInitialized = false;

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	public get Url() {
		return this.url;
	}

	constructor(rendererFactory: RendererFactory2,
				public areaManager: AreaManagerService,
				public authManager: AuthManagerService,
				public broadcastService: BroadcastService,
				public companyManager: CompanyManagerService,
				private cultureSettingsManager: CultureSettingsManagerService,
				public controllerManager: ControllerManagerService,
				public deviceManager: DeviceManagerService,
				public diagnosticLogManagerService: DiagnosticLogManagerService,
				public env: EnvironmentService,
				public geoGroupManager: GeoGroupManagerService,
				public manualOpsManager: ManualOpsManagerService,
				private manualControlManager: ManualControlManagerService,
				public messageBoxService: MessageBoxService,
				public siteManager: SiteManagerService,
				public stationManager: StationManagerService,
				public eventLogManager: EventLogManagerService,
				public translate: TranslateService,
				public uiSettingsService: UiSettingsService,
				public voltageDiagnosticManager: VoltageDiagnosticManagerService,
				@Inject(DOCUMENT) private document: Document,
				) {

		// We don't want several renderers, so we save this one as a static property.
		MapService.renderer = rendererFactory.createRenderer(null, null);

		this.stationManager.stationsListChange
			.pipe(untilDestroyed(this))
			.subscribe((change: StationsListChange) => this.mapInfo.forEach(mi => mi.stationsListChanged(change)));

		// this.stationManager.stationsAdded.pipe(untilDestroyed(this)).subscribe(() => this.mapInfo.forEach(mi => mi.stationsAdded()));
		// this.stationManager.stationsDeleted.pipe(untilDestroyed(this)).subscribe(() => this.mapInfo.forEach(mi => mi.stationsDeleted()));
		// this.siteManager.siteAddressChange.pipe(untilDestroyed(this))
		// 	.subscribe(id => this.mapInfo.filter(mi => mi.siteId === id).forEach(mi => mi.siteAddressChange()));

		this.areaManager.areaChange.pipe(untilDestroyed(this)).subscribe((area: Area) => this.mapInfo.forEach(mi => mi.areaChanged(area)));
		// this.systemStatusService.golfStationStatusChange.pipe(untilDestroyed(this))
		// 	.subscribe((change: StationStatusChange) => this.reloadStation(change.stationId));
		// this.companyManager.companyStatusChange.pipe(untilDestroyed(this)).subscribe(() => this.mapInfo.forEach(mi => mi.companyStatusChanged()));
		this.broadcastService.diagnosticDataReceived.pipe(untilDestroyed(this)).subscribe(data => {
			this.mapInfo.forEach(mi => mi.diagnosticDataReceived(data));
		});
		this.broadcastService.controllerCollectionChange.pipe(untilDestroyed(this))
			.subscribe(data => this.mapInfo.forEach(mi => mi.controllerCollectionChanged(data)));
		this.broadcastService.eventLogsStateChange.pipe(untilDestroyed(this))
			.subscribe((state: ControllerGetLogsState) => this.mapInfo.forEach(mi => mi.eventLogsStateChanged(state)));
		this.siteManager.sitesUpdate.pipe(untilDestroyed(this)).subscribe(info => this.mapInfo.forEach(mi => mi.sitesUpdated(info.data)));
		this.cultureSettingsManager.cultureSettingsChange.pipe(untilDestroyed(this))
			.subscribe(() => this.mapInfo.forEach(mi => mi.cultureSettingsChanged()));
		this.manualControlManager.manualControlStateChange.pipe(untilDestroyed(this))
			.subscribe((state: ManualControlState) => this.mapInfo.forEach(mi => mi.manualControlStateChanged(state.controllerId, state)));
		this.broadcastService.connectionStopped.pipe(untilDestroyed(this)).subscribe(id => this.mapInfo.forEach(mi => mi.manualControlStateChanged(id, null)));

		// Initialize the map stuff as soon as one instance of us is created. We use a static property, initializingGoogleMapsSubject,
		// assuring that we don't repeatedly initialize. We don't do anything when initialization is complete; we just need to subscribe
		// to make the operation happen, if we are the first MapService instance.
		this.initializeMaps(this.url).pipe(untilDestroyed(this)).subscribe(() => {});

		// We need to set up to restart this position tracking when the browser visibility changes. The problem is primarily on iOS
		// (of course, where Apple is always right). In that scenario, when the iPad goes to sleep and is later resumed or the browser
		// is sent to the back and restored to the front, the position data is never received again, or has very low accuracy. The
		// intent of this fix is to detect those changes and make sure we restart high-accuracy location polling when Safari comes
		// to the front again. This likely also applies to Chrome, at least on iOS, FYI.
		this.broadcastService.initializeVisibilityChangeListener();
		this.broadcastService.visibilityChange
			.pipe(untilDestroyed(this))
			.subscribe((notification) => {
				if (notification.hidden) {
					// Clear the watch. It doesn't work anyway.
					this.trackingData = 'Going to sleep?';
					this.stopMonitoringUserLocation();
				} else {
					// The document is back in front. Regenerate the position requests. Since (I presume) we might get several of these
					// messages in a row and we really want the last one to be effective, we stop before starting in each case. If we're
					// already stopped, this does nothing.
					this.trackingData = 'Resuming from sleep?';
					this.stopMonitoringUserLocation();
					this.monitorUserLocation();
				}
			});
	}

	/** Implemented to support untilDestroyed() */
	ngOnDestroy(): void {
		this.stopMonitoringUserLocation();
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	// This is the main function to re-parent the map to a new UI div and trigger loading of the appropriate site info.
	// It will fire a siteDataLoaded event when the loading is complete to allow users of this service to avoid loading the data themselves.
	getMap(siteId: number, uniqueId: number, parentDiv: any, areItemsMovable: boolean = false, specialZoom: boolean = true): MapInfo {
		let mi = this.mapInfo.find(info => info.siteId === siteId && info.uniqueId === uniqueId);
		if (mi == null)	{
			mi = new MapInfo(parentDiv, siteId, uniqueId, this, specialZoom);
			this.mapInfo.push(mi);
		} else {
			mi.placeInParentDiv(parentDiv);
			mi.instanceReused();
		}
		mi.areItemsMovable = areItemsMovable;

		this.monitorUserLocation();
		return mi;
	}

	mapRemoved(siteId: number, uniqueId: number): void {
		this.mapInfo = this.mapInfo.filter(info => info.siteId !== siteId || info.uniqueId !== uniqueId);
	}

	// zoomToUserLocation(radiusInMeters: number) {
	// 	this.zoomRadiusInMeters = radiusInMeters;
	// 	this.monitorUserLocation();
	// }

	// =========================================================================================================================================================
	// Map initialization
	// =========================================================================================================================================================

	initializeMaps(url: string): Observable<void> {

		if (MapService.initializingGoogleMapsSubject != null) {
			return MapService.initializingGoogleMapsSubject;
		}

		// Create static ReplaySubject that async code can subscribe waiting for initialization to complete before performing some
		// action.
		MapService.initializingGoogleMapsSubject = new ReplaySubject<void>();

		// This little structure basically just creates a new thread performing the initialization. The initializingGoogleMapsSubject
		// and googleScriptInitialized properties will be set when the process completes.
		new Observable(observer => {
			const script = MapService.renderer.createElement('script');
			script.type = 'text/javascript';
			script.src = url;
			script.text = ``;
			script.onload = () => {
				// Script loaded. Set both the ReplaySubject and the flag indicating that we're initialized.
				MapService.googleScriptInitialized = true;
				MapService.initializingGoogleMapsSubject.next(null);
				observer.next();
				observer.complete();
			};
			script.onerror = () => {
				MapService.googleScriptInitialized = false;
				MapService.initializingGoogleMapsSubject.error({ message: 'Google Maps service was not initialized' });
				observer.next();
				observer.complete();
				MapService.initializingGoogleMapsSubject = null;
			};
			// script.onerror = reject;
			MapService.renderer.appendChild(this.document.head, script);
		}).pipe(take(1)).subscribe(() => {});

		// Return Subject on which caller can wait for initialization complete.
		return MapService.initializingGoogleMapsSubject;
	}

	// =========================================================================================================================================================
	// Geocoding
	// =========================================================================================================================================================

	/**
	 * LookupAddressLatLng is a public utility method which can be used to retrieve some geo-coding information from
	 * an address. The result is ONLY provided in the callback, so no Observable interface, etc.
	 * RB-9518
	 * @param address - string address of the location to geocode
	 * @param city - string city of the location to geocode
	 * @param state - string state of the location to geocode
	 * @param zip - string zip code/postal code of the location to geocode. Use this only in USA for reliable
	 * results
	 * @param callback - (result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) to which the result
	 * is passed
	 */
	public static LookupAddressLatLng(address: string, city: string, state: string, zip: string, country: string,
		callback: (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => void) {
		if (address == null) address = '';
		if (city == null) city = '';
		if (state == null) state = '';
		if (zip == null) zip = '';
		if (country == null) country = '';
		if (typeof google === 'undefined') return;
		const geoCoder = new google.maps.Geocoder();
		geoCoder.geocode({
			address: `${address} ${city} ${state} ${zip} ${country}`
		}, callback);
	}

	/**
	 * LookupAddressLatLng is a public utility method which can be used to retrieve some geo-coding information from
	 * an address. The result is ONLY provided in the callback, so no Observable interface, etc.
	 * RB-9518
	 * @param address - string address of the location to geocode
	 * @param city - string city of the location to geocode
	 * @param state - string state of the location to geocode
	 * @param zip - string zip code/postal code of the location to geocode. Use this only in USA for reliable
	 * results
	 * @param callback - (result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) to which the result
	 * is passed
	 */
	public LookupAddressLatLng(address: string, city: string, state: string, zip: string, country: string,
		callback: (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => void) {
		// This method just calls out to the static method. It provides, however, a "use" for a mapService instance
		// that a client might need to create.
		MapService.LookupAddressLatLng(address, city, state, zip, country, callback);
	}

	// =========================================================================================================================================================
	// User location
	// =========================================================================================================================================================
	private monitorUserLocation() {
		if (this.watchPositionId != null) {
			return;
		}

		if (!navigator.geolocation) {
			this.userLocationLookupFailed.next(null);
		}

		// Crank up the positional accuracy of location data.
		const options: PositionOptions = {
			enableHighAccuracy: true,

			// RB-9003: Adjust the maximum age of the positional fix. No idea what the "right" value is, but this is a
			// reasonable starting point for tracking a position. 0 ==> always get the latest position, never using
			// cached data.
			maximumAge: 2000,	// ms
		};

		const self = this;
		this.watchPositionStart = new Date().toLocaleTimeString();	// Save when we made this call.

		// We could use getCurrentPosition, but users will expect a nice tracking experience. WatchPosition seems better
		// for that.
		this.watchPositionId = navigator.geolocation.watchPosition(function(position) {
			// We got an update. Record in a toast message when and what and how many mapInfos we updated, if showTrackingData
			// is set.
			self.trackingData = self.translate.instant('STRINGS.MAP_SPECIAL_INFO_FORMAT',
				{
					currentDateTime: new Date().toLocaleTimeString(),
					gpsTimestamp: new Date(position.timestamp).toLocaleTimeString(),
					gpsLongitude: position.coords.latitude.toFixed(6),
					gpsLatitude: position.coords.longitude.toFixed(6),
					gpsAccuracyM: position.coords.accuracy.toFixed(2),
					watchPositionStart: self.watchPositionStart,
				});

			self.mapInfo.forEach(mi => mi.userLocationUpdated(position));
		}, function(err) {
			self.userLocationLookupFailed.next(null);

			if (self.showTrackingData) {
				self.trackingData = self.translate.instant('STRINGS.MAP_SPECIAL_INFO_FORMAT_ERROR',
				{
					currentDateTime: new Date().toLocaleTimeString(),
					errorCode: err.code,
					errorMessage: err.message,
				});
			}
		}, options);
	}

	private stopMonitoringUserLocation() {
		// Turn off map tracking, if enabled.
		if (this.watchPositionId != null) {
			// Show informational message for debugging.
			this.trackingData = this.translate.instant('STRINGS.MAP_SPECIAL_INFO_FORMAT_STOPPING',
				{
					currentDateTime: new Date().toLocaleTimeString(),
				});

			if (navigator.geolocation) {
				navigator.geolocation.clearWatch(this.watchPositionId);
			}

			// Clear the watch Id in all cases so we don't get confused about whether we're tracking or not.
			this.watchPositionId = null;
		}
	}

	/**
	 * Method called by MapInfo when the user performs some special action enabling/disabling special location tracking.
	 * We use this to turn showTrackingData ON/OFF.
	 */
	public monitorUserLocationSpecialActivated() {
		this.showTrackingData = !this.showTrackingData;

		if (this.showTrackingData) {
			// Show something so that, even if we aren't getting updates very fast there's feedback that the feature was
			// activated.
			this.trackingData = this.translate.instant('STRINGS.MAP_SPECIAL_INFO_FORMAT_WAITING',
				{
					currentDateTime: new Date().toLocaleTimeString(),
				});

			// Ask for location updates, if not already doing that.
			this.monitorUserLocation();
		}
	}

	// // noinspection JSMethodCanBeStatic
	// private getTextWidth(text: string, font: string): number {
	// 	const canvas = document.createElement('canvas');
	// 	const ctx = canvas.getContext('2d');
	// 	ctx.font = font;
	// 	return ctx.measureText(text).width;
	// }

	// private areaImage = {
	// 	url: this.env.rbcc_ui + '/assets/images/area_background.png',
	// 	size: new google.maps.Size(this.areaMarkerInfo.width, this.areaMarkerInfo.height),
	// 	origin: new google.maps.Point(0, 0),
	// 	anchor: new google.maps.Point(this.areaMarkerInfo.mapIconHotspotX, this.areaMarkerInfo.mapIconHotspotY),
	// };

	contextMenuDisabled(contextMenuInfo: any, option: any): boolean {
		return (option.value === RbEnums.Map.ControllerContextMenu.Sync &&
			contextMenuInfo.controller.syncState === RbEnums.Common.ControllerSyncState.Syncing) ||
			(option.value === RbEnums.Map.ControllerContextMenu.ReverseSync &&
				contextMenuInfo.controller.syncState === RbEnums.Common.ControllerSyncState.ReverseSyncing) ||
			(option.value === RbEnums.Map.ControllerContextMenu.Logs &&
				(contextMenuInfo.controller.gettingLogs || contextMenuInfo.controller.firmwareUpdateProgress !== null)) ||
			(option.value === RbEnums.Map.ControllerContextMenu.Connect
				&& (contextMenuInfo.controller.isConnecting
					|| contextMenuInfo.controller.isConnected || contextMenuInfo.controller.firmwareUpdateProgress !== null));
	}

	contextMenuClass(contextMenuInfo: any, option: any): string {
		let menuClass = option.icon;
		switch (option.value) {
			case RbEnums.Map.ControllerContextMenu.Sync:
			case RbEnums.Map.ControllerContextMenu.ReverseSync:
				switch (contextMenuInfo.controller.syncState) {
					case RbEnums.Common.ControllerSyncState.Syncing:
						menuClass += ' syncing';
						break;
					case RbEnums.Common.ControllerSyncState.ReverseSyncing:
						menuClass += ' reverse-syncing';
						break;
					case RbEnums.Common.ControllerSyncState.Synchronized:
						menuClass += ' synced';
						break;
					case RbEnums.Common.ControllerSyncState.NotSynchronized:
					case RbEnums.Common.ControllerSyncState.HasDifferences:
						menuClass += ' not-synced';
						break;
					case RbEnums.Common.ControllerSyncState.Incomplete:
						menuClass += ' incomplete';
							break;
				}
				break;
			case RbEnums.Map.ControllerContextMenu.Connect:
				if (contextMenuInfo.controller.isConnected) menuClass += ' connected';
		}

		return menuClass;
	}

	/**
	 * updateStationLatLong sends a station update to the API, setting the database entry for the indicated
	 * station or station list item's latitude and longitude to the values in the station parameter. No
	 * follow-up operations on the station are performed.
	 */
	updateStationLatLong(station: Station | StationListItem) {
		this.stationManager.updateStations([station.id], { latitude: station.latitude, longitude: station.longitude })
			.subscribe(() => {
				// RB-8564: We don't need to reload the station; we have the update in-memory.
				// Since we also know that updateStation is only called when the lat/long has changed and nothing
				// else, we don't have to reload the station name, etc. into the mapInfo collection.
			});
	}

	holeDragged(geoItem: GeoItem, point: { latitude: number, longitude: number}): void {
		this.geoGroupManager.updateGeoItem({ id: geoItem.id, point }).subscribe(() => {
			geoItem.point.latitude = point.latitude;
			geoItem.point.longitude = point.longitude;
			this.mapInfo.forEach(mi => mi.geoItemUpdated(geoItem));
		});
	}

	updateAreaGeoItem(areaId: number, geoItemId: number, polygon: google.maps.LatLng[]) {
		const newPoints = polygon.map(latlng => ({ latitude: latlng.lat(), longitude: latlng.lng() }));
		this.geoGroupManager.updateGeoItem({ id: geoItemId, polygon: newPoints }).subscribe(() => {
			this.mapInfo.forEach(mi => mi.areaGeoItemUpdated(areaId, geoItemId, polygon));
		});
	}

	updateController(id: number, loc: { latitude: any; longitude: any }): void {
		this.controllerManager.updateControllers([ id ], loc).subscribe(() => this.mapInfo.forEach(mi => mi.controllerLocationUpdated(id, loc)));
	}

	addHole(hole: Area, loc: {latitude: number; longitude: number}): void {
		this.geoGroupManager.createGeoItem(hole.siteId, hole.id, null, { point: { longitude: loc.longitude, latitude: loc.latitude } })
			.subscribe(() => {

				// Get the updated geo group list
				this.geoGroupManager.getGeoGroupsForSite(hole.siteId).pipe(take(1)).subscribe(geoGroups => {
					this.mapInfo.forEach(mi => mi.holeAdded(hole.id, geoGroups));
				});
			});
	}

	removeHole(holeId: number, geoItemId: number) {
		this.geoGroupManager.deleteGeoItem(geoItemId).subscribe(() => {
			this.mapInfo.forEach(mi => mi.holeRemoved(holeId));
		});
	}

	addArea(siteId: number, areaId: number, holeId: number, loc: { latitude: number; longitude: number }) {

		// Create an initial polygon
		const defaultAreaRadiusInMeters = 100;
		const latitudeDegreesRadius = defaultAreaRadiusInMeters / 1113200;
		const longitudeDegreesRadius = defaultAreaRadiusInMeters / (1113200 * Math.cos(loc.latitude * Math.PI / 180));
		const polygon = [
			new google.maps.LatLng({lat: loc.latitude + latitudeDegreesRadius, lng: loc.longitude - longitudeDegreesRadius}),
			new google.maps.LatLng({lat: loc.latitude + latitudeDegreesRadius, lng: loc.longitude}),
			new google.maps.LatLng({lat: loc.latitude + latitudeDegreesRadius, lng: loc.longitude + longitudeDegreesRadius}),
			new google.maps.LatLng({lat: loc.latitude, lng: loc.longitude + longitudeDegreesRadius}),
			new google.maps.LatLng({lat: loc.latitude - latitudeDegreesRadius, lng: loc.longitude + longitudeDegreesRadius}),
			new google.maps.LatLng({lat: loc.latitude - latitudeDegreesRadius, lng: loc.longitude}),
			new google.maps.LatLng({lat: loc.latitude - latitudeDegreesRadius, lng: loc.longitude - longitudeDegreesRadius}),
			new google.maps.LatLng({lat: loc.latitude, lng: loc.longitude - longitudeDegreesRadius}),
		].map(ll => ({ longitude: ll.lng(), latitude: ll.lat() }));

		this.geoGroupManager.createGeoItem(siteId, holeId, areaId, { polygon: polygon })
			.subscribe(geoItem => {

				// Get the updated geo group list
				this.geoGroupManager.getGeoGroupsForSite(siteId).pipe(take(1)).subscribe(geoGroups => {

					this.mapInfo.forEach(mi => mi.areaAdded(holeId, areaId, geoItem, geoGroups, polygon));
				});
			});

	}

	removeAreaGeoItem(holeId: number, areaId: number, geoItemId: number): void {
		this.geoGroupManager.deleteGeoItem(geoItemId).subscribe(() => {
			this.mapInfo.forEach(mi => mi.areaGeoItemRemoved(holeId, areaId, geoItemId));
		});

	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppDateAdapter } from '../../custom/date-adapter';
import { ReportSiteListItem } from './models/report-site-list.model';

@Component({
	selector: 'rb-custom-report-site-list',
	templateUrl: './custom-report-site-list.component.html',
	styleUrls: ['./custom-report-site-list.component.scss']
})
export class CustomReportSiteListComponent implements OnInit {

	selectedSite: ReportSiteListItem;
	startDateString = '';
	endDateString = '';

	@Input() reportName = '';

	@Input() set startDate(value: Date) {
		this.startDateString = value == null ? '' : this.appDateAdapter.format(value);
	}

	@Input() set endDate(value: Date) {
		this.endDateString = value == null ? '' : this.appDateAdapter.format(value);
	}

	private _sitesList: ReportSiteListItem[] = [];
	@Input() set sitesList(value: ReportSiteListItem[]) {
		this._sitesList = value || [];
		this.selectedSite = this._sitesList.length === 0 ? null : this._sitesList[0];
	}

	get sitesList(): ReportSiteListItem[] { return this._sitesList; }

	@Output() siteSelected = new EventEmitter<ReportSiteListItem>();

	// =========================================================================================================================================================
	// C'tor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(public appDateAdapter: AppDateAdapter) { }

	ngOnInit() {
	}

	// =========================================================================================================================================================
	// Event Handlers
	// =========================================================================================================================================================

	onSiteClicked(site) {
		this.selectedSite = site;
		this.siteSelected.emit(site);
	}
}

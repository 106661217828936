import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppDateAdapter } from '../../../../../shared-ui/custom/date-adapter';
import { AuthManagerService } from '../../../../../api/auth/auth-manager-service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { CultureSettings } from '../../../../../api/culture-settings/models/culture-settings.model';
import { DatePipe } from '@angular/common';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { ExportService } from '../../../../../common/services/export.service';
import { MapInfoLeaflet } from '../../../../../common/models/map-info-leaflet.model';
import { MessageBoxInfo } from '../../../global-message-box/message-box-info.model';
import { Note } from '../../../../../api/sticky-notes/models/sticky-note.model';
import { Observable } from 'rxjs';
import { RbEnums } from '../../../../../common/enumerations/_rb.enums';
import { RbUtils } from '../../../../../common/utils/_rb.utils';
import { ReportCsvService } from '../../../../../sections/reports/common/csv/report-csv.service';
import { StickyNoteManagerService } from '../../../../../api/sticky-notes/sticky-note-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile } from '../../../../../api/auth/models/user-profile.model';

@UntilDestroy()
@Component({
  selector: 'rb-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})

export class NotesListComponent implements OnInit {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  @Input() busy;
  @Input() mapInfo: MapInfoLeaflet;
  @Input() map;

  @Output() noteLocationClicked = new EventEmitter<{ event: any, note: Note }>();

  NoteAnchor = RbEnums.Note.NoteAnchor;
  NotePriority = RbEnums.Note.NotePriority;
  NoteStatus = RbEnums.Note.NoteStatus;

  notes: Note[] = []
  filteredNotes: Note[] = []

  public isMobile = false;
  user: UserProfile;

  isSearching = false;
  searchFilter = "";
  filterByMentions: number = 0;
  sortNotesBy: number = 0;
  showResolved = false

  protected browserWindow: Window;

  cultureSettings: CultureSettings;
  timeFormatString: string;

  constructor(
    private authManager: AuthManagerService,
    public broadcastService: BroadcastService,
    private datePipe: DatePipe,
    private deviceManager: DeviceManagerService,
    private exportService: ExportService,
    public notesManager: StickyNoteManagerService,
    public translate: TranslateService,
    private appDateAdapter: AppDateAdapter,
    private reportCsvService: ReportCsvService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceManager.isMobile;
    this.user = this.authManager.getUserProfile();
    this.notesManager.notes$.pipe(untilDestroyed(this))
    .subscribe({
      next: (notes: Note[]) => {
        if (notes.length) {
          this.notes = notes.sort((a, b) => {
            return <any>new Date(b.createdDateUtc) - <any>new Date(a.createdDateUtc);
          });
          this.getFilteredNotes();
        }
      },
      error: (error) => {
        console.error(error)
      }
    })
  }

  getFilteredNotes() {
    let filtered: Note[] = this.notes;

    if (this.searchFilter) {
      filtered = filtered.filter(msg => msg.content.toLowerCase().includes(this.searchFilter.toLowerCase()));
    }

    if (this.filterByMentions) {
      filtered = filtered.filter(msg => msg.content.toLowerCase().includes(`@${this.user.name.toLowerCase()}`));
    }

    switch (this.sortNotesBy) {
      case 0:
        filtered = filtered.sort((a, b) => new Date(b.createdDateUtc).getTime() - new Date(a.createdDateUtc).getTime());
        break;
      case 1:
        filtered = filtered.sort((a, b) => b.notePriority - a.notePriority);
        break;
      case 2:
        filtered = filtered.sort((a, b) => {
          const aReadByUser = a.readBy?.includes(this.user.userId);
          const bReadByUser = b.readBy?.includes(this.user.userId);
          if (aReadByUser && !bReadByUser) {
            return 1;
          } else if (!aReadByUser && bReadByUser) {
            return -1;
          } else {
            return new Date(b.createdDateUtc).getTime() - new Date(a.createdDateUtc).getTime()
          }
        });
        break;
    }

    this.filteredNotes = filtered;
  }

  toggleSearch() {
    this.searchFilter = "";
    this.isSearching = !this.isSearching
    if (this.isSearching) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 0);
    } else {
      this.searchFilter = "";
    }
  }

  readNote(note: Note) {
    if (note.readBy && note.readBy.length) {
      const foundId = note.readBy.find(id => id === this.user.userId);
      if (foundId) {
        return
      } else {
        note.readBy.push(this.user.userId)
      }
    } else {
      note.readBy = [this.user.userId]
    }
    const replies = note.replies;
    delete note.replies;
    delete note.isEditing;
    this.notesManager.updateNote(note.id, { readBy: note.readBy }).subscribe({
      next: (updatedNote) => {
        note.isEditing = false;
        note.replies = replies;
        this.notesManager.getUnreadNotesCounter();
        this.notesManager.broadcastNote(note);
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  onNoteLocationClicked(event: any, note: Note) {
    this.noteLocationClicked.emit({ event, note });
  }

  /*
    This method updates the current note without the isEditing property (being a UI-only property)
    and replies (those can be modified separately).
  */
  saveNote(note: Note) {
    const replies = note.replies;
    delete note.replies;
    delete note.isEditing;
    this.notesManager.updateNote(note.id, note).subscribe({
      next: (updatedNote) => {
        note.isEditing = false;
        note.replies = replies;
        this.notesManager.broadcastNote(note);
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  /* 
    This function changes the status of a note and its replies to Resolved and removes the marker from the Map.
  */
  resolveNote(note: Note) {
    let resolveNoteIds: number[] = [];

    switch (note.attachedToType) {
      case RbEnums.Note.NoteAnchor.Station:
        resolveNoteIds = note.replies?.map(reply => reply.id) || [];
        resolveNoteIds.push(note.id)
        break;

      default:
        if (note.replies && note.replies.length) {
          resolveNoteIds = [note.id, ...note.replies.map(reply => reply.id)];
        } else {
          resolveNoteIds = [note.id];
        }
        break;
    }

    const messageBoxInfo = MessageBoxInfo.create(
      this.translate.instant('STRINGS.RESOLVE'),
      this.translate.instant('STRINGS.RESOLVE_NOTE'),
      RbEnums.Common.MessageBoxIcon.None,
      async () => {
        for (const id of resolveNoteIds) {
          await this.waitFor(this.notesManager.resolveNote(id, { status: RbEnums.Note.NoteStatus.Resolved }));
        }
        switch (note.attachedToType) {
          case RbEnums.Note.NoteAnchor.Station:
            const station = this.mapInfo.stations.find(s => s.id === note.attachedToId);
            if (station) { this.mapInfo.removeNoteBadgeToStation(station) }
            break;

          default:
            this.mapInfo.onNoteDeleted(note.id)
            break;
        }
        note.status = RbEnums.Note.NoteStatus.Resolved;
        this.notesManager.broadcastNote(note);
      },
      null,
      RbEnums.Common.MessageBoxButtons.YesNo
    );
    this.broadcastService.showMessageBox.next(messageBoxInfo);
  }

  onInput(e) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight) + "px";
  }

  get isNoteListEmpty(): boolean {
    if (this.showResolved) {
      return !this.filteredNotes.length;
    } else {
      return !this.filteredNotes.filter(note => note.status === 1).length;
    }
  }

  waitFor<T>(obs: Observable<T>): Promise<T> {
    return new Promise(resolve => obs.subscribe(resolve));
  }


  trackNoteById(index: number, note: Note): number {
    return note.id;
  }

  // =========================================================================================================================================================
  // Helper Methods for Export CSV/PDF file
  // =========================================================================================================================================================

  onExportCSVClick() {
    // Handle export CSV file
    this.generateCsv();
  }

  private generateCsv() {
    const csvInfo = this.reportCsvService.createCsvDocument();
    this.exportService.generateCsvFile(
      csvInfo,
      this.generateTableHeader,
      this.generateTableBody
    );
    const fileName = this.translate.instant('STRINGS.EXPORT_FILE_NAME',
      { screen: this.translate.instant('STRINGS.ACTIVITY_COMPLETED'), type: 'csv' });
    this.reportCsvService.save(csvInfo, fileName, this.browserWindow?.open());
  }

  private get generateTableHeader(): any {
    const generatedOn = this.appDateAdapter.formatDateTime(new Date());
    return {
      reportNameLabel: this.translate.instant('STRINGS.NOTES_REPORT'),
      reportTypeLabel: "",
      reportPageLabel: this.translate.instant('STRINGS.NOTE_LIST'),
      reportGeneratedOnLabel: this.translate.instant('STRINGS.EXPORT_FILE_GENERATED_ON', { generatedOn: generatedOn }),
      reportHeader: [
        'STRINGS.CONTENT',
        'STRINGS.AUTHOR',
        'STRINGS.SITE',
        'STRINGS.COMPANY',
        'STRINGS.TYPE',
        'STRINGS.LOCATION',
        'STRINGS.PRIORITY',
        'STRINGS.STATUS',
        'STRINGS.CREATED_AT'
      ].map(x => this.translate.instant(x))
    };
  }

  private get generateTableBody() {
    return this.notes.map(note => [
      note.content ? `${note.content}` : '',
      note.authorId ? `${this.getUserName(note.authorId)}` : '',
      note.siteId ? `${this.getSiteName(note.siteId)}` : '',
      note.companyId ? `${this.getCompanyName(note.companyId)}` : '',
      note.attachedToType ? `${this.getNoteType(note.attachedToType)}` : '',
      note.latitude && note.longitude ? `${this.getLocation(note.latitude, note.longitude)}` : '',
      note.notePriority ? `${this.getPriority(note.notePriority)}` : '',
      note.status ? `${this.getStatus(note.status)}` : '',
      note.createdDateUtc ? `${this.getDateTime(note.createdDateUtc)}` : '',
    ]);
  }

  getUserName(authorId: number) {
    return authorId;
  }

  getSiteName(siteId: number) {
    return siteId;
  }

  getCompanyName(companyId: number) {
    return companyId;
  }

  private getNoteType(attachedToType: RbEnums.Note.NoteAnchor) {
    let type = this.translate.instant('STRINGS.NOTE');

    switch (attachedToType) {
      case RbEnums.Note.NoteAnchor.Station:
        type = this.translate.instant('STRINGS.STATION');
        break;
      case RbEnums.Note.NoteAnchor.Area:
        type = this.translate.instant('STRINGS.AREA');
        break;
      case RbEnums.Note.NoteAnchor.Hole:
        type = this.translate.instant('STRINGS.HOLE');
        break;

      default:
        break;
    }

    return type;
  }

  private getLocation(latitude: number, longitude: number) {
    return `[${latitude}, ${longitude}]`;
  }

  private getPriority(priority: RbEnums.Note.NotePriority): string {
    switch (priority) {
      case RbEnums.Note.NotePriority.None:
        return this.translate.instant('STRINGS.NONE');
      case RbEnums.Note.NotePriority.Low:
        return this.translate.instant('STRINGS.LOW');
      case RbEnums.Note.NotePriority.Moderate:
        return this.translate.instant('STRINGS.MODERATE');
      case RbEnums.Note.NotePriority.High:
        return this.translate.instant('STRINGS.HIGH');
      case RbEnums.Note.NotePriority.Urgent:
        return this.translate.instant('STRINGS.URGENT');
      default:
        return '';
    }
  }

  private getStatus(status: RbEnums.Note.NoteStatus): string {
    switch (status) {
      case RbEnums.Note.NoteStatus.Active:
        return this.translate.instant('STRINGS.ACTIVE');
      case RbEnums.Note.NoteStatus.Resolved:
        return this.translate.instant('STRINGS.RESOLVED');
      case RbEnums.Note.NoteStatus.Discarded:
        return this.translate.instant('STRINGS.DISCARDED');
      default:
        return '';
    }
  }

  getDateTime(value: Date) {
    this.cultureSettings = this.authManager.userCulture;
    this.timeFormatString = RbUtils.Common.getTimePickerDisplayValue(this.cultureSettings);
    const dateFormat = RbUtils.Common.getDateFormat(this.cultureSettings);

    return this.datePipe.transform(value, dateFormat + ' ' + this.timeFormatString);
  }

}


export class SatellitePinCode {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	companyId: number;
	id: number;
	ordinal: number;
	pincodeId: number;
	satelliteId: number;
}

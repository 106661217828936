import { filter, map, take, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiCachedRequestResponse } from '../_common/api-cached-request-response';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CreateProgramGroup } from './models/create-program-group.model';
import { Injectable } from '@angular/core';
import { PriorityGolfInputType } from '../programs/models/priority-input-type.model';
import { ProgramChange } from '../signalR/program-change.model';
import { ProgramGroup } from './models/program-group.model';
import { ProgramGroupApiService } from './program-group-api.service';
import { ProgramGroupListItem } from './models/program-group-list-item.model';
import { ProgramListItem } from '../programs/models/program-list-item.model';
import { ProgramManagerService } from '../programs/program-manager.service';
import { ProgramStepManagerService } from '../program-steps/program-step-manager.service';
import { ProgramType } from '../programs/models/program-type.model';
import { RbConstants } from '../../common/constants/_rb.constants';
import { RbEnums } from '../../common/enumerations/_rb.enums';
import { RbUtils } from '../../common/utils/_rb.utils';
import { RunTimeGolfInputType } from '../programs/models/run-time-input-Type.model';
import { ServiceManagerBase } from '../_common/service-manager-base';
import { SimpleProgramGroup } from './models/simple-program-group.model';
import { SystemStatusService } from '../../common/services/system-status.service';
import { TranslateService } from '@ngx-translate/core';
import { UniquenessResponse } from '../_common/models/uniqueness-response.model';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class ProgramGroupManagerService extends ServiceManagerBase {

	// Subjects
	programGroupsChange = new Subject();
	programGroupsListChange = new Subject<ProgramGroupListItem[]>();

	// Other cached data
	previousQuickIrrSiteId: number;

	// Cache Containers
	private _apiResult: ApiCachedRequestResponse<ProgramGroupListItem[]>;

	private _programTypes: ProgramType[] = null;
	private _runTimeInputTypes: RunTimeGolfInputType[] = null;

	// =========================================================================================================================================================
	// C'tor
	// =========================================================================================================================================================

	constructor(private programGroupApiService: ProgramGroupApiService,
				protected broadcastService: BroadcastService,
				private programStepsManager: ProgramStepManagerService,
				private translate: TranslateService,
				private systemStatusService: SystemStatusService) {

		super(broadcastService);

		// We don't immediately populate the program types list, as we know that the cache will be cleared when the initial
		// login occurs; it would be useless to get values now.

		this.systemStatusService.golfProgramGroupStatusChange
			.pipe(untilDestroyed(this))
			.subscribe((programChange: ProgramChange) => this.updateGolfProgramGroupStatuses(programChange));

		this.broadcastService.collectionChange
			.pipe(
				untilDestroyed(this),
				filter((collection: any) => collection instanceof Array && collection[0] instanceof ProgramListItem)
			)
			.subscribe(() => {
				this._apiResult = null;
				this.programGroupApiService.clearCache();
			});

		this.broadcastService.irrigationCancelled
			.pipe(untilDestroyed(this))
			.subscribe(() => this.updateProgramGroupsAfterCancel());

		// Since we localize values within the data, we must clear the cache when the app language is changed.
		this.broadcastService.cultureChanged
			.pipe(untilDestroyed(this))
			.subscribe(() => this.clearCache());

		this.broadcastService.programGroupsUpdated.pipe(untilDestroyed(this)).subscribe((change: ProgramChange) => {
			if (this._apiResult == null) return;

			if (change.changeType === RbEnums.SignalR.ProgramStatusChangeType.Deleted) {
				// Remove the existing list entry. Updated will add it back shortly.
				this._apiResult.value = this._apiResult.value.filter(item => item.id !== change.programGroupId);
			} else if (change.changeType === RbEnums.SignalR.ProgramStatusChangeType.Added) {
				this.getProgramGroupsList(true);
			} else if (change.changeType === RbEnums.SignalR.ProgramStatusChangeType.StartTimeChanged) {
				this.onProgramGroupStartTimeChanged(change);
			}

			this.programGroupsListChange.next(this._apiResult.value);
		});

		this.broadcastService.programGroupsBatchUpdated.pipe(untilDestroyed(this)).subscribe((change: ProgramChange) => {
			if (this._apiResult == null) return;

			const programGroupIds = change.itemsChanged == null || change.itemsChanged.ids == null ? [] : change.itemsChanged.ids;
			const updatedPrograms = [];
			programGroupIds.forEach(programGroupId => {
				const programGroupListItem = this._apiResult.value.find(p => p.id === programGroupId);
				if (programGroupListItem == null) return;
				Object.assign(programGroupListItem, ProgramManagerService.createSimplePatchObject(change));
				// re-calculate runTimeInputType, etAdjustType
				programGroupListItem.updateRunTimeInfo();
				updatedPrograms.push(programGroupListItem);
			});
			this.programGroupsListChange.next(this._apiResult.value);
		});
	}

	// =========================================================================================================================================================
	// Base Overrides
	// =========================================================================================================================================================

	protected clearCache() {
		this._programTypes = null;

		// Reload them immediately. While waiting for them to arrive, the enum names themselves will be used as a backup.
		// Note that we don't actually do anything with the observable result, so we have to find a suitable way to
		// get it, but not save it.
		this.initializeProgramTypes();
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================
	get cachedProgramGroups(): ProgramGroupListItem[] { return this._apiResult == null ? [] : this._apiResult.value; }

	createProgramGroup(programGroup: CreateProgramGroup): Observable<void> {
		return this.programGroupApiService.createProgramGroup(programGroup)
			.pipe(tap(() => this.programGroupsChange.next(null)));
	}

	createQuickIrr(update: any): Observable<null> {
		this.previousQuickIrrSiteId = update.siteId;
		return this.programGroupApiService.createQuickIrr(update)
			.pipe(tap(() => this.programGroupsChange.next(null)));
	}

	deleteProgramGroups(programGroupIds: number[]): Observable<void> {
		return this.programGroupApiService.deleteProgramGroups(programGroupIds)
			.pipe(
				tap(() => {
					// Remove the deleted programGroup from our collection and let interested parties know.
					this._apiResult.value = this._apiResult.value.filter(s => programGroupIds.indexOf(s.id) === -1);
					this.programGroupsChange.next(null);
				})
			);
	}

	getNameUniqueness(name: string, id: number): Observable<UniquenessResponse> {
		return this.programGroupApiService.getNameUniqueness(name, id);
	}

	getNumberUniqueness(number: number, id: number): Observable<UniquenessResponse> {
		return this.programGroupApiService.getNumberUniqueness(number, id);
	}

	getNextDefaultQuickIrrName(): Observable<string> {
		return this.programGroupApiService.getNextDefaultQuickIrrName();
	}

	getProgramGroupsList(bypassCache = false): Observable<ProgramGroupListItem[]> {
		return this.programGroupApiService.getProgramGroupsList(bypassCache).pipe(map(result => {
			if (result.isFromCache) return result.value.slice();

			// The program group list is new, having no status. Get each item's status.
			this.updateProgramGroupStatusesOnFetch(result.value);
			this._apiResult = result;
			return this._apiResult.value;
		}));
	}

	getProgramGroupListItem(programGroupId: number, bypassCache = false): Observable<ProgramGroupListItem> {
		return this.getProgramGroupsList(bypassCache)
			.pipe(map((programGroups: ProgramGroupListItem[]) => programGroups.find(pg => pg.id === programGroupId)));
	}

	getProgramGroup(id: number): Observable<ProgramGroup> {
		return this.programGroupApiService.getProgramGroup(id);
	}

	getProgramTypes(): Observable<ProgramType[]> {
		if (this._programTypes != null) return of(this._programTypes);

		// Get the types from API. Capture and save the to-be-cached values.
		return this.programGroupApiService.getProgramTypes()
			.pipe(
				take(1),	// We only want one result for this.
				tap(pt => this._programTypes = pt)
			);
	}

	getRunTimeInputTypes(): Observable<RunTimeGolfInputType[]> {
		if (this._runTimeInputTypes != null) { return of(this._runTimeInputTypes); }

		return this.programGroupApiService.getRunTimeInputTypes()
			.pipe(
				take(1),	// We only want one result for this.
				tap(response => this._runTimeInputTypes = response)
			);
	}

	/**
	 * Get the priority values/names for golf program priority. This basically comes down to:
	 * "" = 0
	 * "1 - manually-started stations" = 1
	 * "2 - manually-started schedules" = 2
	 * "1" = 3
	 * "2" = 4
	 * ...
	 * "8" = 10
	 * "9" = 11
	 * "10" = 12
	 * So we reserve the two highest priority values, 1 and 2, and assign 3-12 to program groups, but show them to the user
	 * as 1-10
	 */
	getPriorityInputTypes(): PriorityGolfInputType[] {
		return PriorityGolfInputType.getDefaultPrioritySet();
	}

	/**
	 * Method to immediately return the existing cached Program Types List.
	 * NOTE: This list is pre-loaded via the preloader service and should always be populated before first use.
	 * 		 This list is always assumed to be populated and must be immediately repopulated if cleared.
	 */
	getProgramTypesImmediate(): ProgramType[] {
		return this._programTypes;
	}

	getProgramTypesForDisplay(): Observable<ProgramType[]> {
		return this.getProgramTypes().pipe(map((programTypes: ProgramType[]) => {
			return programTypes.filter(programType => RbConstants.Form.PROGRAM_TYPES_TO_CONSIDER.includes(programType.value));
		}));
	}

	getSimpleProgramGroup(programGroupId: number): Observable<SimpleProgramGroup> {
		return this.programGroupApiService.getSimpleProgramGroup(programGroupId);
	}

	updateProgramGroups(programGroupIds: number[], programGroupUpdate: object): Observable<null> {
		return this.programGroupApiService.updateProgramGroups(programGroupIds, programGroupUpdate)
			.pipe(tap(() => {
				if (programGroupUpdate['programGroupAdjust'] != null
					|| programGroupUpdate['tempProgramGroupAdjust'] != null
					|| programGroupUpdate['overrideWB'] != null) {
					this.programStepsManager.programsOrProgramGroupsUpdated(programGroupIds);
				}
				this.programGroupsListChange.next(null);
			}));
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	/**
	 * @summary Set up the initial values of the program types, or refresh the cache after it is cleared.
	 */
	private initializeProgramTypes() {
		this.getProgramTypes()
			.pipe(take(1))
			.subscribe();
	}

	private updateProgramGroupsAfterCancel() {
		if (this._apiResult && this._apiResult.value.length > 0) {
			this._apiResult.value.forEach(s => s.status = '-');
			this.programGroupsListChange.next(null);
		}
	}

	private updateGolfProgramGroupStatus(programGroup: ProgramGroupListItem, programChange?: ProgramChange): Observable<boolean> {
		// If no programGroupChange, return with 'no status'.
		if (programChange != null && 
			(programGroup.id !== programChange.programGroupId || !RbUtils.Programs.isProgramRealTimeStatusMessage(programChange.changeType))) {
				return of(false);
			}

		const newStatusString = this.getProgramGroupStatus(programChange);
		const diff = (newStatusString !== programGroup.status);
		programGroup.status = newStatusString;
		return of(diff);
	}

	private updateProgramGroupStatusesOnFetch(programGroup: ProgramGroupListItem[]) {
		const operations = programGroup.map(pg => this.updateGolfProgramGroupStatus(pg,
			this.systemStatusService.getGolfProgramGroupStatus(pg.id)));

		forkJoin(operations).pipe(take(1)).subscribe(results => {
			if (results.some((value, index, array) => value === true)) {
				if (this._apiResult)
					this.programGroupsListChange.next(this._apiResult.value);
			}
		});
	}

	private updateGolfProgramGroupStatuses(golfProgramStatus: ProgramChange) {
		if (!this._apiResult || this._apiResult.value.length < 1) return;

		this._apiResult.value.forEach(programGroup => {
			if (programGroup.id === golfProgramStatus.programGroupId &&
				RbUtils.Programs.isProgramRealTimeStatusMessage(golfProgramStatus.changeType)) {
				const newStatusString = this.getProgramGroupStatus(golfProgramStatus);
				programGroup.status = newStatusString;
			}
		});
		this.programGroupsListChange.next(this._apiResult.value);
	}

	private getProgramGroupStatus(programChange: ProgramChange): string {
		if (programChange == null)
			return '-';
		switch (programChange.changeType) {
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramStarted:
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramResumed:
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramRunningUpdate:
				return this.translate.instant('STRINGS.RUNNING');
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramPaused:
				return RbUtils.Translate.instant('STRINGS.PAUSED');
			case RbEnums.SignalR.ProgramStatusChangeType.Waiting:
				return RbUtils.Translate.instant('STRINGS.WAITING');
			case RbEnums.SignalR.ProgramStatusChangeType.Posted:
				return RbUtils.Translate.instant('STRINGS.POSTED');
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramCompleted:
			case RbEnums.SignalR.ProgramStatusChangeType.ProgramCancelled:
			default:
				return '-';
		}
	}

	private onProgramGroupStartTimeChanged(programStartTimeChange: ProgramChange) {
		const programGroupToUpdate = this._apiResult.value.find(x => x.id === programStartTimeChange.programGroupId);

		if (!programGroupToUpdate) {
			return;
		}

		if (!programStartTimeChange.itemsChanged) {
			return;
		}

		// replace path Object
		const programStartTime = this.programGroupApiService.replacePathObject(programStartTimeChange.itemsChanged);

		// add new start time
		if (programStartTimeChange.itemsChanged.add) {
			programStartTime.forEach(newGroupStartTime => {
				// new start time is not added, ignore it
				if (newGroupStartTime.id === undefined) {
					return;
				}
				const currentStartTime = programGroupToUpdate.groupStartTimes.find(x => x.id === newGroupStartTime.id);
				// if new item is not exist, add it to the list
				if (currentStartTime === undefined) {
					programGroupToUpdate.groupStartTimes.push({
						dateTime : new Date(newGroupStartTime.dateTime),
						enabled : newGroupStartTime.enabled,
						id : newGroupStartTime.id,
						programGroupId: newGroupStartTime.programGroupId,
						companyId: newGroupStartTime.companyId
					});
				}
			});
		}

		// remove deleted start time from the list
		if (programStartTimeChange.itemsChanged.delete && programStartTimeChange.itemsChanged.delete.ids) {
			programStartTimeChange.itemsChanged.delete.ids.forEach(deletedId => {
				programGroupToUpdate.groupStartTimes = programGroupToUpdate.groupStartTimes.filter(x => x.id !== deletedId);
			});
		}

		// update program group start time
		if (programStartTimeChange.itemsChanged.update) {
			programStartTime.forEach(updateGroupStartTime => {
				const currentStartTime = programGroupToUpdate.groupStartTimes.find(x => x.id === updateGroupStartTime.id);
				currentStartTime.dateTime = new Date(updateGroupStartTime.dateTime);
				currentStartTime.enabled = updateGroupStartTime.enabled;
			});
		}
	}

	statusSortableValue(listItem: ProgramGroupListItem, statusString: string): number {
		if (statusString === this.translate.instant('STRINGS.RUNNING')) return 0;
		if (statusString === this.translate.instant('STRINGS.POSTED')) return 1;
		if (statusString === this.translate.instant('STRINGS.WAITING')) return 2;
		if (statusString === this.translate.instant('STRINGS.PAUSED')) return 3;
		if (!listItem.isEnabled) return 5;

		return 4;
	}
}
